import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from 'theme/Theme';
import { Registration } from 'utils/common/translationUtils/translationStrings';
import { Days, DAYS_IN_MONTH, Months, MONTHS_IN_YEAR, PERIOD_START_NUMBER, Years } from './DatePickerData';
import DatepickerDropdown from './DatepickerDropdown';
import { FormUI, FormGroup } from '..';
import { InputLocationType } from '../../formTypes';

interface Props {
  componentLocation?: InputLocationType;
  isMobileAgnostic?: boolean;
  label?: string;
  inputIcon?: string;
  hasError?: boolean;
  errorMessage?: string;
  onChange: (name: 'day' | 'month' | 'year') => (value: string | number) => void;
  validation;
  defaultValue?: number; // birthday
  disabled?: boolean;
  readOnly?: boolean;
}

const CustomDatePicker: React.FC<Props> = ({
  componentLocation,
  isMobileAgnostic,
  label,
  inputIcon,
  hasError,
  errorMessage,
  onChange,
  validation,
  disabled,
  defaultValue,
  readOnly,
}) => {
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });
  const { t } = useTranslation();
  const sortedYears = Years ? Years.sort((a, b) => (a.id > b.id ? -1 : 1)) : Years;

  const datePickerFormGroupStyles = clsx(!isDesktop && 'form-group-simple-datepicker col-12');
  const year = defaultValue !== undefined ? new Date(defaultValue).getFullYear() : defaultValue;
  const month = defaultValue !== undefined ? new Date(defaultValue).getMonth() + 1 : defaultValue;
  const day = defaultValue !== undefined ? new Date(defaultValue).getDate() : defaultValue;

  return (
    <FormGroup isMobileAgnostic={isMobileAgnostic} className={datePickerFormGroupStyles}>
      <FormUI.Label componentLocation={componentLocation} icon={inputIcon} label={label} />

      <FormUI.ControlWrapper type="datepicker" hasError={hasError}>
        <FormUI.Row>
          <DatepickerDropdown
            {...(day ? { value: day } : {})}
            colsClasses={'form-group__birth-data-select col-4'}
            disabled={disabled}
            hasError={hasError}
            isMobileAgnostic={isMobileAgnostic}
            max={String(DAYS_IN_MONTH)}
            maxLength={2}
            min={String(PERIOD_START_NUMBER)}
            name="day"
            onChange={onChange}
            options={Days}
            placeholder={t(Registration.DatePicker.dayText)} //TODO change translations to DD-MM-YYYY (by design)
            readOnly={readOnly}
            type="number"
            validation={validation}
          />
          <DatepickerDropdown
            {...(month ? { value: month } : {})}
            colsClasses={'form-group__birth-data-select col-4'}
            disabled={disabled}
            hasError={hasError}
            isMobileAgnostic={isMobileAgnostic}
            max={String(MONTHS_IN_YEAR)}
            maxLength={2}
            min={String(PERIOD_START_NUMBER)}
            name="month"
            onChange={onChange}
            options={Months}
            placeholder={t(Registration.DatePicker.monthText)}
            readOnly={readOnly}
            type="number"
            validation={validation}
          />
          <DatepickerDropdown
            {...(year ? { value: year } : {})}
            colsClasses={'form-group__birth-data-select col-4'}
            disabled={disabled}
            hasError={hasError}
            isMobileAgnostic={isMobileAgnostic}
            maxLength={4}
            min={String(0)}
            name="year"
            onChange={onChange}
            options={sortedYears}
            placeholder={t(Registration.DatePicker.yearText)}
            readOnly={readOnly}
            type="number"
            validation={validation}
          />
        </FormUI.Row>
        {hasError && errorMessage && <FormUI.Message type="error" content={errorMessage} />}
      </FormUI.ControlWrapper>
    </FormGroup>
  );
};

export default CustomDatePicker;
