import { useLocation } from 'react-router-dom';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { BalanceType } from 'shared/common/features/myProfile/types/myProfile.types';
import { calculateFreeBetBalance, matchBalanceType } from 'shared/common/features/myProfile/utils/myProfile.utils';
import { useAppSelector } from 'store';
import { formatBalance } from 'utils/common/helpersCommon';

type ReturnType = {
  balanceAmount: string;
  withdrawableBalance: string;
  freeBetBalance: string;
  balanceNumber?: number;
};

export const useUserBalance = (): ReturnType => {
  const location = useLocation();
  const vertical = location.pathname.split('/')[1];

  const balance = useAppSelector(selectMyProfile.balance);
  const balanceNumber = balance ? balance[matchBalanceType(vertical)] : undefined;
  const balanceAmount = balance ? formatBalance(balance[matchBalanceType(vertical)]) : '';
  const withdrawableBalance = balance ? formatBalance(balance[BalanceType.WithdrawableBalance]) : '';
  const freeBetBalance = balance
    ? formatBalance(calculateFreeBetBalance(vertical, balance)) // Back-end need to return totalFreeBetBalance
    : '';

  return { balanceAmount, withdrawableBalance, freeBetBalance, balanceNumber };
};
