import styled from 'styled-components';

export const StyledSpinButton = styled.div<{ $color?: string }>`
  z-index: 10;
  place-self: center;
  width: clamp(4.5rem, 3.563rem + 4vw, 6.125rem);
  height: clamp(4.5rem, 3.563rem + 4vw, 6.125rem);
  border-radius: 100%;
  font-size: clamp(0.75rem, 0.606rem + 0.62vw, 1rem);
  font-weight: 700;
  text-shadow: 0px 0px 3px #000;
  color: ${({ $color }) => $color ?? '#fff'};
  transition: all 0.15s;
`;
