import React from 'react';
import clsx from 'clsx';
import { Icon } from 'modules/casino/shared/components/icons';
import { Flex } from 'modules/casino/shared/components/layout';
import { jackpotWinModalPrefix } from '../../utils/gameLaunchBonusConstants';

type Props = {
  title: string;
  handleClose: () => void;
  handleInfo: () => void;
};

const JackpotModalHeader: React.FC<Props> = ({ title, handleClose, handleInfo }) => {
  return (
    <Flex.ACJB className={clsx('bs-modal__header')}>
      {handleInfo && (
        <Icon onClick={handleInfo} icon={'info-solid'} className={`${jackpotWinModalPrefix}__header-icon`} />
      )}

      <div className={`${jackpotWinModalPrefix}__header-title d-flex-center`}>{title}</div>

      {handleClose && (
        <div onClick={handleClose}>
          <Icon icon={'times'} className={`${jackpotWinModalPrefix}__header-icon cursor-pointer`} />
        </div>
      )}
    </Flex.ACJB>
  );
};

export default JackpotModalHeader;
