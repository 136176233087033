import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { PortalsTypes } from '../types/platformPortals.types';

const initialState: PortalsTypes.State = {
  platformSidebarCasinoSliders: {},
};

const platformPortals = createSlice({
  name: 'platformPortals',
  initialState,

  reducers: {
    setPlatformSidebaCasinoSlider(state, action) {
      const { portalId, slider } = action.payload;
      if (slider === null && state.platformSidebarCasinoSliders[portalId]) {
        delete state.platformSidebarCasinoSliders[portalId];
      } else {
        state.platformSidebarCasinoSliders[portalId] = slider;
      }
    },
  },
});

export const { setPlatformSidebaCasinoSlider } = platformPortals.actions;

export const selectPlatformPortals = {
  platformSidebarCasinoSliders: (state: RootState) => state.common.platformPortals.platformSidebarCasinoSliders,
};

export default platformPortals.reducer;
