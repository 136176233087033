import isEqual from 'lodash/isEqual';
import { selectMyChallenges } from 'shared/common/features/myProfile/sections/tabs/captainUpTab/slice/clubTab.slice';
import { selectChallenges } from '../slice/challenges.slice';
import { Challenges } from '../types/challenges.types';

export const normalizeChallenge = (currBadges = {}, challenges: Challenges.Challenge[], alias: string): void => {
  challenges?.forEach((badge) => {
    const existingBadge = currBadges?.[alias]?.[badge?.id];

    if (isEqual(existingBadge, badge)) return;
    if (!currBadges?.[alias]) {
      currBadges[alias] = {};
    }
    currBadges[alias][badge.id] = badge;
  });
};

export const normalizeCategories = (
  currCategories = {},
  categories: Challenges.CategoryApi[],
  page: number,
  polling: boolean,
): void => {
  categories?.forEach((currCategory) => {
    const existingCategory = currCategories[currCategory.alias];

    if (existingCategory && polling) {
      currCategories[currCategory.alias].hideName = currCategory.hideName ?? false;

      if (existingCategory.totalItems !== currCategory.totalItems) {
        currCategories[currCategory.alias].totalItems = currCategory.totalItems;
      }
      if (
        !isEqual(
          existingCategory.ids,
          currCategory.items.map((badge) => badge.id),
        )
      ) {
        currCategories[currCategory.alias].ids = currCategory.items.map((badge) => badge.id);
      }
    } else if (existingCategory?.page + 1 === page && !polling) {
      currCategories[currCategory.alias].totalItems = currCategory.totalItems;
      currCategories[currCategory.alias].page = page;
      currCategories[currCategory.alias].hideName = currCategory.hideName ?? false;
      currCategories[currCategory.alias].ids = [
        ...existingCategory.ids,
        ...currCategory.items.map((badge) => badge.id),
      ];
    } else if (!existingCategory) {
      currCategories[currCategory.alias] = {
        totalItems: currCategory.totalItems || 0,
        alias: currCategory.alias,
        categoryId: currCategory.categoryId,
        category: currCategory.category,
        page: page,
        ids: currCategory.items.map((badge) => badge.id),
        hideName: currCategory.hideName ?? false,
      };
    }
  });
};

export const challengeSelector = {
  default: selectChallenges,
  myAccount: selectMyChallenges,
};
