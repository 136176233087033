import { useEffect, useRef } from 'react';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { useAppDispatch } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { LayoutConfigTypes } from '../../general/types/generalSlice.types';
import { highlightNewFeatureActions } from '../slice/highlightNewFeature.slice';
import { isViewFeature } from '../utils/HighlightNewFeature.utils';

export const useHighlightComponents = ({ isPortrait = false, disable }: { isPortrait: boolean; disable: boolean }) => {
  const highlightFeatureConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.HIGHLIGHTFEATURE,
  });

  const isLast = useRef(false);

  const dispatch = useAppDispatch();
  // const components = useAppSelector(selectHighlightNewFeature.components);

  const items = {};
  // const filterItems = useMemo(
  //   () =>
  //     highlightFeatureConfig?.feature.filter(function (e) {
  //       if (Object.keys(components).indexOf(e) > -1) {
  //         items[e] = components[e];
  //       }
  //     }),
  //   [highlightFeatureConfig?.feature, components, items],
  // );

  useEffect(() => {
    if (disable) {
      dispatch(highlightNewFeatureActions.setIsDone(false));
      dispatch(highlightNewFeatureActions.setHide());
    }
  }, [disable]);

  useEffect(() => {
    if (highlightFeatureConfig?.show && !isEmpty(items) && isPortrait && !disable) {
      for (let i = 0; i < Object.keys(items).length; i++) {
        if (!items[Object.keys(items)[i]].isView && !isViewFeature(Object.keys(items)[i])) {
          dispatch(highlightNewFeatureActions.setActiveComponent(Object.keys(items)[i]));
          isLast.current = Object.keys(items).indexOf(Object.keys(items)[i]) === Object.keys(items).length - 1;
          break;
        }
        if (Object.keys(items).length - 1 === i && !disable) {
          dispatch(highlightNewFeatureActions.setIsDone(true));
        }
      }
    } else if (!disable && (!highlightFeatureConfig?.show || isEmpty(items))) {
      dispatch(highlightNewFeatureActions.setIsDone(true));
    }
  }, [dispatch, highlightFeatureConfig, items, isPortrait, disable]);

  return { items, isLast: isLast.current };
};
