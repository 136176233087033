import React from 'react';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';

const componentsMap = {
  title({ text, textStyle }) {
    return (
      <div className={`${cssGlobalNS}-c-prm-lobby-card__title text-truncate`} style={textStyle}>
        {text}
      </div>
    );
  },
  subtitle({ text, textStyle }) {
    return (
      <div className={`${cssGlobalNS}-c-prm-lobby-card__subtitle`} style={textStyle}>
        {text}
      </div>
    );
  },
  description({ text, textStyle }) {
    return (
      <div className={`${cssGlobalNS}-c-prm-lobby-card__description`} style={textStyle}>
        {text}
      </div>
    );
  },
};

interface CardTitleProps {
  type: 'title' | 'subtitle' | 'description';
  text: string;
  textStyle?: { color: string; backgroundColor: string };
}

const CardTitle: React.FC<CardTitleProps> = ({ type, text, textStyle }) => {
  const TextComponent = componentsMap[type];

  return <TextComponent text={text} textStyle={textStyle} />;
};

export default CardTitle;
