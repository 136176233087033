import React, { RefObject, memo } from 'react';
import { General } from 'shared/common/features/general/types/generalSlice.types';
import MainNavDesktopLayout01 from './MainNavDesktopLayout01';
import MainNavDesktopLayout03 from './MainNavDesktopLayout03';

type MainNavDesktopTypes = {
  isAuthenticated: boolean | null;
  layout: General.HeaderWrapperConfig['layoutType'];
  parentRef?: RefObject<HTMLDivElement>;
};

const componentsMap: Record<General.HeaderWrapperConfig['layoutType'], React.FC<MainNavDesktopTypes>> = {
  layout_1({ isAuthenticated, parentRef, layout }) {
    return <MainNavDesktopLayout01 isAuthenticated={isAuthenticated} parentRef={parentRef} layout={layout} />;
  },
  layout_2({ isAuthenticated, parentRef, layout }) {
    return <MainNavDesktopLayout01 isAuthenticated={isAuthenticated} parentRef={parentRef} layout={layout} />;
  },
  layout_3({ isAuthenticated, parentRef, layout }) {
    return <MainNavDesktopLayout03 isAuthenticated={isAuthenticated} parentRef={parentRef} layout={layout} />;
  },
};

const MainNavDesktop: React.FC<MainNavDesktopTypes> = ({ isAuthenticated, layout, parentRef }) => {
  const MainNavDesktopComponent = componentsMap[layout];

  return <MainNavDesktopComponent layout={layout} isAuthenticated={isAuthenticated} parentRef={parentRef} />;
};

export default memo(MainNavDesktop);
