import { useCallback } from 'react';
import { useAppDispatch } from 'store';
import { showPlayerWinInfo } from '../slice/playerWinInfo.slice';

const usePlayerWinInfo = (): (() => void) => {
  const dispatch = useAppDispatch();
  const showPlayerWinInfoModal = useCallback(() => dispatch(showPlayerWinInfo()), [dispatch]);
  return showPlayerWinInfoModal;
};

export default usePlayerWinInfo;
