import React from 'react';
import clsx from 'clsx';
import SVGCDN from 'components/shared/UI/SVGCDN/SVGCDN';
import { BonusIconsTypes } from './BonusIndicator.types';

type Props = {
  className?: string;
  icon: BonusIconsTypes;
  text?: string;
};

const BonusIndicator: React.FC<Props> = ({ className, icon, text }) => {
  const wrapperClass = clsx('egtd-bonus-indicator', className);

  const hasTextProp = text ? { 'data-has-text': true } : undefined;

  return (
    <div className={wrapperClass} {...hasTextProp}>
      {text && text}
      <SVGCDN size={10} path="SPORT_BONUSES" icon={icon} />
    </div>
  );
};

BonusIndicator.displayName = 'EGTD Platform Bonus Indicator';

export default BonusIndicator;
