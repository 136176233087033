import React, { useEffect, useState } from 'react';
import { UnknownAction } from '@reduxjs/toolkit';
import { TFunction } from 'i18next';
import { Flex } from 'components/shared/layout';
import Button from 'components/shared/UI/Button/Button';
import { TerminalModalUI } from 'modules/retail/modules/ssbt/components/ssbtModalUI';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { useAppDispatch } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';
import IdVerificationModalStepper from './UI/IdVerificationModalStepper';
import { useCashierModalPrompt } from '../../hooks/useCashierModalPrompt';

type Props = {
  isPending: boolean;
  responseMessage: { ageError: string; nraError: string } & string;
  resetStatus: () => UnknownAction;
  t: TFunction;
};
const UcnCheckCashierModal: React.FC<Props> = ({ responseMessage, resetStatus, isPending, t }) => {
  const [checkingAge, setCheckingAge] = useState(false);
  const [showingDoubleErrors, setshowingDoubleErrors] = useState(false);
  const isUCNCheckPassed = responseMessage?.nraError === null && responseMessage?.ageError === null;
  const isInitialScanError = responseMessage === 'Cashier.UcnScan.failedScan';

  const isCashierModalPrompted = useCashierModalPrompt();
  useEffect(() => {
    if (isCashierModalPrompted) {
      closeModal();
    }
  }, [isCashierModalPrompted]);
  useEffect(() => {
    if (isInitialScanError) {
      setCheckingAge(false);
      return;
    }
    setCheckingAge(true);

    const timer = setTimeout(() => {
      setCheckingAge(isPending);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [isPending, isInitialScanError]);
  useEffect(() => {
    if (isInitialScanError) {
      setshowingDoubleErrors(false);
      return;
    }
    setshowingDoubleErrors(false);
    const timer = setTimeout(() => {
      if (checkingAge && !isPending) {
        setshowingDoubleErrors(false);
      }
    }, 2500);

    setshowingDoubleErrors(true);

    return () => {
      clearTimeout(timer);
    };
  }, [responseMessage, isInitialScanError]);
  const { closeModal } = useHandleModals();
  const dispatch = useAppDispatch();
  const cssNS = 'idvfr';
  const handleClose = () => {
    closeModal();
    if (resetStatus) {
      dispatch(resetStatus());
    }
  };
  return (
    <div className={cssNS}>
      <TerminalModalUI.Wrapper isVisible={true}>
        <TerminalModalUI.Body>
          <div className={`${cssNS}__heading`}>{t(CashierTranslations.UCNCardScanner.verification)}</div>
          <Flex.Col className={`${cssNS}__content`}>
            <Flex.Col className={`${cssNS}__stepper-wrapper`}>
              {!isInitialScanError && showingDoubleErrors && (
                <>
                  <IdVerificationModalStepper
                    isPending={checkingAge}
                    success={responseMessage?.ageError === null ? true : false}
                    stepText={checkingAge ? CashierTranslations.UCNCardScanner.scanning : responseMessage?.ageError}
                    fieldName={CashierTranslations.UCNCardScanner.idScan}
                    t={t}
                  />
                  <IdVerificationModalStepper
                    isPending={checkingAge}
                    success={responseMessage?.nraError === null ? true : false}
                    stepText={checkingAge ? CashierTranslations.UCNCardScanner.scanning : responseMessage?.nraError}
                    isAgeLimitError={responseMessage?.ageError !== null && !checkingAge}
                    fieldName={CashierTranslations.UCNCardScanner.nraScan}
                    t={t}
                  />
                </>
              )}
              {!showingDoubleErrors && !isEmpty(responseMessage) && (
                <IdVerificationModalStepper
                  isPending={checkingAge}
                  success={isInitialScanError ? false : isUCNCheckPassed ? true : false}
                  stepText={
                    isInitialScanError
                      ? responseMessage
                      : responseMessage?.nraError ||
                        responseMessage?.ageError ||
                        CashierTranslations.UCNCardScanner.personIsNotInNraList
                  }
                  fieldName={
                    !isUCNCheckPassed
                      ? CashierTranslations.UCNCardScanner.verificationUnsuccessful
                      : CashierTranslations.UCNCardScanner.verificationSuccessful
                  }
                  t={t}
                  isLastPartDialog={!showingDoubleErrors}
                />
              )}
            </Flex.Col>
            <Button
              variant="primary"
              onClick={handleClose}
              text={t(CashierTranslations.UCNCardScanner.closeBtn)}
              size="xl"
              className="close-button"
            />
          </Flex.Col>
        </TerminalModalUI.Body>
      </TerminalModalUI.Wrapper>
    </div>
  );
};
export default UcnCheckCashierModal;
