import { RefObject, useEffect } from 'react';
import { isIOS } from 'react-device-detect';

export const useScrollInToView = (
  wrapperRef: RefObject<HTMLElement>,
  elementRef: RefObject<HTMLElement>,
  scrollIn: boolean,
  direction: 'vertical' | 'horizontal',
): void => {
  useEffect(() => {
    if (direction === 'vertical') {
      if (wrapperRef.current && elementRef.current && scrollIn) {
        const scrollTop: number = wrapperRef.current?.scrollTop;
        const viewPort: number = wrapperRef.current?.scrollTop + wrapperRef.current?.clientHeight;
        const elementPosition: number = (elementRef.current.offsetTop + elementRef.current.offsetHeight) as number;
        const scrollTopDirection = scrollTop >= elementRef.current.offsetTop - (isIOS ? 3 : 0);
        const scrollBottomDirection = viewPort < elementPosition;
        if (scrollTopDirection || scrollBottomDirection) {
          const scrollY = scrollTopDirection
            ? scrollTop - (scrollTop - elementRef.current.offsetTop)
            : scrollTop + (elementPosition - viewPort) - (isIOS ? 3 : 0);
          wrapperRef.current.scrollTo({
            behavior: 'smooth',
            top: scrollY,
          });
        }
      }
    } else {
      if (wrapperRef.current && elementRef.current && scrollIn) {
        const leftScroll: number = wrapperRef.current?.scrollLeft as number;
        const viewPort: number = (leftScroll + wrapperRef.current.clientWidth) as number;
        const elementPosition: number = (elementRef.current.offsetLeft + elementRef.current.offsetWidth) as number;
        const scrollLeftDirection = leftScroll >= elementRef.current.offsetLeft;
        const scrollRightDirection = viewPort < elementPosition;
        const inView = elementRef.current.offsetLeft + elementRef.current.offsetWidth;
        const scrollX = scrollLeftDirection
          ? leftScroll - (leftScroll - elementRef.current.offsetLeft) - 25
          : inView - viewPort + leftScroll + 25;
        if (scrollLeftDirection || scrollRightDirection) {
          wrapperRef.current.scrollTo({
            behavior: 'smooth',
            left: scrollX,
          });
        }
      }
    }
  }, [scrollIn]);
};

//IOS bug on auto scroll to bottom
export const useScrollInToViewExit = (
  wrapperRef: RefObject<HTMLElement>,
  scrollIn: boolean,
  direction?: 'vertical' | 'horizontal',
): void => {
  useEffect(() => {
    return () => {
      if (wrapperRef.current && direction === 'vertical' && !scrollIn) {
        wrapperRef?.current?.scrollTo({
          top: wrapperRef?.current?.scrollTop - 2,
          behavior: 'smooth',
        });
      }
    };
  }, [scrollIn, wrapperRef]);
};
