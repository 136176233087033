export const MenuItemFixedPositionType = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  CENTER: 'CENTER',
};

export const MenuItemType = {
  CATEGORY: 'CATEGORY',
  SEARCH: 'SEARCH',
  FAVOURITES: 'FAVOURITES',
  LAST_PLAYED: 'LAST_PLAYED',
  LINK: 'LINK',
  LOBBY: 'LOBBY',
};

export const MENU_ITEM_WITH_BADGE = [MenuItemType.FAVOURITES];
export const DEPEND_AUTH_MENU_ITEMS = [MenuItemType.FAVOURITES, MenuItemType.LAST_PLAYED];
export const WITH_SPLIT_TITLE_MENU_TYPES = [
  MenuItemType.CATEGORY,
  MenuItemType.FAVOURITES,
  MenuItemType.LAST_PLAYED,
  MenuItemType.LINK,
  MenuItemType.LOBBY,
];

export const leftFixedClassName = 'casino-nav__item--fixed-left';
export const rightFixedClassName = 'casino-nav__item--fixed-right';
export const LOBBY_NAV_TO_CATEGORY = '-1';
export const LINK_NAV_TO_CATEGORY = '-3';
