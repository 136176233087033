import React, { memo } from 'react';
import { useAppSelector } from 'store';
import JackpotLevelWrapper from './jackpotLevel/JackpotLevelWrapper';
import SkywindJackpotLevel from './jackpotLevel/SkywindJackpotLevel';
import { SkywindJackpots } from '../jackpotConstants';
import { jackpotSelectors } from '../jackpotSlice';
import { JackpotTypes } from '../jackpotTypes';
import { getLevels } from '../jackpotUtils';

interface JackpotLevelsProps {
  jackpotId: string;
  levels: JackpotTypes.JackpotLevel[];
  gameProviderId: string;
  fromWidget?: boolean;
  fromBanner?: boolean;
}
const JackpotLevels: React.FC<JackpotLevelsProps> = ({ jackpotId, levels, gameProviderId, fromWidget, fromBanner }) => {
  const jackpotDenomDetails = useAppSelector((state) => jackpotSelectors.selectJackpotDenomDetails(state, jackpotId));

  const renderJackpotLevel = (levelDetails: JackpotTypes.CasinoLevel, idx: number) => {
    if (!jackpotId) return <></>;

    return SkywindJackpots[levels[idx]?.name] ? (
      <SkywindJackpotLevel
        jackpotId={jackpotId}
        levelDetails={levelDetails}
        levelName={levels[idx]?.name}
        key={`${jackpotId}_${idx}_${levels[idx]?.name}`}
      >
        <JackpotLevelWrapper
          key={`${jackpotId}_${idx}`}
          idx={idx + 1}
          level={String(levelDetails.level)}
          jackpotId={jackpotId}
          providerId={gameProviderId}
          panelUrl={levels && levels[idx]?.panel?.staticLocation}
          fromWidget={fromWidget}
          fromBanner={fromBanner}
        />
      </SkywindJackpotLevel>
    ) : (
      <JackpotLevelWrapper
        key={`${jackpotId}_${idx}`}
        idx={idx + 1}
        level={String(levelDetails.level)}
        jackpotId={jackpotId}
        providerId={gameProviderId}
        panelUrl={levels && levels[idx]?.panel?.staticLocation}
        fromWidget={fromWidget}
        fromBanner={fromBanner}
      />
    );
  };

  const renderJackpots = () => {
    const jackpotLevels = getLevels({ jackpotDenomDetails }) || [];

    return jackpotLevels.map((levelDetails, idx) => renderJackpotLevel(levelDetails, idx));
  };

  return jackpotDenomDetails ? renderJackpots() : <></>;
};

export default memo(JackpotLevels);
