import Button from './Button/Button';
import HTMLButton from './Button/HTMLButton';
import Flag from './Flag/Flag';
import { History } from './History';
import NewLabel from './NewLabel/NewLabel';
import PNGCDN from './PNGCDN/PNGCDN';
import SVGCDN from './SVGCDN/SVGCDN';
import Switch from './Switch/Switch';
import { TabsUI } from './tabs';

export const UI = {
  Button: Button,
  Flag: Flag,
  History: History,
  HTMLButton: HTMLButton,
  SVGCDN: SVGCDN,
  PNGCDN: PNGCDN,
  Switch: Switch,
  NewLabel: NewLabel,
  Tabs: TabsUI,
};
