import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Flex } from 'components/shared/layout';
import { useCountDown } from 'hooks/common/useCountDown';
import { Breakpoints } from 'theme/Theme';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import { addLeadingZero, formatDateWithOffset } from 'utils/dateUtils';
import PrizeDropPromoTimeCounterItem from './PrizeDropPromoTimeCounterItem';
import { prizeDropPromoCSSNS } from '../prizeDropUICSS.types';

type Props = {
  date: number;
  text?: string;
  isColumn?: boolean;
  showDate?: boolean;
  setIsTimeExpired?: React.Dispatch<React.SetStateAction<boolean>>;
  slotStatus?: 'soon' | 'active' | 'ended';
  children?: React.ReactNode;
};

const PrizeDropPromoTimeCounter: React.FC<Props> = ({
  slotStatus,
  date,
  text,
  showDate,
  setIsTimeExpired,
  children,
}) => {
  const { t } = useTranslation();
  const { timer, isExpired } = useCountDown(date, 'systemTime');

  useEffect(() => {
    if (setIsTimeExpired) {
      setIsTimeExpired(isExpired);
    }
  }, [isExpired, setIsTimeExpired]);

  const isMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isMobile}px)` });

  return (
    <Flex.Col>
      {isMobile && <div className="egtd-promo-header-separator">&nbsp;</div>}
      <egtd-promo-przd-counter>
        {showDate && isExpired && (
          <>
            <div className={`${prizeDropPromoCSSNS}-counter-text`}>{t(Promotions.PrizeDrop.endedOnText)}</div>
            <div className={`${prizeDropPromoCSSNS}-counter-ended-text`}>
              {formatDateWithOffset('DD.MM.YYYY HH:mm:ss', date)}
            </div>
          </>
        )}

        {(!isExpired || slotStatus === 'soon') && (
          <>
            {(!isExpired || slotStatus === 'soon') && (
              <div className={`${prizeDropPromoCSSNS}-counter-text`}>{text}</div>
            )}
            <egtd-promo-przd-counter-display>
              {timer?.days !== undefined && (
                <PrizeDropPromoTimeCounterItem
                  date={addLeadingZero(timer.days > 0 ? timer.days : 0)}
                  text={t(Promotions.PrizeDrop.days)}
                />
              )}
              {timer?.hours !== undefined && (
                <PrizeDropPromoTimeCounterItem
                  date={addLeadingZero(timer.hours > 0 ? timer.hours : 0)}
                  text={t(Promotions.PrizeDrop.hours)}
                />
              )}
              {timer?.minutes !== undefined && (
                <PrizeDropPromoTimeCounterItem
                  date={addLeadingZero(timer.minutes > 0 ? timer.minutes : 0)}
                  text={t(Promotions.PrizeDrop.minutes)}
                />
              )}
              {timer?.seconds !== undefined && (
                <PrizeDropPromoTimeCounterItem
                  date={addLeadingZero(timer.seconds > 0 ? timer.seconds : 0)}
                  text={t(Promotions.PrizeDrop.seconds)}
                />
              )}
            </egtd-promo-przd-counter-display>
          </>
        )}

        {children}
      </egtd-promo-przd-counter>
    </Flex.Col>
  );
};

export default PrizeDropPromoTimeCounter;
