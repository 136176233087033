import React from 'react';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';

interface CardDescriptionProps {
  text: string;
  color: string;
}

const CardDescription: React.FC<CardDescriptionProps> = ({ text, color }) => {
  return (
    <div className={`${cssGlobalNS}-c-prm-lobby-card__description`} style={{ color: color }}>
      {text}
    </div>
  );
};

export default CardDescription;
