import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { promoWidgetCellByLayout } from '../../promoWidgetConstants';
import { PromoWidgetsTypes } from '../../promoWidgetTypes';

interface Props {
  promoItem: PromoWidgetsTypes.PromoItem;
}

const PromoWidgetCell: React.FC<Props> = ({ promoItem }) => {
  const navigate = useNavigate();

  const openPromotionHandler = () => {
    if (promoItem.categoryAlias && promoItem.alias) {
      navigate(`/promotions/${promoItem.categoryAlias}/${promoItem.alias.toLowerCase()}?tab=conditions`);
    }
  };

  const PromoCell = promoWidgetCellByLayout[promoItem.contentLayout];
  const renderPromoCells = useCallback(() => {
    if (!PromoCell) {
      return <></>;
    }

    return <PromoCell promoItem={promoItem} openPromotion={openPromotionHandler} />;
  }, []);

  return renderPromoCells();
};

export default PromoWidgetCell;
