import React from 'react';
import clsx from 'clsx';
import { classNameUtils } from '../utils/className.utils';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  text?: string | null;
  children?: React.ReactNode;
  className?: string;
}

const NotificationPopupBody: React.FC<Props> = ({ text, children, className, ...rest }) => {
  const classProp = clsx(classNameUtils.body, className);

  return (
    <div className={classProp} {...rest}>
      {text && <div className={`${classNameUtils.body}-title`}>{text}</div>}
      {children}
    </div>
  );
};

export default NotificationPopupBody;
