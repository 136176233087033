import React from 'react';
import { TAG_STYLE_CONFIG } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/gameCellTagsConstants';
import { GameTag } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/GameCellTagsTypes';
import CurrencySwapper from 'modules/casino/shared/components/common/CurrencySwapper';
import { Flex } from 'modules/casino/shared/components/layout';
import JackpotOdometer from 'modules/casino/shared/features/jackpots/jackpotOdometer/JackpotOdometer';
import { jackpotSelectors } from 'modules/casino/shared/features/jackpots/jackpotSlice';
import { useCurrency } from 'modules/casino/shared/hooks/useCurrency';
import { useCurrencyConfig } from 'modules/casino/shared/hooks/useCurrencyConfig';
import { useAppSelector } from 'store';
import { RootState } from 'store/rootReducer';

interface Props {
  jackpotId: string;
  tag: GameTag;
}

export const JackpotTag: React.FC<Props> = ({ tag, jackpotId }) => {
  const { formatCurrency } = useCurrency();
  const currency = useAppSelector((state: RootState) => jackpotSelectors.selectJackpotCurrencySign(state, jackpotId));
  const isInitialJackpotsFetched = useAppSelector(jackpotSelectors.selectInitialJackpotsFetched);
  const { currencySymbolFirst, spaceBetween } = useCurrencyConfig();

  if (!jackpotId) {
    return <></>;
  }

  const { type, background, textColor, triangleColor } = tag;
  const { hideTriangle, hideBg } = TAG_STYLE_CONFIG[type];
  const symbol = formatCurrency(currency);

  const backgroundColor = !background
    ? 'transparent'
    : background[1] !== background[0]
      ? `linear-gradient( 0.25turn, ${background[0]}, ${background[1]})`
      : background[0];

  const tagStyle = hideBg
    ? ''
    : {
        background: backgroundColor,
        color: textColor,
      };

  const Jackpot = () =>
    isInitialJackpotsFetched ? (
      <Flex className="jackpot-tag">
        <CurrencySwapper
          sumComponent={<JackpotOdometer jackpotId={jackpotId} />}
          currencyComponent={<span className="jackpot-tag--currency">{symbol}</span>}
          currencySymbolFirst={currencySymbolFirst}
          spaceBetween={spaceBetween}
        />
      </Flex>
    ) : (
      <></>
    );

  return hideTriangle ? (
    <Flex.Center className="cell-tags__wrapper cell-tags__tag" style={{ ...tagStyle }}>
      <Jackpot />
    </Flex.Center>
  ) : (
    <div className={`cell-tags__wrapper`}>
      <Flex.Center className="cell-tags__tag" style={{ ...tagStyle }}>
        <Jackpot />
      </Flex.Center>
      <div className="cell-tags__triangle" style={triangleColor ? { borderTopColor: triangleColor } : {}} />
    </div>
  );
};

JackpotTag.displayName = 'JackpotTag';
