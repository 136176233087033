import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Icon, Text } from 'components/shared';
import { Badge } from 'components/shared/badges';
import { Checkbox } from 'components/shared/checkbox';
import { ExpandableIcon } from 'components/shared/icons';
import { Flex } from 'components/shared/layout';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import HeaderIcon from './HeaderIcon';

interface HeaderProps {
  icon?: string;
  title: string;
  subTitle?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onBackClick?: React.MouseEventHandler<HTMLDivElement>;
  showBackButton?: boolean;
  isRead?: boolean;
  badgeCount?: number;
  onSelect?: React.MouseEventHandler<HTMLDivElement>;
  isChecked?: boolean;
  useIconPath?: boolean;
  dataQid?: string;
  expandable?: boolean;
  isExpanded?: boolean;
  disableBg?: boolean;
  className?: string;
  hasIndicator?: boolean;
  showChevron?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  icon,
  title,
  subTitle,
  onClick,
  onBackClick,
  showBackButton,
  isRead,
  badgeCount,
  onSelect,
  isChecked,
  useIconPath = true,
  dataQid,
  expandable = false,
  isExpanded = false,
  disableBg,
  className,
  hasIndicator,
  showChevron = false,
}) => {
  const { t } = useTranslation();
  const itemStyles = clsx(
    'mpd-cta-header',
    disableBg && 'mpd-cta-header--no-bg',
    !isRead && 'mpd-cta-header--new',
    expandable && 'cursor-pointer',
    className && className,
  );
  const titleStyles = clsx('mpd-cta-header__title-wrapper', onClick && 'mpd-cta-header-link');

  return (
    <Flex.ACJB className={itemStyles} data-qid={dataQid}>
      {hasIndicator && (
        <span className="mpd-cta-header__indicator mr-2">
          <svg className="icon" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" />
          </svg>
        </span>
      )}
      <Flex.AC onClick={onClick} className={titleStyles}>
        {icon && <HeaderIcon useIconPath={useIconPath} icon={icon} />}
        <Flex.Col>
          <Text className="mpd-cta-header__title" text={title} />
          {subTitle && <Text className="mpd-cta-header__subtitle" text={subTitle} />}
        </Flex.Col>
        <Flex.ACJE className={showChevron ? '' : 'ml-auto'}>
          {!!badgeCount && badgeCount > 0 && (
            <Badge
              variant="circle"
              className={showChevron ? 'ml-2 badge-warning' : 'ml-auto badge-warning'}
              label={badgeCount.toString()}
            />
          )}
          {expandable && !isRead && <div className="mpd-cta-header__new">{t(MyProfile.OffersTab.new)}</div>}
          {expandable && (
            <ExpandableIcon className="pl-0 ml-2 mpd-cta-header__back-link" isExpanded={isExpanded ?? false} />
          )}
        </Flex.ACJE>
        {showChevron && <Icon icon={'chevron-right'} size={13} className="ml-auto" />}
      </Flex.AC>
      {showBackButton && (
        <Flex.AC onClick={onBackClick} className="mpd-cta-header__back-link">
          <Icon className="mpd-cta-header__back-icon rotated-90" icon="chevron" />
          {t(MyProfile.OffersTab.OfferItemBackBtn)}
        </Flex.AC>
      )}
      {onSelect && (
        <Flex.AC className="ml-2 mr-2">
          <div onClick={onSelect}>
            <Checkbox checked={isChecked} checkedVariant="success" />
          </div>
        </Flex.AC>
      )}
    </Flex.ACJB>
  );
};

export default Header;
