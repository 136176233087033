import { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMarketingKeys } from 'hooks/common/useMarketingKeys';
import { useAppSelector } from 'store';
import { setZopimContent } from './utils';
import { setIsOpenChat } from '../features/general/slice/general.slice';
import { selectMyProfile } from '../features/myProfile/slice/myProfile.slice';

declare const window: Window &
  typeof globalThis & {
    $zopim: any;
  };

interface Props {
  closeChatWindow: boolean;
  onChatLoaded: () => void;
}

const ZopimChat: React.FC<Props> = ({ closeChatWindow, onChatLoaded }) => {
  const { ZOPIM_ENV_KEY } = useMarketingKeys();
  const userProfile = useAppSelector(selectMyProfile.dataUser);
  const firstInitMobile = useRef(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!window.$zopim) {
      (function (d, s) {
        const z: any = (window.$zopim = function (c) {
          z._.push(c);
        });
        const $ = (z.s = d.createElement(s)),
          e = d.getElementsByTagName(s)[0];
        z.set = function (o) {
          z.set._.push(o);
        };
        z._ = [];
        z.set._ = [];
        $['async'] = !0;
        $.setAttribute('charset', 'utf-8');
        $['src'] = 'https://v2.zopim.com/?' + ZOPIM_ENV_KEY;
        z.t = +new Date();
        $['type'] = 'text/javascript';
        e.parentNode && e.parentNode.insertBefore($, e);
      })(document, 'script');

      window.$zopim(() => {
        if (typeof window.$zopim.livechat == 'object') {
          setZopimContent(t);
          window.$zopim.livechat.window.onHide(() => {
            closeChatWindow && window.$zopim.livechat.hideAll();
            dispatch(setIsOpenChat(false));
          });
          if (isMobile) {
            window.$zopim.livechat.setOnConnected(() => {
              if (firstInitMobile.current) {
                window.$zopim.livechat.setName(userProfile?.fullName || '');
                window.$zopim.livechat.setEmail(userProfile?.email || '');
                window.$zopim.livechat.window.show();
                firstInitMobile.current = false;
                onChatLoaded();
              }
            });
          } else {
            window.$zopim.livechat.setName(userProfile?.fullName || '');
            window.$zopim.livechat.setEmail(userProfile?.email || '');
            window.$zopim.livechat && window.$zopim.livechat.window.show();
          }
        }
      });
    } else {
      if (typeof window.$zopim.livechat == 'object') {
        window.$zopim.livechat.setName(userProfile?.fullName || '');
        window.$zopim.livechat.setEmail(userProfile?.email || '');
        window.$zopim.livechat.window.show();
      }
    }
  }, []);

  return null;
};
export default ZopimChat;
