import { css } from 'styled-components';

export const handleAudio = (url: string) => {
  const audioFile = new Audio(url);
  audioFile.play();
};

export const gamificationGenerateGradient = (color: string) => {
  return css`
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      ${color} 40%,
      ${color} 60%,
      rgba(255, 255, 255, 0) 100%
    );
  `;
};
