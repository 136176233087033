import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { RootState } from 'store/rootReducer';
import { useGameCollectionId } from './useGameCollectionId';
import { CategoryLayoutTypes } from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { favoriteGamesSelectors } from '../additionalGames/favourites/slice/casinoFavouriteGamesSlice';
import { GamesSliceTypes } from '../constants/casinoGamesConstants';
import { gamesSelectors } from '../slice/casinoGamesSlice';
import { getGamesSliceType } from '../utils/casinoGamesUtils';

export const useCategoryTotalElements = ({
  categoryLayoutType,
  categoryId,
}: {
  categoryLayoutType: CategoryLayoutTypes;
  categoryId: string;
}) => {
  const gameSliceType = getGamesSliceType(categoryLayoutType);
  const { gameCollectionId } = useGameCollectionId({ categoryId, categoryLayoutType });

  const selectorMap = useMemo(() => {
    return {
      [GamesSliceTypes.FAVOURITES]: (state: RootState) => favoriteGamesSelectors.favoriteGamesCount(state),
      [GamesSliceTypes.GENERAL]: (state: RootState, collectionId: string) =>
        gamesSelectors.categoryGamesCount(state, collectionId),
    };
  }, []);
  const totalElements = useAppSelector((state) => selectorMap[gameSliceType](state, gameCollectionId));

  return totalElements;
};
