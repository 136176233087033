import React, { memo, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { cdnPaths, cdnUrl, uiCSSPrefix } from '../@types/ui.types';

// Component CSS NameSpace
const cssNS = `${uiCSSPrefix}-flag`;

interface Props {
  className?: string;
  flag: string | null;
  variant?: '4x3' | '1x1';
  isCircle?: boolean;
  size?: number;
}

const Flag: React.FC<Props> = ({ className, flag, variant = '4x3', isCircle, size = 14 }) => {
  const path = cdnUrl + cdnPaths.FLAGS;

  const imgSources = useMemo(
    () => [
      isCircle
        ? `${path + '/1x1/' + flag?.toLowerCase()}.svg`
        : `${path + '/' + variant + '/' + flag?.toLowerCase()}.svg`,
    ],
    [flag, variant, isCircle, path],
  );

  const [srcState, setSrcState] = useState({ index: 0, src: imgSources[0] });

  useEffect(() => {
    setSrcState({ index: 0, src: imgSources[0] });
  }, [flag, imgSources]);

  const onError = () => {
    const newIndex = srcState.index + 1;
    if (imgSources.length > newIndex) {
      const newSourceState = { index: newIndex, src: imgSources[newIndex] };
      setSrcState(newSourceState);
    }
  };

  const imgClass = clsx(cssNS, isCircle && 'rounded-circle', className);

  if (!flag) return null;

  return (
    <img className={imgClass} width={isCircle ? size : 'auto'} height={size} src={srcState.src} onError={onError} />
  );
};

export default memo(Flag);
