import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'react-tabs';
import { UI } from 'components/shared/UI';
import {
  selectAccountTabs,
  setMPDSectionActiveTab,
  setMyAccountTabs,
} from 'pages/myAccount/slice/mpdAccountTabs.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { fetchCategories, selectOffersTab } from './tabs';
import { selectMyChallenges } from './tabs/captainUpTab/slice/clubTab.slice';
import { selectNotification } from './tabs/notificationsTab/notificationSlice';
import { renderAccountTab } from './tabs/RenderAccountTab';
//import ChatButton from '../../../chat/ChatButton';
import { useGamificationWidgetConfig } from '../../integrations/gamification/hooks/useGamificationWidgetConfig';
import { selectIntegrations } from '../../integrations/slice/integrations.slice';
import { MPDUI } from '../components/MPDUI';
import { useMyProfileConfig } from '../hooks/useMyProfileConfig';
import { MyProfileTabsEnum } from '../types/myProfile.types';
import { accTabsWithBadge, detectIOS } from '../utils/myProfile.utils';

interface Props {
  parentRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const notificationsSelectorMap = {
  offers: selectOffersTab,
  notifications: selectNotification,
  captainUp: selectMyChallenges,
  captainUpWidget: selectIntegrations,
};

const MyProfileAccountSection: React.FC<Props> = ({ parentRef }) => {
  const activeTabIndex = useAppSelector(selectAccountTabs.mpdAccountActiveTabIndex);
  const mpdAccountTabs = useAppSelector(selectAccountTabs.mpdAccountTabs);
  const { isWidget } = useGamificationWidgetConfig();
  const dispatch = useAppDispatch();
  const [isClubTab, setIsClubTab] = useState(false);
  const { t } = useTranslation();

  const navigationConfigNew = useMyProfileConfig()?.navigationTabs;
  const isIOS = detectIOS();

  useEffect(() => {
    if (isEmpty(mpdAccountTabs)) {
      navigationConfigNew && dispatch(setMyAccountTabs(navigationConfigNew));
    }
  }, [navigationConfigNew, dispatch, mpdAccountTabs]);

  const containerStyle = `mpd-section__tab-panel no-scrollbars ${
    isIOS && !isClubTab && 'mpd-section__tab-panel--on-ios'
  } ${isClubTab && 'h-100'}
  `;

  const badgeSelector = (tab) => {
    if (tab?.hasBadge && accTabsWithBadge.includes(tab.key)) {
      // !TODO GAMIFICATION fix
      if (isWidget && tab.key === 'captainUp') {
        return notificationsSelectorMap['captainUpWidget'].getGamificationMyChallengesCount;
      } else {
        return notificationsSelectorMap[tab.key].notificationCounter;
      }
    }
  };

  const setActivetab = (tabIndex: number) => {
    dispatch(setMPDSectionActiveTab({ tabKey: mpdAccountTabs[tabIndex].key }));
    mpdAccountTabs[tabIndex].key === MyProfileTabsEnum.OFFERS && dispatch(fetchCategories());
    setIsClubTab(mpdAccountTabs[tabIndex].key === MyProfileTabsEnum.CLUB);
  };
  const hideTabs = useMemo(() => mpdAccountTabs.every((item) => item?.hideTab), [mpdAccountTabs]);
  return (
    <MPDUI.Section section="account">
      <Tabs
        className="d-flex-col mpd-section__tabs-wrapper h-100"
        selectedIndex={activeTabIndex}
        onSelect={setActivetab}
      >
        {/* <TabsUI.ListWrapper>
          <TabList className="react-tabs__tab-list justify-content-start">
            {mpdAccountTabs?.map((tab) => (
              <Tab {...(tab?.hideTab ? { className: 'd-none' } : {})} key={tab.key} data-qid={tab.key}>
                {t(tab.label)}
                {tab?.hasBadge && accTabsWithBadge.includes(tab.key) && <AccountTabBadge tabKey={tab.key} />}
              </Tab>
            ))}
          </TabList>
        </TabsUI.ListWrapper> */}
        {!hideTabs && (
          <UI.Tabs.List setMobilePadding location="myAccount" type="secondary" hasScroll>
            {mpdAccountTabs?.map((tab, index) => (
              <UI.Tabs.Tab
                key={tab.key}
                data-qid={tab.key}
                type="secondary"
                isActive={index === activeTabIndex}
                title={t(tab.label)}
                handleClick={() => setActivetab(index)}
                {...(tab?.hideTab ? { className: 'd-none' } : {})}
                badgeLabel={tab?.hasBadge && accTabsWithBadge.includes(tab.key) ? tab.key : ''}
                badgeSelector={
                  badgeSelector(tab)
                  // tab?.hasBadge &&
                  // accTabsWithBadge.includes(tab.key) &&
                  // notificationsSelectorMap[tab.key].getGamificationMyChallengesCount
                }
                location="myAccount"
              ></UI.Tabs.Tab>
            ))}
          </UI.Tabs.List>
        )}
        <div className={containerStyle}>
          {mpdAccountTabs?.map(({ key, subMenu }) => renderAccountTab({ key, parentRef, subMenu }))}
        </div>
      </Tabs>
      {/* <UI.Tabs.Wrapper
        className="d-flex-col mpd-section__tabs-wrapper h-100"
        selectedIndex={activeTabIndex}
        onSelect={setActivetab}
        hasScroll
        location="myAccount"
      >
        <UI.Tabs.List setMobilePadding location="myAccount">
          {mpdAccountTabs?.map((tab, index) => (
            <UI.Tabs.Tab
              key={tab.key}
              data-qid={tab.key}
              isActive={index === activeTabIndex}
              title={t(tab.label)}
              handleClick={() => setActivetab(index)}
              {...(tab?.hideTab ? { className: 'd-none' } : {})}
              badgeLabel={tab?.hasBadge && accTabsWithBadge.includes(tab.key) ? tab.key : ''}
              badgeSelector={
                tab?.hasBadge &&
                accTabsWithBadge.includes(tab.key) &&
                notificationsSelectorMap[tab.key].notificationCounter
              }
            ></UI.Tabs.Tab>
          ))}
        </UI.Tabs.List>
        <div className={containerStyle}>
          {mpdAccountTabs?.map(({ key, subMenu }) => renderAccountTab({ key, parentRef, subMenu }))}
        </div>
      </UI.Tabs.Wrapper> */}
      {/* <ChatButton /> */}
    </MPDUI.Section>
  );
};

export default memo(MyProfileAccountSection);
