import { useEffect } from 'react';
import { gamesSelectors } from 'modules/casino/modules/container/cellsContainer/cellls/slice/casinoGamesSlice';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { triggerGTMEvent } from 'modules/casino/shared/features/seo/gtm/GoogleTagManager';
import useCategoryJackpot from 'modules/casino/shared/hooks/useCategoryJackpot';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import useSectionBackground from 'modules/casino/shared/hooks/useSectionBackground';
import { useAppSelector } from 'store';
import { useCasinoGamesFetchStatus } from '../../../../cellls/hooks/useCasinoGamesFetchStatus';

type Props = {
  categoryIdCacheKey: string;
  categoryLayoutType: CategoryLayoutTypes;
  googleTagEvent?: string;
};

export const useLandingCasinoCategory = ({ categoryIdCacheKey, categoryLayoutType, googleTagEvent }: Props) => {
  const { isLoading } = useCasinoGamesFetchStatus(categoryIdCacheKey, categoryLayoutType);
  const { jackpot, backgroundLocation, alias } = useGetCategoryParams(categoryIdCacheKey, categoryLayoutType);
  const { jackpotDetails, jackpotId } = useCategoryJackpot(jackpot);
  const jackpotTemplateClass = jackpotDetails ? jackpotDetails?.template : alias;
  const [sectionBackground] = useSectionBackground(backgroundLocation, 'Lobby');
  const openGameId = useAppSelector((state) => gamesSelectors.gamesOpenId(state));
  useEffect(() => {
    if (openGameId && googleTagEvent) {
      triggerGTMEvent({ eventKey: googleTagEvent });
    }
  }, [openGameId]);

  return {
    showBackground: backgroundLocation,
    backgroundImage: sectionBackground,
    jackpotId,
    jackpotDetails,
    jackpotTemplateClass,
    isLoading,
  };
};
