import { useEffect, useState } from 'react';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';

import {
  printCashierCancelTicketMobile,
  printCashierPayoutMobile,
} from 'modules/retail/modules/cashier/printMethods/cashierMobilePrint.utils';
import {
  printCashierCancelTicket,
  printCashierClaimForm,
  printCashierPayout,
} from 'modules/retail/modules/cashier/printMethods/cashierPrint.utils';
import { seTicketIdForDuplicateInBetSlip, ticketInfoThunk } from 'modules/retail/shared/slice/checkTicket.slice';

import userStorage from 'pages/auth/login/UserStorage';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { useAppDispatch } from 'store';
import { isEmpty, isHandheld } from 'utils/common/helpersCommon';
import { actionButtonEnums } from '../cashierModalUI/actionButtonsMock';
import { useCashierTicketStatusInfo } from '../features/cashier-checkTicket/useCashierTicketStatusInfo';
import { clearGetInfoPlayer, clearSaveInfoPlayer } from '../features/UCNForm/slice/playerInfo.slice';
import { cashierThunks, resetCashierCancelTicketStatus, resetPayoutTicketStatus } from '../slice/cashier.slice';
import { PrintCashierPayout } from '../types/cashier.types';

export const useTicketActions = (
  clickCount,
  setClickCount,
  buttonId,
  setActionButtonClicked,
  setSeconds,
  actionButtonClicked,
  ticketInfo,
  setActiveButtons,
  inputValueUCN,
) => {
  const dispatch = useAppDispatch();
  const { payoutTicketStatus, cancelTicketStatus, ticketInfoStatus, savePlayerInfoStatus, savePlayerInfoData } =
    useCashierTicketStatusInfo();

  const userCurr = userStorage.getUserCurrency();
  const currency = userCurr || '';
  const [isDuplicate, setDuplicate] = useState(false);
  const retailAddress = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.RETAIL_ADDRESS,
    deviceLayout: 'general',
  });
  const isCurrencyBeforeAmount = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.CURRENCY_BEFORE_AMOUNT,
    deviceLayout: 'general',
  });
  const isBetAmountVisible = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.IS_BET_AMOUNT_VISIBLE,
    deviceLayout: 'general',
  });

  const isError =
    payoutTicketStatus === 'failure' || cancelTicketStatus === 'failure' || ticketInfoStatus === 'failure';
  const { closeModal } = useHandleModals();

  useEffect(() => {
    if (isEmpty(ticketInfo)) {
      // Reset the button countdown if a new ticket is scanned while the previous countdown is still running
      setClickCount(0);
      setActionButtonClicked(null);
      setSeconds(0);
    }
    if (ticketInfoStatus === 'failure') {
      setClickCount(0);
      setActionButtonClicked(null);
      setSeconds(0);
      setActiveButtons([]);
    }
  }, [ticketInfo, ticketInfoStatus]);

  useEffect(() => {
    if (clickCount === 2) {
      if (buttonId === actionButtonEnums.payTicketButton && actionButtonClicked === actionButtonEnums.payTicketButton) {
        if (ticketInfo?.isClaimForm) {
          dispatch(cashierThunks.claimBankPayout({ code: ticketInfo?.ticket?.ticketId }));
        } else {
          dispatch(cashierThunks.payoutTicket({ code: ticketInfo?.ticket?.ticketId, ucn: Number(inputValueUCN) }));
        }
      }
      if (
        buttonId === actionButtonEnums.cancelTicket &&
        actionButtonClicked === actionButtonEnums.cancelTicket &&
        !isEmpty(ticketInfo)
      ) {
        dispatch(cashierThunks.cancelTicket({ code: ticketInfo.ticket.ticketId, reason: 'no reason' }));
      }
      if (
        buttonId === actionButtonEnums.duplicateTicket &&
        actionButtonClicked === actionButtonEnums.duplicateTicket &&
        !isEmpty(ticketInfo)
      ) {
        setDuplicate(true);
      }
    }
  }, [clickCount]);

  useEffect(() => {
    if (!isEmpty(ticketInfo)) {
      if (ticketInfo?.status === 'CANCELLED' || ticketInfo?.status === 'PAID' || isError) {
        setClickCount(0);
        setActionButtonClicked(null);
        setSeconds(0);
      }
      if (isDuplicate) {
        setClickCount(0);
        setActionButtonClicked(null);
        setSeconds(0);
        closeModal();
        dispatch(seTicketIdForDuplicateInBetSlip());
      }
    }
  }, [isError, ticketInfo, isDuplicate]);

  useEffect(() => {
    if (payoutTicketStatus === 'succeeded') {
      dispatch(ticketInfoThunk.checkTicket({ ticketId: ticketInfo?.ticket?.ticketId }));
      if (ticketInfo?.isClaimForm) {
        const printCashierClaimFormObj = {
          totalWin: ticketInfo?.totalAmount,
          stake: ticketInfo?.ticket?.totalStake,
          claimNumber: ticketInfo?.claimNumber,
          currency,
          retailAddress,
          isClaimForm: ticketInfo?.isClaimForm,
          pinNumber: ticketInfo?.pin,
          paymentEndDate: ticketInfo?.paymentEndDate,
          posName: ticketInfo?.posName,
          address: ticketInfo?.address,
          isCurrencyBeforeAmount,
          isBetAmountVisible: isBetAmountVisible ?? false,
        };
        printCashierClaimForm(printCashierClaimFormObj);
      } else {
        const printCashierPayoutObj: PrintCashierPayout = {
          totalWin: ticketInfo?.totalAmount,
          code: ticketInfo?.ticket?.ticketId,
          currency,
          retailAddress,
          isDuplicate: false,
          isClaimForm: ticketInfo?.isClaimForm,
          dailySequenceNumber: ticketInfo?.dailySequenceNumber,
          pinNumber: ticketInfo?.pin,
          totalStake: ticketInfo?.ticket?.totalStake,
          posName: ticketInfo?.posName,
          posAddress: ticketInfo?.address,
          betTax: ticketInfo?.tax,
          totalAmountAfterTax: ticketInfo?.totalAmountAfterTax,
          isCurrencyBeforeAmount,
        };
        if (isHandheld()) {
          printCashierPayoutMobile(printCashierPayoutObj);
        } else {
          printCashierPayout(printCashierPayoutObj);
        }
      }

      setActionButtonClicked(null);
      setActiveButtons([]);
      setSeconds(0);
      dispatch(resetPayoutTicketStatus());
      dispatch(clearSaveInfoPlayer());
      dispatch(clearGetInfoPlayer());
    }
  }, [payoutTicketStatus]);

  useEffect(() => {
    const printCashierCancelTicketObj = {
      stake: ticketInfo?.totalStake,
      code: ticketInfo?.ticket?.ticketId,
      currency,
      retailAddress,
      isDuplicate: false,
      posName: ticketInfo?.posName,
      posAddress: ticketInfo?.address,
      isCurrencyBeforeAmount,
    };
    if (cancelTicketStatus === 'succeeded') {
      if (isHandheld()) {
        printCashierCancelTicketMobile(printCashierCancelTicketObj);
      } else {
        printCashierCancelTicket(printCashierCancelTicketObj);
      }

      dispatch(ticketInfoThunk.checkTicket({ ticketId: ticketInfo?.ticket?.ticketId }));
      setActionButtonClicked(null);
      setActiveButtons([]);
      setSeconds(0);
      dispatch(resetCashierCancelTicketStatus());
    }
  }, [cancelTicketStatus]);

  useEffect(() => {
    if (!isEmpty(savePlayerInfoData) && savePlayerInfoData?.statusCode === 200) {
      setSeconds(5);
      setActiveButtons([3]);
      setActionButtonClicked(3);
    }
  }, [savePlayerInfoStatus]);
};
