import { AxiosResponse } from 'axios';
import { FormFieldTypes, FieldValues } from 'components/shared/forms/formTypes';
import { MyAccount } from 'pages/myAccount/types/myAccount.types';
import { CreateThunk } from 'store/thunkCreators';

export enum FormSteps {
  PRESTEP = 0,
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
}

export const FieldsTransformMap: Record<string, string> = {
  countryId: 'countryName',
  genderId: 'gender',
  currencyId: 'currencyName',
  birthDate: 'birthday',
  birthdate: 'birthday',
};

export declare module RegisterTypes {
  type State = {
    isLoading: boolean;
    isSubmitting: MyAccount.StateStatus;
    fetchFormFieldsError: string;
    fields: {
      steps?: Record<number, FormFieldTypes[]>;
    };
    stepData: Record<number, FieldValues>;
    activeStep: number;
    totalSteps: number;
    newUser: NewUser;
    newUserError: null | string;
    helpMenu: HelpMenuItems[];
    dropdowns: Record<string, DropdownType[]>;
    isFetchingDropdowns: boolean;
    withSocial: string | null;
  };

  type Thunks = {
    createPlayer: CreateThunk<void, RegisterTypes.NewUser>;
    fetchFormFields: CreateThunk<void, FormFieldsResponse>;
    fetchDropdown: CreateThunk<{ apiUrl: string; name: string }, DropdownType[]>;
    fetchHelpMenu: CreateThunk<void, RegisterTypes.HelpMenuItems[]>;
  };

  type NewUser = {
    email?: string;
    id?: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    city: string;
    country: {
      name: string;
    };
    address: string;
  } | null;

  type DropdownType = {
    text: string;
    value: string;
  };

  type Currency = {
    name: string;
    symbol: string;
    text: string;
    value: string;
  };

  type FormFieldsResponse = {
    steps?: Record<string, FormFieldTypes[]>;
  };
  type HelpMenuItems = {
    icon: 'chat' | 'info-solid' | 'envelope';
    name: string;
    route: string;
    routeType?: 'external';
    type: 'mail' | 'route';
  };

  type UcnParams = {
    genderId: number;
    countryId: string | undefined;
    birthdate: Date;
    ucn: string;
  };

  type ValidationRequestResponse = AxiosResponse<string & { message: string }>;
}
