import isEqual from 'lodash/isEqual';
import { Achievements } from '../types/achievements.types';

export const normalizeAchievements = (currAchievements = {}, achievements: Achievements.Item[]): void => {
  achievements?.forEach((achievement) => {
    const existingAchievement = currAchievements[achievement?.id];

    if (isEqual(existingAchievement, achievement)) return;

    currAchievements[achievement?.id] = achievement;
  });
};
