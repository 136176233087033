import React from 'react';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import { cdnPaths, cdnUrl } from './SVGCDN.types';

/** SVGCDN */
interface SVGCDNProps {
  className?: string;
  size?: number;
  path?: keyof typeof cdnPaths;
  icon?: string;
  iconPath?: string;
  srcSuffix?: string;
  id?: string;
  title?: string;
}

const SVGCDN: React.FC<SVGCDNProps> = ({ className, size, path, icon, srcSuffix = '', id, title }) => {
  const iconSrc = path ? cdnUrl + cdnPaths[path] + icon + '.svg' + srcSuffix : cdnUrl + '/' + icon;

  const svgClass = clsx(className);

  return (
    <SVG
      className={svgClass}
      width={size}
      height={size}
      src={iconSrc}
      id={id}
      title={title}
      cacheRequests={true}
      loader={<span className={svgClass}></span>}
    >
      <span className={svgClass}></span>
    </SVG>
  );
};

export default SVGCDN;
