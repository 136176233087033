import React, { memo } from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';
import ScrollContainer, { arrowStyleTypes, handleScrollMoveWith } from 'components/shared/ScrollContainer';
import { General } from 'shared/common/features/general/types/generalSlice.types';
import { Subvertical, Vertical } from 'shared/common/features/navigationMenu/types/navigationMenu.types';
import { isEmpty } from 'utils/common/helpersCommon';
import CustomSubNavLink from './CustomSubNavLink';
type Align = 'left' | 'center' | 'right';
const AlignClassNameMap: Record<Align, string> = {
  left: 'mr-auto  head__items-left',
  center: 'mx-auto  head__items-center',
  right: 'ml-auto head__items-right ',
};
interface Props {
  verticals: (Vertical | Subvertical)[];
  align?: Align;
  layout: General.HeaderWrapperConfig['layoutType'];
}

export const NavSubHeader: React.FC<Props> = ({ verticals, align = 'center', layout }) => {
  const classes = clsx('head__items overflow-x-auto custom-scrollbars', AlignClassNameMap[align]);

  const scrollItemWidth = 65;
  const itemsToScroll = 3;
  const { moveWith } = handleScrollMoveWith({ itemsToScroll, itemWidth: scrollItemWidth });

  const filteredItems = align
    ? verticals?.filter((item) => {
        return item?.align === align;
      })
    : verticals;

  if (isEmpty(filteredItems)) return null;

  const itemLength = Number(filteredItems.length);
  const hasScroll = itemLength > 4 && layout === 'layout_2' && align === 'center';
  const hasLeftScroll = itemLength > 6 && layout === 'layout_2' && align === 'left';

  return (
    <>
      {hasScroll || hasLeftScroll ? (
        <ScrollContainer
          moveWith={moveWith}
          typeArrow={arrowStyleTypes.ArrowsAlwaysVisible}
          classNames={hasLeftScroll ? `d-flex ` : 'd-flex head__items mx-auto head__items-center'}
          wrapperClassName={hasLeftScroll ? 'head__items head__items-left' : ''}
        >
          {filteredItems.map((item: Vertical | Subvertical, index: number) => (
            <CustomSubNavLink key={index} subverticalsItem={item} />
          ))}
        </ScrollContainer>
      ) : (
        <Flex className={classes}>
          {filteredItems.map((item: Vertical | Subvertical, index: number) => (
            <CustomSubNavLink key={index} subverticalsItem={item} />
          ))}
        </Flex>
      )}
    </>
  );
};

export default memo(NavSubHeader);
