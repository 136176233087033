import { CasinoGamesTypes } from 'modules/casino/modules/container/cellsContainer/cellls/types/casinoGamesTypes';
import { useGameTags } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/hooks/useGameTags';
import { LayoutWrapperTypes } from '../../layoutWrapperTypes';

export const useDefaultWrapper = ({
  game,
}: {
  game: CasinoGamesTypes.GameProps;
}): LayoutWrapperTypes.UseDefaultWrapperReturnTypes => {
  const { bottomTag, hasTranspBottomTag } = useGameTags(game);

  const gameData: LayoutWrapperTypes.GameData = {
    description: game?.description,
    jackpotIdAsString: game?.jackpotId,
  };

  return {
    bottomTag,
    gameData,
    hasTranspBottomTag,
  };
};
