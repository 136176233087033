import { useRef } from 'react';
import { motion } from 'framer-motion';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { ExpandableIcon } from 'components/shared/icons';
import { usePreloadImage } from 'hooks/common/usePreloadImage';
import { NotificationTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationTypes';
import { constructAnimationImage } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationUtils';
import ParseHTML from 'shared/common/ParseHTML';
import { Breakpoints } from 'theme/Theme';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import { createBonusItemAnimation } from '../../../utils/createBonus.utils';

export const SelectBonusSlideItem = ({
  item,
  type,
  onSelectStep,
  isLast,
  animationParams,
  categoryIndex,

  onSelectBonus,
  onSelectTerms,
  activeBonus,
  offerTerms,
  isActiveStep,
  style,
  activeTerms,
}: {
  item: NotificationTypes.GenerateBonusType | NotificationTypes.GenerateLastBonusType;
  // item?: NotificationTypes.GenerateBonusType | NotificationTypes.Bonus;
  type: string;
  animationParams: NotificationTypes.GamificationModalParams;
  onSelectStep?: (item: NotificationTypes.GenerateBonusType, type: string, isLast: boolean, index: number) => void;
  isLast: boolean;
  categoryIndex: number;
  activeBonus?: NotificationTypes.GenerateBonusType | NotificationTypes.GenerateLastBonusType;
  offerTerms?: { text?: string } | null;
  onSelectBonus?: (bonus: NotificationTypes.GenerateLastBonusType) => void;
  onSelectTerms?: (bonus: NotificationTypes.GenerateLastBonusType) => void;
  isActiveStep?: boolean;
  style?: NotificationTypes.NotificationStyle['awardsColors'];
  activeTerms?: NotificationTypes.GenerateLastBonusType;
}) => {
  const isActive =
    activeBonus &&
    (activeBonus as NotificationTypes.GenerateLastBonusType)?.groupConditionId ===
      (item as NotificationTypes.GenerateLastBonusType)?.groupConditionId;
  const showTerms =
    activeTerms?.groupConditionId === (item as NotificationTypes.GenerateLastBonusType)?.groupConditionId;

  // const [showTerms, setShowTerms] = useState(
  //   activeTerms?.groupConditionId === (item as NotificationTypes.GenerateLastBonusType)?.groupConditionId,
  // );
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });
  const itemRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const defaultImage = constructAnimationImage({
    folder: animationParams.folder,
    image: (item as NotificationTypes.GenerateBonusType)?.image || '',

    width: isDesktop ? 320 * 2 : 430,
    height: isDesktop ? 120 * 2 : 161,
    fit: 'contain',
  });
  const activeImage = constructAnimationImage({
    folder: animationParams.folder,
    image: (item as NotificationTypes.GenerateBonusType)?.imageActive || '',
    width: isDesktop ? 320 * 2 : 430,
    height: 60 * 2,
    fit: 'contain',
  });

  usePreloadImage(defaultImage);
  usePreloadImage(activeImage);

  const handleShowTerms = () => {
    // setShowTerms((prev) => !prev);
    onSelectTerms?.(item as NotificationTypes.GenerateLastBonusType);
  };

  const handleOnClick = debounce(() => {
    onSelectStep?.(item as NotificationTypes.GenerateBonusType, type, isLast, categoryIndex);
  }, 550);

  if (isLast) {
    return (
      <WrapperBonus
        variants={createBonusItemAnimation.item}
        animate="visible"
        initial="hidden"
        $active={isActive}
        className="no-scrollbars"
        $activeBorderColor={style?.activeBackground}
      >
        <div>
          <BonusStyle
            onClick={() => onSelectBonus?.(item as NotificationTypes.GenerateLastBonusType)}
            $color={style?.color}
            $activeColor={style?.activeColor}
            $background={style?.background}
            $activeBackground={style?.activeBackground}
            $isActive={isActive}
          >
            <Title>{item?.title}</Title>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <FramePath
                $active={isActive}
                $activeBackground={style?.activeBackground}
                d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H30C30.8284 0.5 31.5 1.17157 31.5 2V30C31.5 30.8284 30.8284 31.5 30 31.5H2C1.17157 31.5 0.5 30.8284 0.5 30V2Z"
              />
              <CheckPath
                $active={isActive}
                $activeBackground={style?.activeBackground}
                d="M22.875 6C18.5318 10.814 16.4803 15.864 14.1813 20.94C12.729 18.4354 11.0338 16.1087 9.125 14H5C8.13615 17.666 10.902 21.6891 13.25 26H16C18.6429 18.7239 22.3586 11.9681 27 6H22.875Z"
              />
            </svg>
          </BonusStyle>
          <TermsWrapper
            $active={isActive}
            $color={style?.termsColor}
            $activeColor={style?.termsColorActive}
            $background={style?.termsBackground}
            $activeBackground={style?.activeBackground}
          >
            <TermsBtn onClick={handleShowTerms}>
              {t(MyProfile.OffersTab.termsAndConditionsApply)}
              <ExpandableIcon size={10} isExpanded={!!offerTerms && !!showTerms} />
            </TermsBtn>
            {offerTerms?.text && showTerms && (
              <TermsInfo>
                <ParseHTML html={offerTerms?.text || ''} options={{ trim: true }} />
              </TermsInfo>
            )}
          </TermsWrapper>
        </div>
      </WrapperBonus>
    );
  }
  // if (!isImageLoaded) return;
  return (
    <ItemStyle
      ref={itemRef}
      onClick={handleOnClick}
      $bg={defaultImage}
      $activeBg={activeImage}
      $isActiveStep={isActiveStep}
      transition={{ delay: 0.2, ease: 'easeInOut' }}
      variants={createBonusItemAnimation.item}
    >
      {/* {isActiveStep ? <img src={defaultImage} alt="" /> : <img src={activeImage} />} */}
    </ItemStyle>
  );
};

const FramePath = styled.path<{ $active?: boolean; $activeBackground?: string }>`
  stroke: ${({ $active, $activeBackground }) => ($active ? $activeBackground || '#FCE34C' : '#949494')};
  transition: all 0.5s;
`;
const CheckPath = styled.path<{ $active?: boolean; $activeBackground?: string }>`
  fill: ${({ $active, $activeBackground }) => ($active ? $activeBackground || '#FCE34C' : 'transparent')};
  stroke: ${({ $active, $activeBackground }) => ($active ? $activeBackground || '#FCE34C' : 'transparent')};
  transition: all 0.5s;
`;
const BonusStyle = styled(motion.div)<{
  $color?: string;
  $activeColor?: string;
  $background?: string;
  $activeBackground?: string;
  $isActive?: boolean;
}>`
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0.5);
  background: ${({ $background }) => $background || 'rgba(0, 0, 0, 0.5)'};
  color: ${({ $isActive, $activeColor, $color }) => ($isActive && $activeColor ? $activeColor : $color || '#fff')};
  border-bottom: 0;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  min-height: 58px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 15px;
  cursor: pointer;

  @media (min-width: ${Breakpoints.isDesktop}px) {
    padding: 5px 15px 5px 10px;
    min-height: 80px;
    padding-block: 10px;
    font-size: 16px;
  }
`;
const TermsInfo = styled.div`
  padding: 10px;
  ol,
  ul {
    padding-inline-start: 0px;
    list-style-position: inside;
  }
`;
const Title = styled.div`
  width: 100%;
`;
const WrapperBonus = styled(motion.div)<{ $active?: boolean; $activeBorderColor?: string }>`
  border-radius: 2px;
  width: 100%;
  & > div {
    width: 100%;
    border-radius: inherit;
  }
  /* width: 320px;
  @media (min-width: ${Breakpoints.isMobile}px) {
    width: 430px;
  } */

  border: 1px solid ${({ $active, $activeBorderColor }) => ($active ? $activeBorderColor || '#FCE34C' : 'transparent')};
`;
const TermsWrapper = styled.div<{
  $active?: boolean;
  $color?: string;
  $activeColor?: string;
  $background?: string;
  $activeBackground?: string;
}>`
  background: ${({ $active, $background, $activeBackground }) =>
    $active ? $activeBackground || '#FCE34C' : $background || '#584538'};
  color: ${({ $active, $color, $activeColor }) => ($active ? $activeColor || '#000' : $color || '#fff')};
  display: flex;
  flex-direction: column;
  transition: all 0.5s;
`;
const TermsBtn = styled(motion.div)<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: normal;
  padding: 5px;
  cursor: pointer;
  & > svg {
    fill: currentColor;
  }
`;
const ItemStyle = styled(motion.div)<{ $bg?: string; $activeBg?: string; $isActiveStep?: boolean }>`
  text-align: center;
  height: 120px;
  width: 320px;
  background-size: contain;
  margin: 0 17px;

  cursor: pointer;
  ${({ $bg, $isActiveStep }) => $bg && !$isActiveStep && `background-image:url(${$bg})`};
  ${({ $activeBg, $isActiveStep }) => $isActiveStep && $activeBg && `background-image:url(${$activeBg})`};
  @media (min-width: ${Breakpoints.isMobile}px) {
    &:hover {
      ${({ $activeBg }) => $activeBg && `background-image:url(${$activeBg})`};
    }
    width: 430px;
    height: 161px;
    margin: 0 20px;
  }
`;
