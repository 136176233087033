import { LayoutConfigInnerLayoutTypes } from 'modules/casino/shared/constants';
import { useCasinoLayoutConfig } from 'modules/casino/shared/hooks/useCasinoLayoutConfig';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { useAppSelector } from 'store';
import { useAggregatorConfig } from '../launcherRoute/hooks/useAggregatorConfig';

const useInitialLaunchConfig = () => {
  const aggregatorConfig = useAggregatorConfig();
  const isLayoutConfigLoaded = useAppSelector(selectGeneral.isLayoutConfigLoaded);
  const kycConfig = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.KYC_CONFIG });

  return { aggregatorConfig, kycConfig, isLayoutConfigLoaded };
};

export default useInitialLaunchConfig;
