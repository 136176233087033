import { Suspense } from 'react';
import styled from 'styled-components';
import cell02ComponentLoader from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell02/Cell02ComponentLoader';
import cell02StyleLoader from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell02/Cell02StyleLoader';
import {
  ComponentNames,
  ComponentType,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/layoutWrapperConstants';
import { SharedComponents } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared';

const GameCellWrapper = ({ gameUniqueId, categoryId }) => {
  const Description = cell02ComponentLoader(ComponentNames.Description, ComponentType.data);
  //TODO Add class 'casino-game-cell--live',

  return (
    <StyledGameCellWrapper className={'casino-game-cell casino-game-cell--data casino-game-cell-tags-wrapper'}>
      <Suspense>
        <SharedComponents.GameCellTablePlayers gameUniqueId={gameUniqueId} categoryId={categoryId} />
        <SharedComponents.GameCellTags
          gameUniqueId={gameUniqueId}
          categoryId={categoryId}
          className={`cell-tag-bottom--mixin cell-tag-bottom--layout_2`}
        >
          <SharedComponents.GameCellTableLimits gameUniqueId={gameUniqueId} categoryId={categoryId} />
        </SharedComponents.GameCellTags>
        <SharedComponents.GameCellTableCharacters
          gameUniqueId={gameUniqueId}
          categoryId={categoryId}
          classFromLayout={'mix-grid-character'}
          layoutStyleLoader={cell02StyleLoader}
        />
        <Description gameUniqueId={gameUniqueId} categoryId={categoryId} />
      </Suspense>
    </StyledGameCellWrapper>
  );
};
//TODO Check styles
const StyledGameCellWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;

  .cell-tags__triangle {
    display: none;
  }
`;

export default GameCellWrapper;
GameCellWrapper.displayName = 'GameCellWrapper';
