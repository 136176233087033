import { useEffect, useRef } from 'react';
import { isEmpty } from 'utils/common/helpersCommon';

const numberOfAttempts = 5;

const RNAutofillLoginForm = ({ formProps }) => {
  const { setValue } = formProps;
  const currentV = useRef(0);
  useEffect(() => {
    let intervalId;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (currentV.current === 0) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ isShowLoginForm: true }));
      let x = 0;
      intervalId = setInterval(() => {
        const user: NodeListOf<HTMLElement> | undefined = document.getElementsByName('username') ?? undefined;
        const pass: NodeListOf<HTMLElement> | undefined = document.getElementsByName('password') ?? undefined;
        if (!isEmpty(user) && !isEmpty(pass)) {
          setValue('username', user[0].getAttribute('data-user'));
          setValue('password', pass[0].getAttribute('data-pass'));
          intervalId && clearInterval(intervalId);
        }
        if (++x === numberOfAttempts) {
          clearInterval(intervalId);
        }
      }, 250);
      currentV.current = 1;
    }
    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, []);
  return <></>;
};

export default RNAutofillLoginForm;
