import { useEffect } from 'react';
import { useIsUnmounted } from 'hooks/common/useIsUnmouted';
import { useWsEffect } from 'hooks/ws/useWsEffect';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { containerLocationPlatformDataMap } from 'utils/common/container.utils';
import { ContainerTypes } from 'utils/common/types/common.types';
import {
  Container,
  ContainerType,
  FetchConfig,
  LobbyFetchType,
} from '../utils/common/types/sportTypes/containerFetch.types';

const DEFAULT_POLLING_INTERVAL = 120000;

const containersToRefetch: ContainerType[] = [
  ContainerTypes.sportLobbyLive,
  ContainerTypes.mixedHighlights,
  ContainerTypes.slider,
  ContainerTypes.highlightsPerSport,
];

const pageWithPolling: Record<string, boolean> = {
  homePage: true,
  specialView: true,
};

const useContainerFetch = ({
  component,
  apiUri,
  containerId,
  slice,
  containerType,
  pollingInterval,
  componentLocation,
  selectLoaderData,
}: FetchConfig): LobbyFetchType<Container> => {
  const container: Container = useAppSelector(slice.selector.containerById(containerId));
  const { loader } = componentLocation ? containerLocationPlatformDataMap(componentLocation) : { loader: undefined };
  const isLoading = useAppSelector(loader && selectLoaderData ? selectLoaderData(loader) : () => false);
  const config = { apiUri, containerId, containerType, component };
  const isPollingEnabledConfig = useAppSelector(selectGeneral.isPollingEnabled);
  const isPageWithPolling = componentLocation ? pageWithPolling[componentLocation] : false;
  const isPollingEnabled = isPollingEnabledConfig || isPageWithPolling;

  const isUnmouted = useIsUnmounted();
  const dispatch = useAppDispatch();

  useWsEffect((isWsReconnect) => {
    // when skipIncrement != true, adding changing arguments (as isAuthenticated) leads loaderDataSlice to increment loader and rerender
    const promise = dispatch(
      slice.thunk.fetchContainer({ ...config, replaceEvent: true, skipIncrement: isWsReconnect, isPollingEnabled }),
    );

    return () => {
      if (isUnmouted() && promise) {
        promise?.abort && promise.abort();
      }
    };
  });

  useEffect(() => {
    if (containerType && containersToRefetch.includes(containerType) && !isLoading) {
      let intervalPromise;
      const interval = setInterval(
        () => {
          intervalPromise = dispatch(
            slice.thunk.fetchContainer({
              ...config,
              pollingChangeEvent: isPollingEnabled,
              skipIncrement: true,
              isPollingEnabled,
            }),
          );
        },
        (isPollingEnabled && pollingInterval) || DEFAULT_POLLING_INTERVAL,
      );

      return () => {
        clearInterval(interval);
        if (intervalPromise) {
          intervalPromise?.abort && intervalPromise.abort();
        }
      };
    }
  }, [isLoading, dispatch]);

  return { container, isLoading };
};

export default useContainerFetch;
