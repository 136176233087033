import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';
import { RequestStatuses } from 'modules/casino/shared/constants';
import { createAbortThunk } from 'modules/casino/store/thunkCreators';
import { setActiveCasinoType, setActiveCasinoVertical } from 'shared/common/sharedSlices/commonActions';
import { AppDispatch } from 'store';
import { RootState } from 'store/rootReducer';
import { cachiosInstance } from 'utils/common/axios-instance';
import config from 'utils/config';
import { noActiveProviderAlias, ttl } from '../constants/casinoGameProvidersConstants';
import { ProvidersTypes } from '../types/casinoGameProvidersTypes';
import { getCasinoProviderKey, normalizeProvidersData } from '../utils/casinoGameProvidersUtils';

export const gameProvidersThunks: ProvidersTypes.ProvidersBarThunks = {
  fetchProviders: createAbortThunk('providersBar/fetchProviders', async ({ mobileAppRestrictions }, { source }) => {
    const fetchURL = `${config.API_URL}/api/gaming/public/providers`;
    const response = await cachiosInstance.get<ProvidersTypes.ProviderWithType[]>(fetchURL, {
      data: null,
      cancelToken: source.token,
      ttl,
    });
    const { isRestricted, filterProviders } = mobileAppRestrictions;

    return isRestricted ? filterProviders(response.data) : response.data;
  }),
};

const { fetchProviders } = gameProvidersThunks;

const initialState: ProvidersTypes.BarState = {
  activeProviderAlias: noActiveProviderAlias,
  providersDataByKey: {},
  providerAliasesByVerticals: {},
  providersDataByIds: {},
  providersDataByAliases: {},
  isToggled: false,
  status: RequestStatuses.INITIAL,
  currentSearchGameAvailability: false,
  allProviderAliases: [],
  allFilteredProviderIds: [],
  activeProvidersBarTab: null,
  showBottomProvidersBar: false,
};

const gameProvidersReducer = createSlice({
  name: 'gameProviders',
  initialState,
  reducers: {
    setActiveAlias(state, action: PayloadAction<{ providerAlias: string }>) {
      state.activeProviderAlias = action.payload.providerAlias;
    },
    setSearchGameAvailability(state, action: PayloadAction<{ searchGame: boolean }>) {
      state.currentSearchGameAvailability = action.payload.searchGame;
    },
    setIsToggled(state, action: PayloadAction<boolean>) {
      if (!action.payload) {
        state.activeProviderAlias = noActiveProviderAlias;
      }

      state.isToggled = action.payload;
    },
    resetProvidersBar(state) {
      state.activeProviderAlias = noActiveProviderAlias;
      state.currentSearchGameAvailability = false;
    },
    addFilteredProviderId: (state, action: PayloadAction<string | string[]>) => {
      const providerIds = Array.isArray(action.payload) ? action.payload : [action.payload];
      providerIds.forEach((id) => {
        if (!state.allFilteredProviderIds.includes(id) && state.providersDataByIds[id]) {
          state.allFilteredProviderIds.push(id);
        }
      });
    },
    removeFilteredProviderId: (state, action: PayloadAction<string>) => {
      state.allFilteredProviderIds = [...state.allFilteredProviderIds.filter((id) => id !== action.payload)];
    },
    resetFilteredProviderIds: (state) => {
      state.allFilteredProviderIds = [];
    },
    setActiveProvidersBarTab(state, action: PayloadAction<number | null>) {
      state.activeProvidersBarTab = action.payload;
    },
    setShowBottomProvidersBar(state, action: PayloadAction<boolean>) {
      state.showBottomProvidersBar = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProviders.fulfilled, (state, action: PayloadAction<ProvidersTypes.ProviderWithType[]>) => {
        const {
          providerAliasesByVerticals,
          providersDataByKey,
          providersDataByIds,
          providersDataByAliases,
          allProviderAliases,
        } = normalizeProvidersData(action.payload);
        state.providerAliasesByVerticals = providerAliasesByVerticals;
        state.providersDataByKey = providersDataByKey;
        state.providersDataByIds = providersDataByIds;
        state.providersDataByAliases = providersDataByAliases;
        state.allProviderAliases = [...allProviderAliases];
        state.status = RequestStatuses.SUCCESS;
      })
      .addCase(fetchProviders.rejected, (state) => {
        state.status = RequestStatuses.ERROR;
      })
      .addCase(fetchProviders.pending, (state) => {
        state.status = RequestStatuses.PENDING;
      });
    builder.addCase(setActiveCasinoType, (state) => {
      state.activeProviderAlias = noActiveProviderAlias;
    });
    builder.addMatcher(isAnyOf(setActiveCasinoVertical), (state) => {
      state.showBottomProvidersBar = false;
    });
  },
});

export const {
  setActiveAlias,
  setSearchGameAvailability,
  setIsToggled,
  resetProvidersBar,
  addFilteredProviderId,
  removeFilteredProviderId,
  resetFilteredProviderIds,
  setActiveProvidersBarTab,
  setShowBottomProvidersBar,
} = gameProvidersReducer.actions;

const selectProviderDataByKey = (state: RootState, key: string) => state.casino.gameProviders.providersDataByKey[key];
const selectAllProviderDataByKey = (state: RootState) => state.casino.gameProviders.providersDataByKey;
const selectProviderDataById = (state: RootState, id: string) => state.casino.gameProviders.providersDataByIds[id];
const selectProviderDataByAlias = (state: RootState, alias: string) =>
  state.casino.gameProviders.providersDataByAliases[alias];
const selectProvidersStatus = (state: RootState): RequestStatuses => state.casino.gameProviders.status;
const selectIsToggled = (state: RootState) => state.casino.gameProviders.isToggled;
const selectActiveProvider = (state: RootState): string => state.casino.gameProviders.activeProviderAlias;
const selectSearchGameAvailability = (state: RootState): boolean =>
  state.casino.gameProviders.currentSearchGameAvailability;
const selectProviderAliasesByVertical = createSelector(
  (state: RootState, vertical: string) => vertical,
  (state: RootState): Record<string, string[]> => state.casino.gameProviders.providerAliasesByVerticals,
  (vertical, providerAliasesByVerticals) => {
    return providerAliasesByVerticals[vertical] || [];
  },
);
const selectAllProviderAliases = (state: RootState): string[] => state.casino.gameProviders.allProviderAliases;
const selectCasinoAllFilteredProviderIds = (state: RootState): string[] =>
  state.casino.gameProviders.allFilteredProviderIds;
const selectShowBottomProvidersBar = (state: RootState): boolean => state.casino.gameProviders.showBottomProvidersBar;

const selectActiveProvidersBarTab = (state: RootState): number | null =>
  state.casino.gameProviders.activeProvidersBarTab;

const selectSideBarProviderItems = createCachedSelector(
  (state: RootState, vertical: string) => vertical,
  selectProviderAliasesByVertical,
  selectAllProviderDataByKey,
  (vertical, providerAliasesByVerticals, providerDataByKeyAll): ProvidersTypes.SideBarProvidersItems =>
    providerAliasesByVerticals?.reduce(
      (acc, item) => {
        const key = getCasinoProviderKey(vertical, item);
        const rank = providerDataByKeyAll[key].rank;
        if (rank % 2 === 0) {
          acc['providersRight'].push(item);
        } else {
          acc['providersLeft'].push(item);
        }

        return acc;
      },
      { providersRight: [], providersLeft: [] } as ProvidersTypes.SideBarProvidersItems,
    ),
)((_, key) => `${key}}`);

const selectActiveProviderIndex = createSelector(
  (state: RootState, vertical: string) => vertical,
  (state: RootState): Record<string, string[]> => state.casino.gameProviders.providerAliasesByVerticals,
  (state: RootState): string => state.casino.gameProviders.activeProviderAlias,
  (vertical, providerAliasesByVerticals, activeProviderAlias) => {
    if (providerAliasesByVerticals[vertical]) {
      return providerAliasesByVerticals[vertical].indexOf(activeProviderAlias);
    }
    return -1;
  },
);

export const gameProvidersSelectors = {
  providerDataByKey: selectProviderDataByKey,
  providerAliasesByVertical: selectProviderAliasesByVertical,
  providerDataById: selectProviderDataById,
  providerDataByAlias: selectProviderDataByAlias,
  providersStatus: selectProvidersStatus,
  isToggled: selectIsToggled,
  activeProvider: selectActiveProvider,
  searchGameAvailability: selectSearchGameAvailability,
  allProviderAliases: selectAllProviderAliases,
  casinoAllFilteredProviderIds: selectCasinoAllFilteredProviderIds,
  sideBarProviderItems: selectSideBarProviderItems,
  activeProviderIndex: selectActiveProviderIndex,
  activeProvidersBarTab: selectActiveProvidersBarTab,
  showBottomProvidersBar: selectShowBottomProvidersBar,
};

export const setActiveProviderStatus = (providerAlias: string, searchGame: boolean) => (dispatch: AppDispatch) => {
  // dispatch(setSearchTags(''));
  if (providerAlias) {
    dispatch(setActiveAlias({ providerAlias }));
    dispatch(setSearchGameAvailability({ searchGame }));
    dispatch(setShowBottomProvidersBar(false));
  }
};

export default gameProvidersReducer.reducer;
