import {
  gridAnimationDefault,
  gridAnimations,
  hoverAnimationDefault,
  hoverAnimations,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridCell/gridCellConstants';
import { useAppSelector } from 'store';
import { CategorySubTypes } from '../../../../modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { gridLayoutSettingsSelectors } from '../gridLayoutSettingsSlice';
import { GridLayoutSettingsTypes, NavigationLayout } from '../gridLayoutSettingsTypes';

export const useGridLayoutSettings = (subType?: CategorySubTypes): GridLayoutSettingsTypes.GridSettings => {
  const {
    showGameCellFavButton,
    gridAnimationClass,
    hoverAnimationClass,
    enableColorIcons,
    enableFilterByProvider,
    menuNavigationLayout,
    providerNavigationLayout,
    providerSliderLayout,
    isMenuGamesCount,
  } = useAppSelector(gridLayoutSettingsSelectors.gridLayoutSettings);

  const fetchStatus = useAppSelector(gridLayoutSettingsSelectors.fetchStatus);

  const disableAnimation =
    subType &&
    (subType === CategorySubTypes.FREE_SPIN_CHALLENGE ||
      subType === CategorySubTypes.FREE_SPIN ||
      subType === CategorySubTypes.IN_GAME_FREE_SPIN);

  return {
    hoverAnimationClass: hoverAnimations.includes(hoverAnimationClass)
      ? hoverAnimationClass
      : hoverAnimationDefault.HOVER_DEFAULT,
    gridAnimationClass: disableAnimation
      ? gridAnimationDefault.FADE_IN
      : gridAnimations.includes(gridAnimationClass)
        ? gridAnimationClass
        : gridAnimationDefault.FADE_IN,
    showGameCellFavButton: showGameCellFavButton !== undefined ? showGameCellFavButton : true,
    enableColorIcons,
    enableFilterByProvider,
    menuNavigationLayout: menuNavigationLayout || NavigationLayout.HORIZONTAL,
    providerNavigationLayout: providerNavigationLayout || NavigationLayout.HORIZONTAL,
    providerSliderLayout,
    fetchStatus,
    isMenuGamesCount,
  };
};
