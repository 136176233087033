import styled, { css, keyframes } from 'styled-components';

const glowPulse = keyframes`
  0% { box-shadow: inset 0px 0px 0px 0px rgba(44, 28, 64, 0); }
  50% { box-shadow: inset 0px 0px 15px 15px rgba(44, 28, 64, 1); }
  100% { box-shadow: inset 0px 0px 0px 0px rgba(44, 28, 64, 0); }
`;

const fade = keyframes`
  0% { filter: grayscale(0); }
  100% { filter: grayscale(0.8); }
`;

const sharedGrid = css`
  grid-row: 1 / 1;
  grid-column: 1 / 1;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
`;

export const ReelFrameGlow = styled.div<{ $glowDelay: number }>`
  ${sharedGrid};
  z-index: 999;
  pointer-events: none;
  animation: ${glowPulse} 2s ease-in-out ${(p) => p.$glowDelay}s;
`;

export const ReelFrameFade = styled.div<{ $isFaded: boolean | string }>`
  ${sharedGrid};

  animation: ${(p) =>
    p.$isFaded
      ? css`
          ${fade} 0.8s ease-in-out forwards
        `
      : css`none`};
`;
