import React, { memo } from 'react';
import clsx from 'clsx';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const BadgeGroup: React.FC<Props> = ({ className, children }) => {
  const groupClass = clsx('badge-group', className);
  return <span className={groupClass}>{children}</span>;
};

export default memo(BadgeGroup);
