import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  SessionBalance,
  SessionBalanceThunk,
  SessionBalanceTypes,
} from 'modules/retail/shared/types/retailCommon.types';
import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import config from 'utils/config';

const initialState: SessionBalanceTypes = {
  retailBalanceInfo: null,
  retailBalanceInfoStatus: 'idle',
};

export const sessionBalanceThunk: SessionBalanceThunk = {
  checkForUpdateBalance: createAbortThunk('myProfile/fetchRetailBalance', async () => {
    const url = `${config.API_URL}/api/pretail/agent/v2/terminal/session-balance`;
    const response = await axiosInstance.get(url);
    return response.data;
  }),
};

const sessionBalanceSlice = createSlice({
  name: 'sessionBalanceSlice',
  initialState,
  reducers: {
    resetCommonRetailThunk: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      sessionBalanceThunk.checkForUpdateBalance.fulfilled,
      (state, action: PayloadAction<SessionBalance>) => {
        state.retailBalanceInfoStatus = 'succeeded';
        state.retailBalanceInfo = {
          sessionBalance: {
            currency: action.payload?.sessionBalance.currency,
            amount: action.payload?.sessionBalance.amount,
          },
        };
      },
    );
    builder.addCase(sessionBalanceThunk.checkForUpdateBalance.pending, (state) => {
      state.retailBalanceInfoStatus = 'pending';
    });
    builder.addCase(sessionBalanceThunk.checkForUpdateBalance.rejected, (state) => {
      state.retailBalanceInfoStatus = 'failure';
      state.retailBalanceInfo = null;
    });
  },
});
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { resetCommonRetailThunk } = sessionBalanceSlice.actions;
export default sessionBalanceSlice.reducer;
export const selectSessionBalance = {
  retailBalanceInfo: (state: RootState) => state.retail.sessionBalance.retailBalanceInfo,
  retailBalanceInfoStatus: (state: RootState) => state.retail.sessionBalance.retailBalanceInfoStatus,
};
