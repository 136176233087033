import React from 'react';
import styled from 'styled-components';

type Props = {
  title?: string;
};

export const Header: React.FC<Props> = ({ title }) => {
  if (!title) return null;
  return <HeaderSC className="popover__title">{title}</HeaderSC>;
};

const HeaderSC = styled('div')`
  font-weight: bold;
`;

export default Header;
