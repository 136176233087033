import React from 'react';
import { Icon } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { cssGamificationNS } from 'utils/common/types/css.types';

type Props = {
  handleOnClick?: () => void;
};

export const TagInfo: React.FC<Props> = ({ handleOnClick }) => {
  return (
    <Flex.AS className={`${cssGamificationNS}-tag ${cssGamificationNS}-tag__info`} onClick={handleOnClick}>
      <Icon defaultClassName={false} className={`${cssGamificationNS}-tag__info__icon`} icon="info-solid" />
    </Flex.AS>
  );
};
