import { useLocation, useParams } from 'react-router-dom';

export type ExtractURLParamsReturnTypes = {
  gameName: string;
  providerAlias: string;
  gameId: string;
  vertical: string;
  agent: string;
  closeUrl: string;
  isFreePlay: 'true' | 'false';
  svara: string;
};

export const useExtractURLParams = (): ExtractURLParamsReturnTypes => {
  const params = useParams();
  const searchParams = new URLSearchParams(useLocation().search);
  const searchParamsKeyVal = {};

  searchParams.forEach((val, key) => {
    searchParamsKeyVal[key] = val;
  });
  return {
    ...params,
    ...searchParamsKeyVal,
  } as ExtractURLParamsReturnTypes;
};

export default useExtractURLParams;
