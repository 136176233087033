import { useEffect } from 'react';
import { useAppDispatch } from 'store';
import { ProvidersBarPosition } from '../constants/casinoGameProvidersConstants';
import { useProvidersBarPositionConfig } from '../hooks/useProvidersBarPositionConfig';
import { setShowBottomProvidersBar } from '../slice/casinoGameProvidersSlice';

const BottomProvidersBarVisibilityHandler = () => {
  const dispatch = useAppDispatch();
  const providersBarPositionConfig = useProvidersBarPositionConfig();

  useEffect(() => {
    if (providersBarPositionConfig === ProvidersBarPosition.TOP) {
      return;
    }

    dispatch(setShowBottomProvidersBar(true));
  }, [dispatch, providersBarPositionConfig]);

  return null;
};

export default BottomProvidersBarVisibilityHandler;
