import { useEffect } from 'react';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { favoriteGamesSelectors, favouritesThunks } from '../slice/casinoFavouriteGamesSlice';

let fetchGameIdsRef = false;

export const useFetchFavouriteGamesIds = (): boolean => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const hasGameIdsFetched = useAppSelector(favoriteGamesSelectors.hasGameIdsFetched);

  useEffect(() => {
    if (hasGameIdsFetched) {
      fetchGameIdsRef = false;
      return;
    }

    if (isAuthenticated && !fetchGameIdsRef) {
      fetchGameIdsRef = true;

      dispatch(favouritesThunks.fetchFavouritesGameIds({}));
    }
  }, [isAuthenticated, dispatch, hasGameIdsFetched]);

  return hasGameIdsFetched;
};
