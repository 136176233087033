import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { MessagesTypes } from './messagesTypes';

const initialState: MessagesTypes.State = {
  postMessageState: null,
};

const messageManagerSlice = createSlice({
  name: 'MessageManager',
  initialState,
  reducers: {
    setPostMessageState(state, action) {
      state.postMessageState = action.payload;
    },
    resetPostMessage(state) {
      state.postMessageState = null;
    },
  },
});

export const { setPostMessageState, resetPostMessage } = messageManagerSlice.actions;

const selectPostMessageState = (state: RootState) => state.casino.messageManager.postMessageState;

export const postMessagesSelectors = {
  postMessageState: selectPostMessageState,
};

export default messageManagerSlice.reducer;
