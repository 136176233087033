import { Flex } from 'components/shared/layout';

/** BetSlip Footer Actions */
const FooterActions: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
  return (
    <Flex className="bsl-footer__actions" data-qid="betslip-footer-actions">
      {children}
    </Flex>
  );
};

export default FooterActions;
