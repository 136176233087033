import { useTranslation } from 'react-i18next';
import { selectPrizeDrop } from 'shared/common/features/PrizeDrop/slice/prizeDrop.slice';
import { useAppSelector } from 'store';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import PrizeDropCampaignItem from './PrizeDropCampaignItem';
import { PrizeDropUI } from '..';
import { prizeDropCSSNS } from '../prizeDropUICSS.types';

type Props = {
  hasHeader?: boolean;
  isInGameBar?: boolean;
};

const PrizeDropCampaignsCol: React.FC<Props> = ({ hasHeader, isInGameBar = false }) => {
  const campaignsIds = useAppSelector(selectPrizeDrop.campaignsIds);

  const { t } = useTranslation();

  const classProp = { class: `${prizeDropCSSNS}-inner-shadow` };
  const classWrapProp = { class: 'no-scrollbars overflow-y-auto overflow-x-hidden' };

  return (
    <egtd-przd-modal-campaigns {...classProp} data-header={hasHeader} data-popover={isInGameBar}>
      {hasHeader && <PrizeDropUI.Header title={t(Promotions.PrizeDrop.campaignsTitle)} size="normal" />}

      <egtd-przd-modal-campaigns-wrap {...classWrapProp}>
        {campaignsIds?.map((id: number) => <PrizeDropCampaignItem key={`campaign-${id}`} id={id} />)}
      </egtd-przd-modal-campaigns-wrap>
    </egtd-przd-modal-campaigns>
  );
};

export default PrizeDropCampaignsCol;
