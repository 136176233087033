import React from 'react';
import { useLocation } from 'react-router-dom';
import { BurgerUI } from 'shared/common/features/burger/components/BurgerUI';
import { BurgerMenuSections } from 'shared/common/features/navigationMenu/types/navigationMenu.types';
import { isEmpty } from 'utils/common/helpersCommon';
import { useGetDrawerSportItems } from '../../hooks/useGetDrawerSportItems';
import useHandleDrawerSportClick from '../../hooks/useHandleDrawerSportClick';
import { SportsType } from '../../types/drawer.types';

const ESportDrawerSectionLayout02: React.FC<BurgerMenuSections> = ({ section }) => {
  const location = useLocation();
  const { items, activeItem } = useGetDrawerSportItems(SportsType.ESports);
  const { handleSportClick } = useHandleDrawerSportClick();
  if (isEmpty(items)) return null;
  return (
    <>
      <BurgerUI.TilesGrid>
        {items.map((tile, index) => (
          <BurgerUI.SportTile
            key={index}
            icon={tile.id.toString()}
            text={tile.name}
            isActive={activeItem === tile.id && location.pathname.includes(section?.route)}
            liveBadgeText={tile.liveEventsCount.toString()}
            onClick={() => handleSportClick({ sportType: SportsType?.ESports, sportId: tile.id })}
          />
        ))}
      </BurgerUI.TilesGrid>
    </>
  );
};

export default ESportDrawerSectionLayout02;
