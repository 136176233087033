import React from 'react';
import clsx from 'clsx';
import { Flex } from 'modules/casino/shared/components/layout';
import { GameCellDataCharactersTypes } from '../gameCellTableCharactersConstants';
import {
  backgroundSymbol,
  convertSymbol,
  isNumeric,
  sicBoDeluxeMultipliers,
  colorSymbol,
} from '../GameCellTableCharactersUtils';

export interface ItemsProps {
  symbol;
  category: GameCellDataCharactersTypes;
  classFromLayout: string;
  showLuckyNumber?: boolean;
  luckyNumber?: number;
}

export const ItemSicBoDeluxe: React.FC<ItemsProps> = (props) => {
  const numberOne = convertSymbol(props.symbol, props.category);
  const sortNumbers = props.symbol.value.split('|').sort();
  const multiplierSum = sicBoDeluxeMultipliers(props.symbol, sortNumbers);

  const charStyles = clsx(
    'character',
    'is-double-roulette d-flex-col',
    multiplierSum > 0 && props.showLuckyNumber && 'lucky-numbers-wrapper',
    props.classFromLayout,
    !isNumeric(props.symbol) &&
      typeof props.symbol === 'string' &&
      `bg--${backgroundSymbol(props.symbol, props.category)}`,
  );

  return (
    <div className={charStyles}>
      {multiplierSum > 0 && <div className={`lucky-numbers`}>{`x${multiplierSum}`}</div>}
      <Flex.Center className={`${colorSymbol(props.symbol, props.category)}-text-deluxe character-text`}>
        {numberOne}
      </Flex.Center>
      <Flex.Center className={`character-text__sicbo-deluxe`}>
        {sortNumbers.map(function (item, idx) {
          const sameNumber = sortNumbers[idx - 1] === item || sortNumbers[idx + 1] === item;
          const styleNumbers = clsx(sameNumber && 'same-numbers');
          return (
            <span key={item + Math.random()} className={styleNumbers}>
              {item}
            </span>
          );
        })}
      </Flex.Center>
    </div>
  );
};
