import { memo, useCallback } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { UI } from 'components/shared/UI';
import { RetailHeaderIcons } from 'modules/retail/modules/ssbt/types/ssbt.types';
import { MyProfile, SSBT } from 'utils/common/translationUtils/translationStrings';

const RetailHeaderUserDepositBtn = ({ onClick, balance, currency, showDepositIcon }) => {
  const { t } = useTranslation();
  const controls = useAnimation();

  const handleTapParent = useCallback(() => {
    controls.start(() => ({
      scale: [0.9, 1],
      transition: { ease: 'easeInOut', duration: 0.4 },
    }));
  }, [controls]);

  const handleClick = () => {
    onClick();
    handleTapParent();
  };
  return (
    <Flex.AC onClick={handleClick} className="rbtn__deposit">
      <Flex.Col className="rbtn__deposit-balance">
        <span className="rbtn__deposit-title">{t(MyProfile.Statistics.balanceText)}</span>
        <div className="rbtn__deposit-sum">
          {balance}
          <span className="rbtn__deposit-currency">{currency}</span>
        </div>
      </Flex.Col>
      <Flex.AC className="rbtn__deposit-btn">
        <motion.div className="egtd-flex-ac" animate={controls}>
          {t(SSBT.Navigation.deposit)}
          {showDepositIcon && (
            <UI.SVGCDN icon={RetailHeaderIcons.DEPOSIT} path="RETAIL" size={24} className="rbtn__deposit-icon" />
          )}
        </motion.div>
      </Flex.AC>
    </Flex.AC>
  );
};

export default memo(RetailHeaderUserDepositBtn);
