import React, { CSSProperties, useEffect } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { Flex } from 'components/shared/layout';
import { Modal } from 'components/shared/Modal';
import { usePreloadImage } from 'hooks/common/usePreloadImage';
import { GamificationContainer } from 'shared/common/features/gamification';
import { useAnimationAssets } from 'shared/common/features/gamification/hooks/useAnimationAssets';
import { useCloseModalInCasinoGame } from 'shared/common/features/gamification/hooks/useCloseModalInCasinoGame';
import { useModalDelay } from 'shared/common/features/gamification/hooks/useModalDelay';
import { useShowGamificationModal } from 'shared/common/features/gamification/hooks/useShowGamificationModal';
import { handleAudio } from 'shared/common/features/gamification/utils/Gamification.utils';
import { Breakpoints } from 'theme/Theme';
import { isEmpty } from 'utils/common/helpersCommon';
import useNotificationStorageMethods from './hooks/useNotificationStorageMethods';
import { NotificationTypes } from './notificationTypes';
import useStorageEvent from '../../../../../../../hooks/common/useStorageEvent';
import { GamificationModalUI } from '../../../../gamification/components/GamificationModalUI';
import { useModalUiActions } from '../../../../gamification/hooks/useModalUiActions';
// import usePollingPopupNotifications from './hooks/usePollingPopupNotifications';

interface Props {
  notification: NotificationTypes.PopUpNotification;
}

const PopUpGamificationModal: React.FC<Props> = ({ notification }) => {
  const { style, bonusData, modalAudioUrl } = notification;

  const { handleModalClose } = useModalUiActions(notification.id);

  useCloseModalInCasinoGame(handleModalClose);

  const { backgroundImage, animationParams, hasBackground } = useAnimationAssets({
    animationKey: style.animationKey,
    layout: style.layout,
    folder: style.folder,
  });

  const { isImageLoaded } = usePreloadImage(backgroundImage, handleModalClose);

  const isReady = useModalDelay(isImageLoaded || !hasBackground, 200);

  useShowGamificationModal(isReady);
  // usePollingPopupNotifications();

  const { handleStorageEvent, clearNotificationId } = useNotificationStorageMethods();
  useStorageEvent(handleStorageEvent, clearNotificationId);

  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });
  // Single bonus is used to cover campaigns with one win bonus
  const singleBonus = bonusData?.bonuses?.[0];
  const winnerIndex = bonusData?.otherOptions?.findIndex((option) => option === singleBonus?.title);
  const wizardBonus = bonusData?.groupOptions;

  useEffect(() => {
    if (winnerIndex === undefined || winnerIndex < 0) {
      handleModalClose();
    }
  }, [winnerIndex, handleModalClose]);

  if (
    (!isImageLoaded && hasBackground) ||
    !bonusData ||
    isEmpty(bonusData?.bonuses) ||
    (style.animationKey === 'WizardBonus' && isEmpty(wizardBonus)) ||
    isEmpty(animationParams)
  ) {
    return null;
  }

  isReady && modalAudioUrl && handleAudio(modalAudioUrl);
  const wrapperClass = clsx('gmf-modal__wrapper', isDesktop && 'no-scrollbars');
  const bodyClass = clsx('gmf-modal__body', animationParams?.modalBodyClassName);
  const modalStyle: CSSProperties = isReady ? { opacity: 1 } : { opacity: 0, visibility: 'hidden' };

  return (
    <Modal
      visible
      size="gamification"
      className={animationParams?.modalClassName}
      style={modalStyle}
      dialogClassName={animationParams?.modalClassName}
      dialogStyle={animationParams?.modalStyle}
    >
      <div className={wrapperClass}>
        <Flex.ColAC
          className={bodyClass}
          style={{
            ...animationParams?.modalBodyStyle,
            ...(hasBackground ? { backgroundImage: `url(${backgroundImage})` } : {}),
          }}
        >
          <GamificationModalUI.Close
            onClick={handleModalClose}
            color={animationParams?.modalCloseColor}
            style={animationParams?.modalCloseStyle}
          />
          <GamificationContainer notification={notification} isReady={isReady} />
        </Flex.ColAC>
      </div>
    </Modal>
  );
};

export default PopUpGamificationModal;
