import CasinoTile from './CasinoTile';
import Close from './Close';
import { SCContent } from './Content.styled';
import CopyRightsHTML from './CopyRights';
import Header from './Header';
import LinkItem from './LinkItem';
import ListWrapper from './ListWrapper';
import NavItem from './NavItem';
import RetailGrid from './RetailGrid';
import Section from './Section';
import Separator from './Separator';
import SportTile from './SportTile';
import TilesGrid from './TilesGrid';
import TilesWrapper from './TilesWrapper';
import Wrapper from './Wrapper';

export const BurgerUI = {
  CasinoTile: CasinoTile,
  Close: Close,
  Content: SCContent,
  Header: Header,
  LinkItem: LinkItem,
  NavItem: NavItem,
  Retail: RetailGrid,
  Section: Section,
  Separator: Separator,
  SportTile: SportTile,
  TilesGrid: TilesGrid,
  TilesWrapper: TilesWrapper,
  ListWrapper: ListWrapper,
  Wrapper: Wrapper,
  Text: CopyRightsHTML,
};
