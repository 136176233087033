import { useEffect } from 'react';
import { useAppDispatch } from 'store';
import { sendOnScrollImpressions } from '../slice/categoryImpressionsSlice';

export const useSendOnScrollImpressions = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(sendOnScrollImpressions());
    };
  }, []);
};
