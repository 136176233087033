import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrency } from 'hooks/common/useCurrency';
import { selectCashierData } from 'modules/retail/modules/cashier/slice/cashier.slice';
import { sessionBalanceThunk } from 'modules/retail/modules/ssbt/features/sessionBalance/slice/sessionBalance.slice';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty, toFixed } from 'utils/common/helpersCommon';
import { SSBT } from 'utils/common/translationUtils/translationStrings';
import CloseModalButton from './CloseModalButton';
import { TerminalModalUI } from './ssbtModalUI';
import { resetTicketInfoState, selectTicketData } from '../../../shared/slice/checkTicket.slice';
import { resetStateDepositTicketSSBT, selectSSBTdata, terminalThunks } from '../slice/ssbt.slice';

const PayoutModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { closeModal } = useHandleModals();
  const { currencyCode } = useCurrency() || '';
  const ticketInfo = useAppSelector(selectTicketData.ticketInfo);
  const payoutTicketStatusSSBT = useAppSelector(selectSSBTdata.payoutTicketStatusSSBT);
  const payoutTicketErrorMessage = useAppSelector(selectCashierData.payoutTicketError);

  const handlePayout = () => {
    if (!isEmpty(ticketInfo)) {
      dispatch(terminalThunks.ssbtPayoutTicket({ code: ticketInfo?.ticket?.ticketId }));
    }
  };

  const closeDepositTicketModal = () => {
    dispatch(resetStateDepositTicketSSBT());
    dispatch(resetTicketInfoState());
    closeModal();
  };

  useEffect(() => {
    if (payoutTicketStatusSSBT === 'succeeded') {
      dispatch(sessionBalanceThunk.checkForUpdateBalance());
    }
  }, [payoutTicketStatusSSBT]);

  const renderTerminalModal = (status) => {
    switch (status) {
      case 'pending':
        return (
          <>
            <TerminalModalUI.Header text={t(SSBT.TerminalModal.requestTerminalText)} />
            <TerminalModalUI.Status status="pending" />
          </>
        );
      case 'succeeded':
        return (
          <>
            <TerminalModalUI.Status status="success" text={t(SSBT.TerminalModal.transactionTextTerminal)} />
            <CloseModalButton onClick={closeDepositTicketModal} />
          </>
        );

      case 'failure':
        return <TerminalModalUI.Status status="error" text={`${payoutTicketErrorMessage}`} />;
      default:
        return (
          <>
            <TerminalModalUI.Header text={t(SSBT.TerminalModal.depositProfitTerminal)} />
            <TerminalModalUI.Button
              variant={'primary'}
              text={t(SSBT.TerminalModal.depositText)}
              textSecondary={`${toFixed(ticketInfo?.totalAmountAfterTax, 2)} ${currencyCode}`}
              onClick={handlePayout} //dispatch(terminalThunks.createVoucher({}));
            />
          </>
        );
    }
  };
  return (
    <TerminalModalUI.Wrapper isVisible={true}>
      {<TerminalModalUI.Close text={t(SSBT.TerminalModal.closeTerminalModalText)} onClick={closeDepositTicketModal} />}
      <TerminalModalUI.Body>
        <TerminalModalUI.ContentWrapper>{renderTerminalModal(payoutTicketStatusSSBT)}</TerminalModalUI.ContentWrapper>
      </TerminalModalUI.Body>
    </TerminalModalUI.Wrapper>
  );
};

export default PayoutModal;
