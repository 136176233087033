import React from 'react';
import { useTranslation } from 'react-i18next';
import { SSBT } from 'utils/common/translationUtils/translationStrings';
import { TerminalModalUI } from '../../../components/ssbtModalUI';
import { enableCoinAcceptor } from '../../../slice/ssbt.slice';

type Props = {
  handleNextStep: () => void;
  setPaymentMethod: React.Dispatch<React.SetStateAction<string>>;
};

const FirstStepDepositSSBTmodal: React.FC<Props> = ({ handleNextStep, setPaymentMethod }) => {
  // const isQrCodeSelected = useAppSelector(selectSSBTdata.isVoucherSelect);
  const { t } = useTranslation();
  const runNextStep = (paymentMethod: string) => {
    enableCoinAcceptor();
    handleNextStep();
    setPaymentMethod(paymentMethod);
  };

  const terminalTiles = [
    {
      icon: ['VISA', 'MASTER_CARD'],
      text: t(SSBT.TerminalModal.creditCardText),
      onClick: () => {
        return;
      },
    },
    { icon: ['CASH'], text: t(SSBT.TerminalModal.cashTextTerminal), onClick: () => runNextStep('cash') },
    {
      icon: ['VOUCHER'],
      text: t(SSBT.TerminalModal.voucherTextTerminal),
      onClick: () => {
        runNextStep('voucher');
      },
    },
  ];

  return (
    <>
      <TerminalModalUI.Header text={t(SSBT.TerminalModal.depositMethodTerminal)} />
      <TerminalModalUI.TilesWrapper>
        {terminalTiles.map((element, index) => (
          <TerminalModalUI.Tile key={index} text={element.text} icons={element.icon} onClick={element.onClick} />
        ))}
      </TerminalModalUI.TilesWrapper>
    </>
  );
};

export default FirstStepDepositSSBTmodal;
