import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import pick from 'lodash/pick';
import { createCachedSelector } from 're-reselect';
import axiosInstance from 'modules/casino/shared/utils/common/axios-instance';
import { isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import { createAbortThunk } from 'modules/casino/store/thunkCreators/createAbortThunk';
import { RootState } from 'store/rootReducer';
import config from 'utils/config';
import { JackpotTypes } from './jackpotTypes';
import { getJackpotCurrency, getLevels } from './jackpotUtils';

// TODO - Update isFakeUpdateON logic
// const fakeStatisticsCollection = {};

export const jackpotThunks: JackpotTypes.JackpotThunks = {
  fetchJackpots: createAbortThunk('jackpots/fetchJackpots', async (jackpotId, { source }) => {
    const jackpotIdAddition = `?ids=${jackpotId}`;
    const fetchURL = `${config.API_URL}/api/gaming/public/jackpots/levels${jackpotId ? jackpotIdAddition : ''}`;
    const response = await axiosInstance.get<Record<string, JackpotTypes.Update>>(fetchURL, {
      data: null,
      cancelToken: source.token,
    });

    return { jackpots: response.data };
  }),
};

const initialState: JackpotTypes.State = {
  jackpotsNormalized: {},
  categoryJackpotDetails: {},
  isInitialJackpotsFetched: false,
  isFakeUpdateON: false,
  runFetchInterval: false,
  pauseFetchInterval: false,
};

const jackpot = createSlice({
  name: 'jackpot',
  initialState,
  reducers: {
    setFakeUpdate(state, action: PayloadAction<boolean>) {
      state.isFakeUpdateON = action.payload;
    },
    setRunFetchInterval(state, action: PayloadAction<boolean>) {
      const prevValue = state.runFetchInterval;
      const newValue = action.payload;

      if (prevValue !== newValue) {
        state.runFetchInterval = action.payload;
      }
    },
    setPauseFetchInterval(state, action: PayloadAction<boolean>) {
      state.pauseFetchInterval = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      jackpotThunks.fetchJackpots.fulfilled,
      (state, action: PayloadAction<{ jackpots: Record<string, JackpotTypes.Update> }>) => {
        const { jackpots } = action.payload;

        state.jackpotsNormalized = state.isFakeUpdateON
          ? {
              ...jackpots,
              ...state.jackpotsNormalized,
            }
          : {
              ...state.jackpotsNormalized,
              ...jackpots,
            };

        state.isInitialJackpotsFetched = true;

        if (isEmpty(state.categoryJackpotDetails)) {
          const dataEntries = Object.entries(jackpots);

          dataEntries.forEach((item) => {
            const details = pick(item[1], 'jackpotCategoryDetails');

            state.categoryJackpotDetails[item[0]] = details;
          });
        }
      },
    );
  },
});

export const { setFakeUpdate, setRunFetchInterval, setPauseFetchInterval } = jackpot.actions;

export default jackpot.reducer;

const selectInitialJackpotsFetched = (state: RootState): boolean => state.casino.jackpot.isInitialJackpotsFetched;
const selectRunFetchInterval = (state: RootState): boolean => state.casino.jackpot.runFetchInterval;
const selectPauseFetchInterval = (state: RootState): boolean => state.casino.jackpot.pauseFetchInterval;
const selectJackpotUpdate = (state: RootState, jackpotId: string): JackpotTypes.Update =>
  state.casino.jackpot.jackpotsNormalized[jackpotId];
const selectJackpotCurrencySign = (state: RootState, jackpotId: string): string => {
  const updatedJackpot = selectJackpotUpdate(state, jackpotId);

  return getJackpotCurrency(updatedJackpot?.jackpotCurrencyDetails);
};

const selectJackpotDenomDetails = (state: RootState, jackpotId: string | number): JackpotTypes.JackpotDenomDetails => {
  const jackpotCurrency = selectJackpotCurrencySign(state, String(jackpotId));
  const updatedJackpot = selectJackpotUpdate(state, String(jackpotId));
  const denomDetails = updatedJackpot?.jackpotCurrencyDetails?.[jackpotCurrency]?.denominationWinsDetails;

  return denomDetails;
};

const selectBaseDenomination = (state: RootState, jackpotId?: string, denomination?: number): number | undefined => {
  if (jackpotId) {
    const denomDetails = selectJackpotDenomDetails(state, jackpotId);

    if (!denomDetails) {
      return;
    }

    const denominations = Object.keys(denomDetails);

    if (isEmpty(denominations)) {
      return;
    }

    if (denomination) {
      // 0 must be returned in order to hide play button, specific case with DENOM games (Progressive jackpots)
      return denominations.includes(String(denomination)) ? denomination : 0;
    } else {
      return Number(denominations[0]) || undefined;
    }
  } else {
    return;
  }
};

const selectJackpotWinDetails = (
  state: RootState,
  jackpotId: number | string,
  level: string,
): JackpotTypes.WinDetails | undefined => {
  if (jackpotId) {
    const jackpotDenomDetails = selectJackpotDenomDetails(state, jackpotId);

    if (!jackpotDenomDetails) {
      return;
    }

    const currentLevels = getLevels({ jackpotDenomDetails });

    if (!currentLevels) {
      return;
    }

    const winsDetails = currentLevels.find((levels) => levels.level === parseInt(level))?.winsDetails;
    const { lastWinAmount, lastWinDate, lastWinPlayer, winCounts, topWinAmount, topWinDate, hasWinners } =
      winsDetails || {};

    return {
      lastWinAmount: lastWinAmount || 0,
      lastWinDate: lastWinDate || '',
      lastWinPlayer: lastWinPlayer || '',
      winCounts: winCounts,
      topWinAmount: topWinAmount || 0,
      topWinDate: topWinDate || '',
      hasWinners: hasWinners || false,
    };
  } else {
    return;
  }
};

const selectJackpotCategoryDetails = createSelector(
  [(state, jackpotId) => state.casino.jackpot.categoryJackpotDetails[jackpotId]],
  (jackpotDetails): JackpotTypes.JackpotCategoryDetails | undefined => {
    if (jackpotDetails) {
      return jackpotDetails.jackpotCategoryDetails;
    }

    return;
  },
);

const selectJackpotLevelInfo = createCachedSelector(
  selectJackpotCategoryDetails,
  (state: RootState, jackpotId: string, levelDescription: string) => levelDescription,
  (jackpotCategoryDetails, levelDescription) => {
    const levelObj = jackpotCategoryDetails?.levels?.find((level) => level.name === levelDescription);

    const levelDisplayName = levelObj?.displayNameOther || levelObj?.displayNameDefault || '';
    const level = levelObj?.level;

    return {
      levelDisplayName,
      level,
    };
  },
)((_, jackpotId) => jackpotId);

export const jackpotSelectors = {
  selectJackpotCategoryDetails,
  selectJackpotWinDetails,
  selectRunFetchInterval,
  selectPauseFetchInterval,
  selectJackpotUpdate,
  selectJackpotCurrencySign,
  selectBaseDenomination,
  selectJackpotDenomDetails,
  selectInitialJackpotsFetched,
  selectJackpotLevelInfo,
};
