import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl } from 'components/shared/forms/components';
import { ModalUI } from 'components/shared/ModalUI';
import { RealityCheckSession, RealityCheckSessionTitleTypes } from '../types/realityCheckSession.types';

type Props = {
  field: RealityCheckSession.Titles | RealityCheckSession.Inputs;
};

const SessionCreateContent: React.FC<Props> = ({ field }): React.ReactNode => {
  const { t } = useTranslation();

  switch (field?.type) {
    case RealityCheckSessionTitleTypes.FORM_PRE_MODAL:
      return null;
    case RealityCheckSessionTitleTypes.FORM_TITLE:
      return <ModalUI.Title hasAccent className={'reality-check-session-create__title'} text={t(field.label)} />;
    case RealityCheckSessionTitleTypes.FORM_SUBTITLE:
      return <div className={'reality-check-session-create__text mt-2 mb-2'}>{t(field.label)}</div>;
    case RealityCheckSessionTitleTypes.FORM_CONTENT:
      return (
        field.label &&
        field.infoText && (
          <div className={'reality-check-session-create__timer'}>{`${t(field.label)} ${field.infoText}`}</div>
        )
      );
    default:
      return (
        <FormControl
          {...field}
          classNames={'reality-check-session-create__form-field col-12 col-md-12 text-left'}
          componentLocation={'realityCheckSession'}
          hasError={false}
          hasErrorIcon
          hasSuccessIcon
          isOutlined
        />
      );
  }
};

export default SessionCreateContent;
