import { isRejected } from '@reduxjs/toolkit';
import { ErrorHandlerTypes, setErrorData } from 'shared/common/sharedSlices/slice/errorLogger.slice';

const LOGGER_ACTION_TYPE = 'errorLogger/logErroData';

export const errorLoggerMiddleware = (store) => (next) => (action) => {
  if (isRejected(action) && !action?.meta?.aborted && !action?.type.includes(LOGGER_ACTION_TYPE)) {
    store.dispatch(setErrorData({ errorData: action, handleFrom: ErrorHandlerTypes.LOGGER_MIDDLEWARE }));
  }

  return next(action);
};
