import React, { useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Modal } from 'components/shared/Modal';
import { NotificationBlock } from 'components/shared/notifications';
import { useAuthentication } from 'hooks/common/useAuthentication';
import { casinoLaunchSelectors } from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import { useAppDispatch, useAppSelector } from 'store';
import { RealityCheckModal } from 'utils/common/translationUtils/translationStrings';
import {
  selectRealityCheckSession,
  RealityCheckSessionModalClose,
  RealityCheckSessionModalContent,
} from '../../realityCheckSession';
import useSessionPreModal from '../../realityCheckSession/hooks/useSessionPreModal';
import { RealityCheckSessionModalTypes } from '../../realityCheckSession/types/realityCheckSession.types';
import { hideRootGrandModal } from '../slice/rootModal.slice';

interface Props {
  modalType: RealityCheckSessionModalTypes;
  message?: string;
  closeGame?: () => void;
  resolve?: (value: unknown) => void;
}

const RealityCheckSessionModal: React.FC<Props> = ({ modalType, message, resolve, closeGame }) => {
  const formError = useAppSelector(selectRealityCheckSession.formError);
  const sessionError = useAppSelector(selectRealityCheckSession.sessionError);
  const isGameLaunch = useAppSelector(casinoLaunchSelectors.launchInProcess);
  const { isAuthenticated } = useAuthentication();
  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { showModal, closeModal } = useSessionPreModal();

  const modalContentStyle = isLandscape && isMobileOnly ? { maxHeight: '100vh' } : undefined;

  // forward/backward in browser history in mobile
  useEffect(() => {
    if (!isGameLaunch) {
      dispatch(hideRootGrandModal());
    }
  }, [isGameLaunch, modalType, dispatch]);

  // TO DO  Hide reality check session modal if user is logged out
  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(hideRootGrandModal());
    }
  }, [isAuthenticated, modalType, dispatch]);

  return (
    <Modal
      className={`no-scrollbars modal-reality-check-session modal-reality-check-session--${
        showModal ? 'pre-' : ''
      }${modalType}`}
      contentStyle={modalContentStyle}
      scrollable={true}
      visible={true}
    >
      <RealityCheckSessionModalClose modalType={modalType} closeGame={closeGame} closeModal={closeModal} />
      <Modal.Body padding={'lg'} className={'text-center'}>
        {formError || sessionError ? (
          <NotificationBlock
            title={t(RealityCheckModal.Session.errorTitle)}
            description={t(formError || sessionError || '')}
            icon={'info-solid'}
            variant={'error'}
          />
        ) : (
          <RealityCheckSessionModalContent modalType={modalType} message={message} resolve={resolve} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default RealityCheckSessionModal;
