import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import userStorage from 'pages/auth/login/UserStorage';
import {
  getPlayerData,
  updateNotificationType,
  setPreferredOddTypeId,
} from 'shared/common/features/myProfile/slice/myProfile.slice';

import { PlayersNotification } from 'shared/common/features/myProfile/types/myProfile.types';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance, { cachiosInstance } from 'utils/common/axios-instance';
import { getCachiosOptions, getError, sortByProperty } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { SettingsTypes } from '../types/settings.types';

const initialState: SettingsTypes.State = {
  languages: null,
  timeZones: null,
  odds: null,
  notificationTypes: null,
  updateErrorMessage: '',
  updateLoading: false,
  updateNotificationTypeLoading: false,
  error: '',
};

export const fetchTimeZonesPromise = async (): Promise<SettingsTypes.TimeZone[]> => {
  try {
    const url = `${config.API_URL}/api/ews-crm/public/nomenclatures/iana-timezones-fo`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (err) {
    return getError.responseDataErrors(err);
  }
};

export const SettingsThunks: SettingsTypes.Thunks = {
  fetchTimeZones: createAbortThunk('setting/fetchTimeZones', async () => {
    return await fetchTimeZonesPromise();
  }),
  updateUserTimeZone: createAbortThunk(
    'settings/updateUserTimeZone',
    async ({ timezoneCode, offsetMinutes }, { dispatch, rejectWithValue, getState }) => {
      let timezone = timezoneCode;

      if (!timezone) {
        let timezones = getState().myAccount.settings.timeZones;

        if (!timezones || timezones.length === 0) {
          timezones = await fetchTimeZonesPromise();
          timezones = getState().myAccount.settings.timeZones;
        }
        if (timezones && timezones.length > 0) {
          const currTimezone = timezones.find((item) => Number(item.offsetMinutes) === Number(offsetMinutes));

          if (currTimezone?.code) {
            timezone = currTimezone?.code;
          }
        }
      }
      const params = { timezone };
      try {
        const url = `${config.API_URL}/api/ews-crm/player/players-settings/update-iana-timezone`;
        await axiosInstance.put(url, null, { params });
        timezone && userStorage.setUserIanaTimeZoneCode(timezone);
        userStorage.setUserTimeZone(offsetMinutes);
        dispatch(getPlayerData());
      } catch (err) {
        return rejectWithValue(getError.responseDataMessage(err));
      }
    },
  ),
  fetchLanguages: createAbortThunk('settings/fetchLanguages', async (_, { rejectWithValue, getState }) => {
    try {
      const fetchedForLang = getState().myAccount.settings.languages?.forLang;
      const { options, language } = getCachiosOptions(fetchedForLang);
      const url = `${config.API_URL}/api/ews-crm/public/nomenclatures/languages`;
      const response = await cachiosInstance.get(url, options);
      return { items: response.data, forLang: language };
    } catch (err) {
      return rejectWithValue(getError.responseDataErrors(err));
    }
  }),
  fetchOdds: createAbortThunk('settings/fetchOdds', async (_, { rejectWithValue }) => {
    try {
      const url = `${config.API_URL}/api/ews-crm/public/nomenclatures/odds`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseDataErrors(err));
    }
  }),
  fetchNotificationTypes: createAbortThunk('settings/fetchNotificationTypes', async (_, { rejectWithValue }) => {
    try {
      const url = `${config.API_URL}/api/ews-crm/public/nomenclatures/notification-types`;
      const response = await axiosInstance.get(url);

      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseDataErrors(err));
    }
  }),
  updateUserLanguage: createAbortThunk('settings/updateUserLanguage', async (arg, { dispatch, rejectWithValue }) => {
    const { languageId } = arg;
    const params = {
      languageId,
    };

    try {
      const url = `${config.API_URL}/api/ews-crm/player/players-settings/update-language`;
      await axiosInstance.put(url, null, { params });
      dispatch(getPlayerData());
      return;
    } catch (err) {
      return rejectWithValue(getError.responseDataMessage(err));
    }
  }),
  updateUserOddType: createAbortThunk('settings/updateUserOddType', async (arg, { dispatch, rejectWithValue }) => {
    const { oddTypeId } = arg;

    try {
      const url = `${config.API_URL}/api/user-favorites/players-sport-preferences/player`;
      const { data } = await axiosInstance.patch(url, { oddTypeId });

      dispatch(setPreferredOddTypeId(data.oddTypeId));
    } catch (err) {
      return rejectWithValue(getError.responseDataMessage(err));
    }
  }),
  updateUserNotificationType: createAbortThunk(
    'settings/updateUserNotificationType',
    async (arg, { dispatch, rejectWithValue, getState }) => {
      const { noticationTypeId, active } = arg;
      const userId = getState().myProfile.user?.id;
      if (!userId) return;
      const params = {
        noticationTypeId,
        active,
      };
      try {
        const url = `${config.API_URL}/api/ews-crm/player/players-settings/update-notifications`;
        await axiosInstance.put(url, null, { params });

        const notification: PlayersNotification & { active: boolean } = {
          playerNotificationTypeId: Number(noticationTypeId),
          active,
        };
        dispatch(updateNotificationType({ notification }));
      } catch (err) {
        return rejectWithValue(getError.responseDataMessage(err));
      }
    },
  ),
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      SettingsThunks.fetchTimeZones.fulfilled,
      (state, action: PayloadAction<SettingsTypes.TimeZone[]>) => {
        state.timeZones = sortByProperty(action.payload, 'offsetMinutes');
      },
    );
    builder.addCase(
      SettingsThunks.fetchTimeZones.rejected,
      (state, action: PayloadAction<string | { rejectValue: string } | null | undefined>) => {
        state.error = action.payload as string;
      },
    );
    builder.addCase(logoutUser, () => initialState);
    builder
      .addCase(SettingsThunks.fetchLanguages.fulfilled, (state, action: PayloadAction<SettingsTypes.Languages>) => {
        state.languages = action.payload;
      })
      .addCase(
        SettingsThunks.fetchLanguages.rejected,
        (state, action: PayloadAction<string | { rejectValue: string } | null | undefined>) => {
          state.error = action.payload as string;
        },
      );
    builder
      .addCase(SettingsThunks.fetchOdds.fulfilled, (state, action: PayloadAction<SettingsTypes.Odds[] | null>) => {
        state.odds = action.payload;
      })
      .addCase(
        SettingsThunks.fetchOdds.rejected,
        (state, action: PayloadAction<string | { rejectValue: string } | null | undefined>) => {
          state.error = action.payload as string;
        },
      );
    builder
      .addCase(
        SettingsThunks.fetchNotificationTypes.fulfilled,
        (state, action: PayloadAction<SettingsTypes.NotificationTypes[] | null>) => {
          state.notificationTypes = action.payload;
        },
      )
      .addCase(
        SettingsThunks.fetchNotificationTypes.rejected,
        (state, action: PayloadAction<string | { rejectValue: string } | null | undefined>) => {
          state.error = action.payload as string;
        },
      );
    builder
      .addCase(SettingsThunks.updateUserNotificationType.pending, (state) => {
        state.updateNotificationTypeLoading = true;
      })
      .addCase(SettingsThunks.updateUserNotificationType.fulfilled, (state) => {
        state.updateNotificationTypeLoading = false;
      })
      .addCase(SettingsThunks.updateUserNotificationType.rejected, (state) => {
        state.updateNotificationTypeLoading = false;
      });
    builder.addMatcher(
      isAnyOf(
        SettingsThunks.updateUserLanguage.fulfilled,
        SettingsThunks.updateUserTimeZone.fulfilled,
        SettingsThunks.updateUserOddType.fulfilled,
        SettingsThunks.updateUserNotificationType.fulfilled,
      ),
      (state) => {
        state.updateErrorMessage = null;
        state.updateLoading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        SettingsThunks.updateUserLanguage.pending,
        SettingsThunks.updateUserTimeZone.pending,
        SettingsThunks.updateUserOddType.pending,
        SettingsThunks.updateUserNotificationType.pending,
      ),
      (state) => {
        state.updateErrorMessage = null;
        state.updateLoading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        SettingsThunks.updateUserLanguage.rejected,
        SettingsThunks.updateUserTimeZone.rejected,
        SettingsThunks.updateUserOddType.rejected,
        SettingsThunks.updateUserNotificationType.rejected,
      ),
      (state, action: PayloadAction<string | { rejectValue: string } | null | undefined>) => {
        state.updateErrorMessage = action.payload as string;
        state.updateLoading = false;
      },
    );
  },
});

export const selectSettings = {
  languages: (state: RootState): SettingsTypes.Language[] | undefined => state.myAccount.settings.languages?.items,
  timeZones: (state: RootState): SettingsTypes.TimeZone[] | null => state.myAccount.settings.timeZones,
  odds: (state: RootState): SettingsTypes.Odds[] | null => state.myAccount.settings.odds,
  notificationTypes: (state: RootState): SettingsTypes.NotificationTypes[] | null =>
    state.myAccount.settings.notificationTypes,
  errorMessage: (state: RootState): string | null => state.myAccount.settings.updateErrorMessage,
  updateLoading: (state: RootState): boolean => state.myAccount.settings.updateLoading,
  updateNotificationTypeLoading: (state: RootState): boolean => state.myAccount.settings.updateNotificationTypeLoading,
};

export default settingsSlice.reducer;
