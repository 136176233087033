import { PlayerRestrictions } from 'pages/myAccount/tabs/responsibleGambling/types/responsibleGambling.types';
import { BottomTabBarTypes, TAB_TYPE } from '../types/bottomTabBar.types';

export const isTabBarModalActive = (
  tabType: string,
  isMyBetsVisible: boolean,
  isSearchOpen: boolean,
  isFavouriteGamesModalOpen: boolean,
): boolean => {
  isMyBetsVisible;
  switch (tabType) {
    case TAB_TYPE.MYBETS:
      return isMyBetsVisible;
    case TAB_TYPE.SPORT_SEARCH:
      return isSearchOpen;
    case TAB_TYPE.CASINO_FAVOURITES:
      return isFavouriteGamesModalOpen;
    default:
      return false;
  }
};

const authenticatedTabs: BottomTabBarTypes.BarTypes[] = [
  TAB_TYPE.MYBETS,
  TAB_TYPE.CASINO_FAVOURITES,
  TAB_TYPE.SPORT_FAVOURITES,
];
export const handleFilterBottomBar = (
  tab: BottomTabBarTypes.TabBarType,
  playerRestrictionsByProducts: PlayerRestrictions.Restriction[],
  isAuthenticated: boolean | null,
): boolean => {
  if (
    (playerRestrictionsByProducts.length > 0 && tab.type === TAB_TYPE.MYBETS) ||
    (!isAuthenticated && authenticatedTabs.includes(tab.type))
  ) {
    return false;
  }

  return true;
};
