import { useEffect } from 'react';
import { useCountDown } from 'hooks/common/useCountDown';
import { formatTimer } from 'pages/myAccount/tabs/responsibleGambling/utils/responsibleGambling.utils';

type Props = {
  timerIsFinnish: (isDone: boolean) => void;
  endDate: string;
  timeZone?: 'localTime' | 'systemTime';
};
const CountDown: React.FC<Props> = ({ endDate, timerIsFinnish, timeZone = 'localTime' }) => {
  const { timer: timeDisplay, isExpired } = useCountDown(endDate, timeZone);

  useEffect(() => {
    if (isExpired) {
      timerIsFinnish(isExpired);
    }
  }, [isExpired, timerIsFinnish]);

  return (
    <>
      {formatTimer(timeDisplay?.minutes && timeDisplay?.minutes > 0 ? timeDisplay?.minutes : 0)}:
      {formatTimer(timeDisplay?.seconds && timeDisplay?.seconds > 0 ? timeDisplay?.seconds : 0)}
    </>
  );
};

export default CountDown;
