import { fitCover } from 'utils/common/cdnConfig';
import config from 'utils/config';
import { CDN, CDNResponse } from './CDN';

export class ImageCDN implements CDN {
  urlPath: string;
  urlFolder: string;
  width: string | number;
  height: string | number;
  pixelDensity: number;

  constructor(urlPath: string, urlFolder: string, width: number, height: number, pixelDensity?: number) {
    this.urlPath = urlPath;
    this.urlFolder = urlFolder;
    this.width = width || 'auto';
    this.height = height || 'auto';
    this.pixelDensity = pixelDensity || 2;
  }

  getUrl = (): string => {
    const w = typeof this.width === 'number' ? this.width * this.pixelDensity : 'auto';
    const h = typeof this.height === 'number' ? this.height * this.pixelDensity : 'auto';
    return `${config.CDN_IMAGE_API}${fitCover(w, h)}/common-content/gamification/${this.urlFolder}/${this.urlPath}`;
  };

  getFullUrl = (): string => {
    return `${config.CDN_URL}/${this.urlPath}`;
  };

  load = (alt: string, retryCount = 0): Promise<CDNResponse> =>
    new Promise<CDNResponse>((resolve, reject) => {
      const element: HTMLImageElement = new Image();
      element.addEventListener('error', (err) => {
        reject({ err, retryCount });
      });
      const elementLoaded = () => {
        resolve({ alt, element });
      };
      element.src = this.getUrl();
      if (element.complete) {
        elementLoaded();
      } else {
        element.onload = elementLoaded;
        element.addEventListener('load', elementLoaded, false);
      }
      element.alt = alt;
    });
}

export const getCdnImages = (images: ImageCDN[]): string[] => images.map((img) => img.getUrl());
