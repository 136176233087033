import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { usePrevious } from 'hooks/usePrevious';
import { useHideCookiesConsentFromPoker } from 'pages/playtechPoker/hooks/useHideCookiesConsentFromPoker';
import { playtechPokerSelectors } from 'pages/playtechPoker/slice/playtechPokerLogin.slice';
import { useHideCookiesConsentFromSvara } from 'pages/svaraAppLogin/hooks/useHideCookiesConsentFromSvara';
import { svaraAppSelectors } from 'pages/svaraAppLogin/slice/svaraAppLogin.slice';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { useAppSelector } from 'store';
import { useHideCookieConfig } from './useHideCookieConfig';

export const useShowCookie = ({ cookies }) => {
  const isLayoutConfigLoaded = useAppSelector(selectGeneral.isLayoutConfigLoaded);
  const { pathname } = useLocation();
  const prevPathName = usePrevious(pathname);
  const [isVisible, setIsVisible] = useState(false);

  const hideCookieConfig = useHideCookieConfig();
  const hideCookiesConsentFromPoker = useHideCookiesConsentFromPoker();
  const hideCookiesConsentFromSvara = useHideCookiesConsentFromSvara();

  const openFromPoker = useAppSelector(playtechPokerSelectors.openFromPoker);
  const openFromSvara = useAppSelector(svaraAppSelectors.openFromSvara);

  const hideForPoker = hideCookiesConsentFromPoker && (location.pathname?.includes('/playtech-poker') || openFromPoker);
  const hideForSvara = hideCookiesConsentFromSvara && openFromSvara;

  useEffect(() => {
    if (isLayoutConfigLoaded) {
      if (hideCookieConfig || hideForPoker || hideForSvara) {
        setIsVisible(false);
        return;
      }

      if (cookies['cookies-consent'] !== true) {
        setIsVisible(true);
      }

      if (prevPathName !== pathname && cookies['cookies-consent'] !== true) {
        setIsVisible(true);
      }
    }
  }, [cookies, pathname, prevPathName, isLayoutConfigLoaded, hideForPoker, hideForSvara]);

  return {
    isVisible,
    setIsVisible,
  };
};
