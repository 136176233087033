import { useEffect } from 'react';
import { useMobileAppRestrictions } from 'modules/casino/shared/features/mobileAppRestrictions/useMobileAppRestrictions';
import { useAppDispatch, useAppSelector } from 'store';
import { useProvidersFetchStatus } from './useProvidersFetchStatus';
import { selectGeneral } from '../../../../../../../../shared/common/features/general/slice/general.slice';
import { gameProvidersThunks } from '../slice/casinoGameProvidersSlice';

const useProvidersFetch = () => {
  const dispatch = useAppDispatch();
  const { providersFetchStatusInitial } = useProvidersFetchStatus();
  // const casinoWidgetConfig = useCasinoWidgetConfig();
  const mobileAppRestrictions = useMobileAppRestrictions();
  const isLayoutConfigLoaded = useAppSelector(selectGeneral.isLayoutConfigLoaded);

  useEffect(() => {
    // if (casinoWidgetConfig) {
    //   return;
    // }

    if (providersFetchStatusInitial && isLayoutConfigLoaded) {
      dispatch(gameProvidersThunks.fetchProviders({ mobileAppRestrictions }));
    }
  }, [providersFetchStatusInitial, dispatch, isLayoutConfigLoaded]);
};

export default useProvidersFetch;
