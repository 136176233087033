import { useCallback, useEffect, useState } from 'react';
import { useSetRefsLoaded } from 'modules/casino/shared/hooks/useSetRefsLoaded';
import { fitCover } from 'modules/casino/shared/utils/common/cdnConfig';
import config from 'utils/config';
import { CasinoWidgetsTypes } from '../../types/casinoWidgetsTypes';

const useProgressiveImage = (src, skip) => {
  const imageSrc = skip ? (src ? src : null) : null;
  const [sourceLoaded, setSourceLoaded] = useState(imageSrc);

  useEffect(() => {
    if (src && !skip) {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setSourceLoaded(src);
      };
      img.onerror = () => setSourceLoaded(src);
    }
  }, [src]);

  return sourceLoaded;
};
const widgetBackground = {};
const useWidgetBackground = ({
  backgroundWidgetResource,
  type,
}: {
  backgroundWidgetResource: string;
  type: CasinoWidgetsTypes.WidgetTypes;
}): any => {
  const { refLoaded, setRefs, ref: widgetRef } = useSetRefsLoaded();
  const hasCssBackground = backgroundWidgetResource?.includes('#') || backgroundWidgetResource?.includes('rgba(');
  const hasCachedBackground = widgetBackground[`${backgroundWidgetResource}${type}`];

  const getBackground = useCallback(() => {
    const { clientWidth, clientHeight } = widgetRef?.current || {};
    if (!clientWidth || !clientHeight) {
      return;
    }

    const quality = 2;
    const height = clientHeight * quality;
    const width = clientWidth * quality;

    return `${config.CDN_IMAGE_API}${fitCover(width, height, 'cover')}/${backgroundWidgetResource}`;
  }, [refLoaded, backgroundWidgetResource]);

  const url = useProgressiveImage(hasCachedBackground ? hasCachedBackground : getBackground(), hasCachedBackground);
  if (url && !hasCachedBackground) {
    widgetBackground[`${backgroundWidgetResource}${type}`] = `url(${url})`;
  }
  return {
    backgroundWrapper: hasCssBackground
      ? backgroundWidgetResource
      : widgetBackground[`${backgroundWidgetResource}${type}`],
    setRefs: setRefs,
  };
};

export default useWidgetBackground;
