import styled from 'styled-components';
import { Breakpoints } from 'theme/Theme';

export const CopyrightStyle = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  & > .d-flex {
    flex-direction: column;
    &:first-child {
      margin-bottom: 8px;
    }
  }
  @media screen and (min-width: ${Breakpoints.isMobile}px) {
    flex-direction: row;
    & > .d-flex {
      flex-direction: row;
      &:first-child {
        margin-bottom: 0;
        margin-right: 8px;
      }
    }
  }
`;
