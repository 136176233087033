import isEqual from 'lodash/isEqual';
import { WS } from 'shared/common/features/websockets/types/webSockets.types';
import { ClockNormalizedEvent } from './wsUpdate.types';
import { isEmpty } from '../helpersCommon';
import { Sport } from '../types/sportTypes';

export const wsEventClockUpdateFunc = <T extends ClockNormalizedEvent>(sportEvent: T, payload: WS.EventClock): void => {
  updateEventClockForSportEvent(sportEvent, payload);
};

const updateEventClockForSportEvent = (sportEvent: ClockNormalizedEvent, payload: WS.EventClock) => {
  if (!isEmpty(sportEvent)) {
    sportEvent.eventClockStatus.eventStatus = payload?.eventStatus;
    sportEvent.live = true;
    updateEventClockStatus(sportEvent?.eventClockStatus, payload);
  }
};

export const updateEventClockStatus = (
  eventClockStatus: Sport.EventClockStatus,
  payload: WS.EventClock,
): Sport.EventClockStatus => {
  if (!isEmpty(eventClockStatus)) {
    eventClockStatus.awayScore = payload.awayScore;
    eventClockStatus.homeScore = payload.homeScore;
    eventClockStatus.eventStatus = payload.eventStatus;
    eventClockStatus.matchStatus = payload.matchStatus;
    if (!isEqual(eventClockStatus.periodScore, payload.periodScore)) {
      eventClockStatus.periodScore = payload.periodScore; // new array, causes rerender
    }
    eventClockStatus.eventClock = payload.eventClock;
    eventClockStatus.sportSpecificProperties = payload.sportSpecificProperties;
    eventClockStatus.scoreChange = payload.scoreChange;
    eventClockStatus.lastUpdate = Date.now();
  }
  return eventClockStatus;
};

export const setInitialEventClockStatus = (eventClockStatus: Sport.EventClockStatus): Sport.EventClockStatus => ({
  ...eventClockStatus,
  lastUpdate: Date.now(),
});
