import { memo } from 'react';

import AppSectionImage from './AppSectionImage';
import Column from './Column';
import { Image } from './Image';
import Menu from './Menu';
import MenuHook from './MenuHook';
import MenuItem from './MenuItem';
import MenuLink from './MenuLink';
import SectionImage from './SectionImage';
import SectionTitle from './SectionTitle';
import SocialIcon from './SocialIcon';

export const FooterUI = {
  AppSectionImage: memo(AppSectionImage),
  Column: Column,
  Image: memo(Image),
  Menu: Menu,
  MenuItem: memo(MenuItem),
  MenuLink: memo(MenuLink),
  MenuHook: memo(MenuHook),
  SocialIcon: memo(SocialIcon),
  SectionImage: memo(SectionImage),
  SectionTitle: memo(SectionTitle),
};
