import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import {
  selectTournamentsButton,
  clearTournamentsButtonData,
  fetchTournaments,
} from '../slice/tournamentsButton.slice';

export const useTournaments = ({ isFreePlay, isAuthenticated, gameType, gameId, providerId, product }) => {
  const isLoading = useAppSelector(selectTournamentsButton.isLoading);
  const data = useAppSelector(selectTournamentsButton.data);
  const error = useAppSelector(selectTournamentsButton.error);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) dispatch(clearTournamentsButtonData());
    if (!isFreePlay && isAuthenticated && gameType && gameId && providerId && product) {
      dispatch(fetchTournaments({ gameType, gameId, providerId, product }));
    }
    return () => {
      dispatch(clearTournamentsButtonData());
    };
  }, [gameType, gameId, providerId, product]);

  return { isLoading, data, error };
};
