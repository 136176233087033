import { getRemainingTime } from 'modules/casino/shared/utils/dateUtils';
import { PromoWidgetsTypes } from './promoWidgetTypes';

export const getDateDifference = (
  { startDateTime, endDateTime, isVisiblePromoTimer, isVisibleExpiredPromoTimer }: PromoWidgetsTypes.PromoItem,
  layout: PromoWidgetsTypes.GridLayoutType,
  hasSeconds: boolean = true,
) => {
  const timeNow = Date.now();
  switch (isVisiblePromoTimer && layout) {
    case 'layout_1':
    case 'layout_2':
    case 'layout_3':
      return (
        /* Removing 59999ms (59.[9]s) to account for last minute as we don't diplay seconds in some cases */
        (startDateTime &&
          startDateTime > timeNow && { ...getRemainingTime(startDateTime, timeNow - (!hasSeconds ? 59999 : 0)) }) ||
        /* Removing 59999ms (59.[9]s) to account for last minute as we don't diplay seconds in some cases */
        (endDateTime &&
          endDateTime > timeNow && { ...getRemainingTime(endDateTime, timeNow - (!hasSeconds ? 59999 : 0)) }) ||
        (isVisibleExpiredPromoTimer &&
          endDateTime &&
          endDateTime < timeNow && { days: undefined, hours: undefined, minutes: undefined, status: 'expiredText' }) ||
        undefined
      );
    case false:
      return undefined;
    case undefined:
    default:
      return endDateTime ? getRemainingTime(endDateTime) : undefined;
  }
};

export const handlePromoScrollMoveWith = ({
  itemWidth,
  itemsToScroll,
  additionalGap,
}: {
  itemWidth: number;
  itemsToScroll: number;
  additionalGap?: number;
}) => {
  const moveWith = itemWidth * itemsToScroll + (additionalGap ? additionalGap : 0);
  return {
    moveWith,
  };
};
