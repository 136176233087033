import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import { Promotions } from 'modules/casino/shared/utils/translationUtils/translationStrings';
import NoDataMessage from './NoDataMessage';
import TableRow from './TableRow';
import { prizeDropCSSNS } from '../../PrizeDropCellWidgetConstants';
import { PrizeDropWidgetTypes } from '../../PrizeDropCellWidgetTypes';

type Props = {
  rewardedPlayers: PrizeDropWidgetTypes.RewardedPlayer[];
};

const RewardedPlayersContainer: React.FC<Props> = ({ rewardedPlayers }) => {
  const { t } = useTranslation();

  if (isEmpty(rewardedPlayers)) {
    return <NoDataMessage message={t(Promotions.PrizeDrop.norewardedPlayers)} />;
  }

  return (
    <div className={`${prizeDropCSSNS}-widget__winner--wrapper`}>
      <div className={`${prizeDropCSSNS}-widget__winner--inner`}>
        <TableRow firstColText={t(Promotions.PrizeDrop.player)} secondColText={t(Promotions.PrizeDrop.win)} />
        <div className={`${prizeDropCSSNS}-widget__winner--scrollable no-scrollbars`}>
          {rewardedPlayers.map((reward, index) => (
            <TableRow
              key={`${reward.bonusName || ''}-${index}`}
              firstColText={reward.username || ''}
              secondColText={reward.bonusName || ''}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RewardedPlayersContainer;
