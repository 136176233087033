import { useCallback, useEffect, useRef } from 'react';

export const useIsUnmounted = (): (() => boolean) => {
  const isUnmounted = useRef(false);
  useEffect(() => {
    isUnmounted.current = false;
    return function cleanup(): void {
      isUnmounted.current = true;
    };
  }, []);
  const checker = useCallback((): boolean => {
    return isUnmounted.current;
  }, []);
  return checker;
};
