import { useEffect } from 'react';
import { isIOS } from 'react-device-detect';
import userStorage from 'pages/auth/login/UserStorage';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { selectNavigationMenu } from 'shared/common/features/navigationMenu/slice/navigationMenu.slice';
import { useAppSelector } from 'store';
import { useAuthentication } from './useAuthentication';
import { useLayoutConfigType } from './useLayoutConfigType';

const useAppHomePage = (): { homeRoute: string } => {
  const { isAuthenticated } = useAuthentication();
  const verticalsConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.VERTICALS,
    deviceLayout: 'general',
  });
  const hidePageForAppVersion =
    window.isRNWebView && isIOS && window.appVersion === verticalsConfig?.hideHomePageAppVersion;
  const IOSAppVersionHomePage = hidePageForAppVersion && verticalsConfig?.appVersionHomePage;
  const hasHomePage = useAppSelector(selectNavigationMenu.homePageVertical) && !hidePageForAppVersion;
  const isVisited = userStorage.getAppIsVisited();
  const hasNewUserPageUrl = verticalsConfig?.newUserHomePageUrl;

  useEffect(() => {
    if (isAuthenticated) {
      if (!isVisited && hasNewUserPageUrl) {
        userStorage.setIsAppVisited();
      } else if (isVisited && !hasNewUserPageUrl) {
        userStorage.removeIsAppVisited();
      }
    }
  }, [isAuthenticated, hasNewUserPageUrl, isVisited]);

  const appHomeRoute = hasHomePage ? '/' : verticalsConfig?.homePageURL || '/sports';
  const newUserPageUrl = verticalsConfig?.newUserHomePageUrl || appHomeRoute;
  const userHomeRoute = isVisited ? appHomeRoute : !isAuthenticated ? newUserPageUrl : appHomeRoute;
  const homeRoute = IOSAppVersionHomePage ? IOSAppVersionHomePage : hasNewUserPageUrl ? userHomeRoute : appHomeRoute;

  return { homeRoute };
};

export default useAppHomePage;
