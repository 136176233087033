import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Flex } from 'components/shared/layout';
import { BANK_ROUTE } from 'pages/myAccount/tabs/cashier/utils/cashier.utils';
import { refreshWidget } from 'shared/common/features/integrations/cashierWidget/cashierWidget.slice';
import { MyProfile as MyProfileComponent } from 'shared/common/features/myProfile';
import AmountCurrency from 'shared/common/features/myProfile/components/AmountCurrency';
import { useUserBalance } from 'shared/common/features/myProfile/hooks/useUserBalance';
import { useAppDispatch } from 'store';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import DepositButton from './DepositButton';
import { useGetMainNavBarButtonsLayout } from './mainNavbarButtons/hooks/useGetMainNavBarButtonsLayout';
import useNavUserLogged from '../../hooks/useNavUserLogged';
import NavUserButton from '../common/NavUserButton';
import NavUserGamificationButton from '../common/NavUserGamificationButton';

interface Props {
  showBalance: boolean;
  showDeposit: boolean;
}

const NavUserLogged: React.FC<Props> = ({ showDeposit }) => {
  const {
    balanceVisibility,
    onClickShowMyAccount,
    notificationsCounter,
    isMyAccountModalShowing,
    hasUnseenOffers,
    gamificationProfile,
  } = useNavUserLogged();
  const { depositBtn } = useGetMainNavBarButtonsLayout({});
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { balanceAmount } = useUserBalance();

  return (
    <Flex.ASJE className="nav-user nav-user__logged-in">
      {depositBtn?.isVisible && <DepositButton />}
      {balanceVisibility && (
        <>
          <Flex.Col className="my-auto">
            <Flex.JE className="navbar-text nav-user__deposit">
              <AmountCurrency currencyClassName="nav-user__currency" amount={balanceAmount} />
            </Flex.JE>
            {showDeposit && (
              <Flex.JE className="navbar-text">
                <Link
                  className="mnav__link--active mnav__link--active--static"
                  to={`${BANK_ROUTE}?tab=deposit`}
                  state={{ from: `${location.pathname}${location.search}` }}
                  onClick={() => dispatch(refreshWidget())}
                >
                  {t(MyProfile.Button.depositProfileBtn)}
                </Link>
              </Flex.JE>
            )}
          </Flex.Col>
        </>
      )}
      <Flex.ColCenter className="egtd-dropdown nav-user__dropdown">
        {!gamificationProfile ? (
          <NavUserButton
            key={isMyAccountModalShowing.toString()}
            onClick={onClickShowMyAccount}
            icon={isMyAccountModalShowing ? 'user-active' : 'user'}
            notificationsCounter={notificationsCounter}
            hasUnseenBonusOffers={hasUnseenOffers}
            dataQid="navigation-user"
          />
        ) : (
          <NavUserGamificationButton
            key={isMyAccountModalShowing.toString()}
            onClick={onClickShowMyAccount}
            icon={isMyAccountModalShowing ? 'user-active' : 'user'}
            notificationsCounter={notificationsCounter}
            hasUnseenBonusOffers={hasUnseenOffers}
            dataQid="navigation-user"
            gamificationIcon={gamificationProfile?.playerLevel?.imageUrl}
          />
        )}
        {isMyAccountModalShowing && <MyProfileComponent />}
      </Flex.ColCenter>
    </Flex.ASJE>
  );
};

export default memo(NavUserLogged);
