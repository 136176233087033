import CardDescription from './CardDescription';
import CardMeta from './CardMeta';
import CardThumb from './CardThumb';
import CardTitle from './CardTitle';

export const Layout01 = {
  CardTitle: CardTitle,
  CardDescription: CardDescription,
  CardThumb: CardThumb,
  CardMeta: CardMeta,
};
