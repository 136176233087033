import React, { CSSProperties } from 'react';
import clsx from 'clsx';

/** ModalUI Title */
interface TitleProps {
  className?: string;
  text: string;
  hasAccent?: boolean;
  children?: React.ReactNode;
  style?: CSSProperties;
  hasCustomStyle?: boolean;
}

const Title: React.FC<TitleProps> = ({ className, text, hasAccent, children, style, hasCustomStyle }) => {
  const textClass = clsx(
    hasCustomStyle ? ' modal__title--custom' : 'modal-terms__title',
    !hasCustomStyle && hasAccent && 'accent',
    className,
  );

  return (
    <span className={textClass} style={style}>
      {text}
      {children}
    </span>
  );
};

export default Title;
