import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { casinoWidgetsSelectors, fetchBigWins } from '../../../slice/casinoWidgetsSlice';

type UseWidgetBigWinsFetchProps = {
  widgetId: string;
  query: string;
};

export const useWidgetBigWinsFetch = ({ widgetId, query }: UseWidgetBigWinsFetchProps) => {
  const dispatch = useAppDispatch();
  const isBigWinsFetched = useAppSelector((state) => casinoWidgetsSelectors.bigWinsCollectionFetched(state, widgetId));

  useEffect(() => {
    if (query && widgetId && !isBigWinsFetched) {
      dispatch(fetchBigWins({ query, id: widgetId }));
    }
  }, [dispatch, query, widgetId, isBigWinsFetched]);
};
