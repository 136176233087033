import { RefObject, useEffect, useRef } from 'react';
import { getJackpotImageSources } from '../jackpotUtils';

export const useJackpotLevelScale = ({
  refObj,
  scaleRef,
  panelUrl,
}: {
  refObj: RefObject<HTMLDivElement>;
  scaleRef: React.MutableRefObject<HTMLDivElement | null>;
  panelUrl: string;
}) => {
  const imageSourcesRef = useRef<string>('');

  if (refObj?.current && !imageSourcesRef.current && scaleRef?.current) {
    const width = refObj.current.offsetWidth;
    const height = refObj.current.clientHeight;
    imageSourcesRef.current = getJackpotImageSources(panelUrl, width, height, 2, false);
    const designWidth = scaleRef.current.clientWidth;
    scaleRef.current.style.scale = `${width / designWidth}`;
  }

  const ro = new ResizeObserver((entries) => {
    for (const entry of entries) {
      const { width } = entry.contentRect;
      if (width) {
        if (refObj.current && scaleRef.current) {
          const designWidth = scaleRef.current.clientWidth;
          scaleRef.current.style.scale = `${refObj.current.offsetWidth / designWidth}`;
        }
      }
    }
  });

  useEffect(() => {
    if (refObj.current) {
      ro.observe(refObj.current);
    }
  }, [refObj]);
  return { imageSource: imageSourcesRef.current };
};
