import { useTranslation } from 'react-i18next';
import { resetTicketInfoState } from 'modules/retail/shared/slice/checkTicket.slice';
import { useCashierModalTicketErrors } from './useCashierModalTicketErrors';
import { useCashierTicketStatusInfo } from './useCashierTicketStatusInfo';
import { CashierModalUI } from '../../cashierModalUI/Index';
import { resetCashierCancelTicketStatus, resetPayoutTicketStatus } from '../../slice/cashier.slice';
import { resetAnexInfoState } from '../anex2Report/slice/anexReport.slice';
import { useAnexStatusInfo } from '../anex2Report/useAnexStatusInfo';
import {
  resetCheckTicketReprintInfoStatus,
  resetReprintReceiptInfo,
  resetReprintTicketHistoryDate,
} from '../reprintTicket/slice/reprintTicket.slice';

const DisplayErrorModal = () => {
  const { cashierCancelTicketStatusError, ticketTerminalInfoErrorMessage } = useCashierModalTicketErrors();
  const {
    cancelTicketStatus,
    payoutTicketStatus,
    ticketInfoStatus,
    reprintInfoStatus,
    reprintInfoError,
    ticketHistoryDataStatus,
    reprintCheckTicketError,

    reprintCheckTicketStatus,
  } = useCashierTicketStatusInfo();
  const { t } = useTranslation();
  const { anexInfoErrorMessage, anexInfoStatus } = useAnexStatusInfo();

  const errorMappings = [
    {
      errorStatus: cancelTicketStatus,
      errorMessage: cashierCancelTicketStatusError,
      resetErrorStatus: resetCashierCancelTicketStatus,
    },

    {
      errorStatus: payoutTicketStatus,
      errorMessage: t(`Cashier.ERROR.defaultError`),
      resetErrorStatus: resetPayoutTicketStatus,
    },
    {
      errorStatus: ticketInfoStatus,
      errorMessage: ticketTerminalInfoErrorMessage,
      resetErrorStatus: resetTicketInfoState,
    },
    {
      errorStatus: anexInfoStatus,
      errorMessage: anexInfoErrorMessage,
      resetErrorStatus: resetAnexInfoState,
    },
    {
      errorStatus: reprintInfoStatus,
      errorMessage: t(`Cashier.ERROR.${reprintInfoError}`),
      resetErrorStatus: resetReprintReceiptInfo,
    },
    {
      errorStatus: ticketHistoryDataStatus,
      errorMessage: t(`Cashier.ERROR.defaultError`),
      resetErrorStatus: resetReprintTicketHistoryDate,
    },
    {
      errorStatus: reprintCheckTicketStatus,
      errorMessage: t(`Cashier.ERROR.${reprintCheckTicketError}`),
      resetErrorStatus: resetCheckTicketReprintInfoStatus,
    },
  ];

  return (
    <>
      {errorMappings.map((errorMapping, index) => (
        <CashierModalUI.ErrorMessage
          key={errorMapping.errorStatus + index}
          errorStatus={errorMapping.errorStatus}
          errorMessage={`${errorMapping.errorMessage}`}
          resetErrorStatus={errorMapping.resetErrorStatus}
        />
      ))}
    </>
  );
};
export default DisplayErrorModal;
