import React, { ErrorInfo, ReactNode } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { Icon } from 'components/shared';
import { Flex } from 'components/shared/layout/Flex';
import { UI } from 'components/shared/UI';
import { ErrorHandlerTypes, setErrorData } from 'shared/common/sharedSlices/slice/errorLogger.slice';
import { store } from 'store';
import { isProductionBuild } from 'utils/common/helpersCommon';

interface Props {
  children: ReactNode;
}

const ErrorBoundary: React.FC<Props> = ({ children }) => {
  const ErrorFallback = ({ error, resetErrorBoundary }) => {
    return (
      <>
        <Flex.ColAC className={`mx-auto p-3 search-msg__wrapper`}>
          <Icon className="mb-3 search-msg__icon" icon={'exclamation-solid'} />
          <span className="mb-3 text-center search-msg__text">We are sorry. there was an error loading this page.</span>
          <UI.Button size="lg" onClick={resetErrorBoundary} text="Reload page" />
        </Flex.ColAC>
        {!isProductionBuild && error && (
          <div className="mt-2 row text-light">
            <details
              className="col-xs-12 col-lg-8 offset-lg-2"
              style={{ borderRadius: '5px', backgroundColor: '#333746' }}
            >
              <summary className="my-2 p-2 h4">Error details</summary>
              <div className="mt-2 p-3" style={{ backgroundColor: '#232736' }}>
                <code>{error?.stack?.toString()}</code>
              </div>
            </details>
          </div>
        )}
      </>
    );
  };

  const onErrorHandler = (error: Error, errorInfo: ErrorInfo) => {
    store.dispatch(setErrorData({ errorData: { error, errorInfo }, handleFrom: ErrorHandlerTypes.ERROR_BOUNDARY }));
  };

  const onResetHandler = () => {
    window.location.reload();
  };

  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={onErrorHandler} onReset={onResetHandler}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
