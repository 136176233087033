import React from 'react';
import { Dropdown, DropdownItem } from 'components/shared/dropdown';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { SettingsThunks, selectSettings } from 'pages/myAccount/tabs/settings/slice/settings.slice';
import { transformOdds } from 'pages/myAccount/tabs/settings/utils/settings.utils';
import { setPreferredOddTypeId, selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { FooterTypes } from '../types/appFooter.types';

interface Props {
  section: FooterTypes.Section;
  isDrawer?: boolean;
}

export const Odds: React.FC<Props> = ({ section, isDrawer }) => {
  const odds = useAppSelector(selectSettings.odds);
  const settingsOddTypeId = useAppSelector(selectMyProfile.preferredOddTypeId);
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);

  const dispatch = useAppDispatch();
  const currentOddText = odds?.find((odd) => odd?.id === settingsOddTypeId)?.nameTranslations;

  const handleDropdownClick = (isShown: boolean) => {
    if (isShown) {
      dispatch(SettingsThunks.fetchOdds());
    }
  };

  const handleChangeDropdown = (oddTypeId: number) => {
    dispatch(setPreferredOddTypeId(oddTypeId));
    isAuthenticated && dispatch(SettingsThunks.updateUserOddType({ oddTypeId }));
  };

  const transformOddsData = transformOdds(odds);
  return (
    <Dropdown
      buttonDefaultClassName={isDrawer ? true : false}
      buttonClassName={isDrawer ? '' : 'p-0 footer-menu__link'}
      titleClassName={isDrawer ? '' : 'mr-1'}
      title={isDrawer ? currentOddText : section?.text || ''}
      onDropdownClick={handleDropdownClick}
      className={isDrawer ? '' : 'footer-system__item'}
      dropdownClassName={isDrawer ? '' : 'pos-l-auto pos-r-0'}
    >
      {transformOddsData?.map((odd, index) => (
        <DropdownItem
          handleChangeDropdown={handleChangeDropdown}
          isActive={odd.value === settingsOddTypeId}
          key={index}
          title={odd.label}
          value={odd.value}
          dataQid={`userOdds-${odd.value}`}
        />
      ))}
    </Dropdown>
  );
};
