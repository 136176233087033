import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { MonthlyHistory } from '../types/monthlyHistory.types';
import { getTimePeriod, normalizeResponse } from '../utils/monthlyHistory.utils';

export const monthlyHistoryThunks: MonthlyHistory.Thunks = {
  fetchSummary: createAbortThunk('monthlyHistory/summary', async (props, { rejectWithValue }) => {
    try {
      const url = `${config.API_URL}/api/reporting/player/crm/monthly-summary`;
      const response = await axiosInstance.post(url, {
        fromDate: getTimePeriod(props.month, props.year).fromDate,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.message(err));
    }
  }),
  fetchLimits: createAbortThunk('monthlyHistory/limits', async (props, { rejectWithValue }) => {
    try {
      const url = `${config.API_URL}/api/ews-crm/player/players-settings/player-limits-history`;
      const response = await axiosInstance.post(url, {
        page: props.page,
        pageSize: props.pageSize,
        filter: { dateRange: getTimePeriod(props.month, props.year) },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.message(err));
    }
  }),
};

export const { fetchSummary, fetchLimits } = monthlyHistoryThunks;

const initialState: MonthlyHistory.State = {
  filters: {
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    page: 1,
    pageSize: 10,
    total: 0,
  },
  data: {
    summary: null,
    limits: null,
  },
  error: {
    summary: null,
    limits: null,
  },
  isLoading: {
    summary: false,
    limits: false,
  },
  isExpanded: {
    limits: false,
  },
};

const monthlyHistorySlice = createSlice({
  name: 'monthlyHistory',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.filters.page = action.payload;
    },
    setMonth(state, action: PayloadAction<number>) {
      state.filters.month = action.payload;
    },
    setYear(state, action: PayloadAction<number>) {
      state.filters.year = action.payload;
      const currentMonth = new Date().getMonth();
      if (state.filters.month && state.filters.month > currentMonth && action.payload === new Date().getFullYear()) {
        state.filters.month = currentMonth;
      }
    },
    setIsExpanded(state, action: PayloadAction<boolean>) {
      state.isExpanded.limits = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSummary.pending, (state: MonthlyHistory.State) => {
        state.isLoading.summary = true;
        state.isExpanded.limits = false;
        state.data.summary = null;
        state.data.limits = null;
        state.error.summary = null;
      })
      .addCase(
        fetchSummary.fulfilled,
        (state: MonthlyHistory.State, action: PayloadAction<MonthlyHistory.ApiResponse>) => {
          state.isLoading.summary = false;
          state.data.summary = normalizeResponse(action.payload.content);
        },
      )
      .addCase(
        fetchSummary.rejected,
        (state: MonthlyHistory.State, action: PayloadAction<MonthlyHistory.ErrorData>) => {
          state.isLoading.summary = false;
          state.error.summary = action.payload;
        },
      );
    builder
      .addCase(fetchLimits.pending, (state: MonthlyHistory.State) => {
        state.isLoading.limits = true;
        state.error.limits = null;
      })
      .addCase(
        fetchLimits.fulfilled,
        (state: MonthlyHistory.State, action: PayloadAction<MonthlyHistory.FetchResponseDataLimits>) => {
          state.isLoading.limits = false;
          state.data.limits = [
            ...(state.data.limits ? state.data.limits : []),
            ...(action.payload.results ? action.payload.results : []),
          ];
          state.filters.page = action.payload.page;
          state.filters.total = action.payload.total;
        },
      )
      .addCase(fetchLimits.rejected, (state: MonthlyHistory.State, action: PayloadAction<MonthlyHistory.ErrorData>) => {
        state.isLoading.limits = false;
        state.error.limits = action.payload;
      });
    builder.addCase(logoutUser, () => initialState);
  },
});

export const { setPage, setMonth, setYear, setIsExpanded } = monthlyHistorySlice.actions;
export default monthlyHistorySlice.reducer;

export const selectMonthlyHistory = {
  filters: (state: RootState): MonthlyHistory.Filters => state.myAccount.monthlyHistory.filters,
  summaryData: (state: RootState): MonthlyHistory.SummaryItem | null => state.myAccount.monthlyHistory.data.summary,
  summaryError: (state: RootState): MonthlyHistory.ErrorData => state.myAccount.monthlyHistory.error.summary,
  summaryIsLoading: (state: RootState): boolean => state.myAccount.monthlyHistory.isLoading.summary,
  limitsData: (state: RootState): MonthlyHistory.LimitsItem[] | null => state.myAccount.monthlyHistory.data.limits,
  limitsError: (state: RootState): MonthlyHistory.ErrorData => state.myAccount.monthlyHistory.error.limits,
  limitsIsLoading: (state: RootState): boolean => state.myAccount.monthlyHistory.isLoading.limits,
  limitsIsExpanded: (state: RootState): boolean => state.myAccount.monthlyHistory.isExpanded.limits,
};
