import React, { useEffect, useRef, useState, useCallback, RefObject, ReactElement } from 'react';
import clsx from 'clsx';
import { Text } from 'components/shared';
import { Button } from 'components/shared/buttons';
import Icon from 'components/shared/Icon';
import { useWindowDimensions } from 'hooks/useWindowDimenions';
import { useAppDispatch, useAppSelector } from 'store';
import { DropdownProvider } from './DropdownContext';
import { setDropdownBackdrop } from './slice/dropdownBackdrop.slice';
import SportIconCDN from '../icons/SportIconCDN';

/** Dropdown Backdrop */
const DropdownBackdrop = () => {
  return <div className="backdrop dropdown-backdrop" />;
};

/** Dropdown */
interface Props {
  /** Dropdown Wrapper optional CSS class */
  className?: string;
  /** Dropdown Toggler text */
  title?: string | ReactElement;
  /** Dropdown Toggler text optional CSS class */
  titleClassName?: string;
  /** Include Toggler default CSS class? */
  buttonDefaultClassName?: boolean;
  /** Dropdown Toggler optional CSS class */
  buttonClassName?: string;
  children?: JSX.Element | JSX.Element[];
  /** Dropdown menu is right aligned relative to toggler? */
  dropdownRight?: boolean;
  /** Dropdown menu optional CSS class */
  dropdownClassName?: string;
  /** Should the dropdowm drop up if close to window bottom end? */
  enableDropUp?: boolean;
  onDropdownClick?: (isShown: boolean) => void;
  buttonRef?: RefObject<HTMLButtonElement>;
  icon?: React.ReactNode;
  /** Create grouped drop down */
  isGrouped?: boolean;
  /** Sport Icon */
  sportIcon?: number;
  /** Extend */
  extend?: {
    show: boolean;
    setShow: (show?: boolean) => void;
  };
}

const Dropdown: React.FC<Props> = ({
  className,
  title,
  titleClassName,
  children,
  dropdownRight,
  dropdownClassName,
  enableDropUp = true,
  buttonDefaultClassName = true,
  buttonClassName,
  onDropdownClick,
  buttonRef,
  icon,
  isGrouped,
  sportIcon,
  extend,
}) => {
  const cssNS = 'egtd-dropdown';
  const dropdown = useRef<HTMLDivElement>(null);
  const [dropdownContentPos, setDropdownContentPos] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const { isDropdownVisible } = useAppSelector((state) => state.platformDropdownBackdrop);
  const dispatch = useAppDispatch();
  const { height } = useWindowDimensions();

  const onClickHandler = useCallback(() => {
    extend?.setShow(!extend.show) || setShowDropdown(!showDropdown);
    onDropdownClick && onDropdownClick(!showDropdown);
    dispatch(setDropdownBackdrop(!isDropdownVisible));
    dropdown.current !== null && setDropdownContentPos(dropdown.current.getBoundingClientRect().bottom);
  }, [dispatch, isDropdownVisible, onDropdownClick, showDropdown, extend?.show]);

  const handleClickOutside = (event) => {
    if (isDropdownVisible) {
      dispatch(setDropdownBackdrop(false));
    }
    if (dropdown.current && !dropdown.current.contains(event.target)) {
      onDropdownClick && onDropdownClick(!showDropdown);
      extend?.setShow(false) || setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (extend?.show || showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [extend?.show, showDropdown, height]);

  //TODO DONT REMOVE
  // const childrenWithProps = React.Children.map(children, (child) => {
  //   return React.cloneElement(child, {
  //     onClickHandler: onClickHandler,
  //   });
  // });

  const dropdownClass = clsx(`${cssNS}`, isGrouped && `${cssNS}-group`, className);
  const buttonClass = clsx(
    buttonDefaultClassName && 'd-inline-flex btn btn-exp-toggler btn-dropdown',
    (extend ? extend.show : showDropdown) && 'toggled',
    buttonClassName,
  );
  const titleClass = clsx(titleClassName ? titleClassName : 'btn-dropdown__title');
  const iconClass = clsx('btn-exp-toggler__icon', (extend ? extend.show : showDropdown) && 'rotated-180');
  const titleWrap = `${cssNS}-title-wrap`;

  const dropdownContentClass = clsx(
    `no-scrollbars ${cssNS}-content`,
    dropdownRight && `${cssNS}-content-right`,
    dropdownClassName,
    enableDropUp && dropdown.current && height - dropdown.current.getBoundingClientRect().bottom < 220 && 'dropup',
  );

  return (
    <DropdownProvider value={{ onClickHandler: extend?.setShow || onClickHandler }}>
      <div className={dropdownClass} ref={dropdown}>
        <Button classNames={buttonClass} onClickHandler={onClickHandler} buttonRef={buttonRef}>
          <span className={titleWrap}>
            {sportIcon && sportIcon > 0 ? <SportIconCDN type="dropDownGroup" icon={sportIcon} /> : null}
            {title && <Text className={titleClass} text={title} />}
          </span>
          {icon && icon}
          <Icon className={iconClass} icon={`chevron`} />
        </Button>
        {(extend ? extend.show : showDropdown) ? (
          <>
            <div className={dropdownContentClass} style={{ maxHeight: `${height - dropdownContentPos - 110}px` }}>
              {children}
            </div>
            {isDropdownVisible && <DropdownBackdrop />}
          </>
        ) : null}
      </div>
    </DropdownProvider>
  );
};

export default React.memo(Dropdown);
