import { useCallback, useEffect } from 'react';
import useHeaderConfig from 'shared/common/hooks/useHeaderConfig';
import { useAppDispatch, useAppSelector } from 'store';
import { setDrawer } from '../../drawer/slice/drawer.slice';
import { useGamificationWidgetConfig } from '../../integrations/gamification/hooks/useGamificationWidgetConfig';
import { GamificationUser } from '../../integrations/gamification/types/gamification.types';
import { selectIntegrations } from '../../integrations/slice/integrations.slice';
import { fetchCategories, selectOffersTab } from '../../myProfile/sections/tabs';
import { myChallengesThunks } from '../../myProfile/sections/tabs/captainUpTab/slice/clubTab.slice';
import {
  notificationThunks,
  selectNotification,
} from '../../myProfile/sections/tabs/notificationsTab/notificationSlice';
import { selectMyProfile, showMyProfileModal } from '../../myProfile/slice/myProfile.slice';

type HookReturnType = {
  balanceVisibility: boolean;
  onClickShowMyAccount: (e) => void;
  notificationsCounter: number;
  isMyAccountModalShowing: boolean;
  hasUnseenOffers: boolean;
  gamificationProfile: GamificationUser.Profile | null;
};

const useNavUserLogged = (): HookReturnType => {
  const dispatch = useAppDispatch();
  const inboxNotificationCounter = useAppSelector(selectNotification.notificationCounter);
  const hasOffersFetched = useAppSelector(selectOffersTab.hasFetched);
  const hasNotificationsFetched = useAppSelector(selectNotification.hasFetched);
  const unseenOffersCounter = useAppSelector(selectOffersTab.notificationCounter);
  const isMyAccountModalShowing = useAppSelector(selectMyProfile.isModalShowing);
  const balanceVisibility = useAppSelector(selectMyProfile.balanceVisibility);
  const allNotificationsFetched = hasOffersFetched && hasNotificationsFetched;
  const gamificationProfile = useAppSelector(selectIntegrations.getGamificationProfile);
  const inboxNotificationToDisplay = !allNotificationsFetched ? 0 : inboxNotificationCounter;
  const unseenBonusOffersToDisplay = !allNotificationsFetched ? 0 : unseenOffersCounter;

  const { isVisible: isVisibleGamification, isWidget } = useGamificationWidgetConfig();

  const { offersToggler } = useHeaderConfig();

  const isVisibleOffersTab = offersToggler?.isVisible ?? true;

  const notificationsCounter =
    !isVisibleOffersTab && unseenBonusOffersToDisplay > 0 ? unseenBonusOffersToDisplay : inboxNotificationToDisplay;

  const onClickShowMyAccount = useCallback(
    (e) => {
      e.preventDefault();

      dispatch(setDrawer(false));
      if (!isMyAccountModalShowing) {
        dispatch(fetchCategories());
        dispatch(notificationThunks.fetchInboxNotification());
        !isWidget && isVisibleGamification && dispatch(myChallengesThunks.getItems());
        dispatch(showMyProfileModal(true));
      }
    },
    [isMyAccountModalShowing, dispatch, isWidget, isVisibleGamification],
  );

  useEffect(() => {
    return () => {
      dispatch(showMyProfileModal(false));
    };
  }, [dispatch]);

  return {
    balanceVisibility,
    onClickShowMyAccount,
    isMyAccountModalShowing,
    notificationsCounter,
    hasUnseenOffers: unseenOffersCounter > 0,
    gamificationProfile,
  };
};
export default useNavUserLogged;
