import { favoriteGamesSelectors } from 'modules/casino/modules/container/cellsContainer/cellls/additionalGames/favourites/slice/casinoFavouriteGamesSlice';
import { CasinoFavouritesTypes } from 'modules/casino/modules/container/cellsContainer/cellls/additionalGames/favourites/types/casinoFavouriteGamesTypes';
import { useCasinoGamesFetchStatus } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useCasinoGamesFetchStatus';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { useInitialJackpotLevelsFetch } from 'modules/casino/shared/features/jackpots/hooks/useInitialJackpotLevelsFetch';
import { useAppSelector } from 'store';
import { useFetchFavouriteGames } from '../../../../../../cellls/additionalGames/favourites/hooks/useFetchFavouriteGames';
import { useCasinoCategoryFetch } from '../../../../hooks/useCasinoCategoryFetch';

export const useCasinoInGameFavouritesCategory =
  (): CasinoFavouritesTypes.UseCasinoInGameFavouritesCategoryReturnProps => {
    useInitialJackpotLevelsFetch();

    const subType = CategorySubTypes.IN_GAME_FAVOURITES;
    const { categoryId, layoutConfig, query } = useCasinoCategoryFetch({
      subType,
      categoryIdKey: subType,
      skipAbortFetch: true,
    });
    const { isLoading, hasSuccess, hasNoGames, hasError } = useCasinoGamesFetchStatus(
      categoryId,
      CategoryLayoutTypes.IN_GAME_FAVOURITES_CATEGORY,
    );
    useFetchFavouriteGames({
      subType,
      layoutConfig,
      query,
      hasSuccess,
      hasError,
      hasNoGames,
      isInGame: true,
    });
    const gamesCount = useAppSelector(favoriteGamesSelectors.favoriteGamesCount);

    return {
      categoryId,
      gamesCount,
      isLoading,
      hasSuccess,
      hasNoGames,
    };
  };
