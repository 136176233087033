import React, { useEffect } from 'react';
import { helpThunks, selectHelp, selectHelpMenus } from 'pages/help/helpSlice';
import { BurgerUI } from 'shared/common/features/burger/components/BurgerUI';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import DrawerHelpSectionNavLink from './HelpSectionNavLink';
import { DrawerUI } from '../DrawerUI';

interface Props {
  isActive: boolean;
}

const HelpDrawerSection: React.FC<Props> = ({ isActive }) => {
  const dispatch = useAppDispatch();

  const helpMenus = useAppSelector(selectHelpMenus);
  const activeCategoryId = useAppSelector(selectHelp.activeCategoryId);
  const hasHelpMenu = !isEmpty(helpMenus);

  useEffect(() => {
    if (!hasHelpMenu) {
      const promise = dispatch(helpThunks.fetchHelpItems({}));

      return () => {
        promise.abort();
      };
    }
  }, [dispatch, hasHelpMenu]);

  const renderItem = (item, idx) => {
    return <DrawerHelpSectionNavLink key={idx} item={item} isActive={String(item.id) === activeCategoryId} />;
  };

  return (
    <BurgerUI.ListWrapper>
      <DrawerUI.Section type="help" isActive={isActive}>
        {!isEmpty(helpMenus) &&
          Object.keys(helpMenus).map((sectionIndex, idx) => {
            const item = helpMenus[sectionIndex];
            return item.isActive && renderItem(item, idx);
          })}
      </DrawerUI.Section>
    </BurgerUI.ListWrapper>
  );
};

export default HelpDrawerSection;
