import { Messages } from '../utils/translationUtils/translationStrings';

export enum ContainerTypes {
  sportLobbyLive = 'sportLobbyLive',
  highlightsPerSport = 'highlightsPerSport',
  linkContainer = 'linkContainer',
  mixedHighlights = 'mixedHighlights',
  slider = 'slider',
  BonusesPromotionsSlider = 'BonusesPromotionsSlider',
  gamesSlider = 'gamesSlider',
  footer = 'footer',
  casinoLobby = 'casinoLobby',
  casinoMenu = 'casinoMenu',
  // virtualPreLobbyCell = 'slider',
  multilineHighlights = 'multilineHighlights',
  captainUpMenu = 'captainUpMenu',
  promo = 'promo',
  //new homePage containers
  gamesContainer = 'gamesContainer',
  casinoContainer = 'casinoContainer',
  topGames = 'topGames',
  topGamesNoTitle = 'topGamesNoTitle',
  casinoJackpots = 'casinoJackpots',
  casinoWidget = 'casinoWidget',
  promoWidget = 'promoWidget',
  videoBanner = 'videoBanner',
  lastWinners = 'lastWinners',
  liveSports = 'liveSports',
  steps = 'steps',
  brandText = 'brandText',
  termsAndConditions = 'termsAndConditions',
  videoPlayer = 'videoPlayer',
  sportEvents = 'sportEvents',
  landingPageSlider = 'landingPageSlider',
  doubleContainer = 'doubleContainer',
  tripleContainer = 'tripleContainer',
  providersMenu = 'providersMenu',
}

export const DEFAULT_ERROR_MSG = Messages.DefaultError.defaultErrorString;
export const DEFAULT_BET_SLIP_ERROR_MSG = 'BET_3031';
export const ERROR_MESSAGE_SOMETING_WENT_WRONG = 'BET_3000';

export const DEFAULT_CURRENCY = 'EUR';
