import React, { useRef } from 'react';
import clsx from 'clsx';
import { isMobileOnly } from 'react-device-detect';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { FormControl } from 'components/shared/forms/components';
import { Modal } from 'components/shared/Modal';
import { NotificationBlock } from 'components/shared/notifications';
import { UI } from 'components/shared/UI';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import NumpadKeyboard from 'modules/retail/shared/components/numericKeyboard/KeyboardNumeric';
import AuthBanner from 'pages/auth/components/AuthBanner';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { getLoginInfo } from 'pages/auth/login/utils/login.utils';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { useAppSelector } from 'store';
import { isCashier, isEmpty, isTerminal } from 'utils/common/helpersCommon';
import { QuickLoginFormTranslation } from 'utils/common/translationUtils/translationStrings';
import ErrorNotification from './ErrorNotification';
import { useGeolocationRestricted } from './hooks/useGeolocationRestricted';
import { useLoginPhoneConfirmation } from './hooks/useLoginPhoneConfirmation';
import useQuickLoginForm from './hooks/useQuickLoginForm';
import LoginInfo from './LoginInfo';
import RNAutofillLoginForm from './RNAutofillLoginForm';

const QuickLoginForm: React.FC = () => {
  const bodyRef = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation();
  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });
  const loginError = useAppSelector(selectAuthentication.loginError);
  const fetchFormFieldsError = useAppSelector(selectAuthentication.fetchFormFieldsError);
  const isFormLoading = useAppSelector(selectAuthentication.isFormLoading);
  const formFields = useAppSelector(selectAuthentication.loginFormFields);
  const formProps = useForm({ mode: 'onChange' });
  const loginInfoProps = getLoginInfo({ type: loginError?.error });
  // const [isKeyboardOpen, setIsKeyboardOpen] = useState(true);
  const showRetailLoginKeyboard = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.RETAIL_LOGIN_KEYBOARD,
    deviceLayout: 'general',
  });
  const isRetailLoginKeyboardActive = !isEmpty(showRetailLoginKeyboard) && showRetailLoginKeyboard;
  const isRetail = isCashier() || isTerminal();

  const {
    onSubmit,
    getCustomProps,
    closeQuickLoginForm,
    isSubmitButtonDisabled,
    handleKeyboardInput,
    submitCount,
    currentField,
    // resetInputValuesWhenRetailKeyboardUnmount,
  } = useQuickLoginForm(formProps, bodyRef);

  useLoginPhoneConfirmation(loginError?.error, closeQuickLoginForm);
  useGeolocationRestricted(loginError?.error, closeQuickLoginForm);

  const username = formProps.watch('username');
  const modalContentStyle = isLandscape && isMobileOnly ? { maxHeight: '100vh' } : undefined;

  const modalClass = clsx(isRetailLoginKeyboardActive && 'login--ssbt', 'no-scrollbars', 'login');

  const btnClass = clsx(isRetailLoginKeyboardActive ? 'w-100 mb-4 mt-3' : 'mt-3 px-5', 'btn-action'); // btn-action added for mobile app compatibility

  if (isFormLoading) return null;

  const social = formFields && Object.values(formFields)?.find((field) => field.name === 'socialMediaCredentials');
  const socialProps = social ? { ...social, ...getCustomProps(social) } : undefined;

  return (
    <Modal visible className={modalClass} contentStyle={modalContentStyle}>
      <div data-ics--global data-ics--login>
        <Modal.Body bodyRef={bodyRef}>
          <AuthBanner type="login" />
          {loginInfoProps ? (
            <LoginInfo {...loginInfoProps} closeModal={closeQuickLoginForm} />
          ) : (
            <FormProvider {...formProps}>
              <form onSubmit={formProps.handleSubmit(onSubmit)} className="mx-auto form-login">
                {!fetchFormFieldsError && (
                  <>
                    {formFields &&
                      Object.values(formFields).map((f, idx) => {
                        const customProps = getCustomProps(f);
                        const field = { ...f, ...customProps };
                        if (field.name === 'socialMediaCredentials') return;
                        return (
                          <FormControl
                            {...field}
                            key={idx}
                            componentLocation="login"
                            hasErrorIcon
                            hasSuccessIcon
                            externalError={!!loginError}
                          />
                        );
                      })}
                    {loginError?.errorMessage && (
                      <ErrorNotification
                        error={loginError?.errorMessage}
                        onClick={closeQuickLoginForm}
                        username={username}
                      />
                    )}
                    {loginError?.subErrorMessage && <div>{loginError?.subErrorMessage}</div>}
                    <UI.HTMLButton
                      type="submit"
                      id="egtd-btn--login" // next mobile app version should target this id
                      className={btnClass}
                      data-qid="login-btn"
                      disabled={isSubmitButtonDisabled}
                      onClick={formProps.handleSubmit(onSubmit)}
                      text={t(QuickLoginFormTranslation.Buttons.signIn)}
                    />
                    {window.isRNWebView && bodyRef.current && (
                      <>
                        <RNAutofillLoginForm formProps={formProps} />

                        <div className={'checkbox-container'}>
                          <input type="checkbox" id="webview-checkbox" className="webview-checkbox" />
                          <label htmlFor="webview-checkbox" className="label-checkbox">
                            {t(QuickLoginFormTranslation.MobileApp.saveCredentialsLabel)}
                          </label>
                        </div>
                      </>
                    )}
                    {!isRetail && (
                      <Link
                        to="/forgot-password"
                        className="form-login__link"
                        data-qid="forgot-pwd-link"
                        onClick={closeQuickLoginForm}
                      >
                        {t(QuickLoginFormTranslation.Buttons.forgottenPassword) + '?'}
                      </Link>
                    )}
                    {socialProps && (
                      <FormControl
                        {...socialProps}
                        componentLocation="login"
                        hasErrorIcon
                        hasSuccessIcon
                        externalError={!!loginError}
                      />
                    )}
                  </>
                )}
                {fetchFormFieldsError && (
                  <NotificationBlock title={fetchFormFieldsError} icon="info-solid" variant="error" />
                )}
              </form>
              {/* {isRetailLoginKeyboardActive && (
                <FormSwitch
                  name="show-keyboard"
                  onChange={() => {
                    setIsKeyboardOpen(!isKeyboardOpen);
                    isKeyboardOpen ? resetInputValuesWhenRetailKeyboardUnmount() : null;
                  }}
                  value={isKeyboardOpen}
                  label={
                    isKeyboardOpen ? t('Cashier.LoginKeyboard.hideKeyboard') : t('Cashier.LoginKeyboard.showKeyboard')
                  }
                  classNames="mt-2"
                />
              )} */}
            </FormProvider>
          )}
          {isRetailLoginKeyboardActive && (
            <NumpadKeyboard
              handleKeyboardInput={handleKeyboardInput}
              submitCount={submitCount}
              currentField={currentField}
            />
          )}
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default QuickLoginForm;
