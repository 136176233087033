import { useEffect, useRef } from 'react';
import { useIsUnmounted } from 'hooks/common/useIsUnmouted';
import {
  selectIntegrations,
  setIsCasinoSubWidgetLoaded,
} from 'shared/common/features/integrations/slice/integrations.slice';
import { useAppDispatch, useAppSelector } from 'store';
import useCasinoWidget from '../hooks/useCasinoWidget';
import { CasinoWidgetTypes, WidgetContainerTypes } from '../types/casinoWidget.types';

type Props = {
  containerType: WidgetContainerTypes;
  params: CasinoWidgetTypes.ContainerCategoryParams | CasinoWidgetTypes.ContainerWidgetParams;
  containerKey: string;
};

const CasinoContainerImplementation: React.FC<Props> = ({ containerType, params, containerKey }) => {
  const containerId = `cw-container-${containerKey}`;
  const containersRef = useRef<{ containers: Record<string, any> }>({ containers: {} });

  useCasinoWidget();
  const dispatch = useAppDispatch();
  const casinoWidgetRef = useRef<HTMLDivElement>(null);
  const isUnmount = useIsUnmounted();
  const isCasinoWidgetLoaded = useAppSelector(selectIntegrations.isCasinoWidgetLoaded);
  // const isSubWidgetLoaded = useAppSelector((state) => selectIntegrations.isCasinoSubWidgetLoaded(state, containerId));

  const containerParamsByType = {
    type: containerType,
    ...(containerType === WidgetContainerTypes.CATEGORY
      ? {
          containerCategoryParams: params,
        }
      : {
          containerWidgetParams: params,
        }),
  } as CasinoWidgetTypes.SubWidgetContainerParams;

  useEffect(() => {
    const config: CasinoWidgetTypes.AddContainerConfig = {
      containerId,
      params: {
        ...containerParamsByType,
      },
      listeners: {
        onContainerLoad,
      },
    };

    if (casinoWidgetRef) {
      if (isCasinoWidgetLoaded && window.widgetCasino && containersRef.current) {
        containersRef.current.containers[containerId] = window.widgetCasino?.addContainer?.(config);
      }

      return () => {
        if (isUnmount() && containersRef.current) {
          if (containersRef.current.containers[containerId]) {
            containersRef.current.containers[containerId].remove();
            delete containersRef.current.containers[containerId];
            dispatch(setIsCasinoSubWidgetLoaded({ key: containerId, isLoaded: false }));
          }
        }
      };
    }
  }, [isCasinoWidgetLoaded]);

  // useEffect(() => {
  //   const params = {};
  //   if (isSubWidgetLoaded && containersRef?.current?.containers?.[containerId]) {
  //     containersRef.current.containers[containerId]?.updateParams(params);
  //   }
  // }, [isSubWidgetLoaded, containerId]);

  const onContainerLoad = () => {
    dispatch(setIsCasinoSubWidgetLoaded({ key: containerId, isLoaded: true }));
  };

  return <div ref={casinoWidgetRef} id={containerId} className="cw-external-container w-100" />;
};

export default CasinoContainerImplementation;
