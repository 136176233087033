import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { resetUCNCodeCheck, selectUcnScanData } from '../slice/scanUCN.slice';

export const useCheckUCNCode = () => {
  const dispatch = useAppDispatch();
  const ucnCheckStatus = useAppSelector(selectUcnScanData.ucnCheckStatus);
  const { t } = useTranslation();
  const ucnErrorMessageInfo = useAppSelector(selectUcnScanData.ucnErrorMessageInfo);
  useEffect(() => {
    if (ucnCheckStatus === 'succeeded' && !isEmpty(ucnErrorMessageInfo)) {
      dispatch(
        showRootModal({
          modalType: 'UCN_CHECK_CASHIER_MODAL',
          modalId: 'UCN_CHECK_CASHIER_MODAL',
          modalPriority: ModalPriorityEnum.MEDIUM,
          modalProps: {
            responseMessage: ucnErrorMessageInfo,
            resetStatus: resetUCNCodeCheck,
            t: t,
          },
        }),
      );
    }
    if (ucnCheckStatus === 'failure') {
      dispatch(
        showRootModal({
          modalType: 'UCN_CHECK_CASHIER_MODAL',
          modalId: 'UCN_CHECK_CASHIER_MODAL',
          modalPriority: ModalPriorityEnum.MEDIUM,
          modalProps: {
            responseMessage: 'Cashier.UcnScan.failedScan',
            resetStatus: resetUCNCodeCheck,
            isPending: false,
            t: t,
          },
        }),
      );
    }
    if (ucnCheckStatus === 'pending') {
      dispatch(
        showRootModal({
          modalType: 'UCN_CHECK_CASHIER_MODAL',
          modalId: 'UCN_CHECK_CASHIER_MODAL',
          modalPriority: ModalPriorityEnum.MEDIUM,
          modalProps: {
            isPending: true,
            t: t,
          },
        }),
      );
    }
    dispatch(resetUCNCodeCheck());
  }, [ucnCheckStatus]);
};
