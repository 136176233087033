import { useMyProfileConfig } from 'shared/common/features/myProfile/hooks/useMyProfileConfig';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { getDefaultBusinessUnitThemeSkin } from 'shared/common/features/myProfile/utils/myProfile.utils';
import { useAppSelector } from 'store';

const usePreferredTheme = (): { preferredTheme: string } => {
  const myProfileLayout = useMyProfileConfig();
  const theme = useAppSelector(selectMyProfile.preferredTheme);

  const preferredTheme = myProfileLayout?.enableThemeSwitcher ? theme : getDefaultBusinessUnitThemeSkin(); //THIS IS TEMPORARY HACK

  return { preferredTheme };
};

export default usePreferredTheme;
