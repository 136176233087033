import { useCasinoLayoutConfig } from './useCasinoLayoutConfig';
import { LayoutConfigInnerLayoutTypes } from '../constants';

type CurrencyConfig = {
  currencySymbolFirst: boolean;
  spaceBetween: boolean;
  formatToComma: boolean;
};

export const useCurrencyConfig = (): CurrencyConfig => {
  const currencyDisplay = useCasinoLayoutConfig({
    innerLayout: LayoutConfigInnerLayoutTypes.CURRENCY_DISPLAY,
  });

  const currencySymbolFirst = currencyDisplay?.currencySymbolFirst ?? false;
  const spaceBetween = currencyDisplay?.spaceBetween ?? false;
  const formatToComma = currencyDisplay?.formatToComma ?? false;

  return {
    currencySymbolFirst,
    spaceBetween,
    formatToComma,
  };
};
