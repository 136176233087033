import React, { useMemo } from 'react';
import { useAppSelector } from 'store';
import { Sport } from 'utils/common/types/sportTypes';
import { SportDataTypes } from 'utils/common/types/sportTypes/sportData.types';
import ESportsScore from './ESportsScore';
import DoubleScoreType from './eventScoreTypeName/DoubleScoreType';
import SingleEventScore from './eventScoreTypeName/SingleEventScore';
import TripleScoreType from './eventScoreTypeName/TripleScoreType';
import { getScoreType } from './utils/eventScore.utils';
import { EventSelectors } from '../types/eventProps.types';
import { hideEventScore } from '../utils/eventType.utils';

type EventScoreWrapperType = {
  sportId: number;
  eventStatusConfig: SportDataTypes.EventStatusConfig | null;
  sportType?: Sport.SportType;
  eventStatus?: Sport.EventStatus;
  eventSelectors?: EventSelectors;
  eventClockStatus?: Sport.EventClockStatus;
  isHorizontal?: boolean;
  sportEventId?: number;
};

const EventScoreWrapper: React.FC<EventScoreWrapperType> = ({
  eventClockStatus: eventClockStatusProps,
  isHorizontal,
  sportId,
  sportEventId,
  eventSelectors,
  eventStatusConfig,
  sportType,
  eventStatus,
}) => {
  const eventScore = useAppSelector((state) =>
    eventSelectors && sportEventId ? eventSelectors.score(state, sportEventId, sportId) : undefined,
  );
  const hideLiveScore = useMemo(
    () => hideEventScore(sportId, sportType, eventStatus),
    [sportId, sportType, eventStatus],
  );

  const eventClockStatus = eventClockStatusProps || eventScore;
  const scoreType = getScoreType(sportId, eventStatusConfig);
  if (hideLiveScore || !eventClockStatus) return null;

  switch (scoreType) {
    case 'Double':
      return (
        <DoubleScoreType
          periodScore={eventClockStatus?.periodScore}
          homeScore={eventClockStatus?.homeScore}
          awayScore={eventClockStatus?.awayScore}
        />
      );

    case 'Triple':
      return (
        <TripleScoreType
          periodScore={eventClockStatus?.periodScore}
          homeScore={eventClockStatus?.homeScore}
          awayScore={eventClockStatus?.awayScore}
          sportSpecificProps={eventClockStatus?.sportSpecificProperties}
        />
      );

    case 'Esport':
      return (
        <ESportsScore
          periodScore={eventClockStatus?.periodScore}
          homeScore={eventClockStatus?.homeScore}
          awayScore={eventClockStatus?.awayScore}
          sportId={sportId}
        />
      );

    default:
      return (
        <SingleEventScore
          homeScore={eventClockStatus?.homeScore}
          awayScore={eventClockStatus?.awayScore}
          isHorizontal={isHorizontal}
          classNames="d-flex-ac"
        />
      );
  }
};
export default EventScoreWrapper;
