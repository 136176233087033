import { isMobileOnly, isTablet } from 'react-device-detect';

export const getGridCellInViewMargin = () => {
  if (isMobileOnly) {
    return '150px';
  }

  if (isTablet) {
    return '150px';
  }

  return '300px';
};
