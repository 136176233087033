import { isIOS, osVersion } from 'react-device-detect';

export const cdnConfig = {
  PROVIDER_LOBBY: '/common-content/provider-lobby',
  PROMOTIONS_MODULE: '/common-content/promotions',
  NOTIFICATIONS_MODULE: '/common-content/notifications',
  LANDING: '/common-content/landing-pages/container',
  LANDING_SLIDER: '/common-content/landing-pages/slider',
  SIGNUP_BANNERS: '/common-content/signup-banners',
  SOCIAL_ICONS: '/common-content/footer/social',
  SPORT_CAROUSEL: '/sport-content/slider',
  SPORT_ICONS: '/sport-content/sport-icons',
  TEAM_LOGOS: '/sport-content/match-info/team-logos',
  GAMIFICATION_MODULE: '/common-content/gamification',
  VIRTUALS_NUMBERS: '/sport-content/virtuals-numbers',
};

export const fitCover = (width?: number | string, height?: number | string, fit?: 'none' | 'contain' | 'cover') => {
  if (!width && !height) return '';

  const [major] = osVersion.split('.');

  const imgFit = width === 'auto' ? 'scale-down' : fit === 'none' ? '' : fit === 'contain' ? 'contain' : 'cover';
  const format = isIOS && parseInt(major) == 16 ? 'webp' : 'auto';

  return `fit=${imgFit},${width ? `width=${width},` : ''}${height ? `height=${height},` : ''},format=${format}`;
};
