import { PartialRecord } from 'utils/common/types/common.types';

export type OffersTabState = {
  status: CategoryStatus;
  hasFetched: boolean;
  error?: string;
  categories: NormalizedCategoriesMap;
  activeCategoryTitle: string;
  bonusStatusMap: BonusStatusMap;
  bonusErrorMap: BonusErrorMap;
  bonusStatusCode: BonusStatusCode;
  redirectedOfferId: string;
  termsOffer: PartialRecord<string, string>;
  bonusCancelInfo: string | null;
  bonusCancelInfoStatus: BonusStatus;
};

export type OffersTabLayout = 'layout_1' | 'layout_2';

export type CategoryStatus = 'Empty' | 'Failed' | 'Success';

export type BonusErrorMap = PartialRecord<string, string>;
export type BonusStatusCode = PartialRecord<string, number>;

export type BonusStatusMap = PartialRecord<string, BonusStatus>;

export type BonusStatus = 'succeeded' | 'pending' | 'failure' | 'idle';

export type NormalizedCategoriesMap = Record<string, Category>;

export type TermsMap = Record<number, Terms>;
export type Terms = {
  text: string;
  title: string;
  bonusCode: string;
};

//Contracts

export type PostAcceptResponse = AcceptWithPostActionResponse | AcceptWithoutPostActionResponse;

export type AcceptWithoutPostActionResponse = {
  expirationDate: string;
  isActive: boolean;
  bonusCompletionPercentage: string;
  title: string;
  description: string;
  bonusCode: string;
  termsAndConditionsId: number;
  details: string;
  statusId: BonusStatusID;
};

export type AcceptWithPostActionResponse = {
  expirationDate: string;
  isActive: boolean;
  postActionURL: string;
  postActionDetailsTextTranslationKey: string;
  buttonTranslationKey: string;
  termsAndConditionsId: number;
};

export type MyBonusAcceptResponse = {
  redirectNow: boolean;
};

export type BonusPostRequest = {
  bonusCode: string;
  id: string;
};

export type GetCategoriesResponse = Category[];

export type TermsResponse = {
  text: string;
};

export type Category = {
  title: string;
  alias: string;
  resourceName?: string;
  verticalUnseenCounter: number;
  promotions?: Promotion[];
};

export enum BonusStatusID {
  Initial = 1,
  InitialWithDeposit = 2,
  AcceptedAndWaitingForActivation = 3,
  Activated = 4,
}

export enum BonusTypes {
  WAGERING = 'wagering',
  FREE_BET = 'freeBet',
  FREE_SPIN = 'freeSpin',
  SURPRISE = 'surprise',
  AWARD = 'award',
  DEPOSIT_ROLLOVER = 'depositRollover',
  FREE_SPIN_WAGERING = 'freeSpinWagering',
  FreeSpinsWinRollover = 'freeSpinsWinRollover',
}

export type BonusButton = {
  buttonText: string;
  buttonUrl: string;
  buttonActionType: BonusButtonAction;
};

export type Bonus = {
  id: string;
  statusId: BonusStatusID;
  title: string;
  bonusCode: string;
  bonusExpirationDate: string;
  content: string;
  freeSpinGamesIds?: string[];
  bonusOfferCasinoGamesIds?: string[];
  resourceName?: string;
  buttons: BonusButton[];
  termsAndConditionsId: number;
  bannerResource?: string;
  bonusCompletion?: BonusCompletion;
  depositRollover?: BonusRollover;
  freeSpinInformation?: FreeSpinInformation;
  subContent?: string;
  bonusActivatedDate?: string;
  givenBonusAmount?: number;
  isUnseen: boolean;
  depositRolloverLeft?: number;
  bonusType: BonusTypes;
  isRequiredDepositRollover?: boolean;
};

export type PromotionType = keyof typeof PromotionTypeEnum;

export enum PromotionTypeEnum {
  Promotion = 'Promotion',
  Bonus = 'Bonus',
}

export type Promotion = {
  promotionId: number;
  title?: string;
  alias?: string;
  bannerResource?: string;
  promotionExpirationDate?: string;
  termsAndConditionsId: number;
  content?: string;
  type: PromotionType;
  bonuses: Bonus[];
};

export enum BonusButtonAction {
  Navigate = 'Navigate',
  Deposit = 'Deposit',
  Accept = 'Accept',
  Cancel = 'Cancel',
  None = 'None',
}

type VerticalCompletion = {
  vertical: string;
  leftToWagerAmount: number;
  completionPercentage: number;
  wageredAmount: number;
};

type BonusCompletion = {
  totalCompletionPercentage: number;
  totalWageredAmount: number;
  givenBonusAmount: number;
  verticals: VerticalCompletion[];
};

export type UnseenOffersType = {
  PlayerBonusIds: number[];
};

type BonusRollover = {
  totalCompletionPercentage: number;
  totalRolloverAmount: number;
  rolloverAmount: number;
  rolloverAmountLeft: number;
};

type FreeSpinInformation = {
  freeSpinCount: number;
  freeSpinCompletedCount: number;
  freeSpinCountLeft: number;
  freeSpinAchieveAmount?: number;
};
