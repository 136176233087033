import React from 'react';
import { Flex } from 'components/shared/layout';
import { bonusCollectionModalPrefix } from '../utils/bonusCollectionsModal.utils';
type Props = {
  icon: string;
  text: string;
  title: string;
};

const BonusCollectionsModalWin: React.FC<Props> = ({ title, icon, text }) => {
  return (
    <Flex.ColCenter
      className={`${bonusCollectionModalPrefix}__won custom-scrollbars overflow-y-auto overflow-x-hidden`}
    >
      <img src={icon} alt="" className={`${bonusCollectionModalPrefix}__won-image`} />
      <div className={`${bonusCollectionModalPrefix}__won-line`}></div>
      <Flex.Center className={`${bonusCollectionModalPrefix}__won-text`}>{text}</Flex.Center>
      <Flex.Center className={`${bonusCollectionModalPrefix}__won-title`}>{title}</Flex.Center>
    </Flex.ColCenter>
  );
};

export default BonusCollectionsModalWin;
