import { useTranslation } from 'react-i18next';
import { CashierModalUI } from 'modules/retail/modules/cashier/cashierModalUI/Index';
import { InfoTableUI } from 'modules/retail/modules/ssbt/components/infoTableUI';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';
import { selectCashPay } from '../../slice/cashPay.slice';

const WithdrawInfoTable = () => {
  const verifyWithdraw = useAppSelector(selectCashPay.verifyWithdraw);
  const verifyWithdrawStatus = useAppSelector(selectCashPay.verifyWithdrawStatus);

  const { t } = useTranslation();

  if (verifyWithdrawStatus !== 'succeeded')
    return (
      <CashierModalUI.InfoMessage
        className="m-auto"
        icon={'user'}
        isLoading={verifyWithdrawStatus === 'pending'}
        hasError={verifyWithdrawStatus === 'failure'}
        infoMessage={
          verifyWithdrawStatus === 'failure'
            ? t(CashierTranslations.CashPayModal.errorInfoText)
            : t(CashierTranslations.CashPayModal.withdrawInfoText)
        }
      />
    );

  if (isEmpty(verifyWithdraw)) return null;

  if (!isEmpty(verifyWithdraw.errorMessage))
    return (
      <CashierModalUI.InfoMessage
        className="m-auto"
        icon={'info'}
        hasError={true}
        infoMessage={verifyWithdraw.errorMessage}
      />
    );

  if (isEmpty(verifyWithdraw?.cashPayQRCheckWithdrawalDto)) return null;

  return (
    <>
      <InfoTableUI.Wrapper>
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.firstName)}
          cellValue={verifyWithdraw?.cashPayQRCheckWithdrawalDto.firstName}
        />
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.lastName)}
          cellValue={verifyWithdraw?.cashPayQRCheckWithdrawalDto.lastName}
        />
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.email)}
          cellValue={verifyWithdraw?.cashPayQRCheckWithdrawalDto.email}
        />
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.playerId)}
          cellValue={verifyWithdraw?.cashPayQRCheckWithdrawalDto.playerId}
        />
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.phone)}
          cellValue={verifyWithdraw?.cashPayQRCheckWithdrawalDto.phone}
        />
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.ucn)}
          cellValue={verifyWithdraw?.cashPayQRCheckWithdrawalDto.ucn}
        />
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.amount)}
          cellValue={`${verifyWithdraw?.cashPayQRCheckWithdrawalDto.amount} ${verifyWithdraw?.cashPayQRCheckWithdrawalDto.currency}`}
        />
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.amountAfterTax)}
          cellValue={`${verifyWithdraw?.cashPayQRCheckWithdrawalDto.amountAfterTax} ${verifyWithdraw?.cashPayQRCheckWithdrawalDto.currency}`}
        />
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.taxAmount)}
          cellValue={`${verifyWithdraw?.cashPayQRCheckWithdrawalDto.taxAmount} ${verifyWithdraw?.cashPayQRCheckWithdrawalDto.currency}`}
        />
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.verificationStatus)}
          cellValue={verifyWithdraw?.cashPayQRCheckWithdrawalDto.verificationStatus}
        />
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.currency)}
          cellValue={verifyWithdraw?.cashPayQRCheckWithdrawalDto.currency}
        />
      </InfoTableUI.Wrapper>
    </>
  );
};

export default WithdrawInfoTable;
