import { memo } from 'react';

import BodyLive from './BodyLive';
import BodyStatic from './BodyStatic';
import Clock from './Clock';
import CustomBetHeader from './CustomBetHeader';
import CustomBetTitle from './CustomBetTitle';
import CustomBetWrapper from './CustomBetWrapper';
import Event from './Event';
import Header from './Header';
import PrematchDate from './PrematchDate';
import Wrapper from './Wrapper';

export const BetSelectionUI = {
  BodyLive: memo(BodyLive),
  BodyStatic: memo(BodyStatic),
  Clock: memo(Clock),
  CustomBetHeader: memo(CustomBetHeader),
  CustomBetTitle: memo(CustomBetTitle),
  CustomBetWrapper: CustomBetWrapper,
  Event: memo(Event),
  Header: memo(Header),
  PrematchDate: memo(PrematchDate),
  Wrapper,
};
