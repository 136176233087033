import { useAppSelector } from 'store';
import { selectFinancialInfo } from './slice/financialReports.slice';
import { selectCashierData } from '../../slice/cashier.slice';

export const useFinancialInfo = () => {
  const financeInfo = useAppSelector(selectFinancialInfo.financeInfo);
  const financeStatus = useAppSelector(selectFinancialInfo.financeStatus);
  const isPrintingEject = useAppSelector(selectCashierData.isPrintingEject);
  const financeInfoErrorMessage = useAppSelector(selectFinancialInfo.financeInfoError);
  const financeInfoCommission = useAppSelector(selectFinancialInfo.financialCommissionInfo);
  const financeInfoCommissionStatus = useAppSelector(selectFinancialInfo.financialCommissionInfoStatus);

  return {
    financeInfo,
    financeStatus,
    isPrintingEject,
    financeInfoErrorMessage,
    financeInfoCommission,
    financeInfoCommissionStatus,
  };
};
