import { useCallback } from 'react';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { useAppDispatch, useAppSelector } from 'store';
import { LayoutConfigTypes } from '../../general/types/generalSlice.types';
import { selectAppHeader, setTopNavHeaderStatus } from '../components/appHeaderContainer/slice/appHeader.slice';

export const useShowTopNavOnClick = (): {
  handleShowHideTopNav: () => void;
  topNavInitialCollapsed: boolean;
  isVisible: boolean;
} => {
  const navigationConfig = useLayoutConfigType({ moduleLayout: 'platform', innerLayout: LayoutConfigTypes.NAVIGATION });
  const topNavInitialCollapsed = navigationConfig?.topNavInitialCollapsed;
  const dispatch = useAppDispatch();
  const topNavHeaderStatus = useAppSelector(selectAppHeader.topNavHeaderStatus);

  const handleShowHideTopNav = useCallback(() => {
    if (topNavHeaderStatus === 'collapsed') {
      dispatch(setTopNavHeaderStatus('visible'));
    } else if (topNavHeaderStatus === 'visible') {
      dispatch(setTopNavHeaderStatus('collapsed'));
    }
  }, [topNavHeaderStatus, dispatch]);

  return { handleShowHideTopNav, topNavInitialCollapsed, isVisible: topNavHeaderStatus === 'visible' };
};
