import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import CasinoContainerImplementation from 'modules/casino/platformImplementation/casinoWidgetTypes/CasinoContainerImplementation';
import useCasinoWidgetConfig from 'modules/casino/platformImplementation/hooks/useCasinoWidgetConfig';
import { WidgetContainerTypes } from 'modules/casino/platformImplementation/types/casinoWidget.types';
import { Text } from 'modules/casino/shared/components/text';
import { ExternalCasinoCategory } from './externalWrapper/ExternalCasinoCategory';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';

type Props = { bonusId: string; freeSpinGamesIds: string[] };
const FreeSpinCasinoCategory: React.FC<Props> = ({ bonusId, freeSpinGamesIds }) => {
  const { t } = useTranslation();
  const casinoWidgetConfig = useCasinoWidgetConfig();

  const renderContainer = () => {
    if (casinoWidgetConfig) {
      return (
        <CasinoContainerImplementation
          containerKey={`${bonusId}`}
          containerType={WidgetContainerTypes.CATEGORY}
          params={{
            categoryType: CategoryLayoutTypes.EXTERNAL_CATEGORY,
            subType: CategorySubTypes.FREE_SPIN,
            gameIds: freeSpinGamesIds,
          }}
        />
      );
    }
    return (
      <ExternalCasinoCategory
        subType={CategorySubTypes.FREE_SPIN}
        categoryDetails={{ gameIds: freeSpinGamesIds }}
        cacheKey={bonusId}
      ></ExternalCasinoCategory>
    );
  };

  if (!freeSpinGamesIds) {
    return <></>;
  }
  return (
    <div className="mpd-cta-item__games">
      <Text type="div" text={t('MyProfile.OffersTab.freeSpinGamesTitle')} className="mpd-cta-item__games__title" />
      {/*{isLoading && <DataLoader />}*/}
      {renderContainer()}
    </div>
  );
};

export default memo(FreeSpinCasinoCategory);
