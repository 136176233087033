import React, { useEffect } from 'react';
import PrizeDropMobileView from 'components/shared/prizeDrop/Preview/PrizeDropMobileView';
import { useCDNIconSprites } from 'hooks/common/useCDNIconSprites';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { usePrizeDropCampaigns } from 'shared/common/features/PrizeDrop/hooks/usePrizeDropCampaigns';
import { setActiveCampaignId } from 'shared/common/features/PrizeDrop/slice/prizeDrop.slice';
import { useAppDispatch } from 'store';
import { TopBarTypes } from '../topBar.types';
import { TopBarPopover } from '../TopBarPopover';

export const PrizeDropPopover: React.FC<TopBarTypes.CommonPopOverProps> = ({ handleClose, parentRef, show }) => {
  useCDNIconSprites('przdIcons', 'przd_icons_timestamp', 'przd-icons', 'common-content/prize-drop/sprites.svg');
  const dispatch = useAppDispatch();
  const { isLoading } = usePrizeDropCampaigns(true);

  useEffect(() => {
    !show && dispatch(setActiveCampaignId(0));
  }, [dispatch, show]);

  if (isLoading) return null;

  return (
    <TopBarPopover handleClose={handleClose} parentRef={parentRef} show={show} additionalClass="prize-drop-popover">
      <div className={`${cssGlobalNS}-gl__popover-wrapper`}>
        <PrizeDropMobileView isInGameBar={true} />
      </div>
    </TopBarPopover>
  );
};
