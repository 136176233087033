import React, { Fragment } from 'react';
import { useCategoryGamesIds } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useCategoryGamesIds';
import { disableSaveScrollPositionMap } from 'modules/casino/modules/container/cellsContainer/grid/components/gridCell/gridCellConstants';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { Casino } from 'modules/casino/shared/types';
import { getCellPosition } from 'modules/casino/shared/utils/casinoUtils';
import GenericLayoutWrapper from '../../layouts/GenericLayoutWrapper';
import SliderGridWrapper from '../../layouts/SliderGridWrapper';

type Props = {
  children: (
    itemId: string,
    key: number,
    isLastItem: boolean,
    gridPage: number,
    gamesRenderedCount: number,
    skipLazyLoad: boolean,
  ) => React.ReactNode;
  subType: CategorySubTypes;
  layoutConfig: Casino.LayoutConfig;
  collectionId: string;
  className: string;
  categoryLayout: CategoryLayoutTypes;
  isCategoryLast?: boolean;
  categoryId: string;
};

const SingleGridSlider: React.FC<Props> = ({
  children,
  subType,
  layoutConfig,
  collectionId,
  className,
  categoryLayout,
  categoryId,
}) => {
  const { layoutRows, layoutGrid } = layoutConfig;
  const centeredSlides = CategorySubTypes.LANDING === subType;
  const gamesIds = useCategoryGamesIds({ categoryLayoutType: categoryLayout, categoryId });
  const disableSaveScrollPosition = subType ? disableSaveScrollPositionMap.includes(subType) : false;
  const cell = getCellPosition(disableSaveScrollPosition);

  const sliderRender = () => {
    const elements: React.ReactNode[] = gamesIds.map((gameId, index) => {
      const skipLazyLoad =
        (cell && cell?.collectionId === collectionId) || (!!centeredSlides && index <= gamesIds.length / 2);

      return (
        <Fragment key={gameId}>
          {children(gameId, index, gamesIds.length - 1 === index, 0, index, skipLazyLoad)}
        </Fragment>
      );
    });

    return elements;
  };

  return (
    <SliderGridWrapper className={className}>
      <GenericLayoutWrapper
        layoutGrid={layoutGrid}
        subType={subType}
        rows={layoutRows}
        categoryId={categoryId}
        categoryLayout={categoryLayout}
      >
        {sliderRender()}
      </GenericLayoutWrapper>
    </SliderGridWrapper>
  );
};

export default SingleGridSlider;
