import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { formatAmountToBRL } from 'modules/retail/modules/cashier/utils';
import { Campaign, TicketBet } from 'modules/retail/modules/ssbt/types/ssbt.types';
import { BetSlipSharedUI } from 'pages/myAccount/tabs/history/sections/sportsHistory/components/BetSlipSharedUI';
import { isEmpty, toDecimal, toFixed } from 'utils/common/helpersCommon';
import { cssNS } from './betLogUI.types';
import Currency from './Currency';

/** BetLog Systems Section */
interface SystemsProps {
  betTypeText: string;
  betSystem: string | undefined;
  numberOfBetsText: string;
  totalCombinations: number;
  totalOddsText: string;
  totalOddsToDisplay: number | undefined;
  stakeText: string;
  stakeAmount: string;
  totalStakeText: string;
  totalStakeAmount: string;
  children?: React.ReactNode;
  hasBetType?: boolean;
  systemText?: string;
  possibleWin?: number | string;
  isCurrencyBeforeAmount?: boolean;
  bets?: TicketBet[];
  bet?: TicketBet;
  status?: string;
  currency?: string;
}

const Systems: React.FC<SystemsProps> = (props) => {
  const { t } = useTranslation();
  const cssSubNS = 'systems';
  const isCashier = window.config.CHANNEL === 'RETAIL' && window.config.DEVICE === 'CASHIER';

  return (
    <>
      <div className={`${cssNS}__text--system`}>
        {/* must be add translation */}
        <span className={`${cssNS}__text--system__label`}>{props.betTypeText}: </span>
        {props.systemText}
      </div>
      <Flex.Col className={`${cssNS}__${cssSubNS}`}>
        {props.hasBetType && (
          <Flex.AC className={`${cssNS}__box ${cssNS}__${cssSubNS}-box ${cssNS}__${cssSubNS}-header`}>
            {props.betTypeText}: {props.betSystem}
          </Flex.AC>
        )}
      </Flex.Col>

      <Flex.Col className={`${cssNS}__box ${cssNS}__${cssSubNS}-box ${cssNS}__${cssSubNS}-body`}>
        <Flex className="align-items-baseline">
          <Text
            className={`${cssNS}__${cssSubNS}-col ${isCashier ? `${cssNS}__${cssSubNS}-col--cashier` : ''}`}
            text={props.numberOfBetsText}
          />
          <Text
            className={`${cssNS}__${cssSubNS}-col ${isCashier ? `${cssNS}__${cssSubNS}-col--cashier` : ''}`}
            text={props.totalCombinations}
          />
        </Flex>
        {props.totalCombinations === 1 && (
          <Flex className="align-items-baseline">
            <Text
              className={`${cssNS}__${cssSubNS}-col ${isCashier ? `${cssNS}__${cssSubNS}-col--cashier` : ''}`}
              text={props.totalOddsText}
            />
            <Text
              className={`${cssNS}__${cssSubNS}-col ${isCashier ? `${cssNS}__${cssSubNS}-col--cashier` : ''}`}
              text={toFixed(Number(props.totalOddsToDisplay), 2)}
            />
          </Flex>
        )}

        <Flex className="align-items-baseline">
          <Text
            className={`${cssNS}__${cssSubNS}-col ${isCashier ? `${cssNS}__${cssSubNS}-col--cashier` : ''}`}
            text={props.stakeText}
          />
          <span className={`${cssNS}__${cssSubNS}-col ${isCashier ? `${cssNS}__${cssSubNS}-col--cashier` : ''}`}>
            <>
              {props.isCurrencyBeforeAmount ? (
                <>
                  <Currency /> {formatAmountToBRL(props.stakeAmount)}
                </>
              ) : (
                <>
                  {props.stakeAmount} <Currency />
                </>
              )}
            </>
          </span>
        </Flex>
        <Flex className="align-items-baseline">
          <Text
            className={`${cssNS}__${cssSubNS}-col ${isCashier ? `${cssNS}__${cssSubNS}-col--cashier` : ''}`}
            text={props.totalStakeText}
          />
          <span className={`${cssNS}__${cssSubNS}-col ${isCashier ? `${cssNS}__${cssSubNS}-col--cashier` : ''}`}>
            <>
              {props.isCurrencyBeforeAmount ? (
                <>
                  <Currency /> {formatAmountToBRL(props.stakeAmount)}
                </>
              ) : (
                <>
                  {props.totalStakeAmount} <Currency />
                </>
              )}
            </>
          </span>
        </Flex>
        <Flex.ACJB className="w-100">
          {/* to be const  */}
          {/* {isEmpty(props.bet?.campaign) && props?.bet?.betStatus !== 'REJECTED' && (
            <Flex.Col>
              <BetSummaryTerminalProfit
                t={t}
                currencySymbol={props.currency}
                retailStatus={props?.status}
                totalAmount={!isEmpty(props?.bet?.possibleTotalWin) ? props.bet?.possibleTotalWin : 0}
              />
            </Flex.Col>
          )} */}

          {!isEmpty(props.bet?.campaign) && (
            <Flex.Col>
              {props.bet.campaign.map((campaign: Campaign, index: number) => (
                <React.Fragment key={index}>
                  {campaign && (
                    <div className="my-2">
                      <BetSlipSharedUI.BoostProfit
                        isVisible={campaign.type === 'ACCABOOST'}
                        profitText={t('Bonuses.AccaBoost.bonus')}
                        bonusPercentage={campaign.percentage}
                      />
                      <BetSlipSharedUI.PossibleBoostWin
                        isVisible={campaign.type === 'ACCABOOST'}
                        winText={t('Bonuses.AccaBoost.includeBonus')}
                        winAmount={toFixed(toDecimal(campaign.amountLong), 2)}
                      />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </Flex.Col>
          )}
        </Flex.ACJB>
      </Flex.Col>

      {/* {!isEmpty(props.bets) && props?.bets?.length > 1 && (
        <Flex.AC className={`${cssNS}__box ${cssNS}__${cssSubNS}-box ${cssNS}__${cssSubNS}-footer`}>
          <span>{t(MyAccountTranslation.BetHistory.toReturnText)}: </span>
          <Text
            className={`${cssNS}__${cssSubNS}-col ${isCashier ? `${cssNS}__${cssSubNS}-col--cashier` : ''}`}
            text={`${props.possibleWin} ${props.currency}`}
          />
        </Flex.AC>
      )} */}
    </>
  );
};

export default Systems;
