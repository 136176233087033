import React, { memo } from 'react';
import { Text } from 'components/shared';
import { Flex } from 'components/shared/layout';
import WinCoinsBalance from './WinCoins/WinCoinsBalance';
import { WinCoinsIcon } from './WinCoins/WinCoinsIcon';

type Props = { showBalance: boolean; amount: string; text: string; onClick?: () => void };

const MyProfileGamificationBalance: React.FC<Props> = ({ showBalance, amount, text, onClick }) => {
  if (!showBalance) return null;

  return (
    <Flex.Col className={`mpd-stat-item ${onClick ? 'cursor-pointer' : ''}`} onClick={onClick}>
      <Flex.AC>
        <Flex.Col>
          <Text className="mpd-section__text--secondary" text={text} />
          <WinCoinsBalance amount={amount} />
        </Flex.Col>
        <WinCoinsIcon />
      </Flex.AC>
    </Flex.Col>
  );
};

export default memo(MyProfileGamificationBalance);
