import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { arrowStyleTypes } from 'components/shared/ScrollContainer';
import { CaptainUpGrid } from 'pages/captainUp/components';
import CTAUI from 'shared/common/features/myProfile/components/CTAUI';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { GameJourney } from 'utils/common/translationUtils/translationStrings';
import ClubChallengesItem from './components/ClubChallengesItem';
import { selectMyChallenges } from '../../slice/clubTab.slice';

const ClubChallenge = ({ itemKey }) => {
  const { t } = useTranslation();
  const categoryIds = useAppSelector(selectMyChallenges.challenges);

  if (isEmpty(categoryIds)) {
    return <CTAUI.Empty text={t(GameJourney.Challenges.noChallengesText)} />;
  }

  return (
    <div style={{ overflow: 'hidden', marginBottom: '100px' }}>
      <CaptainUpGrid size="my-profile" type={'horizontal'} triggerMoveWidth arrowType={arrowStyleTypes.CasinoSmall}>
        {categoryIds?.map((id, idx) => (
          <ClubChallengesItem key={`badge-${id}`} id={id} index={idx} itemKey={itemKey} />
        ))}
      </CaptainUpGrid>
    </div>
  );
};

export default memo(ClubChallenge);
