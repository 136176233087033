import styled, { css } from 'styled-components';
import { Breakpoints } from '../../../../../../theme/Theme';
import { NotificationTypes } from '../../../myProfile/sections/tabs/notificationsTab/notificationTypes';

const centerPositionSM = css`
  margin: auto;
`;
const topPositionSM = css`
  margin: 20px auto auto auto;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    margin: 30px auto auto auto;
  }
`;
const bottomPositionSM = css`
  margin: auto auto 20px auto;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    margin: auto auto 30px auto;
  }
`;
const centerPositionMD = css`
  margin: auto;
`;
const topPositionMD = css`
  margin: 50px auto auto auto;
`;
const bottomPositionMD = css`
  margin: auto auto 50px auto;
`;

const addPosition = {
  sm: { center: centerPositionSM, top: topPositionSM, bottom: bottomPositionSM },
  md: {
    center: centerPositionMD,
    top: topPositionMD,
    bottom: bottomPositionMD,
  },
};

export const StyledBonus = styled.div<{ $position?: NotificationTypes.BonusAlignment; $size?: 'sm' | 'md' }>`
  place-self: inherit;
  ${({ $position, $size }) =>
    $position ? addPosition[$size ?? 'md'][$position] : addPosition[$size ?? 'md']['center']}
`;
