import { HomePageModule } from 'pages/homePage/types/homePage.types';
import { FavouritesTypes } from 'shared/common/features/drawer/components/favourites/types/favourites.types';
import { CreateThunk } from 'store/thunkCreators';
import { ContainerTypes } from 'utils/common/types/common.types';
import { InPlayTypes, Sport as SportDataTypes, SportStatus } from 'utils/common/types/sportTypes';
import { LoaderIncrement } from '../../loaderData/slice/loaderData.slice';

export declare module DrawerTypes {
  type State = {
    isDrawerVisible: boolean;
    typeDrawer: DrawerType;
    sportDrawerContainers: SportVertical[];
    drawerCasinoVerticals: Vertical[];
    sports: SportDataTypes.ActiveSport[];
    esports: SportDataTypes.ActiveSport[];
    containers: Record<string, Container>;
    liveSports: InPlayTypes.LiveSport[];
    liveSportsState: string;
    activePrematchSportID: number;
    activeESportId: number;
    activeLiveSportId: number;
    favourites: FavouritesTypes.Favourites;
    casinoDrawerType: string;
  };

  type Thunks = {
    fetchSportDrawerContainers: CreateThunk<void, SportVertical[], string | null>;
    fetchDrawerCasinoVerticals: CreateThunk<void, Vertical[], string | null>;
    fetchSports: CreateThunk<void, TabData[], string | null>;
    fetchESports: CreateThunk<void, TabData[], string | null>;
    fetchContainer: CreateThunk<
      {
        apiUri: string;
        containerId: number;
        containerType?: ContainerTypes;
        replaceEvent?: boolean;
        pollingChangeEvent?: boolean;
        isPollingEnabled?: boolean;
      } & LoaderIncrement,
      ApiContainerData,
      string | null
    >;
    fetchLiveSports: CreateThunk<void, InPlayTypes.LiveSportsApiResponse, string | null>;
  };

  type ApiContainerData = {
    sportName?: string;
    sportId: number;
    containerName: string;
    sports: Omit<HomePageModule.SportData, 'defaultEventsInView'>[];
    events: HomePageModule.EventsMap;
    eventIds?: number[];
    navigationUrl?: string | null;
    linkContainerContents?: LinkContainerContent[];
  };

  type LinkContainerContent = {
    name: string;
    params: string;
    urlLink: string;
  };

  type LinkParams = {
    originalId?: number;
    sportName?: { id: number; name: string };
    upcoming?: LocationState;
  };

  interface LocationState {
    filter: Template[];
    hasTopEvents: boolean;
    name: string;
    sportName: string;
    upcomingValue: string;
  }

  type Template = {
    name: string;
    count: string;
    type: string;
    value: string;
    hasTopEvents: boolean;
  };

  type Container = {
    sportId?: number;
    containerId: number;
    icon?: string;
    containerName?: string;
    navText?: string;
    status: SportStatus;
    fetched?: boolean;
    error?: null | string;
    navigationUrl?: string | null;
    linkContainerContents?: LinkContainerContent[];
    apiUri: string;
    containerType?: ContainerTypes;
    sports: HomePageModule.SportData[];
  };

  type Vertical = {
    id: number;
    name: string;
    route: string;
    type: string;
  };
  type SportVertical = {
    content: ContainersContent[];
    type: number;
  };

  type ContainersContent = {
    name: string;
    id: number;
    apiUrl: string;
    ranking: number;
  };

  type MenuItem = {
    name: string;
    iconName: string;
    rank: number;
    route: string;
    type: string;
    verticalType: null | 'sport' | 'casino';
    isOpened?: boolean;
  };

  type MarketType = {
    id: string;
    name: string;
    hasData: boolean;
  };

  type SportType = {
    correlationId: string;
    hasMultilineMarkets: boolean;
    icon: string | null;
    id: number;
    liveEventsCount: number;
    marketTypes: MarketType[];
    name: string;
    sportId: number;
  };

  interface VirtualMenu {
    alias: string;
    route: string;
    id: number;
    code: string;
    translation: string;
  }

  type Filters = {
    defaultFilter: boolean;
    name: string;
    row: number;
    type: string;
    value: string;
  };

  type TabUIType =
    | 'sportsSpecial'
    | 'program'
    | 'live'
    | 'sport'
    | 'favourites'
    | 'allSports'
    | 'miniGame'
    | 'results'
    | 'requestBet';

  type TabType = 'sport' | 'ui' | 'category' | 'tournament' | 'virtual' | 'esport' | '';

  type TabData = Tab & SportDataTypes.ActiveSport;

  type Tab = {
    id: number;
    name: string;
    navigationUrl: string;
    icon: string;
    ranking: number;
    uiType: TabUIType;
    folded: boolean;
    data: TabData[];
    filters: Filters[];
    isExternal: null | boolean;
    type: TabType;
    action: 'navigate' | 'check' | 'collapse';
    device?: 'mobile' | 'desktop' | 'all';
    isAccented?: boolean;
    isMultiline?: boolean;
    isPrivate?: boolean; // uiType === 'favourites'
    gameId: number | null;
  };
}

export enum DrawerType {
  Sport = 'sport',
  LiveSport = 'live-sport',
  Casino = 'casino',
  Virtuals = 'virtuals',
  Help = 'help',
  Promotion = 'promotions',
  ESports = 'esports',
  External = 'external',
  InActive = 'inActive',
  Retail = 'retail',
}

export enum SportsType {
  Sport = 'sports',
  LiveSport = 'live_sport',
  ESports = 'esports',
  Virtuals = 'virtual',
}

export type DrawerFunc = {
  (): JSX.Element;
};
