import React from 'react';
import { motion } from 'framer-motion';
import CTAUI from 'shared/common/features/myProfile/components/CTAUI';
import { OfferBonus } from 'shared/common/features/myProfile/sections/tabs/offersTab/components';
import {
  Category,
  OffersTabLayout,
  Promotion,
} from 'shared/common/features/myProfile/sections/tabs/offersTab/types/offersTab.types';
import { leftToRightAnimation } from 'shared/common/features/myProfile/utils/myProfile.utils';
import { isEmpty } from 'utils/common/helpersCommon';

type Props = {
  activeCategory?: Category;
  promotions: Promotion[];
  hideCategoryBonuses?: (e: React.MouseEvent<Element, MouseEvent>) => void;
  layout?: OffersTabLayout;
};

const BonusCategory: React.FC<Props> = ({ activeCategory, promotions, hideCategoryBonuses, layout }) => {
  if (isEmpty(promotions)) return null;

  return (
    <motion.div variants={leftToRightAnimation} initial="closed" animate="open">
      {activeCategory && hideCategoryBonuses && (
        <CTAUI.Header
          icon={activeCategory.resourceName || ''}
          title={activeCategory.title}
          onBackClick={hideCategoryBonuses}
          isRead
          showBackButton
        />
      )}
      <div className="mt-2">
        {promotions?.map((promotion, index) => (
          <OfferBonus
            promotion={promotion}
            key={`${promotion.promotionId}-${index}`}
            type={promotion.type}
            activeCategory={activeCategory}
            layout={layout}
          />
        ))}
      </div>
    </motion.div>
  );
};

export default BonusCategory;
