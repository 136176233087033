import clsx from 'clsx';
import AmountCurrency from 'shared/common/features/myProfile/components/AmountCurrency';

/** BetSlip Selection Possible Boost Win */
interface PossbileBoostWinProps {
  className?: string;
  isVisible?: boolean;
  winText: string;
  winAmount: number | string;
}

const PossbileBoostWin: React.FC<PossbileBoostWinProps> = ({ className, isVisible, winText, winAmount }) => {
  if (!isVisible) return null;

  const blockClass = clsx(className);
  return (
    <div className={blockClass}>
      <span className="ma-text--accent">{winText}</span>
      <AmountCurrency amount={winAmount} />
    </div>
  );
};

export default PossbileBoostWin;
