import React, { useCallback } from 'react';
import { useAppSelector } from 'store';
import { WidgetWrapper } from './components';
import { BigWinsCell } from './components/bigWinsCell';
import { useWidgetBigWinsFetch } from './hooks/useWidgetBigWinsFetch';
import { CategoryLayoutTypes } from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { casinoWidgetsSelectors } from '../../slice/casinoWidgetsSlice';
import { CasinoWidgetsTypes } from '../../types/casinoWidgetsTypes';

interface WidgetsProps {
  widget: CasinoWidgetsTypes.Widget;
  categoryLayoutType: CategoryLayoutTypes;
}

const BigWinsWidget: React.FC<WidgetsProps> = ({ widget, categoryLayoutType }) => {
  useWidgetBigWinsFetch({ widgetId: widget?.id, query: widget?.query });
  const bigWinItems = useAppSelector((state) => casinoWidgetsSelectors.bigWins(state, widget?.id));

  const renderBigWins = useCallback(
    () => bigWinItems.map((bigWin, index) => <BigWinsCell key={`${index}_${bigWin.id}`} bigWin={bigWin} />),
    [bigWinItems],
  );

  return bigWinItems?.length ? (
    <WidgetWrapper categoryLayoutType={categoryLayoutType}>{renderBigWins()}</WidgetWrapper>
  ) : (
    <></>
  );
};

export default BigWinsWidget;
