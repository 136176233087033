import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { IconLoader } from 'components/shared/loaders';
import { Sport } from 'utils/common/types/sportTypes';
import BlockButton from './BlockButton';
import { NotificationTypes } from '../../sections/tabs/notificationsTab/notificationTypes';

type SettingsProps = {
  handleUpdateMessages: (type: NotificationTypes.DeleteMessagesType) => void;
  updateStatus: Sport.StateStatus;
};

const renderByStatus = (
  status: Sport.StateStatus,
  handleUpdateMessages: (type: NotificationTypes.DeleteMessagesType) => void,
  t,
) => {
  switch (status) {
    case 'pending':
      return <IconLoader />;
    default:
      return (
        <>
          <BlockButton
            icon="bin"
            text={t('MyProfile.NotificationsTab.delete')}
            onClick={() => handleUpdateMessages('DELETE')}
          />
          <BlockButton
            icon="envelopoe-read"
            text={t('MyProfile.NotificationsTab.read')}
            onClick={() => handleUpdateMessages('READ')}
          />
        </>
      );
  }
};

const Management: React.FC<SettingsProps> = ({ handleUpdateMessages, updateStatus }) => {
  const { t } = useTranslation();
  const managementWrapperClass = clsx(`mpd-block__toolbar sticky-top`);
  return (
    <Flex.ACJB className={managementWrapperClass}>{renderByStatus(updateStatus, handleUpdateMessages, t)}</Flex.ACJB>
  );
};

export default Management;
