import { NotificationTypes } from './notificationTypes';

export const setCampaignConfig = ({
  folder,
  layout,
  config,
  numberOfFrames,
}: {
  folder?: NotificationTypes.GamificationFolder;
  layout?: NotificationTypes.GamificationCampaignsLayouts;
  config;
  numberOfFrames?: number;
}): NotificationTypes.GamificationModalParams => ({
  ...config,
  ...(folder ? { folder } : {}),
  ...(layout ? { layout } : {}),
  ...(numberOfFrames ? { numberOfFrames } : {}),
});

export const cashBellConfig: NotificationTypes.GamificationModalParams = {
  folder: 'hammer',
  layout: '1column',
  logo: 'logo.png',
  logoWidth: 354,
  logoHeight: 100,
  backgroundMobile: 'bgr-mobile.png',
  background: 'bgr-desktop.png',
  modalCloseColor: 'light',
  // modalCloseStyle: { width: '18px', height: '18px', fill: '#D7B246' },
  modalBodyClassName: 'mx-auto',
  // modalBodyStyle: { maxWidth: '1030px', border: '2px solid #D7B246', borderRadius: '10px' },
  // Number of frames should be 0 for no framer animatons!!!
  numberOfFrames: 25,
  frameWidth: 150,
  frameHeight: 207,
  frameMaxWidth: 150,
  frameMaxHeight: 207,
  frameAspectRatio: 0.833,
  framePixelDensity: 2,
  frameBaseName: 'CashBellFrame',
  fileBaseName: 'frame-1_000',
  fileExt: '.png',
};

export const giftBoxConfig: NotificationTypes.GamificationModalParams = {
  folder: 'gift-boxes',
  layout: '1column',
  logo: 'logo.png',
  logoWidth: 356,
  logoHeight: 140,
  background: 'bgr.png',
  modalCloseColor: 'light',
  modalBodyClassName: 'gmf-modal__extra-height',
  numberOfFrames: 24,
  frameWidth: 164,
  frameHeight: 164,
  frameMaxWidth: 240,
  frameMaxHeight: 240,
  frameAspectRatio: 1,
  framePixelDensity: 2,
  frameBaseName: 'GiftBoxFrame',
  fileBaseName: 'frame-1_000',
  fileExt: '.png',
};

export const xmasBoxConfig: NotificationTypes.GamificationModalParams = {
  folder: 'xmas-box',
  layout: '1column',
  logo: 'logo.png',
  logoWidth: 356,
  logoHeight: 140,
  background: 'bgr.png',
  modalCloseColor: 'light',
  modalBodyClassName: 'gmf-modal__extra-height',
  numberOfFrames: 24,
  frameWidth: 164,
  frameHeight: 164,
  frameMaxWidth: 240,
  frameMaxHeight: 240,
  frameAspectRatio: 1,
  framePixelDensity: 2,
  frameBaseName: 'XmasBoxFrame',
  fileBaseName: 'frame-1_000',
  fileExt: '.png',
};

export const goldenBoxConfig: NotificationTypes.GamificationModalParams = {
  folder: 'golden-box',
  layout: '1column',
  logo: 'logo.png',
  logoWidth: 356,
  logoHeight: 140,
  background: 'bgr.png',
  modalCloseColor: 'light',
  modalBodyClassName: 'gmf-modal__extra-height',
  numberOfFrames: 24,
  frameWidth: 164,
  frameHeight: 164,
  frameMaxWidth: 240,
  frameMaxHeight: 240,
  frameAspectRatio: 1,
  framePixelDensity: 2,
  frameBaseName: 'GoldenBoxFrame',
  fileBaseName: 'frame-1_000',
  fileExt: '.png',
};
export const Box2RowsConfig: NotificationTypes.GamificationModalParams = {
  folder: 'box-2-rows',
  layout: '1column',
  logo: 'logo.png',
  logoWidth: 356,
  logoHeight: 140,
  background: 'bgr.png',
  modalCloseColor: 'light',
  modalBodyClassName: 'gmf-modal__extra-height',
  numberOfFrames: 24,
  frameWidth: 108,
  frameHeight: 108,
  frameMaxWidth: 240,
  frameMaxHeight: 240,
  frameAspectRatio: 1,
  framePixelDensity: 2,
  frameBaseName: 'Box2RowsFrame',
  fileBaseName: 'frame-1_000',
  fileExt: '.png',
};

export const jungleBoxConfig: NotificationTypes.GamificationModalParams = {
  folder: 'jungle-boxes',
  layout: '1column',
  logo: 'logo.png',
  logoWidth: 356,
  logoHeight: 140,
  background: 'bgr.png',
  modalCloseColor: 'light',
  modalBodyClassName: 'gmf-modal__extra-height',
  numberOfFrames: 24,
  frameWidth: 164,
  frameHeight: 164,
  frameMaxWidth: 240,
  frameMaxHeight: 240,
  frameAspectRatio: 1,
  framePixelDensity: 2,
  frameBaseName: 'JungleBoxFrame',
  fileBaseName: 'frame-1_000',
  fileExt: '.png',
};

export const easterEggConfig: NotificationTypes.GamificationModalParams = {
  folder: 'easter',
  layout: '1column',
  logo: 'logo.png',
  logoWidth: 332,
  logoHeight: 162,
  backgroundMobile: 'bgr-mobile.png',
  background: 'bgr-desktop.png',
  modalCloseColor: 'dark',
  modalCloseStyle: { width: '18px', height: '18px', fill: '#fff' },
  modalBodyStyle: { minHeight: '726px' },

  // Number of frames should be 0 for no framer animatons!!!
  numberOfFrames: 72,
  frameWidth: 355,
  frameHeight: 390,
  frameMaxWidth: 355,
  frameMaxHeight: 396,
  frameAspectRatio: 0.71,
  framePixelDensity: 2,
  frameBaseName: 'EggFrame',
  fileBaseName: 'frame-1_000',
  fileExt: '.png',
};

export const cashWheelConfig: NotificationTypes.GamificationModalParams = {
  folder: 'cashwheel',
  layout: 'wheel',
  logo: 'logo.png',
  logoWidth: 356,
  logoHeight: 96,
  backgroundMobile: 'bgr-mobile.png',
  background: 'bgr-desktop.png',
  modalCloseColor: 'light',
  modalBodyClassName: 'bg-pos-bottom',
  // Number of frames should be 0 for no framer animatons!!!
  numberOfFrames: 0,
  frameWidth: 164,
  frameHeight: 164,
  frameMaxWidth: 240,
  frameMaxHeight: 240,
  frameAspectRatio: 1,
  framePixelDensity: 2,
  frameBaseName: 'CashWheelFrame',
  fileBaseName: 'bgr-desktop',
  fileExt: '.png',
};

export const amusementWheelConfig: NotificationTypes.GamificationModalParams = {
  folder: 'amusement-wheel',
  layout: 'wheel',
  logo: 'logo.png',
  logoWidth: 356,
  logoHeight: 140,
  backgroundMobile: 'bgr-mobile.png',
  background: 'bgr-desktop.png',
  modalCloseColor: 'light',
  modalBodyClassName: 'bg-pos-bottom',
  // Number of frames should be 0 for no framer animatons!!!
  numberOfFrames: 0,
  frameWidth: 80,
  frameHeight: 80,
  frameMaxWidth: 168,
  frameMaxHeight: 168,
  frameAspectRatio: 1,
  framePixelDensity: 2,
  modalBodyStyle: { backgroundPosition: 'center top', backgroundSize: 'cover' },
  frameBaseName: 'AmusementWheelFrame',
  fileBaseName: 'frame-1_000',
  fileExt: '.png',
};
export const amusementWheel3DConfig: NotificationTypes.GamificationModalParams = {
  folder: 'amusement-wheel-3d',
  layout: 'wheel',
  logo: 'logo.png',
  logoWidth: 356,
  logoHeight: 140,
  backgroundMobile: 'bgr-mobile.png',
  background: 'bgr-desktop.png',
  modalCloseColor: 'light',
  modalBodyClassName: 'bg-pos-bottom',
  // Number of frames should be 0 for no framer animatons!!!
  numberOfFrames: 0,
  frameWidth: 80,
  frameHeight: 80,
  frameMaxWidth: 168,
  frameMaxHeight: 168,
  frameAspectRatio: 1,
  framePixelDensity: 2,
  modalBodyStyle: { backgroundPosition: 'center top', backgroundSize: 'cover' },
  frameBaseName: 'AmusementWheelFrame',
  fileBaseName: 'frame-1_000',
  fileExt: '.png',
};

export const jungleWheelConfig: NotificationTypes.GamificationModalParams = {
  folder: 'jungle-wheel',
  layout: 'wheel',
  logo: 'logo.png',
  logoWidth: 356,
  logoHeight: 96,
  backgroundMobile: 'bgr-mobile.png',
  background: 'bgr-desktop.png',
  modalCloseColor: 'light',
  modalBodyClassName: 'bg-pos-bottom',
  // Number of frames should be 0 for no framer animatons!!!
  numberOfFrames: 0,
  frameWidth: 164,
  frameHeight: 164,
  frameMaxWidth: 240,
  frameMaxHeight: 240,
  frameAspectRatio: 1,
  framePixelDensity: 2,
  frameBaseName: 'JungleWheelFrame',
  fileBaseName: 'bgr-desktop',
  fileExt: '.png',
};

export const dwarfsWagonConfig: NotificationTypes.GamificationModalParams = {
  folder: 'dwarfs-wagon',
  layout: '1column',
  logo: 'logo.png',
  logoWidth: 356,
  logoHeight: 140,
  backgroundMobile: 'bgr-mobile.png',
  background: 'bgr-desktop.png',
  modalCloseColor: 'light',
  modalBodyClassName: 'dwrf-wgn',
  // Number of frames should be 0 for no framer animatons!!!
  numberOfFrames: 40,
  frameWidth: 188,
  frameHeight: 170,
  frameMaxWidth: 280,
  frameMaxHeight: 252,
  frameAspectRatio: 0.9,
  framePixelDensity: 2,
  frameBaseName: 'DwarfsWagonFrame',
  fileBaseName: 'frame-1_000',
  fileExt: '.png',
};

export const videoConfig: NotificationTypes.GamificationModalParams = {
  folder: 'stoichkov',
  layout: '1column',
  logo: 'logo.png',
  logoWidth: 356,
  logoHeight: 140,
  backgroundMobile: 'mobile.png',
  background: 'desktop.png',
  modalCloseColor: 'light',
  modalBodyClassName: 'dwrf-wgn',
  // Number of frames should be 0 for no framer animatons!!!
  numberOfFrames: 35,
  numberOfFramesSecond: 17,
  frameWidth: 140,
  frameHeight: 140,
  frameMaxWidth: 220,
  frameMaxHeight: 220,
  frameAspectRatio: 1,
  framePixelDensity: 2,
  modalBodyStyle: { backgroundPosition: 'center center', backgroundSize: 'cover' },
  frameBaseName: 'VideoCampaignsFrame',
  fileBaseName: 'frame-1_000',
  fileExt: '.png',
};

export const dwarfsWheelConfig: NotificationTypes.GamificationModalParams = {
  folder: 'dwarfs-wheel',
  layout: 'wheel',
  logo: 'logo.png',
  logoWidth: 356,
  logoHeight: 96,
  backgroundMobile: 'bgr-mobile.png',
  background: 'bgr-desktop.png',
  modalCloseColor: 'light',
  modalBodyStyle: { backgroundPosition: 'center bottom' },
  // Number of frames should be 0 for no framer animatons!!!
  numberOfFrames: 0,
  frameWidth: 164,
  frameHeight: 164,
  frameMaxWidth: 240,
  frameMaxHeight: 240,
  frameAspectRatio: 1,
  framePixelDensity: 2,
  frameBaseName: 'DwarfsWheelFrame',
  fileBaseName: 'bgr-desktop',
  fileExt: '.png',
};

export const christmasTreeConfig: NotificationTypes.GamificationModalParams = {
  folder: 'xmas',
  layout: '1column',
  logo: 'logo.png',
  logoWidth: 356,
  logoHeight: 140,
  backgroundMobile: 'bgr-mobile.png',
  background: 'bgr-desktop.png',
  modalCloseColor: 'light',
  modalBodyClassName: 'dwrf-wgn',
  // Number of frames should be 0 for no framer animatons!!!
  numberOfFrames: 36,
  frameWidth: 50,
  frameHeight: 50,
  frameMaxWidth: 78,
  frameMaxHeight: 78,
  frameAspectRatio: 1,
  framePixelDensity: 2,
  modalBodyStyle: { backgroundPosition: 'center bottom', backgroundSize: 'cover' },
  frameBaseName: 'ChristmasTreeFrame',
  fileBaseName: 'frame-1_000',
  fileExt: '.png',
};

export const christmasVillageConfig: NotificationTypes.GamificationModalParams = {
  folder: 'xmas',
  layout: '1column',
  logo: 'logo.png',
  logoWidth: 356,
  logoHeight: 140,
  backgroundMobile: 'bgr-mobile.png',
  background: 'bgr-desktop.png',
  modalCloseColor: 'light',
  modalBodyClassName: 'dwrf-wgn',
  // Number of frames should be 0 for no framer animatons!!!
  numberOfFrames: 25,
  frameWidth: 80,
  frameHeight: 80,
  frameMaxWidth: 168,
  frameMaxHeight: 168,
  frameAspectRatio: 1,
  framePixelDensity: 2,
  modalBodyStyle: { backgroundPosition: 'center top', backgroundSize: 'cover' },
  frameBaseName: 'ChristmasVillageFrame',
  fileBaseName: 'frame-1_000',
  fileExt: '.png',
};

export const xmasReelConfig: NotificationTypes.GamificationModalParams = {
  folder: 'xmas-reel',
  layout: '1column',
  logo: 'logo.png',
  logoWidth: 484,
  logoHeight: 70,
  backgroundMobile: 'bgr-mobile.png',
  background: 'bgr-desktop.png',
  modalCloseColor: 'light',
  modalCloseStyle: { width: '18px', height: '18px', fill: '#D7B246' },
  modalBodyClassName: 'mx-auto',
  modalBodyStyle: { maxWidth: '1030px', border: '2px solid #D7B246', borderRadius: '10px' },
  // Number of frames should be 0 for no framer animatons!!!
  numberOfFrames: 88,
  numberOfFramesSecond: 8,
  frameWidth: 108,
  frameHeight: 130,
  frameMaxWidth: 300,
  frameMaxHeight: 360,
  frameAspectRatio: 0.833,
  framePixelDensity: 2,
  frameBaseName: 'XmasReelFrame',
  fileBaseName: 'frame-1_000',
  fileExt: '.png',
};

export const springReelConfig: NotificationTypes.GamificationModalParams = {
  folder: 'spring-reel',
  layout: '1column',
  logo: 'logo.png',
  logoWidth: 536,
  logoHeight: 70,
  backgroundMobile: 'bgr-mobile.png',
  background: 'bgr-desktop.png',
  modalClassName: 'spr-reel',
  modalCloseColor: 'light',
  modalCloseStyle: { width: '18px', height: '18px', fill: '#D7B246' },
  modalBodyClassName: 'mx-auto',
  modalBodyStyle: { maxWidth: '1030px', border: '2px solid #D7B246', borderRadius: '10px' },
  // Number of frames should be 0 for no framer animatons!!!
  numberOfFrames: 100,
  numberOfFramesSecond: 8,
  frameWidth: 105,
  frameHeight: 168,
  frameMaxWidth: 290,
  frameMaxHeight: 468,
  frameAspectRatio: 0.833,
  framePixelDensity: 2,
  frameBaseName: 'SpringReelFrame',
  fileBaseName: 'frame-1_000',
  fileExt: '.png',
};

export const TripleAwardBoxConfig: NotificationTypes.GamificationModalParams = {
  folder: 'triple-award-box',
  layout: '1column',
  logo: 'logo.png',
  logoWidth: 422,
  logoHeight: 160,
  backgroundMobile: 'bgr-mobile.png',
  background: 'bgr-desktop.png',
  modalCloseColor: 'light',
  numberOfFrames: 48,
  frameWidth: 172,
  frameHeight: 172,
  frameMaxWidth: 320,
  frameMaxHeight: 320,
  frameAspectRatio: 1,
  framePixelDensity: 2,
  frameBaseName: 'TripleBoxFrame',
  fileBaseName: 'frame-1_000',
  fileExt: '.png',
};

export const OlympicConfig: NotificationTypes.GamificationModalParams = {
  folder: 'boxes-two-steps',
  layout: '1column',
  logo: 'logo.png',
  logoWidth: 422,
  logoHeight: 160,
  backgroundMobile: 'bgr-mobile.png',
  background: 'bgr-desktop.png',
  modalCloseColor: 'light',
  numberOfFrames: 49,
  frameWidth: 172,
  frameHeight: 172,
  frameMaxWidth: 320,
  frameMaxHeight: 320,
  frameAspectRatio: 1,
  framePixelDensity: 2,
  frameBaseName: 'OlympicFrame',
  fileBaseName: 'frame-1_000',
  fileExt: '.png',
};

export const PageBoxes: NotificationTypes.GamificationModalParams = {
  folder: 'page-boxes',
  layout: '1column',
  logo: 'logo.png',
  logoWidth: 422,
  logoHeight: 160,
  backgroundMobile: 'bgr-mobile.png',
  background: 'bgr-desktop.png',
  modalCloseColor: 'light',
  numberOfFrames: 49,
  frameWidth: 172,
  frameHeight: 172,
  frameMaxWidth: 320,
  frameMaxHeight: 320,
  frameAspectRatio: 1,
  framePixelDensity: 2,
  frameBaseName: 'OlympicFrame',
  fileBaseName: 'frame-1_000',
  fileExt: '.png',
};

export const wizardBonusConfig: NotificationTypes.GamificationModalParams = {
  folder: 'easter',
  layout: '1column',
  logo: 'logo.png',
  logoWidth: 430,
  logoHeight: 102,
  // backgroundMobile: 'bgr-mobile.png',
  // background: 'bgr-desktop.png',
  modalCloseColor: 'light',
  modalCloseStyle: { width: '16px', height: '16px', fill: '#fff' },
  modalBodyClassName: 'letter',
  modalBodyStyle: { maxWidth: '470px', margin: '17px auto auto auto' },

  // Number of frames should be 0 for no framer animatons!!!
  numberOfFrames: 72,
  frameWidth: 355,
  frameHeight: 390,
  frameMaxWidth: 355,
  frameMaxHeight: 396,
  frameAspectRatio: 0.71,
  framePixelDensity: 2,
  frameBaseName: 'EggFrame',
  fileBaseName: 'frame-1_000',
  fileExt: '.png',
};
