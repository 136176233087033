import React from 'react';
import { Icon } from 'components/shared';
import { Flex } from 'components/shared/layout';

/** Terminal Modal Close Section */
interface CloseProps {
  text: string;
  onClick?: () => void;
}

const Close: React.FC<CloseProps> = ({ text, onClick }) => {
  return (
    <Flex.AC className="modal-close">
      <Flex.AC className="ml-auto modal-close__btn" onClick={onClick}>
        {text}
        <Icon className="modal-close__icon" icon="times" />
      </Flex.AC>
    </Flex.AC>
  );
};

export default Close;
