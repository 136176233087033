import React, { memo } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { InputLocationType } from 'components/shared/forms/formTypes';
import Icon from 'components/shared/Icon';
import { Flex } from 'components/shared/layout';
import { Breakpoints } from 'theme/Theme';
import FormGroup from './FormGroup';
import { FormUI } from './FormUI';

interface WrapperProps {
  componentLocation?: InputLocationType;
  children: React.ReactNode;
  colsClasses?: string;
  disabled?: boolean;
  dropdownOpen: boolean;
  isMobileAgnostic?: boolean;
  label?: string;
  isOutlined?: boolean;
  hasLabelSecondary?: boolean;
  hasError?: boolean;
  labelSecondary?: string;
  formInfoMessage?: React.ReactNode;
}

interface ControlProps {
  type?: string | undefined;
  name: string | undefined;
  placeholder: string | undefined;
  onClick: () => void;
  onTogglerClick: () => void;
  isExpanded: boolean;
  autoComplete: 'on' | 'off';
  readOnly: boolean;
  disabled?: boolean;
  min?: string | number | undefined;
  max?: string | number | undefined;
  onBlur?;
  value?;
  maxLength?: number;
}

interface MenuProps {
  dropup: boolean;
  children: React.ReactNode;
}

interface OptionProps {
  onClick: () => void;
  dataQid?: string | number;
  text: string;
  isActive?: boolean;
  disabled?: boolean;
}

/** Component CSS NameSpace */
const cssNS = 'form-select';

const FormSelectUI = () => {
  return null;
};

/** FormSelectUI Wrapper */
const Wrapper = React.forwardRef<HTMLDivElement, WrapperProps>((props, ref) => {
  const isTablet = useMediaQuery({ query: `(max-width: ${Breakpoints.isTabletOrMobile}px)` });

  const groupClass = clsx(props.colsClasses && props.colsClasses);
  const selectClass = clsx(
    'fcw fcw-dropdown',
    cssNS,
    props.isMobileAgnostic && isTablet && `${cssNS}--simple`,
    props.dropdownOpen && `${cssNS}--expanded`,
    props.disabled && 'disabled',
    props.isOutlined && 'outlined',
    props.hasError && 'bg--error',
  );

  return (
    <FormGroup className={groupClass} isMobileAgnostic={props.isMobileAgnostic}>
      <FormUI.Label componentLocation={props.componentLocation} label={props.label} />
      <div ref={ref} className={selectClass}>
        {props.children}
      </div>
      {props.formInfoMessage}
      {props.hasLabelSecondary && <FormUI.LabelSecondary label={props.labelSecondary} />}
    </FormGroup>
  );
});
Wrapper.displayName = 'Wrapper';

/** FormSelectUI Control */
const Control = React.forwardRef<HTMLInputElement, ControlProps>((props, ref) => {
  const togglerIconClass = clsx('form-select__toggler-icon', props.isExpanded && 'rotated-180');
  return (
    <Flex className="form-select__header">
      <input
        autoComplete={props.autoComplete}
        className="form-control form-select__control"
        disabled={props.disabled}
        max={props.max}
        min={props.min}
        name={props.name}
        onClick={props.onClick}
        onChange={undefined}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
        value={props.value}
        readOnly={true}
        ref={ref}
        type={props.type}
        aria-label={props.name}
        data-qid={props.name}
        maxLength={props.maxLength}
      />
      <Flex.Center className="form-select__toggler" onClick={props.onTogglerClick}>
        <Icon className={togglerIconClass} icon={`chevron`} />
      </Flex.Center>
    </Flex>
  );
});
Control.displayName = 'Control';

/** FormSelectUI Menu */
const Menu: React.FC<MenuProps> = ({ dropup, children }) => {
  const menuClass = clsx(`${cssNS}__menu`, dropup ? `${cssNS}__menu--up` : `${cssNS}__menu--down`);
  return <div className={menuClass}>{children}</div>;
};

/** FormSelectUI Option */
const Option: React.FC<OptionProps> = ({ onClick, text, dataQid, isActive, disabled }) => {
  const optionClass = clsx(
    `${cssNS}__item`,
    isActive && `${cssNS}__item--active`,
    disabled && `${cssNS}__item--disabled`,
  );
  return (
    <div
      className={optionClass}
      aria-label={text}
      data-qid={dataQid ? dataQid : text}
      onClick={!disabled ? onClick : undefined}
    >
      {text}
    </div>
  );
};

FormSelectUI.Wrapper = Wrapper;
FormSelectUI.Control = Control;
FormSelectUI.Menu = memo(Menu);
FormSelectUI.Option = memo(Option);

export default FormSelectUI;
