import React from 'react';

type Props = {
  date: string;
  text: string;
};

const LeaderBoardTimeCounterItem: React.FC<Props> = ({ date, text }) => {
  return (
    <span>
      {date}
      {text}
    </span>
  );
};

export default LeaderBoardTimeCounterItem;
