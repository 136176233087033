import { validateUserSession, updateStorageAuthToken, loginThunks } from 'pages/auth/login/slice/login.slice';
import { setUpdated } from 'shared/common/features/localization/slice/localization.slice';
import { setBalanceVisibility, setPreferredOddTypeId } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { setLanguage } from 'shared/common/sharedSlices/commonActions';
import { AppDispatch } from 'store';
import { isValidJsonStr } from 'utils/common/helpersCommon';
import { LoginInfoModal } from 'utils/common/translationUtils/translationStrings';

export const updateStoreFromLocalStorage =
  (event: StorageEvent) =>
  (dispatch: AppDispatch): void => {
    const key = event.key;
    const newValue = event.newValue && (isValidJsonStr(event.newValue) || event.newValue);

    switch (key) {
      case 'authentication': {
        const oldValue = event.oldValue && (isValidJsonStr(event.oldValue) || event.oldValue);
        const refreshTokenChange = oldValue && newValue && oldValue?.access_token !== newValue?.access_token;
        if (!refreshTokenChange) {
          sessionStorage.removeItem('uniqueTabId');
        }

        refreshTokenChange
          ? dispatch(updateStorageAuthToken())
          : !newValue
            ? dispatch(loginThunks.logout())
            : dispatch(validateUserSession());
        break;
      }
      case 'lang':
        dispatch(setLanguage(newValue || ''));
        break;
      case 'tz_offset':
        dispatch(setUpdated(false));
        break;
      case 'odds_id':
        dispatch(setPreferredOddTypeId(newValue || ''));
        break;
      case 'isBalanceVisible':
        dispatch(setBalanceVisibility());
        break;
      default:
        return undefined;
    }
  };

export const getLoginInfo = ({ type }: { type?: string | null }) => {
  switch (type) {
    case 'account_auto_lock':
      return {
        icon: 'times-solid',
        titleText: LoginInfoModal.AccountAutoLock.Title,
        descriptionText: LoginInfoModal.AccountAutoLock.Description,
        linkText: LoginInfoModal.AccountAutoLock.Link,
        link: '/help/contact-us',
      };
    case 'force_reset_password':
      return {
        icon: 'times-solid',
        titleText: LoginInfoModal.ForceResetPassword.Title,
        linkText: LoginInfoModal.ForceResetPassword.Link,
        link: '/forgot-password',
      };
    default:
      return null;
  }
};
