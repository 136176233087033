import React, { CSSProperties } from 'react';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';

export type ButtonIconProps = {
  icon?: string;
  size?: number;
  iconClassName?: string;
};

export type ButtonProps = {
  type?: 'button';
  className?: string;

  icon?: string;
  size?: number;
  iconClassName?: string;

  style?: CSSProperties;
  onClickHandler?: React.MouseEventHandler<HTMLButtonElement>;
};

export const cssBtnNS = `${cssGlobalNS}-c-btn-back`;
