import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes, VerticalsConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { CAPTAIN_UP_ROUTE } from '../utils/captainUp.utils';

export const useGamificationConfig = (): { route: string; isVisible?: boolean; isWidget?: boolean } => {
  const isGamification = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.VERTICALS,
    deviceLayout: 'general',
  })?.[VerticalsConfigTypes.GAMIFICATION];

  return {
    route: isGamification?.route || CAPTAIN_UP_ROUTE,
    isVisible: isGamification?.isVisible,
    isWidget: isGamification?.isWidget,
  };
};
