import React from 'react';
import clsx from 'clsx';
import usePlayerWinInfo from 'components/shared/playerWinInfo/hooks/usePlayerWinInfo';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import useNavItemConfig from 'shared/common/features/appHeader/hooks/useNavItemConfig';
import { useAppSelector } from 'store';
import { Image } from './Image';
/* Hooks */

interface MenuHookProps {
  hookType: string;
  text: string | JSX.Element;
  className?: string;
  icon?: string;
  sectionType?: string;
  shouldAuthenticate?: boolean;
}

const hookByType = {
  'player-bet-statistics': usePlayerWinInfo,
};

const MenuHook: React.FC<MenuHookProps> = ({ className, hookType, text, icon, sectionType, shouldAuthenticate }) => {
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const login = useNavItemConfig('signIn');
  const customHook = hookByType[hookType]?.();
  const linkClass = clsx('footer-menu__link d-flex-ac', className);
  const iconRender = icon && <Image icon={icon} title={text as string} sectionType={sectionType} />;

  return (
    <a
      className={linkClass}
      href={hookType}
      title={text as string}
      onClick={(e) => {
        e.preventDefault();
        shouldAuthenticate && !isAuthenticated ? login?.onClick() : customHook?.();
      }}
    >
      {iconRender}
      {text}
    </a>
  );
};
export default MenuHook;
