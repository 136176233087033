import React from 'react';
import Icon from 'components/shared/Icon';
import { Flex } from 'components/shared/layout';

interface Props {
  captchaImage?: string;
  onClick: () => void;
  disabled: boolean;
}

const FormCaptcha: React.FC<Props> = ({ captchaImage, onClick, disabled }) => {
  const handleOnClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  return (
    <Flex.Center className="flex-1">
      <img className="login__captcha-image" src={`data:image/jpeg;base64,${captchaImage}`} />
      <Icon
        className={`login__captcha-refresh ${disabled ? '' : 'cursor-pointer'}`}
        style={{ opacity: disabled ? '0.5' : '1' }}
        onClick={handleOnClick}
        size={24}
        icon="refresh"
      />
    </Flex.Center>
  );
};

export default FormCaptcha;
