import React from 'react';

type Props = {
  children?: React.ReactNode;
  className?: string;
};

const PrizeDropGiftItemWrap: React.FC<Props> = ({ children, className }) => {
  const classProp = className ? { class: className } : undefined;

  return <egtd-przd-gift-item-grid {...classProp}>{children}</egtd-przd-gift-item-grid>;
};

export default PrizeDropGiftItemWrap;
