import React from 'react';
import SessionCreate from './SessionCreate';
import SessionCreateTimeout from './SessionCreateTimeout';
import SessionEnd from './SessionEnd';
import SessionInfoTable from './SessionInfoTable';
import SessionInfoText from './SessionInfoText';
import SessionWarn from './SessionWarn';
import { RealityCheckSessionModalTypes } from '../types/realityCheckSession.types';

type Props = {
  modalType: RealityCheckSessionModalTypes;
  message?: string;
  resolve?: (value: unknown) => void;
};

const ModalContent: React.FC<Props> = ({ modalType, message, resolve }): React.ReactNode => {
  switch (modalType) {
    case RealityCheckSessionModalTypes.CREATE:
      return <SessionCreate resolve={resolve} />;
    case RealityCheckSessionModalTypes.TIMEOUT:
      return <SessionCreateTimeout translationKey={message} />;
    case RealityCheckSessionModalTypes.INFO:
      return (
        <>
          <SessionInfoText />
          <SessionInfoTable />
        </>
      );
    case RealityCheckSessionModalTypes.WARN:
      return <SessionWarn />;
    case RealityCheckSessionModalTypes.END:
      return (
        <>
          <SessionEnd />
          <SessionInfoTable isStatic={true} />
        </>
      );
    default:
      return null;
  }
};

export default ModalContent;
