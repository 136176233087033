import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UI } from 'components/shared/UI';
import { useAuthentication } from 'hooks/common/useAuthentication';
import { useNavigateToBankTab } from 'pages/myAccount/tabs/cashier/utils/cashier.utils';
import { Footer } from 'utils/common/translationUtils/translationStrings';
type Props = {
  type?: 'in-scroll';
};
const DepositBtn: React.FC<Props> = ({ type }) => {
  const { t } = useTranslation();
  const bankNavigate = useNavigateToBankTab();
  const { isAuthenticated, showLoginForm } = useAuthentication();
  const navigateToBank = useCallback(() => {
    if (!isAuthenticated) {
      showLoginForm();
    } else {
      bankNavigate('deposit');
    }
  }, [isAuthenticated]);

  return (
    <>
      <UI.Button
        class={`footer-deposit-btn text-truncate ${type ? `footer-deposit-btn--${type}` : null}`}
        data-qid="footerUserDepositBtn"
        onClick={navigateToBank}
        text={t(Footer.Deposit.deposit)}
      />
    </>
  );
};

export default DepositBtn;
