import React from 'react';
import { useLocation } from 'react-router-dom';
import { Icon } from 'components/shared';
import { Flex } from 'components/shared/layout';
import useHeaderConfig from 'shared/common/hooks/useHeaderConfig';
import { sportRoutes, altenarRoutes } from 'shared/router/TopLevelRouter';

interface NavSearchButtonProps {
  handleOpenSearch: () => void;
  showNavSearch: boolean;
  wrapperClassName?: string;
}

const COMBINED_SPORT_ROUTES = [...new Set([...sportRoutes, ...altenarRoutes])];

const NavSearchButton: React.FC<NavSearchButtonProps> = ({
  handleOpenSearch,
  showNavSearch,
  wrapperClassName = '',
}) => {
  const headerConfig = useHeaderConfig();
  const { pathname } = useLocation();

  const isMatchingSportRoute = React.useMemo(() => {
    const currentRoute = pathname.split('/')[1];
    return COMBINED_SPORT_ROUTES.some((route) => route.startsWith(`/${currentRoute}`));
  }, [pathname]);

  if (headerConfig?.hideSportSearchIcon && isMatchingSportRoute) {
    return null;
  }

  const iconClassName = showNavSearch
    ? 'search-bar__icon search-bar__search-icon search-bar__icon--inactive'
    : 'search-bar__icon search-bar__search-icon';

  return (
    <Flex.AC className={wrapperClassName}>
      <Icon onClick={handleOpenSearch} className={iconClassName} icon="search-alt" data-qid="search-icon" />
    </Flex.AC>
  );
};

export default NavSearchButton;
