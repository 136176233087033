import React, { memo, useMemo } from 'react';
import clsx from 'clsx';
import FrameAnimator from 'components/shared/FrameAnimator/FrameAnimator';
import { getCdnImages } from 'components/shared/FrameAnimator/utils/ImageCDN';
import { mapAnimationFrames } from 'components/shared/FrameAnimator/utils/images/utils';
import { Flex } from 'components/shared/layout';
import { NotificationTypes } from '../../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import { StyledBonus } from '../../components/GamificationModalUI/BonusTitle.styled';
import { GamificationUI } from '../../components/GamificationUI';
import { useAnimationAutoTrigger } from '../../hooks/useAnimationAutoTrigger';
import useGamificationItemState from '../../hooks/useGamificationItemState';

interface Props {
  index: number;
  notification: NotificationTypes.PopUpNotification;
  winItemIndex: null | number;
  setWinItemIndex: (index: number) => void;
  setAnimationComplete: (isVisible: boolean) => void;
  animationComplete: boolean;
  animationParams: NotificationTypes.GamificationModalParams;
  folderName: number | string;
  animationAutoTrigger: boolean;
  bonusAlignment?: NotificationTypes.BonusAlignment;
}

const EasterEggItem: React.FC<Props> = ({
  index,
  notification,
  winItemIndex,
  setWinItemIndex,
  setAnimationComplete,
  animationComplete,
  animationParams,
  folderName,
  animationAutoTrigger,
  bonusAlignment,
}) => {
  const { bonusData, button, style } = notification;

  const { state, handleAnimationComplete, handleAnimation } = useGamificationItemState({
    bonusData,
    animationComplete,
    winItemIndex,
    index,
    setWinItemIndex,
    setAnimationComplete,
    notificationId: notification.id,
  });
  useAnimationAutoTrigger({ animationAutoTrigger, handleAnimation });

  const winTextClass = clsx(' text-center grid-start-1x1 gmf-modal__win gmf-modal__win--accent');

  const tileOpenAnimationImages = useMemo(() => {
    return getCdnImages(mapAnimationFrames(animationParams, `${folderName}/${animationParams.fileBaseName}`));
  }, [animationParams]);

  return (
    <>
      <div className="d-grid grid-1x1">
        <div className="grid-start-1x1 gmf-modal__animation">
          <Flex.Center className="animation-frame__wrapper">
            <FrameAnimator
              images={tileOpenAnimationImages}
              width={animationParams.frameWidth}
              height={animationParams.frameHeight}
              maxWidth={animationParams.frameMaxWidth}
              maxHeight={animationParams.frameMaxHeight}
              aspectRatioWidth={animationParams.frameAspectRatio}
              fps={60}
              iterations={1}
              play={state.playAnim}
              onIterationComplete={handleAnimationComplete}
              onClick={handleAnimation}
            />
          </Flex.Center>
        </div>
        {state.giftTitle && state.winIsVisible && (
          <StyledBonus $position={bonusAlignment} className={winTextClass} style={{ maxWidth: `233px` }}>
            {state.giftTitle}
          </StyledBonus>
        )}
      </div>

      {button?.buttonText !== undefined && !animationComplete && !animationAutoTrigger && (
        <Flex.ColAC className="mt-auto" style={{ marginBottom: '40px' }}>
          <GamificationUI.CTAButtonBgImage
            disabled={state.playAnim}
            onClick={handleAnimation}
            buttonText={button?.buttonText}
            folder={animationParams.folder}
            bgColor={style.backgroundButton}
            textColor={style.buttonTextColor}
            borderColor={style.buttonBorderColor}
          />
        </Flex.ColAC>
      )}
    </>
  );
};

export default memo(EasterEggItem);
