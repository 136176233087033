import { useCallback, useEffect, useRef } from 'react';
import {
  fetchCategoryGames,
  gamesSelectors,
} from 'modules/casino/modules/container/cellsContainer/cellls/slice/casinoGamesSlice';
import { CasinoGamesTypes } from 'modules/casino/modules/container/cellsContainer/cellls/types/casinoGamesTypes';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { CategoryQueryType, LayoutConfigInnerLayoutTypes } from 'modules/casino/shared/constants';
import { casinoUiStateSelectors } from 'modules/casino/shared/features/casinoUi/casinoUiStateSlice';
import { useMobileAppRestrictions } from 'modules/casino/shared/features/mobileAppRestrictions/useMobileAppRestrictions';
import { useCasinoLayoutConfig } from 'modules/casino/shared/hooks/useCasinoLayoutConfig';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { getCellPosition } from 'modules/casino/shared/utils/casinoUtils';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { RootState } from 'store/rootReducer';
import { useCasinoGamesFetchStatus } from './useCasinoGamesFetchStatus';

type useCasinoGamesFetchProps = {
  categoryParams: CasinoGamesTypes.RenderGridCategoryParams;
  gameCollectionId: string;
  searchTagId: string;
  fetchGameSize: number;
  categoryLayoutType: CategoryLayoutTypes;
};

export const useCasinoGamesFetch = ({
  gameCollectionId,
  searchTagId = '',
  fetchGameSize,
  categoryParams,
  categoryLayoutType,
}: useCasinoGamesFetchProps) => {
  const { categoryId, queryType } = categoryParams;
  const { hasNumbers } = useGetCategoryParams(`${categoryId}`, categoryLayoutType);
  const page = useRef<number>(0);
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const categoryFetchInterval = useCasinoLayoutConfig({
    innerLayout: LayoutConfigInnerLayoutTypes.CATEGORY_FETCH_INTERVAL,
  });
  const timerIdRef = useRef<ReturnType<typeof setInterval>>();
  const appDispatch = useAppDispatch();
  const categoryCollection = useAppSelector((state: RootState) =>
    gamesSelectors.categoryCollection(state, gameCollectionId),
  );
  const isCategoryInView = useAppSelector((state) => casinoUiStateSelectors.getCategoryInView(state, gameCollectionId));
  const { hasNoGames, hasSuccess, hasInitial, isLoading } = useCasinoGamesFetchStatus(
    String(categoryId),
    categoryLayoutType,
  );
  const mobileAppRestrictions = useMobileAppRestrictions();
  const lastPlayedHasGamesStatus = queryType === CategoryQueryType.LAST_PLAYED_CATEGORY && hasSuccess;
  const lastPlayedNoGamesStatus = queryType === CategoryQueryType.LAST_PLAYED_CATEGORY && hasNoGames;
  const fetchGameSizeOverride = mobileAppRestrictions.isRestricted ? 500 : fetchGameSize;
  const fetchMoreGames = useCallback(() => {
    const nextPage = page.current + 1;

    const params = {
      collectionId: gameCollectionId,
      categoryParams,
      size: fetchGameSizeOverride,
      page: nextPage,
      searchTagId: searchTagId,
      hasNumbers,
      mobileAppRestrictions,
    };

    if (!(nextPage > categoryCollection?.currentPage)) {
      page.current = categoryCollection?.currentPage;
      params.page = page.current + 1;
      appDispatch(fetchCategoryGames({ queryType, params, isAuthenticated: !!isAuthenticated }));
      return;
    }
    page.current = nextPage;
    appDispatch(fetchCategoryGames({ queryType, params, isAuthenticated: !!isAuthenticated }));
  }, [categoryId, fetchGameSizeOverride, categoryCollection]);

  useEffect(() => {
    if (lastPlayedNoGamesStatus || queryType == CategoryQueryType.SEARCH_BY_TITLE) {
      return;
    }

    if (hasInitial && !isLoading && fetchGameSizeOverride) {
      const cell = getCellPosition();
      const isGamesCountToCellPosition = cell?.gamesCount && cell.collectionId === gameCollectionId;
      const params = {
        collectionId: gameCollectionId,
        categoryParams,
        size: isGamesCountToCellPosition ? cell?.gamesCount : fetchGameSizeOverride,
        page: page.current,
        searchTagId: searchTagId,
        hasNumbers,
        mobileAppRestrictions,
      };
      page.current = isGamesCountToCellPosition ? cell?.gamesCount / fetchGameSizeOverride - 1 : page.current;
      appDispatch(fetchCategoryGames({ queryType, params, isAuthenticated: !!isAuthenticated }));
    }
  }, [gameCollectionId, appDispatch, fetchGameSizeOverride, lastPlayedHasGamesStatus, lastPlayedNoGamesStatus]);

  useEffect(() => {
    if (queryType !== CategoryQueryType.SEARCH_BY_TITLE) {
      return;
    }

    if (!isLoading) {
      const params = {
        collectionId: gameCollectionId,
        categoryParams,
        size: 0,
        page: 0,
        searchTagId: '',
        hasNumbers,
        mobileAppRestrictions,
      };
      appDispatch(fetchCategoryGames({ queryType, params }));
    }
  }, [appDispatch, categoryParams.searchParams, categoryParams.filteredProviders]);

  useEffect(() => {
    if (lastPlayedNoGamesStatus) {
      return;
    }

    if (!isLoading && fetchGameSizeOverride) {
      const cell = getCellPosition();
      const isGamesCountToCellPosition = cell?.gamesCount && cell.collectionId === gameCollectionId;
      const params = {
        collectionId: gameCollectionId,
        categoryParams,
        size: isGamesCountToCellPosition ? cell?.gamesCount : fetchGameSizeOverride,
        page: page.current,
        searchTagId: searchTagId,
        hasNumbers,
        mobileAppRestrictions,
      };
      page.current = isGamesCountToCellPosition ? cell?.gamesCount / fetchGameSizeOverride - 1 : page.current;
      if (categoryFetchInterval && categoryFetchInterval[queryType] && isCategoryInView?.inView) {
        startPolling(params, categoryFetchInterval[queryType]);
      } else {
        stopPolling();
      }

      return () => stopPolling();
    }
  }, [
    gameCollectionId,
    appDispatch,
    fetchGameSizeOverride,
    lastPlayedHasGamesStatus,
    lastPlayedNoGamesStatus,
    isCategoryInView,
  ]);

  const startPolling = (params, interval: number | undefined) => {
    if (!interval) return;
    appDispatch(fetchCategoryGames({ queryType, params, isAuthenticated: !!isAuthenticated }));
    timerIdRef.current = setInterval(
      () =>
        appDispatch(
          fetchCategoryGames({
            queryType,
            params,
            isAuthenticated: !!isAuthenticated,
          }),
        ),
      interval,
    );
  };

  const stopPolling = () => {
    clearInterval(timerIdRef.current);
  };

  return {
    fetchMoreGames: fetchMoreGames,
  };
};
