import React from 'react';
import clsx from 'clsx';
import { NotificationTypes } from '../../../myProfile/sections/tabs/notificationsTab/notificationTypes';

interface CloseProps {
  layout: NotificationTypes.GamificationCampaignsLayouts;
  children: React.ReactNode;
  fullWidth?: boolean;
}

const Layout: React.FC<CloseProps> = ({ layout, children, fullWidth }) => {
  const layoutClass = clsx(
    layout === '1column' && 'd-flex-col',
    layout === '4columns' && 'd-grid grid-2-cols-4-cols-lg-up mt-lg-4',
    fullWidth && 'w-100',
  );
  return <div className={layoutClass}>{children}</div>;
};

export default Layout;
