import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reprintInfoThunk } from 'modules/retail/modules/cashier/features/reprintTicket/slice/reprintTicket.slice';
import { resetStateDepositTicket } from 'modules/retail/modules/cashier/slice/cashier.slice';

import userStorage from 'pages/auth/login/UserStorage';
import { homePageThunks } from 'pages/homePage/slice/homePage.slice';

import { useAppDispatch } from 'store';

import { isEmpty } from 'utils/common/helpersCommon';
import { MyAccountTranslation, SSBT } from 'utils/common/translationUtils/translationStrings';

import { ticketInfoThunk } from '../../../../../shared/slice/checkTicket.slice';
import SSBTTicketBody from '../../../components/ticketUI/TicketSSBT/SSBTTicketBody';
import SSBTTicketContent from '../../../components/ticketUI/TicketSSBT/SSBTTicketContent';
import SSBTTicketFooter from '../../../components/ticketUI/TicketSSBT/SSBTTicketFooter';
import SSBTTicketHeader from '../../../components/ticketUI/TicketSSBT/SSBTTicketHeader';
import SSBTTicketWrapper from '../../../components/ticketUI/TicketSSBT/SSBTTicketWrapper';

import { CheckTicket, TicketBet } from '../../../types/ssbt.types';

type Props = {
  ticketInfo: CheckTicket | null;
  closeTerminalModal?: () => void;
  isReprint?: boolean;
  expandedTicketId?: number;
};

const SSBTLastStepTicketModal: React.FC<Props> = ({
  ticketInfo,
  closeTerminalModal,
  isReprint = false,
  expandedTicketId,
}) => {
  const dispatch = useAppDispatch();
  const userCurr = userStorage.getUserCurrency();
  const currency = userCurr || '';

  const footerRef = useRef<HTMLDivElement | null>(null);
  const footerHight = Number(footerRef.current?.offsetHeight);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(homePageThunks.fetchEventStatusConfig());
    dispatch(resetStateDepositTicket());
    if (ticketInfo?.status === 'ACCEPTED') {
      const interval = setInterval(() => {
        if (isReprint && !isEmpty(expandedTicketId)) {
          return dispatch(reprintInfoThunk.getCheckTicketInfoByTicketHistoryId({ id: expandedTicketId }));
        }
        dispatch(ticketInfoThunk.checkTicket({ ticketId: ticketInfo?.ticket?.ticketId }));
      }, 6000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [ticketInfo, dispatch, isReprint, expandedTicketId]);

  return (
    <SSBTTicketWrapper onClick={closeTerminalModal} isReprint={isReprint}>
      {/* Ticket ID */}
      {!isReprint && (
        <SSBTTicketHeader
          ticketIdText={t(SSBT.TerminalTicket.ticketNumberTerminal)}
          ticketId={ticketInfo?.ticket?.ticketId}
          isExpanded
        />
      )}
      {/* Ticket body Scroll container */}
      <SSBTTicketBody style={{ maxHeight: `calc(100% - ${footerHight})` }}>
        {/* Ticket content */}
        {ticketInfo?.ticket?.bets.map((bet: TicketBet) => (
          <SSBTTicketContent
            key={bet?.betId}
            bet={bet}
            ticketInfo={ticketInfo}
            betTypeText={t(MyAccountTranslation.BetHistory.betTypeHeaderText)}
            betSystem={bet.selectedSystem}
            numberOfBetsText={t(MyAccountTranslation.BetHistory.numberOfBetsText)}
            totalCombinations={bet.totalCombinations}
            totalOddsText={t(MyAccountTranslation.BetHistory.totalOddsText)}
            totalOddsToDisplay={bet.totalOdds}
            stakeText={t(MyAccountTranslation.BetHistory.unitStakeText)}
            stakeAmount={Number(bet.stake).toFixed(2)}
            totalStakeText={t(MyAccountTranslation.BetHistory.totalStakeText)}
            totalStakeAmount={Number(bet.totalStake).toFixed(2)}
            systemText={t(`BetSlip.Systems.${bet?.selectedSystem}`)}
            bets={ticketInfo?.ticket?.bets}
          />
        ))}
      </SSBTTicketBody>
      {/* // Footer content: Profit info and ticket status */}
      <SSBTTicketFooter currencySymbol={currency} ticketInfo={ticketInfo} footerRef={footerRef} />
    </SSBTTicketWrapper>
  );
};
export default SSBTLastStepTicketModal;
