import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { resetStateDepositTicket } from 'modules/retail/modules/cashier/slice/cashier.slice';
import userStorage from 'pages/auth/login/UserStorage';
import { homePageThunks } from 'pages/homePage/slice/homePage.slice';
import { useAppDispatch } from 'store';
import { toDecimal, toFixed } from 'utils/common/helpersCommon';
import { MyAccountTranslation, SSBT } from 'utils/common/translationUtils/translationStrings';
import { ticketInfoThunk } from '../../../../../shared/slice/checkTicket.slice';
import CashierTicketBody from '../../../components/ticketUI/TicketCashier/CashierTicketBody';
import CashierTicketContent from '../../../components/ticketUI/TicketCashier/CashierTicketContent';
import CashierTicketFooter from '../../../components/ticketUI/TicketCashier/CashierTicketFooter';
import CashierTicketHeader from '../../../components/ticketUI/TicketCashier/CashierTicketHeader';
import CashierTicketWrapper from '../../../components/ticketUI/TicketCashier/CashierTicketWrapper';
import { CheckTicket, TicketBet } from '../../../types/ssbt.types';

type Props = {
  ticketInfo: CheckTicket | null;
};

const CashierLastStepTicketModal: React.FC<Props> = ({ ticketInfo }) => {
  const dispatch = useAppDispatch();
  const userCurr = userStorage.getUserCurrency();
  const currency = userCurr || '';

  // const isCurrencyBeforeAmount = useLayoutConfigType({
  //   moduleLayout: 'platform',
  //   deviceLayout: 'general',
  //   innerLayout: LayoutConfigTypes.CURRENCY_BEFORE_AMOUNT,
  // });

  const footerRef = useRef<HTMLDivElement | null>(null);
  const footerHight = Number(footerRef.current?.offsetHeight);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(homePageThunks.fetchEventStatusConfig());
    dispatch(resetStateDepositTicket());
    if (ticketInfo?.status === 'ACCEPTED') {
      const interval = setInterval(() => {
        dispatch(ticketInfoThunk.checkTicket({ ticketId: ticketInfo?.ticket?.ticketId }));
      }, 6000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [ticketInfo, dispatch]);

  return (
    <CashierTicketWrapper>
      {/* Ticket ID */}
      <CashierTicketHeader
        ticketIdText={t(SSBT.TerminalTicket.ticketNumberTerminal)}
        ticketId={ticketInfo?.ticket?.ticketId}
        isExpanded
      />
      {/* Ticket body Scroll container */}
      <CashierTicketBody style={{ maxHeight: `calc(100% - ${footerHight})` }}>
        {ticketInfo?.ticket?.bets.map((bet: TicketBet) => (
          <CashierTicketContent
            bet={bet}
            key={bet?.betId}
            ticketInfo={ticketInfo}
            betTypeText={t(MyAccountTranslation.BetHistory.betTypeHeaderText)}
            betSystem={bet.selectedSystem}
            numberOfBetsText={t(MyAccountTranslation.BetHistory.numberOfBetsText)}
            totalCombinations={bet.totalCombinations}
            totalOddsText={t(MyAccountTranslation.BetHistory.totalOddsText)}
            totalOddsToDisplay={bet.totalOdds}
            stakeText={t(MyAccountTranslation.BetHistory.unitStakeText)}
            stakeAmount={Number(bet.stake).toFixed(2)}
            totalStakeText={t(MyAccountTranslation.BetHistory.totalStakeText)}
            totalStakeAmount={Number(bet.totalStake).toFixed(2)}
            systemText={t(`BetSlip.Systems.${bet?.selectedSystem}`)}
            totalPossibleWin={toFixed(toDecimal(bet.possibleWin), 2)}
            bets={ticketInfo?.ticket?.bets}
          />
        ))}
      </CashierTicketBody>
      {/* // Footer content: Profit info and ticket status */}
      <CashierTicketFooter currency={currency} ticketInfo={ticketInfo} footerRef={footerRef} />
    </CashierTicketWrapper>
  );
};
export default CashierLastStepTicketModal;
