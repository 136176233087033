import { memo, RefObject } from 'react';
import clsx from 'clsx';
import styled, { css } from 'styled-components';
import { WidgetTemplate } from 'modules/casino/shared/constants';
import { CasinoWidgetsTypes } from '../../../types/casinoWidgetsTypes';

const wrapperSectionStyles = css<{
  type?: string;
}>`
  height: ${({ type }) => type && '80px'};
  display: grid;
  grid-template-columns: ${({ type }) => type && 'repeat(12, 200px)'};
  grid-template-rows: 100%;
  grid-gap: 5px;
  overflow: hidden;
`;

const wrapperStyles = css`
  overflow: hidden;
  height: 100%;
`;

const StyledWrapper = styled.div<{
  type: string;
}>`
  ${({ type }) => (type && WidgetTemplate[type] === WidgetTemplate.SECTION ? wrapperSectionStyles : wrapperStyles)};
`;

const StyledCellElement = styled.div<{
  type: string;
}>`
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: 80px;
  background: none;
  margin-bottom: ${({ type }) => (WidgetTemplate[type] === WidgetTemplate.SECTION ? '' : '5px')};
`;

interface Props {
  type: CasinoWidgetsTypes.WidgetTypes;
  columns: number;
  setRefs?: RefObject<HTMLDivElement>;
}

const GenericWidgetSkeleton: React.FC<Props> = ({ type, columns, setRefs }) => {
  const wrapperClass = clsx('cw', 'cw--generic', `cw--${WidgetTemplate[type]}`);
  const styledCellElementClass = clsx(
    'cw--skeleton-element',
    WidgetTemplate[type] === WidgetTemplate.SECTION ? '' : 'w-100',
  );
  const innerWrapperStyle = {
    gridTemplateColumns:
      WidgetTemplate[type] === WidgetTemplate.SECTION ? `242px auto` : `repeat(${columns}, ${100 / columns}%)`,
  };
  const renderItems = (number: number) => {
    const elements: React.ReactNode[] = [];
    for (let index = 0; index < number; index++) {
      elements.push(
        <StyledCellElement
          key={`${number}_${index}`}
          type={type}
          className={styledCellElementClass}
          style={{ height: '100%' }}
        ></StyledCellElement>,
      );
    }
    return elements;
  };

  return (
    <div className={wrapperClass} ref={setRefs && setRefs}>
      <div className="cw--inner-wrapper" style={innerWrapperStyle}>
        <StyledWrapper type={type}>{renderItems(1)}</StyledWrapper>
      </div>
    </div>
  );
};

export default memo(GenericWidgetSkeleton);
