import React from 'react';
import { SCWin, SCWinText, SCWinCardWrapper } from './Win.styled';

/** Win */
interface WinProps {
  imgSrc: string;
  text: string;
}

const Win: React.FC<WinProps> = ({ imgSrc, text }) => {
  return (
    <SCWinCardWrapper>
      <SCWin src={imgSrc} />
      <SCWinText>{text}</SCWinText>
    </SCWinCardWrapper>
  );
};

export default Win;
