import { Children, cloneElement } from 'react';
const makeTypeChecker = (tabsRole) => {
  return (element) => !!element.type && element.type.displayName === tabsRole;
};

const isTab = makeTypeChecker('Tab');
const isTabList = makeTypeChecker('TabList');
const isTabPanel = makeTypeChecker('TabPanel');

const isTabChild = (child) => {
  return isTab(child) || isTabList(child) || isTabPanel(child);
};

const deepMap = (children, callback) => {
  return Children.map(children, (child, index) => {
    if (child === null) return null;

    if (isTabChild(child)) {
      return callback(child, index);
    }

    if (child.props && child.props.children && typeof child.props.children === 'object') {
      return cloneElement(child, {
        ...child.props,
        children: deepMap(child.props.children, callback),
      });
    }
    return child;
  });
};

const getGliderPosition = ({
  elementRef,
}: {
  elementRef: HTMLDivElement;
}): {
  width: number;
  left: number;
} | null => {
  const item = elementRef;
  const pos = item
    ? {
        width: item.clientWidth,
        left: item.offsetLeft,
      }
    : null;
  return pos;
};

const classExtendByLocation = {
  promotions: 'pr',
  myAccount: 'ma',
};

export { deepMap, isTab, isTabList, isTabPanel, getGliderPosition, classExtendByLocation };
