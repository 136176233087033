import React from 'react';
import clsx from 'clsx';
import { Flex } from 'modules/casino/shared/components/layout';
import { WidgetAlignmentItems } from 'modules/casino/shared/constants';
import { CasinoWidgetsTypes } from '../../../../types/casinoWidgetsTypes';

interface ButtonTypes {
  button: CasinoWidgetsTypes.WidgetNavigation;
}

const Button: React.FC<ButtonTypes> = ({ button }) => {
  const buttonClass = clsx('cw__wrapper');
  const buttonStyle = {
    justifyContent: WidgetAlignmentItems[button.alignmentX],
    alignItems: WidgetAlignmentItems[button.alignmentY],
    gridColumnStart: button.column,
  };

  return (
    <Flex className={buttonClass} style={buttonStyle}>
      <div className="cw__button" style={{ background: button.backgroundColor, color: button.textColor }}>
        {button.text}
      </div>
    </Flex>
  );
};
Button.displayName = 'LastWinnersWidgetsUI.Button';
export default Button;
