import { useEffect } from 'react';
import { useGetGameInfoById } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGetGameInfo';
import { useAppDispatch, useAppSelector } from 'store';
import { RootState } from 'store/rootReducer';
import { resetGamesInfo } from '../../../slice/casinoGamesSlice';
import { addFavGame, favoriteGamesSelectors, resetFavGameIds } from '../slice/casinoFavouriteGamesSlice';

const FavouriteGameIdUpdate = () => {
  const dispatch = useAppDispatch();
  const gameIdsToAdd = useAppSelector((state: RootState) => favoriteGamesSelectors.gameIdsToAdd(state));
  const gamesInfo = useGetGameInfoById(gameIdsToAdd);

  useEffect(() => {
    if (gameIdsToAdd?.length && gamesInfo?.length) {
      gameIdsToAdd.forEach((gameId) => {
        const gameToAdd = gamesInfo.find(({ id }) => id === gameId);

        if (gameToAdd) {
          dispatch(addFavGame(gameToAdd));
        }
      });

      dispatch(resetFavGameIds());
      dispatch(resetGamesInfo());
    }
  }, [gamesInfo]);

  return null;
};

export default FavouriteGameIdUpdate;
