import { useTranslation } from 'react-i18next';
import { InfoTableUI } from 'modules/retail/modules/ssbt/components/infoTableUI';
import { isEmpty } from 'utils/common/helpersCommon';
import { useVoucherData } from './useVoucherData';
import { CashierModalUI } from '../../cashierModalUI/Index';

const PayCancelCashierVoucher = () => {
  const { t } = useTranslation();
  const { voucherInfoCashier, voucherInfoCashierStatus, payoutVoucherStatus, cancelVoucherStatus } = useVoucherData();
  return (
    <>
      {isEmpty(voucherInfoCashier) && voucherInfoCashierStatus === 'idle' && (
        <CashierModalUI.InfoMessage
          className="m-auto"
          icon={'ticket-solid'}
          infoMessage={t('Cashier.VoucherModal.payCancelVoucherInit')}
        />
      )}
      {!isEmpty(voucherInfoCashier) && !voucherInfoCashier?.isValid && (
        <CashierModalUI.InfoMessage
          className="m-auto color--danger fill--danger"
          icon={'info-solid'}
          infoMessage={t('Cashier.VoucherModal.invalidVoucher')}
        />
      )}
      {voucherInfoCashierStatus === 'failure' && (
        <CashierModalUI.InfoMessage
          className="m-auto color--danger fill--danger"
          icon={'info-solid'}
          infoMessage={t('Cashier.RetailErrorModal.errorText')}
        />
      )}
      {!isEmpty(voucherInfoCashier) && (payoutVoucherStatus === 'failure' || cancelVoucherStatus === 'failure') && (
        <CashierModalUI.InfoMessage
          className="m-auto color--danger fill--danger"
          icon={'info-solid'}
          infoMessage={t('Cashier.RetailErrorModal.errorText')}
        />
      )}
      {!isEmpty(voucherInfoCashier) &&
        (payoutVoucherStatus === 'idle' || cancelVoucherStatus === 'idle') &&
        payoutVoucherStatus !== 'failure' &&
        cancelVoucherStatus !== 'failure' &&
        voucherInfoCashier?.isValid && (
          <InfoTableUI.Wrapper>
            <InfoTableUI.Cell
              isSpanned
              cellText={t('Cashier.CashPayModal.amount')}
              cellValue={`${voucherInfoCashier?.amount} ${voucherInfoCashier?.currency}`}
            />
          </InfoTableUI.Wrapper>
        )}
    </>
  );
};

export default PayCancelCashierVoucher;
