import { useCallback, useEffect, useState, RefObject } from 'react';

import { isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { useOnOff } from 'hooks/common/useOnOff';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { Breakpoints } from 'theme/Theme';
import {
  selectAppHeader,
  setAppHeaderHeight,
  setAppMainNavHeight,
  setAppTopNavHeight,
  setTopNavHeaderStatus,
  TopNavHeaderStatus,
} from '..';
import { selectBottomTabBar } from '../../bottomTabBar/slice/bottomTabBar.slice';
import { LayoutConfigTypes, General } from '../../general/types/generalSlice.types';
import { selectMyProfile } from '../../myProfile/slice/myProfile.slice';
import { selectNavigationMenu } from '../../navigationMenu/slice/navigationMenu.slice';
import { selectIsExpanded } from '../../ticker/slice/mostPlayed.slice';

type HookReturnType = {
  navigationConfig: General.NavigationConfig | undefined;
  topNavHeaderStatus: TopNavHeaderStatus;
  isMyProfileModalShowing: boolean;
  classByLocation: string;
  isTabletOrMobile: boolean;
  isAuthenticated: boolean | null;
  location;
  triggerAnimationEnd: (handleAnimationEnd: boolean) => void;
  isMyBetsVisible: boolean;
};

const useAppHeader = (
  appHeaderRef: RefObject<HTMLElement>,
  topNavBarRef: RefObject<HTMLElement>,
  mainNavRef: RefObject<HTMLElement>,
): HookReturnType => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isAnimationEnd, setIsAnimationEnd] = useOnOff(true);
  const [classByLocation, setclassByLocation] = useState('');
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const topNavHeaderStatus = useAppSelector(selectAppHeader.topNavHeaderStatus);
  const isMyBetsVisible = useAppSelector(selectBottomTabBar.isMyBetsVisible);
  const isExpanded = useAppSelector(selectIsExpanded);
  const isMyProfileModalShowing = useAppSelector(selectMyProfile.isModalShowing);

  const navigationConfig = useLayoutConfigType({ moduleLayout: 'platform', innerLayout: LayoutConfigTypes.NAVIGATION });

  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isTabletOrMobile}px)` });

  useEffect(() => {
    if (navigationConfig?.topNavInitialCollapsed) {
      dispatch(setTopNavHeaderStatus('collapsed'));
    }
  }, []);

  useEffect(() => {
    // check if global search pages
    // REMOVE THAT
    // Check location - EGTI GAME LАUNCH
    if (location.pathname.indexOf('casino-egt/EGTI/') > 0) {
      setclassByLocation('head-game-launch');
    } else {
      setclassByLocation('');
    }
  }, [location.pathname, dispatch]);

  const triggerAnimationEnd = useCallback(
    (handleAnimationEnd: boolean) => {
      setIsAnimationEnd(handleAnimationEnd);
    },
    [isAnimationEnd, setIsAnimationEnd],
  );

  // CHECK HEADER HEIGHT
  useSetHeight({ isExpanded, appHeaderRef, topNavBarRef, mainNavRef, topNavHeaderStatus, isAnimationEnd });

  return {
    navigationConfig,
    topNavHeaderStatus,
    isMyProfileModalShowing,
    classByLocation,
    isTabletOrMobile,
    isAuthenticated,
    location,
    triggerAnimationEnd,
    isMyBetsVisible,
  };
};
export default useAppHeader;

type UseSetHeightProps = {
  isExpanded: boolean;
  appHeaderRef: RefObject<HTMLElement>;
  topNavBarRef: RefObject<HTMLElement>;
  mainNavRef: RefObject<HTMLElement>;
  topNavHeaderStatus: TopNavHeaderStatus;
  isAnimationEnd: boolean;
};

const useSetHeight = ({
  isExpanded,
  appHeaderRef,
  topNavBarRef,
  mainNavRef,
  topNavHeaderStatus,
  isAnimationEnd,
}: UseSetHeightProps) => {
  const dispatch = useAppDispatch();
  const navigationMenu = useAppSelector(selectNavigationMenu.verticals);
  useEffect(() => {
    // waiting header and get height
    if (isAnimationEnd) {
      dispatch(setAppHeaderHeight(appHeaderRef.current ? appHeaderRef.current.clientHeight : 0));
      dispatch(setAppTopNavHeight(topNavBarRef.current ? topNavBarRef.current.clientHeight : 0));
      dispatch(setAppMainNavHeight(mainNavRef.current ? mainNavRef.current.clientHeight : 0));
    }
  }, [
    isAnimationEnd,
    isExpanded,
    navigationMenu,
    isMobile,
    appHeaderRef,
    topNavBarRef,
    mainNavRef,
    topNavHeaderStatus,
    dispatch,
  ]);
};
