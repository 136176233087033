import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import {
  navigateToCasinoIntegration,
  navigateToIntegration,
} from 'shared/common/features/integrations/utils/integrations.utils';
import { Image } from './Image';

/** Footer Menu Link */
interface MenuLinkProps {
  /** Optional CSS class */
  className?: string;
  /** Link route */
  linkRoute: string;
  /** Link text */
  text: string | JSX.Element;
  /** Mail to */
  phoneNumber?: string;
  isExternal?: boolean;
  icon?: string;
  type?: 'link' | 'email' | 'phone';
  sectionType?: string;
}

const lintByType = {
  email: (linkRoute: string) => `mailTo:${linkRoute}`,
  phone: (linkRoute: string) => `tel:${linkRoute}`,
  link: (linkRoute: string) => `${linkRoute}`,
};

const MenuLink: React.FC<MenuLinkProps> = ({
  linkRoute,
  className,
  text,
  isExternal,
  icon,
  type = 'link',
  sectionType,
}) => {
  const linkClass = clsx('footer-menu__link d-flex-ac', className);

  const iconRender = icon && (
    // <SVG className={svgClass} src={iconPath} width={18} height={18} title={text as string} cacheRequests={true} />
    <Image icon={icon} title={text as string} sectionType={sectionType} />
  );

  const handleNavClick = useCallback(() => {
    navigateToIntegration({ fullPath: linkRoute });
    navigateToCasinoIntegration({ fullPath: linkRoute });
  }, [linkRoute]);

  if (isExternal) {
    return (
      <a className={linkClass} target="_blank" href={linkRoute} rel="noreferrer">
        {iconRender}
        {text}
      </a>
    );
  }

  const navigateLink = lintByType[type ?? '']?.(linkRoute);
  return (
    <>
      {type === 'link' ? (
        <Link className={linkClass} to={navigateLink} onClick={handleNavClick}>
          {iconRender}
          {text}
        </Link>
      ) : (
        <a className={linkClass} href={navigateLink} title={text as string} target="_blank" rel="noreferrer">
          {iconRender}
          {text}
        </a>
      )}
    </>
  );
};
export default MenuLink;
