import React, { useEffect } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import { useAppDispatch } from 'store';
import { setShowComponents } from '../features/gameLaunch/casinoLaunchSlice';
import { clearCellScrollData, getCellPosition } from '../utils/casinoUtils';

type useRestoreCellScrollPositionProps = {
  gameCellRef: React.MutableRefObject<HTMLDivElement | null>;
  disableSaveScrollPosition: boolean;
  uniqueId: string;
  collectionId: string;
  refGameId: string;
};

export const useRestoreCellScrollPosition = ({
  gameCellRef,
  disableSaveScrollPosition,
  uniqueId,
  collectionId,
  refGameId,
}: useRestoreCellScrollPositionProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const cell = getCellPosition(disableSaveScrollPosition);

    if (
      (cell && cell.uniqueId === uniqueId && gameCellRef.current && gameCellRef.current.clientHeight) ||
      (cell && cell.exitReloaded && cell.refGameId === refGameId && cell.collectionId === collectionId)
    ) {
      setTimeout(() => {
        if (gameCellRef.current) {
          const nodeElement = gameCellRef.current;
          scrollIntoView(nodeElement, { block: 'center', inline: 'center' });
          history.scrollRestoration = 'auto';
          dispatch(setShowComponents(true));
        }
      });

      clearCellScrollData();
    }
    dispatch(setShowComponents(true));
  }, [refGameId, collectionId]);
};
