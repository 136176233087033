import { isEmpty } from 'utils/common/helpersCommon';
import { Sport, SportIDs } from 'utils/common/types/sportTypes';
import { SportDataTypes } from 'utils/common/types/sportTypes/sportData.types';

export const getMinutes = (elapsedTime: number): number => {
  return Math.floor(elapsedTime / 60);
};

export const getSeconds = (elapsedTime: number): string => {
  return `0${elapsedTime % 60}`.slice(-2);
};

export const convertToSeconds = (eventTime: string): number => {
  if (eventTime) {
    const eventTimeSplit = eventTime.split(':');
    return parseInt(eventTimeSplit[0]) * 60 + parseInt(eventTimeSplit[1]);
  }
  return 0;
};

export const checkIfSportHasClock = (sportId: number, sportsWithClock: number[] | undefined): boolean => {
  return !!sportsWithClock?.includes(sportId);
};

const map = { [SportIDs.basketball_3x3]: 'remainingTime', [SportIDs.handball]: 'eventTime' };
const getTimeForConversion = (eventClock, sportId) => {
  if (isEmpty(eventClock?.remainingTime) || eventClock?.remainingTime === '0:00') {
    return eventClock?.eventTime;
  } else {
    if (map[sportId]) {
      return eventClock[map[sportId]];
    } else {
      return eventClock?.remainingTimeInPeriod;
    }
  }
};

export const checkIsClockNull = (
  sportId: number,
  eventStatusConfig: SportDataTypes.EventStatusConfig | null,
): boolean => {
  const clockConfig = eventStatusConfig?.sportsConfig?.find((data) => data?.id === sportId)?.config.clock;
  return clockConfig === null ? false : true;
};

export const getShowEventClock = (
  sportId: number,
  eventStatusConfig: SportDataTypes.EventStatusConfig | null,
): boolean =>
  checkIsClockNull(sportId, eventStatusConfig) && checkIfSportHasClock(sportId, eventStatusConfig?.sportsWithClock);

const getStoppageTimeForConversion = (eventClock) => {
  return eventClock?.stoppageTime ? eventClock?.stoppageTime : null;
};

const getTimePlusStoppageTime = (elapsedTime, stoppageTime) => {
  return convertToSeconds(elapsedTime) + convertToSeconds(stoppageTime);
};

const getRemainingTime = (sportId, eventStatusConfig, tournamentId, matchStatus, eventClock) => {
  const clockConfig = eventStatusConfig?.sportsConfig.find((data) => data?.id === sportId)?.config.clock;
  if (sportId === SportIDs.soccer && eventStatusConfig?.pausingMatchStatuses?.includes(matchStatus)) {
    switch (matchStatus) {
      case 'Halftime':
        return convertToSeconds('45:00');
      case 'Awaiting extra time':
        return convertToSeconds('90:00');
      case 'Extra time halftime':
        return convertToSeconds('105:00');
      case 'Awaiting penalties':
      case 'Penalties':
        return convertToSeconds('120:00');
      default:
        return convertToSeconds(!isEmpty(eventClock) ? eventClock.eventTime : '0:00');
    }
  }
  if (matchStatus === 'Interrupted') {
    return convertToSeconds(!isEmpty(eventClock) ? eventClock.eventTime : '0:00');
  }
  return clockConfig?.periodsLength?.[tournamentId] !== undefined
    ? clockConfig?.periodsLength?.[tournamentId]
    : clockConfig?.defaultLength;
};

export const getEventClockResult = (
  eventClockStatus: Sport.EventClockStatus,
  sportId: number,
  eventStatusConfig: SportDataTypes.EventStatusConfig | null,
  tournamentId: number,
):
  | {
      action: string;
      elapsedTime: number;
      decrement?: boolean | null | '';
    }
  | undefined => {
  const clockConfig = eventStatusConfig?.sportsConfig?.find((data) => data?.id === sportId)?.config?.clock;

  switch (eventClockStatus?.eventStatus) {
    case 'NotStarted':
      return;
    case 'Suspended':
      return;
    case 'Ended':
      return stopAndResetClock();
    case 'Closed':
      return;
    case 'Cancelled':
      return;
    case 'Delayed':
      return;
    case 'Interrupted':
      return {
        action: 'stop',
        elapsedTime: convertToSeconds('0:00'),
      };
    case 'Postponed':
      return;
    case 'Abandoned':
      return;
    case 'Live':
      // check if sport event is in break
      if (eventStatusConfig?.pausingMatchStatuses?.includes(eventClockStatus?.matchStatus)) {
        return {
          action: 'stop',
          elapsedTime: getRemainingTime(
            sportId,
            eventStatusConfig,
            tournamentId,
            eventClockStatus?.matchStatus,
            eventClockStatus?.eventClock,
          ),
        };
      }
      // check if sport event is in break
      // if (!eventClockStatus?.eventClock) {
      //   return {
      //     action: 'stop',
      //     elapsedTime: convertToSeconds('5:00'),
      //   };
      // }
      // check if event is paused
      if (eventClockStatus?.eventClock?.stopped) {
        return {
          action: 'stop',
          elapsedTime: convertToSeconds(getTimeForConversion(eventClockStatus?.eventClock, sportId)),
        };
      }

      if (eventClockStatus?.matchStatus == 'Not started') {
        return stopAndResetClock();
      }
      return {
        decrement:
          eventClockStatus?.eventClock?.remainingTime !== '0:00' &&
          eventClockStatus?.eventClock?.remainingTime &&
          !clockConfig?.remainingTime,
        action: 'start',
        elapsedTime: eventClockStatus?.eventClock?.stoppageTime
          ? getTimePlusStoppageTime(
              getTimeForConversion(eventClockStatus?.eventClock, sportId),
              getStoppageTimeForConversion(eventClockStatus?.eventClock),
            )
          : convertToSeconds(getTimeForConversion(eventClockStatus?.eventClock, sportId)),
      };

    default:
      return stopAndResetClock();
  }
};

const stopAndResetClock = () => {
  return {
    action: 'stop',
    elapsedTime: convertToSeconds('0:00'),
  };
};

export const defaultEventStatusTemplate: Sport.EventClockStatus = {
  eventClock: {
    eventTime: '00:00',
    remainingTime: null,
    remainingTimeInPeriod: null,
    stoppageTime: null,
    stoppageTimeAnnounced: null,
    stopped: false,
  },
  awayScore: '0',
  homeScore: '0',
  eventStatus: '',
  matchStatus: '',
  periodScore: [],
  scoreChange: '',
  sportSpecificProperties: {
    homeGameScore: null,
    awayGameScore: null,
    awayLegScore: null,
    currentServer: '',
    delivery: null,
    homeLegScore: null,
    possession: null,
    visit: null,
  },
  lastUpdate: Date.now(),
};
