import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Flex } from 'modules/casino/shared/components/layout';
import { Modal } from 'modules/casino/shared/components/modal';
import { ModalUI } from 'modules/casino/shared/components/modalUI';
import PlayerRestrictionsInfo from 'modules/casino/shared/components/responsibleGambling/PlayerRestrictionsInfo';
import { UI } from 'modules/casino/shared/components/UI';
import { GameType } from 'modules/casino/shared/constants';
import usePlayerRestrictions from 'modules/casino/shared/hooks/usePlayerRestrictions';
import { PRODUCT_TYPE_ENUM } from 'pages/myAccount/tabs/responsibleGambling/types/responsibleGambling.types';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { CodeError } from 'shared/common/features/twoFactorVerification/components/CodeError';
import { useAppDispatch } from 'store';
import { setOpenGameData } from '../casinoLaunchSlice';
import { CasinoLaunchGameTypes, GamePlayType } from '../casinoLaunchTypes';

const SvaraLaunchModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const { t } = useTranslation();
  const { playerRestrictionsByProducts, hasRestrictions } = usePlayerRestrictions({
    products: [PRODUCT_TYPE_ENUM.TABLE_GAMES], // TO DO add bet type from the game instead the enum.
  });

  const regex = /^[1-2]\d{5}$/; //only numbers; length: exactly 6; starting with 1 or 2

  const checkIsValid = (input) => {
    return !regex.test(input);
  };

  const launchData: CasinoLaunchGameTypes.OpenGameData = {
    description: 'SVARA_GAME',
    gameId: value,
    providerAlias: 'svara',
    providerId: '61',
    refGameId: value,
    iframeLoad: false,
    gameType: GameType.SVARA,
    isTrackable: false,
    gamePlayType: GamePlayType.PAID,
    additionalLaunchParams: '&svara=private',
  };

  const handleConfirmClick = () => {
    if (checkIsValid(value)) {
      setHasError(true);
      return;
    }
    if (hasError) return;
    dispatch(setOpenGameData({ ...launchData }));
    dispatch(hideRootModal());
    window.close();
  };

  const handleCancelClick = () => {
    dispatch(hideRootModal());
    window.close();
  };

  const handleChange = (privateId: string) => {
    setValue(privateId);
    setHasError(false);
  };

  const inputClasses = clsx('w-100 modal-join-table-input', hasError && 'modal-join-table-input--error');

  return (
    <Modal visible className="modal-join-table">
      <Modal.Close closeText={'Close'} closeAction={() => handleCancelClick()} />
      <Modal.Body className="text-center">
        <ModalUI.Title className="d-flex-center mb-3" text={'Custom Svara Game'} />
        {hasRestrictions ? (
          <Flex.ColAC>
            <PlayerRestrictionsInfo componentLocation="modal" restrictions={playerRestrictionsByProducts} />
          </Flex.ColAC>
        ) : (
          <>
            <input
              className={inputClasses}
              autoComplete="off"
              maxLength={6}
              value={value}
              onChange={(e) => handleChange(e.target.value)}
            />
            {hasError && <CodeError text={t('GameLaunch.svara.modalError')} />}
            <Flex.ACJA className="w-100 mt-3">
              <UI.Button
                variant={'secondary'}
                className="w-100"
                size="lg"
                onClickHandler={handleCancelClick}
                text={'Cancel'}
              />
              <UI.Button
                className="ml-3 w-100"
                size="lg"
                onClickHandler={handleConfirmClick}
                text={'Submit'}
                isDisabled={value === '' || hasError}
              />
            </Flex.ACJA>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SvaraLaunchModal;
