import React from 'react';
import useHeaderConfig from 'shared/common/hooks/useHeaderConfig';
import { SCSwitcher } from './Switcher.styled';

// Get current CSS
const cssLink: HTMLLinkElement | null = document.querySelector('link[id="main-style"]');
// Get current CDN
const cdnLink: HTMLLinkElement | null = document.querySelector('link[id="cdn"]');
// Get current Layout JS
const layoutLink: HTMLScriptElement | null = document.querySelector('script[id="layout-js"]');
// Get Window Object
const windowConfig = window.config;

// Base CSS folder
const cssBase = 'theme';
// Base layout.js folder
const layoutBase = 'layout';

const skins = [
  {
    label: 'WL WBBG',
    url: 'https://winbet.bg',
    cdnURL: 'https://WBBGcdn.kubdev.com',
    bu: 'WBBG',
    path: 'wl-wbbg',
  },
  {
    label: 'WL IBBG',
    url: 'https://inbet.com',
    cdnURL: 'https://IBBGcdn.kubdev.com',
    bu: 'IBBG',
    path: 'wl-ibbg',
  },
  {
    label: 'Violetforce',
    url: 'https://sesame.bg',
    cdnURL: 'https://SMBGcdn.kubdev.com',
    bu: 'SMBG',
    path: 'violetforce-2022',
  },
  {
    label: 'Whitelabel',
    url: 'https://egt-today-dev.egt-digital.com',
    cdnURL: 'https://WBBGcdn.kubdev.com',
    bu: 'WBBG',
    path: 'whitelabel',
  },
];

type SkinSetterValueProps = {
  url: string;
  base: string;
  path?: string;
  file: string;
};

const composeValue = ({ url, base, path, file }: SkinSetterValueProps) =>
  `${url}/${base}/${path ? path + '/' : ''}${file}`;

const SkinSwitcher: React.FC = () => {
  const { showSkinSwitcher: showSwitcher } = useHeaderConfig();

  if (!showSwitcher) return null;
  // if (isProductionBuild) return null;

  const setSkin = (e) => {
    cssLink &&
      cssLink.setAttribute(
        'href',
        composeValue({
          url: e.target.value.split('@')[0],
          base: cssBase,
          path: e.target.value.split('@')[1],
          file: 'index.css',
        }),
      );
    layoutLink &&
      layoutLink.setAttribute(
        'src',
        composeValue({
          url: e.target.value.split('@')[0],
          base: layoutBase,
          file: 'layout.js',
        }),
      );
    cdnLink && cdnLink.setAttribute('href', e.target.value.split('@')[2]);
    windowConfig.BUSINESS_UNIT = e.target.value.split('@')[3];
  };

  return (
    <SCSwitcher onChange={setSkin}>
      {skins.map((skin) => (
        <option key={skin.label} value={`${skin.url}@${skin.path}@${skin.cdnURL}@${skin.bu}`}>
          {skin.label}
        </option>
      ))}
    </SCSwitcher>
  );
};

export default SkinSwitcher;
