import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { resetUCNCodeCheck, ucnScanThunk } from './slice/scanUCN.slice';
import { clearAllReportData } from '../../utils';

export const scanUCNCode = (ucnCode, dispatch, t, closeModal) => {
  let startPosition = 48; // The starting position of the UCN in the ucnCode string, beginning at :FOR DESKTOP: 48 /FOR CASHIER (linux machine): 56
  // The cashier machine adds "OCR" symbols on each row
  if (ucnCode.startsWith('OCR')) {
    // Change the starting position because the machine adds an extra 8 characters to the uniCode string
    startPosition = 56;
  }
  // The `ucnCode` contains the first two of the three rows from the back side of the ID card

  const endPosition = startPosition + 10; // The position immediately after the end of the 10-digit UCN, based on the starting position
  const extractedUCNCode = ucnCode.slice(startPosition, endPosition);
  const isExtractedCodeContainsValidUCN = /^\d{10}$/.test(extractedUCNCode);

  if (isExtractedCodeContainsValidUCN) {
    dispatch(ucnScanThunk.checkUCNCode({ ucnCode: extractedUCNCode }));
    clearAllReportData(dispatch, closeModal);
  } else {
    dispatch(
      showRootModal({
        modalType: 'UCN_CHECK_CASHIER_MODAL',
        modalId: 'UCN_CHECK_CASHIER_MODAL',
        modalPriority: ModalPriorityEnum.MEDIUM,
        modalProps: {
          responseMessage: 'Cashier.UcnScan.failedScan',
          success: false,
          resetStatus: resetUCNCodeCheck,
          t: t,
        },
      }),
    );
  }
};
