import { useCallback } from 'react';
import { isIOS } from 'react-device-detect';
import { useAuthentication } from 'hooks/common/useAuthentication';
import { useCasinoLayoutConfig } from 'hooks/common/useCasinoLayoutConfig';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigInnerLayoutTypes } from 'modules/casino/shared/constants';
import { LayoutConfigTypes, VerticalsConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { CustomClickActionListener } from 'shared/common/features/sportsBookListeners/types/sportsBookListeners.type';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { selectIntegrations } from '../../slice/integrations.slice';

const useSmartico = () => {
  const smarticoBalance = useAppSelector(selectIntegrations.smarticoUserCoins);
  const { isAuthenticated, showLoginForm } = useAuthentication();

  const config = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.VERTICALS,
    deviceLayout: 'general',
  })?.[VerticalsConfigTypes.SMARTICO];

  const casinoLayoutConfig = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.CASINO_LAYOUT_CONFIG });
  const isRestrictedAppVersion =
    window.isRNWebView && isIOS && window.appVersion === casinoLayoutConfig?.restrictedAppVersion;

  const isActive = !isRestrictedAppVersion && config?.isVisible && !isEmpty(config?.config);

  const openMainWidget = useCallback(
    (type = '') => {
      if (isAuthenticated && isActive && window._smartico) {
        const tabType = type ? `_${type}` : '';
        window._smartico.dp(`dp:gf${tabType}`);
      }
    },
    [isAuthenticated, isActive],
  );

  const openMiniGame = useCallback(
    (gameId: number, isAuthenticatedSport: boolean) => {
      if (isAuthenticatedSport) {
        if (gameId && window._smartico) {
          window._smartico.miniGame(gameId);
        }
      } else {
        showLoginForm();
      }
    },
    [showLoginForm],
  );

  const smarticoCallback = useCallback<CustomClickActionListener>(
    ({ id, type, isAuthenticated: isAuthenticatedSport }) => {
      if (type === 'miniGame' && id) {
        openMiniGame(id, isAuthenticatedSport);
      }
    },
    [openMiniGame],
  );

  return {
    smarticoCallback,
    openMainWidget,
    openMiniGame,
    smarticoBalance,
    config,
    isActive,
    isAuthenticated,
  };
};

export default useSmartico;
