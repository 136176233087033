import { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import { useGenerateAndPreloadImage } from 'hooks/common/useGenerateAndPreloadImage';
import { Breakpoints } from 'theme/Theme';
import { isEmpty } from 'utils/common/helpersCommon';
import config from 'utils/config';

type Props = {
  play?: boolean;
  handleVideoIsLoaded?: () => void;
  triggerVideoEnded?: () => void;
  video:
    | string
    | {
        mobile: string;
        desktop: string;
      };
  cover:
    | string
    | {
        mobile: string;
        desktop: string;
      };

  loop?: boolean;
  muted?: boolean;
};
export const VideoPlayer: React.FC<Props> = ({
  play = true,
  video,
  handleVideoIsLoaded,
  triggerVideoEnded,
  cover,
  loop = true,
  muted = true,
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isMobile}px)` });
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });
  const videoWrapperRef = useRef<HTMLDivElement>(null);
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [videoIsLoaded, setVideoIsLoaded] = useState(false);
  const isLandscapeQuery = useMediaQuery({ query: '(orientation: landscape)' });
  const disableCover = !isEmpty(cover);
  const coverUrl = typeof cover === 'string' ? cover : isMobile && !isLandscapeQuery ? cover.mobile : cover.desktop;

  const { url } = useGenerateAndPreloadImage({
    image: `/${disableCover ? coverUrl : ''}`,
    ref: videoWrapperRef,
    fit: 'cover',
    update: isMobile && !isLandscapeQuery,
    preload: false,
    isDesktop: isDesktop,
  });

  useEffect(() => {
    const videoPath = typeof video === 'string' ? video : isMobile && !isLandscapeQuery ? video.mobile : video.desktop;
    setVideoUrl(`${config.CDN_URL}/${videoPath}`);
  }, [isMobile, isLandscapeQuery, video]);

  const triggerOnVideoReady = () => {
    setTimeout(() => {
      setVideoIsLoaded(true);
      handleVideoIsLoaded && handleVideoIsLoaded();
    });
  };

  return (
    <>
      <div ref={videoWrapperRef} className="grid-start-col-1 grid-start-row-1 d-grid">
        <ReactPlayer
          config={{
            file: {
              attributes: {
                crossOrigin: 'true',
              },
            },
          }}
          url={videoUrl}
          pip={false}
          height="100%"
          width="100%"
          playsinline
          loop={loop}
          muted={muted}
          playing={play || videoIsLoaded}
          progressInterval={1000}
          onReady={triggerOnVideoReady}
          onEnded={triggerVideoEnded}
          className="grid-start-col-1 grid-start-row-1"
        />
        <AnimatePresence>
          {!videoIsLoaded && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="grid-start-col-1 grid-start-row-1"
              style={{
                background: `url(${url}) top /  cover  no-repeat`,
              }}
            />
          )}
        </AnimatePresence>
      </div>
    </>
  );
};
