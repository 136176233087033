import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { DataLoader } from 'components/shared/loaders';
import { useAppSelector } from 'store';
import { cssGlobalNS } from 'utils/common/types/css.types';
import {
  RequestBetBtn,
  RequestBetHeader,
  RequestBetNotification,
  RequestBetSelectSport,
  RequestBetWrite,
} from './components';
import { RequestBetState, selectRequestBet } from './slice/requestBet.slice';
import { General } from '../general/types/generalSlice.types';

const initialRequestBet = { sport: '', description: '', type: 'default', icon: 0 };

export const RequestBet: React.FC<{ requestBetConfig: General.RequestBet; closeModal: () => void }> = ({
  requestBetConfig,
  closeModal,
}) => {
  const error = useAppSelector(selectRequestBet.error);
  const status = useAppSelector(selectRequestBet.status);
  const isSubmited = useAppSelector(selectRequestBet.isSubmited);
  const [requestBetState, updateRequestBet] = useState<RequestBetState>(initialRequestBet);
  const [minLengthError, setMinLenghtError] = useState(false);

  useEffect(() => {
    isSubmited && updateRequestBet(initialRequestBet);
  }, [isSubmited]);

  const handleUpdate = useCallback(
    (next: Partial<RequestBetState>) => updateRequestBet((prev: RequestBetState) => ({ ...prev, ...next })),
    [],
  );

  const { t } = useTranslation();

  return (
    <>
      <RequestBetHeader handleClose={closeModal} />
      <Flex.ColJB className={`${cssGlobalNS}-rb__body custom-scrollbars`}>
        <div>
          <div className={`${cssGlobalNS}-rb__box ${cssGlobalNS}-rb__text-grid ${cssGlobalNS}-rb__desc`}>
            {requestBetConfig?.infoText?.map((infoText: string, i: number) => (
              <div key={`${infoText}-${i}`}>{t(infoText)}</div>
            ))}
          </div>
          <div className={`${cssGlobalNS}-rb__box`}>
            {status !== 'pending' ? (
              <>
                <RequestBetSelectSport
                  onChange={handleUpdate}
                  sport={requestBetState.sport}
                  icon={requestBetState.icon}
                  type={requestBetState.type}
                  dropdownImens={requestBetConfig.menuItems}
                />
                <RequestBetWrite
                  minLengthError={minLengthError}
                  maxLength={requestBetConfig?.validationLength?.maxValue}
                  maxLengthText={requestBetConfig?.validationLength?.text}
                  minLenght={requestBetConfig?.validationLength?.minValue}
                  description={requestBetState.description}
                  onChange={handleUpdate}
                  setMinLenghtError={setMinLenghtError}
                />
              </>
            ) : (
              <DataLoader />
            )}
          </div>
        </div>
        <div>
          <div className={`${cssGlobalNS}-rb__box ${cssGlobalNS}-rb__footer`}>
            <RequestBetNotification icon="info-solid" text={t(error)} />
            <RequestBetBtn
              sport={requestBetState.sport}
              description={requestBetState.description}
              type={requestBetState.type}
              maxLength={requestBetConfig?.validationLength?.maxValue}
              minLenght={requestBetConfig?.validationLength?.minValue}
              minLengthError={minLengthError}
              setMinLenghtError={setMinLenghtError}
            />
          </div>
        </div>
      </Flex.ColJB>
    </>
  );
};
