import React from 'react';
import clsx from 'clsx';
import { Icon } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { cssBurgerNS } from './burgerUI.types';

/** Close */
interface CloseProps {
  className?: string;
  onClick: React.MouseEventHandler;
}

const Close: React.FC<CloseProps> = ({ className, onClick }) => {
  const wrapperClass = clsx(cssBurgerNS.Close, className);

  return (
    <Flex.Center className={wrapperClass} onClick={onClick}>
      <Icon defaultClassName={false} className="flex-1" icon="times" />
    </Flex.Center>
  );
};

export default Close;
