import { useMatch } from 'react-router-dom';

const useIsSearchOpen = () => {
  const isSportSearch = useMatch('sports/search/*');
  const isCasinoSearch = useMatch('casino/search/*');
  const isQuickSearch = useMatch('sports/quick-search/*');
  return isSportSearch || isCasinoSearch || isQuickSearch;
};

export default useIsSearchOpen;
