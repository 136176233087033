import { FooterModal } from './FooterModal';
import { ImageWrapper } from './ImageWrapper';
import { ProgressBar } from './ProgressBar';
import { Wrapper } from './Wrapper';

export const ChallengesCellUI = {
  Wrapper: Wrapper,
  ImageWrapper: ImageWrapper,
  ProgressBar: ProgressBar,
  FooterModal: FooterModal,
};
