import { useEffect, useState } from 'react';
import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import { gamesSelectors } from 'modules/casino/modules/container/cellsContainer/cellls/slice/casinoGamesSlice';
import { generateSeats } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTablePlayers/gameCellTablePlayersUtils';
import { useAppSelector } from 'store';
import { RootState } from 'store/rootReducer';
import { useDataWrapper } from '../../hooks/useDataWrapper';

type UseGameCellTablePlayersProps = {
  gameUniqueId: string;
  categoryId: string;
};

interface UseDataImageReturnTypes {
  showTablePlayers: boolean;
  players: boolean[];
  betBehind: boolean;
  isTableFull: boolean;
}

export const useGameCellTablePlayers = ({
  gameUniqueId,
  categoryId,
}: UseGameCellTablePlayersProps): UseDataImageReturnTypes => {
  const game = useGameProps({ gameUniqueId, categoryId });
  const { showTablePlayers, gameData } = useDataWrapper({
    game,
  });
  const [players, setPlayers] = useState<boolean[]>([]);
  const configuration = useAppSelector((state: RootState) =>
    gamesSelectors.liveGameDataById(state, gameData.refGameId),
  );

  const seatsTaken = configuration?.seatsTaken || gameData.seatsTaken;
  const betBehind = configuration?.betBehind || gameData.betBehind;
  const seats = configuration?.seats || gameData.seats;
  const isTableFull = seatsTaken.length === seats;

  useEffect(() => {
    setPlayers(
      generateSeats({
        seatsTaken,
        seats,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration]);

  return { players, betBehind, isTableFull, showTablePlayers };
};
