import React from 'react';
// import clsx from 'clsx';

interface SSBTTicketBodyProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const SSBTTicketBody: React.FC<SSBTTicketBodyProps> = ({ children, style }) => {
  const classProp = { class: 'no-scrollbars overflow-y-auto overflow-x-hidden' };
  return (
    <egtd-ssbt-ticket-body style={style} {...classProp}>
      {children}
    </egtd-ssbt-ticket-body>
  );
};

export default SSBTTicketBody;
