import React from 'react';
import { isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import GridComponentsRender from 'modules/casino/modules/container/cellsContainer/cellls/components/GridComponentsRender';
import LiveCasinoUpdates from 'modules/casino/modules/container/cellsContainer/cellls/components/LiveCasinoUpdates';
import CategoryImpressions from 'modules/casino/modules/container/cellsContainer/containers/shared/features/categoryImpressions/CategoryImpressions';
import GridFooterHandler from 'modules/casino/modules/container/cellsContainer/grid/components/GridFooterHandler';
import GridSkeleton from 'modules/casino/modules/container/cellsContainer/grid/components/GridSkeleton';
import GridLayout from 'modules/casino/modules/container/cellsContainer/grid/gridLayout';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
  INNER_CATEGORIES,
} from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { useGridSettingsLoadingState } from 'modules/casino/shared/components/gridLayoutSettings/hooks/useGridSettingsLoadingState';
import { CategoryQueryType, LayoutConfigInnerLayoutTypes } from 'modules/casino/shared/constants';
import CategoryInView from 'modules/casino/shared/features/casinoUi/components/CategoryInView';
import { OutOfGameModal } from 'modules/casino/shared/features/rootCasinoModal';
import { useCasinoLayoutConfig } from 'modules/casino/shared/hooks/useCasinoLayoutConfig';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppSelector } from 'store';
import { useCasinoGamesFetch } from './hooks/useCasinoGamesFetch';
import { useCasinoGamesFetchStatus } from './hooks/useCasinoGamesFetchStatus';
import { useGameCollectionId } from './hooks/useGameCollectionId';
import { useGamesRenderAdditionalFetches } from './hooks/useGamesRenderAdditionalFetches';
import { useInitialCollectionUi } from './hooks/useInitialCollectionUi ';
import { CasinoGamesTypes } from './types/casinoGamesTypes';
import { getCustomFetchGameSize, overrideLayoutConfig } from './utils/casinoGamesUtils';
import CasinoLoader from '../../../../shared/components/casinoLoader/CasinoLoader';
import { SearchMessage } from '../../../../shared/components/searchMessage';
import { MessageVariant } from '../../../../shared/components/searchMessage/SearchMessageConstants';
import BottomProvidersBarVisibilityHandler from '../../shared/features/casinoGameProviders/components/BottomProvidersBarVisibilityHandler';
import { categoryBreadcrumbSelectors } from '../../shared/features/categoryBreadcrumb/slice/CategoryBreadcrumbSlice';
import { GridLoadingBehaviorType } from '../grid/types/gridTypes';

interface Props {
  categoryId: string;
  subType: CategorySubTypes;
  categoryLayoutType: CategoryLayoutTypes;
  useLoadAnimation?: boolean;
  params?: Partial<CasinoGamesTypes.CasinoGamesRenderParams>;
}

const CasinoGamesRender: React.FC<Props> = ({
  categoryId,
  subType,
  categoryLayoutType,
  params = {},
  useLoadAnimation = true,
}) => {
  useGamesRenderAdditionalFetches({
    categoryLayoutType,
    subType,
    categoryId,
  });
  const { layoutConfig, queryType, query, gameSection, isTrackable } = useGetCategoryParams(
    categoryId,
    categoryLayoutType,
  );
  const { hasSuccess, hasNoGames, isLoading } = useCasinoGamesFetchStatus(categoryId, categoryLayoutType);
  const { hasNumbers } = useGetCategoryParams(categoryId, categoryLayoutType);
  const { hasGridSettingsSuccess, hasGridSettingsError } = useGridSettingsLoadingState();
  const casinoLayoutConfig = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.CASINO_LAYOUT_CONFIG });
  const searchTagId = useAppSelector((state) => categoryBreadcrumbSelectors.activeFilterId(state, categoryId));
  const playerId = useAppSelector(selectMyProfile.playerId);
  const { t } = useTranslation();

  const { gameCollectionId } = useGameCollectionId({ categoryId, categoryLayoutType });
  const layoutConfigNew = overrideLayoutConfig(layoutConfig, categoryLayoutType, params?.gridLoadingBehavior);
  useInitialCollectionUi(gameCollectionId);

  const categoryParams = {
    categoryId,
    subType,
    layoutRows: layoutConfigNew.layoutRows,
    layoutGrid: layoutConfigNew.layoutGrid,
    queryType,
    query,
    categoryLayoutType,
    additionalParams: params.additionalParams,
    searchParams: params.searchParams || '',
    filteredProviders: params.filteredProviders,
    gridType: layoutConfigNew.layout,
    playerId,
    gameSection,
  };

  const { fetchMoreGames } = useCasinoGamesFetch({
    categoryParams,
    gameCollectionId,
    searchTagId,
    fetchGameSize: getCustomFetchGameSize(layoutConfigNew.layout, hasNumbers),
    categoryLayoutType,
  });
  const defaultParams = {
    subType,
    className: '',
    categoryId,
    gridLoadingBehavior: GridLoadingBehaviorType.LoadButton,
    fetchMoreGames: fetchMoreGames,
    collectionId: gameCollectionId,
    categoryLayout: categoryLayoutType,
  };
  const props = { ...defaultParams, ...params, layoutConfig: layoutConfigNew, query: queryType };

  if (window.isRNWebView && isIOS && window.appVersion === casinoLayoutConfig?.restrictedAppVersion) {
    return <></>;
  }

  const hasShowGridSkeleton = () => {
    const categoryLayoutTypesWithSkeleton = [
      CategoryLayoutTypes.LOBBY,
      CategoryLayoutTypes.PROVIDER_CATEGORY,
      CategoryLayoutTypes.PROVIDER_CATEGORY,
      CategoryLayoutTypes.FAVOURITES_CATEGORY,
      CategoryLayoutTypes.FAVOURITES_MODAL_CATEGORY,
    ];
    return categoryLayoutTypesWithSkeleton.includes(categoryLayoutType);
  };

  const hasShowLoader = () => {
    const categoryWithLoader = [
      CategoryQueryType.SEARCH_BY_TITLE,
      CategorySubTypes.IN_GAME_MOST_PLAYED,
      CategorySubTypes.IN_GAME_RECOMMENDED,
    ];
    return categoryWithLoader.includes(queryType) || categoryWithLoader.includes(subType);
  };

  const hasShowNoGames = () => {
    const categoryWithNoeGameMessage = [
      CategoryQueryType.SEARCH_BY_TITLE,
      CategorySubTypes.IN_GAME_MOST_PLAYED,
      CategorySubTypes.IN_GAME_RECOMMENDED,
    ];
    return categoryWithNoeGameMessage.includes(queryType) || categoryWithNoeGameMessage.includes(subType);
  };

  if (isLoading && hasShowLoader()) {
    return <CasinoLoader />;
  }

  if (hasNoGames && hasShowNoGames()) {
    const renderMessage = ({ variant, message }) => <SearchMessage variant={variant} message={message} />;
    return renderMessage({ variant: MessageVariant.error, message: t('CasinoSearch.Message.noResults') });
  }

  return hasSuccess && (hasGridSettingsSuccess || hasGridSettingsError) ? (
    <>
      <OutOfGameModal />
      <CategoryImpressions categoryId={categoryId} categoryLayoutType={categoryLayoutType} isTrackable={isTrackable} />
      <CategoryInView collectionId={props.collectionId} queryType={queryType}>
        {/* <RecommendationCategoryImpressions
        categoryId={categoryId}
        categoryLayoutType={categoryLayoutType}
        queryType={queryType}
      /> */}

        <LiveCasinoUpdates categoryLayoutType={categoryLayoutType} categoryId={categoryId} />
        <GridLayout {...props}>
          {(itemId, index, isLastItem, gridPage, gamesRenderedCount, skipLazyLoad) => (
            <GridComponentsRender
              gameUniqueId={itemId}
              categoryId={categoryId}
              collectionId={props.collectionId}
              index={index + 1}
              gamesRenderedCount={gamesRenderedCount}
              isLastItem={isLastItem}
              gridPage={gridPage}
              skipLazyLoad={skipLazyLoad}
              categoryLayout={categoryLayoutType}
              queryType={queryType}
              useLoadAnimation={useLoadAnimation}
            />
          )}
        </GridLayout>
      </CategoryInView>
    </>
  ) : (
    <>
      {hasShowGridSkeleton() ? (
        <GridSkeleton layoutConfig={props.layoutConfig} lazySkeleton={false} hasBackground={false} />
      ) : (
        <></>
      )}
      {(hasNoGames && INNER_CATEGORIES.includes(props?.categoryLayout)) || (props.isCategoryLast && hasNoGames) ? (
        <>
          <GridFooterHandler />
          <BottomProvidersBarVisibilityHandler />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
CasinoGamesRender.displayName = 'CasinoGamesRender';
export default CasinoGamesRender;
