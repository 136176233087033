import { useCallback, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useAppDispatch } from 'store';
import { showRootCasinoModal } from '../../rootCasinoModal/rootCasinoModal.slice';

export const useJackpotLastWinner = (
  jackpotId: number | string,
  level: string,
  providerId: string,
  currency: string,
) => {
  const dispatch = useAppDispatch();
  const [startTouch, setStartTouch] = useState(false);

  const showJackpotDetails = useCallback(() => {
    if (isMobile) {
      dispatch(
        showRootCasinoModal({
          modalType: 'JACKPOT_WIN_DETAILS',
          modalProps: { jackpotId, level, providerId, currency },
        }),
      );
    } else if (!startTouch) {
      setStartTouch(true);
    }
  }, [jackpotId]);

  const hideJackpotDetails = useCallback(() => {
    if (!isMobile) {
      setStartTouch(false);
    }
  }, [jackpotId]);

  return { showJackpotDetails, hideJackpotDetails, startTouch };
};
