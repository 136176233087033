import { ThemedIcon } from 'theme/Theme';
import config from 'utils/config';

type ImageComponentProps = {
  imgRef: string;
  route?: string;
};
const ImageComponent = ({ imgRef, route }: ImageComponentProps): JSX.Element => {
  const image = imgRef.split('.svg');
  const imgItem = <img src={`${config.CDN_URL}/${image[0]}${ThemedIcon()}.svg`} className="footer-legal__icon" />;
  return route ? (
    <a href={route} target="_blank" className="d-flex-center" rel="noreferrer">
      {imgItem}
    </a>
  ) : (
    imgItem
  );
};

export default ImageComponent;
