import { memo } from 'react';
import { Subvertical, Vertical } from 'shared/common/features/navigationMenu/types/navigationMenu.types';
import RetailHeaderMenuLink from './RetailHeaderMenuLink';
import { useMainNavbar } from '../../../../../hooks/useMainNavbar';

const RetailHeaderMenu = () => {
  const { subVerticals } = useMainNavbar();

  return (
    <div className="d-flex rnav">
      {subVerticals.map((item: Vertical | Subvertical, index: number) => (
        <RetailHeaderMenuLink key={index} item={item} />
      ))}
    </div>
  );
};

export default memo(RetailHeaderMenu);
