import React, { HTMLAttributes, memo, useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
// import { isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { Flex } from 'components/shared/layout';
import userStorage from 'pages/auth/login/UserStorage';
import { Breakpoints } from 'theme/Theme';
import CTAButtonBgImage from './CTAButtonBgImage';
import { NotificationTypes } from '../../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import { constructAnimationImage } from '../../../myProfile/sections/tabs/notificationsTab/notificationUtils';

interface Props extends HTMLAttributes<HTMLImageElement> {
  animationParams: NotificationTypes.GamificationModalParams;
  settings?: NotificationTypes.NotificationOverlayStyle;
  buttonBackground?: string;
  onHide?: () => void;
  styles: NotificationTypes.PopupNotificationStyle;
}

const OverlayModal: React.FC<Props> = ({ animationParams, settings, onHide, styles }) => {
  const [showOverlay, setShowOverlay] = useState(true);
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });
  const overlayRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const landscape = useMediaQuery({ query: '(orientation: landscape)' });

  useEffect(() => {
    if (overlayRef.current) {
      setDimensions({ width: overlayRef.current.clientWidth * 2, height: overlayRef.current.clientHeight * 2 });
    }
  }, [overlayRef, landscape]);

  if (!settings?.isActive) return null;

  const { folder } = animationParams;
  // const { width, height } = isMobile && !isDesktop ? { width: 342 * 2, height: 700 * 2 } : { width: 1366, height: 768 };
  const bg = isDesktop
    ? `pop-up-desktop-${userStorage.getUserLang() ?? 'bg'}.png`
    : `pop-up-mobile-${userStorage.getUserLang() ?? 'bg'}.png`;
  const logoImage =
    dimensions.width > 0
      ? constructAnimationImage({
          folder,
          image: bg,
          width: dimensions.width,
          height: dimensions.height,
          fit: 'contain',
        })
      : '';
  const handleClick = () => {
    setShowOverlay(false);
    onHide && onHide();
  };
  const classElement = clsx('wh-100 gmf-overlay');
  return (
    <>
      <AnimatePresence initial={true}>
        {showOverlay && settings.isActive && (
          <OverlayStyle
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            transition={{ duration: 0.6 }}
            variants={{
              open: { opacity: 1 },
              collapsed: { opacity: 0 },
            }}
            className={classElement}
            background={logoImage}
            width={dimensions.width}
            ref={overlayRef}
            onClick={handleClick}
          >
            <Flex.ColAC className="mt-auto">
              <CTAButtonBgImage
                disabled={false}
                onClick={handleClick}
                buttonText={settings.btnText}
                folder={animationParams.folder}
                bgColor={styles?.backgroundButton}
                textColor={styles?.buttonTextColor}
                borderColor={styles?.buttonBorderColor}
              />
            </Flex.ColAC>
          </OverlayStyle>
        )}
      </AnimatePresence>
    </>
  );
};

const OverlayStyle = styled(motion.div)<{ background?: string; width: number }>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-image: ${({ background }) => `url(${background})`};
  max-width: ${({ width }) => width > 0 && `${width}px`};
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 20px;
  min-height: 820px;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    min-height: auto;
    padding-bottom: 88px;
  }
  @media (min-width: 768px) and (max-width: ${Breakpoints.isDesktop}px) {
    min-height: 980px;
  }
`;

export default memo(OverlayModal);
