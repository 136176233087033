import React from 'react';
import { EventClock } from 'components/shared/sportContainers/event/eventClock';
import { getShowEventClock } from 'components/shared/sportContainers/event/eventClock/utils/eventClock.utils';
import EventPart from 'components/shared/sportContainers/event/eventScore/EventPart';
import { Sport } from 'utils/common/types/sportTypes/sport.types';
import { SportDataTypes } from 'utils/common/types/sportTypes/sportData.types';

/** BetSeletionUI Clock */
interface ClockProps {
  isVisible: boolean;
  eventClockStatus: Sport.EventClockStatus;
  eventStatusConfig: SportDataTypes.EventStatusConfig | null;
  sportId: number;
  tournamentId: number;
  producerId?: number;
}

const Clock: React.FC<ClockProps> = ({
  isVisible,
  eventClockStatus,
  eventStatusConfig,
  sportId,
  tournamentId,
  producerId,
}) => {
  if (!isVisible) return null;

  return (
    <div className="d-flex">
      {getShowEventClock(sportId, eventStatusConfig) && (
        <EventClock
          sportId={sportId}
          eventClockStatus={eventClockStatus}
          eventStatusConfig={eventStatusConfig}
          tournamentId={tournamentId}
          className="mr-1"
        />
      )}
      <EventPart
        periodScore={eventClockStatus.periodScore}
        sportId={sportId}
        eventClockStatus={eventClockStatus}
        eventStatusConfig={eventStatusConfig}
        producerId={producerId}
      />
    </div>
  );
};

export default Clock;
