import { useRef } from 'react';
import { useVideo } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellMedia/hooks/useVideo';

interface Props {
  videoSource: string;
  inView: boolean;
  onLoad: () => void;
}

const Video: React.FC<Props> = ({ videoSource, inView, onLoad }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  useVideo({ videoRef, videoSource, inView });

  const onLoadVideo = () => {
    if (videoRef.current) {
      onLoad();
      videoRef.current.style.opacity = '1';
    }
  };
  return (
    <video
      autoPlay
      loop
      muted
      playsInline
      disablePictureInPicture
      preload="none"
      ref={videoRef}
      onLoadedData={onLoadVideo}
      disableRemotePlayback
      style={{
        opacity: 0,
        width: '100%',
        height: '100%',
      }}
    />
  );
};

export default Video;
