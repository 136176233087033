import React from 'react';
import { IconTooltip } from 'components/shared/tooltip';

/** EventUI Neutral Ground Toolip */
interface NGProps {
  isVisible?: boolean;
  text: string;
}

export const NeutralGround: React.FC<NGProps> = ({ isVisible, text }) => {
  if (!isVisible) return <></>;
  return <IconTooltip text="N" tooltipAlignment="left" tooltip={text} />;
};
