import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { fetchBusinessUnitSettings } from 'shared/common/features/general/slice/general.slice';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { loginUser, logoutUser } from 'shared/common/sharedSlices/commonActions';
import { listenerMiddleware } from 'store/middlewares/listenerMiddleware';
import { RootState } from 'store/rootReducer';
import { createAbortThunk, createLoadThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { PlayerWinInfo } from '../types/playerWinInfo.types';

const shouldShowModal = (getState, isAuto = false) => {
  const {
    myAccount: { playerWinInfo },
    myProfile: { user },
    common: { general },
  } = getState();

  return user?.id && general?.buSettings?.playerBetStatistics && !(isAuto && playerWinInfo.hasAutoLoaded);
};

export const modalType = 'PLAYER_WIN_INFO';
export const modalShowTime = 5000;
export const playerWinInfoThunks: PlayerWinInfo.Thunks = {
  fetchPlayerWinInfo: createAbortThunk('fetchPlayerWinInfo', async (_, thunkAPI) => {
    try {
      const url = `${config.API_URL}/api/ews-crm/player/player-bet-statistics`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(getError.responseData(err));
    }
  }),
  showPlayerWinInfo: createLoadThunk(
    null,
    () => {},
    async (modalProps: { hasAutoClose: boolean }, dispatch, getState) => {
      if (shouldShowModal(getState)) {
        dispatch(fetchPlayerWinInfo()).then(() => {
          dispatch(
            showRootModal({
              modalId: modalType,
              modalType: modalType,
              modalProps: {
                hasAutoClose: modalProps?.hasAutoClose,
              },
              modalPriority: ModalPriorityEnum.MEDIUM,
            }),
          );
          if (modalProps?.hasAutoClose) {
            dispatch(setAutoLoaded());
          }
        });
      }
    },
  ),
};

export const { fetchPlayerWinInfo, showPlayerWinInfo } = playerWinInfoThunks;

const initialState: PlayerWinInfo.SliceTypes = {
  links: {
    header: '',
    footer: '',
    button: '',
  },
  values: {
    monthWagers: 0,
    monthWins: 0,
    totalWagers: 0,
    totalWins: 0,
  },
  hasAutoLoaded: false,
  dataError: null,
  isLoading: false,
  hasFetched: false,
  hasUpdated: false,
};
const playerWinInfoSlice = createSlice({
  name: 'playerWinInfo',
  initialState,
  reducers: {
    setAutoLoaded(state) {
      state.hasAutoLoaded = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlayerWinInfo.pending, (state) => {
        state.isLoading = true;
        state.links = initialState.links;
        state.values = initialState.values;
      })
      .addCase(
        fetchPlayerWinInfo.rejected,
        (state, action: PayloadAction<PlayerWinInfo.RejectValue | null | undefined>) => {
          if (action.payload) {
            state.dataError = action.payload;
            state.isLoading = false;
          }
        },
      )
      .addCase(fetchPlayerWinInfo.fulfilled, (state, action: PayloadAction<PlayerWinInfo.ApiResponse.Main>) => {
        state.links.header = action?.payload?.headerLink;
        state.links.footer = action?.payload?.footerLink;
        state.links.button = action?.payload?.buttonLink;

        state.values.monthWagers = action?.payload?.monthlyBetAmountBase;
        state.values.monthWins = action?.payload?.monthlyWinAmountBase;
        state.values.totalWagers = action?.payload?.totalBetAmountBase;
        state.values.totalWins = action?.payload?.totalWinAmountBase;

        state.dataError = null;
        state.hasFetched = true;
        state.isLoading = false;
      })
      .addCase(logoutUser, () => initialState);
  },
});

listenerMiddleware.startListening({
  matcher: isAnyOf(loginUser, fetchBusinessUnitSettings.fulfilled),
  effect: async (_, { dispatch, getState }) => {
    shouldShowModal(getState, true) && dispatch(showPlayerWinInfo({ hasAutoClose: true }));
  },
});

const { setAutoLoaded } = playerWinInfoSlice.actions;
export default playerWinInfoSlice.reducer;

export const selectPlayerWinInfo = {
  links: (state: RootState) => state.myAccount.playerWinInfo.links,
  values: (state: RootState) => state.myAccount.playerWinInfo.values,
};
