import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { SSBT } from 'utils/common/translationUtils/translationStrings';
import { TerminalModalUI } from '../../../components/ssbtModalUI';

/** CloseModalButton */
interface DepositAgainButtonProps {
  className?: string;
  onClick: React.MouseEventHandler;
  variant: 'primary' | 'secondary' | 'disabled';
}

const DepositAgainButton: React.FC<DepositAgainButtonProps> = ({ className, onClick, variant }) => {
  const { t } = useTranslation();

  const btnClass = clsx(className);

  return (
    <TerminalModalUI.Button
      variant={variant}
      className={btnClass}
      onClick={onClick}
      text={t(SSBT.TerminalModal.addMoreMoneyText)}
    />
  );
};

export default DepositAgainButton;
