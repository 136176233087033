import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Modal } from 'components/shared/Modal';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch } from 'store';
import { MyAccountTranslation, MyProfile } from 'utils/common/translationUtils/translationStrings';
import { cssGlobalNS } from 'utils/common/types/css.types';
import PlayerRestrictionsInfo from './PlayerRestrictionsInfo';
import { usePlayerRestrictionsConfig } from '../hooks/usePlayerRestrictionsConfig';
import { PlayerRestrictions } from '../types/responsibleGambling.types';

const PlayerRestrictionsModal: React.FC<{
  playerRestrictionsByProducts: PlayerRestrictions.Restriction[];
  onClose?: () => void;
}> = ({ playerRestrictionsByProducts, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const playerRestrictionsConfig = usePlayerRestrictionsConfig();

  const handleClose = () => {
    dispatch(hideRootModal());
    onClose && onClose();
  };

  return (
    <Modal visible className="modal-terms">
      <Modal.Close closeText={t(MyProfile.OffersTab.closeBtn)} closeAction={handleClose} />
      <Modal.Body className="d-flex-col-ac p-3 text-center">
        <PlayerRestrictionsInfo componentLocation="modal" restrictions={playerRestrictionsByProducts} />
        <Link
          to={playerRestrictionsConfig.buttonRoute}
          className={`px-5 ${cssGlobalNS}-p-btn ${cssGlobalNS}-p-btn--lg ${cssGlobalNS}-p-btn--primary`}
          onClick={handleClose}
        >
          {t(playerRestrictionsConfig.buttonText || MyAccountTranslation.Exclusions.settings)}
        </Link>
      </Modal.Body>
    </Modal>
  );
};

export default PlayerRestrictionsModal;
