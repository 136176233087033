import React, { Fragment, memo, useRef } from 'react';
import clsx from 'clsx';
import { Location } from 'history';
import { Link, useLocation, useMatch } from 'react-router-dom';
import { Text } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { SPORT_TYPE_LOBBY_SCROLL_EVENT } from 'components/shared/ScrollContainer';
import { UI } from 'components/shared/UI';
import { useScrollInToView } from 'hooks/useScrollInToView';
import { selecGamificationPage } from 'pages/gamificationPage/slice/gamificationPage.slice';
import {
  navigateToCasinoIntegration,
  navigateToIntegration,
} from 'shared/common/features/integrations/utils/integrations.utils';
import { UiVerticalType } from 'shared/common/features/navigationMenu/types/navigationMenu.types';
import { useAppSelector } from 'store';
import { isActive } from 'utils/common/utilsNav';
import { selectDrawer } from '../../../drawer/slice/drawer.slice';
import { useRouteToInPlay } from '../../hooks/useRouteToInPlay';

type Props = {
  name: string;
  route: string;
  id: number;
  isExternalRoute: boolean;
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
  isActiveLink?: boolean;
  uiVerticalType: UiVerticalType;
  isNew?: boolean;
  isNewBg?: string;
  isNewColor?: string;
  onClick?: () => void;
};

const CustomNavLink: React.FC<Props> = ({
  name,
  route: routeProp,
  id,
  isExternalRoute,
  wrapperRef,
  isActiveLink = true,
  uiVerticalType,
  isNew,
  isNewBg,
  isNewColor,
  onClick,
}) => {
  const promoCount = useAppSelector(selecGamificationPage.unreadNotificationsByVertical(routeProp));
  const routeToInPlay = useRouteToInPlay(routeProp);
  const route = routeToInPlay || routeProp;
  const isEmptyRoute = route === '/' || route === '';
  const location = useLocation() as Location;
  const sportsMatch = useMatch(!isEmptyRoute ? `${route}/:params/*` : route);
  const sportsFavouritesMatch = useMatch(`/sports/favourites`) && routeProp === '/sports';
  const casinoMatch = useMatch(!isEmptyRoute ? `${route}/*` : route);
  const linkRef = useRef<HTMLAnchorElement | null>(null);
  const isDrawerVisible = useAppSelector(selectDrawer.isVisible);

  const linkStyles = clsx(
    'd-flex top-navbar__link',
    (isActive(route, sportsMatch, location) || casinoMatch || routeToInPlay || sportsFavouritesMatch) &&
      'top-navbar__link--active',
    !isActiveLink && 'top-navbar__link--disable',
  );

  useScrollInToView(wrapperRef, linkRef, isActive(route, sportsMatch, location) && !isDrawerVisible, 'horizontal');

  // scrollNavSlider callback triggers NavSlider to scroll to its beginning
  // works for SportLobby ('/sports' route) and ESportsLobby ('/esports' route) - they have the same NavSlider component
  const scrollNavSlider = () => {
    if (uiVerticalType === 'sport') {
      navigateToIntegration({ fullPath: route });
    }

    if (uiVerticalType === 'casino') {
      navigateToCasinoIntegration({ fullPath: route });
    }
    if (route === '/sports' || route === '/esports') {
      document.dispatchEvent(new Event(SPORT_TYPE_LOBBY_SCROLL_EVENT));
    }
    onClick && onClick();
  };

  if (isExternalRoute) {
    return (
      <a href={route} target="_blank" rel="noreferrer" className={linkStyles} data-qid={route.substring(1)}>
        {name}
      </a>
    );
  }

  return (
    <Fragment key={id}>
      {!isEmptyRoute ? (
        <>
          <Link to={route} className={linkStyles} ref={linkRef} onClick={scrollNavSlider} data-qid={route.substring(1)}>
            {name}
            {isNew && <UI.NewLabel bg={isNewBg} color={isNewColor} />}
          </Link>
          {promoCount > 0 && <Flex.Center className="top-navbar__badge">{promoCount}</Flex.Center>}
        </>
      ) : (
        <Text className={linkStyles} text={name} onClick={onClick} />
      )}
    </Fragment>
  );
};

export default memo(CustomNavLink);
