import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { FormFieldTypes } from 'components/shared/forms/formTypes';
import { replaceTranslationPlaceholder } from 'components/shared/forms/utils';
import { UI } from 'components/shared/UI';
import { ChatButtonType } from 'shared/common/chat/types';
import { useAppSelector } from 'store';
import { Breakpoints } from 'theme/Theme';
import { Declaration } from 'utils/common/translationUtils/translationStrings';
import SoFCheckBox from './components/SoFCheckbox';
import SoFInput from './components/SoFInput';
import SoFSwitch from './components/SoFSwitch';
import SupportChat from '../../../chat/SupportChat';
import { selectInterceptors } from '../slice/interceptors.slice';
import { SourceOfFundsInputTypes, OptionType } from '../types/interceptors.types';
import { mapFormData, replacePlayerInfo, checkIsDisabled } from '../utils/interceptors.utils';

type SourceOfFundsFormType = {
  formFields: FormFieldTypes[];
  onSubmitForm: (data) => void;
  onLogout: () => void;
};

const SourceOfFundsForm = ({ formFields, onSubmitForm, onLogout }: SourceOfFundsFormType) => {
  const interceptors = useAppSelector(selectInterceptors.interceptors);
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isMobile}px)` });

  const { handleSubmit, register, watch, control, setValue, formState, trigger, clearErrors } = useForm({
    mode: 'onChange',
  });
  const [checkedOptions, setCheckedOptions] = useState<Partial<OptionType>>({});
  const fields = watch();
  const { isValid, isSubmitting, errors } = formState;
  const isDisabled = checkIsDisabled(fields, isValid, checkedOptions, formFields); // useAppSelector((state: RootState) => checkFormIsDisabled([state, isValid, checkedOptions, fields]));

  useEffect(() => {
    const body = document.body;
    body.classList.add('overflow-hidden');

    return () => {
      body.classList.remove('overflow-hidden');
    };
  }, []);

  const onSubmit = (formData) => {
    if (!isDisabled) {
      const data = mapFormData(formData, checkedOptions);
      if (interceptors.length > 0) {
        onSubmitForm({ ...data, declarationId: interceptors[0].id });
      }
    }
  };
  const isFormChatEnabled = () => {
    const formChat = formFields.filter((formField) => formField.type === SourceOfFundsInputTypes.FORMCHAT)[0];
    if (formChat) {
      return formChat.enabled;
    }
    return false;
  };

  const handleCheckboxChange = (field) => {
    setValue(field.name, !fields[field.name], { shouldValidate: true });
  };

  const handleFocusOut = (field, isFieldDisabled) => {
    setValue(field.name, !isFieldDisabled ? fields[field.name] : undefined);
  };

  const handleSwitchOnChange = (field, checked) => {
    const relatedToField = formFields.filter((formField) => formField.relatedTo === field.name)[0];
    if (checked) {
      const option = {};
      option[`${relatedToField.name}`] = {
        label: t(field.label || ''),
      };
      setCheckedOptions({ ...checkedOptions, ...option });
    } else {
      const { [relatedToField.name]: _, ...restProps } = checkedOptions;
      setCheckedOptions(restProps);
      setValue(relatedToField.name, undefined);
      clearErrors(relatedToField.name);
    }

    trigger([field.name, field.relatedTo]);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={`sof-form__grid`}>
        {!isSubmitting &&
          Object.values(formFields).map((f, idx) => {
            const field = { ...f };

            // handle filled fields to
            // Handle error translation message with placelohers
            const { message = '' } = errors[field.name] || {};
            switch (field.type) {
              case SourceOfFundsInputTypes.PAGE_TITLE:
                return (
                  <div key={idx} className="sof-form__text sof-form__title">
                    {t(field.label || '')}
                  </div>
                );
              case SourceOfFundsInputTypes.PAGE_SUBTITLE:
                return (
                  <div key={idx} className="sof-form__text sof-form__text">
                    {t(field.label || '')}
                  </div>
                );
              case SourceOfFundsInputTypes.PAGE_CONTENT:
                return (
                  <div key={idx} className="sof-form__text">
                    {interceptors[0]?.declarationInfo
                      ? replacePlayerInfo(interceptors[0]?.declarationInfo, t(field.label || ''))
                      : t(field.label || '')}
                  </div>
                );
              case SourceOfFundsInputTypes.INPUT: {
                const isInputDisabled = !(field.relatedTo && fields[field.relatedTo]);
                return (
                  <div
                    key={idx}
                    style={isMobile && isInputDisabled ? { display: 'none', position: 'absolute' } : undefined}
                  >
                    <SoFInput
                      name={field.name}
                      placeholder={t(field.placeholder || '')}
                      type={field.inputType}
                      register={register}
                      disabled={isInputDisabled}
                      error={replaceTranslationPlaceholder(
                        t(message as string),
                        field.validationRules?.minLength?.value,
                        `<<minLength>>`,
                      )}
                      validation={field.validationRules}
                      maxLength={field.validationRules?.maxLength?.value}
                      minLength={field.validationRules?.minLength?.value}
                      onFocusOut={() => handleFocusOut(field, isInputDisabled)}
                      value={fields[field.name]}
                    />
                  </div>
                );
              }
              case SourceOfFundsInputTypes.SWITCH:
                return (
                  <div key={idx}>
                    <Controller
                      control={control}
                      name={field.name}
                      rules={field.validationRules}
                      render={(props) => (
                        <SoFSwitch
                          label={t(field.label || '')}
                          onChange={(checked) => {
                            props.field.onChange(checked);
                            handleSwitchOnChange(field, checked);
                          }}
                        />
                      )}
                      {...register}
                    />
                  </div>
                );
              case SourceOfFundsInputTypes.CHECKBOX: {
                return (
                  <SoFCheckBox
                    key={idx}
                    name={field.name}
                    label={t(String(field.label))}
                    register={register}
                    checked={fields[field.name] || false}
                    onChange={() => handleCheckboxChange(field)}
                    validation={field.validationRules}
                  />
                );
              }
              default:
                return null;
            }
          })}
        <UI.Button
          size="lg"
          variant="secondary"
          text={t(Declaration.SourceOfFunds.logoutBtn)}
          icon={'logout'}
          onClick={onLogout}
        />
        <UI.HTMLButton size="lg" type="submit" text={t(Declaration.SourceOfFunds.declarationBtn)} />
      </form>
      {isFormChatEnabled() && (
        <div className="sof-form__chat">
          <SupportChat type={ChatButtonType.TEXTWITHICON} text={t('OnlineCashier.Chat.chatLabel')} />
        </div>
      )}
    </>
  );
};

export default SourceOfFundsForm;
