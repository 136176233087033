import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import AppSidebarCasinoPortal from './AppSidebarCasinoPortal';
import { selectPlatformPortals } from './slice/platformPortals.slice';

const AppSidebarCasinoWrapper = () => {
  const sidebarCasinoSlidersMap = useAppSelector(selectPlatformPortals.platformSidebarCasinoSliders);

  if (isEmpty(sidebarCasinoSlidersMap)) return null;

  return (
    <>
      {Object.keys(sidebarCasinoSlidersMap).map((portalId) => {
        const slider = sidebarCasinoSlidersMap[portalId];

        if (isEmpty(slider)) return null;

        return (
          <AppSidebarCasinoPortal
            key={portalId}
            portalId={portalId}
            id={slider.id}
            name={slider.name}
            apiUri={slider.apiUri}
          />
        );
      })}
    </>
  );
};

export default AppSidebarCasinoWrapper;
