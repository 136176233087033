import { useEffect } from 'react';
import {
  cashierThunks,
  disableScanDocument,
  enableScanDocument,
  readDocument,
  resetPaperDraftStatus,
  selectCashierData,
} from 'modules/retail/modules/cashier/slice/cashier.slice';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty, isCashier } from 'utils/common/helpersCommon';

const usePaperCoupon = () => {
  const documentEventInfo = useAppSelector(selectCashierData.documentEventInfo);
  const readDocumentInfo = useAppSelector(selectCashierData.readDocumentInfo);
  const paperDraftStatus = useAppSelector(selectCashierData.paperDraftStatus);
  const paperDraftError = useAppSelector(selectCashierData.paperDraftError);
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isCashier()) {
      if (isAuthenticated) {
        enableScanDocument();
      } else {
        disableScanDocument();
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (documentEventInfo?.code === 'RECO_EVT_DOC') {
      dispatch(readDocument());
    }
  }, [documentEventInfo]);

  useEffect(() => {
    if (paperDraftStatus === 'failure') {
      dispatch(
        showRootModal({
          modalType: 'PAPER_COUPON_ERROR_MODAL',
          modalId: 'PAPER_COUPON_ERROR_MODAL',
          modalPriority: ModalPriorityEnum.HIGH,
          modalProps: { errorMessage: paperDraftError, resetStatus: resetPaperDraftStatus },
        }),
      );
    }
  }, [paperDraftStatus]);

  useEffect(() => {
    if (!isEmpty(readDocumentInfo)) {
      dispatch(cashierThunks.paperCouponBet({ ticketType: readDocumentInfo.doc_id, zones: readDocumentInfo?.omr_l }));
      dispatch(resetPaperDraftStatus());
    }
  }, [readDocumentInfo]);
};
export default usePaperCoupon;
