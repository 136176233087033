import React from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { cssBurgerNS, gridStates } from './burgerUI.types';

/** TilesWrapper */
interface TilesWrapperProps {
  children: React.ReactNode;
}

const TilesWrapper: React.FC<TilesWrapperProps> = ({ children }) => {
  const wrapperClass = clsx(`${cssBurgerNS.Tile}__wrapper`);

  return (
    <motion.div
      className={wrapperClass}
      transition={{ ease: 'easeInOut', duration: 0.25 }}
      animate="visible"
      exit="exit"
      variants={gridStates}
      initial="hidden"
    >
      {children}
    </motion.div>
  );
};

export default TilesWrapper;
