import React, { HTMLAttributes, memo } from 'react';
import clsx from 'clsx';
import { NotificationTypes } from '../../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import { constructAnimationImage } from '../../../myProfile/sections/tabs/notificationsTab/notificationUtils';
import './Logo.scss';

interface Props extends HTMLAttributes<HTMLImageElement> {
  className?: string;
  animationParams: NotificationTypes.GamificationModalParams;
}

const AnimationHeader: React.FC<Props> = ({ className, animationParams, ...rest }) => {
  const { logo, logoWidth, logoHeight, folder } = animationParams;

  const logoImage = constructAnimationImage({ folder, image: logo, width: logoWidth * 2, height: logoHeight * 2 });

  const imgClass = clsx('w-100 gmf-logo d-flex m-auto', className);
  return <img className={imgClass} src={logoImage} style={{ maxWidth: `${logoWidth}px` }} {...rest} />;
};

export default memo(AnimationHeader);
