import { ReactNode } from 'react';
import DOMPurify from 'dompurify';
import HTMLReactParser, { HTMLReactParserOptions } from 'html-react-parser';
import { isEmpty } from 'utils/common/helpersCommon';

type ParseHTMLProps = {
  html: string;
  children?: ReactNode;
  options?: HTMLReactParserOptions | undefined;
};

// Add a hook to make all links with target attribute to be opened in a new window
DOMPurify.addHook('afterSanitizeAttributes', (currentNode) => {
  if (currentNode?.hasAttribute?.('target')) {
    currentNode.setAttribute('target', '_blank');
    currentNode.setAttribute('rel', 'noopener noreferrer');
  }
});

DOMPurify.addHook('uponSanitizeElement', function (currentNode) {
  if (currentNode?.tagName === 'IFRAME' && currentNode?.innerHTML) {
    currentNode?.setAttribute('data-tmp-html', currentNode.innerHTML);
    currentNode.innerHTML = '';
  }
});

DOMPurify.addHook('afterSanitizeElements', function (currentNode) {
  if (currentNode?.tagName === 'IFRAME' && currentNode?.getAttribute('data-tmp-html')) {
    currentNode.innerHTML = currentNode?.getAttribute('data-tmp-html') ?? '';
    currentNode?.removeAttribute('data-tmp-html');
  }
});

const ParseHTML = ({ html, children, options }: ParseHTMLProps) => {
  if (isEmpty(html)) return null;
  const clean = DOMPurify.sanitize(html, { USE_PROFILES: { html: true }, ADD_TAGS: ['iframe'], ADD_ATTR: ['target'] });
  return (
    <>
      {HTMLReactParser(clean || '', options)}
      {children}
    </>
  );
};

export default ParseHTML;
