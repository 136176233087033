import { useCallback, useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { navigateToIntegration } from '../../integrations/utils/integrations.utils';
import { selectDrawer, setCasinoDrawerType, setDrawer, setDrawerType } from '../slice/drawer.slice';
import { DrawerType } from '../types/drawer.types';

const useDrawerActiveClass = ({
  itemType,
  itemRoute,
  sectionType,
  isExternalRoute,
}: {
  itemType: string;
  itemRoute: string;
  sectionType: string;
  isExternalRoute: boolean;
}) => {
  const dispatch = useAppDispatch();
  const drawerType = useAppSelector(selectDrawer.type);
  const drawerCasinoType = useAppSelector(selectDrawer.casinoDrawerType);
  const navigate = useNavigate();
  const inactiveRoutes = {
    homePage: useMatch('/'),
    landingPage: useMatch('/landing/*'),
  };
  const removeActiveClass = inactiveRoutes.homePage || inactiveRoutes.landingPage;
  const isActive =
    drawerType === 'casino'
      ? sectionType === drawerCasinoType
        ? true
        : false
      : itemType === drawerType && itemType !== DrawerType.External;

  useEffect(() => {
    if (removeActiveClass) {
      dispatch(setDrawerType(DrawerType.InActive));
    }
  }, [dispatch, removeActiveClass]);

  const handleOnClick = useCallback(() => {
    switch (itemType) {
      case 'custom':
        if (isExternalRoute) {
          window.open(itemRoute);
        } else {
          navigate(itemRoute);
          navigateToIntegration({ fullPath: itemRoute });
        }

        dispatch(setDrawer(false));
        break;
      default:
        dispatch(setDrawerType(itemType));
        dispatch(setCasinoDrawerType(sectionType));
    }
  }, [dispatch, itemType, itemRoute, isExternalRoute]);

  return { isActive, handleOnClick };
};

export default useDrawerActiveClass;
