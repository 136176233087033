import React, { memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pointer } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { navigateToIntegration } from 'shared/common/features/integrations/utils/integrations.utils';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { SportLobby } from 'utils/common/types/sportTypes';
import { DrawerThunks, selectDrawer, setDrawer } from '../../slice/drawer.slice';

type HighlightLinkTypes = {
  id: number;
  ranking: number;
  apiUrl: string;
  name: string;
};

interface Props {
  item: HighlightLinkTypes;
}

const HighlightsLink: React.FC<Props> = ({ item }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const mixedHighlights = useAppSelector(selectDrawer.containerById(item.id));

  const hasHighlights = mixedHighlights?.status === 'succeeded' && !isEmpty(mixedHighlights?.sports);
  const shouldNavigate =
    hasHighlights && mixedHighlights.navigationUrl !== '' && mixedHighlights.navigationUrl !== null;

  const handleHighlightsClick = () => {
    dispatch(setDrawer(false));
    if (shouldNavigate) {
      const sports = mixedHighlights?.sports as SportLobby.SportData[];
      const sportName = sports?.find((x) => x?.name)?.name;
      navigate(`${mixedHighlights?.navigationUrl}`, { state: { sportName: sportName } });
      if (mixedHighlights?.navigationUrl) {
        navigateToIntegration({ fullPath: mixedHighlights?.navigationUrl });
      }
    } else {
      navigate(`/sports#highlights${item.id}`);
      navigateToIntegration({ fullPath: `/sports#highlights${item.id}` });
    }
  };

  useEffect(() => {
    if (!mixedHighlights) {
      dispatch(DrawerThunks.fetchContainer({ apiUri: item.apiUrl, containerId: item.id, skipIncrement: true }));
    }
  }, [dispatch]);

  const cssNS = 'drawer-menu-item drawer-link--highlights';

  if (hasHighlights) {
    return (
      <Flex.ACJB className={cssNS} onClick={handleHighlightsClick}>
        {item.name}
        <Pointer className={`drawer-link__indicator`} direction="right" />
      </Flex.ACJB>
    );
  }

  return null;
};

export default memo(HighlightsLink);
