import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { useLayoutConfigType } from 'modules/casino/shared/hooks/useLayoutConfigType';
import usePollingGamificationProfile from 'pages/captainUp/features/UserInfo/hooks/usePollingGamificationProfile';
import { MainNavBarButtons } from 'shared/common/features/appHeader/components/mainNavbar/mainNavbarButtons';
import { LayoutConfigTypes, VerticalsConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { selectIntegrations } from 'shared/common/features/integrations/slice/integrations.slice';
import { MyProfileBalance, MyProfileGamificationBalance } from 'shared/common/features/myProfile/components';
import { useAppDispatch, useAppSelector } from 'store';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import useSmartico from '../../../integrations/smartico/hooks/useSmartico';
import { MPDUI } from '../../components/MPDUI';
import { LevelProgress } from '../../components/WinCoins/LevelProgress';
import { useUserBalance } from '../../hooks/useUserBalance';
import { showMyProfileModal } from '../../slice/myProfile.slice';
import MyProfileBalanceAmount from '../MyProfileBalanceAmount';

const MyProfileStatisticsLayout1 = () => {
  const dispatch = useAppDispatch();
  const gamificationProfile = useAppSelector(selectIntegrations.getGamificationProfile);
  const { smarticoBalance, isActive: isSmarticoActive, openMainWidget } = useSmartico();
  const { t } = useTranslation();
  const { balanceAmount, withdrawableBalance, freeBetBalance } = useUserBalance();
  usePollingGamificationProfile();

  const gamificationConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.VERTICALS,
    deviceLayout: 'general',
  })?.[VerticalsConfigTypes.GAMIFICATION];

  const showSmarticoBalance = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.VERTICALS,
    deviceLayout: 'general',
  })?.[VerticalsConfigTypes.SMARTICO]?.showPlayerBalance;

  const triggerOpenSmartico = () => {
    dispatch(showMyProfileModal(false));
    openMainWidget();
  };

  return (
    <>
      <MPDUI.Section section="stats">
        <MyProfileBalanceAmount userBalance={balanceAmount} />
        {/* Gamification */}
        <MyProfileGamificationBalance
          text={t(MyProfile.Statistics.winCoinsText)}
          amount={`${gamificationProfile?.playerCurrencies?.coins ?? 0}`}
          showBalance={!!gamificationProfile && !!gamificationConfig?.showPlayerBalance}
        />
        {/* Smartico */}
        <MyProfileGamificationBalance
          text={t(MyProfile.Statistics.smarticoCoinsText)}
          amount={`${smarticoBalance ?? ''}`}
          showBalance={window._smartico && isSmarticoActive && showSmarticoBalance}
          onClick={triggerOpenSmartico}
        />

        <Flex.Col className="mpd-stat-item">
          <MPDUI.Text variant="secondary" text={t(MyProfile.Statistics.withdrawableText)} isTruncated={false} />
          <MyProfileBalance amount={withdrawableBalance} dataQid="withdrawableText" />
        </Flex.Col>

        <Flex.Col className="mpd-stat-item">
          <MPDUI.Text variant="secondary" text={t(MyProfile.Statistics.creditsText)} isTruncated={false} />
          <MyProfileBalance amount={freeBetBalance} dataQid="creditsText" />
        </Flex.Col>
        <MainNavBarButtons isMyProfile />
      </MPDUI.Section>
      {gamificationConfig?.showPlayerLevel &&
        gamificationProfile?.playerLevel?.name &&
        !gamificationConfig.isWidget && <LevelProgress gamificationProfile={gamificationProfile} />}
    </>
  );
};

export default memo(MyProfileStatisticsLayout1);
