import React, { memo } from 'react';
import clsx from 'clsx';
import { useWindowDimensions } from 'hooks/useWindowDimenions';
import { cdnConfig, fitCover } from 'utils/common/cdnConfig';
import config from 'utils/config';
import { StyledCTAButtonBgImage } from './CTAButtonBgImage.styled';

interface Props {
  className?: string;
  buttonText?: string;
  disabled: boolean;
  onClick: () => void;
  folder: string;
  bgColor?: string;
  textColor?: string;
  borderColor?: string;
  size?: 'small' | 'medium' | 'lg';
  disableMargin?: boolean;
}
const btnSizes = {
  small: {
    desktop: [204, 60],
    mobile: [156, 46],
  },
  medium: {
    desktop: [306, 80],
    mobile: [230, 60],
  },
};

const CTAButtonBgImage: React.FC<Props> = ({
  className,
  buttonText,
  disabled,
  onClick,
  folder,
  bgColor,
  textColor,
  borderColor,
  size = 'small',
  disableMargin = false,
}) => {
  const path = `${cdnConfig.GAMIFICATION_MODULE}/${folder}`;

  const { height } = useWindowDimensions();
  const btnSize =
    height > 800
      ? fitCover(btnSizes[size].desktop[0] * 2, btnSizes[size].desktop[1] * 2)
      : fitCover(btnSizes[size].mobile[0] * 2, btnSizes[size].mobile[1] * 2);
  const btnBg = `${config.CDN_IMAGE_API}${btnSize}${path}/button.png`;
  const btnClass = clsx('gmf-cta-btn bg-contain', !disableMargin && 'my-4', className);

  const animation = disabled
    ? {
        x: -2,
      }
    : {
        scale: 0.9,
      };

  return (
    <>
      <StyledCTAButtonBgImage
        className={btnClass}
        btnbg={btnBg}
        $size={btnSizes[size]}
        whileTap={animation}
        disabled={disabled}
        onClick={onClick}
        $bgColor={bgColor}
        $textColor={textColor}
        $borderColor={borderColor}
      >
        {buttonText}
      </StyledCTAButtonBgImage>
    </>
  );
};

export default memo(CTAButtonBgImage);
