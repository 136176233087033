import React from 'react';
import clsx from 'clsx';
// import { prizeDropCSSNS } from '../prizeDropUICSS.types';
// import { SCTableContainer } from '../styled/PrizeDropTable.styled';

type Props = {
  isPromotion?: boolean;
  children: React.ReactNode;
  className?: string;
};

const PrizeDropTable: React.FC<Props> = ({ isPromotion, children, className }) => {
  const tableClass = clsx('no-scrollbars overflow-y-auto overflow-x-hidden', className);
  const classProp = { class: tableClass };
  return (
    <egtd-przd-table data-promo={isPromotion} {...classProp}>
      {children}
    </egtd-przd-table>
  );
};

export default PrizeDropTable;
