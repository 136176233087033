import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { UI } from 'components/shared/UI';
import CashierKeyBoard from 'modules/retail/modules/ssbt/components/terminalKeyboard/CashierKeyBoard';
import { TerminalTheme } from 'modules/retail/modules/ssbt/ssbtConfig';
import { selectTicketData } from 'modules/retail/shared/slice/checkTicket.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { Breakpoints } from 'theme/Theme';
import { CashierTranslations, SSBT } from 'utils/common/translationUtils/translationStrings';
import { actionButtonEnums } from '../../cashierModalUI/actionButtonsMock';
import FiltersWrapper from '../../cashierModalUI/FiltersWrapper';
import { CashierModalUI } from '../../cashierModalUI/Index';
import { useTicketActions } from '../../hooks/useTicketActions';
import { handleCheckTicket, loadNewTicket } from '../../utils';

const TerminalCheckTicketControls = ({
  activeButtons,
  seconds,
  setSeconds,
  initialTime,
  clickCount,
  setClickCount,
  actionButtonClicked,
  setActionButtonClicked,
  inputValueUCN,
  setActiveButtons,
  isMagnumBetRo,
}) => {
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [buttonId, setButtonId] = useState(null);
  const ticketInfo = useAppSelector(selectTicketData.ticketInfo);
  const [inputValue, setInputValue] = useState('');
  const ticketNumberMinLength = 6;
  const ticketNumberMaxLength = 19;
  const isPayoutFromMagnumbetRo =
    isMagnumBetRo && Array.isArray(activeButtons) && activeButtons.includes(actionButtonEnums.payTicketButton);

  useTicketActions(
    clickCount,
    setClickCount,
    buttonId,
    setActionButtonClicked,
    setSeconds,
    actionButtonClicked,
    ticketInfo,
    setActiveButtons,
    inputValueUCN,
  );

  useEffect(() => {
    setInputValue(ticketInfo?.ticket?.ticketId?.toString() || '');
  }, [ticketInfo?.ticket.ticketId]);

  useEffect(() => {
    // Allow only numbers and letters, and remove any other characters
    if (/[^a-zA-Z0-9]/g.test(inputValue)) {
      setInputValue(inputValue.replace(/[^a-zA-Z0-9]/g, ''));
    }
  }, [inputValue]);

  useEffect(() => {
    if (actionButtonClicked === buttonId && clickCount === 1 && !isPayoutFromMagnumbetRo) {
      // If the current buttonId matches the actionButtonClicked, indicating the user has clicked the same action button twice consecutively,
      // and the clickCount is 1 (indicating the first click has already been registered),
      // and the action is not related to a payout from MagnumbetRo,
      // then increment the clickCount to proceed to the second step (final step to perform the action).
      setClickCount((prevCount) => prevCount + 1);
    }
  }, [buttonId, actionButtonClicked, clickCount]);

  const handleButtonPress = (id) => {
    if (buttonId === actionButtonClicked && clickCount === 1 && isPayoutFromMagnumbetRo) {
      // If the current buttonId matches actionButtonClicked, indicating the user has passed the UCN check,
      // the countdown timer has started, and the clickCount is 1 (indicating the first click has already been registered),
      // and the action pertains specifically to payouts from MagnumbetRo,
      // then increment the clickCount to proceed to the next step.
      return setClickCount((prevCount) => prevCount + 1);
    }

    if (isPayoutFromMagnumbetRo && id === actionButtonEnums.payTicketButton) {
      // If the action is for a payout from MagnumbetRo and the user clicks the payout button (specified in actionButtonEnums as payTicketButton),
      // set clickCount to 1 to indicate the first step and trigger the modal with the UCN check.
      // The UCN check must be passed before proceeding to the next step.
      // After passing the UCN check, actionButtonClicked will be set to the buttonId,
      // and upon clicking the button again, clickCount will be set to 2 to proceed with the action.
      setClickCount(1);
    }
    // Set the buttonId to the clicked button's id to track which button was pressed.
    if (seconds !== 0 && actionButtonClicked === buttonId && id !== actionButtonClicked) {
      // Prevent changing buttonId while the countdown is active and the current buttonId matches actionButtonClicked.
      return;
    }
    setButtonId(id);
    if (activeButtons.length > 0) {
      if (actionButtonClicked === null) {
        // If actionButtonClicked is null, indicating no button has been confirmed yet,
        // set the countdown timer to initialTime
        // and mark the actionButtonClicked with the current id,
        // indicating that a countdown has started and the user is waiting to click over this button and confirming the action
        setSeconds(initialTime);
        setActionButtonClicked(id); // On on click, set actionButtonClicked, indicating a countdown is active and the user is confirming the action
      }

      if (id === buttonId && actionButtonClicked === buttonId && clickCount === 0) {
        // If the current button id matches the buttonId,
        // actionButtonClicked is equal to buttonId (indicating a button has been confirmed),
        // and clickCount is 0 (indicating the first click has not occurred yet),
        // increment the clickCount to proceed to the next step (from step 0 to step 1).
        setClickCount((prevCount) => prevCount + 1);
      }
    }
  };

  const updateInputValue = (newValue: string) => {
    setInputValue(newValue);
  };

  return (
    <>
      <FiltersWrapper>
        <CashierModalUI.InputWrapper>
          <CashierModalUI.Input
            id={'inputTicketNumber'}
            value={inputValue}
            placeholder={t(SSBT.TerminalTicket.ticketNumberTerminal)}
            onChange={(e) => updateInputValue(e.target.value)}
          />
          <CashierKeyBoard
            inputValue={inputValue}
            updateInputFunc={updateInputValue}
            onUnmount={() => {
              return;
            }}
            handleHideKeyboard={() => {
              return;
            }}
            inputRemoveValue={false}
          />
          <CashierModalUI.ButtonsWrapper>
            <UI.Button
              text={t(CashierTranslations.ActionButton.loadNewTicket)}
              variant="secondary"
              size={isDesktop ? '2xl' : 'lg'}
              iconPath={TerminalTheme.icons.ui}
              icon="cashier-add"
              onClick={() => loadNewTicket(setInputValue, dispatch, setClickCount, setActiveButtons, setSeconds)}
            />
            <UI.Button
              text={t(SSBT.Navigation.checkTicket)}
              isDisabled={inputValue.length < ticketNumberMinLength || inputValue.length > ticketNumberMaxLength}
              variant="primary"
              size={isDesktop ? '2xl' : 'lg'}
              onClick={
                inputValue.length >= ticketNumberMinLength || inputValue.length >= ticketNumberMaxLength
                  ? () => handleCheckTicket(dispatch, inputValue)
                  : () => {
                      return;
                    }
              }
            />
          </CashierModalUI.ButtonsWrapper>
        </CashierModalUI.InputWrapper>
      </FiltersWrapper>
      <CashierModalUI.NavGrid
        actionButtonClicked={actionButtonClicked}
        seconds={seconds}
        setSeconds={setSeconds}
        onClick={handleButtonPress}
        activeButtons={activeButtons}
        setActionButtonClicked={setActionButtonClicked}
        translation={t}
        setActiveButtons={setActiveButtons}
      />
    </>
  );
};

export default TerminalCheckTicketControls;
