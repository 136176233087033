import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/rootReducer';
import { CashierWidgetType } from './types/cashierWidgetTypes';

const initialState: CashierWidgetType.InitialState = {
  isWidgetLoaded: false,
  configuration: null,
  forceRefresh: false,
};

const cashierWidget = createSlice({
  name: 'cashierWidget',
  initialState,
  reducers: {
    setIsWidgetLoaded(state, action: PayloadAction<boolean>) {
      state.isWidgetLoaded = action.payload;
    },
    refreshWidget(state) {
      const isNewImplementation = window.layout?.platform?.general?.cashierNewImplementation;
      if (isNewImplementation) return;
      state.forceRefresh = !state.forceRefresh;
    },
  },
});

export const { setIsWidgetLoaded, refreshWidget } = cashierWidget.actions;
export default cashierWidget.reducer;

export const selectCashierWidget = {
  isWidgetLoaded: (state: RootState): boolean => state.cashierWidget?.isWidgetLoaded,
  forceWidgetRefresh: (state: RootState): boolean => state.cashierWidget?.forceRefresh,
};
