import { useCallback } from 'react';
import { setGameOpenId } from 'modules/casino/modules/container/cellsContainer/cellls/slice/casinoGamesSlice';
import { GameType } from 'modules/casino/shared/constants';
import { setOpenGameData } from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import { GamePlayType } from 'modules/casino/shared/features/gameLaunch/casinoLaunchTypes';
import usePlayerRestrictions from 'modules/casino/shared/hooks/usePlayerRestrictions';
import { PlayerRestrictions } from 'pages/myAccount/tabs/responsibleGambling/types/responsibleGambling.types';
import { useAppDispatch } from 'store';

type UseOpenGameProps = {
  providerId: string;
  description: string;
  refGameId: string;
  id: string;
  providerAlias: string;
  iframeLoad: boolean;
  gameType: GameType;
  betType: PlayerRestrictions.ProductType;
};

export const useOpenGame = ({
  providerId,
  description,
  id,
  providerAlias,
  refGameId,
  iframeLoad,
  gameType,
  betType,
}: UseOpenGameProps): {
  handleOpenGame: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, gamePlayType: GamePlayType) => void;
} => {
  const dispatch = useAppDispatch();
  const { hasRestrictions } = usePlayerRestrictions({ products: [betType] });

  const handleOpenGame = useCallback((e, gamePlayType) => {
    e.preventDefault();
    if (!hasRestrictions) {
      dispatch(setGameOpenId(`${refGameId}_${new Date().getTime()}`));
      dispatch(
        setOpenGameData({
          description,
          gameType,
          refGameId,
          providerId,
          providerAlias,
          iframeLoad,
          gameId: id,
          gamePlayType,
          isTrackable: false,
        }),
      );
    }
  }, []);

  return { handleOpenGame };
};
