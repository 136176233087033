import { GameSectionTypes } from '../../../../cellls/constants/casinoGamesConstants';
import AccentGameSection from '../components/accentSection/AccentGameSection';
import FavouritesCell from '../components/favouritesCell/FavouritesCell';
import SliderRotating from '../components/SliderRotating';

export const SpecialCellMap = {
  [GameSectionTypes.ROTATING]: SliderRotating,
  [GameSectionTypes.ACCENTED]: AccentGameSection,
  [GameSectionTypes.FAVOURITES]: FavouritesCell,
};
