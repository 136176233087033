import React from 'react';
import { useTranslation } from 'react-i18next';
import CountdownTimer from 'components/shared/CountdownTimer';
import { Flex } from 'components/shared/layout';
import { getValue } from 'pages/myAccount/tabs/history/utils/history.utils';
import { hideRootGrandModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { RealityCheckModal } from 'utils/common/translationUtils/translationStrings';
import { selectRealityCheckSession } from '../';

type Props = {
  isStatic?: boolean;
};

const SessionInfoTable: React.FC<Props> = ({ isStatic }) => {
  const sessionInfoData = useAppSelector(selectRealityCheckSession.sessionInfoData);
  const liveTime = useAppSelector(selectRealityCheckSession.sessionLiveTime);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  if (isEmpty(sessionInfoData)) return null;

  return (
    sessionInfoData && (
      <Flex.Col>
        <Flex.ColCenter className={'reality-check-session-info__table'}>
          <Flex.Center className={'reality-check-session-info__table-title mb-2 w-100 font-weight-bold'}>
            {t(RealityCheckModal.Session.infoTableTitle)}
          </Flex.Center>
          <Flex.ACJB className={'reality-check-session-info__table-data w-100'}>
            <div>{t(RealityCheckModal.Session.infoTimePlayed)}</div>
            <CountdownTimer
              startDateMS={sessionInfoData.sessionStartTime}
              endDateMS={isStatic ? sessionInfoData.sessionEndTime : liveTime || Date.now()}
              duration={sessionInfoData.sessionEndTime - sessionInfoData.sessionStartTime}
              format={'HH:mm:ss without-locale'}
              takeDateOwnOffset={!isStatic}
              isStatic={isStatic}
              isLive={!isStatic}
              countDiff={1}
              onExpire={() => dispatch(hideRootGrandModal())}
            />
          </Flex.ACJB>
          <Flex.ACJB className={'reality-check-session-info__table-data w-100'}>
            <div>{t(RealityCheckModal.Session.totalRealBet)}</div>
            <div>{getValue(sessionInfoData.totalRealBet)}</div>
          </Flex.ACJB>
          <Flex.ACJB className={'reality-check-session-info__table-data w-100'}>
            <div>{t(RealityCheckModal.Session.totalRealWin)}</div>
            <div>{getValue(sessionInfoData.totalRealWin)}</div>
          </Flex.ACJB>
          <Flex.ACJB className={'reality-check-session-info__table-data w-100'}>
            <div>{t(RealityCheckModal.Session.totalBonusBet)}</div>
            <div>{getValue(sessionInfoData.totalBonusBet)}</div>
          </Flex.ACJB>
          <Flex.ACJB className={'reality-check-session-info__table-data w-100'}>
            <div>{t(RealityCheckModal.Session.totalBonusWin)}</div>
            <div>{getValue(sessionInfoData.totalBonusWin)}</div>
          </Flex.ACJB>
          <Flex.ACJB className={'reality-check-session-info__table-data w-100'}>
            <div>{t(RealityCheckModal.Session.infoBalance)}</div>
            <div>{getValue(sessionInfoData.totalNet, false)}</div>
          </Flex.ACJB>
        </Flex.ColCenter>
      </Flex.Col>
    )
  );
};

export default SessionInfoTable;
