import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  isMatchWithIncrementAction,
  loaderIncrementors,
  isMatchWithDecrementAction,
  loaderDecrementors,
  isMatchWithResetAction,
  loaderResetors,
  LoaderName,
} from 'shared/common/features/loaderData/matchWithActions';
import { setLanguage } from 'shared/common/sharedSlices/commonActions';

// This is a specialized slice, which catches the 'actions' of other reducers
// in order to provide the following functions:
// - 'incrementing' and 'decrementing' a common loader
// - not displaying a loader when a specific thunk has been already called. i.e no need for a isFetched flag in the thunk itself.
// - not displaying a loader on websocket reconnect
// - provides a simple selector to show whether the loader is loading or not
//
// To extend this functinality, you will need to extend only the structures: LoaderName, loaderIncrementors, loaderDecrementors, loaderResetors
// extending the logic in the extraReducers shouldn't be needed

export type LoaderIncrement = {
  skipIncrement?: boolean;
};

type LoaderData = {
  count: 0;
  prevArguments: string[];
  initialLoading: boolean;
  repeatLoaderAllowed: boolean;
};

type LoaderDataMap = Record<LoaderName, LoaderData>;

type State = {
  loaderMap: LoaderDataMap;
};

const defaultLoader: LoaderData = {
  count: 0,
  prevArguments: [],
  initialLoading: true,
  repeatLoaderAllowed: true,
};

const getDefaultLoaderData = () => {
  return { ...defaultLoader };
};

const initialState: State = {
  loaderMap: {
    homePage: getDefaultLoaderData(),
  },
};

const loaderDataSlice = createSlice({
  name: 'loaderData',
  initialState,
  reducers: {
    resetLoaders: () => initialState, //use this only when you want to remove all loading data e.g changing the TimeZones
    resetLoader(state, action: PayloadAction<LoaderName>) {
      const loaderName = action.payload;
      state.loaderMap[loaderName] = { ...initialState.loaderMap[loaderName] };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setLanguage, () => {
      return initialState; //reset loaderState on language change
    });

    builder
      .addMatcher(isMatchWithIncrementAction, (state, action) => {
        const { arg } = action['meta'];
        if ((arg as LoaderIncrement)?.skipIncrement) {
          return;
        }
        const loaderName = loaderIncrementors[action.type];
        const argStr = JSON.stringify({ type: action.type, arg });
        const argumentsExist = state.loaderMap[loaderName].prevArguments.find((a) => a === argStr);
        if (!argumentsExist) {
          state.loaderMap[loaderName].count++;
          state.loaderMap[loaderName].prevArguments.push(argStr);
        }
      })
      .addMatcher(isMatchWithDecrementAction, (state, action) => {
        const loaderName = loaderDecrementors[action.type];
        if (state.loaderMap[loaderName].count !== 0) {
          state.loaderMap[loaderName].count--;

          if (state.loaderMap[loaderName].count === 0) {
            state.loaderMap[loaderName].initialLoading = false;
          }
        }
      })
      .addMatcher(isMatchWithResetAction, (state, action) => {
        const loaderName = loaderResetors[action.type];
        state.loaderMap[loaderName] = getDefaultLoaderData();
      });
  },
});

export const loaderDataActions = loaderDataSlice.actions;

export default loaderDataSlice.reducer;
