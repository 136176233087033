import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { sessionBalanceThunk } from 'modules/retail/modules/ssbt/features/sessionBalance/slice/sessionBalance.slice';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch, useAppSelector } from 'store';
import { terminalThunks, selectSSBTdata, resetDepositStatus } from '../slice/ssbt.slice';

export const useDepositTerminalData = () => {
  const { t } = useTranslation();
  const depositRetailStatus = useAppSelector(selectSSBTdata.depositRetailStatus);
  const depositRetailInfo = useAppSelector(selectSSBTdata.depositRetailInfo);
  const totalSum = useAppSelector(selectSSBTdata.totalSum);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (totalSum !== 0) {
      dispatch(terminalThunks.depositRetail({ totalSum }));
    }
  }, [dispatch, totalSum]);

  useEffect(() => {
    if (depositRetailStatus === 'succeeded') {
      dispatch(sessionBalanceThunk.checkForUpdateBalance());
    }

    if (depositRetailStatus === 'failure') {
      dispatch(
        showRootModal({
          modalType: 'CASHIER_ERROR_MODAL',
          modalId: 'CASHIER_ERROR_MODAL',
          modalPriority: ModalPriorityEnum.LOW,
          modalProps: { errorMessage: t('Default.Retail.InternalServerError'), resetStatus: resetDepositStatus },
        }),
      );
    }
  }, [dispatch, depositRetailStatus, depositRetailInfo]);
};
