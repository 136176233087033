import React from 'react';
import clsx from 'clsx';
import { CSS } from 'utils/common/types/css.types';
import { Flex } from '../layout';

export type NotificationBadgePositions = 'centered' | 'right';

interface Props {
  count?: number | string;
  variant?: CSS.Variants;
  position?: NotificationBadgePositions;
  componentLocation: 'bottomTabBar';
}

const NotificationBadge: React.FC<Props> = ({ count, variant, position, componentLocation }) => {
  const cssNS = 'badge-notification';
  const badgeClass = clsx(
    'text-center',
    cssNS,
    variant && `${cssNS}--${variant}`,
    position && `${cssNS}--${position}`,
    componentLocation === 'bottomTabBar' && `${cssNS}--bottom-tab-bar`,
  );
  return <Flex.Center className={badgeClass}>{count}</Flex.Center>;
};

export default NotificationBadge;
