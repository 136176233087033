import { getWindowConfigParam, isEmpty } from 'utils/common/helpersCommon';

export const altenarRouteMap = {
  '/sports': 'overview',
  '/in-play': 'live',
  '/virtual': 'virtual',
};

export const containerId = 'altenar';
export const scriptUrl = getWindowConfigParam('SPORTSBOOK_URL');

export const getPage = ({ location }) => {
  if (isEmpty(location.hash)) return altenarRouteMap[location.pathname] || 'overview';
  const page = location.hash.substring(2);
  return page;
};

export const buildAltenarUrl = (params) => {
  let url;

  switch (params?.page) {
    case 'live':
    case 'liveEvent':
      url = '/in-play';
      break;
    case 'virtual':
    case 'horseRacingDetails':
    case 'horseRacing':
      url = '/virtual';
      break;
    default:
      url = '/sports';
  }

  const hash = window.location?.hash;
  const pathname = window.location?.pathname;
  if (pathname === url) return '';
  return hash ? `${url}${hash}` : url;
};
