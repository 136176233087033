import { DEFAULT_ERROR_MSG } from 'modules/casino/shared/constants/commonConstants';
import axiosInstance from 'utils/common/axios-instance';

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async (url) => {
    try {
      const result = await axiosInstance({
        url: baseUrl + url,
      });
      return { data: result.data };
    } catch (axiosError) {
      return {
        error: DEFAULT_ERROR_MSG,
      };
    }
  };

export default axiosBaseQuery;
