import React from 'react';
import { FooterUI } from 'components/shared';
import { PartialRecord } from 'utils/common/types/common.types';
import { Copyright, HelpBlock, ImageSection, Legal, PoweredBy, ScrollWrapper, Systems, Wrapper } from './components';
import { FooterTypes } from './types/appFooter.types';

interface FooterSectionProps {
  gridItem: FooterTypes.Section;
  index: number;
}

type FooterItemProps = {
  sectionItem: FooterTypes.Section;
};
type RenderComponentFunc = ({ sectionItem }: FooterItemProps) => JSX.Element;
const renderFooterSection: PartialRecord<FooterTypes.ItemNames, RenderComponentFunc> = {
  Partners: ({ sectionItem }) => <ImageSection section={sectionItem} />,
  System: ({ sectionItem }) => <Systems key={sectionItem.name} section={sectionItem} />,
  Copyright: ({ sectionItem }) => <Copyright section={sectionItem} />,
  PoweredBy: ({ sectionItem }) => <PoweredBy section={sectionItem} />,
  License: ({ sectionItem }) => <Legal section={sectionItem} />,
  Payments: ({ sectionItem }) => <ImageSection section={sectionItem} />,
  Links: ({ sectionItem }) => <HelpBlock sections={sectionItem} />,
};

type RenderWrapperFunc = ({
  children,
  sectionTypes,
  isHighlighted,
  depositButton,
}: {
  children: React.ReactNode;
  sectionTypes: string;
  isHighlighted?: boolean;
  depositButton?: boolean;
}) => JSX.Element;

const renderWrapper: PartialRecord<string, RenderWrapperFunc> = {
  autoScroll: ({ children, sectionTypes, isHighlighted, depositButton }) => (
    <ScrollWrapper sectionTypes={sectionTypes} isHighlighted={isHighlighted} depositButton={depositButton}>
      {children}
    </ScrollWrapper>
  ),
  normal: ({ children, isHighlighted, sectionTypes, depositButton }) => (
    <Wrapper sectionTypes={sectionTypes} isHighlighted={isHighlighted} depositButton={depositButton}>
      {children}
    </Wrapper>
  ),
};

const FooterSection = ({ gridItem, index }: FooterSectionProps): JSX.Element => {
  const wrapper = renderWrapper[gridItem.autoScroll ? 'autoScroll' : 'normal'];
  return (
    <>
      <div
        className={`d-flex-col footer-section footer-section-${gridItem.typeName.toLowerCase()} ${
          index === 0 ? 'footer-section--first' : ''
        }`}
      >
        {gridItem.title && <FooterUI.SectionTitle text={gridItem.title} />}
        {wrapper?.({
          sectionTypes: gridItem.typeName,
          isHighlighted: gridItem.isHighlighted,
          depositButton: gridItem.depositButton,
          children: renderFooterSection[gridItem.typeName]?.({
            sectionItem: gridItem,
          }),
        })}
      </div>
    </>
  );
};

export default FooterSection;
