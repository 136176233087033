import { Fragment, memo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { Breakpoints } from 'theme/Theme';
import { DefaultBox2x3Row, DefaultBoxDefaultRow } from './components';
import DefaultBoxItem from './DefaultBoxItem';
import { NotificationTypes } from '../../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import useWinItemState from '../../hooks/useWinItemState';

type Props = {
  setAnimationComplete: (isVisible: boolean) => void;
  animationComplete: boolean;
  animationParams: NotificationTypes.GamificationModalParams;
  notification: NotificationTypes.PopUpNotification;
  isReady: boolean;
  rowLayout: 'default' | '2x3';
  imageByIndexFolder: boolean;
  foldersName?: number[];
  bonusAlignment?: NotificationTypes.BonusAlignment;
};

const BoxRows = {
  default: DefaultBoxDefaultRow,
  '2x3': DefaultBox2x3Row,
};

const DefaultBoxItems: React.FC<Props> = ({
  animationComplete,
  animationParams,
  setAnimationComplete,
  notification,
  isReady,
  rowLayout,
  imageByIndexFolder,
  foldersName,
  bonusAlignment,
}) => {
  const { bonusData } = notification;

  const { state, handlers } = useWinItemState();

  if (!bonusData) return null;
  const SpecificRows = BoxRows[rowLayout];

  return (
    <>
      <AnimatePresence>
        {isReady && (
          <>
            <StyledWrapper
              className="d-flex flex-wrap justify-content-center "
              length={bonusData.otherOptions?.length}
              variants={boxVariant}
              $rowLayout={rowLayout}
              animate="visible"
              initial="hidden"
            >
              {bonusData.otherOptions?.map((item: string, index: number) => (
                <Fragment key={`${item}-${index}`}>
                  <DefaultBoxItem
                    index={index}
                    notification={notification}
                    winItemIndex={state.winItemIndex}
                    setWinItemIndex={handlers.setWinItemIndex}
                    setAnimationComplete={setAnimationComplete}
                    animationComplete={animationComplete}
                    animationParams={animationParams}
                    imageByIndexFolder={imageByIndexFolder}
                    itemsLength={bonusData.otherOptions.length}
                    foldersName={foldersName}
                    bonusAlignment={bonusAlignment}
                  />
                  <SpecificRows index={index} itemLength={bonusData.otherOptions?.length} />
                </Fragment>
              ))}
            </StyledWrapper>
          </>
        )}
      </AnimatePresence>
    </>
  );
};
const boxVariant = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};
const defaultCSS = css`
  margin-right: 10px;

  @media screen and (max-width: ${Breakpoints.isDesktop}px) {
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    &:nth-last-child {
      margin-right: 0;
    }
  }
`;
const row2x3CSS = css`
  margin-right: 6px;

  &:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 1280px) {
    &:nth-child(2) {
      margin-right: 0;
    }
  }
  @media screen and (min-width: 1280px) {
    margin-right: 14px;
  }
`;

const mapCss = {
  default: defaultCSS,
  '2x3': row2x3CSS,
};

const StyledWrapper = styled(motion.div)<{ length?: number; $rowLayout: 'default' | '2x3' }>`
  margin-top: 14px;
  @media screen and (max-width: ${Breakpoints.isDesktop}px) {
    margin-top: 36px;
  }
  & > .d-grid {
    ${({ length, $rowLayout }) => (length && length > 0 ? mapCss[$rowLayout] : ``)}
  }
`;

export default memo(DefaultBoxItems);
