import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import useAppHomePage from 'hooks/common/useAppHomePage';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useIsRetailMobile } from 'shared/common/features/appHeader/retailHeader/hooks/useIsRetailMobile';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { useAppSelector } from 'store';

type Props = {
  children: ReactNode;
};

export const DepositHandHeldRestriction = ({ children }: Props) => {
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const isRetailMobile = useIsRetailMobile();
  const { homeRoute } = useAppHomePage();

  const showHeaderButtons = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.RETAIL_HEADER_BUTTONS_VISIBLE,
    deviceLayout: 'general',
  });
  const showLimitsAndDepositButton = isAuthenticated && isRetailMobile && showHeaderButtons?.isLimitsAndDepositActive;

  if (!showLimitsAndDepositButton) {
    return <Navigate to={homeRoute} />;
  }

  return <>{children}</>;
};
