import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { useNavigateTo } from 'hooks/navigation';
import { populateBetSlipFromQrCode, scanVoucherCode, checkTicket } from 'modules/retail/modules/ssbt/utils/ssbt.utils';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useGetOmniPlayScanData } from 'pages/retailGames/providers/omniPlay/hooks/useGetOmniPlayScanData';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { useAppDispatch, useAppSelector } from 'store';
import { isCashier, isTerminal } from 'utils/common/helpersCommon';
import { scanUCNCode } from '../../modules/cashier/features/ucnScan/scan.utils';
import { selectCashierData } from '../../modules/cashier/slice/cashier.slice';
import { setBarcodeSelected } from '../../modules/ssbt/slice/ssbt.slice';
import { useBarcode } from '../hooks/useBarcode';
import { useScanner } from '../hooks/useScanner';
import { resetTicketInfoState } from '../slice/checkTicket.slice';

const QRCodeScanner: React.FC<{ handleSubmit?: () => void }> = ({ handleSubmit }) => {
  const terminalActivity = useAppSelector(selectCashierData.terminalActivity);
  const dispatch = useAppDispatch();
  const navigateTo = useNavigateTo();
  const { closeModal } = useHandleModals();
  const location = useLocation();
  const isTerminalActive = () => terminalActivity === 'Active';
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const minUCNCharactersLength = 60; // Minimum UCN character length: 60 for cashier machines, 50 for browser testing.
  const { reset } = useBarcode();
  const { t } = useTranslation();
  const { baseUrl, omniPlayIframeId, ticketCodePrefix, navigateToTicketCheckView } = useGetOmniPlayScanData(
    window.location.pathname,
  );

  const updateStateForNewScan = (barcode) => {
    dispatch(resetTicketInfoState());
    checkTicket(barcode, handleSubmit, dispatch);
    dispatch(setBarcodeSelected(true));
    reset();
  };
  const isUcnScannerActive = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.RETAIL_DEVICE,
    deviceLayout: 'general',
  })?.UCNScanner;

  const actions = {
    betSlipDraft: () => {
      closeModal();
      if (location.pathname === '/') {
        navigateTo.navigate('/sports');
      }
      populateBetSlipFromQrCode(barcode, dispatch);
    },
    checkTicket: () => {
      if (!isTerminalActive()) {
        return;
      }
      updateStateForNewScan(barcode);
    },
    scanVoucher: () => {
      if (isTerminal()) {
        scanVoucherCode(barcode, dispatch);
      }
    },
    scanUCN: () => {
      if (isCashier() && isTerminalActive() && isAuthenticated && isUcnScannerActive) {
        scanUCNCode(barcode, dispatch, t, closeModal);
      }
    },
    scanOmniPlayTicket: () => {
      navigateToTicketCheckView(omniPlayIframeId, baseUrl, barcode, dispatch);
    },
    default: () => {
      return;
    },
  };

  const actionKeyMap = [
    { key: 'checkTicket', validate: (digitBarcode) => /^\d{19}$/.test(digitBarcode) },
    { key: 'betSlipDraft', validate: (nBarcode) => nBarcode.charAt(0) === 'N' },
    { key: 'scanVoucher', validate: (voucherCode) => voucherCode.charAt(0) === 'V' },
    { key: 'scanOmniPlayTicket', validate: (voucherCode) => voucherCode.startsWith(ticketCodePrefix) },
    { key: 'scanUCN', validate: (UCNcode) => UCNcode.length >= minUCNCharactersLength },
    { key: 'default', validate: () => true }, // default action key
  ];

  const { barcode } = useScanner(actions, actionKeyMap);

  return <></>;
};

export default QRCodeScanner;
