import React from 'react';
import { useAppSelector } from 'store';
import OpenLauncher from './OpenLauncher';
import { casinoLaunchSelectors } from '../casinoLaunchSlice';

const OpenGameLauncherWrapper = () => {
  const openGameData = useAppSelector(casinoLaunchSelectors.openGameData);
  return openGameData && <OpenLauncher openGameData={openGameData} />;
};

export default OpenGameLauncherWrapper;
