import React from 'react';

import Button from 'components/shared/UI/Button/Button';
import { prizeDropCSSNS } from '../prizeDropUICSS.types';

type Props = {
  infoText: string;
  handleInfo: () => void;
  infoLinkText: string;
  btnText: string;
  handleClose?: () => void;
};

const PrizeDropModalFooter: React.FC<Props> = ({ handleClose, infoText, infoLinkText, handleInfo, btnText }) => {
  const classProp = { class: 'd-flex-ac-jb' };

  return (
    <egtd-przd-modal-footer {...classProp}>
      <div className={`${prizeDropCSSNS}-modal-footer-text`} onClick={handleInfo}>
        {infoText} <u>{infoLinkText}</u>
      </div>
      <Button className={`${prizeDropCSSNS}-modal-footer-btn`} variant="primary" size="lg" onClick={handleClose}>
        {btnText}
      </Button>
    </egtd-przd-modal-footer>
  );
};

export default PrizeDropModalFooter;
