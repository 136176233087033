import React from 'react';
import { Flex } from 'modules/casino/shared/components/layout';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { DesktopDown } from 'theme/Theme';
import LobbySubtitle from './LobbySubtitle';
import { CategoryLayoutTypes } from '../../types/CategoryLayoutTypes';
interface Props {
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
}
const InnerCategorySubtitle: React.FC<Props> = ({ categoryId, categoryLayoutType }) => {
  const { showSubTitle } = useGetCategoryParams(categoryId, categoryLayoutType);
  const styleType = categoryLayoutType.toLowerCase();
  if (!showSubTitle) return <></>;
  return (
    <DesktopDown>
      <Flex.ACJB className={`${styleType}-category-header ${styleType}-category-subheader`}>
        <LobbySubtitle categoryId={categoryId} categoryLayoutType={categoryLayoutType} />
      </Flex.ACJB>
    </DesktopDown>
  );
};

export default InnerCategorySubtitle;
