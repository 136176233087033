import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = { style?: CSSProperties };

export const CustomRibbon: React.FC<Props> = ({ style }) => {
  const { t } = useTranslation();
  return <SCRibbon style={style}>{t('NavBar.NavItem.ribbonText')}</SCRibbon>;
};

const SCRibbon = styled.span`
  position: absolute;
  top: 13px;
  right: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 36px;
  height: 14px;
  padding: 0;

  border-radius: 6px;

  font-size: 12px;
  font-weight: 700;
  text-align: center;
  line-height: 15px;

  flex-shrink: 0;
  overflow: hidden;
`;
