import React, { memo } from 'react';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import { GridLayouts } from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import useWidgetBackground from 'modules/casino/modules/container/widget/widgetTypes/hooks/useWidgetBackground';
import { useAppSelector } from 'store';
import { casinoWidgetsSelectors } from '../../../slice/casinoWidgetsSlice';
import { CasinoWidgetsTypes, Destinations, WidgetTemplates } from '../../../types/casinoWidgetsTypes';
import DynamicSlider from '../components/DynamicSlider';
import LastWinnersCellSkeleton from '../components/LastWinnersCellSkeleton';
import LastWinnersWidgetsTopWinner from '../components/LastWinnersWidgetsTopWinner';
import { LastWinnersWidgetsUI } from '../components/LastWinnersWidgetsUIComponents';
import { useFetchLastWinners } from '../hooks/useFetchLastWinners';
import { useHideLastWinners } from '../hooks/useHideLastWinners';
import { LAST_WINNERS_PULL_INTERVAL_FALLBACK, TOP_WINNER_AREA } from '../LastWinnersWidgetsConstants';

interface WidgetsProps {
  widget: CasinoWidgetsTypes.Widget;
  layoutGrid: GridLayouts;
}

const LastWinnersCellWidget: React.FC<WidgetsProps> = ({ widget, layoutGrid }) => {
  const lastWinners = useAppSelector((state) => casinoWidgetsSelectors.filteredLastWinners(state, widget.id));
  const topWinner = useAppSelector((state) => casinoWidgetsSelectors.topWinner(state, widget.id));
  const type = widget?.template?.type;
  const { hideLastWinnersConfig } = useHideLastWinners({ layoutGrid, type });
  const hideLastWinners = topWinner && hideLastWinnersConfig;
  const backgroundWidgetResource = widget?.backgroundLocations;
  const { backgroundWrapper, setRefs } = useWidgetBackground({ backgroundWidgetResource, type });

  useFetchLastWinners({
    id: widget.id,
    query: widget.query,
    pullInterval: widget.pullInterval,
    fallbackInterval: LAST_WINNERS_PULL_INTERVAL_FALLBACK.CELL,
  });

  const leftColClass = clsx('cw--left-col', topWinner && 'cw--left-col--top-winner');

  if (type === WidgetTemplates.SECTION) {
    return <></>;
  }

  return widget.id && widget.query && lastWinners && (!backgroundWidgetResource || backgroundWrapper) ? (
    <>
      <LastWinnersWidgetsUI.Wrapper type={type} backgroundUrl={backgroundWrapper} columns={widget?.template?.columns}>
        <div
          className={leftColClass}
          style={{ gridColumnStart: topWinner ? TOP_WINNER_AREA.column : widget?.title?.column }}
        >
          {widget?.title && <LastWinnersWidgetsUI.Text description={widget?.title} type={type} />}
          {topWinner && (
            <LastWinnersWidgetsTopWinner
              topWinner={topWinner}
              type={type}
              layoutConfig={TOP_WINNER_AREA}
              showTitle={false}
              cdnPathLayout={layoutGrid?.toLowerCase()}
            />
          )}
        </div>

        {widget.query && !hideLastWinners && (
          <DynamicSlider
            templateType={type}
            lastWinners={lastWinners}
            cdnPathLayout={layoutGrid?.toLowerCase()}
            destination={Destinations.Casino}
            direction={'vertical'}
            sortByWinTime={true}
          />
        )}
      </LastWinnersWidgetsUI.Wrapper>
    </>
  ) : (
    <LastWinnersCellSkeleton type={type} columns={widget?.template?.columns} setRefs={setRefs} />
  );
};
export default memo(LastWinnersCellWidget, isEqual);
