import SVG from 'react-inlinesvg';
import { Flex } from 'components/shared/layout';
import PrizeDropPromoTimeCounter from 'components/shared/prizeDrop/components/PrizeDropPromoTimeCounter';
import PromotionTimeExpired from 'components/shared/prizeDrop/components/PromotionTimeExpired';
import config from 'utils/config';

/** PromoPage Header */
interface HeaderProps {
  title?: string;
  iconResource?: string;
  hasDate?: boolean;
  text: string;
  date: number;
  isExpired: boolean;
}

const Header: React.FC<HeaderProps> = ({ title, hasDate, iconResource, text, date, isExpired }) => {
  const iconPath = `${config.CDN_URL}/${iconResource}`;

  return (
    <div className="overflow-hidden prm-page__wrapper prm-page__header">
      <egtd-promo-przd-header data-hasDate={hasDate} data-expired={isExpired}>
        <Flex.AC className="prm-page__header--text">
          {iconResource && <SVG className="icon" src={iconPath} width={'100%'} height={'100%'} cacheRequests />}
          <span>{title}</span>
        </Flex.AC>

        {isExpired && <PromotionTimeExpired />}
        {hasDate && <PrizeDropPromoTimeCounter text={text} date={date} />}
      </egtd-promo-przd-header>
    </div>
  );
};

export default Header;
