import { useEffect } from 'react';

const useStorageEvent = (eventCallback: (event: StorageEvent) => void, clearCallback?: () => void) => {
  useEffect(() => {
    window.addEventListener('storage', eventCallback);

    return () => {
      window.removeEventListener('storage', eventCallback);
      clearCallback && clearCallback();
    };
  }, [eventCallback, clearCallback]);
};

export default useStorageEvent;
