import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';

const useSportsBookType = () => {
  const sportsBookType =
    useLayoutConfigType({
      moduleLayout: 'platform',
      innerLayout: LayoutConfigTypes.SPORTS_BOOK_WIDGET,
      deviceLayout: 'general',
    })?.type ?? 'egtd';

  return { sportsBookType };
};

export default useSportsBookType;
