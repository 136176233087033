import React from 'react';
import StyledCardTitle from './CardTitle.styled';

interface CardTitleProps {
  text: string;
  textStyle?: { color: string; backgroundColor: string };
}

const CardTitle: React.FC<CardTitleProps> = ({ text, textStyle }) => {
  return <StyledCardTitle style={textStyle}>{text}</StyledCardTitle>;
};

export default CardTitle;
