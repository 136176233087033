import { useLocation } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { svaraAppSelectors } from 'pages/svaraAppLogin/slice/svaraAppLogin.slice';
import { useAppSelector } from 'store';

export const useIsSvaraRoute = (): boolean => {
  const { query } = useQuery();
  const { pathname } = useLocation();
  const openFromSvara = useAppSelector(svaraAppSelectors.openFromSvara);

  return openFromSvara || pathname.includes('svara-app') || !!query.get('openFromSvara');
};
