import { MutableRefObject, useRef } from 'react';
import { useImage } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameInfoModal/hooks/useImage';
import { GridLayouts } from '../../../../../types/gridTypes';

interface Props {
  gameUniqueId: string;
  categoryId: string;
  layoutGrid: GridLayouts;
  imageModalRef: MutableRefObject<HTMLDivElement | null>;
  className?: string;
  alt?: string;
}

const GameInfoModalImage: React.FC<Props> = ({
  gameUniqueId,
  categoryId,
  layoutGrid,
  imageModalRef,
  className,
  alt = '',
}) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const { handleError } = useImage({ imgRef, imageModalRef, gameUniqueId, categoryId, layoutGrid });

  return <img ref={imgRef} alt={alt} className={className} onError={handleError} />;
};

export default GameInfoModalImage;
