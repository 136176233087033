import { useEffect } from 'react';
import { isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import { useAppDispatch, useAppSelector } from 'store';
import { LANDING_PAGE_SEO_DATA_KEY } from '../seoDataConstants';
import { fetchSEOData, resetSEO, seoDataSelectors } from '../slice/seoData.slice';

export const useFetchSeoData = ({
  verticalId,
  hasSeoContent,
  isLandingPage,
}: {
  verticalId: number | undefined;
  hasSeoContent: boolean | undefined;
  isLandingPage: boolean;
}) => {
  const dispatch = useAppDispatch();
  const seoData = useAppSelector((state) =>
    seoDataSelectors.seoDataByVertical(state, isLandingPage ? LANDING_PAGE_SEO_DATA_KEY : verticalId),
  );

  useEffect(() => {
    if (verticalId && hasSeoContent && isEmpty(seoData)) {
      dispatch(fetchSEOData({ verticalId }));
    }
    return () => {
      dispatch(resetSEO());
    };
  }, [dispatch, verticalId, hasSeoContent]);

  return { seoData };
};
