import React, { memo, RefObject, useRef } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { Flex } from 'components/shared/layout';
import { selectHighlightNewFeature } from 'shared/common/features/highlightNewFeature/slice/highlightNewFeature.slice';
import useHeaderConfig from 'shared/common/hooks/useHeaderConfig';
import { useAppSelector } from 'store';
import { Breakpoints } from 'theme/Theme';
import { isTerminal } from 'utils/common/helpersCommon';
import { cssGlobalNS } from 'utils/common/types/css.types';
import MainNavbarDevAPISwitcher from './MainNavbarDevAPISwitcher';
import MainNavDesktop from './MainNavDesktop';
import MainNavMobile from './MainNavMobile';
import { useShowTopNavOnClick } from '../../hooks/useShowTopNavOnClick';

type MainNavbarTypes = {
  classByLocation: string;
  mainNavRef: RefObject<HTMLDivElement>;
  isAuthenticated: boolean | null;
};

const MainNavbar: React.FC<MainNavbarTypes> = ({ classByLocation, mainNavRef, isAuthenticated }) => {
  const appHeaderInnerClass = clsx('wh-100 head-inner');
  const headerInnerRef = useRef<HTMLDivElement | null>(null);
  const mainNavbarClass = clsx('d-flex mnav', classByLocation && classByLocation);
  const showHighlightNewFeature = useAppSelector(selectHighlightNewFeature.isActive);
  const { topNavInitialCollapsed, isVisible: isOpenTopNav } = useShowTopNavOnClick();

  const { wrapper: headerWrapperType } = useHeaderConfig();
  const wrapperType = headerWrapperType?.layoutType || 'layout_1';

  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });

  return (
    <>
      <nav className={mainNavbarClass} ref={mainNavRef}>
        <div className={`${cssGlobalNS}-container`}>
          <Flex className={appHeaderInnerClass} ref={headerInnerRef}>
            {isDesktop || isTerminal() ? (
              <MainNavDesktop layout={wrapperType} isAuthenticated={isAuthenticated} parentRef={headerInnerRef} />
            ) : (
              <MainNavMobile
                layout={wrapperType}
                isAuthenticated={isAuthenticated}
                showArrow={!showHighlightNewFeature && topNavInitialCollapsed}
                isOpenTopNav={isOpenTopNav}
              />
            )}
          </Flex>
        </div>
      </nav>
      <MainNavbarDevAPISwitcher />
    </>
  );
};

export default memo(MainNavbar);
