import React, { memo } from 'react';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import PaperCouponCashier from 'modules/retail/modules/cashier/features/paperCoupon/PaperCouponCashier';
import TerminalStatusActivity from 'modules/retail/modules/cashier/features/quetHours/TerminalStatusActivity';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { useAppSelector } from 'store';
import { isHandheld } from 'utils/common/helpersCommon';
import QRCodeScanner from './QRCodeScanner';
import { useLastPrint } from '../../modules/cashier/features/lastPrint/hook/useLastPrint';
import { useCheckUCNCode } from '../../modules/cashier/features/ucnScan/hooks/useCheckUCNCode';
import { selectCashierData } from '../../modules/cashier/slice/cashier.slice';
import RgbController from '../../modules/ssbt/components/RgbController';
import TerminalModalWrapper from '../../modules/ssbt/components/TerminalModalWrapper';
import TerminalRedirectHomePage from '../../modules/ssbt/components/TerminalRedirectHomePage';
import { useDepositTerminalData } from '../../modules/ssbt/hooks/useDepositSSBTdata';
import { useRunTerminalCommands } from '../hooks/useRunSSBTcommands';

const TerminalWrapper = () => {
  const terminalActivity = useAppSelector(selectCashierData.terminalActivity);
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const retailDevice = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.RETAIL_DEVICE,
    deviceLayout: 'general',
  });

  useRunTerminalCommands(retailDevice, isAuthenticated);
  useLastPrint();
  useDepositTerminalData();
  useCheckUCNCode();

  const componentsToRender = [
    {
      component: terminalActivity === 'Active' ? <QRCodeScanner /> : null,
      condition: retailDevice?.qrCodeScanner,
    },
    { component: <TerminalModalWrapper />, condition: retailDevice?.modalWrapper },
    { component: <TerminalRedirectHomePage />, condition: retailDevice?.redirectPage },
    { component: <RgbController />, condition: retailDevice?.rgbController },
    { component: <TerminalStatusActivity />, condition: !isHandheld() },
    { component: <PaperCouponCashier />, condition: !isHandheld() },
  ];

  return (
    <>
      {componentsToRender.map(
        (item, index) => item.condition && <React.Fragment key={index}>{item.component}</React.Fragment>,
      )}
    </>
  );
};

export default memo(TerminalWrapper);
