import Dial from './Dial';
import Fireworks from './Fireworks';
import Marker from './Marker';
import Segment from './Segment';
import SpinButton from './SpinButton';

export const WheelUI = {
  Dial: Dial,
  Fireworks: Fireworks,
  Marker: Marker,
  Segment: Segment,
  SpinButton: SpinButton,
};
