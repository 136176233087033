import React from 'react';
import clsx from 'clsx';
import { GameCellDataCharactersTypes } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTableCharacters/gameCellTableCharactersConstants';
import {
  backgroundSymbol,
  colorSymbol,
  convertSymbol,
  isNumeric,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTableCharacters/GameCellTableCharactersUtils';
import { Flex } from 'modules/casino/shared/components/layout';

export interface ItemsProps {
  symbol;
  category: GameCellDataCharactersTypes;
  classFromLayout: string;
  showLuckyNumber?: boolean;
  luckyNumber?: number;
}

export const ItemRoulette: React.FC<ItemsProps> = (props) => {
  const numberOne = convertSymbol(props.symbol, props.category)[0];
  const numberTwo = convertSymbol(props.symbol, props.category)[1];

  const charStyles = clsx(
    'character',
    numberTwo ? 'is-double-roulette d-flex-col overflow-hidden' : 'd-flex-col-center',
    props.luckyNumber && props.showLuckyNumber && 'lucky-numbers-wrapper',
    props.classFromLayout,
    !isNumeric(props.symbol) &&
      typeof props.symbol === 'string' &&
      `bg--${backgroundSymbol(props.symbol, props.category)}`,
    !isNumeric(numberTwo) && `${colorSymbol(props.symbol, props.category)}-text`,
  );

  return (
    <div className={charStyles}>
      {props.luckyNumber && props.showLuckyNumber && <div className={`lucky-numbers`}>{`x${props.luckyNumber}`}</div>}
      <Flex.Center className={`${isNumeric(numberOne) && colorSymbol(numberOne, props.category)}-text character-text`}>
        {numberOne}
      </Flex.Center>
      {numberTwo && (
        <Flex.Center
          className={`${isNumeric(numberTwo) && colorSymbol(numberTwo, props.category)}-text character-text ${
            props.symbol.spreadBetResult && 'white-text'
          }`}
          style={
            props.symbol.spreadBetResult
              ? {
                  background: backgroundSymbol(props.symbol.spreadBetResult, props.category),
                }
              : {}
          }
        >
          {numberTwo}
        </Flex.Center>
      )}
    </div>
  );
};
