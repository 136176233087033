import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { Flex } from 'modules/casino/shared/components/layout';
import { UI } from 'modules/casino/shared/components/UI';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { selectAuthentication, showQuickLoginForm } from 'pages/auth/login/slice/login.slice';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch, useAppSelector } from 'store';
import { LOGIN_FORM_TYPE_MODAL } from 'utils/common/helpersCommon';
import { TopBarTypes } from './topBar.types';

export const TopBarButton: React.FC<TopBarTypes.ButtonType> = ({
  type,
  text,
  icon,
  counter,
  progress,
  showPopOver,
  activeDefault = false,
  disableActive,
  backgroundImage,
  checkIsAuthenticated = false,
  restrictForFreePlay = false,
  handleClick,
  handleClose,
  renderChildren,
  disabled = false,
}) => {
  const btnRef = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState(activeDefault);
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const dispatch = useAppDispatch();

  const triggerClick = () => {
    if (disabled) {
      //return;
    }

    active && handleClose && handleClose();
    handleClick && handleClick();
    if (checkIsAuthenticated || restrictForFreePlay) {
      if (checkIsAuthenticated && !isAuthenticated) {
        dispatch(showQuickLoginForm({ status: true, loginActionType: LOGIN_FORM_TYPE_MODAL }));
        return;
      }
      if (restrictForFreePlay) {
        dispatch(
          showRootModal({
            modalType: 'PAID_GAME_MODAL',
            modalPriority: ModalPriorityEnum.HIGH,
            modalId: 'PAID_GAME_MODAL',
            modalProps: { titlePhrase: 'newVersionText' },
          }),
        );

        return;
      }
    }

    setActive(!active);
  };

  const triggerClose = () => {
    if (active) {
      setActive(false);
      handleClose && handleClose();
    }
  };

  const Icon = !!icon && <UI.SVGCDN className={`${cssGlobalNS}-gl__btn-icon`} path="IN_GAME_BAR" icon={icon} />;

  const btnClasses = clsx(
    `${cssGlobalNS}-gl__btn`,
    type && `${cssGlobalNS}-gl__btn--${type}`,
    !disableActive && (showPopOver !== undefined ? showPopOver : true) && active && `${cssGlobalNS}-gl__btn--active`,
  );

  return (
    <>
      <Flex.Center onClick={triggerClick} className={btnClasses} ref={btnRef} style={backgroundImage}>
        {Boolean(counter) && <div className={'egtd-c-collect-count'}>{counter}</div>}

        {progress && (
          <div
            className={'egtd-c-collect-btn close-launch-popover'}
            style={progress?.background?.outer ? { backgroundImage: progress?.background?.outer } : undefined}
          >
            {progress?.background?.inner && (
              <div
                className={'egtd-c-collect-btn__prs'}
                style={{ width: `${Math.round(Number(progress.value))}%`, backgroundImage: progress.background.inner }}
              />
            )}
            <Flex.JB className={'egtd-c-collect-btn__percent'}>{Math.floor(Number(progress.value))}%</Flex.JB>
          </div>
        )}

        {!progress && Icon && Icon}

        {text && <div className={`${cssGlobalNS}-gl__btn-text`}>{text}</div>}
      </Flex.Center>

      {renderChildren &&
        renderChildren(btnRef, triggerClose, active && (showPopOver !== undefined ? showPopOver : true))}
    </>
  );
};
