import React from 'react';
import { useTranslation } from 'react-i18next';
import CurrencySwapper from 'modules/casino/shared/components/common/CurrencySwapper';
import { Flex } from 'modules/casino/shared/components/layout';
import { Text } from 'modules/casino/shared/components/text';
import { useCurrency } from 'modules/casino/shared/hooks/useCurrency';
import { useCurrencyConfig } from 'modules/casino/shared/hooks/useCurrencyConfig';
import { useAppSelector } from 'store';
import { JackpotOdometer } from '../../../jackpots/jackpotOdometer';
import { jackpotSelectors } from '../../../jackpots/jackpotSlice';
import { GameLaunchBonusTypes } from '../../types/gameLaunchBonus.types';
import { JackpotTranslations, jackpotPopOverPrefix } from '../../utils/gameLaunchBonusConstants';

type Props = {
  jackpotLevel: GameLaunchBonusTypes.JackpotLevel;
  jackpotId: string;
};

const JackpotCampaignLevel: React.FC<Props> = ({ jackpotLevel, jackpotId }) => {
  const { formatCurrency } = useCurrency();
  const { t } = useTranslation();
  const levelText = t(JackpotTranslations.level) || '';
  const maxWinText = t(JackpotTranslations.maxWin) || '';
  const multiplierText = t(JackpotTranslations.multiplier) || '';
  const currency = useAppSelector((state) => jackpotSelectors.selectJackpotCurrencySign(state, jackpotId));
  const symbol = formatCurrency(currency);
  const { currencySymbolFirst, spaceBetween } = useCurrencyConfig();

  const { levelDisplayName, level } = useAppSelector((state) =>
    jackpotSelectors.selectJackpotLevelInfo(state, jackpotId, jackpotLevel.jackpotLevelName),
  );

  return (
    <Flex.ASTART className={`${jackpotPopOverPrefix}__block`}>
      <Flex.Col>
        <div>
          {levelText} <span className={`${jackpotPopOverPrefix}__level`}>{levelDisplayName}</span>
        </div>
        <div className={`${jackpotPopOverPrefix}__odometer`}>
          <CurrencySwapper
            sumComponent={level ? <JackpotOdometer jackpotId={jackpotId} level={level} /> : <></>}
            currencyComponent={<span>{symbol}</span>}
            currencySymbolFirst={currencySymbolFirst}
            spaceBetween={spaceBetween}
          />
        </div>
        <Text text={`${maxWinText} ${jackpotLevel.maxBonusAmount || ''}`} />
      </Flex.Col>

      <Flex.Col className="ml-auto">
        <Text className={`${jackpotPopOverPrefix}-won--title`} text={multiplierText} />
        <Text className={`${jackpotPopOverPrefix}__multiplier ml-auto`} text={`${jackpotLevel.multiplier || ''}`} />
      </Flex.Col>
    </Flex.ASTART>
  );
};
export default JackpotCampaignLevel;
