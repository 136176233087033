import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { KeyboardReactInterface } from 'react-simple-keyboard';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { useOutsideClick } from 'hooks/common/useOutsideClick';
import {
  retailGamesSelectors,
  retailGamesThunks,
  setCurrentTicketPayData,
  setIsPayTicketModalVisible,
} from 'pages/retailGames/slice/retailGames.slice';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty, localLog } from 'utils/common/helpersCommon';
import { OmniPlayTypes } from '../types/omniPlay.types';
import { InputNames, InputNameType } from '../utils/payTicketModal.utils';

export const usePayTicketModalHandlers = () => {
  const currentTicketPayData = useAppSelector(retailGamesSelectors.currentTicketPayData);

  const [inputValueCNP, setInputValueCNP] = useState('');
  const [inputValueFirstName, setInputValueFirstName] = useState('');
  const [inputValueLastName, setInputValueLastName] = useState('');
  const [inputName, setInputName] = useState<InputNameType>('');
  const [cursorPosition, setCursorPosition] = useState(0);
  const [isShift, setIsShift] = useState<boolean>(false);

  const bodyRef = useRef<HTMLDivElement | null>(null);
  const cnpInputRef = useRef<HTMLInputElement | null>(null);
  const firstNameInputRef = useRef<HTMLInputElement | null>(null);
  const lastNameInputRef = useRef<HTMLInputElement | null>(null);
  const keyboardRef = useRef<KeyboardReactInterface | null>(null);

  const dispatch = useAppDispatch();

  const allGamesLaunchOptions = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.RETAIL_GAMES,
    deviceLayout: 'general',
  });

  const isDisabledSaveButton = isEmpty(inputValueCNP) || isEmpty(inputValueFirstName) || isEmpty(inputValueLastName);
  const currTicketPrefix = currentTicketPayData?.ticketCode?.slice(0, 2);
  const currentGame = allGamesLaunchOptions?.['omniPlay']?.find((data) => data.ticketCodePrefix === currTicketPrefix);
  const currGameCurrency = currentGame?.currency;
  const addToBalanceText =
    currentTicketPayData?.win && currGameCurrency ? `${currentTicketPayData?.win} ${currGameCurrency}` : '';
  const payTicketUrl = currentGame?.payTicketUrl || '';

  const onSubmit = () => {
    if (!isEmpty(currentTicketPayData)) {
      const payTicketRequestData: OmniPlayTypes.PayTicketRequestArgs = {
        personalId: inputValueCNP, // '6040709015238',
        firstName: inputValueFirstName, //'Michael',
        lastName: inputValueLastName, // 'Scott',
        session: currentTicketPayData.session,
        ticketCode: currentTicketPayData?.ticketCode,
        dispatch,
        payTicketUrl,
      };

      localLog({ message: `ℹ ~ Submit data: ${JSON.stringify(payTicketRequestData)}` });
      dispatch(retailGamesThunks.makePayTicketRequest(payTicketRequestData));
      dispatch(setIsPayTicketModalVisible(false));
      dispatch(setCurrentTicketPayData(null));
    }
  };

  const clearForm = useCallback(() => {
    setInputValueCNP('');
    setInputValueFirstName('');
    setInputValueLastName('');
    setInputName('');
    keyboardRef.current?.replaceInput({ cnpNums: '', firstName: '', lastName: '' });
  }, []);

  const closeModal = useCallback(() => {
    dispatch(hideRootModal());
    dispatch(setIsPayTicketModalVisible(false));
  }, [dispatch]);

  const onFocus = useCallback(
    (input: InputNameType) => () => {
      setInputName(input);
    },
    [],
  );

  const onChangeCNP = useCallback((input: string) => {
    const value = input.replace(/[^0-9]/g, ''); // Allow only numeric values
    setInputValueCNP(value);
    if (keyboardRef?.current?.caretPosition) {
      setCursorPosition(keyboardRef.current.caretPosition);
    }
  }, []);

  const onChangeFirstName = useCallback((input) => {
    setInputValueFirstName(input);
    if (keyboardRef?.current?.caretPosition) {
      setCursorPosition(keyboardRef.current.caretPosition);
    }
  }, []);

  const onChangeLastName = useCallback((input) => {
    setInputValueLastName(input);
    if (keyboardRef?.current?.caretPosition) {
      setCursorPosition(keyboardRef.current.caretPosition);
    }
  }, []);

  // when typing on react simple keyboard
  const handleKeyboardInput = useCallback(
    (input: string) => {
      switch (inputName) {
        case InputNames.cnpNums:
          onChangeCNP(input);
          break;
        case InputNames.firstName:
          onChangeFirstName(input);
          break;
        case InputNames.lastName:
          onChangeLastName(input);
          break;
        default:
          break;
      }
    },
    [inputName, onChangeCNP, onChangeFirstName, onChangeLastName],
  );

  // when typing on the physical keyboard
  const handleInputChange = useCallback(
    (input: InputNameType) => (e: ChangeEvent<HTMLInputElement>) => {
      const numericValue = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric values

      switch (input) {
        case InputNames.cnpNums:
          setInputValueCNP(numericValue);
          keyboardRef?.current?.setInput(numericValue);
          break;
        case InputNames.firstName:
          setInputValueFirstName(e.target.value);
          keyboardRef?.current?.setInput(e.target.value);
          break;
        case InputNames.lastName:
          setInputValueLastName(e.target.value);
          keyboardRef?.current?.setInput(e.target.value);

          break;
        default:
          break;
      }
    },
    [],
  );

  const handleShift = useCallback(
    (button: string) => {
      if (button === '{shift}') {
        setIsShift(!isShift);
      }
    },
    [isShift],
  );

  // this useEffect sets the correct cursor position when typing in between already existing characters
  // (prevents cursor jumping to the end of the input)
  useEffect(() => {
    if (inputName === InputNames.cnpNums) {
      cnpInputRef?.current?.setSelectionRange(cursorPosition, cursorPosition);
    }

    if (inputName === InputNames.firstName) {
      firstNameInputRef?.current?.setSelectionRange(cursorPosition, cursorPosition);
    }

    if (inputName === InputNames.lastName) {
      lastNameInputRef?.current?.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [cursorPosition, inputName]);

  useOutsideClick(bodyRef, closeModal);

  return {
    cnpInputRef,
    firstNameInputRef,
    lastNameInputRef,
    keyboardRef,
    inputName,
    inputValueCNP,
    inputValueFirstName,
    inputValueLastName,
    isDisabledSaveButton,
    isShift,
    onSubmit,
    onFocus,
    clearForm,
    closeModal,
    handleShift,
    handleKeyboardInput,
    handleInputChange,
    ticketCode: currentTicketPayData?.ticketCode,
    addToBalanceText,
  };
};
