import { WidgetInitProps } from './types/cashierWidgetTypes';

type CashierInitParams = {
  document: Document;
  src: string;
  callback: (config?: WidgetInitProps) => void;
  attr: string;
};

export const cashierScriptInit = ({ document, src, callback, attr }: CashierInitParams) => {
  const h = document.getElementsByTagName('head')[0];
  const s = document.createElement('script');
  s.onload = () => callback();
  s.src = src;
  s.setAttribute('n', attr);
  h.appendChild(s);
};
