import React, { useEffect } from 'react';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useTranslation } from 'react-i18next';
import { FormUI } from 'components/shared/forms/components';
import { Modal } from 'components/shared/Modal';
import { ModalUI } from 'components/shared/ModalUI';
import { UI } from 'components/shared/UI';
import { useShowMyProfileUntilUnmount } from 'hooks/common/useShowMyProfileUntilUnmount';
import useCasinoWidgetConfig from 'modules/casino/platformImplementation/hooks/useCasinoWidgetConfig';
import { fetchBonusGames } from 'modules/casino/shared/features/gameLaunchBonus/slice/gameLaunchBonus.slice';
import { refreshWidget } from 'shared/common/features/integrations/cashierWidget/cashierWidget.slice';
import { setRefetchBonusGames } from 'shared/common/features/integrations/slice/integrations.slice';
import {
  fetchCategories,
  postBonus,
  selectOffersTab,
} from 'shared/common/features/myProfile/sections/tabs/offersTab/slice/offersTab.slice';
import {
  Bonus,
  BonusButtonAction,
} from 'shared/common/features/myProfile/sections/tabs/offersTab/types/offersTab.types';
import { detectIOS } from 'shared/common/features/myProfile/utils/myProfile.utils';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { CustomPlatformEvents } from 'shared/common/hooks/useHandleCustomEvents';
import { useAppDispatch, useAppSelector } from 'store';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';

type Props = {
  bonus: Bonus;
};

const AcceptOfferModal: React.FC<Props> = ({ bonus }) => {
  const dispatch = useAppDispatch();
  const { status, error } = useAppSelector((state) => selectOffersTab.bonusStatusAndError(state, bonus.id));
  const { t } = useTranslation();
  useShowMyProfileUntilUnmount();
  const casinoWidgetConfig = useCasinoWidgetConfig();

  const acceptBtn = bonus.buttons?.find(
    (button) =>
      button.buttonActionType === BonusButtonAction.Navigate || button.buttonActionType === BonusButtonAction.Accept,
  );

  const handleOfferAction = async () => {
    dispatch(hideRootModal());
    if (!acceptBtn?.buttonUrl) return;

    await dispatch(
      postBonus({
        buttonUrl: acceptBtn.buttonUrl,
        id: `${bonus.id}`,
        bonusCode: bonus.bonusCode,
      }),
    );
    dispatch(fetchCategories());
    dispatch(refreshWidget());
    document.dispatchEvent(new Event(CustomPlatformEvents.FETCH_SPORT_BONUSES));

    if (casinoWidgetConfig) {
      dispatch(setRefetchBonusGames(true));
    } else {
      dispatch(fetchBonusGames());
    }
  };
  const isIOS = detectIOS();

  useEffect(() => {
    const scrollTop = window.pageYOffset;
    if (isIOS) {
      document.body.classList.add('no-scroll-fixed');
      clearAllBodyScrollLocks();
    }

    return () => {
      if (isIOS) {
        document.body.classList.remove('no-scroll-fixed');
        window.scrollTo({ behavior: 'smooth', top: scrollTop });
      }
    };
  }, []);

  return (
    <Modal visible className="modal-terms" size="terms">
      <Modal.Close closeText={t(MyProfile.OffersTab.closeBtn)} closeAction={() => dispatch(hideRootModal())} />
      <Modal.Body className="d-flex-col-ac p-4 text-center custom-scrollbars">
        <ModalUI.Title text={bonus.title} />
        <ModalUI.Title hasAccent text={t(MyProfile.OffersTab.acceptConfirmText)} />
        {status === 'failure' && error ? (
          <FormUI.Message hasFormGroup type="error" content={t(error)} />
        ) : (
          <UI.Button
            className="mt-4"
            size="lg"
            isDisabled={status === 'pending'}
            onClick={handleOfferAction}
            text={t(MyProfile.OffersTab.confirmBtn)}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AcceptOfferModal;
