import React from 'react';
import clsx from 'clsx';

interface TextProps {
  variant: 'primary' | 'secondary';
  text?: string;
  isTruncated?: boolean;
  children?: React.ReactNode;
  dataQid?: string;
}

const Text: React.FC<TextProps> = ({ variant, text, isTruncated = true, children, dataQid }) => {
  const textClass = clsx(isTruncated && 'text-truncate', `mpd-section__text--${variant}`);
  return (
    <span className={textClass} data-qid={dataQid}>
      {text}
      {children}
    </span>
  );
};

export default Text;
