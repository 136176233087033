import styled from 'styled-components';

export const SCContent = styled.div.attrs({ className: 'd-flex-col custom-scrollbars' })`
  flex-shrink: 1;
  flex-grow: 0;
  flex-basis: 100%;

  height: 100%;
  overflow: auto;
`;
