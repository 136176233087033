import 'react-simple-keyboard/build/css/index.css';
import { useTranslation } from 'react-i18next';
import Keyboard from 'react-simple-keyboard';
import { Flex } from 'components/shared/layout';
import { UI } from 'components/shared/UI';
import Close from 'modules/retail/modules/cashier/cashierModalUI/Close';
import InputsWrapper from 'modules/retail/modules/cashier/cashierModalUI/InputsWrapper';
import { TerminalModalUI } from 'modules/retail/modules/ssbt/components/ssbtModalUI';
import Input from 'modules/retail/modules/ssbt/components/ssbtModalUI/Input';
import { retailGamesSelectors } from 'pages/retailGames/slice/retailGames.slice';
import { useAppSelector } from 'store';
import { CashierTranslations, SSBT } from 'utils/common/translationUtils/translationStrings';
import { usePayTicketModalHandlers } from '../hooks/usePayTicketModalHandlers';
import { InputNames, keyboardDisplay, keyBoardLayouts, KeyboardLayoutTypes } from '../utils/payTicketModal.utils';

const OmniPlayPayTicketDataModal = () => {
  const isPayTicketDataModalVisible = useAppSelector(retailGamesSelectors.isPayTicketDataModalVisible);
  const {
    cnpInputRef,
    firstNameInputRef,
    lastNameInputRef,
    keyboardRef,
    isShift,
    isDisabledSaveButton,
    inputName,
    inputValueCNP,
    inputValueFirstName,
    inputValueLastName,
    ticketCode,
    clearForm,
    closeModal,
    handleShift,
    onSubmit,
    handleKeyboardInput,
    onFocus,
    handleInputChange,
    addToBalanceText,
  } = usePayTicketModalHandlers();
  const { t } = useTranslation();

  return !isPayTicketDataModalVisible ? null : (
    <TerminalModalUI.Wrapper isVisible={true} checkTicket>
      <Flex.JE className={'cashier-modal__header__payTicket'}>
        <Close text={t(SSBT.TerminalModal.closeTerminalModalText)} onClick={closeModal} />
      </Flex.JE>
      <div className="terminal-ticket__ucn-wrapper">
        <div className="terminal-ticket__ucn-form">
          <Input
            id={'inputTicketNumber'}
            value={ticketCode}
            placeholder={'Ticket ID'}
            readOnly
            label={'Ticket ID'}
            spanAllColumns
          />
          <InputsWrapper spanAllColumns>
            <Input
              ref={(r) => (cnpInputRef.current = r)}
              id={InputNames.cnpNums}
              value={inputValueCNP}
              placeholder={''}
              onFocus={onFocus(InputNames.cnpNums)}
              onChange={handleInputChange(InputNames.cnpNums)} // from physical keyboard
              label={t(CashierTranslations.CashPayModal.ucn)}
              spanAllColumns
              inputMode={'numeric'}
              pattern={'[0-9]*'}
            />
          </InputsWrapper>

          <Input
            ref={(r) => (firstNameInputRef.current = r)}
            id={InputNames.firstName}
            value={inputValueFirstName}
            placeholder={''}
            onFocus={onFocus(InputNames.firstName)}
            onChange={handleInputChange(InputNames.firstName)} // from physical keyboard
            label={t(CashierTranslations.CashPayModal.firstName)}
            spanAllColumns={false}
          />

          <Input
            ref={(r) => (lastNameInputRef.current = r)}
            id={InputNames.lastName}
            value={inputValueLastName}
            placeholder={''}
            onFocus={onFocus(InputNames.lastName)}
            onChange={handleInputChange(InputNames.lastName)} // from physical keyboard
            label={t(CashierTranslations.CashPayModal.lastName)}
            wrapperClassName="half"
            spanAllColumns={false}
          />

          <div className={'cashier-modal__payTicketBtns-wrapper span-all-cols'}>
            <UI.Button
              text={`${t(CashierTranslations.CashPayModal.addToBalance)} ${addToBalanceText}`}
              variant="primary"
              size="2xl"
              onClick={onSubmit}
              isDisabled={isDisabledSaveButton}
            />
            <UI.Button
              text={t(CashierTranslations.CashPayModal.clearButton)}
              variant="secondary"
              size="2xl"
              onClick={clearForm}
            />
          </div>
        </div>
      </div>
      <div className="cashier-keyboard">
        <Keyboard
          keyboardRef={(r) => (keyboardRef.current = r)}
          onChange={(input) => handleKeyboardInput(input)} // updates inputs while typing
          onKeyPress={handleShift}
          inputName={inputName}
          preventMouseDownDefault={true} // Prevents loss of input focus from clicking simple-keyboard buttons
          theme="hg-theme-default"
          layoutName={isShift ? KeyboardLayoutTypes.shift : KeyboardLayoutTypes.default}
          layout={keyBoardLayouts}
          display={keyboardDisplay}
        />
      </div>
    </TerminalModalUI.Wrapper>
  );
};

export default OmniPlayPayTicketDataModal;
