import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CasinoGamesTypes } from 'modules/casino/modules/container/cellsContainer/cellls/types/casinoGamesTypes';
import { RequestStatuses } from 'modules/casino/shared/constants';
import {
  DEFAULT_GAME_ID_LOBBY,
  SVARA_LAUNCH_GAME_ID,
} from 'modules/casino/shared/features/gameLaunch/casinoLaunchConstants';
import axiosInstance from 'modules/casino/shared/utils/common/axios-instance';
import { getBusinessUnit, getDeviceType } from 'modules/casino/shared/utils/common/helpersCommon';
// import { getAggregatorLocalUrl } from 'modules/casino/features/gameLaunch/casinoLaunchUtils';
import { PlayerRestrictions } from 'pages/myAccount/tabs/responsibleGambling/types/responsibleGambling.types';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { AppDispatch } from 'store';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import config from 'utils/config';
import {
  ApiSvaraData,
  CasinoLaunchGameTypes,
  RequestData,
  ResponseData,
  SvaraLaunchPathParams,
} from './casinoLaunchTypes';

const initialState: CasinoLaunchGameTypes.State = {
  launchData: null,
  gameData: null,
  launchInProcess: false,
  launchTimestamp: null,
  showComponents: true,
  launchStatus: RequestStatuses.INITIAL,
  openGameData: null,
  isProviderWithLobby: false,
  providerInfoReceived: false,
  newGameIdReceived: false,
  isInGameLaunch: false,
};

export const launchGameThunk: CasinoLaunchGameTypes.LaunchGameThunk = {
  fetchLaunchData: createAbortThunk('launchGame/fetchLaunchData', async (params, { getState }) => {
    const hasRealityCheckSession = Boolean(selectGeneral.buSettings(getState())?.regulationCasinoGameSettings);
    const requestData = generateRequestApiData({ ...params, hasRealityCheckSession });
    const response = await fetchLaunchUrl(requestData);

    //Uncomment this when needed to use the aggregator in localhost and replace launchUrl with aggregatorLocalUrl:
    //const aggregatorLocalUrl = getAggregatorLocalUrl(response.data.launchUrl);

    return {
      launchUrl: !hasRealityCheckSession ? response.data : response.data.launchUrl,
      gameId: params.gameId,
      providerAlias: params.providerAlias,
      isFreePlay: params.isFreePlay,
      agent: params.agent,
      closeUrl: params.closeUrl,
      history: params.history,
      realityCheckSessionData: !hasRealityCheckSession ? undefined : response.data.data,
    } as CasinoLaunchGameTypes.LaunchData;
  }),
  fetchGameData: createAbortThunk('launchGame/fetchGameData', async ({ gameId }) => {
    const gameUrl = `/api/gaming/public/bonus/gameIds?workingBusinessUnit=${getBusinessUnit()}&gameIds=${gameId}`;
    // const gameUrl = `/api/gaming/public/bonus/gameIds?workingBusinessUnit=IBBG&gameIds=${gameId}`; //TODO: uncomment to test locally
    const { data } = await axiosInstance.get<CasinoGamesTypes.GameProps[]>(`${config.API_URL}${gameUrl}`);

    return { data };
  }),
};

const casinoGameLauncher = createSlice({
  name: 'CasinоGameLauncher',
  initialState,
  reducers: {
    resetLaunchGameData(state) {
      state.launchData = null;
      state.providerInfoReceived = false;
      state.isProviderWithLobby = false;
      state.newGameIdReceived = false;
    },
    setNewLaunchGameId(state, action: PayloadAction<string>) {
      if (state.launchData) {
        state.launchData.gameId = action.payload;
        if (state.launchData.gameId !== DEFAULT_GAME_ID_LOBBY) {
          state.newGameIdReceived = true;
        }
      }
    },
    setShowComponents(state, action) {
      state.showComponents = action.payload;
    },
    setLaunchStatus(state, action: PayloadAction<RequestStatuses>) {
      state.launchStatus = action.payload;
    },
    setOpenGameData(state, action: PayloadAction<CasinoLaunchGameTypes.OpenGameData | null>) {
      state.openGameData = action.payload;
    },
    resetOpenGameData(state) {
      state.openGameData = null;
    },
    setLaunchInProcess(state, action: PayloadAction<boolean>) {
      state.launchInProcess = action.payload;
    },
    setIsProviderWithLobby(state, action: PayloadAction<boolean>) {
      state.isProviderWithLobby = action.payload;
      state.providerInfoReceived = true;
    },
    setGameBetType(state, action: PayloadAction<PlayerRestrictions.ProductType>) {
      state.gameBetType = action.payload;
    },
    setIsInGameLaunch(state, action: PayloadAction<boolean>) {
      state.isInGameLaunch = action.payload;
    },
    setLaunchTimestamp(state, action: PayloadAction<number>) {
      state.launchTimestamp = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(launchGameThunk.fetchLaunchData.fulfilled, (state, action) => {
        state.launchData = action.payload;
        state.launchStatus = RequestStatuses.SUCCESS;
      })
      .addCase(launchGameThunk.fetchLaunchData.rejected, (state) => {
        state.launchStatus = RequestStatuses.ERROR;
      })
      .addCase(launchGameThunk.fetchLaunchData.pending, (state) => {
        state.launchStatus = RequestStatuses.PENDING;
      });
    builder
      .addCase(launchGameThunk.fetchGameData.fulfilled, (state, action) => {
        state.gameData = action.payload;
      })
      .addCase(launchGameThunk.fetchGameData.pending, (state) => {
        state.gameData = null;
      });
  },
});

export const {
  resetLaunchGameData,
  setNewLaunchGameId,
  setLaunchStatus,
  setShowComponents,
  setOpenGameData,
  resetOpenGameData,
  setLaunchInProcess,
  setIsProviderWithLobby,
  setGameBetType,
  setIsInGameLaunch,
  setLaunchTimestamp,
} = casinoGameLauncher.actions;
const selectLaunchData = (state: RootState): CasinoLaunchGameTypes.LaunchData | null =>
  state.casino.casinoGameLauncher.launchData;
const selectShowComponents = (state: RootState): boolean => state.casino.casinoGameLauncher.showComponents;
const selectLaunchStatus = (state: RootState): RequestStatuses => state.casino.casinoGameLauncher.launchStatus;
const selectOpenGameData = (state: RootState): CasinoLaunchGameTypes.OpenGameData | null =>
  state.casino.casinoGameLauncher.openGameData;
const SelectLaunchInProccess = (state: RootState): boolean | null => state.casino.casinoGameLauncher.launchInProcess;
const selectIsProviderWithLobby = (state: RootState): boolean => state.casino.casinoGameLauncher.isProviderWithLobby;
const selectIsInGameLaunch = (state: RootState) => state.casino.casinoGameLauncher.isInGameLaunch;
const selectNewGameIdReceived = (state: RootState): boolean => state.casino.casinoGameLauncher.newGameIdReceived;
const selectProviderInfoReceived = (state: RootState): boolean => state.casino.casinoGameLauncher.providerInfoReceived;
const selectLaunchGameBetType = (state: RootState) => state.casino.casinoGameLauncher.gameBetType;
const selectLaunchTimestamp = (state: RootState) => state.casino.casinoGameLauncher.launchTimestamp;
const selectLaunchGameData = (state: RootState): CasinoLaunchGameTypes.GameData | null =>
  state.casino.casinoGameLauncher.gameData;

export const casinoLaunchSelectors = {
  launchData: selectLaunchData,
  launchInProcess: SelectLaunchInProccess,
  launchTimestamp: selectLaunchTimestamp,
  showComponents: selectShowComponents,
  launchStatus: selectLaunchStatus,
  openGameData: selectOpenGameData,
  isProviderWithLobby: selectIsProviderWithLobby,
  isNewGameIdReceived: selectNewGameIdReceived,
  isInGameLaunch: selectIsInGameLaunch,
  providerInfoReceived: selectProviderInfoReceived,
  getGameBetType: selectLaunchGameBetType,
  getGameData: selectLaunchGameData,
};

export const resetLaunchData = () => (dispatch: AppDispatch) => {
  dispatch(resetLaunchGameData());
  dispatch(setLaunchStatus(RequestStatuses.INITIAL));
};

const generateRequestApiData = ({
  gameId,
  isFreePlay,
  closeUrl,
  svara,
  depositUrl,
  hasRealityCheckSession,
}: {
  gameId: string;
  isFreePlay: boolean;
  closeUrl: string;
  svara?: string;
  depositUrl: string;
  hasRealityCheckSession?: boolean;
}): RequestData => {
  const svaraBodyParams = getSvaraBodyParam(gameId, svara);
  return {
    fetchUrl: `${config.API_URL}/api/gaming/aggregator/platform/gamelaunch${hasRealityCheckSession ? 'extended' : ''}/`,
    data: {
      ...svaraBodyParams,
      gameid: svara ? SVARA_LAUNCH_GAME_ID : gameId,
      freeplay: isFreePlay,
      closeurl: closeUrl,
      depositurl: depositUrl,
    },
  };
};

const getSvaraBodyParam = (gameId: string, svaraType?: string): ApiSvaraData => {
  const svaraLaunchParams = {
    [SvaraLaunchPathParams.private]: { privateCode: gameId },
    [SvaraLaunchPathParams.create]: { action: 'createTable' },
    [SvaraLaunchPathParams.paid]: { tableId: gameId },
    [SvaraLaunchPathParams.lobby]: {},
  };
  return svaraType ? svaraLaunchParams[svaraType] : {};
};
const fetchLaunchUrl = async (requestData: RequestData) => {
  const response = await axiosInstance.post<ResponseData>(requestData.fetchUrl, requestData.data, {
    headers: {
      'X-Platform-Origin': getBusinessUnit(),
      'X-Platform-Device': getDeviceType(),
    },
  });

  return response;
};

export default casinoGameLauncher.reducer;
