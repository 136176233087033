import { useEffect } from 'react';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { CustomPlatformEvents } from 'shared/common/hooks/useHandleCustomEvents';
import { useAppDispatch } from 'store';

export const useConnectionLostHandler = (): void => {
  const dispatch = useAppDispatch();
  const currentpath = window.location.pathname;
  useEffect(() => {
    if (!window.isRNWebView && currentpath !== '/sports/live-program') {
      const connectionLostHandler = () => {
        document.dispatchEvent(new Event(CustomPlatformEvents.HIGH_PRIORITY_MODAL));
        dispatch(
          showRootModal({
            modalType: 'CONNECTION_LOST',
            modalPriority: ModalPriorityEnum.HIGH,
            modalProps: { titlePhrase: 'connectionLostText' },
            modalId: 'CONNECTION_LOST',
          }),
        );
      };
      window.addEventListener('offline', connectionLostHandler);
      return () => {
        window.removeEventListener('offline', connectionLostHandler);
      };
    }
  }, [dispatch]);
};
