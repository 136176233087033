import { Location } from 'history';
import { PathMatch } from 'react-router-dom';
import { Subvertical } from 'shared/common/features/navigationMenu/types/navigationMenu.types';

interface NavItem {
  name: string;
  enabled: string;
  target: string;
  id: number;
  route: string;
  translation: string;
  subverticals: Subvertical[];
}
export interface NavProps {
  navItem: NavItem;
}

export const isActive = (
  route: string,
  sportsMatch: PathMatch | null,
  location: Location,
  tabType?: string,
): boolean => {
  let isActiveItem = false;
  const isSpecailTournaments = tabType === 'specialTournament';
  if (route) {
    if (route === location?.pathname && (isSpecailTournaments || !location?.pathname.includes('/sports/special/'))) {
      isActiveItem = true;
    } else {
      if (sportsMatch) {
        if (
          (sportsMatch?.params.params === 'in-play' && route !== '/esports') ||
          sportsMatch?.params.params === 'search' ||
          sportsMatch?.params.params === 'favourites' ||
          sportsMatch?.pattern?.path === '/promo/:params/*'
        ) {
          isActiveItem = false;
        } else {
          isActiveItem = true;
        }
      }
    }
  }
  return isActiveItem;
};
