import React, { memo } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { InGameModalTypes } from './modal.types';
import { hideRootCasinoModal, selectRootCasinoModal } from './rootCasinoModal.slice';
import useHandleCustomEvents, { CustomPlatformEvents } from '../../hooks/useHandleCustomEvents';
import QuickDepositModal from '../gameLaunchBonus/topBar/quickDeposit/QuickDepositModal';
import JackpotWinDetailsModal from '../jackpots/components/JackpotWinDetailsModal';

/* eslint-disable @typescript-eslint/no-explicit-any */
const IN_GAME_MODAL_COMPONENTS: Record<InGameModalTypes, React.FC<any>> = {
  JACKPOT_WIN_DETAILS: JackpotWinDetailsModal,
  QUICK_DEPOSIT_MODAL: QuickDepositModal,
};

const InGameModal = () => {
  const modalType = useAppSelector(selectRootCasinoModal.type);
  const modalProps = useAppSelector(selectRootCasinoModal.props);
  const body = document.body;

  const dispatch = useAppDispatch();

  const eventHandler = () => dispatch(hideRootCasinoModal());

  useHandleCustomEvents(CustomPlatformEvents.HIGH_PRIORITY_MODAL, eventHandler);

  if (!modalType) {
    body.classList.remove('no-scroll');
    return null;
  } else {
    body.classList.add('no-scroll');
  }

  const SpecificModal = IN_GAME_MODAL_COMPONENTS[modalType];
  return SpecificModal ? <SpecificModal {...modalProps} /> : null;
};

export default memo(InGameModal);
