import React from 'react';

interface ReprintTicketWrapProps {
  children?: React.ReactNode;
}

const ReprintTicketWrap: React.FC<ReprintTicketWrapProps> = ({ children }) => {
  return <egtd-reprint-ticket-wrapper>{children}</egtd-reprint-ticket-wrapper>;
};

export default ReprintTicketWrap;
