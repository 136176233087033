import React, { Fragment, memo } from 'react';
import uniqueId from 'lodash/uniqueId';
import { Flex } from 'components/shared/layout';

const TickerSkeleton = () => {
  const renderItems = (number: number, el: React.ReactNode) => {
    const elements: React.ReactNode[] = [];
    for (let index = 0; index < number; index++) {
      elements.push(<Fragment key={uniqueId('ticker-skl')}>{el}</Fragment>);
    }
    return elements;
  };

  const skeletonCount = Math.round(window.innerWidth / 250) + 1;
  return (
    <Flex className="ticker-wrapper ticker-wrapper-skeleton">
      <Flex className="ticker">
        {renderItems(
          skeletonCount,
          <div className="ticker-item ticker-item-skeleton">
            <div className="ticker-item-skeleton__line" style={{ width: '100%' }} />
          </div>,
        )}
      </Flex>
    </Flex>
  );
};

export default memo(TickerSkeleton);
