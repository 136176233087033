import { LOGIN_FORM_TYPE_MODAL } from 'modules/casino/shared/utils/common/helpersCommon';
import { selectAuthentication, showQuickLoginForm } from 'pages/auth/login/slice/login.slice';
import { OpenLaunchTypes } from '../../casinoLaunchConstants';
import { GamePlayType } from '../../casinoLaunchTypes';

const LoginInterceptor =
  ({ gamePlayType, continueExec, callback, closeGame, openLaunchTypes }) =>
  async (dispatch, getState) => {
    const openLoginForm = (loginFormParams) => {
      dispatch(showQuickLoginForm(loginFormParams));
    };

    return new Promise((resolve, reject) => {
      const isAuthenticated = selectAuthentication.isAuthenticated(getState());
      if (!isAuthenticated && gamePlayType === GamePlayType.PAID && !continueExec) {
        const navigationOptions = {
          callback: callback,
          exit: closeGame,
        };

        let loginFormParams = {
          status: true,
          loginActionType: LOGIN_FORM_TYPE_MODAL,
        };

        if (openLaunchTypes == OpenLaunchTypes.OPENLAUNCHER) {
          loginFormParams = { ...loginFormParams, ...{ navigationOptions } };
        }
        return reject(openLoginForm(loginFormParams));
      }
      return resolve(true);
    });
  };

export const FakeInterceptor =
  ({ stopped }) =>
  async () => {
    return new Promise((resolve, reject) => {
      if (stopped) {
        return reject(false);
      }

      return resolve(true);
    });
  };

export default LoginInterceptor;
