import config from 'utils/config';

export const uiCSSPrefix = 'egtd-x';

export const cdnUrl = config.CDN_URL;

export enum cdnPaths {
  PRIZE_DROP = '/common-content/prize-drop/',
  FLAGS = '/sport-content/flags',
  CASINO_MENU_ICONS = '/gaming-content/menu-icons/',
}
