import React from 'react';
import clsx from 'clsx';

/** EventUI Market Change */

/** Component CSS NameSpace */
const cssNS = 'event-market';

const marketChangeLocationsCSSMap = {
  eventBody: `${cssNS}--event-body`,
  sportsSlider: `${cssNS}--sports-slider`,
} as const;

type MarketChangeLocationsTypes = keyof typeof marketChangeLocationsCSSMap;

interface MarketChangeProps {
  text: string;
  componentLocation: MarketChangeLocationsTypes;
}

export const MarketChange: React.FC<MarketChangeProps> = ({ text, componentLocation }) => {
  const marketChangeClass = clsx(cssNS, 'd-flex-center w-100', marketChangeLocationsCSSMap[componentLocation]);

  return <div className={marketChangeClass}>{text}</div>;
};
