/**
 *  valid: 3.100 5.000 444.800
 *  invalid: 2 4. 55 63 751 7.40 5.00
 */
export const singleZeroInsteadOfDoublePattern = /^[0-9]+\.[0-9]00$/;

/**
 *  valid: 12345 44412 2 24 24. 24.5 24.56 0.01 0 0. 01
 *  invalid: 0 01 123455 12.333 43431231221.12
 */
const finalInputValidationPattern = /^[0-9]{0,7}(\.[0-9]{0,2})?$/;
export const isValidBetslipInput = (amount: string): boolean => {
  return amount === '' ? true : finalInputValidationPattern.test(amount);
};

export const buttonKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '00', '.'] as const;
// eslint-disable-next-line prettier/prettier
export type ButtonKeys = (typeof buttonKeys)[number]; //constructs union string type of buttonKeys
type ButtonFunc = (input: string, updateFunc: (inputInfo: string) => void) => string;

/**
 *  valid: 01 05 07 04
 *  invalid: 0 1 01. 04.5
 */
const leadingZeroPattern = /^0[1-9]$/;
const generalKeyFormatter: ButtonFunc = (input, updateFunc) => {
  if (leadingZeroPattern.test(input)) {
    input = input[1]; // removes the leading 0 e.g 05 => 5
  }
  updateFunc(input);
  return input;
};

export const keyFormatFuncMap: Record<ButtonKeys | '', ButtonFunc> = {
  '1': generalKeyFormatter,
  '2': generalKeyFormatter,
  '3': generalKeyFormatter,
  '4': generalKeyFormatter,
  '5': generalKeyFormatter,
  '6': generalKeyFormatter,
  '7': generalKeyFormatter,
  '8': generalKeyFormatter,
  '9': generalKeyFormatter,
  '.': (input, updateFunc) => {
    if (input === '.') {
      input = '0.';
    }
    updateFunc(input);
    return input;
  },
  '00': (input, updateFunc) => {
    if (singleZeroInsteadOfDoublePattern.test(input)) {
      input = input.slice(0, input.length - 1); //removes the last zero e.g 5.000 => 5.00
    } else if (input === '00') {
      input = '0';
    } else if (input === '0' || input === '000') {
      return input;
    }
    updateFunc(input);
    return input;
  },
  '0': (input, updateFunc) => {
    if (input === '00') {
      return input;
    }
    updateFunc(input);
    return input;
  },
  '': (input, updateFunc) => {
    if (input === '') {
      updateFunc(input);
    }
    return input;
  },
};

/**
 *  valid: 2210.0 22.1 22. 55. 34.6 44.0
 *  invalid: 22.11 23 4
 */
const formatPattern = /^[0-9]+\.[0-9]?$/;
const shouldFormat = (input: string): boolean => {
  if (formatPattern.test(input)) {
    return true;
  }
  return false;
};

const formatInput = (input: string): string => {
  if (shouldFormat(input)) {
    return Number(input).toFixed(2);
  }
  return input;
};

export const formatOnBlur = (input: string, dispatchFunc: (inputInfo: string) => void) => {
  const formatted = formatInput(input);
  if (formatted !== input) {
    dispatchFunc(formatted);
  }
};

export const isAllowedKey = (key: string | ButtonKeys): key is ButtonKeys => {
  return keyFormatFuncMap[key] ? true : false;
};

const getLastPhysicalKey = (input: string) => {
  if (input === '') {
    return '';
  }
  return input.slice(input.length - 1, input.length);
};

const handleInput = (key: string, props) => {
  props.input = keyFormatFuncMap[key](props.input, props.updateFunc);
};

export const handlePhysicalInput = (props) => {
  const physicalKey = getLastPhysicalKey(props.input);
  if (isAllowedKey(physicalKey)) {
    handleInput(physicalKey, props);
  }
};

export const handleVirtualInput = (key: ButtonKeys, props) => handleInput(key, props);

// Functions for formatting dates from and for API //
//This function pads a number with leading zeros to ensure it has at least two digits
export const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
};
//Format a date string for API requests (dd/MM/yyyy to yyyy-MM-dd)
export const formatForAPI = (date) => {
  const dateObj = new Date(date);
  if (isNaN(dateObj.getTime())) {
    return '0000/00/00';
  }
  return [dateObj.getFullYear(), padTo2Digits(dateObj.getMonth() + 1), padTo2Digits(dateObj.getDate())].join('-');
};
//Format a date range from API response to display (yyyy-MM-dd to dd/MM/yyyy)
export const formatFromAPI = (dateRange: string): string => {
  const [startDate, endDate] = dateRange
    .toString()
    .split(' -')
    .map((datePart) => {
      if (datePart) {
        const [date] = datePart.trim().split(' ');
        const [month, day, year] = date.split('/').map((part) => part.padStart(2, '0'));
        return `${day}/${month}/${year}`;
      }
      return '00/00/0000';
    });
  return `${startDate} - ${endDate}`;
};

export const currentDateISO = () => {
  const currentDate = new Date();
  const formattedDateTime = currentDate.toISOString();

  return formattedDateTime;
};

export const formatCustomDate = (dateString, t) => {
  if (!dateString) {
    return t('Cashier.Anex2.invalidDate');
  }
  const [datePart, timePart] = dateString.split('T');
  if (!datePart || !timePart) {
    return t('Cashier.Anex2.invalidDate');
  }

  const [day, month, year] = datePart.split('/');
  if (!day || !month || !year) {
    return t('Cashier.Anex2.invalidDate');
  }
  const formattedTimePart = timePart.endsWith('Z') ? timePart.slice(0, -1) : timePart;
  return `${day}-${month}-${year} ${formattedTimePart}`;
};
export const formatPeriod = (periodString) => {
  if (!periodString) {
    return;
  }

  const parts = periodString.split(' - ');
  if (parts.length !== 2) {
    return;
  }

  const startDatePart = parts[0];
  const dateComponents = startDatePart.split('/');

  if (dateComponents.length !== 3) {
    return;
  }

  const [day, month, year] = dateComponents;

  const pad = (num) => String(num).padStart(2, '0');

  const resultDate = `${pad(day)}-${pad(month)}-${year}`;

  return resultDate;
};

// Functions for formatting dates from and for API - END //
