import React from 'react';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { CategoryLayoutTypes } from '../../types/CategoryLayoutTypes';

interface Props {
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
}

const LobbySubtitle: React.FC<Props> = ({ categoryId, categoryLayoutType }) => {
  const { subTitle, subTitleColor, showSubTitle } = useGetCategoryParams(categoryId, categoryLayoutType);
  const styleType = categoryLayoutType.toLowerCase();
  if (!subTitle || !showSubTitle) return <></>;
  return (
    <span className={`${styleType}-category-header__subtitle`} style={{ color: subTitleColor }}>
      {subTitle}
    </span>
  );
};

export default LobbySubtitle;
