import { useCallback, useState } from 'react';

export const useOnOff = (initialState = false) => {
  const [state, setState] = useState(initialState);
  const handlesToggle = useCallback((changeState?: boolean) => {
    setState((currentState) => (changeState === undefined ? !currentState : changeState));
  }, []);

  return [state, handlesToggle] as const;
};
