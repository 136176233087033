import styled from 'styled-components';

const GameCellWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
  .cell-tags__triangle {
    display: none;
  }
`;
export default GameCellWrapperStyled;
GameCellWrapperStyled.displayName = 'GameCellWrapperStyled';
