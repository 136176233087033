import React from 'react';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { Icon } from 'modules/casino/shared/components/icons';
import { Flex } from 'modules/casino/shared/components/layout';
import { CASINO_SEARCH_ROUTE } from 'modules/casino/shared/features/casinoSearch/casinoSearchConstants';
import {
  casinoSearchSelectors,
  setProviderSearchIsActive,
} from 'modules/casino/shared/features/casinoSearch/casinoSearchSlice';
import { useAppDispatch, useAppSelector } from 'store';
import { useProviderDataByAlias } from '../../hooks/useProviderDataByAlias';
import { addFilteredProviderId, gameProvidersSelectors } from '../../slice/casinoGameProvidersSlice';

/** ProvidersBarUI SearchIcon */
const SearchIcon: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const activeProviderAlias = useAppSelector(gameProvidersSelectors.activeProvider);
  const { id: providerId } = useProviderDataByAlias(activeProviderAlias);
  const providerSearchIsActive = useAppSelector(casinoSearchSelectors.providerSearchIsActive);

  const handleButtonClick = () => {
    if (providerSearchIsActive) {
      dispatch(setProviderSearchIsActive(false));
      return;
    }

    dispatch(addFilteredProviderId(providerId));

    isMobile ? navigate(CASINO_SEARCH_ROUTE) : dispatch(setProviderSearchIsActive(true));
  };

  const isSearchTabVisible = useAppSelector(gameProvidersSelectors.searchGameAvailability);
  const iconWrapperClass = clsx(
    'providers-search__icon-wrapper',
    providerSearchIsActive && 'providers-search__icon-wrapper--active',
  );

  if (!isSearchTabVisible) return null;

  return (
    <div className="providers-search__wrapper">
      <Flex.Center className={iconWrapperClass} onClick={handleButtonClick}>
        <Icon className="providers-search__icon" icon="search-alt" />
      </Flex.Center>
    </div>
  );
};

export default SearchIcon;
