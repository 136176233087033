import { useMemo } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { SPORT_TYPE_LOBBY_SCROLL_EVENT } from 'components/shared/ScrollContainer';
import { loteriaPopularData } from 'pages/liveGames/components/providers/Lema/loteriaPopulatConstants';
import { useAppDispatch } from 'store';
import { combineNameAndId, getSportIdNameFormat } from './utils/navigation.utils';

type NavigationOptions = {
  state?;
  replaceCurrentHistory?: boolean;
  noRerender?: boolean;
};

type CustomNavigateProps = {
  path: string;
  query?: string;
  navigate: NavigateFunction;
  options?: NavigationOptions;
};

const customNavigate = ({ options, navigate, path, query }: CustomNavigateProps) => {
  const fullPath = `${path}${query ? query : ''}`;
  //console.log(' ~ customNavigate ~ fullPath', fullPath, ' ');

  if (!options?.replaceCurrentHistory) {
    window.history.pushState({}, '', fullPath);
  }

  if (!options?.noRerender) {
    navigate(fullPath, {
      ...options, //state between routes is here
      ...{
        replace: true,
      },
    });
  }
};

export const useNavigateTo = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const navigateTo = useMemo(
    () => ({
      goBack(back = -1) {
        navigate(back);
      },
      //
      //
      navigate(path: string, options?: NavigationOptions) {
        customNavigate({ path, navigate, options });
        if (path === '/sports' || path === '/esports') {
          document.dispatchEvent(new Event(SPORT_TYPE_LOBBY_SCROLL_EVENT)); //needed to scroll NavSlider
        }
      },
      //
      //
      sportsLobby({ options }: { options?: NavigationOptions }) {
        const path = `/sports`;
        customNavigate({ path, navigate, options });
      },
      //
      //
      sportsEventView({
        sportEventId,
        eventPath,
        isLive,
        options,
      }: {
        sportEventId: number;
        eventPath: string;
        isLive: boolean;
        options?: NavigationOptions;
      }) {
        const path = `${isLive ? '/in-play' : '/sports/event'}/${combineNameAndId(eventPath, sportEventId)}`;

        customNavigate({
          path,
          navigate,
          options: {
            ...options,
            state: { ...options?.state, previousRoute: `${location.pathname}${location.search}` },
          },
        });
      },
      //
      //
      sportsUpcomingEvents({ sportId, options }: { sportId?: number; options?: NavigationOptions }) {
        const path = `/sports/${getSportIdNameFormat(sportId)}/upcomingEvents`;
        customNavigate({ path, navigate, options });
      },
      //
      //
      sportsInPlay({ sportId, options }: { sportId?: number; options?: NavigationOptions }) {
        const query = sportId ? `?sportId=${getSportIdNameFormat(sportId)}` : '';
        const path = `/in-play`;

        customNavigate({ path, query, navigate, options });
      },
      //
      //
      sportTypeLobby({
        sportId,
        activeTab,
        options,
      }: {
        sportId: number;
        activeTab?: number;
        options?: NavigationOptions;
      }) {
        const query = activeTab === 1 ? '?tab=1' : '?tab=0';
        const path = `/sports/${getSportIdNameFormat(sportId)}`;
        const modifieOptions = { ...options, state: { ...options?.state, shouldNavScroll: true } };
        customNavigate({ path, query, navigate, options: modifieOptions });
        document.dispatchEvent(new Event(SPORT_TYPE_LOBBY_SCROLL_EVENT)); //needed to scroll NavSlider
      },
      //
      //
      eSportsLobby({ options }: { options?: NavigationOptions }) {
        const path = `/esports`;
        customNavigate({ path, navigate, options });
      },
      //
      //
      eSportsEventView({
        sportEventId,
        eventPath,
        isLive,
        options,
      }: {
        sportEventId: number;
        eventPath: string;
        isLive: boolean;
        options?: NavigationOptions;
      }) {
        const path = `/esports/${isLive ? 'in-play' : 'event'}/${combineNameAndId(eventPath, sportEventId)}`;

        customNavigate({
          path,
          navigate,
          options: {
            ...options,
            state: { ...options?.state, previousRoute: `${location.pathname}${location.search}` },
          },
        });
      },
      //
      //
      eSportTypeLobby({
        sportId,
        activeTab,
        options,
      }: {
        sportId: number;
        activeTab?: number;
        options?: NavigationOptions;
      }) {
        const query = activeTab === 1 ? '?tab=1' : '?tab=0';
        const path = `/esports/${getSportIdNameFormat(sportId)}`;
        const modifieOptions = { ...options, state: { ...options?.state, shouldNavScroll: true } };
        customNavigate({ path, query, navigate, options: modifieOptions });
        document.dispatchEvent(new Event(SPORT_TYPE_LOBBY_SCROLL_EVENT)); //needed to scroll NavSlider
      },
      //
      //
      liveGamesTypeLobby({
        providerAlias,
        refGameId,
        options,
      }: {
        providerAlias?: string;
        refGameId?: string;
        options?: NavigationOptions;
      }) {
        const path = `/live-games/${providerAlias}${refGameId ? `/${refGameId}` : ''}`;
        customNavigate({ path, navigate, options });
      },
      lotteryTypeLobby({ refGameId, options }: { refGameId?: string; options?: NavigationOptions }) {
        const path = `/lottery${refGameId ? `/${refGameId}` : ''}`;
        customNavigate({ path, navigate, options });
      },
      retailGamesTypeLobby({
        providerAlias,
        refGameId,
        options,
      }: {
        providerAlias?: string;
        refGameId?: string;
        options?: NavigationOptions;
      }) {
        const path = `/games/${providerAlias}${refGameId ? `/${refGameId}` : ''}`;
        customNavigate({ path, navigate, options });
      },
      //
      //
      loteriaPopular({ options }: { refGameId?: string; options?: NavigationOptions }) {
        const path = loteriaPopularData.route;
        customNavigate({ path, navigate, options });
      },
      //
    }),
    [dispatch, navigate],
  );

  return navigateTo;
};

export type NavigateToType = ReturnType<typeof useNavigateTo>;
