import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Axios from 'axios';
import isEqual from 'lodash/isEqual';
import { setIsGamificationWidgetsProfile } from 'shared/common/features/integrations/slice/integrations.slice';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getBusinessUnit, getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { GamificationUser } from '../types/gamificationPlayer.types';

const initialState: GamificationUser.State = {
  profile: null,
  hasError: false,
  isLoading: false,
};

export const fetchGamificationPlayer = createAbortThunk<GamificationUser.Profile, void, string | null>(
  'gamificationPlayer/fetchGamificationPlayer',
  async (_, { rejectWithValue, source, dispatch }) => {
    try {
      const response = await axiosInstance.get(`${config.API_URL}/api/ews-game-journey/player/profile`, {
        headers: { 'X-Platform-Origin': getBusinessUnit() },
        cancelToken: source.token,
      });
      dispatch(setIsGamificationWidgetsProfile(response.data));

      return response.data;
    } catch (err) {
      if (Axios.isCancel(err)) {
        return rejectWithValue(null);
      }
      return rejectWithValue(getError.default());
    }
  },
  {
    condition: (_, { getState }) => {
      const { captainUp } = getState();
      const isLoading = captainUp.gamificationPlayer.isLoading;

      if (isLoading) return false;
    },
  },
);

const gamificationPlayer = createSlice({
  name: 'gamificationPlayer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGamificationPlayer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchGamificationPlayer.fulfilled, (state, action: PayloadAction<GamificationUser.Profile>) => {
        if (!isEqual(state.profile, action.payload)) {
          state.profile = action.payload;
        }
        state.hasError = false;
        state.isLoading = false;
      })
      .addCase(fetchGamificationPlayer.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      });
    builder.addCase(logoutUser, () => initialState);
  },
});

export const { reducer: gamificationPlayerReducer } = gamificationPlayer;

export const selectGamificationPlayer = {
  profile: (state: RootState): GamificationUser.Profile | null => state.captainUp.gamificationPlayer.profile,
  getWinCoins: (state: RootState): number => state.captainUp?.gamificationPlayer?.profile?.playerCurrencies?.coins || 0,
  hasError: (state: RootState): boolean | null => state.captainUp?.gamificationPlayer?.hasError,
};
