import { MutableRefObject, useEffect } from 'react';

export const useOutsideMouseMove = (ref: MutableRefObject<HTMLElement | null>, callback: () => void) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener('mousemove', handleClickOutside);
    return () => {
      document.removeEventListener('mousemove', handleClickOutside);
    };
  }, [ref, callback]);
};
