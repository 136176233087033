import React, { memo } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { ScrollContainer } from 'components/shared';
import { arrowStyleTypes, DRAWER_HEADER_SCROLL_EVENT, handleScrollMoveWith } from 'components/shared/ScrollContainer';
import { selectNavigationMenu } from 'shared/common/features/navigationMenu/slice/navigationMenu.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { Breakpoints } from 'theme/Theme';
import { isEmpty } from 'utils/common/helpersCommon';
import { filterMenuItemsByPlayerId } from 'utils/filterMenuItemsByPlayerId';
import DrawerHeaderItem from './DrawerHeaderItem';
import { DrawerUI } from './DrawerUI';
import { selectMyProfile } from '../../myProfile/slice/myProfile.slice';
import { BurgerMenuLayoutMenuItems02 } from '../../navigationMenu/types/navigationMenu.types';
import { setDrawer } from '../slice/drawer.slice';

const scrollItemWidth = 60;
const itemsToScroll = 4;
const { moveWith } = handleScrollMoveWith({ itemsToScroll, itemWidth: scrollItemWidth });

const DrawerHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const menuItemsLayout02 = useAppSelector(selectNavigationMenu.menuItemsLayout02);
  const playerId = useAppSelector(selectMyProfile.playerId);

  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });

  const onClose = () => {
    dispatch(setDrawer(false));
  };

  if (!menuItemsLayout02) return null;

  const wrapperClass = clsx('drawer-tabs', menuItemsLayout02.length > 4 && 'drawer-tabs--scrollable');

  const menuItems: BurgerMenuLayoutMenuItems02[] = filterMenuItemsByPlayerId({ items: menuItemsLayout02, playerId });

  return (
    <>
      {isDesktop && <DrawerUI.Close onClick={onClose} />}
      <ScrollContainer
        classNames={wrapperClass}
        regularDesktopCellWidth={scrollItemWidth}
        moveWith={moveWith}
        loadItems={!isEmpty(menuItems)}
        activeClass="drawer-tab--active"
        typeArrow={arrowStyleTypes.Tabs}
        centerScrollEventName={DRAWER_HEADER_SCROLL_EVENT}
      >
        {menuItems?.map((item, idx) => {
          return <DrawerHeaderItem key={idx} item={item} />;
        })}
      </ScrollContainer>
    </>
  );
};

export default memo(DrawerHeader);
