import { BetSlip } from 'utils/common/translationUtils/translationStrings';
import BetBuilderInfo from './BetBuilderInfo';
import { BetSelectionUI } from '../../../components/betSelectionUI';

export const LiveEventBetBuilder = ({ customBetLiveOutcome, outcome, t, eventStatusConfig }) => {
  return (
    <>
      <BetSelectionUI.BodyLive
        homeTeam={customBetLiveOutcome.homeTeam}
        awayTeam={customBetLiveOutcome.awayTeam}
        bankerLabel={t(BetSlip.Selections.bankerLetter)}
        eventClockStatus={customBetLiveOutcome.eventClockStatus}
        eventStatusConfig={eventStatusConfig}
        handleEventClick={() => {}}
        handleLiveTrackerClick={() => {}}
        isEventClickable={false}
        isLiveTrackerButtonActive={false}
        market={customBetLiveOutcome.marketTemplateName}
        showBankerLabel={customBetLiveOutcome.banker}
        showEventClock={customBetLiveOutcome.eventClockStatus && !customBetLiveOutcome.isVirtual}
        showLiveScore={customBetLiveOutcome.live && !customBetLiveOutcome.isVirtual}
        showLiveTrackerButton={false}
        tournamentId={customBetLiveOutcome?.tournamentId}
        eventName={customBetLiveOutcome.sportEventName}
        sportId={customBetLiveOutcome.sportId}
        selectionBonuses={customBetLiveOutcome?.selectionBonuses}
      />
      <BetBuilderInfo customBetOutcomes={outcome?.customBetOutcomes} />
    </>
  );
};
