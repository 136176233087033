import React, { SyntheticEvent } from 'react';
import clsx from 'clsx';
import { InputModeType, ValidationRules } from 'components/shared/forms/formTypes';
import { useOnOff } from 'hooks/common/useOnOff';

interface Props {
  name: string;
  value?: string;
  placeholder: string;
  error?: string;
  register?: any;
  validation?: ValidationRules;
  inputMode?: InputModeType;
  type?: string;
  minLength?: number;
  maxLength?: number;
  autoComplete?: string;
  inputValue?: string;
  required?: boolean;
  disabled?: boolean;
  pattern?: string;
  onChange?: (e: SyntheticEvent) => void;
  onKeyDown?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocusOut?: () => void;
}

const SoFInput: React.FC<Props> = ({
  value,
  name,
  placeholder,
  error,
  register,
  validation,
  inputMode,
  type,
  minLength,
  maxLength,
  autoComplete,
  inputValue,
  required,
  disabled,
  pattern,
  onChange,
  onKeyDown,
  onFocusOut,
}) => {
  const [inputActive, setInputActive] = useOnOff();
  const handleInput = () => {
    setInputActive(!inputActive);
  };

  const cssNS = `sof-form-control`;

  const inputClass = clsx(
    cssNS,
    (value || inputValue) && `${cssNS}--active`,
    error && `${cssNS}--error`,
    disabled && `${cssNS}--readonly`,
  );

  const disabledInputClass = `d-flex-ac ${cssNS} ${cssNS}--readonly`;

  const handleInputChange = (e) => {
    onChange && onChange(e);
  };

  const handleFocusOut = () => {
    setInputActive(!inputActive);
    onFocusOut && onFocusOut();
  };

  const handleKewDown = (e) => {
    onKeyDown && onKeyDown(e);
  };

  let modifiedValidation = validation;
  if (validation?.pattern) {
    modifiedValidation = {
      ...modifiedValidation,
      pattern: { ...validation.pattern, value: new RegExp(validation.pattern?.value) },
    };
  }

  return (
    <>
      {disabled ? (
        <div className={disabledInputClass}>{inputValue ? inputValue : placeholder}</div>
      ) : (
        <input
          name={name}
          id={name}
          type={type}
          defaultValue={value || inputValue}
          placeholder={inputValue ? inputValue : placeholder}
          className={inputClass}
          onFocus={handleInput}
          onBlur={handleFocusOut}
          inputMode={inputMode}
          maxLength={maxLength}
          minLength={minLength}
          autoComplete={autoComplete}
          autoFocus
          onChange={handleInputChange}
          onKeyDown={handleKewDown}
          required={required}
          disabled={disabled}
          pattern={pattern}
          {...register(name, modifiedValidation)}
        />
      )}

      {error && <div className="sof-form-control__error">{error}</div>}
    </>
  );
};

export default React.memo(SoFInput);
