import React from 'react';
import clsx from 'clsx';
import { Icon, Text } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { CashierTheme } from 'pages/myAccount/tabs/cashier/utils/cashier.utils';
import { SCTile } from './Tile.styled';
import { item } from '../../utils/ssbt.utils';

interface TileProps {
  text: string;
  status?: 'enabled' | 'disabled';
  icons?: string[];
  onClick: React.MouseEventHandler;
}

const Tile: React.FC<TileProps> = ({ text, status, icons, onClick }) => {
  const cssNS = 'terminal-tile';
  const tileClass = clsx(cssNS, status && `${cssNS}--${status}`);

  return (
    <SCTile variants={item} className={tileClass} onClick={onClick}>
      {icons && (
        <Flex.AC>
          {icons.map((icon, index) => (
            <Flex.Center key={index} className={`${cssNS}__icon-wrapper`}>
              <Icon
                className={`${cssNS}__icon`}
                viewBox="0 0 48 32"
                iconPath={CashierTheme.icons.payments}
                icon={icon}
              />
            </Flex.Center>
          ))}
        </Flex.AC>
      )}
      <Text className={`${cssNS}__text`} text={text} />
    </SCTile>
  );
};

export default Tile;
