import { LastWinnersCellWidget } from './lastWinnersCellWidget';
import { LastWinnersBannerWidget, LastWinnersLandingWidget } from './lastWinnersCustomWidgets';
import { LastWinnersSectionWidget } from './lastWinnersSectionWidgets/templateSection';
import { LastWinnersSectionStakeWidget } from './lastWinnersSectionWidgets/templateSectionStake';

const LastWinnersWidgets = {
  LastWinnersCellWidget,
  LastWinnersSectionWidget,
  LastWinnersSectionStakeWidget,
  LastWinnersLandingWidget,
  LastWinnersBannerWidget,
};

export default LastWinnersWidgets;
