import { CasinoGamesTypes } from 'modules/casino/modules/container/cellsContainer/cellls/types/casinoGamesTypes';
import { GameType } from 'modules/casino/shared/constants';
import { getBusinessUnit } from 'modules/casino/shared/utils/common/helpersCommon';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import axiosInstance from 'utils/common/axios-instance';
import { localLog } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { KycVerificationModal } from '../../casinoLaunchConstants';

const KYCVerificationInterceptor =
  ({ gamePlayType, gameId, kycConfig, providerAlias, closeGame }) =>
  async (dispatch, getState) => {
    const verificationStatus = selectMyProfile.documentVerificationStatus(getState());

    const handleOnConfirm = () => {
      if (window.top) {
        window.top.location.href = 'my-account/profile/verification';
      }
    };

    const handleOnCancel = () => {
      if (window.opener) {
        window.close();
        return;
      }

      if (!window.closed && window.location.href !== `${window.location.origin}/poker`) {
        window.location.href = '/poker';
      }

      localLog({ message: gamePlayType, type: 'info' });
    };

    const openKycModal = () => {
      dispatch(
        showRootModal({
          modalType: 'KYC_VERIFICATION_MODAL',
          modalPriority: ModalPriorityEnum.LOW,
          modalId: 'KYC_VERIFICATION_MODAL',
          modalProps: {
            onConfirm: handleOnConfirm,
            onCancel: () => handleOnCancel(),
            descriptionKey: KycVerificationModal.modalText,
          },
        }),
      );
    };

    const promise = (gameType: GameType) => {
      return new Promise((resolve, reject) => {
        if (!kycConfig) return resolve(true);
        const isAuthenticated = selectAuthentication.isAuthenticated(getState());
        if (
          isAuthenticated &&
          gameType === GameType.PLAYTECH_iPOKER &&
          !kycConfig.verificationStatus.includes(verificationStatus)
        ) {
          return reject(openKycModal());
        }
        return resolve(true);
      });
    };

    try {
      if (providerAlias === 'playtech') {
        const gameUrl = `/api/gaming/public/bonus/gameIds?workingBusinessUnit=${getBusinessUnit()}&gameIds=${gameId}`;
        // const gameUrl = `/api/gaming/public/bonus/gameIds?workingBusinessUnit=IBBG&gameIds=${gameId}`; //TODO: uncomment to test locally
        const { data } = await axiosInstance.get<CasinoGamesTypes.GameProps[]>(`${config.API_URL}${gameUrl}`);
        const gameType = data?.[0]?.gameType;
        return promise(gameType);
      }
    } catch {
      return new Promise((_, reject) => reject(closeGame()));
    }
  };

export default KYCVerificationInterceptor;
