import { useLocation, useMatch } from 'react-router-dom';

// use only in component, which already uses useLocation
// needed because route = 'sports/in-play' alone actually does not exist, but adds to routing history

export const useRouteToInPlay = (route: string): string | undefined => {
  const location = useLocation();
  const inplayMatch = useMatch(`/in-play`);

  if (inplayMatch && route === '/in-play') {
    return location.pathname + location.search;
  }
  return undefined;
};
