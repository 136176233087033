import React from 'react';
import clsx from 'clsx';
import { classNameUtils } from '../utils/className.utils';

type Props = {
  infoText: string;
  handleInfo: () => void;
  infoLinkText: string;
  btnText: string;
  handleClose?: () => void;
  className?: string;
  showInfo?: boolean;
};

const NotificationPopupFooter: React.FC<Props> = ({
  handleClose,
  infoText,
  infoLinkText,
  handleInfo,
  btnText,
  showInfo,
  className,
}) => {
  const classProp = clsx(classNameUtils.footer, className);

  return (
    <div className={classProp}>
      <div className={`${classNameUtils.footer}-text`} onClick={handleInfo}>
        {showInfo && (
          <>
            {infoText}
            <br /> <u>{infoLinkText}</u>
          </>
        )}
      </div>
      <div className={`${classNameUtils.footer}-btn d-flex-center`} onClick={handleClose}>
        {btnText}
      </div>
    </div>
  );
};

export default NotificationPopupFooter;
