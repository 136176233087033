import React from 'react';
import { t } from 'i18next';
import Icon from 'components/shared/Icon';
import { CashierTranslations } from 'modules/casino/shared/utils/translationUtils/translationStrings';
import { Flex } from '../layout';
import { PaginationTypes } from './';

const mainClass = 'ui-pagination';
const extensionClass = '__pagination';

const PageIcon: React.FC<PaginationTypes.Props.PageIcon> = ({ icon, className }) => (
  <Icon
    className={`${mainClass}${extensionClass}-btn-icon${className ? ` ${className}${extensionClass}-btn-icon` : ''}`}
    icon={icon}
    size={13}
  />
);

const PageButton: React.FC<PaginationTypes.Props.PageButton> = ({
  pageNumber,
  isDisabled,
  isActive,
  setPage,
  className,
}) => (
  <button
    key={pageNumber}
    type={'button'}
    className={`${mainClass}${extensionClass}-page ${mainClass}${extensionClass}-page--${pageNumber + 1}${
      isActive ? ` ${mainClass}${extensionClass}-page--active` : ''
    }${
      className
        ? ` ${className}${extensionClass}-page ${className}${extensionClass}-page--${pageNumber + 1}${
            isActive ? ` ${className}${extensionClass}-page--active` : ''
          }`
        : ''
    }`}
    disabled={isActive || isDisabled}
    onClick={() => {
      setPage(pageNumber);
    }}
  >
    {pageNumber + 1}
  </button>
);

const PageButtons: React.FC<PaginationTypes.Props.PageButtons> = ({
  currentPage,
  totalPages,
  sidePagesCount,
  isDisabled,
  hasPages,
  setPage,
  className,
}) => {
  const range = { low: currentPage - sidePagesCount, high: currentPage + sidePagesCount, all: sidePagesCount * 2 + 1 };
  const start =
    range.low <= 0 || totalPages <= range.all
      ? 0
      : totalPages - range.low > range.all
        ? range.low
        : totalPages - range.all;

  return (
    <>
      {hasPages === 'all' && (
        <PageButton
          key={'first'}
          pageNumber={0}
          isDisabled={isDisabled}
          isActive={currentPage === 0}
          setPage={setPage}
          className={className}
        />
      )}
      {hasPages === 'all' && range.low > 0 && <span key={'dots-start'}>...</span>}
      {Array(sidePagesCount * 2 + 1)
        .fill(null)
        .map((_, i) => {
          const index = start + i;
          return (
            (!hasPages || hasPages === 'middle' || index !== 0) &&
            (((!hasPages || hasPages === 'middle') && index === totalPages - 1) || index < totalPages - 1) && (
              <PageButton
                key={index}
                pageNumber={index}
                isDisabled={isDisabled}
                isActive={currentPage === index}
                setPage={setPage}
                className={className}
              />
            )
          );
        })}
      {hasPages === 'all' && totalPages - range.high > 1 && <span key={'dots-end'}>...</span>}
      {hasPages === 'all' && Boolean(totalPages) && Boolean(totalPages - 1) && (
        <PageButton
          key={'last'}
          pageNumber={totalPages - 1}
          isDisabled={isDisabled}
          isActive={currentPage === totalPages - 1}
          setPage={setPage}
          className={className}
        />
      )}
    </>
  );
};

const Pagination: React.FC<PaginationTypes.Props.Main> = ({
  currentPage = 0,
  totalPages = 0,
  totalResults,
  totalResultsTitle = '',
  sidePagesCount = 2,
  isDisabled,
  hasPages = 'all',
  hasButtons = 'all',
  setPage,
  className,
  isReprint = false,
}) => (
  <div className={`${mainClass}${extensionClass}-wrap${className ? ` ${className}${extensionClass}-wrap` : ''}`}>
    {Boolean(totalResults) && (
      <>
        <span
          className={`${mainClass}${extensionClass}-results${
            className ? ` ${className}${extensionClass}-results` : ''
          }`}
        >
          {isReprint ? (
            <Flex.JB className={`${mainClass}${extensionClass}-results-text`}>
              <div>{totalResultsTitle}</div>
              <div>{t(CashierTranslations.TransactionHistoryReprintModal.ofTotal)}</div>
              <div>{totalResults}</div>
            </Flex.JB>
          ) : (
            <>
              {totalResultsTitle} {totalResults}
            </>
          )}
        </span>
        <div className={`${mainClass}${extensionClass}${className ? ` ${className}${extensionClass}` : ''}`}>
          {(hasButtons === 'all' || hasButtons === 'start-end') && (
            <button
              type={'button'}
              className={`${mainClass}${extensionClass}-btn${className ? ` ${className}${extensionClass}-btn` : ''}`}
              disabled={currentPage === 0 || isDisabled}
              onClick={() => {
                setPage(0);
              }}
            >
              <PageIcon className={className} icon={'chevron-left'} />
              <PageIcon className={className} icon={'chevron-left'} />
            </button>
          )}
          {(hasButtons === 'all' || hasButtons === 'prev-next') && (
            <button
              type={'button'}
              className={`${mainClass}${extensionClass}-btn${className ? ` ${className}${extensionClass}-btn` : ''}`}
              disabled={currentPage === 0 || isDisabled}
              onClick={() => {
                setPage(currentPage - 1);
              }}
            >
              <PageIcon className={className} icon={'chevron-left'} />
            </button>
          )}
          <div
            className={`${mainClass}${extensionClass}-pages${className ? ` ${className}${extensionClass}-pages` : ''}`}
          >
            <PageButtons
              currentPage={currentPage}
              totalPages={totalPages}
              sidePagesCount={sidePagesCount}
              isDisabled={isDisabled}
              hasPages={hasPages}
              setPage={setPage}
              className={className}
            />
          </div>
          {(hasButtons === 'all' || hasButtons === 'prev-next') && (
            <button
              type={'button'}
              className={`${mainClass}${extensionClass}-btn${className ? ` ${className}${extensionClass}-btn` : ''}`}
              disabled={currentPage === totalPages - 1 || isDisabled}
              onClick={() => {
                setPage(currentPage + 1);
              }}
            >
              <PageIcon className={className} icon={'chevron-right'} />
            </button>
          )}
          {(hasButtons === 'all' || hasButtons === 'start-end') && (
            <button
              type={'button'}
              className={`${mainClass}${extensionClass}-btn${className ? ` ${className}${extensionClass}-btn` : ''}`}
              disabled={currentPage === totalPages - 1 || isDisabled}
              onClick={() => {
                setPage(totalPages - 1);
              }}
            >
              <PageIcon className={className} icon={'chevron-right'} />
              <PageIcon className={className} icon={'chevron-right'} />
            </button>
          )}
        </div>
      </>
    )}
  </div>
);

export default Pagination;
