import React, { memo, useRef } from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';
import { casinoLaunchSelectors } from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import {
  CasinoDrawerSection,
  DrawerHeader,
  DrawerSearch,
  ESportDrawerSection,
  HelpDrawerSection,
  LiveSportDrawerSection,
  PromotionDrawerSection,
  SportDrawerSection,
  VirtualDrawerSection,
} from './components';
import { useDrawerVisibility } from './hooks/useDrawerVisibility';
import { useFetchUserFavourites } from './hooks/useFetchUserFavourites';
import SetDrawerTypeWrapper from './SetDrawerTypeWrapper';
import { selectDrawer } from './slice/drawer.slice';
import { DrawerFunc, DrawerType } from './types/drawer.types';
// import ChatButton from '../../chat/ChatButton';
import BurgerMenuLayout02 from '../burger/BurgerMenuLayout02';
import { selectNavigationMenu } from '../navigationMenu/slice/navigationMenu.slice';

interface Props {
  isToggled: boolean;
}

const activeDrawerSection = (drawerType: DrawerType, isToggled: boolean) => {
  switch (drawerType) {
    case DrawerType.Sport:
      return <SportDrawerSection isToggled={isToggled} isActive />;
    case DrawerType.LiveSport:
      return <LiveSportDrawerSection isActive />;
    case DrawerType.Casino:
      return <CasinoDrawerSection isActive />;
    case DrawerType.Promotion:
      return <PromotionDrawerSection isActive />;
    case DrawerType.ESports:
      return <ESportDrawerSection isActive />;
    case DrawerType.Virtuals:
      return <VirtualDrawerSection isActive />;
    case DrawerType.Help:
      return <HelpDrawerSection isActive />;
    case DrawerType.InActive:
      return <SportDrawerSection isToggled={isToggled} isActive />;
  }
};

const Drawer: React.FC<Props> = ({ isToggled }) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const drawerType = useAppSelector(selectDrawer.type);
  const burgerMenuLayout02 = useAppSelector(selectNavigationMenu.burgerMenuLayout02);
  useDrawerVisibility({ drawerRef, isToggled });
  useFetchUserFavourites();
  const launchInProcess = useAppSelector(casinoLaunchSelectors.launchInProcess);

  if (launchInProcess) {
    return null;
  }

  const drawerWrapperClass = clsx(
    isToggled && 'drawer-overlay',
    burgerMenuLayout02?.layout === 'layout_2' && 'drawer--on-top', //need to be set to correct layout with burger menu!
  );

  const drawerClass = clsx(
    'd-flex-col no-scrollbars drawer',
    isToggled && 'drawer--active',
    drawerType === DrawerType.Promotion && 'drawer--search-disabled',
    burgerMenuLayout02?.layout === 'layout_2' && 'drawer--wide',
    burgerMenuLayout02?.layout != 'layout_2' && 'drawer--layout01',
  );

  const activeDrawer: Record<string, DrawerFunc> = {
    layout_1() {
      return (
        <>
          {drawerType !== DrawerType.Promotion && <DrawerSearch />}
          <DrawerHeader />
          <Flex.Col className="custom-scrollbars drawer-scroll-container">
            {activeDrawerSection(drawerType, isToggled)}
            {/* <ChatButton /> */}
          </Flex.Col>
        </>
      );
    },

    layout_2() {
      return (
        <BurgerMenuLayout02
          hasSearchField={
            !isEmpty(burgerMenuLayout02) && !isEmpty(burgerMenuLayout02.hasSearchField)
              ? burgerMenuLayout02.hasSearchField
              : false
          }
        />
      );
    },
  };

  const layoutMap = {
    layout_1: activeDrawer.layout_1,
    layout_2: activeDrawer.layout_2,
  };

  let layoutComponent = null;

  if (!isEmpty(burgerMenuLayout02) && layoutMap[burgerMenuLayout02.layout]) {
    layoutComponent = layoutMap[burgerMenuLayout02.layout]();
  }

  return (
    <>
      {!isEmpty(burgerMenuLayout02) &&
        (burgerMenuLayout02?.layout === 'layout_1' || burgerMenuLayout02?.layout === 'layout_2') && (
          <SetDrawerTypeWrapper />
        )}
      <div className={drawerWrapperClass}>
        <div ref={drawerRef} className={drawerClass} tabIndex={1}>
          {layoutComponent}
        </div>
      </div>
    </>
  );
};

export default memo(Drawer);
