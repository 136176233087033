import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { setWithSocial } from 'pages/auth/registration/slice/registration.slice';
import { useAppDispatch } from 'store';
import { ButtonGroup } from './formButtonGroup.types';
import FormButtonGroupControl from './FormButtonGroupControl';

const FormButtonGroup: React.FC<ButtonGroup> = ({
  name,
  label,
  endLabel,
  inputType,
  componentLocation,
  buttons,
  disabled,
}): ReactNode | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setWithSocial(null));
  }, [dispatch]);

  return (
    <div className={`button-group button-group--${componentLocation}`}>
      {label && <div className={'button-group-label'}>{t(label)}</div>}
      <div className={'button-group-buttons'}>
        {buttons?.map((button, i) => (
          <FormButtonGroupControl
            key={i}
            name={name}
            button={button}
            inputType={inputType}
            componentLocation={componentLocation}
            disabled={disabled}
          />
        ))}
      </div>
      {endLabel && (
        <Flex.AC className={'button-group-end-label'}>
          <div className="button-group-end-label__line" />
          <div>{t(endLabel)}</div>
          <div className="button-group-end-label__line" />
        </Flex.AC>
      )}
    </div>
  );
};

export default FormButtonGroup;
