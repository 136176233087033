import React, { memo, useRef } from 'react';
import { Icon } from 'components/shared';
import { ButtonClose } from 'components/shared/buttons';
import { Flex } from 'components/shared/layout';
import { casinoLaunchSelectors } from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import { useAppSelector } from 'store';
import { Desktop, DesktopDown } from 'theme/Theme';
import { useHandleSearchBar } from '../hooks/useHandleSearchBar';

const SearchBar: React.FC = () => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { query, handleCloseSearch, handleMobileClear, handleKeyDown, search, t, searchPlaceholder } =
    useHandleSearchBar(inputRef);
  const isGameLaunch = useAppSelector(casinoLaunchSelectors.launchInProcess);

  return (
    <Flex.AC className={'search-bar__wrapper'} data-qid="search-icon">
      <Flex className={'search-bar'} data-qid="search-bar">
        <DesktopDown>{!isGameLaunch && <ButtonClose onClick={handleCloseSearch} />}</DesktopDown>
        <Flex.AC className="search-bar__input-wrapper">
          <input
            ref={inputRef}
            autoFocus={!isGameLaunch}
            id="main-search-box"
            className="search-bar__input"
            placeholder={t(searchPlaceholder)}
            onChange={(e) => search(e.target.value)}
            defaultValue={query.get('query') || ''}
            onKeyDown={handleKeyDown}
            data-qid="search-bar-input"
          />
        </Flex.AC>
        <DesktopDown>
          <span className="search-bar__icon--wrapper">
            <Icon
              onClick={handleMobileClear}
              className="mx-2 search-bar__icon search-bar__clear"
              icon="kbd-backspace"
            />
          </span>
        </DesktopDown>
        <Desktop>
          {!isGameLaunch && (
            <Icon
              onClick={handleCloseSearch}
              className="ml-2 search-bar__icon search-bar__close"
              icon="times"
              dataQidAttr="btn-close"
            />
          )}
        </Desktop>
      </Flex>
    </Flex.AC>
  );
};

export default memo(SearchBar);
