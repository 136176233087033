import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import { Sport } from 'utils/common/types/sportTypes';
import config from 'utils/config';
import {
  ReprintInfoThunkType,
  ReprintInfoTypes,
  ReprintTerminalDataType,
  ReprintTicketType,
  TicketHistoryDataType,
  TicketOperationDataType,
} from '../type/reprint.types';

const initialState: ReprintTicketType = {
  ticketHistoryDataStatus: 'idle',
  ticketHistoryData: null,
  ticketHistoryDataError: '',
  ticketOperationStatus: 'idle',
  ticketOperationError: '',
  ticketOperationData: null,
  terminalDataStatus: 'idle',
  terminalDataStatusError: '',
  terminalData: null,
  filters: null,
  reprintInfo: null,
  reprintInfoStatus: 'idle',
  reprintInfoError: '',
  expandedTicket: null,
  reprintCheckTicketInfo: null,
  reprintCheckTicketError: '',
  reprintCheckTicketStatus: 'idle',
};

export const reprintInfoThunk: ReprintInfoThunkType = {
  getTicketHistory: createAbortThunk(
    `terminal/getTicketHistory`,
    async (
      { FromDate, ToDate, dateFromTimestamp, dateToTimestamp, ticketOperation, terminalId, pageSize, currentPage },
      { rejectWithValue },
    ) => {
      try {
        let url = `${config.API_URL}/api/pretail/agent/v2/pos/get-tickets-history?FromDate=${FromDate}&ToDate=${ToDate}&dateFromTimestamp=${dateFromTimestamp}&dateToTimestamp=${dateToTimestamp}&PageSize=${pageSize}&CurrentPage=${currentPage}`;
        if (ticketOperation) {
          url += `&Status=${ticketOperation}`;
        }
        if (terminalId) {
          url += `&TerminalId=${terminalId}`;
        }

        const response = await axiosInstance.get(url);

        return response.data;
      } catch (error) {
        return rejectWithValue(getError.responseData(error));
      }
    },
  ),

  getOperations: createAbortThunk(`terminal/getOperations`, async (_, { rejectWithValue }) => {
    try {
      const url = `${config.API_URL}/api/pretail/agent/v2/ticket/ticket-operations`;

      const response = await axiosInstance.get(url);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError.responseData(error));
    }
  }),
  getTerminal: createAbortThunk(`terminal/getTerminal`, async (_, { rejectWithValue }) => {
    try {
      const url = `${config.API_URL}/api/pretail/agent/v2/terminal/get-all-terminals`;

      const response = await axiosInstance.get(url);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError.responseData(error));
    }
  }),
  getTicketReprintInfoForReceipt: createAbortThunk(
    `terminal/getTicketInfoByTicketId`,
    async ({ id }, { rejectWithValue }) => {
      try {
        const url = `${config.API_URL}/api/pretail/agent/v2/ticket/reprint-ticket?Id=${id}&ReprintTicket=${true}`;

        const response = await axiosInstance.get(url);

        return response.data;
      } catch (error) {
        return rejectWithValue(getError.responseData(error));
      }
    },
  ),
  getCheckTicketInfoByTicketHistoryId: createAbortThunk(
    `terminal/getCheckTicketById`,
    async ({ id }, { rejectWithValue }) => {
      try {
        const url = `${config.API_URL}/api/pretail/agent/v2/ticket/reprint-ticket?Id=${id}&ReprintTicket=${false}`;

        const response = await axiosInstance.get(url);

        return response.data;
      } catch (error) {
        return rejectWithValue(getError.responseData(error));
      }
    },
  ),
};

const ReprintTicketSlice = createSlice({
  name: 'ReprintTicketSlice',
  initialState,
  reducers: {
    resetReprintTicketSlice: () => initialState,
    resetReprintTicketHistoryDate: (state) => {
      state.ticketHistoryData = null;
      state.ticketHistoryDataError = '';
      state.ticketHistoryDataStatus = 'idle';
    },
    saveSearchFiltersForNexStepPagination: (state, action) => {
      state.filters = {
        selectedDate: {
          startDate: action.payload.FromDate,
          endDate: action.payload.ToDate,
          dateFromTimestamp: action.payload.dateFromTimestamp,
          dateToTimestamp: action.payload.dateToTimestamp,
        },
        operation: action.payload.ticketOperation,
        terminal: action.payload.terminalId,
        pageSize: action.payload.pageSize,
        currentPage: action.payload.currentPage,
      };
    },
    resetReprintReceiptInfo: (state) => {
      state.reprintInfo = null;
      state.reprintInfoError = '';
      state.reprintInfoStatus = 'idle';
    },

    resetSearchFiltersForNexStepPagination: (state) => {
      state.filters = null;
    },
    setExpandedTicket: (state, action) => {
      state.expandedTicket = action.payload;
    },
    resetCheckTicketReprintInfoStatus: (state) => {
      state.reprintCheckTicketInfo = null;
      state.reprintCheckTicketError = '';
      state.reprintCheckTicketStatus = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      reprintInfoThunk.getTicketHistory.fulfilled,
      (state, action: PayloadAction<TicketHistoryDataType>) => {
        state.ticketHistoryDataStatus = 'succeeded';
        state.ticketHistoryData = action.payload;
      },
    );
    builder.addCase(reprintInfoThunk.getTicketHistory.pending, (state) => {
      state.ticketHistoryDataStatus = 'pending';
    });
    builder.addCase(reprintInfoThunk.getTicketHistory.rejected, (state, action) => {
      state.ticketHistoryDataError = action?.payload?.errorCode;
      state.ticketHistoryDataStatus = 'failure';
      state.ticketHistoryData = null;
    });
    builder.addCase(
      reprintInfoThunk.getOperations.fulfilled,
      (state, action: PayloadAction<TicketOperationDataType>) => {
        state.ticketOperationStatus = 'succeeded';
        const activeOptionsFromDropDown = action.payload?.operations?.map((operation) => ({
          value: operation,
          text: operation,
        }));
        state.ticketOperationData = {
          activeOptionsFromDropDown,
        };
      },
    );
    builder.addCase(reprintInfoThunk.getOperations.pending, (state) => {
      state.ticketOperationStatus = 'pending';
    });
    builder.addCase(reprintInfoThunk.getOperations.rejected, (state, action) => {
      state.ticketOperationError = action?.payload?.errorCode;
      state.ticketOperationStatus = 'failure';
      // Default to 'all' when operations API fails, since it's optional.
      state.ticketOperationData = { activeOptionsFromDropDown: [{ value: 'all', text: 'all' }] };
    });
    builder.addCase(reprintInfoThunk.getTerminal.fulfilled, (state, action: PayloadAction<ReprintTerminalDataType>) => {
      state.terminalDataStatus = 'succeeded';
      const activeTerminalsFromDropDown =
        action.payload?.response?.map((item) => ({
          value: item.id,
          text: item.name,
        })) || [];
      state.terminalData = {
        activeTerminalsFromDropDown,
      };
    });
    builder.addCase(reprintInfoThunk.getTerminal.pending, (state) => {
      state.terminalDataStatus = 'pending';
    });
    builder.addCase(reprintInfoThunk.getTerminal.rejected, (state, action) => {
      state.terminalDataStatusError = action?.payload?.errorCode;
      state.terminalDataStatus = 'failure';
      // Default to an empty terminal option when API fails, as terminals are optional.
      state.terminalData = { activeTerminalsFromDropDown: [{ value: '', text: '' }] };
    });
    builder.addCase(reprintInfoThunk.getTicketReprintInfoForReceipt.fulfilled, (state, action) => {
      state.reprintInfoStatus = 'succeeded';
      state.reprintInfo = action.payload as unknown as ReprintInfoTypes;
    });
    builder.addCase(reprintInfoThunk.getTicketReprintInfoForReceipt.pending, (state) => {
      state.reprintInfoStatus = 'pending';
    });
    builder.addCase(reprintInfoThunk.getTicketReprintInfoForReceipt.rejected, (state, action) => {
      state.reprintInfoError = action?.payload?.errorCode;
      state.reprintInfoStatus = 'failure';
      state.reprintInfo = null;
    });
    builder.addCase(reprintInfoThunk.getCheckTicketInfoByTicketHistoryId.fulfilled, (state, action) => {
      state.reprintCheckTicketStatus = 'succeeded';
      state.reprintCheckTicketInfo = action.payload;
    });
    builder.addCase(reprintInfoThunk.getCheckTicketInfoByTicketHistoryId.pending, (state) => {
      state.reprintCheckTicketStatus = 'pending';
    });
    builder.addCase(reprintInfoThunk.getCheckTicketInfoByTicketHistoryId.rejected, (state, action) => {
      state.reprintCheckTicketError = action?.payload?.errorCode;
      state.reprintCheckTicketStatus = 'failure';
      state.reprintCheckTicketInfo = null;
    });
  },
});
export default ReprintTicketSlice.reducer;
export const {
  resetReprintTicketSlice,
  resetReprintTicketHistoryDate,
  resetSearchFiltersForNexStepPagination,
  saveSearchFiltersForNexStepPagination,
  resetReprintReceiptInfo,
  setExpandedTicket,
  resetCheckTicketReprintInfoStatus,
} = ReprintTicketSlice.actions;

export const selectReprintTicketData = {
  ticketHistoryData: (state: RootState) => state.retail.reprintTicket.ticketHistoryData,
  ticketHistoryDataStatus: (state: RootState): Sport.StateStatus => state.retail.reprintTicket.ticketHistoryDataStatus,
  ticketHistoryDataError: (state: RootState) => state.retail.reprintTicket.ticketHistoryDataError,
  ticketOperationData: (state: RootState) => state.retail.reprintTicket.ticketOperationData,
  ticketOperationStatus: (state: RootState): Sport.StateStatus => state.retail.reprintTicket.ticketOperationStatus,
  ticketOperationError: (state: RootState) => state.retail.reprintTicket.ticketOperationError,
  terminalDataStatusError: (state: RootState) => state.retail.reprintTicket.terminalDataStatusError,
  terminalDataStatus: (state: RootState): Sport.StateStatus => state.retail.reprintTicket.terminalDataStatus,
  terminalData: (state: RootState) => state.retail.reprintTicket.terminalData,
  filters: (state: RootState) => state.retail.reprintTicket.filters,
  reprintInfo: (state: RootState) => state.retail.reprintTicket.reprintInfo,
  reprintInfoStatus: (state: RootState) => state.retail.reprintTicket.reprintInfoStatus,
  reprintInfoError: (state: RootState) => state.retail.reprintTicket.reprintInfoError,
  expandedTicket: (state: RootState) => state.retail.reprintTicket.expandedTicket,
  reprintCheckTicketStatus: (state: RootState) => state.retail.reprintTicket.reprintCheckTicketStatus,
  reprintCheckTicketError: (state: RootState) => state.retail.reprintTicket.reprintCheckTicketError,
  reprintCheckTicketInfo: (state: RootState) => state.retail.reprintTicket.reprintCheckTicketInfo,
};
