type Listeners = {
  //************** Documentation
  onLoad?: (config: Config) => void;
  onLogin?: (payload: boolean) => void;
  onLogout?: () => void;
  onNavigationChange?: (payload: {
    page: NavKeys | '';
    params: Partial<NavigationParams>;
    location: NavLocation;
    options?: NavigationOptions;
    basePath?: string;
  }) => void;
  onGamificationProfileChange?: (user: GamificationUser.Profile) => void;
  onMyChallengesCountChange?: (count: number) => void;
  onMyGamesChallengesChange?: (props: { biggestPercentage: number; challengeUpTo100: boolean }) => void;
  onShowLogin: (isLogin: boolean) => void;
};

export enum WidgetInitTypes {
  GAMIFICATION = 'gamification',
  LOTTO = 'lotto',
}

export type GamificationInitsType = { [key in WidgetInitTypes]?: GamificationInitsPropsType };
export type GamificationInitsPropsType = { isVisible: boolean; popupNavigationLink?: string };

export declare module GamificationUser {
  type Profile = {
    playerCurrencies: Currencies;
    playerLevel: Level;
  };

  type State = { profile: Profile | null; hasError: boolean; isLoading: boolean };

  type Info = {
    name: string;
    image: string;
    playerCurrencies: Currencies;
    playerLevel: Level;
  };

  type Currencies = {
    coins: number;
    points: number;
  };

  type Level = {
    number: number;
    name: string;
    imageUrl: string;
    currentLevelPoints: number;
    nextLevelPoints: number;
    color?: string;
  };
}

export type Config = {
  params: Params;
  listeners?: Listeners;
  clientId: string; // wbbg-dev
};

type Params = {
  preferredTheme: 'Light' | 'Dark';
  language: string;
  currencyCode?: string;
  token?: string | null;
  initialRoute?: string;
  basePath?: string;
  timeZoneOffset?: string | null;
  fixedBottom?: number;
  fixedTop?: number;
  appHeaderHeight?: number;
  appMainNavHeight?: number;
  topNavHeaderStatus?: string;
  isLocal?: boolean;
  loginFormVisibility?: boolean;
  device?: string;
  ianaTimezone?: string | null;
};

export type NavigationType = {
  navigateTo;
  options?: NavigationOptions;
} & NavigationParams;

export type NavigationLocation = {
  fullPath: string;
  path: string;
  query: string;
};

export type NavigationParams = {
  isLive: boolean;
  sportId: number;
  containerId: string;
  sportEventId: number;
  tournamentId: number;
  tournamentPath: string;
  isOutright: boolean;
  tournamentName: string;
  marketTemplateId: number;
  eventPath: string;
  sportType;
  groupId: string | number;
  tab: 'matches' | 'outrights';
  routing: string;
  providerAlias: string;
  queryTemplate: string[];
  queryParams: Record<string, string>[];
  tournamentQuery: Record<string, string>[];
  back: number;
  section: string;
  alias: string;
  jackpotId: string;
  activeProviderAlias: string;
};

export type NavLocation = {
  fullPath: string;
  path?: string;
  query?: string;
  hash?: string;
};

export type NavigationOptions = {
  state?;
  replaceCurrentHistory?: boolean;
  noRerender?: boolean;
};

export type GamificationWidgetType =
  | 'gamification'
  | 'challengesCategory'
  | 'myChallenges'
  | 'storeCategory'
  | 'myGameChallenges'
  | 'lotto';

export type GamificationWidgetPropsType = {
  category?: string;
  categoryId?: number;
  isScrollable?: boolean;
  disableCategoryTitle?: boolean;
  onClick?: () => void;
  // Challenges
  isPercentage?: boolean;
  forceCompactLayout?: boolean;

  // casino games challenge
  gameId?: number;
  interval?: number;
};
export type NavKeys =
  | 'sportsLobby'
  | 'eSportsLobby'
  | 'sportsInPlay'
  | 'eSportTypeLobby'
  | 'sportTypeLobby'
  | 'leagueView'
  | 'eSportLeagueView'
  | 'sportsEventView'
  | 'eSportsEventView'
  | 'sportsUpcomingEvents'
  | 'sportsHeadToHead'
  | 'resultsPageHome'
  | 'sportsTournamentView'
  | 'sportSpecialView'
  | 'eSportsAllTournaments'
  | 'virtualTypeLobby'
  | 'sportAccentView'
  | 'virtualEventView'
  | 'sportsFavourites'
  | 'sportsProgram'
  | 'back'
  | 'myBets'
  | 'sportSearch'
  | 'quickSearch'
  | 'jackpotView'
  | 'jackpotSection'
  | 'quizSection'
  | 'retailLiveProgram';

type NavParams = {
  isLive?: boolean;
  sportId?: number;
  containerId?: string;
  sportEventId?: number;
  tournamentId?: number;
  tournamentPath?: string;
  isOutright?: boolean;
  tournamentName?: string;
  marketTemplateId?: number;
  eventPath?: string;
  sportType?: 'sports' | 'esports';
  groupId?: string | number;
  tab?: 'matches' | 'outrights';
  routing?: string;
  providerAlias?: string;
  queryTemplate?: string[];
  queryParams?: Record<string, string>[];
  back?: number;
};

export type SportNavigationObject = {
  page?: NavKeys | '';
  fullPath?: string;
  invokeCallBack?: boolean;
  params?: NavParams;
};

export type SportInitConfig = {
  containerId: string;
  config: Config;
};

export type SportInitObject = {
  init: () => void;
  destroy: () => void;
  config: () => Config;
  navigate: (params: SportNavigationObject) => void;
  logout: () => void;
  changeConfig: (config: Partial<Params>) => void;
};
