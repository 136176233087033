import { CasinoWidgetsTypes } from '../../types/casinoWidgetsTypes';

export const LAST_WINNER_DEFAULT_GAME_LAYOUT = 'regular';
export const LAST_WINNER_STAKE_TOP_WIN_GAME_LAYOUT = 'horizontal';
export const LAST_WINNER_DEFAULT_IMAGE = `${location.protocol}//${location.host}/assets/img/casino-cells/${LAST_WINNER_DEFAULT_GAME_LAYOUT}.jpg`;

export const LastWinnnersWidgetTranslationKeys = {
  lastWinTitle: 'Casino.LastWin.lastWinTitle',
  lastWinStake: 'Casino.LastWin.lastWinStake',
};

export enum LastWinnersWidgetFetchFromTemplates {
  CELL = 'CELL',
  SECTION = 'SECTION',
  SECTION_STAKE = 'SECTION_STAKE',
  LANDING = 'LANDING',
  BANNER_SLIDER = 'BANNER_SLIDER',
}

export const LAST_WINNERS_PULL_INTERVAL_FALLBACK = {
  [LastWinnersWidgetFetchFromTemplates.CELL]: 60000,
  [LastWinnersWidgetFetchFromTemplates.SECTION]: 60000,
  [LastWinnersWidgetFetchFromTemplates.SECTION_STAKE]: 30000,
  [LastWinnersWidgetFetchFromTemplates.LANDING]: 20000,
  [LastWinnersWidgetFetchFromTemplates.BANNER_SLIDER]: 20000,
};

export const TOP_WINNER_AREA: CasinoWidgetsTypes.TopWinnerArea = {
  column: 1,
  alignmentX: 'CENTER',
  alignmentY: 'CENTER',
};
export const DEFAULT_LANDING_TEMPLATE_COLUMNS = 2;
export const DEFAULT_BANNER_TEMPLATE_COLUMNS = 2;
