import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetFilteredProviderIds } from 'modules/casino/modules/container/shared/features/casinoGameProviders/slice/casinoGameProvidersSlice';
import { CategorySubTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { casinoLaunchSelectors } from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import { useQuery } from 'modules/casino/shared/hooks/useQuery';
import { useShowAppFooter } from 'modules/casino/shared/hooks/useShowAppFooter';
import { SearchType } from 'shared/common/features/appHeader/components/common/slice/navSearch.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { useCasinoCategoryFetch } from '../../../../modules/container/cellsContainer/containers/inner/hooks/useCasinoCategoryFetch';
import { useInitialJackpotLevelsFetch } from '../../jackpots/hooks/useInitialJackpotLevelsFetch';
import { casinoSearchSelectors, resetCasinoSearch, setCasinoSearchValue } from '../casinoSearchSlice';

type UseCasinoSearchReturnTypes = {
  searcherCategoryId: string;
  mostSearchCategoryId: string;
  searchValue: string;
  hasSearchParams: boolean;
};

export const useCasinoSearch = (): UseCasinoSearchReturnTypes => {
  const dispatch = useAppDispatch();
  const searchValue = useAppSelector(casinoSearchSelectors.casinoSearchValue);
  const providersSearchIsActive = useAppSelector(casinoSearchSelectors.providerSearchIsActive);
  const launchInProcess = useAppSelector(casinoLaunchSelectors.launchInProcess);

  useInitialJackpotLevelsFetch();

  const { query } = useQuery();
  const queryParam = query.get('query');
  const hasSearchParams = !!queryParam;

  const location = useLocation();
  const navigate = useNavigate();

  const { categoryId } = useCasinoCategoryFetch({
    subType: launchInProcess ? CategorySubTypes.IN_GAME_SEARCHED_RESULTS : CategorySubTypes.RESULT_SEARCHED,
    categoryIdKey: CategorySubTypes.RESULT_SEARCHED,
    skipAbortFetch: true,
  });

  const { categoryId: mostSearchedCategory } = useCasinoCategoryFetch({
    subType: launchInProcess ? CategorySubTypes.IN_GAME_MOST_SEARCHED_RESULTS : CategorySubTypes.MOST_SEARCHED,
    categoryIdKey: CategorySubTypes.MOST_SEARCHED,
    skipAbortFetch: true,
  });

  useShowAppFooter([false, false]);

  useEffect(() => {
    if (searchValue.length < 2 && (queryParam === null || queryParam.length < 2) && !providersSearchIsActive) {
      dispatch(resetCasinoSearch());
      return;
    }
  }, [searchValue]);

  useEffect(() => {
    return () => {
      if (searchValue && location.pathname === `/${SearchType.Casino}`) {
        navigate(-1);
        return;
      }
      dispatch(resetCasinoSearch());
      dispatch(resetFilteredProviderIds());
      dispatch(setCasinoSearchValue(''));
    };
  }, [location.pathname]);

  return {
    searcherCategoryId: categoryId,
    mostSearchCategoryId: mostSearchedCategory,
    searchValue,
    hasSearchParams,
  };
};
