import React, { HTMLCustomAttributes } from 'react';
import clsx from 'clsx';
import Icon from 'components/shared/Icon';
import { HTMLButtonProps, btnIconSizesMap, cssBtnNS } from './Button.types';
import './Button.scss';

/** EGTD Platform HTML Button */
const HTMLButton = React.forwardRef<HTMLButtonElement, HTMLButtonProps & HTMLCustomAttributes<HTMLButtonElement>>(
  (
    { variant = 'primary', size = 'xl', text, isActive, className, icon, iconPath, iconClassName, children, ...rest },
    ref,
  ) => {
    const btnClass = clsx(
      `${cssBtnNS}`,
      `${cssBtnNS}--${variant}`,
      `${cssBtnNS}--${size}`,
      isActive && `${cssBtnNS}--active`,
      className,
    );

    const iconClass = clsx(`${cssBtnNS}__icon`, iconClassName);
    const iconSize = btnIconSizesMap[size];

    return (
      <button ref={ref} className={btnClass} {...rest}>
        {children ? (
          <div className="d-flex-col-ac">
            <div className="d-flex-ac">
              {text && text}
              {icon && (
                <Icon defaultClassName={false} className={iconClass} size={iconSize} icon={icon} iconPath={iconPath} />
              )}
            </div>
            {children}
          </div>
        ) : (
          <>
            {text && text}
            {icon && (
              <Icon defaultClassName={false} className={iconClass} size={iconSize} icon={icon} iconPath={iconPath} />
            )}
          </>
        )}
      </button>
    );
  },
);

HTMLButton.displayName = 'EGTD Platform HTML Button';

export default HTMLButton;
