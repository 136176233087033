import { memo } from 'react';
import { playtechPokerSelectors } from 'pages/playtechPoker/slice/playtechPokerLogin.slice';
import { getPlaytechPokerFooterSections } from 'pages/playtechPoker/utils/playtechPokerLogin.utils';
import { svaraAppSelectors } from 'pages/svaraAppLogin/slice/svaraAppLogin.slice';
import { getSvaraAppFooterSections } from 'pages/svaraAppLogin/utils/svaraAppLogin.utils';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import FooterSection from './FooterSection';
import { selectFooter } from './slice/appFooter.slice';
import { FooterTypes } from './types/appFooter.types';
import { selectNavigationMenu } from '../navigationMenu/slice/navigationMenu.slice';

interface Props {
  apiUri?: string;
}

const AppFooter: React.FC<Props> = () => {
  const isAppFooterVisible = useAppSelector(selectFooter.isVisible);
  const footerItems = useAppSelector(selectNavigationMenu.footerSections);
  const openFromPoker = useAppSelector(playtechPokerSelectors.openFromPoker);
  const openFromSvara = useAppSelector(svaraAppSelectors.openFromSvara);
  const openLeaderboard = useAppSelector(svaraAppSelectors.openLeaderboard);

  const footerSections = openFromPoker
    ? getPlaytechPokerFooterSections(footerItems)
    : openFromSvara
      ? getSvaraAppFooterSections(footerItems)
      : footerItems;

  if (!isAppFooterVisible || isEmpty(footerSections) || openLeaderboard) return null;

  return (
    <>
      {footerSections.map((gridItem: FooterTypes.Section, idx: number) => (
        <FooterSection index={idx} key={idx} gridItem={gridItem} />
      ))}
    </>
  );
};

export default memo(AppFooter);
