import React from 'react';
import { useTranslation } from 'react-i18next';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { SSBT } from 'utils/common/translationUtils/translationStrings';
import { TerminalModalUI } from './ssbtModalUI';

const TerminalInvalidModal = () => {
  const { t } = useTranslation();
  const { closeModal } = useHandleModals();
  return (
    <TerminalModalUI.Wrapper isVisible={true}>
      <TerminalModalUI.Close text={'Close'} onClick={closeModal} />

      <TerminalModalUI.Body>
        <TerminalModalUI.Status status="error" text={t(SSBT.TerminalModal.invalidCodeText)} />
      </TerminalModalUI.Body>
    </TerminalModalUI.Wrapper>
  );
};

export default TerminalInvalidModal;
