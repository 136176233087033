import { isMobileOnly } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { FooterUI } from 'components/shared/FooterUI';
import { Breakpoints } from 'theme/Theme';
import config from 'utils/config';
import { FooterTypes } from '../types/appFooter.types';

interface Props {
  sections: FooterTypes.Section;
}

export const HelpBlock: React.FC<Props> = ({ sections }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isMobile}px)` });
  const renderLinks = (item, sectionTypeName?: string) => {
    const externalLink = item.externalLink
      ? item.externalLink.includes('https://') || item.externalLink.includes('http://')
        ? item.externalLink
        : `https://${item.externalLink}`
      : null;

    return item.typeName === 'email' || item.typeName === 'phone' ? (
      <FooterUI.MenuLink
        linkRoute={`${item.title}`}
        text={item.title}
        type={item.typeName ?? 'link'}
        icon={item.icon}
        sectionType={sectionTypeName}
      />
    ) : item.typeName === 'socials' ? (
      <>
        {item?.linkSocials.map((social, index) => {
          const iconSrc = `${config.CDN_URL}/${social.icon ?? ''}`;

          return (
            <FooterUI.SocialIcon
              key={index + 'markets'}
              iconSrc={iconSrc}
              alt={social.title ?? ''}
              linkRoute={social.externalLink ?? ''}
            />
          );
        })}
      </>
    ) : item.typeName === 'player-bet-statistics' ? (
      <FooterUI.MenuHook
        hookType={item.typeName}
        text={item.title}
        icon={item.icon}
        sectionType={sectionTypeName}
        shouldAuthenticate
      />
    ) : item?.internalLink ? (
      <FooterUI.MenuLink
        linkRoute={item.internalLink}
        type={item.typeName ?? 'link'}
        text={item.title}
        icon={item.icon}
        sectionType={sectionTypeName}
      />
    ) : item?.externalLink && externalLink ? (
      <FooterUI.MenuLink
        isExternal
        linkRoute={externalLink}
        type={item.typeName ?? 'link'}
        text={item.title}
        icon={item.icon}
        sectionType={sectionTypeName}
      />
    ) : (
      <>
        <FooterUI.Image icon={item.icon} title={item.title as string} sectionType={sectionTypeName} />

        {item.title}
      </>
    );
  };
  const renderCols = (even: boolean) => {
    return sections.items?.map((item, idx) => {
      const rules = even ? idx % 2 : !(idx % 2);
      return rules ? (
        <FooterUI.Menu title={item.title ? item.title : ''} key={idx}>
          {item?.itemLinks?.map((option) => (
            <FooterUI.MenuItem key={option.id} isForeignLink text={renderLinks(option, item.typeName)} />
          ))}
        </FooterUI.Menu>
      ) : null;
    });
  };
  return (
    <>
      {isMobileOnly || isMobile ? (
        <>
          <FooterUI.Column>{renderCols(false)}</FooterUI.Column>
          <FooterUI.Column>{renderCols(true)}</FooterUI.Column>
        </>
      ) : (
        <>
          {sections.items?.map((item, idx) => (
            <FooterUI.Column type={item.typeName} key={idx}>
              <FooterUI.Menu key={item.title ? item.title : 'a' + 1} title={item.title ? item.title : ''}>
                {item?.itemLinks?.map((option, index) => (
                  <FooterUI.MenuItem key={index} isForeignLink text={renderLinks(option, item.typeName)} />
                ))}
              </FooterUI.Menu>
            </FooterUI.Column>
          ))}
        </>
      )}
    </>
  );
};
