import React from 'react';
import clsx from 'clsx';
import Icon from 'components/shared/Icon';
import { Flex } from 'components/shared/layout/Flex';
import { CSS } from 'utils/common/types/css.types';

interface Props {
  checked?: boolean;
  checkedVariant: CSS.Variants;
  className?: string;
  onClick?: (isChecked: boolean) => void | (() => void);
}

const Checkbox: React.FC<Props> = ({ checked, checkedVariant, className, onClick }: Props) => {
  const checkboxClass = clsx(
    'checkbox',
    checked && 'checkbox--checked checkbox--checked--' + checkedVariant,
    className,
  );
  return (
    <Flex.Center className={checkboxClass} onClick={onClick ? () => onClick(!checked) : () => undefined}>
      {checked && <Icon className="checkbox__icon" icon={`checkbox-mark`} />}
    </Flex.Center>
  );
};

export default Checkbox;
