const retailFormatDate = (date: Date) => {
  const day = ('0' + date.getDate()).slice(-2); // Day of the month, padded with leading zeros if necessary
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Month, 0-based so +1 is needed
  const year = date.getFullYear();
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  const seconds = ('0' + date.getSeconds()).slice(-2);

  const formattedTime = { clock: `${hours}:${minutes}:${seconds}`, date: `${day}/${month}`, year: year };

  return formattedTime;
};

export { retailFormatDate };
