import { replaceBulk } from 'modules/casino/shared/utils/common/interceptors.utils';
import { CasinoGamesTypes } from '../../cellsContainer/cellls/types/casinoGamesTypes';
import { CasinoWidgetsTypes, DiffFormat } from '../types/casinoWidgetsTypes';

const intervalsInSeconds = {
  day: 86400,
  hour: 3600,
  minute: 60,
};

const timeSince = (winTime) => {
  const dateNow = new Date().getTime();
  const dateWin = new Date(winTime).getTime();
  const diff = dateNow - dateWin;
  const seconds = Math.floor(diff / 1000);

  // days
  let interval = seconds / intervalsInSeconds.day;
  if (interval > 1) {
    return {
      diff: Math.floor(interval),
      diffFormat: interval < 2 ? DiffFormat.Day : DiffFormat.Days,
    };
  }
  // hours
  interval = seconds / intervalsInSeconds.hour;
  if (interval > 1) {
    return {
      diff: Math.floor(interval),
      diffFormat: interval < 2 ? DiffFormat.Hour : DiffFormat.Hours,
    };
  }
  // minutes
  interval = seconds / intervalsInSeconds.minute;
  if (interval > 1) {
    return {
      diff: Math.floor(interval),
      diffFormat: DiffFormat.Minutes,
    };
  }
  // seconds
  return {
    diff: seconds,
    diffFormat: DiffFormat.Seconds,
  };
};

export const formatDiffByLanguage = (winTime, t) => {
  const { diff, diffFormat } = timeSince(winTime);
  const text = t(`Casino.CasinoWidget.${diffFormat}`);
  const result = replaceBulk(text, ['timeframe'], [diff]);

  return result;
};

export const getLastWinnerLiveImageLocation = ({
  resource,
  type,
}: {
  resource: CasinoGamesTypes.GameResource;
  type: CasinoWidgetsTypes.WidgetTypes;
}): string => {
  if (!resource) {
    return '';
  }

  const { categoryUseStatic, lobbyUseStatic, thumbnailLocation } = resource || {};
  const useStatic = type === 'SECTION' ? lobbyUseStatic : categoryUseStatic;

  return !useStatic && thumbnailLocation ? thumbnailLocation : '';
};
