import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Flex } from 'components/shared/layout';
import { Modal } from 'components/shared/Modal';
import { ModalUI } from 'components/shared/ModalUI';
import { UI } from 'components/shared/UI';
import { usePreloadImage } from 'hooks/common/usePreloadImage';
import AnimatedCounter from 'shared/common/features/animatedCounter/AnimatedCounter';
import ParseHTML from 'shared/common/ParseHTML';
import { Breakpoints } from 'theme/Theme';
import { cdnConfig, fitCover } from 'utils/common/cdnConfig';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import config from 'utils/config';
import { useNotificationHandlers } from './hooks/useNotificationHandlers';
import { NotificationTypes } from './notificationTypes';

interface Props {
  notification: NotificationTypes.PopUpNotification;
}

const imagePath = (itemRef, imageName: string | undefined) => {
  return `${config.CDN_IMAGE_API}${fitCover(
    itemRef?.current && itemRef.current?.clientWidth * 2,
    itemRef?.current && itemRef.current?.clientHeight * 2,
    'contain',
  )}${cdnConfig.NOTIFICATIONS_MODULE}/${imageName}`;
};

const PopUpNotificationBGModal: React.FC<Props> = ({ notification }): JSX.Element => {
  const { t } = useTranslation();
  const { handleAcceptButtonClick, handleCloseButtonClick } = useNotificationHandlers(notification);

  const modalRef = useRef<HTMLDivElement>(null);

  const [bgUrl, setBgUrl] = useState('');

  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isTabletOrMobile}px)` });
  useLayoutEffect(() => {
    if (modalRef.current) {
      setBgUrl(
        imagePath(
          modalRef,
          isTabletOrMobile ? notification.style.backgroundResourceMobile : notification.style.backgroundResourceDesktop,
        ),
      );
    }
  }, [modalRef, notification.style.backgroundResourceMobile, notification.style.backgroundResourceDesktop]);

  const { isImageLoaded } = usePreloadImage(bgUrl, handleCloseButtonClick, 0);

  useEffect(() => {
    if (isTabletOrMobile) {
      document.body.classList.add('modal-open');
      document.body.classList.add('no-select');
    }

    return () => {
      document.body.classList.remove('modal-open');
      document.body.classList.remove('no-select');
    };
  }, [isTabletOrMobile]);

  return (
    <Modal visible={isImageLoaded} className="modal-custom-bg" size="xxl">
      <Modal.Body className="modal-scrollbars" style={{ backgroundImage: `url(${bgUrl})` }} bodyRef={modalRef}>
        <div className={'modal-content-wrapper'}>
          <Modal.Close
            closeText={t(MyProfile.OffersTab.closeBtn)}
            closeAction={handleCloseButtonClick}
            style={{ background: notification.style?.textBackground }}
            className="mb-1"
          />
          <div className="modal__text-wrapper" style={{ background: notification.style?.textBackground }}>
            <Flex.AC className="mb-1">
              <ModalUI.Title
                hasAccent
                text={notification.header}
                style={{ color: notification.style?.headerTextColor }}
                hasCustomStyle
              />
            </Flex.AC>
            <ModalUI.Title text={notification.subheader} style={{ color: notification.style?.subheaderTextColor }} />
            <div className="modal__text mt-2" style={{ color: notification.style?.messageTextColor }}>
              <ParseHTML html={notification?.message || ''} options={{ trim: true }} />
            </div>
          </div>

          {notification?.style?.counterTime && (
            <AnimatedCounter
              expire={notification?.style?.counterExpire}
              text={notification?.style?.counterLabel}
              date={notification?.style?.counterTime}
              componentLocation="counterPromotion"
            />
          )}

          {notification.button?.buttonUrl && (
            <Flex.Center>
              <UI.Button
                style={{ background: notification.style?.backgroundButton, color: notification.style?.buttonTextColor }}
                onClick={handleAcceptButtonClick}
                text={notification.button?.buttonText}
                className="mt-2"
              />
            </Flex.Center>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PopUpNotificationBGModal;
