import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Autoplay, Mousewheel, Navigation, Pagination, Scrollbar, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Modal } from 'components/shared';
import { SwiperInstance } from 'components/shared/reactIdSwiper';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import { SliderNotificationsItem } from './components/SliderNotificationsItem';
import { SliderNotificationsItemThumb } from './components/SliderNotificationsItemThumb';
import { usePopUpSliderNotificationModal } from './hooks/usePopUpSliderNotificationModal';
import { onAutoplayTimeLeft, slideChange } from './utils/popUpSliderNotificationModal.utils';
import { selectNotification } from '../../notificationSlice';
import { NotificationTypes } from '../../notificationTypes';

type Props = {
  notification: NotificationTypes.PopUpNotification;
};

const PopUpSliderNotificationModal: React.FC<Props> = () => {
  const { t } = useTranslation();
  const modalRef = useRef(null);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperInstance | null>(null);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const paginationRef = useRef<HTMLDivElement>(null);

  const popUpNotifications = useAppSelector(selectNotification.popUpSliderNotifications);
  const isMoreThanOne = Object.keys(popUpNotifications).length > 1;

  const notificationCarousel = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.NOTIFICATIONS,
  })?.carousel;

  const carouselDelay = notificationCarousel?.delay ?? 7000;

  useLayoutEffect(() => {
    if (paginationRef.current) {
      const activeBullet = paginationRef.current?.childNodes[0] as HTMLElement;
      activeBullet && activeBullet.classList.add('ns-pagination__bullet--active');
    }
  }, [paginationRef]);

  const { handleCloseModal, callbackNotificationId } = usePopUpSliderNotificationModal();

  useEffect(() => {
    if (!isEmpty(popUpNotifications)) {
      document.body.classList.add('modal-open');
    }

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [modalRef, popUpNotifications]);

  const handleOnTouch = (swiper) => {
    if (isMobile) {
      swiper.autoplay.stop();
      // reset active pagination state
      onAutoplayTimeLeft(swiper, 1, 2000, paginationRef, true);
    }
  };

  if (isEmpty(popUpNotifications)) return null;

  return (
    <Modal visible size="xxl" className="h-100" scrollable isFullScreen>
      <div className="ns-modal" ref={modalRef}>
        <Modal.Close
          className={`ns-close ${!isEnd ? 'ns-close--disable' : ''}`}
          closeText={t(MyProfile.OffersTab.closeBtn)}
          closeAction={isEnd ? handleCloseModal : undefined}
        />
        <div className="position-relative">
          {isMoreThanOne && <div className="swiper-button-prev ns-button-prev"></div>}

          <Swiper
            slidesPerView={1}
            onSlideChange={(swiper) => slideChange({ swiper, setIsEnd, paginationRef, callbackNotificationId })}
            navigation={{
              enabled: isMoreThanOne,
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            onInit={(swiper) => slideChange({ swiper, setIsEnd, paginationRef, callbackNotificationId, isInit: false })}
            onTouchStart={handleOnTouch}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[Autoplay, Pagination, Navigation, Thumbs, Mousewheel, Scrollbar]}
            className="ns-carousel"
            mousewheel={{
              forceToAxis: true,
            }}
            observer={true}
            speed={1000}
            onUpdate={(swiper) => slideChange({ swiper, setIsEnd, paginationRef, callbackNotificationId })}
            autoplay={
              isMoreThanOne
                ? {
                    delay: carouselDelay,
                    pauseOnMouseEnter: true,
                    stopOnLastSlide: true,
                    disableOnInteraction: false,
                  }
                : {}
            }
            onAutoplayTimeLeft={(swiper, time, progress) =>
              isMoreThanOne && onAutoplayTimeLeft(swiper, time, progress, paginationRef, false)
            }
          >
            {Object.keys(popUpNotifications).map((key) => (
              <SwiperSlide key={key} data-id={popUpNotifications[key].id}>
                <SliderNotificationsItem item={popUpNotifications[key]} />
              </SwiperSlide>
            ))}
          </Swiper>
          {isMoreThanOne && <div className="swiper-button-next ns-button-next"></div>}
        </div>
        {isMoreThanOne && (
          <>
            <Swiper
              onSwiper={(swiper) => setThumbsSwiper(swiper)}
              spaceBetween={10}
              allowTouchMove={false}
              slidesPerView={'auto'}
              watchSlidesProgress={true}
              modules={[Thumbs, Pagination]}
              touchRatio={0}
              observer={true}
              className="ns-thumbs"
            >
              {Object.keys(popUpNotifications).map((key) => (
                <SwiperSlide key={key}>
                  <SliderNotificationsItemThumb item={popUpNotifications[key]} />
                </SwiperSlide>
              ))}
            </Swiper>

            {/* PAGINATION */}
            <div className="ns-pagination" ref={paginationRef}>
              {Object.keys(popUpNotifications).map((key) => (
                <div key={key} className="ns-pagination__bullet">
                  <div className="ns-pagination__progress"></div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default PopUpSliderNotificationModal;
