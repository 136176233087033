import { selectTicketData } from 'modules/retail/shared/slice/checkTicket.slice';
import { useAppSelector } from 'store';
import { selectCashierData } from '../../slice/cashier.slice';

export const useCashierModalTicketErrors = () => {
  const cashierCancelTicketStatusError = useAppSelector(selectCashierData.cashierCancelTicketStatusError);
  const payoutTicketError = useAppSelector(selectCashierData.payoutTicketError);
  const ticketTerminalInfoErrorMessage = useAppSelector(selectTicketData.ticketInfoErrorMessage);

  return {
    cashierCancelTicketStatusError,
    payoutTicketError,
    ticketTerminalInfoErrorMessage,
  };
};
