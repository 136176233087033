import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { hideRootModal } from '../../rootModal/slice/rootModal.slice';
import { prizeDropThunks, resetCampaignsState, selectPrizeDrop } from '../slice/prizeDrop.slice';

const POLLING_INTERVAL = 60000;

export const usePrizeDropCampaigns = (isInGameBar?: boolean) => {
  const prizeDropStatus = useAppSelector(selectPrizeDrop.status);

  const interval = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const promise = dispatch(prizeDropThunks.getAllCampaigns({ isInGameBar }));

    return () => {
      dispatch(resetCampaignsState());
      if (promise && promise.abort) {
        promise.abort();
      }
    };
  }, [dispatch]);

  useEffect(() => {
    interval.current && clearInterval(interval.current);

    let intervalPromise;

    interval.current = setInterval(() => {
      intervalPromise = dispatch(prizeDropThunks.getAllCampaigns({ isPolling: true, isInGameBar }));
    }, POLLING_INTERVAL);

    return () => {
      interval?.current && clearInterval(interval.current);
      intervalPromise?.abort && intervalPromise.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    if (prizeDropStatus === 'failure') {
      dispatch(hideRootModal());
    }
  }, [dispatch, prizeDropStatus]);

  return {
    prizeDropStatus,
    isLoading: prizeDropStatus === 'idle' || prizeDropStatus === 'pending',
  };
};
