import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';
import { selectLanguage } from 'shared/common/features/settings/slice/settings.slice';
import { loginUser, logoutUser } from 'shared/common/sharedSlices/commonActions';
import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getBusinessUnit, getError, isEmpty, safeJSONParse, sortByRank } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { PlayerTournaments } from '../promotionLeaderBoard/leaderBoardTypes';
import { PromotionTypes } from '../types/promotions.types';

const initialState: PromotionTypes.State = {
  activeMenuItemId: 0,
  promotionsMenuItems: {},
  promotionsLobbyItems: {},
  lobbyItemsLoading: false,
  promotionResponse: {
    key: { data: undefined, status: 0 },
  },
  ttl: new Date().getTime(),
  tournamentAwards: null,
  prizeDropPlayer: null,
  prizeDropData: null,
  tournamentAwardsHasFetched: false,
};

export const promotionThunks: PromotionTypes.Thunks = {
  getPrizeDropData: createAbortThunk('promotion/getPrizeDropData', async (prizeDropId, { source, rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<PromotionTypes.AllPrizeDrop>(
        `${config.API_URL}/api/ews-bonuses/public/promotions/prize-drop/${prizeDropId}`,
        { cancelToken: source.token },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(getError.responseData(error));
    }
  }),
  getPrizeDropPlayer: createAbortThunk(
    'promotion/getPrizeDropPlayer',
    async (prizeDropId, { source, rejectWithValue }) => {
      try {
        const response = await axiosInstance.get<PromotionTypes.PlayerPrizeDrop>(
          `${config.API_URL}/api/ews-bonuses/player/player-leaderboard/player-rewards/${prizeDropId}`,
          { cancelToken: source.token },
        );
        return response.data;
      } catch (error) {
        return rejectWithValue(getError.responseData(error));
      }
    },
  ),
  getTournamentRankings: createAbortThunk(
    'leaderBoard/getTournamentRankings',
    async ({ tournamentId, currencyCode }, { source }) => {
      const response = await axiosInstance.get(
        `${config.API_URL}/api/player-tournaments/public/player-tournaments/get-additional-info/${tournamentId}`,
        {
          ...(currencyCode ? { params: { currencyCode } } : {}),
          cancelToken: source.token,
          headers: { 'X-Platform-Origin': getBusinessUnit() },
        },
      );

      return response.data;
    },
    {},
    (error, rejectWithValue) => rejectWithValue(error?.response.data),
  ),
  fetchPromotionByAlias: createAbortThunk(
    'promotion/fetchPromotionByAlias',
    async (arg, { source, getState, rejectWithValue }) => {
      const fetchURL = `${config.API_URL}/api/ews-bonuses/public/promotions/alias-promotions?alias=${arg}`;
      try {
        const response = await axiosInstance.get<PromotionTypes.Promotion>(fetchURL, {
          data: null,
          cancelToken: source.token,
        });
        const data = response.data;
        data.lang = getState().common.settings.language;
        return { status: response.status, data: response.data, hasFetched: true };
      } catch (err) {
        return rejectWithValue(JSON.stringify({ [arg]: (err as AxiosError)?.response?.status }));
      }
    },
  ),
  fetchLobbyItems: createAbortThunk('promotion/fetchPromotionsLobbyItems', async (_, { source, getState }) => {
    const fetchURL = `${config.API_URL}/api/ews-bonuses/public/promotions/category`;
    const response = await axiosInstance.get<PromotionTypes.Category[]>(fetchURL, {
      data: null,
      cancelToken: source.token,
    });

    return {
      cacheKey: getState().common.settings.language,
      lobbyItems: response.data,
    };
  }),
  fetchMenuItems: createAbortThunk('promotion/fetchMenuItems', async (_, { source, getState }) => {
    const fetchURL = `${config.API_URL}/api/ews-bonuses/public/promotions/menu`;
    const response = await axiosInstance.get<PromotionTypes.PromotionMenuItem[]>(fetchURL, {
      data: null,
      cancelToken: source.token,
    });

    const sortedItems = response.data.sort((item, nextItem) => item.rank - nextItem.rank);

    return {
      cacheKey: getState().common.settings.language,
      menuItems: sortedItems,
    };
  }),
};

const promotions = createSlice({
  name: 'promotions',
  initialState,
  reducers: {
    setActiveMenuItemId(state, action) {
      state.activeMenuItemId = action.payload;
    },
    setPromotionsByAlias(state, action) {
      state.promotionResponse = action.payload;
    },
    resetPromotionTTL(state, action) {
      state.ttl = action.payload;
    },
    resetTournamentAwards(state) {
      state.tournamentAwards = null;
      state.tournamentAwardsHasFetched = false;
    },
    resetPrizeDropData(state) {
      state.prizeDropData = null;
    },
    resetPrizeDropPlayerWins(state) {
      state.prizeDropPlayer = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        promotionThunks.fetchPromotionByAlias.fulfilled,
        (state, action: PayloadAction<PromotionTypes.Response<PromotionTypes.Promotion>>) => {
          const { data } = action.payload;
          data.ttl = new Date().getTime();
          delete state.promotionResponse['error'];
          state.promotionResponse[data.alias] = action.payload;
        },
      )
      .addCase(promotionThunks.fetchPromotionByAlias.rejected, (state, action) => {
        const error = action.payload && safeJSONParse(action.payload);
        const errorStatus = error && error[Object.keys(error)[0]];
        state.promotionResponse[Object.keys(!isEmpty(error) && error)[0]] = {
          data: undefined,
          status: errorStatus !== undefined && errorStatus !== null ? parseInt(errorStatus) : 404,
        };
      })
      .addCase(promotionThunks.fetchLobbyItems.pending, (state) => {
        state.lobbyItemsLoading = true;
      })
      .addCase(promotionThunks.fetchLobbyItems.fulfilled, (state, action) => {
        delete state.promotionResponse['error'];
        const { cacheKey, lobbyItems } = action.payload;
        /* EFED-8533 TODO - Remove mapping when startDateTime prop is implemented everywhere */
        state.promotionsLobbyItems = {
          ...state.promotionsLobbyItems,
          [cacheKey]: lobbyItems.map((lobbyItem) => ({
            ...lobbyItem,
            promotions: lobbyItem.promotions.map((promotion) => ({
              ...promotion,
              startDate: promotion.startDateTime || promotion.startDate,
              startDateTime: promotion.startDateTime || promotion.startDate,
              endDate: promotion.endDateTime || promotion.endDate,
              endDateTime: promotion.endDateTime || promotion.endDate,
            })),
          })),
        };
        state.lobbyItemsLoading = false;
        state.ttl = new Date().getTime();
      })
      .addCase(promotionThunks.fetchLobbyItems.rejected, (state) => {
        state.promotionsLobbyItems = {};
        state.lobbyItemsLoading = false;
      })
      .addCase(promotionThunks.fetchMenuItems.fulfilled, (state, action) => {
        const { cacheKey, menuItems } = action.payload;
        state.promotionsMenuItems = { ...state.promotionsMenuItems, [cacheKey]: sortByRank(menuItems) };
      })
      .addCase(promotionThunks.fetchMenuItems.rejected, (state) => {
        state.promotionsMenuItems = {};
      });
    builder
      .addCase(promotionThunks.getTournamentRankings.pending, (state) => {
        state.tournamentAwardsHasFetched = false;
        state.tournamentAwards = null;
      })
      .addCase(promotionThunks.getTournamentRankings.rejected, (state) => {
        state.tournamentAwards = null;
        state.tournamentAwardsHasFetched = false;
      })
      .addCase(promotionThunks.getTournamentRankings.fulfilled, (state, action) => {
        state.tournamentAwards = action.payload;
        state.tournamentAwardsHasFetched = true;
      });
    builder
      .addCase(promotionThunks.getPrizeDropData.rejected, (state) => {
        state.prizeDropData = null;
      })
      .addCase(promotionThunks.getPrizeDropData.fulfilled, (state, action) => {
        state.prizeDropData = action.payload;
      });
    builder
      .addCase(promotionThunks.getPrizeDropPlayer.rejected, (state) => {
        state.prizeDropPlayer = null;
      })
      .addCase(promotionThunks.getPrizeDropPlayer.fulfilled, (state, action) => {
        state.prizeDropPlayer = action.payload;
      });
    builder.addCase(logoutUser, () => initialState);
    builder.addCase(loginUser, () => initialState);
  },
});

export const {
  setPromotionsByAlias,
  resetPromotionTTL,
  setActiveMenuItemId,
  resetTournamentAwards,
  resetPrizeDropData,
  resetPrizeDropPlayerWins,
} = promotions.actions;

export const selectPromotions = {
  tournamentAwards: (state: RootState) => state.promotion.promotions.tournamentAwards?.awards,
  rankingInfo: (rankingType: PlayerTournaments) => (state: RootState) =>
    state.promotion.promotions.tournamentAwards?.rankingsInfo?.[rankingType],
  isChallenge: (state: RootState) => state.promotion.promotions.tournamentAwards?.isChallenge,
  isChallengeFinished: (state: RootState) => state.promotion.promotions.tournamentAwards?.isChallengeFinished,
  tournamentAwardsHasFetched: (state: RootState) => state.promotion.promotions.tournamentAwardsHasFetched,
  prizeDropPlayer: (state: RootState) => state.promotion.promotions?.prizeDropPlayer,
  prizeDropData: (state: RootState) => state.promotion.promotions?.prizeDropData,
  activeMenuItemId: (state: RootState) => state.promotion.promotions.activeMenuItemId,
  lobbyItemsLoading: (state: RootState) => state.promotion.promotions.lobbyItemsLoading,
  response:
    (alias?: string) =>
    (state: RootState): PromotionTypes.Response<PromotionTypes.Promotion | undefined> => {
      return alias && state.promotion.promotions.promotionResponse[alias];
    },
  ttl: (state: RootState) => state.promotion.promotions.ttl,
  menuItems: createSelector(
    [selectLanguage, (state) => state.promotion.promotions.promotionsMenuItems],
    (lang, menuItems): PromotionTypes.PromotionMenuItem[] => menuItems[lang] || [],
  ),
  lobbyItems: createSelector(
    [selectLanguage, (state) => state.promotion.promotions.promotionsLobbyItems],
    (lang, lobbyItems): PromotionTypes.Category[] => lobbyItems[lang] || [],
  ),
};

export const selectPromotionAliasByCategory = createSelector(
  selectPromotions.lobbyItems,
  (lobbyItems): Record<string, string[]> =>
    [...lobbyItems].reverse().reduce((acc, item) => {
      item.promotions.forEach((promotion) => {
        acc[promotion.alias] = item.alias;
      });

      return {
        ...acc,
      };
    }, {}),
);

export const selectActivePromotion = createCachedSelector(
  selectPromotions.lobbyItems,
  (_, alias: string) => alias,
  (lobbyItems, alias): PromotionTypes.Category | undefined => lobbyItems.find((item) => item.alias === alias),
)((_, alias) => alias);

export default promotions.reducer;
