import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { createAbortThunk, rejectedWrapper } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { FantasyStarsTypes } from '../types/fantsyStars.types';

export const fetchFantasyStarsToken = createAbortThunk<string, undefined>(
  'fetch/FantasyStarsUserToken',
  async (_, { rejectWithValue }) => {
    const apiUrl = `${config.API_URL}/api/fantasy/v1/ffstars/token`;
    const response = await axiosInstance.put(`${apiUrl}`);
    try {
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseDataMessage(err));
    }
  },
);

export const deleteFantasyStarsToken = createAbortThunk<string, undefined>(
  'delete/FantasyStarsUserToken',
  async (_, { rejectWithValue }) => {
    const apiUrl = `${config.API_URL}/api/fantasy/v1/ffstars/token`;
    const response = await axiosInstance.delete(`${apiUrl}`);
    try {
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseDataMessage(err));
    }
  },
);

const initialState: FantasyStarsTypes.State = {
  token: '',
};

const fantasyStarsSlice = createSlice({
  name: 'fantasyStarsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFantasyStarsToken.fulfilled, (state, action) => {
        state.token = action.payload;
      })
      .addCase(
        fetchFantasyStarsToken.rejected,
        rejectedWrapper((state) => {
          state.token = '';
        }),
      )
      .addCase(deleteFantasyStarsToken.fulfilled, (state) => {
        state.token = '';
      })
      .addCase(
        deleteFantasyStarsToken.rejected,
        rejectedWrapper((state) => {
          state.token = '';
        }),
      );
  },
});

export const selectFantasyStars = {
  token: (state: RootState): string => state.fantasyStars.token,
};

export default fantasyStarsSlice.reducer;
