export declare module WsNotificationsTypes {
  type Bonus = {
    title: string;
    campaignId?: number;
    campaignName: string;
    promotionAlias?: string;
    categoryAlias?: string;
    groupFolderIndex?: string;
    levelFolderIndex?: string;
    jackpotName?: string;
    bannerResource?: string;
    jackpotMultiplier?: string;
    jackpotTotalAmount?: string;
    jackpotInitialAmount?: string;
    jackpotLevelDescription?: string;
    image?: string;
  };

  type NotificationType =
    | 'InGamePopup'
    | 'BonusCollectionPopup'
    | 'JackpotPopup'
    | 'InGameAnimationPopup'
    | 'GamePopup';

  type Notification = {
    PlayerId: string;
    Id: string;
    Message: string | null;
    Header: string | Record<string, string> | null;
    Subheader: string | Record<string, string> | null;
    Style: { type: NotificationType; animationKey?: string; folder: string };
    ValidUntil: number;
    Button?: {
      buttonText?: string;
      buttonUrl?: string;
    };
    BonusData: { bonuses: Bonus[] };
  };
}

export const JackpotPopupType = 'JackpotPopup';
