import { useCallback, useEffect, useState } from 'react';
import { getUserTimeByTimezoneOffset } from 'modules/casino/shared/utils/dateUtils';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import {
  reprintInfoThunk,
  resetSearchFiltersForNexStepPagination,
  saveSearchFiltersForNexStepPagination,
  selectReprintTicketData,
  setExpandedTicket,
} from '../slice/reprintTicket.slice';

export const useTransactionHistoryCashierState = () => {
  const [selectedDates, setSelectedDates] = useState({ startDate: '', endDate: '' });
  const [selectedTerminal, setSelectedTerminal] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [isInitialSearch, setIsInitialSearch] = useState(true);
  const numberOfItemsInPageSize = 10;
  const [selectedCurrentPage, setCurrentPage] = useState<number>(1);
  const ticketHistoryData = useAppSelector(selectReprintTicketData.ticketHistoryData);
  const firstSelectedFilters = useAppSelector(selectReprintTicketData.filters);
  const status = useAppSelector(selectReprintTicketData.terminalDataStatus);
  const error = useAppSelector(selectReprintTicketData.terminalDataStatusError);
  const firsPage = 1;
  const totalItems = ticketHistoryData?.totalItems;
  const dispatch = useAppDispatch();

  const getNextPageTicketHistory = () => {
    if (!isEmpty(firstSelectedFilters)) {
      dispatch(
        reprintInfoThunk.getTicketHistory({
          FromDate: firstSelectedFilters?.selectedDate.startDate ?? '',
          ToDate: firstSelectedFilters?.selectedDate.endDate ?? '',
          dateFromTimestamp: firstSelectedFilters?.selectedDate.dateFromTimestamp ?? 0,
          dateToTimestamp: firstSelectedFilters?.selectedDate.dateToTimestamp ?? 0,
          ticketOperation: firstSelectedFilters?.operation ?? '',
          terminalId: firstSelectedFilters?.terminal ?? '',
          pageSize: firstSelectedFilters?.pageSize ?? numberOfItemsInPageSize,
          currentPage: selectedCurrentPage,
        }),
      );
    }
  };

  const saveFirstSelectedFilters = (formattedStartDate, formattedEndDate, startEpoch, endEpoch) => {
    dispatch(
      saveSearchFiltersForNexStepPagination({
        FromDate: formattedStartDate as string,
        ToDate: formattedEndDate as string,
        dateFromTimestamp: startEpoch as number,
        dateToTimestamp: endEpoch as number,
        ticketOperation: selectedOption as string,
        terminalId: selectedTerminal as string,
        pageSize: numberOfItemsInPageSize as number,
        currentPage: selectedCurrentPage as number,
      }),
    );
    setIsInitialSearch(false);
  };

  useEffect(() => {
    getNextPageTicketHistory();
  }, [selectedCurrentPage]);

  useEffect(() => {
    if (isInitialSearch) {
      dispatch(resetSearchFiltersForNexStepPagination());
    }
  }, [isInitialSearch, selectedDates]);

  const resetInnerState = () => {
    setSelectedDates({ startDate: '', endDate: '' });
    setSelectedTerminal(null);
    setSelectedOption(null);
    setCurrentPage(1);
  };
  const handleSearch = useCallback(() => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 2, 0, 0, 0);

    let startDate = new Date(selectedDates.startDate);
    let endDate = new Date(selectedDates.endDate);

    if (startDate < today) {
      startDate.setHours(0, 0, 0, 0);
      if (endDate > today || endDate.toDateString() === now.toDateString()) {
        endDate = now;
      } else {
        endDate.setHours(23, 59, 59);
      }
    } else {
      startDate = today;
      endDate = now;
    }

    const startEpoch = startDate.getTime();
    const endEpoch = endDate.getTime();

    const formatDateForUrl = (date) => encodeURIComponent(date.toISOString());
    const startDateByTimeZoneOffset = new Date(getUserTimeByTimezoneOffset(startDate));
    const endDateByTimeZoneOffset = new Date(getUserTimeByTimezoneOffset(endDate));
    const formattedStartDate = formatDateForUrl(startDateByTimeZoneOffset);
    const formattedEndDate = formatDateForUrl(endDateByTimeZoneOffset);

    setIsInitialSearch(true);

    dispatch(
      reprintInfoThunk.getTicketHistory({
        FromDate: formattedStartDate,
        ToDate: formattedEndDate,
        dateFromTimestamp: startEpoch,
        dateToTimestamp: endEpoch,
        ticketOperation: selectedOption,
        terminalId: selectedTerminal,
        pageSize: numberOfItemsInPageSize,
        currentPage: firsPage,
      }),
    );

    saveFirstSelectedFilters(formattedStartDate, formattedEndDate, startEpoch, endEpoch);
    setCurrentPage(firsPage); // Reset to the first page when a new search is initiated
    dispatch(setExpandedTicket(null));
  }, [selectedDates, selectedTerminal, selectedOption]);

  const state = {
    selectedDates,
    selectedTerminal,
    selectedOption,
    numberOfItemsInPageSize,
    selectedCurrentPage,
    totalItems,
    error,
    status,
  };

  const methods = {
    setSelectedDates,
    setSelectedTerminal,
    setSelectedOption,
    handleSearch,
    resetInnerState,
    getNextPageTicketHistory,
    setCurrentPage,
  };

  return { state, methods };
};

export default useTransactionHistoryCashierState;
