import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';
import { overrideLayoutConfigWithNumbers } from 'modules/casino/modules/lobby/containersLobby/utils/casinoLobbyUtils';
import { CategoryQueryType, RequestStatuses } from 'modules/casino/shared/constants';
import { Casino } from 'modules/casino/shared/types';
import { isValidCategoryParams } from 'modules/casino/shared/utils/casinoUtils';
import { getOSType } from 'modules/casino/shared/utils/common/helpersCommon';
import { createAbortThunk } from 'modules/casino/store/thunkCreators';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import axiosInstance from 'utils/common/axios-instance';
import config from 'utils/config';
import { CategorySubTypes } from '../../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { DEFAULT_CATEGORY } from '../constants/casinoCategoryConstants';
import { CasinoCategoryTypes } from '../types/casinoCategoryTypes';

const initialState: CasinoCategoryTypes.State = {
  categories: {},
  categoryLoadingState: {},
  categorySubTypeLoadingState: {},
};
export const categoryThunks: CasinoCategoryTypes.CategoryThunks = {
  fetchCategory: createAbortThunk(
    'category/fetchCategory',
    async ({ categoryId, subType, key, categoryConfig }, { source }) => {
      const fetchSubTypesConfigMap = [
        CategorySubTypes.FREE_SPIN,
        CategorySubTypes.FREE_SPIN_CHALLENGE,
        CategorySubTypes.FAVOURITES_MODAL,
        CategorySubTypes.RESULT_SEARCHED,
        CategorySubTypes.MOST_SEARCHED,
        CategorySubTypes.IN_GAME_FAVOURITES,
        CategorySubTypes.IN_GAME_SEARCH,
        CategorySubTypes.IN_GAME_MOST_PLAYED,
        CategorySubTypes.IN_GAME_RECOMMENDED,
        CategorySubTypes.IN_GAME_SEARCHED_RESULTS,
        CategorySubTypes.IN_GAME_FREE_SPIN,
      ];
      const fetchSubTypeCategory = subType && fetchSubTypesConfigMap.includes(subType);
      const fetchCategoryPath = fetchSubTypeCategory ? `subtype/${subType}` : `lobby-categories/${categoryId}`;
      const fetchUrl = `${config.API_URL}/api/gaming/public/categories/${fetchCategoryPath}`;
      const response = await axiosInstance.get<Casino.InnerCategory>(fetchUrl, {
        data: null,
        headers: { 'X-Platform-OS-type': getOSType() },
        cancelToken: source.token,
      });

      const responseData = response.data;

      if (fetchSubTypeCategory && !responseData[0]) {
        // if sub category not exist, use category data from layout config
        return { key, category: categoryConfig, subType };
      }

      if (fetchSubTypeCategory && responseData[0]) {
        // if sub category exist, use category data from response
        const categoryProps =
          subType === CategorySubTypes.IN_GAME_FAVOURITES
            ? {
                ...responseData[0],
                gameSection: {
                  type: 'FAVOURITES',
                  isActive: true,
                },
              }
            : responseData[0];
        return { key, category: categoryProps, subType };
      }

      return { key, category: response.data, subType };
    },
  ),
};

const casinoCategory = createSlice({
  name: 'CasinoCategory',
  initialState,
  reducers: {
    addGeneratedCategory(state, action) {
      const { categoryParams, key } = action.payload;
      const category = { ...DEFAULT_CATEGORY, ...categoryParams };
      state.categories[key] = {
        casinoCategory: category,
        refetchCategory: false,
        ttl: Date.now(),
      };
      state.categoryLoadingState[category.id] = RequestStatuses.SUCCESS;
    },
    setSubCategoryConfig(state, action: PayloadAction<CasinoCategoryTypes.CategoryResponse>) {
      const { category, subType, key } = action.payload;

      const _categories = Object.assign({}, state.categories);

      if (isValidCategoryParams({ category, subType })) {
        _categories[key] = {
          casinoCategory: category,
          refetchCategory: !!(category?.widgetTypes && category.widgetTypes?.includes('THIRD_PARTY')),
          ttl: Date.now(),
        };

        state.categories = _categories;
        state.categoryLoadingState[category.id] = RequestStatuses.SUCCESS;
        if (subType) {
          state.categorySubTypeLoadingState[subType] = RequestStatuses.SUCCESS;
        }
      } else {
        if (subType) {
          state.categorySubTypeLoadingState[subType] = RequestStatuses.ERROR;
        }
        state.categoryLoadingState[category.id] = RequestStatuses.ERROR;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(categoryThunks.fetchCategory.pending, (state, action) => {
        const categoryId = action.meta?.arg?.categoryId;
        const subType = action.meta?.arg?.subType;
        if (categoryId) {
          if (!state.categoryLoadingState[categoryId]) {
            state.categoryLoadingState[categoryId] = RequestStatuses.PENDING;
          }
        }

        if (subType) {
          if (!state.categoryLoadingState[subType]) {
            state.categorySubTypeLoadingState[subType] = RequestStatuses.PENDING;
          }
        }
      })
      .addCase(
        categoryThunks.fetchCategory.fulfilled,
        (state, action: PayloadAction<CasinoCategoryTypes.CategoryResponse | null>) => {
          if (action.payload !== null) {
            const { category, subType, key } = action.payload;
            if (!category && subType) {
              //TODO set status if sub category does not exist
              state.categorySubTypeLoadingState[subType] = RequestStatuses.EMPTY;
              return;
            }
            const _categories = Object.assign({}, state.categories);

            if (isValidCategoryParams({ category, subType })) {
              const { hasNumbers, layoutConfig } = category;
              let overwrittenLayoutConfig = layoutConfig;
              if (hasNumbers) {
                overwrittenLayoutConfig = overrideLayoutConfigWithNumbers(layoutConfig);
              }
              _categories[key] = {
                casinoCategory: hasNumbers ? { ...category, layoutConfig: overwrittenLayoutConfig } : category,
                refetchCategory: !!(category?.widgetTypes && category.widgetTypes?.includes('THIRD_PARTY')),
                ttl: Date.now(),
              };

              state.categories = _categories;
              state.categoryLoadingState[category.id] = RequestStatuses.SUCCESS;
              if (subType) {
                if (!state.categoryLoadingState[subType]) {
                  state.categorySubTypeLoadingState[subType] = RequestStatuses.SUCCESS;
                }
              }
            } else {
              if (subType) {
                if (!state.categoryLoadingState[subType]) {
                  state.categorySubTypeLoadingState[subType] = RequestStatuses.ERROR;
                }
              }
              state.categoryLoadingState[category.id] = RequestStatuses.ERROR;
            }
          }
        },
      )
      .addCase(categoryThunks.fetchCategory.rejected, (state, action) => {
        const categoryId = action.meta?.arg?.categoryId;
        if (categoryId) {
          state.categoryLoadingState[categoryId] = RequestStatuses.ERROR;
        }
      });
    builder.addCase(logoutUser, (state) => {
      const keys = Object.keys(state.categories);

      keys?.forEach((key) => {
        const { queryType, id } = state.categories[key]?.casinoCategory || {};

        if (queryType === CategoryQueryType.LAST_PLAYED_CATEGORY) {
          delete state.categories[key];
          delete state.categoryLoadingState[id];
        }
      });
    });
  },
});

export const { addGeneratedCategory, setSubCategoryConfig } = casinoCategory.actions;

const selectCachedCasinoCategory = (state: RootState, key: string): CasinoCategoryTypes.CachedCategory =>
  state.casino.casinoCategory.categories[key];

const selectCasinoCategory = (state: RootState, key: string): Casino.InnerCategory | undefined =>
  state.casino.casinoCategory.categories[key]?.casinoCategory;

const selectCategoryLoadingState = createCachedSelector(
  (state: RootState, categoryId: string | number) => state.casino.casinoCategory.categoryLoadingState[categoryId],
  (loadingState): RequestStatuses => {
    if (loadingState) return loadingState;
    return RequestStatuses.INITIAL;
  },
)((_, id) => id);

const selectSubTypeCategoryLoadingState = createCachedSelector(
  (state: RootState, subType: CategorySubTypes | undefined) =>
    subType && state.casino.casinoCategory.categorySubTypeLoadingState[subType],
  (loadingState): RequestStatuses => {
    if (loadingState) return loadingState;
    return RequestStatuses.INITIAL;
  },
)((_, id) => id);

export const categorySelectors = {
  cachedCasinoCategory: selectCachedCasinoCategory,
  casinoCategory: selectCasinoCategory,
  categoryLoadingState: selectCategoryLoadingState,
  subTypeCategoryLoadingState: selectSubTypeCategoryLoadingState,
};

export default casinoCategory.reducer;
