import { useRef } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { SelectBonusButton } from '../../../components/SelectBonusButton';

export const SelectBonusFooter = ({ goBack, isLastDisabled, acceptOffer, showPrev, folder, buttonText, style }) => {
  const disableFinishButton = useRef(false);
  const onFinish = () => {
    if (!disableFinishButton.current) {
      acceptOffer(true);
      disableFinishButton.current = true;
    }
  };

  return (
    <BtnsWrapper>
      <SelectBonusButton
        text={buttonText && buttonText?.prev}
        show={showPrev}
        image={'buttons/back.png'}
        folder={folder}
        handleOnClick={() => goBack(true)}
        btnColor={style.buttonTextColor}
      />
      <SelectBonusButton
        text={buttonText && buttonText?.finish}
        show={!isLastDisabled}
        image={'buttons/forward.png'}
        folder={folder}
        onlyOne={!showPrev && !isLastDisabled}
        handleOnClick={onFinish}
        btnColor={style.buttonTextColor}
      />
    </BtnsWrapper>
  );
};

const BtnsWrapper = styled(motion.div)`
  display: flex;
  justify-content: space-between;
`;
