import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UI } from 'components/shared/UI';
import CustomCheckButton from 'modules/retail/modules/ssbt/components/ssbtModalUI/CustomCheckButton';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import {
  clearGetInfoPlayer,
  clearPlayerInfoErrorMessage,
  clearSaveInfoPlayer,
  playerInfoThunk,
  selectPlayerInfo,
} from './slice/playerInfo.slice';
import ButtonsWrapper from '../../cashierModalUI/ButtonsWrapper';
import Input from '../../cashierModalUI/Input';
import InputsWrapper from '../../cashierModalUI/InputsWrapper';

const UCNForm = ({
  setActionButtonClicked,
  ticketId,
  setActiveButton,
  setSeconds,
  inputValueUCN,
  setInputValueUCN,
  setClickCount,
  seconds,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const playerInfo = useAppSelector(selectPlayerInfo.playerInfo);
  const playerInfoStatus = useAppSelector(selectPlayerInfo.playerInfoStatus);
  const playerInfoErrorMessage = useAppSelector(selectPlayerInfo.playerInfoErrorMessage);
  const savePlayerInfoError = useAppSelector(selectPlayerInfo.savePlayerErrorCode);
  const [inputValueFirstName, setInputValueFirstName] = useState('');
  const [inputValueMiddleName, setInputValueMiddleName] = useState('');
  const [inputValueLastName, setInputValueLastName] = useState('');
  const initTime = 5; //seconds
  const isDisabledSaveButton =
    isEmpty(inputValueUCN) ||
    isEmpty(inputValueFirstName) ||
    isEmpty(inputValueMiddleName) ||
    isEmpty(inputValueLastName) ||
    !isEmpty(playerInfoErrorMessage) ||
    seconds < initTime;
  const handleKeyPress = () => {
    dispatch(playerInfoThunk.getPlayerInfo({ ucn: Number(inputValueUCN) }));
  };
  const closeUCN = () => {
    setClickCount(0);
    setSeconds(0);
    setActiveButton([3]);
    dispatch(clearPlayerInfoErrorMessage());
    dispatch(clearSaveInfoPlayer());
    dispatch(clearGetInfoPlayer());
    setInputValueUCN('');
    setInputValueFirstName('');
    setInputValueMiddleName('');
    setInputValueLastName('');
  };
  const savePlayerInfo = () => {
    if (isEmpty(playerInfo?.ucnPlayer)) {
      dispatch(
        playerInfoThunk.savePlayerInfo({
          ucn: Number(inputValueUCN),
          firstName: inputValueFirstName,
          middleName: inputValueMiddleName,
          lastName: inputValueLastName,
        }),
      );
    } else {
      setSeconds(5);
      setActiveButton([3]);
      setActionButtonClicked(3);
    }
    dispatch(clearPlayerInfoErrorMessage());
    dispatch(clearGetInfoPlayer());
  };
  useEffect(() => {
    if (!isEmpty(playerInfo)) {
      setInputValueFirstName(playerInfo?.ucnPlayer?.firstName || '');
      setInputValueMiddleName(playerInfo?.ucnPlayer?.middleName || '');
      setInputValueLastName(playerInfo?.ucnPlayer?.lastName || '');
    }
  }, [playerInfo]);
  return (
    <div className="cashier-modal__ucn-wrapper">
      <div className="cashier-modal__ucn-form">
        <Input
          id={'inputTicketNumber'}
          value={ticketId}
          placeholder={'placeholder'}
          readOnly
          label={t('Cashier.CashierModal.ticketId')}
          spanAllColumns
        />

        <InputsWrapper spanAllColumns>
          <Input
            id={'ucnNums'}
            value={inputValueUCN}
            placeholder={''}
            onChange={(e) => setInputValueUCN(e.target.value)}
            label={t('Cashier.CashPayModal.ucn')}
            spanAllColumns
            inputMode={'numeric'}
            pattern={'[0-9]*'}
            errorText={
              playerInfoStatus === 'succeeded' && isEmpty(playerInfo?.ucnPlayer)
                ? t('Cashier.CashierModal.emptyInfoPlayer')
                : !isEmpty(playerInfoErrorMessage)
                  ? t(`Cashier.ERROR.${playerInfoErrorMessage}`)
                  : !isEmpty(savePlayerInfoError)
                    ? 'Invalid UCN'
                    : ''
            }
          />
          <CustomCheckButton text="Check" onClick={handleKeyPress} />
        </InputsWrapper>

        <Input
          id={'first name'}
          value={inputValueFirstName}
          placeholder={''}
          onChange={(e) => setInputValueFirstName(e.target.value)}
          label={t('Cashier.CashPayModal.firstName')}
          spanAllColumns={false}
        />

        <Input
          id={'middle name'}
          value={inputValueMiddleName}
          placeholder={''}
          onChange={(e) => setInputValueMiddleName(e.target.value)}
          label={t('Cashier.CashierModal.middleName')}
          spanAllColumns={false}
        />
        <Input
          id={'last name'}
          value={inputValueLastName}
          placeholder={''}
          onChange={(e) => setInputValueLastName(e.target.value)}
          label={t('Cashier.CashPayModal.lastName')}
          wrapperClassName="half"
          spanAllColumns
        />

        <ButtonsWrapper className="span-all-cols">
          <UI.Button
            text={t('MyAccount.Exclusions.saveBtn')}
            variant="primary"
            size="2xl"
            onClick={savePlayerInfo}
            isDisabled={isDisabledSaveButton}
          />
          <UI.Button
            text={t('MyAccount.Exclusions.cancelExtendButton')}
            variant="secondary"
            size="2xl"
            onClick={closeUCN}
          />
        </ButtonsWrapper>
      </div>
    </div>
  );
};

export default UCNForm;
