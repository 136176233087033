import { selectTicketData } from 'modules/retail/shared/slice/checkTicket.slice';
import { useAppSelector } from 'store';
import { selectCashierData } from '../../slice/cashier.slice';
import { selectReprintTicketData } from '../reprintTicket/slice/reprintTicket.slice';
import { selectPlayerInfo } from '../UCNForm/slice/playerInfo.slice';

export const useCashierTicketStatusInfo = () => {
  const ticketInfo = useAppSelector(selectTicketData.ticketInfo);
  const ticketInfoStatus = useAppSelector(selectTicketData.ticketInfoStatus);
  const cancelTicketStatus = useAppSelector(selectCashierData.cashierCancelTicketStatus);
  const payoutTicketStatus = useAppSelector(selectCashierData.payoutTicketStatus);
  const savePlayerInfoStatus = useAppSelector(selectPlayerInfo.savePlayerInfoStatus);
  const savePlayerInfoData = useAppSelector(selectPlayerInfo.savePlayerInfo);
  const reprintInfoStatus = useAppSelector(selectReprintTicketData.reprintInfoStatus);
  const reprintInfoError = useAppSelector(selectReprintTicketData.reprintInfoError);
  const ticketHistoryDataStatus = useAppSelector(selectReprintTicketData.ticketHistoryDataStatus);
  const ticketHistoryDataError = useAppSelector(selectReprintTicketData.ticketHistoryDataError);
  const reprintCheckTicketStatus = useAppSelector(selectReprintTicketData.reprintCheckTicketStatus);
  const reprintCheckTicketError = useAppSelector(selectReprintTicketData.reprintCheckTicketError);

  return {
    ticketInfo,
    ticketInfoStatus,
    cancelTicketStatus,
    payoutTicketStatus,
    savePlayerInfoStatus,
    savePlayerInfoData,
    reprintInfoStatus,
    reprintInfoError,
    ticketHistoryDataStatus,
    ticketHistoryDataError,
    reprintCheckTicketError,
    reprintCheckTicketStatus,
  };
};
