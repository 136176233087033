import { useMediaQuery } from 'react-responsive';
import { Text } from 'components/shared';
import ExpandableIcon from 'components/shared/icons/ExpandableIcon';
import { Flex } from 'components/shared/layout/Flex';
import { cssNS } from 'modules/retail/modules/cashier/types/cashierModalUITypes';
import { Breakpoints } from 'theme/Theme';

/** Ticket Header */
interface SSBTTicketContentHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  ticketIdText?: string;
  ticketId?: string;

  icon?: boolean;
  isExpanded?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const SSBTTicketContentHeader: React.FC<SSBTTicketContentHeaderProps> = ({
  ticketIdText,
  ticketId,
  icon,
  isExpanded,
  className,
  children,
  ...props
}) => {
  const classProp = className ? { class: className } : undefined;
  const isMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isMobile}px)` });

  return (
    <egtd-ssbt-ticket-content-header {...classProp} {...props} data-expanded={isExpanded}>
      <Flex.Col className="egtd-ssbt-header-info">
        <Text className={`${cssNS}__text--secondary`} text={ticketIdText + ': ' + ticketId} />
      </Flex.Col>
      {children}
      {icon && <ExpandableIcon isExpanded={isExpanded} size={isMobile ? 10 : 20} />}
    </egtd-ssbt-ticket-content-header>
  );
};

export default SSBTTicketContentHeader;
