import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { useCurrency } from 'hooks/common/useCurrency';
import Amount from 'pages/myAccount/tabs/history/components/BetLogUI/Amount';
import TextRow from 'pages/myAccount/tabs/history/components/BonusLogUI/TextRow';
import { BonusTypes } from 'shared/common/features/myProfile/sections/tabs/offersTab/types/offersTab.types';
import { CasinoTranslations, MyProfile } from 'utils/common/translationUtils/translationStrings';
import { getBonusProgress } from '../BonusOffersInfoButton';
import { BonusOffersInfoButton } from '../types/bonusOffersInfoButton.types';
import { cssBonusOffersInfoNS } from '../types/bonusOffersInfoUI.types';

type Props = {
  dataItem: BonusOffersInfoButton.Bonus;
};

const PopoverBonusProgress: React.FC<Props> = ({ dataItem }) => {
  const { currencySymbol } = useCurrency();
  const { t } = useTranslation();

  switch (dataItem.bonusType) {
    case BonusTypes.FREE_BET:
    case BonusTypes.FREE_SPIN:
      return null;
    case BonusTypes.FREE_SPIN_WAGERING:
      return (
        <div className={cssBonusOffersInfoNS.PopoverBonusProgress}>
          <TextRow
            className={'pb-1'}
            leftColText={t(MyProfile.OffersTab.wonFreeSpins)}
            leftColValue={getBonusProgress(dataItem).totalAmount}
            showRightCol={false}
          />
          <TextRow
            className={'pb-1'}
            leftColText={t(MyProfile.OffersTab.playedFreeSpins)}
            leftColValue={getBonusProgress(dataItem).totalCompletion}
            showRightCol={false}
          />
        </div>
      );
    default:
      return (
        <div className={cssBonusOffersInfoNS.PopoverBonusProgress}>
          <div className={cssBonusOffersInfoNS.PopoverTitle}>
            {`${t(
              dataItem.bonusType === BonusTypes.DEPOSIT_ROLLOVER
                ? CasinoTranslations.BonusOffersInfo.balanceDeposit
                : dataItem.bonusType === BonusTypes.FreeSpinsWinRollover
                  ? MyProfile.OffersTab.wonFromFreeSpins
                  : CasinoTranslations.BonusOffersInfo.balanceBonus,
            )}:`}
            <span
              className={'ml-1 font-weight-normal'}
            >{`${getBonusProgress(dataItem).totalAmount} ${currencySymbol}`}</span>
          </div>
          <div className={cssBonusOffersInfoNS.ProgressBar}>
            <div className={cssBonusOffersInfoNS.ProgressBarTrack}>
              <div
                className={cssBonusOffersInfoNS.ProgressBarCompletion}
                style={{ width: `${getBonusProgress(dataItem).totalCompletion}%` }}
              />
            </div>
            <Flex.ACJB>
              <span className={'font-weight-bold'} style={{ color: 'var(--completion)' }}>
                {getBonusProgress(dataItem).totalCompletion}%
              </span>
              <span className={'ml-auto'}>100%</span>
            </Flex.ACJB>
          </div>

          {dataItem.bonusType === BonusTypes.DEPOSIT_ROLLOVER && dataItem.depositRollover ? (
            <Flex.JB className={'font-weight-bold'}>
              <Amount
                amountText={`${t(CasinoTranslations.BonusOffersInfo.remainingDeposit)}:`}
                amount={dataItem.depositRollover.rolloverAmountLeft.toString()}
              />
            </Flex.JB>
          ) : (
            dataItem.bonusCompletion?.verticals?.length && (
              <>
                <Flex.JB>
                  <span>
                    {t(
                      dataItem.bonusType === BonusTypes.FreeSpinsWinRollover
                        ? MyProfile.OffersTab.remainingProfitToRoll
                        : CasinoTranslations.BonusOffersInfo.remainingBet,
                    )}
                    &nbsp;
                    {t(CasinoTranslations.BonusOffersInfo.byProduct)}:
                  </span>
                </Flex.JB>
                <Flex.JB>
                  {dataItem.bonusCompletion?.verticals.map(({ vertical, leftToWagerAmount }) => (
                    <Amount key={vertical} amountText={vertical} amount={leftToWagerAmount.toString()} />
                  ))}
                </Flex.JB>
              </>
            )
          )}
        </div>
      );
  }
};

export default PopoverBonusProgress;
