import React from 'react';
import { Icon, Text } from 'components/shared';
import { Flex } from 'components/shared/layout';

/** DrawerUI Search */
interface SearchProps {
  onClick: React.MouseEventHandler;
  text: string;
}

const Search: React.FC<SearchProps> = ({ onClick, text }) => {
  return (
    <Flex.AC className="drawer-search" onClick={onClick}>
      <Icon className="drawer-search__icon" icon="search" />
      <Text className="drawer-search__text" text={text} />
    </Flex.AC>
  );
};

export default Search;
