import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';
import ScrollContainer, { arrowStyleTypes, handleScrollMoveWith } from 'components/shared/ScrollContainer';
import { locationNS } from './customTabsUICSS.types';
import { classExtendByLocation, getGliderPosition } from './utils/customTabs.utils';
interface Props {
  className?: string;
  wrapperClassName?: string;
  type?: 'primary' | 'secondary' | 'third';
  location?: 'promotions' | 'myAccount';
  children?: React.ReactNode;
  childrenAfter?: React.ReactNode;
  hasScroll?: boolean;
  setMobilePadding?: boolean;
  hasSubTabs?: boolean;
  hasGlider?: boolean;
  activeElementRef?: HTMLDivElement;
}

const TabList: React.FC<Props> = ({
  className,
  wrapperClassName,
  type = 'primary',
  children,
  hasScroll,
  childrenAfter,
  location = 'promotions',
  setMobilePadding,
  hasSubTabs,
  activeElementRef,
  hasGlider,
}) => {
  const [gliderPos, setGliderPos] = useState<{
    width: number;
    left: number;
  } | null>(null);
  useEffect(() => {
    const setGliderPosition = () => {
      if (activeElementRef && hasGlider) {
        setGliderPos(getGliderPosition({ elementRef: activeElementRef }));
      }
    };
    setGliderPosition();
    window.addEventListener('resize', setGliderPosition);

    return () => window.removeEventListener('resize', setGliderPosition);
  }, [activeElementRef, hasGlider, children]);

  const hiddenClass = clsx(
    // 'w-100',
    `${locationNS}-${classExtendByLocation[location]}__hidden`,
    `${locationNS}-${classExtendByLocation[location]}__hidden--${type}`,
    hasSubTabs && `${locationNS}-${classExtendByLocation[location]}__hidden--${type}--hasSubTabs`,
    className && className,
  );
  const containerClass = clsx(
    'w-100',
    `${locationNS}-${classExtendByLocation[location]}__container`,
    `${locationNS}-${classExtendByLocation[location]}__container--${type}`,
    hasSubTabs && `${locationNS}-${classExtendByLocation[location]}__container--${type}--hasSubTabs`,
    className && className,
  );
  const listClass = clsx(
    `${locationNS}-${classExtendByLocation[location]}__list`,
    `${locationNS}-${classExtendByLocation[location]}__list--${type}`,
    hasSubTabs && `${locationNS}-${classExtendByLocation[location]}__list--${type}--hasSubTabs`,
    setMobilePadding && `${locationNS}-${classExtendByLocation[location]}__list--${type}--padding`,
    className && className,
  );
  const scrollItemWidth = 60;
  const itemsToScroll = 2;
  const { moveWith } = handleScrollMoveWith({ itemsToScroll, itemWidth: scrollItemWidth });

  return (
    <Flex className={hiddenClass}>
      <div className={containerClass}>
        {hasScroll ? (
          <Flex className={`${wrapperClassName ? wrapperClassName : 'wh-100'}`}>
            <ScrollContainer
              regularDesktopCellWidth={scrollItemWidth}
              moveWith={moveWith}
              classNames={`no-scrollbars ${listClass}`}
              typeArrow={arrowStyleTypes.Tabs}
              activeClass={`${locationNS}-${classExtendByLocation[location]}__tab--${type}--active`}
              wrapperClassName={`${locationNS}-${classExtendByLocation[location]}__scroll--${type}`}
            >
              {children}
            </ScrollContainer>
            {childrenAfter && <>{childrenAfter}</>}
          </Flex>
        ) : (
          <Flex className={listClass} role="tablist">
            {children}
            {gliderPos ? (
              <span
                className={`glider`}
                style={{
                  width: `${gliderPos.width}px`,
                  left: `${gliderPos.left}px`,
                }}
              ></span>
            ) : null}
            {childrenAfter && <>{childrenAfter}</>}
          </Flex>
        )}
      </div>
    </Flex>
  );
};
// Dont change name -- need for tabs props
TabList.displayName = 'TabList';

export default TabList;
