import { isSafari } from 'react-device-detect';
import { GridType } from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import { isEmpty, localLog } from './common/helpersCommon';
import { CategorySubTypes } from '../../modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import {
  categoryRequiredParams,
  RequestStatuses,
  requiredLayoutConfigParams,
  RESTRICTED_QUERY_CATEGORIES_BY_GRID_LAYOUT,
  RESTRICTED_SUBTYPES,
} from '../constants';
import { Casino } from '../types';

export const isInitial = (status: RequestStatuses): boolean => {
  return status === RequestStatuses.INITIAL;
};

export const isPending = (status: RequestStatuses | null): boolean => {
  return status === RequestStatuses.PENDING;
};

export const isError = (status: RequestStatuses | null): boolean => {
  return status === RequestStatuses.ERROR;
};

export const isEmptyResponse = (status: RequestStatuses | null): boolean => {
  return status === RequestStatuses.EMPTY;
};

export const isSuccess = (status: RequestStatuses | null): boolean => {
  return status === RequestStatuses.SUCCESS;
};

const isValidCategory = (category) => {
  if (!category) {
    return;
  }

  return categoryRequiredParams.every((param) => {
    // check if param exists
    const isValidParam = !!category[param.key];

    if (!isValidParam) {
      localLog({ message: `Category with id: ${category?.id} have missing required prop: ${param.key}`, type: 'warn' });
    }

    return isValidParam;
  });
};

const isValidLayoutConfig = (category) => {
  if (!category) {
    return;
  }

  return requiredLayoutConfigParams.every((param) => {
    // check if param exists
    const isValidParam = !isEmpty(category.layoutConfig[param.key]);

    if (!isValidParam) {
      localLog({ message: `Category with id: ${category?.id} have missing required prop: ${param.key}`, type: 'warn' });
    }
    return isValidParam;
  });
};

const layoutValidation = ({ category, subType }: { category: Casino.CategoryRequired; subType: CategorySubTypes }) => {
  if (category?.hasNumbers && category?.layoutConfig?.layout === GridType.VERTICAL) {
    return false;
  }

  if (category?.hasNumbers && RESTRICTED_QUERY_CATEGORIES_BY_GRID_LAYOUT.includes(category.queryType)) {
    return false;
  }

  if (category.hasNumbers && RESTRICTED_SUBTYPES.includes(subType)) {
    return false;
  }

  return true;
};

export const isValidCategoryParams = ({
  category,
  subType,
}: {
  category: Casino.CategoryRequired;
  subType: CategorySubTypes;
}) => {
  return isValidCategory(category) && isValidLayoutConfig(category) && layoutValidation({ category, subType });
};

const hasKey = (obj, key) => {
  return Object.keys(obj).indexOf(key) !== -1;
};

export const deepSearchItems = (object, key, predicate) => {
  let ret: any[] = [];
  if (hasKey(object, key) && predicate(key, object[key]) === true) {
    ret = [...ret, object];
  }
  if (Object.keys(object).length) {
    for (let i = 0; i < Object.keys(object).length; i++) {
      const value = object[Object.keys(object)[i]];
      if (typeof value === 'object' && value != null) {
        const o = deepSearchItems(object[Object.keys(object)[i]], key, predicate);
        if (o != null && o instanceof Array) {
          ret = [...ret, ...o];
        }
      }
    }
  }
  return ret;
};

export const newGameWindowOpen = ({ url, title, ratio }: { url: string; title: string; ratio: number }) => {
  const additionalBrowserTop = isSafari ? 28 : 60;
  const windowScale = 0.5;
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const height = Math.floor(screenHeight * windowScale);
  const width = height * ratio + 98;
  const left = (screenWidth - width) / 2;
  const top = (screenHeight - height - additionalBrowserTop) / 2;
  const newWindow = window.open(
    url,
    title,
    `
      menubar=no,
      resizable=yes,
      scrollbars=no,
      toolbar=yes,
      screenX=${left},
      screenY=${top},
      width=${width},
      height=${height},
      top=${top},
      left=${left}
      `,
  );

  newWindow?.focus();
  return newWindow;
};

export const popupCenter = ({ url, title, w, h }) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
      menubar=no,
      resizable=yes,
      scrollbars=no,
      toolbar=yes,
      screenX=${left},
      screenY=${top},
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `,
  );

  newWindow?.focus();
  return newWindow;
};

export const saveScroll = ({
  gameCellRef,
  collectionId,
  gridPage,
  gamesRenderedCount,
  uniqueId,
  refGameId,
  gamesCount,
}: {
  gameCellRef: HTMLDivElement;
  uniqueId: string;
  gridPage: number;
  gamesRenderedCount: number;
  collectionId: string;
  refGameId: string;
  gamesCount: number;
}): void => {
  const cellBounderies = gameCellRef.getBoundingClientRect();
  const scrollToCell: Casino.ScrollToCellTypes = {
    uniqueId,
    gridPage,
    gamesRenderedCount,
    collectionId,
    offsetY: cellBounderies.top,
    offsetLeft: cellBounderies.left,
    windowInnerWidth: window.innerWidth,
    pathName: window.location.pathname,
    hasCarousel: document.getElementById('casino-carousel') ? true : false,
    refGameId,
    exitReloaded: true,
    gamesCount,
  };
  localStorage.setItem('scrollToCell', JSON.stringify(scrollToCell));
};

export const saveScrollBeforeLaunch = ({
  uniqueId,
  gameId,
  collectionId,
  gridPage,
  gamesRenderedCount,
  exitReloaded,
  gamesCount,
}: {
  uniqueId: string;
  gridPage: number;
  gamesRenderedCount: number;
  collectionId: string;
  gameId: string;
  exitReloaded: boolean;
  gamesCount: number;
}): void => {
  const scrollToCell: Casino.ScrollToGameCellTypes = {
    uniqueId,
    gridPage,
    gamesRenderedCount,
    collectionId,
    pathName: window.location.pathname,
    gameId,
    exitReloaded,
    gamesCount,
  };
  localStorage.setItem('scrollToCell', JSON.stringify(scrollToCell));
};

export const getCellPosition = (disableSaveScrollPosition?: boolean): Casino.ScrollToCellTypes | undefined => {
  if (disableSaveScrollPosition) return;
  const objStr = localStorage.getItem('scrollToCell');
  const obj: Casino.ScrollToCellTypes | undefined = objStr !== null && JSON.parse(objStr);
  return obj;
};

export const clearCellScrollData = (): void => {
  localStorage.removeItem('scrollToCell');
};

export const isInViewport = (elem: HTMLElement) => {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const SplitTitles = (title) => {
  if (!title) return;
  const splitted = title?.split(' ');
  if (splitted.length === 1) {
    return <>{splitted[0]}</>;
  }

  if (splitted.length % 2 === 0) {
    return (
      <>
        {splitted.slice(0, splitted.length / 2).join(' ')}
        <br />
        {splitted.slice(splitted.length / 2, splitted.length).join(' ')}
      </>
    );
  }

  if (splitted.length > 2 && splitted.length % 2 === 1) {
    return (
      <>
        {splitted.slice(0, splitted.length / 2 + 1).join(' ')}
        <br />
        {splitted.slice(splitted.length / 2 + 1, splitted.length).join(' ')}
      </>
    );
  }
};
