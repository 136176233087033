import { Mixins } from '../../StyledComponents/shared/index';
import { DefaultThemes } from '../default';
import { HomeTypes } from '../default/types/HomeTypes';
export const HomeVar: HomeTypes.State = {
  BlockTitle: {
    titleFontSize: Mixins.Size.clampedSize(16, 22),
    linkFontSize: Mixins.Size.clampedSize(12, 16),
    iconSizeDesktop: 14,
    iconSize: 16,
  },
  VideoBanner: {
    button: {
      color: '#000',
      background: '#E6B423',
      hoverColor: '#000',
      hoverBg: '#d7b247',
    },
  },
};

function merge_options(defaultObj, themeObj) {
  const newObj = {};
  for (const attrname in defaultObj) {
    newObj[attrname] = { ...defaultObj[attrname], ...themeObj[attrname] };
  }

  return newObj;
}
const Help = merge_options(DefaultThemes.dark.Home, HomeVar);
export default Help;
