import React, { memo } from 'react';

import { BonusCollectionsModal } from 'components/shared/bonusCollectionsModal';
import { PlayerWinInfoModal } from 'components/shared/playerWinInfo';
import SvaraLaunchModal from 'modules/casino/shared/features/gameLaunch/components/SvaraLaunchModal';
import KYCVerificationModal from 'modules/casino/shared/features/gameLaunch/launcherRoute/components/iframeWrappers/KYCVerificationModal';
import { JackpotWinModal } from 'modules/casino/shared/features/gameLaunchBonus/jackpotWinModal';
import PaidGameModal from 'modules/casino/shared/features/gameLaunchBonus/modals/PaidGameModal';
import NotificationPopup from 'modules/games/notificationPopup/NotificationPopup';
import CashierErrorModal from 'modules/retail/modules/cashier/CashierErrorModal';
import DeactiveTerminalModal from 'modules/retail/modules/cashier/DeactiveTerminalModal';
import AnexReport from 'modules/retail/modules/cashier/features/anex2Report/AnexReport';
import CashierModal from 'modules/retail/modules/cashier/features/cashier-checkTicket/CashierModal';
import ClaimTicketModal from 'modules/retail/modules/cashier/features/claimTicket/ClaimTicketModal';
import FinancialModal from 'modules/retail/modules/cashier/features/finance/FinancialModal';
import ReprintTicketModal from 'modules/retail/modules/cashier/features/reprintTicket/ReprintTicketModal';
import UcnCheckCashierModal from 'modules/retail/modules/cashier/features/ucnScan/UcnCheckCashierModal';
import CashierVoucherModal from 'modules/retail/modules/cashier/features/vouchers/CashierVoucherModal';
import PaperCouponErrorModal from 'modules/retail/modules/cashier/PaperCouponErrorModal';
import { CheckTicketTerminalModal } from 'modules/retail/modules/ssbt/components';
import PayoutModal from 'modules/retail/modules/ssbt/components/PayoutModal';
import TerminalInvalidModal from 'modules/retail/modules/ssbt/components/TerminalInvalidModal';
import UCNStepTicketModal from 'modules/retail/modules/ssbt/features/checkTicket/components/UCNStepTicketModal';
import TerminalCreateVoucher from 'modules/retail/modules/ssbt/features/createVoucher/components/TerminalCreateVoucher';
import SSBTModalDeposit from 'modules/retail/modules/ssbt/features/deposit/components/SSBTModalDepost';
import { CashPayModal } from 'modules/retail/shared/features/cashPay';
import MFA_ForgottenPassword from 'pages/auth/forgotPassword/MFA_ForgottenPassword';
import PhoneConfirmationModal from 'pages/auth/phoneConfirmation/PhoneConfirmationModal';
import LimitIncreaseModal from 'pages/myAccount/tabs/limits/components/LimitIncreaseModal';
import PlayerRestrictionsModal from 'pages/myAccount/tabs/responsibleGambling/components/PlayerRestrictionsModal';
import PrizeDropRewardedPlayersModal from 'pages/promotions/prizeDrop/PrizeDropRewardedPlayersModal';
import PrizeDropRewardsModal from 'pages/promotions/prizeDrop/PrizeDropRewardsModal';
import PromoAwardsModal from 'pages/promotions/promotionRankings';
import OmniPlayPayTicketDataModal from 'pages/retailGames/providers/omniPlay/components/OmniPlayPayTicketDataModal';
import { ModalTypes } from 'shared/common/features/rootModal/types/modal.types';
import useRootModalData from './hooks/useRootModalData';
import {
  ConfirmationModal,
  GeolocationModal,
  InsufficientBalanceModal,
  RealityCheckModal,
  RealityCheckSessionModal,
  SessionCheckModal,
  SessionInactivityModal,
  TermsAndConditionsModal,
  TimeOutModal,
} from './modals';
import RetailCertificateModal from './modals/RetailCertificateModal';
import SocialMediaLinkModal from './modals/SocialMediaLinkModal';
import { manageBodyNoScroll } from './modals/utils/rootModal.utils';
import { PopUpSliderNotificationModal } from '../myProfile/sections/tabs/notificationsTab/feature/popUpSliderNotifications';
import PopUpGamificationModal from '../myProfile/sections/tabs/notificationsTab/PopUpGamificationModal';
import PopUpNotificationBGModal from '../myProfile/sections/tabs/notificationsTab/PopUpNotificationBGModal';
import PopUpNotificationModal from '../myProfile/sections/tabs/notificationsTab/PopUpNotificationModal';
import AcceptOfferModal from '../myProfile/sections/tabs/offersTab/components/AcceptOfferModal';
import CancelOfferModal from '../myProfile/sections/tabs/offersTab/components/CancelOfferModal';
import TermsOfferModal from '../myProfile/sections/tabs/offersTab/components/TermsOfferModal';
import PrizeDropModal from '../PrizeDrop/components/PrizeDropModal';
import PrizeDropWinModal from '../PrizeDrop/components/PrizeDropWinModal';
import PrizeDropWinVideoModal from '../PrizeDrop/components/PrizeDropWinVideoModal';
import RequestBetModal from '../requestBet/RequestBetModal';
import { TwoFactorVerification } from '../twoFactorVerification';

/* eslint-disable @typescript-eslint/no-explicit-any */
const MODAL_COMPONENTS: Record<ModalTypes, React.FC<any>> = {
  ACCEPT_OFFER: AcceptOfferModal,
  BONUS_COLLECTION_WIN_MODAL: BonusCollectionsModal,
  CANCEL_OFFER: CancelOfferModal,
  CASHIER_MODAL: CashierModal,
  CONFIRMATION_MODAL: ConfirmationModal,
  TERMS_AND_CONDITIONS: TermsAndConditionsModal,
  CONNECTION_LOST: TimeOutModal,
  INSUFFICIENT_BALANCE_MODAL: InsufficientBalanceModal,
  MFA_FORGOTTEN_PASSWORD: MFA_ForgottenPassword,
  NEW_VERSION_MODAL: TimeOutModal,
  PAYOUT_MODAL: PayoutModal,
  PHONE_CONFIRMATION: PhoneConfirmationModal,
  POP_UP_BG_NOTIFICATION: PopUpNotificationBGModal,
  POP_UP_GAMIFICATION: PopUpGamificationModal,
  POP_UP_NOTIFICATION: PopUpNotificationModal,
  POP_UP_SLIDER_NOTIFICATION: PopUpSliderNotificationModal,
  PLAYER_RESTRICTIONS_INFO: PlayerRestrictionsModal,
  PLAYER_WIN_INFO: PlayerWinInfoModal,
  PRIZE_DROP_CAMPAIGNS_MODAL: PrizeDropModal,
  PRIZE_DROP_WIN_MODAL: PrizeDropWinModal,
  PRIZE_DROP_WIN_VIDEO_MODAL: PrizeDropWinVideoModal,
  JACKPOT_WIN_MODAL: JackpotWinModal,
  PROMO_AWARDS_MODAL: PromoAwardsModal,
  PROMO_PD_REWARDED_PLAYERS_MODAL: PrizeDropRewardedPlayersModal,
  PROMO_PD_REWARDS_MODAL: PrizeDropRewardsModal,
  REALITY_CHECK: RealityCheckModal,
  REALITY_CHECK_SESSION: RealityCheckSessionModal,
  REFRESH_TOKEN: SessionCheckModal,
  REQUEST_BET_MODAL: RequestBetModal,
  SESSION_INACTIVITY: SessionInactivityModal,
  TERMINAL_CHECK_TICKET_DIRECT: CheckTicketTerminalModal,
  TERMINAL_CHECK_TICKET: CheckTicketTerminalModal,
  TERMINAL_CREATE_VOUCHER: TerminalCreateVoucher,
  TERMINAL_DEPOSIT_CASH: SSBTModalDeposit,
  TERMINAL_INVALID_CODE: TerminalInvalidModal,
  TERMS_OFFER: TermsOfferModal,
  TIMEOUT: TimeOutModal,
  TWO_FACTOR_VERIFICATION: TwoFactorVerification,
  MULTI_FACTOR_VERIFICATION: PhoneConfirmationModal,
  CASHIER_ERROR_MODAL: CashierErrorModal,
  DEACTIVATED_TERMINAL_MODAL: DeactiveTerminalModal,
  SOCIAL_MEDIA_LINK_MODAL: SocialMediaLinkModal,
  KYC_VERIFICATION_MODAL: KYCVerificationModal,
  RETAIL_FINANCE_MODAL: FinancialModal,
  CASH_PAY_MODAL: CashPayModal,
  SVARA_LAUNCH_MODAL: SvaraLaunchModal,
  LIMIT_INCREASE_MODAL: LimitIncreaseModal,
  ANEX_MODAL: AnexReport,
  CREATE_RETAIL_VOUCHER: CashierVoucherModal,
  PAPER_COUPON_ERROR_MODAL: PaperCouponErrorModal,
  RETAIL_CERTIFICATE: RetailCertificateModal,
  UCN_CHECK_CASHIER_MODAL: UcnCheckCashierModal,
  RETAIL_UCN_MODAL: UCNStepTicketModal,
  OMNIPLAY_PAYMENT_MODAL: OmniPlayPayTicketDataModal,
  REPRINT_MODAL: ReprintTicketModal,
  GEOLOCATION_MODAL: GeolocationModal,
  CLAIM_TICKET_MODAL: ClaimTicketModal,
  PAID_GAME_MODAL: PaidGameModal,
  CAMPAIGN_GAMES_POPUP: NotificationPopup,
};

const RootModal = () => {
  const { currModalProps, currModalType } = useRootModalData();

  if (!currModalType) {
    manageBodyNoScroll('remove');
    return null;
  } else {
    manageBodyNoScroll('add');
  }

  const SpecificModal = MODAL_COMPONENTS[currModalType];
  return <SpecificModal {...currModalProps} />;
};

export default memo(RootModal);
