export const BONUS_TAG_INTERVAL = 60000;
export const CHALLENGES_INTERVAL = 30000;

export const JackpotTranslations = {
  level: 'Promotions.Jackpot.level',
  maxWin: 'Promotions.Jackpot.maxWin',
  multiplier: 'Promotions.Jackpot.multiplier',
  winMessage: 'Promotions.Jackpot.winMessage',
  infoText: 'Promotions.Jackpot.infoText',
  infoLinkText: 'Promotions.Jackpot.infoLinkText',
  continueButton: 'Promotions.Jackpot.continueButton',
  jackpotCampaignBtn: 'Promotions.Jackpot.jackpotCampaignBtn',
};

export const JACKPOT_INTERVAL_TOP_BAR = 10000;

export const jackpotWinModalPrefix = 'bs-modal';
export const jackpotPopOverPrefix = 'bs-popover';
export const MISSIONS_COLLECTION_INTERVAL = 10000;
