import React from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { UI } from 'modules/casino/shared/components/UI';
import { cssBurgerNS, tileAnimation } from './burgerUI.types';

/** NavItem */
interface CasinoTileProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;

  icon: string;
  useColorIcon?: boolean;
  text: string;
  isActive?: boolean;

  onClick?: React.MouseEventHandler;
}

const CasinoTile: React.FC<CasinoTileProps> = ({ icon, useColorIcon, text, className, onClick, isActive }) => {
  const wrapperClass = clsx(cssBurgerNS.Tile, cssBurgerNS.CasinoTile, isActive && cssBurgerNS.TileIsActive, className);

  const usePng = useColorIcon && icon && icon.split('.').pop()?.toLowerCase() === 'png';

  const useIconFilterProp = useColorIcon ? { 'data-use-filter': useColorIcon } : undefined;

  return (
    <motion.div variants={tileAnimation} className={wrapperClass} onClick={onClick}>
      {usePng ? (
        <UI.CDNPNG className={cssBurgerNS.TileIcon} size={34} icon={icon} {...useIconFilterProp} />
      ) : (
        <UI.CDNSVG className={cssBurgerNS.TileIcon} size={34} icon={icon} {...useIconFilterProp} />
      )}
      <span className="mt-auto text-clamp-2-line">{text}</span>
    </motion.div>
  );
};

export default CasinoTile;
