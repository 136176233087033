import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { isEmpty } from 'utils/common/helpersCommon';

type ModalPropsTypes = {
  show: boolean;
  location?: 'all' | 'content' | 'tournamentView' | 'tournamentViewEvents';
  id?: number | string;
  title?: string;
  descriptions?: string;
  size?: 'small' | 'medium';
  showTriangle?: boolean;
  linkText?: string;
  linkUrl?: string;
  position?: 'left' | 'right';
  top?: number;
  left?: number;
  padding?: number;
  elementWidth?: number;
  promotionId?: number;
  alias?: string;
  categoryAlias?: string;
  wrapperRadius?: boolean;
};

type PopOverState = {
  modalProps: ModalPropsTypes | null;
};

const initialState: PopOverState = {
  modalProps: {
    show: false,
    position: 'left',
    location: 'content',
  },
};

const popOver = createSlice({
  name: 'popOver',
  initialState,
  reducers: {
    showPopOver(state, action: PayloadAction<PopOverState>) {
      const { modalProps } = action.payload;

      if (isEmpty(modalProps)) return;

      state.modalProps = modalProps;
    },
    hidePopOver(state) {
      state.modalProps = initialState.modalProps;
    },
  },
});

export const { showPopOver, hidePopOver } = popOver.actions;

export default popOver.reducer;

export const selectPopOver = {
  props: (state: RootState) => state.common.popOver.modalProps,
};
