import React from 'react';
import { SCBar, SCBarText } from './Bar.styled';

/** CashBell Bar */
interface BarProps {
  rowStart: number;
  colStart: 1 | 3;
  text: string;
  isWinner: boolean;
  pseudoElBg: string;
}

const Bar: React.FC<BarProps> = ({ rowStart, colStart, text, isWinner, pseudoElBg }) => {
  return (
    <SCBar $isWinner={isWinner} $rowStart={rowStart} $colStart={colStart} $pseudoElBg={pseudoElBg}>
      <SCBarText $colStart={colStart}>{text}</SCBarText>
    </SCBar>
  );
};

export default Bar;
