import { useEffect } from 'react';
import {
  fetchGamesInfoByIds,
  gamesSelectors,
} from 'modules/casino/modules/container/cellsContainer/cellls/slice/casinoGamesSlice';
import { useAppDispatch, useAppSelector } from 'store';
import { RootState } from 'store/rootReducer';
import { CasinoGamesTypes } from '../types/casinoGamesTypes';

export const useGetGameInfoById = (gameIds: string[]): CasinoGamesTypes.GameProps[] => {
  const dispatch = useAppDispatch();
  const gamesInfo = useAppSelector((state: RootState) => gamesSelectors.gamesInfo(state));

  useEffect(() => {
    if (gameIds.length && (!gamesInfo.length || gameIds[0] !== gamesInfo[0].id)) {
      dispatch(fetchGamesInfoByIds(gameIds));
    }
  }, [gameIds, gamesInfo, dispatch]);

  return gamesInfo;
};
