import React from 'react';
import clsx from 'clsx';
import { useWidgetNavigate } from 'modules/casino/modules/container/widget/widgetTypes/genericWidgets/hooks/useWidgetNavigate';
import { Flex } from 'modules/casino/shared/components/layout';
import { WidgetAlignmentItems } from 'modules/casino/shared/constants/constants';
import { CasinoWidgetsTypes } from '../../../../types/casinoWidgetsTypes';

interface ButtonTypes {
  button?: CasinoWidgetsTypes.WidgetNavigation;
  additionalClass: string;
}

const Button: React.FC<ButtonTypes> = ({ button, additionalClass }) => {
  const { onClick } = useWidgetNavigate(button);
  if (!button?.buttonNavigation && !button?.text && !button?.route) return <></>;

  const buttonClass = clsx('cw__wrapper', additionalClass);
  const buttonStyle = {
    justifyContent: WidgetAlignmentItems[button.alignmentX],
    alignItems: WidgetAlignmentItems[button.alignmentY],
    gridColumnStart: button.column,
    gridRowStart: button.row,
  };

  const onButtonClick = (ev: React.MouseEvent) => {
    ev.stopPropagation();

    onClick();
  };

  return (
    <Flex className={buttonClass} style={buttonStyle}>
      <div
        className="cw__button"
        style={{ background: button.backgroundColor, color: button.textColor }}
        onClick={onButtonClick}
      >
        {button.text}
      </div>
    </Flex>
  );
};
Button.displayName = 'GenericWidgetsUI.Button';
export default Button;
