import React from 'react';
import { Flex } from 'components/shared/layout';
import { bonusCollectionModalPrefix } from '../utils/bonusCollectionsModal.utils';

type Props = {
  infoText: string;
  handleInfo: () => void;
  infoLinkText: string;
  btnText: string;
  handleClose?: () => void;
};

const BonusCollectionsModalFooter: React.FC<Props> = ({ handleClose, infoText, infoLinkText, handleInfo, btnText }) => {
  return (
    <Flex.ACJB className={`${bonusCollectionModalPrefix}__footer`}>
      <div className={`${bonusCollectionModalPrefix}__footer-link cursor-pointer`} onClick={handleInfo}>
        {infoText} <u>{infoLinkText}</u>
      </div>

      <div className={`${bonusCollectionModalPrefix}__footer-btn cursor-pointer`} onClick={handleClose}>
        {btnText}
      </div>
    </Flex.ACJB>
  );
};

export default BonusCollectionsModalFooter;
