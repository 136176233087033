import { useCallback, useLayoutEffect, useState } from 'react';
import { HookFormReturnProps } from 'components/shared/forms/hooks';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';

interface Props {
  watch: HookFormReturnProps['watch'];
  formState: HookFormReturnProps['formState'];
}

interface ReturnProps {
  onClick: (v) => void;
  clearAutofillOnChange: () => void;
  isCustomValid?: boolean;
}

const numberOfAttempts = 5; // we should limit attempts in incognito mode -> there is no autofill

// THIS IS A HACK, because of chrome autofill issue
const useChromeAutofillHack = ({ watch, formState }: Props): ReturnProps => {
  const [hasAutofill, setHasAutofill] = useState(false);
  const navigationOptions = useAppSelector(selectAuthentication.navigationOptions);
  const loginError = useAppSelector(selectAuthentication.loginError);
  // const betSlipSelection = useAppSelector(selectAuthentication.betSlipSelection); currently no coming from banner
  const isNewWindowNavigate =
    (window.opener && location.pathname.includes('game-launch/casino')) ||
    location.pathname.includes('playtech-poker/login') ||
    location.pathname.includes('svara-app/login') ||
    navigationOptions !== null;
  const { isDirty, touchedFields } = formState;
  const isFormTouched = isDirty || !isEmpty(touchedFields);
  const shouldCheckAutoFill = isNewWindowNavigate && !isFormTouched && !loginError && !hasAutofill;

  useLayoutEffect(() => {
    /**
     * fixes https://jira.egt-digital.com/browse/EFED-4361
     * https://github.com/facebook/react/issues/1159#issuecomment-1025423604
     * The field can be prefilled on the very first page loading by the browser
     * By security reasons browser limits access to the field value from JS level and the value becomes available
     * only after first user interaction with the page
     * So, even if the Form thinks that the field is not touched by user and empty,
     * it actually can have some value, so we should process this edge case in the form logic
     */
    if (shouldCheckAutoFill) {
      let x = 0;
      const checkAutofilled = () => {
        const autofilledInput = !!document.querySelector('input:-webkit-autofill');
        setHasAutofill(autofilledInput);
        if (++x === numberOfAttempts) {
          clearInterval(interval);
        }
      };
      // The time when it's ready is not very stable, so check few times
      const interval = setInterval(checkAutofilled, 250);
      return () => clearInterval(interval);
    }
  }, [shouldCheckAutoFill]);

  const clearAutofillOnChange = useCallback(() => {
    hasAutofill && setHasAutofill(false);
  }, [hasAutofill]);

  // fixes https://jira.egt-digital.com/browse/EQA-731
  // remove, when fixed, or if not efficient
  // https://stackoverflow.com/questions/58201291/chrome-autocomplete-lock-inputs-like-they-are-not-clickable
  // eslint-disable-next-line no-self-assign
  const onClick = useCallback((el) => (el.target.value = el.target.value), []);

  let isCustomValid; // we say it's valid, if we detect autofill, because we do not have access to values at this moment
  if (isNewWindowNavigate && !isFormTouched) {
    const fields = watch();
    const isFormEmpty = Object.values(fields)?.every((f) => isEmpty(f));

    isCustomValid = !isFormEmpty || (isFormEmpty && hasAutofill && !loginError);
  }

  return { onClick, clearAutofillOnChange, isCustomValid };
};

export default useChromeAutofillHack;
