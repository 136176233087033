import Swiper from 'swiper';

const pagination = {
  el: '.swiper-custom-pagination',
  dynamicBullets: true,
  renderBullet: function (index, className) {
    return `<div class="${className}"><div className="progress-bullet">${index}</div></div>`;
  },
};

const quality = 2;

const sizeImage = {
  carousel: {
    mobile: {
      width: 335 * quality,
      height: 160 * quality,
    },
    desktop: {
      width: 480 * quality,
      height: 241 * quality,
    },
  },
  thumb: {
    mobile: {
      width: 88 * quality,
      height: 44 * quality,
    },
    desktop: {
      width: 144 * quality,
      height: 83 * quality,
    },
  },
};

const slideChange = ({
  swiper,
  setIsEnd,
  paginationRef,
  isInit,
  callbackNotificationId,
}: {
  swiper;
  setIsEnd: (endSlide: boolean) => void;
  paginationRef: React.RefObject<HTMLDivElement>;
  isInit?: boolean;
  callbackNotificationId: (id?: number, lastId?: number) => void;
}) => {
  const activeIndex = swiper.activeIndex;
  const previousIndex = swiper.previousIndex;
  const isRead = swiper.slides[previousIndex]?.getAttribute('data-read');
  const prevId = swiper.slides[previousIndex]?.getAttribute('data-id');
  // const setIsRead = swiper.slides[previousIndex]?.setAttribute('data-read', true);
  swiper.slides[previousIndex]?.setAttribute('data-read', true);
  const activeId = swiper.slides[activeIndex]?.getAttribute('data-id');
  const endSlide = swiper.isEnd;
  if (endSlide) setIsEnd(true);

  // set read notification
  if (isInit && endSlide) {
    !isRead && callbackNotificationId(undefined, activeId);
  } else {
    !isRead && callbackNotificationId(prevId ? prevId : undefined, endSlide && activeId);
  }

  // update thumb active slide
  !isInit && activeIndex && swiper?.thumbs && swiper?.thumbs?.swiper?.slideTo(activeIndex, 1000);

  // update pagination
  const activeBullet = paginationRef.current?.childNodes[activeIndex] as HTMLElement;
  const prevBullet = paginationRef.current?.childNodes[previousIndex] as HTMLElement;
  const nextBullet = !endSlide && (paginationRef.current?.childNodes[activeIndex + 1] as HTMLElement);
  const prevPagg = paginationRef.current?.childNodes[previousIndex];
  const nextPagg = paginationRef.current?.childNodes[activeIndex + 1];
  prevBullet && prevBullet?.classList.remove('ns-pagination__bullet--active');
  nextBullet && nextBullet?.classList.remove('ns-pagination__bullet--active');
  prevPagg && ((prevPagg.childNodes[0] as HTMLElement).style.width = '0');
  nextPagg && ((nextPagg.childNodes[0] as HTMLElement).style.width = '0');
  activeBullet && activeBullet.classList.add('ns-pagination__bullet--active');
};

const onAutoplayTimeLeft = (
  swiper: Swiper,
  time: number,
  progress: number,
  paginationRef: React.RefObject<HTMLDivElement>,
  end: boolean,
) => {
  const currentProgres = 1 - progress;
  if (Math.ceil(time / 1000) < 0) return;
  const item = paginationRef.current?.childNodes[swiper.activeIndex];
  if (!end) {
    item && ((item.childNodes[0] as HTMLElement).style.width = `${(currentProgres * 100).toFixed(0)}%`);
  } else {
    item && ((item.childNodes[0] as HTMLElement).style.width = `0%`);
  }
};

export { pagination, sizeImage, slideChange, onAutoplayTimeLeft };
