import { isIOS } from 'react-device-detect';
import { MobileAppRestrictions } from './mobileAppRestrictions.types';
import { useAppSelector } from '../../../../../store';
import { CasinoGamesTypes } from '../../../modules/container/cellsContainer/cellls/types/casinoGamesTypes';
import { useProviderDataByAlias } from '../../../modules/container/shared/features/casinoGameProviders/hooks/useProviderDataByAlias';
import { gameProvidersSelectors } from '../../../modules/container/shared/features/casinoGameProviders/slice/casinoGameProvidersSlice';
import { ProvidersTypes } from '../../../modules/container/shared/features/casinoGameProviders/types/casinoGameProvidersTypes';
import { LayoutConfigInnerLayoutTypes } from '../../constants';
import { useCasinoLayoutConfig } from '../../hooks/useCasinoLayoutConfig';

export const useMobileAppRestrictions = (): MobileAppRestrictions => {
  const mobileAppRestrictions = useCasinoLayoutConfig({
    innerLayout: LayoutConfigInnerLayoutTypes.MOBILE_APP_RESTRICTIONS,
  });
  const activeProviderAlias = useAppSelector(gameProvidersSelectors.activeProvider);
  const { id } = useProviderDataByAlias(activeProviderAlias);

  const allowProvidersAlias = mobileAppRestrictions?.allowProvidersAlias || [];
  const allowProvidersIds = mobileAppRestrictions?.allowProvidersIds || [];
  const allowGameIdsByProviders = mobileAppRestrictions?.allowGameIdsByProviders || [];

  const modifiedQuery = () => {
    if (activeProviderAlias !== '-1') {
      return `/api/gaming/public/games/searchByLive?isLive=false&gameProviderIds=${id}`;
    }
    const randomProvider = allowProvidersIds[Math.floor(Math.random() * allowProvidersIds.length)];
    return `/api/gaming/public/games/searchByLive?isLive=false&gameProviderIds=${randomProvider}`;
  };

  const filterProviders = (providers: ProvidersTypes.ProviderWithType[]) => {
    return providers.filter((provider) => {
      return allowProvidersAlias.includes(provider.alias);
    });
  };

  const filterByGameIds = (gameData: CasinoGamesTypes.GameProps[]) => {
    if (gameData.length) {
      return gameData.filter((game) => {
        if (allowGameIdsByProviders[game.providerAlias]) {
          return allowGameIdsByProviders[game.providerAlias].includes(game.id);
        }
        return false;
      });
    }

    return gameData;
  };

  const modifyLastWinQuery = (query: string) => {
    return query.replace(/(providers=)[^&]+/, '$1' + allowProvidersIds.join(','));
  };

  return {
    filterProviders,
    allowProvidersIds,
    filterByGameIds,
    modifiedQuery,
    modifyLastWinQuery,
    isRestricted: window.isRNWebView && isIOS && window.appVersion === mobileAppRestrictions?.appVersion,
  };
};
