import React from 'react';
import CountDown from './CountDown';

/** CountDownTimer */
interface CountDownTimerProps {
  // Panel Header
  headingText: string;
  counterText: string;
  // Home
  endDate: string;
  timerIsFinnish: (isDone: boolean) => void;
  timeZone?: 'localTime' | 'systemTime';
}

const CountDownTimer: React.FC<CountDownTimerProps> = ({
  headingText,
  counterText,
  endDate,
  timerIsFinnish,
  timeZone,
}) => {
  return (
    <div className="text-center mb-3">
      {headingText}{' '}
      <b>
        <CountDown timeZone={timeZone} endDate={endDate} timerIsFinnish={timerIsFinnish} /> {counterText}
      </b>
    </div>
  );
};

export default CountDownTimer;
