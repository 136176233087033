import React, { useCallback, useEffect, useRef } from 'react';
import { Flex } from 'modules/casino/shared/components/layout';
import { casinoSearchSelectors } from 'modules/casino/shared/features/casinoSearch/casinoSearchSlice';
import { useAppDispatch, useAppSelector } from 'store';
import config from 'utils/config';
import { useProviderDataByAlias } from '../../hooks/useProviderDataByAlias';
import {
  addFilteredProviderId,
  gameProvidersSelectors,
  removeFilteredProviderId,
} from '../../slice/casinoGameProvidersSlice';
import { ProvidersBarUI } from '.';

/** ProvidersBarUI Item */
interface ItemProps {
  providerAlias: string;
  inDrawer?: boolean;
}

const ItemFilter: React.FC<ItemProps> = ({ providerAlias, inDrawer }) => {
  const dispatch = useAppDispatch();
  const buttonRef = useRef<HTMLDivElement>(null);
  const { code: providerCode, id: providerId } = useProviderDataByAlias(providerAlias);
  const searchValue = useAppSelector(casinoSearchSelectors.casinoSearchValue);
  const allFilteredProviders = useAppSelector(gameProvidersSelectors.casinoAllFilteredProviderIds);

  const onPress = useCallback(() => {
    if (buttonRef.current) {
      if (buttonRef.current.classList.contains('providers-bar__item--active')) {
        buttonRef.current.classList.remove('providers-bar__item--active');
        dispatch(removeFilteredProviderId(providerId));
      } else {
        buttonRef.current.classList.add('providers-bar__item--active');
        dispatch(addFilteredProviderId(providerId));
      }
    }
  }, [searchValue]);

  useEffect(() => {
    if (allFilteredProviders.includes(providerId) && buttonRef.current) {
      buttonRef.current.classList.add('providers-bar__item--active');
    }
  }, []);

  return (
    <Flex.AC
      ref={buttonRef}
      className="providers-bar__item"
      key={providerId}
      onClick={onPress}
      data-qid={providerAlias}
    >
      <ProvidersBarUI.Logo
        inDrawer={inDrawer}
        icon={providerCode}
        path={`${config.CDN_URL}/gaming-content/game-providers-new/logos/`}
        className={'providers-bar__icon'}
        height={40}
      />
    </Flex.AC>
  );
};

export default ItemFilter;
