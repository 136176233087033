import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ExpandableIcon } from 'components/shared/icons';
import PageBox from './PageBox';

interface TermsBoxProps {
  headerText: string;
  titleColor?: string;
  children: React.ReactNode;
}

const TermsBox: React.FC<TermsBoxProps> = ({ headerText, titleColor, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: '-100%' },
  };

  return (
    <div className={'overflow-hidden prm-page__wrapper prm-page__terms'}>
      <PageBox
        title={headerText}
        titleColor={titleColor}
        className="d-flex-ac cursor-pointer prm-page__terms-header"
        onClick={handleExpand}
      >
        <ExpandableIcon className="ml-auto prm-page__terms-toggler" isExpanded={isExpanded} />
      </PageBox>
      <motion.div layout animate={isExpanded ? 'open' : 'closed'} variants={variants}>
        {isExpanded && <PageBox>{children}</PageBox>}
      </motion.div>
    </div>
  );
};

export default TermsBox;
