import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import CTAUI from '../../../components/CTAUI';

type Props = { icon: string; title: string; showBackButton: boolean; onBackClick: (boolean: boolean) => void };

const MyAccountBackButton: React.FC<Props> = ({ icon, title, showBackButton, onBackClick }) => {
  const { t } = useTranslation();

  if (!showBackButton) return null;

  return (
    <CTAUI.Header
      title={t(title)}
      showBackButton
      isRead={false}
      useIconPath={false}
      icon={icon}
      onBackClick={() => onBackClick(false)}
      className="mpd-cta-header__notification"
    />
  );
};

export default memo(MyAccountBackButton);
