export const convertDateToUTC = (date: Date): Date => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};

export const getFromDate = (activeDateFilterType: string) => {
  const date = new Date();
  switch (activeDateFilterType) {
    case 'twentyFour':
      date.setDate(date.getDate() - 1);
      return convertDateToUTC(date).getTime();
    case 'fortyEight':
      date.setDate(date.getDate() - 2);
      return convertDateToUTC(date).getTime();
  }
};

export const getFromDateNormal = (activeDateFilterType: string) => {
  const date = new Date();
  switch (activeDateFilterType) {
    case 'twentyFour':
      date.setDate(date.getDate() - 1);
      return date.getTime();
    case 'fortyEight':
      date.setDate(date.getDate() - 2);
      return date.getTime();
  }
};

export const getToDate = () => {
  return convertDateToUTC(new Date()).getTime();
};

export const getToDateNormal = () => {
  return new Date().getTime();
};
