import { useLoadingState } from 'modules/casino/shared/hooks/useLoadingState';
import { useAppSelector } from 'store';
import { gameProvidersSelectors } from '../slice/casinoGameProvidersSlice';

type HookType = () => {
  providersFetchStatusInitial: boolean;
  providersFetchStatusLoading: boolean;
  providersFetchStatusSuccess: boolean;
  providersFetchStatusError: boolean;
};

export const useProvidersFetchStatus: HookType = () => {
  const loadingState = useAppSelector((state) => gameProvidersSelectors.providersStatus(state));
  const { hasInitial, isLoading, hasSuccess, hasError } = useLoadingState(loadingState);

  return {
    providersFetchStatusInitial: hasInitial,
    providersFetchStatusLoading: isLoading,
    providersFetchStatusSuccess: hasSuccess,
    providersFetchStatusError: hasError,
  };
};
