import React from 'react';
import { Text } from 'components/shared';
import { Badge } from 'components/shared/badges';
import { Flex } from 'components/shared/layout';
import { UI } from 'components/shared/UI';

interface GridHeaderProps {
  icon: string;
  text: string;
  promosNumber: number;
}

const GridHeader: React.FC<GridHeaderProps> = ({ icon, text, promosNumber }) => {
  return (
    <Flex.AC className="prm-grid-header">
      <UI.SVGCDN className="mr-2 prm-grid-header__icon" icon={icon} />
      <Text className="prm-grid-header__text" text={text} />
      <Badge variant="circle" className="ml-2 prm-grid-header__badge" label={promosNumber} />
    </Flex.AC>
  );
};

export default GridHeader;
