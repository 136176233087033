import React from 'react';
import { useAppSelector } from 'store';
import AmountCurrency from './AmountCurrency';
import { MPDUI } from './MPDUI';
import { selectMyProfile } from '../slice/myProfile.slice';

type Props = {
  amount: string;
  dataQid?: string;
};

const MyProfileBalance: React.FC<Props> = ({ amount, dataQid }) => {
  const balanceVisibility = useAppSelector(selectMyProfile.balanceVisibility);

  return (
    <MPDUI.Text variant="primary" isTruncated={false} dataQid={dataQid}>
      {balanceVisibility ? (
        <AmountCurrency spaceBefore={false} currencyClassName="currency" amount={amount} />
      ) : (
        '********'
      )}
    </MPDUI.Text>
  );
};

export default MyProfileBalance;
