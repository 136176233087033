import React from 'react';
import { t } from 'i18next';
import { Text } from 'components/shared';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { formatCurrency } from 'modules/retail/modules/cashier/utils';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { toFixed } from 'utils/common/helpersCommon';
import { CheckTicket } from '../types/ssbt.types';

interface MaxProfitProps {
  text: string;
  ticketInfo: CheckTicket | null;
  currency: string;
  isCurrencyBeforeAmount?: boolean;
}

const MaxProfit: React.FC<MaxProfitProps> = (props) => {
  const isMagnumBetRo = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.IS_MAGNUM_BET_RO,
    deviceLayout: 'general',
  });

  const totalBetsText = formatCurrency(
    props?.ticketInfo?.ticket?.totalStake || 0,
    props.currency,
    props.isCurrencyBeforeAmount,
  );
  const maxProfitText = formatCurrency(
    props.ticketInfo?.possibleWin || 0,
    props.currency,
    props.isCurrencyBeforeAmount,
  );

  const totalAmountText = formatCurrency(
    props.ticketInfo?.totalAmountAfterTax || 0,
    props.currency,
    props.isCurrencyBeforeAmount,
  );

  const profitText = formatCurrency(props.ticketInfo?.winAmount || 0, props.currency, props.isCurrencyBeforeAmount);

  const voidAmountText = formatCurrency(props.ticketInfo?.winAmount || 0, props.currency, props.isCurrencyBeforeAmount);

  const classProp = { class: 'd-flex-col' };

  return (
    <egtd-ticket-maxprofit {...classProp}>
      {props?.ticketInfo?.status === 'ACCEPTED' && (
        <>
          <Text text={`${t('Cashier.CashierModal.totalBetsAmount')}: ${totalBetsText}`} />
          {isMagnumBetRo && (
            <Text text={`${t('Cashier.Receipt.betTax')}: ${toFixed(props.ticketInfo.fee, 2)} ${props.currency}`} />
          )}
          <Text text={`${t('Cashier.CashierCheckTicket.maxProfit')} ${maxProfitText}`} />
        </>
      )}
      {props?.ticketInfo?.status === 'CANCELLED' && (
        <Text text={`${t('Cashier.CashierModal.totalBetsAmount')}: ${totalBetsText}`} />
      )}
      {props.ticketInfo?.winAmount !== 0 && (
        <>
          <Text text={`${t('Cashier.Print.profitText')}: ` + `${profitText}`} />
          {isMagnumBetRo && (
            <Text
              text={`${t('Cashier.Receipt.betTax')}: ` + `${toFixed(props.ticketInfo?.tax, 2) + ' ' + props.currency}`}
            />
          )}
        </>
      )}
      {props.ticketInfo?.voidAmount !== 0 && (
        <Text text={`${t('Cashier.CashierCheckTicket.voidText')}: ` + `${voidAmountText}`} />
      )}
      {props.ticketInfo?.totalAmount !== 0 && (
        <Text
          text={
            `${t('Cashier.CashierCheckTicket.checkTicketTotalSum')} ` +
            `${
              props.ticketInfo?.totalAmountAfterTax !== 0
                ? `${totalAmountText}`
                : toFixed(props.ticketInfo?.totalAmount, 2) + ' ' + props.currency
            }`
          }
        />
      )}
    </egtd-ticket-maxprofit>
  );
};

export default MaxProfit;
