import React from 'react';
import { Modal } from 'components/shared/Modal';
import PrizeDropWinView from 'components/shared/prizeDrop/Preview/PrizeDropWinView';
import { WsNotificationsTypes } from '../../myProfile/sections/tabs/notificationsTab/types/wsNotifications.types';

type Props = { activeNotification: WsNotificationsTypes.Notification };

const PrizeDropWinModal: React.FC<Props> = ({ activeNotification }) => {
  return (
    <Modal visible dialogClassName="modal-dialog-centered">
      <PrizeDropWinView activeNotification={activeNotification} />
    </Modal>
  );
};

export default PrizeDropWinModal;
