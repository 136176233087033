import { updateCasinoLiveData } from 'modules/casino/modules/container/cellsContainer/cellls/slice/casinoGamesSlice';
import { setDeclaration } from 'shared/common/features/interceptors/slice/interceptors.slice';
import { isProductionBuild, localLog } from 'utils/common/helpersCommon';

export const casinoUpdateFunction = {
  LIVE_CASINO_GAME_UPDATE: updateCasinoLiveData,
};

export const interceptorsUpdateFunction = {
  FUNDS_DECLARATION: setDeclaration,
};

// Safeguard against any bad imports
if (!isProductionBuild) {
  const funcMaps = [interceptorsUpdateFunction, casinoUpdateFunction, casinoUpdateFunction];
  const emptyFuncs: string[] = [];
  funcMaps.forEach((funcMap) => {
    Object.entries(funcMap).forEach(([key, value]) => {
      if (value === undefined) {
        emptyFuncs.push(key);
      }
    });
  });

  if (emptyFuncs.length) {
    localLog({
      message: `⚠️ %c~ Critical. The following updateFuncs have not been initialized and are undefined. Please check your imports or the reference to the following updateFunctions! color:magenta, ${emptyFuncs}`,
      type: 'warn',
    });
  }
}
