import React from 'react';
import clsx from 'clsx';
import { Flex } from 'modules/casino/shared/components/layout';
import { WidgetAlignmentItems, WidgetTemplate } from 'modules/casino/shared/constants';
import { CasinoWidgetsTypes } from '../../../../types/casinoWidgetsTypes';

interface TextProps {
  description?: CasinoWidgetsTypes.WidgetTitle;
  type: CasinoWidgetsTypes.WidgetTypes;
  additionalClass?: string;
}

const Text: React.FC<TextProps> = ({ description, type, additionalClass }) => {
  if (!description?.text && !description?.textSecondary) return <></>;

  const textClass = clsx('cw__text', type && `cw__text--${WidgetTemplate[type]}`, additionalClass);
  const textStyle = {
    alignItems: WidgetAlignmentItems[description.alignmentX],
    justifyContent: WidgetAlignmentItems[description.alignmentY],
  };
  return (
    <Flex.Col className={textClass} style={textStyle}>
      {description.text && (
        <div className="cw__text-title" style={{ color: description.textColor }}>
          {description.text}
        </div>
      )}
      {description.textSecondary && (
        <div className="cw__sub-title" style={{ color: description.textSecondaryColor }}>
          {description.textSecondary}
        </div>
      )}
    </Flex.Col>
  );
};
Text.displayName = 'GenericWidgetsUI.Text';
export default Text;
