import useShowIngameNotifications from 'shared/common/features/inGameNotifications/hooks/useShowIngameNotifications';
import { useFetchPopupNotifications } from './hooks/useFetchPopupNotifications';
import useShowActiveNotification from './hooks/useShowActiveNotification';

const PopUpNotificationInit = (): null => {
  useFetchPopupNotifications();
  useShowActiveNotification();
  useShowIngameNotifications();

  return null;
};

export default PopUpNotificationInit;
