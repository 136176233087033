interface INameToValueMap {
  [key: string]: { name: string; callback: (data: Record<string, unknown>) => void };
}

interface IListener {
  count: number;
  refs: INameToValueMap;
}

class EventService {
  private static _listener: IListener = {
    count: 0,
    refs: {},
  };

  static addEventListener(eventName: string, callback: (data: Record<string, unknown>) => void): string {
    if (typeof eventName === 'string' && typeof callback === 'function') {
      EventService._listener.count++;
      const sportEventId = 'l' + EventService._listener.count;
      EventService._listener.refs[sportEventId] = {
        name: eventName,
        callback,
      };
      return sportEventId;
    }
    return '';
  }

  static removeEventListener(id: string) {
    if (typeof id === 'string') {
      return delete EventService._listener.refs[id];
    }
    return false;
  }

  static removeAllListeners() {
    let removeError = false;
    Object.keys(EventService._listener.refs).forEach((_id) => {
      const removed = delete EventService._listener.refs[_id];
      removeError = !removeError ? !removed : removeError;
    });
    return !removeError;
  }

  static emitEvent(eventName: string, data: Record<string, unknown>) {
    Object.keys(EventService._listener.refs).forEach((_id) => {
      if (EventService._listener.refs[_id] && eventName === EventService._listener.refs[_id].name) {
        EventService._listener.refs[_id].callback(data);
      }
    });
  }
}

export { EventService };
