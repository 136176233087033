import { CasinoGamesTypes } from '../../cellls/types/casinoGamesTypes';

export const getGameLayoutByUniqueId = (gameUniqueId: string): CasinoGamesTypes.GameLayout => {
  const gameLayoutRegExp = new RegExp('[^#]*$');
  const matched = gameUniqueId?.match(gameLayoutRegExp);
  const gameLayout = (matched && matched[0]) as CasinoGamesTypes.GameLayout;
  return gameLayout;
};

export const stopLoadingHandler = ({ totalElements, pageSize, gridPage }): boolean => {
  return Math.ceil(totalElements / (pageSize / (gridPage + 1))) - 1 === gridPage;
};
