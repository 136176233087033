import React, { forwardRef, HTMLAttributes } from 'react';
import clsx from 'clsx';

const flexTypeClassNameMap = {
  Flex: 'd-flex',
  AC: 'd-flex-ac',
  ASTART: 'd-flex-astart',
  AE: 'd-flex-ae',
  AS: 'd-flex-as',
  ABJB: 'd-flex-ab-jb',
  ACJA: 'd-flex-ac-ja',
  ACJB: 'd-flex-ac-jb',
  ACJE: 'd-flex-ac-je',
  ASJB: 'd-flex-as-jb',
  ASJE: 'd-flex-as-je',
  JB: 'd-flex-jb',
  JE: 'd-flex-je',
  Center: 'd-flex-center',
  Col: 'd-flex-col',
  ColAC: 'd-flex-col-ac',
  ColCenter: 'd-flex-col-center',
  ColJA: 'd-flex-col-ja',
  ColJB: 'd-flex-col-jb',
  ColJC: 'd-flex-col-jc',
  ColJE: 'd-flex-col-je',
} as const;

type FlexLayoutTypes = keyof typeof flexTypeClassNameMap;

interface FlexProps extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  className?: string;
  children?: React.ReactNode | string | JSX.Element[];
}
const classNameGenerator = (flexType: FlexLayoutTypes, className?: string) => {
  return clsx(flexTypeClassNameMap[flexType], className);
};
type ComponentMap = Record<
  FlexLayoutTypes,
  React.ForwardRefExoticComponent<FlexProps & React.RefAttributes<HTMLDivElement>>
>;
const flexComponents = Object.keys(flexTypeClassNameMap).reduce((componentsMap, name) => {
  //eslint-disable-next-line react/display-name
  componentsMap[name] = forwardRef<HTMLDivElement, FlexProps>(({ className, children, ...props }, ref) => {
    return (
      <div className={classNameGenerator(name as FlexLayoutTypes, className)} {...props} ref={ref}>
        <>{children}</>
      </div>
    );
  });
  componentsMap[name].displayName = name;
  return componentsMap;
}, {} as ComponentMap);

const FlexNamespace = Object.assign(flexComponents.Flex, flexComponents);

export { FlexNamespace as Flex };
