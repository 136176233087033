import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { CarouselTypes } from '../types/captainUpCarosel.types';

const initialState: CarouselTypes.InitialState = {
  items: [],
  isLoading: false,
};

export const carouselThunk = {
  fetchCarousel: createAbortThunk<CarouselTypes.CarouselItem[], CarouselTypes.ThunkArgs, string | null>(
    'cuCarousel/getCarousel',
    async ({ apiUri }, { rejectWithValue, source }) => {
      try {
        const url = `${config.API_URL}${apiUri}`;
        const response = await axiosInstance.get<CarouselTypes.CarouselItem[]>(url, {
          cancelToken: source.token,
        });
        return response.data;
      } catch (err) {
        if (Axios.isCancel(err)) {
          return rejectWithValue(null);
        }
        return rejectWithValue(getError.default());
      }
    },
  ),
};

const cuCarousel = createSlice({
  name: 'cuCarousel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(carouselThunk.fetchCarousel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(carouselThunk.fetchCarousel.fulfilled, (state, action) => {
        state.items = action.payload;
        state.isLoading = false;
      })
      .addCase(carouselThunk.fetchCarousel.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const selectCarousel = {
  getItems: (state: RootState): CarouselTypes.CarouselItem[] => state.captainUp.carousel.items,
  isLoading: (state: RootState): boolean => state.captainUp.carousel.isLoading,
};

export const { reducer: carouselReducer } = cuCarousel;
