import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { UI } from 'modules/casino/shared/components/UI';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { TimeDataType } from 'modules/casino/shared/utils/dateUtils';
import { Promotions } from 'modules/casino/shared/utils/translationUtils/translationStrings';
import { Breakpoints } from 'modules/casino/theme/Theme';
import RemaningPeriod from '../../../components/RemaningPeriod';

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  dateDifference?: TimeDataType;
}

const CardMeta: React.FC<Props> = ({ dateDifference, onClick }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isMobile}px)` });
  return (
    <div className={`${cssGlobalNS}-c-prm-lobby-card__meta d-flex-ac-jb`}>
      <UI.Button
        size={`${isMobile ? 'sm' : 'md'}`}
        text={t(Promotions.PromotionCell.showMore)}
        className="mr-auto"
        onClickHandler={onClick}
      />

      {dateDifference && (dateDifference.days > -1 || dateDifference.status) && (
        <RemaningPeriod className="ml-2" dateDifference={dateDifference} />
      )}
    </div>
  );
};

export default CardMeta;
