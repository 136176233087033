import React from 'react';
import { BlockDividerProps } from './History.types';

/** BlockDivider */
const BlockDivider: React.FC<BlockDividerProps> = () => {
  // const classProp = className ? { class: className } : undefined;

  return <egtd-p-history-item-block-divider />;
};

export default BlockDivider;
