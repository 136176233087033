import { HomeTypes } from './types/HomeTypes';
import { Mixins } from '../../StyledComponents/shared/index';
export const Home: HomeTypes.State = {
  BlockTitle: {
    titleFontSize: Mixins.Size.clampedSize(18, 22),
    titleColor: '#fff',
    linkFontSize: Mixins.Size.clampedSize(12, 17),
    linkColor: 'var(--yellow)',
    iconSizeDesktop: 9,
    iconSize: 11,
  },
  VideoBanner: {
    description: {
      color: '#fff',
      fontSize: Mixins.Size.clampedSize(16, 22),
    },
    button: {
      color: '#fff',
      background: '#0AB445',
      hoverColor: '#fff',
      hoverBg: '#0AB445',
    },
  },
};
