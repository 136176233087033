import React, { memo, RefObject } from 'react';
import clsx from 'clsx';
import { useFilterHiddenVerticals } from 'hooks/common/useFilterHiddenVerticals';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import TopNavbarMenu from './TopNavbarMenu';
import { LayoutConfigTypes } from '../../../general/types/generalSlice.types';
// import { useShowTopNavOnClick } from '../../hooks/useShowTopNavOnClick';
// import ShowHideArrow from '../common/ShowHideArrow';

type TopNavbarTypes = {
  triggerAnimationEnd: (handleAnimationEnd: boolean) => void;
  classByLocation: string;
  topNavBarRef: RefObject<HTMLDivElement>;
  isTabletOrMobile: boolean;
};

const TopNavbar: React.FC<TopNavbarTypes> = ({
  triggerAnimationEnd,
  classByLocation,
  topNavBarRef,
  isTabletOrMobile,
}) => {
  const navigationMenu = useFilterHiddenVerticals();
  const topNav = useLayoutConfigType({ moduleLayout: 'platform', innerLayout: LayoutConfigTypes.NAVIGATION })?.topNav;
  // const { handleShowHideTopNav, topNavInitialCollapsed } = useShowTopNavOnClick();

  if (!topNav) return null;
  const topNavbarClass = clsx(
    'top-navbar__wrapper',
    // topNavInitialCollapsed && 'd-flex',
    classByLocation && classByLocation,
  );

  const verticals = navigationMenu || [];

  return (
    <div
      ref={topNavBarRef}
      className={topNavbarClass}
      onAnimationStart={() => {
        !isTabletOrMobile && triggerAnimationEnd(false);
      }}
      onAnimationEnd={() => {
        !isTabletOrMobile && triggerAnimationEnd(true);
      }}
    >
      <TopNavbarMenu isEmpty={!verticals.length} />
      {/* {topNavInitialCollapsed && <ShowHideArrow handleClick={handleShowHideTopNav} />} */}
    </div>
  );
};

export default memo(TopNavbar);
