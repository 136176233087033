import { createSlice } from '@reduxjs/toolkit';

const initialState: { isDropdownVisible: boolean } = {
  isDropdownVisible: false,
};

const platformDropdownBackdrop = createSlice({
  name: 'platformDropdownBackdrop',
  initialState,
  reducers: {
    setDropdownBackdrop(state, action) {
      state.isDropdownVisible = action.payload;
    },
  },
});

export const { setDropdownBackdrop } = platformDropdownBackdrop.actions;

export default platformDropdownBackdrop.reducer;
