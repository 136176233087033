import { arrowStyleTypes } from 'modules/casino/shared/features/casinoScroll/casinoScrollTypes';
import { CategoryLayoutTypes } from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { CasinoWidgetTemplates } from '../../constants/casinoWidgetsConstants';

export const jackpotsWidgetWrapperClassesByLayout = {
  [CategoryLayoutTypes.LOBBY]: 'lobbyCategory',
  [CategoryLayoutTypes.INNER_CATEGORY]: 'innerCategory',
  [CategoryLayoutTypes.LANDING_CATEGORY]: 'landingCategory',
  [CategoryLayoutTypes.EXTERNAL_CATEGORY]: 'externalCategory',
};

export const jackpotsWidgetScrollWrapperParamsMap = {
  [CasinoWidgetTemplates.SECTION]: {
    typeArrow: arrowStyleTypes.CasinoGrid,
    href: '#chevron',
    viewBox: '0 0 24 24',
  },
  [CasinoWidgetTemplates.RIBBON]: {
    typeArrow: arrowStyleTypes.CasinoMenus,
    href: '/assets/img/icons/ui/sprites.svg#chevron',
    viewBox: '0 0 12 7',
    moveWith: 300,
  },
};
