import React from 'react';
import { Hands } from './HandIcons';
import { ActionHandItemWrapper } from './StyleComponents';
const addHands = {
  drag: <Hands.Drag />,
  hold: <Hands.Hold />,
  click: <Hands.Hold />,
};
const ActionHandItem = ({ type }) => {
  return <ActionHandItemWrapper $types={type}>{addHands[type]}</ActionHandItemWrapper>;
};

export default ActionHandItem;
