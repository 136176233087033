import { useEffect, useRef, useState } from 'react';

export const useSetDate = (endDate, days, inView, startIntrval) => {
  const now = new Date().getTime();
  const interval = useRef<NodeJS.Timeout | null>(null);
  const [date, setDate] = useState(new Date(endDate).getTime() - now);
  useEffect(() => {
    if (days === 0 && inView && startIntrval) {
      interval.current = setInterval(() => {
        setDate(new Date(endDate).getTime() - new Date().getTime());
        return new Date(endDate).getTime() - new Date().getTime();
      }, 1000);
    }
    return () => {
      interval.current && clearInterval(interval.current);
    };
  }, [inView, startIntrval, endDate, days]);

  useEffect(() => {
    if (date < 0) {
      interval.current && clearInterval(interval.current);
    }
  }, [date]);

  return date;
};
