import React from 'react';

type Props = {
  title: string;
  isCenter?: boolean;
  className?: string;
};

const LeaderBoardTitle: React.FC<Props> = ({ title, className }) => {
  return <div className={className && className}>{title}</div>;
};

export default LeaderBoardTitle;
