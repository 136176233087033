import { useCallback, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { getGridCellInViewMargin } from 'modules/casino/modules/container/cellsContainer/grid/components/gridCell/gridCellUtils';

interface ReturnProps {
  refLoaded: boolean;
  inView: boolean;
  ref: React.MutableRefObject<HTMLDivElement | null>;
  setRefs: (node: any) => void;
}

export const useSetRefsLoaded = (useView = false): ReturnProps => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [refLoaded, setRefLoaded] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: getGridCellInViewMargin(),
  });

  const setRefs = useCallback(
    (node) => {
      if (node !== null) {
        ref.current = node;
        useView && inViewRef(node);
        setRefLoaded(true);
      }
    },
    [ref],
  );

  return { refLoaded, setRefs, ref, inView };
};
