import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getBusinessUnit, getError, isEmpty } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { fetchGamificationPlayer } from '../../UserInfo/slice/gamificationPlayer.slice';
import { StoreTypes } from '../types/store.types';
import { normalizeShopItem, normalizeCategoryStore } from '../utils/store.utils';

const initialState: StoreTypes.InitialState = {
  categories: {},
  buyError: {},
  buyState: {},
  categoryError: {},
  finishByItem: false,
  isLoading: {},
  hasFetched: {},
  normalizedCategories: {},
  normalizedStores: {},
};

export const storeThunks: StoreTypes.Thunks = {
  getStoreByType: createAbortThunk(
    'store/getStoreByType',
    async ({ page, categoryId, limit = 100, isAuthenticated }, { rejectWithValue, signal, source }) => {
      try {
        signal.addEventListener('abort', () => source.cancel());

        const url = `${config.API_URL}/api/ews-game-journey/${
          isAuthenticated ? 'player' : 'public'
        }/store/get-by-category`;
        const params = { categoryId, skip: (page - 1) * limit, limit, workingBusinessUnit: getBusinessUnit() };

        const response = await axiosInstance.get(url, { params, cancelToken: source.token });

        return response.data;
      } catch (err) {
        if (Axios.isCancel(err)) {
          return rejectWithValue(null);
        }
        return rejectWithValue(getError.default());
      }
    },
  ),
  getItemById: createAbortThunk('store/getItemById', async ({ id }, { rejectWithValue, signal, source }) => {
    try {
      signal.addEventListener('abort', () => source.cancel());

      const url = `${
        config.API_URL
      }/api/ews-game-journey/player/store/get-item?id=${id}&workingBusinessUnit=${getBusinessUnit()}`;

      const response = await axiosInstance.get(url, { cancelToken: source.token });

      return response.data;
    } catch (err) {
      if (Axios.isCancel(err)) {
        return rejectWithValue(null);
      }
      return rejectWithValue(getError.default());
    }
  }),
  buyItem: createAbortThunk('store/buyItem', async ({ assetId, items }, { rejectWithValue, dispatch }) => {
    try {
      const fetchURL = `${
        config.API_URL
      }/api/ews-game-journey/player/purchase?itemId=${assetId}&itemsBought=${items}&workingBusinessUnit=${getBusinessUnit()}`;
      const response = await axiosInstance.post<string | StoreTypes.BonusItemResponseDtoType | null>(fetchURL);
      dispatch(fetchGamificationPlayer());

      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseData(err));
    }
  }),
};

const store = createSlice({
  name: 'store',
  initialState,
  reducers: {
    resetStore: () => initialState,
    resetStatus(state) {
      state.finishByItem = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(storeThunks.getStoreByType.pending, (state, action) => {
        const { alias } = action.meta.arg;
        state.isLoading[alias] = true;
      })
      .addCase(storeThunks.getStoreByType.fulfilled, (state, action) => {
        const { alias: aliasArg, page, polling } = action.meta.arg;

        state.categories[aliasArg] = action.payload?.map(({ alias }) => alias);

        const currPage = polling ? state.normalizedCategories?.[state.categories?.[aliasArg]?.[0]]?.page || page : page;
        normalizeCategoryStore(state.normalizedCategories, action.payload, currPage, polling);

        action.payload?.forEach((shopCategory) => {
          normalizeShopItem(state.normalizedStores, shopCategory.items || []);
        });

        state.hasFetched[aliasArg] = true;
        state.isLoading[aliasArg] = false;
      })
      .addCase(storeThunks.getStoreByType.rejected, (state, action) => {
        const { alias } = action.meta.arg;

        state.isLoading[alias] = false;
        if (action.payload) state.categoryError[alias] = true;
      });
    builder
      .addCase(storeThunks.buyItem.fulfilled, (state, action) => {
        const { assetId } = action.meta.arg;
        if (!isEmpty(action.payload)) {
          if (typeof action.payload === 'string') {
            const stock = JSON.parse(action.payload);
            if (typeof stock === 'number') {
              state.normalizedStores[assetId] = { ...state.normalizedStores[assetId], stock };
            }
          } else if (typeof action.payload === 'object') {
            const { bonusItemResponseDto, itemQuantity } = action.payload;
            const stock = itemQuantity ? { stock: itemQuantity } : {};
            state.normalizedStores[assetId] = {
              ...state.normalizedStores[assetId],
              ...bonusItemResponseDto,
              ...stock,
            };
          }
        }
        if (state.buyState[assetId]) {
          delete state.buyState[assetId];
        }

        state.finishByItem = true;
      })
      .addCase(storeThunks.buyItem.pending, (state, action) => {
        const { assetId } = action.meta.arg;
        state.buyState[assetId] = 'pending';
        state.finishByItem = false;
      })
      .addCase(storeThunks.buyItem.rejected, (state, action) => {
        const { assetId } = action.meta.arg;

        if (action.payload?.message) {
          state.buyError[assetId] = action.payload.message;
        }
        state.finishByItem = true;
        if (state.buyState[assetId]) {
          delete state.buyState[assetId];
        }
      });
    builder.addCase(storeThunks.getItemById.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.normalizedStores[id] = action.payload;
    });
  },
});
export const { resetStatus, resetStore } = store.actions;
export const { reducer: storeReducer } = store;
export const selectStore = {
  isLoading:
    (alias: string) =>
    (state: RootState): boolean =>
      state.captainUp.store.isLoading?.[alias] || false,
  hasFetched:
    (alias: string) =>
    (state: RootState): boolean =>
      state.captainUp.store.hasFetched?.[alias] || false,
  hasError:
    (alias: string) =>
    (state: RootState): boolean =>
      state.captainUp.store.categoryError?.[alias] || false,
  categoriesByType:
    (alias: string) =>
    (state: RootState): string[] =>
      state.captainUp.store.categories?.[alias],
  byItemFinish: (state: RootState): boolean => state.captainUp.store.finishByItem,
  getById:
    (id: string) =>
    (state: RootState): StoreTypes.Item =>
      state.captainUp.store.normalizedStores[id],
  getBuyItemError:
    (id: string) =>
    (state: RootState): string | undefined =>
      state.captainUp.store.buyError?.[id],
  getCategory:
    (category: string) =>
    (state: RootState): StoreTypes.Category =>
      state.captainUp.store.normalizedCategories?.[category],
  hasStoreItems: (state: RootState) => {
    let total = 0;
    Object.values(state.captainUp.store.normalizedCategories)?.forEach((category) => {
      if (category) {
        total += category.totalItems;
      }
    });
    return total > 0;
  },
  isPurchaseInProgress:
    (id: string) =>
    (state: RootState): boolean =>
      state.captainUp.store.buyState?.[id] === 'pending',
};
