import { useAppSelector } from 'store';
import { jackpotSelectors } from '../features/jackpots/jackpotSlice';
import { JackpotTypes } from '../features/jackpots/jackpotTypes';

type UseCategoryJackpotReturnTypes = {
  jackpotId: string;
  jackpotDetails: JackpotTypes.JackpotCategoryDetails | undefined;
};

const useCategoryJackpot = (jackpotId): UseCategoryJackpotReturnTypes => {
  const jackpotDetails = useAppSelector((state) => jackpotSelectors.selectJackpotCategoryDetails(state, jackpotId));

  return {
    jackpotId,
    jackpotDetails: jackpotDetails && {
      ...jackpotDetails,
      template: jackpotDetails?.template?.toLowerCase() || '',
    },
  };
};

export default useCategoryJackpot;
