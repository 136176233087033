import {
  loadBackgroundSymbol,
  loadColorSymbol,
  loadTypeSymbol,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTableCharacters/components/DataCharacterMaping';
import {
  CardColor,
  GameCellDataCharactersTypes,
  RouletteBg,
  SicBo,
  spreadBetNumbetPosition,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTableCharacters/gameCellTableCharactersConstants';
import { Theme } from 'modules/casino/theme/Theme';

const icon = (symbol) => {
  return (
    <>
      <svg className="game-data__char-icon" viewBox="0 0 7.5 8">
        <use href={`${Theme.icons.liveCasinoGames}#${symbol}`} />
      </svg>
    </>
  );
};

const crazyTime = (symbol) => {
  switch (symbol) {
    case 'ca':
      return icon(symbol);
    case 'pa':
      return icon(symbol);

    case 'fl':
    case 'fl_r':
    case 'fl_b':
      return icon('fl');
    case 'bo':
      return icon(symbol);

    default:
      return symbol;
  }
};

const Icons = (symbol) => {
  switch (symbol) {
    case '1':
      return icon('1_icon');
    case '2':
      return icon('2_icon');
    case '5':
      return icon('5_icon');
    case '10':
      return icon('10_icon');
    case '2 SPINS':
      return icon('2_spins');
    case '5 SPINS':
      return icon('5_spins');
    case 'MULTIPLIER MOUNTAIN':
      return icon('multiplayer_mountain');
    case 'MYSTERY CARD SOLDIERS' || 'MYSTERY CARD SOLDIERS':
      return icon('mystery');
    default:
      return symbol;
  }
};

const monopoly = (symbol) => {
  const createIcon = (symbolElem) => {
    return (
      <>
        {symbolElem}
        <>
          <svg className="game-data__char-icon" viewBox="0 0 7.5 8">
            <use href={`${Theme.icons.liveCasinoGames}#dices`} />
          </svg>
        </>
      </>
    );
  };
  switch (symbol) {
    case '2r':
      return createIcon('2');
    case '4r':
      return createIcon('4');
    case 'ch':
      return '8x';

    default:
      return symbol;
  }
};

const dreamCatcher = (symbol) => {
  if (symbol == 'X7' || symbol == 'X2') {
    return [symbol];
  }
  return ('0' + symbol).slice(-2);
};

const dices = (symbol) => {
  const number = symbol.value?.split('|').map(function (item) {
    const i: number = parseInt(item, 10);
    return i ? i : item;
  });
  return sumNumbers(number);
};

const superSicBoColors = (symbol) => {
  const asd = symbol.value?.split('|');
  if (asd[0] === asd[1] && asd[1] === asd[2]) return 'triple';

  return SicBo[dices(symbol)];
};

const rouletteBgSymbol = (symbol) => {
  if (isNumeric(symbol)) {
    for (let i = 0; i < spreadBetNumbetPosition.length; i++) {
      if (
        (symbol > spreadBetNumbetPosition[i][0] && symbol < spreadBetNumbetPosition[i][1]) ||
        symbol === spreadBetNumbetPosition[i][0] ||
        symbol === spreadBetNumbetPosition[i][1]
      ) {
        return RouletteBg[`${spreadBetNumbetPosition[i][0]}-${spreadBetNumbetPosition[i][1]}`];
      }
    }
  } else {
    return RouletteBg[symbol];
  }
};

const rouletteConvertSymbol = (symbol) => {
  // Spread bet roulette
  if (symbol.spreadBetResult) {
    return [symbol.number, symbol.spreadBetResult];
  }
  // Age Of The Gods Bonus Roulette
  if (symbol === 'SLOT_GAME') {
    return [icon('slot_game')];
  }
  // Quantum Roulette Live
  if (symbol.multipliers) {
    return [symbol.number];
  }
  // Spin Win Roulette
  if (symbol.multiplier) {
    return [symbol.value];
  }
  return symbol.toString().split('|');
};

const Cards = (symbol) => {
  return [symbol.charAt(1), icon(symbol.charAt(0).toLowerCase())];
};

const CardsColors = (symbol) => {
  return CardColor[symbol.charAt(0)];
};

export const symbolFunc = {
  crazyTime,
  monopoly,
  dreamCatcher,
  dices,
  superSicBoColors,
  rouletteConvertSymbol,
  Icons,
  rouletteBgSymbol,
  Cards,
  CardsColors,
};

export const sicBoDeluxeMultipliers = (symbol, sortNumbers) => {
  let multiplierSum = 0;
  symbol.multipliers?.map((multiplier) => {
    if (multiplier.betType.indexOf('BET_SB_TRIPLE_ANY') === 0) {
      sortNumbers.every((val, i, arr) => val === arr[0]) && (multiplierSum = +multiplier.multiplier);
    } else if (multiplier.betType.indexOf('BET_SB_DOUBLE_') === 0) {
      const number = multiplier.betType.replace('BET_SB_DOUBLE_', '');
      symbol.value.split(number).length - 1 === 2 && (multiplierSum = +multiplier.multiplier);
    } else if (multiplier.betType.indexOf('BET_SB_TRIPLE_') === 0) {
      const number = multiplier.betType.replace('BET_SB_TRIPLE_', '');
      symbol.value.split(number).length - 1 === 3 && (multiplierSum = +multiplier.multiplier);
    } else if (multiplier.betType.indexOf('BET_SB_NUMBER_') === 0) {
      //  ??????????????
    } else if (multiplier.betType.indexOf('BET_SB_PAIR_') === 0) {
      const number = multiplier.betType.replace('BET_SB_PAIR_', '').split('_');
      if (symbol.value.indexOf(number[0]) >= 0 && symbol.value.indexOf(number[1]) >= 0) {
        multiplierSum = +multiplier.multiplier;
      }
    } else if (multiplier.betType.indexOf('BET_SB_TOTAL_') === 0) {
      const number = JSON.parse(multiplier.betType.replace('BET_SB_TOTAL_', ''));
      const sum = sortNumbers.reduce((acc, curr) => JSON.parse(acc) + JSON.parse(curr), 0);
      number === sum && (multiplierSum = +multiplier.multiplier);
    }
  });
  return multiplierSum;
};

export const convertSymbol = (symbol, category: GameCellDataCharactersTypes) => {
  const Func = loadTypeSymbol[category];
  return Func && Func(symbol);
};

export const colorSymbol = (symbol, category: GameCellDataCharactersTypes) => {
  const Func = loadColorSymbol[category];
  return Func && Func(symbol);
};

export const backgroundSymbol = (symbol, category: GameCellDataCharactersTypes) => {
  const Func = loadBackgroundSymbol[category];
  return Func && Func(symbol);
};

export const isNumeric = (val) => {
  return /^-?\d+$/.test(val);
};

export const sumNumbers = (numbers) => {
  const reducer1 = (accumulator, currentValue) => accumulator + currentValue;
  return numbers?.reduce(reducer1);
};
