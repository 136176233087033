type LoadingOptions = {
  useAnimation: boolean;
  useLoadingEffect: boolean;
};

interface elementProps {
  loaded: boolean;
  visible: boolean;
  loadingOptions: LoadingOptions;
  animationEnd: boolean;
  elementId: string;
  onShowElement: () => void;
}

type Options = {
  loadingOptions: LoadingOptions;
  collectionId: string;
  elementId: string;
  onShowElement: () => void;
};

const elementsCollection: Record<string, elementProps[]> = {};

export const useLoadingElements = () => {
  const getCurrentIndex = (collectionId, elementId) => {
    return elementsCollection[collectionId]?.findIndex(function (item) {
      return item.elementId === elementId;
    });
  };

  const initLoading = (options: Options) => {
    const { loadingOptions, collectionId, elementId, onShowElement } = options;

    if (!elementsCollection[collectionId]) {
      elementsCollection[collectionId] = [];
    }

    const currentIndex = getCurrentIndex(collectionId, elementId);

    if (elementsCollection[collectionId][currentIndex]) {
      elementsCollection[collectionId][currentIndex].loadingOptions = loadingOptions;
      elementsCollection[collectionId][currentIndex].onShowElement = onShowElement;
    } else {
      elementsCollection[collectionId].push({
        loadingOptions: loadingOptions,
        elementId: elementId,
        loaded: false,
        visible: false,
        animationEnd: false,
        onShowElement: onShowElement,
      });
    }
  };

  const setLoaded = (collectionId: string, currentIndex) => {
    elementsCollection[collectionId][currentIndex].loaded = true;
  };

  const setVisible = (collectionId: string, currentIndex: number, pathName: string) => {
    if (pathName !== window.location.pathname) return;

    const itemsCollectionArr = elementsCollection[collectionId];
    if (itemsCollectionArr && itemsCollectionArr[currentIndex]) {
      const prevElement = currentIndex - 1;
      const nextElement = currentIndex + 1;
      const { visible, animationEnd } = itemsCollectionArr[currentIndex] || {};
      const {
        visible: prevVisible,
        animationEnd: prevAnimationEnd,
        loaded: prevLoaded,
      } = itemsCollectionArr[prevElement] || {};
      if (currentIndex === 0 && !visible && !animationEnd) {
        //FIRST ITEM
        showItem(collectionId, currentIndex);
        setVisible(collectionId, nextElement, pathName);
      } else if (prevVisible && prevAnimationEnd) {
        showItem(collectionId, currentIndex);
        if (itemsCollectionArr[nextElement] && !itemsCollectionArr[nextElement].loaded) return;
        setVisible(collectionId, nextElement, pathName);
      } else if (prevLoaded && !prevVisible && !prevAnimationEnd) {
        setVisible(collectionId, prevElement, pathName);
      }
    }
  };

  const showItem = (collectionId: string, currentIndex: number) => {
    if (elementsCollection[collectionId][currentIndex].visible) return;
    const useLoadingEffect = elementsCollection[collectionId][currentIndex].loadingOptions.useLoadingEffect;
    const useAnimation = elementsCollection[collectionId][currentIndex].loadingOptions.useAnimation;

    if (useLoadingEffect && useAnimation) {
      elementsCollection[collectionId][currentIndex].visible = true;
    }

    if (useLoadingEffect && !useAnimation) {
      elementsCollection[collectionId][currentIndex].animationEnd = true;
      elementsCollection[collectionId][currentIndex].visible = true;
    }

    if (!useLoadingEffect && !useAnimation) {
      elementsCollection[collectionId][currentIndex].animationEnd = true;
      elementsCollection[collectionId][currentIndex].visible = true;
    }

    elementsCollection[collectionId][currentIndex].onShowElement();
  };

  const helpers = {
    collectionLength: ({ collectionId }) => elementsCollection[collectionId] && elementsCollection[collectionId].length,
    currentIndex: ({ collectionId, elementId }) => getCurrentIndex(collectionId, elementId),
    forcedShowItem: ({ collectionId, currentIndex }) => showItem(collectionId, currentIndex),
    onLoadImage: ({ collectionId, elementId, pathName }) => {
      const currentIndex = getCurrentIndex(collectionId, elementId);
      setLoaded(collectionId, currentIndex);
      setVisible(collectionId, currentIndex, pathName);
    },
    onAnimationStart: ({ collectionId, elementId }) => {
      const currentIndex = getCurrentIndex(collectionId, elementId);
      elementsCollection[collectionId][currentIndex].animationEnd = true;
      elementsCollection[collectionId][currentIndex + 1]?.loaded && showItem(collectionId, currentIndex + 1);
    },
    reset: ({ collectionId, elementId }) => {
      const currentIndex = getCurrentIndex(collectionId, elementId);
      if (elementsCollection[collectionId][currentIndex]) {
        elementsCollection[collectionId][currentIndex] = {
          ...elementsCollection[collectionId][currentIndex],
          loaded: false,
          visible: false,
          animationEnd: false,
        };
      }
    },
    removeItem: ({ collectionId, elementId }) => {
      const currentIndex = getCurrentIndex(collectionId, elementId);
      elementsCollection[collectionId].splice(currentIndex, 1);
    },
  };

  return {
    helpers: helpers,
    initLoading: initLoading,
  };
};
