import { GameLaunchBonusTypes } from '../types/gameLaunchBonus.types';

export const getGamesWithPrizeDrop = (
  activePrizeDropCampaigns: GameLaunchBonusTypes.ActivePrizeDropCampaign[],
): string[] => {
  const allGamesWithPrizeDrop: string[] = [];

  activePrizeDropCampaigns.forEach((campaign) => {
    allGamesWithPrizeDrop.push(...campaign.games);
  });

  return allGamesWithPrizeDrop;
};
