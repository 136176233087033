import React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import cell02ComponentLoader from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell02/Cell02ComponentLoader';
import { LayoutTypes } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/LayoutTypes';
import {
  ComponentNames,
  ComponentType,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/layoutWrapperConstants';
import { SharedComponents } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared';
import { useDefaultWrapper } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/hooks/useDefaultWrapper';
import { useShowGameTitle } from '../../../../../shared/hooks/useShowGameTitle';

const GameCellWrapper: React.FC<LayoutTypes.GameCellWrapperProps> = ({
  gameUniqueId,
  categoryId,
  layoutGrid,
  subType,
}) => {
  const game = useGameProps({ gameUniqueId, categoryId });
  const { bottomTag, hasTranspBottomTag } = useDefaultWrapper({ game });

  const actionWrapperStyles = clsx([
    'casino-game-cell',
    bottomTag && 'casino-game-cell--bottom-tag',
    hasTranspBottomTag && 'casino-game-cell--bottom-tag-transparent',
  ]);
  const Description = cell02ComponentLoader(ComponentNames.Description, ComponentType.default);
  const { showGameTitle } = useShowGameTitle({ layoutGrid, subType });
  return (
    <StyledGameCellWrapper className={actionWrapperStyles} data-qid="casino-game-cell">
      {showGameTitle && <Description gameUniqueId={gameUniqueId} categoryId={categoryId} />}
      <SharedComponents.GameCellTags gameUniqueId={gameUniqueId} categoryId={categoryId} />
    </StyledGameCellWrapper>
  );
};
const StyledGameCellWrapper = styled.div`
  border-radius: var(--game-cell-br);
`;

export default GameCellWrapper;
GameCellWrapper.displayName = 'GameCellWrapper';
