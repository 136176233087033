// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { SocialAccountsUI } from 'pages/myAccount/tabs/account/sections/components/socialAccountsUI';
import SocialIcon from '../SocialIcon';
import { Social, SocialButtonInputTypes } from '../types/formSocial.types';

let authWindow;
const FacebookButton = {
  config: {
    src: 'https://connect.facebook.net/en_US/sdk.js',
    async: true,
    defer: true,
    crossOrigin: 'anonymous',
  },
  effect: ({ clientId, appVersion }: { clientId?: string; appVersion?: string }) => {
    FB.init({
      xfbml: true,
      appId: clientId,
      version: appVersion || 'v18.0',
    });
  },
  /* Default Button */
  /*
    <div
      className={'fb-login-button'}
      data-size={'large'}
      data-button-type={'continue_with'}
      data-use-continue-as={true}
      data-auto-logout-link={false}
      data-onlogin={'FBOnLogin'}
    />
  */
  element: ({ className, button, isDisabled, onClick, setIsLoading, onSocialAuth, t }: Social.ElementProps) => {
    const onClickHandler = isDisabled
      ? undefined
      : () => {
          onClick();
          setIsLoading(true);
          const open = window.open;
          window.open = (url, name, features) => (authWindow = open.call(window, url, name, features));
          FB.login(
            (loginResponse) => {
              window.open = open;
              if (loginResponse.status === 'unknown') setIsLoading(false);
              FB.getLoginStatus(
                (statusResponse: {
                  statusResponse: { userID: string; accessToken: string; expiresIn: number; signedRequest: string };
                }) => {
                  if (statusResponse.status === 'connected') {
                    FB.api(
                      '/me',
                      { fields: 'name,email' },
                      (apiResponse: { email: string; name: string; id: string }) => {
                        setIsLoading(false);
                        onSocialAuth?.({
                          username: statusResponse.authResponse.userID,
                          password: statusResponse.authResponse.accessToken,
                          userData: {
                            userId: apiResponse.id,
                            firstName: apiResponse.name?.split(' ')[0],
                            lastName: apiResponse.name?.split(' ')[1],
                            email: apiResponse.email,
                          },
                        });
                      },
                    );
                  }
                },
              );
            },
            { scope: 'email' },
          );
        };

    return button.inputType === SocialButtonInputTypes.UI_BUTTON ? (
      <SocialAccountsUI.Link
        type={button.type}
        text={button.label}
        btnText={button.text}
        isDisabled={isDisabled}
        onClick={onClickHandler}
      />
    ) : (
      <div className={className} onClick={onClickHandler}>
        {button.inputType === SocialButtonInputTypes.ICON && (
          <div className="form-social__button-logo">
            <SocialIcon type={button.type} size={20} />
          </div>
        )}
        {button.label && <div className="form-social__button-text">{t(button.label)}</div>}
      </div>
    );
  },
  close: () => {
    authWindow?.close();
  },
};

export default FacebookButton;
