import React, { useRef } from 'react';
import clsx from 'clsx';
import { ScrollContainer } from 'components/shared';
import { arrowStyleTypes, handleScrollMoveWith } from 'components/shared/ScrollContainer';
import { PromotionTypes } from 'pages/promotions/types/promotions.types';

const scrollItemWidth = 335;
const itemsToScroll = 2;
const { moveWith } = handleScrollMoveWith({ itemsToScroll, itemWidth: scrollItemWidth, additionalGap: 12 });

interface GridProps {
  layout: PromotionTypes.GridLayoutType;
  children: React.ReactNode;
}

const Scroll: React.FC<GridProps> = ({ children, layout }) => {
  const gridContainerRef = useRef<HTMLDivElement>(null);
  const scrollWrapperStyles = clsx('prm-grid-scroll-wrapper');

  return (
    <div className={scrollWrapperStyles}>
      <div ref={gridContainerRef}>
        <ScrollContainer
          classNames={`prm-grid prm-grid--scrollable ${layout}`}
          regularDesktopCellWidth={scrollItemWidth}
          moveWith={moveWith}
          typeArrow={arrowStyleTypes.PlatformSmall}
        >
          {children}
        </ScrollContainer>
      </div>
    </div>
  );
};

export default Scroll;
