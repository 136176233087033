import { useLoadingState } from 'modules/casino/shared/hooks/useLoadingState';
import { useAppSelector } from 'store';
import { gamesBonusSelectors } from '../slice/gameLaunchBonus.slice';

type HookType = () => {
  challengesGamesFetchStatusInitial: boolean;
  challengesGamesFetchStatusLoading: boolean;
  challengesGamesFetchStatusSuccess: boolean;
  challengesGamesFetchStatusError: boolean;
};

export const useChallengesGamesFetchStatus: HookType = () => {
  const loadingState = useAppSelector(gamesBonusSelectors.challengesFetchStatus);
  const { hasInitial, isLoading, hasSuccess, hasError } = useLoadingState(loadingState);

  return {
    challengesGamesFetchStatusInitial: hasInitial,
    challengesGamesFetchStatusLoading: isLoading,
    challengesGamesFetchStatusSuccess: hasSuccess,
    challengesGamesFetchStatusError: hasError,
  };
};
