import { useEffect, useRef } from 'react';
import { useAuthentication } from 'hooks/common/useAuthentication';
import { useAppDispatch, useAppSelector } from 'store';
import {
  headerBonusIconThunks,
  resetHeaderBonusIcon,
  selectHeaderBonusIcon,
} from '../slice/mainNavbarButtonsBonus.slice';

type Props = {
  apiUrl?: string;
  contentTemplateKey?: string | null;
  isTombola?: boolean;
  isMyProfile?: boolean;
};

const INTERVAL_TIME_OUT = 60000;

export const useGetTombolaTickets = ({ apiUrl, contentTemplateKey, isTombola, isMyProfile }: Props) => {
  const dispatch = useAppDispatch();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const startInterval = useAppSelector(selectHeaderBonusIcon.isLoad(contentTemplateKey));
  const { isAuthenticated } = useAuthentication();

  useEffect(() => {
    if (apiUrl && isAuthenticated) {
      dispatch(headerBonusIconThunks.getTombolaTickets({ url: apiUrl, contentTemplateKey, isTombola }));
    }
    return () => {
      !isMyProfile && dispatch(resetHeaderBonusIcon());
    };
  }, [apiUrl, dispatch, isAuthenticated, isMyProfile]);

  useEffect(() => {
    let promise;
    if (apiUrl && isAuthenticated && startInterval) {
      intervalRef.current = setInterval(() => {
        promise = dispatch(headerBonusIconThunks.getTombolaTickets({ url: apiUrl, contentTemplateKey }));
      }, INTERVAL_TIME_OUT);
    }

    return () => {
      promise && promise.abort();
      intervalRef.current && clearInterval(intervalRef.current);
    };
  }, [dispatch, apiUrl, isAuthenticated, startInterval]);
};
