import styled, { css, keyframes } from 'styled-components';
import { clampedSize } from 'theme/scMixins';

const blowUp = keyframes`
    0% { transform: scale(1) }
    50% { transform: scale(1.2) }
    100% { transform: scale(1) }
`;

const zoom = css`
  animation: ${blowUp} 0.3s ease-in;
`;

export const SCBar = styled.div<{ $rowStart: number; $colStart: 1 | 3; $isWinner: boolean; $pseudoElBg: string }>`
  place-self: ${(p) => (p.$colStart === 1 ? 'center end' : 'center start')};
  grid-row-start: ${(p) => p.$rowStart};
  grid-column-start: ${(p) => p.$colStart};

  ${clampedSize('font-size', 12, 13)};
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  color: ${(p) => (p.$isWinner ? '#FCE34C' : 'white')};
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);

  transition: color 0.15s;
  user-select: none;

  ${(p) => p.$isWinner && zoom};

  position: relative;
  &::before,
  &::after {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 18px;
    height: 18px;
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.6));
  }
  ${(p) =>
    p.$colStart === 1
      ? `padding-right: 22px;
      &:before {
        display:none;
      }
      &:after { 
        right: -2px;
        background-image: url(${p.$pseudoElBg});
      }`
      : `padding-left: 22px;
      &:before { 
        left: -2px;
        background-image: url(${p.$pseudoElBg});
        transform: scaleX(-1);
      }
      &:after {
        display:none;
      }`}
`;

export const SCBarText = styled.div<{ $colStart: 1 | 3 }>`
  position: absolute;
  ${(p) => p.$colStart === 1 && `right: 20px;`};
  ${clampedSize('width', 80, 90)};
  transform: translateY(-50%);
  word-break: break-word;
`;
