import { useEffect } from 'react';
import { OpenLaunchTypes } from 'modules/casino/shared/features/gameLaunch/casinoLaunchConstants';
import {
  casinoLaunchSelectors,
  resetLaunchData,
  resetOpenGameData,
} from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import { AggregatorConfig, CasinoLaunchGameTypes } from 'modules/casino/shared/features/gameLaunch/casinoLaunchTypes';
import {
  getLaunchGameInterceptors,
  removeLaunchHistoryLength,
} from 'modules/casino/shared/features/gameLaunch/casinoLaunchUtils';
import Interceptors from 'modules/casino/shared/features/gameLaunch/components/launchInterceptors/ExecuteInterceptors';
import useInitialLaunchConfig from 'modules/casino/shared/features/gameLaunch/hooks/useInitialLaunchConfig';
import { saveScrollBeforeLaunch } from 'modules/casino/shared/utils/casinoUtils';
import { useStableNavigate } from 'modules/casino/shared/utils/common/StableNavigateContext';
import { useAppDispatch, useAppSelector } from 'store';

interface Props {
  launchRoute: string;
  openGameData: CasinoLaunchGameTypes.OpenGameData;
  aggregatorConfig: AggregatorConfig;
}

const OpenLaunchAgent = ({ launchRoute, openGameData }: Props) => {
  const { kycConfig } = useInitialLaunchConfig();
  const navigate = useStableNavigate();
  const isInGameLaunch = useAppSelector(casinoLaunchSelectors.isInGameLaunch);
  const dispatch = useAppDispatch();
  const { gameId, gamePlayType, scrollPositionData, providerAlias } = openGameData;

  const saveScrollPosition = () => {
    if (!scrollPositionData) return;
    // Prevent auto scrolling when returning from game
    history.scrollRestoration = 'manual';
    const { collectionId, gridPage, gamesRenderedCount, gamesCount, uniqueId } = scrollPositionData;
    saveScrollBeforeLaunch({
      uniqueId,
      gameId,
      collectionId,
      gridPage,
      gamesRenderedCount,
      exitReloaded: false,
      gamesCount,
    });
  };
  const launchGame = () => {
    removeLaunchHistoryLength();
    saveScrollPosition();
    navigate(launchRoute, { replace: isInGameLaunch, state: isInGameLaunch ? Date.now() : null });
    if (isInGameLaunch) {
      dispatch(resetLaunchData());
    }
  };

  useEffect(() => {
    const interceptionsObj = getLaunchGameInterceptors(
      gamePlayType,
      launchGame,
      OpenLaunchTypes.OPENLAUNCHER,
      gameId,
      kycConfig,
      undefined,
      providerAlias,
    );
    dispatch(Interceptors.executeInterceptors(interceptionsObj));
    dispatch(resetOpenGameData());
  }, []);

  return null;
};

export default OpenLaunchAgent;
