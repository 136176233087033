import React, { useEffect, useLayoutEffect, useState, useCallback } from 'react';
import { Dropdown, DropdownItem } from 'components/shared/dropdown';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import userStorage from 'pages/auth/login/UserStorage';
import { selectSettings, SettingsThunks } from 'pages/myAccount/tabs/settings/slice/settings.slice';
import { SettingsTypes } from 'pages/myAccount/tabs/settings/types/settings.types';
import { parseTimezones as parseTimezones } from 'pages/myAccount/tabs/settings/utils/settings.utils';
import { loaderDataActions } from 'shared/common/features/loaderData/slice/loaderData.slice';
import { setUpdated } from 'shared/common/features/localization/slice/localization.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { formatDateUTC } from 'utils/dateUtils';

type Timezones = {
  isDrawer?: boolean;
};

const useSetDate = () => {
  const [date, setDate] = useState(Date.now());
  useEffect(() => {
    const interval = setInterval(() => {
      setDate(Date.now());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return date;
};

const getTimeZoneClockTitle = ({
  timezone,
  date,
}: {
  timezone: SettingsTypes.ParsedTimeZone;
  date: number;
  activeTimezone: SettingsTypes.ParsedTimeZone;
}) => {
  const time = formatDateUTC('HH:mm:ss', date + timezone.offsetMinutes * 60_000);
  if (timezone) {
    return (
      <>
        <span>{time}</span>&nbsp;{timezone.label}
      </>
    );
  } else return '';
};

export const TimeZones: React.FC<Timezones> = ({ isDrawer }) => {
  const [parsedTimezones, setParsedTimezone] = useState<SettingsTypes.ParsedTimeZone[]>([]);
  const [activeTimezone, setActiveTimezone] = useState<SettingsTypes.ParsedTimeZone>({
    offsetMinutes: 0,
    label: '',
    value: '',
  });
  const [isDropdownShown, setIsDropdownShown] = useState<boolean>(false);
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const timezones = useAppSelector(selectSettings.timeZones);
  const dispatch = useAppDispatch();
  const date = useSetDate();

  useLayoutEffect(() => {
    if (!timezones || timezones.length === 0) {
      dispatch(SettingsThunks.fetchTimeZones());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!timezones) {
      return;
    }

    const _parsedTimezones = parseTimezones(timezones);
    setParsedTimezone(_parsedTimezones);
    const ianaTimezone = userStorage.getUserIanaTimeZoneCode();
    const offetMinutes = userStorage.getUserTimeZone();
    const timezone = _parsedTimezones?.filter(
      (item) => item.value === ianaTimezone || String(item.offsetMinutes) === String(offetMinutes),
    )?.[0];
    timezone && setActiveTimezone(timezone);
  }, [timezones]);

  const handleTimeZoneChange = useCallback(
    async (timezoneCode: string) => {
      if (!isEmpty(parsedTimezones) && activeTimezone.value !== timezoneCode) {
        const timezone = parsedTimezones.filter((item) => item.value === timezoneCode)[0];
        if (isAuthenticated)
          await dispatch(
            SettingsThunks.updateUserTimeZone({ timezoneCode: timezoneCode, offsetMinutes: timezone.offsetMinutes }),
          );

        setActiveTimezone(timezone);
        userStorage.setUserTimeZone(timezone.offsetMinutes);
        userStorage.setUserIanaTimeZoneCode(timezoneCode);
        dispatch(setUpdated(false));
        dispatch(loaderDataActions.resetLoaders());
      }
    },
    [parsedTimezones, activeTimezone.value, isAuthenticated, dispatch],
  );

  const onDropdownClick = useCallback((isShown: boolean) => {
    setIsDropdownShown(isShown);
  }, []);

  return (
    <>
      {parsedTimezones !== undefined && parsedTimezones.length !== 0 && activeTimezone !== undefined ? (
        <Dropdown
          buttonDefaultClassName={isDrawer ? true : false}
          buttonClassName={isDrawer ? '' : 'p-0 text-tabular footer-menu__link'}
          titleClassName={isDrawer ? '' : 'mr-1'}
          title={getTimeZoneClockTitle({ timezone: activeTimezone, date, activeTimezone })}
          onDropdownClick={onDropdownClick}
          className={isDrawer ? '' : 'footer-system__item'}
        >
          {(isDropdownShown ? parsedTimezones : [activeTimezone]).map((zone) => (
            <DropdownItem
              handleChangeDropdown={handleTimeZoneChange}
              isActive={zone.value === activeTimezone.value}
              key={zone.value}
              title={getTimeZoneClockTitle({ timezone: zone, date, activeTimezone })}
              value={zone.value}
              dataQid={`userTz-${zone.value}`}
            />
          ))}
        </Dropdown>
      ) : (
        <Dropdown dropdownRight title={getTimeZoneClockTitle({ timezone: activeTimezone, date, activeTimezone })}>
          {(isDropdownShown ? parsedTimezones : [activeTimezone]).map((zone) => (
            <DropdownItem
              handleChangeDropdown={handleTimeZoneChange}
              isActive={zone.value === activeTimezone.value}
              key={zone.value}
              title={getTimeZoneClockTitle({ timezone: zone, date, activeTimezone })}
              value={zone.value}
              dataQid={`userTz-${zone.value}`}
            />
          ))}
        </Dropdown>
      )}
    </>
  );
};
