import clsx from 'clsx';
export const shouldUpdate = (jackpotIds, jackpotObjIds?) => {
  if (jackpotObjIds) {
    return !jackpotObjIds.map((j) => jackpotIds.includes(j)).every((res) => res === true);
  }

  return false;
};

export const getStyles = ({ jackpotDetails, hasBackground, isToggled }) =>
  clsx([
    'category-single',
    jackpotDetails && jackpotDetails?.template && 'category_bgr_' + jackpotDetails?.template,
    hasBackground && 'category_bgr_default',
    hasBackground && isToggled && 'category_bgr_default--providers-bar-on',
  ]);

export const extendNestedObj = (current, updates) => {
  Object.keys(updates).find((k) => {
    if (!Object.prototype.hasOwnProperty.call(current, k) || typeof updates[k] !== 'object') {
      current[k] = updates[k];
    } else {
      extendNestedObj(current[k], updates[k]);
    }
  });

  return current;
};
