import { useMemo } from 'react';
import { useLocation, useMatch } from 'react-router-dom';

const useActiveVerticalUrlParam = () => {
  const location = useLocation();
  const mainVerticalUrl = `/${location.pathname.split('/')?.[1]}`;
  const isInplayUrl = useMatch('/in-play/*');
  const verticalUrl = useMemo(() => (isInplayUrl ? '/in-play' : mainVerticalUrl), [mainVerticalUrl, isInplayUrl]);

  return verticalUrl;
};

export default useActiveVerticalUrlParam;
