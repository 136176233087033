import React from 'react';
import { Flex } from 'components/shared/layout';
import { GamificationUser } from 'shared/common/features/integrations/gamification/types/gamification.types';
import { useLevelProgress } from '../../hooks/useLevelProgress';

type Props = {
  gamificationProfile?: GamificationUser.Profile | null;
};

export const LevelProgressNew: React.FC<Props> = ({ gamificationProfile }) => {
  const currentProgress = useLevelProgress({
    currentLevelPoints: gamificationProfile?.playerLevel?.currentLevelPoints,
    nextLevelPoints: gamificationProfile?.playerLevel?.nextLevelPoints,
    points: gamificationProfile?.playerCurrencies?.points,
  });
  const levelColor = gamificationProfile?.playerLevel?.color;
  const styleBackground = { '--level-color': levelColor } as React.CSSProperties;
  return (
    <Flex className="mpd-gam__progress w-100" style={levelColor ? styleBackground : {}}>
      <Flex.Center className="mpd-gam__name">{gamificationProfile?.playerLevel.name}</Flex.Center>
      <Flex.Center className="mpd-gam__wrapper w-100">
        {gamificationProfile?.playerCurrencies?.points}
        <div className="w-100 mpd-gam__level">
          <div className="mpd-gam__level-current" style={{ width: currentProgress + '%' }}></div>
        </div>
        {gamificationProfile?.playerLevel.nextLevelPoints}
      </Flex.Center>
    </Flex>
  );
};
