import { memo } from 'react';
import isEqual from 'lodash/isEqual';
import BannerWrapper from './BannerWrapper';
import Button from './Button';
import Text from './Text';
import Wrapper from './Wrapper';

export const LastWinnersWidgetsUI = {
  Wrapper: Wrapper,
  BannerWrapper: BannerWrapper,
  Text: memo(Text),
  Button: memo(Button, isEqual),
};
