import React from 'react';
import { useDynamicSliderSettings } from 'modules/casino/modules/container/widget/widgetTypes/lastWinnersWidgets/hooks/useDynamicSliderSettings';
import { ZASlider } from 'modules/casino/shared/features/ZASlider';
import LastWinnersWidgetGameItem from './LastWinnersWidgetsGameComponents/LastWinnersWidgetGameItem';
import { CasinoWidgetsTypes, Destinations } from '../../../types/casinoWidgetsTypes';

interface DynamicSliderProps {
  templateType: CasinoWidgetsTypes.WidgetTypes;
  lastWinners: CasinoWidgetsTypes.LastWinner[];
  destination: Destinations;
  cdnPathLayout: string;
  direction: string;
  sortByWinTime: boolean;
  sliderLayout?: string;
  animationInterval?: number;
}

const DynamicSlider: React.FC<DynamicSliderProps> = ({
  templateType,
  lastWinners,
  destination,
  cdnPathLayout,
  direction,
  sortByWinTime,
  animationInterval,
}) => {
  const { settings } = useDynamicSliderSettings({ templateType, destination, direction, animationInterval });

  const sortILastWinnersByWinTime = (items) => {
    const winners = [...items];
    const sortedItems = winners.sort(function (x, y) {
      return x.winTime - y.winTime;
    });
    return sortedItems.reverse();
  };

  return (
    <div className="cw__games-wrapper">
      {lastWinners && (
        <ZASlider settings={settings} items={sortByWinTime ? sortILastWinnersByWinTime(lastWinners) : lastWinners}>
          {(item) => {
            return <LastWinnersWidgetGameItem lastWinner={item} type={templateType} cdnPathLayout={cdnPathLayout} />;
          }}
        </ZASlider>
      )}
    </div>
  );
};
export default DynamicSlider;
