import React from 'react';
import { useTranslation } from 'react-i18next';
import CountdownTimer from 'modules/casino/shared/components/common/CountdownTimer';
import CurrencySwapper from 'modules/casino/shared/components/common/CurrencySwapper';
import { useCurrencyConfig } from 'modules/casino/shared/hooks/useCurrencyConfig';
import { formatSumAmount } from 'modules/casino/shared/utils/helpersCasino';
import { useAppSelector } from 'store';
import { SkywindJackpotsTranslations, SKYWIND_HOURLY_COUNTDOWN_FORMAT } from '../../jackpotConstants';
import { jackpotSelectors } from '../../jackpotSlice';
import { JackpotTypes } from '../../jackpotTypes';

interface Props {
  levelDetails: JackpotTypes.CasinoLevel;
  jackpotId: string;
}

const SkywindHourlyJackpot: React.FC<Props> = ({ levelDetails, jackpotId }) => {
  const { t } = useTranslation();
  const currency = useAppSelector((state) => jackpotSelectors.selectJackpotCurrencySign(state, jackpotId));

  const endTime = levelDetails?.endTime || '';
  const [datePart, timePart] = endTime.split(' ');
  const [day, month, year] = datePart.split('/');
  const convertedEndTime = `${month}/${day}/${year} ${timePart}`;
  const endTimeObj = new Date(convertedEndTime);
  const currentTime = new Date();
  const timestamp = endTimeObj.getTime();

  const remainingTimeText = t(SkywindJackpotsTranslations.remainingTime) || '';
  const jackpotWonText = t(SkywindJackpotsTranslations.jackpotWon) || '';
  const { formatToComma, currencySymbolFirst, spaceBetween } = useCurrencyConfig();

  return levelDetails?.winsDetails?.lastWinAmount ? (
    <div className="jackpot__extra">
      <span className="jackpot__extra--condition">
        <CurrencySwapper
          sumComponent={<>{formatSumAmount({ sum: levelDetails.winsDetails.lastWinAmount, formatToComma })}</>}
          currencyComponent={<span>{currency}</span>}
          currencySymbolFirst={currencySymbolFirst}
          spaceBetween={spaceBetween}
        />
      </span>
      <span className="jackpot__extra--info"> {jackpotWonText}</span>
    </div>
  ) : endTimeObj > currentTime ? (
    <div className="jackpot__extra">
      <span className="jackpot__extra--info">{remainingTimeText}</span>
      <CountdownTimer
        classNames="jackpot__extra--condition"
        endDateMS={timestamp ?? 0}
        format={SKYWIND_HOURLY_COUNTDOWN_FORMAT}
      />
    </div>
  ) : null;
};

export default SkywindHourlyJackpot;
