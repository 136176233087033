import { useEffect } from 'react';
import { selectFooter, setAppFooter } from 'shared/common/features/appFooter/slice/appFooter.slice';
import { useAppDispatch, useAppSelector } from 'store';

const GridFooterHandler = () => {
  const dispatch = useAppDispatch();
  const isAppFooterVisible = useAppSelector(selectFooter.isVisible);

  useEffect(() => {
    if (!isAppFooterVisible) {
      dispatch(setAppFooter(true));

      return () => {
        dispatch(setAppFooter(true));
      };
    }
  }, [dispatch, isAppFooterVisible]);

  return null;
};

export default GridFooterHandler;
