import { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { Button } from 'components/shared/buttons';
import Icon from 'components/shared/Icon';

type Props = {
  className?: string;
  icon?: string;
  iconClass?: string;
  text: string;
  onClick: MouseEventHandler;
};
const ViewLessBtn: React.FC<Props> = ({ className, onClick, text, icon, iconClass }) => {
  const btnClass = clsx('footer-section-expandable-btn d-flex-col-ac', className);
  return (
    <Button classNames={btnClass} onClick={onClick}>
      <Icon icon={icon} className={iconClass} />
      <span>{text}</span>
    </Button>
  );
};

export default ViewLessBtn;
