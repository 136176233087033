import React from 'react';
import { ButtonBack } from 'components/shared/buttons';
import { HelpDropdownButton } from 'pages/auth/registration/components';

interface PanelHeaderPops {
  onBackClick: React.MouseEventHandler;
  text: string;
  showHelpMenu?: boolean;
  hideBackButton?: boolean;
}

const PanelHeader: React.FC<PanelHeaderPops> = ({ onBackClick, text, hideBackButton, showHelpMenu = true }) => {
  return (
    <div className="auth-panel__header">
      {hideBackButton ? <span></span> : <ButtonBack onClickHandler={onBackClick} />}
      <div className="place-self-center px-2 text-center">{text}</div>
      {showHelpMenu && <HelpDropdownButton />}
    </div>
  );
};

export default PanelHeader;
