import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { checkTicketStatus } from 'modules/retail/modules/cashier/utils';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { isEmpty } from 'utils/common/helpersCommon';
import { SSBT } from 'utils/common/translationUtils/translationStrings';
import { CheckTicket } from '../../../types/ssbt.types';
import MaxProfit from '../../MaxProfit';
import { TerminalModalUI } from '../../ssbtModalUI';
// import TerminalTicket from '../../TerminalTicket';

interface CashierTicketFooterProps {
  ticketInfo: CheckTicket | null;
  currency: string;
  footerRef?: any; // Maybe must be add proper type
  bet?: any;
}

const CashierTicketFooter: React.FC<CashierTicketFooterProps> = ({ ticketInfo, currency, footerRef }) => {
  const { t } = useTranslation();

  const isCurrencyBeforeAmount = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.CURRENCY_BEFORE_AMOUNT,
  });

  return (
    <egtd-cashier-ticket-footer ref={footerRef}>
      {ticketInfo?.status !== 'LOSS' && (
        <MaxProfit
          text={t(SSBT.TerminalModal.totalWinTerminal)}
          ticketInfo={ticketInfo}
          currency={currency}
          isCurrencyBeforeAmount={isCurrencyBeforeAmount}
        />
      )}

      {ticketInfo?.status && (
        <TerminalModalUI.StatusCentered
          key={ticketInfo?.code}
          icon="info-solid"
          text={checkTicketStatus(
            ticketInfo?.status,
            t,
            ticketInfo?.totalAmount,
            currency,
            ticketInfo?.isClaimForm,
            ticketInfo?.totalAmountAfterTax,
            isCurrencyBeforeAmount,
          )}
          isWin={!isEmpty(ticketInfo) && (ticketInfo?.status === 'WON' || ticketInfo?.status === 'VOID')}
          isProcessing={!isEmpty(ticketInfo) && (ticketInfo?.status === 'ACCEPTED' || ticketInfo?.status === 'PAID')}
          isLoss={!isEmpty(ticketInfo) && (ticketInfo?.status === 'LOSS' || ticketInfo?.status === 'CANCELLED')}
          isNotification={!isEmpty(ticketInfo)}
        />
      )}
    </egtd-cashier-ticket-footer>
  );
};

export default CashierTicketFooter;
