import { MENU_ITEM_WITH_BADGE, MenuItemType } from '../constants/casinoNavigationConstants';
import { CasinoNavigationTypes } from '../types/casinoNavigationTypes';

export const getCategoryPath = (pathName: string, categoryAlias: string, menuType: string): string => {
  if (menuType === MenuItemType.LOBBY || categoryAlias === 'lobby') {
    return `/${pathName.split('/')[1]}`;
  }
  return `/${pathName.split('/')[1]}/${categoryAlias}`;
};

export const getCasinoNavBadge = ({
  menuType,
  counter,
}: {
  menuType: string;
  counter: string | number;
}): string | number => (MENU_ITEM_WITH_BADGE.includes(menuType) && counter ? counter : '');

export const normalizeData = (data: CasinoNavigationTypes.MenuItem[]) => {
  const handleItemByType = {
    [MenuItemType.LOBBY]: (item: CasinoNavigationTypes.MenuItem) => {
      item.newTag = false;
      item.navToGamesCount = undefined;
      return item;
    },
    [MenuItemType.FAVOURITES]: (item: CasinoNavigationTypes.MenuItem) => {
      item.newTag = false;
      item.navToGamesCount = undefined;
      return item;
    },
    [MenuItemType.SEARCH]: (item: CasinoNavigationTypes.MenuItem) => {
      item.newTag = false;
      item.navToGamesCount = undefined;
      item.alias = 'search';
      return item;
    },
    [MenuItemType.LAST_PLAYED]: (item: CasinoNavigationTypes.MenuItem) => {
      item.newTag = false;
      return item;
    },
    [MenuItemType.LINK]: (item: CasinoNavigationTypes.MenuItem) => {
      item.newTag = false;
      return item;
    },
    [MenuItemType.CATEGORY]: (item: CasinoNavigationTypes.MenuItem) => {
      return item;
    },
  };
  return data.map((item) => {
    item.alias = item.alias.toLowerCase();
    return handleItemByType[item.menuType](item);
  });
};

export const getIconRequestFinishedCollection = ({
  menuItems,
  iconSrc,
}: {
  menuItems: CasinoNavigationTypes.MenuItem[];
  iconSrc: string;
}): CasinoNavigationTypes.MenuItem[] =>
  menuItems?.map((item) => {
    if (item?.iconLocation === iconSrc) {
      return {
        ...item,
        isIconRequestFinished: true,
      };
    }

    return item;
  });
