import { arrowStyleTypes } from 'modules/casino/shared/features/casinoScroll/casinoScrollTypes';
import { PromoCellLayout_1 } from './components/PromoWidgetCell/layouts/layout_1';
import { PromoCellLayout_2 } from './components/PromoWidgetCell/layouts/layout_2';
import { PromoCellLayout_3 } from './components/PromoWidgetCell/layouts/layout_3';
import { CategoryLayoutTypes } from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { CasinoWidgetTemplates } from '../../constants/casinoWidgetsConstants';

export const promoWidgetWrapperClassesByLayout = {
  [CategoryLayoutTypes.LOBBY]: 'lobbyCategory',
  [CategoryLayoutTypes.LANDING_CATEGORY]: 'landingCategory',
};

export const promoWidgetScrollWrapperParamsMap = {
  [CasinoWidgetTemplates.SECTION]: {
    typeArrow: arrowStyleTypes.CasinoGrid,
    href: '#chevron',
    viewBox: '0 0 24 24',
  },
};

export enum PromoWidgetCellLayout {
  LAYOUT_1 = 'layout_1',
  LAYOUT_2 = 'layout_2',
  LAYOUT_3 = 'layout_3',
}

export const promoWidgetCellByLayout = {
  [PromoWidgetCellLayout.LAYOUT_1]: PromoCellLayout_1,
  [PromoWidgetCellLayout.LAYOUT_2]: PromoCellLayout_2,
  [PromoWidgetCellLayout.LAYOUT_3]: PromoCellLayout_3,
};
