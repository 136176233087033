import { useEffect } from 'react';
import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import cell03ComponentLoader from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell03/Cell03ComponentLoader';
import cell03StyleLoader from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell03/Cell03StyleLoader';
import StyledWrapper from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell03/styles/cell03Wrapper.styled';
import { LayoutProps } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/Layout_Wrapper';
import { ComponentNames } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/layoutWrapperConstants';
import { useGetLayoutConfig } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/hooks/useGetLayoutConfig';
import { getGameCountImageSrc } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/utils/getGameCountImageSrc';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { SharedComponents } from '../../../shared';

const Cell_3_Wrapper: React.FC<LayoutProps> = ({
  gameUniqueId,
  categoryId,
  categoryLayout,
  gameCellRef,
  finishedLoading,
  hasRestrictions,
  handleButtonClick,
  skipLazyLoad,
  number,
}) => {
  const game = useGameProps({ gameUniqueId, categoryId });
  const { hasNumbers, numbersVariant, layoutConfig } = useGetCategoryParams(categoryId, categoryLayout);
  const { showInfoPage } = game;
  const { isData } = useGetLayoutConfig(game);
  const customComponentType = isData ? 'data' : 'default';
  const GameCellWrapper = cell03ComponentLoader(ComponentNames.GameCellWrapper, customComponentType);
  useEffect(() => {
    if (hasNumbers && number) {
      if (gameCellRef.current) {
        gameCellRef.current.style.zIndex = `${10000 + number}`;
        number > 9 && gameCellRef.current.classList.add('casino-grid-item--ml');
      }
    }
  }, []);

  return (
    <StyledWrapper id={gameUniqueId} className={'casino-grid-item--wrapper layout_3_wrapper'} $isData={isData}>
      {/* eslint-disable-next-line react/jsx-no-undef */}
      <SharedComponents.GameCellMedia
        gameUniqueId={gameUniqueId}
        categoryId={categoryId}
        styledType={customComponentType}
        layoutStyleLoader={cell03StyleLoader}
        finishedLoading={finishedLoading}
        skipLazyLoad={skipLazyLoad}
        layoutGrid={layoutConfig.layoutGrid}
      />
      <GameCellWrapper gameUniqueId={gameUniqueId} categoryId={categoryId} layoutGrid={layoutConfig.layoutGrid} />

      <SharedComponents.GameCellButtonsWrapper
        gameUniqueId={gameUniqueId}
        categoryId={categoryId}
        gameCellRef={gameCellRef}
        handleClick={handleButtonClick}
        hasRestrictions={hasRestrictions}
        showInfoPage={showInfoPage}
      />

      {hasNumbers && number && (
        <img src={getGameCountImageSrc(number, numbersVariant)} className={'casino-grid-item--counter'} />
      )}
      <SharedComponents.GameCellFavouritesButton
        gameUniqueId={gameUniqueId}
        gameCellRef={gameCellRef}
        categoryId={categoryId}
      />
    </StyledWrapper>
  );
};

export default Cell_3_Wrapper;
