import React from 'react';
import { ValidateResult, UseFormReturn, ValidationValueMessage } from 'react-hook-form';
import { convertDateToUTC } from 'pages/myAccount/utils/myAccount.utils';
import axiosInstance from 'utils/common/axios-instance';
import { getError, isValidDate, dateToUTC, isEmpty } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { MIN_YEAR, LAWFUL_AGE } from './components/datepicker/DatePickerData';
import { ValidationRules, InputTypes, FieldValues, DependantReset, FieldEffects, DependantUpdate } from './formTypes';

/**
 * Replace text by given placeholder
 * @param {string} text Text to replace
 * @param {string} textToReplace string value to replace the placeholder
 * @param {string} placeholder string format of placeholder (example: <<placeholder>>)
 */
export const replaceTranslationPlaceholder = (text, textToReplace, placeholder) => {
  return (text = text.replace(new RegExp(placeholder, 'g'), textToReplace));
};

export const transformTranslationPlaceholder = ({ text, replacementText, component, placeholder }) => {
  const parts = text.split(placeholder);

  return parts.reduce((acc, part, index) => {
    acc.push(part);

    if (index < parts.length - 1) {
      if (replacementText) acc.push(replacementText);
      if (component) acc.push(React.cloneElement(component, { key: `component-${index}` }));
    }

    return acc;
  }, []);
};

const isDateInvalid = (getValues: UseFormReturn['getValues']) => (): string | boolean => {
  const [day, month, year] = getValues(['day', 'month', 'year']);
  return !isEmpty(day) && !isEmpty(month) && !isEmpty(year)
    ? isValidDate(day, month - 1, year) || 'Registration.FirstStep.invalidBirthdayPickerText'
    : true;
};

const patternFieldsValue = {};

const isPatternMatch = (patterns: Record<string, ValidationValueMessage<RegExp>>) => (): string | boolean => {
  for (const key of Object.keys(patterns)) {
    const fieldValue: string = patternFieldsValue[key];
    const { value: regEx, message } = patterns[key];
    if (!fieldValue) {
      return true;
    } else if (!new RegExp(regEx).test(fieldValue)) {
      return message;
    }
  }
  return true;
};

type DependentFieldUpdate<T> = {
  dependantUpdate: DependantUpdate;
  setValue: UseFormReturn['setValue'];
  trigger: UseFormReturn['trigger'];
  value: T;
};

// Handle update of 18+ checkbox
// TO DO add more accurate calc for the year days
const MS_PER_YEAR = 365.25 * 24 * 60 * 60 * 1000;

const isOver18 = (date: number, targetValue = LAWFUL_AGE) => {
  const birthDateMs = new Date(date).setHours(23, 59, 59, 0);
  const nowUtcMs = convertDateToUTC(new Date()).getTime();
  const eighteenYearsAgoMs = nowUtcMs - targetValue * MS_PER_YEAR;
  return birthDateMs <= eighteenYearsAgoMs;
};

const dependantUpdateCheckMap = {
  over18: isOver18,
};

const handleDependantUpdate = ({ dependantUpdate, setValue, value, trigger }: DependentFieldUpdate<number>) => {
  Object.keys(dependantUpdate)?.forEach((key) => {
    if (dependantUpdateCheckMap[key]) {
      if (dependantUpdateCheckMap[key](value, dependantUpdate[key].targetValue)) {
        setValue(key, dependantUpdate[key].setValue);
      } else {
        // else reset field value
        setValue(key, '');
      }
      trigger(key);
    }
  });
};
// End handle update of 18+ checkbox

// Hide field upon other field value changes regarding registration configuration
export const setHiddenEffect = (watchedValues: Record<string, string>, field: FieldValues) =>
  Object.keys(watchedValues).some(
    (key) =>
      (field?.effect?.hiddenOnValue?.[key] && !watchedValues[key]) ||
      field?.effect?.hiddenOnValue?.[key]?.includes(watchedValues[key]),
  );

export const setDisabledEffect = (watchedValues: Record<string, string>, field: FieldValues) =>
  Object.keys(watchedValues).some((key) => field?.effect?.disabledOnField?.[key] && !watchedValues[key]);

const resetFieldValues = ({
  dependantReset,
  setValue,
  value,
}: {
  dependantReset?: DependantReset;
  setValue: UseFormReturn['setValue'];
  value?: string | number;
}) => {
  if (value && dependantReset?.values && dependantReset?.values?.includes(`${value}`)) {
    dependantReset?.fields.forEach((field: string) => setValue(field, ''));
  }
};

const replaceHandlers = {
  br: (_, p1, p2, p3, p4) => [p1, p2, p3].filter(Boolean).join('.') + (p4 ? '-' + p4 : ''),
};

const fieldsOldValidationValues = {};
const lastValidationResult = {};

type HandleDependentFieldType = {
  fieldsOldValues: Record<string, string>;
  dependantField?: string;
  trigger: UseFormReturn['trigger'];
  getValues: UseFormReturn['getValues'];
};

const handleDependantField = ({ fieldsOldValues, dependantField, trigger, getValues }: HandleDependentFieldType) => {
  const dependantValue = dependantField && getValues(dependantField);
  if (dependantField && (dependantValue === undefined || !isEmpty(dependantValue))) {
    fieldsOldValues[dependantField] && delete fieldsOldValues[dependantField];
    trigger(dependantField);
  }
};

export const getCustomOnChange = ({
  name,
  type,
  effect,
  setValue,
  getValues,
  trigger,
  onChangeCallback,
}: {
  name: string;
  type?: InputTypes;
  effect?: FieldEffects;
  setValue: UseFormReturn['setValue'];
  getValues: UseFormReturn['getValues'];
  trigger: UseFormReturn['trigger'];
  onChangeCallback?: () => void;
}):
  | ((v: string | number) => void)
  | ((
      value: string,
      country: { name: string; dialCode: string; countryCode: string },
      _e: React.ChangeEvent,
      __formattedValue: string,
    ) => void)
  | ((part: string) => (v: string | number) => void)
  | undefined => {
  const { dependantField, dependantReset, replacePattern, replace = {}, dependantUpdate } = effect || {};
  const { pattern = replacePattern, text, callback, length } = replace;
  const replaceHandler = callback && replaceHandlers[callback] ? replaceHandlers[callback] : (text ?? '');

  if (dependantField && (type === InputTypes.SELECT || type === InputTypes.RADIO)) {
    return (value) => {
      resetFieldValues({ dependantReset, setValue, value });
      setValue(name, value, { shouldDirty: true });
      handleDependantField({ fieldsOldValues: fieldsOldValidationValues, dependantField, trigger, getValues });
      onChangeCallback && onChangeCallback();
    };
  }

  if (dependantField && type !== InputTypes.DATETIME) {
    return (ev) => {
      let value = ev?.target ? ev?.target?.value : ev;
      if (callback) value = value.replace(/\D/g, '').slice(0, length || 11);
      resetFieldValues({ dependantReset, setValue, value });
      setValue(name, pattern ? value.replace(new RegExp(pattern, 'g'), replaceHandler) : value);
      handleDependantField({ fieldsOldValues: fieldsOldValidationValues, dependantField, trigger, getValues });
      onChangeCallback && onChangeCallback();
    };
  }

  if (type === InputTypes.PHONENUMBER) {
    return (value, country) => {
      resetFieldValues({ dependantReset, setValue, value });
      if (country?.dialCode && effect?.patternsFields) {
        effect.patternsFields.forEach((f) => (patternFieldsValue[f] = value?.replace(country?.dialCode, '')));
      }
      setValue(name, `+${value}`, { shouldDirty: true, shouldValidate: true });
      handleDependantField({ fieldsOldValues: fieldsOldValidationValues, dependantField, trigger, getValues });
      onChangeCallback && onChangeCallback();
    };
  }

  if (type === InputTypes.DATETIME) {
    return (datePart: string) =>
      (input): void => {
        const value: string | number = input?.target ? input?.target?.value : input;
        resetFieldValues({ dependantReset, setValue, value });
        setValue(datePart, value);
        const [day, month, year] = getValues(['day', 'month', 'year']);
        const birthDate = dateToUTC(+year, +month - 1, +day);
        setValue(name, birthDate, { shouldValidate: true, shouldDirty: true });
        // Validate b-day on over18 change
        if (!isEmpty(dependantUpdate) && day && month && year) {
          const minYearEpoch = dateToUTC(MIN_YEAR, +month - 1, +day);
          if (birthDate >= minYearEpoch) {
            handleDependantUpdate({ dependantUpdate, value: birthDate, setValue, trigger });
          }
        }
        handleDependantField({ fieldsOldValues: fieldsOldValidationValues, dependantField, trigger, getValues });
        onChangeCallback && onChangeCallback();
      };
  }

  if (type === InputTypes.INPUT && pattern) {
    return (ev) => {
      let value = ev?.target ? ev?.target?.value : ev;
      if (callback) value = value.replace(/\D/g, '').slice(0, length || 11);
      pattern ? setValue(name, value.replace(new RegExp(pattern, 'g'), replaceHandler)) : setValue(name, value);
      handleDependantField({ fieldsOldValues: fieldsOldValidationValues, dependantField, trigger, getValues });
      onChangeCallback && onChangeCallback();
    };
  }
  if (type === InputTypes.OTP) {
    return (ev) => {
      let value = ev?.target ? ev?.target?.value : ev;
      if (callback) value = value.replace(/\D/g, '').slice(0, length || 11);
      pattern
        ? setValue(name, value.replace(new RegExp(pattern, 'g'), replaceHandler), {
            shouldValidate: true,
          })
        : setValue(name, value, { shouldValidate: true });
      handleDependantField({ fieldsOldValues: fieldsOldValidationValues, dependantField, trigger, getValues });
      onChangeCallback && onChangeCallback();
    };
  }
  return undefined;
};

export const FieldsTransformMap: Record<string, string> = {
  countryId: 'countryName',
  genderId: 'gender',
  currencyId: 'currencyName',
  birthDate: 'birthday',
  birthdate: 'birthday',
};

export const asyncValidate = async ({
  name,
  validationRules,
  value,
  data,
  dependancy,
  getValues,
  netAppHeader,
}: {
  name: string;
  validationRules: ValidationRules;
  value;
  data?: FieldValues;
  dependancy?: string[];
  getValues: UseFormReturn['getValues'];
  netAppHeader: string;
}): Promise<ValidateResult> => {
  if (!value) return;
  const newData = { ...data };
  const { apiUrl, fields, message } = validationRules.validationAsync || {};
  const params = { [name]: name === 'phoneNumber' ? encodeURI(value) : value };
  if (dependancy) {
    dependancy.forEach((d) => {
      const propValue = getValues(d);
      propValue && (newData[d] = propValue);
    });
  }

  !isEmpty(newData) &&
    fields?.forEach(
      (key) => !params[key] && newData[FieldsTransformMap[key]] && (params[key] = newData[FieldsTransformMap[key]]),
    );

  const url = `${config.API_URL}/${apiUrl}`;
  const headers = { ...(netAppHeader ? { 'x-platform-hash-netapp': netAppHeader } : {}) };

  try {
    const response = await axiosInstance.get(url, { params, headers });
    if (response?.data === 'true') {
      return message;
    }
  } catch (err) {
    return getError.responseDataMessage(err) as string;
  }
};

const validationPromise = (validationResult: Record<string, string>, name: string) => {
  return new Promise((resolve: (result: string) => void) => {
    return resolve(validationResult[name]);
  });
};

export const modifyValidation = ({
  name,
  type,
  validationRules,
  compareWith,
  getValues,
  data,
  dependancy,
  netAppHeader,
}: {
  name: string;
  type?: InputTypes;
  validationRules?: ValidationRules;
  compareWith?: { value: string; message: string };
  getValues: UseFormReturn['getValues'];
  data?: FieldValues;
  dependancy?: string[];
  netAppHeader: string;
}): ValidationRules | undefined => {
  let modifiedValidation = validationRules;
  if (validationRules?.pattern) {
    modifiedValidation = {
      ...modifiedValidation,
      pattern: { ...validationRules.pattern, value: new RegExp(validationRules.pattern?.value) },
    };
  }
  if (validationRules?.validationAsync && type !== InputTypes.SELECT) {
    modifiedValidation = {
      ...modifiedValidation,
      validate: async (value) => {
        if (value && fieldsOldValidationValues[name] === value) {
          const result = await validationPromise(lastValidationResult, name);
          return result;
        }

        const dataToValidate = { name, validationRules, value, data, dependancy, getValues, netAppHeader };
        const result = await asyncValidate(dataToValidate);
        lastValidationResult[name] = result;
        fieldsOldValidationValues[name] = value;
        return result;
      },
    };
  }
  if (compareWith?.value) {
    return {
      ...modifiedValidation,
      validate: (value) => {
        const compareFieldValue = getValues(compareWith.value);
        return value === compareFieldValue || compareWith?.message;
      },
    };
  }
  if (type === InputTypes.DATETIME) {
    return {
      ...modifiedValidation,
      validate: isDateInvalid(getValues),
    };
  }
  if (validationRules?.patterns) {
    return {
      ...modifiedValidation,
      validate: isPatternMatch(validationRules?.patterns),
    };
  }
  if (type === InputTypes.INPUT && validationRules?.minValue) {
    return {
      ...modifiedValidation,
      validate: (value: string) =>
        !value.match('^(?:0+(?=[1-9])|0+(?=0$))') &&
        parseFloat(value) >=
          (typeof validationRules?.minValue?.value === 'number'
            ? validationRules.minValue.value
            : typeof validationRules?.minValue?.value === 'string'
              ? parseFloat(validationRules.minValue.value)
              : -Infinity),
    };
  }
  return modifiedValidation;
};
