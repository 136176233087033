import { combineReducers } from '@reduxjs/toolkit';
import { achievementsReducer } from './features/Achievements/slice/achievements.slice';
import { carouselReducer } from './features/CaptainUpCarosel/slice/captainUpCarousel.slice';
import { captainUpModalReducer } from './features/CaptainUpModal/slice/modal.slice';
import challengesReducer from './features/Challenges/slice/challenges.slice';
import { captainUpNavigationReducer } from './features/NavBar/slice/navBar.slice';
import { playerHistoryReducer } from './features/PlayerHistory/slice/playerHistory.slice';
import { storeReducer } from './features/Store/slice/store.slice';
import { textPageReducer } from './features/TextPage/slice/textPage.slice';
import { gamificationPlayerReducer } from './features/UserInfo/slice/gamificationPlayer.slice';
import { winnersPageReducer } from './features/WinnersPage/slice/winnersPage.slice';

export const captainUpReducer = combineReducers({
  challenges: challengesReducer,
  modal: captainUpModalReducer,
  navigation: captainUpNavigationReducer,
  gamificationPlayer: gamificationPlayerReducer,
  achievements: achievementsReducer,
  store: storeReducer,
  textPage: textPageReducer,
  playerHistory: playerHistoryReducer,
  winnersPage: winnersPageReducer,
  carousel: carouselReducer,
});
