import { MutableRefObject, useEffect, useState } from 'react';

export const usePosition = ({
  popOverRef,
  popOverInnerRef,
  position,
  left,
  top,
  showTriangle,
  elementWidth,
  padding,
}: {
  popOverRef: MutableRefObject<HTMLElement | null>;
  popOverInnerRef: MutableRefObject<HTMLElement | null>;
  position: 'left' | 'right';
  left: number;
  top: number;
  showTriangle: boolean;
  elementWidth: number;
  padding?: number;
}): {
  triangleClass: string;
  trianglePos: { isLeft: boolean; isTop: boolean };
  popoverPos: { left: number; top: number; padding: number };
} => {
  const [triangleClass, setTriangleClass] = useState('');
  const [trianglePos, setTrianglePos] = useState({ isLeft: true, isTop: false });
  const [popoverPos, setPopoverPos] = useState({ left: 0, top: 0, padding: 0 });

  const triggerClass = (isTop: boolean, isLeft: boolean) => {
    setTrianglePos({ isLeft: !isLeft, isTop: isTop });
    if (showTriangle && popOverInnerRef.current) {
      if (isTop) {
        if (!isLeft) {
          setTriangleClass('popover--arrow-right-up');
        } else {
          setTriangleClass('popover--arrow-left-up');
        }
      } else {
        if (!isLeft) {
          setTriangleClass('popover--arrow-right-down');
        } else {
          setTriangleClass('popover--arrow-left-down');
        }
      }
    }
  };

  useEffect(() => {
    if (popOverRef.current && popOverInnerRef.current) {
      const leftPos = left + elementWidth - (position === 'right' ? popOverRef.current.clientWidth : 0);
      const triangleHeight = showTriangle ? 20 : 0;
      if (window.innerHeight + window.scrollY > top + window.scrollY + popOverRef.current.clientWidth) {
        const topPos = top ? top + window.scrollY : 0;
        setPopoverPos((prev) => {
          return { ...prev, top: topPos, padding: triangleHeight + (padding ? padding : 0) };
        });

        triggerClass(true, leftPos < 0);
      } else {
        const topPos = top
          ? top + window.scrollY - popOverRef.current.clientHeight - triangleHeight - (padding ? padding * 2 : 0)
          : 0;

        setPopoverPos((prev) => {
          return { ...prev, top: topPos, padding: triangleHeight + (padding ? padding : 0) };
        });

        triggerClass(false, leftPos < 0);
      }
      if (leftPos > 0) {
        setPopoverPos((prev) => {
          return { ...prev, left: leftPos };
        });
      } else {
        setPopoverPos((prev) => {
          return { ...prev, left: left };
        });
      }
    }
  }, [popOverRef, top, left, popOverInnerRef]);

  return { triangleClass, trianglePos, popoverPos };
};
