import React from 'react';
import clsx from 'clsx';

/** Form GameCellDescription */
interface DescriptionProps {
  children: React.ReactNode;
  className?: string;
}

const Description: React.FC<DescriptionProps> = ({ className, children }) => {
  const descriptionClass = clsx('form-text', 'form-text--primary', className);
  return <p className={descriptionClass}>{children}</p>;
};

export default Description;
