import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProvidersBarUI } from 'modules/casino/modules/container/shared/features/casinoGameProviders/components/ProvidersBarUI';
import { Flex } from 'modules/casino/shared/components/layout';
import { Text } from 'modules/casino/shared/components/text';
import { useCurrency } from 'modules/casino/shared/hooks/useCurrency';
import { useCurrencyConfig } from 'modules/casino/shared/hooks/useCurrencyConfig';
import { formatCurrencyDisplay } from 'modules/casino/shared/utils/helpersCasino';
import config from 'utils/config';
import { JackpotTypes } from '../jackpotTypes';
import { thousandsSeparators } from '../jackpotUtils';

interface Props {
  winDetails: JackpotTypes.WinDetails;
  providerCode: string;
  currency: string;
}

const JackpotLastWinner: React.FC<Props> = ({ winDetails, providerCode, currency }) => {
  const { formatCurrency } = useCurrency();
  const { t } = useTranslation();
  const symbol = formatCurrency(currency);
  const { topWinAmount, topWinDate, lastWinPlayer, lastWinAmount, lastWinDate, winCounts } = winDetails || {};
  const { currencySymbolFirst, spaceBetween, formatToComma } = useCurrencyConfig();

  return (
    <>
      <div className="overflow-hidden jackpot-modal__inner">
        <Flex.AE className="jackpot-modal__block">
          {topWinAmount ? (
            <Flex.Col>
              <Text text={t('Jackpots.LastWinner.topWin')} />
              <Text
                className="jackpot-modal__text--primary jackpot-modal__text--accent"
                text={formatCurrencyDisplay({
                  currencySymbolFirst,
                  spaceBetween,
                  sum: thousandsSeparators(topWinAmount),
                  currency: symbol,
                  formatToComma,
                })}
              />
            </Flex.Col>
          ) : null}
          <Text className="ml-auto jackpot-modal__text--secondary" text={topWinDate} />
        </Flex.AE>
        <Flex.Col className="jackpot-modal__block">
          <Text text={t('Jackpots.LastWinner.lastWin') + '\u00A0' + lastWinPlayer} />
          <Flex.AE>
            <Text
              className="jackpot-modal__text--primary"
              text={formatCurrencyDisplay({
                currencySymbolFirst,
                spaceBetween,
                sum: thousandsSeparators(lastWinAmount),
                currency: symbol,
                formatToComma,
              })}
            />
            <Text className="ml-auto jackpot-modal__text--secondary" text={lastWinDate} />
          </Flex.AE>
        </Flex.Col>
        <Flex.ACJB className="jackpot-modal__block">
          {winCounts !== undefined ? (
            <>
              <Text text={t('Jackpots.LastWinner.numberOfWins')} />
              <Text text={winCounts} />
            </>
          ) : (
            <></>
          )}
        </Flex.ACJB>
        <Flex.Center className="jackpot-modal__block jackpot-modal__block--secondary">
          <ProvidersBarUI.Logo
            icon={`${providerCode}`}
            path={`${config.CDN_URL}/gaming-content/game-providers-new/logos/`}
            className={'jackpot-modal__provider-icon'}
            height={50}
          />
        </Flex.Center>
      </div>
    </>
  );
};
export default JackpotLastWinner;
