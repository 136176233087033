import React from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';
import { cssBurgerNS } from './burgerUI.types';

/** Wrapper */
interface WrapperProps {
  className?: string;
  children?: React.ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({ className, children }) => {
  const wrapperClass = clsx('h-100', cssBurgerNS.Wrapper, className);

  return <Flex.Col className={wrapperClass}>{children}</Flex.Col>;
};

export default Wrapper;
