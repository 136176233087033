import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { selectCashierData } from 'modules/retail/modules/cashier/slice/cashier.slice';
import { selectSessionBalance } from 'modules/retail/modules/ssbt/features/sessionBalance/slice/sessionBalance.slice';
import { RetailHeaderIcons } from 'modules/retail/modules/ssbt/types/ssbt.types';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { isCashier, isEmpty } from 'utils/common/helpersCommon';
import {
  CashierTranslations,
  MyBetsTranslation,
  SSBT,
  TimeoutModal,
} from 'utils/common/translationUtils/translationStrings';
import { retailBurgerHandlersMap } from './burgerRetailDataHandlers';
import usePrintProgram from '../../appHeader/retailHeader/hooks/usePrintProgram';
import { General, LayoutConfigTypes } from '../../general/types/generalSlice.types';

type RetailHeaderButtonsTypes = keyof General.RetailHeaderButtonsVisibilityTypes | 'cashier';
type RetailBurgerMenuItemsTypes = Exclude<
  RetailHeaderButtonsTypes,
  'deposit' | 'isPosAndTerminalInfo' | 'isDuplicateButtonActive' | 'cashout' | 'isLimitsAndDepositActive' // those are for the main header for now
>;

type ItemDataType = { onClick: () => void; text: string; icon: string; isDisabled: boolean; isEnabled: boolean };

const burgerMenuItemsList: RetailBurgerMenuItemsTypes[] = [
  'cashPay',
  'printProgram',
  'checkTicket',
  'isAnexReport',
  'createVoucher',
  'cashier',
  'voucherMenu',
  'finance',
  'lastPrint',
  'isReprintButtonActive',
  'logout',
  'login',
];

export const useBurgerRetailData = () => {
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const isPrintingEject = useAppSelector(selectCashierData.isPrintingEject);
  const retailSessionBalance = useAppSelector(selectSessionBalance.retailBalanceInfo)?.sessionBalance;
  const { handlePreviewPrint, previewStatus } = usePrintProgram();
  const dispatch = useAppDispatch();

  const showHeaderButtons = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.RETAIL_HEADER_BUTTONS_VISIBLE,
    deviceLayout: 'general',
  });

  const burgerMenuData: Record<RetailBurgerMenuItemsTypes, ItemDataType> = {
    cashPay: {
      icon: RetailHeaderIcons.CASH_PAY,
      isDisabled: false,
      onClick: retailBurgerHandlersMap.showCashPayModal(dispatch),
      text: CashierTranslations.HeaderButton.cashPayButtonText,
      isEnabled: !!(showHeaderButtons?.cashPay && isAuthenticated),
    },
    printProgram: {
      icon: RetailHeaderIcons.PRINT,
      isDisabled: previewStatus !== 'idle',
      onClick: handlePreviewPrint,
      text: SSBT.Navigation.printProgram,
      isEnabled: !!(showHeaderButtons?.printProgram && isCashier() && isAuthenticated),
    },
    checkTicket: {
      icon: RetailHeaderIcons.QR,
      isDisabled: false,
      onClick: retailBurgerHandlersMap.showCheckTicketTerminal(dispatch),
      text: SSBT.Navigation.checkTicket,
      isEnabled: !!showHeaderButtons?.checkTicket,
    },
    isAnexReport: {
      icon: RetailHeaderIcons.QR,
      isDisabled: false,
      onClick: retailBurgerHandlersMap.showAnexModal(dispatch),
      text: CashierTranslations.AnexReport.buttonAnexReport,
      isEnabled: !!(showHeaderButtons?.isAnexReport && isAuthenticated),
    },
    createVoucher: {
      icon: RetailHeaderIcons.CASHOUT,
      isDisabled: false,
      onClick: retailBurgerHandlersMap.createVoucher(dispatch),
      text: MyBetsTranslation.BetSummary.cashoutText,
      isEnabled: !!(
        showHeaderButtons?.createVoucher &&
        !isEmpty(retailSessionBalance) &&
        retailSessionBalance?.amount > 0
      ),
    },
    cashier: {
      icon: RetailHeaderIcons.CASHIER,
      isDisabled: false,
      onClick: retailBurgerHandlersMap.showCashierModal(dispatch),
      text: CashierTranslations.HeaderButton.cashierModalButtonText,
      isEnabled: !!(isAuthenticated && isCashier()),
    },
    voucherMenu: {
      icon: RetailHeaderIcons.VOUCHER,
      isDisabled: false,
      onClick: retailBurgerHandlersMap.createRetailVoucher(dispatch),
      text: 'Cashier.VoucherModal.voucherMenu',
      isEnabled: !!(showHeaderButtons?.voucherMenu && isCashier() && isAuthenticated),
    },
    finance: {
      icon: RetailHeaderIcons.FINANCE,
      isDisabled: false,
      onClick: retailBurgerHandlersMap.showFinanceModal(dispatch),
      text: CashierTranslations.HeaderButton.financeButtonText,
      isEnabled: !!(showHeaderButtons?.finance && isAuthenticated),
    },
    lastPrint: {
      icon: RetailHeaderIcons.REPRINT,
      isDisabled: isPrintingEject,
      onClick: retailBurgerHandlersMap.handleLastPrint(dispatch),
      text: CashierTranslations.HeaderButton.lastPrintText,
      isEnabled: !!(showHeaderButtons?.lastPrint && isAuthenticated),
    },
    isReprintButtonActive: {
      icon: RetailHeaderIcons.REPRINT,
      isDisabled: false,
      onClick: retailBurgerHandlersMap.showReprintModal(dispatch),
      text: CashierTranslations.TransactionHistoryReprintModal.historyBtn,
      isEnabled: !!(showHeaderButtons?.isReprintButtonActive && isAuthenticated),
    },

    logout: {
      icon: RetailHeaderIcons.LOGOUT,
      isDisabled: false,
      onClick: retailBurgerHandlersMap.logoutCashier(dispatch),
      text: TimeoutModal.Button.logoutText,
      // isEnabled: !!(showHeaderButtons?.logout && isAuthenticated),
      isEnabled: !!isAuthenticated,
    },
    login: {
      icon: RetailHeaderIcons.LOGIN,
      isDisabled: false,
      onClick: !isAuthenticated
        ? retailBurgerHandlersMap.loginCashier(dispatch)
        : () => {
            return;
          },
      text: `AppHeader.NavUser.loginBtn`,
      // isEnabled: !!(showHeaderButtons?.login && !isAuthenticated),
      isEnabled: !isAuthenticated,
    },
  };

  return { burgerMenuItemsList, burgerMenuData };
};
