import userStorage from 'pages/auth/login/UserStorage';
import axiosInstance from 'utils/common/axios-instance';
import { isEmpty, isProductionBuild } from 'utils/common/helpersCommon';

type LocationBasedTimeZone = {
  lang: string;
  time: string;
};

export const getLocationUrlBasedTimeZone = async (): Promise<LocationBasedTimeZone> => {
  try {
    const url = !isProductionBuild
      ? `https://winbet-uat.egt-digital.com/localization/env` // if this api changes, request will always fail on localhost
      : `${window.location.origin}/localization/env`;

    const response = await axiosInstance.get(url);
    const strJson = response.data.replace(/'/g, '"');
    return JSON.parse(strJson);
  } catch (err) {
    // set default language based ot browser settings
    return {
      lang: navigator.language.substring(0, 2).toLowerCase() || 'en',
      time: new Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  }
};

export const getTzOffsetFromTzName = (timeZone: string): string => {
  const date = new Date();
  try {
    const utcDate = new Date(date.toLocaleString('en', { timeZone: 'UTC' }));
    const tzDate = new Date(date.toLocaleString('en', { timeZone }));
    const offset = utcDate.getTime() - tzDate.getTime();
    return ((offset / 60000) * -1).toString();
  } catch {
    return (date.getTimezoneOffset() * -1).toString();
  }
};

export const checkDaylightSaving = (): void => {
  const automaticTZ = userStorage.getAutomaticTimeZone();
  if (!isEmpty(automaticTZ)) {
    const currentAutomticTzOffset = getTzOffsetFromTzName(automaticTZ.tz);
    // check if the TZ of the current location is changed, i.e. daylight saving TZ
    if (currentAutomticTzOffset != automaticTZ.tzOffset) {
      userStorage.setUserTimeZone(currentAutomticTzOffset);
      automaticTZ.tz && userStorage.setUserIanaTimeZoneCode(automaticTZ.tz);
      userStorage.setAutomaticTimeZone({ ...automaticTZ, tzOffset: currentAutomticTzOffset });
    }
  }
};

export const calculateRemaningDays = (addDay = 1): number => {
  const weekDaysCount = 7;
  const userOffset = Number(userStorage.getUserTimeZone() ?? 0);
  const dayNow = new Date(new Date().getTime() + userOffset * 60 * 1000).getDay();
  const remainingDays = weekDaysCount + addDay - dayNow;

  return remainingDays;
};
