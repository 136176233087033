import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';
import { CasinoGamesTypes } from 'modules/casino/modules/container/cellsContainer/cellls/types/casinoGamesTypes';
import {
  gamesFetchUrlByQueryType,
  getBackgroundFromGameTag,
  getNormalizedGamesData,
  isWidget,
} from 'modules/casino/modules/container/cellsContainer/cellls/utils/casinoGamesUtils';
import { CategoryQueryType, RequestStatuses } from 'modules/casino/shared/constants';
import { isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import { createAbortThunk } from 'modules/casino/store/thunkCreators';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { AppDispatch } from 'store';
import { RootState } from 'store/rootReducer';
import axiosInstance from 'utils/common/axios-instance';
import { getBusinessUnit } from 'utils/common/helpersCommon';
import config from 'utils/config';

export const categoryGamesThunks: CasinoGamesTypes.CasinoGamesThunks = {
  fetchCategoryGames: createAbortThunk(
    'casinoGames/fetchCategoryGames',
    async ({ queryType, params, isAuthenticated }, { source }) => {
      return gamesFetchUrlByQueryType[queryType](params, source, isAuthenticated);
    },
  ),
};

export const { fetchCategoryGames } = categoryGamesThunks;

export const casinoLiveDataThunk: CasinoGamesTypes.CasinoLiveDataThunk = {
  fetchCasinoLiveData: createAbortThunk(
    'casinoGames/fetchCasinoLiveData',
    async ({ liveGameIds, isSvara }, { source }) => {
      const fetchByType = isSvara ? 'liveFeedByTableId' : 'liveFeedByGameId';
      const queryIdsType = isSvara ? 'tableIds' : 'gameIds';
      const fetchLiveDataUrl = `${config.API_URL}/api/gaming/public/games/${fetchByType}?workingBusinessUnit=${getBusinessUnit()}&${queryIdsType}=${liveGameIds.join(',')}`;
      try {
        const response = await axiosInstance.post(fetchLiveDataUrl, { cancelToken: source.token });
        return response.data;
      } catch (error) {
        // console.log('fetchCasinoLiveData error: ', error);
      }
    },
  ),
};

export const { fetchCasinoLiveData } = casinoLiveDataThunk;

export const fetchGamesInfoByIdsThunk: CasinoGamesTypes.GameInfoByIdThunk = {
  fetchGamesInfoByIds: createAbortThunk('casinoGames/fetchGameInfoById', async (gameIds: string[], { source }) => {
    const fetchURL = `${
      config.API_URL
    }/api/gaming/public/bonus/gameIds?workingBusinessUnit=${getBusinessUnit()}&gameIds=${gameIds.join(',')}`;

    const response = await axiosInstance.get<CasinoGamesTypes.GameProps[]>(fetchURL, {
      cancelToken: source.token,
    });
    return response.data;
  }),
};

export const { fetchGamesInfoByIds } = fetchGamesInfoByIdsThunk;

const initialState: CasinoGamesTypes.State = {
  categoryGames: {},
  categoryGamesCount: {},
  categoryGamesLoadingState: {},
  normalizedLiveGameData: {},
  gamesInfo: [],
  gameOpenId: '',
  isLoading: false,
  ttl: Date.now(),
};

const casinoGames = createSlice({
  name: 'categoryGames',
  initialState,
  reducers: {
    setGameOpenId(state, action) {
      state.gameOpenId = action.payload;
    },
    updateCasinoLiveData(state, action) {
      try {
        const updatedId = JSON.parse(action.payload.value).gameId;
        state.normalizedLiveGameData[updatedId] = JSON.parse(action.payload.value);
        // if (Object.keys(state.normalizedLiveGameData).includes(updatedId)) {
        //   state.normalizedLiveGameData[updatedId] = JSON.parse(action.payload.value);
        // }
      } catch (e) {
        // console.log('JSON parsing has failed');
      }
    },
    clearCollection(state, action) {
      const { collectionId } = action.payload;

      delete state.categoryGames[collectionId];
      delete state.categoryGamesCount[collectionId];
      delete state.categoryGamesLoadingState[collectionId];
    },
    resetGamesInfo(state) {
      state.gamesInfo = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoryGames.pending, (state, action) => {
        const collectionId = action.meta?.arg?.params?.collectionId;
        const queryType = action.meta?.arg?.queryType;

        if (
          (collectionId && !state.categoryGamesLoadingState[collectionId]) ||
          queryType === CategoryQueryType.SEARCH_BY_TITLE
        ) {
          state.categoryGamesLoadingState[collectionId] = RequestStatuses.PENDING;
        }
      })
      .addCase(
        fetchCategoryGames.fulfilled,
        (state, action: PayloadAction<CasinoGamesTypes.FetchCategoryGamesPayload>) => {
          const {
            games,
            collectionId,
            size,
            totalPages,
            totalElements,
            currentPage,
            searchTag,
            last,
            categoryId,
            queryType,
            hasNumbers,
          } = action.payload;

          const {
            games: normalizedGames,
            gameIds,
            gameIdsSpecial,
          } = getNormalizedGamesData(
            games,
            state.categoryGames[collectionId]?.games,
            state.categoryGames[collectionId]?.gameIds,
            state.categoryGames[collectionId]?.gameIdsSpecial,
            queryType,
            hasNumbers,
          );

          state.categoryGames[collectionId] = {
            gameIds,
            games: normalizedGames,
            gameIdsSpecial,
            collectionId,
            size,
            totalPages,
            totalElements,
            currentPage,
            searchTag,
            last,
            ttl: Date.now(),
            categoryId,
            queryType,
          };

          state.categoryGamesCount[collectionId] =
            currentPage + 1 === totalPages ? state.categoryGames[collectionId]?.gameIds?.length : totalElements;
          if (
            isEmpty(state.categoryGames[collectionId].gameIds) &&
            isEmpty(state.categoryGames[collectionId].gameIdsSpecial)
          ) {
            state.categoryGamesLoadingState[collectionId] = RequestStatuses.EMPTY;
          } else {
            state.categoryGamesLoadingState[collectionId] = RequestStatuses.SUCCESS;
          }
        },
      )
      .addCase(fetchCategoryGames.rejected, (state, action) => {
        const collectionId = action.meta?.arg?.params?.collectionId;

        if (collectionId) {
          state.categoryGamesLoadingState[collectionId] = RequestStatuses.ERROR;
        }
      });
    builder.addCase(logoutUser, (state) => {
      const values = Object.values(state.categoryGames);
      values?.forEach((category) => {
        const { queryType, collectionId } = category;

        if (queryType === CategoryQueryType.LAST_PLAYED_CATEGORY) {
          delete state.categoryGames[collectionId];
          delete state.categoryGamesCount[collectionId];
          delete state.categoryGamesLoadingState[collectionId];
        }
      });
    });
    builder.addCase(fetchCasinoLiveData.fulfilled, (state, action) => {
      const payloadData = action.payload;
      try {
        if (payloadData) {
          Object.entries<string>(payloadData).forEach(([_, value]) => {
            if (value) {
              const livedata = JSON.parse(value);
              state.normalizedLiveGameData[livedata.gameId] = livedata;
            }
          });
        }
      } catch (error) {
        // console.log('Error parsing payload live data: ', error);
      }
    });
    builder.addCase(fetchGamesInfoByIds.fulfilled, (state, action) => {
      const payloadData = action.payload;
      if (payloadData.length) {
        state.gamesInfo = payloadData;
      }
    });
  },
});

export const { setGameOpenId, updateCasinoLiveData, clearCollection, resetGamesInfo } = casinoGames.actions;

const selectCategoryCollection = (state: RootState, collectionId: string): CasinoGamesTypes.CategoryCollection =>
  state.casino.casinoGames.categoryGames[collectionId];
const selectCategoryGames = (state: RootState, collectionId: string): CasinoGamesTypes.NormalizedGames =>
  state.casino.casinoGames.categoryGames[collectionId]?.games || {};
const selectCategoryGameUniqueIds = (state: RootState, collectionId: string): string[] =>
  state.casino.casinoGames.categoryGames[collectionId]?.gameIds || [];
const selectCategoryGameUniqueIdsSpecial = (state: RootState, collectionId: string): string[] =>
  state.casino.casinoGames.categoryGames[collectionId]?.gameIdsSpecial || [];
const selectCategoryGamesCount = (state: RootState, collectionId: string) =>
  state.casino.casinoGames.categoryGamesCount[collectionId] || 0;
const selectCategoryTtl = (state: RootState, collectionId: string) =>
  state.casino.casinoGames.categoryGames[collectionId]?.ttl || 0;
const selectGamesOpenId = (state: RootState) => state.casino.casinoGames.gameOpenId;
const selectGamesInfo = (state: RootState): CasinoGamesTypes.GameProps[] => state.casino.casinoGames.gamesInfo;

const selectTTL = (state: RootState) => state.casino.casinoGames.ttl;

const selectCategoryGamesLoadingState = createCachedSelector(
  (state: RootState, collectionId: string) => state.casino.casinoGames.categoryGamesLoadingState[collectionId],
  (loadingState): RequestStatuses => {
    if (loadingState) return loadingState;
    return RequestStatuses.INITIAL;
  },
)((_, id) => id);

const selectLiveGameDataById = (state: RootState, id: string): CasinoGamesTypes.Configuration =>
  state.casino.casinoGames.normalizedLiveGameData[id];

const selectRotationGameData = createCachedSelector(
  selectCategoryGames,
  selectCategoryGameUniqueIdsSpecial,
  (games: CasinoGamesTypes.NormalizedGames, gameUniqueIds: string[]): CasinoGamesTypes.RotatingRenderData[] =>
    gameUniqueIds.reduce((acc, uniqueId) => {
      const { tags, layoutModifier } = games[uniqueId];

      if (layoutModifier === 'SPECIAL') {
        acc.push({
          uniqueId: uniqueId,
          background: getBackgroundFromGameTag(tags),
        });
      }

      return acc;
    }, [] as CasinoGamesTypes.RotatingRenderData[]),
)((_, collectionId) => collectionId);

const selectExpandingGameIds = createCachedSelector(
  selectCategoryGames,
  selectCategoryGameUniqueIdsSpecial,
  (games: CasinoGamesTypes.NormalizedGames, gameUniqueIds: string[]): string[] =>
    gameUniqueIds.filter((uniqueId) => games[uniqueId]),
)((_, collectionId) => collectionId);

const selectAccentGameSectionData = createCachedSelector(
  selectCategoryGames,
  selectCategoryGameUniqueIdsSpecial,
  (
    games: CasinoGamesTypes.NormalizedGames,
    gameUniqueIds: string[],
  ): CasinoGamesTypes.AccentGameRenderData | undefined => {
    const uniqueId = gameUniqueIds[0] || '';

    if (uniqueId) {
      const { tags } = games[uniqueId];

      return {
        uniqueId,
        background: getBackgroundFromGameTag(tags),
      };
    }

    return;
  },
)((_, collectionId) => collectionId);

const selectNonWidgetGameIds = createCachedSelector(
  selectCategoryGames,
  selectCategoryGameUniqueIds,
  (games: CasinoGamesTypes.NormalizedGames, gameUniqueIds: string[]): string[] | undefined =>
    gameUniqueIds.reduce((acc, uniqueId) => {
      if (!isWidget(games[uniqueId])) {
        acc.push(games[uniqueId]?.id);
      }

      return acc;
    }, [] as string[]),
)((_, collectionId) => collectionId);

const selectNonWidgetGameUniqueIds = createSelector(
  selectCategoryGames,
  selectCategoryGameUniqueIds,
  (games: CasinoGamesTypes.NormalizedGames, gameIds: string[]): string[] =>
    gameIds.filter((uniqueId) => !isWidget(games[uniqueId])),
);

export const gamesSelectors = {
  categoryGames: selectCategoryGames,
  categoryGameUniqueIds: selectCategoryGameUniqueIds,
  rotationGameData: selectRotationGameData,
  expandingGameIds: selectExpandingGameIds,
  accentGameSectionData: selectAccentGameSectionData,
  categoryCollection: selectCategoryCollection,
  categoryGamesCount: selectCategoryGamesCount,
  categoryTtl: selectCategoryTtl,
  gamesOpenId: selectGamesOpenId,
  categoryGamesLoadingState: selectCategoryGamesLoadingState,
  liveGameDataById: selectLiveGameDataById,
  nonWidgetGameIds: selectNonWidgetGameIds,
  nonWidgetGameUniqueIds: selectNonWidgetGameUniqueIds,
  ttl: selectTTL,
  gamesInfo: selectGamesInfo,
};

export const resetLastPlayedCollection = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const categoryGames = getState().casino.casinoGames.categoryGames;
  const values: CasinoGamesTypes.CategoryCollection[] = Object.values(categoryGames);

  values?.forEach((category) => {
    const { queryType, collectionId } = category;

    if (queryType === CategoryQueryType.LAST_PLAYED_CATEGORY) {
      dispatch(clearCollection({ collectionId }));
    }
  });
};

export default casinoGames.reducer;
