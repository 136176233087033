const getLocalHighlightNewFeature = () => {
  const localFeatures = localStorage.getItem('viewFeatures');
  const items = localFeatures != null ? JSON.parse(localFeatures) : [];
  return items;
};

const setLocalHighlightNewFeature = (feature) => {
  const items = getLocalHighlightNewFeature();
  localStorage.setItem('viewFeatures', JSON.stringify([...items, feature]));
};

const isViewFeature = (feature) => {
  const items = getLocalHighlightNewFeature();
  return items.indexOf(feature) >= 0;
};

export { getLocalHighlightNewFeature, setLocalHighlightNewFeature, isViewFeature };
