import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { Text } from 'components/shared';
import { Flex } from 'components/shared/layout';

/** Terminal Modal CustomCkeckButton */
interface CustomCkeckButtonProps {
  text: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
}

const CustomCkeckButton: React.FC<CustomCkeckButtonProps> = ({ text, className, onClick }) => {
  const cssNS = 'cashier-form-control__btn--check';
  const btnClass = clsx(cssNS, className);

  return (
    <Flex.ACJB className={btnClass} onClick={onClick}>
      <Text className={`${cssNS}-text`} text={text} />
    </Flex.ACJB>
  );
};

export default CustomCkeckButton;
