import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setLaunchTimestamp } from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import { headerBonusIconThunks } from 'shared/common/features/appHeader/components/mainNavbar/mainNavbarButtons/features/mainNavbarButtonsBonus/slice/mainNavbarButtonsBonus.slice';
import { setReLaunchStatus } from 'shared/common/features/integrations/slice/integrations.slice';
import { hideRootModal, showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { WS } from 'shared/common/features/websockets/types/webSockets.types';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { AppDispatch } from 'store';
import type { RootState } from 'store/rootReducer';
import { createAbortThunk, CreateThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { isEmpty, getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import {
  BonusPostRequest,
  Category,
  CategoryStatus,
  GetCategoriesResponse,
  NormalizedCategoriesMap,
  OffersTabState,
  Promotion,
  TermsResponse,
} from '../types/offersTab.types';

export const readOffers =
  (currCategoryTitle: string, bonusIds: string[]) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      await axiosInstance.put(`${config.API_URL}/api/ews-bonuses/player/player-bonuses/unseen-offers`, {
        PlayerBonusIds: bonusIds,
      });

      if (currCategoryTitle) dispatch(resetVerticalUnseenCounter({ alias: currCategoryTitle, bonusIds }));
    } catch (err) {
      if (currCategoryTitle) dispatch(resetVerticalUnseenCounter({ alias: currCategoryTitle, bonusIds }));
    }
  };

export const handleUpdateBonusOffers =
  (payload: WS.Notification) =>
  async (dispatch: AppDispatch): Promise<void> => {
    if (payload.message.hasNewBonusOffer) {
      dispatch(fetchCategories());
    }
  };

export const fetchCategories: CreateThunk<void | undefined, GetCategoriesResponse, string | undefined> =
  createAbortThunk('offersTab/fetchOffersTab', async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<GetCategoriesResponse>(
        `${config.API_URL}/api/ews-bonuses/player/players/bonuses/offers`,
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseDataMessage(err));
    }
  });

export const postBonus: CreateThunk<
  BonusPostRequest & { buttonUrl: string; hideModal?: boolean },
  string,
  {
    StatusCode?: number;
    Message?: string;
  }
> = createAbortThunk(
  'offersTab/postBonus',
  async ({ bonusCode, id, buttonUrl, hideModal = true }, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await axiosInstance.post(`${config.API_URL}${buttonUrl}`, { bonusCode, id });

      const tombolaBonus = getState().common.headerBonusIcon.activeTombola;
      if (!isEmpty(tombolaBonus)) {
        Object.keys(tombolaBonus).map((bonus) => {
          dispatch(headerBonusIconThunks.getTombolaTickets({ url: tombolaBonus[bonus]?.apiUrl, isFromAccept: true }));
        });
      }

      const isInGameLaunch = getState().casino.casinoGameLauncher.isInGameLaunch;
      const launchInProcess = getState().casino.casinoGameLauncher.launchInProcess;
      // TODO - Add check to reload game only on offers that require it
      if (isInGameLaunch || launchInProcess) {
        dispatch(setLaunchTimestamp(Date.now()));
      }

      const launchedGameData = getState().common.integrations.casino.launchedGameData;
      // const launchInProcess = getState().casino.casinoGameLauncher.launchInProcess;
      // TODO - Add check to reload game only on offers that require it
      if (launchedGameData) {
        dispatch(setReLaunchStatus(Date.now()));
      }

      hideModal && dispatch(hideRootModal());
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseData(err));
    }
  },
);

export const fetchTerms: CreateThunk<
  { bonusId?: string; id: number | string; isPromotion?: boolean },
  string,
  string | undefined
> = createAbortThunk('offersTab/fetchTerms', async ({ bonusId, id, isPromotion }, { rejectWithValue, dispatch }) => {
  try {
    const response = await axiosInstance.get<TermsResponse>(
      `${config.API_URL}/api/ews-bonuses/player/promotions/paragraphs/${bonusId ? `${id}/bonus/${bonusId}` : `${id}`}`,
    );

    //update modal after receiving data
    if (!isPromotion) {
      dispatch(
        showRootModal({
          modalType: 'TERMS_OFFER',
          modalProps: { text: response.data.text, id },
          modalPriority: ModalPriorityEnum.LOW,
          modalId: 'TERMS_OFFER',
        }),
      );
    }

    return response.data.text;
  } catch (err) {
    return rejectWithValue(getError.responseDataMessage(err));
  }
});

export const fetchBonusCancelInfo: CreateThunk<string, string, string | undefined> = createAbortThunk(
  'offersTab/bonusCancelInfo',
  async (bonusId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<string>(
        `${config.API_URL}/api/ews-bonuses/player/player-bonuses/cancel-info/${bonusId}`,
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseDataMessage(err));
    }
  },
);

export const initialState: OffersTabState = {
  status: 'Success',
  hasFetched: false,
  categories: {},
  activeCategoryTitle: '',
  bonusStatusMap: {},
  bonusErrorMap: {},
  bonusStatusCode: {},
  redirectedOfferId: '',
  termsOffer: {},
  bonusCancelInfo: null,
  bonusCancelInfoStatus: 'idle',
};

const offersTab = createSlice({
  name: 'offersTab',
  initialState,
  reducers: {
    setActiveCategoryTitle: (state, action: PayloadAction<string>) => {
      state.activeCategoryTitle = action.payload;
    },
    setRedirectedOfferId: (state, action: PayloadAction<string>) => {
      state.redirectedOfferId = action.payload;
    },
    resetVerticalUnseenCounter: (state, action: PayloadAction<{ alias: string; bonusIds: string[] }>) => {
      const { alias, bonusIds } = action.payload;
      if (state.categories[alias]) {
        const unseenCount = state.categories[alias].verticalUnseenCounter;
        state.categories[alias].verticalUnseenCounter =
          unseenCount - bonusIds.length > 0 ? unseenCount - bonusIds.length : 0;
        state.categories[alias].promotions = state.categories[alias].promotions?.map((p) => {
          return {
            ...p,
            bonuses: p?.bonuses?.map((b) => ({
              ...b,
              isUnseen: bonusIds?.includes(b.id) ? false : b.isUnseen,
            })),
          };
        });
      }
    },
    resetOffersStatuses: (state) => {
      state.bonusStatusMap = {};
      state.bonusErrorMap = {};
      state.bonusStatusCode = {};
    },
    resetOffersTab: () => initialState,
    resetBonusCancelInfo: (state) => {
      state.bonusCancelInfo = null;
      state.bonusCancelInfoStatus = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, () => initialState);
    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.status = 'Failed';
      state.error = action.payload;
      state.hasFetched = true;
    });
    builder
      .addCase(fetchBonusCancelInfo.fulfilled, (state, action) => {
        state.bonusCancelInfo = action.payload;
        state.bonusCancelInfoStatus = 'succeeded';
      })
      .addCase(fetchBonusCancelInfo.pending, (state) => {
        state.bonusCancelInfoStatus = 'pending';
      })
      .addCase(fetchBonusCancelInfo.rejected, (state) => {
        state.bonusCancelInfoStatus = 'failure';
      });
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.categories = [...action.payload].reduce<NormalizedCategoriesMap>((acc, curr) => {
        const category = { ...curr } as Category;
        category.promotions?.forEach((promotion) => {
          promotion.bonuses.forEach((b) => {
            if (b.bonusCompletion) {
              if (!b.bonusCompletion.verticals) {
                b.bonusCompletion.verticals = [];
              }
            }
          });
        });
        acc[category.alias] = category;
        return acc;
      }, {});
      state.status = Object.keys(state.categories).length ? 'Success' : 'Empty';
      state.hasFetched = true;
    });
    builder.addCase(postBonus.pending, (state, action) => {
      const { id } = action.meta.arg;
      state.bonusStatusMap[id] = 'pending';
    });
    builder.addCase(postBonus.rejected, (state, action) => {
      const { id } = action.meta.arg;
      state.bonusStatusMap[id] = 'failure';
      state.bonusErrorMap[id] = action.payload?.Message;
      state.bonusStatusCode[id] = action.payload?.StatusCode;
    });
    builder.addCase(postBonus.fulfilled, (state, action) => {
      const { id } = action.meta.arg;
      state.bonusStatusMap[id] = 'succeeded';
      state.activeCategoryTitle = '';
    });

    builder.addCase(fetchTerms.pending, (state, action) => {
      const { id } = action.meta.arg;
      state.bonusStatusMap[id] = 'pending';
    });
    builder.addCase(fetchTerms.rejected, (state, action) => {
      const { id } = action.meta.arg;
      state.bonusStatusMap[id] = 'failure';
      state.bonusErrorMap[id] = action.payload;
    });
    builder.addCase(fetchTerms.fulfilled, (state, action) => {
      const { id } = action.meta.arg;
      state.termsOffer[id] = action.payload;
      state.bonusStatusMap[id] = 'succeeded';
    });
  },
});

export const {
  setActiveCategoryTitle,
  resetOffersStatuses,
  resetOffersTab,
  setRedirectedOfferId,
  resetVerticalUnseenCounter,
  resetBonusCancelInfo,
} = offersTab.actions;

export default offersTab.reducer;

export const selectOffersTab = {
  error: (state: RootState): string | undefined => state.offersTab.error,
  activeCategoryTitle: (state: RootState): string => state.offersTab.activeCategoryTitle,
  activeCategory: (state: RootState): Category | undefined =>
    state.offersTab.categories[state.offersTab.activeCategoryTitle],
  bonusStatusAndError: createSelector(
    (state: RootState, id: string) => state.offersTab.bonusErrorMap[id],
    (state: RootState, id: string) => state.offersTab.bonusStatusMap[id],
    (state: RootState, id: string) => state.offersTab.bonusStatusCode[id],
    (error, status, statusCode) => ({ error, status: status || 'idle', statusCode }),
  ),
  termsOfferById:
    (id: number | string | undefined) =>
    (state: RootState): string | undefined =>
      id ? state.offersTab.termsOffer?.[id] : undefined,
  categoryStatus: createSelector(
    (state: RootState) => state.offersTab.status,
    (state: RootState) => state.offersTab.categories,
    (status, categories): CategoryStatus => (Object.keys(categories).length || status === 'Failed' ? status : 'Empty'),
  ),
  promoCount: createSelector(
    (state: RootState): NormalizedCategoriesMap => state.offersTab.categories,
    (categories): number =>
      Object.values(categories).reduce((acc: number, c: Category) => {
        acc +=
          c?.promotions?.reduce((a: number, p: Promotion) => {
            a += p.bonuses.length;
            return a;
          }, 0) || 0;
        return acc;
      }, 0),
  ),
  categories: createSelector(
    (state: RootState): NormalizedCategoriesMap => state.offersTab.categories,
    (categories) => Object.values(categories),
  ),
  redirectedOfferId: (state: RootState): string => state.offersTab.redirectedOfferId,
  notificationCounter: createSelector(
    (state: RootState) => state.offersTab.categories,
    (categories): number =>
      Object.values(categories).reduce((acc: number, curr: Category) => (acc += curr.verticalUnseenCounter), 0),
  ),
  hasFetched: (state: RootState): boolean => state.offersTab.hasFetched,
  bonusCancelInfo: (state: RootState) => state.offersTab.bonusCancelInfo,
  cancelInfoLoading: (state: RootState) =>
    state.offersTab.bonusCancelInfoStatus === 'pending' || state.offersTab.bonusCancelInfoStatus === 'idle',
};
