import React from 'react';
import clsx from 'clsx';
import Icon from 'components/shared/Icon';
import { Flex } from 'components/shared/layout';
import { CSS } from 'utils/common/types/css.types';
import { ButtonDismiss } from '../buttons';

interface Props {
  title: string;
  description?: string | React.ReactNode;
  icon?: string;
  variant?: CSS.Variants;
  className?: string;
  dismissable?: boolean;
  onClickHandler?: () => void;
}

const NotificationBlock: React.FC<Props> = ({
  variant,
  icon,
  title,
  description,
  className,
  dismissable = false,
  onClickHandler,
}) => {
  const cssNS = 'notification';
  const notificationClass = clsx(
    'w-100',
    cssNS,
    variant && `${cssNS}--${variant}`,
    variant && `bg--${variant}`,
    dismissable && `${cssNS}--dismissable`,
    className,
  );
  return (
    <Flex.AC className={notificationClass}>
      {icon && <Icon className={`${cssNS}__icon`} icon={icon} />}
      <div className={`'w-100 ${cssNS}__body`}>
        <div className={`${cssNS}__title`}>{title}</div>
        <div className={`${cssNS}__text`}>{description}</div>
      </div>
      {dismissable && <ButtonDismiss onClickHandler={onClickHandler} />}
    </Flex.AC>
  );
};

export default NotificationBlock;
