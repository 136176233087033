import React, { memo, useCallback, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import { useCasinoGamesFetchStatus } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useCasinoGamesFetchStatus';
import { useAppDispatch, useAppSelector } from 'store';
import { CategoryFilterUI } from './CategoryFilterUI';
import { categoryBreadcrumbSelectors, setCategoryActiveFilterId } from '../slice/CategoryBreadcrumbSlice';
import { CategoryLayoutTypes, SearchTag } from '../types/CategoryLayoutTypes';

interface Props {
  categoryId: string;
  searchTags: SearchTag[];
  categoryLayoutType: CategoryLayoutTypes;
}

const CategoryFilter: React.FC<Props> = ({ categoryId, searchTags, categoryLayoutType }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isMostPlayedSelected, setMostPlayed] = useState(false);
  const mostPlayedLabel = 'mostPlayed';
  const activeFilterId = useAppSelector((state) => categoryBreadcrumbSelectors.activeFilterId(state, categoryId));
  const { hasNoGames } = useCasinoGamesFetchStatus(categoryId, categoryLayoutType);

  useEffect(() => {
    if (hasNoGames) {
      dispatch(
        setCategoryActiveFilterId({
          collectionId: categoryId,
          activeFilterId: '',
        }),
      );

      setMostPlayed(true);
    }
  }, [dispatch, hasNoGames, categoryId]);

  const handleTagClickSelect = useCallback(
    (e, tagId: string) => {
      const activeFilter = tagId == mostPlayedLabel && isMostPlayedSelected ? mostPlayedLabel : activeFilterId;

      if (tagId !== activeFilter) {
        if (tagId === mostPlayedLabel) {
          setMostPlayed(true);
        } else {
          setMostPlayed(false);
        }

        dispatch(
          setCategoryActiveFilterId({
            collectionId: categoryId,
            activeFilterId: tagId === mostPlayedLabel ? '' : tagId,
          }),
        );
        return;
      }
    },
    [dispatch, activeFilterId, categoryId],
  );

  const getCategoryFilterTitleById = () => {
    if (isMostPlayedSelected) {
      return t(`Casino.CategoryBreadcrumb.mostPlayedText`);
    }
    const selectedSearchTag = searchTags.find((item) => {
      return String(item.id) === activeFilterId;
    });
    return selectedSearchTag?.name || '';
  };

  return (
    <CategoryFilterUI.Wrapper
      desktopText={
        isMostPlayedSelected || activeFilterId
          ? getCategoryFilterTitleById()
          : t(`Casino.CategoryBreadcrumb.sortByText`)
      }
    >
      <CategoryFilterUI.MenuItem
        onClick={(e) => handleTagClickSelect(e, mostPlayedLabel)}
        dataClass=""
        isActive={isMostPlayedSelected}
        text={t(`Casino.CategoryBreadcrumb.mostPlayedText`)}
      />
      {searchTags.map((tag: SearchTag, index) => {
        return (
          <CategoryFilterUI.MenuItem
            key={index}
            onClick={(e) => handleTagClickSelect(e, String(tag.id))}
            dataClass={tag.name}
            dataSort={tag.id}
            isActive={activeFilterId === String(tag.id)}
            text={tag.name}
          />
        );
      })}
    </CategoryFilterUI.Wrapper>
  );
};

export default memo(CategoryFilter, isEqual);
