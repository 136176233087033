import React, { memo } from 'react';
import clsx from 'clsx';
import { getSectionTitle } from 'utils/common/helpersCommon';
import CDNIcon from '../CDNIcon';
import { Flex } from '../layout';
import { UIBlockTypes, uiBlockTypesClassNameMap } from '../layout/UIBlock';

interface Props {
  title: string;
  accentedTitle?: boolean;
  level?: 0 | 1;
  componentLocation?: UIBlockTypes;
  onClickHandler?: () => void;
  categoryName?: string;
  children?: React.ReactNode;
  iconCDN?: JSX.Element;
  icon?: string | null;
  color?: string;
}

const ContentHeader: React.FC<Props> = ({
  title,
  accentedTitle,
  level = 1,
  componentLocation,
  onClickHandler,
  categoryName,
  children,
  iconCDN: IconCDN,
  icon,
  color,
}) => {
  const cssNS = 'header-level';

  const headerClass = clsx(
    `${cssNS}-${level}`,
    componentLocation && `${cssNS}-${level}--${uiBlockTypesClassNameMap[componentLocation]}`,
  );

  const headerTextClass = clsx(
    'mr-auto text-truncate',
    `${cssNS}-${level}__text`,
    accentedTitle && `${cssNS}-${level}__text--accent`,
  );

  const sectionTitle = getSectionTitle(categoryName, title);

  return (
    <Flex.ACJB className={headerClass} onClick={onClickHandler}>
      {IconCDN ? IconCDN : null}
      {icon && <CDNIcon type="containerHeader" icon={icon} />}
      <div className={headerTextClass} style={color ? { color } : {}}>
        {sectionTitle}
      </div>
      {children && <Flex className="align-self-stretch">{children}</Flex>}
    </Flex.ACJB>
  );
};

export default memo(ContentHeader);

// ContentHeader.whyDidYouRender = true;
