import { selectLanguage } from 'shared/common/features/settings/slice/settings.slice';
import { useAppSelector } from 'store';

type Props = {
  casinoType: string;
};

export const useMenuItemsCollectionCacheKey = ({ casinoType }: Props): { cacheKey: string } => {
  const language = useAppSelector(selectLanguage);

  return { cacheKey: `${casinoType}_${language}` };
};
