import React from 'react';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { TimeDataType } from 'modules/casino/shared/utils/dateUtils';
import { Layout02 } from './components';
import { usePromotionImgResource } from '../../../../hooks/usePromotionImgResource';
import { PromoWidgetsTypes } from '../../../../promoWidgetTypes';
import { getDateDifference } from '../../../../promoWidgetUtils';

interface Props {
  promoItem: PromoWidgetsTypes.PromoItem;
  openPromotion: () => void;
}

// WBRO/WBBG cell layout
const PromoCellLayout_2: React.FC<Props> = ({ promoItem, openPromotion }) => {
  const { cellRef, imgSource } = usePromotionImgResource(promoItem.widgetResource);

  return (
    <div className={`${cssGlobalNS}-c-prm-lobby-card`} key={promoItem.id}>
      <Layout02.CardText
        type="title"
        text={promoItem.title}
        textStyle={{ color: promoItem.titleTextColor, backgroundColor: '' }}
      />
      <div
        className={`${cssGlobalNS}-c-prm-lobby-card__body`}
        style={{ backgroundColor: promoItem.descriptionBackground }}
      >
        <div
          className={`${cssGlobalNS}-c-prm-lobby-card__image`}
          ref={cellRef}
          style={{ backgroundImage: `url(${imgSource})` }}
        >
          <Layout02.CardText
            type="subtitle"
            textStyle={{ color: promoItem?.subTitleTextColor || '', backgroundColor: promoItem.subTitleBackground }}
            text={promoItem?.subTitle || ''}
          />
        </div>
        <div
          className={`${cssGlobalNS}-c-prm-lobby-card__text-block`}
          style={{ color: promoItem?.descriptionTextColor || '' }}
        >
          <Layout02.CardText type="description" text={promoItem?.description || ''} />
          <Layout02.CardMeta
            dateDifference={getDateDifference(promoItem, 'layout_2', false) as TimeDataType}
            onClick={openPromotion}
          />
        </div>
      </div>
    </div>
  );
};

export default PromoCellLayout_2;
