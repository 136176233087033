import { GameCellRestrictions } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellRestriction/gameCellRestriction.types';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { GamePlayType } from 'modules/casino/shared/features/gameLaunch/casinoLaunchTypes';

export declare module CasinoWidgetTypes {
  type CasinoWidgetParams = {
    config: Config;
  };

  type Config = {
    clientId: string;
    params: Params;
    listeners?: Listeners;
  };

  type CasinoWidgetMethods = {
    init: () => void;
    addVertical: (verticalConfig: AddVerticalConfig) => CasinoSubWidgetMethods;
    addContainer: (containerConfig: AddContainerConfig) => CasinoSubWidgetMethods;
    launchGame: (launchGameConfig: LaunchGameConfig) => CasinoSubWidgetMethods;
    config: () => Config;
    // destroy: () => void;
    logout: () => void;
    updateConfig: (config: Partial<Params>) => void;
    navigate: (params: NavigationParams) => void;
  };

  type CasinoSubWidgetMethods = {
    remove;
    updateParams;
  };

  type Params = {
    language: string;
    verticalType?: string | null;
    businessName: string;
    casinoWidgetUrl: string;
    preferredTheme: 'Light' | 'Dark';
    appHeaderHeight?: number;
    currencyCode?: string;
    isAppFooterVisible?: boolean;
    gameLaunchBasePath?: string;
    gameLaunchExitPath?: string;
    epoch?: string;
    token?: string | null;
    basePath?: string;
    timeZoneOffset?: string | null;
    isLocal?: boolean;
    platformRoutes?: Record<'promotions', string>;
    casinoRelaunchStatus: number | null;
    reservedAreaInGameLaunch: {
      mobile: ReservedAreaBounds;
      desktop: ReservedAreaBounds;
    };
    safariSafeArea: {
      portrait: boolean;
      landscape: boolean;
    };
    refetchBonusGames?: boolean;
  };

  type ReservedAreaBounds = {
    topMargin: number;
    bottomMargin: number;
    rightMargin: number;
    leftMargin: number;
  };

  type SubWidgetContainerParams = {
    type: WidgetContainerTypes;
    containerCategoryParams?: ContainerCategoryParams;
    containerWidgetParams?: ContainerWidgetParams;
  };

  type ContainerCategoryParams = {
    categoryType: CategoryLayoutTypes;
    categoryId?: string;
    subType?: CategorySubTypes;
    gameIds?: string[];
    categoryDetails?: {
      googleTagEvent?: string;
      title?: string;
      color?: string;
    };
  };

  type ContainerWidgetParams = {
    widgetId: string;
  };

  type AddContainerConfig = {
    containerId: string;
    params: SubWidgetContainerParams;
    listeners: {
      onContainerLoad?: () => void;
    };
  };

  type AddVerticalConfig = {
    containerId: string;
    params: {
      casinoRootType: string | null;
      initialVerticalRoute: string;
    };
    listeners: {
      onVerticalLoad?: () => void;
    };
  };

  type LaunchGameConfig = {
    containerId: string;
    params: any;
    listeners: {
      onLaunchGameLoad?: () => void;
    };
  };

  type NavigationParams = {
    fullPath?: string;
  };

  export interface StartGameProps {
    id?: string;
    description?: string;
    refGameId?: string;
    providerId?: string;
    providerAlias?: string;
    gamePlayType?: GamePlayType;
    betType?: GameCellRestrictions.ProductType;
    gameType?: string;
    device?: string;
  }

  export type NavigationOptions = {
    state?;
    replaceCurrentHistory?: boolean;
    noRerender?: boolean;
  };

  type Listeners = {
    onLoad?: (config: CasinoWidgetTypes.Config) => void;
    onShowLogin?: (navOptions?: LoginNavigationOptions) => void;
    onLogout?: () => void;
    onShowAppHeader?: (payload: boolean) => void;
    onShowAppFooter?: (payload: boolean) => void;
    onNotificationUpdate?: (payload: string) => void;
    onSetSessionTimerActivity?: (payload: boolean) => void;
    onResize?: (payload: { width: number; height: number }) => void;
    onScroll?: (payload: { x: number; y: number }) => void;
    onTriggerTrackingEvent?: (payload: { eventKey: string; additionalParams?: Record<string, any> }) => void;
    onGameStart?: (payload: StartGameProps) => void;
    onGameDataLoaded?: (payload: StartGameProps) => void;
    onNavigationChange?: (payload: { path: string; navigateBackStep?: number; options?: NavigationOptions }) => void;
    onSetLiveTime?: () => void;
    onFetchFormFields?: (payload: FetchFormFieldsParams) => void;
    onOpenGame?: (payload: string) => void;
    onSetFavGamesCounter?: (payload: number) => void;
    onSetEnabledColorIcons?: (payload: boolean) => void;
  };

  type FetchFormFieldsParams = {
    resolve?: (value: unknown) => void;
    reject?: (value: unknown) => void;
    closeGame?: () => void;
  };

  type LoginNavigationOptions = {
    callback: (continueExec: boolean) => void;
    exit?: () => void;
  };
}

export enum WidgetContainerTypes {
  WIDGET = 'WIDGET',
  CATEGORY = 'CATEGORY',
}
