import Body from './Body';
import ButtonsWrapper from './ButtonsWrapper';
import Close from './Close';
import Content from './Content';
import GridButton from './GridButton';
import Header from './Header';
import InfoMessage from './InfoMessage';
import Input from './Input';
import InputInfoMessage from './InputInfoMessage';
import InputsWrapper from './InputsWrapper';
import NavCol from './NavCol';
import NavGrid from './NavGrid';
import Status from './Status';
import Tab from './Tab';
import TabsWrapper from './TabsWrapper';
import Wrapper from './Wrapper';
import ErrorModalOverlayNotifier from '../ErrorModalOverlayNotifier';
import UCNForm from '../features/UCNForm/UCNForm';

export const CashierModalUI = {
  Body: Body,
  ButtonsWrapper: ButtonsWrapper,
  Close: Close,
  Content: Content,
  ErrorMessage: ErrorModalOverlayNotifier,
  GridButton: GridButton,
  Header: Header,
  InfoMessage: InfoMessage,
  InputInfoMessage: InputInfoMessage,
  Input: Input,
  InputWrapper: InputsWrapper,
  NavCol: NavCol,
  NavGrid: NavGrid,
  Status: Status,
  Tab: Tab,
  TabsWrapper: TabsWrapper,
  Wrapper: Wrapper,
  UCNForm: UCNForm,
};
