import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'modules/casino/shared/components/layout';
import { Promotions } from 'modules/casino/shared/utils/translationUtils/translationStrings';
import CampaignBadge from './CampaignBadge';
import CampaignInfoTimeCounter from './CampaignInfoTimeCounter';
import CampaignTitle from './CampaignTitle';
import { SCCampaignItemInner, SCCampaignItemText } from './styled/CampaignInfo.styled';
import { prizeDropCSSNS } from '../../PrizeDropCellWidgetConstants';
import { PrizeDropWidgetTypes } from '../../PrizeDropCellWidgetTypes';

type Props = {
  campaignInfo: PrizeDropWidgetTypes.CampaignInfo;
};

const CampaignInfo: React.FC<Props> = ({ campaignInfo }) => {
  const { t } = useTranslation();
  const { isActive, endDate, startDate, title } = campaignInfo || {};
  const hasTimer = isActive ? !!endDate : !!startDate;

  return (
    <SCCampaignItemInner className={`${prizeDropCSSNS}-widget--info-wrapper`}>
      <SCCampaignItemText className="d-flex-col-jb">
        <Flex.JB>
          <CampaignTitle title={title || ''} className={`${prizeDropCSSNS}-widget__title`} />
          {!isActive && (
            <CampaignBadge
              badgeText={t(Promotions.PrizeDrop.soonText)}
              className={`${prizeDropCSSNS}-widget__title-badge`}
            />
          )}
        </Flex.JB>

        {hasTimer && (
          <CampaignInfoTimeCounter
            date={!isActive ? startDate : endDate}
            text={!isActive ? t(Promotions.PrizeDrop.startsInText) : t(Promotions.PrizeDrop.endsInText)}
            isColumn
          />
        )}
      </SCCampaignItemText>
    </SCCampaignItemInner>
  );
};

export default CampaignInfo;
