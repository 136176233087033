import { CategorySubTypes } from '../../modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';

export const GLOBAL_TTL = 7200000; //2h

export const GAME_LAUNCH_PREFIX = 'game-launch';
export const GAME_LAUNCH_EXIT_PREFIX = 'close-game';

export enum GameType {
  SLOT_GAME = 'SLOT_GAME',
  LIVE_CASINO = 'LIVE_CASINO',
  VIRTUAL_RNG = 'VIRTUAL_RNG',
  ROULETTE = 'ROULETTE',
  BLACKJACK = 'BLACKJACK',
  BACCARAT = 'BACCARAT',
  SHOW_PROGRAM = 'SHOW_PROGRAM',
  POKER = 'POKER',
  RNG_TABLE_GAME = 'RNG_TABLE_GAME',
  MINI_SLOT_GAME = 'RNG_SLOT_GAME',
  SVARA = 'SVARA',
  PLAYTECH_iPOKER = 'PLAYTECH_iPOKER',
}

export enum LiveCasinoGameType {
  LIVE_CASINO = 'LIVE_CASINO',
  VIRTUAL_RNG = 'VIRTUAL_RNG',
  ROULETTE = 'ROULETTE',
  BLACKJACK = 'BLACKJACK',
  BACCARAT = 'BACCARAT',
  SHOW_PROGRAM = 'SHOW_PROGRAM',
  POKER = 'POKER',
  SVARA = 'SVARA',
  PLAYTECH_iPOKER = 'PLAYTECH_iPOKER',
}

export const imageQuality = 2;

export const noActiveHelpCategory = '-1';

export enum CasinoLobbyType {
  Casino = 'CASINO',
  LiveCasino = 'LOBBY_LIVE',
  Default = 'DEFAULT_LOBBY',
  LiveGames = 'LIVE_GAMES',
}
export const casinoTypeKeyValue = {
  [CasinoLobbyType.Casino]: 'casino',
};

export const DEFAULT_CASINO_ROUTE = '/casino-egt';
export const WINDOW_CLOSE_ROUTE = '/close-game';

export enum ProviderCodeToName {
  EGTI = 'EGT Interactive',
  EGTF = 'EGT Digital',
  PRAGMATIC = 'Pragmatic',
  EVOLUTION = 'Evolution',
  PLAYSON = 'Playson',
  ISOFTBET = 'ISoftBet',
  SPRIBE = 'Spribe',
  EVOPLAY = 'EvoPlay',
  ENDORPHINA = 'Endorphina',
  NETENT = 'NetEnt',
  AMATIC = 'Amatic',
  EGTD_LOTTARY = 'EGT Lottery',
  HABANERO = 'Habanero',
  ORYX = 'Oryx',
  GAM = 'Gamomat',
  GHG = 'Golden hero',
  GIVME = 'Givme',
  KLM = 'Kalamba',
  CDB = 'Candle bets',
  PSO = 'Peter and sons',
  SGD = 'Scientific Games Digital',
  MRSLOTTY = 'Mr. Slotty',
  CT_GAMING = 'Casino Technology',
  TVBET = 'TV Bet',
  STAKE_LOGIC = 'Stake Logic',
  BETGAMES = 'Bet games',
  RED_RAKE = 'Red Rake',
  SPADEGAMING = 'Spade Gaming',
  NSOFT = 'NSoft',
  ELBET = 'ElBet',
  SMOJOS = 'Smojos',
  PARIPLAY = 'Pariplay',
  REDTIGER_OSS = 'redtiger_oss',
  NETENT_OSS = 'NetEnt',
  IWIN = 'Iwin',
  WORLDMATCH = 'Worldmatch',
  SPINOMENAL = 'Spinomenal',
  EGTD_INSTANT = 'EGT Instant',
  GAMEART = 'Gameart',
  SKYWIND = 'Skywind',
  APOLLO = 'Apollo',
  SPEARHEAD = 'Spearhead Studios',
  AGS = 'PlayAGS',
  BETIXON = 'Betixon',
  EZUGI = 'Ezugi',
  YGGDRASIL = 'Yggdrasil',
  DIGITAIN = 'Digitain',
  KAJOT = 'Kajot',
  EXCELLENT_REEL = 'Excellent reel',
  SYNOT = 'Synot',
  ELK_SGD = 'ELK Studio - SGD',
  ELK = 'ELK Studio',
  BGAMING = 'BGaming',
  TOMHORN = 'Tom Horn',
  SVARA = 'BlueCroco - Svara',
  RUBYPLAY = 'RubyPlay',
  KAGAMING = 'KA Gaming',
  NOLIMIT_OSS = 'No Limit City',
  BTG_OSS = 'Big Time Gaming',
  GREENTUBE = 'GreenTube',
  CTG = 'CT Interactive',
  BF = 'BeeFee',
  FAZI = 'Fazi',
  ARC = 'Arcadem',
  WAZDAN = 'Wazdan',
  PLAYTECH = 'Playtech',
  BARBARA_BANG = 'Barbara bang',
  TBP = 'The Better Platform',
  MASCOT = 'Mascot',
  ASPECT = 'Aspect',
  HACKSAW_PP = 'Hacksaw',
  SMARTSOFT = 'Smartsoft',
  KALOBA_PP = 'Kaloba',
}

export enum WidgetTemplate {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
  MACRO = 'macro',
  REGULAR = 'regular',
  SECTION = 'section',
  RIBBON = 'ribbon',
  SECTION_STAKE = 'section_stake',
  LANDING = 'landing',
  BANNER_SLIDER = 'banner_slider',
  JACKPOT_BANNER = 'jackpot_banner',
  SECTION_STAKE_PLUS = 'section_stake_plus',
}

export enum WidgetAlignmentItems {
  LEFT = 'flex-start',
  CENTER = 'center',
  RIGHT = 'flex-end',
  TOP = 'flex-start',
  BOTTOM = 'flex-end',
}

export enum WidgetGamesPreViewMobile {
  VERTICAL = 7,
  HORIZONTAL = 4,
  MACRO = 7,
  REGULAR = 4,
  SECTION = 3,
  SECTION_STAKE = 5,
  SECTION_STAKE_PLUS = 5,
  LANDING = 5,
  BANNER_SLIDER = 5,
}
export enum WidgetGamesPreViewMobileXL {
  VERTICAL = 11,
  HORIZONTAL = 6,
  MACRO = 12,
  REGULAR = 6,
  SECTION = 5,
  SECTION_STAKE = 7,
  SECTION_STAKE_PLUS = 7,
  LANDING = 5,
  BANNER_SLIDER = 5,
}
export enum WidgetGamesPreViewTablet {
  VERTICAL = 7,
  HORIZONTAL = 4,
  MACRO = 8,
  REGULAR = 4,
  SECTION = 5,
  SECTION_STAKE = 11,
  SECTION_STAKE_PLUS = 11,
  LANDING = 4,
  BANNER_SLIDER = 4,
}
export enum WidgetGamesPreViewDesktop {
  VERTICAL = 8,
  HORIZONTAL = 5,
  MACRO = 10,
  REGULAR = 5,
  SECTION = 9,
  SECTION_STAKE = 15,
  SECTION_STAKE_PLUS = 15,
  LANDING = 4,
  BANNER_SLIDER = 4,
}

export enum WidgetGamesPreViewDesktopXL {
  VERTICAL = 6,
  HORIZONTAL = 4,
  MACRO = 8,
  REGULAR = 4,
  SECTION = 14,
  SECTION_STAKE = 23,
  SECTION_STAKE_PLUS = 23,
  LANDING = 4,
  BANNER_SLIDER = 3.09,
}

export enum CategoryQueryType {
  SEARCH_BY = 'SEARCH_BY',
  SEARCH_BY_LIVE = 'SEARCH_BY_LIVE',
  MOST_PLAYED_CATEGORY = 'MOST_PLAYED_CATEGORY',
  FREE_SPIN_BONUS = 'FREE_SPIN_BONUS',
  LAST_PLAYED_CATEGORY = 'LAST_PLAYED_CATEGORY',
  FAVOURITES_CATEGORY = 'FAVOURITES_CATEGORY',
  MOST_SEARCHED_CATEGORY = 'MOST_SEARCHED_CATEGORY',
  SEARCH_BY_TITLE = 'SEARCH_BY_TITLE',
  CATEGORY = 'CATEGORY',
  QUERY_CATEGORY = 'QUERY_CATEGORY',
  GAMES_CATEGORY = 'GAMES_CATEGORY',
  RECOMMENDATIONS_CATEGORY = 'RECOMMENDATIONS_CATEGORY',
  SVARA_CATEGORY = 'SVARA_CATEGORY',
  EXTERNAL = 'EXTERNAL',
}

export type CategoryQueryTypes =
  | CategoryQueryType.CATEGORY
  | CategoryQueryType.QUERY_CATEGORY
  | CategoryQueryType.FAVOURITES_CATEGORY
  | CategoryQueryType.MOST_PLAYED_CATEGORY
  | CategoryQueryType.LAST_PLAYED_CATEGORY
  | CategoryQueryType.RECOMMENDATIONS_CATEGORY
  | CategoryQueryType.SVARA_CATEGORY;

export const CATEGORY_FALLBACK_TYPE = CategoryQueryType.CATEGORY;

export enum GridType {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export enum RequestStatuses {
  INITIAL,
  PENDING,
  ERROR,
  SUCCESS,
  EMPTY,
}

export const categoryRequiredParams = [
  { key: 'id' },
  { key: 'alias' },
  { key: 'title' },
  { key: 'query' },
  { key: 'queryType' },
  { key: 'layoutConfig' },
];
export const requiredLayoutConfigParams = [{ key: 'layout' }, { key: 'layoutRows' }, { key: 'layoutGrid' }];

export enum LayoutConfigInnerLayoutTypes {
  JACKPOTS = 'jackpots',
  LAST_WINNERS = 'lastWinners',
  WIDGET_PRIZE_DROP = 'widgetPrizeDrop',
  WIDGET_LEADERBOARD = 'widgetLeaderBoard',
  NAVIGATION = 'navigation',
  QUICKDEPOSITCONFIG = 'quickDepositConfig',
  CASINO_GAME_PROVIDERS_CONFIG = 'casinoGameProvidersConfig',
  CASINO_GAME_AGGREGATOR_CONFIG = 'casinoGameAggregatorConfig',
  CASINO_LAYOUT_CONFIG = 'casinoLayoutConfig',
  CASINO_EXTERNAL_CATEGORIES = 'casinoExternalCategories',
  LIVE_FEED = 'liveFeed',
  BONUS_GAMES_API = 'bonusGamesApi',
  ENABLE_SEGMENT_CHECK = 'enableSegmentCheck',
  KYC_CONFIG = 'kycConfig',
  CATEGORY_FETCH_INTERVAL = 'categoryFetchInterval',
  ENABLE_CATEGORY_TRACKING = 'enableCategoryTracking',
  SHOW_BET_LIMITS = 'showBetLimits',
  SHOW_BET_LIMITS_PER_PROVIDER = 'showBetLimitsPerProvider',
  CURRENCY_DISPLAY = 'currencyDisplay',
  IOS_SAFE_ARIA = 'iOsSafeAria',
  MOBILE_APP_RESTRICTIONS = 'mobileAppRestrictions',
  CASINO_PROVIDERS_BAR_POSITION = 'casinoProvidersBarPosition',
}

export const LOBBY_ITEM_IN_VIEW_MARGIN = '300px';

export const RESTRICTED_QUERY_CATEGORIES_BY_GRID_LAYOUT = [
  CategoryQueryType.FAVOURITES_CATEGORY,
  CategoryQueryType.MOST_SEARCHED_CATEGORY,
  CategoryQueryType.LAST_PLAYED_CATEGORY,
];

export const RESTRICTED_SUBTYPES = [
  CategorySubTypes.SIDEBAR,
  CategorySubTypes.PROMOTION,
  CategorySubTypes.FOOTER,
  CategorySubTypes.FREE_SPIN,
  CategorySubTypes.FREE_SPIN_CHALLENGE,
  CategorySubTypes.FAVOURITES_MODAL,
  CategorySubTypes.RESULT_SEARCHED,
  CategorySubTypes.MOST_SEARCHED,
  // CategorySubTypes.LANDING,
  CategorySubTypes.IN_GAME_SEARCHED_RESULTS,
  CategorySubTypes.IN_GAME_MOST_SEARCHED_RESULTS,
];

export enum BonusTypes {
  WAGERING = 'wagering',
  FREE_BET = 'freeBet',
  FREE_SPIN = 'freeSpin',
  // SURPRISE = 'surprise',
  // AWARD = 'award',
}

export enum PRODUCT_TYPE_ENUM {
  SPORT = 'SPORT',
  CASINO = 'CASINO',
  LIVE_CASINO = 'LIVE_CASINO',
  FANTASY_GAMES = 'FANTASY_GAMES',
  TABLE_GAMES = 'TABLE_GAMES',
  LOTTERY = 'LOTTERY',
}

export const BANK_ROUTE = '/my-account/bank';
