import { ReactNode } from 'react';
import clsx from 'clsx';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { WidgetTemplate } from 'modules/casino/shared/constants';
import { ScrollWrapper } from 'modules/casino/shared/features/casinoScroll';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { CasinoWidgetsTypes } from '../../../types/casinoWidgetsTypes';
import { promoWidgetScrollWrapperParamsMap, promoWidgetWrapperClassesByLayout } from '../promoWidgetConstants';
import { PromoWidgetsTypes } from '../promoWidgetTypes';

interface WrapperProps {
  children: ReactNode;
  templateType: CasinoWidgetsTypes.WidgetTypes;
  categoryLayoutType: CategoryLayoutTypes;
  contentLayout: PromoWidgetsTypes.GridLayoutType;
  className?: string;
}

const WidgetWrapper: React.FC<WrapperProps> = ({
  children,
  templateType,
  categoryLayoutType,
  contentLayout,
  className,
}) => {
  const scrollWrapperParams = promoWidgetScrollWrapperParamsMap[templateType];

  const widgetWrapperStyle = clsx(
    `${cssGlobalNS}-c-promo-widget`,
    `${cssGlobalNS}-c-promo-widget--${WidgetTemplate[templateType]}`,
    promoWidgetWrapperClassesByLayout[categoryLayoutType] || '',
    className && className,
  );

  return (
    <div className={widgetWrapperStyle}>
      <ScrollWrapper {...scrollWrapperParams}>
        <div className={`prm-grid prm-grid--scrollable ${contentLayout?.toLowerCase()}`}>{children}</div>
      </ScrollWrapper>
    </div>
  );
};

export default WidgetWrapper;
