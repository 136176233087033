import { cdnConfig, fitCover } from 'utils/common/cdnConfig';
import config from 'utils/config';
import { NotificationTypes } from '../../../../myProfile/sections/tabs/notificationsTab/notificationTypes';

export const wheelConfig = {
  bgMobile: 'bgr-mobile.png',
  bgDesktop: 'bgr-desktop.png',
  revolutions: 4,
  revolutionsPeriod: 6,
};

export const wheelCampaignImages = (folder: NotificationTypes.GamificationFolder) => {
  const path = `${cdnConfig.GAMIFICATION_MODULE}/${folder}`;

  const wheelImgSrc = `${config.CDN_IMAGE_API}${fitCover(460 * 2, 460 * 2)}${path}/wheel.png`;
  const markerImgSrc = `${config.CDN_IMAGE_API}${fitCover(64 * 2, 70 * 2)}${path}/arrow-marker.png`;
  const btnImgSrc = `${config.CDN_IMAGE_API}${fitCover(98 * 2, 98 * 2)}${path}/wheel-button.png`;
  const wrapperImgSrc = `${config.CDN_IMAGE_API}${fitCover(460 * 2, 460 * 2)}${path}/wheel_bgr.png`;

  return { wheelImgSrc, markerImgSrc, btnImgSrc, wrapperImgSrc };
};
