import React from 'react';
import clsx from 'clsx';

/** InputLabel */
interface InputLabelProps {
  className?: string;
  children?: React.ReactNode;
  text: string;
}

const InputLabel: React.FC<InputLabelProps> = ({ className, children, text }) => {
  const wrapperClass = clsx('egtd-x-input__label', className);

  return (
    <div className={wrapperClass}>
      {text}
      {children}
    </div>
  );
};

InputLabel.displayName = 'EGTD SportsBook Input Label';

export default InputLabel;
