import { CSSProp } from 'styled-components';
import { ButtonsActionDesign } from './Buttons';
import { Gradient } from './Gradient';
import { Size } from './SCTypography';
import { Spacers } from './Spacers';

type MixinsType = {
  /** an optional prop (VERY COMMON!) */
  Spacers: {
    Ph: CSSProp;
    PhCustom: (paddingHorizontal: string) => CSSProp;
    PvCustom: (paddingVertical: string) => CSSProp;
    MhCustom: (marginHorizontal: string) => CSSProp;
    MvCustom: (marginVertical: string) => CSSProp;
    pvSm: CSSProp;
    phSm: CSSProp;
    spacerH: CSSProp;
    centerHorizontal: CSSProp;
    centerVertical: CSSProp;
  };
  ButtonsActionDesign: {
    PrimaryActionButtonColor: CSSProp;
    PrimaryActionButtonBgWithHover: CSSProp;
    primaryActionButtonBg: CSSProp;
  };
  Gradient: (startColor: string, endColor: string, direction: string) => CSSProp;
  Size: {
    clampedSize: (
      minSize: number,
      maxSize: number,
      minViewport?: number,
      maxViewport?: number,
      cssProp?: string,
    ) => string;
    toRem: (pixelSize: number) => string;
  };
};
export const Mixins: MixinsType = {
  Spacers: Spacers,
  ButtonsActionDesign: ButtonsActionDesign,
  Gradient: Gradient,
  Size: Size,
};
