import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { ContainerTypes } from 'utils/common/types/common.types';

const containerPollingInterval: Partial<Record<ContainerTypes, number>> = {
  [ContainerTypes.highlightsPerSport]: 10000,
};

const DEFAULT_POLLING_INTERVAL = 6000;

const usePollingInterval = (containerType?: keyof typeof ContainerTypes): number => {
  const pollingIntervalConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.POLLING,
  })?.defaultInterval;

  const containerInterval = containerType ? containerPollingInterval[containerType] : undefined;

  return containerInterval || pollingIntervalConfig || DEFAULT_POLLING_INTERVAL;
};

export default usePollingInterval;
