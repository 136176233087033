import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCountDown } from 'hooks/common/useCountDown';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import { addLeadingZero, formatDateWithOffset } from 'utils/dateUtils';
import PrizeDropTimeCounterItem from './PrizeDropTimeCounterItem';
import { prizeDropCSSNS } from '../prizeDropUICSS.types';

type Props = {
  date: number;
  text?: string;
  isColumn?: boolean;
  showDate?: boolean;
  setIsTimeExpired?: React.Dispatch<React.SetStateAction<boolean>>;
  slotStatus?: 'soon' | 'active' | 'ended';
  children?: React.ReactNode;
  className?: string;
};

const PrizeDropTimeCounter: React.FC<Props> = ({
  slotStatus,
  date,
  text,
  isColumn,
  showDate,
  setIsTimeExpired,
  children,
  className,
}) => {
  const { t } = useTranslation();
  const { timer, isExpired } = useCountDown(date, 'systemTime');

  const classProp = { class: className };

  useEffect(() => {
    if (setIsTimeExpired) {
      setIsTimeExpired(isExpired);
    }
  }, [isExpired, setIsTimeExpired]);

  return (
    <egtd-przd-counter data-column={isColumn} {...classProp}>
      {showDate && isExpired && (
        <egtd-przd-counter-display>
          <div className={`${prizeDropCSSNS}-counter-text`}>{t(Promotions.PrizeDrop.endedOnText)}</div>
          <div className={`${prizeDropCSSNS}-counter-ended-text`}>
            {formatDateWithOffset('DD.MM.YYYY HH:mm:ss', date)}
          </div>
        </egtd-przd-counter-display>
      )}

      {(!isExpired || slotStatus === 'soon') && (
        <>
          {(!isExpired || slotStatus === 'soon') && <div className={`${prizeDropCSSNS}-counter-text`}>{text}</div>}
          <egtd-przd-counter-display>
            {timer?.days !== undefined && (
              <PrizeDropTimeCounterItem
                date={addLeadingZero(timer.days > 0 ? timer.days : 0)}
                text={t(Promotions.PrizeDrop.days)}
              />
            )}
            {timer?.hours !== undefined && (
              <PrizeDropTimeCounterItem
                date={addLeadingZero(timer.hours > 0 ? timer.hours : 0)}
                text={t(Promotions.PrizeDrop.hours)}
              />
            )}
            {timer?.minutes !== undefined && (
              <PrizeDropTimeCounterItem
                date={addLeadingZero(timer.minutes > 0 ? timer.minutes : 0)}
                text={t(Promotions.PrizeDrop.minutes)}
              />
            )}
            {timer?.seconds !== undefined && (
              <PrizeDropTimeCounterItem
                date={addLeadingZero(timer.seconds > 0 ? timer.seconds : 0)}
                text={t(Promotions.PrizeDrop.seconds)}
              />
            )}
          </egtd-przd-counter-display>
        </>
      )}

      {children}
    </egtd-przd-counter>
  );
};

export default PrizeDropTimeCounter;
