import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormButton, FormControl } from 'components/shared/forms/components';
import { ContentHeader } from 'components/shared/headers';
import InputStatus from 'components/shared/UI/Input/InputStatus';
import { ClaimTicketTranslations } from 'utils/common/translationUtils/translationStrings';
import { CheckTicketUI } from './checkTicketUI';
import useClaimTicketForm from '../hooks/useClaimTicketForm';

const ClaimTicketForm = ({ hideHeader = true, isModal }: { hideHeader?: boolean; isModal?: boolean }): JSX.Element => {
  const formProps = useForm({ mode: 'onChange' });
  const { formFields, isButtonDisabled, onSubmit, errorMessage } = useClaimTicketForm({ isModal, formProps });
  const { t } = useTranslation();

  return (
    <FormProvider {...formProps}>
      <form onSubmit={formProps.handleSubmit(onSubmit)} className="d-flex-col">
        <CheckTicketUI.Wrapper>
          {!hideHeader && <ContentHeader title={t(ClaimTicketTranslations.Info.title)} />}
          <CheckTicketUI.Content>
            {formFields.map((field) => (
              <FormControl key={field.name} {...field} />
            ))}

            {errorMessage && <InputStatus status="error" text={t(ClaimTicketTranslations.Messages.ticketNotFound)} />}

            <CheckTicketUI.Divider />
            <FormButton text={t(ClaimTicketTranslations.Buttons.searchButton)} disabled={isButtonDisabled} />
          </CheckTicketUI.Content>
        </CheckTicketUI.Wrapper>
      </form>
    </FormProvider>
  );
};

export default ClaimTicketForm;
