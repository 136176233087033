import { useEffect } from 'react';
import { useForm, FormProvider, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/shared';
import { FormButton, FormControl, FormUI } from 'components/shared/forms/components';
import { Flex } from 'components/shared/layout';
import { ModalUI } from 'components/shared/ModalUI';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { MyAccountTranslation, MyProfile } from 'utils/common/translationUtils/translationStrings';
import { clearAddictionTest, playerLimitsThunks, selectMyAccountLimits } from '../slice/limits.slice';

const LimitIncreaseModal: React.FC<{
  onSubmit: ({
    afterDeclaration,
    successfullyPassed,
  }: {
    successfullyPassed: boolean;
    afterDeclaration: boolean;
  }) => Promise<void>;
}> = ({ onSubmit }) => {
  const addictionTest = useAppSelector(selectMyAccountLimits.addictionTest);
  const addictionResultsLoading = useAppSelector(selectMyAccountLimits.addictionResultsLoading);
  const addictionResultsError = useAppSelector(selectMyAccountLimits.addictionResultsError);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { closeModal } = useHandleModals();

  useEffect(() => {
    dispatch(playerLimitsThunks.fetchTestForm());
    return () => {
      dispatch(clearAddictionTest());
    };
  }, [dispatch]);

  const handleSubmit = (params: FieldValues) => {
    dispatch(playerLimitsThunks.saveAddictionPreventionResults({ params, onSubmit }));
  };
  const formProps = useForm({ mode: 'onChange' });

  if (isEmpty(addictionTest)) return null;

  return (
    <Modal className="gambling-test__modal" visible={true} scrollable={true}>
      <Modal.Close closeText={t(MyProfile.OffersTab.closeBtn)} closeAction={closeModal} />
      <Modal.Body className="gambling-test__body no-scrollbars" padding="lg">
        <Flex.Col className="gambling-test__header">
          <ModalUI.Title hasAccent text={t(MyAccountTranslation.Limits.increaseLimitTitle)} />
          <ModalUI.Title text={t(MyAccountTranslation.Limits.increaseLimitSubTitle)} />
        </Flex.Col>
        <div className="gambling-test__content custom-scrollbars overflow-y-auto overflow-x-hidden w-100">
          <FormProvider {...formProps}>
            <form onSubmit={formProps.handleSubmit(handleSubmit)}>
              {addictionTest.map((field) => {
                const fieldWithCustomProps = {
                  ...field,
                  ...{
                    classNames: `col-${field?.gridStyles?.col || 6} col-md-${field?.gridStyles?.md || 6} mt-2 mb-2`,
                    isOutlined: true,
                  },
                };
                return (
                  <FormUI.Row key={fieldWithCustomProps.name}>
                    <FormControl {...fieldWithCustomProps} groupType="default" />
                  </FormUI.Row>
                );
              })}
              {addictionResultsError && <FormUI.Message hasFormGroup type="error" content={t(addictionResultsError)} />}
              <Flex.JE>
                <FormButton
                  className="gambling-test__button col-6 col-md-6"
                  text={t(MyAccountTranslation.Limits.confirmBtn)}
                  disabled={addictionResultsLoading}
                />
              </Flex.JE>
            </form>
          </FormProvider>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LimitIncreaseModal;
