import { useCallback, useRef } from 'react';
import { isIOS } from 'react-device-detect';
import smoothscroll from 'smoothscroll-polyfill';
type ScrollToBackTypes = { setScrollPosition: boolean; disableScrollBack?: boolean };

export const useScrollToBackYPositiion = (): {
  scrollToBack: ({ setScrollPosition, disableScrollBack }: ScrollToBackTypes) => void;
  updateScrollPosition: () => void;
  resetScrollPosition: () => void;
} => {
  const scrollRef = useRef<number>(0);

  const updateScrollPosition = useCallback(() => {
    scrollRef.current = window.scrollY;
  }, []);

  const resetScrollPosition = useCallback(() => {
    scrollRef.current = 0;
  }, []);

  /**
   * Toggle Body and HTML no scroll classes
   * @param setScrollPosition boolean, Add or Remove classes
   * @param disableScrollBack boolean, Scroll to prev position
   */

  const scrollToBack = ({ setScrollPosition, disableScrollBack = true }: ScrollToBackTypes) => {
    if (setScrollPosition) {
      scrollRef.current = window.scrollY;
    } else {
      const scrollYPostion = scrollRef.current < 10 ? window.scrollY : scrollRef.current;
      isIOS && smoothscroll.polyfill();
      disableScrollBack && window.scrollTo(0, scrollYPostion);
    }
  };

  return { scrollToBack, updateScrollPosition, resetScrollPosition };
};
