import { useCallback, useEffect } from 'react';
import { GameCellRestrictions } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellRestriction/gameCellRestriction.types';
import { selectResponsibleGambling } from 'pages/myAccount/tabs/responsibleGambling/slice/responsibleGambling.slice';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch, useAppSelector } from 'store';

type ReturnHookType = {
  playerRestrictionsByProducts: GameCellRestrictions.Restriction[];
  showRestrictionsModalInfo: () => void;
  allProductsRestricted: boolean;
  hasRestrictions: boolean;
};

const usePlayerRestrictions = ({
  products, // Pass all products for all restrictions length
  showModal,
}: {
  products: GameCellRestrictions.ProductType[];
  showModal?: boolean;
}): ReturnHookType => {
  const allPlayerRestrictions = useAppSelector(selectResponsibleGambling.allPlayerRestrictions);
  const dispatch = useAppDispatch();

  const playerRestrictionsByProducts = products.flatMap((product) => {
    const productRestrictions = allPlayerRestrictions?.[product];

    if (!productRestrictions) return [];

    return productRestrictions.filter(
      (restriction) =>
        restriction.productTypeName === product &&
        (restriction.restrictionStatus === 'active' || !restriction.restrictionStatus),
    );
  });

  const hasRestrictions = playerRestrictionsByProducts && playerRestrictionsByProducts?.length > 0;
  // Should pass all products in products arg for correct all restricted products usage
  const allProductsRestricted = hasRestrictions && playerRestrictionsByProducts.length === products.length;

  const showRestrictionsModalInfo = useCallback(() => {
    // TODO - Casino as Widget - use RootCasinoModal
    // dispatch(
    //   showRootCasinoModal({
    //     modalType: 'PLAYER_RESTRICTIONS_INFO',
    //     modalProps: { playerRestrictionsByProducts },
    //   }),
    // );

    dispatch(
      showRootModal({
        modalType: 'PLAYER_RESTRICTIONS_INFO',
        modalProps: { playerRestrictionsByProducts },
        modalPriority: ModalPriorityEnum.MEDIUM,
        modalId: 'PLAYER_RESTRICTIONS_INFO',
      }),
    );
  }, [playerRestrictionsByProducts, dispatch]);

  useEffect(() => {
    if (hasRestrictions && showModal) {
      showRestrictionsModalInfo();
    }
  }, [dispatch, showRestrictionsModalInfo, allPlayerRestrictions, hasRestrictions, showModal]);

  return {
    showRestrictionsModalInfo,
    playerRestrictionsByProducts,
    allProductsRestricted,
    hasRestrictions,
  };
};

export default usePlayerRestrictions;
