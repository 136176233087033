import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';

import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { AltenarSportsBookTypes } from '../types/altenarSportsBook.types';

const initialState: AltenarSportsBookTypes.SliceInitialState = {
  token: null,
  fetchTokenStatus: 'idle',
};

export const altenarSportsBookThunks = {
  fetchToken: createAbortThunk('altenarSportsBook/fetchToken', async (_, { rejectWithValue, signal, source }) => {
    try {
      signal.addEventListener('abort', () => source.cancel());

      const urlPath = `${config.API_URL}/api/altenar/player/token`;

      const response = await axiosInstance.get(urlPath);

      return response.data;
    } catch (err) {
      if (Axios.isCancel(err)) {
        return rejectWithValue(null);
      }
      return rejectWithValue(getError.default());
    }
  }),
};

const { fetchToken } = altenarSportsBookThunks;

const altenarSportsBook = createSlice({
  name: 'altenarSportsBook',
  initialState,
  reducers: {
    clearToken: (state) => {
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchToken.pending, (state) => {
        state.fetchTokenStatus = 'pending';
      })
      .addCase(fetchToken.fulfilled, (state, action) => {
        state.token = action.payload;
        state.fetchTokenStatus = 'fulfilled';
      })
      .addCase(fetchToken.rejected, (state) => {
        state.token = null;
        state.fetchTokenStatus = 'rejected';
      })
      .addCase(logoutUser, (state) => {
        state.token = null;
        state.fetchTokenStatus = 'idle';
      });
  },
});
export const { clearToken } = altenarSportsBook.actions;
export default altenarSportsBook.reducer;

export const selectAltenarSportsBook = {
  token: (state: RootState) => state.altenarSportsBook.token,
  fetchTokenStatus: (state: RootState) => state.altenarSportsBook.fetchTokenStatus,
};
