import { memo } from 'react';
import { WsNotificationsTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/types/wsNotifications.types';
import { isEmpty } from 'utils/common/helpersCommon';
import { LottoWinNumber } from './features/lottoWinNumber';
import { useCloseModal } from './hooks/useCloseModal';

type Props = { activeNotification: WsNotificationsTypes.Notification };

export const containerType: Record<'Lotto' | 'default', React.FC<Props>> = {
  Lotto({ activeNotification }) {
    return <LottoWinNumber activeNotification={activeNotification} />;
  },
  default() {
    return null;
  },
};

const NotificationPopup: React.FC<Props> = ({ activeNotification }) => {
  const { handleClose } = useCloseModal(activeNotification);
  const ContainerComponent: React.FC<Props> = containerType[activeNotification?.Style?.animationKey ?? 'default'];

  if (isEmpty(activeNotification) || !ContainerComponent) {
    handleClose();
    return null;
  }
  return <ContainerComponent activeNotification={activeNotification} />;
};

export default memo(NotificationPopup);
