import React from 'react';
import { Icon } from 'components/shared';
import { UI } from 'components/shared/UI';

interface HeaderIconProps {
  icon?: string;
  useIconPath?: boolean;
}

const HeaderIcon: React.FC<HeaderIconProps> = ({ icon, useIconPath = true }) => {
  return useIconPath ? (
    <UI.SVGCDN className="mpd-cta-header__icon" icon={icon} />
  ) : (
    <Icon defaultClassName={false} className="mpd-cta-header__icon" icon={icon} />
  );
};

export default HeaderIcon;
