import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/rootReducer';

export enum SearchType {
  Sport = 'sports/search',
  Casino = 'casino/search',
  // Virtuals, ESports
}

const initialState = {
  activeSearchType: SearchType.Sport,
};

const navSearch = createSlice({
  name: 'navSearch',
  initialState,
  reducers: {
    setActiveSearchType(state, action) {
      state.activeSearchType = action.payload;
    },
  },
});

export const { setActiveSearchType } = navSearch.actions;

export const selectActiveSearchType = (state: RootState): SearchType => state.navSearch.activeSearchType;

export default navSearch.reducer;
