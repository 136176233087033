import { useCallback, useEffect } from 'react';
import { selectRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { selectCashierData, setIsCashierModalPrompted } from '../slice/cashier.slice';

export const useCashierModalPrompt = () => {
  const isCashierModalPrompted = useAppSelector(selectCashierData.isCashierModalPrompted);
  const modalsQueue = useAppSelector(selectRootModal.modalsQueue);
  const isPrintingEject = useAppSelector(selectCashierData.isPrintingEject);
  const dispatch = useAppDispatch();

  const shouldPromptCashierModal = useCallback(() => {
    return Object.keys(modalsQueue)[0] === 'CASHIER_MODAL' && !isPrintingEject;
  }, [modalsQueue, isPrintingEject]);

  useEffect(() => {
    const shouldPrompt = shouldPromptCashierModal();

    if (shouldPrompt !== isCashierModalPrompted) {
      dispatch(setIsCashierModalPrompted(shouldPrompt));
    }
  }, [modalsQueue, isCashierModalPrompted, dispatch]);

  return isCashierModalPrompted;
};
