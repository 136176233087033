import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError, isEmpty, localLog } from 'utils/common/helpersCommon';
import { Sport } from 'utils/common/types/sportTypes';
import config from 'utils/config';
import { OmniPlayTypes } from '../providers/omniPlay/types/omniPlay.types';
import { RetailGamesTypes } from '../types/retailGames.types';

const initialState: RetailGamesTypes.RetailGamesState = {
  providersData: [],
  providerStatus: 'idle',
  hasProvidersFetched: false,
  hasError: false,
  omniPlay: null,
  notification: null,
  gamesNavWrapperHeight: 0,
};

export const retailGamesThunks: RetailGamesTypes.Thunks = {
  fetchRetailGamesProviders: createAbortThunk(
    `retailGamesLobby/fetchRetailGamesProviders`,
    async (apiUri, { rejectWithValue }) => {
      const url = `${config.API_URL}${apiUri}`;
      try {
        const response = await axiosInstance.get(url);
        return response.data;
      } catch (error) {
        return rejectWithValue(getError.default());
      }
    },
  ),
  saveTicketData: createAbortThunk(
    `retailGamesLobby/saveTicketData`,
    async (ticketData: RetailGamesTypes.SaveTicketDataArgs, { rejectWithValue }) => {
      const url = `${config.API_URL}/api/omnpl/agent/v1/ticket/save`;
      try {
        const response = await axiosInstance.post(url, ticketData);
        localLog({ message: `ℹ ~ Response from saveTicketData: ${JSON.stringify(response?.data)}` });
      } catch (error) {
        return rejectWithValue(getError.default());
      }
    },
  ),
  makePayTicketRequest: createAbortThunk(
    `retailGamesLobby/makePayTicketRequest`,
    async (args: OmniPlayTypes.PayTicketRequestArgs, { rejectWithValue }) => {
      const { ticketCode, session, personalId, firstName, lastName, dispatch, payTicketUrl } = args;
      if (isEmpty(payTicketUrl)) return;
      const url = payTicketUrl
        .replace('{ticketCode}', ticketCode)
        .replace('{session}', session)
        .replace('{personalId}', personalId)
        .replace('{lastName}', lastName)
        .replace('{firstName}', firstName);

      try {
        // example url: `https://www.cfvbfbtlotto.com/retail/client_api_3/web/public/pay_ticket_client?ticket_code=7850C5VB1W7E2&external_integration=egt&type=account&PHPSESSID=dfhj5647jgd5hn1g32hnghnj&CNP=6040709015238&firstname=Michael&lastname=Scott`;
        const response = await axiosInstance.get<OmniPlayTypes.PayTicketResponse>(url);
        localLog({ message: `ℹ ~ Response from ticket-pay request: ${JSON.stringify(response.data)}` });

        dispatch(
          setNotification({
            set: {
              text: response.data?.value,
              title: response.data?.status,
              type: response.data?.status,
            },
          }),
        );

        dispatch(setPayTicketStatus(response.data));
      } catch (error) {
        return rejectWithValue(getError.default());
      }
    },
  ),
};

const retailGames = createSlice({
  name: 'retailGames',
  initialState,
  reducers: {
    setBackBtnVisible(state, action: PayloadAction<boolean>) {
      if (!isEmpty(state.omniPlay)) {
        state.omniPlay.showOmniPlayBackBtn = action.payload;
      } else {
        state.omniPlay = {
          showOmniPlayBackBtn: action.payload,
        };
      }
    },
    setPayTicketStatus(state, action: PayloadAction<OmniPlayTypes.PayTicketResponse | null>) {
      if (!isEmpty(state.omniPlay)) {
        state.omniPlay.payTicketStatus = action.payload;
      } else {
        state.omniPlay = {
          payTicketStatus: action.payload,
        };
      }
    },
    setIsPayTicketModalVisible(state, action: PayloadAction<boolean>) {
      if (!isEmpty(state.omniPlay)) {
        state.omniPlay.isPayTicketDataModalVisible = action.payload;
      } else {
        state.omniPlay = {
          isPayTicketDataModalVisible: action.payload,
        };
      }
    },
    setCurrentTicketPayData(state, action: PayloadAction<OmniPlayTypes.TicketPayData | null>) {
      if (!isEmpty(state.omniPlay)) {
        state.omniPlay.currentTicketPayData = action.payload;
      } else {
        state.omniPlay = {
          currentTicketPayData: action.payload,
        };
      }
    },
    setGameNavWrapperHeight(state, action: PayloadAction<number>) {
      if (isEmpty(action.payload)) return;
      state.gamesNavWrapperHeight = action.payload;
    },
    setNotification(
      state,
      action: PayloadAction<{
        set?: OmniPlayTypes.NotificationMessageData;
        remove?: OmniPlayTypes.NotificationMessageData;
      }>,
    ) {
      const { set, remove } = action.payload;
      if (set) {
        // state.notifications = [...state.notifications, set];
        state.notification = set;
      }
      if (remove) {
        // state.notifications = [...state.notifications.filter((notification) => notification !== remove)];
        state.notification = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retailGamesThunks.fetchRetailGamesProviders.fulfilled, (state, action) => {
      state.providersData = action.payload;
      state.providerStatus = 'succeeded';
      state.hasProvidersFetched = true;
    });
    builder.addCase(retailGamesThunks.fetchRetailGamesProviders.rejected, (state) => {
      state.providersData = [];
      state.providerStatus = 'failure';
      state.hasProvidersFetched = true;
    });
    builder.addCase(retailGamesThunks.fetchRetailGamesProviders.pending, (state) => {
      state.providerStatus = 'pending';
      state.hasProvidersFetched = false;
    });
  },
});

export const {
  setBackBtnVisible,
  setPayTicketStatus,
  setIsPayTicketModalVisible,
  setCurrentTicketPayData,
  setNotification,
  setGameNavWrapperHeight,
} = retailGames.actions;

export default retailGames.reducer;

export const retailGamesSelectors = {
  retailGamesProviders: (state: RootState): RetailGamesTypes.ProvidersData[] | null => state.retailGames.providersData,
  retailGamesProvidersStatus: (state: RootState): Sport.StateStatus => state.retailGames.providerStatus,
  hasProvidersFetched: (state: RootState) => state.retailGames.hasProvidersFetched,
  retailGamesError: (state: RootState) => state.retailGames.hasError,
  gamesNavWrapperHeight: (state: RootState) => state.retailGames.gamesNavWrapperHeight,
  //omniPlay
  showOmniPlayBackBtn: (state: RootState) => !!state.retailGames.omniPlay?.showOmniPlayBackBtn,
  payTicketStatus: (state: RootState) => state.retailGames.omniPlay?.payTicketStatus,
  isPayTicketDataModalVisible: (state: RootState) => !!state.retailGames.omniPlay?.isPayTicketDataModalVisible,
  currentTicketPayData: (state: RootState) => state.retailGames.omniPlay?.currentTicketPayData,
  getNotification: (state: RootState) => state.retailGames.notification,
};
