import { cashierThunks } from 'modules/retail/modules/cashier/slice/cashier.slice';
import { ticketInfoThunk } from 'modules/retail/shared/slice/checkTicket.slice';
import { useAppDispatch } from 'store';
import { getError } from 'utils/common/helpersCommon';
const POLLING_INTERVAL = 1000;

export const useScanCheckTicketHandheld = () => {
  const dispatch = useAppDispatch();
  const isHandheld = window.config.DEVICE_TYPE === 'Handheld';
  const onScan = async () => {
    try {
      await dispatch(cashierThunks.barcodeScanStart({}));

      const pollForResult = async (resolve, reject) => {
        try {
          const result = await dispatch(cashierThunks.getQrCodeCameraResult({})).unwrap();

          if (result && result.barcode) {
            resolve(result);
            await dispatch(ticketInfoThunk.checkTicket({ ticketId: result.barcode }));
            await dispatch(cashierThunks.barcodeScanStop({}));
          } else {
            setTimeout(() => pollForResult(resolve, reject), POLLING_INTERVAL);
          }
        } catch (err) {
          setTimeout(async () => {
            await dispatch(cashierThunks.barcodeScanStop({}));
          }, 1000);

          reject(err);
        }
      };
      await new Promise(pollForResult);
    } catch (err) {
      getError.message(err);
    }
  };
  return { onScan, isHandheld };
};
