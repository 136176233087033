import React from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';
interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  banner: string;
  bgColor?: string;
  children: React.ReactNode;
  className?: string;
}
const Page: React.FC<PageProps> = ({ banner, bgColor, children, className, ...rest }) => {
  const bannerBg = `url(${banner})`;
  const pageClass = clsx('prm-page', className);
  const bgImageStyle = banner && { backgroundImage: bannerBg };
  const bgColorStyle = bgColor && { backgroundColor: bgColor };
  return (
    <Flex.ColAC id="promotion-container" className={pageClass} style={{ ...bgImageStyle, ...bgColorStyle }} {...rest}>
      <div className="prm-page__container">{children}</div>
    </Flex.ColAC>
  );
};
export default Page;
