import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import platformDropdownBackdropReducer from 'components/shared/dropdown/slice/dropdownBackdrop.slice';
import { casinoReducer } from 'modules/casino/store/casinoReducer';
import { gamesReducer } from 'modules/games/slice/games.slice';
import { authReducer } from 'pages/auth/authReducer';
import { captainUpReducer } from 'pages/captainUp/captainUpReducer';
import fantasyScoutReducer from 'pages/fantasyScout/slice/fantasyScout.slice';
import fantasyStarsReducer from 'pages/fantasyStars/slice/fantasyStars.slice';
import helpReducer from 'pages/help/helpSlice';
import liveGamesReducer from 'pages/liveGames/liveGamesSlice';
import { myAccountReducer } from 'pages/myAccount/myAccountReducer';
import playtechPokerReducer from 'pages/playtechPoker/slice/playtechPokerLogin.slice';
import { promotionReducer } from 'pages/promotions/promotionReducer';
import retailGamesReducer from 'pages/retailGames/slice/retailGames.slice';
import svaraAppReducer from 'pages/svaraAppLogin/slice/svaraAppLogin.slice';
import navSearchReducer from 'shared/common/features/appHeader/components/common/slice/navSearch.slice';
import { calendarPreviewReducer } from 'shared/common/features/CalendarPreview/slice/calendarPreview.slice';
import { commonReducer } from 'shared/common/features/commonReducer';
import { backgroundContainerReducer } from 'shared/common/features/gamification/containers/BackgroundComtainer/slice/backgroundContainer.slice';
import { highlightNewFeatureReducer } from 'shared/common/features/highlightNewFeature/slice/highlightNewFeature.slice';
import cashierWidgetReducer from 'shared/common/features/integrations/cashierWidget/cashierWidget.slice';
import { interceptorsReducer } from 'shared/common/features/interceptors/slice/interceptors.slice';
import { myChallengesReducer } from 'shared/common/features/myProfile/sections/tabs/captainUpTab/slice/clubTab.slice';
import offersTabSlice from 'shared/common/features/myProfile/sections/tabs/offersTab/slice/offersTab.slice';
import myProfileReducer from 'shared/common/features/myProfile/slice/myProfile.slice';
import { realityCheckSessionReducer } from 'shared/common/features/realityCheckSession';
import metaDataReducer from 'shared/common/features/seo/metaData/slice/metaData.slice';
import { termsAndConditionsReducer } from 'shared/common/features/termsAndConditions';
import mostPlayedReducer from 'shared/common/features/ticker/slice/mostPlayed.slice';
import tickerReducer from 'shared/common/features/ticker/slice/ticker.slice';
import { tombolaUserTicketsReducer } from 'shared/common/features/tombolaUserTickets/slice/tombolaUserTickets.slice';
import errorLoggerReducer from 'shared/common/sharedSlices/slice/errorLogger.slice';
import { platformApi } from './rtkq/platformCommonRTKQ.slice';
import { retailReducer } from '../modules/retail/store/retailReducer';
import altenarSportsBookReducer from '../modules/sport/altenarSportsBook/slices/altenarSportsBook.slice';

const svaraAppPersistConfig = {
  key: 'svaraApp',
  version: 1,
  storage,
  whitelist: ['openFromSvara', 'openLeaderboard'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  platformDropdownBackdrop: platformDropdownBackdropReducer,
  helpMenu: helpReducer,
  mostPlayed: mostPlayedReducer,
  myAccount: myAccountReducer,
  myProfile: myProfileReducer,
  offersTab: offersTabSlice,
  promotion: promotionReducer,
  ticker: tickerReducer,
  liveGames: liveGamesReducer,
  metaData: metaDataReducer,
  navSearch: navSearchReducer,
  common: commonReducer,
  casino: casinoReducer,
  interceptors: interceptorsReducer,
  errorLogger: errorLoggerReducer,
  captainUp: captainUpReducer,
  highlightNewFeature: highlightNewFeatureReducer,
  cashierWidget: cashierWidgetReducer,
  myChallenges: myChallengesReducer,
  fantasyScout: fantasyScoutReducer,
  fantasyStars: fantasyStarsReducer,
  playtechPoker: playtechPokerReducer,
  backgroundContainer: backgroundContainerReducer,
  calendarPreview: calendarPreviewReducer,
  realityCheckSession: realityCheckSessionReducer,
  retail: retailReducer,
  termsAndConditionsModal: termsAndConditionsReducer,
  svaraApp: persistReducer(svaraAppPersistConfig, svaraAppReducer),
  tombolaUserTickets: tombolaUserTicketsReducer,
  altenarSportsBook: altenarSportsBookReducer,
  retailGames: retailGamesReducer,
  games: gamesReducer,
  [platformApi.reducerPath]: platformApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

/**
 * Provides a partial type-safe version of RootState and access to only the specified properties
 * @usage
 *
 * type LiveSportsState = SliceRootState<'liveSports'>;
 *
 * @result
 * type LiveSportsState = {
 *   liveSports: LiveSportsInit;
 * }
 *
 * LiveSportsState has only access to the 'liveSports' property and the type of 'liveSports' is inferred from RootState
 */
export type SliceRootState<K extends keyof RootState> = Pick<RootState, K>;
export default rootReducer;
