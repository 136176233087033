import React from 'react';
import clsx from 'clsx';

/** Footer Column */
interface ColumnProps {
  type?: string;
  children?: React.ReactNode;
}

const Column: React.FC<ColumnProps> = ({ children }) => {
  // col-12 col-md-4 col-lg-auto
  // , `footer-col--${type}`
  const colClass = clsx('footer-col ');

  return <div className={colClass}>{children}</div>;
};

export default Column;
