import React, { memo, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { usePreviousNew } from 'hooks/common/usePreviousNew';
import { selectLanguage } from 'shared/common/features/settings/slice/settings.slice';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { selectMetaData } from './slice/metaData.slice';
import { MetaData } from './types/metaData.types';

const HelmetComponent = () => {
  const language = useAppSelector(selectLanguage);
  const location = useLocation();
  const prevLocation = usePreviousNew(location.pathname);
  const metaData = useAppSelector(selectMetaData.metaData);
  const filterData = isEmpty(metaData)
    ? []
    : metaData.filter((data) => {
        return data.route === location.pathname || data.route === location.pathname + location.search.toLowerCase();
      });
  const allRoutesMetaData = isEmpty(metaData) ? [] : metaData.filter((data) => data.route === 'all routes');
  const filterDataChildRoute = isEmpty(metaData)
    ? []
    : metaData.filter((data) => '/' + location.pathname.split('/')[1] === data.route);
  const matchDataRoute = filterData.length === 0 ? filterDataChildRoute : filterData;

  const filterMap = {
    basicCategory: 'General/Basic HTML meta tags',
    openGraph: 'OpenGraph Meta Tags /Facebook',
    customGeneral: 'Custom General',
    appleMeta: 'Apple Meta Tags',
    twitter: 'Twitter Card Tags',
  };

  const filterTitle = {
    title: 'Title',
  };
  const categories: Record<string, MetaData.Category | undefined> = {};
  Object.entries(filterMap).map(([key, value]) => {
    categories[key] = matchDataRoute[0]?.categories.find((category) => category.category === value);
  });

  const categoriesAllRoutes: Record<string, MetaData.Category | undefined> = {};
  Object.entries(filterMap).map(([key, value]) => {
    categoriesAllRoutes[key] = allRoutesMetaData[0]?.categories.find((category) => category.category === value);
  });

  const titleCategory: Record<string, MetaData.Category | undefined> = {};
  Object.entries(filterTitle).map(([key, value]) => {
    matchDataRoute.length
      ? (titleCategory[key] = matchDataRoute[0]?.categories?.find((category) => category.category === value))
      : (titleCategory[key] = allRoutesMetaData[0]?.categories?.find((category) => category.category === value));
  });

  const compareRouterPaths = () => {
    const meta = document.querySelectorAll(`meta[id]`);
    if (!isEmpty(meta) && location.pathname !== prevLocation) {
      meta.forEach((item) => item.remove());
    }
    const allRoutes = Object.entries(categoriesAllRoutes).map(
      ([_, category]) =>
        category &&
        category.metatags.map((data) =>
          data?.isActive ? <meta name={data.type} content={data?.translations[language]} key={Math.random()} /> : null,
        ),
    );
    const routerPaths = Object.entries(categories).map(([key, category]) => {
      const metaCategory = category ? category : categoriesAllRoutes[key];
      return (
        metaCategory &&
        metaCategory.metatags.map((data, index) => {
          return (
            data?.isActive && (
              <meta
                name={data.type}
                content={
                  data?.translations[language] === ''
                    ? allRoutes.flat().flat()[index]?.props?.content
                    : data?.translations[language]
                }
                key={Math.random()}
                id={index.toString()}
              />
            )
          );
        })
      );
    });
    return routerPaths;
  };

  const removeHelmetAttribute = useCallback(() => {
    const meta = document.querySelectorAll(`meta[data-rh="true"]`);
    if (!isEmpty(meta)) {
      meta.forEach((item) => {
        item.removeAttribute('data-rh');
      });
    }
  }, []);

  return (
    <Helmet onChangeClientState={removeHelmetAttribute}>
      <title>
        {titleCategory?.title?.metatags?.map((title) =>
          title.translations[language] === undefined ? window.config.BRAND_NAME_LONG : title.translations[language],
        )}
      </title>
      {compareRouterPaths()}
    </Helmet>
  );
};
export default memo(HelmetComponent);
