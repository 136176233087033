import { GameCellRestrictions } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellRestriction/gameCellRestriction.types';
import { GamePlayType } from 'modules/casino/shared/features/gameLaunch/casinoLaunchTypes';
import { CreateThunk } from 'store/thunkCreators';
import { GamificationUser } from '../gamification/types/gamification.types';
export declare module Integrations {
  type State = {
    isScriptLoaded: Partial<Record<IntegrationName, boolean>>;
    token: Partial<Record<IntegrationName, string | null>>;
    sport: {
      betSlipQrCode: string;
      dailySequenceNumber?: string;
      terminalBetPin?: string;
      isWidgetLoaded: boolean;
      posAddress?: { posAddress: string; streetName: string; streetNumber: string; country: string };
      posName?: string;
    };
    smartico: null | { userCoins: number };
    configurator: null | { skin: string };
    casino: CasinoWidgetParams;
    gamification: {
      isWidgetLoaded: null | boolean;
      profile: GamificationUser.Profile | null;
      myChallengesCount: number;
      myGameChallenges: {
        biggestPercentage: number;
        challengeUpTo100: boolean;
      };
    };
  };

  type CasinoWidgetParams = {
    isWidgetLoaded: boolean;
    isSubWidgetLoaded: Record<string, boolean>;
    favGamesCounter: number;
    relaunchStatus: number | null;
    enableColorIcons: boolean;
    refetchBonusGames: boolean;
    launchedGameData?: StartGameProps;
  };

  type StartGameProps = {
    id?: string;
    description?: string;
    refGameId?: string;
    providerId?: string;
    providerAlias?: string;
    gamePlayType?: GamePlayType;
    betType?: GameCellRestrictions.ProductType;
    gameType?: string;
    device?: string;
    isTrackable?: boolean;
  };

  type IntegrationName = 'sportsBook' | 'smartico' | 'cashier' | 'casinoWidget';

  type Thunks = {
    generateToken: CreateThunk<IntegrationName, { token: string }>;
  };
}

export enum IntegrationType {
  SPORTS_BOOK = 'sportsBook',
  SMARTICO = 'smartico',
  CASHIER = 'cashier',
  CASINO_WIDGET = 'casinoWidget',
  GAMIFICATION = 'gamification',
}

export enum SportsBookType {
  EGTD = 'egtd',
  ALTENAR = 'altenar',
}
