import { cdnConfig, fitCover } from 'utils/common/cdnConfig';
import config from 'utils/config';
import { NotificationTypes } from '../../../../myProfile/sections/tabs/notificationsTab/notificationTypes';

export const randomReel = () => {
  const reels = [1, 2, 3, 4, 5, 6];
  const reelsLength = 3;
  const randReels: number[] = [];
  do {
    randReels[randReels.length] = reels.splice(Math.floor(Math.random() * reels.length), 1)[0];
  } while (randReels.length < reelsLength);
  return randReels;
};

export const reelCampaignImages = (folder: NotificationTypes.GamificationFolder) => {
  const path = `${cdnConfig.GAMIFICATION_MODULE}/${folder}`;

  const getRandomReel = randomReel();

  const reel1 = getRandomReel[0];
  const reel2 = getRandomReel[1];
  const reel3 = getRandomReel[2];

  const frameImgSrc = `${config.CDN_IMAGE_API}${fitCover(969 * 2, 392 * 2)}${path}/frame.png`;

  return { frameImgSrc, reel1, reel2, reel3 };
};
