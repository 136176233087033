import React from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';
import { tlbCSSNS } from '../types/tournamentsUI.types';

type Props = {
  firstColText: string;
  secondColText: string;
  thirdColText?: string;
  isHighlighted?: boolean;
  isBold?: boolean;
  noSeparator?: boolean;
  header?: boolean;
};

const TournamentsTableRow: React.FC<Props> = ({
  firstColText,
  secondColText,
  thirdColText,
  isHighlighted,
  isBold,
  noSeparator,
  header,
}) => {
  const rowClass = clsx(
    `${tlbCSSNS}__row`,
    isBold && `${tlbCSSNS}__row--top-wins`,
    isHighlighted && `${tlbCSSNS}__row--highlighted`,
    header && `${tlbCSSNS}__row--header`,
  );
  return (
    <>
      {!noSeparator && <div className={`${tlbCSSNS}__row--separator`}></div>}
      <Flex className={rowClass}>
        <div className={`${tlbCSSNS}__cell`}>{firstColText}</div>
        <div className={`${tlbCSSNS}__cell`}>{secondColText}</div>
        {thirdColText && <div className={`${tlbCSSNS}__cell`}>{thirdColText}</div>}
      </Flex>
    </>
  );
};

export default TournamentsTableRow;
