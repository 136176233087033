import React from 'react';
import { memo } from 'react';
import { JackpotOdometer } from 'modules/casino/shared/features/jackpots/jackpotOdometer';
import { jackpotSelectors } from 'modules/casino/shared/features/jackpots/jackpotSlice';
import { useAppSelector } from 'store';

const addAttributes = (attributes: Record<string, string>, element: HTMLElement) => {
  return Object.keys(attributes).map((key) => {
    let alternativeKey;
    if (key === 'className') alternativeKey = 'class';
    element.setAttribute(alternativeKey ? alternativeKey : key, attributes[key]);
  });
};

const ItemComponent = ({ tickerTypeId, items, jackpotId }) => {
  const jackpotCurrency = useAppSelector((state) => jackpotSelectors.selectJackpotCurrencySign(state, jackpotId));

  return (
    <>
      {items &&
        items.map((item, key) => {
          const itemKeys = Object.keys(item);
          // console.log('Velues: ', Object.values(item));
          // replace casino(jackpot) atributes
          if (jackpotId && item.type === 'response') {
            const innerItems = item?.items.map(() => ({
              tag: 'span',
              text: jackpotCurrency,
              attributes: { className: 'ticker-item__currency' },
            }));
            item = {
              ...item,
              jackpotId,
              items: innerItems,
            };
          }
          return (
            <item.tag
              key={key}
              ref={(node) => {
                if (node) {
                  if (item?.tag === 'svg' && itemKeys?.includes('imgRef')) {
                    const iconsByTickerTypeId = {
                      1: 'undefined', // Theme.icons.sports,
                      2: 'undefined', // Theme.icons.sports,
                      3: 'undefined', // Theme.icons.casinoMenus,
                      4: 'undefined', // Theme.icons.casinoMenus,
                    };
                    const useElement = document.createElementNS('http://www.w3.org/2000/svg', 'use');
                    useElement.setAttributeNS(
                      'http://www.w3.org/1999/xlink',
                      'href',
                      `${iconsByTickerTypeId[tickerTypeId]}#${item.imgRef}`,
                    );

                    node?.appendChild(useElement);
                  }
                  item?.attributes && node && addAttributes(item?.attributes, node);
                }
              }}
            >
              {item?.text && `${item.text}`}
              {jackpotId && item.type === 'response' && (
                <JackpotOdometer level={item.highestLevel} jackpotId={jackpotId} isTicker={true} />
              )}
              {item.items && <ItemComponent tickerTypeId={tickerTypeId} items={item.items} jackpotId={jackpotId} />}
            </item.tag>
          );
        })}
    </>
  );
};

export default memo(ItemComponent);
