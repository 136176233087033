import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { CAPTAIN_UP_ROUTE } from 'pages/captainUp/utils/captainUp.utils';
import { setLanguage } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { isEmpty, sortByRank } from 'utils/common/helpersCommon';
import { ContainerTypeNames } from 'utils/common/types/common.types';
import config from 'utils/config';
import { FooterTypes } from '../../appFooter/types/appFooter.types';
import { DrawerTypes } from '../../drawer/types/drawer.types';
import { LayoutConfigTypes, VerticalsConfigTypes } from '../../general/types/generalSlice.types';
import {
  BurgerMenuLayout02,
  Container,
  NavigationMenuState,
  NavigationMenuThunks,
  Vertical,
  verticalIdsmap,
} from '../types/navigationMenu.types';

const initialState: NavigationMenuState = {
  verticals: null,
  verticalsAll: null,
  menuItems: [],
  containersByVertical: {},
  containersByVerticalId: {},
  verticalsStatus: 'idle',
  homePageVertical: null,
  menuItemsLayout02: null,
  burgerMenuLayout02: null,
  footer: {},
};

export const navigationMenuThunks: NavigationMenuThunks = {
  fetchVerticals: createAbortThunk('fetchVerticals', async (_, { source }) => {
    const response = await axiosInstance.get(`${config.API_URL}/api/cms/public/verticals/ui`, {
      cancelToken: source.token,
    });
    return response.data;
  }),
};

const navigationMenu = createSlice({
  name: 'navigationMenu',
  initialState,
  reducers: {
    setExpandedSection(state, action) {
      if (!isEmpty(state.menuItemsLayout02)) {
        state.menuItemsLayout02[action.payload].isOpened = !state.menuItemsLayout02[action.payload].isOpened;
      }
    },
    clearExpandedSectionState: () => ({
      ...initialState,
    }),
    setExpandedSections(state, action: PayloadAction<{ sectionId: number; isOpened: boolean }>) {
      if (!isEmpty(state.menuItemsLayout02)) {
        state.menuItemsLayout02[action.payload.sectionId].isOpened = action.payload.isOpened;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setLanguage, (state) => {
        state.verticals = null;
        state.verticalsStatus = 'idle';
      })
      .addCase(navigationMenuThunks.fetchVerticals.fulfilled, (state, action) => {
        state.verticals = action.payload.verticals?.filter((item) => !item.isHomePage);
        state.verticalsAll = action.payload.verticals;
        state.homePageVertical = action.payload.verticals?.find((item) => item.isHomePage) || null;
        state.menuItems = sortByRank([...(action.payload?.menuItems ? action.payload.menuItems : [])]);
        state.verticalsStatus = 'succeeded';
        state.burgerMenuLayout02 = action.payload.breadcrumbMenu;
        state.menuItemsLayout02 = sortByRank([
          ...(!isEmpty(state.burgerMenuLayout02) ? state.burgerMenuLayout02.breadcrumbMenuItem : []),
        ]);

        state.menuItemsLayout02 = !isEmpty(state.burgerMenuLayout02)
          ? state.burgerMenuLayout02.breadcrumbMenuItem
              ?.filter(
                (item) =>
                  item?.type !== 'socialmedia' &&
                  item?.type !== 'copyright' &&
                  item?.type !== 'help' &&
                  item?.type !== 'systemsettings',
              )
              ?.map((items) => {
                return { ...items, isOpened: false };
              })
          : null;

        const helperObject = state.burgerMenuLayout02?.breadcrumbMenuItem?.find((item) => item?.type === 'help');
        const systemSettingsObject = state.burgerMenuLayout02?.breadcrumbMenuItem?.find(
          (item) => item?.type === 'systemsettings',
        );
        if (!isEmpty(helperObject) && !isEmpty(state.menuItemsLayout02)) {
          state.menuItemsLayout02.push(helperObject);
        }
        if (
          !isEmpty(systemSettingsObject) &&
          !isEmpty(state.menuItemsLayout02) &&
          state.burgerMenuLayout02?.layout === 'layout_2'
        ) {
          state.menuItemsLayout02.push(systemSettingsObject);
        }

        state.footer = action.payload.footer;
        action.payload.verticals.forEach((vertical) => {
          if (verticalIdsmap[vertical.id]) {
            const verticalKey = verticalIdsmap[vertical.id];
            state.containersByVertical = {
              ...state.containersByVertical,
              [verticalKey]: [...vertical.containers],
            };
          } else {
            state.containersByVerticalId = {
              ...state.containersByVerticalId,
              [vertical.id]: [...vertical.containers],
            };
          }
        });
      })
      .addCase(navigationMenuThunks.fetchVerticals.pending, (state) => {
        state.verticalsStatus = 'pending';
      });
  },
});

export default navigationMenu.reducer;

export const { setExpandedSection, clearExpandedSectionState, setExpandedSections } = navigationMenu.actions;

export const selectNavigationMenu = {
  containersByVerticalId: (state: RootState, verticalId: number | undefined): Container[] | undefined =>
    verticalId ? state.common.navigationMenu.containersByVerticalId[verticalId] : undefined,
  verticals: (state: RootState): Vertical[] | null => state.common.navigationMenu.verticals,
  allVerticals: (state: RootState): Vertical[] | null => state.common.navigationMenu.verticalsAll,
  homePageVertical: (state: RootState): Vertical | null => state.common.navigationMenu.homePageVertical,
  footerSections: (state: RootState): FooterTypes.Section[] | undefined => state.common.navigationMenu.footer?.sections,
  footerVerticals: (state: RootState): number[] | undefined => state.common.navigationMenu.footer?.verticals,
  menuItems: (state: RootState): DrawerTypes.MenuItem[] => state.common.navigationMenu.menuItems,
  menuItemsLayout02: (state: RootState) => state.common.navigationMenu.menuItemsLayout02,
  burgerMenuLayout02: (state: RootState): BurgerMenuLayout02 | null => state.common.navigationMenu.burgerMenuLayout02,
  copyrightBurgerMenu: (state: RootState): string | undefined =>
    state.common.navigationMenu?.burgerMenuLayout02?.breadcrumbMenuItem?.find((item) => item?.type === 'copyright')
      ?.name,
  navigationLoading: (state: RootState): boolean =>
    state.common.navigationMenu.verticalsStatus === 'idle' || state.common.navigationMenu.verticalsStatus === 'pending',
  activeVertical:
    (locationPath: string) =>
    (state: RootState): Vertical | undefined =>
      state.common.navigationMenu.verticalsAll?.find((vertical: Vertical) => vertical.route === locationPath),
};

export const getBonusBySubType = createSelector(
  selectNavigationMenu.verticals,
  (state: RootState, subType: 'bonusCollection') => subType,
  (
    verticals: Vertical[] | null,
    subType?: 'bonusCollection',
  ): { bonusCollectionApiUrl?: string; bonusRoute?: string } => {
    const bonusVertical = verticals?.find((vertical: Vertical) => vertical.subtype === subType);
    const bonusCollectionContainer = bonusVertical?.containers.find((container) => container.type === 'promo');
    return { bonusCollectionApiUrl: bonusCollectionContainer?.apiUri, bonusRoute: bonusVertical?.route };
  },
);

export const selectCurrVerticalByPath = createSelector(
  selectNavigationMenu.verticals,
  (state: RootState, route?: string) => {
    const configRoute =
      state.common.general?.layoutConfig?.['platform']?.['general']?.[LayoutConfigTypes.VERTICALS]?.[
        VerticalsConfigTypes.GAMIFICATION
      ]?.route || CAPTAIN_UP_ROUTE;
    return { configRoute, route };
  },
  (verticals: Vertical[] | null, { configRoute, route }): Vertical | undefined =>
    verticals?.find((vertical) =>
      [configRoute].includes(vertical.route) ? route?.match(vertical.route) : route === vertical.route,
    ),
);

export const selectContainerByType = createSelector(
  selectNavigationMenu.containersByVerticalId,
  (state: RootState, verticalId: number | undefined, type: ContainerTypeNames) => type,
  (containers: Container[] | undefined, type: ContainerTypeNames): Container | undefined =>
    containers?.find((container) => container.type === type),
);

export const selectCurrVerticalContainerByType = (route: string, type: ContainerTypeNames) =>
  createSelector(selectNavigationMenu.allVerticals, (verticals: Vertical[] | null): { container?: Container } => {
    const locationVertical = verticals?.find((vertical: Vertical) => vertical.route === route);
    const iframeContainer = locationVertical?.containers.find((container) => container.type === type);
    return { container: iframeContainer };
  });
