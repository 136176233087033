import { useCallback } from 'react';
import { loginThunks } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch } from 'store';
import { hideRootModal } from '../slice/rootModal.slice';

const useHandleModals = (logOuthideModal?: 'logOuthideModal') => {
  const dispatch = useAppDispatch();

  const handleLogout = useCallback(() => {
    logOuthideModal && dispatch(hideRootModal());
    dispatch(loginThunks.logout());
  }, [dispatch, logOuthideModal]);

  const closeModal = useCallback(() => {
    dispatch(hideRootModal());
  }, [dispatch]);

  const refreshPage = useCallback(() => {
    dispatch(hideRootModal());
    window.location.reload();
  }, [dispatch]);

  return { handleLogout, closeModal, refreshPage };
};

export default useHandleModals;
