import React from 'react';

type Props = {
  date: string;
  text: string;
};

const PrizeDropHeaderTimeCounterItem: React.FC<Props> = ({ date, text }) => {
  return <>{`${date}${text.substring(0, 1).toLowerCase()}. `}</>;
};

export default PrizeDropHeaderTimeCounterItem;
