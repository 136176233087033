import { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Flex } from 'components/shared/layout';
import { DataLoader } from 'modules/casino/shared/components/loaders';
import { Modal } from 'modules/casino/shared/components/modal';
import { useAppDispatch } from 'store';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import { GAME_LAUNCH_PREFIX_PLATFORM } from 'utils/config';
import { hideRootCasinoModal } from '../../../rootCasinoModal/rootCasinoModal.slice';
import { useQuickDeposit } from '../../hooks/useQuickDeposit';

const containerId = 'quick-deposit-cashier';

const QuickDepositModal = ({ promotionId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const widgetRef = useRef(null);
  const { pathname } = useLocation();
  const isCasinoGameRoute = pathname.includes(GAME_LAUNCH_PREFIX_PLATFORM);
  const isWidgetLoaded = useQuickDeposit({ containerId, widgetRef, promoId: promotionId });

  useEffect(() => {
    if (!isCasinoGameRoute) {
      // Hide curr quick deposit modal if exit game.
      dispatch(hideRootCasinoModal());
    }
  }, [isCasinoGameRoute, dispatch]);

  const bodyClass = clsx('d-flex-col-ac p-4 text-center custom-scrollbars', !isWidgetLoaded && 'overflow-hidden');

  return (
    <Modal visible className="modal-quick-deposit" size="lg">
      <Modal.Close closeText={t(MyProfile.OffersTab.closeBtn)} closeAction={() => dispatch(hideRootCasinoModal())} />
      <Modal.Body className={bodyClass}>
        {!isWidgetLoaded && (
          <Flex.Center>
            <DataLoader componentLocation="quick-deposit" />
          </Flex.Center>
        )}
        <div ref={widgetRef} id={containerId} />
      </Modal.Body>
    </Modal>
  );
};

export default QuickDepositModal;
