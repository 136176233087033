import { selectVoucherData } from 'modules/retail/shared/slice/voucher.slice';
import { useAppSelector } from 'store';

export const useVoucherData = () => {
  const voucherInfoCashier = useAppSelector(selectVoucherData.voucherInfoCashier);
  const voucherInfoCashierStatus = useAppSelector(selectVoucherData.voucherInfoCashierStatus);
  const payoutVoucherStatus = useAppSelector(selectVoucherData.payoutVoucherStatus);
  const cancelVoucherStatus = useAppSelector(selectVoucherData.cancelVoucherStatus);
  const voucherCashierData = useAppSelector(selectVoucherData.voucherCashier);
  const voucherCashierStatus = useAppSelector(selectVoucherData.voucherCashierStatus);

  return {
    voucherInfoCashier,
    voucherInfoCashierStatus,
    payoutVoucherStatus,
    cancelVoucherStatus,
    voucherCashierData,
    voucherCashierStatus,
  };
};
