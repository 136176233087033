import { memo } from 'react';
import { AppLogo, Text } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { FooterTypes } from '../types/appFooter.types';

interface Props {
  section: FooterTypes.Section;
}

const Legal: React.FC<Props> = ({ section }) => {
  if (!section?.text) {
    return null;
  }

  return (
    <>
      <Flex.ColAC className="w-100">
        <Text type="p" className={`footer-text`} text={section.text} />
        <AppLogo type="footer" />
      </Flex.ColAC>
    </>
  );
};

export default memo(Legal);
