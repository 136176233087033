import { MyBets } from 'pages/myAccount/tabs/history/sections/sportsHistory/types/myBets.types';
import { isEmpty } from 'utils/common/helpersCommon';

import { SportIDs } from 'utils/common/types/sportTypes';

const betslipRaceSports = [SportIDs.greyhound, SportIDs.horse_racing];

const raceStraightMarkets = [100870, 100872];

export const isRaceSport = (sportId: number) => betslipRaceSports.includes(sportId);

export const isRaceStraightMarket = (marketTemplateId: number | string) =>
  raceStraightMarkets.includes(Number(marketTemplateId));

export const defineCampaignType = (
  campaignList: { campaigns: { type: string; percentage: string | number; amount?: string }[] },
  campaignType: string,
): { type: string; percentage: string | number; amount?: string } | null => {
  return (!isEmpty(campaignList) && campaignList.campaigns?.find((y) => y.type === campaignType)) || null;
};

export const defineBonusesType = (
  inputObject: { selectionBonuses: MyBets.SelectionBonusesTypes },
  propertyName: string,
): boolean => {
  const bonuses = inputObject.selectionBonuses;
  return !isEmpty(bonuses) && Object.hasOwn(bonuses, propertyName);
};
