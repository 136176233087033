import React from 'react';
import Icon from 'components/shared/Icon';

interface Props {
  onClickHandler?: () => void;
}

const ButtonDismiss: React.FC<Props> = ({ onClickHandler }) => {
  const cssNS = 'auth-msg';

  return (
    <div className={`${cssNS}__dismiss-btn`} onClick={onClickHandler}>
      <Icon className={`${cssNS}__dismiss-icon`} icon={`times`} />
    </div>
  );
};

export default ButtonDismiss;
