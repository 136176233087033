import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { CasinoSearchTypes } from './casinoSearchTypes';
import { RequestStatuses } from '../../constants';

const initialState: CasinoSearchTypes.State = {
  casinoSearchValue: '',
  headerSearchIsActive: false,
  providerSearchIsActive: false,
  categorySearchIsActive: false,
  isCategoryLoading: false,
  resultLoadingState: RequestStatuses.INITIAL,
  casinoSearchGamesCount: 0,
};

const casinoSearch = createSlice({
  name: 'casinoSearch',
  initialState,
  reducers: {
    setCasinoSearchValue(state, action) {
      state.casinoSearchValue = action.payload;
    },
    setHeaderSearchIsActive(state, action) {
      state.headerSearchIsActive = action.payload;
    },
    setProviderSearchIsActive(state, action) {
      state.providerSearchIsActive = action.payload;
    },
    setCategorySearchIsActive(state, action) {
      state.categorySearchIsActive = action.payload;
    },
    resetCasinoSearch: (state) => {
      // Reset only results state!
      state.headerSearchIsActive = false;
      state.categorySearchIsActive = false;
      state.providerSearchIsActive = false;
      state.resultLoadingState = RequestStatuses.INITIAL;
    },
  },
});

export const {
  setCasinoSearchValue,
  setHeaderSearchIsActive,
  setCategorySearchIsActive,
  setProviderSearchIsActive,
  resetCasinoSearch,
} = casinoSearch.actions;

const selectCasinoSearchValue = (state: RootState): string => state.casino.casinoSearch.casinoSearchValue;
const selectHeaderSearchIsActive = (state: RootState): boolean => state.casino.casinoSearch.headerSearchIsActive;
const selectCategorySearchIsActive = (state: RootState): boolean => state.casino.casinoSearch.categorySearchIsActive;
const selectProviderSearchIsActive = (state: RootState): boolean => state.casino.casinoSearch.providerSearchIsActive;
const selectCasinoSearchResultLoadingState = (state: RootState): RequestStatuses =>
  state.casino.casinoSearch.resultLoadingState;
const selectCasinoSearchGamesCount = (state: RootState): number => state.casino.casinoSearch.casinoSearchGamesCount;

export const casinoSearchSelectors = {
  casinoSearchValue: selectCasinoSearchValue,
  headerSearchIsActive: selectHeaderSearchIsActive,
  categorySearchIsActive: selectCategorySearchIsActive,
  providerSearchIsActive: selectProviderSearchIsActive,
  casinoSearchResultLoadingState: selectCasinoSearchResultLoadingState,
  casinoSearchGamesCount: selectCasinoSearchGamesCount,
};

export default casinoSearch.reducer;
