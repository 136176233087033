import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { RootState } from 'store/rootReducer';
import { createAbortThunk, CreateThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { isEmpty } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { PlayerTournaments, PAST_PERIODS_RANKING, PLAYER_TOURNAMENTS_RANKINGS, Tournaments } from './leaderBoardTypes';

const initialState: Tournaments.GridState = {
  error: null,
  data: null,
  status: 'idle',
  currentEvent: null,
};

type LeaderBoardThunk = {
  fetchPlayerTournaments: CreateThunk<
    { tournamentId: number | undefined },
    Tournaments.GetPlayerRankingResponse,
    string
  >;
};

export const leaderBoardPlayerThunk: LeaderBoardThunk = {
  fetchPlayerTournaments: createAbortThunk(
    'leaderBoard/fetchPlayerTournaments',
    async ({ tournamentId }, { source, getState }) => {
      const playerId: string | undefined = selectMyProfile.playerId(getState());
      const response = await axiosInstance.get<Tournaments.GetPlayerRankingResponse>(
        `${config.API_URL}/api/player-tournaments/public/player-tournaments/get-players-points/${tournamentId}`,
        {
          params: { playerId },
          cancelToken: source.token,
        },
      );
      return response.data;
    },
    {},
    (error, rejectWithValue) => rejectWithValue(error?.response.data),
  ),
};

const { fetchPlayerTournaments } = leaderBoardPlayerThunk;

const leaderBoardSlice = createSlice({
  name: 'leaderBoard',
  initialState,
  reducers: {
    clearTournamentsState: () => initialState,
    setCurrentEvent: (state, action: PayloadAction<Tournaments.EventRaking>) => {
      state.currentEvent = action.payload;
    },
    resetCurrentEvent: (state) => {
      state.currentEvent = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlayerTournaments.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchPlayerTournaments.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchPlayerTournaments.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = 'success';
      });
  },
});

export const { clearTournamentsState, setCurrentEvent, resetCurrentEvent } = leaderBoardSlice.actions;

export const selectFilteredTournamentsData = createSelector(
  (state: RootState) => state.promotion.tournaments.data || {},
  (tournaments) =>
    Object.entries(tournaments)
      .reduce((acc: Tournaments.PlayerRankingMap[], playerRanking) => {
        if (playerRanking[0] === PlayerTournaments.EVENT_RANKINGS) {
          if (isEmpty(playerRanking[1])) {
            return acc;
          }

          acc.push({
            rankingType: playerRanking[0] as PlayerTournaments,
            data: playerRanking[1] as Tournaments.EventRaking[],
          });

          return acc;
        }

        const periods =
          Object.entries(playerRanking[1] as Tournaments.Periods)?.filter(([key]) => key !== 'winningPlayers') || [];
        const labels = periods.find(([key]) => key === 'label');

        const filteredData = periods
          .filter(([key, value]) => !isEmpty(value) && key !== 'label')
          .map(([key, value]) => ({
            periodType: key,
            rankingData: value,
            label: (labels && labels[1] && labels[1][key]) || '',
          }))
          .sort((a, b) => PAST_PERIODS_RANKING[a.periodType].rank - PAST_PERIODS_RANKING[b.periodType].rank);

        if (!isEmpty(filteredData)) {
          acc.push({
            rankingType: playerRanking[0] as PlayerTournaments,
            data: filteredData,
          });
        }

        return acc;
      }, [])
      .sort(
        (a, b) => PLAYER_TOURNAMENTS_RANKINGS[a.rankingType].rank - PLAYER_TOURNAMENTS_RANKINGS[b.rankingType].rank,
      ) || [],
);

export const selectTournaments = {
  data: (state: RootState) => state.promotion.tournaments.data,
  winningPlayers: (rankingType: PlayerTournaments) => (state: RootState) =>
    rankingType !== PlayerTournaments.EVENT_RANKINGS
      ? state.promotion.tournaments.data?.[rankingType]?.winningPlayers
      : undefined,
  error: (state: RootState) => state.promotion.tournaments.error,
  status: (state: RootState) => state.promotion.tournaments.status,
  currentEvent: (state: RootState) => state.promotion.tournaments.currentEvent,
};

export default leaderBoardSlice.reducer;
