import React from 'react';
import { GridLayouts } from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import useWidgetBackground from 'modules/casino/modules/container/widget/widgetTypes/hooks/useWidgetBackground';
import { useAppSelector } from 'store';
import { casinoWidgetsSelectors } from '../../../../slice/casinoWidgetsSlice';
import { CasinoWidgetsTypes, Destinations } from '../../../../types/casinoWidgetsTypes';
import DynamicSlider from '../../components/DynamicSlider';
import LastWinnersCellSkeleton from '../../components/LastWinnersCellSkeleton';
import { LastWinnersWidgetsUI } from '../../components/LastWinnersWidgetsUIComponents';
import { useFetchLastWinners } from '../../hooks/useFetchLastWinners';
import { LAST_WINNERS_PULL_INTERVAL_FALLBACK } from '../../LastWinnersWidgetsConstants';

interface WidgetsProps {
  widget: CasinoWidgetsTypes.Widget;
  layoutGrid: GridLayouts;
}

const LastWinnersSectionWidget: React.FC<WidgetsProps> = ({ widget, layoutGrid }) => {
  const lastWinners = useAppSelector((state) => casinoWidgetsSelectors.filteredLastWinners(state, widget.id));
  const backgroundWidgetResource = widget?.backgroundLocations;
  const type = widget?.template?.type;
  const { backgroundWrapper, setRefs } = useWidgetBackground({ backgroundWidgetResource, type });

  useFetchLastWinners({
    id: widget.id,
    query: widget.query,
    pullInterval: widget.pullInterval,
    fallbackInterval: LAST_WINNERS_PULL_INTERVAL_FALLBACK.SECTION,
  });

  return widget.id && widget.query && lastWinners && (!backgroundWidgetResource || backgroundWrapper) ? (
    <>
      <LastWinnersWidgetsUI.Wrapper
        type={type}
        backgroundUrl={backgroundWrapper}
        columns={widget?.template?.columns}
        wrapperClassName={layoutGrid?.toLowerCase()}
      >
        <div className="cw--left-col">
          {widget?.title && <LastWinnersWidgetsUI.Text description={widget?.title} type={type} />}
        </div>

        {widget.query && (
          <DynamicSlider
            templateType={type}
            lastWinners={lastWinners}
            cdnPathLayout={layoutGrid?.toLowerCase()}
            destination={Destinations.Casino}
            direction={'horizontal'}
            sortByWinTime={true}
          />
        )}
      </LastWinnersWidgetsUI.Wrapper>
    </>
  ) : (
    <LastWinnersCellSkeleton type={type} columns={widget?.template?.columns} setRefs={setRefs} />
  );
};
export default LastWinnersSectionWidget;
