import { useLoadingState } from 'modules/casino/shared/hooks/useLoadingState';
import { useAppSelector } from 'store';
import { casinoLaunchSelectors } from '../../casinoLaunchSlice';

type HookType = () => {
  initialLaunchStatusInitial: boolean;
  initialLaunchStatusLoading: boolean;
  initialLaunchStatusSuccess: boolean;
  initialLaunchStatusError: boolean;
};

export const useInitialLaunchStatus: HookType = () => {
  const loadingState = useAppSelector((state) => casinoLaunchSelectors.launchStatus(state));
  const { hasInitial, isLoading, hasSuccess, hasError } = useLoadingState(loadingState);

  return {
    initialLaunchStatusInitial: hasInitial,
    initialLaunchStatusLoading: isLoading,
    initialLaunchStatusSuccess: hasSuccess,
    initialLaunchStatusError: hasError,
  };
};
