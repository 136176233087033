import React, { HTMLAttributes, memo } from 'react';
import clsx from 'clsx';
import { styled } from 'styled-components';
import { Breakpoints } from 'theme/Theme';
import { NotificationTypes } from '../../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import { constructAnimationImage } from '../../../myProfile/sections/tabs/notificationsTab/notificationUtils';
import './Logo.scss';

interface Props extends HTMLAttributes<HTMLImageElement> {
  className?: string;
  animationParams: NotificationTypes.GamificationModalParams;
}

const LogoNew: React.FC<Props> = ({ className, animationParams, ...rest }) => {
  const { logo, folder } = animationParams;

  const logoImage = constructAnimationImage({ folder, image: logo ?? 'logo.png', width: 422 * 2, height: 160 * 2 });

  const imgClass = clsx('w-100', className);
  return <LogoStyle className={imgClass} src={logoImage} {...rest} />;
};

const LogoStyle = styled.img`
  margin: auto;
  max-height: 127px;
  height: 100%;
  object-fit: contain;
  padding-bottom: 10px;
  max-width: 335px;
  @media (min-width: ${Breakpoints.isMobile}px) {
    max-height: 160px;
    padding-bottom: 20px;
    max-width: 422px;
  }
`;

export default memo(LogoNew);
