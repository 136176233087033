import { RefObject, useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { TFunction } from 'i18n';
import {
  casinoNavigationSelectors,
  setCasinoActiveCategory,
} from 'modules/casino/modules/navigation/shared/slice/casinoNavigationSlice';
import { casinoLaunchSelectors } from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import { useActiveSearchType } from 'shared/common/features/appHeader/components/mainNavbar/hooks/useActiveSearchType';
import { useAppDispatch, useAppSelector } from 'store';
import { CasinoSearchTranslations } from '../casinoSearchConstants';
import {
  casinoSearchSelectors,
  setCasinoSearchValue,
  setCategorySearchIsActive,
  setProviderSearchIsActive,
} from '../casinoSearchSlice';

type ReturnTypeSearch = {
  query;
  handleCloseSearch: () => void;
  handleMobileClear: () => void;
  search: (text?: string) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  t: TFunction;
  searchPlaceholder: string;
};

export const useHandleSearchBar = (inputRef: RefObject<HTMLInputElement>): ReturnTypeSearch => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const providerSearchIsActive = useAppSelector(casinoSearchSelectors.providerSearchIsActive);
  const categorySearchIsActive = useAppSelector(casinoSearchSelectors.categorySearchIsActive);
  const searchPlaceholder = t(CasinoSearchTranslations.placeholder) || '';
  const previousActiveCategory = useAppSelector(casinoNavigationSelectors.previousActiveCategory);
  const isInGameLaunch = useAppSelector(casinoLaunchSelectors.isInGameLaunch);

  useActiveSearchType();
  const url = new URL(window.location.href);
  const query = new URLSearchParams(url.search);
  const queryParam = query.get('query');

  useEffect(() => {
    queryParam && dispatch(setCasinoSearchValue(queryParam));
  }, [queryParam, dispatch]);

  const handleOnChange = (value: string) => {
    const trimmedVal = value.replace(/\s\s+/g, ' ');
    dispatch(setCasinoSearchValue(trimmedVal));

    if (providerSearchIsActive || categorySearchIsActive || isInGameLaunch) return;
    if (!trimmedVal) {
      query.delete('query');
    } else {
      query.delete('query');
      query.append('query', trimmedVal);
    }
    url.search = query.toString();
    window.history.replaceState({}, '', url);
  };

  const search = debounce((text) => {
    handleOnChange(text);
  }, 550);

  const handleCloseSearch = useCallback(() => {
    if (location.key === 'default') {
      navigate('/casino');
      return;
    }
    if (providerSearchIsActive) {
      dispatch(setProviderSearchIsActive(false));
      return;
    }
    if (categorySearchIsActive) {
      dispatch(setCategorySearchIsActive(false));
      dispatch(setCasinoActiveCategory(previousActiveCategory));
      return;
    }
    navigate(-1);
  }, [location.key, navigate, dispatch, providerSearchIsActive, categorySearchIsActive, previousActiveCategory]);

  const handleMobileClear = useCallback(() => {
    dispatch(setCasinoSearchValue(''));
    if (inputRef.current) inputRef.current.value = '';
  }, [dispatch, inputRef]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLElement).blur();
    }
  }, []);

  return {
    query,
    handleCloseSearch,
    handleMobileClear,
    handleKeyDown,
    search,
    t,
    searchPlaceholder,
  };
};
