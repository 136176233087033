import React from 'react';
import clsx from 'clsx';
import { useWindowDimensions } from 'hooks/useWindowDimenions';

/** Terminal Modal */
interface WrapperProps {
  children: React.ReactNode;
  isVisible?: boolean;
  isTicket?: boolean;
  checkTicket?: boolean;
}

const Wrapper: React.FC<WrapperProps> = ({ isVisible, children, isTicket, checkTicket }) => {
  const modalClass = clsx(
    'modal fade',
    isVisible && 'show d-block',
    isTicket && 'terminal-modal--ticket',
    checkTicket && 'terminal-modal__check-ticket',
  );
  const modalDialogClass = clsx('modal-dialog', 'terminal-modal__dialog');
  const modalContentClass = clsx('modal-content terminal-modal__content');
  const { height } = useWindowDimensions();
  const modalHeight = { height: `${height}px` };

  return (
    <div className={modalClass} tabIndex={-1} role="dialog" style={isTicket ? modalHeight : {}}>
      <div className="modal-backdrop">
        <div className={modalDialogClass} role="document">
          <div className={modalContentClass}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Wrapper;
