import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';

const RetailHeaderLiveText = () => {
  const { t } = useTranslation();
  return (
    <Flex.Center className="r-header__live-text">
      <span>{t(CashierTranslations.Header.live)}</span>
    </Flex.Center>
  );
};

export default memo(RetailHeaderLiveText);
