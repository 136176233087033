import { useEffect } from 'react';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { printCashPayWithdraw } from 'modules/retail/modules/cashier/printMethods/cashPayPrint.utils';
import { selectCashierData } from 'modules/retail/modules/cashier/slice/cashier.slice';

import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { useAppDispatch, useAppSelector } from 'store';
import { clearWithdraw, selectCashPay } from '../../../slice/cashPay.slice';

export const useWithdraw = () => {
  const verifyWithdraw = useAppSelector(selectCashPay.verifyWithdraw);
  const verifyWithdrawStatus = useAppSelector(selectCashPay.verifyWithdrawStatus);
  const withdrawComplete = useAppSelector(selectCashPay.withdrawComplete);
  const withdrawStatus = useAppSelector(selectCashPay.withdrawStatus);
  const posInfo = useAppSelector(selectCashierData.posInfo);
  const posAddress = posInfo?.posInfo.address;
  const numOfCopies = 2;
  const retailAddress = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.RETAIL_ADDRESS,
    deviceLayout: 'general',
  });
  const isCurrencyBeforeAmount = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.CURRENCY_BEFORE_AMOUNT,
    deviceLayout: 'general',
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearWithdraw());
    };
  }, []);

  useEffect(() => {
    if (withdrawComplete?.success && verifyWithdraw?.success) {
      const printCashPayWithdrawObj = {
        data: verifyWithdraw?.cashPayQRCheckWithdrawalDto,
        address: retailAddress,
        posAddress,
        transferId: withdrawComplete?.transferId,
        numOfCopies,
        isCurrencyBeforeAmount,
      };
      printCashPayWithdraw(printCashPayWithdrawObj);

      dispatch(clearWithdraw());
    }
  }, [withdrawComplete]);

  return { verifyWithdraw, verifyWithdrawStatus, withdrawComplete, withdrawStatus };
};
