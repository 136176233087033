import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setLanguage } from 'shared/common/sharedSlices/commonActions';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import config from 'utils/config';
import { HelpTypes, noActiveHelpCategory, RequestStatuses, SectionType } from './helpTypes';

export const helpThunks: HelpTypes.ModuleThunks = {
  fetchHelpItems: createAbortThunk('help/fetchHelpItems', async ({ route }, { source }) => {
    const fetchURL = `${config.API_URL}/api/cms/public/help`;
    const response = await axiosInstance.get<HelpTypes.ItemsResponseType>(fetchURL, {
      data: null,
      cancelToken: source.token,
    });
    const initialCategoryId = getInitialCategoryId(response.data.HelpMenu, route);

    return { ...response.data, initialCategoryId };
  }),
  fetchGamesInfo: createAbortThunk('help/fetchGamesInfo', async (_, { source }) => {
    const fetchURL = `${config.API_URL}/api/gaming/public/games`;
    const response = await axiosInstance.get<HelpTypes.GameInfo[]>(fetchURL, { data: null, cancelToken: source.token });

    const transformedArray: Record<string, HelpTypes.GameInfo[]> = response.data.reduce((next, current) => {
      if (next[current.providerCode]) {
        next[current.providerCode].push(current);
      } else {
        next[current.providerCode] = [current];
      }
      return next;
    }, {});

    return transformedArray;
  }),
};
const initialState: HelpTypes.InitialState = {
  helpMenus: [],
  helpSections: {},
  isLoading: true,
  activeTab: 0,
  activeCategoryId: noActiveHelpCategory,
  gamesInfo: {},
  status: null,
  isOpenChat: false,
};
const helpMenu = createSlice({
  name: 'helpMenu',
  initialState,
  reducers: {
    setActiveTab(state, action: PayloadAction<number>) {
      state.activeTab = action.payload;
    },
    setIsOpenChat(state, action: PayloadAction<boolean>) {
      state.isOpenChat = action.payload;
    },
    setActiveCategoryId(state, action: PayloadAction<string>) {
      state.activeCategoryId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setLanguage, () => {
      return initialState;
    });
    builder
      .addCase(helpThunks.fetchHelpItems.fulfilled, (state, action) => {
        const { HelpMenu, HelpSections, initialCategoryId } = action.payload;
        state.helpMenus = HelpMenu;
        state.helpSections = HelpSections;
        state.isLoading = false;
        if (initialCategoryId) {
          state.activeCategoryId = initialCategoryId;
        }
      })
      .addCase(helpThunks.fetchHelpItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(helpThunks.fetchHelpItems.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(helpThunks.fetchGamesInfo.fulfilled, (state, action) => {
        state.gamesInfo = action.payload;
        state.status = RequestStatuses.SUCCESS;
      })
      .addCase(helpThunks.fetchGamesInfo.pending, (state) => {
        state.status = RequestStatuses.PENDING;
      })
      .addCase(helpThunks.fetchGamesInfo.rejected, (state) => {
        state.status = RequestStatuses.ERROR;
      });
  },
});
export const { setActiveCategoryId, setActiveTab, setIsOpenChat } = helpMenu.actions;

export const selectHelpMenus = (state: HelpTypes.MenuState) => state.helpMenu.helpMenus;
export const selectHelpSections = (state: HelpTypes.MenuState) => state.helpMenu.helpSections;

export const selectHelp = {
  activeCategoryId: (state: HelpTypes.MenuState) => state.helpMenu.activeCategoryId,
  activeTab: (state: HelpTypes.MenuState) => state.helpMenu.activeTab,
  fetchGamesInfoStatus: (state: HelpTypes.MenuState) => state.helpMenu.status,
  itemsIsLoading: (state: HelpTypes.MenuState) => state.helpMenu.isLoading,
  gamesInfo: (state: HelpTypes.MenuState) => state.helpMenu.gamesInfo,
  sectionByType: (type?: SectionType) => (state: HelpTypes.MenuState) => {
    let id;
    if (type) {
      id = Object.keys(state.helpMenu.helpSections).find((sectionKey) => {
        return state.helpMenu.helpSections[sectionKey].type === type;
      });
    }
    return id ? state.helpMenu.helpSections[id] : undefined;
  },
  menu: (menuRoute: string) =>
    createSelector(selectHelpMenus, (helpMenus) => {
      if (helpMenus.length !== 0) {
        return helpMenus.find((item) => {
          const route = item.route?.startsWith('/') ? item.route : `/${item.route}`;
          return route === menuRoute;
        });
      }
    }),
  section: (id: string) =>
    createSelector(selectHelpSections, (helpSections) => {
      return helpSections[id];
    }),
};

const getInitialCategoryId = (helpMenus: HelpTypes.MenuType[] = [], route?: string): string | undefined => {
  if (route) {
    const menuWithRoute = helpMenus.find((menu) => {
      const menuRoute = menu.route?.startsWith('/') ? menu.route : `/${menu.route}`;

      return menuRoute === route;
    });
    if (menuWithRoute) {
      return String(menuWithRoute.id);
    }
  }
};
export default helpMenu.reducer;
