import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { ButtonProps, ButtonContentProps, btnIconSizesMap, cssBtnNS } from './Button.types';
import { Icon } from '../../icons';
import './Button.scss';

/** EGTD Casino Button **/
const ButtonContent: React.FC<ButtonContentProps> = ({ text, size = 'md', icon, iconClassName }) => {
  const iconClass = clsx(`${cssBtnNS}__icon`, iconClassName);
  const iconSize = btnIconSizesMap[size];

  return (
    <>
      {text && text}
      {icon && <Icon defaultClassName={false} className={iconClass} size={iconSize} icon={icon} />}
    </>
  );
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps & HTMLAttributes<HTMLButtonElement>>(
  (
    {
      type = 'button',
      variant = 'primary',
      size = 'md',
      className,
      iconClassName,
      text,
      icon,
      children,
      // style,
      // dataQid,
      isActive,
      isDisabled,
      onClickHandler,
      ...rest
    },
    ref,
  ) => {
    const classProp = { className: clsx(`${cssBtnNS}`, className || null) };
    const hasIconOnlyProp = !text ? { 'is-icon': 'true' } : undefined;
    const activeProp = isActive ? { 'is-active': isActive } : undefined;
    const disabledProp = isDisabled ? { 'is-disabled': 'true' } : undefined;
    return (
      <button
        ref={ref}
        c-type={type}
        c-variant={variant}
        c-size={size}
        {...hasIconOnlyProp}
        {...classProp}
        {...activeProp}
        {...disabledProp}
        {...rest}
        onClick={onClickHandler}
      >
        {children ? (
          <div className="d-flex-ac">
            <ButtonContent size={size} text={text} icon={icon} iconClassName={iconClassName} />
            {children}
          </div>
        ) : (
          <ButtonContent size={size} text={text} icon={icon} iconClassName={iconClassName} />
        )}
      </button>
    );
  },
);

Button.displayName = 'EGTD Casino Button';

export default Button;
