import { FC, useEffect, useState } from 'react';
import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';
import { CasinoScroll } from '../casinoScrollTypes';
import { centerScroll, handleShowArrows } from '../casinoScrollUtils';

const ScrollArrows: FC<CasinoScroll.ScrollArrowsProps> = ({
  centered,
  scrollContainerRef,
  typeArrow,
  useDefaultChevron,
  moveWith,
  inView,
  forceArrowsUpdate,
}) => {
  const [showLeft, setShowLeftArrow] = useState(false);
  const [showRight, setShowRightArrow] = useState(false);
  const scrollWrapper = scrollContainerRef.current;
  const scrollEl = scrollWrapper?.firstElementChild as HTMLElement;

  const updateArrows = () => {
    const { showLeftArrow, showRightArrow } = handleShowArrows(scrollEl);
    setShowLeftArrow(showLeftArrow);
    setShowRightArrow(showRightArrow);
  };

  const ro = new ResizeObserver(() => {
    updateArrows();
  });

  useEffect(() => {
    scrollWrapper && ro.observe(scrollWrapper);
    centered && centerScroll(scrollEl);
    const onScroll = () => {
      updateArrows();
    };
    scrollEl.addEventListener('scroll', onScroll);
    return () => {
      scrollWrapper && ro.unobserve(scrollWrapper);
      scrollEl.removeEventListener('scroll', onScroll);
    };
  }, [scrollEl]);

  useEffect(() => {
    if (inView) {
      updateArrows();
    }
  }, [inView, forceArrowsUpdate]);

  return (
    <>
      {showLeft && (
        <LeftArrow
          scrollEl={scrollEl}
          typeArrow={typeArrow}
          useDefaultChevron={useDefaultChevron}
          moveWith={moveWith}
        />
      )}
      {showRight && (
        <RightArrow
          scrollEl={scrollEl}
          typeArrow={typeArrow}
          useDefaultChevron={useDefaultChevron}
          moveWith={moveWith}
        />
      )}
    </>
  );
};

export default ScrollArrows;
