import { useNavigate } from 'react-router-dom';
import useContainerFetch from 'hooks/useContainerFetch';
import { DrawerThunks, selectDrawer, setDrawer } from 'shared/common/features/drawer/slice/drawer.slice';
import { DrawerTypes } from 'shared/common/features/drawer/types/drawer.types';
import { navigateToIntegration } from 'shared/common/features/integrations/utils/integrations.utils';
import { useAppDispatch } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { LobbyFetchType } from 'utils/common/types/sportTypes';

interface Props {
  apiUrl: string;
  containerId: number;
  inDrawer?: boolean;
}

interface ReturnedProps {
  navigateToLink: (parsedParams: DrawerTypes.LinkParams | undefined, navigationUrl: string) => void;
  container: DrawerTypes.Container;
  isLoading: boolean;
}

export const useContainerLinks = ({ apiUrl, containerId, inDrawer }: Props): ReturnedProps => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const slice = { thunk: DrawerThunks, selector: selectDrawer };
  const { container, isLoading }: LobbyFetchType<DrawerTypes.Container> = useContainerFetch({
    component: 'DrawerContainers',
    slice,
    apiUri: apiUrl,
    containerId,
  });

  const navigateToLink = (parsedParams: DrawerTypes.LinkParams | undefined, navigationUrl: string) => {
    if (parsedParams) {
      if (isEmpty(parsedParams.upcoming)) {
        navigate(`${navigationUrl}`, { state: { sportName: parsedParams?.sportName?.name } });
      } else {
        navigate(`${navigationUrl}`, { state: { ...parsedParams.upcoming, sportName: parsedParams?.sportName?.name } });
      }
    } else {
      navigate(`${navigationUrl}`);
    }
    navigateToIntegration({ fullPath: navigationUrl });
    inDrawer && dispatch(setDrawer(false));
  };

  return { navigateToLink, container, isLoading };
};
