import React, { memo, useCallback, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Drawer } from 'utils/common/translationUtils/translationStrings';
import useDrawerConfig from '../../hooks/useDrawerConfig';
import useHandleDrawerSportClick from '../../hooks/useHandleDrawerSportClick';
import { SportsType } from '../../types/drawer.types';
import { DrawerUI } from '../DrawerUI';

const VirtualExpandableRow: React.FC = () => {
  const { t } = useTranslation();
  const { virtualSection: virtualMenu } = useDrawerConfig();
  const { handleSportClick } = useHandleDrawerSportClick();
  const [isExpanded, setIsExpanded] = useState(true);
  const location = useLocation();

  const onClick = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const contentClass = clsx(isExpanded ? '' : 'hidden');

  return (
    <>
      <DrawerUI.Link
        onClick={onClick}
        isExpandable
        isActive={isExpanded}
        text={t(Drawer.DrawerLinks.virtualProviderText)}
      />
      <div className={contentClass}>
        {virtualMenu?.map((item) => {
          return (
            <DrawerUI.Link
              key={item.id}
              onClick={() =>
                handleSportClick({
                  sportType: SportsType.Virtuals,
                  sportId: item.sportId,
                  fullPath: item.route,
                })
              }
              isActive={location.pathname.includes(item.route)}
              text={t(Drawer.DrawerLinks[`${item.translation}`])}
              logo={item.code}
              dataQid={item.route}
            />
          );
        })}
      </div>
    </>
  );
};

export default memo(VirtualExpandableRow);
