import React from 'react';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { selectIntegrations } from 'shared/common/features/integrations/slice/integrations.slice';
import { useAppSelector } from 'store';
import usePlayerRestrictions from '../../../hooks/common/usePlayerRestrictions';
import { selectAuthentication } from '../../../pages/auth/login/slice/login.slice';
import { isEmpty } from '../../../utils/common/helpersCommon';
import CasinoLaunchImplementation from '../platformImplementation/casinoWidgetTypes/CasinoLaunchImplementation';
import { GamePlayType, ProviderLaunchAgents } from '../shared/features/gameLaunch/casinoLaunchTypes';
import { NewTopBar } from '../shared/features/gameLaunchBonus';
import useBonusGamesFetch from '../shared/features/gameLaunchBonus/hooks/useBonusGamesFetch';
import { useTopBarButtonsConfig } from '../shared/features/gameLaunchBonus/hooks/useTopBarButtonsConfig';
import { InGameModal } from '../shared/features/rootCasinoModal';

const GameLaunchNew = () => {
  const { configTopBarButtons } = useTopBarButtonsConfig();
  const gamePlayType = useAppSelector(selectIntegrations.casinoWidgetGameData).gamePlayType;
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const isPaidGame = gamePlayType === GamePlayType.PAID ? 'true' : 'false';
  const showNewTopBar =
    gamePlayType && (!isEmpty(configTopBarButtons?.pinned) || !isEmpty(configTopBarButtons?.scrollable));
  useBonusGamesFetch(isAuthenticated, isPaidGame);
  const classes = clsx(
    'egtd-game-launch-container',
    isMobile ? 'egtd-game-launch-container--mobile' : 'egtd-game-launch-container--desktop',
  );
  const widgetGameData = useAppSelector(selectIntegrations.casinoWidgetGameData);
  const { hasRestrictions } = usePlayerRestrictions({
    products: widgetGameData?.betType ? [widgetGameData?.betType] : [],
    showModal: false,
  });

  return (
    <div className={classes}>
      <CasinoLaunchImplementation />
      {showNewTopBar && !hasRestrictions && (
        <NewTopBar type={ProviderLaunchAgents.desktop} isFreePlay={gamePlayType === GamePlayType.FREE} />
      )}
      <InGameModal />
    </div>
  );
};

export default GameLaunchNew;
