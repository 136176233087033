import { useCallback } from 'react';
import { fitCover } from 'modules/casino/shared/utils/common/cdnConfig';
import config from 'utils/config';

const useBigWinImage = ({
  imageSrc,
  imageContainerRef,
}: {
  imageSrc: string;
  imageContainerRef: React.MutableRefObject<HTMLDivElement | null>;
}): string | undefined => {
  const getBackground = useCallback(() => {
    const { clientWidth, clientHeight } = imageContainerRef?.current || {};

    if (!clientWidth || !clientHeight) {
      return;
    }

    const height = clientHeight * 2;
    const width = clientWidth * 2;

    return imageSrc ? `url(${config.CDN_IMAGE_API}${fitCover(width, height, 'cover')}/${imageSrc})` : '';
  }, []);

  return getBackground();
};

export default useBigWinImage;
