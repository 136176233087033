import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import { useGridLayoutConfig } from 'modules/casino/modules/container/cellsContainer/grid/components/gridCell/hooks/useGridLayoutConfig';
import { GameMediaTypes } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellMedia/gameCellMediaTypes';
import { createVideoSource } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellMedia/gameCellMediaUtils';

export const useGameVideo = ({ gameUniqueId, categoryId, layoutGrid }: GameMediaTypes.UseGameVideoProps): string => {
  const { gameLayout, refGameId, providerId } = useGameProps({
    gameUniqueId,
    categoryId,
  });
  const { cdnPathLayout, cdnPath } = useGridLayoutConfig(layoutGrid);

  const gameData: GameMediaTypes.UseGameVideoGameData = {
    gameLayout: gameLayout?.toLowerCase(),
    refGameId: refGameId,
    gameProviderId: providerId,
    cdnPathLayout,
    cdnPath,
  };

  const getVideoSource = () => {
    const source = createVideoSource({
      providerId: gameData.gameProviderId,
      gameLayout: gameData.gameLayout,
      refGameId: gameData.refGameId,
      cdnPathLayout: gameData.cdnPathLayout,
      cdnPath: gameData.cdnPath,
    });

    return source;
  };

  return getVideoSource();
};
