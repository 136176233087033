import React from 'react';
import { NotificationTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationTypes';
import { Marker } from './components/Marker';
import { Wrapper } from './components/Wrapper';
import { WheelUI } from '../../components/GamificationUI/WheelUI';
import useWheelAnimation from '../../hooks/useWheelAnimation';
import { wheelConfig, wheelCampaignImages } from '../SharedSC/utils/campaigns.utils';

interface JungleWheelProps {
  folder: NotificationTypes.GamificationFolder;
  segments: string[];
  winnerSegment: number;
  setAnimationComplete: (isVisible: boolean) => void;
  notificationId: string;
  bonusTextColor?: string;
  bonusWinTextColor?: string;
  spinButtonTextColor?: string;
}

const JungleWheel: React.FC<JungleWheelProps> = ({
  folder,
  segments,
  winnerSegment,
  setAnimationComplete,
  notificationId,
  bonusTextColor,
  bonusWinTextColor,
  spinButtonTextColor,
}) => {
  const { calcRemainingDistance, stopSpin, startSpin, hasWin, isSpinning } = useWheelAnimation(
    notificationId,
    setAnimationComplete,
  );

  const { wheelImgSrc, markerImgSrc, btnImgSrc } = wheelCampaignImages(folder);

  return (
    <Wrapper>
      <div className="d-grid grid-1x1 gmf-pw__wheel-wrapper">
        <Marker style={{ backgroundImage: `url(${markerImgSrc})` }} />
        <WheelUI.Dial
          className="bg-contain"
          finalRevolutionAngle={wheelConfig.revolutions * 360 + calcRemainingDistance(winnerSegment + 1)}
          revolutionsPeriod={wheelConfig.revolutionsPeriod}
          isSpinning={isSpinning}
          hasStopped={hasWin}
          onAnimationComplete={stopSpin}
          bgImage={wheelImgSrc}
        >
          {segments.map((segment, index) => (
            <WheelUI.Segment
              key={index}
              hasWin={hasWin}
              isWinner={index === winnerSegment && hasWin}
              text={segment}
              bonusTextColor={bonusTextColor}
              bonusWinTextColor={bonusWinTextColor}
            />
          ))}

          <WheelUI.SpinButton
            isClickable={!isSpinning}
            onClick={startSpin}
            bgImage={btnImgSrc}
            spinButtonTextColor={spinButtonTextColor}
          />
        </WheelUI.Dial>
        <WheelUI.Fireworks isVisible={hasWin} />
      </div>
    </Wrapper>
  );
};

export default JungleWheel;
