import { Promotions } from 'utils/common/translationUtils/translationStrings';

export const leaderBoardCSSNS = 'egtd-lb-c';

export const WIDGET_LEADER_BOARD_PULL_INTERVAL_FALLBACK = 60000;

export const LeaderBoardWidgetTranslationKeys = {
  startsInText: 'Widgets.WidgetsCommonTexts.startsInText',
  endsInText: 'Widgets.WidgetsCommonTexts.endsInText',
  soonText: 'Widgets.WidgetsCommonTexts.soonText',
  viewMore: 'Widgets.WidgetsCommonTexts.viewMoreText',
  days: 'Common.Time.days',
  hours: 'Common.Time.hours',
  minutes: 'Common.Time.minutes',
  seconds: 'Common.Time.seconds',
  awards: 'Widgets.WidgetsLeaderboardTexts.prizes',
  ranking: 'Widgets.WidgetsLeaderboardTexts.ranking',
  points: 'Widgets.WidgetsLeaderboardTexts.points',
  number: 'Widgets.WidgetsLeaderboardTexts.number',
  place: Promotions.Tournaments.placeLabel,
  player: Promotions.Tournaments.playerLabel,
};
