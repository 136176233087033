import React from 'react';
import { useTranslation } from 'react-i18next';
import { DrawerUI } from '../DrawerUI';

const SportsAZ: React.FC = () => {
  const { t } = useTranslation();
  return <DrawerUI.Sorter text={t('Drawer.Texts.sportsAZ')} />;
};

export default SportsAZ;
