import React from 'react';
import clsx from 'clsx';
import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import cell01ComponentLoader from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell01/Cell01ComponentLoader';
import cell01StyleLoader from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell01/Cell01StyleLoader';
import { LayoutTypes } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/LayoutTypes';
import {
  ComponentNames,
  ComponentType,
  StyledNames,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/layoutWrapperConstants';
import { SharedComponents } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared';
import { useDefaultWrapper } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/hooks/useDefaultWrapper';
import { LayoutConfigInnerLayoutTypes } from 'modules/casino/shared/constants';
import { useCasinoLayoutConfig } from 'modules/casino/shared/hooks/useCasinoLayoutConfig';
import { useShowGameTitle } from '../../../../../shared/hooks/useShowGameTitle';

const GameCellWrapper: React.FC<LayoutTypes.GameCellWrapperProps> = ({
  gameUniqueId,
  categoryId,
  layoutGrid,
  subType,
}) => {
  const game = useGameProps({ gameUniqueId, categoryId });
  const { betType, betLimits, providerAlias } = game;
  const showBetLimits = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.SHOW_BET_LIMITS });
  const showBetLimitsPerProvider = useCasinoLayoutConfig({
    innerLayout: LayoutConfigInnerLayoutTypes.SHOW_BET_LIMITS_PER_PROVIDER,
  });
  const showLimitsForProvider =
    showBetLimitsPerProvider && showBetLimitsPerProvider.length
      ? showBetLimitsPerProvider.includes(providerAlias)
      : true;
  const showLimits = betType === 'CASINO' && showBetLimits && showLimitsForProvider && betLimits;
  const { bottomTag, hasTranspBottomTag } = useDefaultWrapper({ game });
  const actionWrapperStyles = clsx([
    'casino-game-cell',
    bottomTag && 'casino-game-cell--bottom-tag',
    hasTranspBottomTag && 'casino-game-cell--bottom-tag-transparent',
  ]);
  const Description = cell01ComponentLoader(ComponentNames.Description, ComponentType.default);
  const GameCellWrapperStyled = cell01StyleLoader(StyledNames.GameCellWrapperStyled, ComponentType.default);
  const { showGameTitle } = useShowGameTitle({ layoutGrid, subType });

  return (
    <GameCellWrapperStyled className={actionWrapperStyles} data-qid="casino-game-cell">
      {showGameTitle && <Description gameUniqueId={gameUniqueId} categoryId={categoryId} />}
      {showLimits && <SharedComponents.GameCellLimits betLimits={betLimits} />}
      <SharedComponents.GameCellTags gameUniqueId={gameUniqueId} categoryId={categoryId} />
    </GameCellWrapperStyled>
  );
};

export default GameCellWrapper;
GameCellWrapper.displayName = 'GameCellWrapper';
