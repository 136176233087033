import styled from 'styled-components';

const Card = styled.div.attrs({ className: 'prm-lobby-card' })`
  display: flex;
  flex-direction: column;
  height: 100%;
  will-change: transform; // Sfarai cut off fix
  cursor: pointer;
  overflow: hidden;
`;

export default Card;
