import { CreateThunk } from 'store/thunkCreators';

type BodyData = {
  str?: string;
  alignment?: string;
  effect?: string;
  size?: string;
  base64_image?: string;
  width?: number;
  v_alignment?: string;
  h_alignment?: string;
  qrCode?: string;
  barcode?: string;
  pixel_size?: number;
  numOfCopies?: number;
};

export type CommandData = {
  command: string;
  bodyData: BodyData;
};
export type SessionBalanceTypes = {
  retailBalanceInfo: SessionBalance | null;
  retailBalanceInfoStatus: string;
};
export type SessionBalance = {
  sessionBalance: {
    currency: string;
    amount: number;
  };
};
export type SessionBalanceThunk = {
  checkForUpdateBalance: CreateThunk<void, SessionBalance>;
};
export type TerminalCommands = {
  start: string;
  print: string;
  printOptions: string;
  printImage: string;
  printBarcode: string;
  printQRCode: string;
  colDefine: string;
  colReset: string;
  eject: string;
  printQrCodeSize: string;
  printCopies: string;
  isReady: string;
};
export enum BonusesTypes {
  earlyPayout = 'EARLYPAYOUT',
  goalInsurance = 'GOALINSURANCE',
  boreDraw = 'BOREDRAW',
}
export enum CampaignTypes {
  accaBoost = 'ACCABOOST',
  accaInsurance = 'ACCAINSURANCE',
  predefinedBets = 'BOOSTMULTIPLE',
}
