import { useEffect, useRef, useState } from 'react';

export const useModalDelay = (imagesLoaded: boolean, timeout = 2000): boolean => {
  const [delayLoadImg, setdelayLoadImg] = useState(false);
  const timeOut = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (imagesLoaded) {
      timeOut.current = setTimeout(() => {
        setdelayLoadImg(true);
      }, timeout);
    }

    return () => {
      timeOut?.current && clearTimeout(timeOut.current);
    };
  }, [imagesLoaded, setdelayLoadImg, timeout]);

  return delayLoadImg;
};
