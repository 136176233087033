import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from 'theme/Theme';
import { fitCover } from 'utils/common/cdnConfig';
import config from 'utils/config';

type Props = {
  onClick: () => void;
  isAuthenticated: boolean;
  icon?: string;
  iconMyProfile?: string;
  bonusCollection;
  isMyProfile;
};

type GenerateImageUrlType = {
  size: number;
  completion:
    | {
        completedPercentage: number;
        groupFolderIndex: string;
        levelFolderIndex: string;
      }
    | undefined;
  folder: string | undefined;
};

const generateImageUrl = ({ size, completion, folder }: GenerateImageUrlType) =>
  `${config.CDN_IMAGE_API}${fitCover(
    size,
    size,
  )}${`${folder}/group-${completion?.groupFolderIndex}/level-${completion?.levelFolderIndex}/header/header-${completion?.completedPercentage}.png`}`;

export const DefaultTombolaLink: React.FC<Props> = ({ icon, iconMyProfile, onClick, bonusCollection, isMyProfile }) => {
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });

  if (!bonusCollection) return null;

  const defaultSize = isDesktop ? 42 : 34;
  const imageSize = defaultSize * 2;
  const imageUrl = generateImageUrl({
    folder: !isMyProfile ? icon : iconMyProfile,
    completion: bonusCollection,
    size: imageSize,
  });

  return (
    <div className={`d-flex-ac`}>
      <img
        className="cursor-pointer"
        width={defaultSize}
        height={defaultSize}
        onClick={onClick}
        src={imageUrl}
        alt="Bonus Collection"
        data-cy="bonus-collection-header"
      />
    </div>
  );
};
