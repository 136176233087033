import React from 'react';
import { Flex } from 'modules/casino/shared/components/layout';
import { WidgetTemplate } from 'modules/casino/shared/constants';

const GameItemSkeleton = ({ skeletons = 4, type }) => {
  const renderSkeleton = (index: number) => {
    return (
      <div className={`cw__game-wrapper cw-skeleton`} key={index}>
        <div className={`d-flex cw__game`}>
          <div className="cw__game-image cw-skeleton__image"></div>
          <Flex.ColJB className="cw__game-description">
            <div>
              <div className="cw__game-profit cw-skeleton__line"></div>
              <div className="cw__game-title cw-skeleton__line"></div>
            </div>
            <div className="cw__game-time cw-skeleton__line"></div>
          </Flex.ColJB>
        </div>
      </div>
    );
  };

  const skeleton: React.ReactNode[] = [];
  for (let index = 0; index < skeletons; index++) {
    skeleton.push(renderSkeleton(index));
  }

  return (
    <div
      className={`w-100 casino-grid-item--skeleton ${
        WidgetTemplate[type] === WidgetTemplate.SECTION ? 'd-flex' : 'd-flex-col'
      }`}
    >
      <>{skeleton}</>
    </div>
  );
};
GameItemSkeleton.displayName = 'LastWinnersWidgetGameItemSkeleton';
export default GameItemSkeleton;
