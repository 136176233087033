import { useEffect } from 'react';
import {
  LIVE_CASINO_DEFAULT_URL,
  LIVE_IMAGE_UPDATE_INTERVAL,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellMedia/gameCellMediaConstants';
import { GameMediaTypes } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellMedia/gameCellMediaTypes';
import { createImageRefSrc } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellMedia/gameCellMediaUtils';

export const useImage = ({
  imgRef,
  srcList,
  withLiveImage,
  skipLazyLoad,
  cachedSrc,
}: GameMediaTypes.UseImageProps): GameMediaTypes.UseImageReturnTypes => {
  useEffect(() => {
    if (imgRef.current) {
      if (withLiveImage) {
        if (imgRef.current.src.includes(LIVE_CASINO_DEFAULT_URL)) {
          imgRef.current.src = createImageRefSrc({ src: srcList[0], withLiveUpdate: false });
          return;
        }

        if (skipLazyLoad && cachedSrc) {
          imgRef.current.src = cachedSrc;
        } else {
          imgRef.current.src = createImageRefSrc({ src: srcList[0], withLiveUpdate: true });
        }

        const interval = setInterval(() => {
          if (imgRef.current) {
            const imageSrc = createImageRefSrc({ src: srcList[0], withLiveUpdate: true });

            if (imageSrc === '') {
              clearInterval(interval);
              return;
            }
            imgRef.current.src = imageSrc;
          }
        }, LIVE_IMAGE_UPDATE_INTERVAL);

        return () => clearInterval(interval);
      } else {
        if (!srcList.includes(imgRef.current.src)) {
          imgRef.current.src = createImageRefSrc({ src: srcList[0], withLiveUpdate: false });
        }
      }
    }
  }, [srcList, cachedSrc]);

  const handleError = () => {
    if (imgRef.current && srcList[1]) {
      imgRef.current.src = createImageRefSrc({ src: srcList[1], withLiveUpdate: false });
    }
  };

  return { handleError };
};
