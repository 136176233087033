import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { TableGamesHistory } from '../types/tableGamesHistory.types';

export const TableGamesHistoryThunks: TableGamesHistory.Thunks = {
  fetchMain: createAbortThunk('fetchMain', async (filterParams, thunkAPI) => {
    try {
      const url = `${config.API_URL}/api/reporting/player/casino/table-games-history`;
      const response = await axiosInstance.post(url, {
        product: 'TABLE_GAMES',
        providerCode: filterParams.tableGame === 'svara' ? 'SVARA' : 'PLAYTECH',
        pageSize: filterParams.pageSize,
        pageNumber: filterParams.pageNumber,
        betDate: {
          fromDate: filterParams.fromDate,
          toDate: filterParams.toDate,
        },
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(getError.responseData(err));
    }
  }),
};

export const { fetchMain } = TableGamesHistoryThunks;

const initialState: TableGamesHistory.SliceTypes = {
  data: [],
  error: null,
  status: 'idle',
  filters: {
    fromDate: new Date().setHours(12, 0, 0, 0),
    toDate: new Date().setHours(12, 0, 0, 0),
  },
  pagination: {
    total: 0,
    page: 0,
    pageSize: 10,
  },
  isLoading: false,
  hasFetched: false,
  hasUpdated: false,
  isLoadingMore: false,
};

const tableGamesHistorySlice = createSlice({
  name: 'tableGamesHistory',
  initialState,
  reducers: {
    setFilterFromDate(state, action: PayloadAction<number>) {
      const date = new Date(action.payload);
      state.filters.fromDate = date.setHours(12, 0, 0, 0);
    },
    setFilterToDate(state, action: PayloadAction<number>) {
      const date = new Date(action.payload);
      state.filters.toDate = date.setHours(12, 0, 0, 0);
    },
    clearTableGamesHistory(state) {
      state.data = [];
      state.error = null;
      state.pagination.page = 0;
      state.pagination.total = 0;
      state.isLoading = false;
      state.isLoadingMore = false;
      state.status = 'idle';
    },
    clearTableGamesHistoryItems(state) {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMain.pending, (state, action) => {
        if (action.meta?.arg?.isFetchMore) {
          state.isLoadingMore = true;
        } else {
          state.isLoading = true;
          state.status = 'pending';
        }
      })
      .addCase(fetchMain.rejected, (state) => {
        state.error = getError.default();
        state.isLoading = false;
        state.isLoadingMore = false;
        state.status = 'failure';
      })
      .addCase(fetchMain.fulfilled, (state, action: PayloadAction<TableGamesHistory.ApiResponse.Main>) => {
        const { content, pageable, totalPages } = action.payload;
        state.data = [...state.data, ...content];
        state.pagination.page = pageable.pageNumber;
        state.pagination.total = totalPages;
        state.error = null;
        state.hasFetched = true;
        state.isLoading = false;
        state.isLoadingMore = false;
        state.status = 'succeeded';
      });
  },
});

export const { setFilterFromDate, setFilterToDate, clearTableGamesHistory, clearTableGamesHistoryItems } =
  tableGamesHistorySlice.actions;
export default tableGamesHistorySlice.reducer;

export const selectTableGamesHistory = {
  bets: (state: RootState): TableGamesHistory.Item[] => state.myAccount.tableGamesHistory.data,
  currentPage: (state: RootState): number => state.myAccount.tableGamesHistory.pagination.page,
  totalPages: (state: RootState): number => state.myAccount.tableGamesHistory.pagination.total,
  pageSize: (state: RootState): number => state.myAccount.tableGamesHistory.pagination.pageSize,
  status: (state: RootState): string | null => state.myAccount.tableGamesHistory.status,
  error: (state: RootState): string | null | undefined =>
    state.myAccount.tableGamesHistory.error as string | null | undefined,
  isLoadingMore: (state: RootState): boolean => state.myAccount.tableGamesHistory.isLoadingMore,
};
