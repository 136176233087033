import { Mixins } from 'theme/StyledComponents/shared';
import { CommonType } from '../default/types/CommonTypes';

const navbar = 145;
const appBottom = 143;
// Common
export const Common: CommonType.State = {
  ThemeOptions: {
    enableDefaultThemeOnly: null, // 'null', 'lite', 'dark'
    enableBoxedLayout: false,
    enableBoxedLayoutWidth: '1920px',
    enableIndividuallyScrollableLayoutCols: true,
    useSystemsFonts: true,
    enableNavSliderBorderBottomMobile: true,
    enableNavSliderBorderBottomDesktop: true,
    enableButtonBgGradients: false,
  },
  ZIndexList: {
    backdrop: 142,
    navbar: navbar,
    footer: navbar - 1,
    appBottom: appBottom,
    bslBackdrop: 105,
    bslWrapper: 110,
    bslWrapperFs: appBottom * 2,
    preloader: 120,
    casinoGameLaunch: 200,
    bottomTabBar: 140,
    stickyTopWrapper: 135,
    myProfileDropdown: 160,
  },
  Variables: {
    gridGutterWidth: Mixins.Size.toRem(20),
    btnBr: Mixins.Size.toRem(10),
    componentBr: Mixins.Size.toRem(10),
    componentPhSm: Mixins.Size.toRem(5),
    componentPh: Mixins.Size.toRem(10),
    componentSpacer: Mixins.Size.toRem(3),
    componentHeight: Mixins.Size.toRem(40),
    componentHeightLg: Mixins.Size.toRem(46),
    itemSpacerSm: Mixins.Size.toRem(1),
    itemSpacer: Mixins.Size.toRem(2),
    fontFamilyBase: `'Roboto Condensed', sans-serif `,
    fontSizeBase: '0.875rem',
  },
  LayoutSettings: {
    layoutEnableGutters: false,
    layoutGuttersSm: 0,
  },
};
