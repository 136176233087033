import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { selectPrizeDrop, setActiveCampaignId } from 'shared/common/features/PrizeDrop/slice/prizeDrop.slice';
import { PrizeDropStatus } from 'shared/common/features/PrizeDrop/types/prizeDrop.types';
import { useAppDispatch, useAppSelector } from 'store';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import PrizeDropBadge from './PrizeDropBadge';
import PrizeDropIcon from './PrizeDropIcon';
import PrizeDropTimeCounter from './PrizeDropTimeCounter';
import PrizeDropTitle from './PrizeDropTitle';
import { prizeDropCSSNS } from '../prizeDropUICSS.types';

const { SOON, ACTIVE, ENDED } = PrizeDropStatus;
type Props = { id: number; className?: string };

const PrizeDropCampaignItem: React.FC<Props> = ({ id, className }) => {
  const campaign = useAppSelector(selectPrizeDrop.campaign(id));
  const activeCampaignId = useAppSelector(selectPrizeDrop.activeCampaignId);

  const [isExpiredStartTime, setIsStartTimeExpired] = useState(campaign?.startDate <= Date.now());
  const [isExpiredEndTime, setIsEndTimeExpired] = useState(campaign?.endDate <= Date.now());

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleCampaignClick = useCallback(() => {
    if (campaign && campaign.id !== activeCampaignId) {
      dispatch(setActiveCampaignId(campaign.id));
    }
  }, [dispatch, campaign, activeCampaignId]);

  if (!campaign) return null;

  const slotStatus = campaign.isActive ? (isExpiredStartTime ? (isExpiredEndTime ? ENDED : ACTIVE) : SOON) : SOON;

  const classProp = {
    class: clsx(className ? className : null),
  };

  const classTextProp = { class: clsx('d-flex-col-jb', className || null) };

  return (
    <egtd-przd-campaign-item
      onClick={handleCampaignClick}
      data-current={campaign?.id === activeCampaignId}
      data-status={slotStatus}
      {...classProp}
    >
      <egtd-przd-campaign-item-inner>
        <egtd-przd-campaign-item-text {...classTextProp}>
          <Flex.JB>
            <PrizeDropTitle
              title={campaign?.title || ''}
              size="sm"
              className={`${prizeDropCSSNS}-campaign-item-title`}
              iconResource={campaign?.iconResource}
            />
            <PrizeDropBadge badgeStatus={slotStatus} badgeText={t(`Promotions.PrizeDrop.${slotStatus}Text`)} />
          </Flex.JB>
          {slotStatus === SOON && (
            <PrizeDropTimeCounter
              date={campaign?.startDate}
              text={t(Promotions.PrizeDrop.startsInText)}
              setIsTimeExpired={setIsStartTimeExpired}
              isColumn
              slotStatus={slotStatus}
            />
          )}
          {(slotStatus === ACTIVE || slotStatus === ENDED) && (
            <PrizeDropTimeCounter
              date={campaign?.endDate}
              text={t(Promotions.PrizeDrop.endsInText)}
              setIsTimeExpired={setIsEndTimeExpired}
              isColumn
              showDate={slotStatus === ENDED}
              slotStatus={slotStatus}
            />
          )}
        </egtd-przd-campaign-item-text>
        <egtd-przd-campaign-item-icon>
          {campaign?.iconResource && <PrizeDropIcon iconResource={campaign.iconResource} />}
        </egtd-przd-campaign-item-icon>
      </egtd-przd-campaign-item-inner>
    </egtd-przd-campaign-item>
  );
};

export default PrizeDropCampaignItem;
