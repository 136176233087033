import { useTranslation } from 'react-i18next';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppSelector } from 'store';
import { PrizeDropPopover } from './prizeDropPopover';
import { casinoLaunchSelectors } from '../../../gameLaunch/casinoLaunchSlice';
import { gamesBonusSelectors } from '../../slice/gameLaunchBonus.slice';
import { TopBarButton } from '../TopBarButton';

type Props = {
  type: string;
  gameId: string;
  text?: string;
  icon?: string;
  isFreePlay: boolean;
};

export const PrizeDropButton = ({ isFreePlay, type, text, gameId, icon }: Props) => {
  const { t } = useTranslation();

  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const isGameWithPrizeDrop = useAppSelector((state) => gamesBonusSelectors.isGameWithPrizeDrop(state, gameId));
  const isProviderWithLobby = useAppSelector(casinoLaunchSelectors.isProviderWithLobby);
  const isNewGameIdReceived = useAppSelector(casinoLaunchSelectors.isNewGameIdReceived);
  const isProviderInfoReceived = useAppSelector(casinoLaunchSelectors.providerInfoReceived);
  const showIconForGamesWithLobby =
    isProviderInfoReceived && isProviderWithLobby
      ? isNewGameIdReceived
      : isProviderInfoReceived && !isProviderWithLobby;

  const showPrizeDropButton = isGameWithPrizeDrop && showIconForGamesWithLobby;

  return (
    <TopBarButton
      disabled={!showPrizeDropButton}
      type={type}
      icon={icon}
      text={text && t(text)}
      restrictForFreePlay={isFreePlay}
      renderChildren={
        !isFreePlay && isAuthenticated
          ? (btnRef, handleClose, show) => <PrizeDropPopover handleClose={handleClose} parentRef={btnRef} show={show} />
          : undefined
      }
    />
  );
};
