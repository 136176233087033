import { useEffect } from 'react';
import { setAppFooter } from 'shared/common/features/appFooter/slice/appFooter.slice';
import { useAppDispatch } from 'store';

type StepsState = [boolean, boolean];

const defaultSteps: StepsState = [true, false];

export const useShowAppFooter = (steps = defaultSteps): void => {
  const dispatch = useAppDispatch();
  const firstStep = steps[0];
  const secondStep = steps[1];

  useEffect(() => {
    dispatch(setAppFooter(firstStep));
    return () => {
      dispatch(setAppFooter(secondStep));
    };
  }, [dispatch, firstStep, secondStep]);
};
