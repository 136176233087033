import React from 'react';
import { useTranslation } from 'react-i18next';
import { BonusLogUI } from 'pages/myAccount/tabs/history/components/BonusLogUI';
import { useAppDispatch } from 'store';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import { OffersGroupBonus } from './OffersGroupBonus';
import { fetchTerms } from '..';
import type { OffersTabLayout, Promotion } from '../types/offersTab.types';

type Props = {
  promotion: Promotion;
  layout?: OffersTabLayout;
};

const OffersPromotion: React.FC<Props> = ({ promotion, layout }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const fetchBonusTerm = () => dispatch(fetchTerms({ id: promotion.termsAndConditionsId }));
  const title = (layout !== 'layout_2' && promotion?.title) || '';
  return (
    <div className="mpd-cta-item">
      <BonusLogUI.Header
        componentLocation="myProfileDropdown"
        title={title}
        banner={promotion?.bannerResource}
        description={promotion?.content}
        activationText={t(MyProfile.OffersTab.activationText)}
        expirationText={t(MyProfile.OffersTab.expirationText)}
        expirationValue={promotion?.promotionExpirationDate}
        promotionType={promotion.type}
      />
      <div className="mpd-cta-item__section">
        <span onClick={fetchBonusTerm} className="d-block mpd-cta-item__link">
          {t(MyProfile.OffersTab.termsAndConditionsApply)}
        </span>
      </div>
      {promotion?.bonuses.map((bonus) => (
        <OffersGroupBonus key={bonus.id} bonus={bonus} isOpen={layout === 'layout_2'} />
      ))}
    </div>
  );
};

export default OffersPromotion;
