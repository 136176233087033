import React from 'react';
import { Flex } from 'components/shared/layout';
import { DepositBtn } from '../index';

interface Props {
  sectionTypes: string;
  children?: React.ReactNode;
  isHighlighted?: boolean;
  depositButton?: boolean;
}

const Wrapper: React.FC<Props> = ({ children, sectionTypes, isHighlighted, depositButton }) => {
  return (
    <>
      <Flex.AC
        data-type={sectionTypes}
        className={`footer-container footer-container--${isHighlighted ? 'primary' : 'secondary'} `}
      >
        <div
          className={`footer-container__items m-auto d-flex align-items-stretch w-100 ${sectionTypes.toLowerCase()}`}
        >
          {children}
        </div>
      </Flex.AC>
      {depositButton && <DepositBtn />}
    </>
  );
};

export default Wrapper;
