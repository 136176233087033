import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { createAbortThunk, CreateThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import config from 'utils/config';
import { SliderContainerState, ParsedSliderContainerDataApiResponse } from '../types/sliderContainer.types';

type SliderContainerThunks = {
  fetchCasinoSlider: CreateThunk<
    { apiUri: string; containerId: number },
    ParsedSliderContainerDataApiResponse,
    string | null
  >;
};

export const sliderContainerThunks: SliderContainerThunks = {
  fetchCasinoSlider: createAbortThunk('sliderContainer/fetchCasinoSlider', async ({ apiUri }, { source }) => {
    const url = `${config.API_URL}${apiUri}`;
    const response = await axiosInstance.get(url, {
      cancelToken: source.token,
    });

    return response.data;
  }),
};

const { fetchCasinoSlider } = sliderContainerThunks;

const initialState: SliderContainerState = {
  sliderContainerData: {},
};

const sliderContainer = createSlice({
  name: 'sliderContainer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCasinoSlider.fulfilled, (state, action) => {
      const { containerId } = action.meta.arg;
      state.sliderContainerData[containerId] = action.payload;
    });
  },
});

export default sliderContainer.reducer;

export const selectSlider = {
  containerById:
    (containerId: number) =>
    (state: RootState): ParsedSliderContainerDataApiResponse =>
      state.common.sliderContainer.sliderContainerData?.[containerId],
};
