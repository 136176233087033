import React, { MouseEventHandler } from 'react';
import Icon from '../../../../../../components/shared/Icon';

/** Footer Section Header */
interface SectionHeaderProps {
  text: string | null;
  icon?: string;
  iconClass?: string;
  isSecondary?: boolean;
  onClick?: MouseEventHandler;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ text, icon, iconClass, onClick }) => {
  return (
    <h1 className="d-flex-ac-jb header-level-1 header-level-1--expandable" onClick={onClick}>
      <span>{text}</span>
      {icon && <Icon icon={icon} className={iconClass} />}
    </h1>
  );
};

export default SectionHeader;
