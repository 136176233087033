import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Flex } from 'modules/casino/shared/components/layout';
import { selectCashierData } from 'modules/retail/modules/cashier/slice/cashier.slice';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';
import Clock from './Clock';

/** RetailMobileTopBar */
interface RetailMobileTopBarProps {
  className?: string;
  isAuthenticated: boolean | null;
}

export function getCurrentDate(separator = '/') {
  const newDate = new Date();
  const dateName = newDate.toLocaleString('en-US', { weekday: 'short' });
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  return `${dateName}, ${date}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${year}
    `;
}

const RetailMobileTopBar: React.FC<RetailMobileTopBarProps> = ({ className, isAuthenticated }) => {
  const { t } = useTranslation();

  const posInfo = useAppSelector(selectCashierData.posInfo);
  const posName = !isEmpty(posInfo?.posInfo?.name) ? `${posInfo?.posInfo?.name}` : '';
  const terminalName = !isEmpty(posInfo?.posInfo?.terminalName) ? `${posInfo?.posInfo?.terminalName}` : '';

  const wrapperClass = clsx('retail-top-bar', className);

  return (
    <div className={wrapperClass}>
      <Flex.ACJB className="retail-top-bar__inner">
        {isAuthenticated && (
          <div>
            {t(CashierTranslations.ActionButton.posName)}:&nbsp;{posName}&nbsp;•&nbsp;
            {t(CashierTranslations.ActionButton.terminalName)}:&nbsp;{terminalName}
          </div>
        )}
        <div>
          {getCurrentDate()}
          <Clock />
        </div>
      </Flex.ACJB>
    </div>
  );
};

export default RetailMobileTopBar;
