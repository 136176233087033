import React from 'react';
import clsx from 'clsx';

interface PageBoxGridProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  children: React.ReactNode;
}

const PageBoxGrid: React.FC<PageBoxGridProps> = ({ className, children, ...rest }) => {
  const boxWrapClass = clsx('prm-page__grid', className);

  return (
    <div className={boxWrapClass} {...rest}>
      {children}
    </div>
  );
};

export default PageBoxGrid;
