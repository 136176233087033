import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { showMyProfileModal } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppDispatch } from 'store';
import { MainNavbarButtonsDefaultMyProfile } from './components/MainNavbarButtonsDefaultMyProfile';
import { MainNavbarButtonsCompact } from '../../components/MainNavbarButtonsCompact';
import { MainNavBarButtonsTypes } from '../../types/mainNavBarButtons.types';

type Props = {
  item: MainNavBarButtonsTypes.Item;
  isAuthenticated?: boolean;
  isMyProfile?: boolean;
};

type PropsComponent = {
  text?: string;
  onClick: () => void;
  isAuthenticated: boolean;
  isMyProfile?: boolean;
  icon?: string;
  iconMyProfile?: string;
};

export const containersMap: Record<'default' | 'banner', React.FC<PropsComponent>> = {
  default({ isMyProfile, onClick, isAuthenticated, icon, iconMyProfile }: PropsComponent) {
    return (
      <MainNavbarButtonsCompact
        disableBadge
        isMyProfile={isMyProfile}
        onClick={onClick}
        icon={icon}
        iconMyProfile={iconMyProfile}
        isAuthenticated={isAuthenticated}
      />
    );
  },
  banner({ text, onClick, isAuthenticated, icon, iconMyProfile }: PropsComponent) {
    return (
      <MainNavbarButtonsDefaultMyProfile
        text={text}
        onClick={onClick}
        icon={icon}
        iconMyProfile={iconMyProfile}
        isAuthenticated={isAuthenticated}
      />
    );
  },
};

const MainNavbarButtonsDefault: React.FC<Props> = ({ isAuthenticated, isMyProfile, item }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const typeByProfile = isMyProfile ? 'banner' : 'default';
  const ContainerComponent: React.FC<PropsComponent> = containersMap[typeByProfile];
  const { t } = useTranslation();
  if (!ContainerComponent) return null;
  const text = item?.text && t(item?.text);

  const goToBonusPage = () => {
    dispatch(showMyProfileModal(false));
    item?.navigationUrls && navigate(item?.navigationUrls);
  };

  return (
    <ContainerComponent
      {...{
        text: text,
        isMyProfile,
        onClick: goToBonusPage,
        isAuthenticated: !!isAuthenticated,
        icon: item?.icon,
        iconMyProfile: item?.iconMyProfile,
      }}
    />
  );
};

export default MainNavbarButtonsDefault;
