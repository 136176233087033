import { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import userStorage from 'pages/auth/login/UserStorage';
import { useAppDispatch, useAppSelector } from 'store';
import {
  readNotification,
  setActivePopupNotificationId,
} from '../../myProfile/sections/tabs/notificationsTab/notificationSlice';
import { NotificationTypes } from '../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import { selectOffersTab } from '../../myProfile/sections/tabs/offersTab';
import { handleAudio } from '../utils/Gamification.utils';

type Props = {
  index: number;
  bonusData?: NotificationTypes.BonusData;
  animationComplete: boolean;
  winItemIndex: null | number;
  setWinItemIndex: (index: number) => void;
  setAnimationComplete: (isVisible: boolean) => void;
  notificationId: string;
  type?: 'popup' | 'page';
  audioUrl?: string;
  frameAudioUrl?: string;
};

type ReturnHookType = {
  state: {
    playAnim: boolean;
    winIsVisible: boolean;
    error?: string;
    giftTitle: string;
    isWinItem: boolean;
  };
  handleAnimationComplete: () => void;
  handleAnimation: () => void;
};

const useGamificationItemState = ({
  index,
  bonusData,
  winItemIndex,
  notificationId,
  animationComplete,
  setWinItemIndex,
  setAnimationComplete,
  type = 'popup',
  frameAudioUrl,
}: Props): ReturnHookType => {
  const [playAnim, setPlayAnim] = useState<boolean>(false);
  const [winIsVisible, setWinIsVisible] = useState<boolean>(false);
  const singleBonus = bonusData?.bonuses?.[0];

  const { error } = useAppSelector((state) => selectOffersTab.bonusStatusAndError(state, singleBonus?.id || ''));
  const dispatch = useAppDispatch();
  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // When win item animation is complete play other items animation.
    if (animationComplete) setPlayAnim(true);
  }, [animationComplete]);

  useEffect(() => {
    return () => {
      timer?.current && clearTimeout(timer.current);
    };
  }, []);

  // Called when framer animation for the item is complete.
  const handleAnimationComplete = useCallback(() => {
    setWinIsVisible(true);
    if (winItemIndex !== null) {
      // Handle other items animation to be completed after timeout.
      timer.current = setTimeout(() => setAnimationComplete(true), 1500);
    }
  }, [setAnimationComplete, winItemIndex]);

  const handleAnimation = useCallback(() => {
    if (winItemIndex === null) {
      setPlayAnim(true);
      setWinItemIndex(index);
      frameAudioUrl && handleAudio(frameAudioUrl);

      if (type === 'popup') {
        dispatch(readNotification(notificationId, 'popup')).then(() => {
          userStorage.setUserPopupNotificationId(notificationId);
        });
        dispatch(setActivePopupNotificationId(notificationId));
      }
    }
  }, [type, winItemIndex, dispatch, notificationId, setWinItemIndex, index]);

  // Define is current item the winning item.
  const isWinItem = winItemIndex === index;
  const bonusTitleIndex = bonusData?.otherOptions?.findIndex((bonusTitle: string) => bonusTitle === singleBonus?.title);
  const otherItemsIndex = isWinItem ? bonusTitleIndex : index === bonusTitleIndex ? winItemIndex : index;
  const hasIndex =
    bonusData?.otherOptions && otherItemsIndex !== null && otherItemsIndex !== undefined && otherItemsIndex > -1;
  const giftTitle = hasIndex ? bonusData?.otherOptions?.[otherItemsIndex] : '';

  const state = useMemo(
    () => ({
      playAnim,
      winIsVisible,
      error,
      giftTitle,
      isWinItem,
    }),
    [playAnim, winIsVisible, error, giftTitle, isWinItem],
  );

  return { state, handleAnimationComplete, handleAnimation };
};

export default useGamificationItemState;
