import { t } from 'i18next';
import { BetBuilderSignCommand } from 'modules/retail/modules/cashier/types/cashier.types';
import { formatAmountToBRL } from 'modules/retail/modules/cashier/utils';
import {
  MapBetbuilderEventsDataParams,
  MapBetbuilderSignParams,
  MapBetbuilderSummaryBetDataParams,
} from 'modules/retail/modules/ssbt/types/ssbt.types';
import { BonusesTypes, CommandData } from 'modules/retail/shared/types/retailCommon.types';
import { defineBonusesType } from 'modules/retail/shared/utils/helperFunctions';
import {
  formatEventStartDate,
  formatStringPerRow,
  printMethods,
  retailPrintOptions,
} from 'modules/retail/shared/utils/retail.utils';
import { commands } from 'modules/retail/shared/utils/retail.utils';
import { store } from 'store';
import { isEmpty, retailFormatDateReceipt, toFixed } from 'utils/common/helpersCommon';
import { BetSlip, CashierTranslations, SSBT } from 'utils/common/translationUtils/translationStrings';
import { MAX_MARKET_NAME_LENGHT_PER_ROW_CASHIER, MAX_SPORT_EVENT_NAME_PER_ROW_CASHIER } from '../../utils/constants';

export const mapBetbuilderEventsData = ({ customBet, getState }: MapBetbuilderEventsDataParams) => {
  const betBuilderEventsData: CommandData[] = [];

  // const iconBoreDraw = getState()?.common?.general?.layoutConfig?.platform?.general?.retailBonusIcons?.boreDraw;
  // const iconGoalInsurance =
  //   getState()?.common?.general?.layoutConfig?.platform?.general?.retailBonusIcons?.goalInsurance;
  // const isBoreDrawActive = defineBonusesType(customBet, BonusesTypes.boreDraw);
  // const isGoalInsuranceActive = defineBonusesType(customBet, BonusesTypes.goalInsurance);
  const earlyPayoutCampaign = defineBonusesType(customBet, BonusesTypes.earlyPayout);
  const iconEarlyPayout =
    getState()?.common?.general?.layoutConfig?.['platform']?.general?.retailBonusIcons?.earlyPayout;
  const eventName = `  •${customBet.name}`;
  const formattedEventName = formatStringPerRow(MAX_MARKET_NAME_LENGHT_PER_ROW_CASHIER, eventName) || [];
  const marketName = `  ${customBet.marketTemplateName}`;
  const formattedMarketName = formatStringPerRow(MAX_MARKET_NAME_LENGHT_PER_ROW_CASHIER, marketName) || [];
  printMethods.colReset(betBuilderEventsData, commands);

  printMethods.printOptions(betBuilderEventsData, commands, retailPrintOptions.totalStakeOptions);
  if (eventName.length > MAX_MARKET_NAME_LENGHT_PER_ROW_CASHIER && earlyPayoutCampaign) {
    for (const str of formattedEventName) {
      printMethods.print(betBuilderEventsData, commands, str);
    }
    printMethods.printImage(betBuilderEventsData, commands, iconEarlyPayout);
  } else {
    printMethods.conditionPrintImageAndStringInARow(
      betBuilderEventsData,
      commands,
      earlyPayoutCampaign,
      iconEarlyPayout,
      eventName,
      'totalStakeOptions',
    );
    if (eventName.length > MAX_MARKET_NAME_LENGHT_PER_ROW_CASHIER) {
      for (const str of formattedEventName) {
        printMethods.print(betBuilderEventsData, commands, str);
      }
    } else {
      printMethods.conditionalPrint(betBuilderEventsData, commands, !earlyPayoutCampaign, eventName);
    }
  }

  printMethods.printOptions(betBuilderEventsData, commands, retailPrintOptions.eventNameOptions);
  for (const str of formattedMarketName) {
    printMethods.print(betBuilderEventsData, commands, str);
  }

  return betBuilderEventsData;
};

export const mapBetbuilderSummaryBetData = <T extends { outcomeId: number | string; totalCombinations: number }>({
  value,
  currency,
  isSingle,
  singles,
  getSingleBetProps,
  isCurrencyBeforeAmount,
}: MapBetbuilderSummaryBetDataParams<T>) => {
  const betBuildeSummaryBetData: CommandData[] = [];
  const currencySettings = store.getState()?.common?.general?.currencySettings;
  const currencySymbol = currencySettings?.find((c) => c.name === currency)?.nameTranslations || currency;
  if (isSingle) {
    const singleBetProps = getSingleBetProps(value);
    const singleOutcome = singles.find((x) => x?.outcomeId === value?.id);

    if (singleBetProps && singleOutcome) {
      printMethods.print(betBuildeSummaryBetData, commands, ''.padEnd(44, '-'));
      printMethods.printOptions(betBuildeSummaryBetData, commands, retailPrintOptions._optionsData);
      const systemStr =
        t(`BetSlip.Systems.${singleBetProps.selectedSystem}`) +
        `, ${singleOutcome.totalCombinations} ${t(SSBT.Ticket.totalCombinations)} X ` +
        (isCurrencyBeforeAmount
          ? `${currencySymbol} ${formatAmountToBRL(singleBetProps.stake)}`
          : `${toFixed(singleBetProps.stake, 2)} ${currency}`);

      printMethods.print(betBuildeSummaryBetData, commands, systemStr);

      const totalOddsStr = `${t(BetSlip.SSBT.totalOdds)} ${toFixed(singleBetProps.totalOdds, 2)}`;
      printMethods.print(betBuildeSummaryBetData, commands, totalOddsStr);

      const stakeInfoStr =
        `${t(BetSlip.BetSlipConfirmation.stakeInfoText)}: ` +
        (isCurrencyBeforeAmount
          ? `${currencySymbol} ${formatAmountToBRL(singleBetProps.stake)}`
          : `${toFixed(singleBetProps.stake, 2)} ${currency}`);
      printMethods.print(betBuildeSummaryBetData, commands, stakeInfoStr);

      const possibleWinStr =
        `${t(BetSlip.PlaceBetButton.possibleWinText)}: ` +
        (isCurrencyBeforeAmount
          ? `${currencySymbol} ${formatAmountToBRL(singleBetProps.win)}`
          : `${toFixed(singleBetProps.win, 2)} ${currency}`);
      printMethods.print(betBuildeSummaryBetData, commands, possibleWinStr);
    }
  } else {
    printMethods.printOptions(betBuildeSummaryBetData, commands, retailPrintOptions._optionsData);
  }
  printMethods.print(betBuildeSummaryBetData, commands, ''.padEnd(44, '-'));
  return betBuildeSummaryBetData;
};

export const mapBetbuilderSign = ({ value }: MapBetbuilderSignParams) => {
  const rawStartDate = isEmpty(value.customBetOutcomes[0].eventStartDate)
    ? retailFormatDateReceipt(value.customBetOutcomes[0].scheduledTime)
    : retailFormatDateReceipt(value.customBetOutcomes[0].eventStartDate);

  const isLive = !isEmpty(value.customBetOutcomes[0].isLive) ? value.customBetOutcomes[0].isLive : null;

  const liveAndTime =
    isLive !== null
      ? isLive
        ? `${formatEventStartDate(rawStartDate)} ` + t(CashierTranslations.Header.live)
        : formatEventStartDate(rawStartDate)
      : formatEventStartDate(rawStartDate);

  let betBuilderSignCommands: BetBuilderSignCommand[] = [];

  if (value.customBetOutcomes[0].sportEventName.length > MAX_SPORT_EVENT_NAME_PER_ROW_CASHIER) {
    const [team1, team2] = value.customBetOutcomes[0].sportEventName.split(' vs. ');
    betBuilderSignCommands = [
      { command: commands.printOptions, bodyData: retailPrintOptions._optionsData },
      { command: commands.print, bodyData: { str: liveAndTime } },
      { command: commands.printOptions, bodyData: retailPrintOptions._optionsData },
      { command: commands.print, bodyData: { str: `${team1} vs.` } },
      { command: commands.print, bodyData: { str: `${team2}` } },
      { command: commands.printOptions, bodyData: retailPrintOptions.totalStakeOptions },
      { command: commands.print, bodyData: { str: `${t('Cashier.Receipt.betBuilder')} (${toFixed(value.odds, 2)})` } },
      { command: commands.print, bodyData: { str: ''.padEnd(44, '-') } },
    ];
  } else {
    betBuilderSignCommands = [
      { command: commands.printOptions, bodyData: retailPrintOptions._optionsData },
      { command: commands.print, bodyData: { str: liveAndTime } },
      { command: commands.printOptions, bodyData: retailPrintOptions._optionsData },
      { command: commands.print, bodyData: { str: value.customBetOutcomes[0].sportEventName } },
      { command: commands.printOptions, bodyData: retailPrintOptions.totalStakeOptions },
      { command: commands.print, bodyData: { str: `${t('Cashier.Receipt.betBuilder')} (${toFixed(value.odds, 2)})` } },
      { command: commands.print, bodyData: { str: ''.padEnd(44, '-') } },
    ];
  }

  return betBuilderSignCommands;
};
