import React from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';

interface BadgeProps {
  isActive?: boolean;
  isVisible: boolean;
  text: string | number;
  className?: string;
}

const Badge: React.FC<BadgeProps> = ({ isActive, isVisible, text, className }) => {
  if (!isVisible) return null;

  const badgeClass = clsx('text-center nav-user__badge', isActive && 'nav-user__badge--active', className);

  return <Flex.Center className={badgeClass}>{text}</Flex.Center>;
};

export default Badge;
