import React from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from 'theme/Theme';
import config from 'utils/config';
import { EventLayouttypes, EventLocationTypes } from '../types/eventProps.types';

/** EventUI Note */
interface NoteProps {
  text?: string;
  icon?: string;
  componentLocation: EventLocationTypes | undefined;
  eventLayout?: EventLayouttypes;
}

export const Note: React.FC<NoteProps> = ({ text, icon, componentLocation, eventLayout }) => {
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isTabletOrMobile}px)` });

  const removeNoteByLocation: boolean =
    eventLayout === 'vertical' ||
    eventLayout === 'verticalCompact' ||
    (eventLayout === 'verticalMobile' && isTabletOrMobile);

  if (removeNoteByLocation) return <></>;

  const cssNS = 'event-note';

  const noteClass = clsx(
    'd-flex-ac w-100',
    cssNS,
    !componentLocation && `${cssNS}--default`,
    componentLocation === 'tournamentView' && `${cssNS}--default`,
    componentLocation === 'dynamicContent' && `${cssNS}--dynamic`,
  );

  return (
    <section className={noteClass}>
      {icon && (
        <img className={`${cssNS}__icon`} src={`${config.CDN_IMAGE_API}/sport-content/event-note-icons/${icon}.svg`} />
      )}
      <span className={`${cssNS}__text`}>{text}</span>
    </section>
  );
};
