import React, { memo, useEffect, useRef } from 'react';
import { formatDateUTC, addToDate, FormatType, timeMsMap } from 'utils/dateUtils';

interface Props {
  endDateMS: number;
  startDateMS?: number;
  duration?: number;
  takeDateOwnOffset?: boolean;
  countDiff?: number;
  countType?: keyof typeof timeMsMap;
  classNames?: string;
  format?: FormatType;
  translations?: { day: string; days: string };
  isStatic?: boolean;
  isLive?: boolean;
  onExpire?: () => void;
}

const CountdownTimer: React.FC<Props> = ({
  classNames,
  startDateMS = Date.now(),
  endDateMS,
  duration,
  takeDateOwnOffset,
  countDiff = -1,
  countType = 'seconds',
  format = 'mm:ss without-locale',
  translations,
  isStatic,
  isLive,
  onExpire,
}) => {
  const endDateRef = useRef(endDateMS);
  const isStaticRef = useRef(isStatic);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const timerRef = useRef<HTMLDivElement>(null);

  if (isStaticRef.current !== isStatic) isStaticRef.current = isStatic;
  if (isLive && countDiff === 1 && !isStaticRef.current && endDateMS !== endDateRef?.current) {
    endDateRef.current = endDateMS;
  }

  useEffect(() => {
    if (!isStaticRef.current) {
      intervalRef.current = setInterval(() => {
        endDateRef.current = addToDate(endDateRef.current, countDiff, countType);
        const diff = endDateRef.current - startDateMS;
        if (duration && duration <= diff && intervalRef.current) {
          isStaticRef.current = true;
          clearInterval(intervalRef.current);
          onExpire && onExpire();
        }

        if (diff > 0 && endDateRef.current > startDateMS) {
          const time = formatDateUTC(format, diff, takeDateOwnOffset, translations);
          if (timerRef.current) {
            timerRef.current.innerText = time;
          }
        } else {
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }
        }
      }, timeMsMap[countType]);

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [isStatic]);

  const initialDiff = formatDateUTC(
    format,
    !isStaticRef.current ? endDateRef.current - startDateMS : duration,
    takeDateOwnOffset,
    translations,
  );
  return (
    <div className={classNames} ref={timerRef}>
      {initialDiff}
    </div>
  );
};

export default memo(CountdownTimer);
