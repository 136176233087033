import React from 'react';
import { prizeDropCSSNS } from '../../PrizeDropCellWidgetConstants';

interface Props {
  message?: string;
}

const NoDataMessage: React.FC<Props> = ({ message }) => {
  return message ? (
    <div className={`${prizeDropCSSNS}-widget__campaigns--wrapper`}>
      <div className={`${prizeDropCSSNS}-widget__campaigns-no-data`}>{message}</div>
    </div>
  ) : (
    <></>
  );
};

export default NoDataMessage;
