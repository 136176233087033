import React from 'react';
import { UI } from 'components/shared/UI';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { getIconSrc } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/utils/categoryBreadcrumbUtils';
import { useGridLayoutSettings } from 'modules/casino/shared/components/gridLayoutSettings/hooks/useGridLayoutSettings';
import { Flex } from 'modules/casino/shared/components/layout';
import { Text } from 'modules/casino/shared/components/text';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';

interface Props {
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
}

const ParallaxLobbyTitle: React.FC<Props> = ({ categoryId, categoryLayoutType }) => {
  const { title, iconLocation, titleColor } = useGetCategoryParams(categoryId, categoryLayoutType);
  const iconSrc = getIconSrc(iconLocation);
  const { enableColorIcons } = useGridLayoutSettings();
  const usePng = enableColorIcons && iconSrc && iconSrc.split('.').pop()?.toLowerCase() === 'png';

  const iconComponent = usePng ? (
    <UI.PNGCDN className="lobby-category-header__title-icon" icon={iconSrc} />
  ) : (
    <UI.SVGCDN className="lobby-category-header__title-icon" icon={iconSrc} />
  );

  return (
    <Flex.ColCenter>
      <div className="lobby-category-header__title">
        {iconSrc && iconComponent}
        <Text
          text={title}
          style={{ color: titleColor }}
          type={categoryLayoutType == CategoryLayoutTypes.LOBBY ? 'h1' : undefined}
        />
      </div>
    </Flex.ColCenter>
  );
};

export default ParallaxLobbyTitle;
