import { memo } from 'react';

import Body from './Body';
import Header from './Header';
import ProgressBar from './ProgressBar';
import Table from './Table';
import TableRow from './TableRow';
import TextRow from './TextRow';
import Wrapper from './Wrapper';

export const BonusLogUI = {
  Body,
  Header: memo(Header),
  ProgressBar: memo(ProgressBar),
  Table,
  TableRow: memo(TableRow),
  TextRow: memo(TextRow),
  Wrapper,
};
