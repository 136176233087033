import React from 'react';
import { useTranslation } from 'react-i18next';
import NoDataMessage from 'components/shared/NoDataMessage';
import { PrizeDropTypes } from 'shared/common/features/PrizeDrop/types/prizeDrop.types';
import { isEmpty } from 'utils/common/helpersCommon';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import PrizeDropTabsPrimary from './PrizeDropTabsPrimary';
import { PrizeDropUI } from '..';

type Props = {
  campaignStatus?: PrizeDropTypes.StateStatus;
  rewards?: PrizeDropTypes.Rewards[];
  rewardedPlayers?: PrizeDropTypes.PersonalRewards[];
  personalRewards?: PrizeDropTypes.PersonalRewards[];
  terms: PrizeDropTypes.Terms | null;
  activeCampaign: PrizeDropTypes.Campaign | undefined;
  campaignsIds: number[];
  status: PrizeDropTypes.StateStatus;
  handleClose: () => void;
};

const PrizeDropCampaignRightCol: React.FC<Props> = ({
  campaignStatus,
  activeCampaign,
  personalRewards = [],
  rewards = [],
  rewardedPlayers = [],
  terms,
  campaignsIds,
  status,
  handleClose,
}) => {
  const { t } = useTranslation();

  return (
    <egtd-przd-modal-campaigns-right-col>
      <PrizeDropUI.Header
        handleClose={handleClose}
        title={activeCampaign?.title || ''}
        size="lg"
        className="pl-1 pt-1 pb-2 pr-1"
        iconResource={activeCampaign?.iconResource || ''}
      />
      {isEmpty(campaignsIds) && status !== 'idle' ? (
        <NoDataMessage message={t(Promotions.PrizeDrop.noInformation)} />
      ) : activeCampaign ? (
        <PrizeDropTabsPrimary
          iconResource={activeCampaign.iconResource}
          campaignStatus={campaignStatus}
          personalRewards={personalRewards}
          rewards={rewards}
          rewardedPlayers={rewardedPlayers}
          terms={terms}
        />
      ) : null}
    </egtd-przd-modal-campaigns-right-col>
  );
};

export default PrizeDropCampaignRightCol;
