import { useAppSelector } from 'store';
import { selectAnexData } from './slice/anexReport.slice';

export const useAnexStatusInfo = () => {
  const anexInfo = useAppSelector(selectAnexData.anexInfo);
  const anexInfoStatus = useAppSelector(selectAnexData.anexInfoStatus);
  const anexInfoErrorMessage = useAppSelector(selectAnexData.anexInfoErrorMessage);

  return {
    anexInfo,
    anexInfoStatus,
    anexInfoErrorMessage,
  };
};
