import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import { useMouseObserver } from 'modules/casino/shared/hooks/useMouseObserver';
import { GameCellRestrictions } from '../../GameCellRestriction/gameCellRestriction.types';

export const useGameButtons = ({
  gameCellRef,
  gameUniqueId,
  categoryId,
  showInfoPage,
}: {
  gameCellRef: React.MutableRefObject<HTMLDivElement | null>;
  gameUniqueId: string;
  categoryId: string;
  showInfoPage: boolean;
}): {
  hidePlayButton: boolean;
  availableForFreeplay: boolean;
  hideButtons: boolean;
  gameBetType: GameCellRestrictions.ProductType;
} => {
  const element = gameCellRef.current;
  const { availableForFreeplay, customFreePlay, betType } = useGameProps({
    gameUniqueId,
    categoryId,
  });
  const showButtons = !!element || false;
  const { startTouch, onTouchInside, onTouchOutside } = useMouseObserver(showButtons);

  let touchStartX: number;
  let touchStartY: number;
  let touchMoved: boolean;

  const handleTouchStart = (ev) => {
    if (element && !element.contains(ev.target)) {
      onTouchOutside(ev);
    } else {
      touchStartX = ev.touches[0].clientX;
      touchStartY = ev.touches[0].clientY;
      touchMoved = false;
    }
  };

  const handleTouchMove = (ev) => {
    const currentX = ev.touches[0].clientX;
    const currentY = ev.touches[0].clientY;

    // Calculate the distance moved
    const distance = Math.sqrt(Math.pow(currentX - touchStartX, 2) + Math.pow(currentY - touchStartY, 2));
    const moveThreshold = 10;
    if (distance > moveThreshold) {
      touchMoved = true;
    }
  };

  const handleTouchEnd = (ev) => {
    if (!touchMoved) {
      onTouchInside(ev, element);
    }
  };

  useEffect(() => {
    if (element && isMobile) {
      document.addEventListener('touchstart', handleTouchStart);
      element.addEventListener('touchmove', handleTouchMove);
      element.addEventListener('touchend', handleTouchEnd);

      return () => {
        document.removeEventListener('touchstart', handleTouchStart);
        element.removeEventListener('touchmove', handleTouchMove);
        element.removeEventListener('touchend', handleTouchEnd);
      };
    }
  }, [element, onTouchInside, onTouchOutside]);

  useEffect(() => {
    if (element && isMobile) {
      startTouch
        ? element.classList.add('casino-grid-item--hover')
        : element.classList.remove('casino-grid-item--hover');
    }
  }, [startTouch, element]);

  return {
    hidePlayButton: customFreePlay,
    availableForFreeplay: availableForFreeplay,
    hideButtons: (isMobile && !startTouch) || !showButtons || showInfoPage,
    gameBetType: betType,
  };
};
