import { MFA_Channels } from '../../myProfile/types/myProfile.types';

const hideString = ({ text, type }: { text?: string; type?: MFA_Channels }) => {
  if (!type) return '';

  const symbols = '****';
  if (text && type) {
    if (type === 'email') {
      const emailSplit = text.split('@');
      const email = emailSplit[0].substring(emailSplit[0].length - 4);

      return symbols + email + '@' + emailSplit[1];
    } else {
      return symbols + text.substring(text.length - 4);
    }
  }
  return '';
};

const splitCode = ({ code, numberSymbol = 6 }: { code?: string; numberSymbol?: number }) => {
  if (code) {
    return [...Array.from(code), ...Array.from(Array(6 - code.length))];
  } else {
    return Array.from(Array(numberSymbol));
  }
};

export { hideString, splitCode };
