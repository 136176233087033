import type { InputLocationType } from '../../formTypes';
import type { Social, SocialButtonInputTypes } from '../FormSocial/types/formSocial.types';

export enum ButtonGroupNames {
  SOCIAL = 'socialMediaCredentials',
  OTHER = 'other',
}

export type ButtonGroupButtons = Social.Button;

type Common = {
  name: ButtonGroupNames;
  inputType: SocialButtonInputTypes;
  componentLocation?: InputLocationType;
  disabled?: boolean;
};

export type ButtonGroup = Common & {
  label?: string;
  endLabel?: string;
  buttons?: Social.Button[];
};

export type ButtonGroupControl = Common & {
  button: Social.Button;
};
