import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { Flex } from 'components/shared/layout';
import { useSetDate } from 'pages/captainUp/features/Store/hooks/useSetDate';
import { GameJourney } from 'utils/common/translationUtils/translationStrings';
import { cssGamificationNS } from 'utils/common/types/css.types';
import { TimeDataType, formatDateUTC } from 'utils/dateUtils';

type Props = {
  startDate: string;
  dateDifference: TimeDataType;
  isEndDate?: boolean;
};

export const DateDifference = ({ startDate, dateDifference, isEndDate }: Props) => {
  const text = !isEndDate ? 'afterText' : 'remains';

  const { t } = useTranslation();

  const [inViewRef, inView] = useInView({
    rootMargin: '200px 0px',
  });
  const date = useSetDate(startDate, dateDifference.days, inView, dateDifference.days === 0);
  const time = formatDateUTC('HH:mm:ss', date, true);

  let dayText: string | number = '0';
  if (dateDifference.days != -1 && !isNaN(dateDifference.days)) {
    if (inView) {
      if (date < 0) {
        dayText = '00:00:00';
      } else {
        dayText = dateDifference.days === 0 ? time : dateDifference.days;
      }
    }
  }

  const classes = clsx(`${cssGamificationNS}-tag__difference`);

  return (
    <Flex.AC className={classes} ref={inViewRef}>
      <Flex.AC className={`${cssGamificationNS}-tag ${cssGamificationNS}-tag__difference__wrapper`}>
        {t(GameJourney.Common[text])} {dayText}{' '}
        {dateDifference.days > 1
          ? t(GameJourney.Common.daysText)
          : dateDifference.days === 1
            ? t(GameJourney.Common.dayText)
            : ''}
      </Flex.AC>
    </Flex.AC>
  );
};
