import { useMemo } from 'react';
import { isError, isInitial, isPending, isSuccess, isEmptyResponse } from '../utils/casinoUtils';

export const useLoadingState = (loadingState) => {
  const hasInitial = useMemo(() => {
    return isInitial(loadingState);
  }, [loadingState]);
  const isLoading = useMemo(() => {
    return isPending(loadingState);
  }, [loadingState]);

  const hasSuccess = useMemo(() => {
    return isSuccess(loadingState);
  }, [loadingState]);

  const hasError = useMemo(() => {
    return isError(loadingState);
  }, [loadingState]);

  const hasNoGames = useMemo(() => {
    return isEmptyResponse(loadingState);
  }, [loadingState]);

  return { hasInitial, isLoading, hasSuccess, hasError, hasNoGames };
};
