import React from 'react';
import clsx from 'clsx';
import styled, { css } from 'styled-components';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { Breakpoints } from 'theme/Theme';
import { Sport } from 'utils/common/types/sportTypes';
import Icon from './Icon';

interface Props {
  type: 'prev' | 'next';
}

const SwiperDirectionButton: React.FC<Props> = ({ type }) => {
  const layoutType = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.LAYOUTTYPECONFIG,
    deviceLayout: 'general',
  })?.sport?.carousel;

  const layoutStyleType = layoutType || 'layout_1';
  const buttonStyles = clsx(
    type === 'prev' && 'swiper-button-prev btn-prev',
    type === 'next' && 'swiper-button-next btn-next rotated-180',
  );

  return (
    <SwiperArrows className={buttonStyles} type={type} $layoutType={layoutStyleType}>
      <Icon className="swiper-button__icon" icon="chevron" />
    </SwiperArrows>
  );
};

const prevType1 = css`
  left: 0;
`;
const nextType1 = css`
  right: 0;
`;

const prevType2 = css`
  left: 10px;
`;

const nextType2 = css`
  right: 10px;
  transform: translateY(-50%) rotate(180deg);
`;
const mapPrevArrow = {
  layout_1: prevType1,
  layout_2: prevType2,
};
const mapNextArrow = {
  layout_1: nextType1,
  layout_2: nextType2,
};

const arrowLayout_1 = css`
  width: 24px;
  height: 100%;
  top: 0;
  transition: background 0.15s;
`;

const arrowLayout_2 = css`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  transform: translateY(-50%);
`;

const mapArrow = {
  layout_1: arrowLayout_1,
  layout_2: arrowLayout_2,
};

const SwiperArrows = styled.div<{ $layoutType: Sport.SliderLayoutType; type: 'prev' | 'next' }>`
  display: none;
  border-radius: 0;
  margin-top: 0;

  &:after {
    content: '';
  }
  &.swiper-button-disabled {
    display: none;
  }
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    display: flex;
  }
  ${({ $layoutType }) => mapArrow[$layoutType]}
  ${({ type, $layoutType }) => (type === 'prev' ? mapPrevArrow[$layoutType] : '')}
  ${({ type, $layoutType }) => (type === 'next' ? mapNextArrow[$layoutType] : '')}
`;

// const iconLayout1 = css`
//   width: 18px;
//   height: 18px;
//   transform: rotate(90deg);
// `;
// const iconLayout2 = css``;

// const mapIcon = {
//   layout_1: iconLayout1,
//   layout_2: iconLayout2,
// };
// const StyledIcon = () => css``;
export default SwiperDirectionButton;
SwiperDirectionButton.displayName = 'SwiperDirectionButton';
