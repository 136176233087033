import React from 'react';
import InGameCategoryWrapper from 'modules/casino/modules/container/cellsContainer/containers/external/InGameCategories/InGameCategoryWrapper';
import { useCasinoInGameFavouritesCategory } from 'modules/casino/modules/container/cellsContainer/containers/inner/components/containerTypes/favourites/hooks/useCasinoInGameFavouritesCategory';
import CasinoContainerImplementation from 'modules/casino/platformImplementation/casinoWidgetTypes/CasinoContainerImplementation';
import useCasinoWidgetConfig from 'modules/casino/platformImplementation/hooks/useCasinoWidgetConfig';
import { WidgetContainerTypes } from 'modules/casino/platformImplementation/types/casinoWidget.types';
import FavouritesGridWrapper from './FavouritesGridWrapper';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from '../../../../../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';

const InGameFavouriteGames: React.FC = () => {
  const { categoryId, gamesCount, isLoading, hasSuccess, hasNoGames } = useCasinoInGameFavouritesCategory();
  const casinoWidgetConfig = useCasinoWidgetConfig();

  if (casinoWidgetConfig) {
    return (
      <CasinoContainerImplementation
        containerKey={'in-game-fav'}
        containerType={WidgetContainerTypes.CATEGORY}
        params={{
          categoryType: CategoryLayoutTypes.EXTERNAL_CATEGORY,
          subType: CategorySubTypes.IN_GAME_FAVOURITES,
        }}
      />
    );
  }
  if (!categoryId) {
    return null;
  }
  return (
    <InGameCategoryWrapper>
      <FavouritesGridWrapper
        categoryId={categoryId}
        subType={CategorySubTypes.IN_GAME_FAVOURITES}
        categoryLayout={CategoryLayoutTypes.IN_GAME_FAVOURITES_CATEGORY}
        gamesCount={gamesCount}
        isLoading={isLoading}
        hasSuccess={hasSuccess}
        hasNoGames={hasNoGames}
        className={`${CategorySubTypes.IN_GAME_FAVOURITES.toLocaleLowerCase()}_external_category`}
        handleBackBtnClick={() => {}}
      />
    </InGameCategoryWrapper>
  );
};

export default InGameFavouriteGames;
