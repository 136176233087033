import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { Registration } from 'utils/common/translationUtils/translationStrings';
import { ConfirmationThunks, resetConfirmationState, selectConfirmation } from '../slice/confirmation.slice';

interface Props {
  emailOrUsername: string | null;
  containerClass?: string;
  linkClass?: string;
  componentLocation?: string;
}

const ResendEmail: React.FC<Props> = ({ emailOrUsername, containerClass, linkClass, componentLocation }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const resendEmail = useAppSelector(selectConfirmation.resendEmail);
  const confirmationError = useAppSelector(selectConfirmation.submitError);

  useEffect(() => {
    return () => {
      dispatch(resetConfirmationState());
    };
  }, [dispatch]);

  const resendEmailHandler = (e) => {
    e.preventDefault();
    if (emailOrUsername) {
      dispatch(ConfirmationThunks.resendConfirmationEmail({ emailOrUsername }));
    }
  };

  if (confirmationError && componentLocation === 'login') return null;

  return (
    <div className={containerClass}>
      {t(Registration.Confirmation.notRecievedEmailLabel)}
      {componentLocation !== 'login' ? <br /> : ' '}
      <Link to="#" onClick={resendEmailHandler} className={linkClass}>
        {t(Registration.Confirmation.notRecievedEmailLink)}
      </Link>{' '}
      {t(Registration.Confirmation.notRecievedEmailPostLinkText)}
      {componentLocation == 'login' && <br />}
      {resendEmail && <p className="mt-1">{t(Registration.Confirmation.successfullyResendedEmail)}</p>}
    </div>
  );
};

export default ResendEmail;
