import styled from 'styled-components';
import { prizeDropCSSNS } from 'components/shared/prizeDrop/prizeDropUICSS.types';
import { Breakpoints } from 'theme/Theme';

export const promoCSSNS = 'prm';

const SCHeader = styled.div<{ $hasDate?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ $hasDate }) => ($hasDate ? 'space-between' : 'center')};
  align-items: center;

  padding: ${({ $hasDate }) => ($hasDate ? '0 0 10px 0' : '0')};

  .prm-page__header--text {
    text-align: center;
  }

  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    flex-direction: row;
    justify-content: ${({ $hasDate }) => ($hasDate ? 'space-between' : 'center')};
    padding: 0 20px;
    .prm-page__header--text {
      padding: 20px 0;
    }
  }

  //Prize drop counter size only for promo header
  .egtd-przd-counter-wrap {
    padding-top: 12px;
    padding-left: 0;
    padding-right: 0;
    border-top-width: ${({ $hasDate }) => ($hasDate ? `1px` : '0')};
    border-top-style: ${({ $hasDate }) => ($hasDate ? `solid` : 'none')};
    border-color: var(--${prizeDropCSSNS}-counter-border-color);
    font-size: var(--${prizeDropCSSNS}-text-sm);
    @media screen and (min-width: ${Breakpoints.isDesktop}px) {
      border: none;
    }

    @media screen and (min-width: 900px) {
      font-size: var(--${prizeDropCSSNS}-text-lg);
    }
    .egtd-przd-counter-display {
      @media screen and (min-width: 900px) {
        column-gap: 20px;
      }
    }

    .egtd-przd-counter-item {
      &:not(&:last-child) {
        :after {
          right: -8px;
          line-height: 1.1rem;

          @media screen and (min-width: 900px) {
            right: -14px;
            font-size: 32px;
            line-height: 1.5rem;
            font-weight: 600;
          }
        }
      }

      .egtd-przd-counter-item--date {
        width: 40px;
        padding: 1px 2px;

        @media screen and (min-width: 900px) {
          width: 48px;
          font-size: 20px;
        }
      }
      .egtd-przd-counter-item--text {
        font-size: 10px;
        @media screen and (min-width: 900px) {
          font-size: 12px;
        }
      }
    }
  }
`;

export { SCHeader };
