import { useRef } from 'react';
import { useInitialMount } from './useInitialMount';

export const usePreviousNew = <T>(value: T) => {
  // use refs to keep track of both the previous &
  // current values
  const prevRef = useRef<T>();
  const curRef = useRef<T>(value);
  const isInitialMount = useInitialMount();

  // after the initial render, if the value passed in
  // differs from the `curRef`, then we know that the
  // value we're tracking actually changed. we can
  // update the refs. otherwise if the `curRef` &
  // value are the same, something else caused the
  // re-render and we should *not* update `prevRef`.
  if (!isInitialMount && curRef.current !== value) {
    prevRef.current = curRef.current;
    curRef.current = value;
  }

  return prevRef.current;
};
