import { memo } from 'react';

import Accordion from './Accordion';
import AccordionRow from './AccordionRow';
import BlockButton from './BlockButton';
import Column from './Column';
import Empty from './Empty';
import Header from './Header';
import Management from './Management';
import { Wrapper } from './Wrapper';

const CTAUI = {
  Wrapper: Wrapper,
  BlockButton: BlockButton,
  Column: Column,
  Management: memo(Management),
  Header: memo(Header),
  Empty: Empty,
  Accordion: Accordion,
  AccordionRow: AccordionRow,
};

export default CTAUI;
