import React, { memo } from 'react';
import { StyledCTAButton, StyledPlaceholder } from './CTAButton.styled';

interface Props {
  buttonText?: string;
  disabled: boolean;
  errorText?: string;
  onClick: () => void;
  isVisible: boolean;
  showPlaceholder?: boolean;
  statusCode?: number;
  text?: string;
  bgColor?: string;
  textColor?: string;
  borderColor?: string;
}

const CTAButton: React.FC<Props> = ({
  buttonText,
  disabled,
  errorText,
  onClick,
  isVisible,
  showPlaceholder = true,
  statusCode,
  text,
  bgColor,
  textColor,
  borderColor,
}) => {
  const showButton = (!errorText || statusCode === 511) && isVisible && buttonText;
  const btnStyle = {
    ...(bgColor ? { background: bgColor } : {}),
    ...(textColor ? { color: textColor } : {}),
    ...(borderColor ? { border: `1px solid ${borderColor}` } : {}),
  };
  return (
    <>
      {showButton ? (
        <StyledCTAButton disabled={disabled} onClick={onClick} style={btnStyle}>
          {buttonText}
        </StyledCTAButton>
      ) : showPlaceholder ? (
        <StyledPlaceholder style={btnStyle}>{text}</StyledPlaceholder>
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(CTAButton);
