import { useCategoryGames } from './useCategoryGames';
import { CategoryLayoutTypes } from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { CasinoGamesTypes } from '../types/casinoGamesTypes';

export const useGameProps = ({
  gameUniqueId,
  categoryId,
}: {
  gameUniqueId: string;
  categoryId: string;
}): CasinoGamesTypes.GameProps => {
  const layoutRegExp = new RegExp('#(.*?)#');
  const matched = gameUniqueId?.match(layoutRegExp);
  const categoryLayoutType = (matched && matched[1]) as CategoryLayoutTypes;
  const games = useCategoryGames({ categoryLayoutType, categoryId });

  return { ...games[gameUniqueId] };
};
