import { useTranslation } from 'react-i18next';
import { updateNotifications } from 'shared/common/features/inGameNotifications/slice/inGameNotifications.slice';
import { WsNotificationsTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/types/wsNotifications.types';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch } from 'store';
import { fitCover } from 'utils/common/cdnConfig';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import config from 'utils/config';
import BonusCollectionsModalFooter from './components/BonusCollectionsModalFooter';
import BonusCollectionsModalHeader from './components/BonusCollectionsModalHeader';
import BonusCollectionsModalWin from './components/BonusCollectionsModalWin';
import { bonusCollectionModalPrefix } from './utils/bonusCollectionsModal.utils';
import { Modal } from '../Modal';

type Props = { activeNotification: WsNotificationsTypes.Notification };

export const BonusCollectionsModal: React.FC<Props> = ({ activeNotification }) => {
  const bonus = activeNotification?.BonusData?.bonuses?.[0];
  const folder = activeNotification?.Style.folder ?? 'bonus-collection';
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(updateNotifications(activeNotification.Id));
    dispatch(hideRootModal());
  };

  const handleGoToPromo = () => {
    handleClose();
    window.open(
      `/promotions/${bonus?.categoryAlias?.toLowerCase()}/${bonus?.promotionAlias?.toLowerCase()}?tab=conditions`,
      '_blank',
    );
  };
  const iconPath = `${config.CDN_IMAGE_API}${fitCover(
    120 * 2,
    120 * 2,
    'cover',
  )}${`/common-content/gamification/${folder}/group-${bonus.groupFolderIndex}/level-${bonus.levelFolderIndex}/symbol-active.png`}`;

  return (
    <Modal visible dialogClassName="modal-dialog-centered">
      <div className={`${bonusCollectionModalPrefix}`}>
        <BonusCollectionsModalHeader
          title={bonus?.campaignName || ''}
          handleInfo={() => {}}
          handleClose={handleClose}
        />
        <BonusCollectionsModalWin text={activeNotification?.Message || ''} title={bonus?.title} icon={iconPath} />
        <BonusCollectionsModalFooter
          infoText={`${t(Promotions.PrizeDrop.winBoxInfoLink)}`}
          btnText={activeNotification?.Button?.buttonText || t(Promotions.PrizeDrop.continueButton)}
          infoLinkText={bonus?.campaignName || ''}
          handleInfo={handleGoToPromo}
          handleClose={handleClose}
        />
      </div>
    </Modal>
  );
};
