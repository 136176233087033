import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loginThunks } from 'pages/auth/login/slice/login.slice';
import { General, LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getBusinessUnit, isEmpty } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { Currency } from '../../myProfile/types/myProfile.types';

const initialState: General.State = {
  isSessionTimerActive: true,
  layoutConfig: window.layout,
  isOpenChat: false,
  buSettings: null,
  netAppHeader: '',
  currencySettings: null,
  isGeolocationRestricted: false,
  realityCheckConfirmedAt: null,
};

export const fetchBusinessUnitSettings = createAbortThunk<General.BusinessUnitSettings, void>(
  'general/fetchBusinessUnitSettings',
  async () => {
    const url = `${config.API_URL}/api/cms/public/business-units/general-settings?businessUnit=${getBusinessUnit()}`;
    const response = await axiosInstance.get(url);
    return response.data;
  },
);

export const fetchCurrencySettings = createAbortThunk<Currency[], void>('general/fetchCurrencySettings', async () => {
  const url = `${config.API_URL}/api/ews-crm/public/currency/extended`;

  const response = await axiosInstance.get(url);
  return response.data;
});

export const fetchGeolocationStatus = createAbortThunk<boolean, void>(
  'general/fetchGeolocationStatus',
  async (_, { dispatch }) => {
    const url = `${config.API_URL}/api/ews-crm/player/players/player-geolocation-data`;
    const response = await axiosInstance.get(url);
    response.data === 'true' && (await dispatch(loginThunks.logout()));
    return response.data;
  },
);

const general = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setIsSessionTimerActive(state, action: PayloadAction<boolean>) {
      state.isSessionTimerActive = action.payload;
    },
    setLayoutConfig(
      state,
      action: PayloadAction<
        Record<General.LayoutConfigurationKeys, General.LayoutConfiguration> | General.NewLyoutCinfigType
      >,
    ) {
      state.layoutConfig = action.payload;
    },
    setIsOpenChat(state, action: PayloadAction<boolean>) {
      state.isOpenChat = action.payload;
    },
    setNetAppHeader(state, action: PayloadAction<string>) {
      state.netAppHeader = action.payload;
    },
    setRealityCheckConfirmedAt(state, action: PayloadAction<number>) {
      state.realityCheckConfirmedAt = action.payload;
    },
    resetNetAppHeader(state) {
      state.netAppHeader = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBusinessUnitSettings.fulfilled, (state, action) => {
      // TODO REMOVE - When CMS and CRM integrate it
      if (!action.payload.regulationCasinoGameSettings || !action.payload.regulationCasinoGameSettings.length) {
        action.payload.regulationCasinoGameSettings = action.payload.regulationGameLaunchSettings ? ['CASINO'] : null;
      }

      state.buSettings = action.payload;
    });
    builder.addCase(fetchCurrencySettings.fulfilled, (state, action) => {
      state.currencySettings = action.payload;
    });
    builder.addCase(fetchGeolocationStatus.fulfilled, (state, action) => {
      state.isGeolocationRestricted = action.payload;
    });
  },
});

export const {
  setIsSessionTimerActive,
  setIsOpenChat,
  setLayoutConfig,
  setNetAppHeader,
  resetNetAppHeader,
  setRealityCheckConfirmedAt,
} = general.actions;

export const selectGeneral = {
  buSettings: (state: RootState) => state.common.general.buSettings,
  noBuSettings: (state: RootState) => isEmpty(state.common.general.buSettings),
  isBuMfaEnabled: (state: RootState) => state.common.general.buSettings?.isMfaEnabled,
  isMfaEnabledExtended: (state: RootState) => state.common.general.buSettings?.isMfaEnabledExtended,
  mfaChannelsExtended: (state: RootState) => state.common.general.buSettings?.mfaChannelsExtended,
  isIpGeolocationTrackingEnabled: (state: RootState) => state.common.general.buSettings?.isIpGeolocationTrackingEnabled,
  isGeolocationRestricted: (state: RootState): boolean => state.common.general.isGeolocationRestricted,
  isMultipleAccountTrackingEnabled: (state: RootState) =>
    state.common.general.buSettings?.isMultipleAccountTrackingEnabled,
  confirmPhone: (state: RootState) => state.common.general.buSettings?.confirmPhone,
  phoneOnlyConfirmation: (state: RootState) => state.common.general.buSettings?.phoneOnlyConfirmation,
  availableRestrictionProducts: (state: RootState) => state.common.general.buSettings?.availableRestrictionProducts,
  isSessionTimerActive: (state: RootState): boolean => state.common.general.isSessionTimerActive,
  netAppHeader: (state: RootState) => state.common.general.netAppHeader,
  realityCheckConfirmedAt: (state: RootState) => state.common.general.realityCheckConfirmedAt,
  isLayoutConfigLoaded: (state: RootState) => !!state.common.general?.layoutConfig,
  moduleLayout:
    <K extends keyof General.LayoutConfiguration>(
      moduleLayout: General.ModuleConfigurationKeys,
      deviceLayout: General.LayoutConfigurationKeys,
      innerLayout: K,
    ) =>
    (state: RootState): General.LayoutConfiguration[K] =>
      state.common.general?.layoutConfig?.[moduleLayout]?.[deviceLayout]?.[innerLayout],
  selectIsOpenChat: (state: RootState): boolean => state.common.general.isOpenChat,
  isPollingEnabled: createSelector(
    (state: RootState) =>
      (state.common.general?.layoutConfig as General.NewLyoutCinfigType)?.platform?.general?.[
        LayoutConfigTypes?.POLLING
      ]?.authenticated,
    (state: RootState) => state.auth.login?.isAuthenticated,
    (isPollingForAuthenticatedEnabled, isAuthenticated) =>
      !isAuthenticated || (isAuthenticated && isPollingForAuthenticatedEnabled),
  ),
  currencySettings: (state: RootState): Currency[] | null => state.common.general.currencySettings,
};

export default general.reducer;
