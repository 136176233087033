import React, { memo, useRef } from 'react';
import clsx from 'clsx';
import { ScrollContainer } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { arrowStyleTypes } from 'components/shared/ScrollContainer';
import NavItemsGroup from './NavItemsGroup';
import DevAPISourceSwitcher from '../mainNavbar/DevAPISourceSwitcher';
import SkinSwitcher from '../mainNavbar/SkinSwitcher';

enum NavItemGroupAlign {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

interface Props {
  isEmpty: boolean;
  className?: string;
}

const TopNavbarMenu: React.FC<Props> = ({ className, isEmpty }) => {
  const wrapperRef = useRef(null);

  const navbarClass = clsx('no-scrollbars top-navbar__menu', className);
  const placeholderClass = clsx('no-scrollbars top-navbar__menu top-navbar__placeholder', className);

  if (isEmpty) {
    return (
      <Flex.JB className={placeholderClass}>
        <DevAPISourceSwitcher />
        <SkinSwitcher />
      </Flex.JB>
    );
  }

  return (
    <ScrollContainer
      containerRef={wrapperRef}
      classNames={`${navbarClass} d-flex justify-content-between`}
      moveWith={300}
      typeArrow={arrowStyleTypes.ArrowsAlwaysVisible}
      activeClass="top-navbar__link--active"
      staticArrow={true}
    >
      <DevAPISourceSwitcher />
      <SkinSwitcher />
      <NavItemsGroup align={NavItemGroupAlign.LEFT} wrapperRef={wrapperRef} />
      <NavItemsGroup align={NavItemGroupAlign.CENTER} wrapperRef={wrapperRef} />
      <NavItemsGroup align={NavItemGroupAlign.RIGHT} wrapperRef={wrapperRef} />
    </ScrollContainer>
  );
};

export default memo(TopNavbarMenu);
