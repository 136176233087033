import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useFetchFavouriteGames } from 'modules/casino/modules/container/cellsContainer/cellls/additionalGames/favourites/hooks/useFetchFavouriteGames';
import { favoriteGamesSelectors } from 'modules/casino/modules/container/cellsContainer/cellls/additionalGames/favourites/slice/casinoFavouriteGamesSlice';
import { CasinoFavouritesTypes } from 'modules/casino/modules/container/cellsContainer/cellls/additionalGames/favourites/types/casinoFavouriteGamesTypes';
import { useCasinoGamesFetchStatus } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useCasinoGamesFetchStatus';
import { useCasinoCategoryFetch } from 'modules/casino/modules/container/cellsContainer/containers/inner/hooks/useCasinoCategoryFetch';
import { useCategoryBreadcrumbData } from 'modules/casino/modules/container/cellsContainer/containers/inner/hooks/useCategoryBreadcrumbData';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { useShowCarousel } from 'modules/casino/modules/lobby/containersLobby/hooks/useShowCarousel';
import { handleBackButtonNavigation } from 'modules/casino/shared/features/gameLaunch/casinoLaunchUtils';
import { useInitialJackpotLevelsFetch } from 'modules/casino/shared/features/jackpots/hooks/useInitialJackpotLevelsFetch';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { Breakpoints } from 'modules/casino/theme/Theme';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppSelector } from 'store';

export const useCasinoFavouritesCategory = ({
  categoryId,
}: CasinoFavouritesTypes.UseCasinoFavouritesCategoryProps): CasinoFavouritesTypes.UseCasinoFavouritesCategoryReturnProps => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isMobile}px)` });
  useInitialJackpotLevelsFetch();
  useCasinoCategoryFetch({ categoryId, subType: CategorySubTypes.STANDARD });
  const { query, title, layoutConfig } = useGetCategoryParams(categoryId, CategoryLayoutTypes.FAVOURITES_CATEGORY);
  const { isLoading, hasSuccess, hasNoGames, hasError } = useCasinoGamesFetchStatus(
    categoryId,
    CategoryLayoutTypes.FAVOURITES_CATEGORY,
  );
  useFetchFavouriteGames({
    query,
    layoutConfig,
    subType: CategorySubTypes.STANDARD,
    hasSuccess,
    hasError,
    hasNoGames,
  });

  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const gamesCount = useAppSelector(favoriteGamesSelectors.favoriteGamesCount);
  const isFavouriteGamesModalOpen = useAppSelector(favoriteGamesSelectors.isFavouriteGamesModalOpen);

  const handleBackBtnClick = () => {
    handleBackButtonNavigation(navigate);
  };

  useEffect(() => {
    if (!isAuthenticated || isFavouriteGamesModalOpen) {
      const casinoHome = window.location.pathname.split('/')[1];
      navigate(`/${casinoHome}`, { replace: true });

      return;
    }
  }, [isAuthenticated, isFavouriteGamesModalOpen]);

  useCategoryBreadcrumbData(gamesCount, title);
  useShowCarousel([!isMobile, !isMobile]);

  return { isLoading, hasSuccess, hasNoGames, gamesCount, handleBackBtnClick };
};
