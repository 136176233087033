import FormActions from './FormActions';
import FormGroup from './FormGroup';
import FormWrapper from './FormWrapper';
import Notification from './Notification';
import NotificationLine from './NotificationLine';
import Page from './Page';
import Panel from './Panel';
import PanelBody from './PanelBody';
import PanelHeader from './PanelHeader';
import PanelWrapper from './PanelWrapper';
import Text from './Text';

export const AuthUI = {
  FormActions: FormActions,
  FormGroup: FormGroup,
  FormWrapper: FormWrapper,
  Notification: Notification,
  NotificationLine: NotificationLine,
  Page: Page,
  PanelHeader: PanelHeader,
  PanelBody: PanelBody,
  PanelWrapper: PanelWrapper,
  Panel: Panel,
  Text: Text,
};
