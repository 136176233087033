import React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { BadgeVariants, badgeVariantsLayout2Map } from '../badgeTypes';

//** Component CSS Namespace */
interface Props {
  variant?: BadgeVariants;
  className?: string;
  label: React.ReactNode | string;
  children?: React.ReactNode;
  title?: string;
  handleOnClick: () => void;
}
export const BadgeLayout_2: React.FC<Props> = ({ variant, className, label, children, title, handleOnClick }) => {
  const badgeClass = clsx('font-weight-normal', variant && badgeVariantsLayout2Map[variant].css, className);

  return (
    <StyledSpan className={badgeClass} variant={variant} onClick={handleOnClick} title={title}>
      <>
        {label}
        {children && children}
      </>
    </StyledSpan>
  );
};

const StyledSpan = styled('span')<{ variant?: BadgeVariants }>`
  ${(p) =>
    p.variant !== 'circle' &&
    `
    padding: 2px 12px !important;
    `}
`;
