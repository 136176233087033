import { useRef, FC } from 'react';
import Icon from 'components/shared/Icon';
import DefaultChevron from 'components/shared/icons/DefaultChevron';
import { CasinoScroll } from '../casinoScrollTypes';
import { scrollWithDirection } from '../casinoScrollUtils';

const RightArrow: FC<CasinoScroll.ArrowProps> = ({ scrollEl, typeArrow, useDefaultChevron, moveWith }) => {
  const arrowRightRef = useRef<HTMLDivElement>(null);

  const scrollToRight = () => {
    scrollWithDirection(scrollEl, false, moveWith);
  };

  return (
    <div className={`scroll-button right-arrow ${typeArrow}-arrow-style `} ref={arrowRightRef} onClick={scrollToRight}>
      <div className="arrow-icon">
        {useDefaultChevron ? (
          <DefaultChevron className={`icon right-direction`} />
        ) : (
          <Icon className={`icon right-direction`} icon={'chevron-right'} />
        )}
      </div>
    </div>
  );
};

export default RightArrow;
