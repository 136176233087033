import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppDispatch } from 'store';
import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators/createAbortThunk';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import {
  CashierInitTypes,
  CashierThunks,
  DocumentEventInfo,
  PaperDraftInfo,
  PosInfo,
  ReadDocumentInfo,
  TerminalActivity,
} from '../types/cashier.types';

const initialState: CashierInitTypes = {
  payoutTicketInfo: null,
  payoutTicketStatus: 'idle',
  payoutTicketError: '',
  cashierPayoutStatus: 'idle',
  cashierPayoutStatusError: '',
  cashierCancelTicketStatus: 'idle',
  cashierCancelTicketStatusError: '',
  paperDraftInfo: null,
  paperDraftStatus: 'idle',
  paperDraftError: '',
  documentEventInfo: null,
  readDocumentInfo: null,
  paperCouponId: '',
  terminalActivity: 'Active',
  posInfo: null,
  isTerminalActive: null,
  isQuietHours: null,
  isPrintingEject: false,
  isCashierModalPrompted: false,
  printerBusyMessage: '',
};

export const cashierThunks: CashierThunks = {
  payoutTicket: createAbortThunk(`terminal/payoutTicket`, async ({ code, ucn }) => {
    const url = `${config.API_URL}/api/pretail/agent/v2/ticket/payout`;
    const response = await axiosInstance.post(
      url,
      { code: code, ucn: ucn },
      // {
      //   headers: {
      //     Authorization: `Bearer 4a68d195-3ec2-401f-b9c4-da1ad4a9627f`,
      //     'X-Platform-Origin': 'Default',
      //     'X-Platform-BU': 'EGTT',
      //     'X-Platform-Device': 'terminal',
      //     'X-Platform-TZ': 120,
      //     'X-Platform-Lang': 'en',
      //     'Content-Type': 'application/json',
      //   },
      // },
    );
    return response.data;
  }),
  cancelTicket: createAbortThunk(`terminal/cancelTicket`, async ({ code, reason }) => {
    const url = `${config.API_URL}/api/pretail/agent/v2/ticket/cancel`;
    const response = await axiosInstance.post(url, { code: code, reason: reason });

    return response.data;
  }),
  cashierPayoutTicket: createAbortThunk(`terminal/cashierPayout`, async ({ code }) => {
    const url = `${config.API_URL}/api/pretail/agent/v2/ticket/payout`;
    const response = await axiosInstance.post(url, { code: code });
    return response.data;
  }),
  paperCouponBet: createAbortThunk(
    `cashier/createPaperCouponBet`,
    async ({ ticketType, zones }, { rejectWithValue }) => {
      const url = `${config.API_URL}/api/pretail/public/v2/betslip/create-paper-draft`;
      let response;
      try {
        response = await axiosInstance.post(url, { ticketType: ticketType, zones: zones });
        return response.data;
      } catch (err) {
        return rejectWithValue(getError.responseData(err));
      }
    },
  ),

  statusTerminal: createAbortThunk(`terminal/terminalStatus`, async () => {
    const url = `${config.API_URL}/api/pretail/public/v2/terminal/status`;
    const response = await axiosInstance.get(url);
    return response.data;
  }),
  claimBankPayout: createAbortThunk(`cashier/claimBankPayout`, async ({ code }, { rejectWithValue }) => {
    try {
      const url = `${config.API_URL}/api/pretail/agent/v2/ticket/claim-bank-payout/${code}`;
      const response = await axiosInstance.post(url, { code: code });
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseData(err));
    }
  }),
  betsLimit: createAbortThunk(`cashier/posLimit`, async () => {
    const url = `${config.API_URL}/api/pretail/agent/v2/pos/current`;
    const response = await axiosInstance.get(url);
    return response.data;
  }),
  barcodeScanStart: createAbortThunk(`lotto/barcodeStart`, async (_, { rejectWithValue }) => {
    const url = `http://127.0.0.1:10009/tp30_barcode_start.json`;
    let response;
    try {
      response = await axios.post(url);
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseData(err));
    }
  }),
  getQrCodeCameraResult: createAbortThunk(`lotto/qrCodeResult`, async (_, { rejectWithValue }) => {
    const url = `http://127.0.0.1:10009/tp30_barcode_result.json`;
    let response;
    try {
      response = await axios.post(url);
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseData(err));
    }
  }),
  barcodeScanStop: createAbortThunk(`lotto/barcodeStop`, async (_, { rejectWithValue }) => {
    const url = `	http://127.0.0.1:10009/tp30_barcode_stop.json`;
    let response;
    try {
      response = await axios.post(url);
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseData(err));
    }
  }),
};

const cashierSlice = createSlice({
  name: 'cashierSlice',
  initialState,
  reducers: {
    resetStateDepositTicket(state) {
      state.payoutTicketStatus = 'idle';
    },
    setDocumentEventInfo(state, action: PayloadAction<DocumentEventInfo>) {
      state.documentEventInfo = action.payload;
    },
    setReadDocumentInfo(state, action: PayloadAction<ReadDocumentInfo>) {
      state.readDocumentInfo = action.payload;
    },

    resetPaperDraftStatus(state) {
      state.paperDraftStatus = 'idle';
      state.paperDraftInfo = null;
      state.readDocumentInfo = null;
    },
    resetCashierCancelTicketStatus(state) {
      state.cashierCancelTicketStatus = 'idle';
      state.cashierCancelTicketStatusError = '';
    },
    resetPayoutTicketStatus(state) {
      state.payoutTicketStatus = 'idle';
      state.payoutTicketError = '';
    },
    resetPaperCouponInfo(state) {
      state.paperCouponId = '';
      state.paperDraftInfo = null;
      state.readDocumentInfo = null;
    },
    setIsPrintingEjected: (state, action) => {
      state.isPrintingEject = action.payload;
    },
    setIsCashierModalPrompted: (state, action) => {
      state.isCashierModalPrompted = action.payload;
    },
    setPrinterBusyMessage: (state, action) => {
      state.printerBusyMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(cashierThunks.payoutTicket.fulfilled, (state, action) => {
      state.payoutTicketInfo = action.payload;
      state.payoutTicketStatus = 'succeeded';
    });
    builder.addCase(cashierThunks.payoutTicket.pending, (state) => {
      state.payoutTicketStatus = 'pending';
    });
    builder.addCase(cashierThunks.payoutTicket.rejected, (state, action) => {
      state.payoutTicketError = action.payload;
      state.payoutTicketStatus = 'failure';
    });
    builder.addCase(cashierThunks.cancelTicket.fulfilled, (state) => {
      state.cashierCancelTicketStatus = 'succeeded';
    });
    builder.addCase(cashierThunks.claimBankPayout.pending, (state) => {
      state.payoutTicketStatus = 'pending';
    });
    builder.addCase(cashierThunks.claimBankPayout.rejected, (state, action) => {
      state.payoutTicketStatus = 'failure';
      state.payoutTicketError = action.payload?.errorCode;
    });
    builder.addCase(cashierThunks.claimBankPayout.fulfilled, (state) => {
      state.payoutTicketStatus = 'succeeded';
    });
    builder.addCase(cashierThunks.cancelTicket.pending, (state) => {
      state.cashierCancelTicketStatus = 'pending';
    });
    builder.addCase(cashierThunks.cancelTicket.rejected, (state, action) => {
      state.cashierCancelTicketStatusError = action.payload;
      state.cashierCancelTicketStatus = 'failure';
    });
    builder.addCase(cashierThunks.paperCouponBet.fulfilled, (state, action: PayloadAction<PaperDraftInfo>) => {
      state.paperDraftInfo = action.payload;
      state.paperDraftStatus = 'succeeded';
    });
    builder.addCase(cashierThunks.paperCouponBet.pending, (state) => {
      state.paperDraftStatus = 'pending';
    });
    builder.addCase(cashierThunks.paperCouponBet.rejected, (state, action) => {
      state.paperDraftError = action?.payload?.errorCode;
      state.paperDraftStatus = 'failure';
    });

    builder.addCase(cashierThunks.betsLimit.fulfilled, (state, action: PayloadAction<PosInfo>) => {
      state.posInfo = action.payload;
    });
    builder.addCase(cashierThunks.betsLimit.rejected, (state) => {
      state.posInfo = null;
    });

    builder.addCase(cashierThunks.statusTerminal.fulfilled, (state, action: PayloadAction<TerminalActivity>) => {
      const { statusName, isQuietHours } = action.payload.terminalStatus;
      switch (statusName) {
        case 'Active':
          state.terminalActivity = isQuietHours ? 'QuietHours' : 'Active';
          break;
        case 'Inactive':
          state.terminalActivity = 'Inactive';
          break;
        case 'Locked':
          state.terminalActivity = 'Locked';
          break;
        default:
          state.terminalActivity = 'Active';
      }
    });

    builder.addCase(cashierThunks.statusTerminal.rejected, (state) => {
      state.terminalActivity = 'Active';
    });
  },
});

export default cashierSlice.reducer;
export const {
  resetStateDepositTicket,
  setDocumentEventInfo,
  setReadDocumentInfo,
  resetPaperDraftStatus,
  resetCashierCancelTicketStatus,
  resetPayoutTicketStatus,
  resetPaperCouponInfo,
  setIsPrintingEjected,
  setIsCashierModalPrompted,
  setPrinterBusyMessage,
} = cashierSlice.actions;

export const checkIfPrintingIsDone = async (dispatch: AppDispatch) => {
  try {
    const url = 'http://localhost:10000/prn_done.json';
    const response = await axios.post(url);
    const { error_msg } = response.data;
    dispatch(setPrinterBusyMessage(error_msg));
  } catch (err) {
    getError.message(err);
  }
};

export const pollDocumentScanner = () => async (dispatch: AppDispatch) => {
  try {
    const url = '	http://localhost:10012/reco_event.json';
    const response = await axios.post(url);
    dispatch(setDocumentEventInfo(response.data));
  } catch (err) {
    getError.message(err);
  }
};

export const readDocument = () => async (dispatch: AppDispatch) => {
  const url = 'http://localhost:10012/reco_doc.json';
  const response = await axios.post(url);
  if (response.status === 200 && response.data.error_code === 0) {
    await axios.post('http://localhost:10012/reco_accept.json');
  } else if (response.data.error_code !== 0) {
    await axios.post('http://localhost:10012/reco_reject.json');
  }
  dispatch(setReadDocumentInfo(response.data));
};

export const enableScanDocument = async (): Promise<void> => {
  const enableApiUrl = 'http://localhost:10012/reco_enable.json';
  await axios.post(enableApiUrl);
};

export const disableScanDocument = async (): Promise<void> => {
  const disableApiUrl = 'http://localhost:10012/reco_disable.json';
  await axios.post(disableApiUrl);
};

export const turnOffClientMonitor = async (): Promise<void> => {
  const url = 'http://127.0.0.1:10013/xmon2_off.json';
  await axios.post(url);
};

export const turnOnClientMonitor = async (): Promise<void> => {
  const url = 'http://127.0.0.1:10013/xmon2_on.json';
  await axios.post(url);
};

export const selectCashierData = {
  payoutTicketInfo: (state: RootState) => state.retail.cashier.payoutTicketInfo,
  payoutTicketStatus: (state: RootState) => state.retail.cashier.payoutTicketStatus,
  payoutTicketError: (state: RootState) => state.retail.cashier.payoutTicketError,
  documentEventInfo: (state: RootState) => state.retail.cashier.documentEventInfo,
  readDocumentInfo: (state: RootState) => state.retail.cashier.readDocumentInfo,
  paperCouponInfo: (state: RootState) => state.retail.cashier.paperDraftInfo,
  paperDraftStatus: (state: RootState) => state.retail.cashier.paperDraftStatus,
  paperDraftError: (state: RootState) => state.retail.cashier.paperDraftError,
  cashierCancelTicketStatus: (state: RootState) => state.retail.cashier.cashierCancelTicketStatus,
  cashierCancelTicketStatusError: (state: RootState) => state.retail.cashier.cashierCancelTicketStatusError,
  cashierPayoutStatus: (state: RootState) => state.retail.cashier.cashierPayoutStatus,
  cashierPayoutError: (state: RootState) => state.retail.cashier.cashierPayoutStatusError,
  paperCouponId: (state: RootState) => state.retail.cashier.paperDraftInfo?.code,
  terminalActivity: (state: RootState) => state.retail.cashier.terminalActivity,
  isPrintingEject: (state: RootState) => state.retail.cashier.isPrintingEject,
  isCashierModalPrompted: (state: RootState) => state.retail.cashier.isCashierModalPrompted,
  posInfo: (state: RootState): PosInfo | null => state.retail.cashier.posInfo,
  printerBusyMessage: (state: RootState) => state.retail.cashier.printerBusyMessage,
};
