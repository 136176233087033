import styled from 'styled-components';

const CardDetails = styled.div.attrs({ className: 'prm-lobby-card__text-block' })`
  flex-grow: 1;
  display: grid;
  grid-gap: 8px;
  padding: 8px;
  font-size: clamp(0.75rem, 0.678rem + 0.31vw, 0.875rem);
`;

export default CardDetails;
