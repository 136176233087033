import { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import styled, { CSSProp } from 'styled-components';
import { useGenerateAndPreloadImage } from 'hooks/common/useGenerateAndPreloadImage';
import { Breakpoints } from 'theme/Theme';
import { fitCover } from 'utils/common/cdnConfig';
import config from 'utils/config';
import { GamificationPageTypes } from '../../../../../../pages/gamificationPage/types/GamificationPage.types';

type Props = {
  play?: boolean;
  handleVideoIsLoaded?: () => void;
  triggerVideoEnded?: () => void;
  triggerVideoPlay?: () => void;
  folder: string;
  video?: GamificationPageTypes.CampaignVideoType;
  videoCoverDesktop?: string;
  videoCoverMobile?: string;
  showCover?: boolean;
  disableMobileVideo?: boolean;
  imgAutoSize?: boolean;
  videoFullHeight?: boolean;
  disableObjectPosition?: boolean;
  loop?: boolean;
  styledCss?: CSSProp;
  hasPlayButton?: boolean;
  muted?: boolean;
};
let urlAuto;
export const VideoPlayer: React.FC<Props> = ({
  play = true,
  video,
  handleVideoIsLoaded,
  triggerVideoEnded,
  folder,
  videoCoverDesktop,
  videoCoverMobile,
  showCover,
  disableMobileVideo,
  imgAutoSize,
  videoFullHeight = true,
  disableObjectPosition,
  loop = true,
  styledCss,
  hasPlayButton = false,
  muted = true,
  triggerVideoPlay,
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isMobile}px)` });
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });
  const videoWrapperRef = useRef<HTMLDivElement>(null);
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [videoIsLoaded, setVideoIsLoaded] = useState(false);
  const isLandscapeQuery = useMediaQuery({ query: '(orientation: landscape)' });

  const { url } = useGenerateAndPreloadImage({
    image: showCover
      ? `/common-content/gamification/${folder}/${isMobile && !isLandscapeQuery ? videoCoverMobile : videoCoverDesktop}`
      : '',
    ref: videoWrapperRef,
    fit: 'cover',
    update: isMobile && !isLandscapeQuery,
    preload: false,
    isDesktop: isDesktop,
  });

  if (imgAutoSize) {
    urlAuto = `${config.CDN_IMAGE_API}${fitCover('auto', 'auto', 'cover')}${`/common-content/gamification/${folder}/${
      isMobile && !isLandscapeQuery ? videoCoverMobile : videoCoverDesktop
    }`}`;
  }

  useEffect(() => {
    setVideoUrl(
      `${config.CDN_URL}/common-content/gamification/${folder}/${
        isMobile && !isLandscapeQuery ? video?.mobile : video?.desktop
      }`,
    );
  }, [isMobile, isLandscapeQuery, folder, video]);

  if (disableMobileVideo && isMobile) return null;
  const triggerOnVideoReady = () => {
    setTimeout(() => {
      setVideoIsLoaded(true);
      handleVideoIsLoaded && handleVideoIsLoaded();
    });
  };

  return (
    <>
      <StyleWrapper
        ref={videoWrapperRef}
        data-cy="bonus-video"
        $videoFullHeight={videoFullHeight}
        $disableObjectPosition={disableObjectPosition}
        $styledCss={styledCss}
      >
        <ReactPlayer
          config={{
            file: {
              attributes: {
                crossOrigin: 'true',
              },
            },
          }}
          url={videoUrl}
          pip={false}
          height="100%"
          width="100%"
          playsinline
          loop={loop}
          muted={muted}
          playing={play}
          progressInterval={1000}
          onReady={triggerOnVideoReady}
          onEnded={triggerVideoEnded}
          onPlay={triggerVideoPlay}
        />
        <AnimatePresence initial={false}>
          {((!videoIsLoaded && showCover) || (hasPlayButton && !play)) && (
            <CoverImage
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              style={{
                background: `url(${imgAutoSize ? urlAuto : url}) ${
                  disableObjectPosition ? 'center' : 'top'
                } /  cover  no-repeat`,
              }}
            />
          )}
        </AnimatePresence>
      </StyleWrapper>
    </>
  );
};

const StyleWrapper = styled.div<{ $disableObjectPosition?: boolean; $videoFullHeight?: boolean; $styledCss?: CSSProp }>`
  height: ${({ $videoFullHeight }) => ($videoFullHeight ? '100%' : 'auto')};
  width: 100%;
  position: absolute;
  ${({ $styledCss }) => $styledCss}
  video {
    object-fit: cover;
    object-position: ${({ $disableObjectPosition }) => (!$disableObjectPosition ? 'top' : 'auto')};
    float: left;
    width: 100%;
    height: ${({ $videoFullHeight }) => ($videoFullHeight ? '100%' : 'auto')} !important;
  }
  &:before {
    content: '';
    width: 100%;
    height: ${({ $videoFullHeight }) => ($videoFullHeight ? '100%' : 'auto')};

    position: absolute;
  }
`;
const CoverImage = styled(motion.div)`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
`;
