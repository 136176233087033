import React from 'react';
import { motion } from 'framer-motion';
import { Flex } from 'components/shared/layout/Flex';
import { IsNew } from './isNew';
import NavIcon from './NavIcon';
import AccountTabBadge from '../../sections/tabs/AccountTabBadge';

interface NavItemProps {
  onClick: () => void;
  text: string;
  icon?: string;
  iconColor?: string;
  hasBadge?: boolean;
  tabKey: string | number;
  isNavGrid?: boolean;
  isNew?: boolean;
  badgeNewText?: string;
}

const NavItem: React.FC<NavItemProps> = ({
  onClick,
  text,
  icon,
  iconColor,
  hasBadge,
  tabKey,
  isNavGrid,
  isNew,
  badgeNewText,
}) => {
  const itemAnimation = {
    hidden: { slace: 0.2, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
    },
  };
  const dataCPPProp = iconColor ? { style: { fill: iconColor } } : { 'data-cpp': tabKey };

  return (
    <motion.div
      variants={itemAnimation}
      className="d-flex-col-ac text-center mpd-nav"
      onClick={onClick}
      data-qid={tabKey}
      {...dataCPPProp}
    >
      <Flex.Center className="position-relative mpd-nav__icon-wrapper">
        {icon && <NavIcon size="lg" icon={icon} />}
        {hasBadge && !isNew && <AccountTabBadge tabKey={tabKey} isNavGrid={isNavGrid} />}
        {isNew && <IsNew text={badgeNewText} />}
      </Flex.Center>
      {text}
    </motion.div>
  );
};

export default NavItem;
