import React from 'react';

type Props = {
  date: string;
  text: string;
};

const PrizeDropTimeCounterItem: React.FC<Props> = ({ date, text }) => {
  return (
    <egtd-przd-counter-item>
      <egtd-przd-counter-item-date>{date}</egtd-przd-counter-item-date>
      <egtd-przd-counter-item-text>{text}</egtd-przd-counter-item-text>
    </egtd-przd-counter-item>
  );
};

export default PrizeDropTimeCounterItem;
