import React from 'react';
import { useMatch } from 'react-router-dom';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import useMyBetsRoute from 'hooks/common/useMyBetsRoute';
import { useShowAppFooter } from 'hooks/useShowAppFooter';
import { FooterCasinoCategory } from 'modules/casino/modules/container/cellsContainer/containers/external';
import { PromosFeed } from 'pages/promotions/promosFeed';
import { svaraAppSelectors } from 'pages/svaraAppLogin/slice/svaraAppLogin.slice';
import { AppFooter } from 'shared/common/features/appFooter';
import { AppFooterUI } from 'shared/common/features/appFooter/components/AppFooterUI';
import { selectFooter } from 'shared/common/features/appFooter/slice/appFooter.slice';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import useGetContainersByPosition from 'shared/common/features/navigationMenu/hooks/useGetContainerByPosition';
import { selectNavigationMenu } from 'shared/common/features/navigationMenu/slice/navigationMenu.slice';
import { Container } from 'shared/common/features/navigationMenu/types/navigationMenu.types';
import { ExpandableSection } from 'shared/common/features/seo/seoData';
import { LANDING_PAGE_SEO_DATA_KEY } from 'shared/common/features/seo/seoData/seoDataConstants';
import { seoDataSelectors } from 'shared/common/features/seo/seoData/slice/seoData.slice';
import { useAppSelector } from 'store';
import { ContainerTypeNames, ContainerTypes, PartialRecord } from 'utils/common/types/common.types';

interface Props {
  className?: string;
  isFullWidth?: boolean;
  children?: React.ReactNode;
  visibleOnUnmount?: boolean;
}

const containerMap: PartialRecord<ContainerTypeNames, React.FC<Container>> = {
  [ContainerTypes.BonusesPromotionsSlider]({ apiUri, name }) {
    return <PromosFeed apiUri={apiUri} containerName={name} />;
  },
  [ContainerTypes.gamesSlider]({ apiUri, name, id }) {
    return <FooterCasinoCategory apiUri={apiUri} containerName={name} containerId={id} />;
  },
  [ContainerTypes.footer]() {
    return null;
    // return <AppFooter apiUri={apiUri} />;
  },
};

const AppFooterSections: React.FC<Props> = ({ children, isFullWidth, className, visibleOnUnmount }) => {
  const matchFantasyLeague = !!useMatch('fantasy/*');
  const footerVerticals = useAppSelector(selectNavigationMenu.footerVerticals);
  const { showMyBetsFooter } = useMyBetsRoute(window.location.pathname);
  const varticalPathname = `/${window.location.pathname.split('/')[1]}`;
  const vertical = useAppSelector(selectNavigationMenu.activeVertical(varticalPathname));
  const checkAddFooter = vertical && footerVerticals && footerVerticals.indexOf(vertical?.id as number) >= 0;
  const footerConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.FOOTERCONFIG,
  });
  const hideFooter = footerConfig?.hideFooter;

  useShowAppFooter(visibleOnUnmount ? [true, true] : [true, false]);

  const isAppFooterVisible = useAppSelector(selectFooter.isVisible);
  const hideAppFooter = useAppSelector(selectFooter.hideAppFooter);
  const showFooterSectionForLandingPage = useAppSelector(selectFooter.showForLandingPage);
  const footerContainers = useGetContainersByPosition({
    containersPosition: 'Footer',
  });
  const lendingPageSeoData = useAppSelector((state) =>
    seoDataSelectors.seoDataByVertical(state, varticalPathname === '/landing' ? LANDING_PAGE_SEO_DATA_KEY : undefined),
  );
  const openLeaderboard = useAppSelector(svaraAppSelectors.openLeaderboard);

  const renderFooterContainer = footerContainers.map((container) => {
    const Component = containerMap[container.type];
    if (Component) {
      Component.displayName = container.type;
    }
    return Component ? <Component {...container} key={container.id} isFullWidth={!!isFullWidth} /> : null;
  });

  const showFooterContainers = !!footerContainers.length;
  const showsSeoContent = !!(vertical?.hasSeoContent || lendingPageSeoData?.content);
  const showFooterSection = checkAddFooter || showFooterSectionForLandingPage || matchFantasyLeague || showMyBetsFooter;
  const hideFooterWrapper = !isAppFooterVisible || hideAppFooter || openLeaderboard;
  const isLiveProgram = useMatch('sports/live-program');

  if (
    hideFooter ||
    hideFooterWrapper ||
    (!showFooterSection && !showFooterContainers && !showsSeoContent) ||
    isLiveProgram
  ) {
    return null;
  }

  return (
    <AppFooterUI.Wrapper className={className}>
      {showFooterContainers && renderFooterContainer}
      {showsSeoContent && <ExpandableSection showFooterSection={showFooterSection} />}
      {showFooterSection && <AppFooter />}
      {children}
    </AppFooterUI.Wrapper>
  );
};
export default AppFooterSections;
