import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { General, LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { Vertical } from 'shared/common/features/navigationMenu/types/navigationMenu.types';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch } from 'store';
import { Verticals } from 'utils/common/translationUtils/translationStrings';
import { useAuthentication } from './useAuthentication';
import { useLayoutConfigType } from './useLayoutConfigType';

type ConfigRoutes = Record<keyof Omit<General.ConfigVerticals, 'verticalsMenu'>, string>;

export const configRoutes: ConfigRoutes = {
  myBets: '/my-bets',
  claimTicket: '',
};

const onClickMap = (dispatch) => ({
  claimTicket: () =>
    dispatch(
      showRootModal({
        modalType: 'CLAIM_TICKET_MODAL',
        modalPriority: ModalPriorityEnum.MEDIUM,
        modalId: 'CLAIM_TICKET_MODAL',
      }),
    ),
});

export const configVerticals = (t, dispatch) =>
  Object.keys(configRoutes).reduce((newMap, currKey) => {
    newMap[currKey] = {
      name: t(Verticals.Name[currKey]),
      route: configRoutes[currKey],
      id: currKey,
      onClick: onClickMap(dispatch)[currKey],
      align: 'left',
    };
    return newMap;
  }, {});

const useVerticalsConfig = (): {
  routesEnabled: Omit<General.ConfigVerticals, 'verticalsMenu'> | undefined;
  configRoutes: ConfigRoutes;
  configVerticals: (Partial<Vertical> & { id: string; route: string; name: string })[] | undefined;
} => {
  const { isAuthenticated } = useAuthentication();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const verticalsConfigGeneral = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.VERTICALS,
    deviceLayout: 'general',
  })?.configVerticals;
  const verticalsConfigByDevice = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.VERTICALS,
  })?.configVerticals;

  const verticalsConfig = useMemo(
    () => ({ ...verticalsConfigGeneral, ...verticalsConfigByDevice }),
    [verticalsConfigByDevice, verticalsConfigGeneral],
  );
  const { verticalsMenu, ...routesEnabled } = verticalsConfig || {};

  const verticals = useMemo(() => {
    const config = configVerticals(t, dispatch);
    return verticalsMenu?.map((key) => config[key])?.filter((v) => (v.isLocked ? isAuthenticated : true));
  }, [dispatch, isAuthenticated, t, verticalsMenu]);

  return { routesEnabled, configRoutes, configVerticals: verticals };
};

export default useVerticalsConfig;
