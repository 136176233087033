import CTAButton from './CTAButton';
import CTAButtonBgImage from './CTAButtonBgImage';
import CTAText from './CTAText';
import CTATitle from './CTATitle';
import GiftModal from './GiftModal';
import Header from './Header';
import Link from './Link';
import Logo from './Logo';
import LogoNew from './LogoNew';
import NotAuthenticated from './NotAuthenticated';
// eslint-disable-next-line import/no-cycle
import OverlayModal from './OverlayModal';
import { SoundEffect } from './SoundEffect';
import Terms from './Terms';
import { VideoPlayer } from './VideoPlayer';

export const GamificationUI = {
  CTAButton: CTAButton,
  CTAButtonBgImage: CTAButtonBgImage,
  CTAText: CTAText,
  Header: Header,
  Link: Link,
  Logo: Logo,
  LogoNew: LogoNew,
  Terms: Terms,
  CTATitle: CTATitle,
  Overlay: OverlayModal,
  SoundEffect: SoundEffect,
  VideoPlayer: VideoPlayer,
  GiftModal: GiftModal,
  NotAuthenticated: NotAuthenticated,
};
