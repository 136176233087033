import { useTranslation } from 'react-i18next';
//import { useCurrency } from 'hooks/common/useCurrency';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { useBarcode } from 'modules/retail/shared/hooks/useBarcode';
import { useScanCheckTicketHandheld } from 'modules/retail/shared/hooks/useCheckTicketHandheld';
import { determineInfoMessage } from 'modules/retail/shared/utils/retail.utils';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { useAppDispatch } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import DisplayErrorModal from './DisplayErrorModal';
import TerminalCheckTicketControls from './TerminalCheckTicketControls';
import { useCashierModalState } from './useCashierModalState';
import { useCashierTicketStatusInfo } from './useCashierTicketStatusInfo';
import { CashierLastStepTicketModal } from '../../../ssbt/components';
import { CashierModalUI } from '../../cashierModalUI/Index';
import { useCashierModalPrompt } from '../../hooks/useCashierModalPrompt';
import { useTicketState } from '../../hooks/useTicketState';
import { clearAllData } from '../../utils';
import UCNForm from '../UCNForm/UCNForm';

const CashierModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //const { currencyCode } = useCurrency();
  const { closeModal } = useHandleModals();
  const { onScan, isHandheld } = useScanCheckTicketHandheld();
  const { reset } = useBarcode();
  const {
    initialTime,
    activeButtons,
    setActiveButtons,
    seconds,
    setSeconds,
    clickCount,
    setClickCount,
    actionButtonClicked,
    setActionButtonClicked,
    inputValueUCN,
    setInputValueUCN,
  } = useCashierModalState();

  useCashierModalPrompt(); // calling it here so isCashierModalPrompted can become false when opening the modal

  const { ticketInfo, ticketInfoStatus, cancelTicketStatus, payoutTicketStatus } = useCashierTicketStatusInfo();

  useTicketState(ticketInfo, setActiveButtons, clickCount, setActionButtonClicked);

  const isMagnumBetRo = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.IS_MAGNUM_BET_RO,
    deviceLayout: 'general',
  });

  const shouldDisplayModal =
    !isEmpty(ticketInfo) &&
    !(isMagnumBetRo && (ticketInfo.status === 'WON' || ticketInfo?.status === 'VOID') && clickCount === 1);

  const isError =
    cancelTicketStatus === 'failure' || payoutTicketStatus === 'failure' || ticketInfoStatus === 'failure';

  return (
    <CashierModalUI.Wrapper isVisible={true}>
      <CashierModalUI.Body>
        <CashierModalUI.Header clearData={() => clearAllData(dispatch, closeModal, reset)} />

        <CashierModalUI.Content>
          {isError && <DisplayErrorModal />}

          {shouldDisplayModal && <CashierLastStepTicketModal ticketInfo={ticketInfo} />}

          {isMagnumBetRo && clickCount === 1 && (ticketInfo?.status === 'WON' || ticketInfo?.status === 'VOID') && (
            <UCNForm
              setActionButtonClicked={setActionButtonClicked}
              ticketId={ticketInfo?.ticket.ticketId}
              setActiveButton={setActiveButtons}
              setSeconds={setSeconds}
              inputValueUCN={inputValueUCN}
              setInputValueUCN={setInputValueUCN}
              setClickCount={setClickCount}
              seconds={seconds}
            />
          )}

          {/* TODO: Conditionally change text and icon for different screens */}
          {isEmpty(ticketInfo) && (
            <CashierModalUI.InfoMessage
              className="m-auto"
              infoMessage={determineInfoMessage(ticketInfoStatus, t)}
              icon={ticketInfoStatus === 'failure' ? 'cashier-search' : 'qr'}
              isLoading={ticketInfoStatus === 'pending'}
              onClick={
                isHandheld
                  ? onScan
                  : () => {
                      return;
                    }
              }
            />
          )}
        </CashierModalUI.Content>

        <CashierModalUI.NavCol>
          <TerminalCheckTicketControls
            activeButtons={activeButtons}
            seconds={seconds}
            setSeconds={setSeconds}
            initialTime={initialTime}
            clickCount={clickCount}
            setClickCount={setClickCount}
            actionButtonClicked={actionButtonClicked}
            setActionButtonClicked={setActionButtonClicked}
            setActiveButtons={setActiveButtons}
            inputValueUCN={inputValueUCN}
            isMagnumBetRo={isMagnumBetRo}
          />
        </CashierModalUI.NavCol>
      </CashierModalUI.Body>
    </CashierModalUI.Wrapper>
  );
};

export default CashierModal;
