import { AppDispatch } from 'store';
import { RootState } from 'store/rootReducer';

export type SilentLoadComponentNames = 'TopEvents' | null;

export const silentLoadComponents = new Set();

type ThunkFunc<T> = (props: T, dispatch: AppDispatch, getState: () => RootState) => void;

export const createLoadThunk =
  <T>(component: SilentLoadComponentNames, loadFunc: ThunkFunc<T>, thunkFunc: ThunkFunc<T>) =>
  (props: T) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (!silentLoadComponents.has(component)) {
      loadFunc(props, dispatch, getState);
    }

    thunkFunc(props, dispatch, getState);
  };
