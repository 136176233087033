export const getNavbarHide = ({ topNavHeaderStatus }: { topNavHeaderStatus: string }): boolean =>
  topNavHeaderStatus === 'collapsed' || topNavHeaderStatus === 'fixed-collapsed';

// export const animationState = {
//   transition: { duration: 0.4 },
//   show: {
//     height: 'auto',
//     overflow: 'hidden',
//   },
//   hide: {
//     height: '0',
//     overflow: 'hidden',
//     zIndex: -1,
//   },
// };

export const animationState = {
  transition: { duration: 0.4 },
  show: {
    // height: 'auto',
    y: 0,
  },
  showBanner: {
    height: '60px',
    overflow: 'hidden',
  },
  hide: {
    y: -44,
  },
  hideBanner: {
    height: '0',
    overflow: 'hidden',
    zIndex: -1,
  },
};
