import React, { memo, useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import { Button } from 'components/shared/buttons';
import { useIsUnmounted } from 'hooks/common/useIsUnmouted';
import { useAppDispatch } from 'store';
import { Theme } from 'theme/Theme';
import { buttonKeys, handleVirtualInput } from './helpersFuncCashier';

const isInputDisabled = (inputValue: string) => {
  if (inputValue.length > 4 && inputValue.indexOf('.') === -1) {
    return true;
  }
  return inputValue.indexOf('.') !== -1 && inputValue.length - inputValue.indexOf('.') === 3;
};

interface Props {
  inputValue: string;
  updateInputFunc: (value: string) => void;
  onUnmount: () => void;
  onMount?: () => void;
  handleHideKeyboard: () => void;
  inputRemoveValue: boolean;
}

const CashierKeyBoard: React.FC<Props> = ({
  inputValue,
  updateInputFunc,
  onUnmount,
  onMount,
  handleHideKeyboard,
  inputRemoveValue,
}) => {
  const isUnmonted = useIsUnmounted();

  useEffect(() => {
    onMount && onMount();
    return () => {
      if (isUnmonted()) {
        onUnmount();
      }
    };
  }, [onUnmount, isUnmonted]);

  const dispatch = useAppDispatch();

  const isKeyboardDisabled = useMemo(
    () => !inputRemoveValue && isInputDisabled(inputValue),
    [inputValue, inputRemoveValue],
  );

  const handleRemoveValue = () => {
    const currentValue = inputValue.slice(0, -1);
    updateInputFunc(currentValue);
  };

  return (
    <div className="ssbt-vkbd">
      {buttonKeys.map((key) => (
        <motion.button
          whileTap={
            !isKeyboardDisabled
              ? {
                  scale: 1.3,
                }
              : {}
          }
          transition={{
            type: 'spring',
            stiffness: 400,
          }}
          key={key}
          type="button"
          disabled={false}
          className={`d-flex-center ssbt-vkbd__btn ssbt-vkbd__btn--digit`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            return handleVirtualInput(key, {
              updateFunc: updateInputFunc,
              // input: inputValue + key,
              input: inputRemoveValue ? key : inputValue + key,
              dispatch,
            });
          }}
        >
          {key}
        </motion.button>
      ))}

      <motion.button
        whileTap={{ x: -5 }}
        transition={{
          type: 'spring',
          stiffness: 300,
        }}
        className="d-flex-center ssbt-vkbd__btn ssbt-vkbd__btn--backspace"
        onClick={handleRemoveValue}
      >
        <svg className="ssbt-vkbd__btn-icon" viewBox="0 0 27 14">
          <use href={`${Theme.icons.ui}#kbd-backspace`} />
        </svg>
      </motion.button>

      <Button
        classNames="d-flex-center ssbt-vkbd__btn ssbt-vkbd__btn--confirm"
        onClickHandler={handleHideKeyboard}
        value=" OK"
      />
    </div>
  );
};

export default memo(CashierKeyBoard);
