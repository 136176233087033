import { FC } from 'react';
import { CasinoScroll } from '../casinoScrollTypes';

const ScrollContainer: FC<CasinoScroll.ScrollContainerProps> = ({ children, setRefs }) => {
  return (
    <div className={'scroll-container-wrapper'} ref={setRefs}>
      {children}
    </div>
  );
};

export default ScrollContainer;
