export const useAnimationDimensions = (
  elementWidth: number,
  aspectRatioWidth: number,
  screenWidthMobile = 375,
): { vWidth: number; vHeight: number } => {
  const vwContext = screenWidthMobile * 0.01;
  const vWidth = elementWidth / vwContext;
  const vHeight = vWidth / aspectRatioWidth;
  return { vWidth, vHeight };
};
