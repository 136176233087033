import React from 'react';
import styled from 'styled-components';
import ParseHTML from 'shared/common/ParseHTML';

type Props = {
  descriptions?: string;
};

const Descriptions: React.FC<Props> = ({ descriptions }) => {
  if (!descriptions) return null;
  return (
    <Desc className="popover__desc">
      <ParseHTML html={descriptions || ''} options={{ trim: true }} />
    </Desc>
  );
};
const Desc = styled('div')`
  padding-bottom: 10px;
  padding-top: 10px;
`;
export default Descriptions;
