import { createSlice } from '@reduxjs/toolkit';
import { DeviceType } from 'hooks/common/useOnDeviceChange';
import { setDeviceType } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { DeviceTypeInitialState } from '../types/generalSlice.types';

const initialState: DeviceTypeInitialState = {
  deviceType: '',
};

const deviceType = createSlice({
  name: 'deviceType',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setDeviceType, (state, action) => {
      state.deviceType = action.payload;
    });
  },
});

export const selectDeviceType = {
  deviceType: (state: RootState): DeviceType => state.common.deviceType.deviceType,
};

export default deviceType.reducer;
