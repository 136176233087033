import React from 'react';
import { ActionHandWrapper } from './StyleComponents';
import { HighlightNewFeatureUI } from '.';

const ActionHand = ({ actionTypes, elementDimensions }) => {
  return (
    <ActionHandWrapper className="d-flex" {...elementDimensions}>
      <HighlightNewFeatureUI.ActionHandItem type={actionTypes} />
    </ActionHandWrapper>
  );
};

export default ActionHand;
