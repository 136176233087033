import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { useCurrency } from 'modules/casino/shared/hooks/useCurrency';
import usePlayerRestrictions from 'modules/casino/shared/hooks/usePlayerRestrictions';
import { loginThunks } from 'pages/auth/login/slice/login.slice';
import userStorage from 'pages/auth/login/UserStorage';
import { selectDeviceType } from 'shared/common/features/general/slice/deviceType.slice';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { selectCashierWidget } from 'shared/common/features/integrations/cashierWidget/cashierWidget.slice';
import useCashierScript from 'shared/common/features/integrations/cashierWidget/hooks/useCashierScript';
import { getWidgetThemeColorsByBu } from 'shared/common/features/integrations/cashierWidget/types/utils';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppDispatch, useAppSelector } from 'store';
import config from 'utils/config';
import { DEFAULT_GAME_ID_LOBBY } from '../../gameLaunch/casinoLaunchConstants';
import { casinoLaunchSelectors } from '../../gameLaunch/casinoLaunchSlice';

export const useQuickDeposit = ({ containerId, widgetRef, promoId }) => {
  const gameId = useAppSelector(casinoLaunchSelectors.launchData)?.gameId;
  const deviceType = useAppSelector(selectDeviceType.deviceType);
  const preferredTheme = useAppSelector(selectMyProfile.preferredTheme);
  const user = useAppSelector(selectMyProfile.dataUser);
  const isWidgetLoaded = useAppSelector(selectCashierWidget.isWidgetLoaded);
  const forceRefresh = useAppSelector(selectCashierWidget.forceWidgetRefresh);
  const availableRestrictionProducts = useAppSelector(selectGeneral.availableRestrictionProducts);
  const accessToken = userStorage?.getUser().access_token;

  const isCashierNewImplementation = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.CASHIER_NEW,
  });

  useCashierScript(isWidgetLoaded);

  const { currencySymbol, currencyCode } = useCurrency();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const promotionId = promoId || location.state?.promotionId;
  const { allProductsRestricted, showRestrictionsModalInfo } = usePlayerRestrictions({
    products: availableRestrictionProducts || [],
    showModal: false,
  });

  const cashierConfigNew = useMemo(
    () =>
      ({
        id: containerId,
        userId: user?.id,
        sessionId: accessToken,
      }) as any,
    [accessToken, containerId, user?.id],
  );

  const cashierUser = useMemo(() => {
    return {
      id: user!.id,
      fullName: user!.fullName,
      currency: user!.currency,
      language: user?.language ?? 'en',
      accessToken: accessToken,
      phoneNumber: user?.phoneNumber,
    };
  }, [user, accessToken]);

  const onLogout = () => {
    dispatch(loginThunks.logout());
  };

  const cashierConfig = useMemo(
    () => ({
      params: {
        ...(gameId === DEFAULT_GAME_ID_LOBBY ? {} : { gameId }),
        isQuickDeposit: true,
        id: containerId,
        deviceType,
        preferredTheme,
        user: cashierUser,
        currencySymbol,
        currencyCode,
        generalLayoutConfig: {},
        MAPBOX_API_KEY: '',
        windowConfig: {
          THEME: window.config.THEME,
          BUSINESS_UNIT: window.config.BUSINESS_UNIT,
          API_URL: config.API_URL,
          FB_TRACKING_SERVICE_URL: window.config.FB_TRACKING_SERVICE_URL,
          ENV_TYPE: window.config.ENV_TYPE,
          EPOCH: window.config.EPOCH,
          ERROR_LOGGER_URL: '',
        },
        locationState: {
          promotionId,
          forceRefresh,
          activeTab: 'deposit',
        },
        restrictions: {
          allProductsRestricted,
        },
        themeSettings: getWidgetThemeColorsByBu(),
      },
      listeners: {
        showRestrictionsModalInfo,
        logout: onLogout,
      },
    }),
    [accessToken, allProductsRestricted, cashierUser, forceRefresh, gameId],
  );

  useEffect(() => {
    if (widgetRef && isWidgetLoaded) {
      window.widgetCashier = window?.Cashier?.cashierWidget(
        !isCashierNewImplementation ? cashierConfig : cashierConfigNew,
      );

      if (window.widgetCashier) {
        window.widgetCashier.init();
      }

      return () => {
        if (window.widgetCashier) {
          window.widgetCashier.destroy();
          window.widgetCashier = undefined;
        }
      };
    }
  }, [widgetRef, isWidgetLoaded, cashierConfig, isCashierNewImplementation, cashierConfigNew]);

  return isWidgetLoaded && widgetRef;
};
