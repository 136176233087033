import { useCallback } from 'react';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch } from 'store';
import { setReadNotificationById } from '../../myProfile/sections/tabs/notificationsTab/notificationSlice';

export const useModalUiActions = (notificationId: string) => {
  const dispatch = useAppDispatch();

  const handleModalClose = useCallback(() => {
    document.body.classList.remove('modal-open');
    document.body.classList.remove('no-scroll');
    dispatch(setReadNotificationById({ notificationId, notificationType: 'popup' }));
    dispatch(hideRootModal());
  }, [dispatch, notificationId]);

  return { handleModalClose };
};
