import { Sport } from 'utils/common/types/sportTypes';

export declare module PlayerRestrictions {
  type InfoForm = {
    label: string;
    link?: string;
  };

  type InfoMap = Record<string, Record<string, InfoForm[]>>;

  type PossibleValues = {
    durationValue: number;
    value: number;
    durationType: string;
    text: string;
  };

  type ProductType = keyof typeof PRODUCT_TYPE_ENUM; // 'SPORT' | 'CASINO' | 'LIVE_CASINO' | 'FANTASY_GAMES' | 'TABLE_GAMES' Bet type === product
  type Products = Record<PlayerRestrictions.ProductType, ProductType[]>;

  type ApiResponse = {
    enabled: boolean;
    coolOffPeriod?: number;
    possibleValues: PossibleValues[];
    removeLimitEnabled: boolean; // Show button to remove restriction after cool off period end and before endDate pass.
    confirmActivationWithPassword: boolean;
    helpUrl: string;
    helpUrlType?: 'external';
    verticals: PlayerRestrictions.ProductType[];
    activeRestrictionInformationEmail?: boolean;
    finishedRestrictionInformationEmail?: boolean;
    loginEnabled?: boolean;
    automaticReOpeningEnabled: boolean; // If false we show request button after endDate pass to request restriction removal.
    marketingMessagesEnabled: boolean;
    revertRestrictionEnabled: boolean; // Activates timer for reverting restriction right after creating it by accident.
    isExtendPeriodEnabled?: boolean; // Show extend period button if true.
    revertRestrictionMinutes: number;
    products?: Products;
  };

  type ResponseStatus = { error: null | string; status: Sport.StateStatus };

  type State = {
    restrictionsTab: ApiResponse & ResponseStatus;
    restrictionsInfo: PlayerRestrictions.InfoMap;
    playerRestrictions: Record<ProductType, Restriction[]> | null;
    statuses: {
      updatePlayerRestrictions: Sport.StateStatus;
    };
    errors: {
      updatePlayerRestrictions: null | string;
    };
  };

  type UpdateParams = {
    selectedValue?: PossibleValues;
    restrictionType: string;
    product?: ProductType;
    type: 'post' | 'put';
    confirmActivationWithPassword: boolean;
    isActive?: boolean;
    isExtended?: boolean;
    isRequestSent?: boolean;
    restrictionGroupId?: number;
    isReOpeningRequestSent?: boolean;
  };

  type ConfigurationKey = 'timeOut' | 'selfExclusion' | 'accountClosure' | 'playerLimits';

  type Restriction = {
    coolOffEndDate: number;
    revertEndDate: number;
    isRequestSent?: boolean;
    createDate: number;
    endDate: number;
    id: number;
    isActive: boolean;
    playerId: string;
    removalRequest: boolean;
    removalRequestTime: boolean;
    restrictionConfigurationKey: ConfigurationKey;
    restrictionType: string;
    restrictionTypeId: number;
    startDate: number;
    updateDate: number;
    productTypeName: ProductType;
    productId: number;
    restrictionStatus: 'pending' | 'active';
    pendingStatusText: string;
    restrictionGroupId: number;
  };
}

export enum RestrictionType {
  ACCOUNT_CLOSURE = 'accountClosure',
  TIME_OUT = 'timeOut',
  SELF_EXCLUSION = 'selfExclusion',
  PLAYER_LIMITS = 'playerLimits',
}

export enum PRODUCT_TYPE_ENUM {
  SPORT = 'SPORT',
  CASINO = 'CASINO',
  LIVE_CASINO = 'LIVE_CASINO',
  FANTASY_GAMES = 'FANTASY_GAMES',
  TABLE_GAMES = 'TABLE_GAMES',
  LOTTERY = 'LOTTERY',
}
