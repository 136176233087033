import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { UI } from 'components/shared/UI';
import { resetTicketInfoState } from 'modules/retail/shared/slice/checkTicket.slice';
import { useAppDispatch } from 'store';
import { Breakpoints } from 'theme/Theme';
import { BetSlip, CashierTranslations } from 'utils/common/translationUtils/translationStrings';
import { ReprintTicketDateControls } from './ReprintTicketDateControls';
import { ReprintTicketOptionsControls } from './ReprintTicketOptionsControls';
import {
  resetReprintTicketHistoryDate,
  resetSearchFiltersForNexStepPagination,
  setExpandedTicket,
} from './slice/reprintTicket.slice';
import { CashierModalUI } from '../../cashierModalUI/Index';

const ReprintFiltersControls = ({ activeOperations, activeTerminal, state, methods }) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const isSearchButtonDisabled = state.selectedDates.startDate === '' || state.selectedDates.endDate === '';
  const isClearButtonDisabled = state.selectedDates.startDate === '' && state.selectedDates.endDate === '';
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });

  const clearDates = () => {
    methods.resetInnerState();
    dispatch(resetReprintTicketHistoryDate());
    dispatch(resetTicketInfoState());
    dispatch(resetSearchFiltersForNexStepPagination());
    dispatch(setExpandedTicket(null));
  };

  return (
    <>
      <CashierModalUI.InputWrapper gridLayout="true">
        <ReprintTicketOptionsControls
          activeOperations={activeOperations}
          selectedOption={state.selectedOption}
          setSelectedOption={methods.setSelectedOption}
          activeTerminal={activeTerminal}
          selectedTerminal={state.selectedTerminal}
          setSelectedTerminal={methods.setSelectedTerminal}
          t={t}
        />
        <ReprintTicketDateControls
          setSelectedDates={methods.setSelectedDates}
          selectedDate={state.selectedDates}
          t={t}
        />

        <CashierModalUI.ButtonsWrapper>
          <UI.Button
            size={isDesktop ? '2xl' : 'lg'}
            variant="secondary"
            text={`${t(BetSlip.Header.clearSelections)}`}
            onClick={clearDates}
            isDisabled={isClearButtonDisabled}
          />
          <UI.Button
            text={`${t(CashierTranslations.FinancialModal.searchButton)}`}
            size={isDesktop ? '2xl' : 'lg'}
            variant="primary"
            isDisabled={isSearchButtonDisabled}
            onClick={methods.handleSearch}
          />
        </CashierModalUI.ButtonsWrapper>
      </CashierModalUI.InputWrapper>
    </>
  );
};

export default ReprintFiltersControls;
