import { useEffect, useRef, MutableRefObject, useCallback } from 'react';
import { authConstants } from 'pages/auth/login/loginHelpers';
import { selectAuthentication, loginThunks } from 'pages/auth/login/slice/login.slice';
import userStorage from 'pages/auth/login/UserStorage';
import { showRootModal, hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch, useAppSelector } from 'store';

export const useSessionCheck = (): void => {
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  // when getting new token, isAuthenticated is still true, using refreshTokenLoading to start a new timeout
  const refreshTokenLoading = useAppSelector(selectAuthentication.refreshTokenLoading);
  const dispatch = useAppDispatch();
  const timeoutToken = useRef<NodeJS.Timeout | null>(null);
  const timeoutRefresh = useRef<NodeJS.Timeout | null>(null);
  const hasRefreshTokenModal = window.config.BUSINESS_UNIT !== 'WBRO';

  const onTokenExpiration = useCallback(
    (timer?: number) =>
      hasRefreshTokenModal
        ? dispatch(
            showRootModal({
              modalType: 'REFRESH_TOKEN',
              modalProps: { timer },
              modalPriority: ModalPriorityEnum.HIGH,
              modalId: 'REFRESH_TOKEN',
            }),
          )
        : dispatch(loginThunks.refreshToken()),

    [dispatch, hasRefreshTokenModal],
  );

  const onRefreshTokenExpiration = useCallback(() => {
    dispatch(hideRootModal());
    dispatch(loginThunks.logout());
    // setTimeout(() => dispatch(showRootModal({ modalType: 'REFRESH_TOKEN', modalProps: { isExpired: true }, modalPriority: ModalPiorityEnum.HIGH, modalId: 'REFRESH_TOKEN', })), 1000);
  }, [dispatch]);

  const setTimeoutAction = (
    expirationDate: number,
    timeout: MutableRefObject<NodeJS.Timeout | null>,
    onTimeout: (timer?: number) => void,
  ) => {
    const defaultTime = authConstants.DEFAULT_TIME_FOR_DECISION_IN_SEC;
    const delay = expirationDate - Date.now();
    if (delay > 0) {
      const flag = delay - defaultTime * 1000 > 0;

      const modalTimer = flag ? defaultTime : Math.round(delay / 1000);
      const modalDelay = flag ? delay - modalTimer * 1000 : 0;
      timeout.current = setTimeout(() => onTimeout(modalTimer), modalDelay);
    }
  };

  const clearTimeouts = useCallback(() => {
    timeoutToken.current && clearTimeout(timeoutToken.current);
    timeoutRefresh.current && clearTimeout(timeoutRefresh.current);
    hasRefreshTokenModal && dispatch(hideRootModal());
  }, [dispatch, hasRefreshTokenModal]);

  useEffect(() => {
    const getUser = () => {
      const user = userStorage.getUser();
      if (!user) return;
      setTimeoutAction(user.expirationDate, timeoutToken, onTokenExpiration);
      setTimeoutAction(user.refreshExpirationDate, timeoutRefresh, onRefreshTokenExpiration);
    };
    isAuthenticated && !refreshTokenLoading ? getUser() : clearTimeouts();
  }, [clearTimeouts, dispatch, isAuthenticated, refreshTokenLoading, onRefreshTokenExpiration, onTokenExpiration]);

  useEffect(() => {
    return () => {
      clearTimeouts();
    };
  }, [clearTimeouts]);
};
