import React from 'react';

/** BetSeletionUI Wrapper */
interface WrapperProps {
  children: React.ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({ children }) => {
  return <div className="d-flex-col egtd-p-bi-sel">{children}</div>;
};

export default Wrapper;
