import React, { useRef, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
// import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useOnOff } from 'hooks/common/useOnOff';
import { useOutsideClick } from 'hooks/common/useOutsideClick';
import { Breakpoints } from 'theme/Theme';
import { FormControlTypes } from '../../formTypes';
import FormSelectUI from '../FormSelectUI';

interface Props {
  options: {
    id: number;
    name: number;
    value: number;
  }[];
  onChange: (name: 'day' | 'month' | 'year') => (value: string | number) => void;
  type?: string;
  name: 'day' | 'month' | 'year';
  placeholder?: string;
  label?: string;
  colsClasses?: string;
  disabled?: boolean;
  hasError?: boolean;
  isMobileAgnostic?: boolean;
  min?: string;
  max?: string;
  validation: FormControlTypes['validation'];
  maxLength: number;
  value?: number;
  readOnly?: boolean;
}

const DatepickerDropdown: React.FC<Props> = ({
  options,
  name,
  placeholder,
  onChange,
  label,
  colsClasses,
  disabled,
  hasError,
  isMobileAgnostic,
  min,
  max,
  validation,
  maxLength,
  value,
  readOnly,
}) => {
  const dropdown = useRef<HTMLDivElement>(null);
  // const { t, i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useOnOff();
  const [dropup, setDropup] = useOnOff();
  const { register } = useFormContext();
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isTabletOrMobile}px)` });

  //----- the commented code is not needed at the moment as there is nothing to translate
  //----- option given to DatepickerDropdown doesn`t have (for now) .nameTranslations property - thus it will always return option.name
  // const translationVerification = (option) => {
  //   if (option.nameTranslations) {
  //     const checkKeyExisting = i18n.exists(`Registration.FormDropdown.${option.nameTranslations.toLowerCase()}`);
  //     if (checkKeyExisting) {
  //       return t(`Registration.FormDropdown.${option.nameTranslations.toLowerCase()}`);
  //     } else {
  //       return option.name;
  //     }
  //   } else {
  //     return option.name;
  //   }
  // };

  const toggleDropdown = () => {
    if (disabled) {
      return;
    }
    setDropup(false);
    if (dropdown.current && window.innerHeight - dropdown.current.getBoundingClientRect().bottom < 220) {
      setDropup(true);
    }
    setDropdownOpen(!dropdownOpen);
  };

  const getSelectedItem = (item: number) => () => {
    if (disabled) {
      return;
    }
    onChange(name)(item);
    setDropdownOpen(false);
  };

  const outsideClickHandler = useCallback(() => {
    dropdownOpen && setDropdownOpen(false);
  }, [dropdownOpen, setDropdownOpen]);

  useOutsideClick(dropdown, outsideClickHandler);

  return (
    <FormSelectUI.Wrapper
      colsClasses={colsClasses}
      disabled={disabled}
      hasError={hasError}
      dropdownOpen={dropdownOpen}
      label={label}
      isMobileAgnostic={isMobileAgnostic}
      ref={dropdown}
    >
      <FormSelectUI.Control
        {...(register && register(name, validation))}
        autoComplete="off"
        isExpanded={dropdownOpen}
        max={max}
        min={min}
        name={name}
        onClick={toggleDropdown}
        onTogglerClick={toggleDropdown}
        placeholder={placeholder}
        // type={type}
        readOnly={isTabletOrMobile || !!readOnly}
        maxLength={maxLength}
        value={value}
      />

      {dropdownOpen && (
        <FormSelectUI.Menu dropup={dropup}>
          {options.map((option) => (
            <FormSelectUI.Option
              key={option.id}
              onClick={getSelectedItem(option.name)}
              text={option.name.toString()}
              // onClick={getSelectedItem(translationVerification(option))}
              // text={translationVerification(option)}
            />
          ))}
        </FormSelectUI.Menu>
      )}
    </FormSelectUI.Wrapper>
  );
};

export default DatepickerDropdown;
