import React from 'react';
import NavUserButton from '../../../appHeader/components/common/NavUserButton';
import useSmartico from '../hooks/useSmartico';

const SmarticoButton = () => {
  const { config, isActive, isAuthenticated, openMainWidget } = useSmartico();

  if (isActive && config?.showOpenButton && isAuthenticated) {
    return <NavUserButton onClick={() => openMainWidget()} icon="star-circle" notificationsCounter={0} />;
  }

  return null;
};

export default SmarticoButton;
