import { useCallback } from 'react';
import { useAuthentication } from 'hooks/common/useAuthentication';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { useAppDispatch } from 'store';
import { LayoutConfigTypes } from '../../general/types/generalSlice.types';
import { showRootModal } from '../../rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from '../../rootModal/types/modal.types';
import { CustomClickActionListener } from '../../sportsBookListeners/types/sportsBookListeners.type';

const useRequestBetCallback = () => {
  const dispatch = useAppDispatch();
  const { showLoginForm } = useAuthentication();

  const requestBetIsActive = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.REQUEST_BET,
    deviceLayout: 'general',
  })?.isActive;

  const openRequestBet = useCallback(
    (isAuthenticatedSport: boolean) => {
      if (requestBetIsActive) {
        if (isAuthenticatedSport) {
          dispatch(
            showRootModal({
              modalType: 'REQUEST_BET_MODAL',
              modalPriority: ModalPriorityEnum.MEDIUM,
              modalId: 'REQUEST_BET_MODAL',
            }),
          );
        } else {
          showLoginForm();
        }
      }
    },
    [dispatch, showLoginForm, requestBetIsActive],
  );

  const requestBetCallback = useCallback<CustomClickActionListener>(
    ({ type, isAuthenticated: isAuthenticatedSport }) => {
      if (type === 'requestBet') {
        openRequestBet(isAuthenticatedSport);
      }
    },
    [openRequestBet],
  );

  return { requestBetCallback };
};

export default useRequestBetCallback;
