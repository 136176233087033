import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { UI } from 'components/shared/UI';
import { useNavigateToBankTab } from 'pages/myAccount/tabs/cashier/utils/cashier.utils';
import { refreshWidget } from 'shared/common/features/integrations/cashierWidget/cashierWidget.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import { MyProfileBalanceToggler } from '../components';
import { MPDUI } from '../components/MPDUI';
import { useMyProfileConfig } from '../hooks/useMyProfileConfig';
import { selectMyProfile, showMyProfileModal } from '../slice/myProfile.slice';

const MyProfileSectionInfo: React.FC<{ showBalanceToggler?: boolean }> = ({ showBalanceToggler = false }) => {
  const hideDepositButton = useMyProfileConfig()?.hideDepositButton;
  const user = useAppSelector(selectMyProfile.dataUser);
  const bankNavigate = useNavigateToBankTab();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const navigateToBank = () => {
    dispatch(showMyProfileModal(false));
    bankNavigate('deposit');
    dispatch(refreshWidget());
  };

  const cssNS = 'mpd-section-info';

  return (
    <MPDUI.Section section="info">
      <Flex.AC className={`${cssNS}__left-col`}>
        <Icon className={`${cssNS}__icon`} icon="user" />
        <Flex.Col className={`mw-0 overflow-hidden ${cssNS}__user-block`}>
          <MPDUI.Text variant="primary" text={`${user?.firstName || ''} ${user?.lastName || ''}`} />
          <MPDUI.Text variant="secondary" text={user?.username} />
        </Flex.Col>
      </Flex.AC>
      <Flex.AC className={`${cssNS}__right-col`}>
        {showBalanceToggler && <MyProfileBalanceToggler />}
        {!hideDepositButton && !showBalanceToggler && (
          <UI.Button
            data-qid="userDepositBtn"
            size="md"
            onClick={navigateToBank}
            text={t(MyProfile.Button.depositProfileBtn)}
          />
        )}
      </Flex.AC>
    </MPDUI.Section>
  );
};

export default memo(MyProfileSectionInfo);
