import { MutableRefObject, useEffect } from 'react';

export const useOutsideClick = (ref: MutableRefObject<HTMLElement | null>, callback: (event?) => void) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        callback(event);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};

type Props = {
  ref: MutableRefObject<HTMLElement | null>;
  secondRef?: MutableRefObject<HTMLElement | null>;
  disableSecondRefForClick?: boolean;
  callback: (event) => void;
};
export const useOutsideClickPortal = ({ ref, callback, secondRef, disableSecondRefForClick }: Props) => {
  useEffect(() => {
    function handleClickOutside(event) {
      // Stop propagate callBack if click on second Ref
      // if disable callback on click specific element
      if (disableSecondRefForClick && secondRef && secondRef.current && secondRef.current.contains(event.target)) {
        return;
      }
      if (ref && ref.current && !ref.current.contains(event.target)) {
        callback(event);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};
