import React from 'react';
import clsx from 'clsx';
import { cssSocialAccountNS } from './socialAccountsUI.types';

/** Block */
interface BlockProps {
  className?: string;
  title?: string;
  children?: React.ReactNode;
}

const Block: React.FC<BlockProps> = ({ className, title, children }) => {
  const wrapperClass = clsx(cssSocialAccountNS.Block, className);

  return (
    <div className={wrapperClass}>
      {title && <div>{title}</div>}
      {children}
    </div>
  );
};

export default Block;
