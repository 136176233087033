import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import config from 'utils/config';
import { GamificationUser } from '../gamification/types/gamification.types';
import { Integrations, IntegrationType } from '../types/integrations.types';

export const integrationsThunks: Integrations.Thunks = {
  generateToken: createAbortThunk('integrations/generateToken', async (widgetName) => {
    //TODO
    const response = await axiosInstance.get(`${config.API_URL}/api/ews-crm/generate-token/${widgetName}`);
    return response.data;
  }),
};
const { generateToken } = integrationsThunks;

const initialState: Integrations.State = {
  isScriptLoaded: {},
  token: {},
  sport: {
    betSlipQrCode: '',
    isWidgetLoaded: false,
  },
  smartico: null,
  configurator: null,
  casino: {
    isWidgetLoaded: false,
    isSubWidgetLoaded: {},
    favGamesCounter: 0,
    relaunchStatus: null,
    refetchBonusGames: false,
    launchedGameData: {},
    enableColorIcons: false,
  },
  gamification: {
    isWidgetLoaded: null,
    profile: null,
    myChallengesCount: 0,
    myGameChallenges: { biggestPercentage: 0, challengeUpTo100: false },
  },
};

const integrations = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    setIsSportScriptLoaded(state, action: PayloadAction<boolean>) {
      state.isScriptLoaded[IntegrationType.SPORTS_BOOK] = action.payload;
    },
    setIsSportWidgetsLoaded(state, action: PayloadAction<boolean>) {
      state.sport.isWidgetLoaded = action.payload;
    },
    setIsGamificationScriptLoaded(state, action: PayloadAction<boolean>) {
      state.isScriptLoaded[IntegrationType.GAMIFICATION] = action.payload;
    },
    setIsGamificationWidgetsLoaded(state, action: PayloadAction<boolean>) {
      state.gamification.isWidgetLoaded = action.payload;
    },
    setIsGamificationWidgetsProfile(state, action: PayloadAction<GamificationUser.Profile>) {
      state.gamification.profile = action.payload;
    },
    setIsGamificationWidgetsMyChallenges(state, action: PayloadAction<number>) {
      state.gamification.myChallengesCount = action.payload;
    },
    setIsGamificationWidgetsMyGameChallenges(
      state,
      action: PayloadAction<{ biggestPercentage: number; challengeUpTo100: boolean }>,
    ) {
      state.gamification.myGameChallenges.biggestPercentage = action.payload.biggestPercentage ?? 0;
      state.gamification.myGameChallenges.challengeUpTo100 = action.payload.challengeUpTo100 ?? false;
    },
    setIsSmarticoScriptLoaded(state, action: PayloadAction<boolean>) {
      state.isScriptLoaded[IntegrationType.SMARTICO] = action.payload;
    },
    clearSportToken(state) {
      state.token[IntegrationType.SPORTS_BOOK] = null;
    },
    setBetSlipQrCode(state, action) {
      state.sport.betSlipQrCode = action.payload;
    },
    setSmarticoUserCoins(state, action: PayloadAction<number>) {
      if (state.smartico === null) {
        state.smartico = { userCoins: action.payload };
      } else {
        state.smartico.userCoins = action.payload;
      }
    },
    clearBetSlipQrCode(state) {
      state.sport.betSlipQrCode = '';
      state.sport.dailySequenceNumber = '';
      state.sport.terminalBetPin = '';
    },
    createBetTicketDataTerminal(state, action) {
      state.sport.dailySequenceNumber = action.payload?.dailySequenceNumber;
      state.sport.terminalBetPin = action.payload?.pin;
      state.sport.posAddress = action.payload?.posAddress;
      state.sport.posName = action?.payload.posName;
    },
    setConfiguratorData(state, action) {
      state.configurator = action.payload;
    },
    setIsCasinoWidgetScriptLoaded(state, action: PayloadAction<boolean>) {
      state.isScriptLoaded[IntegrationType.CASINO_WIDGET] = action.payload;
    },
    setIsCasinoWidgetLoaded(state, action: PayloadAction<boolean>) {
      state.casino.isWidgetLoaded = action.payload;
    },
    setIsCasinoSubWidgetLoaded(state, action: PayloadAction<{ key: string; isLoaded: boolean }>) {
      const { key, isLoaded } = action.payload;
      state.casino.isSubWidgetLoaded[key] = isLoaded;
    },
    setCasinoWidgetFavGamesCounter(state, action: PayloadAction<number>) {
      state.casino.favGamesCounter = action.payload;
    },
    setCasinoWidgetEnabledColorIcons(state, action: PayloadAction<boolean>) {
      state.casino.enableColorIcons = action.payload;
    },
    setCasinoWidgetGameData(state, action: PayloadAction<Integrations.StartGameProps>) {
      state.casino.launchedGameData = action.payload;
    },
    setReLaunchStatus(state, action: PayloadAction<number>) {
      state.casino.relaunchStatus = action.payload;
    },
    setRefetchBonusGames(state, action: PayloadAction<boolean>) {
      state.casino.refetchBonusGames = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateToken.fulfilled, (state, action) => {
        const widgetName = action.meta.arg;
        state.token[widgetName] = action.payload?.token;
      })
      .addCase(logoutUser, (state) => {
        state.token = {};
        state.smartico = null;
      });
  },
});

export const {
  setIsSportScriptLoaded,
  setIsSmarticoScriptLoaded,
  clearSportToken,
  setBetSlipQrCode,
  clearBetSlipQrCode,
  createBetTicketDataTerminal,
  setSmarticoUserCoins,
  setIsSportWidgetsLoaded,
  setConfiguratorData,
  setIsCasinoWidgetScriptLoaded,
  setIsCasinoWidgetLoaded,
  setIsCasinoSubWidgetLoaded,
  setIsGamificationScriptLoaded,
  setIsGamificationWidgetsLoaded,
  setIsGamificationWidgetsProfile,
  setIsGamificationWidgetsMyChallenges,
  setIsGamificationWidgetsMyGameChallenges,
  setCasinoWidgetFavGamesCounter,
  setCasinoWidgetEnabledColorIcons,
  setCasinoWidgetGameData,
  setReLaunchStatus,
  setRefetchBonusGames,
} = integrations.actions;

export const selectIntegrations = {
  isSportScriptLoaded: (state: RootState) => state.common.integrations.isScriptLoaded[IntegrationType.SPORTS_BOOK],
  isSmarticoScriptLoaded: (state: RootState) => state.common.integrations.isScriptLoaded[IntegrationType.SMARTICO],
  isCasinoWidgetScriptLoaded: (state: RootState) =>
    state.common.integrations.isScriptLoaded[IntegrationType.CASINO_WIDGET],
  betSlipQrCode: (state: RootState): string => state.common.integrations.sport.betSlipQrCode,
  dailySequenceNumber: (state: RootState) => state.common.integrations.sport.dailySequenceNumber,
  terminalBetPin: (state: RootState) => state.common.integrations.sport.terminalBetPin,
  posAddress: (state: RootState) => state.common.integrations.sport.posAddress,
  posName: (state: RootState) => state.common.integrations.sport.posName,
  smarticoUserCoins: (state: RootState) => state.common.integrations.smartico?.userCoins,
  isSportWidgetLoaded: (state: RootState) => state.common.integrations.sport.isWidgetLoaded,
  configuratorData: (state: RootState) => state.common.integrations.configurator,
  isCasinoWidgetLoaded: (state: RootState) => state.common.integrations.casino.isWidgetLoaded,
  isCasinoSubWidgetLoaded: (state: RootState, key: string) => state.common.integrations.casino.isSubWidgetLoaded?.[key],
  casinoFavGamesCounter: (state: RootState) => state.common.integrations.casino.favGamesCounter || 0,
  casinoWidgetEnabledColorIcons: (state: RootState) => state.common.integrations.casino.enableColorIcons || false,
  casinoWidgetGameData: (state: RootState): Integrations.StartGameProps =>
    state.common.integrations.casino.launchedGameData || {},
  casinoRelaunchStatus: (state: RootState) => state.common.integrations.casino.relaunchStatus,
  casinoRefetchBonusGames: (state: RootState) => state.common.integrations.casino.refetchBonusGames,
  isGamificationScriptLoaded: (state: RootState) =>
    state.common.integrations.isScriptLoaded[IntegrationType.GAMIFICATION],
  isGamificationWidgetLoaded: (state: RootState) => state.common.integrations.gamification.isWidgetLoaded,
  getGamificationProfile: (state: RootState): GamificationUser.Profile | null =>
    state.common.integrations.gamification.profile,
  getGamificationMyGamesChallengesInfo: (state: RootState) => state.common.integrations.gamification?.myGameChallenges,
  getGamificationMyChallengesCount: (state: RootState) => state.common.integrations.gamification.myChallengesCount,
};
export default integrations.reducer;
