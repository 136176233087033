import { localLog } from 'modules/casino/shared/utils/common/helpersCommon';
import { store } from 'store';
import { getWindowConfigParam } from 'utils/common/helpersCommon';
import { setLayoutConfig } from '../../general/slice/general.slice';

const setThemeColors = (theme) => {
  Object.keys(theme).map((item) => {
    if (theme[item]?.value || theme[item]?.value === '') {
      if (theme[item]?.value === '') return;
      document.documentElement?.style.setProperty(theme[item].name, theme[item].value);
    } else {
      setThemeColors(theme[item]);
    }
  });
};

const isLinkAttached = (attr: string): boolean => document.querySelectorAll(`link[href="${attr}"]`).length > 0;

const setSkin = (skin) => {
  const epoch = getWindowConfigParam('EPOCH');
  const bodyLink: HTMLAnchorElement | null = document.querySelector('link[id="main-style"]');

  if (process.env.NODE_ENV != 'production') {
    // Sport css
    const sportLink: HTMLScriptElement | null = document.getElementById('egtd-sb') as HTMLScriptElement;
    const CDN_CSS_LINK = sportLink ? sportLink.src.slice(0, sportLink.src.lastIndexOf('/')) : ``;
    const sportCss = `${CDN_CSS_LINK}/theme/${skin}/index.css?v=${epoch}`;
    const sportBodyLink: any | null = document.querySelector('link[aria-label="EGTD-Sports"]');
    if (!sportBodyLink?.href.includes(skin) && sportBodyLink) {
      sportBodyLink.href = sportCss;
    }
    // Local default css path
    const bodyHref = `https://winbet-dev.egt-digital.com/theme/${skin}/index.css?v=${epoch}`;
    const isAttachedBody = isLinkAttached(bodyHref);
    bodyLink?.href &&
      !isAttachedBody &&
      (bodyLink.href = `https://winbet-dev.egt-digital.com/theme/${skin}/index.css?v=${epoch}`);
  } else {
    // Platform css
    const linkCss = `/theme/${skin}/index.css?v=${epoch}`;
    const isAttachedBody = isLinkAttached(linkCss);
    !isAttachedBody && bodyLink?.href && (bodyLink.href = linkCss);
  }
};
const setComponents = (theme) => {
  localLog({ message: theme });
};

const realMerge = (to, from) => {
  to = { ...to };
  from = { ...from };

  for (const n in from) {
    if (typeof to[n] != 'object') {
      to[n] = from[n];
    } else if (typeof from[n] == 'object') {
      to[n] = realMerge(to[n], from[n]);
    }
  }
  return to;
};

const changeLayoutConfig = (data) => {
  const mergedObj = realMerge(window.layout, data);

  store.dispatch(setLayoutConfig({ ...mergedObj }));
};

export { setThemeColors, setSkin, setComponents, changeLayoutConfig };
