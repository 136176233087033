import React, { RefObject, useEffect, useReducer, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { Icon } from 'components/shared';
import { FormUI } from 'components/shared/forms/components';
import { Flex } from 'components/shared/layout';
import { Breakpoints } from 'theme/Theme';

interface ReactDatePickerWithInput extends ReactDatePicker {
  input: HTMLInputElement;
}

const useDisableInputEditOnMobileAndTablet = (calendarRef: RefObject<ReactDatePickerWithInput>) => {
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isTabletOrMobile}px)` });

  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.input.readOnly = isMobile || isTabletOrMobile;
    }
  }, [calendarRef.current, isTabletOrMobile]);
};

interface Props {
  dateFormat?: string;
  label: string;
  maxDate?: Date | string | null;
  minDate?: Date | string | null;
  onChange: (date: Date | null, event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void;
  value: string | undefined;
  selected: Date | string | null;
  onCalendarOpen?: () => void;
  onCalendarClose?: () => void;
  dataQid?: string;
}

const FinancialDatePicker: React.FC<Props> = ({
  dateFormat = 'dd.MM.yyyy',
  label,
  maxDate,
  minDate,
  onChange,
  onCalendarOpen,
  onCalendarClose,
  value,
  selected,
  dataQid,
}) => {
  const calendarRef = useRef<ReactDatePickerWithInput>(null);
  useDisableInputEditOnMobileAndTablet(calendarRef);
  const [updateCount, forceUpdate] = useReducer((x) => x + 1, 0);

  const handleCalendarOpen = () => {
    onCalendarOpen && onCalendarOpen();
  };

  const handleCalendarClose = () => {
    onCalendarClose && onCalendarClose();
    forceUpdate();
  };

  return (
    <>
      <FormUI.Label label={label} />
      <div className="form-select" data-qid={dataQid}>
        <Flex className="w-100 form-select__header">
          <ReactDatePicker
            portalId="root-portal"
            dateFormat={dateFormat}
            className={`form-control form-select__control w-100`}
            onChange={onChange}
            value={value}
            {...(maxDate ? { maxDate: typeof maxDate === 'string' ? new Date(maxDate) : maxDate } : {})}
            {...(minDate ? { minDate: typeof minDate === 'string' ? new Date(minDate) : minDate } : {})}
            {...(selected ? { selected: typeof selected === 'string' ? new Date(selected) : selected } : null)}
            shouldCloseOnSelect
            disabledKeyboardNavigation
            onCalendarOpen={handleCalendarOpen}
            onCalendarClose={handleCalendarClose}
            ref={calendarRef}
            calendarClassName="rasta"
          />
          <Flex.Center
            key={updateCount} // do not remove, this is used to avoid a nasty side effect from react-datepicker
            className="form-select__toggler"
            onClick={() => {
              calendarRef.current?.setOpen(true);
            }}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
          >
            <Icon className="form-select__toggler-icon react-datepicker__icon" icon="calendar-outline" />
          </Flex.Center>
        </Flex>
      </div>
    </>
  );
};

export default FinancialDatePicker;
