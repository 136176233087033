import { useEffect, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { selectBottomTabBar } from '../../bottomTabBar/slice/bottomTabBar.slice';
import { setDrawer } from '../slice/drawer.slice';

type Props = { drawerRef: React.RefObject<HTMLDivElement>; isToggled: boolean };

export const useDrawerVisibility = ({ drawerRef, isToggled }: Props) => {
  const isMyBetsVisible = useAppSelector(selectBottomTabBar.isMyBetsVisible);
  const rootDiv: HTMLDivElement | null = document.querySelector('#root');

  const dispatch = useAppDispatch();

  const handleClickOutside = useCallback((event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      dispatch(setDrawer(false));
    }
  }, []);

  useEffect(() => {
    if (isToggled) {
      document.addEventListener('mousedown', handleClickOutside);
      rootDiv?.classList.add('drawer--visible');
      document.documentElement.classList.add('h-100vh-1px');
      !isMyBetsVisible && window.scroll(0, 1);

      window.isRNWebView &&
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({ action: 'bounce', command: { isBounceEnabled: false } }),
        );
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      rootDiv?.classList.remove('drawer--visible');
      !isMyBetsVisible && document.documentElement.classList.remove('h-100vh-1px');

      window.isRNWebView &&
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({ action: 'bounce', command: { isBounceEnabled: true } }),
        );
    }
  }, [isToggled, rootDiv, isMyBetsVisible, handleClickOutside]);

  useEffect(() => {
    return () => {
      dispatch(setDrawer(false));
      rootDiv?.classList.remove('drawer--visible');
      document.documentElement.classList.remove('h-100vh-1px');
    };
  }, [dispatch, rootDiv]);
};
