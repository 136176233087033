import styled from 'styled-components';
import { Breakpoints } from 'theme/Theme';

export const StyledPageCTA = styled.div`
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(1, 1fr);
  min-height: 290px;
  @media (min-width: ${Breakpoints.isMobile}px) {
    min-height: 440px;
  }
  transition: min-height 0.15s;
`;

export const StyledPageCTABackButton = styled.div`
  grid-row-start: 1;
  grid-column-start: 1;
  place-self: start;
  margin-top: clamp(0.625rem, 0.481rem + 0.62vw, 0.875rem);
  width: clamp(1.125rem, 0.981rem + 0.62vw, 1.375rem);
  height: clamp(1.125rem, 0.981rem + 0.62vw, 1.375rem);
  transform: rotate(180deg);
  fill: #fff;
  cursor: pointer;
`;

export const StyledPageCTATitle = styled.div`
  margin-bottom: 10px;
  @media (min-width: ${Breakpoints.isDesktop}px) {
    margin-bottom: 18px;
  }
  font-size: clamp(1.5rem, 0.851rem + 2.77vw, 2.625rem);
  font-weight: 700;
  line-height: 1.4;
  color: #fce34c;
  transition: margin 0.15s;
`;

export const StyledPageCTASubTitle = styled.div.attrs({ className: 'text-clamp-2-line' })`
  margin-bottom: 12px;
  @media (min-width: ${Breakpoints.isDesktop}px) {
    margin-bottom: 36px;
  }
  font-size: clamp(1.25rem, 0.817rem + 1.85vw, 2rem);
  line-height: 1.4;
  color: #fff;
  transition: margin 0.15s;
`;
