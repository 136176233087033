import React from 'react';
import { motion } from 'framer-motion';
import useTransitionTabs from '../../../hooks/useTransitionTabs';
import { Category, OffersTabLayout } from '../../types/offersTab.types';
import BonusCategory from '../BonusCategory';

type Props = {
  categories: Category[];
  activeCategory?: Category;
  layout: OffersTabLayout;
};

export const OffersTabLayout02: React.FC<Props> = ({ categories, activeCategory, layout }) => {
  const { initialOpacity, controls } = useTransitionTabs(categories);
  return (
    <>
      {categories?.map((category, idx) =>
        activeCategory ? null : (
          <motion.div initial={{ opacity: initialOpacity }} key={category?.alias} custom={idx} animate={controls}>
            {category?.promotions && (
              <BonusCategory promotions={category?.promotions} activeCategory={category} layout={layout} />
            )}
          </motion.div>
        ),
      )}
    </>
  );
};
