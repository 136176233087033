import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';

export enum CasinoWidgetTemplates {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
  MACRO = 'MACRO',
  REGULAR = 'REGULAR',
  SECTION = 'SECTION',
  RIBBON = 'RIBBON',
  SECTION_STAKE = 'SECTION_STAKE',
  SECTION_STAKE_PLUS = 'SECTION_STAKE_PLUS',
  LANDING = 'LANDING',
  BANNER_SLIDER = 'BANNER_SLIDER',
  JACKPOT_BANNER = 'JACKPOT_BANNER',
}

export const CELL_WIDGET_RESTRICTED_CATEGORIES = [
  CategoryLayoutTypes.MOST_SEARCHED_CATEGORY,
  CategoryLayoutTypes.EXTERNAL_CATEGORY,
  CategoryLayoutTypes.SEARCH_RESULTS_CATEGORY,
];
export const CELL_WIDGET_RESTRICTED_SUBTYPE_LAYOUTS = [
  CategorySubTypes.SIDEBAR,
  CategorySubTypes.PROMOTION,
  CategorySubTypes.FOOTER,
  CategorySubTypes.FREE_SPIN,
  CategorySubTypes.FREE_SPIN_CHALLENGE,
  CategorySubTypes.FAVOURITES_MODAL,
  CategorySubTypes.RESULT_SEARCHED,
  CategorySubTypes.MOST_SEARCHED,
  CategorySubTypes.IN_GAME_SEARCHED_RESULTS,
  CategorySubTypes.IN_GAME_MOST_SEARCHED_RESULTS,
];
