import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { Sport } from 'utils/common/types/sportTypes';
import { DrawerThunks, selectDrawer } from '../slice/drawer.slice';
import { DrawerTypes, SportsType } from '../types/drawer.types';

const itemsSelector = {
  [SportsType.Sport]: selectDrawer.sports,
  [SportsType.ESports]: selectDrawer.esports,
};

export const useGetDrawerSportItems = (
  type: string,
): { items: Sport.ActiveSport[]; activeItem: number; sportDrawerContainers: DrawerTypes.SportVertical[] } => {
  const dispatch = useAppDispatch();
  const items: Sport.ActiveSport[] = useAppSelector(itemsSelector[type]);
  const sportDrawerContainers = useAppSelector(selectDrawer.sportContainers);
  const activeItem = useAppSelector(
    SportsType.Sport === type ? selectDrawer.activePrematchSportID : selectDrawer.activeESportId,
  );

  useEffect(() => {
    if (type === SportsType.Sport) {
      dispatch(DrawerThunks.fetchSports());
    } else {
      dispatch(DrawerThunks.fetchESports());
    }
  }, [dispatch, type]);

  return { items, activeItem, sportDrawerContainers };
};
