import React, { HTMLAttributes } from 'react';
import { Text } from 'components/shared';
import ExpandableIcon from 'components/shared/icons/ExpandableIcon';
import { cssNS } from 'modules/retail/modules/cashier/types/cashierModalUITypes';

/** Ticket Header */
interface SSBTTicketHeaderProps extends HTMLAttributes<HTMLDivElement> {
  ticketIdText?: string;
  ticketId?: string;
  onClick?: React.MouseEventHandler;
  icon?: boolean;
  isExpanded?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const SSBTTicketHeader: React.FC<SSBTTicketHeaderProps> = ({
  ticketIdText,
  ticketId,
  onClick,
  icon,
  isExpanded,
  className,
  children,
}) => {
  const classProp = className ? { class: className } : undefined;

  return (
    <egtd-ssbt-ticket-header {...classProp} data-expanded={isExpanded} onClick={onClick}>
      <Text className={`${cssNS}__text--secondary`} text={ticketIdText + ': ' + ticketId} />
      {children}
      {icon && <ExpandableIcon isExpanded={isExpanded} size={20} />}
    </egtd-ssbt-ticket-header>
  );
};

export default SSBTTicketHeader;
