import { EntityId, EntityState } from '@reduxjs/toolkit';
import { SocialTypes } from 'components/shared/forms/components/FormSocial/types/formSocial.types';
import { FormFieldTypes } from 'components/shared/forms/formTypes';
import { PlayerLimits } from 'pages/myAccount/tabs/limits/types/limits.types';
import { PlayerRestrictions } from 'pages/myAccount/tabs/responsibleGambling/types/responsibleGambling.types';
import { General } from '../../general/types/generalSlice.types';

export interface MyProfileInitialState {
  isModalShowing: boolean;
  showModalLock: boolean;
  user: User | null;
  error: string | null;
  balance?: Balance | null;
  balanceVisibility: boolean;
  preferredTheme: PreferredTheme;
  migrateUsername: { error?: string | null; formFields: EntityState<FormFieldTypes, EntityId> };
  errors: {
    deleteAccount?: null | string;
    passwordCheck?: null | string;
  };
  campaignBonuses: CampaignBonuses | null;
  beneficientDetails: BeneficientDetailsType | null;
  beneficientIsCreated: boolean;
  preferredOddTypeId: number;
}

export type BeneficientDetailsType = {
  city: string;
  street: string;
  district: string;
  state: string;
  address: string;
  municipality: string;
  nationality: string;
  buildingNumber: string;
  country: string;
  zipCode: string;
  firstName: string;
  lastName: string;
  mothersName: string;
  email: string;
  phoneNumber: string;
  politicallyExposed: string;
  genericVerificationId: string;
  ucn: string;
  occupation: string;
  apartmentNumber: string;
  streetName: string;
  countryName: string;
  sexTitle: string;
  toggleMothersName: boolean;
  playerId: number;
  beneficientId: number;
  birthDate: string;
};

export type CampaignBonuses = {
  bonusCollection?: CampaignBonusCollection;
};

export type CampaignBonusCollection = {
  folder?: string;
  completion?: {
    completedPercentage: number;
    groupFolderIndex: string;
    levelFolderIndex: string;
  };
};

export enum BalanceType {
  WithdrawableBalance = 'withdrawableBalance',
  SportBalanceTotal = 'sportBalanceTotal',
  // SportBalance = 'sportBalance', // missing
  CasinoBalanceTotal = 'casinoBalanceTotal',
  // CasinoBalance = 'casinoBalance', // missing
  LiveCasinoBalanceTotal = 'liveCasinoBalanceTotal',
  // LiveCasinoBalance = 'liveCasinoBalance', // missing
  BalanceTotal = 'totalBalance',
  FreeBetSportBalance = 'freeBetSportBalance',
  FreeBetLiveCasinoBalance = 'freeBetLiveCasinoBalance',
  // TotalFreeBetBalance = 'totalFreeBetBalance', // missing
  BonusBalance = 'bonusBalance',
}
export interface Country {
  id: number;
  name: string;
  code: string;
  codeIso: string;
  isActive: boolean;
  nameTranslations: string;
  text: string;
  value: string;
}

export interface Currency {
  id: number;
  symbol: string;
  name: string;
  precision: number;
  nameTranslations: string;
}

export interface Language {
  id: number;
  language: string;
  nameTranslations: string;
}

export interface Product {
  id: number;
  countryId: number;
  name: string;
}

export interface RiskLevel {
  id: number;
  name: string;
  nameTranslations: string;
}

export interface PlayerGroupLimit {
  playerLimitType: string;
  smlFactorPrematch: number;
  mmlFactorPrematch: number;
  smlFactorLive: number;
  mmlFactorLive: number;
  multipleMassLoss: number;
  additionalLiveDelay: number;
  cashOut: boolean;
  reoffer: boolean;
  autoReoffer: boolean;
}

export interface Liability {
  percentage: number;
}

export interface RiskConfig {
  liability: Liability;
}

export interface Status {
  id: number;
  name: string;
  countryId: number;
  nameTranslations: string;
}

export interface RegulationDetails {
  BgNap: string;
}

export interface Rule {
  id: number;
  name: string;
  rule: string;
  executionOrder: number;
  code: string;
  isActive: boolean;
  createDate: number;
  updateDate: number;
  createdBy: number;
  updatedBy: number;
  typeId: number;
  type: string;
  subTypeId: number;
  subType: string;
  scopeId: number;
}

export interface PlayerRuleStatus {
  id: number;
  name: string;
}

export interface PlayerRule {
  id: number;
  playerId: string;
  ruleId: number;
  statusId: number;
  createDate: number;
  updateDate: number;
  rule: Rule;
  status: PlayerRuleStatus;
}

export type PlayersNotification = {
  playerNotificationTypeId: number;
};

type Rank = {
  id: number;
  name: string;
  businessUnit: string;
  isDefault: boolean;
  department: string; // zendesk chat department 'Default' | 'HVC'
};

type UserTermsAndConditions = {
  alias: string;
  version: string;
  subVersion: string;
};

export type AdditionalDetails = {
  vsvdi?: boolean;
  fvsvdi?: number;
  taxRegion?: string;
  sexTitle?: string;
  hearForUs?: string;
  vDocument?: boolean;
  fvDocument?: number;
  mothersLastName?: string;
  occupation?: string;
  buildingNumber?: string;
  district?: string;
  municipality?: string;
  state?: string;
  streetName?: string;
  apartmentNumber: string;
  countryName?: string;
  toggleMothersName?: boolean;
  hasBeneficiery?: boolean;
  nationality?: string;
};

export type MFA_Channels = 'email' | 'sms' | 'phoneCall' | '';

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  gender: string;
  ucn: string;
  alias: string;
  email: string;
  username: string;
  city: string;
  street: string;
  address: string;
  address2: string;
  phoneNumber: string;
  registeredOn: number;
  locked: boolean;
  deposited: boolean;
  firstDepositAmount: number;
  birthdate: number;
  updatedBy: string;
  zipCode: string;
  languageId: number;
  createDate: number;
  updateDate: number;
  isTestPlayer: boolean;
  verificationStatusesId: number;
  country: Country;
  currency: Currency;
  language: Language;
  product: Product;
  riskLevel: RiskLevel;
  playerGroupLimit: PlayerGroupLimit;
  riskConfig: RiskConfig;
  playersNotifications: PlayersNotification[];
  playerRestrictions: PlayerRestrictions.Restriction[];
  status: Status;
  regulationDetails: RegulationDetails;
  playerRules: PlayerRule[];
  ucnTypeId: number;
  ipAdress: string;
  businessUnit: string;
  currentLoginIp: string;
  currentLoginDevice: string;
  currentLoginCountry: string;
  playerLimits: PlayerLimits.PlayerLimit[];
  // playerCommunications: any[];
  // timezoneId: number;  - obsolete
  ianaTimezone: string;
  offsetMinutes: number;
  preferredTheme: PreferredTheme;
  politicallyExposed: boolean;
  isPendingDeclaration?: boolean;
  isMfaEnabled: boolean;
  mfaChannel?: MFA_Channels | null;
  mfaChannels?: MFA_Channels[] | null;
  pendingDeclarations;
  isRequestingCancellation?: boolean;
  isPhoneVerified: boolean;
  rank: Rank;
  documentVerificationStatus: string;
  socialMediaAccounts?: SocialTypes[];
  termsAndConditionsToAccept?: UserTermsAndConditions[] | null;
  needDeclaration?: boolean; // Should have declaration after cool off in player limit.
  additionalDetails?: AdditionalDetails;
}
export interface Balance {
  withdrawableBalance: number;
  withdrawableBalanceEur: number;
  sportBalance: number;
  sportBalanceEur: number;
  casinoBalance: number;
  casinoBalanceEur: number;
  liveCasinoBalance: number;
  liveCasinoBalanceEur: number;
  bonusBalance: number;
  bonusBalanceEur: number;
  sportBalanceTotal: number;
  sportBalanceTotalEur: number;
  casinoBalanceTotal: number;
  casinoBalanceTotalEur: number;
  liveCasinoBalanceTotal: number;
  liveCasinoBalanceTotalEur: number;
  totalBalanceEur: number;
  totalBalance: number;
  freeBetSportBalance: number;
  freeBetSportBalanceEur: number;
  freeBetLiveCasinoBalance: number;
  freeBetLiveCasinoBalanceEur: number;
  totalFreeBetBalance: number;
  totalFreeBetBalanceEur: number;
  blockedBalance?: number;
}

export type PreferredTheme = 'Light' | 'Dark';

export enum MyProfileTabsEnum {
  ACCOUNT = 'account',
  OFFERS = 'offers',
  NOTIFICATIONS = 'notifications',
  CLUB = 'captainUp',
  SMARTICO = 'smartico',
}

export type MyProfileTabType = {
  key: 'account' | 'offers' | 'captainUp' | 'smartico';
  hasBadge: boolean;
  label: string;
  icon?: string;
  hideTab?: boolean;
  subMenu: General.MyProfileRoutes[] | null;
};

export type SectionWithConfig = 'account';
