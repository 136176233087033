import styled, { CSSProp } from 'styled-components';
import { gamificationGenerateGradient } from '../../utils/Gamification.utils';

export const StyledCTAText = styled.div.attrs(({ ...rest }) => ({
  ...rest,
}))<{ $styledCss?: CSSProp; $textColor?: string; $bgColor?: string; $fontWeight?: string }>`
  font-size: clamp(0.75rem, -0.375rem + 7.5vw, 1.125rem);
  font-weight: ${({ $fontWeight }) => ($fontWeight ? $fontWeight : '700')};
  line-height: 1.2;
  color: ${({ $textColor }) => ($textColor ? $textColor : '#fff')};
  text-shadow: 0px 1px 2px #000;
  ${({ $styledCss }) => $styledCss}
  ${({ $bgColor }) => $bgColor && gamificationGenerateGradient($bgColor)}
`;
