import styled from 'styled-components';

export const SCSwitcher = styled.select`
  display: flex;
  align-self: center;
  margin: 5px;
  padding: 5px;

  border: 2px solid hsl(210, 3%, 50%);
  border-radius: 6px;

  font-size: 12px;

  color: hsl(210, 3%, 15%);
  background: hsl(210, 3%, 70%);

  cursor: pointer;

  &:not(:first-of-type) {
    margin-left: 0;
  }
`;
