import { useEffect } from 'react';
import { useQuery } from 'hooks/useQuery';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { useSvaraAppLogin } from './useSvaraAppLogin';
import { setOpenFromSvara, setOpenLeaderboard } from '../slice/svaraAppLogin.slice';

export const useSetOpenFromSvara = () => {
  const dispatch = useAppDispatch();
  const { query } = useQuery();
  const svaraAppLogin = useSvaraAppLogin();
  const isLayoutConfigLoaded = useAppSelector(selectGeneral.isLayoutConfigLoaded);

  useEffect(() => {
    if (isLayoutConfigLoaded && svaraAppLogin) {
      if (query.get('openFromSvara')) {
        dispatch(setOpenFromSvara(true));
      }

      if (query.get('openLeaderboard')) {
        dispatch(setOpenLeaderboard(true));
      }
    }
  }, [dispatch, query]);

  return;
};
