import React from 'react';
import clsx from 'clsx';

/** NewTagProps */
interface NewTagProps {
  className?: string;
  children?: React.ReactNode;
  size?: number;
  bg?: string;
  color?: string;
  leftPosition?: boolean;
}

const NewTag: React.FC<NewTagProps> = ({
  className,
  size = 24,
  bg = 'var(--egtd-c-new-label--bg)',
  color = 'var(--egtd-c-new-label--color)',
  leftPosition,
}) => {
  const svgClass = clsx('egtd-c-new-label', className, leftPosition && 'egtd-c-new-label--left');

  if (leftPosition) {
    return (
      <svg className={svgClass} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width={size} height={size}>
        <path
          id="egtd-c-new-label-bg"
          fill={bg}
          d="m216.5 3.2-213.4 213.3c-2 2-3.1 4.7-3.1 7.6v277.2c0 5.9 4.8 10.7 10.7 10.7 2.8 0 5.6-1.1 7.6-3.1l490.6-490.6c2-2 3.1-4.7 3.1-7.6 0-1.4-.3-2.8-.8-4.1-1.6-4-5.5-6.6-9.9-6.6h-277.3c-2.8.1-5.5 1.2-7.5 3.2z"
        />
        <g id="egtd-c-new-label-color" fill={color}>
          <path d="m82.8 211.2c-4.1 4.3-3.9 11 .3 15.1l39.1 39.1-72.1-14.4c-3.5-.7-7.1.4-9.6 2.9-4.2 4.2-4.2 10.9 0 15.1l64 64c4.1 4 10.7 4 14.8 0 4.3-4.1 4.4-10.9.3-15.1l-39.1-39.1 72.2 14.5c.7.1 1.4.2 2.1.2 5.9 0 10.7-4.8 10.7-10.7 0-2.9-1.1-5.6-3.3-7.6l-64-64-.3-.3c-4.3-4.1-11-4-15.1.3z" />
          <path d="m211.1 211.3-24.4 24.4-16.9-16.9 13.8-13.8c2-2.1 3.1-4.8 3.1-7.6s-1.1-5.5-3.1-7.5c-4.2-4.1-10.9-4.2-15.1 0l-13.8 13.8-16.9-16.9 24.5-24.4.3-.3c4.1-4.3 3.9-11-.3-15.1-4.3-4.1-11-3.9-15.1.3l-32 32c-4.2 4.2-4.2 10.9 0 15.1l64 64c4.2 4.2 10.9 4.2 15.1 0l32-32c2-2.1 3.1-4.8 3.1-7.6 0-2.9-1.2-5.6-3.2-7.5-4.2-4.2-10.9-4.2-15.1 0z" />
          <path d="m314.7 133.5c0 5.9-4.8 10.7-10.7 10.7-1.1 0-2.3-.2-3.4-.5l-43.8-14.6 14.6 43.8c1.3 4.1.1 8.6-3.1 11.4-3.3 2.8-7.9 3.4-11.8 1.5l-85.3-42.8c-5.3-2.6-7.4-9-4.8-14.3s9-7.4 14.3-4.8l62.3 31.1-13.2-39.6c-1.3-3.8-.3-8.1 2.6-10.9 2.9-2.9 7.1-3.9 10.9-2.6l39.6 13.2-31.1-62.3c-2.6-5.3-.5-11.7 4.8-14.3s11.7-.5 14.3 4.8l42.7 85.3c.7 1.6 1.1 3.3 1.1 4.9z" />
        </g>
      </svg>
    );
  }

  return (
    <svg className={svgClass} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={size} height={size}>
      <path
        id="egtd-c-new-label-bg"
        fill={bg}
        d="M508.9 216.5L295.6 3.1c-2-2-4.7-3.1-7.6-3.1H10.7C4.8 0 0 4.8 0 10.7c0 2.8 1.1 5.6 3.1 7.6L493.8 509c2 2 4.7 3.1 7.6 3.1 1.4 0 2.8-.3 4.1-.8 4-1.6 6.6-5.5 6.6-9.9V224c-.1-2.8-1.2-5.5-3.2-7.5z"
      />
      <g id="egtd-c-new-label-color" fill={color}>
        <path d="M224 170.7c-5.9 0-10.7-4.8-10.7-10.7 0-.7.1-1.4.2-2.1L228 85.7l-39.1 39.1c-4.2 4.1-11 4-15.1-.3-4-4.1-4-10.7 0-14.8l64-64c4.2-4.2 10.9-4.2 15.1 0 2.5 2.5 3.6 6.1 2.9 9.6l-14.4 72.1 39.1-39.1c4.1-4.2 10.8-4.4 15.1-.3s4.4 10.8.3 15.1l-.3.3-64 64c-2 2.2-4.7 3.3-7.6 3.3zM288 234.7c-2.8 0-5.5-1.1-7.6-3.1l-32-32c-4.2-4.2-4.2-10.9 0-15.1l64-64c4.2-4.2 10.9-4.2 15.1 0l32 32c4.2 4.1 4.4 10.8.3 15.1-4.1 4.2-10.8 4.4-15.1.3l-.3-.3-24.4-24.5-48.9 48.9 24.4 24.4c4.2 4.2 4.2 10.9 0 15.1-1.9 2-4.6 3.2-7.5 3.2z" />
        <path d="M309.4 192c-2.8 0-5.5-1.1-7.6-3.1l-21.3-21.3c-4.1-4.2-4-11 .3-15.1 4.1-4 10.7-4 14.8 0l21.3 21.3c4.2 4.2 4.1 10.9 0 15.1-2 2-4.7 3.1-7.5 3.1zM373.4 320c-5.9 0-10.7-4.8-10.7-10.7 0-1.1.2-2.3.5-3.4l14.6-43.8-43.8 14.6c-4.1 1.3-8.6.1-11.4-3.1-2.8-3.3-3.4-7.9-1.5-11.8l42.7-85.3c2.6-5.3 9-7.4 14.3-4.8s7.4 9 4.8 14.3l-31.1 62.3 39.6-13.2c3.8-1.3 8.1-.3 10.9 2.6 2.9 2.9 3.9 7.1 2.6 10.9l-13.2 39.6 62.3-31.1c5.3-2.6 11.7-.5 14.3 4.8s.5 11.7-4.8 14.3l-85.3 42.7c-1.5.7-3.2 1.1-4.8 1.1z" />
      </g>
    </svg>
  );
};

NewTag.displayName = 'EGTD Casino Categories New Tag';

export default NewTag;
