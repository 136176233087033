import React, { memo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { usePreloadImage } from 'hooks/common/usePreloadImage';
import { NotificationTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationTypes';
import { Mixins } from 'theme/StyledComponents/shared';
import { Breakpoints } from 'theme/Theme';
import { cdnConfig, fitCover } from 'utils/common/cdnConfig';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import config from 'utils/config';

interface Props {
  animationParams: NotificationTypes.GamificationModalParams;
  isVisible: boolean;
  onClickOutside: () => void;
  actionButton;
  handleCloseModal: () => void;
  winText?: string[];
}

const GiftModal: React.FC<Props> = ({ animationParams, isVisible, actionButton, winText, handleCloseModal }) => {
  const path = `${cdnConfig.GAMIFICATION_MODULE}/${animationParams.folder}`;
  const isTabletUp = useMediaQuery({ query: `(min-width: ${Breakpoints.isMobile}px)` });
  const popupBGSource = isTabletUp ? fitCover(440 * 2, 640 * 2) : fitCover(375 * 2, 546 * 2);
  const popupIcon = isTabletUp ? fitCover(28 * 2, 28 * 2) : fitCover(28 * 2, 28 * 2);
  const popupBG = `${config.CDN_IMAGE_API}${popupBGSource}${path}/pop-up.png`;
  const closeIcon = `${config.CDN_IMAGE_API}${popupIcon}${path}/close.png`;

  const { isImageLoaded } = usePreloadImage(popupBG, undefined, 0);
  const handleStopPropagation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };
  const { t } = useTranslation();

  return (
    <AnimatePresence>
      {isVisible && (
        <StyledTerms
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1, transition: { delay: 5 } }}
          exit={{ scale: 0, opacity: 0, transition: { delay: 0 } }}
          onClick={handleCloseModal}
        >
          {isImageLoaded && (
            <>
              <FramerWrapper image={popupBG} className="d-flex-col-center" onClick={handleStopPropagation}>
                <WinWrapper className="mt-20">
                  <CloseModal onClick={handleCloseModal} $closeDay={true}>
                    <span>{t(MyProfile.OffersTab.closeBtn)}</span>
                    <CloseImg src={closeIcon} />
                  </CloseModal>
                  <WinTextWrapper>{winText?.map((text, idx) => <WinText key={idx}>{text}</WinText>)}</WinTextWrapper>
                </WinWrapper>
                <ButtonWrapper>{actionButton}</ButtonWrapper>
              </FramerWrapper>
            </>
          )}
        </StyledTerms>
      )}
    </AnimatePresence>
  );
};

const StyledTerms = styled(motion.div).attrs({
  className: 'wh-100',
})`
  z-index: 20;
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`;
const FramerWrapper = styled(motion.div)<{ image: string }>`
  background: ${({ image }) => `url(${image})`};
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 100%;
  max-width: 375px;
  max-height: 530px;
  background-size: contain;
  background-repeat: no-repeat;
  overscroll-behavior: contain;
  background-position: center;

  position: relative;

  @media screen and (min-width: 400px) {
    max-width: 375px;

    max-height: 548px;
  }
  @media screen and (min-width: ${Breakpoints.isMobile}px) {
    max-height: 640px;
    max-width: 440px;
  }
  @media (max-width: 926px) and (orientation: landscape) {
    max-height: 420px;
  }
`;
const WinTextWrapper = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: var(--yellow);
  padding: 0 10px;
  padding-top: 120px;
  line-height: 1.1;
  text-align: center;
  @media (max-width: 926px) and (orientation: landscape) {
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    font-size: 18px;
  }
`;
const WinText = styled.div`
  padding-bottom: 16px;
`;

const WinWrapper = styled.div`
  max-width: 62%;
  width: 100%;
  color: #fff;
  position: relative;
  height: 82%;

  font-size: ${Mixins.Size.toRem(12)};
  @media (max-width: ${Breakpoints.isMobile}px) and (orientation: landscape) {
    max-width: 49%;
  }
  @media (max-width: 926px) and (orientation: landscape) {
    max-width: 43%;
  }
`;

const CloseModal = styled.div<{ $closeDay?: boolean }>`
  margin-bottom: 10px;
  color: #fff;
  font-size: ${Mixins.Size.toRem(12)};
  cursor: pointer;
  @media screen and (min-width: ${Breakpoints.isMobile}px) {
    margin-bottom: 8px;
  }
  ${({ $closeDay }) =>
    $closeDay &&
    `
    position:absolute;
    top:42px;
    right:0;
  `}
`;
const CloseImg = styled.img`
  width: 18px;
  margin-left: 4px;
  height: 18px;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    width: 28px;
    height: 28px;
  }
`;

const ButtonWrapper = styled.div`
  height: 66px;
  width: 220px;
  @media screen and (max-width: ${Breakpoints.isDesktop}px) and (orientation: landscape) {
    width: 180px;
  }
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    width: 239px;
  }
`;

export default memo(GiftModal);
