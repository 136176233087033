import React from 'react';
import clsx from 'clsx';
import { SCPrevOdds } from './PrevOdds.styled';

/** PrevOdds */
interface PrevOddsProps {
  className?: string;
  prevOdds?: string | number;
}

const PrevOdds: React.FC<PrevOddsProps> = ({ className, prevOdds }) => {
  const oddsClass = clsx(className);
  return (
    <SCPrevOdds className={oddsClass}>
      <del>{prevOdds}</del>&nbsp;›&nbsp;
    </SCPrevOdds>
  );
};

export default PrevOdds;
