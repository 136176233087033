// eslint-disable-next-line import/no-cycle
import Tab from './Tab';
import TabList from './TabList';
import TabPanel from './TabPanel';
import Tabs from './Tabs';

export const TabsUI = {
  Wrapper: Tabs,
  List: TabList,
  Tab: Tab,
  Panel: TabPanel,
};
