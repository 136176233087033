import React, { useEffect, memo } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { Flex } from 'components/shared/layout';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { CasinoLobbyType } from 'modules/casino/shared/constants';
import useGeneralCasinoType from 'modules/casino/shared/hooks/useGeneralCasinoType';
import { selectAuthentication, validateUserSession } from 'pages/auth/login/slice/login.slice';
import { NavBrand } from 'shared/common/features/appHeader/components';
import { AppThemeProvider } from 'shared/common/features/appThemeProvider';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { useAppDispatch, useAppSelector } from 'store';
import { getError } from 'utils/common/helpersCommon';
import { cssGlobalNS } from 'utils/common/types/css.types';
import DataLoader from './DataLoader';

const Preloader: React.FC = () => {
  const validateSession = useAppSelector(selectAuthentication.validateSession);
  const navigationConfig = useLayoutConfigType({ moduleLayout: 'platform', innerLayout: LayoutConfigTypes.NAVIGATION });
  const layoutCinfigs = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.LAYOUTTYPECONFIG,
    deviceLayout: 'general',
  });
  const location = useLocation();
  const { casinoType } = useGeneralCasinoType();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const restoreUserSession = () => {
      try {
        dispatch(validateUserSession());
      } catch (error) {
        getError.message(error);
      }
    };

    if (validateSession) {
      restoreUserSession();
    }
  }, [dispatch]);

  if (location.pathname.includes('close-game') || location.pathname.includes('sitemap')) return null;
  if (location.pathname.includes('launch')) {
    return null;
  }

  const objStr = localStorage.getItem('scrollToCell');
  const showMobileCasinoLoader = casinoType !== CasinoLobbyType.Default && objStr !== null && isMobile;
  const preloaderClassName = `w-100 head head--${!navigationConfig?.topNavInitialCollapsed ? 'preloader' : 'hide'} ${
    !navigationConfig?.topNavInitialCollapsed && 'head__back'
  }`;

  const brandLogoStyle = isMobile
    ? layoutCinfigs?.header?.wrapper === 'layout_1' && isMobile
      ? { justifyContent: 'center' }
      : { marginLeft: '40px' }
    : {};
  return (
    <AppThemeProvider>
      <div className={navigationConfig?.topNav ? preloaderClassName : ''}>
        {/* <div className={showMobileCasinoLoader ? casinoType : 'top-navbar__wrapper'} /> */}
        <Flex.AS className="mnav">
          <div className={`${cssGlobalNS}-container`}>
            <Flex.AC className={`wh-100 head-inner`} style={brandLogoStyle}>
              <NavBrand />
            </Flex.AC>
          </div>
        </Flex.AS>
      </div>
      {showMobileCasinoLoader ? (
        <div className={casinoType} style={{ minHeight: '100vh' }}></div>
      ) : (
        <Flex.Center className="preloader__wrapper">
          <Flex.ColCenter className="preloader__inner">
            <DataLoader componentLocation="preloader" />
          </Flex.ColCenter>
        </Flex.Center>
      )}
    </AppThemeProvider>
  );
};

export default memo(Preloader);
