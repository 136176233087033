import React, { memo, useCallback, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { AppLogo } from 'components/shared';
import useAppHomePage from 'hooks/common/useAppHomePage';
import { setAppBackdrop } from 'shared/common/features/appBackdrop/slice/appBackdrop.slice';
import {
  selectBottomTabBar,
  setMyBetsVisibilityPlatform,
} from 'shared/common/features/bottomTabBar/slice/bottomTabBar.slice';
import {
  highlightNewFeatureActions,
  selectHighlightNewFeature,
} from 'shared/common/features/highlightNewFeature/slice/highlightNewFeature.slice';
import {
  navigateToCasinoIntegration,
  navigateToIntegration,
} from 'shared/common/features/integrations/utils/integrations.utils';
import { useAppDispatch, useAppSelector } from 'store';
import { useShowTopNavOnClick } from '../../hooks/useShowTopNavOnClick';
import { useShowTopNavTouchMove } from '../../hooks/useShowTopNavTouchMove';
import { selectAppHeader } from '../appHeaderContainer/slice/appHeader.slice';

interface NavBrandProps {
  className?: string;
  logoClassName?: string;
}

const NavBrand: React.FC<NavBrandProps> = ({ className, logoClassName }) => {
  const brandRef = useRef<HTMLSpanElement | null>(null);
  const { homeRoute } = useAppHomePage();
  const isMyBetsVisible = useAppSelector(selectBottomTabBar.isMyBetsVisible);
  const topNavHeaderStatus = useAppSelector(selectAppHeader.topNavHeaderStatus);

  const activeComponent = useAppSelector(selectHighlightNewFeature.activeComponent);

  const dispatch = useAppDispatch();
  useEffect(() => {
    activeComponent === 'collapsedHeader' &&
      brandRef?.current &&
      setTimeout(() => {
        dispatch(highlightNewFeatureActions.setActiveElement(brandRef.current));
      }, 1000);
  }, [dispatch, brandRef, activeComponent, topNavHeaderStatus]);

  useShowTopNavTouchMove({ ref: brandRef, disable: isMyBetsVisible });
  const { handleShowHideTopNav, topNavInitialCollapsed } = useShowTopNavOnClick();

  const sportsBookNavigate = () => {
    navigateToIntegration({ fullPath: homeRoute });
    navigateToCasinoIntegration({ fullPath: homeRoute });
  };

  const closeMyBets = useCallback(() => {
    /**Only if mybets modal is open */
    if (isMyBetsVisible) {
      dispatch(setMyBetsVisibilityPlatform(false));
      dispatch(setAppBackdrop(false));
    }
    // homeRoute === window.location.pathname &&
    if (topNavInitialCollapsed) {
      handleShowHideTopNav();
    } else {
      sportsBookNavigate();
    }
  }, [dispatch, isMyBetsVisible, homeRoute, topNavInitialCollapsed, topNavHeaderStatus]);

  const spanClass = clsx('d-flex-center brand', className);

  return (
    <>
      {topNavInitialCollapsed ? (
        <span
          ref={brandRef}
          className={spanClass}
          // to={!topNavInitialCollapsed || homeRoute != window.location.pathname ? homeRoute : '#'}
          onClick={closeMyBets}
        >
          <AppLogo id="app-logo-navbar" className={logoClassName} />
        </span>
      ) : (
        <Link onClick={sportsBookNavigate} className={spanClass} to={homeRoute}>
          <AppLogo id="app-logo-navbar" className={logoClassName} />
        </Link>
      )}
    </>
  );
};

export default memo(NavBrand);
