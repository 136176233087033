import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { cdnUrl } from './PNGCDN.types';

interface PNGCDNProps extends HTMLAttributes<HTMLImageElement> {
  icon: string;
  size?: number;
  altText?: string;
  className?: string;
  onLoad?: () => void;
  onError?: (error) => void;
}

const PNGCDN: React.FC<PNGCDNProps> = ({ icon, size, altText, className, onLoad, onError, ...props }) => {
  const iconSrc = cdnUrl + '/' + icon;

  const altTextProp = altText ? { alt: altText } : undefined;

  const imgClass = clsx(className);

  return (
    <img
      className={imgClass}
      src={iconSrc}
      width={size}
      height={size}
      onLoad={onLoad}
      onError={onError}
      {...altTextProp}
      {...props}
    />
  );
};

export default PNGCDN;
