import React from 'react';
import { cssGlobalNS } from 'utils/common/types/css.types';
import { HighlightFeatureBackdrop, HighlightFeatureWrapper } from './StyleComponents';

type Props = {
  elementDimensions: {
    width: number;
    height: number;
    left: number;
    top: number;
  };
  handleRef: (node: Element | null) => void;

  isActive: boolean;
  children?: React.ReactNode;
};
export const HighlightNewFeatureWrapper: React.FC<Props> = ({ elementDimensions, isActive, handleRef, children }) => {
  return (
    <HighlightFeatureBackdrop ref={(node) => handleRef(node)}>
      <HighlightFeatureWrapper
        {...elementDimensions}
        className={`${isActive ? 'is-active' : ''} ${cssGlobalNS}-highlight-feature`}
      >
        {children}
      </HighlightFeatureWrapper>
    </HighlightFeatureBackdrop>
  );
};
