import { store } from 'store';
import { ExecuteInterceptorsType } from './types';

const Interceptors = {
  executeInterceptors: (params: ExecuteInterceptorsType) => async (dispatch) => {
    const { interceptors, continueExec, finalCallback } = params;
    // iterate over interceptions and execute all
    try {
      await Promise.all(
        interceptors.map((interceptor) => {
          return dispatch(
            interceptor.func({
              ...interceptor.params,
              continueExec: continueExec,
              callback: (isSuccessful: boolean) =>
                store.dispatch(
                  Interceptors.executeInterceptors({ interceptors, continueExec: isSuccessful, finalCallback }),
                ),
            }),
          );
        }),
      );
      return finalCallback();
    } catch (error) {
      // console.log('Error: ', error);
    }
  },
};

export default Interceptors;
