import React, { HTMLAttributes, memo } from 'react';
import clsx from 'clsx';
import { CSSProp } from 'styled-components';
import { StyledCTATitle } from './CTATitle.styled';
interface Props extends HTMLAttributes<HTMLDivElement> {
  title: string;
  className?: string;
  color?: string;
  styledCss?: CSSProp;
}

const CTATitle: React.FC<Props> = ({ title, className, styledCss, color, ...rest }) => {
  const textClass = clsx(className);

  return (
    <StyledCTATitle className={textClass} {...rest} $color={color} $styledCss={styledCss}>
      {title}
    </StyledCTATitle>
  );
};

export default memo(CTATitle);
