import React from 'react';
// import clsx from 'clsx';
import PrizeDropIcon from './PrizeDropIcon';
import { prizeDropCSSNS } from '../prizeDropUICSS.types';
// import { SCPrizeDrop } from '../styled';
type Props = {
  icon: string;
  text: string;
  title: string;
};

const PrizeDropIsWin: React.FC<Props> = ({ title, icon, text }) => {
  const classProp = {
    class: `${prizeDropCSSNS}-inner-shadow d-flex-col-jc no-scrollbars overflow-y-auto overflow-x-hidden`,
  };
  return (
    <egtd-przd-modal-win-content {...classProp}>
      {icon && <PrizeDropIcon iconResource={icon} />}
      <div className={`${prizeDropCSSNS}-modal-win-text`}>{text}</div>
      <div className={`${prizeDropCSSNS}-modal-win-gift`}>{title}</div>
    </egtd-przd-modal-win-content>
  );
};

export default PrizeDropIsWin;
