import React from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { classNameUtils } from '../utils/className.utils';

type Props = {
  children?: React.ReactNode;
  className?: string;
  show?: boolean;
};

const NotificationPopupModal: React.FC<Props> = ({ children, className, show }) => {
  const classProp = clsx(classNameUtils.modal, className);

  return (
    <motion.div initial={animation.initial} animate={show ? animation.animate : {}} className={classProp}>
      {children}
    </motion.div>
  );
};

const animation = {
  initial: {
    // scale: 0,
    y: 200,
  },
  animate: {
    // scale: [0, 1.2, 1],
    y: 1,
    transition: {
      type: 'spring',
      damping: 17,
      mass: 1,
      stiffness: 107,
    },
  },
};

export default NotificationPopupModal;
