import React from 'react';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';

interface CardTitleProps {
  text: string;
  color: string;
}

const CardTitle: React.FC<CardTitleProps> = ({ text, color }) => {
  return (
    <div className={`${cssGlobalNS}-c-prm-lobby-card__title text-truncate`} style={{ color: color }}>
      {text}
    </div>
  );
};

export default CardTitle;
