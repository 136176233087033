import { useState } from 'react';

export const useCreateBonusVideoStates = ({
  callBack,
}): {
  videoState: {
    initialLoaded: boolean;
    initialEnded: boolean;
    lastEnded: boolean;
    initialPlay: boolean;
  };
  handleInitialVideoIsLoaded: () => void;
  triggerInitialVideoEnded: () => void;
  handleLastVideoIsLoaded: () => void;
  triggerLastVideoEnded: () => void;
  handleInitialVideoPlay?: () => void;
} => {
  const [videoState, setVideoState] = useState({
    initialLoaded: false,
    initialPlay: false,
    initialEnded: false,
    lastEnded: false,
  });

  const handleInitialVideoIsLoaded = () => {
    setVideoState((prevState) => ({
      ...prevState,
      initialLoaded: true,
    }));
  };
  const triggerInitialVideoEnded = () => {
    setVideoState((prevState) => ({
      ...prevState,
      initialEnded: true,
    }));
  };
  const handleInitialVideoPlay = () => {
    setVideoState((prevState) => ({
      ...prevState,
      initialEnded: true,
    }));
  };
  const handleLastVideoIsLoaded = () => {
    callBack && callBack();
  };
  const triggerLastVideoEnded = () => {
    setVideoState((prevState) => ({
      ...prevState,
      lastEnded: true,
    }));
  };

  return {
    videoState,
    handleInitialVideoIsLoaded,
    triggerInitialVideoEnded,
    handleLastVideoIsLoaded,
    triggerLastVideoEnded,
    handleInitialVideoPlay,
  };
};
