import config from 'utils/config';

export const cdnUrl = config.CDN_URL;

export enum cdnPaths {
  BRAND = '/common-content/brand/',
  GENERAL = '/common-content/general-ui/',
  HELP = '/common-content/help/icons/',
  LIVE_GAMES = '/common-content/live-games-icons/',
  PRIZE_DROP = '/common-content/prize-drop/',
  PROMOTIONS = '/common-content/promotion-icons/',
  SOCIALS = '/common-content/social-login-icons/',
  SPORT_BONUSES = '/sport-content/sport-bonuses/',
  RETAIL = '/retail-content/ui-buttons/',
}
