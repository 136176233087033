import React from 'react';
import { Flex } from 'components/shared/layout';
import { ctaUICSSNS } from './ctaUI.types';

interface AccordionRowProps {
  /** Row left text */
  leftText: string;
  /** Row right text */
  rightText: string;
}

const AccordionRow: React.FC<AccordionRowProps> = ({ leftText, rightText }) => {
  return (
    <Flex className={`${ctaUICSSNS.accordion}__row`}>
      <div className="col-half ma-text--accent">{leftText}</div>
      <div className="col-half">{rightText}</div>
    </Flex>
  );
};

export default AccordionRow;
