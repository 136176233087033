import { RefObject, useEffect } from 'react';
import { Subvertical, Vertical } from 'shared/common/features/navigationMenu/types/navigationMenu.types';
import { isEmpty } from 'utils/common/helpersCommon';

const alignItems = (items, align) => items.every((item) => item.align === align);
const alignSomeItems = (items, align) => items.some((item) => item.align === align);

type Props = {
  subVerticals?: (Vertical | Subvertical)[];
  parentRef?: RefObject<HTMLDivElement>;
};
export const useClassRapperByAlign = ({ parentRef, subVerticals }: Props) => {
  useEffect(() => {
    if (parentRef?.current && !isEmpty(subVerticals)) {
      if (alignItems(subVerticals, 'center')) {
        parentRef.current.classList.add('head-inner--center');
      } else if (alignSomeItems(subVerticals, 'center') && alignSomeItems(subVerticals, 'left')) {
        parentRef.current.classList.add('head-inner--center-l');
      } else if (alignItems(subVerticals, 'left')) {
        parentRef.current.classList.add('head-inner--left');
      } else if (alignItems(subVerticals, 'right')) {
        parentRef.current.classList.add('head-inner--right');
      }
    } else if (parentRef?.current && isEmpty(subVerticals)) {
      parentRef.current.classList.add('head-inner--center');
    }
  }, [parentRef, subVerticals]);
};
