import React from 'react';
import { StyledPyroAfter, StyledPyroBefore } from './Fireworks.styled';

interface FireworksProps {
  isVisible: boolean;
}

const Fireworks: React.FC<FireworksProps> = ({ isVisible }) => {
  if (!isVisible) return null;

  return (
    <div className="grid-start-1x1">
      <StyledPyroBefore />
      <StyledPyroAfter />
    </div>
  );
};

export default Fireworks;
