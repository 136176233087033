import styled from 'styled-components';

const StyledWrapper = styled.div<{ $isData: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  ${({ $isData }) => ($isData ? `overflow: hidden; .cell-tags__triangle {display: none;}` : ``)}
`;

export default StyledWrapper;
