import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useIsPokerRoute } from 'pages/playtechPoker/hooks/useIsPokerRoute';
import { useIsSvaraRoute } from 'pages/svaraAppLogin/hooks/useIsSvaraRoute';
import { selectRootModal, showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum, ModalTypes } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { GAME_LAUNCH_PREFIX_PLATFORM } from 'utils/config';
import useActiveNotification from './useActiveNotification';
import { NotificationPopUpTypes } from '../notificationUtils';

const modalTypesMap: Record<
  'AnimatedPopup' | 'StandartPopup' | 'BackgroundPopup' | 'SliderPopup' | 'GamePopup',
  ModalTypes
> = {
  SliderPopup: 'POP_UP_SLIDER_NOTIFICATION',
  StandartPopup: 'POP_UP_NOTIFICATION',
  AnimatedPopup: 'POP_UP_GAMIFICATION',
  BackgroundPopup: 'POP_UP_BG_NOTIFICATION',
  GamePopup: 'CAMPAIGN_GAMES_POPUP',
};

const useShowActiveNotification = () => {
  const currModalType = useAppSelector(selectRootModal.type);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { activeNotifiction, popUpSliderNotifications, activePriorityPopUp, sliderIsDone } = useActiveNotification();
  const isPokerRoute = useIsPokerRoute();
  const isSvaraRoute = useIsSvaraRoute();

  useEffect(() => {
    const isCasinoGameRoute = pathname.includes(GAME_LAUNCH_PREFIX_PLATFORM);

    if (isCasinoGameRoute || isPokerRoute || isSvaraRoute) return;

    if (activeNotifiction && currModalType === null && activePriorityPopUp === NotificationPopUpTypes.single) {
      const { type = 'StandartPopup' } = activeNotifiction.style || {};
      const modalType = modalTypesMap[type];
      dispatch(
        showRootModal({
          modalType,
          modalProps: { notification: activeNotifiction },
          modalPriority: ModalPriorityEnum.MEDIUM,
          modalId: activeNotifiction.id,
        }),
      );
    } else if (
      !isEmpty(popUpSliderNotifications) &&
      currModalType === null &&
      activePriorityPopUp === NotificationPopUpTypes.slider &&
      !sliderIsDone
    ) {
      const modalType = modalTypesMap['SliderPopup'];

      dispatch(
        showRootModal({
          modalType,
          modalProps: { notification: popUpSliderNotifications },
          modalPriority: ModalPriorityEnum.MEDIUM,
          modalId: 'slider',
        }),
      );
    }
  }, [pathname, currModalType, activeNotifiction, popUpSliderNotifications, dispatch, activePriorityPopUp]);
};

export default useShowActiveNotification;
