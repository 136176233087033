import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { handleScrollMoveWith } from 'components/shared/ScrollContainer';
import { footerThunks, selectFooter } from 'shared/common/features/appFooter/slice/appFooter.slice';
import { selectLanguage } from 'shared/common/features/settings/slice/settings.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { PromoFeedUI } from '../components';

interface PromosFeedProps {
  apiUri: string;
  containerName?: string;
}

const scrollItemWidth = 350;
const itemsToScroll = 2;
const { moveWith } = handleScrollMoveWith({ itemsToScroll, itemWidth: scrollItemWidth });

const PromosFeed: React.FC<PromosFeedProps> = ({ apiUri, containerName }) => {
  const language = useAppSelector(selectLanguage);
  const isAppFooterVisible = useAppSelector(selectFooter.isVisible);
  const promotions = useAppSelector(selectFooter.promotions);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (apiUri) {
      dispatch(footerThunks.fetchFooterPromotions({ apiUri, language }));
    }
  }, [apiUri, language, dispatch]);

  if (isEmpty(promotions) || !isAppFooterVisible) return null;

  return (
    <PromoFeedUI.Wrapper
      title={containerName || t('Promotions.Footer.title')}
      cellWidth={scrollItemWidth}
      moveWith={moveWith}
    >
      {promotions.map((promotion) => (
        <PromoFeedUI.Item
          key={promotion.id}
          title={promotion.title}
          subTitle={promotion.subTitle}
          content={promotion.description}
          alias={promotion.alias}
          bg={promotion.resource}
          category={promotion.categoryAlias}
          type={promotion?.type}
        />
      ))}
    </PromoFeedUI.Wrapper>
  );
};

export default memo(PromosFeed);
