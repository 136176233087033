import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';
import {
  GridLayouts,
  GridRowType,
  GridType,
} from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import { CategoryQueryType, RequestStatuses } from 'modules/casino/shared/constants';
import { Casino } from 'modules/casino/shared/types';
import { isValidCategoryParams } from 'modules/casino/shared/utils/casinoUtils';
import axiosInstance from 'modules/casino/shared/utils/common/axios-instance';
import { getOSType, sortByProperty } from 'modules/casino/shared/utils/common/helpersCommon';
import { createAbortThunk } from 'modules/casino/store/thunkCreators';
import { RootState } from 'store/rootReducer';
import config from 'utils/config';
import { CategorySubTypes } from '../../../container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { CategoryTypes } from '../types/casinoProviderCategoryTypes';
import { providerLobbyItemsFilterConditions } from '../utils/casinoProviderCategoryUtils';

const initialState: CategoryTypes.CategoryState = {
  lobbyItems: {},
  providersCategoryLoadingState: {},
};

export const providersThunks: CategoryTypes.ProvidersCategoryThunks = {
  fetchLobbyItems: createAbortThunk(
    'providers/fetchLobbyItems',
    async ({ apiUri = '/api/gaming/public/categories/lobby-categories', key, providerId }, { source }) => {
      const fetchURL = `${config.API_URL}${apiUri}&providerLobby=${providerId}`;
      const response = await axiosInstance.get<CategoryTypes.ProviderCategory[]>(fetchURL, {
        data: null,
        headers: { 'X-Platform-OS-type': getOSType() },
        cancelToken: source.token,
      });

      return {
        key,
        content: sortByProperty([...response.data], 'rank'),
      };
    },
  ),
};

const providersCasinoCategory = createSlice({
  name: 'ProvidersCasinoCategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(providersThunks.fetchLobbyItems.pending, (state, action) => {
        const key = action.meta.arg.key;
        state.providersCategoryLoadingState[key] = RequestStatuses.PENDING;
      })
      .addCase(
        providersThunks.fetchLobbyItems.fulfilled,
        (state, action: PayloadAction<CategoryTypes.LobbyItemsResponseType>) => {
          const { key, content } = action.payload;

          const normalizedContent = content
            .filter((category) => isValidCategoryParams({ category, subType: CategorySubTypes.STANDARD }))
            .reduce(
              (acc, item) => {
                const { id, alias, title, isPublic, widgetTypes } = item;

                acc['items'] = {
                  ...acc.items,
                  [id]: {
                    ...item,
                    alias: alias.toLowerCase(),
                  },
                };
                acc['itemIds'].push(id);
                acc['tags'].push({ id, name: title, isPublic } as Casino.ScrollTag);
                acc['refetchLobby'] = !!(widgetTypes && widgetTypes?.includes('THIRD_PARTY'));

                return acc;
              },
              { items: {}, itemIds: [], tags: [], refetchLobby: false } as CategoryTypes.LobbyItemsTypes,
            );

          state.lobbyItems[key] = { ...normalizedContent, ttl: Date.now() };
          state.providersCategoryLoadingState[key] = RequestStatuses.SUCCESS;
        },
      )
      .addCase(providersThunks.fetchLobbyItems.rejected, (state, action) => {
        const key = action.meta.arg.key;
        state.providersCategoryLoadingState[key] = RequestStatuses.ERROR;
      });
  },
});

const selectProviderLobby = createCachedSelector(
  (state: RootState, key: string) => state.casino.providersCasinoCategory.lobbyItems[key],
  (_, key, isAuthenticated) => isAuthenticated,
  (providerLobby, isAuthenticated): CategoryTypes.LobbyItems => {
    const filteredItemIds = providerLobby?.itemIds?.filter((id: string) => {
      const category = providerLobby?.items[id];

      return providerLobbyItemsFilterConditions({
        queryType: category.queryType,
        categoryIsPublic: category.isPublic,
        isAuthenticated,
      });
    });

    return {
      ...providerLobby,
      itemIds: filteredItemIds,
    };
  },
)((_, key, isAuthenticated) => `${key}_${isAuthenticated ? '1' : '0'}`);

const selectProviderLobbyCategory = createCachedSelector(
  (state: RootState, key: string) => state.casino.providersCasinoCategory.lobbyItems[key]?.items,
  (_, key: string, categoryId: string): string => categoryId,
  (providerLobby, categoryId): CategoryTypes.ProviderCategory => {
    if (!providerLobby) {
      return {
        id: '',
        alias: '',
        rank: 0,
        title: '',
        query: '',
        isPublic: true,
        isFilterEnabled: false,
        queryType: CategoryQueryType.CATEGORY,
        buttonLoadMore: false,
        subType: CategorySubTypes.STANDARD,
        layoutConfig: {
          layout: GridType.VERTICAL,
          layoutRows: GridRowType.FOURTH_ROWS,
          layoutGrid: GridLayouts.LAYOUT_1,
        },
      };
    }

    return providerLobby[categoryId];
  },
)((_, categoryId) => categoryId);

const selectProvidersLobbyLoadingState = createCachedSelector(
  (state: RootState, key: string) => state.casino.providersCasinoCategory.providersCategoryLoadingState[key],
  (loadingState): RequestStatuses => {
    if (loadingState) return loadingState;
    return RequestStatuses.INITIAL;
  },
)((_, key) => key);

const selectProviderCategoryIds = createSelector(
  selectProviderLobby,
  (providerLobby): string[] => providerLobby?.itemIds || [],
);

export const providersCategorySelectors = {
  providerLobby: selectProviderLobby,
  providerLobbyCategory: selectProviderLobbyCategory,
  providersLobbyLoadingState: selectProvidersLobbyLoadingState,
  providerCategoryIds: selectProviderCategoryIds,
};

export default providersCasinoCategory.reducer;
