import { useCallback, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { Manipulation, Virtual } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperInstance } from 'components/shared/reactIdSwiper';
import { usePreloadImage } from 'hooks/common/usePreloadImage';
import { gamificationPageThunks } from 'pages/gamificationPage/slice/gamificationPage.slice';
import { NotificationTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationTypes';
import { useAppDispatch } from 'store';
import { Breakpoints } from 'theme/Theme';
import { fitCover } from 'utils/common/cdnConfig';
import { isEmpty } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { SelectBonusFooter } from './components/SelectBonusFooter';
import { SelectBonusHeader } from './components/SelectBonusHeader';
import { SelectBonusLastSlide } from './components/SelectBonusLastSlide';
import { SelectBonusSlide } from './components/SelectBonusSlide';
import { SelectBonusSlideItem } from './components/SelectBonusSlideItem';
import { SelectBonusVideo } from './components/SelectBonusVideo';
import { useButtonsState } from './hooks/useButtonsState';
import { useCreateBonusVideoStates } from './hooks/useCreateBonusVideoStates';
import { useShowTerms } from './hooks/useShowTerms';
import Logo from '../../components/Logo';
import {
  changeArrayElement,
  findIndexByValue,
  getValueByIndex,
  removeItemsObjAfterIndex,
} from '../../utils/createBonus.utils';

const video = {
  mobile: 'video/main.mp4',
  desktop: 'video/main.mp4',
};
const videoEnded = {
  mobile: 'video/end.mp4',
  desktop: 'video/end.mp4',
};

export const SelectBonus = ({
  bonuses,
  notification,
  animationParams,
  animationComplete,
  setAnimationComplete,
  header,
  activeLanguage,
}: {
  bonuses;
  notification;
  animationParams;
  animationComplete?: boolean;
  setAnimationComplete: (isVisible: boolean) => void;
  header?: string;
  activeLanguage?: string | null;
}) => {
  const [instanceSwiper, setInstanceSwiper] = useState<SwiperInstance | null>(null);
  const { style } = notification;
  const dispatch = useAppDispatch();
  const [activeBonus, setActiveBonus] = useState<NotificationTypes.Bonus>();
  const [bonus, setBonus] = useState<NotificationTypes.GenerateLastBonusType>();
  const [activeTerms, setActiveTerms] = useState<NotificationTypes.GenerateLastBonusType>();
  const { offerTerms, getTerms, resetTerms } = useShowTerms();
  const [slides, setSlides] = useState<NotificationTypes.GenerateBonusType[]>([]);
  const stepProgress = useRef({});
  const isMobileUp = useMediaQuery({ query: `(min-width: ${Breakpoints.isMobile}px)` });
  const { btnState, updateBtnState } = useButtonsState();

  const mapBonusSteps = (items) => {
    if (Array.isArray(items[Object.keys(items)[0]].data)) {
      appendSlide(items[Object.keys(items)[0]], slides.length);
      return;
    }
    if (Object.keys(items).length === 1) {
      if (Object.keys(items[Object.keys(items)[0]].data).length === 1) {
        mapBonusSteps(items[Object.keys(items)[0]].data);
      } else {
        appendSlide(items[Object.keys(items)[0]], slides.length);
      }
    }
  };
  const triggerNext = useCallback(
    (prev?: boolean, isLast?: boolean) => {
      resetTerms();
      if (prev && instanceSwiper) {
        removeSelected(instanceSwiper?.activeIndex);
        setBonus(undefined);
        setActiveTerms(undefined);
      }
      if (instanceSwiper) {
        prev ? instanceSwiper.slidePrev() : instanceSwiper.slideNext();
        updateBtnState({ swiper: instanceSwiper, isLast });
      }
    },
    [instanceSwiper, updateBtnState],
  );
  const mapBonusSelectSteps = useCallback(
    (items, type, index, firstInit) => {
      firstInit && (stepProgress.current = { ...stepProgress.current, [type]: items });
      if (Object.keys(items.data).length < 2 && !Array.isArray(items.data)) {
        if (Array.isArray(items.data[Object.keys(items.data)[0]].data)) {
          mapBonusSelectSteps(items.data[Object.keys(items.data)[0]], Object.keys(items)[0], index, false);
          return;
        }
        if (Object.keys(items.data[Object.keys(items.data)[0]].data).length < 2) {
          mapBonusSelectSteps(items.data[Object.keys(items.data)[0]], Object.keys(items)[0], index, false);
        } else {
          !firstInit && (stepProgress.current = { ...stepProgress.current, [type]: items });
          appendSlide(items.data[Object.keys(items.data)[0]].data, index);
          triggerNext(false, false);
        }
      } else {
        appendSlide(items, index);
        triggerNext(false, false);
      }
    },
    [triggerNext, stepProgress],
  );

  useEffect(() => {
    if (Object.keys(bonuses).length === 1 && slides.length === 0) {
      if (isEmpty(stepProgress.current)) {
        mapBonusSteps(bonuses);
      }
    } else {
      slides.length === 0 && appendSlide(bonuses, slides.length);
    }
  }, [bonuses, slides]);

  const removeSelected = (index) => {
    const changeCurrent = !isEmpty(stepProgress.current) && getValueByIndex(stepProgress.current, index);
    changeCurrent &&
      (stepProgress.current = removeItemsObjAfterIndex(
        stepProgress.current,
        findIndexByValue(stepProgress.current, changeCurrent),
      ));
  };

  const {
    videoState,
    handleInitialVideoIsLoaded,
    triggerInitialVideoEnded,
    handleLastVideoIsLoaded,
    triggerLastVideoEnded,
  } = useCreateBonusVideoStates({ callBack: triggerNext });

  const appendSlide = (item, index) => {
    setSlides((prev) => changeArrayElement(prev ? [...JSON.parse(JSON.stringify(prev))] : [], index, item));
  };
  // : NotificationTypes.GenerateBonusType
  const onSelect = useCallback(
    (item, type: string, isLast: boolean, index: number) => {
      const changeCurrent = !isEmpty(stepProgress.current) && getValueByIndex(stepProgress.current, index - 1);
      if (changeCurrent && slides.length > index) {
        stepProgress.current = removeItemsObjAfterIndex(
          stepProgress.current,
          findIndexByValue(stepProgress.current, changeCurrent),
        );
      }
      if (!isLast) {
        mapBonusSelectSteps(item, type, index, true);
      }
      isLast && updateBtnState({ swiper: instanceSwiper, isLast });
    },
    [instanceSwiper, triggerNext, updateBtnState, slides, stepProgress],
  );

  const onSelectBonus = useCallback(
    (selectedBonus: NotificationTypes.GenerateLastBonusType) => {
      updateBtnState({ swiper: instanceSwiper, isLast: selectedBonus.groupConditionId != bonus?.groupConditionId });
      setBonus((prev) => {
        if (prev && selectedBonus.groupConditionId === prev?.groupConditionId) {
          return undefined;
        } else {
          return selectedBonus;
        }
      });
    },
    [bonus, instanceSwiper],
  );

  const onSelectTerms = useCallback(
    (triggerTerms) => {
      resetTerms();
      if (activeTerms?.groupConditionId === triggerTerms.groupConditionId) {
        setActiveTerms(undefined);
        return;
      }
      getTerms(triggerTerms);
      setActiveTerms(triggerTerms);
    },
    [activeTerms],
  );

  const acceptOffer = useCallback(async () => {
    if (bonus?.buttonUrl) {
      const response = await dispatch(
        gamificationPageThunks.bonusesSelection({
          url: bonus.buttonUrl,
          replacedPlayerBonusId: bonus.playerBonusId,
          selectedBonusConditionId: bonus.groupConditionId,
        }),
      );
      if (response.payload) {
        setAnimationComplete(true);
        setActiveBonus(response.payload);
      } else {
        // handle error
      }
    }
  }, [bonus, dispatch]);
  const wheelBG = isMobileUp
    ? `${config.CDN_IMAGE_API}${fitCover(470, 790, 'contain')}/common-content/gamification/${animationParams.folder}/bgr/main.png`
    : `${config.CDN_IMAGE_API}${fitCover(355 * 2, 600 * 2, 'cover')}/common-content/gamification/${animationParams.folder}/bgr/main.png`;
  const wheelEnd = isMobileUp
    ? `${config.CDN_IMAGE_API}${fitCover(470, 790, 'contain')}/common-content/gamification/${animationParams.folder}/bgr/end.png`
    : `${config.CDN_IMAGE_API}${fitCover(355 * 2, 600 * 2, 'cover')}/common-content/gamification/${animationParams.folder}/bgr/end.png`;
  usePreloadImage(wheelBG, undefined, 0);
  usePreloadImage(wheelEnd, undefined, 0);
  // const hideContent = animationComplete && videoState.lastEnded;
  const hideContentInitial = videoState.initialEnded;

  return (
    <InnerWrapper
      className="no-scrollbars"
      $background={hideContentInitial ? (animationComplete && videoState.lastEnded ? wheelEnd : wheelBG) : ''}
    >
      <div style={{ opacity: hideContentInitial ? 1 : 0 }}>
        <Logo animationParams={animationParams} activeLanguage={activeLanguage} />
      </div>

      <SelectWrapperStyle className="wh-100 no-scrollbars" $showTerms={!!offerTerms}>
        {videoState.initialLoaded && !videoState.lastEnded && (
          <SelectBonusHeader bonus={stepProgress.current} header={header} style={style} />
        )}

        {videoState.initialLoaded && (
          <SliderWrapper $showTerms={!!offerTerms} className="no-scrollbars">
            <Swiper
              onSwiper={setInstanceSwiper}
              slidesPerView={1}
              spaceBetween={10}
              initialSlide={0}
              autoHeight
              modules={[Virtual, Manipulation]}
              observer={true}
              allowTouchMove={false}
              touchRatio={0}
              className={'no-scrollbars'}
            >
              {slides.map((item, index) => (
                <SwiperSlide key={index} className="no-scrollbars">
                  <SelectBonusSlide
                    item={item}
                    triggerForceShowItems={videoState.initialEnded}
                    isLast={Array.isArray(item?.data)}
                    renderItems={({ element, idx, options }) => (
                      <SelectBonusSlideItem
                        key={idx}
                        item={options[element]}
                        type={element}
                        isActiveStep={stepProgress.current[element]}
                        onSelectStep={onSelect}
                        isLast={Array.isArray(options)}
                        animationParams={animationParams}
                        categoryIndex={index + 1}
                        onSelectBonus={onSelectBonus}
                        onSelectTerms={onSelectTerms}
                        activeBonus={bonus}
                        offerTerms={offerTerms}
                        activeTerms={activeTerms}
                        style={style.awardsColors}
                      />
                    )}
                  />
                </SwiperSlide>
              ))}
              <SwiperSlide key={'last'}>
                <SelectBonusLastSlide
                  animationParams={animationParams}
                  bonus={activeBonus}
                  notification={notification}
                />
              </SwiperSlide>
            </Swiper>
          </SliderWrapper>
        )}
        {!videoState.lastEnded && (
          <SelectBonusFooter
            goBack={triggerNext}
            showPrev={btnState.prev}
            isLastDisabled={btnState.disabledNext}
            acceptOffer={acceptOffer}
            folder={animationParams.folder}
            buttonText={style?.buttons}
            style={style}
          />
        )}

        <SelectBonusVideo
          show={!videoState.initialEnded}
          video={video}
          handleVideoIsLoaded={handleInitialVideoIsLoaded}
          triggerVideoEnded={triggerInitialVideoEnded}
          folder={animationParams.folder}
        />
        <SelectBonusVideo
          show={animationComplete && !videoState.lastEnded}
          video={videoEnded}
          handleVideoIsLoaded={handleLastVideoIsLoaded}
          triggerVideoEnded={triggerLastVideoEnded}
          folder={animationParams.folder}
        />
      </SelectWrapperStyle>
    </InnerWrapper>
  );
};

const InnerWrapper = styled.div<{ $background?: string }>`
  display: flex;
  gap: 25px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-size: contain;
  overflow: scroll;
  background-image: ${({ $background }) => `url(${$background})`};
  padding: 10px;
  flex: 1;
  @media (min-width: ${Breakpoints.isMobile}px) {
    gap: 30px;
    padding: 20px;
  }
`;
const SliderWrapper = styled.div<{ $showTerms?: boolean }>`
  flex: 1 1 auto;
  ${({ $showTerms }) => ($showTerms ? '' : 'overflow-y:auto')};
  overflow-x: ${({ $showTerms }) => ($showTerms ? 'clip' : 'hidden')};
`;
const SelectWrapperStyle = styled.div<{ $showTerms?: boolean }>`
  display: flex;

  overflow: ${({ $showTerms }) => ($showTerms ? 'scroll' : 'hidden')};
  flex-direction: column;
  gap: 25px;

  @media (min-width: ${Breakpoints.isDesktop}px) {
    gap: 30px;
  }
  @media (min-width: ${Breakpoints.isMobile}px) and (max-width: 1024px) {
    flex: 1;
  }
  .swiper {
    width: 100%;
    height: 100% !important;
    flex: 0 1 100%;
    overflow-x: visible;
    ${({ $showTerms }) =>
      $showTerms
        ? `
    overflow-y: visible;
  `
        : `overflow-y: clip;`}

    .swiper-slide {
      height: 100% !important;
      overflow: auto;
    }
  }
  .swiper-autoheight .swiper-wrapper {
    height: 100% !important;
    /* height: 307px !important;
    @media (min-width: ${Breakpoints.isDesktop}px) {
      height: 422px !important;
    } */
  }
`;
