import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import config from 'utils/config';
import { RetailVoucher, RetailVoucherInfo, VoucherTypes, VoucherThunk } from '../../modules/ssbt/types/ssbt.types';

const initialState: VoucherTypes = {
  retailVoucherInfo: null,
  retailVoucherInfoStatus: 'idle',
  retailVoucher: null,
  retailVoucherStatus: 'idle',
  depositWithVoucherStatus: 'idle',
  voucherCode: '',
  isVoucherSelect: false,
  voucherCashier: null,
  voucherCashierStatus: 'idle',
  voucherInfoCashier: null,
  voucherInfoCashierStatus: 'idle',
  payoutVoucherStatus: 'idle',
  cancelVoucherStatus: 'idle',
};
export const voucherThunk: VoucherThunk = {
  createVoucher: createAbortThunk(`terminal/create`, async () => {
    const url = `${config.API_URL}/api/pretail/agent/v2/voucher/issue`;
    const response = await axiosInstance.post(url, {});
    return response.data;
  }),
  getVoucherInfo: createAbortThunk(`terminal/getVoucherInfo`, async ({ voucherCode }) => {
    const url = `${config.API_URL}/api/pretail/v1/voucher/get-info?voucherCode=${voucherCode}`;
    const response = await axiosInstance.get(url);
    return response.data;
  }),
  getVoucherInfoCashier: createAbortThunk(`cashier/getVoucherInfo`, async ({ voucherCode }) => {
    const url = `${config.API_URL}/api/pretail/agent/v2/voucher/get-info?voucherCode=${voucherCode}`;
    const response = await axiosInstance.get(url);
    return response.data;
  }),
  depositWithVoucher: createAbortThunk(`terminal/depositWithVoucher`, async ({ code }) => {
    const url = `${config.API_URL}/api/pretail/v1/voucher/deposit`;
    const response = await axiosInstance.post(url, { code: code });
    return response.data;
  }),
  payoutVoucher: createAbortThunk(`cashier/payout`, async ({ code }) => {
    const url = `${config.API_URL}/api/pretail/agent/v2/voucher/payout`;
    const response = await axiosInstance.post(url, { code: code });
    return response.data;
  }),
  cancelVoucher: createAbortThunk(`cashier/cancel`, async ({ code }) => {
    const url = `${config.API_URL}/api/pretail/agent/v2/voucher/cancel`;
    const response = await axiosInstance.post(url, { code: code });
    return response.data;
  }),
  createVoucherCashier: createAbortThunk(`cashier/voucher`, async ({ amount }) => {
    const url = `${config.API_URL}/api/pretail/agent/v2/voucher/issue`;
    const response = await axiosInstance.post(url, { amount: amount });
    return response.data;
  }),
};
const voucherSlice = createSlice({
  name: 'voucherSlice',
  initialState,
  reducers: {
    setRetailVoucherInfoStatus(state, action) {
      state.retailVoucherInfoStatus = action.payload;
    },
    setVoucherCode(state, action) {
      state.voucherCode = action.payload;
    },
    setIsVoucherSelect(state, action) {
      state.isVoucherSelect = action.payload;
    },
    resetCashierVoucherData(state) {
      state.voucherCashier = null;
      state.voucherCashierStatus = 'idle';
    },
    resetVoucherInfoCashier(state) {
      state.voucherInfoCashier = null;
      state.voucherInfoCashierStatus = 'idle';
    },
    resetCashierPayoutVoucherStatus(state) {
      state.payoutVoucherStatus = 'idle';
    },
    resetCashierCancelVoucherStatus(state) {
      state.cancelVoucherStatus = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(voucherThunk.createVoucher.fulfilled, (state, action: PayloadAction<RetailVoucherInfo>) => {
      state.retailVoucherInfo = action.payload;
      state.retailVoucherInfoStatus = 'succeeded';
    });
    builder.addCase(voucherThunk.createVoucher.pending, (state) => {
      state.retailVoucherInfoStatus = 'pending';
    });
    builder.addCase(voucherThunk.createVoucher.rejected, (state) => {
      state.retailVoucherInfoStatus = 'failure';
      state.retailVoucherInfo = null;
    });
    builder.addCase(voucherThunk.getVoucherInfo.fulfilled, (state, action: PayloadAction<RetailVoucher>) => {
      state.retailVoucher = action.payload;
      state.retailVoucherStatus = 'succeeded';
    });
    builder.addCase(voucherThunk.getVoucherInfo.pending, (state) => {
      state.retailVoucherStatus = 'pending';
    });
    builder.addCase(voucherThunk.getVoucherInfo.rejected, (state) => {
      state.retailVoucherStatus = 'failure';
      state.retailVoucher = null;
    });
    builder.addCase(voucherThunk.depositWithVoucher.fulfilled, (state) => {
      state.depositWithVoucherStatus = 'succeeded';
    });
    builder.addCase(voucherThunk.depositWithVoucher.pending, (state) => {
      state.depositWithVoucherStatus = 'pending';
    });
    builder.addCase(voucherThunk.depositWithVoucher.rejected, (state) => {
      state.depositWithVoucherStatus = 'failure';
    });
    builder.addCase(voucherThunk.createVoucherCashier.fulfilled, (state, action) => {
      state.voucherCashier = action.payload;
      state.voucherCashierStatus = 'succeeded';
    });
    builder.addCase(voucherThunk.createVoucherCashier.pending, (state) => {
      state.voucherCashierStatus = 'pending';
    });
    builder.addCase(voucherThunk.createVoucherCashier.rejected, (state) => {
      state.voucherCashier = null;
      state.voucherCashierStatus = 'failure';
    });
    builder.addCase(voucherThunk.getVoucherInfoCashier.fulfilled, (state, action) => {
      state.voucherInfoCashier = action.payload;
      state.voucherInfoCashierStatus = 'succeeded';
    });
    builder.addCase(voucherThunk.getVoucherInfoCashier.pending, (state) => {
      state.voucherInfoCashierStatus = 'pending';
    });
    builder.addCase(voucherThunk.getVoucherInfoCashier.rejected, (state) => {
      state.voucherInfoCashier = null;
      state.voucherInfoCashierStatus = 'failure';
    });
    builder.addCase(voucherThunk.payoutVoucher.fulfilled, (state) => {
      state.payoutVoucherStatus = 'succeeded';
    });
    builder.addCase(voucherThunk.payoutVoucher.pending, (state) => {
      state.payoutVoucherStatus = 'pending';
    });
    builder.addCase(voucherThunk.payoutVoucher.rejected, (state) => {
      state.payoutVoucherStatus = 'failure';
    });
    builder.addCase(voucherThunk.cancelVoucher.fulfilled, (state) => {
      state.cancelVoucherStatus = 'succeeded';
    });
    builder.addCase(voucherThunk.cancelVoucher.pending, (state) => {
      state.cancelVoucherStatus = 'pending';
    });
    builder.addCase(voucherThunk.cancelVoucher.rejected, (state) => {
      state.cancelVoucherStatus = 'failure';
    });
  },
});
export default voucherSlice.reducer;
export const {
  setRetailVoucherInfoStatus,
  setVoucherCode,
  setIsVoucherSelect,
  resetCashierVoucherData,
  resetVoucherInfoCashier,
  resetCashierCancelVoucherStatus,
  resetCashierPayoutVoucherStatus,
} = voucherSlice.actions;

export const selectVoucherData = {
  retailVoucherInfo: (state: RootState): RetailVoucherInfo | null => state.retail.voucher.retailVoucherInfo,
  retailVoucherInfoStatus: (state: RootState) => state.retail.voucher.retailVoucherInfoStatus,
  retailVoucher: (state: RootState): RetailVoucher | null => state.retail.voucher.retailVoucher,
  retailVoucherStatus: (state: RootState) => state.retail.voucher.retailVoucherStatus,
  depositWithVoucherStatus: (state: RootState) => state.retail.voucher.depositWithVoucherStatus,
  voucherCode: (state: RootState) => state.retail.voucher.voucherCode,
  isVoucherSelect: (state: RootState) => state.retail.voucher.isVoucherSelect,
  voucherCashier: (state: RootState) => state.retail.voucher.voucherCashier,
  voucherCashierStatus: (state: RootState) => state.retail.voucher.voucherCashierStatus,
  voucherInfoCashier: (state: RootState) => state.retail.voucher.voucherInfoCashier,
  voucherInfoCashierStatus: (state: RootState) => state.retail.voucher.voucherInfoCashierStatus,
  payoutVoucherStatus: (state: RootState) => state.retail.voucher.payoutVoucherStatus,
  cancelVoucherStatus: (state: RootState) => state.retail.voucher.cancelVoucherStatus,
};
