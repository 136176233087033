import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { loginThunks } from 'pages/auth/login/slice/login.slice';
import { AccountThunks } from 'pages/myAccount/tabs/account/slice/account.slice';
import { showRootModal, hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { Social, SocialModal } from '../types/formSocial.types';

export const SocialLinkAccountThunks: Social.Thunks = {
  linkAccount: createAbortThunk('socialMedia/linkAccount', async (params, { dispatch, rejectWithValue }) => {
    try {
      const url = `${config.API_URL}/api/ews-crm/player/player-social-media/link-account`;
      await axiosInstance.post(url, {
        userId: params.userId,
        accessToken: params.accessToken,
        name: params.name,
      });
      dispatch(
        showRootModal({
          modalType: SocialModal.TYPE,
          modalId: SocialModal.ID,
          modalPriority: ModalPriorityEnum.HIGH,
          modalProps: { modal: SocialModal.SUCCESS, type: params.name, name: params.email },
        }),
      );
      return params.name;
    } catch (err) {
      dispatch(
        showRootModal({
          modalType: SocialModal.TYPE,
          modalId: SocialModal.ID,
          modalPriority: ModalPriorityEnum.HIGH,
          modalProps: {
            modal: SocialModal.ERROR,
            message: (err as AxiosError<string | undefined>)?.response?.data,
            onCancel: () => dispatch(hideRootModal()),
          },
        }),
      );
      return rejectWithValue(getError.responseData(err));
    }
  }),
  unlinkAccount: createAbortThunk('socialMedia/unlinkAccount', async (params, { dispatch, rejectWithValue }) => {
    try {
      const url = `${config.API_URL}/api/ews-crm/player/player-social-media/unlink-account`;
      await axiosInstance.post(url, params.name, { headers: { 'Content-Type': 'application/json' } });
      return params.name;
    } catch (err) {
      dispatch(
        showRootModal({
          modalType: SocialModal.TYPE,
          modalId: SocialModal.ID,
          modalPriority: ModalPriorityEnum.HIGH,
          modalProps: {
            modal: SocialModal.ERROR,
            message: (err as AxiosError<string | undefined>)?.response?.data,
            onCancel: () => dispatch(hideRootModal()),
          },
        }),
      );
      return rejectWithValue(getError.responseData(err));
    }
  }),
};

export const { linkAccount, unlinkAccount } = SocialLinkAccountThunks;

const initialState: Social.SliceTypes = {
  data: null,
  error: null,
  isLoading: false,
};

const socialMediaSlice = createSlice({
  name: 'socialMedia',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(linkAccount.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(linkAccount.rejected, (state) => {
        state.error = getError.default();
        state.isLoading = false;
      })
      .addCase(linkAccount.fulfilled, (state, action: PayloadAction<string>) => {
        state.error = null;
        state.isLoading = false;
        if (state.data) {
          state.data = state.data.map((media) =>
            media.type !== action.payload ? media : { ...media, isLinked: true },
          );
        }
      });
    builder
      .addCase(unlinkAccount.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(unlinkAccount.rejected, (state) => {
        state.error = getError.default();
        state.isLoading = false;
      })
      .addCase(unlinkAccount.fulfilled, (state, action: PayloadAction<string>) => {
        state.error = null;
        state.isLoading = false;
        if (state.data) {
          state.data = state.data.map((media) =>
            media.type !== action.payload ? media : { ...media, isLinked: false },
          );
        }
      });
    builder
      .addCase(AccountThunks.fetchMyAccountFormFields.fulfilled, (state, action) => {
        if (action.payload.socialMedia) {
          state.data = action.payload.socialMedia as Social.Button[];
        }
        state.isLoading = false;
      })
      .addCase(AccountThunks.fetchMyAccountFormFields.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        AccountThunks.fetchMyAccountFormFields.rejected,
        (state, action: PayloadAction<string | { rejectValue: string } | null | undefined>) => {
          state.isLoading = false;
          state.error = action.payload as string;
        },
      );
    builder.addCase(loginThunks.logout.fulfilled, (state) => {
      state.data = initialState.data;
      state.error = initialState.error;
      state.isLoading = initialState.isLoading;
    });
  },
});

export default socialMediaSlice.reducer;

export const selectSocialMedia = {
  data: (state: RootState): Social.Button[] | null => state.myAccount.socialMedia.data,
  error: (state: RootState): string | null | undefined => state.myAccount.socialMedia.error,
  isLoading: (state: RootState): boolean => state.myAccount.socialMedia.isLoading,
};
