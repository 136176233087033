import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { createAbortThunk, rejectedWrapper } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { FantasyState } from '../types/fantasyScout.types';

export const fetchFantasyScoutToken = createAbortThunk<string, undefined>(
  'fetch/FantasyScoutUserToken',
  async (_, { rejectWithValue }) => {
    const apiUrl = `${config.API_URL}/api/fantasy/v1/token`;
    const response = await axiosInstance.put(`${apiUrl}`);
    try {
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseDataMessage(err));
    }
  },
);

export const deleteFantasyScoutToken = createAbortThunk<string, undefined>(
  'delete/FantasyScoutUserToken',
  async (_, { rejectWithValue }) => {
    const apiUrl = `${config.API_URL}/api/fantasy/v1/token`;
    const response = await axiosInstance.delete(`${apiUrl}`);
    try {
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseDataMessage(err));
    }
  },
);

const initialState: FantasyState = {
  token: '',
  isScriptLoaded: false,
  isViewPortLoaded: false,
  isFantasyUnmounted: false,
};

const fantasyScoutSlice = createSlice({
  name: 'fantasyScoutSlice',
  initialState,
  reducers: {
    setIsScriptLoaded: (state, action: PayloadAction<boolean>) => {
      state.isScriptLoaded = action.payload;
    },
    setIsViewPortLoaded: (state, action: PayloadAction<boolean>) => {
      state.isViewPortLoaded = action.payload;
    },
    setIsFantasyUnmounted: (state, action: PayloadAction<boolean>) => {
      state.isFantasyUnmounted = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchFantasyScoutToken.rejected,
        rejectedWrapper((state) => {
          state.token = '';
        }),
      )
      .addCase(fetchFantasyScoutToken.fulfilled, (state, action) => {
        state.token = action.payload;
      })
      .addCase(deleteFantasyScoutToken.fulfilled, (state) => {
        state.token = '';
      });
  },
});
export const { setIsScriptLoaded, setIsViewPortLoaded, setIsFantasyUnmounted } = fantasyScoutSlice.actions;

export const selectFantasyScout = {
  token: (state: RootState): string => state.fantasyScout.token,
  isScriptLoaded: (state: RootState): boolean => state.fantasyScout.isScriptLoaded,
  isViewPortLoaded: (state: RootState): boolean => state.fantasyScout.isViewPortLoaded,
  isFantasyUnmounted: (state: RootState): boolean => state.fantasyScout.isFantasyUnmounted,
};

export default fantasyScoutSlice.reducer;
