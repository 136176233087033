import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from '../features/general/types/generalSlice.types';

const useHeaderConfig = () => {
  const headerConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.HEADER,
    deviceLayout: 'general',
  });
  return headerConfig || {};
};

export default useHeaderConfig;
