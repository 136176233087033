import { BetBuilderUI } from 'pages/myAccount/tabs/history/sections/sportsHistory/components/BetBuilderUI';
import { isEmpty } from 'utils/common/helpersCommon';

const BetBuilderInfo = ({ customBetOutcomes }) => {
  return (
    <>
      {!isEmpty(customBetOutcomes) && (
        <BetBuilderUI.SelectionsWrapper className="px-2 pb-2">
          {customBetOutcomes.map((betBuilder, index) => (
            <BetBuilderUI.Selection
              isRetail={true}
              key={betBuilder.id || index}
              isLast={index === customBetOutcomes.length - 1}
              outcome={betBuilder.name}
              marketName={betBuilder.marketTemplateName}
              showRemoveButton={false}
              selectionStatus={betBuilder.selectionStatus}
              betStatus={betBuilder.betStatus}
              hasEarlyPayout={!!betBuilder.selectionBonuses?.['EARLYPAYOUT']}
              hasBoreDraw={!!betBuilder.selectionBonuses?.['BOREDRAW']}
              hasGoalInsurance={!!betBuilder.selectionBonuses?.['GOALINSURANCE']}
              isBoreDrawPaid={betBuilder.selectionBonuses?.['BOREDRAW']?.isPaid}
              isEarlyPayoutPaid={betBuilder.selectionBonuses?.['EARLYPAYOUT']?.isPaid}
            />
          ))}
        </BetBuilderUI.SelectionsWrapper>
      )}
    </>
  );
};

export default BetBuilderInfo;
