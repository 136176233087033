import React, { useEffect } from 'react';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { useGridLayoutSettings } from 'modules/casino/shared/components/gridLayoutSettings/hooks/useGridLayoutSettings';
import { useFullScreen } from 'modules/casino/shared/features/casinoFullScreen/useFullScreen';
import { casinoSearchSelectors } from 'modules/casino/shared/features/casinoSearch/casinoSearchSlice';
import { useScrollToActiveElement } from 'modules/casino/shared/hooks/useScrollToActiveElement';
import { useAppSelector } from 'store';
import { ProvidersBarPosition } from '../../constants/casinoGameProvidersConstants';
import { gameProvidersSelectors } from '../../slice/casinoGameProvidersSlice';

/** ProvidersBarUI Wrapper */
interface WrapperProps {
  children: React.ReactNode;
  activeClass: string;
  refLoaded: boolean;
  position?: ProvidersBarPosition;
}

const Wrapper: React.FC<WrapperProps> = ({ children, activeClass, refLoaded, position }) => {
  const isToggled = useAppSelector(gameProvidersSelectors.isToggled);
  const isActive = useAppSelector(gameProvidersSelectors.searchGameAvailability);
  const providersSearchIsActive = useAppSelector(casinoSearchSelectors.providerSearchIsActive);
  const scrollContainerInner = document.getElementById('providers-scroll-container');
  const activeProviderId = useAppSelector(gameProvidersSelectors.activeProvider);
  const headerSearchIsActive = useAppSelector(casinoSearchSelectors.headerSearchIsActive);
  const { enableFilterByProvider } = useGridLayoutSettings();
  const providersBarClass = clsx(
    'providers-search',
    isActive && 'active',
    providersSearchIsActive && enableFilterByProvider && 'activated',
    headerSearchIsActive && isMobile && 'activated',
  );
  const { isFullScreen } = useFullScreen();
  const scrollToActiveElement = useScrollToActiveElement();

  useEffect(() => {
    const rootElement = document.getElementById('root');
    const fullscreenchanged = () => {
      if (activeProviderId && refLoaded && !isFullScreen()) {
        scrollToActiveElement(scrollContainerInner, activeClass);
      }
    };
    rootElement && rootElement.addEventListener('fullscreenchange', fullscreenchanged);
    if (activeProviderId && refLoaded && !isFullScreen()) {
      scrollToActiveElement(scrollContainerInner, activeClass);
    }

    return () => {
      rootElement && rootElement.removeEventListener('fullscreenchange', fullscreenchanged);
    };
  }, [activeProviderId, refLoaded, scrollToActiveElement, activeClass, scrollContainerInner, isFullScreen]);

  if (!isToggled) return null;

  return (
    <div
      className={providersBarClass}
      data-qid="providers-menu"
      style={{ marginTop: position === ProvidersBarPosition.BOTTOM ? 'auto' : '' }}
    >
      {children}
    </div>
  );
};

export default Wrapper;
