import React from 'react';
import { localLog } from 'modules/casino/shared/utils/common/helpersCommon';
import { GridTypes } from './constants/gridSupportsMap';
import { GridLayoutPros } from './types/gridTypes';

const GridLayout: React.FC<GridLayoutPros> = ({
  children,
  subType,
  layoutConfig,
  className,
  gridLoadingBehavior,
  fetchMoreGames,
  collectionId,
  categoryLayout,
  isCategoryLast,
  categoryId,
  query,
}) => {
  const { layout } = layoutConfig;

  const props = {
    children,
    subType,
    className,
    layoutConfig,
    gridLoadingBehavior,
    fetchMoreGames,
    categoryLayout,
    isCategoryLast,
    categoryId,
    collectionId,
    query,
  };

  const renderGrid = () => {
    const GridComponent = GridTypes[layout];

    if (!GridComponent) {
      localLog({ message: `Provided Grid Type is not supported` });
      return <></>;
    }

    return <GridComponent {...props} />;
  };

  return renderGrid();
};

export default GridLayout;
