import { createSlice } from '@reduxjs/toolkit';
import { createAbortThunk } from 'modules/casino/store/thunkCreators';
import { RootState } from 'store/rootReducer';
import axiosInstance from 'utils/common/axios-instance';
import config from 'utils/config';
import { CasinoCarouselInitialState, SliderContainerThunks, SliderContent } from '../types/types';

export const casinoCarouselThunks: SliderContainerThunks = {
  fetchCasinoSlider: createAbortThunk('casinoCarousel/fetchCasinoSlider', async ({ apiUri }, { source }) => {
    const url = `${config.API_URL}${apiUri}`;
    const response = await axiosInstance.get<SliderContent[]>(url, {
      cancelToken: source.token,
    });

    return response.data;
  }),
};

const { fetchCasinoSlider } = casinoCarouselThunks;

const initialState: CasinoCarouselInitialState = {
  showCarousel: false,
  sliderContainerData: {},
};

const casinoCarousel = createSlice({
  name: 'casinoCarousel',
  initialState,
  reducers: {
    setShowCarousel(state, action) {
      state.showCarousel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCasinoSlider.fulfilled, (state, action) => {
      const { containerId } = action.meta.arg;
      state.sliderContainerData[containerId] = action.payload;
    });
  },
});

export const { setShowCarousel } = casinoCarousel.actions;

const selectShowCarousel = (state: RootState) => state.casino.casinoCarousel.showCarousel;
const selectSliderContainerData = (state: RootState, containerId: number): SliderContent[] =>
  state.casino.casinoCarousel.sliderContainerData?.[containerId];

export const carouselSelectors = {
  showCarousel: selectShowCarousel,
  sliderContainerData: selectSliderContainerData,
};

export default casinoCarousel.reducer;
