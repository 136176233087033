import React from 'react';

/** Form Label Secondary */
interface LabelSecondaryProps {
  label?: string;
}

const LabelSecondary: React.FC<LabelSecondaryProps> = ({ label }) => {
  return <div className="form-label--secondary col-12">{label}</div>;
};

export default LabelSecondary;
