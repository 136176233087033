import React, { useEffect, memo } from 'react';
import { ProviderLaunchAgents } from 'utils/common/types/casino.types';
import useExtractAgentFromUrl from './hooks/useExtractAgentFromUrl';

const AgentHandler = () => {
  const agent = useExtractAgentFromUrl();

  useEffect(() => {
    if (ProviderLaunchAgents[agent] && window.opener !== null && agent !== ProviderLaunchAgents.window_desktop) {
      window.close();
    }
  }, []);
  return <></>;
};

export default memo(AgentHandler);
