import styled from 'styled-components';
import { prizeDropCSSNS } from '../../../PrizeDropCellWidgetConstants';

const SCTimeCounter = styled.div<{ $isColumn?: boolean }>`
  display: flex;
  flex-direction: ${({ $isColumn }) => (!$isColumn ? 'row' : 'column')};
  justify-content: ${({ $isColumn }) => (!$isColumn ? 'space-between' : '')};
  align-items: ${({ $isColumn }) => (!$isColumn ? 'center' : 'flex-start')};

  padding: ${({ $isColumn }) => ($isColumn ? '0' : `var(--${prizeDropCSSNS}-spacer-default)`)};

  font-size: var(--${prizeDropCSSNS}-text-xs);

  @media screen and (min-width: 900px) {
    font-size: var(--${prizeDropCSSNS}-text-sm);
  }

  color: var(--${prizeDropCSSNS}-counter-text);
`;

const SCTimeCounterDisplay = styled.div.attrs({ className: `${prizeDropCSSNS}-counter-display` })`
  display: grid;
  grid-auto-flow: column;
  column-gap: 10px;
`;

const SCTimeCounterItem = styled.div.attrs({ className: `${prizeDropCSSNS}-counter-item` })`
  position: relative;

  text-align: center;

  &:not(&:last-child) {
    :after {
      content: ':';
      position: absolute;
      right: -8px;

      top: 1px;
      font-size: 24px;
      font-weight: 400;
      line-height: 1.1rem;

      color: var(--${prizeDropCSSNS}-counter-text);
    }
  }
`;

const SCTimeCounterItemDate = styled.div.attrs({ className: `${prizeDropCSSNS}-counter-item--date` })`
  width: 28px;
  padding: 1px 2px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: bold;
  font-variant-numeric: tabular-nums;
  border: var(--${prizeDropCSSNS}-counter-item-border);
  border-radius: var(--${prizeDropCSSNS}-counter-item-br);
  box-shadow: var(--${prizeDropCSSNS}-counter-item-shadow);
  background: var(--${prizeDropCSSNS}-counter-item-bg);
  color: var(--${prizeDropCSSNS}-counter-item-text);
`;

const SCTimeCounterItemText = styled.div.attrs({ className: `${prizeDropCSSNS}-counter-item--text` })`
  font-size: 9px;
  @media screen and (min-width: 900px) {
    font-size: 10px;
  }

  &:after {
    display: none;
  }
`;

export { SCTimeCounter, SCTimeCounterDisplay, SCTimeCounterItem, SCTimeCounterItemDate, SCTimeCounterItemText };
