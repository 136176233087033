import React from 'react';
import PrizeDropIcon from './PrizeDropIcon';
import PrizeDropTitle from './PrizeDropTitle';
import { prizeDropCSSNS } from '../prizeDropUICSS.types';

type Props = {
  iconResource?: string;
  icon?: string;
  title: string;
  givenAwards: number;
  totalAwards: number;
  isActive?: boolean;
  isPromotion?: boolean;
  onClick?: React.MouseEventHandler;
};

const PrizeDropGiftItem: React.FC<Props> = ({
  title,
  givenAwards,
  totalAwards,
  iconResource,
  isActive,
  isPromotion,
  onClick,
}) => {
  return (
    <egtd-przd-gift-item data-active={isActive} data-promo={isPromotion} onClick={onClick}>
      <egtd-przd-gift-item-inner>
        <div className={`${prizeDropCSSNS}-gift-item-icon`}>
          {iconResource && <PrizeDropIcon iconResource={iconResource} />}
        </div>
        <PrizeDropTitle title={title} size="md" className="text-clamp-4-line" />
      </egtd-przd-gift-item-inner>
      <egtd-przd-gift-item-footer data-active={isActive}>
        <span className={`${prizeDropCSSNS}-gift-item-text--primary`}>{givenAwards}</span>
        <span className={`${prizeDropCSSNS}-gift-item-text--secondary`}>/{totalAwards}</span>
      </egtd-przd-gift-item-footer>
    </egtd-przd-gift-item>
  );
};

export default PrizeDropGiftItem;
