import React from 'react';
import clsx from 'clsx';
import { Text } from 'components/shared';
import { Badge, BadgeVariants } from 'components/shared/badges';
import { Flex } from 'components/shared/layout';
import HeaderIcon from 'shared/common/features/myProfile/components/CTAUI/HeaderIcon';
import { PromotionTypeEnum } from 'shared/common/features/myProfile/sections/tabs';
import ParseHTML from 'shared/common/ParseHTML';
import { cdnConfig, fitCover } from 'utils/common/cdnConfig';
import { getBusinessUnit } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { bonusLogComponentLocationCSSSuffixesMap, BonusLogComponentLocationType, cssNS } from './bonusLogUITypes';
import TextRow from './TextRow';

/** BonusItem Header */
interface HeaderProps {
  componentLocation: BonusLogComponentLocationType;
  /** Bonus name */
  title: string;
  /** Bonus product source */
  source?: string;
  /** Bonus Status */
  status?: BadgeVariants;
  /** Bonus Status text*/
  statusText?: string;
  banner?: string;
  description?: string;
  freeSpinGameInfo?: JSX.Element | null;
  activationText: string;
  activationValue?: string;
  expirationText?: string;
  expirationValue?: string;
  promotionType?: string;
  givenDateValue?: string;
  givenDateText?: string;
  icon?: string;
}

const Header: React.FC<HeaderProps> = ({
  componentLocation,
  title,
  status,
  statusText,
  banner,
  description,
  freeSpinGameInfo,
  activationText,
  activationValue,
  expirationText,
  expirationValue,
  promotionType,
  givenDateValue,
  givenDateText = '',
  icon,
}) => {
  // TODO: MOVE TO CONFIG, temp SMBG hack
  const isSmbg = getBusinessUnit() === 'SMBG';

  const imageUrl =
    promotionType === PromotionTypeEnum.Promotion
      ? `${config.CDN_IMAGE_API}${fitCover(303 * 2, isSmbg ? 341 : 70 * 2)}${cdnConfig.PROMOTIONS_MODULE}/${banner}`
      : `${config.CDN_IMAGE_API}${fitCover(303 * 2, isSmbg ? 341 : 70 * 2)}${
          cdnConfig.NOTIFICATIONS_MODULE
        }/${banner}.png`;

  const headerClass = clsx(
    `${cssNS}__header ${cssNS}__header${bonusLogComponentLocationCSSSuffixesMap[componentLocation]}`,
  );

  return (
    <div className={headerClass}>
      <Flex.AC className="mb-2">
        {icon && <HeaderIcon icon={icon} />}
        {title && <Text className={`${cssNS}__header-text ${cssNS}__text--accent`} text={title} />}
        {status && <Badge className="ml-auto" variant={status} label={statusText} />}
      </Flex.AC>
      {banner && (
        <div className="mpd-cta-item__section">
          <img className="mpd-cta-item__banner" src={imageUrl} />
        </div>
      )}
      {description && !freeSpinGameInfo && (
        <div className="mb-2">
          <ParseHTML html={description || ''} options={{ trim: true }} />
        </div>
      )}
      {freeSpinGameInfo && <div className="mb-2">{freeSpinGameInfo}</div>}
      {activationValue && <TextRow leftColText={activationText} leftColValue={activationValue} showRightCol={false} />}
      {expirationValue && expirationText && (
        <TextRow leftColText={expirationText} leftColValue={expirationValue} showRightCol={false} />
      )}
      {givenDateValue && <TextRow leftColText={givenDateText} leftColValue={givenDateValue} showRightCol={false} />}
    </div>
  );
};

export default Header;
