import { CDN_PATH_MAP } from 'modules/casino/modules/container/cellsContainer/cellls/constants/casinoGamesConstants';
import { LayoutCellWrappers } from 'modules/casino/modules/container/cellsContainer/grid/components/gridCell/gridCellConstants';
import { GridLayouts } from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import { LayoutConfigInnerLayoutTypes } from 'modules/casino/shared/constants';
import { useCasinoLayoutConfig } from 'modules/casino/shared/hooks/useCasinoLayoutConfig';

type ReturnProps = {
  cdnPathLayout: string;
  cdnPath: string;
  cellLayout: string;
};

export const useGridLayoutConfig = (layoutGrid: GridLayouts): ReturnProps => {
  const casinoLayoutConfig = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.CASINO_LAYOUT_CONFIG });
  const upperCaseLayout = layoutGrid?.toUpperCase();

  const CDN_PATH_NEW = 'game-providers-new';

  const cdnPathLayout: string | undefined =
    layoutGrid && CDN_PATH_MAP.includes(layoutGrid!.toUpperCase())
      ? layoutGrid?.toLowerCase()
      : casinoLayoutConfig?.defaultCdnPathLayout || 'layout_1';

  const cdnPath: string =
    casinoLayoutConfig && casinoLayoutConfig?.cdnPath && casinoLayoutConfig?.cdnPath[upperCaseLayout] !== undefined
      ? casinoLayoutConfig?.cdnPath[upperCaseLayout]
      : CDN_PATH_NEW;

  const cellLayout =
    upperCaseLayout && casinoLayoutConfig?.cellLayouts && casinoLayoutConfig?.cellLayouts[upperCaseLayout] !== undefined
      ? casinoLayoutConfig?.cellLayouts[upperCaseLayout]
      : layoutGrid && LayoutCellWrappers[layoutGrid]
        ? LayoutCellWrappers[layoutGrid]
        : 'cell_1';

  return {
    cdnPathLayout,
    cdnPath,
    cellLayout,
  } as ReturnProps;
};
