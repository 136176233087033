import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'modules/casino/shared/components/icons';
import { Flex } from 'modules/casino/shared/components/layout';
import { Modal } from 'modules/casino/shared/components/modal';
import { ModalUI } from 'modules/casino/shared/components/modalUI';
import { UI } from 'modules/casino/shared/components/UI';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch } from 'store';
import { KycVerificationModal } from '../../../casinoLaunchConstants';

const KYCVerificationModal: React.FC<{
  onConfirm: () => void;
  onCancel: () => void;
  descriptionKey?: string;
}> = ({ onConfirm, onCancel, descriptionKey = '' }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleConfirmClick = () => {
    onConfirm && onConfirm();
  };

  const handleCancelClick = () => {
    dispatch(hideRootModal());
    onCancel && onCancel();
  };

  return (
    <Modal visible className="kyc-verification-modal" dialogClassName="modal-dialog-centered">
      <Modal.Close closeText={t(KycVerificationModal.closeButton)} closeAction={() => handleCancelClick()} />
      <Modal.Body className="d-flex-col-ac text-center" padding="lg">
        <ModalUI.Title text={t(KycVerificationModal.accountVerification)} />
        <Icon className="my-3 modal-terms__icon" icon="shield-check" />
        <ModalUI.Title hasAccent text={t(descriptionKey)} />
        <Flex.ACJA className="w-100 mt-3">
          <UI.Button
            variant={'secondary'}
            className="w-100"
            size="lg"
            onClickHandler={handleCancelClick}
            text={t(KycVerificationModal.cancelButton)}
          />
          <UI.Button
            className="ml-3 w-100"
            size="lg"
            onClickHandler={handleConfirmClick}
            text={t(KycVerificationModal.modalButton)}
          />
        </Flex.ACJA>
      </Modal.Body>
    </Modal>
  );
};

export default KYCVerificationModal;
