import styled, { css } from 'styled-components';
import { gamificationGenerateGradient } from '../../../utils/Gamification.utils';

const SCShared = css`
  font-size: clamp(0.875rem, 0.803rem + 0.31vw, 1rem);
  font-weight: 400;
  line-height: 1.1;
  text-align: center;
  text-shadow: 0px 1px 2px #000;
  transition: width 0.15s;
`;

export const SCCTAText = styled.div<{ $textColor?: string; $bgColor?: string }>`
  ${SCShared};
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  place-self: start center;
  color: ${({ $textColor }) => ($textColor ? $textColor : '#fff')};
  margin-top: 65px;

  max-width: 200px;
`;

export const SCCTAWinText = styled.div<{ $textColor?: string; $bgColor?: string }>`
  ${SCShared};

  padding: 10px 20px;

  max-width: 230px;
  color: ${({ $textColor }) => ($textColor ? $textColor : '#fff')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;

  ${({ $bgColor }) => $bgColor && gamificationGenerateGradient($bgColor ?? 'rgba(65, 76, 208, 0.4)')}
`;
