import React from 'react';
import { useTranslation } from 'react-i18next';
import NoDataMessage from 'components/shared/NoDataMessage';
import { CasinoTranslations } from 'utils/common/translationUtils/translationStrings';
import PopoverBonus from './PopoverBonus';
import PopoverOffer from './PopoverOffer';
import { BonusOffersInfoButton } from '../types/bonusOffersInfoButton.types';
import { cssBonusOffersInfoNS } from '../types/bonusOffersInfoUI.types';

type Props = {
  data: BonusOffersInfoButton.Data | null;
};

const BonusOffersInfoPopover: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className={cssBonusOffersInfoNS.Popover}>
      {!data?.bonuses.length && !data?.offers.length && (
        <NoDataMessage message={t(CasinoTranslations.BonusOffersInfo.noOffersText)} />
      )}
      {Boolean(data?.bonuses.length) && (
        <div className={cssBonusOffersInfoNS.PopoverBonuses}>
          <div className={cssBonusOffersInfoNS.PopoverTitle}>{t(CasinoTranslations.BonusOffersInfo.activesTitle)}</div>
          {data?.bonuses.map((dataItem) => <PopoverBonus key={dataItem.id} dataItem={dataItem} />)}
        </div>
      )}
      {Boolean(data?.offers.length) && (
        <div className={cssBonusOffersInfoNS.PopoverOffers}>
          <div className={cssBonusOffersInfoNS.PopoverTitle}>{t(CasinoTranslations.BonusOffersInfo.offersTitle)}</div>
          {data?.offers.map((promotion) => (
            <PopoverOffer key={promotion.promotionId} promotion={promotion} unseen={data?.unseen} />
          ))}
        </div>
      )}
    </div>
  );
};

export default BonusOffersInfoPopover;
