import React from 'react';
import { UI } from 'components/shared/UI';
import { cssNS } from './bonusLogUITypes';
import TextRow from './TextRow';

/** BonusItem Body */
interface BodyProps {
  children?: React.ReactNode;
  /** Bonus vertical */
  vertical?: string;
  /** Bonus give amount text */
  bonusGivenText: string;
  /** Bonus give amount value */
  bonusGivenValue?: string;
  /** Bonus achieved amound text */
  bonusAchievedText: string;
  /** Bonus achieved amound value */
  bonusAchievedValue?: string;
  /** Additional bonus give amount text */
  additionalBonusGivenText?: string;
  /** Additional bonus give amount value */
  additionalBonusGivenValue?: string;
  /** Additional bonus achieved amound text */
  additionalBonusAchievedText?: string;
  /** Additional bonus achieved amound value */
  additionalBonusAchievedValue?: string;
}

const Body: React.FC<BodyProps> = ({
  children,
  vertical,
  bonusGivenText,
  bonusGivenValue,
  bonusAchievedText,
  bonusAchievedValue,
  additionalBonusGivenText,
  additionalBonusGivenValue,
  additionalBonusAchievedText,
  additionalBonusAchievedValue,
}) => {
  return (
    <UI.History.Block isBody className={`${cssNS}__body`}>
      {vertical && <div className="mb-2">{vertical}</div>}
      {children}
      {bonusGivenValue && <TextRow showRightCol={false} leftColText={bonusGivenText} leftColValue={bonusGivenValue} />}
      {bonusAchievedValue && (
        <TextRow showRightCol={false} leftColText={bonusAchievedText} leftColValue={bonusAchievedValue} />
      )}
      {additionalBonusGivenText && additionalBonusGivenValue && (
        <TextRow showRightCol={false} leftColText={additionalBonusGivenText} leftColValue={additionalBonusGivenValue} />
      )}
      {additionalBonusAchievedText && additionalBonusAchievedValue && (
        <TextRow
          showRightCol={false}
          leftColText={additionalBonusAchievedText}
          leftColValue={additionalBonusAchievedValue}
        />
      )}
    </UI.History.Block>
  );
};

export default Body;
