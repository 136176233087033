import { memo } from 'react';
import { Badge } from 'components/shared/badges';
import { General } from 'shared/common/features/general/types/generalSlice.types';
import { useAppSelector } from 'store';
import { selectMyChallenges } from './captainUpTab/slice/clubTab.slice';
import { selectNotification } from './notificationsTab/notificationSlice';
import { selectOffersTab } from './offersTab';
import { MyProfileTabType, MyProfileTabsEnum } from '../../types/myProfile.types';

const defineBadgeStyle = (tabKey: MyProfileTabType['key'] | General.MyProfileRoutes['key']): string => {
  switch (tabKey) {
    case MyProfileTabsEnum.OFFERS:
      return 'ml-2 mpd-nav__badge--tab';
    case MyProfileTabsEnum.NOTIFICATIONS:
      return 'position-absolute mpd-nav__badge--item';
    case MyProfileTabsEnum.CLUB:
      return 'ml-2 mpd-nav__badge--tab';
    default:
      return '';
  }
};

const notificationsSelectorMap = {
  offers: selectOffersTab,
  notifications: selectNotification,
  captainUp: selectMyChallenges,
};
const AccountTabBadge: React.FC<{
  tabKey: MyProfileTabType['key'] | General.MyProfileRoutes['key'];
  isNavGrid?: boolean;
}> = ({ tabKey, isNavGrid }) => {
  const counter: number = useAppSelector(notificationsSelectorMap[tabKey].notificationCounter);

  if (counter < 1) return null;

  const style = `mpd-nav__badge ${defineBadgeStyle(isNavGrid ? 'notifications' : tabKey)}`;

  return <Badge variant="circle" className={style} label={counter.toString()} />;
};

export default memo(AccountTabBadge);
