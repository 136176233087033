import { PrintTerminalDataType } from 'modules/retail/modules/ssbt/types/ssbt.types';
import { printTerminalDataMobile } from 'modules/retail/shared/printMethods/mobilePrintMethods/mobilePrint.utils';
import { printTerminalData } from 'modules/retail/shared/printMethods/ticketPrintPartsMethods/ticketPrint.utils';
import { store } from 'store';
import { isHandheld } from 'utils/common/helpersCommon';
import { resetReprintReceiptInfo } from '../slice/reprintTicket.slice';

export const printSportData = (ticketInfo, dispatch, isCurrencyBeforeAmount) => {
  const isDuplicate = true;
  const ticketId = ticketInfo.ticket.ticket.ticketId || '';
  const createdDate = ticketInfo.ticket.ticket.createdDate || '';
  const paidWinLong = ticketInfo.ticket.ticket.ticketAggregate.metadata.paidWinLong || '';
  const outcomes = ticketInfo.ticket.ticket.bets.reduce((acc, bet) => {
    return [...acc, ...(bet?.outcomes || [])];
  }, []);
  const uniqueOutcomes = outcomes.reduce((acc, current) => {
    const x = acc.find((item) => item.id === current.id);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  const totalStake = ticketInfo.ticket.ticket.ticketAggregate.metadata.totalStake || '';
  const summaries = ticketInfo.ticket.ticket.ticketAggregate.metadata.summaries || [];
  const totalOdds = ticketInfo.ticket.ticket.ticketAggregate.metadata.totalOdds.toString() || '';
  const totalCombinations = ticketInfo.ticket.ticket.ticketAggregate.metadata.totalCombinations || '';
  const currency = ticketInfo.ticket.currency || '';
  const ticket = ticketInfo.ticket || {};
  const printTerminalDataObj: PrintTerminalDataType = {
    isDuplicate,
    ticketId,
    timestamp: createdDate,
    totalWin: paidWinLong,
    outcomes: uniqueOutcomes,
    stake: totalStake,
    summary: summaries,
    totalOdds,
    getState: store?.getState,
    totalCombinations,
    currency,
    retailInfo: ticket,
    isCurrencyBeforeAmount,
  };
  if (isHandheld()) {
    printTerminalDataMobile(printTerminalDataObj);
  } else {
    printTerminalData(printTerminalDataObj);
  }
  dispatch(resetReprintReceiptInfo());
};
