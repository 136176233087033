import { useTranslation } from 'react-i18next';
import { replaceTranslationPlaceholder } from 'components/shared/forms/utils';
import { Flex } from 'components/shared/layout';
import { Modal } from 'components/shared/Modal';
import { UI } from 'components/shared/UI';
import { handleRealityCheckConfirmed } from 'hooks/common/useRealityCheckConfirmed';
import { useAppDispatch } from 'store';
import { RealityCheckModal as RealityCheckModalTranslation } from 'utils/common/translationUtils/translationStrings';
import { setRealityCheckConfirmedAt } from '../../general/slice/general.slice';
import useHandleModals from '../hooks/useHandleModals';

interface Props {
  message: string;
  showLogoutButton: boolean;
  minutes: number;
}

const MINUTES_PLACEHOLDER = '<<minutes>>';

export const RealityCheckModal: React.FC<Props> = ({ message, showLogoutButton, minutes }) => {
  const { t } = useTranslation();
  const messageTranslated = replaceTranslationPlaceholder(t(message), minutes, MINUTES_PLACEHOLDER);
  const { closeModal, handleLogout } = useHandleModals();

  const dispatch = useAppDispatch();
  const handleClick = () => {
    closeModal();
    handleRealityCheckConfirmed();
    dispatch(setRealityCheckConfirmedAt(Date.now()));
  };

  return (
    <Modal visible className="modal-terms">
      <Modal.Body padding="lg" className="text-center">
        <p className="mb-3 modal-body__title">
          {t(RealityCheckModalTranslation.Title.realityCheckText)}
          <br />
          {messageTranslated}
        </p>

        <Flex.Center className="d-flex-col-lg-row">
          <UI.Button
            className="m-2"
            size="lg"
            onClick={handleClick}
            text={t(RealityCheckModalTranslation.Button.Confirm)}
          />

          {showLogoutButton && (
            <UI.Button
              className="m-2"
              size="lg"
              variant="secondary"
              onClick={handleLogout}
              text={t(RealityCheckModalTranslation.Button.Logout)}
            />
          )}
        </Flex.Center>
      </Modal.Body>
    </Modal>
  );
};

export default RealityCheckModal;
