import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import userStorage from 'pages/auth/login/UserStorage';
import { useAppDispatch } from 'store';
import {
  readNotification,
  setActivePopupNotificationId,
} from '../../myProfile/sections/tabs/notificationsTab/notificationSlice';

const useWheelAnimation = (notificationId: string, setAnimationComplete: (complete: boolean) => void) => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [hasWin, setHasWin] = useState(false);
  const timer = useRef<NodeJS.Timeout | null>(null);

  const dispatch = useAppDispatch();

  const isDialVisible = useMediaQuery({ query: `(min-width: 375px)` });

  useEffect(() => {
    return () => {
      timer?.current && clearTimeout(timer.current);
    };
  }, []);

  const startSpin = () => {
    setIsSpinning(true);
    dispatch(readNotification(notificationId, 'popup')).then(() => {
      userStorage.setUserPopupNotificationId(notificationId);
    });
    dispatch(setActivePopupNotificationId(notificationId));
  };

  const stopSpin = () => {
    if (!isSpinning) {
      setHasWin(false);
    } else {
      timer.current = setTimeout(() => {
        setHasWin(true);
        setAnimationComplete(true);
      }, 750);
    }
  };

  const calcRemainingDistance = (winnerSegmentIdx: number) => {
    if (winnerSegmentIdx <= 10) {
      return (10 - winnerSegmentIdx) * 30;
    } else if (winnerSegmentIdx === 11) {
      return 11 * 30;
    } else {
      return 10 * 30;
    }
  };

  return { calcRemainingDistance, stopSpin, startSpin, isDialVisible, hasWin, isSpinning };
};

export default useWheelAnimation;
