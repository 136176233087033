import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/shared';
import { useAppSelector } from 'store';
import { ClaimTicketTranslations } from 'utils/common/translationUtils/translationStrings';
import { selectClaimTicket } from '../slice/claimTicket.slice';

const StatusMessage = (): JSX.Element => {
  const claimTicketStatus = useAppSelector(selectClaimTicket.claimTicketStatus);
  const isError = claimTicketStatus === 'rejected';
  const { t } = useTranslation();
  const variant = isError ? 'error' : 'success';
  const text = isError
    ? ClaimTicketTranslations.Messages.failureMessage
    : ClaimTicketTranslations.Messages.successMessage;
  const icon = isError ? 'times-solid' : 'check-solid';

  const messageClass = clsx('m-auto egtd-x-check-ticket-status', variant && `fill--${variant}`);
  return (
    <div className={messageClass}>
      <Icon size={20} icon={icon} />
      <span className="egtd-x-text-fs-20">{t(text)}</span>
    </div>
  );
};

export default StatusMessage;
