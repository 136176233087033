import { useLocation } from 'react-router-dom';
import { useFilterHiddenVerticals } from 'hooks/common/useFilterHiddenVerticals';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { useAppSelector } from 'store';
import useSearchIcon from './useSearchIcon';
import { selectAppHeader } from '..';
import { General, LayoutConfigTypes } from '../../general/types/generalSlice.types';
import { Subvertical, Vertical } from '../../navigationMenu/types/navigationMenu.types';

type ReturnTypeSearch = {
  subNav: General.SubNav;
  subVerticals: (Vertical | Subvertical)[];
  hideSearchIcon: boolean;
  // bonusCollection: boolean;
  // showTombolaCollection?: boolean;
};

export const useMainNavbar = (): ReturnTypeSearch => {
  const location = useLocation();
  useSearchIcon();
  const hideSearchIcon = useAppSelector(selectAppHeader.hideSearchIcon);
  const verticals = useFilterHiddenVerticals();
  // const { show: showTombolaCollection } = useTombolaTickets();
  // const { bonusCollectionApiUrl } = useAppSelector((state: RootState) => getBonusBySubType(state, `bonusCollection`));
  const navigationLayout = useLayoutConfigType({ moduleLayout: 'platform', innerLayout: LayoutConfigTypes.NAVIGATION });
  const topNav = navigationLayout?.topNav;
  const subNav = navigationLayout?.subNav;
  const currentVertical = verticals.find((item) => location.pathname.includes(item.route || ''));
  const subVerticals = topNav ? (currentVertical ? currentVertical.subverticals : []) : verticals;

  return {
    subNav,
    subVerticals,
    hideSearchIcon,
    // bonusCollection:  !!bonusCollectionApiUrl,
    // showTombolaCollection,
  };
};
