import React, { RefObject, memo, useEffect } from 'react';
import { Flex } from 'components/shared/layout';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { sessionBalanceThunk } from 'modules/retail/modules/ssbt/features/sessionBalance/slice/sessionBalance.slice';
import { printVoucher } from 'modules/retail/modules/ssbt/printMethods/voucherPrintMethod';
import { selectVoucherData } from 'modules/retail/shared/slice/voucher.slice';
import { General, LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { selectNavigationMenu } from 'shared/common/features/navigationMenu/slice/navigationMenu.slice';
import { useAppDispatch, useAppSelector } from 'store';
import VerticalsMenu from './VerticalsMenu';
import { NavBrand, NavUserTerminal } from '..';
import { useUserBalance } from '../../../myProfile/hooks/useUserBalance';
import { DrawerToggler } from '../common/DrawerToggler';

type MainNavDesktopLayout01Types = {
  isAuthenticated: boolean | null;
  parentRef?: RefObject<HTMLDivElement>;
  layout: General.HeaderWrapperConfig['layoutType'];
};

const MainNavDesktopLayout03: React.FC<MainNavDesktopLayout01Types> = ({ isAuthenticated, parentRef, layout }) => {
  const { balanceAmount } = useUserBalance();
  const dispatch = useAppDispatch();
  const retailVoucherInfoStatus = useAppSelector(selectVoucherData?.retailVoucherInfoStatus);
  const voucherInfo = useAppSelector(selectVoucherData?.retailVoucherInfo);
  const showTogglerOnDesktop = useAppSelector(selectNavigationMenu.burgerMenuLayout02)?.showOnDesktop;
  const isTicketCodeLinear = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.TICKET_CODE_LINEAR,
    deviceLayout: 'general',
  });
  const retailAddress = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.RETAIL_ADDRESS,
    deviceLayout: 'general',
  });
  const isCurrencyBeforeAmount = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.CURRENCY_BEFORE_AMOUNT,
    deviceLayout: 'general',
  });

  useEffect(() => {
    if (retailVoucherInfoStatus === 'succeeded') {
      dispatch(sessionBalanceThunk.checkForUpdateBalance());
      const printVoucherObj = {
        totalWin: voucherInfo?.value?.amount ?? 0,
        code: voucherInfo?.code ?? '',
        currency: voucherInfo?.value?.currency ?? '',
        issuedAt: voucherInfo?.issuedAt ?? '',
        validUntil: voucherInfo?.validUntil ?? '',
        isLinearCodeOnReceipt: isTicketCodeLinear ?? false,
        retailAddress,
        isCurrencyBeforeAmount: isCurrencyBeforeAmount ?? false,
      };
      printVoucher(printVoucherObj);
    }
  }, [retailVoucherInfoStatus]);

  return (
    <Flex className={`overflow-y-auto flex-1`}>
      {showTogglerOnDesktop && <DrawerToggler />}
      <NavBrand />
      <VerticalsMenu parentRef={parentRef} layout={layout} />
      <Flex.ACJE className="nav-user">
        {<NavUserTerminal userBalance={balanceAmount} isAuthenticated={isAuthenticated} />}
      </Flex.ACJE>
    </Flex>
  );
};

export default memo(MainNavDesktopLayout03);
