import React from 'react';
import { GridLayouts } from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import useWidgetBackground from 'modules/casino/modules/container/widget/widgetTypes/hooks/useWidgetBackground';
import { CasinoWidgetsTypes } from '../../../types/casinoWidgetsTypes';
import GenericWidgetSkeleton from '../components/GenericWidgetSkeleton';
import { GenericWidgetsUI } from '../components/GenericWidgetsUIComponents';
interface WidgetsProps {
  widget: CasinoWidgetsTypes.GenericWidget;
  layoutGrid: GridLayouts;
}

const GenericSectionWidget: React.FC<WidgetsProps> = ({ widget, layoutGrid }) => {
  const backgroundWidgetResource = widget?.backgroundLocations;
  const type = widget?.template?.type;
  const { backgroundWrapper, setRefs } = useWidgetBackground({ backgroundWidgetResource, type });

  return widget.id && (!backgroundWidgetResource || backgroundWrapper) ? (
    <>
      <GenericWidgetsUI.Wrapper
        type={type}
        backgroundUrl={backgroundWrapper}
        columns={widget?.template?.columns}
        navigation={widget?.navigation}
        wrapperClassName={layoutGrid?.toLowerCase()}
      >
        <div className="cw--left-col">
          {widget?.title && <GenericWidgetsUI.Text description={widget?.title} type={type} />}
        </div>
      </GenericWidgetsUI.Wrapper>
    </>
  ) : (
    <GenericWidgetSkeleton type={type} columns={widget?.template?.columns} setRefs={setRefs} />
  );
};
export default GenericSectionWidget;
