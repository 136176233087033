import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Keyboard, { KeyboardReactInterface } from 'react-simple-keyboard';
import { UI } from 'components/shared/UI';
import InputsWrapper from 'modules/retail/modules/cashier/cashierModalUI/InputsWrapper';
import {
  clearGetInfoPlayer,
  clearPlayerInfoErrorMessage,
  clearSaveInfoPlayer,
  playerInfoThunk,
  selectPlayerInfo,
} from 'modules/retail/modules/cashier/features/UCNForm/slice/playerInfo.slice';
import { selectTicketData } from 'modules/retail/shared/slice/checkTicket.slice';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { TerminalModalUI } from '../../../components/ssbtModalUI';
import ButtonsWrapper from '../../../components/ssbtModalUI/ButtonsWrapper';
import CustomCheckButton from '../../../components/ssbtModalUI/CustomCheckButton';
import Input from '../../../components/ssbtModalUI/Input';
import 'react-simple-keyboard/build/css/index.css';

const UCNStepTicketModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const keyboardRef = useRef<KeyboardReactInterface | null>(null);
  const ucnInputRef = useRef<HTMLInputElement | null>(null);
  const { closeModal } = useHandleModals();
  const playerInfo = useAppSelector(selectPlayerInfo.playerInfo);
  const playerInfoStatus = useAppSelector(selectPlayerInfo.playerInfoStatus);
  const [inputValueUCN, setInputValueUCN] = useState('');
  const ticketInfo = useAppSelector(selectTicketData.ticketInfo);
  const playerInfoErrorMessage = useAppSelector(selectPlayerInfo.playerInfoErrorMessage);
  const savePlayerInfoError = useAppSelector(selectPlayerInfo.savePlayerErrorCode);
  const [inputValueFirstName, setInputValueFirstName] = useState('');
  const [inputValueMiddleName, setInputValueMiddleName] = useState('');
  const [inputValueLastName, setInputValueLastName] = useState('');
  const [inputName, setInputName] = useState('');
  const handleInputChange = (value: string, setValue: React.Dispatch<React.SetStateAction<string>>) => {
    const numericValue = value.replace(/[^0-9]/g, ''); // Allow only numeric values
    setValue(numericValue);
    if (keyboardRef.current) {
      keyboardRef.current.setInput(numericValue);
    }
  };

  const handleInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    setValue: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    const value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric values
    setValue(value);
    if (keyboardRef.current) {
      keyboardRef.current.setInput(value);
    }
  };
  const handleKeyPress = () => {
    dispatch(playerInfoThunk.getPlayerInfo({ ucn: Number(inputValueUCN) }));
  };

  const onChangeAll = (input) => {
    const numericInput = input.replace(/[^0-9]/g, ''); // Ensure only numeric input
    switch (inputName) {
      case 'ucnNums':
        setInputValueUCN(numericInput);
        break;
      case 'first name':
        setInputValueFirstName(input);
        break;
      case 'middle name':
        setInputValueMiddleName(input);
        break;
      case 'last name':
        setInputValueLastName(input);
        break;
      default:
        break;
    }
  };
  const isDisabledSaveButton =
    isEmpty(inputValueUCN) ||
    isEmpty(inputValueFirstName) ||
    isEmpty(inputValueMiddleName) ||
    isEmpty(inputValueLastName) ||
    !isEmpty(playerInfoErrorMessage) ||
    isEmpty(playerInfo);

  const closeUCN = () => {
    closeModal();
    dispatch(clearPlayerInfoErrorMessage());
    dispatch(clearSaveInfoPlayer());
    dispatch(clearGetInfoPlayer());
    setInputValueUCN('');
    setInputValueFirstName('');
    setInputValueMiddleName('');
    setInputValueLastName('');
  };
  const showPayoutModal = () => {
    dispatch(
      showRootModal({
        modalType: 'PAYOUT_MODAL',
        modalId: 'PAYOUT_MODAL',
        modalPriority: ModalPriorityEnum.MEDIUM,
      }),
    );
  };
  const savePlayerInfo = () => {
    showPayoutModal();
    dispatch(clearPlayerInfoErrorMessage());
    dispatch(clearGetInfoPlayer());
  };
  useEffect(() => {
    if (!isEmpty(playerInfo)) {
      setInputValueFirstName(playerInfo?.ucnPlayer?.firstName || '');
      setInputValueMiddleName(playerInfo?.ucnPlayer?.middleName || '');
      setInputValueLastName(playerInfo?.ucnPlayer?.lastName || '');
    }
  }, [playerInfo]);
  useEffect(() => {
    if (ucnInputRef.current) {
      ucnInputRef.current.focus(); // Focus the UCN input when the component mounts
    }
  }, []);
  return (
    <TerminalModalUI.Wrapper isVisible={true}>
      <div className="terminal-ticket__ucn-wrapper">
        <div className="terminal-ticket__ucn-form">
          <Input
            id={'inputTicketNumber'}
            value={ticketInfo?.ticket.ticketId}
            placeholder={'placeholder'}
            readOnly
            label={t('Cashier.CashierModal.ticketId')}
            spanAllColumns
          />
          <InputsWrapper spanAllColumns>
            <Input
              id={'ucnNums'}
              value={inputValueUCN}
              placeholder={''}
              onFocus={() => setInputName('ucnNums')}
              onChange={(e) => handleInputChange(e.target.value, setInputValueUCN)}
              onInput={(e) => handleInput(e, setInputValueUCN)}
              label={t('Cashier.CashPayModal.ucn')}
              spanAllColumns
              inputMode={'numeric'}
              pattern={'[0-9]*'}
              errorText={
                playerInfoStatus === 'succeeded' && isEmpty(playerInfo?.ucnPlayer)
                  ? t('SSBT.CheckTicketModal.ucnErrorPlayer')
                  : !isEmpty(playerInfoErrorMessage)
                    ? t(`Cashier.ERROR.${playerInfoErrorMessage}`)
                    : !isEmpty(savePlayerInfoError)
                      ? 'Invalid UCN'
                      : ''
              }
              ref={ucnInputRef}
            />
            <CustomCheckButton text="Check" onClick={handleKeyPress} />
          </InputsWrapper>

          <Input
            id={'first name'}
            value={inputValueFirstName}
            placeholder={''}
            onChange={(e) => setInputValueFirstName(e.target.value)}
            label={t('Cashier.CashPayModal.firstName')}
            spanAllColumns={false}
            isDisabled={true}
          />

          <Input
            id={'middle name'}
            value={inputValueMiddleName}
            placeholder={''}
            onChange={(e) => setInputValueMiddleName(e.target.value)}
            label={t('Cashier.CashierModal.middleName')}
            spanAllColumns={false}
            isDisabled={true}
          />
          <Input
            id={'last name'}
            value={inputValueLastName}
            placeholder={''}
            onChange={(e) => setInputValueLastName(e.target.value)}
            label={t('Cashier.CashPayModal.lastName')}
            wrapperClassName="half"
            spanAllColumns
            isDisabled={true}
          />

          <ButtonsWrapper className="span-all-cols">
            <UI.Button
              text={t('SSBT.CheckTicketModal.addToBalance')}
              variant="primary"
              size="2xl"
              onClick={savePlayerInfo}
              isDisabled={isDisabledSaveButton}
            />
            <UI.Button
              text={t('MyAccount.Exclusions.cancelExtendButton')}
              variant="secondary"
              size="2xl"
              onClick={closeUCN}
            />
          </ButtonsWrapper>
        </div>
      </div>
      <Keyboard
        keyboardRef={(r) => (keyboardRef.current = r)}
        onChange={onChangeAll}
        layout={{
          default: [
            '1 2 3',
            '4 5 6',
            '7 8 9',
            '0 {bksp}', // {bksp} adds a backspace key
          ],
        }}
        display={{
          '{bksp}': '⌫', // Optional: Display a backspace icon instead of text
        }}
      />
    </TerminalModalUI.Wrapper>
  );
};

export default UCNStepTicketModal;
