import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import {
  TAG_STYLE_CONFIG,
  TagType,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/gameCellTagsConstants';
import { GameTag } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/GameCellTagsTypes';
import { Flex } from 'modules/casino/shared/components/layout';
import { fitCover } from 'modules/casino/shared/utils/common/cdnConfig';
import config from 'utils/config';

interface Props {
  tag: GameTag;
}

export const Tag: React.FC<Props> = ({ tag }) => {
  const { type, logo, background, textColor, text, triangleColor } = tag;
  const { hideTriangle, hideBg } = TAG_STYLE_CONFIG[type];
  const wrapperClass = clsx('cell-tags__wrapper', logo && 'cell-tags__wrapper--logo');
  const truncateElement = type === TagType.BOTTOM_TEXT || type === TagType.BOTTOM_TRANSPARENT_TEXT;
  const tagClass = clsx('cell-tags__tag', truncateElement ? 'text-truncate' : 'd-flex-center');
  const logoUrl = `${config.CDN_IMAGE_API}${fitCover('auto', isMobile ? 76 : 114)}/${logo}`;

  const tagStyle: CSSProperties | undefined = logo
    ? undefined
    : {
        background: !hideBg && background ? `linear-gradient(0.25turn, ${background[0]}, ${background[1]})` : undefined,
        color: textColor,
        padding: truncateElement ? '2px 6px' : undefined,
      };

  return (
    <Flex className={wrapperClass}>
      <div className={tagClass} style={tagStyle}>
        {logo ? <img className="cell-tags__logo" src={logoUrl} alt="" /> : text}
      </div>

      {!hideTriangle && !logo && (
        <div className="cell-tags__triangle" style={{ borderTopColor: triangleColor ?? 'transparent' }}></div>
      )}
    </Flex>
  );
};

Tag.displayName = 'Tag';
