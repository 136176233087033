import i18next, { t } from 'i18next';
import { MapFirstPartReceiptInfoParams } from 'modules/retail/modules/ssbt/types/ssbt.types';
import { CommandData } from 'modules/retail/shared/types/retailCommon.types';
import { printMethods, retailPrintOptions } from 'modules/retail/shared/utils/retail.utils';
import { commands } from 'modules/retail/shared/utils/retail.utils';
import { isEmpty, retailFormatDate } from 'utils/common/helpersCommon';
import { BetSlip, CashierTranslations } from 'utils/common/translationUtils/translationStrings';

export const mapFirstPartReceiptInfo = ({
  ticketIdData,
  timestamp,
  retailAddress,
  isDuplicate,
  posAddress,
  posName,
  currentMnemonicCode,
  playerCpf,
}: MapFirstPartReceiptInfoParams) => {
  const firstPartData: CommandData[] = [];
  printMethods.printIsReady(firstPartData, commands);
  printMethods.printStart(firstPartData, commands);
  printMethods.printOptions(firstPartData, commands, retailPrintOptions._logoOptionsData);
  printMethods.printImage(firstPartData, commands, retailAddress?.buLogo);
  printMethods.printOptions(firstPartData, commands, retailPrintOptions._optionsData);
  printMethods.print(firstPartData, commands, '');
  printMethods.printOptions(firstPartData, commands, retailPrintOptions.winbetOptions);
  printMethods.conditionalPrint(
    firstPartData,
    commands,
    !isEmpty(retailAddress?.responsibleGambling),
    t(retailAddress?.responsibleGambling),
  );
  printMethods.print(firstPartData, commands, t(''));
  printMethods.print(firstPartData, commands, t('Cashier.Receipt.addressPOSText'));
  printMethods.print(
    firstPartData,
    commands,
    `${posAddress?.city}, ${posAddress?.streetName} ${posAddress?.streetNumber}`,
  );
  printMethods.print(firstPartData, commands, '');
  printMethods.print(firstPartData, commands, '');
  printMethods.print(firstPartData, commands, t('Cashier.Receipt.signatureNumber'));
  printMethods.print(firstPartData, commands, posName);
  printMethods.printOptions(firstPartData, commands, retailPrintOptions._logoOptionsData);
  printMethods.conditionalPrint(
    firstPartData,
    commands,
    isDuplicate,
    `${i18next.t(CashierTranslations.Print.dublicateText)} `,
  );
  printMethods.conditionalPrint(firstPartData, commands, isDuplicate, '');
  printMethods.conditionalPrint(
    firstPartData,
    commands,
    window.config.ENV_TYPE !== 'PRODUCTION',
    t('Cashier.Receipt.testOperation'),
  );
  printMethods.printOptions(firstPartData, commands, retailPrintOptions.winbetOptions);
  printMethods.print(firstPartData, commands, ''.padEnd(44, '='));
  printMethods.print(firstPartData, commands, ticketIdData.str);
  printMethods.conditionalPrint(
    firstPartData,
    commands,
    !isEmpty(playerCpf),
    `${t('Cashier.Receipt.playerCpf')}: ${playerCpf}`,
  );
  printMethods.conditionalPrint(
    firstPartData,
    commands,
    currentMnemonicCode !== '',
    `${t('Cashier.Receipt.mnemonicCode')}: ${currentMnemonicCode}`,
  );
  printMethods.print(
    firstPartData,
    commands,
    `${i18next.t(BetSlip.SSBT.acceptedTicket)}: ` + retailFormatDate(timestamp),
  );
  printMethods.print(firstPartData, commands, ''.padEnd(44, '='));
  return firstPartData;
};
