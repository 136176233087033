import { isIOS } from 'react-device-detect';

const bodyRoot = document.body;
const htmlRoot = document.documentElement;

/**
 * Toggle Body and HTML no scroll classes
 * @param add boolean, Add or Remove classes
 * @param affectHTMLRoot boolean, Add or Remove classes to HTML root
 */

export const toggleNoScrollClasses = (add: boolean, affectHTMLRoot?: boolean): void => {
  if (add) {
    bodyRoot.classList.add(isIOS ? 'no-scroll-fixed' : 'no-scroll');
    affectHTMLRoot && htmlRoot.classList.add('h-100vh-1px');
  } else {
    bodyRoot.classList.remove(isIOS ? 'no-scroll-fixed' : 'no-scroll');
    affectHTMLRoot && htmlRoot.classList.remove('h-100vh-1px');
  }
};
