import React, { memo } from 'react';
import clsx from 'clsx';
import { Text } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { DrawerTogglerProps } from './drawerTogglerTypes';

const DrawerTogglerLayout01: React.FC<DrawerTogglerProps> = ({ text, onClick, isDrawerVisible, className }) => {
  const burgerClass = clsx('burger', isDrawerVisible && 'active', className);

  return (
    <Flex.ColCenter className={burgerClass} onClick={onClick} data-qid="drawerToggler">
      <span className="burger-box">
        <span className="burger-bar" />
      </span>
      {text && <Text className="text-nowrap burger__a-z" text={text} />}
    </Flex.ColCenter>
  );
};

export default memo(DrawerTogglerLayout01);
