import { useEffect } from 'react';
import axios from 'axios';
import userStorage from 'pages/auth/login/UserStorage';
import { useAppDispatch } from 'store';
import { isExpiredIconsSprite } from 'utils/common/helpersCommon';
import { cssGlobalNS } from 'utils/common/types/css.types';
import config from 'utils/config';

const timeStamp = window.config.EPOCH;

const getIconsSprite = (spriteName: string, spriteTimeStampName: string, iconsPath: string) => async () => {
  try {
    const { uiIcons, date } = userStorage.getUIIcons(spriteName, spriteTimeStampName);
    if (uiIcons && !isExpiredIconsSprite(date)) {
      return uiIcons;
    }

    const response = await axios.get(`${config.CDN_URL}/${iconsPath}?v=${timeStamp}`, {
      data: null,
      headers: { 'Content-type': 'image/svg+xml' },
    });

    if (response.data) {
      userStorage.setUIIcons(spriteName, response.data, spriteTimeStampName);
      return response.data;
    }
  } catch (e) {
    return null;
  }
};

export const useCDNIconSprites = (
  spriteName: string,
  spriteTimeStampName: string,
  iconsDiv: string,
  iconsPath: string,
) => {
  const dispatch = useAppDispatch();

  const divId = `${cssGlobalNS}-${iconsDiv}`;

  useEffect(() => {
    const insertSprite = async () => {
      const iconsSprite = await dispatch(getIconsSprite(spriteName, spriteTimeStampName, iconsPath));
      if (iconsSprite)
        document.body.insertAdjacentHTML(
          'afterbegin',
          `<div style="visibility: hidden; position: absolute; width: 0px; height: 0px; overflow: hidden;" aria-hidden="true" id="${divId}">${iconsSprite}</div>`,
        );
    };

    const now = new Date();
    if (!document.getElementById(`${divId}`) || isExpiredIconsSprite(now)) {
      insertSprite();
    }
  }, [dispatch, spriteName, spriteTimeStampName, iconsDiv, iconsPath, divId]);
};
