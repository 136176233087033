import React from 'react';
import ParseHTML from 'shared/common/ParseHTML';

/** NavItem */
interface CopyRightsHTMLProps extends React.HTMLProps<HTMLDivElement> {
  text?: string;
  className?: string;
}

const CopyRightsHTML: React.FC<CopyRightsHTMLProps> = ({ text }) => {
  return (
    <div className="text text-center mb-4 mt-1 pl-3 pr-3">
      <ParseHTML html={text || ''} options={{ trim: false }} />
    </div>
  );
};

export default CopyRightsHTML;
