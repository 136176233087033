import { createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit';
import {
  logoutUser,
  loginUser,
  setDeviceType,
  setIsSingleBetSelection,
} from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';

export type TopNavHeaderStatus = 'visible' | 'collapsed' | 'fixed-collapsed';

type AppHeaderState = {
  isAppHeaderVisible: boolean;
  topNavHeaderStatus: TopNavHeaderStatus;
  appHeaderHeight: number;
  appTopNavHeight: number;
  appMainNavHeight: number;
  hideSearchIcon: boolean;
  isSingleBetSelection: boolean;
};

const initialState: AppHeaderState = {
  isAppHeaderVisible: true,
  topNavHeaderStatus: 'visible',
  appHeaderHeight: 0,
  appTopNavHeight: 0,
  appMainNavHeight: 0,
  hideSearchIcon: false,
  isSingleBetSelection: false,
};

const appHeader = createSlice({
  name: 'appHeader',
  initialState,
  reducers: {
    setAppHeader(state, action) {
      state.isAppHeaderVisible = action.payload;
    },
    setTopNavHeaderStatus(state, action: PayloadAction<TopNavHeaderStatus>) {
      state.topNavHeaderStatus = action.payload;
    },
    setAppHeaderHeight(state, action) {
      state.appHeaderHeight = action.payload;
    },
    setAppTopNavHeight(state, action) {
      state.appTopNavHeight = action.payload;
    },
    setAppMainNavHeight(state, action) {
      state.appMainNavHeight = action.payload;
    },
    setHideSearchIcon(state, action: PayloadAction<boolean>) {
      state.hideSearchIcon = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setIsSingleBetSelection, (state, action) => {
        state.isSingleBetSelection = action.payload;
      })
      .addMatcher(isAnyOf(logoutUser, loginUser, setDeviceType), (state) => {
        state.topNavHeaderStatus = 'visible';
      });
  },
});

export const {
  setAppHeader,
  setTopNavHeaderStatus,
  setAppHeaderHeight,
  setAppTopNavHeight,
  setAppMainNavHeight,
  setHideSearchIcon,
} = appHeader.actions;

export const selectAppHeader = {
  isVisible: (state: RootState): boolean => state.common.appHeader.isAppHeaderVisible,
  height: (state: RootState): number => state.common.appHeader.appHeaderHeight,
  appMainNavHeight: (state: RootState): number => state.common.appHeader.appMainNavHeight,
  appTopNavHeight: (state: RootState): number => state.common.appHeader.appTopNavHeight,
  topNavHeaderStatus: (state: RootState): TopNavHeaderStatus => state.common.appHeader.topNavHeaderStatus,
  hideSearchIcon: (state: RootState): boolean => state.common.appHeader.hideSearchIcon,
  isSingleBetSelection: (state: RootState): boolean => state.common.appHeader.isSingleBetSelection,
};

export default appHeader.reducer;
