import { createSlice } from '@reduxjs/toolkit';
import userStorage from 'pages/auth/login/UserStorage';
import { setLanguage, setTimeZoneOffset, setInitialLanguage } from 'shared/common/sharedSlices/commonActions';
import { AppDispatch } from 'store';
import { RootState } from 'store/rootReducer';
import { getWindowConfigParam } from 'utils/common/helpersCommon';
import { reinitializeDateFormatter } from 'utils/dateUtils';
import { getLocationUrlBasedTimeZone, getTzOffsetFromTzName, checkDaylightSaving } from '../utils/localization.utils';

type InitialState = {
  isUpdated: boolean;
};

const initialState: InitialState = {
  isUpdated: false,
};

const localization = createSlice({
  name: 'localization',
  initialState,
  reducers: {
    setUpdated(state, action) {
      state.isUpdated = action.payload;
    },
  },
});

export const { setUpdated } = localization.actions;

export const selectLocalizationUpdated = (state: RootState): boolean => state.common.localization?.isUpdated;

export const updateLocalizationEnv =
  () =>
  async (dispatch: AppDispatch): Promise<void> => {
    const timeZoneOffset = localStorage.getItem('tz_offset');
    if (isNaN(Number(timeZoneOffset))) {
      localStorage.removeItem('tz_offset');
    }

    const userTzOffset = userStorage.getUserTimeZone();
    const userIanaTimeZone = userStorage.getUserIanaTimeZoneCode();
    const userCurrency = userStorage.getUserCurrency();
    const userLang = userStorage.getUserLang();
    if (!userCurrency) {
      // set default user currency
      const defaultCurrency = getWindowConfigParam('DEFAULT_CURR');
      userStorage.setUserCurrency(defaultCurrency);
    }

    if (!userLang || !userTzOffset || !userIanaTimeZone) {
      dispatch(setUpdated(false));

      const locationBasedTimeZone = await getLocationUrlBasedTimeZone();
      const currentTzOffset = getTzOffsetFromTzName(locationBasedTimeZone.time);
      const lang = locationBasedTimeZone.lang;

      userStorage.setUserLang(lang);
      userStorage.setAutomaticTimeZone({ tz: locationBasedTimeZone.time, tzOffset: currentTzOffset });
      userStorage.setUserTimeZone(currentTzOffset);
      locationBasedTimeZone.time && userStorage.setUserIanaTimeZoneCode(locationBasedTimeZone.time);

      dispatch(setLanguage(lang));
      dispatch(setUpdated(true));
      dispatch(setTimeZoneOffset(currentTzOffset));
    } else {
      checkDaylightSaving();
      dispatch(setUpdated(false));
      dispatch(setUpdated(true));
      dispatch(setTimeZoneOffset(userTzOffset));
      dispatch(setInitialLanguage(userLang));
    }
    reinitializeDateFormatter();
  };
export default localization.reducer;
