import React from 'react';
import { Flex } from 'components/shared/layout';

interface EmptyProps {
  text: string;
  linkText?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const Empty: React.FC<EmptyProps> = ({ text, linkText, onClick }) => {
  return (
    <Flex.ColAC className="mpd-cta-empty">
      <span className="mpd-cta-empty__text">{text}</span>
      <span className="mpd-cta-empty__link" onClick={onClick}>
        {linkText}
      </span>
    </Flex.ColAC>
  );
};

export default Empty;
