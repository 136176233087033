import { setLastPrintClick } from 'modules/retail/modules/cashier/features/lastPrint/slice/lastPrint.slice';
import { reprintInfoThunk } from 'modules/retail/modules/cashier/features/reprintTicket/slice/reprintTicket.slice';
import { resetTicketInfoState } from 'modules/retail/shared/slice/checkTicket.slice';
import { loginThunks, showQuickLoginForm } from 'pages/auth/login/slice/login.slice';
import { AppDispatch } from 'store';
import { LOGIN_FORM_TYPE_AUTH } from 'utils/common/helpersCommon';
import { setDrawer } from '../../drawer/slice/drawer.slice';
import { showRootModal } from '../../rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from '../../rootModal/types/modal.types';

export const retailBurgerHandlersMap = {
  loginCashier: (dispatch: AppDispatch) => () => {
    dispatch(showQuickLoginForm({ status: true, loginActionType: LOGIN_FORM_TYPE_AUTH }));
    dispatch(setDrawer(false));
  },
  logoutCashier: (dispatch: AppDispatch) => () => {
    dispatch(loginThunks.logout());
  },
  showCheckTicketTerminal: (dispatch: AppDispatch) => () => {
    dispatch(
      showRootModal({
        modalType: 'TERMINAL_CHECK_TICKET',
        modalId: 'TERMINAL_CHECK_TICKET',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
  },
  showAnexModal: (dispatch: AppDispatch) => () => {
    dispatch(
      showRootModal({
        modalType: 'ANEX_MODAL',
        modalId: 'ANEX_MODAL',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
  },
  showCashierModal: (dispatch: AppDispatch) => () => {
    dispatch(
      showRootModal({
        modalType: 'CASHIER_MODAL',
        modalId: 'CASHIER_MODAL',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
    dispatch(resetTicketInfoState());
  },
  showFinanceModal: (dispatch: AppDispatch) => () => {
    dispatch(
      showRootModal({
        modalType: 'RETAIL_FINANCE_MODAL',
        modalId: 'RETAIL_FINANCE_MODAL',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
  },
  createVoucher: (dispatch: AppDispatch) => () => {
    dispatch(
      showRootModal({
        modalType: 'TERMINAL_CREATE_VOUCHER',
        modalId: 'TERMINAL_CREATE_VOUCHER1',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
  },
  createRetailVoucher: (dispatch: AppDispatch) => () => {
    dispatch(
      showRootModal({
        modalType: 'CREATE_RETAIL_VOUCHER',
        modalId: 'CREATE_RETAIL_VOUCHER',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
  },
  handleLastPrint: (dispatch: AppDispatch) => () => {
    dispatch(setLastPrintClick(true));
  },
  showCashPayModal: (dispatch: AppDispatch) => () => {
    dispatch(
      showRootModal({
        modalType: 'CASH_PAY_MODAL',
        modalId: 'CASH_PAY_MODAL',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
  },
  showReprintModal: (dispatch: AppDispatch) => () => {
    dispatch(reprintInfoThunk.getTerminal());
    dispatch(reprintInfoThunk.getOperations());
    dispatch(
      showRootModal({
        modalType: 'REPRINT_MODAL',
        modalId: 'REPRINT_MODAL',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
  },
};

// export const loginCashier = (dispatch: AppDispatch) => () => {
//   dispatch(showQuickLoginForm({ status: true, loginActionType: LOGIN_FORM_TYPE_AUTH }));
//   dispatch(setDrawer(false));
// };

// export const logoutCashier = (dispatch: AppDispatch) => () => {
//   dispatch(loginThunks.logout());
// };

// export const showCheckTicketTerminal = (dispatch: AppDispatch) => () => {
//   dispatch(
//     showRootModal({
//       modalType: 'TERMINAL_CHECK_TICKET',
//       modalId: 'TERMINAL_CHECK_TICKET',
//       modalPriority: ModalPriorityEnum.LOW,
//     }),
//   );
// };

// export const showAnexModal = (dispatch: AppDispatch) => () => {
//   dispatch(
//     showRootModal({
//       modalType: 'ANEX_MODAL',
//       modalId: 'ANEX_MODAL',
//       modalPriority: ModalPriorityEnum.LOW,
//     }),
//   );
// };

// export const showCashierModal = (dispatch: AppDispatch) => () => {
//   dispatch(
//     showRootModal({
//       modalType: 'CASHIER_MODAL',
//       modalId: 'CASHIER_MODAL',
//       modalPriority: ModalPriorityEnum.LOW,
//     }),
//   );
//   dispatch(resetTicketInfoState());
// };

// export const showFinanceModal = (dispatch: AppDispatch) => () => {
//   dispatch(
//     showRootModal({
//       modalType: 'RETAIL_FINANCE_MODAL',
//       modalId: 'RETAIL_FINANCE_MODAL',
//       modalPriority: ModalPriorityEnum.LOW,
//     }),
//   );
// };

// export const createVoucher = (dispatch: AppDispatch) => () => {
//   dispatch(
//     showRootModal({
//       modalType: 'TERMINAL_CREATE_VOUCHER',
//       modalId: 'TERMINAL_CREATE_VOUCHER1',
//       modalPriority: ModalPriorityEnum.LOW,
//     }),
//   );
// };

// export const createRetailVoucher = (dispatch: AppDispatch) => () => {
//   dispatch(
//     showRootModal({
//       modalType: 'CREATE_RETAIL_VOUCHER',
//       modalId: 'CREATE_RETAIL_VOUCHER',
//       modalPriority: ModalPriorityEnum.LOW,
//     }),
//   );
// };

// export const handleLastPrint = (dispatch: AppDispatch) => () => {
//   dispatch(setLastPrintClick(true));
// };

// export const showCashPayModal = (dispatch: AppDispatch) => () => {
//   dispatch(
//     showRootModal({
//       modalType: 'CASH_PAY_MODAL',
//       modalId: 'CASH_PAY_MODAL',
//       modalPriority: ModalPriorityEnum.LOW,
//     }),
//   );
// };
