import React, { useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import NoDataMessage from 'components/shared/NoDataMessage';
import { PromotionTypes } from 'pages/promotions/types/promotions.types';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import PrizeDropGiftItem from './PrizeDropGiftItem';
import PrizeDropPromoGiftItemWrapper from './PrizeDropPromoGiftItemWrapper';
import { prizeDropPromoCSSNS } from '../prizeDropUICSS.types';

const itemsCount = isMobile ? 8 : 11;

type Props = { iconResource?: string; rewards: PromotionTypes.RewardsObject<PromotionTypes.Rewards> };

const PrizeDropPromoGiftItemsContainer: React.FC<Props> = ({ iconResource, rewards }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleModalOpen = useCallback(() => {
    dispatch(
      showRootModal({
        modalType: 'PROMO_PD_REWARDS_MODAL',
        modalProps: { iconResource, ...rewards },
        modalPriority: ModalPriorityEnum.LOW,
        modalId: 'PROMO_PD_REWARDS_MODAL',
      }),
    );
  }, [dispatch, rewards]);

  if (isEmpty(rewards?.rewards))
    return (
      <div className={`${prizeDropPromoCSSNS}-inner-shadow`}>
        <NoDataMessage message={t(Promotions.PrizeDrop.noRewards)} />
      </div>
    );

  const rewardsToDisplay = rewards?.rewards?.slice(0, itemsCount);
  const showMore = rewards?.rewards?.length > itemsCount;

  return (
    <PrizeDropPromoGiftItemWrapper
      showMoreClick={handleModalOpen}
      textBtn={t(Promotions.PrizeDrop.seeAll)}
      showMore={showMore}
    >
      {rewardsToDisplay.map((reward, index) => (
        <PrizeDropGiftItem
          key={`${reward.bonusName || ''}-${index}`}
          title={reward.bonusName || ''}
          isActive={!!reward.givenAwards}
          givenAwards={reward.givenAwards}
          totalAwards={reward.totalAwards}
          iconResource={iconResource}
          isPromotion
        />
      ))}
    </PrizeDropPromoGiftItemWrapper>
  );
};

export default PrizeDropPromoGiftItemsContainer;
