import ParallaxLobbyTitle from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/components/headerTitles/ParallaxLobbyTitle';
import FavouritesCategoryTitle from './FavouritesCategoryTitle';
import InnerCategoryTitle from './innerCategoryTitle';
import LobbyTitle from './lobbyTitle';
import ProviderCategoryMobileBreadcrumbTitle from './ProviderCategoryMobileBreadcrumbTitle';
import { CategoryLayoutTypes, CategorySubTypes } from '../../types/CategoryLayoutTypes';

export const TitleComponents = {
  [CategoryLayoutTypes.LOBBY]: LobbyTitle,
  [CategoryLayoutTypes.PROVIDER_CATEGORY]: LobbyTitle,
  [CategoryLayoutTypes.PROVIDER_CATEGORY_MOBILE_LAYOUT]: ProviderCategoryMobileBreadcrumbTitle,
  [CategoryLayoutTypes.INNER_CATEGORY]: InnerCategoryTitle,
  [CategoryLayoutTypes.FAVOURITES_CATEGORY]: FavouritesCategoryTitle,
  [CategorySubTypes.PARALLAX]: ParallaxLobbyTitle,
};
