import styled from 'styled-components';
import { Breakpoints } from 'modules/casino/theme/Theme';

export const SeatsStyled = styled.div`
  width: 60%;
`;
SeatsStyled.displayName = 'SeatsStyled';
export const SeatStyled = styled.svg`
  margin-top: auto;
  height: 12px;

  @media screen and (min-width: 576px) {
    height: 16px;
  }
  @media screen and (min-width: ${Breakpoints.isMobile}px) {
    height: 18px;
  }
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    height: 20px;
  }
  @media screen and (min-width: ${Breakpoints.isDesktopLarge}px) {
    height: 26px;
  }
  // First and Last
  &:first-child,
  &:last-child {
    margin-bottom: 16%;
  }
  // Second and Second from end
  &:nth-child(2),
  &:nth-last-child(2) {
    margin-bottom: 8%;
  }
  // Third and Third from end
  &:nth-child(3),
  &:nth-last-child(3) {
    margin-bottom: 4%;
  }
  // Forth
  &:nth-child(4) {
    margin-bottom: 2%;
  }
`;

SeatStyled.displayName = 'SeatStyled';
