import React from 'react';
import { prizeDropCSSNS } from '../../PrizeDropCellWidgetConstants';

type Props = {
  firstColText: string;
  secondColText: string;
};

const TableRow: React.FC<Props> = ({ firstColText, secondColText }) => {
  return (
    <div className={`${prizeDropCSSNS}-widget__winner d-flex-ac-jb`}>
      <div className={`${prizeDropCSSNS}-widget__winner-name`}>{firstColText}</div>
      <div className={`${prizeDropCSSNS}-widget__winner-prize text-truncate`}>{secondColText}</div>
    </div>
  );
};

export default TableRow;
