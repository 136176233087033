import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCountDown } from 'hooks/common/useCountDown';
import LeaderBoardTimeCounterItem from 'modules/casino/modules/container/widget/widgetTypes/leaderBoardWidget/leaderBoardCellWidget/components/widgetLeaderBoardComponents/LeaderBoardTimeCounterItem';
import { addLeadingZero } from 'utils/dateUtils';
import { SCTimeCounter, SCTimeCounterDisplay } from './styled/CampaignInfoTimeCounter.styled';
import { leaderBoardCSSNS, LeaderBoardWidgetTranslationKeys } from '../../LeaderBoardCellWidgetConstants';

type Props = {
  date: number;
  text: string;
  isColumn?: boolean;
};

const LeaderBoardTimeCounter: React.FC<Props> = ({ date, text, isColumn }) => {
  const { t } = useTranslation();
  const { timer } = useCountDown(date, 'systemTime');

  return (
    <SCTimeCounter $isColumn={isColumn} className={`${leaderBoardCSSNS}-counter-wrap`}>
      <div className={isColumn ? 'mb-1' : 'mr-2'}>{text}</div>
      <SCTimeCounterDisplay>
        {timer?.days !== undefined && (
          <LeaderBoardTimeCounterItem
            date={addLeadingZero(timer.days > 0 ? timer.days : 0)}
            text={t(LeaderBoardWidgetTranslationKeys.days)}
          />
        )}
        {timer?.hours !== undefined && (
          <LeaderBoardTimeCounterItem
            date={addLeadingZero(timer.hours > 0 ? timer.hours : 0)}
            text={t(LeaderBoardWidgetTranslationKeys.hours)}
          />
        )}
        {timer?.minutes !== undefined && (
          <LeaderBoardTimeCounterItem
            date={addLeadingZero(timer.minutes > 0 ? timer.minutes : 0)}
            text={t(LeaderBoardWidgetTranslationKeys.minutes)}
          />
        )}
        {timer?.seconds !== undefined && (
          <LeaderBoardTimeCounterItem
            date={addLeadingZero(timer.seconds > 0 ? timer.seconds : 0)}
            text={t(LeaderBoardWidgetTranslationKeys.seconds)}
          />
        )}
      </SCTimeCounterDisplay>
    </SCTimeCounter>
  );
};

export default LeaderBoardTimeCounter;
