import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { MyProfileBalance, MyProfileBalanceToggler } from 'shared/common/features/myProfile/components';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import { MPDUI } from '../components/MPDUI';

interface Props {
  userBalance: string;
  disableMargin?: boolean;
}

const MyProfileBalanceAmount: React.FC<Props> = ({ userBalance, disableMargin }) => {
  const { t } = useTranslation();

  return (
    <Flex.Col className={`mpd-stat-item ${disableMargin ? 'm-0' : ''}`}>
      <Flex.AC>
        <MPDUI.Text variant="secondary" text={t(MyProfile.Statistics.balanceText)} isTruncated={false} />
        <MyProfileBalanceToggler />
      </Flex.AC>
      <MyProfileBalance amount={userBalance} dataQid="balanceText" />
    </Flex.Col>
  );
};

export default memo(MyProfileBalanceAmount);
