import React, { HTMLAttributes, useCallback } from 'react';
import { SwitchProps } from './Switch.types';
import './Switch.scss';

/** EGTD Switch */
const Switch = React.forwardRef<HTMLInputElement, SwitchProps & HTMLAttributes<HTMLInputElement>>(
  ({ label, name, handleChange, checked, value, className, isActive, isDisabled, ...rest }, ref) => {
    const classProp = className ? { class: className } : undefined;
    const activeProp = isActive ? { 'is-active': isActive } : undefined;
    const disabledProp = isDisabled ? { 'is-disabled': isDisabled } : undefined;
    const onHandleClick = useCallback(() => {
      if (!isDisabled) {
        handleChange && handleChange(value, !checked);
      }
    }, [checked, isDisabled, value, handleChange]);

    return (
      <egtd-p-switch role="input" ref={ref} {...classProp} {...activeProp} {...disabledProp} {...rest}>
        <div className="custom-control ui-switch" onClick={onHandleClick}>
          <input type="checkbox" name={name} className="ui-switch-input" checked={checked} readOnly />
          <label className="ui-switch-label">{label}</label>
        </div>
      </egtd-p-switch>
    );
  },
);

Switch.displayName = 'EGTD Platform Switch';

export default Switch;
