import { useEffect, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store';
import config from 'utils/config';
import { submitFBPageView } from './features/seo/fb/events';

const FBEventTriggerer = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    config.FB_TRACKING_SERVICE_URL && dispatch(submitFBPageView({ eventSourceUrl: window.location.href }));
  }, [location.pathname, dispatch]);
  return <></>;
};

export default memo(FBEventTriggerer);
