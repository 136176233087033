import { useState, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { NotificationTypes } from '../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import { fetchTerms, selectOffersTab } from '../../myProfile/sections/tabs/offersTab';

type ReturnHookType = {
  showTerms: boolean;
  offerTerms?: string;
  outsideClick: () => void;
  fetchBonusTerm: () => void;
};

const useOfferTerms = (bonus?: NotificationTypes.Bonus): ReturnHookType => {
  const [showTerm, setShowTerm] = useState<boolean>(false);

  const offerTerms = useAppSelector(selectOffersTab.termsOfferById(bonus?.termsAndConditionsId));

  const dispatch = useAppDispatch();

  const outsideClick = useCallback(() => {
    setShowTerm(false);
  }, [setShowTerm]);

  const fetchBonusTerm = useCallback(() => {
    bonus &&
      bonus?.termsAndConditionsId &&
      dispatch(
        fetchTerms({
          bonusId: `${bonus?.id}`,
          id: bonus?.termsAndConditionsId,
          isPromotion: true,
        }),
      );
    setShowTerm(true);
  }, [bonus, dispatch]);

  return { fetchBonusTerm, showTerms: !!(showTerm && offerTerms), outsideClick, offerTerms };
};

export default useOfferTerms;
