import React from 'react';
import Icon from 'components/shared/Icon';
import { Flex } from 'components/shared/layout';
import CDNSVG from 'modules/casino/shared/components/UI/CDNSVG/CDNSVG';

export enum MessageVariant {
  default = 'search-alt',
  error = 'search-no-results',
  exclamation = 'exclamation-solid',
  info = 'info-solid',
  lock = 'lock',
}
interface Props {
  variant: MessageVariant;
  title?: string;
  message: string;
  classNames?: string;
}

const SearchMessage: React.FC<Props> = ({ variant, title, message, classNames }) => {
  return (
    <Flex.ColAC className={`mx-auto p-4 text-center search-msg__wrapper ${classNames ? classNames : ''}`}>
      {variant === 'lock' ? (
        <span className="search-msg__icon--pad mb-4">
          <CDNSVG size={48} icon={'common-content/general-ui/lock-screen.svg'} />
        </span>
      ) : (
        <Icon className="search-msg__icon" icon={variant} />
      )}

      {title && <b className="search-msg__title">{title}</b>}
      <span className="search-msg__text">{message}</span>
    </Flex.ColAC>
  );
};

export default SearchMessage;
