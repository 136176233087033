import { memo, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import usePreloadAssets from 'components/shared/FrameAnimator/hooks/usePreloadAssets';
import { mapAnimationAssets } from 'components/shared/FrameAnimator/utils/images/utils';
import { Flex } from 'components/shared/layout';
import { defineAnimationParams } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationUtils';
import { generateRandomNumberArrayFromNumbers, getBusinessUnit } from 'utils/common/helpersCommon';
import EasterEggItems from './EasterEggItems';
import { NotificationTypes } from '../../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import { GamificationUI } from '../../components/GamificationUI';
import useAnimationComplete from '../../hooks/useAnimationComplete';
import { useAutoScrollToDepositButton } from '../../hooks/useAutoScrollToDepositButton';
import useHandleBonusActions from '../../hooks/useHandleBonusActions';
import useOfferTerms from '../../hooks/useOfferTerms';

const EasterEggContainer: React.FC<{ notification: NotificationTypes.PopUpNotification }> = ({ notification }) => {
  const { bonusData, header, message, style } = notification;
  const singleBonus = bonusData?.bonuses?.[0];
  const [isShowModal, setIsShowModal] = useState(style && style.overlay?.isActive);
  const { showTerms, outsideClick, offerTerms, fetchBonusTerm } = useOfferTerms(singleBonus);
  const { animationComplete, setAnimationComplete } = useAnimationComplete();

  useAutoScrollToDepositButton(animationComplete);
  const animationAutoTrigger = !!(style && style.overlay?.isActive && getBusinessUnit() === 'IBBG' && !isShowModal);

  const animationParams = useMemo(
    () =>
      defineAnimationParams({
        animationKey: style.animationKey,
        layout: style.layout,
        folder: style.folder,
        numberOfFrames: style.numberOfFrames,
      }),
    [style.animationKey, style.layout, style.folder, style.numberOfFrames],
  );

  const randomNumber: number = useMemo(
    () => generateRandomNumberArrayFromNumbers(1, style?.folderCounter ?? 1)[0],
    [style.folderCounter],
  );
  const eggAssets = useMemo(
    () => mapAnimationAssets(animationParams, `${randomNumber}/${animationParams.fileBaseName}`),
    [animationParams],
  );

  usePreloadAssets({ module: eggAssets, loadingFinished: true });

  const { handleOfferAction, error, status } = useHandleBonusActions({
    bonus: singleBonus,
    notificationId: notification.id,
  });

  const isModernPhone = useMediaQuery({ query: `(min-width: 360px)` });

  if (!singleBonus) return null;

  return (
    <>
      <GamificationUI.Header
        style={{
          display: 'grid',
          gridTemplateRows: isModernPhone ? '100px 36%' : '100px 43%',
          minHeight: '162px',
          opacity: !isShowModal ? 1 : 0,
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <GamificationUI.Logo
          animationParams={animationParams}
          style={{
            gridRowStart: '1',
            gridColumnStart: 1,
            maxWidth: animationParams.logoWidth + 'px',
            opacity: !isShowModal ? 1 : 0,
          }}
        />
        <GamificationUI.CTAText
          showWinText={animationComplete}
          text={header}
          winText={message}
          error={error}
          className="mt-2"
          textColor={style.headerTextColor}
          style={{
            paddingTop: isModernPhone ? '15px' : '5px',
            paddingBottom: isModernPhone ? '20px' : '5px',
            gridRowStart: 2,
            gridColumnStart: 1,
            alignItems: 'flex-end',
            maxWidth: isModernPhone ? '62%' : '70%',
            margin: '0 auto',
            textAlign: 'center',
            fontSize: '13px',
            minHeight: '18px',
            opacity: !isShowModal ? 1 : 0,
          }}
        />
      </GamificationUI.Header>
      <div style={{ opacity: !isShowModal ? 1 : 0 }}>
        <EasterEggItems
          notification={notification}
          animationComplete={animationComplete}
          animationParams={animationParams}
          setAnimationComplete={setAnimationComplete}
          folderName={randomNumber}
          animationAutoTrigger={animationAutoTrigger}
          bonusAlignment={style?.bonusAlignment}
        />
        {animationComplete && (
          <Flex.ColAC>
            <GamificationUI.CTAButtonBgImage
              disabled={status === 'pending'}
              onClick={handleOfferAction}
              buttonText={singleBonus?.buttonText}
              folder={animationParams.folder}
              bgColor={style.backgroundButton}
              textColor={style.buttonTextColor}
              borderColor={style.buttonBorderColor}
            />
            <GamificationUI.Link
              textColor={style.termsTextColor}
              isActive={showTerms}
              onClick={fetchBonusTerm}
              isVisible={animationComplete}
            />
          </Flex.ColAC>
        )}
      </div>
      <GamificationUI.Terms isVisible={showTerms} onClickOutside={outsideClick} text={offerTerms} />
      {style && style.overlay?.isActive && (
        <GamificationUI.Overlay
          animationParams={animationParams}
          settings={style?.overlay}
          buttonBackground={style?.backgroundButton}
          onHide={() => setIsShowModal(false)}
          styles={style}
        />
      )}
    </>
  );
};

export default memo(EasterEggContainer);
