import { useCallback, useState } from 'react';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { useMarketingKeys } from 'hooks/common/useMarketingKeys';
import { useAppSelector } from 'store';
import LivechatCom from './LivechatCom';
import { SupportChatButtons } from './SupportChatButtons';
import { ChatButtonType } from './types';
import ZendeskChat from './ZendeskChat';
import ZopimChat from './ZopimChat';
import { selectGeneral } from '../features/general/slice/general.slice';
import { LayoutConfigTypes } from '../features/general/types/generalSlice.types';
interface Props {
  text: string;
  type: ChatButtonType;
}

const SupportChat: React.FC<Props> = ({ text, type }) => {
  const { ZOPIM_ENV_KEY, ZENDESK_ENV_KEY, LIVECHATCOM_ENV_KEY, AIVO_KEY } = useMarketingKeys();
  const isOpenChat = useAppSelector(selectGeneral.selectIsOpenChat);
  const [chatLoaded, setChatLoaded] = useState(false);

  const liveChatConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.LIVE_CHAT_CONFIG,
  });
  const isChatButtonVisible = liveChatConfig?.isVisible;

  const onChatLoaded = useCallback(() => {
    setChatLoaded(true);
  }, []);

  if (isChatButtonVisible) return null;

  return (
    <>
      {ZENDESK_ENV_KEY && isOpenChat && <ZendeskChat closeChatWindow={true} onChatLoaded={onChatLoaded} />}
      {ZOPIM_ENV_KEY && isOpenChat && <ZopimChat closeChatWindow={true} onChatLoaded={onChatLoaded} />}
      {LIVECHATCOM_ENV_KEY && isOpenChat && <LivechatCom closeChatWindow={true} onChatLoaded={onChatLoaded} />}
      {!AIVO_KEY && <SupportChatButtons text={text} type={type} isOpenChat={isOpenChat} chatLoaded={chatLoaded} />}
    </>
  );
};
export default SupportChat;
