import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import { useGridLayoutConfig } from 'modules/casino/modules/container/cellsContainer/grid/components/gridCell/hooks/useGridLayoutConfig';
import { imageQuality } from 'modules/casino/shared/constants';
import { LIVE_CASINO_DEFAULT_URL } from '../gameCellMediaConstants';
import { GameMediaTypes } from '../gameCellMediaTypes';
import { createLiveImageSource, createStaticImageSource, getDefaultImage } from '../gameCellMediaUtils';

export const useGameImage = ({
  gameUniqueId,
  categoryId,
  gameMediaRef,
  layoutGrid,
}: GameMediaTypes.UseGameImageProps): {
  imageSources: string[];
} => {
  const { refGameId, providerId, gameLayout, gameResource, liveImageLocation } = useGameProps({
    gameUniqueId,
    categoryId,
  });
  const { cdnPathLayout, cdnPath } = useGridLayoutConfig(layoutGrid);

  const getImageSource = () => {
    const { clientWidth, clientHeight } = gameMediaRef?.current || {};

    if (clientWidth && clientHeight) {
      return liveImageLocation
        ? createLiveImageSource({ liveImageLocation, defaultUrl: LIVE_CASINO_DEFAULT_URL })
        : createStaticImageSource({
            refGameId,
            cdnPathLayout,
            cdnPath,
            providerId,
            imageQuality,
            gameLayout: gameLayout,
            width: clientWidth,
            height: clientHeight,
            liveDefaultImg: gameResource ? LIVE_CASINO_DEFAULT_URL : '',
          });
    }

    return [
      getDefaultImage({
        gameLayout: gameLayout,
        liveDefaultImg: gameResource ? LIVE_CASINO_DEFAULT_URL : '',
      }),
    ];
  };

  return {
    imageSources: getImageSource(),
  };
};
