import React, { HTMLAttributes, memo } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { Breakpoints } from 'theme/Theme';
import { getWindowConfigParam, isEmpty } from 'utils/common/helpersCommon';
import SVGCDN from './UI/SVGCDN/SVGCDN';

const logoTypesSVGMap = {
  default: { fileName: 'app-logo', cssSuffix: 'app-logo--navbar' },
  footer: { fileName: 'app-logo-footer', cssSuffix: 'app-logo--footer' },
  terminal: { fileName: 'app-logo-terminal', cssSuffix: 'app-logo--terminal' },
};

type logoTypes = keyof typeof logoTypesSVGMap;

interface Props extends HTMLAttributes<HTMLImageElement> {
  className?: string;
  type?: logoTypes;
}

const AppLogo: React.FC<Props> = ({ className, type = 'default', ...props }) => {
  const timeStamp = window.config.EPOCH;
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });

  const appLogoConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.APP_LOGO,
  });

  let logoSrcCDN =
    type === 'default'
      ? isDesktop
        ? `${logoTypesSVGMap[type].fileName}--desktop`
        : `${logoTypesSVGMap[type].fileName}`
      : `${logoTypesSVGMap[type].fileName}`;
  const logoTitle = getWindowConfigParam('BRAND_NAME_LONG');

  const logoClass = clsx('app-logo', logoTypesSVGMap[type].cssSuffix, className);

  if (!isEmpty(appLogoConfig)) {
    logoSrcCDN = `${logoSrcCDN}-${appLogoConfig}`;
  }

  return (
    <>
      <SVGCDN
        path="BRAND"
        className={logoClass}
        title={logoTitle}
        icon={logoSrcCDN}
        srcSuffix={`?v=${timeStamp}`}
        {...props}
      />
      {/* <img className={logoClass} src={logoSrc} title={logoTitle} {...props} /> */}
    </>
  );
};

export default memo(AppLogo);
