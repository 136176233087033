import React from 'react';

/** Footer Menu */
interface MenuProps {
  title: string;
  forwardRef?: React.RefObject<HTMLUListElement> | ((node?: Element | null) => void);
  children?: React.ReactNode;
}

const Menu: React.FC<MenuProps> = ({ title, children, forwardRef }) => {
  return (
    <div className="footer-menu">
      <div className="footer-menu__title">{title}</div>
      <ul className="footer-menu__list" ref={forwardRef}>
        {children}
      </ul>
    </div>
  );
};

export default Menu;
