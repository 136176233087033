import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/shared/Modal';
import { ModalUI } from 'components/shared/ModalUI';
import { UI } from 'components/shared/UI';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch } from 'store';

const PaidGameModal: React.FC = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleClose = useCallback(() => {
    dispatch(hideRootModal());
  }, [dispatch]);

  const handlePlay = useCallback(() => {
    window.location.replace(window.location.href.replace('isFreePlay=true', 'isFreePlay=false'));
  }, [dispatch]);

  return (
    <Modal ref={containerRef} visible={true} className="modal-paid-game" size="lg">
      <Modal.Close closeText={t('GameLaunch.inGameModal.closeText')} closeAction={handleClose} />
      <Modal.Body className="d-flex-col-ac text-center" padding="lg">
        <ModalUI.Title hasAccent text={t('GameLaunch.inGameModal.paidGameLaunch')} />
        <ModalUI.Title text={t('GameLaunch.inGameModal.paidGameModalDescription')} />
        <UI.Button size="lg" onClick={handlePlay} text={t('GameLaunch.inGameModal.paidGame')} />
      </Modal.Body>
    </Modal>
  );
};

export default PaidGameModal;
