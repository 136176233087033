import { fitCover } from 'utils/common/cdnConfig';
import config from 'utils/config';
import { imagesConfig } from '../../utils/captainUp.utils';

export const createImageSources = (
  imgName: string,
  width: number,
  height: number,
  quality: number,
  defaultUrl?: string,
): string[] => {
  const uri = `${config.CDN_IMAGE_API}${fitCover(width * quality, height * quality)}/${
    imagesConfig.CAPTAIN_UP_CDN_IMAGE_API
  }${imgName}`;
  const uriDefault = `${config.CDN_IMAGE_API}${fitCover(width * quality, height * quality)}/${
    imagesConfig.CAPTAIN_UP_CDN_IMAGE_API
  }${defaultUrl}`;
  return defaultUrl ? [uri, uriDefault] : [uri];
};
