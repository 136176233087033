import React, { HTMLAttributes, memo } from 'react';
import clsx from 'clsx';
import { SVGLoader } from 'components/shared';
import { Flex } from 'components/shared/layout';
import SVGCDN from 'components/shared/UI/SVGCDN/SVGCDN';
import { isEmpty } from 'utils/common/helpersCommon';

/** Cashier Modal Info Section */
interface InfoMessageProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  infoMessage: string;
  isLoading?: boolean;
  hasError?: boolean;
  icon?: string;
  onClick?: React.MouseEventHandler;
}

const InfoMessage: React.FC<InfoMessageProps> = ({ className, infoMessage, isLoading, hasError, icon, onClick }) => {
  const wrapClass = clsx('cashier-modal__info-message', hasError && 'color--danger', className);
  const handleClick = !isEmpty(onClick) ? onClick : undefined;
  return (
    <Flex.ColCenter className={wrapClass} onClick={handleClick}>
      {hasError ? (
        <SVGCDN path="RETAIL" className="cashier-modal__info-icon" icon={'info'} />
      ) : isLoading ? (
        <SVGLoader className="cashier-modal__info-icon" />
      ) : icon ? (
        <SVGCDN path="RETAIL" className="cashier-modal__info-icon" icon={icon} />
      ) : (
        <></>
      )}

      <span>{infoMessage}</span>
    </Flex.ColCenter>
  );
};

export default memo(InfoMessage);
