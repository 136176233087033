/* eslint-disable prefer-rest-params */
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useAuthentication } from 'hooks/common/useAuthentication';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { useMarketingKeys } from 'hooks/common/useMarketingKeys';
import { useAppSelector } from 'store';
import { isEmpty, localLog } from 'utils/common/helpersCommon';
import { setIsOpenChat } from '../features/general/slice/general.slice';
import { LayoutConfigTypes } from '../features/general/types/generalSlice.types';
import { selectMyProfile } from '../features/myProfile/slice/myProfile.slice';

declare const window: Window &
  typeof globalThis & {
    __lc: any;
    LiveChatWidget: any;
  };
interface Props {
  closeChatWindow: boolean;
  onChatLoaded: () => void;
  maximizeOnLoad?: boolean;
}

const determineGroup = async (email: string, url: string) => {
  try {
    const headers = { 'Content-Type': 'application/json' };
    const response = await axios.post<{ vip: boolean }>(url, { email }, { headers });
    return response.data;
  } catch (error) {
    localLog({ message: error });
  }
};

const LivechatCom: React.FC<Props> = ({ closeChatWindow, onChatLoaded, maximizeOnLoad = true }) => {
  const [group, setGroup] = useState<number | undefined>();
  const userProfile = useAppSelector(selectMyProfile.dataUser);
  const { isAuthenticated } = useAuthentication();
  const { LIVECHATCOM_ENV_KEY } = useMarketingKeys();
  const dispatch = useDispatch();

  const verifyGroupUrl = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.SUPPORT_CHAT,
    deviceLayout: 'general',
  })?.liveChat?.verifyGroup;

  let isHidden = false;

  window.__lc = window.__lc || {};
  window.__lc.license = LIVECHATCOM_ENV_KEY;

  window.__lc.visitor = { name: userProfile?.fullName, email: userProfile?.email };
  window.__lc.params = [
    { name: 'Email', value: userProfile?.email },
    { name: 'Fullname', value: userProfile?.fullName },
  ];

  useEffect(() => {
    if (verifyGroupUrl && userProfile?.email) {
      determineGroup(userProfile?.email, verifyGroupUrl)
        .then((res) => setGroup(res?.vip ? 2 : 0))
        .catch((e) => localLog({ message: e }));
    }
  }, [userProfile?.email, verifyGroupUrl]);

  useEffect(() => {
    if ((verifyGroupUrl && group === undefined && isAuthenticated) || isEmpty(LIVECHATCOM_ENV_KEY)) return;
    if (!window.LiveChatWidget) {
      (function (n, t, c) {
        function i(args) {
          return e._h ? e._h.apply(null, args) : e._q.push(args);
        }
        const e: any = {
          _q: [],
          _h: null,
          _v: '3.0',
          on: function () {
            i(['on', c.call(arguments)]);
          },
          once: function () {
            i(['once', c.call(arguments)]);
          },
          off: function () {
            i(['off', c.call(arguments)]);
          },
          get: function () {
            if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
            return i(['get', c.call(arguments)]);
          },
          call: function () {
            i(['call', c.call(arguments)]);
          },
          init: function () {
            const el = t.createElement('script');
            (el.async = !0),
              (el.type = 'text/javascript'),
              (el.src = 'https://cdn.livechatinc.com/tracking.js'),
              t.body.appendChild(el);
          },
        };
        !n.__lc.asyncInit && e.init(), (n.LiveChatWidget = n.LiveChatWidget || e);
      })(window, document, [].slice);

      window.LiveChatWidget.on('ready', onReady);
      window.LiveChatWidget.on('visibility_changed', onVisibilityChanged);
    }
    maximizeOnLoad && window.LiveChatWidget.call('maximize');
  }, [isAuthenticated, group, verifyGroupUrl, LIVECHATCOM_ENV_KEY]);

  useEffect(() => {
    return () => {
      if (window.LiveChatWidget) {
        window.LiveChatWidget.call('destroy');
      }
    };
  }, []);

  const onReady = () => {
    onChatLoaded();
    //!isHidden && closeChatWindow && window.LiveChatWidget.call('hide');
  };

  const onVisibilityChanged = (data) => {
    switch (data.visibility) {
      case 'maximized':
        isHidden = false;
        break;
      case 'minimized':
        dispatch(setIsOpenChat(false));
        !isHidden && closeChatWindow && window.LiveChatWidget.call('hide');
        break;
      case 'hidden':
        isHidden = true;
        break;
    }
  };

  return null;
};
export default LivechatCom;
