import React, { CSSProperties, RefObject } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import Icon from 'components/shared/Icon';
import { Flex } from 'components/shared/layout';

interface ModalProps {
  children?: React.ReactNode;
  visible?: boolean;
  isFullScreen?: boolean;
  isPartial?: boolean;
  className?: string;
  size?: 'md' | 'lg' | 'xl' | 'xxl' | 'gamification' | 'terms' | 'prize-drop';
  style?: CSSProperties;
  dialogStyle?: CSSProperties;
  dialogClassName?: string;
  contentStyle?: { maxHeight?: string; top?: string };
  scrollable?: boolean;
}

const Modal = React.forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      visible,
      children,
      isFullScreen = false,
      isPartial = false,
      className,
      size,
      style,
      dialogStyle,
      dialogClassName,
      contentStyle,
      scrollable,
    },
    ref,
  ) => {
    const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });
    const modalClass = clsx('modal fade', visible && 'show d-block', isPartial && 'modal--partial');
    const modalBackdropClass = clsx(
      'modal-backdrop',
      scrollable && 'scrollable',
      isPartial && 'modal-backdrop--partial',
    );
    const modalDialogClass = clsx('modal-dialog', size && `modal-dialog-${size}`, dialogClassName && dialogClassName);
    const modalContentClass = clsx([
      'modal-content',
      isFullScreen && 'full-screen',
      isLandscape ? 'landscape' : 'portrait',
      className,
    ]);

    return (
      <div className={modalClass} style={style} tabIndex={-1} role="dialog" ref={ref}>
        <div className={modalBackdropClass}>
          <div className={modalDialogClass} style={dialogStyle} role="document">
            <div className={modalContentClass} style={contentStyle}>
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

Modal.displayName = 'Modal';

interface CloseProps {
  closeText: string;
  closeAction?: (event: React.MouseEvent<HTMLElement>) => void;
  style?: CSSProperties;
  className?: string;
}

const Close: React.FC<CloseProps> = ({ closeText, closeAction, style, className }) => {
  return (
    <Flex.AC className={`modal-close ${className}`} data-cy="close-modal">
      <Flex.AC className="ml-auto modal-close__btn" onClick={closeAction} style={style}>
        <span>{closeText}</span>
        <Icon className="modal-close__icon" icon="times" />
      </Flex.AC>
    </Flex.AC>
  );
};

interface HeaderProps {
  children?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ children }) => {
  return <Flex.AC className="modal-header">{children}</Flex.AC>;
};
const Header_2: React.FC<HeaderProps> = ({ children }) => {
  return <Flex.AC className="modal-header-2">{children}</Flex.AC>;
};

interface BodyProps {
  className?: string;
  padding?: 'lg' | 'xl';
  removeBottomRadius?: boolean;
  bodyRef?: RefObject<HTMLDivElement>;
  children?: React.ReactNode;
  style?: CSSProperties;
}

const Body: React.FC<BodyProps> = ({ className, padding, children, removeBottomRadius, bodyRef, style }) => {
  const modalBodyClass = clsx(
    'modal-body',
    padding === 'lg' && 'modal-body--lg',
    padding === 'xl' && 'modal-body--xl',
    removeBottomRadius && 'modal-body--no-bottom-br',
    className,
  );
  return (
    <div className={modalBodyClass} ref={bodyRef} style={style}>
      {children}
    </div>
  );
};

const ModalNamespace = Object.assign(Modal, { Close, Header, Header_2, Body });

export { ModalNamespace as Modal };
