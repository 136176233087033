import { LayoutConfigInnerLayoutTypes } from 'modules/casino/shared/constants';
import { useCasinoLayoutConfig } from 'modules/casino/shared/hooks/useCasinoLayoutConfig';

type PrizeDropConfigType = () => {
  enableGameTagIcon: boolean | undefined;
};

export const useBonusGamesConfig: PrizeDropConfigType = () => {
  const config = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.BONUS_GAMES_API });
  const enableGameTagIcon = config && config?.enableGameTagIcon;

  return {
    enableGameTagIcon,
  };
};
