import { CategoryQueryType } from 'modules/casino/shared/constants';
import { useSvaraLaunchParam } from './useSvaraLaunchParam';
import { GamePlayType } from '../casinoLaunchTypes';

type AdditionalParams = {
  refGameId?: string;
  svaraParams?: {
    gamePlayType?: GamePlayType;
    isFreeCustom?: boolean;
    isPrivate?: boolean;
    isCreate?: boolean;
  };
};

export const useAdditionalLaunchParams = (
  queryType: CategoryQueryType,
): {
  getAdditionalLaunchParams: (params?: AdditionalParams) => string;
} => {
  const { getSvaraLaunchParam } = useSvaraLaunchParam(queryType);
  const getAdditionalLaunchParams = (params?: AdditionalParams) => {
    if (queryType === CategoryQueryType.SVARA_CATEGORY || params?.refGameId === 'svara') {
      const svaraGameType = getSvaraLaunchParam(params!.svaraParams!);
      return `&svara=${svaraGameType}`;
    }
    return '';
  };

  return { getAdditionalLaunchParams };
};
