import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppSelector } from 'store';
import { CategoryFilterUI } from './CategoryFilterUI';
import { useProviderDataByKey } from '../../casinoGameProviders/hooks/useProviderDataByKey';
import { gameProvidersSelectors } from '../../casinoGameProviders/slice/casinoGameProvidersSlice';

const CategoryScroll = ({ scrollTags }) => {
  const { t } = useTranslation();
  const [activeScrollTag] = useState('');
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const activeProviderAlias = useAppSelector(gameProvidersSelectors.activeProvider);
  const { scrollToCategory: isScrollToCategoryVisible } = useProviderDataByKey(activeProviderAlias);

  const handleTagClickSelect = (e, tag) => {
    const violation = document.getElementById(tag.id);

    violation &&
      window.scrollTo({
        top: violation.offsetTop,
        behavior: 'smooth',
      });
  };

  return isScrollToCategoryVisible && scrollTags.length ? (
    <CategoryFilterUI.Wrapper desktopText={t(`Casino.CategoryBreadcrumb.sortByText`)}>
      {scrollTags?.map((tag, index) => {
        return (
          <CategoryFilterUI.MenuItem
            key={index}
            onClick={(e) => handleTagClickSelect(e, tag)}
            dataClass={tag.name}
            dataSort={tag.id}
            isActive={activeScrollTag.trim() === tag.name}
            text={tag.name}
            hideItem={!isAuthenticated && !tag.isPublic}
          />
        );
      })}
    </CategoryFilterUI.Wrapper>
  ) : (
    <></>
  );
};

export default CategoryScroll;
