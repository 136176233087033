import { useEffect } from 'react';
import { casinoSearchSelectors } from 'modules/casino/shared/features/casinoSearch/casinoSearchSlice';
import { useShowAppFooter } from 'modules/casino/shared/hooks/useShowAppFooter';
import { selectFooter } from 'shared/common/features/appFooter/slice/appFooter.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { ProvidersBarPosition } from '../../../shared/features/casinoGameProviders/constants/casinoGameProvidersConstants';
import { useProvidersBarPositionConfig } from '../../../shared/features/casinoGameProviders/hooks/useProvidersBarPositionConfig';
import {
  gameProvidersSelectors,
  setShowBottomProvidersBar,
} from '../../../shared/features/casinoGameProviders/slice/casinoGameProvidersSlice';
import { CategoryLayoutTypes } from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';

type Props = {
  isInfinityScrollCollection: boolean;
  stopLoading: boolean;
  isCategoryLast?: boolean;
  categoryLayout?: CategoryLayoutTypes;
};

const CategoryLayoutFooterHandler: React.FC<Props> = ({
  categoryLayout,
  isCategoryLast,
  stopLoading,
  isInfinityScrollCollection,
}) => {
  const dispatch = useAppDispatch();
  const isAppFooterVisible = useAppSelector(selectFooter.isVisible);
  const showBottomProvidersBar = useAppSelector(gameProvidersSelectors.showBottomProvidersBar);

  const headerSearchIsActive = useAppSelector(casinoSearchSelectors.headerSearchIsActive);
  const providerSearchIsActive = useAppSelector(casinoSearchSelectors.providerSearchIsActive);
  const categorySearchIsActive = useAppSelector(casinoSearchSelectors.categorySearchIsActive);
  const searchIsActive = headerSearchIsActive || providerSearchIsActive || categorySearchIsActive;
  const providersBarPositionConfig = useProvidersBarPositionConfig();

  useEffect(() => {
    if (providersBarPositionConfig === ProvidersBarPosition.TOP) {
      return;
    }

    if (categoryLayout === CategoryLayoutTypes.PROVIDER_CATEGORY || categoryLayout === CategoryLayoutTypes.LOBBY) {
      const showProvidersBar = isCategoryLast
        ? isInfinityScrollCollection
          ? stopLoading
          : true
        : showBottomProvidersBar;

      dispatch(setShowBottomProvidersBar(showProvidersBar));
      return;
    }

    if (isInfinityScrollCollection) {
      dispatch(setShowBottomProvidersBar(stopLoading));
      return;
    }

    // dispatch(setShowBottomProvidersBar(true));
  }, [dispatch, providersBarPositionConfig, categoryLayout, isCategoryLast, isInfinityScrollCollection, stopLoading]);

  const showFooterHandler = () => {
    if (searchIsActive) return false;

    if (categoryLayout === CategoryLayoutTypes.PROVIDER_CATEGORY || categoryLayout === CategoryLayoutTypes.LOBBY) {
      return isCategoryLast ? (isInfinityScrollCollection ? stopLoading : true) : isAppFooterVisible || false;
    }

    if (isInfinityScrollCollection) {
      return stopLoading;
    }

    return true;
  };

  useShowAppFooter([showFooterHandler(), searchIsActive ? false : true]);

  return null;
};

export default CategoryLayoutFooterHandler;
