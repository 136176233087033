import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InGameFavouriteGames from 'modules/casino/modules/container/cellsContainer/containers/inner/components/containerTypes/favourites/components/InGameFavouriteGames';
import { gridLayoutSettingsSelectors } from 'modules/casino/shared/components/gridLayoutSettings/gridLayoutSettingsSlice';
import { useGridLayoutSettings } from 'modules/casino/shared/components/gridLayoutSettings/hooks/useGridLayoutSettings';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { RequestStatuses } from 'pages/help/helpTypes';
import { useAppSelector } from 'store';
import { TopBarButton } from '../TopBarButton';
import { TopBarPopover } from '../TopBarPopover';

type Props = {
  type: string;
  text?: string;
  icon?: string;
  isFreePlay: boolean;
};

export const FavouritesButton = ({ isFreePlay, type, text, icon }: Props) => {
  const { t } = useTranslation();
  const { showGameCellFavButton } = useGridLayoutSettings();
  const settingsFetchSuccess = useAppSelector(gridLayoutSettingsSelectors.fetchStatus) === RequestStatuses.SUCCESS;
  const [showCat, setShowCat] = useState(false);

  const handleClick = () => {
    setShowCat(true);
  };

  return (
    <TopBarButton
      disabled={!(settingsFetchSuccess && showGameCellFavButton)}
      type={type}
      handleClick={handleClick}
      icon={icon}
      text={text && t(text)}
      restrictForFreePlay={isFreePlay}
      renderChildren={(btnRef, handleClose, show) =>
        showCat &&
        show && (
          <TopBarPopover handleClose={handleClose} parentRef={btnRef} show={show}>
            <div className={`${cssGlobalNS}-gl__popover__search--title`}>{t('GameLaunch.Favourites.title')}</div>
            <InGameFavouriteGames />
          </TopBarPopover>
        )
      }
    />
  );
};
