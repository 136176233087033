import React, { ReactNode } from 'react';
import { ButtonGroupControl, ButtonGroupNames } from './formButtonGroup.types';
import FormSocial from '../FormSocial/FormSocial';
import { SocialButtonAuthTypes } from '../FormSocial/types/formSocial.types';

const authMap = {
  reg: SocialButtonAuthTypes.REGISTER,
  login: SocialButtonAuthTypes.LOGIN,
};

const FormButtonGroupControl: React.FC<ButtonGroupControl> = ({
  name,
  button,
  inputType,
  componentLocation,
  disabled,
}): ReactNode | null => {
  switch (name) {
    case ButtonGroupNames.SOCIAL:
      return (
        <FormSocial
          button={{ ...button, inputType, authType: componentLocation && authMap[componentLocation] }}
          disabled={disabled}
        />
      );
    default:
      return null;
  }
};

export default FormButtonGroupControl;
