import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { PlayerInfoThunkType, PlayerInfoTypes, RejectedAction, SavePlayerInfoAction } from '../type/playerInfo.type';

const initialState: PlayerInfoTypes = {
  playerInfo: null,
  playerInfoStatus: 'idle',
  playerInfoErrorMessage: '',
  savePlayerInfo: null,
  savePlayerInfoStatus: 'idle',
  savePlayerError: '',
};

export const playerInfoThunk: PlayerInfoThunkType = {
  getPlayerInfo: createAbortThunk(`cashier/playerInfo`, async ({ ucn }, { rejectWithValue }) => {
    try {
      const url = `${config.API_URL}/api/pretail/agent/v2/ucn-player/get`;
      const response = await axiosInstance.post(url, { ucn: ucn });
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseData(err));
    }
  }),
  savePlayerInfo: createAbortThunk(
    `cashier/savePlayerInfo`,
    async ({ ucn, firstName, middleName, lastName }, { rejectWithValue }) => {
      try {
        const url = `${config.API_URL}/api/pretail/agent/v2/ucn-player/add`;
        const response = await axiosInstance.post(url, {
          ucn: ucn,
          firstName: firstName,
          middleName: middleName,
          lastName: lastName,
        });
        return response.data;
      } catch (err) {
        return rejectWithValue(getError.responseData(err));
      }
    },
  ),
};

const playerInfoSlice = createSlice({
  name: 'playerInfoSlice',
  initialState,
  reducers: {
    clearSaveInfoPlayer: (state) => {
      state.savePlayerInfo = null;
      state.savePlayerInfoStatus = 'idle';
      state.savePlayerError = '';
    },
    clearGetInfoPlayer: (state) => {
      state.playerInfo = null;
      state.playerInfoStatus = 'idle';
    },
    clearPlayerInfoErrorMessage: (state) => {
      state.playerInfoErrorMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(playerInfoThunk.getPlayerInfo.fulfilled, (state, action) => {
      state.playerInfo = action.payload;
      state.playerInfoStatus = 'succeeded';
      state.playerInfoErrorMessage = '';
    });
    builder.addCase(playerInfoThunk.getPlayerInfo.pending, (state) => {
      state.playerInfoStatus = 'pending';
    });
    builder.addCase(playerInfoThunk.getPlayerInfo.rejected, (state, action: RejectedAction) => {
      state.playerInfoErrorMessage = action?.payload?.errorCode;
      state.playerInfo = null;
      state.playerInfoStatus = 'failure';
    });
    builder.addCase(playerInfoThunk.savePlayerInfo.fulfilled, (state, action: SavePlayerInfoAction) => {
      state.savePlayerInfo = action.payload.proxiedResponse;
      state.savePlayerInfoStatus = 'succeeded';
      state.playerInfoErrorMessage = '';
    });
    builder.addCase(playerInfoThunk.savePlayerInfo.pending, (state) => {
      state.savePlayerInfoStatus = 'pending';
    });
    builder.addCase(playerInfoThunk.savePlayerInfo.rejected, (state, action: any) => {
      state.savePlayerError = action.payload?.errorCode;
      state.savePlayerInfo = null;
      state.savePlayerInfoStatus = 'failure';
    });
  },
});
export const { clearSaveInfoPlayer, clearGetInfoPlayer, clearPlayerInfoErrorMessage } = playerInfoSlice.actions;
export default playerInfoSlice.reducer;
export const selectPlayerInfo = {
  playerInfo: (state: RootState) => state.retail.ucnPlayerInfo.playerInfo,
  playerInfoStatus: (state: RootState) => state.retail.ucnPlayerInfo.playerInfoStatus,
  playerInfoErrorMessage: (state: RootState) => state.retail.ucnPlayerInfo.playerInfoErrorMessage,
  savePlayerInfo: (state: RootState) => state.retail.ucnPlayerInfo.savePlayerInfo,
  savePlayerInfoStatus: (state: RootState) => state.retail.ucnPlayerInfo.savePlayerInfoStatus,
  savePlayerErrorCode: (state: RootState) => state.retail.ucnPlayerInfo.savePlayerError,
};
