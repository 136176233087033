import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import TickerSingleItem from './TickerSingleItem';
import { TickerItem } from './types/ticker.types';

interface Props {
  mostPlayed: TickerItem;
}

const TickerSingleItemDefault: React.FC<Props> = ({ mostPlayed }) => {
  const navigate = useNavigate();
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      mostPlayed?.route !== window.location.pathname && navigate(mostPlayed?.route);
    },
    [mostPlayed?.route, navigate],
  );

  return <TickerSingleItem mostPlayed={mostPlayed} onClickHandler={handleClick} />;
};

export default memo(TickerSingleItemDefault);
