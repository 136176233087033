import { useCallback } from 'react';
import userStorage from 'pages/auth/login/UserStorage';
import { useAppDispatch } from 'store';
import { notificationThunks, setActivePopupNotificationId } from '../notificationSlice';

const useNotificationStorageMethods = () => {
  const dispatch = useAppDispatch();

  const handleStorageEvent = useCallback(
    (event: StorageEvent) => {
      if (event.storageArea !== localStorage) return;
      if (event.key === 'popup_notification_id') {
        dispatch(notificationThunks.fetchPopUpGroupedNotification());
      }
    },
    [dispatch],
  );

  const clearNotificationId = useCallback(() => {
    dispatch(setActivePopupNotificationId(''));
    userStorage.setUserPopupNotificationId('');
  }, [dispatch]);

  return { clearNotificationId, handleStorageEvent };
};

export default useNotificationStorageMethods;
