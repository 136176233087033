import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { InputLocationType, RefType } from 'components/shared/forms/formTypes';
import FormGroup from './FormGroup';
import { FormUI } from './FormUI';

interface Props {
  label?: string;
  name: string;
  onChange: (v?) => void;
  onBlur?: () => void;
  errorMessage?: string;
  link?: string;
  linkLabel?: string;
  linkType?: 'external';
  links?: { label?: string; link?: string; linkLabel?: string }[];
  componentLocation?: InputLocationType;
  classNames?: string;
  position?: 'left' | 'right';
  fieldLabel?: boolean;
  controlRef?: RefType;
  value: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  isHidden?: boolean;
}

const LabelLink = ({
  label,
  link,
  linkLabel,
  linkType,
  errorMessage,
}: {
  label?: string;
  link?: string;
  linkLabel?: string;
  linkType?: 'external';
  errorMessage?: string;
}): JSX.Element => {
  const { t } = useTranslation();

  const labelClass = clsx('switch-custom-link', errorMessage && 'has-error');

  if (linkType === 'external') {
    return (
      <>
        {t(`${label}`)}{' '}
        {link && (
          <a href={link} target="_blank" className={labelClass} rel="noreferrer">
            {t(`${linkLabel}`)}
          </a>
        )}
      </>
    );
  }

  return (
    <>
      {t(`${label}`)}{' '}
      {link && (
        <Link className={labelClass} to={link} target="_blank">
          {t(`${linkLabel}`)}
        </Link>
      )}
    </>
  );
};

const FormSwitch: React.FC<Props> = ({
  label,
  name,
  onChange,
  onBlur,
  errorMessage,
  link,
  linkLabel,
  linkType,
  links,
  componentLocation,
  classNames,
  position = 'left',
  fieldLabel,
  controlRef,
  value,
  disabled,
  readOnly,
  isHidden,
}) => {
  const isLeft = position === 'left';
  const switchWrapperStyles = clsx(
    'fcw fcw-switch',
    (disabled || readOnly) && 'disabled',
    errorMessage && 'has-error bg--error',
  );
  const switchStyles = clsx('form-group-switch', !isLeft && 'form-control d-flex-ac-jb');

  const handleChange = () => {
    if (!disabled || !isHidden) {
      onChange(!value);
      onBlur && onBlur();
    }
  };

  if (isHidden) {
    return (
      <FormGroup>
        {!isLeft && <label>&#xfeff;</label>}
        {isLeft && (
          <label>
            {links ? (
              links.map((lnk) => <LabelLink key={lnk.label} {...lnk} errorMessage={errorMessage} />)
            ) : (
              <LabelLink
                label={label}
                link={link}
                linkLabel={linkLabel}
                linkType={linkType}
                errorMessage={errorMessage}
              />
            )}
          </label>
        )}
      </FormGroup>
    );
  }

  return (
    <FormGroup className={classNames}>
      {label && fieldLabel && <FormUI.Label componentLocation={componentLocation} label={label} />}
      <div className={switchWrapperStyles}>
        <div className={switchStyles} onClick={handleChange}>
          {!isLeft && label}
          <div className="custom-control custom-switch">
            <input
              ref={controlRef}
              type="checkbox"
              className="custom-control-input"
              name={name}
              checked={value}
              onChange={handleChange}
              onBlur={onBlur}
              aria-label={name}
              data-qid={name}
              readOnly={readOnly}
              disabled={disabled}
            />
            {!isLeft && <label className="custom-control-label">&#xfeff;</label>}
            {isLeft && (
              <label className="custom-control-label">
                {links ? (
                  links.map((lnk) => <LabelLink key={lnk.label} {...lnk} errorMessage={errorMessage} />)
                ) : (
                  <LabelLink
                    label={label}
                    link={link}
                    linkLabel={linkLabel}
                    linkType={linkType}
                    errorMessage={errorMessage}
                  />
                )}
              </label>
            )}
          </div>
        </div>
        {errorMessage && <FormUI.Message type="error" content={errorMessage} />}
      </div>
    </FormGroup>
  );
};

export default FormSwitch;
