import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import useGeneralCasinoType from 'modules/casino/shared/hooks/useGeneralCasinoType';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { useGetCategoryPosition } from 'modules/casino/shared/hooks/useGetCategoryPosition';
import { getDeviceType } from 'modules/casino/shared/utils/common/helpersCommon';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppSelector } from 'store';
import { CategoryImpressionsTypes } from '../types/categoryImpressionsTypes';

type Props = {
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
};

export const useGetEventParams = ({
  categoryId,
  categoryLayoutType,
}: Props): CategoryImpressionsTypes.CategoryImpressionsRegisteredParams => {
  const { casinoType } = useGeneralCasinoType();
  const { id, title, trackingTemplate } = useGetCategoryParams(categoryId, categoryLayoutType);
  const { categoryPosition } = useGetCategoryPosition(categoryId, categoryLayoutType);
  const playerId = useAppSelector(selectMyProfile.playerId);
  const userId = playerId ? { userId: playerId } : {};

  return {
    trackingTemplate: trackingTemplate || '',
    categoryId: id,
    categoryName: title,
    categoryRank: categoryPosition ? categoryPosition + 1 : 1,
    categoryRoot: casinoType,
    device: getDeviceType(),
    items: [],
    ...userId,
  };
};
