import { useEffect } from 'react';
import { RequestStatuses } from 'modules/casino/shared/constants';
import { useAppDispatch, useAppSelector } from 'store';
import { fetchBonusGames, gamesBonusSelectors } from '../slice/gameLaunchBonus.slice';

const useBonusGamesFetch = (isAuthenticated: boolean | null, isFreePlay: string) => {
  const dispatch = useAppDispatch();
  const fetchStatus = useAppSelector(gamesBonusSelectors.bonusGamesFetchStatus);

  useEffect(() => {
    if (!isAuthenticated || isFreePlay === 'true' || fetchStatus === RequestStatuses.PENDING) return;

    dispatch(fetchBonusGames());
  }, [dispatch, isAuthenticated, isFreePlay]);
};

export default useBonusGamesFetch;
