export declare module PrizeDropTypes {
  type StateStatus = 'pending' | 'idle' | 'empty' | 'succeeded' | 'failure';

  type State = {
    activeCampaignId: number;
    normalizedCampaigns: Record<number, Campaign>;
    campaignsIds: number[];
    rewards: Record<number, RewardsObject<Rewards>>;
    rewardedPlayers: Record<number, RewardsObject<PersonalRewards>>;
    personalRewards: Record<number, RewardsObject<PersonalRewards>>;
    termsAndConditions: Record<number, Terms | null>;
    campaignsStatus: Record<number, StateStatus>;
    status: StateStatus;
  };

  type RewardsObject<T extends Rewards | PersonalRewards> = {
    title: string;
    text: string;
    rewards: T[];
  };

  type CampaignsAPI = {
    rewards: RewardsObject<Rewards>;
    rewardedPlayers: RewardsObject<PersonalRewards>;
    personalRewards: RewardsObject<PersonalRewards>;
    termsAndConditions: Terms | null;
  };

  type Campaign = {
    id: number;
    title: string;
    isActive: boolean;
    startDate: number;
    endDate: number;
    iconResource?: string;
  };

  type Rewards = {
    bonusName: string;
    bonusCode: string;
    totalAwards: number;
    givenAwards: number;
  };

  type PersonalRewards = {
    bonusName: string;
    bonusCode: string;
    playerId: string;
    username: string;
    givenDate: string;
  };

  type Terms = {
    text: string;
    title: string;
    titleTextColor: string;
    color: string;
    ids?: number[];
  };
}

export enum PrizeDropStatus {
  SOON = 'soon',
  ACTIVE = 'active',
  ENDED = 'ended',
}
