import smoothscroll from 'smoothscroll-polyfill';

const isPadding = (scrollEl: HTMLElement) => {
  const currentPosition = scrollEl.scrollLeft;
  const lastElement = scrollEl.lastElementChild as HTMLElement;

  return currentPosition <= 0 ||
    currentPosition + scrollEl.clientWidth >= lastElement?.offsetLeft + lastElement?.offsetWidth
    ? true
    : false;
};

export const handleShowArrows = (scrollEl: HTMLElement) => {
  const currentPosition = scrollEl.scrollLeft;
  const showRightArrow = currentPosition + scrollEl.clientWidth === scrollEl.scrollWidth ? false : true;
  const showLeftArrow = currentPosition <= 0 ? false : true;

  return { showLeftArrow, showRightArrow };
};

export const scrollWithDirection = (scrollEl: HTMLElement, left: boolean, moveWith: number) => {
  const addPadding = isPadding(scrollEl);
  let gridColWidth = window.getComputedStyle(scrollEl).getPropertyValue('grid-template-columns').split('px')[0];
  if (window.getComputedStyle(scrollEl).getPropertyValue('grid-template-columns') === 'none') {
    gridColWidth = window.getComputedStyle(scrollEl.children[0]).getPropertyValue('width').split('px')[0];
  }

  const gridPadding = window.getComputedStyle(scrollEl).getPropertyValue('padding').split('px')[1];
  const gridGap = window.getComputedStyle(scrollEl).getPropertyValue('grid-gap').split('px')[0];
  const scrollStep = moveWith
    ? moveWith
    : 2 * (Number(gridColWidth) + Number(gridGap)) + (addPadding ? Number(gridPadding) : 0);

  const currentPosition = scrollEl.scrollLeft;
  const moveWithPixes = left ? -scrollStep : scrollStep;
  const targetPosition = currentPosition + moveWithPixes;
  smoothscroll.polyfill();
  scrollEl.scrollTo({ behavior: 'smooth', left: targetPosition });
};

export const centerScroll = (scrollEl: HTMLElement) => {
  const lastElement = scrollEl.lastElementChild as HTMLElement;
  const left = (lastElement.offsetLeft + lastElement.clientWidth - scrollEl.clientWidth) / 2;
  smoothscroll.polyfill();
  scrollEl.scrollTo({ behavior: 'smooth', left });
};
