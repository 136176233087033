import { useState, useEffect } from 'react';

/**
 * Use to eliminate loader flashing, by artificially prolonging the loader.
 * The hook will return false only after the minTime has passed and isLoading becomes false
 */
export const useProlongedLoading = (isLoading: boolean, minTime = 100) => {
  const [isProlongedLoading, setIsProlongedLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setIsProlongedLoading(true);

      setTimeout(() => {
        setIsProlongedLoading(false);
      }, minTime);
    }
  }, [isLoading]);

  return isLoading || isProlongedLoading;
};
