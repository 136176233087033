import { memo } from 'react';
import isEqual from 'lodash/isEqual';
import ControlWrapper from './ControlWrapper';
import Description from './Description';
import Label from './Label';
import LabelSecondary from './LabelSecondary';
import Message from './Message';
import Row from './Row';
import Status from './Status';

export const FormUI = {
  Row: memo(Row, isEqual),
  Label: memo(Label),
  Description: memo(Description),
  ControlWrapper: memo(ControlWrapper, isEqual),
  LabelSecondary: memo(LabelSecondary),
  Status: memo(Status),
  Message: Message,
};
