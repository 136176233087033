import { useEffect, useCallback } from 'react';
import { useMyProfileConfig } from 'shared/common/features/myProfile/hooks/useMyProfileConfig';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { CustomPlatformEvents } from 'shared/common/hooks/useHandleCustomEvents';
import { useAppDispatch } from 'store';
import axiosInstance from 'utils/common/axios-instance';
import { localLog } from 'utils/common/helpersCommon';

export const fetchAppVersion = async (): Promise<{ app_version: string } | void> => {
  try {
    const response = await axiosInstance.get(`${window.location.origin}/v.json`);
    return response.data;
  } catch (err) {
    localLog({ message: `appVersion ${JSON.stringify(err)}`, type: 'error' });
  }
};

const DEFAULT_POLLING_INTERVAL = 1000 * 60 * 10;
let appVersion: string;

export const useAppVersionCheck = (): void => {
  const dispatch = useAppDispatch();
  const pollingIntervalConfig = useMyProfileConfig()?.appVersionCheckInterval;

  const pollingInterval = pollingIntervalConfig ? pollingIntervalConfig * 1000 * 60 : DEFAULT_POLLING_INTERVAL;

  const onVersionChange = useCallback(() => {
    document.dispatchEvent(new Event(CustomPlatformEvents.HIGH_PRIORITY_MODAL));
    dispatch(
      showRootModal({
        modalType: 'NEW_VERSION_MODAL',
        modalPriority: ModalPriorityEnum.HIGH,
        modalProps: { titlePhrase: 'newVersionText' },
        modalId: 'NEW_VERSION_MODAL',
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    axiosInstance
      .get(`/v.json`)
      .then((response) => (appVersion = response?.data?.app_version))
      .catch((err) => localLog({ message: `appVersion ${JSON.stringify(err)}`, type: 'error' }));
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      const currentpath = window.location.pathname;
      if (currentpath === '/sports/live-program') {
        return;
      }
      const newAppVersion = await fetchAppVersion();
      if (newAppVersion && newAppVersion?.app_version !== appVersion) {
        onVersionChange();
      }
    }, pollingInterval);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, onVersionChange, pollingInterval]);
};
