import { useCallback, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import GameInfoModalImage from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameInfoModal/components/GameInfoModalImage';
import { GameInfoModalPlayBtns } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameInfoModal/components/GameInfoModalPlayBtns';
import { SharedComponents } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/index';
import { Flex } from 'modules/casino/shared/components/layout';
import { Modal } from 'modules/casino/shared/components/modal/Modal';
import { LayoutConfigInnerLayoutTypes } from 'modules/casino/shared/constants';
import { GamePlayType } from 'modules/casino/shared/features/gameLaunch/casinoLaunchTypes';
import { hideRootCasinoModal } from 'modules/casino/shared/features/rootCasinoModal/rootCasinoModal.slice';
import { useCasinoLayoutConfig } from 'modules/casino/shared/hooks/useCasinoLayoutConfig';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { GridLayouts } from '../../../../types/gridTypes';

interface Props {
  visible: boolean;
  gameUniqueId: string;
  categoryId: string;
  layoutGrid: GridLayouts;
  handleButtonClick: (e, launchType) => void;
}

const GameInfoModal: React.FC<Props> = ({ visible, gameUniqueId, categoryId, layoutGrid, handleButtonClick }) => {
  const { gameInfo, description, availableForFreeplay } = useGameProps({ gameUniqueId, categoryId });
  const [openModal, setOpenModal] = useState(visible);
  const infoGameImageRef = useRef<HTMLDivElement | null>(null);
  const infoGameButtonsRef = useRef<HTMLDivElement | null>(null);
  const [loadedRef, setLoadedRef] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const casinoLayoutConfig = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.CASINO_LAYOUT_CONFIG });
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const showFreePlayBtnConfig = casinoLayoutConfig?.showFreePlayBtn || !isAuthenticated;

  const closeAction = () => {
    setOpenModal(false);
    dispatch(hideRootCasinoModal());
  };

  const imageRef = useCallback((imageNode) => {
    infoGameImageRef.current = imageNode;
    setLoadedRef(true);
  }, []);

  const playHandler = useCallback((e) => {
    isMobile && closeAction();
    handleButtonClick(e, GamePlayType.PAID);
  }, []);

  const freePlayHandler = useCallback((e) => {
    isMobile && closeAction();
    handleButtonClick(e, GamePlayType.FREE);
  }, []);

  const renderDescription = () =>
    gameInfo?.description.map((paragraph, idx) => (
      <Flex key={idx} className="game-launch-modal__description--item">
        {paragraph}
      </Flex>
    ));

  return (
    <Modal visible={openModal} className="game-launch-modal">
      <Flex.ACJB className="game-launch-modal__header">
        <span className="spacer"></span>
        <div className="game-launch-modal__header__title">{description}</div>
        <Modal.Close closeText={''} closeAction={closeAction} />
      </Flex.ACJB>
      <Modal.Body className="game-launch-modal__body">
        <div ref={imageRef} className="game-launch-modal__image">
          {loadedRef && (
            <GameInfoModalImage
              gameUniqueId={gameUniqueId}
              imageModalRef={infoGameImageRef}
              categoryId={categoryId}
              layoutGrid={layoutGrid}
            />
          )}
        </div>
        {gameInfo?.description && <Flex.Col className="game-launch-modal__description">{renderDescription()}</Flex.Col>}

        <Flex.ACJB ref={infoGameButtonsRef} className="game-launch-modal__btns">
          <div className="game-launch-modal__favourites">
            <SharedComponents.GameCellFavouritesButton
              show={true}
              className="game-launch-modal__fav-btn"
              gameUniqueId={gameUniqueId}
              categoryId={categoryId}
              gameCellRef={infoGameButtonsRef}
              callback={closeAction}
            />
          </div>
          <GameInfoModalPlayBtns
            showFreePlay={availableForFreeplay && showFreePlayBtnConfig}
            playText={`${t(`GameInfo.Buttons.play`)}`}
            freePlayText={`${t(`GameInfo.Buttons.freePlay`)}`}
            playHandler={playHandler}
            freePlayHandler={freePlayHandler}
          />
        </Flex.ACJB>
      </Modal.Body>
    </Modal>
  );
};

export default GameInfoModal;
