import React from 'react';
import { useTranslation } from 'react-i18next';
import CountdownTimer from 'components/shared/CountdownTimer';
import { Flex } from 'components/shared/layout';
import { ModalUI } from 'components/shared/ModalUI';
import { getValue } from 'pages/myAccount/tabs/history/utils/history.utils';
import { hideRootGrandModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { RealityCheckModal } from 'utils/common/translationUtils/translationStrings';
import AmountCurrency from '../../myProfile/components/AmountCurrency';
import { selectRealityCheckSession } from '../slice/realityCheckSession.slice';

const tableDataClass = 'reality-check-session-info__table-data';

const SessionWarn: React.FC = () => {
  const sessionWarnData = useAppSelector(selectRealityCheckSession.sessionWarnData);
  const liveTime = useAppSelector(selectRealityCheckSession.sessionLiveTime);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  if (isEmpty(sessionWarnData)) return null;

  return (
    <Flex.Col>
      <ModalUI.Title
        hasAccent
        className={'reality-check-session-end__title mb-2'}
        text={t(RealityCheckModal.Session.warnTitle)}
      />
      <Flex.ACJB
        className={`${tableDataClass} w-100${
          sessionWarnData.hasHitBalanceLimit ? `${tableDataClass}--limit-hit` : `${tableDataClass}--limit-not-hit`
        }`}
      >
        <div>{t(RealityCheckModal.Session.warnInitialBalance)}</div>
        <div>
          <AmountCurrency amount={getValue(sessionWarnData.initialBalance)} />
        </div>
      </Flex.ACJB>
      <Flex.ACJB
        className={`${tableDataClass} w-100${
          sessionWarnData.hasHitBalanceLimit ? `${tableDataClass}--limit-hit` : `${tableDataClass}--limit-not-hit`
        }`}
      >
        <div>{t(RealityCheckModal.Session.warnCurrentBalance)}</div>
        <div>
          <AmountCurrency amount={getValue(sessionWarnData.currentBalance)} />
        </div>
      </Flex.ACJB>
      <Flex.ACJB
        className={`${tableDataClass} w-100${
          sessionWarnData.hasHitTimeLimit ? `${tableDataClass}--limit-hit` : `${tableDataClass}--limit-not-hit`
        }`}
      >
        <div>{t(RealityCheckModal.Session.warnTimePlayed)}</div>
        <Flex>
          <CountdownTimer
            classNames={'font-weight-bold mr-1 positive'}
            startDateMS={sessionWarnData.sessionStartTime}
            endDateMS={liveTime || Date.now()}
            duration={sessionWarnData.sessionEndTime - sessionWarnData.sessionStartTime}
            format={'HH:mm:ss without-locale'}
            takeDateOwnOffset={true}
            isLive={true}
            countDiff={1}
            onExpire={() => dispatch(hideRootGrandModal())}
          />
          {t(RealityCheckModal.Session.warnOfValueText)}
          <CountdownTimer
            classNames={'font-weight-bold ml-1'}
            startDateMS={sessionWarnData.sessionStartTime}
            endDateMS={sessionWarnData.sessionEndTime}
            duration={sessionWarnData.sessionEndTime - sessionWarnData.sessionStartTime}
            format={'HH:mm:ss without-locale'}
            isStatic={true}
          />
        </Flex>
      </Flex.ACJB>
    </Flex.Col>
  );
};

export default SessionWarn;
