import { useAuthentication } from 'hooks/common/useAuthentication';
import { Languages } from 'shared/common/features/appFooter/components';
import ScheduleLink from '../../../ScheduleLink';
import { useMainNavBarButtonsCheckDate } from '../../hooks/useMainNavBarButtonsCheckDate';
import { useMainNavBarButtonsCheckDevice } from '../../hooks/useMainNavBarButtonsCheckDevice';
import { MainNavBarButtonsTypes } from '../../types/mainNavBarButtons.types';
import { MainNavBarButtonOffers } from '../MainNavBarButtonOffers';
import { MainNavbarButtonsBonus } from '../mainNavbarButtonsBonus';
import { MainNavbarButtonsDefault } from '../mainNavbarButtonsDefault';

type Props = {
  item: MainNavBarButtonsTypes.Item;
  isMyProfile?: boolean;
  isAuthenticated: boolean | null;
  type?: MainNavBarButtonsTypes.ItemType;
};
export const btnsMap: Record<MainNavBarButtonsTypes.ItemType, React.FC<Props>> = {
  offers({ item, isAuthenticated }: Props) {
    return <MainNavBarButtonOffers icon={item?.icon} isAuthenticated={isAuthenticated} />;
  },
  bonusCollection({ item, isMyProfile, isAuthenticated }: Props) {
    return (
      <MainNavbarButtonsBonus
        item={item}
        isMyProfile={isMyProfile}
        type={isMyProfile ? 'default' : 'collection'}
        isTombola={false}
        isAuthenticated={isAuthenticated}
      />
    );
  },
  schedule() {
    return <ScheduleLink />;
  },
  tombola({ item, isMyProfile, isAuthenticated }: Props) {
    return (
      <MainNavbarButtonsBonus
        isAuthenticated={isAuthenticated}
        item={item}
        isMyProfile={isMyProfile}
        type={isMyProfile ? 'default' : 'compact'}
      />
    );
  },
  deposit() {
    return null;
  },
  language({ type }) {
    return type === 'language' ? <Languages inHeader /> : <></>;
  },
  default({ item, isMyProfile }: Props) {
    return <MainNavbarButtonsDefault item={item} isMyProfile={isMyProfile} />;
  },
};

type PropsItem = {
  item: MainNavBarButtonsTypes.Item;
  isMyProfile?: boolean;
  type?: MainNavBarButtonsTypes.ItemType;
};

export const MainNavBarButtonsMap: React.FC<PropsItem> = ({ item, isMyProfile, type }) => {
  const { isAuthenticated } = useAuthentication();
  const Button = item?.type && btnsMap[item?.type];
  const isVisible = !isMyProfile ? item.isHeaderVisible != false : item?.isMyProfileVisible;
  const checkDate = useMainNavBarButtonsCheckDate({ startDate: item?.startDate, endDate: item?.endDate });
  const checkByDevice = useMainNavBarButtonsCheckDevice({ viewPerDevice: item?.device });
  if (
    !item?.isVisible ||
    !Button ||
    !isVisible ||
    !checkDate ||
    (item?.device && !checkByDevice) ||
    (type ? type != item.type : false)
  )
    return null;
  return <Button item={item} isMyProfile={isMyProfile} isAuthenticated={isAuthenticated} type={type} />;
};
