import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import {
  selectBonusOffersInfoButton,
  clearBonusOffersInfoButtonData,
  fetchCasinoBonusOffersInfo,
  setGameId,
} from '../slice/bonusOffersInfoButton.slice';

const pollTime = 3000;
export const useBonusOffersInfo = ({ isFreePlay, isAuthenticated, gameId }) => {
  const isLoading = useAppSelector(selectBonusOffersInfoButton.isLoading);
  const data = useAppSelector(selectBonusOffersInfoButton.data);
  const error = useAppSelector(selectBonusOffersInfoButton.error);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) dispatch(clearBonusOffersInfoButtonData());
    if (!isFreePlay && isAuthenticated) {
      dispatch(fetchCasinoBonusOffersInfo({ gameId }));
      dispatch(setGameId(gameId));
    }
    return () => {
      dispatch(clearBonusOffersInfoButtonData());
    };
  }, []);

  useEffect(() => {
    if (data?.bonuses?.length) {
      const autoPoll = setInterval(() => {
        dispatch(fetchCasinoBonusOffersInfo({ gameId }));
      }, pollTime);
      return () => {
        clearInterval(autoPoll);
      };
    }
  }, [data]);

  return { isLoading, data, error };
};
