import React from 'react';
import clsx from 'clsx';

interface Props {
  eventTeam: string;
  isCenteredH?: boolean;
  isCenteredV?: boolean;
  isTruncated?: boolean;
  isW100?: boolean;
}

export const TeamSingle: React.FC<Props> = ({
  eventTeam,
  isCenteredH = true,
  isCenteredV = true,
  isTruncated,
  isW100,
}) => {
  const teamClass = clsx(
    'team',
    isCenteredH && 'text-center',
    isCenteredV && 'my-auto',
    isTruncated && 'text-truncate',
    isW100 && 'w-100',
  );
  return <span className={teamClass}>{eventTeam}</span>;
};
