import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

/** Cashier Modal Tabs Wrapper Section */
interface TabsWrapperProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: React.ReactNode;
}

const TabsWrapper: React.FC<TabsWrapperProps> = ({ className, children }) => {
  const wrapClass = clsx('cashier-modal__tabs-wrapper', className);

  return <div className={wrapClass}>{children}</div>;
};

export default TabsWrapper;
