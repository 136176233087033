import { useEffect } from 'react';
import { getSVGCasinoMenuSprites } from 'modules/casino/modules/navigation/shared/slice/casinoNavigationSlice';
import { useAppDispatch } from 'store';
import { isExpiredIconsSprite } from '../utils/common/helpersCommon';

export const useSVG = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const insertSprites = async () => {
      const svgSprites = await dispatch(getSVGCasinoMenuSprites());
      if (svgSprites && !document.getElementById('casino-icons'))
        document.body.insertAdjacentHTML(
          'afterbegin',
          `<div style="visibility: hidden; position: absolute; width: 0px; height: 0px; overflow: hidden;" aria-hidden="true" id="casino-icons">${svgSprites}</div>`,
        );
    };

    const now = new Date();
    if (!document.getElementById('sprites') || isExpiredIconsSprite(now)) {
      insertSprites();
    }
  }, [dispatch]);
};
