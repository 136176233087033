import clsx from 'clsx';
import { Flex } from 'components/shared/layout';

interface WrapperProps {
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
}
const SelectionsWrapper: React.FC<WrapperProps> = ({ className, children, onClick }) => {
  const wrapperClass = clsx('w-100 overflow-hidden bbldr-lines', className);
  return (
    <Flex.Col className={wrapperClass} onClick={onClick}>
      {children}
    </Flex.Col>
  );
};

export default SelectionsWrapper;
