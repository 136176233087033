import React, { memo, useCallback, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { useSetJackpotLevelsPolling } from 'modules/casino/shared/features/jackpots/hooks/useSetJackpotLevelsPolling';
import { WidgetWrapper } from './components';
import { jackpotsWidgetCellByTemplate } from './components/JackpotsWidgetCell/JackpotsWidgetCellMap';
import { useWidgetJackpotsFetch } from './hooks/useWidgetJackpotsFetch';
import { CategoryLayoutTypes } from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { CasinoWidgetsTypes } from '../../types/casinoWidgetsTypes';

interface WidgetsProps {
  widget: CasinoWidgetsTypes.Widget;
  categoryLayoutType: CategoryLayoutTypes;
}

const JackpotsWidget: React.FC<WidgetsProps> = ({ widget, categoryLayoutType }) => {
  useSetJackpotLevelsPolling({});
  const { widgetJackpotItems } = useWidgetJackpotsFetch({ widgetId: widget.id, query: widget.query });
  const WidgetJackpotCell = jackpotsWidgetCellByTemplate[widget?.template?.type];
  const [itemsLoaded, setItemsLoaded] = useState<boolean>(false);

  const renderWidgetJackpot = useCallback(() => {
    if (!WidgetJackpotCell) {
      return <></>;
    }

    return widgetJackpotItems.map((jackpotItem, index) => (
      <WidgetJackpotCell
        key={`${index}_${jackpotItem.jackpotId}`}
        jackpotItem={jackpotItem}
        isLast={index + 1 === widgetJackpotItems.length}
        setItemsLoaded={() => setItemsLoaded(true)}
      />
    ));
  }, [widgetJackpotItems, widget?.template?.type]);
  return (
    <>
      {widgetJackpotItems.length ? (
        <WidgetWrapper
          templateType={widget?.template?.type}
          categoryLayoutType={categoryLayoutType}
          forceArrowsUpdate={itemsLoaded}
        >
          {renderWidgetJackpot()}
        </WidgetWrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(JackpotsWidget, isEqual);
