import { createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit';
import { logoutUser, hardwareBackPress } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { ModalTypes } from '../types/modal.types';

type ModalProps = { id: string; alias?: string; categoryId?: number };

type RootModalState = {
  modalType: ModalTypes | null;
  modalProps?: ModalProps | null;
};

const initialState: RootModalState = {
  modalType: null,
  modalProps: null,
};

const captainUpModal = createSlice({
  name: 'captainUpModal',
  initialState,
  reducers: {
    showCaptainUpModal(
      state,
      action: PayloadAction<{
        modalType: ModalTypes;
        modalProps?: ModalProps | null;
      }>,
    ) {
      state.modalType = action.payload.modalType;
      state.modalProps = action.payload.modalProps;
    },
    hideCaptainUpModal(state) {
      state.modalType = null;
      state.modalProps = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(logoutUser, hardwareBackPress), (state) => {
      state.modalType = null;
      state.modalProps = null;
    });
  },
});

export const { showCaptainUpModal, hideCaptainUpModal } = captainUpModal.actions;

export const { reducer: captainUpModalReducer } = captainUpModal;

export const selectCaptainUpModalModal = {
  modalType: (state: RootState): ModalTypes | null => state.captainUp.modal.modalType,
  modalProps: (state: RootState) => state.captainUp.modal.modalProps,
};
