import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import config from 'utils/config';
import { TombolaTypes } from '../types/mainNavbarButtonsBonus.types';

const initialState: TombolaTypes.InitialState = {
  bonusType: {},
  isLoad: {},
  activeTombola: {},
};
export const headerBonusIconThunks: TombolaTypes.Thunks = {
  getTombolaTickets: createAbortThunk('headerBonusIcon/getTombolaTickets', async ({ url, isFromAccept }) => {
    const response = await axiosInstance.get(`${config.API_URL}/${url}&isFromAccept=${!!isFromAccept}`);
    return response.data;
  }),
};

const headerBonusIcon = createSlice({
  name: 'headerBonusIcon',
  initialState,
  reducers: {
    resetHeaderBonusIcon: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(headerBonusIconThunks.getTombolaTickets.fulfilled, (state, action) => {
      const { contentTemplateKey, isTombola, url } = action.meta.arg;
      isTombola &&
        contentTemplateKey &&
        !state.activeTombola[contentTemplateKey] &&
        url &&
        (state.activeTombola[contentTemplateKey] = {
          apiUrl: url,
        });
      if (action.payload?.completion && contentTemplateKey) {
        state.bonusType[contentTemplateKey] = action.payload?.completion;
        state.isLoad[contentTemplateKey] = true;
      }
    });
  },
});

export const { reducer: headerBonusIconReducer } = headerBonusIcon;
export const { resetHeaderBonusIcon } = headerBonusIcon.actions;
export const selectHeaderBonusIcon = {
  tickets: (state: RootState) => state.common.headerBonusIcon.bonusType,
  getBonus: (contentTemplateKey?: string | null) => (state: RootState) =>
    contentTemplateKey ? state.common.headerBonusIcon.bonusType?.[contentTemplateKey] : null,
  isLoad: (contentTemplateKey?: string | null) => (state: RootState) =>
    contentTemplateKey ? state.common.headerBonusIcon.isLoad?.[contentTemplateKey] : null,
};
