import React from 'react';
import { Icon } from 'components/shared';
import { TerminalTheme } from '../../ssbtConfig';
import { TerminalModalActionIcons } from '../../types/ssbt.types';

/** Terminal Modal Action Icon Section */
interface ActionIconProps {
  icon: TerminalModalActionIcons;
}

const ActionIcon: React.FC<ActionIconProps> = (props) => {
  const cssNS = 'terminal-modal__action-icon';

  return <Icon className={`${cssNS}`} iconPath={TerminalTheme.icons.ui} icon={props.icon} />;
};

export default ActionIcon;
