import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { CATEGORY_CUSTOM_GAMES_COUNTER } from 'modules/casino/modules/container/cellsContainer/cellls/constants/casinoGamesConstants';
import { useGameCollectionId } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameCollectionId';
import { gamesSelectors } from 'modules/casino/modules/container/cellsContainer/cellls/slice/casinoGamesSlice';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import Icon from 'modules/casino/shared/components/icons/Icon';
import { Flex } from 'modules/casino/shared/components/layout';
import { casinoUiStateSelectors } from 'modules/casino/shared/features/casinoUi/casinoUiStateSlice';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { useAppSelector } from 'store';

interface Props {
  categoryId: string;
  categoryLayout: CategoryLayoutTypes;
}

const ViewAllCell: React.FC<Props> = ({ categoryId, categoryLayout }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const { gameCollectionId } = useGameCollectionId({ categoryId, categoryLayoutType: categoryLayout });
  const show = useAppSelector((state) => casinoUiStateSelectors.collectionShowAll(state, gameCollectionId));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loaded, setLodaded] = useState(false);

  const { navToGamesCount, navToAlias, navToCategory, queryType } = useGetCategoryParams(categoryId, categoryLayout);
  const totalElements = useAppSelector((state) => gamesSelectors.categoryGamesCount(state, gameCollectionId));
  const gamesCount = CATEGORY_CUSTOM_GAMES_COUNTER.includes(queryType) ? totalElements : navToGamesCount;

  const onClickHandler = useCallback(() => {
    window.scrollTo(0, 0);
    navToAlias && navigate(navToAlias, { state: { categoryId: navToCategory, queryType } });
  }, [navigate, navToAlias, navToCategory, queryType]);

  useEffect(() => {
    inView && setLodaded(true);
  }, [inView]);

  const viewAllStyles = clsx(
    'casino-grid-item cg-view-all',
    !(loaded && show) ? 'casino-grid-item--loading' : 'casino-grid-item--is-loaded-fade-in',
  );

  return (
    <div className={viewAllStyles} onClick={onClickHandler} ref={ref}>
      <div className="casino-game-cell h-100">
        <Flex.Center className="cg-view-all__wrapper">
          <Icon className="cg-view-all__icon" icon={`plus-circled`} />
        </Flex.Center>
        <div className="casino-game-cell__title w-100 text-center text-truncate">
          {t(`CasinoLobby.CategoryHeader.viewAllGamesText`)} {gamesCount ? ` (${gamesCount})` : ''}
        </div>
      </div>
    </div>
  );
};

export default ViewAllCell;
