import React from 'react';
import { useAppSelector } from 'store';
import { selectMyProfile } from '../../slice/myProfile.slice';

type Props = {
  amount?: string;
};

const WinCoinsBalance: React.FC<Props> = ({ amount }) => {
  const balanceVisibility = useAppSelector(selectMyProfile.balanceVisibility);
  return (
    <span className={'mpd-section__text--primary'}>
      {balanceVisibility ? (
        <>
          <span>{amount}</span>
        </>
      ) : (
        '********'
      )}
    </span>
  );
};

export default WinCoinsBalance;
