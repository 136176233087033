import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { casinoLaunchSelectors } from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import { ProviderLaunchAgents } from 'modules/casino/shared/features/gameLaunch/casinoLaunchTypes';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { GAME_LAUNCH_PREFIX_PLATFORM } from 'utils/config';
import { storageGameWindows } from './useTrackGameLaunchWindows';

type Props = {
  paused: boolean;
  handlePause: () => void;
  handleReset: () => void;
};

export const useSessionTimerPause = ({ paused, handlePause, handleReset }: Props) => {
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const launchData = useAppSelector(casinoLaunchSelectors.launchData);

  const { pathname } = useLocation();
  const gameOpenedInNewWindow = launchData?.agent === ProviderLaunchAgents.window_desktop;

  useEffect(() => {
    const handlePauseAndReset = () => {
      const openedWindows = localStorage.getItem(storageGameWindows) || '';
      const shouldPause = openedWindows && !isEmpty(JSON.parse(openedWindows));

      if (paused && !shouldPause && !pathname.includes(GAME_LAUNCH_PREFIX_PLATFORM) && !gameOpenedInNewWindow) {
        handleReset();
      }

      if (!paused && shouldPause) {
        handlePause();
      }
    };
    function onStorageChange(e: StorageEvent) {
      if (e.key === storageGameWindows && isAuthenticated) {
        handlePauseAndReset();
      }
    }

    window.addEventListener('storage', onStorageChange);
    handlePauseAndReset();

    return () => {
      window.removeEventListener('storage', onStorageChange);
    };
  }, [handlePause, handleReset, isAuthenticated, paused, gameOpenedInNewWindow, pathname]);
};
