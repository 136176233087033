import React from 'react';
import clsx from 'clsx';
import { Text } from 'components/shared';
import { Flex } from 'components/shared/layout';
import {
  bonusLogComponentLocationCSSSuffixesMap,
  BonusLogComponentLocationType,
  bonusLogComponentLocationCSSPaddingMap,
  cssNS,
} from './bonusLogUITypes';

/** BonusLog Progress Bar */
const progressBarVariantsCSSMap = {
  primary: { bar: 'bonus-bar__progress--primary', text: 'bonus-bar__progress-text--accent' },
  success: { bar: 'bonus-bar__progress--success', text: 'text-success' },
} as const;
const progressBarSizesCSSMap = {
  sm: 'bonus-bar__track--sm',
  md: 'bonus-bar__track--md',
  lg: 'bonus-bar__track--lg',
} as const;

type ProgressBarVariantTypes = keyof typeof progressBarVariantsCSSMap;
type ProgressBarSizeTypes = keyof typeof progressBarSizesCSSMap;

interface ProgressBarProps {
  componentLocation: BonusLogComponentLocationType;
  /** Progress bar height */
  size?: ProgressBarSizeTypes;
  /** Progress bar variant */
  variant: ProgressBarVariantTypes;
  /** Progress Completed percentage */
  completedPct: number;
  /** Target text */
  targetText: string;
  /** Target value */
  targetValue?: string | number;
  /** Completed text */
  completedText?: string;
  /** Completed value */
  completedValue?: string;
  children?: React.ReactNode;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  componentLocation,
  size = 'sm',
  variant,
  completedPct,
  targetText,
  targetValue,
  children,
  // completedText,
  // completedValue,
}) => {
  const bonusTextClass = clsx(
    `bonus-bar bonus-text bonus-text${bonusLogComponentLocationCSSSuffixesMap[componentLocation]} ${bonusLogComponentLocationCSSPaddingMap[componentLocation]}`,
  );
  const trackClass = clsx(
    'mb-1 w-100',
    'bonus-bar__track',
    `bonus-bar__track${bonusLogComponentLocationCSSSuffixesMap[componentLocation]}`,
    progressBarSizesCSSMap[size],
  );
  const barClass = clsx(
    'h-100',
    'bonus-bar__progress',
    `bonus-bar__progress${bonusLogComponentLocationCSSSuffixesMap[componentLocation]}`,

    progressBarVariantsCSSMap[variant].bar,
  );
  const textClass = clsx(
    'bonus-bar__progress-text',
    `${progressBarVariantsCSSMap[variant].text}${bonusLogComponentLocationCSSSuffixesMap[componentLocation]}`,
  );
  const completedPctInt = Math.floor(completedPct);
  return (
    <div className={bonusTextClass}>
      <Flex.ACJB className="mb-1">
        <Flex.AC>
          <Text className={`${cssNS}__text--accent ${cssNS}__text--sm`} text={`${targetText}:\xa0`} />
          {targetValue}
          {children}
        </Flex.AC>

        {/* {completedText && completedValue && (
          <Flex className="text-right">
            <Text className={`${cssNS}__text--accent`} text={`${completedText}:`} />
            &nbsp;{completedValue}
          </Flex>
        )} */}
      </Flex.ACJB>
      <div className={trackClass}>
        <Flex className={barClass} style={{ width: `${completedPctInt}%` }} />
      </div>
      <Flex.ACJB>
        <Text className={textClass} text={completedPctInt + '%'} />
        <Text text="100%" />
      </Flex.ACJB>
    </div>
  );
};

export default ProgressBar;
