import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'store';
import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { Sport } from 'utils/common/types/sportTypes';
import config from 'utils/config';
// import { mockLaunchOptions } from './components/providers/Lema/mockData'; // ❗ MOCK
import { LiveGamesTypes } from './types';

const initialState: LiveGamesTypes.LiveGamesState = {
  liveGamesLobbys: [],
  liveGamesLobbyItems: {},
  liveGamesLobbyItemsLoading: false,
  isLobbyGameLoading: false,
  launchOptions: undefined,
  isLoading: false,
  hasError: false,
  providerStatus: 'idle',
  providersData: [],
};

export const liveGamesThunks: LiveGamesTypes.Thunks = {
  fetchLiveGamesProviders: createAbortThunk(`liveGamesLobby/fetchLiveGamesProviders`, async (apiUri) => {
    const url = `${config.API_URL}${apiUri}`;
    const response = await axiosInstance.get(url);

    return response.data;
  }),
};

const liveGames = createSlice({
  name: 'liveGames',
  initialState,
  reducers: {
    setLiveGamesLobbyItems(state, action) {
      const { data, providerAlias } = action.payload;
      state.liveGamesLobbyItems[providerAlias] = data;
      state.liveGamesLobbyItemsLoading = false;
    },
    setLiveGamesLobbyItemsLoading(state, action) {
      state.liveGamesLobbyItemsLoading = action.payload;
    },
    setIsLobbyGameLoading(state, action) {
      state.isLobbyGameLoading = action.payload;
    },
    setLaunchOptions(state, action) {
      state.launchOptions = action.payload;
      state.isLoading = false;
    },
    setError(state, action) {
      state.hasError = action.payload;
      state.isLoading = false;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(liveGamesThunks.fetchLiveGamesProviders.fulfilled, (state, action) => {
      state.providersData = action.payload;
      state.providerStatus = 'succeeded';
    });
    builder.addCase(liveGamesThunks.fetchLiveGamesProviders.rejected, (state) => {
      state.providerStatus = 'failure';
    });
  },
});

export const {
  setLaunchOptions,
  setIsLobbyGameLoading,
  setLiveGamesLobbyItemsLoading,
  setLoading,
  setError,
  setLiveGamesLobbyItems,
} = liveGames.actions;

// container lobbys
const selectLiveGamesLobbyItems = (providerAlias: string) => (state: RootState) =>
  state.liveGames.liveGamesLobbyItems?.[providerAlias];
const selectLiveGamesLobbyItemsLoading = (state: RootState) => state.liveGames.liveGamesLobbyItemsLoading;
const selectLaunchOptions = (state: RootState) => state.liveGames.launchOptions;
const selectIsLobbyGameLoading = (state: RootState) => state.liveGames.isLobbyGameLoading;
const selectLiveGamesLoading = (state: RootState) => state.liveGames.isLoading;
const selectLiveGamesError = (state: RootState) => state.liveGames.hasError;

export const liveGamesSelectors = {
  liveGamesLobbyItems: selectLiveGamesLobbyItems,
  liveGamesLobbyItemsLoading: selectLiveGamesLobbyItemsLoading,
  launchOptions: selectLaunchOptions,
  isLobbyGameLoading: selectIsLobbyGameLoading,
  liveGamesLoading: selectLiveGamesLoading,
  liveGamesError: selectLiveGamesError,
  liveGamesProviders: (state: RootState): LiveGamesTypes.ProvidersData[] | null => state.liveGames.providersData,
  liveGamesProvidersStatus: (state: RootState): Sport.StateStatus => state.liveGames.providerStatus,
};

// getLobbyItems for lobby
export const fetchLiveGamesLobbyItems =
  (providerId: number, isLive: boolean, providerAlias: string, gameTypes?: string[]) => async (dispatch) => {
    dispatch(setLiveGamesLobbyItemsLoading(true));
    try {
      const gameType = gameTypes && gameTypes.length ? `&gameTypes=${gameTypes.concat()}` : '';
      const fetchURL = `${config.API_URL}/api/gaming/public/games/searchByLive?isLive=${isLive}&gameProviderIds=${providerId}${gameType}`;
      // const fetchURL = `${config.API_URL}/api/gaming/public/games/searchByLiveNew?isLive=${isLive}&gameProviderIds=${providerId}`;

      const response = await axiosInstance.get(fetchURL, { data: null });
      dispatch(setLiveGamesLobbyItems({ data: response.data.content, providerAlias }));
      dispatch(setIsLobbyGameLoading(true));
    } catch (error) {
      dispatch(setError(true));
    }
  };

export const getLiveGamesLaunchOptions =
  (providerId: number, isFreePlay: boolean, currentPathname?: string, refGameId?: string) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(setError(false));

      const closeUrl = currentPathname ? `&closeUrl=${currentPathname}` : '';
      const fetchURL = `${config.API_URL}/api/gaming/${isFreePlay ? 'public' : 'player'}/providers/${providerId}${
        refGameId ? `/gameId/${refGameId}` : ''
      }/launchUrl?isFreeplay=${isFreePlay}${closeUrl}`;

      // const response =
      //   providerId === 114 ? { data: mockLaunchOptions } : await axiosInstance.get(fetchURL, { data: null });

      const response = await axiosInstance.get(fetchURL, { data: null });

      dispatch(setLaunchOptions(response.data));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(true));
    }
  };

export default liveGames.reducer;
