import InnerCategoryActions from './InnerCategoryActions';
import LobbyActions from './LobbyAction';
import ProviderCategoryActions from './ProviderCategoryAction';
import ProviderCategoryMobileBreadcrumbActions from './ProviderCategoryMobileBreadcrumbActions';
import { CategoryLayoutTypes } from '../../types/CategoryLayoutTypes';

export const BreadcrumbActionComponents = {
  [CategoryLayoutTypes.LOBBY]: LobbyActions,
  [CategoryLayoutTypes.PROVIDER_CATEGORY]: ProviderCategoryActions,
  [CategoryLayoutTypes.INNER_CATEGORY]: InnerCategoryActions,
  [CategoryLayoutTypes.FAVOURITES_CATEGORY]: InnerCategoryActions,
  [CategoryLayoutTypes.PROVIDER_CATEGORY_MOBILE_LAYOUT]: ProviderCategoryMobileBreadcrumbActions,
};
