import React from 'react';
//import clsx from 'clsx';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { showQuickLoginForm } from 'pages/auth/login/slice/login.slice';
import { setMPDSectionActiveTab } from 'pages/myAccount/slice/mpdAccountTabs.slice';
import { setDrawer } from 'shared/common/features/drawer/slice/drawer.slice';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { selectOffersTab } from 'shared/common/features/myProfile/sections/tabs';
import { fetchCategories } from 'shared/common/features/myProfile/sections/tabs/offersTab';
import { selectMyProfile, showMyProfileModal } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { MyProfileTabsEnum } from 'shared/common/features/myProfile/types/myProfile.types';
import { useAppDispatch, useAppSelector } from 'store';
import { LOGIN_FORM_TYPE_MODAL } from 'utils/common/helpersCommon';
import NavUserButton from '../../../../common/NavUserButton';

interface Props {
  isAuthenticated: boolean | null;
  icon?: string;
}
const iconFallback = 'common-content/general-ui/link-offers--header.svg';

const MainNavBarButtonOffers: React.FC<Props> = ({ isAuthenticated, icon }) => {
  const unseenBonusOffersCounter = useAppSelector(selectOffersTab.notificationCounter);
  const isMyProfileModalShowing = useAppSelector(selectMyProfile.isModalShowing);

  const dispatch = useAppDispatch();

  const layout =
    useLayoutConfigType({
      moduleLayout: 'platform',
      innerLayout: LayoutConfigTypes.LAYOUTTYPECONFIG,
      deviceLayout: 'general',
    })?.offersTab || 'layout_1';

  const onClickShowMyAccount = (e) => {
    if (isAuthenticated) {
      e.preventDefault();
      dispatch(setDrawer(false));
      if (!isMyProfileModalShowing) {
        const tabKey = layout === 'layout_1' ? MyProfileTabsEnum.OFFERS : MyProfileTabsEnum.ACCOUNT;
        const navTab = layout === 'layout_1' ? undefined : 'OffersTab';

        dispatch(showMyProfileModal(true));
        dispatch(setMPDSectionActiveTab({ tabKey, navTab }));
        dispatch(fetchCategories());
      }
    } else {
      dispatch(showQuickLoginForm({ status: true, loginActionType: LOGIN_FORM_TYPE_MODAL }));
    }
  };

  return (
    <NavUserButton
      onClick={onClickShowMyAccount}
      iconClassName="nav-user__btn-icon--offers"
      icon={icon ?? iconFallback}
      useCDNIcon
      notificationsCounter={unseenBonusOffersCounter}
      hasUnseenBonusOffers={unseenBonusOffersCounter > 0}
    />
  );
};

export default MainNavBarButtonOffers;
