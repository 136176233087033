import React, { useCallback, useLayoutEffect, useRef } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { selectNavigationMenu } from 'shared/common/features/navigationMenu/slice/navigationMenu.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { getWindowConfigParam } from 'utils/common/helpersCommon';
import { AppHeader } from 'utils/common/translationUtils/translationStrings';
import { setAppHeaderHeight } from './slice/appHeader.slice';
import { DrawerToggler, NavBrand } from '..';

const AppHelpHeader: React.FC = () => {
  const showTogglerOnDesktop = useAppSelector(selectNavigationMenu.burgerMenuLayout02)?.showOnDesktop;

  const dispatch = useAppDispatch();
  const headerRef = useRef<HTMLDivElement | null>(null);

  const handleRef = useCallback(
    (node) => {
      node && (headerRef.current = node);
    },
    [headerRef],
  );

  useLayoutEffect(() => {
    if (headerRef.current) {
      dispatch(setAppHeaderHeight(headerRef.current?.clientHeight));
    }
  }, [headerRef.current, dispatch]);

  const { t } = useTranslation();

  return (
    <div className={`d-flex-ac mnav head head--help`} ref={(node) => handleRef(node)}>
      {isMobile && <DrawerToggler />}
      {showTogglerOnDesktop && !isMobileOnly && <DrawerToggler />}

      <NavBrand />

      <Link className="ml-auto egtd-p-btn egtd-p-btn--lg egtd-p-btn--primary flex-wrap" to="/">
        {t(AppHeader.NavMainHelp.backToBtn)}{' '}
        <span className="text-capitalize">{getWindowConfigParam('BRAND_NAME_SHORT')}</span>
      </Link>
    </div>
  );
};

export default AppHelpHeader;
