import clsx from 'clsx';
import { Flex } from 'components/shared/layout';
import { useAppSelector } from 'store';
import { Selector, locationNS } from './customTabsUICSS.types';
import { classExtendByLocation } from './utils/customTabs.utils';

type Props = {
  badgeLabel?: string;
  disable?: boolean;
  isActive?: boolean;
  location?: 'promotions' | 'myAccount';
  badgeSelector?: Selector<number | undefined>;
};

const Badge: React.FC<Props> = ({ location = 'promotions', badgeLabel, isActive, disable, badgeSelector }) => {
  const counter: number | undefined = useAppSelector(badgeSelector ? badgeSelector : () => 0);

  const badgeClass = clsx(
    `${locationNS}-${classExtendByLocation[location]}__tab-badge`,
    isActive && `${locationNS}-${classExtendByLocation[location]}__tab-badge--active`,
    disable && `${locationNS}-${classExtendByLocation[location]}__tab-badge--disable`,
  );
  if (counter != undefined && counter < 1) return null;

  return <Flex.Center className={badgeClass}>{counter?.toString() ?? badgeLabel}</Flex.Center>;
};
Badge.displayName = 'Badge';

export default Badge;
