import Badge from './Badge';
import DrawerTogglerLayout01 from './DrawerTogglerLayout01';
import DrawerTogglerLayout02 from './DrawerTogglerLayout02';
import RetailDrawerToggler from './RetailDrawerToggler';

export const AppHeaderUI = {
  Badge: Badge,
  DrawerTogglerLayout01: DrawerTogglerLayout01,
  DrawerTogglerLayout02: DrawerTogglerLayout02,
  RetailDrawerToggler: RetailDrawerToggler,
};
