import { useCallback } from 'react';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { Currency } from 'shared/common/features/myProfile/types/myProfile.types';
import { useAppSelector } from 'store';

type ReturnType = {
  currencySymbol: string;
  formatCurrency: (currency?: string) => string;
  currencyCode: string;
};

const getCurrencyDisplay = (currencyCode: string, currencySettings: Currency[] | null): string =>
  currencySettings?.find((c) => c.name === currencyCode)?.nameTranslations || currencyCode;

const formatSymbol = (symbol: string) => {
  return symbol?.endsWith('.') ? symbol?.substr(0, symbol.length - 1) : symbol;
};

export const useCurrency = (): ReturnType => {
  const userCurrencyName = useAppSelector(selectMyProfile.currencyName);
  const currencyCode = userCurrencyName || localStorage.getItem('currency') || '';
  const currencySettings = useAppSelector(selectGeneral.currencySettings);
  const currencySymbol = formatSymbol(getCurrencyDisplay(currencyCode, currencySettings));

  const formatCurrency = useCallback(
    (currency?: string) => (!currency ? '' : formatSymbol(getCurrencyDisplay(currency, currencySettings))),
    [currencySettings],
  );

  return { currencySymbol, currencyCode, formatCurrency };
};
