import React from 'react';
import { StyledSegment, StyledSegmentText } from './Segment.styled';

interface SegmentProps {
  hasWin: boolean;
  isWinner: boolean;
  text: string | JSX.Element;
  bonusTextColor?: string;
  bonusWinTextColor?: string;
}

const Segment: React.FC<SegmentProps> = ({ hasWin, isWinner, text, bonusTextColor, bonusWinTextColor }) => {
  return (
    <StyledSegment
      $hasWin={hasWin}
      $isWinner={isWinner}
      $color={bonusTextColor}
      $winColor={bonusWinTextColor}
      className={'d-flex-ac grid-start-1x1'}
    >
      <StyledSegmentText className="ml-auto">{text}</StyledSegmentText>
    </StyledSegment>
  );
};

export default Segment;
