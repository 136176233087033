import { useEffect, useCallback, useState } from 'react';
import { FieldValues, UseFormReset } from 'react-hook-form';
import { useCountDown } from 'hooks/common/useCountDown';

type Props = {
  mfaCoolOff: string;
  isLoading: boolean;
  reset: UseFormReset<FieldValues>;
  isValidMfaCode: boolean | null;
};

export const useCode = ({ mfaCoolOff, isLoading, reset, isValidMfaCode }: Props) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [resendCodeCoolOff, setResendCodeCoolOff] = useState(new Date().getTime() + 30000);
  const [disableWriteCode, setDisableWriteCode] = useState<boolean>(
    new Date().getTime() > new Date(mfaCoolOff).getTime(),
  );

  const { isExpired: isCoolOffExpired } = useCountDown(resendCodeCoolOff, 'systemTime');

  useEffect(() => {
    setResendCodeCoolOff(new Date().getTime() + 30000);
  }, [mfaCoolOff]);

  const isDisabledBtn = disableWriteCode || hasError || isLoading;

  const timerIsFinnish = useCallback(
    (isDone: boolean) => {
      setDisableWriteCode(isDone);
      setHasError(false);
      reset();
    },
    [reset],
  );

  useEffect(() => {
    if (isValidMfaCode === false) {
      setHasError(true);
    }
  }, [isValidMfaCode]);

  return {
    isDisabledBtn,
    hasError,
    disableWriteCode,
    isCoolOffExpired,
    timerIsFinnish,
    setHasError,
  };
};
