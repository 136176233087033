import React, { HTMLAttributes, memo } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { NotificationTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationTypes';
import { constructAnimationImage } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationUtils';
import '../../../components/GamificationUI/Logo.scss';
import { Breakpoints } from 'theme/Theme';

interface Props extends HTMLAttributes<HTMLImageElement> {
  className?: string;
  animationParams: NotificationTypes.GamificationModalParams;
  activeLanguage?: string | null;
}

const AnimationHeader: React.FC<Props> = ({ className, animationParams, activeLanguage, ...rest }) => {
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });

  const { logoWidth, logoHeight, folder } = animationParams;
  const logoMaxWidth = isDesktop ? logoWidth : 335;

  const logoImage = constructAnimationImage({
    folder,
    image: `logo-${activeLanguage ?? 'en'}.png`,
    width: logoWidth * 2,
    height: logoHeight * 2,
  });

  const imgClass = clsx('w-100 gmf-logo', className);
  return <img className={imgClass} src={logoImage} style={{ maxWidth: `${logoMaxWidth}px` }} {...rest} />;
};

export default memo(AnimationHeader);
