import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import NoDataMessage from 'components/shared/NoDataMessage';
import { useAuthentication } from 'hooks/common/useAuthentication';
import { PromotionTypes } from 'pages/promotions/types/promotions.types';
import { Breakpoints } from 'theme/Theme';
import { isEmpty } from 'utils/common/helpersCommon';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import PrizeDropPromoViewAllBtn from './PrizeDropPromoViewAllBtn';
import PrizeDropTable from './PrizeDropTableContainer';
import PrizeDropTableRow from './PrizeDropTableRow';
import { prizeDropPromoCSSNS } from '../prizeDropUICSS.types';

const winnersRows = isMobile ? 6 : 8;

const secondaryTabs: { name: string; type: 'rewardedPlayers' | 'personalRewards' }[] = [
  { name: Promotions.PrizeDrop.winnersSubTab, type: 'personalRewards' },
  { name: Promotions.PrizeDrop.myWinsSubTab, type: 'rewardedPlayers' },
];

type Props = {
  rewardedPlayers: PromotionTypes.RewardsObject<PromotionTypes.PersonalRewards>;
  personalRewards: PromotionTypes.RewardsObject<PromotionTypes.PersonalRewards> | null;
  onShowAllClick?: (type: 'rewardedPlayers' | 'personalRewards') => void;
};

const PrizeDropPromoTabsSecondary: React.FC<Props> = ({ rewardedPlayers, personalRewards, onShowAllClick }) => {
  const [secondaryTabIndex, setSecondaryTabIndex] = useState(0);
  const { isAuthenticated, showLoginForm } = useAuthentication();
  const { t } = useTranslation();

  const onSelectSecondaryTabIndex = useCallback(
    (index: number) => {
      if (secondaryTabs?.[index].type === 'rewardedPlayers' && !isAuthenticated) {
        showLoginForm();
        return;
      }
      setSecondaryTabIndex(index);
    },
    [showLoginForm, isAuthenticated],
  );

  const rewardedPlayersToDisplay = rewardedPlayers?.rewards?.slice(0, winnersRows) || [];
  const personalRewardsToDisplay = personalRewards?.rewards?.slice(0, winnersRows) || [];
  const showAllPersonalRewards = personalRewards?.rewards ? personalRewards?.rewards?.length > winnersRows : false;
  const showAllRewardedPlayers = rewardedPlayers?.rewards ? rewardedPlayers?.rewards?.length > winnersRows : false;

  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isMobile}px)` });
  const tabClass = clsx(!isDesktop && `${prizeDropPromoCSSNS}-inner-shadow`, `${prizeDropPromoCSSNS}-tabs-secondary`);

  if (isEmpty(rewardedPlayers?.rewards))
    return (
      <div className={`${prizeDropPromoCSSNS}-error-wrapper d-flex w-100 h-100`}>
        <NoDataMessage message={t(Promotions.PrizeDrop.norewardedPlayers)} />
      </div>
    );

  return (
    <Tabs
      selectedIndex={secondaryTabIndex}
      onSelect={onSelectSecondaryTabIndex}
      className={tabClass}
      focusTabOnClick={false}
    >
      <TabList className={`${prizeDropPromoCSSNS}-tabs-list-secondary`} data-ics--global>
        {secondaryTabs.map((tab) => (
          <Tab
            className={`${prizeDropPromoCSSNS}-tab`}
            selectedClassName={`${prizeDropPromoCSSNS}-tab--selected`}
            key={tab.type}
          >
            <>{t(tab.name)}</>
          </Tab>
        ))}
      </TabList>

      <TabPanel
        className={`${prizeDropPromoCSSNS}-panel no-scrollbars`}
        selectedClassName={`${prizeDropPromoCSSNS}-panel--selected`}
      >
        {isEmpty(rewardedPlayersToDisplay) ? (
          <NoDataMessage message={t(Promotions.PrizeDrop.norewardedPlayers)} />
        ) : (
          <PrizeDropTable isPromotion>
            <PrizeDropTableRow
              isHeader
              firstColText={t(Promotions.PrizeDrop.player)}
              secondColText={t(Promotions.PrizeDrop.win)}
              isPromotion
            />

            {rewardedPlayersToDisplay?.map((reward, index) => (
              <PrizeDropTableRow
                key={`${reward.bonusName || ''}-${index}`}
                firstColText={reward.username || ''}
                secondColText={reward.bonusName || ''}
                isPromotion
              />
            ))}
          </PrizeDropTable>
        )}
        {showAllRewardedPlayers && onShowAllClick && (
          <PrizeDropPromoViewAllBtn
            onClick={() => onShowAllClick('rewardedPlayers')}
            textBtn={t(Promotions.PromotionCell.showMore)}
          />
        )}
      </TabPanel>

      <TabPanel
        className={`${prizeDropPromoCSSNS}-panel custom-scrollbars `}
        selectedClassName={`${prizeDropPromoCSSNS}-panel--selected`}
      >
        {isEmpty(personalRewardsToDisplay) ? (
          <NoDataMessage message={t(Promotions.PrizeDrop.nopersonalRewards)} />
        ) : (
          <PrizeDropTable>
            <PrizeDropTableRow
              isHeader
              firstColText={t(Promotions.PrizeDrop.player)}
              secondColText={t(Promotions.PrizeDrop.win)}
              isPromotion
            />
            {personalRewardsToDisplay.map((reward, index) => (
              <PrizeDropTableRow
                key={`${reward.bonusName || ''}-${index}`}
                firstColText={reward.username || ''}
                secondColText={reward.bonusName || ''}
                isPromotion
              />
            ))}
          </PrizeDropTable>
        )}
        {showAllPersonalRewards && onShowAllClick && (
          <PrizeDropPromoViewAllBtn
            onClick={() => onShowAllClick('personalRewards')}
            textBtn={t(Promotions.PromotionCell.showMore)}
          />
        )}
      </TabPanel>
    </Tabs>
  );
};

export default PrizeDropPromoTabsSecondary;
