import { useMemo, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import userStorage from 'pages/auth/login/UserStorage';
import { NotificationTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationTypes';
import { defineAnimationParams } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationUtils';
import { Breakpoints } from 'theme/Theme';
import { SelectBonus } from './feature/selectBonus';
import useAnimationComplete from '../../hooks/useAnimationComplete';
import { useAutoScrollToDepositButton } from '../../hooks/useAutoScrollToDepositButton';

const WizardBonus: React.FC<{
  notification: NotificationTypes.PopUpNotification;
  rowLayout?: 'default' | '2x3';
  primaryTextBgProps?: boolean;
  differentImage?: boolean;
}> = ({ notification }) => {
  const { header } = notification;
  const { style, bonusData } = notification;
  const [activeLanguage] = useState(userStorage.getUserLang());
  const { animationComplete, setAnimationComplete } = useAnimationComplete();
  useAutoScrollToDepositButton(animationComplete);

  const animationParams = useMemo(
    () =>
      defineAnimationParams({
        animationKey: style.animationKey as NotificationTypes.AnimationKeys,
        folder: style.folder as NotificationTypes.GamificationFolder,
      }),
    [style.animationKey, style.folder],
  );

  return (
    <Wrapper>
      <GlobalStyles />
      <SelectBonus
        bonuses={bonusData.groupOptions}
        notification={notification}
        animationComplete={animationComplete}
        setAnimationComplete={setAnimationComplete}
        animationParams={animationParams}
        header={header}
        activeLanguage={activeLanguage}
      />
    </Wrapper>
  );
};

const GlobalStyles = createGlobalStyle`
.letter {
    max-width: 355px !important;
    @media (min-width: ${Breakpoints.isMobile}px) {
      max-width: 470px !important;
    }
}
`;
const Wrapper = styled.div<{ $background?: string }>`
  max-width: 355px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 600px;
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;
  flex: 1 0 600px;
  /* gap: 25px; */
  background-size: contain;
  background-image: ${({ $background }) => `url(${$background})`};
  @media (min-width: ${Breakpoints.isMobile}px) {
    max-width: 470px;
    /* gap: 30px; */
    max-height: 790px;
    flex: 1 0 790px;
  }
`;

export default WizardBonus;
