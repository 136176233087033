import React from 'react';
import clsx from 'clsx';
import Icon from 'components/shared/Icon';
import { classNameUtils } from '../utils/className.utils';

type Props = {
  title: string | null;
  handleClose?: () => void;
  className?: string;
};

const NotificationPopupHeader: React.FC<Props> = ({ title, handleClose, className }) => {
  const classProp = clsx(classNameUtils.header, className);

  return (
    <div className={classProp}>
      <Icon icon={'info-solid'} className={`${classNameUtils.header}-icon`} />
      <div className={`${classNameUtils.header}-title`}>{title}</div>
      {handleClose && (
        <Icon
          icon={'times'}
          className={`${classNameUtils.header}-icon ${classNameUtils.header}-close`}
          onClick={handleClose}
        />
      )}
    </div>
  );
};

export default NotificationPopupHeader;
