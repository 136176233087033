import { isIOS } from 'react-device-detect';
import { FieldValues } from 'react-hook-form';
import { NavigateFunction } from 'react-router-dom';
import { FormFieldTypes } from 'components/shared/forms/formTypes';
import { VERIFICATION_PLACEHOLDER_FORMAT, VerificationTypes } from '../types/account.types';

/**
 * Replace text by given placeholders
 * @param {string} text Text to replace
 * @param {Object} placeholders Object with key value placeholders to replace
 */
export const replacePlaceholders = (text, placeholders) => {
  if (placeholders) {
    Object.entries(placeholders).map((placeholder) => {
      const verifPlaceholder = VERIFICATION_PLACEHOLDER_FORMAT.replace('placeholder', placeholder[0]);
      text = text.replace(new RegExp(verifPlaceholder), placeholder[1]);
    });
  }
  return text;
};

export const isSectionInvisible = (
  section: { key: string | number; version?: string; versionAndroid?: string },
  isBuMfaEnabled?: boolean,
) => {
  const isInVisibleKey = section?.key == 'deleteAccount';
  const isVisibleVersion = isIOS ? window.appVersion == section?.version : window.appVersion == section?.versionAndroid;
  const isRNVisible = window.isRNWebView && isVisibleVersion;
  const buMFADisabled = !isBuMfaEnabled && section?.key === 'multiFactorAuthentication';
  const isInvisible = (isInVisibleKey && !isRNVisible) || buMFADisabled;
  return isInvisible;
};

const verificationTypes: Record<VerificationTypes, string> = {
  [VerificationTypes.PLATFORM]: 'submit',
  [VerificationTypes.IDENFY]: 'submit-provider',
  [VerificationTypes.SHUFTI]: 'submit-provider',
  [VerificationTypes.SUMSUB]: 'submit-provider',
};

export const getNavigateToSubmit =
  (navigate: NavigateFunction) =>
  (
    pendingDocumentId: string | number,
    verificationType: VerificationTypes,
    externalVerificationToken?: string,
    externalVerificationIFrameUrl?: string,
    externalVerificationReference?: string,
  ) => {
    navigate(`/my-account/profile/verification/${verificationTypes[verificationType] || verificationTypes.PLATFORM}`, {
      state: {
        verificationType: verificationType,
        pendingDocumentId: pendingDocumentId,
        verificationToken: externalVerificationToken,
        verificationIFrameUrl: externalVerificationIFrameUrl,
        verificationReference: externalVerificationReference,
      },
    });
  };

export const getProviderUrl = ({
  language,
  verificationIFrameUrl,
  verificationToken,
}): Partial<Record<VerificationTypes, string>> => ({
  [VerificationTypes.IDENFY]:
    verificationIFrameUrl || `https://ui.idenfy.com/?lang=${language}&authToken=${verificationToken}`,
  [VerificationTypes.SHUFTI]: verificationIFrameUrl || 'https://app.shuftipro.com',
});

export const getSubmitButtonClass = (rowFormFields: FormFieldTypes[]) => {
  const elemTotalSize = rowFormFields?.reduce((acc, curr) => (acc += curr?.gridStyles?.size ?? 0), 0);
  const fraction = rowFormFields[0]?.gridStyles?.fraction || 0;
  const buttonElCol = fraction ? fraction - elemTotalSize || 1 : 1;
  const align = fraction && buttonElCol === 1 ? 'ml-auto mt-2' : '';

  return `col-lg-fifth-${fraction ? fraction : ''}${buttonElCol} ${align}`;
};

export const getDefaultValues = <T>({
  formFields,
  detailsMap = {},
  details,
}: {
  formFields: FormFieldTypes[];
  detailsMap?: Record<string, string>;
  details: T;
}) => {
  const defaultValues = {} as FieldValues;

  formFields?.forEach((field) => {
    defaultValues[field.name] = detailsMap?.[field.name]
      ? (details?.[detailsMap?.[field.name]] ?? field.defaultValue ?? '')
      : (details?.[field.name] ?? field.defaultValue ?? '');
  });

  return defaultValues;
};
