import styled from 'styled-components';

const GameMediaStyled = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  grid-row-start: 1;
  grid-column-start: 1;
  height: 100%;
  border-radius: var(--game-cell-br);
`;

export default GameMediaStyled;
