import { useEffect } from 'react';
import { Casino } from 'modules/casino/shared/types';
import { useAppDispatch } from 'store';
// import {
//   setCategoryGamesCount,
//   setCategoryIsFilterEnabled,
//   setCasinoCategorySearchTags,
//   setCategoryTitle,
//   setCategoryFromProviders,
// } from '../categoryBreadcrumb/CategoryBreadcrumbSlice';
/**
* Sets category breadcrumb data
*
* @param {number} totalElements Total number of games.
* @param {string} title Category title.
* @param {boolean | undefined} isFilterEnabled  It depends whether dropdown with search tags should be displayed.
* @param {SearchTag[] | undefined} searchTags Category search tags.

*/
export const useCategoryBreadcrumbData = (
  totalElements: number,
  title: string,
  isFilterEnabled = false,
  searchTags: Casino.SearchTag[] | boolean = false,
): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // dispatch(setCategoryGamesCount(totalElements));
    // dispatch(setCategoryIsFilterEnabled(isFilterEnabled));
    // dispatch(setCasinoCategorySearchTags(searchTags));
    // dispatch(setCategoryTitle(title));
    // dispatch(setCategoryFromProviders(false));

    return () => {
      // dispatch(setCategoryGamesCount(0));
      // dispatch(setCategoryIsFilterEnabled(false));
      // dispatch(setCasinoCategorySearchTags([]));
      // dispatch(setCategoryTitle(''));
      // dispatch(setCategoryFromProviders(false));
    };
  }, [dispatch, totalElements, isFilterEnabled, searchTags, title]);
};
