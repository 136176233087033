import React from 'react';
import StyledCardDescription from './CardDescription.styled';
import StyledCardSubtitle from './CardSubtitle.styled';
import StyledCardTitle from './CardTitle.styled';

const componentsMap = {
  title({ text, textStyle }: TextComponentProps) {
    return <StyledCardTitle style={textStyle}>{text}</StyledCardTitle>;
  },
  subtitle({ text, textStyle }: TextComponentProps) {
    return <StyledCardSubtitle style={textStyle}>{text}</StyledCardSubtitle>;
  },
  description({ text, textStyle }: TextComponentProps) {
    return <StyledCardDescription style={textStyle}>{text}</StyledCardDescription>;
  },
};

type TextComponentProps = {
  text: string;
  textStyle?: { color: string; backgroundColor: string };
};

type ComponentType = 'title' | 'subtitle' | 'description';

interface CardTitleProps extends TextComponentProps {
  type: ComponentType;
}

const CardTitle: React.FC<CardTitleProps> = ({ type, text, textStyle }) => {
  const TextComponent = componentsMap[type];

  return <TextComponent text={text} textStyle={textStyle} />;
};

export default CardTitle;
