import { InfoTableUI } from 'modules/retail/modules/ssbt/components/infoTableUI';
import { formatFromAPI } from 'modules/retail/modules/ssbt/components/terminalKeyboard/helpersFuncCashier';
import { isEmpty, retailFormatDate } from 'utils/common/helpersCommon';
import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';
import { financialCommission } from './financialRecords ';
import { CashierModalUI } from '../../cashierModalUI/Index';

// Collect odd and even cells
export const getCells = ({ data }: { data: any }) => {
  const resultsRenderEven: any[] = [];
  const resultsRenderOdd: any[] = [];

  for (let i = 0; i < data.length; i += 1) {
    if (data[i].id % 2 === 0) {
      resultsRenderEven?.push(data[i]);
    } else {
      resultsRenderOdd?.push(data[i]);
    }
  }
  return { odd: resultsRenderOdd, even: resultsRenderEven };
};

const FinanceInfoCommission = ({
  financeInfoCommissionStatus,
  t,
  financeInfoCommission,
  handleFinanceInfoIcon,
  requestDate,
  isCurrencyBeforeAmount,
}) => {
  const showInfoMessage = financeInfoCommissionStatus === 'idle' || financeInfoCommissionStatus === 'failure';
  const showCommissionMessage = () => {
    if (financeInfoCommissionStatus === 'idle') {
      return `${t(CashierTranslations.FinancialModal.messageText)}`;
    } else if (financeInfoCommissionStatus === 'failure') {
      return t('Cashier.FinancialModal.comissionErrorMessage');
    }
  };
  const { odd, even } = getCells({
    data: financialCommission(t, financeInfoCommission, isCurrencyBeforeAmount),
  });

  return (
    <>
      {showInfoMessage && (
        <CashierModalUI.InfoMessage
          className="m-auto"
          icon={handleFinanceInfoIcon(financeInfoCommissionStatus)}
          infoMessage={showCommissionMessage()}
        />
      )}
      {!isEmpty(financeInfoCommission) && (
        <InfoTableUI.Wrapper>
          <InfoTableUI.Cell
            isSpanned
            cellText={t(CashierTranslations.FinancialModal.selectedPeriod)}
            cellValue={formatFromAPI(financeInfoCommission?.period)}
          />
          <InfoTableUI.Cell
            isSpanned
            cellText={t('Cashier.FinancialModal.requestDate')}
            cellValue={retailFormatDate(requestDate)}
          />

          <InfoTableUI.Col>
            {even?.map((row) => {
              return (
                <InfoTableUI.Cell key={row.id} cellText={row.text} cellValue={row.value} className={`${row.id}-even`} />
              );
            })}
          </InfoTableUI.Col>
          <InfoTableUI.Col>
            {odd?.map((row) => {
              return (
                <InfoTableUI.Cell key={row.id} cellText={row.text} cellValue={row.value} className={`${row.id}-odd`} />
              );
            })}
          </InfoTableUI.Col>
        </InfoTableUI.Wrapper>
      )}
    </>
  );
};

export default FinanceInfoCommission;
