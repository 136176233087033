import { useEffect } from 'react';
import { LayoutConfigInnerLayoutTypes, RequestStatuses } from 'modules/casino/shared/constants';
import { useCasinoLayoutConfig } from 'modules/casino/shared/hooks/useCasinoLayoutConfig';
import { useInterval } from 'modules/casino/shared/hooks/useInterval';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { store, useAppDispatch, useAppSelector } from 'store';
import { casinoWidgetsSelectors, fetchWidgetLeaderBoardData } from '../../../../slice/casinoWidgetsSlice';
import { WIDGET_LEADER_BOARD_PULL_INTERVAL_FALLBACK } from '../LeaderBoardCellWidgetConstants';

export const useFetchLeaderBoardData = ({ id, query }: { id: string; query: string }) => {
  const dispatch = useAppDispatch();
  const leaderBoardLoadingStatus = useAppSelector((state) =>
    casinoWidgetsSelectors.leaderBoardLoadingStatus(state, id),
  );
  const userCurrencyName = selectMyProfile.currencyName(store.getState());
  const playerId = selectMyProfile.playerId(store.getState());
  const currencyCode = userCurrencyName || localStorage.getItem('currency') || undefined;
  const config = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.WIDGET_LEADERBOARD });
  const pullInterval = config && config?.interval ? config?.interval : WIDGET_LEADER_BOARD_PULL_INTERVAL_FALLBACK;

  const additionalParams = `${currencyCode ? '&currencyCode=' + currencyCode : ''}${
    playerId ? '&playerId=' + playerId : ''
  }`;

  useInterval(() => {
    if (
      query &&
      id &&
      (leaderBoardLoadingStatus === RequestStatuses.INITIAL || leaderBoardLoadingStatus === RequestStatuses.EMPTY)
    ) {
      dispatch(
        fetchWidgetLeaderBoardData({
          query: query + additionalParams,
          id,
        }),
      );
    }
  }, pullInterval);

  useEffect(() => {
    if (query && id) {
      dispatch(
        fetchWidgetLeaderBoardData({
          query: query + additionalParams,
          id,
        }),
      );
    }
  }, [dispatch, playerId, currencyCode]);
};
