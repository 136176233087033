import React, { memo } from 'react';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import useWidgetBackground from 'modules/casino/modules/container/widget/widgetTypes/hooks/useWidgetBackground';
import { ButtonOrder, CasinoWidgetsTypes, WidgetTemplates } from '../../../types/casinoWidgetsTypes';
import GenericWidgetSkeleton from '../components/GenericWidgetSkeleton';
import { GenericWidgetsUI } from '../components/GenericWidgetsUIComponents';

interface WidgetsProps {
  widget: CasinoWidgetsTypes.GenericWidget;
}

const GenericCellWidget: React.FC<WidgetsProps> = ({ widget }) => {
  const backgroundWidgetResource = widget?.backgroundLocations;
  const type = widget?.template?.type;
  const { backgroundWrapper, setRefs } = useWidgetBackground({ backgroundWidgetResource, type });
  const hasWidgetButton = widget?.navigation?.buttonNavigation;
  const hasWidgetTitle = widget?.title?.text || widget?.title?.textSecondary;
  const leftColClass = clsx('cw--left-col');
  const leftColStyle = {
    gridColumnStart: widget?.title?.column,
    gridRowStart: widget?.title?.row,
  };
  if (type === WidgetTemplates.SECTION) {
    return <></>;
  }
  const renderTitle = (additionalClass = '') => {
    return (
      hasWidgetTitle && (
        <GenericWidgetsUI.Text description={widget?.title} type={type} additionalClass={additionalClass} />
      )
    );
  };

  const renderButton = (additionalClass = '') => {
    return hasWidgetButton && <GenericWidgetsUI.Button button={widget?.navigation} additionalClass={additionalClass} />;
  };
  const buttonOrder = () => {
    if (widget?.navigation?.order === ButtonOrder.TOP) {
      return ButtonOrder.TOP;
    }

    return ButtonOrder.BOTTOM;
  };

  const renderMergedCellContent = () => {
    const additionalClass = hasWidgetTitle && hasWidgetButton ? 'cw--flex-50' : 'cw--flex-100';
    return (
      <div className={leftColClass} style={leftColStyle}>
        {buttonOrder() === ButtonOrder.TOP ? (
          <>
            {renderButton(additionalClass)}
            {renderTitle(additionalClass)}
          </>
        ) : (
          <>
            {renderTitle(additionalClass)}
            {renderButton(additionalClass)}
          </>
        )}
      </div>
    );
  };

  const renderSeparateCellContent = () => {
    return (
      <>
        <div className={leftColClass} style={leftColStyle}>
          {buttonOrder() === ButtonOrder.TOP ? renderButton() : renderTitle()}
        </div>
        {buttonOrder() === ButtonOrder.TOP ? renderTitle() : renderButton()}
      </>
    );
  };

  return widget.id && (!backgroundWidgetResource || backgroundWrapper) ? (
    <>
      <GenericWidgetsUI.Wrapper
        type={type}
        backgroundUrl={backgroundWrapper}
        columns={widget?.template?.columns}
        navigation={widget?.navigation}
        rows={widget?.template?.rows}
      >
        {/*{widget?.template?.rows === 1 && widget?.template?.columns === 1 && renderMergedCellContent()}   */}

        {widget?.template?.rows === 1 &&
          widget?.template?.columns === 1 &&
          (widget?.title?.column === widget?.navigation?.column && widget?.title?.row === widget?.navigation?.row
            ? renderMergedCellContent()
            : renderSeparateCellContent())}

        {/*{widget?.template?.rows === 1 &&*/}
        {/*  widget?.template?.columns === 1 &&*/}
        {/*  (widget?.title?.row === widget?.button?.row ? renderMergedCellContent() : renderSeparateCellContent())}*/}

        {widget?.template?.rows === 1 &&
          widget?.template?.columns === 2 &&
          (widget?.title?.column === widget?.navigation?.column && widget?.title?.row === widget?.navigation?.row
            ? renderMergedCellContent()
            : renderSeparateCellContent())}

        {widget?.template?.rows === 2 &&
          widget?.template?.columns === 1 &&
          (widget?.title?.column === widget?.navigation?.column && widget?.title?.row === widget?.navigation?.row
            ? renderMergedCellContent()
            : renderSeparateCellContent())}

        {widget?.template?.rows === 2 &&
          widget?.template?.columns === 2 &&
          (widget?.title?.column === widget?.navigation?.column && widget?.title?.row === widget?.navigation?.row
            ? renderMergedCellContent()
            : renderSeparateCellContent())}
      </GenericWidgetsUI.Wrapper>
    </>
  ) : (
    <GenericWidgetSkeleton type={type} columns={widget?.template?.columns} setRefs={setRefs} />
  );
};
export default memo(GenericCellWidget, isEqual);
