import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes, VerticalsConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { GamificationInitsPropsType, GamificationInitsType, WidgetInitTypes } from '../types/gamification.types';

export const GAMIFICATION_ROUTE = '/captain-up';
export const useGamificationWidgetConfig = (
  getWidgetItemByType?: keyof typeof WidgetInitTypes,
): {
  route: string;
  isVisible?: boolean;
  isWidget?: boolean;
  isGamificationInit?: boolean;
  gamificationInits?: GamificationInitsType;
  itemByType?: GamificationInitsPropsType;
} => {
  const isGamification = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.VERTICALS,
  })?.[VerticalsConfigTypes.GAMIFICATION];

  const gamificationInits =
    isGamification &&
    (Object.keys(WidgetInitTypes) as Array<WidgetInitTypes>).reduce(
      (inits, key) =>
        isGamification[WidgetInitTypes[key]]?.isVisible !== undefined
          ? { ...inits, [WidgetInitTypes[key]]: { isVisible: isGamification[WidgetInitTypes[key]].isVisible } }
          : inits,
      {},
    );
  const isGamificationInit =
    gamificationInits && Object.keys(gamificationInits).length
      ? Object.keys(gamificationInits).some((gamificationInit) => gamificationInits[gamificationInit].isVisible)
      : isGamification?.isWidget;

  const itemByType = getWidgetItemByType ? isGamification?.[getWidgetItemByType.toLocaleLowerCase()] : undefined;

  return {
    route: isGamification?.route || GAMIFICATION_ROUTE,
    isVisible: isGamification?.isVisible,
    isWidget: isGamification?.isWidget,
    isGamificationInit,
    gamificationInits,
    itemByType,
  };
};
