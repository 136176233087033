import React from 'react';
import clsx from 'clsx';

/** Form Row */
interface RowProps {
  className?: string;
  children: React.ReactNode;
}

const Row: React.FC<RowProps> = ({ className, children }) => {
  const rowClass = clsx('form-row', className);
  return <div className={rowClass}>{children}</div>;
};

export default Row;
