import styled, { keyframes } from 'styled-components';
import { Mixins } from 'theme/StyledComponents/shared';
import { Breakpoints } from 'theme/Theme';

const popIn = keyframes`
  0% { transform: scale(0); }
  90% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

export const PrizeCard = styled.div<{ $isWinner: boolean }>`
  place-self: stretch;
  grid-row: 1 / 1;

  z-index: 1000;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5px;

  text-align: center;
  font-size: ${Mixins.Size.clampedSize(16, 32)};
  font-weight: 700;
  line-height: 1.3;
  text-shadow: 0 4px 3px rgba(0, 0, 0, 0.6);

  color: ${({ $isWinner }) => ($isWinner ? '#d7b247' : 'rgba(255,255,255,0.9)')};
  background-color: ${({ $isWinner }) => ($isWinner ? '#31095161' : '#310951cd')};

  overflow: hidden;
  word-break: break-word;
  user-select: none;
  animation: 0.3s ${popIn} ease-in-out;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    padding: 10px;
  }
`;
