import React, { memo, useEffect } from 'react';
import clsx from 'clsx';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { useGetCategoryPosition } from 'modules/casino/shared/hooks/useGetCategoryPosition';
import { useShowAppFooter } from 'modules/casino/shared/hooks/useShowAppFooter';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { useAppDispatch } from 'store';
import { sectionWidgetByLayout } from './SectionWidgetMap';
import { ProvidersBarPosition } from '../../../shared/features/casinoGameProviders/constants/casinoGameProvidersConstants';
import { useProvidersBarPositionConfig } from '../../../shared/features/casinoGameProviders/hooks/useProvidersBarPositionConfig';
import { setShowBottomProvidersBar } from '../../../shared/features/casinoGameProviders/slice/casinoGameProvidersSlice';
import { CategoryLayoutTypes } from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';

interface WidgetProps {
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
  className?: string;
}

const GridWidgetSectionLayout: React.FC<WidgetProps> = ({ categoryId, categoryLayoutType, className }) => {
  const dispatch = useAppDispatch();
  const { widget, layoutConfig } = useGetCategoryParams(categoryId, categoryLayoutType);
  const { isLast } = useGetCategoryPosition(categoryId, categoryLayoutType);
  const providersBarPositionConfig = useProvidersBarPositionConfig();
  const showFooter =
    categoryLayoutType === CategoryLayoutTypes.PROVIDER_CATEGORY || categoryLayoutType === CategoryLayoutTypes.LOBBY
      ? isLast
      : true;

  useEffect(() => {
    if (providersBarPositionConfig === ProvidersBarPosition.TOP) {
      return;
    }

    dispatch(setShowBottomProvidersBar(showFooter));
  }, [dispatch, providersBarPositionConfig, showFooter]);

  useShowAppFooter([showFooter, true]);

  if (!widget?.id) {
    return <></>;
  }

  const WidgetComponentMap = sectionWidgetByLayout[categoryLayoutType];
  const WidgetTypeComponent = WidgetComponentMap && WidgetComponentMap[widget.type];
  const wrapperStyles = clsx(`casino-category-container ${cssGlobalNS}-container`, className);

  return WidgetTypeComponent ? (
    <div className={wrapperStyles}>
      <WidgetTypeComponent
        widget={widget}
        layoutGrid={layoutConfig.layoutGrid}
        categoryLayoutType={categoryLayoutType}
      />
    </div>
  ) : null;
};

GridWidgetSectionLayout.displayName = 'GridWidgetSectionLayout';
export default memo(GridWidgetSectionLayout);
