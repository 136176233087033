import React from 'react';
import Icon from 'components/shared/Icon';
type Props = {
  icon?: string;
  textBtn: string;
  onClick: React.MouseEventHandler;
};

const PrizeDropPromoViewAllBtn: React.FC<Props> = ({ icon = 'trophy', textBtn, onClick }) => {
  return (
    <egtd-promo-przd-view-all-btn onClick={onClick} data-promo={true} data-active={true} data-btn={true}>
      {textBtn}
      {icon && <Icon icon="chevron-right" size={12} />}
    </egtd-promo-przd-view-all-btn>
  );
};

export default PrizeDropPromoViewAllBtn;
