import { useEffect } from 'react';
import { useAppDispatch } from 'store';
import { DrawerThunks } from '../../../slice/drawer.slice';

const usePrefetchSportContainers = (isToggled: boolean): void => {
  const dispatch = useAppDispatch();
  // using this to fix sports tab blinking
  useEffect(() => {
    if (isToggled) {
      dispatch(DrawerThunks.fetchSportDrawerContainers());
    }
  }, [dispatch, isToggled]);
};

export default usePrefetchSportContainers;
