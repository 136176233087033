import React, { memo, RefObject, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { cdnConfig } from 'utils/common/cdnConfig';
import { cssGlobalNS } from 'utils/common/types/css.types';
import config from 'utils/config';

// Component CSS NameSpace
const cssNS = `${cssGlobalNS}-spico`;

const sportIconTypesClassNameMap = {
  slider: `${cssNS}--slider`,
  sliderTitle: `${cssNS}--slider-title`,
  burger: `${cssNS}--burger`,
  drawer: `${cssNS}--drawer`,
  tab: `${cssNS}--tab`,
  contentHeader: `${cssNS}--content-header`,
  sectionHeader: `${cssNS}--section-header`,
  containerHeader: `${cssNS}--container-header`,
  eSportsSectionHeader: `${cssNS}--esports-header`,
  betslip: `${cssNS}--betslip`,
  dropDownGroup: `${cssNS}--dropdown`,
  virtualNavTab: `${cssNS}--vnav-tab`,
} as const;

type SportIconTypes = keyof typeof sportIconTypesClassNameMap;

interface Props {
  icon: number | string | undefined;
  iconPath?: string;
  isActive?: boolean;
  type: SportIconTypes;
  initialInViewIcon?: boolean;
  wrapperRef?: RefObject<HTMLImageElement>;
  className?: string;
}

const SportIconCDN: React.FC<Props> = ({
  icon,
  iconPath = config.CDN_URL + cdnConfig.SPORT_ICONS,
  isActive,
  type,
  initialInViewIcon = false,
  wrapperRef,
  className,
}) => {
  const [scrollRef, inView] = useInView({
    root: wrapperRef?.current ? wrapperRef?.current : null,
    rootMargin: wrapperRef?.current ? '200px' : '600px',
    triggerOnce: true,
    initialInView: initialInViewIcon,
  });

  const iconsType =
    useLayoutConfigType({
      moduleLayout: 'platform',
      innerLayout: LayoutConfigTypes.ICONS_TYPE,
      deviceLayout: 'general',
    }) || 'svg';

  const fileExt = `.${iconsType}`;

  const imgSources = useMemo(
    () => [`${iconPath + '/' + icon + fileExt}`, `${iconPath + '/' + icon + '.svg'}`],
    [iconPath, icon, fileExt],
  );

  const [srcState, setSrcState] = useState({ index: 0, src: '' });

  useEffect(() => {
    setSrcState({ index: 0, src: imgSources[0] });
  }, [icon, imgSources]);

  const onError = () => {
    const newIndex = srcState.index + 1;
    if (imgSources.length > newIndex) {
      const newSourceState = { index: newIndex, src: imgSources[newIndex] };
      setSrcState(newSourceState);
    }
  };

  const loader =
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBpZD0iZVkxM0F6VEpRSDkxIiB2aWV3Qm94PSIwIDAgMzIgMzIiPjxnPjxlbGxpcHNlIG9wYWNpdHk9IjAuMSIgcng9IjYiIHJ5PSI2IiB0cmFuc2Zvcm09Im1hdHJpeCgxLjUwNzMyNiAwIDAgMS41MDczMjYgMTYgMTYpIiBmaWxsPSIjOTk5Ii8+PC9nPjwvc3ZnPg==';

  const imgClass = clsx(cssNS, isActive && `${cssNS}--active`, sportIconTypesClassNameMap[type], className);

  return isMobile ? (
    <img
      ref={scrollRef}
      className={imgClass}
      draggable="false"
      src={inView && srcState.src ? srcState.src : loader}
      onError={onError}
    />
  ) : (
    <img
      ref={scrollRef}
      className={imgClass}
      draggable="false"
      src={srcState.src ? srcState.src : loader}
      onError={onError}
    />
  );
};

export default memo(SportIconCDN);
