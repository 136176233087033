import Card from './Card.styled';
import CardBody from './CardBody.styled';
import CardDescription from './CardDescription.styled';
import CardDetails from './CardDetails.styled';
import CardMeta from './CardMeta';
import CardText from './CardText';
import CardThumb from './CardThumb.styled';
import CardTitle from './CardTitle';
import GridHeader from './GridHeader';
import PageCTA from './PageCTA';

export const Layout02 = {
  Card: Card,
  CardBody: CardBody,
  CardDescription: CardDescription,
  CardDetails: CardDetails,
  CardMeta: CardMeta,
  CardText: CardText,
  CardTitle: CardTitle,
  CardThumb: CardThumb,
  GridHeader: GridHeader,
  PageCTA: PageCTA,
};
