import { CategoryQueryType, CategoryQueryTypes } from 'modules/casino/shared/constants/constants';
import { CasinoFavouritesCategory } from '../components/containerTypes/favourites';
import { CasinoCategory } from '../components/containerTypes/general';

export const componentByQueryType: Record<CategoryQueryTypes, (categoryId: string) => JSX.Element> = {
  [CategoryQueryType.FAVOURITES_CATEGORY]: (categoryId) => <CasinoFavouritesCategory categoryId={categoryId} />,
  [CategoryQueryType.CATEGORY]: (categoryId) => <CasinoCategory categoryId={categoryId} />,
  [CategoryQueryType.QUERY_CATEGORY]: (categoryId) => <CasinoCategory categoryId={categoryId} />,
  [CategoryQueryType.MOST_PLAYED_CATEGORY]: (categoryId) => <CasinoCategory categoryId={categoryId} />,
  [CategoryQueryType.LAST_PLAYED_CATEGORY]: (categoryId) => <CasinoCategory categoryId={categoryId} />,
  [CategoryQueryType.RECOMMENDATIONS_CATEGORY]: (categoryId) => <CasinoCategory categoryId={categoryId} />,
  [CategoryQueryType.SVARA_CATEGORY]: (categoryId) => <CasinoCategory categoryId={categoryId} />,
};

export const DEFAULT_CATEGORY = {
  id: '999',
  query: '',
  alias: '',
  title: '',
  layoutConfig: {
    layout: '',
    layoutGrid: '',
    layoutRows: '',
  },
  queryType: '',
  isFilterEnabled: false,
  isPublic: true,
};

export const CUSTOM_INNER_CATEGORY_JACKPOTS = ['egypt-quest-jackpot'];
