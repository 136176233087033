import { isMobile, isTablet } from 'react-device-detect';
import { EventLayoutType, EventSelectors } from 'components/shared/sportContainers/event/types/eventProps.types';
import { homePageEventSelectors, homePageThunks, selectHomePage } from 'pages/homePage/slice/homePage.slice';
import { LoaderName } from 'shared/common/features/loaderData/matchWithActions';
import { WsComponent } from 'shared/common/features/websockets/types/webSockets.types';
import { Sport } from 'utils/common/types/sportTypes';

export const containerLocationPlatformDataMap = (componentLocation: Sport.ContainerLocation) => {
  const componentMap: Record<Sport.ContainerLocation, WsComponent> = {
    homePage: 'HomePageContainers',
  };

  const homePageSlice = { thunk: homePageThunks, selector: selectHomePage };

  const sliceMap: Record<Sport.ContainerLocation, { thunk; selector }> = {
    homePage: homePageSlice,
  };

  const sportTypeMap: Record<Sport.ContainerLocation, Sport.ContainerLocation> = {
    homePage: 'homePage',
  };
  const eventSelectorMap: Record<Sport.ContainerLocation, EventSelectors> = {
    homePage: homePageEventSelectors,
  };

  const loaderMap: Record<Sport.ContainerLocation, LoaderName> = {
    homePage: 'homePage',
  };

  return {
    eventSelector: eventSelectorMap[componentLocation],
    transformSportType: sportTypeMap[componentLocation],
    slice: sliceMap[componentLocation],
    component: componentMap[componentLocation],
    loader: loaderMap[componentLocation],
  };
};

export const isScrollable = {
  layout_1: isMobile || isTablet, // Scroll only for mobile
  layout_2: false, // no scroll
  layout_3: true, // scroll for mobile and desktop
};

// Mixed Highlights force event Layout by wrapper and configurations layout
// Is Scrollable Layout
const layout_1 = {
  layout_1: 'layout_5',
  layout_2: 'layout_2',
};
//is Column layout
const layout_2 = {};
// Is Scrollable Layout
const layout_3 = {
  layout_1: 'layout_5',
  layout_2: 'layout_2',
};

const mapForceEventLayout = {
  layout_1: layout_1,
  layout_2: layout_2,
  layout_3: layout_3,
};
export const triggerEventLayoutConfig = ({ wrapperLayout, eventLayout }): EventLayoutType | undefined => {
  return mapForceEventLayout[wrapperLayout][eventLayout];
};
