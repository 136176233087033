import styled from 'styled-components';

export const StyledLink = styled.div`
  z-index: 3;

  display: flex;
  align-items: center;

  margin: 10px 0;
  padding: 5px 0;
  min-height: 30px;

  font-size: 12px;
  text-align: center;
  color: #fff;
  fill: currentColor;
`;
