import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import isEqual from 'lodash/isEqual';
import { FieldsType, FieldValues, FormFieldTypes, GtmRegistrationParams } from 'components/shared/forms/formTypes';
import { utmCookieName } from 'components/shared/UtmCookieQueryParams';
import userStorage from 'pages/auth/login/UserStorage';
import { setPlayerId, setPlayerPhoneNumber } from 'pages/auth/phoneConfirmation/slice/phoneConfirmation.slice';
import { setNetAppHeader } from 'shared/common/features/general/slice/general.slice';
import { getDefaultBusinessUnitThemeSkin } from 'shared/common/features/myProfile/utils/myProfile.utils';
import { AppDispatch } from 'store';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { createCookie, getCookie, getError, isEmpty } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { FieldsTransformMap, FormSteps, RegisterTypes } from '../types/registration.types';

export const registrationInitialState: RegisterTypes.State = {
  isLoading: true,
  fetchFormFieldsError: '',
  fields: {},
  stepData: {} as Record<number, FieldValues>,
  activeStep: FormSteps.FIRST,
  totalSteps: 0,
  newUser: null,
  newUserError: null,
  isSubmitting: 'idle',
  isFetchingDropdowns: true,
  helpMenu: [],
  dropdowns: {},
  withSocial: null,
};

export const RegistrationThunks: RegisterTypes.Thunks = {
  createPlayer: createAbortThunk('registration/createPlayer', async (_, { rejectWithValue, getState, dispatch }) => {
    const stepsData = getState().auth.registration.stepData;
    if (!stepsData) return;
    const data = Object.keys(stepsData)?.reduce((stepData, key) => {
      return { ...stepData, ...stepsData[key] };
    }, {});
    const preferredTheme = getDefaultBusinessUnitThemeSkin();
    const affiliateToken: string = getCookie('affiliateToken');
    const utmCookie: string = getCookie(utmCookieName)?.replaceAll(',', '; ');
    const timezoneName = userStorage.getAutomaticTimeZone()?.tz;

    const dataForSend: FieldValues = {
      ...data,
      preferredTheme,
      timezoneName,
      ...(utmCookie && { utmCookie }),
      ...(affiliateToken && { affiliateToken }),
    };

    Object.entries(FieldsTransformMap).forEach(([key, value]) => (dataForSend[key] = dataForSend[value]));

    Object.entries(dataForSend).forEach(([key, value]) => {
      if (value === '') {
        delete dataForSend[key];
      }
    });

    const fbpCookieValue = getCookie('_fbp');
    const fbcCookieValue = getCookie('_fbc');

    const queryParams = {
      ...(fbpCookieValue && { fbp: encodeURIComponent(fbpCookieValue) }),
      ...(fbcCookieValue && { fbc: encodeURIComponent(fbcCookieValue) }),
    };

    try {
      const url = `${config.API_URL}/api/ews-crm/public/players`;
      const response = await axiosInstance.post(url, dataForSend, { params: queryParams });

      response.data?.phoneNumber && dispatch(setPlayerPhoneNumber(response.data.phoneNumber));
      response.data?.id && dispatch(setPlayerId(response.data.id));

      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseDataMessage(err));
    }
  }),
  fetchFormFields: createAbortThunk('registration/fetchFormFields', async (_, { dispatch, rejectWithValue }) => {
    try {
      const url = `${config.API_URL}/api/ews-crm/public/cms/player-content?key=registration_form`;
      const response = await axiosInstance.get(url);
      dispatch(fetchDropdowns(response.data));
      if (response.headers?.['x-platform-hash-netapp']) {
        dispatch(setNetAppHeader(response.headers['x-platform-hash-netapp']));
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(getError.message(err));
    }
  }),
  fetchDropdown: createAbortThunk('registration/fetchDropdown', async ({ apiUrl }) => {
    const url = `${config.API_URL}${apiUrl}`;
    const response = await axiosInstance.get(url);
    return response.data;
  }),
  fetchHelpMenu: createAbortThunk('registration/fetchHelpMenu', async () => {
    const url = `${config.API_URL}/api/cms/public/registration-content`;
    const response = await axiosInstance.get(url);
    return response.data;
  }),
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState: registrationInitialState,
  reducers: {
    clearFormData(state) {
      state.stepData = registrationInitialState.stepData;
    },
    setFormData(state, action: PayloadAction<{ step: number; data: FieldValues }>) {
      const { step, data } = action.payload;
      if (!isEqual(state.stepData[step], data)) {
        state.stepData[step] = data;
      }
    },
    setFormDataField(state, action: PayloadAction<Partial<Record<FieldsType, string | undefined | object>>>) {
      if (state.fields?.steps) {
        const socialInfo = Object.keys(action.payload);

        Object.keys(state.fields?.steps).forEach((step: string) => {
          const stepFields: FormFieldTypes[] = state.fields?.steps?.[step];
          const fields = stepFields?.filter((field) => socialInfo.includes(field.name));

          fields.forEach((field) => {
            state.stepData[step] = {
              ...state.stepData[step],
              [field.name]: action.payload[field.name],
            };
          });
        });
      }
    },
    setActiveStep(state, action: PayloadAction<number>) {
      state.activeStep = action.payload;
    },
    setWithSocial(state, action: PayloadAction<string | null>) {
      state.withSocial = action.payload;
    },
    clearErrorMessage(state) {
      state.newUserError = null;
    },
    clearRegistrationState: () => registrationInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(RegistrationThunks.createPlayer.fulfilled, (state, action: PayloadAction<RegisterTypes.NewUser>) => {
        state.newUser = action.payload;
        state.isSubmitting = 'succeeded';
      })
      .addCase(RegistrationThunks.createPlayer.pending, (state) => {
        state.isSubmitting = 'pending';
      })
      .addCase(RegistrationThunks.createPlayer.rejected, (state, action) => {
        state.isSubmitting = 'failure';
        state.newUserError = action.payload as string;
      });
    builder
      .addCase(
        RegistrationThunks.fetchFormFields.fulfilled,
        (state, action: PayloadAction<RegisterTypes.FormFieldsResponse>) => {
          const { steps } = action.payload;
          if (!steps) return;
          if (
            !Object.values(steps)
              ?.flat()
              ?.some((f) => f.apiUrl)
          ) {
            state.isFetchingDropdowns = false;
          }

          state.fields.steps = Object.keys(steps).reduce((acc, key) => {
            acc[key] = steps[key];
            return acc;
          }, {});
          const stepsKeys = Object.keys(state.fields.steps);
          state.activeStep = Number(stepsKeys[0]);
          state.totalSteps = stepsKeys.length;
          state.isLoading = false;
        },
      )
      .addCase(RegistrationThunks.fetchFormFields.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(RegistrationThunks.fetchFormFields.rejected, (state, action) => {
        state.isLoading = false;
        state.fetchFormFieldsError = action.payload as string;
      });
    builder
      .addCase(RegistrationThunks.fetchDropdown.pending, (state) => {
        state.isFetchingDropdowns = true;
      })
      .addCase(RegistrationThunks.fetchDropdown.fulfilled, (state, action) => {
        const { name } = action.meta.arg;
        state.dropdowns[name] = action.payload;
        state.isFetchingDropdowns = false;
      })
      .addCase(RegistrationThunks.fetchDropdown.rejected, (state) => {
        state.isFetchingDropdowns = false;
      });
    builder.addCase(
      RegistrationThunks.fetchHelpMenu.fulfilled,
      (state, action: PayloadAction<RegisterTypes.HelpMenuItems[]>) => {
        state.helpMenu = action.payload;
      },
    );
    builder.addMatcher(isAnyOf(RegistrationThunks.createPlayer.fulfilled), () => {
      const affiliateCookie = getCookie('affiliateToken');
      const utmCookie = getCookie(utmCookieName);

      if (affiliateCookie) createCookie({ name: 'affiliateToken', value: '', expiration: 0 }); //delete cookie
      if (utmCookie) createCookie({ name: utmCookieName, value: '', expiration: 0 }); //delete cookie
    });
  },
});

export const {
  setFormData,
  clearErrorMessage,
  clearRegistrationState,
  setActiveStep,
  setFormDataField,
  setWithSocial,
  clearFormData,
} = registrationSlice.actions;

export const selectGTMRegistrationParams = createSelector(
  (state: RootState): Record<number, FieldValues> => state.auth.registration.stepData,
  (state: RootState): Record<string, RegisterTypes.DropdownType[]> => state.auth.registration.dropdowns,
  (state: RootState): RegisterTypes.NewUser => state.auth.registration.newUser,
  (steps: Record<number, FieldValues>, dropdowns, newUser: RegisterTypes.NewUser) => {
    return Object.values(steps).reduce(
      (acc, val: FieldValues) => {
        const {
          email,
          phoneNumber: phone_number,
          firstName: first_name,
          lastName: last_name,
          city,
          countryName: countryId,
          street,
        } = val;
        const { userId } = { userId: newUser?.id };
        let { country } = { country: '' };
        if (countryId) {
          const countryDropdown = 'countryName';
          const countryObj = dropdowns[countryDropdown].find((item) => item.value === countryId);
          if (countryObj) {
            country = countryObj.text;
          }
        }

        const addressParams = {
          ...(first_name && { first_name }),
          ...(last_name && { last_name }),
          ...(city && { city }),
          ...(country && { country }),
          ...(street && { street }),
        };
        const accAddress = acc['gtmRegistrationParams']?.address;
        const address = { ...accAddress, ...addressParams };

        const gtmParams = {
          ...(userId && { userId }),
          ...(phone_number && { phone_number }),
          ...(email && { email }),
          ...(address && { address }),
        };
        acc['gtmRegistrationParams'] = { ...acc['gtmRegistrationParams'], ...gtmParams };

        return acc;
      },
      { gtmRegistrationParams: {} } as { gtmRegistrationParams: GtmRegistrationParams },
    );
  },
);

export const selectRegistration = {
  dropdowns: (state: RootState): Record<string, RegisterTypes.DropdownType[]> => state.auth.registration.dropdowns,
  createdUser: (state: RootState): RegisterTypes.NewUser => state.auth.registration.newUser,
  newUserError: (state: RootState): string | null => state.auth.registration.newUserError,
  stepData:
    (step?: number) =>
    (state: RootState): FieldValues | undefined =>
      state.auth.registration.stepData?.[step || state.auth.registration.activeStep],
  stepFields: (state: RootState): FormFieldTypes[] | undefined =>
    state.auth.registration?.fields?.steps?.[state.auth.registration.activeStep],
  totalSteps: (state: RootState): number => state.auth.registration?.totalSteps,
  isCreatingPlayer: (state: RootState): boolean =>
    ['pending', 'succeeded'].includes(state.auth.registration?.isSubmitting),
  isSubmitting: (state: RootState): string => state.auth.registration?.isSubmitting,
  fetchFormFieldsError: (state: RootState): string => state.auth.registration?.fetchFormFieldsError,
  isFetchingFields: (state: RootState): boolean =>
    state.auth.registration?.isLoading || state.auth.registration?.isFetchingDropdowns,
  helpMenus: (state: RootState): RegisterTypes.HelpMenuItems[] => state.auth.registration.helpMenu,
  activeStep: (state: RootState): number => state.auth.registration.activeStep,
  firstStep: (state: RootState): number =>
    state.auth.registration?.fields?.steps
      ? Number(Object.keys(state.auth.registration?.fields?.steps)[0])
      : state.auth.registration.activeStep,
  withSocial: (state: RootState): string | null => state.auth.registration?.withSocial,
  isSocialRegistration: (state: RootState) => {
    const acc: FieldValues = {};
    const data = Object.keys(state.auth.registration.stepData)?.reduce(
      (a, k) => ({ ...a, ...state.auth.registration.stepData[k] }),
      acc,
    )?.socialMediaCredentials;
    return !isEmpty(data);
  },
  GTMRegistrationParams: selectGTMRegistrationParams,
};

export default registrationSlice.reducer;

export const fetchDropdowns =
  (fields?: RegisterTypes.FormFieldsResponse) =>
  async (dispatch: AppDispatch): Promise<void | string | undefined> => {
    try {
      Object.values(fields?.steps || {})
        ?.flat()
        ?.filter((f) => f.apiUrl)
        ?.map((f) => dispatch(RegistrationThunks.fetchDropdown({ apiUrl: f.apiUrl as string, name: f.name })));
    } catch (err) {
      return (err as AxiosError<string | void | undefined>).response?.data;
    }
  };

export const getPromoCodeValidation = async (params: {
  promoCode: string;
}): Promise<RegisterTypes.ValidationRequestResponse | undefined> => {
  try {
    const url = `${config.API_URL}/api/ews-crm/public/validation/exist-promotion`;
    const response = await axiosInstance.get(url, { params });
    return response;
  } catch (err) {
    return (err as AxiosError<string & { message: string }>).response;
  }
};
