import { useSetRefsLoaded } from 'modules/casino/shared/hooks/useSetRefsLoaded';
import useBigWinImage from '../../../hooks/useBigWinImage';

interface ImageProps {
  imageSrc: string;
}

const ImageWrapper: React.FC<ImageProps> = ({ imageSrc }) => {
  const { setRefs, ref: imageContainerRef } = useSetRefsLoaded();
  const backgroundWrapper = useBigWinImage({ imageSrc, imageContainerRef });
  const wrapperStyle = {
    backgroundImage: backgroundWrapper && backgroundWrapper,
  };

  return <div className={'bww__game-image'} ref={setRefs} style={wrapperStyle} />;
};

export default ImageWrapper;
