import { useEffect } from 'react';
import { useGetFavGamesCounter } from 'modules/casino/modules/container/cellsContainer/cellls/additionalGames/favourites/hooks/useGetFavGamesCounter';
import {
  favoriteGamesSelectors,
  setIsFavouriteGamesModalOpen,
} from 'modules/casino/modules/container/cellsContainer/cellls/additionalGames/favourites/slice/casinoFavouriteGamesSlice';
import { CasinoFavouritesTypes } from 'modules/casino/modules/container/cellsContainer/cellls/additionalGames/favourites/types/casinoFavouriteGamesTypes';
import { useCasinoGamesFetchStatus } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useCasinoGamesFetchStatus';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { useInitialJackpotLevelsFetch } from 'modules/casino/shared/features/jackpots/hooks/useInitialJackpotLevelsFetch';
import { toggleNoScrollClasses } from 'modules/casino/shared/utils/common/toggleNoScrollClasses';
import { useAppDispatch, useAppSelector } from 'store';
import { useFetchFavouriteGames } from '../../../../../../cellls/additionalGames/favourites/hooks/useFetchFavouriteGames';
import { useCasinoCategoryFetch } from '../../../../hooks/useCasinoCategoryFetch';

export const useCasinoFavouritesModal = (): CasinoFavouritesTypes.UseCasinoFavouritesModalReturnProps => {
  const dispatch = useAppDispatch();
  useInitialJackpotLevelsFetch();

  const subType = CategorySubTypes.FAVOURITES_MODAL;
  const { categoryId, layoutConfig, query } = useCasinoCategoryFetch({
    subType,
    categoryIdKey: subType,
    skipAbortFetch: true,
  });
  const { isLoading, hasSuccess, hasNoGames, hasError } = useCasinoGamesFetchStatus(
    categoryId,
    CategoryLayoutTypes.FAVOURITES_CATEGORY,
  );
  useFetchFavouriteGames({
    subType: CategorySubTypes.FAVOURITES_MODAL,
    layoutConfig,
    query,
    hasSuccess,
    hasError,
    hasNoGames,
  });
  const gamesCount = useGetFavGamesCounter();
  const isModalOpen = useAppSelector(favoriteGamesSelectors.isFavouriteGamesModalOpen);

  useEffect(() => {
    if (isModalOpen) {
      toggleNoScrollClasses(true, true);

      return () => {
        toggleNoScrollClasses(false, true);
      };
    }
  }, [dispatch, isModalOpen]);

  const handleCloseModal = () => {
    dispatch(setIsFavouriteGamesModalOpen(false));
  };

  return {
    categoryId,
    gamesCount,
    isLoading,
    hasSuccess,
    hasNoGames,
    isModalOpen,
    handleCloseModal,
  };
};
