import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormUI } from 'components/shared/forms/components';
import { Modal } from 'components/shared/Modal';
import { ModalUI } from 'components/shared/ModalUI';
import { UI } from 'components/shared/UI';
import { useShowMyProfileUntilUnmount } from 'hooks/common/useShowMyProfileUntilUnmount';
import useCasinoWidgetConfig from 'modules/casino/platformImplementation/hooks/useCasinoWidgetConfig';
import { fetchBonusGames } from 'modules/casino/shared/features/gameLaunchBonus/slice/gameLaunchBonus.slice';
import { refreshWidget } from 'shared/common/features/integrations/cashierWidget/cashierWidget.slice';
import { setRefetchBonusGames } from 'shared/common/features/integrations/slice/integrations.slice';
import AmountCurrency from 'shared/common/features/myProfile/components/AmountCurrency';
import {
  fetchBonusCancelInfo,
  fetchCategories,
  postBonus,
  resetBonusCancelInfo,
  selectOffersTab,
} from 'shared/common/features/myProfile/sections/tabs/offersTab/slice/offersTab.slice';
import {
  Bonus,
  BonusButtonAction,
} from 'shared/common/features/myProfile/sections/tabs/offersTab/types/offersTab.types';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { CustomPlatformEvents } from 'shared/common/hooks/useHandleCustomEvents';
import { useAppDispatch, useAppSelector } from 'store';
import { transformStringTemplate } from 'utils/common/helpersCommon';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';

type Props = {
  bonus: Bonus;
};

const CancelOfferModal: React.FC<Props> = ({ bonus }) => {
  const bonusCancelInfo = useAppSelector(selectOffersTab.bonusCancelInfo);
  const cancelInfoLoading = useAppSelector(selectOffersTab.cancelInfoLoading);
  const { status, error } = useAppSelector((state) => selectOffersTab.bonusStatusAndError(state, bonus.id));
  const casinoWidgetConfig = useCasinoWidgetConfig();

  const cancelBtn = bonus.buttons?.find((button) => button.buttonActionType === BonusButtonAction.Cancel);

  const isBonusAccepted =
    cancelBtn &&
    !bonus.buttons?.some(
      (button) =>
        button.buttonActionType === BonusButtonAction.Accept || button.buttonActionType === BonusButtonAction.Navigate,
    );

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useShowMyProfileUntilUnmount();

  useEffect(() => {
    dispatch(fetchBonusCancelInfo(bonus.id));
    return () => {
      dispatch(resetBonusCancelInfo());
    };
  }, [dispatch, bonus.id]);

  const handleOfferAction = async () => {
    if (status === 'pending' || cancelInfoLoading || !cancelBtn) return;
    await dispatch(
      postBonus({
        buttonUrl: cancelBtn.buttonUrl,
        id: `${bonus.id}`,
        bonusCode: bonus.bonusCode,
      }),
    );
    dispatch(fetchCategories());
    dispatch(refreshWidget());
    document.dispatchEvent(new Event(CustomPlatformEvents.FETCH_SPORT_BONUSES));

    if (casinoWidgetConfig) {
      dispatch(setRefetchBonusGames(true));
    } else {
      dispatch(fetchBonusGames());
    }
  };

  const cancelMessage = transformStringTemplate({
    template: t(MyProfile.OffersTab.cancelBonusTemplateText),
    placeholder: '{{BALANCE}}',
    component: bonusCancelInfo && bonusCancelInfo.length ? <AmountCurrency amount={bonusCancelInfo} /> : undefined,
    fallback: t(MyProfile.OffersTab.cancelConfirmText),
  });

  if (!cancelMessage || cancelInfoLoading) return null;

  return (
    <Modal visible className="modal-terms">
      <Modal.Close closeText={t(MyProfile.OffersTab.closeBtn)} closeAction={() => dispatch(hideRootModal())} />
      <Modal.Body className="d-flex-col-ac p-4 text-center">
        {isBonusAccepted ? (
          <>
            <ModalUI.Title text={bonus.title} />
            <ModalUI.Title className="mt-3" hasAccent>
              {cancelMessage}
            </ModalUI.Title>
          </>
        ) : (
          <ModalUI.Title hasAccent text={t(MyProfile.OffersTab.cancelConfirmText)} />
        )}

        {status === 'failure' && error ? (
          <FormUI.Message hasFormGroup type="error" content={t(error)} />
        ) : (
          <UI.Button
            className="mt-4"
            size="lg"
            isDisabled={status === 'pending' || cancelInfoLoading}
            onClick={handleOfferAction}
            text={t(MyProfile.OffersTab.confirmBtn)}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CancelOfferModal;
