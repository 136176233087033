import { memo, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import usePreloadAssets from 'components/shared/FrameAnimator/hooks/usePreloadAssets';
import { mapAnimationAssets } from 'components/shared/FrameAnimator/utils/images/utils';
import { Flex } from 'components/shared/layout';
import { defineAnimationParams } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationUtils';
import { Breakpoints } from 'theme/Theme';
import DwarfsWheel from './DwarfsWheel';
import CTAButton from './DwarfsWheelUI/CTAButton';
import Dwarf from './DwarfsWheelUI/Dwarf';
import { NotificationTypes } from '../../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import { GamificationUI } from '../../components/GamificationUI';
import useAnimationComplete from '../../hooks/useAnimationComplete';
import { useAutoScrollToDepositButton } from '../../hooks/useAutoScrollToDepositButton';
import useHandleBonusActions from '../../hooks/useHandleBonusActions';
import useOfferTerms from '../../hooks/useOfferTerms';

const DwarfsWheelContainer: React.FC<{ notification: NotificationTypes.PopUpNotification }> = ({ notification }) => {
  const { bonusData, header, subheader, message, style } = notification;
  const singleBonus = bonusData?.bonuses?.[0];

  const { animationComplete, setAnimationComplete } = useAnimationComplete();

  useAutoScrollToDepositButton(animationComplete);

  const animationParams = useMemo(
    () =>
      defineAnimationParams({
        animationKey: style.animationKey,
        layout: style.layout,
        folder: style.folder,
      }),
    [style.animationKey, style.layout, style.folder],
  );

  const module = useMemo(() => mapAnimationAssets(animationParams), [animationParams]);
  usePreloadAssets({ module, loadingFinished: true });

  const { showTerms, outsideClick, offerTerms, fetchBonusTerm } = useOfferTerms(singleBonus);
  const { handleOfferAction, error, status, statusCode } = useHandleBonusActions({
    bonus: singleBonus,
    notificationId: notification.id,
  });

  useAutoScrollToDepositButton(animationComplete);

  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });

  if (!singleBonus) return null;

  const winnerSegment = bonusData?.otherOptions?.findIndex((option) => option === singleBonus?.title);

  return (
    <>
      <GamificationUI.Header>
        <GamificationUI.CTAText
          showWinText={animationComplete}
          text={header}
          winText={message}
          error={error}
          className="text-center"
          style={{
            padding: '4px',
            minHeight: '40px',
            fontWeight: 400,
            marginBottom: '38px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 60px',
            backgroundImage: `linear-gradient(
              90deg,
              rgba(26, 214, 207, 0) 0%,
              rgba(65, 76, 208, 0.4) 50%,
              rgba(26, 214, 207, 0) 100%
            )`,
          }}
        />
      </GamificationUI.Header>

      <DwarfsWheel
        folder={animationParams.folder}
        segments={bonusData.otherOptions}
        winnerSegment={winnerSegment}
        setAnimationComplete={setAnimationComplete}
        notificationId={notification.id}
      />
      <Flex.ColAC className="w-100">
        <CTAButton
          className={!isDesktop ? 'mr-auto' : ''}
          buttonText={singleBonus?.buttonText}
          disabled={status === 'pending'}
          errorText={error}
          onClick={handleOfferAction}
          isVisible={animationComplete}
          statusCode={statusCode}
          text={subheader}
          folder={animationParams.folder}
        />
        <GamificationUI.Link
          isActive={showTerms}
          isVisible={animationComplete}
          onClick={fetchBonusTerm}
          className="text-center"
        />
        <Dwarf folder={animationParams.folder} />
      </Flex.ColAC>

      <GamificationUI.Terms isVisible={showTerms} onClickOutside={outsideClick} text={offerTerms} />
    </>
  );
};

export default memo(DwarfsWheelContainer);
