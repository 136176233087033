import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useScrollToBackYPositiion } from 'hooks/useScrollToBackYPositiion';
import { useBottomTabBarProps } from 'shared/common/features/bottomTabBar/hooks/useBottomTabBarProps';
import { selectNavigationMenu } from 'shared/common/features/navigationMenu/slice/navigationMenu.slice';
import SectionHeader from 'shared/common/features/seo/seoData/components/SectionHeader';
import ParseHTML from 'shared/common/ParseHTML';
import { useAppSelector } from 'store';
import { Footer } from 'utils/common/translationUtils/translationStrings';
import ViewLessBtn from './ViewLessBtn';
import { useFetchSeoData } from '../hooks/useFetchSeoData';

type showFooterSectionProps = {
  showFooterSection: boolean;
};

const ExpandableSection: FC<showFooterSectionProps> = ({ showFooterSection }) => {
  const varticalPathname = `/${window.location.pathname.split('/')[1]}`;
  const vertical = useAppSelector(selectNavigationMenu.activeVertical(varticalPathname));
  const { seoData } = useFetchSeoData({
    verticalId: vertical?.id,
    hasSeoContent: vertical?.hasSeoContent,
    isLandingPage: varticalPathname === '/landing',
  });
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { t } = useTranslation();
  const { scrollToBack } = useScrollToBackYPositiion();
  const { isBottomTabBar } = useBottomTabBarProps();
  const noAppFooterWithBottomTabBar = !showFooterSection && isBottomTabBar && !isDesktop;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    scrollToBack({ setScrollPosition: true });
  };

  const scrollToEnd = () => {
    setIsExpanded(false);
    scrollToBack({ setScrollPosition: false });
  };

  useEffect(() => {
    setIsExpanded(!!seoData?.isExpanded);
  }, [seoData?.isExpanded, vertical?.id]);

  const iconClass = clsx('btn-exp-toggler__icon section-icon', isExpanded && 'rotated-180');
  const sectionClass = clsx(
    'd-flex-col footer-section footer-section-expandable no-scrollbars pb-0',
    isExpanded && 'footer-section-expanded',
    noAppFooterWithBottomTabBar && 'footer-section-expandable-mb',
  );

  if (!seoData?.content) return <></>;

  return (
    <div className={sectionClass}>
      <SectionHeader text={seoData?.title} onClick={toggleExpand} icon="chevron" iconClass={iconClass} />

      {isExpanded && (
        <>
          <div className="expandable-content mt-2">
            <ParseHTML html={seoData?.content || ''} options={{ trim: false }} />
          </div>
          <ViewLessBtn text={t(Footer.SeoContent.seoBtnText)} icon="chevron-top" onClick={scrollToEnd} />
        </>
      )}
    </div>
  );
};

export default ExpandableSection;
