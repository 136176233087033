import React from 'react';
import clsx from 'clsx';

interface Props {
  description: string;
  className?: string;
}

const GameCellDescription: React.FC<Props> = ({ description, className }) => {
  return (
    <div className={clsx(['w-100 px-1 text-center text-truncate casino-game-cell__title', className && className])}>
      {description}
    </div>
  );
};
export default GameCellDescription;
