import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { useNavigateTo } from 'hooks/navigation';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';

const TerminalRedirectHomePage = () => {
  const navigateTo = useNavigateTo();
  const redirectTimeout = 1000 * 60 * 3; //3 minutes in milliseconds useIdletime works with milliseconds
  const redirectTimeoutConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.TERMINAL,
  })?.redirectTimeout;
  const redirectTimeoutInterval = redirectTimeoutConfig ? redirectTimeoutConfig * 1000 * 60 : redirectTimeout;
  useIdleTimer({
    timeout: redirectTimeoutInterval,
    onIdle: () => {
      if (location.pathname !== '/sports/live-program') {
        navigateTo.navigate('/');
      }
    },
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
    ],
  });
  return <></>;
};

export default TerminalRedirectHomePage;
