import React, { memo, useRef } from 'react';
import { fitCover } from 'modules/casino/shared/utils/common/cdnConfig';
import config from 'utils/config';

interface Props {
  logoUrl: string;
}

const JackpotLogo: React.FC<Props> = ({ logoUrl }) => {
  const uri = `${config.CDN_IMAGE_API}${fitCover('auto', 'auto')}/${logoUrl}`;
  const imageRef: React.MutableRefObject<HTMLImageElement | null> = useRef(null);

  if (imageRef.current !== null) {
    imageRef.current.src = '';
  }

  return (
    <div className="jp-logo">
      <img className="jp-logo__image" src={uri} ref={imageRef} />
    </div>
  );
};
export default memo(JackpotLogo);
