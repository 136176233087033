import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import { getError } from 'utils/common/helpersCommon';
import { winnersMock } from '../mock';
import { WinnersPageTypes } from '../types/winnersPage.types';

const initialState: WinnersPageTypes.InitialState = {
  winners: [],
  isLoading: false,
  hasError: false,
};

export const winnersPageThunks: WinnersPageTypes.Thunks = {
  getWinners: createAbortThunk('winnersPage/getWinners', async (_, { rejectWithValue }) => {
    try {
      // const urlApi = `${config.API_URL}/api/cms/public/gamification-winners/${getBusinessUnit()}`;
      // const response = await axiosInstance.get(urlApi);
      return winnersMock;
    } catch (err) {
      if (Axios.isCancel(err)) {
        return rejectWithValue(null);
      }
      return rejectWithValue(getError.default());
    }
  }),
};

const winnersPageSlice = createSlice({
  name: 'winnersPageSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(winnersPageThunks.getWinners.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(winnersPageThunks.getWinners.fulfilled, (state, action) => {
        state.winners = action.payload;
        state.isLoading = false;
      })
      .addCase(winnersPageThunks.getWinners.rejected, (state) => {
        state.isLoading = false;
        state.hasError = false;
      });
  },
});

export const { reducer: winnersPageReducer } = winnersPageSlice;

export const selectWinnersPage = {
  winners: (state: RootState): WinnersPageTypes.Winner[] => state.captainUp.winnersPage.winners || [],
  isLoading: (state: RootState): boolean => state.captainUp.winnersPage.isLoading,
  hasError: (state: RootState): boolean => state.captainUp.winnersPage.hasError,
};
