import React, { memo } from 'react';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex } from 'components/shared/layout';
import { selectDrawer, setDrawer } from 'shared/common/features/drawer/slice/drawer.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { DrawerTogglerProps } from './drawerTogglerTypes';

const RetailDrawerToggler: React.FC<DrawerTogglerProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const isDrawerVisible = useAppSelector(selectDrawer.isVisible);
  const location = useLocation();
  const navigate = useNavigate();

  const handleDrawer = () => {
    if (!isDrawerVisible) {
      if (location.pathname === '/handheld-deposit') {
        navigate('/');
      }
      dispatch(setDrawer(true));
    } else {
      dispatch(setDrawer(false));
    }
  };

  const burgerClass = clsx('burger', isDrawerVisible && 'active', className);
  return (
    <Flex.Center className={burgerClass} onClick={handleDrawer} data-qid="drawerToggler">
      <span className="burger-box">
        <span className="burger-bar" />
      </span>
    </Flex.Center>
  );
};

export default memo(RetailDrawerToggler);
