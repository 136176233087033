import React from 'react';
import clsx from 'clsx';
import { CasinoWidgetsTypes } from 'modules/casino/modules/container/widget/types/casinoWidgetsTypes';
import { Flex } from 'modules/casino/shared/components/layout';
import JackpotSection from 'modules/casino/shared/features/jackpots/components/JackpotSection';
import useCategoryJackpot from 'modules/casino/shared/hooks/useCategoryJackpot';
import { useSetRefsLoaded } from 'modules/casino/shared/hooks/useSetRefsLoaded';
import { JackpotsWidgetCellButton, JackpotsWidgetCellLogo, JackpotsWidgetCellWrapper } from '../components';

interface TextProps {
  jackpotItem: CasinoWidgetsTypes.WidgetJackpot;
}

const JackpotsWidgetCell: React.FC<TextProps> = ({ jackpotItem }) => {
  const { refLoaded, setRefs, ref } = useSetRefsLoaded();
  const { jackpotDetails, jackpotId } = useCategoryJackpot(jackpotItem.jackpotId);

  if (!jackpotDetails) {
    return <></>;
  }

  const jackpotCellClass = clsx('jackpot-widget-cell', jackpotDetails?.template || '');

  return (
    <div className={jackpotCellClass} ref={setRefs}>
      {refLoaded && (
        <JackpotsWidgetCellWrapper
          backgroundUrl={jackpotDetails?.widgetBackgroundLocation || ''}
          jackpotWidgetCellRef={ref}
          classNames={'jackpot-widget-cell--wrapper'}
        >
          <JackpotSection jackpot={jackpotDetails} jackpotId={jackpotId} fromWidget widgetLogo />
          <Flex.ColCenter>
            <JackpotsWidgetCellLogo providerId={jackpotDetails?.gameProviderId || ''} />
            <JackpotsWidgetCellButton link={jackpotItem?.hyperlink} buttonSize="md" />
          </Flex.ColCenter>
        </JackpotsWidgetCellWrapper>
      )}
    </div>
  );
};

export default JackpotsWidgetCell;
