import React from 'react';
import styled from 'styled-components';
import { Icon } from 'modules/casino/shared/components/icons';

type Props = {
  icon?: string;
  text?: string;
};

const BigWinIcon: React.FC<Props> = ({ icon, text }) => {
  return (
    <BigWinIconStyled>
      <BigWinIconWrapperStyled className={`bww-item__type-wrapper`}>
        <div className={`bww-item__type-wrapper-icon d-flex-center wh-100`}>
          <Icon icon={icon} className={`bww-item__type-icon`} size={14} />
        </div>
      </BigWinIconWrapperStyled>
      <BigWinTextStyled>{text}</BigWinTextStyled>
    </BigWinIconStyled>
  );
};

const BigWinIconStyled = styled.div.attrs({ className: `bww-item__type d-flex` })`
  color: #fff;
  position: absolute;
  top: 10px;
  left: 6px;
`;
const BigWinIconWrapperStyled = styled.div`
  flex: 0 0 26px;
  height: 26px;
  border-radius: 100%;
  margin-right: 4px;
  overflow: hidden;
`;
const BigWinTextStyled = styled.span`
  word-spacing: 100vw;
  font-weight: 700;
`;

export default BigWinIcon;
