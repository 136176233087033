import i18next, { t } from 'i18next';
import { formatAmountToBRL, formatCurrency } from 'modules/retail/modules/cashier/utils';

import {
  LastPartManualReofferParams,
  MapBetbuilderEventsDataParams,
  MapBetBuilderSignManualReofferParams,
  MapBetbuilderSignParams,
  MapBetbuilderSummaryBetDataParams,
  MapEventContentSingleDataParams,
  MapEventContentSportEventNameParams,
  MapFirstPartManualReofferParams,
  MapFirstPartReceiptInfoMobileParams,
  MapLastPartContentParams,
  MapManualReofferBetsDataParams,
  MapSecondPartManualReofferParams,
  MapSummaryDataParams,
  MapSummaryManualReofferParams,
  MapSummaryVirtualDataParams,
  OutcomesData,
  ProcessRegularBetSelectionParams,
} from 'modules/retail/modules/ssbt/types/ssbt.types';
import { BonusesTypes, CampaignTypes, CommandData } from 'modules/retail/shared/types/retailCommon.types';
import {
  defineBonusesType,
  defineCampaignType,
  isRaceSport,
  isRaceStraightMarket,
} from 'modules/retail/shared/utils/helperFunctions';
import {
  formatEventStartDate,
  formatStringPerRow,
  getTotalOdds,
  getTotalStake,
  printMethods,
  retailPrintOptions,
} from 'modules/retail/shared/utils/retail.utils';
import { commands } from 'modules/retail/shared/utils/retail.utils';
import { store } from 'store';
import { isEmpty, retailFormatDate, retailFormatDateReceipt, toFixed } from 'utils/common/helpersCommon';
import { BetSlip, CashierTranslations, SSBT } from 'utils/common/translationUtils/translationStrings';
import { MAX_SPORT_EVENT_NAME_PER_ROW_HANDHELD, MAX_VALUE_NAME_PER_ROW_HANDHELD } from '../../utils/constants';

export const mapBetbuilderSignMobile = ({ value }: MapBetbuilderSignParams) => {
  const rawStartDate = isEmpty(value.customBetOutcomes[0].eventStartDate)
    ? retailFormatDateReceipt(value.customBetOutcomes[0].scheduledTime)
    : retailFormatDateReceipt(value.customBetOutcomes[0].eventStartDate);

  const isLive = !isEmpty(value.customBetOutcomes[0].isLive) ? value.customBetOutcomes[0].isLive : null;

  const liveAndTime =
    isLive !== null
      ? isLive
        ? `${formatEventStartDate(rawStartDate)} ` + t(CashierTranslations.Header.live)
        : formatEventStartDate(rawStartDate)
      : formatEventStartDate(rawStartDate);
  const [team1, team2] = value.customBetOutcomes[0].sportEventName.split(' vs. ') || '';

  const betBuilderSignCommands = [
    { command: commands.printOptions, bodyData: retailPrintOptions._optionsData },
    { command: commands.print, bodyData: { str: liveAndTime } },
    { command: commands.printOptions, bodyData: retailPrintOptions._optionsData },
    { command: commands.print, bodyData: { str: `${team1} vs. ` } },
    { command: commands.print, bodyData: { str: team2 } },
    { command: commands.printOptions, bodyData: retailPrintOptions.totalStakeOptions },
    { command: commands.print, bodyData: { str: `${t('Cashier.Receipt.betBuilder')} (${toFixed(value.odds, 2)})` } },
    { command: commands.print, bodyData: { str: ''.padEnd(44, '-') } },
  ];

  return betBuilderSignCommands;
};
export const mapFirstPartReceiptInfoMobile = ({
  ticketId,
  timestamp,
  retailAddress,
  isDuplicate,
  posAddress,
  posName,
  currentMnemonicCode,
  playerCpf,
}: MapFirstPartReceiptInfoMobileParams) => {
  const firstPartData: CommandData[] = [];
  printMethods.printIsReady(firstPartData, commands);
  printMethods.printStart(firstPartData, commands);
  printMethods.printOptions(firstPartData, commands, retailPrintOptions._logoOptionsData);
  printMethods.printImage(firstPartData, commands, retailAddress?.buLogo);
  printMethods.printOptions(firstPartData, commands, retailPrintOptions._optionsData);
  printMethods.print(firstPartData, commands, '');
  printMethods.printOptions(firstPartData, commands, retailPrintOptions.winbetOptions);
  printMethods.conditionalPrint(
    firstPartData,
    commands,
    !isEmpty(retailAddress?.responsibleGambling),
    t(retailAddress?.responsibleGambling),
  );
  printMethods.print(firstPartData, commands, t(''));
  printMethods.print(firstPartData, commands, t('Cashier.Receipt.addressPOSText'));
  printMethods.print(firstPartData, commands, `${posAddress?.city},`);
  printMethods.print(firstPartData, commands, `${posAddress?.streetName},`);
  printMethods.print(firstPartData, commands, `${posAddress?.streetNumber}`);
  printMethods.print(firstPartData, commands, '');
  printMethods.print(firstPartData, commands, t('Cashier.Receipt.signatureNumber'));
  printMethods.print(firstPartData, commands, posName);
  printMethods.printOptions(firstPartData, commands, retailPrintOptions._logoOptionsData);
  printMethods.conditionalPrint(
    firstPartData,
    commands,
    isDuplicate,
    `${i18next.t(CashierTranslations.Print.dublicateText)} `,
  );
  printMethods.conditionalPrint(firstPartData, commands, isDuplicate, '');
  printMethods.conditionalPrint(
    firstPartData,
    commands,
    window.config.ENV_TYPE !== 'PRODUCTION',
    t('Cashier.Receipt.testOperation'),
  );
  printMethods.printOptions(firstPartData, commands, retailPrintOptions.winbetOptions);
  printMethods.print(firstPartData, commands, ''.padEnd(44, '='));
  printMethods.print(firstPartData, commands, `${t(BetSlip.SSBT.ticketNumber)}:`);
  printMethods.print(firstPartData, commands, ticketId);
  printMethods.conditionalPrint(firstPartData, commands, !isEmpty(playerCpf), `${t('Cashier.Receipt.playerCpf')}:`);
  printMethods.conditionalPrint(firstPartData, commands, !isEmpty(playerCpf), `${playerCpf}`);
  printMethods.conditionalPrint(
    firstPartData,
    commands,
    currentMnemonicCode !== '',
    `${t('Cashier.Receipt.mnemonicCode')}: ${currentMnemonicCode}`,
  );
  printMethods.print(firstPartData, commands, `${i18next.t(BetSlip.SSBT.acceptedTicket)}: `);
  printMethods.print(firstPartData, commands, retailFormatDate(timestamp));
  printMethods.print(firstPartData, commands, ''.padEnd(44, '='));
  return firstPartData;
};
export const mapEventContentRegularDataMobile = <
  T extends { outcomeId: string; selectedSystem: string; totalCombinations: number },
  K extends OutcomesData,
>({
  value,
  getState,
  sportEventId,
  isSingle,
  getSingleBetProps,
  singles,
  outcomes,
  currency,
  isCurrencyBeforeAmount,
}: ProcessRegularBetSelectionParams<T, K>) => {
  const eventContentRegularData: CommandData[] = [];
  const rawStartDate = isEmpty(value.eventStartDate)
    ? retailFormatDateReceipt(value.scheduledTime)
    : retailFormatDateReceipt(value.eventStartDate);

  const isLive = !isEmpty(value.isLive) ? value.isLive : null;

  const liveAndTime =
    isLive !== null
      ? isLive
        ? `${formatEventStartDate(rawStartDate)} ` + t(CashierTranslations.Header.live)
        : formatEventStartDate(rawStartDate)
      : formatEventStartDate(rawStartDate);
  const formattedSportEvenName = formatStringPerRow(MAX_SPORT_EVENT_NAME_PER_ROW_HANDHELD, value.sportEventName) || [];

  printMethods.printOptions(eventContentRegularData, commands, retailPrintOptions.eventOptionsNew);
  printMethods.print(eventContentRegularData, commands, liveAndTime);
  printMethods.printOptions(eventContentRegularData, commands, retailPrintOptions.eventOptionsNew);
  for (const str of formattedSportEvenName) {
    printMethods.print(eventContentRegularData, commands, str);
  }

  const iconEarlyPayout =
    getState()?.common?.general?.layoutConfig?.['platform']?.general?.retailBonusIcons?.earlyPayout;
  const isEarlyPayoutActive = defineBonusesType(value, BonusesTypes.earlyPayout);
  // const isBoreDrawActive = defineBonusesType(value, BonusesTypes.boreDraw);
  // const isGoalInsuranceActive = defineBonusesType(value, BonusesTypes.goalInsurance);
  // const iconBoreDraw = getState()?.common?.general?.layoutConfig?.platform?.general?.retailBonusIcons?.boreDraw;
  // const iconGoalInsurance =
  //   getState()?.common?.general?.layoutConfig?.platform?.general?.retailBonusIcons?.goalInsurance;

  const isVirtual = /^3/.test(sportEventId);
  printMethods.conditionalPrint(
    eventContentRegularData,
    commands,
    !isEmpty(getState()?.['virtuals']?.virtualLobby?.tournamentEvents?.iframeTitleData?.competition),
    getState()?.['virtuals']?.virtualLobby?.tournamentEvents?.iframeTitleData?.competition,
  );
  printMethods.conditionalPrint(eventContentRegularData, commands, isVirtual, `Match ${sportEventId}`);

  printMethods.print(
    eventContentRegularData,
    commands,
    !isEmpty(value?.marketName) ? value?.marketName : value?.marketTemplateName,
  );
  printMethods.printOptions(eventContentRegularData, commands, retailPrintOptions.totalStakeOptions);
  if (isEarlyPayoutActive && value.name.length > MAX_VALUE_NAME_PER_ROW_HANDHELD) {
    const valueName = formatStringPerRow(MAX_VALUE_NAME_PER_ROW_HANDHELD, value.name) || [];
    for (const str of valueName) {
      printMethods.print(eventContentRegularData, commands, str);
    }
    printMethods.printImage(eventContentRegularData, commands, iconEarlyPayout);
  } else {
    printMethods.conditionPrintImageAndStringInARow(
      eventContentRegularData,
      commands,
      isEarlyPayoutActive,
      iconEarlyPayout,
      `${value.name}`,
      'totalStakeOptions',
    );
  }
  printMethods.conditionalPrint(
    eventContentRegularData,
    commands,
    value.banker === true,
    ' ' + t(BetSlip.Selections.bankerLetter),
  );
  printMethods.printOptions(eventContentRegularData, commands, retailPrintOptions.totalStakeOptions);
  if (value.name.length > MAX_VALUE_NAME_PER_ROW_HANDHELD && !isEarlyPayoutActive) {
    const valueNameFormatted = formatStringPerRow(MAX_VALUE_NAME_PER_ROW_HANDHELD, value.name) || [];
    for (const str of valueNameFormatted) {
      printMethods.print(eventContentRegularData, commands, str);
    }
  } else {
    printMethods.conditionalPrint(eventContentRegularData, commands, !isEarlyPayoutActive, `${value.name}`);
  }
  printMethods.print(eventContentRegularData, commands, `(${toFixed(value.odds, 2)})`);
  printMethods.conditionalPrint(
    eventContentRegularData,
    commands,
    !isEarlyPayoutActive && value.banker === true,
    ' ' + t(BetSlip.Selections.bankerLetter),
  );
  printMethods.printOptions(eventContentRegularData, commands, retailPrintOptions.eventOptions);

  if (isSingle) {
    const singleBetProps = getSingleBetProps(value);
    const singleOutcome = singles.find((x) => x?.outcomeId === value?.id);

    if (singleBetProps && singleOutcome) {
      printMethods.print(eventContentRegularData, commands, ''.padEnd(44, '-'));
      printMethods.printOptions(eventContentRegularData, commands, retailPrintOptions._optionsData);
      printMethods.print(
        eventContentRegularData,
        commands,
        i18next.t(`BetSlip.Systems.${singleBetProps.selectedSystem}`),
      );
      printMethods.print(
        eventContentRegularData,
        commands,
        `${singleOutcome.totalCombinations} ${i18next.t(SSBT.Ticket.totalCombinations)} X`,
      );
      printMethods.print(
        eventContentRegularData,
        commands,
        `${formatCurrency(singleBetProps.stake, currency, isCurrencyBeforeAmount)}`,
      );

      const totalOddsStr = `${i18next.t(BetSlip.SSBT.totalOdds)} ${toFixed(singleBetProps.totalOdds, 2)}`;
      printMethods.print(eventContentRegularData, commands, totalOddsStr);

      const stakeInfoStr = `${i18next.t(BetSlip.BetSlipConfirmation.stakeInfoText)}: ${formatCurrency(singleBetProps.stake, currency, isCurrencyBeforeAmount)}`;
      printMethods.print(eventContentRegularData, commands, stakeInfoStr);

      const possibleWinStr =
        `${i18next.t(BetSlip.PlaceBetButton.possibleWinText)}: ` +
        `${formatCurrency(singleBetProps.win, currency, isCurrencyBeforeAmount)}`;
      printMethods.print(eventContentRegularData, commands, possibleWinStr);
    }
  } else {
    printMethods.printOptions(eventContentRegularData, commands, retailPrintOptions._optionsData);
  }

  printMethods.print(
    eventContentRegularData,
    commands,
    ''.padEnd(44, outcomes.length - 1 === outcomes.indexOf(value) ? '=' : '-'),
  );
  return eventContentRegularData;
};
export const mapEventContentSportEventNameMobile = ({ value }: MapEventContentSportEventNameParams) => {
  const eventContentSingleData: CommandData[] = [];

  const rawStartDate = isEmpty(value.eventStartDate)
    ? retailFormatDateReceipt(value.scheduledTime)
    : retailFormatDateReceipt(value.eventStartDate);
  const isLive = !isEmpty(value.isLive) ? value.isLive : null;

  const liveAndTime =
    isLive !== null
      ? isLive
        ? `${formatEventStartDate(rawStartDate)} ` + t(CashierTranslations.Header.live)
        : formatEventStartDate(rawStartDate)
      : formatEventStartDate(rawStartDate);
  printMethods.printOptions(eventContentSingleData, commands, retailPrintOptions.eventOptionsNew);
  printMethods.print(eventContentSingleData, commands, liveAndTime);
  printMethods.printOptions(eventContentSingleData, commands, retailPrintOptions.totalStakeOptions);
  printMethods.print(eventContentSingleData, commands, value.sportEventName);
  printMethods.print(eventContentSingleData, commands, ''.padEnd(44, '='));
  return eventContentSingleData;
};
export const mapBetbuilderEventsDataMobile = ({ customBet, getState }: MapBetbuilderEventsDataParams) => {
  const betBuilderEventsData: CommandData[] = [];

  // const iconBoreDraw = getState()?.common?.general?.layoutConfig?.platform?.general?.retailBonusIcons?.boreDraw;
  // const iconGoalInsurance =
  //   getState()?.common?.general?.layoutConfig?.platform?.general?.retailBonusIcons?.goalInsurance;
  // const isBoreDrawActive = defineBonusesType(customBet, BonusesTypes.boreDraw);
  // const isGoalInsuranceActive = defineBonusesType(customBet, BonusesTypes.goalInsurance);
  const earlyPayoutCampaign = defineBonusesType(customBet, BonusesTypes.earlyPayout);
  const iconEarlyPayout =
    getState()?.common?.general?.layoutConfig?.['platform']?.general?.retailBonusIcons?.earlyPayout;
  const eventName = `  •${customBet.name}`;
  const marketName = `  ${customBet.marketTemplateName}`;
  printMethods.colReset(betBuilderEventsData, commands);

  printMethods.printOptions(betBuilderEventsData, commands, retailPrintOptions.totalStakeOptions);
  printMethods.print(betBuilderEventsData, commands, eventName);
  printMethods.printOptions(betBuilderEventsData, commands, retailPrintOptions.eventNameOptions);
  printMethods.conditionPrintImageAndStringInARow(
    betBuilderEventsData,
    commands,
    earlyPayoutCampaign,
    iconEarlyPayout,
    marketName,
    'eventNameOptions',
  );
  printMethods.conditionalPrint(betBuilderEventsData, commands, !earlyPayoutCampaign, marketName);

  return betBuilderEventsData;
};
export const mapBetbuilderSummaryBetDataMobile = <T extends { outcomeId: number | string; totalCombinations: number }>({
  value,
  currency,
  isSingle,
  singles,
  getSingleBetProps,
  isCurrencyBeforeAmount,
}: MapBetbuilderSummaryBetDataParams<T>) => {
  const currencySettings = store.getState()?.common?.general?.currencySettings;

  const currencySymbol = currencySettings?.find((c) => c.name === currency)?.nameTranslations || currency;
  const betBuildeSummaryBetData: CommandData[] = [];
  if (isSingle) {
    const singleBetProps = getSingleBetProps(value);
    const singleOutcome = singles.find((x) => x?.outcomeId === value?.id);

    if (singleBetProps && singleOutcome) {
      printMethods.print(betBuildeSummaryBetData, commands, ''.padEnd(44, '-'));
      printMethods.printOptions(betBuildeSummaryBetData, commands, retailPrintOptions._optionsData);
      printMethods.print(
        betBuildeSummaryBetData,
        commands,
        `${i18next.t('BetSlip.Systems.' + singleBetProps.selectedSystem)}`,
      );
      printMethods.print(
        betBuildeSummaryBetData,
        commands,
        `${singleOutcome.totalCombinations} ${t(SSBT.Ticket.totalCombinations)} X`,
      );
      printMethods.print(
        betBuildeSummaryBetData,
        commands,
        isCurrencyBeforeAmount
          ? `${currencySymbol} ${formatAmountToBRL(singleBetProps.stake)}`
          : `${toFixed(singleBetProps.stake, 2)} ${currency}`,
      );

      const totalOddsStr = `${t(BetSlip.SSBT.totalOdds)} ${toFixed(singleBetProps.totalOdds, 2)}`;
      printMethods.print(betBuildeSummaryBetData, commands, totalOddsStr);

      const stakeInfoStr =
        `${t(BetSlip.BetSlipConfirmation.stakeInfoText)}: ` +
        (isCurrencyBeforeAmount
          ? `${currencySymbol} ${formatAmountToBRL(singleBetProps.stake)}`
          : `${toFixed(singleBetProps.stake, 2)} ${currency}`);
      printMethods.print(betBuildeSummaryBetData, commands, stakeInfoStr);

      const possibleWinStr =
        `${t(BetSlip.PlaceBetButton.possibleWinText)}: ` +
        (isCurrencyBeforeAmount
          ? `${currencySymbol} ${formatAmountToBRL(singleBetProps.win)}`
          : `${toFixed(singleBetProps.win, 2)} ${currency}`);
      printMethods.print(betBuildeSummaryBetData, commands, possibleWinStr);
    }
  } else {
    printMethods.printOptions(betBuildeSummaryBetData, commands, retailPrintOptions._optionsData);
  }
  printMethods.print(betBuildeSummaryBetData, commands, ''.padEnd(44, '-'));
  return betBuildeSummaryBetData;
};
export const mapSummaryDataMobile = ({
  x,
  getState,
  toDecimal,
  currency,
  isCurrencyBeforeAmount,
}: MapSummaryDataParams) => {
  const summaryData: CommandData[] = [];
  const iconAccaBoost = getState()?.common?.general?.layoutConfig?.['platform']?.general?.retailBonusIcons?.accaboost;
  const iconAccaInsurance =
    getState()?.common?.general?.layoutConfig?.['platform']?.general?.retailBonusIcons?.accaInsurance;
  const accaInsuranceCampaign = defineCampaignType(x, CampaignTypes.accaInsurance);
  const accaBoostCampaign = defineCampaignType(x, CampaignTypes.accaBoost);

  const isStraightColumn = x.totalCombinations === 1; /// when is one bet combination
  printMethods.printOptions(summaryData, commands, retailPrintOptions.eventOptionsNew);
  printMethods.printOptions(summaryData, commands, retailPrintOptions.eventNameOptions);
  printMethods.colReset(summaryData, commands);
  printMethods.printOptions(summaryData, commands, retailPrintOptions._addressOptions);
  printMethods.printOptions(summaryData, commands, retailPrintOptions.eventNameOptions);

  // printMethods.conditionalPrint(
  //   summaryData,
  //   commands,
  //   !isEmpty(x),
  //   !isEmpty(x?.bankerBetTypeName)
  //     ? `${x.totalCombinations} X ${x.stake} ${x.bankerBetTypeName}`
  //     : `${i18next.t('BetSlip.Systems.' + x.selectedSystem)}, ${x.totalCombinations} ${i18next.t(
  //         SSBT.Ticket.totalCombinations,
  //       )} X ${x.stake.toFixed(2)} ${currency}`,
  // );
  printMethods.conditionalPrint(
    summaryData,
    commands,
    !isEmpty(x?.bankerBetTypeName),

    `${x.totalCombinations} X ${x.stake} ${x.bankerBetTypeName}`,
  );
  printMethods.conditionalPrint(
    summaryData,
    commands,
    isEmpty(x?.bankerBetTypeName),
    `${i18next.t('BetSlip.Systems.' + x.selectedSystem)}, ${x.totalCombinations} ${i18next.t(
      SSBT.Ticket.totalCombinations,
    )} X `,
  );
  printMethods.conditionalPrint(
    summaryData,
    commands,
    isEmpty(x?.bankerBetTypeName),
    `${formatCurrency(x.stake, currency, isCurrencyBeforeAmount)}`,
  );
  printMethods.printOptions(summaryData, commands, retailPrintOptions.eventNameOptions);
  printMethods.conditionalPrint(
    summaryData,
    commands,
    isStraightColumn,
    `${i18next.t(BetSlip.SSBT.totalOdds)} ${toFixed(Number(x.totalOdds), 2)}`,
  );

  printMethods.conditionPrintImageAndStringInARow(
    summaryData,
    commands,
    !isEmpty(accaBoostCampaign),
    iconAccaBoost,
    `${i18next.t(BetSlip.SSBT.bonusPercentageText)}: + ${accaBoostCampaign?.percentage}%`,
  );
  printMethods.conditionalPrint(
    summaryData,
    commands,
    !isEmpty(accaBoostCampaign),
    `${t('Bonuses.AccaBoost.includeBonus')}: ${formatCurrency(Number(accaBoostCampaign?.amount), currency, isCurrencyBeforeAmount)}`,
  );

  printMethods.conditionPrintImageAndStringInARow(
    summaryData,
    commands,
    !isEmpty(accaInsuranceCampaign),
    iconAccaInsurance,
    `${i18next.t(BetSlip.BetSlipConfirmation.stakeInfoText)}: ${formatCurrency(Number(x.totalStake), currency, isCurrencyBeforeAmount)}`,
  );

  printMethods.conditionalPrint(
    summaryData,
    commands,
    isEmpty(x.campaigns),
    `${i18next.t(BetSlip.BetSlipConfirmation.stakeInfoText)}: ${formatCurrency(Number(x.totalStake), currency, isCurrencyBeforeAmount)}`,
  );
  printMethods.print(
    summaryData,
    commands,
    `${i18next.t(BetSlip.PlaceBetButton.possibleWinText)}: ${formatCurrency(toDecimal(x.totalWinLong), currency, isCurrencyBeforeAmount)}`,
  );
  printMethods.printOptions(summaryData, commands, retailPrintOptions.eventNameOptions);
  printMethods.print(summaryData, commands, ''.padEnd(44, '='));
  return summaryData;
};
export const mapEventContentSingleDataMobile = <
  T extends { outcomeId: string; id: number; totalCombinations: number },
>({
  value,
  isSingle,
  getSingleBetProps,
  singles,
  outcomes,
  currency,
  isCurrencyBeforeAmount,
}: MapEventContentSingleDataParams<T>) => {
  const eventContentSingleData: CommandData[] = [];
  printMethods.printOptions(eventContentSingleData, commands, retailPrintOptions.totalStakeOptions);
  const outcomeStr =
    `${value.name} (${toFixed(value.odds, 2)})` +
    (value.banker === true ? ' ' + t(BetSlip.Selections.bankerLetter) : '');
  printMethods.printOptions(eventContentSingleData, commands, retailPrintOptions.eventOptionsNew);
  printMethods.print(
    eventContentSingleData,
    commands,
    !isEmpty(value?.marketName) ? value?.marketName : value?.marketTemplateName,
  );
  printMethods.printOptions(eventContentSingleData, commands, retailPrintOptions.totalStakeOptions);
  if (isRaceSport(value.sportId)) {
    const positions = value.name.split(',');
    const isStraightMarket = isRaceStraightMarket(value.marketTemplateName);

    positions.forEach((position: string, index: number) => {
      if (position) {
        const competitorName = position.replace(/[0-9]/g, '');
        const competitorNumber = position.replace(/[^0-9]/g, '');
        const competitorPosition = isStraightMarket ? `${index + 1}` : '';
        printMethods.print(
          eventContentSingleData,
          commands,
          `${competitorPosition}${competitorNumber ? `[${competitorNumber}]` : ''}${competitorName}`,
        );
      }
    });
  }
  printMethods.conditionalPrint(
    eventContentSingleData,
    commands,
    isRaceSport(value.sportId),
    `${`${i18next.t('MyBets.BetHistory.totalOddsText')}: ${toFixed(value.odds, 2)}`}`,
  );
  printMethods.conditionalPrint(eventContentSingleData, commands, !isRaceSport(value?.sportId), outcomeStr);
  printMethods.printOptions(eventContentSingleData, commands, retailPrintOptions._optionsData);

  if (isSingle) {
    const singleBetProps = getSingleBetProps(value);
    const singleOutcome = singles.find((x) => x?.outcomeId === value?.id);

    if (singleBetProps && singleOutcome) {
      printMethods.printOptions(eventContentSingleData, commands, retailPrintOptions._optionsData);
      printMethods.print(
        eventContentSingleData,
        commands,
        i18next.t(`BetSlip.Systems.${singleBetProps.selectedSystem},`),
      );
      printMethods.print(
        eventContentSingleData,
        commands,
        `${singleOutcome.totalCombinations} ${i18next.t(SSBT.Ticket.totalCombinations)} X`,
      );
      printMethods.print(
        eventContentSingleData,
        commands,
        `${toFixed(singleBetProps.stake, 2)} ${formatCurrency(Number(singleBetProps.stake), currency, isCurrencyBeforeAmount)}`,
      );
    }
  }
  printMethods.print(
    eventContentSingleData,
    commands,
    ''.padEnd(44, outcomes.length - 1 === outcomes.indexOf(value) ? '=' : '-'),
  );
  return eventContentSingleData;
};
export const mapSummaryVirtualDataMobile = ({ selectionTypes }: MapSummaryVirtualDataParams) => {
  const uniqueSelectionTypes = [...selectionTypes];
  const selectionsText = uniqueSelectionTypes.map((type) => `${i18next.t(`BetSlip.Systems.` + type)}`).join(', ');
  const summaryData: CommandData[] = [];
  printMethods.conditionalPrint(summaryData, commands, selectionTypes.size > 0, selectionsText);
  return summaryData;
};
export const mapLastPartContentMobile = ({
  ticketIdData,
  totalOdds,
  _stake,
  _totalWin,
  dailySequenceNumber,
  terminalBetPin,
  retailAddress,
  totalCombinations,
  isDuplicate,
  isVermantiaFootballOnly,
  isLinearCodeOnReceipt,
  retailInfo,
  currency,
}: MapLastPartContentParams) => {
  const lastPartContent: CommandData[] = [];
  const hasFees = !isEmpty(retailInfo?.feePercentage);
  const isMagnumBet = store.getState()?.common?.general?.layoutConfig?.['platform']?.general?.isMagnumBetRo;
  printMethods.printOptions(lastPartContent, commands, retailPrintOptions.totalStakeOptions);
  printMethods.conditionalPrint(
    lastPartContent,
    commands,
    isMagnumBet,
    `${i18next.t('Cashier.Receipt.paymentAmount')}: ${retailInfo?.grossStake} ${currency}`,
  );
  printMethods.print(lastPartContent, commands, _stake.str);
  printMethods.conditionalPrint(
    lastPartContent,
    commands,
    isMagnumBet && hasFees,
    `${i18next.t('Cashier.Receipt.betTax')}(${retailInfo?.feePercentage * 100 + '%'}): ${retailInfo?.fee} ${currency}`,
  );
  printMethods.conditionalPrint(
    lastPartContent,
    commands,
    totalCombinations === 1,
    `${i18next.t(BetSlip.SSBT.totalOdds)}: ${toFixed(Number(totalOdds), 2)}`,
  );
  printMethods.conditionalPrint(lastPartContent, commands, !isVermantiaFootballOnly, _totalWin.str);
  printMethods.print(lastPartContent, commands, ''.padEnd(44, '='));
  printMethods.printOptions(lastPartContent, commands, retailPrintOptions._addressOptions);
  printMethods.print(
    lastPartContent,
    commands,
    `${i18next.t(CashierTranslations.CashierModal.betNumber)}: ${
      !isEmpty(dailySequenceNumber) ? dailySequenceNumber : ''
    }`,
  );
  printMethods.print(
    lastPartContent,
    commands,
    `${i18next.t(CashierTranslations.CashierModal.pinNumber)}: ${!isEmpty(terminalBetPin) ? terminalBetPin : ''}`,
  );
  printMethods.print(lastPartContent, commands, '');
  printMethods.printOptions(lastPartContent, commands, retailPrintOptions.winbetAddress);
  printMethods.conditionalPrintBarcode(
    lastPartContent,
    commands,
    !isDuplicate && isLinearCodeOnReceipt,
    ticketIdData.str,
  );
  printMethods.print(lastPartContent, commands, '');
  printMethods.print(lastPartContent, commands, '');
  printMethods.print(lastPartContent, commands, '');
  printMethods.print(lastPartContent, commands, '');
  printMethods.conditionalPrintQrCode(lastPartContent, commands, !isDuplicate, ticketIdData.str);
  printMethods.print(lastPartContent, commands, '');
  printMethods.conditionalPrint(
    lastPartContent,
    commands,
    !isEmpty(retailAddress?.responsibleGambling),
    t(retailAddress?.responsibleGambling),
  );
  printMethods.conditionalPrint(
    lastPartContent,
    commands,
    !isEmpty(retailAddress?.responsibleGambling1),
    t(retailAddress?.responsibleGambling1),
  );
  printMethods.conditionalPrint(
    lastPartContent,
    commands,
    !isEmpty(retailAddress?.responsibleGambling2),
    t(retailAddress?.responsibleGambling2),
  );
  printMethods.conditionalPrint(
    lastPartContent,
    commands,
    !isEmpty(retailAddress?.responsibleGambling3),
    t(retailAddress?.responsibleGambling3),
  );
  printMethods.conditionalPrint(
    lastPartContent,
    commands,
    !isEmpty(
      retailAddress?.responsibleGambling3 ||
        retailAddress?.responsibleGambling2 ||
        retailAddress?.responsibleGambling1 ||
        retailAddress?.responsibleGambling,
    ),
    '',
  );

  printMethods.printEject(lastPartContent, commands);

  return lastPartContent;
};
export const mapFirstPartManualReofferMobile = ({ requestId, retailAddress }: MapFirstPartManualReofferParams) => {
  const firstPartData: CommandData[] = [];
  printMethods.printIsReady(firstPartData, commands);
  printMethods.printStart(firstPartData, commands);
  printMethods.printOptions(firstPartData, commands, retailPrintOptions._logoOptionsData);
  printMethods.printImage(firstPartData, commands, retailAddress?.buLogo);
  printMethods.printOptions(firstPartData, commands, retailPrintOptions._optionsData);
  printMethods.print(firstPartData, commands, '');
  printMethods.printOptions(firstPartData, commands, retailPrintOptions.winbetOptions);
  printMethods.conditionalPrint(
    firstPartData,
    commands,
    !isEmpty(retailAddress?.responsibleGambling),
    t(retailAddress?.responsibleGambling),
  );
  printMethods.print(firstPartData, commands, '');
  printMethods.conditionalPrint(
    firstPartData,
    commands,
    window.config.ENV_TYPE !== 'PRODUCTION',
    t('Cashier.Receipt.testOperation'),
  );
  printMethods.printOptions(firstPartData, commands, retailPrintOptions.betBuilderDotStyle);
  printMethods.print(
    firstPartData,
    commands,
    '***' + t(CashierTranslations.Print.highStakeRequest).toUpperCase() + '***',
  );
  printMethods.printOptions(firstPartData, commands, retailPrintOptions._logoOptionsData);
  printMethods.printOptions(firstPartData, commands, retailPrintOptions.winbetOptions);
  printMethods.print(firstPartData, commands, ''.padEnd(44, '='));
  printMethods.print(firstPartData, commands, `${t(CashierTranslations.Print.requestText)}`);
  printMethods.print(firstPartData, commands, `${requestId}`);
  printMethods.print(firstPartData, commands, `${t(CashierTranslations.CashPayModal.date)}`);
  printMethods.print(firstPartData, commands, retailFormatDate(Date.now()));
  printMethods.print(firstPartData, commands, ''.padEnd(44, '='));
  return firstPartData;
};
export const mapSecondPartManualReofferMobile = ({
  value,
  bets,
  getState,
  currency,
  isCurrencyBeforeAmount,
}: MapSecondPartManualReofferParams) => {
  const singles = bets.find((x) => x?.selectedSystem === 'singles');
  const currencySettings = store.getState()?.common?.general?.currencySettings;

  const currencySymbol = currencySettings?.find((c) => c.name === currency)?.nameTranslations || currency;
  const firstPartData: CommandData[] = [];

  const isEarlyPayoutActive = defineBonusesType(value, BonusesTypes.earlyPayout);
  const iconEarlyPayout =
    getState()?.common?.general?.layoutConfig?.['platform']?.general?.retailBonusIcons?.earlyPayout;
  printMethods.printOptions(firstPartData, commands, retailPrintOptions._optionsData);
  printMethods.print(firstPartData, commands, value?.eventTitle);
  printMethods.print(firstPartData, commands, value?.marketName);
  printMethods.conditionPrintImageAndStringInARow(
    firstPartData,
    commands,
    isEarlyPayoutActive,
    iconEarlyPayout,
    value?.outcomeTitle,
    '_optionsData',
  );
  printMethods.conditionalPrint(firstPartData, commands, !isEarlyPayoutActive, value?.outcomeTitle);
  printMethods.conditionalPrint(firstPartData, commands, !isEarlyPayoutActive, toFixed(value?.odds, 2));
  printMethods.printOptions(firstPartData, commands, retailPrintOptions.winbetOptions);

  if (singles) {
    printMethods.print(firstPartData, commands, ''.padEnd(44, '-'));
    printMethods.printOptions(firstPartData, commands, retailPrintOptions._optionsData);
    printMethods.print(firstPartData, commands, i18next.t(`BetSlip.Systems.${singles.selectedSystem}`));
    printMethods.print(
      firstPartData,
      commands,
      `${singles?.manualReofferDetails?.totalCombinations} ${i18next.t(SSBT.Ticket.totalCombinations)} X}`,
    );
    printMethods.print(
      firstPartData,
      commands,
      `${formatCurrency(singles.stake.originalValue, currency, isCurrencyBeforeAmount)}`,
    );

    const totalOddsStr = `${i18next.t(BetSlip.SSBT.totalOdds)} ${toFixed(singles?.manualReofferDetails?.totalOdds, 2)}`;
    printMethods.print(firstPartData, commands, totalOddsStr);

    const stakeInfoStr =
      `${i18next.t(BetSlip.BetSlipConfirmation.stakeInfoText)}: ` +
      (isCurrencyBeforeAmount
        ? `${currencySymbol} ${formatAmountToBRL(singles?.stake?.originalValue)}`
        : `${toFixed(singles?.stake?.originalValue, 2)} ${currency}`);
    printMethods.print(firstPartData, commands, stakeInfoStr);
  } else {
    printMethods.printOptions(firstPartData, commands, retailPrintOptions._optionsData);
  }
  printMethods.print(firstPartData, commands, ''.padEnd(44, '='));
  return firstPartData;
};
export const mapSummaryManualReofferMobile = ({
  bets,
  getState,
  currency,
  isCurrencyBeforeAmount,
}: MapSummaryManualReofferParams) => {
  const currencySettings = store.getState()?.common?.general?.currencySettings;

  const currencySymbol = currencySettings?.find((c) => c.name === currency)?.nameTranslations || currency;
  const systems = bets.find((x) => x?.selectedSystem !== 'singles');
  const summaryDataManualReoffer: CommandData[] = [];
  const iconAccaBoost = getState()?.common?.general?.layoutConfig?.['platform']?.general?.retailBonusIcons?.accaboost;
  const iconAccaInsurance =
    getState()?.common?.general?.layoutConfig?.['platform']?.general?.retailBonusIcons?.accaInsurance;
  const accaInsuranceCampaign = defineCampaignType(systems, CampaignTypes.accaInsurance);
  const accaBoostCampaign = defineCampaignType(systems, CampaignTypes.accaBoost);

  printMethods.printOptions(summaryDataManualReoffer, commands, retailPrintOptions.eventNameOptions);
  printMethods.conditionPrintImageAndStringInARow(
    summaryDataManualReoffer,
    commands,
    !isEmpty(accaBoostCampaign),
    iconAccaBoost,
    `${i18next.t('BetSlip.Systems.' + systems.selectedSystem)}`,
  ); /// there is no amount property in response : accaBoostCampaign?.percentage}%

  printMethods.conditionPrintImageAndStringInARow(
    summaryDataManualReoffer,
    commands,
    !isEmpty(accaInsuranceCampaign),
    iconAccaInsurance,
    `${i18next.t('BetSlip.Systems.' + systems.selectedSystem)}`,
  );
  printMethods.conditionalPrint(
    summaryDataManualReoffer,
    commands,
    isEmpty(systems.campaigns),
    `${i18next.t('BetSlip.Systems.' + systems.selectedSystem)}`,
  );
  printMethods.conditionalPrint(
    summaryDataManualReoffer,
    commands,
    isEmpty(systems.campaigns),
    `${systems.manualReofferDetails.totalCombinations},
     ${i18next.t(SSBT.Ticket.totalCombinations)} X`,
  );
  printMethods.conditionalPrint(
    summaryDataManualReoffer,
    commands,
    isEmpty(systems.campaigns),
    `${formatCurrency(systems?.stake?.originalValue, currencySymbol, isCurrencyBeforeAmount)}`,
  );
  printMethods.printOptions(summaryDataManualReoffer, commands, retailPrintOptions.eventNameOptions);
  printMethods.print(summaryDataManualReoffer, commands, ''.padEnd(44, '='));
  return summaryDataManualReoffer;
};
export const lastPartManualReofferMobile = ({ retailAddress }: LastPartManualReofferParams) => {
  const lastPartData: CommandData[] = [];
  printMethods.printOptions(lastPartData, commands, retailPrintOptions.winbetAddress);
  printMethods.conditionalPrint(
    lastPartData,
    commands,
    !isEmpty(retailAddress?.responsibleGambling),
    t(retailAddress?.responsibleGambling),
  );
  printMethods.conditionalPrint(
    lastPartData,
    commands,
    !isEmpty(retailAddress?.responsibleGambling1),
    t(retailAddress?.responsibleGambling1),
  );
  printMethods.conditionalPrint(
    lastPartData,
    commands,
    !isEmpty(retailAddress?.responsibleGambling2),
    t(retailAddress?.responsibleGambling2),
  );
  printMethods.conditionalPrint(
    lastPartData,
    commands,
    !isEmpty(retailAddress?.responsibleGambling3),
    t(retailAddress?.responsibleGambling3),
  );
  printMethods.printEject(lastPartData, commands);
  return lastPartData;
};
export const mapManualReofferBetsDataMobile = ({
  bets,
  currency,
  isCurrencyBeforeAmount,
}: MapManualReofferBetsDataParams) => {
  const totalStake = getTotalStake({ bets });
  const totalOdds = getTotalOdds({ bets });
  const reofferBets: CommandData[] = [];

  printMethods.printOptions(reofferBets, commands, retailPrintOptions._optionsData);
  printMethods.print(reofferBets, commands, `${i18next.t(BetSlip.SSBT.totalOdds)} ${totalOdds}`);
  printMethods.print(
    reofferBets,
    commands,
    `${i18next.t(BetSlip.BetSlipConfirmation.stakeInfoText)}: ${formatCurrency(
      Number(totalStake),
      currency,
      isCurrencyBeforeAmount,
    )}`,
  );

  printMethods.print(reofferBets, commands, ''.padEnd(44, '='));
  return reofferBets;
};
export const mapBetBuilderSignManualReofferMobile = ({ value }: MapBetBuilderSignManualReofferParams) => {
  const betBuilderData: CommandData[] = [];

  printMethods.printOptions(betBuilderData, commands, retailPrintOptions._optionsData);
  printMethods.printOptions(betBuilderData, commands, retailPrintOptions._optionsData);
  printMethods.print(betBuilderData, commands, value.customBetSelections[0]?.eventTitle);
  printMethods.printOptions(betBuilderData, commands, retailPrintOptions.totalStakeOptions);
  printMethods.print(betBuilderData, commands, `${t('Cashier.Receipt.betBuilder')} (${toFixed(value.odds, 2)})`);
  printMethods.print(betBuilderData, commands, ''.padEnd(44, '-'));
  return betBuilderData;
};
