import React, { memo } from 'react';
import { Icon } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { useAppSelector } from 'store';
import { cssGlobalNS } from 'utils/common/types/css.types';
import { SCRequestBetNotificationIconStyle } from './styled';
import { selectRequestBet } from '../slice/requestBet.slice';

type Props = {
  icon: string;
  text: string;
};

const RequestBetNotificationSimple: React.FC<Props> = ({ icon, text }) => {
  const error = useAppSelector(selectRequestBet.error) || text;
  const isSubmited = useAppSelector(selectRequestBet.isSubmited);

  if (!error && !isSubmited) return null;

  return (
    <Flex.AC className={`w-100 bg-none ${cssGlobalNS}-rb__box--inline ${cssGlobalNS}-rb__notification`}>
      <Icon className={`${cssGlobalNS}-rb__notification-icon`} icon={icon} style={SCRequestBetNotificationIconStyle} />
      {text}
    </Flex.AC>
  );
};

export default memo(RequestBetNotificationSimple);
