import { Text } from 'components/shared';
import ExpandableIcon from 'components/shared/icons/ExpandableIcon';
import { Flex } from 'components/shared/layout/Flex';

/** Ticket Header */
interface CashierTicketContentHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  ticketIdText?: string;
  ticketId?: string;
  ticketAcceptedDate?: string;

  icon?: boolean;
  isExpanded?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const CashierTicketContentHeader: React.FC<CashierTicketContentHeaderProps> = ({
  ticketIdText,
  ticketId,
  ticketAcceptedDate,
  icon,
  isExpanded,
  className,
  children,
  ...props
}) => {
  const classProp = className ? { class: className } : undefined;

  return (
    <egtd-cashier-ticket-content-header {...classProp} {...props} data-expanded={isExpanded}>
      <Flex.Col className="egtd-cashier-header-info">
        <Text className="egtd-cashier-ticket__text--primary" text={ticketAcceptedDate} />
        <Text className="egtd-cashier-ticket__text--secondary" text={ticketIdText + ': ' + ticketId} />
      </Flex.Col>
      {children}
      {icon && <ExpandableIcon isExpanded={isExpanded} size={20} />}
    </egtd-cashier-ticket-content-header>
  );
};

export default CashierTicketContentHeader;
