import React, { memo } from 'react';
import { Flex } from 'components/shared/layout';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { showHideNavUserButton } from 'utils/common/helpersCommon';
import { MainNavBarButtons } from './mainNavbarButtons';
import { DrawerToggler, NavBrand, NavUser, NavUserLogged } from '..';
import SmarticoButton from '../../../integrations/smartico/components/SmarticoButton';
import { useMainNavbar } from '../../hooks/useMainNavbar';

type MainNavMobileLayout01Types = {
  isAuthenticated: boolean | null;
};

const MainNavMobileLayout01: React.FC<MainNavMobileLayout01Types> = ({ isAuthenticated }) => {
  const { subNav } = useMainNavbar();
  const showNavUserButtons = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.LOGIN_REGISTER_SHOW,
    deviceLayout: 'general',
  })?.isVissible;

  return (
    <>
      <Flex className="head__left">
        <DrawerToggler />
        <Flex.AC className="flex-1 justify-content-evenly">
          {/*{tickerVerticalConfig?.visible ? <TickerToggler onToggleClick={onToggleClick} /> : null}*/}
          {/* TODO: Implement condition to show the Offers link */}
          <SmarticoButton />
          <MainNavBarButtons />
          {/* <OffersToggler isAuthenticated={isAuthenticated} /> */}
          {/* <PrizeDropButtons /> */}
        </Flex.AC>
      </Flex>
      <NavBrand />
      <Flex.JE className={`head__right`}>
        {isAuthenticated ? (
          <NavUserLogged showBalance={subNav.balance} showDeposit={subNav.deposit} />
        ) : showHideNavUserButton(showNavUserButtons) ? (
          <NavUser />
        ) : null}
        <MainNavBarButtons type={'language'} />
      </Flex.JE>
    </>
  );
};

export default memo(MainNavMobileLayout01);
