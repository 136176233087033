import React from 'react';
import { Flex } from 'components/shared/layout';
import { cssGamificationNS } from 'utils/common/types/css.types';
import { CoinsIcon } from '../../';
import { ExpIcon } from '../../ExpIcon';

type Props = {
  coins: string;
  isWin?: boolean;
  icon?: string;
};

export const Coins: React.FC<Props> = ({ coins, isWin }) => {
  if (coins === '0' && !coins) return <></>;
  return (
    <Flex.AC
      className={`${cssGamificationNS}-tag__coins__text ${isWin && `${cssGamificationNS}-tag__coins__text--win`}`}
    >
      {coins}
      {isWin ? <CoinsIcon /> : <ExpIcon />}
    </Flex.AC>
  );
};
