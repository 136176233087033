import {
  FieldValues,
  UseFormRegister,
  UseFormReturn,
  UseFormStateProps,
  FormStateProxy,
  RefCallBack,
  useFormContext,
  useController,
  FormState,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { modifyValidation, getCustomOnChange } from 'components/shared/forms/utils';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { useAppSelector } from 'store';
import { FormControlTypes } from '../formTypes';

type ReturnProps = {
  onChange;
  onBlur: () => void;
  controlRef: RefCallBack;
  hasError: boolean;
  hasSuccess: boolean;
  errorMessage?: string;
  invalid: boolean;
  isTouched: boolean;
  value;
  trigger: UseFormReturn['trigger'];
};

export interface HookFormReturnProps {
  fields: FieldValues;
  touchedFields: FormStateProxy['touchedFields'];
  dirtyFields: FormStateProxy['dirtyFields'];
  setValue: UseFormReturn['setValue'];
  handleSubmit: UseFormReturn['handleSubmit'];
  reset: UseFormReturn['reset'];
  register: UseFormRegister<FieldValues>;
  trigger: UseFormReturn['trigger'];
  watch: UseFormReturn['watch'];
  control: UseFormStateProps<FieldValues>['control'];
  formState: FormState<FieldValues>;
}

const useFormControl = (props: FormControlTypes): ReturnProps => {
  const netAppHeader = useAppSelector(selectGeneral.netAppHeader);
  const { t } = useTranslation();
  const {
    type,
    name,
    validationRules,
    onChange: onChangeProps,
    onBlur: onBlurProps,
    componentLocation,
    compareWith,
    data,
    onChangeCallback,
    effect,
    defaultValue,
    disabled,
    ...rest
  } = props;
  const { setValue, getValues, control, trigger } = useFormContext();
  const { dependancy } = effect || {};
  const { field, fieldState } = useController({
    name,
    control,
    rules: modifyValidation({ name, type, validationRules, getValues, compareWith, data, dependancy, netAppHeader }),
    defaultValue: defaultValue ?? '',
  });
  const { invalid, isTouched, error } = fieldState;
  const { ref: controlRef, onChange: onChangeForm, onBlur: onBlurForm, value } = field;
  const defaultOnChange = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onChangeCallback && onChangeCallback();
    return event?.target ? onChangeForm(event) : onChangeForm({ target: { name, value: event } });
  };
  const onChange = disabled
    ? () => undefined
    : getCustomOnChange({ name, type, effect, setValue, getValues, trigger, onChangeCallback }) ||
      onChangeProps ||
      defaultOnChange;

  const onBlur = onBlurProps || onBlurForm;
  const hasError = componentLocation !== 'login' && (rest.hasError ?? invalid);
  const hasSuccess =
    rest.hasSuccess ?? (rest.autoComplete !== 'off' ? !!(!invalid && value) : !!(isTouched && !invalid && value));
  const errorMessage =
    rest.errorMessage ??
    (error ? t(error?.message ?? `Registration.ValidationRules.${name}${error?.type}`) : undefined);

  return {
    controlRef,
    onChange,
    onBlur,
    hasError,
    hasSuccess,
    errorMessage,
    invalid,
    isTouched,
    value,
    trigger,
  };
};

export default useFormControl;
