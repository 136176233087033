import { useTranslation } from 'react-i18next';
import { useCurrency } from 'hooks/common/useCurrency';
import { isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { useAppDispatch } from 'store';
import AnexDateControls from './AnexDateControls';
import AnexReportInfo from './AnexReportInfo';
import { useAnexStatusInfo } from './useAnexStatusInfo';
import { CashierModalUI } from '../../cashierModalUI/Index';
import { useCashierModalOpenRequest } from '../../hooks/useCashierModalOpenRequest';
import { useCashierModalPrompt } from '../../hooks/useCashierModalPrompt';
import { clearAnexData } from '../../utils';

const AnexReport = () => {
  const { t } = useTranslation();
  const { closeModal } = useHandleModals();
  const dispatch = useAppDispatch();
  const { anexInfo, anexInfoStatus } = useAnexStatusInfo();
  const { currencySymbol } = useCurrency();
  const isCashierModalPrompted = useCashierModalPrompt();

  const handleAnexModalClosing = () => {
    clearAnexData(dispatch, closeModal);
  };

  useCashierModalOpenRequest(isCashierModalPrompted, dispatch, closeModal, clearAnexData);

  return (
    <>
      <CashierModalUI.Wrapper isVisible={true}>
        <CashierModalUI.Body>
          <CashierModalUI.Header
            text={!isEmpty(anexInfo?.posAddress) ? `${anexInfo?.posAddress}` : ''}
            clearData={handleAnexModalClosing}
          />

          <CashierModalUI.Content>
            <AnexReportInfo t={t} anexInfo={anexInfo} anexInfoStatus={anexInfoStatus} currency={currencySymbol || ''} />
          </CashierModalUI.Content>
          <CashierModalUI.NavCol>
            <AnexDateControls />
          </CashierModalUI.NavCol>
        </CashierModalUI.Body>
      </CashierModalUI.Wrapper>
    </>
  );
};
export default AnexReport;
