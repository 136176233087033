import React from 'react';
import FavouriteGameIdUpdate from 'modules/casino/modules/container/cellsContainer/cellls/additionalGames/favourites/components/FavouriteGameIdUpdate';
import useCasinoWidgetConfig from 'modules/casino/platformImplementation/hooks/useCasinoWidgetConfig';
import OpenGameLauncherWrapper from '../../features/gameLaunch/openLauncher/OpenGameLauncherWrapper';
import { BonusTagGamesPolling } from '../../features/gameLaunchBonus/FetchBonusTagGames';
import JackpotsInit from '../../features/jackpots/JackpotsInit';

const CasinoAppComponents = () => {
  const casinoWidgetConfig = useCasinoWidgetConfig();

  if (casinoWidgetConfig) {
    return null;
  }

  return (
    <>
      <OpenGameLauncherWrapper />
      <JackpotsInit />
      <BonusTagGamesPolling />
      <FavouriteGameIdUpdate />
    </>
  );
};

export default CasinoAppComponents;
