import { useEffect } from 'react';
import { LayoutConfigInnerLayoutTypes, RequestStatuses } from 'modules/casino/shared/constants';
import { useCasinoLayoutConfig } from 'modules/casino/shared/hooks/useCasinoLayoutConfig';
import { useInterval } from 'modules/casino/shared/hooks/useInterval';
import { useAppDispatch, useAppSelector } from 'store';
import { casinoWidgetsSelectors, fetchWidgetPrizeDropCampaign } from '../../../../slice/casinoWidgetsSlice';
import { WIDGET_PRIZE_DROP_PULL_INTERVAL_FALLBACK } from '../PrizeDropCellWidgetConstants';

const savedInitialFetchedWidgetIds: string[] = [];

export const useFetchPrizeDropCampaign = ({ id, query }: { id: string; query: string }) => {
  const dispatch = useAppDispatch();
  const prizeDropLoadingStatus = useAppSelector((state) => casinoWidgetsSelectors.prizeDropLoadingStatus(state, id));
  const config = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.WIDGET_PRIZE_DROP });
  const pullInterval = config && config?.interval ? config?.interval : WIDGET_PRIZE_DROP_PULL_INTERVAL_FALLBACK;

  useInterval(() => {
    if (
      query &&
      id &&
      (prizeDropLoadingStatus === RequestStatuses.INITIAL || prizeDropLoadingStatus === RequestStatuses.EMPTY)
    ) {
      dispatch(fetchWidgetPrizeDropCampaign({ query, id }));
    }
  }, pullInterval);

  useEffect(() => {
    if (query && id && !savedInitialFetchedWidgetIds.includes(id)) {
      dispatch(fetchWidgetPrizeDropCampaign({ query, id }));
      savedInitialFetchedWidgetIds.push(id);
    }
  }, [dispatch]);
};
