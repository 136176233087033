import { useEffect, useState, useCallback, useRef } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useAppSelector } from 'store';

import { selectHighlightNewFeature } from '../slice/highlightNewFeature.slice';
const defaultState = {
  width: 0,
  height: 0,
  left: 0,
  top: 0,
};
export const useGetElementDimensions = ({
  isPortrait = false,
  isActive,
}: {
  isPortrait: boolean;
  isActive: boolean;
}): {
  elementDimensions: { width: number; height: number; left: number; top: number };
  handleRef: (node: Element | null) => void;
} => {
  const wrapperRef = useRef(null);
  const handleRef = useCallback(
    (node) => {
      wrapperRef.current = node;
    },
    [wrapperRef],
  );

  const activeElement = useAppSelector(selectHighlightNewFeature.activeElement);

  const [elementDimensions, setElementDimensions] = useState(defaultState);
  useEffect(() => {
    const bodyRoot = document.body;
    if (activeElement && wrapperRef?.current && isPortrait && isActive) {
      bodyRoot.classList.add('overflow-hidden');
      disableBodyScroll(wrapperRef.current);
      const elPosition = activeElement.getBoundingClientRect();
      if (window.innerHeight / 2 < elPosition.y) {
        activeElement.scrollIntoView({
          block: 'center',
        });
      }

      setElementDimensions({
        width: activeElement.clientWidth + 10,
        height: activeElement.clientHeight,
        left: elPosition.x - 5,
        top:
          window.innerHeight / 2 < elPosition.y
            ? window.innerHeight / 2 - activeElement.clientHeight / 2
            : elPosition.y > 0
              ? elPosition.y
              : -elPosition.y,
      });
    }

    // else {
    //   bodyRoot.classList.remove('overflow-hidden');
    //   setElementDimensions(defaultState);
    //   clearAllBodyScrollLocks();
    // }
    return () => {
      if (isActive) {
        bodyRoot.classList.remove('overflow-hidden');
        clearAllBodyScrollLocks();
      }
    };
  }, [activeElement, setElementDimensions, wrapperRef, isPortrait, isActive]);

  return activeElement ? { elementDimensions, handleRef } : { elementDimensions: defaultState, handleRef };
};
