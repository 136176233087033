import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { navigateToTicketCheckView, omniPlayIframeId } from 'pages/retailGames/providers/omniPlay/utils/omniPlay.utils';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { isEmpty } from 'utils/common/helpersCommon';

export const useGetOmniPlayScanData = (pathName: string) => {
  const allGamesLaunchOptions = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.RETAIL_GAMES,
    deviceLayout: 'general',
  });

  const currGame = allGamesLaunchOptions?.omniPlay?.find(
    (game) => !isEmpty(game?.game_id) && pathName.includes(game?.game_id),
  );

  return {
    ticketCodePrefix: currGame?.ticketCodePrefix || undefined,
    baseUrl: currGame?.baseUrl || '',
    omniPlayIframeId,
    navigateToTicketCheckView,
  };
};
