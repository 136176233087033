import { WinnersPageTypes } from './types/winnersPage.types';

export const winnersMock: WinnersPageTypes.Winner[] = [
  {
    tombolaName: 'VIP полет с балон',
    imageUrl: '',
    winnerCity: 'Русе',
    winnerName: 'Милена Стоянова',
    winTime: 1655990647780,
  },
  {
    tombolaName: '5000 лв награда',
    imageUrl: '',
    winnerCity: 'Видин',
    winnerName: 'Красимир Иванов',
    winTime: 1655972424216,
  },
  {
    tombolaName: 'VIP полет с балон',
    imageUrl: '',
    winnerCity: 'Петрич',
    winnerName: 'Анатоли Спасов',
    winTime: 1655972517404,
  },
  {
    tombolaName: '5000 лв.',
    imageUrl: '',
    winnerCity: 'Пловдив',
    winnerName: 'Валерия Михова',
    winTime: 1655449348923,
  },
  {
    tombolaName: 'VIP полет с балон',
    imageUrl: '',
    winnerCity: 'Шумен',
    winnerName: 'Станимира Петрова',
    winTime: 1655972468897,
  },
  {
    tombolaName: '1000 лв.',
    imageUrl: '',
    winnerCity: 'Бяла',
    winnerName: 'Иван Павлов',
    winTime: 1655911155552,
  },
];
