import { useEffect } from 'react';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { notificationThunks } from '../notificationSlice';

export const useFetchPopupNotifications = () => {
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const playerId = useAppSelector(selectMyProfile.playerId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated && playerId) {
      // Added playerId check to wait until player details data fetch is finished.
      const popUpGroupedNotification = dispatch(notificationThunks.fetchPopUpGroupedNotification());

      return () => {
        popUpGroupedNotification.abort();
      };
    }
  }, [isAuthenticated, playerId, dispatch]);
};
