import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { css } from 'styled-components';
import usePreloadAssets from 'components/shared/FrameAnimator/hooks/usePreloadAssets';
import { mapFirstItemFromFolder } from 'components/shared/FrameAnimator/utils/images/utils';
import { Flex } from 'components/shared/layout';
import { defineAnimationParams } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationUtils';
import { generateUniqueRandomNumbers } from 'utils/common/helpersCommon';
import DefaultBoxItems from './DefaultBoxItems';
import { NotificationTypes } from '../../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import { GamificationUI } from '../../components/GamificationUI';
import useAnimationComplete from '../../hooks/useAnimationComplete';
import { useAutoScrollToDepositButton } from '../../hooks/useAutoScrollToDepositButton';
import useHandleBonusActions from '../../hooks/useHandleBonusActions';
import useOfferTerms from '../../hooks/useOfferTerms';

const DefaultBoxContainer: React.FC<{
  notification: NotificationTypes.PopUpNotification;
  isReady: boolean;
  rowLayout?: 'default' | '2x3';
  primaryTextBgProps?: boolean;
  differentImage?: boolean;
}> = ({ notification, isReady, rowLayout = 'default', primaryTextBgProps, differentImage = false }) => {
  const { bonusData, header, subheader, message, style } = notification;

  const singleBonus = bonusData?.bonuses?.[0];

  const primaryTextBg = !!style.primaryTextBg || primaryTextBgProps;
  const imageByIndexFolder = !!style.imageByIndexFolder || differentImage;

  const { showTerms, outsideClick, offerTerms, fetchBonusTerm } = useOfferTerms(singleBonus);
  const { animationComplete, setAnimationComplete } = useAnimationComplete();

  useAutoScrollToDepositButton(animationComplete);

  const animationParams = useMemo(
    () =>
      defineAnimationParams({
        animationKey: style.animationKey,
        layout: style.layout,
        folder: style.folder,
        numberOfFrames: style?.numberOfFrames,
      }),
    [style.animationKey, style.layout, style.folder, style.numberOfFrames],
  );

  const randomFolders = useMemo(
    () =>
      style.folderCounter ? generateUniqueRandomNumbers(style.folderCounter, bonusData.otherOptions.length) : undefined,
    [style.folderCounter, bonusData.otherOptions.length],
  );

  const frameAssets = useMemo(
    () =>
      mapFirstItemFromFolder({
        itemsLength: bonusData.otherOptions.length,
        animationParams,
        getFromFolder: imageByIndexFolder,
        randomFromFolder: randomFolders,
      }),
    [animationParams, bonusData.otherOptions],
  );

  const { imagesLoadingCompleted } = usePreloadAssets({ module: frameAssets, loadingFinished: true });

  const { handleOfferAction, error, status, statusCode } = useHandleBonusActions({
    bonus: singleBonus,
    notificationId: notification.id,
  });

  const isModernPhone = useMediaQuery({ query: `(min-width: 360px)` });

  if (!singleBonus) return null;

  return (
    <>
      <GamificationUI.Header>
        <GamificationUI.Logo animationParams={animationParams} />
        <GamificationUI.CTAText
          showWinText={animationComplete}
          text={header}
          winText={message}
          error={error}
          // className="mt-2"
          style={{
            paddingTop: isModernPhone ? '20px' : '5px',
            paddingBottom: isModernPhone ? '20px' : '5px',
            backgroundPosition: 'center bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            textAlign: 'center',
            marginTop: isModernPhone ? '14px' : '16px',
            transition: '0.15s padding',
          }}
          bgColor={style.textBackground}
          textColor={style.headerTextColor}
          styledCss={!style.textBackground && textCss(primaryTextBg)}
        />
      </GamificationUI.Header>

      <DefaultBoxItems
        notification={notification}
        animationComplete={animationComplete}
        animationParams={animationParams}
        setAnimationComplete={setAnimationComplete}
        isReady={isReady && imagesLoadingCompleted}
        rowLayout={rowLayout}
        imageByIndexFolder={imageByIndexFolder}
        foldersName={randomFolders}
        bonusAlignment={style?.bonusAlignment}
      />
      <Flex.ColAC className="mt-auto pt-2">
        <GamificationUI.CTAButton
          statusCode={statusCode}
          errorText={error}
          disabled={status === 'pending'}
          onClick={handleOfferAction}
          isVisible={animationComplete}
          buttonText={singleBonus?.buttonText}
          text={subheader}
          marginTop={'50px'}
          bgColor={style.backgroundButton}
          textColor={style.buttonTextColor}
          borderColor={style.buttonBorderColor}
        />
        <GamificationUI.Link
          isActive={showTerms}
          onClick={fetchBonusTerm}
          isVisible={animationComplete}
          textColor={style.termsTextColor}
        />
      </Flex.ColAC>
      <GamificationUI.Terms isVisible={showTerms} onClickOutside={outsideClick} text={offerTerms} />
    </>
  );
};

const textCss = (primaryTextBg) => {
  const gradientMap = {
    primary: `linear-gradient(
    90deg,
    rgba(var(--primary-rgb), 0) 0%,
    rgba(var(--primary-rgb), 0.4) 30%,
    rgba(var(--primary-rgb), 1) 50%,
    rgba(var(--primary-rgb), 0.4) 70%,
    rgba(var(--primary-rgb), 0) 100%


  )`,
    default: `linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 30%,
    rgba(0, 0, 0, 0.4) 70%,
    rgba(0, 0, 0, 0) 100%)`,
  };

  return css`
    background-image: ${gradientMap[primaryTextBg ? 'primary' : 'default']};
  `;
};

export default DefaultBoxContainer;
