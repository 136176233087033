import { createSlice } from '@reduxjs/toolkit';
import { FormFieldTypes } from 'components/shared/forms/formTypes';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { formatDateUTC } from 'utils/dateUtils';
import { InterceptorInitialSlice, SubmitData } from '../types/interceptors.types';
import { DeclarationDateFormat } from '../utils/interceptors.utils';

const initialState: InterceptorInitialSlice = {
  interceptors: [],
  formStatus: 'idle',
  declarationStatus: 'idle',
  fields: [],
  error: null,
};

export const fetchForm = createAbortThunk<FormFieldTypes[], void>('fetchForm', async (_, { rejectWithValue }) => {
  try {
    const apiURL = `${config.API_URL}/api/ews-crm/public/cms/player-content?key=declaration_sourceoffunds_form`;
    const response = await axiosInstance.get(apiURL);
    return response.data;
  } catch (error) {
    return rejectWithValue(getError.responseDataErrors(error));
  }
});

export const submitDeclaration = createAbortThunk(
  'submitDeclaration',
  async (data: SubmitData, { rejectWithValue }) => {
    try {
      const { sourcesofFunds, acceptTerms, declarationId } = data;
      const apiUrl = `${config.API_URL}/api/ews-crm/player/players/declaration/source-of-funds/${declarationId}`;
      const response = await axiosInstance.put(apiUrl, { sourcesofFunds, acceptTerms });
      return response.data;
    } catch (error) {
      return rejectWithValue(getError.responseDataErrors(error));
    }
  },
);

const interceptorsSlice = createSlice({
  name: 'interceptor',
  initialState,
  reducers: {
    setDeclaration(state, action) {
      const { declaration } = action.payload;
      state.interceptors = [
        ...state.interceptors,
        {
          ...declaration,
          declarationInfo: {
            ...declaration.declarationInfo,
            fromDate: formatDateUTC(
              DeclarationDateFormat,
              new Date(declaration.declarationInfo['fromDate']).toString(),
            ), //formatDate(declaration.declarationInfo['fromDate']),
            toDate: formatDateUTC(DeclarationDateFormat, new Date(declaration.declarationInfo['toDate']).toString()),
          },
        },
      ];
    },
    setDeclarations(state, action) {
      const declarations = action.payload.map((declaration) => {
        return {
          ...declaration,
          declarationInfo: {
            ...declaration.declarationInfo,
            fromDate: formatDateUTC(DeclarationDateFormat, declaration.declarationInfo['fromDate']), //formatDate(declaration.declarationInfo['fromDate']),
            toDate: formatDateUTC(DeclarationDateFormat, declaration.declarationInfo['toDate']),
          },
        };
      });
      state.interceptors = [...state.interceptors, ...declarations];
    },
    resetFormFieldsData(state) {
      state.fields = [];
      state.error = null;
      state.declarationStatus = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchForm.pending, (state) => {
        state.formStatus = 'pending';
      })
      .addCase(fetchForm.fulfilled, (state, action) => {
        state.formStatus = 'succeeded';
        state.fields = action.payload;
      })
      .addCase(fetchForm.rejected, (state) => {
        state.formStatus = 'failed';
      })
      .addCase(submitDeclaration.pending, (state) => {
        state.declarationStatus = 'pending';
      })
      .addCase(submitDeclaration.fulfilled, (state) => {
        state.declarationStatus = 'idle';
        if (state.interceptors.length > 0) {
          const arr = state.interceptors.slice(1);
          if (arr) {
            state.interceptors = arr;
          } else {
            state.interceptors = [];
          }
        }
      })
      .addCase(submitDeclaration.rejected, (state, action) => {
        state.declarationStatus = 'failed';
        state.fields = [];
        state.error = action.error.message;
      })
      .addCase(logoutUser, (state) => {
        state.interceptors = [];
        state.error = null;
        state.declarationStatus = 'idle';
      });
  },
});

export const { setDeclaration, setDeclarations, resetFormFieldsData } = interceptorsSlice.actions;
export const interceptorsReducer = interceptorsSlice.reducer;

export const selectInterceptors = {
  isModalVisibility: (state: RootState): boolean | null =>
    state.interceptors.interceptors.length > 0 && state.auth.login.isAuthenticated,
  formFields: (state: RootState) => state.interceptors.fields,
  interceptors: (state: RootState) => state.interceptors.interceptors,
  declarationStatus: (state: RootState) => state.interceptors.declarationStatus,
  formHasFetched: (state: RootState) =>
    state.interceptors.formStatus !== 'idle' && state.interceptors.formStatus !== 'pending',
};
export default interceptorsSlice;
