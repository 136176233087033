import { Social } from 'components/shared/forms/components/FormSocial/types/formSocial.types';
import { FormFieldTypes } from 'components/shared/forms/formTypes';
import { CreateThunk } from 'store/thunkCreators';

export const VERIFICATION_PLACEHOLDER_FORMAT = '<<[placeholder]*>>';

export enum StatusTypes {
  VERIFIED = 'Verified',
  PENDING = 'Pending',
  PROCESSING = 'Processing',
  REJECTED = 'Rejected',
}

export enum VerificationTypes {
  PLATFORM = 'PLATFORM',
  IDENFY = 'IDENFY',
  SHUFTI = 'SHUFTI',
  SUMSUB = 'SUMSUB',
}

export enum DocumentTypes {
  ID_CARD = 'IdentificationCard',
  PAYMENT_CARD = 'PaymentCard',
  GROUP_DOCUMENTS = 'GroupDocuments',
  NOT_OWN_ID_CARD = 'NotOwnIdentificationCard',
}

export enum DocumentTypeGroup {
  PAYMENT_CARDS = 'PaymentCard',
  OTHER_DOCUMENTS = 'Other',
}

export enum VerificationGroupLabelKey {
  OTHER_DOCUMENTS = 'MyAccount.KYC.otherDocumentsLabel',
  PAYMENT_CARDS = 'MyAccount.KYC.paymentCardLabel',
}

export const FieldsTransformMap: Record<string, string> = {
  countryId: 'countryName',
};

export declare module AccountTypes {
  type State = {
    countries: Countries[] | null;
    verification: VerificationState;
    formFields: MyAccountFormFieldsState;
    beneficientFields: MyAccountFormFieldsState;
    mfaChannels: MFAChannel[] | null;
    mfaCoolOff: string;
    referFriend: AccountTypes.ReferFriendState;
    isValidMfaCode: boolean | null;
    isLoadingCheckCode: boolean;
    isLoadingGenerateCode: boolean;
  };

  type MFAChannel = { text: string; value: 'email' | 'sms' | 'phoneCall' };

  type Thunks = {
    generateMfaCode: CreateThunk<void, string, string>;
    checkMfaCode: CreateThunk<string, boolean, string>;
    mfaChannels: CreateThunk<void, MFAChannel[] | null, { rejectValue: string } | string | null>;
    fetchCountries: CreateThunk<void, Countries[] | null, { rejectValue: string } | string | null>;
    fetchVerificationData: CreateThunk<void, VerificationResponse | null, { rejectValue: string } | string | null>;
    fetchReferralCode: CreateThunk<void, AccountTypes.ReferFriendCode, string | null>;
    fetchReferralData: CreateThunk<void, AccountTypes.ReferFriendDataResponse, string | null>;
    submitUploadedFiles: CreateThunk<void, boolean, { rejectValue: string } | string | null>;
    fetchMyAccountFormFields: CreateThunk<
      void,
      Record<string, FormFieldTypes[] | Social.Button[] | AccountTypes.ReferFriendButtonResponse[]>,
      { rejectValue: string } | string | null
    >;
    fetchMyBeneficientFields: CreateThunk<
      void,
      Record<string, FormFieldTypes[]>,
      { rejectValue: string } | string | null
    >;
  };

  type VerificationResponse = {
    status: StatusTypes;
    pendingDocuments: PendingDocumentType[];
    rejectedDocuments: RejectedDocumentsTypes[];
  };

  interface UploadedFilesStructure {
    id: number;
    subDocuments: FileStructure[];
  }

  type Countries = {
    text: string;
    value: string;
  };

  type MyAccountFormFieldsState = {
    isLoading: boolean;
    error: string;
    data: Record<string, FormFieldTypes[] | Social.Button[] | AccountTypes.ReferFriendButtonResponse[]>;
  };

  type ReferFriendButtonResponse = {
    name: string;
    buttons: AccountTypes.ReferFriendButton[];
  };
  type ReferFriendDataResponse = AccountTypes.ReferFriendPagination & {
    results: AccountTypes.ReferFriendData[];
  };
  type ReferFriendButton = {
    type: string;
    label: string;
  };
  type ReferFriendCode = {
    name: string;
    referralQrCode: string;
    referralCode: string;
    referralUrl: string;
  };
  type ReferFriendData = {
    referredPlayerStatus: string;
    referredPlayerName: string;
    expirationDate: string;
    createDate: string;
  };
  type ReferFriendPagination = {
    total: number;
    page: number;
    pageSize: number;
  };
  type ReferFriendState = {
    isLoading: boolean;
    hasFetched: boolean;
    error: string;
    codes: AccountTypes.ReferFriendCode | null;
    referrals: AccountTypes.ReferFriendData[] | null;
    buttons: AccountTypes.ReferFriendButton[] | null;
    pagination: AccountTypes.ReferFriendPagination;
  };

  type VerificationState = {
    isLoading: boolean;
    hasFetched: boolean;
    hasFetchError: string;
    data: VerificationResponse | null;
    submittedFiles: FilesState;
  };

  type FilesState = {
    isSubmitting: boolean;
    hasSubmitted: boolean;
    hasSuccess: boolean;
    error: string;
    data: UploadedFilesStructure[] | null;
  };

  type PendingDocumentType = {
    id: number;
    documentType: string;
    documentTypeGroup: string | 'PaymentCard';
    documentTranslationKey: string;
    descriptionText: string;
    descriptionTranslationKey: string;
    documentPlaceholder: string;
    subDocuments: SubDocumentsTypes[];
    reason?: string;
    isOwn?: boolean;
    verificationType: VerificationTypes;
    externalVerificationToken?: string;
    externalVerificationIFrameUrl?: string;
    externalVerificationReference?: string;
  };

  type SubDocumentsTypes = {
    id: number;
    documentTranslationKey: string;
  };

  type RejectedDocumentsTypes = {
    id: number;
    reason: string;
    type: string;
    fileNames: [];
    verificationType: VerificationTypes;
    externalVerificationToken?: string;
  };

  type FileStructure = {
    id: number;
    file: File;
  };

  type File = {
    fileName: string;
    fileSize?: string;
    fileType: string;
    content: string;
  };

  type MenuModeTypes = 'menu' | 'link' | 'header';

  type SectionName =
    | 'player-limits'
    | 'self-exclusion'
    | 'time-out'
    | 'free-spins'
    | 'free-bets'
    | 'wagering'
    | 'awards'
    | 'unsettled'
    | 'settled'
    | 'poker'
    | 'svara'
    | 'casinoHistory'
    | 'sportHistory'
    | 'liveCasinoHistory'
    | 'ipHistory'
    | 'bonusHistory'
    | 'transactionHistory'
    | 'tableGamesHistory'
    | 'fantasySportsHistory'
    | 'contactDetails'
    | 'address'
    | 'password'
    | 'verification'
    | 'deleteAccount'
    | 'multiFactorAuthentication'
    | 'beneficient';

  type SubMenu =
    | {
        key: SectionName;
        name: string;
        route?: string;
        subMenu?: SubMenu;
        menuMode?: MenuModeTypes;
        dataQid?: string;
        initOpen?: boolean;
      }[]
    | null;

  type Section = {
    key: SectionName;
    name: string;
    menuMode?: MenuModeTypes;
    route?: string;
    subMenu?: SubMenu;
    version?: string;
  };

  type SumSubMessage =
    | 'idCheck.onApplicantLoaded'
    | 'idCheck.onResize'
    | 'idCheck.onReady'
    | 'idCheck.onInitialized'
    | 'idCheck.onStepInitiated'
    | 'idCheck.onApplicantSubmitted'
    | 'idCheck.onApplicantResubmitted'
    | 'idCheck.onStepCompleted';
}
