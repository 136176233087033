import React from 'react';

type PrizeDropTableRowProps = {
  firstColText: string;
  secondColText: string;
  isHeader?: boolean;
  isPromotion?: boolean;
};

const PrizeDropTableRow: React.FC<PrizeDropTableRowProps> = ({
  firstColText,
  secondColText,
  isHeader,
  isPromotion,
}) => {
  return (
    <egtd-przd-table-row data-header={isHeader} data-promo={isPromotion}>
      <egtd-przd-table-cell>{firstColText}</egtd-przd-table-cell>
      <egtd-przd-table-cell>{secondColText}</egtd-przd-table-cell>
    </egtd-przd-table-row>
  );
};

export default PrizeDropTableRow;
