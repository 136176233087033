import React, { RefObject, useRef } from 'react';
import clsx from 'clsx';
import Icon from 'components/shared/Icon';
import { SpanStyled, StyledBonusBtn } from './BonusBtn.styled';
import { getIconStyle } from './Icon.styled';

type Props = {
  text?: string;
  icon: string;
  isActive: boolean;
  onClick: (e: React.MouseEvent<HTMLElement>, btnRef?: RefObject<HTMLDivElement>) => void;
  isAccaBoost?: boolean;
  isCircle?: boolean;
  type?: 'normal' | 'eventHeader';
  isCircleIcon?: boolean;
};

const classes = {
  normal: 'normal',
  eventHeader: 'event',
};

export const BonusBtn: React.FC<Props> = ({
  text,
  icon,
  isActive,
  onClick,
  isAccaBoost,
  isCircle,
  type = 'normal',
  isCircleIcon,
}) => {
  const btnRef = useRef<HTMLDivElement>(null);
  const btnClasses = clsx(
    `acca-bonuses-btn--${classes[type]} d-flex-center`,
    isActive && `acca-bonuses-btn--${classes[type]}--active`,
    'text-nowrap',
  );

  return (
    <StyledBonusBtn
      $showText={text}
      $isAccaBoost={isAccaBoost}
      $isCircleIcon={isCircleIcon}
      className={btnClasses}
      ref={btnRef}
      onClick={(e) => onClick(e, btnRef)}
      $isCircle={isCircle}
    >
      {text}
      {icon && (
        <>
          {isCircleIcon ? (
            <SpanStyled showText={!text}>
              <Icon
                defaultClassName={false}
                viewBox={isAccaBoost ? '0 0 22 14' : '0 0 14 16'}
                className="acca-bonuses-btn__icon"
                icon={icon}
                style={getIconStyle(isCircle, isAccaBoost, text, isCircleIcon)}
              />
            </SpanStyled>
          ) : (
            <Icon
              defaultClassName={false}
              viewBox={isAccaBoost ? '0 0 22 14' : '0 0 14 16'}
              className="acca-bonuses-btn__icon"
              icon={icon}
              style={getIconStyle(isCircle, isAccaBoost, text, isCircleIcon)}
            />
          )}
        </>
      )}
    </StyledBonusBtn>
  );
};
