import { useCallback, useEffect } from 'react';
import { useViewportScroll } from 'framer-motion';
import { isIOS } from 'react-device-detect';
import smoothscroll from 'smoothscroll-polyfill';
import { useOnOff } from 'hooks/common/useOnOff';
import { setTopNavHeaderStatus } from 'shared/common/features/appHeader';
import { selectBottomTabBar } from 'shared/common/features/bottomTabBar/slice/bottomTabBar.slice';
import { useAppDispatch, useAppSelector } from 'store';
export const useButtonBackToTop = (): {
  isBottomTabBarVisible: boolean;
  handleBackToTop: () => void;
  showBackToTop: boolean;
} => {
  const [showBackToTop, setShowBackToTop] = useOnOff(false);
  const isBottomTabBarVisible = useAppSelector(selectBottomTabBar.isBottomTabBarVisible);
  const dispatch = useAppDispatch();

  const { scrollY } = useViewportScroll();
  useEffect(() => {
    scrollY.onChange((v) => {
      if (v > 2500) {
        !showBackToTop && setShowBackToTop(true);
      } else {
        showBackToTop && setShowBackToTop(false);
      }
    });
  }, [setShowBackToTop, showBackToTop, scrollY]);

  const handleBackToTop = useCallback(() => {
    smoothscroll.polyfill();
    if (isIOS) {
      window.scrollTo({ top: 1, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    dispatch(setTopNavHeaderStatus('visible'));
  }, [dispatch]);

  return { isBottomTabBarVisible, handleBackToTop, showBackToTop };
};
