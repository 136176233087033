import { CSSProperties, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { GameLaunchTranslationKeys } from 'modules/casino/shared/features/gameLaunch/casinoLaunchConstants';
import { fitCover } from 'modules/casino/shared/utils/common/cdnConfig';
import { isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import config from 'utils/config';
import { CampaignMissionsProgressPopover } from './CampaignMissionsProgressPopover';
import { useCampaignMissionsProgress } from './hooks/useCampaignMissionsProgress';
import { TopBarButton } from '../TopBarButton';

type Props = {
  type: string;
  text?: string;
  backgroundImage: CSSProperties;
  isFreePlay: boolean;
};

export const CampaignMissionsProgress = ({ isFreePlay, type, backgroundImage, text }: Props) => {
  const { t } = useTranslation();
  const { data } = useCampaignMissionsProgress({ isFreePlay });
  const progressOuterBG = `${config.CDN_IMAGE_API}${fitCover(isMobile ? 41 * 2 : 45, 'auto')}${backgroundImage}2.png`;
  const progressInnerBG = `${config.CDN_IMAGE_API}${fitCover(isMobile ? 37 * 2 : 41, 'auto')}${backgroundImage}1.png`;

  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (!isEmpty(data)) {
      setProgress(Number(data.progress));
    }
  }, [data]);

  return (
    <TopBarButton
      type={type}
      restrictForFreePlay={isFreePlay}
      progress={{
        value: progress,
        background: { outer: `url(${progressOuterBG})`, inner: `url(${progressInnerBG})` },
      }}
      text={text && t(text)}
      renderChildren={(btnRef, handleClose, show) => (
        <CampaignMissionsProgressPopover
          parentRef={btnRef}
          handleClose={handleClose}
          text={t(GameLaunchTranslationKeys.missionCollectionPopover ?? '')}
          missionsTitle={data?.title}
          missionId={data?.missionId}
          show={show}
        />
      )}
    />
  );
};
