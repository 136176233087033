import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout/Flex';
import { UI } from 'components/shared/UI';
import FreeSpinCasinoCategory from 'modules/casino/modules/container/cellsContainer/containers/external/FreeSpinCasinoCategory';
import { casinoLaunchSelectors } from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import CTAUI from 'shared/common/features/myProfile/components/CTAUI';
import ParseHTML from 'shared/common/ParseHTML';
import { useAppSelector, useAppDispatch } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import usePromotions from './hooks/usePromotions';
import { fetchTerms } from '..';
import { Bonus, BonusButtonAction } from '../types/offersTab.types';

type Props = { bonus: Bonus; isOpen?: boolean };

export const OffersGroupBonus: React.FC<Props> = ({ bonus, isOpen }) => {
  const launchInProcess = useAppSelector(casinoLaunchSelectors.launchInProcess);
  const bonusHasAction =
    bonus.buttons && bonus.buttons.some((button) => button.buttonActionType !== BonusButtonAction.None);

  const { status, error, buttonClickHandler, elRef } = usePromotions(bonus);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const fetchBonusTerm = () => dispatch(fetchTerms({ bonusId: `${bonus.id}`, id: bonus.termsAndConditionsId }));

  return (
    <CTAUI.Accordion icon={bonus?.resourceName || ''} text={bonus.title} isOpen={isOpen}>
      <div ref={elRef} className="m-1">
        <ParseHTML html={bonus?.content || ''} options={{ trim: true }} />
      </div>
      {bonus.subContent && <p className="mpd-cta-item__section">{bonus.subContent}</p>}
      {bonus?.bonusExpirationDate && (
        <CTAUI.AccordionRow
          leftText={t(MyProfile.OffersTab.bonusPromotionExpirationDate)}
          rightText={bonus?.bonusExpirationDate}
        />
      )}
      <span onClick={fetchBonusTerm} className="d-block mpd-cta-item__link">
        {t(MyProfile.OffersTab.termsAndConditionsApply)}
      </span>
      {bonusHasAction && status === 'failure' && error && <p>{error}</p>}
      {bonusHasAction && !error && (
        <Flex className="w-100" style={{ gap: '10px' }}>
          {bonus.buttons.map(
            (button, index) =>
              button.buttonActionType !== BonusButtonAction.None && (
                <UI.Button
                  key={index}
                  class={'f-basis-100'}
                  size="md"
                  variant={button.buttonActionType === BonusButtonAction.Cancel ? 'secondary' : undefined}
                  isDisabled={status === 'pending'}
                  onClick={buttonClickHandler(button)}
                  text={button.buttonText ? button.buttonText : '\u00A0'}
                />
              ),
          )}
        </Flex>
      )}
      {/* REMOVE  freeSpinGamesIds After release 2.43.0 */}
      {!isEmpty(bonus.freeSpinGamesIds) &&
        isEmpty(bonus.bonusOfferCasinoGamesIds) &&
        (launchInProcess ? (
          <FreeSpinCasinoCategory bonusId={bonus.id + '-old'} freeSpinGamesIds={bonus.freeSpinGamesIds} />
        ) : (
          <FreeSpinCasinoCategory bonusId={bonus.id + '-old'} freeSpinGamesIds={bonus.freeSpinGamesIds} />
        ))}
      {!isEmpty(bonus.bonusOfferCasinoGamesIds) &&
        (launchInProcess ? (
          <FreeSpinCasinoCategory bonusId={bonus.id} freeSpinGamesIds={bonus.bonusOfferCasinoGamesIds} />
        ) : (
          <FreeSpinCasinoCategory bonusId={bonus.id} freeSpinGamesIds={bonus.bonusOfferCasinoGamesIds} />
        ))}
    </CTAUI.Accordion>
  );
};
