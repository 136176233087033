import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Flex } from 'components/shared/layout';
import { Modal } from 'components/shared/Modal';
import NoDataMessage from 'components/shared/NoDataMessage';
import PrizeDropGiftItem from 'components/shared/prizeDrop/components/PrizeDropGiftItem';
import ViewAll from 'components/shared/prizeDrop/components/PrizeDropPromoGiftItemWrapper';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import { PromoUI } from '../components/PromoUI';
import { PromotionTypes } from '../types/promotions.types';

type Props = {
  rewards: PromotionTypes.Rewards[];
  title?: string;
  text?: string;
  iconResource: string;
};

const PrizeDropRewardsModal: React.FC<Props> = ({ iconResource, title, text, rewards }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isTablet = useMediaQuery({ query: `(min-width: 576px)` });

  return (
    <Modal visible className="modal-terms" size="xl">
      <Modal.Close closeText={t(Promotions.Common.closeBtn)} closeAction={() => dispatch(hideRootModal())} />
      <Modal.Body className={`custom-scrollbars ${isTablet ? 'p-4' : 'p-2'} prm-page__content`} padding="lg">
        <PromoUI.PageBoxTitle title={title} />
        <p>{text && <PromoUI.RankingBox isTitleCell text={text} />}</p>
        <Flex.Col>
          {isEmpty(rewards) ? (
            <NoDataMessage message={t(Promotions.PrizeDrop.noRewards)} />
          ) : (
            <ViewAll textBtn={t(Promotions.PrizeDrop.seeAll)} showMore={false}>
              {rewards?.map((reward, index) => (
                <PrizeDropGiftItem
                  key={`reward-${index}`}
                  title={reward.bonusName || ''}
                  isActive={!!reward.givenAwards}
                  givenAwards={reward.givenAwards}
                  totalAwards={reward.totalAwards}
                  iconResource={iconResource}
                  isPromotion
                />
              ))}
            </ViewAll>
          )}
        </Flex.Col>
      </Modal.Body>
    </Modal>
  );
};

export default PrizeDropRewardsModal;
