import { useMemo } from 'react';
import { categorySelectors } from 'modules/casino/modules/container/cellsContainer/containers/inner/slice/casinoCategorySlice';
import { gameProvidersSelectors } from 'modules/casino/modules/container/shared/features/casinoGameProviders/slice/casinoGameProvidersSlice';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { casinoLobbySelectors } from 'modules/casino/modules/lobby/containersLobby/slice/casinoLobbySlice';
import { providersCategorySelectors } from 'modules/casino/modules/lobby/providerLobby/slice/providersCategorySlice';
import { selectLanguage } from 'shared/common/features/settings/slice/settings.slice';
import { useAppSelector } from 'store';
import useGeneralCasinoType from './useGeneralCasinoType';

export const useGetCategoryParams = (categoryId: string, categoryLayoutType: CategoryLayoutTypes): any => {
  const { casinoType } = useGeneralCasinoType();
  const language = useAppSelector(selectLanguage);
  const activeProviderAlias: string = useAppSelector(gameProvidersSelectors.activeProvider);

  const keyMap = useMemo(() => {
    return {
      [CategoryLayoutTypes.LOBBY]: `${language}_${casinoType}`,
      [CategoryLayoutTypes.INNER_CATEGORY]: `${language}_${categoryId}`,
      [CategoryLayoutTypes.PROVIDER_CATEGORY]: `${language}_${casinoType}_${activeProviderAlias}`,
      [CategoryLayoutTypes.PROVIDER_CATEGORY_MOBILE_LAYOUT]: `${language}_${casinoType}_${activeProviderAlias}`,
      [CategoryLayoutTypes.FAVOURITES_CATEGORY]: `${language}_${categoryId}`,
      [CategoryLayoutTypes.FAVOURITES_MODAL_CATEGORY]: `${language}_${CategorySubTypes.FAVOURITES_MODAL}`,
      [CategoryLayoutTypes.IN_GAME_FAVOURITES_CATEGORY]: `${language}_${CategorySubTypes.IN_GAME_FAVOURITES}`,
      [CategoryLayoutTypes.LANDING_CATEGORY]: `${language}_${categoryId}`,
      [CategoryLayoutTypes.SEARCH_RESULTS_CATEGORY]: `${language}_${CategorySubTypes.RESULT_SEARCHED}`,
      [CategoryLayoutTypes.EXTERNAL_CATEGORY]: `${language}_${categoryId}`,
      [CategoryLayoutTypes.SVARA_CATEGORY]: `${language}_${categoryId}`,
      [CategoryLayoutTypes.MOST_SEARCHED_CATEGORY]: `${language}_${CategorySubTypes.MOST_SEARCHED}`,
    };
  }, [language, casinoType, activeProviderAlias, categoryId]);

  const selectorMap = useMemo(() => {
    return {
      [CategoryLayoutTypes.LOBBY]: (state, key, id) => casinoLobbySelectors.lobbyCategory(state, key, id),
      [CategoryLayoutTypes.INNER_CATEGORY]: (state, key) => categorySelectors.casinoCategory(state, key),
      [CategoryLayoutTypes.PROVIDER_CATEGORY]: (state, key, id) =>
        providersCategorySelectors.providerLobbyCategory(state, key, id),
      [CategoryLayoutTypes.PROVIDER_CATEGORY_MOBILE_LAYOUT]: (state, key, id) =>
        providersCategorySelectors.providerLobbyCategory(state, key, id),
      [CategoryLayoutTypes.SEARCH_RESULTS_CATEGORY]: (state, key) => categorySelectors.casinoCategory(state, key),
      [CategoryLayoutTypes.MOST_SEARCHED_CATEGORY]: (state, key) => categorySelectors.casinoCategory(state, key),
      [CategoryLayoutTypes.FAVOURITES_CATEGORY]: (state, key) => categorySelectors.casinoCategory(state, key),
      [CategoryLayoutTypes.FAVOURITES_MODAL_CATEGORY]: (state, key) => categorySelectors.casinoCategory(state, key),
      [CategoryLayoutTypes.IN_GAME_FAVOURITES_CATEGORY]: (state, key) => categorySelectors.casinoCategory(state, key),
      [CategoryLayoutTypes.LANDING_CATEGORY]: (state, key) => categorySelectors.casinoCategory(state, key),
      [CategoryLayoutTypes.EXTERNAL_CATEGORY]: (state, key) => categorySelectors.casinoCategory(state, key),
      [CategoryLayoutTypes.SVARA_CATEGORY]: (state, key) => categorySelectors.casinoCategory(state, key),
    };
  }, [categoryId]);
  const category = useAppSelector((state) =>
    selectorMap[categoryLayoutType](state, keyMap[categoryLayoutType], categoryId),
  );
  return { ...category };
};
