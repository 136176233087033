import React, { HTMLAttributes, memo } from 'react';
import clsx from 'clsx';
import { CSSProp } from 'styled-components';
import { StyledCTAText } from './CTAText.styled';
import Error from './Error';

interface Props extends HTMLAttributes<HTMLDivElement> {
  showWinText: boolean;
  text: string;
  winText: string | JSX.Element | JSX.Element[];
  error?: string;
  className?: string;
  styledCss?: CSSProp;
  bgColor?: string;
  textColor?: string;
  fontWeight?: string;
}

const CTAText: React.FC<Props> = ({
  showWinText,
  text,
  winText,
  styledCss,
  error,
  className,
  textColor,
  bgColor,
  fontWeight,
  ...rest
}) => {
  const textClass = clsx('d-flex-center', className);

  return (
    <StyledCTAText
      className={textClass}
      {...rest}
      $styledCss={styledCss}
      $textColor={textColor}
      $bgColor={bgColor}
      $fontWeight={fontWeight}
    >
      {error ? <Error text={error} /> : showWinText ? winText : text}
    </StyledCTAText>
  );
};

export default memo(CTAText);
