import React, { HTMLAttributes } from 'react';
import { motion } from 'framer-motion';
import { container, item } from '../../utils/ssbt.utils';

/** Terminal Modal Body Section */
interface ContentWrapperProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({ children }) => {
  return (
    <motion.div initial="hidden" animate="visible" variants={container}>
      <motion.div className="d-flex-col-ac" variants={item}>
        {children}
      </motion.div>
    </motion.div>
  );
};

export default ContentWrapper;
