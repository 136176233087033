import { useCallback, useEffect } from 'react';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { fetchGeolocationStatus, selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { CustomPlatformEvents } from 'shared/common/hooks/useHandleCustomEvents';
import { useAppDispatch, useAppSelector } from 'store';
import { useLayoutConfigType } from './useLayoutConfigType';

const DEFAULT_POLLING_INTERVAL = 1000 * 60 * 30;

export const useGeolocationCheck = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const isIpGeolocationTrackingEnabled = useAppSelector(selectGeneral.isIpGeolocationTrackingEnabled);

  const pollingIntervalConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.POLLING_GEOLOCATION_CHECK,
  });

  const pollingInterval = pollingIntervalConfig || DEFAULT_POLLING_INTERVAL;

  const onVPNDetection = useCallback(() => {
    document.dispatchEvent(new Event(CustomPlatformEvents.HIGH_PRIORITY_MODAL));
    dispatch(
      showRootModal({
        modalType: 'GEOLOCATION_MODAL',
        modalPriority: ModalPriorityEnum.HIGH,
        modalProps: {},
        modalId: 'GEOLOCATION_MODAL',
      }),
    );
  }, [dispatch]);

  const checkIsGeolocationRestricted = useCallback(() => {
    if (isIpGeolocationTrackingEnabled && isAuthenticated) {
      dispatch(fetchGeolocationStatus()).then(
        (action) => action.meta.requestStatus === 'fulfilled' && action.payload === 'true' && onVPNDetection(),
      );
    }
  }, [dispatch, isAuthenticated, isIpGeolocationTrackingEnabled, onVPNDetection]);

  useEffect(() => {
    if (isIpGeolocationTrackingEnabled && isAuthenticated) {
      checkIsGeolocationRestricted();
      const interval = setInterval(() => {
        checkIsGeolocationRestricted();
      }, pollingInterval);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isAuthenticated, onVPNDetection, pollingInterval, checkIsGeolocationRestricted, isIpGeolocationTrackingEnabled]);

  return { onVPNDetection };
};
