import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import config from 'utils/config';
import { MetaData } from '../types/metaData.types';

const initialState: MetaData.State = {
  metaData: null,
  metaDataStatus: 'idle',
};

export const metaDataThunks: MetaData.Thunks = {
  metaData: createAbortThunk(`metaData/metaTags`, async () => {
    const url = `${config.API_URL}/api/cms/public/metatags/all-tags`;
    const response = await axiosInstance.get(url);

    return response.data;
  }),
};

const metaDataContent = createSlice({
  name: 'metaDataContent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(metaDataThunks.metaData.fulfilled, (state, action) => {
      state.metaDataStatus = 'succeeded';
      state.metaData = action.payload;
    });
    builder.addCase(metaDataThunks.metaData.pending, (state) => {
      state.metaDataStatus = 'pending';
    });
    builder.addCase(metaDataThunks.metaData.rejected, (state) => {
      state.metaDataStatus = 'failure';
      state.metaData = null;
    });
  },
});

export default metaDataContent.reducer;

export const selectMetaData = {
  metaData: (state: RootState) => state.metaData.metaData,
};
