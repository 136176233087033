import { GameCellButtonsWrapper } from './GameCellButtons';
import { GameCellDescription } from './GameCellDescription';
import { GameCellFavouritesButton } from './GameCellFavouritesButton';
import { GameCellLimits } from './GameCellLimits';
import { GameCellMedia } from './GameCellMedia';
import { GameCellRestriction } from './GameCellRestriction';
import { GameCellTableCharacters } from './GameCellTableCharacters';
import { GameCellTableLimits } from './GameCellTableLimits';
import { GameCellTablePlayers } from './GameCellTablePlayers';
import { GameCellTags } from './GameCellTags';
import { GameInfoModal } from './GameInfoModal';

export const SharedComponents = {
  GameCellRestriction,
  GameCellDescription,
  GameCellFavouritesButton,
  GameCellButtonsWrapper,
  GameCellTags,
  GameInfoModal,
  GameCellMedia,
  GameCellTableCharacters,
  GameCellTableLimits,
  GameCellTablePlayers,
  GameCellLimits,
};
