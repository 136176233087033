import { CreateThunk } from 'store/thunkCreators';

export enum SummaryStatusTypes {
  WIN = 'WIN',
  LOSS = 'LOSS',
  NEUTRAL = 'NEUTRAL',
}
export enum PeriodTypes {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
}
export enum LimitTypes {
  BET = 'Bet',
  LOSS = 'Loss',
  DEPOSIT = 'Deposit',
}
export declare module MonthlyHistory {
  type FetchRequestDataSummary = {
    month: number;
    year: number;
  };
  type FetchRequestDataLimits = {
    month: number;
    year: number;
    page: number;
    pageSize: number;
  };

  type FetchResponseDataSummary = SummaryItem;
  type FetchResponseDataLimits = {
    results: MonthlyHistory.LimitsItem[];
    total: number;
    page: number;
    pageSize: number;
  };

  type ErrorData = string | undefined | null;
  type Thunks = {
    fetchSummary: CreateThunk<MonthlyHistory.FetchRequestDataSummary, MonthlyHistory.ApiResponse>;
    fetchLimits: CreateThunk<MonthlyHistory.FetchRequestDataLimits, MonthlyHistory.FetchResponseDataLimits>;
  };

  type Filters = {
    month: number | null;
    year: number | null;
    page: number;
    pageSize: number;
    total: number;
  };
  type State = {
    filters: MonthlyHistory.Filters;
    data: {
      summary: MonthlyHistory.SummaryItem | null;
      limits: MonthlyHistory.LimitsItem[] | null;
    };
    error: {
      summary: MonthlyHistory.ErrorData | null;
      limits: MonthlyHistory.ErrorData | null;
    };
    isLoading: {
      summary: boolean;
      limits: boolean;
    };
    isExpanded: {
      limits: boolean;
    };
  };

  type SummaryItemApi = {
    playerId: number;
    totalLogins: string;
    initialBalance: number;
    totalWin: number;
    totalBet: number;
    bonusAchievedAmount?: number;
    withdrawalAmountPerPaymentMethod: number;
    depositAmountPerPaymentMethod: number;
    totalDepositAmount: number;
    totalWithdrawalAmount: number;
    netBalance?: number; // TO DO will be removed
    netBalanceStatus?: SummaryStatusTypes; // TO DO will be removed
    endBalance?: number;
    endBalanceStatus?: SummaryStatusTypes;
    paymentMethod: string;
  };

  type ApiResponse = {
    content: SummaryItemApi[];
  };

  type SummaryItem = {
    numberOfLogins: string;
    totalBet: number;
    totalWin: number;
    bonusAchievedAmount?: number;
    initialBalance: number;
    endBalance: number;
    endBalanceStatus?: SummaryStatusTypes;
    deposits: {
      total: number;
      byProvider: {
        paymentMethod: string;
        amount: number;
      }[];
    };
    withdraws: {
      total: number;
      byProvider: {
        providerName: string;
        amount: number;
      }[];
    };
  };
  type LimitsItem = {
    limitId: number;
    limitType: LimitTypes;
    periodType: PeriodTypes;
    subType: string;
    oldLimit?: number;
    newLimit: number;
    createdOn: number;
  };
}
