import React, { useCallback, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { GridType } from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import Icon from 'modules/casino/shared/components/icons/Icon';
import { useCurrency } from 'modules/casino/shared/hooks/useCurrency';
import { useCurrencyConfig } from 'modules/casino/shared/hooks/useCurrencyConfig';
import { isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import { formatCurrencyDisplay } from 'modules/casino/shared/utils/helpersCasino';
import { Theme } from 'modules/casino/theme/Theme';
import { loadTypeClass } from './components/DataCharacterMaping';
import { RenderItem } from './components/RenderItem';
import { TotalPlayersStyled } from './components/TotalPlayers.styled';
import { GameCellDataCharactersTypes } from './gameCellTableCharactersConstants';
import { useColumnLength } from './hooks/useColumnLength';
import { useDataCharacters } from './hooks/useDataCharacters';

interface DataCharactersProps {
  // gameData: GameData;
  gameUniqueId: string;
  categoryId: string;
  classFromLayout: string;
  layoutStyleLoader;
  totalPlayers?: number;
  icon?: string;
  iconPath?: string;
}

const GameCellTableCharacters: React.FC<DataCharactersProps> = ({
  gameUniqueId,
  categoryId,
  classFromLayout: classFromLayout,
  layoutStyleLoader,
  iconPath = Theme.icons.liveCasinoGames,
}) => {
  const dataCharacterRef = useRef(null);
  const {
    showTableCharacters,
    gameConfig,
    luckyNumbers,
    isMacro,
    category,
    visualizationTemplateId,
    totalPlayers,
    gridType,
    description,
    gameData,
    hasLiveFeedInfo,
  } = useDataCharacters({ gameUniqueId, categoryId });
  const [dataCharacterRefDefined, setDataCharacterRefDefined] = useState(false);
  const { formatCurrency, currencySymbol: currencySymbolDefault } = useCurrency();
  const { columns } = useColumnLength(dataCharacterRef, dataCharacterRefDefined);
  const currencyCode = gameData.gameCurrency ? formatCurrency(gameData.gameCurrency) : currencySymbolDefault;
  const currencySymbol = currencyCode?.replace(/[.]$/, '');
  const { formatToComma, currencySymbolFirst, spaceBetween } = useCurrencyConfig();

  const setRefs = useCallback(
    (node) => {
      if (node !== null) {
        dataCharacterRef.current = node;
        setDataCharacterRefDefined(true);
      }
    },
    [dataCharacterRef],
  );

  const isMultiplierLarge = useMemo(
    () =>
      gameConfig?.configuration?.some((symbol) => {
        if (typeof symbol === 'object') {
          if (symbol?.multiplier) {
            return symbol.multiplier > 99;
          }
        } else {
          return false;
        }
      }),
    [gameConfig.configuration],
  );

  if (!showTableCharacters) return null;
  const isSvara = visualizationTemplateId === GameCellDataCharactersTypes.Svara && hasLiveFeedInfo;
  const isBlackJack =
    visualizationTemplateId === GameCellDataCharactersTypes.Blackjack ||
    visualizationTemplateId === GameCellDataCharactersTypes.Poker;
  const getLuckyNumber = (index) => {
    if (!luckyNumbers) return undefined;
    const luckyNumberFilter = luckyNumbers.filter((number) => {
      return number.index === index;
    });

    if (luckyNumberFilter[0]) {
      return luckyNumberFilter[0].luckyNumber;
    }
    return undefined;
  };
  const renderItem = (symbol, idx) => (
    <RenderItem
      key={idx}
      symbol={symbol}
      category={category}
      classFromLayout={classFromLayout}
      luckyNumber={getLuckyNumber(idx)}
      showLuckyNumber={columns ? columns > idx : false}
    />
  );

  if (
    !gameConfig ||
    typeof gameConfig.configuration === 'string' ||
    (gameConfig && gameConfig.configuration && gameConfig.configuration.length === 0) ||
    (gameConfig && gameConfig.description !== description)
  ) {
    return null;
  }
  const charsStyles = clsx(
    'live-cell__game-data-chars',
    classFromLayout,
    visualizationTemplateId && loadTypeClass[GameCellDataCharactersTypes[visualizationTemplateId]],
    isMacro && 'live-cell__game-data-chars--macro',
    isMultiplierLarge && 'live-cell__game-data-chars--large',
  );
  const charsWrapperStyles = clsx(
    'live-cell__game-data',
    isBlackJack && 'live-cell__game-data__blackjack-wrapper',
    isEmpty(totalPlayers) && 'live-cell__game-data--bg-remove',
  );

  const DataCharactersWrapperStyled = layoutStyleLoader('DataCharactersWrapperStyled', 'data');
  return (
    <DataCharactersWrapperStyled
      className={charsWrapperStyles}
      $isBlackJack={isBlackJack}
      $isSlider={GridType.SLIDER === gridType}
    >
      {gameConfig.configuration && (
        <div ref={setRefs} className={charsStyles}>
          {gameConfig.configuration?.map((symbol, idx) => renderItem(symbol, idx))}
        </div>
      )}
      {isSvara && (
        <div className="live-cell__game-data__svara">
          <Icon className="live-cell__game-data__svara-icon" iconPath={iconPath} icon={'ante'} />
          {`Ante: ${formatCurrencyDisplay({ currencySymbolFirst, spaceBetween, sum: gameData.ante, currency: currencySymbol, formatToComma })}`}
        </div>
      )}
      {!isEmpty(totalPlayers) && (
        <TotalPlayersStyled>
          <Icon className="live-cell__meta-users-icon" iconPath={iconPath} icon={'users-online'} />
          {isSvara ? (
            <span className="live-cell__meta-users__count">{`${gameData.seatsTaken.length}/${gameData.seats}`}</span>
          ) : (
            <span>{totalPlayers > 0 ? totalPlayers : 0}</span>
          )}
        </TotalPlayersStyled>
      )}
    </DataCharactersWrapperStyled>
  );
};

export default GameCellTableCharacters;
