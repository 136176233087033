import React, { memo } from 'react';
import clsx from 'clsx';
import Icon from './Icon';

interface Props {
  isExpanded?: boolean;
  className?: string;
  size?: number;
}

const ExpandableIcon: React.FC<Props> = ({ isExpanded, className, size }) => {
  const iconClass = clsx('btn-exp-toggler__icon', isExpanded && 'rotated-180', className);

  return <Icon defaultClassName={false} className={iconClass} size={size} icon={`chevron`} />;
};

export default memo(ExpandableIcon);
