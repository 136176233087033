import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { UI } from 'components/shared/UI';

import { useAppDispatch } from 'store';
import { Breakpoints } from 'theme/Theme';
import { BetSlip, CashierTranslations } from 'utils/common/translationUtils/translationStrings';
import { anexInfoThunk, resetAnexInfoState } from './slice/anexReport.slice';
import FinancialDatePicker from '../../cashierModalUI/FinancialDatePicker';
import { CashierModalUI } from '../../cashierModalUI/Index';

const AnexDateControls = () => {
  const dispatch = useAppDispatch();
  const [selectedDate, setSelectedDate] = useState('');
  const { t } = useTranslation();

  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });

  const handleSearchButton = useCallback(() => {
    const now = new Date();
    const startDate = new Date(selectedDate);
    let endDate = new Date(selectedDate);

    if (startDate.toDateString() === now.toDateString()) {
      startDate.setHours(0, 0, 0, 0);
      endDate = now;
    } else {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
    }

    const formattedStartDate = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(
      2,
      '0',
    )}-${String(startDate.getDate()).padStart(2, '0')}T${String(startDate.getHours()).padStart(2, '0')}:${String(
      startDate.getMinutes(),
    ).padStart(2, '0')}:${String(startDate.getSeconds()).padStart(2, '0')}`;
    const formattedEndDate = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(
      endDate.getDate(),
    ).padStart(2, '0')}T${String(endDate.getHours()).padStart(2, '0')}:${String(endDate.getMinutes()).padStart(
      2,
      '0',
    )}:${String(endDate.getSeconds()).padStart(2, '0')}`;

    dispatch(anexInfoThunk.getAnexReport({ fromDate: formattedStartDate, toDate: formattedEndDate }));
    setSelectedDate('');
    dispatch(resetAnexInfoState());
  }, [dispatch, selectedDate]);

  const clearDates = () => {
    setSelectedDate('');
    dispatch(resetAnexInfoState());
  };
  const handleDate = (date) => {
    setSelectedDate(date);
    dispatch(resetAnexInfoState());
  };
  const getTodayAnexReport = () => {
    const now = new Date();
    const startDate = new Date(now);
    startDate.setHours(0, 0, 0, 0);

    const formattedStartDate = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}T00:00:00`;
    const formattedEndDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}T${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;

    dispatch(anexInfoThunk.getAnexReport({ fromDate: formattedStartDate, toDate: formattedEndDate }));
    setSelectedDate('');
    dispatch(resetAnexInfoState());
  };
  return (
    <>
      <CashierModalUI.InputWrapper>
        <CashierModalUI.InputWrapper size="sm">
          <FinancialDatePicker
            label={t(CashierTranslations.FinancialModal.chooseDay)}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => handleDate(date)}
            value={selectedDate ? selectedDate : t(CashierTranslations.FinancialModal.chooseDay)}
            selected={selectedDate ? selectedDate : null}
            dataQid="fromDate"
            maxDate={new Date()}
          />

          <UI.Button
            size={isDesktop ? 'xl' : 'lg'}
            text={`${t(CashierTranslations.AnexReport.todayAnexReport)}`}
            onClick={getTodayAnexReport}
            className="col-lg-fifth-1 cashier-form-control__btn--check"
          />
        </CashierModalUI.InputWrapper>

        <CashierModalUI.ButtonsWrapper>
          <UI.Button
            size={isDesktop ? '2xl' : 'lg'}
            variant="secondary"
            text={`${t(BetSlip.Header.clearSelections)}`}
            onClick={clearDates}
          />
          <UI.Button
            text={`${t(CashierTranslations.FinancialModal.searchButton)}`}
            size={isDesktop ? '2xl' : 'lg'}
            variant="primary"
            isDisabled={!selectedDate}
            onClick={handleSearchButton}
          />
        </CashierModalUI.ButtonsWrapper>
      </CashierModalUI.InputWrapper>
    </>
  );
};

export default AnexDateControls;
