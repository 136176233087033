import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import { CellDescription } from './components';
import BigWinIcon from './components/BigWinIcon';
import ImageWrapper from './components/ImageWrapper';
import { CasinoWidgetsTypes } from '../../../../types/casinoWidgetsTypes';
import { BIG_WIN_ICON, BigWinsWidgetTranslationKeys } from '../../bigWinsConstants';

interface GameItemProps {
  bigWin: CasinoWidgetsTypes.BigWin;
}

const BigWinsCell: React.FC<GameItemProps> = ({ bigWin }) => {
  const widgetGameRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = () => {
    const { navigationUrl } = bigWin;
    if (isEmpty(navigationUrl)) return;
    navigate(navigationUrl);
  };

  return (
    <div className="bww-item__wrapper" ref={widgetGameRef} onClick={handleNavigate}>
      {bigWin.showIcon && <BigWinIcon icon={BIG_WIN_ICON} text={t(BigWinsWidgetTranslationKeys.bigWinIconLabel)} />}
      <ImageWrapper imageSrc={bigWin?.backgroundLocation} />
      <CellDescription bigWin={bigWin} />
    </div>
  );
};

export default BigWinsCell;
