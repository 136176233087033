import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataLoader } from 'components/shared/loaders';
import { AuthUI } from 'pages/auth/components';
import { useAppDispatch, useAppSelector } from 'store';
import { SEActivationError, SEActivationSuccess } from './components';
import SENotConfirm from './components/SENotConfirm';
import {
  SEConfirmationThunks,
  selectSEConfirmation,
  resetSEConfirmationState,
} from './slice/selfExclusionConfirmation.slice';
import { csType } from './types/selfExclusionConfirmation.types';

export const SelfExclusionConfirmation: React.FC = () => {
  const isLoading = useAppSelector(selectSEConfirmation.isLoading);
  const submitError = useAppSelector(selectSEConfirmation.submitError);
  const successConfirmation = useAppSelector(selectSEConfirmation.successConfirmation);
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const urlToken = urlSearchParams.get('token');
  const cstype = (urlSearchParams.get('cstype') as csType) || '';

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (urlToken && cstype) {
      dispatch(SEConfirmationThunks.validateSelfExclusion({ urlToken, cstype }));
    }
  }, [dispatch, cstype, urlToken]);

  useEffect(() => {
    return () => {
      dispatch(resetSEConfirmationState());
    };
  }, []);

  useEffect(() => {
    if (!urlToken || !cstype) {
      navigate('/');
    }
  }, []);

  if (isLoading || !urlToken || !cstype) {
    return <DataLoader />;
  }

  return (
    <AuthUI.Page type="reg">
      <AuthUI.Panel>
        {submitError ? (
          <SEActivationError />
        ) : successConfirmation === 'Confirmed' ? (
          <SEActivationSuccess />
        ) : (
          <SENotConfirm />
        )}
      </AuthUI.Panel>
    </AuthUI.Page>
  );
};
