import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { HighlightNewFeature } from '../types/highlightNewFeature.types';

const initialState: HighlightNewFeature.InitialState = {
  isActive: false,
  components: {
    collapsedHeader: {
      types: 'click',
      isView: false,
      text: 'DragHeader',
    },
    // REMOVE WHEN ADD SPORT WIDGET
    // holdToBet: {
    //   types: 'hold',
    //   isView: false,
    //   text: 'HoldToBet',
    // },
  },
  activeElement: null,
  activeComponent: '',
  platformIsDone: false,
};

const highlightNewFeature = createSlice({
  name: 'highlightNewFeature',
  initialState,
  reducers: {
    collapsed(state) {
      state.isActive = !state.isActive;
    },
    setIsView(state, action: PayloadAction<{ activeComponent: string; isLast: boolean }>) {
      const { activeComponent, isLast } = action.payload;
      state.components[activeComponent].isView = true;

      state.activeElement = null;
      state.activeComponent = '';
      state.isActive = !isLast;
      isLast && (state.platformIsDone = true);
    },
    setActiveElement(state, action) {
      if (state.activeComponent) {
        state.activeElement = action.payload;
        state.isActive = true;
      }
    },
    setActiveComponent(state, action: PayloadAction<string>) {
      state.activeComponent = action.payload;
    },
    setHide(state) {
      state.activeElement = null;
      state.activeComponent = '';
      state.isActive = false;
    },
    setIsDone(state, action: PayloadAction<boolean>) {
      state.platformIsDone = action.payload;
    },
  },
});

const { collapsed, setActiveElement, setActiveComponent, setIsView, setIsDone, setHide } = highlightNewFeature.actions;
export const highlightNewFeatureActions = {
  collapsed: collapsed,
  setActiveElement: setActiveElement,
  setActiveComponent: setActiveComponent,
  setIsView: setIsView,
  setIsDone: setIsDone,
  setHide: setHide,
};
export const { reducer: highlightNewFeatureReducer } = highlightNewFeature;

export const selectHighlightNewFeature = {
  isActive: (state: RootState): boolean => state.highlightNewFeature.isActive,
  components: (state: RootState): HighlightNewFeature.ComponentsType => state.highlightNewFeature.components,
  activeComponent: (state: RootState): string => state.highlightNewFeature.activeComponent,
  activeElement: (state: RootState) => state.highlightNewFeature.activeElement,
  isDone: (state: RootState) => state.highlightNewFeature.platformIsDone,
};
