import styled from 'styled-components';
import { prizeDropCSSNS } from '../../../PrizeDropCellWidgetConstants';

const SCTitleDefault = styled.div`
  position: relative;

  padding: 2px 23px 2px 2px;

  border-radius: var(--${prizeDropCSSNS}-br-normal);
`;

const SCCampaignItem = styled(SCTitleDefault)<{ $isActive?: boolean; $isCurrent?: boolean }>`
  margin-bottom: var(--${prizeDropCSSNS}-spacer-default);

  cursor: ${({ $isActive, $isCurrent }) => (!$isActive && !$isCurrent ? 'auto' : 'pointer')};

  background: ${({ $isCurrent, $isActive }) =>
    $isCurrent
      ? `var(--${prizeDropCSSNS}-gradient-campaign-item--current)`
      : $isActive
        ? `var(--${prizeDropCSSNS}-gradient-campaign-item--active)`
        : `var(--${prizeDropCSSNS}-gradient-campaign-item--inactive)`};

  &::after {
    position: absolute;
    right: 3px;
    top: calc(50% - 12px);

    content: ${({ $isCurrent, $isActive }) =>
      $isCurrent || $isActive
        ? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='20' viewBox='0 0 24 24'%3E%3Cpath d='m.6 20.4 8.2-8.4-8.2-8.4a2.17 2.17 0 0 1 0-3 2.17 2.17 0 0 1 3 0l9.8 9.8a2.17 2.17 0 0 1 0 3l-9.8 10a2.17 2.17 0 0 1-3 0 2.18 2.18 0 0 1 0-3z' fill='black' /%3E%3C/svg%3E")`
        : `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='20' viewBox='0 0 24 24'%3E%3Cpath d='m.6 20.4 8.2-8.4-8.2-8.4a2.17 2.17 0 0 1 0-3 2.17 2.17 0 0 1 3 0l9.8 9.8a2.17 2.17 0 0 1 0 3l-9.8 10a2.17 2.17 0 0 1-3 0 2.18 2.18 0 0 1 0-3z' fill='white' /%3E%3C/svg%3E")`} !important;
  }

  &:last-child {
    margin-bottom: 0;
  }

  transition: background 1s ease;
`;

const SCCampaignItemInner = styled.div`
  padding: var(--${prizeDropCSSNS}-spacer-default);

  .${prizeDropCSSNS}-campaign-item-title {
    justify-content: flex-start;
    align-items: center;

    font-size: var(--${prizeDropCSSNS}-text-sm);

    @media screen and (min-width: 900px) {
      font-size: var(--${prizeDropCSSNS}-text-md);
    }
  }
`;

const SCCampaignItemText = styled.div`
  grid-area: 1 / 1;
`;

const SCCampaignBadge = styled.div`
  z-index: 5;

  align-self: flex-start;

  margin-right: calc(-1 * var(--${prizeDropCSSNS}-spacer-default));
  padding: 2px 10px 3px 13px;

  font-size: 11px;
  line-height: 1rem;
  text-shadow: 0 1px 2px #000;

  color: var(--${prizeDropCSSNS}-color-primary);
  background: var(--${prizeDropCSSNS}-badge-bg);

  border-radius: 4px 0 0 4px;

  will-change: transform;

  &:after {
    content: '';
    width: 100%;
    height: 10px;

    display: block;

    position: absolute;
    bottom: -10px;
    right: 0;
    z-index: 5;

    background: var(--${prizeDropCSSNS}-badge-bg);
    clip-path: polygon(0 0, 100% 0, 100% 35%, 0 0);

    border-bottom: 1px solid var(--egtd-przd-badge-bg);
  }
`;

export { SCCampaignItem, SCCampaignItemInner, SCCampaignItemText, SCCampaignBadge };
