import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormButton, FormControl } from 'components/shared/forms/components';
import { FieldValues } from 'components/shared/forms/formTypes';
import { Flex } from 'components/shared/layout';
import { Modal } from 'components/shared/Modal';
import { defaultOtpInput } from 'pages/auth/phoneConfirmation/utils/poneConfirmation.utils';
import { AccountThunks, resetMfaCheckCode, selectAccount } from 'pages/myAccount/tabs/account/slice/account.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { MyAccountTranslation, MyProfile } from 'utils/common/translationUtils/translationStrings';
import { ResendCode } from './components';
import { CodeError } from './components/CodeError';
import CountDownTimer from './components/CountDownTimer';
import { useCode } from './hooks/useCode';
import { useMFAType } from './hooks/useMFAType';
import { hideString } from './utils/twoFactorVerification.utils';
import useHandleModals from '../rootModal/hooks/useHandleModals';

const sendType = {
  email: MyAccountTranslation.MFA.email,
  sms: MyAccountTranslation.MFA.sms,
  phoneCall: MyAccountTranslation.MFA.phoneCall,
};

const TwoFactorVerification: React.FC<{
  onSubmit: (data: FieldValues, mfaCode?: string) => void;
  data: FieldValues;
}> = ({ onSubmit, data }) => {
  const mfaCoolOff = useAppSelector(selectAccount.mfaCoolOff);
  const isValidMfaCode = useAppSelector(selectAccount.isValidMfaCode);
  const isLoading = useAppSelector(selectAccount.isLoadingCheckCode);
  const isLoadingGenerateCode = useAppSelector(selectAccount.isLoadingGenerateCode);
  const field = defaultOtpInput({ inputMode: 'text', replacePattern: '-' });

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { closeModal } = useHandleModals();

  const formProps = useForm({ mode: 'onChange' });
  const { reset, handleSubmit } = formProps;

  const { channelText, mfaChannel } = useMFAType();
  const userMfaType = hideString({ text: channelText, type: mfaChannel || '' });

  const { hasError, disableWriteCode, isCoolOffExpired, setHasError, timerIsFinnish } = useCode({
    mfaCoolOff,
    isLoading,
    reset,
    isValidMfaCode,
  });

  const handleSendCode = useCallback(
    (fields) => {
      if (!hasError && fields.confirmationCode && !isLoading) {
        dispatch(AccountThunks.checkMfaCode(fields.confirmationCode)).then((action) => {
          if (action.payload === true) {
            onSubmit(data, action.meta.arg);
          }
        });
      }
    },
    [dispatch, onSubmit, data, hasError, isLoading],
  );

  const handleResendChannel = useCallback(() => {
    if (!isLoading && isCoolOffExpired && !isLoadingGenerateCode) {
      dispatch(AccountThunks.generateMfaCode()).then(() => {
        timerIsFinnish(false);
        reset();
      });
    }
  }, [dispatch, timerIsFinnish, reset, isLoading, isCoolOffExpired, isLoadingGenerateCode]);

  useEffect(() => {
    return () => {
      dispatch(resetMfaCheckCode());
    };
  }, [dispatch]);

  const onChangeCallback = useCallback(() => {
    hasError && setHasError(false);
  }, [hasError, setHasError]);

  const resendStyle =
    isLoading || !isCoolOffExpired || isLoadingGenerateCode ? { pointerEvents: 'none', opacity: '0.5' } : {};

  return (
    <Modal visible className="modal-tfa">
      <Modal.Close closeText={t(MyProfile.OffersTab.closeBtn)} closeAction={closeModal} />
      <Modal.Body padding="lg" className="text-center">
        <div className="mb-2">
          {t(MyAccountTranslation.MFA.text)} {t(sendType[mfaChannel || 'email'])}{' '}
          <b style={{ verticalAlign: 'text-top' }}>{userMfaType}</b>
        </div>

        {mfaCoolOff && (
          <CountDownTimer
            headingText={t(MyAccountTranslation.MFA.textValid)}
            counterText={t(MyAccountTranslation.MFA.time)}
            endDate={mfaCoolOff}
            timerIsFinnish={timerIsFinnish}
            timeZone="systemTime"
          />
        )}

        <FormProvider {...formProps}>
          <form onSubmit={handleSubmit(handleSendCode)}>
            <Flex.Center className="d-flex-col">
              <FormControl
                {...field}
                hasError={hasError}
                disabled={disableWriteCode}
                onChangeCallback={onChangeCallback}
              />
              {hasError && <CodeError text={t(MyAccountTranslation.MFA.wrongCode)} />}
              <FormButton
                className="mt-3"
                disabled={disableWriteCode || hasError}
                text={t(MyAccountTranslation.MFA.btn)}
              />
            </Flex.Center>
          </form>
        </FormProvider>
        <ResendCode
          style={resendStyle}
          handleResendChannel={handleResendChannel}
          text={t(MyAccountTranslation.MFA.notReceive)}
        />
      </Modal.Body>
    </Modal>
  );
};

export default TwoFactorVerification;
