import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledBetBehind } from './BetBehind.styled';

interface Props {
  betBehind: boolean;
}

const BetBehind: React.FC<Props> = ({ betBehind }) => {
  const { t } = useTranslation();

  return (
    <StyledBetBehind className="text-center blackjack-full-seats">
      <div className="blackjack-full-seats__text">{t(`LiveCasino.GameCellDataImage.fullTableText`)}</div>
      {betBehind && (
        <div className="blackjack-full-seats__text accent">{t(`LiveCasino.GameCellDataImage.betBehindText`)}</div>
      )}
    </StyledBetBehind>
  );
};

export default BetBehind;
