import { FooterUI } from 'components/shared/FooterUI';
import { Flex } from 'components/shared/layout';
import { useAppSelector } from 'store';
import config from 'utils/config';
import { selectNavigationMenu } from '../../navigationMenu/slice/navigationMenu.slice';

const BurgerSocial: React.FC = () => {
  const socialItems = useAppSelector(selectNavigationMenu.burgerMenuLayout02)?.breadcrumbMenuItem;
  const social = socialItems?.find((item) => item.type === 'socialmedia')?.socialMedia;

  const cdnPath = `${config.CDN_URL}`;

  const getSocialLink = (link: string) => {
    return `https://` + link;
  };

  return (
    <Flex.Center className="mx-auto mt-auto pt-3 pb-1">
      {social?.map((item, index) => {
        const iconSrc = `${cdnPath}/${item.icon ?? ''}`;

        return (
          <FooterUI.SocialIcon
            key={index + 'markets'}
            className="mx-2"
            iconSrc={iconSrc}
            alt={item.text}
            linkRoute={getSocialLink(item?.link) || ''}
            componentLocation="drawer"
          />
        );
      })}
    </Flex.Center>
  );
};

export default BurgerSocial;
