import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { DataLoader } from 'components/shared/loaders';
import NoDataMessage from 'components/shared/NoDataMessage';
import { PrizeDropTypes } from 'shared/common/features/PrizeDrop/types/prizeDrop.types';
import { isEmpty } from 'utils/common/helpersCommon';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import PrizeDropTable from './PrizeDropTableContainer';
import PrizeDropTableRow from './PrizeDropTableRow';
import { prizeDropCSSNS } from '../prizeDropUICSS.types';

const secondaryTabs: { name: string; type: 'rewardedPlayers' | 'personalRewards' }[] = [
  { name: Promotions.PrizeDrop.winnersSubTab, type: 'personalRewards' },
  { name: Promotions.PrizeDrop.myWinsSubTab, type: 'rewardedPlayers' },
];

type Props = {
  rewardedPlayers: PrizeDropTypes.PersonalRewards[];
  personalRewards: PrizeDropTypes.PersonalRewards[];
  campaignStatus?: PrizeDropTypes.StateStatus;
};

const PrizeDropTabsSecondary: React.FC<Props> = ({ campaignStatus = 'idle', rewardedPlayers, personalRewards }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation();

  const onSelectSecondaryTabIndex = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  if (campaignStatus === 'idle') return null;

  if (campaignStatus === 'pending') return <DataLoader />;

  if (isEmpty(rewardedPlayers)) return <NoDataMessage message={t(Promotions.PrizeDrop.norewardedPlayers)} />;

  return (
    <Tabs
      selectedIndex={tabIndex}
      onSelect={onSelectSecondaryTabIndex}
      className={`${prizeDropCSSNS}-tabs-secondary`}
      focusTabOnClick={false}
    >
      <TabList className={`${prizeDropCSSNS}-tabs-list-secondary`}>
        {secondaryTabs.map((tab) => (
          <Tab className={`${prizeDropCSSNS}-tab`} selectedClassName={`${prizeDropCSSNS}-tab--selected`} key={tab.type}>
            <>{t(tab.name)}</>
          </Tab>
        ))}
      </TabList>

      <TabPanel
        className={`${prizeDropCSSNS}-panel no-scrollbars`}
        selectedClassName={`${prizeDropCSSNS}-panel--selected`}
      >
        {isEmpty(rewardedPlayers) ? (
          <NoDataMessage message={t(Promotions.PrizeDrop.norewardedPlayers)} />
        ) : (
          <PrizeDropTable>
            <PrizeDropTableRow
              isHeader
              firstColText={t(Promotions.PrizeDrop.player)}
              secondColText={t(Promotions.PrizeDrop.win)}
            />

            {rewardedPlayers.map((reward, index) => (
              <PrizeDropTableRow
                key={`${reward.bonusName || ''}-${index}`}
                firstColText={reward.username || ''}
                secondColText={reward.bonusName || ''}
              />
            ))}
          </PrizeDropTable>
        )}
      </TabPanel>

      <TabPanel
        className={`${prizeDropCSSNS}-panel no-scrollbars`}
        selectedClassName={`${prizeDropCSSNS}-panel--selected`}
      >
        {isEmpty(personalRewards) ? (
          <NoDataMessage message={t(Promotions.PrizeDrop.nopersonalRewards)} />
        ) : (
          <PrizeDropTable>
            <PrizeDropTableRow
              isHeader
              firstColText={t(Promotions.PrizeDrop.player)}
              secondColText={t(Promotions.PrizeDrop.win)}
            />
            {personalRewards.map((reward, index) => (
              <PrizeDropTableRow
                key={`${reward.bonusName || ''}-${index}`}
                firstColText={reward.username || ''}
                secondColText={reward.bonusName || ''}
              />
            ))}
          </PrizeDropTable>
        )}
      </TabPanel>
    </Tabs>
  );
};

export default PrizeDropTabsSecondary;
