import { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { fitCover } from 'modules/casino/shared/utils/common/cdnConfig';
import { Breakpoints } from 'modules/casino/theme/Theme';
import config from 'utils/config';

const useJackpotCellBackground = ({
  backgroundUrl,
  jackpotWidgetCellRef,
}: {
  backgroundUrl: string;
  jackpotWidgetCellRef: React.RefObject<HTMLDivElement>;
}): string | undefined => {
  const isMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isMobile}px)` });
  const getBackground = useCallback(() => {
    const { clientWidth, clientHeight } = jackpotWidgetCellRef?.current || {};

    if (!clientWidth || !clientHeight) {
      return;
    }

    const quality = isMobile ? 2 : 1;
    const height = clientHeight * quality;
    const width = clientWidth * quality;

    return backgroundUrl?.includes('#') || backgroundUrl?.includes('rgba(')
      ? backgroundUrl
      : `url(${config.CDN_IMAGE_API}${fitCover(width, height, 'cover')}/${backgroundUrl})`;
  }, [isMobile]);

  return getBackground();
};

export default useJackpotCellBackground;
