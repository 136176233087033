import { TFunction } from 'i18next';
import userStorage from 'pages/auth/login/UserStorage';
import { getValue } from 'pages/myAccount/tabs/history/utils/history.utils';
import { isEmpty } from 'utils/common/helpersCommon';
import { MyAccountTranslation } from 'utils/common/translationUtils/translationStrings';
import { PeriodTypes, LimitTypes, MonthlyHistory } from '../types/monthlyHistory.types';

const convertDateToUserTz = (date: Date) => {
  const systemOffset = date.getTimezoneOffset();
  const userOffset = Number(userStorage.getUserTimeZone() ?? 0);
  const offsetDiffInMilliseconds = (systemOffset - userOffset) * 60 * 1000;

  return new Date(date.getTime() - offsetDiffInMilliseconds).getTime();
};

export const getTimePeriod = (month: number, year: number) => ({
  fromDate: convertDateToUserTz(new Date(year, month, 1, 0, 0, 0)),
  toDate: convertDateToUserTz(new Date(year, month + 1, 0, 23, 59, 59, 999)),
});

export const getPeriodValue = (periodType: PeriodTypes, t: TFunction<'translation', undefined>) => {
  switch (periodType) {
    case PeriodTypes.DAILY:
    case PeriodTypes.WEEKLY:
    case PeriodTypes.MONTHLY:
      return ` / ${t(MyAccountTranslation.MonthlyHistory[`period${periodType}`])}`;
    default:
      return null;
  }
};

export const getLimitValue = (limitValue: number, limitType: LimitTypes) => {
  switch (limitType) {
    case LimitTypes.BET:
    case LimitTypes.LOSS:
      return `${getValue(limitValue)}`;
    default:
      return `${limitValue}`;
  }
};

export const getDropdownTitle = (
  month: number | null,
  year: number | null,
  months: {
    value: number;
    text: string;
  }[],
  years: {
    value: number;
    text: string;
  }[],
  t: TFunction<'translation', undefined>,
) => {
  const titleMonth =
    typeof month === 'number' ? t(months[month].text) : t(MyAccountTranslation.MonthlyHistory.dropdownValueMonth);
  const selectedYear = years?.find((y) => y.value === year);
  const titleYear =
    typeof year === 'number' ? selectedYear?.text : t(MyAccountTranslation.MonthlyHistory.dropdownValueYear);
  return typeof month === 'number' &&
    typeof year === 'number' &&
    month === new Date().getMonth() &&
    year === new Date().getFullYear()
    ? `${titleMonth}, ${titleYear} (${new Date(year, month + 1, 0).getDate() - new Date().getDate()} ${t(
        MyAccountTranslation.MonthlyHistory.dropdownDaysLeft,
      )})`
    : typeof month === 'number' || typeof year === 'number'
      ? `${titleMonth}, ${titleYear}`
      : t(MyAccountTranslation.MonthlyHistory.dropdownPlaceholder);
};

export const normalizeResponse = (data: MonthlyHistory.SummaryItemApi[]) => {
  if (!isEmpty(data)) {
    return data?.reduce((acc, curr) => {
      // Aggregate player-level data
      acc.numberOfLogins = curr.totalLogins;
      acc.totalBet = curr.totalBet;
      acc.totalWin = curr.totalWin;
      acc.bonusAchievedAmount = curr.bonusAchievedAmount;
      acc.initialBalance = curr.initialBalance ?? 0;
      acc.endBalance = curr.netBalance ?? curr.endBalance ?? 0;
      acc.endBalanceStatus = curr.netBalanceStatus ?? curr.endBalanceStatus ?? undefined;

      // Aggregate deposits
      acc.deposits = acc.deposits || { total: 0, byProvider: [] };
      acc.deposits.total = curr.totalDepositAmount;
      if (curr?.depositAmountPerPaymentMethod !== 0) {
        acc.deposits.byProvider.push({ paymentMethod: curr.paymentMethod, amount: curr.depositAmountPerPaymentMethod });
      }

      // Aggregate withdrawals
      acc.withdraws = acc.withdraws || { total: 0, byProvider: [] };
      acc.withdraws.total = curr.totalWithdrawalAmount;
      if (curr.withdrawalAmountPerPaymentMethod !== 0) {
        acc.withdraws.byProvider.push({
          providerName: curr.paymentMethod,
          amount: curr.withdrawalAmountPerPaymentMethod,
        });
      }

      return acc;
    }, {} as MonthlyHistory.SummaryItem);
  }
  return null;
};
