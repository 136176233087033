import React from 'react';
import { Icon } from 'components/shared';
import userStorage from 'pages/auth/login/UserStorage';
import { useAppDispatch, useAppSelector } from 'store';
import { myProfileThunks, setBalanceVisibility, selectMyProfile } from '../slice/myProfile.slice';

const MyProfileBalanceToggler: React.FC = () => {
  const dispatch = useAppDispatch();
  const isBalanceVisible = useAppSelector(selectMyProfile.balanceVisibility);
  const refreshBalance = () => dispatch(myProfileThunks.fetchBalance());

  const toggleBalance = () => {
    userStorage.setBalanceVisibility(!isBalanceVisible);
    dispatch(setBalanceVisibility());
  };

  return (
    <>
      <Icon className="mpd-stat-item__btn" onClick={toggleBalance} icon={isBalanceVisible ? 'eye-off' : 'eye-on'} />
      {isBalanceVisible && <Icon className="mpd-stat-item__btn" onClick={refreshBalance} icon="refresh" />}
    </>
  );
};

export default MyProfileBalanceToggler;
