import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues } from 'components/shared/forms/formTypes';
import { useCountDown } from 'hooks/common/useCountDown';
import { ResendCode } from 'shared/common/features/twoFactorVerification/components';
import { useAppDispatch, useAppSelector } from 'store';
import { MyAccountTranslation } from 'utils/common/translationUtils/translationStrings';
import { PhoneConfirmationThunks, selectPhoneConfirmation } from '../slice/phoneConfirmation.slice';
import { PhoneConfirmationTypes } from '../types/phoneConfirmation.types';

type Props = {
  data?: FieldValues;
  timerIsFinnish: (arg: boolean) => void;
  type?: PhoneConfirmationTypes.VerificationType;
};

const PhoneResendCode: React.FC<Props> = ({ data, timerIsFinnish, type }) => {
  const newCodeCoolOff = useAppSelector(selectPhoneConfirmation.newCodeCoolOff);
  const isLoadingResendCode = useAppSelector(selectPhoneConfirmation.isLoadingResendCode);
  const playerId = useAppSelector(selectPhoneConfirmation.playerId);
  const phoneNumber = useAppSelector(selectPhoneConfirmation.phoneNumber);
  const channel = useAppSelector(selectPhoneConfirmation.channel);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const timeZone = type === 'mfa_extended' ? 'systemTime' : 'localTime';
  const { isExpired } = useCountDown(newCodeCoolOff, timeZone);
  const shouldResendCode = isExpired && !isLoadingResendCode;

  const handleResendChannel = useCallback(() => {
    if (shouldResendCode) {
      dispatch(
        PhoneConfirmationThunks.generatePhoneVerificationCode({
          isNewCodeRequest: true,
          playerId,
          phoneNumber,
          type,
          data,
          channel,
        }),
      ).then(() => timerIsFinnish(false));
    }
  }, [dispatch, timerIsFinnish, shouldResendCode, type, playerId, phoneNumber, data, channel]);

  return (
    <ResendCode
      style={shouldResendCode ? {} : { pointerEvents: 'none', opacity: '0.5' }}
      handleResendChannel={handleResendChannel}
      text={t(MyAccountTranslation.PhoneConfirmation.notReceive)}
    />
  );
};

export default PhoneResendCode;
