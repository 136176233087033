import { memo } from 'react';

import Close from './Close';
import Link from './Link';
import Search from './Search';
import Section from './Section';
import Sorter from './Sorter';
import Tab from './Tab';

export const DrawerUI = {
  Search: memo(Search),
  Sorter: memo(Sorter),
  Tab: memo(Tab),
  Section: Section,
  Link: memo(Link),
  Close: Close,
};
