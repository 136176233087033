import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/rootReducer';
import { CreateThunk, createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError, isEmpty, WindowConfigInterface } from 'utils/common/helpersCommon';
import config from 'utils/config';

export enum ErrorHandlerTypes {
  ERROR_BOUNDARY = 'ERROR_BOUNDARY',
  LOGGER_MIDDLEWARE = 'LOGGER_MIDDLEWARE',
  WRONG_CONFIGURATION = 'WRONG_CONFIGURATION',
}

export type ErrorHandlerProps = {
  errorData: any;
  handleFrom: ErrorHandlerTypes;
};

type User = {
  id: string;
  username: string;
};

type BrowserInfo = {
  userAgent: string;
  lang: string;
  cookieEnabled: boolean;
};

type ErrorData = {
  user: User | string;
  timestamp: Date;
  url: string;
  browserInfo: BrowserInfo;
  windowConfig: WindowConfigInterface;
  errorData: any;
  handleFrom?: ErrorHandlerTypes;
};

type ErrorLoggerThunk = {
  logErroData: CreateThunk<{ data: ErrorData }, string | null>;
};

export const errorLoggerThunk: ErrorLoggerThunk = {
  logErroData: createAbortThunk('errorLogger/logErroData', async ({ data }) => {
    if (config.ERROR_LOGGER_URL) {
      try {
        const response = await axiosInstance.post(config.ERROR_LOGGER_URL, data);
        return response.data;
      } catch (err) {
        return getError.responseDataMessage(err);
      }
    }
  }),
};

const { logErroData } = errorLoggerThunk;

const initialState = {
  errorData: null,
};

const errorLogger = createSlice({
  name: 'errorLogger',
  initialState,
  reducers: {
    saveErrorInfo(state, action) {
      state.errorData = action.payload;
    },
  },
});

export const { saveErrorInfo } = errorLogger.actions;

export default errorLogger.reducer;

export const setErrorData =
  ({ errorData, handleFrom }: ErrorHandlerProps) =>
  async (dispatch, getState: () => RootState) => {
    const user = getState().myProfile.user;

    const data: ErrorData = {
      user: user
        ? {
            id: user?.id,
            username: user?.username,
          }
        : 'Guest',
      timestamp: new Date(),
      url: window.location?.href,
      browserInfo: {
        userAgent: navigator?.userAgent,
        lang: navigator?.language,
        cookieEnabled: navigator?.cookieEnabled,
      },
      windowConfig: window?.config,
      errorData,
      handleFrom,
    };

    if (!isEmpty(errorData.payload)) dispatch(logErroData({ data }));
  };
