import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { selectNavigationMenu } from 'shared/common/features/navigationMenu/slice/navigationMenu.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { setActiveSearchType } from '../../common/slice/navSearch.slice';

const searchMap = {
  sport: 'sports/search',
  casino: 'casino/search',
};

export const useActiveSearchType = () => {
  const verticals = useAppSelector(selectNavigationMenu.verticals);

  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isEmpty(verticals)) {
      const vertical = verticals.find((v) => {
        //TODO remove, when BE returns new path
        const routeToUse = v.route;
        // const stringToMatch = v.uiVerticalType === 'casino' ? 'casino' : routeToUse || '';
        const stringToMatch = routeToUse || '';

        return v.uiVerticalType && pathname.match(stringToMatch);
      });
      if (vertical?.uiVerticalType && searchMap[vertical.uiVerticalType]) {
        dispatch(setActiveSearchType(searchMap[vertical.uiVerticalType]));
      }
    }
  }, [dispatch, pathname, verticals]);
};
