import { useCallback } from 'react';
import { useNavigateTo } from 'hooks/navigation';
import { useAppDispatch } from 'store';
import { navigateToIntegration } from '../../integrations/utils/integrations.utils';
import { setDrawer } from '../slice/drawer.slice';
import { SportsType } from '../types/drawer.types';

const useHandleDrawerDefaultClick = ({ sportType }: { sportType?: SportsType }) => {
  const dispatch = useAppDispatch();
  const navigateTo = useNavigateTo();

  const handleClick = useCallback(() => {
    dispatch(setDrawer(false));
    switch (sportType) {
      case SportsType.LiveSport:
        navigateToIntegration({ page: 'sportsInPlay' });
        navigateTo.sportsInPlay({});
        return;
      case SportsType.Sport:
        navigateToIntegration({ page: 'sportsLobby' });
        navigateTo.sportsLobby({});

        return;
      case SportsType.ESports:
        navigateToIntegration({ page: 'eSportsLobby' });
        navigateTo.eSportsLobby({});
        return;
      default:
        return;
    }
  }, [dispatch, sportType, navigateTo]);

  return { handleClick };
};

export default useHandleDrawerDefaultClick;
