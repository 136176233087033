export const SkywindJackpotsTranslations = {
  mustWinBefore: 'Jackpots.Text.mustWinBefore',
  remainingTime: 'Jackpots.Text.remainingTime',
  jackpotWon: 'Jackpots.Text.jackpotWon',
};

export const DaysText = {
  day: 'Jackpots.Text.oneDay',
  days: 'Jackpots.Text.moreDays',
};

export const SkywindJackpots = {
  AmountLimit: 'AmountLimit',
  Daily: 'Daily',
  Hourly: 'Hourly',
};

export const SKYWIND_HOURLY_COUNTDOWN_FORMAT = 'All.HH:mm:ss';
export const SKYWIND_DAILY_COUNTDOWN_FORMAT = 'Days HH:mm:ss';
