import { useEffect } from 'react';
import { getBusinessUnit, getUniqueUuidv4, isCashier } from 'utils/common/helpersCommon';

const useSetMidHeader = () => {
  useEffect(() => {
    if (getBusinessUnit() !== 'MBRO' && !isCashier()) {
      const existingValue = localStorage.getItem('mid');
      if (existingValue) {
        localStorage.removeItem('mid');
      }

      return;
    }
    const existingValue = localStorage.getItem('mid');
    if (existingValue) return;

    const value = getUniqueUuidv4();
    localStorage.setItem('mid', value);
  }, []);
};

export default useSetMidHeader;
