import React, { useEffect } from 'react';
import clsx from 'clsx';
import { CasinoWidgetsTypes } from 'modules/casino/modules/container/widget/types/casinoWidgetsTypes';
import { Flex } from 'modules/casino/shared/components/layout';
import JackpotHighestLevel from 'modules/casino/shared/features/jackpots/components/JackpotHighestLevel';
import JackpotLogo from 'modules/casino/shared/features/jackpots/components/JackpotLogo';
import useCategoryJackpot from 'modules/casino/shared/hooks/useCategoryJackpot';
import { useSetRefsLoaded } from 'modules/casino/shared/hooks/useSetRefsLoaded';
import { JackpotsWidgetCellButton, JackpotsWidgetCellWrapper } from '../components';

interface TextProps {
  jackpotItem: CasinoWidgetsTypes.WidgetJackpot;
  isLast: boolean;
  setItemsLoaded: () => void;
}

const JackpotsWidgetRibbonCell: React.FC<TextProps> = ({ jackpotItem, isLast, setItemsLoaded }) => {
  const { refLoaded, setRefs, ref } = useSetRefsLoaded();
  const { jackpotDetails, jackpotId } = useCategoryJackpot(jackpotItem.jackpotId);

  useEffect(() => {
    if (refLoaded && isLast) setItemsLoaded();
  }, [refLoaded]);

  if (!jackpotDetails) {
    return <></>;
  }

  const logoUrl = jackpotDetails?.widgetLogoLocation;
  const jackpotCellClass = clsx('jackpot-widget-cell', jackpotDetails?.template || '');

  return (
    <div className={jackpotCellClass} ref={setRefs}>
      {refLoaded && (
        <JackpotsWidgetCellWrapper
          jackpotWidgetCellRef={ref}
          backgroundUrl={''}
          classNames={'jackpot-widget-cell--wrapper'}
        >
          <JackpotLogo logoUrl={logoUrl} />
          <Flex.AC className="jackpot-widget-cell-inner">
            <JackpotHighestLevel jackpotId={jackpotId} />
            <JackpotsWidgetCellButton link={jackpotItem?.hyperlink} buttonSize="lg" />
          </Flex.AC>
        </JackpotsWidgetCellWrapper>
      )}
    </div>
  );
};

export default JackpotsWidgetRibbonCell;
