import React from 'react';
import clsx from 'clsx';
import ScrollContainer, { arrowStyleTypes, handleScrollMoveWith } from 'components/shared/ScrollContainer';
import { cssGamificationNS } from 'utils/common/types/css.types';

const scrollItemWidth = 227;
const itemsToScroll = 1.6;
const { moveWith } = handleScrollMoveWith({ itemsToScroll, itemWidth: scrollItemWidth });

interface GridProps {
  className?: string;
  type: 'horizontal' | 'vertical';
  size?: 'small' | 'normal' | 'big' | 'sidebar' | 'my-profile';
  heightSize?: 'large' | '';
  children: React.ReactNode;
  triggerMoveWidth?: boolean;
  arrowType?: arrowStyleTypes;
}

export const CaptainUpGrid: React.FC<GridProps> = ({
  children,
  className,
  size = 'big',
  type,
  triggerMoveWidth,
  arrowType = arrowStyleTypes.Casino,
}) => {
  const gridClass = clsx(
    `${cssGamificationNS}-grid ${cssGamificationNS}-grid-${type}`,
    `${cssGamificationNS}-grid-${type}--${size}`,
    className,
  );
  return (
    <div style={{ overflow: 'hidden' }} className="no-scrollbars">
      <ScrollContainer
        classNames={gridClass}
        regularDesktopCellWidth={scrollItemWidth}
        moveWith={triggerMoveWidth ? scrollItemWidth : moveWith}
        typeArrow={arrowType}
      >
        {children}
      </ScrollContainer>
    </div>
  );
};
