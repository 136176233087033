import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { Modal } from 'components/shared/Modal';
import { ModalUI } from 'components/shared/ModalUI';
import { UI } from 'components/shared/UI';
import ParseHTML from 'shared/common/ParseHTML';
import { Desktop, DesktopDown } from 'theme/Theme';
import { cdnConfig, fitCover } from 'utils/common/cdnConfig';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import config from 'utils/config';
import { useNotificationHandlers } from './hooks/useNotificationHandlers';
import { NotificationTypes } from './notificationTypes';
import AnimatedCounter from '../../../../animatedCounter/AnimatedCounter';

const quality = 2;
// Mobile Sizes
const mobileWidth = 303 * quality;
const mobileHeight = 154 * quality;
// Desktop Sizes
const desktopWidth = 472 * quality;
const desktopHeight = 240 * quality;

interface Props {
  notification: NotificationTypes.PopUpNotification;
}

const PopUpNotificationModal: React.FC<Props> = ({ notification }): JSX.Element => {
  const { t } = useTranslation();
  const { handleAcceptButtonClick, handleCloseButtonClick } = useNotificationHandlers(notification);

  const renderBanner = (type) => {
    if (!notification?.style?.bannerResourceLocation) return;
    return (
      <div className="mb-3">
        <img
          className="modal-terms__banner"
          src={`${config.CDN_IMAGE_API}${fitCover(
            type === 'desktop' ? desktopWidth : mobileWidth,
            type === 'desktop' ? desktopHeight : mobileHeight,
          )}${cdnConfig.NOTIFICATIONS_MODULE}/${notification?.style?.bannerResourceLocation}`}
        />
      </div>
    );
  };

  return (
    <Modal visible className="modal-terms">
      <Modal.Close closeText={t(MyProfile.OffersTab.closeBtn)} closeAction={handleCloseButtonClick} />

      <Modal.Body className="modal-terms-scrollbars">
        <Flex.AC className="mb-2">
          {notification?.style?.iconResourceLocation && (
            <UI.SVGCDN className="modal-terms__title-icon" icon={notification?.style?.iconResourceLocation} />
          )}
          <ModalUI.Title hasAccent text={notification.header} />
        </Flex.AC>
        <ModalUI.Title className="mt-2 mb-3" text={notification.subheader} />
        <DesktopDown>{renderBanner('mobile')}</DesktopDown>
        <Desktop>{renderBanner('desktop')}</Desktop>

        {notification?.style?.counterTime && (
          <AnimatedCounter
            expire={notification?.style?.counterExpire}
            text={notification?.style?.counterLabel}
            date={notification?.style?.counterTime}
          />
        )}

        <div className="modal-terms__text">
          <ParseHTML html={notification?.message || ''} options={{ trim: true }} />
        </div>

        {notification.button?.buttonUrl && (
          <Flex.Center className="mt-4 mb-2">
            <UI.Button size="lg" onClick={handleAcceptButtonClick} text={notification.button?.buttonText} />
          </Flex.Center>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PopUpNotificationModal;
