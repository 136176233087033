import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/shared';

/** BetSeletionUI CustomBet Title */
const CustomBetTitle: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Text
      className="mr-1 text-truncate"
      text={t('BetBuilder.SelectionHeader.customBetTitle')}
      title={t('BetBuilder.SelectionHeader.customBetTitle')}
    />
  );
};

export default CustomBetTitle;
