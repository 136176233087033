import React, { useEffect, useRef } from 'react';
type Props = {
  handleOnScroll: () => void;
  isInitialPage: boolean;
  showLoadMoreAfterLoadLastImage: boolean;
};
const LoadMoreOnScroll: React.FC<Props> = ({ handleOnScroll, isInitialPage, showLoadMoreAfterLoadLastImage }) => {
  const observerTarget = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && showLoadMoreAfterLoadLastImage) {
          !isInitialPage && handleOnScroll();
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, isInitialPage, showLoadMoreAfterLoadLastImage, handleOnScroll]);

  return <div id="loader" ref={observerTarget}></div>;
};

export default LoadMoreOnScroll;
