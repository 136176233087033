import { useState } from 'react';

export const useCashierModalState = () => {
  const initialTime = 5; //seconds when action button is available to press
  const [activeButtons, setActiveButtons] = useState<number[] | null>(null); // pay ticket, cancel, cashout, cashout and pay buttons
  const [seconds, setSeconds] = useState(initialTime);
  const [clickCount, setClickCount] = useState(0);
  const [actionButtonClicked, setActionButtonClicked] = useState<number[] | null>(null);
  const [inputValueUCN, setInputValueUCN] = useState('');

  return {
    initialTime,
    activeButtons,
    setActiveButtons,
    seconds,
    setSeconds,
    clickCount,
    setClickCount,
    actionButtonClicked,
    setActionButtonClicked,
    inputValueUCN,
    setInputValueUCN,
  };
};
