import { useEffect } from 'react';
import debounce from 'lodash/debounce';

type Types = {
  element: React.RefObject<HTMLElement | HTMLDivElement>;
  minSize?: number;
  maxSize?: number;
  step?: number;
  unit?: string;
  update?: boolean;
  disableParent?: boolean;
};

const isOverflown = ({ clientHeight, scrollHeight, scrollWidth, clientWidth }) => {
  return scrollHeight > clientHeight || clientWidth < scrollWidth;
};
export const useAutoFontSize = ({
  element,
  minSize = 10,
  maxSize = 100,
  step = 1,
  unit = 'px',
  update,
  disableParent,
}: Types) => {
  useEffect(() => {
    const triggerFontSizer = (updateOnResize) => {
      if (element && element.current) {
        const el = element.current;
        let i = minSize;
        let overflow = false;
        const parent = el.parentElement;

        if ((!el.style.fontSize || updateOnResize) && parent) {
          el.style.fontSize = '';
          // el.style.lineHeight = '';
          while (!overflow && i < maxSize) {
            el.style.fontSize = `${i}${unit}`;
            el.style.lineHeight = `${i + 2}${unit}`;
            overflow = parent || disableParent ? isOverflown(disableParent ? el : parent) : true;

            if (!overflow) {
              i += step;
            } else {
              const size = i - step;
              el.style.lineHeight = `${size + 2}${unit}`;
              el.style.fontSize = `${size}${unit}`;
            }
          }
        }
      }
    };

    triggerFontSizer(update);
    const triggerDebounceFontSizer = debounce(() => {
      triggerFontSizer(true);
    }, 550);

    if (element && element.current) {
      window.addEventListener('resize', triggerDebounceFontSizer);
      return () => window.removeEventListener('resize', triggerDebounceFontSizer);
    }
  }, [update, element]);
};
