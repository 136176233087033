import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { UI } from 'components/shared/UI';
import FreeSpinCasinoCategory from 'modules/casino/modules/container/cellsContainer/containers/external/FreeSpinCasinoCategory';
import InGameFreeSpinCasinoCategory from 'modules/casino/modules/container/cellsContainer/containers/external/InGameCategories/InGameFreeSpinCasinoCategory';
import { casinoLaunchSelectors } from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import { BonusLogUI } from 'pages/myAccount/tabs/history/components/BonusLogUI';
import TextRow from 'pages/myAccount/tabs/history/components/BonusLogUI/TextRow';
import AmountCurrency from 'shared/common/features/myProfile/components/AmountCurrency';
import { fetchTerms } from 'shared/common/features/myProfile/sections/tabs/offersTab/slice/offersTab.slice';
import {
  singleBonusOffersProgressBarTitle,
  singleBonusOffersTableTitle,
} from 'shared/common/features/myProfile/utils/myProfile.utils';
import { useAppSelector, useAppDispatch } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import usePromotions from './hooks/usePromotions';
import { Bonus, BonusButtonAction, BonusStatusID, BonusTypes } from '../types/offersTab.types';

type Props = { bonus: Bonus; disableHeader?: boolean };

export const OffersSingleBonus: React.FC<Props> = ({ bonus, disableHeader }) => {
  const launchInProcess = useAppSelector(casinoLaunchSelectors.launchInProcess);
  const bonusHasAction =
    bonus.buttons && bonus.buttons.some((button) => button.buttonActionType !== BonusButtonAction.None);

  const { status, error, buttonClickHandler, elRef } = usePromotions(bonus);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const fetchBonusTerm = () => dispatch(fetchTerms({ bonusId: `${bonus.id}`, id: bonus.termsAndConditionsId }));

  return (
    <div className="mpd-cta-item" ref={elRef}>
      <BonusLogUI.Header
        componentLocation="myProfileDropdown"
        title={!disableHeader ? bonus?.title : ''}
        banner={bonus.bannerResource}
        description={bonus.content}
        activationText={t(MyProfile.OffersTab.activationText)}
        activationValue={bonus?.bonusActivatedDate || ''}
        expirationText={t(MyProfile.OffersTab.expirationText)}
        expirationValue={bonus?.bonusExpirationDate}
        // icon={bonus?.resourceName}
      />

      {bonus?.bonusCompletion && (
        <>
          <BonusLogUI.ProgressBar
            componentLocation="myProfileDropdown"
            variant="primary"
            completedPct={bonus.bonusCompletion.totalCompletionPercentage}
            targetText={t(singleBonusOffersProgressBarTitle(bonus.bonusType))}
          >
            <AmountCurrency amount={bonus.bonusCompletion.givenBonusAmount} />
          </BonusLogUI.ProgressBar>
          <BonusLogUI.Table
            componentLocation="myProfileDropdown"
            showTableHeader={!isEmpty(bonus.bonusCompletion.verticals)}
            headerText={t(singleBonusOffersTableTitle(bonus.bonusType))}
            headerTextTail={t(MyProfile.OffersTab.byProduct)}
          >
            {bonus?.bonusCompletion?.verticals?.map((vertical) => (
              <BonusLogUI.TableRow
                key={vertical.vertical}
                keyText={vertical.vertical}
                value={<AmountCurrency amount={vertical.leftToWagerAmount} />}
              />
            ))}
          </BonusLogUI.Table>
        </>
      )}
      {bonus?.freeSpinInformation && bonus.bonusType === BonusTypes.FREE_SPIN_WAGERING && (
        <div className="bonus-text--mpd pt-2">
          <TextRow
            className="pb-1"
            leftColText={t(MyProfile.OffersTab.wonFreeSpins)}
            leftColValue={bonus?.freeSpinInformation?.freeSpinCount}
            showRightCol={false}
          />
          <TextRow
            className="pb-1"
            leftColText={t(MyProfile.OffersTab.playedFreeSpins)}
            leftColValue={bonus?.freeSpinInformation?.freeSpinCompletedCount}
            showRightCol={false}
          />
          {bonus?.freeSpinInformation?.freeSpinAchieveAmount && (
            <TextRow
              className="pb-1"
              leftColText={t(MyProfile.OffersTab.wonFromFreeSpins)}
              leftColValue={bonus?.freeSpinInformation?.freeSpinAchieveAmount}
              showRightCol={false}
            />
          )}
        </div>
      )}
      {bonus?.depositRollover && bonus.bonusType === BonusTypes.DEPOSIT_ROLLOVER && (
        <>
          <BonusLogUI.ProgressBar
            componentLocation="myProfileDropdown"
            variant="primary"
            completedPct={bonus?.depositRollover?.totalCompletionPercentage}
            targetText={t(MyProfile.OffersTab.depositForRollover)}
          >
            <AmountCurrency amount={bonus?.depositRollover?.totalRolloverAmount} />
          </BonusLogUI.ProgressBar>
          {(bonus.depositRollover.rolloverAmountLeft || bonus.depositRollover.rolloverAmountLeft === 0) && (
            <div className="bonus-text--mpd pt-2">
              <TextRow className="pb-1" leftColText={t(MyProfile.OffersTab.depositRolloverLeft)} showRightCol={false}>
                <AmountCurrency amount={bonus?.depositRollover?.rolloverAmountLeft} />
              </TextRow>
            </div>
          )}
        </>
      )}
      {bonus.statusId === BonusStatusID.Activated &&
        bonus.bonusType === BonusTypes.WAGERING &&
        bonus?.isRequiredDepositRollover && (
          <Flex.Col className="mpd-cta-item__section">
            <span className="font-weight-bold">{t(MyProfile.OffersTab.depositRolloverLeft)}:</span>
            <AmountCurrency amount={bonus.depositRolloverLeft} />
          </Flex.Col>
        )}
      {bonus.subContent && <p className="mpd-cta-item__section">{bonus.subContent}</p>}
      <div className="mpd-cta-item__section">
        <span onClick={fetchBonusTerm} className="d-block mpd-cta-item__link">
          {t(MyProfile.OffersTab.termsAndConditionsApply)}
        </span>
      </div>
      {bonusHasAction && (
        <div className="mpd-cta-item__section">
          {status === 'failure' && error ? (
            <p>{error}</p>
          ) : (
            <Flex style={{ gap: '10px' }}>
              {bonus.buttons.map(
                (button, index) =>
                  button.buttonActionType !== BonusButtonAction.None && (
                    <UI.Button
                      key={index}
                      class={'f-basis-100'}
                      size="md"
                      variant={button.buttonActionType === BonusButtonAction.Cancel ? 'secondary' : undefined}
                      isDisabled={status === 'pending'}
                      onClick={buttonClickHandler(button)}
                      text={button.buttonText ? button.buttonText : '\u00A0'}
                    />
                  ),
              )}
            </Flex>
          )}
        </div>
      )}
      {/* REMOVE  freeSpinGamesIds After release 2.43.0 */}
      {!isEmpty(bonus.freeSpinGamesIds) &&
        isEmpty(bonus.bonusOfferCasinoGamesIds) &&
        (launchInProcess ? (
          <FreeSpinCasinoCategory bonusId={bonus.id + '-old'} freeSpinGamesIds={bonus.freeSpinGamesIds} />
        ) : (
          <FreeSpinCasinoCategory bonusId={bonus.id + '-old'} freeSpinGamesIds={bonus.freeSpinGamesIds} />
        ))}
      {!isEmpty(bonus.bonusOfferCasinoGamesIds) &&
        (launchInProcess ? (
          <InGameFreeSpinCasinoCategory bonusId={bonus.id} freeSpinGamesIds={bonus.bonusOfferCasinoGamesIds} />
        ) : (
          <FreeSpinCasinoCategory bonusId={bonus.id} freeSpinGamesIds={bonus.bonusOfferCasinoGamesIds} />
        ))}
    </div>
  );
};
