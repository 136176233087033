export enum CasinoLobbyType {
  Casino = 'CASINO',
  LiveCasino = 'LOBBY_LIVE',
  Default = 'DEFAULT_LOBBY',
  LiveGames = 'LIVE_GAMES',
}

export enum GameType {
  SLOT_GAME = 'SLOT_GAME',
  LIVE_CASINO = 'LIVE_CASINO',
  VIRTUAL_RNG = 'VIRTUAL_RNG',
  ROULETTE = 'ROULETTE',
  BLACKJACK = 'BLACKJACK',
  BACCARAT = 'BACCARAT',
  SHOW_PROGRAM = 'SHOW_PROGRAM',
  POKER = 'POKER',
  RNG_TABLE_GAME = 'RNG_TABLE_GAME',
  MINI_SLOT_GAME = 'RNG_SLOT_GAME',
}

export enum ProviderLaunchAgents {
  mobile = 'mobile',
  desktop = 'desktop',
  window_desktop = 'window_desktop',
}

export enum GridLayouts {
  LAYOUT_1 = 'LAYOUT_1',
  LAYOUT_2 = 'LAYOUT_2',
  LAYOUT_3 = 'LAYOUT_3',
  LAYOUT_4 = 'LAYOUT_4', // 1 row / SLIDER / regular ot vertical
  LAYOUT_5 = 'LAYOUT_5', // 1 row / SLIDER / vertical only
}
