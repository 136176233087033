import React, { memo, ReactNode, RefObject } from 'react';
import sortBy from 'lodash/sortBy';
import { ViewportList } from 'react-viewport-list';
import { FavouritesTypes } from 'shared/common/features/drawer/components/favourites/types/favourites.types';
import { selectFavouritesByType } from 'shared/common/features/drawer/components/favourites/utils/favourites.utils';
import { useAppSelector } from 'store';

interface Props<T> {
  favouriteType: FavouritesTypes.FavouritesSection;
  items: T[];
  renderItem: (item: T, index: number) => ReactNode;
  isAnimated?: boolean;
  favouriteParentId?: number;
  containerRef?: RefObject<HTMLDivElement>;
}

export const spring = {
  type: 'spring',
  damping: 25,
  stiffness: 150,
};

const FavouritesContainer = <T extends { id: number }>({
  favouriteType,
  items,
  renderItem,
  isAnimated,
  favouriteParentId,
  containerRef,
}: Props<T>) => {
  const favourites = useAppSelector(selectFavouritesByType(favouriteType, favouriteParentId));
  if (!items) return null;

  const sortedElements = sortBy(
    items?.map((item) => (favourites?.includes(item.id) ? { ...item, isFavourite: 1 } : item)),
    'isFavourite',
  );

  return isAnimated ? (
    <>
      {sortedElements?.map((item, index) => (
        <div key={item.id} className="drawer-link-wrapper">
          {renderItem(item, index)}
        </div>
      ))}
    </>
  ) : containerRef ? (
    <ViewportList viewportRef={containerRef} items={sortedElements} itemSize={90} overscan={10} initialOffset={400}>
      {renderItem}
    </ViewportList>
  ) : (
    <>{sortedElements?.map((item, index) => renderItem(item, index))}</>
  );
};

// memoized component doesn`t recognize generic types well thus casting it to the type of FavouritesContainer
export default memo(FavouritesContainer) as typeof FavouritesContainer;
