import React from 'react';
import { Modal } from 'components/shared/Modal';
import PrizeDropWinVideoView from 'components/shared/prizeDrop/Preview/PrizeDropWinVideoView';
import { WsNotificationsTypes } from '../../myProfile/sections/tabs/notificationsTab/types/wsNotifications.types';

type Props = { activeNotification: WsNotificationsTypes.Notification };

const PrizeDropWinVideoModal: React.FC<Props> = ({ activeNotification }) => {
  return (
    <Modal visible dialogClassName="modal-dialog-centered">
      <PrizeDropWinVideoView activeNotification={activeNotification} />
    </Modal>
  );
};

export default PrizeDropWinVideoModal;
