import styled from 'styled-components';
import { Breakpoints } from 'theme/Theme';

export const Wrapper = styled.div.attrs({
  className: 'd-flex-col-ac bg-cover',
})`
  flex: 1;

  margin-top: 40px;
  margin-bottom: 40px;
  @media (min-width: ${Breakpoints.isDesktop}px) {
    margin-top: 35px;
    margin-bottom: 30px;
  }
  @media (orientation: portrait) {
    width: 80.46vw;
    height: 80.46vw;
  }
  @media (orientation: landscape) {
    width: 64.46vw;
    height: 64.46vw;
  }

  @media (min-width: 375px) {
    width: clamp(21.75rem, 16.234rem + 18.58vw, 28.125rem);
    height: clamp(21.75rem, 16.234rem + 18.58vw, 28.125rem);
  }
  transition:
    margin 0.15s,
    width 0.15s,
    height 0.15s;
`;
