import React from 'react';
import ExternalCasinoCategoryWrapper from './ExternalCasinoCategoryWrapper';
import useShowSingleCategory from '../../../../../../shared/hooks/useShowSingleCategory';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from '../../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { CasinoGamesRender } from '../../../cellls';
import { useCasinoCategoryFetch } from '../../inner/hooks/useCasinoCategoryFetch';

export type CategoryDetailsType = { gameIds?: any; googleTagEvent?: string; title?: string; color?: string };

interface Props {
  subType: CategorySubTypes;
  cacheKey: string;
  categoryId?: string;
  categoryDetails?: CategoryDetailsType;
}
export const ExternalCasinoCategory: React.FC<Props> = ({ categoryId, subType, cacheKey, categoryDetails }) => {
  const categoryIdKey = `${categoryId ?? subType}_${cacheKey}`;
  const { queryType, isPublic, gridLoadingBehavior } = useCasinoCategoryFetch({
    categoryId,
    subType,
    categoryIdKey,
    skipAbortFetch: true,
  });

  const { showSingleCategory } = useShowSingleCategory({
    isPublic,
  });
  if (!queryType || !showSingleCategory) return;
  const categoryLayoutType =
    subType === CategorySubTypes.IN_GAME_FAVOURITES
      ? CategoryLayoutTypes.FAVOURITES_CATEGORY
      : CategoryLayoutTypes.EXTERNAL_CATEGORY;

  return (
    <ExternalCasinoCategoryWrapper subType={subType} categoryIdKey={categoryIdKey} categoryDetails={categoryDetails}>
      <CasinoGamesRender
        categoryId={categoryIdKey}
        subType={subType}
        categoryLayoutType={categoryLayoutType}
        useLoadAnimation={false}
        params={{
          className: `${subType.toLocaleLowerCase()}_external_category`,
          additionalParams: categoryDetails?.gameIds,
          gridLoadingBehavior,
        }}
      />
    </ExternalCasinoCategoryWrapper>
  );
};

export default ExternalCasinoCategory;
