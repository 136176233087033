import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { ModalUI } from 'components/shared/ModalUI';
import { RealityCheckModal } from 'utils/common/translationUtils/translationStrings';

const SessionInfoText: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex.Col>
      <ModalUI.Title
        hasAccent
        className={'reality-check-session-info__title text-left'}
        text={t(RealityCheckModal.Session.infoTitle)}
      />
      <div className={'text-left mt-2 mb-2'}>{t(RealityCheckModal.Session.infoText)}</div>
    </Flex.Col>
  );
};

export default SessionInfoText;
