import React from 'react';
import { useTranslation } from 'react-i18next';
import NoDataMessage from 'components/shared/NoDataMessage';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import TournamentsLeaderBoardAwards from './components/TournamentsLeaderBoardAwards';
import TournamentsLeaderBoardRanking from './components/TournamentsLeaderBoardRanking';
import TournamentsLeaderBoardTimeCounter from './components/TournamentsLeaderBoardTimeCounter';
import { TournamentsButton } from './types/tournamentsButton.types';
import { tlbCSSNS } from './types/tournamentsUI.types';
import { GameLaunchTranslationKeys } from '../../../gameLaunch/casinoLaunchConstants';

type Props = {
  data: TournamentsButton.Data;
};

const TournamentsPopover: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    data && (
      <div className={`${tlbCSSNS}__wrapper`}>
        {!data?.length && <NoDataMessage message={t(GameLaunchTranslationKeys.noTournamentsPopoverText)} />}
        {data.map((item, i) => {
          const isActive = Date.now() > item.startDate;
          return (
            <div className={`${tlbCSSNS}__tournament`} key={`${item.id}-${i}`}>
              <div className={`${tlbCSSNS}__tournament--title`}>{item.name}</div>
              <TournamentsLeaderBoardTimeCounter
                date={!isActive ? item.startDate : item.endDate}
                text={!isActive ? t(Promotions.Tournaments.startsInText) : t(Promotions.Tournaments.endsInText)}
              />

              <div className={`${tlbCSSNS}__winner--wrapper`}>
                <div className={`${tlbCSSNS}__winner--inner`}>
                  <div className={`${tlbCSSNS}__winner--title`}>{t(Promotions.Tournaments.rankingTableLabel)}</div>
                  <TournamentsLeaderBoardRanking ranking={item.ranking} />
                </div>
                <div className={`${tlbCSSNS}__winner--inner`}>
                  <div className={`${tlbCSSNS}__winner--title`}>{t(Promotions.Tournaments.awardsTableLabel)}</div>
                  <TournamentsLeaderBoardAwards awards={item.awards} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    )
  );
};

export default TournamentsPopover;
