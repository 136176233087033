import React from 'react';
import styled from 'styled-components';
import { CodeFormItem } from './CodeFormItem';
import { splitCode } from '../utils/twoFactorVerification.utils';
type Props = {
  code?: string;
  handleInputCode: (character: string, idx: number) => void;
  activeItem: number | null;
  hasError?: boolean;
  isDisabled?: boolean;
  handleFocusFirstItem: () => void;
};
export const CodeForm: React.FC<Props> = ({
  code,
  handleInputCode,
  activeItem,
  hasError,
  isDisabled,
  handleFocusFirstItem,
}) => {
  const objCode = splitCode({ code });
  return (
    <StyledWrapper className="modal-tfa__code">
      {objCode.map((symbol: string, idx: number) => (
        <CodeFormItem
          key={idx}
          index={idx}
          symbol={symbol}
          handleInputCode={handleInputCode}
          isDisabled={isDisabled}
          isFocus={activeItem === idx}
          hasError={hasError}
        />
      ))}
      {activeItem === null ? (
        <StyledCellDisable onClick={handleFocusFirstItem} className="wh-100"></StyledCellDisable>
      ) : null}
    </StyledWrapper>
  );
  // || (idx != 0 && !objCode[idx - 1] && (symbol === '' || symbol === undefined))
};

const StyledWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(6, 42px);
  grid-template-rows: 56px;
  justify-content: center;
  position: relative;
`;

const StyledCellDisable = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;
