import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { TransactionHistory } from '../types/transactionHistory.types';

export const transactionHistoryThunks: TransactionHistory.Thunks = {
  fetchMain: createAbortThunk('fetchMain', async (filterParams, thunkAPI) => {
    try {
      const url = `${config.API_URL}/api/reporting/player/accounting/transaction-history/v2`;
      const response = await axiosInstance.post(url, {
        fromDate: filterParams.fromDate,
        toDate: filterParams.toDate,
        playerId: filterParams.playerId,
        reasons: filterParams.reasons,
        accountTypes: filterParams.accountTypes,
        pageNumber: filterParams.pageNumber,
        pageSize: filterParams.pageSize,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(getError.responseData(err));
    }
  }),
  fetchReasons: createAbortThunk('fetchReasons', async (_, thunkAPI) => {
    try {
      const url = `${config.API_URL}/api/accounting/common/player/transactions/reasons`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(getError.responseData(err));
    }
  }),
  fetchAccountTypes: createAbortThunk('fetchAccountType', async (_, thunkAPI) => {
    try {
      const url = `${config.API_URL}/api/accounting/common/player/accounts/accountTypes`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(getError.responseData(err));
    }
  }),
};

export const { fetchMain, fetchReasons, fetchAccountTypes } = transactionHistoryThunks;

const initialState: TransactionHistory.SliceTypes = {
  data: [],
  reasons: null,
  accountTypes: null,
  dataError: null,
  reasonsError: null,
  accountTypesError: null,
  filters: {
    searchTime: null,
    fromDate: new Date().setHours(12, 0, 0, 0) - 2678400000,
    toDate: new Date().setHours(12, 0, 0, 0),
    reason: null,
    accountType: null,
  },
  pagination: {
    elements: 0,
    total: 0,
    page: 0,
    pageSize: 20,
  },
  isLoading: false,
  isLoadingMore: false,
  hasFetched: false,
  hasUpdated: false,
};
const transactionHistorySlice = createSlice({
  name: 'transactionHistory',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.pagination.page = action.payload;
    },
    setFilterFromDate(state, action: PayloadAction<number>) {
      const date = new Date(action.payload);
      state.filters.fromDate = date.setHours(12, 0, 0, 0);
    },
    setFilterToDate(state, action: PayloadAction<number>) {
      const date = new Date(action.payload);
      state.filters.toDate = date.setHours(12, 0, 0, 0);
    },
    setSearchTime(state, action: PayloadAction<number>) {
      state.isLoading = true;
      state.pagination.page = 0;
      state.filters.searchTime = action.payload;
    },
    setReason(state, action: PayloadAction<TransactionHistory.Reasons>) {
      state.filters.reason = action.payload === 'ALL' ? null : action.payload;
    },
    setAccountType(state, action: PayloadAction<TransactionHistory.AccountTypes>) {
      state.filters.accountType = action.payload === 'ALL' ? null : action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMain.pending, (state, action) => {
        if (action.meta?.arg?.isMoreLoading) {
          state.isLoadingMore = true;
        } else {
          state.isLoading = true;
        }
      })
      .addCase(
        fetchMain.rejected,
        (state, action: PayloadAction<TransactionHistory.RejectValue | null | undefined>) => {
          state.data = [];
          if (action.payload) {
            state.dataError = action.payload;
            state.isLoading = false;
            state.isLoadingMore = false;
          }
        },
      )
      .addCase(fetchMain.fulfilled, (state, action: PayloadAction<TransactionHistory.ApiResponse.Main>) => {
        const { content, pageable, totalPages, totalElements } = action.payload;
        state.data = content;
        state.pagination = {
          ...state.pagination,
          elements: totalElements,
          total: totalPages,
          page: pageable.pageNumber,
          pageSize: pageable.pageSize,
        };
        state.dataError = null;
        state.hasFetched = true;
        state.isLoading = false;
        state.isLoadingMore = false;
      })
      .addCase(fetchReasons.pending, (state, action) => {
        state.reasons = null;
        if (action.meta?.arg?.isMoreLoading) {
          state.isLoadingMore = true;
        } else {
          state.isLoading = true;
        }
      })
      .addCase(
        fetchReasons.rejected,
        (state, action: PayloadAction<TransactionHistory.RejectValue | null | undefined>) => {
          if (action.payload) {
            state.reasonsError = action.payload;
            state.isLoading = false;
            state.isLoadingMore = false;
          }
        },
      )
      .addCase(fetchReasons.fulfilled, (state, action: PayloadAction<TransactionHistory.ApiResponse.Reasons>) => {
        state.reasons = action.payload || {};
        state.reasonsError = null;
        state.hasFetched = true;
        state.isLoading = false;
        state.isLoadingMore = false;
      })
      .addCase(fetchAccountTypes.pending, (state, action) => {
        state.accountTypes = null;
        if (action.meta?.arg?.isMoreLoading) {
          state.isLoadingMore = true;
        } else {
          state.isLoading = true;
        }
      })
      .addCase(
        fetchAccountTypes.rejected,
        (state, action: PayloadAction<TransactionHistory.RejectValue | null | undefined>) => {
          if (action.payload) {
            state.accountTypesError = action.payload;
            state.isLoading = false;
            state.isLoadingMore = false;
          }
        },
      )
      .addCase(
        fetchAccountTypes.fulfilled,
        (state, action: PayloadAction<TransactionHistory.ApiResponse.AccountTypes>) => {
          state.accountTypes = action.payload || {};
          state.accountTypesError = null;
          state.hasFetched = true;
          state.isLoading = false;
          state.isLoadingMore = false;
        },
      );
  },
});

export const { setPage, setFilterFromDate, setFilterToDate, setSearchTime, setReason, setAccountType } =
  transactionHistorySlice.actions;
export default transactionHistorySlice.reducer;

export const selectTransactionHistory = {
  items: (state: RootState) => state.myAccount.transactionHistory.data,
  filters: (state: RootState) => state.myAccount.transactionHistory.filters,
  pagination: (state: RootState) => state.myAccount.transactionHistory.pagination,
  accountTypes: (state: RootState) => state.myAccount.transactionHistory.accountTypes,
  reasons: (state: RootState) => state.myAccount.transactionHistory.reasons,
  isLoading: (state: RootState) => state.myAccount.transactionHistory.isLoading,
  isLoadingMore: (state: RootState) => state.myAccount.transactionHistory.isLoadingMore,
  hasFetched: (state: RootState) => state.myAccount.transactionHistory.hasFetched,
  hasDataError: (state: RootState) => state.myAccount.transactionHistory.dataError,
  hasReasonsError: (state: RootState) => state.myAccount.transactionHistory.reasonsError,
  hasAccountTypesError: (state: RootState) => state.myAccount.transactionHistory.accountTypesError,
};
