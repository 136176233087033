import React, { memo, useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import { WidgetWrapper } from './components';
import PromoWidgetCell from './components/PromoWidgetCell/PromoWidgetCell';
import { useWidgetPromoItemsFetch } from './hooks/useWidgetPromoItemsFetch';
import { CategoryLayoutTypes } from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { CasinoWidgetsTypes } from '../../types/casinoWidgetsTypes';

interface Props {
  widget: CasinoWidgetsTypes.Widget;
  categoryLayoutType: CategoryLayoutTypes;
}

const PromoWidget: React.FC<Props> = ({ widget, categoryLayoutType }) => {
  const { widgetPromoItems } = useWidgetPromoItemsFetch({ widgetId: widget?.id, query: widget?.query });

  const renderPromoCell = useCallback(() => {
    return widgetPromoItems.map((promoItem, index) => (
      <PromoWidgetCell key={`${index}_${promoItem.id}`} promoItem={promoItem} />
    ));
  }, [widgetPromoItems]);

  return (
    <>
      {widgetPromoItems.length ? (
        <WidgetWrapper
          contentLayout={widgetPromoItems[0]?.contentLayout}
          templateType={widget?.template?.type}
          categoryLayoutType={categoryLayoutType}
        >
          {renderPromoCell()}
        </WidgetWrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(PromoWidget, isEqual);
