import { GridLayouts, GridRowType } from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import { CasinoLobbyTypes } from 'modules/casino/modules/lobby/containersLobby/types/casinoLobbyTypes';
import { CategoryQueryType } from 'modules/casino/shared/constants';
import { Casino } from 'modules/casino/shared/types';
import { getCellPosition, isValidCategoryParams } from 'modules/casino/shared/utils/casinoUtils';
import { CategorySubTypes } from '../../../container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';

const hasMostPlayed = (category) => {
  return category.queryType === CategoryQueryType.MOST_PLAYED_CATEGORY;
};

const mostPlayedChildCount = (query) => {
  const childCount = new URLSearchParams(query).get('childCount');

  if (childCount) {
    return parseInt(childCount);
  }

  return 0;
};

export const getNormalizedLobbyData = (lobbyItems): CasinoLobbyTypes.LobbyItems => {
  const lobbyCategoryIds: string[] = [];
  const lobbyCategories = {};
  let refetchLobby: boolean = false;

  lobbyItems.forEach((item) => {
    item.alias = item.alias.toLowerCase();

    if (isValidCategoryParams({ category: item, subType: CategorySubTypes.STANDARD })) {
      const { hasNumbers, layoutConfig } = item;
      let overwrittenLayoutConfig = layoutConfig;
      if (hasNumbers) {
        overwrittenLayoutConfig = overrideLayoutConfigWithNumbers(layoutConfig);
      }
      lobbyCategoryIds.push(item.id);
      lobbyCategories[item.id] = hasNumbers ? { ...item, layoutConfig: overwrittenLayoutConfig } : item;

      if (item.widgetTypes && item.widgetTypes?.includes('THIRD_PARTY')) {
        refetchLobby = true;
      }

      if (hasMostPlayed(item)) {
        lobbyCategories[item.id].navToGamesCount = mostPlayedChildCount(item.query);
      }
    }
  });

  return {
    categoryIds: lobbyCategoryIds,
    categories: lobbyCategories,
    ttl: Date.now(),
    refetchLobby,
  };
};

export const overrideLayoutConfigWithNumbers = (layoutConfig: Casino.LayoutConfig): Casino.LayoutConfig => {
  const { layoutGrid, layoutRows } = layoutConfig;
  if (layoutGrid === GridLayouts.LAYOUT_3 && layoutRows !== GridRowType.ONE_ROW) {
    return { ...layoutConfig, layoutRows: GridRowType.ONE_ROW };
  }
  if (layoutGrid !== GridLayouts.LAYOUT_3) {
    if (layoutRows !== GridRowType.ONE_ROW && layoutRows !== GridRowType.TWO_ROWS) {
      return { ...layoutConfig, layoutRows: GridRowType.ONE_ROW };
    }
  }

  return layoutConfig;
};

export const lobbyItemsFilterConditions = ({
  queryType,
  categoryIsPublic,
  isAuthenticated,
}: {
  queryType: CategoryQueryType;
  categoryIsPublic: boolean;
  isAuthenticated: boolean;
}) => {
  if (queryType === CategoryQueryType.FAVOURITES_CATEGORY) return false;
  if (queryType === CategoryQueryType.LAST_PLAYED_CATEGORY && !isAuthenticated) return false;
  if (!isAuthenticated && !categoryIsPublic) return false;

  return true;
};

export const skipLobbyItemLazyLoad = ({
  isFavouriteGamesModalOpen,
  hasSuccess,
}: {
  isFavouriteGamesModalOpen: boolean;
  hasSuccess: boolean;
}): boolean | undefined => {
  const cell = getCellPosition();

  return isFavouriteGamesModalOpen ? false : cell && (hasSuccess || cell.exitReloaded);
};
