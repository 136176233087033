import React from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';

interface NavGridProps {
  columns: number;
  children: React.ReactNode;
}

const gridStates = { visible: { x: 0, transition: { staggerChildren: 0.1 } }, exit: { x: '-100%' } };

const NavGrid: React.FC<NavGridProps> = ({ columns, children }) => {
  const gridClass = clsx(`d-grid grid-${columns}-cols w-100 mpd-nav-grid`);
  return (
    <motion.div
      style={{ flex: '1 0 100%' }}
      className={gridClass}
      transition={{ ease: 'easeInOut', duration: 0.4 }}
      animate="visible"
      exit="exit"
      variants={gridStates}
      initial="hidden"
    >
      {children}
    </motion.div>
  );
};

export default NavGrid;
