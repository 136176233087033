import React from 'react';
import { MyBets } from 'pages/myAccount/tabs/history/sections/sportsHistory/types/myBets.types';
import { Sport } from 'utils/common/types/sportTypes';
import { SportDataTypes } from 'utils/common/types/sportTypes/sportData.types';
import { SPORTS_WITH_EVENT_NAME } from './betSelectionUITypes';
import Clock from './Clock';
import Event from './Event';
import Market from './Market';

/** BetSeletionUI Body Live */
interface BodyLiveProps {
  homeTeam: string;
  awayTeam: string;
  bankerLabel: string;
  eventClockStatus: Sport.EventClockStatus;
  eventStatusConfig: SportDataTypes.EventStatusConfig | null;
  handleEventClick: () => void;
  handleLiveTrackerClick: () => void;
  isEventClickable: boolean;
  isLiveTrackerButtonActive: boolean;
  market: string;
  showBankerLabel: boolean;
  showEventClock: boolean;
  showLiveScore: boolean;
  showLiveTrackerButton: boolean;
  tournamentId: number;
  eventName: string;
  sportId: number;
  selectionBonuses: MyBets.SelectionBonusesTypes;
  producerId?: number;
}

const BodyLive: React.FC<BodyLiveProps> = ({
  homeTeam,
  awayTeam,
  bankerLabel,
  eventClockStatus,
  eventStatusConfig,
  handleEventClick,
  // handleLiveTrackerClick,
  isEventClickable,
  // isLiveTrackerButtonActive,
  market,
  showBankerLabel,
  showEventClock,
  showLiveScore,
  // showLiveTrackerButton,
  tournamentId,
  eventName,
  sportId,
  selectionBonuses,
  producerId,
}) => {
  return (
    <div className="d-flex-col egtd-p-bi-sel__body">
      <Market
        market={market}
        showBankerLabel={showBankerLabel}
        bankerLabel={bankerLabel}
        selectionBonuses={selectionBonuses}
      />

      <div className="d-flex-col">
        <Event
          showEventName={SPORTS_WITH_EVENT_NAME.includes(sportId)}
          showLiveScore={showLiveScore}
          sportId={sportId}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          eventName={eventName}
          eventClockStatus={eventClockStatus}
          eventStatusConfig={eventStatusConfig}
          hasEventLink={isEventClickable}
          handleEventClick={handleEventClick}
        />

        <div className="d-flex-ac-jb mt-1">
          <Clock
            isVisible={showEventClock}
            eventClockStatus={eventClockStatus}
            eventStatusConfig={eventStatusConfig}
            sportId={sportId}
            tournamentId={tournamentId}
            producerId={producerId}
          />
        </div>
      </div>
    </div>
  );
};

export default BodyLive;
