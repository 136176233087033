import React from 'react';
import styled from 'styled-components';
import { FormUI } from 'components/shared/forms/components';
type Props = {
  text: string;
};
export const CodeError: React.FC<Props> = ({ text }) => {
  return (
    <StyledWrapper className="modal-tfa__code-has-error d-flex-center mt-3">
      <FormUI.Status type="error" size="sm" isCentered={false} />
      <b className="p-1 text-left">{text}</b>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  margin-top: 20px;
  justify-content: center;

  cursor: pointer;

  .form-status__icon {
    margin: 0;
  }
  .text-left {
    line-height: 1.2;
  }
`;
