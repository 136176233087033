import React, { memo } from 'react';
import clsx from 'clsx';
import { useWindowDimensions } from 'hooks/useWindowDimenions';
import { StyledCTAButton, StyledPlaceholder } from './CTAButton.styled';

interface Props {
  buttonText?: string;
  disabled?: boolean;
  errorText?: string;
  onClick: () => void;
  isVisible: boolean;
  showPlaceholder?: boolean;
  statusCode?: number;
  text?: string;
  marginTop?: string;
  buttonBackground?: string;
  bgColor?: string;
  textColor?: string;
  borderColor?: string;
}

const CTAButton: React.FC<Props> = ({
  buttonText,
  disabled,
  errorText,
  onClick,
  isVisible,
  showPlaceholder = true,
  statusCode,
  text,
  marginTop,
  buttonBackground,
  bgColor,
  textColor,
  borderColor,
}) => {
  const { height } = useWindowDimensions();
  const ctaBtnClass = clsx(height > 800 && 'my-4');

  const btnStyle = {
    ...(bgColor ? { background: bgColor } : {}),
    ...(textColor ? { color: textColor } : {}),
    ...(borderColor ? { borderColor: borderColor } : {}),
  };

  const showButton = (!errorText || statusCode === 511) && isVisible && buttonText;

  return (
    <>
      {showButton ? (
        <StyledCTAButton
          disabled={disabled}
          onClick={onClick}
          className={ctaBtnClass}
          $addMarginTop={marginTop}
          $buttonBackground={buttonBackground}
          style={btnStyle}
        >
          {buttonText}
        </StyledCTAButton>
      ) : showPlaceholder && !isVisible ? (
        <StyledPlaceholder className={ctaBtnClass}>{text}</StyledPlaceholder>
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(CTAButton);
