import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { GameJourney } from 'utils/common/translationUtils/translationStrings';
import { cssGamificationNS } from 'utils/common/types/css.types';
type Props = {
  title: string;
  coins: number;
  completed: boolean;
  progress: number;
  componentLocation?: 'item' | 'modal' | undefined;
  description: string;
  experience: string;
};

export const FooterModal: React.FC<Props> = ({
  title,
  description,
  experience,
  coins,
  completed,
  progress,
  componentLocation,
}) => {
  const { t } = useTranslation();
  const progressClass = clsx(
    `${cssGamificationNS}-chall-cell__progress-bar-inner`,
    completed && `${cssGamificationNS}-chall-cell__progress-bar-inner--complete`,
    componentLocation === `modal` && `${cssGamificationNS}-chall-cell__modal__progress-bar-inner`,
  );

  return (
    <div className={`${cssGamificationNS}-chall-cell__modal-wrapper`}>
      <div className={`${cssGamificationNS}-chall-cell__progress`}>
        <div
          className={`${cssGamificationNS}-chall-cell__progress-bar ${cssGamificationNS}-chall-cell__modal__progress-bar`}
        >
          <div className={progressClass} style={{ width: progress + '%' }}></div>
        </div>
      </div>

      <div className={`${cssGamificationNS}-chall-cell__modal-status`}> {completed ? 'Completed' : 'In Progress'}</div>
      <div className={`${cssGamificationNS}-chall-cell__modal-title`}>{title}</div>
      <div className={`${cssGamificationNS}-chall-cell__modal-desc`}>{description}</div>

      <div className={`${cssGamificationNS}-chall-cell__modal-reward`}>{t(GameJourney.Common.rewardText)}:</div>
      <Flex.AC className={`${cssGamificationNS}-chall-cell__modal-footer`}>
        <div className={`${cssGamificationNS}-chall-cell__modal-exp`}>
          +{experience} {t(GameJourney.Common.experienceText)}
        </div>
        <Flex.AC className={`${cssGamificationNS}-chall-cell__modal-coins`}>
          {coins} {t(GameJourney.Common.coinsText)}{' '}
          <Icon className={`${cssGamificationNS}-chall-cell__icon`} icon="times" />
        </Flex.AC>
      </Flex.AC>
    </div>
  );
};
