import React from 'react';
import Icon from 'components/shared/Icon';
import { Flex } from 'components/shared/layout';
type Props = {
  icon?: string;
  textBtn: string;
  iconBtn: string;
  onClick: React.MouseEventHandler;
};

const PrizeDropViewAllBtn: React.FC<Props> = ({ icon = 'trophy', textBtn, iconBtn, onClick }) => {
  return (
    <egtd-przd-gift-item onClick={onClick} data-promo={true} data-active={true} data-btn={true}>
      <egtd-przd-gift-item-inner>
        <Icon icon={icon} className="egtd-przd-view-all-icon" />
      </egtd-przd-gift-item-inner>
      <egtd-przd-gift-item-footer>
        <Flex.AC className="mx-auto">
          {textBtn}
          <Icon defaultClassName={false} className="ml-1" size={12} icon={iconBtn} />
        </Flex.AC>
      </egtd-przd-gift-item-footer>
    </egtd-przd-gift-item>
  );
};

export default PrizeDropViewAllBtn;
