import { useEffect } from 'react';
import { useTimeToLeave } from 'hooks/common/useTimeToLeave';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { promotionThunks, resetPromotionTTL, selectPromotions } from '../slice/promotions.slice';
import { PromotionTypes } from '../types/promotions.types';
import { PROMOTION_BASE_TTL } from '../utils/promotions.utils';

export const useFetchPromotionLobbyItems = (): PromotionTypes.Category[] => {
  const dispatch = useAppDispatch();
  const lobbyItems = useAppSelector(selectPromotions.lobbyItems);
  const promotionTTL = useAppSelector(selectPromotions.ttl);
  const hasExpired = useTimeToLeave();

  useEffect(() => {
    let promise;
    if (isEmpty(lobbyItems) || hasExpired(promotionTTL, PROMOTION_BASE_TTL)) {
      promise = dispatch(promotionThunks.fetchLobbyItems());
      dispatch(resetPromotionTTL(new Date().getTime()));
      return () => {
        promise.abort();
      };
    }
  }, []);

  return lobbyItems;
};
