import React from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';
import { cssGamificationNS } from 'utils/common/types/css.types';

interface FavouritesBtnProps {
  isFavorite?: boolean;
  showFavBtn?: boolean;
  handleOnClick?: () => void;
}

const FavIcon: React.FC<FavouritesBtnProps> = ({ isFavorite, handleOnClick, showFavBtn = true }) => {
  const favIconClass = clsx(`${cssGamificationNS}-tag__fav-btn`, isFavorite && 'casino-fav-btn--active');
  const onClick = (e) => {
    e.stopPropagation();
    handleOnClick && handleOnClick();
  };
  return showFavBtn ? (
    <Flex.JE className={`${cssGamificationNS}-tag__fav`}>
      <div id="captainUpFavButton" className={favIconClass} onClick={onClick} />
    </Flex.JE>
  ) : (
    <></>
  );
};

export default FavIcon;
