import React from 'react';
import clsx from 'clsx';
import { Icon } from 'components/shared';
import { CSS } from 'utils/common/types/css.types';
import { StyledCTAButton } from './CTAButton.styled';

interface CTAButtonProps {
  size?: CSS.Sizes;
  variant?: 'accent';
  className?: string;
  text: string;
  onClick: React.MouseEventHandler;
  disabled?: boolean;
  style?: { color: string; backgroundColor: string };
  icon?: string;
}

const CTAButton: React.FC<CTAButtonProps> = ({
  size,
  variant,
  className,
  text,
  onClick,
  disabled,
  style = {},
  icon,
}) => {
  const btnClass = clsx('egtd-p-btn', !variant && 'egtd-p-btn--primary', className);
  return (
    <StyledCTAButton
      type="button"
      size={size}
      variant={variant}
      className={btnClass}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {text} {icon && <Icon icon={icon}></Icon>}
    </StyledCTAButton>
  );
};

export default CTAButton;
