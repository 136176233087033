import React, { memo } from 'react';
import Icon from '../../Icon';
import { Flex } from '../../layout';

/** Component CSS NameSpace */
const cssNS = 'file-list';

/** Form Row */
interface WrapperProps {
  children: React.ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({ children }) => {
  return <Flex.Col className={`${cssNS}__wrapper`}>{children}</Flex.Col>;
};

/** Form Label */
interface ItemProps {
  text: string;
}

const Item: React.FC<ItemProps> = ({ text }) => {
  return (
    <Flex.AC className={`${cssNS}__item`}>
      <Icon className={`${cssNS}__item-icon`} icon="file" />
      {text}
    </Flex.AC>
  );
};

export const FileList = {
  Wrapper: Wrapper,
  Item: memo(Item),
};
