import React from 'react';
import { Icon } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { cssGamificationNS } from 'utils/common/types/css.types';

type ImageProps = {
  isDone: boolean;
  claimed?: boolean;
};
export const DoneIcon: React.FC<ImageProps> = ({ isDone, claimed }) => {
  if (!isDone) return null;

  return (
    <Flex.AE className={`${cssGamificationNS}-tag ${cssGamificationNS}-tag__status__wrapper`}>
      <Flex.Center
        className={`${cssGamificationNS}-tag__status ${
          claimed ? `${cssGamificationNS}-tag__status--claimed` : `${cssGamificationNS}-tag__status--done`
        }`}
      >
        <Icon icon={'check'} className="w-100" />
      </Flex.Center>
    </Flex.AE>
  );
};
