export const sportColorsMap = {
  1001: { red: '27', green: '95', blue: '52' },
  1002: { red: '133', green: '88', blue: '0' },
  1004: { red: '75', green: '94', blue: '105' },
  1005: { red: '74', green: '112', blue: '34' },
  1006: { red: '36', green: '86', blue: '111' },
  1009: { red: '78', green: '128', blue: '54' },
  1019: { red: '66', green: '128', blue: '85' },
  1020: { red: '57', green: '97', blue: '136' },
  1022: { red: '120', green: '62', blue: '58' },
  1023: { red: '124', green: '87', blue: '45' },
  1024: { red: '109', green: '128', blue: '64' },
  1029: { red: '36', green: '86', blue: '111' },
  1031: { red: '30', green: '107', blue: '129' },
  1032: { red: '109', green: '128', blue: '64' },
  1109: { red: '48', green: '73', blue: '175' },
  0: { red: '255', green: '0', blue: '0' },
};

export const defaultColors = { red: '255', green: '0', blue: '0' };
