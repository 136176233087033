import styled from 'styled-components';

const StyledCardSubtitle = styled.div.attrs({ className: 'prm-lobby-card__subtitle' })`
  padding: 8px;
  place-self: end start;
  line-height: 1.2;
  font-size: clamp(0.75rem, calc(0.606rem + 0.62vw), 1rem);
  font-weight: 700;
`;

export default StyledCardSubtitle;
