import { memo } from 'react';
import { Text } from 'components/shared';
import { replaceTranslationPlaceholder } from 'components/shared/forms/utils';
import { Flex } from 'components/shared/layout';
import ImageComponent from './ImageComponent';
import { CopyrightStyle } from './Styled';
import { FooterTypes, CURRENT_YEAR_PLACEHOLDER } from '../types/appFooter.types';
interface Props {
  section: FooterTypes.Section;
}

const Copyright: React.FC<Props> = ({ section }) => {
  if (!section.items) {
    return null;
  }
  const currentYear = new Date().getFullYear();
  return (
    <CopyrightStyle className="w-100">
      {section.items.map((item: FooterTypes.Item, idx) => (
        <Flex key={idx} className="copyright__item">
          {item.icon && <ImageComponent imgRef={item.icon} route={item?.externalLink ?? ''} />}
          {item.title && (
            <Text
              type="p"
              className="mb-0 footer-text"
              text={replaceTranslationPlaceholder(item.title, currentYear, CURRENT_YEAR_PLACEHOLDER)}
            />
          )}
        </Flex>
      ))}
    </CopyrightStyle>
  );
};

export default memo(Copyright);
