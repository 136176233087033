import { useMediaQuery } from 'react-responsive';
type Props = {
  index: number;
  itemLength: number;
};
export const DefaultBox2x3Row: React.FC<Props> = ({ index, itemLength }) => {
  const isDesktopDown = useMediaQuery({ query: `(max-width: 1280px)` });

  return (
    <>
      {isDesktopDown && index === 1 && <div className="w-100"></div>}
      {!isDesktopDown && itemLength > 5 && (index + 1) % 2 === 0 && <div className="w-100"></div>}
    </>
  );
};
