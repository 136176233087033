import { CasinoWidgetsTypes } from 'modules/casino/modules/container/widget/types/casinoWidgetsTypes';
import {
  WidgetGamesPreViewDesktop,
  WidgetGamesPreViewMobile,
  WidgetGamesPreViewMobileXL,
  WidgetGamesPreViewTablet,
  WidgetTemplate,
} from 'modules/casino/shared/constants';

export const layout01Settings = (templateType: CasinoWidgetsTypes.WidgetTypes) => {
  const lastWinnerInitialItemDimension =
    WidgetTemplate[templateType] === WidgetTemplate.SECTION
      ? 242
      : WidgetTemplate[templateType] === WidgetTemplate.SECTION_STAKE ||
          WidgetTemplate[templateType] === WidgetTemplate.SECTION_STAKE_PLUS
        ? 125
        : 80;

  const lastWinnerResponsive = [
    {
      breakpoint: 1924,
      settings: {
        itemDimension:
          WidgetTemplate[templateType] === WidgetTemplate.SECTION
            ? 242
            : WidgetTemplate[templateType] === WidgetTemplate.SECTION_STAKE ||
                WidgetTemplate[templateType] === WidgetTemplate.SECTION_STAKE_PLUS
              ? 125
              : 80,
        slidesToShow: WidgetGamesPreViewDesktop[templateType],
      },
    },
    {
      breakpoint: 1024,
      settings: {
        itemDimension:
          WidgetTemplate[templateType] === WidgetTemplate.SECTION
            ? 242
            : WidgetTemplate[templateType] === WidgetTemplate.SECTION_STAKE ||
                WidgetTemplate[templateType] === WidgetTemplate.SECTION_STAKE_PLUS
              ? 125
              : 72,
        slidesToShow: WidgetGamesPreViewTablet[templateType],
      },
    },
    {
      breakpoint: 768,
      settings: {
        itemDimension:
          WidgetTemplate[templateType] === WidgetTemplate.SECTION
            ? 152
            : WidgetTemplate[templateType] === WidgetTemplate.SECTION_STAKE ||
                WidgetTemplate[templateType] === WidgetTemplate.SECTION_STAKE_PLUS
              ? 125
              : 50,
        slidesToShow: WidgetGamesPreViewMobileXL[templateType],
      },
    },
    {
      breakpoint: 441,
      settings: {
        itemDimension:
          WidgetTemplate[templateType] === WidgetTemplate.SECTION
            ? 152
            : WidgetTemplate[templateType] === WidgetTemplate.SECTION_STAKE ||
                WidgetTemplate[templateType] === WidgetTemplate.SECTION_STAKE_PLUS
              ? 125
              : 50,
        slidesToShow: WidgetGamesPreViewMobile[templateType],
      },
    },
  ];

  const lastWinnerSpaceBetween =
    WidgetTemplate[templateType] === WidgetTemplate.SECTION
      ? 3
      : WidgetTemplate[templateType] === WidgetTemplate.SECTION_STAKE ||
          WidgetTemplate[templateType] === WidgetTemplate.SECTION_STAKE_PLUS
        ? 8
        : 2;

  return { lastWinnerSpaceBetween, lastWinnerResponsive, lastWinnerInitialItemDimension };
};
