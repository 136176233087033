import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'modules/casino/shared/components/layout';
import CampaignTitle from './CampaignTitle';
import { prizeDropCSSNS } from '../../PrizeDropCellWidgetConstants';

type Props = {
  title: string;
  givenAwards: number;
  totalAwards: number;
};

const RewardItem: React.FC<Props> = ({ title, givenAwards, totalAwards }) => {
  const { t } = useTranslation();

  return (
    <div className={`${prizeDropCSSNS}-widget-campaign-item`}>
      <CampaignTitle title={title} className={`${prizeDropCSSNS}-widget-campaign-item-title`} />
      <Flex.ACJB>
        {`${t('Promotions.PrizeDrop.winnersTab')}:`}
        <>
          <span>
            <span className={`${prizeDropCSSNS}-widget-campaign-item--win-count`}>{givenAwards}</span>/{totalAwards}
          </span>
        </>
      </Flex.ACJB>
    </div>
  );
};

export default RewardItem;
