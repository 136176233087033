import { useEffect, useRef, useState } from 'react';

export const usePreloadImage = (
  src?: string,
  errorCallback?: () => void,
  timeout = 1000,
): { isImageLoaded: boolean; imageLoadError: boolean | null } => {
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
  const [imageLoadError, setImageLoadError] = useState<boolean | null>(null);
  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!isImageLoaded && !imageLoadError && src) {
      const image = new Image();

      image.onload = () => {
        timer.current = setTimeout(() => {
          setIsImageLoaded(true);
          setImageLoadError(false);
        }, timeout);
      };

      image.onerror = () => {
        setImageLoadError(true);
        errorCallback && errorCallback();
      };

      image.src = src;
    }
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [src, isImageLoaded, imageLoadError, timeout, errorCallback]);

  return { isImageLoaded, imageLoadError };
};
