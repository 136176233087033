import React from 'react';
import { NotificationTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationTypes';
import { Marker } from './DwarfsWheelUI/Marker';
import { Wrapper } from './DwarfsWheelUI/Wrapper';
import { WheelUI } from '../../components/GamificationUI/WheelUI';
import useWheelAnimation from '../../hooks/useWheelAnimation';
import { wheelConfig, wheelCampaignImages } from '../SharedSC/utils/campaigns.utils';

interface DwarfsWheelProps {
  folder: NotificationTypes.GamificationFolder;
  segments: string[];
  winnerSegment: number;
  setAnimationComplete: (isVisible: boolean) => void;
  notificationId: string;
}

const DwarfsWheel: React.FC<DwarfsWheelProps> = ({
  folder,
  segments,
  winnerSegment,
  setAnimationComplete,
  notificationId,
}) => {
  const { calcRemainingDistance, stopSpin, startSpin, hasWin, isSpinning, isDialVisible } = useWheelAnimation(
    notificationId,
    setAnimationComplete,
  );

  const { wheelImgSrc, markerImgSrc, btnImgSrc, wrapperImgSrc } = wheelCampaignImages(folder);

  return (
    <Wrapper style={{ backgroundImage: `url(${wrapperImgSrc})` }}>
      <div className="d-grid grid-1x1 gmf-pw__wheel-wrapper" style={{ border: '1px solid rgba(0, 0, 0, 0.7)' }}>
        <Marker style={{ backgroundImage: `url(${markerImgSrc})` }} />
        <WheelUI.Dial
          className={`${isDialVisible ? 'bg-contain' : 'bg-cover'}`}
          finalRevolutionAngle={wheelConfig.revolutions * 360 + calcRemainingDistance(winnerSegment + 1)}
          revolutionsPeriod={wheelConfig.revolutionsPeriod}
          isSpinning={isSpinning}
          hasStopped={hasWin}
          onAnimationComplete={stopSpin}
          bgImage={wheelImgSrc}
        >
          {segments.map((segment, index) => (
            <WheelUI.Segment key={index} hasWin={hasWin} isWinner={index === winnerSegment && hasWin} text={segment} />
          ))}
        </WheelUI.Dial>
        <WheelUI.SpinButton isClickable={!isSpinning} onClick={startSpin} bgImage={btnImgSrc} />
      </div>
    </Wrapper>
  );
};

export default DwarfsWheel;
