import { useEffect } from 'react';
import { actionButtonVoucherEnums } from './cashierModalUI/actionButtonsMock';

const CountDownTimerVoucher = ({
  isActive,
  setIsActive,
  seconds,
  setSeconds,
  setActionButtonClicked,
  id,
  actionButtonClicked,
}) => {
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (isActive[id] && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((inSeconds: number) => inSeconds - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, seconds, setSeconds]);

  useEffect(() => {
    if (seconds === 0) {
      const otherId =
        id === actionButtonVoucherEnums.cancelVoucher
          ? actionButtonVoucherEnums.payOutVoucher
          : actionButtonVoucherEnums.cancelVoucher;
      setSeconds(0);
      setActionButtonClicked((prevState) => ({
        ...prevState,
        [id]: false,
      }));
      if (actionButtonClicked[id] && !isActive[otherId]) {
        setIsActive((prevState) => ({
          ...prevState,
          [otherId]: true,
        }));
        setActionButtonClicked((prevState) => ({
          ...prevState,
          [otherId]: true,
        }));
      }
    }
  }, [seconds, setActionButtonClicked, actionButtonClicked]);

  return (
    <div className="app">
      <div className="time ml-2">{`${seconds > 0 ? '(' + '00:0' + seconds + ')' : ''}`}</div>
    </div>
  );
};

export default CountDownTimerVoucher;
