import { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { useWindowDimensions } from 'hooks/useWindowDimenions';
import { GamificationPageTypes } from 'pages/gamificationPage/types/GamificationPage.types';
import { Breakpoints } from 'theme/Theme';
import { NotificationTypes } from '../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import {
  defineAnimationParams,
  constructAnimationImage,
} from '../../myProfile/sections/tabs/notificationsTab/notificationUtils';

export const useAnimationAssets = ({
  animationKey,
  isGamificationPage,
  layout,
  folder,
}: {
  animationKey?: NotificationTypes.AnimationKeys | GamificationPageTypes.AnimationKeys;
  isGamificationPage?: boolean;
  layout?: NotificationTypes.GamificationCampaignsLayouts;
  folder?: NotificationTypes.GamificationFolder;
}) => {
  const { width } = useWindowDimensions();

  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });

  const animationParams = useMemo(
    () => defineAnimationParams({ animationKey, layout, folder }),
    [animationKey, layout, folder],
  );

  const bgImage = animationParams.backgroundMobile
    ? isMobile && !isDesktop
      ? animationParams.backgroundMobile
      : animationParams.background
    : animationParams.background;

  const bgImageSize = useMemo(
    () =>
      animationParams.backgroundMobile ? (isMobile && !isDesktop ? [342 * 2, 700 * 2] : [1366, 768]) : [1366, 768],
    [animationParams.backgroundMobile, isDesktop],
  );
  const bgImageSizePage = useMemo(
    () =>
      animationParams.backgroundMobile
        ? isMobile && !isDesktop
          ? [width * 2, window.innerHeight * 2]
          : [width, window.innerHeight]
        : [width, 978],
    [animationParams.backgroundMobile, isDesktop],
  );

  const backgroundImage = useMemo(
    () =>
      bgImage
        ? constructAnimationImage({
            folder: animationParams.folder,
            image: bgImage,
            width: isGamificationPage ? bgImageSizePage[0] : bgImageSize[0],
            height: isGamificationPage ? bgImageSizePage[1] : bgImageSize[1],
            fit: isGamificationPage ? 'contain' : 'cover',
          })
        : '',
    [animationParams.folder, bgImage, bgImageSize, bgImageSizePage],
  );

  return { backgroundImage, animationParams, hasBackground: !!animationParams?.background };
};
