import { useEffect } from 'react';

export enum CustomPlatformEvents {
  HIGH_PRIORITY_MODAL = 'HIGH_PRIORITY_MODAL',
  FETCH_SPORT_BONUSES = 'FETCH_SPORT_BONUSES',
}

type PlatformEventsTypes = 'HIGH_PRIORITY_MODAL' | 'FETCH_SPORT_BONUSES';

const useHandleCustomEvents = (event: PlatformEventsTypes, callback: () => void) => {
  useEffect(() => {
    document.addEventListener(event, callback);
    return () => {
      document.removeEventListener(event, callback);
    };
  }, []);
};

export default useHandleCustomEvents;
