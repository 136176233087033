import React from 'react';
import clsx from 'clsx';
import { Icon } from 'components/shared';
import { IconTooltip } from 'components/shared/tooltip';
import { DesktopDown } from 'theme/Theme';
import { InputLocationType, inputLocationTypeClassNameMap } from '../../formTypes';

/** Form Label */
interface LabelProps {
  componentLocation?: InputLocationType;
  icon?: string;
  label?: string;
  className?: string;
  tooltip?: string;
}

const Label: React.FC<LabelProps> = ({ componentLocation, icon, label, className, tooltip }) => {
  if (!label) return null;

  const cssNS = 'form-label';
  const labelClass = clsx(
    cssNS,
    icon && 'd-flex-ac',
    componentLocation && `${cssNS}--${inputLocationTypeClassNameMap[componentLocation]}`,
    className,
  );

  return (
    <label className={labelClass}>
      {icon && (
        <DesktopDown>
          <Icon className="form-label__icon" icon={icon} />
        </DesktopDown>
      )}
      {label}
      {tooltip && <IconTooltip tooltipAlignment="left" tooltip={tooltip} icon={'exclamation-solid'} />}
    </label>
  );
};

export default Label;
