import React from 'react';
import clsx from 'clsx';
import Icon from 'components/shared/Icon';

interface Props {
  onClick?: () => void;
  className?: string;
  componentLocation?: 'tabList';
  autoMarginLeft?: boolean;
}

const ButtonClose: React.FC<Props> = ({ onClick, className, componentLocation, autoMarginLeft = true }) => {
  const btnClass = clsx(
    'd-flex-center btn btn-close',
    autoMarginLeft && 'ml-auto',
    componentLocation === 'tabList' && 'tab-list-btn',
    className,
  );

  const iconClass = clsx(
    !componentLocation && 'btn-close__icon',
    componentLocation === 'tabList' && 'tab-list-btn__icon',
  );

  return (
    <button type="button" className={btnClass} onClick={onClick} data-qid="btn-close">
      <Icon className={iconClass} icon="times" />
    </button>
  );
};

export default ButtonClose;
