import { memo, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { motion, useAnimation } from 'framer-motion';
import { SVGLoader } from 'components/shared';
import { UI } from 'components/shared/UI';
import { useIsRetailMobile } from 'shared/common/features/appHeader/retailHeader/hooks/useIsRetailMobile';

type Props = {
  onClick: () => void;
  text: string;
  icon: string;
  isDisabled?: boolean;
  isLastPrintClicked?: boolean;
  isPending?: boolean;
  isReprint?: boolean;
};

const CLICK_TIMEOUT_DURATION = 2000;
const RetailHeaderUserDefaultBtn: React.FC<Props> = ({ onClick, text, icon, isDisabled, isPending, isReprint }) => {
  const [isClicked, setIsClicked] = useState(false);
  const controls = useAnimation();
  const isRetailMobile = useIsRetailMobile();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    if (isClicked) {
      timeoutId = setTimeout(() => {
        setIsClicked(false);
      }, CLICK_TIMEOUT_DURATION);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isClicked]);

  const handleTapParent = useCallback(() => {
    controls.start(() => ({
      scale: [0.9, 1],
      transition: { ease: 'easeInOut', duration: 0.4 },
    }));
  }, [controls]);
  const handleClick = () => {
    setIsClicked(true);
    onClick();
    handleTapParent();
  };

  const buttonClassReprint = clsx(isDisabled ? 'rbtn__default--disabled' : '', 'rbtn__default d-flex-ac');
  const buttonClass = clsx(
    'rbtn__default',
    isRetailMobile ? 'd-flex-ac' : 'd-flex-center',
    isDisabled && isClicked && 'rbtn__default--disabled',
  );
  const clickHandler = !isDisabled && { onClick: handleClick };

  return (
    <motion.div {...clickHandler} className={isReprint ? buttonClassReprint : buttonClass} animate={controls}>
      {isRetailMobile ? (
        <>
          <UI.SVGCDN icon={icon} path="RETAIL" size={24} className="rbtn__default-icon" />
          {text}
        </>
      ) : (
        <>
          {text}
          {isPending ? (
            <SVGLoader className="cashier-modal__loader-icon" size={18} />
          ) : (
            <UI.SVGCDN icon={icon} path="RETAIL" size={24} className="rbtn__default-icon" />
          )}
        </>
      )}
    </motion.div>
  );
};

export default memo(RetailHeaderUserDefaultBtn);
