import React from 'react';
import clsx from 'clsx';
import { Sport } from 'utils/common/types/sportTypes';

// Component CSS NameSpace
const cssNS = 'block';

export const uiBlockTypesClassNameMap = {
  dynamicContent: 'dynamic',
  gamesFeed: 'games-feed',
  highlights: 'highlights',
  leagueMarkets: 'league-markets',
  leagueOutrights: 'league-outrights',
  liveEvents: 'live-events',
  lobbyLive: 'lobby-live-events',
  miltulineEvents: 'multiline-events',
  promosFeed: 'promos-feed',
  videoEvents: 'video-events',
} as const;

const componentByLocation = {
  homePage: 'home',
  landingPage: 'landing',
};

export type UIBlockTypes = keyof typeof uiBlockTypesClassNameMap;

interface Props {
  className?: string;
  children: React.ReactNode;
  type?: UIBlockTypes;
  forwardRef?: React.MutableRefObject<HTMLDivElement | null>;
  componentLocation?: Sport.ContainerLocation;
}

const UIBlock: React.FC<Props> = ({ type, className, children, componentLocation, forwardRef }) => {
  const blockClass = clsx(
    cssNS,
    type && `${cssNS}--${uiBlockTypesClassNameMap[type]}`,
    componentLocation && `${cssNS}--${componentByLocation[componentLocation]}`,
    className,
  );

  return (
    <div className={blockClass} ref={forwardRef}>
      {children}
    </div>
  );
};

export default UIBlock;
