import React from 'react';
import { Flex } from 'components/shared/layout';
import { useFilterHiddenVerticals } from 'hooks/common/useFilterHiddenVerticals';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppSelector } from 'store';
import { filterMenuItemsByPlayerId } from 'utils/filterMenuItemsByPlayerId';
import CustomNavLink from '../mainNavbar/CustomNavLink';

type Align = 'left' | 'center' | 'right';
const AlignClassNameMap: Record<Align, string> = {
  left: 'mr-auto',
  center: 'mx-auto',
  right: 'ml-auto',
};

type Props = { align: Align; wrapperRef: React.MutableRefObject<HTMLDivElement | null> };

const NavItemsGroup: React.FC<Props> = ({ align, wrapperRef }) => {
  const navigationMenu = useFilterHiddenVerticals();
  const playerId = useAppSelector(selectMyProfile.playerId);

  const filteredItems = navigationMenu?.filter((item) => {
    if (window.isRNWebView) {
      // if isShowInMobile is missing or it is true return the vertical
      if (item.isShowInMobileApp === undefined || item.isShowInMobileApp) return item.align === align;
      // else hide the vertical
      return false;
    }

    return item.align === align;
  });

  const items = filterMenuItemsByPlayerId({ items: filteredItems, playerId });

  return (
    <Flex className={AlignClassNameMap[align]}>
      {items?.map((navItem) => (
        <CustomNavLink
          key={navItem.id}
          isExternalRoute={navItem.isExternalRoute}
          name={navItem.name}
          isActiveLink={navItem?.isActive}
          //TODO remove, when BE returns new path
          route={navItem.route || ''}
          id={navItem.id}
          wrapperRef={wrapperRef}
          uiVerticalType={navItem.uiVerticalType}
          isNew={navItem?.ribbonConfig?.isNew}
          isNewBg={navItem?.ribbonConfig?.labelColor}
          isNewColor={navItem?.ribbonConfig?.textColor}
          onClick={navItem.onClick}
        />
      ))}
    </Flex>
  );
};

export default NavItemsGroup;
