import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestStatuses } from 'modules/casino/shared/constants';
import { updateNotifications } from 'shared/common/features/inGameNotifications/slice/inGameNotifications.slice';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch, useAppSelector } from 'store';
import JackpotPopover from './JackpotPopover';
import { casinoLaunchSelectors } from '../../../gameLaunch/casinoLaunchSlice';
import { jackpotSelectors, jackpotThunks } from '../../../jackpots/jackpotSlice';
import useActiveJackpotNotification from '../../hooks/useActiveJackpotNotification';
import { useJackpotCampaignDetails } from '../../hooks/useJackpotCampaignDetails';
import { useJackpotPollingTopBar } from '../../hooks/useJackpotPollingTopBar';
import {
  fetchJackpotCampaign,
  gamesBonusSelectors,
  resetJackpotCampaignFetchStatus,
} from '../../slice/gameLaunchBonus.slice';
import { TopBarButton } from '../TopBarButton';

type Props = {
  type: string;
  gameId: string;
  text?: string;
  icon?: string;
  isFreePlay: boolean;
};

export const JackpotCampaignButton = ({ isFreePlay, type, gameId, text, icon }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [openedJackpot, setOpenedJackpot] = useState(false);
  const { jackpotId } = useJackpotCampaignDetails();
  const jackpotCampaignId = useAppSelector(gamesBonusSelectors.jackpotCampaignId);
  const jackpotCampaignFetchStatus = useAppSelector(gamesBonusSelectors.jackpotCampaignFetchStatus);
  const isInitialJackpotsFetched = useAppSelector(jackpotSelectors.selectInitialJackpotsFetched);
  const bonusGamesFetchSuccess = useAppSelector(gamesBonusSelectors.bonusGamesFetchStatus) === RequestStatuses.SUCCESS;
  const isProviderWithLobby = useAppSelector(casinoLaunchSelectors.isProviderWithLobby);
  const isNewGameIdReceived = useAppSelector(casinoLaunchSelectors.isNewGameIdReceived);
  const isProviderInfoReceived = useAppSelector(casinoLaunchSelectors.providerInfoReceived);
  const activeJackpotNotification = useActiveJackpotNotification();
  useJackpotPollingTopBar({ openedJackpot, dispatch, jackpotId });

  const showIconForGamesWithLobby =
    isProviderInfoReceived && isProviderWithLobby
      ? isNewGameIdReceived
      : isProviderInfoReceived && !isProviderWithLobby;

  const showJackpotCampaignInfo = showIconForGamesWithLobby && bonusGamesFetchSuccess;

  const handleJackpotPopOverClick = () => {
    !openedJackpot && setOpenedJackpot(true);
    if (
      jackpotCampaignId &&
      jackpotCampaignFetchStatus !== RequestStatuses.PENDING &&
      jackpotCampaignFetchStatus !== RequestStatuses.SUCCESS
    ) {
      dispatch(fetchJackpotCampaign({ jackpotCampaignId, gameId }));
    }

    if (!isInitialJackpotsFetched && openedJackpot) {
      dispatch(jackpotThunks.fetchJackpots(jackpotId));
    }
  };

  useEffect(() => {
    if (activeJackpotNotification && openedJackpot) {
      dispatch(jackpotThunks.fetchJackpots(jackpotId));
    }
  }, [activeJackpotNotification, openedJackpot, dispatch, jackpotId]);

  useEffect(() => {
    return () => {
      if (activeJackpotNotification) {
        dispatch(updateNotifications(activeJackpotNotification.Id));
        dispatch(hideRootModal());
      }
      dispatch(resetJackpotCampaignFetchStatus());
    };
  }, []);

  return (
    <TopBarButton
      disabled={!showJackpotCampaignInfo}
      type={type}
      icon={icon}
      text={text && t(text)}
      restrictForFreePlay={isFreePlay}
      handleClick={handleJackpotPopOverClick}
      renderChildren={(btnRef, handleClose, show) => (
        <JackpotPopover handleClose={handleClose} parentRef={btnRef} show={show} />
      )}
    />
  );
};
