import { useEffect } from 'react';
import { General } from 'shared/common/features/general/types/generalSlice.types';

const useIsMobileBannerActive = (
  headBackRef: React.MutableRefObject<HTMLDivElement | null>,
  bannerConfig: General.TopMobileAppBanner,
  topMobileBannerCookie: string,
  setBannerClosed: React.Dispatch<React.SetStateAction<boolean>>,
  supportedDeviceDetect: 'ios' | 'android' | '',
  isTabletOrMobile: boolean,
): void => {
  useEffect(() => {
    if (bannerConfig && !window.isRNWebView) {
      const maxCloseCount = bannerConfig.maximumCloseCounter || 3;
      const selectedDeviceConfig = supportedDeviceDetect && bannerConfig[supportedDeviceDetect];
      const isBannerActiveOnMobile = selectedDeviceConfig && selectedDeviceConfig.active;
      const isReachedCloseCounter =
        topMobileBannerCookie.length > 0 && parseInt(topMobileBannerCookie) === maxCloseCount;
      const hideBanner = !isBannerActiveOnMobile || isReachedCloseCounter;

      if (headBackRef.current && !hideBanner) {
        const headerBackSize = !isTabletOrMobile ? '165px' : '150px';
        headBackRef.current.style.maxHeight = headerBackSize;
        headBackRef.current.style.flex = `1 0 ${headerBackSize}`;
      }
      setBannerClosed(hideBanner);
    }
  }, []);
};

export default useIsMobileBannerActive;
