import React from 'react';
import clsx from 'clsx';

const controlTypesClassNameMap = {
  datepicker: 'fcw-datepicker',
  dropdown: 'fcw-dropdown form-select',
  phoneInput: 'd-flex-col',
  switch: 'fcw-switch',
} as const;

type ControlTypes = keyof typeof controlTypesClassNameMap;

interface ControlWrapperProps {
  type: ControlTypes;
  children: React.ReactNode;
  hasError?: boolean;
  hasSuccess?: boolean;
  hasInfo?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

/** Form Control Wrapper */
const ControlWrapper: React.FC<ControlWrapperProps> = ({
  type,
  children,
  disabled,
  hasError,
  hasSuccess,
  hasInfo,
  className,
  onClick,
}) => {
  const wrapperClass = clsx(
    'fcw',
    // Classes by Type
    controlTypesClassNameMap[type],
    // Classes by State
    disabled && 'disabled',
    hasError && 'has-error bg--error has-error-icon',
    hasSuccess && 'has-success has-success-icon',
    hasInfo && 'has-info bg--info',
    className,
  );
  return (
    <div className={wrapperClass} onClick={onClick}>
      {children}
    </div>
  );
};

export default ControlWrapper;
