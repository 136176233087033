import React from 'react';
import clsx from 'clsx';

/** InputWrapper */
interface InputWrapperProps {
  className?: string;
  children?: React.ReactNode;
}

const InputWrapper: React.FC<InputWrapperProps> = ({ className, children }) => {
  const wrapperClass = clsx('egtd-x-input__group', className);

  return <div className={wrapperClass}>{children}</div>;
};

export default InputWrapper;
