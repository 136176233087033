import { useCallback, useState } from 'react';
import axios from 'axios';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { localLog } from 'utils/common/helpersCommon';

const usePrintProgram = () => {
  const [previewStatus, setPreviewStatus] = useState('idle');
  const [downloadStatus, setDownloadStatus] = useState('idle');

  const printProgramConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.PRINT_PROGRAM,
  });

  const handleDownload = useCallback(async () => {
    setDownloadStatus('loading');
    try {
      const response = await axios.get(`${printProgramConfig?.fileUrl}`, { responseType: 'blob' });
      const docBlob = new Blob([response.data]);
      const url = window.URL.createObjectURL(docBlob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${printProgramConfig?.fileName}.${printProgramConfig?.fileType}`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      localLog({ message: `Error downloading ${printProgramConfig?.fileName}: ${error}` });
    } finally {
      setDownloadStatus('idle');
    }
  }, []);

  const handlePreviewPrint = useCallback(async () => {
    setPreviewStatus('loading');
    try {
      const response = await axios.get(`${printProgramConfig?.fileUrl}`, {
        responseType: 'blob',
        headers: {
          'Content-type': 'image/jpeg',
        },
      });

      const url = URL.createObjectURL(response.data);

      window.open(url, 'popup', 'width = ' + window.screen.width + ', height = ' + window.screen.height);
    } catch (error) {
      localLog({ message: `Error downloading ${printProgramConfig?.fileName}: ${error}` });
    } finally {
      setPreviewStatus('idle');
    }
  }, []);

  return { handleDownload, handlePreviewPrint, previewStatus, downloadStatus };
};

export default usePrintProgram;
