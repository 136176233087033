import { useCallback, useLayoutEffect } from 'react';
import { cssGamificationNS } from 'utils/common/types/css.types';
import { CategoryQueryType } from '../utils/captainUp.utils';
interface ImageProps {
  loaded: boolean;
  visible: boolean;
  ref: React.MutableRefObject<HTMLDivElement | null> | null;
  animationEnd: boolean;
  animation: string;
  uniqueId: string;
  typeTap: string;
}
export const gridAnimationDefault = {
  FADE_IN: 'fade-in',
};

const collectionsArr: Record<string, ImageProps[]> = {};

const forcedShowCell = ({ collectionId, uniqueId, order }) => {
  const currentIndex = getCurrentIndex(collectionId, uniqueId);
  // const comparingIndex =
  //   queryType && exceptionalRouteCollections.includes(queryType) ? collectionsArr[collectionId]?.length : currentIndex;
  //
  // const skipLoading = cell && closedGameIndex !== -1 ? comparingIndex <= closedGameIndex : cell;

  if (collectionsArr[collectionId][order]) {
    showCell(collectionId, currentIndex);
  }
};
const showCell = (collectionId: string, currentIndex: number) => {
  if (collectionsArr[collectionId][currentIndex].visible) return;
  const ref = collectionsArr[collectionId][currentIndex].ref;
  const animation = collectionsArr[collectionId][currentIndex].animation;

  if (ref && ref.current) {
    collectionsArr[collectionId][currentIndex].visible = true;
    ref.current.classList.remove(`${cssGamificationNS}__image--loading`);
    ref.current.classList.add(`${cssGamificationNS}__image--is-loaded-${animation}`);
  }
};
const onAnimationStart = (collectionId: string, uniqueId: string) => {
  const currentIndex = getCurrentIndex(collectionId, uniqueId);
  collectionsArr[collectionId][currentIndex].animationEnd = true;
  collectionsArr[collectionId][currentIndex + 1]?.loaded && showCell(collectionId, currentIndex + 1);
};
const setLoaded = (collectionId: string, currentIndex) => {
  collectionsArr[collectionId][currentIndex].loaded = true;
};
const getCurrentIndex = (collectionId, uniqueId) => {
  return collectionsArr[collectionId]?.findIndex(function (item) {
    return item.uniqueId === uniqueId;
  });
};
const setInitial = (
  collectionId: string,
  uniqueId,
  animation,
  ref: React.MutableRefObject<HTMLDivElement | null>,
  order: number,
  typeTap: string,
) => {
  if (!collectionsArr[collectionId]) {
    collectionsArr[collectionId] = [];
  }
  const currentIndex = getCurrentIndex(collectionId, uniqueId);

  if (collectionsArr[collectionId][currentIndex]) {
    collectionsArr[collectionId][currentIndex].ref = ref;
  } else {
    if (collectionsArr[collectionId][order]) {
      collectionsArr[collectionId].splice(order, 0, {
        uniqueId: uniqueId,
        loaded: false,
        visible: false,
        ref,
        animationEnd: false,
        animation: animation,
        typeTap: typeTap,
      });
    } else {
      collectionsArr[collectionId].push({
        uniqueId: uniqueId,
        loaded: false,
        visible: false,
        ref,
        animationEnd: false,
        animation: animation,
        typeTap: typeTap,
      });
    }
  }
};

const setVisible = (collectionId: string, currentIndex: number, pathname: string) => {
  if (pathname !== window.location.pathname) return;

  const gameCollectionArr = collectionsArr[collectionId];
  if (gameCollectionArr && gameCollectionArr[currentIndex]) {
    const prevElement = currentIndex - 1;
    const nextElement = currentIndex + 1;
    const { visible, animationEnd } = gameCollectionArr[currentIndex] || {};
    const {
      visible: prevVisible,
      animationEnd: prevAnimationEnd,
      loaded: prevLoaded,
    } = gameCollectionArr[prevElement] || {};
    if (currentIndex === 0 && !visible && !animationEnd) {
      //FIRST CELL
      showCell(collectionId, currentIndex);
      setVisible(collectionId, nextElement, pathname);
    } else if (prevVisible && prevAnimationEnd) {
      showCell(collectionId, currentIndex);
      setVisible(collectionId, nextElement, pathname);
    } else if (prevLoaded && !prevVisible && !prevAnimationEnd) {
      setVisible(collectionId, prevElement, pathname);
    }
  }
};

const reset = (collectionId: string, currentIndex) => {
  if (collectionsArr[collectionId][currentIndex]) {
    const ref = collectionsArr[collectionId][currentIndex].ref;
    if (ref && ref.current) {
      ref.current.classList.add(`${cssGamificationNS}__image--loading`);
    }
    collectionsArr[collectionId][currentIndex] = {
      ...collectionsArr[collectionId][currentIndex],
      loaded: false,
      visible: false,
      ref: null,
      animationEnd: false,
    };
  }
};

export const useLoadingEffect = (
  uniqueId: string,
  order: number,
  gameCellRef: React.MutableRefObject<HTMLDivElement | null>,
  collectionId: string,
  typeTap,
  animation: string = gridAnimationDefault.FADE_IN,
  queryType?: CategoryQueryType,
  skipLazyLoad?: boolean,
): { hasFinishedLoading: () => void; onAnimationStart: () => void } => {
  useLayoutEffect(() => {
    if (gameCellRef.current) {
      gameCellRef.current.classList.add(`${cssGamificationNS}__image--loading`);
      setInitial(collectionId, uniqueId, animation, gameCellRef, order, typeTap);
      if (skipLazyLoad || collectionsArr[collectionId][order]) {
        forcedShowCell({ collectionId, uniqueId, order });
      }
    }

    return () => {
      if (!collectionsArr[collectionId]) {
        return;
      }
      const currentIndex = getCurrentIndex(collectionId, uniqueId);
      if (location.pathname.includes(collectionsArr[collectionId]?.[currentIndex]?.typeTap)) {
        collectionsArr[collectionId].splice(currentIndex, 1);
      } else {
        reset(collectionId, currentIndex);
      }
    };
  }, [collectionId, uniqueId, typeTap]);

  return {
    hasFinishedLoading: useCallback(() => {
      const currentIndex = getCurrentIndex(collectionId, uniqueId);
      setLoaded(collectionId, currentIndex);
      setVisible(collectionId, currentIndex, window.location.pathname);
    }, [collectionId, uniqueId]),
    onAnimationStart: useCallback(() => {
      onAnimationStart(collectionId, uniqueId);
    }, [collectionId, uniqueId]),
  };
};
