import clsx from 'clsx';
import { Link } from 'react-router-dom';
import config from 'utils/config';

interface AppSectionImageProps {
  /** Image location */
  imgRef: string | null;
  /** Image alt text */
  alt: string | undefined;
  /** Link Text */
  linkRoute: string | null;
  staticRoute: string | null;
}

const AppSectionImage: React.FC<AppSectionImageProps> = ({ imgRef, alt, linkRoute, staticRoute }) => {
  const imageLink = `${config.CDN_URL}/${imgRef}`;
  const logoClass = clsx(`footer-logo footer-logo--app`);
  const altProp = alt || '';

  if (!imgRef) return null;
  return (
    <>
      {staticRoute ? (
        <a className={logoClass} target="_blank" href={staticRoute} rel="noreferrer" title={altProp}>
          <img className="wh-100" src={imageLink} alt={altProp}></img>
        </a>
      ) : (
        <Link className={logoClass} to={linkRoute ? `/${linkRoute}` : '/'}>
          <img className="wh-100" src={imageLink} alt={altProp}></img>
        </Link>
      )}
    </>
  );
};

export default AppSectionImage;
