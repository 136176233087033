import { useEffect } from 'react';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { printCashPayDeposit } from 'modules/retail/modules/cashier/printMethods/cashPayPrint.utils';
import { selectCashierData } from 'modules/retail/modules/cashier/slice/cashier.slice';
import { sessionBalanceThunk } from 'modules/retail/modules/ssbt/features/sessionBalance/slice/sessionBalance.slice';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { useAppDispatch, useAppSelector } from 'store';
import { isTerminal } from 'utils/common/helpersCommon';
import { clearDeposit, selectCashPay } from '../../../slice/cashPay.slice';

export const useDeposit = () => {
  const verifyPlayerDeposit = useAppSelector(selectCashPay.verifyPlayerDeposit);
  const verifyPlayerDepositStatus = useAppSelector(selectCashPay.verifyPlayerDepositStatus);
  const processDeposit = useAppSelector(selectCashPay.processDeposit);
  const processDepositStatus = useAppSelector(selectCashPay.processDepositStatus);
  const posInfo = useAppSelector(selectCashierData.posInfo);
  const numOfCopies = 2;

  const retailAddress = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.RETAIL_ADDRESS,
    deviceLayout: 'general',
  });
  const isCurrencyBeforeAmount = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.CURRENCY_BEFORE_AMOUNT,
    deviceLayout: 'general',
  });
  const dispatch = useAppDispatch();

  const posAddress = posInfo?.posInfo.address;

  useEffect(() => {
    return () => {
      dispatch(clearDeposit());
    };
  }, []);

  useEffect(() => {
    if (processDeposit?.success && verifyPlayerDeposit?.eligibleForDeposit) {
      if (isTerminal()) {
        dispatch(sessionBalanceThunk.checkForUpdateBalance());
      }
      const printCashPayDepositObj = {
        data: verifyPlayerDeposit,
        address: retailAddress,
        posAddress,
        transferId: processDeposit?.transferId,
        numOfCopies,
        isCurrencyBeforeAmount,
      };
      printCashPayDeposit(printCashPayDepositObj);

      dispatch(clearDeposit());
    }
  }, [processDeposit]);

  return { verifyPlayerDeposit, verifyPlayerDepositStatus, processDeposit, processDepositStatus };
};
