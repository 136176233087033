import { useMediaQuery } from 'react-responsive';
import PrizeDropDesktopView from 'components/shared/prizeDrop/Preview/PrizeDropDesktopView';
import PrizeDropMobileView from 'components/shared/prizeDrop/Preview/PrizeDropMobileView';
import { useCDNIconSprites } from 'hooks/common/useCDNIconSprites';
import { usePrizeDropCampaigns } from './hooks/usePrizeDropCampaigns';

export const PrizeDrop = () => {
  useCDNIconSprites('przdIcons', 'przd_icons_timestamp', 'przd-icons', 'common-content/prize-drop/sprites.svg');

  const isMobile = useMediaQuery({ query: `(max-width: 900px)` });

  const { isLoading } = usePrizeDropCampaigns();

  if (isLoading) return null;

  if (isMobile) {
    return <PrizeDropMobileView />;
  }

  return <PrizeDropDesktopView />;
};
