import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CasinoWidgetsTypes } from 'modules/casino/modules/container/widget/types/casinoWidgetsTypes';
import { Icon } from 'modules/casino/shared/components/icons';
import { Flex } from 'modules/casino/shared/components/layout';

interface Props {
  widget: CasinoWidgetsTypes.Widget;
}

const WidgetLobbyAction: React.FC<Props> = ({ widget }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClickHandler = () => {
    if (widget.navigation?.route) {
      navigate(widget.navigation.route);
    }
  };

  return widget.navigation?.route ? (
    <Flex.AC className="lobby-category-header__link" onClick={onClickHandler}>
      <span>{t('CasinoLobby.CategoryHeader.viewAllGamesText')}</span>
      <Icon className="rotated-270 lobby-category-header__link-icon" icon="chevron" />
    </Flex.AC>
  ) : (
    <></>
  );
};

export default WidgetLobbyAction;
