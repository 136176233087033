import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { UI } from 'components/shared/UI';
import { BurgerUI } from 'shared/common/features/burger/components/BurgerUI';
import { BurgerMenuLayoutMenuItems02 } from 'shared/common/features/navigationMenu/types/navigationMenu.types';
import { useAppDispatch } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { Drawer } from 'utils/common/translationUtils/translationStrings';
import usePrefetchSportContainers from './hooks/usePrefetchSportContainers';
import { useGetDrawerSportItems } from '../../hooks/useGetDrawerSportItems';
import useHandleDrawerSportClick from '../../hooks/useHandleDrawerSportClick';
import { clearSportSection } from '../../slice/drawer.slice';
import { SportsType } from '../../types/drawer.types';

type SportProps = {
  section: BurgerMenuLayoutMenuItems02;
};

const SportDrawerSectionLayout02: React.FC<SportProps> = ({ section }) => {
  usePrefetchSportContainers(true);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const { items, activeItem } = useGetDrawerSportItems(SportsType.Sport);
  const { handleSportClick } = useHandleDrawerSportClick();
  const [isShowAll, setIsShowAll] = useState(false);
  const sportItems = isShowAll ? items : items.slice(0, 12);
  const toggleShowAll = () => {
    setIsShowAll(!isShowAll);
  };

  useEffect(() => {
    return () => {
      dispatch(clearSportSection());
    };
  }, []);
  if (isEmpty(sportItems)) return null;
  return (
    <BurgerUI.TilesWrapper>
      <BurgerUI.TilesGrid>
        {sportItems.map((tile, index) => (
          <BurgerUI.SportTile
            key={index}
            icon={tile.id.toString()}
            text={tile.name}
            isActive={activeItem === tile.id && location.pathname.includes(section?.route)}
            liveBadgeText={tile.liveEventsCount.toString()}
            onClick={() => handleSportClick({ sportType: SportsType.Sport, sportId: tile.id })}
            showLiveBadge={false}
          />
        ))}
      </BurgerUI.TilesGrid>
      {!isEmpty(sportItems) && (
        <UI.Button
          variant="secondary"
          size="sm"
          className="mx-auto mb-2 px-4"
          onClick={toggleShowAll}
          text={isShowAll ? t(Drawer.DrawerSection.viewLessText) : t(Drawer.DrawerSection.viewMoreText)}
          icon="chevron"
          iconClassName={isShowAll ? 'rotated-180' : ''}
        />
      )}
    </BurgerUI.TilesWrapper>
  );
};

export default SportDrawerSectionLayout02;
