import { useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { setHideSearchIcon } from 'shared/common/features/appHeader';
import { useAppDispatch } from 'store';
import { isTerminal } from 'utils/common/helpersCommon';

const useSearchIcon = (): void => {
  const isVirtual = !!useMatch('/virtual/*');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isVirtual) {
      dispatch(setHideSearchIcon(true));
      return () => {
        if (!isTerminal()) {
          dispatch(setHideSearchIcon(false));
        }
      };
    }
  }, [dispatch, isVirtual]);

  useEffect(() => {
    if (isTerminal()) {
      dispatch(setHideSearchIcon(true));
    }
  }, [dispatch]);
};

export default useSearchIcon;
