import { useEffect } from 'react';
import { useAuthentication } from 'hooks/common/useAuthentication';
import { getUserFavourites } from 'shared/common/features/drawer/components/favourites/utils/favourites.utils';
import { useAppDispatch } from 'store';

export const useFetchUserFavourites = (): void => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuthentication();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserFavourites());
    }
  }, [dispatch, isAuthenticated]);
};
