import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalPriorityEnum, RootModalState, RootModalType } from 'shared/common/features/rootModal/types/modal.types';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { isEmpty, isTerminal } from 'utils/common/helpersCommon';

const initialState: RootModalState = {
  modalType: null,
  modalProps: {},
  modalPriority: null,
  modalId: null,
  modalsQueue: {},
};

const rootModal = createSlice({
  name: 'rootModal',
  initialState,
  reducers: {
    showRootModal(state, action: PayloadAction<RootModalType>) {
      const { modalType, modalProps, modalPriority, modalId } = action.payload;

      if (isEmpty(modalType)) return;

      const isCurrHighPriorityModal =
        state.modalType !== null && state.modalPriority !== null && state.modalPriority >= modalPriority;

      if (isCurrHighPriorityModal && modalId !== state.modalId && !isTerminal()) {
        state.modalsQueue[modalId] = action.payload;
        return;
      }

      state.modalType = modalType;
      state.modalProps = modalProps;
      state.modalPriority = modalPriority;
      state.modalId = modalId;

      if (state.modalsQueue[modalId]) delete state.modalsQueue[modalId];
    },
    // Use only for other than grand modal type.
    hideRootModal(state) {
      if (state.modalPriority !== ModalPriorityEnum.GRAND) {
        state.modalType = null;
        state.modalProps = null;
        state.modalPriority = null;
        state.modalId = null;
      }
    },
    // Use only for grand modal type close.
    hideRootGrandModal(state) {
      state.modalType = null;
      state.modalProps = null;
      state.modalPriority = null;
      state.modalId = null;
    },
    clearModalsQueue(state) {
      state.modalsQueue = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, () => initialState);
  },
});

export const { showRootModal, hideRootModal, hideRootGrandModal, clearModalsQueue } = rootModal.actions;

export default rootModal.reducer;

export const selectRootModal = {
  type: (state: RootState) => state.common.rootModal.modalType,
  props: (state: RootState) => state.common.rootModal.modalProps,
  priority: (state: RootState) => state.common.rootModal.modalPriority,
  modalsQueue: (state: RootState) => state.common.rootModal.modalsQueue,
};
