import userStorage from 'pages/auth/login/UserStorage';

export const timeMsMap = {
  seconds: 1000,
  minutes: 1000 * 60,
  hours: 1000 * 60 * 60,
  days: 1000 * 60 * 60 * 24,
} as const;

export const addToDate = (timestamp: number, amount: number, type: keyof typeof timeMsMap): number =>
  timestamp + amount * timeMsMap[type];

export const addTzOffsetToDate = (
  timestamp: number,
  tzOffset: number = Number(userStorage.getUserTimeZone() ?? 0),
): number => timestamp + tzOffset * 60_000;

/**
 * catches: 7:44   0:22, Tomorrow 2:22 AM, 08.10, 0:00
 * doesn't catch: 12:34    10:22 Today, 11:00 AM
 */
const hourSingleDigitPattern = /(,*)(^|\D)(\d:)/;
export const addLeadingZero = (date: string | number): string => {
  if (typeof date === 'string') {
    const replacedDate = date.replace(hourSingleDigitPattern, `$1$20$3`);
    return replacedDate;
  }
  if (date < 10) {
    return `0${date}`;
  }

  return date.toString();
};

const round = (num: number) => {
  return parseFloat(num.toFixed(1));
};

export const dateDiffer = {
  seconds(date1: number, date2: number): number {
    const diff = Math.floor(date1 - date2);
    return round(diff / timeMsMap.seconds);
  },
  minutes(date1: number, date2: number): number {
    const diff = Math.floor(date1 - date2);
    return round(diff / timeMsMap.minutes);
  },
  hours(date1: number, date2: number): number {
    const diff = Math.floor(date1 - date2);
    return round(diff / timeMsMap.hours);
  },
  /**
   *  This diff takes the hours of the dates into account
   *  to calculate the diff. If one date is 23:30 and the
   *  other one is 00:30 on the following day. The diff
   *  is just one hour, however one day has passed
   *  and this diff will return 1
   */
  daysPassedFromNowUTC(timestamp: number, tzOffset?: number): number {
    const now = addTzOffsetToDate(Date.now(), tzOffset);
    const currentHours = new Date(now).getUTCHours();
    const hoursDiff = Math.floor(dateDiffer.hours(timestamp, now));
    const exactDays = Math.floor((currentHours + hoursDiff) / 24);

    // console.log('  ~ getExactDayDiffFromNow ~ now', new Date(now).toUTCString());
    // console.log('  ~ getExactDayDiffFromNow ~ currentHours', currentHours);
    // console.log('  ~ getExactDayDiffFromNow ~ hoursDiff', hoursDiff);
    // console.log('  ~ getExactDayDiffFromNow ~ days', Math.floor(days));
    return exactDays;
  },
};

export type TimeDataType = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  status?: string;
};

export const getRemainingTime = (futureDate: string | number, timeNow = new Date().getTime()) => {
  const diffInMs = new Date(futureDate).getTime() - timeNow;

  return getTimeData(diffInMs);
};

export const getUserLocalTime = () => {
  const miliseconds = 60000;
  // Local storage offset minutes
  const userOffsetMinutes = Number(userStorage.getUserTimeZone());
  const userOffsetMiliseconds = userOffsetMinutes * miliseconds;

  // Date in system timezone
  const systemDateNow = new Date();

  // Calc UTC from system date
  const utcDateNow = systemDateNow.getTime() + systemDateNow.getTimezoneOffset() * miliseconds;

  // User local date (may not be equal to system local date)
  const userLocalDate = new Date(utcDateNow + userOffsetMiliseconds);

  // Return user local time
  return userLocalDate.getTime();
};

export const getTimeData = (timeInMilisec: number): TimeDataType => {
  const days = Math.floor(timeInMilisec / timeMsMap.days);
  const hours = Math.floor((timeInMilisec % timeMsMap.days) / timeMsMap.hours);
  const minutes = Math.floor((timeInMilisec % timeMsMap.hours) / timeMsMap.minutes);
  const seconds = Math.floor((timeInMilisec % timeMsMap.minutes) / timeMsMap.seconds);
  return { days, hours, minutes, seconds };
};

export const calcTimeToday = (offset: number) => {
  // create Date object for current location
  const todayDate = new Date();

  // convert to msec
  // subtract local time zone offset
  // get UTC time in msec
  const miliseconds = 60000;
  const utc = todayDate.getTime() + todayDate.getTimezoneOffset() * miliseconds;

  // create new Date object for different city
  // using supplied offset
  const supliedMiliseconds = 3600000;
  const localDate = new Date(utc + supliedMiliseconds * offset);
  return new Date(localDate).getDate();
};

export const calcTimeTomorrow = (offset: number) => {
  // create Date object for current location
  const tomorrowDate = new Date();

  // convert to msec
  // subtract local time zone offset
  // get UTC time in msec
  const miliseconds = 60000;
  const utc = tomorrowDate.getTime() + tomorrowDate.getTimezoneOffset() * miliseconds;

  // create new Date object for different city
  // using supplied offset
  const supliedMiliseconds = 3600000;
  const localDate = new Date(utc + supliedMiliseconds * offset);

  // return time as a string
  return new Date(localDate.setDate(localDate.getDate() + 1)).getDate();
};
