export const eventOptions = { alignment: 'right', size: 'medium', effect: 'normal' };
export const eventOptionsBold = { alignment: 'right', size: 'medium', effect: 'bold' };
export const _optionsData = { alignment: 'left', size: 'medium', effect: 'normal' };
export const _optionsDataBold = { alignment: 'left', size: 'medium', effect: 'bold' };
export const _logoOptionsData = { alignment: 'center', size: 'large', effect: 'bold' };
export const winbetOptions = { alignment: 'center', size: 'medium', effect: 'normal' };
export const _addressOptions = { alignment: 'center', size: 'large', effect: 'bold' };
export const winbetAddress = { alignment: 'center', size: 'medium', effect: 'normal' };
export const voucherValueOptions = { alignment: 'center', size: 'medium', effect: 'bold' };
// const port = 'http://localhost:10000';
