import React, { RefObject, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { gamesSelectors } from 'modules/casino/modules/container/cellsContainer/cellls/slice/casinoGamesSlice';
import { disableSaveScrollPositionMap } from 'modules/casino/modules/container/cellsContainer/grid/components/gridCell/gridCellConstants';
import { rowsGridNumber } from 'modules/casino/modules/container/cellsContainer/grid/constants/gridContants';
import { CategoryQueryType } from 'modules/casino/shared/constants';
import { setCollectionLoadMore } from 'modules/casino/shared/features/casinoUi/casinoUiStateSlice';
import { clearCellScrollData, getCellPosition } from 'modules/casino/shared/utils/casinoUtils';
import { useAppDispatch, useAppSelector } from 'store';
import { useSetNextElements } from './useSetNextElements';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { favoriteGamesSelectors } from '../../cellls/additionalGames/favourites/slice/casinoFavouriteGamesSlice';
import { GridLayouts, GridLoadingBehaviorType, GridRowType } from '../types/gridTypes';

type Props = {
  children: (
    itemId: string,
    key: number,
    isLastItem: boolean,
    gridPage: number,
    gamesRenderedCount: number,
    skipLazyLoad: boolean,
  ) => React.ReactNode;
  categoryLayout: CategoryLayoutTypes;
  collectionId: string;
  gamesIds: string[];
  totalElements: number;
  layoutRows: GridRowType;
  fetchMoreGames: () => void;
  subType: CategorySubTypes;
  gridLoadingBehavior: GridLoadingBehaviorType;
  layoutRef: RefObject<HTMLDivElement> | null;
  layoutGrid: GridLayouts;
  query: CategoryQueryType;
};

export const useHandleVerticalItems = ({
  children,
  categoryLayout,
  collectionId,
  gamesIds,
  totalElements,
  layoutRows,
  fetchMoreGames,
  subType,
  gridLoadingBehavior,
  layoutRef,
  layoutGrid,
  query,
}: Props) => {
  const dispatch = useAppDispatch();
  const disableSaveScrollPosition = subType ? disableSaveScrollPositionMap.includes(subType) : false;
  const cell = getCellPosition(disableSaveScrollPosition);
  const layoutColumn = useRef<number>(0);
  const gridPageRef = useRef<number>(1);
  const savedElementsCountRef = useRef<number>(0);
  const onColumnChangeFetch = useRef<boolean>(false);
  const restoreScrollPosition = useRef<boolean>(false);
  const rows = rowsGridNumber[layoutRows];
  const favGamesUpdate = useAppSelector(favoriteGamesSelectors.favoriteGamesCount);
  const categoryTtl = useAppSelector((state) => gamesSelectors.categoryTtl(state, collectionId));

  const favGamesUpdateCountRef = useRef<number>(favGamesUpdate);
  const initialCategoryTtl = useRef<number>(categoryTtl);
  const {
    cellElements,
    columnChangeUpdate,
    loadMoreDataUpdate,
    initialCreate,
    favouritesUpdate,
    svaraGamesUpdate,
    restoreToScrollPosition,
    clearCellElements,
  } = useSetNextElements({
    children,
    gamesIds,
    layoutRows,
    totalElements,
    collectionId,
    layoutGrid,
  });

  const getColumns = () => {
    if (layoutRef?.current) {
      return window.getComputedStyle(layoutRef.current).getPropertyValue('grid-template-columns').split(' ').length;
    }
    return 5;
  };

  useLayoutEffect(() => {
    if (onColumnChangeFetch.current) {
      columnChangeUpdate(getColumns(), gridPageRef.current);
      onColumnChangeFetch.current = false;
    }
    const onResize = () => {
      if (layoutColumn.current !== getColumns()) {
        layoutColumn.current = getColumns();
        dispatch(setCollectionLoadMore({ collectionId, value: true }));
        if (hasNeedFetchFromResize()) {
          fetchMoreGames();
          onColumnChangeFetch.current = true;
        } else {
          gridPageRef.current = Math.ceil(getGeneratedElementsCount() / (rows * getColumns()));
          columnChangeUpdate(getColumns(), gridPageRef.current);
        }
      }
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [gamesIds, totalElements, gridLoadingBehavior, cellElements, layoutRef]);
  useEffect(() => {
    if (cell && cell?.collectionId === collectionId) {
      if (cell?.gridPage && cell?.gamesRenderedCount) {
        const gamesCount = cell?.gamesRenderedCount;
        gridPageRef.current = Math.ceil(gamesCount / (rows * getColumns()));
        restoreScrollPosition.current = true;
      }
    }
  }, [cell, cell?.collectionId, collectionId]);
  useEffect(() => {
    layoutColumn.current = getColumns();
    if (restoreScrollPosition.current) {
      restoreToScrollPosition(getColumns(), gridPageRef.current, true);
      restoreScrollPosition.current = false;
    } else {
      initialCreate(getColumns());
    }

    return () => {
      gridPageRef.current = 0;
      savedElementsCountRef.current = 0;
      clearCellElements();
    };
  }, [collectionId]);
  useEffect(() => {
    if (
      (categoryLayout === CategoryLayoutTypes.FAVOURITES_CATEGORY ||
        categoryLayout === CategoryLayoutTypes.FAVOURITES_MODAL_CATEGORY ||
        categoryLayout === CategoryLayoutTypes.IN_GAME_FAVOURITES_CATEGORY) &&
      favGamesUpdate !== favGamesUpdateCountRef.current
    ) {
      favouritesUpdate(getColumns(), gridPageRef.current);
      favGamesUpdateCountRef.current = favGamesUpdate;
    }
  }, [favGamesUpdate, categoryLayout]);
  useEffect(() => {
    if (query === CategoryQueryType.SVARA_CATEGORY && initialCategoryTtl.current !== categoryTtl) {
      svaraGamesUpdate(getColumns(), gridPageRef.current);
      initialCategoryTtl.current = categoryTtl;
    }
  }, [categoryTtl]);

  const getGeneratedElementsCount = () => {
    return cellElements[collectionId] ? cellElements[collectionId].length : 0;
  };

  const getGeneratedElements = () => {
    return cellElements[collectionId] || <></>;
  };

  const stopLoading = () => {
    if (
      (categoryLayout === CategoryLayoutTypes.FAVOURITES_CATEGORY ||
        categoryLayout === CategoryLayoutTypes.FAVOURITES_MODAL_CATEGORY) &&
      Math.abs(getGeneratedElementsCount() - totalElements) === 1
    ) {
      //Not all games are shown
      if (totalElements > getGeneratedElementsCount()) {
        //True if we add game to favourites. False if we are waiting for the last game to load
        return favGamesUpdate > favGamesUpdateCountRef.current;
      } else {
        //Last game is shown and we remove game from favourites
        return true;
      }
    }
    return getGeneratedElementsCount() == totalElements;
  };

  const isInitialPage = () => {
    return getGeneratedElementsCount() === 0;
  };

  const hasNeedFetch = () => {
    const nextPortion = getColumns() * rows;
    return (
      gamesIds.length - (getGeneratedElementsCount() + nextPortion) <= nextPortion && gamesIds.length !== totalElements
    );
  };
  const hasNeedFetchFromResize = () => {
    const nextPortion = getColumns() * rows;
    const nextGameSize = getColumns() * rows * gridPageRef.current;
    return gamesIds.length - (nextGameSize + nextPortion) <= nextPortion && gamesIds.length !== totalElements;
  };

  const loadMoreData = useCallback(() => {
    gridPageRef.current = gridPageRef.current += 1;
    loadMoreDataUpdate(getColumns(), gridPageRef.current);
    clearCellScrollData();
    if (hasNeedFetch()) {
      fetchMoreGames();
    }
  }, [gamesIds, totalElements, gridLoadingBehavior, cellElements, layoutRef]);

  return {
    getGeneratedElements,
    getGeneratedElementsCount,
    stopLoading,
    isInitialPage,
    gridPage: gridPageRef.current,
    loadMoreData,
  };
};
