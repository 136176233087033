import { isEmpty, toDecimal } from 'utils/common/helpersCommon';
import { BetStatuses, MyBets, SelectionStatuses } from '../types/myBets.types';

export const defaultEventStatusTemplate = {
  eventClock: {
    eventTime: '00:00',
    remainingTime: null,
    remainingTimeInPeriod: null,
    stoppageTime: null,
    stoppageTimeAnnounced: null,
    stopped: false,
  },
  awayScore: '0',
  homeScore: '0',
  eventStatus: '',
  matchStatus: '',
  periodScore: [],
  scoreChange: '',
  sportSpecificProperties: {
    homeGameScore: null,
    awayGameScore: null,
    awayLegScore: null,
    currentServer: '',
    delivery: null,
    homeLegScore: null,
    possession: null,
    visit: null,
  },
  lastUpdate: Date.now(),
};

const getSportSpecificProperties = (sportEvent) => {
  return {
    homeGameScore: sportEvent.sportSpecificProperties?.homeGameScore || null,
    awayGameScore: sportEvent.sportSpecificProperties?.awayGameScore || null,
    awayLegScore: sportEvent.sportSpecificProperties?.awayLegScore || null,
    currentServer: sportEvent.sportSpecificProperties?.currentServer || '',
    delivery: sportEvent.sportSpecificProperties?.delivery || null,
    homeLegScore: sportEvent.sportSpecificProperties?.homeLegScore || null,
    possession: sportEvent.sportSpecificProperties?.possession || null,
    visit: sportEvent.sportSpecificProperties?.visit || null,
  };
};

const getEventClock = (sportEvent) => {
  return {
    ...defaultEventStatusTemplate.eventClock,
    eventTime: sportEvent.eventTime || '',
    remainingTime: sportEvent.remainingTime === '0:00' ? null : sportEvent.remainingTime || '',
    remainingTimeInPeriod: sportEvent?.remainingTimeInPeriod || '',
    stoppageTime: sportEvent?.stoppageTime || '',
  };
};

export const getDefaultEventClockStatus = (sportEvent) => {
  if ((sportEvent.eventStatus && sportEvent.periodScoresData === null) || !sportEvent.eventStatus) {
    return { ...defaultEventStatusTemplate };
  }

  return {
    eventClock: getEventClock(sportEvent),
    periodScore: [...(sportEvent.periodScoresData || [])],
    homeScore: sportEvent.homeScore || '',
    awayScore: sportEvent.awayScore || '',
    eventStatus: sportEvent.eventStatus || '',
    matchStatus: sportEvent.matchStatus || '',
    sportSpecificProperties: getSportSpecificProperties(sportEvent) || null,
    scoreChange: '',
    lastUpdate: Date.now(),
  };
};

export const normalizeMyBetsData = (playerBets: MyBets.BetContent[]): MyBets.NormalizedBetsMap => {
  return playerBets.reduce((acc, curr) => {
    acc[curr.bet.id] = {
      ...curr,
      isCashOutLoading: false,
      fullCashoutStake: null,
      minCashoutStake: null,
      hasReoffer: false,
      eventIds: curr.outcomes.map((outcome) => outcome.sportEventId),
    };
    return acc;
  }, {});
};

export const normalizeEventsData = (playerBets: MyBets.BetContent[]): MyBets.NormalizedOutcomeEventMap => {
  return playerBets.reduce((acc, bet) => {
    bet?.outcomes?.forEach((outcome) => {
      acc[outcome.sportEventId] = {
        ...outcome,
        eventClockStatus: getDefaultEventClockStatus(outcome),
      };
    });
    return acc;
  }, {});
};

export const getSelectionsFeedProducerIds = (bet: MyBets.BetContent): number[] => {
  const feedProducerIds = bet?.outcomes.map((outcome) => outcome.feedProducerId);
  return Array.from(new Set(feedProducerIds));
};

export const getSelection = (outcomes: MyBets.OutcomeEvent[]): MyBets.CashoutCalculatorSelections[] => {
  const selection = outcomes.map((outcome, index) => {
    return {
      wageringEventId: outcome.sportEventId.toString(),
      outcomeOdds: outcome.odds,
      banker: outcome.banker,
      selectionPosition: index,
      outcomeId: outcome.id,
      isLoss: outcome.selectionStatus === SelectionStatuses.LOST || outcome.currentProbability === 0 ? true : false,
      probability: outcome.probability,
      currentProbability: outcome.currentProbability,
      selectionStatus: outcome.selectionStatus,
      marketId: outcome.marketId,
      feedProducerId: outcome.feedProducerId,
    };
  });

  return selection;
};

export const getEmptyMessage = (activeTab: MyBets.BetTabCategory): string | undefined => {
  switch (activeTab) {
    case 'ALL':
      return 'noBetsText';
    case 'LIVE':
      return 'noLiveBetsText';
    case 'SETTLED':
      return 'noSettledText';
    case 'UNSETTLED':
      return 'noUnsettledText';
    default:
      break;
  }
};

export const PAGE_NUMBER = 0;
export const SORTING = 'DESC';
export const WEBSOCKET_RECCONECT = true;

export const filterBetByCategory = (
  activeBetTabCategory: MyBets.BetTabCategory,
  betStatus: BetStatuses,
  hasLiveSelection: boolean,
): boolean => {
  if (betStatus === 'REJECTED') return false;
  if (
    activeBetTabCategory === 'ALL' ||
    (activeBetTabCategory === 'UNSETTLED' && betStatus === 'ACCEPTED') ||
    (activeBetTabCategory === 'SETTLED' && betStatus !== 'ACCEPTED') ||
    (activeBetTabCategory === 'LIVE' && hasLiveSelection && betStatus === 'ACCEPTED')
  ) {
    return true;
  }

  return false;
};

type GetShowAccumulatorBonus = {
  boostBonus?: MyBets.Campaign;
  betStatus: MyBets.BetStatus;
  outcomeStatus: MyBets.OutcomeStatuses;
};

const NOT_QUALIFY_BET_STATUSES = ['REJECTED', 'CASHED_OUT', 'CANCELED'];

export const getShowAccumulatorBonus = ({ boostBonus, betStatus, outcomeStatus }: GetShowAccumulatorBonus): boolean => {
  if (isEmpty(boostBonus) || NOT_QUALIFY_BET_STATUSES.includes(betStatus)) return false;
  if (betStatus === 'SETTLED' && outcomeStatus !== 'WON' && outcomeStatus !== 'PARTIAL_WON') return false;

  return true;
};

export const getShowAccaInsuranceBonus = ({
  accaInsuranceBonus,
  betStatus,
}: {
  accaInsuranceBonus?: MyBets.Campaign;
  betStatus: BetStatuses;
}): boolean => {
  if (isEmpty(accaInsuranceBonus) || NOT_QUALIFY_BET_STATUSES.includes(betStatus)) return false;
  return true;
};

export const getBetTotalWin = ({ winLong, totalWinLong }: { winLong: number; totalWinLong: number }) => {
  if (isEmpty(totalWinLong) || totalWinLong === 0) return toDecimal(winLong);
  return toDecimal(totalWinLong);
};

// export const onlyUnique = (value, index, self) => {
//   return self.indexOf(value) === index;
// };
