import styled from 'styled-components';
import { Breakpoints } from 'modules/casino/theme/Theme';

const MetaInfo = styled.div.attrs({
  className: 'live-cell__meta d-flex-ac-jb',
})`
  padding: 5px 6px;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    padding: 9px 6px;
  }
`;
export default MetaInfo;
MetaInfo.displayName = 'MetaInfo';
