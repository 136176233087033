import { useEffect, memo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigationType, NavigationType } from 'react-router-dom';
import { usePrevious } from 'hooks/usePrevious';
import { hardwareBackPress } from 'shared/common/sharedSlices/commonActions';
import { useAppDispatch } from 'store';
import { Breakpoints } from 'theme/Theme';

const MobileBackHandler = () => {
  const navigationType: NavigationType = useNavigationType();
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isTabletOrMobile}px)` });
  const location = useLocation();
  const path = `${location.pathname}${location.search}`;
  const previousPath = usePrevious(path);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isTabletOrMobile && previousPath && path !== previousPath && navigationType === 'POP') {
      dispatch(hardwareBackPress());
    }
  }, [dispatch, isTabletOrMobile, location, navigationType, path, previousPath]);
  return <></>;
};

export default memo(MobileBackHandler);
