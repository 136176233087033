import { createSlice } from '@reduxjs/toolkit';
import { setLanguage } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { LayoutsState } from '../types/layout.types';

const initialState: LayoutsState = {
  hasLayoutInit: false,
};

const appLayouts = createSlice({
  name: 'appLayoutsSlice',
  initialState,
  reducers: {
    setHasAppLayoutInit(state) {
      state.hasLayoutInit = true;
    },
    resetAppLayoutsData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(setLanguage, (state) => {
      state.hasLayoutInit = false;
    });
  },
});

export const { setHasAppLayoutInit, resetAppLayoutsData } = appLayouts.actions;

export default appLayouts.reducer;

export const selectAppLayouts = {
  hasInitilized: (state: RootState): boolean => state.common.appLayouts.hasLayoutInit,
};
