import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormButton, FormControl } from 'components/shared/forms/components';
import { FieldValues } from 'components/shared/forms/formTypes';
import { Flex } from 'components/shared/layout';
import { Modal } from 'components/shared/Modal';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { selectLanguage } from 'shared/common/features/settings/slice/settings.slice';
import { ResendCode } from 'shared/common/features/twoFactorVerification/components';
import { CodeError } from 'shared/common/features/twoFactorVerification/components/CodeError';
import CountDownTimer from 'shared/common/features/twoFactorVerification/components/CountDownTimer';
import { useCode } from 'shared/common/features/twoFactorVerification/hooks/useCode';
import { hideString } from 'shared/common/features/twoFactorVerification/utils/twoFactorVerification.utils';
import { useAppDispatch, useAppSelector } from 'store';
import { MyAccountTranslation, MyProfile } from 'utils/common/translationUtils/translationStrings';
import { requestChangePassword, resetMfaCheckCode, selectForgotPassword } from './slice/forgotPassword.slice';
import { defaultOtpInput } from '../phoneConfirmation/utils/poneConfirmation.utils';

const sendType = {
  email: MyAccountTranslation.MFA.email,
  sms: MyAccountTranslation.MFA.sms,
  phoneCall: MyAccountTranslation.MFA.phoneCall,
};

const MFA_ForgottenPassword: React.FC<{
  data: FieldValues;
}> = ({ data }) => {
  const isLoading = useAppSelector(selectForgotPassword.isLoadingCheckCode);
  const isValidMfaCode = useAppSelector(selectForgotPassword.isValidMfaCode);
  const mfaCoolOff = useAppSelector(selectForgotPassword.mfaCoolOff);
  const language = useAppSelector(selectLanguage);
  const field = defaultOtpInput({ inputMode: 'text', replacePattern: '-' });
  const mfaChannel = useAppSelector(selectForgotPassword.mfaChannel); // default - email
  const phoneNumber = useAppSelector(selectForgotPassword.phoneNumber);
  const email = useAppSelector(selectForgotPassword.email);

  const mfaText = {
    email: email,
    sms: phoneNumber,
  };

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { closeModal } = useHandleModals();

  const userMfaType = hideString({ text: mfaText?.[mfaChannel], type: mfaChannel });
  const formProps = useForm({ mode: 'onChange' });
  const { reset, handleSubmit } = formProps;

  const { setHasError, hasError, disableWriteCode, isCoolOffExpired, timerIsFinnish } = useCode({
    mfaCoolOff,
    isLoading,
    reset,
    isValidMfaCode,
  });

  const handleSendCode = useCallback(
    (fields) => {
      if (!hasError && fields.confirmationCode && !isLoading) {
        dispatch(requestChangePassword({ data, language, mfaCode: fields.confirmationCode })).then((action) => {
          if (action.meta.requestStatus === 'fulfilled' && !action.payload) {
            dispatch(hideRootModal());
          }
        });
      }
    },
    [dispatch, data, language, hasError, isLoading],
  );

  const handleResendChannel = useCallback(() => {
    if (!isLoading && isCoolOffExpired) {
      dispatch(requestChangePassword({ data, language })).then(() => {
        timerIsFinnish(false);
        reset();
      });
    }
  }, [timerIsFinnish, dispatch, reset, data, language, isLoading, isCoolOffExpired]);

  useEffect(() => {
    return () => {
      dispatch(resetMfaCheckCode());
    };
  }, [dispatch]);

  const onChangeCallback = useCallback(() => {
    hasError && setHasError(false);
  }, [hasError, setHasError]);

  return (
    <Modal visible className="modal-tfa">
      <Modal.Close closeText={t(MyProfile.OffersTab.closeBtn)} closeAction={closeModal} />
      <Modal.Body padding="lg" className="text-center">
        <div className="mb-2">
          {t(MyAccountTranslation.MFA.text)} {t(sendType[mfaChannel])}{' '}
          <b style={{ verticalAlign: 'text-top' }}>{userMfaType}</b>
        </div>

        {mfaCoolOff && (
          <CountDownTimer
            headingText={t(MyAccountTranslation.MFA.textValid)}
            counterText={t(MyAccountTranslation.MFA.time)}
            endDate={mfaCoolOff}
            timerIsFinnish={timerIsFinnish}
            timeZone="systemTime"
          />
        )}

        <FormProvider {...formProps}>
          <form onSubmit={handleSubmit(handleSendCode)}>
            <Flex.Center className="d-flex-col">
              <FormControl
                {...field}
                hasError={hasError}
                disabled={disableWriteCode}
                onChangeCallback={onChangeCallback}
              />
              {hasError && <CodeError text={t(MyAccountTranslation.MFA.wrongCode)} />}
              <FormButton
                className="mt-3"
                disabled={disableWriteCode || hasError}
                text={t(MyAccountTranslation.MFA.btn)}
              />
            </Flex.Center>
          </form>
        </FormProvider>

        <ResendCode
          style={isLoading || !isCoolOffExpired ? { pointerEvents: 'none', opacity: '0.5' } : {}}
          handleResendChannel={handleResendChannel}
          text={t(MyAccountTranslation.MFA.notReceive)}
        />
      </Modal.Body>
    </Modal>
  );
};

export default MFA_ForgottenPassword;
