import base64 from 'base-64';
import i18next from 'i18next';
import { isIOS } from 'react-device-detect';
import UAParser from 'ua-parser-js';
import { selectDeviceType } from 'shared/common/features/general/slice/deviceType.slice';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { updateLocalizationEnv } from 'shared/common/features/localization/slice/localization.slice';
import { getDefaultBusinessUnitThemeSkin } from 'shared/common/features/myProfile/utils/myProfile.utils';
import { AppDispatch, store } from 'store';
import { getHashHeader, getMidHeader } from 'utils/common/axios-instance';
import { getBusinessUnit, isEmpty } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { loginErrors } from './components/hooks/useLoginErrors';
import { Login } from './types/login.types';
import UserStorage from './UserStorage';

export const authConstants = {
  DEFAULT_TIME_FOR_DECISION_IN_SEC: 30, //the session modal will be vissible for 30 seconds
  CLIENT_ID: 'customer', //change to st_player in order to test session expiration and switch to int from uat
  TERMINAL_ID: 'exhibition',
  AUTHORIZATION_STRING: 'customer:123456', //change to st_player/123456 in order to test session expiration and switch to int from uat
  AUTORIZATION_TERMINAL_STRING: 'exhibition:123456',
  GRAND_TYPES: {
    PASSWORD: 'password',
    REFRESH_TOKEN: 'refresh_token',
  },
  PASSWORD: '123456',
  RETAIL_AUTHORIZATION_STRING: 'agent:123456',
};

export const LOGIN_URL = `${config.API_URL}/oauth/token`;
export const DELETE_DEVICES_URL = `${config.API_URL}/api/ews-crm/player/player-devices/delete`;

export const setUnauthenticatedUserProps =
  () =>
  (dispatch: AppDispatch): void => {
    const sessionlang = UserStorage.getUserLang();
    UserStorage.setUserLang(sessionlang ? sessionlang : window.navigator.language.slice(0, 2));

    const userSessionTZ = UserStorage.getUserTimeZone();
    const locationUrlBasedTZ = UserStorage.getAutomaticTimeZone();
    const currentTimeZone = locationUrlBasedTZ?.tzOffset;
    currentTimeZone !== undefined && UserStorage.setUserTimeZone(currentTimeZone);
    locationUrlBasedTZ?.tz && UserStorage.setUserIanaTimeZoneCode(locationUrlBasedTZ?.tz);
    if (currentTimeZone === undefined || currentTimeZone !== userSessionTZ) {
      dispatch(updateLocalizationEnv());
    }

    const defaultBusinessUnitSkin = getDefaultBusinessUnitThemeSkin();
    UserStorage.setPreferredThemeSkin(defaultBusinessUnitSkin);
  };

export const setAuthenticatedUserLocales = (language: string): void => {
  UserStorage.setUserLang(language ? language : window.navigator.language.slice(0, 2));
};

export const getClientIdString = (): string => {
  return `${getBusinessUnit().toLowerCase()}fo`;
};

export const getClientId = (): string => {
  return getBusinessUnit() === 'EGTT' ? authConstants.TERMINAL_ID : getClientIdString();
};

export const getAuthorization = (): string => {
  return `${getClientIdString()}:${authConstants.PASSWORD}`;
};

export const getAuthorizationHeaders = () => {
  const authorization = base64.encode(
    window.config.CHANNEL === 'RETAIL' ? authConstants.RETAIL_AUTHORIZATION_STRING : getAuthorization(),
  );

  return {
    authorization: `Basic ${authorization}`,
  };
};

export const getLoginHeaders = (shouldDelete?: boolean): Record<string, string> => {
  const user = UserStorage.getUser();
  const authorization = !shouldDelete
    ? base64.encode(window.config.CHANNEL === 'RETAIL' ? authConstants.RETAIL_AUTHORIZATION_STRING : getAuthorization())
    : user.access_token;
  const deviceType = selectDeviceType.deviceType(store.getState());
  const userLang = String(UserStorage.getUserLang());
  const userTZ = String(UserStorage.getUserTimeZone());
  const userTZCode = UserStorage.getUserIanaTimeZoneCode() || UserStorage.getAutomaticTimeZone()?.tz;
  const parser = new UAParser();
  const result = parser.getResult();
  const deviceTypeName = (result.device && result.device.model) || '';
  const { hash, expiry } = getHashHeader();
  const midHeader = getMidHeader();

  const netAppHeader = selectGeneral.netAppHeader(store.getState());

  const headers = {
    authorization: `Basic ${authorization}`,
    'X-Platform-Lang': userLang,
    'X-Platform-TZ': userTZ,
    'X-Platform-Device': deviceType,
    'X-Platform-Device-Name': deviceTypeName,
    'X-Platform-Hash': hash,
    'X-Platform-Exp': String(expiry),
    ...(netAppHeader ? { 'x-platform-hash-netapp': netAppHeader } : {}),
    ...(userTZCode ? { 'X-Platform-Timezone': userTZCode } : {}),
    ...(!isEmpty(midHeader) && { 'X-Platform-Mid': midHeader }),
  };

  if (window.isRNWebView) {
    headers['x-platform-application'] = isIOS
      ? window?.appVersion
        ? `ios-${window.appVersion}`
        : 'ios'
      : `android-${window?.appVersion}`;
  }
  return headers;
};

export const getLoginErrorMessage = (status: number, response: Login.LoginResponseError): Login.LoginError => {
  switch (response?.error) {
    case 'invalid_grant':
      return { error: response?.error, errorMessage: loginErrors.incorrectCredentials };
    case 'phone_not_verified':
      return { error: response?.error, errorMessage: response?.error_description };
    case 'geolocation_restricted':
      return { error: response?.error, errorMessage: response?.error_description };
    case 'auto_lock_attempt':
      return {
        error: response?.error,
        errorMessage: loginErrors.accountLockAttempts,
        subErrorMessage: `${response?.error_description} ${i18next.t(loginErrors.accountLockAttemptsCount)}`,
      };
    case 'account_auto_lock':
    case 'force_reset_password':
      return { error: response?.error, errorMessage: response?.error_description };
    default:
      switch (status) {
        case 400:
        case 401:
          return { error: response?.error, errorMessage: response?.error };
        case 403:
          return { error: response?.error, errorMessage: response?.error_description };
        default:
          return { error: response?.error, errorMessage: loginErrors.loginFailed };
      }
  }
};
