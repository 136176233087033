import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export const useInViewWithDelay = (isTicker, delay): [(node?: Element | null) => void, boolean] => {
  const [state, setState] = useState(false);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [jackpotRef, isVisible] = useInView({
    root: isTicker ? document.querySelector('.ticker-wrapper') : null,
    rootMargin: '0px',
    threshold: 0,
  });

  useEffect(() => {
    if (state === isVisible && timer.current !== null) {
      clearTimeout(timer.current);
    }
    if (state !== isVisible) {
      timer.current = setTimeout(function () {
        setState(isVisible);
      }, delay);
    }

    return () => {
      if (timer.current !== null) {
        clearTimeout(timer.current);
      }
    };
  }, [isVisible, delay]);

  return [jackpotRef, state];
};
