import styled from 'styled-components';

const StyledGridHeaderBadge = styled.div.attrs({ className: `prm-grid-header__badge` })`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 4px;
  min-width: clamp(1.125rem, 1.053rem + 0.31vw, 1.25rem);
  height: clamp(1.125rem, 1.053rem + 0.31vw, 1.25rem);
  border-radius: 0.65rem;
  text-align: center;
  font-size: clamp(0.75rem, 0.606rem + 0.62vw, 1rem);
`;

export default StyledGridHeaderBadge;
