import React, { useLayoutEffect, useRef, useState } from 'react';
import { ImageCell } from 'pages/captainUp/components';
import { createImageSources } from 'pages/captainUp/components/utils/captinUp.utils';
import { imagesConfig } from 'pages/captainUp/utils/captainUp.utils';
import { cssGamificationNS } from 'utils/common/types/css.types';

interface WrapperProps {
  image: string;
  title: string;
  children: React.ReactNode;
  cellId: string;
  hasFinishedLoading: () => void;
  type?: string;
}

export const ImageWrapper: React.FC<WrapperProps> = ({ image, title, children, cellId, hasFinishedLoading, type }) => {
  const imgRef = useRef<HTMLDivElement>(null);
  const imgName = image;
  const [imageList, setImageList] = useState<string[] | null>(null);

  useLayoutEffect(() => {
    imgRef.current &&
      setImageList(
        createImageSources(
          imgName,
          imgRef.current.clientWidth,
          imgRef.current.clientWidth,
          2,
          imagesConfig.CHALLENGES_DEFAULT_IMAGE,
        ),
      );
  }, [imgRef]);

  return (
    <div
      className={`${cssGamificationNS}-chall-cell__image-wrapper ${cssGamificationNS}-chall-cell__image-wrapper--${type}`}
      ref={imgRef}
    >
      {imageList && (
        <ImageCell
          image={imageList}
          title={title}
          className={`${cssGamificationNS}-chall-cell__image ${cssGamificationNS}-chall-cell__image--${type}`}
          cellId={cellId}
          hasFinishedLoading={hasFinishedLoading}
        />
      )}
      {children}
    </div>
  );
};
