import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { Sport } from 'utils/common/types/sportTypes';
import config from 'utils/config';
import { AnexInfoThunk, AnexReportDetailedType, AnexReportType } from '../type/anexReport.types';

const initialState: AnexReportType = {
  anexInfoStatus: 'idle',
  anexInfo: null,
  anexInfoErrorMessage: '',
};

export const anexInfoThunk: AnexInfoThunk = {
  getAnexReport: createAbortThunk(`terminal/getAnexReport`, async ({ fromDate, toDate }) => {
    const url = `${config.API_URL}/api/pretail/agent/v2/pos/get-regulatory-report-three?fromDate=${fromDate}&toDate=${toDate}`;
    const response = await axiosInstance.get(url);
    return response.data;
  }),
};
const anexInfoThunkStat = anexInfoThunk.getAnexReport;
const anexSlice = createSlice({
  name: 'anexSlice',
  initialState,
  reducers: {
    resetAnexInfoState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(anexInfoThunkStat.fulfilled, (state, action: PayloadAction<AnexReportDetailedType>) => {
      state.anexInfoStatus = 'succeeded';
      state.anexInfo = action.payload;
    });
    builder.addCase(anexInfoThunkStat.pending, (state) => {
      state.anexInfoStatus = 'pending';
    });
    builder.addCase(anexInfoThunkStat.rejected, (state, action) => {
      state.anexInfoErrorMessage = action.payload;
      state.anexInfoStatus = 'failure';
      state.anexInfo = null;
    });
  },
});
export const { resetAnexInfoState } = anexSlice.actions;
export default anexSlice.reducer;
export const selectAnexData = {
  anexInfo: (state: RootState) => state.retail.anexReport.anexInfo,
  anexInfoStatus: (state: RootState): Sport.StateStatus => state.retail.anexReport.anexInfoStatus,
  anexInfoErrorMessage: (state: RootState) => state.retail.anexReport.anexInfoErrorMessage,
};
