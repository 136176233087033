import { isEmpty } from 'utils/common/helpersCommon';
import { NavBarType } from '../features/NavBar/types/navBar.types';

export const CAPTAIN_UP_POLLING_INTERVAL = 60000;

export const CAPTAIN_UP_ROUTE = '/captain-up';

export const itemsToDisplayCount = 20;

export const unauthenticatedTabs = ['store', 'terms'];

export const percentage = (num, per) => {
  return (per / num) * 100;
};

export const isCategoryRoute = (alias: string) => alias === 'challenges' || alias === 'store';

export const levelProgress = ({ currentLevelPoints, nextLevelPoints, points }): number => {
  const current = points - currentLevelPoints;
  const next = nextLevelPoints - currentLevelPoints;

  if (currentLevelPoints === nextLevelPoints) return 100;
  return (current / next) * 100;
};

export enum DiffFormat {
  Seconds = 'seconds',
  Minutes = 'minutes',
  Hour = 'hour',
  Hours = 'hours',
  Day = 'day',
  Days = 'days',
}

export const imagesConfig = {
  CAPTAIN_UP_CAROUSEL_CDN_IMAGE_API: 'gaming-content/slider/',
  CAPTAIN_UP_CDN_IMAGE_API: 'common-content/gamification/module/',
  CAPTAIN_UP_ICONS: 'common-content/gamification/module/sprites.svg',
  COINS: 'coins.png',
  CHALLENGES_BGR: 'challenges_bgr.png',
  ACHIEVEMENT_BGR: 'achievement_bgr.png',
  ACHIEVEMENT_BGR_POPUP: 'achievement_bgr_popup.png',
  CHALLENGES_DEFAULT_IMAGE: 'missing_pic_store&challenge.png',
  STORE_DEFAULT_IMAGE: 'missing_pic_store&challenge.png',
  ACHIEVEMENT_DEFAULT_IMAGE: 'missing_pic_achieve.png',
};

export const noSidebarLocations = 'challenges';

export enum GamificationFilter {
  UPCOMING = 'upcoming',
  ALL = 'all',
  ACTIVE = 'active',
  FINISHED = 'finished',
  WEEKLY = 'weekly',
  DAILY = 'daily',
  UNIQUE = 'unique',
}

export enum CategoryQueryType {
  SEARCH_BY = 'SEARCH_BY',
  SEARCH_BY_LIVE = 'SEARCH_BY_LIVE',
  MOST_PLAYED_CATEGORY = 'MOST_PLAYED_CATEGORY',
  FREE_SPIN_BONUS = 'FREE_SPIN_BONUS',
  LAST_PLAYED_CATEGORY = 'LAST_PLAYED_CATEGORY',
  FAVOURITES_CATEGORY = 'FAVOURITES_CATEGORY',
  MOST_SEARCHED_CATEGORY = 'MOST_SEARCHED_CATEGORY',
  SEARCH_BY_TITLE = 'SEARCH_BY_TITLE',
  CATEGORY = 'CATEGORY',
  QUERY_CATEGORY = 'QUERY_CATEGORY',
  GAMES_CATEGORY = 'GAMES_CATEGORY',
  RECOMMENDATIONS_CATEGORY = 'RECOMMENDATIONS_CATEGORY',
}

export const getFirstMenuItem = (menuItems: NavBarType.Tab[], isAuthenticated: boolean | null): NavBarType.Tab => {
  if (isAuthenticated) return menuItems[0];
  const storeMenuItem = menuItems.find((menu) => menu.alias === 'store');
  return storeMenuItem || menuItems[0];
};

export const getFirstMenuSubMenu = (firstMenuItem: NavBarType.Tab): NavBarType.SubTab[] | null => {
  if (!isEmpty(firstMenuItem.categories)) return firstMenuItem.categories;
  if (!isCategoryRoute(firstMenuItem.alias)) return firstMenuItem.subMenu;
  return null;
};
