import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'utils/common/helpersCommon';
import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';

export type RetailHeaderUserPosInfoProps = {
  posName?: string;
  terminalName?: string;
};

const RetailHeaderUserPosInfo: React.FC<RetailHeaderUserPosInfoProps> = ({ posName, terminalName }) => {
  const { t } = useTranslation();

  if (isEmpty(posName) && isEmpty(terminalName)) {
    return null;
  }
  return (
    <div className="d-flex-col rbtn__posinfo">
      {!isEmpty(posName) && (
        <div>
          <span className="label">{t(CashierTranslations.ActionButton.posName)}: </span>
          {posName}
        </div>
      )}
      {!isEmpty(terminalName) && (
        <div>
          <span className="label">{t(CashierTranslations.ActionButton.terminalName)}: </span>
          {terminalName}
        </div>
      )}
    </div>
  );
};

export default RetailHeaderUserPosInfo;
