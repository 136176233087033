import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrency } from 'hooks/common/useCurrency';
import { getFbUserData } from 'modules/casino/shared/features/gameLaunch/casinoLaunchUtils';
import { useLayoutConfigType } from 'modules/casino/shared/hooks/useLayoutConfigType';
import { loginThunks, selectAuthentication, showQuickLoginForm } from 'pages/auth/login/slice/login.slice';
import { Login } from 'pages/auth/login/types/login.types';
import userStorage from 'pages/auth/login/UserStorage';
import { setIsSessionTimerActive } from 'shared/common/features/general/slice/general.slice';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { updateNotifications } from 'shared/common/features/inGameNotifications/slice/inGameNotifications.slice';
import {
  selectIntegrations,
  setCasinoWidgetEnabledColorIcons,
  setCasinoWidgetFavGamesCounter,
  setCasinoWidgetGameData,
  setIsCasinoWidgetLoaded,
  setRefetchBonusGames,
} from 'shared/common/features/integrations/slice/integrations.slice';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import {
  fetchFormFields,
  setLiveTime,
} from 'shared/common/features/realityCheckSession/slice/realityCheckSession.slice';
import { fbEventFreeStartGame, fbEventPaidStartGame } from 'shared/common/features/seo/fb/events';
import { triggerGTMEvent } from 'shared/common/features/seo/gtm/GoogleTagManager';
import { selectLanguage } from 'shared/common/features/settings/slice/settings.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { getWindowConfigParam, isProductionBuild, LOGIN_FORM_TYPE_MODAL } from 'utils/common/helpersCommon';
import { getActiveDevEnvAndBU } from 'utils/config';
import { GAME_LAUNCH_EXIT_PREFIX, GAME_LAUNCH_PREFIX } from '../../shared/constants';
import useCasinoWidget from '../hooks/useCasinoWidget';
import { CasinoWidgetTypes } from '../types/casinoWidget.types';
import { getReservedAreaInGameLaunch } from '../utils/casinoWidget.utils';

const isDevelopment = !isProductionBuild;
const BU = isDevelopment ? getActiveDevEnvAndBU(0) : getWindowConfigParam('BUSINESS_UNIT');
const ENV_TYPE = isDevelopment ? getActiveDevEnvAndBU(1) : getWindowConfigParam('ENV_TYPE');
const channel = getWindowConfigParam('CHANNEL');
const device = getWindowConfigParam('DEVICE');
const env = ENV_TYPE === 'PRODUCTION' ? '' : `-${ENV_TYPE}`;
const clientId =
  getWindowConfigParam('CLIENT_ID')?.toLowerCase() ||
  `${BU}${env}${channel ? `-${channel}-${device}` : ''}`.toLowerCase();
const businessName = getWindowConfigParam('BRAND_NAME_SHORT') || '';
const casinoWidgetUrl = getWindowConfigParam('CASINO_WIDGET_URL');
const gameLaunchBasePath = GAME_LAUNCH_PREFIX;
const gameLaunchExitPath = GAME_LAUNCH_EXIT_PREFIX;
const reservedAreaInGameLaunch = getReservedAreaInGameLaunch();

const useCasinoWidgetInit = () => {
  useCasinoWidget();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const preferredTheme = useAppSelector(selectMyProfile.preferredTheme);
  const language = useAppSelector(selectLanguage);
  const { currencyCode } = useCurrency();
  const isCasinoWidgetScriptLoaded = useAppSelector(selectIntegrations.isCasinoWidgetScriptLoaded);
  const isCasinoWidgetLoaded = useAppSelector(selectIntegrations.isCasinoWidgetLoaded);
  const casinoRelaunchStatus = useAppSelector(selectIntegrations.casinoRelaunchStatus);
  const token = userStorage.getUser()?.access_token;
  const timeZoneOffset = userStorage.getUserTimeZone();
  const userData = useAppSelector(selectMyProfile.dataUser);
  const currentPath = window.location.pathname;
  const refetchBonusGames = useAppSelector(selectIntegrations.casinoRefetchBonusGames);

  const trackingEventsConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.TRACKING_EVENTS,
    deviceLayout: 'general',
  });

  useEffect(() => {
    const config: CasinoWidgetTypes.Config = {
      clientId,
      params: {
        preferredTheme,
        language,
        currencyCode,
        token,
        casinoWidgetUrl,
        businessName,
        timeZoneOffset,
        gameLaunchBasePath,
        gameLaunchExitPath,
        reservedAreaInGameLaunch,
        safariSafeArea: { portrait: true, landscape: false },
        casinoRelaunchStatus,
      },
      listeners: {
        onLoad,
        onShowLogin,
        onLogout,
        onNavigationChange,
        onNotificationUpdate,
        onSetSessionTimerActivity,
        onResize,
        onScroll,
        onSetLiveTime,
        onFetchFormFields,
        onTriggerTrackingEvent,
        onGameStart,
        onGameDataLoaded,
        onOpenGame,
        onSetFavGamesCounter,
        onSetEnabledColorIcons,
      },
    };

    if (isCasinoWidgetScriptLoaded && window.XNaveCasino) {
      window.widgetCasino = window.XNaveCasino?.({ config });
      window.widgetCasino && window.widgetCasino.init();
    }
  }, [isCasinoWidgetScriptLoaded]);

  useEffect(() => {
    const config = {
      language,
      currencyCode,
      token,
      preferredTheme,
      timeZoneOffset,
      casinoRelaunchStatus,
    };
    isCasinoWidgetLoaded && window.widgetCasino?.updateConfig(config);
  }, [language, currencyCode, token, preferredTheme, timeZoneOffset, isCasinoWidgetLoaded, casinoRelaunchStatus]);

  useEffect(() => {
    if (refetchBonusGames && isCasinoWidgetLoaded) {
      window.widgetCasino?.updateConfig({
        refetchBonusGames,
      });

      dispatch(setRefetchBonusGames(false));
    }
  }, [isCasinoWidgetLoaded, refetchBonusGames, dispatch]);

  useEffect(() => {
    !isAuthenticated && window.widgetCasino?.logout();
  }, [isAuthenticated]);

  useEffect(() => {
    if (currentPath?.includes('/my-account') && isAuthenticated && window.widgetCasino) {
      window.widgetCasino?.logout();
    }
  }, [currentPath, isAuthenticated]);

  const onLoad = () => {
    dispatch(setIsCasinoWidgetLoaded(true));
  };

  const onNavigationChange = (payload) => {
    if (payload?.navigateBackStep) {
      navigate(payload.navigateBackStep);
      return;
    }

    if (payload?.options) {
      const replace = !!payload.options?.replaceCurrentHistory;
      navigate(payload.path, { ...payload.options, replace });
    } else {
      navigate(payload.path);
    }
  };

  const onOpenGame = (route) => {
    navigate(route);
  };

  const onNotificationUpdate = (payload) => dispatch(updateNotifications(payload));

  const onSetSessionTimerActivity = (payload) => dispatch(setIsSessionTimerActive(payload));

  const onTriggerTrackingEvent = (payload) => triggerGTMEvent(payload);

  const onGameDataLoaded = (payload) => {
    dispatch(setCasinoWidgetGameData(payload));
  };

  const onGameStart = (payload) => {
    if (!payload) return;
    dispatch(setCasinoWidgetGameData(payload));

    if (!payload.isTrackable) return;
    const apiUrl = trackingEventsConfig?.fbPaidGameStartApi;
    const fBUserData = getFbUserData(userData);
    if (fBUserData) {
      fbEventPaidStartGame({
        contentName: payload.description,
        contentType: payload.gameType,
        contentIds: payload.refGameId,
        fBUserData,
        apiUrl,
        device: payload.device,
      });
    } else {
      fbEventFreeStartGame({
        contentName: payload.description,
        contentType: payload.gameType,
        contentIds: payload.refGameId,
        apiUrl,
        device: payload.device,
      });
    }
  };

  const onShowLogin = (payload?: Login.NavigationOptions) =>
    dispatch(
      showQuickLoginForm({
        status: true,
        loginActionType: LOGIN_FORM_TYPE_MODAL,
        navigationOptions: payload,
      }),
    );

  const onLogout = () => dispatch(loginThunks.logout());

  const onResize = () => {
    // console.log('onResize');
  };

  const onScroll = () => {
    // console.log('onScroll');
  };

  const onSetLiveTime = () => {
    dispatch(setLiveTime());
  };

  const onFetchFormFields = (payload) => {
    dispatch(fetchFormFields(payload));
  };

  const onSetFavGamesCounter = (payload) => {
    dispatch(setCasinoWidgetFavGamesCounter(payload));
  };

  const onSetEnabledColorIcons = (payload) => {
    dispatch(setCasinoWidgetEnabledColorIcons(payload));
  };

  return;
};

export default useCasinoWidgetInit;
