import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { BurgerUI } from './BurgerUI';
import { setExpandedSections } from '../../navigationMenu/slice/navigationMenu.slice';

type BurgerNavWrapperType = {
  index: number;
  iconName: string | null;
  iconColor: string | undefined;
  textName: string;
  sectionType: string;
  isOpened: boolean | undefined;
  toggleSportTiles: (
    route: string,
    sectionId: number,
    verticalType: string | undefined,
    sectionType: string,
    isExternalRoute: boolean | undefined,
  ) => void;
  route: string;
  verticalType: string | undefined;
  concreteType: string;
  isExternalRoute: boolean | undefined;
};

const BurgerNavWrapper = ({
  index,
  iconName,
  iconColor,
  textName,
  sectionType,
  isOpened,
  toggleSportTiles,
  route,
  verticalType,
  concreteType,
  isExternalRoute,
}: BurgerNavWrapperType) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentPath = location.pathname;

  // Find the index of the second '/'
  const secondSlashIndex = currentPath.indexOf('/', 1);

  // Find the index of the first '?'
  const questionMarkIndex = currentPath.indexOf('?');

  // Determine the cut index based on the occurrence of '/' or '?'
  const cutIndex =
    secondSlashIndex !== -1 ? secondSlashIndex : questionMarkIndex !== -1 ? questionMarkIndex : currentPath.length;

  // Cut the path
  const trimmedPath = currentPath.substring(0, cutIndex);

  useEffect(() => {
    if (trimmedPath === route && !isEmpty(route)) {
      dispatch(setExpandedSections({ sectionId: index, isOpened: true }));
    }
    return () => {
      dispatch(setExpandedSections({ sectionId: index, isOpened: false }));
    };
  }, [currentPath]);

  return (
    <>
      <BurgerUI.NavItem
        key={index}
        icon={iconName}
        iconColor={iconColor}
        text={textName}
        onClick={() => toggleSportTiles(route, index, verticalType, sectionType, isExternalRoute)}
        isActive={isOpened}
        isExpandable={
          verticalType === 'sport' ||
          (verticalType === 'casino' && sectionType !== 'custom') ||
          sectionType === 'systemsettings' ||
          sectionType === 'help'
        }
        isExpanded={isOpened}
        dataQid={verticalType}
        dataCpp={concreteType}
      />
    </>
  );
};

export default BurgerNavWrapper;
