import { useEffect } from 'react';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { selectLanguage } from 'shared/common/features/settings/slice/settings.slice';
import { useAppDispatch, useAppSelector } from 'store';
import useSmartico from './useSmartico';
import { selectIntegrations, setIsSmarticoScriptLoaded, setSmarticoUserCoins } from '../../slice/integrations.slice';
import { isScriptAttached, smarticoScriptInit, SMARTICO_URL } from '../utils/smartico.utils';

const useSmarticoScript = (): void => {
  const isSmarticoScriptLoaded = useAppSelector(selectIntegrations.isSmarticoScriptLoaded);
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const playerId = useAppSelector(selectMyProfile.playerId);
  const language = useAppSelector(selectLanguage);

  const isAttached = isScriptAttached('smartico');

  const dispatch = useAppDispatch();
  const { config, isActive } = useSmartico();

  useEffect(() => {
    if (!isAttached && isActive && config?.config) {
      const brandKey = config.config.brandKey;

      smarticoScriptInit({
        document,
        src: config.config?.scriptSrc || SMARTICO_URL,
        callback: () => {
          brandKey
            ? window._smartico.init(config.config.labelKey, { brand_key: brandKey })
            : window._smartico.init(config.config.labelKey);
          dispatch(setIsSmarticoScriptLoaded(true));
        },
        attr: 'smartico',
        config: config?.config,
      });
    }
  }, [dispatch, isAttached, config, isActive]);

  useEffect(() => {
    if (isActive && isSmarticoScriptLoaded) {
      if (isAuthenticated && playerId && window._smartico) {
        window._smartico_user_id = `${playerId}`;
        window._smartico_language = language;

        window._smartico.on('props_change', (props: { ach_points_balance?: number }) => {
          if (props.ach_points_balance !== undefined) dispatch(setSmarticoUserCoins(props.ach_points_balance));
        });
      } else {
        window._smartico_user_id = null;
        window._smartico_language = null;
      }
    }
  }, [isSmarticoScriptLoaded, isAuthenticated, playerId, language, isActive, dispatch]);
};

export default useSmarticoScript;
