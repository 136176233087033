import { useEffect } from 'react';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { LayoutConfigInnerLayoutTypes } from 'modules/casino/shared/constants';
import { useCasinoLayoutConfig } from 'modules/casino/shared/hooks/useCasinoLayoutConfig';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { useAppDispatch, useAppSelector } from 'store';
import { useGetEventParams } from './useGetEventParams';
import { useGameCollectionId } from '../../../../../cellls/hooks/useGameCollectionId';
import { categoryImpressionsSelectors, registerCategory } from '../slice/categoryImpressionsSlice';

type Props = {
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
  isCategoryinView: boolean;
};

export const useCategoryImpressions = ({ categoryId, categoryLayoutType, isCategoryinView }: Props) => {
  const dispatch = useAppDispatch();
  const { isTrackable } = useGetCategoryParams(categoryId, categoryLayoutType);
  const { gameCollectionId } = useGameCollectionId({ categoryId, categoryLayoutType });
  const isCategoryRegistered = useAppSelector((state) =>
    categoryImpressionsSelectors.isCategoryRegistered(state, gameCollectionId),
  );
  const eventParams = useGetEventParams({ categoryId, categoryLayoutType });
  const enableCategoryTracking = useCasinoLayoutConfig({
    innerLayout: LayoutConfigInnerLayoutTypes.ENABLE_CATEGORY_TRACKING,
  });

  useEffect(() => {
    if (!enableCategoryTracking) {
      return;
    }

    if (isTrackable && !isCategoryRegistered && isCategoryinView) {
      dispatch(registerCategory({ gameCollectionId, eventParams }));
    }
  }, [dispatch, isTrackable, isCategoryRegistered, isCategoryinView, gameCollectionId]);
};
