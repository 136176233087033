import React from 'react';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { CategoryLayoutTypes } from '../../types/CategoryLayoutTypes';
import CategoryFilter from '../CategoryFilter';

interface Props {
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
}

const InnerCategoryActions: React.FC<Props> = ({ categoryId, categoryLayoutType }) => {
  const { isFilterEnabled, searchTags } = useGetCategoryParams(categoryId, categoryLayoutType);
  return isFilterEnabled && searchTags ? (
    <CategoryFilter categoryId={categoryId} searchTags={searchTags} categoryLayoutType={categoryLayoutType} />
  ) : (
    <></>
  );
};

export default InnerCategoryActions;
