import { getRemainingTime } from 'utils/dateUtils';
import { PromotionTypes } from '../types/promotions.types';

export const PROMOTION_BASE_TTL = 120000; // 2 minutes base ttl for promotions fetch

export const SplitTitles = (title: string) => {
  if (!title) return;
  const splitted = title?.split(' ');
  if (splitted.length === 1) {
    return <>{splitted[0]}</>;
  }

  if (splitted.length % 2 === 0) {
    return (
      <>
        {splitted.slice(0, splitted.length / 2).join(' ')}
        <br />
        {splitted.slice(splitted.length / 2, splitted.length).join(' ')}
      </>
    );
  }

  if (splitted.length > 2 && splitted.length % 2 === 1) {
    return (
      <>
        {splitted.slice(0, splitted.length / 2 + 1).join(' ')}
        <br />
        {splitted.slice(splitted.length / 2 + 1, splitted.length).join(' ')}
      </>
    );
  }
};

export const getDateDifference = (
  {
    startDateTime,
    endDateTime,
    isVisiblePromoTimer,
    isVisibleExpiredPromoTimer,
  }: PromotionTypes.Promotion | PromotionTypes.LobbyPromotion,
  layout: PromotionTypes.GridLayoutType,
  hasSeconds: boolean = true,
) => {
  const timeNow = Date.now();
  switch (isVisiblePromoTimer && layout) {
    case 'layout_1':
    case 'layout_2':
    case 'layout_3':
      return (
        /* Removing 59999ms (59.[9]s) to account for last minute as we don't diplay seconds in some cases */
        (startDateTime &&
          startDateTime > timeNow && { ...getRemainingTime(startDateTime, timeNow - (!hasSeconds ? 59999 : 0)) }) ||
        /* Removing 59999ms (59.[9]s) to account for last minute as we don't diplay seconds in some cases */
        (endDateTime &&
          endDateTime > timeNow && { ...getRemainingTime(endDateTime, timeNow - (!hasSeconds ? 59999 : 0)) }) ||
        (isVisibleExpiredPromoTimer &&
          endDateTime &&
          endDateTime < timeNow && { days: undefined, hours: undefined, minutes: undefined, status: 'expiredText' }) ||
        undefined
      );
    case false:
      return undefined;
    case undefined:
    default:
      return endDateTime ? getRemainingTime(endDateTime) : undefined;
  }
};
