import { createPortal } from 'react-dom';
import { SidebarCasinoCategory } from 'modules/casino/modules/container/cellsContainer/containers/external';

type Props = { portalId: string; id: number; apiUri: string; name: string };

const AppSidebarCasinoPortal: React.FC<Props> = ({ portalId, id, apiUri, name }) => {
  const elem = document.getElementById(portalId);

  if (!elem) return null;

  return createPortal(<SidebarCasinoCategory apiUri={apiUri} containerName={name} containerId={id} />, elem);
};

export default AppSidebarCasinoPortal;
