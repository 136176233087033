import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { logoutUser, setPlayerRestrictions } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError, getBusinessUnit } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { PlayerRestrictions } from '../types/responsibleGambling.types';

export const responsibleGamblingThunks = {
  fetchDefaultRestrictions: createAbortThunk<PlayerRestrictions.ApiResponse, string, string | null>(
    'responsibleGambling/fetchDefaultRestrictions',
    async (restrictions, { source }) => {
      const response = await axiosInstance.get<PlayerRestrictions.ApiResponse>(
        `${config.API_URL}/api/ews-crm/player/players/responsible-gambling/${getBusinessUnit()}/${restrictions}`,
        {
          cancelToken: source.token,
        },
      );
      return response.data;
    },
  ),
  updatePlayerRestrictions: createAbortThunk<null, PlayerRestrictions.UpdateParams, string>(
    'responsibleGambling/updatePlayerRestrictions',
    async (
      {
        selectedValue,
        restrictionType,
        product,
        type = 'post',
        isActive,
        isExtended,
        isRequestSent,
        restrictionGroupId,
        isReOpeningRequestSent,
      },
      { rejectWithValue, dispatch },
    ) => {
      try {
        const restrictionDurationPeriod = {
          durationType: selectedValue?.durationType,
          durationValue: selectedValue?.value,
        };

        const postBody = {
          restrictionType,
          restrictionGroupId,
          ...(isExtended ? { isExtended } : {}),
          ...(isRequestSent ? { isRequestSent } : {}),
          ...(selectedValue ? { restrictionDurationPeriod } : {}),
          ...(restrictionType !== 'accountClosure' && { productKey: product }),
          ...(isActive !== undefined ? { isActive } : {}),
          ...(isActive !== undefined ? { isActive } : {}),
          ...(isReOpeningRequestSent !== undefined ? { isReOpeningRequestSent } : {}),
        };

        await axiosInstance[type](`${config.API_URL}/api/ews-crm/player/players-settings/player-restriction`, postBody);
        dispatch(hideRootModal());
        return null;
      } catch (err) {
        return rejectWithValue(getError.responseDataMessage(err));
      }
    },
  ),
  fetchRestrictionsInfo: createAbortThunk<PlayerRestrictions.InfoMap, string, { rejectValue: string }>(
    'responsibleGambling/fetchRestrictionsInfo',
    async (_, { rejectWithValue, source }) => {
      try {
        const response = await axiosInstance.get<PlayerRestrictions.InfoMap>(
          `${config.API_URL}/api/ews-crm/public/cms/player-content?key=player_restrictions_info_form`,
          {
            headers: { 'X-Platform-Origin': getBusinessUnit() },
            cancelToken: source.token,
          },
        );
        return response.data;
      } catch (err) {
        return rejectWithValue(getError.responseDataErrors(err));
      }
    },
  ),
};

const { updatePlayerRestrictions, fetchDefaultRestrictions, fetchRestrictionsInfo } = responsibleGamblingThunks;

const initialState: PlayerRestrictions.State = {
  restrictionsInfo: {},
  restrictionsTab: {
    enabled: false,
    possibleValues: [],
    removeLimitEnabled: false,
    confirmActivationWithPassword: false,
    status: 'idle',
    error: null,
    helpUrl: '/help/reasonable-betting',
    verticals: [],
    automaticReOpeningEnabled: false,
    marketingMessagesEnabled: true,
    revertRestrictionEnabled: true,
    revertRestrictionMinutes: 0,
    products: undefined,
  },
  playerRestrictions: null,
  statuses: {
    updatePlayerRestrictions: 'idle',
  },
  errors: {
    updatePlayerRestrictions: null,
  },
};

const responsibleGambling = createSlice({
  name: 'responsibleGambling',
  initialState,
  reducers: {
    resetRestrictionsTab(state) {
      state.restrictionsTab = initialState.restrictionsTab;
    },
    resetPostRestrictions(state) {
      state.errors.updatePlayerRestrictions = null;
      state.statuses.updatePlayerRestrictions = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setPlayerRestrictions, (state, action) => {
      if (action.payload) {
        state.playerRestrictions = action.payload?.reduce(
          (acc, restriction) => {
            if (!acc[restriction.productTypeName]) {
              acc[restriction.productTypeName] = [];
            }
            acc[restriction.productTypeName].push(restriction);
            return acc;
          },
          {} as Record<PlayerRestrictions.ProductType, PlayerRestrictions.Restriction[]>,
        );
      }
    });
    builder.addCase(logoutUser, (state) => {
      state.playerRestrictions = initialState.playerRestrictions;
      state.errors.updatePlayerRestrictions = null;
      state.statuses.updatePlayerRestrictions = 'idle';
    });
    builder.addCase(fetchRestrictionsInfo.fulfilled, (state, action: PayloadAction<PlayerRestrictions.InfoMap>) => {
      state.restrictionsInfo = action.payload;
    });
    builder
      .addCase(fetchDefaultRestrictions.pending, (state) => {
        state.restrictionsTab.status = 'pending';
      })
      .addCase(fetchDefaultRestrictions.rejected, (state, action) => {
        state.restrictionsTab.status = 'failure';
        state.restrictionsTab.error = action.payload || null;
      })
      .addCase(fetchDefaultRestrictions.fulfilled, (state, action) => {
        state.restrictionsTab = { status: 'succeeded', error: null, ...action.payload };
      });
    builder
      .addCase(updatePlayerRestrictions.pending, (state) => {
        state.statuses.updatePlayerRestrictions = 'pending';
        state.errors.updatePlayerRestrictions = null;
      })
      .addCase(
        updatePlayerRestrictions.rejected,
        (state, action: PayloadAction<string | { rejectValue: string } | null | undefined>) => {
          state.statuses.updatePlayerRestrictions = 'failure';
          state.errors.updatePlayerRestrictions = action.payload as string;
        },
      )
      .addCase(updatePlayerRestrictions.fulfilled, (state) => {
        state.statuses.updatePlayerRestrictions = 'succeeded';
      });
  },
});

export const { resetRestrictionsTab, resetPostRestrictions } = responsibleGambling.actions;

export default responsibleGambling.reducer;

export const selectResponsibleGambling = {
  allPlayerRestrictions: (state: RootState) => state.myAccount.responsibleGambling?.playerRestrictions,
  restrictionsTypeTab: (state: RootState) => state.myAccount.responsibleGambling?.restrictionsTab,
  products: (state: RootState) => state.myAccount.responsibleGambling?.restrictionsTab?.products,
  updatePlayerRestrictionsStatus: (state: RootState) =>
    state.myAccount.responsibleGambling?.statuses?.updatePlayerRestrictions,
  updatePlayerRestrictionsError: (state: RootState): string | null =>
    state.myAccount.responsibleGambling.errors.updatePlayerRestrictions,
  restrictionsInfoText: (key: PlayerRestrictions.ConfigurationKey) => (state: RootState) =>
    state.myAccount.responsibleGambling.restrictionsInfo?.[key],
};

export const selectPlayerRestrictionsByProducts = createSelector(
  (state: RootState) => state.myAccount.responsibleGambling?.playerRestrictions ?? null,
  (state: RootState) => state.common.general.buSettings?.availableRestrictionProducts ?? null,
  (allPlayerRestrictions, products) => {
    if (!allPlayerRestrictions || !products) return null;

    const selectedRestrictions: PlayerRestrictions.Restriction[] = [];
    const productSet = new Set(products);

    for (const productKey in allPlayerRestrictions) {
      const product = productKey as PlayerRestrictions.ProductType;
      if (productSet.has(product)) {
        const productRestrictions: PlayerRestrictions.Restriction[] = allPlayerRestrictions[product];
        if (productRestrictions) {
          selectedRestrictions.push(
            ...productRestrictions.filter(
              (restriction) =>
                restriction.productTypeName === product &&
                (restriction.restrictionStatus === 'active' || !restriction.restrictionStatus),
            ),
          );
        }
      }
    }

    return selectedRestrictions.length > 0 ? selectedRestrictions : null;
  },
);
