import { printJoker } from 'modules/retail/shared/printMethods/otherTicketMethods/jokerTicketMethod';
import { printLottoTicket } from 'modules/retail/shared/printMethods/otherTicketMethods/lottoTicketMethod';
import { printOmniPlayTicket } from 'modules/retail/shared/printMethods/otherTicketMethods/omniPlayTicketMethod';
import { printSportData } from '../utils/reprintMethoods.utils';

enum BetType {
  type_Lotto = '76',
  type_RocketJet = '78',
  type_Joker = '82',
}
export const printTransactionHistoryDuplicatedTicket = (ticketInfo, t, dispatch, isCurrencyBeforeAmount) => {
  const ticketId = ticketInfo?.ticket?.ticket_code;
  const prefix = ticketId?.substring(0, 2);

  switch (prefix) {
    case BetType.type_Lotto:
      printLottoTicket(
        ticketInfo?.ticket?.play_time,
        ticketInfo?.ticket?.ticket_code,
        ticketInfo?.ticket?.details,
        ticketInfo?.ticket?.additionalInfo,
        t,
        true,
      );

      break;

    case BetType.type_RocketJet:
      printOmniPlayTicket(
        ticketInfo?.ticket?.play_time,
        ticketInfo?.ticket?.ticket_code,
        ticketInfo?.ticket?.details,
        ticketInfo?.ticket?.additionalInfo,
        t,
        true,
      );

      break;

    case BetType.type_Joker:
      printJoker(
        ticketInfo?.ticket?.play_time,
        ticketInfo?.ticket?.ticket_code,
        ticketInfo?.ticket?.details,
        ticketInfo?.ticket?.additionalInfo,
        t,
        true,
      );

      break;

    default:
      printSportData(ticketInfo, dispatch, isCurrencyBeforeAmount);
      break;
  }
};
