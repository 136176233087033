import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import { isMobile } from 'react-device-detect';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { fetchGamificationPlayer } from '../../UserInfo/slice/gamificationPlayer.slice';
import { Achievements } from '../types/achievements.types';
import { normalizeAchievements } from '../utils/achievements.utils';

const initialState: Achievements.State = {
  achievementIds: [],
  normalizedAchievements: {},
  hasMore: false,
  hasError: false,
  isLoading: false,
  hasFetched: false,
  totalItems: 0,
  page: 1,
};

export const achievementsLimit = isMobile ? 15 : 24;

export const achievementsThunks: Achievements.Thunks = {
  getAchievements: createAbortThunk(
    'achievements/getAchievements',
    async ({ page, limit = 100 }, { rejectWithValue, signal, source }) => {
      try {
        signal.addEventListener('abort', () => source.cancel());

        const url = `${config.API_URL}/api/ews-game-journey/player/game-trophy`;
        const params = { skip: (page - 1) * limit, limit };

        const response = await axiosInstance.get(url, { params, cancelToken: source.token });

        return response.data || { items: [], totalItems: 0 };
      } catch (err) {
        if (Axios.isCancel(err)) {
          return rejectWithValue(null);
        }
        return rejectWithValue(getError.default());
      }
    },
  ),
  claimRewards: createAbortThunk(
    'achievements/claimRewards',
    async ({ assetId, rewardId }, { rejectWithValue, dispatch, getState }) => {
      try {
        // TO DO - replace with new api
        const fetchURL = `${config.API_URL}/api/ews-event-streaming-service/captainup/player/rewards/assets/claim`;

        const response = await axiosInstance.post(fetchURL, { assetId, rewardId });
        dispatch(fetchGamificationPlayer());
        const limit = getState().captainUp.achievements.achievementIds.length;
        dispatch(achievementsThunks.getAchievements({ page: 1, limit, isPolling: true }));
        return response.data.data;
      } catch (err) {
        if (Axios.isCancel(err)) {
          return rejectWithValue(null);
        }
        return rejectWithValue(getError.default());
      }
    },
  ),
};

const achievements = createSlice({
  name: 'achievements',
  initialState,
  reducers: {
    resetAchievements: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(achievementsThunks.getAchievements.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(achievementsThunks.getAchievements.fulfilled, (state, action) => {
      const { limit, page, isPolling } = action.meta.arg;

      if (!isPolling) state.page = page;
      state.hasMore = page * limit < action.payload?.totalItems || false;

      normalizeAchievements(state.normalizedAchievements, action.payload?.items);
      state.achievementIds = Object.keys(state.normalizedAchievements);
      state.totalItems = action.payload?.totalItems || 0;
      state.hasFetched = true;
      state.isLoading = false;
    });
    builder.addCase(achievementsThunks.getAchievements.rejected, (state, action) => {
      state.hasFetched = false;
      if (action.payload) state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(logoutUser, () => initialState);
  },
});

export const { resetAchievements } = achievements.actions;
export const { reducer: achievementsReducer } = achievements;

export const selectAchievements = {
  getAll: (state: RootState): string[] => state.captainUp.achievements.achievementIds || [],
  totalItems: (state: RootState): number => state.captainUp.achievements.totalItems,
  page: (state: RootState): number => state.captainUp.achievements.page,
  isLoading: (state: RootState): boolean => state.captainUp.achievements.isLoading,
  hasFetched: (state: RootState): boolean => state.captainUp.achievements.hasFetched,
  hasMore: (state: RootState): boolean => state.captainUp.achievements.hasMore,
  hasError: (state: RootState): boolean => state.captainUp.achievements.hasError,
  getById:
    (id: string) =>
    (state: RootState): Achievements.Item =>
      state.captainUp.achievements.normalizedAchievements[id],
};
