import { LaunchParam } from './casinoLaunchTypes';

export const launchParamsConfig: LaunchParam[] = [
  { key: 'vertical' },
  { key: 'providerAlias' },
  { key: 'gameName' },
  { key: 'gameId' },
  { key: 'agent' },
  { key: 'isFreePlay', isOptional: true, default: 'true' },
  { key: 'closeUrl', isOptional: true, default: '' },
  { key: 'svara', isOptional: true, default: '' },
];

export const GameLaunchTranslationKeys = {
  prizeDropIconText: 'Casino.PrizeDrop.prizeDropIconText',
  challengeBtn: 'GameLaunch.Challenges.challengeBtn',
  challengeTitle: 'GameLaunch.Challenges.title',
  challengeProgress: 'GameLaunch.Challenges.progress',
  quickDepositTopBarButton: 'GameLaunch.QuickDeposit.topBarButton',
  quickDepositPopoverButton: 'GameLaunch.QuickDeposit.popoverButton',
  quickDepositPopover: 'GameLaunch.QuickDeposit.popover',
  missionCollectionPopover: 'GameLaunch.MissionCollection.popoverInfo',
  noJackpotPopoverText: 'GameLaunch.JackpotCampaign.noJackpotText',
  noMissionPopoverText: 'GameLaunch.Mission.noMissionText',
  noTournamentsPopoverText: 'GameLaunch.Tournaments.noTournamentsText',
};
export const KycVerificationModal = {
  modalText: 'GameLaunch.ModalVerification.kycVerification',
  modalDescriptionSvaraApp: 'GameLaunch.ModalVerification.kycVerificationSvara',
  modalButton: 'GameLaunch.ModalVerification.kycVerificationButton',
  cancelButton: 'MyAccount.Exclusions.cancelBtn',
  closeButton: 'GameLaunch.ModalVerification.closeKycModal',
  accountVerification: 'GameLaunch.ModalVerification.kycAccountVerification',
};

export enum WidgetAlignmentItems {
  LEFT = 'flex-start',
  CENTER = 'center',
  RIGHT = 'flex-end',
  TOP = 'flex-start',
  BOTTOM = 'flex-end',
}

export enum OpenLaunchTypes {
  OPENLAUNCHER = 'open-launcher',
  LAUNCHER = 'launcher',
}

export enum SvaraLaunchTypes {
  FREE = 'free',
  PAID = 'paid',
  PRIVATE = 'private',
  FREE_CUSTOM = 'freeCustom',
  CREATE = 'create',
  LOBBY = 'lobby',
}

export const DEFAULT_GAME_ID_LOBBY = 'in_lobby';

export const SVARA_LAUNCH_GAME_ID = '-84428';

export const DEPOSIT_ROUTE = '/my-account/bank';
