import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Modal } from 'components/shared/Modal';
import PrizeDropTable from 'components/shared/prizeDrop/components/PrizeDropTableContainer';
import PrizeDropTableRow from 'components/shared/prizeDrop/components/PrizeDropTableRow';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch } from 'store';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import { PromoUI } from '../components/PromoUI';
import { PromotionTypes } from '../types/promotions.types';

type Props = {
  rewards: PromotionTypes.PersonalRewards[];
  title: string;
  text: string;
};

const PrizeDropRewardedPlayersModal: React.FC<Props> = ({ rewards, title, text }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isTablet = useMediaQuery({ query: `(min-width: 576px)` });

  return (
    <Modal visible className="modal-terms" size="xl">
      <Modal.Close closeText={t(Promotions.Common.closeBtn)} closeAction={() => dispatch(hideRootModal())} />
      <Modal.Body className={`custom-scrollbars ${isTablet ? 'p-4' : 'p-2'} prm-page__content`} padding="lg">
        <PromoUI.PageBoxTitle title={title} />
        <p>{text && <PromoUI.RankingBox isTitleCell text={text} />}</p>
        <PrizeDropTable>
          <PrizeDropTableRow
            isHeader
            firstColText={t(Promotions.PrizeDrop.player)}
            secondColText={t(Promotions.PrizeDrop.win)}
            isPromotion
          />
          {rewards?.map((reward, index) => (
            <PrizeDropTableRow
              key={`reward-${index}`}
              firstColText={reward.username || ''}
              secondColText={reward.bonusName || ''}
              isPromotion
            />
          ))}
        </PrizeDropTable>
      </Modal.Body>
    </Modal>
  );
};

export default PrizeDropRewardedPlayersModal;
