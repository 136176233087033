import { useMemo } from 'react';
import { getRemainingTime, TimeDataType } from 'utils/dateUtils';

export const useGetDateInfo = (
  startDate: string,
  endDate: string,
): {
  showDateDifference: boolean;
  dateDifference: TimeDataType;
  passedDate: boolean;
  isActiveDate: boolean;
  dateDifferenceEnd: TimeDataType;
} => {
  const dateDifference = getRemainingTime(startDate);
  const dateDifferenceEnd = getRemainingTime(endDate);

  const showDateDifference = useMemo(() => {
    const dateNow = new Date().getTime();
    const dateStart = new Date(startDate).getTime();
    return dateNow < dateStart;
  }, [startDate]);

  const passedDate = useMemo(() => {
    if (endDate) {
      const dateNow = new Date().getTime();
      const dateEnd = new Date(endDate).getTime();

      return dateNow > dateEnd;
    } else {
      return false;
    }
  }, [endDate]);

  const isActiveDate = useMemo(() => {
    const dateNow = new Date().getTime();
    const dateEnd = new Date(endDate).getTime();

    return dateNow < dateEnd;
  }, [endDate]);

  return {
    showDateDifference,
    passedDate,
    dateDifference,
    isActiveDate,
    dateDifferenceEnd,
  };
};
