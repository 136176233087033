import { memo } from 'react';
import { ReelsGrid } from './components/ReelsGrid.styled';
import SpringReelItem from './SpringReelItem';
import { NotificationTypes } from '../../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import useWinItemState from '../../hooks/useWinItemState';

type Props = {
  setAnimationComplete: (isVisible: boolean) => void;
  animationComplete: boolean;
  animationParams: NotificationTypes.GamificationModalParams;
  notification: NotificationTypes.PopUpNotification;
  isSpinning: boolean;
  spinFinished: () => void;
  reelSequences: number[];
};

const SpringReelItems: React.FC<Props> = ({
  animationComplete,
  animationParams,
  setAnimationComplete,
  notification,
  isSpinning,
  spinFinished,
  reelSequences,
}) => {
  const { bonusData } = notification;

  const { state, handlers } = useWinItemState();

  if (!bonusData) return null;

  return (
    <ReelsGrid>
      {bonusData?.otherOptions?.map((item: string, index: number) => (
        <SpringReelItem
          key={`${item}-${index}`}
          index={index}
          notification={notification}
          winItemIndex={state.winItemIndex}
          setWinItemIndex={handlers.setWinItemIndex}
          setAnimationComplete={setAnimationComplete}
          animationComplete={animationComplete}
          animationParams={animationParams}
          isSpinning={isSpinning}
          spinFinished={spinFinished}
          reelId={reelSequences[index]}
        />
      ))}
    </ReelsGrid>
  );
};

export default memo(SpringReelItems);
