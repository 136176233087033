import i18next from 'i18next';
import { setIsPrintingEjected } from 'modules/retail/modules/cashier/slice/cashier.slice';
import { formatAmountToBRL } from 'modules/retail/modules/cashier/utils';
import {
  LastPartContentParams,
  OutcomesData,
  PrintBetContentParams,
  PrintEventContentDataParams,
  PrintEventContentManualReofferParams,
  PrintFirstPartDataParams,
  PrintFirstPartManualReofferParams,
  PrintLastPartDataManualReofferParams,
  PrintManualReofferDataParams,
  PrintSummaryContentManualReofferParams,
  PrintSummaryContentParams,
  PrintTerminalDataParams,
  ProcessCustomBetSelectionParams,
  ProcessManualReofferCustomBetParams,
  ProcessRegularBetSelectionParams,
  SummaryDataXparams,
} from 'modules/retail/modules/ssbt/types/ssbt.types';
import { commands, formatManualReofferRequestNumber, getRetailInfo } from 'modules/retail/shared/utils/retail.utils';
import { store } from 'store';
import { getError, toDecimal, toFixed } from 'utils/common/helpersCommon';
import {
  lastPartManualReofferMobile,
  mapBetbuilderEventsDataMobile,
  mapBetBuilderSignManualReofferMobile,
  mapBetbuilderSignMobile,
  mapBetbuilderSummaryBetDataMobile,
  mapEventContentRegularDataMobile,
  mapEventContentSingleDataMobile,
  mapEventContentSportEventNameMobile,
  mapFirstPartManualReofferMobile,
  mapFirstPartReceiptInfoMobile,
  mapLastPartContentMobile,
  mapManualReofferBetsDataMobile,
  mapSecondPartManualReofferMobile,
  mapSummaryDataMobile,
  mapSummaryManualReofferMobile,
  mapSummaryVirtualDataMobile,
} from './mobilePrintMethods';
import { printSummaryContentManualReoffer } from '../manualReoferMethods/manualReofer.utils';
import { mapBetBuilderEventsDataManualReoffer } from '../manualReoferMethods/manualReoferMethods';
import { printReceipt } from '../printUtils/helperPrintFunctions';
export const printFirstPartDataMobile = async ({
  getState,
  ticketId,
  timestamp,
  isDuplicate,
  address,
  posName,
  mnemonicCode,
  playerCpf,
}: PrintFirstPartDataParams): Promise<void> => {
  const retailAddress = getState()?.common?.general?.layoutConfig?.['platform']?.general?.retailAddress;

  const mapFirstPartReceiptInfoMobileObj = {
    commands,
    ticketId,
    timestamp,
    retailAddress,
    isDuplicate,
    posAddress: address,
    posName,
    currentMnemonicCode: mnemonicCode,
    playerCpf,
  };

  try {
    await printReceipt(mapFirstPartReceiptInfoMobile(mapFirstPartReceiptInfoMobileObj));
  } catch (error) {
    getError.message(error);
  }
};
const processCustomBetSelectionMobile = async <K extends { outcomeId: number | string; totalCombinations: number }>({
  value,
  getState,
  currency,
  isSingle,
  singles,
  getSingleBetProps,
  isCurrencyBeforeAmount,
}: ProcessCustomBetSelectionParams<K>): Promise<void> => {
  const mapBetbuilderSummaryBetDataMobileObj = {
    value,
    currency,
    isSingle,
    singles,
    getSingleBetProps,
    isCurrencyBeforeAmount,
  };
  try {
    await printReceipt(mapBetbuilderSignMobile({ value }));

    for (const customBet of value.customBetOutcomes) {
      await printReceipt(mapBetbuilderEventsDataMobile({ customBet, getState }));
    }
    await printReceipt(mapBetbuilderSummaryBetDataMobile(mapBetbuilderSummaryBetDataMobileObj));
  } catch (error) {
    getError.message(error);
  }
};
const processRegularBetSelectionMobile = async <
  T extends { outcomeId: string; selectedSystem: string; totalCombinations: number },
  K extends OutcomesData,
>({
  value,
  getState,
  sportEventId,
  isSingle,
  getSingleBetProps,
  singles,
  outcomes,
  currency,
  isCurrencyBeforeAmount,
}: ProcessRegularBetSelectionParams<T, K>) => {
  const mapEventContentRegularDataObj = {
    value,
    getState,
    sportEventId,
    isSingle,
    getSingleBetProps,
    singles,
    outcomes,
    currency,
    isCurrencyBeforeAmount,
  };
  await printReceipt(mapEventContentRegularDataMobile(mapEventContentRegularDataObj));
};
export const printEventContentDataMobile = async <
  T extends OutcomesData,
  K extends {
    id: number;
    selectedSystem: string;
    outcomeId: string;
    totalCombinations: number;
    stake: number;
    win: number;
  },
>({
  outcomes,
  summary,
  getState,
  currency,
  isCurrencyBeforeAmount,
}: PrintEventContentDataParams<T, K>): Promise<void> => {
  const systems = summary.filter((x) => x.selectedSystem !== 'singles');
  const singles = summary.filter((x) => x?.selectedSystem === 'singles');
  const hasSystems = systems !== undefined && systems.length > 0;
  const filterInvalidSection = (selection): boolean => {
    if (hasSystems) return true;
    return singles.findIndex((x) => x.outcomeId === selection.id) !== -1;
  };
  const getSingleBetProps = (value) => {
    return singles.find((x) => x.outcomeId === value.id);
  };
  const filterOutcomes = outcomes.filter(filterInvalidSection);

  try {
    let hasPrintedEventDetails = false; // Flag to control one-time printing
    const groupedBySportEventId = {};

    // group by sportEventId
    for (const value of filterOutcomes) {
      const { sportEventId } = value;
      if (!groupedBySportEventId[sportEventId]) {
        groupedBySportEventId[sportEventId] = [];
      }
      groupedBySportEventId[sportEventId].push(value);
    }

    //  process each group
    for (const sportEventId in groupedBySportEventId) {
      const events = groupedBySportEventId[sportEventId];
      hasPrintedEventDetails = false; // Reset for each sportEventId group

      for (const value of events) {
        const isSingle = singles.findIndex((x) => x.outcomeId === value.id) !== -1;

        if (value.isCustomBetSelection) {
          const processCustomBetSelectionMobileObj = {
            value,
            getState,
            isSingle,
            singles,
            getSingleBetProps,
            currency,
            isCurrencyBeforeAmount,
          };
          await processCustomBetSelectionMobile(processCustomBetSelectionMobileObj);
        } else {
          const isVirtual = /^3/.test(sportEventId);

          if (isVirtual) {
            if (!hasPrintedEventDetails) {
              await printReceipt(mapEventContentSportEventNameMobile({ value }));
              hasPrintedEventDetails = true;
            }
            const mapEventContentSingleDataObj = {
              value,
              isSingle,
              getSingleBetProps,
              singles,
              outcomes,
              currency,
              isCurrencyBeforeAmount,
            };
            await printReceipt(mapEventContentSingleDataMobile(mapEventContentSingleDataObj));
          } else if (!isVirtual) {
            const processRegularBetSelectionMobileObj = {
              value,
              getState,
              sportEventId,
              isSingle,
              getSingleBetProps,
              singles,
              outcomes,
              currency,
              isCurrencyBeforeAmount,
            };
            await processRegularBetSelectionMobile(processRegularBetSelectionMobileObj);
          }
        }
      }
    }
  } catch (error) {
    getError.message(error);
  }
};
export const printSummaryContentMobile = async <
  K extends SummaryDataXparams,
  T extends { id: string; sportEventId: number; sportId?: number; isCustomBetSelection?: boolean },
>({
  summary,
  getState,
  outcomes,
  currency,
  isCurrencyBeforeAmount,
}: PrintSummaryContentParams<T, K>): Promise<void> => {
  const isVermantia = outcomes.every((outcome) => outcome?.sportEventId?.toString()?.startsWith('3'));
  const selectionTypes = new Set<string>();
  try {
    for (const x of summary) {
      selectionTypes.add(x.selectedSystem);
      try {
        if (x.selectedSystem !== 'singles' && !isVermantia) {
          const mapSummaryDataObj = { x, getState, toDecimal, currency, isCurrencyBeforeAmount };
          await printReceipt(mapSummaryDataMobile(mapSummaryDataObj));
        }
      } catch (err) {
        getError.message(err);
      }
    }
    if (isVermantia && selectionTypes.size > 0) {
      await printReceipt(mapSummaryVirtualDataMobile({ selectionTypes }));
    }
  } catch (err) {
    getError.message(err);
  }
};
export const lastPartContentMobile = async <K extends OutcomesData>({
  _stake,
  _totalWin,
  getState,
  ticketId,
  totalOdds,
  totalCombinations,
  isDuplicate,
  currency,
  pin,
  dailySequenceNumber,
  outcomes,
  isLinearCodeOnReceipt,
  retailInfo,
}: LastPartContentParams<K>): Promise<void> => {
  const ticketIdData = {
    str: `${ticketId}`,
  };
  const retailAddress = getState()?.common?.general?.layoutConfig?.['platform']?.general?.retailAddress;
  const isVermantia = outcomes?.every((outcome) => outcome?.sportEventId?.toString()?.startsWith('3'));
  const isFootball = outcomes?.every((outcome) => outcome?.sportId === 1001);
  const isVermantiaFootballOnly = isVermantia && isFootball;
  const mapLastPartContentMobileObj = {
    ticketIdData,
    totalOdds,
    _stake,
    _totalWin,
    dailySequenceNumber,
    terminalBetPin: pin,
    retailAddress,
    totalCombinations,
    isDuplicate: isDuplicate ?? false,
    isVermantiaFootballOnly: isVermantiaFootballOnly ?? false,
    isLinearCodeOnReceipt: isLinearCodeOnReceipt ?? false,
    retailInfo,
    currency,
  };
  try {
    await printReceipt(mapLastPartContentMobile(mapLastPartContentMobileObj));
  } catch (error) {
    getError.message(error);
  }
};
export const printFirstPartManualReofferMobile = async ({
  getState,
  ticketId,
}: PrintFirstPartManualReofferParams): Promise<void> => {
  const requestId = formatManualReofferRequestNumber(ticketId);
  const retailAddress = getState()?.common?.general?.layoutConfig?.['platform']?.general?.retailAddress;
  try {
    await printReceipt(mapFirstPartManualReofferMobile({ requestId: requestId, retailAddress: retailAddress }));
  } catch (errorr) {
    getError.message(errorr);
  }
};
export const printEventContentManualReofferMobile = async ({
  selections,
  bets,
  getState,
  currency,
  isCurrencyBeforeAmount,
}: PrintEventContentManualReofferParams): Promise<void> => {
  const systems = bets.filter((x) => x.selectedSystem !== 'singles');
  const singles = bets.filter((x) => x?.selectedSystem === 'singles');
  const hasSystems = systems !== undefined && systems.length > 0;
  const filterSelections = selections.filter((s, index) =>
    singles?.find((e) => e.selectionRefs[0].selectionIndex === index),
  );
  const filterSelectionss = hasSystems ? selections : filterSelections;
  try {
    for (const value of filterSelectionss) {
      if (value?.isCustomBetSelection) {
        await processManualReofferCustomBetMobile({ value, getState });
      } else {
        await printReceipt(
          mapSecondPartManualReofferMobile({ value, bets, getState, currency, isCurrencyBeforeAmount }),
        );
      }
    }
  } catch (errorr) {
    getError.message(errorr);
  }
};
export const printSummaryContentManualReofferMobile = async ({
  bets,
  getState,
  currency,
  isCurrencyBeforeAmount,
}: PrintSummaryContentManualReofferParams): Promise<void> => {
  try {
    await printReceipt(mapSummaryManualReofferMobile({ bets, getState, currency, isCurrencyBeforeAmount }));
  } catch (error) {
    getError.message(error);
  }
};
export const printLastPartDataManualReofferMobile = async ({
  getState,
}: PrintLastPartDataManualReofferParams): Promise<void> => {
  const retailAddress = getState()?.common?.general?.layoutConfig?.['platform']?.general?.retailAddress;
  try {
    await printReceipt(lastPartManualReofferMobile({ retailAddress }));
  } catch (error) {
    getError.message(error);
  }
};
export const processManualReofferCustomBetMobile = async ({
  value,
  getState,
}: ProcessManualReofferCustomBetParams): Promise<void> => {
  await printReceipt(mapBetBuilderSignManualReofferMobile({ value }));

  for (const customBet of value.customBetSelections) {
    const mapBetBuilderEventsDataManualReofferObj = {
      customBet,
      value,
      getState,
    };
    await printReceipt(mapBetBuilderEventsDataManualReoffer(mapBetBuilderEventsDataManualReofferObj));
  }
};
export const printBetContentMobile = async ({
  bets,
  currency,
  isCurrencyBeforeAmount,
}: PrintBetContentParams): Promise<void> => {
  try {
    for (let i = 0; i < bets.length; i++) {
      await printReceipt(mapManualReofferBetsDataMobile({ bets, currency, isCurrencyBeforeAmount }));
    }
  } catch (err) {
    getError.message(err);
  }
};
export const printManualReofferDataMobile = async ({
  ticketId,
  getState,
  selections,
  bets,
  currency,
  isCurrencyBeforeAmount,
}: PrintManualReofferDataParams): Promise<void> => {
  try {
    await printFirstPartManualReofferMobile({ getState, ticketId });
    await printEventContentManualReofferMobile({
      selections,
      bets,
      getState,
      currency,
      isCurrencyBeforeAmount,
    });
    await printSummaryContentManualReoffer({ bets, getState, currency, isCurrencyBeforeAmount });
    await printBetContentMobile({ bets, currency, isCurrencyBeforeAmount });
    await printLastPartDataManualReofferMobile({ getState });
  } catch (error) {
    getError.message(error);
  }
};

export const printTerminalDataMobile = async <T extends OutcomesData, K extends SummaryDataXparams>({
  isDuplicate,
  ticketId,
  timestamp,
  totalWin,
  outcomes,
  stake,
  summary,
  totalOdds,
  getState,
  totalCombinations,
  currency,
  retailInfo,
  isCurrencyBeforeAmount,
}: PrintTerminalDataParams<T, K>): Promise<void> => {
  const { address, posName, pin, dailySequenceNumber, mnemonicCode, playerCpf } = getRetailInfo(retailInfo);
  const isLinearCodeOnReceipt =
    getState()?.common?.general?.layoutConfig?.['platform']?.general?.isTicketCodeLinear || false;

  const currencySettings = getState()?.common?.general?.currencySettings;
  const currencySymbol = currencySettings?.find((c) => c.name === currency)?.nameTranslations || currency;

  const _totalWin = {
    str: `${i18next.t(`BetSlip.PlaceBetButton.possibleWinText`)}: ${
      isCurrencyBeforeAmount
        ? `${currencySymbol} ${formatAmountToBRL(toDecimal(totalWin))}`
        : `${toFixed(toDecimal(totalWin), 2)} ${currency}`
    }`,
  };
  const _stake = {
    str: `${i18next.t(`BetSlip.BetSlipConfirmation.summaryTotalStakeText`)}: ${
      isCurrencyBeforeAmount ? `${currencySymbol} ${formatAmountToBRL(stake)}` : `${toFixed(stake, 2)} ${currency}`
    }`,
  };

  const printFirstPartDataMobileParamsObj = {
    getState,
    currency,
    ticketId,
    timestamp,
    isDuplicate,
    address,
    posName,
    mnemonicCode,
    playerCpf,
  };

  const printEventContentDataMobileParamsObj = {
    outcomes,
    summary,
    getState,
    currency,
    isCurrencyBeforeAmount,
  };

  const printSummaryContentMobileParamsObj = {
    summary,
    getState,
    outcomes,
    currency,
    isCurrencyBeforeAmount,
  };

  const lastPartContentMobileParamsObj = {
    _stake,
    _totalWin,
    getState,
    ticketId,
    totalOdds,
    totalCombinations,
    isDuplicate,
    currency,
    pin,
    dailySequenceNumber,
    outcomes,
    isLinearCodeOnReceipt,
    retailInfo,
    isCurrencyBeforeAmount,
  };

  try {
    store.dispatch(setIsPrintingEjected(true));

    await printFirstPartDataMobile(printFirstPartDataMobileParamsObj);

    await printEventContentDataMobile(printEventContentDataMobileParamsObj);

    await printSummaryContentMobile(printSummaryContentMobileParamsObj);

    await lastPartContentMobile(lastPartContentMobileParamsObj);
  } catch (error) {
    getError.message(error);
  } finally {
    setTimeout(() => {
      store.dispatch(setIsPrintingEjected(false));
    }, 2500);
  }
};
