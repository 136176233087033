import { useState } from 'react';

export const useBarcode = () => {
  const [barcode, setBarcode] = useState('');
  const [startTime, setStartTime] = useState(0);

  const startScanning = () => setStartTime(Date.now());

  const addCharacter = (character: string) => setBarcode((prevBarcode) => prevBarcode + character);

  const reset = () => {
    setBarcode('');
    setStartTime(0);
  };

  const isScanningFast = () => Date.now() - startTime < 600; // Checks if the time elapsed since scanning started is less than 600 milliseconds, indicating a fast, continuous scan.

  return {
    barcode,
    startScanning,
    addCharacter,
    reset,
    isScanningFast,
  };
};
