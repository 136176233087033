import React from 'react';
import Text from 'components/shared/Text';
import { isEmpty } from 'utils/common/helpersCommon';

/** EventUI Additional Markets Button */
interface MarketsButtonProps {
  markets?: number;
  onClick: () => void;
}

export const MarketsButton: React.FC<MarketsButtonProps> = ({ markets, onClick }) => {
  if (isEmpty(markets)) return <></>;
  return <Text onClick={onClick} className={`markets`} text={`+ ${markets}`} />;
};
