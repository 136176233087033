import { useCallback, useEffect, useState } from 'react';
import {
  resetCashierCancelVoucherStatus,
  resetCashierPayoutVoucherStatus,
  resetCashierVoucherData,
  resetVoucherInfoCashier,
  selectVoucherData,
} from 'modules/retail/shared/slice/voucher.slice';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { clearModalsQueue } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { useCashierModalPrompt } from '../../hooks/useCashierModalPrompt';
import { useFinancialInfo } from '../finance/useFinanceInfo';

export const useVoucherModal = () => {
  const [isCreateVoucher, setIsCreateVoucher] = useState(true);
  const { closeModal } = useHandleModals();
  const { isPrintingEject } = useFinancialInfo();
  const dispatch = useAppDispatch();
  const payoutVoucherStatus = useAppSelector(selectVoucherData.payoutVoucherStatus);
  const cancelVoucherStatus = useAppSelector(selectVoucherData.cancelVoucherStatus);

  const isCashierModalPrompted = useCashierModalPrompt();

  const closeVoucherModal = () => {
    if (isPrintingEject) {
      return;
    }
    if (payoutVoucherStatus === 'failure') {
      dispatch(resetCashierPayoutVoucherStatus());
    }
    if (cancelVoucherStatus === 'failure') {
      dispatch(resetCashierCancelVoucherStatus());
    }
    dispatch(resetCashierVoucherData());
    dispatch(resetVoucherInfoCashier());
    dispatch(clearModalsQueue());
    closeModal();
  };

  const closeVoucherModalWhenCashierPrompted = useCallback(() => {
    if (payoutVoucherStatus === 'failure') {
      dispatch(resetCashierPayoutVoucherStatus());
    }
    if (cancelVoucherStatus === 'failure') {
      dispatch(resetCashierCancelVoucherStatus());
    }
    dispatch(resetCashierVoucherData());
    dispatch(resetVoucherInfoCashier());
    closeModal();
  }, [payoutVoucherStatus, cancelVoucherStatus]);

  useEffect(() => {
    if (isCashierModalPrompted) {
      closeVoucherModalWhenCashierPrompted();
    }
  }, [isCashierModalPrompted]);

  useEffect(() => {
    if (isCreateVoucher) {
      if (payoutVoucherStatus === 'failure') {
        dispatch(resetCashierPayoutVoucherStatus());
      }

      if (cancelVoucherStatus === 'failure') {
        dispatch(resetCashierCancelVoucherStatus());
      }
    }
  }, [dispatch, isCreateVoucher, payoutVoucherStatus, cancelVoucherStatus]);

  const handleToggleVoucherTab = useCallback(
    (newState) => {
      if (newState === isCreateVoucher) {
        return;
      }

      if (newState) {
        dispatch(resetCashierVoucherData());
      } else {
        dispatch(resetVoucherInfoCashier());
      }

      setIsCreateVoucher(newState);
    },
    [dispatch, isCreateVoucher],
  );

  return { closeVoucherModal, handleToggleVoucherTab, isCreateVoucher };
};
