import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { UI } from 'components/shared/UI';
import { useNavigateToBankTab } from 'pages/myAccount/tabs/cashier/utils/cashier.utils';
import { refreshWidget } from 'shared/common/features/integrations/cashierWidget/cashierWidget.slice';
import { MyProfileBalance } from 'shared/common/features/myProfile/components';
import { useAppDispatch } from 'store';
import { formatBalance } from 'utils/common/helpersCommon';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import { MPDUI } from '../../components/MPDUI';
import { useMyProfileConfig } from '../../hooks/useMyProfileConfig';
import { useUserBalanceByConfig } from '../../hooks/useUserBalanceByConfig';
import { showMyProfileModal } from '../../slice/myProfile.slice';

const MyProfileStatisticsLayout2 = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userBalances = useUserBalanceByConfig();
  const bankNavigate = useNavigateToBankTab();
  const hideDepositButton = useMyProfileConfig()?.hideDepositButton;

  const navigateToBank = () => {
    dispatch(showMyProfileModal(false));
    bankNavigate('deposit');
    dispatch(refreshWidget());
  };

  return (
    <MPDUI.Section section="balance">
      {Object.keys(userBalances)?.map((balanceType) => (
        <Flex.ACJB key={balanceType} className="mpd-stat-item">
          <MPDUI.Text variant="secondary" text={t(`MyProfile.Statistics.${balanceType}`)} isTruncated={false} />
          <MyProfileBalance amount={formatBalance(userBalances[balanceType])} dataQid="balanceText" />
        </Flex.ACJB>
      ))}

      {!hideDepositButton && (
        <UI.Button
          data-qid="userDepositBtn"
          size="md"
          onClick={navigateToBank}
          text={t(MyProfile.Button.depositProfileBtn)}
        />
      )}
    </MPDUI.Section>
  );
};

export default memo(MyProfileStatisticsLayout2);
