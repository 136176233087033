import React from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { General } from 'shared/common/features/general/types/generalSlice.types';
import { Breakpoints } from 'theme/Theme';
import { fitCover } from 'utils/common/cdnConfig';
import config from 'utils/config';
import { useAuthBanner } from './useAuthBanner';

/** AuthBanner */
interface AuthBannerProps {
  className?: string;
  type: General.AuthBannerType;
}

const AuthBanner: React.FC<AuthBannerProps> = ({ className, type }) => {
  const { banner, bannerPath, onClick } = useAuthBanner(type);
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });

  if (!banner?.isVisible) return null;

  const wrapperClass = clsx('login-banner', className);

  const mobileSize = 86 * 2;
  const regDesktopSize = 150 * 2;

  const size = isDesktop && type === 'registration' ? regDesktopSize : mobileSize;

  const bannerUrl = `${config.CDN_IMAGE_API}${fitCover('auto', size, 'cover')}/${bannerPath}`;

  return (
    <div className={wrapperClass} data-type={type} style={{ backgroundImage: `url(${bannerUrl})` }} onClick={onClick} />
  );
};

export default AuthBanner;
