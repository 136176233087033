import { createSlice, createEntityAdapter, PayloadAction, createSelector, EntityId } from '@reduxjs/toolkit';
import { FormFieldTypes } from 'components/shared/forms/formTypes';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { WS } from 'shared/common/features/websockets/types/webSockets.types';
import { wsActions } from 'shared/common/features/websockets/webSocketActions';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { PlayerLimits } from '../types/limits.types';

export const playerLimitsThunks = {
  fetchLimitsConfig: createAbortThunk<PlayerLimits.Verticals, undefined, string | null>(
    'playerLimits/fetchLimits',
    async () => {
      const url = `${config.API_URL}/api/ews-crm/player/players-settings/player-limits-struct`;

      const response = await axiosInstance.get<PlayerLimits.LimitsResponse>(url);
      return response.data?.Verticals;
    },
  ),
  fetchTestForm: createAbortThunk<FormFieldTypes[], undefined, string | null>(
    'playerLimits/fetchTestForm',
    async () => {
      const url = `${config.API_URL}/api/ews-crm/public/cms/player-content?key=declaration_addictionprevention_form`;

      const response = await axiosInstance.get<FormFieldTypes[]>(url);
      return response.data;
    },
  ),
  saveAddictionPreventionResults: createAbortThunk<
    PlayerLimits.AddictionResponse | null,
    {
      params: Record<string, string>;
      onSubmit: ({
        successfullyPassed,
        afterDeclaration,
      }: {
        successfullyPassed: boolean;
        afterDeclaration: boolean;
      }) => Promise<void>;
    },
    string
  >('playerLimits/saveAddictionPreventionResults', async ({ params, onSubmit }, { dispatch, rejectWithValue }) => {
    const dataToSend = {};
    for (const key in params) {
      if (!params[key]) continue;
      dataToSend[key] = !!Number(params[key]);
    }
    try {
      const response = await axiosInstance.post<PlayerLimits.AddictionResponse>(
        `${config.API_URL}/api/ews-crm/player/players/declaration/addiction-prevention`,
        dataToSend,
      );
      await onSubmit({ successfullyPassed: response.data?.successfullyPassed, afterDeclaration: true });
      dispatch(hideRootModal());

      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseDataMessage(err));
    }
  }),
  savePlayerLimit: createAbortThunk<
    string,
    { limits: PlayerLimits.SavePlayerLimitData[]; afterDeclaration: boolean },
    string
  >('playerLimits/savePlayerLimit', async ({ limits, afterDeclaration }, { dispatch, rejectWithValue }) => {
    const dataForSend = limits.map((param) => {
      const { periodType, limitSubType, value, limitType } = param;
      const limit = parseFloat(`${value}`.replace(/[^\d.-]/g, '')) || 0;

      return {
        limitTypeString: limitType,
        limitPeriodTypeString: periodType,
        limitSubTypeString: limitSubType,
        limit,
        afterDeclaration,
      };
    });
    try {
      const url = `${config.API_URL}/api/ews-crm/player/players-settings/player-limit`;
      const response = await axiosInstance.put(url, dataForSend);
      dispatch(hideRootModal());
      return response.data;
    } catch (err) {
      dispatch(hideRootModal());
      return rejectWithValue(getError.responseDataMessage(err));
    }
  }),
};

const { fetchLimitsConfig, savePlayerLimit, fetchTestForm, saveAddictionPreventionResults } = playerLimitsThunks;

const playerLimitsAdapter = createEntityAdapter<PlayerLimits.PlayerLimit, EntityId>({
  selectId: (limit) => `${limit.limitSubType}-${limit.limitType}-${limit.periodType}`,
});

const initialState: PlayerLimits.State = {
  isLoading: false,
  error: '',
  hasFetched: false,
  hasUpdated: false,
  limitsConfig: null,
  playerLimits: playerLimitsAdapter.getInitialState(),
  addictionTest: null,
  addictionResults: null,
  addictionResultsError: '',
  addictionResultsLoading: false,
  needDeclaration: false,
};

const limitsSlice = createSlice({
  name: 'playerLimits',
  initialState,
  reducers: {
    setPlayerLimits(state, action) {
      playerLimitsAdapter.setAll(state.playerLimits, action.payload);
    },
    setLimitsDeclarationNeed(state, action) {
      state.needDeclaration = action.payload;
    },
    clearAddictionTest(state) {
      state.addictionTest = null;
      state.addictionResultsError = '';
    },
    clearLimitState: (state) => ({
      ...initialState,
      addictionResults: state.addictionResults,
      playerLimits: state.playerLimits,
      needDeclaration: state.needDeclaration,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, () => initialState);
    builder.addCase(wsActions.wsPlayerLimits, (state, action: PayloadAction<WS.Limits>) => {
      playerLimitsAdapter.setAll(state.playerLimits, action.payload?.playerLimits);
    });
    builder
      .addCase(saveAddictionPreventionResults.pending, (state) => {
        state.addictionResultsLoading = true;
        state.addictionResultsError = '';
      })
      .addCase(saveAddictionPreventionResults.fulfilled, (state, action) => {
        state.addictionResults = action.payload;
        state.addictionResultsLoading = false;
      })
      .addCase(saveAddictionPreventionResults.rejected, (state, action) => {
        state.addictionResultsError = action.payload || '';
        state.addictionResultsLoading = false;
      });
    builder
      .addCase(fetchLimitsConfig.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLimitsConfig.fulfilled, (state, action) => {
        state.limitsConfig = action.payload;
        state.isLoading = false;
        state.hasFetched = true;
      })
      .addCase(fetchLimitsConfig.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      });
    builder
      .addCase(savePlayerLimit.fulfilled, (state) => {
        state.hasUpdated = true;
      })
      .addCase(savePlayerLimit.rejected, (state, action) => {
        state.error = action.payload as string;
      });
    builder.addCase(fetchTestForm.fulfilled, (state, action) => {
      state.addictionTest = action.payload;
    });
  },
});

export const { setPlayerLimits, clearLimitState, clearAddictionTest, setLimitsDeclarationNeed } = limitsSlice.actions;

export const playerLimitsSelectors = playerLimitsAdapter.getSelectors(
  (state: RootState) => state.myAccount.limits.playerLimits,
);

export const selectLimitsByIds = createSelector(
  [playerLimitsSelectors.selectEntities, (_, ids: string[]) => ids],
  (entities, ids) => {
    return ids.map((id) => entities[id]) as PlayerLimits.PlayerLimit[];
  },
);

export const selectMyAccountLimits = {
  limitsConfig: (state: RootState): PlayerLimits.Verticals | null => state.myAccount.limits.limitsConfig,
  error: (state: RootState): string | null => state.myAccount.limits.error,
  addictionTest: (state: RootState) => state.myAccount.limits.addictionTest,
  addictionResults: (state: RootState) => state.myAccount.limits.addictionResults,
  addictionResultsLoading: (state: RootState) => state.myAccount.limits.addictionResultsLoading,
  addictionResultsError: (state: RootState) => state.myAccount.limits.addictionResultsError,
  needDeclaration: (state: RootState) => state.myAccount.limits.needDeclaration,
};

// TO DO Use selector from EFED-9403 ES Tests for the prevention of addictive gambling when merged.
export const selectCurrLimitsByIds = createSelector(
  [playerLimitsSelectors.selectEntities, (_, ids: string[]) => ids],
  (entities, ids) => {
    return ids.map((id) => entities[id]) as PlayerLimits.PlayerLimit[];
  },
);

export default limitsSlice.reducer;
