import React from 'react';
import clsx from 'clsx';
import { GridLayouts } from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import { LayoutConfigInnerLayoutTypes } from 'modules/casino/shared/constants';
import { useCasinoLayoutConfig } from 'modules/casino/shared/hooks/useCasinoLayoutConfig';
import { useAppSelector } from 'store';
import { casinoWidgetsSelectors } from '../../../../slice/casinoWidgetsSlice';
import { CasinoWidgetsTypes, Destinations } from '../../../../types/casinoWidgetsTypes';
import DynamicSlider from '../../components/DynamicSlider';
import LastWinnersCellSkeleton from '../../components/LastWinnersCellSkeleton';
import LastWinnersWidgetsTopWinner from '../../components/LastWinnersWidgetsTopWinner';
import { LastWinnersWidgetsUI } from '../../components/LastWinnersWidgetsUIComponents';
import { useFetchLastWinners } from '../../hooks/useFetchLastWinners';
import {
  LAST_WINNER_STAKE_TOP_WIN_GAME_LAYOUT,
  LAST_WINNERS_PULL_INTERVAL_FALLBACK,
  TOP_WINNER_AREA,
} from '../../LastWinnersWidgetsConstants';
interface WidgetsProps {
  widget: CasinoWidgetsTypes.Widget;
  layoutGrid: GridLayouts;
}

const LastWinnersSectionStakeWidget: React.FC<WidgetsProps> = ({ widget, layoutGrid }) => {
  const lastWinners = useAppSelector((state) => casinoWidgetsSelectors.filteredLastWinners(state, widget.id));
  const topWinner = useAppSelector((state) => casinoWidgetsSelectors.topWinner(state, widget.id));
  const casinoLayoutConfig = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.CASINO_LAYOUT_CONFIG });
  const cdnPathLayout = casinoLayoutConfig?.defaultCdnPathLayout || 'layout_1';

  useFetchLastWinners({
    id: widget.id,
    query: widget.query,
    pullInterval: widget.pullInterval,
    fallbackInterval: LAST_WINNERS_PULL_INTERVAL_FALLBACK.SECTION_STAKE,
  });
  const wrapperClass = clsx(layoutGrid?.toLowerCase(), topWinner && 'cw--section_stake--top-win');
  return widget.id && widget.query && lastWinners ? (
    <>
      <LastWinnersWidgetsUI.Wrapper
        type={widget?.template?.type}
        columns={widget?.template?.columns}
        wrapperClassName={wrapperClass}
      >
        {topWinner && (
          <div className="cw--left-col">
            <LastWinnersWidgetsTopWinner
              topWinner={topWinner}
              type={widget?.template?.type}
              layoutConfig={TOP_WINNER_AREA}
              showTitle={false}
              cdnPathLayout={cdnPathLayout}
              gameLayout={LAST_WINNER_STAKE_TOP_WIN_GAME_LAYOUT}
            />
          </div>
        )}
        {widget.query && (
          <DynamicSlider
            templateType={widget?.template?.type}
            lastWinners={lastWinners}
            cdnPathLayout={layoutGrid?.toLowerCase()}
            destination={Destinations.Casino}
            direction={'horizontal'}
            sortByWinTime={false}
            animationInterval={widget?.animationInterval}
          />
        )}
      </LastWinnersWidgetsUI.Wrapper>
    </>
  ) : (
    <LastWinnersCellSkeleton type={widget?.template?.type} columns={widget?.template?.columns} />
  );
};
export default LastWinnersSectionStakeWidget;
