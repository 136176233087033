import React from 'react';
import { useNavigateToBankTab } from 'pages/myAccount/tabs/cashier/utils/cashier.utils';
import { refreshWidget } from 'shared/common/features/integrations/cashierWidget/cashierWidget.slice';
import { showMyProfileModal } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppDispatch } from 'store';
import NavUserButton from '../common/NavUserButton';

const DepositButton: React.FC = () => {
  const bankNavigate = useNavigateToBankTab();

  const dispatch = useAppDispatch();

  const navigateToBank = () => {
    dispatch(showMyProfileModal(false));
    bankNavigate('deposit');
    dispatch(refreshWidget());
  };

  return <NavUserButton onClick={navigateToBank} icon="link-bank-deposit" notificationsCounter={0} />;
};

export default DepositButton;
