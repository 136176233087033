import { useMediaQuery } from 'react-responsive';
import { Flex } from 'components/shared/layout';
import { UI } from 'components/shared/UI';

import { printFinancialResults } from 'modules/retail/modules/cashier/printMethods/cashierFinancePrint.utils';
import { printFinancialResultsMobile } from 'modules/retail/modules/cashier/printMethods/cashierMobilePrint.utils';
import { InfoTableUI } from 'modules/retail/modules/ssbt/components/infoTableUI';
import { formatFromAPI } from 'modules/retail/modules/ssbt/components/terminalKeyboard/helpersFuncCashier';
import { store } from 'store';
import { Breakpoints } from 'theme/Theme';
import { isEmpty, isHandheld, retailFormatDate } from 'utils/common/helpersCommon';
import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';
import { financialRecords } from './financialRecords ';
import { CashierModalUI } from '../../cashierModalUI/Index';
import { formatAmountToBRL } from '../../utils';

const FinanceInfoBalance = ({
  financeStatus,
  financeInfo,
  t,
  isPrintingEject,
  retailAddress,
  handleFinanceInfoIcon,
  financeInfoErrorMessage,
  requestDate,
  isCurrencyBeforeAmount,
}) => {
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });
  const currencySettings = store.getState()?.common?.general?.currencySettings;
  const currencySymbol =
    currencySettings?.find((c) => c.name === financeInfo?.currency)?.nameTranslations || financeInfo?.currency;

  const showInfoMessage = financeStatus === 'idle' || financeStatus === 'failure';
  const showMessage = () => {
    if (financeStatus === 'idle') {
      return `${t(CashierTranslations.FinancialModal.messageText)}`;
    } else if (financeStatus === 'failure') {
      return financeInfoErrorMessage;
    }
  };

  const currencyCellValue = isCurrencyBeforeAmount
    ? `${currencySymbol}  ${financeInfo?.balance ? formatAmountToBRL(financeInfo.balance) : '0,00'}`
    : `${financeInfo?.balance.toFixed(2)} ${financeInfo?.currency}`;

  const printFinancialResultsObj = {
    financeInfo,
    retailAddress,
    requestDate,
    isCurrencyBeforeAmount,
  };
  return (
    <>
      {showInfoMessage && (
        <CashierModalUI.InfoMessage
          className="m-auto"
          icon={handleFinanceInfoIcon(financeStatus)}
          infoMessage={showMessage()}
        />
      )}
      {!isEmpty(financeInfo) && (
        <Flex.Col>
          <InfoTableUI.Wrapper>
            <InfoTableUI.Cell
              isSpanned
              cellText={t(CashierTranslations.FinancialModal.selectedPeriod)}
              cellValue={formatFromAPI(financeInfo.period)}
            />

            <InfoTableUI.Cell
              isSpanned
              cellText={t('Cashier.FinancialModal.requestDate')}
              cellValue={retailFormatDate(requestDate)}
            />

            {financialRecords(t, financeInfo, isCurrencyBeforeAmount)?.map((row) => {
              return <InfoTableUI.Cell key={row.id} cellText={row.text} cellValue={row.value} />;
            })}

            <InfoTableUI.Cell
              isSpanned
              cellText={t('Cashier.FinancialModal.balanceText')}
              cellValue={currencyCellValue}
            />
          </InfoTableUI.Wrapper>
          <UI.Button
            size={isDesktop ? '2xl' : 'lg'}
            isDisabled={isPrintingEject}
            className={isDesktop ? 'mt-4 mx-auto' : 'my-2 mx-auto'}
            text={t(`Cashier.FinancialModal.printReport`)}
            onClick={() =>
              isHandheld()
                ? printFinancialResultsMobile(printFinancialResultsObj)
                : printFinancialResults(printFinancialResultsObj)
            }
          />
        </Flex.Col>
      )}
    </>
  );
};

export default FinanceInfoBalance;
