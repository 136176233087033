import { isDesktop } from 'react-device-detect';
import {
  CATEGORY_WITH_NUMBERS_GAME_SIZE,
  GamesSliceTypes,
  GRID_SLIDER_FETCH_GAME_SIZE,
  ONE_ROW_SLIDER_VALID_GAME_LAYOUTS,
  REGULAR_GAME_LAYOUT_BY_SUBTYPE,
  SpecialCellMap,
} from 'modules/casino/modules/container/cellsContainer/cellls/constants/casinoGamesConstants';
import {
  fetchBonusFreeSpinGames,
  fetchCasinoGamesByIds,
  fetchLastPlayed,
  fetchMostPlayed,
  fetchRecommendationsCategory,
  fetchSearchBy,
  fetchSearchByLive,
  fetchSvara,
  searchResults,
} from 'modules/casino/modules/container/cellsContainer/cellls/slice/casinoGamesFetches';
import { CUSTOM_INNER_CATEGORY_JACKPOTS } from 'modules/casino/modules/container/cellsContainer/containers/inner/constants/casinoCategoryConstants';
import { TagType } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/gameCellTagsConstants';
import { GameTag } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/GameCellTagsTypes';
import {
  GridLayouts,
  GridLoadingBehaviorType,
  GridRowType,
  GridType,
} from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
  INNER_CATEGORIES,
} from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import {
  CELL_WIDGET_RESTRICTED_CATEGORIES,
  CELL_WIDGET_RESTRICTED_SUBTYPE_LAYOUTS,
} from 'modules/casino/modules/container/widget/constants/casinoWidgetsConstants';
import { CasinoWidgetsTypes } from 'modules/casino/modules/container/widget/types/casinoWidgetsTypes';
import { CategoryQueryType, LiveCasinoGameType } from 'modules/casino/shared/constants';
import { Casino } from 'modules/casino/shared/types';
import { getUniqueUuidv4, safeJSONParse } from 'utils/common/helpersCommon';
import { CasinoGamesTypes, GameLayoutEnum } from '../types/casinoGamesTypes';

type CreateGameCollectionIdProps = {
  id: string;
  casinoType: string;
  language: string;
  queryType: string;
  playerId: string | undefined;
  categoryLayoutType: CategoryLayoutTypes;
  searchTagId: string;
};
export const createGameCollectionId = ({
  id,
  casinoType,
  language,
  queryType,
  playerId,
  categoryLayoutType,
  searchTagId = '',
}: CreateGameCollectionIdProps): string => {
  const generalKey = `${language}_${casinoType}_${id}`;
  const collectionKey = searchTagId ? `${generalKey}_${searchTagId}` : generalKey;

  if (queryType === CategoryQueryType.RECOMMENDATIONS_CATEGORY && playerId) {
    return `${collectionKey}_${playerId}`;
  }

  if (queryType === CategoryQueryType.SVARA_CATEGORY && playerId) {
    return `${collectionKey}_${playerId}`;
  }

  if (categoryLayoutType === CategoryLayoutTypes.MOST_SEARCHED_CATEGORY) {
    return `${language}_MOST_SEARCHED_${id}`;
  }

  if (categoryLayoutType === CategoryLayoutTypes.SEARCH_RESULTS_CATEGORY) {
    return `${language}_SEARCHED_RESULT_${id}`;
  }

  return collectionKey;
};

export const gamesFetchUrlByQueryType = {
  [CategoryQueryType.MOST_PLAYED_CATEGORY]: (params, token) => fetchMostPlayed(params, token),
  [CategoryQueryType.SEARCH_BY_LIVE]: (params, token) => fetchSearchByLive(params, token),
  [CategoryQueryType.SEARCH_BY]: (params, token) => fetchSearchBy(params, token),
  [CategoryQueryType.MOST_SEARCHED_CATEGORY]: (params, token) => fetchSearchBy(params, token),
  [CategoryQueryType.FREE_SPIN_BONUS]: (params, token) => fetchBonusFreeSpinGames(params, token),
  [CategoryQueryType.LAST_PLAYED_CATEGORY]: (params, token) => fetchLastPlayed(params, token),
  [CategoryQueryType.RECOMMENDATIONS_CATEGORY]: (params, token) => fetchRecommendationsCategory(params, token),
  [CategoryQueryType.SVARA_CATEGORY]: (params, token, isAuthenticated) => fetchSvara(params, token, isAuthenticated),
  [CategoryQueryType.EXTERNAL]: (params, token) => fetchCasinoGamesByIds(params, token),
  [CategoryQueryType.SEARCH_BY_TITLE]: (params, token) => searchResults(params, token),
};

export const getGamesAmount = (gridRowType, layoutGrid, preloadPages) => {
  const getRows = {
    [GridRowType.ONE_ROW]: 1,
    [GridRowType.TWO_ROWS]: 2,
    [GridRowType.THREE_ROWS]: 3,
    [GridRowType.FOURTH_ROWS]: 4,
    [GridRowType.FIFTH_ROWS]: 5,
    [GridRowType.SIX_ROWS]: 6,
  };

  const getGridLayouts = {
    [GridLayouts.LAYOUT_1]: 0,
    [GridLayouts.LAYOUT_2]: 0,
    [GridLayouts.LAYOUT_3]: 1,
  };
  const windowInnerWidth = window.innerWidth;
  if (isDesktop) return getRows[gridRowType] * (8 + getGridLayouts[layoutGrid]) * preloadPages;
  if (windowInnerWidth >= 2560) return getRows[gridRowType] * (8 + getGridLayouts[layoutGrid]) * preloadPages;
  if (2560 > windowInnerWidth && windowInnerWidth >= 1800)
    return Math.round(getRows[gridRowType] * (7 + getGridLayouts[layoutGrid]) * preloadPages);
  if (1800 > windowInnerWidth && windowInnerWidth >= 1600)
    return Math.round(getRows[gridRowType] * (6 + getGridLayouts[layoutGrid]) * preloadPages);
  if (1600 > windowInnerWidth && windowInnerWidth >= 1366)
    return Math.round(getRows[gridRowType] * (5 + getGridLayouts[layoutGrid]) * preloadPages);
  if (1366 > windowInnerWidth && windowInnerWidth >= 1024)
    return Math.round(
      (getRows[gridRowType] + getGridLayouts[layoutGrid]) * (4 + getGridLayouts[layoutGrid]) * preloadPages,
    );
  if (1024 > windowInnerWidth && windowInnerWidth >= 415)
    return Math.round(
      (getRows[gridRowType] + getGridLayouts[layoutGrid]) * (3 + getGridLayouts[layoutGrid]) * preloadPages,
    );
  if (415 > windowInnerWidth && windowInnerWidth >= 0)
    return Math.round(
      (getRows[gridRowType] + getGridLayouts[layoutGrid]) * (2 + getGridLayouts[layoutGrid]) * preloadPages,
    );
  return Math.round(
    (getRows[gridRowType] + getGridLayouts[layoutGrid]) * (2 + getGridLayouts[layoutGrid]) * preloadPages,
  );
};

export const getLiveFetchGameIds = ({
  games,
  cachedLiveGameIds,
}: {
  games: CasinoGamesTypes.NormalizedGames;
  cachedLiveGameIds: string[];
}) => {
  const gameUniqueIds = Object.keys(games);

  const gamesToAdd = gameUniqueIds?.reduce((acc: string[], gameUniqueId: string) => {
    const game = games[gameUniqueId];
    const gameId = game?.id;
    const cachedGame = cachedLiveGameIds?.includes(gameId);

    if (LiveCasinoGameType[game?.gameType] && !cachedGame) {
      acc.push(gameId);
    }

    return acc;
  }, []);

  return [...cachedLiveGameIds, ...gamesToAdd];
};

export const getCustomFetchGameSize = (gridType: GridType, hasNumbers: boolean): number => {
  if (hasNumbers) {
    return CATEGORY_WITH_NUMBERS_GAME_SIZE;
  }

  if (gridType === GridType.SLIDER) {
    return GRID_SLIDER_FETCH_GAME_SIZE;
  }
  return 70;
};

const overrideGameLayout = ({
  subType,
  layoutRows,
  defaultGameLayout,
  gridType,
  hasNumbers,
}: {
  subType: CategorySubTypes;
  layoutGrid: GridLayouts;
  layoutRows: GridRowType;
  defaultGameLayout: CasinoGamesTypes.GameLayout;
  gridType?: GridType;
  hasNumbers?: boolean;
}): CasinoGamesTypes.GameLayout => {
  if (
    gridType === GridType.SLIDER &&
    layoutRows === GridRowType.ONE_ROW &&
    !ONE_ROW_SLIDER_VALID_GAME_LAYOUTS.includes(defaultGameLayout as GameLayoutEnum)
  ) {
    return GameLayoutEnum.REGULAR;
  }

  if (gridType === GridType.VERTICAL && layoutRows === GridRowType.ONE_ROW) {
    return GameLayoutEnum.REGULAR;
  }

  if (REGULAR_GAME_LAYOUT_BY_SUBTYPE.includes(subType)) {
    return GameLayoutEnum.REGULAR;
  }

  if (hasNumbers && layoutRows === GridRowType.TWO_ROWS) {
    return GameLayoutEnum.VERTICAL;
  }

  return defaultGameLayout;
};

const overrideWidgetTemplate = ({
  game,
  hasNumbers,
}: {
  game: CasinoGamesTypes.GameProps;
  hasNumbers?: boolean;
}): CasinoWidgetsTypes.Widget => {
  const { widget } = game;
  if (!widget || !hasNumbers) return widget;

  const templateType = widget.template.type;

  if (
    templateType === 'VERTICAL' ||
    templateType === 'REGULAR' ||
    templateType === 'HORIZONTAL' ||
    templateType === 'MACRO'
  ) {
    return { ...widget, template: { ...widget.template, type: game.gameLayout } };
  }

  return widget;
};

const getLayoutRows = (gridLoadingBehavior: GridLoadingBehaviorType, layoutConfig: Casino.LayoutConfig) => {
  if (gridLoadingBehavior === GridLoadingBehaviorType.InfinityScroll && layoutConfig.layout === GridType.VERTICAL) {
    return GridRowType.FOURTH_ROWS;
  }

  return layoutConfig.layoutRows;
};

export const overrideLayoutConfig = (
  layoutConfig: Casino.LayoutConfig,
  categoryLayoutType: CategoryLayoutTypes,
  gridLoadingBehavior: GridLoadingBehaviorType = GridLoadingBehaviorType.LoadButton,
): Casino.LayoutConfig => {
  // if (hasNumbers) {
  //   const supportedGridRows = [GridRowType.ONE_ROW, GridRowType.TWO_ROWS];
  //   return {
  //     layout: GridType.SLIDER,
  //     layoutRows: supportedGridRows.includes(layoutConfig.layoutRows) ? layoutConfig.layoutRows : GridRowType.ONE_ROW,
  //     layoutGrid: layoutConfig.layoutGrid,
  //   };
  // }

  if (CategoryLayoutTypes.INNER_CATEGORY == categoryLayoutType) {
    if (CUSTOM_INNER_CATEGORY_JACKPOTS) {
      return {
        ...layoutConfig,
        // layout: GridType.SLIDER,
        // layoutRows: GridRowType.TWO_ROWS,
      };
    }
  }

  if (
    CategoryLayoutTypes.FAVOURITES_CATEGORY == categoryLayoutType ||
    CategoryLayoutTypes.FAVOURITES_MODAL_CATEGORY == categoryLayoutType
  ) {
    return {
      layout: GridType.VERTICAL,
      layoutRows: getLayoutRows(gridLoadingBehavior, layoutConfig),
      layoutGrid: layoutConfig.layoutGrid,
    };
  }

  return {
    ...layoutConfig,
    layoutRows: getLayoutRows(gridLoadingBehavior, layoutConfig),
  };
};

export const isWidget = (item: CasinoGamesTypes.GameProps) => {
  return item?.widget && item?.widget?.id != null ? true : false;
};

const getRestrictedCellWidget = ({
  game,
  categoryLayoutType,
  subType,
  hasNumbers,
}: {
  game: CasinoGamesTypes.GameProps;
  categoryLayoutType: CategoryLayoutTypes;
  subType: CategorySubTypes;
  hasNumbers: boolean;
}) => {
  const isWidgetRestricted =
    hasNumbers ||
    CELL_WIDGET_RESTRICTED_CATEGORIES.includes(categoryLayoutType) ||
    CELL_WIDGET_RESTRICTED_SUBTYPE_LAYOUTS.includes(subType);

  return isWidget(game) && isWidgetRestricted;
};

export const getLiveImageLocation = ({
  gameResource,
  categoryLayout,
}: {
  gameResource: CasinoGamesTypes.GameResource;
  categoryLayout?: CategoryLayoutTypes;
}): string => {
  if (!gameResource) {
    return '';
  }

  const { categoryUseStatic, lobbyUseStatic, thumbnailLocation } = gameResource || {};
  const useStatic = categoryLayout && INNER_CATEGORIES.includes(categoryLayout) ? categoryUseStatic : lobbyUseStatic;

  return !useStatic && thumbnailLocation ? thumbnailLocation : '';
};

export const getGamesSliceType = (categoryLayoutType: CategoryLayoutTypes): GamesSliceTypes => {
  const gameSliceByCategoryLayout = {
    [CategoryLayoutTypes.FAVOURITES_CATEGORY]: GamesSliceTypes.FAVOURITES,
    [CategoryLayoutTypes.FAVOURITES_MODAL_CATEGORY]: GamesSliceTypes.FAVOURITES,
    [CategoryLayoutTypes.IN_GAME_FAVOURITES_CATEGORY]: GamesSliceTypes.FAVOURITES,
  };

  return gameSliceByCategoryLayout[categoryLayoutType] || GamesSliceTypes.GENERAL;
};

const parseliveFeed = (game: CasinoGamesTypes.GameProps): CasinoGamesTypes.GameProps => {
  const gameConfigJSON = game?.liveFeedInfo;
  const gameConfig =
    gameConfigJSON && typeof gameConfigJSON === 'string'
      ? safeJSONParse(gameConfigJSON, (k, v) => (v === 'true' ? true : v === 'false' ? false : v))
      : gameConfigJSON;

  return gameConfig
    ? {
        ...game,
        liveFeedInfo: gameConfig as CasinoGamesTypes.Configuration,
      }
    : game;
};

export const generateGameUniqueId = ({
  categoryLayoutType,
  gameLayout,
  gameInitialId,
}: {
  categoryLayoutType: CategoryLayoutTypes;
  gameLayout: CasinoGamesTypes.GameLayout;
  gameInitialId?: string;
}): string =>
  gameInitialId
    ? `${gameInitialId}#${categoryLayoutType}#${gameLayout}`
    : `${getUniqueUuidv4()}#${categoryLayoutType}#${gameLayout}`;

export const normalizeGames = ({
  games,
  subType,
  categoryLayoutType,
  layoutGrid,
  layoutRows,
  queryType,
  gridType,
  hasNumbers,
}: {
  games: CasinoGamesTypes.GameProps[];
  subType: CategorySubTypes;
  categoryLayoutType: CategoryLayoutTypes;
  layoutGrid: GridLayouts;
  layoutRows: GridRowType;
  queryType: CategoryQueryType;
  hasNumbers: boolean;
  gridType?: GridType;
}): CasinoGamesTypes.GameProps[] =>
  games
    .map((game: CasinoGamesTypes.GameProps) => {
      const gameLayout = overrideGameLayout({
        subType,
        layoutGrid,
        layoutRows,
        gridType,
        defaultGameLayout: game?.gameLayout || GameLayoutEnum.REGULAR,
        hasNumbers,
      });

      const isPermanentSvara = queryType === CategoryQueryType.SVARA_CATEGORY && game.isPermanent;

      return {
        ...parseliveFeed(game),
        uniqueId: generateGameUniqueId({
          categoryLayoutType,
          gameLayout,
          gameInitialId: getGameIdForSpecificCategory(
            queryType,
            isPermanentSvara ? getPermanentSvaraId(game) : game.id,
          ),
        }),
        liveImageLocation: getLiveImageLocation({
          gameResource: game?.gameResource,
          categoryLayout: categoryLayoutType,
        }),
        gridType: gridType || GridType.VERTICAL,
        isLiveGame: !!LiveCasinoGameType[game?.gameType],
        gameLayout,
        layoutGrid,
        subType,
        hasNumbers,
        layoutModifier:
          game.layoutModifier === 'SPECIAL' && REGULAR_GAME_LAYOUT_BY_SUBTYPE.includes(subType)
            ? 'STANDARD'
            : game.layoutModifier,
      };
    })
    .filter(
      (game: CasinoGamesTypes.GameProps) => !getRestrictedCellWidget({ game, categoryLayoutType, subType, hasNumbers }),
    );

export const normalizeGamesData = (games: CasinoGamesTypes.GameProps[], hasNumbers?: boolean) => {
  let orderNumber = 1;

  return games.reduce(
    (acc, game) => {
      const { uniqueId, refGameId } = game;
      const overriddenWidget = overrideWidgetTemplate({ game, hasNumbers });

      acc['content'] = {
        ...acc.content,
        [uniqueId]: {
          ...game,
          number: refGameId ? orderNumber++ : undefined,
          widget: overriddenWidget,
        },
      };
      // if (game.layoutModifier === 'EXPANDING' || game.layoutModifier === 'ROTATING') {
      if (game.layoutModifier === 'SPECIAL') {
        acc['contentUniqueIdsSpecial'].push(uniqueId);
      } else {
        acc['contentUniqueIds'].push(uniqueId);
      }

      return acc;
    },
    { content: {}, contentUniqueIds: [], contentUniqueIdsSpecial: [] } as {
      content: CasinoGamesTypes.NormalizedGames;
      contentUniqueIds: string[];
      contentUniqueIdsSpecial: string[];
    },
  );
};
export const normalizeSvaraGamesData = (
  games: CasinoGamesTypes.GameProps[],
  prevGamesIds: string[],
  hasNumbers?: boolean,
) => {
  let orderNumber = 1;
  return games.reduce(
    (acc, game) => {
      const { uniqueId, refGameId, gameType } = game;
      const overriddenWidget = overrideWidgetTemplate({ game, hasNumbers });

      acc['content'] = {
        ...acc.content,
        [uniqueId]: {
          ...game,
          shouldLoad: !!prevGamesIds.length && !game.isPermanent && !prevGamesIds.includes(uniqueId),
          number: refGameId || gameType === 'SVARA' ? orderNumber++ : undefined,
          widget: overriddenWidget,
        },
      };

      acc['contentUniqueIds'].push(uniqueId);

      return acc;
    },
    { content: {}, contentUniqueIds: [], contentUniqueIdsSpecial: [] } as {
      content: CasinoGamesTypes.NormalizedGames;
      contentUniqueIds: string[];
      contentUniqueIdsSpecial: string[];
    },
  );
};

export const getRecommendationCategoryCustomGameSize = ({
  size,
  games,
  gridType,
  hasNumbers,
}: {
  size: number;
  games: CasinoGamesTypes.GameProps[];
  gridType: GridType;
  hasNumbers: boolean;
}): CasinoGamesTypes.GameProps[] => {
  if (size && (hasNumbers || gridType === GridType.SLIDER)) {
    return games.slice(0, size);
  }

  return games;
};

export const getBackgroundFromGameTag = (tags: GameTag[]) => {
  if (tags) {
    const tagObj = tags.find((tag) => tag.type === TagType.BOTTOM_TEXT || tag.type === TagType.BOTTOM_LABEL);
    return tagObj?.background ? tagObj?.background[0] : '';
  } else {
    return '';
  }
};
export const getGameIdForSpecificCategory = (queryType: CategoryQueryType, gameId: string): string | undefined => {
  const categoriesToCheck = [CategoryQueryType.SVARA_CATEGORY];
  return categoriesToCheck.includes(queryType) ? gameId : undefined;
};
export const getNormalizedGamesData = (
  games: CasinoGamesTypes.GameProps[],
  prevGames: CasinoGamesTypes.NormalizedGames,
  prevGamesIds: string[],
  prevGameIdsSpecial: string[],
  queryType: CategoryQueryType,
  hasNumbers?: boolean,
) => {
  if (queryType === CategoryQueryType.SVARA_CATEGORY || queryType === CategoryQueryType.SEARCH_BY_TITLE) {
    return getSvaraGamesData(games, prevGamesIds || [], hasNumbers);
  }
  return getGamesData(games, prevGames, prevGamesIds, prevGameIdsSpecial, hasNumbers);
};

export const collectionHasSpecialCell = (collection: CasinoGamesTypes.GameProps[]): boolean => {
  return !!collection.find((item) => item.layoutModifier === 'SPECIAL');
};

export const getPermanentSvaraId = (game: CasinoGamesTypes.GameProps): string => {
  return `${game.isFast ? 'fast' : 'normal'}${game.tableCurrency}${game.isFreePlay ? 'free' : 'paid'}${
    game.liveFeedInfo.ante
  }${game.liveFeedInfo.betLimits && game.liveFeedInfo.betLimits[game.tableCurrency].min}${
    game.liveFeedInfo.betLimits && game.liveFeedInfo.betLimits[game.tableCurrency].max
  }`;
};

export const getGamesData = (
  games: CasinoGamesTypes.GameProps[],
  prevGames: CasinoGamesTypes.NormalizedGames,
  prevGamesIds: string[],
  prevGameIdsSpecial: string[],
  hasNumbers?: boolean,
) => {
  const { content, contentUniqueIds, contentUniqueIdsSpecial } = normalizeGamesData(games, hasNumbers);

  return {
    gameIds: [...(prevGamesIds || []), ...contentUniqueIds],
    games: { ...(prevGames || {}), ...content },
    gameIdsSpecial: [...(prevGameIdsSpecial || []), ...contentUniqueIdsSpecial],
  };
};

export const getSvaraGamesData = (
  games: CasinoGamesTypes.GameProps[],
  prevGamesIds: string[],
  hasNumbers?: boolean,
) => {
  const { content, contentUniqueIds } = normalizeSvaraGamesData(games, prevGamesIds || [], hasNumbers);

  return {
    gameIds: contentUniqueIds,
    games: content,
    gameIdsSpecial: [],
  };
};

export const getSpecialGamesData = ({
  gameSection,
  games,
}: {
  gameSection: Casino.GameSection | undefined;
  games: CasinoGamesTypes.GameProps[];
}): CasinoGamesTypes.GameProps[] => {
  const addCell = gameSection?.type && gameSection?.isActive && collectionHasSpecialCell(games);
  const specialCell: CasinoGamesTypes.GameProps | undefined = addCell && SpecialCellMap[gameSection?.type];

  if (specialCell && games.length) {
    return [specialCell, ...games];
  }

  return games;
};

export const getSpecialFavGamesData = ({
  gameSection,
  games,
}: {
  gameSection: Casino.GameSection | undefined;
  games: CasinoGamesTypes.GameProps[];
}): CasinoGamesTypes.GameProps[] => {
  const addCell = gameSection?.type && gameSection?.isActive;
  const specialCell: CasinoGamesTypes.GameProps | undefined = addCell && SpecialCellMap[gameSection?.type];

  if (specialCell) {
    return [specialCell, ...games];
  }

  return games;
};
