import React, { useEffect, useRef, useState } from 'react';

interface WrapperProps {
  image: string[];
  title: string;
  className?: string;
  cellId?: string;
  hasFinishedLoading?: () => void;
  handleHasError?: (() => void) | undefined;
}

export const ImageCell: React.FC<WrapperProps> = ({ image, title, className, hasFinishedLoading, handleHasError }) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    if (imgRef?.current) {
      imgRef.current.src = `${image[0]}`;
    }
  }, [image]);

  const handleError = () => {
    if (imgRef.current) {
      if (!isError) {
        imgRef.current.src = `${image[1]}`;
        setIsError(true);
      } else {
        imgRef.current.src = '';
      }
    }
    handleHasError && handleHasError();
  };

  return className ? (
    <div className={className}>
      <img
        alt={title}
        ref={imgRef}
        onError={handleError}
        onLoad={() => {
          hasFinishedLoading && hasFinishedLoading();
        }}
      />
    </div>
  ) : (
    <img
      alt={title}
      ref={imgRef}
      onError={handleError}
      onLoad={() => {
        hasFinishedLoading && hasFinishedLoading();
      }}
    />
  );
};
