import React, { memo } from 'react';
import { SharedComponents } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared';
import { useAppDispatch, useAppSelector } from 'store';
import { OutOfGameModalTypes } from './modal.types';
import { hideRootCasinoModal, selectRootCasinoModal } from './rootCasinoModal.slice';
import useHandleCustomEvents, { CustomPlatformEvents } from '../../hooks/useHandleCustomEvents';

/* eslint-disable @typescript-eslint/no-explicit-any */
const OUT_OF_GAME_MODAL_COMPONENTS: Record<OutOfGameModalTypes, React.FC<any>> = {
  GAME_INFO_MODAL: SharedComponents.GameInfoModal,
};

const OutOfGameModal = () => {
  const modalType = useAppSelector(selectRootCasinoModal.type);
  const modalProps = useAppSelector(selectRootCasinoModal.props);
  const body = document.body;

  const dispatch = useAppDispatch();

  const eventHandler = () => dispatch(hideRootCasinoModal());

  useHandleCustomEvents(CustomPlatformEvents.HIGH_PRIORITY_MODAL, eventHandler);

  if (!modalType) {
    body.classList.remove('no-scroll');
    return null;
  } else {
    body.classList.add('no-scroll');
  }

  const SpecificModal = OUT_OF_GAME_MODAL_COMPONENTS[modalType];
  return SpecificModal ? <SpecificModal {...modalProps} /> : null;
};

export default memo(OutOfGameModal);
