import React from 'react';
import clsx from 'clsx';
import { Icon } from 'components/shared';
import { CSS } from 'utils/common/types/css.types';

/** Form Status Icon */
interface StatusProps {
  type: CSS.Variants;
  size?: 'sm' | 'lg';
  isCentered?: boolean;
}

const Status: React.FC<StatusProps> = ({ type, size = 'lg', isCentered = true }) => {
  const icon = (variantType: CSS.Variants) => {
    switch (variantType) {
      case 'success':
        return 'check-solid';
      case 'info':
        return 'info-solid';
      case 'error':
        return 'times-solid';
      default:
        return '';
    }
  };

  const iconSize = size === 'sm' ? 14 : 38;

  const iconClass = clsx(
    isCentered && 'mx-auto',
    'form-status__icon',
    type === 'error' && 'fill--error',
    type === 'info' && 'fill--info',
    type === 'success' && 'fill--success',
  );

  return <Icon defaultClassName={false} className={iconClass} size={iconSize} icon={icon(type)} />;
};

export default Status;
