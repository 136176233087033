import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { setLanguage } from 'shared/common/sharedSlices/commonActions';
import axiosInstance from './utils/common/axios-instance';
import config from './utils/config';

export type TFunction = typeof i18n.t;
export type TOptions = typeof i18n;

export const seti18nLanguage = (lang) => {
  i18n.changeLanguage(lang);
};

const fetchTranslations = async () => {
  const url = `${config.API_URL}/api/cms/public/general-translations`;
  const response = await axiosInstance.get(url);
  return response.data;
};

export const getI18n = async (language, dispatch) => {
  if (!language) return;
  let translations;

  await fetchTranslations()
    .then((res) => (translations = res))
    .catch(() => {
      language !== window.config.DEFAULT_LANG && dispatch(setLanguage(window.config.DEFAULT_LANG));
    });

  const initConfig = {
    react: {
      useSuspense: true,
    },
    fallbackLng: {
      default: ['en'],
    },
    supportedLngs: [language],
    preload: [language],
    detection: {
      lookupLocalStorage: 'lang',
      caches: [],
      order: ['localStorage', 'navigator', 'htmlTag'],
    },
    load: 'languageOnly',
    ...(translations && {
      resources: {
        [language]: {
          translation: translations,
        },
      },
    }),
  };

  return i18n.use(initReactI18next).use(LanguageDetector).init(initConfig);
};
