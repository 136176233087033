import React, { memo } from 'react';
import useWidgetBackground from 'modules/casino/modules/container/widget/widgetTypes/hooks/useWidgetBackground';
import CellWrapper from './components/CellWrapper';
import WidgetPrizeDropCampaign from './components/widgetPrizeDropCampaign/WidgetPrizeDropCampaign';
import WidgetSkeleton from './components/WidgetSkeleton';
import { useFetchPrizeDropCampaign } from './hooks/useFetchPrizeDropCampaign';
import { CasinoWidgetsTypes } from '../../../types/casinoWidgetsTypes';

interface WidgetsProps {
  widget: CasinoWidgetsTypes.Widget;
}

const PrizeDropCellWidget: React.FC<WidgetsProps> = ({ widget }) => {
  const { id, template, backgroundLocations } = widget || {};
  const backgroundWidgetResource = backgroundLocations;
  const { backgroundWrapper, setRefs } = useWidgetBackground({ backgroundWidgetResource, type: template?.type });

  useFetchPrizeDropCampaign({ id: widget.id, query: widget.query });

  return id && (!backgroundWidgetResource || backgroundWrapper) ? (
    <CellWrapper type={template?.type} backgroundUrl={backgroundWrapper}>
      <WidgetPrizeDropCampaign widgetId={id} widgetTemplate={template?.type} />
    </CellWrapper>
  ) : (
    <WidgetSkeleton type={template?.type} columns={template?.columns} setRefs={setRefs} />
  );
};

export default memo(PrizeDropCellWidget);
