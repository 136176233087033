import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isIOS } from 'react-device-detect';
import { setNetAppHeader } from 'shared/common/features/general/slice/general.slice';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from '../../../store/thunkCreators';
import axiosInstance from '../../../utils/common/axios-instance';
import { getBusinessUnit, getDeviceType, getError } from '../../../utils/common/helpersCommon';
import config from '../../../utils/config';
import { SvaraAppLoginTypes } from '../types/svaraAppLogin.types';

export const SvaraApploginThunks: SvaraAppLoginTypes.LoginThunks = {
  fetchFormFields: createAbortThunk('svaraApp/fetchFormFields', async (_, { rejectWithValue, dispatch }) => {
    try {
      const url = `${config.API_URL}/api/ews-crm/public/cms/player-content?key=login_form`;
      const response = await axiosInstance.get(url);

      if (response.headers?.['x-platform-hash-netapp']) {
        dispatch(setNetAppHeader(response.headers['x-platform-hash-netapp']));
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(getError.message(err));
    }
  }),
  fetchSvaraAppDefCodeData: createAbortThunk('svaraApp/fetchSvaraAppDefCodeData', async (_, { rejectWithValue }) => {
    try {
      const headers = {
        'X-Platform-Device': getDeviceType(),
        'X-Platform-Origin': getBusinessUnit(),
        'X-Platform-Application': isIOS ? `ios` : `android`,
      };

      const url = `${config.API_URL}/api/gaming/player/svaraDefCode`;
      const response = await axiosInstance.get(url, { headers });

      return response.data;
    } catch (err) {
      return rejectWithValue(getError.default());
    }
  }),
};

const { fetchFormFields, fetchSvaraAppDefCodeData } = SvaraApploginThunks;

const initialState: SvaraAppLoginTypes.State = {
  loggingSuccess: false,
  hideLoginForm: false,
  isFormLoading: false,
  fields: null,
  fetchFormFieldsError: null,
  showUserNotVerifiedMsg: false,
  openFromSvara: false,
  openLeaderboard: false,
  svaraAppDefCodeData: null,
  defCodeDataError: null,
};

const svaraAppReducer = createSlice({
  name: 'svaraApp',
  initialState,
  reducers: {
    setLoggingSuccess(state, action) {
      state.loggingSuccess = action.payload;
    },
    setHideLoginForm(state, action) {
      state.hideLoginForm = action.payload;
    },
    resetFormFieldsData(state) {
      state.fields = null;
      state.fetchFormFieldsError = null;
    },
    setShowUserNotVerifiedMsg(state, action: PayloadAction<boolean>) {
      state.showUserNotVerifiedMsg = action.payload;
    },
    setOpenFromSvara(state, action: PayloadAction<boolean>) {
      state.openFromSvara = action.payload;
    },
    setOpenLeaderboard(state, action: PayloadAction<boolean>) {
      state.openLeaderboard = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormFields.pending, (state) => {
        state.isFormLoading = true;
      })
      .addCase(fetchFormFields.fulfilled, (state, action) => {
        state.isFormLoading = false;
        state.fields = action.payload;
      })
      .addCase(fetchFormFields.rejected, (state, action) => {
        state.isFormLoading = false;
        state.fetchFormFieldsError = action.payload;
      });
    builder
      .addCase(fetchSvaraAppDefCodeData.fulfilled, (state, action) => {
        state.svaraAppDefCodeData = action.payload;
        state.hideLoginForm = true;
      })
      .addCase(fetchSvaraAppDefCodeData.rejected, (state, action) => {
        state.defCodeDataError = action.payload;
        state.loggingSuccess = false;
      });
  },
});

export const {
  resetFormFieldsData,
  setHideLoginForm,
  setShowUserNotVerifiedMsg,
  setOpenFromSvara,
  setOpenLeaderboard,
  setLoggingSuccess,
} = svaraAppReducer.actions;

export default svaraAppReducer.reducer;

export const svaraAppSelectors = {
  loggingSuccess: (state: RootState): boolean => state.svaraApp.loggingSuccess,
  hideLoginForm: (state: RootState): boolean => state.svaraApp.hideLoginForm,
  loginFormFields: (state: RootState): SvaraAppLoginTypes.LoginFormInputTypes[] | null => state.svaraApp.fields,
  isFormLoading: (state: RootState): boolean => state.svaraApp.isFormLoading,
  fetchFormFieldsError: (state: RootState): null | string | undefined => state.svaraApp.fetchFormFieldsError,
  showUserNotVerifiedMsg: (state: RootState): boolean => state.svaraApp.showUserNotVerifiedMsg,
  openFromSvara: (state: RootState): boolean => state.svaraApp.openFromSvara,
  openLeaderboard: (state: RootState): boolean => state.svaraApp.openLeaderboard,
  svaraApprDefCodeData: (state: RootState): SvaraAppLoginTypes.SvaraAppDefCodeData | null =>
    state.svaraApp.svaraAppDefCodeData,
  defCodeDataError: (state: RootState): null | string | undefined => state.svaraApp.defCodeDataError,
};
