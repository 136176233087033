import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import lottie, { AnimationItem } from 'lottie-web/build/player/lottie_light';
import { isIOS, isMobile, useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Flex } from 'modules/casino/shared/components/layout';
import { useWindowDimensions } from 'modules/casino/shared/hooks/useWindowDimenions';
//import useWindowSize from 'modules/casino/shared/hooks/useWindowSize';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { useAppSelector } from 'store';
import config from 'utils/config';
import BonusOffersInfoButton from './bonusOffersInfo/BonusOffersInfoButton';
import { CampaignMissionsProgress } from './campaignMissionsProgress';
import { ChallengesButton } from './challenges/ChallengesButton';
import { ExitGameButton } from './ExitGame/ExitGameButton';
import { FavouritesButton } from './favourites/FavouritesButton';
import { JackpotCampaignButton } from './jackpotCampaign/JackpotCampaignButton';
import { LastPlayedGamesButton } from './lastPlayedGames/LastPlayedGamesButton';
import { PrizeDropButton } from './prizeDropCampaign/PrizeDropButton';
import { QuickDepositButton } from './quickDeposit/QuickDepositButton';
import { RealityCheckSessionTopBar } from './realityCheckSession/RealityCheckSessionTopBar';
import { RecommendedGamesButton } from './recommendedGames/RecommendedGamesButton';
import { SearchButton } from './search/searchButton';
import { TournamentsButton } from './tournaments/TournamentsButton';
import { fromRight } from './utils/topBar.utils';
import { selectIntegrations } from '../../../../../../shared/common/features/integrations/slice/integrations.slice';
import useCasinoWidgetConfig from '../../../../platformImplementation/hooks/useCasinoWidgetConfig';
import { isEmpty } from '../../../utils/common/helpersCommon';
import { DEFAULT_GAME_ID_LOBBY } from '../../gameLaunch/casinoLaunchConstants';
import { casinoLaunchSelectors } from '../../gameLaunch/casinoLaunchSlice';
import { ProviderLaunchAgents } from '../../gameLaunch/casinoLaunchTypes';
import { useTopBarButtonsConfig } from '../hooks/useTopBarButtonsConfig';

interface TopBarProps {
  type: ProviderLaunchAgents;
  isFreePlay: boolean;
}

const NewTopBar: React.FC<TopBarProps> = ({ type, isFreePlay }) => {
  const { configTopBarButtons } = useTopBarButtonsConfig();
  const [showMore, setShowMore] = useState(false);
  const { height } = useWindowDimensions();
  const { t } = useTranslation();
  const { getButtonEnabled } = useTopBarButtonsConfig();
  const showTopBar =
    configTopBarButtons?.hideOnFreePlay !== undefined ? !(isFreePlay && configTopBarButtons?.hideOnFreePlay) : true;
  const launchData = useAppSelector(casinoLaunchSelectors.launchData);

  const casinoWidgetConfig = useCasinoWidgetConfig();

  const widgetGameData = useAppSelector(selectIntegrations.casinoWidgetGameData);
  const gameId = casinoWidgetConfig ? widgetGameData.id : launchData?.gameId || DEFAULT_GAME_ID_LOBBY;

  const buSettings = useAppSelector(selectGeneral.buSettings);
  const gameBetType = useAppSelector(casinoLaunchSelectors.getGameBetType);
  const showRealityCheckSession = gameBetType && buSettings?.regulationCasinoGameSettings?.includes(gameBetType);

  const { isPortrait } = useMobileOrientation();

  const renderButton = useCallback(
    (button): JSX.Element | null => {
      switch (button.type) {
        case 'casinoBonusOffersInfo':
          return getButtonEnabled('casinoBonusOffersInfo') ? (
            <BonusOffersInfoButton isFreePlay={isFreePlay} gameId={gameId} {...button} key={button.type} />
          ) : null;
        case 'quickDeposit':
          return getButtonEnabled('quickDeposit') ? (
            <QuickDepositButton isFreePlay={isFreePlay} {...button} key={button.type} />
          ) : null;
        case 'challenges':
          return getButtonEnabled('challenges') ? (
            <ChallengesButton isFreePlay={isFreePlay} gameId={gameId} {...button} key={button.type} />
          ) : null;
        case 'jackpotCampaign':
          return getButtonEnabled('jackpotCampaign') ? (
            <JackpotCampaignButton isFreePlay={isFreePlay} gameId={gameId} {...button} key={button.type} />
          ) : null;
        case 'prizeDrop':
          return getButtonEnabled('prizeDrop') ? (
            <PrizeDropButton isFreePlay={isFreePlay} gameId={gameId} {...button} key={button.type} />
          ) : null;
        case 'campaignMissionProgress':
          return getButtonEnabled('campaignMissionProgress') ? (
            <CampaignMissionsProgress isFreePlay={isFreePlay} {...button} key={button.type} />
          ) : null;
        case 'gameCellFav':
          return getButtonEnabled('gameCellFav') ? (
            <FavouritesButton isFreePlay={isFreePlay} {...button} key={button.type} />
          ) : null;
        case 'tournaments':
          return getButtonEnabled('tournaments') ? (
            <TournamentsButton isFreePlay={isFreePlay} gameId={gameId} {...button} key={button.type} />
          ) : null;
        case 'recommendedGames':
          return getButtonEnabled('recommendedGames') ? (
            <RecommendedGamesButton isFreePlay={isFreePlay} {...button} key={button.type} />
          ) : null;
        case 'lastPlayedGames':
          return getButtonEnabled('lastPlayedGames') ? (
            <LastPlayedGamesButton isFreePlay={isFreePlay} {...button} key={button.type} />
          ) : null;
        case 'search':
          return getButtonEnabled('search') ? <SearchButton {...button} key={button.type} /> : null;
        case 'exitGame':
          return getButtonEnabled('exitGame') ? <ExitGameButton {...button} key={button.type} /> : null;

        default:
          return null;
      }
    },
    [gameId, isFreePlay, getButtonEnabled],
  );

  const animationContainer = useRef(null);
  const anim = useRef<AnimationItem | null>(null);
  const toolbarRef = useRef<HTMLDivElement | null>(null);

  const allActiveButtons = useMemo(() => {
    return configTopBarButtons?.scrollable?.map((button) => renderButton(button)).filter(Boolean);
  }, [configTopBarButtons?.scrollable, renderButton]);

  const initialButtonsLength = allActiveButtons?.length || 0;
  //const [buttonsSplitIndex, setButtonsSplitIndex] = useState(initialButtonsLength);

  const firstSection = allActiveButtons?.slice(0, 5);
  const slideInButtons = allActiveButtons?.slice(5, allActiveButtons.length);
  const [buttonsToShow, setButtonsToShow] = useState(firstSection);
  const showMoreButtons = allActiveButtons!.length > 5;

  useEffect(() => {
    isMobile ? setButtonsToShow(firstSection) : setButtonsToShow(allActiveButtons);
  }, [isMobile]);

  const showScrollableSection = !isEmpty(configTopBarButtons?.scrollable);
  const showPinnedSection = !isEmpty(configTopBarButtons?.pinned);

  useLayoutEffect(() => {
    if (animationContainer.current) {
      anim.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: `${config.CDN_URL}/common-content/bottom-bar/inGameBarAnimation.json`,
      });

      return () => anim.current?.destroy();
    }
  }, [initialButtonsLength]);

  //const windowSize = useWindowSize();

  // useEffect(() => {
  //   if (toolbarRef.current && isMobile) {
  //     const updateToolbar = () => {
  //       const windowWidth = window.innerWidth;

  //       if (toolbarRef.current && initialButtonsLength) {
  //         const toolBarWidth = toolbarRef.current?.clientWidth;
  //         if (toolBarWidth > windowWidth) {
  //           let availableWidth = 0;
  //           availableWidth = windowWidth - 54;
  //           const arr = Array.prototype.slice.call(toolbarRef.current.children);

  //           arr.some((child, index) => {
  //             availableWidth = availableWidth - child.clientWidth;

  //             if (availableWidth < 0) {
  //               setButtonsSplitIndex(index);
  //               return true;
  //             }
  //             return false;
  //           });
  //         } else {
  //           setButtonsSplitIndex(initialButtonsLength);
  //         }
  //       }
  //     };
  //     if (buttonsSplitIndex === -1) {
  //       updateToolbar();
  //     }
  //   }
  // }, [toolbarRef, initialButtonsLength, buttonsSplitIndex]);

  useLayoutEffect(() => {
    if (toolbarRef.current && !isMobile) {
      toolbarRef.current.scrollHeight > toolbarRef.current.clientHeight
        ? (toolbarRef.current.style.justifyContent = 'flex-start')
        : (toolbarRef.current.style.justifyContent = 'center');
    }
  }, [height, toolbarRef.current?.scrollHeight]);

  // useLayoutEffect(() => {
  //   isMobile && setButtonsSplitIndex(-1); // reset
  // }, [windowSize]);

  const setRefs = useCallback((node) => {
    toolbarRef.current = node;
  }, []);

  if (!type || !showTopBar) return null; // TODO - Added to avoid param not used error. Remove when implement new favourites icon
  //const slitIndex = buttonsSplitIndex === -1 ? allActiveButtons?.length : buttonsSplitIndex;

  //const showMoreButtons = showMore && initialButtonsLength !== buttonsSplitIndex;

  const renderTopBar = () => {
    return (
      <>
        <Flex.ACJB className={`${cssGlobalNS}-gl-top-bar--inner`} style={{ visibility: 'visible' }}>
          {showScrollableSection && (
            <div className={`${cssGlobalNS}-gl-top-bar__toolbar custom-scrollbars`} ref={setRefs}>
              {buttonsToShow}
            </div>
          )}

          {showScrollableSection && !!slideInButtons?.length && showMoreButtons && (
            <AnimatePresence>
              {showMore && (
                <motion.div
                  variants={fromRight}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  className={`${cssGlobalNS}-gl-top-bar__toolbar slide-in`}
                >
                  {slideInButtons}
                </motion.div>
              )}
            </AnimatePresence>
          )}

          {isMobile && !!slideInButtons?.length && showMoreButtons && (
            <div className="egtd-gl__btn egtd-gl__btn-switch">
              <div
                className="egtd-gl__btn-animation"
                style={{ width: '20px', height: '20px' }}
                onClick={() => {
                  setShowMore(!showMore);
                  anim.current?.setDirection(showMore ? -1 : 1);
                  anim.current?.play();
                }}
                ref={animationContainer}
              ></div>
              {showMore ? t('GameLaunch.MoreButton.hideText') : t('GameLaunch.MoreButton.moreText')}
            </div>
          )}
        </Flex.ACJB>
        {showPinnedSection && showRealityCheckSession && <RealityCheckSessionTopBar />}
      </>
    );
  };

  return casinoWidgetConfig ? (
    <StyledNewTopBar
      className={`d-flex-ac-jb ${cssGlobalNS}-gl-top-bar`}
      $isIOS={isIOS}
      $isMobile={isMobile}
      $isPortrait={isPortrait}
    >
      {renderTopBar()}
    </StyledNewTopBar>
  ) : (
    <Flex.ACJB className={`${cssGlobalNS}-gl-top-bar`}>{renderTopBar()}</Flex.ACJB>
  );
};

const StyledNewTopBar = styled.div<{
  $isMobile: boolean;
  $isIOS: boolean;
  $isPortrait: boolean;
}>`
  ${({ $isMobile, $isPortrait, $isIOS }) =>
    $isMobile &&
    `
    position: fixed;
    left: 0;
    bottom: ${$isPortrait && $isIOS ? 'calc( 0px + env(safe-area-inset-bottom))' : '0px'};
  `};
  ${({ $isMobile }) =>
    !$isMobile &&
    `
    width: calc(100% - 98px);
  `};
`;

export default NewTopBar;
