import styled from 'styled-components';
import { CSS } from 'utils/common/types/css.types';

interface StyledCTAButtonProps {
  size?: CSS.Sizes;
  variant?: 'accent';
}

const paddings = {
  sm: '2px 15px',
  lg: '10px 25px',
  xl: '10px 30px',
};

export const StyledCTAButton = styled('button')<StyledCTAButtonProps>`
  ${(p) => p.size && `padding: ${paddings[p.size]};`}
  ${(p) =>
    p.variant &&
    `color: #48494a;
    background-color: #fce34c;`};
  ${(p) =>
    p.size === 'xl' &&
    `font-size: clamp(0.875rem, 0.442rem + 1.85vw, 1.625rem);
`};

  border-radius: 3px;
`;
