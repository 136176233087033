import { useEffect } from 'react';
import { loginThunks } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch } from 'store';
import { hideRootModal } from '../slice/rootModal.slice';

const useInactivityModalData = (time: number) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(hideRootModal());
      dispatch(loginThunks.logout());
    }, time);
    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, time]);
};

export default useInactivityModalData;
