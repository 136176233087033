import React, { memo, useMemo } from 'react';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { EventPartTranslation, EventTranslation } from 'utils/common/translationUtils/translationStrings';
import { Sport, SportIDs } from 'utils/common/types/sportTypes';
import { SportDataTypes } from 'utils/common/types/sportTypes/sportData.types';
import { getPeriodScore, isBetgeniusWidget } from './utils/eventScore.utils';
import { EventSelectors } from '../types/eventProps.types';

interface Props {
  periodScore?: Sport.PeriodScoresData | undefined;
  sportId: number;
  eventClockStatus?: Sport.EventClockStatus;
  eventStatusConfig: SportDataTypes.EventStatusConfig | null;
  isLiveEventsNavigation?: boolean;
  className?: string;
  sportEventId?: number;
  eventSelectors?: EventSelectors;
  producerId?: number;
}

const EventPart: React.FC<Props> = ({
  periodScore: periodScoreProps,
  sportId,
  eventClockStatus,
  eventStatusConfig,
  isLiveEventsNavigation,
  className,
  sportEventId,
  eventSelectors,
  producerId,
}) => {
  const matchStatusSelected = useAppSelector((state) =>
    eventSelectors && sportEventId ? eventSelectors.matchStatus(state, sportEventId, sportId) : undefined,
  );
  const periodScoreSelected = useAppSelector((state) =>
    eventSelectors && sportEventId ? eventSelectors.periodScore(state, sportEventId, sportId) : undefined,
  );
  const matchStatus = eventClockStatus?.matchStatus || matchStatusSelected;
  const periodScore = periodScoreProps || periodScoreSelected;
  const eventPart = useMemo(
    () =>
      eventStatusConfig?.showMatchStatuses
        ?.filter((x) => x.matchStatus === matchStatus)
        ?.map((part) => (isMobile || isLiveEventsNavigation ? part.shortName : part.name)),
    [matchStatus, eventStatusConfig?.showMatchStatuses, isLiveEventsNavigation],
  );
  const { t } = useTranslation();
  const hideEventPart = matchStatus === '1st half' || matchStatus === '2nd half';
  const partClass = clsx('part', className);

  const roundCS = getPeriodScore(periodScore)?.map((data) => Number(data.homeScore) + Number(data.awayScore) + 1);
  const round = roundCS <= 30 ? 30 : 'OT'; // rounds for CS are max 30 and if there is equal result - we put over time - OT
  switch (sportId) {
    case SportIDs.tennis:
      return (
        <>
          {!isEmpty(periodScore) ? (
            <>
              <span className={partClass}>
                {t(EventPartTranslation.PartGame.setString)}{' '}
                {getPeriodScore(periodScore)?.map((data) => data.periodNumber)}
              </span>
              <span className={partClass}>
                {t(EventPartTranslation.PartGame.gameString)}{' '}
                {getPeriodScore(periodScore)?.map((data) => Number(data.homeScore) + Number(data.awayScore) + 1)}
              </span>
            </>
          ) : (
            <span className={partClass}>{eventPart}</span>
          )}
        </>
      );
    case SportIDs.counter_strike:
      return (
        <>
          {!isEmpty(eventPart) ? <span className={partClass}>{eventPart}</span> : ''}
          {!isEmpty(periodScore) && !isBetgeniusWidget(producerId) && (
            <span className={partClass}>{t(EventTranslation.Score.eSportsRound) + ' ' + roundCS + `/${round}`}</span>
          )}
        </>
      );
    case SportIDs.soccer:
    case SportIDs.handball:
    case SportIDs.esoccer:
    case SportIDs.basketball_3x3:
      return !isEmpty(eventPart) && !hideEventPart ? (
        <span className={`${partClass} text-truncate`}>{eventPart}</span>
      ) : (
        <></>
      );
    default:
      return !isEmpty(eventPart) ? <span className={`${partClass} text-truncate`}>{eventPart}</span> : <></>;
  }
};

export default memo(EventPart);
