import WidgetLobbyAction from './WidgetLobbyAction';
import { CategoryLayoutTypes } from '../../../types/CategoryLayoutTypes';

const widgetActionComponents = {
  PROMO: WidgetLobbyAction,
};

const widgetLobbyActions = {
  PROMO: widgetActionComponents.PROMO,
};

export const widgetBreadCrumbActionByLayout = {
  [CategoryLayoutTypes.LOBBY]: widgetLobbyActions,
};
