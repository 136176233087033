import { RefObject } from 'react';
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { useScrollPosition } from 'hooks/common/useScrollPosition';
import { useAppDispatch, useAppSelector } from 'store';
import { Breakpoints } from 'theme/Theme';
import { selectAppHeader, setAppHeaderHeight, setTopNavHeaderStatus } from '..';
import { LayoutConfigTypes } from '../../general/types/generalSlice.types';

export const useHideTopNavHeaderOnScroll = (appHeaderRef: RefObject<HTMLElement>): void => {
  const dispatch = useAppDispatch();
  const topNavHeaderStatus = useAppSelector(selectAppHeader.topNavHeaderStatus);
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isTabletOrMobile}px)` });
  const navigationConfig = useLayoutConfigType({ moduleLayout: 'platform', innerLayout: LayoutConfigTypes.NAVIGATION });

  useScrollPosition({
    effectFunc: (dir, position) => {
      if (topNavHeaderStatus === 'fixed-collapsed') {
        return;
      }
      if (dir === 'up' || position.top > -300) {
        if (topNavHeaderStatus === 'collapsed') {
          dispatch(setTopNavHeaderStatus('visible'));
          dispatch(setAppHeaderHeight(appHeaderRef.current?.clientHeight));
        }
      } else if ((dir === 'down' && position.top < -300) || position.top < -300) {
        if (topNavHeaderStatus === 'visible') {
          dispatch(setAppHeaderHeight(appHeaderRef.current?.clientHeight));
          dispatch(setTopNavHeaderStatus('collapsed'));
        }
      }
    },
    enableHook: (isTabletOrMobile || isMobile) && navigationConfig?.topNav && !navigationConfig?.topNavInitialCollapsed,
  });
};
