import { Messages } from './translationUtils/translationStrings';

export const DEFAULT_ERROR_MSG = Messages.DefaultError.defaultErrorString;
export const DEFAULT_BET_SLIP_ERROR_MSG = 'BET_3031';
export const ERROR_MESSAGE_SOMETING_WENT_WRONG = 'BET_3000';

export const defaultOddsFormat = 2;

export const minBetAmounts = {
  BGN: 0.25,
  EUR: 0.13,
} as const;

export const DEFAULT_MIN_BET_AMOUNT = minBetAmounts.EUR;

export type DeviceType = 'mobile' | 'tablet' | 'desktop' | '' | 'terminal';

export const GLOBAL_TTL = 7200000; //2h
