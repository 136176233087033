import { RefObject, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { showRootCasinoModal } from 'modules/casino/shared/features/rootCasinoModal/rootCasinoModal.slice';
import { selectOffersTab } from 'shared/common/features/myProfile/sections/tabs/offersTab/slice/offersTab.slice';
import { showMyProfileModal } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch, useAppSelector } from 'store';
import { BonusStatus, setRedirectedOfferId } from '../..';
import { Bonus, BonusButton, BonusButtonAction } from '../../types/offersTab.types';

type UsePromotionsReturn = {
  error?: string;
  status: BonusStatus;
  buttonClickHandler: (newActionBtn?: BonusButton) => () => Promise<void>;
  elRef: RefObject<HTMLDivElement> | null;
};

const usePromotions = (bonus: Bonus): UsePromotionsReturn => {
  const redirectedOfferId = useAppSelector(selectOffersTab.redirectedOfferId);
  const { status, error } = useAppSelector((state) => selectOffersTab.bonusStatusAndError(state, bonus.id));
  const elRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (`${bonus.id}` === redirectedOfferId) {
      elRef?.current?.scrollIntoView();
      dispatch(setRedirectedOfferId(''));
    }
  }, [elRef, bonus.id, redirectedOfferId, dispatch]);

  const buttonClickHandler = useCallback(
    (newActionBtn?: BonusButton) => async () => {
      const { buttonActionType, buttonUrl } = newActionBtn || {};
      const button = { buttonActionType, buttonUrl };

      if (button.buttonActionType === BonusButtonAction.Navigate) {
        dispatch(showMyProfileModal(false));
        button.buttonUrl &&
          bonus?.id &&
          navigate(button.buttonUrl, {
            state: { promotionId: `${bonus.id}`, from: `${location.pathname}${location.search}` },
          });
        return;
      }
      if (button.buttonActionType === BonusButtonAction.Deposit) {
        dispatch(
          showRootCasinoModal({
            modalType: 'QUICK_DEPOSIT_MODAL',
            modalProps: { promotionId: `${bonus.id}` },
          }),
        );
      }
      if (
        button.buttonActionType === BonusButtonAction.Accept ||
        button.buttonActionType === BonusButtonAction.Cancel
      ) {
        const modalType = button.buttonActionType === BonusButtonAction.Accept ? 'ACCEPT_OFFER' : 'CANCEL_OFFER';

        dispatch(
          showRootModal({
            modalType,
            modalProps: { bonus },
            modalPriority: ModalPriorityEnum.LOW,
            modalId: modalType,
          }),
        );
      }
    },
    [bonus, navigate, dispatch, location],
  );

  return { status, error: t(error || ''), buttonClickHandler, elRef };
};

export default usePromotions;
