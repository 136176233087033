import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAbortThunk } from 'modules/casino/store/thunkCreators';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import axiosInstance from 'utils/common/axios-instance';
import config from 'utils/config';
import { RecommendationEngineTypes } from '../types/recommendationEngineTypes';

export const recommendationsCheckThunk: RecommendationEngineTypes.RecommendationsCheckThunk = {
  fetchInSegmentCheck: createAbortThunk('recommendationEngine/fetchInSegmentCheck', async (_, { source }) => {
    const fetchURL = `${config.API_URL}/api/casino-recommendations/player/recommendations/feed/inSegmentCheck`;
    const response = await axiosInstance.get<RecommendationEngineTypes.FetchInSegmentCheckParams>(fetchURL, {
      data: null,
      cancelToken: source.token,
    });

    return response.data;
  }),
  postRecommendationGameClick: createAbortThunk(
    `recommendationEngine/postRecommendationGameClick`,
    async ({ params }, { source }) => {
      const fetchUrl = `${config.API_URL}/api/casino-recommendations/player/recommendations/feed/Clicks`;

      try {
        await axiosInstance.post(fetchUrl, params, { cancelToken: source.token });
      } catch (error) {
        // console.log('recommendationEngine/postRecommendationGameClick', error);
      }
    },
  ),
  postRecommendationCategoryImpressions: createAbortThunk(
    `recommendationEngine/postRecommendationCategoryImpressions`,
    async ({ params }, { source }) => {
      const fetchUrl = `${config.API_URL}/api/casino-recommendations/player/recommendations/feed/Impressions`;

      try {
        await axiosInstance.post(fetchUrl, params, { cancelToken: source.token });
      } catch (error) {
        // console.log('recommendationEngine/postRecommendationCategoryImpressions', error);
      }
    },
  ),
};

export const { fetchInSegmentCheck, postRecommendationGameClick, postRecommendationCategoryImpressions } =
  recommendationsCheckThunk;

const initialState: RecommendationEngineTypes.State = {
  isSegmentCheckEnabled: false,
};

const recommendationEngine = createSlice({
  name: 'recommendationEngine',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchInSegmentCheck.fulfilled,
        (state, action: PayloadAction<RecommendationEngineTypes.FetchInSegmentCheckParams>) => {
          const { segmented } = action.payload;
          state.isSegmentCheckEnabled = segmented;
        },
      )
      .addCase(fetchInSegmentCheck.rejected, (state) => {
        state.isSegmentCheckEnabled = false;
      });
    builder.addCase(logoutUser, (state) => {
      state.isSegmentCheckEnabled = false;
    });
  },
});

const selectIsSegmentCheckEnabled = (state: RootState): boolean =>
  state.casino.recommendationEngine.isSegmentCheckEnabled;

export const recommendationEngineSelectors = {
  isSegmentCheckEnabled: selectIsSegmentCheckEnabled,
};

export default recommendationEngine.reducer;
