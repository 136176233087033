import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from 'modules/casino/theme/Theme';
import { getJackpotImageSources } from '../features/jackpots/jackpotUtils';

const useSectionBackground = (backgroundLocation, backgroundType) => {
  const isTablet = useMediaQuery({ query: `(max-width: ${Breakpoints.isTabletOrMobile}px)` });
  const isMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isMobile}px)` });

  const getHeight = (): number | null => {
    if (backgroundType === 'Lobby') {
      let height = isTablet ? 418 : 540;
      // Mobile Size
      if (isMobile) {
        height = 566;
      }
      return height;
    } else {
      return null;
    }
  };
  const getWidth = () => {
    if (backgroundType === 'Lobby') {
      let width = isTablet ? 1024 : 1920;
      if (isMobile) {
        width = 374;
      }
      return width;
    } else {
      let width = isTablet ? 1024 : 1920;
      if (isMobile) {
        width = 748;
      }
      return width;
    }
  };

  const getSectionBackground = () => {
    const height = getHeight();
    const width = getWidth();
    const noFit = backgroundType === 'Lobby' ? false : true;
    return `url(${getJackpotImageSources(backgroundLocation, width, height, isMobile ? 2 : 1, noFit)})`;
  };

  return [getSectionBackground()];
};

export default useSectionBackground;
