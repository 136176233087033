import useCasinoWidgetConfig from 'modules/casino/platformImplementation/hooks/useCasinoWidgetConfig';
import { selectIntegrations } from 'shared/common/features/integrations/slice/integrations.slice';
import { useAppSelector } from 'store';
import { useGridLayoutSettings } from '../shared/components/gridLayoutSettings/hooks/useGridLayoutSettings';

export const useGetEnabledColorIcons = (): boolean => {
  const casinoWidgetConfig = useCasinoWidgetConfig();
  const { enableColorIcons } = useGridLayoutSettings();
  const casinoWidgetEnabledColorIcons = useAppSelector(selectIntegrations.casinoWidgetEnabledColorIcons);

  return casinoWidgetConfig ? casinoWidgetEnabledColorIcons : enableColorIcons;
};
