export const useScrollToActiveElement = () => {
  const scrollToActiveElement = (scrollContainerInner, activeClass: string | undefined) => {
    if (scrollContainerInner) {
      const activeEl = scrollContainerInner.querySelectorAll(`.${activeClass}`)[0] as HTMLElement;
      if (activeEl) {
        const leftScroll: number = scrollContainerInner?.scrollLeft as number;
        const viewPort: number = (scrollContainerInner?.scrollLeft + scrollContainerInner?.clientWidth) as number;
        const elementPosition: number = (activeEl.offsetLeft + activeEl.offsetWidth) as number;
        const scrollLeftDirection = leftScroll >= activeEl.offsetLeft; // + (activeEl.offsetWidth * 2)
        const scrollRightDirection = viewPort < elementPosition; //+ (activeEl.offsetWidth * 2)
        const inView = scrollLeftDirection ? activeEl.offsetLeft : activeEl.offsetLeft + activeEl.offsetWidth / 2;

        if (scrollLeftDirection || scrollRightDirection) {
          scrollContainerInner?.scrollTo({
            behavior: 'smooth',
            left: scrollLeftDirection
              ? activeEl.offsetLeft + activeEl.offsetWidth / 2 - scrollContainerInner?.clientWidth / 2
              : inView - scrollContainerInner?.clientWidth + scrollContainerInner?.clientWidth / 2,
          });
        }
      } else {
        scrollContainerInner?.scrollTo({ behavior: 'smooth', left: 0 });
      }
    }
  };
  return scrollToActiveElement;
};
