import React from 'react';
import { useTranslation } from 'react-i18next';
import { CasinoGamesRender } from 'modules/casino/modules/container/cellsContainer/cellls';
import { SearchMessage } from 'modules/casino/shared/components/searchMessage';
import { MessageVariant } from 'modules/casino/shared/components/searchMessage/SearchMessageConstants';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from '../../../../modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { useSearchGridWrapper } from '../hooks/useSearchGridWrapper';

type Props = {
  categoryLayoutType: CategoryLayoutTypes;
  collectionId: string;
  categoryId: string;
  subType: CategorySubTypes;
  searchValue?: string;
};

const SearchGridWrapper: React.FC<Props> = ({ categoryLayoutType, collectionId, categoryId, subType, searchValue }) => {
  const { t } = useTranslation();
  const { propsByType } = useSearchGridWrapper({ categoryLayoutType, categoryId, collectionId, searchValue });

  const renderComponents = () => {
    if (propsByType.showFallback) {
      return <SearchMessage variant={MessageVariant.default} message={t('CasinoSearch.Message.noInput')} />;
    }

    return (
      <>
        <div className="results-count">{propsByType.title}</div>
        <CasinoGamesRender
          params={propsByType.gridParams}
          categoryId={categoryId}
          subType={subType}
          categoryLayoutType={categoryLayoutType}
        />
      </>
    );
  };

  return <div className="search-data">{renderComponents()}</div>;
};

export default SearchGridWrapper;
