import React from 'react';
import clsx from 'clsx';
import { Text } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { cssNS } from './bonusLogUITypes';

/** BonusLog Text Row */
interface TextRowProps {
  /** Optional CSS class */
  className?: string;
  /** Show the right column? */
  showRightCol?: boolean;
  /** Left column key text */
  leftColText: string;
  /** Left column value */
  leftColValue?: string | number;
  children?: React.ReactNode;
  /** Right column key text */
  rightColText?: string;
  /** Right column value */
  rightColValue?: string;
}

const TextRow: React.FC<TextRowProps> = ({
  showRightCol = true,
  className,
  leftColText,
  leftColValue,
  rightColText,
  rightColValue,
  children,
}) => {
  const rowClass = clsx(className);
  const leftColClass = clsx(showRightCol && `text-nowrap ${cssNS}__row`);
  return (
    <Flex.ACJB className={rowClass}>
      <Flex className={leftColClass}>
        <Text className={`ma-text--accent`} text={`${leftColText}:\xa0`} />
        {leftColValue}
        {children}
      </Flex>
      {showRightCol && (
        <Flex className={`text-right text-nowrap ${cssNS}__row`}>
          {rightColText && <Text className={`ma-text--accent`} text={`${rightColText}:`} />}
          &nbsp;{rightColValue}
        </Flex>
      )}
    </Flex.ACJB>
  );
};

export default TextRow;
