import React from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { AppLogo } from 'components/shared';
import { Breakpoints } from 'theme/Theme';
import { cssGlobalNS } from 'utils/common/types/css.types';

const LoginPageHeader: React.FC = () => {
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });
  const spanClass = clsx('d-flex-center brand', isDesktop ? 'mr-auto' : 'mx-auto');

  return (
    <nav className={'d-flex-ac mnav login-page__header'}>
      <div className={`d-flex ${cssGlobalNS}-container`}>
        <div className={spanClass}>
          <AppLogo id="app-logo-navbar" />
        </div>
      </div>
    </nav>
  );
};

export default LoginPageHeader;
