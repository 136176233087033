import { useEffect } from 'react';
import { usePrevious } from 'hooks/usePrevious';
import { ws } from 'shared/common/features/websockets/slice/webSockets.slice';
import { useAppSelector } from 'store';

const isPromise = (p) => {
  return p && Object.prototype.toString.call(p) === '[object Promise]';
};

export const useWsEffect = <T>(
  effect: (isWsReconnect?: boolean) => (() => void) | undefined,
  dependencies?: T[],
): void => {
  const status = useAppSelector(ws.selectStatus);
  const prevStatus = usePrevious(status);

  // invoke only when dependencies change
  useEffect(() => {
    const effectResult = effect();

    if (effectResult && !isPromise(effectResult)) {
      return effectResult;
    }
  }, [...(dependencies ?? [])]);

  // invoke only when web socket reconnect
  useEffect(() => {
    if (status !== prevStatus && status === 'init' && prevStatus === 'close') {
      const effectResult = effect(true);
      if (effectResult && !isPromise(effectResult)) {
        return effectResult;
      }
    }
  }, [status, prevStatus]);
};
