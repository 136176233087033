import styled from 'styled-components';

const StyledCardDescription = styled.div.attrs({ className: 'prm-lobby-card__description' })`
  display: -webkit-box;
  text-overflow: ellipsis;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  min-height: clamp(2.75rem, calc(2.497rem + 1.08vw), 3.188rem);
  line-height: 1.2;
`;

export default StyledCardDescription;
