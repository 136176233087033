import { useEffect } from 'react';
import {
  casinoLaunchSelectors,
  resetLaunchData,
  resetOpenGameData,
} from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import { AggregatorConfig, CasinoLaunchGameTypes } from 'modules/casino/shared/features/gameLaunch/casinoLaunchTypes';
import useOpenNewWindowsEventListeners from 'modules/casino/shared/features/gameLaunch/openLauncher/hooks/useOpenNewWindowsEventListeners';
import { newGameWindowOpen } from 'modules/casino/shared/utils/casinoUtils';
import { useStableNavigate } from 'modules/casino/shared/utils/common/StableNavigateContext';
import { useAppDispatch, useAppSelector } from 'store';

const openWindowsCollection: any = [];

interface Props {
  launchRoute: string;
  openGameData: CasinoLaunchGameTypes.OpenGameData;
  aggregatorConfig: AggregatorConfig;
}
const OpenNewWindowLauncherWrapper = ({ launchRoute, openGameData, aggregatorConfig }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useStableNavigate();
  const isInGameLaunch = useAppSelector(casinoLaunchSelectors.isInGameLaunch);
  const { providerAlias } = openGameData;

  const { addEventListeners } = useOpenNewWindowsEventListeners({ openWindowsCollection });

  useEffect(() => {
    dispatch(resetOpenGameData());
  }, [dispatch]);

  const ratioTypes = {
    oldStandard: 4 / 3,
    modernStandard: 16 / 9,
    customRatio1: 1280 / 853,
  };

  const openNewWindow = () => {
    if (isInGameLaunch) {
      navigate(launchRoute, { replace: true, state: Date.now() });
      dispatch(resetLaunchData());
      return;
    }
    const ratio = aggregatorConfig.stylesConfig.customRatio1.includes(providerAlias)
      ? ratioTypes.customRatio1
      : aggregatorConfig.stylesConfig.oldStandardRatio.includes(providerAlias)
        ? ratioTypes.oldStandard
        : ratioTypes.modernStandard;
    const windowId = Math.floor(Math.random() * 10001);
    const newWindow: Window | null = newGameWindowOpen({
      url: `${window.location.origin}${launchRoute}`,
      title: `newwindow${windowId}`,
      ratio,
    });

    openWindowsCollection.push(newWindow);
    window['openWindows'] = openWindowsCollection;
    setTimeout(() => {
      if (newWindow?.closed) {
        // If we close window immediately right after opening we need to check, because beforeunload now working
        dispatch(resetLaunchData());
      }
    }, 4000);

    // uncomment this if game isGameLaunchedInNewWindow state is needed
    // const timer = setInterval(() => {
    //   if (newWindow?.closed) {
    //     dispatch(setGameLaunchedInNewWindow(false));
    //     clearInterval(timer);
    //   }
    // }, 1000);

    newWindow && addEventListeners(newWindow);
  };

  return openNewWindow();
};

export default OpenNewWindowLauncherWrapper;
