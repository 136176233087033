import styled from 'styled-components';

const ActionHandWrapper = styled.div<{
  width: number;
  height: number;
  left: number;
  top: number;
}>`
  position: absolute;
  top: ${({ top, height }) => `${top + height + 10}px`};
  left: ${({ width, left }) => `${width / 2 + left - 60 / 2}px`};
`;

export default ActionHandWrapper;
