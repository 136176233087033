import React from 'react';
import clsx from 'clsx';
import { GameCellDataCharactersTypes } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTableCharacters/gameCellTableCharactersConstants';
import { Flex } from 'modules/casino/shared/components/layout';
import { colorSymbol, convertSymbol } from '../GameCellTableCharactersUtils';

export interface ItemsProps {
  symbol;
  category: GameCellDataCharactersTypes;
  classFromLayout: string;
  showLuckyNumber?: boolean;
  luckyNumber?: number;
}

export const Item: React.FC<ItemsProps> = (props) => {
  const numberOne = convertSymbol(props.symbol, props.category);
  const charStyles = clsx(
    'character',
    props.luckyNumber && props.showLuckyNumber && 'lucky-numbers-wrapper',
    props.classFromLayout,
    colorSymbol(props.symbol, props.category) ? colorSymbol(props.symbol, props.category) + '-wrapper' : '',
  );
  return (
    <Flex.ColCenter className={charStyles}>
      {props.luckyNumber && props.showLuckyNumber && <div className={`lucky-numbers`}>{`x${props.luckyNumber}`}</div>}
      <Flex.Center className={`${colorSymbol(props.symbol, props.category)}-text character-text`}>
        {numberOne}
      </Flex.Center>
    </Flex.ColCenter>
  );
};
Item.displayName = 'DataCharacter.Item';
