import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { AppLogo } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { SSBT } from 'utils/common/translationUtils/translationStrings';
import Close from './Close';

/** Cashier Modal Header Section */
interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  clearData: () => void;
  text?: string | React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ className, clearData, text }) => {
  const { t } = useTranslation();
  const headerClass = clsx('cashier-modal__header', className);
  return (
    <Flex.ACJB className={headerClass}>
      <AppLogo id="app-logo-navbar" />
      <div className="title">{text}</div>
      <Close text={t(SSBT.TerminalModal.closeTerminalModalText)} onClick={clearData} />
    </Flex.ACJB>
  );
};

export default Header;
