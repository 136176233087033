import { useState } from 'react';
import { useInterval } from 'hooks/common/useInterval';
import { authConstants } from 'pages/auth/login/loginHelpers';
import { loginThunks } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch } from 'store';
import { hideRootModal } from '../slice/rootModal.slice';

const useSessionModalData = ({
  intervalDelay,
  timer,
  isExpired,
}: {
  intervalDelay: number;
  timer: number | undefined;
  isExpired: boolean;
}) => {
  const [timeLeft, setTimeLeft] = useState<number>(timer || authConstants.DEFAULT_TIME_FOR_DECISION_IN_SEC);
  const dispatch = useAppDispatch();
  const timeToDisplay = timeLeft < 10 ? `0${timeLeft}` : `${timeLeft}`;

  useInterval(() => {
    if (!isExpired) {
      if (timeLeft <= 0) {
        dispatch(hideRootModal());
        dispatch(loginThunks.logout());
      } else {
        setTimeLeft(timeLeft - 1);
      }
    }
  }, intervalDelay);

  const handleOnSubmit = async () => {
    !isExpired && dispatch(loginThunks.refreshToken());
    dispatch(hideRootModal());
  };

  return {
    timeToDisplay,
    handleOnSubmit,
  };
};

export default useSessionModalData;
