import React, { Fragment, useState } from 'react';
import { useGenerateNextElements } from './useGenerateNextElements';
import { rowsGridNumber } from '../constants/gridContants';
import { getGameLayoutByUniqueId } from '../utils/gridUtils';

export const useSetNextElements = ({ children, gamesIds, layoutRows, totalElements, collectionId, layoutGrid }) => {
  const initialCellElements = {};
  initialCellElements[collectionId] = [];
  const [cellElements, setCellElements] = useState<Record<string, any>>(initialCellElements);
  const cellCollection = cellElements[collectionId] || [];
  const rows = rowsGridNumber[layoutRows];
  const { isGridCellFilledUp } = useGenerateNextElements();

  const stopRender = (gameUniqueId, finishRender, columns, gridPage) => {
    const gameLayout = getGameLayoutByUniqueId(gameUniqueId);
    const itemGameLayout = gameLayout?.toLowerCase();
    return isGridCellFilledUp(itemGameLayout, rows, columns, gridPage, layoutGrid) || finishRender;
  };

  const createElements = (startIndex, gridPage, columns, onPauseCallback, savedGridPage, skipLazyLoad = false) => {
    const elements: React.ReactNode[] = [];
    for (let index = startIndex; index < gamesIds.length; index++) {
      const itemId = gamesIds[index];
      const finishRender = totalElements - 1 === index;
      const pauseGrid = stopRender(itemId, finishRender, columns, gridPage);
      const gamesRenderedCount = savedGridPage * rows * columns;
      elements.push(
        <Fragment key={itemId}>
          {children(itemId, index, pauseGrid, savedGridPage, gamesRenderedCount, skipLazyLoad)}
        </Fragment>,
      );
      if (pauseGrid) {
        onPauseCallback(elements);
        break;
      }
    }
  };

  const favouritesUpdate = (columns, gridPage) => {
    const onPauseCallback = (elements) => {
      const updatedCollection = {};
      updatedCollection[collectionId] = [...elements];
      setCellElements(updatedCollection);
    };
    createElements(0, gridPage, columns, onPauseCallback, gridPage);
  };

  const columnChangeUpdate = (columns, gridPage) => {
    const onPauseCallback = (elements) => {
      const updatedCollection = {};
      updatedCollection[collectionId] = [...elements];
      setCellElements(updatedCollection);
    };
    createElements(0, gridPage, columns, onPauseCallback, gridPage);
  };

  const svaraGamesUpdate = (columns, gridPage) => {
    const onPauseCallback = (elements) => {
      const updatedCollection = {};
      updatedCollection[collectionId] = [...elements];
      setCellElements(updatedCollection);
    };
    createElements(0, gridPage, columns, onPauseCallback, gridPage);
  };

  const initialCreate = (columns) => {
    const onPauseCallback = (elements) => {
      const updatedCollection = {};
      updatedCollection[collectionId] = [...cellCollection, ...elements];
      setCellElements(updatedCollection);
    };
    createElements(0, 1, columns, onPauseCallback, 1);
  };

  const loadMoreDataUpdate = (columns, gridPage) => {
    const onPauseCallback = (elements) => {
      const updatedCollection = {};
      updatedCollection[collectionId] = [...cellCollection, ...elements];
      setCellElements(updatedCollection);
    };
    const startIndex = cellCollection.length;
    createElements(startIndex, 1, columns, onPauseCallback, gridPage);
  };

  const restoreToScrollPosition = (columns, gridPage, skipLazyLoad) => {
    const onPauseCallback = (elements) => {
      const updatedCollection = {};
      updatedCollection[collectionId] = [...elements];
      setCellElements(updatedCollection);
    };
    createElements(0, gridPage, columns, onPauseCallback, gridPage, skipLazyLoad);
  };

  const clearCellElements = () => {
    setCellElements([]);
  };

  return {
    columnChangeUpdate,
    loadMoreDataUpdate,
    initialCreate,
    favouritesUpdate,
    svaraGamesUpdate,
    restoreToScrollPosition,
    cellElements,
    clearCellElements,
  };
};
