import { useEffect } from 'react';
import { ws } from 'shared/common/features/websockets/slice/webSockets.slice';
import { useAppDispatch } from 'store';

export const useUnsubscribe = (): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(ws.unsubscribeUrlRedirect());
  }, [dispatch]);
};
