import React from 'react';
import clsx from 'clsx';
import { WidgetTemplate } from 'modules/casino/shared/constants';
import { CasinoWidgetsTypes } from '../../../../types/casinoWidgetsTypes';

interface WrapperProps {
  type: CasinoWidgetsTypes.WidgetTypes;
  columns: number;
  children: React.ReactNode;
  backgroundUrl?: string;
  wrapperClassName?: string;
}

const Wrapper: React.FC<WrapperProps> = ({ type, backgroundUrl, columns, children, wrapperClassName }) => {
  const wrapperClass = clsx('cw', `cw--${WidgetTemplate[type]}`, wrapperClassName && wrapperClassName);
  const wrapperStyle = {
    backgroundImage: backgroundUrl && backgroundUrl,
  };
  const innerWrapperStyle = {
    gridTemplateColumns: WidgetTemplate[type] === WidgetTemplate.SECTION ? `` : `repeat(${columns}, ${100 / columns}%)`,
  };

  return (
    <div className={wrapperClass} style={wrapperStyle}>
      <div className="cw--inner-wrapper" style={innerWrapperStyle}>
        {children}
      </div>
    </div>
  );
};
Wrapper.displayName = 'LastWinnersWidgetsUI.Wrapper';
export default Wrapper;
