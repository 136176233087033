import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { ModalUI } from 'components/shared/ModalUI';

const SessionCreateTimeout: React.FC<{ translationKey?: string }> = ({ translationKey }) => {
  const { t } = useTranslation();

  return (
    translationKey && (
      <Flex.Col>
        <ModalUI.Title hasAccent className={'reality-check-session-timeout__title mb-2'} text={t(translationKey)} />
      </Flex.Col>
    )
  );
};

export default SessionCreateTimeout;
