import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAbortThunk } from 'modules/casino/store/thunkCreators';
import { RootState } from 'store/rootReducer';
import axiosInstance from 'utils/common/axios-instance';
import config from 'utils/config';
import { GridLayoutSettingsTypes, ProviderSliderLayout } from './gridLayoutSettingsTypes';
import { RequestStatuses } from '../../constants';

export const gridLayoutSettingsThunks: GridLayoutSettingsTypes.Thunks = {
  fetchGridLayoutSettings: createAbortThunk('gridLayoutSettings/fetchGridLayoutSettings', async (_, { source }) => {
    const fetchURL = `${config.API_URL}/api/gaming/public/categories/grid-layout-settings`;
    const response = await axiosInstance.get<GridLayoutSettingsTypes.Response>(fetchURL, {
      data: null,
      cancelToken: source.token,
    });

    return response.data;
  }),
};

export const { fetchGridLayoutSettings } = gridLayoutSettingsThunks;

const initialState: GridLayoutSettingsTypes.State = {
  showGameCellFavButton: false,
  gridAnimationClass: '',
  hoverAnimationClass: '',
  isMenuGamesCount: false,
  enableColorIcons: false,
  enableFilterByProvider: null,
  menuNavigationLayout: null,
  providerNavigationLayout: null,
  providerSliderLayout: ProviderSliderLayout.NO_SLIDER,
  fetchStatus: RequestStatuses.INITIAL,
  ttl: 0,
};

const gridLayoutSettingsReducer = createSlice({
  name: 'gridLayoutSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        gridLayoutSettingsThunks.fetchGridLayoutSettings.fulfilled,
        (state, action: PayloadAction<GridLayoutSettingsTypes.Response>) => {
          const { gridLayoutSettings, navigationSettings } = action.payload;
          state.showGameCellFavButton = gridLayoutSettings?.showGameCellFavButton;
          state.gridAnimationClass = gridLayoutSettings?.gridAnimationClass;
          state.hoverAnimationClass = gridLayoutSettings?.hoverAnimationClass;
          state.enableColorIcons = navigationSettings?.enableColorIcons;
          state.enableFilterByProvider = navigationSettings?.enableFilterByProvider;
          state.isMenuGamesCount = navigationSettings?.menuGamesCount;
          state.menuNavigationLayout = navigationSettings?.menuNavigationLayout;
          state.providerNavigationLayout = navigationSettings?.providerNavigationLayout;
          state.providerSliderLayout = navigationSettings?.providerSliderLayout;
          state.fetchStatus = RequestStatuses.SUCCESS;
          state.ttl = Date.now();
        },
      )
      .addCase(gridLayoutSettingsThunks.fetchGridLayoutSettings.pending, (state) => {
        state.fetchStatus = RequestStatuses.PENDING;
      })
      .addCase(gridLayoutSettingsThunks.fetchGridLayoutSettings.rejected, (state) => {
        state.fetchStatus = RequestStatuses.ERROR;
      });
  },
});

export const selectGridLayoutSettings = (state: RootState) => state.casino.gridLayoutSettings;
const selectFetchStatus = (state: RootState) => state.casino.gridLayoutSettings.fetchStatus;
const selectTTL = (state: RootState) => state.casino.gridLayoutSettings.ttl;
const selectIsMenuGamesCount = (state: RootState) => state.casino.gridLayoutSettings.isMenuGamesCount;

export const gridLayoutSettingsSelectors = {
  gridLayoutSettings: selectGridLayoutSettings,
  fetchStatus: selectFetchStatus,
  ttl: selectTTL,
  isMenuGamesCount: selectIsMenuGamesCount,
};

export default gridLayoutSettingsReducer.reducer;
