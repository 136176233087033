import { useEffect, useCallback } from 'react';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import usePlayerRestrictions from 'hooks/common/usePlayerRestrictions';
import useStorageEvent from 'hooks/common/useStorageEvent';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import userStorage from 'pages/auth/login/UserStorage';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { useUserBalance } from 'shared/common/features/myProfile/hooks/useUserBalance';
import { hideRootModal, selectRootModal, showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { selectGeneral } from '../../general/slice/general.slice';
import { selectMyProfile } from '../slice/myProfile.slice';

export const INSUFFICIENT_BALANCE_MODAL = 'INSUFFICIENT_BALANCE_MODAL';

export const InsufficientBalanceTrigger = (): null => {
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const playerId = useAppSelector(selectMyProfile.playerId);
  const availableRestrictionProducts = useAppSelector(selectGeneral.availableRestrictionProducts);

  const storageValue = sessionStorage.getItem('egtd:insufficientBalance');
  const modalType = useAppSelector(selectRootModal.type);
  const { allProductsRestricted } = usePlayerRestrictions({
    products: availableRestrictionProducts || [],
    showModal: false,
  });

  const dispatch = useAppDispatch();
  const { balanceNumber } = useUserBalance();

  const balanceConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.INSUFFICIENT_BALANCE,
    deviceLayout: 'general',
  });

  const handleStorageEvent = useCallback(
    (event: StorageEvent) => {
      if (event.storageArea !== localStorage) return;
      if (event.key === 'egtd:modalType' && event.newValue === modalType) {
        dispatch(hideRootModal());
      }
    },
    [dispatch, modalType],
  );

  const clearStorageData = useCallback(() => {
    userStorage.removeUserModalType();
  }, []);

  useStorageEvent(handleStorageEvent, clearStorageData);

  useEffect(() => {
    const hasBalance = typeof balanceConfig?.minBalance === 'number' && !isEmpty(balanceNumber);
    if (isAuthenticated && playerId && !storageValue && hasBalance && !allProductsRestricted) {
      const showModal = balanceNumber <= balanceConfig?.minBalance;

      if (showModal) {
        sessionStorage.setItem('egtd:insufficientBalance', 'true');
        dispatch(
          showRootModal({
            modalType: INSUFFICIENT_BALANCE_MODAL,
            modalPriority: ModalPriorityEnum.MEDIUM,
            modalId: INSUFFICIENT_BALANCE_MODAL,
          }),
        );
      }
    }

    if (storageValue && hasBalance && balanceNumber > balanceConfig?.minBalance) {
      sessionStorage.removeItem('egtd:insufficientBalance');
    }
  }, [
    dispatch,
    balanceNumber,
    balanceConfig?.minBalance,
    isAuthenticated,
    storageValue,
    allProductsRestricted,
    playerId,
  ]);

  return null;
};
