export const useFullScreen = () => {
  const fullScreenAvailable =
    document.fullscreenEnabled ||
    document.mozFullscreenEnabled ||
    document.webkitFullscreenEnabled ||
    document.msFullscreenEnabled ||
    ('ontouchstart' in window && 'fullscreenEnabled' in window.screen);

  const isFullScreen = (): boolean => {
    return !!document.fullscreenElement;
  };

  const isFullScreenByButton = (): boolean => {
    return window.innerHeight === screen.height && !document.fullscreenElement;
  };

  return { fullScreenAvailable, isFullScreen, isFullScreenByButton };
};
