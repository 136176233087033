import React from 'react';
import { Icon, Text } from 'components/shared';
import { Flex } from 'components/shared/layout';

/** BlockButton */
interface BlockButtonProps {
  icon: string;
  text: string;
  onClick: React.MouseEventHandler;
}

const BlockButton: React.FC<BlockButtonProps> = ({ icon, text, onClick }) => {
  return (
    <Flex.Center className="mw-0 mpd-block__btn" onClick={onClick}>
      <Icon icon={icon} className="mr-2 mpd-block__btn-icon" />
      <Text className="text-truncate" text={text} />
    </Flex.Center>
  );
};

export default BlockButton;
