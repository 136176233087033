import React, { useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { selectRegistration, setWithSocial } from 'pages/auth/registration/slice/registration.slice';
import { useAppSelector, useAppDispatch } from 'store';
import { useSocialAuth } from './hooks/formSocial.hooks';
import AppleButton from './providers/Apple';
import FacebookButton from './providers/Facebook';
// import GoogleOAuth from './providers/Google/GoogleOAuth';
// import GoogleOneTap from './providers/Google/GoogleOneTap';
import GoogleButton from './providers/Google/GoogleButton';
import TwitterButton from './providers/Twitter';
import { Social, SocialTypes } from './types/formSocial.types';

const getSocialButton = (type: SocialTypes) => {
  switch (type) {
    case SocialTypes.APPLE:
      return AppleButton;
    case SocialTypes.GOOGLE:
      // return GoogleOneTap;
      // return GoogleOAuth;
      return GoogleButton;
    case SocialTypes.FACEBOOK:
      return FacebookButton;
    case SocialTypes.TWITTER:
      return TwitterButton;
    default:
      return {};
  }
};

const loadScript = ({ id, config }) => {
  if (document.getElementById(id)) return;
  const firstScript = document.getElementsByTagName('script')[0];
  const script = document.createElement('script');
  script.id = id;
  Object.assign(script, config);
  firstScript?.parentNode?.insertBefore(script, firstScript);
};

const FormSocial: React.FC<Social.Form> = ({ button, className = 'form-social', disabled }) => {
  const social = useAppSelector(selectRegistration.withSocial);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { type, authType, clientId, appVersion, redirectUrl } = button;
  const id = `${type}-jssdk`;
  const { onSocialAuth } = useSocialAuth({ social: type, authType });
  const { config, effect, element, close } = getSocialButton(type) as {
    config?: Social.Config;
    effect?: Social.Effect;
    element?: Social.Element;
    close?: () => void;
  };
  if (config?.src) loadScript({ id, config });

  const onClick = useCallback(() => {
    dispatch(setWithSocial(type));
  }, [type, dispatch]);

  useEffect(() => {
    if (close && social && social !== type) {
      setIsLoading(false);
      close();
    }
  }, [social, type, close]);

  useEffect(() => {
    if (config?.src && effect) {
      try {
        effect({ onSocialAuth, authType, clientId, appVersion, redirectUrl });
        setIsLoading(false);
      } catch {
        const script = document.getElementById(id);
        script?.addEventListener('load', () => {
          effect({ onSocialAuth, authType, clientId, appVersion, redirectUrl });
          setIsLoading(false);
        });
      }
    } else {
      setIsLoading(false);
    }
  }, [config?.src, id, authType, clientId, appVersion, redirectUrl, onSocialAuth, effect]);

  return (
    element && (
      <>
        {element({
          button,
          t,
          onClick,
          onSocialAuth,
          setIsLoading,
          isDisabled: isLoading || !!disabled,
          className: clsx(
            `${className}__button`,
            `${className}__button--${type}`,
            isLoading && `${className}__button--disabled`,
          ),
        })}
      </>
    )
  );
};

export default FormSocial;
