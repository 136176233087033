import Cell01Data from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell01/components/data';
import Cell01DataAlternative from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell01/components/dataAlternative';
import Cell01Default from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell01/components/default';
import {
  ComponentNames,
  ComponentType,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/layoutWrapperConstants';

const components = {
  [ComponentNames.GameCellWrapper]: {
    [ComponentType.default]: Cell01Default.GameCellWrapper,
    [ComponentType.data]: Cell01Data.GameCellWrapper,
    [ComponentType.dataAlternative]: Cell01DataAlternative.GameCellWrapper,
  },
  [ComponentNames.Description]: {
    [ComponentType.default]: Cell01Default.Description,
    [ComponentType.data]: Cell01Data.Description,
    [ComponentType.dataAlternative]: Cell01DataAlternative.Description,
  },
};

const cell01ComponentLoader = (componentName, componentType) => {
  return components[componentName][componentType];
};

export default cell01ComponentLoader;
