import { memo, useCallback } from 'react';
import clsx from 'clsx';
import { Link, useLocation, useMatch } from 'react-router-dom';
import { Flex } from 'components/shared/layout';
import { UI } from 'components/shared/UI';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { selecGamificationPage } from 'pages/gamificationPage/slice/gamificationPage.slice';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import {
  navigateToCasinoIntegration,
  navigateToIntegration,
} from 'shared/common/features/integrations/utils/integrations.utils';
import { useAppSelector } from 'store';
import { isActive } from 'utils/common/utilsNav';
import { CustomRibbon } from './components/CustomRibbon';
import { useRouteToInPlay } from '../../hooks/useRouteToInPlay';

type Props<T> = {
  subverticalsItem: T; // can be of type Vertical or Subvertical
};

const CustomSubNavLink = <
  T extends {
    isExternalRoute?: boolean;
    name: string;
    route: string;
    iconName?: string;
    ribbonConfig?: { isNew?: boolean; labelColor?: string; textColor?: string };
    onClick?: () => void;
  },
>({
  subverticalsItem,
}: Props<T>) => {
  const routeToInPlay = useRouteToInPlay(subverticalsItem.route);
  const route = routeToInPlay || subverticalsItem.route;
  const location = useLocation();
  const sportsMatch = useMatch(route ? `${route}/:params/*` : route);
  const casinoMatch = useMatch(route ? `${route}/category/:params` : route);
  const promoCount = useAppSelector(selecGamificationPage.unreadNotificationsByVertical(route));

  const showRibbon = subverticalsItem?.ribbonConfig?.isNew;
  const icon = subverticalsItem?.iconName;

  const handleNavClick = useCallback(() => {
    navigateToIntegration({ fullPath: route });
    navigateToCasinoIntegration({ fullPath: route });
  }, [route]);

  const linkStyles = clsx(
    'mnav__link',
    icon && 'mnav__link--has-icon',
    (isActive(route, sportsMatch, location) || casinoMatch || routeToInPlay) && 'mnav__link--active',
  );

  const isNewRibbon = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.VERTICALS,
    deviceLayout: 'general',
  })?.newRibbon;

  if (subverticalsItem.isExternalRoute) {
    return (
      <a href={route} target="_blank" rel="noreferrer" className={linkStyles}>
        {icon && <UI.SVGCDN icon={icon} className={'mnav__link-icon'} />}
        {subverticalsItem?.name}
        {isNewRibbon && showRibbon && (
          <CustomRibbon
            style={{
              background: `${subverticalsItem?.ribbonConfig?.labelColor}`,
              color: `${subverticalsItem?.ribbonConfig?.textColor}`,
            }}
          />
        )}
      </a>
    );
  }

  return (
    <Flex>
      {route && route !== '/' ? (
        <>
          <Link to={route} className={linkStyles} onClick={handleNavClick}>
            {icon && <UI.SVGCDN icon={icon} className={'mnav__link-icon'} />}
            {subverticalsItem?.name}
            {isNewRibbon && showRibbon && (
              <CustomRibbon
                style={{
                  background: `${subverticalsItem?.ribbonConfig?.labelColor}`,
                  color: `${subverticalsItem?.ribbonConfig?.textColor}`,
                }}
              />
            )}
          </Link>
          {promoCount > 0 && <Flex.Center className="top-navbar__badge">{promoCount}</Flex.Center>}
        </>
      ) : (
        <>
          {icon && <UI.SVGCDN icon={icon} className={'mnav__link-icon'} />}
          <span className={`mnav__link`} onClick={subverticalsItem?.onClick}>
            {subverticalsItem?.name}
          </span>
        </>
      )}
    </Flex>
  );
};

export default memo(CustomSubNavLink);
