import React, { useState } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Icon } from 'modules/casino/shared/components/icons';
import { Flex } from 'modules/casino/shared/components/layout';
import { UI } from 'modules/casino/shared/components/UI';
import { useLayoutConfigType } from 'modules/casino/shared/hooks/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { StyledArrows } from './AnimatedArrows.styled';

type Props = {
  handleClick: () => void;
};

export const LoadMoreButton: React.FC<Props> = ({ handleClick }) => {
  const { t } = useTranslation();
  const buttonLabel = `${t(`Casino.General.loadMore`)}`;
  const casinoLayout = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.LAYOUTTYPECONFIG,
    deviceLayout: 'general',
  });
  const loadMoreBtnLayout = casinoLayout?.casino?.grid?.loadMoreBtn;
  const loadMoreBtnClasses = clsx(
    'btn-load-more',
    loadMoreBtnLayout === 'layout_1' && 'btn-load-more__l1',
    loadMoreBtnLayout === 'layout_2' && 'btn-load-more__l2',
  );
  const [onTap, setOnTap] = useState<boolean>(false);
  const handleOnClick = () => {
    setOnTap(true);
    handleClick();
  };

  return (
    <Flex.Center className="btn-load-more--wrapper">
      <UI.Button
        c-size={isDesktop ? '2xl' : 'xl'}
        c-variant="tertiary"
        className={loadMoreBtnClasses}
        onClickHandler={handleOnClick}
        text={buttonLabel}
      >
        {loadMoreBtnLayout === 'layout_1' ? (
          <motion.div
            className="d-flex-col"
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.2, delay: 0 }}
          >
            <StyledArrows $startAnimate={onTap}>
              <Icon className="btn-load-more__icon" icon={`chevron`} />
            </StyledArrows>
            <StyledArrows $startAnimate={onTap} $isSecond={true} onAnimationEnd={() => setOnTap(false)}>
              <Icon className="btn-load-more__icon second-arrow" icon={`chevron`} />
            </StyledArrows>
          </motion.div>
        ) : (
          ''
        )}
      </UI.Button>
    </Flex.Center>
  );
};
