import React, { memo } from 'react';
import clsx from 'clsx';
import ParseHTML from 'shared/common/ParseHTML';

/** PromoPage Wrapper */
interface WrapperProps {
  children: React.ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({ children }) => {
  return <div className="mx-auto w-100 promo-page__wrapper">{children}</div>;
};

/** PromoPage Paragraphs Wrapper */
interface ParagraphsWrapperProps {
  children: React.ReactNode;
}

const ParagraphsWrapper: React.FC<ParagraphsWrapperProps> = ({ children }) => {
  return <div className="promo-page__text-content">{children}</div>;
};

/** PromoPage Paragraph */
interface ParagraphProps {
  title: string;
  paragraphAsHtml: string;
  color: string;
  className?: string;
}

const Paragraph: React.FC<ParagraphProps> = ({ title, paragraphAsHtml, color, className }) => {
  const blockClass = clsx('promo-page__text-block', className ? className : 'promo-page__text-block--primary');

  return (
    <div className={blockClass}>
      <p className="promo-page__title" style={{ color }}>
        {title}
      </p>
      <ParseHTML html={paragraphAsHtml || ''} />
    </div>
  );
};

export const PromoPageUI = {
  Wrapper: Wrapper,
  ParagraphsWrapper: ParagraphsWrapper,
  Paragraph: memo(Paragraph),
};
