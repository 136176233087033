import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistStore } from 'redux-persist';
import { isProductionBuild } from 'utils/common/helpersCommon';
import { errorLoggerMiddleware } from './middlewares/errorLoggerMiddleware';
import { listenerMiddleware } from './middlewares/listenerMiddleware';
import rootReducer, { RootState } from './rootReducer';
import { platformApi } from './rtkq/platformCommonRTKQ.slice';

export const configureAppStore = (preloadedState?: RootState) =>
  configureStore({
    reducer: rootReducer,
    devTools: window.config?.ENV_TYPE !== 'DEV' ? window.config?.API_URL?.includes('-int') || !isProductionBuild : true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      })
        .prepend(listenerMiddleware.middleware)
        .concat(errorLoggerMiddleware, platformApi.middleware),
    preloadedState,
  });

export type StoreType = ReturnType<typeof configureAppStore>;

const store: StoreType = configureAppStore();

const persistor = persistStore(store);

setupListeners(store.dispatch); // for RTKQ

export { persistor, store };

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
