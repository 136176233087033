import React from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';
import { locationNS } from './customTabsUICSS.types';
import { classExtendByLocation } from './utils/customTabs.utils';
interface Props {
  className?: string;
  selectedClassName?: string;
  children?: React.ReactNode;
  isActive?: boolean;
  type?: 'primary' | 'secondary' | 'third';
  location?: 'promotions' | 'myAccount';
}

const TabPanel: React.FC<Props> = ({
  className,
  selectedClassName,
  children,
  isActive,
  type = 'primary',
  location = 'promotions',
}) => {
  if (!isActive) return null;

  const paneClass = clsx(
    selectedClassName && isActive && selectedClassName,
    `${locationNS}-${classExtendByLocation[location]}__panel`,
    `${locationNS}-${classExtendByLocation[location]}__panel--${type}`,
    isActive && `${locationNS}-${classExtendByLocation[location]}__panel--${type}--active`,
    className,
  );

  return (
    <Flex.Col className={paneClass} role="tabpanel">
      {children}
    </Flex.Col>
  );
};
// Dont change name -- need for tabs props
TabPanel.displayName = 'TabPanel';

export default TabPanel;
