import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { sessionBalanceThunk } from 'modules/retail/modules/ssbt/features/sessionBalance/slice/sessionBalance.slice';
import { selectVoucherData, setIsVoucherSelect } from 'modules/retail/shared/slice/voucher.slice';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { useAppDispatch, useAppSelector } from 'store';
import { SSBT } from 'utils/common/translationUtils/translationStrings';
import DepositAgainButton from './DepositAgainButton';
import CloseModalButton from '../../../components/CloseModalButton';
import { TerminalModalUI } from '../../../components/ssbtModalUI';

const LastStepDepositSSBTvoucher = ({ setActiveStep }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const depositWithVoucherStatus = useAppSelector(selectVoucherData.depositWithVoucherStatus);
  const date = new Date(Date.now());
  const { closeModal } = useHandleModals();

  const returnToFirstStep = () => {
    dispatch(setIsVoucherSelect(false));
    dispatch(setActiveStep(1));
  };

  const closeModalButton = () => {
    dispatch(setActiveStep(1));
    closeModal();
  };

  useEffect(() => {
    if (depositWithVoucherStatus === 'succeeded') {
      dispatch(sessionBalanceThunk.checkForUpdateBalance());
    }
  }, [dispatch, depositWithVoucherStatus]);

  const renderDepositStateModals = (status) => {
    switch (status) {
      case 'pending':
        return (
          <>
            <TerminalModalUI.Status status="pending" />
            <TerminalModalUI.Header text={t(SSBT.TerminalModal.requestTerminalText)} />
          </>
        );
      case 'succeeded':
        return (
          <>
            <TerminalModalUI.Status
              status="success"
              text={t(SSBT.TerminalModal.transactionTextTerminal)}
              textSecondary={date.toDateString()}
            />
            <TerminalModalUI.ActionsWrapper>
              <DepositAgainButton variant="disabled" onClick={() => returnToFirstStep()} />
              <CloseModalButton onClick={closeModalButton} />
            </TerminalModalUI.ActionsWrapper>
          </>
        );
      case 'failure':
        return (
          <>
            <TerminalModalUI.Status status="error" text={t(SSBT.TerminalModal.rejectedTransactionTerminal)} />
            <CloseModalButton depositStatus={status} onClick={closeModalButton} />
          </>
        );
    }
  };

  return (
    <TerminalModalUI.ContentWrapper>
      {renderDepositStateModals(depositWithVoucherStatus)}
    </TerminalModalUI.ContentWrapper>
  );
};
export default LastStepDepositSSBTvoucher;
