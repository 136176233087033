import clsx from 'clsx';
import AmountCurrency from 'shared/common/features/myProfile/components/AmountCurrency';
/** BetSlip Amount Line */
interface PossbileWinProps {
  className?: string;
  noWrap?: boolean;
  amountText?: string;
  amount: string | null;
}

const Amount: React.FC<PossbileWinProps> = ({ className, noWrap, amountText, amount }) => {
  const blockClass = clsx(className, noWrap && 'text-nowrap');
  return (
    <span className={blockClass}>
      {amountText && <span className="ma-text--accent">{amountText} </span>}
      <AmountCurrency currencyClassName="currency" amount={amount} />
    </span>
  );
};

export default Amount;
