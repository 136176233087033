import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Flex } from 'components/shared/layout';
import { UI } from 'components/shared/UI';

import {
  formatCustomDate,
  formatPeriod,
} from 'modules/retail/modules/ssbt/components/terminalKeyboard/helpersFuncCashier';

import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';

import { CashierModalUI } from '../../cashierModalUI/Index';

const AnexReportInfo = ({ t, anexInfo, anexInfoStatus, currency }) => {
  const componentRef = useRef(null);

  const handlePrintQRCode = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
    @media print {
      body, html {
        margin: 0;
        padding: 0;
        height: 100%;
      }

      .pageContainer {
        display: flex !important;
        justify-content: center;
        align-items: start;
        height: 100%;
        padding: 20px;
      }

      .noPrint {
        display: none;
      }

      .hiddenOnPrint {
        display: block !important;
        width: 100%;
        font-size: 14px;
        margin-bottom: 20px;
        color: black;
      }

      .hiddenOnPrint p,
      .hiddenOnPrint span {
        display: block;
        margin: 0 10px;
      }

      .qrCode {
        padding-top: 20px;
      }

      .infoTable {
        display: table !important;
        width: 100%;
        margin-bottom: 20px;
      }


      .boldCenter {
        font-weight: bold;
        text-align: center;
      }

      .leftAlign {
        text-align: left;
      }

      .borderedBox {
        border: 1px solid black;
        padding: 5px;
        margin: 10px 5px;
      }

      .borderedBox p {
        display: flex;
        justify-content: space-between;
        margin: 3px 0;
      }

      .borderedBox .right {
        margin-left: auto;
        text-align: right;
      }

      table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 10px;
      }

      th, td {
        border: 1px solid black;
        text-align: center;
      }

      th, td {
       text-align: center;
     }

     th {
       background-color: #f2f2f2;
        text-align: center;
     }

    }
  `,
  });
  const register = [
    {
      name: t(CashierTranslations.AnexReport.sport),
      totalAmount: anexInfo?.sport?.totalBetAmount,
      paidAmount: anexInfo?.sport?.paidAmount,
    },
    {
      name: t(CashierTranslations.AnexReport.live),
      totalAmount: anexInfo?.live?.totalBetAmount,
      paidAmount: anexInfo?.live?.paidAmount,
    },
    {
      name: t(CashierTranslations.AnexReport.lotto),
      totalAmount: anexInfo?.lotto?.totalBetAmount,
      paidAmount: anexInfo?.lotto?.paidAmount,
    },
    {
      name: t(CashierTranslations.AnexReport.rocketJet),
      totalAmount: anexInfo?.rocketJet?.totalBetAmount,
      paidAmount: anexInfo?.rocketJet?.paidAmount,
    },
    {
      name: t(CashierTranslations.AnexReport.joker),
      totalAmount: anexInfo?.joker?.totalBetAmount,
      paidAmount: anexInfo?.joker?.paidAmount,
    },
    {
      name: t(CashierTranslations.AnexReport.diamondX),
      totalAmount: anexInfo?.diamondX?.totalBetAmount,
      paidAmount: anexInfo?.diamondX?.paidAmount,
    },
    { name: t(CashierTranslations.AnexReport.fee), totalAmount: anexInfo?.totalTax, paidAmount: '-' },
    {
      name: t(CashierTranslations.AnexReport.coupons),
      totalAmount: anexInfo?.voucher?.totalAmount,
      paidAmount: anexInfo?.voucher?.paidAmount,
    },
    { name: t(CashierTranslations.AnexReport.terminal), totalAmount: anexInfo?.cashMoney, paidAmount: '-' },
    // { name: t('Cashier.Anex2.promotions'), totalAmount: 'total amount 6', paidAmount: 'paid amount 6' },
    {
      name: t(CashierTranslations.AnexReport.cashpayTransactions),
      totalAmount: anexInfo?.cashpayDepositsAmount,
      paidAmount: anexInfo?.cashpayWithdrawalsAmount,
    },
    {
      name: t(CashierTranslations.AnexReport.incassoAmount),
      totalAmount: anexInfo?.incassoDepositsAmount,
      paidAmount: anexInfo?.incassoWithdrawalsAmount,
    },
  ];

  const details = [
    {
      text: t(CashierTranslations.AnexReport.posAddress),
      value: anexInfo?.posAddress,
    },
    { text: t(CashierTranslations.AnexReport.participationFeeOrCommission), value: anexInfo?.participationTax },
    { text: t(CashierTranslations.AnexReport.bonusValue), value: anexInfo?.bonusAmount },
    { text: t(CashierTranslations.AnexReport.prizeValue), value: anexInfo?.prizeAmount },
    { text: t(CashierTranslations.AnexReport.specialPrizes), value: anexInfo?.specialPrizes },
    { text: t(CashierTranslations.AnexReport.earnedIncome), value: anexInfo?.earnedIncome },
    { text: t(CashierTranslations.AnexReport.department), value: '......................' },
    { text: t(CashierTranslations.AnexReport.accountant), value: '' },
  ];
  const showMessage = () => {
    if (anexInfoStatus === 'idle') {
      return `${t(CashierTranslations.FinancialModal.messageText)}`;
    } else if (anexInfoStatus === 'failure') {
      return `${t(CashierTranslations.AnexReport.noRecords)}`;
    }
    return '';
  };
  const requestedDate = formatCustomDate(anexInfo?.requestedDate, t);
  const infoMessage = anexInfoStatus === 'idle' || anexInfoStatus === 'failure';
  return (
    <>
      {infoMessage && <CashierModalUI.InfoMessage className="m-auto" icon={'icon'} infoMessage={showMessage()} />}
      {anexInfoStatus !== 'failure' && anexInfoStatus !== 'pending' && (
        <>
          <Flex.Col>
            <div className="hiddenOnPrint" style={{ display: 'none' }} ref={componentRef}>
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}
              >
                <div>
                  <p style={{ fontWeight: 'bold' }}>{'MAGNUM BETTING SRL'}</p>
                  <div>
                    <p>Sediu Social: Constanta Bd Ui MAmaia Nr 159B Birou Nr 5</p>
                    <p>Nr De Inregistrare La Registrul Comertului: J13/3521/2019</p>
                    <p>Cod Unic De Inregistrare: 41782575</p>
                    <p>Capital Social: 1000000 LEI</p>
                    <p>Licenta De Organizare Acttivitate Pariuri: L1213893F001339</p>
                  </div>
                </div>
                <div>
                  <p>{t(CashierTranslations.AnexReport.date)}:</p>
                  <p style={{ fontWeight: 'bold', fontSize: '20px' }}>{formatPeriod(anexInfo?.selectedPeriod)}</p>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
                <div>
                  <div>
                    <span>
                      {t(CashierTranslations.AnexReport.posName)}: <b>{anexInfo?.posName}</b>
                    </span>
                  </div>
                  <p>{`${t(CashierTranslations.AnexReport.posAddress)}   ${anexInfo?.posAddress || 'N/A'}`}</p>
                </div>
                <span>{`${t(CashierTranslations.AnexReport.printed)}  ${requestedDate}`}</span>
              </div>

              <div style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center' }}>
                <span>
                  {t(CashierTranslations.AnexReport.register)}({currency})
                </span>
              </div>
              <div className="infoTable">
                <table>
                  <tr style={{ textAlign: 'center' }}>
                    <th>{t(CashierTranslations.AnexReport.crt)} </th>
                    <th>{t(CashierTranslations.AnexReport.explanation)} </th>
                    <th>{t(CashierTranslations.AnexReport.collection)} </th>
                    <th>{t(CashierTranslations.AnexReport.payment)} </th>
                  </tr>

                  <tbody>
                    <tr>
                      <td colSpan={2}>{t(CashierTranslations.AnexReport.balanceFromPrevDay)}</td>
                      <td colSpan={2} style={{ paddingLeft: '10px', fontWeight: 'bold' }}>
                        {anexInfo?.totalBalanceFromPreviousDay || 'N/A'}
                      </td>
                    </tr>
                    {register.map((game, index) => (
                      <tr key={index}>
                        <td>{index + 1}.</td>
                        <td>{game.name}</td>
                        <td className="">{game.totalAmount}</td>
                        <td className="">{game.paidAmount}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={2}>{t(CashierTranslations.AnexReport.payments)}</td>
                      <td className="">{anexInfo?.totalInAmount}</td>
                      <td className="">{anexInfo?.totalPayAmount}</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <strong>{t(CashierTranslations.AnexReport.balanceTotal)}</strong>
                      </td>
                      <td colSpan={2} className="">
                        <strong>{anexInfo?.totalBalance}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}
              >
                <div> {`${t(CashierTranslations.AnexReport.totalZ)}: ${anexInfo?.totalBetWithFee}`}</div>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <div style={{ paddingRight: '5px' }}> {`${t(CashierTranslations.AnexReport.cashier)}:`}</div>
                  <div style={{ fontWeight: 'bold' }}> {`${anexInfo?.cashierUsername} ................`}</div>
                </div>
              </div>
              <div className="boldCenter">{t(CashierTranslations.AnexReport.statement)}</div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="leftAlign">{t(CashierTranslations.AnexReport.DMY)} </div>
                <div className="leftAlign">{formatPeriod(anexInfo?.selectedPeriod)}</div>
              </div>
              <div className="borderedBox">
                <p>Sediu Social: Constanta Bd Ui MAmaia Nr 159B Birou Nr 5</p>
                <p>Nr De Inregistrare La Registrul Comertului: J13/3521/2019</p>
                <p>Cod Unic De Inregistrare: 41782575</p>
                <p>Capital Social: 1000000 LEI</p>
                <p>Licenta De Organizare Acttivitate Pariuri: L1213893F001339</p>
              </div>
              <div className="borderedBox">
                {details.map((detail, index) => (
                  <p key={index}>
                    <span>{detail.text}</span>
                    <span className="right">{detail.value}</span>
                  </p>
                ))}
              </div>
            </div>
          </Flex.Col>
          {anexInfoStatus === 'succeeded' && (
            <UI.Button
              size="2xl"
              isDisabled={false}
              className="mt-4 mx-auto"
              text={t(CashierTranslations.FinancialModal.printReport)}
              onClick={handlePrintQRCode}
            />
          )}
        </>
      )}
    </>
  );
};

export default AnexReportInfo;
