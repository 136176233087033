import React, { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'i18n';
import { AppDispatch } from 'store';
import { CreateThunk } from 'store/thunkCreators';

export enum SocialTypes {
  APPLE = 'apple',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
}
export enum SocialButtonInputTypes {
  UI_BUTTON = 'ui-button',
  ICON = 'icon',
  TEXT = 'text',
}
export enum SocialButtonAuthTypes {
  LOGIN = 'login',
  REGISTER = 'register',
  LINK = 'link',
  UNLINK = 'unlink',
}
export enum SocialModal {
  TYPE = 'SOCIAL_MEDIA_LINK_MODAL',
  ID = 'SOCIAL_MEDIA_LINK_MODAL_ID',
  SUCCESS = 'SUCCESS',
  CONFIRM = 'CONFIRM',
  ERROR = 'ERROR',
}

export declare module Social {
  export type Form = {
    button: Social.Button;
    className?: string;
    disabled?: boolean;
  };
  export type Config = {
    src: string;
    async?: boolean;
    defer?: boolean;
    crossOrigin?: string;
  };

  export type Button = {
    type: SocialTypes;
    text?: string;
    label?: string;
    authType?: SocialButtonAuthTypes;
    inputType?: SocialButtonInputTypes;
    clientId?: string;
    appVersion?: string;
    redirectUrl?: string;
    isLinked?: boolean;
  };

  export type OnSocialAuth = ({ username, password, userData }: Social.AuthProps) => void;

  export type Effect = ({
    onSocialAuth,
    authType,
    clientId,
    appVersion,
    redirectUrl,
  }: {
    onSocialAuth?: Social.OnSocialAuth;
    authType?: SocialButtonAuthTypes;
    clientId?: string;
    appVersion?: string;
    redirectUrl?: string;
  }) => void;

  export type ElementProps = {
    className: string;
    button: Social.Button;
    isDisabled?: boolean;
    onClick: () => void;
    onSocialAuth: Social.OnSocialAuth;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    t: TFunction;
  };

  export type Element = ({
    className,
    button,
    isDisabled,
    onClick,
    onSocialAuth,
    setIsLoading,
    t,
  }: ElementProps) => React.ReactElement;

  export type UserData = {
    userId?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
  };

  export type AuthProps = {
    username?: string;
    password: string;
    userData?: Social.UserData;
  };

  export type AuthUse = { social: SocialTypes; authType?: SocialButtonAuthTypes };

  export type AuthAction = Social.AuthProps & {
    dispatch: AppDispatch;
    social: SocialTypes;
    activeStep?: number;
  };

  type SliceTypes = {
    data: Social.Button[] | null;
    error: string | null;
    isLoading: boolean;
  };

  type Thunks = {
    linkAccount: CreateThunk<Social.FetchParamsLink, string, Social.RejectValue>;
    unlinkAccount: CreateThunk<Social.FetchParamsUnlink, string, Social.RejectValue>;
  };

  type FetchParamsLink = {
    name: string;
    accessToken: string;
    email?: string;
    userId?: string;
  };

  type FetchParamsUnlink = {
    name: SocialTypes;
  };

  type RejectValue = { rejectValue: boolean | undefined };

  type Modal = {
    modal: SocialModal.SUCCESS | SocialModal.CONFIRM | SocialModal.ERROR;
    type: SocialTypes;
    name?: string;
    message?: string;
    onSubmit?: () => void;
    onCancel?: () => void;
  };
}
