import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useAppHomePage from 'hooks/common/useAppHomePage';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from '../features/general/types/generalSlice.types';

//This hook checks if we have current location in layoutConfig inactiveRoutes and redirects to home page

const useHandleInactiveRoutes = (pathname: string): void => {
  const navigate = useNavigate();
  const verticalsConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.VERTICALS,
    deviceLayout: 'general',
  });
  const inactiveSubRoutes = useMemo(() => verticalsConfig?.inactiveSubRoutes, [verticalsConfig]);
  const { homeRoute } = useAppHomePage();

  const hideRoute = inactiveSubRoutes?.some((route) => {
    return pathname.includes(route);
  });

  useEffect(() => {
    if (hideRoute) {
      //added timeout to trigger navigate after verticals custom navigation
      const timeout = setTimeout(() => {
        navigate(homeRoute);
      });
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [navigate, homeRoute, hideRoute]);
};

export default useHandleInactiveRoutes;
