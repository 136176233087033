import React, { MutableRefObject, memo, useCallback } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Flex } from 'components/shared/layout';
import { selectAccountTabs, setAccountActiveNavTab } from 'pages/myAccount/slice/mpdAccountTabs.slice';
import { General } from 'shared/common/features/general/types/generalSlice.types';
import { useAppDispatch, useAppSelector } from 'store';
import { ClubTab } from './captainUpTab';
import NavigationTabs from './NavigationTabs';
import Notifications from './notificationsTab/Notifications';
import { OffersTab } from './offersTab';

type TabsType = 'OffersTab' | 'NotificationsTab' | 'CaptainUp';

type ComponentsProps = {
  onBackClick: () => void;
  parentRef?: MutableRefObject<HTMLDivElement | null>;
  subMenu?: General.MyProfileRoutes[] | null;
};

const ComponentTabsMap: Record<TabsType, React.FC<ComponentsProps>> = {
  OffersTab({ onBackClick }) {
    return <OffersTab onBackClick={onBackClick} />;
  },
  NotificationsTab({ onBackClick, parentRef }) {
    return <Notifications parentRef={parentRef} onBackClick={onBackClick} />;
  },
  CaptainUp({ onBackClick, parentRef, subMenu }) {
    const items = subMenu?.filter((item) => item.key === 'captainUp')[0];
    return items ? <ClubTab parentRef={parentRef} subMenu={items.subMenu} onBackClick={onBackClick} /> : null;
  },
};

type Props = {
  subMenu?: General.MyProfileRoutes[] | null;
  parentRef?: MutableRefObject<HTMLDivElement | null>;
};

const NavigationTabsContainer: React.FC<Props> = ({ subMenu, parentRef }) => {
  const activeTab = useAppSelector(selectAccountTabs.accountActiveNavTab);
  const dispatch = useAppDispatch();

  const onBackClick = useCallback(() => dispatch(setAccountActiveNavTab('')), [dispatch]);

  const Component = ComponentTabsMap[activeTab];

  return (
    <Flex.ASTART className="overflow-hidden">
      <AnimatePresence>
        {!activeTab ? (
          <NavigationTabs parentRef={parentRef} subMenu={subMenu} />
        ) : (
          <div className="w-100">
            {Component ? <Component parentRef={parentRef} subMenu={subMenu} onBackClick={onBackClick} /> : null}
          </div>
        )}
      </AnimatePresence>
    </Flex.ASTART>
  );
};

export default memo(NavigationTabsContainer);
