import { useCallback, useRef } from 'react';
import { useImage } from '../../hooks/useImage';

interface Props {
  srcList: string[];
  onLoad: () => void;
  uniqueId: string;
  skipLazyLoad: boolean;
  className?: string;
  withLiveImage?: boolean;
  width?: number;
  height?: number;
  alt?: string;
}

const collections = {};
const Image: React.FC<Props> = ({
  srcList,
  onLoad,
  uniqueId,
  skipLazyLoad,
  className,
  withLiveImage,
  width,
  height,
  alt = '',
}) => {
  const isLoaded = useRef<boolean>(false);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const cachedSrc = collections[uniqueId];
  const { handleError } = useImage({ imgRef, srcList, withLiveImage, skipLazyLoad, cachedSrc });

  const onLoadImage = useCallback(() => {
    if (withLiveImage) {
      collections[uniqueId] = imgRef?.current?.src;
    }

    if (isLoaded.current) {
      return;
    }
    isLoaded.current = true;
    onLoad();
  }, [onLoad]);

  return (
    <img
      ref={imgRef}
      alt={alt}
      width={width}
      height={height}
      className={className}
      onLoad={onLoadImage}
      onError={handleError}
    />
  );
};

export default Image;
