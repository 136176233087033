import { isProductionBuild } from 'utils/common/helpersCommon';

type Params = {
  document: Document;
  src: string;
  callback: () => void;
  attr: string;
  id: string;
};

export const casinoScriptInit = ({ document, src, callback, attr, id }: Params) => {
  const h = document.getElementsByTagName('head')[0];
  const s = document.createElement('script');
  s.onload = () => callback();
  s.src = src;
  if (!isProductionBuild && !src.includes('cdn')) {
    s.type = 'module';
  }
  s.id = id;
  s.setAttribute('id', id);
  s.setAttribute('n', attr);
  h.appendChild(s);
};
export const customCasinoNavigate = ({ path, options, navigate }) => {
  if (!options?.replaceCurrentHistory) {
    window.history.pushState({}, '', path);
  }

  if (!options?.noRerender) {
    navigate(path, {
      ...options, //state between routes is here
      ...{
        replace: true,
      },
    });
  }
};

export const getReservedAreaInGameLaunch = () => {
  return {
    mobile: { topMargin: 0, leftMargin: 0, rightMargin: 0, bottomMargin: 50 },
    desktop: { topMargin: 0, leftMargin: 98, rightMargin: 0, bottomMargin: 0 },
  };
};
