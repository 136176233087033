import React, { memo } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import LoginPageHeader from 'pages/playtechPoker/components/LoginPageHeader';
import { playtechPokerSelectors } from 'pages/playtechPoker/slice/playtechPokerLogin.slice';
import { svaraAppSelectors } from 'pages/svaraAppLogin/slice/svaraAppLogin.slice';
import { selectAppHeader } from 'shared/common/features/appHeader/components/appHeaderContainer/slice/appHeader.slice';
import { RetailHeader } from 'shared/common/features/appHeader/retailHeader';
import { useAppSelector } from 'store';
import { isCashier, isTerminal } from 'utils/common/helpersCommon';
import { GAME_LAUNCH_PREFIX_PLATFORM } from 'utils/config';
import AppHeader from './AppHeader';
import AppHelpHeader from './AppHelpHeader';

const AppHeaderLayout: React.FC = () => {
  const isAppHeaderVisible = useAppSelector(selectAppHeader.isVisible);
  const location = useLocation();
  const openFromPoker = useAppSelector(playtechPokerSelectors.openFromPoker);
  const openFromSvara = useAppSelector(svaraAppSelectors.openFromSvara);
  const openLeaderboard = useAppSelector(svaraAppSelectors.openLeaderboard);
  const isLiveProgram = useMatch('sports/live-program');

  const renderAppHeader = () => {
    if (
      location.pathname?.includes('sitemap') ||
      location.pathname?.includes(GAME_LAUNCH_PREFIX_PLATFORM) ||
      openLeaderboard
    ) {
      return null;
    }

    if (isTerminal() || isCashier() || isLiveProgram) {
      return <RetailHeader isLiveProgram={isLiveProgram} />;
    }

    if (location.pathname?.includes('/playtech-poker') || openFromPoker || openFromSvara) {
      return <LoginPageHeader />;
    }

    if (isAppHeaderVisible) {
      return location?.pathname?.includes('/help') ? <AppHelpHeader /> : <AppHeader />;
    }

    return null;
  };

  return renderAppHeader();
};

export default memo(AppHeaderLayout);
