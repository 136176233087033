import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { createAbortThunk, CreateThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';

type RequestBetData = { sport: string; description: string };
export type RequestBetState = { sport: string; description: string; type: string; icon: number };

export const postRequestBetForm: CreateThunk<RequestBetData, void, string> = createAbortThunk(
  'requestBet/requestBetPost',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<RequestBetData>(
        `${config.API_URL}/api/ews-notification-service/player/player-communications/request-custom-bet`,
        data,
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseDataMessage(err));
    }
  },
);

const initialState: {
  status: 'idle' | 'pending' | 'failure' | 'succeeded';
  error: string;
} = {
  status: 'idle',
  error: '',
};

const requestBet = createSlice({
  name: 'requestBet',
  initialState,
  reducers: {
    resetRequestBetForm: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(postRequestBetForm.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(postRequestBetForm.rejected, (state, action) => {
      state.status = 'failure';
      state.error = action.payload || '';
    });
    builder.addCase(postRequestBetForm.fulfilled, (state) => {
      state.status = 'succeeded';
      if (state.error) {
        state.error = '';
      }
    });
  },
});

export const { resetRequestBetForm } = requestBet.actions;

export default requestBet.reducer;

export const selectRequestBet = {
  isSubmited: (state: RootState) => state.promotion.requestBet.status === 'succeeded',
  status: (state: RootState) => state.promotion.requestBet.status,
  error: (state: RootState) => state.promotion.requestBet.error,
};
