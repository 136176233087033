import React from 'react';

interface Props {
  classNames?: string;
}

const IconLoader: React.FC<Props> = ({ classNames }) => {
  return (
    <div className={`icon-loader ${classNames ? classNames : ''}`}>
      <div className="icon-loader__wrapper">
        <svg className="icon-loader__circle" viewBox="25 25 50 50">
          <circle className="icon-loader__path" cx="50" cy="50" r="20" fill="none" stroke="#fff" strokeWidth="5" />
        </svg>
      </div>
    </div>
  );
};

export default IconLoader;
