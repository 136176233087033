import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UI } from 'components/shared/UI';
import { useAppDispatch, useAppSelector } from 'store';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import { postRequestBetForm, selectRequestBet } from '../slice/requestBet.slice';

type Props = {
  sport: string;
  description: string;
  type: string;
  maxLength?: number;
  minLenght?: number;
  minLengthError: boolean;
  setMinLenghtError: (boolean: boolean) => void;
};

const RequestBetBtn: React.FC<Props> = ({
  setMinLenghtError,
  sport,
  description,
  type,
  maxLength = 650,
  minLenght = 10,
  minLengthError,
}) => {
  const status = useAppSelector(selectRequestBet.status);
  const error = useAppSelector(selectRequestBet.error);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleSubmitForm = useCallback(() => {
    if (description.trim().length < minLenght) {
      setMinLenghtError(true);
      return;
    } else if (sport && description.trim()) {
      setMinLenghtError(false);
      const newSport = `${type.includes('noGroup') ? '' : t(`Promotions.RequestBet.${type}`)} ${t(sport)}`;
      dispatch(postRequestBetForm({ sport: newSport, description: description.trim() }));
    }
  }, [setMinLenghtError, dispatch, sport, type, description, minLenght, t]);

  const disabled =
    !!error ||
    !sport ||
    !description.trim() ||
    description.trim().length > maxLength ||
    minLengthError ||
    status === 'pending' ||
    status === 'failure';

  return (
    <UI.Button
      className="w-100"
      size="lg"
      onClick={handleSubmitForm}
      isDisabled={disabled}
      text={t(Promotions.RequestBet.requestBetBtn)}
    />
  );
};

export default memo(RequestBetBtn);
