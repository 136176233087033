import React, { memo } from 'react';
import { selectNavigationMenu } from 'shared/common/features/navigationMenu/slice/navigationMenu.slice';
import { useAppSelector } from 'store';
import CasinoDrawerSectionLayout02 from './CasinoDrawerSectionLayout02';
import { selectDrawer } from '../../slice/drawer.slice';
import { DrawerUI } from '../DrawerUI';

interface Props {
  isActive: boolean;
}

const CasinoDrawerSection: React.FC<Props> = ({ isActive }) => {
  const menuItems = useAppSelector(selectNavigationMenu.menuItemsLayout02);
  const casinoDrawerType = useAppSelector(selectDrawer.casinoDrawerType);
  const casinoItems = menuItems?.filter((item) => item?.sectionType === casinoDrawerType);

  return (
    <DrawerUI.Section type="casino" isActive={isActive}>
      {casinoItems?.map((vertical, idx) => {
        return <CasinoDrawerSectionLayout02 section={vertical} key={idx} />;
      })}
    </DrawerUI.Section>
  );
};

export default memo(CasinoDrawerSection);
