import React from 'react';
import clsx from 'clsx';

/** Divider */
interface DividerProps {
  className?: string;
}
const Divider: React.FC<DividerProps> = ({ className }) => {
  const wrapperClass = clsx('egtd-x-check-ticket-search__divider', className);

  return <div className={wrapperClass} />;
};

export default Divider;
