export enum TagType {
  JACKPOT = 'JACKPOT',
  LABEL = 'LABEL',
  LOGO = 'LOGO',
  BOTTOM_LABEL = 'BOTTOM_LABEL',
  BOTTOM_TEXT = 'BOTTOM_TEXT',
  BOTTOM_TRANSPARENT = 'BOTTOM_TRANSPARENT',
  BOTTOM_TRANSPARENT_TEXT = 'BOTTOM_TRANSPARENT_TEXT',
  LABEL_RIGHT = 'LABEL_RIGHT',
}

export const BOTTOM_TAGS = [
  TagType.BOTTOM_LABEL,
  TagType.BOTTOM_TEXT,
  TagType.BOTTOM_TRANSPARENT,
  TagType.BOTTOM_TRANSPARENT_TEXT,
];

export const TAG_STYLE_CONFIG: Record<TagType, { hideTriangle: boolean; hideBg: boolean }> = {
  [TagType.JACKPOT]: { hideTriangle: false, hideBg: false },
  [TagType.LABEL]: { hideTriangle: false, hideBg: false },
  [TagType.LOGO]: { hideTriangle: false, hideBg: false },
  [TagType.BOTTOM_LABEL]: { hideTriangle: true, hideBg: false },
  [TagType.BOTTOM_TEXT]: { hideTriangle: true, hideBg: false },
  [TagType.BOTTOM_TRANSPARENT]: { hideTriangle: true, hideBg: true },
  [TagType.BOTTOM_TRANSPARENT_TEXT]: { hideTriangle: true, hideBg: true },
  [TagType.LABEL_RIGHT]: { hideTriangle: true, hideBg: false },
};
