import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/shared/Modal';
import { ModalUI } from 'components/shared/ModalUI';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import { RequestBet } from './RequestBet';
import { resetRequestBetForm } from './slice/requestBet.slice';
import { LayoutConfigTypes } from '../general/types/generalSlice.types';
import useHandleModals from '../rootModal/hooks/useHandleModals';

const RequestBetModal: React.FC = () => {
  const isAuthenticsted = useAppSelector(selectAuthentication.isAuthenticated);

  const { closeModal } = useHandleModals();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(resetRequestBetForm());
    closeModal();
  };

  const requestBetConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.REQUEST_BET,
    deviceLayout: 'general',
  });

  if (!requestBetConfig?.isActive || !isAuthenticsted) return null;

  return (
    <Modal visible={true} dialogClassName="modal-rb">
      <Modal.Body padding="lg">
        <Modal.Header_2>
          <ModalUI.Title text={t(Promotions.RequestBet.requestBetTitle)} />
          <Modal.Close closeText="" closeAction={handleCloseModal} />
        </Modal.Header_2>
        <RequestBet requestBetConfig={requestBetConfig} closeModal={handleCloseModal} />
      </Modal.Body>
    </Modal>
  );
};

export default RequestBetModal;
