import React, { Fragment, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'components/shared/dropdown/Dropdown';
import DropdownGroupHeader from 'components/shared/dropdown/DropdownGroupHeader';
import DropdownItem from 'components/shared/dropdown/DropdownItem';
import { useAppDispatch, useAppSelector } from 'store';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import { cssGlobalNS } from 'utils/common/types/css.types';
import { SCRequestBetSelectTitle } from './styled';
import { General } from '../../general/types/generalSlice.types';
import { resetRequestBetForm, selectRequestBet, RequestBetState } from '../slice/requestBet.slice';

type Props = {
  onChange: (next: Partial<RequestBetState>) => void;
  dropdownImens: General.RequestBet['menuItems'];
  sport: string;
  icon: number;
  type: string;
};

const RequestBetSelectSport: React.FC<Props> = ({ onChange, dropdownImens, sport, icon, type }) => {
  const isSubmited = useAppSelector(selectRequestBet.isSubmited);
  const error = useAppSelector(selectRequestBet.error);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleOnClick = useCallback(() => {
    if (isSubmited || error) {
      dispatch(resetRequestBetForm());
    }
  }, [isSubmited, error, dispatch]);

  const handleOnChange = useCallback((value: Partial<RequestBetState>) => onChange(value), [onChange]);

  return (
    <div className={`${cssGlobalNS}-rb__select`}>
      <SCRequestBetSelectTitle className={`${cssGlobalNS}-rb__select-title`}>
        {t(Promotions.RequestBet.selectCategory)}
      </SCRequestBetSelectTitle>
      {Object.keys(dropdownImens)?.length > 1 && (
        <Dropdown
          onDropdownClick={handleOnClick}
          title={sport ? t(sport) : t(Promotions.RequestBet.selectCategory)}
          className="w-100"
          isGrouped
          sportIcon={icon}
        >
          {Object.keys(dropdownImens).map((group) => (
            <Fragment key={group}>
              {!group.includes('noGroup') && (
                <DropdownGroupHeader key={group} title={t(`Promotions.RequestBet.${group}`)} />
              )}
              {dropdownImens[group].map((curr: General.BetMenuItem, index: number) => (
                <DropdownItem
                  handleChangeDropdown={(value) => handleOnChange({ type: group, ...dropdownImens[group][value - 1] })}
                  isActive={curr.sport === sport && group === type}
                  key={curr.sport + index + group}
                  title={t(curr.sport)}
                  value={index + 1}
                  sportIcon={curr.icon ? `${curr.icon}` : ''}
                />
              ))}
            </Fragment>
          ))}
        </Dropdown>
      )}
    </div>
  );
};

export default memo(RequestBetSelectSport);
