import React from 'react';
import clsx from 'clsx';
import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import cell03StyleLoader from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell03/Cell03StyleLoader';
import { LayoutTypes } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/LayoutTypes';
import {
  ComponentType,
  StyledNames,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/layoutWrapperConstants';
import { SharedComponents } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared';
import { useDefaultWrapper } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/hooks/useDefaultWrapper';

const GameCellWrapper: React.FC<LayoutTypes.GameCellWrapperProps> = ({ gameUniqueId, categoryId }) => {
  const game = useGameProps({ gameUniqueId, categoryId });
  const { bottomTag, hasTranspBottomTag } = useDefaultWrapper({ game });
  const actionWrapperStyles = clsx([
    'casino-game-cell',
    bottomTag && 'casino-game-cell--bottom-tag',
    hasTranspBottomTag && 'casino-game-cell--bottom-tag-transparent',
  ]);
  const GameCellWrapperStyled = cell03StyleLoader(StyledNames.GameCellWrapperStyled, ComponentType.default);
  return (
    <GameCellWrapperStyled className={actionWrapperStyles} data-qid="casino-game-cell">
      <SharedComponents.GameCellTags gameUniqueId={gameUniqueId} categoryId={categoryId} />
    </GameCellWrapperStyled>
  );
};

export default GameCellWrapper;
GameCellWrapper.displayName = 'GameCellWrapper';
