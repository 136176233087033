import { memo } from 'react';

import { IsNew } from './isNew';
import NavGrid from './NavGrid';
import NavIcon from './NavIcon';
import NavItem from './NavItem';
import NavItemInRow from './NavItemInRow';
import NavItemRow from './NavItemRow';
import Section from './Section';
import Text from './Text';

export const MPDUI = {
  NavGrid: NavGrid,
  NavIcon: NavIcon,
  NavItem: memo(NavItem),
  NavItemInRow: memo(NavItemInRow),
  NavItemRow: NavItemRow,
  Section: memo(Section),
  IsNew: memo(IsNew),
  Text: Text,
};
