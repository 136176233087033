import { Flex } from 'components/shared/layout';
import { MainNavbarButtonsCompact } from '../../../components/MainNavbarButtonsCompact';

type Props = {
  folder?: string;
  text?: string;
  onClick: () => void;
  isAuthenticated: boolean;
  icon?: string;
  iconMyProfile?: string;
};
export const MainNavbarButtonsDefaultMyProfile: React.FC<Props> = ({
  text,
  onClick,
  isAuthenticated,
  icon,
  iconMyProfile,
}) => {
  return (
    <Flex.Center className="mpd-stat-item mpd-stat-item__static-link cursor-pointer" onClick={onClick}>
      <div>{text && text}</div>
      <MainNavbarButtonsCompact
        disableBadge
        sx
        isAuthenticated={isAuthenticated}
        isMyProfile
        icon={icon}
        iconMyProfile={iconMyProfile}
      />
    </Flex.Center>
  );
};
