import { ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  children: ReactNode;
  bindTo: HTMLElement | null;
};

const TopBarPortal: React.FC<Props> = ({ children, bindTo }) => {
  // Create a reference for the container div element of the portal
  const portalContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!bindTo) return;

    const container = document.createElement('div');
    portalContainerRef.current = container;
    bindTo.appendChild(container);

    // Cleanup function to remove the container element
    return () => {
      if (bindTo && container) {
        bindTo.removeChild(container);
      }
    };
  }, [bindTo]);

  // Render the portal into the created container
  return portalContainerRef.current ? createPortal(children, portalContainerRef.current) : null;
};

export default TopBarPortal;
