import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';

export const useAutoScrollToDepositButton = (animationComplete: boolean, isPage?: boolean): void => {
  const isLandscapeQuery = useMediaQuery({ query: '(orientation: landscape)' });
  useEffect(() => {
    if (animationComplete) {
      if (isPage) {
        isMobile && isLandscapeQuery && window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
      } else {
        const wrapper = document.getElementsByClassName('gmf-modal__wrapper')[0];

        if (wrapper) {
          wrapper.scrollHeight != wrapper.clientHeight &&
            wrapper.scrollTo({ top: wrapper.scrollHeight, behavior: 'smooth' });
        }
      }
    }
  }, [animationComplete]);
};
