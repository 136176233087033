import { capitalizeFirstLetter } from 'utils/common/helpersCommon';
import { PlayerRestrictions } from '../types/responsibleGambling.types';

export const formatRestrictionName = (route?: string): PlayerRestrictions.ConfigurationKey =>
  route
    ?.split('-')
    ?.map((r: string, i: number) => (i === 0 ? r : capitalizeFirstLetter(r)))
    ?.join('') as PlayerRestrictions.ConfigurationKey;

export const formatTimer = (time: number): string => {
  if (time > 9) {
    return `${time}`;
  }
  return `0${time > 0 ? time : 0}`;
};

export const getTextForTranslation = (verticalName: string) => {
  const modifiedName = verticalName?.charAt(0)?.toLowerCase() + verticalName?.slice(1);
  return `${modifiedName}Text`;
};
