import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'modules/casino/shared/components/layout';
import { SearchMessage } from 'modules/casino/shared/components/searchMessage';
import { MessageVariant } from 'modules/casino/shared/components/searchMessage/SearchMessageConstants';

interface Props {
  handleBackBtnClick: () => void;
}

const NoGamesMessage: React.FC<Props> = ({ handleBackBtnClick }) => {
  const { t } = useTranslation();

  return (
    <Flex.ColAC>
      <SearchMessage variant={MessageVariant.exclamation} message={t(`Casino.CasinoCategory.noGames`)} />
      <button className="game-launch-overlay__back-btn" onClick={handleBackBtnClick}>
        {t(`Casino.CasinoNavigation.goBack`)}
      </button>
    </Flex.ColAC>
  );
};

export default memo(NoGamesMessage);
