import { useNavigate } from 'react-router-dom';
import { Flex } from 'components/shared/layout';
import { useAuthentication } from 'hooks/common/useAuthentication';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch } from 'store';
import { ClaimTicketTranslations, MyBetsTranslation } from 'utils/common/translationUtils/translationStrings';

const ClaimTicketNoAuthView = ({ t }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showLoginForm } = useAuthentication();

  const handleClickLink = (type: string) => {
    if (type === 'logIn') {
      showLoginForm();
    } else {
      navigate('/registration');
      dispatch(hideRootModal());
    }
  };

  return (
    <Flex className={'flex-wrap justify-content-center egtd-s-px-2 egtd-s-py-3 no-bets-msg'}>
      {t(MyBetsTranslation.Messages.pleaseText)}&nbsp;
      <span className="auth-msg__link" onClick={() => handleClickLink('logIn')}>
        {t(MyBetsTranslation.Messages.loginMyBetsText)}&nbsp;
      </span>
      {t(MyBetsTranslation.Messages.orText)}&nbsp;
      <span className="auth-msg__link" onClick={() => handleClickLink('signUp')}>
        {t(MyBetsTranslation.Messages.registerMyBetsText)},
      </span>
      &nbsp;
      {t(ClaimTicketTranslations.Messages.claimMsgText)}
    </Flex>
  );
};

export default ClaimTicketNoAuthView;
