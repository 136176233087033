import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getCookie, getError } from 'utils/common/helpersCommon';
import config from 'utils/config';

type FBCookieType = {
  fbp?: string;
  fbc?: string;
};

const getFBCookieValues = (): FBCookieType => {
  const fbpCookieValue = getCookie('_fbp');
  const fbcCookieValue = getCookie('_fbc');

  return {
    ...(fbpCookieValue && { fbp: fbpCookieValue }),
    ...(fbcCookieValue && { fbc: fbcCookieValue }),
  };
};

type CapiEventResponse = {
  status: 'success' | 'fail' | 'error';
};

interface FBEventProps {
  eventSourceUrl: string;
}

export const submitFBPageView = createAbortThunk<CapiEventResponse, FBEventProps>(
  'fb/pageViewTracking',
  async ({ eventSourceUrl }, { rejectWithValue }) => {
    const fbCookies = getFBCookieValues();
    const submitData = {
      eventSourceUrl: eventSourceUrl,
      workingBusinessUnit: window.config.BUSINESS_UNIT,
      ...(fbCookies && fbCookies),
    };

    try {
      const url = `${config.FB_TRACKING_SERVICE_URL}/api/capi/public/page-view`;
      const response = await axiosInstance.post(url, submitData);

      return response.data;
    } catch (err) {
      return rejectWithValue(getError.message(err));
    }
  },
);

type FbUserData = {
  email?: string;
  phone_number?: string;
  address: {
    first_name?: string;
    last_name?: string;
    city?: string;
    country?: string;
  };
  street?: string;
};

interface StartGameProps {
  contentName: string;
  contentType: string;
  contentIds: string | number;
  contentCategory?: string;
  fbp?: string;
  fbc?: string;
  apiUrl: string;
  fBUserData?: FbUserData;
  device: string;
}

export const fbEventFreeStartGame = createAbortThunk<CapiEventResponse, StartGameProps>(
  'fb/freeGameTracking',
  async ({ contentName, contentType, contentIds, apiUrl, device }, { rejectWithValue }) => {
    const fbCookies = getFBCookieValues();
    const submitData = {
      workingBusinessUnit: window.config.BUSINESS_UNIT,
      eventSourceUrl: window.location.href,
      contentCategory: 'Games',
      eventName: 'DemoGameStart',
      contentName,
      contentType,
      contentIds,
      ...(fbCookies && fbCookies),
      device,
    };

    if (config.FB_TRACKING_SERVICE_URL && apiUrl) {
      try {
        const url = `${config.FB_TRACKING_SERVICE_URL}${apiUrl}`;
        const response = await axiosInstance.post(url, submitData);

        return response.data;
      } catch (err) {
        return rejectWithValue(getError.message(err));
      }
    }
  },
);

export const fbEventPaidStartGame = createAbortThunk<CapiEventResponse, StartGameProps>(
  'fb/paidGameTracking',
  async ({ contentName, contentType, contentIds, apiUrl, fBUserData, device }, { rejectWithValue }) => {
    const fbCookies = getFBCookieValues();
    const submitData = {
      eventName: 'PaidGameStart',
      contentCategory: 'Games',
      workingBusinessUnit: window.config.BUSINESS_UNIT,
      eventSourceUrl: window.location.href,
      contentName,
      contentType,
      contentIds,
      ...(fbCookies && fbCookies),
      ...fBUserData,
      device,
    };

    if (config.FB_TRACKING_SERVICE_URL && apiUrl) {
      try {
        const url = `${config.FB_TRACKING_SERVICE_URL}${apiUrl}`;
        const response = await axiosInstance.post(url, submitData);

        return response.data;
      } catch (err) {
        return rejectWithValue(getError.message(err));
      }
    }
  },
);

interface FBPlaceBetProps {
  currency: string;
  value: string;
  eventId: string;
  // eventTime?: string;
  fbp?: string;
  fbc?: string;
  apiUrl: string;
}

export const fbPlaceBet = createAbortThunk<CapiEventResponse, FBPlaceBetProps>(
  'fb/userTracking',
  async ({ currency, value, eventId, apiUrl }, { rejectWithValue }) => {
    const fbCookies = getFBCookieValues();
    const submitData = {
      workingBusinessUnit: window.config.BUSINESS_UNIT,
      eventSourceUrl: window.location.href,
      eventName: 'PlaceBet',
      contentCategory: 'betting',
      contentName: 'Sport',
      currency: currency.toUpperCase(),
      value,
      eventId,
      ...(fbCookies && fbCookies),
    };

    if (config.FB_TRACKING_SERVICE_URL && apiUrl) {
      try {
        const url = `${config.FB_TRACKING_SERVICE_URL}${apiUrl}`;
        const response = await axiosInstance.post(url, submitData);

        return response.data;
      } catch (err) {
        return rejectWithValue(getError.message(err));
      }
    }
  },
);
