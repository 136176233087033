const defaultProps = {
  slidesToShow: 1,
  direction: 'vertical',
  spaceBetween: 2,
  animationDirection: 'left',
  autoplaySpeed: 3000,
  animationSpeed: 1000,
  itemDimension: 100,
  easing: 'linear',
};

export default defaultProps;
