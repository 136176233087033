import styled from 'styled-components';
import { Breakpoints } from 'modules/casino/theme/Theme';

export const TotalPlayersStyled = styled.div.attrs({
  className: 'live-cell__meta-users d-flex-ac-je',
})`
  margin-left: 10px;
  height: 20px;
  @media screen and (min-width: ${Breakpoints.isMobile}px) {
    height: 24px;
  }
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    height: 27px;
    margin-left: 15px;
  }
  .live-cell__meta-users-icon {
    margin-right: 4px;
    width: 12px;
    height: 12px;
    @media screen and (min-width: ${Breakpoints.isMobile}px) {
      margin-right: 8px;
      width: 16px;
      height: 16px;
    }
    @media screen and (min-width: ${Breakpoints.isDesktop}px) {
      margin-right: 10px;
    }
  }
`;
TotalPlayersStyled.displayName = 'TotalPlayersStyled';
