import styled, { css } from 'styled-components';
import { Breakpoints } from 'theme/Theme';
import { FavouritesTypes } from '../types/favourites.types';

const iconStyleType1 = css`
  background: none !important;
`;
const iconStyleType2 = css`
  border-radius: 6px;
  padding: 4px;
`;

const defaultIconStyle = {
  layout_1: iconStyleType1,
  layout_2: iconStyleType2,
};

const headerLocationType1 = css`
  align-self: center;
  margin-left: auto;
  width: 22px;
  height: 22px;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    width: 24px;
    height: 24px;
  }
`;
const headerLocationType2 = css`
  align-self: center;
  margin-left: auto;
  width: 26px;
  height: 26px;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    width: 30px;
    height: 30px;
  }
`;
const headerStyle = {
  layout_1: headerLocationType1,
  layout_2: headerLocationType2,
};

const drawerLocationType1 = css`
  margin-left: 8px;
  padding: 0 10px;
  width: 40px;
  height: 18px;
`;
const drawerLocationType2 = css`
  margin-left: 8px;
  padding: 4px;
  width: 24px;
  height: 24px;
`;

const drawerStyle = {
  layout_1: drawerLocationType1,
  layout_2: drawerLocationType2,
};

const eventLocationType1 = css`
  margin-right: 8px;
  width: 16px;
  height: 16px;
  @media screen and (min-width: ${Breakpoints.isMobile}px) {
    margin-left: auto;
    margin-right: 0px;
    width: 14px;
    height: 14px;
  }
`;
const eventLocationType2 = css`
  margin-right: 8px;
  width: 18px;
  height: 18px;
  @media screen and (min-width: ${Breakpoints.isMobile}px) {
    margin-right: 8px;
    margin-left: 0;
    order: -1;
    width: 24px;
    height: 24px;
  }
`;

const eventStyle = {
  layout_1: eventLocationType1,
  layout_2: eventLocationType2,
};

const widgetLocationType1 = css`
  padding: 10px;
  align-self: center;
  width: 40px;
  height: 46px;
`;
const widgetLocationType2 = css`
  padding: 4px;
  margin-left: 9px;
  align-self: center;
  width: 30px;
  height: 30px;
`;

const widgetStyle = {
  layout_1: widgetLocationType1,
  layout_2: widgetLocationType2,
};

const getByLocation = (layout, componentLocation) => {
  const locationMap = {
    drawer: drawerStyle[layout],
    header: headerStyle[layout],
    event: eventStyle[layout],
    widget: widgetStyle[layout],
  };

  return locationMap[componentLocation];
};

export const StyledIcon = styled.svg<{
  $componentLocation?: FavouritesTypes.FavTypes;
  $componentOrder?: FavouritesTypes.FavOrderTypes;
}>`
  order: ${({ $componentOrder }) => $componentOrder === 'first' && '-1'};
  order: ${({ $componentOrder }) => $componentOrder === 'last' && '1'};
  margin-left: ${({ $componentOrder }) => $componentOrder === 'last' && 'auto'};
  ${({ theme }) => defaultIconStyle[theme.LayoutConfigs?.sport?.sportBtns]}
  ${({ theme, $componentLocation }) => getByLocation(theme.LayoutConfigs?.sport?.sportBtns, $componentLocation)}
`;
