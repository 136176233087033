import React, { memo } from 'react';
import CurrencySwapper from 'modules/casino/shared/components/common/CurrencySwapper';
import { Flex } from 'modules/casino/shared/components/layout';
import { useCurrencyConfig } from 'modules/casino/shared/hooks/useCurrencyConfig';
import { useAppSelector } from 'store';
import { JackpotOdometer } from '../jackpotOdometer';
import { jackpotSelectors } from '../jackpotSlice';
import { getLevels } from '../jackpotUtils';

interface Props {
  jackpotId: string;
}

const JackpotHighestLevel: React.FC<Props> = ({ jackpotId }) => {
  const jackpotDenomDetails = useAppSelector((state) => jackpotSelectors.selectJackpotDenomDetails(state, jackpotId));
  const currency = useAppSelector((state) => jackpotSelectors.selectJackpotCurrencySign(state, jackpotId));
  const { currencySymbolFirst, spaceBetween } = useCurrencyConfig();

  const renderHighestJackpot = () => {
    const jackpotLevels = getLevels({ jackpotDenomDetails }) || [];

    return (
      <div className={`jackpot__numbers d-flex-center`}>
        <CurrencySwapper
          sumComponent={
            <div className="jackpot__number">
              <JackpotOdometer jackpotId={jackpotId} level={jackpotLevels[0]?.level} />
            </div>
          }
          currencyComponent={
            <Flex.Center className="jackpot__currency">
              <span>{currency || ''}</span>
            </Flex.Center>
          }
          currencySymbolFirst={currencySymbolFirst}
          spaceBetween={spaceBetween}
        />
      </div>
    );
  };

  return jackpotDenomDetails ? renderHighestJackpot() : <></>;
};

export default memo(JackpotHighestLevel);
