import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { createSelector } from 'reselect';
import { FormFieldTypes, FieldValues, DropdownOptionType } from 'components/shared/forms/formTypes';
import { setNetAppHeader } from 'shared/common/features/general/slice/general.slice';
import { BeneficientDetailsType } from 'shared/common/features/myProfile/types/myProfile.types';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { DEFAULT_ERROR_MSG } from 'utils/common/constants';
import { getError, isEmpty } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { AccountTypes, DocumentTypeGroup } from '../types/account.types';

const initialSubmitFilesState = {
  isSubmitting: false,
  hasSubmitted: false,
  hasSuccess: false,
  data: null,
  error: '',
};

const initialVerificationState = {
  data: null,
  isLoading: false,
  hasFetched: false,
  hasFetchError: '',
  submittedFiles: initialSubmitFilesState,
};

const initialReferFriendState = {
  isLoading: false,
  hasFetched: false,
  error: '',
  codes: null,
  buttons: null,
  referrals: null,
  pagination: {
    total: 0,
    page: 0,
    pageSize: 6,
  },
};

const initialFormFieldsState = {
  isLoading: false,
  data: {},
  error: '',
};

const initialState: AccountTypes.State = {
  countries: null,
  verification: initialVerificationState,
  formFields: initialFormFieldsState,
  beneficientFields: initialFormFieldsState,
  mfaChannels: null,
  mfaCoolOff: '',
  referFriend: initialReferFriendState,
  isValidMfaCode: null,
  isLoadingCheckCode: false,
  isLoadingGenerateCode: false,
};

export const fetchMyAccountDropdownsData = async (apiUrl: string) => {
  try {
    const url = `${config.API_URL}${apiUrl}`;
    const response = await axiosInstance.get<DropdownOptionType[]>(url);
    return response.data;
  } catch {
    return [];
  }
};

export const AccountThunks: AccountTypes.Thunks = {
  generateMfaCode: createAbortThunk('myProfile/generateMfaCode', async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${config.API_URL}/api/ews-crm/player/player-mfa/generate-mfa-code`);
      return response.data;
    } catch {
      return rejectWithValue(getError.default());
    }
  }),
  checkMfaCode: createAbortThunk('myProfile/checkMfaCode', async (mfaCode, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${config.API_URL}/api/ews-crm/player/player-mfa/check-mfa-code?mfacode=${mfaCode}`,
      );
      return JSON.parse(response.data);
    } catch {
      return rejectWithValue(getError.default());
    }
  }),
  mfaChannels: createAbortThunk('account/mfaChannels', async () => {
    const response = await axiosInstance.get<AccountTypes.MFAChannel[] | null>(
      `${config.API_URL}/api/ews-crm/player/player-mfa/channels`,
    );
    return response.data;
  }),
  fetchCountries: createAbortThunk('account/fetchCountries', async () => {
    const url = `${config.API_URL}/api/ews-crm/public/countries`;
    const response = await axiosInstance.get(url);
    return response.data;
  }),
  fetchVerificationData: createAbortThunk('account/fetchVerificationData', async (_, { rejectWithValue }) => {
    try {
      const url = `${config.API_URL}/api/ews-crm/player/players/document-verification`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue('Not verified correct! Please contact support!');
    }
  }),
  submitUploadedFiles: createAbortThunk('account/submitUploadedFiles', async (_, { getState, rejectWithValue }) => {
    try {
      const url = `${config.API_URL}/api/ews-crm/player/players/documents`;
      const verificationState = getState().myAccount.account.verification;
      if (verificationState?.submittedFiles?.data) {
        const dataForSend: AccountTypes.UploadedFilesStructure[] = verificationState?.submittedFiles?.data;
        const response = await axiosInstance.put(url, dataForSend);

        if (response?.status) {
          if (response.status === 202) {
            return true;
          } else {
            return rejectWithValue('Failed to save');
          }
        }
      }
    } catch (err) {
      return rejectWithValue(getError.responseDataMessage(err) ?? 'Request failed');
    }
  }),
  fetchReferralCode: createAbortThunk('account/fetchReferralCode', async (_, { rejectWithValue }) => {
    try {
      const url = `${config.API_URL}/api/ews-crm/player/players/referral-code`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseDataMessage(err));
    }
  }),
  fetchReferralData: createAbortThunk('account/fetchReferralData', async (_, { getState, rejectWithValue }) => {
    try {
      const pagination = getState().myAccount.account.referFriend.pagination;
      const url = `${config.API_URL}/api/ews-crm/player/players/referred-players`;
      const response = await axiosInstance.post(url, {
        pageSize: pagination.pageSize,
        page: pagination.page + 1,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseDataMessage(err));
    }
  }),
  fetchMyAccountFormFields: createAbortThunk(
    'account/fetchMyAccountFormFields',
    async (_, { rejectWithValue, dispatch }) => {
      try {
        const url = `${config.API_URL}/api/ews-crm/public/cms/player-content?key=my_account_form`;
        const response = await axiosInstance.get(url);
        if (response.headers?.['x-platform-hash-netapp']) {
          dispatch(setNetAppHeader(response.headers['x-platform-hash-netapp']));
        }
        return response.data;
      } catch (err) {
        return rejectWithValue(getError.responseDataMessage(err));
      }
    },
  ),
  fetchMyBeneficientFields: createAbortThunk(
    'account/fetchMyBeneficientFields',
    async (_, { rejectWithValue, dispatch }) => {
      try {
        const url = `${config.API_URL}/api/ews-crm/public/cms/player-content?key=my_account_form_beneficient`;
        const response = await axiosInstance.get(url);
        if (response.headers?.['x-platform-hash-netapp']) {
          dispatch(setNetAppHeader(response.headers['x-platform-hash-netapp']));
        }
        return response.data;
      } catch (err) {
        return rejectWithValue(getError.responseDataMessage(err));
      }
    },
  ),
};

const account = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setUploadedFile(state, action: PayloadAction<AccountTypes.UploadedFilesStructure[]>) {
      const { subDocuments } = action.payload[0];
      if (state.verification.submittedFiles.data) {
        //in case of multiple files, fulfill only subDocuments set
        state.verification.submittedFiles.data[0].subDocuments = [
          ...state.verification.submittedFiles.data[0].subDocuments,
          subDocuments[0],
        ];
      } else {
        state.verification.submittedFiles.data = [];
        state.verification.submittedFiles.data.push(action.payload[0]);
      }
    },
    resetVerificationStatus(state) {
      state.verification = initialVerificationState;
    },
    resetFormFieldsData(state) {
      state.formFields = initialFormFieldsState;
    },
    resetMfaCheckCode(state) {
      state.mfaCoolOff = '';
      state.isValidMfaCode = null;
    },
    resetAccountState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(AccountThunks.generateMfaCode.pending, (state) => {
        state.isLoadingGenerateCode = true;
      })
      .addCase(AccountThunks.generateMfaCode.fulfilled, (state, action) => {
        state.mfaCoolOff = JSON.parse(action.payload);
        state.isLoadingGenerateCode = false;
      })
      .addCase(AccountThunks.generateMfaCode.rejected, (state) => {
        state.mfaCoolOff = '';
        state.isLoadingGenerateCode = false;
      });
    builder
      .addCase(AccountThunks.checkMfaCode.fulfilled, (state, action) => {
        state.isValidMfaCode = action.payload;
        state.isLoadingCheckCode = false;
      })
      .addCase(AccountThunks.checkMfaCode.pending, (state) => {
        state.isValidMfaCode = null;
        state.isLoadingCheckCode = true;
      })
      .addCase(AccountThunks.checkMfaCode.rejected, (state) => {
        state.isValidMfaCode = false;
        state.isLoadingCheckCode = false;
      });
    builder.addCase(AccountThunks.fetchCountries.fulfilled, (state, action) => {
      state.countries = action.payload;
    });
    builder
      .addCase(AccountThunks.fetchVerificationData.fulfilled, (state, action) => {
        state.verification.data = action.payload;
        state.verification.isLoading = false;
        state.verification.hasFetchError = '';
        state.verification.hasFetched = true;
      })
      .addCase(AccountThunks.fetchVerificationData.pending, (state) => {
        state.verification.isLoading = true;
      })
      .addCase(
        AccountThunks.fetchVerificationData.rejected,
        (state, action: PayloadAction<string | { rejectValue: string } | null | undefined>) => {
          state.verification.isLoading = false;
          state.verification.hasFetchError = action.payload as string;
        },
      );
    builder
      .addCase(AccountThunks.fetchReferralCode.fulfilled, (state, action) => {
        state.referFriend.codes = action.payload;
        state.referFriend.hasFetched = true;
        state.referFriend.isLoading = false;
      })
      .addCase(AccountThunks.fetchReferralCode.pending, (state) => {
        state.referFriend.hasFetched = false;
        state.referFriend.isLoading = true;
      })
      .addCase(AccountThunks.fetchReferralCode.rejected, (state, action) => {
        state.referFriend.hasFetched = true;
        state.referFriend.isLoading = false;
        state.referFriend.error = action.payload || DEFAULT_ERROR_MSG;
      });
    builder
      .addCase(AccountThunks.fetchReferralData.fulfilled, (state, action) => {
        const { results, ...pagination } = action.payload;
        state.referFriend.referrals = state.referFriend.referrals
          ? [...state.referFriend.referrals, ...results]
          : results;
        state.referFriend.pagination = pagination;
        state.referFriend.hasFetched = true;
        state.referFriend.isLoading = false;
      })
      .addCase(AccountThunks.fetchReferralData.pending, (state) => {
        state.referFriend.hasFetched = false;
        state.referFriend.isLoading = true;
      })
      .addCase(AccountThunks.fetchReferralData.rejected, (state, action) => {
        state.referFriend.hasFetched = true;
        state.referFriend.isLoading = false;
        state.referFriend.error = action.payload || DEFAULT_ERROR_MSG;
      });
    builder
      .addCase(AccountThunks.submitUploadedFiles.fulfilled, (state, action) => {
        state.verification.submittedFiles.hasSubmitted = action.payload;
        state.verification.submittedFiles.hasSuccess = true;
        state.verification.submittedFiles.isSubmitting = false;
      })
      .addCase(AccountThunks.submitUploadedFiles.pending, (state) => {
        state.verification.submittedFiles.isSubmitting = true;
      })
      .addCase(
        AccountThunks.submitUploadedFiles.rejected,
        (state, action: PayloadAction<string | { rejectValue: string } | null | undefined>) => {
          state.verification.submittedFiles.isSubmitting = false;
          state.verification.submittedFiles.error = action.payload as string;
          state.verification.submittedFiles.hasSubmitted = true;
        },
      );
    builder
      .addCase(AccountThunks.fetchMyAccountFormFields.fulfilled, (state, action) => {
        if (action.payload.referFriend) {
          state.referFriend = {
            ...state.referFriend,
            ...action.payload.referFriend[0],
          };
        }
        state.formFields.data = action.payload;
        state.formFields.isLoading = false;
      })
      .addCase(AccountThunks.fetchMyAccountFormFields.pending, (state) => {
        state.formFields.isLoading = true;
      })
      .addCase(
        AccountThunks.fetchMyAccountFormFields.rejected,
        (state, action: PayloadAction<string | { rejectValue: string } | null | undefined>) => {
          state.formFields.isLoading = false;
          state.formFields.error = action.payload as string;
        },
      );
    builder
      .addCase(AccountThunks.fetchMyBeneficientFields.fulfilled, (state, action) => {
        state.beneficientFields.data = action.payload;
      })
      .addCase(
        AccountThunks.fetchMyBeneficientFields.rejected,
        (state, action: PayloadAction<string | { rejectValue: string } | null | undefined>) => {
          state.beneficientFields.error = action.payload as string;
        },
      );

    builder.addCase(AccountThunks.mfaChannels.fulfilled, (state, action) => {
      state.mfaChannels = action.payload;
    });
    builder.addCase(logoutUser, () => initialState);
  },
});

export const { setUploadedFile, resetVerificationStatus, resetAccountState, resetMfaCheckCode } = account.actions;

export default account.reducer;

export const selectAccount = {
  isValidMfaCode: (state: RootState) => state.myAccount.account.isValidMfaCode,
  isLoadingGenerateCode: (state: RootState) => state.myAccount.account.isLoadingGenerateCode,
  mfaChannels: (state: RootState) => state.myAccount.account.mfaChannels || [],
  isLoadingCheckCode: (state: RootState) => state.myAccount.account.isLoadingCheckCode,
  mfaCoolOff: (state: RootState) => state.myAccount.account.mfaCoolOff,
  countries: (state: RootState): AccountTypes.Countries[] | null => state.myAccount.account.countries,
  verificationStatus: (state: RootState): AccountTypes.VerificationResponse | null =>
    state.myAccount.account.verification.data,
  verificationState: (state: RootState): AccountTypes.VerificationState => state.myAccount.account.verification,
  verificationUploadedFiles: (state: RootState): AccountTypes.FilesState =>
    state.myAccount.account.verification.submittedFiles,
  contactDetailsFormFields: (state: RootState): FormFieldTypes[] =>
    state.myAccount.account.formFields.data['contacts'] as FormFieldTypes[],
  addressFormFields: (state: RootState): FormFieldTypes[] =>
    state.myAccount.account.formFields.data['address'] as FormFieldTypes[],
  passwordFormFields: (state: RootState): FormFieldTypes[] =>
    state.myAccount.account.formFields.data['password'] as FormFieldTypes[],
  beneficientFormFields: (state: RootState): FormFieldTypes[] =>
    state.myAccount.account.beneficientFields.data.beneficient as FormFieldTypes[],
  mfaFormFields: (state: RootState): FormFieldTypes[] =>
    state.myAccount.account.formFields.data['multiFactorAuthentication'] as FormFieldTypes[],
  referFriendState: (state: RootState): AccountTypes.ReferFriendState =>
    state.myAccount.account.referFriend as AccountTypes.ReferFriendState,
  deleteAccountFormFields: (state: RootState): FormFieldTypes[] =>
    state.myAccount.account.formFields.data['deleteAccount'] as FormFieldTypes[],
  isLoadingFormFields: (state: RootState) => state.myAccount.account.formFields.isLoading,
  hasPasswordForm: (state: RootState) => !isEmpty(state.myAccount.account.formFields.data['password']),
};

export const selectVerificationPendingDocuments = createSelector(
  selectAccount.verificationStatus,
  (verificationData) => {
    const pendingDocuments: {
      paymentCards: AccountTypes.PendingDocumentType[];
      otherDocuments: AccountTypes.PendingDocumentType[];
    } = {
      paymentCards: [],
      otherDocuments: [],
    };

    verificationData?.pendingDocuments?.forEach((pd) => {
      pd.documentTypeGroup === DocumentTypeGroup.PAYMENT_CARDS
        ? pendingDocuments.paymentCards.push(pd)
        : pendingDocuments.otherDocuments.push(pd);
    });

    return pendingDocuments;
  },
);

export const updateAccountDetails = async (data: FieldValues): Promise<AxiosResponse> => {
  try {
    const url = `${config.API_URL}/api/ews-crm/player/players/update-contact-details`;
    const payload = { ...data, ...(isEmpty(data['mothersLastName']) ? {} : { toggleMothersName: true }) };
    const response = await axiosInstance.put(url, payload);
    return response;
  } catch (err) {
    return (err as AxiosError).response as AxiosResponse;
  }
};

export const updateBeneficientDetails = async (data: FieldValues): Promise<AxiosResponse> => {
  try {
    const url = `${config.API_URL}/api/ews-crm/player/players/create-beneficient`;

    const response = await axiosInstance.post<BeneficientDetailsType>(url, data);
    return response;
  } catch (err) {
    return (err as AxiosError).response as AxiosResponse;
  }
};

export const updateAddress = async (data: FieldValues, mfaCode?: string): Promise<AxiosResponse> => {
  const dataForSend = {
    city: data.city,
    address: data.address,
    address2: data.address2,
    zipCode: data.zipCode,
    countryId: data.countryName,
    currentCountryId: 0,
    currentCity: '',
    currentAddress: '',
    currentZipCode: '',
    currentAddress2: '',
    ...data,
    ...(mfaCode ? { mfaCode } : {}),
  };

  try {
    const url = `${config.API_URL}/api/ews-crm/player/players/update-address`;
    const response = await axiosInstance.put(url, dataForSend);
    return response;
  } catch (err) {
    return (err as AxiosError).response as AxiosResponse;
  }
};

export const updatePassword = async (data: FieldValues): Promise<AxiosResponse> => {
  try {
    const url = `${config.API_URL}/api/ews-crm/player/users/update-password`;
    const response = await axiosInstance.put(url, data);
    return response;
  } catch (err) {
    return (err as AxiosError).response as AxiosResponse;
  }
};

export const setUserMfa = async ({ channel }): Promise<AxiosResponse> => {
  try {
    // if (!isActive) {

    //   return await axiosInstance.put(`${config.API_URL}/api/ews-crm/player/player-mfa/toggle-mfa?active=true`);

    // } else {

    //   return await axiosInstance.put(`${config.API_URL}/api/ews-crm/player/player-mfa/set-mfa?channel=${channel}`);

    // }

    return await axiosInstance.put(`${config.API_URL}/api/ews-crm/player/player-mfa/set-mfa?channel=${channel}`);
  } catch (err) {
    return (err as AxiosError).response as AxiosResponse;
  }
};
