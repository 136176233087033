import clsx from 'clsx';
import { Text } from 'components/shared';
import { useCurrency } from 'hooks/common/useCurrency';
import { selectSessionBalance } from 'modules/retail/modules/ssbt/features/sessionBalance/slice/sessionBalance.slice';
import { useAppSelector } from 'store';
import { isTerminal } from 'utils/common/helpersCommon';

interface CurrencyProps {
  className?: string;
  spaceBefore?: boolean;
}

const Currency: React.FC<CurrencyProps> = ({ className, spaceBefore }) => {
  const { currencySymbol } = useCurrency();
  const currency = useAppSelector(selectSessionBalance.retailBalanceInfo)?.sessionBalance?.currency;
  const currencyClass = clsx('egtd-p-bet-log__body-currency', spaceBefore && 'ml-1', className);

  return isTerminal() ? (
    <Text className={currencyClass} text={currency || ''} />
  ) : (
    <Text className={currencyClass} text={currencySymbol} />
  );
};

export default Currency;
