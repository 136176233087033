import { useRef } from 'react';

type Props = {
  myProfileRef: React.RefObject<HTMLDivElement>;
};

export const useMyProfileOnScroll = ({
  myProfileRef,
}: Props): { onScroll: (e: React.UIEvent<HTMLElement>) => void } => {
  const isScrollRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const onScroll = (e) => {
    const size = e.target.scrollHeight - 10 - (e.target.scrollTop + e.target.clientHeight);
    if (isScrollRef.current !== null) {
      clearTimeout(isScrollRef.current);
    }
    if (size <= 0) {
      if (size <= 0) {
        isScrollRef.current = setTimeout(function () {
          myProfileRef.current?.scrollTo({
            behavior: 'smooth',
            top: e.target.scrollHeight - e.target.clientHeight - 2,
          });
          e.target.focus();
        }, 150);
        e.stopPropagation();
        e.preventDefault();
      }
    } else {
      if (e.target.scrollTop <= 0) {
        isScrollRef.current = setTimeout(function () {
          myProfileRef.current?.scrollTo({
            behavior: 'smooth',
            top: e.target.scrollTop + 2,
          });
        }, 150);
      }
    }
  };

  return { onScroll };
};
