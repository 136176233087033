import React, { memo } from 'react';
import VirtualExpandableRow from './VirtualExpandableRow';
import { DrawerUI } from '../DrawerUI';

interface Props {
  isActive: boolean;
}

const VirtualDrawerSection: React.FC<Props> = ({ isActive }) => {
  return (
    <DrawerUI.Section type="casino" isActive={isActive}>
      <VirtualExpandableRow />
    </DrawerUI.Section>
  );
};

export default memo(VirtualDrawerSection);
