import React from 'react';
import { OfferBonusLayout01, OfferBonusLayout02 } from './offerBonusLayouts';
import OffersPromotion from './OffersPromotion';
import { OffersSingleBonus } from './OffersSingleBonus';
import { PromotionTypeEnum, PromotionType } from '..';
import type { Category, OffersTabLayout, Promotion } from '../types/offersTab.types';

type Props = {
  promotion?: Promotion;
  activeCategory?: Category;
  type: PromotionType;
  layout?: OffersTabLayout;
};

type LayoutKeyMapProps = {
  activeCategory?: Category;
  items: React.ReactNode;
  bonusIds?: string[];
  promotionTitle?: string;
};

export const OffersTabSuccessMap: Record<OffersTabLayout, React.FC<LayoutKeyMapProps>> = {
  layout_1(props) {
    return <OfferBonusLayout01 {...props} />;
  },
  layout_2(props) {
    return <OfferBonusLayout02 {...props} />;
  },
};

export const OfferBonus: React.FC<Props> = ({ promotion, type, activeCategory, layout }) => {
  if (!promotion) return null;

  const ComponentLayout = OffersTabSuccessMap[layout || 'layout_1'];
  const bonusIds = promotion?.bonuses?.filter((b) => b.isUnseen)?.map((b) => b.id);
  const disableHeader = layout === 'layout_2';
  return (
    <>
      {type === PromotionTypeEnum.Promotion && (
        <ComponentLayout
          bonusIds={bonusIds}
          promotionTitle={promotion.title}
          activeCategory={activeCategory}
          items={<OffersPromotion promotion={promotion} layout={layout} />}
        ></ComponentLayout>
      )}
      {type === PromotionTypeEnum.Bonus &&
        promotion?.bonuses.map((bonus) => (
          <ComponentLayout
            key={bonus.id}
            activeCategory={activeCategory}
            items={<OffersSingleBonus bonus={bonus} disableHeader={disableHeader} />}
            promotionTitle={bonus.title}
            bonusIds={bonus?.isUnseen ? [bonus.id] : undefined}
          ></ComponentLayout>
        ))}
    </>
  );
};
