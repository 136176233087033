import React from 'react';
import { Flex } from 'components/shared/layout/Flex';
import NavIcon from './NavIcon';

interface NavItemRowProps {
  onClick: () => void;
  text: string;
  icon?: string;
  iconColor?: string;
  dataQid?: string;
  dataCpp?: string;
}

const NavItemRow: React.FC<NavItemRowProps> = ({ onClick, text, icon, iconColor, dataQid, dataCpp }) => {
  const dataCPPProp = iconColor ? { style: { fill: iconColor } } : { 'data-cpp': dataCpp };

  return (
    <Flex.ACJB className="grid-cols-span-3 mpd-nav mpd-nav--row" onClick={onClick} data-qid={dataQid} {...dataCPPProp}>
      {text}
      <NavIcon size="sm" icon={icon} />
    </Flex.ACJB>
  );
};

export default NavItemRow;
