import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { BankHistory } from './bankHistoryTypes';

const initialState: BankHistory = {
  activeTab: '',
  bankHistory: [],
  totalPages: 0,
  bankHistoryIsFetching: false,
  status: 'idle',
};

const bankHistory = createSlice({
  name: 'bankHistory',
  initialState,
  reducers: {
    setBankHistoryIsFetching(state) {
      state.bankHistoryIsFetching = true;
    },

    clearBankHistory(state) {
      state.bankHistory = [];
      state.totalPages = 0;
      state.status = 'idle';
      delete state.error;
      state.bankHistoryIsFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBankHistory.pending, (state, action) => {
        const { isFetchMore } = action.meta.arg;
        if (!isFetchMore) {
          state.status = 'pending';
        }
      })
      .addCase(fetchBankHistory.fulfilled, (state, action) => {
        const { isFetchMore } = action.meta.arg;
        const { totalPages, transfers } = action.payload;
        if (isFetchMore) {
          state.bankHistoryIsFetching = false;
          state.bankHistory = [...state.bankHistory, ...transfers];
        } else {
          state.bankHistory = transfers;
        }
        state.totalPages = totalPages;
        state.status = 'succeeded';
      })
      .addCase(fetchBankHistory.rejected, (state, action) => {
        state.bankHistory = [];
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { setBankHistoryIsFetching, clearBankHistory } = bankHistory.actions;

export default bankHistory.reducer;

type FetchBankHistoryProps = {
  page: number;
  size: number;
  isFetchMore: boolean;
  type: string;
  fromDate: number;
  toDate: number | null;
};

export const fetchBankHistory = createAbortThunk(
  'fetchBankHistory',
  async ({ page, size, isFetchMore, type, fromDate, toDate }: FetchBankHistoryProps, { rejectWithValue, dispatch }) => {
    try {
      const queryParams = {
        page,
        size,
        fromDate,
        toDate: toDate ? toDate : Date.now(),
      };
      //do not delete debugging logs
      // console.log('timestamp_from ', fromDate, '---');
      // console.log('timestamp_to ', toDate, '---');
      // console.log('______________________');
      // console.log('UTC date_from ', new Date(queryParams.fromDate).toUTCString(), '---');
      // console.log('UTC date_to ', new Date(queryParams.toDate).toUTCString(), '---');
      // console.log('______________________');
      // console.log('LOCALE date_from ', new Date(queryParams.fromDate).toLocaleString(), '---');
      // console.log('LOCALE date_to', new Date(queryParams.toDate).toLocaleString(), '---');
      if (isFetchMore) {
        dispatch(setBankHistoryIsFetching());
      }
      const response = await axiosInstance.get(`${config.API_URL}/api/payment/player/history/${type}`, {
        params: queryParams,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(getError.responseDataMessage(error));
    }
  },
);

export type BankHistoryState = Pick<RootState, 'myAccount'>;

export const selectBankHistory = {
  bankHistory: (state: BankHistoryState) => state.myAccount.bankHistory.bankHistory,
  totalPages: (state: BankHistoryState) => state.myAccount.bankHistory.totalPages,
  isLoading: (state: BankHistoryState) => state.myAccount.bankHistory.bankHistoryLoading,
  isFetchingMore: (state: BankHistoryState) => state.myAccount.bankHistory.bankHistoryIsFetching,
  status: (state: BankHistoryState) => state.myAccount.bankHistory.status,
  error: (state: BankHistoryState) => state.myAccount.bankHistory.error,
};
