import styled from 'styled-components';

export const SCPrevOdds = styled.span`
  margin-right: -3px;

  font-size: 85%;
  font-weight: 700;
  line-height: 75%;

  opacity: 0.75;
`;
