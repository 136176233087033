import { ReactNode } from 'react';
import clsx from 'clsx';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { WidgetTemplate } from 'modules/casino/shared/constants';
import { ScrollWrapper } from 'modules/casino/shared/features/casinoScroll';
import { CasinoWidgetsTypes } from '../../../types/casinoWidgetsTypes';
import { jackpotsWidgetWrapperClassesByLayout, jackpotsWidgetScrollWrapperParamsMap } from '../jackpotsWidgetConstants';

interface WrapperProps {
  children: ReactNode;
  templateType: CasinoWidgetsTypes.WidgetTypes;
  categoryLayoutType: CategoryLayoutTypes;
  forceArrowsUpdate?: boolean;
  className?: string;
}

const WidgetWrapper: React.FC<WrapperProps> = ({
  children,
  templateType,
  categoryLayoutType,
  forceArrowsUpdate,
  className,
}) => {
  const scrollWrapperParams = jackpotsWidgetScrollWrapperParamsMap[templateType];

  const widgetWrapperStyle = clsx(
    'jackpot-widget',
    `jackpot-widget--${WidgetTemplate[templateType]}`,
    jackpotsWidgetWrapperClassesByLayout[categoryLayoutType] || '',
    WidgetTemplate[templateType] === WidgetTemplate.RIBBON ? 'slider-flex' : 'slider-grid',
    className && className,
  );

  const scrollWrapperInnerStyles = clsx(
    'generic-wrapper grid',
    WidgetTemplate[templateType] === WidgetTemplate.RIBBON ? 'no-scrollbars' : '',
  );
  const params = { ...scrollWrapperParams, ...{ forceArrowsUpdate: forceArrowsUpdate } };
  return (
    <div className={widgetWrapperStyle}>
      <ScrollWrapper {...params}>
        <div className={scrollWrapperInnerStyles}>{children}</div>
      </ScrollWrapper>
    </div>
  );
};

WidgetWrapper.displayName = 'WidgetWrapper';
export default WidgetWrapper;
