import { memo } from 'react';
import { RetailHeaderLiveDate, RetailHeaderLiveText } from './features';

const RetailHeaderLive = () => {
  return (
    <>
      <RetailHeaderLiveText />
      <div className="r-header__right">
        <RetailHeaderLiveDate />
      </div>
    </>
  );
};

export default memo(RetailHeaderLive);
