import { useLocation } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { playtechPokerSelectors } from 'pages/playtechPoker/slice/playtechPokerLogin.slice';
import { useAppSelector } from 'store';

export const useIsPokerRoute = (): boolean => {
  const { query } = useQuery();
  const { pathname } = useLocation();
  const openFromPoker = useAppSelector(playtechPokerSelectors.openFromPoker);

  return openFromPoker || pathname.includes('playtech-poker') || !!query.get('openFromPoker');
};
