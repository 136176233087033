import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { getError, isEmpty } from 'utils/common/helpersCommon';

type PrintRequest = () => Promise<void>;

type PrintQueueState = {
  queue: PrintRequest[];
  isPrinting: boolean;
};

const initialState: PrintQueueState = {
  queue: [],
  isPrinting: false,
};

const printQueueSlice = createSlice({
  name: 'printQueue',
  initialState,
  reducers: {
    enqueuePrintRequest: (state, action: PayloadAction<PrintRequest>) => {
      state.queue.push(action.payload);
    },
    dequeuePrintRequest: (state) => {
      state.queue.shift();
    },
    setIsPrinting: (state, action: PayloadAction<boolean>) => {
      state.isPrinting = action.payload;
    },
  },
});

export const { enqueuePrintRequest, dequeuePrintRequest, setIsPrinting } = printQueueSlice.actions;

export const processPrintQueue = () => async (dispatch, getState) => {
  let state = getState() as RootState;
  let queue = state.retail.printQueue.queue;
  const isPrinting = state.retail.printQueue.isPrinting;

  if (isPrinting || queue.length === 0) return;

  dispatch(setIsPrinting(true));

  while (queue.length > 0) {
    state = getState() as RootState;
    const nextPrintRequest = state.retail.printQueue.queue[0];

    if (!isEmpty(nextPrintRequest)) {
      try {
        await nextPrintRequest();
      } catch (error) {
        getError.message(error);
      } finally {
        dispatch(dequeuePrintRequest());
      }
    }

    // Refresh the state and queue after each operation
    state = getState() as RootState;
    queue = state.retail.printQueue.queue;
  }

  dispatch(setIsPrinting(false));
};

export const selectPrintQueueData = {
  printQueue: (state: RootState) => state.retail.printQueue.queue,
};

export default printQueueSlice.reducer;
