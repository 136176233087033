import React, { HTMLAttributes } from 'react';
import { Flex } from 'components/shared/layout';
import ScrollContainer, { arrowStyleTypes, handleScrollMoveWith } from 'components/shared/ScrollContainer';

// Component CSS NameSpace
const cssNS = 'bottom-tab-bar';

const scrollItemWidth = 60;

// BottomTabBar Wrapper
interface WrapperProps extends HTMLAttributes<HTMLDivElement> {
  tabsLength: number;
  forwardRef;
  children;
}

const Wrapper: React.FC<WrapperProps & React.RefAttributes<HTMLDivElement>> = ({
  tabsLength,
  forwardRef,
  children,
  ...props
}) => {
  const { moveWith } = handleScrollMoveWith({ itemsToScroll: tabsLength, itemWidth: scrollItemWidth });

  return (
    <div className={`on-ios-no-extra-padding ${cssNS}`} ref={(node) => forwardRef(node)} {...props}>
      <ScrollContainer
        regularDesktopCellWidth={scrollItemWidth}
        moveWith={moveWith}
        typeArrow={arrowStyleTypes.Sports}
        classNames="scrollable"
        activeClass="bottom-tab-bar__item--active"
        scrollToElemOnChildrenChange={false}
      >
        <Flex.ACJB>{children}</Flex.ACJB>
      </ScrollContainer>
    </div>
  );
};

export const BottomTabBarUI = { Wrapper };
