import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout/Flex';
import { UI } from 'components/shared/UI';
import CDNSVG from 'modules/casino/shared/components/UI/CDNSVG/CDNSVG';
import usePromotions from 'shared/common/features/myProfile/sections/tabs/offersTab/components/hooks/usePromotions';
import { BonusButtonAction } from 'shared/common/features/myProfile/sections/tabs/offersTab/types/offersTab.types';
import ParseHTML from 'shared/common/ParseHTML';
import { CasinoTranslations } from 'utils/common/translationUtils/translationStrings';
import PopoverBonusProgress from './PopoverBonusProgress';
import { BonusOffersInfoButton } from '../types/bonusOffersInfoButton.types';
import { cssBonusOffersInfoNS } from '../types/bonusOffersInfoUI.types';

type Props = {
  dataItem: BonusOffersInfoButton.Bonus;
};

const PopoverBonus: React.FC<Props> = ({ dataItem }) => {
  const { status, error, buttonClickHandler } = usePromotions(dataItem);
  const { t } = useTranslation();

  const hasAction =
    dataItem.buttons && dataItem.buttons.some((button) => button.buttonActionType !== BonusButtonAction.None);

  return (
    <div className={cssBonusOffersInfoNS.PopoverBonus}>
      <div className={cssBonusOffersInfoNS.PopoverBonusTitle}>
        {dataItem.resourceName && <CDNSVG className={'mr-2'} size={20} icon={dataItem.resourceName} />}
        <span>{dataItem.title}</span>
      </div>
      {dataItem.content && (
        <div>
          <ParseHTML html={dataItem.content} options={{ trim: true }} />
        </div>
      )}
      <div>
        <span className={'font-weight-bold'}>{t(CasinoTranslations.BonusOffersInfo.activatedDate)}</span>&nbsp;
        <span>{dataItem.bonusActivatedDate}</span>
      </div>
      <div>
        <span className={'font-weight-bold'}>{t(CasinoTranslations.BonusOffersInfo.expirationDate)}</span>&nbsp;
        <span>{dataItem.bonusExpirationDate}</span>
      </div>
      <PopoverBonusProgress dataItem={dataItem} />
      {hasAction && (
        <div className="mpd-cta-item__section">
          {status === 'failure' && error ? (
            <p>{error}</p>
          ) : (
            <Flex style={{ gap: '10px' }}>
              {dataItem.buttons.map(
                (button, index) =>
                  button.buttonActionType !== BonusButtonAction.None && (
                    <UI.Button
                      key={index}
                      class={'f-basis-100'}
                      size="md"
                      variant={button.buttonActionType === BonusButtonAction.Cancel ? 'secondary' : undefined}
                      isDisabled={status === 'pending'}
                      onClick={buttonClickHandler(button)}
                      text={button.buttonText ? button.buttonText : '\u00A0'}
                    />
                  ),
              )}
            </Flex>
          )}
        </div>
      )}
    </div>
  );
};

export default PopoverBonus;
