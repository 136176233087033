import { useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FormButton, FormControl } from 'components/shared/forms/components';
import { FieldValues } from 'components/shared/forms/formTypes';
import { Flex } from 'components/shared/layout';
import Button from 'components/shared/UI/Button/Button';
import { useAuthentication } from 'hooks/common/useAuthentication';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { MFA_Channels } from 'shared/common/features/myProfile/types/myProfile.types';
import { CodeError } from 'shared/common/features/twoFactorVerification/components/CodeError';
import CountDownTimer from 'shared/common/features/twoFactorVerification/components/CountDownTimer';
import { hideString } from 'shared/common/features/twoFactorVerification/utils/twoFactorVerification.utils';
import { useAppDispatch, useAppSelector } from 'store';
import { MyAccountTranslation, Registration } from 'utils/common/translationUtils/translationStrings';
import { PhoneResendCode } from './components';
import { usePhoneConfirmation } from './hooks/usePhoneConfirmation';
import { selectPhoneConfirmation } from './slice/phoneConfirmation.slice';
import { PhoneConfirmationTypes } from './types/phoneConfirmation.types';
import { loginThunks } from '../login/slice/login.slice';

type Props = {
  data?: FieldValues;
  type?: PhoneConfirmationTypes.VerificationType;
  location: 'reg' | 'login' | 'reset-pass' | 'logged-in' | 'my-account';
  closeAction?: () => void;
  hideChannel?: boolean;
  showLogoutButton?: boolean;
  channels?: MFA_Channels[];
};

const PhoneConfirmation: React.FC<Props> = ({
  data,
  type = 'player',
  closeAction,
  hideChannel = false,
  location,
  showLogoutButton = true,
  channels = ['sms'],
}) => {
  const { isAuthenticated } = useAuthentication();
  const field = useAppSelector(selectPhoneConfirmation.field);
  const userPhone = useAppSelector(selectPhoneConfirmation.phoneNumber);
  const userEmail = useAppSelector(selectMyProfile.email);
  const phoneCoolOff = useAppSelector(selectPhoneConfirmation.phoneCoolOff);
  const hasAttemptsLeft = useAppSelector(selectPhoneConfirmation.hasAttemptsLeft);

  const phoneConfirmationConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.PHONE_CONFIRMATION,
  });

  const channelsMap = {
    email: userEmail,
    sms: userPhone,
    phoneCall: userPhone,
  };

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const logout = useCallback(() => {
    dispatch(loginThunks.logout());
  }, [dispatch]);

  const formProps = useForm({ mode: 'onChange' });
  const { reset, handleSubmit } = formProps;
  const {
    handleSendCode,
    onChangeCallback,
    timerFinnishCallback,
    resetTimerCallback,
    timerIsFinished,
    errorMessage,
    isFormDisabled,
    step,
  } = usePhoneConfirmation({
    data,
    type,
    reset,
    isResetPassword: location === 'reset-pass',
    channels,
  });

  const currChannel = channels[step] || '';

  const channelValue =
    phoneConfirmationConfig?.hideChannel || hideChannel
      ? hideString({ text: channelsMap[currChannel], type: currChannel })
      : channelsMap[currChannel];

  if (!field || !currChannel) return null;

  const timeZone = type === 'mfa_extended' ? 'systemTime' : 'localTime';

  return (
    <div className="text-center">
      {location === 'reg' && <div className="text-center mb-2">{t(Registration.Confirmation.registerConfirmText)}</div>}
      <div className="text-center mb-2">
        {type === 'mfa_extended' ? <b className="d-block">{t(`MyAccount.PhoneConfirmation.${currChannel}`)}</b> : ''}
        {t(`MyAccount.PhoneConfirmation.${currChannel}Text`)}{' '}
        <b style={{ verticalAlign: 'text-top' }}>{channelValue}</b>
      </div>

      {phoneCoolOff && hasAttemptsLeft && (
        <CountDownTimer
          timeZone={timeZone}
          headingText={t(MyAccountTranslation.PhoneConfirmation.textValid)}
          counterText={t(MyAccountTranslation.PhoneConfirmation.time)}
          endDate={phoneCoolOff}
          timerIsFinnish={timerFinnishCallback}
        />
      )}

      <FormProvider {...formProps}>
        <form onSubmit={handleSubmit(handleSendCode)}>
          <Flex.Center className="d-flex-col">
            <FormControl
              {...field}
              hasError={!!errorMessage}
              disabled={timerIsFinished}
              onChangeCallback={onChangeCallback}
            />
            {errorMessage && <CodeError text={errorMessage} />}
            <Flex.Center className="d-flex-col-row">
              {isAuthenticated && showLogoutButton && (
                <Button
                  variant="secondary"
                  onClick={logout}
                  isDisabled={false}
                  text={t(MyAccountTranslation.PhoneConfirmation.logoutBtn)}
                  icon="logout"
                  className="mt-3 mr-2"
                />
              )}
              <FormButton
                className="mt-3"
                disabled={isFormDisabled}
                text={t(MyAccountTranslation.PhoneConfirmation.btn)}
              />
            </Flex.Center>
          </Flex.Center>
        </form>
      </FormProvider>
      <PhoneResendCode data={data} type={type} timerIsFinnish={resetTimerCallback} />
      {phoneConfirmationConfig && (
        <>
          {t(phoneConfirmationConfig.text)}
          <Link className="px-1" onClick={closeAction} to={phoneConfirmationConfig.link}>
            {t(phoneConfirmationConfig.linkText)}
          </Link>
        </>
      )}
    </div>
  );
};

export default PhoneConfirmation;
