import { CSSProperties } from 'react';
import { DeviceType } from 'hooks/common/useOnDeviceChange';
import type { AggregatorConfig } from 'modules/casino/shared/features/gameLaunch/casinoLaunchTypes';
import { Casino } from 'modules/casino/shared/types';
import { RetailAddress } from 'modules/retail/modules/cashier/types/cashier.types';
import { GamificationFilter } from 'pages/captainUp/utils/captainUp.utils';
import { AccountTypes } from 'pages/myAccount/tabs/account/types/account.types';
import { PlayerRestrictions } from 'pages/myAccount/tabs/responsibleGambling/types/responsibleGambling.types';
import { OmniPlayTypes } from 'pages/retailGames/providers/omniPlay/types/omniPlay.types';
import { CasinoGamesTypes } from '../../../../../modules/casino/modules/container/cellsContainer/cellls/types/casinoGamesTypes';
import { GridLayouts } from '../../../../../modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import { CategorySubTypes } from '../../../../../modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { MainNavBarButtonsTypes } from '../../appHeader/components/mainNavbar/mainNavbarButtons/types/mainNavBarButtons.types';
import { WidgetInitTypes } from '../../integrations/gamification/types/gamification.types';
import type {
  Balance,
  Currency,
  MFA_Channels,
  MyProfileTabType,
  SectionWithConfig,
} from '../../myProfile/types/myProfile.types';

export declare module General {
  import GameLayout = CasinoGamesTypes.GameLayout;
  type State = {
    isSessionTimerActive: boolean;
    layoutConfig: Record<LayoutConfigurationKeys, LayoutConfiguration> | NewLyoutCinfigType;
    isOpenChat: boolean;
    buSettings: BusinessUnitSettings | null;
    netAppHeader: string;
    currencySettings: Currency[] | null;
    isGeolocationRestricted: boolean;
    realityCheckConfirmedAt: number | null;
  };

  type BusinessUnitSettings = {
    mfaChannels: string[];
    isMfaEnabled: boolean;
    isMfaEnabledExtended?: boolean;
    mfaChannelsExtended?: MFA_Channels[];
    playerBetStatistics: boolean;
    confirmPhone: boolean;
    regulationCasinoGameSettings?: PlayerRestrictions.ProductType[] | null;
    regulationGameLaunchSettings?: boolean;
    phoneOnlyConfirmation?: boolean;
    manualConfirmPhone?: boolean;
    availableRestrictionProducts?: PlayerRestrictions.ProductType[];
    isIpGeolocationTrackingEnabled?: boolean;
    isMultipleAccountTrackingEnabled?: boolean;
  };

  type NewLyoutCinfigType = Record<ModuleConfigurationKeys, Record<LayoutConfigurationKeys, LayoutConfiguration>>;

  type businessUnitDataType = {
    oddSettingsTypeId: number;
    maxProfit: Record<string, number>;
    minStake: Record<string, number>;
    maxStake: Record<string, number>;
  };

  type RowColumn = { row: number; cols: number[] };

  type LayoutConfigurationKeys = 'general' | 'desktop' | 'tablet' | 'mobile' | 'terminal';
  type TrackingEventsConfig = {
    fbPageViewApi: string;
    fbPlaceBetApi: string;
    fbFreeGameStartApi: string;
    fbPaidGameStartApi: string;
    fbDepositApi: string;
  };
  type ModuleConfigurationKeys = 'casino' | 'platform';

  type InsufficientBalance = {
    minBalance: number;
  };

  type BetMenuItem = { sport: string; icon: number };

  type RequestBet = {
    isActive: boolean;
    menuItems: Record<string, { sport: string; icon: number }[]>;
    infoText: string[];
    validationLength?: { maxValue: number; minValue: number; text: string };
  };

  type SupportChat = {
    liveChat?: {
      verifyGroup?: string;
    };
  };

  type GameLauncherTopBarButton = {
    type: GameLauncherTopBarButtonTypes;
    enabled: boolean;
    icon?: string;
    text?: string;
    buttonStyle?: CSSProperties;
    textStyle?: CSSProperties;
    backgroundImage?: string;
  };

  type LayoutConfiguration = {
    trackingEvents: TrackingEventsConfig;
    navigation: NavigationConfig;
    ticker: Ticker; // TO DO REMOVE
    event: EventConfig | null;
    spaceBetween: number;
    myAccount: myAccountConfig;
    myProfile: MyProfileConfig;
    casino: CasinoConfig;
    casinoAggregatorGameLaunch?: boolean;
    clientAggregatorGameLaunch?: boolean;
    playtechPokerLogin?: boolean;
    hideCookiesConsentFromPoker?: boolean;
    svaraAppLogin?: boolean;
    hideCookiesConsentFromSvara?: boolean;
    hideCookie?: boolean;
    tombolaIndicator?: TombolaIndicator;
    affiliateExpires: number;
    header: HeaderConfigs;
    verticals: VerticalsConfig;
    navigationLayout: NavigationLayoutConfig;
    // !!! layoutTypeConfig should be updated in both sport & platform configs
    layoutTypeConfig?: LayoutTypesConfig | null;
    playerRestrictions?: PlayerRestrictionsConfig | null;
    playerHistory?: PlayerHistory | null;
    phoneConfirmation: { link: string; linkText: string; text: string; hideChannel: boolean } | null;
    supportChat?: SupportChat | null;
    // !!! polling should be updated in both sport & platform configs
    polling?: PollingConfig | null;
    pollingGeoLocationCheck?: number;
    loginRegisterShow?: { isVissible: boolean };
    retailAddress: RetailAddress;
    retailDevice?: {
      qrCodeScanner: boolean;
      modalWrapper: boolean;
      redirectPage: boolean;
      moneyValidator: boolean;
      printerStats: boolean;
      coinValidator: boolean;
      rgbController: boolean;
      documentScanner: boolean;
      UCNScanner: boolean;
    } | null;
    retailHeaderButtonsVisible?: RetailHeaderButtonsVisibilityTypes | null;
    retailGames?: {
      omniPlay?: OmniPlayTypes.LaunchOptions[];
    };
    currencyDisplay?: CurrencyDisplayConfig;
    loginRetailKeyboard?: boolean;
    isTicketCodeLinear?: boolean;
    isMagnumBetRo?: boolean;
    isBetAmountVisible?: boolean;
    currencyBeforeAmount?: boolean;
    drawer: DrawerConfig;
    terminal?: TerminalConfig | null;
    highlightFeature?: HighlightFeatureConfig;
    insufficientBalance: InsufficientBalance | null;
    requestBet: null | RequestBet;
    sitemapTags?: SitemapTagsConfig;
    drawerToggler?: DrawerToggler;
    fantasyLeague: FantasyLeagueConfig;
    fantasyStars: FantasyStarsConfig;
    notifications?: Notifications;
    favouritesConfig?: {
      hideFavourites: boolean;
    };
    footerConfig?: {
      hideFooter: boolean;
    };
    printProgram?: PrintProgram;
    iconsType: 'svg' | 'png';
    lottery: LotteryPage;
    offersToggler: HeaderOffersTogglerConfig;
    appLogo: 'dev' | 'int' | 'uat';
    sportsBookWidget: SportsBookWidget;
    casinoWidgetConfig?: boolean;
    // Custom icons main nav bar
    mainNavBarButtons: MainNavBarButtonsTypes.Item[];
    gameLaunchTopBar?: {
      hideOnFreePlay?: boolean;
      pinned?: GameLauncherTopBarButton[];
      scrollable?: GameLauncherTopBarButton[];
    };
    authBanner?: AuthBanner;
    cashierNewImplementation?: boolean;
  } & CasinoConfig;

  type AuthBanner = {
    registration?: {
      isVisible: boolean;
      imagePath: string;
      linkUrl: string;
    };
    login?: {
      isVisible: boolean;
      imagePath: string;
      linkUrl: string;
    };
  };

  type AuthBannerType = keyof AuthBanner;

  type PrintProgram = {
    fileUrl: string;
    fileName: string;
    fileType: string;
  };

  type PollingConfig = {
    authenticated?: boolean;
    defaultInterval?: number;
  };

  type HistoryConfig = {
    minPeriodInDays?: number;
    maxPeriodInDays?: number;
    daysRange?: number;
    pageSize?: number;
    hasExportCSV?: boolean;
  };

  type PlayerRestrictionsConfig = {
    buttonRoute: string;
    buttonText?: string;
  };

  type PlayerHistory = {
    bonus?: HistoryConfig;
    bank?: HistoryConfig;
    sport?: HistoryConfig;
    casino?: HistoryConfig;
    login?: HistoryConfig;
    transactions?: HistoryConfig;
  };

  type SitemapTagsConfig = {
    changeFreq: string;
  };

  type DrawerConfig = {
    hideIconText: boolean;
    virtualSection: DrawerVirtualSection[];
  };

  type DrawerToggler = {
    isVisibleOnDesktop: boolean;
  };

  type DrawerVirtualSection = {
    alias: string;
    sportId: number;
    route: string;
    id: number;
    code: string;
    translation: string;
  };

  type FantasyLeagueConfig = {
    showFantasyLeague: boolean;
    scriptInitData: FantasyScripData;
  };

  type FantasyScripData = {
    src: string;
    whiteLabel: string;
    environment: string;
    basePath: string;
  };

  type FantasyStarsConfig = {
    srcDomain: string;
    showFantasyStars: boolean;
    tokenExp: number;
  };

  type layoutTypes = 'layout_1' | 'layout_2';
  type casinoNavigationLayoutTypes = 'layout_1' | 'layout_2' | 'layout_3' | 'layout_5';

  type LayoutTypesConfig = {
    sport: {
      betSlip: layoutTypes;
      myBets: layoutTypes;
      sportBtns: layoutTypes;
      carousel: layoutTypes;
      betStatusBadge: layoutTypes;
      lastWinner: layoutTypes;
    };
    navigationLayout: layoutTypes;
    promotions: { navigation: layoutTypes };
    offersTab: 'layout_1' | 'layout_2';
    tournamentsButton?: General.casinoNavigationLayoutTypes;
    header: {
      wrapper: layoutTypes;
      drawerToggler: layoutTypes;
      offersToggler?: HeaderOffersTogglerConfig;
    };
    home: {
      videoBanner: layoutTypes;
    };
    casino: {
      casinoNavigationLayout?: casinoNavigationLayoutTypes;
      grid: {
        loadMoreBtn: layoutTypes;
      };
      latestWinners: layoutTypes;
      latestWinnersNew: Record<string, layoutTypes>;
    };
    sitemapTags: {
      changeFreq: string;
    };
  };

  type HeaderConfigs = {
    topMobileAppBanner: TopMobileAppBanner;
    wrapper: HeaderWrapperConfig;
    drawerToggler: HeaderDrawerTogglerConfig;
    offersToggler?: HeaderOffersTogglerConfig;
    showSkinSwitcher?: boolean;
    hideSportSearchIcon?: boolean;
  };

  type TopMobileAppBanner = {
    android: TopMobileAppBannerBannerPath;
    ios: TopMobileAppBannerBannerPath;
    iOSActive: boolean;
    AndroidActive: boolean;
    navigationUrl: string;
    reactRoute: string;
    route: string;
    cookieExpires: number;
    maximumCloseCounter: number;
  };
  type CasinoExternalCategoriesConfig = Casino.CategoryRequired;
  type TopMobileAppBannerBannerPath = {
    active: boolean;
    bannerPath: string;
    buttonText: string;
    description: string;
    downloadLink: string;
    reactRoute: string;
    route: string;
    title: string;
  };

  type HeaderWrapperConfig = { layoutType: layoutTypes | 'layout_3' };
  type HeaderDrawerTogglerConfig = { layoutType: layoutTypes; isVisibleOnDesktop: boolean };

  type HeaderOffersTogglerConfig = {
    isVisible: boolean;
    icon?: string;
    url?: string | null;
    startDate?: string | null;
    endDate?: string | null;
  };

  type CasinoConfig = {
    liveFeed: {
      enablePooling: boolean;
      interval: number;
    };
    doubleFetching: boolean;
    jackpots: {
      fallbackInterval: number;
      isFakeUpdateON: boolean;
    };
    lastWinners: {
      interval: number;
    };
    widgetPrizeDrop: {
      interval: number;
    };
    widgetLeaderBoard: {
      interval: number;
    };
    navigation: {
      providerTabParentHighlights: boolean;
      navigationGridLayout: boolean;
    };
    iOsSafeAria: number;
    showBetLimits: boolean;
    showBetLimitsPerProvider: string[];
    casinoGameAggregatorConfig: Casino.PartialNestedObjects<AggregatorConfig>;
    mobileAppRestrictions: {
      appVersion: string;
      allowProvidersAlias: string[];
      allowProvidersIds: number[];
      allowGameIdsByProviders: Record<string, string[]>;
    };
    casinoLayoutConfig: {
      defaultCdnPathLayout: string;
      cdnPath: Record<GridLayouts, string>;
      restrictedAppVersion: string;
      lastCategoryInfinityScroll: boolean;
      casinoExternalCategories: Record<string, CasinoExternalCategoriesConfig>;
      cellLayouts: Record<GridLayouts, string>;
      showGameTitleByLayout: Record<GridLayouts, boolean>;
      showGameTitleBySubType: Record<CategorySubTypes, boolean>;
      hideLastWinners: Record<GridLayouts, Record<GameLayout, boolean>>;
      showFreePlayBtn: boolean;
      showGameTitle: boolean;
    };
    zendeskChatConfig: {
      webWidget: {
        launcher: Record<never, never>;
        chat: {
          departments: {
            enabled: string[];
            select: string;
          };
        };
      };
    };
    aivoChatConfig: null | { restrictedRoutes: string[] };
    liveChatConfig: null | { isVisible: boolean; restrictedRoutes: string[] };
    bonusGamesApi: {
      fallbackInterval: number;
      enableGameTagIcon: boolean;
    };
    enableSegmentCheck: boolean;
    kycConfig: {
      gameIds: string[];
      verificationStatus: string[];
    };
    categoryFetchInterval: {
      svara: number;
    };
    enableCategoryTracking: boolean;
    currencyDisplay: {
      currencySymbolFirst: boolean;
      spaceBetween: boolean;
      formatToComma: boolean;
    };
    casinoProvidersBarPosition: string;
  };

  export enum LayoutConfigInnerLayoutTypes {
    JACKPOTS = 'jackpots',
    LAST_WINNERS = 'lastWinners',
    NAVIGATION = 'navigation',
    CASINO_GAME_PROVIDERS_CONFIG = 'casinoGameProvidersConfig',
    CASINO_LAYOUT_CONFIG = 'casinoLayoutConfig',
  }

  type OddsSettings = {
    id: number;
    name: 'none' | 'higher' | 'any';
    nameTranslations: string;
  };

  type MyBetsTabs = { id: number; translation: string; type: 'ALL' | 'LIVE' | 'SETTLED' | 'UNSETTLED' };

  type SidebarTabs = { id: number; translation: string; type: 'betslip' | 'myBets' };

  type SideBarConfig = {
    sports: {
      collapsed: boolean;
      showExpander: boolean;
      filters: FiltersConfig[];
    };
    esports: {
      collapsed: boolean;
      showExpander: boolean;
      // filters: FiltersConfig[];
    };
    virtual: {
      collapsed: boolean;
      showExpander: boolean;
    };
  };

  type FiltersConfig = {
    name: string;
    value: string;
    type: 'days' | 'hours';
    row: 0 | 1;
    defaultFilter: boolean;
  };

  type MainNavConfig = {
    isVisible: boolean;
    search: boolean;
    registration: boolean;
    signIn: boolean;
    balance: boolean;
  };

  type TopNavConfig = {
    isVisible: boolean;
  };

  type BalanceVertical = 'sport' | 'casino' | 'liveCasino' | 'other';

  type BalanceKeysList = (keyof Balance)[];

  type myAccountConfig = {
    hasOddsSettingsDropdown?: boolean;
  } | null;

  type MyProfileConfig = {
    balanceMap: Record<BalanceVertical, BalanceKeysList>;
    layout: 'layout_1' | 'layout_2';
    isVisible: boolean;
    balance: boolean;
    enableThemeSwitcher: boolean;
    navigationTabs: MyProfileTabType[];
    realityCheck?: RealityCheckType;
    inactivityCheck?: InactivityCheckType;
    menus: Record<SectionWithConfig, { subMenu: AccountTypes.Section[] }>;
    hideDepositButton: boolean;
    tombolaIndicator?: boolean;
    appVersionCheckInterval?: number;
  };

  type RealityCheckType = {
    enabled: boolean;
    interval: number;
    showLogoutButton?: boolean;
  };

  type InactivityCheckType = {
    interval: number;
    timeout?: {
      type?: string;
      time?: number;
    };
  };

  type MyProfileRoutes = {
    key: string | number;
    label: string;
    route?: string;
    icon?: string;
    iconColor?: string;
    hasBadge?: boolean;
    isInRow?: boolean;
    isExpanded?: boolean;
    version?: string;
    subMenu?: MyProfileRoutes[];
    isNew?: boolean;
  };

  type NavigationConfig = {
    topNav: boolean;
    topNavInitialCollapsed: boolean;
    subNav: SubNav;
    subNavContent: SubNavContentConfig;
    inactiveSubRoutes?: string[];
  };

  type NavigationLayoutConfig = {
    layoutType: string;
  };

  type SubNav = {
    isVisible: boolean;
    search: boolean;
    registration: boolean;
    signIn: boolean;
    balance: boolean;
    defaultUser: boolean;
    deposit: boolean;
    programButton: boolean;
  };
  type TextProps = {
    color: string;
    fontSize: string;
    text: string;
  };

  type Banner = {
    style: {
      height: number;
      bgImage: string;
      bgColor: string;
      textBlockWidth: string;
      textBlockAlignment: 'left' | 'right' | 'center';
    };
    content: {
      title: TextProps;
      subtitle: TextProps;
    };
  };

  type NavButtons = 'registration' | 'signIn';

  type SubNavContentConfig = Record<
    NavButtons,
    {
      button?: {
        order?: number;
      };
      banner?: Banner;
    }
  >;

  type TickerItem = {
    vertical_id: number;
    speed: number;
    visible: boolean;
  };

  type Ticker = {
    [key: string]: TickerItem;
  };

  type VerticalsConfig = {
    homePageURL: string;
    newUserHomePageUrl?: string;
    notFoundURL?: string;
    inactiveSubRoutes: string[];
    gamification?: {
      isVisible: boolean;
      sidebarTabs: GamificationFilter[];
      showPlayerLevel: boolean;
      route: string;
      isWidget?: boolean;
      showPlayerBalance?: boolean;
      [WidgetInitTypes.GAMIFICATION]?: { isVisible: boolean };
      [WidgetInitTypes.LOTTO]?: { isVisible: boolean };
    };
    iFrameVertical?: { isVisible?: boolean; route: string };
    smartico?: {
      isVisible: boolean;
      showOpenButton: boolean;
      showPlayerBalance?: boolean;
      config: { labelKey: string; brandKey: string; scriptSrc?: string };
    };
    newRibbon?: boolean;
    hideHomePageAppVersion: string;
    appVersionHomePage: string;
    configVerticals: ConfigVerticals;
  };

  type ConfigVerticals = {
    verticalsMenu?: string[];
    myBets?: boolean;
    claimTicket?: boolean;
  };

  type SliderPosition = 'top' | 'default';
  type HighlightFeatureConfig = {
    show: boolean;
    feature: HighlightFeature[];
  };
  type HighlightFeature = 'holdToBet' | 'collapsedHeader';

  type Notifications = {
    carousel: NotificationsCarousel;
  };

  type NotificationsCarousel = {
    delay: number;
  };

  type TombolaIndicator = {
    show: boolean;
    folder: string;
    route: string;
    apiUrl: string;
    isStatic?: boolean;
    navigationUrls: Record<number, string>;
  };

  type LotteryPage = {
    url: string;
    hasLottery: boolean;
  };

  type SportsBookWidget = {
    type: 'egtd' | 'altenar';
  };

  type RetailHeaderButtonsVisibilityTypes = {
    checkTicket: boolean;
    deposit: boolean;
    cashout: boolean;
    createVoucher: boolean;
    login: boolean;
    logout?: boolean;
    lastPrint?: boolean;
    finance?: boolean;
    cashPay?: boolean;
    printProgram?: boolean;
    voucherMenu?: boolean;
    isAnexReport?: boolean;
    isDuplicateButtonActive?: boolean;
    isPosAndTerminalInfo?: boolean;
    isReprintButtonActive?: boolean;
    isLimitsAndDepositActive?: boolean;
  };
}

type CurrencyDisplayConfig = {
  currencySymbolFirst?: boolean;
  spaceBetween?: boolean;
  formatToComma?: boolean;
};

export enum LayoutConfigTypes {
  NAVIGATION = 'navigation',
  MYPROFILE = 'myProfile',
  MY_ACCOUNT = 'myAccount',
  TICKER = 'ticker',
  JACKPOTS = 'jackpots',
  CASINO = 'casino',
  CLIENT_AGGREGATOR_GAME_LAUNCH = 'clientAggregatorGameLaunch',
  AGGREGATOR_GAME_LAUNCH = 'casinoAggregatorGameLaunch',
  VERTICALS = 'verticals',
  HEADER = 'header',
  AFFILIATE_EXPIRES = 'affiliateExpires',
  TRACKING_EVENTS = 'trackingEvents',
  LAYOUTTYPECONFIG = 'layoutTypeConfig',
  PLAYER_RESTRICTIONS = 'playerRestrictions',
  PLAYER_HISTORY = 'playerHistory',
  SUPPORT_CHAT = 'supportChat',
  SPACEBETWEEN = 'spaceBetween',
  DRAWER = 'drawer',
  POLLING = 'polling',
  POLLING_GEOLOCATION_CHECK = 'pollingGeoLocationCheck',
  SPORT_WIDGET = 'sportWidget',
  LOGIN_REGISTER_SHOW = 'loginRegisterShow',
  RETAIL_HEADER_BUTTONS_VISIBLE = 'retailHeaderButtonsVisible',
  RETAIL_ADDRESS = 'retailAddress',
  RETAIL_DEVICE = 'retailDevice',
  RETAIL_LOGIN_KEYBOARD = 'loginRetailKeyboard',
  RETAIL_GAMES = 'retailGames',
  IS_MAGNUM_BET_RO = 'isMagnumBetRo',
  IS_BET_AMOUNT_VISIBLE = 'isBetAmountVisible',
  EVENT = 'event',
  PLAYTECH_POKER_LOGIN = 'playtechPokerLogin',
  PLAYTECH_POKER_COOKIES_CONSENT = 'hideCookiesConsentFromPoker',
  FANTASY_LEAGUE = 'fantasyLeague',
  FANTASY_STARS = 'fantasyStars',
  SVARA_APP_LOGIN = 'svaraAppLogin',
  SVARA_APP_COOKIES_CONSENT = 'hideCookiesConsentFromSvara',
  CASINO_WIDGET_CONFIG = 'casinoWidgetConfig',
  //from terminal object
  TERMINAL = 'terminal',
  HIGHLIGHTFEATURE = 'highlightFeature',
  INSUFFICIENT_BALANCE = 'insufficientBalance',
  SITEMAPTAGS = 'sitemapTags',
  REQUEST_BET = 'requestBet',
  DRAWER_TOGGLER = 'drawerToggler',
  ZENDESK_CHAT_CONFIG = 'zendeskChatConfig',
  AIVO_CHAT_CONFIG = 'aivoChatConfig',
  LIVE_CHAT_CONFIG = 'liveChatConfig',
  NOTIFICATIONS = 'notifications',
  FAVOURITESCONFIG = 'favouritesConfig',
  FOOTERCONFIG = 'footerConfig',
  PRINT_PROGRAM = 'printProgram',
  HIDE_COOKIE = 'hideCookie',
  ICONS_TYPE = 'iconsType',
  TICKET_CODE_LINEAR = 'isTicketCodeLinear',
  TOMBOLA_INDICATOR = 'tombolaIndicator',
  LOTTERY = 'lottery',
  OFFERSTOGGLER = 'offersToggler',
  APP_LOGO = 'appLogo',
  SPORTS_BOOK_WIDGET = 'sportsBookWidget',
  Main_Nav_Bar_Buttons = 'mainNavBarButtons',
  GAME_LAUNCH_TOP_BAR = 'gameLaunchTopBar',
  PHONE_CONFIRMATION = 'phoneConfirmation',
  CURRENCY_BEFORE_AMOUNT = 'currencyBeforeAmount',
  CURRENCY_DISPLAY = 'currencyDisplay',
  AUTH_BANNER = 'authBanner',
  CASHIER_NEW = 'cashierNewImplementation',
}

export enum VerticalsConfigTypes {
  GAMIFICATION = 'gamification',
  SMARTICO = 'smartico',
  IFRAMEVERTICAL = 'iFrameVertical',
}

export type GameLauncherTopBarButtonTypes =
  | 'quickDeposit'
  | 'prizeDrop'
  | 'jackpotCampaign'
  | 'casinoBonusInfo'
  | 'casinoBonusOffersInfo'
  | 'challenges'
  | 'campaignMissionProgress'
  | 'gameCellFav'
  | 'tournaments'
  | 'lastPlayedGames'
  | 'recommendedGames'
  | 'exitGame'
  | 'search';

export type EventConfig = {
  isFavoriteOnHover: boolean;
  showStreamIcon: boolean;
};

export type DeviceTypeInitialState = {
  deviceType: DeviceType;
};

export type TerminalConfig = {
  redirectTimeout: number;
};
