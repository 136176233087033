import { useTranslation } from 'react-i18next';
import { isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import { Promotions } from 'modules/casino/shared/utils/translationUtils/translationStrings';
import NoDataMessage from './NoDataMessage';
import RewardItem from './RewardItem';
import { prizeDropCSSNS } from '../../PrizeDropCellWidgetConstants';
import { PrizeDropWidgetTypes } from '../../PrizeDropCellWidgetTypes';

type Props = {
  rewards: PrizeDropWidgetTypes.Reward[];
};

const RewardsContainer: React.FC<Props> = ({ rewards }) => {
  const { t } = useTranslation();

  if (isEmpty(rewards)) {
    return <NoDataMessage message={t(Promotions.PrizeDrop.noRewards)} />;
  }

  return (
    <div className={`${prizeDropCSSNS}-widget__campaigns--wrapper no-scrollbars`}>
      {rewards?.map((reward, index) => (
        <RewardItem
          key={`${reward.bonusName || ''}-${index}`}
          title={reward.bonusName || ''}
          givenAwards={reward.givenAwards}
          totalAwards={reward.totalAwards}
        />
      ))}
    </div>
  );
};

export default RewardsContainer;
