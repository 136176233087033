import React, { Fragment } from 'react';
import userStorage from 'pages/auth/login/UserStorage';
import { isProductionBuild } from 'utils/common/helpersCommon';
import { ACTIVE_BU_DEV } from 'utils/config';
import { SCSwitcher } from './Switcher.styled';

const groups = [
  { label: 'BETHUB NRA', value: 'BHBGNRA', language: 'en', device: '' },
  { label: 'BETHUB', value: 'BHBG', language: 'en', device: '' },
  { label: 'EGT-TODAY-RETAIL', value: 'EGTT', device: 'SSBT', language: 'en' },
  { label: 'EGT-TODAY', value: 'EGTT', device: '', language: 'en' },
  { label: 'EGTT-RETAIL-CASHIER', value: 'EGTT', device: 'CASHIER', language: 'en' },
  { label: 'ESGLI', value: 'ESGLI', device: '', language: 'es' },
  { label: 'FTMX', value: 'FTMX', device: '', language: 'en' },
  { label: 'IBBG', value: 'IBBG', device: '', language: 'bg' },
  { label: 'INBET-RETAIL-CASHIER', value: 'IBBGRT', device: 'CASHIER', language: 'en' },
  { label: 'INBET-RETAIL-ONLINE', value: 'IBBGRT', device: 'ONLINE', language: 'en' },
  { label: 'INBET-RETAIL-SSBT', value: 'IBBGRT', device: 'SSBT', language: 'en' },
  { label: 'INBETNRA-RETAIL-CASHIER', value: 'IBBGRTNRA', device: 'CASHIER', language: 'en' },
  { label: 'INBETNRA-RETAIL-ONLINE', value: 'IBBGRTNRA', device: 'ONLINE', language: 'en' },
  { label: 'LSRO', value: 'LSRO', device: '', language: 'en' },
  { label: 'MAGNUM BET', value: 'MBRO', device: '', language: 'en' },
  { label: 'RJBR', value: 'RJBR', device: '', language: 'en' },
  { label: 'MGBG', value: 'MGBG', device: '', language: 'bg' },
  { label: 'SESAME', value: 'SMBG', device: '', language: 'bg' },
  { label: 'SESAME-RETAIL-CASHIER', value: 'SMBGRT', device: 'CASHIER', language: 'en' },
  { label: 'SESAME-RETAIL-ONLINE', value: 'SMBGRT', device: 'ONLINE', language: 'en' },
  { label: 'VVRO', value: 'VVRO', device: '', language: 'en' },
  { label: 'WBBG', value: 'WBBG', device: '', language: 'bg' },
  { label: 'WBRO', value: 'WBRO', device: '', language: 'en' },
  { label: 'OCRO', value: 'OCRO', device: '', language: 'en' },
];
const subgroups = ['DEV', 'INT', 'UAT', 'PRODUCTION'];
const getLabel = (label: string, subgr: string) => `${label} @ ${subgr.toUpperCase()}`;
const getValue = (value: string, subgr: string, device?: string) =>
  device === '' ? `${value}_${subgr}` : `${value}_${subgr}_RETAIL_${device}`;

const DevAPISourceSwitcher: React.FC = () => {
  if (isProductionBuild) return null;

  const handleAPISourceChange = (e) => {
    const userLang = userStorage.getUserLang();
    const buValue = e.target.value.slice(0, 2);
    const buLang = groups.find((gr) => gr.value === buValue)?.language || 'en';
    buLang !== userLang && userStorage.setUserLang(buLang);

    sessionStorage.setItem('devAPISource', e.target.value);
    window.location.reload();
  };

  return (
    <SCSwitcher onChange={handleAPISourceChange} value={ACTIVE_BU_DEV}>
      {groups.map((group) => (
        <Fragment key={group.label}>
          <optgroup label={group.label} />
          {subgroups.map((subgr) => (
            <option key={subgr} value={getValue(group.value, subgr, group?.device)}>
              {getLabel(group.label, subgr)}
            </option>
          ))}
        </Fragment>
      ))}
    </SCSwitcher>
  );
};

export default DevAPISourceSwitcher;
