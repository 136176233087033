import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { DrawerUI } from './DrawerUI';
import useHandleDrawerDefaultClick from '../hooks/useHandleDrawerDefaultClick';
import { SportsType } from '../types/drawer.types';

interface Props {
  navRoute?: { route: string; state? };
  icon?: string;
  sportType: SportsType;
}

const DrawerNavLinkDefault: React.FC<Props> = ({ navRoute, icon, sportType }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const { handleClick } = useHandleDrawerDefaultClick({ sportType });

  return (
    <DrawerUI.Link
      onClick={handleClick}
      isActive={navRoute?.state?.isActive || location.pathname === navRoute?.route}
      icon={icon}
      text={t('Drawer.DrawerNavLink.home')}
      dataQid={navRoute?.route}
    />
  );
};

export default memo(DrawerNavLinkDefault);
