import { useCategoryGames } from './useCategoryGames';
import { CategoryLayoutTypes } from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { isWidget } from '../utils/casinoGamesUtils';

export const useShowWidget = ({
  gameUniqueId,
  categoryId,
  categoryLayout,
}: {
  gameUniqueId: string;
  categoryId: string;
  categoryLayout: CategoryLayoutTypes;
}): { showWidget: boolean } => {
  const items = useCategoryGames({ categoryLayoutType: categoryLayout, categoryId });
  const showWidget = isWidget(items[gameUniqueId]);

  return { showWidget };
};
