import React from 'react';
import styled from 'styled-components';
import { useAuthentication } from 'hooks/common/useAuthentication';
import { useNavigateTo } from 'hooks/navigation';
import useRequestBetCallback from 'shared/common/features/requestBet/hooks/useRequestBetCallback';
import { Breakpoints } from 'theme/Theme';
import { usePromotionImgResource } from '../../hooks/usePromotionImgResource';

/** PromoFeed Item */
interface ItemProps {
  title: string;
  content: string;
  subTitle?: string;
  alias: string;
  bg: string;
  category: string;
  type?: 'REQUEST_BET';
}

const StyledItem = styled.div<{ $bg?: string }>`
  flex: 0 0 280px;
  display: flex;
  align-items: center;
  margin-right: 6px;
  padding: 16px 8px;
  min-height: 80px;
  background: ${({ $bg }) => `url(${$bg})`};
  background-size: cover;
  background-position: center right;
  border-radius: 3px;
  @media screen and (min-width: ${Breakpoints.isMobile}px) {
    margin-top: 0;
    min-height: 100px;
    padding: 5px 12px;
  }
`;
StyledItem.displayName = 'Item.Wrappaer';

const StyledItemTitle = styled.span`
  max-width: 190px;
  font-size: 13px;
  @media screen and (min-width: ${Breakpoints.isMobile}px) {
    max-width: 190px;
    font-size: 14px;
  }
`;
StyledItemTitle.displayName = 'Item.Title';

const StyledItemBody = styled.span`
  max-width: 154px;
  font-size: 11px;
  margin-top: 8px;
  max-width: 66%;
  @media screen and (min-width: ${Breakpoints.isMobile}px) {
    max-width: 190px;
    font-size: 12px;
    margin-top: 10px;
  }
`;
StyledItemBody.displayName = 'Item.Body';

const Item: React.FC<ItemProps> = ({ type, title, content, alias, bg, subTitle, category = 'general' }) => {
  const { isAuthenticated } = useAuthentication();
  const { cellRef, imgSource } = usePromotionImgResource(bg, 'none');

  const navigateTo = useNavigateTo();
  const { requestBetCallback } = useRequestBetCallback();

  const handleClick = () => {
    if (type === 'REQUEST_BET') {
      requestBetCallback({ type: 'requestBet', isAuthenticated: !!isAuthenticated });
    } else {
      navigateTo.navigate(`/promotions/${category || 'general'}/${alias}`);
    }
  };

  return (
    <StyledItem
      onClick={handleClick}
      $bg={imgSource}
      ref={cellRef}
      className="promos-feed__item overflow-hidden"
      data-text-on-img
    >
      <div className="d-flex-col wh-100">
        {title && <StyledItemTitle className="text-truncate promos-feed__item-title">{title}</StyledItemTitle>}
        {content && <StyledItemBody className="overflow-hidden promos-feed__item-body">{content}</StyledItemBody>}
        {!content && subTitle && (
          <StyledItemBody className="overflow-hidden promos-feed__item-body">{subTitle}</StyledItemBody>
        )}
      </div>
    </StyledItem>
  );
};
Item.displayName = 'PromosFeed.Item';
export default Item;
