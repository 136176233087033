import React from 'react';
import { useCurrency } from 'hooks/common/useCurrency';
import { CasinoGamesTypes } from 'modules/casino/modules/container/cellsContainer/cellls/types/casinoGamesTypes';

const GameCellLimits: React.FC<{ betLimits: CasinoGamesTypes.CasinoBetLimits }> = ({ betLimits }) => {
  const { currencyCode } = useCurrency();
  if (!currencyCode) return null;
  if (
    !betLimits[currencyCode!] ||
    !betLimits[currencyCode!].min ||
    !betLimits[currencyCode!].max ||
    betLimits[currencyCode!].min >= betLimits[currencyCode!].max
  ) {
    return null;
  }

  const min = betLimits[currencyCode!].min;
  const max = betLimits[currencyCode!].max;

  const format = (number: number) => {
    return number < 10 ? number.toFixed(2) : number;
  };

  return (
    <div className={`casino-game-cell__limits-bet`}>
      {format(min)} - {format(max)}
    </div>
  );
};

export default GameCellLimits;
GameCellLimits.displayName = 'GameCellLimits';
