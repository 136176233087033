import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  ActiveBonusTag,
  BonusTag,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/GameCellTagsTypes';
import { GameType, LiveCasinoGameType, RequestStatuses } from 'modules/casino/shared/constants';
import axiosInstance from 'modules/casino/shared/utils/common/axios-instance';
import { sortByProperty } from 'modules/casino/shared/utils/common/helpersCommon';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import config from 'utils/config';
import { GameLaunchBonusTypes } from '../types/gameLaunchBonus.types';
import { getGamesWithPrizeDrop } from '../utils/gameLaunchBonus.utils';

export const gameLaunchBonusThunk: GameLaunchBonusTypes.GameLaunchBonusThunk = {
  fetchBonusGames: createAbortThunk('gameLaunchBonus/fetchBonusGames', async (_, { source }) => {
    const fetchUrl = `${config.API_URL}/api/ews-bonuses/player/players/offers/games`;
    const response = await axiosInstance.get<GameLaunchBonusTypes.BonusGamesResponse>(fetchUrl, {
      cancelToken: source.token,
    });

    return response.data;
  }),

  fetchGamesChallenges: createAbortThunk('gameLaunchBonus/fetchGamesChallenges', async (gameId, { source }) => {
    const fetchUrl = `${config.API_URL}/api/ews-game-journey/player/missions/progress/${gameId}`;
    const response = await axiosInstance.get<GameLaunchBonusTypes.Challenge[]>(fetchUrl, {
      cancelToken: source.token,
    });

    return response.data;
  }),
  updateGamesChallenges: createAbortThunk('gameLaunchBonus/updateGamesChallenges', async (challengeIds) => {
    const fetchURL = `${config.API_URL}/api/ews-game-journey/player/missions`;
    const response = await axiosInstance.put(fetchURL, challengeIds);

    return response.data;
  }),

  fetchJackpotCampaign: createAbortThunk(
    'gameLaunchBonus/fetchJackpotCampaign',
    async ({ jackpotCampaignId, gameId }, { source }) => {
      const fetchUrl = `${config.API_URL}/api/ews-bonuses/player/players/jackpot-campaigns/${jackpotCampaignId}/games/${gameId}`;
      const response = await axiosInstance.get<GameLaunchBonusTypes.JackpotCampaignResponse>(fetchUrl, {
        cancelToken: source.token,
      });

      return response.data;
    },
  ),
  fetchMissionCollectionProgress: createAbortThunk(
    'gameLaunchBonus/fetchMissionCollectionProgress',
    async (_, { source }) => {
      const fetchUrl = `${config.API_URL}/api/ews-game-journey/player/campaign-mission-progress`;
      const response = await axiosInstance.get<GameLaunchBonusTypes.MissionCollectionsResponse>(fetchUrl, {
        cancelToken: source.token,
      });

      return response.data;
    },
  ),
};

export const {
  fetchBonusGames,
  fetchGamesChallenges,
  updateGamesChallenges,
  fetchJackpotCampaign,
  fetchMissionCollectionProgress,
} = gameLaunchBonusThunk;

const initialState: GameLaunchBonusTypes.State = {
  runBonusFetchInterval: false,
  gamesWithPrizeDrop: [],
  gamesWithJackpot: [],
  jackpotCampaign: {
    campaignId: null,
    campaignFetchStatus: RequestStatuses.INITIAL,
    details: null,
  },
  bonusGamesFetchStatus: RequestStatuses.INITIAL,
  challengesFetchStatus: RequestStatuses.INITIAL,
  missionCollections: {
    status: RequestStatuses.INITIAL,
    data: {},
  },
  bonusTagGames: [],
  challengesGames: [],
  ttl: Date.now(),
};

const casinoGamesBonus = createSlice({
  name: 'gameLaunchBonus',
  initialState,
  reducers: {
    setBonusTagFetchInterval(state, action: PayloadAction<boolean>) {
      const prevValue = state.runBonusFetchInterval;
      const newValue = action.payload;

      if (prevValue !== newValue) {
        state.runBonusFetchInterval = action.payload;
      }
    },

    resetChallenge(state) {
      state.challengesGames = [];
      state.challengesFetchStatus = RequestStatuses.INITIAL;
    },

    resetJackpotCampaignFetchStatus(state) {
      if (state.jackpotCampaign.campaignFetchStatus !== RequestStatuses.INITIAL) {
        state.jackpotCampaign.campaignFetchStatus = RequestStatuses.INITIAL;
      }
    },
    resetMissionCollections(state) {
      state.missionCollections.status = RequestStatuses.INITIAL;
      state.missionCollections.data = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBonusGames.fulfilled, (state, action: PayloadAction<GameLaunchBonusTypes.BonusGamesResponse>) => {
        const data = action.payload;
        if (data?.activePrizeDropCampaigns?.length) {
          state.gamesWithPrizeDrop = getGamesWithPrizeDrop(data.activePrizeDropCampaigns);
        }

        if (data?.activeJackpotCampaigns[0]?.games) {
          state.gamesWithJackpot = [...data.activeJackpotCampaigns[0].games];
          state.jackpotCampaign.campaignId = data.activeJackpotCampaigns[0]?.campaignId;
        }
        state.bonusGamesFetchStatus = RequestStatuses.SUCCESS;

        if (data?.activeBonuses) {
          const sortedData = sortByProperty([...data.activeBonuses], 'rank');
          state.bonusTagGames = sortedData;
          state.ttl = Date.now();
        }
      })
      .addCase(fetchBonusGames.pending, (state) => {
        state.bonusGamesFetchStatus = RequestStatuses.PENDING;
      })
      .addCase(fetchBonusGames.rejected, (state) => {
        state.bonusGamesFetchStatus = RequestStatuses.ERROR;
        state.bonusTagGames = [];
      })
      .addCase(fetchGamesChallenges.fulfilled, (state, action: PayloadAction<GameLaunchBonusTypes.Challenge[]>) => {
        const data = action.payload;

        state.challengesGames = data;
        state.challengesFetchStatus = RequestStatuses.SUCCESS;
      })
      .addCase(fetchGamesChallenges.pending, (state) => {
        state.challengesFetchStatus = RequestStatuses.PENDING;
      })
      .addCase(fetchGamesChallenges.rejected, (state) => {
        state.challengesGames = [];
        state.challengesFetchStatus = RequestStatuses.ERROR;
      })
      .addCase(updateGamesChallenges.fulfilled, (state, action) => {
        const challengeIds = action.meta.arg;

        const currChallengesNew = state.challengesGames;
        challengeIds.map((id) => {
          const index = currChallengesNew.findIndex((item) => item.playerMissionId === id.playerMissionId);
          index >= 0 && (currChallengesNew[index]['isRead'] = true);
        });
        state.challengesGames = currChallengesNew;
      })
      .addCase(
        fetchJackpotCampaign.fulfilled,
        (state, action: PayloadAction<GameLaunchBonusTypes.JackpotCampaignResponse>) => {
          state.jackpotCampaign.campaignFetchStatus = RequestStatuses.SUCCESS;
          state.jackpotCampaign.details = { ...action.payload };
        },
      )
      .addCase(fetchJackpotCampaign.pending, (state) => {
        state.jackpotCampaign.campaignFetchStatus = RequestStatuses.PENDING;
      })
      .addCase(fetchJackpotCampaign.rejected, (state) => {
        state.jackpotCampaign.campaignFetchStatus = RequestStatuses.ERROR;
      })
      .addCase(
        fetchMissionCollectionProgress.fulfilled,
        (state, action: PayloadAction<GameLaunchBonusTypes.MissionCollectionsResponse>) => {
          state.missionCollections.status = RequestStatuses.SUCCESS;
          state.missionCollections.data = { ...action.payload };
        },
      )
      .addCase(fetchMissionCollectionProgress.pending, (state) => {
        state.missionCollections.status = RequestStatuses.PENDING;
      })
      .addCase(fetchMissionCollectionProgress.rejected, (state) => {
        state.missionCollections.status = RequestStatuses.ERROR;
      });

    builder.addCase(logoutUser, (state) => {
      state.bonusTagGames = [];
      state.challengesGames = [];
    });
  },
});

export const { setBonusTagFetchInterval, resetChallenge, resetJackpotCampaignFetchStatus, resetMissionCollections } =
  casinoGamesBonus.actions;

export const { reducer: casinoGamesBonusReducer } = casinoGamesBonus;

const selectBonusFetchInterval = (state: RootState): boolean => state.casino.casinoGamesBonus.runBonusFetchInterval;

const selectActiveBonusTag = createSelector(
  (state: RootState) => state.casino.casinoGamesBonus.bonusTagGames,
  (state: RootState, gameId: string) => gameId,
  (state: RootState, gameId: string, gameType: GameType) => gameType,
  (bonusTagGames: BonusTag[], gameId: string, gameType: GameType): ActiveBonusTag | undefined => {
    const verticalType = LiveCasinoGameType[gameType] ? 'LiveCasino' : 'Casino';
    const bonusTag = bonusTagGames?.find(
      (item) => item?.games?.includes(gameId) && item?.bonusVerticals?.includes(verticalType),
    );

    return (
      bonusTag && {
        bonusName: bonusTag.bonusName,
        bonusType: bonusTag.bonusType,
      }
    );
  },
);
const selectAllGamesWithPrizeDrop = (state: RootState) => state.casino.casinoGamesBonus.gamesWithPrizeDrop;
const selectAllGamesWithJackpot = (state: RootState) => state.casino.casinoGamesBonus.gamesWithJackpot;
const selectBonusGamesFetchStatus = (state: RootState) => state.casino.casinoGamesBonus.bonusGamesFetchStatus;
const selectChallengesFetchStatus = (state: RootState) => state.casino.casinoGamesBonus.challengesFetchStatus;
const selectMissionsCollectionFetchStatus = (state: RootState) =>
  state.casino.casinoGamesBonus.missionCollections.status;
const selectGameChallenges = (state: RootState) => state.casino.casinoGamesBonus.challengesGames;
const selectMissionsCollection = (state: RootState) => state.casino.casinoGamesBonus.missionCollections.data;
const selectTTL = (state: RootState): number => state.casino.casinoGamesBonus.ttl;
const selectIsGameWithPrizeDrop = createSelector(
  selectAllGamesWithPrizeDrop,
  (state: RootState, gameId: string) => gameId,
  (games, gameId) => {
    if (games.includes(gameId)) return true;
    else return false;
  },
);
const selectIsGameWithJackpot = createSelector(
  selectAllGamesWithJackpot,
  (state: RootState, gameId: string) => gameId,
  (games, gameId) => {
    if (games.includes(gameId)) return true;
    else return false;
  },
);
const selectJackpotCampaignId = (state: RootState) => state.casino.casinoGamesBonus.jackpotCampaign.campaignId;
const selectJackpotCampaignDetails = (state: RootState) => state.casino.casinoGamesBonus.jackpotCampaign.details;
const selectJackpotCampaignFetchStatus = (state: RootState) =>
  state.casino.casinoGamesBonus.jackpotCampaign.campaignFetchStatus;

export const gamesBonusSelectors = {
  bonusFetchInterval: selectBonusFetchInterval,
  activeBonusTag: selectActiveBonusTag,
  gamesWithPrizeDrops: selectAllGamesWithPrizeDrop,
  bonusGamesFetchStatus: selectBonusGamesFetchStatus,
  challengesFetchStatus: selectChallengesFetchStatus,
  challenges: selectGameChallenges,
  isGameWithPrizeDrop: selectIsGameWithPrizeDrop,
  isGameWithJackpot: selectIsGameWithJackpot,
  jackpotCampaignId: selectJackpotCampaignId,
  jackpotCampaignDetails: selectJackpotCampaignDetails,
  jackpotCampaignFetchStatus: selectJackpotCampaignFetchStatus,
  ttl: selectTTL,
  missionsCollection: selectMissionsCollection,
  missionsCollectionFetchStatus: selectMissionsCollectionFetchStatus,
};
