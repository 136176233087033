import { memo } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useCDNIconSprites } from 'hooks/common/useCDNIconSprites';
import { imagesConfig } from 'pages/captainUp/utils/captainUp.utils';
import { General } from 'shared/common/features/general/types/generalSlice.types';
import useSmartico from 'shared/common/features/integrations/smartico/hooks/useSmartico';
import { useAppDispatch } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { MPDUI } from '../../../components/MPDUI';
import { showMyProfileModal } from '../../../slice/myProfile.slice';
import { logoutAction } from '../../../utils/myProfile.utils';
import useTransitionTabs from '../hooks/useTransitionTabs';

const SmarticoTab: React.FC<{ subMenu?: General.MyProfileRoutes[] | null }> = ({ subMenu }) => {
  useCDNIconSprites('cuIcons', 'cu_icons_timestamp', 'cu-icons', `${imagesConfig.CAPTAIN_UP_ICONS}`);
  const { openMainWidget } = useSmartico();
  const { initialOpacity, controls } = useTransitionTabs(subMenu);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleLogout = () => logoutAction(dispatch);

  const handleTabClick = (key: string | number) => {
    openMainWidget(`${key}`);
    dispatch(showMyProfileModal(false));
  };

  if (isEmpty(subMenu)) return null;

  const navigation = subMenu?.filter((item) => item?.key !== 'logout');
  const logout = subMenu?.find((item) => item?.key === 'logout');

  return (
    <>
      {navigation?.map((tab, idx) => (
        <motion.div initial={{ opacity: initialOpacity }} key={tab.key} custom={idx} animate={controls}>
          <MPDUI.NavItemInRow
            key={tab.key}
            onClick={() => handleTabClick(tab.key)}
            dataQid={`${tab.key}`}
            dataCpp={`${tab.key}`}
            text={t(tab.label)}
            icon={tab.icon}
            iconColor={tab.iconColor}
          />
        </motion.div>
      ))}

      {logout && (
        <>
          <div className="cu-nav__item--distance" />
          <MPDUI.NavItemRow
            key={logout.key}
            onClick={handleLogout}
            dataQid="logout"
            dataCpp="logout"
            text={t(logout.label)}
            icon={logout.icon}
            iconColor={logout.iconColor}
          />
        </>
      )}
    </>
  );
};

export default memo(SmarticoTab);
