import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { useMarketingKeys } from 'hooks/common/useMarketingKeys';
import { useAppDispatch } from 'store';
import { LayoutConfigTypes } from '../features/general/types/generalSlice.types';

declare const window: Window &
  typeof globalThis & {
    $aivo: {
      launcher: { show: () => void; hide: () => void; hideSticky: (shouldHide: boolean) => void };
      ready: (callback: () => void) => void;
    };
  };

const aivoAttr = 'aivo-script';
const isScriptAttached = (attr: string): boolean => document.querySelectorAll(`script[n="${attr}"]`).length > 0;

const AIVOChat = () => {
  const dispatch = useAppDispatch();
  const [isReady, setIsReady] = useState(false);

  const { pathname } = useLocation();
  const { AIVO_KEY } = useMarketingKeys();

  const restrictedRoutes = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.AIVO_CHAT_CONFIG,
  })?.restrictedRoutes;

  useLayoutEffect(() => {
    if (isReady && window.$aivo) {
      if (restrictedRoutes?.some((route) => pathname?.startsWith(route))) {
        window.$aivo.launcher.hide();
        window.$aivo.launcher.hideSticky(true);
      } else {
        window.$aivo.launcher.hideSticky(false);
        window.$aivo.launcher.show();
      }
    }
  }, [pathname, isReady, restrictedRoutes]);

  useEffect(() => {
    if (AIVO_KEY && !isReady && !isScriptAttached(aivoAttr)) {
      if (!window?.$aivo) {
        let script: HTMLScriptElement | null = null;
        if (!script) {
          script = document.createElement('script');
          script.src = `https://cdn.agentbot.net/core/${AIVO_KEY}.js`;
          script.setAttribute('n', aivoAttr);
          script.onload = () => {
            window.$aivo.ready(() => setTimeout(() => setIsReady(true), 500));
          };
          document.getElementsByTagName('head')[0].appendChild(script);
        }
      }
    }
  }, [AIVO_KEY, dispatch, isReady]);

  return null;
};

export default AIVOChat;
