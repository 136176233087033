import React from 'react';
import Icon from 'components/shared/Icon';
import PrizeDropTitle from './PrizeDropTitle';
import { prizeDropCSSNS } from '../prizeDropUICSS.types';

type Props = {
  title: string;
  size?: 'sm' | 'normal' | 'lg';
  iconResource?: string;
  isCenter?: boolean;
  showInfo?: boolean;
  showBack?: boolean;
  handleBack?: () => void;
  showClose?: boolean;
  handleClose?: () => void;
  handleInfo?: () => void;
  className?: string;
};

const PrizeDropHeader: React.FC<Props> = ({
  title,
  iconResource,
  isCenter,
  size,
  handleBack,
  handleClose,
  handleInfo,
  className,
}) => {
  const classProp = { class: className && `${className}` };

  return (
    <egtd-przd-header {...classProp}>
      {handleInfo && <Icon onClick={handleInfo} icon={'info-solid'} className={`${prizeDropCSSNS}-header-icon`} />}

      {handleBack && (
        <Icon icon={'egtd-przd-arrow-left'} className={`${prizeDropCSSNS}-header-icon`} onClick={handleBack} />
      )}

      <PrizeDropTitle title={title} iconResource={iconResource} size={size} isCenter={isCenter} />

      {handleClose && <Icon icon={'times'} className={`${prizeDropCSSNS}-header-icon`} onClick={handleClose} />}
    </egtd-przd-header>
  );
};

export default PrizeDropHeader;
