import React from 'react';
import { useTranslation } from 'react-i18next';
import { NoDataMessage } from 'components/shared';
import { BurgerUI } from 'shared/common/features/burger/components/BurgerUI';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { SportTypeLobby } from 'utils/common/translationUtils/translationStrings';
import { useGetDrawerLiveSport } from './hooks/useGetDrawerLiveSport';
import useHandleDrawerSportClick from '../../hooks/useHandleDrawerSportClick';
import { selectDrawer } from '../../slice/drawer.slice';
import { SportsType } from '../../types/drawer.types';

const LiveSportDrawerSectionLayout02 = () => {
  const { t } = useTranslation();
  const { items, activeItem } = useGetDrawerLiveSport();
  const { handleSportClick } = useHandleDrawerSportClick();
  const liveSportsState = useAppSelector(selectDrawer.liveSportsState);

  return (
    <>
      {!isEmpty(items) && (
        <BurgerUI.TilesGrid>
          {items.map((tile, index) => (
            <BurgerUI.SportTile
              key={index}
              icon={tile.id.toString()}
              text={tile.name}
              isActive={tile.id === activeItem}
              liveBadgeText={tile.liveEventsCount.toString()}
              onClick={() => handleSportClick({ sportType: SportsType.LiveSport, sportId: tile?.id })}
            />
          ))}
        </BurgerUI.TilesGrid>
      )}
      {liveSportsState === 'empty' && <NoDataMessage message={t(SportTypeLobby.Messages.noEventsText)} />}
    </>
  );
};

export default LiveSportDrawerSectionLayout02;
