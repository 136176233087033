import React, { createContext, useMemo } from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';

interface CTAProps {
  showContent: boolean;
  children: React.ReactNode;
  className: string;
}

export const CTAContext = createContext<CTAProps | null>(null);

export const Wrapper = (props): JSX.Element => {
  const value = useMemo(() => props, []);
  const mpdContainerStyles = clsx(
    'mpd-offers-container',
    props.showContent && 'mpd-offers-container--content',
    props.className,
  );
  return (
    <CTAContext.Provider value={value}>
      <Flex className="mpd-offers-wrapper">
        <Flex className={mpdContainerStyles}>{props.children}</Flex>
      </Flex>
    </CTAContext.Provider>
  );
};
