import styled, { keyframes } from 'styled-components';
import { clampedSize } from 'theme/scMixins/scClamp';

const popIn = keyframes`
    0% { transform: scale(0) }
    85% { transform: scale(1.2) }
    100% { transform: scale(1) }
`;

export const SCWinWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${popIn} 0.3s ease-in-out;
`;

export const SCWinCardWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;

  margin-top: 15px;
  margin-bottom: 20px;

  max-width: 298px;
`;

export const SCWin = styled.img`
  grid-row: 1 / -1;
  grid-column: 1 / -1;

  width: 298px;
  height: 420px;
`;

export const SCWinText = styled.div`
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  place-self: center;

  padding: 10px;

  ${clampedSize('font-size', 26, 30)};
  font-weight: 700;
  text-align: center;

  color: #fce34c;
  text-shadow: 0px 1px 2px #000;

  word-break: break-word;
`;
