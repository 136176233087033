import { useEffect } from 'react';
import { useCDNIconSprites } from 'hooks/common/useCDNIconSprites';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { imagesConfig } from 'pages/captainUp/utils/captainUp.utils';
import { General, LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { useGamificationWidgetConfig } from 'shared/common/features/integrations/gamification/hooks/useGamificationWidgetConfig';
import { selectIntegrations } from 'shared/common/features/integrations/slice/integrations.slice';
import { logoutAction } from 'shared/common/features/myProfile/utils/myProfile.utils';
import { useAppDispatch, useAppSelector } from 'store';
import { OffersTabLayout } from '../../offersTab';
import { myChallengesThunks, selectMyChallenges } from '../slice/clubTab.slice';
import { ClubTabsTypes } from '../types/clubTabs.types';

export const useFetchClub = ({
  subMenu,
  onBackClick,
}: {
  subMenu?: General.MyProfileRoutes[] | null;
  onBackClick?: (show: boolean) => void;
}): {
  navigation: General.MyProfileRoutes[];
  hasHeader: boolean;
  handleLogout: () => void;
  logout?: General.MyProfileRoutes;
  layout?: OffersTabLayout;
  badgesCount: ClubTabsTypes.BadgesCount;
} => {
  const challengesCounter: number = useAppSelector(selectMyChallenges.notificationCounter);
  const challengesCounterWidget: number = useAppSelector(selectIntegrations.getGamificationMyChallengesCount);
  const { isWidget } = useGamificationWidgetConfig();
  useCDNIconSprites('cuIcons', 'cu_icons_timestamp', 'cu-icons', `${imagesConfig.CAPTAIN_UP_ICONS}`);
  const dispatch = useAppDispatch();
  const handleLogout = () => logoutAction(dispatch);
  const badgesCount = {
    challenges: isWidget ? challengesCounterWidget : challengesCounter,
  };

  useEffect(() => {
    dispatch(myChallengesThunks.getItems());
  }, [dispatch]);

  const layout =
    useLayoutConfigType({
      moduleLayout: 'platform',
      innerLayout: LayoutConfigTypes.LAYOUTTYPECONFIG,
      deviceLayout: 'general',
    })?.offersTab || 'layout_1';

  const hasHeader = layout === 'layout_2' && !!onBackClick;
  const navigation = subMenu ? subMenu?.filter((item) => item?.key !== 'logout') : [];
  const logout = subMenu?.find((item) => item?.key === 'logout');
  return { hasHeader, navigation, logout, handleLogout, badgesCount, layout };
};
