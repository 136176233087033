import { useMemo } from 'react';
import useActiveVerticalUrlParam from 'hooks/common/useActiveVerticalUrl';
import { useAppSelector } from 'store';
import { selectNavigationMenu } from '../slice/navigationMenu.slice';
import { Container, ContainerPosition, Vertical } from '../types/navigationMenu.types';

const useGetContainersByPosition = ({ containersPosition }: { containersPosition: ContainerPosition }): Container[] => {
  const activeVerticalRoute = useActiveVerticalUrlParam();
  const memorizedActiveVerticalRoute = useMemo(() => activeVerticalRoute, [activeVerticalRoute]);
  const verticals = useAppSelector(selectNavigationMenu.verticals);
  const activeVertical = useMemo(
    () => verticals?.find((vertical: Vertical) => vertical.route === memorizedActiveVerticalRoute),
    [memorizedActiveVerticalRoute, verticals],
  );

  if (activeVertical) {
    return activeVertical.containers
      .filter((container) => container.positionType === containersPosition)
      ?.sort((a, b) => a.ranking - b.ranking);
  }
  return [];
};

export default useGetContainersByPosition;
