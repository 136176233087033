import { isEmpty } from 'utils/common/helpersCommon';
import { Sport } from 'utils/common/types/sportTypes';
import { SportDataTypes } from 'utils/common/types/sportTypes/sportData.types';

export const getScoreType = (
  sportId: number,
  eventStatusConfig: SportDataTypes.EventStatusConfig | null,
): Sport.ScoreType | undefined => {
  const scoreType = eventStatusConfig?.sportsConfig.find((data) => data.id === sportId)?.config.scoreTypeName;

  return scoreType;
};

export const getPeriodScore = (periodScore) => {
  const sortedArray = !isEmpty(periodScore)
    ? periodScore.slice().sort((a, b) => a.periodNumber - b.periodNumber)
    : null; // we need to sort the array because we receive data unordered
  return !isEmpty(sortedArray) ? sortedArray.slice(sortedArray.length - 1) : null;
};

export const getPeriodScoreBetSelection = (periodScore) => {
  if (!isEmpty(periodScore) && periodScore[0]?.homeScore !== undefined) {
    return periodScore?.slice(periodScore.length - 1);
  } else {
    return periodScore?.slice(0, 1);
  }
};

export const isBetgeniusWidget = (producerId): boolean => {
  const activeProviderName = providerIdNameMap[producerId];

  return activeProviderName === 'betgenius';
};

export const providerIdNameMap = {
  1: 'betradar',
  3: 'betradar',
  4: 'betradar',
  5: 'betradar',
  6: 'betradar',
  7: 'betradar',
  8: 'betradar',
  9: 'betradar',
  10: 'betradar',
  11: 'betradar',
  12: 'betradar',
  14: 'betradar',
  15: 'betradar',
  16: 'betradar',
  20: 'betgenius',
  21: 'betgenius',
  30: 'vermantia',
} as const;
