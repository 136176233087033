import { useCallback } from 'react';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useMediaQuery } from 'react-responsive';
import { casinoLaunchSelectors } from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { HighlightNewFeatureUI } from './components';
import NextFeatures from './components/NextFeatures';
import { useGetElementDimensions } from './hooks/useGetElementDimensions';
import { useHighlightComponents } from './hooks/useHighlightComponents';
import { highlightNewFeatureActions, selectHighlightNewFeature } from './slice/highlightNewFeature.slice';
import { setLocalHighlightNewFeature } from './utils/HighlightNewFeature.utils';
import { selectDrawer } from '../drawer/slice/drawer.slice';
import { selectMyProfile } from '../myProfile/slice/myProfile.slice';
import useRootModalData from '../rootModal/hooks/useRootModalData';

export const HighlightNewFeature = () => {
  const dispatch = useAppDispatch();
  const isActive = useAppSelector(selectHighlightNewFeature.isActive);
  const isMyProfileModalShowing = useAppSelector(selectMyProfile.isModalShowing);
  const isDrawerVisible = useAppSelector(selectDrawer.isVisible);
  const launchInProcess = useAppSelector(casinoLaunchSelectors.launchInProcess);
  const activeComponent = useAppSelector(selectHighlightNewFeature.activeComponent);
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const { elementDimensions, handleRef } = useGetElementDimensions({ isPortrait, isActive });

  const { currModalType } = useRootModalData();
  const disable = !isAuthenticated || launchInProcess || !!currModalType || isMyProfileModalShowing || isDrawerVisible;

  const { items, isLast } = useHighlightComponents({
    isPortrait,
    disable,
  });

  const handleNext = useCallback(() => {
    setLocalHighlightNewFeature(activeComponent);
    if (isLast) {
      dispatch(highlightNewFeatureActions.setIsDone(true));
      clearAllBodyScrollLocks();
    }
    dispatch(
      highlightNewFeatureActions.setIsView({
        activeComponent,
        isLast: isLast,
      }),
    );
  }, [dispatch, activeComponent, items, isLast]);

  if (disable || !isActive || isEmpty(items) || !isPortrait) return null;

  return (
    <HighlightNewFeatureUI.Wrapper
      handleRef={handleRef}
      elementDimensions={elementDimensions}
      isActive={elementDimensions.left > 0 && !items[activeComponent]?.isView}
    >
      <HighlightNewFeatureUI.ActionHand
        elementDimensions={elementDimensions}
        actionTypes={!items[activeComponent]?.isView && activeComponent ? items[activeComponent].types : ''}
      />
      <NextFeatures
        activeComponent={!items[activeComponent]?.isView ? activeComponent : ''}
        handleNext={handleNext}
        isLast={isLast}
        items={items}
      />
    </HighlightNewFeatureUI.Wrapper>
  );
};
