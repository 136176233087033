import React, { HTMLAttributes } from 'react';

/** Ticket Wrapper */
interface SSBTTicketWrapperProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: React.ReactNode;
  isReprint?: boolean;
}

const SSBTTicketWrapper: React.FC<SSBTTicketWrapperProps> = ({ className, children, isReprint }) => {
  const classProp = className ? { class: className } : undefined;

  return (
    <egtd-ssbt-ticket-wrapper {...classProp} data-reprint={isReprint}>
      {children}
    </egtd-ssbt-ticket-wrapper>
  );
};

export default SSBTTicketWrapper;
