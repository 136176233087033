import React from 'react';
import { useSetDrawerType } from './hooks/useSetDrawerType';

const SetDrawerTypeWrapper = () => {
  useSetDrawerType();

  return <></>;
};

export default SetDrawerTypeWrapper;
