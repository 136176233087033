import trim from 'lodash/trim';

export const replaceBulk = (str, findArray, replaceArray) => {
  let i,
    regex: any = [];
  const map: any = {};
  for (i = 0; i < findArray.length; i++) {
    regex.push(findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g, '\\$1'));
    map[findArray[i]] = replaceArray[i];
  }
  regex = regex.join('|');
  str = str.replace(new RegExp(regex, 'g'), function (matched) {
    return map[matched];
  });
  return str.replace(/[<<>>]/g, '');
};

export const replacePlayerInfo = (declarationInfo, text) => {
  return replaceBulk(text, Object.keys(declarationInfo), Object.values(declarationInfo));
};

export const checkIsDisabled = (fields, isValidForm, enabledOptions, formFields) => {
  const filledFextInputs = Object.keys(enabledOptions).filter((key) => {
    const relatedField = formFields.filter((formField) => formField.name === key)[0];
    if (trim(fields[key])?.length >= relatedField.validationRules?.minLength?.value) {
      return true;
    }
  });

  return (
    !isValidForm ||
    (Object.keys(enabledOptions).length === 0 && enabledOptions.constructor === Object) ||
    filledFextInputs.length !== Object.keys(enabledOptions).length
  );
};

export const DeclarationDateFormat = 'DD.MM.YYYY HH:mm';
