import React from 'react';

import { AnyAction } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { TerminalModalUI } from '../ssbt/components/ssbtModalUI';

type Props = {
  errorStatus: string;
  errorMessage: string;
  resetErrorStatus: () => AnyAction;
};
const ErrorModalOverlayNotifier: React.FC<Props> = ({ errorStatus, errorMessage, resetErrorStatus }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isError = errorStatus === 'failure';
  const handleCloseError = () => {
    if (resetErrorStatus) {
      dispatch(resetErrorStatus());
    }
  };
  return (
    <TerminalModalUI.Wrapper isVisible={isError}>
      <TerminalModalUI.Close text={t(`SSBT.TerminalModal.closeTerminalModalText`)} onClick={handleCloseError} />
      <TerminalModalUI.Body>
        <TerminalModalUI.Status status="error" text={`${errorMessage}`} />
      </TerminalModalUI.Body>
    </TerminalModalUI.Wrapper>
  );
};
export default ErrorModalOverlayNotifier;
