import { createListenerMiddleware, addListener } from '@reduxjs/toolkit';
import type { TypedStartListening, TypedAddListener } from '@reduxjs/toolkit';
import type { AppDispatch } from 'store';
import { RootState } from 'store/rootReducer';

export const listenerMiddleware = createListenerMiddleware();
export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

/**
 * Adds a new listener entry to the middleware.
 * It runs logic in response to Redux store updates instead of component props/state updates.
 * Every time an action is dispatched, the listener will be checked to see if it should run a callback (effect) based on the current action.
 * https://redux-toolkit.js.org/api/createListenerMiddleware
 */
export const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>;
