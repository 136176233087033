import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { sessionBalanceThunk } from 'modules/retail/modules/ssbt/features/sessionBalance/slice/sessionBalance.slice';
import { selectVoucherData, voucherThunk } from 'modules/retail/shared/slice/voucher.slice';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { SSBT } from 'utils/common/translationUtils/translationStrings';
import CloseModalButton from '../../../components/CloseModalButton';
import { TerminalModalUI } from '../../../components/ssbtModalUI';
import { TerminalModalActionIcons } from '../../../types/ssbt.types';

const ThirdStepDepostSSBTVoucher = ({ voucherValue, setActiveStep }) => {
  const dispatch = useAppDispatch();
  const voucherStatus = useAppSelector(selectVoucherData.retailVoucherStatus);
  const voucher = useAppSelector(selectVoucherData.retailVoucher);
  const depositWithVoucherStatus = useAppSelector(selectVoucherData.depositWithVoucherStatus);
  // const date = new Date(Date.now());
  const { t } = useTranslation();
  const { closeModal } = useHandleModals();

  const closeModalButton = () => {
    dispatch(setActiveStep(1));
    closeModal();
  };

  const handleDepositWithVoucher = () => {
    dispatch(voucherThunk.depositWithVoucher({ code: voucherValue }));
    dispatch(setActiveStep(4));
  };
  const renderDepositStateModals = (status) => {
    switch (status) {
      case 'pending':
        return (
          <>
            <TerminalModalUI.Status status="pending" />
            <TerminalModalUI.Header text={t(SSBT.TerminalModal.requestTerminalText)} />
          </>
        );
      case 'succeeded':
        return (
          <>
            {voucher?.isValid ? (
              <>
                <TerminalModalUI.Header text={t(SSBT.TerminalModal.scanVoucherText)} />
                <TerminalModalUI.ActionIcon icon={TerminalModalActionIcons.QR} />
                <TerminalModalUI.Button
                  variant={isEmpty(voucher) || voucher?.amount <= 0 ? 'disabled' : 'primary'}
                  text={t(SSBT.TerminalModal.depositText)}
                  textSecondary={`${voucher?.amount} ${voucher?.currency}`}
                  onClick={() => handleDepositWithVoucher()}
                />
              </>
            ) : (
              <>
                <TerminalModalUI.Status status="error" text={t(SSBT.TerminalModal.invalidVoucherText)} />
                <CloseModalButton onClick={closeModalButton} />
              </>
            )}
          </>

          // <TerminalModal.Status
          //   status="success"
          //   text={t(SSBT.TerminalModal.transactionTextTerminal)}
          //   textSecondary={date.toDateString()}
          //   onClose={closeModal}
          //   onDepositAgain={() => console.log('voucher')}
          //   showStatusButtons
          // />
        );
      case 'failure':
        return (
          <>
            <TerminalModalUI.Status
              status="error"
              text={
                !isEmpty(voucher) && voucher?.amount <= 0
                  ? t(SSBT.TerminalModal.invalidVoucherText)
                  : t(SSBT.TerminalModal.rejectedTransactionTerminal)
              }
            />
            <CloseModalButton onClick={closeModalButton} />
          </>
        );
    }
  };

  useEffect(() => {
    if (depositWithVoucherStatus === 'succeeded') {
      dispatch(sessionBalanceThunk.checkForUpdateBalance());
    }
  }, [depositWithVoucherStatus]);

  return (
    <>
      {renderDepositStateModals(voucherStatus)}
      {/* <TerminalModal.Header text={'Confirm Deposit'} />
      <TerminalModal.ActionIcon icon={TerminalModalActionIcons.DEPOSIT_CASH} />
      <TerminalModal.Button
        text={'Deposit'}
        textSecondary={'5'}
        onClick={() => console.log('deposit')}
        totalSum={5}
        state={'active'}
      /> */}
      {/* <TerminalModal.Status
        status="error"
        text={'Invalid voucher'}
        onClose={() => console.log('voucher')}
        showStatusButtons={false}
      /> */}
    </>
  );
};

export default ThirdStepDepostSSBTVoucher;
