import { useCallback, useEffect, useRef } from 'react';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import userStorage from 'pages/auth/login/UserStorage';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { useMyProfileConfig } from 'shared/common/features/myProfile/hooks/useMyProfileConfig';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { CustomPlatformEvents } from 'shared/common/hooks/useHandleCustomEvents';
import { useAppDispatch, useAppSelector } from 'store';
import { RealityCheckModal } from 'utils/common/translationUtils/translationStrings';
import useRealityCheckConfirmed from './useRealityCheckConfirmed';

export const useRealityCheck = (): void => {
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const realityCheckConfirmedAt = useAppSelector(selectGeneral.realityCheckConfirmedAt);
  const realityCheckConfig = useMyProfileConfig()?.realityCheck;
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const realityCheckIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useAppDispatch();
  const { enabled, interval, showLogoutButton } = realityCheckConfig || {};
  const loginDate = userStorage.getUser()?.loginDate;
  const realityCheckDuration = interval && interval * 60 * 1000;

  const { closeModal } = useHandleModals();

  const showModal = useCallback(
    (minutes) => {
      document.dispatchEvent(new Event(CustomPlatformEvents.HIGH_PRIORITY_MODAL));
      dispatch(
        showRootModal({
          modalType: 'REALITY_CHECK',
          modalProps: {
            message: RealityCheckModal.Message.messageText,
            showLogoutButton,
            minutes,
          },
          modalPriority: ModalPriorityEnum.MEDIUM,
          modalId: 'REALITY_CHECK',
        }),
      );
    },
    [dispatch, showLogoutButton],
  );

  const setRealityCheckInterval = () => {
    if (!realityCheckDuration) return;
    realityCheckIntervalRef.current = setInterval(() => {
      const time = Date.now() - loginDate;
      const minutes = (Math.trunc(time / realityCheckDuration) * realityCheckDuration) / 60000;
      showModal(minutes);
    }, realityCheckDuration);
  };

  const setTimeoutAction = () => {
    if (!realityCheckDuration) return;
    const timePassed = Date.now() - loginDate;
    const timeoutInterval = realityCheckDuration - (timePassed % realityCheckDuration);
    const minutesInSession = ((Math.trunc(timePassed / realityCheckDuration) + 1) * realityCheckDuration) / 60000;
    timeout.current = setTimeout(() => {
      showModal(minutesInSession);
      setRealityCheckInterval();
    }, timeoutInterval);
  };

  const setTimeouts = () => {
    isAuthenticated && loginDate && enabled && setTimeoutAction();
  };

  const clearTimeouts = () => {
    timeout.current && clearTimeout(timeout.current);
    realityCheckIntervalRef.current && clearInterval(realityCheckIntervalRef.current);
  };

  const resetTimeouts = () => {
    clearTimeouts();
    setRealityCheckInterval();
  };

  useRealityCheckConfirmed({ closeModal, callback: resetTimeouts });

  useEffect(() => {
    realityCheckConfirmedAt && resetTimeouts();
  }, [realityCheckConfirmedAt]);

  useEffect(() => {
    if (isAuthenticated) {
      setTimeouts();

      return () => {
        clearTimeouts();
      };
    }
  }, [isAuthenticated]);
};
