import React, { memo } from 'react';
import { CasinoGamesRender } from 'modules/casino/modules/container/cellsContainer/cellls';
import { GridLoadingBehaviorType } from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { CasinoLoader } from 'modules/casino/shared/components/casinoLoader';
import NoGamesMessage from './NoGamesMessage';

interface Props {
  categoryId: string;
  categoryLayout: CategoryLayoutTypes;
  subType: CategorySubTypes;
  gamesCount: number;
  isLoading: boolean;
  hasSuccess: boolean;
  hasNoGames: boolean;
  handleBackBtnClick: () => void;
  className?: string;
}

const FavouritesGridWrapper: React.FC<Props> = ({
  categoryId,
  categoryLayout,
  subType,
  gamesCount,
  isLoading,
  hasSuccess,
  hasNoGames,
  className,
  handleBackBtnClick,
}) => {
  if (!categoryId) {
    return <></>;
  }

  const gridParams = {
    totalElements: gamesCount,
    className: className || 'favourites',
    gridLoadingBehavior: GridLoadingBehaviorType.InfinityScroll,
  };

  const renderComponents = () => {
    if (isLoading) {
      return <CasinoLoader />;
    }

    if (hasSuccess) {
      return (
        <>
          <CasinoGamesRender
            params={gridParams}
            categoryId={categoryId}
            subType={subType}
            categoryLayoutType={categoryLayout}
          />
        </>
      );
    }

    if (hasNoGames) {
      return <NoGamesMessage handleBackBtnClick={handleBackBtnClick} />;
    }

    return <></>;
  };

  return renderComponents();
};

export default memo(FavouritesGridWrapper);
