import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { createAbortThunk, CreateThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import type { BetHistoryState } from '../../../hooks/BetHistoryStateTypes';

interface LotteryHistoryState {
  history: LotteryHistoryItem[];
  currentPage: number;
  totalPages: number;
  isFetching: boolean;
  status: string;
  error: string | null;
}

export interface LotteryHistoryItem {
  currencyCode: string;
  betCid: string;
  settled: boolean;
  createdDate: number;
  totalWin: number;
  totalBet: number;
  gameName: string;
}

export type Sort = {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
};

export type Pageable = {
  sort: Sort;
  pageNumber: number;
  pageSize: number;
  offset: number;
  paged: boolean;
  unpaged: boolean;
};

export type HistoryThunkAPI = { content: LotteryHistoryItem[]; pageable: Pageable; totalPages: number };

export const fetchLotteryHistory: CreateThunk<BetHistoryState.ThunkArgs, HistoryThunkAPI, boolean | undefined> =
  createAbortThunk(
    'fetchLotteryHistory',
    async ({ pageNumber, fromDate, toDate, isFetchMore }, { rejectWithValue }) => {
      const params = {
        pageNumber,
        betDate: { fromDate, toDate },
        product: 'LOTTERY',
      };

      //do not delete debugging logs
      // console.log('timestamp_from ', fromDate, '---');
      // console.log('timestamp_to ', toDate, '---');
      // console.log('______________________');
      // console.log('UTC date_from ', new Date(queryParams.fromDate).toUTCString(), '---');
      // console.log('UTC date_to ', new Date(queryParams.toDate).toUTCString(), '---');
      // console.log('______________________');
      // console.log('LOCALE date_from ', new Date(queryParams.fromDate).toLocaleString(), '---');
      // console.log('LOCALE date_to', new Date(queryParams.toDate).toLocaleString(), '---');

      try {
        const response = await axiosInstance.post(
          `${config.API_URL}/api/reporting/player/lottery/lottery-player-history`,
          params,
        );
        return response.data;
      } catch (error) {
        return rejectWithValue(isFetchMore);
      }
    },
  );

const initialState: LotteryHistoryState = {
  history: [],
  currentPage: 0,
  totalPages: 0,
  isFetching: false,
  status: 'idle',
  error: null,
};

const lotteryBetHistory = createSlice({
  name: 'lotteryBetHistory',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchLotteryHistory.pending, (state, action) => {
        if (action.meta?.arg?.isFetchMore) {
          state.isFetching = true;
        } else {
          state.status = 'pending';
        }
      })
      .addCase(fetchLotteryHistory.fulfilled, (state, action) => {
        const { content, pageable, totalPages } = action.payload;

        state.history = [...state.history, ...content];
        state.currentPage = pageable.pageNumber;
        state.totalPages = totalPages;
        state.status = 'succeeded';
        state.isFetching = false;
      })
      .addCase(fetchLotteryHistory.rejected, (state, action) => {
        if (!action.payload) {
          state.status = 'failure';
        }
        state.error = getError.default();
        state.isFetching = false;
      });
  },
  reducers: {
    clearLotteryHistory(state) {
      state.history = [];
      state.currentPage = 0;
      state.totalPages = 0;
      state.isFetching = false;
      state.status = 'idle';
      state.error = null;
    },
    clearLotteryBetHistoryItems(state) {
      state.history = [];
    },
  },
});

export const { clearLotteryHistory, clearLotteryBetHistoryItems } = lotteryBetHistory.actions;

export default lotteryBetHistory.reducer;

export const selectLotteryHistory = {
  bets: (state: RootState) => state.myAccount.lotteryBetHistory.history,
  currentPage: (state: RootState) => state.myAccount.lotteryBetHistory.currentPage,
  totalPages: (state: RootState) => state.myAccount.lotteryBetHistory.totalPages,
  status: (state: RootState) => state.myAccount.lotteryBetHistory.status,
  error: (state: RootState) => state.myAccount.lotteryBetHistory.error,
  isLoadingMore: (state: RootState) => state.myAccount.lotteryBetHistory.isFetching,
};
