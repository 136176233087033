/**
  DESC
*/
export const cssGlobalNS = 'egtd';
export const cssGamificationNS = 'egtd-g';
export const cssNavNS = `${cssGlobalNS}-nav`;

export declare namespace CSS {
  /**
  CSS color variants
  */
  type Variants = 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'error';
  /**
  CSS button variants
  */
  type ButtonVariants = 'primary' | 'secondary' | 'tertiary';
  /**
  CSS size variants
  */
  type Sizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  // type Variants2 = 'primary' | 'secondary' | 'test2' ;
  // Extend other types
  // type Buttons = Variants | 'neshto'
  // Remove from Extended, add some other
  // type ButtonsWithout = Exclude<Variants,'warning'> | 'iuhfiuh'
}

// implement All
// const map: Record<CSS.Variants2, number> = {primary: 34, secondary: 23, test2: 3}
