import { memo } from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppSelector } from 'store';
import { RetailHeaderDefault } from './features/retailHeaderDefault';
import RetailHeaderUser from './features/retailHeaderDefault/features/retailHeaderUser/RetailHeaderUser';
import { RetailHeaderLive } from './features/retailHeaderLive';
import { useIsRetailMobile } from './hooks/useIsRetailMobile';
import { useRetailHeaderHeight } from './hooks/useRetailHeaderHeight';
import { NavBrand } from '../components';
import RetailMobileTopBar from '../components/appHeaderContainer/RetailMobileTopBar';
import RetailDrawerToggler from '../components/AppHeaderUI/RetailDrawerToggler';

const RetailHeader = ({ isLiveProgram }) => {
  const handleRef = useRetailHeaderHeight();
  const isRetailMobile = useIsRetailMobile();
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);

  const headerMobileClass = clsx('r-header r-header--mobile');
  const headerMobileInnerClass = clsx('r-header__inner--mobile');
  const headerDesktopClass = clsx('r-header r-header--desktop', isLiveProgram && 'r-header--live');

  return isRetailMobile ? (
    <div className={headerMobileClass}>
      <RetailMobileTopBar isAuthenticated={isAuthenticated} />
      <Flex.ACJB className={headerMobileInnerClass}>
        <Flex.AC style={{ gap: 'inherit' }}>
          <RetailDrawerToggler className="p-0" />
          <NavBrand logoClassName="r-header-logo--mobile" />
        </Flex.AC>
        <RetailHeaderUser isAuthenticated={isAuthenticated} isRetailMobile={isRetailMobile} />
      </Flex.ACJB>
    </div>
  ) : (
    <Flex.AC className={headerDesktopClass} ref={(node) => handleRef(node)}>
      <div className="r-header__left">
        <NavBrand />
      </div>
      {!isLiveProgram ? <RetailHeaderDefault /> : <RetailHeaderLive />}
    </Flex.AC>
  );
};

export default memo(RetailHeader);
