import React from 'react';
import clsx from 'clsx';
import { WidgetTemplate } from 'modules/casino/shared/constants';
import { CasinoWidgetsTypes } from '../../../../types/casinoWidgetsTypes';

interface WrapperProps {
  children: React.ReactNode;
  type: CasinoWidgetsTypes.WidgetTypes;
  backgroundUrl?: string;
  wrapperClassName?: string;
}

const CellWrapper: React.FC<WrapperProps> = ({ children, type, backgroundUrl, wrapperClassName }) => {
  const wrapperClass = clsx('cw', 'cw--generic', `cw--${WidgetTemplate[type]}`, wrapperClassName && wrapperClassName);
  const wrapperStyle = {
    backgroundImage: backgroundUrl && backgroundUrl,
  };

  return (
    <div className={wrapperClass} style={wrapperStyle}>
      {children}
    </div>
  );
};

export default CellWrapper;
