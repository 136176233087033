import { CasinoGamesTypes } from 'modules/casino/modules/container/cellsContainer/cellls/types/casinoGamesTypes';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { fetchFormFields } from 'shared/common/features/realityCheckSession';
import axiosInstance from 'utils/common/axios-instance';
import { getBusinessUnit } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { casinoLaunchSelectors, setGameBetType } from '../../casinoLaunchSlice';

const RealityCheckSessionInterceptor =
  ({ gameId, closeGame }: { gameId: string | number; closeGame: () => void }) =>
  async (dispatch, getState) => {
    const buSettings = selectGeneral.buSettings(getState());
    const isGameLaunch = casinoLaunchSelectors.launchInProcess(getState());

    if (buSettings?.regulationCasinoGameSettings && isGameLaunch) {
      const promise = (betType) =>
        new Promise((resolve, reject) => {
          if (betType && buSettings.regulationCasinoGameSettings?.includes(betType)) {
            dispatch(fetchFormFields({ resolve, reject, closeGame }));
          } else {
            resolve(true);
          }
        });
      const gameBetType = casinoLaunchSelectors.getGameBetType(getState());
      if (!gameBetType) {
        try {
          const gameUrl = `/api/gaming/public/bonus/gameIds?workingBusinessUnit=${getBusinessUnit()}&gameIds=${gameId}`;
          const { data } = await axiosInstance.get<CasinoGamesTypes.GameProps[]>(`${config.API_URL}${gameUrl}`);
          dispatch(setGameBetType(data?.[0]?.betType));
          return promise(data?.[0]?.betType);
        } catch {
          return new Promise((_, reject) => reject(closeGame()));
        }
      }
      return promise(gameBetType);
    } else {
      return new Promise((resolve) => resolve(true));
    }
  };

export default RealityCheckSessionInterceptor;
