import React from 'react';
import CurrencySwapper from 'modules/casino/shared/components/common/CurrencySwapper';
import { useCurrencyConfig } from 'modules/casino/shared/hooks/useCurrencyConfig';
import { formatSumAmount } from 'modules/casino/shared/utils/helpersCasino';

type Props = {
  label: string;
  value: string;
  currencySymbol: string;
};

const StakeItem: React.FC<Props> = ({ label, value, currencySymbol }) => {
  const { formatToComma, currencySymbolFirst, spaceBetween } = useCurrencyConfig();

  if (!value) {
    return null;
  }

  return (
    <div className="bww-item__bet">
      {`${label} `}
      <CurrencySwapper
        sumComponent={<>{`${formatSumAmount({ sum: value, formatToComma })}`}</>}
        currencyComponent={<span className={`bww-item__bet--currency`}>{currencySymbol}</span>}
        currencySymbolFirst={currencySymbolFirst}
        spaceBetween={spaceBetween}
      />
    </div>
  );
};

export default StakeItem;
