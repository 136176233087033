import { useState, useCallback } from 'react';
import { createCookie, getCookie } from '../../../../../utils/common/helpersCommon';
import { General } from '../../general/types/generalSlice.types';

const defaultExpirationValue = 30;
const TOP_MOBILE_BANNER_COOKIE_NAME = 'topMobileAppBanner';

const useMobileCloseBanner = (
  bannerConfig: General.TopMobileAppBanner,
  headBackRef: React.MutableRefObject<HTMLDivElement | null>,
  isTabletOrMobile: boolean,
) => {
  const [bannerClosed, setBannerClosed] = useState(true);
  const topMobileBannerCookie: string = getCookie(TOP_MOBILE_BANNER_COOKIE_NAME);

  const handleCloseBanner = useCallback(() => {
    let bannerCloseCount = 0;
    const expireDate = new Date().setTime(
      new Date().getTime() +
        (bannerConfig.cookieExpires ? bannerConfig.cookieExpires : defaultExpirationValue) * 24 * 60 * 60 * 1000,
    );

    bannerCloseCount = (topMobileBannerCookie && parseInt(topMobileBannerCookie)) || 0;
    bannerCloseCount = bannerCloseCount += 1;

    createCookie({
      name: TOP_MOBILE_BANNER_COOKIE_NAME,
      value: bannerCloseCount.toString(),
      expiration: expireDate,
    });

    if (headBackRef.current) {
      const headerBackSize = !isTabletOrMobile ? '105px' : '90px';
      headBackRef.current.style.maxHeight = headerBackSize;
      headBackRef.current.style.flex = `1 0 ${headerBackSize}`;
    }

    setBannerClosed(true);
  }, [bannerConfig.cookieExpires, headBackRef, topMobileBannerCookie]);

  return { bannerClosed, setBannerClosed, handleCloseBanner, topMobileBannerCookie };
};

export default useMobileCloseBanner;
