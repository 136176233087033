import React from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { UI } from 'components/shared/UI';
import { useShowCookie } from 'shared/common/features/cookiesConsent/hooks/useShowCookie';
import { CookiesMessages } from 'utils/common/translationUtils/translationStrings';
import { useGetCookieLink } from './hooks/useGetCookieLink';

const CookiesConsent: React.FC = () => {
  const [cookies, setCookie] = useCookies(['cookies-consent']);
  const cookieLink = useGetCookieLink();
  const { isVisible, setIsVisible } = useShowCookie({ cookies });

  const { t } = useTranslation();

  const hideCookiesConsentHandler = () => {
    setIsVisible(false);
  };

  const setCookiesConsent = () => {
    setCookie('cookies-consent', true, { maxAge: 2592000, path: '/' });
    setIsVisible(false);
  };

  if (!isVisible || cookies['cookies-consent']) return null;

  return (
    <Flex.Col className="w-100 consent-popup">
      <Icon className="ml-auto consent-popup__close" icon="times" onClick={hideCookiesConsentHandler} />
      <div className="py-3">
        <p>{t(CookiesMessages.CookiesConsent.cookiesConsentIntro)}</p>
        {t(CookiesMessages.CookiesConsent.cookiesConsentLinkIntro)}{' '}
        <a href={cookieLink} target="_blank" rel="noreferrer">
          {t(CookiesMessages.CookiesConsent.cookiesConsentLinkText)}
        </a>{' '}
        {t(CookiesMessages.CookiesConsent.cookiesConsentLinkOutro)}
      </div>
      <UI.Button
        class="ml-auto px-4"
        size="lg"
        text={t(`Messages.CookiesConsent.buttonText`)}
        onClick={setCookiesConsent}
        data-qid={'accept-cookies'}
      />
    </Flex.Col>
  );
};

export default CookiesConsent;
