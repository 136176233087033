import { getLastWinnerLiveImageLocation } from 'modules/casino/modules/container/widget/utils/casinoWidgetsUtils';
import { useSetRefsLoaded } from 'modules/casino/shared/hooks/useSetRefsLoaded';
import LastWinnersWidgetGameImage from './GameImage';
import TagLogo from './TagLogo';
import { CasinoWidgetsTypes, WidgetTemplates } from '../../../../types/casinoWidgetsTypes';

interface ImageProps {
  lastWinner: CasinoWidgetsTypes.LastWinner;
  type: CasinoWidgetsTypes.WidgetTypes;
  cdnPathLayout: string;
  gameLayout?: string;
}

const GameImageWrapper: React.FC<ImageProps> = ({ lastWinner, type, cdnPathLayout, gameLayout }) => {
  const liveImageLocation = getLastWinnerLiveImageLocation({ resource: lastWinner?.gameResource, type });
  const { refLoaded, setRefs, ref: imageContainerRef } = useSetRefsLoaded();

  return (
    <div className={`cw__game-image ${liveImageLocation ? 'cw__game-image--live' : ''}`} ref={setRefs}>
      {refLoaded && (
        <>
          {(type === WidgetTemplates.SECTION_STAKE || type === WidgetTemplates.SECTION_STAKE_PLUS) && (
            <TagLogo lastWinner={lastWinner} />
          )}
          <LastWinnersWidgetGameImage
            liveImageLocation={liveImageLocation}
            imageContainerRef={imageContainerRef}
            cdnPathLayout={cdnPathLayout}
            lastWinner={lastWinner}
            gameLayout={gameLayout}
          />
        </>
      )}
    </div>
  );
};
GameImageWrapper.displayName = 'LastWinnersWidgetGameWrapper';
export default GameImageWrapper;
