import React from 'react';
import clsx from 'clsx';
import BetBehind from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTablePlayers/components/BetBehind';
import { useGameCellTablePlayers } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTablePlayers/hooks/useGameCellTablePlayers';
import { Flex } from 'modules/casino/shared/components/layout';
import { Theme } from 'modules/casino/theme/Theme';
import { SeatsStyled, SeatStyled } from './components/Seats.styled';

interface Props {
  gameUniqueId: string;
  categoryId: string;
}

const GameCellTablePlayers: React.FC<Props> = ({ gameUniqueId, categoryId }) => {
  const { players, isTableFull, betBehind, showTablePlayers } = useGameCellTablePlayers({ gameUniqueId, categoryId });
  const seatWrapperStyles = clsx('mt-auto', 'blackjack-seats__wrapper');

  if (!showTablePlayers) return null;
  return (
    <>
      {isTableFull ? (
        <BetBehind betBehind={betBehind} />
      ) : (
        <Flex className={seatWrapperStyles}>
          <SeatsStyled className="mx-auto d-flex-jb">
            {players.map((item, idx) => (
              <SeatStyled
                key={idx}
                viewBox="0 0 19 26"
                className={clsx('blackjack-seats__seat', item && 'blackjack-seats__seat--active')}
              >
                <use href={`${Theme.icons.liveCasinoGames}#player`} />
              </SeatStyled>
            ))}
          </SeatsStyled>
        </Flex>
      )}
    </>
  );
};
export default GameCellTablePlayers;
