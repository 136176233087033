import React from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { CasinoGamesRender } from 'modules/casino/modules/container/cellsContainer/cellls';
import { GridLoadingBehaviorType } from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import { gameProvidersSelectors } from 'modules/casino/modules/container/shared/features/casinoGameProviders/slice/casinoGameProvidersSlice';
import CategoryBreadcrumbLayout from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/CategoryBreadcrumbLayout';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import GridWidgetSectionLayout from 'modules/casino/modules/container/widget/components/widgetSection/GridWidgetSectionLayout';
import { useShowCarousel } from 'modules/casino/modules/lobby/containersLobby/hooks/useShowCarousel';
import JackpotSection from 'modules/casino/shared/features/jackpots/components/JackpotSection';
import useCategoryJackpot from 'modules/casino/shared/hooks/useCategoryJackpot';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import useSectionBackground from 'modules/casino/shared/hooks/useSectionBackground';
import useShowSingleCategory from 'modules/casino/shared/hooks/useShowSingleCategory';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { Breakpoints, Desktop, DesktopDown } from 'modules/casino/theme/Theme';
import { useAppSelector } from 'store';
import { getStyles } from '../../../utils/casinoCategoryUtils';

export interface CategoryProps {
  categoryId: string;
}
const CasinoCategory: React.FC<CategoryProps> = ({ categoryId }) => {
  const { jackpot, backgroundLocation, alias, layoutConfig, widget, isPublic } = useGetCategoryParams(
    categoryId,
    CategoryLayoutTypes.INNER_CATEGORY,
  );
  const isToggled = useAppSelector(gameProvidersSelectors.isToggled);
  const { jackpotDetails, jackpotId } = useCategoryJackpot(jackpot);
  const isMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isMobile}px)` });
  const step = !isMobile && !jackpotDetails ? true : false;
  useShowCarousel([step, step]);
  const { showSingleCategory } = useShowSingleCategory({
    isPublic,
  });

  const hideBreadcrumb = jackpotDetails?.template === 'egypt_quest';
  const [sectionBackground] = useSectionBackground(backgroundLocation, 'Category');
  const casinoCatStyles = getStyles({ jackpotDetails, hasBackground: backgroundLocation, isToggled });
  const categoryTemplateClass = jackpotDetails ? jackpotDetails?.template : alias;
  const categoryStyles = clsx(
    `${cssGlobalNS}-container inner-category`,
    categoryTemplateClass,
    layoutConfig?.layout,
    jackpotDetails && 'jackpot-section',
    jackpotDetails && `${jackpotDetails?.template}--${layoutConfig?.layoutGrid.toLowerCase()}`,
  );

  if (!showSingleCategory) {
    return <></>;
  }

  if (widget?.id) {
    return (
      <>
        <CategoryBreadcrumbLayout categoryId={categoryId} type={CategoryLayoutTypes.INNER_CATEGORY} />
        <GridWidgetSectionLayout categoryId={categoryId} categoryLayoutType={CategoryLayoutTypes.INNER_CATEGORY} />
      </>
    );
  }

  return (
    <>
      <DesktopDown>
        <CategoryBreadcrumbLayout categoryId={categoryId} type={CategoryLayoutTypes.INNER_CATEGORY} />
      </DesktopDown>
      <div className={casinoCatStyles} style={backgroundLocation && { backgroundImage: sectionBackground }}>
        <div className={categoryStyles}>
          {jackpotDetails && <JackpotSection jackpot={jackpotDetails} jackpotId={jackpotId} />}

          <Desktop>
            {!hideBreadcrumb && (
              <CategoryBreadcrumbLayout categoryId={categoryId} type={CategoryLayoutTypes.INNER_CATEGORY} />
            )}
          </Desktop>
          <CasinoGamesRender
            categoryId={categoryId}
            subType={CategorySubTypes.STANDARD}
            categoryLayoutType={CategoryLayoutTypes.INNER_CATEGORY}
            params={{
              className: 'innerCategory',
              gridLoadingBehavior: GridLoadingBehaviorType.InfinityScroll,
            }}
          />
        </div>
      </div>
    </>
  );
};
export default CasinoCategory;
