import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/shared';
import { Modal } from 'components/shared/Modal';
import { ModalUI } from 'components/shared/ModalUI';
import { UI } from 'components/shared/UI';
import { useAppDispatch } from 'store';
import { GeolocationRestriction } from 'utils/common/translationUtils/translationStrings';
import { hideRootModal } from '../slice/rootModal.slice';

const GeolocationModal: React.FC = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(hideRootModal());
  }, [dispatch]);

  return (
    <Modal ref={containerRef} visible={true} className="modal-terms">
      <Modal.Close closeText={t(GeolocationRestriction.Modal.closeButton)} closeAction={handleClose} />
      <Modal.Body className="d-flex-col-ac text-center" padding="lg">
        <ModalUI.Title text={t(GeolocationRestriction.Modal.title)} />
        <Icon className="my-3 modal-terms__icon fill--error" icon="times-solid" />
        <ModalUI.Title className="mb-3" hasAccent text={t(GeolocationRestriction.Modal.vpnWarning)} />
        <UI.Button size="lg" onClick={handleClose} text={t(GeolocationRestriction.Modal.logoutButton)} />
      </Modal.Body>
    </Modal>
  );
};

export default GeolocationModal;
