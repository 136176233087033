import { useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { useMarketingKeys } from 'hooks/common/useMarketingKeys';
import { useScript } from 'hooks/common/useScript';
import userStorage from 'pages/auth/login/UserStorage';
import { useAppSelector } from 'store';
import { setIsOpenChat } from '../features/general/slice/general.slice';
import { LayoutConfigTypes } from '../features/general/types/generalSlice.types';
import { selectMyProfile } from '../features/myProfile/slice/myProfile.slice';

declare const window: Window &
  typeof globalThis & {
    zE: any;
    $zopim: any;
    zESettings: {
      webWidget: {
        launcher?: Record<never, never>;
        chat?: {
          departments: {
            enabled: string[];
          };
        };
      };
    };
  };
interface Props {
  closeChatWindow: boolean;
  onChatLoaded: () => void;
}

const ZendeskChat: React.FC<Props> = ({ closeChatWindow, onChatLoaded }) => {
  const { ZENDESK_ENV_KEY } = useMarketingKeys();
  const zendeskDepartment = useAppSelector(selectMyProfile.zendeskDepartment);

  const id = 'ze-snippet';
  const scriptStatus = useScript('https://static.zdassets.com/ekr/snippet.js?key=' + ZENDESK_ENV_KEY, id);
  const userProfile = useAppSelector(selectMyProfile.dataUser);
  const userLang = userStorage.getUserLang();
  const zE = window['zE'];
  const hideLauncherButton = clsx('hide-launcher-button');
  const dispatch = useDispatch();

  const zendeskChatConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.ZENDESK_CHAT_CONFIG,
  });

  useEffect(() => {
    if (scriptStatus === 'ready') {
      zE('webWidget', 'setLocale', userLang);
      zE('webWidget', 'show');
      zE('webWidget', 'open');

      zE('webWidget:on', 'chat:connected', function () {
        onChatLoaded();
      });

      zE('webWidget:on', 'userEvent', (_userEvent) => {
        if (_userEvent.action === 'Web Widget Minimised') {
          dispatch(setIsOpenChat(false));
          if (closeChatWindow) {
            const launcerButton = document.getElementById('launcher');
            launcerButton?.classList.add(hideLauncherButton);
          }
        }
      });

      zE('webWidget', 'prefill', {
        name: {
          value: userProfile?.fullName,
        },
        email: {
          value: userProfile?.email,
        },
      });

      zE('webWidget', 'identify', {
        name: userProfile?.fullName,
        email: userProfile?.email,
      });

      zE('webWidget', 'identify', {
        name: userProfile?.fullName,
        email: userProfile?.email,
      });

      const chat = {
        departments: {
          enabled: zendeskDepartment ? [zendeskDepartment] : [],
          ...(zendeskDepartment ? { select: zendeskDepartment } : {}),
        },
      };

      const webWidget = zendeskChatConfig !== undefined ? { ...zendeskChatConfig.webWidget, chat } : {};

      window.zESettings = zendeskChatConfig !== undefined ? { webWidget } : { webWidget: { launcher: {}, chat } };
    }
  }, [scriptStatus, zendeskDepartment]);
  return null;
};
export default ZendeskChat;
