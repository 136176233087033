import styled from 'styled-components';
import { Breakpoints } from 'modules/casino/theme/Theme';

export const StyledBetBehind = styled.div`
  align-self: center;
  padding: 10px 20px;
  max-width: 90%;
  border-radius: 4px;
  font-size: 0.625rem;
  line-height: 0.625rem;
  position: absolute;
  bottom: 50px;
  z-index: 2;
  @media screen and (min-width: ${Breakpoints.isMobile}px) {
    max-width: max(60%, 160px);
    font-size: 0.875rem;
    line-height: 1.0625rem;
    bottom: 60px;
  }
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    margin-bottom: 10px;
    bottom: 50%;
    transform: translateY(50%);
  }
`;

StyledBetBehind.displayName = 'StyledBetBehind';
