import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Breakpoints } from 'theme/Theme';
import { SCFadeIn } from '../../campaigns/SharedSC/SharedSC';

interface StyledCTAButtonProps {
  btnbg: string;
  $bgColor?: string;
  $textColor?: string;
  $borderColor?: string;
  $size: {
    desktop: number[];
    mobile: number[];
  };
}

export const StyledCTAButtonBgImage = styled(motion.button).attrs(({ className }: { className? }) => ({
  className: className,
}))<StyledCTAButtonProps>`
  z-index: 20;
  padding: 14px 30px;
  min-width: ${({ $size }) => `${$size.mobile[0]}px`};
  min-height: ${({ $size }) => `${$size.mobile[1]}px`};
  border: none;
  font-size: 18px;
  font-weight: 700;
  vertical-align: middle;
  @include fade-in();
  background-color: ${({ $bgColor }) => $bgColor ?? `transparent`};
  background-image: ${({ $bgColor, btnbg }) => !$bgColor && `url(${btnbg})`};
  ${({ $borderColor }) =>
    $borderColor &&
    `
  border:1px solid ${$borderColor};
  border-radius: 3px;
  `}
  margin-bottom: 11px;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    /* min-height: 60px; */
    /* min-width: 258px; */
    min-width: ${({ $size }) => `${$size.desktop[0]}px`};
    min-height: ${({ $size }) => `${$size.desktop[1]}px`};
  }
  transition: all 0.15s;
  text-align: center;
  color: ${({ $textColor }) => $textColor ?? '#fff'};
  animation: ${SCFadeIn} 0.3s;
`;

// export const StyledPlaceholder = styled.div`
//   ${sharedStyle};
// `;
