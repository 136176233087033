import React from 'react';
import clsx from 'clsx';
import { Icon } from 'components/shared';

/** Form Message */
interface MessageProps {
  type: 'error' | 'info' | 'success';
  content?: string;
  hasFormGroup?: boolean;
}

const Message: React.FC<MessageProps> = ({ type, content, hasFormGroup }) => {
  const blockClass = clsx(hasFormGroup && 'form-group', `d-flex-ac w-100 form-message ${type} bg--${type}`);
  return (
    <div className={blockClass}>
      {type === 'info' && <Icon defaultClassName={false} className="form-message__icon" icon="info-solid" />}
      <div className="flex-auto">{content}</div>
    </div>
  );
};

export default Message;
