import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppDispatch } from 'store';
import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import { Sport } from 'utils/common/types/sportTypes';
import config from 'utils/config';
import {
  CoinAcceptorInfo,
  DepositTerminalResponse,
  MoneyValidatorContent,
  SportsTerminalTypes,
  PrinterStatus,
  RetailDepositInfo,
  TerminalThunks,
} from '../types/ssbt.types';

const initialState: SportsTerminalTypes = {
  moneyValidatorData: null,
  error: false,
  printerStatus: null,
  coinAcceptorInfo: null,
  isCoinAcceptorStopped: false,
  isRgbControllerStopped: false,
  totalSum: 0,
  billAmount: null,
  billSum: 0,
  depositInfo: null,
  depositInfoStatus: 'idle',
  errorModalVisible: false,
  depositRetail: null,
  depositRetailStatus: 'idle',
  activeStep: 1,
  isTicketSelectedFromBarcodeReader: false,
  isPopulateBetSlipFromQrCode: false,
  payoutTicketStatusSSBT: 'idle',
};

export const terminalThunks: TerminalThunks = {
  depositTerminal: createAbortThunk(`terminal/depositTerminal`, async ({ totalSum }) => {
    const url = `${config.API_URL}/api/payment/player/ssbt/deposit`;
    const response = await axiosInstance.post(url, { amount: totalSum });
    return response.data;
  }),

  depositRetail: createAbortThunk(`terminal/depositRetail`, async ({ totalSum }) => {
    const url = `${config.API_URL}/api/pretail/v1/terminal/deposit`;
    const response = await axiosInstance.post(url, { amount: totalSum });
    return response.data;
  }),
  ssbtPayoutTicket: createAbortThunk(`terminal/ssbtPayout`, async ({ code }) => {
    const url = `${config.API_URL}/api/pretail/agent/v2/ticket/payout`;
    const response = await axiosInstance.post(url, { code: code });
    return response.data;
  }),
};

const ssbtSlice = createSlice({
  name: 'ssbtSlice',
  initialState,
  reducers: {
    getMoneyValidatorContentSuccess(state, action) {
      state.moneyValidatorData = action.payload;
      state.totalSum = action.payload.amt;
    },
    getMoneyValidatorContentError(state) {
      state.error = true;
    },
    getPrinterStatusInfo(state, action) {
      state.printerStatus = action.payload;
    },
    getCoinAcceptorInfo(state, action) {
      state.coinAcceptorInfo = action.payload;
      state.totalSum += Number(action.payload.amt) / 100;
    },
    setModalVisible(state, action) {
      state.errorModalVisible = action.payload;
    },
    resetTerminalState: (state, action) => ({ ...initialState, ...action.payload }),
    resetTotalSum(state) {
      state.totalSum = 0;
    },
    resetDepositStatus: (state) => {
      state.depositInfoStatus = 'idle';
    },
    getBillAmountInfo(state, action) {
      state.billAmount = action.payload;
    },
    setActiveStep(state, action) {
      state.activeStep = action.payload;
    },
    setBarcodeSelected(state, action) {
      state.isTicketSelectedFromBarcodeReader = action.payload;
    },
    setIsPopulateBetSlipFromQrCode(state, action) {
      state.isPopulateBetSlipFromQrCode = action.payload;
    },
    stopCoinAcceptorCommand(state, action) {
      state.isCoinAcceptorStopped = action.payload;
    },
    stopRgbControllerCommand(state, action) {
      state.isRgbControllerStopped = action.payload;
    },
    resetStateDepositTicketSSBT(state) {
      state.payoutTicketStatusSSBT = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      terminalThunks.depositTerminal.fulfilled,
      (state, action: PayloadAction<DepositTerminalResponse>) => {
        state.depositInfo = action.payload;
        state.depositInfoStatus = 'succeeded';
        state.totalSum = 0;
      },
    );
    builder.addCase(terminalThunks.depositTerminal.pending, (state) => {
      state.depositInfoStatus = 'pending';
    });
    builder.addCase(terminalThunks.depositTerminal.rejected, (state) => {
      state.depositInfoStatus = 'failure';
    });
    builder.addCase(terminalThunks.depositRetail.fulfilled, (state, action: PayloadAction<RetailDepositInfo>) => {
      state.depositRetail = action.payload;
      state.depositRetailStatus = 'succeeded';
      state.totalSum = 0;
    });
    builder.addCase(terminalThunks.depositRetail.pending, (state) => {
      state.depositRetailStatus = 'pending';
    });
    builder.addCase(terminalThunks.depositRetail.rejected, (state) => {
      state.depositRetailStatus = 'failure';
    });
    builder.addCase(terminalThunks.ssbtPayoutTicket.fulfilled, (state) => {
      state.payoutTicketStatusSSBT = 'succeeded';
    });
    builder.addCase(terminalThunks.ssbtPayoutTicket.pending, (state) => {
      state.payoutTicketStatusSSBT = 'pending';
    });
    builder.addCase(terminalThunks.ssbtPayoutTicket.rejected, (state) => {
      state.payoutTicketStatusSSBT = 'failure';
    });
  },
});

export const {
  getMoneyValidatorContentSuccess,
  getMoneyValidatorContentError,
  getPrinterStatusInfo,
  getCoinAcceptorInfo,
  resetTerminalState,
  resetTotalSum,
  getBillAmountInfo,
  setModalVisible,
  setActiveStep,
  setBarcodeSelected,
  setIsPopulateBetSlipFromQrCode,
  stopCoinAcceptorCommand,
  stopRgbControllerCommand,
  resetStateDepositTicketSSBT,
  resetDepositStatus,
} = ssbtSlice.actions;

export default ssbtSlice.reducer;

export const runBillingValidator =
  () =>
  async (dispatch: AppDispatch): Promise<void> => {
    const port = 'http://localhost:10001/';
    try {
      const url = `${port}bnv_event.json`;
      const response = await axios.post(url);
      dispatch(getMoneyValidatorContentSuccess(response.data));
      if (response.data.code.includes('BNV_EVT_PAYOUT')) {
        dispatch(resetTotalSum());
      }
    } catch (err) {
      dispatch(getMoneyValidatorContentError());
    }
  };
// DO NOT DELETE - we will need this if we have withdraw functionality
export const runBillAmountCommand = () => async (dispatch) => {
  try {
    const url = 'http://localhost:10001/bnv_cmd_all_amounts.json';
    const response = await axios.post(url);
    dispatch(getBillAmountInfo(response.data));
  } catch (err) {
    getError.message(err);
  }
};

export const runPrinterStats =
  () =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const url = 'http://localhost:10000/prn_stat.json';
      const response = await axios.post(url);
      dispatch(getPrinterStatusInfo(response.data));
    } catch (err) {
      getError.message(err);
    }
  };

export const runCoinAcceptorCommand =
  () =>
  async (dispatch: AppDispatch): Promise<void> => {
    const url = 'http://localhost:10003/azkoyen_event.json';

    const response = await axios.post(url);
    dispatch(getCoinAcceptorInfo(response.data));
  };

export const enableCoinAcceptor = async (): Promise<void> => {
  const enableAcceptorUrl = 'http://localhost:10003/azkoyen_cmd_x6_enable.json';
  await axios.post(enableAcceptorUrl);
};
export const runTouchScreenCalibration = async (): Promise<void> => {
  const calibrationTouchScreenApiUrl = 'http://127.0.0.1:10004/xtouch_calib_start.json';
  await axios.post(calibrationTouchScreenApiUrl);
};

export const enableMoneyValidator = async (): Promise<void> => {
  const url = 'http://localhost:10001/bnv_cmd_enable.json';
  await axios.post(url);
};

export const selectSSBTdata = {
  payoutTicketStatusSSBT: (state: RootState) => state.retail.ssbt.payoutTicketStatusSSBT,
  moneyValidatorData: (state: RootState): MoneyValidatorContent | null => state.retail.ssbt.moneyValidatorData,
  moneyValidatorError: (state: RootState): boolean => state.retail.ssbt.error,
  printerStatusInfo: (state: RootState): PrinterStatus | null => state.retail.ssbt.printerStatus,
  coinAcceptorInfo: (state: RootState): CoinAcceptorInfo | null => state.retail.ssbt.coinAcceptorInfo,
  isCoinAcceptorStopped: (state: RootState): boolean => state.retail.ssbt.isCoinAcceptorStopped,
  isRgbControllerStopped: (state: RootState): boolean => state.retail.ssbt.isRgbControllerStopped,
  totalSum: (state: RootState): number => state.retail.ssbt.totalSum,
  //billAmount: (state: RootState) => state.ssbt.billAmount --> uncomment if we have withdraw functionality
  depositInfo: (state: RootState): DepositTerminalResponse | null => state.retail.ssbt.depositInfo,
  depositInfoStatus: (state: RootState): Sport.StateStatus => state.retail.ssbt.depositInfoStatus,
  errorModalVisible: (state: RootState): boolean => state.retail.ssbt.errorModalVisible,
  billSum: (state: RootState) =>
    (state.retail.ssbt.billAmount?.list || []).reduce(
      (result, item) => Number(item.denom) * Number(item.num) + result,
      0,
    ),

  // withdrawAmount: (state: RootState) =>
  //   Math.abs((Number(state.ssbt.totalSum) % 5) - Number(state.ssbt.totalSum)), // value, which customer can withdraw if he does not want to bet all the sum from balance
  depositRetailInfo: (state: RootState): RetailDepositInfo | null => state.retail.ssbt.depositRetail,
  depositRetailStatus: (state: RootState) => state.retail.ssbt.depositRetailStatus,
  activeStep: (state: RootState) => state.retail.ssbt.activeStep,
  isTicketSelectedFromBarcodeReader: (state: RootState) => state.retail.ssbt.isTicketSelectedFromBarcodeReader,
  isPopulateBetSlipFromQrCode: (state: RootState) => state.retail.ssbt.isPopulateBetSlipFromQrCode,
};
