import styled from 'styled-components';
import { Breakpoints } from 'theme/Theme';

const CardThumb = styled.div.attrs({ className: 'prm-lobby-card__image' })`
  flex-shrink: 0;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  min-height: 234px;
  @media (min-width: ${Breakpoints.isMobile}) {
    min-height: 290px;
  }
  @media (min-width: ${Breakpoints.isDesktop}) {
    min-height: 320px;
  }

  aspect-ratio: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: height 0.15s;
`;

export default CardThumb;
