import { Buffer } from 'buffer';
import { decode } from '@msgpack/msgpack';
import { loginThunks } from 'pages/auth/login/slice/login.slice';
import userStorage from 'pages/auth/login/UserStorage';
import { Balance, BalanceType } from 'shared/common/features/myProfile/types/myProfile.types';
import { AppDispatch } from 'store';
import { EventService } from 'utils/common/EventService';
import { getWindowConfigParam } from 'utils/common/helpersCommon';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import { PreferredTheme } from '../types/myProfile.types';

export const detectIOS = (): boolean =>
  /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

export const logoutAction = (dispatch: AppDispatch) => {
  EventService.emitEvent('logout', {});
  dispatch(loginThunks.logout());
};

export const accTabsWithBadge = ['offers', 'notifications', 'captainUp'];

export const getPreferredThemeSkin = (): PreferredTheme => {
  const theme = userStorage.getPreferredThemeSkin() as PreferredTheme | null;
  if (theme === null) {
    const buThemeSkin = getWindowConfigParam('DEFAULT_THEME_SKIN');
    userStorage.setPreferredThemeSkin(buThemeSkin);
    return buThemeSkin;
  }
  return theme;
};

export const getDefaultBusinessUnitThemeSkin = (): PreferredTheme => getWindowConfigParam('DEFAULT_THEME_SKIN');

const SPORT_VERTICALS = ['sports', 'virtual', 'esports', 'in-play'];
const CASINO_VERTICALS = ['casino', 'casino-egt', 'vegas', 'casino-games', 'live-games'];
const LIVE_CASINO_VERTICALS = ['live-casino'];

const FREE_BET_SPORT_VERTICALS = ['sports', 'virtual', 'esports', 'in-play'];
const FREE_BET_LIVE_CASINO_VERTICALS = ['live-casino'];
const FREE_BET_TOTAL_VERTICALS = ['my-account', 'promo', 'promotions'];

type MatchBalanceReturnType =
  | BalanceType.SportBalanceTotal
  | BalanceType.CasinoBalanceTotal
  | BalanceType.LiveCasinoBalanceTotal
  | BalanceType.BalanceTotal;

export const matchBalanceType = (vertical: string): MatchBalanceReturnType => {
  if (SPORT_VERTICALS.includes(vertical)) {
    return BalanceType.SportBalanceTotal;
  } else if (CASINO_VERTICALS.includes(vertical)) {
    return BalanceType.CasinoBalanceTotal;
  } else if (LIVE_CASINO_VERTICALS.includes(vertical)) {
    return BalanceType.LiveCasinoBalanceTotal;
  }
  return BalanceType.BalanceTotal;
};

export const calculateFreeBetBalance = (vertical: string, balance: Balance): number => {
  if (FREE_BET_SPORT_VERTICALS.includes(vertical)) {
    return balance[BalanceType.FreeBetSportBalance];
  } else if (FREE_BET_LIVE_CASINO_VERTICALS.includes(vertical)) {
    return balance[BalanceType.FreeBetLiveCasinoBalance];
  } else if (FREE_BET_TOTAL_VERTICALS.includes(vertical)) {
    return balance[BalanceType.FreeBetSportBalance] + balance[BalanceType.FreeBetLiveCasinoBalance];
  }
  return 0;
};

export const singleBonusOffersProgressBarTitle = (type) => {
  const defaultTitle = MyProfile.OffersTab.bonusBalance;
  const mapTItle = {
    freeSpinsWinRollover: MyProfile.OffersTab.wonFromFreeSpins,
  };

  return mapTItle[type] ?? defaultTitle;
};
export const singleBonusOffersTableTitle = (type) => {
  const defaultTitle = MyProfile.OffersTab.remainingBet;
  const mapTItle = {
    freeSpinsWinRollover: MyProfile.OffersTab.remainingProfitToRoll,
  };

  return mapTItle[type] ?? defaultTitle;
};

export const leftToRightAnimation = {
  open: {
    x: 0,
    opacity: 1,
    zIndex: 160,
    transition: {
      x: { stiffness: 1000, velocity: 160 },
    },
  },
  closed: {
    x: 100,
    opacity: 0,
    zIndex: 1,
    transition: {
      x: { stiffness: 1000 },
    },
  },
};

export const parseCampaign = (notification: string) => {
  const decodedResponse = decode(Buffer.from(notification, 'base64')) as string;

  return JSON.parse(decodedResponse);
};
