import { useEffect, useState, useRef } from 'react';
import { getTimeData, TimeDataType, getUserLocalTime } from '../utils/dateUtils';

export type CountDownTimer = {
  timer: TimeDataType;
  isExpired: boolean;
  prevTime?: TimeDataType;
};

const calculateRemainingTime = (endDate, useLocalTime) => {
  const endDateConvert = new Date(endDate).getTime();
  if (useLocalTime) {
    return endDateConvert - getUserLocalTime();
  } else {
    return endDateConvert - new Date().getTime();
  }
};

export const useCountDown = (endDate: string | number, timeZone: 'localTime' | 'systemTime'): CountDownTimer => {
  const useLocalTime = timeZone === 'localTime' ? true : false;
  const [time, setTime] = useState<number>(calculateRemainingTime(0, useLocalTime));

  useEffect(() => {
    setTime(calculateRemainingTime(endDate, useLocalTime));
  }, [endDate, useLocalTime]);

  const isExpired = useLocalTime
    ? getUserLocalTime() > new Date(endDate).getTime()
    : new Date().getTime() > new Date(endDate).getTime();

  const interval = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const timeInterval = setInterval(() => setTime(calculateRemainingTime(endDate, useLocalTime)), 1000);
    interval.current = timeInterval;

    return () => clearInterval(timeInterval);
  }, [endDate, useLocalTime]);

  useEffect(() => {
    if (isExpired && interval.current) {
      clearInterval(interval.current);
    }
  }, [time, isExpired]);

  const result: CountDownTimer = {
    timer: getTimeData(time),
    isExpired,
    prevTime: useLocalTime ? undefined : getTimeData(calculateRemainingTime(endDate, useLocalTime) + 1000),
  };

  return result;
};
