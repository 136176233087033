import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  SearchType,
  selectActiveSearchType,
} from 'shared/common/features/appHeader/components/common/slice/navSearch.slice';
import useHeaderConfig from 'shared/common/hooks/useHeaderConfig';
import { useAppDispatch, useAppSelector } from 'store';
import { DrawerUI } from './DrawerUI';
import useCasinoWidgetConfig from '../../../../../modules/casino/platformImplementation/hooks/useCasinoWidgetConfig';
import { BurgerUI } from '../../burger/components/BurgerUI';
import { navigateToCasinoIntegration, navigateToIntegration } from '../../integrations/utils/integrations.utils';
import { setDrawer } from '../slice/drawer.slice';

interface Props {
  showSeparator?: boolean;
}

const DrawerSearch: React.FC<Props> = ({ showSeparator = false }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const casinoWidgetConfig = useCasinoWidgetConfig();

  const { hideSportSearchIcon } = useHeaderConfig();
  const activeSearchType = useAppSelector(selectActiveSearchType);

  const handleOpenSearch = useCallback(() => {
    dispatch(setDrawer(false));
    navigateToIntegration({ fullPath: activeSearchType });

    if (casinoWidgetConfig) {
      navigateToCasinoIntegration({ fullPath: activeSearchType });
    }

    navigate(activeSearchType, {
      state: { internalNavigate: true },
    });
  }, [dispatch, activeSearchType]);

  if (activeSearchType === SearchType.Sport && hideSportSearchIcon) {
    return null;
  }

  return (
    <>
      {showSeparator && <BurgerUI.Separator className="mt-1" />}
      <DrawerUI.Search onClick={handleOpenSearch} text={t('Drawer.Texts.searchText')} />
    </>
  );
};

export default DrawerSearch;
