import { useEffect } from 'react';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { clearClaimTicket, clearFormData, selectClaimTicket } from '../slice/claimTicket.slice';

const useClaimTicketModal = () => {
  const step = useAppSelector(selectClaimTicket.step);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(hideRootModal());
  };

  useEffect(() => {
    return () => {
      dispatch(clearClaimTicket());
      dispatch(clearFormData());
      dispatch(hideRootModal());
    };
  }, [dispatch]);

  return { showCloseIconInHeader: step === 1, onClose: handleClose };
};

export default useClaimTicketModal;
