import { createSlice } from '@reduxjs/toolkit';
import { Casino } from 'modules/casino/shared/types';
import { setDeviceType } from 'shared/common/sharedSlices/commonActions';
import { AppDispatch } from 'store';
import type { RootState } from 'store/rootReducer';
import axiosInstance from 'utils/common/axios-instance';
import { localLog } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { setTickerTTL } from './ticker.slice';
import { MostPlayedInitialState, TickerItem } from '../types/ticker.types';

const initialState: MostPlayedInitialState = {
  tickerItems: [],
  casinoCategories: [],
  jackpots: [],
  isExpanded: false,
  showTicker: false,
};

const producerStatus = createSlice({
  name: 'mostPlayed',
  initialState,
  reducers: {
    setTickerItems(state, action) {
      state.tickerItems = action.payload;
    },
    setCasinoCategories(state, action) {
      state.casinoCategories = action.payload;
    },
    setJackpots(state, action) {
      state.jackpots = action.payload;
    },
    setIsExpanded(state, action) {
      state.isExpanded = action.payload;
    },
    setShowTicker(state, action) {
      state.showTicker = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setDeviceType, (state) => {
      state.showTicker = false;
    });
  },
});

export const { setTickerItems, setCasinoCategories, setJackpots, setIsExpanded, setShowTicker } =
  producerStatus.actions;

export const selectTickerItems = (state: RootState): TickerItem[] => state.mostPlayed.tickerItems;
export const selectCasinoCategories = (state: RootState): Casino.Category[] => state.mostPlayed.casinoCategories;
export const selectIsExpanded = (state: RootState): boolean => state.mostPlayed.isExpanded;
export const selectShowTicker = (state: RootState): boolean => state.mostPlayed.showTicker;

export default producerStatus.reducer;

export const fetchTickerItems =
  () =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const response = await axiosInstance.get(`${config.API_URL}/api/cms/public/ticker-line`);
      const tickerItems = response.data.tickerItems as TickerItem[]; // items['tickerItems'] as unknown as TickerItem[]; //

      dispatch(setTickerTTL(Date.now()));
      dispatch(setTickerItems(tickerItems));
    } catch (error) {
      localLog({ message: `Error: ${error}` });
    }
  };
