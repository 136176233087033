import React, { useMemo, useState } from 'react';
import { FrameAnimator } from 'components/shared/FrameAnimator';
import { getCdnImages } from 'components/shared/FrameAnimator/utils/ImageCDN';
import { mapAnimationFrames } from 'components/shared/FrameAnimator/utils/images/utils';
import userStorage from 'pages/auth/login/UserStorage';
import {
  readNotification,
  setActivePopupNotificationId,
} from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationSlice';
import { NotificationTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationTypes';
import { useAppDispatch } from 'store';
import Bar from './components/Bar';
import { Bell } from './components/Bell';
import { Frame } from './components/Frame';
import { SCHammer, SCMeter, SCMeterWrapper, SCPlayArea } from './components/Meter';
import { Wrapper } from './components/Wrapper';
import { hammerCampaignImages } from './utils/cashBell.utils';

interface CashBellProps {
  animationParams: NotificationTypes.GamificationModalParams;
  folder: NotificationTypes.GamificationFolder;
  notification: NotificationTypes.PopUpNotification;
  setAnimationComplete: (isVisible: boolean) => void;
  children: React.ReactNode;
}

const CashBell: React.FC<CashBellProps> = ({
  animationParams,
  folder,
  notification,
  setAnimationComplete,
  children,
}) => {
  const dispatch = useAppDispatch();

  const { bonusData } = notification;
  const singleBonus = bonusData?.bonuses?.[0];
  const [winBarHeight, setWinBarHeight] = useState(0);
  const [hasHit, setHasHit] = useState(false);
  const [hasWin, setHasWin] = useState(false);

  const { frameImgSrc, lightsImgSrc, bellImgSrc, markerImgSrc, arrowImgSrc } = hammerCampaignImages(folder);

  const noOfSegments = bonusData?.otherOptions.length;

  const winnerSegment = bonusData?.otherOptions?.findIndex((option) => option === singleBonus?.title);

  const getWin = () => {
    if (hasHit) return;

    setHasHit(true);
    // Index starts from 0, so add 1
    const win = winnerSegment + 1;

    // Timeout for the hammer to "hit"
    // Bar Height = 300px, so
    setTimeout(() => setWinBarHeight((win * 300) / noOfSegments), 500);

    dispatch(readNotification(notification.id, 'popup')).then(() => {
      userStorage.setUserPopupNotificationId(notification.id);
    });
    dispatch(setActivePopupNotificationId(notification.id));

    // Timeout for the marker to "reach" the prize
    setTimeout(() => setHasWin(true), 800);
  };

  const resetWinIndex = () => {
    setWinBarHeight(0);
  };

  const handleBlink = () => {
    setAnimationComplete(true);
  };

  const variants = {
    static: { y: 0 },
    action: { y: -winBarHeight },
  };

  const hammerSequence = useMemo(() => {
    return getCdnImages(mapAnimationFrames(animationParams));
  }, [animationParams]);

  return (
    <Wrapper className="d-grid grid-1x1">
      <Frame src={frameImgSrc} />
      <Frame src={lightsImgSrc} $hasAnimation={hasWin} onAnimationEnd={handleBlink} />
      <Bell src={bellImgSrc} />
      {children}
      <SCPlayArea>
        <SCMeterWrapper>
          {bonusData?.otherOptions
            ?.slice(0)
            .reverse()
            .map((item: string, index: number) => (
              <Bar
                key={index}
                rowStart={index + 1}
                colStart={Math.abs((index + 1) % 2) == 1 ? 3 : 1}
                isWinner={hasWin && noOfSegments - index - 1 === winnerSegment}
                text={item}
                pseudoElBg={arrowImgSrc}
              />
            ))}
          <SCMeter
            $startRow={noOfSegments + 1}
            variants={variants}
            animate={hasHit ? variants.action : variants.static}
            transition={{ type: 'spring', stiffness: 100, duration: 1.5 }}
            onAnimationComplete={resetWinIndex}
            style={{ backgroundImage: `url(${markerImgSrc})` }}
          />
        </SCMeterWrapper>
        <SCHammer>
          <FrameAnimator
            images={hammerSequence}
            width={animationParams.frameWidth}
            height={animationParams.frameHeight}
            maxWidth={animationParams.frameMaxWidth}
            maxHeight={animationParams.frameMaxHeight}
            aspectRatioWidth={animationParams.frameAspectRatio}
            fps={60}
            iterations={1}
            play={hasHit}
            onClick={getWin}
          />
        </SCHammer>
      </SCPlayArea>
    </Wrapper>
  );
};

export default CashBell;
