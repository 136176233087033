import React, { cloneElement, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { locationNS } from './customTabsUICSS.types';
import { classExtendByLocation, deepMap, isTabList, isTabPanel } from './utils/customTabs.utils';
interface Props {
  className?: string;
  type?: 'primary' | 'secondary' | 'third';
  location?: 'promotions' | 'myAccount';
  childrenBefore?: React.ReactNode;
  childrenAfter?: React.ReactNode;
  children?: React.ReactNode;
  selectedIndex?: number;
  onSelect?: (index: number) => void;
  hasGlider?: boolean;
  hasScroll?: boolean;
  hasSubTabs?: boolean;
}

const Tabs: React.FC<Props> = ({
  className,
  type = 'primary',
  location = 'promotions',
  childrenBefore,
  childrenAfter,
  children,
  selectedIndex,
  onSelect,
  hasGlider,
  hasScroll,
  hasSubTabs,
}) => {
  const [activeTab, setActiveTab] = useState<number>(selectedIndex ? selectedIndex : 0);
  const tabNodes = useRef<HTMLDivElement[]>([]);
  const tabClass = clsx(
    `${locationNS}-${classExtendByLocation[location]}__tabs--${type}`,
    `${locationNS}-${classExtendByLocation[location]}__tabs`,
    className,
  );

  const handleClick = (index: number) => {
    setActiveTab(index);
    onSelect && onSelect(index);
  };

  useEffect(() => {
    if (selectedIndex != undefined && selectedIndex >= 0) {
      setActiveTab(selectedIndex);
      onSelect && onSelect(selectedIndex);
    }
  }, [selectedIndex]);

  const childrenWithProps = () => {
    let index = 0;
    return deepMap(children, (child) => {
      let result = child;

      if (isTabList(child)) {
        const tabListProps = {
          type,
          location,
          childrenBefore,
          childrenAfter,
          activeElementRef: tabNodes.current[`tabs-${activeTab}`],
          hasGlider,
          hasScroll,
          hasSubTabs,
        };

        result = cloneElement(child, {
          ...tabListProps,
          children: deepMap(child.props.children, (tab, idx) => {
            const key = `tabs-${idx}`;
            const isActive = activeTab === idx;
            const props = {
              tabRef: (node) => {
                tabNodes.current[key] = node;
              },
              isActive: isActive,
              handleClick,
              type,
              location,
              index: idx,
              hasGlider,
              hasScroll: false,
              hasSubTabs,
            };

            return React.cloneElement(tab, props);
          }),
        });
      } else if (isTabPanel(child)) {
        const props = {
          isActiveIndex: activeTab,
          type,
          isActive: activeTab === index,
          location,
        };
        props['index'] = index;
        index++;
        result = React.cloneElement(child, props);
      }

      return result;
    });
  };

  if (!children) return null;
  return <div className={tabClass}>{childrenWithProps()}</div>;
};
Tabs.displayName = 'Tabs';

export default Tabs;
