type Props = {
  currentLevelPoints?: number;
  nextLevelPoints?: number;
  points?: number;
};
export const useLevelProgress = ({ currentLevelPoints = 0, nextLevelPoints = 0, points = 0 }: Props): number => {
  const current = points - currentLevelPoints;
  const next = nextLevelPoints - currentLevelPoints;

  if (currentLevelPoints === nextLevelPoints) return 100;
  return (current / next) * 100;
};
