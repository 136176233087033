import styled from 'styled-components';
import { Breakpoints } from 'modules/casino/theme/Theme';

const DescriptionStyled = styled.div`
  padding: 2px 10px 4px 10px;
  line-height: 1.1;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    line-height: 21px;
  }
`;
export default DescriptionStyled;
DescriptionStyled.displayName = 'DescriptionStyled';
