import React from 'react';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import { cdnUrl } from './CDNSVG.types';

/** CDNSVG */
interface CDNSVGProps extends Omit<React.SVGProps<SVGElement>, 'onLoad' | 'onError' | 'ref'> {
  icon: string;
  className?: string;
  size?: number;
  onLoad?: (src: string, isCached: boolean) => void;
  onError?: (error) => void;
}

const CDNSVG: React.FC<CDNSVGProps> = ({ className, size, icon, onLoad, onError, ...props }) => {
  const iconSrc = cdnUrl + '/' + icon;
  const svgClass = clsx(className);

  const handleOnLoad = (src: string, isCached: boolean) => {
    onLoad && onLoad(src, isCached);
  };

  const handleOnError = (error) => {
    onError && onError(error);
  };

  return (
    <SVG
      className={svgClass}
      width={size}
      height={size}
      src={iconSrc}
      cacheRequests={true}
      loader={<span className={svgClass}></span>}
      onLoad={handleOnLoad}
      onError={handleOnError}
      {...props}
    >
      <span className={svgClass}></span>
    </SVG>
  );
};

export default CDNSVG;
