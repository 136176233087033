import React from 'react';
import {
  SCTimeCounterItem,
  SCTimeCounterItemDate,
  SCTimeCounterItemText,
} from './styled/CampaignInfoTimeCounter.styled';

type Props = {
  date: string;
  text: string;
};

const LeaderBoardTimeCounterItem: React.FC<Props> = ({ date, text }) => {
  return (
    <SCTimeCounterItem>
      <SCTimeCounterItemDate>{date}</SCTimeCounterItemDate>
      <SCTimeCounterItemText>{text}</SCTimeCounterItemText>
    </SCTimeCounterItem>
  );
};

export default LeaderBoardTimeCounterItem;
