import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Text } from 'components/shared';
import SportIconCDN from 'components/shared/icons/SportIconCDN';
import { EventUI } from 'components/shared/sportContainers/event/EventUI';
import { formatEventStartDate } from 'modules/retail/shared/utils/retail.utils';
import { MyBets } from 'pages/myAccount/tabs/history/sections/sportsHistory/types/myBets.types';
import { isEmpty, retailFormatDateReceipt } from 'utils/common/helpersCommon';
import { Sport } from 'utils/common/types/sportTypes';
import Market from './Market';
import PrematchDate from './PrematchDate';

import BetBuilderInfo from '../../features/checkTicket/components/BetBuilderInfo';
import { CustomBetOutcomes } from '../../types/ssbt.types';

/** BetSeletionUI Body Static */
interface BodyStaticProps {
  customBetOutcomes: CustomBetOutcomes[];
  matchStatus?: string;
  homeTeam: string;
  awayTeam: string;
  homeScore?: string;
  awayScore?: string;
  bankerLabel: string;
  handleEventClick: () => void;
  handleStatsClick?: React.MouseEventHandler<SVGSVGElement>;
  isEventClickable: boolean;
  market: string;
  prematchEventDate: string | undefined;
  prematchEventName: string;
  showBankerLabel: boolean;
  showPrematchEventDate: boolean;
  showStats?: boolean;
  eventClockStatus: Sport.EventClockStatus;
  sportEventType: Sport.SportEventType;
  sportId: number;
  marketStatus?: string;
  isVermantiaEvent: boolean;
  selectionBonuses: MyBets.SelectionBonusesTypes;
}

const BodyStatic: React.FC<BodyStaticProps> = ({
  customBetOutcomes,
  homeTeam,
  awayTeam,
  bankerLabel,
  handleEventClick,
  handleStatsClick,
  isEventClickable,
  market,
  prematchEventDate,
  prematchEventName,
  showBankerLabel,
  showPrematchEventDate,
  showStats,
  eventClockStatus,
  sportEventType,
  sportId,
  marketStatus,
  isVermantiaEvent,
  selectionBonuses,
}) => {
  const eventLinkStyle = clsx('text-truncate event-name', isEventClickable && 'event-name-link');
  const clickHandler = useCallback(() => {
    isEventClickable && handleEventClick();
  }, [isEventClickable, handleEventClick]);
  const isCustomBet = !isEmpty(customBetOutcomes);

  const renderEventInfo = () => {
    if (!isVermantiaEvent) {
      if (sportEventType === 'match' && eventClockStatus.homeScore && eventClockStatus.awayScore) {
        return (
          <span onClick={clickHandler} className={eventLinkStyle}>
            {homeTeam} <strong>{eventClockStatus.homeScore}</strong> : <strong>{eventClockStatus.awayScore}</strong>{' '}
            {awayTeam}
          </span>
        );
      }
      if (isCustomBet && !isEmpty(customBetOutcomes[0].eventPath)) {
        return <Text className={eventLinkStyle} onClick={clickHandler} text={customBetOutcomes[0].eventPath} />;
      } else {
        return <Text className={eventLinkStyle} onClick={clickHandler} text={prematchEventName} />;
      }
      // for vermantia homeScore and awayScore are "0" before and during the game, that's why we make this condition and show result after market status "Settled"
    } else if (isVermantiaEvent && marketStatus === 'Settled') {
      return (
        <span onClick={clickHandler} className={eventLinkStyle}>
          {homeTeam} <strong>{eventClockStatus.homeScore}</strong> : <strong>{eventClockStatus.awayScore}</strong>
          {awayTeam}
        </span>
      );
    } else {
      return <Text className={eventLinkStyle} onClick={clickHandler} text={prematchEventName} />;
    }
  };

  return (
    <div className="d-flex-col egtd-p-bi-sel__body">
      <Market
        market={market}
        showBankerLabel={showBankerLabel}
        bankerLabel={bankerLabel}
        selectionBonuses={selectionBonuses}
      />

      <div className="d-flex-col">
        <div className="d-flex-ac">
          <SportIconCDN type="betslip" icon={sportId} />
          {renderEventInfo()}
          {showStats && <EventUI.Link className="ml-2" linkType={'live-stats'} onClick={handleStatsClick} />}
        </div>
        <PrematchDate
          isVisible={showPrematchEventDate}
          prematchEventDate={
            isCustomBet
              ? retailFormatDateReceipt(customBetOutcomes[0].scheduledTime)
              : formatEventStartDate(prematchEventDate)
          }
        />

        <BetBuilderInfo customBetOutcomes={customBetOutcomes} />
      </div>
    </div>
  );
};

export default BodyStatic;
