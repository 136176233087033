import React from 'react';
import clsx from 'clsx';
import CTAButton from './CTAButton';

interface RankingLinkProps {
  className?: string;
  round?: string;
  period?: string;
  linkText: string;
  isDisabled?: boolean;
  onClick: React.MouseEventHandler;
}

const RankingLink: React.FC<RankingLinkProps> = ({ className, round, period, linkText, isDisabled, onClick }) => {
  const linkClass = clsx('d-grid my-2 prm-rank-link', isDisabled && 'prm-rank-link--disabled', className);
  return (
    <div className={linkClass}>
      {round && <span className="prm-rank-link__round">{round}</span>}
      {period && <span className="text-tabular prm-rank-link__period">{period}</span>}
      <CTAButton
        variant="accent"
        className="prm-rank-link__btn"
        text={linkText}
        onClick={onClick}
        disabled={isDisabled}
      />
    </div>
  );
};

export default RankingLink;
