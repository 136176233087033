import { useMemo } from 'react';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { selectInGameNotifications } from '../slice/inGameNotifications.slice';
import { findActiveAwardNotificaton } from '../utils/wsNotifications.utils';

const useActiveNotification = () => {
  const inGameRewards = useAppSelector(selectInGameNotifications.notifications);
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);

  const activeNotification = useMemo(() => {
    if (isAuthenticated && !isEmpty(inGameRewards)) {
      return findActiveAwardNotificaton(inGameRewards);
    }

    return undefined;
  }, [inGameRewards, isAuthenticated]);

  return activeNotification;
};

export default useActiveNotification;
