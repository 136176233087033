import React, { memo } from 'react';
import CellWrapper from './components/CellWrapper';
import { thirdPartySubTypeMap } from './subTypeWidgets/ThirdPartySubTypeMap';
import { CasinoWidgetsTypes } from '../../../types/casinoWidgetsTypes';

interface WidgetsProps {
  widget: CasinoWidgetsTypes.Widget;
}

const ThirdPartyCellWidget: React.FC<WidgetsProps> = ({ widget }) => {
  const { id, template, thirdPartyType } = widget || {};

  const renderSubTypeWidget = () => {
    const SubTypeWidget = thirdPartyType && thirdPartySubTypeMap[thirdPartyType];

    if (SubTypeWidget) {
      return <SubTypeWidget widget={widget} />;
    }

    return <></>;
  };

  return (
    id && (
      <CellWrapper templateType={template?.type} widgetSubType={thirdPartyType}>
        {renderSubTypeWidget()}
      </CellWrapper>
    )
  );
};

export default memo(ThirdPartyCellWidget);
