import { RefObject } from 'react';

export enum arrowStyleTypes {
  CasinoGrid = 'casino-grid',
  CasinoMenus = 'casino-menus',
}

export declare module CasinoScroll {
  type ScrollWrapperProps = {
    children: React.ReactNode;
    centered?: boolean;
    typeArrow: arrowStyleTypes;
    useDefaultChevron?: boolean;
    moveWith?: number;
    forceArrowsUpdate?: boolean;
  };

  type ScrollContainerProps = {
    children: React.ReactNode;
    setRefs: (node: HTMLDivElement) => void;
  };

  type ScrollArrowsProps = {
    centered?: boolean;
    scrollContainerRef: RefObject<HTMLDivElement>;
    typeArrow: arrowStyleTypes;
    useDefaultChevron?: boolean;
    moveWith: number;
    inView: boolean;
    forceArrowsUpdate?: boolean;
  };

  type ArrowProps = {
    scrollEl: HTMLElement;
    typeArrow: arrowStyleTypes;
    useDefaultChevron?: boolean;
    moveWith: number;
  };
}
