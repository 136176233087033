import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { casinoWidgetsSelectors, fetchWidgetPromoItemIds } from '../../../slice/casinoWidgetsSlice';

type Props = {
  widgetId: string;
  query: string;
};

export const useWidgetPromoItemIdsFetch = ({ widgetId, query }: Props): { widgetPromoItemIds: string[] } => {
  const dispatch = useAppDispatch();
  const widgetPromoItemIds = useAppSelector((state) => casinoWidgetsSelectors.widgetPromoItemIds(state, widgetId));

  useEffect(() => {
    if (query && widgetId && !widgetPromoItemIds.length) {
      dispatch(fetchWidgetPromoItemIds({ query, id: widgetId }));
    }
  }, [dispatch, query, widgetId, widgetPromoItemIds.length]);

  return { widgetPromoItemIds };
};
