import React, { RefObject } from 'react';
import clsx from 'clsx';

type Props = Omit<React.HTMLProps<HTMLButtonElement>, 'type'> & {
  type?: 'button' | 'submit' | 'reset';
  classNames?: string;
  onClickHandler?: React.MouseEventHandler<HTMLButtonElement>;
  value?: string;
  buttonRef?: RefObject<HTMLButtonElement>;
  dataQid?: string;
};

const Button: React.FC<Props> = ({
  buttonRef,
  children,
  classNames,
  onClickHandler,
  type = 'button',
  value,
  // dataQid,
  ...props
}) => {
  const btnClass = clsx('btn', classNames);

  return (
    <button type={type} className={btnClass} onClick={onClickHandler} ref={buttonRef} {...props}>
      <>
        {value}
        {children}
      </>
    </button>
  );
};

export default Button;
