import React from 'react';

interface CashierTicketBodyProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const CashierTicketBody: React.FC<CashierTicketBodyProps> = ({ children, style }) => {
  const classProp = { class: 'custom-scrollbars overflow-y-auto overflow-x-hidden' };
  return (
    <egtd-cashier-ticket-body style={style} {...classProp}>
      {children}
    </egtd-cashier-ticket-body>
  );
};

export default CashierTicketBody;
