import React from 'react';
import clsx from 'clsx';
import { bonusLogComponentLocationCSSSuffixesMap, BonusLogComponentLocationType, cssNS } from './bonusLogUITypes';

/** BonusLog Progress Table */
interface TableProps {
  componentLocation: BonusLogComponentLocationType;
  showTableHeader?: boolean;
  headerText?: string;
  headerTextTail?: string;
  showTableFooter?: boolean;
  footerText?: string;
  children?: React.ReactNode;
}

const Table: React.FC<TableProps> = ({
  componentLocation,
  showTableHeader,
  headerText,
  headerTextTail,
  showTableFooter,
  footerText,
  children,
}) => {
  const tableClass = clsx(
    `bonus-table bonus-text bonus-text${bonusLogComponentLocationCSSSuffixesMap[componentLocation]}`,
  );

  return (
    <div className={tableClass}>
      {showTableHeader && (
        <div className="w-100 bonus-table__row">
          <span className={`${cssNS}__text--accent ${cssNS}__text--sm`}>{headerText}&nbsp;</span>
          <span>({headerTextTail}):</span>
        </div>
      )}
      {children}
      {showTableFooter && <div className="w-100 bonus-table__row">{footerText}</div>}
    </div>
  );
};

export default Table;
