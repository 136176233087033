import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Icon, Modal } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { DataLoader } from 'components/shared/loaders';
import { ModalUI } from 'components/shared/ModalUI';
import { NotificationBlock } from 'components/shared/notifications';
import { UI } from 'components/shared/UI';
import { useOutsideClick } from 'hooks/common/useOutsideClick';
import { loginThunks } from 'pages/auth/login/slice/login.slice';
import ParseHTML from 'shared/common/ParseHTML';
import { useAppDispatch, useAppSelector } from 'store';
import { TermsAndConditions } from 'utils/common/translationUtils/translationStrings';
import { manageBodyNoScroll } from './utils/rootModal.utils';
import { selectTermsAndConditions, useTermsAndConditions } from '../../termsAndConditions';

interface Props {
  alias: string;
  version: string;
  subVersion: string;
  style?: CSSProperties;
}

const TermsAndConditionsModal: React.FC<Props> = ({ alias, version, subVersion }): JSX.Element | null => {
  const isLoading = useAppSelector(selectTermsAndConditions.isLoading);
  const error = useAppSelector(selectTermsAndConditions.error);
  const termsAndConditionsData = useAppSelector(selectTermsAndConditions.data);
  const [inHelpPage, setInHelpPage] = useState<boolean>(false);
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);
  const modalRef = useRef<null | HTMLDivElement>(null);
  const scrollRef = useRef<null | HTMLDivElement>(null);
  const { onAccept } = useTermsAndConditions({ alias, version, subVersion, shouldFetch: !termsAndConditionsData });
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const logout = useCallback(() => {
    dispatch(loginThunks.logout());
  }, [dispatch]);

  useOutsideClick(modalRef, logout);

  useEffect(() => {
    const scrollContainer = scrollRef?.current;
    if (scrollContainer && !hasScrolled) {
      if (scrollContainer.clientHeight && scrollContainer.clientHeight === scrollContainer.scrollHeight) {
        setHasScrolled(true);
      } else {
        const detectScrollToBottom = ({ currentTarget }) => {
          const scrollToBottom = currentTarget.scrollHeight - currentTarget.clientHeight - currentTarget.scrollTop;
          if (!hasScrolled && scrollToBottom < 10) {
            scrollContainer.removeEventListener('scroll', detectScrollToBottom);
            setHasScrolled(true);
          }
        };
        scrollContainer.addEventListener('scroll', detectScrollToBottom);
        return () => {
          scrollContainer.removeEventListener('scroll', detectScrollToBottom);
        };
      }
    }
  });

  useEffect(() => {
    if (location.pathname.match('/help') && !inHelpPage) {
      manageBodyNoScroll('remove');
      setInHelpPage(true);
    } else if (!location.pathname.match('/help') && inHelpPage) {
      manageBodyNoScroll('add');
      setInHelpPage(false);
    }
  }, [location.pathname]);

  return inHelpPage ? null : (
    <Modal visible ref={modalRef} className={'terms-updated__modal'} scrollable={true}>
      <Modal.Body className={'terms-updated__body no-scrollbar'}>
        {isLoading ? (
          <DataLoader />
        ) : error ? (
          <NotificationBlock
            title={t(TermsAndConditions.Modal.errorTitle)}
            description={error}
            icon={'info-solid'}
            variant={'error'}
          />
        ) : (
          <Flex.Col>
            <Flex.Col className={'terms-updated__header'}>
              <Flex>
                <Icon className={'terms-updated__icon'} icon={'tnc-solid'} />
                <ModalUI.Title className={'terms-updated__title'} text={t(TermsAndConditions.Modal.mainTitle)} />
              </Flex>
              <div className={'terms-updated__header-text'}>{t(TermsAndConditions.Modal.infoText)}</div>
            </Flex.Col>
            <Flex.ColCenter className={'terms-updated__terms-box'}>
              <Flex.Center className={'terms-updated__terms-box--header w-100'}>
                {termsAndConditionsData?.header}
              </Flex.Center>
              <div
                className={'terms-updated__terms-box--terms custom-scrollbars overflow-y-auto overflow-x-hidden w-100'}
                ref={scrollRef}
              >
                <ParseHTML html={termsAndConditionsData?.content || ''} options={{ trim: false }} />
              </div>
            </Flex.ColCenter>
            <Flex className={'terms-updated__text--help'}>
              <span>{t(TermsAndConditions.Modal.helpText)} &nbsp;</span>
              <Link to={'/help'}>{t(TermsAndConditions.Modal.helpLink)}</Link>
            </Flex>
            <p className={'terms-updated__text--info'}>{t(TermsAndConditions.Modal.acceptText)}</p>
            <UI.Button
              className={'w-100'}
              variant={'primary'}
              size={'lg'}
              text={t(TermsAndConditions.Modal.acceptButton)}
              isDisabled={!hasScrolled}
              onClick={onAccept}
            />
          </Flex.Col>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default TermsAndConditionsModal;
