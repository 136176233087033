import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { selectIntegrations } from '../../../../../../../shared/common/features/integrations/slice/integrations.slice';
import useCasinoWidgetConfig from '../../../../../platformImplementation/hooks/useCasinoWidgetConfig';
import { casinoLaunchSelectors } from '../../../gameLaunch/casinoLaunchSlice';
import { showRootCasinoModal } from '../../../rootCasinoModal/rootCasinoModal.slice';
import { TopBarButton } from '../TopBarButton';

type Props = {
  type: string;
  text?: string;
  icon?: string;
  isFreePlay: boolean;
};

export const QuickDepositButton = ({ isFreePlay, type, text, icon }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const widgetGameData = useAppSelector(selectIntegrations.casinoWidgetGameData);
  const casinoWidgetConfig = useCasinoWidgetConfig();
  const launchData = useAppSelector(casinoLaunchSelectors.launchData);
  const gameLaunchData = casinoWidgetConfig ? widgetGameData : launchData;
  const showQuickDeposit = useCallback(() => {
    const provider = gameLaunchData?.providerAlias;
    return !isFreePlay && provider;
  }, [isFreePlay, gameLaunchData?.providerAlias]);

  const showDepositModal = () => {
    dispatch(showRootCasinoModal({ modalType: 'QUICK_DEPOSIT_MODAL' }));
  };

  const handleClick = () => {
    showQuickDeposit() && showDepositModal();
  };

  return (
    <TopBarButton
      type={type}
      handleClick={handleClick}
      icon={icon}
      text={text && t(text)}
      restrictForFreePlay={isFreePlay}
      disableActive
    />
  );
};
