import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { UI } from 'components/shared/UI';
import { Breakpoints } from 'theme/Theme';
import StyledGridHeader from './GridHeader.styled';
import StyledGridHeaderBadge from './GridHeaderBadge.styled';

interface GridHeaderProps {
  icon: string;
  text: string;
  promosNumber: number;
}

const GridHeader: React.FC<GridHeaderProps> = ({ icon, text, promosNumber }) => {
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });

  return (
    <StyledGridHeader>
      <UI.SVGCDN size={isDesktop ? 26 : 18} className="flex-shrink-0 mr-2" icon={icon} />
      {text}
      <StyledGridHeaderBadge className="ml-2">{promosNumber}</StyledGridHeaderBadge>
    </StyledGridHeader>
  );
};

export default GridHeader;
