import { AggregatorConfig } from '../casinoLaunchTypes';

export const aggregatorConfig: AggregatorConfig = {
  iosHandConfig: {
    allowIOSHand: true,
    disableIOSHandByProvider: ['svara'],
    disableIOSHandByGameId: ['-81554'],
    disableIOSHandByVersion: {},
    disableIOSHandByProviderAndVersion: {
      'egt-digital': { '15': ['6'] },
    },
    disableIOSHandByGameIdAndVersion: {},
    useNewScrollLogic: [],
    forceRefresh: ['digitain', 'tomhorn', 'evolution', 'egt-digital'],
  },
  postMessages: [
    {
      message: 'egtd-bg.exit',
      type: 'EXIT',
    },
    {
      message: 'egtd-bg.game-load-complete',
      type: 'GAME_LOAD_COMPLETED',
    },
    {
      message: 'egtd-bg.reload',
      type: 'RELOAD',
    },
    {
      message: 'egtd-bg.open-lobby',
      type: 'OPEN_LOBBY',
    },
    {
      message: 'egtd-bg.open-game',
      type: 'OPEN_GAME',
    },
    {
      message: 'egtd-bg.fullscreen',
      type: 'FULLSCREEN',
    },
    {
      message: 'egtd-bg.game-ratio',
      type: 'RATIO',
    },
    {
      message: 'egtd-bg.cashier',
      type: 'CASHIER',
    },
    {
      message: 'egtd-bg.error',
      type: 'ERROR',
    },
    {
      message: 'egtd-bg.provider-info',
      type: 'PROVIDER_INFO',
    },
  ],
  stylesConfig: {
    hasNotch: [
      'spribe',
      'evoplay',
      'endorphina',
      'netent',
      'oryx',
      'gamomat',
      'golden-hero',
      'givme',
      'kalamba',
      'candle-bets',
      'peter-and-sons',
      'sgd',
      'mr-slotty',
      'ct-gaming',
      'ctg',
      'tvbet',
      'stake-logic',
      'betgames',
      'redrake',
      'spadegaming',
      'skywind',
      'nsoft',
      'elbet',
      'fazi',
      'arc',
      'wazdan',
      'bf',
      'netent_oss',
      'bgaming',
      'microgaming',
      'barbarabang',
    ],
    hasBottomNotch: [
      'smojos',
      'pariplay',
      'synot',
      'yggdrasil',
      'kagaming',
      'btg_oss',
      'hacksaw_pp',
      'relax',
      'sagaming',
      'bigpot',
      'eurasian',
      'simpleplay',
      'platipus',
      'jelly',
    ],
    hasTopNotch: ['digitain'],
    bottomCustomArea: ['habanero', 'bf'],
    bodyAdditionalHeight: ['evolution', 'playtech'],
    oldStandardRatio: ['greentube', 'oryx'],
    customRatio1: ['gamomat'],
  },
};
