import React, { memo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { GameJourney } from 'utils/common/translationUtils/translationStrings';
import { cssGamificationNS } from 'utils/common/types/css.types';

interface SectionHeaderProps {
  title: string;
  badge: number;
  removeBadge?: boolean;
  navigationHandler: () => void;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, badge, removeBadge = false, navigationHandler }) => {
  const { t } = useTranslation();
  const headerStyles = clsx(`${cssGamificationNS}-category-header`);

  return (
    <Flex.AC className={headerStyles}>
      <Flex.ACJB className="w-100" onClick={navigationHandler}>
        <div className={`${cssGamificationNS}-category-header__title`}>{title}</div>

        {/* <div className="cu-category-header__badge">
          <span className="cu-category-header__badge--number">{badge}</span>
        </div> */}
        {!removeBadge && (
          <Flex.AC className={`${cssGamificationNS}-category-header__view-all`}>
            <span>{`${t(GameJourney.Menu.allTab)}${badge ? ` (${badge})` : ''}`}</span>
            <Icon className={`rotated-270 ${cssGamificationNS}-category-header__view-all-icon`} icon="chevron" />
          </Flex.AC>
        )}
      </Flex.ACJB>
    </Flex.AC>
  );
};

export default memo(SectionHeader);
