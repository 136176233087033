import React from 'react';
import clsx from 'clsx';
import Icon from 'components/shared/Icon';
import { NotificationTypes } from '../../../myProfile/sections/tabs/notificationsTab/notificationTypes';

interface CloseProps {
  onClick: React.MouseEventHandler;
  color: NotificationTypes.GamificationModalParams['modalCloseColor'];
  style?: React.CSSProperties;
  text?: string;
}

const Close: React.FC<CloseProps> = ({ onClick, color, text, style }) => {
  const closeClass = clsx('ml-auto my-2 mr-3', 'gmf-modal__close', `gmf-modal__close--${color}`);
  return (
    <div className={closeClass} data-qid="gmf-modal-close" style={style}>
      {text && text}
      <Icon className="wh-100" defaultClassName={false} onClick={onClick} icon="times" />
    </div>
  );
};

export default Close;
