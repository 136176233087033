import React from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { cssBurgerNS, gridStates } from './burgerUI.types';

interface RetailGridProps {
  className?: string;
  children: React.ReactNode;
}

const RetailGrid: React.FC<RetailGridProps> = ({ className, children }) => {
  const wrapperClass = clsx(`${cssBurgerNS.Retail}`, className);

  return (
    <motion.div
      className={wrapperClass}
      transition={{ ease: 'easeInOut', duration: 0.25 }}
      animate="visible"
      exit="exit"
      variants={gridStates}
      initial="hidden"
    >
      {children}
    </motion.div>
  );
};

export default RetailGrid;
