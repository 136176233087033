import React, { memo } from 'react';
import { Flex } from 'components/shared/layout';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { showHideNavUserButton } from 'utils/common/helpersCommon';
import { MainNavBarButtons } from './mainNavbarButtons';
import { DrawerToggler, NavBrand, NavUser, NavUserLogged } from '..';
import SmarticoButton from '../../../integrations/smartico/components/SmarticoButton';
import { useMainNavbar } from '../../hooks/useMainNavbar';

type MainNavMobileLayout02Types = {
  isAuthenticated: boolean | null;
};

const MainNavMobileLayout02: React.FC<MainNavMobileLayout02Types> = ({ isAuthenticated }) => {
  const { subNav } = useMainNavbar();
  const showNavUserButtons = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.LOGIN_REGISTER_SHOW,
    deviceLayout: 'general',
  })?.isVissible;

  return (
    <>
      <Flex.JB className="w-100">
        <Flex>
          <DrawerToggler />
          <NavBrand />
        </Flex>
        <Flex.AC className="head__right--mobile">
          {/*{tickerVerticalConfig?.visible ? <TickerToggler onToggleClick={onToggleClick} /> : null}*/}
          {/* TODO: Implement condition to show the Offers link */}
          <SmarticoButton />
          <MainNavBarButtons />
          {/* <OffersToggler isAuthenticated={isAuthenticated} /> */}

          {isAuthenticated ? (
            <>
              {/* <DepositButton /> */}
              <NavUserLogged showBalance={subNav.balance} showDeposit={subNav.deposit} />
            </>
          ) : showHideNavUserButton(showNavUserButtons) ? (
            <NavUser />
          ) : null}
          <MainNavBarButtons type={'language'} />
        </Flex.AC>
      </Flex.JB>
    </>
  );
};

export default memo(MainNavMobileLayout02);
