import { combineReducers } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage/session';
import rootCasinoModalReducer from 'modules/casino/shared/features/rootCasinoModal/rootCasinoModal.slice';
import pageNotificationReducer from 'pages/gamificationPage/slice/gamificationPage.slice';
import homePageReducer from 'pages/homePage/slice/homePage.slice';
import appBackdropReducer from 'shared/common/features/appBackdrop/slice/appBackdrop.slice';
import appFooterReducer from 'shared/common/features/appFooter/slice/appFooter.slice';
import appHeaderReducer from 'shared/common/features/appHeader/components/appHeaderContainer/slice/appHeader.slice';
import bottomTabBarReducer from 'shared/common/features/bottomTabBar/slice/bottomTabBar.slice';
import drawerReducer from 'shared/common/features/drawer/slice/drawer.slice';
import deviceTypeReducer from 'shared/common/features/general/slice/deviceType.slice';
import generalReducer from 'shared/common/features/general/slice/general.slice';
import integrationsReducer from 'shared/common/features/integrations/slice/integrations.slice';
import appLayoutsReducer from 'shared/common/features/layouts/slice/layouts.slice';
import loaderDataReducer from 'shared/common/features/loaderData/slice/loaderData.slice';
import localizationReducer from 'shared/common/features/localization/slice/localization.slice';
import notificationReducer from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationSlice';
import navigationMenuReducer from 'shared/common/features/navigationMenu/slice/navigationMenu.slice';
import contentModalReducer from 'shared/common/features/partialModal/slice/partialModal.slice';
import platformPortalsReducer from 'shared/common/features/platformPortals/slice/platformPortals.slice';
import popOverReducer from 'shared/common/features/popover/popoverContainer/slice/popOver.slice';
import rootModalReducer from 'shared/common/features/rootModal/slice/rootModal.slice';
import seoDataReducer from 'shared/common/features/seo/seoData/slice/seoData.slice';
import settingsReducer from 'shared/common/features/settings/slice/settings.slice';
import sliderContainerReducer from 'shared/common/features/sliderContainer/slice/sliderContainer.slice';
import { headerBonusIconReducer } from './appHeader/components/mainNavbar/mainNavbarButtons/features/mainNavbarButtonsBonus/slice/mainNavbarButtonsBonus.slice';
import webSocketsReducer from '../features/websockets/slice/webSockets.slice';

const webSocketsPersistConfig = {
  key: 'webSockets',
  version: 1,
  storage,
  blacklist: ['status', 'addKeys', 'removeKeys'],
};

export const commonReducer = combineReducers({
  appBackdrop: appBackdropReducer,
  appHeader: appHeaderReducer,
  bottomTabBar: bottomTabBarReducer,
  drawer: drawerReducer,
  general: generalReducer,
  localization: localizationReducer,
  navigationMenu: navigationMenuReducer,
  notification: notificationReducer,
  pageNotification: pageNotificationReducer,
  rootModal: rootModalReducer,
  rootCasinoModal: rootCasinoModalReducer,
  contentModal: contentModalReducer,
  settings: settingsReducer,
  sliderContainer: sliderContainerReducer,
  webSockets: persistReducer(webSocketsPersistConfig, webSocketsReducer),
  loaderData: loaderDataReducer,
  appLayouts: appLayoutsReducer,
  popOver: popOverReducer,
  appFooter: appFooterReducer,
  homePage: homePageReducer,
  deviceType: deviceTypeReducer,
  platformPortals: platformPortalsReducer,
  integrations: integrationsReducer,
  seoData: seoDataReducer,
  headerBonusIcon: headerBonusIconReducer,
});
