import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store';
import useCasinoWidgetConfig from '../../../../../platformImplementation/hooks/useCasinoWidgetConfig';
import { setPostMessageState } from '../../../gameLaunch/postMessages/messageManagerSlice';
import { PostMessageType } from '../../../gameLaunch/postMessages/messagesTypes';
import { resetChallenge } from '../../slice/gameLaunchBonus.slice';
import { TopBarButton } from '../TopBarButton';

type Props = {
  type: string;
  text?: string;
  icon?: string;
};

export const ExitGameButton = ({ type, text, icon }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const casinoWidgetConfig = useCasinoWidgetConfig();
  const navigate = useNavigate();

  const handleExitGame = () => {
    if (casinoWidgetConfig) {
      window.isRNWebView &&
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({ action: 'bounce', command: { isBounceEnabled: true } }),
        );

      if (window.opener) {
        dispatch(resetChallenge());
        window.close();
      } else {
        navigate(-1);
      }
    } else {
      dispatch(setPostMessageState({ type: PostMessageType.EXIT, options: {} }));
    }
  };

  return <TopBarButton type={type} handleClick={handleExitGame} icon={icon} text={text && t(text)} />;
};
