import React, { useCallback } from 'react';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import ReactIdSwiperCustom from 'modules/casino/shared/components/reactIdSwiper';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { useAppSelector } from 'store';
import { gamesSelectors } from '../../../../cellls/slice/casinoGamesSlice';
import useSliderRotatingConfig from '../../../hooks/useSliderRotatingConfig';
import GridCellLayout from '../../gridCell/GridCellLayout';
import { getSpecialCellItemBackgroundColor } from '../utils/specialCellUtils';

type Props = {
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
  collectionId: string;
  gridPage: number;
};

const SliderRotating: React.FC<Props> = ({ categoryId, categoryLayoutType, gridPage, collectionId }) => {
  const rotationGameData = useAppSelector((state) => gamesSelectors.rotationGameData(state, collectionId));
  const { queryType } = useGetCategoryParams(categoryId, categoryLayoutType);
  const { params } = useSliderRotatingConfig();

  const setRefs = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      const aspr = node.clientWidth / node.clientHeight;
      const paddingTop = node.clientHeight - (node.clientWidth - 20) / aspr;
      node.style.padding = `${paddingTop}px 10px 0`;
    }
  }, []);
  return rotationGameData?.length ? (
    <div className={`${cssGlobalNS}-c-rotating casino-grid-item casino-grid-item--vertical`}>
      <ReactIdSwiperCustom {...params}>
        {rotationGameData.map(({ uniqueId, background }, index) => (
          <div className={'swiper-slide'} key={uniqueId}>
            <div
              ref={setRefs}
              style={{ background: getSpecialCellItemBackgroundColor(background), width: '100%', height: '100%' }}
            >
              <GridCellLayout
                index={index}
                gridPage={gridPage}
                gamesRenderedCount={3}
                categoryId={categoryId}
                isLastItem={true}
                gameUniqueId={uniqueId}
                collectionId={collectionId}
                skipLazyLoad={true}
                queryType={queryType}
                categoryLayout={categoryLayoutType}
              />
            </div>
          </div>
        ))}
      </ReactIdSwiperCustom>
    </div>
  ) : (
    <></>
  );
};

export default SliderRotating;
