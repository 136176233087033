import { GridLayouts } from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import { CategorySubTypes } from '../../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';

export const disableSaveScrollPositionMap = [CategorySubTypes.FREE_SPIN, CategorySubTypes.FREE_SPIN_CHALLENGE];

export const LayoutCellWrappers = {
  [GridLayouts.LAYOUT_1]: 'cell_1',
  [GridLayouts.LAYOUT_2]: 'cell_2',
  [GridLayouts.LAYOUT_3]: 'cell_1',
};

export const gridAnimations = [
  'fade-in',
  'animate-pop-up',
  'animate-blur',
  'animate-flip',
  'animate-slide',
  'animate-drop',
  'fade-in-zoom-in',
  'fade-in-pop',
];

export const gridAnimationDefault = {
  FADE_IN: 'fade-in',
};

export const hoverAnimations = [
  'default',
  'fade-in-zoom-in',
  'pop-up',
  'blur',
  'slide-from-top',
  'slide-from-right',
  'slide-from-bottom',
  'slide-from-left',
  'scale-from-center',
  'scale-hr-center',
  'scale-ver-center',
  'scale-ver-top',
  'slide-top-right',
  'slide-top-left',
  'center-blur',
  'fade-in-shake',
];

export const hoverAnimationDefault = {
  HOVER_DEFAULT: 'default',
};
