import React, { Fragment } from 'react';
import { useInView } from 'react-intersection-observer';
import { Flex } from 'components/shared/layout';
import Languages from './Languages';
import { Odds } from './Odds';
import { TimeZones } from './TimeZones';
import { FooterTypes } from '../types/appFooter.types';

interface Props {
  section: FooterTypes.Section;
}

export const Systems: React.FC<Props> = ({ section }) => {
  const [inViewRef, inView] = useInView({
    rootMargin: '200px 0px',
  });

  if (!section?.options) return null;

  const FOOTER_SECTIONS = {
    language: Languages,
    odds: Odds,
    timezones: TimeZones,
  };

  const renderOptions = (sections: FooterTypes.OptionsProps) => {
    const FooterBlock = FOOTER_SECTIONS[sections.name];
    return FooterBlock ? <FooterBlock section={sections} key={sections?.name} /> : <></>;
  };
  if (!section) return null;
  return (
    <Flex.ACJE className="w-100" ref={inViewRef}>
      {inView ? (
        <Fragment key="sections">
          {section?.options?.map((option) => <Fragment key={option.name}>{renderOptions(option)}</Fragment>)}
        </Fragment>
      ) : null}
    </Flex.ACJE>
  );
};
