import { isMobile } from 'react-device-detect';
import userStorage from 'pages/auth/login/UserStorage';
import { MyAccountTranslation } from 'utils/common/translationUtils/translationStrings';
import { User } from '../features/myProfile/types/myProfile.types';

export const loginUserChat = (playerDetails: User): void => {
  const zopim = window['$zopim'];
  const zE = window['zE'];
  const LiveChatWidget = window['LiveChatWidget'];

  if (zopim && typeof zopim.livechat.init == 'function') {
    zopim.livechat.setName(playerDetails.fullName);
    zopim.livechat.setEmail(playerDetails.email);
  }

  if (zE && zopim && typeof zopim.livechat.init == 'undefined') {
    zE('webWidget', 'identify', {
      name: playerDetails.fullName,
      email: playerDetails.email,
    });
  }

  if (LiveChatWidget) {
    LiveChatWidget.call('set_session_variables', {
      username: playerDetails.fullName,
      email: playerDetails.email,
    });
    LiveChatWidget.call('set_customer_name', playerDetails.fullName);
    LiveChatWidget.call('set_customer_email', playerDetails.email);
  }
};

export const logoutUserChat = (): void => {
  const zopim = window['$zopim'];
  const zE = window['zE'];
  const LiveChatWidget = window['LiveChatWidget'];

  if (LiveChatWidget) {
    LiveChatWidget.call('set_session_variables', {
      username: ' ',
      email: ' ',
    });
    LiveChatWidget.call('set_customer_name', ' ');
    LiveChatWidget.call('set_customer_email', ' ');
  }

  if (zopim && typeof zopim.livechat.init == 'function') {
    zopim.livechat.clearAll();
  }

  if (zE && zopim && typeof zopim.livechat.init == 'undefined') {
    zE('webWidget', 'logout');
    !isMobile && zE('webWidget', 'open');
  }
};

export const setChatLanguage = (): void => {
  const zopim = window['$zopim'];
  const zE = window['zE'];
  const userLang = userStorage.getUserLang();

  if (zopim && typeof zopim.livechat.init == 'function') {
    zopim.livechat.setLanguage(userLang);
  }

  if (zE && zopim && typeof zopim.livechat.init == 'undefined') {
    zE('webWidget', 'setLocale', userLang);
  }
};

export const setZopimContent = (t): void => {
  const zopim = window['$zopim'];

  if (zopim && typeof zopim.livechat.init == 'function') {
    zopim.livechat.window.setTitle(t(MyAccountTranslation.SupportChat.titleChatWindow));
    zopim.livechat.concierge.setTitle(t(MyAccountTranslation.SupportChat.subTitleChatWindow));
    zopim.livechat.concierge.setName(t(MyAccountTranslation.SupportChat.nameChatWindow));
  }
};
