import styled from 'styled-components';
import { Mixins } from 'theme/StyledComponents/shared';

export const StyledCTAText = styled.div.attrs(({ ...rest }) => ({
  ...rest,
}))`
  margin-top: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
  font-size: ${Mixins.Size.clampedSize(16, 22)};
  font-weight: 700;
  color: #fff;
`;
