import React from 'react';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import { Flex } from 'components/shared/layout';
import { isEmpty } from 'utils/common/helpersCommon';
import { TerminalModalUI } from '../../../../ssbt/components/ssbtModalUI';

type IdVerificationModalStepperProps = {
  success: boolean;
  stepText: string;
  isPending?: boolean;
  isAgeLimitError?: boolean;
  isLastPartDialog?: boolean;
  fieldName: string;
  t: TFunction;
};

const IdVerificationModalStepper: React.FC<IdVerificationModalStepperProps> = ({
  success,
  stepText,
  isPending,
  isAgeLimitError,
  isLastPartDialog,
  fieldName,
  t,
}) => {
  const checkClass = clsx(
    'idvfr__status-check-wrapper',
    success && 'color--success',
    !success && 'color--danger',
    isPending && 'color--neutral',
    isAgeLimitError && 'color--neutral blur',
  );
  const isPendingRequest = !isEmpty(isPending) && isPending;

  return (
    <Flex className={`status ${success ? 'success' : 'error'} ${isLastPartDialog ? 'final-message' : ''}`}>
      {isPendingRequest ? (
        <TerminalModalUI.Status status={'pending'} />
      ) : (
        <TerminalModalUI.Status status={isAgeLimitError ? 'isAgeLimitError' : success ? 'success' : 'error'} />
      )}

      <Flex.Col className={checkClass}>
        <h4 className="check-text">{`${t(fieldName)}`}</h4>
        <h1 className="status-text">{t(stepText)}</h1>
      </Flex.Col>
    </Flex>
  );
};

export default IdVerificationModalStepper;
