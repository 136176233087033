import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { TermsAndConditions } from '../types/termsAndConditions.types';

export const termsAndConditionsThunks: TermsAndConditions.Thunks = {
  fetchTermsAndConditions: createAbortThunk('termsAndConditions/getUpdated', async (props, { rejectWithValue }) => {
    try {
      const url = `${config.API_URL}/api/cms/public/get-content?alias=${props.alias}`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.message(err));
    }
  }),
  acceptTermsAndConditions: createAbortThunk('termsAndConditions/acceptUpdated', async (props, { rejectWithValue }) => {
    try {
      const url = `${config.API_URL}/api/ews-crm/player/players-settings/player-terms-conditions`;
      const response = await axiosInstance.post(url, {
        alias: props.alias,
        version: props.version,
        subVersion: props.subVersion,
      });
      props.closeModal();
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.message(err));
    }
  }),
};

export const { fetchTermsAndConditions, acceptTermsAndConditions } = termsAndConditionsThunks;

const initialState: TermsAndConditions.State = {
  data: null,
  error: null,
  isLoading: false,
};

const termsAndConditions = createSlice({
  name: 'termsAndConditions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTermsAndConditions.pending, (state: TermsAndConditions.State) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchTermsAndConditions.fulfilled,
        (state: TermsAndConditions.State, action: PayloadAction<TermsAndConditions.FetchResponseData>) => {
          state.isLoading = false;
          state.data = action.payload;
        },
      )
      .addCase(
        fetchTermsAndConditions.rejected,
        (state: TermsAndConditions.State, action: PayloadAction<TermsAndConditions.ErrorData>) => {
          state.isLoading = false;
          state.error = action.payload;
        },
      );
    builder
      .addCase(acceptTermsAndConditions.pending, (state: TermsAndConditions.State) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(acceptTermsAndConditions.fulfilled, (state: TermsAndConditions.State) => {
        state.isLoading = false;
        state.data = null;
      })
      .addCase(
        acceptTermsAndConditions.rejected,
        (state: TermsAndConditions.State, action: PayloadAction<TermsAndConditions.ErrorData>) => {
          state.isLoading = false;
          state.error = action.payload;
        },
      );
    builder.addCase(logoutUser, () => initialState);
  },
});

export default termsAndConditions.reducer;

export const selectTermsAndConditions = {
  data: (state: RootState): TermsAndConditions.TermsAndConditionsState | null => state.termsAndConditionsModal.data,
  error: (state: RootState): TermsAndConditions.ErrorData => state.termsAndConditionsModal.error,
  isLoading: (state: RootState): boolean => state.termsAndConditionsModal.isLoading,
};
