import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { selectRootModal, showRootModal } from '../slice/rootModal.slice';

const useRootModalData = () => {
  const currModalType = useAppSelector(selectRootModal.type);
  const currModalProps = useAppSelector(selectRootModal.props);
  const modalsQueue = useAppSelector(selectRootModal.modalsQueue);

  const dispatch = useAppDispatch();

  const nextModalToShow = useMemo(() => {
    if (currModalType === null && !isEmpty(modalsQueue)) {
      const sortedModalsQueue = Object.values(modalsQueue).sort((a, b) => b.modalPriority - a.modalPriority);
      return sortedModalsQueue[0];
    }
    return;
  }, [currModalType, modalsQueue]);

  useEffect(() => {
    if (currModalType === null && nextModalToShow) {
      dispatch(showRootModal(nextModalToShow));
    }
  }, [nextModalToShow, currModalType, dispatch]);

  return { currModalType, currModalProps };
};

export default useRootModalData;
