import React, { memo } from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';

interface Props {
  componentLocation?: 'preloader';
}
const DataLoader: React.FC<Props> = ({ componentLocation }) => {
  const loaderClass = clsx(
    'loader-wrapper',
    componentLocation === 'preloader' ? 'loader-wrapper--preloader' : 'loader-wrapper--default',
  );
  return (
    <Flex.Center className={loaderClass}>
      <div className="loader" />
    </Flex.Center>
  );
};

export default memo(DataLoader);
