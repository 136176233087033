import React, { RefObject } from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';

import { isEmpty } from 'utils/common/helpersCommon';
import { useClassRapperByAlign } from './hooks/useClassRapperByAlign';
import { NavSubHeader } from '..';
import { useMainNavbar } from '../../hooks/useMainNavbar';

type MainBavbarDesktopTypes = {
  parentRef?: RefObject<HTMLDivElement>;
  layout: 'layout_1' | 'layout_2' | 'layout_3';
};
enum NavItemGroupAlign {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

const VerticalsMenu: React.FC<MainBavbarDesktopTypes> = ({ parentRef, layout }) => {
  const { subNav, subVerticals } = useMainNavbar();
  useClassRapperByAlign({ parentRef, subVerticals });

  const isVisible = subNav.isVisible;

  if (!isVisible) return null;

  if (isEmpty(subVerticals)) {
    return <div />;
  }

  const hasScroll = layout === 'layout_2' && NavItemGroupAlign.CENTER;

  const wrapperClass = clsx(
    hasScroll
      ? 'head__items-wrapper-scroll head__items-wrapper'
      : 'head__items-wrapper overflow-x-auto custom-scrollbars',
  );
  return (
    <Flex.ACJB className={wrapperClass}>
      <NavSubHeader align={NavItemGroupAlign.LEFT} verticals={subVerticals} layout={layout} />

      <NavSubHeader align={NavItemGroupAlign.CENTER} verticals={subVerticals} layout={layout} />

      <NavSubHeader align={NavItemGroupAlign.RIGHT} verticals={subVerticals} layout={layout} />
    </Flex.ACJB>
  );
};

export default VerticalsMenu;
