import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormUI } from 'components/shared/forms/components';
import { AuthUI } from 'pages/auth/components';
import { Restrictions } from 'utils/common/translationUtils/translationStrings';

const SEActivationSuccess: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <FormUI.Status type="success" />
      <AuthUI.Text text={t(Restrictions.Exclusions.seActivationConfirmed)} />
    </>
  );
};

export default SEActivationSuccess;
