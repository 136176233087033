import { createGlobalStyle } from 'styled-components';
// import { inbetColors } from 'theme/themes/inbet/SCColors';
// import { sesameColors } from 'theme/themes/sesame/SCColors';
// import { winbetColors } from 'theme/themes/winbet/SCColors';
// import { winbetRoColors } from 'theme/themes/winbetRo/SCColors';
// import { getBusinessUnit } from 'utils/common/helpersCommon';

// const theme = {
//   wbbg: winbetColors,
//   wbro: winbetRoColors,
//   ibbg: inbetColors,
//   smbg: sesameColors,
// };

const GlobalStyle = () => {
  // const bu = getBusinessUnit().toLowerCase();
  const GlobalStyles = createGlobalStyle` :root {  } `;
  return <GlobalStyles />;
};

export default GlobalStyle;
