import { Flex } from 'components/shared/layout';
import { MPDUI } from 'shared/common/features/myProfile/components/MPDUI';
import WinCoinsBalance from 'shared/common/features/myProfile/components/WinCoins/WinCoinsBalance';
import { MainNavbarButtonsCompact } from '../../../components/MainNavbarButtonsCompact';

type Props = {
  folder?: string;
  text?: string;
  tickets?: number;
  onClick: () => void;
  isAuthenticated: boolean;
  icon?: string;
  iconMyProfile?: string;
};

export const TombolaIndicatorDefault: React.FC<Props> = ({
  text,
  tickets,
  icon,
  iconMyProfile,
  onClick,
  isAuthenticated,
}) => {
  return (
    <Flex.Col className="mpd-stat-item mt-2 cursor-pointer" onClick={onClick}>
      <Flex.AC>
        <Flex.Col className="mr-1">
          {text && <MPDUI.Text variant="secondary" text={text} isTruncated={false} />}
          {tickets != undefined && tickets > -1 && <WinCoinsBalance amount={String(tickets)} />}
        </Flex.Col>
        <MainNavbarButtonsCompact
          disableBadge
          isAuthenticated={isAuthenticated}
          icon={icon}
          iconMyProfile={iconMyProfile}
        />
      </Flex.AC>
    </Flex.Col>
  );
};
