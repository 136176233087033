import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { createAbortThunk, CreateThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import type { BetHistoryState } from '../../../hooks/BetHistoryStateTypes';

interface CasinoHistoryState {
  casinoHistory: CasinoHistoryItem[];
  currentPage: number;
  totalPages: number;
  casinoHistoryIsFetching: boolean;
  status: string;
  error: string | null;
}

export interface CasinoHistoryItem {
  currencyCode: string;
  betCid: string;
  settled: boolean;
  createdDate: number;
  totalWin: number;
  totalBet: number;
  gameName: string;
}

export type Sort = {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
};

export type Pageable = {
  sort: Sort;
  pageNumber: number;
  pageSize: number;
  offset: number;
  paged: boolean;
  unpaged: boolean;
};

export type HistoryThunkAPI = { content: CasinoHistoryItem[]; pageable: Pageable; totalPages: number };

export const fetchCasinoHistory: CreateThunk<BetHistoryState.ThunkArgs, HistoryThunkAPI, boolean | undefined> =
  createAbortThunk(
    'fetchCasinoHistory',
    async ({ live, pageNumber, fromDate, toDate, isFetchMore }, { rejectWithValue }) => {
      const params = {
        pageNumber,
        betDate: { fromDate, toDate },
        product: live ? 'LIVE_CASINO' : 'CASINO',
      };

      //do not delete debugging logs
      // console.log('timestamp_from ', fromDate, '---');
      // console.log('timestamp_to ', toDate, '---');
      // console.log('______________________');
      // console.log('UTC date_from ', new Date(queryParams.fromDate).toUTCString(), '---');
      // console.log('UTC date_to ', new Date(queryParams.toDate).toUTCString(), '---');
      // console.log('______________________');
      // console.log('LOCALE date_from ', new Date(queryParams.fromDate).toLocaleString(), '---');
      // console.log('LOCALE date_to', new Date(queryParams.toDate).toLocaleString(), '---');

      try {
        const response = await axiosInstance.post(
          `${config.API_URL}/api/reporting/player/casino/casino-player-bet-history`,
          params,
        );
        return { ...response.data };
      } catch (error) {
        return rejectWithValue(isFetchMore);
      }
    },
  );

const initialState: CasinoHistoryState = {
  casinoHistory: [],
  currentPage: 0,
  totalPages: 0,
  casinoHistoryIsFetching: false,
  status: 'idle',
  error: null,
};

const casinoBetHistory = createSlice({
  name: 'casinoBetHistory',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchCasinoHistory.pending, (state, action) => {
        if (action.meta?.arg?.isFetchMore) {
          state.casinoHistoryIsFetching = true;
        } else {
          state.status = 'pending';
        }
      })
      .addCase(fetchCasinoHistory.fulfilled, (state, action) => {
        const { content, pageable, totalPages } = action.payload;

        state.casinoHistory = [...state.casinoHistory, ...content];
        state.currentPage = pageable.pageNumber;
        state.totalPages = totalPages;
        state.status = 'succeeded';
        state.casinoHistoryIsFetching = false;
      })
      .addCase(fetchCasinoHistory.rejected, (state, action) => {
        if (!action.payload) {
          state.status = 'failure';
        }
        state.error = getError.default();
        state.casinoHistoryIsFetching = false;
      });
  },
  reducers: {
    clearCasinoHistory(state) {
      state.casinoHistory = [];
      state.currentPage = 0;
      state.totalPages = 0;
      state.casinoHistoryIsFetching = false;
      state.status = 'idle';
      state.error = null;
    },
    clearCasinoBetHistoryItems(state) {
      state.casinoHistory = [];
    },
  },
});

export const { clearCasinoHistory, clearCasinoBetHistoryItems } = casinoBetHistory.actions;

export default casinoBetHistory.reducer;

export const selectCasinoHistory = {
  bets: (state: RootState): CasinoHistoryItem[] => state.myAccount.casinoBetHistory.casinoHistory,
  currentPage: (state: RootState): number => state.myAccount.casinoBetHistory.currentPage,
  totalPages: (state: RootState): number => state.myAccount.casinoBetHistory.totalPages,
  status: (state: RootState): string | null => state.myAccount.casinoBetHistory.status,
  error: (state: RootState): string | null | undefined => state.myAccount.casinoBetHistory.error,
  isLoadingMore: (state: RootState): boolean => state.myAccount.casinoBetHistory.casinoHistoryIsFetching,
};
