import { isAndroid, isIOS, isMobile, isMobileSafari } from 'react-device-detect';
import { NavigateFunction } from 'react-router-dom';
import {
  CasinoLobbyType,
  casinoTypeKeyValue,
  DEFAULT_CASINO_ROUTE,
  GAME_LAUNCH_PREFIX,
  GameType,
  LiveCasinoGameType,
  WINDOW_CLOSE_ROUTE,
} from 'modules/casino/shared/constants';
import KYCVerificationInterceptor from 'modules/casino/shared/features/gameLaunch/components/launchInterceptors/KYCVerificationInterceptor';
import { getCookie, getDeviceType } from 'modules/casino/shared/utils/common/helpersCommon';
import { User } from 'shared/common/features/myProfile/types/myProfile.types';
import { fbEventFreeStartGame, fbEventPaidStartGame } from 'shared/common/features/seo/fb/events';
import { ACTIVE_BU_DEV } from 'utils/config';
import { OpenLaunchTypes } from './casinoLaunchConstants';
import {
  AggregatorStylesConfig,
  DirectLaunchConfig,
  DirectLaunchMobileEnvironments,
  GamePlayType,
  IsIOSHandDisabledProps,
  KycConfig,
  ProviderLaunchAgents,
  TriggerTrackingProps,
} from './casinoLaunchTypes';
import LoginInterceptor from './components/launchInterceptors/LoginInterceptor';
import PlayerBlockAppInterceptor from './components/launchInterceptors/PlayerBlockAppInterceptor';
import PlayerRestrictionsInterceptor from './components/launchInterceptors/PlayerRestrictionsInterceptor';
import RealityCheckSessionInterceptor from './components/launchInterceptors/RealityCheckSessionInterceptor';
import { ExecuteInterceptorsType } from './components/launchInterceptors/types';
import { triggerGTMEvent } from '../seo/gtm/GoogleTagManager';

export const checkDirectLaunch = (directLaunchConfig?: DirectLaunchConfig): boolean => {
  // check if direct launch is defined and enabled
  if (!directLaunchConfig || !directLaunchConfig.enabled || !isMobile) {
    return false;
  }

  // check for "enabledOn"
  return (
    directLaunchConfig.enabledOn === DirectLaunchMobileEnvironments.Both ||
    (isIOS && directLaunchConfig.enabledOn === DirectLaunchMobileEnvironments.iOS) ||
    (isAndroid && directLaunchConfig.enabledOn === DirectLaunchMobileEnvironments.Android)
  );
};

const navigateToPathname = (navigate: NavigateFunction) => {
  const params = new URLSearchParams(window.location.search);
  const closePathname = params.get('closeUrl');

  if (closePathname && closePathname !== WINDOW_CLOSE_ROUTE) {
    navigate(closePathname);
  } else {
    navigate('/');
  }
};

export const handleBackButtonNavigation = (navigate: NavigateFunction, historyParam?: number): void => {
  removeLaunchHistoryLength();
  if (window.opener) {
    window.close();
    return;
  }

  if (window.history.length >= 2) {
    if (historyParam) {
      const historyNavigationDelta = history.length - historyParam;
      if (historyNavigationDelta !== 0) {
        navigate(-historyNavigationDelta);
        return;
      }
      navigateToPathname(navigate);
    } else {
      navigate(-1);
    }
    return;
  }

  navigateToPathname(navigate);
};

export const getLaunchGameInterceptors = (
  gamePlayType: GamePlayType,
  finalCallback: () => void,
  openLaunchTypes: OpenLaunchTypes,
  gameId?: string,
  kycConfig?: KycConfig | undefined,
  closeGame?: () => void,
  providerAlias?: string,
): ExecuteInterceptorsType => {
  return {
    interceptors: [
      { func: LoginInterceptor, params: { gamePlayType, closeGame, openLaunchTypes } },
      { func: KYCVerificationInterceptor, params: { gamePlayType, gameId, kycConfig, providerAlias, closeGame } },
      { func: PlayerRestrictionsInterceptor, params: { gameId, providerAlias, closeGame } },
      { func: PlayerBlockAppInterceptor, params: { closeGame } },
      { func: RealityCheckSessionInterceptor, params: { gameId, closeGame } },
    ],
    continueExec: false,
    finalCallback,
  };
};

export const getAgent = (iframeLoad: boolean): ProviderLaunchAgents => {
  const isTestMode = getCookie('isTestMode') === 'true';
  return isMobile
    ? ProviderLaunchAgents.mobile
    : iframeLoad || isTestMode
      ? ProviderLaunchAgents.desktop
      : ProviderLaunchAgents.window_desktop;
};

export const getValidAgent = (agent: string): ProviderLaunchAgents | undefined => {
  // if agent is included in enum and isMobile return MobileLaunchAgent
  if (isMobile && ProviderLaunchAgents[agent]) {
    return ProviderLaunchAgents.mobile;
  }
  // WINDOW DESKTOP
  if (window.opener !== null && agent === ProviderLaunchAgents.window_desktop) {
    return ProviderLaunchAgents.window_desktop;
  }

  // DESKTOP
  if (agent === ProviderLaunchAgents.desktop) {
    return ProviderLaunchAgents.desktop;
  }

  // desktop browser with wrong agent
  // TODO: shouldn't there be a ! before ProviderLaunchAgents
  if (!isMobile && ProviderLaunchAgents[agent]) {
    return ProviderLaunchAgents.desktop;
  }
};

export interface GamePlayPathOptions {
  description: string;
  gameType: GameType;
  gameId: string;
  gamePlayType: GamePlayType;
  providerAlias: string;
  casinoRoute: string;
  agent: ProviderLaunchAgents;
  additionalLaunchParams?: string;
}

export const getGeneratedLaunchPath = ({
  description,
  gameType,
  gameId,
  gamePlayType,
  providerAlias,
  casinoRoute,
  agent,
  additionalLaunchParams,
}: GamePlayPathOptions): string => {
  const gameName = description.split(' ').join('-');
  const isFreePlay = gamePlayType === GamePlayType.FREE;
  const { exitUrl } = createExitUrl(gameType, casinoRoute, agent);

  return `/${GAME_LAUNCH_PREFIX}${casinoRoute}/${providerAlias}/${encodeURIComponent(
    gameName,
  )}/launch/${gameId}?agent=${agent.toLowerCase()}&isFreePlay=${isFreePlay}${exitUrl}${
    additionalLaunchParams ? additionalLaunchParams : ''
  }`;
};

export const createExitUrl = (
  gameType: GameType,
  casinoRoute: string,
  agent: ProviderLaunchAgents,
): { exitUrl: string } => {
  if (LiveCasinoGameType[gameType]) {
    return {
      exitUrl: `&closeUrl=${
        agent === ProviderLaunchAgents.window_desktop
          ? WINDOW_CLOSE_ROUTE
          : window.location.pathname + window.location.search
      }`,
    };
  }

  const closeUrl = new URLSearchParams(window.location.href).get('closeUrl');

  if (closeUrl) return { exitUrl: `&closeUrl=${closeUrl}` };

  const exitVertical = getExitVertical();
  const exitUrl =
    agent === ProviderLaunchAgents.window_desktop
      ? `&closeUrl=${WINDOW_CLOSE_ROUTE}`
      : `&closeUrl=${
          window.location.pathname.includes('casino/search')
            ? `${window.location.pathname + window.location.search}`
            : casinoRoute === DEFAULT_CASINO_ROUTE
              ? `/${exitVertical}`
              : window.location.pathname
        }`;
  return { exitUrl };
};

export const getExitVertical = (): string => {
  return casinoTypeKeyValue[CasinoLobbyType.Casino];
};
export const getClosePath = (closeUrl: string) => {
  const { pathname } = window.location;
  const hostUrl = window.location.href.split('/', 3).join('/');
  const lobby = pathname.split('/')[2];
  return closeUrl ? `${hostUrl}${closeUrl}` : `${hostUrl}/${lobby}`;
};

export const removeLaunchHistoryLength = () => {
  window.localStorage.removeItem('initialHistoryLength');
};

export const addLaunchHistoryLength = (historyBackIndex: number) => {
  window.localStorage.setItem('initialHistoryLength', `${historyBackIndex}`);
};

export const getLaunchHistoryLength = () => {
  return window.localStorage.getItem('initialHistoryLength');
};

export const getFbUserData = (userData: User | null) => {
  if (!userData) return;
  const { email, phoneNumber, firstName, lastName, city, country, street } = userData;
  const fBUserData = {
    ...(email && { email }),
    ...(phoneNumber && { phone_number: phoneNumber }),
    address: {
      ...(firstName && { first_name: firstName }),
      ...(lastName && { last_name: lastName }),
      ...(city && { city }),
      ...(country && { country: country.name }),
      ...(street && { street }),
    },
  };
  return fBUserData;
};

export const triggerTrackingEvents = ({
  trackingGameData,
  gamePlayType,
  dispatch,
  isAuthenticated,
  playerId,
  trackingEventsConfig,
  userData,
}: TriggerTrackingProps) => {
  const userId = playerId ? { userId: playerId } : {};
  const additionalParams = {
    gameName: trackingGameData.description,
    gameType: trackingGameData.gameType,
    gameIds: [`${trackingGameData?.refGameId}`],
    provider_id: trackingGameData.providerId,
    provider_name: trackingGameData.providerAlias,
    ...userId,
  };

  triggerGTMEvent({
    eventKey: gamePlayType === GamePlayType.FREE ? 'demo-game-start' : 'game-start',
    additionalParams,
  });

  const fBUserData = getFbUserData(userData);
  const device = getDeviceType();

  // Enable when API url is ready
  const fbEventData = {
    contentName: trackingGameData.description,
    contentIds: trackingGameData?.refGameId,
    contentType: trackingGameData.gameType,
    apiUrl:
      gamePlayType === GamePlayType.PAID && isAuthenticated
        ? trackingEventsConfig?.fbPaidGameStartApi
        : trackingEventsConfig?.fbFreeGameStartApi,
    fBUserData,
    device,
  };

  if (gamePlayType === GamePlayType.PAID && isAuthenticated) dispatch(fbEventPaidStartGame(fbEventData));
  if (gamePlayType === GamePlayType.FREE) dispatch(fbEventFreeStartGame(fbEventData));
};

const isIOSHandDisabledByVersion = ({ IOSHandByVersionConfig, osVersion }) => {
  const [major, minor] = osVersion.split('.');
  return (
    IOSHandByVersionConfig[major] &&
    (IOSHandByVersionConfig[major].length ? IOSHandByVersionConfig[major].includes(minor) : true)
  );
};

const disableIOSHandByParamAndVersion = ({ param, iosHandConfigByParam, osVersion }) => {
  if (!iosHandConfigByParam[param]) return false;
  else {
    return isIOSHandDisabledByVersion({
      IOSHandByVersionConfig: iosHandConfigByParam[param],
      osVersion,
    });
  }
};

export const checkIsIOSHandDisabled = ({ gameId, providerAlias, iosHandConfig, osVersion }: IsIOSHandDisabledProps) => {
  if (!iosHandConfig.allowIOSHand) return true;
  else {
    const disableIOSHandByProvider = iosHandConfig.disableIOSHandByProvider.includes(providerAlias);
    const disableIOSHandByGameId = iosHandConfig.disableIOSHandByGameId.includes(gameId);

    const disableIOSHandByVersion = isIOSHandDisabledByVersion({
      IOSHandByVersionConfig: iosHandConfig.disableIOSHandByVersion,
      osVersion,
    });

    const disableIOSHandByProviderAndVersion = disableIOSHandByParamAndVersion({
      param: providerAlias,
      iosHandConfigByParam: iosHandConfig.disableIOSHandByProviderAndVersion,
      osVersion,
    });

    const disableIOSHandByGameIdAndVersion = disableIOSHandByParamAndVersion({
      param: gameId,
      iosHandConfigByParam: iosHandConfig.disableIOSHandByGameIdAndVersion,
      osVersion,
    });

    return (
      disableIOSHandByProvider ||
      disableIOSHandByGameId ||
      disableIOSHandByVersion ||
      disableIOSHandByProviderAndVersion ||
      disableIOSHandByGameIdAndVersion
    );
  }
};

export const getClassesFromAggregatorConfig = ({
  stylesConfig,
  providerAlias,
}: {
  stylesConfig: AggregatorStylesConfig;
  providerAlias: string;
}): string => {
  let allClasses = '';

  if (isIOS) {
    if (stylesConfig.hasNotch.includes(providerAlias)) {
      allClasses = 'has-notch';
    } else if (stylesConfig.hasBottomNotch.includes(providerAlias)) {
      allClasses = 'has-notch--bottom';
    } else if (stylesConfig.hasTopNotch.includes(providerAlias)) {
      allClasses = 'has-notch--top';
    }

    if (stylesConfig.bottomCustomArea.includes(providerAlias) && isMobileSafari) {
      allClasses += ' mobile-safari--bottom-area';
    }
  }

  allClasses += stylesConfig.customRatio1.includes(providerAlias)
    ? ' custom-standard-1'
    : stylesConfig.oldStandardRatio.includes(providerAlias)
      ? ' old-standard'
      : ' modern-standard';

  return allClasses;
};

export const addIOSBodyAdditionalHeight = ({
  stylesConfig,
  providerAlias,
}: {
  stylesConfig: AggregatorStylesConfig;
  providerAlias: string;
}): boolean => {
  return stylesConfig.bodyAdditionalHeight.includes(providerAlias);
};

export const getAggregatorLocalUrl = (responseData: string) => {
  const splitRespData = responseData.split('?');
  const requiredParams = splitRespData[1];
  const isGameOpenedFromSearch = splitRespData.length > 2;

  const optionalParams = isGameOpenedFromSearch ? `?${splitRespData[2]}` : '';

  const aggregatorLocalUrl = `http://localhost:3005/gaming/aggregator/launchUrl?businessunit=${ACTIVE_BU_DEV}&${requiredParams}${optionalParams}`;
  return aggregatorLocalUrl;
};
