import { useTranslation } from 'react-i18next';
import { InputTypes } from 'components/shared/forms/formTypes';
import { IconTooltip } from 'components/shared/tooltip';
import Input from 'components/shared/UI/Input/Input';
import InputLabel from 'components/shared/UI/Input/InputLabel';
import InputWrapper from 'components/shared/UI/Input/InputWrapper';
import FormRadio from './FormRadio';

const CustomFormControl = (props) => {
  const {
    type = InputTypes.INPUT,
    label,
    placeholder,
    tooltip,
    errorMessage,
    onBlur,
    onChange,
    value,
    inputType,
    validationRules,
  } = props;
  const { t } = useTranslation();

  switch (type) {
    case InputTypes.INPUT:
      return (
        <InputWrapper>
          <InputLabel text={t(label)}>
            {tooltip && <IconTooltip tooltipAlignment="left" tooltip={t(tooltip)} textWrap icon="exclamation-solid" />}
          </InputLabel>
          <Input
            placeholder={t(placeholder)}
            status={errorMessage ? 'error' : undefined}
            errorMessage={t(errorMessage)}
            textAlignment="right"
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            type={inputType}
            maxLength={validationRules?.maxLength?.value}
            minLength={validationRules?.minLength?.value}
          />
        </InputWrapper>
      );
    case InputTypes.RADIO:
      return <FormRadio {...props} />;
    default:
      return <></>;
  }
};

export default CustomFormControl;
