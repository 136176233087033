import React from 'react';
import clsx from 'clsx';
import { PromotionTypes } from '../../../types/promotions.types';
interface GridProps {
  layout: PromotionTypes.GridLayoutType;
  children: React.ReactNode;
}

const Vertical: React.FC<GridProps> = ({ children, layout }) => {
  const gridClass = clsx(`prm-grid prm-grid-${layout}`);
  return <div className={gridClass}>{children}</div>;
};

export default Vertical;
