import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/shared';
import { Modal } from 'components/shared/Modal';
import { UI } from 'components/shared/UI';
import { isTerminal } from 'utils/common/helpersCommon';
import useSessionModalData from '../hooks/useSessionModalData';

const intervalDelay = 1000;

const SessionCheckModal = ({ isExpired, timer }: { isExpired: boolean; timer?: number }): JSX.Element | null => {
  const { t } = useTranslation();
  const { timeToDisplay, handleOnSubmit } = useSessionModalData({
    isExpired,
    timer,
    intervalDelay,
  });

  if (isTerminal()) {
    return null;
  }

  return (
    <Modal visible className="modal-terms">
      <Modal.Body className="d-flex-col-ac text-center" padding="lg">
        <p className="modal-body__title accent">
          {t(!isExpired ? 'TimeoutModal.Message.sessionCheckText' : 'TimeoutModal.Message.sessionExpiredText')}
          <br />
          {!isExpired && <Text className="modal-terms__title-accent" text={timeToDisplay} />}
        </p>

        <UI.Button class="mt-3" size="lg" onClick={handleOnSubmit} text={t('TimeoutModal.Button.sessionCheckButton')} />
      </Modal.Body>
    </Modal>
  );
};

export default SessionCheckModal;
