import { Cell02Data } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell02/components/data';
import { Cell02Default } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell02/components/default';
import {
  ComponentNames,
  ComponentType,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/layoutWrapperConstants';

const components = {
  [ComponentNames.GameCellWrapper]: {
    [ComponentType.default]: Cell02Default.GameCellWrapper,
    [ComponentType.data]: Cell02Data.GameCellWrapper,
  },
  [ComponentNames.Description]: {
    [ComponentType.default]: Cell02Default.Description,
    [ComponentType.data]: Cell02Data.Description,
  },
};

const cell02ComponentLoader = (componentName, componentType) => {
  return components[componentName][componentType];
};

export default cell02ComponentLoader;
