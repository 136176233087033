import { cssGlobalNS } from 'modules/casino/shared/types/css.types';

export const rowsGridNumber = {
  ONE_ROW: 1,
  TWO_ROWS: 2,
  THREE_ROWS: 3,
  FOURTH_ROWS: 4,
  FIFTH_ROWS: 5,
  SIX_ROWS: 6,
};

export const CasinoGridLayoutClasses = {
  ONE_ROW: 'one-row',
  TWO_ROWS: 'two-rows',
  THREE_ROWS: 'three-rows',
};

export enum GridType {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export const GRID_LAYOUTS = {
  grid: 'grid',
  flex: `${cssGlobalNS}-c-flex`,
};
