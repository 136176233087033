import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

/** Cashier Modal Input Wrapper Section */
interface InputWrapperProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: React.ReactNode;
  spanAllColumns?: boolean;
}

const InputsWrapper: React.FC<InputWrapperProps> = ({ className, children, spanAllColumns }) => {
  const wrapClass = clsx('terminal-ticket__input-wrapper', spanAllColumns && 'span-all-cols', className);

  return <div className={wrapClass}>{children}</div>;
};

export default InputsWrapper;
