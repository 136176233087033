import React from 'react';
import SVGCDN from 'components/shared/UI/SVGCDN/SVGCDN';
import { SocialTypes } from './types/formSocial.types';

const iconsMap = {
  [SocialTypes.GOOGLE]: 'social-google',
  [SocialTypes.FACEBOOK]: 'social-facebook',
  [SocialTypes.TWITTER]: 'social-x',
  [SocialTypes.APPLE]: 'social-apple',
};

type Props = { type: SocialTypes; size?: number };

const SocialIcon: React.FC<Props> = ({ type, size = 20 }) => {
  return <SVGCDN path="SOCIALS" size={size} icon={`${iconsMap[type]}`} />;
};

export default SocialIcon;
