import useGeneralCasinoType from 'modules/casino/shared/hooks/useGeneralCasinoType';
import { LaunchAgents } from './openLaunchAgents/openLaunchAgents';
import { CasinoLaunchGameTypes, ProviderLaunchAgents } from '../casinoLaunchTypes';
import { getAgent, getGeneratedLaunchPath } from '../casinoLaunchUtils';
import { useAggregatorConfig } from '../launcherRoute/hooks/useAggregatorConfig';
import TrackingLaunchedGameEvents from '../tracking/TrackingLaunchedGameEvents';

const OpenLauncher: React.FC<{ openGameData: CasinoLaunchGameTypes.OpenGameData }> = ({ openGameData }) => {
  const { casinoRoute } = useGeneralCasinoType();
  const aggregatorConfig = useAggregatorConfig();
  const {
    gamePlayType,
    gameId,
    isTrackable,
    iframeLoad,
    description,
    providerAlias,
    gameType,
    additionalLaunchParams,
  } = openGameData;
  const renderOpenLauncher = () => {
    const agent = getAgent(iframeLoad);
    const launchRoute = getGeneratedLaunchPath({
      description,
      gameType,
      gameId,
      gamePlayType,
      providerAlias,
      casinoRoute,
      agent,
      additionalLaunchParams,
    });

    const OpenLauncherComponent = LaunchAgents[ProviderLaunchAgents[agent]];
    return (
      <OpenLauncherComponent
        launchRoute={launchRoute}
        openGameData={openGameData}
        aggregatorConfig={aggregatorConfig}
      />
    );
  };

  return (
    <>
      {renderOpenLauncher()}
      {isTrackable && <TrackingLaunchedGameEvents openGameData={openGameData} />}
    </>
  );
};

export default OpenLauncher;
