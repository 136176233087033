import { createSlice } from '@reduxjs/toolkit';
import userStorage from 'pages/auth/login/UserStorage';
import { setLanguage } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';

export interface SettingsState {
  language: string;
}

const initialState: SettingsState = {
  language: userStorage.getUserLang() || '',
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setLanguage, (state, action) => {
      userStorage.setUserLang(action.payload);
      state.language = action.payload;
    });
  },
});

export const selectLanguage = (state: RootState): string => state.common.settings.language;

export default settingsSlice.reducer;
