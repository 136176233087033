import { LayoutConfigInnerLayoutTypes } from 'modules/casino/shared/constants';
import { useCasinoLayoutConfig } from 'modules/casino/shared/hooks/useCasinoLayoutConfig';
import { useInterval } from 'modules/casino/shared/hooks/useInterval';
import { useAppDispatch, useAppSelector } from 'store';
import { fetchBonusGames, gamesBonusSelectors } from '../slice/gameLaunchBonus.slice';
import { BONUS_TAG_INTERVAL } from '../utils/gameLaunchBonusConstants';

const BonusTagGamesPolling = (): null => {
  const dispatch = useAppDispatch();
  const runInterval = useAppSelector((state) => gamesBonusSelectors.bonusFetchInterval(state));
  const config = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.BONUS_GAMES_API });
  const fallbackInterval = config && config?.fallbackInterval ? config?.fallbackInterval : BONUS_TAG_INTERVAL;

  useInterval(() => {
    if (runInterval) {
      dispatch(fetchBonusGames());
    }
  }, fallbackInterval);

  return null;
};

export default BonusTagGamesPolling;
