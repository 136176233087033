import { useEffect } from 'react';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { CasinoLaunchGameTypes } from '../casinoLaunchTypes';
import { triggerTrackingEvents } from '../casinoLaunchUtils';
import useGetTrackingDetails from '../hooks/useGetTrackingDetails';

const TrackingLaunchedGameEvents: React.FC<{ openGameData: CasinoLaunchGameTypes.OpenGameData }> = ({
  openGameData,
}) => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectMyProfile.dataUser);
  const { trackingEventsConfig, isAuthenticated, playerId } = useGetTrackingDetails();
  const { gamePlayType, providerAlias, providerId, description, gameType, refGameId } = openGameData;

  useEffect(() => {
    const setTrackingEvents = () => {
      const trackingGameData = {
        description,
        gameType,
        refGameId,
        providerId,
        providerAlias,
      };
      triggerTrackingEvents({
        trackingGameData,
        gamePlayType,
        dispatch,
        isAuthenticated,
        playerId,
        trackingEventsConfig,
        userData,
      });
    };

    setTrackingEvents();
  }, [
    trackingEventsConfig,
    isAuthenticated,
    playerId,
    gamePlayType,
    providerAlias,
    providerId,
    description,
    gameType,
    refGameId,
    userData,
    dispatch,
  ]);
  return null;
};

export default TrackingLaunchedGameEvents;
