import React, { HTMLAttributes, memo } from 'react';
import { NotificationTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationTypes';
import { constructAnimationImage } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationUtils';
import { SCLogo } from './Logo.styled';

interface Props extends HTMLAttributes<HTMLImageElement> {
  className?: string;
  animationParams: NotificationTypes.GamificationModalParams;
}

const AnimationHeader: React.FC<Props> = ({ animationParams, ...rest }) => {
  const { logo, logoWidth, logoHeight, folder } = animationParams;

  const logoImage = constructAnimationImage({
    folder,
    image: logo,
    width: logoWidth * 2,
    height: logoHeight * 2,
  });

  return <SCLogo src={logoImage} {...rest} />;
};

export default memo(AnimationHeader);
