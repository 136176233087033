import { useEffect, useMemo } from 'react';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { selectNotification, setActivePriorityPopUp } from '../notificationSlice';
import { NotificationTypes } from '../notificationTypes';
import { NotificationPopUpTypes, findActivePopupNotificaton } from '../notificationUtils';

const useActiveNotification = () => {
  const dispatch = useAppDispatch();
  const popUpNotifications = useAppSelector(selectNotification.popUpSingleNotifications);
  const popUpSliderNotifications = useAppSelector(selectNotification.popUpSliderNotifications);
  const popUpGroupedNotifications = useAppSelector(selectNotification.popUpGroupedNotifications);

  const activePriorityPopUp = useAppSelector(selectNotification.activePriorityPopUp);
  const sliderIsDone = useAppSelector(selectNotification.sliderIsDone);
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);

  const activeNotifiction = useMemo(() => {
    if (isAuthenticated && !isEmpty(popUpNotifications)) {
      return findActivePopupNotificaton(popUpNotifications);
    }
    return undefined;
  }, [popUpNotifications, isAuthenticated]);

  useEffect(() => {
    if (
      (isEmpty(activeNotifiction) && activePriorityPopUp === NotificationPopUpTypes.single) ||
      (sliderIsDone && activePriorityPopUp === NotificationPopUpTypes.slider)
    ) {
      let isCurrent = false;
      popUpGroupedNotifications &&
        popUpGroupedNotifications.map((item, index) => {
          isCurrent && dispatch(setActivePriorityPopUp(item.type as NotificationTypes.ActivePriorityPopUpType));
          if (activePriorityPopUp === item.type) {
            isCurrent = true;
            if (Object.keys(popUpGroupedNotifications).length - 1 === index) {
              dispatch(setActivePriorityPopUp(null));
            }
          }
        });
    }
  }, [activePriorityPopUp, activeNotifiction, dispatch, popUpGroupedNotifications, sliderIsDone]);

  return {
    activeNotifiction,
    popUpSliderNotifications,
    activePriorityPopUp,
    sliderIsDone,
  };
};

export default useActiveNotification;
