import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getBusinessUnit, getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { TextPageTypes } from '../types/textPage.types';
const initialState: TextPageTypes.InitialState = {
  content: [],
  isLoading: false,
  hasError: false,
};

export const textPageThunks: TextPageTypes.Thunks = {
  getPageContent: createAbortThunk('textPage/getPageContent', async (_, { rejectWithValue }) => {
    try {
      const urlApi = `${config.API_URL}/api/cms/public/gamification-terms-conditions/${getBusinessUnit()}`;
      const response = await axiosInstance.get(urlApi);
      return response.data;
    } catch (err) {
      if (Axios.isCancel(err)) {
        return rejectWithValue(null);
      }
      return rejectWithValue(getError.default());
    }
  }),
};

const textPageSlice = createSlice({
  name: 'textPageSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(textPageThunks.getPageContent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(textPageThunks.getPageContent.fulfilled, (state, action) => {
        state.content = action.payload;
        state.isLoading = false;
      })
      .addCase(textPageThunks.getPageContent.rejected, (state) => {
        state.isLoading = false;
        state.hasError = false;
      });
  },
});

export const { reducer: textPageReducer } = textPageSlice;

export const selectTextPage = {
  content: (state: RootState): TextPageTypes.ContentType[] => state.captainUp.textPage.content || [],
  isLoading: (state: RootState): boolean => state.captainUp.textPage.isLoading,
  hasError: (state: RootState): boolean => state.captainUp.textPage.hasError,
};
