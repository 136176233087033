import { useLayoutEffect } from 'react';
import { useAuthentication } from 'hooks/common/useAuthentication';
import { CasinoWidgetsTypes } from 'modules/casino/modules/container/widget/types/casinoWidgetsTypes';

interface Props {
  widget: CasinoWidgetsTypes.Widget;
}

const SmarticoWidget: React.FC<Props> = ({ widget }) => {
  const { isAuthenticated } = useAuthentication();
  const { thirdPartyKey, id } = widget || {};
  const ifarmeKey = `${id}-smartico-widget`;

  useLayoutEffect(() => {
    if (isAuthenticated && window._smartico && thirdPartyKey) {
      window._smartico.showWidget('ui-widget', {
        iframe: ifarmeKey,
        zoom: 1,
        height: '0',
        wuuid: thirdPartyKey,
      });
    }
  }, [isAuthenticated, thirdPartyKey, ifarmeKey]);

  return <iframe id={ifarmeKey} />;
};

export default SmarticoWidget;
