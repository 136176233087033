import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './axiosBaseQuery';

export enum PlatformApiTagTypes {
  myAccountMenuItems = 'MyAccountMenuItems',
}

export const platformApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: ``,
  }),
  endpoints: () => ({}),
  tagTypes: [PlatformApiTagTypes.myAccountMenuItems],
});
