import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'modules/casino/shared/components/layout';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { replaceBulk } from 'modules/casino/shared/utils/common/interceptors.utils';
import { GameLaunchTranslationKeys } from '../../../gameLaunch/casinoLaunchConstants';
import { TopBarTypes } from '../topBar.types';

export const ChallengesPopoverItem: React.FC<TopBarTypes.ChallengesPopoverItem> = ({ item }) => {
  const { t } = useTranslation();
  const isReady = item.progress === 1;
  const textProgress = (item.progress * 100).toFixed(0);
  const title = !isReady
    ? item.title
    : replaceBulk(t(GameLaunchTranslationKeys.challengeTitle), ['name'], [item.title]);
  const progress = replaceBulk(t(GameLaunchTranslationKeys.challengeProgress), ['progress'], [textProgress]);

  return (
    <Flex.ColCenter
      className={`${cssGlobalNS}-gl__popover-inner ${isReady ? `${cssGlobalNS}-gl__popover-inner--active` : ''}`}
    >
      <Flex.Center className={`${cssGlobalNS}-gl__popover-title`}>{title}</Flex.Center>
      <div className={`${cssGlobalNS}-gl__popover-progress`}>
        <div className={`${cssGlobalNS}-gl__popover-progress--active`} style={{ width: `${textProgress}%` }}></div>
      </div>
      <div className={`${cssGlobalNS}-gl__popover-description`}>{progress}</div>
    </Flex.ColCenter>
  );
};
