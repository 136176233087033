import { useRef, FC } from 'react';
import Icon from 'components/shared/Icon';
import DefaultChevron from 'components/shared/icons/DefaultChevron';
import { CasinoScroll } from '../casinoScrollTypes';
import { scrollWithDirection } from '../casinoScrollUtils';

const LeftArrow: FC<CasinoScroll.ArrowProps> = ({ scrollEl, typeArrow, useDefaultChevron, moveWith }) => {
  const arrowLeftRef = useRef<HTMLDivElement>(null);

  const scrollToLeft = () => {
    scrollWithDirection(scrollEl, true, moveWith);
  };

  return (
    <div className={`scroll-button left-arrow ${typeArrow}-arrow-style`} ref={arrowLeftRef} onClick={scrollToLeft}>
      <div className="arrow-icon">
        {useDefaultChevron ? (
          <DefaultChevron direction="left" className={`icon left-direction`} />
        ) : (
          <Icon className={`icon left-direction`} icon={'chevron-left'} />
        )}
      </div>
    </div>
  );
};

export default LeftArrow;
