import React, { memo } from 'react';
import isEqual from 'lodash/isEqual';
import useWidgetBackground from 'modules/casino/modules/container/widget/widgetTypes/hooks/useWidgetBackground';
import { LayoutConfigInnerLayoutTypes } from 'modules/casino/shared/constants';
import { useCasinoLayoutConfig } from 'modules/casino/shared/hooks/useCasinoLayoutConfig';
import { useAppSelector } from 'store';
import { casinoWidgetsSelectors } from '../../../slice/casinoWidgetsSlice';
import { CasinoWidgetsTypes } from '../../../types/casinoWidgetsTypes';
import BannerDynamicSlider from '../components/BannerDynamicSlider';
import LastWinnersCellSkeleton from '../components/LastWinnersCellSkeleton';
import LastWinnersWidgetsTopWinner from '../components/LastWinnersWidgetsTopWinner';
import { LastWinnersWidgetsUI } from '../components/LastWinnersWidgetsUIComponents';
import { useFetchLastWinners } from '../hooks/useFetchLastWinners';
import {
  DEFAULT_BANNER_TEMPLATE_COLUMNS,
  LAST_WINNERS_PULL_INTERVAL_FALLBACK,
  TOP_WINNER_AREA,
} from '../LastWinnersWidgetsConstants';

interface Props {
  widget: CasinoWidgetsTypes.Widget;
  sliderLayout: string;
}

const LastWinnersBannerWidget: React.FC<Props> = ({ widget, sliderLayout }) => {
  const lastWinners = useAppSelector((state) => casinoWidgetsSelectors.filteredLastWinners(state, widget.id));
  const topWinner = useAppSelector((state) => casinoWidgetsSelectors.topWinner(state, widget.id));
  const lastWinnersConfig = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.LAST_WINNERS });
  const casinoLayoutConfig = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.CASINO_LAYOUT_CONFIG });
  const cdnPathLayout = casinoLayoutConfig?.defaultCdnPathLayout || 'layout_1';
  const backgroundWidgetResource = widget?.backgroundLocations;
  const type = widget?.template?.type;
  const { backgroundWrapper, setRefs } = useWidgetBackground({ backgroundWidgetResource, type });

  useFetchLastWinners({
    id: widget.id,
    query: widget.query,
    pullInterval: widget.pullInterval,
    fallbackInterval: lastWinnersConfig?.interval || LAST_WINNERS_PULL_INTERVAL_FALLBACK.BANNER_SLIDER,
  });

  return widget.id && widget.query && lastWinners && (!backgroundWidgetResource || backgroundWrapper) ? (
    <LastWinnersWidgetsUI.BannerWrapper type={type} backgroundUrl={backgroundWrapper}>
      {topWinner && (
        <LastWinnersWidgetsTopWinner
          topWinner={topWinner}
          type={type}
          layoutConfig={TOP_WINNER_AREA}
          showTitle={true}
          cdnPathLayout={cdnPathLayout}
          inBanner
        />
      )}
      <BannerDynamicSlider
        templateType={type}
        lastWinners={lastWinners}
        cdnPathLayout={cdnPathLayout}
        direction="vertical"
        sliderLayout={sliderLayout}
      />
    </LastWinnersWidgetsUI.BannerWrapper>
  ) : (
    <LastWinnersCellSkeleton type={type} columns={DEFAULT_BANNER_TEMPLATE_COLUMNS} setRefs={setRefs} />
  );
};

export default memo(LastWinnersBannerWidget, isEqual);
