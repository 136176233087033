import { useEffect } from 'react';

interface Props {
  animationAutoTrigger: boolean;
  handleAnimation: () => void;
}

export const useAnimationAutoTrigger = ({ animationAutoTrigger, handleAnimation }: Props) => {
  useEffect(() => {
    if (animationAutoTrigger) {
      handleAnimation();
    }
  }, [animationAutoTrigger]);
};
