import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { OutOfGameModalTypes, RootCasinoModalState, RootCasinoModal, InGameModalTypes } from './modal.types';

const initialState: RootCasinoModalState = {
  modalType: null,
  modalProps: {},
};

const rootCasinoModal = createSlice({
  name: 'rootCasinoModal',
  initialState,
  reducers: {
    showRootCasinoModal(state, action: PayloadAction<RootCasinoModal>) {
      const { modalType, modalProps } = action.payload;

      state.modalType = modalType;
      state.modalProps = modalProps;
    },
    hideRootCasinoModal(state) {
      state.modalType = null;
      state.modalProps = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, () => initialState);
  },
});

export const { showRootCasinoModal, hideRootCasinoModal } = rootCasinoModal.actions;

export default rootCasinoModal.reducer;

export const selectRootCasinoModal = {
  type: (state: RootState): OutOfGameModalTypes | InGameModalTypes | null => state.common.rootCasinoModal.modalType,
  props: (state: RootState): any | null => state.common.rootCasinoModal.modalProps,
};
