import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { GamificationPageTypes } from 'pages/gamificationPage/types/GamificationPage.types';
import { GamificationUI } from 'shared/common/features/gamification/components/GamificationUI';

export const SelectBonusVideo = ({
  show,
  video,
  folder,
  handleVideoIsLoaded,
  triggerVideoEnded,
  triggerVideoPlay,
}: {
  show?: boolean;
  handleVideoIsLoaded?: () => void;
  triggerVideoEnded?: () => void;
  triggerVideoPlay?: () => void;
  video?: GamificationPageTypes.CampaignVideoType;
  folder: string;
}) => {
  return (
    <AnimatePresence>
      {show && (
        <StyledVideo
          exit={{
            opacity: 0,
          }}
        >
          <GamificationUI.VideoPlayer
            video={video}
            folder={folder}
            showCover
            handleVideoIsLoaded={handleVideoIsLoaded}
            triggerVideoEnded={triggerVideoEnded}
            triggerVideoPlay={triggerVideoPlay}
            loop={false}
            videoCoverDesktop="video-cover-desktop.png"
            videoCoverMobile="video-cover-mobile.png"
          />
        </StyledVideo>
      )}
    </AnimatePresence>
  );
};

const StyledVideo = styled(motion.div)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 2;
`;
