import { AccountTypes } from 'pages/myAccount/tabs/account/types/account.types';
import { MyAccountMenu } from 'pages/myAccount/tabs/menu/types/menu.types';

export const getFromDateNormal = (activeDateFilterType: string) => {
  const date = new Date();
  switch (activeDateFilterType) {
    case 'twentyFour':
      date.setDate(date.getDate() - 1);
      return date.getTime();
    case 'fortyEight':
      date.setDate(date.getDate() - 2);
      return date.getTime();
  }
};

export const convertDateToUTC = (date: Date): Date => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};

export const getFromDate = (activeDateFilterType: string) => {
  const date = new Date();
  switch (activeDateFilterType) {
    case 'twentyFour':
      date.setDate(date.getDate() - 1);
      return convertDateToUTC(date).getTime();
    case 'fortyEight':
      date.setDate(date.getDate() - 2);
      return convertDateToUTC(date).getTime();
  }
};

export const getToDate = () => {
  return convertDateToUTC(new Date()).getTime();
};

export const getToDateNormal = () => {
  return new Date().getTime();
};

export const getValue = (value: number | string, isModule: boolean = true) =>
  ((typeof value === 'string' && Number.parseFloat(value)) || (typeof value === 'number' ? value : undefined))
    ?.toFixed(2)
    .toString()
    .replace(isModule ? '-' : '', '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') || '- -';

export const doesRouteExist = ({
  routes,
}: { routes?: MyAccountMenu.MenuResponse[] | AccountTypes.SubMenu | null } = {}) =>
  (routes as MyAccountMenu.MenuResponse[])?.find(
    ({ route, subMenu }) =>
      document.location.pathname === route ||
      (subMenu && document.location.pathname.includes(route ?? '') && doesRouteExist({ routes: subMenu })),
  );
