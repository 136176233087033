import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import { StyledSpinButton } from './SpinButton.styled';

interface SpinButtonProps {
  isClickable: boolean;
  onClick: React.MouseEventHandler;
  bgImage: string;
  spinButtonTextColor?: string;
}

const SpinButton: React.FC<SpinButtonProps> = ({ isClickable, onClick, bgImage, spinButtonTextColor }) => {
  const { t } = useTranslation();

  return (
    <StyledSpinButton
      className="grid-start-1x1 bg-contain"
      $color={spinButtonTextColor}
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      {isClickable && (
        <Flex.Center className="wh-100 cursor-pointer" onClick={onClick}>
          {t(Promotions.Gamification.wheelSpinBtnText)}
        </Flex.Center>
      )}
    </StyledSpinButton>
  );
};

export default SpinButton;
