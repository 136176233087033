import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';
import { LeaderBoardWidgetTypes } from 'modules/casino/modules/container/widget/widgetTypes/leaderBoardWidget/leaderBoardCellWidget/LeaderBoardCellWidgetTypes';
import { RequestStatuses } from 'modules/casino/shared/constants';
import { getBusinessUnit, getOSType, isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import { createAbortThunk } from 'modules/casino/store/thunkCreators';
import { RootState } from 'store/rootReducer';
import axiosInstance from 'utils/common/axios-instance';
import config from 'utils/config';
import { CasinoWidgetsTypes } from '../types/casinoWidgetsTypes';
import { PrizeDropWidgetTypes } from '../widgetTypes/prizeDropWidget/prizeDropCellWidget/PrizeDropCellWidgetTypes';
import { PromoWidgetsTypes } from '../widgetTypes/promoWidget/promoWidgetTypes';

const initialState: CasinoWidgetsTypes.State = {
  widgets: {},
  lastWinners: {},
  lastWinnersLoadingState: {},
  widgetJackpots: {},
  bigWins: {},
  bigWinsCollectionFetched: {},
  widgetPromoItems: {},
  widgetPrizeDropData: {},
  prizeDropLoadingState: {},
  leaderBoardLoadingState: {},
  widgetLeaderBoardData: {},
};

export const casinoWidgetThunks: CasinoWidgetsTypes.CasinoWidgetThunks = {
  fetchLastWinners: createAbortThunk(
    'lastWinners/fetchLastWinners',
    async ({ query, id, mobileAppRestrictions }, { source }) => {
      const modifyQuery = mobileAppRestrictions.isRestricted ? mobileAppRestrictions.modifyLastWinQuery(query) : query;
      const url = `${config.API_URL}${modifyQuery}`;
      const response = await axiosInstance.get<CasinoWidgetsTypes.LastWinner[]>(url, {
        data: null,
        cancelToken: source.token,
      });

      return { id, data: response.data };
    },
  ),
  fetchWidgetJackpots: createAbortThunk('lastWinners/fetchWidgetJackpots', async ({ query, id }, { source }) => {
    const url = `${config.API_URL}${query}`;
    const response = await axiosInstance.get<CasinoWidgetsTypes.WidgetJackpot[]>(url, {
      data: null,
      cancelToken: source.token,
    });

    return { [id]: response.data };
  }),
  fetchBigWins: createAbortThunk('lastWinners/fetchBigWins', async ({ query, id }, { source }) => {
    const url = `${config.API_URL}${query}`;
    const response = await axiosInstance.get<CasinoWidgetsTypes.BigWin[]>(url, {
      data: null,
      cancelToken: source.token,
    });

    return { id, data: response.data };
  }),
  fetchWidgetById: createAbortThunk('lastWinners/fetchWidgetById', async ({ widgetId }, { source }) => {
    const url = `${config.API_URL}/api/gaming/public/widgets/${widgetId}`;
    const response = await axiosInstance.get<CasinoWidgetsTypes.Widget>(url, {
      data: null,
      headers: { 'X-Platform-OS-type': getOSType() },
      cancelToken: source.token,
    });

    return { widgetId, data: response.data };
  }),
  fetchWidgetPromoItemIds: createAbortThunk(
    'casinoWidget/fetchWidgetPromoItemIds',
    async ({ query, id }, { source }) => {
      const url = `${config.API_URL}${query}`;
      const response = await axiosInstance.get<string[]>(url, {
        data: null,
        cancelToken: source.token,
      });

      return { id, promoIds: response.data };
    },
  ),
  fetchWidgetPromoItems: createAbortThunk('casinoWidget/fetchWidgetPromoItems', async ({ promoIds, id }) => {
    const fetchUrl = `${config.API_URL}/api/ews-bonuses/public/promotions/container/search`;
    const response = await axiosInstance.post<PromoWidgetsTypes.PromoItem[]>(fetchUrl, promoIds, {
      headers: {
        'X-Platform-Origin': getBusinessUnit(),
      },
    });

    return { id, data: response.data };
  }),
  fetchWidgetPrizeDropCampaign: createAbortThunk(
    'casinoWidgets/fetchWidgetPrizeDropCampaign',
    async ({ query, id }, { source }) => {
      const url = `${config.API_URL}${query}`;
      const response = await axiosInstance.get<PrizeDropWidgetTypes.WidgetPrizeDropCampaign>(url, {
        data: null,
        cancelToken: source.token,
      });

      return { id, data: response.data };
    },
  ),
  fetchWidgetLeaderBoardData: createAbortThunk(
    'casinoWidgets/fetchWidgetLeaderBoardData',
    async ({ query, id }, { source }) => {
      const url = `${config.API_URL}${query}`;
      const response = await axiosInstance.get<LeaderBoardWidgetTypes.LeaderBoardWidgetData>(url, {
        data: null,
        cancelToken: source.token,
      });

      return { id, data: response.data };
    },
  ),
};

export const {
  fetchLastWinners,
  fetchWidgetJackpots,
  fetchBigWins,
  fetchWidgetById,
  fetchWidgetPromoItemIds,
  fetchWidgetPromoItems,
  fetchWidgetPrizeDropCampaign,
  fetchWidgetLeaderBoardData,
} = casinoWidgetThunks;

const casinoWidgets = createSlice({
  name: 'CasinoWidgets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(casinoWidgetThunks.fetchLastWinners.pending, (state, action) => {
        const id = action.meta?.arg?.id;

        if (id) {
          state.lastWinnersLoadingState[id] = RequestStatuses.PENDING;
        }
      })
      .addCase(
        casinoWidgetThunks.fetchLastWinners.fulfilled,
        (state, action: PayloadAction<CasinoWidgetsTypes.LastWinnersFetchPayload>) => {
          const { id, data } = action.payload;

          if (data?.length) {
            state.lastWinners = { ...state.lastWinners, [id]: data };
            state.lastWinnersLoadingState[id] = RequestStatuses.INITIAL;
          } else {
            state.lastWinnersLoadingState[id] = RequestStatuses.EMPTY;
          }
        },
      )
      .addCase(casinoWidgetThunks.fetchLastWinners.rejected, (state, action) => {
        const id = action.meta?.arg?.id;

        if (id) {
          state.lastWinnersLoadingState[id] = RequestStatuses.ERROR;
        }
      })
      .addCase(
        casinoWidgetThunks.fetchWidgetJackpots.fulfilled,
        (state, action: PayloadAction<CasinoWidgetsTypes.WidgetJackpots>) => {
          const result = action.payload;
          state.widgetJackpots = { ...state.widgetJackpots, ...result };
        },
      )
      .addCase(fetchBigWins.fulfilled, (state, action: PayloadAction<CasinoWidgetsTypes.BigWinsFetchPayload>) => {
        const { id, data } = action.payload;

        state.bigWins[id] = data;
        state.bigWinsCollectionFetched[id] = true;
      })
      .addCase(fetchBigWins.rejected, (state, action) => {
        const widgetId = action.meta?.arg?.id;

        if (widgetId) {
          state.bigWinsCollectionFetched[widgetId] = true;
        }
      })
      .addCase(fetchWidgetById.fulfilled, (state, action) => {
        const { widgetId, data } = action.payload;

        if (widgetId) {
          state.widgets[widgetId] = data;
        }
      })
      .addCase(
        fetchWidgetPromoItemIds.fulfilled,
        (state, action: PayloadAction<CasinoWidgetsTypes.WidgetPromoItemIdsFetchPayload>) => {
          const { id, promoIds } = action.payload;

          if (promoIds?.length) {
            state.widgetPromoItems[id] = { ...(state.widgetPromoItems[id] || {}), itemIds: promoIds };
          }
        },
      )
      .addCase(
        fetchWidgetPromoItems.fulfilled,
        (state, action: PayloadAction<CasinoWidgetsTypes.WidgetPromoItemsFetchPayload>) => {
          const { id, data } = action.payload;

          if (data?.length) {
            state.widgetPromoItems[id] = { ...(state.widgetPromoItems[id] || {}), items: data };
          }
        },
      )
      .addCase(fetchWidgetPrizeDropCampaign.pending, (state, action) => {
        const id = action.meta?.arg?.id;

        if (id) {
          state.prizeDropLoadingState[id] = RequestStatuses.PENDING;
        }
      })
      .addCase(
        fetchWidgetPrizeDropCampaign.fulfilled,
        (state, action: PayloadAction<CasinoWidgetsTypes.WidgetPrizeDropFetchPayload>) => {
          const { id, data } = action.payload;

          if (!isEmpty(data)) {
            state.widgetPrizeDropData[id] = { ...(state.widgetPrizeDropData[id] || {}), ...data };
            state.prizeDropLoadingState[id] = RequestStatuses.INITIAL;
          } else {
            state.prizeDropLoadingState[id] = RequestStatuses.EMPTY;
          }
        },
      )
      .addCase(fetchWidgetPrizeDropCampaign.rejected, (state, action) => {
        const id = action.meta?.arg?.id;

        if (id) {
          state.prizeDropLoadingState[id] = RequestStatuses.ERROR;

          if (state.widgetPrizeDropData[id]) {
            delete state.widgetPrizeDropData[id];
          }
        }
      })
      .addCase(fetchWidgetLeaderBoardData.pending, (state, action) => {
        const id = action.meta?.arg?.id;

        if (id) {
          state.leaderBoardLoadingState[id] = RequestStatuses.PENDING;
        }
      })
      .addCase(
        fetchWidgetLeaderBoardData.fulfilled,
        (state, action: PayloadAction<CasinoWidgetsTypes.WidgetLeaderBoardFetchPayload>) => {
          const { id, data } = action.payload;

          if (!isEmpty(data)) {
            state.widgetLeaderBoardData[id] = { ...(state.widgetLeaderBoardData[id] || {}), ...data };
            state.leaderBoardLoadingState[id] = RequestStatuses.INITIAL;
          } else {
            state.leaderBoardLoadingState[id] = RequestStatuses.EMPTY;
          }
        },
      )
      .addCase(fetchWidgetLeaderBoardData.rejected, (state, action) => {
        const id = action.meta?.arg?.id;

        if (id) {
          state.leaderBoardLoadingState[id] = RequestStatuses.ERROR;

          if (state.widgetLeaderBoardData[id]) {
            delete state.widgetLeaderBoardData[id];
          }
        }
      });
  },
});

const selectLastWinnersLoadingStatus = (state: RootState, widgetId: string): number =>
  state.casino.casinoWidgets.lastWinnersLoadingState[widgetId];

const selectLastWinners = createCachedSelector(
  (state, _) => state.casino.casinoWidgets.lastWinners,
  (state, id) => id,
  (lastWinners, id): CasinoWidgetsTypes.LastWinner[] => lastWinners[id],
)((state, id) => id);

const selectFilteredLastWinners = createSelector(selectLastWinners, (lastWinners): CasinoWidgetsTypes.LastWinner[] =>
  lastWinners?.filter((winner) => !winner.isTopWin),
);

const selectTopWinner = createSelector(selectLastWinners, (lastWinners): CasinoWidgetsTypes.LastWinner | undefined =>
  lastWinners?.find((winner) => winner.isTopWin),
);

const selectWidgetJackpots = createCachedSelector(
  (state: RootState) => state.casino.casinoWidgets.widgetJackpots,
  (_, widgetId: string) => widgetId,
  (widgetJackpots, widgetId): CasinoWidgetsTypes.WidgetJackpot[] => widgetJackpots[widgetId] || [],
)((_, widgetId) => widgetId);

const selectWidgetPromoItemIds = (state: RootState, widgetId: string): string[] =>
  state.casino.casinoWidgets.widgetPromoItems[widgetId]?.itemIds || [];

const selectWidgetPromoItems = createCachedSelector(
  (state: RootState) => state.casino.casinoWidgets.widgetPromoItems,
  (_, widgetId: string) => widgetId,
  (promoItems, widgetId): PromoWidgetsTypes.PromoItem[] => promoItems[widgetId]?.items || [],
)((_, widgetId) => widgetId);

const selectCategoryCollection = (state: RootState, widgetId: string): boolean | undefined =>
  state.casino.casinoWidgets.bigWinsCollectionFetched[widgetId];

const selectBigWins = createCachedSelector(
  (state: RootState) => state.casino.casinoWidgets.bigWins,
  (_, widgetId: string) => widgetId,
  (bigWins, widgetId): CasinoWidgetsTypes.BigWin[] => bigWins[widgetId] || [],
)((_, widgetId) => widgetId);

const selectWidgetById = (state: RootState, widgetId: string): CasinoWidgetsTypes.Widget | undefined =>
  state.casino.casinoWidgets.widgets[widgetId];

const selecPrizeDropData = createCachedSelector(
  (state, _) => state.casino.casinoWidgets.widgetPrizeDropData,
  (state, id) => id,
  (prizeDropData, id): PrizeDropWidgetTypes.WidgetPrizeDropCampaign => prizeDropData[id],
)((state, id) => id);

const selectPrizeDropLoadingStatus = (state: RootState, widgetId: string): number =>
  state.casino.casinoWidgets.prizeDropLoadingState[widgetId];

const selectLeaderBoardData = createCachedSelector(
  (state, _) => state.casino.casinoWidgets.widgetLeaderBoardData,
  (state, id) => id,
  (leaderBoardData, id): LeaderBoardWidgetTypes.LeaderBoardWidgetData => leaderBoardData[id],
)((state, id) => id);

const selectPLeaderBoardLoadingStatus = (state: RootState, widgetId: string): number =>
  state.casino.casinoWidgets.leaderBoardLoadingState[widgetId];

export const casinoWidgetsSelectors = {
  lastWinnersLoadingStatus: selectLastWinnersLoadingStatus,
  filteredLastWinners: selectFilteredLastWinners,
  topWinner: selectTopWinner,
  widgetJackpots: selectWidgetJackpots,
  bigWins: selectBigWins,
  bigWinsCollectionFetched: selectCategoryCollection,
  widgetById: selectWidgetById,
  widgetPromoItemIds: selectWidgetPromoItemIds,
  widgetPromoItems: selectWidgetPromoItems,
  widgetPrizeDropData: selecPrizeDropData,
  prizeDropLoadingStatus: selectPrizeDropLoadingStatus,
  widgetLeaderBoardData: selectLeaderBoardData,
  leaderBoardLoadingStatus: selectPLeaderBoardLoadingStatus,
};

export default casinoWidgets.reducer;
