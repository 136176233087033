import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { TournamentsButton } from '../types/tournamentsButton.types';

export const TournamentsButtonThunks: TournamentsButton.Thunks = {
  fetchTournaments: createAbortThunk(
    'fetchTournaments',
    async ({ gameType, providerId, gameId, product }, { source, rejectWithValue }) => {
      try {
        const query = `?gameType=${gameType}&providerId=${providerId}&gameId=${gameId}&product=${product}`;
        const url = `${config.API_URL}/api/player-tournaments/leaderboard/get-casino-tournaments-leaderboards${query}`;
        const response = await axiosInstance.get<TournamentsButton.Data | 'null'>(url, {
          cancelToken: source.token,
        });

        return response.data === 'null' ? null : response.data;
      } catch (err) {
        return rejectWithValue(getError.responseData(err));
      }
    },
  ),
};

export const { fetchTournaments } = TournamentsButtonThunks;

const initialState: TournamentsButton.SliceTypes = {
  data: null,
  error: null,
  isLoading: true,
  isSuccess: false,
};

const tournamentsButtonSlice = createSlice({
  name: 'tournamentsButton',
  initialState,
  reducers: {
    clearTournamentsButtonData(state) {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTournaments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTournaments.rejected, (state) => {
        state.error = getError.default();
        state.isLoading = false;
        state.isSuccess = false;
      })
      .addCase(fetchTournaments.fulfilled, (state, action: PayloadAction<TournamentsButton.ApiResponse.Main>) => {
        if (JSON.stringify(state.data) !== JSON.stringify(action.payload)) {
          state.data = action.payload;
        }
        state.error = null;
        state.isSuccess = true;
        state.isLoading = false;
      });
  },
});

export const { clearTournamentsButtonData } = tournamentsButtonSlice.actions;
export default tournamentsButtonSlice.reducer;

export const selectTournamentsButton = {
  isLoading: (state: RootState): boolean => state.casino.tournamentsButton.isLoading,
  isSuccess: (state: RootState): boolean => state.casino.tournamentsButton.isSuccess,
  data: (state: RootState): TournamentsButton.Data => state.casino.tournamentsButton.data,
  error: (state: RootState): string | null | undefined =>
    state.casino.tournamentsButton.error as string | null | undefined,
};
