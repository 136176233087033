import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateNotifications } from 'shared/common/features/inGameNotifications/slice/inGameNotifications.slice';
import { WsNotificationsTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/types/wsNotifications.types';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch } from 'store';

export const useCloseModal = (
  activeNotification: WsNotificationsTypes.Notification,
  btnNavigationLink?: string,
): { handleClose: () => void; handleInfo: () => void } => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    dispatch(updateNotifications(activeNotification.Id));
    dispatch(hideRootModal());
  }, []);

  const handleInfo = useCallback(() => {
    handleClose();
    if (btnNavigationLink) {
      navigate(btnNavigationLink);
    } else if (activeNotification.Button?.buttonUrl) {
      navigate(activeNotification.Button?.buttonUrl);
    }
  }, [activeNotification, btnNavigationLink, navigate]);

  return { handleClose, handleInfo };
};
