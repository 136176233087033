import { useEffect } from 'react';
import { useAppDispatch } from 'store';
import { useInitialJackpotLevelsFetch } from './useInitialJackpotLevelsFetch';
import { setPauseFetchInterval, setRunFetchInterval } from '../jackpotSlice';

export const useSetJackpotLevelsPolling = ({
  skipPolling,
  pauseSetPolling,
}: {
  skipPolling?: boolean;
  pauseSetPolling?: boolean;
}): void => {
  const dispatch = useAppDispatch();
  useInitialJackpotLevelsFetch();

  useEffect(() => {
    if (skipPolling) {
      return;
    }

    dispatch(setRunFetchInterval(true));

    if (!pauseSetPolling) {
      dispatch(setPauseFetchInterval(true));
    }

    return () => {
      dispatch(setRunFetchInterval(false));
      dispatch(setPauseFetchInterval(false));
    };
  }, [dispatch, skipPolling, pauseSetPolling]);
};
