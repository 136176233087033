import { useEffect } from 'react';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { useChallengesGamesFetchStatus } from './useChallengesGamesFetchStatus';
import { useInitialChallengesFetch } from './useInitialChallengesFetch';
import { DEFAULT_GAME_ID_LOBBY } from '../../gameLaunch/casinoLaunchConstants';
import { fetchGamesChallenges, resetChallenge } from '../slice/gameLaunchBonus.slice';
import { CHALLENGES_INTERVAL } from '../utils/gameLaunchBonusConstants';

export const useGamesChallengesPolling = (gameId: string | undefined, isFreePlay: boolean) => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const { challengesGamesFetchStatusInitial } = useChallengesGamesFetchStatus();
  const fetchApi = !!(!isFreePlay && isAuthenticated && gameId && gameId !== DEFAULT_GAME_ID_LOBBY);
  useInitialChallengesFetch({ gameId, fetchApi });

  useEffect(() => {
    let interval;

    if (fetchApi && !challengesGamesFetchStatusInitial && gameId) {
      interval = setInterval(() => {
        dispatch(fetchGamesChallenges(gameId));
      }, CHALLENGES_INTERVAL);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, fetchApi, challengesGamesFetchStatusInitial, gameId]);

  useEffect(() => {
    return () => {
      dispatch(resetChallenge());
    };
  }, [dispatch]);
};
