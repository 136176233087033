import { useEffect } from 'react';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { CustomPlatformEvents } from 'shared/common/hooks/useHandleCustomEvents';
import { useAppDispatch, useAppSelector } from 'store';
import { clearLoginError } from '../../slice/login.slice';

export const useLoginPhoneConfirmation = (error?: string, closeQuickLoginForm?: () => void) => {
  const phoneOnlyConfirmation = useAppSelector(selectGeneral.phoneOnlyConfirmation);
  const showConfirmation = error === 'phone_not_verified' && phoneOnlyConfirmation;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (showConfirmation) {
      closeQuickLoginForm && closeQuickLoginForm();
      document.dispatchEvent(new Event(CustomPlatformEvents.HIGH_PRIORITY_MODAL));
      dispatch(
        showRootModal({
          modalType: 'PHONE_CONFIRMATION',
          modalPriority: ModalPriorityEnum.GRAND,
          modalId: 'PHONE_CONFIRMATION',
          modalProps: { type: 'public', location: 'login' },
        }),
      );
      return () => {
        dispatch(clearLoginError());
      };
    }
  }, [showConfirmation, dispatch, closeQuickLoginForm]);
};
