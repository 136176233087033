import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCountDown } from 'hooks/common/useCountDown';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import { addLeadingZero } from 'utils/dateUtils';
import TournamentsLeaderBoardTimeCounterItem from './TournamentsLeaderBoardTimeCounterItem';
import { tlbCSSNS } from '../types/tournamentsUI.types';

type Props = {
  date: number;
  text: string;
};

const TournamentsLeaderBoardTimeCounter: React.FC<Props> = ({ date, text }) => {
  const { t } = useTranslation();
  const { timer } = useCountDown(date, 'systemTime');

  return (
    <div className={`${tlbCSSNS}__counter`}>
      <span>{text}</span>

      {timer?.days !== undefined && (
        <TournamentsLeaderBoardTimeCounterItem
          date={addLeadingZero(timer.days > 0 ? timer.days : 0)}
          text={t(Promotions.PromotionCell.daysText)}
        />
      )}
      {timer?.hours !== undefined && (
        <TournamentsLeaderBoardTimeCounterItem
          date={addLeadingZero(timer.hours > 0 ? timer.hours : 0)}
          text={t(Promotions.PromotionCell.hoursText)}
        />
      )}
      {timer?.minutes !== undefined && (
        <TournamentsLeaderBoardTimeCounterItem
          date={addLeadingZero(timer.minutes > 0 ? timer.minutes : 0)}
          text={t(Promotions.PromotionCell.minutesText)}
        />
      )}
    </div>
  );
};

export default TournamentsLeaderBoardTimeCounter;
