import React, { memo } from 'react';
import { motion } from 'framer-motion';
import { isIOS, isAndroid } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { UI } from 'components/shared/UI';
import { General } from 'shared/common/features/general/types/generalSlice.types';
import { localLog } from 'utils/common/helpersCommon';
import useIsMobileBannerActive from '../../hooks/useIsMobileBannerActive';
import useMobileCloseBanner from '../../hooks/useMobileCloseBanner';
import { animationState } from '../appHeaderContainer/utils/appHeader.utils';
import TopMobileBannerNavigationElement from '../TopMobileBannerNavigationElement';

interface HeadBackRefProps {
  headBackRef: React.MutableRefObject<HTMLDivElement | null>;
  isTabletOrMobile: boolean;
  bannerConfig: General.TopMobileAppBanner;
}

const TopMobileAppBanner: React.FC<HeadBackRefProps> = ({ headBackRef, isTabletOrMobile, bannerConfig }) => {
  // const { scrollY } = useViewportScroll();
  const { t } = useTranslation();
  const supportedDeviceDetect = isIOS ? 'ios' : isAndroid ? 'android' : '';
  // const yPosition = useTransform(scrollY, [-100, -1, 1, 60], [0, 60, 60, 0]);

  const { bannerClosed, setBannerClosed, handleCloseBanner, topMobileBannerCookie } = useMobileCloseBanner(
    bannerConfig,
    headBackRef,
    isTabletOrMobile,
  );

  useIsMobileBannerActive(
    headBackRef,
    bannerConfig,
    topMobileBannerCookie,
    setBannerClosed,
    supportedDeviceDetect,
    isTabletOrMobile,
  );

  if (bannerClosed) {
    return null;
  }
  const isAppDownloaded = false;

  const openMobileApp = () => {
    localLog({ message: 'OPEN APP' });
  };

  return (
    <motion.div
      // style={{
      //   height: yPosition,
      // }}
      initial={animationState.showBanner}
    >
      <Flex.AC className={'top-banner'}>
        <Icon className="top-banner__close" icon="times" onClick={handleCloseBanner} />
        <div className="top-banner__info">
          <div className={'top-banner__title'}>{t(bannerConfig[supportedDeviceDetect].title)}</div>
          <div className={'top-banner__description'}>{t(bannerConfig[supportedDeviceDetect].description)}</div>
        </div>
        {!isAppDownloaded ? (
          <TopMobileBannerNavigationElement
            bannerConfigPerDevice={bannerConfig[supportedDeviceDetect]}
            device={supportedDeviceDetect}
          />
        ) : (
          <UI.Button
            size="md"
            className="top-banner__link"
            onClick={openMobileApp}
            text={t(bannerConfig[supportedDeviceDetect].buttonText)}
          />
        )}
      </Flex.AC>
    </motion.div>
  );
};

export default memo(TopMobileAppBanner);
