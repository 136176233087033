import React from 'react';
import { ZASlider } from 'modules/casino/shared/features/ZASlider';
import LastWinnersWidgetGameItem from './LastWinnersWidgetsGameComponents/LastWinnersWidgetGameItem';
import { CasinoWidgetsTypes } from '../../../types/casinoWidgetsTypes';

interface BannerDynamicSliderProps {
  templateType: CasinoWidgetsTypes.WidgetTypes;
  lastWinners: CasinoWidgetsTypes.LastWinner[];
  cdnPathLayout: string;
  direction: string;
  sliderLayout: string;
}

const lastWinnerResponsiveSettings = (sliderLayout) => {
  switch (sliderLayout) {
    case 'layout_1':
      return '';
    case 'layout_2':
      return '';
    case 'layout_3':
      return '';
    case 'layout_6':
      return '';
    default:
      return '';
  }
};

const lastWinnerInitialItemDimension = (sliderLayout) => {
  switch (sliderLayout) {
    case 'layout_1':
      return 56;
    case 'layout_2':
      return 52;
    case 'layout_3':
      return 56;
    case 'layout_6':
      return 56;
    default:
      return 56;
  }
};

const lastWinnerSpaceBetween = (sliderLayout) => {
  switch (sliderLayout) {
    case 'layout_1':
      return 4;
    default:
      return 4;
  }
};

const BannerDynamicSlider: React.FC<BannerDynamicSliderProps> = ({
  templateType,
  lastWinners,
  cdnPathLayout,
  direction,
  sliderLayout,
}) => {
  const settings = {
    direction,
    slidesToShow: 5,
    spaceBetween: lastWinnerSpaceBetween(sliderLayout), // in pixels
    itemDimension: lastWinnerInitialItemDimension(sliderLayout),
    animationDirection: 'right',
    autoplaySpeed: 3000,
    animationSpeed: 1000,
    easing: 'easeInOutQuint',
    responsive: lastWinnerResponsiveSettings(sliderLayout),
    duplicateItems: true,
  };
  const sortILastWinnersByWinTime = (items) => {
    const winners = [...items];
    const sortedItems = winners.sort(function (x, y) {
      return x.winTime - y.winTime;
    });
    return sortedItems.reverse();
  };

  return (
    <div className="cw__games-wrapper">
      {lastWinners && (
        <ZASlider settings={settings} items={sortILastWinnersByWinTime(lastWinners)}>
          {(item) => {
            return <LastWinnersWidgetGameItem lastWinner={item} type={templateType} cdnPathLayout={cdnPathLayout} />;
          }}
        </ZASlider>
      )}
    </div>
  );
};
export default BannerDynamicSlider;
