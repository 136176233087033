import { useMemo } from 'react';
import useGeneralCasinoType from 'modules/casino/shared/hooks/useGeneralCasinoType';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { selectLanguage } from 'shared/common/features/settings/slice/settings.slice';
import { useAppSelector } from 'store';
import { categoryBreadcrumbSelectors } from '../../../shared/features/categoryBreadcrumb/slice/CategoryBreadcrumbSlice';
import { CategoryLayoutTypes } from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { createGameCollectionId } from '../utils/casinoGamesUtils';

export const useGameCollectionId = ({
  categoryId,
  categoryLayoutType,
}: {
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
}): { gameCollectionId: string } => {
  const { casinoType } = useGeneralCasinoType();
  const searchTagId = useAppSelector((state) => categoryBreadcrumbSelectors.activeFilterId(state, categoryId));
  const language = useAppSelector(selectLanguage);
  const { queryType } = useGetCategoryParams(categoryId, categoryLayoutType);
  const playerId = useAppSelector(selectMyProfile.playerId);

  const gameCollectionId = useMemo(() => {
    return createGameCollectionId({
      id: categoryId,
      casinoType,
      language,
      searchTagId,
      queryType,
      playerId,
      categoryLayoutType,
    });
  }, [categoryId, casinoType, language, searchTagId, queryType, playerId]);

  return { gameCollectionId };
};
