import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { setActiveStep } from 'pages/auth/registration/slice/registration.slice';
import { useAppDispatch } from 'store';
import { SSBT } from 'utils/common/translationUtils/translationStrings';
import { TerminalModalUI } from '../../../components/ssbtModalUI';
import { TerminalModalActionIcons, FormSteps } from '../../../types/ssbt.types';

type Props = {
  handleNextStep: () => void;
  setActiveStep: Dispatch<SetStateAction<FormSteps>>;
};

const SecondStepDepositSSBTmodal: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  // const { currencySymbol } = useCurrency();

  return (
    <>
      <TerminalModalUI.Header text={t(SSBT.TerminalModal.depositSumText)} />
      <TerminalModalUI.ContentWrapper>
        <TerminalModalUI.ActionIcon icon={TerminalModalActionIcons.DEPOSIT_CASH} />
        <TerminalModalUI.Button
          variant={'disabled'}
          text={t(SSBT.TerminalModal.depositText)}
          textSecondary={``}
          onClick={() => {
            return;
          }}
        />
        <TerminalModalUI.Link
          text={t(SSBT.TerminalModal.anotherDepositMethod)}
          onClick={() => dispatch(setActiveStep(1))}
        />
      </TerminalModalUI.ContentWrapper>
    </>
  );
};

export default SecondStepDepositSSBTmodal;
