import { useEffect } from 'react';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import {
  printCashierCancelTicketMobile,
  printCashierPayoutMobile,
} from 'modules/retail/modules/cashier/printMethods/cashierMobilePrint.utils';
import {
  printCashierCancelTicket,
  printCashierPayout,
} from 'modules/retail/modules/cashier/printMethods/cashierPrint.utils';
import { setIsPrintingEjected } from 'modules/retail/modules/cashier/slice/cashier.slice';

import { PrintTerminalDataType } from 'modules/retail/modules/ssbt/types/ssbt.types';
import { printTerminalDataMobile } from 'modules/retail/shared/printMethods/mobilePrintMethods/mobilePrint.utils';
import { printTerminalData } from 'modules/retail/shared/printMethods/ticketPrintPartsMethods/ticketPrint.utils';
import userStorage from 'pages/auth/login/UserStorage';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { store, useAppDispatch, useAppSelector } from 'store';
import { isEmpty, isHandheld } from 'utils/common/helpersCommon';
import {
  lastPrintThunk,
  resetLastPrintInfoStatus,
  selectLastPrintInfo,
  setLastPrintClick,
} from '../slice/lastPrint.slice';

export const useLastPrint = () => {
  const isMagnumBetRo = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.IS_MAGNUM_BET_RO,
    deviceLayout: 'general',
  });
  const isCurrencyBeforeAmount = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.CURRENCY_BEFORE_AMOUNT,
    deviceLayout: 'general',
  });
  const dispatch = useAppDispatch();
  const lastPrintInfoStatus = useAppSelector(selectLastPrintInfo.lastPrintInfoStatus);
  const isLastPrintClicked = useAppSelector(selectLastPrintInfo.lastPrintClicked);
  const lastPrintInfo = useAppSelector(selectLastPrintInfo.lastPrintInfo);
  const lastPrintInfoError = useAppSelector(selectLastPrintInfo.lastPrintInfoError);

  const retailAddress = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.RETAIL_ADDRESS,
    deviceLayout: 'general',
  });
  const userCurr = userStorage.getUserCurrency();
  const currency = userCurr || '';
  useEffect(() => {
    if (isLastPrintClicked) {
      dispatch(setLastPrintClick(false));
      dispatch(lastPrintThunk.lastPrint());
      dispatch(setIsPrintingEjected(true));
    }
  }, [isLastPrintClicked]);

  const printTerminalDataObj: PrintTerminalDataType = {
    isDuplicate: true,
    ticketId: lastPrintInfo?.ticketNumber ?? '',
    timestamp: lastPrintInfo?.ticketData?.value?.timestamp ?? 0,
    totalWin: lastPrintInfo?.ticketData?.value?.summary?.paidWinLong ?? 0,
    outcomes: lastPrintInfo?.ticketData?.value?.outcomes ?? [],
    stake: lastPrintInfo?.ticketData?.value?.summary?.totalStake ?? 0,
    summary: lastPrintInfo?.ticketData?.value?.summary?.summaries ?? [],
    totalOdds: lastPrintInfo?.ticketData?.value?.summary?.totalOdds.toString() ?? '',
    getState: store?.getState,
    totalCombinations: lastPrintInfo?.ticketData?.value?.summary?.totalCombinations ?? 0,
    currency: currency,
    retailInfo: lastPrintInfo,
    isCurrencyBeforeAmount: isCurrencyBeforeAmount ?? false,
  };

  useEffect(() => {
    if (lastPrintInfoStatus === 'succeeded') {
      if (!isEmpty(lastPrintInfo) && !isEmpty(lastPrintInfo.ticketData)) {
        if (isHandheld()) {
          printTerminalDataMobile(printTerminalDataObj);
        } else {
          printTerminalData(printTerminalDataObj);
        }
      } else if (!isEmpty(lastPrintInfo) && isEmpty(lastPrintInfo.ticketData)) {
        if (lastPrintInfo.ticketStatus === 'Cancelled') {
          const printCashierCancelTicketObj = {
            stake: isMagnumBetRo ? lastPrintInfo?.grossStake : lastPrintInfo.amount,
            code: lastPrintInfo.ticketNumber,
            currency: lastPrintInfo.currency,
            retailAddress,
            isDuplicate: true,
            posName: lastPrintInfo?.posName,
            posAddress: lastPrintInfo?.posAddress,
            isCurrencyBeforeAmount,
          };
          if (isHandheld()) {
            printCashierCancelTicketMobile(printCashierCancelTicketObj);
          } else {
            printCashierCancelTicket(printCashierCancelTicketObj);
          }
        } else if (lastPrintInfo.paymentStatus === 'Paid') {
          const printCashierPayoutObj = {
            totalWin: lastPrintInfo.paidAmount,
            code: lastPrintInfo.ticketNumber,
            currency: lastPrintInfo.currency,
            retailAddress,
            isDuplicate: true,
            isClaimForm: lastPrintInfo.isClaimForm,
            dailySequenceNumber: lastPrintInfo.dailySequenceNumber,
            pinNumber: lastPrintInfo.pin,
            totalStake: lastPrintInfo.amount,
            posName: lastPrintInfo.posName,
            posAddress: lastPrintInfo.posAddress,
            betTax: lastPrintInfo?.tax,
            totalAmountAfterTax: lastPrintInfo?.totalAmountAfterTax,
            isCurrencyBeforeAmount,
          };
          if (isHandheld()) {
            printCashierPayoutMobile(printCashierPayoutObj);
          } else {
            printCashierPayout(printCashierPayoutObj);
          }
        }
        if (
          isEmpty(lastPrintInfo?.ticketData) &&
          lastPrintInfo.paymentStatus !== 'Paid' &&
          lastPrintInfo.ticketStatus !== 'Cancelled'
        ) {
          dispatch(setIsPrintingEjected(false));
        }
      }
    } else if (lastPrintInfoStatus === 'failure') {
      dispatch(
        showRootModal({
          modalType: 'CASHIER_ERROR_MODAL',
          modalId: 'CASHIER_ERROR_MODAL',
          modalPriority: ModalPriorityEnum.LOW,
          modalProps: { errorMessage: lastPrintInfoError, resetStatus: resetLastPrintInfoStatus },
        }),
      );
      dispatch(setIsPrintingEjected(false));
    }

    dispatch(resetLastPrintInfoStatus());
  }, [lastPrintInfoStatus]);
};
