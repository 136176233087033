import { useTheme } from 'styled-components';
import { getLastWinnersDynamicSliderSettings } from 'modules/casino/modules/container/widget/widgetTypes/lastWinnersWidgets/components/sliderConfigSettings/getLastWinnersDynamicSliderSettings';
import { WidgetGamesPreViewDesktopXL, WidgetTemplate } from 'modules/casino/shared/constants';
import { CasinoWidgetsTypes, Destinations } from '../../../types/casinoWidgetsTypes';

export const useDynamicSliderSettings = ({
  templateType,
  destination,
  direction,
  animationInterval,
}: {
  templateType: CasinoWidgetsTypes.WidgetTypes;
  destination: Destinations;
  direction: string;
  animationInterval?: number;
}) => {
  const theme = useTheme();
  const latestWinnersConfig = theme?.LayoutConfigs?.casino?.latestWinnersNew;
  const latestWinnersLayout = latestWinnersConfig ? latestWinnersConfig[destination] : 'layout_1';
  const { lastWinnerResponsive, lastWinnerSpaceBetween, lastWinnerInitialItemDimension } =
    getLastWinnersDynamicSliderSettings(templateType, latestWinnersLayout);
  const settings = {
    direction,
    slidesToShow: WidgetGamesPreViewDesktopXL[templateType],
    spaceBetween: lastWinnerSpaceBetween, // in pixels
    itemDimension: lastWinnerInitialItemDimension,
    animationDirection: direction === 'horizontal' ? 'left' : 'right',
    autoplaySpeed: 3000,
    animationSpeed: animationInterval ? animationInterval : 1000,
    easing: 'easeInOutQuint',
    responsive: lastWinnerResponsive,
    duplicateItems: WidgetTemplate[templateType] === WidgetTemplate.SECTION_STAKE_PLUS ? false : true,
  };

  return { settings };
};
