import React, { Fragment, memo, useEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';
import { disableSaveScrollPositionMap } from 'modules/casino/modules/container/cellsContainer/grid/components/gridCell/gridCellConstants';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { useSetRefsLoaded } from 'modules/casino/shared/hooks/useSetRefsLoaded';
import { Casino } from 'modules/casino/shared/types';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { getCellPosition } from 'modules/casino/shared/utils/casinoUtils';
import { useCategoryGamesIds } from '../../../../cellls/hooks/useCategoryGamesIds';
import { ExpandingBannerSize, ExpandingGridSize, GridLayouts, GridRowType } from '../../../types/gridTypes';
import GenericLayoutWrapper from '../../layouts/GenericLayoutWrapper';
import SliderGridWrapper from '../../layouts/SliderGridWrapper';
import ExpandingSection from '../../specialCell/components/expandingSection/ExpandingSection';

type Props = {
  children: (
    itemId: string,
    key: number,
    isLastItem: boolean,
    gridPage: number,
    gamesRenderedCount: number,
    skipLazyLoad: boolean,
  ) => React.ReactNode;
  subType: CategorySubTypes;
  layoutConfig: Casino.LayoutConfig;
  collectionId: string;
  className: string;
  categoryLayout: CategoryLayoutTypes;
  isCategoryLast?: boolean;
  categoryId: string;
};

const NestedGridSlider: React.FC<Props> = ({ children, subType, collectionId, categoryId, categoryLayout }) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const scrollWrapper = scrollContainerRef.current;
  const { refLoaded, setRefs, ref: sliderGridRef } = useSetRefsLoaded();
  const [bannerSize, setBannerSize] = useState<ExpandingBannerSize>({ width: 0, height: 0 });
  const [bannerImageSize, setBannerImageSize] = useState<ExpandingBannerSize>({ width: 0, height: 0 });
  const [expandingGridSize, setExpandingGridSize] = useState<ExpandingGridSize | null>(null);

  const getGridSize = () => {
    const sliderGrid = sliderGridRef.current as HTMLDivElement;
    const templateRows = Number(
      window.getComputedStyle(sliderGrid).getPropertyValue('grid-template-rows').split('px ')[0],
    );
    const templateColumns = Number(
      window.getComputedStyle(sliderGrid).getPropertyValue('grid-template-columns').split('px')[0],
    );
    const gridGap = Number(window.getComputedStyle(sliderGrid).getPropertyValue('grid-gap').split('px')[0]);
    const gridCellHeight = templateRows * 2 + gridGap;
    const gridCellWidth = templateColumns;

    const aspr = templateColumns / templateRows;
    const expandingGridCols = templateColumns - 40;
    const expandingGridRows = expandingGridCols / aspr;

    return {
      gridTemplateRows: `${expandingGridRows}px ${expandingGridRows}px`,
      gridAutoColumns: `${expandingGridCols}px`,
      gridCellHeight: gridCellHeight,
      gridCellWidth: gridCellWidth,
    };
  };

  const disableSaveScrollPosition = subType ? disableSaveScrollPositionMap.includes(subType) : false;
  const gamesIds = useCategoryGamesIds({ categoryLayoutType: categoryLayout, categoryId });
  const cell = getCellPosition(disableSaveScrollPosition);

  useEffect(() => {
    if (refLoaded && sliderGridRef.current) {
      const { gridCellWidth, gridCellHeight, gridTemplateRows, gridAutoColumns } = getGridSize();
      setExpandingGridSize({
        gridTemplateRows: gridTemplateRows,
        gridAutoColumns: gridAutoColumns,
      });
      setBannerSize({ width: gridCellWidth, height: gridCellHeight });
      setBannerImageSize({ width: gridCellWidth, height: gridCellHeight });
    }
    scrollWrapper && ro.observe(scrollWrapper);
    return () => {
      scrollWrapper && ro.unobserve(scrollWrapper);
    };
  }, [refLoaded]);

  const ro = new ResizeObserver(
    throttle(() => {
      const { gridCellWidth, gridCellHeight, gridTemplateRows, gridAutoColumns } = getGridSize();
      setExpandingGridSize({
        gridTemplateRows: gridTemplateRows,
        gridAutoColumns: gridAutoColumns,
      });
      setBannerSize({ width: gridCellWidth, height: gridCellHeight });
    }, 500),
  );

  const sliderRender = () => {
    const elements: React.ReactNode[] = gamesIds.map((gameId, index) => {
      const skipLazyLoad = (cell && cell?.collectionId === collectionId) || false;

      return (
        <Fragment key={gameId}>
          {children(gameId, index, gamesIds.length - 1 === index, 0, index, skipLazyLoad)}
        </Fragment>
      );
    });

    return elements;
  };
  return (
    <SliderGridWrapper className={`${cssGlobalNS}-c-expanding`} moveWidth={530}>
      <div
        className={`scroll-container-inner ${cssGlobalNS}-c-expanding--inner no-scrollbars`}
        ref={scrollContainerRef}
      >
        {refLoaded && expandingGridSize && (
          <ExpandingSection
            collectionId={collectionId}
            bannerSize={bannerSize}
            bannerImageSize={bannerImageSize}
            expandingGridSize={expandingGridSize}
            categoryId={categoryId}
            categoryLayoutTypes={categoryLayout}
          />
        )}

        <GenericLayoutWrapper
          layoutGrid={GridLayouts.LAYOUT_1}
          subType={subType}
          rows={GridRowType.TWO_ROWS}
          forwardRef={setRefs}
        >
          {sliderRender()}
        </GenericLayoutWrapper>
      </div>
    </SliderGridWrapper>
  );
};

export default memo(NestedGridSlider);
