import React from 'react';
import { Flex } from 'components/shared/layout';
import { cssGamificationNS } from 'utils/common/types/css.types';
type Props = {
  tag?: string;
  tagBg?: string;
};

export const TagType: React.FC<Props> = ({ tag, tagBg }) => {
  if (!tag) return <></>;
  return (
    <div className={`${cssGamificationNS}-tag  ${cssGamificationNS}-tag__type`}>
      <Flex.AC className={`${cssGamificationNS}-tag__type__wrapper`} style={{ background: tagBg }}>
        {tag}
      </Flex.AC>
    </div>
  );
};
