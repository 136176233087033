import { useEffect, useMemo } from 'react';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import useMyBetsRoute from 'hooks/common/useMyBetsRoute';
import { setTopNavHeaderStatus, selectAppHeader } from 'shared/common/features/appHeader';
import { selectBottomTabBar } from 'shared/common/features/bottomTabBar/slice/bottomTabBar.slice';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { Breakpoints } from 'theme/Theme';

export const useSetTopNavHeaderStatus = (): boolean => {
  const dispatch = useAppDispatch();
  const isMyBetsVisible = useAppSelector(selectBottomTabBar.isMyBetsVisible);
  const isBetSlipExpanded = useAppSelector(selectBottomTabBar.isBetSlipExpanded);
  const isSingleBetSelection = useAppSelector(selectAppHeader.isSingleBetSelection);
  const isMyAccountModalShowing = useAppSelector(selectMyProfile.isModalShowing);
  const isMyAccount = location.pathname.indexOf('my-account') > -1;
  const { collapseInMyBets } = useMyBetsRoute(location.pathname);

  const isMobileQuery = useMediaQuery({ query: `(max-width: ${Breakpoints.isMobile}px)` });
  const isTabletQuery = useMediaQuery({ query: `(max-width: ${Breakpoints.isTabletOrMobile}px)` });
  const getDeviceType = useMemo(() => {
    if (isMobileOnly || isMobileQuery) {
      return 'mobile';
    } else if (isTablet || isTabletQuery) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }, [isMobileQuery, isTabletQuery]);

  const navigationConfig = useLayoutConfigType({ moduleLayout: 'platform', innerLayout: LayoutConfigTypes.NAVIGATION });
  const isFixed =
    navigationConfig?.topNav &&
    (isMyAccountModalShowing ||
      isMyAccount ||
      collapseInMyBets ||
      (getDeviceType !== 'desktop' && (isMyBetsVisible || (isBetSlipExpanded && !isSingleBetSelection))));
  const hideAppBanner =
    isMyAccountModalShowing ||
    isMyAccount ||
    collapseInMyBets ||
    (getDeviceType !== 'desktop' && (isMyBetsVisible || (isBetSlipExpanded && !isSingleBetSelection)));

  useEffect(() => {
    if (!navigationConfig?.topNavInitialCollapsed) {
      if (isFixed) {
        dispatch(setTopNavHeaderStatus('fixed-collapsed'));
      } else {
        dispatch(setTopNavHeaderStatus('visible'));
      }
    } else {
      if (hideAppBanner) {
        dispatch(setTopNavHeaderStatus('collapsed'));
      }
    }
  }, [dispatch, isFixed]);
  return hideAppBanner;
};
