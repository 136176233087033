import React from 'react';
import { useGridLayoutConfig } from 'modules/casino/modules/container/cellsContainer/grid/components/gridCell/hooks/useGridLayoutConfig';
import layoutCellLoader from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/LayoutComponentLoader';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';

export interface LayoutProps {
  gameUniqueId: string;
  categoryId: string;
  categoryLayout: CategoryLayoutTypes;
  gameCellRef: React.MutableRefObject<HTMLDivElement | null>;
  hasRestrictions: boolean;
  finishedLoading: () => void;
  handleButtonClick: (e, launchType) => void;
  skipLazyLoad: boolean;
  number?: number;
}

const Layout_Wrapper: React.FC<LayoutProps> = (props: LayoutProps) => {
  const { layoutConfig } = useGetCategoryParams(props.categoryId, props.categoryLayout);
  const { cellLayout } = useGridLayoutConfig(layoutConfig.layoutGrid);
  const Cell = layoutCellLoader(cellLayout);

  return <Cell {...props} />;
};

export default Layout_Wrapper;
