import React from 'react';

const DragHand = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="70" viewBox="0 0 60 70">
      <g id="drag" transform="translate(-32.008 0)">
        <g id="Group_6674" data-name="Group 6674" transform="translate(47.006 3.043)">
          <g id="Group_6673" data-name="Group 6673">
            <path
              id="Path_18874"
              data-name="Path 18874"
              d="M150.659,21.333A12.129,12.129,0,0,0,140.21,39.4a1.491,1.491,0,0,0,2.043.59,1.531,1.531,0,0,0,.579-2.07A9.026,9.026,0,1,1,159.3,35.9a1.529,1.529,0,0,0,1.053,1.869A1.479,1.479,0,0,0,162.2,36.7a12.056,12.056,0,0,0-11.538-15.364Z"
              transform="translate(-138.659 -21.333)"
              fill="#fff"
            />
          </g>
        </g>
        <g id="Group_6676" data-name="Group 6676" transform="translate(41.009 27.391) rotate(180)">
          <g id="Group_6675" data-name="Group 6675" transform="translate(0)">
            <path
              id="Path_18875"
              data-name="Path 18875"
              d="M1.5,0A1.512,1.512,0,0,0,0,1.522V25.87a1.512,1.512,0,0,0,1.5,1.522A1.512,1.512,0,0,0,3,25.87V1.522A1.512,1.512,0,0,0,1.5,0Z"
              fill="#fff"
            />
          </g>
        </g>
        <g id="Group_6678" data-name="Group 6678" transform="translate(47.007 27.389) rotate(180)">
          <g id="Group_6677" data-name="Group 6677" transform="translate(0)">
            <path
              id="Path_18876"
              data-name="Path 18876"
              d="M14.56,6.532,8.56.445a1.486,1.486,0,0,0-2.121,0l-6,6.087a1.538,1.538,0,0,0,0,2.152,1.486,1.486,0,0,0,2.121,0L7.5,3.671l4.938,5.013a1.491,1.491,0,0,0,2.124,0A1.539,1.539,0,0,0,14.56,6.532Z"
              fill="#fff"
            />
          </g>
        </g>
        <g id="Group_6680" data-name="Group 6680" transform="translate(36.249 9.13)">
          <g id="Group_6679" data-name="Group 6679" transform="translate(0)">
            <path
              id="Path_18877"
              data-name="Path 18877"
              d="M111.922,85.3a5.92,5.92,0,0,0-3.336,1.029,6.022,6.022,0,0,0-5.664-4.072,5.92,5.92,0,0,0-3.336,1.029,6.022,6.022,0,0,0-5.664-4.072,5.887,5.887,0,0,0-3,.819V70.087a6,6,0,1,0-12,0v25.87l-5.214-3.966a7.785,7.785,0,0,0-10.227.737,4.615,4.615,0,0,0,0,6.455l21.364,21.676a13.322,13.322,0,0,0,9.549,4.011h7.029a16.641,16.641,0,0,0,16.5-16.739V91.391A6.05,6.05,0,0,0,111.922,85.3Zm3,22.826a13.616,13.616,0,0,1-13.5,13.7H94.392a10.359,10.359,0,0,1-7.428-3.12L65.6,97.031a1.538,1.538,0,0,1,0-2.152,4.8,4.8,0,0,1,6.309-.454l7.611,5.792a1.48,1.48,0,0,0,1.572.143A1.527,1.527,0,0,0,81.921,99V70.087a3,3,0,1,1,6,0V92.913a1.5,1.5,0,1,0,3,0V85.3a3,3,0,1,1,6,0v7.609a1.5,1.5,0,1,0,3,0V88.348a3,3,0,1,1,6,0v4.565a1.5,1.5,0,1,0,3,0V91.391a3,3,0,1,1,6,0Z"
              transform="translate(-62.163 -64)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

const HoldHand = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="55.759" height="66.957" viewBox="0 0 55.759 66.957">
      <g id="drag" transform="translate(-36.249 -3.043)">
        <g id="Group_6674" data-name="Group 6674" transform="translate(47.006 3.043)">
          <g id="Group_6673" data-name="Group 6673">
            <path
              id="Path_18874"
              data-name="Path 18874"
              d="M150.659,21.333A12.129,12.129,0,0,0,140.21,39.4a1.491,1.491,0,0,0,2.043.59,1.531,1.531,0,0,0,.579-2.07A9.026,9.026,0,1,1,159.3,35.9a1.529,1.529,0,0,0,1.053,1.869A1.479,1.479,0,0,0,162.2,36.7a12.056,12.056,0,0,0-11.538-15.364Z"
              transform="translate(-138.659 -21.333)"
              fill="#fff"
            />
          </g>
        </g>
        <g id="Group_6680" data-name="Group 6680" transform="translate(36.249 9.13)">
          <g id="Group_6679" data-name="Group 6679" transform="translate(0)">
            <path
              id="Path_18877"
              data-name="Path 18877"
              d="M111.922,85.3a5.92,5.92,0,0,0-3.336,1.029,6.022,6.022,0,0,0-5.664-4.072,5.92,5.92,0,0,0-3.336,1.029,6.022,6.022,0,0,0-5.664-4.072,5.887,5.887,0,0,0-3,.819V70.087a6,6,0,1,0-12,0v25.87l-5.214-3.966a7.785,7.785,0,0,0-10.227.737,4.615,4.615,0,0,0,0,6.455l21.364,21.676a13.322,13.322,0,0,0,9.549,4.011h7.029a16.641,16.641,0,0,0,16.5-16.739V91.391A6.05,6.05,0,0,0,111.922,85.3Zm3,22.826a13.616,13.616,0,0,1-13.5,13.7H94.392a10.359,10.359,0,0,1-7.428-3.12L65.6,97.031a1.538,1.538,0,0,1,0-2.152,4.8,4.8,0,0,1,6.309-.454l7.611,5.792a1.48,1.48,0,0,0,1.572.143A1.527,1.527,0,0,0,81.921,99V70.087a3,3,0,1,1,6,0V92.913a1.5,1.5,0,1,0,3,0V85.3a3,3,0,1,1,6,0v7.609a1.5,1.5,0,1,0,3,0V88.348a3,3,0,1,1,6,0v4.565a1.5,1.5,0,1,0,3,0V91.391a3,3,0,1,1,6,0Z"
              transform="translate(-62.163 -64)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const Hands = {
  Drag: DragHand,
  Hold: HoldHand,
};
