import React, { memo, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { cdnConfig } from 'modules/casino/shared/utils/common/cdnConfig';
import config from 'utils/config';
import { cssGlobalNS } from '../../types/css.types';

// Component CSS NameSpace
const cssNS = `${cssGlobalNS}-spico`;

const sportIconTypesClassNameMap = {
  slider: `${cssNS}--slider`,
  sliderTitle: `${cssNS}--slider-title`,
  drawer: `${cssNS}--drawer`,
  tab: `${cssNS}--tab`,
  contentHeader: `${cssNS}--content-header`,
  sectionHeader: `${cssNS}--section-header`,
  containerHeader: `${cssNS}--container-header`,
  eSportsSectionHeader: `${cssNS}--esports-header`,
  betslip: `${cssNS}--betslip`,
} as const;

type SportIconTypes = keyof typeof sportIconTypesClassNameMap;

interface Props {
  icon: number | string | undefined;
  iconPath?: string;
  isActive?: boolean;
  type: SportIconTypes;
}

const CDNIcon: React.FC<Props> = ({ icon, iconPath = config.CDN_URL + cdnConfig.SPORT_ICONS, isActive, type }) => {
  // TODO: Get the Sport Icon type from config
  const fileExt = config.CDN_URL === `https://ibbgcdn.kubdev.com` ? '.png' : '.svg';

  const imgSources = useMemo(
    () => [`${iconPath + '/' + icon + fileExt}`, `${iconPath + '/' + icon + '.svg'}`],
    [iconPath, icon, fileExt],
  );

  const [srcState, setSrcState] = useState({ index: 0, src: imgSources[0] });

  useEffect(() => {
    setSrcState({ index: 0, src: imgSources[0] });
  }, [icon, imgSources]);

  const onError = () => {
    const newIndex = srcState.index + 1;
    if (imgSources.length > newIndex) {
      const newSourceState = { index: newIndex, src: imgSources[newIndex] };
      setSrcState(newSourceState);
    }
  };

  const imgClass = clsx(cssNS, isActive && `${cssNS}--active`, sportIconTypesClassNameMap[type]);

  return <img className={imgClass} src={srcState.src} onError={onError} />;
};

export default memo(CDNIcon);
