import { useEffect } from 'react';
import {
  favoriteGamesSelectors,
  toggleGameInFavouriteGames,
} from 'modules/casino/modules/container/cellsContainer/cellls/additionalGames/favourites/slice/casinoFavouriteGamesSlice';
import { FAV_BTN_NONE_STYLE_EXCEPTION_BY_SUBTYPES } from 'modules/casino/modules/container/cellsContainer/cellls/constants/casinoGamesConstants';
import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import { useGridLayoutSettings } from 'modules/casino/shared/components/gridLayoutSettings/hooks/useGridLayoutSettings';
import { GameType } from 'modules/casino/shared/constants';
import { LOGIN_FORM_TYPE_MODAL } from 'modules/casino/shared/utils/common/helpersCommon';
import { selectAuthentication, showQuickLoginForm } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { RootState } from 'store/rootReducer';

type useFavouritesBtnProps = {
  gameUniqueId: string;
  categoryId: string;
  gameCellRef: React.MutableRefObject<HTMLDivElement | null>;
  show?: boolean;
  callback?: () => void;
};

type useFavouritesBtnReturnTypes = {
  inFavourite: boolean;
  showFavBtn: boolean;
  onClickHandler: (event: React.MouseEvent<HTMLElement>) => void;
};

export const useFavouritesBtn = ({
  gameUniqueId,
  categoryId,
  gameCellRef,
  show,
  callback,
}: useFavouritesBtnProps): useFavouritesBtnReturnTypes => {
  const game = useGameProps({ gameUniqueId, categoryId });
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const inFavourite = useAppSelector((state: RootState) => favoriteGamesSelectors.isFavourite(state, game.id));
  const { showGameCellFavButton } = useGridLayoutSettings();
  const onClickHandler = async (event) => {
    event.preventDefault();

    if (!isAuthenticated) {
      callback && callback();
      dispatch(
        showQuickLoginForm({
          status: true,
          loginActionType: LOGIN_FORM_TYPE_MODAL,
        }),
      );
    } else {
      if (window.openWindows && Array.isArray(window.openWindows)) {
        const favUpdateEvent = new CustomEvent('favouriteGamesUpdateFromParent', {
          detail: { inFavourite: !inFavourite, gameId: game?.id },
        });
        window.openWindows.forEach((windowObj) => {
          //Sending a post message to all opened windows to identify a change in favourite games.
          windowObj.dispatchEvent(favUpdateEvent);
        });
      }
      dispatch(toggleGameInFavouriteGames(game, !inFavourite));
    }
  };

  useEffect(() => {
    const lastElementFromPath = window.location.pathname.split('/').reverse()[0];
    if (lastElementFromPath === 'favourites' && !inFavourite && gameCellRef.current) {
      //Removes (hide) games from a favourites category when star is being pressed there
      //Display none style is used because removing the actual game from the state causes bug with image caching
      if (!FAV_BTN_NONE_STYLE_EXCEPTION_BY_SUBTYPES.includes(game.subType)) {
        gameCellRef.current.style.display = 'none';
      }
    }
  }, [inFavourite]);

  const showIconForSvaraGames =
    game.gameType !== GameType.SVARA || (game.gameType === GameType.SVARA && game.refGameId === 'svara');

  return {
    inFavourite,
    onClickHandler,
    showFavBtn: showIconForSvaraGames && (show || showGameCellFavButton),
  };
};
