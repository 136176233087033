import React, { memo } from 'react';
import { Icon } from 'components/shared';
import { Flex } from 'components/shared/layout';

/* Reg Help Menu */
interface MenuProps {
  children: React.ReactNode;
  text: string;
}

const Menu: React.FC<MenuProps> = ({ children, text }) => {
  return (
    <Flex.Col className="egtd-dropdown-content reg-help__menu">
      <div className="reg-help__header">{text}?</div>
      {children}
    </Flex.Col>
  );
};

/* Reg Help Menu Item */
interface ItemProps {
  icon: string;
  text: string;
  href: string;
  type?: 'external';
}

const Item: React.FC<ItemProps> = ({ icon, text, href: link, type }) => {
  const target = type === 'external' ? '_blank' : '_self';

  return (
    <a className="d-flex-ac reg-help__item" href={link} target={target} rel="noreferrer">
      <Icon className="reg-help__item-icon" icon={icon} />
      {text}
    </a>
  );
};

export const HelpDropdown = {
  Menu,
  Item: memo(Item),
};
