import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { CashierTranslations } from 'modules/casino/shared/utils/translationUtils/translationStrings';

import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { clearModalsQueue } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch, useAppSelector } from 'store';

import { isEmpty } from 'utils/common/helpersCommon';
import { useReprintSelectors } from './hook/useReprintSelectors';
import { useTransactionHistoryCashierState } from './hook/useTransactionHistoryCashierState';
import ReprintContentInfo from './ReprintContentInfo';
import ReprintFiltersControls from './ReprintFiltersControls';
import { CashierModalUI } from '../../cashierModalUI/Index';
import { useCashierModalOpenRequest } from '../../hooks/useCashierModalOpenRequest';
import { useCashierModalPrompt } from '../../hooks/useCashierModalPrompt';
import { selectCashierData } from '../../slice/cashier.slice';
import { clearRePrintData } from '../../utils';
import DisplayErrorModal from '../cashier-checkTicket/DisplayErrorModal';

const ReprintTicketModal = () => {
  const [disabledButtons, setDisabledButtons] = useState({});
  const isPrintingEject = useAppSelector(selectCashierData.isPrintingEject);
  const { t } = useTranslation();
  const { closeModal } = useHandleModals();
  const dispatch = useAppDispatch();
  const isCashierModalPrompted = useCashierModalPrompt();

  useCashierModalOpenRequest(isCashierModalPrompted, dispatch, closeModal, clearRePrintData);

  const {
    ticketHistoryDataStatus,
    ticketHistoryData,
    terminalData,
    ticketOperationData,
    reprintInfoStatus,
    reprintCheckTicketStatus,
    ticketOperationStatus,
    terminalDataStatus,
  } = useReprintSelectors();
  const isLoading = ticketHistoryDataStatus === 'pending';
  const isError =
    reprintInfoStatus === 'failure' || ticketHistoryDataStatus === 'failure' || reprintCheckTicketStatus === 'failure';
  const { state, methods } = useTransactionHistoryCashierState();
  if (ticketOperationStatus === 'pending' || terminalDataStatus === 'pending') {
    return;
  }
  if (isEmpty(ticketOperationData) && ticketOperationStatus !== 'failure') {
    return null;
  }

  const handleRePrintModalClosing = () => {
    if (isPrintingEject) {
      return;
    }
    dispatch(clearModalsQueue());
    clearRePrintData(dispatch);
  };

  const reprintContentClass = clsx(
    isError || (isEmpty(ticketHistoryData) && 'hasBG'),
    'cashier-modal__reprint-ticket-content',
  );

  return (
    <>
      <CashierModalUI.Wrapper isVisible={true}>
        <CashierModalUI.Body className="reprint-modal__body">
          <CashierModalUI.Header clearData={handleRePrintModalClosing} />

          <CashierModalUI.Content className={reprintContentClass}>
            {isError && <DisplayErrorModal />}
            {!isEmpty(ticketHistoryData) ? (
              <ReprintContentInfo
                ticketHistoryData={ticketHistoryData}
                state={state}
                methods={methods}
                t={t}
                disabledButtons={disabledButtons}
                setDisabledButtons={setDisabledButtons}
              />
            ) : (
              <CashierModalUI.InfoMessage
                className="m-auto"
                icon="calendar-outline"
                infoMessage={isLoading ? '' : t(CashierTranslations.FinancialModal.messageText)}
                isLoading={isLoading}
              />
            )}
          </CashierModalUI.Content>

          <CashierModalUI.NavCol>
            <ReprintFiltersControls
              activeOperations={ticketOperationData}
              activeTerminal={terminalData}
              state={state}
              methods={methods}
            />
          </CashierModalUI.NavCol>
        </CashierModalUI.Body>
      </CashierModalUI.Wrapper>
    </>
  );
};
export default ReprintTicketModal;
