export const generateSeats = ({ seatsTaken, seats }) => {
  const players: boolean[] = [];

  if (typeof seatsTaken === 'number') {
    return players;
  }

  for (let i = 0; i < seats; i++) {
    seatsTaken.indexOf(i) > -1 ? (players[i] = true) : (players[i] = false);
  }

  return players;
};
