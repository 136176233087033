import { useEffect } from 'react';
import { useGeolocationCheck } from 'hooks/common/useGeolocationCheck';
import { selectRegistration } from 'pages/auth/registration/slice/registration.slice';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { useAppDispatch, useAppSelector } from 'store';

export const useGeolocationRestricted = (error?: string, closeQuickLoginForm?: () => void) => {
  const showLogoutModal = error === 'geolocation_restricted' || error === 'Player ip geolocation is restricted!';
  const isIpGeolocationTrackingEnabled = useAppSelector(selectGeneral.isIpGeolocationTrackingEnabled);
  const isSubmitting = useAppSelector(selectRegistration.isSubmitting);
  const dispatch = useAppDispatch();
  const { onVPNDetection } = useGeolocationCheck();

  useEffect(() => {
    if (isIpGeolocationTrackingEnabled && showLogoutModal) {
      closeQuickLoginForm && closeQuickLoginForm();
      onVPNDetection();
    }
  }, [showLogoutModal, dispatch, closeQuickLoginForm, onVPNDetection, isIpGeolocationTrackingEnabled, isSubmitting]);
};
