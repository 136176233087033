import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InGameMostPlayedCategory from 'modules/casino/modules/container/cellsContainer/containers/external/InGameCategories/InGameMostPlayedCategory';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { TopBarButton } from '../TopBarButton';
import { TopBarPopover } from '../TopBarPopover';

type Props = {
  type: string;
  text?: string;
  icon?: string;
  isFreePlay: boolean;
};

export const LastPlayedGamesButton = ({ isFreePlay, type, text, icon }: Props) => {
  const { t } = useTranslation();
  const [showCat, setShowCat] = useState(false);

  const handleClick = () => {
    setShowCat(true);
  };

  return (
    <TopBarButton
      type={type}
      handleClick={handleClick}
      icon={icon}
      text={text && t(text)}
      restrictForFreePlay={isFreePlay}
      renderChildren={(btnRef, handleClose, show) =>
        showCat &&
        show && (
          <TopBarPopover handleClose={handleClose} parentRef={btnRef} show={show}>
            <div className={`${cssGlobalNS}-gl__popover__search--title`}>{t('GameLaunch.LastPlayed.title')}</div>
            <InGameMostPlayedCategory />
          </TopBarPopover>
        )
      }
    />
  );
};
