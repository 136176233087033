import { useAppSelector } from 'store';
import { selectMyProfile } from '../../myProfile/slice/myProfile.slice';
import { MFA_Channels } from '../../myProfile/types/myProfile.types';

export const useMFAType = (): { mfaChannel: MFA_Channels | null; channelText?: string } => {
  const userEmail = useAppSelector(selectMyProfile.email);
  const userPhone = useAppSelector(selectMyProfile.phoneNumber);
  const mfaChannel = useAppSelector(selectMyProfile.mfaChannel);

  const channels = {
    email: userEmail,
    sms: userPhone,
    phoneCall: userPhone,
  };
  const channelText = channels[mfaChannel ?? 'email'];
  return { mfaChannel: mfaChannel ?? 'email', channelText };
};
