import config from 'utils/config';

export const cdnUrl = config.CDN_URL;

export enum cdnPaths {
  BRAND = '/common-content/brand/',
  GENERAL = '/common-content/general-ui/',
  HELP = '/common-content/help/icons/',
  PRIZE_DROP = '/common-content/prize-drop/',
  PROMOTIONS = '/common-content/promotion-icons/',
  RETAIL = '/retail-content/ui-buttons/',
  IN_GAME_BAR = '/common-content/bottom-bar/',
}
