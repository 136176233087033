import { LayoutConfigInnerLayoutTypes } from '../../../../../../shared/constants';
import { useCasinoLayoutConfig } from '../../../../../../shared/hooks/useCasinoLayoutConfig';
import { WidgetTemplates } from '../../../types/casinoWidgetsTypes';

export const useHideLastWinners = ({ layoutGrid, type }) => {
  const casinoLayoutConfig = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.CASINO_LAYOUT_CONFIG });
  const upperCaseLayout = layoutGrid.toUpperCase();
  const hideLastWinnersConfig =
    upperCaseLayout &&
    casinoLayoutConfig?.hideLastWinners &&
    casinoLayoutConfig?.hideLastWinners[upperCaseLayout] !== undefined
      ? casinoLayoutConfig.hideLastWinners[upperCaseLayout]
      : undefined;

  return {
    hideLastWinnersConfig: hideLastWinnersConfig ? hideLastWinnersConfig[type] : type === WidgetTemplates.REGULAR,
  };
};
