import useActiveNotification from 'shared/common/features/inGameNotifications/hooks/useActiveNotification';
import { JackpotPopupType } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/types/wsNotifications.types';

const useActiveJackpotNotification = () => {
  const activeNotification = useActiveNotification();

  if (activeNotification?.Style.type === JackpotPopupType) return activeNotification;
};

export default useActiveJackpotNotification;
