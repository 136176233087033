import { Suspense } from 'react';
import cell01ComponentLoader from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell01/Cell01ComponentLoader';
import cell01StyleLoader from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell01/Cell01StyleLoader';
import { LayoutTypes } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/LayoutTypes';
import {
  ComponentNames,
  ComponentType,
  StyledNames,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/layoutWrapperConstants';
import { SharedComponents } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared';
import { useShowGameTitle } from '../../../../../shared/hooks/useShowGameTitle';

const GameCellWrapper: React.FC<LayoutTypes.GameCellWrapperProps> = ({
  gameUniqueId,
  categoryId,
  layoutGrid,
  subType,
}) => {
  const Description = cell01ComponentLoader(ComponentNames.Description, ComponentType.dataAlternative);
  const GameCellWrapperStyled = cell01StyleLoader(StyledNames.GameCellWrapperStyled, ComponentType.data);
  const { showGameTitle } = useShowGameTitle({ layoutGrid, subType });
  return (
    <GameCellWrapperStyled className={'casino-game-cell casino-game-cell--data-alternative'}>
      <Suspense>
        {showGameTitle && <Description gameUniqueId={gameUniqueId} categoryId={categoryId} />}
        <SharedComponents.GameCellTags
          gameUniqueId={gameUniqueId}
          categoryId={categoryId}
          className={`cell-tag-bottom--mixin cell-tag-bottom--layout_1`}
        >
          <SharedComponents.GameCellTableLimits gameUniqueId={gameUniqueId} categoryId={categoryId} />
        </SharedComponents.GameCellTags>
      </Suspense>
    </GameCellWrapperStyled>
  );
};

export default GameCellWrapper;
GameCellWrapper.displayName = 'GameCellWrapper';
