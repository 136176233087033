import { casinoNavigationSelectors } from 'modules/casino/modules/navigation/shared/slice/casinoNavigationSlice';
import { useAppSelector } from 'store';

type CasinoType = {
  casinoType: string;
  casinoRoute: string;
};

const useGeneralCasinoType = (): CasinoType => {
  const activeCasinoVertical = useAppSelector(casinoNavigationSelectors.activeCasinoVertical);
  const { casinoType, casinoRoute } = activeCasinoVertical;

  return { casinoType, casinoRoute };
};

export default useGeneralCasinoType;
