import { Item } from './Item';
import { ItemRoulette } from './ItemRoulette';
import { ItemSicBoDeluxe } from './ItemSicBoDeluxe';
import {
  GameCellDataCharactersTypes,
  FootballStudioSymbol,
  Baccarat,
  Roulette,
  Monopoly,
  SpinAWin,
  DreamCatcher,
  FootballStudioColors,
} from '../gameCellTableCharactersConstants';
import { symbolFunc } from '../GameCellTableCharactersUtils';

export const ItemComponents = {
  [GameCellDataCharactersTypes.Roulette]: ItemRoulette,
  [GameCellDataCharactersTypes.Cards]: ItemRoulette,
  [GameCellDataCharactersTypes.Baccarat]: Item,
  [GameCellDataCharactersTypes.Icons]: Item,
  [GameCellDataCharactersTypes.SicBo]: Item,
  [GameCellDataCharactersTypes.SuperSicBo]: Item,
  [GameCellDataCharactersTypes.Monopoly]: Item,
  [GameCellDataCharactersTypes.Football]: Item,
  [GameCellDataCharactersTypes.LightningDice]: Item,
  [GameCellDataCharactersTypes.DreamCatcher]: Item,
  [GameCellDataCharactersTypes.CrazyTime]: Item,
  [GameCellDataCharactersTypes.MegaBall]: Item,
  [GameCellDataCharactersTypes.MegaWheel]: Item,
  [GameCellDataCharactersTypes.DragonTiger]: Item,
  [GameCellDataCharactersTypes.SicBoDeluxe]: ItemSicBoDeluxe,
  [GameCellDataCharactersTypes.SpinAWin]: Item,
};

export const loadTypeSymbol = {
  [GameCellDataCharactersTypes.CrazyTime]: (symbol) => symbolFunc.crazyTime(symbol),
  [GameCellDataCharactersTypes.Cards]: (symbol) => symbolFunc.Cards(symbol),
  [GameCellDataCharactersTypes.Icons]: (symbol) => symbolFunc.Icons(symbol),
  [GameCellDataCharactersTypes.Monopoly]: (symbol) => symbolFunc.monopoly(symbol),
  [GameCellDataCharactersTypes.SpinAWin]: (symbol) => symbolFunc.monopoly(symbol),
  [GameCellDataCharactersTypes.DreamCatcher]: (symbol) => symbolFunc.dreamCatcher(symbol),
  [GameCellDataCharactersTypes.Baccarat]: (symbol) => symbol,
  [GameCellDataCharactersTypes.MegaWheel]: (symbol) => (symbol?.multiplier ? `${symbol?.multiplier}x` : ''),
  [GameCellDataCharactersTypes.MegaBall]: (symbol) => symbol[0]?.multiplier + 'x',
  [GameCellDataCharactersTypes.SuperSicBo]: (symbol) => symbolFunc.dices(symbol),
  [GameCellDataCharactersTypes.LightningDice]: (symbol) => symbolFunc.dices(symbol),
  [GameCellDataCharactersTypes.Roulette]: (symbol) => symbolFunc.rouletteConvertSymbol(symbol),
  [GameCellDataCharactersTypes.Football]: (symbol) => FootballStudioSymbol[symbol],
  [GameCellDataCharactersTypes.SicBoDeluxe]: (symbol) => symbol.value && symbolFunc.dices(symbol),
};

export const loadColorSymbol = {
  [GameCellDataCharactersTypes.Baccarat]: (symbol) => Baccarat[symbol],
  [GameCellDataCharactersTypes.Roulette]: (symbol) => Roulette[symbol],
  [GameCellDataCharactersTypes.SuperSicBo]: (symbol) => symbolFunc.superSicBoColors(symbol),
  [GameCellDataCharactersTypes.Monopoly]: (symbol) => Monopoly[symbol],
  [GameCellDataCharactersTypes.SpinAWin]: (symbol) => SpinAWin[symbol],
  [GameCellDataCharactersTypes.DreamCatcher]: (symbol) => DreamCatcher[symbol],
  [GameCellDataCharactersTypes.Football]: (symbol) => FootballStudioColors[symbol],
  [GameCellDataCharactersTypes.Cards]: (symbol) => symbolFunc.CardsColors(symbol),
  [GameCellDataCharactersTypes.SicBoDeluxe]: (symbol) => symbolFunc.superSicBoColors(symbol),
};

export const loadBackgroundSymbol = {
  [GameCellDataCharactersTypes.Roulette]: (symbol) => symbolFunc.rouletteBgSymbol(symbol),
};

export const loadTypeClass = {
  [GameCellDataCharactersTypes.CrazyTime]: 'roulette-style',
  [GameCellDataCharactersTypes.Icons]: 'roulette-style',
  [GameCellDataCharactersTypes.Cards]: 'roulette-style cards-style',
  [GameCellDataCharactersTypes.Monopoly]: 'monopoly',
  [GameCellDataCharactersTypes.SpinAWin]: 'monopoly',
  [GameCellDataCharactersTypes.DreamCatcher]: 'monopoly',
  [GameCellDataCharactersTypes.Baccarat]: 'baccarat',
  [GameCellDataCharactersTypes.MegaBall]: 'roulette-style',
  [GameCellDataCharactersTypes.MegaWheel]: 'roulette-style',
  [GameCellDataCharactersTypes.SuperSicBo]: 'roulette-style',
  [GameCellDataCharactersTypes.LightningDice]: 'roulette-style',
  [GameCellDataCharactersTypes.Roulette]: 'roulette-style',
  [GameCellDataCharactersTypes.Football]: 'football',
  [GameCellDataCharactersTypes.SicBoDeluxe]: 'roulette-style',
  [GameCellDataCharactersTypes.Svara]: 'svara-style',
};
