import React, { useContext } from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';
import { useAppDispatch } from 'store';
import DropdownContext from './DropdownContext';
import { setDropdownBackdrop } from './slice/dropdownBackdrop.slice';
import SportIconCDN from '../icons/SportIconCDN';
import { UI } from '../UI';

interface Props {
  title: string | JSX.Element;
  isActive: boolean;
  isDisabled?: boolean;
  handleChangeDropdown: (itemVal) => void;
  className?: string;
  defaultClassName?: boolean;
  value?: string | number;
  dataQid?: string | number;
  sportIcon?: string;
  flag?: string;
}

const DropdownItem: React.FC<Props> = ({
  className,
  defaultClassName = true,
  handleChangeDropdown,
  title,
  value,
  isActive,
  isDisabled,
  dataQid,
  sportIcon,
  flag,
}) => {
  const dispatch = useAppDispatch();
  const dropdownContext = useContext<{ onClickHandler?: () => void }>(DropdownContext);
  const handleSelectValue = () => {
    value !== null && value !== undefined && handleChangeDropdown(value);
    if (dropdownContext?.onClickHandler) {
      dropdownContext.onClickHandler();
    }
    dispatch(setDropdownBackdrop(false));
  };

  const itemClass = clsx(
    defaultClassName && 'egtd-dropdown-item',
    className,
    isActive && 'egtd-dropdown-item--active',
    isDisabled && 'egtd-dropdown-item--disabled',
    !defaultClassName && isActive && `${className}--active`,
  );

  return (
    <Flex.AC className={itemClass} onClick={!isDisabled ? handleSelectValue : undefined} data-qid={dataQid}>
      {flag && <UI.Flag size={16} flag={flag} isCircle />}
      {sportIcon && <SportIconCDN type={'dropDownGroup'} icon={sportIcon} />}
      {title}
    </Flex.AC>
  );
};

export default DropdownItem;
