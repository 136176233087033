import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useCasinoWidgetConfig from 'modules/casino/platformImplementation/hooks/useCasinoWidgetConfig';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { selectIntegrations } from 'shared/common/features/integrations/slice/integrations.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { useTournaments } from './hooks/useTournaments';
import { fetchTournaments } from './slice/tournamentsButton.slice';
import TournamentsPopover from './TournamentsPopover';
import { casinoLaunchSelectors, launchGameThunk } from '../../../gameLaunch/casinoLaunchSlice';
import { TopBarButton } from '../TopBarButton';
import { TopBarPopover } from '../TopBarPopover';

type Props = {
  isFreePlay: boolean;
  type: string;
  gameId: string;
  text?: string;
  icon?: string;
};

export const TournamentsButton = ({ isFreePlay, type, gameId, text, icon }: Props) => {
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const casinoWidgetConfig = useCasinoWidgetConfig();

  const platformGameData = useAppSelector(casinoLaunchSelectors.getGameData)?.data[0];
  const widgetGameData = useAppSelector(selectIntegrations.casinoWidgetGameData);
  const gameData = casinoWidgetConfig ? widgetGameData : platformGameData;
  const { data } = useTournaments({
    isFreePlay,
    isAuthenticated,
    gameId: gameData?.refGameId,
    gameType: gameData?.gameType,
    providerId: gameData?.providerId,
    product: gameData?.betType,
  });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!casinoWidgetConfig && gameId && gameData?.id !== gameId) {
      dispatch(launchGameThunk.fetchGameData({ gameId }));
    }
  }, [gameId, gameData?.id, dispatch]);

  return (
    <TopBarButton
      type={type}
      icon={icon}
      text={text && t(text)}
      restrictForFreePlay={isFreePlay}
      handleClick={() =>
        !isFreePlay &&
        isAuthenticated &&
        gameData &&
        dispatch(
          fetchTournaments({
            gameId: gameData.refGameId!,
            gameType: gameData.gameType,
            providerId: gameData.providerId,
            product: gameData.betType,
          }),
        )
      }
      renderChildren={(btnRef, handleClose, show) => (
        <TopBarPopover handleClose={handleClose} parentRef={btnRef} show={show}>
          <TournamentsPopover data={data} />
        </TopBarPopover>
      )}
    />
  );
};
