import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UI } from 'components/shared/UI';
import { CashierModalUI } from 'modules/retail/modules/cashier/cashierModalUI/Index';
import { setIsPrintingEjected } from 'modules/retail/modules/cashier/slice/cashier.slice';
import CashierKeyBoard from 'modules/retail/modules/ssbt/components/terminalKeyboard/CashierKeyBoard';
import { useAppDispatch } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';
import { useWithdraw } from './hooks/useWithdraw';
import { cashPayThunks, clearWithdraw } from '../../slice/cashPay.slice';

const WithdrawForm = () => {
  const [withdrawId, setWithdrawId] = useState<string>('');
  const { verifyWithdraw, verifyWithdrawStatus, withdrawComplete, withdrawStatus } = useWithdraw();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const hasVerifyWithdrawId = verifyWithdraw?.success && verifyWithdrawStatus === 'succeeded';
  const hasInputValues = !isEmpty(withdrawId);

  useEffect(() => {
    if (withdrawComplete?.success && verifyWithdraw?.success) {
      setWithdrawId('');
    }
  }, [withdrawComplete]);

  const handleUpdateInput = ({ type, value }: { type: 'withdrawId'; value: string }) => {
    if (hasVerifyWithdrawId) return;

    if (type === 'withdrawId') {
      setWithdrawId(value);
    }
  };

  const handleSearch = () => {
    if (verifyWithdrawStatus === 'pending') return;
    dispatch(
      cashPayThunks.verifyWithdrawId({
        id: withdrawId,
      }),
    );
  };

  const handleWithDraw = () => {
    if (isEmpty(verifyWithdraw) || withdrawStatus === 'pending') return;
    dispatch(setIsPrintingEjected(true));
    dispatch(
      cashPayThunks.withdrawComplete({
        id: withdrawId,
      }),
    );
  };

  const handleClearWithdraw = () => {
    dispatch(clearWithdraw());
    setWithdrawId('');
  };

  return (
    <CashierModalUI.InputWrapper>
      <CashierModalUI.Input
        label={t(CashierTranslations.CashPayModal.withdrawInputLabel)}
        value={withdrawId}
        placeholder={t(CashierTranslations.CashPayModal.withdrawInputPlaceholder)}
        isDisabled={hasVerifyWithdrawId}
        onChange={(e) => handleUpdateInput({ type: 'withdrawId', value: e.target.value })}
      />

      <CashierKeyBoard
        inputValue={withdrawId}
        updateInputFunc={(value) => handleUpdateInput({ type: 'withdrawId', value })}
        onUnmount={() => {
          return;
        }}
        handleHideKeyboard={() => {
          return;
        }}
        inputRemoveValue={false}
      />

      <CashierModalUI.ButtonsWrapper>
        <UI.Button
          size="2xl"
          variant="secondary"
          text={`${t(CashierTranslations.CashPayModal.clearButton)}`}
          onClick={handleClearWithdraw}
        />
        <UI.Button
          text={
            hasVerifyWithdrawId
              ? `${t(CashierTranslations.CashPayModal.withdrawButton)}`
              : `${t(CashierTranslations.CashPayModal.searchButton)}`
          }
          size="2xl"
          variant="primary"
          isDisabled={!hasInputValues}
          showLoader={verifyWithdrawStatus === 'pending' || withdrawStatus === 'pending'}
          onClick={hasVerifyWithdrawId ? handleWithDraw : handleSearch}
        />
      </CashierModalUI.ButtonsWrapper>
    </CashierModalUI.InputWrapper>
  );
};

export default WithdrawForm;
