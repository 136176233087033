import React from 'react';
import clsx from 'clsx';
import { Icon } from 'components/shared';
import { CSS } from 'utils/common/types/css.types';

interface NavIconProps {
  size: CSS.Sizes;
  icon?: string;
}

const NavIcon: React.FC<NavIconProps> = ({ size, icon }) => {
  if (!icon) return null;

  const iconClass = clsx('mpd-nav__icon', `mpd-nav__icon--${size}`);

  return <Icon className={iconClass} icon={icon} />;
};

export default NavIcon;
