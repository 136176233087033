import styled from 'styled-components';
import { Breakpoints } from 'modules/casino/theme/Theme';

const DataCharactersWrapper = styled.div<{
  $isSlider: boolean;
}>`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;
  position: relative;
  padding: 2px 4px;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    padding: 4px 6px;
  }
  padding: ${({ $isSlider }) => ($isSlider ? '4px 6px' : '2px 4px')};
`;
export default DataCharactersWrapper;
DataCharactersWrapper.displayName = 'DataCharactersWrapper';
