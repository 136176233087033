import React, { memo } from 'react';
import clsx from 'clsx';
import { Flex } from '../layout';

interface Props {
  className?: string;
  componentLocation?: 'preloader' | 'quick-deposit';
}
const DataLoader: React.FC<Props> = ({ className, componentLocation }) => {
  const loaderClass = clsx(
    'loader-wrapper',
    componentLocation === 'preloader'
      ? 'loader-wrapper--preloader'
      : componentLocation === 'quick-deposit'
        ? 'loader-wrapper--qd'
        : 'loader-wrapper--default',
    className,
  );
  return (
    <Flex.Center className={loaderClass}>
      <div className="loader" />
    </Flex.Center>
  );
};

export default memo(DataLoader);
