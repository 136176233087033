import { useEffect } from 'react';

export const storageGameWindows = 'egtd:open_games';

export const setGameLaunchWindow = (key: string) => {
  if (key) {
    const storageValue = localStorage.getItem(storageGameWindows);
    const parsedStorage = storageValue ? JSON.parse(storageValue) : [];
    // Add the window name to the array if it doesn't already exist
    const updatedStorage = parsedStorage.includes(key) ? parsedStorage : [...parsedStorage, key];
    localStorage.setItem(storageGameWindows, JSON.stringify(updatedStorage));
  }
};

export const removeGameLaunchWindow = (key: string) => {
  if (key) {
    const storageValue = localStorage.getItem(storageGameWindows);
    const parsedStorage = storageValue ? JSON.parse(storageValue) : [];

    if (parsedStorage.includes(key)) {
      const updatedStorage = parsedStorage.filter((item: string) => item !== key);
      localStorage.setItem(storageGameWindows, JSON.stringify(updatedStorage));
    }
  }
};

const useTrackGameLaunchWindows = () => {
  useEffect(() => {
    const key = window.name;

    // Add the current window name to localStorage upon mount
    setGameLaunchWindow(key);

    return () => {
      // Remove the window name from localStorage when the window is closed
      removeGameLaunchWindow(key);
    };
  }, []);
};

export default useTrackGameLaunchWindows;
