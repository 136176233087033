import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UI } from 'modules/casino/shared/components/UI';

interface Props {
  link: string;
  classNames?: string;
  buttonSize?: string;
}

const JackpotsWidgetCellButton: React.FC<Props> = ({ link, classNames, buttonSize }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const buttonLabel = `${t('Casino.CasinoWidget.jackpotsWidgetCellBtnLabel')}`;
  const buttonClass = clsx(classNames && classNames);

  const onButtonClick = (ev: React.MouseEvent) => {
    ev.stopPropagation();

    if (link) {
      navigate(link);
    }
  };

  return link ? (
    <UI.Button c-size={buttonSize} className={buttonClass} onClickHandler={onButtonClick} text={buttonLabel} />
  ) : (
    <></>
  );
};

JackpotsWidgetCellButton.displayName = 'JackpotsWidgetCellButton';
export default JackpotsWidgetCellButton;
