import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { useSwiperSlide } from 'swiper/react';
import { Breakpoints } from 'theme/Theme';
import { createBonusItemAnimation } from '../../../utils/createBonus.utils';

export const SelectBonusSlide = ({
  item,
  triggerForceShowItems,
  isLast,
  renderItems,
}: {
  item;
  triggerForceShowItems?: boolean;
  isLast?: boolean;
  renderItems: ({ element, idx, options }) => React.ReactNode;
}) => {
  const items = item?.data ? Object.keys(item?.data) : Object.keys(item);
  const options = item?.data ? item?.data : item;
  const swiperSlide = useSwiperSlide();

  return (
    <SelectBonusSlideStyle>
      <AnimatePresence>
        {triggerForceShowItems && swiperSlide.isActive && (
          <ItemWrapperStyle
            variants={createBonusItemAnimation.wrapper}
            animate="visible"
            initial="hidden"
            $isLast={isLast}
          >
            {items.map((el, idx) => renderItems({ element: el, idx, options }))}
          </ItemWrapperStyle>
        )}
      </AnimatePresence>
    </SelectBonusSlideStyle>
  );
};

const SelectBonusSlideStyle = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  flex-direction: column;
  overflow: hidden;
  /* padding: 0 17px;
  @media (min-width: ${Breakpoints.isDesktop}px) {
    padding: 0 20px;
  } */
`;
const ItemWrapperStyle = styled(motion.div)<{ $isLast?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  @media (min-width: ${Breakpoints.isDesktop}px) {
    ${({ $isLast }) => $isLast && 'gap:15px'}
  }
`;
