import { useEffect } from 'react';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { useQuery } from 'hooks/useQuery';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { createCookie } from 'utils/common/helpersCommon';

export const utmCookieName = 'utm_query_params';
const utmNames = [
  'utm_id',
  'utm_affiliate',
  'utm_marketing_tactic',
  'utm_source_platform',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'landing_page',
  'gclid',
  'fbclid',
];
const UtmCookieQueryParams = (): null => {
  const { query } = useQuery();
  const affiliateTokenExpires = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.AFFILIATE_EXPIRES,
    deviceLayout: 'general',
  });

  useEffect(() => {
    const utmCookie = utmNames.reduce(
      (cookies, cookie) => (query.get(cookie) ? [...cookies, `${cookie}=${query.get(cookie)}`] : cookies),
      [] as string[],
    );

    if (utmCookie.length) {
      const expireDate = new Date().setTime(
        new Date().getTime() + (affiliateTokenExpires ? affiliateTokenExpires : 30) * 24 * 60 * 60 * 1000,
      );
      createCookie({ name: utmCookieName, value: utmCookie.join(','), expiration: expireDate });
    }
  }, []);

  return null;
};

export default UtmCookieQueryParams;
