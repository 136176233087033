import styled from 'styled-components';

const HighlightFeatureBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1020;
`;

export default HighlightFeatureBackdrop;
