import React from 'react';
import CurrencySwapper from 'modules/casino/shared/components/common/CurrencySwapper';
import { useCurrencyConfig } from 'modules/casino/shared/hooks/useCurrencyConfig';
import { formatSumAmount } from 'modules/casino/shared/utils/helpersCasino';

type Props = {
  value: string;
  currencySymbol: string;
};

const WinItem: React.FC<Props> = ({ value, currencySymbol }) => {
  const { formatToComma, currencySymbolFirst, spaceBetween } = useCurrencyConfig();

  if (!value) {
    return null;
  }

  return (
    <div className="bww-item__win">
      <CurrencySwapper
        sumComponent={<>{formatSumAmount({ sum: value, formatToComma })}</>}
        currencyComponent={<span className={`bww-item__win--currency`}>{currencySymbol}</span>}
        currencySymbolFirst={currencySymbolFirst}
        spaceBetween={spaceBetween}
      />
    </div>
  );
};

export default WinItem;
