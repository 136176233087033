import { useState, useMemo, Dispatch, SetStateAction } from 'react';

type ReturnHookType = {
  state: { winItemIndex: number | null };
  handlers: { setWinItemIndex: Dispatch<SetStateAction<number | null>> };
};

const useWinItemState = (): ReturnHookType => {
  const [winItemIndex, setWinItemIndex] = useState<number | null>(null);

  const handlers = useMemo(() => ({ setWinItemIndex }), []);

  const state = useMemo(() => ({ winItemIndex }), [winItemIndex]);

  return { state, handlers };
};

export default useWinItemState;
