import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { InPlayTypes } from 'utils/common/types/sportTypes';
import { DrawerThunks, selectDrawer } from '../../../slice/drawer.slice';

type HookReturnType = {
  items: InPlayTypes.LiveSport[];
  activeItem: number | undefined;
};
const POLLING_INTERVAL = 10_000;

export const useGetDrawerLiveSport = (): HookReturnType => {
  const dispatch = useAppDispatch();
  const items = useAppSelector(selectDrawer.liveSports);
  const activeItem = useAppSelector(selectDrawer.activeLiveSportId);
  const isDrawerOpen = useAppSelector(selectDrawer.isVisible);

  useEffect(() => {
    if (isDrawerOpen) {
      dispatch(DrawerThunks.fetchLiveSports());
      const interval = setInterval(() => {
        dispatch(DrawerThunks.fetchLiveSports());
      }, POLLING_INTERVAL);
      return () => {
        clearInterval(interval);
      };
    }
  }, [dispatch, isDrawerOpen]);

  return { items, activeItem };
};
