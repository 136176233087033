import { useTranslation } from 'react-i18next';
import { InfoTableUI } from 'modules/retail/modules/ssbt/components/infoTableUI';
import { isEmpty, retailFormatDate } from 'utils/common/helpersCommon';
import { useVoucherData } from './useVoucherData';
import { CashierModalUI } from '../../cashierModalUI/Index';
const CreateCashierVoucher = () => {
  const { t } = useTranslation();
  const { voucherCashierData, voucherCashierStatus } = useVoucherData();
  return (
    <>
      {isEmpty(voucherCashierData) && voucherCashierStatus === 'idle' && (
        <CashierModalUI.InfoMessage
          className="m-auto"
          icon={'ticket-solid'}
          infoMessage={t('Cashier.VoucherModal.issueVoucherDetails')}
        />
      )}
      {voucherCashierStatus === 'failure' && (
        <CashierModalUI.InfoMessage
          className="m-auto color--danger fill--danger"
          icon={'info-solid'}
          infoMessage={t('Cashier.RetailErrorModal.errorText')}
        />
      )}
      {!isEmpty(voucherCashierData) && (
        <InfoTableUI.Wrapper>
          <InfoTableUI.Cell
            isSpanned
            cellText={t('Cashier.VoucherModal.voucherCode')}
            cellValue={voucherCashierData?.code}
          />
          <InfoTableUI.Cell
            isSpanned
            cellText={t('Cashier.CashPayModal.amount')}
            cellValue={`${voucherCashierData?.value?.amount} ${voucherCashierData?.value?.currency}`}
          />
          <InfoTableUI.Cell
            isSpanned
            cellText={t('Cashier.VoucherModal.issueDate')}
            cellValue={retailFormatDate(voucherCashierData?.issuedAt)}
          />
          <InfoTableUI.Cell
            isSpanned
            cellText={t('Cashier.VoucherModal.validDate')}
            cellValue={retailFormatDate(voucherCashierData?.validUntil)}
          />
        </InfoTableUI.Wrapper>
      )}
    </>
  );
};
export default CreateCashierVoucher;
