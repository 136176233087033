import { useState } from 'react';

const useReelAnimation = () => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [isSpinFinished, setIsSpinFinished] = useState(false);

  const startSpin = () => {
    setIsSpinning(true);
  };

  const stopSpin = () => {
    setIsSpinning(false);
    setIsSpinFinished(true);
  };

  return { stopSpin, startSpin, isSpinFinished, isSpinning };
};

export default useReelAnimation;
