import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormUI } from 'components/shared/forms/components';
import { AuthUI } from 'pages/auth/components/AuthUI';
import { useAppSelector } from 'store';
import { Restrictions } from 'utils/common/translationUtils/translationStrings';
import { selectSEConfirmation } from '../slice/selfExclusionConfirmation.slice';

const SEActivationError: React.FC = () => {
  const { t } = useTranslation();

  const submitError = useAppSelector(selectSEConfirmation.submitError);

  return (
    <>
      <FormUI.Status type="error" />
      <AuthUI.Text text={t(Restrictions.Exclusions.seErrorLabel)} />
      <AuthUI.Text isSecondary text={t(submitError)} />
    </>
  );
};

export default SEActivationError;
