import React from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { UI } from 'components/shared/UI';
import { cssBurgerNS, tileAnimation } from './burgerUI.types';

/** NavItem */
interface LinkItemProps {
  className?: string;

  icon: string;
  iconColor?: string;
  text: string;

  isActive?: boolean;

  dataQid?: string;
  dataCpp?: string;

  onClick?: React.MouseEventHandler;
}

const LinkItem: React.FC<LinkItemProps> = ({
  className,
  icon,
  iconColor,
  text,

  isActive,

  dataQid,
  dataCpp,
  onClick,
}) => {
  const wrapperClass = clsx(
    'd-flex-ac',
    cssBurgerNS.LinkItem,
    isActive && `${cssBurgerNS.LinkItem}--active`,
    className,
  );

  const dataCPPProp = iconColor ? { style: { fill: iconColor } } : { 'data-cpp': dataCpp };

  return (
    <motion.div variants={tileAnimation} className={wrapperClass} onClick={onClick} data-qid={dataQid} {...dataCPPProp}>
      {icon && <UI.SVGCDN className={`mr-2 ${cssBurgerNS.LinkItem}__icon`} icon={icon} path="HELP" />}
      {text}
    </motion.div>
  );
};

export default LinkItem;
