import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import { cssGlobalNS } from 'utils/common/types/css.types';
import RequestBetNotificationSimple from './RequestBetNotificationSimple';
import { SCRequestBetWrite } from './styled';
import { RequestBetState, resetRequestBetForm, selectRequestBet } from '../slice/requestBet.slice';

type Props = {
  onChange: (next: Partial<RequestBetState>) => void;
  description: string;
  maxLength?: number;
  minLenght?: number;
  maxLengthText?: string;
  minLengthError: boolean;
  setMinLenghtError: (boolean: boolean) => void;
};

const RequestBetWrite: React.FC<Props> = ({
  maxLength = 650,
  minLenght = 10,
  maxLengthText = Promotions.RequestBet.maxLengthText,
  description,
  minLengthError,
  onChange,
  setMinLenghtError,
}) => {
  const isSubmited = useAppSelector(selectRequestBet.isSubmited);
  const error = useAppSelector(selectRequestBet.error);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange({ description: e?.target?.value });
    if (isSubmited || error) {
      dispatch(resetRequestBetForm());
    }
    if (minLengthError && e?.target?.value.trim().length >= minLenght) {
      setMinLenghtError(false);
    }
  };

  const showMessage = description.trim().length > maxLength || minLengthError;

  return (
    <>
      <SCRequestBetWrite
        value={description}
        onChange={handleChangeText}
        placeholder={t(Promotions.RequestBet.textPlaceholder)}
        className={`${cssGlobalNS}-rb__textarea w-100`}
        rows={6}
        maxLength={maxLength + 1}
      />
      {showMessage && <RequestBetNotificationSimple icon="info-solid" text={t(maxLengthText)} />}
    </>
  );
};

export default memo(RequestBetWrite);
