import clsx from 'clsx';

const useGamificationItemStyles = (
  isWinItem: boolean,
  itemsLength?: number,
  removeMargin?: boolean,
): { winTextClass: string } => {
  const winTextClass = clsx(
    'text-center grid-start-1x1 gmf-modal__win',
    !removeMargin && 'm-auto',
    isWinItem && 'gmf-modal__win--accent',
    itemsLength && itemsLength > 4 ? 'gmf-modal__win--small' : '',
  );

  return { winTextClass };
};

export default useGamificationItemStyles;
