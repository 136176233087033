import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { UI } from 'components/shared/UI';
import {
  resetCashierCancelVoucherStatus,
  resetCashierPayoutVoucherStatus,
  resetVoucherInfoCashier,
  voucherThunk,
} from 'modules/retail/shared/slice/voucher.slice';
import { useAppDispatch } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { useVoucherActions } from './useVoucherActions';
import { useVoucherState } from './useVoucherState';
import { actionButtonVoucherEnums, voucherActionButtons } from '../../cashierModalUI/actionButtonsMock';
import { CashierModalUI } from '../../cashierModalUI/Index';
import GridButtonVoucher from '../../GridButtonVoucher';

const CashierPayCancelVoucherForm = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const initialTime = 5;
  const maxInputLength = 15;

  const {
    inputValue,
    setInputValue,
    actionButtonClicked,
    setActionButtonClicked,
    isActive,
    setIsActive,
    activeButton,
    clickCount,
    setClickCount,
    buttonId,
    setButtonId,
    seconds,
    setSeconds,
    voucherInfoCashier,
    payoutVoucherStatus,
    cancelVoucherStatus,
  } = useVoucherState(initialTime);

  useVoucherActions(
    clickCount,
    setClickCount,
    buttonId,
    isActive,
    setActionButtonClicked,
    setSeconds,
    actionButtonClicked,
    inputValue,
    setInputValue,
    voucherInfoCashier,
  );
  const updateInputValue = (newValue) => {
    const updatedValue = newValue.startsWith('V') ? newValue.slice(1) : newValue;
    setInputValue(updatedValue);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const voucherCode = encodeURIComponent(inputValue);

  const checkVoucher = () => {
    if (payoutVoucherStatus === 'failure') {
      dispatch(resetCashierPayoutVoucherStatus());
    }

    if (cancelVoucherStatus === 'failure') {
      dispatch(resetCashierCancelVoucherStatus());
    }

    dispatch(voucherThunk.getVoucherInfoCashier({ voucherCode: voucherCode }));
  };

  const resetVoucherInfo = () => {
    dispatch(resetVoucherInfoCashier());
    dispatch(resetCashierPayoutVoucherStatus());
    dispatch(resetCashierCancelVoucherStatus());
    setInputValue('');
  };

  const handleButtonPress = (id) => {
    const otherId =
      id === actionButtonVoucherEnums.cancelVoucher
        ? actionButtonVoucherEnums.payOutVoucher
        : actionButtonVoucherEnums.cancelVoucher;
    setButtonId(id);
    setActionButtonClicked((prevState) => ({
      ...prevState,
      [id]: true,
      [otherId]: false,
    }));
    setIsActive((prevState) => ({
      ...prevState,
      [otherId]: false,
      [id]: true,
    }));
    if (isActive[id]) {
      if (actionButtonClicked[id] === false) {
        setSeconds(initialTime);
      }
      if (seconds === 0 && clickCount === 1) {
        return;
      } else {
        setClickCount((prevCount) => prevCount + 1);
      }
    }
  };
  return (
    <CashierModalUI.InputWrapper>
      <CashierModalUI.Input
        ref={inputRef}
        label={t(`Cashier.VoucherModal.voucherCode`)}
        value={inputValue}
        placeholder={t(`Cashier.VoucherModal.typeText`)}
        onChange={(e) => updateInputValue(e.target.value)}
        isDisabled={false}
        maxLength={maxInputLength}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter' && inputValue.trim()) {
            checkVoucher();
          }
        }}
      />

      <CashierModalUI.ButtonsWrapper>
        <UI.Button
          size="2xl"
          variant="primary"
          text={t(`Cashier.VoucherModal.checkVoucher`)}
          onClick={checkVoucher}
          isDisabled={inputValue?.length < maxInputLength}
        />
        <UI.Button
          size="2xl"
          variant="secondary"
          text={t(`Cashier.CashPayModal.clearButton`)}
          onClick={resetVoucherInfo}
        />
      </CashierModalUI.ButtonsWrapper>
      {!isEmpty(voucherInfoCashier) && voucherInfoCashier?.isValid && (
        <div className="cashier-modal__nav-grid">
          {voucherActionButtons(t)?.map((btn) => (
            <GridButtonVoucher
              key={btn.id}
              text={btn.text}
              icon={btn.icon}
              id={btn.id}
              hasCounter={btn.hasCounter}
              onClick={handleButtonPress}
              isActive={isActive}
              setIsActive={setIsActive}
              seconds={seconds}
              setSeconds={setSeconds}
              activeButton={activeButton}
              actionButtonClicked={actionButtonClicked}
              setActionButtonClicked={setActionButtonClicked}
            />
          ))}
        </div>
      )}
    </CashierModalUI.InputWrapper>
  );
};

export default CashierPayCancelVoucherForm;
