import React from 'react';
import clsx from 'clsx';
import { Text } from 'components/shared';
import { ExpandableIcon } from 'components/shared/icons';
import SportIconCDN from 'components/shared/icons/SportIconCDN';
import { Flex } from 'components/shared/layout';
import { UI } from 'components/shared/UI';
import FavouritesIcon from 'shared/common/features/drawer/components/favourites/favouritesIcon/FavouritesIcon';
import { FavouritesSection } from 'shared/common/features/drawer/components/favourites/types/favourites.types';
import Logo from './Logo';

/** DrawerUI Link */
interface LinkProps {
  icon?: string;
  logo?: string;
  sportIcon?: number;
  text?: string;
  isActive: boolean;
  onClick: React.MouseEventHandler;
  isExpandable?: boolean;
  showLiveCounter?: boolean;
  liveCounter?: number;
  liveCounterText?: string;
  showFav?: boolean;
  favId?: number;
  dataQid?: string;
  showHelpIcon?: boolean;
}

const Link: React.FC<LinkProps> = ({
  logo,
  icon,
  sportIcon,
  text,
  isActive,
  onClick,
  isExpandable,
  showLiveCounter,
  liveCounter,
  liveCounterText,
  showFav,
  favId,
  dataQid,
  showHelpIcon,
}) => {
  const linkClass = clsx(
    'drawer-menu-item drawer-link',
    isExpandable && 'drawer-link--expandable',
    isActive && (isExpandable ? 'drawer-link--expandable--active' : 'drawer-link--active'),
  );

  const iconClass = 'drawer-link__icon drawer-link__icon--filled';

  const showIcon = icon !== undefined;

  return (
    <Flex.AC className={linkClass} onClick={onClick} data-qid={dataQid}>
      {sportIcon && <SportIconCDN type="drawer" icon={sportIcon} initialInViewIcon={true} />}
      {showHelpIcon ? (
        <UI.SVGCDN className={iconClass} icon={icon} path="HELP" />
      ) : showIcon ? (
        <UI.SVGCDN className={iconClass} icon={icon} />
      ) : (
        <></>
      )}

      {logo && <Logo logo={logo} />}

      <Text className="mr-auto text-truncate drawer-link__text" text={text} />

      {showLiveCounter && (
        <span className="drawer-link__live-counter">
          {liveCounter} {liveCounterText}
        </span>
      )}
      {showFav && favId && (
        <FavouritesIcon componentLocation="drawer" favouriteType={FavouritesSection.sportIds} favouriteId={favId} />
      )}
      {isExpandable && <ExpandableIcon isExpanded={isActive} />}
    </Flex.AC>
  );
};

export default Link;
