import { createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit';
import Axios from 'axios';
import { assignEventsCountInView } from 'components/shared/sportContainers/event/utils/event.utils';
import userStorage from 'pages/auth/login/UserStorage';
import { HomePageModule } from 'pages/homePage/types/homePage.types';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { hardwareBackPress, logoutUser } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { createAbortThunk, rejectedWrapper } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError, isEmpty, sortByProperty } from 'utils/common/helpersCommon';
import { InPlayTypes, Sport } from 'utils/common/types/sportTypes';
import config from 'utils/config';
import { FavouritesTypes } from '../components/favourites/types/favourites.types';
import { DrawerTypes, DrawerType } from '../types/drawer.types';
import { setSportNavigationUrl } from '../utils/drawer.utils';

const initialState: DrawerTypes.State = {
  isDrawerVisible: false,
  typeDrawer: DrawerType.InActive,
  sportDrawerContainers: [],
  drawerCasinoVerticals: [],
  sports: [],
  esports: [],
  containers: {},
  liveSports: [],
  liveSportsState: 'idle',
  activePrematchSportID: 0,
  activeESportId: 0,
  activeLiveSportId: 0,
  favourites: userStorage.getUserFavourites() || {},
  casinoDrawerType: '',
};

export const fetchSportsPromise = (): Promise<DrawerTypes.TabData[]> =>
  axiosInstance
    .get(`${config.API_URL}/api/sportsapi/public/simpleSportsInfo`)
    .then((response) => response.data)
    .catch(() => []);

export const fetchESportsPromise = (): Promise<DrawerTypes.TabData[]> =>
  axiosInstance
    .get(`${config.API_URL}/api/sportsapi/public/simpleEsportsInfo`)
    .then((response) => response.data)
    .catch(() => []);

export const DrawerThunks: DrawerTypes.Thunks = {
  fetchSportDrawerContainers: createAbortThunk('DrawerThunks/fetchSportDrawerContainers', async () => {
    try {
      const fetchURL = `${config.API_URL}/api/cms/public/sport-item-content`;
      const response = await axiosInstance.get(fetchURL, { data: null });
      return response.data;
    } catch (error) {
      // console.log(`error in fetchSportDrawerContainers - ${(error as AxiosError).message}`);
    }
  }),
  fetchDrawerCasinoVerticals: createAbortThunk('DrawerThunks/fetchDrawerCasinoVerticals', async () => {
    try {
      const fetchURL = `${config.API_URL}/api/cms/public/verticals-content`;
      const response = await axiosInstance.get(fetchURL, { data: null });
      return response.data;
    } catch (error) {
      // console.log(`error in fetchDrawerCasinoVerticals - ${(error as AxiosError).message}`);
    }
  }),
  fetchSports: createAbortThunk('drawer/fetchSports', async () => await fetchSportsPromise()),
  fetchESports: createAbortThunk('drawer/fetchEsports', async () => await fetchESportsPromise()),

  fetchContainer: createAbortThunk('drawer/fetchContainer', async (arg, { rejectWithValue, signal }) => {
    const { apiUri } = arg;
    try {
      const source = Axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const response = await axiosInstance.get<DrawerTypes.ApiContainerData>(`${config.API_URL}${apiUri}`, {
        cancelToken: source.token,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseDataMessage(err));
    }
  }),
  fetchLiveSports: createAbortThunk('drawer/fetchLiveSports', async (_, { source }) => {
    const response = await axiosInstance.get<InPlayTypes.LiveSport[]>(
      `${config.API_URL}/api/sportsapi/public/sports/live`,
      {
        cancelToken: source.token,
      },
    );
    return response.data;
  }),
};

const {
  fetchSportDrawerContainers,
  fetchDrawerCasinoVerticals,
  fetchSports,
  fetchESports,
  fetchContainer,
  fetchLiveSports,
} = DrawerThunks;

const Drawer = createSlice({
  name: 'Drawer',
  initialState,
  reducers: {
    setDrawer(state, action) {
      state.isDrawerVisible = action.payload;
    },
    setDrawerType(state, action) {
      state.typeDrawer = action.payload;
    },
    setCasinoDrawerType(state, action) {
      state.casinoDrawerType = action.payload;
    },
    setActiveLiveSportIDPlatform(state, action) {
      state.activeLiveSportId = action.payload;
    },
    setActivePrematchSportIDPlatform(state, action) {
      state.activePrematchSportID = action.payload;
    },
    setActiveESportIdPlatform(state, action) {
      state.activeESportId = action.payload;
    },
    setFavourites: (state, action: PayloadAction<FavouritesTypes.Favourites>) => {
      if (isEmpty(action.payload)) {
        state.favourites = action.payload;
      } else {
        state.favourites = { ...state.favourites, ...action.payload };
      }
      userStorage.setUserFavourites(state.favourites);
    },
    clearSportSection(state) {
      state.sports = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLiveSports.fulfilled, (state, action) => {
        const sports = setSportNavigationUrl(action.payload);
        state.liveSports = sortByProperty(sports, 'ranking');
        state.liveSportsState = 'succeeded';
        if (isEmpty(state.liveSports)) {
          state.liveSportsState = 'empty';
        }
      })
      .addCase(fetchSportDrawerContainers.fulfilled, (state, action: PayloadAction<DrawerTypes.SportVertical[]>) => {
        state.sportDrawerContainers = action.payload;
      })
      .addCase(fetchDrawerCasinoVerticals.fulfilled, (state, action: PayloadAction<DrawerTypes.Vertical[]>) => {
        state.drawerCasinoVerticals = action.payload;
      })
      .addCase(fetchSports.fulfilled, (state, action) => {
        state.sports = action.payload;
      })
      .addCase(fetchESports.fulfilled, (state, action) => {
        state.esports = action.payload;
      })
      .addCase(fetchContainer.pending, (state, action) => {
        const { containerId, apiUri } = action.meta.arg;

        if (!state.containers[containerId]?.fetched) {
          state.containers[containerId] = {
            containerId,
            containerName: '',
            status: 'pending',
            fetched: false,
            error: null,
            apiUri,
            sports: [],
          };
        }
      })
      .addCase(
        fetchContainer.rejected,
        rejectedWrapper((state, action) => {
          const { containerId, apiUri } = action.meta.arg;
          state.containers[containerId] = {
            containerId,
            status: 'failure',
            error: action.error,
            fetched: true,
            apiUri,
          };
        }),
      )
      .addCase(fetchContainer.fulfilled, (state, action) => {
        const { containerId, apiUri, containerType } = action.meta.arg;
        const normalizedSports: HomePageModule.SportData[] = !isEmpty(action.payload.sports)
          ? assignEventsCountInView({
              sportsOrTournaments: action.payload.sports,
            })
          : [];
        state.containers[containerId] = {
          ...state.containers[containerId],
          containerId,
          containerName: action.payload?.containerName,
          linkContainerContents: action.payload?.linkContainerContents,
          navigationUrl: action.payload?.navigationUrl,
          sportId: action.payload?.sportId,
          sports: normalizedSports,
          apiUri,
          containerType,
          status: 'succeeded',
          fetched: true,
          error: null,
        };
      })
      .addCase(logoutUser, (state) => {
        state.favourites = {};
        userStorage.setUserFavourites(state.favourites);
      })
      .addMatcher(isAnyOf(showRootModal, hardwareBackPress), (state) => {
        state.isDrawerVisible = false;
      });
  },
});
export const {
  setDrawer,
  setDrawerType,
  setActivePrematchSportIDPlatform,
  setActiveESportIdPlatform,
  setActiveLiveSportIDPlatform,
  setFavourites,
  clearSportSection,
  setCasinoDrawerType,
} = Drawer.actions;

export const selectDrawer = {
  liveSports: (state: RootState): InPlayTypes.LiveSport[] => state.common.drawer.liveSports,
  sports: (state: RootState): Sport.ActiveSport[] => state.common.drawer.sports,
  esports: (state: RootState): Sport.ActiveSport[] => state.common.drawer.esports,
  containerById:
    (containerId: number) =>
    (state: RootState): DrawerTypes.Container =>
      state.common.drawer.containers[containerId],
  type: (state: RootState): DrawerType => state.common.drawer.typeDrawer,
  sportContainers: (state: RootState): DrawerTypes.SportVertical[] => state.common.drawer.sportDrawerContainers,
  casinoVerticals: (state: RootState): DrawerTypes.Vertical[] => state.common.drawer.drawerCasinoVerticals,
  isVisible: (state: RootState): boolean => state.common.drawer.isDrawerVisible,
  routingKeys: (): string[] => [],
  activePrematchSportID: (state: RootState): number => state.common.drawer.activePrematchSportID,
  activeESportId: (state: RootState): number => state.common.drawer.activeESportId,
  activeLiveSportId: (state: RootState): number => state.common.drawer.activeLiveSportId,
  favourites: (state: RootState) => state.common.drawer.favourites,
  liveSportsState: (state: RootState) => state.common.drawer.liveSportsState,
  casinoDrawerType: (state: RootState) => state.common.drawer.casinoDrawerType,
};

export default Drawer.reducer;
