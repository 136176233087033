export const retailConfig = {
  retail: {
    excludeApis: [
      `api/ews-crm/player/player-messages/popup/get`,
      `api/ews-crm/player/player-messages`,
      `api/ews-bonuses/player/players/bonuses/offers`,
      `api/ews-crm/player/player-messages/page/count-unread`,
      `api/mybets/player/bets/open/count`,
      `api/accounting/common/player/accounts/summary`,
      `api/ews-crm/player/player-messages/inbox/get`,
      `api/user-favorites/player/.*`,
      `api/gaming/public/providers`,
      `/api/ews-crm/player/player-messages/popup/get-by-grouped`,
      `/api/accounting/common/player/accounts/summary`,
      `api/ews-crm/player/players/player-geolocation-data`,
    ],
  },
};
