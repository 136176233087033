import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Icon } from 'components/shared';
import { Promotions } from 'utils/common/translationUtils/translationStrings';

interface Props {
  text?: string;
}

const Error: React.FC<Props> = ({ text }) => {
  const { t } = useTranslation();
  const StyledError = styled.span`
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    color: #fce34c;
    fill: currentColor;
  `;

  return (
    <StyledError>
      <Icon className="mr-2" size={16} icon="times-solid" />
      <span className="text-left">
        {text} <br />
        {t(Promotions.Common.contactSupport)}
      </span>
    </StyledError>
  );
};

export default memo(Error);
