import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { isMobile } from 'react-device-detect';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { PrizeDropTypes } from '../types/prizeDrop.types';

const initialState: PrizeDropTypes.State = {
  activeCampaignId: 0,
  normalizedCampaigns: {},
  campaignsIds: [],
  rewards: {},
  rewardedPlayers: {},
  personalRewards: {},
  termsAndConditions: {},
  campaignsStatus: {},
  status: 'idle',
};

export const prizeDropThunks = {
  getAllCampaigns: createAbortThunk<
    PrizeDropTypes.Campaign[],
    { isPolling?: boolean; isInGameBar?: boolean },
    string | null
  >('prizeDrop/getAllCampaigns', async (_, { source, rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<PrizeDropTypes.Campaign[]>(
        `${config.API_URL}/api/ews-bonuses/player/player-leaderboard`,
        {
          cancelToken: source.token,
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(getError.responseData(error));
    }
  }),
  getCampaignData: createAbortThunk<PrizeDropTypes.CampaignsAPI, { campaignId: number }, string | null>(
    'prizeDrop/getCampaignData',
    async ({ campaignId }, { source, rejectWithValue }) => {
      try {
        const response = await axiosInstance.get<PrizeDropTypes.CampaignsAPI>(
          `${config.API_URL}/api/ews-bonuses/player/player-leaderboard/rewards/${campaignId}`,
          {
            cancelToken: source.token,
          },
        );
        return response.data;
      } catch (error) {
        return rejectWithValue(getError.responseData(error));
      }
    },
  ),
};

const prizeDrop = createSlice({
  name: 'prizeDrop',
  initialState,
  reducers: {
    setActiveCampaignId(state, action: PayloadAction<number>) {
      state.activeCampaignId = action.payload;
    },
    resetCampaignsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(prizeDropThunks.getAllCampaigns.pending, (state, action) => {
        const { isPolling } = action.meta.arg;
        if (!isPolling) {
          state.status = 'pending';
        }
      })
      .addCase(prizeDropThunks.getAllCampaigns.rejected, (state) => {
        state.status = 'failure';
      })
      .addCase(prizeDropThunks.getAllCampaigns.fulfilled, (state, action) => {
        const { isPolling } = action.meta.arg;
        state.status = 'succeeded';
        state.campaignsIds = action.payload?.map((campaign) => campaign.id);

        if (isPolling) {
          Object.values(state.normalizedCampaigns)?.forEach((normalizedCampaign) => {
            if (state.campaignsIds?.includes(normalizedCampaign.id)) return;
            // Delete and reset state.activeCampaignId if the campaign no longer exist
            if (state.campaignsIds[normalizedCampaign.id]) delete state.campaignsIds[normalizedCampaign.id];
            if (state.activeCampaignId === normalizedCampaign.id) state.activeCampaignId = 0;
          });
        }

        action.payload?.forEach((campaign) => {
          state.normalizedCampaigns[campaign.id] = campaign;
          // Reset state.activeCampaignId if active campaign in state is no longer active
          const activeCampaign = state.normalizedCampaigns[state?.activeCampaignId];
          const currIsTheActiveCampaign = campaign.id === activeCampaign?.id;
          const shouldResetActiveCampaignId = currIsTheActiveCampaign && campaign.isActive === false;

          if (shouldResetActiveCampaignId) state.activeCampaignId = 0;
          // Set state.activeCampaignId if device is not mobile and curr campaign is active - needed at the moment only for prize drop WIN view
          if (!isMobile && !state.activeCampaignId) {
            const { isInGameBar } = action.meta.arg;
            if (!isInGameBar) {
              state.activeCampaignId = campaign.id;
            }
          }
          state.normalizedCampaigns[campaign.id] = campaign;
        });
      });
    builder
      .addCase(prizeDropThunks.getCampaignData.pending, (state, action) => {
        const { campaignId } = action.meta.arg;
        state.campaignsStatus[campaignId] = 'pending';
      })
      .addCase(prizeDropThunks.getCampaignData.fulfilled, (state, action) => {
        const { campaignId } = action.meta.arg;
        state.campaignsStatus[campaignId] = 'succeeded';
        state.rewards[campaignId] = action.payload?.rewards;
        state.rewardedPlayers[campaignId] = action.payload?.rewardedPlayers;
        state.personalRewards[campaignId] = action.payload?.personalRewards;
        state.termsAndConditions[campaignId] = action.payload?.termsAndConditions;
      })
      .addCase(prizeDropThunks.getCampaignData.rejected, (state, action) => {
        const { campaignId } = action.meta.arg;
        state.campaignsStatus[campaignId] = 'failure';
        if (state.rewards[campaignId]) delete state.rewards[campaignId];
        if (state.rewardedPlayers[campaignId]) delete state.rewardedPlayers[campaignId];
        if (state.personalRewards[campaignId]) delete state.personalRewards[campaignId];
        if (state.termsAndConditions[campaignId]) delete state.termsAndConditions[campaignId];
      });
    builder.addCase(logoutUser, () => initialState);
  },
});

export const { setActiveCampaignId, resetCampaignsState } = prizeDrop.actions;

export const selectPrizeDrop = {
  campaign: (id: number) => (state: RootState) => state.promotion.prizeDrop.normalizedCampaigns[id],
  status: (state: RootState) => state.promotion.prizeDrop.status,
  campaignsIds: (state: RootState) => state.promotion.prizeDrop.campaignsIds,
  activeCampaignId: (state: RootState) => state.promotion.prizeDrop.activeCampaignId,
  activeCampaign: createSelector(
    [
      (state: RootState): number => state.promotion.prizeDrop.activeCampaignId,
      (state: RootState): Record<string, PrizeDropTypes.Campaign> => state.promotion.prizeDrop.normalizedCampaigns,
    ],
    (
      activeCampaignId: number,
      campaigns: Record<string, PrizeDropTypes.Campaign>,
    ): PrizeDropTypes.Campaign | undefined => campaigns?.[activeCampaignId],
  ),
  activeCampaignProp: createSelector(
    [
      (state: RootState, prop: number) => ({ activeCampaignId: state.promotion.prizeDrop.activeCampaignId, prop }),
      (state: RootState): Record<number, PrizeDropTypes.Campaign> => state.promotion.prizeDrop.normalizedCampaigns,
    ],
    ({ activeCampaignId, prop }, campaigns: Record<number, PrizeDropTypes.Campaign>) =>
      campaigns?.[activeCampaignId]?.[prop],
  ),
  campaignStatus: (id: number) => (state: RootState) => state.promotion.prizeDrop.campaignsStatus?.[id],
  rewards: (id: number) => (state: RootState) => state.promotion.prizeDrop.rewards?.[id],
  rewardedPlayers: (id: number) => (state: RootState) => state.promotion.prizeDrop.rewardedPlayers?.[id],
  personalRewards: (id: number) => (state: RootState) => state.promotion.prizeDrop.personalRewards?.[id],
  termsAndConditions: (id: number) => (state: RootState) => state.promotion.prizeDrop.termsAndConditions?.[id],
};

export default prizeDrop.reducer;
