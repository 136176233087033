import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tournaments } from 'pages/promotions/promotionLeaderBoard/leaderBoardTypes';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppSelector } from 'store';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import TournamentsTableRow from './TournamentsTableRow';

type Props = {
  ranking: Tournaments.PlayerRanking[];
};

const TournamentsLeaderBoardRanking: React.FC<Props> = ({ ranking }) => {
  const { t } = useTranslation();
  const currentUser = useAppSelector(selectMyProfile.dataUser);

  return (
    <>
      <TournamentsTableRow
        firstColText={'№'}
        secondColText={t(Promotions.Tournaments.playerLabel)}
        thirdColText={t(Promotions.Tournaments.scoresLabel)}
        noSeparator={true}
        header
      />
      {ranking.map((player, index) => {
        const shouldHighlight = player.name === currentUser?.username;

        return (
          <TournamentsTableRow
            key={player.rank + player.name}
            firstColText={`${player.rank}`}
            secondColText={player.name}
            thirdColText={`${player.points}`}
            isHighlighted={shouldHighlight}
            isBold={index < 3}
          />
        );
      })}
    </>
  );
};

export default TournamentsLeaderBoardRanking;
