import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { General, LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { selectLanguage } from 'shared/common/features/settings/slice/settings.slice';
import { useAppSelector } from 'store';
import { Breakpoints } from 'theme/Theme';

export const useAuthBanner = (type: General.AuthBannerType) => {
  const language = useAppSelector(selectLanguage);

  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });

  const authBanner = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.AUTH_BANNER,
  });

  const banner = authBanner?.[type];
  const bannerPath = `${banner?.imagePath}${isDesktop ? 'desktop' : 'mobile'}-${language}.png`;

  const onClick = () => {
    if (!banner?.linkUrl) return;

    if (banner?.linkUrl?.startsWith('https')) {
      window.open(banner?.linkUrl, '_blank');
    } else {
      navigate(banner?.linkUrl);
    }
  };

  return { banner: authBanner?.[type], onClick, bannerPath };
};
