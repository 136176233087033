import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { hidePopOver, selectPopOver } from './slice/popOver.slice';
import { PopoverUI } from '../components';

export const Popover = () => {
  const dispatch = useAppDispatch();
  const modalProps = useAppSelector(selectPopOver.props);
  const navigate = useNavigate();

  const handleClickPopOverOutside = useCallback(() => {
    if (modalProps?.show) {
      dispatch(hidePopOver());
    }
  }, [dispatch, modalProps?.show]);

  const handleClickLink = useCallback(() => {
    if (isEmpty(modalProps)) return;
    const { alias, categoryAlias } = modalProps;

    navigate(`/promotions/${categoryAlias}/${alias}?tab=conditions`);
  }, [modalProps, navigate]);

  useEffect(() => {
    window.addEventListener('resize', handleClickPopOverOutside);
    !modalProps?.show && dispatch(hidePopOver());
    return () => {
      window.removeEventListener('resize', handleClickPopOverOutside);
      modalProps?.show && dispatch(hidePopOver());
    };
  }, [modalProps, dispatch]);

  if (isEmpty(modalProps)) return null;

  return (
    <PopoverUI.Wrapper
      position={modalProps?.position}
      show={modalProps.show}
      left={modalProps.left ? modalProps.left : 0}
      top={modalProps.top}
      size={modalProps.size}
      showTriangle={modalProps.showTriangle}
      wrapperRadius={modalProps.wrapperRadius}
      padding={modalProps.padding}
      elementWidth={modalProps.elementWidth}
      handleClickPopOverOutside={handleClickPopOverOutside}
    >
      <Wrapper className="popover__wrapper">
        {modalProps.title && <PopoverUI.Header title={modalProps.title} />}
        {modalProps.descriptions && <PopoverUI.Description descriptions={modalProps.descriptions} />}
        {modalProps.linkText && <PopoverUI.Link linkText={modalProps.linkText} onClick={handleClickLink} />}
      </Wrapper>
    </PopoverUI.Wrapper>
  );
};

const Wrapper = styled.div`
  border-radius: 3px;
  padding: 14px 10px;
  font-size: 12px;
`;
