import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Icon from 'components/shared/Icon';
import NoDataMessage from 'components/shared/NoDataMessage';
import { PrizeDropTypes } from 'shared/common/features/PrizeDrop/types/prizeDrop.types';
import { isEmpty } from 'utils/common/helpersCommon';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import PrizeDropGiftItemsContainer from './PrizeDropGiftItemsContainer';
import PrizeDropTabsSecondary from './PrizeDropTabsSecondary';
import PrizeDropHTML from './PrizeDropTerms';
import { prizeDropCSSNS } from '../prizeDropUICSS.types';
// import { SCInnerShadow } from '../styled/PrizeDropInnerShadow.styled';

export const primaryTabs = [
  { name: Promotions.PrizeDrop.rewardsTab, icon: 'egtd-przd-gift' },
  { name: Promotions.PrizeDrop.winnersTab, icon: 'egtd-przd-podium' },
  { name: Promotions.PrizeDrop.termsTab, icon: 'egtd-przd-list' },
];

type Props = {
  campaignStatus?: PrizeDropTypes.StateStatus;
  personalRewards?: PrizeDropTypes.PersonalRewards[];
  rewards?: PrizeDropTypes.Rewards[];
  rewardedPlayers?: PrizeDropTypes.PersonalRewards[];
  terms?: PrizeDropTypes.Terms | null;
  iconResource?: string;
  isPopover?: boolean;
};

const PrizeDropTabsPrimary: React.FC<Props> = ({
  personalRewards = [],
  rewards = [],
  rewardedPlayers = [],
  terms,
  campaignStatus = 'idle',
  iconResource,
  isPopover = false,
}) => {
  const [tabPrimaryIndex, setTabPrimaryIndex] = useState(0);
  const { t } = useTranslation();

  const onSelectTabPrimaryIndex = useCallback((tabIdx: number) => {
    setTabPrimaryIndex(tabIdx);
  }, []);

  return (
    <Tabs
      selectedIndex={tabPrimaryIndex}
      onSelect={onSelectTabPrimaryIndex}
      className={`${prizeDropCSSNS}-tabs-primary`}
      focusTabOnClick={false}
      data-popover={isPopover}
    >
      <TabPanel
        className={`${prizeDropCSSNS}-panel`}
        selectedClassName={`${prizeDropCSSNS}-panel--selected ${prizeDropCSSNS}-inner-shadow`}
      >
        <PrizeDropGiftItemsContainer
          iconResource={iconResource}
          campaignStatus={campaignStatus}
          rewards={rewards}
          className="no-scrollbars overflow-y-auto overflow-x-hidden h-100"
        />
      </TabPanel>

      <TabPanel
        className={`${prizeDropCSSNS}-panel `}
        selectedClassName={`${prizeDropCSSNS}-panel--selected ${prizeDropCSSNS}-inner-shadow`}
      >
        <PrizeDropTabsSecondary
          campaignStatus={campaignStatus}
          personalRewards={personalRewards}
          rewardedPlayers={rewardedPlayers}
        />
      </TabPanel>

      <TabPanel
        className={`${prizeDropCSSNS}-panel `}
        selectedClassName={`${prizeDropCSSNS}-panel--selected ${prizeDropCSSNS}-inner-shadow`}
      >
        {isEmpty(terms) ? (
          campaignStatus !== 'idle' ? (
            <NoDataMessage message={t(Promotions.PrizeDrop.noInformation)} />
          ) : null
        ) : (
          <div className="overflow-x-hidden overflow-y-auto h-100 no-scrollbars">
            {terms.title && <div>{terms.title}</div>}
            {terms.text && <PrizeDropHTML text={terms.text} />}
          </div>
        )}
      </TabPanel>

      <TabList className={`${prizeDropCSSNS}-tabs-list-primary`}>
        {primaryTabs.map((data, index) => (
          <Tab
            key={`tab-${index}`}
            className={`${prizeDropCSSNS}-tab`}
            selectedClassName={`${prizeDropCSSNS}-tab--selected`}
          >
            <Icon icon={data.icon} />
            <>{t(data.name)}</>
          </Tab>
        ))}
        <span
          className={`${prizeDropCSSNS}-glider`}
          style={{ width: `calc((100% / ${primaryTabs.length}) - .33px)` }}
        ></span>
      </TabList>
    </Tabs>
  );
};

export default PrizeDropTabsPrimary;
