import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import './Header.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children: React.ReactNode;
}
const Header: React.FC<Props> = ({ className, children, ...rest }) => {
  const headerClass = clsx('px-2 gnf-header', className);
  return (
    <div className={headerClass} {...rest}>
      {children}
    </div>
  );
};

export default Header;
