import React, { createContext, useCallback, useContext, useRef, useState } from 'react';
import clsx from 'clsx';
import { Button } from 'modules/casino/shared/components/buttons';
import { ExpandableIcon, Icon } from 'modules/casino/shared/components/icons';
import { Flex } from 'modules/casino/shared/components/layout';
import { useOutsideClick } from 'modules/casino/shared/hooks/useOutsideClick';
import { Desktop, DesktopDown } from 'modules/casino/theme/Theme';

const SorterContext = createContext<{
  isOpen: boolean;
  onToggle: () => void;
} | null>(null);

interface SorterProps {
  componentLocation?: 'providerHeader' | 'breadcrumb';
  desktopText: string;
  className?: string;
  children?: React.ReactNode;
}

const Wrapper: React.FC<SorterProps> = ({
  className,
  // componentLocation = 'breadcrumb',
  desktopText,
  children,
  ...props
}) => {
  const sorterRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const value = {
    isOpen: isOpen,
    onToggle: onToggle,
  };

  const onOutsideClick = useCallback(() => {
    isOpen && setIsOpen(false);
  }, [isOpen, setIsOpen]);

  useOutsideClick(sorterRef, onOutsideClick);

  const wrapperClass = clsx('h-100 category-sorter', isOpen && 'category-sorter--open', className);
  const togglerIconClass = clsx(
    'category-sorter__dropdown-icon',
    isOpen && 'rotated-360 category-sorter__dropdown-icon--active',
  );

  return (
    <SorterContext.Provider value={value}>
      <div className={wrapperClass} {...props} ref={sorterRef}>
        <Button classNames="d-flex-ac h-100 category-sorter__dropdown" onClickHandler={onToggle}>
          <DesktopDown>
            <Icon className={togglerIconClass} icon="filter" />
          </DesktopDown>
          <Desktop>
            {desktopText}
            <ExpandableIcon className="ml-1" isExpanded={isOpen} />
          </Desktop>
        </Button>
        {isOpen && <Flex.Col className="category-sorter__menu">{children}</Flex.Col>}
        {/* {isOpen && <Flex.Col className="dropdown-content dropdown-content-right">{children}</Flex.Col>} */}
      </div>
    </SorterContext.Provider>
  );
};

interface SortItemProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: React.MouseEventHandler;
  dataClass?: string;
  dataSort?: number;
  isActive: boolean;
  text: string;
  hideItem?: boolean;
}

const MenuItem: React.FC<SortItemProps> = ({ onClick, dataClass, dataSort, isActive, text, hideItem, ...props }) => {
  const context = useContext(SorterContext);

  const handleClick = (e: React.MouseEvent) => {
    context?.onToggle();
    onClick(e);
  };

  if (hideItem) {
    return <></>;
  }

  const itemClass = clsx('category-sorter__item', isActive && 'category-sorter__item--active');
  // const itemClass = clsx('dropdown-item', isActive && 'dropdown-item--active');
  return (
    <button {...props} onClick={handleClick} data-class={dataClass} data-sort={dataSort} className={itemClass}>
      {text}
    </button>
  );
};

export const CategoryFilterUI = { Wrapper, MenuItem };
