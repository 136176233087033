import React, { memo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { AppHeader } from 'utils/common/translationUtils/translationStrings';
import useNavItemConfig from '../../hooks/useNavItemConfig';

const NavUser: React.FC = () => {
  const { t } = useTranslation();
  const login = useNavItemConfig('signIn');
  const register = useNavItemConfig('registration');

  const isReverseOrder = register?.buttonOrder > login?.buttonOrder;

  const regBtnClass = clsx('my-auto mnav__link mnav__reg-btn');

  const loginBtnClass = clsx('text-center mnav__login-btn');

  return (
    <Flex.JE className="nav-user">
      {/* {register?.isButtonVisible && ( */}
      <div
        className={regBtnClass}
        style={{ order: isReverseOrder ? 1 : 0 }}
        onClick={register?.onClick}
        data-qid="nav-reg-btn"
      >
        {t(AppHeader.NavUser.registerBtn)}
      </div>
      {/* )} */}
      {login?.isButtonVisible && (
        <div className={loginBtnClass} onClick={login?.onClick} data-qid="nav-login-btn">
          {t(AppHeader.NavUser.loginBtn)}
        </div>
      )}
    </Flex.JE>
  );
};

export default memo(NavUser);
