import { RefObject, useEffect, useRef, useState } from 'react';
import useWindowSize from 'hooks/common/useWindowSize';
import { cdnConfig, fitCover } from 'utils/common/cdnConfig';
import config from 'utils/config';

type ReturnHookType = { imgSource: string; cellRef: RefObject<HTMLDivElement> | null };

export const usePromotionImgResource = (resource: string, fit?: 'none' | 'contain' | 'cover'): ReturnHookType => {
  const [imgSource, setImgSource] = useState('');
  const windowSize = useWindowSize();
  const cellRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cellRef.current) {
      const width = cellRef.current.clientWidth;
      const height = cellRef.current.clientHeight;
      resource &&
        setImgSource(
          `${config.CDN_IMAGE_API}${fitCover(width * 2, height * 2, fit)}${
            cdnConfig.PROMOTIONS_MODULE
          }/${encodeURIComponent(resource).replace('jpeg', 'jpg')}`,
        );
    }
  }, [windowSize, resource]);

  return { imgSource, cellRef };
};
