import { CancelTokenSource } from 'axios';
import { CasinoGamesTypes } from 'modules/casino/modules/container/cellsContainer/cellls/types/casinoGamesTypes';
import {
  getRecommendationCategoryCustomGameSize,
  getSpecialGamesData,
  normalizeGames,
} from 'modules/casino/modules/container/cellsContainer/cellls/utils/casinoGamesUtils';
import axiosInstance from 'modules/casino/shared/utils/common/axios-instance';
import { getBusinessUnit, getOSType, sortByProperty } from 'modules/casino/shared/utils/common/helpersCommon';
import config from 'utils/config';
import { getProvidersParameter } from '../../../../../shared/features/casinoSearch/casinoSearchUtils';
import { EXCLUDED_PROVIDER_IDS } from '../constants/casinoGamesConstants';

export const fetchSearchBy = async (params: CasinoGamesTypes.FetchCategoryGamesParams, source: CancelTokenSource) => {
  const { collectionId, categoryParams, page, size, searchTagId, hasNumbers, mobileAppRestrictions } = params;
  const searchParams = searchTagId && searchTagId !== '' ? '&searchTags='.concat(searchTagId) : '';
  const query = mobileAppRestrictions.isRestricted ? mobileAppRestrictions.modifiedQuery() : categoryParams.query;
  const gamesFetchURL = `${config.API_URL}${query}&page=${page}&size=${size}${searchParams}`;

  const response = await axiosInstance.get<CasinoGamesTypes.PageableResult<CasinoGamesTypes.GameProps>>(gamesFetchURL, {
    data: null,
    headers: { 'X-Platform-OS-type': getOSType() },
    cancelToken: source.token,
  });

  const gameData = mobileAppRestrictions.isRestricted
    ? mobileAppRestrictions.filterByGameIds(response.data.content)
    : response.data.content;

  const sortedGames = sortByProperty(gameData, 'rank');

  const games = getSpecialGamesData({
    gameSection: categoryParams.gameSection,
    games: sortedGames,
  });

  return {
    games: normalizeGames({
      games,
      subType: categoryParams.subType,
      categoryLayoutType: categoryParams.categoryLayoutType,
      layoutGrid: categoryParams.layoutGrid,
      gridType: categoryParams.gridType,
      layoutRows: categoryParams.layoutRows,
      queryType: categoryParams.queryType,
      hasNumbers,
    }),
    collectionId: collectionId,
    totalPages: response.data.totalPages,
    totalElements: mobileAppRestrictions.isRestricted
      ? gameData.length
      : categoryParams.gameSection?.type
        ? response.data.totalElements + 1
        : response.data.totalElements,
    currentPage: response.data.number,
    searchTag: '',
    last: response.data.last,
    categoryId: categoryParams.categoryId,
    queryType: categoryParams.queryType,
    hasNumbers,
  };
};

export const fetchSearchByLive = async (
  params: CasinoGamesTypes.FetchCategoryGamesParams,
  source: CancelTokenSource,
) => {
  const { collectionId, categoryParams, page, size, searchTagId, hasNumbers, mobileAppRestrictions } = params;
  const searchParams = searchTagId && searchTagId !== '' ? '&searchTags='.concat(searchTagId) : '';
  // const query = mobileAppRestrictions.isRestricted ? mobileAppRestrictions.modifiedQuery : categoryParams.query;
  const gamesFetchURL = `${config.API_URL}${categoryParams.query}&page=${page}&size=${size}${searchParams}`;
  const response = await axiosInstance.get<CasinoGamesTypes.PageableResult<CasinoGamesTypes.GameProps>>(gamesFetchURL, {
    data: null,
    cancelToken: source.token,
  });

  const gameData = mobileAppRestrictions.isRestricted
    ? mobileAppRestrictions.filterByGameIds(response.data.content)
    : response.data.content;

  const sortedGames = sortByProperty(gameData, 'rank');

  return {
    games: normalizeGames({
      games: sortedGames,
      subType: categoryParams.subType,
      categoryLayoutType: categoryParams.categoryLayoutType,
      layoutGrid: categoryParams.layoutGrid,
      gridType: categoryParams.gridType,
      layoutRows: categoryParams.layoutRows,
      queryType: categoryParams.queryType,
      hasNumbers,
    }),
    collectionId: collectionId,
    totalPages: response.data.totalPages,
    totalElements: mobileAppRestrictions.isRestricted ? gameData.length : response.data.totalElements,
    currentPage: response.data.number,
    searchTag: '',
    last: response.data.last,
    categoryId: categoryParams.categoryId,
    queryType: categoryParams.queryType,
    hasNumbers,
  };
};

export const fetchMostPlayed = async (params: CasinoGamesTypes.FetchCategoryGamesParams, source: CancelTokenSource) => {
  const { collectionId, categoryParams, size, hasNumbers, mobileAppRestrictions } = params;

  if (!categoryParams.query) return;
  const query = mobileAppRestrictions.isRestricted ? mobileAppRestrictions.modifiedQuery() : categoryParams.query;

  const gamesFetchURL = `${config.API_URL}${query}`;
  const response = await axiosInstance.get<CasinoGamesTypes.GameProps[]>(gamesFetchURL, {
    data: null,
    cancelToken: source.token,
  });

  const gameData = mobileAppRestrictions.isRestricted
    ? mobileAppRestrictions.filterByGameIds(response.data)
    : response.data;

  const normalizedGames = normalizeGames({
    games: gameData,
    subType: categoryParams.subType,
    categoryLayoutType: categoryParams.categoryLayoutType,
    layoutGrid: categoryParams.layoutGrid,
    gridType: categoryParams.gridType,
    layoutRows: categoryParams.layoutRows,
    queryType: categoryParams.queryType,
    hasNumbers,
  });

  const gamesDataByGridLayout = hasNumbers && size ? normalizedGames.slice(0, size) : normalizedGames;

  return {
    games: gamesDataByGridLayout,
    collectionId,
    totalPages: 1,
    totalElements: mobileAppRestrictions.isRestricted ? gameData.length : response.data.length,
    currentPage: 0,
    searchTag: '',
    last: true,
    categoryId: categoryParams.categoryId,
    queryType: categoryParams.queryType,
    hasNumbers,
  };
};

export const fetchBonusFreeSpinGames = async (
  params: CasinoGamesTypes.FetchCategoryGamesParams,
  source: CancelTokenSource,
) => {
  const { collectionId, categoryParams, hasNumbers } = params;
  const fetchURL = `${
    config.API_URL
  }/api/gaming/public/bonus/gameIds?workingBusinessUnit=${getBusinessUnit()}&gameIds=${categoryParams.additionalParams.join(
    ',',
  )}`;

  const response = await axiosInstance.get<CasinoGamesTypes.GameProps[]>(fetchURL, {
    cancelToken: source.token,
  });

  const sortedGames = sortByProperty(response.data, 'rank');

  return {
    games: normalizeGames({
      games: sortedGames,
      subType: categoryParams.subType,
      categoryLayoutType: categoryParams.categoryLayoutType,
      layoutGrid: categoryParams.layoutGrid,
      gridType: categoryParams.gridType,
      layoutRows: categoryParams.layoutRows,
      queryType: categoryParams.queryType,
      hasNumbers,
    }),
    collectionId: collectionId,
    totalPages: 1,
    totalElements: response.data.length,
    currentPage: 1,
    searchTag: '',
    last: true,
    categoryId: categoryParams.categoryId,
    queryType: categoryParams.queryType,
    hasNumbers,
  };
};

export const fetchLastPlayed = async (params: CasinoGamesTypes.FetchCategoryGamesParams, source: CancelTokenSource) => {
  const { collectionId, categoryParams, hasNumbers, mobileAppRestrictions } = params;

  if (!categoryParams.query) return;
  const query = mobileAppRestrictions.isRestricted ? mobileAppRestrictions.modifiedQuery() : categoryParams.query;

  const gamesFetchURL = `${config.API_URL}${query}`;
  const response = await axiosInstance.get<CasinoGamesTypes.GameProps[]>(gamesFetchURL, {
    data: null,
    cancelToken: source.token,
  });

  const gameData = mobileAppRestrictions.isRestricted
    ? mobileAppRestrictions.filterByGameIds(response.data)
    : response.data;

  return {
    games: normalizeGames({
      games: gameData,
      subType: categoryParams.subType,
      categoryLayoutType: categoryParams.categoryLayoutType,
      layoutGrid: categoryParams.layoutGrid,
      gridType: categoryParams.gridType,
      layoutRows: categoryParams.layoutRows,
      queryType: categoryParams.queryType,
      hasNumbers,
    }),
    collectionId,
    totalPages: 1,
    totalElements: mobileAppRestrictions.isRestricted ? gameData.length : response.data.length,
    currentPage: 0,
    searchTag: '',
    last: true,
    categoryId: categoryParams.categoryId,
    queryType: categoryParams.queryType,
    hasNumbers,
  };
};

export const fetchRecommendationsCategory = async (
  params: CasinoGamesTypes.FetchCategoryGamesParams,
  source: CancelTokenSource,
) => {
  const { collectionId, categoryParams, size, hasNumbers, mobileAppRestrictions } = params;
  const { query, playerId } = categoryParams || {};

  if (!query) {
    return;
  }
  const urlWithBU = `${config.API_URL}${query}&workingBusinessUnit=${getBusinessUnit()}`;
  const gamesFetchURL = playerId ? `${urlWithBU}&playerId=${playerId}` : urlWithBU;

  const newQuery = mobileAppRestrictions.isRestricted ? mobileAppRestrictions.modifiedQuery() : gamesFetchURL;

  const response = await axiosInstance.get<CasinoGamesTypes.GameProps[]>(newQuery, {
    data: null,
    cancelToken: source.token,
  });

  const games = getSpecialGamesData({
    gameSection: categoryParams.gameSection,
    games: response.data,
  });

  const normalizedGames = normalizeGames({
    games,
    subType: categoryParams.subType,
    categoryLayoutType: categoryParams.categoryLayoutType,
    layoutGrid: categoryParams.layoutGrid,
    gridType: categoryParams.gridType,
    layoutRows: categoryParams.layoutRows,
    queryType: categoryParams.queryType,
    hasNumbers,
  });

  const customGameSize = getRecommendationCategoryCustomGameSize({
    size,
    games: normalizedGames,
    gridType: categoryParams.gridType,
    hasNumbers,
  });

  return {
    games: customGameSize,
    collectionId,
    totalPages: 1,
    totalElements: response.data?.length,
    currentPage: 0,
    searchTag: '',
    last: true,
    categoryId: categoryParams.categoryId,
    queryType: categoryParams.queryType,
    hasNumbers,
  };
};

export const fetchSvara = async (
  params: CasinoGamesTypes.FetchCategoryGamesParams,
  source: CancelTokenSource,
  isAuthenticated: boolean,
) => {
  const { collectionId, categoryParams, hasNumbers } = params;
  const apiType = isAuthenticated ? 'player' : 'public';
  const gamesFetchURL = `${config.API_URL}/api/gaming/${apiType}/svara-dynamic-layout`;
  const response = await axiosInstance.get<CasinoGamesTypes.GameProps[]>(gamesFetchURL, {
    data: null,
    cancelToken: source.token,
  });
  const filterGames = response.data
    .filter((item) => !item.customFreePlay)
    .sort((a, b) => (a.isPermanent === b.isPermanent ? 0 : a.isPermanent ? -1 : 1));

  return {
    games: normalizeGames({
      games: filterGames,
      subType: categoryParams.subType,
      categoryLayoutType: categoryParams.categoryLayoutType,
      layoutGrid: categoryParams.layoutGrid,
      gridType: categoryParams.gridType,
      layoutRows: categoryParams.layoutRows,
      queryType: categoryParams.queryType,
      hasNumbers,
    }),
    collectionId: collectionId,
    searchTag: '',
    categoryId: categoryParams.categoryId,
    queryType: categoryParams.queryType,
    totalElements: filterGames?.length,
    hasNumbers,
  };
};

export const fetchCasinoGamesByIds = async (
  params: CasinoGamesTypes.FetchCategoryGamesParams,
  source: CancelTokenSource,
) => {
  const { collectionId, categoryParams, hasNumbers, mobileAppRestrictions } = params;
  const gamesFetchURL = `${
    config.API_URL
  }/api/gaming/public/bonus/gameIds?workingBusinessUnit=${getBusinessUnit()}&gameIds=${params.categoryParams.additionalParams.join(',')}`;
  const query = mobileAppRestrictions.isRestricted ? mobileAppRestrictions.modifiedQuery() : gamesFetchURL;

  const response = await axiosInstance.get<CasinoGamesTypes.GameProps[]>(query, {
    data: null,
    cancelToken: source.token,
  });

  const gameData = mobileAppRestrictions.isRestricted
    ? mobileAppRestrictions.filterByGameIds(response.data)
    : response.data;

  return {
    games: normalizeGames({
      games: gameData,
      subType: categoryParams.subType,
      categoryLayoutType: categoryParams.categoryLayoutType,
      layoutGrid: categoryParams.layoutGrid,
      gridType: categoryParams.gridType,
      layoutRows: categoryParams.layoutRows,
      queryType: categoryParams.queryType,
      hasNumbers,
    }),
    collectionId: collectionId,
    searchTag: '',
    categoryId: categoryParams.categoryId,
    queryType: categoryParams.queryType,
    totalElements: mobileAppRestrictions.isRestricted ? gameData.length : response.data?.length,
  };
};

export const searchResults = async (params: CasinoGamesTypes.FetchCategoryGamesParams, source: CancelTokenSource) => {
  const { collectionId, categoryParams, hasNumbers, mobileAppRestrictions } = params;
  const query = '/api/gaming/public/games/searchByTitle';
  const filterProviders = mobileAppRestrictions.isRestricted
    ? mobileAppRestrictions.allowProvidersIds
    : params.categoryParams.filteredProviders;

  const resultsFetchURL = `${query}?title=${params.categoryParams.searchParams}${getProvidersParameter(filterProviders)}&excludeProviderIds=${EXCLUDED_PROVIDER_IDS}`;

  const fetchUrl = `${config.API_URL}${resultsFetchURL}`;

  const response = await axiosInstance.get<CasinoGamesTypes.GameProps[]>(fetchUrl, {
    data: null,
    cancelToken: source.token,
  });

  const gameData = mobileAppRestrictions.isRestricted
    ? mobileAppRestrictions.filterByGameIds(response.data)
    : response.data;

  const sortedGames = sortByProperty(gameData, 'searchRank');

  return {
    games: normalizeGames({
      games: sortedGames,
      subType: categoryParams.subType,
      categoryLayoutType: categoryParams.categoryLayoutType,
      layoutGrid: categoryParams.layoutGrid,
      gridType: categoryParams.gridType,
      layoutRows: categoryParams.layoutRows,
      queryType: categoryParams.queryType,
      hasNumbers,
    }),
    collectionId: collectionId,
    searchTag: '',
    categoryId: categoryParams.categoryId,
    queryType: categoryParams.queryType,
    totalElements: mobileAppRestrictions.isRestricted ? gameData.length : response.data?.length,
    hasNumbers,
  };
};
