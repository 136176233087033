import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChallengesCell } from 'pages/captainUp/features/Challenges/components';
import { useGamificationConfig } from 'pages/captainUp/hooks/useGamificationConfig';
import { showMyProfileModal } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { selectMyChallenges } from '../../../slice/clubTab.slice';

const route = {
  challenges: '/challenges/all',
};

const ClubChallengeItem = ({ id, index, itemKey }) => {
  const dispatch = useAppDispatch();
  const challenge = useAppSelector(selectMyChallenges.getChallengeById(id));

  const { route: gamificationRoute } = useGamificationConfig();
  const navigate = useNavigate();
  const closeMyProfile = useCallback(() => {
    navigate(`${gamificationRoute}${route[itemKey]}`);
    dispatch(showMyProfileModal(false));
  }, [dispatch, itemKey, gamificationRoute, navigate]);
  const categories = challenge.categories[0];
  if (!challenge || !categories) return null;

  return (
    <ChallengesCell
      key={`badge-${id}`}
      id={id}
      order={index}
      collections={`myAccount_${categories.alias}`}
      type={`horizontal-my-profile`}
      typeTap={`/${categories.alias}`}
      alias={categories.alias}
      categoryId={categories.id}
      selectorType="myAccount"
      handleClick={closeMyProfile}
      hideTitle={categories.hideTitle}
    />
  );
};

export default memo(ClubChallengeItem);
