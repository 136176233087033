import { useEffect, useRef } from 'react';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { actionButtonEnums } from '../../cashierModalUI/actionButtonsMock';

const Countdown = ({ seconds, setSeconds, setActionButtonClicked, activeButton, setActiveButtons }) => {
  const isMagnumBetRo = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.IS_MAGNUM_BET_RO,
    deviceLayout: 'general',
  });
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (seconds > 0) {
      intervalRef.current = setInterval(() => {
        setSeconds((inSeconds) => inSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (isMagnumBetRo && activeButton === actionButtonEnums.payTicketButton) {
        setActionButtonClicked(null);
        setActiveButtons([]);
      } else {
        setSeconds(0);
        setActionButtonClicked(null);
      }
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [seconds, setSeconds, setActionButtonClicked, isMagnumBetRo, activeButton, setActiveButtons]);
  return (
    <div className="app">
      <div className="time ml-2">{`${seconds > 0 ? '(' + '00:0' + seconds + ')' : ''}`}</div>
    </div>
  );
};

export default Countdown;
