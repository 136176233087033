import { useEffect } from 'react';
import { fetchCasinoLiveData } from 'modules/casino/modules/container/cellsContainer/cellls/slice/casinoGamesSlice';
import { useAppDispatch } from 'store';
import { CasinoGamesTypes } from '../types/casinoGamesTypes';
import { getLiveFetchGameIds } from '../utils/casinoGamesUtils';

let intervalId;
let liveGameIds: string[] = [];

const TIMER = 20000;

interface Props {
  games: CasinoGamesTypes.NormalizedGames;
  interval: number;
  hasSuccess: boolean;
  isSvara: boolean;
}

const FetchLiveFeed: React.FC<Props> = ({ games, interval, hasSuccess, isSvara }) => {
  const appDispatch = useAppDispatch();
  const fallbackInterval = interval !== undefined ? interval : TIMER;
  const location = window.location;

  useEffect(() => {
    if (hasSuccess) {
      liveGameIds = getLiveFetchGameIds({ games, cachedLiveGameIds: liveGameIds });

      if (liveGameIds.length) {
        if (intervalId) {
          clearInterval(intervalId);
        }

        intervalId = setInterval(async () => {
          appDispatch(fetchCasinoLiveData({ liveGameIds: [...liveGameIds], isSvara }));
        }, fallbackInterval);
      }
    }
  }, [games, intervalId, isSvara]);

  useEffect(() => {
    return () => {
      if (liveGameIds?.length && intervalId) {
        clearInterval(intervalId);
        liveGameIds = [];
      }
    };
  }, [location.pathname]);

  return null;
};

export default FetchLiveFeed;
