import { useCallback, useEffect, useState } from 'react';

const Clock = () => {
  const [time, setTime] = useState(new Date());

  const refreshClock = useCallback(() => {
    setTime(new Date());
  }, [setTime]);

  useEffect(() => {
    const timerID = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  }, [refreshClock]);

  return <>{time.toLocaleTimeString()}</>;
};

export default Clock;
