// Responsive Breakpoints
import React, { memo } from 'react';
import isEqual from 'lodash/isEqual';
import { useMediaQuery } from 'react-responsive';
import usePreferredTheme from '../shared/hooks/usePreferredTheme';

export const ThemedIcon = () => {
  const { preferredTheme } = usePreferredTheme();

  return preferredTheme === 'Light' ? '-light-bg' : '';
};

export const Breakpoints = {
  isMobile: 768,
  isTabletOrMobile: 1024,
  isDesktop: 1025,
  isDesktopLarge: 1366,
  isMobileMini: 360,
  isDesktopExtraLarge: 1599,
  isMobileMiniDown: 359,
  isDesktopUXL: 1800,
} as const;

interface Props {
  children: React.ReactNode;
}

// Mobile Devies Only
const Mobile: React.FC<Props> = memo(({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? <>{children}</> : null;
}, isEqual);
Mobile.displayName = 'Mobile';

const Mobile768: React.FC<Props> = memo(({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return isMobile ? <>{children}</> : null;
}, isEqual);
Mobile768.displayName = 'Mobile768';

// Small Tablets in Portrait Mode Only
const Tablet: React.FC<Props> = memo(({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  return isTablet ? <>{children}</> : null;
}, isEqual);
Tablet.displayName = 'Tablet';

// Small Tablets in Portrait Mode and Up
const TabletUp: React.FC<Props> = memo(({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768 });
  return isTablet ? <>{children}</> : null;
}, isEqual);
TabletUp.displayName = 'TabletUp';

const DesktopDown: React.FC<Props> = memo(({ children }) => {
  const isDesktop = useMediaQuery({ maxWidth: 1024 });
  return isDesktop ? <>{children}</> : null;
}, isEqual);
DesktopDown.displayName = 'DesktopDown';

const Desktop: React.FC<Props> = memo(({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1025 });
  return isDesktop ? <>{children}</> : null;
}, isEqual);
Desktop.displayName = 'Desktop';

const DesktopUp: React.FC<Props> = memo(({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return isDesktop ? <>{children}</> : null;
}, isEqual);
DesktopUp.displayName = 'DesktopUp';

const DesktopLargeDown: React.FC<Props> = memo(({ children }) => {
  const isDesktopLargeDown = useMediaQuery({ maxWidth: 1365 });
  return isDesktopLargeDown ? <>{children}</> : null;
}, isEqual);
DesktopLargeDown.displayName = 'DesktopLargeDown';

const DesktopLarge: React.FC<Props> = memo(({ children }) => {
  const isDesktopLarge = useMediaQuery({ minWidth: 1366 });
  return isDesktopLarge ? <>{children}</> : null;
}, isEqual);
DesktopLarge.displayName = 'DesktopLarge';

const DesktopExtraLargeDown: React.FC<Props> = memo(({ children }) => {
  const isDesktopExtraLarge = useMediaQuery({ maxWidth: 1599 });
  return isDesktopExtraLarge ? <>{children}</> : null;
}, isEqual);
DesktopExtraLargeDown.displayName = 'DesktopExtraLargeDown';

const DesktopExtraLarge: React.FC<Props> = memo(({ children }) => {
  const isDesktopExtraLarge = useMediaQuery({ minWidth: 1600 });
  return isDesktopExtraLarge ? <>{children}</> : null;
}, isEqual);
DesktopExtraLarge.displayName = 'DesktopExtraLarge';

const MobileMini: React.FC<Props> = memo(({ children }) => {
  const isMobileMini = useMediaQuery({ maxWidth: 360 });
  return isMobileMini ? <>{children}</> : null;
}, isEqual);
MobileMini.displayName = 'MobileMini';

const MobileMiniDown: React.FC<Props> = memo(({ children }) => {
  const isMobileMiniDwon = useMediaQuery({ maxWidth: 360 });
  return isMobileMiniDwon ? <>{children}</> : null;
}, isEqual);
MobileMiniDown.displayName = 'MobileMiniDown';

const MobileMiniUp: React.FC<Props> = memo(({ children }) => {
  const isMobileMiniUp = useMediaQuery({ minWidth: 361 });
  return isMobileMiniUp ? <>{children}</> : null;
}, isEqual);
MobileMiniUp.displayName = 'MobileMiniUp';

export {
  Mobile,
  Mobile768,
  Tablet,
  TabletUp,
  DesktopDown,
  Desktop,
  DesktopUp,
  DesktopLargeDown,
  DesktopLarge,
  DesktopExtraLargeDown,
  DesktopExtraLarge,
  MobileMini,
  MobileMiniDown,
  MobileMiniUp,
};

export const Theme = {
  icons: {
    ui: '/assets/img/icons/ui/sprites.svg',
    liveCasinoGames: `/assets/img/live-casino/games/sprites.svg?v=${window.config.EPOCH}`,
    // promo: '/assets/img/icons/promo-module/sprites.svg',
    providers: '/assets/img/icons/providers/sprites.svg',
  },
};
