import React, { useRef, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Keyboard from 'react-simple-keyboard';
import { useAppDispatch } from 'store';
import { SSBT } from 'utils/common/translationUtils/translationStrings';
import { TerminalModalUI } from '../../../components/ssbtModalUI';
// import { terminalThunks } from '../../slice/ssbt.slice';
import CustomCheckButton from '../../../components/ssbtModalUI/CustomCheckButton';
import { TerminalModalActionIcons } from '../../../types/ssbt.types';
import { handleOnSubmitLocal } from '../../../utils/ssbt.utils';

type Props = {
  handleSubmit: () => void;
};

const onChangeAll = (input: string, setInputValue: React.Dispatch<React.SetStateAction<string>>) => {
  setInputValue(input);
};

const FirstStepTicketModal: React.FC<Props> = ({ handleSubmit }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const [inputValue, setInputValue] = useState('');

  const handleButtonClick = () => {
    if (formRef.current) {
      const submitEvent = new Event('submit', { bubbles: true, cancelable: true });
      formRef.current.dispatchEvent(submitEvent);
    }
  };

  return (
    <>
      <TerminalModalUI.Body>
        <TerminalModalUI.ActionIcon icon={TerminalModalActionIcons.QR} />
        <TerminalModalUI.Header className="mb-0" text={`${t(SSBT.TerminalModal.checkTicketTextModal)}`} />
        {/* TODO: When implementation is completed, insert className="d-none" to form below */}
        {/* TODO: form is placed for testing purposes, when testing is completed, remove form! */}
        <form
          className={'d-flex-ac mt-3'}
          onSubmit={(e) => handleOnSubmitLocal(e, dispatch, formRef, handleSubmit)}
          ref={formRef}
        >
          <TerminalModalUI.Input
            id={'ticketId'}
            inputMode={'text'}
            value={inputValue}
            placeholder={`${t(SSBT.TerminalModal.checkTicketTextModalPlaceHolder)}`}
            wrapperClassName={'custom-check-input'}
            onChange={(e) => setInputValue(e.target.value)} // Update state on input change
          />
          <CustomCheckButton text="Check" onClick={handleButtonClick} />
        </form>
      </TerminalModalUI.Body>
      <Keyboard className="span-all-cols" onChange={(input) => onChangeAll(input, setInputValue)} />
    </>
  );
};

export default memo(FirstStepTicketModal);
