import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { partialModalTypes } from 'shared/common/features/partialModal/types/partialModal.types';
import { RootState } from 'store/rootReducer';

/* eslint-disable @typescript-eslint/no-explicit-any */
type RootModalState = {
  modalType: partialModalTypes | null;
  modalProps: any | null;
};

const initialState: RootModalState = {
  modalType: null,
  modalProps: {},
};

const contentModal = createSlice({
  name: 'contentModal',
  initialState,
  reducers: {
    showContentModal(state, action: PayloadAction<{ modalType: partialModalTypes; modalProps?: any }>) {
      state.modalType = action.payload.modalType;
      state.modalProps = action.payload.modalProps;
    },
    hideContentModal(state) {
      state.modalType = null;
      state.modalProps = null;
    },
  },
});

export const { showContentModal, hideContentModal } = contentModal.actions;

export default contentModal.reducer;

export const selectPartialtModalType = (state: RootState): partialModalTypes | null =>
  state.common.contentModal.modalType;
export const selectPartialtModalProps = (state: RootState) => state.common.contentModal.modalProps;
