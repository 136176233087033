import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CasinoGameProviders from 'modules/casino/modules/container/shared/features/casinoGameProviders';
import {
  addFilteredProviderId,
  gameProvidersSelectors,
  setIsToggled,
} from 'modules/casino/modules/container/shared/features/casinoGameProviders/slice/casinoGameProvidersSlice';
import { useGridLayoutSettings } from 'modules/casino/shared/components/gridLayoutSettings/hooks/useGridLayoutSettings';
import { casinoLaunchSelectors } from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import { useAppDispatch, useAppSelector } from 'store';
import { casinoSearchSelectors, setHeaderSearchIsActive } from './casinoSearchSlice';
import SearchBar from './components/SearchBar';
import SearchGridWrapper from './components/SearchGridWrapper';
import { useCasinoSearch } from './hooks/useCasinoSearch';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from '../../../modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import CasinoLoader from '../../components/casinoLoader/CasinoLoader';
import { SearchMessage } from '../../components/searchMessage';
import { MessageVariant } from '../../components/searchMessage/SearchMessageConstants';
import { useQuery } from '../../hooks/useQuery';

const CasinoSearch: React.FC = () => {
  const { searcherCategoryId, mostSearchCategoryId, searchValue } = useCasinoSearch();
  const { enableFilterByProvider } = useGridLayoutSettings();
  const dispatch = useAppDispatch();
  const { query } = useQuery();
  const categorySearchIsActive = useAppSelector(casinoSearchSelectors.categorySearchIsActive);
  const providerSearchIsActive = useAppSelector(casinoSearchSelectors.providerSearchIsActive);
  const allFilteredProviders = useAppSelector(gameProvidersSelectors.casinoAllFilteredProviderIds);
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const { t } = useTranslation();
  const launchInProcess = useAppSelector(casinoLaunchSelectors.launchInProcess);

  useEffect(() => {
    const filteredProviderIds = query.get('filter')?.split(',') || [];
    dispatch(setIsToggled(true));
    !categorySearchIsActive && !providerSearchIsActive && dispatch(setHeaderSearchIsActive(true));
    dispatch(addFilteredProviderId(filteredProviderIds));
  }, [dispatch, categorySearchIsActive, providerSearchIsActive]);

  useEffect(() => {
    if (providerSearchIsActive || categorySearchIsActive) return;
    const filterParam = `${allFilteredProviders.join(',')}`;
    params.delete('query');
    params.delete('filter');

    if (searchValue && !launchInProcess) {
      params.append('query', searchValue);
    }
    if (filterParam && !launchInProcess) {
      params.append('filter', filterParam);
    }

    url.search = params.toString();
    window.history.replaceState({}, '', url);
  }, [allFilteredProviders, searchValue, providerSearchIsActive, categorySearchIsActive]);

  const renderComponents = () => {
    if (mostSearchCategoryId && searchValue.length === 0) {
      return (
        <SearchGridWrapper
          categoryLayoutType={CategoryLayoutTypes.MOST_SEARCHED_CATEGORY}
          collectionId={CategorySubTypes.MOST_SEARCHED}
          subType={launchInProcess ? CategorySubTypes.IN_GAME_MOST_SEARCHED_RESULTS : CategorySubTypes.MOST_SEARCHED}
          categoryId={mostSearchCategoryId}
        />
      );
    }

    if (searchValue.length <= 2) {
      const renderMessage = ({ variant, message }) => <SearchMessage variant={variant} message={message} />;
      return renderMessage({ variant: MessageVariant.error, message: t('CasinoSearch.Message.noInput') });
    }

    if (searchValue.length > 1 && searcherCategoryId) {
      return (
        <SearchGridWrapper
          categoryLayoutType={CategoryLayoutTypes.SEARCH_RESULTS_CATEGORY}
          collectionId={`${searcherCategoryId}_${searchValue}`}
          categoryId={searcherCategoryId}
          subType={launchInProcess ? CategorySubTypes.IN_GAME_SEARCHED_RESULTS : CategorySubTypes.RESULT_SEARCHED}
          searchValue={searchValue}
        />
      );
    }
    return <CasinoLoader />;
  };

  return (
    <div className="casino-search-container w-100">
      <SearchBar />
      {enableFilterByProvider && !launchInProcess && <CasinoGameProviders filterFunctionality />}
      <div>{renderComponents()}</div>
    </div>
  );
};

export default CasinoSearch;
