const boxVariant = {
  visible: {
    transition: {
      // when: 'afterChildren',
      staggerChildren: 0.2,
    },
  },
};

const listVariant = {
  hidden: {
    scale: 0.5,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
  },
};
const btnVariant = {
  hidden: {
    y: 100,
  },
  visible: {
    y: -10,
  },
};

export const createBonusItemAnimation = {
  wrapper: boxVariant,
  item: listVariant,
  btn: btnVariant,
};

export const findIndexByValue = (obj: Record<string, string>, value: string): number => {
  const values = Object.keys(obj);
  return values.indexOf(value);
};

export const getValueByIndex = <T>(obj: Record<string, T>, index: number): string | undefined => {
  const keys = Object.keys(obj);
  const key = keys[index];
  return key !== undefined ? key : undefined;
};

export const changeArrayElement = <T>(arr: T[], index: number, newValue: T): T[] => {
  if (arr[index]) {
    if (index >= 0 && index < arr.length) {
      arr[index] = newValue;
      removeItemsAfterIndex(arr, index);
    }
  } else {
    arr = [...arr, newValue];
  }
  return arr;
};

export const removeItemsObjAfterIndex = (obj: Record<string, string>, index: number): Record<string, string> => {
  const entries = Object.entries(obj).slice(0, index);
  return Object.fromEntries(entries);
};

export const removeItemsAfterIndex = <T>(arr: T[], index: number): T[] => {
  return arr.slice(0, index + 1); // Slices up to and including the specified index
};
