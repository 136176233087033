import { ReactElement } from 'react';
import clsx from 'clsx';

type CurrencySwapperProps = {
  sumComponent: ReactElement;
  currencyComponent: ReactElement;
  currencySymbolFirst: boolean;
  spaceBetween: boolean;
};

const CurrencySwapper: React.FC<CurrencySwapperProps> = ({
  sumComponent,
  currencyComponent,
  currencySymbolFirst,
  spaceBetween,
}) => {
  const className = clsx('egtd-c-invert-currency', spaceBetween && 'egtd-c-invert-currency--spacer');

  return (
    <>
      {currencySymbolFirst ? (
        <>
          <span className={className}>{currencyComponent}</span>
          {sumComponent}
        </>
      ) : (
        <>
          {sumComponent}
          {currencyComponent}
        </>
      )}
    </>
  );
};

export default CurrencySwapper;
