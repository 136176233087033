import { cdnConfig, fitCover } from 'utils/common/cdnConfig';
import config from 'utils/config';
import { NotificationTypes } from '../../../../myProfile/sections/tabs/notificationsTab/notificationTypes';

export const randomReel = () => {
  const reels = [1, 2, 3, 4, 5, 6];
  const reelsLength = 3;
  const randReels: number[] = [];
  do {
    randReels[randReels.length] = reels.splice(Math.floor(Math.random() * reels.length), 1)[0];
  } while (randReels.length < reelsLength);
  return randReels;
};

export const hammerCampaignImages = (folder: NotificationTypes.GamificationFolder) => {
  const path = `${cdnConfig.GAMIFICATION_MODULE}/${folder}`;

  const frameImgSrc = `${config.CDN_IMAGE_API}${fitCover(334 * 2, 673 * 2)}${path}/hammer-frame.png`;
  const lightsImgSrc = `${config.CDN_IMAGE_API}${fitCover(334 * 2, 673 * 2)}${path}/hammer-lights.png`;
  const bellImgSrc = `${config.CDN_IMAGE_API}${fitCover(110 * 2, 110 * 2)}${path}/bell.png`;

  const markerImgSrc = `${config.CDN_IMAGE_API}${fitCover(12 * 2, 26 * 2)}${path}/scroll-select.png`;
  const arrowImgSrc = `${config.CDN_IMAGE_API}${fitCover(18 * 2, 18 * 2)}${path}/arrow.png`;

  const popUpBgImgSrc = `${config.CDN_IMAGE_API}${fitCover(298 * 2, 420 * 2)}${path}/bgr-popup.png`;

  return { frameImgSrc, lightsImgSrc, bellImgSrc, markerImgSrc, arrowImgSrc, popUpBgImgSrc };
};
