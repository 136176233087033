import { Buffer } from 'buffer';
import { decode } from '@msgpack/msgpack';
import { isValidJsonStr } from 'utils/common/helpersCommon';
import { WsNotificationsTypes } from '../../myProfile/sections/tabs/notificationsTab/types/wsNotifications.types';
const JSONbig = require('json-bigint')({ storeAsString: true });

export const findActiveAwardNotificaton = (notifications: WsNotificationsTypes.Notification[]) => {
  const now = Date.now();
  const newAwardNotification = notifications?.find((notification) => {
    return new Date(notification?.ValidUntil).getTime() > now;
  });
  return newAwardNotification;
};

const jsonParseCallback = (_, value) => {
  if (isValidJsonStr(value)) {
    return JSONbig.parse(value);
  }
  return value;
};

export const parsePopupContent = (popupContent: string): WsNotificationsTypes.Notification[] => {
  const decodedResponse = decode(Buffer.from(popupContent, 'base64')) as string;
  const jsonParsedResponse = JSONbig.parse(decodedResponse, jsonParseCallback);

  return jsonParsedResponse;
};
