import { useEffect } from 'react';
import { useAppDispatch } from 'store';
import { gridLayoutSettingsThunks } from '../gridLayoutSettingsSlice';

let fetchGridLayoutSettings = false;

const useGridLayoutSettingsFetch = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!fetchGridLayoutSettings) {
      dispatch(gridLayoutSettingsThunks.fetchGridLayoutSettings());
      fetchGridLayoutSettings = true;
    }
  }, [dispatch]);
};

export default useGridLayoutSettingsFetch;
