import React from 'react';
import { useTranslation } from 'react-i18next';
import CasinoContainerImplementation from 'modules/casino/platformImplementation/casinoWidgetTypes/CasinoContainerImplementation';
import useCasinoWidgetConfig from 'modules/casino/platformImplementation/hooks/useCasinoWidgetConfig';
import { WidgetContainerTypes } from 'modules/casino/platformImplementation/types/casinoWidget.types';
import { Icon } from 'modules/casino/shared/components/icons';
import { Flex } from 'modules/casino/shared/components/layout';
import { useInitialLaunchStatus } from 'modules/casino/shared/features/gameLaunch/launcherRoute/hooks/useInitialLaunchStatus';
import FavouritesGridWrapper from './FavouritesGridWrapper';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from '../../../../../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { useCasinoFavouritesModal } from '../hooks/useCasinoFavouritesModal';

const FavouriteGamesModal: React.FC = () => {
  const { t } = useTranslation();
  const { categoryId, gamesCount, isLoading, hasSuccess, hasNoGames, isModalOpen, handleCloseModal } =
    useCasinoFavouritesModal();

  const { initialLaunchStatusLoading, initialLaunchStatusSuccess } = useInitialLaunchStatus();
  const gameIsLoadingOrLoaded = initialLaunchStatusLoading || initialLaunchStatusSuccess;
  const casinoWidgetConfig = useCasinoWidgetConfig();

  if (!isModalOpen || !categoryId || gameIsLoadingOrLoaded) {
    return null;
  }

  return (
    <div className="fav-modal-backdrop">
      <div className="d-flex-col fav-modal-bottom">
        <div className="fav-modal-bottom__content">
          <Flex.AC className={`fav-modal__header px-0`}>
            <Flex.Center className="fav-modal__close-btn" onClick={handleCloseModal}>
              <Icon className="fav-modal__close-icon" icon="times" />
            </Flex.Center>
            <div className="fav-modal-count">
              {t('Favourites.Sports.BreadcrumbFavouritesString')} ({gamesCount})
            </div>
          </Flex.AC>
          <div className="no-scrollbars fav-modal-bottom__body">
            {casinoWidgetConfig ? (
              <CasinoContainerImplementation
                containerKey={'fav-modal'}
                containerType={WidgetContainerTypes.CATEGORY}
                params={{
                  categoryType: CategoryLayoutTypes.EXTERNAL_CATEGORY,
                  subType: CategorySubTypes.FAVOURITES_MODAL,
                }}
              />
            ) : (
              <FavouritesGridWrapper
                categoryId={categoryId}
                subType={CategorySubTypes.FAVOURITES_MODAL}
                categoryLayout={CategoryLayoutTypes.FAVOURITES_MODAL_CATEGORY}
                gamesCount={gamesCount}
                isLoading={isLoading}
                hasSuccess={hasSuccess}
                hasNoGames={hasNoGames}
                className={`${CategorySubTypes.FAVOURITES_MODAL.toLocaleLowerCase()}_external_category`}
                handleBackBtnClick={handleCloseModal}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavouriteGamesModal;
