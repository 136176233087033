import styled from 'styled-components';
import { Breakpoints } from 'theme/Theme';

export const ReelsGridWrapper = styled.div`
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  margin: 0 2px 16px 2px;

  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    margin-top: 0;
    margin-right: 28px;
    margin-bottom: 20px;
    margin-left: 28px;
  }
`;

export const ReelsGrid = styled.div`
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  padding: 11px 8px 8px 8px;
  width: 100%;

  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    column-gap: 28px;
    padding: 32px 21px 22px 21px;
  }

  & > div,
  .animation-frame__wrapper {
    grid-row: 1 / 1;
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
  }
`;

export const ReelsFrame = styled.img`
  z-index: 9999;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  width: 100%;
  pointer-events: none;
`;
