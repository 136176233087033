import { combineNameAndId } from 'hooks/navigation';
import { InPlayTypes } from 'utils/common/types/sportTypes';
import { BurgerMenuLayoutMenuItems02 } from '../../navigationMenu/types/navigationMenu.types';

export const setSportNavigationUrl = (sports: InPlayTypes.LiveSport[]): InPlayTypes.LiveSport[] => {
  return sports
    ?.filter((sport) => sport.liveEventsCount)
    ?.map((sport) => {
      let navigationUrl;
      if (sport.isOutright) {
        navigationUrl = `/in-play/${combineNameAndId(sport.eventPath || '', sport.sportEventId || '')}`;
      }
      return {
        ...sport,
        navigationUrl,
      };
    });
};

export const getDrawerHeaderItemType = (item: BurgerMenuLayoutMenuItems02): string => {
  const { concreteType, isCategoryActive, type } = item;

  if (concreteType === 'CasinoEGT' && !isCategoryActive) {
    return 'custom';
  }

  return type;
};
