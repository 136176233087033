import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import Icon from 'components/shared/Icon';

interface BoxProps {
  className?: string;
  style?: CSSProperties;
  hasAccent?: boolean;
  hasTextAccent?: boolean;
  hasIcon?: boolean;
  isRow?: boolean;
  text?: string | number;
  children?: React.ReactNode;
  isTitleCell?: boolean;
}

const RankingBox: React.FC<BoxProps> = ({
  className,
  style,
  text,
  hasAccent,
  hasTextAccent,
  hasIcon,
  isRow,
  children,
  isTitleCell,
}) => {
  const cellClass = clsx(isTitleCell ? 'prm-ranking__cell__title' : 'prm-ranking__cell');
  const boxClass = clsx(
    isRow ? `d-flex prm-ranking__row ${className ? className : ''}` : cellClass,
    hasAccent && 'prm-ranking__row--has-accent',
    hasTextAccent && 'prm-ranking__row--has-text-accent',
    hasIcon && 'prm-ranking__cell--icon',
  );
  return (
    <div className={boxClass} style={style}>
      {children}
      {text}
      {hasIcon && <Icon icon="egtd-challenge-star" />}
    </div>
  );
};

export default RankingBox;
