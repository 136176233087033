import { CategoryLayoutTypes } from '../../../modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';

export enum SearchGridTypes {
  Results = 'results',
  MostSearched = 'mostSearched',
}

export const CATEGORY_TITLE_KEY = {
  [CategoryLayoutTypes.SEARCH_RESULTS_CATEGORY]: 'CasinoSearch.Text.results',
  [CategoryLayoutTypes.MOST_SEARCHED_CATEGORY]: 'CasinoSearch.Text.mostSearched',
};

export const CasinoSearchTranslations = {
  filter: 'CasinoSearch.Text.filter',
  placeholder: 'CasinoSearch.Text.searchPlaceholder',
};

export const CASINO_SEARCH_ROUTE = '/casino/search';
