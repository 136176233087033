import React from 'react';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { showHideNavUserButton } from 'utils/common/helpersCommon';
import { NavUser, NavUserLogged } from '..';
import { useMainNavbar } from '../../hooks/useMainNavbar';

type UserSectionTypes = {
  isAuthenticated: boolean | null;
};

const UserSection: React.FC<UserSectionTypes> = ({ isAuthenticated }) => {
  const { subNav } = useMainNavbar();
  const showNavUserButtons = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.LOGIN_REGISTER_SHOW,
    deviceLayout: 'general',
  })?.isVissible;
  return isAuthenticated ? (
    <NavUserLogged showBalance={subNav.balance} showDeposit={subNav.deposit} />
  ) : showHideNavUserButton(showNavUserButtons) ? (
    <NavUser />
  ) : null;
};

export default UserSection;
