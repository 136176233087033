/* eslint-disable @typescript-eslint/no-explicit-any */
import { CasinoGamesTypes } from 'modules/casino/modules/container/cellsContainer/cellls/types/casinoGamesTypes';
import { GameType, RequestStatuses } from 'modules/casino/shared/constants';
import { PlayerRestrictions } from 'pages/myAccount/tabs/responsibleGambling/types/responsibleGambling.types';
import { General } from 'shared/common/features/general/types/generalSlice.types';
import { User } from 'shared/common/features/myProfile/types/myProfile.types';
import { RealityCheckSession } from 'shared/common/features/realityCheckSession/types/realityCheckSession.types';
import { AppDispatch } from 'store';
import { CreateThunk } from 'store/thunkCreators';
import { MessagesTypes } from './postMessages/messagesTypes';

export declare module CasinoLaunchGameTypes {
  type LaunchData = {
    launchUrl: string;
    gameId: string;
    providerAlias: string;
    isFreePlay: boolean;
    agent: ProviderLaunchAgents;
    closeUrl: string;
    history: number;
    realityCheckSessionData?: RealityCheckSession.HeaderData;
  };

  type GameData = {
    data: CasinoGamesTypes.GameProps[];
  };

  type State = {
    launchData: LaunchData | null;
    gameData: GameData | null;
    launchInProcess: boolean;
    launchTimestamp: number | null;
    showComponents: boolean;
    launchStatus: RequestStatuses;
    openGameData: OpenGameData | null;
    isProviderWithLobby: boolean;
    newGameIdReceived: boolean;
    providerInfoReceived: boolean;
    gameBetType?: PlayerRestrictions.ProductType;
    isInGameLaunch?: boolean;
  };

  type OpenGameData = {
    description: string;
    gameType: GameType;
    refGameId: string;
    providerId: string;
    providerAlias: string;
    iframeLoad: boolean;
    gameId: string;
    gamePlayType: GamePlayType;
    isTrackable: boolean;
    scrollPositionData?: ScrollPositionData;
    additionalLaunchParams?: string;
  };

  type ScrollPositionData = {
    collectionId: string;
    gridPage: number;
    gamesRenderedCount: number;
    gamesCount: number;
    uniqueId: string;
  };

  type CasinoGameOptions = {
    url: string;
    gameId: string;
    isFreePlay: boolean;
    currentPathname: string;
    history: number;
    providerAlias: string;
    data?: RealityCheckSession.HeaderData;
  };

  type FetchLaunchUrlParams = {
    gameId: string;
    providerAlias: string;
    isFreePlay: boolean;
    agent: ProviderLaunchAgents;
    closeUrl: string;
    history: number;
    svara?: string;
    depositUrl: string;
  };

  type FetchGameDataParams = {
    gameId: string;
  };

  type LaunchGameThunk = {
    fetchLaunchData: CreateThunk<FetchLaunchUrlParams, LaunchData, string | null>;
    fetchGameData: CreateThunk<FetchGameDataParams, GameData, string | null>;
  };
}

export enum ProviderLaunchAgents {
  mobile = 'mobile',
  desktop = 'desktop',
  window_desktop = 'window_desktop',
  playtechClose = 'playtechClose',
}

export enum SvaraLaunchPathParams {
  free = 'free',
  freeCustom = 'freeCustom',
  paid = 'paid',
  private = 'private',
  create = 'create',
  lobby = 'lobby',
}

export type AggregatorStylesConfig = {
  hasNotch: string[];
  hasBottomNotch: string[];
  hasTopNotch: string[];
  bottomCustomArea: string[];
  bodyAdditionalHeight: string[];
  oldStandardRatio: string[];
  customRatio1: string[];
};

export type IosHandConfig = {
  allowIOSHand: boolean;
  disableIOSHandByProvider: string[];
  disableIOSHandByGameId: string[];
  disableIOSHandByVersion: Record<string, string[]>;
  disableIOSHandByProviderAndVersion: Record<string, Record<string, string[]>>;
  disableIOSHandByGameIdAndVersion: Record<string, Record<string, string[]>>;
  useNewScrollLogic: string[];
  forceRefresh: string[];
};

export type AggregatorConfig = {
  iosHandConfig: IosHandConfig;
  postMessages: MessagesTypes.ProviderPostMessageConfig[];
  stylesConfig: AggregatorStylesConfig;
};

export type DirectLaunchConfig = {
  enabled: boolean;
  enabledOn?: DirectLaunchMobileEnvironments;
};

export enum DirectLaunchMobileEnvironments {
  Android,
  iOS,
  Both,
}

export type KycConfig = { verificationStatus: string[] };

export interface ProviderLauncherProps {
  agent: string;
  aggregatorConfig: AggregatorConfig;
}

export enum GamePlayType {
  FREE = 'FREE',
  PAID = 'PAID',
}

export type LaunchLocationState = {
  pathname: string;
  state?: {
    prevPathname?: string;
  };
};

export type LauncherComponent = React.FC<{
  launchData: CasinoLaunchGameTypes.LaunchData;
  aggregatorConfig: AggregatorConfig;
}>;

export type LauncherComponentByAgent = Record<Exclude<ProviderLaunchAgents, 'playtechClose'>, LauncherComponent>;

type OptionalParamType = { isOptional: true; default: any } | { isOptional?: never; default?: never };

type RequiredLaunchParam = {
  key: string;
};

export type LaunchParam = RequiredLaunchParam & OptionalParamType;

type TrackingGameDataType = {
  description: string;
  gameType: string;
  refGameId: string;
  providerId: string;
  providerAlias: string;
};
export type TriggerTrackingProps = {
  trackingGameData: TrackingGameDataType;
  gamePlayType: GamePlayType;
  dispatch: AppDispatch;
  isAuthenticated: boolean | null;
  playerId: string | undefined;
  trackingEventsConfig: General.TrackingEventsConfig;
  isTrackable?: boolean;
  userData: User | null;
};

export type IsIOSHandDisabledProps = {
  gameId: string;
  providerAlias: string;
  iosHandConfig: IosHandConfig;
  osVersion: string;
};

export type ApiData = ApiSvaraData & {
  gameid: string;
  freeplay: boolean;
  closeurl: string;
  depositurl: string;
};

export type RequestData = {
  fetchUrl: string;
  data: ApiData;
};

export type ResponseData = {
  data: RealityCheckSession.HeaderData;
  launchUrl: string;
  launchToken: string;
};

export type ApiSvaraData = {
  action?: string;
  privateCode?: string;
  tableId?: string;
  userData: User | null;
};

export type RenderLauncherProps = {
  agent: string;
  aggregatorConfig: AggregatorConfig;
  dispatch: AppDispatch;
};
