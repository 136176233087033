import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import {
  selectBottomTabBar,
  setMyBetsVisibilityFromSport,
} from 'shared/common/features/bottomTabBar/slice/bottomTabBar.slice';
import useDrawerConfig from 'shared/common/features/drawer/hooks/useDrawerConfig';
import { setDrawer, selectDrawer } from 'shared/common/features/drawer/slice/drawer.slice';
import { General, LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { useAppDispatch, useAppSelector } from 'store';
import { AppHeader } from 'utils/common/translationUtils/translationStrings';
import { useIsRetailMobile } from '../../retailHeader/hooks/useIsRetailMobile';
import { AppHeaderUI } from '../AppHeaderUI';
import { DrawerTogglerProps } from '../AppHeaderUI/drawerTogglerTypes';
import RetailDrawerToggler from '../AppHeaderUI/RetailDrawerToggler';

const componentsMap: Record<General.HeaderDrawerTogglerConfig['layoutType'], React.FC<DrawerTogglerProps>> = {
  layout_1({ className, isDrawerVisible, onClick, text }) {
    return (
      <AppHeaderUI.DrawerTogglerLayout01
        className={className}
        isDrawerVisible={isDrawerVisible}
        onClick={onClick}
        text={text}
      />
    );
  },
  layout_2({ className, isDrawerVisible, onClick }) {
    return (
      <AppHeaderUI.DrawerTogglerLayout02 className={className} isDrawerVisible={isDrawerVisible} onClick={onClick} />
    );
  },
};

interface Props {
  className?: string;
}

export const DrawerToggler: React.FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isDrawerVisible = useAppSelector(selectDrawer.isVisible);
  const isMyBetsVisible = useAppSelector(selectBottomTabBar.isMyBetsVisible);
  const { hideIconText } = useDrawerConfig();

  const isRetailMobile = useIsRetailMobile();

  const drawerTogglerLayout = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.LAYOUTTYPECONFIG,
    deviceLayout: 'general',
  })?.header;

  const layout = drawerTogglerLayout?.drawerToggler || 'layout_1';

  const handleDrawer = () => {
    if (!isDrawerVisible) {
      dispatch(setDrawer(true));
      if (isMyBetsVisible) {
        dispatch(setMyBetsVisibilityFromSport(false));
      }
    } else {
      dispatch(setDrawer(false));
    }
  };

  const DrawerTogglerComponent = componentsMap[layout];

  return isRetailMobile ? (
    <RetailDrawerToggler className={className} isDrawerVisible={isDrawerVisible} onClick={handleDrawer} />
  ) : (
    <DrawerTogglerComponent
      className={className}
      onClick={handleDrawer}
      isDrawerVisible={isDrawerVisible}
      text={hideIconText ? '' : t(AppHeader.DrawerToggler.az)}
    />
  );
};

export default memo(DrawerToggler);
