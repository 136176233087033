import { useEffect, useMemo } from 'react';
import { REFETCH_LOBBY_BASE_TTL } from 'modules/casino/modules/lobby/containersLobby/constants/casinoLobbyConstants';
import { CategoryQueryType, GLOBAL_TTL, LayoutConfigInnerLayoutTypes } from 'modules/casino/shared/constants';
import { useTimeToLeave } from 'modules/casino/shared/hooks/useTimeToLeave';
import { useAppDispatch, useAppSelector } from 'store';
import { selectLanguage } from '../../../../../../../../shared/common/features/settings/slice/settings.slice';
import { useCasinoLayoutConfig } from '../../../../../../shared/hooks/useCasinoLayoutConfig';
import { Casino } from '../../../../../../shared/types';
import { CategorySubTypes } from '../../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { GridLoadingBehaviorType } from '../../../grid/types/gridTypes';
import { categorySelectors, categoryThunks } from '../slice/casinoCategorySlice';

type UseCasinoCategoryFetchProps = {
  categoryId?: string;
  subType: CategorySubTypes;
  skipAbortFetch?: boolean; // Used to skip promise.abort if category type is LANDING_CATEGORY
  categoryIdKey?: string;
};

export const useCasinoCategoryFetch = ({
  categoryId,
  subType,
  skipAbortFetch = false,
  categoryIdKey,
}: UseCasinoCategoryFetchProps): {
  queryType: CategoryQueryType | undefined;
  layoutConfig: Casino.LayoutConfig;
  categoryId: string;
  isPublic: boolean;
  query: string | undefined;
  gridLoadingBehavior: GridLoadingBehaviorType;
} => {
  const dispatch = useAppDispatch();
  const hasExpired = useTimeToLeave();
  const language = useAppSelector(selectLanguage);

  const key = useMemo(() => {
    if (categoryIdKey) {
      return `${language}_${categoryIdKey}`;
    }
    return `${language}_${categoryId}`;
  }, [language, categoryId, categoryIdKey]);

  const categoryData = useAppSelector((state) => categorySelectors.cachedCasinoCategory(state, key));

  const casinoLayoutConfig = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.CASINO_LAYOUT_CONFIG });

  useEffect(() => {
    const baseTTL = categoryData?.refetchCategory ? REFETCH_LOBBY_BASE_TTL : GLOBAL_TTL;

    if ((categoryId || subType) && casinoLayoutConfig && hasExpired(categoryData?.ttl, baseTTL)) {
      if (subType === CategorySubTypes.STANDARD && !categoryId) {
        return;
      }
      const casinoExternalCategories = casinoLayoutConfig.casinoExternalCategories;
      const categoryConfig = casinoExternalCategories && subType && casinoExternalCategories[subType];
      const promise = dispatch(
        categoryThunks.fetchCategory({
          categoryId,
          subType,
          categoryConfig,
          key,
        }),
      );

      return () => {
        if (!skipAbortFetch) {
          promise && promise.abort();
        }
      };
    }
  }, [categoryId, subType, key, casinoLayoutConfig, dispatch]);

  return {
    queryType: categoryData?.casinoCategory.queryType,
    layoutConfig: categoryData?.casinoCategory.layoutConfig,
    categoryId: categoryData?.casinoCategory.id,
    isPublic: categoryData?.casinoCategory.isPublic || true,
    query: categoryData?.casinoCategory.query,
    gridLoadingBehavior:
      categoryData?.casinoCategory?.buttonLoadMore === false
        ? GridLoadingBehaviorType.InfinityScroll
        : GridLoadingBehaviorType.LoadButton,
  };
};
