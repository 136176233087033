import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from '../../general/types/generalSlice.types';

export const useMyProfileConfig = () => {
  const myProfileLayoutGeneral = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.MYPROFILE,
  });

  const myProfileLayout = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.MYPROFILE,
  });

  return myProfileLayoutGeneral || myProfileLayout;
};
