import React from 'react';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from 'theme/Theme';
import config from 'utils/config';

/** Footer Image */
interface ImageProps {
  icon?: string;
  sectionType?: string;
  title?: string;
}

export const Image: React.FC<ImageProps> = ({ icon, sectionType, title }) => {
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });
  const svgClass = clsx(
    'footer-menu__link-icon',
    isMobile || !isDesktop ? 'footer-menu__link-icon--mobile' : 'footer-menu__link-icon--desktop',
    sectionType === 'Apps' && 'footer-menu__link-icon--apps',
  );

  const iconPath = `${config.CDN_URL}/${icon}`;

  return <>{icon && <img src={iconPath} className={svgClass} title={title} />}</>;
};
