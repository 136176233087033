import React from 'react';

import { useTranslation } from 'react-i18next';
import { PrizeDropUI } from 'components/shared/prizeDrop';
import { WsNotificationsTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/types/wsNotifications.types';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import ModalFooter from '../components/PrizeDropModalFooter';
import { useWinModalTriggers } from '../hooks/useWinModalTriggers';

type Props = { activeNotification: WsNotificationsTypes.Notification };

const PrizeDropWinVideoView: React.FC<Props> = ({ activeNotification }) => {
  const { t } = useTranslation();
  const { handleInfo, handleClose, bonus } = useWinModalTriggers(activeNotification);

  return (
    <egtd-przd-modal data-win-video="true">
      <PrizeDropUI.Header
        handleClose={handleClose}
        handleInfo={handleInfo}
        title={bonus?.campaignName || ''}
        size="lg"
        isCenter
      />
      <PrizeDropUI.IsWinVideo text={activeNotification?.Message || ''} title={bonus?.title || ''} />
      <ModalFooter
        infoText={`${t(Promotions.PrizeDrop.winBoxInfoLink)}`}
        btnText={activeNotification?.Button?.buttonText || t(Promotions.PrizeDrop.continueButton)}
        infoLinkText={bonus?.campaignName || ''}
        handleClose={handleClose}
        handleInfo={handleInfo}
      />
    </egtd-przd-modal>
  );
};

export default PrizeDropWinVideoView;
