import React from 'react';
import { Flex } from 'components/shared/layout';

/** Auth Panel Wrapper */
interface PanelWrapperProps {
  children: React.ReactNode;
}

const PanelWrapper: React.FC<PanelWrapperProps> = ({ children }) => {
  return <Flex.Col className={'mx-auto auth-panel__wrapper'}>{children}</Flex.Col>;
};

export default PanelWrapper;
