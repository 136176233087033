import { useEffect } from 'react';
import { CasinoLobbyType } from 'modules/casino/shared/constants';
import { useAppDispatch } from 'store';
import { useMenuItemsCollectionCacheKey } from './useMenuItemsCollectionCacheKey';
import { casinoNavigationThunks } from '../slice/casinoNavigationSlice';

type Props = {
  casinoType: string;
  apiUri?: string;
  fetchNavItems?: boolean;
};

const cachedKeys: string[] = [];

export const useFetchCasinoNavigationItems = ({ casinoType, apiUri, fetchNavItems = true }: Props) => {
  const dispatch = useAppDispatch();
  const { cacheKey } = useMenuItemsCollectionCacheKey({ casinoType });

  useEffect(() => {
    if (!cachedKeys.includes(cacheKey) && fetchNavItems && casinoType && casinoType !== CasinoLobbyType.Default) {
      dispatch(casinoNavigationThunks.fetchMenuItems({ casinoType, apiUri }));
      cachedKeys.push(cacheKey);
    }
  }, [fetchNavItems, casinoType, dispatch]);
};
