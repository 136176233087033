import React, { memo, useCallback } from 'react';
import FavouritesContainer from 'shared/common/features/drawer/components/favourites/FavouritesContainer';
import { FavouritesSection } from 'shared/common/features/drawer/components/favourites/types/favourites.types';
import { Theme } from 'theme/Theme';
import { isEmpty } from 'utils/common/helpersCommon';
import { Sport } from 'utils/common/types/sportTypes';
import { useGetDrawerSportItems } from '../../hooks/useGetDrawerSportItems';
import { SportsType } from '../../types/drawer.types';
import DrawerNavLinkDefault from '../DrawerNavLinkDefault';
import { DrawerUI } from '../DrawerUI';
import SportsAZ from '../sport/SportsAZ';
import SportSectionNavLink from '../sport/SportSectionNavLink';

interface Props {
  isActive: boolean;
}

const ESportDrawerSection: React.FC<Props> = ({ isActive }) => {
  const { items, activeItem } = useGetDrawerSportItems(SportsType.ESports);

  const renderItem = useCallback(
    (item: Sport.ActiveSport, idx: number) => (
      <SportSectionNavLink
        key={idx}
        item={item}
        isActive={activeItem === item.id}
        index={idx}
        sportType={SportsType.ESports}
      />
    ),
    [activeItem],
  );

  return (
    <DrawerUI.Section type="sport" isActive={isActive}>
      <DrawerNavLinkDefault
        sportType={SportsType.ESports}
        navRoute={{ route: '/' + SportsType.ESports }}
        icon={`${Theme.icons.ui}#link-home`}
      />
      {!isEmpty(items) && (
        <>
          <SportsAZ />
          <FavouritesContainer
            favouriteType={FavouritesSection.sportIds}
            items={items}
            renderItem={renderItem}
            isAnimated
          />
        </>
      )}
    </DrawerUI.Section>
  );
};

export default memo(ESportDrawerSection);
