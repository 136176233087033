import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { General } from 'shared/common/features/general/types/generalSlice.types';
import { useAppSelector } from 'store';

export const useCasinoLayoutConfig = <K extends keyof General.CasinoConfig>({
  innerLayout,
}: {
  innerLayout: K;
}): General.LayoutConfiguration[K] => {
  const config = useAppSelector(selectGeneral.moduleLayout('casino', 'general', innerLayout));

  return config;
};
