import { useState, useEffect } from 'react';
import { resetNetAppHeader, setNetAppHeader } from 'shared/common/features/general/slice/general.slice';
import { AppDispatch, useAppDispatch } from 'store';
import axiosInstance from 'utils/common/axios-instance';
import config from 'utils/config';
import { Captcha, CaptchaRefresh } from '../formTypes';

const refreshCaptchaImage =
  (url: string) =>
  async (dispatch: AppDispatch): Promise<CaptchaRefresh> => {
    try {
      const response = await axiosInstance.get<CaptchaRefresh>(`${config.API_URL}/${url}`);
      if (response.headers?.['x-platform-hash-netapp']) {
        dispatch(setNetAppHeader(response.headers['x-platform-hash-netapp']));
      }
      return response.data;
    } catch (err) {
      dispatch(resetNetAppHeader());
      return { refreshTimeSeconds: 30, ci: '' };
    }
  };

const useFormCaptcha = ({ ci }: { ci?: Captcha }) => {
  const [captchaImage, setCaptchaImage] = useState(ci?.image);
  const [remainingTime, setRemainingTime] = useState(-1);
  const [isCiLoading, setCiLoading] = useState(false);
  const shouldRefresh = remainingTime <= 0 && !isCiLoading;

  const dispatch = useAppDispatch();

  const refreshCaptcha = () => {
    if (shouldRefresh && ci?.refreshUrl) {
      setCiLoading(true);
      dispatch(refreshCaptchaImage(ci.refreshUrl)).then((res) => {
        setCiLoading(false);
        setCaptchaImage(res.ci);
        setRemainingTime(res.refreshTimeSeconds);
      });
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (remainingTime > 0) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      timer && clearInterval(timer);
    }

    return () => {
      timer && clearInterval(timer);
    };
  }, [remainingTime]);

  return { refreshCaptcha, remainingTime, captchaImage, shouldRefresh };
};

export default useFormCaptcha;
