import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { UCNCheckInfoType, UcnScanThunkType, UCNScanType } from '../type/ucnScan.types';

const initialState: UCNScanType = {
  ucnCheckStatus: 'idle',
  ucnErrorMessageInfo: null,
};

export const ucnScanThunk: UcnScanThunkType = {
  checkUCNCode: createAbortThunk(`cashier/checkUCNcode`, async ({ ucnCode }, { rejectWithValue }) => {
    try {
      const url = `${config.API_URL}/api/pretail/agent/v2/ucn-player/vulnerability-check/${ucnCode}`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseData(err));
    }
  }),
};

const ucnScanSlice = createSlice({
  name: 'ucnScanSlice',
  initialState,
  reducers: {
    resetUCNCodeCheck: (state) => {
      state.ucnErrorMessageInfo = null;
      state.ucnCheckStatus = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ucnScanThunk.checkUCNCode.fulfilled, (state, action: PayloadAction<UCNCheckInfoType>) => {
      state.ucnErrorMessageInfo = {
        ageError:
          action.payload.errorMessage === 'Authentication.Error.minimumAgeLimit' ? action.payload.errorMessage : null,
        nraError:
          action.payload.errorMessage !== 'Authentication.Error.minimumAgeLimit' ? action.payload.errorMessage : null,
        success: action.payload.success,
      };

      state.ucnCheckStatus = 'succeeded';
    });
    builder.addCase(ucnScanThunk.checkUCNCode.pending, (state) => {
      state.ucnCheckStatus = 'pending';
    });
    builder.addCase(ucnScanThunk.checkUCNCode.rejected, (state) => {
      state.ucnCheckStatus = 'failure';
    });
  },
});
export const { resetUCNCodeCheck } = ucnScanSlice.actions;
export default ucnScanSlice.reducer;
export const selectUcnScanData = {
  ucnErrorMessageInfo: (state: RootState) => state.retail.ucnCheck.ucnErrorMessageInfo,
  ucnCheckStatus: (state: RootState) => state.retail.ucnCheck.ucnCheckStatus,
};
