import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from '../../general/types/generalSlice.types';

export const useInactivityCheckConfig = () => {
  const device = (isMobile && 'mobile') || (isTablet && 'tablet') || (isDesktop && 'desktop') || undefined;

  const inactivityCheckConfigDevice = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: device,
    innerLayout: LayoutConfigTypes.MYPROFILE,
  })?.inactivityCheck;

  const inactivityCheckConfigGeneral = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.MYPROFILE,
  })?.inactivityCheck;

  return inactivityCheckConfigDevice?.interval ? inactivityCheckConfigDevice : inactivityCheckConfigGeneral;
};
