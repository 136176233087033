import isEqual from 'lodash/isEqual';
import { StoreTypes } from '../types/store.types';

export const normalizeShopItem = (currShopItems = {}, storeItems: StoreTypes.Item[]): void => {
  storeItems?.forEach((shopItem) => {
    const existingShopItem = currShopItems[shopItem?.id];

    if (isEqual(existingShopItem, shopItem)) return;

    currShopItems[shopItem?.id] = shopItem;
  });
};

export const normalizeCategoryStore = (
  currCategories = {},
  categories: StoreTypes.CategoryApi[],
  page: number,
  polling: boolean,
): void => {
  categories?.forEach((currCategory) => {
    const existingCategory = currCategories[currCategory.alias];
    const shopItems = currCategory?.items || [];
    const isExpired = shopItems.every((shopItem) => !shopItem.isActive);
    if (existingCategory && polling) {
      currCategories[currCategory.alias].hideName = currCategory.hideName ?? false;
      currCategories[currCategory.alias].isExpired = isExpired;

      if (existingCategory.totalItems !== currCategory.totalItems) {
        currCategories[currCategory.alias].totalItems = currCategory.totalItems;
      }
      if (
        !isEqual(
          existingCategory.ids,
          shopItems.map((shopItem) => shopItem.id),
        )
      ) {
        currCategories[currCategory.alias].ids = shopItems.map((shopItem) => shopItem.id);
      }
    } else if (existingCategory?.page + 1 === page && !polling) {
      currCategories[currCategory.alias].totalItems = currCategory.totalItems;
      currCategories[currCategory.alias].page = page;
      currCategories[currCategory.alias].hideName = currCategory.hideName ?? false;
      currCategories[currCategory.alias].ids = [...existingCategory.ids, ...shopItems.map((shopItem) => shopItem.id)];
    } else if (!existingCategory) {
      currCategories[currCategory.alias] = {
        totalItems: currCategory.totalItems || 0,
        alias: currCategory.alias,
        category: currCategory.category,
        page: page,
        ids: shopItems.map((shopItem) => shopItem.id),
        hideName: currCategory.hideName ?? false,
        isExpired: isExpired,
      };
    }
  });
};

export const storeItemType = {
  Bonus: 'Bonus',
  Physical: 'Physical',
  Coupon: 'Coupon',
};

export const renderComponentType = ({
  isModal,
  isBulk,
}: {
  isModal: boolean;
  isBulk?: boolean;
}): StoreTypes.ComponentType => {
  if (isModal && isBulk) {
    return 'bulkModal';
  } else if (isModal) {
    return 'modal';
  } else {
    return 'default';
  }
};
