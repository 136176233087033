import React, { memo } from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';
import { DrawerTogglerProps } from './drawerTogglerTypes';

const DrawerTogglerLayout02: React.FC<DrawerTogglerProps> = ({ onClick, isDrawerVisible, className }) => {
  const burgerClass = clsx('burger', isDrawerVisible && 'active', className);

  return (
    <Flex.Center className={burgerClass} onClick={onClick} data-qid="drawerToggler">
      <span className="burger-box">
        <span className="burger-bar" />
      </span>
    </Flex.Center>
  );
};

export default memo(DrawerTogglerLayout02);
