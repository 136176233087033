import { useEffect } from 'react';
import { prizeDropThunks, selectPrizeDrop } from 'shared/common/features/PrizeDrop/slice/prizeDrop.slice';
import { useAppDispatch, useAppSelector } from 'store';

export const useGetCampaignData = () => {
  const activeCampaignId = useAppSelector(selectPrizeDrop.activeCampaignId);
  const activeCampaign = useAppSelector(selectPrizeDrop.activeCampaign);
  const rewards = useAppSelector(selectPrizeDrop.rewards(activeCampaignId));
  const rewardedPlayers = useAppSelector(selectPrizeDrop.rewardedPlayers(activeCampaignId));
  const personalRewards = useAppSelector(selectPrizeDrop.personalRewards(activeCampaignId));
  const terms = useAppSelector(selectPrizeDrop.termsAndConditions(activeCampaignId));
  const campaignStatus = useAppSelector(selectPrizeDrop.campaignStatus(activeCampaignId));
  const status = useAppSelector(selectPrizeDrop.status);
  const campaignsIds = useAppSelector(selectPrizeDrop.campaignsIds);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (activeCampaignId) {
      const promise = dispatch(prizeDropThunks.getCampaignData({ campaignId: activeCampaignId }));

      return () => {
        if (promise && promise?.abort) {
          promise.abort();
        }
      };
    }
  }, [activeCampaignId, dispatch]);

  return { rewards, rewardedPlayers, personalRewards, terms, activeCampaign, campaignStatus, campaignsIds, status };
};
