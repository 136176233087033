import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import {
  HistoryState,
  FetchHistoryParams,
  HistoryPeriod,
  HistoryPeriodType,
  DEFAULT_PAGE_SIZE,
  HistoryErrorValue,
  HistoryApiResponse,
  BonusWagerHistory,
  IpHistoryResponse,
} from '../types/history.types';

export const fetchHistory = createAbortThunk<HistoryApiResponse, FetchHistoryParams, HistoryErrorValue>(
  'fetchHistory',
  async (filterParams, { rejectWithValue }) => {
    const isPeriod = filterParams?.fromDate && filterParams?.toDate;
    const type = isPeriod ? HistoryPeriod.TYPE_PERIOD : HistoryPeriod.TYPE_LATEST;
    try {
      const url = `${config.API_URL}${filterParams.url}`;
      const data = {
        page: filterParams?.page ?? 1,
        pageSize: DEFAULT_PAGE_SIZE,
      };
      if (isPeriod && filterParams?.dateRange === 'dateRange') {
        data['filter'] = { dateRange: { fromDate: filterParams.fromDate, toDate: filterParams.toDate } };
      } else if (isPeriod && filterParams?.dateRange === 'bonusGivenDate') {
        data['filter'] = {
          bonusGivenDate: { fromDate: filterParams.fromDate, toDate: filterParams.toDate },
        };
      } else if (!isPeriod && filterParams?.dateRange === 'bonusGivenDate') {
        data['filter'] = {
          bonusGivenDate: {},
        };
      }

      const response = await axiosInstance.post(url, data);
      const { results, ...pagination } = response.data;

      return { results, pagination, type };
    } catch (err) {
      return rejectWithValue({
        type,
        error: getError.responseDataErrors(err),
      });
    }
  },
);

export const fetchCalendarPeriodData = createAbortThunk<{ periodInDays: number }, void>(
  'fetchCalendarPeriodData',
  async () => {
    try {
      const url = `${config.API_URL}/api/ews-crm/public/cms/player-content?key=login_history_form`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (err) {
      return getError.responseDataErrors(err);
    }
  },
);

const initialState: HistoryState = {
  isLoading: false,
  isLoadingMore: false,
  error: {
    latest: '',
    period: '',
  },
  hasFetched: { latest: false, period: false },
  hasUpdated: false,
  data: {
    latest: [],
    period: [],
  },
  pagination: {
    page: 0,
    pageSize: 10,
    total: 0,
  },
  periodInDays: 0,
};

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    clearHistoryState: () => initialState,
    clearHistoryPeriod: (state) => {
      state.data.period = [];
      state.hasFetched.period = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHistory.pending, (state, action) => {
        if (action.meta?.arg?.isMoreLoading) {
          state.isLoadingMore = true;
        } else {
          state.isLoading = true;
        }
        state.error.period = '';
        state.error.latest = '';
      })
      .addCase(fetchHistory.rejected, (state, action: PayloadAction<HistoryErrorValue>) => {
        if (action.payload) {
          const { type, error } = action.payload;
          state.error[type] = error;
          state.isLoading = false;
          state.isLoadingMore = false;
        }
      })
      .addCase(fetchHistory.fulfilled, (state, action: PayloadAction<HistoryApiResponse>) => {
        const { type, results, pagination } = action.payload;
        state.data[type] = [...state.data[type], ...results] as IpHistoryResponse[] | BonusWagerHistory[];
        state.pagination = pagination;
        state.hasFetched[type] = true;
        state.isLoading = false;
        state.isLoadingMore = false;
      })
      .addCase(fetchCalendarPeriodData.fulfilled, (state, action: PayloadAction<{ periodInDays: number }>) => {
        const { periodInDays } = action.payload;
        state.periodInDays = periodInDays;
      })
      .addCase(fetchCalendarPeriodData.rejected, (state, action: PayloadAction<string | null | undefined>) => {
        state.error.latest = action.payload || getError.default();
      });
  },
});

export default historySlice.reducer;

export const { clearHistoryState, clearHistoryPeriod } = historySlice.actions;

export const selectHistory = {
  items: (type: HistoryPeriodType) => (state: RootState) => state.myAccount.history.data[type],
  fetchState: (state: RootState) => {
    const { isLoading, isLoadingMore, hasFetched, error } = state.myAccount.history;
    return { isLoading, isLoadingMore, hasFetched, error };
  },
  error: (state: RootState) => state.myAccount.history.error,
  hasFetched: (state: RootState) => state.myAccount.history.hasFetched,
  isLoadingMore: (state: RootState) => state.myAccount.history.isLoadingMore,
  isLoading: (state: RootState) => state.myAccount.history.isLoading,
  pagination: (state: RootState) => state.myAccount.history.pagination,
  periodInDays: (state: RootState) => state.myAccount.history.periodInDays,
};
