import styled from 'styled-components';
import { AnimationHand } from './index';

const mapAnimation = {
  drag: AnimationHand.SwipeDown,
  hold: AnimationHand.Hold,
  click: AnimationHand.Click,
};

const ActionHandItemWrapper = styled.div<{ $types: string }>`
  opacity: 0;
  width: 50px;
  height: 50px;
  position: relative;
  animation: ${({ $types }) => mapAnimation[$types]} 2s infinite ease-in-out;
  animation-delay: 1s;
  @media screen and (min-height: 800px) {
    width: 60px;
    height: 60px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

export default ActionHandItemWrapper;
