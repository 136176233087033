import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { CuTags } from 'pages/captainUp/components/Tags';
import { showCaptainUpModal } from 'pages/captainUp/features/CaptainUpModal/slice/modal.slice';
import { useLoadingEffect } from 'pages/captainUp/hooks/useLoadingEffect';
import { MyChallengesType } from 'shared/common/features/myProfile/sections/tabs/captainUpTab/types/challenges.types';
import { useAppSelector } from 'store';
import { ChallengesCellUI } from './ChallengesCellUI';
import { useGetDateInfo } from '../../Store/hooks/useGetDateInfo';
import { Challenges } from '../types/challenges.types';
import { challengeSelector } from '../utils/challenges.utils';

interface CustomInputProps {
  id: string;
  alias: string;
  order: number;
  collections: string;
  type?: string;
  typeTap?: string;
  categoryId: number;
  selectorType?: 'default' | 'myAccount';
  handleClick?: () => void;
  hideTitle?: boolean;
}

export const ChallengesCell: React.FC<CustomInputProps> = ({
  id,
  categoryId,
  alias,
  order,
  collections,
  type,
  typeTap,
  selectorType = 'default',
  handleClick,
  hideTitle,
}) => {
  const challenge: Challenges.Challenge | MyChallengesType.Challenge = useAppSelector(
    challengeSelector[selectorType].getChallengeById(id, alias),
  );

  const dispatch = useDispatch();
  const cellRef = useRef<HTMLDivElement | null>(null);

  const { hasFinishedLoading, onAnimationStart } = useLoadingEffect(id, order, cellRef, collections, typeTap);

  const onClickHandler = useCallback(() => {
    handleClick && handleClick();
    dispatch(
      showCaptainUpModal({
        modalType: 'CHALLENGES',
        modalProps: {
          id: challenge.id,
          alias: alias,
          categoryId: categoryId,
        },
      }),
    );
  }, [dispatch, challenge, alias, categoryId, handleClick]);

  const { dateDifferenceEnd, passedDate } = useGetDateInfo(new Date().toLocaleString(), challenge?.custom?.endDate);

  if (!challenge) return null;

  return (
    <ChallengesCellUI.Wrapper
      onClickHandler={onClickHandler}
      title={challenge.name}
      wrapperRef={cellRef}
      onAnimationStart={onAnimationStart}
      type={type}
      hideTitle={hideTitle}
    >
      <ChallengesCellUI.ImageWrapper
        title={challenge.name}
        image={challenge.imageUrl}
        cellId={challenge.id}
        hasFinishedLoading={hasFinishedLoading}
        type={type}
      >
        <CuTags.TagInfo handleOnClick={onClickHandler} />

        {/* <CuTags.FavIcon handleOnClick={addToFav} /> */}
        <CuTags.TagCoins winCoins={challenge.currencies?.coins} experiencePoints={challenge?.currencies?.points}>
          {challenge?.custom?.endDate && !passedDate && (
            <CuTags.DateDifference startDate={challenge.custom.endDate} dateDifference={dateDifferenceEnd} isEndDate />
          )}
        </CuTags.TagCoins>
      </ChallengesCellUI.ImageWrapper>
      <ChallengesCellUI.ProgressBar completed={challenge.completed} progress={Math.round(challenge?.progress * 100)} />
    </ChallengesCellUI.Wrapper>
  );
};
