import { t } from 'i18next';
import { setIsPrintingEjected } from 'modules/retail/modules/cashier/slice/cashier.slice';
import { PrintFinancialResults } from 'modules/retail/modules/cashier/types/cashier.types';
import { formatCurrency } from 'modules/retail/modules/cashier/utils';
import { formatFromAPI } from 'modules/retail/modules/ssbt/components/terminalKeyboard/helpersFuncCashier';
import { enqueuePrintRequest, processPrintQueue } from 'modules/retail/shared/slice/printQueue.slice';
import { CommandData } from 'modules/retail/shared/types/retailCommon.types';
import {
  commands,
  monitorPrintingCompletion,
  printEjectCommand,
  printMethods,
} from 'modules/retail/shared/utils/retail.utils';
import { store } from 'store';
import { getError, retailFormatDate } from 'utils/common/helpersCommon';
import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';
import { makeRequest } from '../../../shared/printMethods/printUtils/helperPrintFunctions';
import {
  _addressOptions,
  _logoOptionsData,
  _optionsData,
  voucherValueOptions,
  winbetAddress,
  winbetOptions,
} from '../../../shared/printMethods/printUtils/printReceiptOptions';

export const printFinancialResults = async ({
  financeInfo,
  retailAddress,
  requestDate,
  isCurrencyBeforeAmount,
}: PrintFinancialResults) => {
  const printFinancialJob = async () => {
    const printFinancialData: CommandData[] = [];
    printMethods.printIsReady(printFinancialData, commands);
    printMethods.printStart(printFinancialData, commands);
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, _logoOptionsData);
    printMethods.printImage(printFinancialData, commands, retailAddress?.buLogo);
    printMethods.printOptions(printFinancialData, commands, _optionsData);
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, winbetOptions);
    printMethods.print(printFinancialData, commands, t(retailAddress?.responsibleGambling));
    printMethods.print(printFinancialData, commands, t('Cashier.Receipt.addressPOSText'));
    printMethods.print(
      printFinancialData,
      commands,
      `${financeInfo?.posAddress?.city}, ${financeInfo?.posAddress?.streetName} ${financeInfo?.posAddress?.streetNumber}`,
    );
    printMethods.print(printFinancialData, commands, '');
    printMethods.print(printFinancialData, commands, t('Cashier.Receipt.signatureNumber'));
    printMethods.print(printFinancialData, commands, financeInfo?.posName);
    printMethods.conditionalPrint(
      printFinancialData,
      commands,
      window.config.ENV_TYPE !== 'PRODUCTION',
      t('Cashier.Receipt.testOperation'),
    );
    printMethods.printOptions(printFinancialData, commands, winbetOptions);
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, winbetOptions);
    printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, _addressOptions);
    printMethods.print(printFinancialData, commands, t(CashierTranslations.FinancialModal.posNameText));
    printMethods.print(printFinancialData, commands, financeInfo?.posName);
    printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, winbetOptions);
    printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
    printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
    printMethods.print(printFinancialData, commands, '');
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'left');
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'right');
    printMethods.print(printFinancialData, commands, t('Cashier.FinancialModal.selectedPeriod'));
    printMethods.print(printFinancialData, commands, formatFromAPI(financeInfo?.period) + ' '); //   // { command: commands.colDefine, bodyData: { width: 290, v_alignment: 'middle', h_alignment: 'right' } },
    printMethods.colReset(printFinancialData, commands);
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, winbetOptions);
    printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
    printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
    printMethods.print(printFinancialData, commands, '');
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'left');
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'right');
    printMethods.print(printFinancialData, commands, t('Cashier.FinancialModal.requestDateReceipt'));
    printMethods.print(printFinancialData, commands, retailFormatDate(requestDate) + ' '); //   // { command: commands.colDefine, bodyData: { width: 290, v_alignment: 'middle', h_alignment: 'right' } },
    printMethods.colReset(printFinancialData, commands);
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, winbetOptions);
    printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
    printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
    printMethods.print(printFinancialData, commands, '');
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'left');
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'right');
    printMethods.print(printFinancialData, commands, t('Cashier.FinancialModal.acceptedTicketsAmount'));
    printMethods.print(
      printFinancialData,
      commands,
      formatCurrency(financeInfo?.betAmount, financeInfo?.currency, isCurrencyBeforeAmount) + ' ',
    ); //   // { command: commands.colDefine, bodyData: { width: 290, v_alignment: 'middle', h_alignment: 'right' } },
    printMethods.colReset(printFinancialData, commands);
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, winbetOptions);
    printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'left');
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'right');
    printMethods.print(printFinancialData, commands, t(CashierTranslations.FinancialModal.acceptedTicketsCount));
    printMethods.print(printFinancialData, commands, financeInfo?.numberOfTickets + ' '); //   // { command: commands.colDefine, bodyData: { width: 290, v_alignment: 'middle', h_alignment: 'right' } },
    printMethods.colReset(printFinancialData, commands);
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, winbetOptions);
    printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'left');
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'right');
    printMethods.print(printFinancialData, commands, t(CashierTranslations.FinancialModal.paidTicketsCount));
    printMethods.print(printFinancialData, commands, financeInfo?.paidTickets + ' ');
    printMethods.colReset(printFinancialData, commands);
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, winbetOptions);
    printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
    printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
    printMethods.print(printFinancialData, commands, '');
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'left');
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'right');
    printMethods.print(printFinancialData, commands, t(CashierTranslations.FinancialModal.paidAmountValue));
    printMethods.print(
      printFinancialData,
      commands,
      formatCurrency(financeInfo?.paidAmount, financeInfo?.currency, isCurrencyBeforeAmount) + ' ',
    );
    printMethods.colReset(printFinancialData, commands);
    printMethods.printOptions(printFinancialData, commands, winbetOptions);
    printMethods.print(printFinancialData, commands, '');
    printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'left');
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'right');
    printMethods.print(printFinancialData, commands, t(CashierTranslations.FinancialModal.cancelledAmountValue));
    printMethods.print(
      printFinancialData,
      commands,
      formatCurrency(financeInfo?.cancelledAmount, financeInfo?.currency, isCurrencyBeforeAmount) + ' ',
    );
    printMethods.colReset(printFinancialData, commands);
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, winbetOptions);
    printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'left');
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'right');
    printMethods.print(printFinancialData, commands, t(CashierTranslations.FinancialModal.cancelledTicketsCount));
    printMethods.print(printFinancialData, commands, financeInfo?.cancelledTickets + ' ');
    printMethods.colReset(printFinancialData, commands);
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, winbetOptions);
    printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'left');
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'right');
    printMethods.print(printFinancialData, commands, t(CashierTranslations.FinancialModal.voidedBetsAmount));
    printMethods.print(
      printFinancialData,
      commands,
      formatCurrency(financeInfo?.voidedBetsAmount, financeInfo?.currency, isCurrencyBeforeAmount) + ' ',
    );
    printMethods.colReset(printFinancialData, commands);
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, winbetOptions);
    printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'left');
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'right');
    printMethods.print(printFinancialData, commands, t(CashierTranslations.FinancialModal.numberOfVoidedBets));
    printMethods.print(printFinancialData, commands, financeInfo?.voidedTickets + ' ');
    printMethods.colReset(printFinancialData, commands);
    printMethods.printOptions(printFinancialData, commands, winbetOptions);
    printMethods.print(printFinancialData, commands, '');
    printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'left');
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'right');
    printMethods.print(printFinancialData, commands, t(CashierTranslations.FinancialModal.depositedFromPosAmount));
    printMethods.print(
      printFinancialData,
      commands,
      formatCurrency(financeInfo?.depositedFromPosAmount, financeInfo?.currency, isCurrencyBeforeAmount) + ' ',
    );
    printMethods.colReset(printFinancialData, commands);
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, winbetOptions);
    printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'left');
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'right');
    printMethods.print(printFinancialData, commands, t(CashierTranslations.FinancialModal.depositedToPosAmount));
    printMethods.print(
      printFinancialData,
      commands,
      formatCurrency(financeInfo?.depositedToPosAmount, financeInfo?.currency, isCurrencyBeforeAmount) + ' ',
    );
    printMethods.colReset(printFinancialData, commands);
    printMethods.printOptions(printFinancialData, commands, winbetOptions);
    printMethods.print(printFinancialData, commands, '');
    printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'left');
    printMethods.colDefine(printFinancialData, commands, 290, 'middle', 'right');
    printMethods.print(printFinancialData, commands, t('Cashier.FinancialModal.balanceText'));
    printMethods.print(
      printFinancialData,
      commands,
      formatCurrency(financeInfo?.balance, financeInfo?.currency, isCurrencyBeforeAmount) + ' ',
    );
    printMethods.colReset(printFinancialData, commands);
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, winbetOptions);
    printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
    printMethods.print(printFinancialData, commands, '');
    printMethods.printOptions(printFinancialData, commands, winbetAddress);
    printMethods.print(printFinancialData, commands, '');
    printMethods.print(printFinancialData, commands, '');
    printMethods.printEject(printFinancialData, commands);

    try {
      store.dispatch(setIsPrintingEjected(true));
      for (const i of printFinancialData) {
        await makeRequest(i);
        if (i.command === printEjectCommand) {
          await monitorPrintingCompletion();
        }
      }
    } catch (error) {
      getError.message(error);
    } finally {
      store.dispatch(setIsPrintingEjected(false));
      // Process the next job in the queue if any
      store.dispatch(processPrintQueue());
    }
  };

  store.dispatch(enqueuePrintRequest(printFinancialJob));

  // Check if the current printing state and process the queue if no job is currently running
  const state = store.getState();
  if (!state.retail.printQueue.isPrinting) {
    store.dispatch(processPrintQueue());
  }
};
