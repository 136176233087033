import { useAppSelector } from 'store';
import { gamesBonusSelectors } from '../slice/gameLaunchBonus.slice';
import { GameLaunchBonusTypes } from '../types/gameLaunchBonus.types';

export const useJackpotCampaignDetails = (): GameLaunchBonusTypes.JackpotCampaignResponse => {
  const jackpotCampaignDetails = useAppSelector(gamesBonusSelectors.jackpotCampaignDetails);

  return {
    bannerResource: jackpotCampaignDetails?.bannerResource || '',
    promotionAlias: jackpotCampaignDetails?.promotionAlias || '',
    categoryAlias: jackpotCampaignDetails?.categoryAlias || '',
    jackpotName: jackpotCampaignDetails?.jackpotName || '',
    jackpotId: jackpotCampaignDetails?.jackpotId || '',
    jackpotLevels: jackpotCampaignDetails?.jackpotLevels || [],
  };
};
