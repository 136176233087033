import React from 'react';
import clsx from 'clsx';
import { useAppSelector } from 'store';
import { EventSelectors } from '../types/eventProps.types';

/** EventUI Serving Indicator */

/** Component CSS NameSpace */
const cssNS = 'serving__indicator';

interface ServeIndicatorProps {
  homeIndicator?: boolean;
  awayIndicator?: boolean;
  // TODO support both props
  eventSelectors?: EventSelectors;
  sportId?: number;
  sportEventId?: number;
}

export const ServeIndicator: React.FC<ServeIndicatorProps> = ({
  homeIndicator,
  awayIndicator,
  sportEventId,
  sportId,
  eventSelectors,
}) => {
  const currentServer = useAppSelector((state) =>
    eventSelectors && sportEventId ? eventSelectors.currentServer(state, sportEventId, sportId) : undefined,
  );

  const hasHomeIndicator = homeIndicator || currentServer === 'home';
  const hasAwayIndicator = awayIndicator || currentServer === 'away';
  const homeIndicatorClass = clsx(cssNS, hasHomeIndicator ? `${cssNS}--active` : `${cssNS}--idle`);
  const awayIndicatorClass = clsx(cssNS, hasAwayIndicator ? `${cssNS}--active` : `${cssNS}--idle`);

  return (
    <div className="d-flex-col-ja serving__wrapper">
      <div className={homeIndicatorClass} />
      <div className={awayIndicatorClass} />
    </div>
  );
};
