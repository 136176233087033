import React, { useState } from 'react';
import clsx from 'clsx';
import { ExpandableIcon } from 'components/shared/icons';
import { Flex } from 'components/shared/layout';
import { UI } from 'components/shared/UI';
import { ctaUICSSNS } from './ctaUI.types';

interface AccordionProps {
  /** Accordion Header icon. Icon should be present in Promo icons sprite. */
  icon: string;
  /** Accordion Header text */
  text: string;
  /** Is the accordion opem? */
  isOpen?: boolean;
  children?: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ icon, text, isOpen = false, children }) => {
  const [isToggled, setIsToggled] = useState(isOpen);
  const handleOpen = () => {
    setIsToggled(!isToggled);
  };

  const cssNS = ctaUICSSNS.accordion;
  const headerClass = clsx(`${cssNS}__box ${cssNS}__header`, isToggled && `${cssNS}__header--open`);

  return (
    <div className={cssNS}>
      <Flex.AC className={headerClass} onClick={handleOpen}>
        <UI.SVGCDN className={`${cssNS}__header-icon`} icon={icon} />
        {text}
        <ExpandableIcon className="ml-auto" isExpanded={isToggled} />
      </Flex.AC>
      {children && isToggled && <div className={`${cssNS}__box ${cssNS}__body`}>{children}</div>}
    </div>
  );
};

export default Accordion;
