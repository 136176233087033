import React, { useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { VideoPlayer } from 'components/shared/VideoPlayer';
import { useAutoFontSize } from 'hooks/common/useAutoFontSize';
import { Breakpoints } from 'theme/Theme';
import { prizeDropCSSNS } from '../prizeDropUICSS.types';
type Props = {
  text: string;
  title: string;
};

const PrizeDropIsWinVideo: React.FC<Props> = ({ title, text }) => {
  const [videoIsDone, setVideoIsDone] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const classProp = {
    class: `${prizeDropCSSNS}-video-wrapper`,
  };
  const wrapperProp = {
    className: `${prizeDropCSSNS}-video-info grid-start-col-1 grid-start-row-1`,
  };

  const isTabletOrMobile = useMediaQuery({ query: `(min-width: ${Breakpoints.isMobile}px)` });
  useAutoFontSize({
    element: textRef,
    maxSize: isTabletOrMobile ? 30 : 26,
    update: isTabletOrMobile,
    disableParent: true,
  });

  return (
    <div {...classProp}>
      <VideoPlayer
        loop={false}
        video="common-content/prize-drop/pop-up/pop-up.mp4"
        cover="common-content/prize-drop/pop-up/pop-up.png"
        triggerVideoEnded={() => setVideoIsDone(true)}
      />
      <AnimatePresence initial={false}>
        {videoIsDone && (
          <motion.div {...wrapperProp} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className={`${prizeDropCSSNS}-video-info-text`}>{text}</div>
            <div className={`${prizeDropCSSNS}-video-info-gift no-scrollbars`} ref={textRef}>
              <div className={`${prizeDropCSSNS}-video-info-title`}>{title}</div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PrizeDropIsWinVideo;
