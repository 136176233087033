import React from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';

interface ColumnProps {
  children: React.ReactNode;
  className?: string;
  hasGap?: boolean;
  animation?: boolean;
}

const Column: React.FC<ColumnProps> = ({ children, className, animation, hasGap }) => {
  const columnStyle = clsx(`mpd-block`, hasGap && 'mpd-block--hasGap', animation && 'mpd-block--hidden', className);
  return <Flex.Col className={columnStyle}>{children}</Flex.Col>;
};

export default Column;
