import React from 'react';
import { useTranslation } from 'react-i18next';
import { NoDataMessage } from 'components/shared';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import { ChallengesPopoverItem } from './ChallengesPopoverItem';
import { GameLaunchTranslationKeys } from '../../../gameLaunch/casinoLaunchConstants';
import { TopBarTypes } from '../topBar.types';
import { TopBarPopover } from '../TopBarPopover';

export const ChallengesPopover: React.FC<TopBarTypes.ChallengesPopover> = ({ handleClose, parentRef, items, show }) => {
  const { t } = useTranslation();
  return (
    <TopBarPopover handleClose={handleClose} parentRef={parentRef} show={show}>
      {isEmpty(items) ? (
        <NoDataMessage message={t(GameLaunchTranslationKeys.noMissionPopoverText)} />
      ) : (
        <div className={`${cssGlobalNS}-gl__popover-wrapper`}>
          {items.map((item, idx) => (
            <ChallengesPopoverItem key={idx} item={item} />
          ))}
        </div>
      )}
    </TopBarPopover>
  );
};
