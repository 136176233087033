import React, { RefObject, memo, useRef } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Flex } from 'components/shared/layout';
import { General } from 'shared/common/features/general/types/generalSlice.types';
import { selectNavigationMenu } from 'shared/common/features/navigationMenu/slice/navigationMenu.slice';
import { useAppSelector } from 'store';
import { MainNavBarButtons } from './mainNavbarButtons';
import UserSection from './UserSection';
import VerticalsMenu from './VerticalsMenu';
import { DrawerToggler, NavBrand, NavUserTerminal } from '..';
import SmarticoButton from '../../../integrations/smartico/components/SmarticoButton';
import { useUserBalance } from '../../../myProfile/hooks/useUserBalance';
import useIsSearchOpen from '../../hooks/useIsSearchOpen';
import { useMainNavbar } from '../../hooks/useMainNavbar';
import { useSearch } from '../../hooks/useSearch';
import NavSearchButton from '../common/NavSearchButton';

type MainNavDesktopLayout01Types = {
  isAuthenticated: boolean | null;
  parentRef?: RefObject<HTMLDivElement>;
  layout: General.HeaderWrapperConfig['layoutType'];
};

const MainNavDesktopLayout01: React.FC<MainNavDesktopLayout01Types> = ({ isAuthenticated, parentRef, layout }) => {
  const { subNav } = useMainNavbar();
  const { balanceAmount } = useUserBalance();
  const showTogglerOnDesktop = useAppSelector(selectNavigationMenu.burgerMenuLayout02)?.showOnDesktop;
  const showNavSearch = useIsSearchOpen();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const { handleOpenSearch } = useSearch(inputRef);

  return (
    <>
      <Flex className="head__left">
        {showTogglerOnDesktop && !isMobileOnly && <DrawerToggler />}
        <NavBrand />
      </Flex>
      <VerticalsMenu parentRef={parentRef} layout={layout} />
      <Flex.JE className={`head__right`}>
        <NavSearchButton handleOpenSearch={handleOpenSearch} showNavSearch={!!showNavSearch} />

        {/* TODO: currently defaultUser is "normal" user */}
        {!subNav.defaultUser ? (
          <Flex.ACJE className="nav-user">
            <NavUserTerminal userBalance={balanceAmount} isAuthenticated={isAuthenticated} />
          </Flex.ACJE>
        ) : (
          <>
            <SmarticoButton />
            <MainNavBarButtons />
            {/* {showTombolaCollection && <TombolaUserTickets />} */}
            {/* {bonusCollection && <BonusCollectionsLink />} */}
            {/* <OffersToggler isAuthenticated={isAuthenticated} /> */}
            {/* <PrizeDropButtons /> */}
            <UserSection isAuthenticated={isAuthenticated} />
            <MainNavBarButtons type={'language'} />
          </>
        )}
      </Flex.JE>
    </>
  );
};

export default memo(MainNavDesktopLayout01);
