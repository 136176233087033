import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { FieldValues, FormFieldTypes } from 'components/shared/forms/formTypes';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch, useAppSelector } from 'store';
import CustomFormControl from '../components/CustomFormControl';
import {
  claimTicketThunks,
  clearClaimTicket,
  clearFormData,
  selectClaimTicket,
  setFormData,
  setFormStep,
} from '../slice/claimTicket.slice';
import { ClaimTicket } from '../types/claimTicket.types';
import { formsMap } from '../utils/forms';

interface ReturnProps {
  onSubmit: (d) => void;
  isButtonDisabled?: boolean;
  formFields: FormFieldTypes[];
  errorMessage?: boolean;
  isSubmitLoading: boolean;
}

const sportVerticals = ['/sports', '/in-play', '/esports', '/virtual', '/outrights'];

const useClaimTicketForm = ({ isModal, formProps }): ReturnProps => {
  const { formState, setValue } = formProps;
  const { isValid } = formState;
  const formFields = formsMap['claim_ticket_form'];
  const step = useAppSelector(selectClaimTicket.step);
  const errorMessage = useAppSelector(selectClaimTicket.claimTicketSearchError);
  const status = useAppSelector(selectClaimTicket.claimTicketSearchStatus);
  const isSubmitLoading = status == 'pending';
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      !isModal && dispatch(clearFormData());
    };
  }, [dispatch, isModal]);

  useEffect(() => {
    if (status == 'fulfilled') {
      dispatch(setFormStep(2));
      // in case of form in sidebarX
      dispatch(
        showRootModal({
          modalType: 'CLAIM_TICKET_MODAL',
          modalPriority: ModalPriorityEnum.MEDIUM,
          modalId: 'CLAIM_TICKET_MODAL',
        }),
      );
    }
  }, [dispatch, status]);

  useEffect(() => {
    //set default value according to vertical
    const isSport = sportVerticals.some((v) => location.pathname.includes(v));
    step == 1 && setValue('type', isSport ? 'Sport' : 'Lottery');
  }, [dispatch, location, location.pathname, setValue, step]);

  const onSubmit = (formData: FieldValues) => {
    if (!isValid) return;
    dispatch(claimTicketThunks.claimTicketSearch(formData as ClaimTicket.ClaimTicketParams));
    dispatch(setFormData(formData));
  };

  const isButtonDisabled = !isValid || !!errorMessage;

  const onChangeCallback = useCallback(() => {
    errorMessage && dispatch(clearClaimTicket());
  }, [dispatch, errorMessage]);

  const fields = useMemo(
    () =>
      formFields.map((f) => ({
        ...f,
        onChangeCallback,
        CustomComponent: (props) => {
          return <CustomFormControl {...props} />;
        },
      })),
    [formFields, onChangeCallback],
  );

  return {
    onSubmit,
    isButtonDisabled,
    formFields: fields as FormFieldTypes[],
    errorMessage,
    isSubmitLoading,
  };
};
export default useClaimTicketForm;
