import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { CategoryQueryType } from 'modules/casino/shared/constants';
import { useAppDispatch } from 'store';
import { setCategoryInView } from '../casinoUiStateSlice';

type Props = {
  collectionId: string;
  queryType: CategoryQueryType;
  children?: React.ReactNode;
};

const CategoryInView: React.FC<Props> = ({ collectionId, queryType, children }) => {
  const dispatch = useAppDispatch();
  const [inViewRef, inView] = useInView({
    rootMargin: '0px',
  });
  useEffect(() => {
    dispatch(setCategoryInView({ collectionId, inView }));
  }, [dispatch, inView]);

  return queryType === CategoryQueryType.SVARA_CATEGORY ? <div ref={inViewRef}>{children}</div> : <>{children}</>;
};

export default CategoryInView;
