import React from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer } from 'utils/common/translationUtils/translationStrings';
import useDrawerConfig from '../../hooks/useDrawerConfig';
import useHandleDrawerSportClick from '../../hooks/useHandleDrawerSportClick';
import { SportsType } from '../../types/drawer.types';
import { DrawerUI } from '../DrawerUI';

const VirtualDrawerSectionLayout02 = () => {
  const { t } = useTranslation();
  const { virtualSection: virtualMenu } = useDrawerConfig();
  const { handleSportClick } = useHandleDrawerSportClick();
  return (
    <>
      {virtualMenu?.map((item) => (
        <DrawerUI.Link
          key={item.id}
          onClick={() =>
            handleSportClick({
              sportType: SportsType.Virtuals,
              sportId: item.sportId,
              fullPath: item.route,
            })
          }
          isActive={location.pathname.includes(item.route)}
          text={t(Drawer.DrawerLinks[`${item.translation}`])}
          logo={item.code}
          dataQid={item.route}
        />
      ))}
    </>
  );
};

export default VirtualDrawerSectionLayout02;
