import { ProvidersTypes } from '../types/casinoGameProvidersTypes';

export const getProviderPath = (pathName: string, provider: string) => {
  // casino/providers/egt-interactive
  return `/${pathName.split('/')[1]}/providers/${provider}`;
};

export const clearAllProviderButtonHighlights = (): void => {
  const providersDestination = ['providers-bar', 'sidebar', 'slider'];

  providersDestination.forEach((destination) => {
    clearProviderButtonHighlights(destination);
  });
};

export const clearProviderButtonHighlights = (destination: string): void => {
  // remove current active button if any
  const currentActive = document.getElementsByClassName(`${destination} providers-bar__item--active`).item(0);
  if (currentActive) {
    currentActive.classList.remove('providers-bar__item--active');
    currentActive.classList.remove(destination);
  }
};

export const highlightProviderButton = (buttonRef: HTMLDivElement, destination: string): void => {
  clearProviderButtonHighlights(destination);
  buttonRef.classList.add(`providers-bar__item--active`);
  buttonRef.classList.add(destination);
};

export const normalizeProvidersData = (
  data: ProvidersTypes.ProviderWithType[],
): {
  providerAliasesByVerticals: Record<string, string[]>;
  providersDataByKey: Record<string, ProvidersTypes.ProviderDataByKey>;
  providersDataByIds: Record<string, ProvidersTypes.ProviderDataById>;
  providersDataByAliases: Record<string, ProvidersTypes.ProviderDataByAlias>;
  allProviderAliases: string[];
} => {
  const providerAliasesByVerticals = {};
  const providersDataByKey = {};
  const providersDataByIds = {};
  const providersDataByAliases = {};
  const sortedItemsRank = {};
  const allProviderAliases: string[] = [];
  const sortedIndex = (arr, n) => {
    const isDescending = arr[0] > arr[arr.length - 1];
    const index = arr.findIndex((el) => (isDescending ? n >= el : n <= el));
    return index === -1 ? arr.length : index;
  };

  data.forEach((item: ProvidersTypes.ProviderWithType) => {
    if (item.rootTypes) {
      const typeKeys = Object.keys(item.rootTypes);
      typeKeys.forEach((type) => {
        if (item.rootTypes && item.rootTypes[type]) {
          const itemKey = `${type}_${item.alias}`;
          if (!providerAliasesByVerticals[type]) {
            providerAliasesByVerticals[type] = [];
          }
          if (!sortedItemsRank[type]) {
            sortedItemsRank[type] = [];
          }
          const curRank = item.rootTypes[type].rank;
          const indexToAdd = sortedIndex(sortedItemsRank[type], curRank);
          providerAliasesByVerticals[type].splice(indexToAdd, 0, item.alias);
          sortedItemsRank[type].splice(indexToAdd, 0, item.rootTypes[type].rank);
          const { rootTypes } = item;
          const { scrollToCategory, searchGame, rank } = rootTypes[type];
          providersDataByKey[itemKey] = {};
          providersDataByKey[itemKey]['scrollToCategory'] = scrollToCategory;
          providersDataByKey[itemKey]['searchGame'] = searchGame;
          providersDataByKey[itemKey]['rank'] = rank;
        }
      });
      providersDataByIds[item.id] = { alias: item.alias, code: item.code };
      providersDataByAliases[item.alias] = {
        id: item.id,
        code: item.code,
        providerName: item.providerName,
        gamesCount: { ...item.gamesCount },
      };
      allProviderAliases.push(item.alias);
    }
  });

  return {
    providerAliasesByVerticals,
    providersDataByKey,
    providersDataByIds,
    providersDataByAliases,
    allProviderAliases,
  };
};

export const getCasinoProviderKey = (casinoType: string, providerAlias: string | undefined): string => {
  return `${casinoType}_${providerAlias}`;
};
