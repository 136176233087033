import { useEffect, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { triggerGTMEvent } from 'shared/common/features/seo/gtm/GoogleTagManager';

const GTMEventTriggerer = () => {
  const location = useLocation();

  useEffect(() => {
    /*
      For TESTING purpose
      Create custom GTM pageview event on every url change
    */
    triggerGTMEvent({ eventKey: 'custom-page-view' });
  }, [location.pathname]);
  return <></>;
};

export default memo(GTMEventTriggerer);
