import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';

export const useHideCookiesConsentFromSvara = () => {
  const hideCookiesConsent = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.SVARA_APP_COOKIES_CONSENT,
    deviceLayout: 'general',
  });

  return hideCookiesConsent;
};
