import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { ChallengesPopover } from './ChallengesPopover';
import { selectIntegrations } from '../../../../../../../shared/common/features/integrations/slice/integrations.slice';
import useCasinoWidgetConfig from '../../../../../platformImplementation/hooks/useCasinoWidgetConfig';
import { DEFAULT_GAME_ID_LOBBY } from '../../../gameLaunch/casinoLaunchConstants';
import { casinoLaunchSelectors } from '../../../gameLaunch/casinoLaunchSlice';
import { useChallengesGamesFetchStatus } from '../../hooks/useChallengesGamesFetchStatus';
import { useGamesChallengesPolling } from '../../hooks/useGamesChallengesPolling';
import { gamesBonusSelectors, updateGamesChallenges } from '../../slice/gameLaunchBonus.slice';
import { GameLaunchBonusTypes } from '../../types/gameLaunchBonus.types';
import { TopBarButton } from '../TopBarButton';

type Props = {
  type: string;
  gameId: string;
  text?: string;
  icon?: string;
  isFreePlay: boolean;
};

export const ChallengesButton = ({ isFreePlay, type, text, icon }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showAnimation, setShowAnimation] = useState<GameLaunchBonusTypes.Challenge[]>([]);
  const [highestProgress, setHighestProgress] = useState<number>(0);
  const { challengesGamesFetchStatusSuccess } = useChallengesGamesFetchStatus();
  const casinoWidgetConfig = useCasinoWidgetConfig();
  const launchData = useAppSelector(casinoLaunchSelectors.launchData);
  const widgetGameData = useAppSelector(selectIntegrations.casinoWidgetGameData);
  const gameId = casinoWidgetConfig ? widgetGameData.id : launchData?.gameId || DEFAULT_GAME_ID_LOBBY;
  useGamesChallengesPolling(gameId, isFreePlay);
  const gameChallenges = useAppSelector(gamesBonusSelectors.challenges);

  useEffect(() => {
    setShowAnimation(gameChallenges?.filter((x) => x.progress === 1 && !x?.isRead));
    !isEmpty(gameChallenges) && setHighestProgress(Math.max(...gameChallenges.map((x) => x.progress)) * 100);
  }, [gameChallenges, setHighestProgress, setShowAnimation]);

  const handleUpdateChallenges = () => {
    if (!isEmpty(showAnimation) && showAnimation) {
      const ids: GameLaunchBonusTypes.UpdateChallenge[] = showAnimation.map((challenge) => {
        return {
          playerMissionId: challenge.playerMissionId,
          missionId: challenge.missionId,
        };
      });
      dispatch(updateGamesChallenges(ids));
    }
  };

  return (
    <TopBarButton
      disabled={!challengesGamesFetchStatusSuccess}
      type={type}
      icon={icon}
      text={text && t(text)}
      progress={!isEmpty(gameChallenges) ? { value: highestProgress } : undefined}
      handleClick={handleUpdateChallenges}
      restrictForFreePlay={isFreePlay}
      renderChildren={(btnRef, handleClose, show) => (
        <ChallengesPopover handleClose={handleClose} parentRef={btnRef} items={gameChallenges} show={show} />
      )}
    />
  );
};
