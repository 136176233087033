import React from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { cssBurgerNS, gridStates } from './burgerUI.types';

/** TilesGrid */
interface TilesGridProps {
  className?: string;
  children?: React.ReactNode;

  isTable?: boolean;
}

const TilesGrid: React.FC<TilesGridProps> = ({ className, children, isTable }) => {
  const wrapperClass = clsx(
    `${cssBurgerNS.Tile}__grid`,
    isTable ? `${cssBurgerNS.Tile}__grid--table` : 'd-grid',
    className,
  );

  return (
    <motion.div
      className={wrapperClass}
      transition={{ ease: 'easeInOut', duration: 0.25 }}
      animate="visible"
      exit="exit"
      variants={gridStates}
      initial="hidden"
    >
      {children}
    </motion.div>
  );
};

export default TilesGrid;
