import cell03StyleLoader from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell03/Cell03StyleLoader';
import { LayoutTypes } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/LayoutTypes';
import {
  ComponentType,
  StyledNames,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/layoutWrapperConstants';
import useGameDescription from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/hooks/useGameDescription';
import { providerIdToSVG } from 'modules/casino/shared/utils/helpersCasino';

const Description: React.FC<LayoutTypes.DescriptionDataProps> = ({ gameUniqueId, categoryId }) => {
  const { description, gameProviderId } = useGameDescription({ gameUniqueId, categoryId });
  const DescriptionStyled = cell03StyleLoader(StyledNames.DescriptionStyled, ComponentType.data);
  const ProviderLogoStyled = cell03StyleLoader(StyledNames.ProviderLogoStyled, ComponentType.data);
  const MetaInfoStyled = cell03StyleLoader(StyledNames.MetaInfoStyled, ComponentType.data);

  return (
    <MetaInfoStyled>
      <DescriptionStyled className="text-truncate live-cell__descriptions">{description}</DescriptionStyled>
      {providerIdToSVG[gameProviderId] && <ProviderLogoStyled $icon={providerIdToSVG[gameProviderId]} />}
    </MetaInfoStyled>
  );
};
Description.displayName = 'Description';
export default Description;
