import styled from 'styled-components';
import { cssBurgerNS } from './burgerUI.types';

export const SCTileBadge = styled.div.attrs({ className: `${cssBurgerNS.Tile}__badge` })`
  top: 0;
  right: 0;

  font-size: toRem(10);
  font-weight: 700;
  line-height: 1;
`;
