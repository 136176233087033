import Cell02DataStyles from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell02/components/data/styles';
import Cell02DefaultStyles from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell02/components/default/styles';
import {
  ComponentType,
  StyledNames,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/layoutWrapperConstants';

const components = {
  [StyledNames.GameMediaStyled]: {
    [ComponentType.default]: Cell02DefaultStyles.GameMediaStyled,
    [ComponentType.data]: Cell02DataStyles.GameMediaStyled,
  },
  [StyledNames.ProviderLogoStyled]: {
    [ComponentType.data]: Cell02DataStyles.ProviderLogoStyled,
  },
  [StyledNames.DescriptionStyled]: {
    [ComponentType.data]: Cell02DataStyles.DescriptionStyled,
  },
  [StyledNames.MetaInfoStyled]: {
    [ComponentType.data]: Cell02DataStyles.MetaInfoStyled,
  },
  [StyledNames.DataCharactersWrapperStyled]: {
    [ComponentType.data]: Cell02DataStyles.DataCharactersWrapperStyled,
  },
};

const cell02StyleLoader = (styledComponentName, styledType) => {
  return components[styledComponentName][styledType];
};

export default cell02StyleLoader;
