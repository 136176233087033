import React, { MouseEventHandler } from 'react';

/** Terminal Modal Link */
interface LinkProps {
  text: string;
  onClick: MouseEventHandler<HTMLDivElement>;
}

const Link: React.FC<LinkProps> = ({ text, onClick }) => {
  const cssNS = 'terminal-modal__link';

  return (
    <div className={`${cssNS}`} onClick={onClick}>
      {text}
    </div>
  );
};
export default Link;
