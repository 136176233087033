import React, { memo, RefObject } from 'react';
import CurrencySwapper from 'modules/casino/shared/components/common/CurrencySwapper';
import { Flex } from 'modules/casino/shared/components/layout';
import { useCurrencyConfig } from 'modules/casino/shared/hooks/useCurrencyConfig';
import { useSetRefsLoaded } from 'modules/casino/shared/hooks/useSetRefsLoaded';
import { useAppSelector } from 'store';
import { useJackpotLevelScale } from '../../hooks/useJackpotLevelScale';
import { JackpotOdometer } from '../../jackpotOdometer';
import { jackpotSelectors } from '../../jackpotSlice';

interface Props {
  level: string;
  jackpotId: string;
  panelUrl: string;
  refObj: RefObject<HTMLDivElement>;
  stopOdometer?: boolean;
}

const JackpotLevel: React.FC<Props> = ({ level, jackpotId = '0', panelUrl, refObj, stopOdometer = false }) => {
  const { setRefs, ref } = useSetRefsLoaded();

  const { imageSource } = useJackpotLevelScale({ refObj, scaleRef: ref, panelUrl });

  const levelStyles = panelUrl
    ? {
        backgroundImage: `url(${imageSource})`,
      }
    : {};

  const currency = useAppSelector((state) => jackpotSelectors.selectJackpotCurrencySign(state, jackpotId));
  const { currencySymbolFirst, spaceBetween } = useCurrencyConfig();

  return (
    <div className="jackpot__number--wrapper d-flex-center" style={levelStyles} ref={setRefs}>
      <CurrencySwapper
        sumComponent={
          <div className="jackpot__number">
            <JackpotOdometer jackpotId={jackpotId} level={level} stopOdometer={stopOdometer} />
          </div>
        }
        currencyComponent={
          <Flex.Center className="jackpot__currency">
            <span>{currency}</span>
          </Flex.Center>
        }
        currencySymbolFirst={currencySymbolFirst}
        spaceBetween={spaceBetween}
      />
    </div>
  );
};

export default memo(JackpotLevel);
