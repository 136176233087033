import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { FormStatusType, InputLocationType } from 'components/shared/forms/formTypes';
import FormGroup from './FormGroup';
import { FormUI } from './FormUI';
import VerificationBadge from './VerificationBadge';

interface PhoneInputProps {
  componentLocation?: InputLocationType;
  name: string;
  value?: string;
  label: string;
  errorMessage?: string;
  hasError?: boolean;
  hasSuccess?: boolean;
  hasInfo?: boolean;
  classNames?: string;
  preferredOptions?: Array<string>;
  message?: string;
  onChangeHandler?: (phoneNumber: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  status?: FormStatusType;
}

const PhoneInputSelect: React.FC<PhoneInputProps> = ({
  componentLocation,
  name,
  value,
  label,
  errorMessage,
  hasError,
  hasSuccess,
  hasInfo,
  classNames,
  preferredOptions,
  message,
  onChangeHandler,
  onFocus,
  onBlur,
  disabled,
  status,
}) => {
  return (
    <FormGroup className={classNames}>
      <FormUI.Label componentLocation={componentLocation} label={label} />
      <div className="phone-input-wrapper">
        <FormUI.ControlWrapper
          type="phoneInput"
          disabled={disabled}
          hasError={hasError}
          hasSuccess={hasSuccess}
          hasInfo={hasInfo}
          className="phone-input-field"
        >
          <PhoneInput
            country={preferredOptions?.[0]}
            preferredCountries={preferredOptions}
            onChange={onChangeHandler}
            countryCodeEditable={false}
            value={value}
            containerClass="rti input-group"
            buttonClass="input-group-prepend"
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={name}
            disabled={disabled}
            disableDropdown={disabled}
            data-qid="phoneNumber"
          />
          {errorMessage && <FormUI.Message type="error" content={errorMessage} />}
          {hasInfo && <FormUI.Message type="info" content={message} />}
        </FormUI.ControlWrapper>
        {status && componentLocation === 'myAccount' && <VerificationBadge status={status} />}
      </div>
    </FormGroup>
  );
};

export default PhoneInputSelect;
