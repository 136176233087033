import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnyAction } from 'redux';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { useAppDispatch } from 'store';
import { TerminalModalUI } from '../ssbt/components/ssbtModalUI';

type Props = {
  errorMessage: string;
  resetStatus?: () => AnyAction;
};
const PaperCouponErrorModal: React.FC<Props> = ({ errorMessage, resetStatus }) => {
  const { closeModal } = useHandleModals();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleClose = () => {
    closeModal();
    if (resetStatus) {
      dispatch(resetStatus());
    }
  };
  return (
    <TerminalModalUI.Wrapper isVisible={true}>
      <TerminalModalUI.Close text={t(`SSBT.TerminalModal.closeTerminalModalText`)} onClick={handleClose} />
      <TerminalModalUI.Body>
        <TerminalModalUI.Status status="error" text={t(`RETAIL.ERROR.${errorMessage}`)} />
      </TerminalModalUI.Body>
    </TerminalModalUI.Wrapper>
  );
};
export default PaperCouponErrorModal;
