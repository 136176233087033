import React from 'react';

/** Auth Form Wrapper */
interface Props {
  children?: React.ReactNode;
}

const FormWrapper: React.FC<Props> = ({ children }) => {
  return <div className="h-100 auth-form-wrapper">{children}</div>;
};

export default FormWrapper;
