import { useEffect } from 'react';
import { RequestStatuses } from 'modules/casino/shared/constants';
import { useMobileAppRestrictions } from 'modules/casino/shared/features/mobileAppRestrictions/useMobileAppRestrictions';
import { useInterval } from 'modules/casino/shared/hooks/useInterval';
import { useAppDispatch, useAppSelector } from 'store';
import { casinoWidgetsSelectors, fetchLastWinners } from '../../../slice/casinoWidgetsSlice';
import { getLastWinnersPullInterval } from '../LastWinnersWidgetsUtils';
const savedLastWinnersWidgetIds: string[] = [];

export const useFetchLastWinners = ({
  id,
  query,
  fallbackInterval,
  pullInterval,
}: {
  id: string;
  query: string;
  fallbackInterval: number;
  pullInterval?: number;
}) => {
  const dispatch = useAppDispatch();
  const lastWinnersLoadingStatus = useAppSelector((state) =>
    casinoWidgetsSelectors.lastWinnersLoadingStatus(state, id),
  );
  const mobileAppRestrictions = useMobileAppRestrictions();

  useInterval(() => {
    if (
      query &&
      id &&
      (lastWinnersLoadingStatus === RequestStatuses.INITIAL || lastWinnersLoadingStatus === RequestStatuses.EMPTY)
    ) {
      dispatch(fetchLastWinners({ query, id, mobileAppRestrictions }));
    }
  }, getLastWinnersPullInterval({ pullInterval, fallbackInterval }));

  useEffect(() => {
    if (query && id && !savedLastWinnersWidgetIds.includes(id)) {
      dispatch(fetchLastWinners({ query, id, mobileAppRestrictions }));
      savedLastWinnersWidgetIds.push(id);
    }
  }, [dispatch]);
};
