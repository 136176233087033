import { useEffect } from 'react';

export const useChunkFailedErrorHandler = (): void => {
  useEffect(() => {
    const errorHandler = (e) => {
      const chunkNumberSaved = sessionStorage.getItem('chunkNumber');
      const chunkNumber = e.message?.match(/\d+/)?.[0];
      // allow only 1 reload per chunk
      if (/Loading chunk [\d]+ failed/.test(e.message) && chunkNumberSaved !== chunkNumber) {
        sessionStorage.setItem('chunkNumber', chunkNumber);
        window.location.reload();
      }
    };
    // clear storage if page is loaded and user interacted
    const clearStorage = () => sessionStorage.removeItem('chunkNumber');

    window.addEventListener('error', errorHandler);
    document.addEventListener('click', clearStorage, { once: true });
    return () => {
      window.removeEventListener('error', errorHandler);
      document.removeEventListener('click', clearStorage);
    };
  }, []);
};
