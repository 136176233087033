import React, { memo } from 'react';
import { ExternalCasinoCategory } from 'modules/casino/modules/container/cellsContainer/containers/external/externalWrapper/ExternalCasinoCategory';
import InGameCategoryWrapper from 'modules/casino/modules/container/cellsContainer/containers/external/InGameCategories/InGameCategoryWrapper';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import CasinoContainerImplementation from 'modules/casino/platformImplementation/casinoWidgetTypes/CasinoContainerImplementation';
import useCasinoWidgetConfig from 'modules/casino/platformImplementation/hooks/useCasinoWidgetConfig';
import { WidgetContainerTypes } from 'modules/casino/platformImplementation/types/casinoWidget.types';

const InGameRecommendedCategory: React.FC = () => {
  const casinoWidgetConfig = useCasinoWidgetConfig();

  if (casinoWidgetConfig) {
    return (
      <CasinoContainerImplementation
        containerKey={'in-game-recommended'}
        containerType={WidgetContainerTypes.CATEGORY}
        params={{
          categoryType: CategoryLayoutTypes.EXTERNAL_CATEGORY,
          subType: CategorySubTypes.IN_GAME_RECOMMENDED,
        }}
      />
    );
  }
  return (
    <InGameCategoryWrapper>
      <ExternalCasinoCategory
        subType={CategorySubTypes.IN_GAME_RECOMMENDED}
        cacheKey={'in-game-recommended'}
      ></ExternalCasinoCategory>
    </InGameCategoryWrapper>
  );
};

export default memo(InGameRecommendedCategory);
