import { createSlice } from '@reduxjs/toolkit';
import { triggerLoginActions } from 'pages/auth/login/slice/login.slice';
import UserStorage from 'pages/auth/login/UserStorage';
import { getPlayerData } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { PhoneConfirmationTypes } from '../types/phoneConfirmation.types';
import { defaultOtpInput } from '../utils/poneConfirmation.utils';

const initialState: PhoneConfirmationTypes.State = {
  playerId: '',
  phoneNumber: '',
  newCodeCoolOff: '',
  phoneCoolOff: '',
  isValidPhoneCode: null,
  isLoadingCheckCode: false,
  isLoadingResendCode: false,
  confirmationError: '',
  attemptsLeft: '',
  hasAttemptsLeft: true,
  field: defaultOtpInput({ inputMode: 'tel', replacePattern: '[^0-9]' }),
  communicationChannel: '',
};

const apiUrl: Record<PhoneConfirmationTypes.VerificationType, { check: string; generate: string }> = {
  player: {
    check: '/api/ews-crm/player/players/verify-phone-code',
    generate: '/api/ews-crm/player/players/generate-phone-verification-code',
  },
  public: {
    check: '/api/ews-crm/public/verify-phone-code',
    generate: '/api/ews-crm/public/player/generate-phone-verification-code',
  },
  reset_password: {
    check: '/api/ews-crm/public/verify-phone-code',
    generate: '/api/ews-crm/public/users/send-forgotten-password-sms',
  },
  mfa_extended: {
    check: '/api/ews-crm/public/players/verify-mfa-code',
    generate: '/api/ews-crm/public/players/generate-mfa-code',
  },
};

export const PhoneConfirmationThunks: PhoneConfirmationTypes.Thunks = {
  generatePhoneVerificationCode: createAbortThunk(
    'phoneConfirmation/generatePhoneVerificationCode',
    async ({ isNewCodeRequest, type = 'player', playerId, phoneNumber, data, channel }, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post(`${config.API_URL}${apiUrl[type].generate}`, {
          isNewCodeRequest,
          ...(data ? data : {}),
          ...(playerId ? { playerId } : {}),
          ...(channel ? { channel } : {}),
          ...(phoneNumber ? { phoneNumber } : {}),
        });
        return response.data;
      } catch {
        return rejectWithValue(getError.default());
      }
    },
  ),
  checkPhoneVerificationCode: createAbortThunk(
    'phoneConfirmation/checkPhoneVerificationCode',
    async (
      {
        verificationCode,
        type = 'player',
        playerId,
        isAuthenticated,
        isResetPassword = false,
        closeAction,
        nextChannel,
        isLastStep,
      },
      { rejectWithValue, dispatch },
    ) => {
      try {
        const response = await axiosInstance.post(`${config.API_URL}${apiUrl[type].check}`, {
          isResetPassword,
          ...(playerId && { playerId }),
          ...(verificationCode && { verificationCode }),
          ...(nextChannel && { nextChannel }),
        });
        // Case: Extended MFA (BR)
        if (response.data?.mfa_code_model) {
          // 1. Not last channel verification success
          dispatch(setNewCodeCoolOff(response.data?.mfa_code_model?.receiveNewCodeDate));
          dispatch(setPhoneCoolOff(response.data?.mfa_code_model?.tokenExpiryDate));
        } else if (response.data?.access_token) {
          UserStorage.logout();
          // 2. Last channel verification success
          triggerLoginActions({ dispatch, responseData: response.data });
        }
        if (isAuthenticated) {
          // Case: Phone Confirmation after login
          await dispatch(getPlayerData());
        }
        isLastStep && closeAction();
        return response.data;
      } catch (e) {
        return rejectWithValue({
          message: getError.responseDataMessage(e, type === 'mfa_extended' ? 'Message' : 'message'),
          attemptsLeft: getError.responseDataProp(e, 'attemptsLeft') || '',
          statusCode: getError.responseDataProp(e, 'statusCode'),
        });
      }
    },
  ),
};

const phoneConfirmation = createSlice({
  name: 'phoneConfirmation',
  initialState,
  reducers: {
    setPlayerId(state, action) {
      state.playerId = action.payload;
    },
    setCommunicationChannel(state, action) {
      state.communicationChannel = action.payload;
    },
    setPlayerPhoneNumber(state, action) {
      state.phoneNumber = action.payload;
    },
    setPhoneCoolOff(state, action) {
      state.phoneCoolOff = action.payload;
    },
    setNewCodeCoolOff(state, action) {
      state.newCodeCoolOff = action.payload;
    },
    resetConfirmationError(state) {
      state.confirmationError = '';
    },
    resetPhoneConfirmationState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(PhoneConfirmationThunks.generatePhoneVerificationCode.fulfilled, (state, action) => {
        if (action.payload?.tokenExpiryDate) {
          state.phoneCoolOff = action.payload?.tokenExpiryDate;
        }
        if (action.payload?.phoneNumber) {
          state.phoneNumber = action.payload?.phoneNumber;
        }
        if (action.payload?.playerId) {
          state.playerId = action.payload?.playerId;
        }
        if (action.payload?.receiveNewCodeDate) {
          state.newCodeCoolOff = action.payload?.receiveNewCodeDate;
        }
        if (action.payload?.confirmationCodeField) {
          state.field = action.payload?.confirmationCodeField;
        }
        state.hasAttemptsLeft = true;
        state.isLoadingResendCode = false;
      })
      .addCase(PhoneConfirmationThunks.generatePhoneVerificationCode.rejected, (state) => {
        state.phoneCoolOff = '';
        state.newCodeCoolOff = '';
        // state.communicationChannel = '';
        state.isLoadingResendCode = false;
      })
      .addCase(PhoneConfirmationThunks.generatePhoneVerificationCode.pending, (state) => {
        state.isLoadingResendCode = true;
      });

    builder
      .addCase(PhoneConfirmationThunks.checkPhoneVerificationCode.fulfilled, (state, action) => {
        state.isValidPhoneCode = !!action.payload;
        state.isLoadingCheckCode = false;
      })
      .addCase(PhoneConfirmationThunks.checkPhoneVerificationCode.pending, (state) => {
        state.isValidPhoneCode = null;
        state.isLoadingCheckCode = true;
      })
      .addCase(PhoneConfirmationThunks.checkPhoneVerificationCode.rejected, (state, action) => {
        state.isValidPhoneCode = false;
        state.isLoadingCheckCode = false;
        state.confirmationError = action.payload?.message || '';
        state.attemptsLeft = action.payload?.attemptsLeft || '';
        if (action.payload?.statusCode === 446 || action.payload?.attemptsLeft === '0') {
          state.hasAttemptsLeft = false;
        }
      });
  },
});

export const {
  resetPhoneConfirmationState,
  resetConfirmationError,
  setPlayerId,
  setPlayerPhoneNumber,
  setNewCodeCoolOff,
  setPhoneCoolOff,
  setCommunicationChannel,
} = phoneConfirmation.actions;

export default phoneConfirmation.reducer;

export const selectPhoneConfirmation = {
  isValidPhoneCode: (state: RootState) => state.auth.phoneConfirmation.isValidPhoneCode,
  isLoadingCheckCode: (state: RootState) => state.auth.phoneConfirmation.isLoadingCheckCode,
  isLoadingResendCode: (state: RootState) => state.auth.phoneConfirmation.isLoadingResendCode,
  phoneCoolOff: (state: RootState) => state.auth.phoneConfirmation.phoneCoolOff,
  newCodeCoolOff: (state: RootState) => state.auth.phoneConfirmation.newCodeCoolOff,
  error: (state: RootState) => state.auth.phoneConfirmation.confirmationError,
  attemptsLeft: (state: RootState) => state.auth.phoneConfirmation.attemptsLeft,
  hasAttemptsLeft: (state: RootState) => state.auth.phoneConfirmation.hasAttemptsLeft,
  field: (state: RootState) => state.auth.phoneConfirmation.field,
  playerId: (state: RootState) => state.auth.phoneConfirmation.playerId,
  channel: (state: RootState) => state.auth.phoneConfirmation.communicationChannel,
  phoneNumber: (state: RootState) => state.auth.phoneConfirmation.phoneNumber,
  isPlayerCreate: (state: RootState) =>
    state.auth.phoneConfirmation.phoneNumber && state.auth.phoneConfirmation.playerId,
};
