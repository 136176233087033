import styled, { CSSProp } from 'styled-components';
import { Breakpoints } from 'theme/Theme';

export const StyledCTATitle = styled.div.attrs(({ ...rest }) => ({
  ...rest,
}))<{ $styledCss?: CSSProp; $color?: string }>`
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
  color: ${({ $color }) => ($color ? $color : '#fce34c')};
  ${({ $styledCss }) => $styledCss}
  @media screen and (min-width: ${Breakpoints.isMobile}px) {
    font-size: 36px;
  }
`;
