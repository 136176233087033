import DataCharactersWrapperStyled from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell02/components/data/styles/DataCharactersWrapper.styled';
import DescriptionStyled from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell02/components/data/styles/Description.style';
import GameMediaStyled from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell02/components/data/styles/gameMedia.styled';
import MetaInfoStyled from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell02/components/data/styles/MetaInfo.styled';
import ProviderLogoStyled from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell02/components/data/styles/ProviderLogo.styled';

const Cell02DataStyles = {
  DescriptionStyled,
  GameMediaStyled,
  ProviderLogoStyled,
  MetaInfoStyled,
  DataCharactersWrapperStyled,
};

export default Cell02DataStyles;
