import i18next from 'i18next';
import { setIsPrintingEjected } from 'modules/retail/modules/cashier/slice/cashier.slice';
import { formatAmountToBRL } from 'modules/retail/modules/cashier/utils';
import {
  LastPartContentParams,
  MapFirstPartReceiptInfoParams,
  OutcomesData,
  PrintEventContentDataParams,
  PrintFirstPartDataParams,
  PrintSummaryContentParams,
  PrintTerminalDataParams,
  ProcessRegularBetSelectionParams,
  ProcessRegularBetValue,
  SummaryDataXparams,
} from 'modules/retail/modules/ssbt/types/ssbt.types';
import { enqueuePrintRequest, processPrintQueue } from 'modules/retail/shared/slice/printQueue.slice';
import { getRetailInfo } from 'modules/retail/shared/utils/retail.utils';
import { store } from 'store';
import { getError, toDecimal, toFixed } from 'utils/common/helpersCommon';
import { BetSlip } from 'utils/common/translationUtils/translationStrings';
import {
  mapEventContentRegularData,
  mapEventContentSingleData,
  mapEventContentSportEventName,
} from './ticketContentMethods';
import { mapFirstPartReceiptInfo } from './ticketFirstPartMethod';
import { mapLastPartContent } from './ticketLastPartMethod';
import { mapSummaryData, mapSummaryVirtualData } from './ticketSummaryMethods';
import { processCustomBetSelection } from '../betBuilderPrintMethods/betBuilder.utils';
import { printReceipt } from '../printUtils/helperPrintFunctions';

export const printTerminalData = async <T extends OutcomesData, K extends SummaryDataXparams>({
  isDuplicate,
  ticketId,
  timestamp,
  totalWin,
  outcomes,
  stake,
  summary,
  totalOdds,
  getState,
  totalCombinations,
  currency,
  retailInfo,
  isCurrencyBeforeAmount,
}: PrintTerminalDataParams<T, K>): Promise<void> => {
  const printTerminalJob = async () => {
    const { address, posName, pin, dailySequenceNumber, mnemonicCode, playerCpf, checkTicketRetailInfo } =
      getRetailInfo(retailInfo);
    const isLinearCodeOnReceipt =
      getState()?.common?.general?.layoutConfig?.['platform']?.general?.isTicketCodeLinear || false;
    const currencySettings = getState()?.common?.general?.currencySettings;
    const currencySymbol = currencySettings?.find((c) => c.name === currency)?.nameTranslations || currency;
    const _totalWin = {
      str: `${i18next.t(`BetSlip.PlaceBetButton.possibleWinText`)}: ${
        isCurrencyBeforeAmount
          ? `${currencySymbol} ${formatAmountToBRL(toDecimal(totalWin))}`
          : `${toFixed(toDecimal(totalWin), 2)} ${currency}`
      }`,
    };
    const _stake = {
      str: `${i18next.t(`BetSlip.BetSlipConfirmation.summaryTotalStakeText`)}: ${
        isCurrencyBeforeAmount ? `${currencySymbol} ${formatAmountToBRL(stake)}` : `${toFixed(stake, 2)} ${currency}`
      }`,
    };

    const printFirstPartDataParamsObj = {
      getState,
      currency,
      ticketId,
      timestamp,
      isDuplicate,
      address,
      posName,
      mnemonicCode,
      playerCpf,
    };

    const printEventContentDataParamsObj = {
      outcomes,
      summary,
      getState,
      currency,
      isCurrencyBeforeAmount,
    };

    const printSummaryContentParamsObj = {
      summary,
      getState,
      outcomes,
      currency,
      isCurrencyBeforeAmount,
    };

    const lastPartContentParamsObj = {
      _stake,
      _totalWin,
      getState,
      ticketId,
      totalOdds,
      totalCombinations,
      isDuplicate,
      currency,
      pin,
      dailySequenceNumber,
      outcomes,
      isLinearCodeOnReceipt,
      retailInfo,
      checkTicketRetailInfo,
      isCurrencyBeforeAmount,
    };

    try {
      store.dispatch(setIsPrintingEjected(true));

      await printFirstPartData(printFirstPartDataParamsObj);

      await printEventContentData(printEventContentDataParamsObj);

      await printSummaryContent(printSummaryContentParamsObj);

      await lastPartContent(lastPartContentParamsObj);
    } catch (error) {
      getError.message(error);
    } finally {
      store.dispatch(setIsPrintingEjected(false));
      // Process the next job in the queue if any
      store.dispatch(processPrintQueue());
    }
  };

  store.dispatch(enqueuePrintRequest(printTerminalJob));

  const state = store.getState();
  if (!state.retail.printQueue.isPrinting) {
    store.dispatch(processPrintQueue());
  }
};

export const printFirstPartData = async ({
  getState,
  ticketId,
  timestamp,
  isDuplicate,
  address,
  posName,
  mnemonicCode,
  playerCpf,
}: PrintFirstPartDataParams): Promise<void> => {
  const ticketIdData = {
    str: `${i18next.t(BetSlip.SSBT.ticketNumber)}: ${ticketId}`,
  };
  const retailAddress = getState()?.common?.general?.layoutConfig?.['platform']?.general?.retailAddress;
  const mapFirstPartReceiptInfoObj: MapFirstPartReceiptInfoParams = {
    ticketIdData,
    timestamp,
    retailAddress,
    isDuplicate,
    posAddress: address,
    posName,
    currentMnemonicCode: mnemonicCode,
    playerCpf: playerCpf ?? '',
  };
  try {
    await printReceipt(mapFirstPartReceiptInfo(mapFirstPartReceiptInfoObj));
  } catch (error) {
    getError.message(error);
  }
};

const processRegularBetSelection = async <
  T extends { outcomeId: string; selectedSystem: string; totalCombinations: number },
  K extends ProcessRegularBetValue,
>({
  value,
  getState,
  sportEventId,
  isSingle,
  getSingleBetProps,
  singles,
  outcomes,
  currency,
  isCurrencyBeforeAmount,
}: ProcessRegularBetSelectionParams<T, K>) => {
  const mapEventContentRegularDataObj = {
    value,
    getState,
    sportEventId,
    isSingle,
    getSingleBetProps,
    singles,
    outcomes,
    currency,
    isCurrencyBeforeAmount,
  };
  await printReceipt(mapEventContentRegularData(mapEventContentRegularDataObj));
};

export const printEventContentData = async <
  T extends OutcomesData,
  K extends {
    id: number;
    selectedSystem: string;
    outcomeId: string;
    totalCombinations: number;
    stake: number;
    win: number;
  },
>({
  outcomes,
  summary,
  getState,
  currency,
  isCurrencyBeforeAmount,
}: PrintEventContentDataParams<T, K>): Promise<void> => {
  const systems = summary.filter((x) => x.selectedSystem !== 'singles');
  const singles = summary.filter((x) => x?.selectedSystem === 'singles');
  const hasSystems = systems !== undefined && systems.length > 0;
  const filterInvalidSection = (selection): boolean => {
    if (hasSystems) return true;
    return singles.findIndex((x) => x.outcomeId === selection.id) !== -1;
  };
  const getSingleBetProps = (value) => {
    return singles.find((x) => x.outcomeId === value.id);
  };
  const filterOutcomes = outcomes.filter(filterInvalidSection);

  try {
    let hasPrintedEventDetails = false; // Flag to control one-time printing
    const groupedBySportEventId = {};

    // group by sportEventId
    for (const value of filterOutcomes) {
      const { sportEventId } = value;
      if (!groupedBySportEventId[sportEventId]) {
        groupedBySportEventId[sportEventId] = [];
      }
      groupedBySportEventId[sportEventId].push(value);
    }

    //  process each group
    for (const sportEventId in groupedBySportEventId) {
      const events = groupedBySportEventId[sportEventId];
      hasPrintedEventDetails = false; // Reset for each sportEventId group

      for (const value of events) {
        const isSingle = singles.findIndex((x) => x.outcomeId === value.id) !== -1;

        if (value.isCustomBetSelection) {
          const processCustomBetSelectionObj = {
            value,
            getState,
            isSingle,
            singles,
            getSingleBetProps,
            currency,
            isCurrencyBeforeAmount,
          };
          await processCustomBetSelection(processCustomBetSelectionObj);
        } else {
          const isVirtual = /^3/.test(sportEventId);

          if (isVirtual) {
            if (!hasPrintedEventDetails) {
              await printReceipt(mapEventContentSportEventName({ value }));
              hasPrintedEventDetails = true;
            }
            const mapEventContentSingleDataObj = {
              value,
              isSingle,
              getSingleBetProps,
              singles,
              outcomes,
              currency,
              isCurrencyBeforeAmount,
            };
            await printReceipt(mapEventContentSingleData(mapEventContentSingleDataObj));
          } else if (!isVirtual) {
            const processRegularBetSelectionObj = {
              value,
              getState,
              sportEventId,
              isSingle,
              getSingleBetProps,
              singles,
              outcomes,
              currency,
              isCurrencyBeforeAmount,
            };
            await processRegularBetSelection(processRegularBetSelectionObj);
          }
        }
      }
    }
  } catch (error) {
    getError.message(error);
  }
};

export const printSummaryContent = async <
  K extends SummaryDataXparams,
  T extends { id: string; sportEventId: number; sportId?: number; isCustomBetSelection?: boolean },
>({
  summary,
  getState,
  outcomes,
  currency,
  isCurrencyBeforeAmount,
}: PrintSummaryContentParams<T, K>): Promise<void> => {
  const isVermantia = outcomes.every((outcome) => outcome?.sportEventId?.toString()?.startsWith('3'));
  const isFootball = outcomes.every((outcome) => outcome?.sportId === 1001);
  const isVermantiaFootballOnly = isVermantia && isFootball;
  const selectionTypes = new Set<string>();
  try {
    for (const x of summary) {
      selectionTypes.add(x.selectedSystem);
      try {
        if (x.selectedSystem !== 'singles' && !isVermantiaFootballOnly) {
          const mapSummaryDataObj = { x, getState, toDecimal, currency, isCurrencyBeforeAmount };
          await printReceipt(mapSummaryData(mapSummaryDataObj));
        }
      } catch (err) {
        getError.message(err);
      }
    }
    if (selectionTypes.size > 0 && isVermantiaFootballOnly) {
      await printReceipt(mapSummaryVirtualData({ selectionTypes }));
    }
  } catch (err) {
    getError.message(err);
  }
};

export const lastPartContent = async <K extends OutcomesData>({
  _stake,
  _totalWin,
  getState,
  ticketId,
  totalOdds,
  totalCombinations,
  isDuplicate,
  currency,
  pin,
  dailySequenceNumber,
  outcomes,
  isLinearCodeOnReceipt,
  retailInfo,
  checkTicketRetailInfo,
}: LastPartContentParams<K>): Promise<void> => {
  const ticketIdData = {
    str: `${ticketId}`,
  };
  const retailAddress = getState()?.common?.general?.layoutConfig?.['platform']?.general?.retailAddress;
  const isVermantia = outcomes?.every((outcome) => outcome?.sportEventId?.toString()?.startsWith('3'));
  const isFootball = outcomes?.every((outcome) => outcome?.sportId === 1001);
  const isVermantiaFootballOnly = isVermantia && isFootball;

  const mapLastPartContentObj = {
    ticketIdData,
    totalOdds,
    _stake,
    _totalWin,
    dailySequenceNumber,
    terminalBetPin: pin,
    retailAddress,
    totalCombinations,
    isDuplicate: isDuplicate ?? false,
    isVermantiaFootballOnly: isVermantiaFootballOnly ?? false,
    isLinearCodeOnReceipt: isLinearCodeOnReceipt ?? false,
    retailInfo,
    currency,
    checkTicketRetailInfo,
  };
  try {
    await printReceipt(mapLastPartContent(mapLastPartContentObj));
  } catch (error) {
    getError.message(error);
  }
};
