import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { setMPDSectionActiveTab } from 'pages/myAccount/slice/mpdAccountTabs.slice';
import { refreshWidget } from 'shared/common/features/integrations/cashierWidget/cashierWidget.slice';
import { showMyProfileModal } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { MyProfileTabsEnum } from 'shared/common/features/myProfile/types/myProfile.types';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { CustomPlatformEvents } from 'shared/common/hooks/useHandleCustomEvents';
import { useAppDispatch, useAppSelector } from 'store';
import { LayoutConfigTypes } from '../../general/types/generalSlice.types';
import { setReadNotificationById } from '../../myProfile/sections/tabs/notificationsTab/notificationSlice';
import {
  BonusTypes,
  fetchCategories,
  resetOffersStatuses,
  selectOffersTab,
  setActiveCategoryTitle,
  setRedirectedOfferId,
} from '../../myProfile/sections/tabs/offersTab';

type Props = {
  bonusId: string;
  categoryAlias: string;
  notificationId: string;
  bonusType?: BonusTypes;
};

const usePostBonusActions = ({ bonusId, categoryAlias, notificationId, bonusType }: Props) => {
  const { status, statusCode } = useAppSelector((state) => selectOffersTab.bonusStatusAndError(state, bonusId));

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const successStatus = status === 'succeeded' || statusCode === 511;

  const layout =
    useLayoutConfigType({
      moduleLayout: 'platform',
      innerLayout: LayoutConfigTypes.LAYOUTTYPECONFIG,
      deviceLayout: 'general',
    })?.offersTab || 'layout_1';

  useEffect(() => {
    // If bonus accepted of bonus already accepted (511 status)
    if (bonusId && successStatus) {
      dispatch(hideRootModal());
      pathname?.includes('/help') && navigate('/sports');
      document.dispatchEvent(new Event(CustomPlatformEvents.FETCH_SPORT_BONUSES));
      dispatch(refreshWidget());
      if (bonusType !== BonusTypes.AWARD) {
        const tabKey = layout === 'layout_1' ? MyProfileTabsEnum.OFFERS : MyProfileTabsEnum.ACCOUNT;
        const navTab = layout === 'layout_1' ? undefined : 'OffersTab';

        dispatch(fetchCategories()).then(() => {
          dispatch(showMyProfileModal(true));
          layout === 'layout_1' && dispatch(setActiveCategoryTitle(categoryAlias));
          dispatch(setRedirectedOfferId(`${bonusId}`));
          dispatch(setReadNotificationById({ notificationId, notificationType: 'popup' }));
          dispatch(setMPDSectionActiveTab({ tabKey, navTab }));
        });
      }
    }
  }, [successStatus, bonusType, bonusId, categoryAlias, notificationId, dispatch, navigate, pathname]);

  useEffect(() => {
    return () => {
      dispatch(resetOffersStatuses());
    };
  }, [dispatch]);
};

export default usePostBonusActions;
