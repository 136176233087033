import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProviderDataByAlias } from 'modules/casino/modules/container/shared/features/casinoGameProviders/hooks/useProviderDataByAlias';
import { useProviderDataByKey } from 'modules/casino/modules/container/shared/features/casinoGameProviders/hooks/useProviderDataByKey';
import {
  gameProvidersSelectors,
  setActiveProviderStatus,
} from 'modules/casino/modules/container/shared/features/casinoGameProviders/slice/casinoGameProvidersSlice';
import { removeDashes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/utils/categoryBreadcrumbUtils';
import useGeneralCasinoType from 'modules/casino/shared/hooks/useGeneralCasinoType';
import { useAppDispatch, useAppSelector } from 'store';
import { customCasinoNavigate } from '../../../../../../modules/casino/platformImplementation/utils/casinoWidget.utils';
import { navigateToCasinoIntegration } from '../../../integrations/utils/integrations.utils';
import { setDrawer } from '../../slice/drawer.slice';
import { DrawerUI } from '../DrawerUI';

interface Props {
  rootType: string;
  providerAlias: string;
  verticalRoute?: string;
}

const CasinoProviderRow: React.FC<Props> = ({ rootType, providerAlias, verticalRoute }) => {
  const { casinoType } = useGeneralCasinoType();
  const activeProviderAlias = useAppSelector(gameProvidersSelectors.activeProvider);
  const { searchGame } = useProviderDataByKey(providerAlias, rootType);
  const { code: providerCode, providerName } = useProviderDataByAlias(providerAlias);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    dispatch(setActiveProviderStatus(providerAlias, searchGame));
    dispatch(setDrawer(false));

    if (`${verticalRoute}/providers/${providerAlias}` === window.location.pathname) return;
    navigateToCasinoIntegration({ fullPath: `${verticalRoute}/providers/${providerAlias}` });
    customCasinoNavigate({ path: `${verticalRoute}/providers/${providerAlias}`, navigate, options: {} });
    // navigate(`${verticalRoute}/providers/${providerAlias}`);
  }, [dispatch, providerAlias, verticalRoute, searchGame]);

  return (
    <DrawerUI.Link
      onClick={handleClick}
      isActive={activeProviderAlias === providerAlias && casinoType === rootType}
      logo={providerCode}
      text={providerName?.toUpperCase()}
      dataQid={removeDashes(providerAlias)}
    />
  );
};

export default memo(CasinoProviderRow);
