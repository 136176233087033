import { useEffect } from 'react';
import {
  addInitialCollection,
  setCollectionLoadMore,
} from 'modules/casino/shared/features/casinoUi/casinoUiStateSlice';
import { useAppDispatch } from 'store';

export const useInitialCollectionUi = (collectionId: string) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(addInitialCollection({ collectionId }));
    return () => {
      dispatch(setCollectionLoadMore({ collectionId, value: false }));
    };
  }, []);
};
