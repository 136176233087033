import React from 'react';
import clsx from 'clsx';

interface TextProps {
  text: string;
  isSecondary?: boolean;
  className?: string;
}

const Text: React.FC<TextProps & React.HTMLProps<HTMLParagraphElement>> = ({
  text,
  isSecondary,
  className,
  ...props
}) => {
  const textClass = clsx(className, isSecondary ? 'auth-text--secondary' : 'auth-text');

  return (
    <p className={textClass} {...props}>
      {text}
    </p>
  );
};

export default Text;
