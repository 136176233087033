import { useEffect } from 'react';
import { useGameCollectionId } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameCollectionId';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { triggerGTMEvent } from 'modules/casino/shared/features/seo/gtm/GoogleTagManager';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { CategoryImpressionsEventKeys } from '../constants/categoryImpressionsConstants';
import {
  categoryImpressionsSelectors,
  postCategoryImpressions,
  registerSentCategoryData,
} from '../slice/categoryImpressionsSlice';
import { getImpressionsParams } from '../utils/categoryImpressionsUtils';

type Props = {
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
};

export const useSendInitialImpressions = ({ categoryId, categoryLayoutType }: Props) => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const { gameCollectionId } = useGameCollectionId({ categoryId, categoryLayoutType });
  const isCategoryRegistered = useAppSelector((state) =>
    categoryImpressionsSelectors.isCategoryRegistered(state, gameCollectionId),
  );
  const isCategoryDataSent = useAppSelector((state) =>
    categoryImpressionsSelectors.isCategoryDataSent(state, gameCollectionId),
  );
  const registeredCategoryParams = useAppSelector((state) =>
    categoryImpressionsSelectors.registeredCategoryParams(state, gameCollectionId),
  );

  useEffect(() => {
    if (isCategoryRegistered && registeredCategoryParams?.items?.length && !isCategoryDataSent) {
      const { eventKey, params } = getImpressionsParams({
        key: CategoryImpressionsEventKeys.CATEGORY_VIEW,
        registeredCategoryParams,
      });

      if (isAuthenticated) {
        dispatch(
          postCategoryImpressions({
            params: { ...params, eventType: eventKey },
          }),
        );
      }

      triggerGTMEvent({ eventKey, additionalParams: params });
      dispatch(registerSentCategoryData({ gameCollectionId }));
    }
  }, [dispatch, isCategoryRegistered, isCategoryDataSent, registeredCategoryParams]);
};
