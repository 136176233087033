import React from 'react';
import Icon from 'components/shared/Icon';

interface Props {
  icon?: string;
  children?: React.ReactNode;
}

export const InputGroupPrepend: React.FC<Props> = ({ icon, children }) => {
  return (
    <div className="input-group-prepend">
      <span className="input-group-text">
        {icon && <Icon className="input-group-prepend__icon" icon={icon} />}
        {children}
      </span>
    </div>
  );
};

export default InputGroupPrepend;
