import React, { useCallback, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import CTAUI from 'shared/common/features/myProfile/components/CTAUI';
import { leftToRightAnimation } from 'shared/common/features/myProfile/utils/myProfile.utils';
import { useAppDispatch } from 'store';
import useTransitionTabs from '../../../hooks/useTransitionTabs';
import { ClubTabsTypes } from '../../types/clubTabs.types';
import { LinkItem } from '../ClubLinkItem';
import { ComponentMapping } from '../ComponentMapping';

export const CaptainUpTabLayout02: React.FC<ClubTabsTypes.LayoutKeyMapProps> = ({ tabs, singleTab, badgesCount }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { initialOpacity, controls } = useTransitionTabs(tabs);

  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpand = useCallback(() => {
    setIsExpanded((expand) => !expand);
  }, [dispatch]);

  return (
    <motion.div
      variants={leftToRightAnimation}
      className={` ${!singleTab ? 'mt-2' : ''}`}
      initial="closed"
      animate="open"
    >
      {tabs?.map((tab, idx) => (
        <motion.div
          initial={{ opacity: initialOpacity }}
          className="mpd-cta__wrapper--layout-02"
          key={idx}
          custom={idx}
          animate={controls}
        >
          {tab.route ? (
            <>
              <LinkItem tab={tab} showArrow />
            </>
          ) : (
            <>
              <CTAUI.Header
                dataQid={`mpd-offers-cat-${tab.label}`}
                icon={tab.icon || ''}
                title={t(tab.label)}
                isRead
                className="p-2"
                useIconPath={false}
                badgeCount={badgesCount[tab.key]}
                expandable
                isExpanded={isExpanded}
                onClick={handleExpand}
              />

              {isExpanded ? (
                <div className="p-2 mt-0 pr-0">
                  <ComponentMapping itemKey={tab.key as ClubTabsTypes.ComponentMappingType} />
                </div>
              ) : null}
            </>
          )}
        </motion.div>
      ))}
    </motion.div>
  );
};
