import isEqual from 'lodash/isEqual';
import { SportLobby } from 'utils/common/types/sportTypes';

const flatSportEventIds = (sports: SportLobby.SportData[]) =>
  sports?.map((sport) => sport?.eventIds)?.flat() ||
  sports?.map((sport) => sport?.dates?.map((date) => date?.eventIds)?.flat())?.flat() ||
  [];

export const updateSports = (
  stateContainer: SportLobby.Container,
  normalizedSports: SportLobby.SportData[],
  eventIds?: number[],
): void => {
  if (eventIds) {
    if (!isEqual(eventIds, stateContainer?.eventIds)) {
      stateContainer.eventIds = eventIds;
    }
  } else if (normalizedSports) {
    const currEventsIds = flatSportEventIds(stateContainer?.sports);
    const newEventsIds = flatSportEventIds(normalizedSports);

    if (!isEqual(newEventsIds, currEventsIds)) {
      stateContainer.sports = normalizedSports;
    }
  }
};
