import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { CategoryQueryType, RequestStatuses } from 'modules/casino/shared/constants';
import { DEFAULT_GAME_ID_LOBBY } from 'modules/casino/shared/features/gameLaunch/casinoLaunchConstants';
import { Casino } from 'modules/casino/shared/types';
import { createAbortThunk } from 'modules/casino/store/thunkCreators';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import axiosInstance from 'utils/common/axios-instance';
import { isEmpty } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { GridLayouts, GridRowType } from '../../../../grid/types/gridTypes';
import {
  EXCLUDED_PROVIDER_IDS,
  GameSectionTypes,
  PROVIDER_IDS_TO_EXCLUDE,
} from '../../../constants/casinoGamesConstants';
import { CasinoGamesTypes, GameLayoutEnum } from '../../../types/casinoGamesTypes';
import {
  generateGameUniqueId,
  getSpecialFavGamesData,
  normalizeGames,
  normalizeGamesData,
} from '../../../utils/casinoGamesUtils';
import { FAV_GAMES_FETCH_PARAMS } from '../constants/casinoFavouriteGameConstants';
import { CasinoFavouritesTypes } from '../types/casinoFavouriteGamesTypes';

const initialState: CasinoFavouritesTypes.State = {
  collection: {
    content: {},
    contentIds: [],
    ttl: 0,
  },
  gameIdsToAdd: [],
  hasGameIdsFetched: false,
  layoutGrid: GridLayouts.LAYOUT_1,
  isFavouriteGamesModalOpen: false,
  favoriteGamesLoadingState: RequestStatuses.INITIAL,
};

export const favouritesThunks: CasinoFavouritesTypes.Thunks = {
  fetchGames: createAbortThunk(
    'favourites/fetchGames',
    async ({ subType, layoutConfig, query, isInGame }, { source }) => {
      if (!query) {
        return {
          games: [],
          layoutGrid: layoutConfig?.layoutGrid || GridLayouts.LAYOUT_1,
        };
      }

      const gamesFetchURL = `${config.API_URL}${query}?page=${FAV_GAMES_FETCH_PARAMS.PAGE}&size=${FAV_GAMES_FETCH_PARAMS.SIZE}&excludeProviderIds=${EXCLUDED_PROVIDER_IDS}`;
      const response = await axiosInstance.get<CasinoGamesTypes.GameProps[]>(gamesFetchURL, {
        data: null,
        cancelToken: source.token,
      });

      const gameSection: Casino.GameSection | undefined = isInGame
        ? {
            type: GameSectionTypes.FAVOURITES,
            isActive: true,
            name: '',
            background: { staticLocation: '' },
            backgroundColor: '',
            textColor: '',
          }
        : undefined;

      const games = getSpecialFavGamesData({
        gameSection: gameSection,
        games: response.data,
      });

      return {
        games: normalizeGames({
          games: games,
          categoryLayoutType: CategoryLayoutTypes.FAVOURITES_CATEGORY,
          layoutGrid: layoutConfig?.layoutGrid || GridLayouts.LAYOUT_1,
          subType,
          hasNumbers: false,
          layoutRows: layoutConfig?.layoutRows || GridRowType.THREE_ROWS,
          queryType: CategoryQueryType.FAVOURITES_CATEGORY,
        }),
        layoutGrid: layoutConfig?.layoutGrid || GridLayouts.LAYOUT_1,
      };
    },
  ),
  fetchFavouritesGameIds: createAbortThunk(
    'favourites/fetchFavouritesGameIds',
    async ({ query = '/api/gaming/player/favourites/gameIds' }, { source }) => {
      const fetchURL = `${config.API_URL}${query}?excludeProviderIds=${EXCLUDED_PROVIDER_IDS}`;
      const response = await axiosInstance.get<string[]>(fetchURL, {
        data: null,
        cancelToken: source.token,
      });

      return {
        data: response.data,
      };
    },
  ),
};

const casinoFavouriteGames = createSlice({
  name: 'casinoFavouriteGames',
  initialState,
  reducers: {
    removeFavGame(state, action: PayloadAction<string>) {
      const gameIdToRemove = action.payload;

      state.collection.contentIds = [...state.collection.contentIds.filter((gameId) => gameId !== gameIdToRemove)];
      Object.values(state.collection.content).forEach((game) => {
        if (gameIdToRemove === game.id) {
          delete state.collection.content[game.uniqueId];
        }
      });

      if (state.favoriteGamesLoadingState !== RequestStatuses.INITIAL && !state.collection.contentIds?.length) {
        state.favoriteGamesLoadingState = RequestStatuses.EMPTY;
      }
    },
    addFavGameId(state, action: PayloadAction<string>) {
      const gameId = action.payload;
      if (gameId === DEFAULT_GAME_ID_LOBBY) return;
      gameId && !state.gameIdsToAdd.includes(gameId) && state.gameIdsToAdd.push(gameId);
    },
    resetFavGameIds(state) {
      state.gameIdsToAdd = [];
    },
    addFavGame(state, action: PayloadAction<CasinoGamesTypes.GameProps>) {
      const game = action.payload;

      if (state.collection.contentIds.includes(game?.id)) {
        return;
      }
      state.collection.contentIds.push(game?.id);
      const newFavGameUniqueId = generateGameUniqueId({
        categoryLayoutType: CategoryLayoutTypes.FAVOURITES_CATEGORY,
        gameLayout: GameLayoutEnum.REGULAR,
      });

      if (state.favoriteGamesLoadingState !== RequestStatuses.INITIAL) {
        state.collection.content[newFavGameUniqueId] = {
          ...game,
          gameLayout: GameLayoutEnum.REGULAR,
          uniqueId: newFavGameUniqueId,
          layoutGrid: state.layoutGrid,
        };
        state.favoriteGamesLoadingState = RequestStatuses.SUCCESS;
      }
    },
    setIsFavouriteGamesModalOpen(state, action: PayloadAction<boolean>) {
      state.isFavouriteGamesModalOpen = action.payload;
    },
    toggleFavGame(state, action: PayloadAction<{ game: CasinoGamesTypes.GameProps; toAdd?: boolean }>) {
      const { game, toAdd } = action.payload;
      if (PROVIDER_IDS_TO_EXCLUDE.includes(game?.providerId)) {
        return;
      }

      const newFavGameUniqueId = generateGameUniqueId({
        categoryLayoutType: CategoryLayoutTypes.FAVOURITES_CATEGORY,
        gameLayout: GameLayoutEnum.REGULAR,
      });

      if (toAdd) {
        if (!state.collection.contentIds.includes(game?.id)) {
          state.collection.contentIds.push(game?.id);
        }
        if (state.favoriteGamesLoadingState !== RequestStatuses.INITIAL) {
          state.collection.content[newFavGameUniqueId] = {
            ...game,
            gameLayout: GameLayoutEnum.REGULAR,
            uniqueId: newFavGameUniqueId,
            layoutGrid: state.layoutGrid,
          };
          state.favoriteGamesLoadingState = RequestStatuses.SUCCESS;
        }
      } else {
        const gameToRemove = Object.values(state.collection.content)?.find((item) => item.id === game?.id);

        if (gameToRemove) {
          delete state.collection.content[gameToRemove?.uniqueId];
        }

        state.collection = {
          ...state.collection,
          contentIds: state.collection.contentIds.filter((id) => id !== game?.id),
        };
        if (
          state.favoriteGamesLoadingState !== RequestStatuses.INITIAL &&
          Object.values(state.collection.content).length === 0
        ) {
          state.favoriteGamesLoadingState = RequestStatuses.EMPTY;
        }
      }
    },
    setUpdateFavouriteGamesIds(state, action: PayloadAction<{ gameId: string; isInFavourites: boolean }>) {
      const newGameId = action.payload.gameId;
      const isInFavourites = action.payload.isInFavourites;

      isInFavourites
        ? !state.collection.contentIds.includes(newGameId) && state.collection.contentIds.push(newGameId)
        : (state.collection.contentIds = [...state.collection.contentIds.filter((gameId) => gameId !== newGameId)]);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state) => {
      state.collection = initialState.collection;
      state.hasGameIdsFetched = initialState.hasGameIdsFetched;
      state.isFavouriteGamesModalOpen = initialState.isFavouriteGamesModalOpen;
      state.favoriteGamesLoadingState = RequestStatuses.INITIAL;
    });
    builder
      .addCase(favouritesThunks.fetchGames.pending, (state) => {
        state.favoriteGamesLoadingState = RequestStatuses.PENDING;
      })
      .addCase(favouritesThunks.fetchGames.fulfilled, (state, action) => {
        const { games, layoutGrid } = action.payload;
        const { content } = normalizeGamesData(games);
        const contentIds = games.map(({ id }) => id);
        state.layoutGrid = layoutGrid;
        state.collection = {
          content,
          contentIds,
          ttl: Date.now(),
        };
        if (isEmpty(games)) {
          state.favoriteGamesLoadingState = RequestStatuses.EMPTY;
        } else {
          state.favoriteGamesLoadingState = RequestStatuses.SUCCESS;
        }
      })
      .addCase(favouritesThunks.fetchGames.rejected, (state) => {
        state.favoriteGamesLoadingState = RequestStatuses.ERROR;
      })
      .addCase(favouritesThunks.fetchFavouritesGameIds.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.collection.contentIds = data;
        state.hasGameIdsFetched = true;
      });
  },
});

export const {
  setIsFavouriteGamesModalOpen,
  toggleFavGame,
  setUpdateFavouriteGamesIds,
  addFavGameId,
  resetFavGameIds,
  removeFavGame,
  addFavGame,
} = casinoFavouriteGames.actions;

const selectFavoriteGames = (state: RootState): CasinoGamesTypes.NormalizedGames =>
  state.casino.casinoFavouriteGames.collection.content;
const selectFavoriteGameUniqueIds = createSelector(
  selectFavoriteGames,
  (gamesContent: CasinoGamesTypes.NormalizedGames): string[] => {
    const games = Object.values(gamesContent);

    return games?.map(({ uniqueId }) => uniqueId) || [];
  },
);
const selectFavoriteGamesCount = (state: RootState): number =>
  state.casino.casinoFavouriteGames.collection.contentIds?.length || 0;
const selectHasGameIdsFetched = (state: RootState): boolean => state.casino.casinoFavouriteGames.hasGameIdsFetched;
const selectIsFavourite = (state: RootState, id: string): boolean =>
  !!state.casino.casinoFavouriteGames.collection.contentIds.find((gameId) => gameId === id) || false;
const selectIsFavouriteGamesModalOpen = (state: RootState): boolean =>
  state.casino.casinoFavouriteGames.isFavouriteGamesModalOpen;
const selectFavoriteGamesLoadingState = (state: RootState): RequestStatuses =>
  state.casino.casinoFavouriteGames.favoriteGamesLoadingState;
const selectGameIdsToAdd = (state: RootState): string[] => state.casino.casinoFavouriteGames.gameIdsToAdd;
const selectFavouritesCollection = (state: RootState): CasinoGamesTypes.NormalizedGames =>
  state.casino.casinoFavouriteGames.collection.content;
const selectFavouritesGamesIds = (state: RootState): string[] =>
  state.casino.casinoFavouriteGames.collection.contentIds;
// const selectLobbyCategory = createCachedSelector(
//   (state: RootState, lobbyId: string): CasinoLobbyTypes.LobbyItems => state.casino.casinoLobby.lobbyItems[lobbyId],
//   (state: RootState, lobbyId: string, categoryId): string => categoryId,
//   (lobbyItems, categoryId): Casino.LobbyCategory => lobbyItems?.categories[categoryId],
// )((_, id) => id);

const selectHasGamesToUpdate = createSelector(
  selectFavouritesCollection,
  selectFavouritesGamesIds,
  (games: CasinoGamesTypes.NormalizedGames, gameIds: string[]): boolean =>
    Object.values(games).length !== gameIds.length,
);

const selectGamesIdToRemove = createSelector(
  selectFavouritesCollection,
  selectFavouritesGamesIds,
  (games: CasinoGamesTypes.NormalizedGames, gameIds: string[]): string[] =>
    Object.values(games)
      .map((game) => game.id)
      .filter((id) => !gameIds.includes(id)),
);

export const favoriteGamesSelectors = {
  favoriteGames: selectFavoriteGames,
  favouriteGameUniqueIds: selectFavoriteGameUniqueIds,
  favoriteGamesCount: selectFavoriteGamesCount,
  hasGameIdsFetched: selectHasGameIdsFetched,
  isFavourite: selectIsFavourite,
  isFavouriteGamesModalOpen: selectIsFavouriteGamesModalOpen,
  favoriteGamesLoadingState: selectFavoriteGamesLoadingState,
  gameIdsToAdd: selectGameIdsToAdd,
  gameIdToRemove: selectGamesIdToRemove,
  hasGamesToUpdate: selectHasGamesToUpdate,
  favouritesGamesIds: selectFavouritesGamesIds,
};

const addGame = async ({ query = '/api/gaming/player/favourites', gameId }: CasinoFavouritesTypes.ToggleGameProps) => {
  return await axiosInstance.post(`${config.API_URL}${query}`, { gameId });
};

const removeGame = async ({
  query = '/api/gaming/player/favourites',
  gameId,
}: CasinoFavouritesTypes.ToggleGameProps) => {
  return await axiosInstance.delete(`${config.API_URL}${query}?gameId=${gameId}`, {
    data: null,
  });
};

export const toggleGameInFavouriteGames = (game: CasinoGamesTypes.GameProps, toAdd: boolean) => async (dispatch) => {
  dispatch(toggleFavGame({ game, toAdd }));

  try {
    if (toAdd) {
      await addGame({ gameId: game.id });
    } else {
      await removeGame({ gameId: game.id });
    }
  } catch (error) {
    dispatch(toggleFavGame({ game, toAdd: !toAdd }));
  }
};

export const toggleFavGameIdInTopBar = (gameId: string, toAdd: boolean) => {
  if (toAdd) {
    addGame({ gameId });
  } else {
    removeGame({ gameId });
  }
};

export default casinoFavouriteGames.reducer;
