import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import LeaderBoardBadge from 'modules/casino/modules/container/widget/widgetTypes/leaderBoardWidget/leaderBoardCellWidget/components/widgetLeaderBoardComponents/LeaderBoardBadge';
import LeaderBoardTimeCounter from 'modules/casino/modules/container/widget/widgetTypes/leaderBoardWidget/leaderBoardCellWidget/components/widgetLeaderBoardComponents/LeaderBoardTimeCounter';
import LeaderBoardTitle from 'modules/casino/modules/container/widget/widgetTypes/leaderBoardWidget/leaderBoardCellWidget/components/widgetLeaderBoardComponents/LeaderBoardTitle';
import { LeaderBoardWidgetTypes } from 'modules/casino/modules/container/widget/widgetTypes/leaderBoardWidget/leaderBoardCellWidget/LeaderBoardCellWidgetTypes';
import { SCCampaignItemInner, SCCampaignItemText } from './styled/CampaignInfo.styled';
import { leaderBoardCSSNS, LeaderBoardWidgetTranslationKeys } from '../../LeaderBoardCellWidgetConstants';

type Props = {
  leaderBoardInfo: LeaderBoardWidgetTypes.LeaderBoardWidgetData;
};

const LeaderBoardInfo: React.FC<Props> = ({ leaderBoardInfo }) => {
  const { t } = useTranslation();
  const { isActive, endDate, startDate, name } = leaderBoardInfo || {};
  const hasTimer = isActive ? !!endDate : !!startDate;

  return (
    <SCCampaignItemInner className={`${leaderBoardCSSNS}-widget--info-wrapper`}>
      <SCCampaignItemText className="d-flex-col-jb">
        <Flex.JB>
          <LeaderBoardTitle title={name || ''} className={`${leaderBoardCSSNS}-widget__title text-truncate`} />
          {!isActive && (
            <LeaderBoardBadge
              badgeText={t(LeaderBoardWidgetTranslationKeys.soonText)}
              className={`${leaderBoardCSSNS}-widget__title-badge`}
            />
          )}
        </Flex.JB>

        {hasTimer && (
          <LeaderBoardTimeCounter
            date={!isActive ? startDate : endDate}
            text={
              !isActive
                ? t(LeaderBoardWidgetTranslationKeys.startsInText)
                : t(LeaderBoardWidgetTranslationKeys.endsInText)
            }
            isColumn
          />
        )}
      </SCCampaignItemText>
    </SCCampaignItemInner>
  );
};

export default LeaderBoardInfo;
