import { useEffect } from 'react';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { CAPTAIN_UP_POLLING_INTERVAL } from 'pages/captainUp/utils/captainUp.utils';
import { LayoutConfigTypes, VerticalsConfigTypes } from 'shared/common/features/general/types/generalSlice.types';

import { useAppDispatch } from 'store';
import { fetchGamificationPlayer } from '../slice/gamificationPlayer.slice';

const usePollingGamificationProfile = () => {
  const dispatch = useAppDispatch();

  const isVisible = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.VERTICALS,
    deviceLayout: 'general',
  })?.[VerticalsConfigTypes.GAMIFICATION]?.isVisible;

  useEffect(() => {
    if (isVisible) {
      let promise = dispatch(fetchGamificationPlayer());

      const interval = setInterval(() => {
        promise = dispatch(fetchGamificationPlayer());
      }, CAPTAIN_UP_POLLING_INTERVAL);

      return () => {
        clearInterval(interval);
        promise && promise?.abort?.();
      };
    }
  }, [dispatch, isVisible]);
};

export default usePollingGamificationProfile;
