// eslint-disable-next-line import/no-cycle

import PrizeDropCampaignItem from './components/PrizeDropCampaignItem';
import PrizeDropGiftItem from './components/PrizeDropGiftItem';
import PrizeDropWrap from './components/PrizeDropGiftItemWrap';
import PrizeDropHeader from './components/PrizeDropHeader';
import PrizeDropIcon from './components/PrizeDropIcon';
import PrizeDropIsWin from './components/PrizeDropIsWin';
import PrizeDropIsWinVideo from './components/PrizeDropIsWinVideo';
import ModalFooter from './components/PrizeDropModalFooter';
import PrizeDropPromoTabsPrimary from './components/PrizeDropPromoTabsPrimary';
import PrizeDropPromoTabsSecondary from './components/PrizeDropPromoTabsSecondary';
import PrizeDropTable from './components/PrizeDropTableContainer';
import PrizeDropTabsPrimary from './components/PrizeDropTabsPrimary';
import PrizeDropTabsSecondary from './components/PrizeDropTabsSecondary';
import PrizeDropHTML from './components/PrizeDropTerms';
import PrizeDropTimeCounter from './components/PrizeDropTimeCounter';
import PrizeDropTitle from './components/PrizeDropTitle';

export const PrizeDropUI = {
  Wrap: PrizeDropWrap,
  Header: PrizeDropHeader,
  Title: PrizeDropTitle,
  isWin: PrizeDropIsWin,
  ModalFooter: ModalFooter,
  TimeCounter: PrizeDropTimeCounter,
  GiftItem: PrizeDropGiftItem,
  CampaignItem: PrizeDropCampaignItem,
  Table: PrizeDropTable,
  TabsPrimary: PrizeDropTabsPrimary,
  TabsSecondary: PrizeDropTabsSecondary,
  PromoTabsPrimary: PrizeDropPromoTabsPrimary,
  PromoTabsSecondary: PrizeDropPromoTabsSecondary,
  HTMLText: PrizeDropHTML,
  Icon: PrizeDropIcon,
  IsWinVideo: PrizeDropIsWinVideo,
};
