export const CURRENT_YEAR_PLACEHOLDER = '<<currentYear>>';

export declare module FooterTypes {
  type ThunkArgs = {
    apiUri?: string;
    language: string;
  };

  type Footer = { sections?: Section[]; verticals?: number[] };

  type Section = {
    autoScroll: boolean;
    depositButton: boolean;
    footerId: number;
    icon?: string;
    iconType?: 'small' | 'medium' | 'large';
    id: number;
    isHighlighted: boolean;
    ranking: number;
    options: OptionsProps[];
    items: Item[];
    text?: string;
    title?: string;
    typeName: ItemNames;
    name?: string;
  };
  type OptionsProps = {
    name: string;
    text?: string;
    type: string;
    options: OptionItems[];
  };
  type OptionItems = {
    text: string;
    value: string;
  };

  type Item = {
    externalLink?: string;
    footerSectionId: number;
    icon?: string;
    id: number;
    internalLink?: string;
    ranking: number;
    text?: string;
    title?: string;
    typeName?: string;
    itemLinks: ItemLinks[];
  };

  type ItemLinks = {
    enableChatbot: boolean;
    externalLink?: string;
    icon?: string;
    id: number;
    internalLink?: string;
    title?: string;
    typeName?: string;
    linkSocials: LinkSocials[];
  };
  type LinkSocials = {
    sectionItemLinkId: string;
    title?: string;
    icon?: string;
  };

  type ItemNames = 'Copyright' | 'Partners' | 'System' | 'Payments' | 'PoweredBy' | 'License' | 'Links';

  type DropdowItem = 'options-language' | 'options-odds' | 'options-timezones';

  type SectionType = 'primary' | 'secondary';

  // FOOTER SLICE TYPES
  type ApiResponse = {
    promotions: Promotion[];
  };

  type Promotion = {
    subTitle: string;
    alias: string;
    title: string;
    description: string;
    resource: string;
    id: number;
    categoryAlias: string;
    type?: 'REQUEST_BET';
  };

  type State = {
    isAppFooterVisible: boolean;
    hideAppFooter: boolean;
    showForLandingPage: boolean;
    promotions: Promotion[] | null;
    promoApiUri: string;
    footerApiUri: string;
  };
}
