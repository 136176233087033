import styled from 'styled-components';
import { InfoAnimation } from './Animation.styled';

const NextFeaturesWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  clip-path: circle(0 at 50% 90%);
  width: 100%;
  height: 100%;
  animation: ${InfoAnimation.ClipCircleSmall} 1s forwards ease-in-out;
  @media screen and (min-height: 800px) {
    animation: ${InfoAnimation.ClipCircle} 1s forwards ease-in-out;
  }
`;
const NextFeaturesInner = styled.div`
  opacity: 0;
  transform: translateX(300px);
  position: absolute;
  display: none;
  &.active {
    display: block;
    animation: ${InfoAnimation.Text} 0.5s forwards ease-in-out;
  }
`;

const NextFeaturesText = styled.div`
  padding: 5px 5px 0;
  max-width: 220px;
  text-align: center;
`;
const NextFeaturesButton = styled.div`
  padding: 10px 30px;
  font-weight: bold;
  border-radius: 100px;
  max-width: 130px;
  clear: both;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (min-height: 800px) {
    margin-bottom: 50px;
  }
`;

export const NextFeaturesUI = {
  Wrapper: NextFeaturesWrapper,
  Inner: NextFeaturesInner,
  Text: NextFeaturesText,
  Button: NextFeaturesButton,
};
