import React, { memo } from 'react';
import clsx from 'clsx';
import { browserVersion, isSafari } from 'react-device-detect';

interface Props {
  icon: string;
  id?: string;
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
  handleRef?: (node) => void;
}

const CasinoIcon: React.FC<Props> = ({ className, icon, onClick, handleRef, id }) => {
  const iconStyles = clsx(className);

  const getViewBox = () => {
    const svgSymbol = icon && document.getElementById(icon);
    if (svgSymbol && svgSymbol !== null) {
      const svgSymbolViewBox = svgSymbol.getAttribute('viewBox') || '0 0 24 24';
      const [, , svgSymbolViewBoxWidth, svgSymbolViewBoxHeight] = svgSymbolViewBox.split(' ');
      const difference = (a, b) => {
        return Math.abs(a - b);
      };

      if (difference(Number(svgSymbolViewBoxWidth), Number(svgSymbolViewBoxHeight)) > 5) {
        return svgSymbolViewBox;
      } else {
        return '0 0 24 24';
      }
    } else {
      return '0 0 24 24';
    }
  };

  return (
    <svg
      id={id && `${id}_svg`}
      viewBox={getViewBox()}
      ref={(node) => handleRef && handleRef(node)}
      className={iconStyles}
      onClick={onClick}
    >
      {browserVersion < '12' && isSafari ? (
        <use id={id && `${id}_use`} xlinkHref={`#${icon}`} />
      ) : (
        <use id={id && `${id}_use`} href={`#${icon}`} />
      )}
    </svg>
  );
};

export default memo(CasinoIcon);
