import { useRef } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { FooterTypes } from 'shared/common/features/appFooter/types/appFooter.types';
import { useMyProfileConfig } from 'shared/common/features/myProfile/hooks/useMyProfileConfig';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { getDefaultBusinessUnitThemeSkin } from 'shared/common/features/myProfile/utils/myProfile.utils';
import { useAppSelector } from 'store';
import config from 'utils/config';
interface SectionImageProps {
  /** Image location */
  imgRef: string;
  /** Image alt text */
  alt: string;
  /** Link Text */
  linkRoute?: string;
  staticRoute?: string;
  mailTo?: string;
  type: FooterTypes.ItemNames;
}

const SectionImage: React.FC<SectionImageProps> = ({ imgRef, alt, linkRoute, staticRoute, type }) => {
  const theme = useAppSelector(selectMyProfile.preferredTheme);

  const logoRef = useRef<HTMLImageElement>(null);
  const myProfileLayout = useMyProfileConfig();

  const preferredTheme = myProfileLayout?.enableThemeSwitcher ? theme : getDefaultBusinessUnitThemeSkin(); //THIS IS TEMPORARY HACK
  const externalLink = staticRoute
    ? staticRoute.includes('https://') || staticRoute.includes('http://')
      ? staticRoute
      : `https://${linkRoute}`
    : null;

  // TODO: Implement for other types as well
  const imageLink =
    type.toLocaleLowerCase() === 'partners'
      ? `${config.CDN_URL}/${imgRef.slice(0, -4)}${preferredTheme === 'Light' ? '-light-bg.svg' : '.svg'}`
      : `${config.CDN_URL}/${imgRef}`;

  const logoClass = clsx(`footer-logo footer-logo--${type.toLocaleLowerCase()}`);

  const Logo = () => <img ref={logoRef} src={imageLink} alt={alt ?? ''} className={logoClass} />;

  return staticRoute && externalLink ? (
    <a className="d-block footer-menu__link" target="_blank" href={externalLink} rel="noreferrer" title={alt}>
      <Logo />
    </a>
  ) : linkRoute ? (
    <Link to={linkRoute ? `/${linkRoute}` : '/'}>{<Logo />}</Link>
  ) : (
    <Logo />
  );
};

export default SectionImage;
