import React, { memo } from 'react';
import PlayerRestrictionsModal from 'pages/myAccount/tabs/responsibleGambling/components/PlayerRestrictionsModal';
import { useAppSelector } from 'store';
import { selectPartialtModalType, selectPartialtModalProps } from './slice/partialModal.slice';
import { partialModalTypes } from './types/partialModal.types';

/* eslint-disable @typescript-eslint/no-explicit-any */
const MODAL_COMPONENTS: Record<partialModalTypes, React.FC<any>> = {
  PLAYER_RESTRICTIONS_INFO: PlayerRestrictionsModal,
};

const PartialModal = () => {
  const modalType = useAppSelector(selectPartialtModalType);
  const modalProps = useAppSelector(selectPartialtModalProps);
  const body = document.body;

  if (!modalType) {
    body.classList.remove('overflow-hidden');
    return null;
  } else {
    body.classList.add('overflow-hidden');
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal {...modalProps} />;
};

export default memo(PartialModal);
