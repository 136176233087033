export enum SportIDs {
  //regular
  soccer = 1001,
  basketball = 1002,
  baseball = 1003,
  ice_hockey = 1004,
  tennis = 1005,
  handball = 1006,
  floorball = 1007,
  golf = 1009,
  boxing = 1010,
  motorsport = 1011,
  rugby = 1012,
  aussie_rules = 1013,
  bandy = 1015,
  american_football = 1016,
  cycling = 1017,
  specials = 1018,
  snooker = 1019,
  table_tennis = 1020,
  cricket = 1021,
  darts = 1022,
  volleyball = 1023,
  field_hockey = 1024,
  waterpolo = 1026,
  futsal = 1029,
  badminton = 1031,
  bowls = 1032,
  beach_volley = 1034,
  squash = 1037,
  formula_1 = 1040,
  alpine_skiing = 1043,
  biathlon = 1044,
  cross_country = 1046,
  ski_jumping = 1048,
  sailing = 1081,
  mma = 1117,
  indy_racing = 1129,
  speedway = 1131,
  gaelic_football = 1135,
  gaelic_hurling = 1136,
  touring_car_racing = 1188,
  motorcycle_racing = 1190,
  stock_car_racing = 1191,
  horse_racing = 1055,
  greyhound = 1058,
  beach_soccer = 1060,
  basketball_3x3 = 1155,
  rally = 1101,
  softball = 1054,
  pesapallo = 1061,
  olimpic_games = 1030,
  kabaddi = 1138,
  curling = 1028,

  //esport
  counter_strike = 1109,
  league_of_legends = 1110,
  dota_2 = 1111,
  starcraft = 1112,
  call_of_duty = 1118,
  overwatch = 1121,
  rainbow_six = 1125,
  rocket_league = 1128,
  esport_king_of_glory = 1134,
  esoccer = 1137,
  ebasketball = 1153,
  esport_arena_of_valor = 1158,
  eice_hockey = 1195,
  valorant = 1194,
  wild_rift = 1199,

  // app
  PROGRAM = 1,
  PREMATCH_PROGRAM = 2,
  INPLAY_VIDEO = 3,
  FAVOURITE = 4,
  INPLAY_ALL = 5,
  virtuals = 60003,
  allESports = 60002,
  ACCENTS = 33,
}

export const EXCLUDE_ESPORTS_ICONS = [SportIDs.ebasketball, SportIDs.esoccer, SportIDs.eice_hockey] as const;

export const SPORTS_WITH_SERVER = [
  SportIDs.tennis,
  SportIDs.volleyball,
  SportIDs.table_tennis,
  SportIDs.beach_volley,
  SportIDs.badminton,
  SportIDs.squash,
] as const;

export const GOAL_INDICATOR_SPORTS = [
  SportIDs.soccer,
  SportIDs.esoccer,
  SportIDs.handball,
  SportIDs.ice_hockey,
  SportIDs.eice_hockey,
  SportIDs.futsal,
  SportIDs.waterpolo,
  SportIDs.field_hockey,
  SportIDs.beach_soccer,
] as const;

export const mapSportIDsByKey = (sport: string | number | undefined | null) => {
  if (sport) {
    sport.toString().toLocaleLowerCase();
    return SportIDs[sport] || null;
  }
  return null;
};
