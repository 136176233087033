import React from 'react';
import clsx from 'clsx';
import { StatusIcon } from 'components/shared/icons';
import { BadgeVariants, badgeVariantsMap } from '../badgeTypes';

//** Component CSS Namespace */
interface Props {
  variant?: BadgeVariants;
  className?: string;
  label: React.ReactNode | string;
  children?: React.ReactNode;
  title?: string;
  handleOnClick: () => void;
}
export const BadgeLayout_1: React.FC<Props> = ({ variant, className, label, children, title, handleOnClick }) => {
  const showStatusIcon =
    variant &&
    [
      'statusPositive',
      'statusNegative',
      'statusError',
      'statusInfo',
      'statusNeutral',
      'statusSoldChips',
      'statusBuyIn',
      'statusRefund',
    ].includes(variant);

  const badgeClass = clsx(variant && badgeVariantsMap[variant].css, className);

  return (
    <span className={badgeClass} onClick={handleOnClick} title={title}>
      <>
        {variant && showStatusIcon && <StatusIcon spacingRight size="md" status={badgeVariantsMap[variant].status} />}
        {label}
        {children && children}
      </>
    </span>
  );
};
