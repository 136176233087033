import React from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';

interface Props {
  title: string | JSX.Element;
  className?: string;
}

const DropdownGroupHeader: React.FC<Props> = ({ className, title }) => {
  const itemClass = clsx('egtd-dropdown-group__header', className);

  return <Flex.AC className={itemClass}>{title}</Flex.AC>;
};

export default DropdownGroupHeader;
