import { memo, useCallback } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Flex } from 'components/shared/layout';
import { UI } from 'components/shared/UI';
import { useCurrency } from 'hooks/common/useCurrency';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { useFinancialReportManager } from 'modules/retail/modules/cashier/features/finance/useFinancialModal';
import { formatAmountToBRL } from 'modules/retail/modules/cashier/utils';
import { RetailHeaderIcons } from 'modules/retail/modules/ssbt/types/ssbt.types';
import { setDrawer } from 'shared/common/features/drawer/slice/drawer.slice';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { useAppDispatch } from 'store';
import { MyProfile, SSBT } from 'utils/common/translationUtils/translationStrings';

const RetailHeaderHandHeldBtn = () => {
  const { posInfo } = useFinancialReportManager();
  const { t } = useTranslation();
  const controls = useAnimation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isCurrencyBeforeAmount = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.CURRENCY_BEFORE_AMOUNT,
  });
  const { currencySymbol } = useCurrency();
  const balance = posInfo?.posInfo?.financialSettings?.bettingShopTemporaryLimit || '';
  const currency = posInfo?.posInfo?.financialSettings?.currency;

  const handleTapParent = useCallback(() => {
    controls.start(() => ({
      scale: [0.9, 1],
      transition: { ease: 'easeInOut', duration: 0.4 },
    }));
  }, [controls]);

  const handleClick = () => {
    handleTapParent();
    navigate('/handheld-deposit');
    dispatch(setDrawer(false)); // if in retail burger menu
  };
  return (
    <Flex.AC onClick={handleClick} className="rbtn__deposit">
      <Flex.Col className="rbtn__deposit-balance">
        <span className="rbtn__deposit-title">{t(MyProfile.Statistics.limitText)}</span>
        <div className="rbtn__deposit-sum">
          {isCurrencyBeforeAmount ? (
            <>
              <span className="rbtn__deposit-currency">{currencySymbol}</span> {formatAmountToBRL(balance)}
            </>
          ) : (
            <>
              {balance}
              <span className="rbtn__deposit-currency">{currency}</span>
            </>
          )}
        </div>
      </Flex.Col>
      <Flex.AC className="rbtn__deposit-btn">
        <motion.div className="egtd-flex-ac" animate={controls}>
          {t(SSBT.Navigation.deposit)}
          <UI.SVGCDN icon={RetailHeaderIcons.DEPOSIT} path="RETAIL" size={24} className="rbtn__deposit-icon" />
        </motion.div>
      </Flex.AC>
    </Flex.AC>
  );
};

export default memo(RetailHeaderHandHeldBtn);
