import { FooterUI } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { FooterTypes } from '../types/appFooter.types';

interface Props {
  section: FooterTypes.Section;
}

const ImageSection: React.FC<Props> = ({ section }) => {
  const sectionTypes = section.typeName.toLowerCase() as FooterTypes.ItemNames;
  if (!section?.items) {
    return null;
  }

  return (
    <>
      {section.items.map((item) => {
        return (
          <Flex.ColJC className={`footer-logo__wrapper--${section.iconType ?? 'default'}`} key={item.id}>
            <Flex.AC className="footer-logo__image">
              <FooterUI.SectionImage
                imgRef={item.icon ?? ''}
                alt={item.title ?? ''}
                linkRoute={item.internalLink ?? ''}
                staticRoute={item.externalLink ?? ''}
                type={sectionTypes}
              />
            </Flex.AC>
            {item.title && <div className="footer-logo__title">{item.title}</div>}
          </Flex.ColJC>
        );
      })}
    </>
  );
};

export default ImageSection;
