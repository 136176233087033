import React from 'react';
import { Flex } from 'components/shared/layout';
import { cssGamificationNS } from 'utils/common/types/css.types';
type Props = {
  number?: string | number;
};

export const TagCount: React.FC<Props> = ({ number }) => {
  if (!number) return null;
  return <Flex.Center className={`${cssGamificationNS}-tag ${cssGamificationNS}-tag__count`}>{number}</Flex.Center>;
};
