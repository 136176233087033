import { LayoutConfigInnerLayoutTypes } from 'modules/casino/shared/constants';
import { useCasinoLayoutConfig } from 'modules/casino/shared/hooks/useCasinoLayoutConfig';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import FetchLiveFeed from './FetchLiveFeed';
import { CategoryLayoutTypes } from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { useCasinoGamesFetchStatus } from '../hooks/useCasinoGamesFetchStatus';
import { useCategoryGames } from '../hooks/useCategoryGames';

type Props = {
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
};

const LiveCasinoUpdates: React.FC<Props> = ({ categoryId, categoryLayoutType }) => {
  const games = useCategoryGames({ categoryLayoutType, categoryId });
  const { alias } = useGetCategoryParams(categoryId, categoryLayoutType);
  const { hasSuccess } = useCasinoGamesFetchStatus(categoryId, categoryLayoutType);
  const liveFeed = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.LIVE_FEED });

  return liveFeed?.enablePooling ? (
    <FetchLiveFeed games={games} interval={liveFeed?.interval} hasSuccess={hasSuccess} isSvara={alias === 'svara'} />
  ) : (
    <></>
  );
};

export default LiveCasinoUpdates;
