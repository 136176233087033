import { CategoryQueryType } from 'modules/casino/shared/constants';

export const providerLobbyItemsFilterConditions = ({
  queryType,
  categoryIsPublic,
  isAuthenticated,
}: {
  queryType: CategoryQueryType;
  categoryIsPublic: boolean;
  isAuthenticated: boolean;
}) => {
  if (queryType === CategoryQueryType.LAST_PLAYED_CATEGORY) return false;
  if (!isAuthenticated && !categoryIsPublic) return false;

  return true;
};
