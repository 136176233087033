import React, { memo } from 'react';

const textWrapperTypeExtenderMap = {
  h1: HTMLHeadingElement,
  p: HTMLParagraphElement,
  span: HTMLSpanElement,
  div: HTMLDivElement,
} as const;

type TextWrapperType = keyof typeof textWrapperTypeExtenderMap;

type TextOptions = {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  text?: string | number | null | JSX.Element | JSX.Element[];
};

type Props = {
  type?: TextWrapperType;
} & TextOptions &
  Omit<React.HTMLProps<typeof textWrapperTypeExtenderMap>, 'onClick'>;

type RenderComponentFunc = React.FC<TextOptions>;

const textWrapperMap: Record<TextWrapperType, RenderComponentFunc> = {
  h1: ({ onClick, text, ...props }) => (
    <>
      {text && (
        <h1 onClick={onClick} {...props}>
          <>{text}</>
        </h1>
      )}
    </>
  ),
  p: ({ onClick, text, ...props }) => (
    <p onClick={onClick} {...props}>
      <>{text}</>
    </p>
  ),
  span: ({ onClick, text, ...props }) => (
    <span onClick={onClick} {...props}>
      <>{text}</>
    </span>
  ),
  div: ({ onClick, text, ...props }) => (
    <div onClick={onClick} {...props}>
      <>{text}</>
    </div>
  ),
};

const Text: React.FC<Props> = (props) => {
  const TextRenderer = textWrapperMap[props.type || 'span'];
  TextRenderer.displayName = 'TextRenderer';
  return <TextRenderer {...props} />;
};

export default memo(Text);
