import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { General } from 'shared/common/features/general/types/generalSlice.types';
import { isEmpty } from 'utils/common/helpersCommon';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import { CaptainUpTabs } from './components';
import { useFetchClub } from './hoocks/useClubTab';
import { MPDUI } from '../../../components/MPDUI';
import MyAccountBackButton from '../notificationsTab/MyAccountBackButton';

const ClubTab: React.FC<{
  subMenu?: General.MyProfileRoutes[] | null;
  parentRef?: React.MutableRefObject<HTMLDivElement | null>;
  onBackClick?: (show: boolean) => void;
}> = ({ subMenu, onBackClick }) => {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState<General.MyProfileRoutes | undefined | null>(undefined);
  const { hasHeader, navigation, logout, handleLogout, layout, badgesCount } = useFetchClub({ subMenu, onBackClick });
  if (isEmpty(subMenu)) return null;

  return (
    <>
      {hasHeader && onBackClick && (
        <MyAccountBackButton
          icon="mpd-link-gamification"
          title={MyProfile.ProfileTab.captainUpTab}
          showBackButton
          onBackClick={onBackClick}
        />
      )}

      <CaptainUpTabs
        tabs={navigation}
        handleActiveItem={setActiveItem}
        activeItem={activeItem}
        layout={layout}
        singleTab={!!hasHeader}
        badgesCount={badgesCount}
      />

      {!activeItem && !hasHeader && (
        <>
          <div className="cu-nav__item--distance" />

          {logout && (
            <MPDUI.NavItemRow
              key={logout.key}
              onClick={handleLogout}
              dataQid="logout"
              dataCpp="logout"
              text={t(logout.label)}
              icon={logout.icon}
              iconColor={logout.iconColor}
            />
          )}
        </>
      )}
    </>
  );
};

export default memo(ClubTab);
