import { isProductionBuild } from 'utils/common/helpersCommon';

type Params = {
  document: Document;
  src: string;
  callback: () => void;
  attr: string;
  id: string;
};

export const isScriptAttached = (attr: string): boolean => document.querySelectorAll(`script[n="${attr}"]`).length > 0;

export const gamificationScriptInit = ({ document, src, callback, attr, id }: Params) => {
  const h = document.getElementsByTagName('head')[0];
  const s = document.createElement('script');
  s.onload = () => callback();
  s.src = src;
  if (!isProductionBuild && !src.includes('cdn')) {
    s.type = 'module';
  }
  s.id = id;
  s.setAttribute('id', id);
  s.setAttribute('n', attr);
  h.appendChild(s);
};
