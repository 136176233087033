import React from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';

interface SectionProps {
  section: 'info' | 'stats' | 'account' | 'balance' | 'gamification';
  children: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ section, children }) => {
  const sectionClass = clsx('mpd-section', `mpd-section-${section}`);

  return <Flex className={sectionClass}>{children}</Flex>;
};

export default Section;
