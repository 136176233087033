import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/rootReducer';
import { Ticker } from '../types/ticker.types';

const initialState: Ticker = {
  tickerHeight: 0,
  tickerTTL: 0,
};

const ticker = createSlice({
  name: 'ticker',
  initialState,
  reducers: {
    setTickerHeight(state, action) {
      state.tickerHeight = action.payload;
    },
    setTickerTTL(state, action) {
      state.tickerTTL = action.payload;
    },
  },
});

export const { setTickerHeight, setTickerTTL } = ticker.actions;

export const selectTickerTTL = (state: RootState): number => state.ticker.tickerTTL;

export default ticker.reducer;
