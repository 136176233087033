import React, { useEffect, useRef, memo, useState } from 'react';
import clsx from 'clsx';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { Flex } from 'components/shared/layout';
import { useAppDispatch, useAppSelector } from 'store';
import { fetchTickerItems, selectTickerItems, setIsExpanded, setTickerItems } from './slice/mostPlayed.slice';
import TickerSingleItemDefault from './TickerSingleItemDefault';
import TickerSingleItemPlayGame from './TickerSingleItemPlayGame';
import TickerSkeleton from './TickerSkeleton';
import { TickerItem } from './types/ticker.types';

type TickerProps = {
  speed: number;
  visible: boolean;
};

const tickerTypeIdPlayGame = 5;

const renderTickerItemComponent = (index: number, item: TickerItem) => {
  if (item.tickerTypeId === tickerTypeIdPlayGame) {
    return <TickerSingleItemPlayGame key={index} mostPlayed={item} />;
  } else {
    return <TickerSingleItemDefault key={index} mostPlayed={item} />;
  }
};

const generateTabs = (items: TickerItem[]) => {
  return items.map((item, index) => renderTickerItemComponent(index, item));
};

const generateFirstTickerGroup = (items: TickerItem[], ref: React.RefObject<HTMLDivElement>) => {
  return (
    <div className="d-flex ticker-group" ref={ref}>
      {items.map((item, index) => renderTickerItemComponent(index, item))}
    </div>
  );
};

const generateTickerGroups = (repeat: number, items: TickerItem[]) => {
  return (
    repeat > 0 &&
    [...Array(repeat)].map((e, i) => (
      <div className="d-flex ticker-group" key={i}>
        {generateTabs(items)}
      </div>
    ))
  );
};

const Ticker: React.FC<TickerProps> = ({ speed, visible }) => {
  const tickerItems = useAppSelector(selectTickerItems);
  const dispatch = useAppDispatch();
  const tickerRef = useRef<HTMLDivElement>(null);
  const tickerWrapper = useRef<HTMLDivElement>(null);
  const mobileTouch = useRef<HTMLDivElement>(null);
  const [tickerRepeat, setTickerRepeat] = useState(0);
  const [animationWidth, setAnimationWidth] = useState(0);
  const [startTouch, setStartTouch] = useState(false);
  const pxsForSecond = speed ? speed : 40;
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [animationPaused, setanimationPaused] = useState(false);
  const TickerToggler = document.getElementsByClassName('ticker-toggler')[0] as HTMLElement;

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const handleResize = () => {
      const tw = tickerRef.current?.scrollWidth;
      const uw = window.innerWidth;
      timeout = setTimeout(() => {
        if (tw) {
          setTickerRepeat(Math.round(uw / tw) === 1 ? Math.round(uw / tw) * 2 + 1 : Math.round((uw / tw) * 2));
          setAnimationWidth(tw);
        }
      }, 100);
    };

    if (!isMobileOnly) window.addEventListener('resize', handleResize);
    dispatch(fetchTickerItems());
    return () => {
      dispatch(setTickerItems([]));
      if (!isMobileOnly) window.removeEventListener('resize', handleResize);
      clearTimeout(timeout);
    };
  }, []);

  const handleChangeAnimation = () => {
    setanimationPaused(true);
    setTimeout(() => {
      setanimationPaused(false);
    });
  };

  useEffect(() => {
    window.addEventListener('popstate', handleChangeAnimation);
    return () => {
      window.removeEventListener('popstate', handleChangeAnimation);
    };
  }, []);

  useEffect(() => {
    const tw = tickerRef.current?.scrollWidth;
    const uw = window.innerWidth;
    if (tw) {
      isMobileOnly
        ? setTickerRepeat(2)
        : setTickerRepeat(Math.round(uw / tw) === 1 ? Math.round(uw / tw) * 2 + 1 : Math.round((uw / tw) * 2 - 1));
      setAnimationWidth(tw);
      setShowSkeleton(false);
    }
  }, [tickerItems]);

  const handleTouchStart = (event) => {
    mobileTouch.current && mobileTouch.current.contains(event.target)
      ? setStartTouch(true)
      : TickerToggler && TickerToggler.contains(event.target)
        ? null
        : dispatch(setIsExpanded(false));
  };

  const handleTouchEnd = (e) => {
    if (mobileTouch.current && mobileTouch.current.contains(e.target)) {
      setStartTouch(false);
    }
  };

  const animIteration = () => {
    if (tickerRef.current?.scrollWidth && animationWidth !== tickerRef.current?.scrollWidth) {
      setAnimationWidth(tickerRef.current?.scrollWidth);
    }
  };

  useEffect(() => {
    const tickerIteration = tickerWrapper.current!;

    if (tickerIteration) {
      tickerIteration.addEventListener('animationiteration', animIteration);

      return () => {
        tickerIteration.removeEventListener('animationiteration', animIteration);
      };
    }
  }, []);

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);
    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  const tickerWrapperStyles = clsx({
    'ticker-wrapper': true,
    'mobile-touched': isMobile && startTouch,
    'mobile-device': isMobile,
  });

  const tickerStyles = clsx({
    ticker: true,
    'mobile-device': isMobile,
    anime: animationWidth !== 0,
    paused: animationPaused,
  });

  if (!visible) {
    return null;
  }

  function animation(width: number, pxsForSec: number) {
    const styleDeclarations = `
    @keyframes ticker {
    0% {transform: translate3d(0%, 0, 0)} 
    100% {transform: translate3d(-${width}px, 0, 0)}
  }
  .ticker.anime {
      animation: ticker ${Math.round(width / pxsForSec)}s linear infinite running;
  }
`;
    return <style>{styleDeclarations}</style>;
  }

  return (
    <div className={tickerWrapperStyles} ref={mobileTouch}>
      {animation(animationWidth, pxsForSecond)}
      {showSkeleton ? <TickerSkeleton /> : null}
      <Flex className={tickerStyles} ref={tickerWrapper}>
        {!isMobileOnly && tickerItems && (
          <div className="d-flex ticker-group" ref={tickerRef}>
            {generateTabs(tickerItems)}
          </div>
        )}
        {isMobileOnly &&
          tickerItems.length > 0 &&
          tickerRepeat === 0 &&
          generateFirstTickerGroup(tickerItems, tickerRef)}
        {tickerItems.length > 0 && tickerRepeat > 0 && generateTickerGroups(tickerRepeat, tickerItems)}
      </Flex>
    </div>
  );
};

export default memo(Ticker);
