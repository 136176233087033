import React, { memo } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'utils/common/helpersCommon';

interface Props {
  homeScore?: string | null;
  awayScore?: string | null;
  className?: string;
}

const EventScoreCurrent: React.FC<Props> = ({ homeScore, awayScore, className }) => {
  if (isEmpty(homeScore) || isEmpty(awayScore)) return null;

  const scoreStyles = clsx('event-score--current', className);

  return (
    <div className={scoreStyles}>
      <div className="score">{homeScore}</div>
      <div className="score">{awayScore}</div>
    </div>
  );
};

export default memo(EventScoreCurrent);
