import { useMemo } from 'react';
import { selectMetaData } from 'shared/common/features/seo/metaData/slice/metaData.slice';
import { useAppSelector } from 'store';

export const useMarketingKeys = () => {
  const metaData = useAppSelector(selectMetaData.metaData);
  const allRoutesMetData = metaData?.find((data) => data.route === 'all routes');
  const marketingIntegrationsTags = allRoutesMetData && JSON.parse(allRoutesMetData?.marketingIntegrationsTags);
  const GTM_KEY = marketingIntegrationsTags?.find((tag) => tag.Key === 'GOOGLE_TAG_MANAGER_KEY')?.Value?.Value ?? '';
  const GTM_AUTH = marketingIntegrationsTags?.find((tag) => tag.Key === 'GOOGLE_TAG_MANAGER_AUTH')?.Value?.Value ?? '';
  const GTM_ENV =
    marketingIntegrationsTags?.find((tag) => tag.Key === 'GOOGLE_TAG_MANAGER_PREVIEW_ENV')?.Value?.Value ?? '';
  const ZOPIM_ENV_KEY = marketingIntegrationsTags?.find((tag) => tag.Key === 'ZOPIM_API_KEY')?.Value?.Value ?? '';
  const ZENDESK_ENV_KEY = marketingIntegrationsTags?.find((tag) => tag.Key === 'ZENDESK_API_KEY')?.Value?.Value ?? '';
  const LIVECHATCOM_ENV_KEY =
    marketingIntegrationsTags?.find((tag) => tag.Key === 'LIVECHATCOM_API_KEY')?.Value?.Value ?? '';
  const MAPBOX_API_KEY = marketingIntegrationsTags?.find((tag) => tag.Key === 'MAPBOX_API_KEY')?.Value?.Value ?? '';
  const AIVO_KEY = marketingIntegrationsTags?.find((tag) => tag.Key === 'AIVO_KEY')?.Value?.Value ?? '';

  const keys = useMemo(() => {
    return {
      GTM_KEY,
      GTM_AUTH,
      GTM_ENV,
      ZOPIM_ENV_KEY,
      ZENDESK_ENV_KEY,
      LIVECHATCOM_ENV_KEY,
      MAPBOX_API_KEY,
      AIVO_KEY,
    };
  }, [GTM_KEY, GTM_ENV, GTM_AUTH, ZOPIM_ENV_KEY, ZENDESK_ENV_KEY, LIVECHATCOM_ENV_KEY, MAPBOX_API_KEY, AIVO_KEY]);

  return keys;
};
