import { isEmpty } from './common/helpersCommon';

const RESTRICTED_PLAYER_ID = ['1299877113857', '969186596656230400'];

export const filterMenuItemsByPlayerId = ({ items, playerId }) => {
  if (!isEmpty(playerId) && RESTRICTED_PLAYER_ID.includes(playerId?.toString())) {
    return items.filter((item) => item.route !== '/virtual');
  }

  return items;
};
