import { isEmpty } from 'utils/common/helpersCommon';
import { mapSportIDsByKey } from 'utils/common/types/sportTypes';

export type SportID = string | number;
export type NameAndID = { id: string | number; name: string };
export type SportParams = Record<string, string | number | undefined>;

/**
 *  transforms
 *  from: Some_Team, Name.,     Value
 *  to: some-team-name-value
 */
const namePattern = /[_\s,/.,]/g;
export const formatName = (name: string | undefined): string => {
  if (!name) {
    return '';
  }

  return name
    .replace(namePattern, '-')
    .split('-')
    .filter((str) => str)
    .join('-')
    .toLowerCase();
};

//utility
export const getQueryParamsAsMap = (query: URLSearchParams): SportParams => {
  const queryObj: Record<string, string | undefined> = {};

  const queryParams = Array.from(query.entries());
  queryParams.forEach(([key, value]) => {
    queryObj[key] = value;
  });

  return queryObj;
};

export const getSportIdNameFormat = (sportId: SportID | undefined): string => {
  if (!sportId) {
    return '';
  }

  const sportName = formatName(mapSportIDsByKey(sportId) || '');
  return sportName ? `${sportName}-${sportId}` : sportId.toString();
};

export const combineNameAndId = (name: string | undefined, id: string | number): string => {
  return name ? `${formatName(name)}-${id}` : id.toString();
};

export const getQueryString = (queryMap: SportParams) => {
  // parseQueryToSportFormat(queryParams);

  if (queryMap.sportId) {
    queryMap.sportId = getSportIdNameFormat(queryMap.sportId);
  }

  const queryStr = Object.entries(queryMap)
    .filter(([key, value]) => !isEmpty(key) && !isEmpty(value))
    .map((keyValue) => keyValue.join('='))
    .join('&');

  return queryStr;
};
