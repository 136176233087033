import { useCallback, useRef, useState } from 'react';
import {
  isCasinoBonusTagBtn,
  isCasinoFavBtn,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellFavouritesButton/gameCellFavouritesButtonUtils';

export const useMouseObserver = (
  showButtons: boolean,
): {
  startTouch: boolean;
  onTouchInside: (ev: TouchEvent, element: HTMLDivElement | null) => void;
  onMouseEnter: (ev: MouseEvent) => void;
  onTouchOutside: (ev: TouchEvent) => void;
  onMouseMove: (ev: MouseEvent) => void;
  onScroll: (ev: Event) => void;
} => {
  const [startTouch, setStartTouch] = useState(false);
  const entered = useRef<boolean>(false);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isScrolling = useRef<boolean>(false);

  const onMouseEnter = useCallback(() => {
    entered.current = true;
    if (isScrolling.current) {
      return;
    }
    if (!startTouch) setStartTouch(true);
  }, []);

  const onTouchInside = useCallback((ev, element) => {
    if (isCasinoFavBtn(ev) || isCasinoBonusTagBtn(ev)) {
      return;
    }
    if (element && !element.classList.contains('casino-grid-item--hover') && showButtons) {
      ev.preventDefault();
    }
    entered.current = true;
    if (isScrolling.current) {
      return;
    }
    if (!startTouch) setStartTouch(true);
  }, []);

  const onMouseMove = useCallback(() => {
    if (entered.current) {
      entered.current = false;
      setStartTouch(true);
    }
  }, []);

  const onTouchOutside = useCallback((ev) => {
    if (isCasinoFavBtn(ev) || isCasinoBonusTagBtn(ev)) {
      return;
    }

    entered.current = false;
    setStartTouch(false);
  }, []);

  const onScroll = useCallback(() => {
    isScrolling.current = true;
    if (timer.current !== null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(function () {
      isScrolling.current = false;
    }, 550);
  }, []);

  return { startTouch, onTouchInside, onTouchOutside, onMouseEnter, onMouseMove, onScroll };
};
