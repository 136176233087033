import clsx from 'clsx';
import { Icon } from 'components/shared';
import { Flex } from 'components/shared/layout';

/** BetSlip Boost Profit Indicator  */
interface PossbileWinProps {
  className?: string;
  isVisible?: boolean;
  bonusPercentage: number | undefined;
  boostType?: 'acca' | 'multiple';
  profitText?: string;
  icon?: 'gauge-meter-full' | 'gauge-meter';
}

const BoostProfit: React.FC<PossbileWinProps> = ({
  className,
  isVisible,
  bonusPercentage,
  boostType = 'acca',
  profitText,
  icon = 'gauge-meter',
}) => {
  if (!isVisible) return null;

  const divClass = clsx(
    'ma-text--accent text-right',
    boostType === 'acca' ? 'ma-text--info' : 'ma-text--success',
    className,
  );

  return (
    <Flex.AC className={divClass}>
      +{bonusPercentage}%{profitText && `\u00A0${profitText}`}
      <Icon defaultClassName={false} className="ml-1" size={16} icon={icon} />
    </Flex.AC>
  );
};

export default BoostProfit;
