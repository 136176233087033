import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
// import { playerHistoryMock } from '../playerHistoryMock';
import { PlayerHistoryTypes } from '../types/PlayerHistory.types';

const initialState: PlayerHistoryTypes.InitialState = {
  history: {},
  isLoading: {},
  hasError: {},
  hasFetched: {},
};

export const playerHistoryThunks: PlayerHistoryTypes.Thunks = {
  getPlayerHistory: createAbortThunk(
    'playerHistory/getPlayerHistory',
    async ({ page, type, limit = 100 }, { rejectWithValue, signal, source }) => {
      try {
        signal.addEventListener('abort', () => source.cancel());

        const url = `${config.API_URL}/api/ews-game-journey/player/transactions-history`;
        const params = { filterType: type, skip: (page - 1) * limit, limit };
        const response = await axiosInstance.get(url, { params, cancelToken: source.token });
        return response.data;
      } catch (err) {
        if (Axios.isCancel(err)) {
          return rejectWithValue(null);
        }
        return rejectWithValue(getError.default());
      }
    },
  ),
};

const playerHistory = createSlice({
  name: 'playerHistory',
  initialState,
  reducers: {
    resetPlayerHistory: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(playerHistoryThunks.getPlayerHistory.pending, (state, action) => {
        const { type } = action.meta.arg;
        state.isLoading[type] = true;
      })
      .addCase(playerHistoryThunks.getPlayerHistory.fulfilled, (state, action) => {
        const { type, page } = action.meta.arg;
        const currItems = state.history?.[type]?.items ? state.history?.[type]?.items : [];
        const { items = [], totalItems = 0 } = action.payload;
        state.history[type] = { items: [...currItems, ...items], page, totalItems };
        state.isLoading[type] = false;
        state.hasFetched[type] = true;
      })
      .addCase(playerHistoryThunks.getPlayerHistory.rejected, (state, action) => {
        const { type } = action.meta.arg;
        state.isLoading[type] = false;
        state.hasError[type] = false;
      });
    builder.addCase(logoutUser, () => initialState);
  },
});

export const { resetPlayerHistory } = playerHistory.actions;
export const { reducer: playerHistoryReducer } = playerHistory;

export const selectPlayerHistory = {
  history:
    (type: string) =>
    (state: RootState): PlayerHistoryTypes.HistoryState =>
      state.captainUp.playerHistory.history[type],
  isLoading:
    (type: string) =>
    (state: RootState): boolean =>
      state.captainUp.playerHistory.isLoading[type],
  hasError:
    (type: string) =>
    (state: RootState): boolean =>
      state.captainUp.playerHistory.hasError[type],
  hasFetched:
    (type: string) =>
    (state: RootState): boolean =>
      state.captainUp.playerHistory.hasFetched[type],
};
