import { layout01Settings } from 'modules/casino/modules/container/widget/widgetTypes/lastWinnersWidgets/components/sliderConfigSettings/settings/layout01Settings';
import { layout02Settings } from 'modules/casino/modules/container/widget/widgetTypes/lastWinnersWidgets/components/sliderConfigSettings/settings/layout02Settings';
import { CasinoWidgetsTypes } from '../../../../types/casinoWidgetsTypes';

export const getLastWinnersDynamicSliderSettings = (
  templateType: CasinoWidgetsTypes.WidgetTypes,
  latestWinnersLayout: string,
) => {
  switch (latestWinnersLayout) {
    case 'layout_1':
      return layout01Settings(templateType);
    case 'layout_2':
      return layout02Settings(templateType);
    default:
      return layout01Settings(templateType);
  }
};
