import { useTranslation } from 'react-i18next';
import { Modal } from 'components/shared/Modal';
import { ModalUI } from 'components/shared/ModalUI';
import { UI } from 'components/shared/UI';
import { useAppDispatch } from 'store';
import { hideRootModal } from '../slice/rootModal.slice';

const RetailCertificateModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const refreshPage = () => {
    dispatch(hideRootModal());
    window.location.reload();
  };

  return (
    <Modal visible={true} className="modal-terms">
      <Modal.Body className="d-flex-col-ac text-center" padding="lg">
        <ModalUI.Title className="mb-3" hasAccent text={t(`RetailCertificateModal.Message.titleText`)} />
        <UI.Button
          class="mt-3"
          size="lg"
          onClick={refreshPage}
          text={t('RetailCertificateModal.Buttons.refreshButton')}
        />
      </Modal.Body>
    </Modal>
  );
};

export default RetailCertificateModal;
