import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'components/shared';
import { handleBackButtonNavigation } from 'modules/casino/shared/features/gameLaunch/casinoLaunchUtils';
import { hideRootGrandModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch } from 'store';
import { RealityCheckModal } from 'utils/common/translationUtils/translationStrings';
import { RealityCheckSessionModalTypes } from '../types/realityCheckSession.types';

type Props = {
  modalType: RealityCheckSessionModalTypes;
  closeGame?: (handleBack?: () => void) => void;
  closeModal?: () => void;
};

const ModalClose: React.FC<Props> = ({ modalType, closeGame, closeModal }): React.ReactNode => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleBack = () => {
    handleBackButtonNavigation(navigate);
  };

  switch (modalType) {
    case RealityCheckSessionModalTypes.INFO:
    case RealityCheckSessionModalTypes.WARN:
      return (
        <Modal.Close
          closeText={t(RealityCheckModal.Session.closeButton)}
          closeAction={() => dispatch(hideRootGrandModal())}
        />
      );
    case RealityCheckSessionModalTypes.CREATE:
      return (
        <Modal.Close
          closeText={t(RealityCheckModal.Session.closeButton)}
          closeAction={closeModal || (() => closeGame?.(handleBack))}
        />
      );
    case RealityCheckSessionModalTypes.TIMEOUT:
    case RealityCheckSessionModalTypes.END:
    default:
      return (
        <Modal.Close closeText={t(RealityCheckModal.Session.closeButton)} closeAction={() => closeGame?.(handleBack)} />
      );
  }
};

export default ModalClose;
