import React from 'react';

/** Terminal Modal Subheader */
interface SubHeaderProps {
  text: string;
}

const SubHeader: React.FC<SubHeaderProps> = ({ text }) => {
  return <div className="text-center terminal-modal__sub-header">{text}</div>;
};

export default SubHeader;
