import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { isEmpty } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { NavBarType } from '../types/navBar.types';

export const getMenuItems = createAbortThunk<
  NavBarType.Tab[],
  { uri: string },
  {
    rejectValue: string | null;
  }
>('captainUpNavigation/getMenuItems', async ({ uri }) => {
  const response = await axiosInstance.get(`${config.API_URL}${uri}`);

  return response.data;
});

const initialState: NavBarType.InitialState = {
  menus: [],
  activeCategory: null,
  normalizedTabs: {},
};

const captainUpNavigation = createSlice({
  name: 'captainUpNavigation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMenuItems.fulfilled, (state, action) => {
      state.menus = action.payload;
      state.normalizedTabs = action.payload.reduce((acc, tab) => {
        const subMenu = !isEmpty(tab?.categories) ? tab?.categories : tab?.subMenu || [];
        const subMenuMap = subMenu.reduce((accSubTab, subTab) => {
          accSubTab[subTab.alias] = subTab.id;
          return accSubTab;
        }, {});
        acc[tab.alias] = subMenuMap;
        return acc;
      }, {});
    });
  },
});

export const selectCaptainUpNavigation = {
  menuItems: (state: RootState): NavBarType.Tab[] => state.captainUp.navigation.menus,
  normalizedTabs: (state: RootState) => state.captainUp.navigation.normalizedTabs,
  categoryId: (arg: { mainAlias: string; subAlias: string }) => (state: RootState) =>
    state.captainUp.navigation.normalizedTabs?.[arg.mainAlias]?.[arg?.subAlias],
};

export const { reducer: captainUpNavigationReducer } = captainUpNavigation;
