import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppSelector } from 'store';

type ReturnType = { currencySymbol: string; currencyCode: string | undefined };

export const useCurrency = (): ReturnType => {
  const userCurrencyName = useAppSelector(selectMyProfile.currencyName);
  const currencyCode = userCurrencyName || localStorage.getItem('currency') || '';
  const currencySettings = useAppSelector(selectGeneral.currencySettings);
  const currencySymbol = currencySettings?.find((c) => c.name === currencyCode)?.nameTranslations || currencyCode;
  return { currencySymbol, currencyCode };
};
