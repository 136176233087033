import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'components/shared/UI/Button/Button';
import TableRow from 'modules/casino/modules/container/widget/widgetTypes/leaderBoardWidget/leaderBoardCellWidget/components/widgetLeaderBoardComponents/TableRow';
import { LeaderBoardWidgetTypes } from 'modules/casino/modules/container/widget/widgetTypes/leaderBoardWidget/leaderBoardCellWidget/LeaderBoardCellWidgetTypes';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppSelector } from 'store';
import { leaderBoardCSSNS, LeaderBoardWidgetTranslationKeys } from '../../LeaderBoardCellWidgetConstants';

type Props = {
  ranking: LeaderBoardWidgetTypes.PlayerRanking[];
  buttonUrl: string;
};

const LeaderBoardRanking: React.FC<Props> = ({ ranking, buttonUrl }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUser = useAppSelector(selectMyProfile.dataUser);

  const handleButtonClick = () => {
    navigate(`/${buttonUrl}`);
  };

  return (
    <>
      <div className={`${leaderBoardCSSNS}-widget__winner--scrollable no-scrollbars`}>
        <TableRow
          firstColText={t(LeaderBoardWidgetTranslationKeys.number)}
          secondColText={t(LeaderBoardWidgetTranslationKeys.player)}
          thirdColText={t(LeaderBoardWidgetTranslationKeys.points)}
        />
        {ranking.map((player, index) => {
          const shouldHighlight = player.name === currentUser?.username;

          return (
            <TableRow
              key={player.rank + player.name}
              firstColText={`${player.rank}`}
              secondColText={player.name}
              thirdColText={`${player.points}`}
              isHighlighted={shouldHighlight}
              isBold={index < 3}
            />
          );
        })}
        <div className={`${leaderBoardCSSNS}-widget__row d-flex-center`}>
          <Button
            size={'xs'}
            text={t(LeaderBoardWidgetTranslationKeys.viewMore)}
            variant="primary"
            onClick={handleButtonClick}
          ></Button>
        </div>
      </div>
    </>
  );
};

export default LeaderBoardRanking;
