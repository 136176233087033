import { useNavigate } from 'react-router-dom';
import { AllowedModalTypes } from 'modules/casino/modules/container/widget/widgetTypes/genericWidgets/constants/genericWidgetConstants';
import { localLog } from 'modules/casino/shared/utils/common/helpersCommon';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum, ModalTypes } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch } from 'store';
import { CasinoWidgetsTypes, WidgetButtonType } from '../../../types/casinoWidgetsTypes';

export const useWidgetNavigate = (
  navigateSettings: CasinoWidgetsTypes.WidgetNavigation | undefined,
): {
  onClick: () => void;
} => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let onClick = () => {};

  if (!navigateSettings) return { onClick };

  const { type, route } = navigateSettings;

  switch (type) {
    case WidgetButtonType.Route:
      onClick = () => {
        navigate(route);
      };
      break;
    case WidgetButtonType.External:
      onClick = () => {
        window.open(route, '_blank');
      };
      break;
    case WidgetButtonType.Modal:
      onClick = () => {
        if (!AllowedModalTypes.includes(route)) {
          localLog({ message: `${route} is Invalid Modal Type`, type: 'error' });
        } else {
          // TODO - Casino as Widget - use RootCasinoModal
          // dispatch(
          //   showRootCasinoModal({
          //     modalType: route as CasinoModalTypes,
          //   }),
          // );

          dispatch(
            showRootModal({
              modalType: route as ModalTypes,
              modalPriority: ModalPriorityEnum.LOW,
              modalId: route,
            }),
          );
        }
      };
      break;
    default:
      break;
  }
  return { onClick };
};
