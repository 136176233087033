import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { Modal } from 'components/shared/Modal';
import { ModalUI } from 'components/shared/ModalUI';
import { UI } from 'components/shared/UI';
import { TimeoutModal } from 'utils/common/translationUtils/translationStrings';
import { addToDate, formatDateUTC, addTzOffsetToDate, timeMsMap } from 'utils/dateUtils';
import useHandleModals from '../hooks/useHandleModals';
import useInactivityModalData from '../hooks/useInactivityModalData';

type Props = {
  type?: keyof typeof timeMsMap;
  time?: number;
};

const getTime = (type: keyof typeof timeMsMap = 'seconds', time: number = 60) => time * timeMsMap[type];

const SessionInactivityModal: React.FC<Props> = ({ type, time }) => {
  const { t } = useTranslation();
  const loggedOutTimeLeft = formatDateUTC(
    'HH:mm:ss without-locale',
    addToDate(addTzOffsetToDate(Date.now()), time || 1, type || 'minutes'),
  );
  const { closeModal, handleLogout } = useHandleModals('logOuthideModal');
  useInactivityModalData(getTime(type, time));

  return (
    <Modal visible className="modal-terms">
      <Modal.Body padding="lg" className="text-center">
        <ModalUI.Title text={t(`TimeoutModal.Message.maximumInactivityText`)} />
        <p className="mb-3 modal-terms__title accent">
          {t(TimeoutModal.Message.loggedOutText)}
          <br />
          <span className="modal-terms__title-accent">
            {loggedOutTimeLeft} {t(TimeoutModal.Message.hourText)}
          </span>{' '}
          {t(TimeoutModal.Message.inactivityReasonText)}
        </p>

        <Flex.Center className="d-flex-col-lg-row">
          <UI.Button className="m-2" size="lg" onClick={closeModal} text={t(TimeoutModal.Button.remainLoggedInText)} />
          <UI.Button
            className="m-2"
            size="lg"
            variant="secondary"
            onClick={handleLogout}
            text={t(TimeoutModal.Button.logoutText)}
          />
        </Flex.Center>
      </Modal.Body>
    </Modal>
  );
};

export default memo(SessionInactivityModal);
