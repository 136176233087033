import { JackpotBanner } from './customTemplateBanner';
import { JackpotsWidgetRibbonCell } from './templateRibbon';
import { JackpotsWidgetCell } from './templateSection';
import { CasinoWidgetTemplates } from '../../../../constants/casinoWidgetsConstants';

export const jackpotsWidgetCellByTemplate = {
  [CasinoWidgetTemplates.SECTION]: JackpotsWidgetCell,
  [CasinoWidgetTemplates.RIBBON]: JackpotsWidgetRibbonCell,
  [CasinoWidgetTemplates.JACKPOT_BANNER]: JackpotBanner,
};
