import { gameProvidersSelectors } from 'modules/casino/modules/container/shared/features/casinoGameProviders/slice/casinoGameProvidersSlice';
import useGeneralCasinoType from 'modules/casino/shared/hooks/useGeneralCasinoType';
import { selectLanguage } from 'shared/common/features/settings/slice/settings.slice';
import { useAppSelector } from 'store';

export const useProviderLobbyKey = (): string => {
  const { casinoType } = useGeneralCasinoType();
  const language = useAppSelector(selectLanguage);
  const activeProviderAlias: string = useAppSelector(gameProvidersSelectors.activeProvider);

  return `${language}_${casinoType}_${activeProviderAlias}`;
};
