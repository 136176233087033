import React from 'react';
import { Flex } from 'components/shared/layout';
import { GamificationUser } from 'shared/common/features/integrations/gamification/types/gamification.types';
import { useLevelProgress } from '../../hooks/useLevelProgress';

type Props = {
  gamificationProfile?: GamificationUser.Profile | null;
};

export const LevelProgress: React.FC<Props> = ({ gamificationProfile }) => {
  const currentProgress = useLevelProgress({
    currentLevelPoints: gamificationProfile?.playerLevel?.currentLevelPoints,
    nextLevelPoints: gamificationProfile?.playerLevel?.nextLevelPoints,
    points: gamificationProfile?.playerCurrencies?.points,
  });

  return (
    <div className="mpd-cu__progress">
      <Flex.Center className="mpd-cu__name">{gamificationProfile?.playerLevel.name}</Flex.Center>
      <Flex.Center>
        {gamificationProfile?.playerLevel.currentLevelPoints}
        <div className="w-100 mpd-cu__level">
          <div className="mpd-cu__level-current" style={{ width: currentProgress + '%' }}></div>
        </div>
        {gamificationProfile?.playerLevel.nextLevelPoints}
      </Flex.Center>
    </div>
  );
};
