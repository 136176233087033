import { memo, RefObject } from 'react';
import clsx from 'clsx';
import styled, { css } from 'styled-components';
import { WidgetTemplate } from 'modules/casino/shared/constants';
import { CasinoWidgetsTypes } from '../../../types/casinoWidgetsTypes';

const wrapperSectionStyles = css<{
  type?: string;
}>`
  height: ${({ type }) =>
    (type && WidgetTemplate[type] === WidgetTemplate.SECTION_STAKE) ||
    (type && WidgetTemplate[type] === WidgetTemplate.SECTION_STAKE_PLUS)
      ? '175px'
      : '80px'};
  display: grid;
  grid-template-columns: ${({ type }) =>
    (type && WidgetTemplate[type] === WidgetTemplate.SECTION_STAKE) ||
    (type && WidgetTemplate[type] === WidgetTemplate.SECTION_STAKE_PLUS)
      ? 'repeat(18, 125px)'
      : 'repeat(12, 200px)'};
  grid-template-rows: 100%;
  grid-gap: 5px;
  overflow: hidden;
`;

const wrapperStyles = css`
  overflow: hidden;
  height: 100%;
`;

const StyledWrapper = styled.div<{
  type: string;
}>`
  ${({ type }) =>
    type &&
    (WidgetTemplate[type] === WidgetTemplate.SECTION ||
      WidgetTemplate[type] === WidgetTemplate.SECTION_STAKE ||
      WidgetTemplate[type] === WidgetTemplate.SECTION_STAKE_PLUS)
      ? wrapperSectionStyles
      : wrapperStyles};
`;

const StyledCellElement = styled.div<{
  type: string;
}>`
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: ${({ type }) =>
    WidgetTemplate[type] === WidgetTemplate.SECTION_STAKE || WidgetTemplate[type] === WidgetTemplate.SECTION_STAKE_PLUS
      ? '175px'
      : '80px'};
  margin-bottom: ${({ type }) => (WidgetTemplate[type] === WidgetTemplate.SECTION ? '' : '5px')};
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    animation: shimmer 1.2s infinite;
    content: '';
  }
`;

interface Props {
  type: CasinoWidgetsTypes.WidgetTypes;
  columns: number;
  setRefs?: RefObject<HTMLDivElement>;
}

const LastWinnersCellSkeleton: React.FC<Props> = ({ type, columns, setRefs }) => {
  const wrapperClass = clsx('cw', `cw--${WidgetTemplate[type]}`);
  const styledCellElementClass = clsx(
    'cw--skeleton-element',
    WidgetTemplate[type] === WidgetTemplate.SECTION ||
      WidgetTemplate[type] === WidgetTemplate.SECTION_STAKE ||
      WidgetTemplate[type] === WidgetTemplate.SECTION_STAKE_PLUS
      ? ''
      : 'w-100',
  );
  const innerWrapperStyle = {
    gridTemplateColumns:
      WidgetTemplate[type] === WidgetTemplate.SECTION
        ? `242px auto`
        : WidgetTemplate[type] === WidgetTemplate.SECTION_STAKE ||
            WidgetTemplate[type] === WidgetTemplate.SECTION_STAKE_PLUS
          ? `125px auto`
          : `repeat(${columns}, ${100 / columns}%)`,
  };
  const leftColStyle = {
    marginRight: '5px',
  };
  const renderItems = (number: number) => {
    const elements: React.ReactNode[] = [];
    for (let index = 0; index < number; index++) {
      elements.push(
        <StyledCellElement
          key={`${number}_${index}`}
          type={type}
          className={styledCellElementClass}
          style={{
            height:
              WidgetTemplate[type] === WidgetTemplate.SECTION_STAKE ||
              WidgetTemplate[type] === WidgetTemplate.SECTION_STAKE_PLUS
                ? '175px'
                : '80px',
          }}
        ></StyledCellElement>,
      );
    }
    return elements;
  };

  return (
    <div className={wrapperClass} ref={setRefs && setRefs}>
      <div className="cw--inner-wrapper" style={innerWrapperStyle}>
        <div className="cw--left-col" style={leftColStyle}>
          <StyledCellElement type={type} className={styledCellElementClass}></StyledCellElement>
        </div>
        <StyledWrapper type={type}>
          {renderItems(
            WidgetTemplate[type] === WidgetTemplate.SECTION_STAKE ||
              WidgetTemplate[type] === WidgetTemplate.SECTION_STAKE_PLUS
              ? 18
              : 12,
          )}
        </StyledWrapper>
      </div>
    </div>
  );
};

export default memo(LastWinnersCellSkeleton);
