import React, { memo } from 'react';
import { DataLoader } from 'components/shared/loaders';
import { isEmpty, transformMyBetsDate } from 'utils/common/helpersCommon';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import NotificationItem from './components/NotificationItem';
import { useNotificationTab } from './hooks/useNotificationTab';
import MyAccountBackButton from './MyAccountBackButton';
import CTAUI from '../../../components/CTAUI';

interface Props {
  parentRef?: React.MutableRefObject<HTMLDivElement | null>;
  onBackClick: (show: boolean) => void;
}

const Notifications: React.FC<Props> = ({ parentRef, onBackClick }) => {
  const {
    status,
    inboxNotifications,
    error,
    activeNotification,
    handleUpdateMessages,
    handleSelectMessage,
    handleSelect,
    t,
    selectedMessages,
    updateStatus,
    showNotificationManagement,
  } = useNotificationTab(parentRef);

  if (status === 'pending') return <DataLoader />;
  if (status === 'succeeded' && isEmpty(inboxNotifications))
    return (
      <>
        <MyAccountBackButton
          icon="mpd-link-notifications"
          title={MyProfile.ProfileTab.notificationsTab}
          showBackButton={!activeNotification}
          onBackClick={onBackClick}
        />
        <CTAUI.Empty text={t(MyProfile.NotificationsTab.noNotificationsTitle)} />
      </>
    );
  if (status === 'failure' && error)
    return (
      <>
        <MyAccountBackButton
          icon="mpd-link-notifications"
          title={MyProfile.ProfileTab.notificationsTab}
          showBackButton={!activeNotification}
          onBackClick={onBackClick}
        />
        <CTAUI.Empty text={t(MyProfile.NotificationsTab.errorNotificationsMessage)} />
      </>
    );

  return (
    <>
      <MyAccountBackButton
        icon="mpd-link-notifications"
        title={MyProfile.ProfileTab.notificationsTab}
        showBackButton={!activeNotification}
        onBackClick={onBackClick}
      />
      {showNotificationManagement && (
        <CTAUI.Management handleUpdateMessages={handleUpdateMessages} updateStatus={updateStatus} />
      )}
      <CTAUI.Wrapper
        showContent={!isEmpty(activeNotification)}
        className={showNotificationManagement && 'mpd-offers-container--column'}
      >
        <CTAUI.Column className={activeNotification && 'mpd-block--hidden'} hasGap>
          {status === 'succeeded' &&
            !isEmpty(inboxNotifications) &&
            Object.keys(inboxNotifications).map((notificationId) => (
              <CTAUI.Header
                key={notificationId}
                onClick={() => handleSelectMessage(notificationId)}
                icon={inboxNotifications[notificationId].style?.iconResourceLocation}
                title={inboxNotifications[notificationId].header}
                subTitle={transformMyBetsDate(inboxNotifications[notificationId].createDate)}
                isRead={inboxNotifications[notificationId].isRead}
                onSelect={() => handleSelect(notificationId)}
                isChecked={selectedMessages.includes(notificationId)}
                hasIndicator
                className="mpd-cta-header--notification"
              />
            ))}
        </CTAUI.Column>
        {!isEmpty(activeNotification) && (
          <CTAUI.Column>
            <CTAUI.Header
              key={activeNotification.id}
              icon={activeNotification.style?.iconResourceLocation}
              title={activeNotification.header}
              showBackButton
              onBackClick={() => handleSelectMessage('')}
              isRead={true}
              className="m-0 mb-2"
            />
            <NotificationItem notification={activeNotification} />
          </CTAUI.Column>
        )}
      </CTAUI.Wrapper>
    </>
  );
};

export default memo(Notifications);
