import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

/** Cashier Modal Filters Wrapper Section */
interface FiltersWrapperProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: React.ReactNode;
}

const FiltersWrapper: React.FC<FiltersWrapperProps> = ({ className, children }) => {
  const wrapClass = clsx('cashier-modal__filters-wrapper', className);

  return <div className={wrapClass}>{children}</div>;
};

export default FiltersWrapper;
