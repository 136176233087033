import { motion } from 'framer-motion';
import styled from 'styled-components';

export const SCPlayArea = styled.div`
  place-self: end center;

  grid-row: 1 / -1;
  grid-column: 1 / -1;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
`;

export const SCMeterWrapper = styled.div`
  grid-row: 1 / -1;
  grid-column: 1 / -1;

  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 20px 1fr;

  margin-bottom: 170px;

  height: 300px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

interface SCMeterProps {
  $startRow: number;
}

export const SCMeter = styled(motion.div)<SCMeterProps>`
  grid-row-start: ${(p) => p.$startRow};
  grid-column-start: 2;
  place-self: end center;
  width: 20px;
  height: 36px;
  border-radius: 2px;
  background-size: 20px 36px;
  background-repeat: no-repeat;
  background-position: center;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.8));
`;

export const SCHammer = styled.div`
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  place-self: end center;
`;
