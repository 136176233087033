import {
  createAsyncThunk,
  // eslint-disable-next-line no-restricted-syntax
  AsyncThunk,
} from '@reduxjs/toolkit';
import Axios, { CancelTokenSource } from 'axios';
import i18next from 'i18next';
import { DEFAULT_ERROR_MSG } from 'modules/casino/shared/constants/commonConstants';
import { isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import { AppDispatch } from 'store';
import { RootState } from 'store/rootReducer';

export type ThunkConfig<TRejectValue> = {
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: TRejectValue;
};
// eslint-disable-next-line no-restricted-syntax
export type CreateThunk<TArg, TResponse, TRejectValue = string | null> = AsyncThunk<
  TResponse,
  TArg,
  ThunkConfig<TRejectValue>
>;

type AbortOptions = {
  selfAbort?: boolean;
  condition?;
};

const defaultOptions = {
  selfAbort: false,
};

export type ThunkArgs<TError> = {
  getState: () => RootState;
  dispatch: AppDispatch;
  source: CancelTokenSource;
  signal: AbortSignal;
  rejectWithValue: (value: TError) => any;
};

export const createAbortThunk = <TApiResponse, TArg = void, TError = string | null>(
  thunkName: string,
  thunkCallback: (arg: TArg, thunkArgs: ThunkArgs<TError>) => Promise<TApiResponse>,
  options: AbortOptions = defaultOptions,
  catchCallback: TryCatchCallback<TError> = defaultTryCatchCallback,
): CreateThunk<TArg, TApiResponse, TError> => {
  let source: CancelTokenSource;

  // eslint-disable-next-line no-restricted-syntax
  return createAsyncThunk<TApiResponse, TArg, ThunkConfig<TError>>(
    thunkName,
    async (arg, thunkArgs) => {
      try {
        options.selfAbort && source && source.cancel();
        source = Axios.CancelToken.source();
        thunkArgs.signal.addEventListener('abort', () => {
          source.cancel();
        });

        const result = await thunkCallback(arg, { ...thunkArgs, source });
        return result;
      } catch (err) {
        if (Axios.isCancel(err)) {
          return thunkArgs.rejectWithValue(null as any);
        }
        return catchCallback(err, thunkArgs.rejectWithValue);
      }
    },
    options,
  );
};

type RejectedCallback = (state, action) => void;

export const rejectedWrapper =
  (rejectedCallback: RejectedCallback) =>
  (state, action): void => {
    if (!isEmpty(action.payload)) {
      rejectedCallback(state, action);
    }
  };

type TryCatchCallback<TError> = (err, rejectWithValue: (value: TError) => any) => any;
export type ResponseErrorMessage = { response: { message: string } };

const defaultTryCatchCallback = (err, rejectWithValue) => {
  return rejectWithValue(err?.response?.data?.message || err?.response?.message || i18next.t(DEFAULT_ERROR_MSG));
};
