import styled from 'styled-components';
import { Breakpoints } from 'theme/Theme';

export const StyledMarker = styled.div`
  margin: 0 auto;
  z-index: 10;
  place-self: start center;
  margin-top: -18px;
  aspect-ratio: 64 / 70;
  width: 50px;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    width: 64px;
  }
  transition: width 0.15s;
`;
