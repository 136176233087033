import React, { memo } from 'react';
import { Sport } from 'utils/common/types/sportTypes';
import SingleEventScore from './SingleEventScore';
import EventScoreCurrent from '../EventScoreCurrent';
import { getPeriodScore } from '../utils/eventScore.utils';
interface Props {
  periodScore: Sport.PeriodScoresData;
  homeScore: string;
  awayScore: string;
}

const DoubleScoreType: React.FC<Props> = ({ periodScore, homeScore, awayScore }) => {
  return (
    <>
      <SingleEventScore homeScore={homeScore} awayScore={awayScore} />
      <EventScoreCurrent
        homeScore={getPeriodScore(periodScore)?.map((data) => data.homeScore)}
        awayScore={getPeriodScore(periodScore)?.map((data) => data.awayScore)}
      />
    </>
  );
};

export default memo(DoubleScoreType);
