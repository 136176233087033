import React from 'react';
import clsx from 'clsx';
import { useFavouritesBtn } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellFavouritesButton/hooks/useFavouritesBtn';

interface FavouritesBtnProps {
  gameUniqueId: string;
  categoryId: string;
  gameCellRef: React.MutableRefObject<HTMLDivElement | null>;
  show?: boolean;
  className?: string;
  callback?: () => void;
}

const GameCellFavouritesButton: React.FC<FavouritesBtnProps> = ({
  gameUniqueId,
  categoryId,
  gameCellRef,
  show,
  className,
  callback,
}) => {
  const { inFavourite, onClickHandler, showFavBtn } = useFavouritesBtn({
    gameUniqueId,
    categoryId,
    gameCellRef,
    callback,
    show,
  });
  const favIconClass = clsx('casino-fav-btn', inFavourite && 'casino-fav-btn--active', className);

  return showFavBtn ? (
    <div id="casinoFavButton" className={favIconClass} onClick={onClickHandler} data-qid="casino-fav-button" />
  ) : (
    <></>
  );
};

export default GameCellFavouritesButton;
