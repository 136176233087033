import styled from 'styled-components';
import { Breakpoints } from 'theme/Theme';

export const StyledLink = styled.div`
  min-height: 30px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  fill: currentColor;
  padding: 12px 0;
  padding-bottom: 14px;
  text-align: center;
  z-index: 3;
  @media (min-width: ${Breakpoints.isMobile}px) {
    padding-bottom: 25px;
  }
`;
