import clsx from 'clsx';
import Icon from 'components/shared/Icon';
import { Flex } from 'components/shared/layout';
import Badge from './Badge';
import { Selector, locationNS, tabNS } from './customTabsUICSS.types';
import { classExtendByLocation } from './utils/customTabs.utils';

type Props = {
  className?: string;
  title?: string;
  icon?: string;
  badgeLabel?: string;
  disable?: boolean;
  isActive?: boolean;
  handleClick?: (index: number) => void;
  onClick?: (index: number) => void;
  index?: number;
  type?: 'primary' | 'secondary' | 'third';
  tabRef?: (node?: HTMLDivElement | null) => void;
  location?: 'promotions' | 'myAccount';
  dataQid?: string;
  badgeSelector?: Selector<number | undefined>;
  hasSubTabs?: boolean;
};

const Tab: React.FC<Props> = ({
  className,
  title,
  icon,
  badgeLabel,
  disable,
  isActive,
  handleClick,
  onClick,
  index,
  tabRef,
  type = 'primary',
  location = 'promotions',
  dataQid,
  badgeSelector,
  hasSubTabs,
}) => {
  const tabClass = clsx(
    `${locationNS}-${classExtendByLocation[location]}__tab`,
    `${locationNS}-${classExtendByLocation[location]}__tab--${type}`,
    hasSubTabs && `${locationNS}-${classExtendByLocation[location]}__tab--${type}--hasSubTabs`,
    isActive && `${locationNS}-${classExtendByLocation[location]}__tab--${type}--active`,
    isActive && hasSubTabs && `${locationNS}-${classExtendByLocation[location]}__tab--${type}--active--hasSubTabs`,
    disable && `${locationNS}-${classExtendByLocation[location]}__tab--${type}--disable`,
    className,
  );

  const triggerClick = () => {
    !disable && handleClick && handleClick(index ? index : 0);
    !disable && onClick && onClick(index ? index : 0);
  };

  return (
    <Flex.AC
      className={tabClass}
      onClick={triggerClick}
      role="tab"
      ref={(node: HTMLDivElement | null) => {
        if (tabRef) tabRef(node);
      }}
      data-qid={dataQid}
    >
      {icon && <Icon icon={icon} className={`${tabNS}-icon`} />}
      {title}
      {badgeLabel && (
        <Badge
          badgeLabel={badgeLabel}
          badgeSelector={badgeSelector}
          disable={disable}
          isActive={isActive}
          location={location}
        />
      )}
    </Flex.AC>
  );
};
// Dont change name -- need for tabs props
Tab.displayName = 'Tab';

export default Tab;
