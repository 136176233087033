import { useLayoutConfigType } from 'modules/casino/shared/hooks/useLayoutConfigType';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { General, LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppSelector } from 'store';

type TrackingDetailsProps = {
  trackingEventsConfig: General.TrackingEventsConfig;
  isAuthenticated: boolean | null;
  playerId: string | undefined;
};

export const useGetTrackingDetails = (): TrackingDetailsProps => {
  const trackingEventsConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.TRACKING_EVENTS,
    deviceLayout: 'general',
  });

  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const playerId = useAppSelector(selectMyProfile.playerId);

  return { trackingEventsConfig, isAuthenticated, playerId };
};

export default useGetTrackingDetails;
