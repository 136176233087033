import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { MyProfileTabType } from 'shared/common/features/myProfile/types/myProfile.types';
import type { RootState } from 'store/rootReducer';
import { MyProfileTabsType } from '../types/myAccount.types';

interface mpdAccountTabsInterface {
  accountActiveNavTab: MyProfileTabsType;
  mpdAccountActiveTab: MyProfileTabType['key'] | null;
  mpdAccountTabs: MyProfileTabType[];
}

const initialState: mpdAccountTabsInterface = {
  accountActiveNavTab: '',
  mpdAccountActiveTab: null,
  mpdAccountTabs: [],
};

const mpdAccountTabsReducer = createSlice({
  name: 'mpdAccountTabs',
  initialState,
  reducers: {
    setMyAccountTabs(state, action) {
      state.mpdAccountTabs = action.payload;
      if (!state.mpdAccountActiveTab) state.mpdAccountActiveTab = state.mpdAccountTabs?.[0]?.key || 'account';
    },
    setAccountActiveNavTab(state, action: PayloadAction<MyProfileTabsType>) {
      state.accountActiveNavTab = action.payload;
    },
    setMPDSectionActiveTab(
      state,
      action: PayloadAction<{ tabKey: MyProfileTabType['key']; navTab?: MyProfileTabsType }>,
    ) {
      state.mpdAccountActiveTab = action.payload.tabKey;
      if (action.payload.navTab) {
        state.accountActiveNavTab = action.payload.navTab;
      } else {
        state.accountActiveNavTab = '';
      }
    },
  },
});

export const { setMyAccountTabs, setAccountActiveNavTab, setMPDSectionActiveTab } = mpdAccountTabsReducer.actions;

export default mpdAccountTabsReducer.reducer;

export const selectAccountTabs = {
  mpdAccountActiveTab: (state: RootState): MyProfileTabType['key'] | null =>
    state.myAccount.mpdAccount.mpdAccountActiveTab,
  mpdAccountTabs: (state: RootState): MyProfileTabType[] => state.myAccount.mpdAccount.mpdAccountTabs,
  mpdAccountActiveTabIndex: createSelector(
    [
      (state: RootState) => state.myAccount.mpdAccount.mpdAccountTabs,
      (state: RootState) => state.myAccount.mpdAccount.mpdAccountActiveTab,
    ],
    (mpdAccountTabs, mpdAccountActiveTab) => mpdAccountTabs.findIndex((tab) => tab.key === mpdAccountActiveTab),
  ),
  accountActiveNavTab: (state: RootState) => state.myAccount.mpdAccount.accountActiveNavTab,
};
