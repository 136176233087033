import { memo, useEffect, useRef } from 'react';
import React from 'react';
import { Flex } from 'components/shared/layout';
import { addLeadingZero } from 'utils/dateUtils';

type Props = {
  date: number;
  text: string;
  counterStyling?: string;
  prevDate: number;
};

const AnimatedCounterItem: React.FC<Props> = ({ date, prevDate, text, counterStyling }) => {
  const countDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let flipTimeout: NodeJS.Timeout | undefined;
    if (countDownRef) {
      countDownRef.current?.classList.add('flip');

      flipTimeout = setTimeout(() => {
        countDownRef.current?.classList.remove('flip');
      }, 800);
    }
    return () => {
      if (flipTimeout) {
        clearTimeout(flipTimeout);
      }
    };
  }, [date]);

  return (
    <>
      <Flex.ColAC className={`text-center ${counterStyling}-item`}>
        <div className={`${counterStyling}-date`} ref={countDownRef}>
          <div className={`${counterStyling}-top`}>{addLeadingZero(date)}</div>
          <div className={`${counterStyling}-bottom`} data-value={addLeadingZero(date)}></div>
          <div className={`${counterStyling}-back`} data-value={addLeadingZero(prevDate)}></div>
          <div className={`${counterStyling}-back-bottom`} data-value={addLeadingZero(prevDate)}></div>
        </div>
        <div className={`${counterStyling}-date-text`}>{text}</div>
      </Flex.ColAC>
    </>
  );
};

export default memo(AnimatedCounterItem);
