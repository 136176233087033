import i18next, { t } from 'i18next';
import { formatAmountToBRL } from 'modules/retail/modules/cashier/utils';

import {
  MapEventContentSingleDataParams,
  MapEventContentSportEventNameParams,
  OutcomesData,
  ProcessRegularBetSelectionParams,
} from 'modules/retail/modules/ssbt/types/ssbt.types';
import { BonusesTypes, CommandData } from 'modules/retail/shared/types/retailCommon.types';
import { defineBonusesType, isRaceSport, isRaceStraightMarket } from 'modules/retail/shared/utils/helperFunctions';
import {
  formatEventStartDate,
  formatStringPerRow,
  printMethods,
  retailPrintOptions,
  commands,
} from 'modules/retail/shared/utils/retail.utils';
import { store } from 'store';
import { isEmpty, retailFormatDate, retailFormatDateReceipt, toFixed } from 'utils/common/helpersCommon';
import { BetSlip, CashierTranslations, SSBT } from 'utils/common/translationUtils/translationStrings';
import { MAX_MARKET_NAME_LENGHT_PER_ROW_CASHIER } from '../../utils/constants';

export const mapEventContentRegularData = <
  T extends { outcomeId: string; selectedSystem: string; totalCombinations: number },
  K extends OutcomesData,
>({
  value,
  getState,
  sportEventId,
  isSingle,
  getSingleBetProps,
  singles,
  outcomes,
  currency,
  isCurrencyBeforeAmount,
}: ProcessRegularBetSelectionParams<T, K>) => {
  const currencySettings = store.getState()?.common?.general?.currencySettings;
  const currencySymbol = currencySettings?.find((c) => c.name === currency)?.nameTranslations || currency;
  const maxSportEventNameLengthPerRow = 43;
  const maxValueNameLengthPerRow = 38;
  const rawMarketName = !isEmpty(value?.marketName) ? value?.marketName : value?.marketTemplateName;
  const marketName = formatStringPerRow(MAX_MARKET_NAME_LENGHT_PER_ROW_CASHIER, rawMarketName);

  const eventContentRegularData: CommandData[] = [];

  const rawStartDate =
    value.eventStartDate === null
      ? retailFormatDate(value.scheduledTime)
      : retailFormatDateReceipt(value.eventStartDate);

  const isLive = !isEmpty(value.isLive) ? value.isLive : null;
  const liveAndTime =
    isLive !== null
      ? isLive
        ? `${formatEventStartDate(rawStartDate)} ` + t(CashierTranslations.Header.live)
        : formatEventStartDate(rawStartDate)
      : formatEventStartDate(rawStartDate);

  printMethods.printOptions(eventContentRegularData, commands, retailPrintOptions.eventOptionsNew);
  printMethods.print(eventContentRegularData, commands, liveAndTime);
  printMethods.printOptions(eventContentRegularData, commands, retailPrintOptions.eventOptionsNew);
  if (value.sportEventName.length > maxSportEventNameLengthPerRow) {
    const splitEventName = value.sportEventName.split(' vs. ');
    printMethods.print(eventContentRegularData, commands, splitEventName[0] + ' ' + 'vs.');
    printMethods.print(eventContentRegularData, commands, splitEventName[1]);
  } else {
    printMethods.print(eventContentRegularData, commands, value.sportEventName);
  }
  const iconEarlyPayout =
    getState()?.common?.general?.layoutConfig?.['platform']?.general?.retailBonusIcons?.earlyPayout;
  const isEarlyPayoutActive = defineBonusesType(value, BonusesTypes.earlyPayout);
  // const isBoreDrawActive = defineBonusesType(value, BonusesTypes.boreDraw);
  // const isGoalInsuranceActive = defineBonusesType(value, BonusesTypes.goalInsurance);
  // const iconBoreDraw = getState()?.common?.general?.layoutConfig?.platform?.general?.retailBonusIcons?.boreDraw;
  // const iconGoalInsurance =
  //   getState()?.common?.general?.layoutConfig?.platform?.general?.retailBonusIcons?.goalInsurance;

  const isVirtual = /^3/.test(sportEventId);
  printMethods.conditionalPrint(
    eventContentRegularData,
    commands,
    !isEmpty(getState()?.['virtuals']?.virtualLobby?.tournamentEvents?.iframeTitleData?.competition),
    getState()?.['virtuals']?.virtualLobby?.tournamentEvents?.iframeTitleData?.competition,
  );
  printMethods.conditionalPrint(eventContentRegularData, commands, isVirtual, `Match ${sportEventId}`);
  if (!isEmpty(marketName)) {
    for (const str of marketName) {
      printMethods.print(eventContentRegularData, commands, str);
    }
  }
  const outcomeStr =
    `${value.name} (${toFixed(value.odds, 2)})` +
    (value.banker === true ? ' ' + t(BetSlip.Selections.bankerLetter) : '');
  printMethods.printOptions(eventContentRegularData, commands, retailPrintOptions.totalStakeOptions);
  printMethods.conditionPrintImageAndStringInARow(
    eventContentRegularData,
    commands,
    isEarlyPayoutActive,
    iconEarlyPayout,
    outcomeStr,
    'totalStakeOptions',
  );
  if (value.name.length > maxValueNameLengthPerRow && !isEarlyPayoutActive) {
    printMethods.print(eventContentRegularData, commands, value.name);
    printMethods.print(eventContentRegularData, commands, `(${toFixed(value.odds, 2)})`);
    if (value.banker === true) {
      printMethods.print(eventContentRegularData, commands, ' ' + t(BetSlip.Selections.bankerLetter));
    }
  } else {
    printMethods.conditionalPrint(eventContentRegularData, commands, !isEarlyPayoutActive, outcomeStr);
  }
  printMethods.printOptions(eventContentRegularData, commands, retailPrintOptions.totalStakeOptions);
  printMethods.printOptions(eventContentRegularData, commands, retailPrintOptions.eventOptions);

  if (isSingle) {
    const singleBetProps = getSingleBetProps(value);
    const singleOutcome = singles.find((x) => x?.outcomeId === value?.id);

    if (singleBetProps && singleOutcome) {
      printMethods.print(eventContentRegularData, commands, ''.padEnd(44, '-'));
      printMethods.printOptions(eventContentRegularData, commands, retailPrintOptions._optionsData);
      const systemStr =
        i18next.t(`BetSlip.Systems.${singleBetProps.selectedSystem}`) +
        `, ${singleOutcome.totalCombinations} ${i18next.t(SSBT.Ticket.totalCombinations)} X ` +
        (isCurrencyBeforeAmount
          ? `${currencySymbol} ${formatAmountToBRL(singleBetProps.stake)}`
          : `${toFixed(singleBetProps.stake, 2)} ${currency}`);
      printMethods.print(eventContentRegularData, commands, systemStr);

      const totalOddsStr = `${i18next.t(BetSlip.SSBT.totalOdds)} ${toFixed(singleBetProps.totalOdds, 2)}`;
      printMethods.print(eventContentRegularData, commands, totalOddsStr);

      const stakeInfoStr =
        `${i18next.t(BetSlip.BetSlipConfirmation.stakeInfoText)}: ` +
        (isCurrencyBeforeAmount
          ? `${currencySymbol} ${formatAmountToBRL(singleBetProps.stake)}`
          : `${toFixed(singleBetProps.stake, 2)} ${currency}`);
      printMethods.print(eventContentRegularData, commands, stakeInfoStr);

      const possibleWinStr =
        `${i18next.t(BetSlip.PlaceBetButton.possibleWinText)}: ` +
        (isCurrencyBeforeAmount
          ? `${currencySymbol} ${formatAmountToBRL(singleBetProps.win)}`
          : `${toFixed(singleBetProps.win, 2)} ${currency}`);
      printMethods.print(eventContentRegularData, commands, possibleWinStr);
    }
  } else {
    printMethods.printOptions(eventContentRegularData, commands, retailPrintOptions._optionsData);
  }

  printMethods.print(
    eventContentRegularData,
    commands,
    ''.padEnd(44, outcomes.length - 1 === outcomes.indexOf(value) ? '=' : '-'),
  );
  return eventContentRegularData;
};
export const mapEventContentSportEventName = ({ value: value }: MapEventContentSportEventNameParams) => {
  const eventContentSingleData: CommandData[] = [];
  const sportEventNameLength = value.sportEventName.length;
  const maxSportEventNameLengthPerRow = 43;
  const rawStartDate = isEmpty(value.eventStartDate)
    ? retailFormatDateReceipt(value.scheduledTime)
    : retailFormatDateReceipt(value.eventStartDate);
  const isLive = !isEmpty(value.isLive) ? value.isLive : null;

  const liveAndTime =
    isLive !== null
      ? isLive
        ? `${formatEventStartDate(rawStartDate)} ` + t(CashierTranslations.Header.live)
        : formatEventStartDate(rawStartDate)
      : formatEventStartDate(rawStartDate);
  printMethods.printOptions(eventContentSingleData, commands, retailPrintOptions.eventOptionsNew);
  printMethods.print(eventContentSingleData, commands, liveAndTime);
  printMethods.printOptions(eventContentSingleData, commands, retailPrintOptions.totalStakeOptions);
  if (value.sportEventName.length > maxSportEventNameLengthPerRow) {
    const splitEventName = value.sportEventName.split(' vs. ');
    printMethods.print(eventContentSingleData, commands, splitEventName[0] + ' ' + 'vs.');
    printMethods.print(eventContentSingleData, commands, splitEventName[1]);
  } else {
    printMethods.print(eventContentSingleData, commands, value.sportEventName);
  }
  printMethods.print(eventContentSingleData, commands, ''.padEnd(sportEventNameLength, '='));
  return eventContentSingleData;
};

export const mapEventContentSingleData = <T extends { outcomeId: string; id: number; totalCombinations: number }>({
  value,
  isSingle,
  getSingleBetProps,
  singles,
  outcomes,
  currency,
  isCurrencyBeforeAmount,
}: MapEventContentSingleDataParams<T>) => {
  const currencySettings = store.getState()?.common?.general?.currencySettings;

  const currencySymbol = currencySettings?.find((c) => c.name === currency)?.nameTranslations || currency;
  const rawMarketName = !isEmpty(value?.marketName) ? value?.marketName : value?.marketTemplateName;
  const marketName = formatStringPerRow(MAX_MARKET_NAME_LENGHT_PER_ROW_CASHIER, rawMarketName);
  const eventContentSingleData: CommandData[] = [];
  printMethods.printOptions(eventContentSingleData, commands, retailPrintOptions.totalStakeOptions);
  const outcomeStr =
    `${value.name} (${toFixed(value.odds, 2)})` +
    (value.banker === true ? ' ' + t(BetSlip.Selections.bankerLetter) : '');
  printMethods.printOptions(eventContentSingleData, commands, retailPrintOptions.eventOptionsNew);
  if (!isEmpty(marketName)) {
    for (const str of marketName) {
      printMethods.print(eventContentSingleData, commands, str);
    }
  }
  printMethods.printOptions(eventContentSingleData, commands, retailPrintOptions.totalStakeOptions);
  if (isRaceSport(value.sportId)) {
    const positions = value.name.split(',');
    const isStraightMarket = isRaceStraightMarket(value?.marketTemplateId);

    positions.forEach((position: string, index: number) => {
      if (position) {
        const competitorName = position.replace(/[0-9]/g, '');
        const competitorNumber = position.replace(/[^0-9]/g, '');
        const competitorPosition = isStraightMarket ? `${index + 1}` : '';
        printMethods.print(
          eventContentSingleData,
          commands,
          `${competitorPosition}${competitorNumber ? `[${competitorNumber}]` : ''}${competitorName}`,
        );
      }
    });
  }
  printMethods.conditionalPrint(
    eventContentSingleData,
    commands,
    isRaceSport(value.sportId),
    `${`${i18next.t('MyBets.BetHistory.totalOddsText')}: ${toFixed(value.odds, 2)}`}`,
  );
  printMethods.conditionalPrint(eventContentSingleData, commands, !isRaceSport(value?.sportId), outcomeStr);
  printMethods.printOptions(eventContentSingleData, commands, retailPrintOptions._optionsData);

  if (isSingle) {
    const singleBetProps = getSingleBetProps(value);
    const singleOutcome = singles.find((x) => x?.outcomeId === value?.id);

    if (singleBetProps && singleOutcome) {
      printMethods.printOptions(eventContentSingleData, commands, retailPrintOptions._optionsData);
      printMethods.conditionalPrint(eventContentSingleData, commands, isRaceSport(value?.sportId), ''.padEnd(44, '-'));
      const systemStr =
        i18next.t(`BetSlip.Systems.${singleBetProps.selectedSystem}`) +
        `, ${singleOutcome.totalCombinations} ${i18next.t(SSBT.Ticket.totalCombinations)} X ` +
        (isCurrencyBeforeAmount
          ? `${currencySymbol} ${formatAmountToBRL(singleBetProps.stake)}`
          : `${toFixed(singleBetProps.stake, 2)} ${currency}`);
      printMethods.print(eventContentSingleData, commands, systemStr);
      if (isRaceSport(value.sportId)) {
        const stakeInfoStr =
          `${t(BetSlip.BetSlipConfirmation.stakeInfoText)}: ${toFixed(singleBetProps.stake, 2)} ` + `${currency}`;
        printMethods.print(eventContentSingleData, commands, stakeInfoStr);
        const possibleWinStr =
          `${t(BetSlip.BetSlipConfirmation.summaryTotalWinText)}: ${toFixed(singleBetProps.win, 2)} ` + `${currency}`;
        printMethods.print(eventContentSingleData, commands, possibleWinStr);
      }
    }
  }
  printMethods.print(
    eventContentSingleData,
    commands,
    ''.padEnd(44, outcomes.length - 1 === outcomes.indexOf(value) ? '=' : '-'),
  );
  return eventContentSingleData;
};
