import { memo } from 'react';
import { useParams } from 'react-router-dom';
import { Flex } from 'components/shared/layout';
import { ChallengesCell } from 'pages/captainUp/features/Challenges/components';
import { selectStore } from 'pages/captainUp/features/Store/slice/store.slice';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';

const ClubGames = () => {
  const categoryItem = useAppSelector(selectStore.getCategory('active'));
  const { type } = useParams();
  const category = 'active';
  if (isEmpty(categoryItem)) return null;

  return (
    <Flex.Col>
      {categoryItem?.ids.map((id, idx) => (
        <ChallengesCell
          key={`badge-${id}`}
          id={id}
          order={idx}
          collections={`${category}_${type}`}
          type={`horizontal-small`}
          typeTap={`/${category}`}
          alias={category}
          categoryId={5}
        />
      ))}
    </Flex.Col>
  );
};

export default memo(ClubGames);
