import CTA from './CTA';
import CTAButton from './CTAButton';
import Grid from './Grid';
import GridHeader from './GridHeader';
import Header from './Header';
import { SCHeader } from './Header.styled';
import Page from './Page';
import PageBox from './PageBox';
import PageBoxGrid from './PageBoxGrid';
import PageBoxTitle from './PageBoxTitle';
import RankingBox from './RankingBox';
import RankingLink from './RankingLink';
import Step from './Step';
import TermsBox from './TermsBox';
import Thumb from './Thumb';

export const PromoUI = {
  CTA: CTA,
  CTAButton: CTAButton,
  Grid: Grid,
  GridHeader: GridHeader,
  RankingBox: RankingBox,
  RankingLink: RankingLink,
  Page: Page,
  PageBox: PageBox,
  PageBoxGrid: PageBoxGrid,
  PageBoxTitle: PageBoxTitle,
  Step: Step,
  TermsBox: TermsBox,
  Thumb: Thumb,
  Header: Header,
  SCHeader: SCHeader,
};
