import { useMemo } from 'react';
import { selectNavigationMenu } from 'shared/common/features/navigationMenu/slice/navigationMenu.slice';
import { Vertical } from 'shared/common/features/navigationMenu/types/navigationMenu.types';
import { useAppSelector } from 'store';
import useVerticalsConfig from './useVerticalsConfig';

export const useFilterHiddenVerticals = (): Vertical[] => {
  const { configVerticals } = useVerticalsConfig();
  const navigationMenu = useAppSelector(selectNavigationMenu.verticals);

  const verticals = useMemo(() => {
    const filterVerticals = navigationMenu?.filter((item) => !item?.isHidden);
    return [...(filterVerticals || []), ...(configVerticals || [])] as Vertical[];
  }, [configVerticals, navigationMenu]);

  return verticals;
};
