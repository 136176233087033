import { store } from 'store';
import { isEmpty, toFixed } from 'utils/common/helpersCommon';
import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';
import { checkIfPrintingIsDone } from '../../modules/cashier/slice/cashier.slice';
import { OutcomeRetailInfo, PosInfoType } from '../../modules/cashier/types/cashier.types';
import { CommandData, TerminalCommands } from '../types/retailCommon.types';

export const printMethods = {
  printStart: (content: CommandData[], commands: TerminalCommands) => {
    content.push({ command: commands.start, bodyData: {} });
  },
  printImage: (content: CommandData[], commands: TerminalCommands, base64Image: string) => {
    content.push({ command: commands.printImage, bodyData: { base64_image: base64Image } });
  },
  printOptions: (eventContent: CommandData[], commands: TerminalCommands, options: Record<string, string>) => {
    eventContent.push({ command: commands.printOptions, bodyData: options });
  },

  print: (eventContent: CommandData[], commands: TerminalCommands, str: string) => {
    eventContent.push({ command: commands.print, bodyData: { str } });
  },

  conditionalPrint: (eventContent: CommandData[], commands: TerminalCommands, condition: boolean, str: string) => {
    if (condition) {
      printMethods.print(eventContent, commands, str);
    }
  },

  conditionalPrintQrCode: (
    eventContent: CommandData[],
    commands: TerminalCommands,
    condition: boolean,
    qrCode: string,
  ) => {
    if (condition) {
      printMethods.printQrCode(eventContent, commands, qrCode);
    }
  },
  conditionalPrintBarcode: (
    eventContent: CommandData[],
    commands: TerminalCommands,
    condition: boolean,
    qrCode: string,
  ) => {
    if (condition) {
      printMethods.printBarcode(eventContent, commands, qrCode);
    }
  },
  conditionalPrintImage: (
    eventContent: CommandData[],
    commands: TerminalCommands,
    condition: boolean,
    imageStr: string,
  ) => {
    if (condition) {
      printMethods.printImage(eventContent, commands, imageStr);
    }
  },
  conditionPrintImageAndStringInARow: (
    content: CommandData[],
    commands: TerminalCommands,
    condition,
    imageStr: string,
    str: string,
    style?: string | undefined,
  ) => {
    const widthEachLetterOfTheReceiptTakesInPixels = 14;
    if (condition) {
      const distanceBetweenTextAndIcon = str.length * widthEachLetterOfTheReceiptTakesInPixels;
      printMethods.printOptions(content, commands, retailPrintOptions.eventNameOptions);
      printMethods.colDefine(content, commands, distanceBetweenTextAndIcon, 'middle', 'left');
      printMethods.colDefine(content, commands, 290, 'middle', 'left');
      if (!isEmpty(style)) {
        printMethods.printOptions(content, commands, retailPrintOptions[style]);
      }
      printMethods.print(content, commands, str);
      printMethods.printImage(content, commands, imageStr);
      printMethods.colReset(content, commands);
    }
  },
  colDefine: (
    eventContent: CommandData[],
    commands: TerminalCommands,
    width: number,
    v_alignment: string,
    h_alignment: string,
  ) => {
    eventContent.push({ command: commands.colDefine, bodyData: { width, v_alignment, h_alignment } });
  },

  colReset: (eventContent: CommandData[], commands: TerminalCommands) => {
    eventContent.push({ command: commands.colReset, bodyData: {} });
  },
  emptyCommand: (content: CommandData[], condition: boolean) => {
    if (condition) {
      content.push({ command: '', bodyData: {} });
    }
  },
  printBarcode: (content: CommandData[], commands: TerminalCommands, barcode: string) => {
    content.push({ command: commands.printBarcode, bodyData: { barcode } });
  },
  printQrCode: (content: CommandData[], commands: TerminalCommands, qrCode: string) => {
    content.push({ command: commands.printQRCode, bodyData: { qrCode } });
  },
  printQrCodeSize: (content: CommandData[], commands: TerminalCommands, qrCode: string) => {
    content.push({ command: commands.printQrCodeSize, bodyData: { str: qrCode, pixel_size: 8 } });
  },
  printEject: (content: CommandData[], commands: TerminalCommands) => {
    content.push({ command: commands.eject, bodyData: {} });
  },
  printCopies: (content: CommandData[], commands: TerminalCommands, numOfCopies: number) => {
    content.push({ command: commands.printCopies, bodyData: { numOfCopies } });
  },
  printIsReady: (content: CommandData[], commands: TerminalCommands) => {
    content.push({ command: commands.isReady, bodyData: {} });
  },
};
export const retailPrintOptions = {
  eventOptions: { alignment: 'right', size: 'medium', effect: 'normal' },
  eventOptionsNew: { alignment: 'left', size: 'medium', effect: 'normal' },
  _oddsOptionsData: { alignment: 'right', size: 'medium', effect: 'bold' },
  _optionsData: { alignment: 'left', size: 'medium', effect: 'normal' },
  _logoOptionsData: { alignment: 'center', size: 'large', effect: 'bold' },
  winbetOptions: { alignment: 'center', size: 'medium', effect: 'normal' },
  _addressOptions: { alignment: 'center', size: 'large', effect: 'bold' },
  winbetAddress: { alignment: 'center', size: 'small', effect: 'normal' },
  _emptySpace: { str: ' ' },
  totalStakeOptions: { alignment: 'left', size: 'medium', effect: 'bold' },
  eventNameOptions: { alignment: 'left', size: 'medium', effect: 'normal' },
  betBuilderDotStyle: { alignment: 'center', size: 'large', effect: 'bold' },
  voucherValueOptions: { alignment: 'center', size: 'medium', effect: 'bold' },
};

const port = 'http://localhost:10000';
export const commands: TerminalCommands = {
  start: `${port}/prn_start.json`,
  print: `${port}/prn_print.json`,
  printOptions: `${port}/prn_opts.json`,
  printImage: `${port}/prn_img.json`,
  printBarcode: `${port}/prn_code128.json`,
  printQRCode: `${port}/prn_qr_code.json`,
  colDefine: `${port}/prn_col_define.json`,
  colReset: `${port}/prn_col_reset.json`,
  eject: `${port}/prn_eject.json`,
  printQrCodeSize: `${port}/prn_qr_code_size.json`,
  printCopies: `${port}/prn_copies.json`,
  isReady: `${port}/prn_is_ready.json`,
};

export const printEjectCommand = 'http://localhost:10000/prn_eject.json';

export const getTotalStake = ({ bets }) => {
  let totalStake = 0;

  bets.forEach((bet) => {
    totalStake += bet?.stake?.originalValue;
  });
  return toFixed(totalStake, 2);
};

export const getTotalOdds = ({ bets }) => {
  let totalOdds = 1;

  bets.forEach((bet) => {
    const odds = bet?.manualReofferDetails?.totalOdds || 1;
    totalOdds *= odds;
  });
  return toFixed(totalOdds, 2);
};

export const formatManualReofferRequestNumber = (number) => {
  const strNumber = number.toString();

  const lastFiveDigits = strNumber.slice(-5);

  const result = lastFiveDigits.replace(/^0+/, '');

  return result;
};

export const formatEventStartDate = (rawDate) => {
  if (rawDate.includes('/')) {
    const eventStartDate = rawDate
      .split(' ')
      .reverse()
      .join(' ')
      .replaceAll('/', '.')
      .slice(0, rawDate.length - 3);
    return eventStartDate;
  }
  return rawDate;
};

export const formatStringPerRow = (MAX_LENGHT_PER_ROW: number, text: string) => {
  if (!text || MAX_LENGHT_PER_ROW <= 0) return;
  const result: string[] = [];
  let currentIndex: number = 0;
  while (currentIndex < text.length) {
    const newChunk = text.slice(currentIndex, currentIndex + MAX_LENGHT_PER_ROW);
    result.push(newChunk);
    currentIndex += MAX_LENGHT_PER_ROW;
  }

  if (result[result.length - 1]?.startsWith(' ')) {
    result[result.length - 1] = result[result.length - 1].trimStart();
  }

  return result;
};

export const getCashierPosInfo = (posInfo?: PosInfoType) => {
  return !isEmpty(posInfo)
    ? {
        posName: posInfo.name,
        posAddress: posInfo.address,
      }
    : {
        posName: '',
        posAddress: {
          country: '',
          city: '',
          zipCode: '',
          streetName: '',
          streetNumber: '',
        },
      };
};

export const bonusParseJSON = (data) => {
  if (typeof data !== 'string') {
    return null;
  }
  try {
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
};
export const getRetailInfo = (retailInfo): OutcomeRetailInfo => {
  const address = retailInfo?.posAddress || retailInfo?.address || '';
  const posName = retailInfo?.posName || '';
  const pin = retailInfo?.pin || '';
  const dailySequenceNumber = retailInfo?.dailySequenceNumber || '';
  const mnemonicCode = retailInfo?.mnemonicCode || '';
  const checkTicketRetailInfo = retailInfo?.retailInfo || {};
  const playerCpf = retailInfo?.playerCpf;
  return {
    address,
    posName,
    pin,
    dailySequenceNumber,
    mnemonicCode,
    checkTicketRetailInfo,
    playerCpf,
  };
};

export const determineInfoMessage = (ticketInfoStatus, t) => {
  switch (ticketInfoStatus) {
    case 'failure':
      return t(CashierTranslations.CashierCheckTicket.invalidTicketNumber);
    case 'pending':
      return t(CashierTranslations.CashierCheckTicket.pleaseWait);
    default:
      return t(CashierTranslations.CashierCheckTicket.checkTicketInit);
  }
};

export const monitorPrintingCompletion = async () => {
  let printerBusyMessage = '';
  do {
    await checkIfPrintingIsDone(store.dispatch);

    printerBusyMessage = store.getState().retail.cashier.printerBusyMessage;
  } while (printerBusyMessage !== '');
};
