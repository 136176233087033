import { NotificationTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationTypes';
import { ImageCDN } from '../ImageCDN';

export const animParamsMock: NotificationTypes.GamificationModalParams = {
  folder: 'easter',
  layout: '1column',
  logo: 'logo.png',
  logoWidth: 300,
  logoHeight: 120,
  background: 'bgr.png',
  modalCloseColor: 'light',
  numberOfFrames: 48,
  frameWidth: 280,
  frameMaxWidth: 280,
  frameHeight: 396,
  frameMaxHeight: 396,
  frameAspectRatio: 0.71,
  framePixelDensity: 2,
  frameBaseName: 'EggFrame',
  fileBaseName: 'frame-1_000',
  fileExt: '.png',
};

export const mapAnimationFrames = (
  animationParams: NotificationTypes.GamificationModalParams,
  fileName?: string | false,
  numberOfFrames?: number,
  width?: number,
  height?: number,
): ImageCDN[] =>
  Array(numberOfFrames ? numberOfFrames : animationParams.numberOfFrames)
    .fill(0)
    .map((_, index) => {
      return new ImageCDN(
        (fileName ? fileName : animationParams.fileBaseName) + (index < 10 ? 0 : '') + index + animationParams.fileExt,
        animationParams.folder,
        width ? width : animationParams.frameMaxWidth,
        height ? height : animationParams.frameMaxHeight,
        animationParams.framePixelDensity,
      );
    });

export const mapAnimationAssets = (
  animationParams?: NotificationTypes.GamificationModalParams,
  fileName?: string | false,
  numberOfFrames?: number,
): Record<string, ImageCDN>[] =>
  !animationParams
    ? []
    : Array(numberOfFrames ? numberOfFrames : animationParams.numberOfFrames)
        .fill(0)
        .map((_, index) => {
          const name = animationParams.frameBaseName + (index < 10 ? 0 : '') + index;
          const image = {
            [name]: new ImageCDN(
              (fileName ? fileName : animationParams.fileBaseName) +
                (index < 10 ? 0 : '') +
                index +
                animationParams.fileExt,
              animationParams.folder,
              animationParams.frameMaxWidth,
              animationParams.frameMaxHeight,
              animationParams.framePixelDensity,
            ),
          };
          return image;
        });

export const mapCalendarAssets = (
  history: any,
  animationParams?: NotificationTypes.GamificationModalParams,
): Record<string, ImageCDN>[] =>
  !animationParams || !history
    ? []
    : Array(history.length)
        .fill(0)
        .map((_, index) => {
          const name = animationParams.frameBaseName + (index < 10 ? 0 : '') + index;
          const day = index + 1;
          const image = {
            [name]: new ImageCDN(
              `${day}/${day}-${history[index].past ? 'inactive' : 'regular'}${animationParams.fileExt}`,
              animationParams.folder,
              animationParams.frameMaxWidth,
              animationParams.frameMaxHeight,
              animationParams.framePixelDensity,
            ),
          };
          return image;
        });

export const mapFirstItemFromFolder = ({
  itemsLength,
  animationParams,
  fileName,
  getFromFolder = true,
  randomFromFolder,
  customImageFolder,
}: {
  itemsLength: number;
  animationParams?: NotificationTypes.GamificationModalParams;
  fileName?: string;
  getFromFolder?: boolean;
  randomFromFolder?: number[];
  customImageFolder?: NotificationTypes.OptionsBonusType[];
}): Record<string, ImageCDN>[] =>
  !animationParams || !itemsLength
    ? []
    : Array(itemsLength)
        .fill(0)
        .map((_, index) => {
          const name = animationParams.frameBaseName + (index < 10 ? 0 : '') + index;
          let folderPath = '';
          if (customImageFolder) {
            folderPath = `${customImageFolder[index]?.image}/`;
          } else if (getFromFolder) {
            folderPath = `${index + 1}/`;
          } else if (randomFromFolder) {
            folderPath = `${randomFromFolder[index]}/`;
          }

          const image = {
            [name]: new ImageCDN(
              folderPath +
                (fileName ? fileName : animationParams.fileBaseName) +
                (index < 10 ? 0 : '') +
                index +
                animationParams.fileExt,
              animationParams.folder,
              animationParams.frameMaxWidth,
              animationParams.frameMaxHeight,
              animationParams.framePixelDensity,
            ),
          };
          return image;
        });
