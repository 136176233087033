import { t } from 'i18next';
import { setIsPrintingEjected } from 'modules/retail/modules/cashier/slice/cashier.slice';
import {
  PrintCashierCancelTicket,
  PrintCashierPayout,
  PrintFinancialResults,
} from 'modules/retail/modules/cashier/types/cashier.types';
import { formatCurrency } from 'modules/retail/modules/cashier/utils';
import { formatFromAPI } from 'modules/retail/modules/ssbt/components/terminalKeyboard/helpersFuncCashier';
import { enqueuePrintRequest, processPrintQueue } from 'modules/retail/shared/slice/printQueue.slice';
import { CommandData } from 'modules/retail/shared/types/retailCommon.types';
import {
  commands,
  monitorPrintingCompletion,
  printEjectCommand,
  printMethods,
} from 'modules/retail/shared/utils/retail.utils';
import { store } from 'store';
import { getError, isEmpty, retailFormatDate, toFixed } from 'utils/common/helpersCommon';
import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';
import { makeRequest } from '../../../shared/printMethods/printUtils/helperPrintFunctions';
import {
  _addressOptions,
  _logoOptionsData,
  _optionsData,
  eventOptions,
  voucherValueOptions,
  winbetAddress,
  winbetOptions,
} from '../../../shared/printMethods/printUtils/printReceiptOptions';

export const printCashierPayoutMobile = async ({
  totalWin,
  code,
  currency,
  retailAddress,
  isDuplicate,
  isClaimForm,
  dailySequenceNumber,
  pinNumber,
  totalStake,
  posName,
  posAddress,
  betTax,
  totalAmountAfterTax,
  isCurrencyBeforeAmount,
}: PrintCashierPayout) => {
  const printCashierPayoutJob = async () => {
    const isMagnumBet = store.getState()?.common?.general?.layoutConfig?.['platform']?.general?.isMagnumBetRo;
    const mapCashierPayoutData: CommandData[] = [];
    printMethods.printIsReady(mapCashierPayoutData, commands);
    printMethods.printStart(mapCashierPayoutData, commands);
    printMethods.printOptions(mapCashierPayoutData, commands, _logoOptionsData);
    printMethods.printImage(mapCashierPayoutData, commands, retailAddress?.buLogo);
    printMethods.printOptions(mapCashierPayoutData, commands, _optionsData);
    printMethods.print(mapCashierPayoutData, commands, '');
    printMethods.printOptions(mapCashierPayoutData, commands, winbetOptions);
    printMethods.print(mapCashierPayoutData, commands, t(retailAddress?.responsibleGambling));
    printMethods.print(mapCashierPayoutData, commands, t('Cashier.Receipt.addressPOSText'));
    printMethods.print(mapCashierPayoutData, commands, `${posAddress.city},`);
    printMethods.print(mapCashierPayoutData, commands, `${posAddress.streetName}`);
    printMethods.print(mapCashierPayoutData, commands, `${posAddress.streetNumber}`);
    printMethods.print(mapCashierPayoutData, commands, '');
    printMethods.print(mapCashierPayoutData, commands, t('Cashier.Receipt.signatureNumber'));
    printMethods.print(mapCashierPayoutData, commands, posName);
    printMethods.printOptions(mapCashierPayoutData, commands, _logoOptionsData);
    printMethods.conditionalPrint(
      mapCashierPayoutData,
      commands,
      isDuplicate,
      `${t(CashierTranslations.Print.dublicateText)}`,
    );
    printMethods.conditionalPrint(mapCashierPayoutData, commands, isDuplicate, '');
    printMethods.conditionalPrint(
      mapCashierPayoutData,
      commands,
      window.config.ENV_TYPE !== 'PRODUCTION',
      t('Cashier.Receipt.testOperation'),
    );
    printMethods.print(mapCashierPayoutData, commands, ''.padEnd(44, '='));
    printMethods.printOptions(mapCashierPayoutData, commands, _addressOptions);
    printMethods.print(
      mapCashierPayoutData,
      commands,
      isClaimForm ? t('Cashier.Print.claimFormText') : t(CashierTranslations.ActionButton.payTicketButton),
    );
    printMethods.printOptions(mapCashierPayoutData, commands, voucherValueOptions);
    printMethods.print(mapCashierPayoutData, commands, code);
    printMethods.printOptions(mapCashierPayoutData, commands, winbetOptions);
    printMethods.print(mapCashierPayoutData, commands, ''.padEnd(44, '='));
    printMethods.printOptions(mapCashierPayoutData, commands, voucherValueOptions);
    printMethods.print(mapCashierPayoutData, commands, t(CashierTranslations.CashierModal.issuedDate));
    printMethods.print(mapCashierPayoutData, commands, retailFormatDate(Date.now()) + ' ');
    printMethods.printOptions(mapCashierPayoutData, commands, winbetOptions);
    printMethods.print(mapCashierPayoutData, commands, ''.padEnd(44, '='));
    printMethods.printOptions(mapCashierPayoutData, commands, voucherValueOptions);
    printMethods.print(mapCashierPayoutData, commands, t('Cashier.Print.profitText'));
    printMethods.print(
      mapCashierPayoutData,
      commands,
      formatCurrency(totalWin, currency, isCurrencyBeforeAmount) + ' ',
    );
    printMethods.printOptions(mapCashierPayoutData, commands, eventOptions);
    printMethods.print(mapCashierPayoutData, commands, ''.padEnd(44, '='));
    printMethods.printOptions(mapCashierPayoutData, commands, voucherValueOptions);
    printMethods.print(mapCashierPayoutData, commands, t(`BetSlip.BetSlipConfirmation.summaryTotalStakeText`));
    printMethods.print(
      mapCashierPayoutData,
      commands,
      formatCurrency(totalStake, currency, isCurrencyBeforeAmount) + ' ',
    );
    printMethods.printOptions(mapCashierPayoutData, commands, winbetOptions);
    printMethods.conditionalPrint(mapCashierPayoutData, commands, isMagnumBet, ''.padEnd(44, '='));
    printMethods.printOptions(mapCashierPayoutData, commands, voucherValueOptions);
    printMethods.conditionalPrint(mapCashierPayoutData, commands, isMagnumBet, t('Cashier.Receipt.betTax') + ' ');
    printMethods.conditionalPrint(mapCashierPayoutData, commands, isMagnumBet, `${toFixed(betTax, 2)} ${currency}`);
    printMethods.printOptions(mapCashierPayoutData, commands, winbetOptions);
    printMethods.conditionalPrint(mapCashierPayoutData, commands, isMagnumBet, ''.padEnd(44, '='));
    printMethods.printOptions(mapCashierPayoutData, commands, voucherValueOptions);
    printMethods.conditionalPrint(
      mapCashierPayoutData,
      commands,
      isMagnumBet,
      t('Cashier.CashierCheckTicket.checkTicketTotalSum'),
    );
    printMethods.conditionalPrint(
      mapCashierPayoutData,
      commands,
      isMagnumBet,
      `${totalAmountAfterTax} ${currency}` + ' ',
    );
    printMethods.print(mapCashierPayoutData, commands, '');
    printMethods.printOptions(mapCashierPayoutData, commands, _addressOptions);
    printMethods.conditionalPrint(mapCashierPayoutData, commands, isClaimForm, '');
    printMethods.conditionalPrint(
      mapCashierPayoutData,
      commands,
      isClaimForm,
      `${t(CashierTranslations.CashierModal.betNumber)}: ${dailySequenceNumber}`,
    );
    printMethods.conditionalPrint(
      mapCashierPayoutData,
      commands,
      isClaimForm,
      `${t(CashierTranslations.CashierModal.pinNumber)}: ${pinNumber}`,
    );
    printMethods.conditionalPrint(mapCashierPayoutData, commands, isClaimForm, '');
    printMethods.printOptions(mapCashierPayoutData, commands, winbetAddress);
    printMethods.print(mapCashierPayoutData, commands, '');
    printMethods.conditionalPrint(
      mapCashierPayoutData,
      commands,
      !isEmpty(retailAddress?.responsibleGambling),
      t(retailAddress?.responsibleGambling),
    );
    printMethods.conditionalPrint(
      mapCashierPayoutData,
      commands,
      !isEmpty(retailAddress?.responsibleGambling1),
      t(retailAddress?.responsibleGambling1),
    );
    printMethods.conditionalPrint(
      mapCashierPayoutData,
      commands,
      !isEmpty(retailAddress?.responsibleGambling2),
      t(retailAddress?.responsibleGambling2),
    );
    printMethods.conditionalPrint(
      mapCashierPayoutData,
      commands,
      !isEmpty(retailAddress?.responsibleGambling3),
      t(retailAddress?.responsibleGambling3),
    );
    printMethods.conditionalPrint(
      mapCashierPayoutData,
      commands,
      !isEmpty(
        retailAddress?.responsibleGambling3 ||
          retailAddress?.responsibleGambling2 ||
          retailAddress?.responsibleGambling1 ||
          retailAddress?.responsibleGambling,
      ),
      '',
    );

    printMethods.printEject(mapCashierPayoutData, commands);

    try {
      for (const i of mapCashierPayoutData) {
        await makeRequest(i);
        if (i.command === printEjectCommand) {
          await monitorPrintingCompletion();
        }
      }
    } catch (error) {
      getError.message(error);
    } finally {
      store.dispatch(setIsPrintingEjected(false));
      // Process the next job in the queue if any
      store.dispatch(processPrintQueue());
    }
  };

  store.dispatch(enqueuePrintRequest(printCashierPayoutJob));

  // Check if the current printing state and process the queue if no job is currently running
  const state = store.getState();
  if (!state.retail.printQueue.isPrinting) {
    store.dispatch(processPrintQueue());
  }
};
export const printCashierCancelTicketMobile = async ({
  stake,
  code,
  currency,
  retailAddress,
  isDuplicate,
  posName,
  posAddress,
  isCurrencyBeforeAmount,
}: PrintCashierCancelTicket) => {
  const printCashierCancelJob = async () => {
    const mapCancelTicketData: CommandData[] = [];
    printMethods.printIsReady(mapCancelTicketData, commands);
    printMethods.printStart(mapCancelTicketData, commands);
    printMethods.printOptions(mapCancelTicketData, commands, _logoOptionsData);
    printMethods.printImage(mapCancelTicketData, commands, retailAddress?.buLogo);
    printMethods.printOptions(mapCancelTicketData, commands, _optionsData);
    printMethods.print(mapCancelTicketData, commands, '');
    printMethods.printOptions(mapCancelTicketData, commands, winbetOptions);
    printMethods.print(mapCancelTicketData, commands, t(retailAddress?.responsibleGambling));
    printMethods.print(mapCancelTicketData, commands, t('Cashier.Receipt.addressPOSText'));
    printMethods.print(mapCancelTicketData, commands, `${posAddress?.city}`);
    printMethods.print(mapCancelTicketData, commands, `${posAddress?.streetName}`);
    printMethods.print(mapCancelTicketData, commands, ` ${posAddress?.streetNumber}`);
    printMethods.print(mapCancelTicketData, commands, '');
    printMethods.print(mapCancelTicketData, commands, t('Cashier.Receipt.signatureNumber'));
    printMethods.print(mapCancelTicketData, commands, posName);
    printMethods.printOptions(mapCancelTicketData, commands, _logoOptionsData);
    printMethods.conditionalPrint(
      mapCancelTicketData,
      commands,
      isDuplicate,
      `${t(CashierTranslations.Print.dublicateText)}`,
    );
    printMethods.conditionalPrint(mapCancelTicketData, commands, isDuplicate, '');
    printMethods.conditionalPrint(
      mapCancelTicketData,
      commands,
      window.config.ENV_TYPE !== 'PRODUCTION',
      t('Cashier.Receipt.testOperation'),
    );
    printMethods.print(mapCancelTicketData, commands, ''.padEnd(44, '='));
    printMethods.printOptions(mapCancelTicketData, commands, _addressOptions);
    printMethods.print(mapCancelTicketData, commands, t(CashierTranslations.ActionButton.cancelTicket));
    printMethods.printOptions(mapCancelTicketData, commands, voucherValueOptions);
    printMethods.print(mapCancelTicketData, commands, code);
    printMethods.printOptions(mapCancelTicketData, commands, winbetOptions);
    printMethods.print(mapCancelTicketData, commands, ''.padEnd(44, '='));
    printMethods.printOptions(mapCancelTicketData, commands, voucherValueOptions);
    printMethods.print(mapCancelTicketData, commands, t(CashierTranslations.CashierModal.issuedDate));
    printMethods.print(mapCancelTicketData, commands, retailFormatDate(Date.now()) + ' ');
    printMethods.printOptions(mapCancelTicketData, commands, winbetOptions);
    printMethods.print(mapCancelTicketData, commands, ''.padEnd(44, '='));
    printMethods.printOptions(mapCancelTicketData, commands, voucherValueOptions);
    printMethods.print(mapCancelTicketData, commands, t(CashierTranslations.CashierModal.totalAmount));
    printMethods.print(mapCancelTicketData, commands, formatCurrency(stake, currency, isCurrencyBeforeAmount) + ' ');
    printMethods.printOptions(mapCancelTicketData, commands, eventOptions);
    printMethods.print(mapCancelTicketData, commands, ''.padEnd(44, '='));
    printMethods.printOptions(mapCancelTicketData, commands, winbetAddress);
    printMethods.printOptions(mapCancelTicketData, commands, winbetAddress);
    printMethods.printQrCode(mapCancelTicketData, commands, code);
    printMethods.print(mapCancelTicketData, commands, '');
    printMethods.conditionalPrint(
      mapCancelTicketData,
      commands,
      !isEmpty(retailAddress?.responsibleGambling),
      t(retailAddress?.responsibleGambling),
    );
    printMethods.conditionalPrint(
      mapCancelTicketData,
      commands,
      !isEmpty(retailAddress?.responsibleGambling1),
      t(retailAddress?.responsibleGambling1),
    );
    printMethods.conditionalPrint(
      mapCancelTicketData,
      commands,
      !isEmpty(retailAddress?.responsibleGambling2),
      t(retailAddress?.responsibleGambling2),
    );
    printMethods.conditionalPrint(
      mapCancelTicketData,
      commands,
      !isEmpty(retailAddress?.responsibleGambling3),
      t(retailAddress?.responsibleGambling3),
    );
    printMethods.conditionalPrint(
      mapCancelTicketData,
      commands,
      !isEmpty(
        retailAddress?.responsibleGambling3 ||
          retailAddress?.responsibleGambling2 ||
          retailAddress?.responsibleGambling1 ||
          retailAddress?.responsibleGambling,
      ),
      '',
    );

    printMethods.printEject(mapCancelTicketData, commands);

    try {
      for (const i of mapCancelTicketData) {
        await makeRequest(i);
        if (i.command === printEjectCommand) {
          await monitorPrintingCompletion();
        }
      }
    } catch (error) {
      getError.message(error);
    } finally {
      store.dispatch(setIsPrintingEjected(false));
      // Process the next job in the queue if any
      store.dispatch(processPrintQueue());
    }
  };

  store.dispatch(enqueuePrintRequest(printCashierCancelJob));

  // Check the current printing state and process the queue if no job is currently running
  const state = store.getState();
  if (!state.retail.printQueue.isPrinting) {
    store.dispatch(processPrintQueue());
  }
};
export const printFinancialResultsMobile = async ({
  financeInfo,
  retailAddress,
  requestDate,
  isCurrencyBeforeAmount,
}: PrintFinancialResults) => {
  const printFinancialData: CommandData[] = [];
  printMethods.printIsReady(printFinancialData, commands);
  printMethods.printStart(printFinancialData, commands);
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, _logoOptionsData);
  printMethods.printImage(printFinancialData, commands, retailAddress?.buLogo);
  printMethods.printOptions(printFinancialData, commands, _optionsData);
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, winbetOptions);
  printMethods.print(printFinancialData, commands, t(retailAddress?.responsibleGambling));
  printMethods.print(printFinancialData, commands, t('Cashier.Receipt.addressPOSText'));
  printMethods.print(printFinancialData, commands, `${financeInfo?.posAddress?.city}`);
  printMethods.print(printFinancialData, commands, `${financeInfo?.posAddress?.streetName}`);
  printMethods.print(printFinancialData, commands, `${financeInfo?.posAddress?.streetNumber}`);
  printMethods.print(printFinancialData, commands, '');
  printMethods.print(printFinancialData, commands, t('Cashier.Receipt.signatureNumber'));
  printMethods.print(printFinancialData, commands, financeInfo?.posName);
  printMethods.conditionalPrint(
    printFinancialData,
    commands,
    window.config.ENV_TYPE !== 'PRODUCTION',
    t('Cashier.Receipt.testOperation'),
  );
  printMethods.printOptions(printFinancialData, commands, winbetOptions);
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, winbetOptions);
  printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, _addressOptions);
  printMethods.print(printFinancialData, commands, t(CashierTranslations.FinancialModal.posNameText));
  printMethods.print(printFinancialData, commands, financeInfo?.posName);
  printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, winbetOptions);
  printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
  printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
  printMethods.print(printFinancialData, commands, '');
  printMethods.print(printFinancialData, commands, t('Cashier.FinancialModal.selectedPeriod'));
  printMethods.print(printFinancialData, commands, formatFromAPI(financeInfo?.period) + ' '); //   // { command: commands.colDefine, bodyData: { width: 290, v_alignment: 'middle', h_alignment: 'right' } },
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, winbetOptions);
  printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
  printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
  printMethods.print(printFinancialData, commands, '');
  printMethods.print(printFinancialData, commands, t('Cashier.FinancialModal.requestDateReceipt'));
  printMethods.print(printFinancialData, commands, retailFormatDate(requestDate) + ' '); //   // { command: commands.colDefine, bodyData: { width: 290, v_alignment: 'middle', h_alignment: 'right' } },
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, winbetOptions);
  printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
  printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
  printMethods.print(printFinancialData, commands, '');
  printMethods.print(printFinancialData, commands, t('Cashier.FinancialModal.acceptedTicketsAmount'));
  printMethods.print(
    printFinancialData,
    commands,
    formatCurrency(financeInfo?.betAmount, financeInfo?.currency, isCurrencyBeforeAmount) + ' ',
  ); //   // { command: commands.colDefine, bodyData: { width: 290, v_alignment: 'middle', h_alignment: 'right' } },
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, winbetOptions);
  printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
  printMethods.print(printFinancialData, commands, t(CashierTranslations.FinancialModal.acceptedTicketsCount));
  printMethods.print(printFinancialData, commands, financeInfo?.numberOfTickets + ' '); //   // { command: commands.colDefine, bodyData: { width: 290, v_alignment: 'middle', h_alignment: 'right' } },
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, winbetOptions);
  printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
  printMethods.print(printFinancialData, commands, t(CashierTranslations.FinancialModal.paidTicketsCount));
  printMethods.print(printFinancialData, commands, financeInfo?.paidTickets + ' ');
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, winbetOptions);
  printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
  printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
  printMethods.print(printFinancialData, commands, '');
  printMethods.print(printFinancialData, commands, t(CashierTranslations.FinancialModal.paidAmountValue));
  printMethods.print(
    printFinancialData,
    commands,
    formatCurrency(financeInfo?.paidAmount, financeInfo?.currency, isCurrencyBeforeAmount) + ' ',
  );
  printMethods.printOptions(printFinancialData, commands, winbetOptions);
  printMethods.print(printFinancialData, commands, '');
  printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
  printMethods.print(printFinancialData, commands, t(CashierTranslations.FinancialModal.cancelledAmountValue));
  printMethods.print(
    printFinancialData,
    commands,
    formatCurrency(financeInfo?.cancelledAmount, financeInfo?.currency, isCurrencyBeforeAmount) + ' ',
  );
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, winbetOptions);
  printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
  printMethods.print(printFinancialData, commands, t(CashierTranslations.FinancialModal.cancelledTicketsCount));
  printMethods.print(printFinancialData, commands, financeInfo?.cancelledTickets + ' ');
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, winbetOptions);
  printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
  printMethods.print(printFinancialData, commands, t(CashierTranslations.FinancialModal.voidedBetsAmount));
  printMethods.print(
    printFinancialData,
    commands,
    formatCurrency(financeInfo?.voidedBetsAmount, financeInfo?.currency, isCurrencyBeforeAmount) + ' ',
  );
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, winbetOptions);
  printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
  printMethods.print(printFinancialData, commands, t(CashierTranslations.FinancialModal.numberOfVoidedBets));
  printMethods.print(printFinancialData, commands, financeInfo?.voidedTickets + ' ');
  printMethods.printOptions(printFinancialData, commands, winbetOptions);
  printMethods.print(printFinancialData, commands, '');
  printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
  printMethods.print(printFinancialData, commands, t(CashierTranslations.FinancialModal.depositedFromPosAmount));
  printMethods.print(
    printFinancialData,
    commands,
    formatCurrency(financeInfo?.depositedFromPosAmount, financeInfo?.currency, isCurrencyBeforeAmount) + ' ',
  );
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, winbetOptions);
  printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
  printMethods.print(printFinancialData, commands, t(CashierTranslations.FinancialModal.depositedToPosAmount));
  printMethods.print(
    printFinancialData,
    commands,
    formatCurrency(financeInfo?.depositedToPosAmount, financeInfo?.currency, isCurrencyBeforeAmount) + ' ',
  );
  printMethods.printOptions(printFinancialData, commands, winbetOptions);
  printMethods.print(printFinancialData, commands, '');
  printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, voucherValueOptions);
  printMethods.print(printFinancialData, commands, t('Cashier.FinancialModal.balanceText'));
  printMethods.print(
    printFinancialData,
    commands,
    formatCurrency(financeInfo?.balance, financeInfo?.currency, isCurrencyBeforeAmount) + ' ',
  );
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, winbetOptions);
  printMethods.print(printFinancialData, commands, ''.padEnd(44, '='));
  printMethods.print(printFinancialData, commands, '');
  printMethods.printOptions(printFinancialData, commands, winbetAddress);
  printMethods.print(printFinancialData, commands, '');
  printMethods.print(printFinancialData, commands, '');
  printMethods.printEject(printFinancialData, commands);

  try {
    store.dispatch(setIsPrintingEjected(true));
    for (const i of printFinancialData) {
      await makeRequest(i);
      if (i.command === printEjectCommand) {
        await monitorPrintingCompletion();
      }
    }
  } catch (error) {
    getError.message(error);
  } finally {
    setTimeout(() => {
      store.dispatch(setIsPrintingEjected(false));
    }, 2500);
  }
};
