import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import NoDataMessage from 'components/shared/NoDataMessage';
import { useGetCampaignData } from 'shared/common/features/PrizeDrop/hooks/useGetCampaignData';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch } from 'store';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import { PrizeDropUI } from '..';
import PrizeDropCampaignRightCol from '../components/PrizeDropCampaignRightCol';
import PrizeDropCampaignsCol from '../components/PrizeDropCampaignsCol';
import { prizeDropCSSNS } from '../prizeDropUICSS.types';

const PrizeDropDesktopView = () => {
  const { activeCampaign, personalRewards, rewards, rewardedPlayers, terms, campaignStatus, campaignsIds, status } =
    useGetCampaignData();
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => dispatch(hideRootModal()), [dispatch]);
  const { t } = useTranslation();

  return (
    <egtd-przd-modal>
      <egtd-przd-modal-grid-layout>
        <PrizeDropCampaignsCol hasHeader />
        {!activeCampaign ? (
          <div className={`${prizeDropCSSNS}-right-col`}>
            <PrizeDropUI.Header handleClose={handleClose} title={''} size="lg" />
            <div className={`${prizeDropCSSNS}-inner-shadow`}>
              <NoDataMessage message={t(Promotions.PrizeDrop.noActiveCampaigns)} />
            </div>
          </div>
        ) : (
          <PrizeDropCampaignRightCol
            activeCampaign={activeCampaign}
            personalRewards={personalRewards?.rewards}
            rewards={rewards?.rewards}
            rewardedPlayers={rewardedPlayers?.rewards}
            terms={terms}
            campaignStatus={campaignStatus}
            campaignsIds={campaignsIds}
            status={status}
            handleClose={handleClose}
          />
        )}
      </egtd-przd-modal-grid-layout>
    </egtd-przd-modal>
  );
};

export default PrizeDropDesktopView;
