import React from 'react';
import { Text } from 'components/shared';

/** BetSeletionUI Prenatch Date */
interface PrematchDateProps {
  isVisible: boolean;
  prematchEventDate: string | undefined;
}

const PrematchDate: React.FC<PrematchDateProps> = ({ isVisible, prematchEventDate }) => {
  if (!isVisible) return null;

  return <Text className="event-date" text={prematchEventDate} />;
};

export default PrematchDate;
