import React from 'react';
import { CardProps } from './History.types';

/** Card */
const Card: React.FC<CardProps> = ({ className, children, ...rest }) => {
  const cardClass = 'ma-text ma-text--sm';
  const classProp = className ? { class: `${cardClass} ${className}` } : { class: `${cardClass}` };

  return (
    <egtd-p-history-card {...classProp} {...rest}>
      {children}
    </egtd-p-history-card>
  );
};

export default Card;
