import { CSSProperties } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import { TimeDataType } from 'utils/dateUtils';

interface RemPeriodProps {
  className?: string;
  style?: CSSProperties;
  dateDifference: TimeDataType;
  location?: 'lobby' | 'page';
}

// dateDifference upates only when the page reloads
const RemPeriod: React.FC<RemPeriodProps> = ({ className, style, dateDifference, location = 'lobby' }) => {
  const { t } = useTranslation();
  const boxClass = clsx('text-nowrap fill--current', location === 'page' && 'prm-page__rem-period', className);
  const hasDays = typeof dateDifference.days === 'number' || typeof dateDifference.days === 'string';
  const hasHours = typeof dateDifference.hours === 'number' || typeof dateDifference.hours === 'string';
  const hasMinutes = typeof dateDifference.minutes === 'number' || typeof dateDifference.minutes === 'string';

  return (
    <Flex.AC className={boxClass} style={style}>
      {dateDifference.status && t(Promotions.PrizeDrop[dateDifference.status])}
      {(hasDays || hasHours || hasMinutes) && (
        <Icon defaultClassName={false} size={16} className="mr-1 flex-shrink-0" icon="time-machine" />
      )}
      {dateDifference.days}
      {hasDays && t(Promotions.PromotionCell.daysText)} {dateDifference.hours}
      {hasHours && t(Promotions.PromotionCell.hoursText)} {dateDifference.minutes}
      {hasMinutes && t(Promotions.PromotionCell.minutesText)}
    </Flex.AC>
  );
};

export default RemPeriod;
