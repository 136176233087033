import { SportDataTypes } from 'utils/common/types/sportTypes/sportData.types';

export const sportsConfigMock: SportDataTypes.EventStatusConfig = {
  pausingMatchStatuses: [
    'Drinks',
    'Break top 9 bottom 9',
    'Break top EI bottom 9',
    'Dinner Break',
    'Fifth break',
    'Awaiting sudden death',
    'Second break',
    'Third break',
    'Awaiting penalties',
    'Halftime',
    'Break top 9 bottom 8',
    'Super over break',
    'Break top 8 bottom 8',
    'Lunch break',
    'Tea break',
    'Interrupted',
    'Awaiting golden raid',
    'Break top 7 bottom 6',
    'Break',
    'Innings break',
    'First break',
    'Fourth break',
    'Sixth break',
    'Awaiting extra time',
    'Break top 7 bottom 7',
    'Break top 2 bottom 2',
    'Break top 3 bottom 2',
    'Break top 3 bottom 3',
    'Awaiting golden set',
    'Break top 4 bottom 3',
    'Break top 4 bottom 4',
    'Break top 5 bottom 4',
    'Break top 5 bottom 5',
    'Break top EI bottom EI',
    'Break top 1 bottom 1',
    'Break top EI bottom 3',
    'Break top EI bottom 7',
    'Break top 6 bottom 5',
    'Break top 6 bottom 6',
    'Break top 8 bottom 7',
    'Break top 2 bottom 1',
    'Extra time halftime',
    'Penalties',
  ],
  showMatchStatuses: [
    {
      matchStatus: '2nd period',
      name: '2nd Period',
      shortName: '2P',
    },
    {
      matchStatus: '3rd period',
      name: '3rd Period',
      shortName: '3P',
    },
    {
      matchStatus: 'Suspended',
      name: 'Interrupted',
      shortName: 'Interrupted',
    },
    {
      matchStatus: 'Stumps',
      name: 'Stumps',
      shortName: 'Stumps',
    },
    {
      matchStatus: 'Player 2 retired, player 1 won',
      name: 'Player 2 retired, player 1 won',
      shortName: 'Player 2 retired, player 1 won',
    },
    {
      matchStatus: 'Player 1 defaulted, player 2 won',
      name: 'Player 1 defaulted, player 2 won',
      shortName: 'Player 1 defaulted, player 2 won',
    },
    {
      matchStatus: 'Player 2 defaulted, player 1 won',
      name: 'Player 2 defaulted, player 1 won',
      shortName: 'Player 2 defaulted, player 1 won',
    },
    {
      matchStatus: 'Only Result',
      name: 'Only Result',
      shortName: 'Only Result',
    },
    {
      matchStatus: 'Sudden death',
      name: 'Sudden death',
      shortName: 'Sudden death',
    },
    {
      matchStatus: 'Drinks',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Break top 9 bottom 9',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Break top EI bottom 9',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Dinner Break',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Started',
      name: 'Started',
      shortName: 'Started',
    },
    {
      matchStatus: 'Fifth break',
      name: 'Fifth break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Awaiting sudden death',
      name: 'Awaiting Sudden death',
      shortName: 'Awaiting Sudden death',
    },
    {
      matchStatus: 'Start delayed',
      name: 'Start delayed',
      shortName: 'Start delayed',
    },
    {
      matchStatus: 'Golden raid',
      name: 'Golden Raid',
      shortName: 'Golden Raid',
    },
    {
      matchStatus: 'After golden raid',
      name: 'After Golden Raid',
      shortName: 'After Golden Raid',
    },
    {
      matchStatus: 'AOT',
      name: 'AOT',
      shortName: 'AOT',
    },
    {
      matchStatus: 'Second break',
      name: 'Second break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Third break',
      name: 'Third break',
      shortName: 'Break',
    },
    {
      matchStatus: '5th period',
      name: '5th Period',
      shortName: '5P',
    },
    {
      matchStatus: 'Awaiting penalties',
      name: 'Awaiting penalties',
      shortName: 'Awaiting penalties',
    },
    {
      matchStatus: '1st set',
      name: '1st Set',
      shortName: '1S',
    },
    {
      matchStatus: '2nd set',
      name: '2nd Set',
      shortName: '2S',
    },
    {
      matchStatus: 'Halftime',
      name: 'Halftime',
      shortName: 'Halftime',
    },
    {
      matchStatus: 'Break top 9 bottom 8',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Overtime',
      name: 'Overtime',
      shortName: 'Overtime',
    },
    {
      matchStatus: 'Not started',
      name: 'Not started',
      shortName: 'Not started',
    },
    {
      matchStatus: 'Super over break',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Break top 8 bottom 8',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Lunch break',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Tea break',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'In progress',
      name: 'Started',
      shortName: 'Started',
    },
    {
      matchStatus: 'Interrupted',
      name: 'Interrupted',
      shortName: 'Interrupted',
    },
    {
      matchStatus: 'Awaiting golden raid',
      name: 'Awaiting Golden raid',
      shortName: 'Awaiting Golden raid',
    },
    {
      matchStatus: '4th period',
      name: '4th Period',
      shortName: '4P',
    },
    {
      matchStatus: 'After sudden death',
      name: 'After Sudden Death',
      shortName: 'After Sudden Death',
    },
    {
      matchStatus: 'After super over',
      name: 'After Super Over',
      shortName: 'After SO',
    },
    {
      matchStatus: 'Super over',
      name: 'Super Over',
      shortName: 'Super Over',
    },
    {
      matchStatus: '3rd set',
      name: '3rd Set',
      shortName: '3S',
    },
    {
      matchStatus: 'Break top 7 bottom 6',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Break',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Walkover, player 2 won',
      name: 'Walkover, player 2 won',
      shortName: 'Walkover, player 2 won',
    },
    {
      matchStatus: 'Innings break',
      name: 'Innings break',
      shortName: 'Break',
    },
    {
      matchStatus: 'First break',
      name: 'First break',
      shortName: 'Break',
    },
    {
      matchStatus: '4th set',
      name: '4th Set',
      shortName: '4S',
    },
    {
      matchStatus: 'Awaiting super over',
      name: 'Awaiting Super Over',
      shortName: 'Awaiting SO',
    },
    {
      matchStatus: 'Fourth break',
      name: 'Fourth break',
      shortName: 'Break',
    },
    {
      matchStatus: '5th set',
      name: '5th Set',
      shortName: '5S',
    },
    {
      matchStatus: 'Sixth break',
      name: 'Sixth break',
      shortName: 'Break',
    },
    {
      matchStatus: '2nd quarter',
      name: '2nd Quarter',
      shortName: '2Q',
    },
    {
      matchStatus: 'Awaiting extra time',
      name: 'Awaiting Extra Time',
      shortName: 'Awaiting ET',
    },
    {
      matchStatus: 'Break top 7 bottom 7',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Break top 2 bottom 2',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Break top 3 bottom 2',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Break top 3 bottom 3',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Super over, away team',
      name: 'Super Over, Away Team',
      shortName: 'SO, Away Team',
    },
    {
      matchStatus: 'Super over, home team',
      name: 'Super Over, Home Team',
      shortName: 'SO, Home Team',
    },
    {
      matchStatus: 'Awaiting golden set',
      name: 'Awaiting Golden set',
      shortName: 'Awaiting Golden set',
    },
    {
      matchStatus: '4th quarter',
      name: '4th Quarter',
      shortName: '4Q',
    },
    {
      matchStatus: '1st extra',
      name: '1st Extra',
      shortName: '1E',
    },
    {
      matchStatus: '2nd extra',
      name: '2nd extra',
      shortName: '2E',
    },
    {
      matchStatus: 'Game 1',
      name: '1st Game',
      shortName: '1G',
    },
    {
      matchStatus: 'Game 2',
      name: '2nd Game',
      shortName: '2G',
    },
    {
      matchStatus: 'Game 3',
      name: '3th Game',
      shortName: '3G',
    },
    {
      matchStatus: 'Game 4',
      name: '4th Game',
      shortName: '4G',
    },
    {
      matchStatus: 'Game 5',
      name: '5th Game',
      shortName: '5G',
    },
    {
      matchStatus: 'Break top 4 bottom 3',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Break top 4 bottom 4',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Break top 5 bottom 4',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Break top 5 bottom 5',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Break top EI bottom EI',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Break top 1 bottom 1',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Break top EI bottom 3',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Break top EI bottom 7',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Break top 6 bottom 5',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Break top 6 bottom 6',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Break top 8 bottom 7',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Walkover',
      name: 'Walkover',
      shortName: 'Walkover',
    },
    {
      matchStatus: 'Ended',
      name: 'Ended',
      shortName: 'Ended',
    },
    {
      matchStatus: 'Abandoned',
      name: 'Interrupted',
      shortName: 'Interrupted',
    },
    {
      matchStatus: 'Break top 2 bottom 1',
      name: 'Break',
      shortName: 'Break',
    },
    {
      matchStatus: 'Cancelled',
      name: 'Cancelled',
      shortName: 'Cancelled',
    },
    {
      matchStatus: 'Postponed',
      name: 'Postponed',
      shortName: 'Postponed',
    },
    {
      matchStatus: 'Walkover, player 1 won',
      name: 'Walkover, player 1 won',
      shortName: 'Walkover, player 1 won',
    },
    {
      matchStatus: 'Coin toss',
      name: 'Coin toss',
      shortName: 'Coin toss',
    },
    {
      matchStatus: 'Retired',
      name: 'Walkover',
      shortName: 'Walkover',
    },
    {
      matchStatus: 'About to start',
      name: 'Not started',
      shortName: 'Not started',
    },
    {
      matchStatus: 'Player 1 retired, player 2 won',
      name: 'Player 1 retired, player 2 won',
      shortName: 'Player 1 retired, player 2 won',
    },
    {
      matchStatus: 'Golden set',
      name: 'Golden Set',
      shortName: 'Golden Set',
    },
    {
      matchStatus: '1st period',
      name: '1st Period',
      shortName: '1P',
    },
    {
      matchStatus: 'Extra time halftime',
      name: 'Extra Time Halftime',
      shortName: 'ET Halftime',
    },
    {
      matchStatus: 'Penalties',
      name: 'Penalties',
      shortName: 'Penalties',
    },
    {
      matchStatus: '1st half',
      name: '1st Half',
      shortName: '1H',
    },
    {
      matchStatus: '2nd half',
      name: '2nd Half',
      shortName: '2H',
    },
    {
      matchStatus: 'AET',
      name: 'AET',
      shortName: 'AET',
    },
    {
      matchStatus: '1st quarter',
      name: '1st Quarter',
      shortName: '1Q',
    },
    {
      matchStatus: '3rd quarter',
      name: '3rd Quarter',
      shortName: '3Q',
    },
    {
      matchStatus: 'Game 6',
      name: '6th Game',
      shortName: '6G',
    },
    {
      matchStatus: 'Game 7',
      name: '7th Game',
      shortName: '7G',
    },
    {
      matchStatus: '1st map',
      name: '1st Map',
      shortName: '1M',
    },
    {
      matchStatus: 'AP',
      name: 'AP',
      shortName: 'AP',
    },
    {
      matchStatus: '2nd map',
      name: '2nd Map',
      shortName: '2M',
    },
    {
      matchStatus: '3rd map',
      name: '3th Map',
      shortName: '3M',
    },
    {
      matchStatus: 'After golden set',
      name: 'After Golden Set',
      shortName: 'After Golden Set',
    },
    {
      matchStatus: '4th map',
      name: '4th Map',
      shortName: '4M',
    },
    {
      matchStatus: '5th map',
      name: '5th Map',
      shortName: '5M',
    },
    {
      matchStatus: '6th map',
      name: '6th Map',
      shortName: '6M',
    },
    {
      matchStatus: '7th map',
      name: '7th Map',
      shortName: '7M',
    },
    {
      matchStatus: '1st Game',
      name: '1st Game',
      shortName: '1G',
    },
    {
      matchStatus: '2nd Game',
      name: '2nd Game',
      shortName: '2G',
    },
    {
      matchStatus: '3rd Game',
      name: '3th Game',
      shortName: '3G',
    },
    {
      matchStatus: '4th Game',
      name: '4th Game',
      shortName: '4G',
    },
    {
      matchStatus: '5th Game',
      name: '5th Game',
      shortName: '5G',
    },
    {
      matchStatus: '1st end',
      name: '1st End',
      shortName: '1E',
    },
    {
      matchStatus: '2nd end',
      name: '2nd End',
      shortName: '2E',
    },
    {
      matchStatus: '3rd end',
      name: '3th End',
      shortName: '3E',
    },
    {
      matchStatus: '4th end',
      name: '4th End',
      shortName: '4E',
    },
    {
      matchStatus: '5th end',
      name: '5th End',
      shortName: '5E',
    },
    {
      matchStatus: '6th end',
      name: '6th End',
      shortName: '6E',
    },
    {
      matchStatus: '7th end',
      name: '7th End',
      shortName: '7E',
    },
    {
      matchStatus: '8th end',
      name: '8th End',
      shortName: '8E',
    },
    {
      matchStatus: '9th end',
      name: '9th End',
      shortName: '9E',
    },
    {
      matchStatus: '10th end',
      name: '10th End',
      shortName: '10E',
    },
    {
      matchStatus: 'Extra end',
      name: 'Extra End',
      shortName: 'EE',
    },
    {
      matchStatus: '1st inning top',
      name: '1st Inning',
      shortName: '1I',
    },
    {
      matchStatus: '1st inning bottom',
      name: '1st Inning',
      shortName: '1I',
    },
    {
      matchStatus: '2nd inning top',
      name: '2nd Inning',
      shortName: '2I',
    },
    {
      matchStatus: '2nd inning bottom',
      name: '2nd Inning',
      shortName: '2I',
    },
    {
      matchStatus: '3rd inning top',
      name: '3th Inning',
      shortName: '3I',
    },
    {
      matchStatus: '3rd inning bottom',
      name: '3th Inning',
      shortName: '3I',
    },
    {
      matchStatus: '4th inning top',
      name: '4th Inning',
      shortName: '4I',
    },
    {
      matchStatus: '4th inning bottom',
      name: '4th Inning',
      shortName: '4I',
    },
    {
      matchStatus: '5th inning top',
      name: '5th Inning',
      shortName: '5I',
    },
    {
      matchStatus: '5th inning bottom',
      name: '5th Inning',
      shortName: '5I',
    },
    {
      matchStatus: '6th inning top',
      name: '6th Inning',
      shortName: '6I',
    },
    {
      matchStatus: '6th inning bottom',
      name: '6th Inning',
      shortName: '6I',
    },
    {
      matchStatus: '7th inning top',
      name: '7th Inning',
      shortName: '7I',
    },
    {
      matchStatus: '7th inning bottom',
      name: '7th Inning',
      shortName: '7I',
    },
    {
      matchStatus: '8th inning top',
      name: '8th Inning',
      shortName: '8I',
    },
    {
      matchStatus: '8th inning bottom',
      name: '8th Inning',
      shortName: '8I',
    },
    {
      matchStatus: '9th inning top',
      name: '9th Inning',
      shortName: '9I',
    },
    {
      matchStatus: '9th inning bottom',
      name: '9th Inning',
      shortName: '9I',
    },
    {
      matchStatus: 'Extra inning top',
      name: 'Extra Inning Top',
      shortName: 'EI',
    },
    {
      matchStatus: 'Extra inning bottom',
      name: 'Extra Inning Bottom',
      shortName: 'EI',
    },
    {
      matchStatus: '6th set',
      name: '6th Set',
      shortName: '6S',
    },
    {
      matchStatus: '7th set',
      name: '7th Set',
      shortName: '7S',
    },
    {
      matchStatus: 'First innings, home team',
      name: 'First Innings, Home Team',
      shortName: '1I',
    },
    {
      matchStatus: 'First innings, away team',
      name: 'First Innings, Away Team',
      shortName: '1I',
    },
    {
      matchStatus: 'Second innings, home team',
      name: 'Second Innings, Home Team',
      shortName: '2I',
    },
    {
      matchStatus: 'Second innings, away team',
      name: 'Second Innings, Away Team',
      shortName: '2I',
    },
    {
      matchStatus: '8th set',
      name: '8th Set',
      shortName: '8S',
    },
    {
      matchStatus: '9th set',
      name: '9th Set',
      shortName: '9S',
    },
    {
      matchStatus: '10th set',
      name: '10th Set',
      shortName: '10S',
    },
    {
      matchStatus: '11th set',
      name: '11th Set',
      shortName: '11S',
    },
    {
      matchStatus: '12th set',
      name: '12th Set',
      shortName: '12S',
    },
    {
      matchStatus: '13th set',
      name: '13th Set',
      shortName: '13S',
    },
    {
      matchStatus: 'Third innings, away team',
      name: 'Third Innings, Away Team',
      shortName: '3I',
    },
    {
      matchStatus: 'Third innings, home team',
      name: 'Third Innings, Home Team',
      shortName: '3I',
    },
    {
      matchStatus: 'Fourth innings, home team',
      name: 'Fourth Innings, Home Team',
      shortName: '4I',
    },
    {
      matchStatus: 'Fourth innings, away team',
      name: 'Fourth Innings, Away Team',
      shortName: '4I',
    },
    {
      matchStatus: '1st inning',
      name: '1st Inning',
      shortName: '1I',
    },
    {
      matchStatus: '2nd inning',
      name: '2nd Inning',
      shortName: '2I',
    },
    {
      matchStatus: '3rd inning',
      name: '3th Inning',
      shortName: '3I',
    },
    {
      matchStatus: '4th inning',
      name: '4th Inning',
      shortName: '4I',
    },
    {
      matchStatus: '5th inning',
      name: '5th Inning',
      shortName: '5I',
    },
    {
      matchStatus: '6th inning',
      name: '6th Inning',
      shortName: '6I',
    },
    {
      matchStatus: '7th inning',
      name: '7th Inning',
      shortName: '7I',
    },
    {
      matchStatus: '8th inning',
      name: '8th Inning',
      shortName: '8I',
    },
    {
      matchStatus: '9th inning',
      name: '9th Inning',
      shortName: '9I',
    },
    {
      matchStatus: 'First round',
      name: '1st Round',
      shortName: '1R',
    },
    {
      matchStatus: 'Second round',
      name: '2nd Round',
      shortName: '2R',
    },
    {
      matchStatus: 'Third round',
      name: '3th Round',
      shortName: '3R',
    },
    {
      matchStatus: 'Fourth round',
      name: '4th Round',
      shortName: '4R',
    },
    {
      matchStatus: 'Fifth round',
      name: '5th Round',
      shortName: '5R',
    },
    {
      matchStatus: 'Sixth round',
      name: '6th Round',
      shortName: '6R',
    },
    {
      matchStatus: 'Seventh round',
      name: '7th Round',
      shortName: '7R',
    },
    {
      matchStatus: 'Eighth round',
      name: '8th Round',
      shortName: '8R',
    },
    {
      matchStatus: 'Ninth round',
      name: '9th Round',
      shortName: '9R',
    },
    {
      matchStatus: 'Tenth round',
      name: '10th Round',
      shortName: '10R',
    },
    {
      matchStatus: 'Eleventh round',
      name: '11th Round',
      shortName: '11R',
    },
    {
      matchStatus: 'Twelfth round',
      name: '12th Round',
      shortName: '12R',
    },
  ],
  sportsWithClock: [1001, 1002, 1004, 1006, 1010, 1012, 1013, 1016, 1024, 1029, 1137, 1138, 1153, 1155, 1195, 1999],
  sportsWithStatistics: [1001, 1002, 1003, 1004, 1005, 1006, 1012, 1016, 1023, 1024, 1029, 1138, 1999],
  sportsConfig: [
    {
      id: 1001,
      favoriteMarketTemplates: [
        100165, 100166, 100167, 100091, 100092, 100138, 100139, 100140, 100141, 100151, 100176, 100177, 100152, 100153,
        100154, 100037, 100116, 100120, 100127, 100128, 100129, 100168, 100544, 100574, 100575, 100576, 100580, 100583,
        100584, 100587, 100797, 100798, 100799, 100801, 100802, 100883, 100885, 100887, 100856, 100857, 100858, 100884,
        100886, 100014, 100016, 100018, 100019, 100068, 100020, 100066, 100065, 100069, 100070, 100087, 100088, 100090,
        100058, 100059, 100854, 100855, 100859, 100104, 100107, 100110, 100117, 100567, 100568, 100569, 100570, 100577,
        100581, 100582, 100592,
      ],
      config: {
        clock: {
          defaultLength: 2700,
          periodsLength: null,
        },
        scoreType: 1,
        showNeutralGround: true,
        scoreTypeName: 'Single',
      },
    },
    {
      id: 1002,
      favoriteMarketTemplates: [
        100014, 100019, 100020, 100231, 100232, 100297, 100303, 100757, 100921, 100922, 100924, 100962, 100963, 100965,
        100966, 100967, 100016, 100018, 100069, 100070, 100088, 100090, 100223, 100225, 100227, 100228, 100292, 100756,
        100923, 100066, 100068,
      ],
      config: {
        clock: {
          defaultLength: 600,
          periodsLength: {
            '19000000132': 720,
            '19000000648': 1200,
            '19000001566': 720,
            '19000001956': 720,
            '19000024135': 1200,
            '19000028370': 1200,
            '19000028372': 1200,
            '19000033946': 1200,
          },
        },
        scoreType: 1,
        statistics: true,
        showNeutralGround: true,
        scoreTypeName: 'Single',
      },
    },
    {
      id: 1003,
      favoriteMarketTemplates: [
        100254, 100261, 100265, 100275, 100277, 100278, 100280, 100282, 100284, 100288, 100740, 100744, 100746, 100748,
        100926, 101041, 101043, 100256, 100258, 100260, 100262, 100276, 100281, 100283, 100741, 100747, 100787, 100925,
        101040, 101042, 101076, 101121, 101120,
      ],
      config: {
        clock: null,
        scoreType: 5,
        statistics: true,
        showNeutralGround: true,
        scoreTypeName: 'BaseballSpecific',
      },
    },
    {
      id: 1004,
      favoriteMarketTemplates: [
        100410, 100412, 100408, 100447, 100019, 100020, 100414, 100442, 100016, 100018, 100014, 100445, 100448, 100460,
        100415, 100037, 100116, 100441,
      ],
      config: {
        clock: {
          defaultLength: 1200,
          periodsLength: null,
        },
        scoreType: 1,
        statistics: true,
        showNeutralGround: true,
        scoreTypeName: 'Single',
      },
    },
    {
      id: 1005,
      favoriteMarketTemplates: [101055, 100189, 100187, 100203, 100204, 100188, 100895],
      config: {
        clock: null,
        scoreType: 3,
        statistics: true,
        showNeutralGround: false,
        scoreTypeName: 'Triple',
      },
    },
    {
      id: 1006,
      favoriteMarketTemplates: [
        100014, 100020, 100068, 100065, 100070, 100116, 100016, 100018, 100019, 100037, 100066, 100069, 100117,
      ],
      config: {
        clock: {
          defaultLength: 1800,
          periodsLength: null,
          remainingTime: true,
        },
        scoreType: 1,
        statistics: true,
        showNeutralGround: true,
        scoreTypeName: 'Single',
      },
    },
    {
      id: 1007,
      favoriteMarketTemplates: [100018],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1009,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1010,
      favoriteMarketTemplates: [100018],
      config: {
        clock: {
          defaultLength: 180,
          periodsLength: null,
        },
        scoreType: null,
        showNeutralGround: false,
        scoreTypeName: null,
      },
    },
    {
      id: 1011,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1012,
      favoriteMarketTemplates: [
        100014, 100016, 100019, 100066, 100069, 100476, 100478, 100480, 100485, 100487, 100018, 100020, 100037, 100065,
        100068, 100070, 100477, 100479, 100486, 100488, 100489,
      ],
      config: {
        clock: {
          defaultLength: 2400,
          periodsLength: null,
        },
        scoreType: 1,
        statistics: true,
        showNeutralGround: true,
        scoreTypeName: 'Single',
      },
    },
    {
      id: 1013,
      favoriteMarketTemplates: [
        100016, 100020, 100037, 100068, 100070, 100303, 100757, 100018, 100019, 100066, 100069, 100756,
      ],
      config: {
        clock: {
          defaultLength: 1200,
          periodsLength: null,
        },
        scoreType: 1,
        showNeutralGround: true,
        scoreTypeName: 'Single',
      },
    },
    {
      id: 1015,
      favoriteMarketTemplates: [100018],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1016,
      favoriteMarketTemplates: [
        100090, 100932, 100934, 100066, 100068, 100070, 100088, 100069, 100223, 100225, 100227, 100228, 100231, 100232,
        100303, 100914, 100916, 100918, 100915, 100917, 100919, 100920, 100931, 100933,
      ],
      config: {
        clock: {
          defaultLength: 900,
          periodsLength: null,
        },
        scoreType: 1,
        statistics: true,
        showNeutralGround: true,
        scoreTypeName: 'Single',
      },
    },
    {
      id: 1017,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1018,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1019,
      favoriteMarketTemplates: [100494, 100501, 100493, 100500],
      config: {
        clock: null,
        scoreType: 2,
        showNeutralGround: false,
        scoreTypeName: 'Double',
      },
    },
    {
      id: 1020,
      favoriteMarketTemplates: [100237, 100238, 100247, 100246],
      config: {
        clock: null,
        scoreType: 2,
        showNeutralGround: false,
        scoreTypeName: 'Double',
      },
    },
    {
      id: 1021,
      favoriteMarketTemplates: [100353, 100356, 100352, 100357, 100605, 100606, 100607],
      config: {
        clock: null,
        scoreType: 4,
        showNeutralGround: true,
        scoreTypeName: 'CricketSpecific',
      },
    },
    {
      id: 1022,
      favoriteMarketTemplates: [100385, 100371, 100372, 100376, 100188, 100386, 100383],
      config: {
        clock: null,
        scoreType: 2,
        showNeutralGround: false,
        scoreTypeName: 'Double',
      },
    },
    {
      id: 1023,
      favoriteMarketTemplates: [100238, 100309, 100237, 100310],
      config: {
        clock: null,
        scoreType: 2,
        statistics: true,
        showNeutralGround: true,
        scoreTypeName: 'Double',
      },
    },
    {
      id: 1024,
      favoriteMarketTemplates: [
        100037, 100066, 100069, 100090, 100756, 101036, 100070, 100014, 100016, 100018, 100019, 100020, 100065, 100068,
        100087, 100303, 100757,
      ],
      config: {
        clock: {
          defaultLength: 900,
          periodsLength: null,
        },
        scoreType: 1,
        statistics: true,
        showNeutralGround: true,
        scoreTypeName: 'Single',
      },
    },
    {
      id: 1026,
      favoriteMarketTemplates: [100018],
      config: {
        showNeutralGround: true,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1028,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1029,
      favoriteMarketTemplates: [100016, 100018, 100068, 100116, 100014, 100066],
      config: {
        clock: {
          defaultLength: 1200,
          periodsLength: null,
        },
        scoreType: 1,
        statistics: true,
        showNeutralGround: true,
        scoreTypeName: 'Single',
      },
    },
    {
      id: 1030,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1031,
      favoriteMarketTemplates: [100247, 100237, 100246, 100238],
      config: {
        clock: null,
        scoreType: 2,
        showNeutralGround: false,
        scoreTypeName: 'Double',
      },
    },
    {
      id: 1032,
      favoriteMarketTemplates: [100188, 100318, 100319, 100317, 100528, 100527],
      config: {
        clock: null,
        scoreType: 2,
        showNeutralGround: false,
        scoreTypeName: 'Double',
      },
    },
    {
      id: 1033,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1034,
      favoriteMarketTemplates: [100238, 100310, 100237, 100309],
      config: {
        clock: null,
        scoreType: 2,
        showNeutralGround: false,
        scoreTypeName: 'Double',
      },
    },
    {
      id: 1036,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1037,
      favoriteMarketTemplates: [100237, 100238, 100246, 100247],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1040,
      favoriteMarketTemplates: [101098, 101099, 101100, 101106, 101108],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1043,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1044,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1046,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1047,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1048,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1055,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1058,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1060,
      favoriteMarketTemplates: [100018],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1081,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1101,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1109,
      favoriteMarketTemplates: [100327, 100331, 100332, 100626, 100326, 100328],
      config: {
        clock: null,
        scoreType: 6,
        showNeutralGround: false,
        scoreTypeName: 'Esport',
      },
    },
    {
      id: 1110,
      favoriteMarketTemplates: [100327, 100326, 100328],
      config: {
        clock: null,
        scoreType: 6,
        showNeutralGround: false,
        scoreTypeName: 'Esport',
      },
    },
    {
      id: 1111,
      favoriteMarketTemplates: [100327, 100326, 100328],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1112,
      favoriteMarketTemplates: [100327, 100328],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1117,
      favoriteMarketTemplates: [100018],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1118,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1121,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1125,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1128,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1129,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1131,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1134,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1135,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: true,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1136,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: true,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1137,
      favoriteMarketTemplates: [100014, 100016, 100018, 100019, 100020, 100058, 100068, 100070],
      config: {
        clock: {
          defaultLength: 360,
          periodsLength: null,
        },
        scoreType: 1,
        showNeutralGround: false,
        scoreTypeName: 'Single',
      },
    },
    {
      id: 1138,
      favoriteMarketTemplates: [],
      config: {
        clock: {
          defaultLength: 1200,
          periodsLength: null,
        },
        scoreType: 1,
        statistics: true,
        scoreTypeName: 'Single',
      },
    },
    {
      id: 1142,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1153,
      favoriteMarketTemplates: [
        100016, 100018, 100019, 100020, 100223, 100225, 100227, 100228, 100231, 100232, 100068, 100066,
      ],
      config: {
        clock: {
          defaultLength: 300,
          periodsLength: null,
        },
        scoreType: 6,
        showNeutralGround: false,
        scoreTypeName: 'Esport',
      },
    },
    {
      id: 1155,
      favoriteMarketTemplates: [100016, 100018, 100019, 100020, 100223, 100225, 100227],
      config: {
        clock: {
          defaultLength: 600,
          periodsLength: null,
          remainingTimeInPeriod: false,
        },
        scoreType: 1,
        showNeutralGround: false,
        scoreTypeName: 'Single',
      },
    },
    {
      id: 1158,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1188,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1190,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1191,
      favoriteMarketTemplates: [],
      config: {
        showNeutralGround: false,
        scoreTypeName: null,
        clock: null,
        scoreType: null,
      },
    },
    {
      id: 1195,
      favoriteMarketTemplates: [100014, 100016, 100018, 100019, 100020, 100037, 100442, 100448, 100460],
      config: {
        clock: {
          defaultLength: 300,
          periodsLength: null,
        },
        scoreType: 1,
        showNeutralGround: false,
        scoreTypeName: 'Single',
      },
    },
    {
      id: 1999,
      favoriteMarketTemplates: [],
      config: {
        clock: {
          defaultLength: 2700,
          periodsLength: null,
        },
        scoreType: 1,
        statistics: true,
        showNeutralGround: true,
        scoreTypeName: 'Single',
      },
    },
  ],
};
