import { MutableRefObject, useEffect } from 'react';
import {
  createLiveImageSource,
  createStaticImageSource,
  getDefaultImage,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellMedia/gameCellMediaUtils';
import { CasinoWidgetsTypes } from '../../../types/casinoWidgetsTypes';
import { LAST_WINNER_DEFAULT_GAME_LAYOUT, LAST_WINNER_DEFAULT_IMAGE } from '../LastWinnersWidgetsConstants';

export const useImage = ({
  imgRef,
  imageContainerRef,
  lastWinner,
  liveImageLocation,
  cdnPathLayout,
  cdnPath,
  gameLayout,
}: {
  imgRef: MutableRefObject<HTMLImageElement | null>;
  imageContainerRef: React.RefObject<HTMLDivElement>;
  lastWinner: CasinoWidgetsTypes.LastWinner;
  liveImageLocation: string;
  cdnPathLayout: string;
  cdnPath: string;
  gameLayout?: string;
}): { handleError: () => void } => {
  const getImageSources = () => {
    const { clientWidth, clientHeight } = imageContainerRef?.current || {};
    if (clientHeight) {
      return liveImageLocation
        ? createLiveImageSource({ liveImageLocation, defaultUrl: LAST_WINNER_DEFAULT_IMAGE })
        : createStaticImageSource({
            width: clientWidth,
            height: clientHeight,
            cdnPathLayout,
            cdnPath,
            imageQuality: 2,
            gameLayout: gameLayout ? gameLayout : LAST_WINNER_DEFAULT_GAME_LAYOUT,
            refGameId: lastWinner?.refGameId,
            providerId: lastWinner?.providerId,
            liveDefaultImg: lastWinner?.gameResource ? LAST_WINNER_DEFAULT_IMAGE : '',
          });
    }
    return [
      getDefaultImage({
        gameLayout: LAST_WINNER_DEFAULT_GAME_LAYOUT,
        liveDefaultImg: '',
      }),
    ];
  };

  useEffect(() => {
    const srcList = getImageSources();

    if (imgRef.current && srcList[0]) {
      imgRef.current.src = `${srcList[0]}${liveImageLocation ? `?z=${new Date().getTime()}` : ''}`;
    }
  }, [lastWinner?.refGameId]);

  const handleError = () => {
    const srcList = getImageSources();

    if (imgRef.current && srcList[1]) {
      imgRef.current.src = srcList[1];
    }
  };

  return { handleError };
};
