import React from 'react';
import { useTranslation } from 'react-i18next';
import { PromotionTypes } from 'pages/promotions/types/promotions.types';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import TournamentsTableRow from './TournamentsTableRow';

type Props = {
  awards: PromotionTypes.AwardPlaces[];
};

const TournamentsLeaderBoardAwards: React.FC<Props> = ({ awards }) => {
  const { t } = useTranslation();

  return (
    <>
      <TournamentsTableRow
        firstColText={t(Promotions.Tournaments.placeLabel)}
        secondColText={t(Promotions.Tournaments.awardLabel)}
        noSeparator={true}
        header
      />
      {awards.map((award, index) => {
        return (
          <TournamentsTableRow
            key={award.place}
            firstColText={award.place}
            secondColText={award.award}
            isBold={index < 3}
          />
        );
      })}
    </>
  );
};

export default TournamentsLeaderBoardAwards;
