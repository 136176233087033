import React, { memo } from 'react';
import isEqual from 'lodash/isEqual';
import { CasinoWidgetsTypes } from 'modules/casino/modules/container/widget/types/casinoWidgetsTypes';
import { widgetBreadCrumbActionByLayout } from './WidgetBreadCrumbActionMap';
import { CategoryLayoutTypes } from '../../../types/CategoryLayoutTypes';

interface CategoryBreadcrumbLayoutProps {
  widget: CasinoWidgetsTypes.Widget;
  categoryLayoutType: CategoryLayoutTypes;
}

const WidgetBreadCrumbActionComponent: React.FC<CategoryBreadcrumbLayoutProps> = ({ widget, categoryLayoutType }) => {
  const renderBreadCrumbActions = () => {
    const WidgetActionComponentMap = widgetBreadCrumbActionByLayout[categoryLayoutType];
    const WidgetActionComponent = WidgetActionComponentMap && WidgetActionComponentMap[widget.type];

    if (WidgetActionComponent) {
      return <WidgetActionComponent widget={widget} />;
    }

    return <></>;
  };

  return renderBreadCrumbActions();
};

export default memo(WidgetBreadCrumbActionComponent, isEqual);
