import { combineReducers } from '@reduxjs/toolkit';
import formSocialReducer from 'components/shared/forms/components/FormSocial/slice/formSocial.slice';
import mpdAccountTabsReducer from './slice/mpdAccountTabs.slice';
import accountReducer from './tabs/account/slice/account.slice';
import bankHistoryReducer from './tabs/cashier/features/bankHistory/bankHistorySlice';
import casinoBetHistoryReducer from './tabs/history/sections/casinoHistory/slice/casinoBetHistory.slice';
import fantasySportsHistoryReducer from './tabs/history/sections/fantasySportsHistory/slice/fantasySportsHistory.slice';
import lotteryBetHistoryReducer from './tabs/history/sections/lotteryBetHistory/slice/lotteryBetHistory.slice';
import monthlyHistoryReducer from './tabs/history/sections/monthlyHistory/slice/monthlyHistory.slice';
import betHistoryReducer from './tabs/history/sections/sportsHistory/slice/betHistory.slice';
import tableGamesHistoryReducer from './tabs/history/sections/tableGamesHistory/slice/tableGamesHistory.slice';
import transactionHistoryReducer from './tabs/history/sections/transactionHistory/slice/transactionHistory.slice';
import historyReducer from './tabs/history/slice/history.slice';
import limitsReducer from './tabs/limits/slice/limits.slice';
import seConfirmationReducer from './tabs/responsibleGambling/selfExclusionConfirm/slice/selfExclusionConfirmation.slice';
import responsibleGambling from './tabs/responsibleGambling/slice/responsibleGambling.slice';
import settingsReducer from './tabs/settings/slice/settings.slice';
import playerWinInfoReducer from '../../components/shared/playerWinInfo/slice/playerWinInfo.slice';

export const myAccountReducer = combineReducers({
  account: accountReducer,
  betHistory: betHistoryReducer,
  bankHistory: bankHistoryReducer,
  casinoBetHistory: casinoBetHistoryReducer,
  lotteryBetHistory: lotteryBetHistoryReducer,
  history: historyReducer,
  monthlyHistory: monthlyHistoryReducer,
  transactionHistory: transactionHistoryReducer,
  tableGamesHistory: tableGamesHistoryReducer,
  fantasySportsHistory: fantasySportsHistoryReducer,
  limits: limitsReducer,
  responsibleGambling: responsibleGambling,
  settings: settingsReducer,
  selfExclusionConfirm: seConfirmationReducer,
  playerWinInfo: playerWinInfoReducer,
  mpdAccount: mpdAccountTabsReducer,
  socialMedia: formSocialReducer,
});
