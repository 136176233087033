import React from 'react';
import clsx from 'clsx';
import MenuLink from './MenuLink';

/** Footer Menu Item */
interface MenuItemProps {
  /** Optional CSS class */
  className?: string;
  /** Optional link CSS class */
  linkClassName?: string;
  /** Is the link generated outside the component? */
  isForeignLink?: boolean;
  /** Link route */
  linkRoute?: string;
  /** Link text */
  text: string | JSX.Element;
  inView?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({
  isForeignLink,
  linkRoute,
  className,
  linkClassName,
  text,
  inView = true,
}) => {
  const itemClass = clsx('text-tabular footer-menu__item', className);
  return (
    <li className={itemClass}>
      {inView ? (
        <>
          {isForeignLink ? (
            text
          ) : (
            <MenuLink className={linkClassName} linkRoute={linkRoute ? linkRoute : '/'} text={text} />
          )}
        </>
      ) : null}
    </li>
  );
};

export default MenuItem;
