import React from 'react';
import { useTranslation } from 'react-i18next';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch, useAppSelector } from 'store';

import { SSBT } from 'utils/common/translationUtils/translationStrings';
import {
  selectSSBTdata,
  setModalVisible,
  // stopCoinAcceptorCommand,
  stopRgbControllerCommand,
} from '../../slice/ssbt.slice';
import { TerminalModalUI } from '../ssbtModalUI';

type TerminalModalType = {
  isClosable?: boolean;
  message?: string;
  isCoinError?: boolean;
  terminalAmount?: number;
  billTotal?: number;
};

const TerminalModalStatus: React.FC<TerminalModalType> = ({ isClosable, isCoinError, message }) => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { t } = useTranslation();
  // const isCoinAcceptorStopped = useAppSelector(selectSSBTdata.isCoinAcceptorStopped);
  const isRgbControllerStopped = useAppSelector(selectSSBTdata.isRgbControllerStopped);

  const hideModal = () => {
    dispatch(hideRootModal());
    dispatch(setModalVisible(false));
  };

  // const hideCoinModal = () => {
  //   hideModal();
  //   dispatch(stopCoinAcceptorCommand(true));
  // };

  const hideRgbModal = () => {
    hideModal();
    dispatch(stopRgbControllerCommand(true));
  };
  const renderTerminalModal = () => {
    return <TerminalModalUI.Status status="error" text={message} />;
  };
  // const renderCoinModal = () => (
  //   <TerminalModalUI.Wrapper isVisible={isCoinError}>
  //     <TerminalModalUI.Close text={t(SSBT.TerminalModal.closeTerminalModalText)} onClick={hideCoinModal} />
  //     <TerminalModalUI.Body>{renderTerminalModal()}</TerminalModalUI.Body>
  //   </TerminalModalUI.Wrapper>
  // );

  const renderRgbModal = () => (
    <TerminalModalUI.Wrapper isVisible={!isCoinError || !isRgbControllerStopped}>
      <TerminalModalUI.Close text={t(SSBT.TerminalModal.closeTerminalModalText)} onClick={hideRgbModal} />
      <TerminalModalUI.Body>{renderTerminalModal()}</TerminalModalUI.Body>
    </TerminalModalUI.Wrapper>
  );

  const renderErrorModal = () => (
    <TerminalModalUI.Wrapper isVisible={true}>
      <TerminalModalUI.Body>{renderTerminalModal()}</TerminalModalUI.Body>
    </TerminalModalUI.Wrapper>
  );

  return (
    <>
      {/* {isClosable && isCoinError && !isCoinAcceptorStopped && !!message && renderCoinModal()} */}
      {isClosable && !isCoinError && !isRgbControllerStopped && !!message && renderRgbModal()}
      {!isClosable && !!message && renderErrorModal()}
    </>
  );
};

export default TerminalModalStatus;
