import { isDesktop } from 'react-device-detect';
import { UI } from 'modules/casino/shared/components/UI';

export const GameInfoModalPlayBtns = ({ showFreePlay, freePlayText, freePlayHandler, playText, playHandler }) => {
  return (
    <>
      {showFreePlay && (
        <UI.Button
          c-variant="secondary"
          c-size={isDesktop ? '2xl' : 'lg'}
          onClickHandler={freePlayHandler}
          text={freePlayText}
        />
      )}
      <UI.Button c-variant="primary" c-size={isDesktop ? '2xl' : 'lg'} onClickHandler={playHandler} text={playText} />
    </>
  );
};
