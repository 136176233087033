import styled from 'styled-components';
import { Breakpoints } from 'modules/casino/theme/Theme';

const DescriptionStyled = styled.div<{ padding?: string }>`
  padding: ${({ padding }) => padding};
  line-height: 1.1;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    line-height: 21px;
  }
`;
export default DescriptionStyled;
DescriptionStyled.displayName = 'DescriptionStyled';
