export type InputNameType = 'cnpNums' | 'firstName' | 'lastName' | '';

export enum InputNames {
  cnpNums = 'cnpNums',
  firstName = 'firstName',
  lastName = 'lastName',
}

export const keyboardDisplay = {
  // '{bksp}': '⌫', // Optional: Display a backspace icon instead of text
  '{bksp}': 'backspace',
  '{shift}': 'shift',
  '{enter}': '< enter',
  '{space}': ' ',
};

export const keyBoardLayouts = {
  default: [
    '1 2 3 4 5 6 7 8 9 0 {bksp}',
    'q w e r t y u i o p {shift}',
    'a s d f g h j k l {enter}',
    'z x c v b n m {space}',
    // '.com @ {space}',
  ],
  shift: [
    '1 2 3 4 5 6 7 8 9 0 {bksp}',
    'Q W E R T Y U I O P {shift}',
    'A S D F G H J K L {enter}',
    'Z X C V B N M {space}',
    // '.com @ {space}',
  ],
};

export enum KeyboardLayoutTypes {
  default = 'default',
  shift = 'shift',
}
