import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import SocialIcon from 'components/shared/forms/components/FormSocial/SocialIcon';
import { Social, SocialModal } from 'components/shared/forms/components/FormSocial/types/formSocial.types';
import { Flex } from 'components/shared/layout';
import { Modal } from 'components/shared/Modal';
import { UI } from 'components/shared/UI';
import CDNSVG from 'modules/casino/shared/components/UI/CDNSVG/CDNSVG';
import { useAppDispatch } from 'store';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import { CSS } from 'utils/common/types/css.types';
import { hideRootModal } from '../slice/rootModal.slice';

const modalMap = {
  [SocialModal.CONFIRM]: {
    icon: 'common-content/general-ui/question-circle-solid.svg',
    class: 'cancel w-100',
    variant: 'secondary',
    title: MyProfile.SocialMedia.confirmTitle,
    info: MyProfile.SocialMedia.confirmInfo,
    reject: MyProfile.SocialMedia.cancel,
  },
  [SocialModal.ERROR]: {
    icon: 'common-content/general-ui/exclamation-triangle-solid.svg',
    class: 'goback px-5',
    variant: 'primary',
    title: MyProfile.SocialMedia.errorTitle,
    info: MyProfile.SocialMedia.errorInfo,
    reject: MyProfile.SocialMedia.goBack,
  },
};

const SocialMediaLinkModal: React.FC<Social.Modal> = ({ modal, type, name, message, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });

  const modalContentStyle = isLandscape && isMobileOnly ? { maxHeight: '100vh' } : undefined;
  return (
    <Modal
      className={`no-scrollbars modal-social-media modal-social-media--${type}`}
      contentStyle={modalContentStyle}
      scrollable={true}
      visible={true}
    >
      <Modal.Close closeText={t(MyProfile.SocialMedia.close)} closeAction={() => dispatch(hideRootModal())} />
      <Modal.Body padding={'lg'} className={'text-center'}>
        {modal === SocialModal.SUCCESS ? (
          <>
            <div className={'modal-social-media__success--title w-100'}>{t(MyProfile.SocialMedia.linkedTo)}</div>
            <SocialIcon type={type} size={36} />
            <div className={'modal-social-media__success--user w-100'}>{name}</div>
          </>
        ) : (
          <>
            <CDNSVG className={'modal-social-media__icon'} icon={modalMap[modal].icon} size={36} />
            <div className={'font-weight-bold'}>{t(modalMap[modal].title)}</div>
            <div>{t(message || modalMap[modal].info)}</div>
            <Flex.ACJA>
              <UI.Button
                variant={modalMap[modal].variant as CSS.ButtonVariants}
                className={`btn-action modal-social-media__btn--reject-${modalMap[modal].class}`}
                id={'egtd-btn--modal-social-media-reject'}
                text={t(modalMap[modal].reject)}
                onClick={onCancel}
              />
              {modal === SocialModal.CONFIRM && (
                <UI.Button
                  className={'btn-action modal-social-media__btn--confirm w-100'}
                  id={'egtd-btn--modal-social-media-confirm'}
                  text={t(MyProfile.SocialMedia.confirm)}
                  onClick={onSubmit}
                />
              )}
            </Flex.ACJA>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SocialMediaLinkModal;
