import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { setActiveSearchType } from '../../appHeader/components/common/slice/navSearch.slice';
import { selectNavigationMenu } from '../../navigationMenu/slice/navigationMenu.slice';
import { setCasinoDrawerType, setDrawerType } from '../slice/drawer.slice';

const searchMap = {
  sport: 'sports/search',
  live_sport: 'sports/search',
  esports: 'sports/search',
  virtuals: 'sports/search',
  casino: 'casino/search',
};

export const useSetDrawerType = () => {
  const menuItems = useAppSelector(selectNavigationMenu.menuItemsLayout02);

  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const currVertical = useMemo(() => {
    return menuItems?.find((menu) => {
      const routeToUse = menu.route;

      if (pathname.match(routeToUse)) return menu;
      return undefined;
    });
  }, [menuItems, pathname]);

  useEffect(() => {
    //Removed checks when fixed by cms backend
    if (currVertical && (currVertical?.type === 'live-sport' || searchMap[currVertical?.type])) {
      dispatch(
        setActiveSearchType(currVertical?.type === 'live-sport' ? 'sports/search' : searchMap[currVertical.type]),
      );
    }
    if (currVertical && currVertical.type !== 'home') {
      dispatch(setDrawerType(currVertical.type));
      dispatch(setCasinoDrawerType(currVertical.sectionType));
    }
  }, [dispatch, pathname, currVertical]);
};
