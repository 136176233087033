import { Element } from 'html-react-parser';

export const HELP_MAIN_ROUTE = '/help';

export const helpRoute = (route: string) => {
  if (route) {
    return route?.startsWith('/') ? `${HELP_MAIN_ROUTE}${route}` : `${HELP_MAIN_ROUTE}/${route}`;
  }
  return '';
};

export const hasBottomAnchorTag = (text: string = '', regex = /href="#bottom"/g) => regex.test(text);

export const replaceElem = ({ node, path, scrollMargin }: { node: Element; path: string; scrollMargin: number }) => {
  if (node.type === 'tag' && node.name === 'a') {
    if (node.attribs && node.attribs.href && node.attribs.href.startsWith('#')) {
      node.attribs.href = path + node.attribs.href;
    }
  }
  if (node.attribs && node.attribs.id) {
    const existingStyles = node.attribs?.style || '';
    node.attribs.style = `${existingStyles}${scrollMargin ? `scroll-margin-top: ${scrollMargin}px` : ''}`;
  }

  return node;
};
