import React from 'react';
import { Flex } from 'components/shared/layout';

/** Auth Form Wrapper */
interface Props {
  children?: React.ReactNode;
}

/** Auth Form Actions Wrapper */
const FormActions: React.FC<Props> = ({ children }) => {
  return <Flex.ColAC className="auth-form__actions">{children}</Flex.ColAC>;
};

export default FormActions;
