import React, { memo, useCallback } from 'react';
import FavouritesContainer from 'shared/common/features/drawer/components/favourites/FavouritesContainer';
import { FavouritesSection } from 'shared/common/features/drawer/components/favourites/types/favourites.types';
import { isEmpty } from 'utils/common/helpersCommon';
import { Sport } from 'utils/common/types/sportTypes';
import usePrefetchSportContainers from './hooks/usePrefetchSportContainers';
import SportsAZ from './SportsAZ';
import SportsContainers from './SportsContainers';
import SportSectionNavLink from './SportSectionNavLink';
import { useGetDrawerSportItems } from '../../hooks/useGetDrawerSportItems';
import { SportsType } from '../../types/drawer.types';
import DrawerNavLinkDefault from '../DrawerNavLinkDefault';
import { DrawerUI } from '../DrawerUI';

interface Props {
  isActive: boolean;
  isToggled: boolean;
}

const SportDrawerSection: React.FC<Props> = ({ isActive, isToggled }) => {
  usePrefetchSportContainers(isToggled);
  const { items, activeItem, sportDrawerContainers } = useGetDrawerSportItems(SportsType.Sport);

  const renderItem = useCallback(
    (item: Sport.ActiveSport, idx: number) => (
      <SportSectionNavLink
        key={idx}
        item={item}
        isActive={activeItem === item.id}
        index={idx}
        sportType={SportsType.Sport}
      />
    ),
    [activeItem],
  );

  return (
    <DrawerUI.Section type="sport" isActive={isActive}>
      <DrawerNavLinkDefault
        sportType={SportsType.Sport}
        navRoute={{ route: '/' + SportsType.Sport }}
        icon={`common-content/menu-icons/link-home.svg`}
      />
      <SportsContainers sportDrawerContainers={sportDrawerContainers} />
      {!isEmpty(items) && (
        <>
          <SportsAZ />
          <FavouritesContainer
            favouriteType={FavouritesSection.sportIds}
            items={items}
            renderItem={renderItem}
            isAnimated
          />
        </>
      )}
    </DrawerUI.Section>
  );
};

export default memo(SportDrawerSection);
