import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { usePreloadImage } from 'hooks/common/usePreloadImage';
import { General } from 'shared/common/features/general/types/generalSlice.types';
import { selectLanguage } from 'shared/common/features/settings/slice/settings.slice';
import { useAppSelector } from 'store';
import config from 'utils/config';
interface TopMobileBannerNavigationElementProps {
  bannerConfigPerDevice: General.TopMobileAppBannerBannerPath;
  device?: string;
}

const TopMobileBannerNavigationElement: React.FC<TopMobileBannerNavigationElementProps> = ({
  bannerConfigPerDevice,
  device,
}) => {
  const language = useAppSelector(selectLanguage);
  const imgURL = `${config.CDN_URL}/common-content/mobile/${device}-${language}.svg`;

  const [img, setImg] = useState(imgURL);
  const { imageLoadError } = usePreloadImage(imgURL);

  useEffect(() => {
    imageLoadError && setImg(`${config.CDN_URL}/common-content/mobile/${device}-en.svg`);
  }, [imageLoadError]);

  const bannerElement = () => {
    return <img src={img} className="top-banner__link--icon" alt="mobile_banner" />;
  };

  if (bannerConfigPerDevice.downloadLink) {
    return (
      <a className="top-banner__link" href={bannerConfigPerDevice.downloadLink} rel="noreferrer">
        {bannerElement()}
      </a>
    );
  }
  if (bannerConfigPerDevice.reactRoute) {
    return (
      <Link to={bannerConfigPerDevice.reactRoute} className="top-banner__link">
        {bannerElement()}
      </Link>
    );
  }
  if (bannerConfigPerDevice.route) {
    return (
      <a className="top-banner__link" href={bannerConfigPerDevice.route} rel="noreferrer" target="_blank">
        {bannerElement()}
      </a>
    );
  }
  return <img src={img} className="top-banner__link--icon" />;
};
export default TopMobileBannerNavigationElement;
