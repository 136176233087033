import React from 'react';
import clsx from 'clsx';
import { cssBurgerNS } from './burgerUI.types';

/** Header */
interface HeaderProps {
  className?: string;
  children?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ className, children }) => {
  const wrapperClass = clsx(cssBurgerNS.Header, className);

  return <div className={wrapperClass}>{children}</div>;
};

export default Header;
