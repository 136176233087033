import React, { memo } from 'react';
import clsx from 'clsx';
import { useCurrency } from 'hooks/common/useCurrency';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { formatWithComma, isEmpty } from 'utils/common/helpersCommon';
import { LayoutConfigTypes } from '../../general/types/generalSlice.types';

/** AmountCurrency */
interface AmountCurrencyProps {
  amount?: string | number | null;
  className?: string;
  currencyClassName?: string;
  currencyDefaultClassName?: boolean;
  defaultClassName?: boolean;
  externalCurrencySymbol?: string;
  scaleCurrencyTo?: 50 | 75;
  spaceBefore?: boolean;
}

export const useAmountCurrency = (amount: string | number) => {
  const { currencySymbol } = useCurrency();
  const currencyDisplayConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.CURRENCY_DISPLAY,
    deviceLayout: 'general',
  }) || { currencySymbolFirst: false };

  const formattedAmount =
    currencyDisplayConfig?.formatToComma && (amount === 0 || !isEmpty(amount)) ? formatWithComma(amount) : amount;

  const amountCurrency = currencyDisplayConfig.currencySymbolFirst
    ? `${currencySymbol} ${formattedAmount ?? ''}`
    : `${formattedAmount ?? ''} ${currencySymbol}`;

  return amountCurrency;
};

const AmountCurrency: React.FC<AmountCurrencyProps> = ({
  amount,
  className,
  currencyClassName,
  currencyDefaultClassName = true,
  defaultClassName = true,
  externalCurrencySymbol,
  scaleCurrencyTo,
  spaceBefore = true,
}) => {
  const { currencySymbol } = useCurrency();

  const currencyDisplayConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.CURRENCY_DISPLAY,
    deviceLayout: 'general',
  });

  if (!amount) return;

  const formattedAmount =
    currencyDisplayConfig?.formatToComma && (amount === 0 || !isEmpty(amount)) ? formatWithComma(amount) : amount;

  const currencyBeforeAmount =
    currencyDisplayConfig?.currencySymbolFirst !== undefined ? currencyDisplayConfig.currencySymbolFirst : false;
  const currencyAmountSpacer =
    currencyDisplayConfig?.spaceBetween !== undefined ? currencyDisplayConfig.spaceBetween : true;

  const wrapperClass = clsx(defaultClassName && 'egtd-amc', spaceBefore && 'ml-1', className);
  const currencyClass = clsx(currencyDefaultClassName && 'egtd-amc__c', currencyClassName);

  const scaleCurrencyProp = scaleCurrencyTo ? { 'data-s': scaleCurrencyTo } : undefined;
  const currencySpacerProp = currencyAmountSpacer ? { 'data-g': currencyAmountSpacer } : undefined;

  return (
    <div className={wrapperClass} {...currencySpacerProp}>
      {currencyBeforeAmount ? (
        <>
          <span className={currencyClass} {...scaleCurrencyProp}>
            {externalCurrencySymbol ? externalCurrencySymbol : currencySymbol}
          </span>
          {formattedAmount}
        </>
      ) : (
        <>
          {formattedAmount}
          <span className={currencyClass} {...scaleCurrencyProp}>
            {externalCurrencySymbol ? externalCurrencySymbol : currencySymbol}
          </span>
        </>
      )}
    </div>
  );
};

export default memo(AmountCurrency);
