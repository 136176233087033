import React, { CSSProperties } from 'react';
import { CSS, cssGlobalNS } from 'modules/casino/shared/types/css.types';

export type ButtonContentProps = {
  size?: CSS.Sizes;
  iconClassName?: string;

  text?: string;
  icon?: string;
};

export type ButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  variant?: CSS.ButtonVariants;
  size?: CSS.Sizes;

  className?: string;
  iconClassName?: string;

  text?: string;
  icon?: string;
  children?: React.ReactNode;

  style?: CSSProperties;
  dataQid?: string;

  isActive?: boolean;
  isDisabled?: boolean;
  onClickHandler?: React.MouseEventHandler<HTMLButtonElement>;
};

export interface HTMLButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: CSS.ButtonVariants;
  size?: CSS.Sizes;

  className?: string;
  iconClassName?: string;
  children?: React.ReactNode;
  text?: string;
  isActive?: boolean;

  icon?: string;
  iconPath?: string;
}

// export interface ButtonProps extends React.HTMLCustomAttributes<HTMLButtonElement>

export const cssBtnNS = `${cssGlobalNS}-c-btn`;

export const btnIconSizesMap: Partial<Record<CSS.Sizes, number>> = {
  sm: 14,
  md: 16,
  lg: 18,
};
