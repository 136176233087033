import styled from 'styled-components';
import { ClipAnimation } from './Animation.styled';

const HighlightFeatureWrapper = styled.div<{
  width: number;
  height: number;
  left: number;
  top: number;
}>`
  width: 100%;
  height: 100%;
  &.is-active {
    animation: ${({ width, height, left, top }) => ClipAnimation(top, left, width, height)} 0.5s forwards ease-in-out;
  }
`;

export default HighlightFeatureWrapper;
