import React from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';
import { cssGamificationNS } from 'utils/common/types/css.types';

type Props = {
  completed: boolean;
  progress: number;
  isModal?: boolean;
};

export const ProgressBar: React.FC<Props> = ({ completed, progress, isModal = false }) => {
  const progressWrapperClass = clsx(
    `${cssGamificationNS}-chall-cell__progress`,
    isModal && `${cssGamificationNS}-chall-cell__progress--modal`,
  );
  const progressClass = clsx(
    `${cssGamificationNS}-chall-cell__progress-bar-inner`,
    completed && `${cssGamificationNS}-chall-cell__progress-bar-inner--complete`,
  );
  const compleateNumber = progress === 0 ? 0 : progress === 100 ? 100 : parseFloat(progress.toString());

  return (
    <div className={progressWrapperClass}>
      <div className={`${cssGamificationNS}-chall-cell__progress-bar`}>
        <Flex.Center className={progressClass} style={{ width: progress + '%' }}></Flex.Center>
        <Flex.Center className={`${cssGamificationNS}-chall-cell__progress-number`}>{compleateNumber}%</Flex.Center>
      </div>
    </div>
  );
};
