import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues } from 'components/shared/forms/formTypes';
import { Modal } from 'components/shared/Modal';
import { useAuthentication } from 'hooks/common/useAuthentication';
import { useOutsideClick } from 'hooks/common/useOutsideClick';
import { MFA_Channels } from 'shared/common/features/myProfile/types/myProfile.types';
import { hideRootGrandModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch } from 'store';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import PhoneConfirmation from './PhoneConfirmation';
import { loginThunks } from '../login/slice/login.slice';

type Props = {
  data?: FieldValues;
  type?: 'player' | 'public';
  location: 'reg' | 'login' | 'reset-pass' | 'logged-in' | 'my-account';
  showLogoutButton?: boolean;
  showCloseButton?: boolean;
  hideChannel?: boolean;
  channels: MFA_Channels[];
};

const PhoneConfirmationModal: React.FC<Props> = ({
  data,
  type = 'player',
  location,
  showLogoutButton,
  showCloseButton = false,
  hideChannel,
  channels,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { isAuthenticated } = useAuthentication();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const clickOutside = useCallback(() => {
    if (isAuthenticated) {
      dispatch(loginThunks.logout());
    }
  }, [dispatch, isAuthenticated]);

  useOutsideClick(containerRef, clickOutside);

  const closeAction = () => dispatch(hideRootGrandModal());

  return (
    <Modal ref={containerRef} visible className="modal-tfa">
      {(!isAuthenticated || showCloseButton) && (
        <Modal.Close closeText={t(MyProfile.OffersTab.closeBtn)} closeAction={closeAction} />
      )}
      <Modal.Body padding="lg" className="text-center">
        <PhoneConfirmation
          data={data}
          type={type}
          location={location}
          showLogoutButton={showLogoutButton}
          closeAction={closeAction}
          hideChannel={hideChannel}
          channels={channels}
        />
      </Modal.Body>
    </Modal>
  );
};

export default PhoneConfirmationModal;
