import React, { Dispatch, HTMLAttributes, MouseEventHandler, SetStateAction } from 'react';
import clsx from 'clsx';
import { TFunction } from 'i18n';
import { cashierActionButtons } from './actionButtonsMock';
import GridButton from './GridButton';

/** Cashier Modal NavGrid Section */
interface NavGridProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onClick'> {
  className?: string;
  children?: React.ReactNode;
  actionButtonClicked?: number[] | null;
  seconds?: number;
  setSeconds?: Dispatch<SetStateAction<number>>;
  onClick: (id?: number) => void | (() => void) | MouseEventHandler<HTMLButtonElement>;
  activeButtons: number[];
  setActionButtonClicked?: React.Dispatch<React.SetStateAction<null | number[]>>;
  translation?: TFunction;
  setActiveButtons?: any;
}

const NavGrid: React.FC<NavGridProps> = ({
  className,
  actionButtonClicked,
  seconds,
  setSeconds,
  onClick,
  activeButtons,
  setActionButtonClicked,
  translation,
  setActiveButtons,
}) => {
  const wrapClass = clsx('cashier-modal__nav-grid', className);
  const findCurrentClickedActiveBtn = (id) => {
    return Array.isArray(activeButtons) ? activeButtons.filter((button) => button === id) : [];
  };

  return (
    <div className={wrapClass}>
      {cashierActionButtons(translation)
        .filter((button) => button.visible)
        .map((btn) => (
          <GridButton
            key={btn.id}
            text={btn.text}
            icon={btn.icon}
            hasCounter={btn.hasCounter}
            actionButtonClicked={actionButtonClicked}
            seconds={seconds}
            setSeconds={setSeconds}
            onClick={onClick}
            id={btn.id}
            activeButtons={activeButtons}
            setActionButtonClicked={setActionButtonClicked}
            secondIcon={btn.secondIcon}
            currentClickedActiveBtn={findCurrentClickedActiveBtn(btn.id)[0]}
            setActiveButtons={setActiveButtons}
          />
        ))}
    </div>
  );
};

export default NavGrid;
