import { lazy, memo, Suspense } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import useAppHomePage from 'hooks/common/useAppHomePage';
import useMyBetsRoute from 'hooks/common/useMyBetsRoute';
import { configRoutes } from 'hooks/common/useVerticalsConfig';
import { useGetCasinoVerticals } from 'modules/casino/modules/navigation/menu/hooks/useGetCasinoVerticals';
import useCasinoWidgetConfig from 'modules/casino/platformImplementation/hooks/useCasinoWidgetConfig';
import { CasinoLobbyType, GAME_LAUNCH_PREFIX } from 'modules/casino/shared/constants';
import useSportsBookType from 'modules/sport/hooks/useSportsBookType';
import { useGamificationConfig } from 'pages/captainUp/hooks/useGamificationConfig';
import { HELP_MAIN_ROUTE } from 'pages/help/utils/help.utils';
import { useIframeVerticalConfig } from 'pages/location/hooks/useIframeVerticalConfig';
import SelfExclusionConfirmation from 'pages/myAccount/tabs/responsibleGambling/selfExclusionConfirm';
import { PRODUCT_TYPE_ENUM } from 'pages/myAccount/tabs/responsibleGambling/types/responsibleGambling.types';
import { useSetOpenFromPoker } from 'pages/playtechPoker/hooks/useSetOpenFromPoker';
import { useSetOpenFromSvara } from 'pages/svaraAppLogin/hooks/useSetOpenFromSvara';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { useAppSelector } from 'store';
import { DepositHandHeldRestriction } from './DepositHandHeldRestriction';
import GameLaunchNew from '../../modules/casino/gameLaunch/GameLaunchNew';

const DynamicSitemap = lazy(() => import('shared/common/features/seo/sitemapDynamic/DynamicSitemap'));
const RestrictVerticalWrapper = lazy(() => import('./RestrictVerticalWrapper'));
const RestrictionRouteWrapper = lazy(() => import('./RestrictionRouteWrapper'));
const PlayerRestrictionsRouteWrapper = lazy(() => import('./PlayerRestrictionsRouteWrapper'));

const HomePage = lazy(() => import('pages/homePage'));
const FantasyScoutLayout = lazy(() => import('pages/fantasyScout/FantasyScoutLayout'));
const SportsImplementationPage = lazy(() => import('modules/sport/sportsBook/SportsImplementationPage'));
const AltenarImplementationPage = lazy(() => import('modules/sport/altenarSportsBook/AltenarImplementationPage'));

const CasinoTopLevelLayout = lazy(() => import('modules/casino/shared/layouts/CasinoTopLevelLayout'));
const CasinoVerticalImplementation = lazy(
  () => import('modules/casino/platformImplementation/casinoWidgetTypes/CasinoVerticalImplementation'),
);
const CasinoSearch = lazy(() => import('modules/casino/shared/features/casinoSearch'));
const CasinoLayout = lazy(() => import('modules/casino/shared/layouts'));
const LiveGamesLayout = lazy(() => import('pages/liveGames'));
const GameLauncher = lazy(() => import('modules/casino/shared/features/gameLaunch/launcherRoute/GameLauncher'));
const CloseGame = lazy(() => import('modules/casino/shared/features/gameLaunch/launcherRoute/CloseGame'));

const CommonTopLevelLayout = lazy(() => import('shared/layouts/CommonTopLevelLayout'));
const PromotionContentLayout = lazy(() => import('pages/promotions/promotionContent'));
const PromotionsLayout = lazy(() => import('pages/promotions/PromotionsLayout'));

const MyAccount = lazy(() => import('pages/myAccount'));
const Registration = lazy(() => import('pages/auth/registration'));
const Confirmation = lazy(() => import('pages/auth/registrationConfirm'));
const EmailChangedConfirm = lazy(() => import('pages/auth/emailChangedConfirm'));
const ResetPassword = lazy(() => import('pages/auth/resetPassword'));
const ForgotPassword = lazy(() => import('pages/auth/forgotPassword'));

const CaptainUpTopLevelLayout = lazy(() => import('shared/layouts/CaptainUpTopLevelLayout'));
const CaptainUpLayout = lazy(() => import('pages/captainUp'));
const GamificationPage = lazy(() => import('pages/gamificationPage'));
const PromoHighlights = lazy(() => import('pages/landingPage/PromoHighlights'));

const PlaytechPoker = lazy(() => import('pages/playtechPoker/PlaytechPoker'));
const SvaraAppLogin = lazy(() => import('pages/svaraAppLogin/SvaraAppLogin'));
const Affiliates = lazy(() => import('pages/lottery/Affiliates'));
const GamificationImplementationPage = lazy(
  () => import('shared/common/features/integrations/gamification/GamificationImplementationPage'),
);
const RetailGamesLayout = lazy(() => import('pages/retailGames/RetailGamesLayout'));
const FantasyStarsLayout = lazy(() => import('pages/fantasyStars/FantasyStarsLayout'));
const HandHeldDeposit = lazy(() => import('pages/handHeldDeposit/HandHeldDeposit'));
const HelpLayout = lazy(() => import('pages/help'));
const LocationMap = lazy(() => import('pages/location/Location'));

const NotFound = lazy(() => import('pages/404'));

export const sportRoutes = ['/sports/*', '/in-play/*', '/esports/*', '/virtual/*', '/sports/results/*', '/outrights/*'];
export const altenarRoutes = ['/sports/*', '/in-play/*', '/virtual/*'];

const TopLevelRouter = () => {
  const availableRestrictionProducts = useAppSelector(selectGeneral.availableRestrictionProducts);

  const { homeRoute } = useAppHomePage();
  const { route: gamificationRoute, isWidget } = useGamificationConfig();
  const { enabled } = useMyBetsRoute();
  const { route: iframeRoute } = useIframeVerticalConfig();
  const { sportsBookType } = useSportsBookType();

  const isPrerender = navigator.userAgent.toLowerCase().indexOf('prerender') !== -1;

  const casinoVerticals = useGetCasinoVerticals();
  useSetOpenFromPoker();
  useSetOpenFromSvara();

  const casinoWidgetConfig = useCasinoWidgetConfig();

  // TO DO check if this could be dynamic
  const fantasyType = availableRestrictionProducts?.includes(PRODUCT_TYPE_ENUM.FANTASY_GAMES)
    ? PRODUCT_TYPE_ENUM.FANTASY_GAMES
    : PRODUCT_TYPE_ENUM.SPORT;

  return (
    <Suspense fallback={null}>
      <Routes>
        {/* Should we restrict home page? */}
        {homeRoute === '/' ? (
          <Route path="/" element={<HomePage />} />
        ) : (
          <Route path="/" element={<Navigate to={homeRoute} replace state={{ redirectFromHome: true }} />} />
        )}
        <Route path="/schema/sitemap.xml" element={<DynamicSitemap />} />
        {/* TO DO - No vertical for fantasy - it could be accessed from sidebar tab */}
        <Route
          path="/fantasy/*"
          element={
            <RestrictVerticalWrapper
              element={
                <PlayerRestrictionsRouteWrapper restrictions={[fantasyType]}>
                  <FantasyScoutLayout />
                </PlayerRestrictionsRouteWrapper>
              }
            />
          }
        />
        <Route
          path="/fantasyStars/*"
          element={
            <RestrictVerticalWrapper
              element={
                <PlayerRestrictionsRouteWrapper restrictions={[fantasyType]}>
                  <FantasyStarsLayout />
                </PlayerRestrictionsRouteWrapper>
              }
            />
          }
        />
        {/* for retail games */}
        <Route path="/games/*" element={<RestrictVerticalWrapper element={<RetailGamesLayout />} />} />

        {sportsBookType === 'egtd'
          ? sportRoutes.map((route) => (
              <Route
                key={route}
                path={route}
                element={<RestrictVerticalWrapper element={<SportsImplementationPage />} />}
              />
            ))
          : altenarRoutes.map((route) => (
              <Route
                key={route}
                path={route}
                element={<RestrictVerticalWrapper element={<AltenarImplementationPage />} />}
              />
            ))}

        {/* No vertical for my bets */}
        {enabled && (
          <Route
            path={configRoutes.myBets}
            element={
              <RestrictionRouteWrapper restriction="private">
                <SportsImplementationPage />
              </RestrictionRouteWrapper>
            }
          />
        )}
        {casinoWidgetConfig ? (
          <>
            {casinoVerticals?.map((vertical, index) => (
              <Route
                key={`${index}_${vertical.casinoRootType}`}
                path={`${vertical.route}/*`}
                element={
                  <RestrictVerticalWrapper
                    element={
                      vertical.casinoRootType === CasinoLobbyType.LiveGames ? (
                        <LiveGamesLayout casinoType={CasinoLobbyType.Default} vertical={vertical} />
                      ) : (
                        <CasinoVerticalImplementation casinoRootType={vertical.casinoRootType} />
                      )
                    }
                  />
                }
              />
            ))}

            {<Route path="casino/search" element={<CasinoVerticalImplementation casinoRootType={'SEARCH'} />} />}
            <Route path="search/casino" element={<Navigate to="/casino/search" replace />} />

            <Route path={`/${GAME_LAUNCH_PREFIX}/*`} element={<GameLaunchNew />} />
            <Route path={`/close-game`} element={<CloseGame />} />
          </>
        ) : (
          <Route element={<CasinoTopLevelLayout />}>
            {<Route path="casino/search" element={<CasinoSearch />} />}
            <Route path="search/casino" element={<Navigate to="/casino/search" replace />} />

            {casinoVerticals?.map((vertical, index) => (
              <Route
                key={`${index}_${vertical.casinoRootType}`}
                path={`${vertical.route}/*`}
                element={
                  <RestrictVerticalWrapper
                    element={
                      vertical.casinoRootType === CasinoLobbyType.LiveGames ? (
                        <LiveGamesLayout casinoType={CasinoLobbyType.Default} vertical={vertical} />
                      ) : (
                        <CasinoLayout casinoType={vertical.casinoRootType} casinoRoute={vertical.route} />
                      )
                    }
                  />
                }
              />
            ))}

            <Route
              path={`/${GAME_LAUNCH_PREFIX}/:vertical/:providerAlias/:gameName/launch/:gameId`}
              element={<GameLauncher />}
            />
            <Route path={`/close-game`} element={<CloseGame />} />
          </Route>
        )}
        <Route element={<CommonTopLevelLayout />}>
          <Route path="/promotions/*" element={<RestrictVerticalWrapper element={<PromotionsLayout />} />} />

          <Route
            path="/promotions/:promoCategoryAlias/:promotionTitle"
            element={<RestrictVerticalWrapper element={<PromotionContentLayout />} />}
          />
          {/* No vertical for my-account */}
          <Route
            path="/my-account/*"
            element={
              <RestrictionRouteWrapper restriction="private" showLogin>
                <MyAccount />
              </RestrictionRouteWrapper>
            }
          />

          <Route
            path="/registration/*"
            element={
              <RestrictionRouteWrapper restriction="public">
                <Registration />
              </RestrictionRouteWrapper>
            }
          />
          <Route
            path="/confirmation"
            element={
              <RestrictionRouteWrapper restriction="public">
                <Confirmation />
              </RestrictionRouteWrapper>
            }
          />
          <Route
            path="/email-changed-confirmation"
            element={
              <RestrictionRouteWrapper restriction="public">
                <EmailChangedConfirm />
              </RestrictionRouteWrapper>
            }
          />
          <Route path="/self-exclusion-confirmation" element={<SelfExclusionConfirmation />} />

          <Route path={`${HELP_MAIN_ROUTE}/*`} element={<HelpLayout />} />

          <Route
            path="/forgot-password"
            element={
              <RestrictionRouteWrapper restriction="public">
                <ForgotPassword />
              </RestrictionRouteWrapper>
            }
          />
          <Route
            path="/reset-password"
            element={
              <RestrictionRouteWrapper restriction="public">
                <ResetPassword />
              </RestrictionRouteWrapper>
            }
          />
        </Route>
        <Route path="/landing/:landingTitle" element={<HomePage isLandingPage />} />
        <Route path={`${iframeRoute}`} element={<RestrictVerticalWrapper element={<LocationMap />} />} />

        {isWidget ? (
          <Route
            path={`${gamificationRoute}/*`}
            element={<RestrictVerticalWrapper element={<GamificationImplementationPage />} />}
          />
        ) : (
          <Route element={<CaptainUpTopLevelLayout />}>
            <Route
              path={`${gamificationRoute}/*`}
              element={<RestrictVerticalWrapper element={<CaptainUpLayout />} />}
            />
          </Route>
        )}
        <Route path="/promo/*" element={<RestrictVerticalWrapper element={<GamificationPage />} />} />
        <Route path="/promo/landing" element={<PromoHighlights />} />

        <Route path="/playtech-poker/*" element={<PlaytechPoker />} />
        <Route
          path="/affiliates/*"
          element={
            <RestrictionRouteWrapper restriction="public">
              <Affiliates />
            </RestrictionRouteWrapper>
          }
        />
        <Route path="/svara-app/*" element={<SvaraAppLogin />} />
        <Route
          path="/handheld-deposit"
          element={
            <DepositHandHeldRestriction>
              <HandHeldDeposit />
            </DepositHandHeldRestriction>
          }
        />
        <Route path="*" element={isPrerender ? <Navigate to={homeRoute} replace /> : <NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default memo(TopLevelRouter);
