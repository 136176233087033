import SliderGridRender from '../components/gridTypes/slider/SlideGridRender';
import VerticalGrid from '../components/gridTypes/VerticalGrid';
import GenericLayoutWrapper from '../components/layouts/GenericLayoutWrapper';
import { GridType } from '../types/gridTypes';

export const GridTypes = {
  [GridType.SLIDER]: SliderGridRender,
  [GridType.VERTICAL]: VerticalGrid,
};

export const GridLayouts = {
  GenericLayout: GenericLayoutWrapper,
};
