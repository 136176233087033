import { memo, useCallback, useRef } from 'react';
import { Button } from 'components/shared/buttons';
import SVGCDN from 'components/shared/UI/SVGCDN/SVGCDN';
import { useOnOff } from 'hooks/common/useOnOff';
import { useOutsideClick } from 'hooks/common/useOutsideClick';
import HelpDropdownMenu from './HelpDropdownMenu';

const HelpDropdownButton: React.FC = () => {
  const dropdown = useRef<HTMLDivElement>(null);
  const [dropdownOpen, setDropdownOpen] = useOnOff();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const outsideClickHandler = useCallback(() => {
    dropdownOpen && setDropdownOpen(false);
  }, [dropdownOpen]);

  useOutsideClick(dropdown, outsideClickHandler);

  return (
    <div className="egtd-dropdown" ref={dropdown}>
      <Button onClickHandler={toggleDropdown}>
        <SVGCDN path="GENERAL" className="reg-help__icon" icon="reg-help-menu" />
      </Button>
      {dropdownOpen && <HelpDropdownMenu />}
    </div>
  );
};

export default memo(HelpDropdownButton);
