import { useCallback } from 'react';
import { loginThunks } from 'pages/auth/login/slice/login.slice';
import { Login } from 'pages/auth/login/types/login.types';
import { selectRegistration, setActiveStep, setFormDataField } from 'pages/auth/registration/slice/registration.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { linkAccount } from '../slice/formSocial.slice';
import { Social, SocialButtonAuthTypes } from '../types/formSocial.types';

const authTypes = {
  [SocialButtonAuthTypes.LOGIN]: ({ username, password, social, dispatch }: Social.AuthAction) => {
    const data = { username, password, social_media: social } as Login.LoginData;
    if (window.isRNWebView) {
      const checkbox = document.getElementById('webview-checkbox') as HTMLInputElement;
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          credentials: {
            ...data,
            isChecked: checkbox.checked,
          },
        }),
      );
    }
    dispatch(loginThunks.login({ data }));
  },
  [SocialButtonAuthTypes.REGISTER]: ({ password, social, userData, activeStep = 0, dispatch }: Social.AuthAction) => {
    dispatch(setActiveStep(activeStep + 1));
    dispatch(
      setFormDataField({
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        email: userData?.email,
        socialMediaCredentials: { name: social, userId: userData?.userId, accessToken: password },
      }),
    );
  },
  [SocialButtonAuthTypes.LINK]: ({ username, password, social, userData, dispatch }: Social.AuthAction) => {
    dispatch(linkAccount({ name: social, userId: username, accessToken: password, email: userData?.email }));
  },
};

export const useSocialAuth = ({ social, authType }: Social.AuthUse) => {
  const activeStep = useAppSelector(selectRegistration.activeStep);
  const dispatch = useAppDispatch();
  const onSocialAuth = useCallback(
    (authProps: Social.AuthProps) => authType && authTypes[authType]?.({ ...authProps, social, activeStep, dispatch }),
    [social, authType, activeStep, dispatch],
  );

  return { onSocialAuth };
};
