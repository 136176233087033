import { createSlice } from '@reduxjs/toolkit';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { MyChallengesType } from '../types/challenges.types';

export const myChallengesThunks: MyChallengesType.MyChallengesThunks = {
  getItems: createAbortThunk('myChallenges/getMyChallenges', async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<MyChallengesType.Challenge[]>(
        `${config.API_URL}/api/ews-game-journey/player/get-my-profile-player-missions`,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.default());
    }
  }),
};

const initialState: MyChallengesType.State = {
  isLoading: false,
  hasFetched: false,
  hasError: false,
  normalizedChallenges: {},
  challengesCount: 0,
  challengesIds: [],
};

const myChallenges = createSlice({
  name: 'myChallenges',
  initialState,
  reducers: {
    resetChallenges: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(myChallengesThunks.getItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(myChallengesThunks.getItems.fulfilled, (state, action) => {
        state.challengesCount = action.payload?.length;
        normalizeChallenge(state.normalizedChallenges, action.payload);
        state.challengesIds = action.payload.map((badge) => badge.id);
        state.hasFetched = true;
        state.isLoading = false;
      })
      .addCase(myChallengesThunks.getItems.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) state.hasError = true;
      });

    builder.addCase(logoutUser, () => initialState);
  },
});

const normalizeChallenge = (currBadges = {}, challenges: MyChallengesType.Challenge[]): void => {
  challenges?.forEach((badge) => {
    currBadges[badge.id] = badge;
  });
};

export const { resetChallenges } = myChallenges.actions;

export const { reducer: myChallengesReducer } = myChallenges;

export const selectMyChallenges = {
  challenges: (state: RootState): string[] => state.myChallenges.challengesIds,
  getChallengeById: (id: string) => (state: RootState) => state.myChallenges.normalizedChallenges?.[id],
  notificationCounter: (state: RootState): number => state.myChallenges.challengesCount,
};
