import React from 'react';
import clsx from 'clsx';
import { cssBurgerNS } from './burgerUI.types';

/** Section */
interface SectionProps {
  className?: string;
  children?: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ className, children }) => {
  const wrapperClass = clsx('d-flex', cssBurgerNS.Section, className);

  return <div className={wrapperClass}>{children}</div>;
};

export default Section;
