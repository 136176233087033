import { cdnPaths } from 'components/shared/UI/SVGCDN/SVGCDN.types';

export const bonusIconsPath = cdnPaths.SPORT_BONUSES;

export enum BonusIconsTypes {
  AccaBoost = 'boost',
  AccaInsurance = 'secure',
  BoreDraw = 'bore-draw',
  EarlyPayout = 'payout',
  EnhancedEarlyPayout = 'early-payout',
  EnhancedOdds = 'percent',
  GoalInsurance = 'secure-goal',
  MultipleBoost = 'boost-multiple',
}
