import { Location } from 'history';

//TODO: separate into files when sport and casino are autonomous projects

// ----------------------- container.types ------------------------
export enum ContainerTypes {
  sportLobbyLive = 'sportLobbyLive',
  highlightsPerSport = 'highlightsPerSport',
  linkContainer = 'linkContainer',
  mixedHighlights = 'mixedHighlights',
  slider = 'slider',
  BonusesPromotionsSlider = 'BonusesPromotionsSlider',
  gamesSlider = 'gamesSlider',
  footer = 'footer',
  casinoLobby = 'casinoLobby',
  casinoMenu = 'casinoMenu',
  // virtualPreLobbyCell = 'slider',
  multilineHighlights = 'multilineHighlights',
  captainUpMenu = 'captainUpMenu',
  promo = 'promo',
  //new homePage containers
  gamesContainer = 'gamesContainer',
  casinoContainer = 'casinoContainer',
  topGames = 'topGames',
  topGamesNoTitle = 'topGamesNoTitle',
  casinoJackpots = 'casinoJackpots',
  casinoWidget = 'casinoWidget',
  promoWidget = 'promoWidget',
  videoBanner = 'videoBanner',
  lastWinners = 'lastWinners',
  liveSports = 'liveSports',
  steps = 'steps',
  brandText = 'brandText',
  termsAndConditions = 'termsAndConditions',
  videoPlayer = 'videoPlayer',
  sportEvents = 'sportEvents',
  landingPageSlider = 'landingPageSlider',
  doubleContainer = 'doubleContainer',
  tripleContainer = 'tripleContainer',
  providersMenu = 'providersMenu',
  iFrame = 'iFrame',
  progressBarContainer = 'progressBarContainer',
  ticketCounterContainer = 'ticketCounterContainer',
  multimediaContainer = 'multimediaContainer',
}

export enum ContainerSubTypes {
  PROMO_LOTTO = 'promoLotto',
}

export type ContainerTypeNames = keyof typeof ContainerTypes; // 'sportLobbyLive' | 'highlightsPerSport' | 'linkContainer'...
export type ContainerSubTypeNames = keyof typeof ContainerSubTypes; // 'sportLobbyLive' | 'highlightsPerSport' | 'linkContainer'...

// -------------- deviceTypes.types
export enum deviceOrientation {
  portraitPrimary = 'portraitPrimary',
  portraitSecondary = 'portraitSecondary',
  landscapePrimary = 'landscapePrimary',
  landscapeSecondary = 'landscapeSecondary',
}

// ----------------------- utility.types -------------------
export type PartialRecord<K extends string | number | symbol, T> = Partial<Record<K, T>>;

export type DeepPartial<T> = {
  [K in keyof T]?: T[K] extends Record<string, unknown> ? DeepPartial<T[K]> : T[K];
};

// -------------------- cachios.types -------------------
export type CachiosOptions = {
  ttl: number;
  force?: boolean;
};

export type CachiosGetOptions = {
  options: CachiosOptions;
  language: string | null;
};

// ---------------------- location.types ------------------------
export interface LocationType<T> extends Location {
  state: T;
}
