import { useEffect } from 'react';
import { GameMediaTypes } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellMedia/gameCellMediaTypes';
import { localLog } from 'modules/casino/shared/utils/common/helpersCommon';

export const useVideo = ({ videoRef, videoSource, inView }: GameMediaTypes.UseVideoProps) => {
  useEffect(() => {
    if (videoRef.current) {
      const isPlaying =
        videoRef.current?.currentTime > 0 &&
        !videoRef.current?.paused &&
        !videoRef.current?.ended &&
        videoRef.current?.readyState > videoRef.current?.HAVE_CURRENT_DATA;
      const playPromise = !inView ? videoRef.current.pause() : !isPlaying && videoRef.current.play();
      playPromise &&
        playPromise.catch((error) => {
          localLog({ message: `${error}, ${videoRef.current?.src}` });
        });
    }
  }, [inView, videoRef]);

  useEffect(() => {
    if (videoRef.current) {
      if (videoRef.current.src !== videoSource) {
        videoRef.current.src = videoSource;
      }
    }
  }, [videoSource, videoRef]);
};
