import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CashierModalUI } from 'modules/retail/modules/cashier/cashierModalUI/Index';
import { useFinancialInfo } from 'modules/retail/modules/cashier/features/finance/useFinanceInfo';
import { useCashierModalPrompt } from 'modules/retail/modules/cashier/hooks/useCashierModalPrompt';
import { selectCashierData } from 'modules/retail/modules/cashier/slice/cashier.slice';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { clearModalsQueue } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { isCashier, isEmpty } from 'utils/common/helpersCommon';
import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';
import DepositInfoTable from './components/deposit/DepositInfoTable';
import WithdrawInfoTable from './components/withdraw/WithdrawInfoTable';
import OperationControl from './OperationControl';
import { CashPayTypes } from './types/cashPay.types';

const infoTableMap: Record<CashPayTypes.PaymentType, React.FC> = {
  deposit: () => <DepositInfoTable />,
  withdraw: () => <WithdrawInfoTable />,
};

const CashPayModal: React.FC = () => {
  const [activeTab, setActiveTab] = useState<CashPayTypes.PaymentType>('deposit');
  const posInfo = useAppSelector(selectCashierData.posInfo);
  const dispatch = useAppDispatch();
  const { isPrintingEject } = useFinancialInfo();
  const { closeModal } = useHandleModals();
  const { t } = useTranslation();
  const isCashierModalPrompted = useCashierModalPrompt();

  const handleSetActiveTab = (type: CashPayTypes.PaymentType) => {
    setActiveTab(type);
  };

  const handleCashPayModalClosure = () => {
    if (isPrintingEject) return;
    dispatch(clearModalsQueue());
    closeModal();
  };

  useEffect(() => {
    if (isCashierModalPrompted) {
      closeModal();
    }
  }, [isCashierModalPrompted]);

  const modalTitle = !isEmpty(posInfo)
    ? `${t(CashierTranslations.FinancialModal.posNameText)}: ${posInfo?.posInfo?.name}`
    : '';

  const InfoTableComponent = infoTableMap[activeTab];

  return (
    <>
      <CashierModalUI.Wrapper isVisible={true}>
        <CashierModalUI.Body>
          <CashierModalUI.Header text={modalTitle} clearData={handleCashPayModalClosure} />

          <CashierModalUI.Content>
            <InfoTableComponent />
          </CashierModalUI.Content>

          <CashierModalUI.NavCol>
            <CashierModalUI.TabsWrapper>
              <CashierModalUI.Tab
                text={t(CashierTranslations.CashPayModal.depositText)}
                onClick={() => handleSetActiveTab('deposit')}
                isActive={activeTab === 'deposit'}
              />
              {isCashier() && (
                <CashierModalUI.Tab
                  text={t(CashierTranslations.CashPayModal.withdrawText)}
                  onClick={() => handleSetActiveTab('withdraw')}
                  isActive={activeTab === 'withdraw'}
                />
              )}
            </CashierModalUI.TabsWrapper>
            <OperationControl activeTab={activeTab} />
          </CashierModalUI.NavCol>
        </CashierModalUI.Body>
      </CashierModalUI.Wrapper>
    </>
  );
};

export default CashPayModal;
