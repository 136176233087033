import { useMemo } from 'react';
import { isError, isSuccess } from 'modules/casino/shared/utils/casinoUtils';
import { useAppSelector } from 'store';
import { gridLayoutSettingsSelectors } from '../gridLayoutSettingsSlice';

export const useGridSettingsLoadingState = () => {
  const loadingState = useAppSelector(gridLayoutSettingsSelectors.fetchStatus);

  const hasGridSettingsSuccess = useMemo(() => {
    return isSuccess(loadingState);
  }, [loadingState]);

  const hasGridSettingsError = useMemo(() => {
    return isError(loadingState);
  }, [loadingState]);

  return { hasGridSettingsSuccess, hasGridSettingsError };
};
