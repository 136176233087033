import styled from 'styled-components';
import { Breakpoints } from 'theme/Theme';

export const Wrapper = styled.div.attrs({
  className: 'd-flex-col-ac bg-cover',
})`
  padding-top: clamp(2.813rem, 2.379rem + 1.85vw, 3.563rem);
  padding-bottom: clamp(2.813rem, 2.379rem + 1.85vw, 3.563rem);

  @media (orientation: portrait) {
    width: 119.46vw;
    height: 119.46vw;
  }
  @media (orientation: landscape) {
    width: 64.46vw;
    height: 64.46vw;
  }

  @media (min-width: 460px) {
    width: clamp(28rem, 24.382rem + 12.58vw, 35.125rem);
    height: clamp(28rem, 24.382rem + 12.58vw, 35.125rem);
  }
  @media (max-width: ${Breakpoints.isDesktop}px) {
    margin-bottom: 15vw;
  }
`;
