import React, { useEffect } from 'react';
import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import cell01ComponentLoader from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell01/Cell01ComponentLoader';
import cell01StyleLoader from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell01/Cell01StyleLoader';
import StyledWrapper from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell01/styles/cell01Wrapper.styled';
import { LayoutProps } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/Layout_Wrapper';
import { ComponentNames } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/layoutWrapperConstants';
import { SharedComponents } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared';
import { useGetLayoutConfig } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/hooks/useGetLayoutConfig';
import { getGameCountImageSrc } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/utils/getGameCountImageSrc';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';

const Cell_1_Wrapper: React.FC<LayoutProps> = ({
  gameUniqueId,
  categoryId,
  categoryLayout,
  gameCellRef,
  finishedLoading,
  hasRestrictions,
  handleButtonClick,
  skipLazyLoad,
  number,
}) => {
  const game = useGameProps({ gameUniqueId, categoryId });
  const { layoutConfig, subtype } = useGetCategoryParams(categoryId, categoryLayout);
  const { hasNumbers, numbersVariant } = useGetCategoryParams(categoryId, categoryLayout);
  const { isData, hideDataCharacters } = useGetLayoutConfig(game);
  const { showInfoPage } = game;
  const customComponentType = isData ? (hideDataCharacters ? 'dataAlternative' : 'data') : 'default';
  const GameCellWrapper = cell01ComponentLoader(ComponentNames.GameCellWrapper, customComponentType);
  useEffect(() => {
    if (hasNumbers && number) {
      if (gameCellRef.current) {
        gameCellRef.current.style.zIndex = `${10000 + number}`;
        number > 9 && gameCellRef.current.classList.add('casino-grid-item--ml');
      }
    }
  }, []);

  return (
    <StyledWrapper id={gameUniqueId} className={'casino-grid-item--wrapper layout_1_wrapper'} $isData={isData}>
      <SharedComponents.GameCellMedia
        gameUniqueId={gameUniqueId}
        categoryId={categoryId}
        styledType={customComponentType}
        layoutStyleLoader={cell01StyleLoader}
        finishedLoading={finishedLoading}
        skipLazyLoad={skipLazyLoad}
        layoutGrid={layoutConfig.layoutGrid}
      />
      <GameCellWrapper
        gameUniqueId={gameUniqueId}
        categoryId={categoryId}
        layoutGrid={layoutConfig.layoutGrid}
        subType={subtype}
      />

      <SharedComponents.GameCellButtonsWrapper
        gameUniqueId={gameUniqueId}
        categoryId={categoryId}
        gameCellRef={gameCellRef}
        handleClick={handleButtonClick}
        hasRestrictions={hasRestrictions}
        showInfoPage={showInfoPage}
      />

      {hasNumbers && number && (
        <img src={getGameCountImageSrc(number, numbersVariant)} className={'casino-grid-item--counter'} />
      )}
      <SharedComponents.GameCellFavouritesButton
        gameUniqueId={gameUniqueId}
        gameCellRef={gameCellRef}
        categoryId={categoryId}
      />
    </StyledWrapper>
  );
};

export default Cell_1_Wrapper;
