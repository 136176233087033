import { useCallback, useState } from 'react';
import { gamificationPageThunks } from 'pages/gamificationPage/slice/gamificationPage.slice';
import { useAppDispatch } from 'store';

export const useShowTerms = (): {
  offerTerms?: { text?: string } | null;
  getTerms: (triggerTerms) => void;
  resetTerms: () => void;
} => {
  const dispatch = useAppDispatch();
  const [offerTerms, setOfferTerms] = useState<{ text?: string } | null>(null);

  const getTerms = useCallback(
    async (activeTerms) => {
      if (activeTerms?.groupConditionId) {
        const response = await dispatch(
          gamificationPageThunks.getGroupConditionTerms({
            url: `api/ews-bonuses/player/promotions/paragraphs/bonusGroups/${activeTerms.groupId}/conditions/${activeTerms.groupConditionId}`,
          }),
        );
        if (response.payload) {
          setOfferTerms(response.payload);
        }
      }
    },
    [dispatch],
  );

  const resetTerms = () => {
    setOfferTerms(null);
  };

  return { offerTerms, getTerms, resetTerms };
};
