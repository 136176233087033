import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { showQuickLoginForm } from 'pages/auth/login/slice/login.slice';
import { General } from 'shared/common/features/general/types/generalSlice.types';
import { useAppDispatch } from 'store';
import { triggerGTMEvent } from '../features/seo/gtm/GoogleTagManager';
import { LOGIN_FORM_TYPE_AUTH } from '../utils/common/helpersCommon';

type ReturnType = Record<General.NavButtons, () => void>;

const useNavUserActions = (): ReturnType => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const signIn = useCallback(() => {
    dispatch(showQuickLoginForm({ status: true, loginActionType: LOGIN_FORM_TYPE_AUTH }));
  }, [dispatch]);

  const registration = useCallback(() => {
    triggerGTMEvent({ eventKey: 'home-button-registration-start' });
    navigate('/registration');
  }, [navigate]);

  return { signIn, registration };
};

export default useNavUserActions;
