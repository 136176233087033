import React from 'react';
import { useProvidersLobby } from 'modules/casino/modules/lobby/providerLobby/hooks/useProvidersLobby';
import CategoryScroll from '../CategoryScroll';

const ProviderCategoryMobileBreadcrumbActions = () => {
  const { providerLobbyTags } = useProvidersLobby();

  return <CategoryScroll scrollTags={providerLobbyTags} />;
};

export default ProviderCategoryMobileBreadcrumbActions;
