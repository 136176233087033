import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { createCachedSelector } from 're-reselect';
import { CasinoLobbyType, DEFAULT_CASINO_ROUTE } from 'modules/casino/shared/constants';
import axiosInstance from 'modules/casino/shared/utils/common/axios-instance';
import { isExpiredIconsSprite, sortByRank } from 'modules/casino/shared/utils/common/helpersCommon';
import { createAbortThunk } from 'modules/casino/store/thunkCreators';
import userStorage from 'pages/auth/login/UserStorage';
import { setActiveCasinoCategory, setActiveCasinoVertical } from 'shared/common/sharedSlices/commonActions';
import { AppDispatch } from 'store';
import { RootState } from 'store/rootReducer';
import config from 'utils/config';
import {
  DEPEND_AUTH_MENU_ITEMS,
  LOBBY_NAV_TO_CATEGORY,
  MenuItemFixedPositionType,
  MenuItemType,
} from '../constants/casinoNavigationConstants';
import { CasinoNavigationTypes } from '../types/casinoNavigationTypes';
import { getIconRequestFinishedCollection, normalizeData } from '../utils/casinoNavigationUtils';

export const casinoNavigationThunks: CasinoNavigationTypes.NavigationThunks = {
  fetchMenuItems: createAbortThunk(
    'casinoNavigation/fetchMenuItems',
    async ({ casinoType, apiUri }, { source, getState }) => {
      const language = getState().common.settings.language;
      const cacheKey = `${casinoType}_${language}`;

      const fallbackUri = `${config.API_URL}/api/gaming/public/categories/menu-categories?rootType=${casinoType}`;
      const uri = apiUri ? `${config.API_URL}${apiUri}` : fallbackUri;

      const response = await axiosInstance.get<CasinoNavigationTypes.MenuItem[]>(uri, {
        data: null,
        cancelToken: source.token,
      });

      const sortedData = sortByRank([...response.data]);

      return { cacheKey, data: sortedData };
    },
  ),
};

const { fetchMenuItems } = casinoNavigationThunks;

const initialState: CasinoNavigationTypes.InitialState = {
  menuItemsCollection: {},
  activeCategory: '',
  previousActiveCategory: '',
  activeCasinoVertical: {
    casinoType: CasinoLobbyType.Default,
    casinoRoute: DEFAULT_CASINO_ROUTE,
  },
};

const casinoNavigation = createSlice({
  name: 'casinoNavigation',
  initialState,
  reducers: {
    setIsIconRequestFinished(state, action) {
      const { iconSrc, cacheKey } = action.payload;
      const updatedItems = getIconRequestFinishedCollection({
        menuItems: state.menuItemsCollection[cacheKey],
        iconSrc,
      });

      if (updatedItems.length) {
        state.menuItemsCollection[cacheKey] = updatedItems;
      }
    },
    setPreviousActiveCategory(state, action: PayloadAction<string>) {
      state.previousActiveCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveCasinoCategory, (state, action: PayloadAction<string>) => {
        state.activeCategory = action.payload;
      })
      .addCase(setActiveCasinoVertical, (state, action: PayloadAction<CasinoNavigationTypes.ActiveVerticalType>) => {
        const { casinoType, casinoRoute } = action.payload;
        state.activeCasinoVertical = {
          casinoType,
          casinoRoute,
        };
      })
      .addCase(
        fetchMenuItems.fulfilled,
        (state, action: PayloadAction<CasinoNavigationTypes.MenuItemsFetchPayload>) => {
          const { cacheKey, data } = action.payload;

          if (data?.length) {
            state.menuItemsCollection[cacheKey] = normalizeData(data);
          }
        },
      );
  },
});

export const { setIsIconRequestFinished, setPreviousActiveCategory } = casinoNavigation.actions;

const selectMenuItemsCollection = (state: RootState) => state.casino.casinoNavigation.menuItemsCollection;
const selectActiveCategory = (state: RootState) => state.casino.casinoNavigation.activeCategory;
const selectActiveCasinoVertical = (state: RootState) => state.casino.casinoNavigation.activeCasinoVertical;
const selectIsCategoryActive = (navToCategory?: string) => (state: RootState) =>
  state.casino.casinoNavigation.activeCategory === navToCategory;
const selectMenuItems = (state: RootState, cacheKey: string): CasinoNavigationTypes.MenuItem[] =>
  state.casino.casinoNavigation.menuItemsCollection[cacheKey] || [];

const selectAllMenuItems = createSelector(
  selectMenuItemsCollection,
  (menuItemsCollection): CasinoNavigationTypes.MenuItem[] => {
    const casinoMenuItemsCollection = (menuItemsCollection && Object.values(menuItemsCollection)) || [];

    return casinoMenuItemsCollection?.reduce((acc, menuItems) => acc.concat(menuItems), []);
  },
);

const selectMenuItemByAlias = createSelector(
  (state: RootState, cacheKey: string) => state.casino.casinoNavigation.menuItemsCollection[cacheKey],
  (state: RootState, cacheKey: string, alias: string | undefined) => alias,
  (menuItems, alias): CasinoNavigationTypes.MenuItem | undefined => {
    if (menuItems) {
      return menuItems?.find((item) => item.alias === alias);
    }
  },
);

const selectFilteredMenuItems = createSelector(
  selectMenuItems,
  (state: RootState, cacheKey: string, isAuthenticated: boolean | null): boolean | null => isAuthenticated,
  (menuItems, isAuthenticated): CasinoNavigationTypes.MenuItem[] =>
    isAuthenticated
      ? menuItems
      : menuItems?.filter((item) => item.isPublic && !DEPEND_AUTH_MENU_ITEMS.includes(item.menuType)),
);

const selectPublicMenuItemByAlias = createSelector(
  selectFilteredMenuItems,
  (state: RootState, cacheKey: string, isAuthenticated: boolean | null, alias: string | undefined) => alias,
  (menuItems, alias): CasinoNavigationTypes.MenuItem | undefined => {
    if (menuItems) {
      return menuItems?.find((item) => item.alias === alias);
    }
  },
);

const selectMenuItemsByPosition = createCachedSelector(
  selectFilteredMenuItems,
  (filteredMenuItems): CasinoNavigationTypes.MenuItemsByPositionType =>
    filteredMenuItems?.reduce(
      (acc, item) => {
        if (item.position === MenuItemFixedPositionType.LEFT) {
          acc['left'].push(item);
        }

        if (item.position === MenuItemFixedPositionType.RIGHT) {
          acc['right'].push(item);
        }

        if (item.position === MenuItemFixedPositionType.CENTER) {
          acc['center'].push(item);
        }

        return acc;
      },
      { left: [], right: [], center: [] } as CasinoNavigationTypes.MenuItemsByPositionType,
    ),
)((_, key, isAuthenticated) => `${key}_${isAuthenticated ? '1' : '0'}`);

const selectSideBarMenuItems = createCachedSelector(
  selectFilteredMenuItems,
  (filteredMenuItems): CasinoNavigationTypes.SidebarMenuItems =>
    filteredMenuItems?.reduce(
      (acc, item) => {
        const filter = {
          [MenuItemType.FAVOURITES]: 'favMenuItem',
          [MenuItemType.SEARCH]: 'searchMenuItem',
        };

        if (filter[item.menuType]) {
          acc[filter[item.menuType]] = item;
        } else {
          acc['menuItems'].push(item);
        }

        return acc;
      },
      { menuItems: [], favMenuItem: null, searchMenuItem: null } as CasinoNavigationTypes.SidebarMenuItems,
    ),
)((_, key, isAuthenticated) => `${key}_${isAuthenticated ? '1' : '0'}`);

const selectShowIsCollectionLoaded = createSelector(selectFilteredMenuItems, (filteredMenuItems): boolean =>
  filteredMenuItems?.every((item) => item.isIconRequestFinished),
);

const selectMenuItemByType = createSelector(
  (state: RootState, cacheKey: string) => state.casino.casinoNavigation.menuItemsCollection[cacheKey],
  (state: RootState, cacheKey: string, menuType: string) => menuType,
  (menuItems, menuType): CasinoNavigationTypes.MenuItem | undefined => {
    if (menuItems) {
      return menuItems?.find((item) => item.menuType === menuType);
    }
  },
);

const selectPreviousActiveCategory = (state: RootState): string => state.casino.casinoNavigation.previousActiveCategory;

export const casinoNavigationSelectors = {
  activeCategory: selectActiveCategory,
  activeCasinoVertical: selectActiveCasinoVertical,
  isCategoryActive: selectIsCategoryActive,
  menuItemsCollection: selectMenuItemsCollection,
  menuItemByAlias: selectMenuItemByAlias,
  publicMenuItemByAlias: selectPublicMenuItemByAlias,
  menuItems: selectMenuItems,
  menuItemsByPosition: selectMenuItemsByPosition,
  allMenuItems: selectAllMenuItems,
  showIsCollectionLoaded: selectShowIsCollectionLoaded,
  menuItemByType: selectMenuItemByType,
  previousActiveCategory: selectPreviousActiveCategory,
  sideBarMenuItems: selectSideBarMenuItems,
};

export const setMenuItemWithShowProviders =
  (casinoType: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const language = getState().common.settings.language;
    const menuItemsCollection = getState().casino.casinoNavigation.menuItemsCollection;
    const menuItems = menuItemsCollection[`${casinoType}_${language}`];

    if (menuItems) {
      const menuItemWithShowProviders = menuItems?.find(
        (item) => item.showProviders && item.navToCategory === LOBBY_NAV_TO_CATEGORY,
      );

      if (menuItemWithShowProviders) {
        dispatch(setActiveCasinoCategory(menuItemWithShowProviders.navToCategory));
      }
    }
  };

export const setCasinoActiveCategory = (categoryId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
  const activeCategoryId = getState().casino.casinoNavigation.activeCategory;

  if (categoryId === activeCategoryId) {
    return;
  }

  dispatch(setActiveCasinoCategory(categoryId));
};

export const setActiveCasinoTypeAndRoute =
  ({ casinoType, casinoRoute }: CasinoNavigationTypes.ActiveVerticalType) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const activeCasinoType = getState().casino.casinoNavigation.activeCasinoVertical.casinoType;

    if (casinoType === activeCasinoType) return;
    dispatch(setActiveCasinoVertical({ casinoType, casinoRoute }));
  };

export const getSVGCasinoMenuSprites = () => async () => {
  try {
    const { svgSprites, date } = userStorage.getSVGSprites();
    if (svgSprites && !isExpiredIconsSprite(date)) {
      return svgSprites;
    }

    const response = await axios.get(
      `${config.CDN_URL}/gaming-content/game-providers-new/logos/sprites-casino-icons.svg`,
      {
        data: null,
        headers: { 'Content-type': 'image/svg+xml' },
      },
    );

    if (response.data) {
      userStorage.setSVGSprites(response.data);
      return response.data;
    }
  } catch (e) {
    return null;
  }
};

export const { reducer: casinoNavigationReducer } = casinoNavigation;
