import styled from 'styled-components';
import { Breakpoints } from 'theme/Theme';

const StyledGridHeader = styled.div.attrs({ className: `layout-02-grid-header prm-grid-header` })`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 10px;
  @media (max-width: ${Breakpoints.isDesktop}px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (min-width: ${Breakpoints.isDesktop}px) {
    margin-top: 30px;
    margin-bottom: 15px;
  }
  font-size: clamp(1rem, 0.856rem + 0.62vw, 1.25rem);
  font-weight: 700;
  /* fill: currentColor; */
  transition: all 0.15s;
`;

export default StyledGridHeader;
