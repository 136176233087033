import React from 'react';
import clsx from 'clsx';
import { Flex } from 'modules/casino/shared/components/layout';
import JackpotLevelsWrapper from 'modules/casino/shared/features/jackpots/components/JackpotLevelsWrapper';
import JackpotLogo from 'modules/casino/shared/features/jackpots/components/JackpotLogo';
import { useSetJackpotLevelsPolling } from 'modules/casino/shared/features/jackpots/hooks/useSetJackpotLevelsPolling';
import useCategoryJackpot from 'modules/casino/shared/hooks/useCategoryJackpot';
import { useSetRefsLoaded } from 'modules/casino/shared/hooks/useSetRefsLoaded';
import { JackpotsWidgetCellButton, JackpotsWidgetCellLogo, JackpotsWidgetCellWrapper } from '../components';

interface TextProps {
  jackpotBannerId: string;
  buttonLink: string;
}

const JackpotBanner: React.FC<TextProps> = ({ jackpotBannerId, buttonLink }) => {
  useSetJackpotLevelsPolling({});
  const { jackpotDetails, jackpotId } = useCategoryJackpot(jackpotBannerId);
  const { refLoaded, setRefs, ref } = useSetRefsLoaded();

  if (!jackpotDetails) {
    return <></>;
  }
  const backgroundUrl = jackpotDetails?.bannerBackgroundLocation || '';
  const logoUrl = jackpotDetails?.bannerLogoLocation || '';
  const jackpotLayoutClass = (jackpotDetails && jackpotDetails?.template) || '';
  const jackpotCellClass = clsx('jackpot-widget-cell', jackpotDetails?.template || '', jackpotLayoutClass);
  return (
    <div ref={setRefs} className={'h-100'}>
      {refLoaded && (
        <JackpotsWidgetCellWrapper
          jackpotWidgetCellRef={ref}
          backgroundUrl={backgroundUrl}
          classNames={jackpotCellClass}
        >
          <Flex.ColCenter>
            <JackpotLogo logoUrl={logoUrl} />
            <JackpotsWidgetCellLogo providerId={jackpotDetails?.gameProviderId || ''} />
            <JackpotsWidgetCellButton link={buttonLink} buttonSize="sm" />
          </Flex.ColCenter>
          <Flex.ColCenter className={'jackpot-section__container '}>
            <JackpotLevelsWrapper jackpotId={jackpotId} jackpot={jackpotDetails} fromBanner />
          </Flex.ColCenter>
        </JackpotsWidgetCellWrapper>
      )}
    </div>
  );
};

export default JackpotBanner;
