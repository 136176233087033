import { useNavigate } from 'react-router-dom';
import { Text } from 'modules/casino/shared/components/text';
import { UI } from 'modules/casino/shared/components/UI';
import { CasinoLobbyType } from 'modules/casino/shared/constants';
import useGeneralCasinoType from 'modules/casino/shared/hooks/useGeneralCasinoType';
import { useAppSelector } from 'store';
import { useProviderDataByAlias } from '../../../casinoGameProviders/hooks/useProviderDataByAlias';
import { gameProvidersSelectors } from '../../../casinoGameProviders/slice/casinoGameProvidersSlice';

const ProviderCategoryMobileBreadcrumbTitle = () => {
  const navigate = useNavigate();
  const activeProviderAlias = useAppSelector(gameProvidersSelectors.activeProvider);
  const { casinoType } = useGeneralCasinoType();
  const { gamesCount, providerName } = useProviderDataByAlias(activeProviderAlias);
  const providerGamesCount = casinoType === CasinoLobbyType.LiveCasino ? gamesCount.liveGames : gamesCount.otherGames;

  const handleBackButton = () => {
    window.scrollBy(0, 0.1);
    navigate(-1);
  };

  return (
    <>
      <UI.ButtonBack icon={`arrow`} onClickHandler={handleBackButton} />
      <div className={`mobile_layout-category-header__title`}>
        <Text text={providerName} type={'h1'} />
        {providerGamesCount ? ` (${providerGamesCount})` : ''}
      </div>
    </>
  );
};

export default ProviderCategoryMobileBreadcrumbTitle;
