import { ButtonsTypes } from './types/ButtonsTypes';

// Buttons
export const Buttons: ButtonsTypes.State = {
  ActionSuccessPrimaryWithHover: {
    color: 'var(--white)',
    bgStart: 'var(--success)',
    bgEnd: 'var(--success-dark)',
    bgStartHover: '#a0cc06',
    bgEndHover: '#016123',
    bg: 'var(--success-dark)',
    bgHover: '#00772a',
  },
  PrimaryAction: {
    bgStart: 'var(--success)',
    bgEnd: 'var(--success-dark)',
    bg: 'var(--success-dark)',
  },
};
