import { memo, useCallback, useRef } from 'react';
import useAtWidget from 'shared/common/features/integrations/gamification/hooks/useAtWidget';
import { useGamificationWidgetConfig } from 'shared/common/features/integrations/gamification/hooks/useGamificationWidgetConfig';
import { showMyProfileModal } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppDispatch } from 'store';

const route = {
  challenges: '/challenges/all',
};
const ClubChallangesWidget = ({ itemKey }) => {
  const myChallengesRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { route: gamificationRoute } = useGamificationWidgetConfig();

  const closeMyProfile = useCallback(() => {
    window.widgetGamification.navigate({ fullPath: `${gamificationRoute}${route[itemKey]}`, invokeCallBack: true });
    dispatch(showMyProfileModal(false));
  }, [dispatch, gamificationRoute, itemKey]);

  useAtWidget({
    id: 'myChallenges',
    containerId: 'myChallenges',
    props: {
      forceCompactLayout: true,
      onClick: closeMyProfile,
    },
    onLoadContainerEl: myChallengesRef,
  });

  return <div style={{ overflow: 'hidden', marginBottom: '100px' }} id="myChallenges" ref={myChallengesRef} />;
};

export default memo(ClubChallangesWidget);
