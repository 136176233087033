import { useEffect } from 'react';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { actionButtonEnums } from '../cashierModalUI/actionButtonsMock';

export const useTicketState = (ticketInfo, setActiveButton, clickCount, setActionButtonClicked) => {
  const showDuplicateTicketButton = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.RETAIL_HEADER_BUTTONS_VISIBLE,
    deviceLayout: 'general',
  })?.isDuplicateButtonActive;
  const isMagnumBetRo = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.IS_MAGNUM_BET_RO,
    deviceLayout: 'general',
  });
  useEffect(() => {
    if (ticketInfo?.possibleActions) {
      const possibleActions = ticketInfo.possibleActions;

      const activeButtons: actionButtonEnums[] = [];

      possibleActions.forEach((action: string) => {
        switch (action) {
          case 'PAY_TICKET':
            if (clickCount === 1 && isMagnumBetRo) {
              setActiveButton([]);
              setActionButtonClicked(null);
            } else {
              activeButtons.push(actionButtonEnums.payTicketButton);
            }

            break;
          case 'CANCEL_TICKET':
            activeButtons.push(actionButtonEnums.cancelTicket);
            break;
          case 'DUPLICATE_TICKET':
            showDuplicateTicketButton && activeButtons.push(actionButtonEnums.duplicateTicket);
            break;

          case 'CASHOUT':
            activeButtons.push(actionButtonEnums.cashoutButton);
            break;
          default:
            break;
        }
      });

      if (activeButtons.length > 0) {
        setActiveButton(activeButtons);
      } else {
        setActiveButton([]);
      }
    }
  }, [ticketInfo, clickCount]);
};
