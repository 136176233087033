import { Sport } from 'utils/common/types/sportTypes';

export declare module FavouritesTypes {
  type Layout = 'layout_1' | 'layout_2';
  type FavTypes = keyof typeof favTypesCSSMap;

  type FavOrderTypes = 'first' | 'last';

  type Variant = 'fav' | 'pin';

  type Props = {
    variant?: Variant;
    componentLocation?: FavTypes;
    componentOrder?: FavOrderTypes;
    favouriteType: FavouritesSection;
    favouriteId: number;
    favouriteParentId?: number;
    className?: string;
    isLive?: boolean;
    activeEventId?: boolean;
    sportType?: Sport.SportType;
    eventMatchId?: number;
  };

  type FavouritesSection =
    | 'sportIds'
    | 'sportEventIds'
    | 'marketTemplatesPerSport'
    | 'drawer'
    | 'competitorIds'
    | 'liveSportEventIds';
  type FavouritesSectionRequest = 'sportId' | 'sportEventId' | 'marketTemplateId';

  type MarketTemplatesPerSport = {
    sportId: number;
    marketTemplateIds: number[];
  };

  type Favourites = {
    playerId?: string | null;
    sportIds?: number[] | null;
    sportEventIds?: number[] | null;
    liveSportEventIds?: number[] | null;
    marketTemplatesPerSport?: MarketTemplatesPerSport[];
  };

  type FavouritesRequest = {
    userId?: string;
    sportId?: number | null;
    sportEventId?: number | null;
    marketTemplateId?: number | null;
  };
}

export const FavouritesSection: Record<FavouritesTypes.FavouritesSection, FavouritesTypes.FavouritesSection> = {
  sportIds: 'sportIds',
  sportEventIds: 'sportEventIds',
  liveSportEventIds: 'liveSportEventIds',
  marketTemplatesPerSport: 'marketTemplatesPerSport',
  drawer: 'drawer',
  competitorIds: 'competitorIds',
};

export const cssNS = 'user-fav';
export const favTypesCSSMap = {
  drawer: {
    iconClass: `${cssNS}-drawer`,
    activeIconClass: `${cssNS}-drawer--active`,
  },
  header: {
    iconClass: `${cssNS}-header`,
    activeIconClass: `${cssNS}-header--active`,
  },
  event: {
    iconClass: `${cssNS}-event`,
    activeIconClass: `${cssNS}-event--active`,
  },
  widget: {
    iconClass: `${cssNS}-widget`,
    activeIconClass: `${cssNS}-widget--active`,
  },
} as const;
