import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppDispatch } from 'store';
import type { RootState } from 'store/rootReducer';
import { RgbControllerInfo, RgbControllerTypes } from '../../../types/ssbt.types';

const initialState: RgbControllerTypes = {
  rgbControllerInfo: null,
  retailActiveSportId: 0,
};
const rgbSlice = createSlice({
  name: 'rgbSlice',
  initialState,
  reducers: {
    getRgbControllerInfo(state, action: PayloadAction<RgbControllerInfo>) {
      state.rgbControllerInfo = action.payload;
    },
    setRetailActiveSportId(state, action) {
      state.retailActiveSportId = action.payload;
    },
  },
});
export const { getRgbControllerInfo, setRetailActiveSportId } = rgbSlice.actions;
export default rgbSlice.reducer;
export const selectRgbControllerData = {
  rgbControllerInfo: (state: RootState): RgbControllerInfo | null => state.retail.rgbController.rgbControllerInfo,
  retailActiveSportId: (state: RootState) => state.retail.rgbController.retailActiveSportId,
};
export const pollRgbcontroller =
  () =>
  async (dispatch: AppDispatch): Promise<void> => {
    const url = 'http://localhost:10005/rgb_event.json';
    const response = await axios.post(url);
    dispatch(getRgbControllerInfo(response.data));
  };
