import React from 'react';
import clsx from 'clsx';
import useJackpotCellBackground from '../../../hooks/useJackpotCellBackground';

interface Props {
  children: React.ReactNode;
  backgroundUrl: string;
  jackpotWidgetCellRef: React.RefObject<HTMLDivElement>;
  classNames?: string;
}

const JackpotsWidgetCellWrapper: React.FC<Props> = ({ children, backgroundUrl, jackpotWidgetCellRef, classNames }) => {
  const backgroundImage = useJackpotCellBackground({
    backgroundUrl,
    jackpotWidgetCellRef,
  });
  const setWrapperStyle = {
    backgroundImage: backgroundImage && backgroundImage,
  };
  const wrapperClass = clsx(classNames && classNames);

  return (
    <div className={wrapperClass} style={setWrapperStyle}>
      {children}
    </div>
  );
};

JackpotsWidgetCellWrapper.displayName = 'JackpotsWidgetCellWrapper';
export default JackpotsWidgetCellWrapper;
