import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { Sport } from 'utils/common/types/sportTypes';
import config from 'utils/config';
import { CheckTicket, TicketInfoType, TicketInfoThunk } from '../../modules/ssbt/types/ssbt.types';

const initialState: TicketInfoType = {
  ticketInfoStatus: 'idle',
  ticketInfo: null,
  ticketInfoErrorMessage: '',
  ticketIdForDuplicateInBetSlip: '',
};

export const ticketInfoThunk: TicketInfoThunk = {
  checkTicket: createAbortThunk(`terminal/checkTicket`, async ({ ticketId }) => {
    const url = `${config.API_URL}/api/pretail/agent/v2/ticket/${ticketId}`;
    const response = await axiosInstance.get(url);
    return response.data;
  }),
};
const ticketInfoThunkStat = ticketInfoThunk.checkTicket;
const ticketSlice = createSlice({
  name: 'ticketSlice',
  initialState,
  reducers: {
    resetTicketInfoState: () => initialState,
    seTicketIdForDuplicateInBetSlip: (state) => {
      state.ticketIdForDuplicateInBetSlip = state.ticketInfo?.ticket?.ticketId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ticketInfoThunkStat.fulfilled, (state, action: PayloadAction<TicketInfoType>) => {
      state.ticketInfoStatus = 'succeeded';
      state.ticketInfo = action.payload as unknown as CheckTicket;
    });
    builder.addCase(ticketInfoThunkStat.pending, (state) => {
      state.ticketInfoStatus = 'pending';
    });
    builder.addCase(ticketInfoThunkStat.rejected, (state, action) => {
      state.ticketInfoErrorMessage = action.payload;
      state.ticketInfoStatus = 'failure';
      state.ticketInfo = null;
    });
  },
});
export const { resetTicketInfoState, seTicketIdForDuplicateInBetSlip } = ticketSlice.actions;
export default ticketSlice.reducer;
export const selectTicketData = {
  ticketInfo: (state: RootState) => state.retail.ticket.ticketInfo,
  ticketInfoStatus: (state: RootState): Sport.StateStatus => state.retail.ticket.ticketInfoStatus,
  ticketInfoErrorMessage: (state: RootState) => state.retail.ticket.ticketInfoErrorMessage,
  ticketIdForDuplicateInBetSlip: (state: RootState) => state.retail.ticket.ticketIdForDuplicateInBetSlip,
};
