import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isIOS } from 'react-device-detect';
import { setNetAppHeader } from 'shared/common/features/general/slice/general.slice';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from '../../../store/thunkCreators';
import axiosInstance from '../../../utils/common/axios-instance';
import { getBusinessUnit, getDeviceType, getError } from '../../../utils/common/helpersCommon';
import config from '../../../utils/config';
import { PlaytechPokerLoginTypes } from '../types/playtechPokerLogin.types';

export const PlaytechPokerloginThunks: PlaytechPokerLoginTypes.LoginThunks = {
  fetchFormFields: createAbortThunk('playtechPoker/fetchFormFields', async (_, { rejectWithValue, dispatch }) => {
    try {
      const url = `${config.API_URL}/api/ews-crm/public/cms/player-content?key=login_form`;
      const response = await axiosInstance.get(url);

      if (response.headers?.['x-platform-hash-netapp']) {
        dispatch(setNetAppHeader(response.headers['x-platform-hash-netapp']));
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(getError.message(err));
    }
  }),
  fetchPlaytechPokerDefCode: createAbortThunk(
    'playtechPoker/fetchPlaytechPokerDefCode',
    async (_, { rejectWithValue }) => {
      try {
        const headers = {
          'X-Platform-Device': getDeviceType(),
          'X-Platform-Origin': getBusinessUnit(),
          'X-Platform-Application': isIOS ? `ios` : `android`,
        };

        const url = `${config.API_URL}/api/gaming/player/playtechPokerDefCode`;
        const response = await axiosInstance.get(url, { headers });

        return response.data;
      } catch (err) {
        return rejectWithValue(getError.default());
      }
    },
  ),
};

const { fetchFormFields, fetchPlaytechPokerDefCode } = PlaytechPokerloginThunks;

const initialState: PlaytechPokerLoginTypes.State = {
  hideLoginForm: false,
  isFormLoading: false,
  fetchFormFieldsError: null,
  fields: null,
  loginSuccess: false,
  showUserNotVerifiedMsg: false,
  playtechPokerDefCode: {
    defCode: '',
    userName: '',
  },
  defCodeError: null,
  openFromPoker: false,
};

const playtechPokerReducer = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoginSuccess(state, action) {
      state.loginSuccess = action.payload;
    },
    setHideLoginForm(state, action) {
      state.hideLoginForm = action.payload;
    },
    resetFormFieldsData(state) {
      state.fields = null;
      state.fetchFormFieldsError = null;
    },
    clearDefCodeError(state) {
      state.defCodeError = null;
    },
    setShowUserNotVerifiedMsg(state, action: PayloadAction<boolean>) {
      state.showUserNotVerifiedMsg = action.payload;
    },
    setOpenFromPoker(state, action: PayloadAction<boolean>) {
      state.openFromPoker = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormFields.pending, (state) => {
        state.isFormLoading = true;
      })
      .addCase(fetchFormFields.fulfilled, (state, action) => {
        state.isFormLoading = false;
        state.fields = action.payload;
      })
      .addCase(fetchFormFields.rejected, (state, action) => {
        state.isFormLoading = false;
        state.fetchFormFieldsError = action.payload;
      });
    builder
      .addCase(fetchPlaytechPokerDefCode.fulfilled, (state, action) => {
        state.playtechPokerDefCode = action.payload;
        state.hideLoginForm = true;
      })
      .addCase(fetchPlaytechPokerDefCode.rejected, (state, action) => {
        state.defCodeError = action.payload;
        state.loginSuccess = false;
      });
  },
});

export const {
  resetFormFieldsData,
  setHideLoginForm,
  clearDefCodeError,
  setShowUserNotVerifiedMsg,
  setOpenFromPoker,
  setLoginSuccess,
} = playtechPokerReducer.actions;

export default playtechPokerReducer.reducer;

export const playtechPokerSelectors = {
  hideLoginForm: (state: RootState): boolean => state.playtechPoker.hideLoginForm,
  loginFormFields: (state: RootState): PlaytechPokerLoginTypes.LoginFormInputTypes[] | null =>
    state.playtechPoker.fields,
  isFormLoading: (state: RootState): boolean => state.playtechPoker.isFormLoading,
  fetchFormFieldsError: (state: RootState): null | string | undefined => state.playtechPoker.fetchFormFieldsError,
  loginSuccess: (state: RootState): boolean => state.playtechPoker.loginSuccess,
  showUserNotVerifiedMsg: (state: RootState): boolean => state.playtechPoker.showUserNotVerifiedMsg,
  playtechPokerDefCodeData: (state: RootState): PlaytechPokerLoginTypes.PlaytechPokerDefCodeData =>
    state.playtechPoker.playtechPokerDefCode,
  defCodeError: (state: RootState): null | string | undefined => state.playtechPoker.defCodeError,
  openFromPoker: (state: RootState): boolean => state.playtechPoker.openFromPoker,
};
