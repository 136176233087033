import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { useAppSelector } from 'store';

const useCasinoWidgetConfig = () => {
  const isLayoutConfigLoaded = useAppSelector(selectGeneral.isLayoutConfigLoaded);
  const casinoWidgetConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.CASINO_WIDGET_CONFIG,
    deviceLayout: 'general',
  });

  return isLayoutConfigLoaded && casinoWidgetConfig;
};

export default useCasinoWidgetConfig;
