import { OffersTabLayout } from '../../offersTab';
import { CaptainUpTabLayout01, CaptainUpTabLayout02 } from '../components/CaptainUpTabLayout';
import { ClubTabsTypes } from '../types/clubTabs.types';

const OffersTabSuccessMap: Record<OffersTabLayout, React.FC<ClubTabsTypes.LayoutKeyMapProps>> = {
  layout_1(props) {
    return <CaptainUpTabLayout01 {...props} />;
  },
  layout_2(props) {
    return <CaptainUpTabLayout02 {...props} />;
  },
};

export const CaptainUpTabs: React.FC<ClubTabsTypes.LayoutKeyMapProps> = (props) => {
  const ComponentLayout = OffersTabSuccessMap[props.layout || 'layout_1'];

  if (!ComponentLayout) return null;
  return <>{<ComponentLayout {...props} />}</>;
};
