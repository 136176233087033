import { useLayoutEffect } from 'react';
import { setShowCarousel } from 'modules/casino/modules/carousel/slice/casinoCarouselSlice';
import { useAppDispatch } from 'store';

type StepsState = [boolean, boolean];

const defaultSteps: StepsState = [true, true];

/**
 * A hook to show/hide casino carousel
 *
 * @param steps tuple of booleans, first element is used while component is being rendered, the second one while component is being removed from the DOM.
 * Depending on these steps carousel will be shown or hidden.
 *
 *
 * @example The below example explains how to hide the carousel only for mobile screens.
 *
 * export const Component = () => {
 *   const isMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isMobile}px)` });
 *   useShowCarousel([!isMobile, false]);
 *   return <div>Foo</div>
 * }
 */
export const useShowCarousel = (steps = defaultSteps): void => {
  const dispatch = useAppDispatch();
  const firstStep = steps[0];
  const secondStep = steps[1];

  useLayoutEffect(() => {
    dispatch(setShowCarousel(firstStep));
    return () => {
      dispatch(setShowCarousel(secondStep));
    };
  }, [dispatch, firstStep, secondStep]);
};
