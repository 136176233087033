import { useRef, useLayoutEffect, useCallback, MutableRefObject } from 'react';
import { selectAppHeader, setTopNavHeaderStatus } from 'shared/common/features/appHeader';
import { useAppDispatch, useAppSelector } from 'store';

type ScrollPosition = {
  top: number;
  bottom: number;
  height: number;
};

const getScrollPosition = (element: undefined | MutableRefObject<HTMLElement> = undefined): ScrollPosition => {
  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return { bottom: position.bottom, top: position.top, height: position.height };
  // return { bottom: position.bottom, top: position.top, height: Math.trunc(position.bottom - position.top) };
};

type Direction = 'up' | 'down';

interface Props {
  effectFunc: (dir: Direction, scrollPosition: ScrollPosition, isTop?: boolean) => void;
  delay?: number;
  enableHook?: boolean;
  element?: MutableRefObject<HTMLElement>;
  minimumScrollDistance?: number;
}

export const useScrollPosition = ({
  effectFunc,
  delay = 100,
  element,
  enableHook = true,
  minimumScrollDistance = 5,
}: Props) => {
  const prevPos = useRef(getScrollPosition(element));
  const topNavHeaderStatus = useAppSelector(selectAppHeader.topNavHeaderStatus);
  const dispatch = useAppDispatch();

  const callback = useCallback(
    // throttle(() => {
    () => {
      const currPos = getScrollPosition(element);

      if (currPos.height === prevPos.current.height) {
        if (currPos.bottom - prevPos.current.bottom > minimumScrollDistance) {
          //   console.log('up');
          effectFunc('up', currPos, false);
        }

        if (currPos.bottom - prevPos.current.bottom < -minimumScrollDistance) {
          //   console.log('down');
          effectFunc('down', currPos, false);
        }
      }

      if (window.pageYOffset < 300) {
        effectFunc('up', currPos, true);
        if (topNavHeaderStatus != 'fixed-collapsed' && topNavHeaderStatus === 'collapsed') {
          dispatch(setTopNavHeaderStatus('visible'));
        }
      }

      prevPos.current = currPos;
    },
    // }, delay),
    [effectFunc, delay, element],
  );

  useLayoutEffect(() => {
    if (!enableHook) {
      return;
    }

    window.addEventListener('scroll', callback, { passive: true });
    return () => {
      window.removeEventListener('scroll', callback);
    };
  }, [callback, enableHook, window.pageYOffset]);
};

// const throttle = (func: () => void, delay: number) => {
//   let timeout = 0;
//   let debounceTimeout = 0;
//   let isDebouncing = false;
//   return () => {
//     if (!timeout) {
//       timeout = window.setTimeout(
//         () => {
//           func();
//           isDebouncing = true;
//           timeout = 0;
//         },
//         isDebouncing ? delay : 0,
//       );
//     }

//     if (isDebouncing) {
//       clearTimeout(debounceTimeout);
//       debounceTimeout = window.setTimeout(() => {
//         isDebouncing = false;
//       }, delay);
//     }
//   };
// };
