import { css } from 'styled-components';
const iconCircleCss = css`
  width: 10px;
  height: 10px;
  margin-left: 0;
`;
const iconStyleType1 = (isCircle, isAccaBoost, showText, isCircleIcon) => css`
  ${isCircle &&
  !isCircleIcon &&
  `
      width:10px;
      height:10px;
      `}
  ${!isCircle &&
  !isCircleIcon &&
  `
  width: ${isAccaBoost ? '22px' : '14px'};
  height: ${isAccaBoost ? '14px' : '16px'};
`}

${showText && `margin-left: 0.25rem;`}
${isCircleIcon && iconCircleCss}
`;

export const getIconStyle = (isCircle, isAccaBoost, showText, isCircleIcon) => {
  const iconMap = {
    layout_1: iconStyleType1(isCircle, isAccaBoost, showText, isCircleIcon),
    layout_2: iconStyleType1(isCircle, isAccaBoost, showText, isCircleIcon),
  };

  return css`
    ${({ theme }) => iconMap[theme.LayoutConfigs?.sport?.sportBtns]}
  `;
};
// export const getIconStyle = (layout, isCircle, isAccaBoost, showText, isCircleIcon) => {
//   const iconMap = {
//     layout_1: iconStyleType1(isCircle, isAccaBoost, showText, isCircleIcon),
//     layout_2: iconStyleType1(isCircle, isAccaBoost, showText, isCircleIcon),
//   };

//   return iconMap[layout];
// };
