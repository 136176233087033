import ActionIcon from './ActionIcon';
import { SCActionsWrapper as ActionsWrapper } from './ActionsWrapper.styled';
import Body from './Body';
import Button from './Button';
import ButtonsWrapper from './ButtonsWrapper';
import Close from './Close';
import ContentWrapper from './ContentWrapper';
import Header from './Header';
import Input from './Input';
import InputsWrapper from './InputsWrapper';
import Link from './Link';
import Status from './Status';
import StatusCentered from './StatusCentered';
import SubHeader from './SubHeader';
import Tile from './Tile';
import TilesWrapper from './TilesWrapper';
import Wrapper from './Wrapper';

export const TerminalModalUI = {
  ActionIcon: ActionIcon,
  ActionsWrapper: ActionsWrapper,
  Body: Body,
  Button: Button,
  ButtonsWrapper: ButtonsWrapper,
  Close: Close,
  ContentWrapper: ContentWrapper,
  Header: Header,
  Input: Input,
  InputsWrapper: InputsWrapper,
  Link: Link,
  Status: Status,
  StatusCentered: StatusCentered,
  SubHeader: SubHeader,
  Tile: Tile,
  TilesWrapper: TilesWrapper,
  Wrapper: Wrapper,
};
