import { useTranslation } from 'react-i18next';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import FinanceInfoBalance from './FinanceInfoBalance';
import FinanceInfoCommission from './FinanceInfoCommission';
import { useFinancialInfo } from './useFinanceInfo';

interface FinancialInfoTableProps {
  isBalanceReport: boolean;
  requestDate: number | null;
  selectedDateFrom?: string;
  selectedDateTo?: string;
}

const FinancialInfoTable: React.FC<FinancialInfoTableProps> = ({ isBalanceReport, requestDate }) => {
  const { t } = useTranslation();
  const retailAddress = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.RETAIL_ADDRESS,
    deviceLayout: 'general',
  });

  const isCurrencyBeforeAmount = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.CURRENCY_BEFORE_AMOUNT,
  });

  const {
    financeStatus,
    financeInfoCommissionStatus,
    financeInfoErrorMessage,
    financeInfo,
    isPrintingEject,
    financeInfoCommission,
  } = useFinancialInfo();

  const handleFinanceInfoIcon = (status) => {
    if (status === 'failure') {
      return 'cashier-search';
    } else if (status === 'idle') {
      return 'calendar-outline';
    } else {
      return '';
    }
  };

  return (
    <>
      {isBalanceReport ? (
        <FinanceInfoBalance
          financeStatus={financeStatus}
          financeInfo={financeInfo}
          t={t}
          isPrintingEject={isPrintingEject}
          retailAddress={retailAddress}
          handleFinanceInfoIcon={handleFinanceInfoIcon}
          financeInfoErrorMessage={financeInfoErrorMessage}
          requestDate={requestDate}
          isCurrencyBeforeAmount={isCurrencyBeforeAmount}
        />
      ) : (
        <FinanceInfoCommission
          financeInfoCommissionStatus={financeInfoCommissionStatus}
          t={t}
          financeInfoCommission={financeInfoCommission}
          handleFinanceInfoIcon={handleFinanceInfoIcon}
          requestDate={requestDate}
          isCurrencyBeforeAmount={isCurrencyBeforeAmount}
        />
      )}
    </>
  );
};

export default FinancialInfoTable;
