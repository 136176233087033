import React from 'react';
import clsx from 'clsx';
import Icon from '../Icon';
import Text from '../Text';

type Props = {
  icon?: string;
  tooltip: string;
  tooltipAlignment: 'left' | 'right';
  text?: string;
  textWrap?: boolean;
  iconClass?: string;
  className?: string;
};

const IconTooltip: React.FC<Props> = ({
  icon,
  tooltip,
  tooltipAlignment,
  text,
  textWrap,
  iconClass,
  className,
}: Props) => {
  const cssNS = 'icon-tooltip';
  const ttClass = clsx('d-flex-center text-center', cssNS, className);
  const ttIconClass = clsx(`${cssNS}__icon`, iconClass && iconClass);
  const ttTipClass = clsx(
    `${cssNS}__tip`,
    tooltipAlignment == 'left' && `${cssNS}__tip--left`,
    tooltipAlignment === 'right' && `${cssNS}__tip--right`,
    textWrap && 'text-wrap',
  );

  return (
    <div className={ttClass}>
      {icon && <Icon className={ttIconClass} icon={icon} />}
      {text && text}
      <Text style={textWrap ? { width: '200px' } : {}} className={ttTipClass} text={tooltip} />
    </div>
  );
};

export default IconTooltip;
