import React, { useEffect, useRef, useState } from 'react';
import easingFunctions from './easing';
import useZASliderInitialSetup from './hooks/useZASliderInitialSetup';
import { ZASliderItems, ZASliderItemToRotate, ZASliderProps, Styles } from './types/zaslider.types';
import { getContainerDimension, getNextItem, getTranslateDirection } from './utils/zaslider.utils';

/**
 * @function ZASlider
 */
const ZASlider: React.FC<ZASliderProps> = ({ children, settings, items }) => {
  const currentId = useRef<number>(0);
  const currentItemIndex = useRef<number>(0);
  const slideContainerRef = useRef<HTMLDivElement | null>(null);
  const [itemsToRotate, setItemsToRotate] = useState<ZASliderItemToRotate>([]);
  const currentSettings = useZASliderInitialSetup(settings);
  const {
    direction,
    animationDirection,
    slidesToShow,
    spaceBetween,
    itemDimension,
    autoplaySpeed,
    animationSpeed,
    easing,
    duplicateItems,
  } = currentSettings;
  const selectedDirection: string = getTranslateDirection(direction);
  const removeLastItem = () => {
    const popList = [...itemsToRotate];
    popList.pop();
    setItemsToRotate(popList);
  };

  const removeFirstItem = () => {
    const shiftList = [...itemsToRotate];
    shiftList.shift();
    setItemsToRotate(shiftList);
  };
  const animateSlide = () => {
    if (document.hidden) {
      return;
    }
    const easingFn = easingFunctions[easing] || easingFunctions['linear'];
    const startFrom = animationDirection === 'left' ? 0 : itemDimension + spaceBetween;
    const endUntil = animationDirection === 'left' ? itemDimension + spaceBetween : 0;
    const duration = animationSpeed;
    let stop = false;
    let start = 0;
    // let end = 0;
    const startAnim = (timeStamp: number) => {
      start = timeStamp;
      // end = start + duration;
      draw(timeStamp);
    };
    const draw = (now: number) => {
      if (document.hidden) {
        stop = true;
        translateTo(selectedDirection, 0);
      }
      if (stop) {
        animationDirection === 'left' ? removeFirstItem() : removeLastItem();
        return;
      }
      if (now - start >= duration) stop = true;
      const p = (now - start) / duration;
      const val = easingFn(p);
      const x = startFrom + (endUntil - startFrom) * val;
      translateTo(selectedDirection, x);
      requestAnimationFrame(draw);
    };

    requestAnimationFrame(startAnim);
  };
  const translateTo = (translateDirection: string, value: number) => {
    if (slideContainerRef.current) {
      slideContainerRef.current.style.transform = `translate${translateDirection}(-${value}px)`;
    }
  };

  useEffect(() => {
    if (itemsToRotate.length === slidesToShow) {
      const nextItemIndex = (currentItemIndex.current + 1) % items.length;
      const { nextIndexToAdd, nextItemToAdd } = getNextItem({ items, itemsToRotate, nextItemIndex });
      currentItemIndex.current = nextIndexToAdd;
      const nextItem = {
        key: currentId.current++,
        item: nextItemToAdd || items[nextItemIndex],
      };

      if (animationDirection === 'left') {
        const array = itemsToRotate.slice();
        array.push(nextItem);
        setItemsToRotate(array);
      } else {
        setItemsToRotate([nextItem, ...itemsToRotate]);
      }
    }

    if (itemsToRotate.length === slidesToShow + 1) {
      if (animationDirection === 'left') {
        translateTo(selectedDirection, 0);
      } else {
        translateTo(selectedDirection, itemDimension + spaceBetween);
      }
      const interval = setInterval(animateSlide, autoplaySpeed);
      return () => clearInterval(interval);
    }
  }, [itemsToRotate]);
  useEffect(() => {
    if (!items.length) {
      return; // if no items, stop animation
    }

    const slidesToRotateArr: ZASliderItems = [];
    const cloneItemsArr: ZASliderItems = [];
    currentItemIndex.current = parseInt(slidesToShow) - 1;
    const slides = items.slice(0, slidesToShow);
    slides.forEach((item) => {
      slidesToRotateArr.push({
        key: currentId.current++,
        item: item,
      });
    });
    if (slidesToShow > slidesToRotateArr.length && duplicateItems) {
      // Need to clone more items
      const itemToClone = slidesToShow - slidesToRotateArr.length;
      for (let i = 0; i < itemToClone; i++) {
        cloneItemsArr[i] = {
          key: currentId.current++,
          item: slidesToRotateArr[i % slidesToRotateArr.length].item,
        };
      }
    }

    setItemsToRotate([...cloneItemsArr, ...slidesToRotateArr]);
  }, [currentSettings]);
  const styles: Styles = {
    containers: {
      containerClassName: 'za-container',
      innerContainerClassName: 'za-inner-container',
      itemClassName: 'za-slide-item',
    },
    vertical: {
      containerStyles: { overflow: 'hidden', height: getContainerDimension(itemDimension, slidesToShow, spaceBetween) },
      innerContainerStyles: {
        display: 'relative',
      },
      itemStyles: {
        height: itemDimension,
        marginBottom: spaceBetween,
      },
    },
    horizontal: {
      containerStyles: { width: getContainerDimension(itemDimension, slidesToShow, spaceBetween) },
      innerContainerStyles: {
        display: 'flex',
      },
      itemStyles: {
        flex: `0 0 ${itemDimension}px`,
        maxWidth: `${itemDimension}px`,
        marginRight: spaceBetween,
      },
    },
  };
  return (
    <div className={styles.containers.containerClassName} style={styles[direction].containerStyles}>
      <div
        className={styles.containers.innerContainerClassName}
        style={styles[direction].innerContainerStyles}
        ref={slideContainerRef}
      >
        {itemsToRotate.map((item) => (
          <div className={styles.containers.itemClassName} key={item.key} style={styles[direction].itemStyles}>
            {children(item.item, item.key)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ZASlider;
