import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGameCollectionId } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameCollectionId';
import { gamesSelectors } from 'modules/casino/modules/container/cellsContainer/cellls/slice/casinoGamesSlice';
import { Flex } from 'modules/casino/shared/components/layout';
import { Text } from 'modules/casino/shared/components/text';
import { UI } from 'modules/casino/shared/components/UI';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { Desktop, DesktopDown } from 'modules/casino/theme/Theme';
import { useAppSelector } from 'store';
import { CategoryLayoutTypes } from '../../types/CategoryLayoutTypes';
import LobbySubtitle from '../headerSubtitles/LobbySubtitle';

interface Props {
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
}

const InnerCategoryTitle: React.FC<Props> = ({ categoryId, categoryLayoutType }) => {
  const { title, widget, titleColor } = useGetCategoryParams(categoryId, categoryLayoutType);
  const navigate = useNavigate();
  const { gameCollectionId } = useGameCollectionId({ categoryId, categoryLayoutType });
  const gamesCount = useAppSelector((state) => gamesSelectors.categoryGamesCount(state, gameCollectionId));
  const hasGamesCount = gamesCount && title;
  const hasWidget = widget?.id;
  const renderTitle = () => {
    if (hasWidget) {
      return <Text text={title} type={'h1'} style={{ color: titleColor }} />;
    } else if (hasGamesCount) {
      return (
        <>
          <Text text={title} type={'h1'} style={{ color: titleColor }} />
          <span style={{ color: titleColor }}>{` (${gamesCount})`}</span>
        </>
      );
    } else {
      return '';
    }
  };

  const handleBackButton = () => {
    window.scrollBy(0, 0.1);
    navigate(-1);
  };

  return hasWidget || hasGamesCount ? (
    <>
      <DesktopDown>
        <UI.ButtonBack icon={'arrow'} onClickHandler={handleBackButton} />
        <div className={`inner_category-category-header__title `}>{renderTitle()}</div>
      </DesktopDown>
      <Desktop>
        <Flex.Col>
          <div className={`inner_category-category-header__title`}>{renderTitle()}</div>
          <LobbySubtitle categoryId={categoryId} categoryLayoutType={categoryLayoutType}></LobbySubtitle>
        </Flex.Col>
      </Desktop>
    </>
  ) : (
    <></>
  );
};

export default InnerCategoryTitle;
