import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Icon } from 'components/shared';
import { Flex } from 'components/shared/layout';

type LoginInfoType = {
  icon?: string;
  titleText: string;
  descriptionText?: string;
  linkText: string;
  link: string;
  openNewTab?: boolean;
  closeModal?: MouseEventHandler<HTMLAnchorElement>;
};

const LoginInfo = ({ icon, titleText, descriptionText, link, linkText, openNewTab, closeModal }: LoginInfoType) => {
  const { t } = useTranslation();

  return (
    <Flex.ColAC className="text-center">
      {icon && (
        <Icon defaultClassName={false} className={'mb-2 fill--error login-info-modal__icon'} size={38} icon={icon} />
      )}
      <div className={'mb-3 login-info-modal__title'}>{t(titleText)}</div>
      {descriptionText && <div className={'mb-3 login-info-modal__description'}>{t(descriptionText)}</div>}
      <Link
        className={'egtd-p-btn egtd-p-btn--primary egtd-p-btn--xl px-5 btn-action login-info-modal__button'}
        to={link}
        onClick={openNewTab ? undefined : closeModal}
        target={openNewTab ? '_blank' : '_self'}
      >
        {t(linkText)}
      </Link>
    </Flex.ColAC>
  );
};

export default LoginInfo;
