import React from 'react';
import { CategoryQueryType } from 'modules/casino/shared/constants';
import { Casino } from 'modules/casino/shared/types';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';

export enum GridType {
  SLIDER = 'SLIDER',
  VERTICAL = 'VERTICAL',
}

export enum SliderLayout {
  EXPANDING = 'EXPANDING',
  ROTATING = 'ROTATING',
  STANDARD = 'STANDARD',
}

export type ExpandingBannerSize = {
  width: number;
  height: number;
};

export type ExpandingGridSize = {
  gridTemplateRows: string;
  gridAutoColumns: string;
};

export enum SliderGridTypes {
  NESTED_GRID_SLIDER = 'NESTED_GRID_SLIDER',
  SINGLE_GRID_SLIDER = 'SINGLE_GRID_SLIDER',
}

export enum GridRowType {
  ONE_ROW = 'ONE_ROW',
  TWO_ROWS = 'TWO_ROWS',
  THREE_ROWS = 'THREE_ROWS',
  FOURTH_ROWS = 'FOURTH_ROWS',
  FIFTH_ROWS = 'FIFTH_ROWS',
  SIX_ROWS = 'SIX_ROWS',
}

export enum GridLayouts {
  LAYOUT_1 = 'LAYOUT_1',
  LAYOUT_2 = 'LAYOUT_2',
  LAYOUT_3 = 'LAYOUT_3',
  LAYOUT_4 = 'LAYOUT_4', // 1 row / SLIDER / regular ot vertical
  LAYOUT_5 = 'LAYOUT_5', // 1 row / SLIDER / vertical only
}

export enum GridLoadingBehaviorType {
  LoadButton = 'loadButton',
  InfinityScroll = 'infinityScroll',
}

// type GridLayoutChildrenType = {
//   itemId: string;
//   key: number;
//   isLastItem: boolean;
//   gridPage: number;
//   gamesRenderedCount: number;
//   skipLazyLoad: boolean;
// };
export interface GridLayoutPros {
  children: (
    itemId: string,
    key: number,
    isLastItem: boolean,
    gridPage: number,
    gamesRenderedCount: number,
    skipLazyLoad: boolean,
  ) => React.ReactNode;
  subType: CategorySubTypes;
  layoutConfig: Casino.LayoutConfig;
  className: string;
  categoryId: string;
  categoryAlias?: string;
  categoryLayout: CategoryLayoutTypes;
  gamesCount?: number;
  isCategoryLast?: boolean;
  onItemClicked?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  gridLoadingBehavior: GridLoadingBehaviorType;
  page?: number;
  fetchMoreGames: () => void;
  collectionId: string;
  query: CategoryQueryType;
}
