import React from 'react';
import { useProvidersLobby } from 'modules/casino/modules/lobby/providerLobby/hooks/useProvidersLobby';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { useGetCategoryPosition } from 'modules/casino/shared/hooks/useGetCategoryPosition';
import { Desktop } from 'modules/casino/theme/Theme';
import { CategoryLayoutTypes } from '../../types/CategoryLayoutTypes';
import CategoryFilter from '../CategoryFilter';
import CategoryScroll from '../CategoryScroll';

interface Props {
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
}

const ProviderCategoryActions: React.FC<Props> = ({ categoryId, categoryLayoutType }) => {
  const { providerLobbyTags } = useProvidersLobby();
  const { isFilterEnabled, searchTags } = useGetCategoryParams(categoryId, CategoryLayoutTypes.PROVIDER_CATEGORY);
  const { isFirst } = useGetCategoryPosition(categoryId, CategoryLayoutTypes.PROVIDER_CATEGORY);

  return isFilterEnabled && searchTags ? (
    <CategoryFilter categoryId={categoryId} searchTags={searchTags} categoryLayoutType={categoryLayoutType} />
  ) : isFirst ? (
    <Desktop>
      <CategoryScroll scrollTags={providerLobbyTags} />
    </Desktop>
  ) : (
    <></>
  );
};

export default ProviderCategoryActions;
