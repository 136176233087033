import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownOptionType, InputLocationType, RefType } from 'components/shared/forms/formTypes';
import { Flex } from 'components/shared/layout';
import FormGroup from './FormGroup';
import { FormUI } from './FormUI';

interface Props {
  options?: DropdownOptionType[];
  onChange?: (v) => void;
  label?: string;
  classNames?: string;
  name: string;
  errorMessage?: string;
  controlRef?: RefType;
  value?: string | number;
  onBlur?: (v?) => void;
  componentLocation?: InputLocationType;
  groupeClass?: string;
  isOutlined?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}

const FormRadio: React.FC<Props> = ({
  options,
  onChange,
  label,
  classNames,
  name,
  errorMessage,
  controlRef,
  value,
  onBlur,
  componentLocation = 'reg',
  groupeClass,
  isOutlined,
  disabled,
  readOnly,
}) => {
  const { t } = useTranslation();

  const handleOnChange = (selectedItem: string) => () => {
    if (!disabled) {
      if (selectedItem !== undefined || selectedItem !== null) {
        onChange && onChange(selectedItem);
        onBlur && onBlur();
      }
    }
  };

  return (
    <FormGroup className={groupeClass}>
      <FormUI.Label componentLocation={componentLocation} label={label} />
      <div className="form-row form-row-radios">
        {options &&
          options.map((option) => (
            <div
              key={`${name}-${option.value}`}
              className={`col-radio ${classNames || 'col-12'} `}
              onClick={handleOnChange(option.value)}
            >
              <div className={`${isOutlined ? 'is-outlined' : ''} fcw fcw-radio`}>
                <Flex.AC className="radio-wrapper">
                  <div className="custom-control custom-radio">
                    <input
                      data-testid={`${name}-${option.value}`}
                      type="radio"
                      className="custom-control-input"
                      id={`${name}-${option.value}`}
                      name={name}
                      value={option.value}
                      checked={option.value === value}
                      ref={controlRef}
                      onChange={handleOnChange(option.value)}
                      onBlur={onBlur}
                      aria-label={name}
                      data-qid={name}
                      disabled={disabled}
                      readOnly={readOnly}
                    />

                    <label className="custom-control-label">{t(option?.text || '')}</label>
                  </div>
                </Flex.AC>
              </div>
            </div>
          ))}
      </div>
      {errorMessage && <div className="form-message error bg--error">{errorMessage}</div>}
    </FormGroup>
  );
};

export default FormRadio;
