import { memo, useCallback, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router';
import { DataLoader } from 'components/shared/loaders';
import { Modal } from 'components/shared/Modal';
import { loginThunks } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { GAME_LAUNCH_PREFIX_PLATFORM } from 'utils/config';
import { fetchForm, submitDeclaration, selectInterceptors, resetFormFieldsData } from './slice/interceptors.slice';
import SourceOfFundsForm from './sourceOfFunds/SourceOfFundsForm';
import { StateStatus } from './types/interceptors.types';

const InterceptorModal = () => {
  const declarationStatus = useAppSelector(selectInterceptors.declarationStatus);
  const formHasFetched = useAppSelector(selectInterceptors.formHasFetched);
  const isModalVisibility = useAppSelector(selectInterceptors.isModalVisibility);
  const fields = useAppSelector(selectInterceptors.formFields);
  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });
  const modalContentStyle = isLandscape && isMobileOnly ? { top: '0', maxHeight: '80vh' } : undefined;
  const dialogContentStyle = isLandscape && isMobileOnly ? { maxWidth: '90%' } : undefined;

  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const handleSubmitForm = useCallback(
    (data) => {
      dispatch(submitDeclaration(data));

      return () => {
        dispatch(resetFormFieldsData());
      };
    },
    [dispatch],
  );

  const handleLogout = () => {
    dispatch(loginThunks.logout());
  };

  useEffect(() => {
    isModalVisibility && dispatch(fetchForm());
  }, [isModalVisibility, dispatch]);

  const renderModal = (status: StateStatus) => {
    switch (status) {
      case 'idle': {
        return isModalVisibility && formHasFetched ? (
          <Modal
            visible={isModalVisibility}
            scrollable={true}
            className="no-scrollbars sof-form"
            contentStyle={modalContentStyle}
            dialogStyle={dialogContentStyle}
            size="xl"
          >
            <SourceOfFundsForm formFields={fields} onSubmitForm={handleSubmitForm} onLogout={handleLogout} />
          </Modal>
        ) : null;
      }
      case 'pending': {
        return <DataLoader />;
      }
      case 'failed': {
        return null;
      }
      default: {
        return null;
      }
    }
  };

  if (pathname.includes(GAME_LAUNCH_PREFIX_PLATFORM)) {
    return null;
  }

  return renderModal(declarationStatus);
};

export default memo(InterceptorModal);
