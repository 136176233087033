import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { UI } from 'components/shared/UI';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { printVoucherCashier } from 'modules/retail/modules/cashier/printMethods/cashierVoucherPrint.utils';
import CashierKeyBoard from 'modules/retail/modules/ssbt/components/terminalKeyboard/CashierKeyBoard';
import { useFetchSSBTdata } from 'modules/retail/modules/ssbt/hooks/useFetchSSBTdata';
import { resetCashierVoucherData, voucherThunk } from 'modules/retail/shared/slice/voucher.slice';
import userStorage from 'pages/auth/login/UserStorage';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { useAppDispatch } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { useVoucherData } from './useVoucherData';
import { CashierModalUI } from '../../cashierModalUI/Index';
import { setIsPrintingEjected } from '../../slice/cashier.slice';

import { useFinancialInfo } from '../finance/useFinanceInfo';

const CashierVoucherForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { voucherCashierData, voucherCashierStatus } = useVoucherData();
  const userCurr = userStorage.getUserCurrency();
  const currency = userCurr || '';
  const { printerStatus } = useFetchSSBTdata();
  const { isPrintingEject } = useFinancialInfo();
  const isTicketCodeLinear = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.TICKET_CODE_LINEAR,
    deviceLayout: 'general',
  });
  const retailAddress = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.RETAIL_ADDRESS,
    deviceLayout: 'general',
  });
  const isCurrencyBeforeAmount = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.CURRENCY_BEFORE_AMOUNT,
  });
  const [inputValue, setInputValue] = useState('');
  const minInputValue = 1;
  const maxInputValue = 5;
  const disabledButton =
    inputValue?.length < minInputValue ||
    inputValue?.length > maxInputValue ||
    isPrintingEject ||
    Number(inputValue) <= 0;
  const updateInputValue = (newValue) => {
    if (!isNaN(newValue) && !isPrintingEject) {
      setInputValue(newValue);
    }
  };
  const resetVoucherData = () => {
    dispatch(resetCashierVoucherData());
    setInputValue('');
  };
  const createVoucher = () => {
    if (!isEmpty(printerStatus)) {
      dispatch(setIsPrintingEjected(true));
    }
    dispatch(voucherThunk.createVoucherCashier({ amount: inputValue }));
    setInputValue('');
  };

  useEffect(() => {
    if (voucherCashierStatus === 'succeeded' && !isEmpty(printerStatus)) {
      const printVoucherCashierObj = {
        amount: voucherCashierData?.value?.amount ?? 0,
        code: voucherCashierData?.code ?? '',
        currency,
        issuedAt: voucherCashierData?.issuedAt ?? '',
        validUntil: voucherCashierData?.validUntil ?? '',
        retailAddress,
        posAddress: voucherCashierData?.posAddress,
        posName: voucherCashierData?.posName ?? '',
        isTicketCodeLinear: isTicketCodeLinear ?? false,
        isCurrencyBeforeAmount,
      };
      printVoucherCashier(printVoucherCashierObj);
    }
    if (voucherCashierStatus === 'failure') {
      dispatch(setIsPrintingEjected(false));
    }
  }, [voucherCashierStatus]);
  return (
    <CashierModalUI.InputWrapper>
      <CashierModalUI.Input
        label={t(`Cashier.VoucherModal.voucherAmount`)}
        value={inputValue}
        placeholder={t(`Cashier.VoucherModal.typeString`)}
        onChange={(e) => updateInputValue(e.target.value)}
        isDisabled={false}
      />

      <CashierKeyBoard
        inputValue={inputValue}
        updateInputFunc={updateInputValue}
        onUnmount={() => {
          return;
        }}
        handleHideKeyboard={() => {
          return;
        }}
        inputRemoveValue={false}
      />

      <CashierModalUI.ButtonsWrapper>
        <UI.Button
          size="2xl"
          variant="primary"
          text={t(`Cashier.VoucherModal.createVoucher`)}
          onClick={createVoucher}
          isDisabled={disabledButton}
        />
        <UI.Button
          size="2xl"
          variant="secondary"
          text={t(`Cashier.CashPayModal.clearButton`)}
          onClick={resetVoucherData}
        />
      </CashierModalUI.ButtonsWrapper>
    </CashierModalUI.InputWrapper>
  );
};

export default CashierVoucherForm;
