import { createSlice } from '@reduxjs/toolkit';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getBusinessUnit } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { GamesTypes } from '../types/games.types';
import { parseGameData } from '../utils/games.utils';

const initialState: GamesTypes.InitialState = {
  game: {},
  gameStatus: {},
  activeGameId: null,
  gameContainerType: 'initial',
  modalProps: null,
  isLoading: false,
  memorizedOfferParts: {},
};

export const gamesThunks: GamesTypes.Thunks = {
  getGameContent: createAbortThunk('myGames/getGameContent', async ({ gameId, apiUrl }) => {
    const urlApi = apiUrl
      ? `${config.API_URL}${apiUrl}`
      : `${config.API_URL}/api/ews-platform-games/players/game-rounds/initialize/${gameId}`;

    const response = await axiosInstance.get(urlApi, {
      headers: {
        'X-Platform-Origin': getBusinessUnit(),
        'X-Platform-Ch': 'online',
      },
    });
    return response.data;
  }),

  getNextStep: createAbortThunk('myGames/getAwards', async ({ url, gameId, playerRoundId, bonusId, row, column }) => {
    const urlApi = `${config.API_URL}${url}`;
    const params = { gameId, roundId: playerRoundId, selectedAwardConditionId: bonusId, row, column };
    const response = await axiosInstance.post(urlApi, params, {
      headers: {
        'X-Platform-Origin': getBusinessUnit(),
        'X-Platform-Ch': 'online',
      },
    });
    return response.data;
  }),
};

const memorizedOfferParts = ({ state, gameId, offersParts }) => {
  if (!state[gameId]) {
    state[gameId] = {};
  }

  offersParts.forEach(({ column, row, ...element }) => {
    const columnState = state[gameId][column] || (state[gameId][column] = {});
    if (columnState[row]) return;
    columnState[row] = element;
  });
};
const myGamesSlice = createSlice({
  name: 'myGamesSlice',
  initialState,
  reducers: {
    resetGame: (state, action) => {
      if (state.game[action.payload]) {
        delete state.game[action.payload];
      }
      if (state.memorizedOfferParts[action.payload]) {
        delete state.memorizedOfferParts[action.payload];
      }
      if (state.gameStatus[action.payload]) {
        delete state.gameStatus[action.payload];
      }
      state.activeGameId = null;
      state.gameContainerType = 'initial';
    },
    reloadGame: (state, action) => {
      if (state.gameStatus[action.payload]) {
        delete state.gameStatus[action.payload];
      }
      if (state.memorizedOfferParts[action.payload]) {
        delete state.memorizedOfferParts[action.payload];
      }
      if (state.game[action.payload]) {
        state.game[action.payload].awards = {};
      }

      state.gameContainerType = 'initial';
    },
    closeModal: (state, action) => {
      resetGame(action.payload);
      state.modalProps = {};
    },
    setGameContainerType: (state, action) => {
      state.gameContainerType = action.payload;
    },
    setSetFinishType: (state, action) => {
      state.gameStatus[action.payload] = 'complete';
    },
    showGameModal: (state, action) => {
      state.modalProps = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(gamesThunks.getGameContent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(gamesThunks.getGameContent.fulfilled, (state, action) => {
        const game = typeof action.payload === 'string' ? parseGameData(action.payload) : action.payload;
        state.game[game.gameId] = game;
        state.activeGameId = game.gameId;
        game?.awards?.offersParts &&
          memorizedOfferParts({
            state: state.memorizedOfferParts,
            gameId: game.gameId,
            offersParts: game.awards.offersParts,
          });
        state.isLoading = false;
      })
      .addCase(gamesThunks.getGameContent.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(gamesThunks.getNextStep.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(gamesThunks.getNextStep.fulfilled, (state, action) => {
        const { gameStatus, gameId } = action.meta.arg;
        gameStatus && (state.gameStatus[gameId] = gameStatus);
        const game = typeof action.payload === 'string' ? parseGameData(action.payload) : action.payload;
        const cc = { ...state.game[gameId], ...game };
        state.game[gameId] = cc;
        state.isLoading = false;
        game.awards?.offersParts &&
          memorizedOfferParts({
            state: state.memorizedOfferParts,
            gameId: gameId,
            offersParts: game.awards.offersParts,
          });
      })
      .addCase(gamesThunks.getNextStep.rejected, (state) => {
        state.isLoading = false;
      });
    builder.addCase(logoutUser, () => initialState);
  },
});
export const { resetGame, setGameContainerType, showGameModal, closeModal, setSetFinishType, reloadGame } =
  myGamesSlice.actions;
export const { reducer: gamesReducer } = myGamesSlice;

export const selectGame = {
  initial: (id?: number) => (state: RootState) => (id ? state.games.game[id] : null),
  gameStatus: (id?: number) => (state: RootState) => (id ? state.games.gameStatus[id] : null),
  activeGameId: (state: RootState) => state.games.activeGameId,
  gameContainerType: (state: RootState) => state.games.gameContainerType,
  modalProps: (state: RootState) => state.games.modalProps || null,
  getOffersPartsItem:
    ({ gameId, row, column }: { gameId: number; row: number; column: number }) =>
    (state: RootState) =>
      state.games.memorizedOfferParts[gameId]?.[column]?.[row],
};
