import React from 'react';
import PrizeDropIcon from './PrizeDropIcon';

type Props = {
  title: string;
  iconResource?: string;
  size?: 'sm' | 'normal' | 'md' | 'lg';
  isCenter?: boolean;
  className?: string;
  children?: React.ReactNode;
};

const PrizeDropTitle: React.FC<Props> = ({ title, iconResource, size, isCenter, className, children }) => {
  return (
    <egtd-przd-title data-size={size} className={className} data-align={isCenter}>
      {children}
      {iconResource && <PrizeDropIcon iconResource={iconResource} />}
      {title}
    </egtd-przd-title>
  );
};

export default PrizeDropTitle;
