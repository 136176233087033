import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UI } from 'components/shared/UI';
import { CashierModalUI } from 'modules/retail/modules/cashier/cashierModalUI/Index';
import { setIsPrintingEjected } from 'modules/retail/modules/cashier/slice/cashier.slice';
import CashierKeyBoard from 'modules/retail/modules/ssbt/components/terminalKeyboard/CashierKeyBoard';
import { selectSessionBalance } from 'modules/retail/modules/ssbt/features/sessionBalance/slice/sessionBalance.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty, isTerminal } from 'utils/common/helpersCommon';
import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';
import { useDeposit } from './hooks/useDeposit';
import { cashPayThunks, clearDeposit } from '../../slice/cashPay.slice';

const DepositForm = () => {
  const [activeInput, setActiveInput] = useState<'cnp' | 'deposit'>('cnp');
  const [ucnValue, setUcnValue] = useState<string>('');
  const [depositAmount, setDepositAmount] = useState<string>('');
  const [hasConfirm, setConfirm] = useState<boolean>(false);
  const { verifyPlayerDeposit, verifyPlayerDepositStatus, processDeposit, processDepositStatus } = useDeposit();
  const retailSessionBalanceAmount = useAppSelector(selectSessionBalance.retailBalanceInfo)?.sessionBalance?.amount;
  const hasVerifyPlayer = verifyPlayerDeposit?.eligibleForDeposit && verifyPlayerDepositStatus === 'succeeded';
  const hasInputValues = !isEmpty(ucnValue) && !isEmpty(depositAmount);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (processDeposit?.success && verifyPlayerDeposit?.eligibleForDeposit) {
      setActiveInput('cnp');
      setUcnValue('');
      setDepositAmount('');
      setConfirm(false);
    }
  }, [processDeposit]);

  const handleUpdateInput = ({ type, value }: { type: 'cnp' | 'deposit'; value: string }) => {
    if (hasVerifyPlayer) return;
    if (type === 'cnp') {
      setUcnValue(value);
    } else {
      setDepositAmount(value);
    }
  };

  const handleSetMaxDepositAmount = () => {
    if (!isTerminal || verifyPlayerDepositStatus === 'pending') return;
    const amount = !isEmpty(retailSessionBalanceAmount) ? retailSessionBalanceAmount.toString() : '';
    setActiveInput('deposit');
    setDepositAmount(amount);
  };

  const handleSearch = () => {
    if (verifyPlayerDepositStatus === 'pending') return;
    dispatch(
      cashPayThunks.verifyPlayerDeposit({
        ucn: ucnValue,
        amount: +depositAmount,
      }),
    );
  };

  const handleDeposit = () => {
    if (isEmpty(verifyPlayerDeposit) || processDepositStatus === 'pending') return;
    if (!hasConfirm) {
      setConfirm(true);
      return;
    }
    dispatch(setIsPrintingEjected(true));
    dispatch(
      cashPayThunks.processDeposit({
        amount: verifyPlayerDeposit?.amount,
        currency: verifyPlayerDeposit?.currency,
        playerId: verifyPlayerDeposit?.playerId,
        processInstanceId: verifyPlayerDeposit?.processInstanceId,
      }),
    );
  };

  const handleClearDeposit = () => {
    dispatch(clearDeposit());
    setActiveInput('cnp');
    setUcnValue('');
    setDepositAmount('');
    setConfirm(false);
  };

  return (
    <CashierModalUI.InputWrapper>
      <CashierModalUI.Input
        label={`${t(CashierTranslations.CashPayModal.cnpInputLabel)}`}
        value={ucnValue}
        placeholder={`${t(CashierTranslations.CashPayModal.cnpInputPlaceholder)}`}
        onChange={(e) => handleUpdateInput({ type: 'cnp', value: e.target.value })}
        onFocus={() => setActiveInput('cnp')}
        isDisabled={hasVerifyPlayer}
      />

      <CashierModalUI.Input
        label={`${t(CashierTranslations.CashPayModal.depositInputLabel)}`}
        value={depositAmount}
        placeholder={`${t(CashierTranslations.CashPayModal.depositInputPlaceholder)}`}
        onChange={(e) => handleUpdateInput({ type: 'deposit', value: e.target.value })}
        onFocus={() => setActiveInput('deposit')}
        isDisabled={hasVerifyPlayer}
        // hasError
        // errorText="Deposit minimum sum is 1000 RON"
        buttonText={isTerminal() ? 'MAX' : ''}
        buttonOnClick={handleSetMaxDepositAmount}
      />

      <CashierKeyBoard
        inputValue={activeInput === 'cnp' ? ucnValue : depositAmount}
        updateInputFunc={(value) => handleUpdateInput({ type: activeInput, value })}
        onUnmount={() => {
          return;
        }}
        handleHideKeyboard={() => {
          return;
        }}
        inputRemoveValue={false}
      />

      <CashierModalUI.ButtonsWrapper>
        <UI.Button
          size="2xl"
          variant="secondary"
          text={`${t(CashierTranslations.CashPayModal.clearButton)}`}
          onClick={handleClearDeposit}
        />
        <UI.Button
          text={
            hasVerifyPlayer
              ? `${t(CashierTranslations.CashPayModal.depositButton)}`
              : `${t(CashierTranslations.CashPayModal.searchButton)}`
          }
          size="2xl"
          variant="primary"
          isActive={hasConfirm}
          isDisabled={hasInputValues === false}
          showLoader={verifyPlayerDepositStatus === 'pending' || processDepositStatus === 'pending'}
          onClick={hasVerifyPlayer ? handleDeposit : handleSearch}
        />
      </CashierModalUI.ButtonsWrapper>
    </CashierModalUI.InputWrapper>
  );
};

export default DepositForm;
