import React from 'react';
import clsx from 'clsx';
import { Icon } from 'components/shared';
import { ButtonDismiss } from 'components/shared/buttons';
import { Flex } from 'components/shared/layout';
import { DesktopDown } from 'theme/Theme';

/** Auth Notification */
interface NotificationProps {
  variant: 'info' | 'error';
  onClickHandler?: () => void;
  icon?: string;
  className?: string;
  dismissable?: boolean;
  text?: string;
  children?: React.ReactNode;
}

const Notification: React.FC<NotificationProps> = ({
  onClickHandler,
  variant,
  icon,
  className,
  dismissable = true,
  text,
  children,
}) => {
  const cssNS = 'auth-msg';
  const notificationClass = clsx(
    cssNS,
    `${cssNS}--${variant}`,
    dismissable && `${cssNS}--dismissable`,
    !icon && 'text-center',
    className,
  );

  return (
    <Flex.AC className={notificationClass} onClick={onClickHandler}>
      {icon && <Icon className={`${cssNS}__icon`} icon={icon} />}
      <div className={`${cssNS}__text`}>
        {text && text}
        {children && children}
      </div>
      {dismissable && (
        <DesktopDown>
          <ButtonDismiss />
        </DesktopDown>
      )}
    </Flex.AC>
  );
};

export default Notification;
