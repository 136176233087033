import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout/Flex';

/** Cashier Modal Info Section */
interface NavColProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: React.ReactNode;
}

const NavCol: React.FC<NavColProps> = ({ className, children }) => {
  const wrapClass = clsx('cashier-modal__navigation', className);

  return <Flex.Col className={wrapClass}>{children}</Flex.Col>;
};

export default NavCol;
