import React from 'react';
import clsx from 'clsx';
import { Icon, Text } from 'components/shared';
import { ValidationRules } from 'components/shared/forms/formTypes';
import { Flex } from 'components/shared/layout';
import { CashierTheme } from 'pages/myAccount/tabs/cashier/utils/cashier.utils';

interface Props {
  checked?: boolean;
  label: string;
  register?: any;
  validation?: ValidationRules;
  name: string;
  onChange: () => void;
}

const SoFCheckBox: React.FC<Props> = ({ label, register, validation, name, onChange, checked }) => {
  const cssNS = `sof-form-checkbox`;
  const checkboxStyles = clsx(cssNS, checked && `${cssNS}--checked`);

  const registerProps = validation ? register(name, { ...validation, onChange }) : register(name, { onChange });

  return (
    <Flex.AC className={`${cssNS}__wrapper`} onClick={onChange}>
      <input
        name={name}
        checked={checked}
        type="checkbox"
        className={`${cssNS}__input`}
        onChange={onChange}
        {...registerProps}
      />
      <Flex.Center className={checkboxStyles}>
        {checked && <Icon className={`${cssNS}__icon`} iconPath={CashierTheme.icons.ui} icon="checkbox-mark" />}
      </Flex.Center>
      <Text className={`${cssNS}__label`} text={label} />
    </Flex.AC>
  );
};

export default SoFCheckBox;
