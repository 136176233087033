import React, { memo } from 'react';
import { General } from 'shared/common/features/general/types/generalSlice.types';
import MainNavMobileLayout01 from './MainNavMobileLayout01';
import MainNavMobileLayout02 from './MainNavMobileLayout02';
import ShowHideArrow from '../common/ShowHideArrow';

type MainNavMobileTypes = {
  isAuthenticated: boolean | null;
  layout: General.HeaderWrapperConfig['layoutType'];
  showArrow?: boolean;
  isOpenTopNav?: boolean;
};

const componentsMap: Record<General.HeaderWrapperConfig['layoutType'], React.FC<MainNavMobileTypes>> = {
  layout_1({ isAuthenticated }) {
    return <MainNavMobileLayout01 isAuthenticated={isAuthenticated} />;
  },
  layout_2({ isAuthenticated }) {
    return <MainNavMobileLayout02 isAuthenticated={isAuthenticated} />;
  },
  layout_3({ isAuthenticated }) {
    return <MainNavMobileLayout01 isAuthenticated={isAuthenticated} />;
  },
};

const MainNavMobile: React.FC<MainNavMobileTypes> = ({ isAuthenticated, layout, showArrow, isOpenTopNav }) => {
  const MainNavMobileComponent = componentsMap[layout];
  return (
    <>
      <MainNavMobileComponent layout={layout} isAuthenticated={isAuthenticated} />
      {showArrow && <ShowHideArrow show={true} isOpenTopNav={isOpenTopNav} />}
    </>
  );
};

export default memo(MainNavMobile);
