import { BusinessUnit } from 'utils/common/helpersCommon';

export const hslToHex = (h: number, s: number, l: number) => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const getWidgetThemeColorsByBu = (): string => {
  const bu: BusinessUnit = window.config.BUSINESS_UNIT;
  switch (bu) {
    case 'WBBG':
      return `
      --ecw-white: #fff !important;
      --ecw-neutral-100: #dedede !important;
      --ecw-neutral-200: #e9ecef !important;
      --ecw-neutral-300: #cfd0d1 !important;
      --ecw-neutral-400: #9e9fa0 !important;
      --ecw-neutral-500: #636465 !important;
      --ecw-neutral-600: #5c5d5e !important;
      --ecw-neutral-700: #575859 !important;
      --ecw-neutral-800: #48494a !important;
      --ecw-neutral-900: #323334 !important;
      --ecw-primary-light: #19b14e !important;
      --ecw-primary-dark: #19b14e !important;
      --ecw-success-light: #19b14e !important;
      --ecw-success-dark: #19b14e !important;
      --ecw-warning-light: #f5ce6f !important;
      --ecw-warning-dark: #f09c5e !important;
      --ecw-danger-light: #e42314 !important;
      --ecw-danger-dark: #b4001f !important;
      --ecw-yellow: #fce34c !important;
      --ecw-tabs-active-color--dark-theme: #ffdf1a !important;
      --ecw-tabs-active-color--light-theme: #0054a5 !important;
      --ecw-dark-input-border-color-focus: #cfd0d1 !important;
      --ecw-primary-lt: #19b14e !important;
      --ecw-primary-dk: #19b14e !important;
      --ecw-checkbox-check: #19b14e !important;
      --ecw-submit-btn-color: #fff !important;
      --ecw-warning-lt: #f5ce6f !important;
      --ecw-warning-dk: #f09c5e !important;
      --ecw-amount-hover: #c5aa03 !important;
      --ecw-qd-modal-footer-bg: #454545 !important;
      `;
    case 'WBRO':
      //strawbery - ro
      return `
      --ecw-white: #fff !default !important;
      --ecw-neutral-100: #dedede !default !important;
      --ecw-neutral-200: #e9ecef !default !important;
      --ecw-neutral-300: #cfd0d1 !default !important;
      --ecw-neutral-400: #9e9fa0 !default !important;
      --ecw-neutral-500: #636465 !default !important;
      --ecw-neutral-600: #5c5d5e !default !important;
      --ecw-neutral-700: #575859 !default !important;
      --ecw-neutral-800: #48494a !default !important;
      --ecw-neutral-900: #323334 !default !important;
      --ecw-primary-light: #19b14e !default !important;
      --ecw-primary-dark: #19b14e !default !important;
      --ecw-success-light: #19b14e !default !important;
      --ecw-success-dark: #19b14e !default !important;
      --ecw-warning-light: #f5ce6f !default !important;
      --ecw-warning-dark: #f09c5e !default !important;
      --ecw-danger-light: #e42314 !default !important;
      --ecw-danger-dark: #b4001f !default !important;
      --ecw-yellow: #fce34c !important;
      --ecw-tabs-active-color--dark-theme: #ffdf1a !important;
      --ecw-tabs-active-color--light-theme: #0054a5 !important;
      --ecw-dark-input-border-color-focus: #cfd0d1 !important;
      --ecw-primary-lt: #19b14e !important;
      --ecw-primary-dk: #19b14e !important;
      --ecw-checkbox-check: #19b14e !important;
      --ecw-submit-btn-color: #fff !important;
      --ecw-warning-lt: #f5ce6f !important;
      --ecw-warning-dk: #f09c5e !important;
      --ecw-amount-hover: #c5aa03 !important;
      --ecw-qd-modal-footer-bg: #454545 !important;
      `;
    case 'SMBG':
      //violet
      return `
      --ecw-white: #fff !important;
      --ecw-neutral-100: #fff !important;
      --ecw-neutral-200: #dadada !important;
      --ecw-neutral-300: #b8b8b8 !important;
      --ecw-neutral-400: #9a9a9a !important;
      --ecw-neutral-500: #3a2856 !important;
      --ecw-neutral-600: #362550 !important;
      --ecw-neutral-700: #3a2856 !important;
      --ecw-neutral-800: #3a2856 !important;
      --ecw-neutral-900: #2c1c40 !important;
      --ecw-primary-light: #d7b247 !important;
      --ecw-primary-dark: #d7b247 !important;
      --ecw-success-light: #5ec96c !important;
      --ecw-success-dark: #5ec96c !important;
      --ecw-warning-light: #d7b247 !important;
      --ecw-warning-dark: #ec6a5c !important;
      --ecw-danger-light: #e42314 !important;
      --ecw-danger-dark: #b4001f !important;
      --ecw-yellow: #d7b247 !important;
      --ecw-tabs-active-color--dark-theme: #d7b247 !important;
      --ecw-tabs-active-color--light-theme: #0054a5 !important;
      --ecw-dark-input-border-color-focus: #d7b247;
      --ecw-primary-lt: #d7b247 !important;
      --ecw-primary-dk: #d7b247 !important;
      --ecw-checkbox-check: #644091 !important;
      --ecw-submit-btn-color: #2c1c40 !important;
      --ecw-warning-lt: #ec6a5c !important;
      --ecw-warning-dk: #ec6a5c !important;
      --ecw-amount-hover: #82681c !important;
      --ecw-qd-modal-footer-bg: #454545 !important;
      `;
    case 'IBBG':
      return `
      --ecw-white: #fff !important;
      --ecw-neutral-100: #dedede !important;
      --ecw-neutral-200: #e9ecef !important;
      --ecw-neutral-300: #cfd0d1 !important;
      --ecw-neutral-400: #9e9fa0 !important;
      --ecw-neutral-500: #636465 !important;
      --ecw-neutral-600: #5c5d5e !important;
      --ecw-neutral-700: #575859 !important;
      --ecw-neutral-800: #48494a !important;
      --ecw-neutral-900: #323334 !important;
      --ecw-primary-light: #19b14e !important;
      --ecw-primary-dark: #19b14e !important;
      --ecw-success-light: #19b14e !important;
      --ecw-success-dark: #19b14e !important;
      --ecw-warning-light: #f5ce6f !important;
      --ecw-warning-dark: #f09c5e !important;
      --ecw-danger-light: #e42314 !important;
      --ecw-danger-dark: #b4001f !important;
      --ecw-yellow: #fce34c !important;
      --ecw-tabs-active-color--dark-theme: #ffdf1a !important;
      --ecw-tabs-active-color--light-theme: #0054a5 !important;
      --ecw-dark-input-border-color-focus: #cfd0d1 !important;
      --ecw-primary-lt: #19b14e !important;
      --ecw-primary-dk: #19b14e !important;
      --ecw-checkbox-check: #19b14e !important;
      --ecw-submit-btn-color: #fff !important;
      --ecw-warning-lt: #f5ce6f !important;
      --ecw-warning-dk: #f09c5e !important;
      --ecw-amount-hover: #c5aa03 !important;
      --ecw-qd-modal-footer-bg: #454545 !important;
      `;
    case 'BHBG':
      return `
      --ecw-white: #fff !important;
      --ecw-neutral-100: #dedede !important;
      --ecw-neutral-200: #e9ecef !important;
      --ecw-neutral-300: #cfd0d1 !important;
      --ecw-neutral-400: #ACADAD !important;
      --ecw-neutral-500: #313952 !important;
      --ecw-neutral-600: #1e263d !important;
      --ecw-neutral-700: #1e263d !important;
      --ecw-neutral-800: #141C33 !important;
      --ecw-neutral-900: #12172c !important;
      --ecw-primary-light: #19b14e !important;
      --ecw-primary-dark: #19b14e !important;
      --ecw-success-light: #19b14e !important;
      --ecw-success-dark: #19b14e !important;
      --ecw-warning-light: #f5ce6f !important;
      --ecw-warning-dark: #f09c5e !important;
      --ecw-danger-light: #e42314 !important;
      --ecw-danger-dark: #b4001f !important;
      --ecw-yellow: #ffb142 !important;
      --ecw-tabs-active-color--dark-theme: #ffdf1a !important;
      --ecw-tabs-active-color--light-theme: #0054a5 !important;
      --ecw-dark-input-border-color-focus: #cfd0d1 !important;
      --ecw-primary-lt: #19b14e !important;
      --ecw-primary-dk: #19b14e !important;
      --ecw-checkbox-check: #19b14e !important;
      --ecw-submit-btn-color: #fff !important;
      --ecw-warning-lt: #f09d5f !important;
      --ecw-warning-dk: #f09c5e !important;
      --ecw-amount-hover: #c5aa03 !important;
      --ecw-qd-modal-footer-bg: #454545 !important;
      `;

    default:
      return `
      --ecw-white: #fff !important;
      --ecw-neutral-100: #dedede !important;
      --ecw-neutral-200: #e9ecef !important;
      --ecw-neutral-300: #cfd0d1 !important;
      --ecw-neutral-400: #9e9fa0 !important;
      --ecw-neutral-500: #636465 !important;
      --ecw-neutral-600: #5c5d5e !important;
      --ecw-neutral-700: #575859 !important;
      --ecw-neutral-800: #48494a !important;
      --ecw-neutral-900: #323334 !important;
      --ecw-primary-light: #19b14e !important;
      --ecw-primary-dark: #19b14e !important;
      --ecw-success-light: #19b14e !important;
      --ecw-success-dark: #19b14e !important;
      --ecw-warning-light: #f5ce6f !important;
      --ecw-warning-dark: #f09c5e !important;
      --ecw-danger-light: #e42314 !important;
      --ecw-danger-dark: #b4001f !important;
      --ecw-yellow: #fce34c !important;
      --ecw-tabs-active-color--dark-theme: #ffdf1a !important;
      --ecw-tabs-active-color--light-theme: #0054a5 !important;
      --ecw-dark-input-border-color-focus: #cfd0d1 !important;
      --ecw-primary-lt: #19b14e !important;
      --ecw-primary-dk: #19b14e !important;
      --ecw-checkbox-check: #19b14e !important;
      --ecw-submit-btn-color: #fff !important;
      --ecw-warning-lt: #f5ce6f !important;
      --ecw-warning-dk: #f09c5e !important;
      --ecw-amount-hover: #c5aa03 !important;
      --ecw-qd-modal-footer-bg: #454545 !important;
      `;
  }
};
