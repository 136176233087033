import React from 'react';
import { ItemComponents } from './DataCharacterMaping';
import { GameCellDataCharactersTypes } from '../gameCellTableCharactersConstants';

export interface ItemsProps {
  symbol;
  category: GameCellDataCharactersTypes;
  classFromLayout: string;
  showLuckyNumber?: boolean;
  luckyNumber?: number;
}

export const RenderItem: React.FC<ItemsProps> = (props) => {
  const Element = ItemComponents[props.category];

  return Element ? (
    <Element
      symbol={props.symbol}
      category={props.category}
      luckyNumber={props.symbol.multipliers ? props.symbol.multipliers[props.symbol.number] : props.luckyNumber}
      classFromLayout={props.classFromLayout}
      showLuckyNumber={props.showLuckyNumber}
    />
  ) : (
    <></>
  );
};
