import { GridLayouts } from '../types/gridTypes';

export const useGenerateNextElements = () => {
  let gridMapArray: string[][] = [];
  let initalMapCreated = false;

  const helpers = {
    hasEmpty: () => {
      let fullFillMap = false;
      for (let i = 0; i < gridMapArray.length; i++) {
        for (let t = 0; t < gridMapArray[i].length; t++) {
          if (gridMapArray[i][t] === 'x') {
            fullFillMap = true;
          }
        }
      }
      return !fullFillMap;
    },
    createInitialMap: (rows, columns, gridPage) => {
      // gridPage = gridPage === 0 ? 1 : gridPage;
      for (let i = 0; i < rows * gridPage; i++) {
        const columnArr: string[] = [];
        for (let t = 0; t < columns; t++) {
          columnArr.push('x');
        }
        gridMapArray.push(columnArr);
      }
      initalMapCreated = true;
    },
    fillCurrentLayout: (gameLayouts, columns, layoutGrid) => {
      for (let i = 0; i < gridMapArray.length; i++) {
        const columnArr = gridMapArray[i];
        const isLastRow = gridMapArray.length === i + 1;
        if (gameLayouts.isHorizontal) {
          if (horizontalLayout.canInsertInCurrentRow(i, columnArr, layoutGrid)) {
            break;
          }
          if (isLastRow) {
            if (
              horizontalLayout.canInsertInNextRow(i, columnArr, layoutGrid) ||
              horizontalLayout.createNewRowAndInsert(columns, layoutGrid)
            ) {
              break;
            }
          }
        }

        if (gameLayouts.isVertical) {
          if (verticalLayout.canInsertInCurrentAndNextRow(i, columnArr)) {
            break;
          } else {
            if (isLastRow) {
              if (
                verticalLayout.canInsertInNextRow(i, columnArr, columns) ||
                verticalLayout.createTwoNewRowsAndInsert(i, columns)
              ) {
                break;
              }
            }
          }
        }

        if (gameLayouts.isMacro) {
          if (macroLayout.canInsertInCurrentAndNextRow(i, columnArr)) {
            break;
          }

          if (gridMapArray.length === i + 1) {
            if (
              macroLayout.canInsertInNextRow(i, columnArr, columns) ||
              macroLayout.createTwoNewRowsAndInsert(i, columns)
            ) {
              break;
            }
          }
        }
        if (gameLayouts.isRegular) {
          if (regularLayout.insertIfEmpty(columnArr)) {
            break;
          }
        }
      }
    },
  };

  const verticalLayout = {
    canInsertInCurrentAndNextRow: (mapIndex, columnArr) => {
      // Check if have two empty indexes in two rows with same index. If yes place v
      const emptyIndex = columnArr.indexOf('x');
      if (emptyIndex !== -1 && gridMapArray[mapIndex + 1] && gridMapArray[mapIndex + 1][emptyIndex] === 'x') {
        columnArr[emptyIndex] = 'v';
        gridMapArray[mapIndex + 1][emptyIndex] = 'v';
        return true;
      }
      return false;
    },
    canInsertInNextRow: (mapIndex, columnArr, colum) => {
      const emptyIndex = columnArr.indexOf('x');
      // Last row. Insert Vertical in first empty index then create new row and insert vertical in same index
      if (emptyIndex !== -1) {
        const newRow: string[] = [];
        for (let l = 0; l < colum; l++) {
          newRow.push('x');
        }
        gridMapArray.push(newRow);
        columnArr[emptyIndex] = 'v';
        gridMapArray[mapIndex + 1][emptyIndex] = 'v';
        return true;
      }
      return false;
    },
    createTwoNewRowsAndInsert: (mapIndex, colum) => {
      // Last row. Create two empty rows. Insert vertical in each first empty index
      const newRow: string[] = [];
      for (let r = 0; r < 2; r++) {
        for (let l = 0; l < colum; l++) {
          newRow.push('x');
        }
        gridMapArray.push(newRow);
      }
      gridMapArray[mapIndex + 1][0] = 'v';
      gridMapArray[mapIndex + 2][0] = 'v';
      return true;
    },
  };
  const horizontalLayout = {
    canInsertInCurrentRow: (mapIndex, columnArr, layoutGrid) => {
      const emptyIndex = columnArr.indexOf('x');
      if (layoutGrid === GridLayouts.LAYOUT_3) {
        if (
          emptyIndex !== -1 &&
          columnArr[emptyIndex] === 'x' &&
          columnArr[emptyIndex + 1] === 'x' &&
          columnArr[emptyIndex + 2] === 'x'
        ) {
          columnArr[emptyIndex] = 'h';
          columnArr[emptyIndex + 1] = 'h';
          columnArr[emptyIndex + 2] = 'h';

          return true;
        }
      } else {
        if (emptyIndex !== -1 && columnArr[emptyIndex] === 'x' && columnArr[emptyIndex + 1] === 'x') {
          columnArr[emptyIndex] = 'h';
          columnArr[emptyIndex + 1] = 'h';
          return true;
        }
      }
      return false;
    },
    canInsertInNextRow: (mapIndex, columnArr, layoutGrid) => {
      const emptyIndex = columnArr.indexOf('x');
      if (layoutGrid === GridLayouts.LAYOUT_3) {
        if (
          emptyIndex !== -1 &&
          gridMapArray[mapIndex + 1] &&
          gridMapArray[mapIndex + 1][emptyIndex] === 'x' &&
          gridMapArray[mapIndex + 1][emptyIndex + 1] === 'x' &&
          gridMapArray[mapIndex + 1][emptyIndex + 2] === 'x'
        ) {
          gridMapArray[mapIndex + 1][emptyIndex] = 'h';
          gridMapArray[mapIndex + 1][emptyIndex + 1] = 'h';
          gridMapArray[mapIndex + 1][emptyIndex + 2] = 'h';

          return true;
        }
      } else {
        if (
          emptyIndex !== -1 &&
          gridMapArray[mapIndex + 1] &&
          gridMapArray[mapIndex + 1][emptyIndex] === 'x' &&
          gridMapArray[mapIndex + 1][emptyIndex + 1] === 'x'
        ) {
          gridMapArray[mapIndex + 1][emptyIndex] = 'h';
          gridMapArray[mapIndex + 1][emptyIndex + 1] = 'h';
          return true;
        }
      }
      return false;
    },
    createNewRowAndInsert: (colum, layoutGrid) => {
      const newRow: string[] = [];

      for (let t = 0; t < colum; t++) {
        newRow.push('x');
      }
      newRow[0] = 'h';
      newRow[1] = 'h';
      if (layoutGrid === GridLayouts.LAYOUT_3) {
        newRow[2] = 'h';
      }
      gridMapArray.push(newRow);
      return true;
    },
  };
  const macroLayout = {
    canInsertInCurrentAndNextRow: (mapIndex, columnArr) => {
      const emptyIndex = columnArr.indexOf('x');
      if (
        emptyIndex !== -1 &&
        gridMapArray[mapIndex + 1] &&
        columnArr[emptyIndex] === 'x' &&
        columnArr[emptyIndex + 1] === 'x' &&
        gridMapArray[mapIndex + 1][emptyIndex] === 'x' &&
        gridMapArray[mapIndex + 1][emptyIndex + 1] === 'x'
      ) {
        columnArr[emptyIndex] = 'm';
        columnArr[emptyIndex + 1] = 'm';
        gridMapArray[mapIndex + 1][emptyIndex] = 'm';
        gridMapArray[mapIndex + 1][emptyIndex + 1] = 'm';
        return true;
      }
      return false;
    },
    canInsertInNextRow: (mapIndex, columnArr, colum) => {
      const emptyIndex = columnArr.indexOf('x');
      const newRow: string[] = [];
      if (emptyIndex !== -1 && columnArr[emptyIndex] === 'x' && columnArr[emptyIndex + 1] === 'x') {
        columnArr[emptyIndex] = 'm';
        columnArr[emptyIndex + 1] = 'm';

        for (let t = 0; t < colum; t++) {
          newRow.push('x');
        }
        newRow[emptyIndex] = 'm';
        newRow[emptyIndex + 1] = 'm';
        gridMapArray.push(newRow);
        return true;
      }
      return false;
    },
    createTwoNewRowsAndInsert: (mapIndex, colum) => {
      let newRow: string[] = [];
      for (let n = 0; n < 2; n++) {
        for (let p = 0; p < colum; p++) {
          newRow.push('x');
        }
        gridMapArray.push(newRow);
        newRow = [];
      }

      gridMapArray[mapIndex + 1][0] = 'm';
      gridMapArray[mapIndex + 1][1] = 'm';
      gridMapArray[mapIndex + 2][0] = 'm';
      gridMapArray[mapIndex + 2][1] = 'm';
      return true;
    },
  };
  const regularLayout = {
    insertIfEmpty: (columnArr) => {
      if (columnArr.includes('x')) {
        const emptyIndex = columnArr.indexOf('x');
        columnArr[emptyIndex] = 'r';
        return true;
      }
    },
  };

  const isGridCellFilledUp = (itemGameLayout, rows, columns, gridPage, layoutGrid) => {
    const gameLayouts: { isVertical: boolean; isMacro: boolean; isHorizontal: boolean; isRegular: boolean } = {
      isVertical: itemGameLayout === 'vertical',
      isMacro: itemGameLayout === 'macro',
      isHorizontal: itemGameLayout === 'horizontal',
      isRegular: itemGameLayout === 'regular',
    };

    !initalMapCreated && helpers.createInitialMap(rows, columns, gridPage);
    helpers.fillCurrentLayout(gameLayouts, columns, layoutGrid);
    if (helpers.hasEmpty()) {
      resetCollection();
    }
    return helpers.hasEmpty();
  };
  const resetCollection = () => {
    gridMapArray = [];
    initalMapCreated = false;
  };
  return {
    isGridCellFilledUp: isGridCellFilledUp,
  };
};
