import {
  CasinoGamesTypes,
  GameLayoutEnum,
} from 'modules/casino/modules/container/cellsContainer/cellls/types/casinoGamesTypes';
import { BonusTypes, CategoryQueryType } from 'modules/casino/shared/constants';
import { CategorySubTypes } from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';

export const GRID_SLIDER_FETCH_GAME_SIZE = 30;
export const CATEGORY_WITH_NUMBERS_GAME_SIZE = 20;
// export const GRID_LAYOUTS_CUSTOM_FETCH_GAME_SIZE = [GridLayouts.LAYOUT_4, GridLayouts.LAYOUT_5];

export const CATEGORY_CUSTOM_GAMES_COUNTER = [
  CategoryQueryType.LAST_PLAYED_CATEGORY,
  CategoryQueryType.MOST_PLAYED_CATEGORY,
  CategoryQueryType.RECOMMENDATIONS_CATEGORY,
];
export const CDN_PATH_MAP = ['LAYOUT_1', 'LAYOUT_2', 'LAYOUT_3', 'LAYOUT_4', 'LAYOUT_5'];

export enum GamesSliceTypes {
  GENERAL = 'GENERAL',
  SEARCH = 'SEARCH',
  FAVOURITES = 'FAVOURITES',
  GENERAL_MODIFIER = 'GENERAL_MODIFIER',
}

export enum GameSectionTypes {
  WIDGET = 'WIDGET',
  ROTATING = 'ROTATING',
  EXPANDING = 'EXPANDING',
  ACCENTED = 'ACCENTED',
  FAVOURITES = 'FAVOURITES',
}

export const PROVIDER_IDS_TO_EXCLUDE = ['13', '25', '27', '31', '34'];
export const EXCLUDED_PROVIDER_IDS = PROVIDER_IDS_TO_EXCLUDE.join();

export const REGULAR_GAME_LAYOUT_BY_SUBTYPE = [
  CategorySubTypes.FREE_SPIN,
  CategorySubTypes.FOOTER,
  CategorySubTypes.FREE_SPIN_CHALLENGE,
  CategorySubTypes.SIDEBAR,
];
export const ONE_ROW_SLIDER_VALID_GAME_LAYOUTS = [GameLayoutEnum.REGULAR, GameLayoutEnum.HORIZONTAL];

export const BonusTagIconMapping = {
  [BonusTypes.FREE_SPIN]: 'casino-bonus-free-spin',
  [BonusTypes.FREE_BET]: 'casino-bonus-free-bet',
  [BonusTypes.WAGERING]: 'casino-bonus-wagering',
};

export const SET_POLLING_RESTRICTED_SUBTYPE = [CategorySubTypes.FREE_SPIN, CategorySubTypes.FREE_SPIN_CHALLENGE];

export const FAV_BTN_NONE_STYLE_EXCEPTION_BY_SUBTYPES = [
  CategorySubTypes.FREE_SPIN,
  CategorySubTypes.FREE_SPIN_CHALLENGE,
  CategorySubTypes.FOOTER,
  CategorySubTypes.SIDEBAR,
];

export const SPECIAL_VERTICAL_CELL = {
  gameLayout: 'VERTICAL',
  specialCell: true,
} as CasinoGamesTypes.GameProps;

export const SPECIAL_REGULAR_CELL = {
  gameLayout: 'REGULAR',
  specialCell: true,
} as CasinoGamesTypes.GameProps;

export const SpecialCellMap = {
  [GameSectionTypes.ROTATING]: SPECIAL_VERTICAL_CELL,
  [GameSectionTypes.ACCENTED]: SPECIAL_REGULAR_CELL,
  [GameSectionTypes.FAVOURITES]: SPECIAL_REGULAR_CELL,
};
