import Cell03DataStyles from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell03/components/data/styles';
import Cell03DefaultStyles from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell03/components/default/styles';
import {
  ComponentType,
  StyledNames,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/layoutWrapperConstants';

const components = {
  [StyledNames.GameCellWrapperStyled]: {
    [ComponentType.default]: Cell03DefaultStyles.GameCellWrapperStyled,
    [ComponentType.data]: Cell03DataStyles.GameCellWrapperStyled,
  },
  [StyledNames.GameMediaStyled]: {
    [ComponentType.default]: Cell03DefaultStyles.GameMediaStyled,
    [ComponentType.data]: Cell03DataStyles.GameMediaStyled,
  },
  [StyledNames.ProviderLogoStyled]: {
    [ComponentType.data]: Cell03DataStyles.ProviderLogoStyled,
  },
  [StyledNames.DescriptionStyled]: {
    [ComponentType.data]: Cell03DataStyles.DescriptionStyled,
  },
  [StyledNames.MetaInfoStyled]: {
    [ComponentType.data]: Cell03DataStyles.MetaInfoStyled,
  },
  [StyledNames.DataCharactersWrapperStyled]: {
    [ComponentType.data]: Cell03DataStyles.DataCharactersWrapperStyled,
  },
};

const cell03StyleLoader = (styledComponentName, styledType) => {
  return components[styledComponentName][styledType];
};

export default cell03StyleLoader;
