import React, { memo } from 'react';
import ItemComponent from './ItemComponent';
import { TickerItem } from './types/ticker.types';

interface Props {
  mostPlayed: TickerItem;
  onClickHandler: (e) => void;
}

const TickerSingleItem: React.FC<Props> = ({ mostPlayed, onClickHandler }) => {
  return (
    mostPlayed && (
      <div
        onClick={onClickHandler}
        className={`ticker-item ticker-item-${mostPlayed.itemName} no-flick`}
        ref={(node) => {
          node?.setAttribute('style', mostPlayed.attributes.style);
        }}
      >
        <ItemComponent
          tickerTypeId={mostPlayed.tickerTypeId}
          items={mostPlayed.items}
          jackpotId={mostPlayed.metadata.rawMetadata.jackpotId}
        />
      </div>
    )
  );
};

export default memo(TickerSingleItem);
