import { BetLimits } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTableLimits/gameCellTableLimitsTypes';
import { useCurrency } from 'modules/casino/shared/hooks/useCurrency';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppSelector } from 'store';

export const useGameLimits = (betLimits: BetLimits, gameCurrency: string | undefined) => {
  const { formatCurrency, currencySymbol } = useCurrency();
  const currencyName = useAppSelector(selectMyProfile.currencyName);
  const limitsCurrency = gameCurrency ?? currencyName ?? (localStorage.getItem('currency') || '');
  const currencyCode = gameCurrency ? formatCurrency(gameCurrency) : currencySymbol;
  const symbol = currencyCode?.replace(/[.]$/, '');
  const limits = betLimits ? betLimits[limitsCurrency] : null;

  return { limits, symbol };
};
