import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CasinoWidgetTemplates } from 'modules/casino/modules/container/widget/constants/casinoWidgetsConstants';
import LeaderBoardAwards from 'modules/casino/modules/container/widget/widgetTypes/leaderBoardWidget/leaderBoardCellWidget/components/widgetLeaderBoardComponents/LeaderBoardAwards';
import LeaderBoardRanking from 'modules/casino/modules/container/widget/widgetTypes/leaderBoardWidget/leaderBoardCellWidget/components/widgetLeaderBoardComponents/LeaderBoardRanking';
import { LeaderBoardWidgetTypes } from 'modules/casino/modules/container/widget/widgetTypes/leaderBoardWidget/leaderBoardCellWidget/LeaderBoardCellWidgetTypes';
import { leaderBoardCSSNS, LeaderBoardWidgetTranslationKeys } from '../../LeaderBoardCellWidgetConstants';

type Props = {
  awards: LeaderBoardWidgetTypes.Award[];
  ranking: LeaderBoardWidgetTypes.PlayerRanking[];
  cellLayout: string;
  buttonUrl: string;
};

type Tab = {
  title: string;
  content: ReactNode;
};

const LeaderBoardTabsSection: React.FC<Props> = ({ awards, ranking, cellLayout, buttonUrl }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<number>(0);

  const tabs: Tab[] = [
    {
      title: t(LeaderBoardWidgetTranslationKeys.ranking),
      content: <LeaderBoardRanking ranking={ranking} buttonUrl={buttonUrl} />,
    },
    {
      title: t(LeaderBoardWidgetTranslationKeys.awards),
      content: <LeaderBoardAwards awards={awards} />,
    },
  ];

  if (cellLayout === CasinoWidgetTemplates.MACRO) {
    return (
      <>
        {tabs.map((tab, index) => (
          <div className={`${leaderBoardCSSNS}-widget__winner--wrapper`} key={index}>
            <div className={`${leaderBoardCSSNS}-widget__winner--inner-top`}>
              <div
                className={`${leaderBoardCSSNS}-widget__winner--tab ${leaderBoardCSSNS}-widget__winner--tab--active`}
              >
                {tab.title}
              </div>
            </div>
            <div className={`${leaderBoardCSSNS}-widget__winner--inner-bottom`}>{tab.content}</div>
          </div>
        ))}
      </>
    );
  }
  return (
    <div className={`${leaderBoardCSSNS}-widget__winner--wrapper`}>
      <div className={`${leaderBoardCSSNS}-widget__winner--inner-top`}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            onClick={() => setActiveTab(index)}
            className={`${leaderBoardCSSNS}-widget__winner--tab ${
              activeTab === index ? `${leaderBoardCSSNS}-widget__winner--tab--active` : ''
            }`}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div className={`${leaderBoardCSSNS}-widget__winner--inner-bottom`}>{tabs[activeTab].content}</div>
    </div>
  );
};

export default LeaderBoardTabsSection;
