import React from 'react';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';

interface CardThumbProps {
  bgImage: string;
}

const CardThumb = React.forwardRef<HTMLDivElement, CardThumbProps>(({ bgImage }, ref) => {
  return (
    <div
      ref={ref}
      className={`bg-cover ${cssGlobalNS}-c-prm-lobby-card__thumb`}
      style={{ backgroundImage: `url(${bgImage})` }}
    ></div>
  );
});

CardThumb.displayName = 'CardThumb';

export default CardThumb;
