import React from 'react';
import clsx from 'clsx';

/** Wrapper */
interface WrapperProps {
  className?: string;
  children?: React.ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({ className, children }) => {
  const wrapperClass = clsx('financial-info-table no-scroll overflow-y-scroll', className);

  return <div className={wrapperClass}>{children}</div>;
};

export default Wrapper;
