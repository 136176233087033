import React from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';
import { cssGamificationNS } from 'utils/common/types/css.types';
import { Coins } from './components';

type Props = {
  winCoins: number;
  experiencePoints?: number;
  location?: 'store' | 'challenges';
  children?: React.ReactNode;
};

export const TagCoins: React.FC<Props> = ({ winCoins, experiencePoints, location, children }) => {
  if (!winCoins && !experiencePoints) return <></>;
  const symbol = location != 'store' ? '+' : '';
  const classes = clsx(`${cssGamificationNS}-tag ${cssGamificationNS}-tag__coins`);

  return (
    <Flex className={classes}>
      <div>
        <Flex.AC className={`${cssGamificationNS}-tag__coins__wrapper`}>
          {winCoins && winCoins != 0 ? <Coins coins={symbol + winCoins} isWin={true} /> : <></>}
          {experiencePoints && experiencePoints != 0 ? (
            <div className={`${cssGamificationNS}-tag__coins__experience-coins`}>
              <Coins coins={symbol + experiencePoints} />
            </div>
          ) : (
            <></>
          )}
        </Flex.AC>
        {children}
      </div>
    </Flex>
  );
};
