import React, { useRef } from 'react';
import clsx from 'clsx';
import { NotificationBadge } from 'components/shared/notifications';
import { NotificationBadgePositions } from 'components/shared/notifications/NotificationBadge';
import { UI } from 'components/shared/UI';
import { cdnUrl } from 'components/shared/UI/@types/ui.types';
import { isEmpty } from 'utils/common/helpersCommon';
import useActiveBottomTab from '../hooks/useActiveBottomTab';

interface Props {
  onClick: (arg) => void;
  notifications?: number | string;
  notificationPosition?: NotificationBadgePositions;
  icon: string;
  iconColor?: string;
  title: string;
  route: string;
  tabType: string;
  isNew?: boolean;
  isNewBg?: string;
  isNewColor?: string;
  bgImage?: string;
  bgColor?: string;
}

const BottomTabBarButton: React.FC<Props> = ({
  onClick,
  notifications,
  notificationPosition,
  icon,
  iconColor,
  title,
  route,
  tabType,
  isNew,
  isNewBg,
  isNewColor,
  bgImage,
  bgColor,
}) => {
  const { isActiveTab } = useActiveBottomTab(route, tabType);
  const tabClass = clsx(
    ['bottom-tab-bar__item', isActiveTab && 'bottom-tab-bar__item--active'],
    tabType === 'specialTournament' && 'bottom-tab-bar__item--special',
  );
  const isIOSBookmark = useRef(false);

  if (window.navigator['standalone'] || window.matchMedia('(display-mode: standalone)').matches) {
    isIOSBookmark.current = true;
  }

  const dataCPPProp = iconColor ? { style: { fill: iconColor } } : { 'data-cpp': tabType };

  const specialProp =
    tabType === 'specialTournament'
      ? { style: { background: `url(${cdnUrl}/${bgImage})`, backgroundColor: bgColor } }
      : undefined;

  const styleProp = Object.assign({ ...dataCPPProp }, { ...specialProp });

  return (
    <div className={tabClass} onClick={onClick} data-qid={`btb-link-${tabType}`} {...styleProp}>
      <UI.SVGCDN icon={icon} className={'bottom-tab-bar__item-icon'} />
      <span>{title}</span>
      {isNew && <UI.NewLabel bg={isNewBg} color={isNewColor} />}
      {!isEmpty(notifications) && notifications !== 0 && (
        <NotificationBadge
          variant="warning"
          position={notificationPosition}
          count={notifications}
          componentLocation="bottomTabBar"
        />
      )}
      {isIOSBookmark.current && <div style={{ paddingBottom: '20px' }}></div>}
    </div>
  );
};

export default BottomTabBarButton;
