import { useCallback, useEffect, useState } from 'react'; // Assuming you're using Redux
import { useAppDispatch, useAppSelector } from 'store';
import { isHandheld } from 'utils/common/helpersCommon';
import {
  clearFinancialInfoCommission,
  financialReportsThunk,
  resetFinanceStatus,
} from './slice/financialReports.slice';
import { cashierThunks, selectCashierData, turnOffClientMonitor, turnOnClientMonitor } from '../../slice/cashier.slice';
type ReportTypes = 'Balance' | 'Commission';

export const useFinancialReportManager = () => {
  const dispatch = useAppDispatch();
  const posInfo = useAppSelector(selectCashierData.posInfo);
  const [reportType, setReportType] = useState<ReportTypes>('Balance');
  const [selectedDates, setSelectedDates] = useState<{ startDate: Date | null; endDate: Date | null }>({
    startDate: null,
    endDate: null,
  });
  const [requestDate, setRequestDate] = useState<number | null>(null);

  const resetStartDateEndDate = useCallback(() => {
    setSelectedDates({ startDate: null, endDate: null });
  }, []);

  const handleToggleReportType = useCallback(
    (type) => {
      if (type === reportType) {
        return;
      }
      setReportType(type);
      if (reportType === 'Balance') {
        dispatch(resetFinanceStatus());
      } else {
        dispatch(clearFinancialInfoCommission());
      }
      resetStartDateEndDate();
    },
    [dispatch, reportType, resetStartDateEndDate],
  );

  const handleSearchButton = useCallback(() => {
    let startDate = selectedDates.startDate ? new Date(selectedDates.startDate) : null;

    let endDate = selectedDates.endDate ? new Date(selectedDates.endDate) : null;

    if (!startDate || !endDate) {
      return; // Early return if the dates are not valid
    }

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 2, 0, 0, 0); // Today at 02:00
    const action =
      reportType === 'Balance' ? financialReportsThunk.financeCashier : financialReportsThunk.fetchFinancialCommission;

    const exactStartDate = new Date(
      Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0),
    );

    const exactEndDate = new Date(
      Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999),
    );

    if (reportType === 'Balance') {
      // Adjusting dates for balance reports
      if (startDate < today) {
        // If the start date is before today at 02:00
        startDate.setHours(2, 0, 0, 0); // Set start time to 02:00 on the start day
        if (endDate > today || endDate.toDateString() === now.toDateString()) {
          endDate = now; // If the end date is today (after 02:00), set to current time
        } else {
          endDate.setHours(2, 0, 0, 0);
          endDate.setDate(endDate.getDate() + 1); // Otherwise, adjust end date to the next day at 02:00
        }
      } else {
        // If the range starts today, adjust start time to 02:00 and end time to now
        startDate = today;
        endDate = now;
      }
    } else {
      // Logic for financial commission reports
      if (endDate.toDateString() === now.toDateString()) {
        // If the end date is today, set to the current time
        endDate = now;
      } else {
        // If the end date is before today, set to 23:59:59.999
        endDate.setHours(23, 59, 59, 999);
      }
    }

    const formattedStartDate = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(
      2,
      '0',
    )}-${String(startDate.getDate()).padStart(2, '0')}T${String(startDate.getHours()).padStart(2, '0')}:${String(
      startDate.getMinutes(),
    ).padStart(2, '0')}:${String(startDate.getSeconds()).padStart(2, '0')}`;

    const formattedEndDate = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(
      endDate.getDate(),
    ).padStart(2, '0')}T${String(endDate.getHours()).padStart(2, '0')}:${String(endDate.getMinutes()).padStart(
      2,
      '0',
    )}:${String(endDate.getSeconds()).padStart(2, '0')}`;

    const startEpoch = new Date(formattedStartDate).getTime();
    const endEpoch = new Date(formattedEndDate).getTime();

    dispatch(
      action({
        dateFrom: exactStartDate.toISOString().split('T')[0],
        dateTo: exactEndDate.toISOString().split('T')[0],
        dateFromTimestamp: startEpoch,
        dateToTimestamp: endEpoch,
      }),
    );

    resetStartDateEndDate();
    setRequestDate(Date.now());
  }, [dispatch, selectedDates, reportType, resetStartDateEndDate]);

  useEffect(() => {
    dispatch(cashierThunks.betsLimit());
    if (!isHandheld) {
      turnOffClientMonitor();
      return () => {
        turnOnClientMonitor();
      };
    }
  }, []);

  return {
    posInfo,
    reportType,
    selectedDates,
    requestDate,
    handleToggleReportType,
    handleSearchButton,
    resetStartDateEndDate,
    setSelectedDates,
  };
};
