import React from 'react';
import { useTranslation } from 'react-i18next';
import { UI } from 'components/shared/UI';
import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';
import { TerminalModalUI } from '../ssbt/components/ssbtModalUI';

type Props = {
  resetStatus: () => void;
  message?: string;
};
const DeactiveTerminalModal: React.FC<Props> = ({ message, resetStatus }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    resetStatus();
  };
  return (
    <TerminalModalUI.Wrapper isVisible={true}>
      <TerminalModalUI.Body>
        <TerminalModalUI.Status status="error" className={'terminal-status__wrapper-deactive'} text={message} />
        <UI.Button
          size="2xl"
          variant="tertiary"
          onClick={handleClose}
          text={`${t(CashierTranslations.DeactiveTerminalModal.refreshStatus)}`}
        />
      </TerminalModalUI.Body>
    </TerminalModalUI.Wrapper>
  );
};
export default DeactiveTerminalModal;
