import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { useAppDispatch, useAppSelector } from 'store';
import FirstStepTicketModal from './FirstStepTicketModal';
import SecondStepTicketModal from './SecondStepTicketModal';
import { resetTicketInfoState, selectTicketData } from '../../../../../shared/slice/checkTicket.slice';
import { TerminalModalUI } from '../../../components/ssbtModalUI';
import { selectSSBTdata } from '../../../slice/ssbt.slice';
import { FormSteps } from '../../../types/ssbt.types';
import { handleNextStep, setPreviousStep } from '../../../utils/ssbt.utils';

const CheckTicketTerminalModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [activeStep, setActiveStep] = useState(FormSteps.FIRST);
  const ticketInfo = useAppSelector(selectTicketData.ticketInfo);
  const isTicketSelected = useAppSelector(selectSSBTdata.isTicketSelectedFromBarcodeReader);

  const { closeModal } = useHandleModals();

  const closeTerminalModal = () => {
    dispatch(resetTicketInfoState());
    closeModal();
  };

  useEffect(() => {
    if (isTicketSelected) {
      setActiveStep(2);
    }
  }, [isTicketSelected]);

  const renderStepContainer = (step) => {
    switch (step) {
      case 1:
        return <FirstStepTicketModal handleSubmit={() => handleNextStep(setActiveStep, 2)} />;
      case 2:
        return <SecondStepTicketModal handleNextStep={() => setPreviousStep(setActiveStep)} ticketInfo={ticketInfo} />;
      default:
        return null;
    }
  };

  return (
    <TerminalModalUI.Wrapper isVisible={true} isTicket={activeStep === 2} checkTicket>
      <TerminalModalUI.Close text={t(`SSBT.TerminalModal.closeTerminalModalText`)} onClick={closeTerminalModal} />
      {renderStepContainer(activeStep)}
      {/*TODO Notification translation here*/}
    </TerminalModalUI.Wrapper>
  );
};

export default CheckTicketTerminalModal;
