import React from 'react';
import { useTranslation } from 'react-i18next';
import CountdownTimer from 'components/shared/CountdownTimer';
import { transformTranslationPlaceholder } from 'components/shared/forms/utils';
import { Flex } from 'components/shared/layout';
import { ModalUI } from 'components/shared/ModalUI';
import { getValue } from 'pages/myAccount/tabs/history/utils/history.utils';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { RealityCheckModal } from 'utils/common/translationUtils/translationStrings';
import AmountCurrency from '../../myProfile/components/AmountCurrency';
import { selectRealityCheckSession } from '../slice/realityCheckSession.slice';

const SessionEnd: React.FC = () => {
  const sessionEndData = useAppSelector(selectRealityCheckSession.sessionEndData);
  const { t } = useTranslation();

  if (isEmpty(sessionEndData)) return null;

  const result = transformTranslationPlaceholder({
    text: t(
      sessionEndData.hasHitBalanceLimit ? RealityCheckModal.Session.exceedText : RealityCheckModal.Session.expireText,
    ),
    replacementText: !sessionEndData.hasHitBalanceLimit ? `${sessionEndData.hitTimeLimitValue}` : undefined,
    component: sessionEndData.hasHitBalanceLimit ? (
      <AmountCurrency amount={getValue(sessionEndData.hitBalanceLimitValue)} />
    ) : undefined,
    placeholder: '<<value>>',
  });

  return (
    <Flex.Col>
      <ModalUI.Title
        hasAccent
        className={'reality-check-session-end__title mb-2'}
        text={t(
          sessionEndData.hasHitBalanceLimit
            ? RealityCheckModal.Session.exceedTitle
            : RealityCheckModal.Session.expireTitle,
        )}
      />
      <div className={'reality-check-session-end__text mb-2'}>{result}</div>
      <div className={'reality-check-session-end__session-info'}>
        {t(RealityCheckModal.Session.infoNextSession)}
        <CountdownTimer
          classNames={'font-weight-bold'}
          startDateMS={Date.now()}
          endDateMS={sessionEndData.nextSessionStartTime}
          format={'HH:mm:ss without-locale'}
          takeDateOwnOffset={true}
        />
      </div>
    </Flex.Col>
  );
};

export default SessionEnd;
