import { CasinoGamesTypes } from 'modules/casino/modules/container/cellsContainer/cellls/types/casinoGamesTypes';
import { getBusinessUnit } from 'modules/casino/shared/utils/common/helpersCommon';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { selectPlayerRestrictionsByProducts } from 'pages/myAccount/tabs/responsibleGambling/slice/responsibleGambling.slice';
import {
  PRODUCT_TYPE_ENUM,
  PlayerRestrictions,
} from 'pages/myAccount/tabs/responsibleGambling/types/responsibleGambling.types';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { AppDispatch } from 'store';
import { RootState } from 'store/rootReducer';
import axiosInstance from 'utils/common/axios-instance';
import config from 'utils/config';
import { setGameBetType } from '../../casinoLaunchSlice';

const PlayerRestrictionsInterceptor =
  ({ gameId, closeGame, providerAlias }: { gameId: string | number; closeGame: () => void; providerAlias?: string }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const isAuthenticated = selectAuthentication.isAuthenticated(getState());
    const playerRestrictionsByProducts = selectPlayerRestrictionsByProducts(getState());

    const promise = (betType: PlayerRestrictions.ProductType) => {
      const productRestriction = playerRestrictionsByProducts?.find((r) => r.productTypeName === betType);

      return new Promise((resolve, reject) => {
        const showRestrictionsModalInfo = () => {
          dispatch(
            showRootModal({
              modalType: 'PLAYER_RESTRICTIONS_INFO',
              modalProps: { playerRestrictionsByProducts: [productRestriction], onClose: closeGame },
              modalPriority: ModalPriorityEnum.MEDIUM,
              modalId: 'PLAYER_RESTRICTIONS_INFO',
            }),
          );
        };

        if (isAuthenticated && productRestriction) {
          return reject(showRestrictionsModalInfo());
        }
        return resolve(true);
      });
    };

    if (providerAlias === 'svara') {
      return promise(PRODUCT_TYPE_ENUM.TABLE_GAMES);
    }

    try {
      const gameUrl = `/api/gaming/public/bonus/gameIds?workingBusinessUnit=${getBusinessUnit()}&gameIds=${gameId}`;
      const { data } = await axiosInstance.get<CasinoGamesTypes.GameProps[]>(`${config.API_URL}${gameUrl}`);
      const gameBetType = data?.[0]?.betType;
      dispatch(setGameBetType(gameBetType));
      return promise(gameBetType);
    } catch {
      return new Promise((_, reject) => reject(closeGame()));
    }
  };

export default PlayerRestrictionsInterceptor;
