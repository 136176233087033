import React, { memo, MouseEventHandler } from 'react';
import SportIconCDN from 'components/shared/icons/SportIconCDN';
import { Flex } from 'components/shared/layout';
import Pointer from 'components/shared/Pointer';

const cssNS = 'event-links';

/** ContainerLinkUI Wrapper */
interface WrapperProps {
  containerId: string;
  children: React.ReactNode;
}

const Wrapper: React.FC<WrapperProps> = (props) => {
  return (
    <Flex.Col id={`link-container-${props.containerId}`} className={`${cssNS}__wrapper`}>
      {props.children}
    </Flex.Col>
  );
};

/** ContainerLinkUI Content */
interface ContentProps {
  children: React.ReactNode;
}

const Content: React.FC<ContentProps> = (props) => {
  return <div className={`${cssNS}__content`}>{props.children}</div>;
};

/** ContainerLinkUI Header */
interface HeaderProps {
  showIcon: boolean;
  icon: string;
  text: string;
}

const Header: React.FC<HeaderProps> = (props) => {
  return (
    <Flex.AC className={`${cssNS}__header`}>
      {props.showIcon && <SportIconCDN type="containerHeader" icon={props.icon} initialInViewIcon={true} />}
      {props.text}
    </Flex.AC>
  );
};

/** ContainerLinkUI Item */
interface ItemProps {
  showIcon?: boolean;
  icon?: string;
  text: string;
  onClick: MouseEventHandler<HTMLDivElement>;
}

const Item: React.FC<ItemProps> = (props) => {
  return (
    <Flex.AC className={`${cssNS}__item`} onClick={props.onClick} data-box="event-link-item">
      {props.showIcon && <SportIconCDN type="containerHeader" icon={props.icon} />}
      {props.text}
      <Pointer className={`ml-auto ${cssNS}__item-pointer`} direction="right" />
    </Flex.AC>
  );
};

export const ContainerLinkUI = {
  Wrapper: Wrapper,
  Content: Content,
  Header: memo(Header),
  Item: memo(Item),
};
