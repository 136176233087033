import { useEffect } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { selectMyProfile, showMyProfileModal } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { localLog } from 'utils/common/helpersCommon';
import { selectAppHeader } from '../../appHeader';
import { selectPopOver } from '../../popover/popoverContainer/slice/popOver.slice';
import { selectRootModal } from '../../rootModal/slice/rootModal.slice';

type Props = {
  myProfileRef: React.RefObject<HTMLDivElement>;
};

export const useMyProfile = ({ myProfileRef }: Props): { appMainNavHeight: number } => {
  const dispatch = useAppDispatch();
  const isMyProfileModalShowing = useAppSelector(selectMyProfile.isModalShowing);
  const modalProps = useAppSelector(selectPopOver.props);
  const appMainNavHeight = useAppSelector(selectAppHeader.appMainNavHeight);
  const modalType = useAppSelector(selectRootModal.type);

  useEffect(() => {
    const handleMessage = (event) => {
      try {
        const changeOrientation = JSON.parse(event.data);
        if (myProfileRef.current && changeOrientation['isChange']) {
          dispatch(showMyProfileModal(false));
        }
      } catch (err) {
        localLog({ message: err });
      }
    };

    window.isRNWebView && window.addEventListener('message', handleMessage);

    return () => {
      window.isRNWebView && window.removeEventListener('message', handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (myProfileRef && myProfileRef.current) {
      disableBodyScroll(myProfileRef.current);
      return () => {
        clearAllBodyScrollLocks();
      };
    }
  }, [myProfileRef]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (myProfileRef.current && !myProfileRef.current.contains(e.target)) {
        dispatch(showMyProfileModal(false));
      }
    };

    if (isMyProfileModalShowing) {
      !modalProps?.show && document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
  }, [isMyProfileModalShowing, dispatch, myProfileRef, modalType, modalProps]);

  return { appMainNavHeight };
};
