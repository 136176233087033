import React from 'react';
import i18next from 'i18next';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { HelmetTags } from 'react-helmet-async';
import type { DeviceType } from 'hooks/common/useOnDeviceChange';
// eslint-disable-next-line import/no-cycle
import userStorage from 'pages/auth/login/UserStorage';
import { PreferredTheme } from 'shared/common/features/myProfile/types/myProfile.types';
import { Container } from 'shared/common/features/navigationMenu/types/navigationMenu.types';
import { DEFAULT_ERROR_MSG } from 'utils/common/constants';
import { formatDateWithOffset, formatDateUTC } from 'utils/dateUtils';
import { SportDataTypes } from './types/sportTypes/sportData.types';

//TODO: After separating sport and casino, group these methods into separate files

// --------------- wsMessageParser.utils ---------------
const JSONbig = require('json-bigint')({ storeAsString: true });

export const parseWsPayload = (messageBody: string): boolean => {
  return isJson(messageBody) ? JSONbig.parse(messageBody) : JSON.parse(messageBody);

  /**!IMPORTANT DONT REMOVE */
  // const parsedPayload = isJson(messageBody) ? JSONbig.parse(messageBody) : JSON.parse(messageBody);

  // return parsedPayload?.sportEventId
  //   ? { ...parsedPayload, sportEventId: `${parsedPayload.sportEventId}` }
  //   : parsedPayload;
};

// ------------- businessUnitRetriever.utils -------------------

export const getBusinessUnit = () => getWindowConfigParam('BUSINESS_UNIT');

export const getCdnBusinessUnit = () => getBusinessUnit().toLowerCase();

export const getClientNameBU = () => {
  const bu = getBusinessUnit();
  let clientNameBU = '';
  switch (bu) {
    case 'WBBG':
      clientNameBU = 'winbetonline';
      break;
    case 'IBBG':
      clientNameBU = 'winbetonline';
      break;
    case 'EGTT':
      clientNameBU = 'winbetonline';
      break;
    default:
      clientNameBU = 'winbetonline';
      break;
  }
  return clientNameBU;
};

// ----------------------- valuesValidators.utils -------------------
//THIS null is hack
export const isEmpty = <T>(value: T | null | undefined | string): value is null | undefined => {
  return (
    value === undefined ||
    value === null ||
    value === 'null' ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
};

export const isJson = (item): boolean => {
  item = typeof item !== 'string' ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === 'object' && item !== null) {
    return true;
  }

  return false;
};

/**
 * isValidJsonStr checks provided string is valid JSON
 * @param jsonStr to be parsed
 * @returns boolean is parsable
 */
export const isValidJsonStr = (jsonStr: string) => {
  try {
    return jsonStr && JSON.parse(jsonStr);
  } catch (e) {
    return false;
  }
};

export const compareValues = (prevVal: number | string, currVal: number | string): 'up' | 'down' | '' => {
  const comparedBets = prevVal < currVal;
  switch (comparedBets) {
    case true:
      return 'up';
    case false:
      return 'down';
    default:
      return '';
  }
};

// ----------------------- stringFormaters.utils -----------------------

export const capitalizeFirstLetter = (string: string): string => {
  if (!string) return '';

  if (string.length === 1) {
    return string.charAt(0).toUpperCase();
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

// --------------------- transformData.utils ----------------------
export const transformMyBetsDate = (date: number | string): string => {
  if (date) {
    return formatDateUTC('HH:mm DD.MM.YYYY', date);
  }
  return '';
};

export const retailFormatDate = (timestamp: number | string) => {
  const date = new Date(timestamp);

  const day = ('0' + date.getDate()).slice(-2); // Day of the month, padded with leading zeros if necessary
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Month, 0-based so +1 is needed
  const year = date.getFullYear();
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  const seconds = ('0' + date.getSeconds()).slice(-2);

  // Format the date and time as "HH:mm DD.MM.YYYY"
  const formattedTime = `${hours}:${minutes}:${seconds} ${day}/${month}/${year}`;

  return formattedTime;
};
export const retailFormatDateReceipt = (timestamp: number | string) => {
  const date = new Date(timestamp);

  const day = ('0' + date.getDate()).slice(-2); // Day of the month, padded with leading zeros if necessary
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Month, 0-based so +1 is needed
  const year = date.getFullYear();
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);

  // Format the date and time as "HH:mm DD.MM.YYYY"
  const formattedTime = `${day}.${month}.${year} ${hours}:${minutes}`;

  return formattedTime;
};

export const retailDate = (timestamp: number | string) => {
  const date = new Date(timestamp);

  const day = ('0' + date.getDate()).slice(-2); // Day of the month, padded with leading zeros if necessary
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Month, 0-based so +1 is needed
  const year = date.getFullYear();

  // Format the date and time as "HH:mm DD.MM.YYYY"
  const formattedTime = `${day}.${month}.${year}`;

  return formattedTime;
};

export const transformBetsHistoryDate = (date: string | number): string => {
  return formatDateUTC('DD.MM.YYYY', date, true);
};

export const transformDateWithHours = (date: string | number): string => {
  return formatDateUTC('dddd HH:mm || DD.MM HH:mm', date);
};

export const transformDateWithSeconds = (date: string | number): string => {
  return formatDateWithOffset('DD.MM.YYYY HH:mm:ss', date);
};

export const transformTerminalFinancialDate = (date: string | number): string => {
  return formatDateUTC('DD.MM.YYYY', date, true);
};
export const formatDateForReprintTicketSelectedPeriod = (dateString) => {
  const date = new Date(decodeURIComponent(dateString));
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
export const formatCreatedDate = (date) => {
  const utcDate = new Date(date);
  const offsetMinutes = utcDate.getTimezoneOffset();
  const localDate = new Date(utcDate.getTime() - offsetMinutes * 60000);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };

  const localDateStr = localDate.toLocaleString('en-US', options);

  const [datePart, timePart] = localDateStr.split(', ');
  const [month, day, year] = datePart.split('/');

  const formattedDate = `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;

  const formattedTime = timePart;

  return `${formattedTime} ${formattedDate}`;
};
// ---------------------- formaters.utils ------------------------

export const toFixedPrecision = (num: number, fixed: number): string => {
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  return num?.toString()?.match(re)?.[0] || '0';
};

export const formatBalance = (amount: number): string => {
  let finalBalance = '0.00';
  if (amount || amount === 0) {
    const fixedCurrencyDivider = 10000;
    const balance = toFixedPrecision(amount / fixedCurrencyDivider, 2);

    finalBalance = parseFloat(balance)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  return finalBalance;
};

export const toFixed = (number: number | undefined, fixed: number): string => {
  const num = number || 0;
  const cutNumber = toFixedPrecision(num, fixed);
  return parseFloat(cutNumber).toFixed(2);
};

export const toDecimal = (number: number | undefined = 0): number => number / 10000;

export function sortByRank<T extends { rank: number }>(items: T[]): T[] {
  return (items = items.sort(function (a, b) {
    return a.rank - b.rank;
  }));
}

export const filterContainer = (containersLayout: Container[], columnPos: number, rowPosition: number): Container[] => {
  const filteredContainers = containersLayout
    .filter((container) => container.columnPosition === columnPos && container.rowPosition === rowPosition)
    .sort((a, b) => a.ranking - b.ranking);

  return filteredContainers;
};

// --------------------- stringManipulations.utils --------------------------------
export const getSectionTitle = (categoryName: string | undefined, title: string | null | undefined): string => {
  return categoryName ? `${categoryName}${title ? ` - ${title}` : ''}` : title || '';
};

// --------------------- sorting.utils --------------------------------
type SortPropType = 'offsetMinutes' | 'utcOffset' | 'ranking' | 'searchRank' | 'modalPriority' | 'rank';
export const sortByProperty = <T>(items: T[], property: SortPropType): T[] => {
  if (isEmpty(items)) return [];
  return (items = items.sort((a, b) => a[property] - b[property]));
};

// ---------------- deviceTypeRetriever -----------------------
export const getDeviceType = (): DeviceType => {
  if (isMobileOnly) return 'mobile';
  if (isTablet) return 'tablet';
  return 'desktop';
};

export const isTerminal = (): boolean => {
  return window.config.CHANNEL === 'RETAIL' && window.config.DEVICE === 'SSBT';
};

export const isCashier = (): boolean => {
  return window.config.CHANNEL === 'RETAIL' && window.config.DEVICE === 'CASHIER';
};

export const isHandheld = (): boolean => {
  return window.config.CHANNEL === 'RETAIL' && window.config.DEVICE_TYPE === 'Handheld';
};

export const isTerminalOnline = (): boolean => {
  return window.config.CHANNEL === 'RETAIL' && window.config.DEVICE === 'ONLINE';
};

export const isRetail = (): boolean => {
  return isTerminalOnline() || isCashier() || isTerminal();
};

function daysInMonth(m: number, y: number) {
  // m is 0 indexed: 0-11
  switch (m) {
    case 1:
      return (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28;
    case 8:
    case 3:
    case 5:
    case 10:
      return 30;
    default:
      return 31;
  }
}

export const isValidDate = (d: number, m: number, y: number): boolean => {
  return m >= 0 && m < 12 && d > 0 && d <= daysInMonth(m, y) && y > 0;
};

export const dateToUTC = (year: number, month: number, day: number): number => {
  return Date.UTC(year, month, day);
};

export const LOGIN_FORM_TYPE_AUTH = 'auth';
export const LOGIN_FORM_TYPE_MODAL = 'modal';

export type BusinessUnit =
  | 'WBBG'
  | 'WBRO'
  | 'WBRS'
  | 'IBBG'
  | 'EGTT'
  | 'SMBG'
  | 'GSRS'
  | 'IBBGRT'
  | 'VVRO'
  | 'BHBG'
  | 'MBRO'
  | 'MBRORT'
  | 'RJBR'
  | 'RJBRPOC';

export interface WindowConfigInterface {
  API_URL: string;
  FB_TRACKING_SERVICE_URL: string;
  BUSINESS_UNIT: BusinessUnit;
  BRAND_NAME_LONG: string;
  BRAND_NAME_SHORT: string;
  THEME: string;
  DEFAULT_THEME_SKIN: PreferredTheme;
  DEFAULT_LANG: string;
  DEFAULT_CURR: string;
  ENV_TYPE?: 'DEV' | 'INT' | 'UAT' | 'PRODUCTION' | '${ENV_TYPE}';
  EPOCH?: string;
  APP_MOBILE_VERSION?: string;
  CHANNEL?: string;
  CASHIER_URL: string;
  SPORTSBOOK_URL: string;
  CONFIGURATOR: string;
  DEVICE?: string;
  CASINO_WIDGET_URL: string;
  GAMIFICATION_URL: string;
  CLIENT_ID?: string;
  DEVICE_TYPE?: string;
}

type ReplaceStringArgs = {
  template: string;
  placeholder: '{{BALANCE}}' | '<<attemptsLeft>>' | '<<vertical>>';
  value: string;
  fallback: string;
  regex?: RegExp;
};

export const replaceStringTemplate = ({
  template,
  placeholder,
  value,
  fallback,
  regex = /{{\w+}}/g,
}: ReplaceStringArgs) =>
  template.replace(regex, (substring) => {
    if (substring === placeholder) {
      return value;
    }
    return fallback;
  });

type TransformStringArgs = {
  template: string;
  placeholder: '{{BALANCE}}' | '<<attemptsLeft>>' | '<<vertical>>';
  value?: string;
  fallback: string;
  component?: React.ReactElement;
  regex?: RegExp;
};

export const formatWithComma = (value: string | number) => {
  return value
    .toString()
    .replace('.', ',')
    .replace(' ', '.')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const transformStringTemplate = ({
  template,
  placeholder,
  value,
  fallback,
  component,
  regex = /{{\w+}}/g,
}: TransformStringArgs): React.ReactNode[] => {
  const parts = template.split(regex);

  const matches = template.match(regex) || [];

  const result: React.ReactNode[] = [];

  parts.forEach((part, index) => {
    result.push(part);

    if (index < matches.length) {
      const currentMatch = matches[index];

      if (currentMatch === placeholder) {
        if (value) result.push(value);
        if (component) result.push(React.cloneElement(component, { key: `component-${index}` }));
      } else {
        result.push(fallback);
      }
    }
  });

  return result;
};

export const localeParser = {
  bg: 'bg_BG',
  en: 'en_GB',
  sr: 'sr_SR',
};

export const isProductionBuild = import.meta.env.MODE === 'production';

export const getWindowConfigParam = <T extends keyof WindowConfigInterface>(envVar: T): WindowConfigInterface[T] => {
  const templateEnvVar = '${' + envVar + '}';
  const isProdEnv = isProductionBuild;

  return isProdEnv || (!isProdEnv && window.config[envVar] !== templateEnvVar)
    ? window.config[envVar]
    : (import.meta.env[`VITE_APP_DEV_${envVar}`] as WindowConfigInterface[T]);
};

// --------------------cachios.utils ----------------------
export const getCachiosOptions = (
  lang?: string,
): { options: { force?: boolean; ttl: number }; language: string | null } => {
  const userLang = userStorage.getUserLang();
  const options = lang !== userLang ? { force: true, ttl: 60 * 60 * 24 } : { ttl: 60 * 60 * 24 };

  return { options, language: userLang };
};

// ------------------ oddsFormatting.utils ---------------
export const mapOddId = {
  1: 'americanOdds',
  2: 'odds',
  3: 'fractionalOdds',
};

export const mapDisplayOddsId = {
  1: 'displayAmericanOdds',
  2: 'displayOdds',
  3: 'displayFractionalOdds',
};

export const convertOddsValue = (decimal: number | string, oddsId: number): string | undefined => {
  const decimalNum = Number(decimal);
  switch (oddsId) {
    case 1: {
      const converted =
        decimalNum < 2.0 ? (-100 / (decimalNum - 1)).toPrecision(5) : ((decimalNum - 1) * 100).toPrecision(5);
      return Number.isFinite(Number(converted)) ? Number(converted).toFixed() : '-';
    }
    case 2:
      return toFixed(decimalNum, 2);
    case 3:
      return `${toFixed(decimalNum - 1, 2)}/1`;
  }
};

export const formatOddsValue = (oddsValue: number, oddsId: number): string | number | undefined => {
  switch (oddsId) {
    case 2:
      return !isNaN(oddsValue) ? toFixed(oddsValue, 2) : '0.00';
    case 1:
    case 3:
      return oddsValue;
  }
};

// ------------------ cookies.utils -----------------------
type cookieParams = { name: string; value: string; expiration: number };
export const createCookie = (params: cookieParams): void => {
  const { name, value, expiration } = params;
  document.cookie = `${name}=${value}; path=/; expires=${new Date(expiration).toUTCString()}; SameSite=None; Secure`;
};

const getSafeDocumentCookie = () => {
  try {
    return decodeURIComponent(document.cookie);
  } catch (e) {
    localLog({ message: e, type: 'error' });
  }
};

export const getCookie = (cookieName: string): string => {
  const name = cookieName + '=';
  const decodedCookie = getSafeDocumentCookie();
  if (!decodedCookie) return '';
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

// ------------------ appInit.utils ---------------------
export async function loadPolyfills(): Promise<void> {
  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }
}

// ------------------------ requestHandlers.utils ----------------
export const sleep = (ms = 100): Promise<unknown> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const TRY_POST_COUNT = 3;
const TRY_POST_INTERVAL = 1000;

export const retryCallback = async ({
  callback,
  callbackFail,
  count = TRY_POST_COUNT,
}: {
  callback;
  callbackFail?;
  count?: number;
}): Promise<'fail' | 'success'> =>
  await callback()
    .then((response) => {
      if (count < TRY_POST_COUNT) {
        localLog({ message: `👌 %c~ Retry request succeded. Retry count: ${TRY_POST_COUNT - count}`, type: 'warn' });
      }
      return response ? 'success' : 'fail';
    })
    .catch(() => {
      if (count > 0) {
        return sleep(TRY_POST_INTERVAL * count).then(() => retryCallback({ callback, callbackFail, count: count - 1 }));
      } else {
        callbackFail && callbackFail();
        return 'fail';
      }
    });

// ---------------------- svg.utils ---------------------
export const isExpiredIconsSprite = (date: Date | number | string | null) => {
  if (!date) return true;

  const oldDate = new Date(date);
  const newDate = new Date();
  const diffTimestamp = Math.abs(oldDate.getTime() - newDate.getTime());
  const diffDate = new Date(diffTimestamp);
  const hours = diffDate.getUTCHours();

  if (hours < 2) return false;
  return true;
};

// ----------------- uniqueIdGenerator.utils ------------------
export const getUniqueUuidv4 = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// ---------------------- sessionStorage.utils ----------------------
export const getBrowserTabId = (() => {
  const UNIQUE_TAB_ID = 'uniqueTabId';

  /**indicates tab reload */
  window.onload = () => {
    if (!sessionStorage.status) {
      sessionStorage.setItem('status', 'reloaded');
    }
  };
  window.onunload = () => sessionStorage.setItem('status', '');
  /**indicates tab reload */

  const currentUniqueId = sessionStorage.getItem(UNIQUE_TAB_ID);

  // for new or duplicated tab
  if (!currentUniqueId || sessionStorage.status) {
    sessionStorage.setItem(UNIQUE_TAB_ID, getUniqueUuidv4());
    // ws session from previous tab is loaded in store
    sessionStorage.removeItem('persist:webSockets');
  }

  return () => {
    let uniqueId = sessionStorage.getItem(UNIQUE_TAB_ID);
    if (!uniqueId) {
      const newId = getUniqueUuidv4();
      sessionStorage.setItem(UNIQUE_TAB_ID, newId);
      uniqueId = newId;
    }
    return uniqueId;
  };
})();

/**
 * Group array by object's key
 * @param array array
 * @param key string
 * @returns array
 */

// ------------------------- groupingByKey.utils ---------------------------
export const groupByKey = (array, key) => {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) });
  }, {});
};

export const filterByKey = (array, key, value) => {
  return array.filter((item) => item[key] === value);
};
// --------------------------- errorHandling.utils -----------------------
export const getError = {
  responseData: (err) => err?.response?.data ?? i18next.t(DEFAULT_ERROR_MSG),
  responseStatus: (err) => err?.response?.status,
  responseDataMessage: (err, prop: string = 'message'): string =>
    err?.response?.data?.[prop] ?? i18next.t(DEFAULT_ERROR_MSG),
  responseDataProp: (err, prop: string) => err?.response?.data?.[prop],
  message: (err): string => err?.message ?? i18next.t(DEFAULT_ERROR_MSG),
  responseDataErrors: (err) => err?.response?.data?.errors?.[0] ?? i18next.t(DEFAULT_ERROR_MSG),
  default: (): string => i18next.t(DEFAULT_ERROR_MSG),
};

// ---------------------- filtering.utils -------------------------------
export const filterPrematchProgram = (prematchProgramFilter) => {
  return prematchProgramFilter
    ?.filter((segment) => segment.section === 'Special') /// where is section property coming from in section? the type SportDataTypes.TabData[] | null
    ?.find((item) => item.data?.find((i) => i.uiType === 'program'));
};

// ------------------ logging.utils -------------------------
export const localLog = ({ message, type }: { message; type?: 'warn' | 'error' | 'info' }): void => {
  if (window.config?.ENV_TYPE !== 'PRODUCTION') {
    switch (type) {
      case 'warn':
        // eslint-disable-next-line no-console
        console.warn(message);
        break;
      case 'error':
        // eslint-disable-next-line no-console
        console.error(message);
        break;
      case 'info':
        // eslint-disable-next-line no-console
        console.info(message);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log(message);
        break;
    }
  }
};

export const generateRandomNumberArray = (quantity: number, max: number): number[] => {
  const arr: number[] = [];
  if (max >= quantity) {
    while (arr.length < quantity) {
      const newNumber = Math.floor(Math.random() * max);
      if (arr.indexOf(newNumber) === -1) arr.push(newNumber);
    }
  }
  return arr;
};
export const generateRandomNumberArrayFromNumbers = (numbers: number, quantity: number): number[] => {
  const arr = Array.from({ length: quantity }, (_, i) => i + 1);
  const arrLength = numbers;
  const randArr: number[] = [];
  do {
    randArr[randArr.length] = arr.splice(Math.floor(Math.random() * arr.length), 1)[0];
  } while (randArr.length < arrLength);
  return randArr;
};
export const generateUniqueRandomNumbers = (quantity: number, length: number): number[] => {
  const numbers: number[] = [];
  while (numbers.length < length) {
    const randomNumber: number = Math.floor(Math.random() * quantity) + 1;
    if (!numbers.includes(randomNumber)) {
      numbers.push(randomNumber);
    }
  }
  return numbers;
};

export const isStatisticsSport = (
  eventStatusConfig: SportDataTypes.EventStatusConfig | null,
  sportId: number,
): boolean => {
  return eventStatusConfig && eventStatusConfig.sportsWithStatistics
    ? eventStatusConfig.sportsWithStatistics.includes(sportId)
    : false;
};

// ------------------ sportData.utils -------------------------
const favouriteMarketTemplateIds = new Set<number>();
export const setFavouriteMarketTemplates = (config: SportDataTypes.EventStatusConfig): void => {
  favouriteMarketTemplateIds.clear();
  if (!config?.sportsConfig) return;
  config.sportsConfig
    .filter((sportsDataConfig) => sportsDataConfig.favoriteMarketTemplates?.length)
    .map((conf) => conf.favoriteMarketTemplates)
    .flat()
    .forEach((templateId) => {
      favouriteMarketTemplateIds.add(templateId);
    });
};

export const isMarketTemplateIdFavourite = (templateId: number): boolean => favouriteMarketTemplateIds.has(templateId);

// ------------------ wsSubscription.utils -------------------------
export const arrayEquals = (a: string[], b: string[]): boolean => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
};

export const filterRoutingKeys = (keys: string[], prevKeys: string[]): string[] => {
  const copyKeys = [...keys];
  const copyPrevKeys = [...prevKeys];

  const filteredKeys = copyKeys.filter((key) => {
    const index = copyPrevKeys.indexOf(key);
    if (index > -1) {
      copyPrevKeys.splice(index, 1);
      return false;
    }

    return true;
  });

  return filteredKeys;
};

export const handleTerminalBetStatus = (outcomes) => {
  return outcomes.find((outcome) => outcome.live === true)?.live;
};

export const showHideNavUserButton = (showNavUserButtons?: boolean) => {
  return isEmpty(showNavUserButtons) ? true : showNavUserButtons;
};

export const safeJSONParse = (jsonAsString: string, reviver?) => {
  let jsonConfig = {};
  try {
    jsonConfig = JSON.parse(jsonAsString, reviver);
  } catch (e) {
    getError.message(e); //console.log('invalid json: ', jsonAsString);
  }
  return jsonConfig;
};

export const helmetHandleLinkInject = (tags: HelmetTags) => {
  if (tags.linkTags) {
    const scriptTag = tags.linkTags[0];
    scriptTag.onload = null;
    scriptTag.rel = 'stylesheet';
  }
};
