import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import config from 'utils/config';
import { LastPrintInfo, LastPrintThunk, LastPrintType } from '../type/lastPrint.types';

const initialState: LastPrintType = {
  lastPrintStatus: 'idle',
  lastPrintError: '',
  lastPrintInfo: null,
  lastPrintClicked: false,
};

export const lastPrintThunk: LastPrintThunk = {
  lastPrint: createAbortThunk(`cashier/LAST-SCANNED-PaperCouponBet`, async () => {
    const url = `${config.API_URL}/api/pretail/agent/v2/ticket/last-receipt`;
    const response = await axiosInstance.get(url);
    return response.data;
  }),
};

const lastPrintSlice = createSlice({
  name: 'lastPrintSlice',
  initialState,
  reducers: {
    resetLastPrintInfoStatus(state) {
      state.lastPrintStatus = 'idle';
      state.lastPrintInfo = null;
      state.lastPrintError = '';
    },
    setLastPrintClick(state, action) {
      state.lastPrintClicked = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(lastPrintThunk.lastPrint.fulfilled, (state, action: PayloadAction<LastPrintInfo>) => {
      state.lastPrintInfo = action.payload;
      state.lastPrintStatus = 'succeeded';
    });
    builder.addCase(lastPrintThunk.lastPrint.pending, (state) => {
      state.lastPrintStatus = 'pending';
    });
    builder.addCase(lastPrintThunk.lastPrint.rejected, (state, action) => {
      state.lastPrintError = action.payload;
      state.lastPrintStatus = 'failure';
    });
  },
});
export const { resetLastPrintInfoStatus, setLastPrintClick } = lastPrintSlice.actions;
export default lastPrintSlice.reducer;
export const selectLastPrintInfo = {
  lastPrintInfo: (state: RootState) => state.retail.lastPrintInfo.lastPrintInfo,
  lastPrintInfoStatus: (state: RootState) => state.retail.lastPrintInfo.lastPrintStatus,
  lastPrintInfoError: (state: RootState) => state.retail.lastPrintInfo.lastPrintError,
  lastPrintClicked: (state: RootState) => state.retail.lastPrintInfo.lastPrintClicked,
};
