import { useTranslation } from 'react-i18next';
import { gridLayoutSettingsSelectors } from 'modules/casino/shared/components/gridLayoutSettings/gridLayoutSettingsSlice';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { RequestStatuses } from 'pages/help/helpTypes';
import { BonusTypes } from 'shared/common/features/myProfile/sections/tabs/offersTab/types/offersTab.types';
import { useAppDispatch, useAppSelector } from 'store';
import BonusOffersInfoPopover from './components/BonusOffersInfoPopover';
import { useBonusOffersInfo } from './hooks/useBonusOffersInfo';
import { fetchCasinoBonusOffersInfo } from './slice/bonusOffersInfoButton.slice';
import { BonusOffersInfoButton as BonusOffersInfoButtonTypes } from './types/bonusOffersInfoButton.types';
import { TopBarButton } from '../TopBarButton';
import { TopBarPopover } from '../TopBarPopover';

type Props = {
  isFreePlay: boolean;
  type: string;
  gameId: string;
  text?: string;
  icon?: string;
};

export const getBonusProgress = (dataItem: BonusOffersInfoButtonTypes.Bonus) => ({
  totalAmount:
    ((dataItem?.bonusType === BonusTypes.WAGERING || dataItem?.bonusType === BonusTypes.FreeSpinsWinRollover) &&
      dataItem?.bonusCompletion?.givenBonusAmount) ||
    (dataItem?.bonusType === BonusTypes.DEPOSIT_ROLLOVER && dataItem?.depositRollover?.totalRolloverAmount) ||
    ((dataItem?.bonusType === BonusTypes.FREE_SPIN_WAGERING ||
      dataItem?.bonusType === BonusTypes.FREE_SPIN ||
      dataItem?.bonusType === BonusTypes.FREE_BET) &&
      dataItem?.freeSpinInformation?.freeSpinCount) ||
    0,
  totalCompletion: Math.floor(
    ((dataItem?.bonusType === BonusTypes.WAGERING || dataItem?.bonusType === BonusTypes.FreeSpinsWinRollover) &&
      dataItem?.bonusCompletion?.totalCompletionPercentage) ||
      (dataItem?.bonusType === BonusTypes.DEPOSIT_ROLLOVER && dataItem?.depositRollover?.totalCompletionPercentage) ||
      ((dataItem?.bonusType === BonusTypes.FREE_SPIN_WAGERING ||
        dataItem?.bonusType === BonusTypes.FREE_SPIN ||
        dataItem?.bonusType === BonusTypes.FREE_BET) &&
        dataItem?.freeSpinInformation?.freeSpinCompletedCount) ||
      0,
  ),
});

const getBonusProgressHighest = (data: BonusOffersInfoButtonTypes.Bonus[]) =>
  data.reduce(
    (active, current) => {
      const progress = getBonusProgress(current);
      if (!active || active.totalCompletion < progress.totalCompletion) {
        return { ...current, ...progress };
      } else {
        return active;
      }
    },
    null as
      | null
      | (BonusOffersInfoButtonTypes.Bonus & {
          totalCompletion: number;
          totalAmount: number;
        }),
  );

const BonusOffersInfoButton: React.FC<Props> = ({ isFreePlay, type, gameId, text, icon }) => {
  const settingsFetchSuccess = useAppSelector(gridLayoutSettingsSelectors.fetchStatus) === RequestStatuses.SUCCESS;
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const { data } = useBonusOffersInfo({ isFreePlay, isAuthenticated, gameId });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dataItem = data?.bonuses && getBonusProgressHighest(data.bonuses);
  const isFreeSpin =
    dataItem?.bonusType === BonusTypes.FREE_BET ||
    dataItem?.bonusType === BonusTypes.FREE_SPIN ||
    dataItem?.bonusType === BonusTypes.FREE_SPIN_WAGERING;

  return (
    <TopBarButton
      disabled={!settingsFetchSuccess}
      type={type}
      text={text && t(text)}
      icon={!dataItem || isFreeSpin ? icon : undefined}
      counter={data?.unseen?.length}
      restrictForFreePlay={isFreePlay}
      progress={
        dataItem && !isFreeSpin
          ? {
              value: dataItem.totalCompletion,
            }
          : undefined
      }
      handleClick={() => !isFreePlay && isAuthenticated && dispatch(fetchCasinoBonusOffersInfo({ gameId }))}
      renderChildren={(btnRef, handleClose, show) => (
        <TopBarPopover handleClose={handleClose} parentRef={btnRef} show={show}>
          <BonusOffersInfoPopover data={data} />
        </TopBarPopover>
      )}
    />
  );
};

export default BonusOffersInfoButton;
