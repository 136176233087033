import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { checkTicketStatus } from 'modules/retail/modules/cashier/utils';
import { TerminalTheme } from 'modules/retail/modules/ssbt/ssbtConfig';
import { resetTicketInfoState, seTicketIdForDuplicateInBetSlip } from 'modules/retail/shared/slice/checkTicket.slice';
import userStorage from 'pages/auth/login/UserStorage';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch } from 'store';
import { isEmpty, isTerminal } from 'utils/common/helpersCommon';
import { CashierTranslations, SSBT } from 'utils/common/translationUtils/translationStrings';
import { CheckTicket } from '../../../types/ssbt.types';
import MaxProfit from '../../MaxProfit';
import { TerminalModalUI } from '../../ssbtModalUI';
import TicketActionButton from '../TicketActionButton';

interface SSBTTicketFooterProps {
  ticketInfo: CheckTicket | null;
  currencySymbol: string;
  closeTerminalModal?: () => void;
  footerRef?: any; // Must be add proper type
}

const SSBTTicketFooter: React.FC<SSBTTicketFooterProps> = ({ ticketInfo, currencySymbol, footerRef }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { closeModal } = useHandleModals();
  const userCurr = userStorage.getUserCurrency();
  const currency = userCurr || '';
  const cssNS = 'terminal-ticket';

  const isMagnumBetRo = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.IS_MAGNUM_BET_RO,
    deviceLayout: 'general',
  });

  const isCurrencyBeforeAmount = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.CURRENCY_BEFORE_AMOUNT,
  });

  const closeTerminalModal = () => {
    dispatch(resetTicketInfoState());
    closeModal();
  };

  const showPayoutModal = () => {
    dispatch(
      showRootModal({
        modalType: 'PAYOUT_MODAL',
        modalId: 'PAYOUT_MODAL',
        modalPriority: ModalPriorityEnum.MEDIUM,
      }),
    );
  };
  const showUCNModal = () => {
    dispatch(
      showRootModal({
        modalType: 'RETAIL_UCN_MODAL',
        modalId: 'RETAIL_UCN_MODAL',
        modalPriority: ModalPriorityEnum.MEDIUM,
      }),
    );
  };

  const handleDuplicateTicketRequest = () => {
    if (!isEmpty(ticketInfo)) {
      dispatch(seTicketIdForDuplicateInBetSlip());
      closeModal();
    }
  };

  return (
    <egtd-ssbt-ticket-footer ref={footerRef}>
      {ticketInfo?.status !== 'LOSS' && (
        <MaxProfit
          text={t(SSBT.TerminalModal.totalWinTerminal)}
          ticketInfo={ticketInfo}
          currency={currencySymbol}
          isCurrencyBeforeAmount={isCurrencyBeforeAmount}
        />
      )}

      {ticketInfo?.status && (
        <TerminalModalUI.StatusCentered
          key={ticketInfo?.code}
          icon="info-solid"
          text={checkTicketStatus(
            ticketInfo?.status,
            t,
            ticketInfo?.totalAmount,
            currencySymbol,
            ticketInfo?.isClaimForm,
            ticketInfo?.totalAmountAfterTax,
            isCurrencyBeforeAmount,
          )}
          isWin={!isEmpty(ticketInfo) && (ticketInfo?.status === 'WON' || ticketInfo?.status === 'VOID')}
          isProcessing={!isEmpty(ticketInfo) && (ticketInfo?.status === 'ACCEPTED' || ticketInfo?.status === 'PAID')}
          isLoss={!isEmpty(ticketInfo) && (ticketInfo?.status === 'LOSS' || ticketInfo?.status === 'CANCELLED')}
          isNotification={!isEmpty(ticketInfo)}
          className={ticketInfo?.status !== 'LOSS' ? '' : 'isSingle'}
        />
      )}

      {!isEmpty(ticketInfo) && isTerminal() && (
        <>
          <Flex.ACJB className={`${cssNS}__buttons`}>
            {(ticketInfo?.status === 'WON' || ticketInfo?.status === 'VOID') && (
              // TODO [ADD TO BALANCE] Change translation + functionality
              <TicketActionButton
                text={`${t('SSBT.CheckTicketModal.addToBalance')} - ${ticketInfo?.totalAmountAfterTax} ${currency}`}
                onClick={() => {
                  ticketInfo?.status === 'WON' || ticketInfo?.status === 'VOID'
                    ? isMagnumBetRo
                      ? showUCNModal()
                      : showPayoutModal()
                    : () => {
                        return;
                      };
                }}
              />
            )}
            {ticketInfo.possibleActions.includes('DUPLICATE_TICKET') && (
              <TicketActionButton
                text={t(CashierTranslations.ActionButton.duplicateTicket)}
                iconPath={TerminalTheme.icons.ui}
                icon="cashout"
                onClick={handleDuplicateTicketRequest}
                isTertiary={true}
              />
            )}

            <TicketActionButton
              text={t(SSBT.TerminalModal.scanNewTicketTerminal)}
              iconPath={TerminalTheme.icons.ui}
              icon="cashout"
              onClick={closeTerminalModal}
              isTertiary={false}
            />
          </Flex.ACJB>
        </>
      )}
    </egtd-ssbt-ticket-footer>
  );
};

export default SSBTTicketFooter;
