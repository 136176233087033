import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from 'theme/Theme';
import { usePreviousNew } from './usePreviousNew';
import useVerticalsConfig, { configRoutes } from './useVerticalsConfig';

const useMyBetsRoute = (
  path: string = '',
): {
  enabled?: boolean;
  isMyBets: boolean;
  myBetsMenuPath?: string;
  collapseInMyBets: boolean;
  showMyBetsFooter: boolean;
} => {
  const { routesEnabled } = useVerticalsConfig();
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isTabletOrMobile}px)` });
  const isRouteEnabled = routesEnabled?.myBets;
  const isMyBets = (isRouteEnabled && path.indexOf(configRoutes.myBets) > -1) || false;
  //collapse nav bar = current behaviour with popup
  const collapseInMyBets = (isTabletOrMobile && isMyBets) || false;
  const prevPath = usePreviousNew(path) || '/sports';
  // myBets route has no BottomTabBar menu items, we get the previous route menu
  const myBetsMenuPath = isMyBets ? prevPath : undefined;
  const showMyBetsFooter = !isTabletOrMobile && isMyBets;

  return { enabled: isRouteEnabled, isMyBets, myBetsMenuPath, collapseInMyBets, showMyBetsFooter };
};

export default useMyBetsRoute;
