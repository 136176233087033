import React, { memo, useMemo, useRef } from 'react';
import styled from 'styled-components';
import FrameAnimator from 'components/shared/FrameAnimator/FrameAnimator';
import { getCdnImages } from 'components/shared/FrameAnimator/utils/ImageCDN';
import { mapAnimationFrames } from 'components/shared/FrameAnimator/utils/images/utils';
import { Flex } from 'components/shared/layout';
import { useWindowDimensions } from 'hooks/useWindowDimenions';
import { Breakpoints } from 'theme/Theme';
import { NotificationTypes } from '../../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import { GamificationUI } from '../../components/GamificationUI';
import { useAutoScrollToDepositButton } from '../../hooks/useAutoScrollToDepositButton';
import useGamificationItemState from '../../hooks/useGamificationItemState';
import useGamificationItemStyles from '../../hooks/useGamificationItemStyles';

interface Props {
  index: number;
  notification: NotificationTypes.PopUpNotification;
  winItemIndex: null | number;
  setWinItemIndex: (index: number) => void;
  setAnimationComplete: (isVisible: boolean) => void;
  animationComplete: boolean;
  animationParams: NotificationTypes.GamificationModalParams;
}

const DwarfsWagonItem: React.FC<Props> = ({
  index,
  notification,
  winItemIndex,
  setWinItemIndex,
  setAnimationComplete,
  animationComplete,
  animationParams,
}) => {
  const { bonusData, button, frameAudioUrl } = notification;
  const wagonRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowDimensions();
  const textWinWidth = width > 675 ? '2vw' : '13vw';
  useAutoScrollToDepositButton(animationComplete);
  const { state, handleAnimationComplete, handleAnimation } = useGamificationItemState({
    bonusData,
    animationComplete,
    winItemIndex,
    index,
    setWinItemIndex,
    setAnimationComplete,
    notificationId: notification.id,
    frameAudioUrl,
  });

  const { winTextClass } = useGamificationItemStyles(state.isWinItem);

  const tileOpenAnimationImages = useMemo(() => {
    return getCdnImages(
      mapAnimationFrames(
        animationParams,
        index === 1 && animationParams.fileBaseName.substring(0, animationParams.fileBaseName.length - 1),
      ),
    );
  }, [animationParams, index]);
  return (
    <>
      <ItemWrapper index={index} className={`d-grid grid-1x1`} ref={wagonRef}>
        <div className="grid-start-1x1 gmf-modal__animation wagon">
          <Flex.Center className="animation-frame__wrapper">
            <FrameAnimator
              images={tileOpenAnimationImages}
              width={animationParams.frameWidth}
              height={animationParams.frameHeight}
              maxWidth={animationParams.frameMaxWidth}
              maxHeight={animationParams.frameMaxHeight}
              aspectRatioWidth={animationParams.frameAspectRatio}
              fps={60}
              iterations={1}
              play={state.playAnim}
              onIterationComplete={handleAnimationComplete}
              onClick={handleAnimation}
            />
          </Flex.Center>
        </div>
        {state.giftTitle && state.winIsVisible && (
          <WinText
            index={index}
            className={winTextClass}
            style={{ width: `calc(${animationParams.frameWidth}px - ${textWinWidth})` }}
          >
            {state.giftTitle}
          </WinText>
        )}
      </ItemWrapper>
      <GamificationUI.CTAButton
        isVisible={button?.buttonText !== undefined && !animationComplete}
        disabled={winItemIndex !== null || state.winIsVisible}
        onClick={handleAnimation}
        text={button?.buttonText}
        showPlaceholder={false}
      />
    </>
  );
};

const ItemWrapper = styled('div')<{ index: number }>`
  position: absolute;
  @media screen and (min-width: 576px) {
    position: relative;
  }
  @media screen and (min-width: ${Breakpoints.isDesktopLarge}px) {
    position: absolute;
  }
  ${(props) =>
    props.index === 0 &&
    `
    align-self: flex-start;
    // top:-4vw;
    top: 11vw;
    left: -2vw;
    @media screen and (min-width:  576px) {
      
      top:20px;
    }
    @media screen and (min-width: ${Breakpoints.isDesktop}px) {
      top: -2vw;
      left: 7vw;
    }
    @media screen and (min-width: ${Breakpoints.isDesktopLarge}px) {
      top: -11px;
      left: 220px;
    }
  `}
  ${(props) =>
    props.index === 1 &&
    `
    align-self: flex-end;
    margin-right:27px;
    top:50vw;
    // top:-14vw;
    
    @media screen and (min-width:  576px) {
      top:-30px;
    }
    @media screen and (min-width: ${Breakpoints.isDesktop}px) {
      top: -6vw;
      right: 12vw;
    }
    @media screen and (min-width: ${Breakpoints.isDesktopLarge}px) {
      top: 150px;
      right: 360px;
    }
  
  `}
  ${(props) =>
    props.index === 2 &&
    `
    margin-left:45px;
    align-self: flex-start;
    top:98vw;
   
    @media screen and (min-width:  576px) {
      top:-15px;
    }
    @media screen and (min-width: ${Breakpoints.isDesktop}px) {
      margin-left:0;
      top: 1vw;
      left: 22vw;
    }
    @media screen and (min-width: ${Breakpoints.isDesktopLarge}px) {
      top: auto;
      left: 360px;
      bottom: 75px;
    }
  `}
  .wagon {
    ${(props) =>
      props.index === 0 &&
      `
      transform:rotate(27deg);
     
    `}
    ${(props) =>
      props.index === 1 &&
      `
      transform:rotate(-31deg) ;
      
    `}
    ${(props) =>
      props.index === 2 &&
      `
      transform:rotate(18deg);
      
    `}
  }
`;

const WinText = styled('div')<{ index: number }>`
  text-shadow: 0px 4px 8px #000000;
  font-size: 14px;
  ${(props) =>
    props.index === 0 &&
    `
    margin-right: 4vw !important;
    position: relative;
    top: 3vw;
      @media screen and (min-width: ${Breakpoints.isDesktop}px) {
        margin-right:3vw !important;
        top: 1vw;
      }
    `}
  ${(props) =>
    props.index === 1 &&
    `
    margin-left: 3vw !important;
    position: relative;
    top: 3vw;
    @media screen and (min-width: ${Breakpoints.isDesktop}px) {
      margin-left:3vw !important;
      top: 1vw;
    }
    `}
    ${(props) =>
    props.index === 2 &&
    `
    margin-right: 4vw !important;
    position: relative;
    top: 3vw;
    @media screen and (min-width: ${Breakpoints.isDesktop}px) {
      margin-right:2vw !important;
      top: 0vw;
    }
    `}
`;

export default memo(DwarfsWagonItem);
// maxWidth={height < 1024 ? animationParams.frameWidth : animationParams.frameMaxWidth}
// maxHeight={height < 1024 ? animationParams.frameHeight : animationParams.frameMaxHeight}
//
