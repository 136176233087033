import { useEffect } from 'react';
import { useAppDispatch } from 'store';
import { fetchGamesChallenges } from '../slice/gameLaunchBonus.slice';

export const useInitialChallengesFetch = ({ gameId, fetchApi }: { gameId: string | undefined; fetchApi: boolean }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fetchApi && gameId) {
      dispatch(fetchGamesChallenges(gameId));
    }
  }, [dispatch, fetchApi, gameId]);
};
