import styled from 'styled-components';
import { Breakpoints } from 'theme/Theme';

export const ReelsGridWrapper = styled.div`
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  margin-right: 2px;
  margin-bottom: 16px;
  margin-left: 2px;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    margin-top: 20px;
    margin-right: 30px;
    margin-bottom: 30px;
    margin-left: 30px;
  }
`;

export const ReelsGrid = styled.div`
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 5px;
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  background-color: #e57b09;

  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    column-gap: 16px;
    padding: 16px;
  }

  & > div,
  .animation-frame__wrapper {
    grid-row: 1 / 1;
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
  }
`;

export const ReelsFrame = styled.img`
  z-index: 9999;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  width: 100%;
  pointer-events: none;
  filter: drop-shadow(0 0 3px #000);
`;
