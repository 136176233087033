export enum HistoryDateFilterType {
  twentyFour = 'twentyFour',
  fortyEight = 'fortyEight',
  dateRange = 'dateRange',
}

export enum BonusHistoryStatus {
  Expired = 'statusNeutral',
  Canceled = 'statusNegative',
  Completed = 'statusPositive',
  Deactivated = 'statusNegative',
  Rejected = 'statusNegative',
  Declined = 'statusNegative',
  Reset = 'statusNeutral',
  Invalid = 'statusNegative',
  Exhausted = 'statusNeutral',
}

export interface HistoryOptions {
  title: string;
  items: string[];
}
export interface SortObject {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface HistoryPageable {
  sort: SortObject;
  pageNumber: number;
  pageSize: number;
  offset: number;
  paged: boolean;
  unpaged: boolean;
}

export enum ActiveHistoryTab {
  HistoryLobby = 'History Lobby',
  SettledBets = 'Settled Bets',
  UnsettledBets = 'Unsettled Bets',
  Casino = 'Casino',
  LiveCasino = 'Live Casino',
}

export enum BetHistoryType {
  Settled = 'SETTLED',
  Unsettled = 'UNSETTLED',
  All = 'ALL',
}

export enum SelectionStatus {
  WON = 'WON',
  LOST = 'LOST',
}

export enum HistoryPeriod {
  TYPE_LATEST = 'latest',
  TYPE_PERIOD = 'period',
}

export type HistoryPeriodType = 'latest' | 'period';

export const DEFAULT_PAGE_SIZE = 10;
export interface PaginationState {
  page: number;
  pageSize: number;
  total: number;
}

export type BonusVerticalCompletion = {
  leftToWagerAmount: number;
  completionPercentage: number;
  wageredAmount: number;
  vertical: string;
};

export type BonusCompletion = {
  totalWageredAmount: number;
  totalCompletionPercentage: number;
  verticals: BonusVerticalCompletion[];
};

export type BonusVertical = {
  completionPercentage: number;
  leftToWagerAmount: number;
  vertical: string;
  wageredAmount: number;
};

export type BonuseCompletion = {
  totalCompletionPercentage: number;
  totalWageredAmount: number;
  givenBonusAmount: number;
  verticals: BonusVertical[];
};

export type BonusWagerHistory = {
  playerBonusId: number;
  bonusVertical?: string;
  game?: string;
  freeSpinsCount?: number;
  bonusStatus: keyof typeof BonusHistoryStatus; // "Deactivated" | "Expired" | "Canceled" | "Completed" | "Rejected" | "Declined" | "Reset" | "Invalid" | "Exhausted"
  bonusSubStatus: string;
  bonusSubType: BonusHistoryBonusSubType;
  bonusGivenDate: string;
  bonusActivatedDate: string;
  bonusExpirationDate: string;
  bonusCanceledDate: string;
  bonusGiven?: number;
  tangibleCount?: number;
  bonusName: string;
  bonusAchievedAmount: number;
  bonusCompletion?: BonuseCompletion;
  wageringFreeSpinGivenCount?: number;
  wageringFreeSpinAchievedAmount?: number;
  currentDepositRolloverAmount?: number;
  totalDepositRolloverAmount?: number;
  depositRolloverCompletionPercentage?: number;
};

export type HistoryApiResponse = {
  type: HistoryPeriodType;
  results: IpHistoryResponse[] | BonusWagerHistory[];
  pagination: PaginationState;
};

export type HistoryErrorValue = { type: HistoryPeriodType; error: string } | undefined;

export interface HistoryState {
  isLoading: boolean;
  isLoadingMore: boolean;
  error: Record<HistoryPeriodType, string>;
  hasFetched: Record<HistoryPeriodType, boolean>;
  hasUpdated: boolean;
  data: Record<HistoryPeriodType, IpHistoryResponse[] | BonusWagerHistory[]>;
  pagination: PaginationState;
  periodInDays: number;
}

export type DateRange = 'dateRange' | 'bonusGivenDate';

export type FetchHistoryParams = {
  fromDate?: number | null;
  toDate?: number | null;
  page?: number;
  pageSize?: number;
  isMoreLoading?: boolean;
  url: string;
  dateRange: DateRange;
};

export interface IpHistoryResponse {
  loginTime: string;
  loginTimeDate: string;
  loginIp: string;
  loginStatus: string;
  logoutTime: string;
  browserType: string;
  userAgentHeader: string;
  deviceName: string;
  clientType: string;
}

export interface DateScopeType {
  fromDate: number | null;
  toDate: number | null;
}

export enum BonusHistoryType {
  ROLLOVER = 'rollover',
  WAGERING = 'wagering',
  FREE_BET = 'freebets',
  FREE_SPIN = 'freespins',
  AWARDS = 'awards',
}

export enum BonusHistoryBonusSubType {
  WAGERING_DEFAULT = 'WageringDefault',
  WAGERING_LOCK_AMOUNT = 'WageringLockAmount',
  WAGERING_DEPOSIT_FREE_SPINS = 'WageringDepositFreeSpins',
  FREE_BET_RETRY = 'FreeBetRetry',
  FREE_BET_DEFAULT = 'FreeBetDefault',
  AWARD_DEFAULT = 'AwardDefault',
  AWARD_BOOST_ODDS = 'AwardBoostOdds',
  FREE_SPINS_DEFAULT = 'FreeSpinsDefault',
  WAGERING_FREE_SPINS_WIN = 'WageringFreeSpinsWin',
  FREE_SPINS_BONUS_BUY = 'FreeSpinsBonusBuy',
  WAGERING_DEPOSIT_FREE_SPINS_BONUS_BUY = 'WageringDepositFreeSpinsBonusBuy',
  WAGERING_FREE_SPINS_WIN_BONUS_BUY = 'WageringFreeSpinsWinBonusBuy',
  NO_AWARD = 'NoAward',
  TANGIBLE_AWARD = 'AwardTangible',
}

export enum BonusHistoryRouteType {
  WAGERING = 'wagering',
  FREE_BET = 'free-bets',
  FREE_SPIN = 'free-spins',
  AWARDS = 'awards',
}
