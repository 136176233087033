import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { CasinoUiStateTypes } from './casinoUiStateTypes';

const initialState: CasinoUiStateTypes.State = {
  categories: {},
};

const collectionInitialData: CasinoUiStateTypes.Collection = {
  loadMoreVisibility: false,
  showAllVisibility: false,
  inView: false,
};

const casinoUiState = createSlice({
  name: 'casinoUiState',
  initialState,
  reducers: {
    setCollectionLoadMore(state, action: PayloadAction<{ collectionId: string; value: boolean }>) {
      const { collectionId, value } = action.payload;
      if (state.categories[collectionId]) state.categories[collectionId].loadMoreVisibility = value;
    },
    setCollectionShowAll(state, action: PayloadAction<{ collectionId: string; value: boolean }>) {
      const { collectionId, value } = action.payload;
      state.categories[collectionId].showAllVisibility = value;
    },
    addInitialCollection(state, action: PayloadAction<{ collectionId: string }>) {
      const { collectionId } = action.payload;
      if (!(collectionId in state.categories)) {
        state.categories[collectionId] = collectionInitialData;
      }
    },
    setCategoryInView(state, action: PayloadAction<{ collectionId: string; inView: boolean }>) {
      const { collectionId, inView } = action.payload;
      if (!(collectionId in state.categories)) {
        state.categories[collectionId] = { ...collectionInitialData, inView };
      } else {
        state.categories[collectionId].inView = inView;
      }
    },
    resetCategories(state) {
      state.categories = {};
    },
  },
});

export const { setCollectionLoadMore, setCollectionShowAll, addInitialCollection, setCategoryInView, resetCategories } =
  casinoUiState.actions;

const selectCollectionLoadMore = (state: RootState, collectionId: string): boolean =>
  state.casino.casinoUiState.categories[collectionId]?.loadMoreVisibility || false;
const selectCollectionShowAll = (state: RootState, collectionId: string): boolean =>
  state.casino.casinoUiState.categories[collectionId]?.showAllVisibility || false;
const selectCategoryInView = (state: RootState, collectionId: string): CasinoUiStateTypes.Collection | undefined => {
  return state.casino.casinoUiState.categories[collectionId];
};
export const casinoUiStateSelectors = {
  collectionLoadMore: selectCollectionLoadMore,
  collectionShowAll: selectCollectionShowAll,
  getCategoryInView: selectCategoryInView,
};

export default casinoUiState.reducer;
