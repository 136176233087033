import React, { memo, useLayoutEffect, useRef } from 'react';
import clsx from 'clsx';
import { useAppSelector } from 'store';
import { Sport } from 'utils/common/types/sportTypes';
import { SportDataTypes } from 'utils/common/types/sportTypes/sportData.types';
import { dateDiffer } from 'utils/dateUtils';
import { getEventClockResult, getMinutes, getSeconds } from './utils/eventClock.utils';
import { EventSelectors } from '../types/eventProps.types';

//-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
// WARNING:
// The below code has been created with performance in mind, so it doesn't
// follow the standard react best practices for updating state.
// If you are making changes to it, please monitor the performance impact.
//-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
// 27.08.2021 Note: Changed the logic to work with math instead of just incrementing/decrementing
// the timer. This mechanism works only if the 'lastUpdate' field in 'eventClockStatus' is updated
// everytime we receive and eventClock message through sockets
// I looked at the updateActions file and found only 'updateBetSlipEventStatus'
// isn't using the 'createEventClockAction' function. This function updates
// the 'lastUpdate' field and is essential to make the timer work. Keep this in mind
// if you need to use 'EventClock' in the Betslip
//-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+

type Clock = {
  ref: React.MutableRefObject<HTMLSpanElement | null>;
  displayTime: number;
  backendTime: number;
  lastUpdate: number;
  decrement?: boolean | '' | null;
};

const getIncrementedDisplayTime = (clock: Omit<Clock, 'ref'>) => {
  const diff = Math.round(dateDiffer.seconds(Date.now(), clock.lastUpdate));
  const displayTime = clock.backendTime + diff * (clock.decrement ? -1 : 1);

  return displayTime > 0 ? displayTime : 0;
};

const clocks = new Set<Clock>();
let interval: NodeJS.Timeout;

const incrementClocks = () => {
  clocks.forEach((clock) => {
    if (clock.ref?.current) {
      clock.displayTime = getIncrementedDisplayTime(clock);
    }
  });
  //keep both loops separate to keep js and painting seperate
  clocks.forEach((clock) => {
    if (clock.ref?.current) {
      clock.ref.current.innerText = `${getMinutes(clock.displayTime)}:${getSeconds(clock.displayTime)}`;
    }
  });
};

const addClock = (clock: Clock) => {
  clocks.add(clock);

  if (clocks.size === 1) {
    interval = setInterval(incrementClocks, 1000);
  }
};

const removeClock = (clock: Clock) => {
  clocks.delete(clock);

  if (clocks.size === 0) {
    clearInterval(interval);
  }
};

interface Props {
  eventClockStatus?: Sport.EventClockStatus;
  sportId: number;
  eventStatusConfig: SportDataTypes.EventStatusConfig | null;
  tournamentId: number;
  className?: string;
  sportEventId?: number;
  eventSelectors?: EventSelectors;
}

const EventClock: React.FC<Props> = ({
  eventClockStatus: eventClockStatusProps,
  sportId,
  eventStatusConfig,
  tournamentId,
  className,
  sportEventId,
  eventSelectors,
}) => {
  const eventClockStatusSelected = useAppSelector((state) =>
    eventSelectors && sportEventId ? eventSelectors.eventClockStatus(state, sportEventId, sportId) : undefined,
  );
  const eventClockStatus = (eventClockStatusProps || eventClockStatusSelected) as Sport.EventClockStatus;
  //console.log(eventClockStatus);
  const ref = useRef<HTMLSpanElement | null>(null);
  const eventClockResult = getEventClockResult(eventClockStatus, sportId, eventStatusConfig, tournamentId);
  const backendTime = eventClockResult?.elapsedTime || 0;
  // we need to calculate the first display time
  // so that when the clock is hidden by virutalization and then appears again
  // it will not start from backendTime, but from the calculated displayTime
  // There will be no flickering when it starts with displayTime
  const initialTime =
    eventClockResult?.action === 'start'
      ? getIncrementedDisplayTime({
          backendTime,
          displayTime: backendTime,
          lastUpdate: eventClockStatus.lastUpdate,
          decrement: eventClockResult?.decrement,
        })
      : backendTime;

  useLayoutEffect(() => {
    if (eventClockResult?.action === 'start') {
      const clock: Clock = {
        ref,
        displayTime: backendTime,
        backendTime: backendTime,
        lastUpdate: eventClockStatus.lastUpdate,
        decrement: eventClockResult?.decrement,
      };
      addClock(clock);

      return () => {
        removeClock(clock);
      };
    }
  }, [eventClockStatus, eventClockResult, backendTime]);

  // this will hide eventClock only when we dont have config time or eventStatus is not live. (00:00) case in halftime and  Not Started
  if (!eventClockResult || eventClockResult.elapsedTime === null) return null;

  const clockStyles = clsx('clock', className);

  return (
    <span className={clockStyles} ref={ref}>
      {`${getMinutes(initialTime)}:${getSeconds(initialTime)}`}
    </span>
  );
};

export default memo(EventClock);
