import React from 'react';
import { ScrollWrapper } from 'modules/casino/shared/features/casinoScroll';
import { arrowStyleTypes } from 'modules/casino/shared/features/casinoScroll/casinoScrollTypes';

type Props = {
  children: React.ReactNode;
  className: string;
  centeredSlides?: boolean;
  moveWidth?: number;
};

const SliderGridWrapper: React.FC<Props> = ({ children, className, centeredSlides, moveWidth }) => {
  return (
    <div className={`${className} slider-grid`}>
      <ScrollWrapper centered={centeredSlides} typeArrow={arrowStyleTypes.CasinoGrid} moveWith={moveWidth}>
        {children}
      </ScrollWrapper>
    </div>
  );
};

export default SliderGridWrapper;
