import { t } from 'i18next';
import { setIsPrintingEjected } from 'modules/retail/modules/cashier/slice/cashier.slice';
import { formatCurrency } from 'modules/retail/modules/cashier/utils';
import { enqueuePrintRequest, processPrintQueue } from 'modules/retail/shared/slice/printQueue.slice';
import { CommandData } from 'modules/retail/shared/types/retailCommon.types';
import {
  printMethods,
  retailPrintOptions,
  commands as printCommands,
  monitorPrintingCompletion,
  printEjectCommand,
} from 'modules/retail/shared/utils/retail.utils';
import { store } from 'store';
import { getError, transformMyBetsDate } from 'utils/common/helpersCommon';
import { PrintVoucherParams } from '../types/ssbt.types';
export const printVoucher = async ({
  totalWin,
  code,
  currency,
  issuedAt,
  validUntil,
  isLinearCodeOnReceipt,
  retailAddress,
  isCurrencyBeforeAmount,
}: PrintVoucherParams) => {
  const printVoucherJob = async () => {
    const printVoucherData: CommandData[] = [];
    printMethods.printIsReady(printVoucherData, printCommands);
    printMethods.printStart(printVoucherData, printCommands);
    printMethods.print(printVoucherData, printCommands, '');
    printMethods.printOptions(printVoucherData, printCommands, retailPrintOptions._logoOptionsData);
    printMethods.printImage(printVoucherData, printCommands, retailAddress?.buLogo);
    printMethods.printOptions(printVoucherData, printCommands, retailPrintOptions._optionsData);
    printMethods.print(printVoucherData, printCommands, '');
    printMethods.printOptions(printVoucherData, printCommands, retailPrintOptions.winbetOptions);
    printMethods.print(printVoucherData, printCommands, '');
    printMethods.print(printVoucherData, printCommands, '');
    printMethods.print(printVoucherData, printCommands, ''.padEnd(44, '='));
    printMethods.print(printVoucherData, printCommands, '');
    printMethods.printOptions(printVoucherData, printCommands, retailPrintOptions._addressOptions);
    printMethods.print(printVoucherData, printCommands, t('Cashier.VoucherModal.voucherCode'));
    printMethods.print(printVoucherData, printCommands, '');
    printMethods.printOptions(printVoucherData, printCommands, retailPrintOptions.voucherValueOptions);
    printMethods.print(printVoucherData, printCommands, code);
    printMethods.print(printVoucherData, printCommands, '');
    printMethods.printOptions(printVoucherData, printCommands, retailPrintOptions.winbetOptions);
    printMethods.print(printVoucherData, printCommands, ''.padEnd(44, '='));
    printMethods.printOptions(printVoucherData, printCommands, retailPrintOptions.voucherValueOptions);
    printMethods.print(printVoucherData, printCommands, '');
    printMethods.colDefine(printVoucherData, printCommands, 290, 'middle', 'left');
    printMethods.colDefine(printVoucherData, printCommands, 290, 'middle', 'right');
    printMethods.print(printVoucherData, printCommands, t('Cashier.CashierModal.issuedDate'));
    printMethods.print(printVoucherData, printCommands, transformMyBetsDate(issuedAt));
    printMethods.colReset(printVoucherData, printCommands);
    printMethods.printOptions(printVoucherData, printCommands, retailPrintOptions.winbetOptions);
    printMethods.print(printVoucherData, printCommands, '');
    printMethods.print(printVoucherData, printCommands, ''.padEnd(44, '='));
    printMethods.print(printVoucherData, printCommands, '');
    printMethods.printOptions(printVoucherData, printCommands, retailPrintOptions.voucherValueOptions);
    printMethods.colDefine(printVoucherData, printCommands, 290, 'middle', 'left');
    printMethods.colDefine(printVoucherData, printCommands, 290, 'middle', 'right');
    printMethods.print(printVoucherData, printCommands, t('Cashier.VoucherModal.validDate'));
    printMethods.print(printVoucherData, printCommands, transformMyBetsDate(validUntil));
    printMethods.colReset(printVoucherData, printCommands);
    printMethods.print(printVoucherData, printCommands, '');
    printMethods.printOptions(printVoucherData, printCommands, retailPrintOptions.winbetOptions);
    printMethods.print(printVoucherData, printCommands, ''.padEnd(44, '='));
    printMethods.print(printVoucherData, printCommands, '');
    printMethods.printOptions(printVoucherData, printCommands, retailPrintOptions.voucherValueOptions);
    printMethods.colDefine(printVoucherData, printCommands, 290, 'middle', 'left');
    printMethods.colDefine(printVoucherData, printCommands, 290, 'middle', 'right');
    printMethods.print(printVoucherData, printCommands, t('Cashier.CashPayModal.amount'));
    printMethods.print(
      printVoucherData,
      printCommands,
      `${formatCurrency(totalWin, currency, isCurrencyBeforeAmount)}` + ' ',
    );
    printMethods.colReset(printVoucherData, printCommands);
    printMethods.print(printVoucherData, printCommands, '');
    printMethods.printOptions(printVoucherData, printCommands, retailPrintOptions.eventOptions);
    printMethods.print(printVoucherData, printCommands, ''.padEnd(44, '='));
    printMethods.print(printVoucherData, printCommands, '');
    printMethods.printOptions(printVoucherData, printCommands, retailPrintOptions.winbetAddress);
    printMethods.print(printVoucherData, printCommands, '');
    printMethods.print(printVoucherData, printCommands, '');
    printMethods.printOptions(printVoucherData, printCommands, retailPrintOptions.winbetAddress);
    printMethods.conditionalPrintBarcode(printVoucherData, printCommands, isLinearCodeOnReceipt, 'V' + code);
    printMethods.print(printVoucherData, printCommands, '');
    printMethods.print(printVoucherData, printCommands, '');
    printMethods.printQrCode(printVoucherData, printCommands, 'V' + code);
    printMethods.print(printVoucherData, printCommands, '');
    printMethods.print(printVoucherData, printCommands, '');
    printMethods.printEject(printVoucherData, printCommands);
    try {
      for (const i of printVoucherData) {
        await fetch(i.command, {
          method: 'POST',
          body: JSON.stringify(i.bodyData),
        });
        if (i.command === printEjectCommand) {
          await monitorPrintingCompletion();
        }
      }
    } catch (error) {
      getError.message(error);
    } finally {
      store.dispatch(setIsPrintingEjected(false));
      // Process the next job in the queue if any
      store.dispatch(processPrintQueue());
    }
  };

  store.dispatch(enqueuePrintRequest(printVoucherJob));

  // Check the current printing state and process the queue if no job is currently running
  const state = store.getState();
  if (!state.retail.printQueue.isPrinting) {
    store.dispatch(processPrintQueue());
  }
};
