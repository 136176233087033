import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { Text } from 'components/shared';
import { Flex } from 'components/shared/layout';

/** Terminal Modal Button */
interface ButtonProps {
  text: string;
  className?: string;
  isDisabled?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
  variant?: 'primary' | 'secondary' | 'disabled';
  textSecondary?: string;
}

const Button: React.FC<ButtonProps> = ({
  text,
  className,
  // isDisabled: disabled,
  onClick,
  variant: state,
  textSecondary,
}) => {
  // const handleClick = () => {
  //   console.log(state);
  //   state === 'disabled'
  //     ? () => {
  //         return;
  //       }
  //     : onClick;
  // };

  const cssNS = 'terminal-modal__btn';
  const btnClass = clsx(cssNS, state && `${cssNS}--${state}`, className);

  return (
    <Flex.ACJB
      className={btnClass}
      onClick={
        state === 'disabled'
          ? () => {
              return;
            }
          : onClick
      }
    >
      <Text className={`${cssNS}-text`} text={text} />
      {textSecondary && <Text className={`${cssNS}-text--secondary`} text={textSecondary} />}
    </Flex.ACJB>
  );
};

export default Button;
