import useGeneralCasinoType from 'modules/casino/shared/hooks/useGeneralCasinoType';
import { useAppSelector } from 'store';
import { gameProvidersSelectors } from '../slice/casinoGameProvidersSlice';
import { ProvidersTypes } from '../types/casinoGameProvidersTypes';
import { getCasinoProviderKey } from '../utils/casinoGameProvidersUtils';

export const useProviderDataByKey = (
  providerAlias: string | undefined,
  vertical?: string,
): ProvidersTypes.ProviderDataByKey => {
  const { casinoType } = useGeneralCasinoType();
  const providerKey = getCasinoProviderKey(vertical || casinoType, providerAlias);
  const provider = useAppSelector((state) => gameProvidersSelectors.providerDataByKey(state, providerKey));

  return { ...provider };
};
