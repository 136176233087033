import React, { useRef } from 'react';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { CategoryQueryType } from 'modules/casino/shared/constants';
import { Casino } from 'modules/casino/shared/types';
import { useCategoryGamesIds } from '../../../cellls/hooks/useCategoryGamesIds';
import { useCategoryTotalElements } from '../../../cellls/hooks/useCategoryTotalElements';
import { useHandleVerticalItems } from '../../hooks/useHandleVerticalItems';
import { GridLoadingBehaviorType } from '../../types/gridTypes';
import CategoryLayoutFooterHandler from '../CategoryLayoutFooterHandler';
import GenericLayoutWrapper from '../layouts/GenericLayoutWrapper';
import { LoadMore } from '../loadMore/LoadMore';

type Props = {
  children: (
    itemId: string,
    key: number,
    isLastItem: boolean,
    gridPage: number,
    gamesRenderedCount: number,
    skipLazyLoad: boolean,
  ) => React.ReactNode;
  subType: CategorySubTypes;
  layoutConfig: Casino.LayoutConfig;
  className: string;
  gridLoadingBehavior: GridLoadingBehaviorType;
  fetchMoreGames: () => void;
  collectionId: string;
  categoryLayout: CategoryLayoutTypes;
  isCategoryLast?: boolean;
  categoryId: string;
  query: CategoryQueryType;
};

const VerticalGrid: React.FC<Props> = ({
  children,
  subType,
  layoutConfig,
  className,
  gridLoadingBehavior,
  fetchMoreGames,
  collectionId,
  categoryLayout,
  isCategoryLast,
  categoryId,
  query,
}) => {
  const { layoutRows, layoutGrid } = layoutConfig;
  const gamesIds = useCategoryGamesIds({ categoryLayoutType: categoryLayout, categoryId });
  const totalElements = useCategoryTotalElements({ categoryLayoutType: categoryLayout, categoryId });
  const layoutRef = useRef<HTMLDivElement | null>(null);
  const { getGeneratedElements, stopLoading, isInitialPage, loadMoreData } = useHandleVerticalItems({
    children,
    categoryLayout,
    collectionId,
    gamesIds,
    totalElements,
    layoutRows,
    fetchMoreGames,
    subType,
    gridLoadingBehavior,
    layoutRef,
    layoutGrid,
    query,
  });

  return (
    <div className={`${className} vertical-grid`}>
      <GenericLayoutWrapper layoutGrid={layoutGrid} subType={subType} rows={layoutRows} forwardRef={layoutRef}>
        {getGeneratedElements()}
      </GenericLayoutWrapper>

      <LoadMore
        gridLoadingBehavior={gridLoadingBehavior}
        fetchMoreData={loadMoreData}
        isInitialPage={isInitialPage()}
        stopLoading={stopLoading()}
        collectionId={collectionId}
      />

      {categoryLayout !== CategoryLayoutTypes.EXTERNAL_CATEGORY ? (
        <CategoryLayoutFooterHandler
          categoryLayout={categoryLayout}
          isCategoryLast={isCategoryLast}
          isInfinityScrollCollection={gridLoadingBehavior === GridLoadingBehaviorType.InfinityScroll}
          stopLoading={stopLoading()}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default VerticalGrid;
