import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { UI } from 'components/shared/UI';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { useNavigateToBankTab } from 'pages/myAccount/tabs/cashier/utils/cashier.utils';
import { LayoutConfigTypes, VerticalsConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { refreshWidget } from 'shared/common/features/integrations/cashierWidget/cashierWidget.slice';
import { selectIntegrations } from 'shared/common/features/integrations/slice/integrations.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import config from 'utils/config';
import { MPDUI } from '../../../components/MPDUI';
import { LevelProgressNew } from '../../../components/WinCoins/LevelProgressNew';
import { useMyProfileConfig } from '../../../hooks/useMyProfileConfig';
import { selectMyProfile, showMyProfileModal } from '../../../slice/myProfile.slice';

const MyProfileGamificationSectionInfo: React.FC = () => {
  const hideDepositButton = useMyProfileConfig()?.hideDepositButton;
  const [setError, setSetError] = useState(false);
  const user = useAppSelector(selectMyProfile.dataUser);
  const gamificationProfile = useAppSelector(selectIntegrations.getGamificationProfile);
  const showPlayerLevel = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.VERTICALS,
    deviceLayout: 'general',
  })?.[VerticalsConfigTypes.GAMIFICATION]?.showPlayerLevel;
  const bankNavigate = useNavigateToBankTab();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const navigateToBank = () => {
    dispatch(showMyProfileModal(false));
    bankNavigate('deposit');
    dispatch(refreshWidget());
  };

  const cssNS = 'mpd-section-gamification';

  return (
    <MPDUI.Section section="gamification">
      <div className={`${cssNS}__wrapper${!showPlayerLevel ? ` ${cssNS}__wrapper-compact` : ''}`}>
        <div className={`${cssNS}__avatar${!showPlayerLevel ? ` ${cssNS}__avatar-compact` : ''}`}>
          {gamificationProfile?.playerLevel?.imageUrl && !setError ? (
            <img
              className="wh-100"
              onError={() => setSetError(true)}
              src={`${config.CDN_IMAGE_API}fit=cover,width=160,height=160,,format=auto/${gamificationProfile.playerLevel.imageUrl}`}
            />
          ) : (
            <Icon className={`${cssNS}__logo`} icon={'user'} />
          )}
        </div>
        <div className={`${cssNS}__info${!showPlayerLevel ? ` d-flex-center` : ''}`}>
          <Flex.ACJB className="w-100">
            <div className={`${cssNS}__player`}>
              <div className={`${cssNS}__name`}>{`${user?.firstName || ''}`}</div>
              <div>{user?.username}</div>
            </div>
            {!hideDepositButton && (
              <UI.Button
                data-qid="userDepositBtn"
                size="sm"
                onClick={navigateToBank}
                text={t(MyProfile.Button.depositProfileBtn)}
              />
            )}
          </Flex.ACJB>
          {showPlayerLevel && gamificationProfile?.playerLevel?.name && (
            <>
              <div className={`${cssNS}__line`}></div>
              <LevelProgressNew gamificationProfile={gamificationProfile} />
            </>
          )}
        </div>
      </div>
    </MPDUI.Section>
  );
};

export default memo(MyProfileGamificationSectionInfo);
