import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/shared';
import { useAppSelector } from 'store';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import { cssGlobalNS } from 'utils/common/types/css.types';
import { SCRequestBetNotification, SCRequestBetNotificationIconStyle } from './styled';
import { selectRequestBet } from '../slice/requestBet.slice';

type Props = {
  icon: string;
  text: string;
};
const RequestBetNotification: React.FC<Props> = ({ icon, text }) => {
  const error = useAppSelector(selectRequestBet.error) || text;
  const isSubmited = useAppSelector(selectRequestBet.isSubmited);
  const { t } = useTranslation();

  if (!error && !isSubmited) return null;
  const isError = !isSubmited;

  return (
    <SCRequestBetNotification
      className={`d-flex-ac w-100 ${cssGlobalNS}-rb__box--sm ${cssGlobalNS}-rb__notification ${
        isSubmited ? `${cssGlobalNS}-rb__notification--active` : ''
      }`}
    >
      {icon && (
        <Icon
          className={`${cssGlobalNS}-rb__notification-icon`}
          icon={icon}
          style={SCRequestBetNotificationIconStyle}
        />
      )}
      {isError ? t(error) : t(Promotions.RequestBet.successText)}
    </SCRequestBetNotification>
  );
};

export default memo(RequestBetNotification);
