import { CasinoWidgetTypes } from 'modules/casino/platformImplementation/types/casinoWidget.types';
import { altenarRouteMap } from 'modules/sport/altenarSportsBook/utils/altenarImplementationPage.utils';
import { SportNavigationObject } from 'modules/sport/sportsBook/types/sportsImplementationPage.types';
import { SportsBookType } from '../types/integrations.types';

export const navigateToIntegration = ({ fullPath, ...navParams }: SportNavigationObject): void | null => {
  let widgetType: `${SportsBookType}` | undefined = undefined;
  if (window.widgetSportsBook) {
    widgetType = SportsBookType.EGTD;
  } else if (window.altenarWSDK) {
    widgetType = SportsBookType.ALTENAR;
  }

  switch (widgetType) {
    case SportsBookType.EGTD:
      return window.widgetSportsBook?.navigate({ fullPath, ...navParams });
    case SportsBookType.ALTENAR:
      return window.altenarWSDK.set({
        page: fullPath ? altenarRouteMap?.[fullPath] : (undefined ?? 'overview'),
      });
    default:
      return null;
  }
};

export const navigateToCasinoIntegration = ({
  fullPath,
  ...navParams
}: CasinoWidgetTypes.NavigationParams): void | null => {
  window.widgetCasino?.navigate({ fullPath, ...navParams });
};
