import { Translations } from './translationTypes';

export const LiveSports: Translations.LiveSports = {
  FavouritesContainer: {
    noFavouriteEventsMessage: 'LiveSports.FavouritesContainer.noFavouriteEventsMessage',
  },
  EventsHeader: {
    neutralGround: 'LiveSports.EventsHeader.neutralGround',
    hasGoal: 'LiveSports.EventsHeader.hasGoal',
  },
  VideoEvents: {
    eventsCounter: 'LiveSports.VideoEvents.eventsCounter',
    headerTitle: 'LiveSports.VideoEvents.headerTitle',
  },
  EventsContainer: {
    noLiveEventsMessage: 'LiveSports.EventsContainer.noLiveEventsMessage',
  },
  Messages: {
    noLiveEventsText: 'LiveSports.Messages.noLiveEventsText',
  },
  Landing: {
    eventsText: 'LiveSports.Landing.eventsText',
  },
  LiveVideoContainer: {
    noLiveEventsMessage: 'LiveSports.LiveVideoContainer.noLiveEventsMessage',
  },
  AllEvents: {
    headerTitle: 'LiveSports.AllEvents.headerTitle',
  },
  Badge: {
    labelText: 'LiveSports.Badge.labelText',
  },
};

export const UpcomingEvents: Translations.UpcomingEvents = {
  UpcomingEventsBreadcrumb: {
    hoursText: 'UpcomingEvents.UpcomingEventsBreadcrumb.hoursText',
    nextText: 'UpcomingEvents.UpcomingEventsBreadcrumb.nextText',
  },
  TabView: {
    allMatchesText: 'allMatchesText',
    topLeaguesText: 'topLeaguesText',
  },
  UpcomingEventsFilterList: {
    upcomingHeaderTitle: 'UpcomingEvents.UpcomingEventsFilterList.upcomingHeaderTitle',
  },
};

export const BetSlip: Translations.BetSlip = {
  Messages: {
    noBetsSelectedMessage: 'BetSlip.Messages.noBetsSelectedMessage',
    selectionsRestrictionError: 'BetSlip.Messages.selectionsRestrictionError',
    acceptChangesMessage: 'BetSlip.Messages.acceptChangesMessage',
    maxAmount: 'BetSlip.Messages.maxAmount',
    maxSelectionCountMessage: 'BetSlip.Messages.maxSelectionCountMessage',
    minAmount: 'BetSlip.Messages.minAmount',
  },
  OddsChangeSettingsTitle: {
    oddsChangeTitle: 'BetSlip.OddsChangeSettingsTitle.oddsChangeTitle',
  },
  Selections: {
    returnSelectionsText: 'BetSlip.Selections.returnSelectionsText',
    bankerLetter: 'BetSlip.Selections.bankerLetter',
    selectionsString: 'BetSlip.Selections.selectionsString',
  },
  PlaceBetButton: {
    possibleWinText: 'BetSlip.PlaceBetButton.possibleWinText',
    acceptChangesBtnText: 'BetSlip.PlaceBetButton.acceptChangesBtnText',
    loadingBtnText: 'BetSlip.PlaceBetButton.loadingBtnText',
    unavailableBtnText: 'BetSlip.PlaceBetButton.unavailableBtnText',
    placeBetText: 'BetSlip.PlaceBetButton.placeBetText',
    ticketReoffer: 'BetSlip.PlaceBetButton.ticketReoffer',
  },
  BetSlipConfirmation: {
    summaryTotalWinText: 'BetSlip.BetSlipConfirmation.summaryTotalWinText',
    confirmationButton: 'BetSlip.BetSlipConfirmation.confirmationButton',
    headerConfirmationText: 'BetSlip.BetSlipConfirmation.headerConfirmationText',
    winInfoText: 'BetSlip.BetSlipConfirmation.winInfoText',
    stakeInfoText: 'BetSlip.BetSlipConfirmation.stakeInfoText',
    summaryTotalStakeText: 'BetSlip.BetSlipConfirmation.summaryTotalStakeText',
  },
  Systems: {
    stateText: 'BetSlip.Systems.stateText',
    folds: 'BetSlip.Systems.folds',
    heinz: 'BetSlip.Systems.heinz',
    yankee: 'BetSlip.Systems.yankee',
    canadian: 'BetSlip.Systems.canadian',
    doubles: 'BetSlip.Systems.doubles',
    eight_folds: 'BetSlip.Systems.eight_folds',
    eleven_folds: 'BetSlip.Systems.eleven_folds',
    five_folds: 'BetSlip.Systems.five_folds',
    four_folds: 'BetSlip.Systems.four_folds',
    fourteen_folds: 'BetSlip.Systems.fourteen_folds',
    goliath: 'BetSlip.Systems.goliath',
    lucky_31: 'BetSlip.Systems.lucky_31',
    lucky_15: 'BetSlip.Systems.lucky_15',
    lucky_63: 'BetSlip.Systems.lucky_63',
    moreMultiplesText: 'BetSlip.Systems.moreMultiplesText',
    nine_folds: 'BetSlip.Systems.nine_folds',
    patent: 'BetSlip.Systems.patent',
    twelve_folds: 'BetSlip.Systems.twelve_folds',
    round_robin: 'BetSlip.Systems.round_robin',
    seven_folds: 'BetSlip.Systems.seven_folds',
    singles: 'BetSlip.Systems.singles',
    six_folds: 'BetSlip.Systems.six_folds',
    superheinz: 'BetSlip.Systems.superheinz',
    ten_folds: 'BetSlip.Systems.ten_folds',
    thirteen_folds: 'BetSlip.Systems.thirteen_folds',
    trebles: 'BetSlip.Systems.trebles',
    trixie: 'BetSlip.Systems.trixie',
    fifteen_folds: 'BetSlip.Systems.fifteen_folds',
    sixteen_folds: 'BetSlip.Systems.sixteen_folds',
    seventeen_folds: 'BetSlip.Systems.seventeen_folds',
    eighteen_folds: 'BetSlip.Systems.eighteen_folds',
    nineteen_folds: 'BetSlip.Systems.nineteen_folds',
    twenty_folds: 'BetSlip.Systems.twenty_folds',
    twentyone_folds: 'BetSlip.Systems.twentyone_folds',
    twentytwo_folds: 'BetSlip.Systems.twentytwo_folds',
    twentythree_folds: 'BetSlip.Systems.twentythree_folds',
    twentyfour_folds: 'BetSlip.Systems.twentyfour_folds',
    twentyfive_folds: 'BetSlip.Systems.twentyfive_folds',
    twentysix_folds: 'BetSlip.Systems.twentysix_folds',
    twentyseven_folds: 'BetSlip.Systems.twentyseven_folds',
    twentyeight_folds: 'BetSlip.Systems.twentyeight_folds',
    twentynine_folds: 'BetSlip.Systems.twentynine_folds',
    thirty_folds: 'BetSlip.Systems.thirty_folds',
  },
  Credits: {
    netProfit: 'BetSlip.Credits.netProfit',
    useCredits: 'BetSlip.Credits.useCredits',
    usedOddsBoost: 'BetSlip.Credits.usedOddsBoost',
  },
  BetSlipExpander: {
    betslipExpanderText: 'BetSlip.BetSlipExpander.betslipExpanderText',
  },
  Title: {
    betSlipTitle: 'BetSlip.Title.betSlipTitle',
  },
  Header: {
    betslipTypeBankers: 'betslipTypeBankers',
    betslipTypeStandart: 'betslipTypeStandart',
    clearSelections: 'BetSlip.Header.clearSelections',
  },
  SSBT: {
    totalOdds: 'BetSlip.SSBT.totalOdds',
    ticketNumber: 'BetSlip.SSBT.ticketNumber',
    acceptedTicket: 'BetSlip.SSBT.acceptedTicket',
    bonusText: 'BetSlip.SSBT.bonusText',
    bonusPercentageText: 'BetSlip.SSBT.bonusPercentageText',
  },
  ReuseSelectionBtn: {
    Text: 'BetSlip.ReuseSelectionBtn.Text',
  },
  FreeBetErrorMsg: {
    notQualify: 'BetSlip.freeBetErrorMsg.notQualify',
    singleMinOdds: 'BetSlip.freeBetErrorMsg.singleMinOdds',
    multipleMinOdds: 'BetSlip.freeBetErrorMsg.multipleMinOdds',
    cantBeUsedMultiple: 'BetSlip.freeBetErrorMsg.cantBeUsedMultiple',
    cantBeUsedSystems: 'BetSlip.freeBetErrorMsg.cantBeUsedSystems',
    minFreeBetAmount: 'BetSlip.freeBetErrorMsg.minFreeBetAmount',
    cantBeSplit: 'BetSlip.freeBetErrorMsg.cantBeSplit',
    maxBetAmount: 'BetSlip.freeBetErrorMsg.maxBetAmount',
    minBetAmount: 'BetSlip.freeBetErrorMsg.minBetAmount',
    invalidConfig: 'BetSlip.freeBetErrorMsg.invalidConfig',
    notAllowEnhancedMarkets: 'BetSlip.freeBetErrorMsg.notAllowEnhancedMarkets',
  },
};

export const Authentication: Translations.Authentication = {
  ResetPassword: {
    tryAgainMessage: 'Authentication.ResetPassword.tryAgainMessage',
    newPasswordLabel: 'Authentication.ResetPassword.newPasswordLabel',
    homePageForwardText: 'Authentication.ResetPassword.homePageForwardText',
    notMatchingPasswordWarning: 'Authentication.ResetPassword.notMatchingPasswordWarning',
    passwordChangeSuccessfull: 'Authentication.ResetPassword.passwordChangeSuccessfull',
    passwordCharactersText: 'Authentication.ResetPassword.passwordCharactersText',
    passwordSuggestions: 'Authentication.ResetPassword.passwordSuggestions',
    repeatPasswordLabel: 'Authentication.ResetPassword.repeatPasswordLabel',
    savePasswordText: 'Authentication.ResetPassword.savePasswordText',
    strongPasswordText: 'Authentication.ResetPassword.strongPasswordText',
  },

  ForgotPassword: {
    errorWarningText: 'Authentication.ForgotPassword.errorWarningText',
    goToHomepageMessage: 'Authentication.ForgotPassword.goToHomepageMessage',
    emailInstructionsMessage: 'Authentication.ForgotPassword.emailInstructionsMessage',
  },

  ErrorSubheader: {
    clickHereText: 'Authentication.ErrorSubheader.clickHereText',
    tryAgainText: 'Authentication.ErrorSubheader.tryAgainText',
    retrieveAccText: 'Authentication.ErrorSubheader.retrieveAccText',
  },

  Button: {
    closeModalButton: 'Authentication.Button.closeModalButton',
  },

  Error: {
    loginFailed: 'Authentication.Error.loginFailed',
    incorrectCredentials: 'Authentication.Error.incorrectCredentials',
  },
};

export const Virtual: Translations.Virtual = {
  PopUp: {
    popUpHeaderText: 'Virtual.PopUp.popUpHeaderText',
  },

  Header: {
    headerTextForecast: 'Virtual.Header.headerTextForecast',
    headerTextPlace: 'Virtual.Header.headerTextPlace',
    headerTextReverseForecast: 'Virtual.Header.headerTextReverseForecast',
    headerTextReverseTricast: 'Virtual.Header.headerTextReverseTricast',
    headerTextSecond: 'Virtual.Header.headerTextSecond',
    headerTextThird: 'Virtual.Header.headerTextThird',
    headerTextTricast: 'Virtual.Header.headerTextTricast',
    headerTextWin: 'Virtual.Header.headerTextWin',
    headerTextFirst: 'Virtual.Header.headerTextFirst',
  },
  Button: {
    buttonAddToBetslip: 'Virtual.Button.buttonAddToBetslip',
  },
  Messages: {
    eventEndedText: 'Virtual.Messages.eventEndedText',
    noActiveMarketsText: 'Virtual.Messages.noActiveMarketsText',
    noEventsText: 'Virtual.Messages.noEventsText',
    noProviderText: 'Virtual.Messages.noProviderText',
    noSportsVirtualText: 'Virtual.Messages.noSportsVirtualText',
    noDataText: 'Virtual.Messages.noDataText',
    errorMessage: 'Virtual.Messages.errorMessage',
  },
};

export const SportTypeLobby: Translations.SportTypeLobby = {
  Breadcrumb: {
    sport: 'SportTypeLobby.Breadcrumb.sport',
    statistics: 'SportTypeLobby.Breadcrumb.statistics',
  },
  Messages: {
    noCategoriesText: 'SportTypeLobby.Messages.noCategoriesText',
    noEventsText: 'SportTypeLobby.Messages.noEventsText',
  },
  ESports: {
    allTournaments: 'SportTypeLobby.ESports.allTournaments',
  },
  LiveEventsCount: {
    multiLiveEventsCountText: 'SportTypeLobby.LiveEventsCount.multiLiveEventsCountText',
    singleLiveEventCountText: 'SportTypeLobby.LiveEventsCount.singleLiveEventCountText',
  },
};

export const SportsProgram: Translations.SportsProgram = {
  Video: {
    videoText: 'SportsProgram.Video.videoText',
  },
  Favourite: {
    favouriteText: 'SportsProgram.Favourite.favouriteText',
  },
  Schedule: {
    eventNumberLabel: 'SportsProgram.Schedule.eventNumberLabel',
    eventsNumberLabel: 'SportsProgram.Schedule.eventsNumberLabel',
    calendarLabel: 'SportsProgram.Schedule.calendarLabel',
    todayLabel: 'SportsProgram.Schedule.todayLevel',
    scheduleText: 'SportsProgram.Schedule.scheduleText',
    noScheduleMessage: 'SportsProgram.Schedule.noScheduleMessage',
    daysText: 'SportsProgram.Schedule.daysText',
  },
};

export const Registration: Translations.Registration = {
  Confirmation: {
    emailVerificationText: 'Registration.Confirmation.emailVerificationText',
    activationErrorTitle: 'Registration.Confirmation.activationErrorTitle',
    activationSuccess: 'Registration.Confirmation.activationSuccess',
    activationSuccessSuggest: 'Registration.Confirmation.activationSuccessSuggest',
    notRecievedEmailLabel: 'Registration.Confirmation.notRecievedEmailLabel',
    notRecievedEmailLink: 'Registration.Confirmation.notRecievedEmailLink',
    notRecievedEmailPostLinkText: 'Registration.Confirmation.notRecievedEmailPostLinkText',
    registerConfirmText: 'Registration.Confirmation.registerConfirmText',
    successfullyResendedEmail: 'Registration.Confirmation.successfullyResendedEmail',
  },
  HelpDropdownMenu: {
    helpText: 'Registration.HelpDropdownMenu.helpText',
  },
  ValidationRules: {
    acceptTermsrequired: 'Registration.ValidationRules.acceptTermsrequired',
    ucnAddicted: 'Registration.ValidationRules.ucnAddicted',
    confirmNewPasswordpattern: 'Registration.ValidationRules.confirmNewPasswordpattern',
    confirmNewPasswordrequired: 'Registration.ValidationRules.confirmNewPasswordrequired',
    currencyrequired: 'Registration.ValidationRules.currencyrequired',
    ucnBlacklisted: 'Registration.ValidationRules.ucnBlacklisted',
    emailmaxLength: 'Registration.ValidationRules.emailmaxLength',
    emailpattern: 'Registration.ValidationRules.emailpattern',
    emailrequired: 'Registration.ValidationRules.emailrequired',
    emailminLength: 'Registration.ValidationRules.emailminLength',
    emailvalidate: 'Registration.ValidationRules.emailvalidate',
    firstNamemaxLength: 'Registration.ValidationRules.firstNamemaxLength',
    firstNameminLength: 'Registration.ValidationRules.firstNameminLength',
    firstNamepattern: 'Registration.ValidationRules.firstNamepattern',
    firstNamerequired: 'Registration.ValidationRules.firstNamerequired',
    lastNameminLength: 'Registration.ValidationRules.lastNameminLength',
    citizenshiprequired: 'Registration.ValidationRules.citizenshiprequired',
    newPasswordpattern: 'Registration.ValidationRules.newPasswordpattern',
    newPasswordrequired: 'Registration.ValidationRules.newPasswordrequired',
    confirmNewPasswordminLength: 'Registration.ValidationRules.confirmNewPasswordminLength',
    newPasswordminLength: 'Registration.ValidationRules.newPasswordminLength',
    lastNamemaxLength: 'Registration.ValidationRules.lastNamemaxLength',
    lastNamerequired: 'Registration.ValidationRules.lastNamerequired',
    newPasswordmaxLength: 'Registration.ValidationRules.newPasswordmaxLength',
    pinConfirmmaxLength: 'Registration.ValidationRules.pinConfirmmaxLength',
    over18required: 'Registration.ValidationRules.over18required',
    passwordConfirmvalidate: 'Registration.ValidationRules.passwordConfirmvalidate',
    passwordConfirmvalidatew: 'Registration.ValidationRules.passwordConfirmvalidatew',
    passwordminLength: 'Registration.ValidationRules.passwordminLength',
    passwordpattern: 'Registration.ValidationRules.passwordpattern',
    passwordrequired: 'Registration.ValidationRules.passwordrequired',
    phoneNumberminLength: 'Registration.ValidationRules.phoneNumberminLength',
    phoneNumberpattern: 'Registration.ValidationRules.phoneNumberpattern',
    pinConfirmminLength: 'Registration.ValidationRules.pinConfirmminLength',
    pinConfirmpattern: 'Registration.ValidationRules.pinConfirmpattern',
    pinConfirmrequired: 'Registration.ValidationRules.pinConfirmrequired',
    pinConfirmvalidate: 'Registration.ValidationRules.pinConfirmvalidate',
    pinmaxLength: 'Registration.ValidationRules.pinmaxLength',
    pinminLength: 'Registration.ValidationRules.pinminLength',
    pinpattern: 'Registration.ValidationRules.pinpattern',
    pinrequired: 'Registration.ValidationRules.pinrequired',
    lastNamepattern: 'Registration.ValidationRules.lastNamepattern',
    passwordMaxLength: 'Registration.ValidationRules.passwordmaxLength',
    politicallyExposedrequired: 'Registration.ValidationRules.politicallyExposedrequired',
    phoneNumberrequired: 'Registration.ValidationRules.phoneNumberrequired',
    promoCodeminLength: 'Registration.ValidationRules.promoCodeminLength',
    surnamepattern: 'Registration.ValidationRules.surnamepattern',
    surnamerequired: 'Registration.ValidationRules.surnamerequired',
    surnameminLength: 'Registration.ValidationRules.surnameminLength',
    ucnmaxLength: 'Registration.ValidationRules.ucnmaxLength',
    ucnminLength: 'Registration.ValidationRules.ucnminLength',
    ucnrequired: 'Registration.ValidationRules.ucnrequired',
    phoneNumbermaxLength: 'Registration.ValidationRules.phoneNumbermaxLength',
    promoCodemaxLength: 'Registration.ValidationRules.promoCodemaxLength',
    usernameminLength: 'Registration.ValidationRules.usernameminLength',
    usernamepattern: 'Registration.ValidationRules.usernamepattern',
    surnamemaxLength: 'Registration.ValidationRules.surnamemaxLength',
    usernamemaxLength: 'Registration.ValidationRules.usernamemaxLength',
    usernamerequired: 'Registration.ValidationRules.usernamerequired',
  },
  ThirdStep: {
    acceptTermsText: 'Registration.ThirdStep.acceptTermsText',
    affiliateCodeText: 'Registration.ThirdStep.affiliateCodeText',
    politicallyExposed: 'Registration.ThirdStep.politicallyExposed',
    existingUsername: 'Registration.ThirdStep.existingUsername',
    notExistingPromoCode: 'Registration.ThirdStep.notExistingPromoCode',
    over18Text: 'Registration.ThirdStep.over18Text',
    passwordConfirmText: 'Registration.ThirdStep.passwordConfirmText',
    passwordLabel: 'Registration.ThirdStep.passwordLabel',
    passwordRequirements: 'Registration.ThirdStep.passwordRequirements',
    pinPlaceholderText: 'Registration.ThirdStep.pinPlaceholderText',
    pinText: 'Registration.ThirdStep.pinText',
    promoCodeText: 'Registration.ThirdStep.promoCodeText',
    registerButtonLabel: 'Registration.ThirdStep.registerButtonLabel',
    termsOfUseText: 'Registration.ThirdStep.termsOfUseText',
    ucnvalidate: 'Registration.ThirdStep.ucnvalidate',
    usernameLabel: 'Registration.ThirdStep.usernameLabel',
    pinConfirmText: 'Registration.ThirdStep.pinConfirmText',
  },
  RegistrationComponents: {
    newsViaMailText: 'Registration.RegistrationComponents.newsViaMailText',
    currencyDropdown: 'Registration.RegistrationComponents.currencyDropdown',
  },
  FirstStep: {
    birthdayPickerLabel: 'Registration.FirstStep.birthdayPickerLabel',
    genderLabel: 'Registration.FirstStep.genderLabel',
    lastNameLabel: 'Registration.FirstStep.lastNameLabel',
    invalidBirthdayPickerText: 'Registration.FirstStep.invalidBirthdayPickerText',
    nameLabel: 'Registration.FirstStep.nameLabel',
    notMatchingAccountWarning: 'Registration.FirstStep.notMatchingAccountWarning',
    tryAgaintText: 'Registration.FirstStep.tryAgaintText',
  },
  SecondStep: {
    ucnExisting: 'Registration.SecondStep.ucnExisting',
    chooseOptionText: 'Registration.SecondStep.chooseOptionText',
    citizenLabel: 'Registration.SecondStep.citizenLabel',
    currencyLabel: 'Registration.SecondStep.currencyLabel',
    emailLabel: 'Registration.Confirmation.notRecievedEmailLabel',
    emailExisting: 'Registration.SecondStep.emailExisting',
    existingPhoneNumber: 'Registration.SecondStep.existingPhoneNumber',
    phoneNumberLabel: 'Registration.SecondStep.phoneNumberLabel',
    ucnLabel: 'Registration.SecondStep.ucnLabel',
    ucnValidationLabel: 'Registration.SecondStep.ucnValidationLabel',
  },
  Header: {
    createNewAccText: 'Registration.Header.createNewAccText',
  },
  DatePicker: {
    dayText: 'Registration.DatePicker.dayText',
    monthText: 'Registration.DatePicker.monthText',
    yearText: 'Registration.DatePicker.yearText',
  },
};

export const LiveTrackerTranslations: Translations.LiveTracker = {
  Header: {
    liveTrackingHeader: 'LiveTracker.Header.liveTrackingHeader',
  },
  Modal: {
    matchPreviewModalHeader: 'LiveTracker.Modal.matchPreviewModalHeader',
  },
};

export const EventTranslation: Translations.Event = {
  Score: {
    eSportsMap: 'Event.Score.eSportsMap',
    eSportsRound: 'Event.Score.eSportsRound',
  },
};

export const LoginInfoModal: Translations.LoginInfoModal = {
  AccountAutoLock: {
    Title: 'LoginInfoModal.AccountAutoLock.Title',
    Link: 'LoginInfoModal.AccountAutoLock.Link',
    Description: 'LoginInfoModal.AccountAutoLock.Description',
  },
  ForceResetPassword: {
    Title: 'LoginInfoModal.ForceResetPassword.Title',
    Link: 'LoginInfoModal.ForceResetPassword.Link',
  },
};

export const QuickLoginFormTranslation: Translations.QuickLoginForm = {
  EmailRules: {
    emailRequired: 'Registration.ValidationRules.emailrequired',
  },
  Button: {
    closeButton: 'QuickLoginForm.Button.closeButton',
  },
  Placeholder: {
    descriptionTextPlaceholder: 'QuickLoginForm.Placeholder.descriptionTextPlaceholder',
    retrievePasswordPlaceholder: 'QuickLoginForm.Placeholder.retrievePasswordPlaceholder',
  },
  Buttons: {
    forgottenPassword: 'QuickLoginForm.Buttons.forgottenPassword',
    joinUsLink: 'QuickLoginForm.Buttons.joinUsLink',
    sendButton: 'QuickLoginForm.Buttons.sendButton',
    signIn: 'QuickLoginForm.Buttons.signIn',
    noAccount: 'QuickLoginForm.Buttons.noAccount',
  },
  MobileApp: {
    saveCredentialsLabel: 'QuickLoginForm.MobileApp.saveCredentialsLabel',
  },
};

export const SideBarXContent: Translations.SideBarXContent = {
  Header: {
    betSlipHeader: 'SideBarXContent.Header.betSlipHeader',
    myBetsHeader: 'SideBarXContent.Header.myBetsHeader',
    sportsHeader: 'SideBarXContent.Header.sportsHeader',
    topDailyHeader: 'SideBarXContent.Header.topDailyHeader',
    topLeaguesHeader: 'SideBarXContent.Header.topLeaguesHeader',
  },
  Filters: {
    SectionTitle: 'SideBarXContent.Filters.SectionTitle',
  },
};

export const EventPartTranslation: Translations.EventPart = {
  PartGame: {
    gameString: 'EventPart.PartGame.gameString',
    setString: 'EventPart.PartGame.setString',
  },
};

export const ContentHeaderTranslation: Translations.ContentHeader = {
  Content: {
    liveCountText: 'ContentHeader.Content.liveCountText',
  },
};

export const SportLobbyLive: Translations.SportLobbyLive = {
  Header: {
    liveEventsText: 'SportLobbyLive.Header.liveEventsText',
    viewAllText: 'SportLobbyLive.Header.viewAllText',
  },
};

export const TopEventsTranslations: Translations.TopEvents = {
  Header: {
    topEventsText: 'TopEvents.Header.topEventsText',
  },
};

export const LeagueViewTranslations: Translations.LeagueView = {
  Messages: {
    noEventsLeagueText: 'LeagueView.Messages.noEventsLeagueText',
    noDataText: 'LeagueView.Messages.noDataText',
    notActiveText: 'LeagueView.Messages.notActiveText',
    eventNotActiveText: 'LeagueView.Messages.eventNotActiveText',
  },
};

export const EventViewTranslations: Translations.EventView = {
  MarketTemplate: {
    showMore: 'EventView.MarketTemplate.showMore',
    AlternativeText: 'EventView.MarketTemplate.AlternativeText',
    showLess: 'EventView.MarketTemplate.showLess',
  },
  Messages: {
    eventEnded: 'EventView.Messages.eventEnded',
    bettingStopped: 'EventView.Messages.bettingStopped',
    eventNotActiveText: 'EventView.Messages.eventNotActiveText',
  },

  Breadcrumb: { liveScreenName: 'EventView.Breadcrumb.liveScreenName' },
  BoostedBets: {
    containerTitle: 'EventView.BoostedBets.containerTitle',
    betTitle: 'EventView.BoostedBets.betTitle',
  },
};

export const SSBT: Translations.SSBT = {
  TerminalModal: {
    addMoreMoneyText: 'SSBT.TerminalModal.addMoreMoneyText',
    closeTerminalModalText: 'SSBT.TerminalModal.closeTerminalModalText',
    checkTicketTextModal: 'SSBT.TerminalModal.checkTicketTextModal',
    checkTicketTextModalPlaceHolder: 'SSBT.TerminalModal.checkTicketTextModalPlaceHolder',
    totalWinTerminal: 'SSBT.TerminalModal.totalWinTerminal',
    scanNewTicketTerminal: 'SSBT.TerminalModal.scanNewTicketTerminal',
    requestTerminalText: 'SSBT.TerminalModal.requestTerminalText',
    requestRejectTerminal: 'SSBT.TerminalModal.requestRejectTerminal',
    depositSumText: 'SSBT.TerminalModal.depositSumText',
    depositText: 'SSBT.TerminalModal.depositText',
    anotherDepositMethod: 'SSBT.TerminalModal.anotherDepositMethod',
    creditCardText: 'SSBT.TerminalModal.creditCardText',
    cashTextTerminal: 'SSBT.TerminalModal.cashTextTerminal',
    depositMethodTerminal: 'SSBT.TerminalModal.depositMethodTerminal',
    voucherTextTerminal: 'SSBT.TerminalModal.voucherTextTerminal',
    transactionTextTerminal: 'SSBT.TerminalModal.transactionTextTerminal',
    rejectedTransactionTerminal: 'SSBT.TerminalModal.rejectedTransactionTerminal',
    returnMoneyString: 'SSBT.TerminalModal.returnMoneyString',
    invalidVoucherText: 'SSBT.TerminalModal.invalidVoucherText',
    createVoucherSuccess: 'SSBT.TerminalModal.createVoucherSuccess',
    createVoucherText: 'SSBT.TerminalModal.createVoucherText',
    generateVoucherText: 'SSBT.TerminalModal.generateVoucherText',
    scanVoucherText: 'SSBT.TerminalModal.scanVoucherText',
    depositWithVoucherHeader: 'SSBT.TerminalModal.depositWithVoucherHeader',
    depositProfitTerminal: 'SSBT.TerminalModal.depositProfitTerminal',
    payoutButtonText: 'SSBT.TerminalModal.payoutButtonText',
    invalidCodeText: 'SSBT.TerminalModal.invalidCodeText',
  },
  TerminalTicket: {
    ticketNumberTerminal: 'SSBT.TerminalTicket.ticketNumberTerminal',
    acceptedTicketTerminalText: 'SSBT.TerminalTicket.acceptedTicketTerminalText',
  },
  SportLobby: {
    terminalMarkets: 'SSBT.SportLobby.terminalMarkets',
  },
  Navigation: {
    deposit: 'SSBT.Navigation.deposit',
    checkTicket: 'SSBT.Navigation.checkTicket',
    printProgram: 'SSBT.Navigation.printProgram',
  },
  Ticket: {
    totalCombinations: 'SSBT.Ticket.totalCombinations',
  },
  PrinterErrorMessages: {
    theOutputMouthIsFull: 'SSBT.PrinterErrorMessages.theOutputMouthIsFull',
    thePrinterIsOffline: 'SSBT.PrinterErrorMessages.thePrinterIsOffline',
    theHeadIsOverheated: 'SSBT.PrinterErrorMessages.theHeadIsOverheated',
    theCoverIsOpen: 'SSBT.PrinterErrorMessages.theCoverIsOpen',
    noPaper: 'SSBT.PrinterErrorMessages.noPaper',
    paperJammed: 'SSBT.PrinterErrorMessages.paperJammed',
    commandNotAcknowledged: 'SSBT.PrinterErrorMessages.commandNotAcknowledged',
    tooManyColumnsDefined: 'SSBT.PrinterErrorMessages.tooManyColumnsDefined',
    noPrinterFound: 'SSBT.PrinterErrorMessages.noPrinterFound',
    printerHardwareMalfunction: 'SSBT.PrinterErrorMessages.printerHardwareMalfunction',
    printerCommunicationFailure: 'SSBT.PrinterErrorMessages.printerCommunicationFailure',
    cutterError: 'SSBT.PrinterErrorMessages.cutterError',
    unsupportedPrinter: 'SSBT.PrinterErrorMessages.unsupportedPrinter',
    powerSupplyVoltageError: 'SSBT.PrinterErrorMessages.powerSupplyVoltageError',
  },
  BankValidatorErrors: {
    validatorTamper: 'SSBT.BankValidatorErrors.validatorTamper',
    cashboxRemoved: 'SSBT.BankValidatorErrors.cashboxRemoved',
    cashboxReplaced: 'SSBT.BankValidatorErrors.cashboxReplaced',
    validatorError: 'SSBT.BankValidatorErrors.validatorError',
    validatorJammed: 'SSBT.BankValidatorErrors.validatorJammed',
    validatorCleared: 'SSBT.BankValidatorErrors.validatorCleared',
    containerFull: 'SSBT.BankValidatorErrors.containerFull',
  },
  DocumentScanner: {
    failedScan: 'SSBT.documentScanner.failedScan',
  },
  CoinValidatorErrors: {
    unknownResponse: 'SSBT.CoinValidatorErrors.unknownResponse',
    negativeACK: 'SSBT.CoinValidatorErrors.negativeACK',
    commandTimeout: 'SSBT.CoinValidatorErrors.commandTimeout',
    busyDevice: 'SSBT.CoinValidatorErrors.busyDevice',
    unknownCommand: 'SSBT.CoinValidatorErrors.unknownCommand',
    invalidArgument: 'SSBT.CoinValidatorErrors.invalidArgument',
    notConfigured: 'SSBT.CoinValidatorErrors.notConfigured',
    coinRejected: 'SSBT.CoinValidatorErrors.coinRejected',
    acceptorInhibited: 'SSBT.CoinValidatorErrors.acceptorInhibited',
    timeoutDetector: 'SSBT.CoinValidatorErrors.timeoutDetector',
    passedTwoCoins: 'SSBT.CoinValidatorErrors.passedTwoCoins',
    shuttreNotWork: 'SSBT.CoinValidatorErrors.shuttreNotWork',
    sensorNotWork: 'SSBT.CoinValidatorErrors.sensorNotWork',
    jamInDetector: 'SSBT.CoinValidatorErrors.jamInDetector',
    outputModule: 'SSBT.CoinValidatorErrors.outputModule',
    antiThreadingSystem: 'SSBT.CoinValidatorErrors.antiThreadingSystem',
    inhibitedCoin: 'SSBT.CoinValidatorErrors.inhibitedCoin',
    fastCoin: 'SSBT.CoinValidatorErrors.fastCoin',
    recoveryMechanism: 'SSBT.CoinValidatorErrors.recoveryMechanism',
    unspecifiedError: 'SSBT.CoinValidatorErrors.unspecifiedError',
    notPresent: 'SSBT.CoinValidatorErrors.notPresent',
  },
  RgbControllerErrors: {
    notFnd: 'SSBT.RgbControllerErrors.notFnd',
    badResponse: 'SSBT.RgbControllerErrors.badResponse',
    port: 'SSBT.RgbControllerErrors.port',
    timeout: 'SSBT.RgbControllerErrors.timeout',
    busy: 'SSBT.RgbControllerErrors.busy',
    unknownCommand: 'SSBT.RgbControllerErrors.unknownCommand',
    invalidArgument: 'SSBT.RgbControllerErrors.invalidArgument',
    baddev: 'SSBT.RgbControllerErrors.baddev',
  },
};

export const NavBarTranslations: Translations.NavBar = {
  Search: {
    casinoPlaceholder: 'NavBar.Search.casinoPlaceholder',
    sportPlaceholder: 'NavBar.Search.sportPlaceholder',
    casinoCategoryPlaceholder: 'NavBar.Search.casinoCategoryPlaceholder',
  },
  NavItem: {
    viewAllText: 'NavBar.NavItem.viewAllText',
  },
  Ribbon: {
    ribbonText: 'NavBar.NavItem.ribbonText',
  },
};

export const MyBetsTranslation: Translations.MyBets = {
  Messages: {
    toWatchLiveText: 'MyBets.Messages.toWatchLiveText',
    accessMsgText: 'MyBets.Messages.accessMsgText',
    noFunds: 'MyBets.Messages.noFunds',
    videoError: 'MyBets.Messages.videoError',
    loginMyBetsText: 'MyBets.Messages.loginMyBetsText',
    noBetsText: 'noBetsText',
    noLiveBetsText: 'noLiveBetsText',
    noSettledText: 'noSettledText',
    noUnsettledText: 'noUnsettledText',
    orText: 'MyBets.Messages.orText',
    pleaseText: 'MyBets.Messages.pleaseText',
    registerMyBetsText: 'MyBets.Messages.registerMyBetsText',
  },
  FreeBet: {
    usedTxt: 'MyBets.FreeBet.usedTxt',
    creditsTxt: 'MyBets.FreeBet.creditsTxt',
    usedCredits: 'MyBets.FreeBet.usedCredits',
  },
  BetSummary: {
    cashoutBtn: 'MyBets.BetSummary.cashoutBtn',
    cashoutText: 'MyBets.BetSummary.cashoutText',
    cashoutUnavailableBtn: 'MyBets.BetSummary.cashoutUnavailableBtn',
    confirmCashoutBtn: 'MyBets.BetSummary.confirmCashoutBtn',
    headerBetSummaryText: 'MyBets.BetSummary.headerBetSummaryText',
    loadingCashoutBtn: 'MyBets.BetSummary.loadingCashoutBtn',
    toReturnText: 'MyBets.BetSummary.toReturnText',
    reofferMessage: 'MyBets.BetSummary.reofferMessage',
    returnText: 'MyBets.BetSummary.returnText',
    returnCashoutText: 'MyBets.BetSummary.returnCashoutText',
    stakeSummaryText: 'MyBets.BetSummary.stakeSummaryText',
    usedStakeText: 'MyBets.BetSummary.usedStakeText',
  },
  Tabs: {
    live: 'MyBets.Tabs.live',
    all: 'MyBets.Tabs.all',
    settled: 'MyBets.Tabs.settled',
    unsettled: 'MyBets.Tabs.unsettled',
  },
  BonusMoney: {
    bonusMoneyText: 'MyBets.BonusMoney.bonusMoneyText',
  },
};

export const Restrictions: Translations.Restrictions = {
  Exclusions: {
    seActivationConfirmed: 'Restriction.Exclusions.seActivationConfirmed',
    seActivationNotConfirmed: 'Restriction.Exclusions.seActivationNotConfirmed',
    seErrorLabel: 'Restriction.Exclusions.seErrorLabel',
  },
  Error: {
    invalidRequest: 'Restriction.Error.invalidRequest',
    invalidToken: 'Restriction.Error.invalidToken',
  },
};

export const MyAccountTranslation: Translations.MyAccount = {
  MFA: {
    setMfaPasswordPopupMessage: 'MyAccount.MFA.setMfaPasswordPopupMessage',
    text: 'MyAccount.MFA.text',
    email: 'MyAccount.MFA.email',
    sms: 'MyAccount.MFA.sms',
    phoneCall: 'MyAccount.MFA.phoneCall',
    textValid: 'MyAccount.MFA.textValid',
    time: 'MyAccount.MFA.time',
    btn: 'MyAccount.MFA.btn',
    notReceive: 'MyAccount.MFA.notReceive',
    wrongCode: 'MyAccount.MFA.wrongCode',
    toggleMFA: 'MyAccount.MFA.toggleMFA',
    mfaChannel: 'MyAccount.MFA.mfaChannel',
    mfaSectionTitle: 'MyAccount.MFA.mfaSectionTitle',
  },
  PhoneConfirmation: {
    text: 'MyAccount.PhoneConfirmation.text',
    textValid: 'MyAccount.PhoneConfirmation.textValid',
    time: 'MyAccount.PhoneConfirmation.time',
    wrongCode: 'MyAccount.PhoneConfirmation.wrongCode',
    btn: 'MyAccount.PhoneConfirmation.btn',
    notReceive: 'MyAccount.PhoneConfirmation.notReceive',
    logoutBtn: 'MyAccount.PhoneConfirmation.logoutBtn',
  },
  CasinoBetHistory: {
    settled: 'MyAccount.CasinoBetHistory.settled',
    unsettled: 'MyAccount.CasinoBetHistory.unsettled',
    CasinoHeaderText: 'MyAccount.CasinoBetHistory.CasinoHeaderText',
    LiveCasinoHeaderText: 'MyAccount.CasinoBetHistory.LiveCasinoHeaderText',
    totalBetText: 'MyAccount.CasinoBetHistory.totalBetText',
    totalWinText: 'MyAccount.CasinoBetHistory.totalWinText',
  },
  Limits: {
    increaseLimitTitle: 'MyAccount.Limits.increaseLimitTitle',
    increaseLimitSubTitle: 'MyAccount.Limits.increaseLimitSubTitle',
    confirmBtn: 'MyAccount.Limits.confirmBtn',
    depositedSumValue: 'MyAccount.Limits.depositedSumValue',
    disabledUpdateLabel: 'MyAccount.Limits.disabledUpdateLabel',
    limitTypeBet: 'MyAccount.Limits.limitTypeBet',
    limitTypeDaily: 'MyAccount.Limits.limitTypeDaily',
    limitTypeDeposits: 'MyAccount.Limits.limitTypeDeposits',
    limitTypeLabel: 'MyAccount.Limits.limitTypeLabel',
    limitTypeLoss: 'MyAccount.Limits.limitTypeLoss',
    limitTypeMonthly: 'MyAccount.Limits.limitTypeMonthly',
    limitTypeMontly: 'MyAccount.Limits.limitTypeMontly',
    limitTypeWeekly: 'MyAccount.Limits.limitTypeWeekly',
    noLimitLabel: 'MyAccount.Limits.noLimitLabel',
    playedSumValue: 'MyAccount.Limits.playedSumValue',
    limitDescription: 'MyAccount.Limits.limitDescription',
    limitDescription2: 'MyAccount.Limits.limitDescription2',
    limitPeriodTypeLabel: 'MyAccount.Limits.limitPeriodTypeLabel',
    enterPasswordText: 'MyAccount.Limits.enterPasswordText',
    confirmLimitText: 'MyAccount.Limits.confirmLimitText',
    delayedLimitRemovedLabel: 'MyAccount.Limits.delayedLimitRemovedLabel',
    delayedLimitChangedLabel: 'MyAccount.Limits.delayedLimitChangedLabel',
    limitsWarning: 'MyAccount.Limits.limitsWarning',
    increaseLimitsWarning: 'MyAccount.Limits.increaseLimitsWarning',
    pendingLimitsWarning: 'MyAccount.Limits.pendingLimitsWarning',
    pendingLimitChangedLabel: 'MyAccount.Limits.pendingLimitChangedLabel',
  },

  KYC: {
    hideCVVLabel: 'MyAccount.KYC.hideCVVLabel',
    idCardLabel: 'MyAccount.KYC.',
    accountVerifiedText1: 'MyAccount.KYC.accountVerifiedText1',
    accountVerifiedText2: 'MyAccount.KYC.accountVerifiedText2',
    accountVerifiedText3: 'MyAccount.KYC.accountVerifiedText3',
    accountVerificationTitle: 'MyAccount.KYC.accountVerificationTitle',
    fullyVerifiedText: 'MyAccount.KYC.fullyVerifiedText',
    idCardRequiredVerificationText1: 'MyAccount.KYC.idCardRequiredVerificationText1',
    idCardRequiredVerificationText2: 'MyAccount.KYC.idCardRequiredVerificationText2',
    idVerifiedText: 'MyAccount.KYC.idVerifiedText',
    chooseUploadFileLabel: 'MyAccount.KYC.chooseUploadFileLabel',
    fileRestrictionsLabel: 'MyAccount.KYC.fileRestrictionsLabel',
    groupDocumentsRequiredVerificationText: 'MyAccount.KYC.groupDocumentsRequiredVerificationText',
    personalIdCardFoVerificationText: 'MyAccount.KYC.personalIdCardFoVerificationText',
    notOwnIdentificationCard: 'MyAccount.KYC.notOwnIdentificationCard',
    paymentCardLabel: 'MyAccount.KYC.paymentCardLabel',
    paymentCardRequiredVerificationText: 'MyAccount.KYC.paymentCardRequiredVerificationText',
    processingVerificationText: 'MyAccount.KYC.processingVerificationText',
    rejectedFilesSectionLabel: 'MyAccount.KYC.rejectedFilesSectionLabel',
    rejectedText: 'MyAccount.KYC.rejectedText',
    generalDocumentText: 'MyAccount.KYC.generalDocumentText',
    paymentCardVerificationTitle: 'MyAccount.KYC.',
    oneSideDocumentText: 'MyAccount.KYC.paymentCardVerificationTitle',
    otherDocumentsLabel: 'MyAccount.KYC.otherDocumentsLabel',
    twoSidesDocumentText: 'MyAccount.KYC.twoSidesDocumentText',
    uploadFormFileRestrictions: 'MyAccount.KYC.uploadFormFileRestrictions',
    uploadFormFileSizeLimit: 'MyAccount.KYC.uploadFormFileSizeLimit',
    uploadFormSingleFileAllowed: 'MyAccount.KYC.uploadFormSingleFileAllowed',
    verificationPageTitle: 'MyAccount.KYC.verificationPageTitle',
  },

  Buttons: {
    historySearchButtonText: 'MyAccount.Buttons.historySearchButtonText',
    saveButton: 'MyAccount.Buttons.saveButton',
  },
  Bank: {
    historyTabText: 'MyAccount.Bank.historyTabText',
    depositTabText: 'MyAccount.Bank.depositTabText',
    DropDownDepositText: 'MyAccount.Bank.DropDownDepositText',
    DropDownWithdrawText: 'MyAccount.Bank.DropDownWithdrawText',
    noDataMsgText: 'MyAccount.Bank.noDataMsgText',
    ReferenceNumberText: 'MyAccount.Bank.ReferenceNumberText',
    withdrawalTabText: 'MyAccount.Bank.withdrawalTabText',
    easyPayDepositCode: 'MyAccount.Bank.easyPayDepositCode',
    smartPaymentsDepositCode: 'MyAccount.Bank.smartPaymentsDepositCode',
  },
  BetSelection: {
    cashedOut: 'MyAccount.BetSelection.cashedOut',
    cancelledStatus: 'MyAccount.BetSelection.cancelledStatus',
    lostStatus: 'MyAccount.BetSelection.lostStatus',
    voidStatus: 'MyAccount.BetSelection.voidStatus',
    winStatus: 'MyAccount.BetSelection.winStatus',
  },
  Sport: {
    hours24Text: 'MyAccount.Sport.hours24Text',
    hours48Text: 'MyAccount.Sport.hours48Text',
    hoursFilterRangeTab: 'MyAccount.Sport.hoursFilterRangeTab',
    betsText: 'MyAccount.Sport.betsText',
    dateRangeTab: 'MyAccount.Sport.dateRangeTab',
    noBetsMsgText: 'MyAccount.Sport.noBetsMsgText',
  },

  Verification: {
    idCardRequiredVerificationText: '',
    ReasonLabel: 'MyAccount.Verification.ReasonLabel',
    SendAgainButton: 'MyAccount.Verification.SendAgainButton',
  },
  BetHistory: {
    betHeaderText: 'MyAccount.BetHistory.betHeaderText',
    betTypeHeaderText: 'MyAccount.BetHistory.betTypeHeaderText',
    cashoutAmountBetText: 'MyAccount.BetHistory.cashoutAmountBetText',
    cashoutBetText: 'MyAccount.BetHistory.cashoutBetText',
    cashoutText: 'MyAccount.BetHistory.cashoutText',
    dateText: 'MyAccount.BetHistory.dateText',
    numberOfBetsText: 'MyAccount.BetHistory.numberOfBetsText',
    originalStakeText: 'MyAccount.BetHistory.originalStakeText',
    returnText: 'MyAccount.BetHistory.returnText',
    seeLess: 'MyAccount.BetHistory.seeLess',
    stakeHeaderText: 'MyAccount.BetHistory.stakeHeaderText',
    stakeUsedText: 'MyAccount.BetHistory.stakeUsedText',
    toReturnText: 'MyAccount.BetHistory.toReturnText',
    totalCashoutText: 'MyAccount.BetHistory.totalCashoutText',
    totalOddsText: 'MyAccount.BetHistory.totalOddsText',
    totalStakeText: 'MyAccount.BetHistory.totalStakeText',
    unitStakeText: 'MyAccount.BetHistory.unitStakeText',
    seeDetails: 'MyAccount.BetHistory.seeDetails',
  },
  BetHistoryMenu: {
    casinoHistoryTitle: 'MyAccount.BetHistoryMenu.casinoHistoryTitle',
    liveCasinoTitle: 'MyAccount.BetHistoryMenu.liveCasinoTitle',
    sportHistoryTitle: 'MyAccount.BetHistoryMenu.sportHistoryTitle',
  },
  IpHistory: {
    ipAddressLabel: 'MyAccount.IpHistory.ipAddressLabel',
    loginLabel: 'MyAccount.IpHistory.loginLabel',
    logoutLabel: 'MyAccount.IpHistory.logoutLabel',
    unknownDevice: '',
  },
  Forms: {
    datePickerLabelFrom: 'MyAccount.Forms.datePickerLabelFrom',
    datePickerLabelTo: 'MyAccount.Forms.datePickerLabelTo',
    datePickerPlaceholder: 'MyAccount.Forms.datePickerPlaceholder',
  },
  Settings: {
    dropdownPlaceholder: '',
    headerText: 'MyAccount.Settings.headerText',
    languageLabel: 'MyAccount.Settings.languageLabel',
    oddTypeLabel: 'MyAccount.Settings.oddTypeLabel',
    timezoneLabel: 'MyAccount.Settings.timezoneLabel',
  },
  IpHistoryMenu: {
    ipHistoryLatestLabel: 'MyAccount.IpHistoryMenu.ipHistoryLatestLabel',
    ipHistoryPeriodLabel: 'MyAccount.IpHistoryMenu.ipHistoryPeriodLabel',
    ipHistoryTitle: 'MyAccount.IpHistoryMenu.ipHistoryTitle',
    IpHistoryHeaderText: 'MyAccount.IpHistoryMenu.IpHistoryHeaderText',
    noLoginsMsgText: 'MyAccount.IpHistoryMenu.noLoginsMsgText',
  },
  SportSubMenu: {
    settledBetsText: 'MyAccount.SportSubMenu.settledBetsText',
    unsettledBetsText: 'MyAccount.SportSubMenu.unsettledBetsText',
    freeSpinsBonusText: 'MyAccount.SportSubMenu.freeSpinsBonusText',
    freeBetBonusText: 'MyAccount.SportSubMenu.freeBetBonusText',
    wageringBonusText: 'MyAccount.SportSubMenu.wageringBonusText',
    awardsBonusText: 'MyAccount.SportSubMenu.awardsBonusText',
  },
  BonusHistoryMenu: {
    wageringHeaderText: 'MyAccount.BonusHistoryMenu.wageringHeaderText',
    freebetsHeaderText: 'MyAccount.BonusHistoryMenu.freebetsHeaderText',
    freespinsHeaderText: 'MyAccount.BonusHistoryMenu.freespinsHeaderText',
    awardsHeaderText: 'MyAccount.BonusHistoryMenu.awardsHeaderText',
    bonusHistoryTitle: 'MyAccount.BonusHistoryMenu.bonusHistoryTitle',
    noBonusesMsgText: 'MyAccount.BonusHistoryMenu.noBonusesMsgText',
  },
  BonusHistoryItem: {
    Sport: 'MyAccount.BonusHistoryItem.Sport',
    Casino: 'MyAccount.BonusHistoryItem.Casino',
    LiveCasino: 'MyAccount.BonusHistoryItem.LiveCasino',
    DeactivatedBonusStatus: 'MyAccount.BonusHistoryItem.DeactivatedBonusStatus',
    CompletedBonusStatus: 'MyAccount.BonusHistoryItem.CompletedBonusStatus',
    ExpiredBonusStatus: 'MyAccount.BonusHistoryItem.ExpiredBonusStatus',
    CanceledBonusStatus: 'MyAccount.BonusHistoryItem.CanceledBonusStatus',
    RejectedBonusStatus: 'MyAccount.BonusHistoryItem.RejectedBonusStatus',
    DeclinedBonusStatus: 'MyAccount.BonusHistoryItem.DeclinedBonusStatus',
    ResetBonusStatus: 'MyAccount.BonusHistoryItem.ResetBonusStatus',
    InvalidBonusStatus: 'MyAccount.BonusHistoryItem.InvalidBonusStatus',
    ExhaustedBonusStatus: 'MyAccount.BonusHistoryItem.ExhaustedBonusStatus',
    freespinsAchievedText: 'MyAccount.BonusHistoryItem.freespinsAchievedText',
    freebetsAchievedText: 'MyAccount.BonusHistoryItem.freebetsAchievedText',
    freespinsGivenText: 'MyAccount.BonusHistoryItem.freespinsGivenText',
    freebetsGivenText: 'MyAccount.BonusHistoryItem.freebetsGivenText',
    wageredText: 'MyAccount.BonusHistoryItem.wageredText',
    leftToWagerText: 'MyAccount.BonusHistoryItem.leftToWagerText',
    wageringAchievedText: 'MyAccount.BonusHistoryItem.wageringAchievedText',
    wageringGivenText: 'MyAccount.BonusHistoryItem.wageringGivenText',
    awardsGivenText: 'MyAccount.BonusHistoryItem.awardsGivenText',
    activatedText: 'MyAccount.BonusHistoryItem.activatedText',
    expirationText: 'MyAccount.BonusHistoryItem.expirationText',
    givenText: 'MyAccount.BonusHistoryItem.givenText',
  },
  TransactionHistory: {
    Main: {
      all: 'MyAccount.TransactionHistory.MainAll',
      title: 'MyAccount.TransactionHistory.MainTitle',
      resultsTitle: 'MyAccount.TransactionHistory.ResultsTitle',
      noTransactions: 'MyAccount.TransactionHistory.MainNoTransactions',
    },
    Headers: {
      createdDate: 'MyAccount.TransactionHistory.HeaderDateTime',
      referenceId: 'MyAccount.TransactionHistory.HeaderReferenceId',
      reason: 'MyAccount.TransactionHistory.HeaderTransactionStatus',
      betType: 'MyAccount.TransactionHistory.HeaderBetType',
      gameName: 'MyAccount.TransactionHistory.HeaderGameName',
      paymentMethodLabel: 'MyAccount.TransactionHistory.HeaderPaymentMethod',
      accountType: 'MyAccount.TransactionHistory.HeaderAccountType',
      currency: 'MyAccount.TransactionHistory.HeaderCurrency',
      amount: 'MyAccount.TransactionHistory.HeaderAmount',
      balance: 'MyAccount.TransactionHistory.HeaderBalance',
    },
  },
  TableGamesHistory: {
    Headers: {
      pokerHeaderText: 'MyAccount.TableGamesHistory.HeaderPoker',
      svaraHeaderText: 'MyAccount.TableGamesHistory.HeaderSvara',
    },
    Items: {
      transaction: 'MyAccount.TableGamesHistory.TransactionNumberSymbol',
      amount: 'MyAccount.TableGamesHistory.BetAmount',
      fee: 'MyAccount.TableGamesHistory.TournamentFee',
      WIN: 'MyAccount.TableGamesHistory.WIN',
      BUY_IN: 'MyAccount.TableGamesHistory.BUY_IN',
      BUY_IN_REFUND: 'MyAccount.TableGamesHistory.BUY_IN_REFUND',
      BUY_OUT: 'MyAccount.TableGamesHistory.BUY_OUT',
    },
  },
  FantasySportsHistory: {
    Headers: {
      MainHeader: 'MyAccount.FantasySportsHistory.MainHeader',
    },
    Items: {
      transaction: 'MyAccount.FantasySportsHistory.TransactionNumberSymbol',
      amount: 'MyAccount.FantasySportsHistory.Amount',
      Win: 'MyAccount.FantasySportsHistory.WIN',
      'Buy-in': 'MyAccount.FantasySportsHistory.BUY_IN',
      Refund: 'MyAccount.FantasySportsHistory.BUY_IN_REFUND',
    },
  },
  PlayerInfoPopup: {
    Headers: {
      first: 'PlayerInfoPopup.Headers.First',
      second: 'PlayerInfoPopup.Headers.Second',
      table: 'PlayerInfoPopup.Headers.Table',
    },
    Texts: {
      main: 'PlayerInfoPopup.Texts.Main',
      footer: 'PlayerInfoPopup.Texts.Footer',
      monthWagers: 'PlayerInfoPopup.Texts.WagersMonthly',
      monthWins: 'PlayerInfoPopup.Texts.WinningsMonthly',
      totalWagers: 'PlayerInfoPopup.Texts.WagersTotal',
      totalWins: 'PlayerInfoPopup.Texts.WinningsTotal',
    },
    Labels: {
      button: 'PlayerInfoPopup.Labels.Button',
      linkMain: 'PlayerInfoPopup.Labels.LinkMain',
      linkFooter: 'PlayerInfoPopup.Labels.LinkFooter',
    },
  },
  Exclusions: {
    incorrectPassword: 'MyAccount.Exclusions.incorrectPassword',
    SportText: 'MyAccount.Exclusions.SportText',
    CasinoText: 'MyAccount.Exclusions.CasinoText',
    activationDate: 'MyAccount.Exclusions.activationDate',
    endDate: 'MyAccount.Exclusions.endDate',
    activeselfExclusionLimit: 'MyAccount.Exclusions.activeselfExclusionLimit',
    activetimeOutLimit: 'MyAccount.Exclusions.activetimeOutLimit',
    activeaccountClosureLimit: 'MyAccount.Exclusions.activeaccountClosureLimit',
    activeplayerLimitsLimit: 'MyAccount.Exclusions.activeplayerLimitsLimit',
    settings: 'MyAccount.Exclusions.settings',
    includedVerticals: 'MyAccount.Exclusions.includedVerticals',
    removeBtn: 'MyAccount.Exclusions.removeBtn',
    removeAfterBtn: 'MyAccount.Exclusions.removeAfterBtn',
    removeInBtn: 'MyAccount.Exclusions.removeInBtn',
    requestBtn: 'MyAccount.Exclusions.requestBtn',
    requestedBtn: 'MyAccount.Exclusions.requestedBtn',
    daysText: 'MyAccount.Exclusions.daysText',
    dayText: 'MyAccount.Exclusions.dayText',
    selectPeriodLabel: 'MyAccount.Exclusions.selectPeriodLabel',
    selectExtendPeriodLabel: 'MyAccount.Exclusions.selectExtendPeriodLabel',
    cancelExtendButton: 'MyAccount.Exclusions.cancelExtendButton',
    extendButton: 'MyAccount.Exclusions.extendButton',
    reOpenRequestSent: 'MyAccount.Exclusions.reOpenRequestSent',
    sendReOpeningRequest: 'MyAccount.Exclusions.sendReOpeningRequest',
    confirmBtn: 'MyAccount.Exclusions.confirmBtn',
    sportText: 'MyAccount.Exclusions.sportText',
    casinoText: 'MyAccount.Exclusions.casinoText',
    timeOut: 'MyAccount.Exclusions.timeOut',
    selfExclusion: 'MyAccount.Exclusions.selfExclusion',
    accountClosure: 'MyAccount.Exclusions.accountClosure',
    playerLimits: 'MyAccount.Exclusions.playerLimits',
    selectPeriodPlaceholder: 'MyAccount.Exclusions.selectPeriodPlaceholder',
    enterPasswordText: 'MyAccount.Exclusions.enterPasswordText',
    confirmText: 'MyAccount.Exclusions.confirmText',
    passwordPlaceholder: 'MyAccount.Exclusions.passwordPlaceholder',
    responsibleGambling: 'MyAccount.Exclusions.responsibleGambling',
  },
  DeleteAccount: {
    popupTitle: 'MyAccount.DeleteAccount.popupTitle',
    popupDescription: 'MyAccount.DeleteAccount.popupDescription',
    passwordPlaceholder: 'MyAccount.DeleteAccount.passwordPlaceholder',
    sendButton: 'MyAccount.DeleteAccount.sendButton',
    rejectButton: 'MyAccount.DeleteAccount.rejectButton',
    infoMessage: 'MyAccount.DeleteAccount.infoMessage',
    messageTitle: 'MyAccount.DeleteAccount.messageTitle',
    messageSubtitle: 'MyAccount.DeleteAccount.messageSubtitle',
  },
  SupportChat: {
    titleChatWindow: 'MyAccount.SupportChat.titleChatWindow',
    subTitleChatWindow: 'MyAccount.SupportChat.subTitleChatWindow',
    nameChatWindow: 'MyAccount.SupportChat.nameChatWindow',
  },
};

export const BetBuilderTranslation: Translations.BetBuilder = {
  tabTextNew: 'BetBuilder.TabText.new',
};

export const BETTING: Translations.BETTING = {
  ERROR: {
    BET_3039: 'BETTING.ERROR.BET_3039',
    BET_3040: 'BETTING.ERROR.BET_3040',
    BET_3005: 'BETTING.ERROR.BET_3005',
    BET_3018: 'BETTING.ERROR.BET_3018',
    BET_3001: 'BETTING.ERROR.BET_3001',
    BET_3006: 'BETTING.ERROR.BET_3006',
    BET_3007: 'BETTING.ERROR.BET_3007',
    BET_3008: 'BETTING.ERROR.BET_3008',
    BET_3009: 'BETTING.ERROR.BET_3009',
    BET_3000: 'BETTING.ERROR.BET_3000',
    BET_3002: 'BETTING.ERROR.BET_3002',
    BET_3003: 'BETTING.ERROR.BET_3003',
    BET_3004: 'BETTING.ERROR.BET_3004',
    BET_3010: 'BETTING.ERROR.BET_3010',
    BET_3011: 'BETTING.ERROR.BET_3011',
    BET_3012: 'BETTING.ERROR.BET_3012',
    BET_3014: 'BETTING.ERROR.BET_3014',
    BET_3017: 'BETTING.ERROR.BET_3017',
    BET_3019: 'BETTING.ERROR.BET_3019',
    BET_3021: 'BETTING.ERROR.BET_3021',
    BET_3022: 'BETTING.ERROR.BET_3022',
    BET_3023: 'BETTING.ERROR.BET_3023',
    BET_3024: 'BETTING.ERROR.BET_3024',
    BET_3025: 'BETTING.ERROR.BET_3025',
    BET_3013: 'BETTING.ERROR.BET_3013',
    BET_3015: 'BETTING.ERROR.BET_3015',
    BET_3016: 'BETTING.ERROR.BET_3016',
    BET_3020: 'BETTING.ERROR.BET_3020',
    BET_3026: 'BETTING.ERROR.BET_3026',
    BET_3027: 'BETTING.ERROR.BET_3027',
    BET_3028: 'BETTING.ERROR.BET_3028',
    BET_3029: 'BETTING.ERROR.BET_3029',
    BET_3031: 'BETTING.ERROR.BET_3031',
    BET_3033: 'BETTING.ERROR.BET_3033',
    BET_3035: 'BETTING.ERROR.BET_3035',
    BET_3036: 'BETTING.ERROR.BET_3036',
    BET_3037: 'BETTING.ERROR.BET_3037',
    BET_3030: 'BETTING.ERROR.BET_3030',
    BET_3038: 'BETTING.ERROR.BET_3038',
    BET_3032: 'BETTING.ERROR.BET_3032',
    BET_3034: 'BETTING.ERROR.BET_3034',
    BET_3041: 'BETTING.ERROR.BET_3041',
    BET_3042: 'BETTING.ERROR.BET_3042',
  },
};

export const COMBINATOR: Translations.COMBINATOR = {
  ERROR: {
    CMB_1013: 'COMBINATOR.ERROR.CMB_1013',
    CMB_1014: 'COMBINATOR.ERROR.CMB_1014',
    CMB_1000: 'COMBINATOR.ERROR.CMB_1000',
    CMB_1001: 'COMBINATOR.ERROR.CMB_1001',
    CMB_1002: 'COMBINATOR.ERROR.CMB_1002',
    CMB_1003: 'COMBINATOR.ERROR.CMB_1003',
    CMB_1005: 'COMBINATOR.ERROR.CMB_1005',
    CMB_1006: 'COMBINATOR.ERROR.CMB_1006',
    CMB_1007: 'COMBINATOR.ERROR.CMB_1007',
    CMB_1008: 'COMBINATOR.ERROR.CMB_1008',
    CMB_1004: 'COMBINATOR.ERROR.CMB_1004',
    CMB_1009: 'COMBINATOR.ERROR.CMB_1009',
    CMB_1010: 'COMBINATOR.ERROR.CMB_1010',
    CMB_1011: 'COMBINATOR.ERROR.CMB_1011',
    CMB_1012: 'COMBINATOR.ERROR.CMB_1012',
  },
};

export const ACCOUNTING: Translations.ACCOUNTING = {
  ERROR: {
    ACC_2012: 'ACCOUNTING.ERROR.ACC_2012',
    ACC_2014: 'ACCOUNTING.ERROR.ACC_2014',
    ACC_2042: 'ACCOUNTING.ERROR.ACC_2042',
    ACC_2015: 'ACCOUNTING.ERROR.ACC_2015',
    ACC_2043: 'ACCOUNTING.ERROR.ACC_2043',
    ACC_2016: 'ACCOUNTING.ERROR.ACC_2016',
    ACC_2017: 'ACCOUNTING.ERROR.ACC_2017',
    ACC_2018: 'ACCOUNTING.ERROR.ACC_2018',
    ACC_2019: 'ACCOUNTING.ERROR.ACC_2019',
    ACC_2020: 'ACCOUNTING.ERROR.ACC_2020',
    ACC_2021: 'ACCOUNTING.ERROR.ACC_2021',
    ACC_2022: 'ACCOUNTING.ERROR.ACC_2022',
    ACC_2023: 'ACCOUNTING.ERROR.ACC_2023',
    ACC_2024: 'ACCOUNTING.ERROR.ACC_2024',
    ACC_2025: 'ACCOUNTING.ERROR.ACC_2025',
    ACC_2026: 'ACCOUNTING.ERROR.ACC_2026',
    ACC_2027: 'ACCOUNTING.ERROR.ACC_2027',
    ACC_2028: 'ACCOUNTING.ERROR.ACC_2028',
    ACC_2029: 'ACCOUNTING.ERROR.ACC_2029',
    ACC_2030: 'ACCOUNTING.ERROR.ACC_2030',
    ACC_2031: 'ACCOUNTING.ERROR.ACC_2031',
    ACC_2035: 'ACCOUNTING.ERROR.ACC_2035',
    ACC_2037: 'ACCOUNTING.ERROR.ACC_2037',
    ACC_2032: 'ACCOUNTING.ERROR.ACC_2032',
    ACC_2033: 'ACCOUNTING.ERROR.ACC_2033',
    ACC_2000: 'ACCOUNTING.ERROR.ACC_2000',
    ACC_2001: 'ACCOUNTING.ERROR.ACC_2001',
    ACC_2002: 'ACCOUNTING.ERROR.ACC_2002',
    ACC_2003: 'ACCOUNTING.ERROR.ACC_2003',
    ACC_2004: 'ACCOUNTING.ERROR.ACC_2004',
    ACC_2005: 'ACCOUNTING.ERROR.ACC_2005',
    ACC_2006: 'ACCOUNTING.ERROR.ACC_2006',
    ACC_2007: 'ACCOUNTING.ERROR.ACC_2007',
    ACC_2008: 'ACCOUNTING.ERROR.ACC_2008',
    ACC_2009: 'ACCOUNTING.ERROR.ACC_2009',
    ACC_2010: 'ACCOUNTING.ERROR.ACC_2010',
    ACC_2011: 'ACCOUNTING.ERROR.ACC_2011',
    ACC_2013: 'ACCOUNTING.ERROR.ACC_2013',
    ACC_2034: 'ACCOUNTING.ERROR.ACC_2034',
    ACC_2036: 'ACCOUNTING.ERROR.ACC_2036',
  },
};

export const Messages: Translations.RootMessages = {
  SportsProgram: {
    filterLiveStreamsLabel: 'Messages.SportsProgram.filterLiveStreamsLabel',
    noSportsProgramEventsText: 'Messages.SportsProgram.noSportsProgramEventsText',
  },
  DefaultError: {
    defaultErrorString: 'Messages.DefaultError.defaultErrorString',
  },
};

export const Favourites: Translations.Favourites = {
  Sports: {
    BreadcrumbFavouritesString: 'Favourites.Sports.BreadcrumbFavouritesString',
    BreadcrumbSportsString: 'Favourites.Sports.BreadcrumbSportsString',
  },
};

export const GameJourney: Translations.GameJourney = {
  Achievements: {
    receivedButtonText: 'GameJourney.Achievements.receivedButtonText',
    acquireButtonText: 'GameJourney.Achievements.acquireButtonText',
    textConditionsBefore: 'GameJourney.Achievements.textConditionsBefore',
    textConditionsAfter: 'GameJourney.Achievements.textConditionsAfter',
    noAchievementsText: 'GameJourney.Achievements.noAchievementsText',
  },
  Menu: {
    storeTab: 'GameJourney.Menu.storeTab',
    achievementsTab: 'GameJourney.Menu.achievementsTab',
    challengesTab: 'GameJourney.Menu.challengesTab',
    allTab: 'GameJourney.Menu.allTab',
    upcomingTab: 'GameJourney.Menu.upcomingTab',
    activeTab: 'GameJourney.Menu.activeTab',
    finishedTab: 'GameJourney.Menu.finishedTab',
    dailyTab: 'GameJourney.Menu.dailyTab',
    weeklyTab: 'GameJourney.Menu.weeklyTab',
    uniqueTab: 'GameJourney.Menu.uniqueTab',
    achievementsTabSubTitle: 'GameJourney.Menu.achievementsTabSubTitle',
    termsTabSubTitle: 'GameJourney.Menu.termsTabSubTitle',
    historyTabSubTitle: 'GameJourney.Menu.historyTabSubTitle',
  },
  Challenges: {
    noChallengesText: 'GameJourney.Challenges.noChallengesText',
  },
  Common: {
    completedText: 'GameJourney.Common.completedText',
    endText: 'GameJourney.Common.endText',
    coinsText: 'GameJourney.Common.coinsText',
    experienceText: 'GameJourney.Common.experienceText',
    rewardText: 'GameJourney.Common.rewardText',
    daysText: 'GameJourney.Common.daysText',
    dayText: 'GameJourney.Common.dayText',
    afterText: 'GameJourney.Common.afterText',
    noInformartion: 'GameJourney.Common.noInformartion',
    timeCompletedText: 'GameJourney.Common.timeCompletedText',
    timesCompletedText: 'GameJourney.Common.timesCompletedText',
    oneTimeText: 'GameJourney.Common.oneTimeText',
    manyTimesText: 'GameJourney.Common.manyTimesText',
    remains: 'GameJourney.Common.remains',
  },
  History: {
    dateText: 'GameJourney.History.dateText',
    gameText: 'GameJourney.History.gameText',
    typeText: 'GameJourney.History.typeText',
    coinsText: 'GameJourney.History.coinsText',
    gameInfo: 'GameJourney.History.gameInfo',
    noHistoryText: 'GameJourney.History.noHistoryText',
    IncreaseCoins: 'GameJourney.History.IncreaseCoins',
    DecreaseCoins: 'GameJourney.History.DecreaseCoins',
  },
  Store: {
    buyButtonText: 'GameJourney.Store.buyButtonText',
    buyTicketButtonText: 'GameJourney.Store.buyTicketButtonText',
    backButtonText: 'GameJourney.Store.backButtonText',
    cancelButtonText: 'GameJourney.Store.cancelButtonText',
    remainingTicketsText: 'GameJourney.Store.remainingTicketsText',
    takeTicketText: 'GameJourney.Store.takeTicketText',
    takeCustomTicketText: 'GameJourney.Store.takeCustomTicketText',
    takeTicketsButtonText: 'GameJourney.Store.takeTicketsButtonText',
    successTakeTicketsButtonText: 'GameJourney.Store.successTakeTicketsButtonText',
    itemTypeText: 'GameJourney.Store.itemTypeText',
    successText: 'GameJourney.Store.successText',
    failureText: 'GameJourney.Store.failureText',
    restText: 'GameJourney.Store.restText',
    noShopItemsText: 'GameJourney.Store.noShopItemsText',
    notEnoughItems: 'GameJourney.Store.notEnoughItems',
    badAssetIdProvided: 'GameJourney.Store.badAssetIdProvided',
    lowBalance: 'GameJourney.Store.lowBalance',
    assetSegmented: 'GameJourney.Store.assetSegmented',
    more: 'GameJourney.Store.more',
    coupons: 'GameJourney.Store.coupons',
    storeItemMyOffersText: 'GameJourney.Store.storeItemMyOffersText',
    toOffersButton: 'GameJourney.Store.toOffersButton',
    successPhysicalText: 'GameJourney.Store.successPhysicalText',
    successPhysicalInfo: 'GameJourney.Store.successPhysicalInfo',
    bonus: 'GameJourney.Store.bonus',
    physical: 'GameJourney.Store.physical',
  },
  Profile: {
    pointsText: 'GameJourney.Profile.pointsText',
    virtualPointsText: 'GameJourney.StoProfilere.virtualPointsText',
  },
  Sidebar: {
    remainingTimeText: 'GameJourney.Sidebar.remainingTimeText',
    skilledText: 'GameJourney.Sidebar.skilledText',
  },
};

export const MyProfile: Translations.MyProfile = {
  Statistics: {
    creditsText: 'MyProfile.Statistics.creditsText',
    balanceText: 'MyProfile.Statistics.balanceText',
    withdrawableText: 'MyProfile.Statistics.withdrawableText',
    winCoinsText: 'MyProfile.Statistics.winCoinsText',
    smarticoCoinsText: 'MyProfile.Statistics.smarticoCoinsText',
  },
  OffersTab: {
    CasinoGift: 'MyProfile.OffersTab.CasinoGift',
    acceptConfirmText: 'MyProfile.OffersTab.acceptConfirmText',
    activationText: 'MyProfile.OffersTab.activationText',
    acceptBtn: 'MyProfile.OffersTab.acceptBtn',
    activateBtn: 'MyProfile.OffersTab.activateBtn',
    offerExpiresSuffix: 'MyProfile.OffersTab.offerExpiresSuffix',
    bonusCompletion: 'MyProfile.OffersTab.bonusCompletion',
    bonusBalance: 'MyProfile.OffersTab.bonusBalance',
    byProduct: 'MyProfile.OffersTab.byProduct',
    cancelBtn: 'MyProfile.OffersTab.cancelBtn',
    cancelConfirmText: 'MyProfile.OffersTab.cancelConfirmText',
    cancelBonusTemplateText: 'MyProfile.OffersTab.cancelBonusTemplateText',
    cancelTitle: 'MyProfile.OffersTab.cancelTitle',
    closeBtn: 'MyProfile.OffersTab.closeBtn',
    confirmBtn: 'MyProfile.OffersTab.confirmBtn',
    depositBtn: 'MyProfile.OffersTab.depositBtn',
    depositNeededText: 'MyProfile.OffersTab.depositNeededText',
    descriptionWSB2021Text: 'MyProfile.OffersTab.descriptionWSB2021Text',
    expirationText: 'MyProfile.OffersTab.expirationText',
    noOffersTitle: 'MyProfile.OffersTab.noOffersTitle',
    errorOffersMessage: 'MyProfile.OffersTab.errorOffersMessage',
    offerExpiresInfix: 'MyProfile.OffersTab.offerExpiresInfix',
    offerExpiresPrefix: 'MyProfile.OffersTab.offerExpiresPrefix',
    OfferItemBackBtn: 'MyProfile.OffersTab.OfferItemBackBtn',
    promotionsLink: 'MyProfile.OffersTab.promotionsLink',
    remainingBet: 'MyProfile.OffersTab.remainingBet',
    termsAndConditions: 'MyProfile.OffersTab.termsAndConditions',
    termsAndConditionsApply: 'MyProfile.OffersTab.termsAndConditionsApply',
    Casino: 'MyProfile.OffersTab.Casino',
    winningsExcludeFreeBets: 'MyProfile.OffersTab.winningsExcludeFreeBets',
    Live_Casino: 'MyProfile.OffersTab.Live_Casino',
    Sport: 'MyProfile.OffersTab.Sport',
    General: 'MyProfile.OffersTab.General',
    LiveCasino: 'MyProfile.OffersTab.LiveCasino',
    bonusPromotionExpirationDate: 'MyProfile.OffersTab.bonusPromotionExpirationDate',
    depositRolloverLeft: 'MyProfile.OffersTab.depositRolloverLeft',
    promotionAction: 'MyProfile.OffersTab.promotionAction',
    promotionSuccessText: 'MyProfile.OffersTab.promotionSuccessText',
    promotionActionText: 'MyProfile.OffersTab.promotionActionText',
    depositForRollover: 'MyProfile.OffersTab.depositForRollover',
    wonFreeSpins: 'MyProfile.OffersTab.wonFreeSpins',
    playedFreeSpins: 'MyProfile.OffersTab.playedFreeSpins',
    wonFromFreeSpins: 'MyProfile.OffersTab.wonFromFreeSpins',
    remainingProfitToRoll: 'MyProfile.OffersTab.remainingProfitToRoll',
  },
  Button: {
    historyBtn: 'MyProfile.Button.historyBtn',
    bankBtn: 'MyProfile.Button.bankBtn',
    depositProfileBtn: 'MyProfile.Button.depositProfileBtn',
    limitsBtn: 'MyProfile.Button.limitsBtn',
    logoutBtn: 'MyProfile.Button.logoutBtn',
    myOffersBtn: 'MyProfile.Button.myOffersBtn',
    myProfileBtn: 'MyProfile.Button.myProfileBtn',
    settingsBtn: 'MyProfile.Button.settingsBtn',
    responsibleGamblingBtn: 'MyProfile.Button.responsibleGamblingBtn',
    openMissions: 'MyProfile.Button.openMissions',
  },
  Icon: {
    inboxBtn: 'MyProfile.Icon.inboxBtn',
    balanceBtn: 'MyProfile.Icon.balanceBtn',
    freeBetsBtn: 'MyProfile.Icon.freeBetsBtn',
  },

  ProfileTab: {
    accountTab: 'MyProfile.ProfileTab.accountTab',
    myOffersTab: 'MyProfile.ProfileTab.myOffersTab',
    notificationsTab: 'MyProfile.ProfileTab.notificationsTab',
    captainUpTab: 'MyProfile.ProfileTab.captainUpTab',
    new: 'MyProfile.ProfileTab.new',
  },

  PostActionPopup: {
    bonusButtonWSB2021: 'MyProfile.PostActionPopup.bonusButtonWSB2021',
    detailsTextWSB2021: 'MyProfile.PostActionPopup.detailsTextWSB2021',
  },

  NotificationsTab: {
    noNotificationsTitle: 'MyProfile.NotificationsTab.noNotificationsTitle',
    errorNotificationsMessage: 'MyProfile.NotificationsTab.errorNotificationsMessage',
  },

  InsufficientBalance: {
    lowBalance: 'MyProfile.InsufficientBalance.lowBalance',
    loadBalanceText: 'MyProfile.InsufficientBalance.loadBalanceText',
    depositBtn: 'MyProfile.InsufficientBalance.depositBtn',
  },
};

export const RealityCheckModal: Translations.RealityCheckModal = {
  Title: { realityCheckText: 'RealityCheckModal.Title.realityCheckText' },
  Message: { messageText: 'RealityCheckModal.Message.messageText' },
  Button: { Confirm: 'RealityCheckModal.Button.Confirm', Logout: 'RealityCheckModal.Button.Logout' },
};

export const TermsAndConditions: Translations.TermsAndConditions = {
  Modal: {
    mainTitle: 'TermsAndConditions.Modal.mainTitle',
    infoText: 'TermsAndConditions.Modal.infoText',
    helpText: 'TermsAndConditions.Modal.helpText',
    helpLink: 'TermsAndConditions.Modal.helpLink',
    acceptText: 'TermsAndConditions.Modal.acceptText',
    acceptButton: 'TermsAndConditions.Modal.acceptButton',
    errorTitle: 'TermsAndConditions.Modal.errorTitle',
  },
};

export const CommonTranslations: Translations.Common = {
  Time: {
    days: 'Common.Time.days',
    hours: 'Common.Time.hours',
    minutes: 'Common.Time.minutes',
    seconds: 'Common.Time.seconds',
    abbSeconds: 'Common.Time.abbSeconds',
    remainingTimeText: 'Common.Time.remainingTimeText',
  },
};

export const Promotions: Translations.Promotions = {
  Tournaments: {
    eventRankings: 'Promotions.Tournaments.eventRankings',
    monthlyRanking: 'Promotions.Tournaments.monthlyRanking',
    currentRankings: 'Promotions.Tournaments.currentRankings',
    lastRankings: 'Promotions.Tournaments.lastRankings',
    scoresLabel: 'Promotions.Tournaments.scoresLabel',
    playerLabel: 'Promotions.Tournaments.playerLabel',
    tabRankingLabel: 'Promotions.Tournaments.tabRankingLabel',
    tabTermsLabel: 'Promotions.Tournaments.tabTermsLabel',
    generalRanking: 'Promotions.Tournaments.generalRanking',
    weeklyRanking: 'Promotions.Tournaments.weeklyRanking',
    rankingSubtabLabel: 'Promotions.Tournaments.rankingSubtabLabel',
    dailyRanking: 'Promotions.Tournaments.dailyRanking',
    placeLabel: 'Promotions.Tournaments.placeLabel',
    awardLabel: 'Promotions.Tournaments.awardLabel',
    winningPlayers: 'Promotions.Tournaments.winningPlayers',
    dateLabel: 'Promotions.Tournaments.dateLabel',
  },
  PromotionBreadcrumb: {
    backBtn: 'Promotions.PromotionBreadcrumb.backBtn',
  },
  PromotionCell: {
    daysText: 'Promotions.PromotionCell.daysText',
    hoursText: 'Promotions.PromotionCell.hoursText',
    minutesText: 'Promotions.PromotionCell.minutesText',
    showMore: 'Promotions.PromotionCell.showMore',
  },
  Common: {
    closeBtn: 'Promotions.Common.closeBtn',
    contactSupport: 'Promotions.Common.contactSupport',
    startsInText: 'Promotions.Common.endsInText',
    endsInText: 'Promotions.Common.endsInText',
    expiredText: 'Promotions.Common.expiredText',
    privateContent: 'Promotions.Common.privateContent',
  },
  Gamification: {
    wheelSpinBtnText: 'Promotions.Gamification.wheelSpinBtnText',
    chooseReelBtnText: 'Promotions.Gamification.chooseReelBtnText',
    toMyOffers: 'Promotions.Gamification.toMyOffers',
  },
  RequestBet: {
    requiredField: 'Promotions.RequestBet.requiredField',
    textPlaceholder: 'Promotions.RequestBet.textPlaceholder',
    selectCategory: 'Promotions.RequestBet.selectCategory',
    requestBetBtn: 'Promotions.RequestBet.requestBetBtn',
    paragraphOneText: 'Promotions.RequestBet.paragraphOneText',
    paragraphTwoText: 'Promotions.RequestBet.paragraphTwoText',
    paragraphTreeText: 'Promotions.RequestBet.paragraphTreeText',
    requestBetTitle: 'Promotions.RequestBet.requestBetTitle',
    successText: 'Promotions.RequestBet.successText',
    maxLengthText: 'Promotions.RequestBet.maxLengthText',
  },
  PrizeDrop: {
    noRewards: 'Promotions.PrizeDrop.noRewards',
    rewardsTab: 'Promotions.PrizeDrop.rewardsTab',
    winnersTab: 'Promotions.PrizeDrop.winnersTab',
    norewardedPlayers: 'Promotions.PrizeDrop.norewardedPlayers',
    nopersonalRewards: 'Promotions.PrizeDrop.nopersonalRewards',
    noInformation: 'Promotions.PrizeDrop.noInformation',
    rewardedPlayers: 'Promotions.PrizeDrop.rewardedPlayers',
    personalRewards: 'Promotions.PrizeDrop.personalRewards',
    termsTab: 'Promotions.PrizeDrop.termsTab',
    winnersSubTab: 'Promotions.PrizeDrop.winnersSubTab',
    myWinsSubTab: 'Promotions.PrizeDrop.myWinsSubTab',
    winBoxInfoLink: 'Promotions.PrizeDrop.winBoxInfoLink',
    campaignsTitle: 'Promotions.PrizeDrop.campaignsTitle',
    continueButton: 'Promotions.PrizeDrop.continueButton',
    expiredText: 'Promotions.PrizeDrop.expiredText',
    endsInText: 'Promotions.PrizeDrop.endsInText',
    startsInText: 'Promotions.PrizeDrop.startsInText',
    player: 'Promotions.PrizeDrop.player',
    win: 'Promotions.PrizeDrop.win',
    soonText: 'Promotions.PrizeDrop.soonText',
    days: 'Promotions.PrizeDrop.days',
    hours: 'Promotions.PrizeDrop.hours',
    minutes: 'Promotions.PrizeDrop.minutes',
    seconds: 'Promotions.PrizeDrop.seconds',
    seeAll: 'Promotions.PrizeDrop.seeAll',
    noActiveCampaigns: 'Promotions.PrizeDrop.noActiveCampaigns',
  },
};

export const Declaration: Pick<Translations.Declaration, 'SourceOfFunds'> = {
  SourceOfFunds: {
    declarationBtn: 'Declaration.SourceOfFunds.declarationBtn',
    logoutBtn: 'Declaration.SourceOfFunds.logoutBtn',
  },
};

export const Drawer: Translations.Drawer = {
  DrawerLinks: {
    virtualProviderText: 'Drawer.DrawerLinks.virtualProviderText',
    vermantiaText: 'Drawer.DrawerLinks.vermantiaText',
    betradarText: 'Drawer.DrawerLinks.betradarText',
    casino: 'Drawer.DrawerLinks.casino',
    dashboardLink: 'Drawer.DrawerLinks.dashboardLink',
    notificationsLink: 'Drawer.DrawerLinks.notificationsLink',
    promoLink: 'Drawer.DrawerLinks.promoLink',
    settingsLink: 'Drawer.DrawerLinks.settingsLink',
    sport: 'Drawer.DrawerLinks.sport',
  },
  DrawerSection: {
    viewLessText: 'Drawer.DrawerSection.viewLessText',
    viewMoreText: 'Drawer.DrawerSection.viewMoreText',
  },
};

export const CookiesMessages: Translations.CookiesMessages = {
  CookiesConsent: {
    cookiesConsentIntro: 'Messages.CookiesConsent.cookiesConsentIntro',
    cookiesConsentLinkText: 'Messages.CookiesConsent.cookiesConsentLinkText',
    cookiesConsentLinkOutro: 'Messages.CookiesConsent.cookiesConsentLinkOutro',
    buttonText: 'Messages.CookiesConsent.buttonText',
    cookiesConsentLinkIntro: 'Messages.CookiesConsent.cookiesConsentLinkIntro',
  },
};

export const NavMainHelp: Translations.NavMainHelp = {
  backToBtn: 'AppHeader.NavMainHelp.backToBtn',
};

export const Toggler: Translations.Toggler = {
  mostPlayed: 'TickerToggler.Toggler.mostPlayed',
};

export const NavUser: Translations.NavUser = {
  registerBtn: 'AppHeader.NavUser.registerBtn',
  loginBtn: 'AppHeader.NavUser.loginBtn',
};

export const DrawerToggler: Translations.DrawerToggler = {
  az: 'AppHeader.DrawerToggler.az',
};

export const NavMain: Translations.NavMain = {
  inPlayItem: 'inPlayItem',
  virtualsItem: 'virtualsItem',
  casinoItem: 'casinoItem',
  casinoEgtItem: 'casinoEgtItem',
  eSportsItem: 'eSportsItem',
  liveCasinoItem: 'liveCasinoItem',
  vegasItem: 'vegasItem',
  sportItem: 'sportItem',
};

export const AppHeader: Translations.AppHeader = {
  NavMain: NavMain,
  NavUser: NavUser,
  NavMainHelp: NavMainHelp,
  Toggler: Toggler,
  DrawerToggler: DrawerToggler,
};

export const DropDown: Translations.DropDown = {
  Title: {
    changeTitleText: 'DropDown.Title.changeTitleText',
  },
};

export const NotFoundMessages: Translations.NotFoundMessages = {
  NotFound: {
    buttonText: 'Messages.NotFound.buttonText',
  },
};

export const TimeoutMessage: Translations.TimeoutMessage = {
  sessionCheckText: 'TimeoutModal.Message.sessionCheckText',
  sessionExpiredText: 'TimeoutModal.Message.sessionExpiredText',
  inactivityReasonText: 'TimeoutModal.Message.inactivityReasonText',
  connectionLostText: 'TimeoutModal.Message.connectionLostText',
  hourText: 'TimeoutModal.Message.hourText',
  loggedOutText: 'TimeoutModal.Message.loggedOutText',
  longTimeInactiveText: 'TimeoutModal.Message.longTimeInactiveText',
  maximumInactivityText: 'TimeoutModal.Message.maximumInactivityText',
  refreshPageText: 'TimeoutModal.Message.refreshPageText',
};

export const TimeoutButton: Translations.TimeoutButton = {
  sessionCheckButton: 'TimeoutModal.Button.sessionCheckButton',
  logoutText: 'TimeoutModal.Button.logoutText',
  refreshButton: 'TimeoutModal.Button.refreshButton',
  remainLoggedInText: 'TimeoutModal.Button.remainLoggedInText',
};

export const TimeoutModal: Translations.TimeoutModal = {
  Message: TimeoutMessage,
  Button: TimeoutButton,
};

export const StatisticsModalHeader: Translations.StatisticsModalHeader = {
  showStatsText: 'StatisticsModal.Header.showStatsText',
  summaryText: 'StatisticsModal.Header.summaryText',
};

export const StatisticsModal: Translations.StatisticsModal = {
  Header: StatisticsModalHeader,
};

export const FantasyLeague: Translations.FantasyLeague = {
  FantasyLeagueMenu: {
    games: 'FantasyLeague.FantasyLeagueMenu.games',
    liveResults: 'FantasyLeague.FantasyLeagueMenu.liveResults',
    matchups: 'FantasyLeague.FantasyLeagueMenu.matchups',
    support: 'FantasyLeague.FantasyLeagueMenu.support',
  },
};

export const OnlineCashier: Pick<Translations.OnlineCashier, 'Wizard'> = {
  Wizard: {
    buttonLabel: 'OnlineCashier.Wizard.buttonLabel',
    withdrawalButtonLabel: 'OnlineCashier.Wizard.withdrawalButtonLabel',
    sectionTitle: 'OnlineCashier.Wizard.sectionTitle',
    submitbuttonLabel: 'OnlineCashier.Wizard.submitbuttonLabel',
  },
};

export const Help: Translations.Help = {
  viewAll: 'HelpPage.HelpPage.viewAll',
  mainTopics: 'HelpPage.HelpPage.mainTopics',
  downloadButton: 'HelpPage.HelpPage.downloadButton',
};

export const HelpSearch: Translations.HelpSearch = {
  helpYou: 'HelpPage.Search.helpYou',
  search: 'HelpPage.Search.search',
};

export const Contacts: Translations.Contacts = {
  contactUs: 'HelpPage.Contacts.contactUs',
  needHelp: 'HelpPage.Contacts.needHelp',
  extraHelp: 'HelpPage.Contacts.extraHelp',
};

export const Paymants: Translations.Paymants = {
  limits: 'HelpPage.Paymants.limits',
  max: 'HelpPage.Paymants.max',
  min: 'HelpPage.Paymants.min',
  transfer: 'HelpPage.Paymants.transfer',
};
export const HelpPage: Translations.HelpPage = {
  HelpPage: Help,
  Search: HelpSearch,
  Contacts: Contacts,
  Paymants: Paymants,
};
export const FooterDeposit: Translations.Deposit = {
  deposit: 'Footer.Deposit.deposit',
};
export const Footer: Translations.Footer = {
  Deposit: FooterDeposit,
  SeoContent: {
    seoBtnText: 'Footer.SeoContent.seoBtnText',
  },
};

export const HighlightFeatureButton: Translations.HighlightFeatureButton = {
  next: 'HighlightFeature.Button.next',
  finish: 'HighlightFeature.Button.finish',
};
export const HighlightFeatureFeatures: Translations.HighlightFeatureFeatures = {
  DragHeader: 'HighlightFeature.Features.DragHeader',
  HoldToBet: 'HighlightFeature.Features.HoldToBet',
};
export const HighlightFeatureTranslate: Translations.HighlightFeature = {
  Button: HighlightFeatureButton,
  Features: HighlightFeatureFeatures,
};

export const CashierTranslations: Translations.CashierTranslations = {
  Status: {
    winStatus: 'Cashier.Status.winStatus',
    lostStatus: 'Cashier.Status.lostStatus',
    acceptedStatus: 'Cashier.Status.acceptedStatus',
    paidStatus: 'Cashier.Status.paidStatus',
    cancelledStatus: 'Cashier.Status.cancelledStatus',
  },
  ActionButton: {
    cancelTicket: 'Cashier.ActionButton.cancelTicket',
    cashoutButton: 'Cashier.ActionButton.cashoutButton',
    payTicketButton: 'Cashier.ActionButton.payTicketButton',
    cashoutAndPayButton: 'Cashier.ActionButton.cashoutAndPayButton',
    loadNewTicket: 'Cashier.ActionButton.loadNewTicket',
  },
  CashierCheckTicket: {
    invalidTicketNumber: 'Cashier.CashierCheckTicket.invalidTicketNumber',
    checkTicketInit: 'Cashier.CashierCheckTicket.checkTicketInit',
  },
  CashierModal: {
    issuedDate: 'Cashier.CashierModal.issuedDate',
    totalAmount: 'Cashier.CashierModal.totalAmount',
    betNumber: 'Cashier.CashierModal.betNumber',
    pinNumber: 'Cashier.CashierModal.pinNumber',
    scanFailedPaperCoupon: 'Cashier.CashierModal.scanFailedPaperCoupon',
  },

  DeactiveTerminalModal: {
    refreshStatus: 'Cashier.DeactiveTerminalModal.refreshStatus',
    messageDeactivated: 'Cashier.DeactiveTerminalModal.messageDeactivated',
    quietHours: 'Cashier.DeactiveTerminalModal.quietHours',
  },
  Print: {
    dublicateText: 'Cashier.Print.dublicateText',
    highStakeRequest: 'Cashier.Print.highStakeRequest',
    requestText: 'Cashier.Print.requestText',
  },
  HeaderButton: {
    financeButtonText: 'Cashier.HeaderButton.financeButtonText',
    lastPrintText: 'Cashier.HeaderButton.lastPrintText',
    cashierModalButtonText: 'Cashier.HeaderButton.cashierModalButtonText',
    cashPayButtonText: 'Cashier.HeaderButton.cashPayButtonText',
  },
  FinancialModal: {
    messageText: 'Cashier.FinancialModal.messageText',
    errorMessage: 'Cashier.FinancialModal.errorMessage',
    acceptedTicketsCount: 'Cashier.FinancialModal.acceptedTicketsCount',
    paidTicketsCount: 'Cashier.FinancialModal.paidTicketsCount',
    refundedTicketsCount: 'Cashier.FinancialModal.refundedTicketsCount',
    persentagePOS: 'Cashier.FinancialModal.persentagePOS',
    cancelledTicketsCount: 'Cashier.FinancialModal.cancelledTicketsCount',
    cashDeskAmount: 'Cashier.FinancialModal.cashDeskAmount',
    paidAmountValue: 'Cashier.FinancialModal.paidAmountValue',
    refundsAmountValue: 'Cashier.FinancialModal.refundsAmountValue',
    cancelledAmountValue: 'Cashier.FinancialModal.cancelledAmountValue',
    selectedPeriod: 'Cashier.FinancialModal.selectedPeriod',
    chooseDay: 'Cashier.FinancialModal.chooseDay',
    startDate: 'Cashier.FinancialModal.startDate',
    endDate: 'Cashier.FinancialModal.endDate',
    searchButton: 'Cashier.FinancialModal.searchButton',
    depositedFromPosAmount: 'Cashier.FinancialModal.depositedFromPosAmount',
    depositedToPosAmount: 'Cashier.FinancialModal.depositedToPosAmount',
    numberOfVoidedBets: 'Cashier.FinancialModal.numberOfVoidedBets',
    voidedBetsAmount: 'Cashier.FinancialModal.voidedBetsAmount',
    posNameText: 'Cashier.FinancialModal.posNameText',
    printReport: 'Cashier.FinancialModal.printReport',
    balanceText: 'Cashier.FinancialModal.balanceText',
    betsLimit: 'Cashier.FinancialModal.betsLimit',
  },
  Header: {
    live: 'Cashier.Header.live',
  },
  CashPayModal: {
    email: 'Cashier.CashPayModal.email',
    playerId: 'Cashier.CashPayModal.playerId',
    phone: 'Cashier.CashPayModal.phone',
    ucn: 'Cashier.CashPayModal.ucn',
    amount: 'Cashier.CashPayModal.amount',
    amountAfterTax: 'Cashier.CashPayModal.amountAfterTax',
    verificationStatus: 'Cashier.CashPayModal.verificationStatus',
    searchButton: 'Cashier.CashPayModal.searchButton',
    depositButton: 'Cashier.CashPayModal.depositButton',
    clearButton: 'Cashier.CashPayModal.clearButton',
    cnpInputPlaceholder: 'Cashier.CashPayModal.cnpInputPlaceholder',
    depositInputPlaceholder: 'Cashier.CashPayModal.depositInputPlaceholder',
    currency: 'Cashier.CashPayModal.currency',
    withdrawButton: 'Cashier.CashPayModal.withdrawButton',
    withdrawText: 'Cashier.CashPayModal.withdrawText',
    depositText: 'Cashier.CashPayModal.depositText',
    withdrawInputPlaceholder: 'Cashier.CashPayModal.withdrawInputPlaceholder',
    withdrawInputLabel: 'Cashier.CashPayModal.withdrawInputLabel',
    cnpInputLabel: 'Cashier.CashPayModal.cnpInputLabel',
    withdrawInfoText: 'Cashier.CashPayModal.withdrawInfoText',
    depositInfoText: 'Cashier.CashPayModal.depositInfoText',
    depositInputLabel: 'Cashier.CashPayModal.depositInputLabel',
    firstName: 'Cashier.CashPayModal.firstName',
    lastName: 'Cashier.CashPayModal.lastName',
    taxAmount: 'Cashier.CashPayModal.taxAmount',
    errorInfoText: 'Cashier.CashPayModal.errorInfoText',
    date: 'Cashier.CashPayModal.date',
  },
  TransactionHistoryReprintModal: {
    selectFiltersMsg: 'Cashier.TransactionHistoryReprint.selectFiltersMsg',
    reprintBtn: 'Cashier.TransactionHistoryReprint.reprintBtn',
    showMoreInfo: 'Cashier.TransactionHistoryReprint.showMoreInfo',
    showLessInfo: 'Cashier.TransactionHistoryReprint.showLessInfo',
    totalTickets: 'Cashier.TransactionHistoryReprint.totalTickets',
    noDataForSelectedFilters: 'Cashier.TransactionHistoryReprint.noDataForSelectedFilters',
    selectTerminal: 'Cashier.TransactionHistoryReprint.selectTerminal',
    selectOption: 'Cashier.TransactionHistoryReprint.selectOption',
    historyBtn: 'Cashier.TransactionHistoryReprint.historyBtn',
    accepted: 'Cashier.TransactionHistoryReprint.accepted',
    paid: 'Cashier.TransactionHistoryReprint.paid',
    cancelled: 'Cashier.TransactionHistoryReprint.cancelled',
    all: 'Cashier.TransactionHistoryReprint.all',
    ofTotal: 'Cashier.TransactionHistoryReprint.ofTotal',
  },
};
