import React from 'react';
import { ScrollContainer } from 'components/shared';
import { ContentHeader } from 'components/shared/headers';
import { UIBlock } from 'components/shared/layout';
import { arrowStyleTypes } from 'components/shared/ScrollContainer';

/** PromoFeedWrapper */
interface WrapperProps {
  title: string;
  children: React.ReactNode;
  cellWidth: number;
  moveWith: number;
}

const Wrapper: React.FC<WrapperProps> = ({ title, children, cellWidth, moveWith }) => {
  return (
    <UIBlock type="promosFeed">
      <ContentHeader title={title} componentLocation="promosFeed" />
      <ScrollContainer
        classNames={'no-scrollbars promos-feed__wrapper'}
        regularDesktopCellWidth={cellWidth}
        moveWith={moveWith}
        typeArrow={arrowStyleTypes.Tabs}
      >
        {children}
      </ScrollContainer>
    </UIBlock>
  );
};

export default Wrapper;
