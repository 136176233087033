import { useEffect } from 'react';
import { isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import { useAppDispatch, useAppSelector } from 'store';
import { casinoWidgetsSelectors, fetchWidgetJackpots } from '../../../slice/casinoWidgetsSlice';
import { CasinoWidgetsTypes } from '../../../types/casinoWidgetsTypes';

type UseWidgetJackpotsFetchProps = {
  widgetId: string;
  query: string;
};

export const useWidgetJackpotsFetch = ({
  widgetId,
  query,
}: UseWidgetJackpotsFetchProps): { widgetJackpotItems: CasinoWidgetsTypes.WidgetJackpot[] } => {
  const dispatch = useAppDispatch();
  const widgetJackpotItems = useAppSelector((state) => casinoWidgetsSelectors.widgetJackpots(state, widgetId));

  useEffect(() => {
    if (query && widgetId && isEmpty(widgetJackpotItems)) {
      dispatch(fetchWidgetJackpots({ query, id: widgetId }));
    }
  }, [dispatch, query, widgetId, widgetJackpotItems]);

  return { widgetJackpotItems };
};
