import React, { useCallback, useEffect, useRef } from 'react';
import CasinoIcon from 'modules/casino/modules/navigation/menu/components/CasinoIcon';
import {
  hidePopOver,
  selectPopOver,
  showPopOver,
} from 'shared/common/features/popover/popoverContainer/slice/popOver.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { useBonusTagsConfig } from './hooks/useBonusTagsConfig';

type Props = {
  gameUniqueId: string;
  icon?: string;
  description?: string;
};

export const BonusTag: React.FC<Props> = ({ icon, description, gameUniqueId }) => {
  const wrapperRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const activeTag = useAppSelector(selectPopOver.props);
  const enableGameTagIcon = useBonusTagsConfig();
  const handleRef = useCallback(
    (node) => {
      node && (wrapperRef.current = node);
    },
    [wrapperRef],
  );

  const handleActiveBonus = useCallback(
    (e) => {
      e.stopPropagation();
      if (wrapperRef.current) {
        const elPosition = wrapperRef.current.getBoundingClientRect();
        dispatch(
          showPopOver({
            modalProps: {
              top: elPosition.top,
              padding: wrapperRef.current.clientHeight,
              id: gameUniqueId,
              // left: wrapperRef.current.offsetLeft,
              left: elPosition.x,
              show: true,
              descriptions: description,
              position: 'right',
              size: 'small',
              showTriangle: true,
              wrapperRadius: true,
              elementWidth: wrapperRef.current.clientWidth,
            },
          }),
        );
      }
    },
    [dispatch, description],
  );

  useEffect(() => {
    return () => {
      dispatch(hidePopOver());
    };
  }, [dispatch]);

  if (!icon || !enableGameTagIcon) {
    return null;
  }

  return (
    <>
      <CasinoIcon
        id="bonusTagIcon"
        onClick={handleActiveBonus}
        handleRef={handleRef}
        className={`cell-tags--casino-bonus ${activeTag?.id === gameUniqueId ? 'cell-tags--casino-bonus--active' : ''}`}
        icon={icon}
      />
    </>
  );
};
