import { useEffect } from 'react';
import { cashierThunks, selectCashierData } from 'modules/retail/modules/cashier/slice/cashier.slice';
import { usePollingSSBTfunctions } from 'modules/retail/modules/ssbt/hooks/usePollingSSBTfunctions';
// import { selectSSBTdata } from 'modules/retail/ssbt/slice/ssbt.slice';
import { setHideSearchIcon } from 'shared/common/features/appHeader';
import { useAppDispatch, useAppSelector } from 'store';
import { isCashier, isTerminal } from 'utils/common/helpersCommon';

export const useRunTerminalCommands = (retailDevice, isAuthenticated): void => {
  // const isCoinAcceptorStopped = useAppSelector(selectSSBTdata.isCoinAcceptorStopped);
  // const isRgbControllerStopped = useAppSelector(selectSSBTdata.isRgbControllerStopped);
  const dispatch = useAppDispatch();
  const terminalActivity = useAppSelector(selectCashierData.terminalActivity);
  const isLiveProgram = window.location.pathname.includes('live-program');

  const {
    runPrinterStatsInterval,
    rgbPollingInterval,
    runCoinAcceptorCommandInterval,
    runBillingValidatorInterval,
    pollDocumentScannerInterval,
  } = usePollingSSBTfunctions();

  const commandMap = {
    coinValidator: runCoinAcceptorCommandInterval,
    moneyValidator: runBillingValidatorInterval,
    printerStats: runPrinterStatsInterval,
    rgbController: rgbPollingInterval,
    documentScanner: pollDocumentScannerInterval,
  };

  useEffect(() => {
    if ((isTerminal() || isCashier()) && isAuthenticated) {
      dispatch(cashierThunks.betsLimit());
    }
  }, []);

  useEffect(() => {
    const intervalIDs: Array<() => void> = [];
    Object.keys(commandMap).forEach((device) => {
      if (retailDevice?.[device] && isAuthenticated && terminalActivity === 'Active' && !isLiveProgram) {
        const cleanupInterval = dispatch(commandMap[device]());
        intervalIDs.push(cleanupInterval);
        dispatch(setHideSearchIcon(true));
      }
    });
    return () => {
      intervalIDs.forEach((cleanup) => cleanup());
    };
  }, [dispatch, retailDevice, terminalActivity, isAuthenticated]);
};
