import { PlayerLimits } from 'pages/myAccount/tabs/limits/types/limits.types';
import { PlayerRestrictions } from 'pages/myAccount/tabs/responsibleGambling/types/responsibleGambling.types';
import { RealityCheckSession as RealityCheckSessionType } from 'shared/common/features/realityCheckSession/types/realityCheckSession.types';
import { Sport } from 'utils/common/types/sportTypes';

export type WsSubscriptions = Record<string, WsComponent[]>;
export type WsStatusType = 'close' | 'init' | 'notStarted';

export enum WebSocketMsgTypes {
  CHANGE_BALANCE = 'CHANGE_BALANCE',
  LIVE_CASINO_GAME_UPDATE = 'LIVE_CASINO_GAME_UPDATE',
  NOTIFICATION = 'NOTIFICATION',
  PRIZE_DROP = 'PRIZE_DROP',
  JACKPOT = 'JACKPOT',
  BONUS_COLLECTION = 'BONUS_COLLECTION',
  BONUS_OFFERS = 'BONUS_OFFERS',
  PLAYER_RESTRICTIONS = 'PLAYER_RESTRICTIONS',
  FUNDS_DECLARATION = 'FUNDS_DECLARATION',
  REALITY_CHECK = 'REALITY_CHECK',
  REALITY_CHECK_SESSION_MODAL = 'REALITY_CHECK_SESSION_MODAL',
  PLAYER_LIMITS = 'PLAYER_LIMITS',
  INGAME_NOTIFICATION = 'INGAME_NOTIFICATION',
  KICK_OUT_PLAYER = 'KICK_OUT_PLAYER',
}

export type WsComponent =
  | 'DrawerLiveSports'
  | 'DrawerContainers'
  | 'Player'
  | 'HomePageContainers'
  | 'LIVE_CASINO_GAME_UPDATE'
  | 'HomeWidgets';

/** Types for payloads received from WebSocket udpates */
export declare module WS {
  type EventClockInSummaryChange = {
    eventTime: string;
    remainingTime: string | null;
    stoppageTime: string | null;
    stoppageTimeAnnounced: string | null;
    remainingTimeInPeriod: string | null;
    stopped: boolean;
  };

  type EventClock = {
    routingKey: string;
    eventStatus: Sport.EventStatus;
    matchStatus: Sport.MatchStatus;
    homeScore: string;
    awayScore: string;
    periodScore: PeriodScore[] | null;
    scoreChange: string;
    sportSpecificProperties: Sport.SpecificProperties | undefined;
    eventClock: EventClockInSummaryChange | undefined;
  };
  //PeriodScores props are currently all with capital letters is currently with capital letters
  //these are modified to lower ones once the contract is received
  type PeriodScore = {
    homeScore: string;
    awayScore: string;
    periodNumber: number;
    periodType: string;
  };

  type RealityCheck = {
    type: WebSocketMsgTypes.REALITY_CHECK;
    message: string;
    showLogoutButton: boolean;
  };

  type RealityCheckSession = {
    type: WebSocketMsgTypes.REALITY_CHECK_SESSION_MODAL;
    message: RealityCheckSessionType.WSMessage;
  };

  type Notification = {
    type: WebSocketMsgTypes.NOTIFICATION;
    message: {
      hasNewPopupNotification: boolean;
      hasNewInboxMessage: boolean;
      hasNewBonusOffer: boolean;
      hasNewPage: boolean;
    };
  };

  type Restrictions = {
    type: WebSocketMsgTypes.PLAYER_RESTRICTIONS;
    restrictions: PlayerRestrictions.Restriction[]; // & SportUser.Restriction[];
  };

  type IngameNotification = {
    type: WebSocketMsgTypes.INGAME_NOTIFICATION;
    popupContent: string; // Base64
    message: {
      hasNewPopupNotification: boolean;
      hasNewInboxMessage: boolean;
      hasNewBonusOffer?: boolean;
      hasNewPage: boolean;
    };
  };

  type Limits = {
    type: WebSocketMsgTypes.PLAYER_LIMITS;
    playerLimits: PlayerLimits.PlayerLimit[];
  };
}
