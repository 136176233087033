import styled from 'styled-components';

const GameCellWrapperStyled = styled.div`
  /* z-index: 2; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  align-items: flex-end;
`;
export default GameCellWrapperStyled;
GameCellWrapperStyled.displayName = 'GameCellWrapperStyled';
