import { useLocation } from 'react-router-dom';
import useHandleInactiveRoutes from './common/hooks/useHandIeInactiveRoutes';

const InactiveRoutesHandler = () => {
  const location = useLocation();
  useHandleInactiveRoutes(location.pathname);
  return <></>;
};

export default InactiveRoutesHandler;
