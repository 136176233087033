import styled from 'styled-components';
import { Breakpoints } from 'modules/casino/theme/Theme';

const GameMediaStyled = styled.div`
  border-radius: var(--game-cell-br) var(--game-cell-br) 0 0;
  grid-row-start: 1;
  grid-column-start: 1;
  height: calc(100% - 23px);
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    height: calc(100% - 35px);
  }
`;

export default GameMediaStyled;
