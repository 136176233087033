import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import config from 'utils/config';
import { TombolaTypes } from '../types/tombolaUserTickets.types';

const initialState: TombolaTypes.InitialState = {
  tombola: null,
};
export const tombolaUserTicketsThunks: TombolaTypes.Thunks = {
  getTombolaTickets: createAbortThunk('tombolaCollection/getTombolaTickets', async ({ url, isFromAccept }) => {
    const response = await axiosInstance.get(`${config.API_URL}/${url}?isFromAccept=${!!isFromAccept}`);
    return response.data;
  }),
};

const tombolaUserTickets = createSlice({
  name: 'tombolaUserTickets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(tombolaUserTicketsThunks.getTombolaTickets.fulfilled, (state, action) => {
      state.tombola = action.payload?.completion;
    });
  },
});

export const { reducer: tombolaUserTicketsReducer } = tombolaUserTickets;
export const selectTombolaUserTickets = {
  tickets: (state: RootState) => state.tombolaUserTickets.tombola,
};
