import React from 'react';
import PrizeDropViewAllBtn from './PrizeDropViewAllBtn';
import { prizeDropPromoCSSNS } from '../prizeDropUICSS.types';

type Props = {
  children: JSX.Element[];
  textBtn: string;
  showMore: boolean;
  showMoreClick?: () => void;
};

const PrizeDropPromoGiftItemWrapper: React.FC<Props> = ({ children, showMore, textBtn, showMoreClick }) => {
  const classProp = { class: `${prizeDropPromoCSSNS}-inner-shadow` };
  return (
    <egtd-promo-przd-gift-item-wrapper {...classProp}>
      {children}
      {showMore && showMoreClick ? (
        <PrizeDropViewAllBtn icon="plus-circled" onClick={showMoreClick} textBtn={textBtn} iconBtn="chevron-right" />
      ) : null}
    </egtd-promo-przd-gift-item-wrapper>
  );
};

export default PrizeDropPromoGiftItemWrapper;
