import TagManager from 'react-gtm-module';

interface GTMEventProps {
  eventKey: string;
  additionalParams?: Record<string, any>;
}

export const triggerGTMEvent = ({ eventKey, additionalParams }: GTMEventProps): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: eventKey,
      ...additionalParams,
    },
  });
};
