import React from 'react';
import { CategoryDetailsType } from './ExternalCasinoCategory';
import { ContentHeader } from '../../../../../../shared/components/headers';
import { DataLoader } from '../../../../../../shared/components/loaders';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from '../../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { LandingCasinoCategory } from '../landingCasinoCategory';

interface Props {
  children: React.ReactNode;
  subType: string;
  categoryIdKey: string;
  categoryDetails?: CategoryDetailsType;
}

const ExternalCasinoCategoryWrapper: React.FC<Props> = ({ children, subType, categoryIdKey, categoryDetails }) => {
  //TODO Implement loading
  const isLoading = false;
  const renderWrapper = () => {
    if (subType === CategorySubTypes.PROMOTION && categoryDetails?.title) {
      return (
        <div className="promo-page__games-feed">
          <ContentHeader title={categoryDetails?.title} color={categoryDetails?.color} />
          <>{children}</>
        </div>
      );
    }

    if (subType === CategorySubTypes.LANDING) {
      return (
        <LandingCasinoCategory
          categoryIdCacheKey={categoryIdKey}
          categoryLayoutType={CategoryLayoutTypes.EXTERNAL_CATEGORY}
          googleTagEvent={categoryDetails?.googleTagEvent}
        >
          {children}
        </LandingCasinoCategory>
      );
    }

    return <>{children}</>;
  };
  return isLoading ? <DataLoader /> : renderWrapper();
};

export default ExternalCasinoCategoryWrapper;
