import React from 'react';
import { CasinoWidgetTemplates } from 'modules/casino/modules/container/widget/constants/casinoWidgetsConstants';
import { casinoWidgetsSelectors } from 'modules/casino/modules/container/widget/slice/casinoWidgetsSlice';
import { isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import { useAppSelector } from 'store';
import CampaignInfo from './CampaignInfo';
import RewardedPlayersContainer from './RewardedPlayersContainer';
import RewardsContainer from './RewardsContainer';
import { prizeDropCSSNS } from '../../PrizeDropCellWidgetConstants';

type Props = {
  widgetId: string;
  widgetTemplate: string;
};

const WidgetPrizeDropCampaign: React.FC<Props> = ({ widgetId, widgetTemplate }) => {
  const prizeDropData = useAppSelector((state) => casinoWidgetsSelectors.widgetPrizeDropData(state, widgetId));

  if (isEmpty(prizeDropData)) {
    return <></>;
  }

  return (
    <div className={`d-flex ${prizeDropCSSNS}-widget ${prizeDropCSSNS}-widget--${widgetTemplate.toLowerCase()}`}>
      <CampaignInfo campaignInfo={prizeDropData?.campaignInfo} />

      <div className={`${prizeDropCSSNS}-widget__campaigns`}>
        <RewardsContainer rewards={prizeDropData?.rewards} />
        {widgetTemplate === CasinoWidgetTemplates.MACRO && (
          <RewardedPlayersContainer rewardedPlayers={prizeDropData?.rewardedPlayers} />
        )}
      </div>
    </div>
  );
};

export default WidgetPrizeDropCampaign;
