import React, { HTMLCustomAttributes } from 'react';
import clsx from 'clsx';
import { SVGLoader } from 'components/shared';
import Icon from 'components/shared/Icon';
import { addDataQid } from 'utils/testing/dataUtils';
import { ButtonProps, btnIconSizesMap, cssBtnNS } from './Button.types';
import './Button.scss';

/** EGTD Platform UI Button */
const Button = React.forwardRef<HTMLDivElement, ButtonProps & HTMLCustomAttributes<HTMLDivElement>>(
  (
    {
      variant = 'primary',
      size = 'xl',
      text,
      isActive,
      isDisabled,
      showLoader,
      className,
      icon,
      iconPath,
      onClick,
      iconClassName,
      children,
      qid,
      ...rest
    },
    ref,
  ) => {
    const classProp = className ? { class: className } : undefined;
    const activeProp = isActive ? { 'is-active': isActive } : undefined;
    const disabledProp = isDisabled ? { 'is-disabled': isDisabled } : undefined;
    const hasIconOnlyProp = !text ? { 'is-icon': !text } : undefined;
    const iconClass = clsx(`${cssBtnNS}__icon`, iconClassName);
    const iconSize = btnIconSizesMap[size];
    const handleClick = !isDisabled ? { onClick: onClick } : undefined;

    return (
      <egtd-p-btn
        role="button"
        ref={ref}
        {...classProp}
        {...activeProp}
        {...disabledProp}
        {...hasIconOnlyProp}
        c-size={size}
        c-variant={variant}
        {...rest}
        {...handleClick}
        {...addDataQid(qid)}
      >
        {children ? (
          <div className="d-flex-col-ac">
            <div className="d-flex-ac">
              {text && text}
              {icon && (
                <Icon defaultClassName={false} className={iconClass} size={iconSize} icon={icon} iconPath={iconPath} />
              )}
            </div>
            {children}
          </div>
        ) : (
          <>
            {text && text}
            {icon && (
              <Icon defaultClassName={false} className={iconClass} size={iconSize} icon={icon} iconPath={iconPath} />
            )}
            {showLoader && <SVGLoader className="ml-1" size={iconSize} />}
          </>
        )}
      </egtd-p-btn>
    );
  },
);

Button.displayName = 'EGTD Platform Button';

export default Button;
