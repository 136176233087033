import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandableIcon } from 'components/shared/icons';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import { StyledLink } from './Link.styled';

interface Props {
  isVisible: boolean;
  onClick: () => void;
  isActive: boolean;
  className?: string;
}

const Link: React.FC<Props> = ({ isVisible, onClick, isActive, className }) => {
  const { t } = useTranslation();

  if (!isVisible) return <StyledLink />;

  return (
    <StyledLink onClick={onClick} className={`cursor-pointer ${className || ''}`}>
      {t(MyProfile.OffersTab.termsAndConditionsApply)}
      <ExpandableIcon className="ml-2 gmf-link__icon" size={10} isExpanded={isActive} />
    </StyledLink>
  );
};

export default memo(Link);
