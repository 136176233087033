import { decode } from '@msgpack/msgpack';
import { inflate } from 'pako';

const msgTypes = {
  5: 'fixture-change',
};
const rkTypes = {
  5: 'alert',
};

export const isMsgPackMessage = (contentType: string) => {
  return contentType === 'application/x-msgpack';
};

export const isMsgPackGzMessage = (contentType: string) => contentType === 'application/x-msgpackgz';

const getDecodedMessageArr = (messageBody: string, contentType: string) => {
  const byteCharacters = atob(messageBody);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  if (isMsgPackGzMessage(contentType)) {
    const decompressed = inflate(byteArray);
    return decode(decompressed);
  } else {
    return decode(byteArray);
  }
};

const getRoutingKey = (routingKey) => {
  const idx = routingKey.indexOf('.');
  const result =
    idx > 0
      ? `${rkTypes[Number(routingKey.substring(0, idx))]}${routingKey.substring(idx)}`
      : rkTypes[Number(routingKey)];
  return result;
};
export const parseMsgPackMessage = (messageBody, routingKey, contentType) => {
  const alert = 5;

  const decodedMessage = getDecodedMessageArr(messageBody, contentType);
  const rk = getRoutingKey(routingKey);

  switch (decodedMessage?.[0]) {
    case alert:
      return parseMsgPackMessageKickOffAlert(decodedMessage, rk);
  }
};

const parseMsgPackMessageKickOffAlert = (msg, rk) => {
  if (!msg) return;

  return {
    routingKey: rk,
    type: msgTypes[msg[0]],
    sportEventId: msg[1],
    newDate: msg[2],
    oldDate: msg[3],
    alertStatus: msg[4],
  };
};
