import React from 'react';
import Text from 'components/shared/Text';
import { Desktop, DesktopDown } from 'theme/Theme';
import { isEmpty } from 'utils/common/helpersCommon';
import { SportIDs } from 'utils/common/types/sportTypes';

interface Props {
  sportId: number;
  eventTeam: string;
  pitcher?: string | null;
}

export const Team: React.FC<Props> = ({ sportId, eventTeam, pitcher }) => {
  if (sportId === SportIDs.baseball) {
    return (
      <>
        <DesktopDown>
          <div className="d-flex-col">
            <Text className="team text-truncate" text={eventTeam} />
            <Text className="team text-truncate" text={isEmpty(pitcher) ? '' : `(${pitcher})`} />
          </div>
        </DesktopDown>
        <Desktop>
          <div className="event-header__names--wrapper">
            <Text className="team text-truncate" text={eventTeam} />
            <Text className="ml-1 event-header__names--pitcher" text={isEmpty(pitcher) ? '' : `(${pitcher})`} />
          </div>
        </Desktop>
      </>
    );
  }

  return <Text className="mr-auto text-truncate team" text={eventTeam} />;
};
