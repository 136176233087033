import React from 'react';
import Scroll from './gridLayout/Scroll';
import Vertical from './gridLayout/Vertical';
import { GridType, PromotionTypes } from '../../types/promotions.types';

interface GridProps {
  type: GridType;
  children: React.ReactNode;
  layout: PromotionTypes.GridLayoutType;
}

const RenderGrid = {
  vertical: Vertical,
  horizontal: Scroll,
};

const Grid: React.FC<GridProps> = ({ type, children, layout }) => {
  const LayoutComponent = RenderGrid[type];
  return <LayoutComponent layout={layout}>{children}</LayoutComponent>;
};

export default Grid;
