import { useAppDispatch, useAppSelector } from 'store';
import { jackpotSelectors, jackpotThunks } from './jackpotSlice';
import { LayoutConfigInnerLayoutTypes } from '../../constants';
import { useCasinoLayoutConfig } from '../../hooks/useCasinoLayoutConfig';
import { useInterval } from '../../hooks/useInterval';

const TIMER = 10000;

const JackpotsInit = (): null => {
  const dispatch = useAppDispatch();
  const jackpotsConfig = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.JACKPOTS });
  const runInterval = useAppSelector(jackpotSelectors.selectRunFetchInterval);

  // TODO - Update isFakeUpdateON logic
  // const isFakeUpdateON = jackpotsConfig ? jackpotsConfig.isFakeUpdateON : false;

  const fallbackInterval =
    jackpotsConfig && jackpotsConfig?.fallbackInterval !== undefined ? jackpotsConfig.fallbackInterval : TIMER;

  useInterval(() => {
    if (runInterval) {
      dispatch(jackpotThunks.fetchJackpots());
    }
  }, fallbackInterval);

  // useEffect(() => {
  //   dispatch(setFakeUpdate(isFakeUpdateON));
  // }, [dispatch, isFakeUpdateON]);

  return null;
};

export default JackpotsInit;
