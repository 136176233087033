import { loginThunks } from 'pages/auth/login/slice/login.slice';
import { handleUpdateBonusOffers } from 'shared/common/features/myProfile/sections/tabs';
import { handleUpdateNotification } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationSlice';
import { Balance } from 'shared/common/features/myProfile/types/myProfile.types';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { CustomPlatformEvents } from 'shared/common/hooks/useHandleCustomEvents';
import { setUserBalance, setPlayerRestrictions } from 'shared/common/sharedSlices/commonActions';
import { AppDispatch } from 'store';
import { localLog, parseWsPayload } from 'utils/common/helpersCommon';
import { GAME_LAUNCH_PREFIX_PLATFORM } from 'utils/config';
import { isMsgPackMessage, parseMsgPackMessage } from './messagePackSignalR';
import { WebSocketMsgTypes, WS } from './types/webSockets.types';
import { casinoUpdateFunction, interceptorsUpdateFunction } from './updateFunctions';
import { wsActions } from './webSocketActions';
import { updateInGameNotifications } from '../inGameNotifications/slice/inGameNotifications.slice';
import { updateRealityCheckSessionData } from '../realityCheckSession/slice/realityCheckSession.slice';
import { RealityCheckSessionModalTypes } from '../realityCheckSession/types/realityCheckSession.types';

type PayloadHandlerMap = Record<WebSocketMsgTypes, (dispatch: AppDispatch, payload) => void>;
const payloadHandler: PayloadHandlerMap = {
  [WebSocketMsgTypes.LIVE_CASINO_GAME_UPDATE]: (dispatch, payload) =>
    dispatch(casinoUpdateFunction[payload.type](payload)),
  [WebSocketMsgTypes.NOTIFICATION]: (dispatch, payload) => dispatch(handleUpdateNotification(payload)),
  [WebSocketMsgTypes.JACKPOT]: (dispatch, payload: WS.IngameNotification) => {
    dispatch(updateInGameNotifications(payload));
  },
  [WebSocketMsgTypes.PRIZE_DROP]: (dispatch, payload: WS.IngameNotification) =>
    dispatch(updateInGameNotifications(payload)),
  [WebSocketMsgTypes.BONUS_COLLECTION]: (dispatch, payload: WS.IngameNotification) =>
    dispatch(updateInGameNotifications(payload)),
  [WebSocketMsgTypes.INGAME_NOTIFICATION]: (dispatch, payload: WS.IngameNotification) =>
    dispatch(updateInGameNotifications(payload)),
  [WebSocketMsgTypes.BONUS_OFFERS]: (dispatch, payload) => dispatch(handleUpdateBonusOffers(payload)),
  [WebSocketMsgTypes.PLAYER_RESTRICTIONS]: (dispatch, payload: WS.Restrictions) =>
    dispatch(setPlayerRestrictions(payload?.restrictions)),
  [WebSocketMsgTypes.CHANGE_BALANCE]: (dispatch, payload: { value: Balance | null }) =>
    dispatch(setUserBalance(payload?.value)),
  [WebSocketMsgTypes.KICK_OUT_PLAYER]: (dispatch) => dispatch(loginThunks.logout()),
  [WebSocketMsgTypes.FUNDS_DECLARATION]: (dispatch, payload) =>
    dispatch(interceptorsUpdateFunction[payload.type](payload)),
  [WebSocketMsgTypes.REALITY_CHECK]: (dispatch, { message, showLogoutButton }) => {
    document.dispatchEvent(new Event(CustomPlatformEvents.HIGH_PRIORITY_MODAL));
    dispatch(
      showRootModal({
        modalType: 'REALITY_CHECK',
        modalProps: { message, showLogoutButton },
        modalPriority: ModalPriorityEnum.HIGH,
        modalId: 'REALITY_CHECK',
      }),
    );
  },
  [WebSocketMsgTypes.REALITY_CHECK_SESSION_MODAL]: (dispatch, payload) => {
    if (location.pathname?.includes(GAME_LAUNCH_PREFIX_PLATFORM)) {
      const modalType = payload.message.type;
      dispatch(updateRealityCheckSessionData(payload.message));
      if (Object.values(RealityCheckSessionModalTypes).includes(modalType)) {
        const closeGame = (handleBack: () => void) => {
          if (window.opener) window.close();
          else handleBack && handleBack();
        };
        dispatch(
          showRootModal({
            modalType: 'REALITY_CHECK_SESSION',
            modalProps: { modalType, closeGame },
            modalPriority: ModalPriorityEnum.GRAND,
            modalId: `REALITY_CHECK_SESSION_ID_${modalType}`,
          }),
        );
      }
    }
  },
  [WebSocketMsgTypes.PLAYER_LIMITS]: (dispatch, payload) => dispatch(wsActions.wsPlayerLimits(payload)),
};

export const onMessageReceivedSignalR = ({ dispatch, contentType, routingKey, payload }): void => {
  let wsMessage;

  isMsgPackMessage(contentType)
    ? (wsMessage = parseMsgPackMessage(payload, routingKey, contentType))
    : (wsMessage = parseWsPayload(payload));

  if (payloadHandler[wsMessage.type]) {
    payloadHandler[wsMessage.type](dispatch, wsMessage);
    return;
  }
  localLog({ message: `Payload handler encountered unknown payload of type ${payload.message}`, type: 'error' });
};
