import React, { memo, FC, useState, useMemo, useRef, useEffect } from 'react';
import { useAnimationDimensions } from './hooks/useAnimationDimensions';

interface FrameFunction {
  frame: number;
  fn: (resetAnimation: () => void) => void;
}

interface AnimationProps {
  images: string[];
  width: number;
  height: number | null;
  maxWidth: number;
  maxHeight: number | null;
  image?: string; // ignore this, used for styled-components
  fps?: number; // display next frame in 1000 / fps
  autoPlay?: boolean;
  play?: boolean; // play|pause
  rewind?: boolean; // to frame 0
  iterations?: number | null; // how many times to run this animation
  delay?: number; // only if autoplay is true does delay work
  moveToFrame?: number | null; // does not reset currentFrameRef
  onEnterFrame?: FrameFunction[] | null; // frames start from 0, frame 0 = first image in array
  onClick?: () => void;
  onIterationComplete?: () => void;
  onMouseOver?: () => void;
  onEnd?: () => void; //if iterations!==null; when all iterations are over
  aspectRatioWidth: number; // sum dimension by screenSize and aspect ratio
  screenWidthMobile?: number; // sum dimension by screenSize and aspect ratio
  stopEnterFrame?: boolean;
  style?: React.CSSProperties;
}

const FrameAnimator: FC<AnimationProps> = ({
  images,
  fps = 12,
  delay = 0,
  autoPlay = true,
  play = true,
  rewind = false,
  iterations = null,
  width,
  height = null,
  maxWidth,
  maxHeight = null,
  moveToFrame = null,
  onEnterFrame = null,
  onClick,
  onIterationComplete,
  onMouseOver,
  onEnd,
  aspectRatioWidth,
  screenWidthMobile,
  stopEnterFrame,
  style,
}) => {
  const [currentImageIdx, setCurrentImageIdx] = useState<number>(0);
  const currentImageRef = useRef<number>(0);
  const currentFrameRef = useRef<number>(0);
  const currentIterationRef = useRef<number>(1);
  const requestRef = useRef<number>();
  const fpsInterval: number = useMemo(() => 1000 / fps, [fps]);
  // const fpsInterval: number = useMemo(() => {
  //   return Math.floor(1000 / fps) - 1;
  // }, [fps]);

  const { vWidth, vHeight } = useAnimationDimensions(width, aspectRatioWidth, screenWidthMobile);

  const [loaded, setLoaded] = useState<boolean>(false);

  let startedAt: null | number;

  const animate = (time: number, endFrame: FrameFunction[] | boolean | undefined | null) => {
    if (play === false) return;
    if (!startedAt) startedAt = time;
    if (currentImageRef.current + 1 < images.length) {
      // animating until last image
      requestRef.current = requestAnimationFrame((animationTime) => animate(animationTime, endFrame));
    } else {
      if (!iterations) {
        // ∞ iterations
        onIterationComplete && onIterationComplete();
        resetAnimation();
      } else if (iterations > currentIterationRef.current) {
        // do the iterations
        onIterationComplete && onIterationComplete();
        resetAnimation();
        currentIterationRef.current = currentIterationRef.current + 1;
      } else if (iterations === currentIterationRef.current) {
        onIterationComplete && onIterationComplete();
        onEnd && onEnd();
      }
    }

    const elapsed: number = time - startedAt;
    if (elapsed > fpsInterval) {
      currentFrameRef.current = currentFrameRef.current + 1;
      onEnterFrame &&
        !endFrame &&
        onEnterFrame.map(({ frame, fn }) => currentFrameRef.current === frame && fn(resetAnimation)); // execute func if frame matches
      currentImageRef.current = currentImageRef.current + 1; // to be used within this func
      setCurrentImageIdx((idx) => idx + 1); // to re-render component
      startedAt = null;
    }
  };

  const resetAnimation = () => {
    currentImageRef.current = 0;
    currentFrameRef.current = 0;
    setCurrentImageIdx((idx) => idx - idx);
    requestRef.current = requestAnimationFrame((time) => animate(time, onEnterFrame));
    cancelAnimationFrame(requestRef.current || 0);
  };

  useEffect(() => {
    if (moveToFrame !== null) {
      currentImageRef.current = moveToFrame;
      setCurrentImageIdx(moveToFrame);
    }
    return () => setCurrentImageIdx(0);
  }, [moveToFrame]);

  useEffect(() => {
    if (rewind) {
      currentImageRef.current = 0;
      setCurrentImageIdx(0);
    }
    return () => setCurrentImageIdx(0);
  }, [rewind]);

  useEffect(() => {
    if (!loaded) return;
    if (autoPlay) {
      setTimeout(() => (requestRef.current = requestAnimationFrame((time) => animate(time, stopEnterFrame))), delay);
    } else if (play) {
      requestRef.current = requestAnimationFrame((time) => animate(time, stopEnterFrame));
    }
    return () => cancelAnimationFrame(requestRef.current || 0);
  }, [play, loaded, stopEnterFrame]);

  useEffect(() => {
    if (images.length === 0) return;
    const decodedImages = images.map((image) => {
      const tmpImg = new Image();
      tmpImg.src = image;
      const imageDecoded = tmpImg
        .decode()
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
      return imageDecoded;
    });

    if (decodedImages.length === images.length) {
      setLoaded(true);
    }
  }, [images]);

  const memoImages = useMemo(
    () =>
      images.map((image, index) => {
        return (
          <div
            className="animation-frame__image"
            key={index}
            style={{
              backgroundSize: `${width}px ${height}px`,
              zIndex: 1000 - index,
              backgroundImage: `url(${image})`,
              visibility: index === currentImageIdx ? 'visible' : 'hidden',
            }}
          />
        );
      }),
    [currentImageIdx],
  );

  return (
    <div
      className="animation-frame__wrapper"
      style={{
        width: width ? `${vWidth}vw` : 'auto',
        height: height ? `${vHeight}vw` : 'auto',
        maxWidth: maxWidth ? maxWidth : 'auto',
        maxHeight: maxHeight ? maxHeight : 'auto',
        position: 'relative',
        ...style,
      }}
      onClick={onClick}
      onMouseOver={onMouseOver}
    >
      {memoImages}
    </div>
  );
};

export default memo(FrameAnimator);
