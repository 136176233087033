import styled from 'styled-components';

const StyledCardTitle = styled.div.attrs({
  className: 'text-truncate prm-lobby-card__title',
})`
  min-height: clamp(1.375rem, 1.014rem + 1.54vw, 2rem); // For when title is missing
  font-size: clamp(0.875rem, calc(0.731rem + 0.62vw), 1.125rem);
  font-weight: 700;
  color: #fff;
`;

export default StyledCardTitle;
