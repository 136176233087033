import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

/** Cashier Modal Buttons Wrapper Section */
interface ButtonsWrapperProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: React.ReactNode;
}

const ButtonsWrapper: React.FC<ButtonsWrapperProps> = ({ className, children }) => {
  const wrapClass = clsx('cashier-modal__buttons-wrapper', className);

  return <div className={wrapClass}>{children}</div>;
};

export default ButtonsWrapper;
