import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { ButtonProps, ButtonIconProps, cssBtnNS } from './ButtonBack.types';
import { Icon } from '../../icons';
import './ButtonBack.scss';

/** EGTD Casino  Back Button **/
const ButtonIcon: React.FC<ButtonIconProps> = ({ icon, size = 16, iconClassName }) => {
  const iconClass = clsx(`${cssBtnNS}__icon`, iconClassName);
  const iconSize = size;

  return <>{icon && <Icon defaultClassName={false} className={iconClass} size={iconSize} icon={icon} />}</>;
};

const ButtonBack = React.forwardRef<HTMLButtonElement, ButtonProps & HTMLAttributes<HTMLButtonElement>>(
  ({ type = 'button', className, icon, size = 16, iconClassName, onClickHandler, ...rest }, ref) => {
    const classProp = { className: clsx(`${cssBtnNS}`, className || null) };
    return (
      <button ref={ref} c-type={type} {...classProp} {...rest} onClick={onClickHandler}>
        <ButtonIcon icon={icon} size={size} iconClassName={iconClassName} />
      </button>
    );
  },
);

ButtonBack.displayName = 'EGTD Casino Back Button';

export default ButtonBack;
