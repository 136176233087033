import React, { memo, useCallback, useState } from 'react';
import { motion } from 'framer-motion';
import { gamesSelectors } from 'modules/casino/modules/container/cellsContainer/cellls/slice/casinoGamesSlice';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { useAppSelector } from 'store';
import useExpandingSliderBanner from './hooks/useExpandingSliderBanner';
import { ExpandingBannerSize, ExpandingGridSize } from '../../../../types/gridTypes';
import GridCellLayout from '../../../gridCell/GridCellLayout';

type Props = {
  bannerSize: ExpandingBannerSize;
  bannerImageSize: ExpandingBannerSize;
  expandingGridSize: ExpandingGridSize;
  categoryId: string;
  collectionId: string;
  categoryLayoutTypes: CategoryLayoutTypes;
};

const ExpandingGridSection: React.FC<Props> = ({
  bannerSize,
  bannerImageSize,
  collectionId,
  expandingGridSize,
  categoryId,
  categoryLayoutTypes,
}) => {
  const expandingIds = useAppSelector((state) => gamesSelectors.expandingGameIds(state, collectionId));
  const { queryType, gameSection } = useGetCategoryParams(categoryId, categoryLayoutTypes);
  const { background, backgroundColor } = gameSection;

  const expandingWrapperBackground = backgroundColor
    ? backgroundColor[1] !== backgroundColor[0]
      ? `linear-gradient( 0.25turn, ${backgroundColor[0]}, ${backgroundColor[1]})`
      : backgroundColor[0]
    : '';

  const expandingWrapperStyle = {
    background: expandingWrapperBackground,
  };
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const bannerImage = useExpandingSliderBanner({ imageSrc: background, bannerImageSize });

  const bannerStyle = {
    backgroundImage: bannerImage && bannerImage,
    width: bannerSize.width || 0,
    height: bannerSize.height || 0,
  };

  const expandingGridStyle = {
    gridTemplateRows: expandingGridSize.gridTemplateRows,
    gridAutoColumns: expandingGridSize.gridAutoColumns,
  };
  const handleCollapsed = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  if (!expandingIds.length) return;

  return (
    <div className={`${cssGlobalNS}-c-expanding--wrapper`}>
      <div className={`${cssGlobalNS}-c-expanding--banner`} style={bannerStyle}>
        <div className={`${cssGlobalNS}-c-expanding--banner-button`}>
          <motion.div
            initial={{ transform: 'rotate(-90deg)' }}
            animate={{ transform: isOpen ? 'rotate(-270deg)' : 'rotate(-90deg)' }}
            transition={{ ease: 'easeInOut' }}
            onClick={handleCollapsed}
            className="arrow-icon"
          >
            <svg className={`icon`} viewBox={'0 0 14 14'}>
              <use href={'#chevron'} />
            </svg>
          </motion.div>
        </div>
      </div>
      <motion.div
        initial={{ width: '0', overflow: 'hidden' }}
        animate={{ width: isOpen ? 'auto' : '0' }}
        transition={{ ease: 'easeInOut' }}
        className={`${cssGlobalNS}-c-expanding-games--wrapper`}
        style={expandingWrapperStyle}
      >
        <div className={`${cssGlobalNS}-c-expanding-games`} style={expandingGridStyle}>
          {expandingIds.map((uniqueId, index) => {
            return (
              <GridCellLayout
                key={uniqueId}
                index={index}
                gridPage={1}
                gamesRenderedCount={3}
                categoryId={categoryId}
                isLastItem={false}
                gameUniqueId={uniqueId}
                collectionId={collectionId}
                skipLazyLoad={true}
                queryType={queryType}
                categoryLayout={categoryLayoutTypes}
              />
            );
          })}
        </div>
      </motion.div>
    </div>
  );
};

export default memo(ExpandingGridSection);
