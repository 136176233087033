import { useCallback, useEffect } from 'react';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { useAppDispatch } from 'store';
import { setIsWidgetLoaded } from '../cashierWidget.slice';
import { cashierScriptInit } from '../widget.utils';

const useCashierScript = (isWidgetLoaded: boolean) => {
  const dispatch = useAppDispatch();
  const isCashierNewImplementation = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.CASHIER_NEW,
  });

  const callback = useCallback(() => {
    !isCashierNewImplementation && dispatch(setIsWidgetLoaded(true));
  }, [dispatch, isCashierNewImplementation]);

  useEffect(() => {
    if (window.config.CASHIER_URL && !isWidgetLoaded) {
      cashierScriptInit({
        document,
        src: window.config.CASHIER_URL,
        attr: 'cashier',
        callback,
      });
    }

    if (isCashierNewImplementation) {
      const handleCashierAppLoaded = () => {
        dispatch(setIsWidgetLoaded(true));
        window.removeEventListener('cashierWidgetLoaded', handleCashierAppLoaded);
      };

      // Listen for the custom event from the child app
      window.addEventListener('cashierWidgetLoaded', handleCashierAppLoaded);

      return () => {
        window.removeEventListener('cashierWidgetLoaded', handleCashierAppLoaded);
      };
    }
  }, [callback, isWidgetLoaded, dispatch, isCashierNewImplementation]);

  useEffect(() => {
    const widgetJSFile = document.querySelector('script[n="cashier"]');
    return () => {
      widgetJSFile?.remove();
    };
  }, []);
};

export default useCashierScript;
