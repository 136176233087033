import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { UI } from 'components/shared/UI';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import { BurgerUI } from './BurgerUI';
import { useNavigateToBankTab } from '../../../../../pages/myAccount/tabs/cashier/utils/cashier.utils';
import useNavUserLogged from '../../appHeader/hooks/useNavUserLogged';
import { setDrawer } from '../../drawer/slice/drawer.slice';
import AmountCurrency from '../../myProfile/components/AmountCurrency';
import { MPDUI } from '../../myProfile/components/MPDUI';
import { useUserBalance } from '../../myProfile/hooks/useUserBalance';
import { selectMyProfile, showMyProfileModal } from '../../myProfile/slice/myProfile.slice';

const BurgerAccount: React.FC = () => {
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);

  const user = useAppSelector(selectMyProfile.dataUser);
  const { balanceAmount } = useUserBalance();
  const { balanceVisibility } = useNavUserLogged();
  const bankNavigate = useNavigateToBankTab();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const navigateToBank = () => {
    dispatch(showMyProfileModal(false));
    dispatch(setDrawer(false));
    bankNavigate('deposit');
  };

  const cssNS = 'mpd-section-info';

  if (!isAuthenticated) return null;

  return (
    <>
      <BurgerUI.Section>
        <Flex.AC className={`${cssNS}__left-col`}>
          <Icon className={`${cssNS}__icon`} icon="user" />
          <Flex.Col className={`mw-0 overflow-hidden ${cssNS}__user-block`}>
            {/* <MPDUI.Text variant="primary" text={`${user?.firstName || ''} ${user?.lastName || ''}`} /> */}
            <MPDUI.Text variant="secondary" text={user?.username} />
            {balanceVisibility ? (
              <AmountCurrency spaceBefore={false} currencyClassName="nav-user__currency" amount={balanceAmount} />
            ) : (
              '**************'
            )}
          </Flex.Col>
        </Flex.AC>
        <Flex.AC className={`${cssNS}__right-col`}>
          <>
            <UI.Button
              data-qid="userDepositBtn"
              size="md"
              onClick={navigateToBank}
              text={t(MyProfile.Button.depositProfileBtn)}
            />
          </>
        </Flex.AC>
      </BurgerUI.Section>
      <BurgerUI.Separator className="my-2" />
    </>
  );
};

export default memo(BurgerAccount);
