import { InputType as FormInputType, InputTypes as FormInputTypes } from 'components/shared/forms/formTypes';
import { CreateThunk } from 'store/thunkCreators';

export enum RealityCheckSessionModalTypes {
  CREATE = 'create',
  TIMEOUT = 'timeout',
  INFO = 'info',
  WARN = 'warn',
  END = 'end',
}
export enum RealityCheckSessionTitleTypes {
  FORM_PRE_MODAL = 'formPreModal',
  FORM_TITLE = 'formTitle',
  FORM_SUBTITLE = 'formSubTitle',
  FORM_CONTENT = 'formContent',
}

export declare module RealityCheckSession {
  type CommonDuration = {
    sessionStartTime: number;
    sessionEndTime: number;
  };
  type CommonLimitValues = {
    hasHitBalanceLimit: boolean;
    hasHitTimeLimit: boolean;
  };
  type InfoModalData = RealityCheckSession.CommonDuration & {
    totalNet: number;
    totalRealBet: number;
    totalRealWin: number;
    totalBonusBet: number;
    totalBonusWin: number;
  };
  type WarnModalData = RealityCheckSession.CommonDuration &
    RealityCheckSession.CommonLimitValues & {
      initialBalance: number;
      currentBalance: number;
    };
  type EndModalData = RealityCheckSession.CommonLimitValues & {
    nextSessionStartTime: number;
    hitBalanceLimitValue: number;
    hitTimeLimitValue: number;
  };

  type HeaderData =
    | (RealityCheckSession.CommonDuration & {
        totalBet: number;
        totalNet: number;
      })
    | null;

  type ModalsData = {
    info: InfoModalData | null;
    warn: WarnModalData | null;
    end: EndModalData | null;
  };

  type TitleNames = 'rgPopupAlertTime' | 'title' | 'subtitle' | 'lastSessionDuration';
  type Titles = {
    name: RealityCheckSession.TitleNames;
    type: RealityCheckSessionTitleTypes;
    label: string;
    infoText?: string;
    showModal?: 'true' | 'false';
  };

  type InputNames = 'selectDuration' | 'selectInitialBalance' | 'selectAlertPeriod' | 'selectTimeoutPeriod';
  type InputTypes = FormInputTypes.INPUT | FormInputTypes.SELECT;
  type Inputs = {
    name: RealityCheckSession.InputNames;
    type: RealityCheckSession.InputTypes;
    inputType: FormInputType;
    label: string;
    placeholder: string;
    options?: {
      text: string;
      value: string;
      disabled?: boolean;
    }[];
  };

  type FormFields = [RealityCheckSession.Titles | RealityCheckSession.Inputs];

  type FetchFormRequestData = {
    resolve?: (value: unknown) => void;
    reject?: (value: unknown) => void;
    closeGame?: () => void;
  };
  type CreateRequestData = {
    balance: number;
    duration: number;
    alertPeriod: number;
    timeoutPeriod: number;
    onSuccess: () => void;
  };
  type ResponseData = RealityCheckSession.CommonDuration;
  type Thunks = {
    fetchFormFields: CreateThunk<RealityCheckSession.FetchFormRequestData, RealityCheckSession.FormFields>;
    sessionCreate: CreateThunk<RealityCheckSession.CreateRequestData, RealityCheckSession.ResponseData>;
  };
  type ErrorData = string | undefined | null;

  type WSLiveMessage = {
    type: 'live';
    data: RealityCheckSession.HeaderData;
  };
  type WSInfoMessage = {
    type: RealityCheckSessionModalTypes.INFO;
    data: RealityCheckSession.InfoModalData;
  };
  type WSWarnMessage = {
    type: RealityCheckSessionModalTypes.WARN;
    data: RealityCheckSession.WarnModalData;
  };
  type WSEndMessage = {
    type: RealityCheckSessionModalTypes.END;
    data: RealityCheckSession.InfoModalData & RealityCheckSession.EndModalData;
  };
  type WSMessage = WSLiveMessage | WSInfoMessage | WSWarnMessage | WSEndMessage;

  type CommonState = {
    error: ErrorData;
  };
  type FormState = RealityCheckSession.CommonState & {
    fields: RealityCheckSession.FormFields | null;
    modal: boolean;
  };

  type SessionState = RealityCheckSession.CommonState & {
    data: {
      liveTime: number;
      endTime: number | null;
      header: RealityCheckSession.HeaderData | null;
      modals: RealityCheckSession.ModalsData;
    };
  };

  type State = {
    form: RealityCheckSession.FormState;
    session: RealityCheckSession.SessionState;
    isLoading: boolean;
  };
}
