import { CasinoGamesTypes } from 'modules/casino/modules/container/cellsContainer/cellls/types/casinoGamesTypes';
import { GameCellDataCharactersTypes } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTableCharacters/gameCellTableCharactersConstants';
import { GameData } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTablePlayers/gameCellTablePlayersTypes';
import { GameTag } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/GameCellTagsTypes';
import { useGameTags } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/hooks/useGameTags';

type UseWrapperProps = {
  game: CasinoGamesTypes.GameProps;
};

type UseDataWrapperReturnTypes = {
  gameData: GameData;
  showDataImage: number | boolean;
  hideDataCharacters: GameTag | undefined | boolean;
  showTablePlayers: boolean;
  showTableCharacters: boolean;
  showGameCellTags: boolean;
  showTableLimits: boolean;
};

export const useDataWrapper = ({ game }: UseWrapperProps): UseDataWrapperReturnTypes => {
  const gameData: GameData = {
    gameProviderId: game?.providerId,
    visualizationTemplateId: game?.visualizationTemplateId,
    betLimits: game?.liveFeedInfo?.betLimits,
    gameLayout: game?.gameLayout?.toLowerCase(),
    refGameId: game?.refGameId,
    betBehind: game?.liveFeedInfo?.betBehind,
    seats: game?.liveFeedInfo?.seats,
    seatsTaken: game?.liveFeedInfo?.seatsTaken || [],
    description: game?.description,
    tablePlayers: game?.liveFeedInfo?.tablePlayers,
    results: game?.liveFeedInfo?.results,
    gameResult: game?.liveFeedInfo?.gameResult,
    history: game?.liveFeedInfo?.history,
    ante: game?.liveFeedInfo?.ante,
    isFast: game?.isFast,
    gameCurrency: game?.tableCurrency,
  };
  const { bottomTag, hasTranspBottomTag } = useGameTags(game);
  const showDataImage = gameData.visualizationTemplateId === GameCellDataCharactersTypes.Blackjack && gameData.seats;

  return {
    gameData,
    showDataImage,
    hideDataCharacters: bottomTag || hasTranspBottomTag,
    showTablePlayers: Boolean(showDataImage) && !(bottomTag || hasTranspBottomTag),
    showTableCharacters: !(bottomTag || hasTranspBottomTag),
    showGameCellTags: game?.tags ? true : false,
    showTableLimits: game?.liveFeedInfo?.betLimits ? true : false,
  };
};
