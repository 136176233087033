import React from 'react';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { navigateToIntegration } from 'shared/common/features/integrations/utils/integrations.utils';
import NavUserButton from '../common/NavUserButton';

export type ScheduleIconLocationType = {
  state: { navigateFromScheduleIcon: true };
};

const ScheduleLink: React.FC = () => {
  const navigate = useNavigate();

  const goToSchedule = () => {
    navigate('/sports/program', { state: { navigateFromScheduleIcon: true } });
    navigateToIntegration({ fullPath: '/sports/program' });
  };
  const { state } = useLocation() as ScheduleIconLocationType;

  const isActive = window.location.href.indexOf('sports/program') != -1;

  const btnClass = clsx('ml-0');

  return (
    <NavUserButton
      className={btnClass}
      onClick={goToSchedule}
      icon="schedule"
      notificationsCounter={0}
      isActive={isActive && state?.navigateFromScheduleIcon}
    />
  );
};

export default ScheduleLink;
