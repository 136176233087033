import React from 'react';
import { useTranslation } from 'react-i18next';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import PrizeDropTimeCounter from './PrizeDropTimeCounter';

type Props = {
  isColumn?: boolean;
};

const PromotionTimeExpired: React.FC<Props> = ({ isColumn }) => {
  const { t } = useTranslation();

  return (
    <PrizeDropTimeCounter isColumn={isColumn} date={0}>
      <div className={isColumn ? 'mb-1' : 'mr-2'}>{t(Promotions.Common.expiredText)}</div>
    </PrizeDropTimeCounter>
  );
};

export default PromotionTimeExpired;
