import { css } from 'styled-components';
import { Gradient } from 'theme/StyledComponents/shared/Gradient';
// Buttons partials
export const PrimaryActionButtonColor = css`
  fill: currentColor;
  transition:
    color 0.15s,
    background-color 0.15s;
  &,
  &:hover {
    color: ${({ theme }) => theme.Buttons.ActionSuccessPrimaryWithHover.color};
  }
`;

export const PrimaryActionButtonBgWithHover = css`
  cursor: pointer;
  ${({ theme }) =>
    theme.Common.ThemeOptions.enableButtonBgGradients
      ? `

        ${Gradient(
          theme.Buttons.ActionSuccessPrimaryWithHover.bgStart,
          theme.Buttons.ActionSuccessPrimaryWithHover.bgEnd,
        )}
        &:hover {
            ${Gradient(
              theme.Buttons.ActionSuccessPrimaryWithHover.bgStartHover,
              theme.Buttons.ActionSuccessPrimaryWithHover.bgEndHover,
            )}
        }
    `
      : `
        background-color:${theme.Buttons.ActionSuccessPrimaryWithHover.bg};
        transition: color 0.15s, background-color 0.15s;
        &:hover {
            background-color:${theme.Buttons.ActionSuccessPrimaryWithHover.bgHover};
        }
  `}
`;
export const primaryActionButtonBg = css`
  ${({ theme }) =>
    theme.Common.ThemeOptions.enableButtonBgGradients
      ? `${Gradient(theme.Buttons.PrimaryAction.bgStart, theme.Buttons.PrimaryAction.bgEnd)}`
      : `background-color:${theme.Buttons.PrimaryAction.bg};`}
`;

export const ButtonsActionDesign = {
  PrimaryActionButtonColor,
  PrimaryActionButtonBgWithHover,
  primaryActionButtonBg,
};
