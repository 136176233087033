import { useEffect } from 'react';
import { useAppDispatch } from 'store';
import { setDrawer } from '../../drawer/slice/drawer.slice';
import { keepMyProfileModalShown, showMyProfileModal } from '../../myProfile/slice/myProfile.slice';

export const useShowGamificationModal = (isReady: boolean): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isReady) {
      dispatch(keepMyProfileModalShown(false));
      dispatch(showMyProfileModal(false));
      dispatch(setDrawer(false));
      document.body.classList.add('modal-open');
    }
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [isReady, dispatch]);
};
