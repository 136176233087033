import React, { memo, useCallback } from 'react';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { IconLoader } from 'components/shared/loaders';
// import { StyledContactBlockUIChat } from 'pages/help/components/styled/ContactBlockUI.styled';
import { TextAndIcon } from './TextAndIcon';
import { ChatButtonType } from './types';
import { setIsOpenChat } from '../features/general/slice/general.slice';

interface Props {
  text: string;
  type: ChatButtonType;
  isOpenChat?: boolean;
  chatLoaded: boolean;
}

export const SupportChatButtons: React.FC<Props> = memo(({ text, type, isOpenChat, chatLoaded }) => {
  const textAndIconBtnClass = clsx(`d-flex-center egtd-x-chat-btn`);
  const textBtnClass = clsx('help-contact-block__link d-flex-center', 'help-contact-block__link--chat');
  const dispatch = useDispatch();
  // const isLoadedChat = useAppSelector(selectGeneral.selectIsLoadedChat);

  const handleButtonClick = useCallback(() => {
    if (!isOpenChat) {
      dispatch(setIsOpenChat(true));
    }
  }, [isOpenChat, dispatch]);

  const getButton = (isOpen: boolean | undefined) => {
    switch (type) {
      case ChatButtonType.TEXT:
        return (
          <button
            className={textBtnClass}
            onTouchStart={handleButtonClick}
            onClick={handleButtonClick}
            disabled={isOpen}
          >
            {isMobile ? !chatLoaded && isOpen ? <IconLoader /> : text : text}
          </button>
        );
      case ChatButtonType.TEXTWITHICON:
        return (
          <button
            className={textAndIconBtnClass}
            onTouchStart={handleButtonClick}
            onClick={handleButtonClick}
            disabled={isOpen}
          >
            {isMobile ? !chatLoaded && isOpen ? <IconLoader /> : <TextAndIcon /> : <TextAndIcon />}
          </button>
        );
    }
  };

  return <>{getButton(isOpenChat)}</>;
});
SupportChatButtons.displayName = 'SupportChatButtons';
