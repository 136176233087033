import styled from 'styled-components';
import { Breakpoints } from 'modules/casino/theme/Theme';

export const StyledArrows = styled.span<{ $startAnimate: boolean; $isSecond?: boolean }>`
  margin-left: 6px;
  position: absolute;
  top: ${({ $isSecond }) => ($isSecond ? '10px' : '0px')};
  opacity: ${({ $isSecond }) => ($isSecond ? '1' : '0')};
  transform: ${({ $isSecond }) => ($isSecond ? '' : 'scale3d(0.5, 0.5, 0.5)')};
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    margin-left: 8px;
    top: ${({ $isSecond }) => ($isSecond ? '14px' : '0px')};
  }
  ${({ $isSecond, $startAnimate, theme }) =>
    $startAnimate && theme.LayoutConfigs?.casino?.grid?.loadMoreBtn === 'layout_1'
      ? $isSecond
        ? `animation: move 1.5s ease-out 0.2s ;
    top:13px`
        : `animation: move 1.5s ease-out 0s ;`
      : ``}

  @keyframes move {
    25% {
      opacity: 1;
    }
    33% {
      opacity: 1;
      transform: translateY(5px);
    }
    67% {
      opacity: 1;
      transform: translateY(8px);
    }
    100% {
      opacity: 0;
      transform: translateY(10px) scale3d(0.5, 0.5, 0.5);
    }
  }
`;
export default StyledArrows;
