import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import { PromoUI } from '../components/PromoUI';
import { PromotionTypes } from '../types/promotions.types';

type Props = { awards: PromotionTypes.AwardPlaces[] };

const headings = [{ text: Promotions.Tournaments.placeLabel }, { text: Promotions.Tournaments.awardLabel }];

const RankingsAwards: React.FC<Props> = ({ awards }) => {
  const { t } = useTranslation();

  return (
    <Flex.Col className="prm-awards">
      <PromoUI.RankingBox isRow>
        {headings.map((heading, idx) => (
          <PromoUI.RankingBox key={idx} text={t(heading.text)} />
        ))}
      </PromoUI.RankingBox>
      {awards?.map((award, idx) => (
        <PromoUI.RankingBox key={idx} isRow>
          <PromoUI.RankingBox text={award.place} />
          <PromoUI.RankingBox text={award.award} />
        </PromoUI.RankingBox>
      ))}
    </Flex.Col>
  );
};

export default RankingsAwards;
