import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { Layout02 } from 'pages/promotions/layouts/layout_2';
import { SharedLayout } from 'pages/promotions/layouts/shared';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import { TimeDataType } from 'utils/dateUtils';
import CTAButton from './CTAButton';

interface ThumbProps {
  title: string;
  banner: string;
  subTitle: string;
  description: string;
  ctaOnClick: React.MouseEventHandler;
  dateDifference?: TimeDataType;
  titleStyle?: { color: string; backgroundColor: string };
  subTitleStyle?: { color: string; backgroundColor: string };
  descriptionStyle?: { color: string; backgroundColor: string };
}

const Thumb: FC<ThumbProps> = ({ dateDifference, description, ctaOnClick, descriptionStyle = {} }) => {
  const { t } = useTranslation();

  return (
    <>
      <Layout02.CardDetails>
        <Layout02.CardText type="description" text={description} />

        <Flex.ACJB className="mt-auto" style={{ ...descriptionStyle }}>
          {dateDifference && dateDifference.days > -1 && (
            <SharedLayout.RemPeriod style={{ opacity: 0.6 }} dateDifference={dateDifference} />
          )}

          <CTAButton text={t(Promotions.PromotionCell.showMore)} onClick={ctaOnClick} />
        </Flex.ACJB>
      </Layout02.CardDetails>
    </>
  );
};

export default Thumb;
