import { SettingsTypes } from 'pages/myAccount/tabs/settings/types/settings.types';
import { FavouritesTypes } from 'shared/common/features/drawer/components/favourites/types/favourites.types';
import { getUniqueUuidv4, isEmpty } from 'utils/common/helpersCommon';
import { Login } from './types/login.types';

export const AUTH_KEY = 'authentication';

class UserStorage {
  logout() {
    localStorage.removeItem(AUTH_KEY);
    sessionStorage.removeItem('uniqueTabId');
    localStorage.removeItem('isBalanceVisible');
    sessionStorage.removeItem('egtd:insufficientBalance');
  }

  setUser(responseData: Login.LoginResponse) {
    const expirationDate = Date.now() + responseData.expires_in * 1000;
    const refreshExpirationDate = Date.now() + responseData.refresh_token_expires_in * 1000;
    const userAuth: Login.LoginResponseExtended = {
      ...responseData,
      expirationDate,
      refreshExpirationDate,
      loginDate: Date.now(),
    };
    localStorage.setItem(AUTH_KEY, JSON.stringify(userAuth));
  }

  setUserRefreshToken(responseData: Login.RefreshTokenResponse) {
    const storedUser = this.getUser();
    const expirationDate = Date.now() + responseData.expires_in * 1000;

    const userAuth: Login.LoginResponseExtended = { ...storedUser, ...responseData, expirationDate };
    localStorage.setItem(AUTH_KEY, JSON.stringify(userAuth));
  }

  getUser() {
    const userJson = localStorage.getItem(AUTH_KEY);
    return userJson !== null ? JSON.parse(userJson) : null;
  }

  setUserLang(lang: string) {
    localStorage.setItem('lang', lang);
  }

  getUserLang() {
    return localStorage.getItem('lang');
  }

  setUserTimeZone(timezone) {
    localStorage.setItem('tz_offset', timezone);
  }

  getUserTimeZone() {
    return localStorage.getItem('tz_offset');
  }

  setUserIanaTimeZoneCode(tzCode: string) {
    localStorage.setItem('egtd:iana_tz_code', tzCode);
  }

  getUserIanaTimeZoneCode() {
    return localStorage.getItem('egtd:iana_tz_code');
  }

  setUserOddsId(oddsId) {
    localStorage.setItem('odds_id', oddsId);
  }

  getUserOddsId() {
    return localStorage.getItem('odds_id');
  }

  setUserCurrency(curr) {
    localStorage.setItem('currency', curr);
  }

  getUserCurrency() {
    return localStorage.getItem('currency');
  }

  clearUserPreferences() {
    localStorage.removeItem('currency');
    localStorage.removeItem('tz_offset');
    localStorage.removeItem('lang');
    localStorage.removeItem('tz_auto');
  }

  setUserPopupNotificationId(id: string) {
    localStorage.setItem('popup_notification_id', id);
  }

  setUserModalType(modalId: string) {
    localStorage.setItem('egtd:modalType', modalId);
  }

  removeUserModalType() {
    localStorage.removeItem('egtd:modalType');
  }

  setAutomaticTimeZone(timezone: SettingsTypes.AutomaticTimeZone) {
    localStorage.setItem('tz_auto', JSON.stringify(timezone));
  }

  getAutomaticTimeZone(): SettingsTypes.AutomaticTimeZone {
    const tz = localStorage.getItem('tz_auto');
    return tz ? JSON.parse(tz) : {};
  }

  setPreferredThemeSkin(theme: string) {
    localStorage.setItem('theme', theme);
  }

  getPreferredThemeSkin() {
    const theme = localStorage.getItem('theme');
    return theme;
  }

  setBalanceVisibility = (isVisible: boolean) => {
    localStorage.setItem('isBalanceVisible', JSON.stringify(isVisible));
  };

  getBalanceVisibility = (): boolean => {
    const isVisible = localStorage.getItem('isBalanceVisible');
    return isVisible ? JSON.parse(isVisible) : true;
  };

  setSVGSprites = (sprites: string) => {
    localStorage.setItem('svgSprites', sprites);
    localStorage.setItem('svg_timestamp', String(new Date()));
  };

  getSVGSprites = () => {
    const svgSprites = localStorage.getItem('svgSprites');
    const date = localStorage.getItem('svg_timestamp');
    return { svgSprites, date };
  };

  setUIIcons = (spritesName: string, sprites: string, spritesTimeStampName: string) => {
    localStorage.setItem(spritesName, sprites);
    localStorage.setItem(spritesTimeStampName, String(new Date()));
  };

  getUIIcons = (spritesName: string, spritesTimeStampName: string) => {
    const uiIcons = localStorage.getItem(spritesName);
    const date = localStorage.getItem(spritesTimeStampName);
    return { uiIcons, date };
  };

  getUserFavourites(): FavouritesTypes.Favourites {
    const favouritesJson = localStorage.getItem('favourites');
    return favouritesJson ? JSON.parse(favouritesJson) : {};
  }

  setUserFavourites(favourites: FavouritesTypes.Favourites) {
    const storedFavourites = this.getUserFavourites();
    if (isEmpty(favourites)) {
      localStorage.setItem('favourites', JSON.stringify(favourites));
    } else {
      localStorage.setItem('favourites', JSON.stringify({ ...storedFavourites, ...favourites }));
    }
  }

  setIsAppVisited() {
    localStorage.setItem('isAppVisited', 'true');
  }

  getAppIsVisited() {
    return localStorage.getItem('isAppVisited');
  }

  removeIsAppVisited() {
    return localStorage.removeItem('isAppVisited');
  }

  getMultiAccountDataKey() {
    return localStorage.getItem('egtd_players_key');
  }

  getMultiAccountDataValue() {
    const multiAccountPlayerValues = localStorage.getItem('egtd_players_value');
    return multiAccountPlayerValues ? JSON.parse(multiAccountPlayerValues) : [];
  }

  setMultiAccountDataKey() {
    const currentPlayersKey = this.getMultiAccountDataKey();
    if (isEmpty(currentPlayersKey)) {
      localStorage.setItem('egtd_players_key', getUniqueUuidv4());
    }
  }

  setMultiAccountDataValue(playerId: string) {
    const currentPlayersValue = this.getMultiAccountDataValue();
    if (isEmpty(currentPlayersValue)) {
      localStorage.setItem('egtd_players_value', JSON.stringify([playerId]));
    } else {
      if (!currentPlayersValue.includes(playerId)) {
        localStorage.setItem('egtd_players_value', JSON.stringify([...currentPlayersValue, playerId]));
      }
    }
  }
}

const userStorage = new UserStorage();

export default userStorage;
