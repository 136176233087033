import React, { Suspense } from 'react';
import cell01ComponentLoader from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell01/Cell01ComponentLoader';
import cell01StyleLoader from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell01/Cell01StyleLoader';
import { LayoutTypes } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/LayoutTypes';
import {
  ComponentNames,
  ComponentType,
  StyledNames,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/layoutWrapperConstants';
import { SharedComponents } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared';

const GameCellWrapper: React.FC<LayoutTypes.GameCellWrapperProps> = ({ gameUniqueId, categoryId }) => {
  const Description = cell01ComponentLoader(ComponentNames.Description, ComponentType.data);
  const GameCellWrapperStyled = cell01StyleLoader(StyledNames.GameCellWrapperStyled, ComponentType.data);

  return (
    <GameCellWrapperStyled className={'casino-game-cell casino-game-cell--data'}>
      <Suspense>
        <SharedComponents.GameCellTablePlayers gameUniqueId={gameUniqueId} categoryId={categoryId} />
        <SharedComponents.GameCellTableCharacters
          gameUniqueId={gameUniqueId}
          categoryId={categoryId}
          classFromLayout={'mix-grid-character'}
          layoutStyleLoader={cell01StyleLoader}
        />

        <SharedComponents.GameCellTags
          gameUniqueId={gameUniqueId}
          categoryId={categoryId}
          className={`cell-tag-bottom--mixin cell-tag-bottom--layout_1`}
        >
          <SharedComponents.GameCellTableLimits gameUniqueId={gameUniqueId} categoryId={categoryId} />
        </SharedComponents.GameCellTags>
        <Description gameUniqueId={gameUniqueId} categoryId={categoryId} />
      </Suspense>
    </GameCellWrapperStyled>
  );
};

export default GameCellWrapper;
GameCellWrapper.displayName = 'GameCellWrapper';
