import { memo, useMemo } from 'react';
import { Flex } from 'components/shared/layout';
import { defineAnimationParams } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationUtils';
import CashBell from './CashBell';
import CTAText from './components/CTAText';
import { SCCTAWinText } from './components/CTAText.styled';
import { LogoWrapper } from './components/LogoWrapper.styled';
import Win from './components/Win';
import { SCWinWrapper } from './components/Win.styled';
import { hammerCampaignImages } from './utils/cashBell.utils';
import { NotificationTypes } from '../../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import { GamificationUI } from '../../components/GamificationUI';
import useAnimationComplete from '../../hooks/useAnimationComplete';
import useHandleBonusActions from '../../hooks/useHandleBonusActions';
import useOfferTerms from '../../hooks/useOfferTerms';

const CashBellContainer: React.FC<{ notification: NotificationTypes.PopUpNotification }> = ({ notification }) => {
  const { bonusData, header, subheader, message, style } = notification;
  const singleBonus = bonusData?.bonuses?.[0];

  const { animationComplete, setAnimationComplete } = useAnimationComplete();

  const animationParams = useMemo(
    () =>
      defineAnimationParams({
        animationKey: style.animationKey,
        layout: style.layout,
        folder: style.folder,
        numberOfFrames: style.numberOfFrames,
      }),
    [style.animationKey, style.layout, style.folder, style.numberOfFrames],
  );

  const { showTerms, outsideClick, offerTerms, fetchBonusTerm } = useOfferTerms(singleBonus);
  const { handleOfferAction, error, status, statusCode } = useHandleBonusActions({
    bonus: singleBonus,
    notificationId: notification.id,
  });

  const { popUpBgImgSrc } = hammerCampaignImages(animationParams.folder);

  if (!singleBonus) return null;

  return (
    <>
      <LogoWrapper>
        <GamificationUI.Header className="text-center">
          <GamificationUI.Logo animationParams={animationParams} />
        </GamificationUI.Header>
      </LogoWrapper>

      {animationComplete ? (
        <>
          <SCWinWrapper>
            <SCCTAWinText $bgColor={style.textBackground} $textColor={style.headerTextColor}>
              {error ? error : message}
            </SCCTAWinText>
            <Win imgSrc={popUpBgImgSrc} text={singleBonus?.title || ''} />
          </SCWinWrapper>
          <Flex.ColAC className="mt-auto">
            <GamificationUI.CTAButton
              buttonText={singleBonus?.buttonText}
              disabled={status === 'pending'}
              errorText={error}
              onClick={handleOfferAction}
              isVisible={animationComplete}
              statusCode={statusCode}
              text={subheader}
              bgColor={style.backgroundButton}
              textColor={style.buttonTextColor}
              borderColor={style.buttonBorderColor}
            />

            <GamificationUI.Link
              className="py-2 text-center"
              isActive={showTerms}
              isVisible={animationComplete}
              onClick={fetchBonusTerm}
              textColor={style.termsTextColor}
            />
          </Flex.ColAC>
        </>
      ) : (
        <CashBell
          animationParams={animationParams}
          folder={animationParams.folder}
          notification={notification}
          setAnimationComplete={setAnimationComplete}
        >
          <CTAText
            showWinText={animationComplete}
            text={header}
            winText={message}
            error={error}
            bgColor={style.textBackground}
            textColor={style.headerTextColor}
          />
        </CashBell>
      )}

      <GamificationUI.Terms isVisible={showTerms} onClickOutside={outsideClick} text={offerTerms} />
    </>
  );
};

export default memo(CashBellContainer);
