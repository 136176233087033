import { useLocation, useMatch } from 'react-router-dom';
import useMyBetsRoute from 'hooks/common/useMyBetsRoute';
import { favoriteGamesSelectors } from 'modules/casino/modules/container/cellsContainer/cellls/additionalGames/favourites/slice/casinoFavouriteGamesSlice';
import { useAppSelector } from 'store';
import { isActive } from 'utils/common/utilsNav';
import { selectBottomTabBar } from '../slice/bottomTabBar.slice';
import { isTabBarModalActive } from '../utils/bottamTabBar.utils';

const useActiveBottomTab = (route: string, tabType: string): { isActiveTab: boolean } => {
  const location = useLocation();
  const sportsMatch = useMatch(`${route}/:params/*`);
  const isNavigationTabActive = isActive(route, sportsMatch, location, tabType);
  const { isMyBets } = useMyBetsRoute(location.pathname);
  const isSearchOpen = !!useMatch('/sports/quick-search');
  const isMyBetsVisible = useAppSelector(selectBottomTabBar.isMyBetsVisible) || isMyBets;
  const isFavouriteGamesModalOpen = useAppSelector(favoriteGamesSelectors.isFavouriteGamesModalOpen);
  const isModalOpen = isMyBetsVisible || isFavouriteGamesModalOpen || isSearchOpen;
  const isModalTabActive = isTabBarModalActive(tabType, isMyBetsVisible, isSearchOpen, isFavouriteGamesModalOpen);
  const isActiveTab = isModalOpen ? isModalTabActive : isNavigationTabActive;

  return {
    isActiveTab,
  };
};

export default useActiveBottomTab;
