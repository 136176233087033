import React from 'react';
import { useTranslation } from 'react-i18next';
import { isCashier, isEmpty, isTerminal } from 'utils/common/helpersCommon';
import { TerminalModalStatus } from './terminalModals';
import { useFetchSSBTdata } from '../hooks/useFetchSSBTdata';
import {
  bankValidatorErrors,
  documentScannerError,
  printerErrorMessages,
  rgbControllerErrors,
} from '../utils/ssbtErrorMessageRecords';

const TerminalModalWrapper: React.FC = () => {
  const { t } = useTranslation();
  const { moneyValidatorData, printerStatus, documentScanner, rgbControllerInfo } = useFetchSSBTdata();
  const isTerminalDevice = isTerminal() || isCashier();

  const getFilteredErrorMessage = (errorMessages, filterProperty, filterValue) => {
    return errorMessages(t)
      .filter((element) => element[filterProperty] === filterValue)
      .map((element) => element.errorMessage);
  };
  const showErrorMessage = (...error) => {
    return error.filter((arr) => arr.length > 0);
  };

  const currentPrinterErrorMessage = getFilteredErrorMessage(
    printerErrorMessages,
    'error_code',
    Number(printerStatus?.error_code),
  );

  const currentBankValidatorErrorMessage = getFilteredErrorMessage(
    bankValidatorErrors,
    'alert',
    moneyValidatorData?.alert,
  );
  // const currentCoinValidatorError = getFilteredErrorMessage(coinValidatorErrors, 'code', coinValidatorInfo?.code);
  // const currentCoinValidatorErrorMsg = getFilteredErrorMessage(coinValidatorErrors, 'msg', coinValidatorInfo?.msg);
  const currenrRgbControllerError = getFilteredErrorMessage(rgbControllerErrors, 'code', rgbControllerInfo?.code);
  const currentDocumentScannerError = getFilteredErrorMessage(documentScannerError, 'code', documentScanner?.code);
  const isError =
    // !isEmpty(currentCoinValidatorError) ||
    // !isEmpty(currentCoinValidatorErrorMsg) ||
    !isEmpty(currenrRgbControllerError) ||
    !isEmpty(currentDocumentScannerError) ||
    !isEmpty(currentPrinterErrorMessage) ||
    !isEmpty(currentBankValidatorErrorMessage);

  const showStatus = isTerminalDevice && isError;
  const isClosableError = !isEmpty(currenrRgbControllerError);
  // // !isEmpty(currentCoinValidatorError) ||
  // !isEmpty(currentCoinValidatorErrorMsg) || !isEmpty(currenrRgbControllerError);

  return showStatus ? (
    <>
      {isClosableError && (
        <TerminalModalStatus
          isClosable={true}
          isCoinError={false}
          message={`${showErrorMessage(currenrRgbControllerError)}`}
        />
      )}

      <TerminalModalStatus
        isClosable={false}
        isCoinError={false}
        message={`${showErrorMessage(
          currentBankValidatorErrorMessage,
          currentPrinterErrorMessage,
          currentDocumentScannerError,
        )}`}
      />

      {/* {isClosableError && (
        <TerminalModalStatus
          isClosable={true}
          isCoinError={true}
          message={`${showErrorMessage([currentCoinValidatorError, currentCoinValidatorErrorMsg])}`}
        />
      )} */}
    </>
  ) : null;
};
export default TerminalModalWrapper;
