import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Flex } from 'modules/casino/shared/components/layout';
import { WidgetAlignmentItems, WidgetTemplate } from 'modules/casino/shared/constants';
import { sliceString } from 'modules/casino/shared/utils/common/sliceString';
import LastWinnersWidgetGameItem from './LastWinnersWidgetsGameComponents/LastWinnersWidgetGameItem';
import { CasinoWidgetsTypes } from '../../../types/casinoWidgetsTypes';

interface TextProps {
  topWinner: CasinoWidgetsTypes.LastWinner;
  type: CasinoWidgetsTypes.WidgetTypes;
  layoutConfig: CasinoWidgetsTypes.TopWinnerArea;
  showTitle: boolean;
  cdnPathLayout: string;
  inBanner?: boolean;
  gameLayout?: string;
}

const LastWinnersWidgetsTopWinner: React.FC<TextProps> = ({
  topWinner,
  type,
  layoutConfig,
  showTitle,
  cdnPathLayout,
  inBanner,
  gameLayout,
}) => {
  const { t } = useTranslation();

  const textClass = clsx('cw__top-winner', type && `cw__top-winner--${WidgetTemplate[type]}`);
  const topWinnerStyle = {
    alignItems: WidgetAlignmentItems[layoutConfig.alignmentX],
    justifyContent: WidgetAlignmentItems[layoutConfig.alignmentY],
    gridColumnStart: layoutConfig.column,
  };

  return (
    <Flex.Col className={textClass} style={topWinnerStyle}>
      {showTitle && (
        <div className="cw__top-winner-title">
          {inBanner
            ? t('Casino.CasinoWidget.bannerTopWinnerLabel')
            : sliceString(t('Casino.CasinoWidget.topWinnerLabel'), 19, true)}
        </div>
      )}
      <LastWinnersWidgetGameItem
        lastWinner={topWinner}
        type={type}
        cdnPathLayout={cdnPathLayout}
        gameLayout={gameLayout}
      />
    </Flex.Col>
  );
};
LastWinnersWidgetsTopWinner.displayName = 'LastWinnersWidgetTopWinner';
export default LastWinnersWidgetsTopWinner;
