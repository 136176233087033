import styled, { css } from 'styled-components';

const SCRequestBetNotification = styled.div`
  font-size: 12px;
`;

const SCRequestBetNotificationIconStyle = css`
  width: 18px;
  height: 18px;
  margin-right: 10px;
`;

export { SCRequestBetNotification, SCRequestBetNotificationIconStyle };
