import { useEffect, useState } from 'react';
import { selectVoucherData } from 'modules/retail/shared/slice/voucher.slice';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { useVoucherData } from './useVoucherData';
import { actionButtonVoucherEnums } from '../../cashierModalUI/actionButtonsMock';

export const useVoucherState = (initialTime) => {
  const [inputValue, setInputValue] = useState('');
  const [actionButtonClicked, setActionButtonClicked] = useState({});
  const [isActive, setIsActive] = useState({});
  const [activeButton, setActiveButton] = useState<number[] | null>([]);
  const [clickCount, setClickCount] = useState(0);
  const [buttonId, setButtonId] = useState(null);
  const [seconds, setSeconds] = useState(initialTime);
  const { voucherInfoCashier } = useVoucherData();
  const payoutVoucherStatus = useAppSelector(selectVoucherData.payoutVoucherStatus);
  const cancelVoucherStatus = useAppSelector(selectVoucherData.cancelVoucherStatus);

  useEffect(() => {
    let actionFound = false;
    const actionButtons: number[] = [];

    if (!isEmpty(voucherInfoCashier) && voucherInfoCashier?.possibleActions.includes('PAY_VOUCHER')) {
      setIsActive((prevState) => ({
        ...prevState,
        [actionButtonVoucherEnums.payOutVoucher]: true,
      }));
      actionButtons.push(actionButtonVoucherEnums.payOutVoucher);
      actionFound = true;
    }

    if (!isEmpty(voucherInfoCashier) && voucherInfoCashier?.possibleActions.includes('CANCEL_VOUCHER')) {
      setIsActive((prevState) => ({
        ...prevState,
        [actionButtonVoucherEnums.cancelVoucher]: true,
      }));
      actionButtons.push(actionButtonVoucherEnums.cancelVoucher);
      actionFound = true;
    }
    if (!isEmpty(voucherInfoCashier) && (payoutVoucherStatus === 'failure' || cancelVoucherStatus === 'failure')) {
      setIsActive((prevState) => ({
        ...prevState,
        [actionButtonVoucherEnums.payOutVoucher]: true,
        [actionButtonVoucherEnums.cancelVoucher]: true,
      }));
      actionButtons.push(actionButtonVoucherEnums.payOutVoucher);
      actionButtons.push(actionButtonVoucherEnums.cancelVoucher);
      actionFound = true;
    }
    setActiveButton(actionButtons);
    if (!actionFound) {
      setIsActive({});
      setActiveButton(null);
    }
  }, [setIsActive, setActiveButton, voucherInfoCashier, payoutVoucherStatus, cancelVoucherStatus]);

  return {
    inputValue,
    setInputValue,
    actionButtonClicked,
    setActionButtonClicked,
    isActive,
    setIsActive,
    activeButton,
    setActiveButton,
    clickCount,
    setClickCount,
    buttonId,
    setButtonId,
    seconds,
    setSeconds,
    voucherInfoCashier,
    payoutVoucherStatus,
    cancelVoucherStatus,
  };
};
