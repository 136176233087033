import React, { memo, useEffect } from 'react';
import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import { LayoutConfigInnerLayoutTypes } from 'modules/casino/shared/constants';
import { setCollectionLoadMore } from 'modules/casino/shared/features/casinoUi/casinoUiStateSlice';
import { useCasinoLayoutConfig } from 'modules/casino/shared/hooks/useCasinoLayoutConfig';
import { useAppDispatch } from 'store';
import { cellWidgetTypesMap } from './CellWidgetMap';

interface WidgetProps {
  gameUniqueId: string;
  categoryId: string;
  isLastItem: boolean;
  collectionId: string;
}

const GridWidgetCellLayout: React.FC<WidgetProps> = ({ gameUniqueId, categoryId, isLastItem, collectionId }) => {
  const dispatch = useAppDispatch();
  const { widget, layoutGrid } = useGameProps({ gameUniqueId, categoryId });
  const casinoLayoutConfig = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.CASINO_LAYOUT_CONFIG });
  const cdnPathLayout = casinoLayoutConfig?.defaultCdnPathLayout || 'layout_1';

  useEffect(() => {
    if (isLastItem) {
      dispatch(setCollectionLoadMore({ collectionId, value: true }));
    }
  }, [isLastItem]);

  const CellWidgetTypeComponent = cellWidgetTypesMap[widget.type];

  return CellWidgetTypeComponent ? (
    <CellWidgetTypeComponent widget={widget} layoutGrid={layoutGrid || cdnPathLayout} />
  ) : (
    <></>
  );
};

export default memo(GridWidgetCellLayout);
