import { useRef } from 'react';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch } from 'store';
import { readNotification, setSliderIsDone } from '../../../notificationSlice';

export const usePopUpSliderNotificationModal = () => {
  const dispatch = useAppDispatch();
  const lastIdRef = useRef<string>('');

  const handleCloseModal = () => {
    dispatch(hideRootModal());
    dispatch(setSliderIsDone(true));
    dispatch(readNotification(lastIdRef.current, 'popup'));
  };

  const callbackNotificationId = (id, lastId) => {
    id && dispatch(readNotification(id, 'popup'));
    lastIdRef.current = lastId;
  };

  return {
    handleCloseModal,
    callbackNotificationId,
  };
};
