import React from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';

/** Auth Page */
interface PageProps {
  type: 'pwd' | 'reg' | 'pre-reg';
  backgroundURL?: string;
  children: React.ReactNode;
}

const Page: React.FC<PageProps> = ({ type, children, backgroundURL }) => {
  const pageClass = clsx('auth-page', `auth-page--${type}`);

  return (
    <Flex.ColAC className={pageClass} style={backgroundURL ? { backgroundImage: `url(${backgroundURL})` } : {}}>
      {children}
    </Flex.ColAC>
  );
};

export default Page;
