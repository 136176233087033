import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { useWidgetPromoItemIdsFetch } from './useWidgetPromoItemIdsFetch';
import { casinoWidgetsSelectors, fetchWidgetPromoItems } from '../../../slice/casinoWidgetsSlice';
import { PromoWidgetsTypes } from '../promoWidgetTypes';

type Props = {
  widgetId: string;
  query: string;
};

export const useWidgetPromoItemsFetch = ({
  widgetId,
  query,
}: Props): { widgetPromoItems: PromoWidgetsTypes.PromoItem[] } => {
  const dispatch = useAppDispatch();
  const { widgetPromoItemIds } = useWidgetPromoItemIdsFetch({ widgetId, query });
  const widgetPromoItems = useAppSelector((state) => casinoWidgetsSelectors.widgetPromoItems(state, widgetId));

  useEffect(() => {
    if (widgetId && widgetPromoItemIds.length && !widgetPromoItems.length) {
      dispatch(fetchWidgetPromoItems({ promoIds: widgetPromoItemIds, id: widgetId }));
    }
  }, [dispatch, query, widgetId, widgetPromoItemIds.length, widgetPromoItems.length]);

  return { widgetPromoItems };
};
