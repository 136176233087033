import { FreeMode, Mousewheel, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Flex } from 'components/shared/layout';
import { ModalUI } from 'components/shared/ModalUI';
import { UI } from 'components/shared/UI';
import AnimatedCounter from 'shared/common/features/animatedCounter/AnimatedCounter';
import ParseHTML from 'shared/common/ParseHTML';
import { useAppDispatch } from 'store';
import { SliderNotificationsItemThumb } from './SliderNotificationsItemThumb';
import { useNotificationHandlers } from '../../../hooks/useNotificationHandlers';
import { setActivePriorityPopUp } from '../../../notificationSlice';
import { NotificationTypes } from '../../../notificationTypes';

type Props = {
  item: NotificationTypes.PopUpNotification;
};

export const SliderNotificationsItem: React.FC<Props> = ({ item }) => {
  const dispatch = useAppDispatch();
  const { handleAcceptButtonClick } = useNotificationHandlers(item);
  // const scorllRef = useScrollLandscape();
  const handleClick = () => {
    dispatch(setActivePriorityPopUp(null));
    handleAcceptButtonClick();
  };

  return (
    <div className="ns-carousel__item d-flex-col h-100">
      <Swiper
        direction={'vertical'}
        slidesPerView={'auto'}
        mousewheel={{
          forceToAxis: true,
          releaseOnEdges: true,
        }}
        scrollbar={true}
        freeMode={{
          momentumRatio: 0.2,
          enabled: true,
          momentumBounce: false,
        }}
        modules={[Mousewheel, Scrollbar, FreeMode]}
        className="ns-carousel__item-carousel"
      >
        <SwiperSlide>
          <div className="ns-carousel__item-info">
            <Flex.AC>
              {item?.style?.iconResourceLocation && (
                <UI.SVGCDN className="ns-carousel__item-icon" icon={item?.style?.iconResourceLocation} />
              )}
              <div className="ns-carousel__item-title">{item.header}</div>
            </Flex.AC>

            <ModalUI.Title text={item.subheader} />
            <Flex>
              <SliderNotificationsItemThumb item={item} type="carousel" />
            </Flex>

            {item?.style?.counterTime && (
              <AnimatedCounter
                expire={item?.style?.counterExpire}
                text={item?.style?.counterLabel}
                date={item?.style?.counterTime as number}
              />
            )}

            <div className="modal-terms__text">
              <ParseHTML html={item?.message || ''} options={{ trim: true }} />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      {item.button?.buttonUrl && (
        <Flex.Center className="ns-carousel__item-btns">
          <UI.Button size="lg" onClick={handleClick} text={item.button?.buttonText} />
        </Flex.Center>
      )}
    </div>
  );
};
