import React from 'react';
import clsx from 'clsx';
import Icon from 'components/shared/Icon';
import { EventButtonLocationTypes } from '../types/eventProps.types';

/** EventUI Button */
interface ButtonProps {
  className?: string;
  componentLocation?: EventButtonLocationTypes;
  isActive?: boolean;
  icon: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const Button: React.FC<ButtonProps> = ({ className, componentLocation, isActive, icon, onClick }) => {
  const buttonClass = clsx(
    'd-flex-center',
    'btn-tracker',
    componentLocation === 'eventBody' && 'btn-tracker--event-body',
    componentLocation === 'eSportsSectionHeader' && 'btn-tracker--esports-header',
    isActive && 'btn-tracker--active',
    className,
  );

  return (
    <div className={buttonClass} onClick={onClick}>
      <Icon className="btn-tracker__icon" defaultClassName={false} icon={icon} />
    </div>
  );
};
