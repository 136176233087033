import { useTranslation } from 'react-i18next';
import { useCasinoGamesFetchStatus } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useCasinoGamesFetchStatus';
import { useCategoryGamesIds } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useCategoryGamesIds';
import { useGameCollectionId } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameCollectionId';
import { gamesSelectors } from 'modules/casino/modules/container/cellsContainer/cellls/slice/casinoGamesSlice';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { useAppSelector } from 'store';
import { gameProvidersSelectors } from '../../../../modules/container/shared/features/casinoGameProviders/slice/casinoGameProvidersSlice';
import { CATEGORY_TITLE_KEY } from '../casinoSearchConstants';

type UseSearchGridWrapperProps = {
  categoryLayoutType: CategoryLayoutTypes;
  categoryId: string;
  collectionId: string;
  searchValue?: string;
};

type GridParams = {
  collectionId: string;
  className: string;
  searchParams: string;
  totalElements?: number;
};

type UseSearchGridWrapperReturnTypes = {
  propsByType: {
    gridParams: GridParams;
    title: string;
    showFallback: boolean;
    categoryLayoutType: CategoryLayoutTypes;
  };
};

export const useSearchGridWrapper = ({
  categoryLayoutType,
  categoryId,
  collectionId,
  searchValue,
}: UseSearchGridWrapperProps): UseSearchGridWrapperReturnTypes => {
  const { t } = useTranslation();
  const { gameCollectionId } = useGameCollectionId({ categoryId, categoryLayoutType });
  const nonWidgetGameIds = useAppSelector((state) => gamesSelectors.nonWidgetGameUniqueIds(state, gameCollectionId));
  const allFilteredProviders = useAppSelector(gameProvidersSelectors.casinoAllFilteredProviderIds);

  const { hasError, hasNoGames, hasSuccess } = useCasinoGamesFetchStatus(categoryId, categoryLayoutType);
  const gamesIds = useCategoryGamesIds({ categoryLayoutType, categoryId });

  const defaultSearchGridParams = {
    collectionId,
  };

  const gridPropsByType = {
    [CategoryLayoutTypes.SEARCH_RESULTS_CATEGORY]: {
      gridParams: {
        ...defaultSearchGridParams,
        className: 'search-results',
        searchParams: searchValue,
        filteredProviders: allFilteredProviders,
        totalElements: gamesIds?.length,
      },
      title: `${t(CATEGORY_TITLE_KEY[categoryLayoutType])} (${gamesIds?.length})`,
      showFallback: false,
    },
    [CategoryLayoutTypes.MOST_SEARCHED_CATEGORY]: {
      gridParams: {
        ...defaultSearchGridParams,
        className: 'most-searched',
      },
      title: hasSuccess && `${t(CATEGORY_TITLE_KEY[categoryLayoutType])} (${nonWidgetGameIds?.length})`,
      showFallback: hasNoGames || hasError,
    },
  };

  return {
    propsByType: gridPropsByType[categoryLayoutType],
  };
};
