import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FormInputUI, FormUI } from 'components/shared/forms/components';
import { InputType, InputLocationType } from 'components/shared/forms/formTypes';
import { Flex } from 'components/shared/layout';
import { Modal } from 'components/shared/Modal';
import { ModalUI } from 'components/shared/ModalUI';
import { UI } from 'components/shared/UI';
import { AuthUI } from 'pages/auth/components/AuthUI';
import { ModalPriorityEnum, ModalTypes } from 'shared/common/features/rootModal/types/modal.types';
import { CreateThunk } from 'store/thunkCreators';
import { MyAccountTranslation, MyProfile } from 'utils/common/translationUtils/translationStrings';
import useConfirmationModalData, { ActionType, emptySelector, StringSelector } from '../hooks/useConfirmationModalData';

export type ModalProps = {
  onSubmit: () => void;
  title?: string;
  subtitle?: string;
  hasConfirmationInput: boolean;
  inputCheckFunction: CreateThunk<{ inputValue: string; error?: string }, boolean, { rejectValue: string }>;
  inputProps: {
    inputIcon?: string;
    inputType: InputType;
    name: string;
    placeholder?: string;
    componentLocation?: InputLocationType;
  };
  hasInfo?: boolean;
  infoComponent?: JSX.Element;
  statusSelector?: StringSelector;
  errorSelector?: StringSelector;
  inputErrorSelector?: StringSelector;
  linkLabel?: string;
  link?: string;
  linkType?: 'external';
  inputErrorMessage?: string;
  resetFunc?: ActionType;
  resetInputErrorFunc?: ActionType;
  hideClose?: boolean;
};

export type ConfirmationModalProps = {
  modalType: ModalTypes;
  modalProps: ModalProps;
  modalPriority: ModalPriorityEnum;
  modalId: string;
};

const ConfirmationModal: React.FC<ModalProps> = ({
  onSubmit,
  title,
  subtitle,
  hasConfirmationInput,
  inputCheckFunction,
  inputProps,
  hasInfo,
  statusSelector = emptySelector,
  errorSelector = emptySelector,
  inputErrorSelector = emptySelector,
  linkLabel,
  link = '/help/reasonable-betting',
  linkType,
  inputErrorMessage,
  resetFunc,
  resetInputErrorFunc,
  infoComponent,
  hideClose,
}) => {
  const { t } = useTranslation();

  const {
    handleInputChange,
    closeModal,
    handleOnSubmit,
    isLoading,
    submitError,
    submitStatus,
    inputCheckError,
    inputValue,
  } = useConfirmationModalData({
    statusSelector,
    errorSelector,
    inputErrorSelector,
    resetInputErrorFunc,
    resetFunc,
    hasConfirmationInput,
    inputCheckFunction,
    onSubmit,
    inputErrorMessage,
  });

  return (
    <Modal visible className="no-scrollbars" size="md">
      {!hideClose && <Modal.Close closeText={t(MyProfile.OffersTab.closeBtn)} closeAction={closeModal} />}
      <Modal.Body className="modal-confirm d-flex-col px-2 py-3" removeBottomRadius={submitStatus === 'failure'}>
        {title && <ModalUI.Title className="mb-4 text-center" text={t(title)} />}
        {subtitle && <div className="mb-4 modal-terms__text">{t(subtitle)}</div>}
        {hasConfirmationInput ? (
          <>
            <FormInputUI
              {...inputProps}
              type={inputProps.inputType}
              isMobileAgnostic={false}
              componentLocation="login"
              classNames="mb-4"
              onChange={handleInputChange}
              value={inputValue}
              hasSuccessIcon={!!inputValue.length}
            />
            {inputCheckError && (
              <AuthUI.Notification variant="error" icon="exclamation-solid" dismissable={false}>
                {t(inputCheckError)}
              </AuthUI.Notification>
            )}
          </>
        ) : (
          hasInfo && infoComponent
        )}
        <Flex.ColAC>
          <UI.Button
            class="px-5 mb-1"
            size="lg"
            isDisabled={(hasConfirmationInput && !inputValue.length) || isLoading}
            onClick={handleOnSubmit}
            text={t(MyAccountTranslation.Exclusions.confirmBtn)}
            qid="pass-confirm-button"
          />

          {linkLabel &&
            link &&
            (linkType !== 'external' ? (
              <Link className="mt-2 form-login__link" to={link} onClick={closeModal}>
                {t(linkLabel)}
              </Link>
            ) : (
              <a className="mt-2 form-login__link" target="_blank" href={link} rel="noreferrer">
                {t(linkLabel)}
              </a>
            ))}
        </Flex.ColAC>
      </Modal.Body>
      {(submitStatus === 'failure' || submitError) && (
        <FormUI.Message hasFormGroup type="error" content={submitError || ''} />
      )}
    </Modal>
  );
};

export default ConfirmationModal;
