import { useEffect } from 'react';
import { DEFAULT_GAME_ID_LOBBY } from 'modules/casino/shared/features/gameLaunch/casinoLaunchConstants';
import { casinoLaunchSelectors } from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import { gamesBonusSelectors } from 'modules/casino/shared/features/gameLaunchBonus/slice/gameLaunchBonus.slice';
import { PartialRecord } from 'modules/casino/shared/types/common.types';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { selectRootModal, showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum, ModalTypes } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch, useAppSelector } from 'store';
import useActiveNotification from './useActiveNotification';
import useCasinoWidgetConfig from '../../../../../modules/casino/platformImplementation/hooks/useCasinoWidgetConfig';
import { GamePlayType } from '../../../../../modules/casino/shared/features/gameLaunch/casinoLaunchTypes';
import { selectIntegrations } from '../../integrations/slice/integrations.slice';
import {
  JackpotPopupType,
  WsNotificationsTypes,
} from '../../myProfile/sections/tabs/notificationsTab/types/wsNotifications.types';

const modalMapType: PartialRecord<WsNotificationsTypes.NotificationType, ModalTypes> = {
  InGamePopup: 'PRIZE_DROP_WIN_MODAL',
  InGameAnimationPopup: 'PRIZE_DROP_WIN_VIDEO_MODAL',
  BonusCollectionPopup: 'BONUS_COLLECTION_WIN_MODAL',
  JackpotPopup: 'JACKPOT_WIN_MODAL',
  GamePopup: 'CAMPAIGN_GAMES_POPUP',
};

const useShowIngameNotifications = () => {
  const currModalType = useAppSelector(selectRootModal.type);
  const launchInProcess = useAppSelector(casinoLaunchSelectors.launchInProcess);
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const casinoWidgetConfig = useCasinoWidgetConfig();
  const launchData = useAppSelector(casinoLaunchSelectors.launchData);
  const widgetGameData = useAppSelector(selectIntegrations.casinoWidgetGameData);

  const isNotFreePlay = casinoWidgetConfig
    ? widgetGameData && widgetGameData.gamePlayType === GamePlayType.PAID
    : launchData && !launchData.isFreePlay;
  const gameId = casinoWidgetConfig
    ? widgetGameData?.id || DEFAULT_GAME_ID_LOBBY
    : launchData?.gameId || DEFAULT_GAME_ID_LOBBY;
  const isGameWithJackpot = useAppSelector((state) => gamesBonusSelectors.isGameWithJackpot(state, gameId));
  const dispatch = useAppDispatch();
  const activeNotification = useActiveNotification();
  const isJackpotNotification = activeNotification?.Style.type === JackpotPopupType;

  useEffect(() => {
    const shouldShowJackpot = launchInProcess && isNotFreePlay && isGameWithJackpot;
    const shouldShow =
      isAuthenticated &&
      activeNotification &&
      currModalType === null &&
      (isJackpotNotification ? shouldShowJackpot : true);

    if (shouldShow) {
      const modal = modalMapType[activeNotification.Style.type];

      modal &&
        dispatch(
          showRootModal({
            modalType: modal,
            modalProps: { activeNotification },
            modalPriority: ModalPriorityEnum.MEDIUM,
            modalId: activeNotification.Id,
          }),
        );
    }
  }, [
    isAuthenticated,
    currModalType,
    activeNotification,
    launchInProcess,
    isNotFreePlay,
    isGameWithJackpot,
    isJackpotNotification,
    dispatch,
  ]);
};

export default useShowIngameNotifications;
