import React, { useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { Breakpoints } from 'theme/Theme';
import { useClosePopOver } from '../hooks/useClosePopOver';
import { usePosition } from '../hooks/usePosition';

type Props = {
  show: boolean;
  children: React.ReactNode;
  position?: 'left' | 'right';
  left?: number;
  top?: number;
  padding?: number;
  handleClickPopOverOutside: () => void;
  wrapperRadius?: boolean;
  size?: 'small' | 'medium';
  showTriangle?: boolean;
  elementWidth?: number;
};

const Wrapper: React.FC<Props> = ({
  show = false,
  children,
  position = 'left',
  left = 0,
  top = 0,
  handleClickPopOverOutside,
  padding = 0,
  // wrapperRadius = false,
  size = 'small',
  showTriangle = false,
  elementWidth = 0,
}) => {
  const popOverWrapperRef = useRef<HTMLDivElement>(null);
  const popOverRef = useRef<HTMLDivElement>(null);
  const popOverInnerRef = useRef<HTMLDivElement>(null);
  const { triangleClass, trianglePos, popoverPos } = usePosition({
    popOverRef,
    popOverInnerRef,
    position,
    left,
    top,
    showTriangle,
    elementWidth,
    padding,
  });
  const { preventClose } = useClosePopOver(popOverRef, handleClickPopOverOutside, show);

  return (
    <>
      <AnimatePresence initial={false}>
        {show && (
          <WrapperSC
            onClick={handleClickPopOverOutside}
            onMouseLeave={handleClickPopOverOutside}
            key="content"
            ref={popOverWrapperRef}
            initial="collapsed"
            animate="open"
            exit=""
            size={size}
            transition={{ duration: 0.2 }}
            left={popoverPos.left}
            top={popoverPos.top}
            padding={popoverPos.padding}
            variants={{
              open: { opacity: 1, y: 0 },
              collapsed: { opacity: 0, y: 20 },
            }}
          >
            <StyleTriangle
              ref={popOverInnerRef}
              className={triangleClass}
              $elementWidth={elementWidth}
              $isLeft={trianglePos.isLeft}
              $isTop={trianglePos.isTop}
              top={popoverPos.top}
            >
              <StyleInner top={popoverPos.top} ref={popOverRef} className={`popover`} onClick={preventClose}>
                {children}
              </StyleInner>
            </StyleTriangle>
          </WrapperSC>
        )}
      </AnimatePresence>
    </>
  );
};

const StyleInner = styled(motion.div)<{ top: number }>`
  border-radius: 3px;
  padding: 3px;
  position: relative;
  opacity: ${({ top }) => (top != 0 ? 1 : 0)};
  @media screen and (max-width: ${Breakpoints.isDesktop}px) {
    margin-left: 0;
  }
`;
const StyleTriangle = styled.div<{ $elementWidth?: number; $isLeft: boolean; $isTop: boolean; top: number }>`
  position: relative;
  opacity: ${({ top }) => (top != 0 ? 1 : 0)};

  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    ${({ $isTop }) => ($isTop ? 'top' : 'bottom')}: -14px;
    ${({ $isLeft }) => (!$isLeft ? 'left' : 'right')}: ${({ $elementWidth }) =>
      $elementWidth && $elementWidth / 2 - 10}px;
  }
`;
const WrapperSC = styled(motion.div)<{
  size?: 'small' | 'medium';
  left: number;
  top: number;
  padding: number;
}>`
  position: absolute;
  top: 100%;
  z-index: 9999;
  min-width: 192px;
  max-width: ${({ size }) => (size === 'small' ? '192px' : '250px')};
  left: ${({ left }) => left + 'px'};
  top: ${({ top }) => top + 'px'};
  padding: ${({ padding }) => `${padding}px 0px`};

  @media screen and (min-width: ${Breakpoints.isMobile}px) {
    max-width: ${({ size }) => (size === 'small' ? '192px' : '400px')};
  }
`;

export default Wrapper;
