import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18n';
import {
  Category,
  CategoryStatus,
  OffersTabLayout,
} from 'shared/common/features/myProfile/sections/tabs/offersTab/types/offersTab.types';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import BonusCategory from './components/BonusCategory';
import NoOffers from './components/NoOffers';
import { OffersTabLayout01, OffersTabLayout02 } from './components/offersTabLayouts';
import useFetchOffers from './hooks/useFetchOffers';
import CTAUI from '../../../components/CTAUI';
import MyAccountBackButton from '../notificationsTab/MyAccountBackButton';

type MapProps = {
  t: TFunction;
  hideCategoryBonuses: (e: React.MouseEvent<Element, MouseEvent>) => void;
  categories: Category[];
  activeCategory?: Category;
  layout: OffersTabLayout;
};

type ComponentStatusKey = `OffersTab${CategoryStatus}`;

type LayoutKeyMapProps = {
  categories: Category[];
  activeCategory?: Category;
  layout: OffersTabLayout;
  hideCategoryBonuses?: (e: React.MouseEvent<Element, MouseEvent>) => void;
};

const OffersTabSuccessMap: Record<OffersTabLayout, React.FC<LayoutKeyMapProps>> = {
  layout_1(props) {
    return <OffersTabLayout01 {...props} />;
  },
  layout_2(props) {
    return <OffersTabLayout02 {...props} />;
  },
};

const ComponentStatusMap: Record<ComponentStatusKey, React.FC<MapProps>> = {
  OffersTabFailed({ t }) {
    return <NoOffers text={t(MyProfile.OffersTab.errorOffersMessage)} t={t} />;
  },

  OffersTabEmpty({ t }) {
    return <NoOffers text={t(MyProfile.OffersTab.noOffersTitle)} t={t} />;
  },

  OffersTabSuccess({ hideCategoryBonuses, activeCategory, categories, layout }) {
    const ComponentLayout = OffersTabSuccessMap[layout];
    return (
      <CTAUI.Column hasGap>
        <ComponentLayout
          categories={categories}
          activeCategory={activeCategory}
          layout={layout}
          hideCategoryBonuses={hideCategoryBonuses}
        />
        {activeCategory && (
          <>
            {activeCategory?.promotions && (
              <BonusCategory
                hideCategoryBonuses={hideCategoryBonuses}
                activeCategory={activeCategory}
                promotions={activeCategory?.promotions}
              />
            )}
          </>
        )}
      </CTAUI.Column>
    );
  },
};

type OffersProps = {
  onBackClick?: (show: boolean) => void;
};

const OffersTab: React.FC<OffersProps> = ({ onBackClick }) => {
  const { t } = useTranslation();
  const { layout, categories, activeCategory, status, hideCategoryBonuses } = useFetchOffers();

  const componentKey = `OffersTab${status}`;
  const Component = ComponentStatusMap[componentKey];
  const hasHeader = layout === 'layout_2' && onBackClick;

  return (
    <CTAUI.Wrapper className={hasHeader ? 'd-flex-col' : ''} showContent={!!activeCategory}>
      {hasHeader && (
        <MyAccountBackButton
          icon="link-offers"
          title={MyProfile.ProfileTab.myOffersTab}
          showBackButton
          onBackClick={onBackClick}
        />
      )}
      {Component && (
        <Component
          categories={categories}
          activeCategory={activeCategory}
          hideCategoryBonuses={hideCategoryBonuses}
          t={t}
          layout={layout}
        />
      )}
    </CTAUI.Wrapper>
  );
};

export default memo(OffersTab);
