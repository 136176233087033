import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { selectAppHeader, setTopNavHeaderStatus } from '../components/appHeaderContainer/slice/appHeader.slice';

export const useShowTopNavTouchMove = ({ ref, disable }: { ref; disable?: boolean }) => {
  const [touchStart, setTouchStart] = useState({ x: 0, y: 0 });

  const dispatch = useAppDispatch();
  const topNavHeaderStatus = useAppSelector(selectAppHeader.topNavHeaderStatus);

  useEffect(() => {
    const onTouchStart = (e) => {
      const touch = e.touches[0];
      setTouchStart({ x: touch.clientX, y: touch.clientY });
    };
    const onTouchMove = (e) => {
      const touch = e.changedTouches[0];
      e.returnValue = false;
      e.preventDefault();

      if (touch.clientY < touchStart.y && topNavHeaderStatus === 'visible') {
        dispatch(setTopNavHeaderStatus('collapsed'));
      } else if (touch.clientY > touchStart.y && topNavHeaderStatus === 'collapsed') {
        dispatch(setTopNavHeaderStatus('visible'));
      }
    };
    if (!disable) {
      ref?.current?.addEventListener('touchstart', onTouchStart, {
        passive: false,
      });
      ref?.current?.addEventListener('touchmove', onTouchMove, {
        passive: false,
      });
    }
    return () => {
      ref?.current?.removeEventListener('touchstart', onTouchStart, {
        passive: false,
      });
      ref?.current?.removeEventListener('touchmove', onTouchMove, {
        passive: false,
      });
    };
  }, [ref, dispatch, topNavHeaderStatus, touchStart, disable]);
};
