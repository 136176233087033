import React, { memo, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Modal } from 'components/shared/Modal';
import { useOutsideClick } from 'hooks/common/useOutsideClick';
import ParseHTML from 'shared/common/ParseHTML';
import { Breakpoints } from 'theme/Theme';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
type Props = {
  isVisible: boolean;
  onClickOutside: () => void;
  text: string | undefined | 0;
  background?: string;
};

const StyledTerms = styled(motion.div).attrs({
  className: 'wh-100',
})`
  z-index: 20;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  margin: 0% 5%;
  padding-bottom: 60px;
  width: 90%;
  max-width: 480px;
  max-height: 93%;
  margin: auto;
  justify-content: flex-end;
  left: 50%;
  @media screen and (min-width: 926px) {
    position: absolute;
    width: 100%;
    bottom: 0%;
    max-height: 100%;
  }
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    max-width: 760px;
  }

  ol {
    padding-left: 20px;
    ul {
      padding-left: 12px;
    }
  }
`;
StyledTerms.displayName = 'StyledTerms';

const StyledTermsContent = styled.div.attrs<{ $bg?: string }>({
  className: 'custom-scrollbars popup-terms',
})<{ $bg?: string }>`
  padding: 10px;
  align-self: flex-end;
  height: 100%;
  max-height: 82%;
  border-radius: 5px;
  overflow: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  width: 100%;
  ${({ $bg }) =>
    $bg
      ? `background:url(${$bg}); background-size: contain;
  background-repeat: no-repeat;`
      : ''}
`;
StyledTermsContent.displayName = 'StyledTermsContent';

const Terms: React.FC<Props> = ({ isVisible, onClickOutside, text, background }) => {
  const termsRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useOutsideClick(termsRef, onClickOutside);
  return (
    <AnimatePresence>
      {isVisible && (
        <StyledTerms
          initial={{ y: '100%', opacity: 0, x: '-50%' }}
          animate={{ y: '0%', opacity: 1, x: '-50%' }}
          exit={{ y: '200%', opacity: 0, x: '-50%' }}
          transition={{
            type: 'spring',
            stiffness: 200,
            damping: 30,
          }}
        >
          <Modal.Close closeText={t(MyProfile.OffersTab.closeBtn)} closeAction={onClickOutside} />
          <StyledTermsContent $bg={background} ref={termsRef}>
            <ParseHTML html={text || ''} options={{ trim: true }} />
          </StyledTermsContent>
        </StyledTerms>
      )}
    </AnimatePresence>
  );
};

export default memo(Terms);
