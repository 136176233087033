import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';

import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { BackgroundContainerTypes } from '../types/backgroundContainer.types';

const initialState: BackgroundContainerTypes.InitialState = {
  item: null,
  isLoaded: false,
};

export const backgroundContainerThunks: BackgroundContainerTypes.Thunks = {
  getBackgroundContainer: createAbortThunk(
    'store/getBackgroundContainer',
    async ({ url }, { rejectWithValue, signal, source, getState }) => {
      const id = getState().myProfile.user?.id;
      try {
        signal.addEventListener('abort', () => source.cancel());

        const urlPath = `${config.API_URL}${url}${id ? `/${id}` : ''}`;

        const response = await axiosInstance.get(urlPath);

        return response.data;
      } catch (err) {
        if (Axios.isCancel(err)) {
          return rejectWithValue(null);
        }
        return rejectWithValue(getError.default());
      }
    },
  ),
};

const backgroundContainer = createSlice({
  name: 'backgroundContainer',
  initialState,
  reducers: {
    resetBackgroundState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        backgroundContainerThunks.getBackgroundContainer.fulfilled,
        (state, action: PayloadAction<BackgroundContainerTypes.ItemType>) => {
          state.item = action.payload;
          state.isLoaded = true;
        },
      )
      .addCase(backgroundContainerThunks.getBackgroundContainer.rejected, (state) => {
        state.isLoaded = true;
      });
  },
});
export const { resetBackgroundState } = backgroundContainer.actions;
export const { reducer: backgroundContainerReducer } = backgroundContainer;
export const selectBackgroundContainer = {
  item: (state: RootState) => state.backgroundContainer.item,
  isLoaded: (state: RootState): boolean => state.backgroundContainer.isLoaded,
};
