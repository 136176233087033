import React from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { ButtonBack } from 'components/shared/buttons';
import { PromotionTypes } from 'pages/promotions/types/promotions.types';
import { Breakpoints } from 'theme/Theme';
import CTAButton from './CTAButton';

interface CTAProps {
  layout: PromotionTypes.GridLayoutType;
  text?: string;
  textColor?: string;
  description?: string;
  descriptionColor?: string;
  ctaTextColor?: string;
  ctaTextBackground?: string;
  ctaText?: string;
  onCtaClick?: React.MouseEventHandler;
  onBackClick: React.MouseEventHandler;
}

const CTA: React.FC<CTAProps> = ({
  layout,
  text,
  textColor,
  description,
  descriptionColor,
  ctaTextColor,
  ctaTextBackground,
  ctaText,
  onCtaClick,
  onBackClick,
}) => {
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });

  let wrapperClass = '';
  let blockClass = '';

  switch (layout) {
    case 'layout_1':
      wrapperClass = clsx('d-grid grid-1x1 py-2 layout-01-cta__wrapper');
      blockClass = clsx(
        'grid-start-1x1 prm-cta__details',
        isDesktop ? 'my-auto mr-50pct py-3' : 'mt-auto p-3 text-center',
      );
      break;
    case 'layout_2':
      wrapperClass = clsx('d-grid grid-1x1 p-2 prm-cta__wrapper');
      blockClass = clsx('grid-start-1x1 p-3 prm-cta__details', isDesktop ? 'my-auto ml-50pct' : 'mt-auto text-center');
      break;

    default:
      break;
  }

  const ctaStyle = { color: ctaTextColor || '', backgroundColor: ctaTextBackground || '' };

  return (
    <div className={wrapperClass}>
      <ButtonBack
        className="grid-start-1x1 place-self-start prm-cta__back"
        style={{ fill: descriptionColor }}
        onClickHandler={onBackClick}
      />

      <motion.div className={blockClass} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
        {text && (
          <div className={`${isDesktop ? 'mb-4' : ''} prm-cta__box prm-cta__text`} style={{ color: textColor }}>
            {text}
          </div>
        )}
        {description && (
          <div
            className={`${isDesktop ? 'mb-4' : ''} prm-cta__box prm-cta__desc text-clamp-2-line`}
            style={{ color: descriptionColor }}
          >
            {description}
          </div>
        )}
        {ctaText && onCtaClick && (
          <CTAButton style={ctaStyle} size={isDesktop ? 'xl' : 'lg'} text={ctaText} onClick={onCtaClick} />
        )}
      </motion.div>
    </div>
  );
};

export default CTA;
