import React from 'react';
import { useTranslation } from 'react-i18next';
import { NoDataMessage } from 'components/shared';
import { Flex } from 'modules/casino/shared/components/layout';
import { Text } from 'modules/casino/shared/components/text';
import { cdnConfig, fitCover } from 'modules/casino/shared/utils/common/cdnConfig';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import config from 'utils/config';
import JackpotCampaignLevel from './JackpotCampaignLevel';
import { selectIntegrations } from '../../../../../../../shared/common/features/integrations/slice/integrations.slice';
import useCasinoWidgetConfig from '../../../../../platformImplementation/hooks/useCasinoWidgetConfig';
import { DEFAULT_GAME_ID_LOBBY, GameLaunchTranslationKeys } from '../../../gameLaunch/casinoLaunchConstants';
import { casinoLaunchSelectors } from '../../../gameLaunch/casinoLaunchSlice';
import { useJackpotCampaignDetails } from '../../hooks/useJackpotCampaignDetails';
import { gamesBonusSelectors } from '../../slice/gameLaunchBonus.slice';
import { JackpotTranslations, jackpotPopOverPrefix } from '../../utils/gameLaunchBonusConstants';
import { TopBarTypes } from '../topBar.types';
import { TopBarPopover } from '../TopBarPopover';

const JackpotPopover: React.FC<TopBarTypes.JackpotPopover> = ({ handleClose, parentRef, show }) => {
  const { t } = useTranslation();
  const { bannerResource, jackpotName, jackpotId, jackpotLevels, promotionAlias, categoryAlias } =
    useJackpotCampaignDetails();
  const casinoWidgetConfig = useCasinoWidgetConfig();
  const launchData = useAppSelector(casinoLaunchSelectors.launchData);
  const widgetGameData = useAppSelector(selectIntegrations.casinoWidgetGameData);
  const gameId = casinoWidgetConfig
    ? widgetGameData?.id || DEFAULT_GAME_ID_LOBBY
    : launchData?.gameId || DEFAULT_GAME_ID_LOBBY;
  const isGameWithJackpot = useAppSelector((state) => gamesBonusSelectors.isGameWithJackpot(state, gameId));

  const imageUrl = `${config.CDN_IMAGE_API}${fitCover(303 * 2, 70 * 2)}${
    cdnConfig.PROMOTIONS_MODULE
  }/${bannerResource}`;

  const openPromotion = () => {
    promotionAlias && categoryAlias && window.open(`/promotions/${categoryAlias}/${promotionAlias}`, '_blank');
  };

  const renderAllLevels = () => {
    return jackpotLevels?.map((jackpotLevel) => {
      return (
        <JackpotCampaignLevel
          jackpotLevel={jackpotLevel}
          jackpotId={jackpotId}
          key={jackpotLevel.jackpotLevelDescription}
        />
      );
    });
  };

  return (
    <TopBarPopover handleClose={handleClose} parentRef={parentRef} show={show} additionalClass="jackpot-popover">
      {!isGameWithJackpot || isEmpty(jackpotLevels) ? (
        <NoDataMessage message={t(GameLaunchTranslationKeys.noJackpotPopoverText)} />
      ) : (
        <div className={`overflow-hidden ${jackpotPopOverPrefix}`}>
          <img src={imageUrl} alt="" className={`${jackpotPopOverPrefix}-image`} />
          <div className={`${jackpotPopOverPrefix}__wrapper`}>
            <Text className={`${jackpotPopOverPrefix}--title d-flex-center`} text={jackpotName} />
            {renderAllLevels()}
          </div>
          <Flex.Center className={`${jackpotPopOverPrefix}__footer`}>
            <div className={`${jackpotPopOverPrefix}__footer-link`}>
              {t(JackpotTranslations.infoText)}{' '}
              <u className="cursor-pointer" onClick={openPromotion}>
                {t(JackpotTranslations.infoLinkText)}
              </u>
            </div>
          </Flex.Center>
        </div>
      )}
    </TopBarPopover>
  );
};
export default JackpotPopover;
