import React from 'react';
import { Text } from 'components/shared';
import { ExpandableIcon } from 'components/shared/icons';
import { Flex } from 'components/shared/layout/Flex';
import NavIcon from './NavIcon';

interface NavItemRowProps {
  onClick: () => void;
  text: string;
  icon?: string;
  iconColor?: string;
  dataQid?: string;
  dataCpp?: string;
  showArrow?: boolean;
}

const NavItemInRow: React.FC<NavItemRowProps> = ({ onClick, text, icon, iconColor, dataQid, dataCpp, showArrow }) => {
  const dataCPPProp = iconColor ? { style: { fill: iconColor } } : { 'data-cpp': dataCpp };

  return (
    <Flex.AC className="p-2 mpd-nav m-0" onClick={onClick} data-qid={dataQid} {...dataCPPProp}>
      <NavIcon size="sm" icon={icon} />
      <Text className="pl-2" text={text} />
      {showArrow && (
        <div className="mpd-cta-header__back-link ml-auto">
          <ExpandableIcon isLink />
        </div>
      )}
    </Flex.AC>
  );
};

export default NavItemInRow;
