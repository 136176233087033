import React from 'react';
import styled from 'styled-components';
type Props = {
  text?: string;
  handleResendChannel: () => void;
  style?: { pointerEvents: 'auto' | 'none' | 'initial' | 'inherit'; opacity: number | string } | object;
};
export const ResendCode: React.FC<Props> = ({ handleResendChannel, text, style = {} }) => {
  return (
    <StyledWrapper style={style} className="modal-tfa__resend" onClick={handleResendChannel}>
      {text}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  margin-top: 20px;
  cursor: pointer;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  width: 100%;
`;
