import { MutableRefObject, useLayoutEffect, useState } from 'react';
import { usePreloadImage } from 'hooks/common/usePreloadImage';
import { fitCover } from 'utils/common/cdnConfig';
import config from 'utils/config';

type ImageType = {
  image?: string;
  ref: MutableRefObject<HTMLDivElement | null>;
  fit?: 'none' | 'contain' | 'cover';
  preload?: boolean;
  timeout?: number;
  update?: boolean;
  isDesktop?: boolean;
};

export const useGenerateAndPreloadImage = ({
  image,
  ref,
  fit,
  preload = true,
  timeout = 100,
  update,
  isDesktop,
}: ImageType): { isImageLoaded: boolean; url: string } => {
  const [url, setUrl] = useState('');
  useLayoutEffect(() => {
    if (ref.current && image) {
      const el = ref.current;
      const multiplier = isDesktop ? 1 : 2;
      setUrl(
        `${config.CDN_IMAGE_API}${fitCover(el.clientWidth * multiplier, el.clientHeight * multiplier, fit)}${image}`,
      );
    }
  }, [ref, fit, image, update]);

  const { isImageLoaded, imageLoadError } = usePreloadImage(preload && image ? url : '', undefined, timeout);

  return { isImageLoaded: isImageLoaded || (imageLoadError as boolean), url };
};
