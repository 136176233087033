/* eslint-disable @typescript-eslint/no-explicit-any */
export enum ModalPriorityEnum {
  'GRAND' = 4,
  'HIGH' = 3,
  'MEDIUM' = 2,
  'LOW' = 1,
}

export type ModalTypes =
  | 'TIMEOUT'
  | 'CONNECTION_LOST'
  | 'ACCEPT_OFFER'
  | 'CANCEL_OFFER'
  | 'TERMS_OFFER'
  | 'SESSION_INACTIVITY'
  | 'POP_UP_NOTIFICATION'
  | 'POP_UP_BG_NOTIFICATION'
  | 'REFRESH_TOKEN'
  | 'PLAYER_RESTRICTIONS_INFO'
  | 'PLAYER_WIN_INFO'
  | 'REALITY_CHECK'
  | 'REALITY_CHECK_SESSION'
  | 'CONFIRMATION_MODAL'
  | 'TERMS_AND_CONDITIONS'
  | 'POP_UP_GAMIFICATION'
  | 'TERMINAL_CHECK_TICKET_DIRECT'
  | 'TERMINAL_DEPOSIT_CASH'
  | 'TERMINAL_CHECK_TICKET'
  | 'TERMINAL_CREATE_VOUCHER'
  | 'PROMO_AWARDS_MODAL'
  | 'PROMO_PD_REWARDS_MODAL'
  | 'PROMO_PD_REWARDED_PLAYERS_MODAL'
  | 'PAYOUT_MODAL'
  | 'INSUFFICIENT_BALANCE_MODAL'
  | 'NEW_VERSION_MODAL'
  | 'REQUEST_BET_MODAL'
  | 'TERMINAL_INVALID_CODE'
  | 'CASHIER_MODAL'
  | 'PRIZE_DROP_CAMPAIGNS_MODAL'
  | 'PRIZE_DROP_WIN_MODAL'
  | 'JACKPOT_WIN_MODAL'
  | 'BONUS_COLLECTION_WIN_MODAL'
  | 'TWO_FACTOR_VERIFICATION'
  | 'MULTI_FACTOR_VERIFICATION'
  | 'PHONE_CONFIRMATION'
  | 'MFA_FORGOTTEN_PASSWORD'
  | 'POP_UP_SLIDER_NOTIFICATION'
  | 'CASHIER_ERROR_MODAL'
  | 'DEACTIVATED_TERMINAL_MODAL'
  | 'SOCIAL_MEDIA_LINK_MODAL'
  | 'KYC_VERIFICATION_MODAL'
  | 'RETAIL_FINANCE_MODAL'
  | 'CASH_PAY_MODAL'
  | 'SVARA_LAUNCH_MODAL'
  | 'LIMIT_INCREASE_MODAL'
  | 'ANEX_MODAL'
  | 'CREATE_RETAIL_VOUCHER'
  | 'PAPER_COUPON_ERROR_MODAL'
  | 'RETAIL_CERTIFICATE'
  | 'RETAIL_UCN_MODAL'
  | 'REPRINT_MODAL'
  | 'UCN_CHECK_CASHIER_MODAL'
  | 'RETAIL_UCN_MODAL'
  | 'OMNIPLAY_PAYMENT_MODAL'
  | 'PAID_GAME_MODAL'
  | 'GEOLOCATION_MODAL'
  | 'CLAIM_TICKET_MODAL'
  | 'PRIZE_DROP_WIN_VIDEO_MODAL'
  | 'CAMPAIGN_GAMES_POPUP';

export type RootModalType = {
  modalType: ModalTypes;
  modalProps?: any | null;
  modalPriority: ModalPriorityEnum;
  modalId: string;
};

export type RootModalState = {
  modalType: ModalTypes | null;
  modalProps: any | null;
  modalPriority: ModalPriorityEnum | null;
  modalId: string | null;
  modalsQueue: Record<string, RootModalType>;
};
