import { useEffect } from 'react';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { useBonusGamesConfig } from './useBonusGamesConfig';
import { useInitialBonusTagFetch } from '../../../features/gameLaunchBonus/hooks/useInitialBonusTagFetch';
import { setPauseFetchInterval } from '../../jackpots/jackpotSlice';
import { setBonusTagFetchInterval } from '../slice/gameLaunchBonus.slice';

export const useSetBonusTagPolling = ({
  skipPolling,
  pauseSetPolling,
}: {
  skipPolling?: boolean;
  pauseSetPolling?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const { enableGameTagIcon } = useBonusGamesConfig();
  useInitialBonusTagFetch();

  useEffect(() => {
    if (skipPolling || !enableGameTagIcon) {
      return;
    }

    if (isAuthenticated) {
      dispatch(setBonusTagFetchInterval(true));

      if (!pauseSetPolling) {
        dispatch(setPauseFetchInterval(true));
      }

      return () => {
        dispatch(setBonusTagFetchInterval(false));
        dispatch(setPauseFetchInterval(false));
      };
    }
  }, [dispatch, isAuthenticated, enableGameTagIcon, skipPolling, pauseSetPolling]);
};
