import { isMobileOnly, isTablet } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from 'theme/Theme';
import { isEmpty } from 'utils/common/helpersCommon';

type Props = {
  viewPerDevice?: {
    mobile?: boolean;
    tablet?: boolean;
    desktop?: boolean;
  };
};

const deviceType = {
  mobile: 'mobile',
  tablet: 'tablet',
  desktop: 'desktop',
};

export const useMainNavBarButtonsCheckDevice: React.FC<Props> = ({ viewPerDevice }): boolean => {
  const isMobileQuery = useMediaQuery({ query: `(max-width: ${Breakpoints.isMobile}px)` });
  const isTabletQuery = useMediaQuery({ query: `(max-width: ${Breakpoints.isTabletOrMobile}px)` });
  const isDesktopQuery = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });
  if (isEmpty(viewPerDevice)) return true;

  if ((isMobileQuery || isMobileOnly) && typeof viewPerDevice[deviceType.mobile] == 'boolean') {
    return viewPerDevice[deviceType.mobile];
  } else if ((isTabletQuery || isTablet) && typeof viewPerDevice[deviceType.tablet] == 'boolean') {
    return viewPerDevice[deviceType.tablet];
  } else if (isDesktopQuery && typeof viewPerDevice[deviceType.desktop] == 'boolean') {
    return viewPerDevice[deviceType.desktop];
  }
  return true;
};
