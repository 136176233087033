import { useEffect } from 'react';
import { keepMyProfileModalShown } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppDispatch } from 'store';

export const useShowMyProfileUntilUnmount = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(keepMyProfileModalShown(true));
    return () => {
      dispatch(keepMyProfileModalShown(false));
    };
  }, [dispatch]);
};
