import { useCallback, useEffect } from 'react';
import { setIsGamificationScriptLoaded } from 'shared/common/features/integrations/slice/integrations.slice';
import { useAppDispatch } from 'store';
import { getWindowConfigParam, isProductionBuild } from 'utils/common/helpersCommon';
import { gamificationScriptInit, isScriptAttached } from '../utils/gamification.utils';

const scriptSrc = getWindowConfigParam('GAMIFICATION_URL');
const epoch = !isProductionBuild && scriptSrc?.includes('localhost') ? '' : `?v=${getWindowConfigParam('EPOCH')}`;

const useGamificationWidget = () => {
  const dispatch = useAppDispatch();

  const callback = useCallback(() => {
    dispatch(setIsGamificationScriptLoaded(true));
  }, [dispatch]);

  useEffect(() => {
    const isAttached = isScriptAttached('gamification');
    if (scriptSrc && !isAttached) {
      const src = `${scriptSrc}${epoch}`;
      gamificationScriptInit({
        document,
        src,
        attr: 'gamification',
        id: 'egtd-gm',
        callback,
      });
    }
  }, [callback]);
};

export default useGamificationWidget;
