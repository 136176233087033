import React from 'react';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { Casino } from 'modules/casino/shared/types';
import NestedGridSlider from './NestedGridSlider';
import SingleGridSlider from './SingleGridSlider';
import { GameSectionTypes } from '../../../../cellls/constants/casinoGamesConstants';
import { SliderGridTypes } from '../../../types/gridTypes';
import CategoryLayoutFooterHandler from '../../CategoryLayoutFooterHandler';

type Props = {
  children: (
    itemId: string,
    key: number,
    isLastItem: boolean,
    gridPage: number,
    gamesRenderedCount: number,
    skipLazyLoad: boolean,
  ) => React.ReactNode;
  subType: CategorySubTypes;
  layoutConfig: Casino.LayoutConfig;
  collectionId: string;
  className: string;
  categoryLayout: CategoryLayoutTypes;
  isCategoryLast?: boolean;
  categoryId: string;
};

const SliderGridRender: React.FC<Props> = (props) => {
  const { gameSection } = useGetCategoryParams(props.categoryId, props.categoryLayout);
  const sliderGridType =
    gameSection?.type === GameSectionTypes.EXPANDING && gameSection.isActive
      ? SliderGridTypes.NESTED_GRID_SLIDER
      : SliderGridTypes.SINGLE_GRID_SLIDER;

  const renderSliderGrid = {
    [SliderGridTypes.NESTED_GRID_SLIDER]: () => <NestedGridSlider {...props}></NestedGridSlider>,
    [SliderGridTypes.SINGLE_GRID_SLIDER]: () => <SingleGridSlider {...props}></SingleGridSlider>,
  };

  return (
    <>
      {renderSliderGrid[sliderGridType]()}
      {props.categoryLayout !== CategoryLayoutTypes.EXTERNAL_CATEGORY ? (
        <CategoryLayoutFooterHandler
          categoryLayout={props.categoryLayout}
          isCategoryLast={props.isCategoryLast}
          isInfinityScrollCollection={false}
          stopLoading={false}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default SliderGridRender;
