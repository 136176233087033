import { useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { fitCover } from 'modules/casino/shared/utils/common/cdnConfig';
import config from 'utils/config';
import { ExpandingBannerSize } from '../../../../../types/gridTypes';

const useExpandingSliderBanner = ({
  imageSrc,
  bannerImageSize,
}: {
  imageSrc: string;
  bannerImageSize: ExpandingBannerSize;
}): string | undefined => {
  const getBackground = useCallback(() => {
    const { width, height } = bannerImageSize;
    const quality = isMobile ? 2 : 1;
    return imageSrc
      ? `url(${config.CDN_IMAGE_API}${fitCover(width * quality, height * quality, 'cover')}/${imageSrc})`
      : '';
  }, [bannerImageSize]);

  return getBackground();
};

export default useExpandingSliderBanner;
