import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GAME_LAUNCH_PREFIX_PLATFORM } from 'utils/config';

export const useCloseModalInCasinoGame = (handleModalClose: () => void) => {
  const { pathname } = useLocation();
  const isCasinoGameRoute = pathname.includes(GAME_LAUNCH_PREFIX_PLATFORM);

  useEffect(() => {
    if (isCasinoGameRoute) {
      handleModalClose();
    }
  }, [isCasinoGameRoute, handleModalClose]);
};
