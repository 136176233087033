import { memo } from 'react';
import { Button } from './Button';
import { ButtonSpacer } from './ButtonSpacer';
import { EnhancedMarkets } from './EnhancedMarkets';
import { Jersey } from './Jersey';
import { Link } from './Link';
import { MarketChange } from './MarketChange';
import { MarketsButton } from './MarketsButton';
import { NeutralGround } from './NeutralGround';
import { Note } from './Note';
import { PlayIcon } from './PlayIcon';
import { ScoreChangeIndicator } from './ScoreChangeIndicator';
import { ServeIndicator } from './ServeIndicator';
import { Team } from './Team';
import { TeamSingle } from './TeamSingle';

export const EventUI = {
  Button: memo(Button),
  ButtonSpacer: ButtonSpacer,
  Jersey: Jersey,
  Link: Link,
  MarketChange: memo(MarketChange),
  MarketsButton: memo(MarketsButton),
  NeutralGround: memo(NeutralGround),
  EnhancedMarkets: memo(EnhancedMarkets),
  Note: memo(Note), // for Event.tsx
  PlayIcon: PlayIcon,
  ScoreChangeIndicator: memo(ScoreChangeIndicator),
  ServeIndicator: memo(ServeIndicator),
  Team: memo(Team),
  TeamAlt: memo(TeamSingle),
};
