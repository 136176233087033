import { combineReducers } from '@reduxjs/toolkit';
import loginReducer from 'pages/auth/login/slice/login.slice';
import emailChangedConfirmReducer from './emailChangedConfirm/slice/emailChangedConfirm.slice';
import forgotPasswordReducer from './forgotPassword/slice/forgotPassword.slice';
import phoneConfirmationReducer from './phoneConfirmation/slice/phoneConfirmation.slice';
import registrationReducer from './registration/slice/registration.slice';
import registrationConfirmReducer from './registrationConfirm/slice/confirmation.slice';
import resetPasswordReducer from './resetPassword/slice/resetPassword.slice';

export const authReducer = combineReducers({
  forgotPassword: forgotPasswordReducer,
  login: loginReducer,
  registration: registrationReducer,
  registrationConfirm: registrationConfirmReducer,
  emailChangedConfirm: emailChangedConfirmReducer,
  resetPassword: resetPasswordReducer,
  phoneConfirmation: phoneConfirmationReducer,
});
