import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { launchGameThunk } from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { AppDispatch } from 'store';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { showRootModal } from '../../rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from '../../rootModal/types/modal.types';
import {
  RealityCheckSession,
  RealityCheckSessionModalTypes,
  RealityCheckSessionTitleTypes,
} from '../types/realityCheckSession.types';

export const updateRealityCheckSessionData =
  (payload: RealityCheckSession.WSMessage) =>
  async (dispatch: AppDispatch): Promise<void> => {
    switch (payload.type) {
      case RealityCheckSessionModalTypes.INFO:
        dispatch(wsSetInfoData(payload.data));
        break;
      case RealityCheckSessionModalTypes.WARN:
        dispatch(wsSetWarnData(payload.data));
        break;
      case RealityCheckSessionModalTypes.END:
        dispatch(wsSetEndData(payload.data));
        break;
      default:
        dispatch(wsSetLiveData(payload.data));
        break;
    }
  };

export const realityCheckSessionThunks: RealityCheckSession.Thunks = {
  fetchFormFields: createAbortThunk(
    'realityCheckSession/fetchFormFields',
    async (params, { dispatch, rejectWithValue }) => {
      try {
        const url = `${config.API_URL}/api/ews-crm/public/cms/player-content?key=casino_session_declaration`;
        const response = await axiosInstance.get(url);
        if (response.status === 204) {
          params.resolve && params.resolve(true);
          return null;
        }
        dispatch(
          showRootModal({
            modalType: 'REALITY_CHECK_SESSION',
            modalProps: {
              modalType: RealityCheckSessionModalTypes.CREATE,
              resolve: params.resolve,
              closeGame: params.closeGame,
            },
            modalPriority: ModalPriorityEnum.GRAND,
            modalId: `REALITY_CHECK_SESSION_ID_${RealityCheckSessionModalTypes.CREATE}`,
          }),
        );
        return response.data;
      } catch (err) {
        const error = err as { response: { status: number; data: string } };
        if (error?.response.status === 400) {
          dispatch(
            showRootModal({
              modalType: 'REALITY_CHECK_SESSION',
              modalProps: {
                modalType: RealityCheckSessionModalTypes.TIMEOUT,
                message: error?.response.data,
                closeGame: params.closeGame,
              },
              modalPriority: ModalPriorityEnum.GRAND,
              modalId: `REALITY_CHECK_SESSION_ID_${RealityCheckSessionModalTypes.TIMEOUT}`,
            }),
          );
        } else {
          dispatch(
            showRootModal({
              modalType: 'REALITY_CHECK_SESSION',
              modalProps: {
                modalType: RealityCheckSessionModalTypes.CREATE,
                resolve: params.resolve,
                closeGame: params.closeGame,
              },
              modalPriority: ModalPriorityEnum.GRAND,
              modalId: `REALITY_CHECK_SESSION_ID_${RealityCheckSessionModalTypes.CREATE}`,
            }),
          );
          return rejectWithValue(getError.message(err));
        }
      }
    },
  ),
  sessionCreate: createAbortThunk('realityCheckSession/create', async (params, { rejectWithValue }) => {
    try {
      const url = `${config.API_URL}/api/ews-crm/player/players/generate-casino-session`;
      const response = await axiosInstance.post(url, params);
      params.onSuccess();
      return response.data;
    } catch (err) {
      const data = (err as { response: { data?: string } }).response.data;
      return rejectWithValue(getError.message(data ? { message: data } : err));
    }
  }),
};

export const { fetchFormFields, sessionCreate } = realityCheckSessionThunks;

const initialState: RealityCheckSession.State = {
  form: {
    fields: null,
    error: null,
    modal: true,
  },
  session: {
    data: {
      liveTime: 0,
      endTime: null,
      header: null,
      modals: {
        info: null,
        warn: null,
        end: null,
      },
    },
    error: null,
  },
  isLoading: false,
};

const realityCheckSession = createSlice({
  name: 'realityCheckSession',
  initialState,
  reducers: {
    setLiveTime(state: RealityCheckSession.State) {
      state.session.data.liveTime = Date.now();
    },
    wsSetLiveData(state: RealityCheckSession.State, action: PayloadAction<RealityCheckSession.HeaderData>) {
      state.session.data.header = action.payload;
    },
    wsSetInfoData(state: RealityCheckSession.State, action: PayloadAction<RealityCheckSession.InfoModalData>) {
      state.session.data.liveTime = Date.now();
      state.session.data.modals.info = {
        sessionStartTime: action.payload.sessionStartTime,
        sessionEndTime: action.payload.sessionEndTime,
        totalNet: action.payload.totalNet,
        totalRealBet: action.payload.totalRealBet,
        totalRealWin: action.payload.totalRealWin,
        totalBonusBet: action.payload.totalBonusBet,
        totalBonusWin: action.payload.totalBonusWin,
      };
    },
    wsSetWarnData(state: RealityCheckSession.State, action: PayloadAction<RealityCheckSession.WarnModalData>) {
      state.session.data.liveTime = Date.now();
      state.session.data.modals.warn = {
        sessionStartTime: action.payload.sessionStartTime,
        sessionEndTime: action.payload.sessionEndTime,
        hasHitBalanceLimit: action.payload.hasHitBalanceLimit,
        hasHitTimeLimit: action.payload.hasHitTimeLimit,
        initialBalance: action.payload.initialBalance,
        currentBalance: action.payload.currentBalance,
      };
    },
    wsSetEndData(
      state: RealityCheckSession.State,
      action: PayloadAction<RealityCheckSession.InfoModalData & RealityCheckSession.EndModalData>,
    ) {
      action.payload.hasHitBalanceLimit && (state.session.data.endTime = Date.now());
      state.session.data.liveTime = Date.now();
      state.session.data.modals.info = {
        sessionStartTime: action.payload.sessionStartTime,
        sessionEndTime: action.payload.sessionEndTime,
        totalNet: action.payload.totalNet,
        totalRealBet: action.payload.totalRealBet,
        totalRealWin: action.payload.totalRealWin,
        totalBonusBet: action.payload.totalBonusBet,
        totalBonusWin: action.payload.totalBonusWin,
      };
      state.session.data.modals.end = {
        hasHitBalanceLimit: action.payload.hasHitBalanceLimit,
        hasHitTimeLimit: action.payload.hasHitTimeLimit,
        nextSessionStartTime: action.payload.nextSessionStartTime,
        hitBalanceLimitValue: action.payload.hitBalanceLimitValue,
        hitTimeLimitValue: action.payload.hitTimeLimitValue,
      };
    },
    closeFormPreModal(state: RealityCheckSession.State) {
      state.form.modal = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormFields.pending, (state: RealityCheckSession.State) => {
        state.isLoading = true;
        state.form.error = null;
      })
      .addCase(
        fetchFormFields.fulfilled,
        (state: RealityCheckSession.State, action: PayloadAction<RealityCheckSession.FormFields>) => {
          state.isLoading = false;
          state.form.fields = action.payload;
        },
      )
      .addCase(
        fetchFormFields.rejected,
        (state: RealityCheckSession.State, action: PayloadAction<RealityCheckSession.ErrorData>) => {
          state.isLoading = false;
          state.form.error = action.payload;
        },
      );
    builder
      .addCase(sessionCreate.pending, (state: RealityCheckSession.State) => {
        state.isLoading = true;
        state.session.error = null;
      })
      .addCase(sessionCreate.fulfilled, (state: RealityCheckSession.State) => {
        state.session.data.endTime = null;
        state.isLoading = false;
      })
      .addCase(
        sessionCreate.rejected,
        (state: RealityCheckSession.State, action: PayloadAction<RealityCheckSession.ErrorData>) => {
          state.isLoading = false;
          state.session.error = action.payload;
        },
      );
    builder
      .addCase(launchGameThunk.fetchLaunchData.pending, (state) => {
        state.session.data.header = null;
      })
      .addCase(launchGameThunk.fetchLaunchData.rejected, (state) => {
        state.session.data.header = null;
      })
      .addCase(launchGameThunk.fetchLaunchData.fulfilled, (state, action) => {
        state.session.data.header = action.payload.realityCheckSessionData || null;
      });
    builder.addCase(logoutUser, () => initialState);
  },
});

export const { setLiveTime, wsSetLiveData, wsSetInfoData, wsSetWarnData, wsSetEndData, closeFormPreModal } =
  realityCheckSession.actions;

export default realityCheckSession.reducer;

export const selectRealityCheckSession = {
  formFields: (state: RootState): RealityCheckSession.FormFields | null => state.realityCheckSession.form.fields,
  formError: (state: RootState): RealityCheckSession.ErrorData => state.realityCheckSession.form.error,
  formPreModal: (state: RootState): RealityCheckSession.Titles | null =>
    state.realityCheckSession.form.fields?.find(
      (field) => field.type === RealityCheckSessionTitleTypes.FORM_PRE_MODAL,
    ) as RealityCheckSession.Titles,
  showPreModal: (state: RootState): boolean => state.realityCheckSession.form.modal,
  sessionLiveTime: (state: RootState): number => state.realityCheckSession.session.data.liveTime,
  sessionEndTime: (state: RootState): number | null => state.realityCheckSession.session.data.endTime,
  sessionHeaderData: (state: RootState): RealityCheckSession.HeaderData =>
    state.realityCheckSession.session.data.header,
  sessionInfoData: (state: RootState): RealityCheckSession.InfoModalData | null =>
    state.realityCheckSession.session.data.modals.info,
  sessionWarnData: (state: RootState): RealityCheckSession.WarnModalData | null =>
    state.realityCheckSession.session.data.modals.warn,
  sessionEndData: (state: RootState): RealityCheckSession.EndModalData | null =>
    state.realityCheckSession.session.data.modals.end,
  sessionError: (state: RootState): RealityCheckSession.ErrorData => state.realityCheckSession.session.error,
  isLoading: (state: RootState): boolean => state.realityCheckSession.isLoading,
};
