import React, { useState } from 'react';
import { Text } from 'components/shared';
import { Flex } from 'components/shared/layout';

interface SoFSwitchProps {
  /** Switch text */
  label: string;
  /** onChange Handler */
  onChange: (checked: boolean) => void;
}

const SoFSwitch: React.FC<SoFSwitchProps> = ({ label, onChange }) => {
  // TODO: Remove internal state and toggle handler. Should be received.
  const [isToggled, setIsToggled] = useState(false);
  const change = () => {
    setIsToggled(!isToggled);
    onChange(!isToggled);
  };

  const cssNS = `sof-form-switch`;

  return (
    <Flex.AC className={cssNS}>
      <Flex.AC className={`${cssNS}__wrapper`} onClick={() => change()}>
        <input
          className={`${cssNS}__input`}
          type="checkbox"
          checked={isToggled}
          onChange={() => {
            // console.log(e);
          }}
        />
        <label className={`mb-0 ${cssNS}__label`}></label>
      </Flex.AC>
      <Text className={`${cssNS}__text`} text={label} />
    </Flex.AC>
  );
};

export default SoFSwitch;
