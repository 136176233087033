import styled, { css, keyframes } from 'styled-components';

const glowKeyframes = keyframes`
  0% { filter: drop-shadow(0 0 0px #ffd87edd)}
  25% { filter: drop-shadow(0 0 10px #fdff7edd)}
  50% { filter: drop-shadow(0 0 5px #ffa97edd)}
  75% { filter: drop-shadow(0 0 15px #ffbc7edd)}
  100% { filter: drop-shadow(0 0 0px #ffd87edd)}
`;

const glow = css`
  animation: ${glowKeyframes} 0.9s ease-in-out 1s 2;
  transition: filter;
`;

export const Frame = styled.img<{ $hasAnimation?: boolean }>`
  grid-row: 1 / -1;
  grid-column: 1 / -1;

  margin-left: auto;
  margin-right: auto;

  width: 100%;
  max-width: 334px;

  ${(p) => p.$hasAnimation && glow}
`;
