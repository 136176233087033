import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { useGameCollectionId } from './useGameCollectionId';
import { CategoryLayoutTypes } from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { favoriteGamesSelectors } from '../additionalGames/favourites/slice/casinoFavouriteGamesSlice';
import { GamesSliceTypes } from '../constants/casinoGamesConstants';
import { gamesSelectors } from '../slice/casinoGamesSlice';
import { CasinoGamesTypes } from '../types/casinoGamesTypes';
import { getGamesSliceType } from '../utils/casinoGamesUtils';

export const useCategoryGames = ({
  categoryLayoutType,
  categoryId,
}: {
  categoryLayoutType: CategoryLayoutTypes;
  categoryId: string;
}): CasinoGamesTypes.NormalizedGames => {
  const gameSliceType = getGamesSliceType(categoryLayoutType);
  const { gameCollectionId } = useGameCollectionId({ categoryId, categoryLayoutType });

  const selectorMap = useMemo(() => {
    return {
      [GamesSliceTypes.FAVOURITES]: (state) => favoriteGamesSelectors.favoriteGames(state),
      [GamesSliceTypes.GENERAL]: (state, collectionId) => gamesSelectors.categoryGames(state, collectionId),
    };
  }, []);
  const games = useAppSelector((state) => selectorMap[gameSliceType](state, gameCollectionId));

  return games;
};
