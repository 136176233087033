import { useTranslation } from 'react-i18next';
import CurrencySwapper from 'modules/casino/shared/components/common/CurrencySwapper';
import { Flex } from 'modules/casino/shared/components/layout';
import { useCurrency } from 'modules/casino/shared/hooks/useCurrency';
import { useCurrencyConfig } from 'modules/casino/shared/hooks/useCurrencyConfig';
import { formatSumAmount } from 'modules/casino/shared/utils/helpersCasino';
import { CasinoWidgetsTypes, WidgetTemplates } from '../../../../types/casinoWidgetsTypes';
import { formatDiffByLanguage } from '../../../../utils/casinoWidgetsUtils';
import { LastWinnnersWidgetTranslationKeys } from '../../LastWinnersWidgetsConstants';

interface DescriptionProps {
  lastWinner: CasinoWidgetsTypes.LastWinner;
  type: CasinoWidgetsTypes.WidgetTypes;
}

const GameDescription: React.FC<DescriptionProps> = ({ lastWinner, type }) => {
  const { formatCurrency } = useCurrency();
  const { t } = useTranslation();
  const symbol = formatCurrency(lastWinner?.playerCurrency);
  const title = t(LastWinnnersWidgetTranslationKeys.lastWinTitle) || '';
  const stakeLabel = lastWinner?.stakeAsDecimal ? `${t(LastWinnnersWidgetTranslationKeys.lastWinStake)} ` : '';
  const difference = formatDiffByLanguage(lastWinner?.winTime, t);
  const topWinLabel = `${t('Casino.CasinoWidget.topWin')}`;
  const { formatToComma, currencySymbolFirst, spaceBetween } = useCurrencyConfig();

  return (
    <Flex.ColJB className="cw__game-description">
      <div>
        {(type === WidgetTemplates.SECTION_STAKE || type === WidgetTemplates.SECTION_STAKE_PLUS) && (
          <div className="cw__game-title">{`${
            lastWinner.isTopWin ? topWinLabel.toUpperCase() : title?.toUpperCase()
          }`}</div>
        )}
        <div className="cw__game-profit">
          <CurrencySwapper
            sumComponent={
              <>
                {formatSumAmount({
                  sum: lastWinner?.winAmountAsDecimal?.toFixed(2),
                  formatToComma,
                })}
              </>
            }
            currencyComponent={<span className="cw__game-profit--currency">{symbol}</span>}
            currencySymbolFirst={currencySymbolFirst}
            spaceBetween={spaceBetween}
          />
        </div>
        {(type === WidgetTemplates.SECTION_STAKE || type === WidgetTemplates.SECTION_STAKE_PLUS) && (
          <div className="cw__game-bet">
            {stakeLabel}
            <CurrencySwapper
              sumComponent={
                <>
                  {formatSumAmount({
                    sum: lastWinner.stakeAsDecimal?.toFixed(2),
                    formatToComma,
                  })}
                </>
              }
              currencyComponent={stakeLabel ? <span className="cw__game-bet--currency">{symbol}</span> : <></>}
              currencySymbolFirst={currencySymbolFirst}
              spaceBetween={spaceBetween}
            />
          </div>
        )}
        {type !== WidgetTemplates.SECTION_STAKE && type !== WidgetTemplates.SECTION_STAKE_PLUS && (
          <div className="cw__game-title text-truncate" title={lastWinner?.description}>
            {lastWinner?.description}
          </div>
        )}
      </div>
      <div className="cw__game-time">{difference}</div>
    </Flex.ColJB>
  );
};
GameDescription.displayName = 'LastWinnersWidgetGameDescription';
export default GameDescription;
