import React, { useEffect } from 'react';
import { OfferBonus } from 'shared/common/features/myProfile/sections/tabs/offersTab/components/OffersBonus';
import { readOffers } from 'shared/common/features/myProfile/sections/tabs/offersTab/slice/offersTab.slice';
import { useAppDispatch } from 'store';
import { clearUnseenOffersCounter } from '../slice/bonusOffersInfoButton.slice';
import { BonusOffersInfoButton } from '../types/bonusOffersInfoButton.types';

type Props = {
  promotion: BonusOffersInfoButton.Offer;
  unseen?: string[];
};

const PopoverOffer: React.FC<Props> = ({ promotion, unseen }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (unseen?.length) {
      dispatch(readOffers('', unseen));
      dispatch(clearUnseenOffersCounter());
    }
  }, []);

  return <OfferBonus promotion={promotion} type={promotion.type} layout="layout_1" />;
};

export default PopoverOffer;
