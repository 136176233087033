import React from 'react';
import { selectHomePage } from 'pages/homePage/slice/homePage.slice';
import { MyBets } from 'pages/myAccount/tabs/history/sections/sportsHistory/types/myBets.types';
import { useAppSelector } from 'store';
import { ET } from 'utils/common/types/sportTypes';
import { TerminalEventWrapper } from '../../../components';
import { TicketBet } from '../../../types/ssbt.types';

type Props = {
  bet: ET.TerminalOutcomesEvent extends MyBets.SelectionBonusesTypes ? ET.TerminalOutcomesEvent : TicketBet;
};

const BetSSBT: React.FC<Props> = ({ bet }) => {
  const eventStatusConfig = useAppSelector(selectHomePage.eventStatusConfig);

  return (
    <div className="egtd-bets-wrapper">
      {bet?.outcomes?.map((outcome) => (
        <TerminalEventWrapper outcome={outcome} eventStatusConfig={eventStatusConfig} bet={bet} key={outcome.id} />
      ))}
    </div>
  );
};

export default BetSSBT;
