import { useAppSelector } from 'store';
import { selectReprintTicketData } from '../slice/reprintTicket.slice';

export const useReprintSelectors = () => {
  const firstSelectedFilters = useAppSelector(selectReprintTicketData.filters);
  const ticketHistoryData = useAppSelector(selectReprintTicketData.ticketHistoryData);
  const ticketHistoryDataStatus = useAppSelector(selectReprintTicketData.ticketHistoryDataStatus);
  const ticketHistoryDataError = useAppSelector(selectReprintTicketData.ticketHistoryDataError);
  const ticketOperationData = useAppSelector(selectReprintTicketData.ticketOperationData);
  const ticketOperationStatus = useAppSelector(selectReprintTicketData.ticketOperationStatus);
  const ticketOperationError = useAppSelector(selectReprintTicketData.ticketOperationError);
  const terminalDataStatusError = useAppSelector(selectReprintTicketData.terminalDataStatusError);
  const terminalDataStatus = useAppSelector(selectReprintTicketData.terminalDataStatus);
  const terminalData = useAppSelector(selectReprintTicketData.terminalData);
  const reprintInfo = useAppSelector(selectReprintTicketData.reprintInfo);
  const reprintInfoStatus = useAppSelector(selectReprintTicketData.reprintInfoStatus);
  const reprintInfoError = useAppSelector(selectReprintTicketData.reprintInfoError);
  const expandedTicket = useAppSelector(selectReprintTicketData.expandedTicket);
  const ticketInfo = useAppSelector(selectReprintTicketData.reprintCheckTicketInfo);
  const reprintCheckTicketStatus = useAppSelector(selectReprintTicketData.reprintCheckTicketStatus);
  const reprintCheckTicketError = useAppSelector(selectReprintTicketData.reprintCheckTicketError);
  return {
    firstSelectedFilters,
    ticketHistoryData,
    ticketHistoryDataStatus,
    ticketHistoryDataError,
    ticketOperationData,
    ticketOperationStatus,
    ticketOperationError,
    terminalDataStatusError,
    terminalDataStatus,
    terminalData,
    reprintInfo,
    reprintInfoStatus,
    reprintInfoError,
    expandedTicket,
    ticketInfo,
    reprintCheckTicketStatus,
    reprintCheckTicketError,
  };
};
