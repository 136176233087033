import { DateDifference } from './DateDifference';
import { DoneIcon } from './DoneIcon';
import FavIcon from './FavIcon';
import { TagCoins } from './TagCoins';
import { TagCount } from './TagCount';
import { TagInfo } from './TagInfo';
import { TagType } from './TagType';

export const CuTags = {
  TagType: TagType,
  TagCoins: TagCoins,
  DateDifference: DateDifference,
  TagInfo: TagInfo,
  DoneIcon: DoneIcon,
  FavIcon: FavIcon,
  TagCount: TagCount,
};
