import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { useOddsFormat } from 'hooks/useOddsFormat';
import { cdnConfig, fitCover } from 'utils/common/cdnConfig';
import { isEmpty, retailFormatDate } from 'utils/common/helpersCommon';
import { BetSlip } from 'utils/common/translationUtils/translationStrings';
import { ET } from 'utils/common/types/sportTypes';
import { SportDataTypes } from 'utils/common/types/sportTypes/sportData.types';
import config from 'utils/config';
import { LiveEventBetBuilder } from './LiveEventBetBuilder';
import { isRaceSport, isRaceStraightMarket } from '../../../../../shared/utils/helperFunctions';
import { BetSelectionUI } from '../../../components/betSelectionUI';
import { TicketBet } from '../../../types/ssbt.types';
interface Props {
  outcome: ET.TerminalOutcomesEvent;
  eventStatusConfig: SportDataTypes.EventStatusConfig | null;
  bet: TicketBet;
}
const SSBTEventWrapper: React.FC<Props> = ({ outcome, eventStatusConfig, bet }) => {
  const positions = outcome?.name?.split(',');
  const isStraightMarket = isRaceStraightMarket(Number(outcome.marketTemplateId));
  const { t } = useTranslation();
  const textClass = clsx('bsl-sel__header-race-title');

  const customBetLive = useMemo(() => {
    return outcome?.customBetOutcomes?.find((betBuilder) => betBuilder.isLive);
  }, [outcome?.customBetOutcomes]);

  const iconSize = 18;
  const iconPath = `${config.CDN_IMAGE_API}${fitCover(iconSize * 2)}${cdnConfig.VIRTUALS_NUMBERS}`;
  const iconBaseName = 'silk_';
  const renderPosition = (position: string, index: number) => {
    if (!position) return null;

    const competitorName = position.replace(/[0-9]/g, '');
    const competitorNumber = position.replace(/[^0-9]/g, '');

    const competitorPosition = isStraightMarket ? `${index + 1}` : '';

    return (
      <Flex.AC style={{ gap: '5px' }} key={index}>
        {competitorPosition && <Text text={competitorPosition + ' '} />}
        {competitorNumber && (
          <img
            src={`${iconPath}/${iconBaseName}${competitorNumber}.png`}
            className="bsl-sel__header-race-icon"
            width={`${iconSize}px`}
            height={`${iconSize}px`}
          />
        )}
        <Text className={textClass} text={competitorName} />
      </Flex.AC>
    );
  };
  const isVermantiaEvent = outcome?.selectionStatus === null && outcome?.feedProducerId === 30;
  const { formatOdds } = useOddsFormat();

  return (
    <div className={'full-width egtd-p-bi-sel__wrapper'}>
      <BetSelectionUI.Wrapper>
        <BetSelectionUI.Header
          selectionName={outcome.name}
          odds={formatOdds(outcome)}
          status={outcome?.selectionStatus}
          betStatus={bet.betStatus}
          showLiveBadge={outcome.live}
          renderPosition={renderPosition}
          position={positions}
          isRaceSport={isRaceSport(outcome.sportId)}
          isCustomBetSelection={outcome.isCustomBetSelection}
          isCustomBetLive={!isEmpty(customBetLive)}
        />
        {outcome?.live ? (
          <BetSelectionUI.BodyLive
            homeTeam={outcome.homeTeam}
            awayTeam={outcome.awayTeam}
            bankerLabel={t(BetSlip.Selections.bankerLetter)}
            eventClockStatus={outcome.eventClockStatus}
            eventStatusConfig={eventStatusConfig}
            handleEventClick={() => {
              return;
            }}
            handleLiveTrackerClick={() => {
              return;
            }}
            isEventClickable={false}
            isLiveTrackerButtonActive={false}
            market={outcome.marketTemplateName}
            showBankerLabel={outcome.banker}
            showEventClock={outcome.eventClockStatus && !outcome.isVirtual}
            showLiveScore={outcome.live && !outcome.isVirtual}
            showLiveTrackerButton={false}
            tournamentId={outcome?.tournamentId}
            eventName={outcome.sportEventName}
            sportId={outcome.sportId}
            selectionBonuses={outcome?.selectionBonuses}
          />
        ) : !isEmpty(customBetLive) ? (
          <LiveEventBetBuilder
            key={customBetLive.id}
            customBetLiveOutcome={customBetLive}
            outcome={outcome}
            t={t}
            eventStatusConfig={eventStatusConfig}
          />
        ) : (
          <BetSelectionUI.BodyStatic
            customBetOutcomes={outcome?.customBetOutcomes}
            homeTeam={outcome.homeTeam}
            awayTeam={outcome.awayTeam}
            eventClockStatus={outcome.eventClockStatus}
            isEventClickable={false}
            market={outcome.marketTemplateName}
            showBankerLabel={outcome.banker}
            bankerLabel={t(BetSlip.Selections.bankerLetter)}
            handleEventClick={() => {
              return;
            }}
            prematchEventName={outcome.sportEventName}
            showPrematchEventDate={true}
            prematchEventDate={retailFormatDate(outcome.scheduledTime)}
            sportEventType={outcome.sportEventType}
            sportId={outcome.sportId}
            isVermantiaEvent={isVermantiaEvent}
            marketStatus={outcome.marketStatus}
            selectionBonuses={outcome?.selectionBonuses}
          />
        )}
      </BetSelectionUI.Wrapper>
    </div>
  );
};

export default SSBTEventWrapper;
