import { LayoutConfigInnerLayoutTypes } from 'modules/casino/shared/constants';
import { useCasinoLayoutConfig } from 'modules/casino/shared/hooks/useCasinoLayoutConfig';
import { useInterval } from 'modules/casino/shared/hooks/useInterval';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { AppDispatch, useAppSelector } from 'store';
import { jackpotThunks } from '../../jackpots/jackpotSlice';
import { JACKPOT_INTERVAL_TOP_BAR } from '../utils/gameLaunchBonusConstants';

type JackpotPollingTopBarProps = {
  openedJackpot: boolean;
  dispatch: AppDispatch;
  jackpotId: string;
};
export const useJackpotPollingTopBar = ({ openedJackpot, dispatch, jackpotId }: JackpotPollingTopBarProps) => {
  const jackpotsConfig = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.JACKPOTS });
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const fallbackInterval =
    jackpotsConfig && jackpotsConfig?.fallbackInterval !== undefined
      ? jackpotsConfig.fallbackInterval
      : JACKPOT_INTERVAL_TOP_BAR;

  useInterval(() => {
    if (openedJackpot && isAuthenticated) {
      dispatch(jackpotThunks.fetchJackpots(jackpotId));
    }
  }, fallbackInterval);
};
