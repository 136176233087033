import React from 'react';
import clsx from 'clsx';
import { Sport } from 'utils/common/types/sportTypes';

export const Jersey: React.FC<Sport.Jersey> = ({
  size,
  baseColor,
  sleeveColor,
  sleeveDetail,
  splitColor,
  stripesColor,
}) => {
  const jerseyStripesColor = stripesColor
    ? `<path
      id="jersey-stripes"
      fill="#${stripesColor}"
      d="m29.78 6.43c3.41-.91 5.87-3.43 5.87-6.43.79.14 1.55.3 2.3.49v50.71h-7.57zm-5.3.07c-3.54-.85-6.13-3.43-6.13-6.5-.79.14-1.55.3-2.3.49v50.71h7.83zm18.31 10.56c1.12 2.27 2.38 4.94 3.59 7.48l5.7-1.43-7.6-13.85a56.47 56.47 0 0 0 -1.69 7.8zm-33.27-7.8-7.61 13.85 5.7 1.43c1.22-2.54 2.47-5.21 3.6-7.48a58 58 0 0 0 -1.69-7.8z"
    />`
    : '';

  const jerseySplitColor = splitColor
    ? `<path
      id="jersey-split"
      fill="#${splitColor}"
      d="m43.23 49.51a1.82 1.82 0 0 1 -1.82 1.69h-14.41v-44.4c4.77 0 8.65-3 8.65-6.8a31.21 31.21 0 0 1 10.59 3.88c-4.64 12.86-4.97 20.96-3.01 45.63zm-31.56-24-10.49-2.59h-.05c-1.8-.9-1-3-.7-3.65v-.1a110.63 110.63 0 0 1 7.35-15.25 62.34 62.34 0 0 1 4.1 21.59.25.25 0 0 1 -.21.04z"
    />`
    : '';

  const jerseySleeveDetail = sleeveDetail
    ? `<path
      id="jersey-sleeves_detail"
      fill="#${sleeveDetail}"
      d="m53.57 19.25c.27.68 1.1 2.75-.7 3.65l-10.54 2.65a.25.25 0 0 1 -.21 0c0-1 .07-1.93.12-2.85l11.26-3.6zm-53.07-.15v.1c-.27.68-1.1 2.75.7 3.65h.05l10.49 2.63a.25.25 0 0 0 .21 0c0-1-.07-1.93-.12-2.85z"
    />`
    : '';

  const jerseySVG = `
  <svg width="54" height="80" viewBox="0 0 54 80" xmlns="http://www.w3.org/2000/svg">
    <path d="m27 6.8c4.77 0 8.65-3 8.65-6.8h-1.59a16.48 16.48 0 0 1 -7.06 1.49 16.48 16.48 0 0 1 -7.06-1.49h-1.59c0 3.75 3.88 6.8 8.65 6.8z" />
    <path
      id="jersey-base"
      fill="#${baseColor}"
      d="m46.24 3.88a31.21 31.21 0 0 0 -10.59-3.88c0 3.75-3.88 6.8-8.65 6.8s-8.65-3-8.65-6.8a31.21 31.21 0 0 0 -10.59 3.88c4.64 12.86 5 21 3 45.63a1.82 1.82 0 0 0 1.82 1.69h28.83a1.82 1.82 0 0 0 1.82-1.69c-1.96-24.67-1.63-32.77 3.01-45.63z"
    />
    <path
      id="jersey-shorts"
      fill="#${baseColor}"
      d="m49.66 76.09-6.07-21.6a1.56 1.56 0 0 0 -1.5-1.14h-30.18a1.56 1.56 0 0 0 -1.5 1.14l-6.07 21.6a.93.93 0 0 0 .74 1.18l16.84 2.73a1.54 1.54 0 0 0 1.74-1.17l3.34-14.28 3.34 14.26a1.54 1.54 0 0 0 1.74 1.19l16.84-2.71a.93.93 0 0 0 .74-1.2z"
    />
    <path
      id="jersey-sleeves"
      fill="#${sleeveColor}"
      d="m52.87 22.9-10.54 2.65a.25.25 0 0 1 -.21 0 62.34 62.34 0 0 1 4.1-21.59 110.63 110.63 0 0 1 7.3 15.22v.1c.32.65 1.15 2.72-.65 3.62zm-41 2.61a62.34 62.34 0 0 0 -4.09-21.59 110.63 110.63 0 0 0 -7.3 15.22v.1c-.27.68-1.1 2.75.7 3.65h.05l10.49 2.63a.25.25 0 0 0 .16-.01z"
    />
    ${jerseyStripesColor}
    ${jerseySplitColor}
    ${jerseySleeveDetail}
    <path
      id="jersey-stroke"
      d="M49.65 76.09l-6.07-21.6a1.56 1.56 0 00-1.5-1.14H11.9a1.56 1.56 0 00-1.5 1.14l-6.07 21.6a.93.93 0 00.74 1.18L21.91 80a1.55 1.55 0 001.74-1.17l3.34-14.28 3.34 14.26A1.55 1.55 0 0032.07 80l16.84-2.71a.93.93 0 00.74-1.2zM31.88 78.8a.35.35 0 01-.38-.26l-3.34-14.27a1.21 1.21 0 00-2.34 0l-3.34 14.27a.34.34 0 01-.33.26L5.56 76.13l6-21.31a.37.37 0 01.35-.27h30.17a.37.37 0 01.35.27l6 21.31zM53.56 19.25a112.79 112.79 0 00-7.35-15.33A32 32 0 0035.64 0h-1.59C30.28 2 23.7 2 19.93 0h-1.59A32 32 0 007.75 3.88 110.63 110.63 0 00.47 19.14c-.52 1.11-1.14 3.59 1.43 4l5.7 1.43s3.5 1 3.71 1a214.23 214.23 0 01-.55 24 1.82 1.82 0 001.82 1.69H41.4a1.82 1.82 0 001.82-1.69 214.28 214.28 0 01-.5-24c.2 0 7.07-1.84 9.35-2.41 2.53-.41 1.98-2.84 1.49-3.91zm-1.15 2.54c-1.44.35-3 .76-5.14 1.33-1.9.5-4.25 1.11-4.69 1.21a1.27 1.27 0 00-1.07 1.46 216.55 216.55 0 00.5 23.7.62.62 0 01-.61.51H12.58a.62.62 0 01-.61-.51 216.13 216.13 0 00.54-24 1.2 1.2 0 00-1.09-1.17c-.38-.08-2-.52-3.49-.93L2.2 21.94c-.38 0-.82-.19-.9-.4a2.6 2.6 0 01.25-1.89A113.6 113.6 0 018.68 4.73a30.26 30.26 0 019.77-3.53h1.19a17.29 17.29 0 007.35 1.47 17.29 17.29 0 007.35-1.47h1.19a30.05 30.05 0 019.77 3.54 115.18 115.18 0 017.14 15c.63 1.52.26 1.89-.03 2.05z"
      fill="gray"
    />
  </svg>
  `;

  const symbols = /[\r\n"%#()<>?[\\\]^`{|}]/g;

  const encodeSVG = (data: string) => {
    // Use single quotes instead of double to avoid encoding.
    if (data.indexOf('"') >= 0) {
      data = data.replace(/"/g, "'");
    }

    data = data.replace(/>\s{1,}</g, '><');
    data = data.replace(/\s{2,}/g, ' ');

    return data.replace(symbols, encodeURIComponent);
  };

  const bg = encodeSVG(jerseySVG);

  const jerseyClass = clsx('team-jersey', size === 'lg' && 'team-jersey--lg', size === 'xl' && 'team-jersey--xl');

  return <div className={jerseyClass} style={{ backgroundImage: `url("data:image/svg+xml,${bg}")` }} />;
};
