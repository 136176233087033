import clsx from 'clsx';
import { HelmetProvider } from 'react-helmet-async';
import { useMediaQuery } from 'react-responsive';
import { AppFooterSections } from 'components/shared';
import AppBottomPlatform from 'components/shared/AppBottomPlatform';
import ButtonBackToTop from 'components/shared/buttons/ButtonBackToTop';
import UtmCookieQueryParams from 'components/shared/UtmCookieQueryParams';
import { useAppInit } from 'hooks/common/useAppInit';
import { useAppVersionCheck } from 'hooks/common/useAppVersionCheck';
import { useCDNIconSprites } from 'hooks/common/useCDNIconSprites';
import { useChunkFailedErrorHandler } from 'hooks/common/useChunkFailedErrorHandler';
import { useConnectionLostHandler } from 'hooks/common/useConnectionLostHandler';
import { useGeolocationCheck } from 'hooks/common/useGeolocationCheck';
import { useRealityCheck } from 'hooks/common/useRealityCheck';
import { useSessionCheck } from 'hooks/common/useSessionCheck';
import { useTheme } from 'hooks/common/useTheme';
import { useUnsubscribe } from 'hooks/ws/useUnsubscribe';
import useCasinoWidgetInit from 'modules/casino/platformImplementation/hooks/useCasinoWidgetInit';
import CasinoAppComponents from 'modules/casino/shared/components/casinoAppComponent/CasinoAppComponents';
import TerminalWrapper from 'modules/retail/shared/components/RetailWrapper';
import { QuickLoginFormModal } from 'pages/auth/login';
import AffiliateTokenHandler from 'shared/common/AffiliateTokenHandler';
import AgentHandler from 'shared/common/AgentHandler';
import AIVOChat from 'shared/common/chat/AIVOChat';
import PreloaderSupportChat from 'shared/common/chat/PreloaderSupportChat';
import ErrorBoundary from 'shared/common/ErrorBoundary';
import FBEventTriggerer from 'shared/common/FBEventTriggerer';
import { AppBackdrop } from 'shared/common/features/appBackdrop';
import { AppHeaderLayout } from 'shared/common/features/appHeader';
import { AppThemeProvider } from 'shared/common/features/appThemeProvider';
import BurgerRetailMobile from 'shared/common/features/burger/BurgerRetailMobile';
import CookiesConsent from 'shared/common/features/cookiesConsent/CookiesConsent';
import Drawer from 'shared/common/features/drawer/Drawer';
import { selectDrawer } from 'shared/common/features/drawer/slice/drawer.slice';
import HighlightNewFeature from 'shared/common/features/highlightNewFeature';
import { GamificationWidgetInit } from 'shared/common/features/integrations/gamification/GamificationWidgetInit';
import { useGamificationWidgetConfig } from 'shared/common/features/integrations/gamification/hooks/useGamificationWidgetConfig';
import useSmarticoScript from 'shared/common/features/integrations/smartico/hooks/useSmarticoScript';
import InterceptorModal from 'shared/common/features/interceptors/interceptorModal';
import Localization from 'shared/common/features/localization/Localization';
import { UsernameMigrationModal } from 'shared/common/features/myProfile/components';
import { FetchUserBalance } from 'shared/common/features/myProfile/components/FetchUserBalance';
import { InsufficientBalanceTrigger } from 'shared/common/features/myProfile/components/InsufficientBalanceTrigger';
import PopUpNotificationInit from 'shared/common/features/myProfile/sections/tabs/notificationsTab/PopUpNotificationInit';
import { selectNavigationMenu } from 'shared/common/features/navigationMenu/slice/navigationMenu.slice';
import PartialModal from 'shared/common/features/partialModal/PartialModal';
import AppSidebarCasinoWrapper from 'shared/common/features/platformPortals/AppSidebarCasinoWrapper';
import { Popover } from 'shared/common/features/popover/popoverContainer';
import { RootModal } from 'shared/common/features/rootModal';
import { HelmetComponent } from 'shared/common/features/seo/metaData';
import { WebSocketSignalR } from 'shared/common/features/websockets/WebSocketSignalR';
import GTMEventTriggerer from 'shared/common/GTMEventTriggerer';
import MobileBackHandler from 'shared/common/MobileBackHandler';
import SessionInactivity from 'shared/common/sessionInactivity/SessionInactivity';
import TagManagerInit from 'shared/common/TagManagerInit';
import InactiveRoutesHandler from 'shared/InactiveRoutesHandler';
import { TopLevelRouter } from 'shared/router';
import { useAppSelector } from 'store';
import GlobalStyle from 'theme/scMixins/SCGlobalStyles';
import { Breakpoints, DesktopDown } from 'theme/Theme';
import { isCashier, isRetail, isTerminal } from 'utils/common/helpersCommon';
import useConfiguratorPostMessages from './shared/common/features/integrations/configurator/useConfiguratorPostMessages';
import { selectBottomTabBar } from '../src/shared/common/features/bottomTabBar/slice/bottomTabBar.slice';

function App(): JSX.Element {
  const isDrawerVisible = useAppSelector(selectDrawer.isVisible);
  const isDesktopDown = useMediaQuery({ query: `(max-width: ${Breakpoints.isTabletOrMobile}px)` });
  const burgerMenuLayout02 = useAppSelector(selectNavigationMenu.burgerMenuLayout02)?.layout;
  // const isNotRetail = !isCashier() && !isTerminal() && !isTerminalOnline();
  const { gamificationInits, isGamificationInit } = useGamificationWidgetConfig();
  // DO NOT USE useLocation in these hooks!!!
  useAppInit();
  useUnsubscribe();
  useConnectionLostHandler();
  useChunkFailedErrorHandler();
  useTheme();
  useCDNIconSprites('uiIcons', 'ui_icons_timestamp', 'ui-icons', 'common-content/general-ui/sprites.svg');
  useCDNIconSprites('mpdIcons', 'mpd_icons_timestamp', 'mpd-icons', 'common-content/general-ui/mpd-sprites.svg');
  useSessionCheck();
  useRealityCheck();
  useSmarticoScript();
  useAppVersionCheck();
  useGeolocationCheck();
  useConfiguratorPostMessages();
  useCasinoWidgetInit();

  const isBottomTabBarVisible = useAppSelector(selectBottomTabBar.isBottomTabBarVisible);

  const contentStyles = clsx({
    'main-content': true,
    'active-content-offset': isDrawerVisible && isDesktopDown && burgerMenuLayout02 != 'layout_2',
    'has-bbt': isBottomTabBarVisible,
  });

  return (
    <>
      {/* <PageCss /> */}
      {/* <UserSubscriptionInit /> */}
      <GlobalStyle />
      {isGamificationInit && gamificationInits && <GamificationWidgetInit gamificationInits={gamificationInits} />}
      <PopUpNotificationInit />
      <UtmCookieQueryParams />
      <AIVOChat />
      {/* {!isDrawerVisible && !isMyAccountModalShowing && <ChatButton />} */}
      <InsufficientBalanceTrigger />
      <FetchUserBalance />
      <TagManagerInit />
      <ErrorBoundary>
        <Localization>
          <HelmetProvider>
            <CasinoAppComponents />
            <WebSocketSignalR />
            <AffiliateTokenHandler />
            <GTMEventTriggerer />
            <FBEventTriggerer />
            <AgentHandler />
            <InactiveRoutesHandler />
            <HelmetComponent />
            <AppThemeProvider>
              {!isRetail() && !window.isRNWebView && <CookiesConsent />}
              {isRetail() && window.config.DEVICE_TYPE === 'Handheld' ? (
                <BurgerRetailMobile isToggled={isDrawerVisible} />
              ) : (
                <Drawer isToggled={isDrawerVisible} />
              )}
              <MobileBackHandler />

              <div className={contentStyles}>
                <AppHeaderLayout />

                <main style={{ minHeight: '85vh' }}>
                  {/* *
                   * App router for top level routes like: sports, casino egt, live casino. */}
                  <TopLevelRouter />
                  <PartialModal />
                  <AppSidebarCasinoWrapper />
                </main>
                {(isTerminal() || isCashier()) && <TerminalWrapper />}
                <AppFooterSections />
                <AppBottomPlatform />
                <HighlightNewFeature />
                <UsernameMigrationModal />
                <InterceptorModal />
                <PreloaderSupportChat />
                <RootModal />
                <QuickLoginFormModal />
                <AppBackdrop />
                {!isRetail() && !window.isRNWebView && <SessionInactivity />}
                <Popover />

                <DesktopDown>{!isDrawerVisible && <ButtonBackToTop />}</DesktopDown>
              </div>
            </AppThemeProvider>
          </HelmetProvider>
        </Localization>
      </ErrorBoundary>
    </>
  );
}

export default App;
