import { useEffect, useCallback, useState } from 'react';
import { useBarcode } from './useBarcode';

export const useScanner = (actions, actionKeyMap) => {
  const { barcode, startScanning, addCharacter, reset, isScanningFast } = useBarcode();
  const [isScanning, setScanning] = useState(false);
  const getActionKey = useCallback(
    (barcodeValue) => {
      const actionEntry = actionKeyMap.find((entry) => entry.validate(barcodeValue));
      return actionEntry ? actionEntry.key : 'default';
    },
    [actionKeyMap],
  );

  useEffect(() => {
    const handleScan = (e) => {
      // Start scanning if it's a non-Enter key press and not already scanning
      if (!isScanning && e.key !== 'Enter' && e?.key?.length === 1) {
        setScanning(true);
        startScanning();
      }

      if (e.key === 'Enter') {
        if (isScanningFast()) {
          // isScanningFast() is used to check if the scanning was completed quickly enough (within 600 milliseconds) to process it as a single scan.
          const actionKey = getActionKey(barcode);
          // Execute the action corresponding to the scanned barcode
          (actions[actionKey] || actions['default'])();
          // Delay the reset to allow the scanner to finish sending keystrokes
          setTimeout(() => {
            reset();
            setScanning(false);
          }, 360); // Delay to handle scanning completion
        } else {
          // Handle slow or interrupted scans
          setTimeout(() => {
            reset();
            setScanning(false);
          }, 360); //160 for other
        }
      } else if (e?.key?.length === 1) {
        // Ignore non-printable keys like Shift
        // Add character to the barcode if it's a printable key
        addCharacter(e?.key);
      }
    };

    window.addEventListener('keydown', handleScan);
    return () => {
      window.removeEventListener('keydown', handleScan);
    };
  }, [isScanning, barcode, getActionKey]);

  return {
    barcode,
    reset,
  };
};
