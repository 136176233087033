import { isEmpty } from 'utils/common/helpersCommon';
import { MainNavBarButtonsMap } from './features/mainNavbarButtonsMap';
import { useGetMainNavBarButtonsLayout } from './hooks/useGetMainNavBarButtonsLayout';
import { MainNavBarButtonsTypes } from './types/mainNavBarButtons.types';

type Props = {
  isMyProfile?: boolean;
  type?: MainNavBarButtonsTypes.ItemType;
};

export const MainNavBarButtons: React.FC<Props> = ({ isMyProfile, type }) => {
  const { mainButtons } = useGetMainNavBarButtonsLayout({});
  if (isEmpty(mainButtons)) return null;

  return (
    <>
      {mainButtons.map((item, key) => (
        <MainNavBarButtonsMap key={key} item={item} isMyProfile={isMyProfile} type={type} />
      ))}
    </>
  );
};
