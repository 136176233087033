import { useRef } from 'react';
import { useImage } from 'modules/casino/modules/container/widget/widgetTypes/lastWinnersWidgets/hooks/useImage';
import { useGridLayoutConfig } from '../../../../../cellsContainer/grid/components/gridCell/hooks/useGridLayoutConfig';
import { GridLayouts } from '../../../../../cellsContainer/grid/types/gridTypes';
import { CasinoWidgetsTypes } from '../../../../types/casinoWidgetsTypes';

interface Props {
  lastWinner: CasinoWidgetsTypes.LastWinner;
  liveImageLocation: string;
  imageContainerRef: React.RefObject<HTMLDivElement>;
  cdnPathLayout: string;
  className?: string;
  alt?: string;
  gameLayout?: string;
}

const GameImage: React.FC<Props> = ({
  lastWinner,
  liveImageLocation,
  imageContainerRef,
  cdnPathLayout,
  className,
  alt = '',
  gameLayout,
}) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const { cdnPath } = useGridLayoutConfig(GridLayouts.LAYOUT_1);
  const { handleError } = useImage({
    imgRef,
    imageContainerRef,
    lastWinner,
    liveImageLocation,
    cdnPathLayout,
    cdnPath,
    gameLayout,
  });

  return <img ref={imgRef} alt={alt} className={className} onError={handleError} />;
};
GameImage.displayName = 'LastWinnersWidgetGameImage';
export default GameImage;
