import { Buffer } from 'buffer';
import { decode } from '@msgpack/msgpack';
import { isEmpty } from 'utils/common/helpersCommon';
import { GamificationPageTypes } from '../types/GamificationPage.types';

export const parsePageNotification = (notification: string): GamificationPageTypes.Campaign => {
  const decodedResponse = decode(Buffer.from(notification, 'base64')) as string;

  return JSON.parse(decodedResponse);
};

export const formatNotification = (notification: GamificationPageTypes.Campaign): GamificationPageTypes.Campaign => {
  if (notification.bonusData) {
    if (!isEmpty(notification.bonusData.bonuses)) {
      // New contract with bonuses property
      // Extract bonuses titles into array
      const winBonusesTitles = notification.bonusData.bonuses.map((bonus) => bonus.title);
      // Set other options
      const otherOptions = [...(notification?.bonusData?.otherOptions ? notification.bonusData.otherOptions : [])];
      // Get index from BE notification.winIndex or generate random index from other options indexes
      // In case with one bonus randomIndex === middleWinItemIndex === winIndex
      const randomIndex =
        notification.winIndex ?? Math.floor(Math.random() * otherOptions.length > 0 ? otherOptions.length - 1 : 0);
      // Define the index of middle win item
      const middleWinItemIndex = Math.round(notification.bonusData.bonuses.length - 1) / 2 + randomIndex;
      // Add win bonuses titles at random index in notification.bonusData.otherOptions
      otherOptions.splice(randomIndex, 0, ...winBonusesTitles);
      const bonusData = { ...notification.bonusData, otherOptions };
      // Set middleWinItemIndex to notification winIndex prop if there is no win index
      const winIndex = middleWinItemIndex;
      const startIndex = randomIndex;
      const endIndex = randomIndex + notification.bonusData.bonuses.length - 1;
      return { ...notification, winIndex, startIndex, endIndex, bonusData };
    }
  }

  return notification;
};

export const defineCalendarActiveDay = (history?: GamificationPageTypes.DaysType[]) => {
  return !isEmpty(history) && history[history.length - 1].isActive && history[history.length - 1].isRead === false
    ? history.length - 1
    : undefined;
};

export const defineCalendarItemState = ({
  history,
  index,
}: {
  history?: GamificationPageTypes.DaysType[];
  index: number;
}) => {
  const activeDayIndex: number | undefined = defineCalendarActiveDay(history);
  const hasHistory = !!history?.[index];
  const currIsActive = history && history?.[index]?.isActive && history?.[index]?.isRead === false;
  const hasNextActive = index !== activeDayIndex && currIsActive;
  const latestActiveItem = index === activeDayIndex;
  const isActive = latestActiveItem ? true : hasNextActive || history?.[index]?.isActive === false ? false : undefined;
  const isRead = history && history[index]?.isRead;

  return { isActive, isRead, hasHistory };
};

export const bonusDataMissionsType = {
  Active: 'Active',
  Locked: 'Locked',
  Success: 'Success',
};
