import { useEffect } from 'react';
import { useAppDispatch } from 'store';
import { jackpotThunks } from '../jackpotSlice';

let jackpotLevelsInitialFetch = false;

export const useInitialJackpotLevelsFetch = (): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!jackpotLevelsInitialFetch) {
      jackpotLevelsInitialFetch = true;
      dispatch(jackpotThunks.fetchJackpots());
    }
  }, [dispatch]);
};
