import React, { memo, useRef } from 'react';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import useHeaderConfig from 'shared/common/hooks/useHeaderConfig';
import TickerWrapper from './TickerWrapper';
import { TopNavbar } from '..';
import useAppHeader from '../../hooks/useAppHeader';
import { useHideTopNavHeaderOnScroll } from '../../hooks/useHideTopNavHeaderOnScroll';
import useIsSearchOpen from '../../hooks/useIsSearchOpen';
import { useNotifications } from '../../hooks/useNotifications';
import { useSetTopNavHeaderStatus } from '../../hooks/useSetTopNavHeaderStatus';
import MainNavbar from '../mainNavbar/MainNavbar';
import TopMobileAppBanner from '../topNavbar/TopMobileAppBanner';

let addInitialCollapse = true;

const AppHeader: React.FC = () => {
  const appHeaderRef = useRef<HTMLDivElement | null>(null);
  const topNavBarRef = useRef<HTMLDivElement | null>(null);
  const mainNavRef = useRef<HTMLDivElement | null>(null);
  const headBackRef = useRef<HTMLDivElement | null>(null);

  const {
    navigationConfig,
    topNavHeaderStatus,
    isMyProfileModalShowing,
    classByLocation,
    isTabletOrMobile,
    isAuthenticated,
    triggerAnimationEnd,
    isMyBetsVisible,
  } = useAppHeader(appHeaderRef, topNavBarRef, mainNavRef);

  const { topMobileAppBanner: bannerConfig } = useHeaderConfig();
  const isSearchPage = useIsSearchOpen();
  // Fetch notification
  useNotifications(isAuthenticated);

  // Hide/Show Header on scroll
  // only if configuration enables TopNavBar
  useHideTopNavHeaderOnScroll(appHeaderRef);
  useSetTopNavHeaderStatus();

  const appHeaderClass = clsx(
    'head',
    navigationConfig?.topNavInitialCollapsed && 'head--sticky',
    navigationConfig?.topNavInitialCollapsed && addInitialCollapse && 'head--collapsed',
    // isTablet && 'head--device',
    (topNavHeaderStatus === 'collapsed' || topNavHeaderStatus === 'fixed-collapsed') && 'head--collapsed',
    isMyProfileModalShowing && navigationConfig?.topNav && (isMobile ? 'head--my-profile' : 'head--collapsed'),
    isMyProfileModalShowing && !navigationConfig?.topNav && isMobile && 'head--my-profile',
  );
  addInitialCollapse = false;
  if (!navigationConfig) {
    return <div className={appHeaderClass} ref={appHeaderRef} />;
  }

  if (isTabletOrMobile && isSearchPage) return null;

  return (
    <>
      {topNavHeaderStatus != 'fixed-collapsed' && !isMyBetsVisible && !isMyProfileModalShowing && bannerConfig && (
        <TopMobileAppBanner isTabletOrMobile={isTabletOrMobile} headBackRef={headBackRef} bannerConfig={bannerConfig} />
      )}
      <div className={appHeaderClass} ref={appHeaderRef}>
        {/* {on Tablet or Mobile: TopNavbar shows only if configuration requires it: navigationConfig.topNav = true } */}
        {isTabletOrMobile && !navigationConfig.topNav ? null : (
          <TopNavbar
            triggerAnimationEnd={triggerAnimationEnd}
            classByLocation={classByLocation}
            topNavBarRef={topNavBarRef}
            isTabletOrMobile={isTabletOrMobile}
          />
        )}
        <MainNavbar classByLocation={classByLocation} mainNavRef={mainNavRef} isAuthenticated={isAuthenticated} />
        <TickerWrapper />
      </div>
    </>
  );
};

export default memo(AppHeader);
