import React, { memo } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { GamificationPageTypes } from 'pages/gamificationPage/types/GamificationPage.types';
import { Breakpoints } from 'theme/Theme';
import NotAuthenticatedText from './NotAuthenticatedText';
import { NotificationTypes } from '../../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import { GamificationUI } from '.';
import './Logo.scss';

interface Props {
  notification: GamificationPageTypes.Campaign;
  animationParams: NotificationTypes.GamificationModalParams;
  className?: string;
  isLoading: boolean;
  isBanned?: boolean;
  disableLogo?: boolean;
}

const NotAuthenticated: React.FC<Props> = ({
  className,
  animationParams,
  isLoading,
  notification,
  isBanned,
  disableLogo,
}) => {
  const { isRead, publicText, inActiveText, noBonusText, style } = notification;
  const text = isBanned ? noBonusText : isRead ? inActiveText : publicText;

  if (isLoading) return null;

  const wrapperClass = clsx('w-100 ', disableLogo && 'd-flex-col', className);
  return (
    <WrapperStyle className={wrapperClass} $disableLogo={disableLogo}>
      {!disableLogo && <GamificationUI.LogoNew animationParams={animationParams} />}
      <InfoStyle $disableLogo={disableLogo}>
        <NotAuthenticatedText style={style} textState={text} />
      </InfoStyle>
    </WrapperStyle>
  );
};

const InfoStyle = styled.div<{ $disableLogo?: boolean }>`
  margin: 0 auto;
  ${({ $disableLogo }) => !$disableLogo && `display:flex; justify-content:center;`};
`;
const WrapperStyle = styled.div<{ $disableLogo?: boolean }>`
  text-align: center;
  padding: 20px 0 0 0;
  padding-top: ${({ $disableLogo }) => $disableLogo && '167px'};

  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    padding: 60px 0 0 0;
    padding-top: ${({ $disableLogo }) => ($disableLogo ? '240px' : '77px')};
  }
`;

export default memo(NotAuthenticated);
