import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'modules/casino/shared/components/modal';
import { updateNotifications } from 'shared/common/features/inGameNotifications/slice/inGameNotifications.slice';
import { WsNotificationsTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/types/wsNotifications.types';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch } from 'store';
import JackpotModalFooter from './components/JackpoModalFooter';
import JackpotModalHeader from './components/JackpotModalHeader';
import JackpotModalSection from './components/JackpotModalSection';
import { JackpotTranslations, jackpotWinModalPrefix } from '../utils/gameLaunchBonusConstants';

type Props = { activeNotification: WsNotificationsTypes.Notification };

export const JackpotWinModal: React.FC<Props> = ({ activeNotification }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const bonus = activeNotification?.BonusData?.bonuses?.[0];
  const continueButtonText = t(JackpotTranslations.continueButton) || '';
  const jackpotWinModalRef = useRef<HTMLDivElement>(null);
  const handleClose = () => {
    dispatch(updateNotifications(activeNotification.Id));
    dispatch(hideRootModal());
  };

  const handleInfo = () => {
    bonus?.promotionAlias &&
      bonus?.categoryAlias &&
      window.open(`/promotions/${bonus.categoryAlias}/${bonus.promotionAlias}`, '_blank');
  };
  useEffect(() => {
    const scaleToFit = () => {
      if (jackpotWinModalRef.current) {
        const viewHeight = window.innerHeight;
        const modalHeight = jackpotWinModalRef.current?.clientHeight;
        if (viewHeight < modalHeight) {
          jackpotWinModalRef.current.style.scale = `${(viewHeight - 46) / modalHeight}`;
          jackpotWinModalRef.current.style.transformOrigin = 'top';
        } else {
          jackpotWinModalRef.current.style.scale = '1';
        }
      }
    };
    scaleToFit();
    window.addEventListener('resize', scaleToFit);
    return () => window.removeEventListener('resize', scaleToFit);
  }, [jackpotWinModalRef]);

  return (
    <Modal visible dialogClassName="modal-dialog-centered">
      <div className={`${jackpotWinModalPrefix}`} ref={jackpotWinModalRef}>
        <JackpotModalHeader title={bonus?.campaignName || ''} handleInfo={handleInfo} handleClose={handleClose} />
        <JackpotModalSection bonus={bonus} />
        <JackpotModalFooter
          infoText={t(JackpotTranslations.infoText) || ''}
          btnText={activeNotification?.Button?.buttonText || continueButtonText}
          infoLinkText={t(JackpotTranslations.infoLinkText) || ''}
          handleClose={handleClose}
          handleInfo={handleInfo}
        />
      </div>
    </Modal>
  );
};
