import { memo } from 'react';
import styled, { css } from 'styled-components';
import { GamificationPageTypes } from 'pages/gamificationPage/types/GamificationPage.types';
import ParseHTML from 'shared/common/ParseHTML';
import { Breakpoints } from 'theme/Theme';
import { GamificationUI } from './';

const NotAuthenticatedText: React.FC<{
  isLoading?: boolean;
  textState: GamificationPageTypes.CampaignText;
  style?: GamificationPageTypes.StyleProps;
}> = ({ isLoading = false, textState, style }) => {
  if (isLoading) return null;

  return (
    <StyledWrapper>
      <StyledInner
        $border={style?.subject?.borderColor}
        $brRadius={style?.subject?.borderRadius}
        $brWidth={style?.subject?.borderWidth}
        $bgColor={style?.subject?.backgroundColor}
      >
        <GamificationUI.CTATitle title={textState?.title} color={style?.subject?.titleColor} />
        <GamificationUI.CTAText
          showWinText
          text=""
          winText={<ParseHTML html={textState?.description || ''} options={{ trim: true }} />}
          styledCss={textCss}
          textColor={style?.subject?.textColor}
        />
      </StyledInner>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  margin: 0 10px;
  justify-self: center;
  align-self: center;
  margin-top: 10px;
  max-width: 355px;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    margin: 0 20px;
    max-width: 547px;
    margin-top: 25px;
  }
`;

const StyledInner = styled.div<{ $border?: string; $bgColor?: string; $brRadius?: number; $brWidth?: number }>`
  background: ${({ $bgColor }) => ($bgColor ? $bgColor : '#2C2C2C')};
  border: ${({ $border, $brWidth }) => `${$brWidth ? $brWidth : '1'}px solid ${$border ? $border : '#858484'}`};
  border-radius: ${({ $brRadius }) => ($brRadius ? $brRadius + 'px' : '0')};
  text-align: center;
  padding: 20px 10px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @media (max-width: 926px) and (orientation: landscape) {
    justify-content: inherit;
  }

  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    justify-content: inherit;
    padding: 60px 20px;
  }
`;

const textCss = css`
  padding-top: 12px;
  font-size: 14px;
  display: inherit !important;

  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    padding-top: 18px;
    font-size: 18px;
    max-width: 100%;
  }
  p {
    margin-bottom: 10px;
  }
`;
export default memo(NotAuthenticatedText);
