import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import Icon from 'components/shared/Icon';
import { Flex } from 'components/shared/layout';
import { TerminalTheme } from 'modules/retail/modules/ssbt/ssbtConfig';
import CountDownTimerVoucher from './CountDownTimerVoucher';

/** Cashier Modal Grid Button Section */
interface GridButtonProps {
  className?: string;
  text: string;
  icon: string;
  id: number;
  isActive: Record<number, boolean>;
  actionButtonClicked: Record<number, boolean>;
  secondIcon?: string;
  hasCounter?: boolean;
  onClick: (id?: number) => void | (() => void) | MouseEventHandler<HTMLButtonElement>;
  setIsActive?: React.Dispatch<React.SetStateAction<boolean>>;
  seconds?: number;
  setSeconds?: React.Dispatch<React.SetStateAction<number>>;
  activeButton?: number[] | null;
  setActionButtonClicked?: React.Dispatch<React.SetStateAction<boolean>>;
}

const GridButtonVoucher: React.FC<GridButtonProps> = ({
  className,
  text,
  icon,
  secondIcon,
  isActive,
  onClick,
  actionButtonClicked,
  setIsActive,
  seconds,
  setSeconds,
  id,
  activeButton,
  setActionButtonClicked,
}) => {
  const buttonClass = clsx(
    'grid-button grid-button--disable',
    isActive[id] && activeButton?.includes(id) && 'grid-button--active',
    actionButtonClicked?.[id] && activeButton?.includes(id) && 'grid-button--counter',
    className,
  );

  return (
    <Flex.ColJC
      className={buttonClass}
      onClick={
        isActive[id] && activeButton?.includes(id)
          ? () => onClick(id)
          : () => {
              return;
            }
      }
    >
      <Flex.AC className="icon-pad">
        <Icon iconPath={TerminalTheme.icons.ui} icon={icon} />
        {secondIcon && <Icon iconPath={TerminalTheme.icons.ui} icon={secondIcon} />}
      </Flex.AC>
      <Flex.ACJB className="text">
        <span>{text}</span>{' '}
        {actionButtonClicked?.[id] && activeButton?.includes(id) && (
          <CountDownTimerVoucher
            isActive={isActive}
            setIsActive={setIsActive}
            seconds={seconds}
            setSeconds={setSeconds}
            setActionButtonClicked={setActionButtonClicked}
            id={id}
            actionButtonClicked={actionButtonClicked}
          />
        )}
      </Flex.ACJB>
    </Flex.ColJC>
  );
};

export default GridButtonVoucher;
