import React from 'react';
import { useTranslation } from 'react-i18next';
import CurrencySwapper from 'modules/casino/shared/components/common/CurrencySwapper';
import { useCurrencyConfig } from 'modules/casino/shared/hooks/useCurrencyConfig';
import { formatSumAmount } from 'modules/casino/shared/utils/helpersCasino';
import { useAppSelector } from 'store';
import { SkywindJackpotsTranslations } from '../../jackpotConstants';
import { jackpotSelectors } from '../../jackpotSlice';
import { JackpotTypes } from '../../jackpotTypes';

interface Props {
  levelDetails: JackpotTypes.CasinoLevel;
  jackpotId: string;
}

const SkywindAmountLimitJackpot: React.FC<Props> = ({ levelDetails, jackpotId }) => {
  const { t } = useTranslation();
  const currency = useAppSelector((state) => jackpotSelectors.selectJackpotCurrencySign(state, jackpotId));
  const mustWinBeforeText = t(SkywindJackpotsTranslations.mustWinBefore) || '';
  const jackpotWonText = t(SkywindJackpotsTranslations.jackpotWon) || '';
  const { formatToComma, currencySymbolFirst, spaceBetween } = useCurrencyConfig();

  return levelDetails?.lastWonAmount ? (
    <div className="jackpot__extra">
      <span className="jackpot__extra--condition">
        <CurrencySwapper
          sumComponent={<>{formatSumAmount({ sum: levelDetails.lastWonAmount, formatToComma })}</>}
          currencyComponent={<span>{currency}</span>}
          currencySymbolFirst={currencySymbolFirst}
          spaceBetween={spaceBetween}
        />
      </span>
      <span className="jackpot__extra--info"> {jackpotWonText}</span>
    </div>
  ) : levelDetails?.dropAmount ? (
    <div className="jackpot__extra">
      <span className="jackpot__extra--info">{mustWinBeforeText}</span>
      <span className="jackpot__extra--condition">
        <CurrencySwapper
          sumComponent={<>{formatSumAmount({ sum: levelDetails.dropAmount, formatToComma, omitZeroDecimals: true })}</>}
          currencyComponent={<span>{currency}</span>}
          currencySymbolFirst={currencySymbolFirst}
          spaceBetween={spaceBetween}
        />
      </span>
    </div>
  ) : null;
};

export default SkywindAmountLimitJackpot;
