// Mixin: Spacing and Alignment

import { css } from 'styled-components';

const Ph = css`
  padding-left: ${({ theme }) => theme.Common.Variables.componentPh};
  padding-right: ${({ theme }) => theme.Common.Variables.componentPh};
`;

const PhCustom = (paddingHorizontal: string) => css`
  padding-left: ${paddingHorizontal};
  padding-right: ${paddingHorizontal};
`;

const PvCustom = (paddingVertical: string) => css`
  padding-top: ${paddingVertical};
  padding-bottom: ${paddingVertical};
`;

const MhCustom = (marginHorizontal: string) => css`
  margin-left: ${marginHorizontal};
  margin-right: ${marginHorizontal};
`;
const MvCustom = (marginVertical: string) => css`
  margin-top: ${marginVertical};
  margin-bottom: ${marginVertical};
`;

const pvSm = css`
  padding-top: 5px;
  padding-bottom: 5px;
`;

const phSm = css`
  padding-left: ${({ theme }) => theme.Common.Variables.componentSpacer};
  padding-right: ${({ theme }) => theme.Common.Variables.componentSpacer};
`;
const spacerH = css`
  margin-left: ${({ theme }) => theme.Common.Variables.componentSpacer};
  margin-right: ${({ theme }) => theme.Common.Variables.componentSpacer};
`;

const centerHorizontal = css`
  margin-left: auto;
  margin-right: auto;
`;
const centerVertical = css`
  margin-top: auto;
  margin-bottom: auto;
`;

/**
 * @constant {Ph}  - padding left and right -- Ph
 * @constant {PhCustom(paddingHorizontal:string)}  - Custom padding left and right
 * @constant {PvCustom(paddingVertical:string)}  - Custom padding top and bottom
 * @constant {MhCustom(marginHorizontal:string)}  - Custom margin left and right
 * @constant {MvCustom(marginVertical:string)}  - Custom margin top and bottom
 * @constant {pvSm}  - 5px padding top and bottom
 * @constant {phSm}  - padding left and right -- componentSpacer
 * @constant {spacerH}  - margin left and right -- componentSpacer
 * @constant {centerHorizontal}  - margin left and right -- auto
 * @constant {centerVertical}  - margin top and bottom -- auto
 */

export const Spacers = {
  Ph,
  PhCustom,
  PvCustom,
  MhCustom,
  MvCustom,
  pvSm,
  phSm,
  spacerH,
  centerHorizontal,
  centerVertical,
};
