import React, { memo } from 'react';
import { SkywindJackpots } from '../../jackpotConstants';
import { JackpotTypes } from '../../jackpotTypes';
import SkywindAmountLimitJackpot from '../skywindCustomComponents/SkywindAmountLimitJackpot';
import SkywindDailyJackpot from '../skywindCustomComponents/SkywindDailyJackpot';
import SkywindHourlyJackpot from '../skywindCustomComponents/SkywindHourlyJackpot';

type Props = {
  jackpotId: string;
  levelName: string;
  levelDetails: JackpotTypes.CasinoLevel;
  children: React.ReactNode;
};

const SkywindJackpotLevel: React.FC<Props> = ({ jackpotId, levelName, levelDetails, children }) => {
  const renderComponentByLevelName = () => {
    switch (levelName) {
      case SkywindJackpots.AmountLimit:
        return (
          <>
            {children}
            <SkywindAmountLimitJackpot levelDetails={levelDetails} jackpotId={jackpotId} />
          </>
        );
      case SkywindJackpots.Hourly:
        return (
          <>
            {children}
            <SkywindHourlyJackpot levelDetails={levelDetails} jackpotId={jackpotId} />
          </>
        );
      case SkywindJackpots.Daily:
        return (
          <>
            {children}
            <SkywindDailyJackpot levelDetails={levelDetails} jackpotId={jackpotId} />
          </>
        );

      default:
        return null;
    }
  };

  return <div className={`skywind-custom ${levelName.toLocaleLowerCase()}`}>{renderComponentByLevelName()}</div>;
};

export default memo(SkywindJackpotLevel);
