import React, { useEffect } from 'react';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useTranslation } from 'react-i18next';
import { DataLoader } from 'components/shared/loaders';
import { Modal } from 'components/shared/Modal';
import { useShowMyProfileUntilUnmount } from 'hooks/common/useShowMyProfileUntilUnmount';
import { selectOffersTab } from 'shared/common/features/myProfile/sections/tabs/offersTab/slice/offersTab.slice';
import { BonusStatus } from 'shared/common/features/myProfile/sections/tabs/offersTab/types/offersTab.types';
import { detectIOS } from 'shared/common/features/myProfile/utils/myProfile.utils';
import { hideRootModal, selectRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import ParseHTML from 'shared/common/ParseHTML';
import { useAppDispatch, useAppSelector } from 'store';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';

type RenderComponentFunc = (text?: string, error?: string) => JSX.Element | null;

const statusComponentMap: Record<BonusStatus, RenderComponentFunc> = {
  pending: () => <DataLoader />,
  failure: (_, error) => <p>{error}</p>,
  succeeded: (text) => (
    <div>
      <ParseHTML html={text || ''} options={{ trim: true }} />
    </div>
  ),
  idle: () => null,
};

type Props = {
  id: string;
  text?: string;
};

const TermsOfferModal: React.FC<Props> = ({ text, id }) => {
  const dispatch = useAppDispatch();
  const modalType = useAppSelector(selectRootModal.type);
  const { status, error } = useAppSelector((state) => selectOffersTab.bonusStatusAndError(state, id));

  const { t } = useTranslation();
  useShowMyProfileUntilUnmount();
  const isIOS = detectIOS();

  useEffect(() => {
    const sctollTop = window.pageYOffset;
    if (isIOS && modalType) {
      document.body.classList.add('no-scroll-fixed');
      clearAllBodyScrollLocks();
    }

    return () => {
      if (isIOS) {
        document.body.classList.remove('no-scroll-fixed');
        window.scrollTo({ behavior: 'smooth', top: sctollTop });
      }
    };
  }, [modalType]);

  return (
    <Modal visible size="md" className="modal-terms">
      <Modal.Close closeText={t(MyProfile.OffersTab.closeBtn)} closeAction={() => dispatch(hideRootModal())} />
      <Modal.Body className="custom-scrollbars">{statusComponentMap[status](text, t(error || ''))}</Modal.Body>
    </Modal>
  );
};

export default TermsOfferModal;
