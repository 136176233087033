import React from 'react';
import styled from 'styled-components';

type Props = {
  linkText?: string;
  onClick: () => void;
};

export const Link: React.FC<Props> = ({ linkText, onClick }) => {
  if (!linkText) return null;
  return (
    <LinkSC onClick={onClick} className="popover__link">
      {linkText}
    </LinkSC>
  );
};
const LinkSC = styled('span')`
  cursor: pointer;
  pointer-events: all;
`;

export default Link;
