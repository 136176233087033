import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
// import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { SSBT } from 'utils/common/translationUtils/translationStrings';
import { TerminalModalUI } from './ssbtModalUI';

/** CloseModalButton */
interface CloseModalButtonProps {
  className?: string;
  depositStatus?: 'failure';
  onClick?: React.MouseEventHandler;
}

const CloseModalButton: React.FC<CloseModalButtonProps> = ({ className, depositStatus, onClick }) => {
  const { t } = useTranslation();

  // const { closeModal } = useHandleModals();

  const btnClass = clsx(className);

  return (
    <TerminalModalUI.Button
      variant={'secondary'}
      className={btnClass}
      onClick={onClick}
      text={
        depositStatus === 'failure'
          ? t(SSBT.TerminalModal.returnMoneyString)
          : t(SSBT.TerminalModal.closeTerminalModalText)
      }
    />
  );
};

export default CloseModalButton;
