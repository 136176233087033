import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCountDown } from 'modules/casino/shared/hooks/useCountDown';
import { addLeadingZero } from 'modules/casino/shared/utils/dateUtils';
import { Promotions } from 'modules/casino/shared/utils/translationUtils/translationStrings';
import CampaignInfoTimeCounterItem from './CampaignInfoTimeCounterItem';
import { SCTimeCounter, SCTimeCounterDisplay } from './styled/CampaignInfoTimeCounter.styled';
import { prizeDropCSSNS } from '../../PrizeDropCellWidgetConstants';

type Props = {
  date: number;
  text: string;
  isColumn?: boolean;
};

const CampaignInfoTimeCounter: React.FC<Props> = ({ date, text, isColumn }) => {
  const { t } = useTranslation();
  const { timer } = useCountDown(date, 'systemTime');

  return (
    <SCTimeCounter $isColumn={isColumn} className={`${prizeDropCSSNS}-counter-wrap`}>
      <div className={isColumn ? 'mb-1' : 'mr-2'}>{text}</div>
      <SCTimeCounterDisplay>
        {timer?.days !== undefined && (
          <CampaignInfoTimeCounterItem
            date={addLeadingZero(timer.days > 0 ? timer.days : 0)}
            text={t(Promotions.PrizeDrop.days)}
          />
        )}
        {timer?.hours !== undefined && (
          <CampaignInfoTimeCounterItem
            date={addLeadingZero(timer.hours > 0 ? timer.hours : 0)}
            text={t(Promotions.PrizeDrop.hours)}
          />
        )}
        {timer?.minutes !== undefined && (
          <CampaignInfoTimeCounterItem
            date={addLeadingZero(timer.minutes > 0 ? timer.minutes : 0)}
            text={t(Promotions.PrizeDrop.minutes)}
          />
        )}
        {timer?.seconds !== undefined && (
          <CampaignInfoTimeCounterItem
            date={addLeadingZero(timer.seconds > 0 ? timer.seconds : 0)}
            text={t(Promotions.PrizeDrop.seconds)}
          />
        )}
      </SCTimeCounterDisplay>
    </SCTimeCounter>
  );
};

export default CampaignInfoTimeCounter;
