import React, { memo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import { Text } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { selectLanguage } from 'shared/common/features/settings/slice/settings.slice';
import { useAppSelector } from 'store';
import { DesktopDown } from 'theme/Theme';
import { cdnConfig, fitCover } from 'utils/common/cdnConfig';
import config from 'utils/config';
import { LayoutConfigTypes } from '../../../general/types/generalSlice.types';
import useNavItemConfig from '../../hooks/useNavItemConfig';

const createImageSource = (imgUrl: string, height?: number, language?: string) => {
  return `url(${config.CDN_IMAGE_API}${fitCover('auto', height ? height : 'auto')}${
    cdnConfig.SIGNUP_BANNERS
  }/${language}__${imgUrl}`;
};

const NavUserBanner: React.FC = () => {
  const { t } = useTranslation();
  const isSearchView = useMatch('sports/search');
  const register = useNavItemConfig('registration');
  const language = useAppSelector(selectLanguage);
  const navigationConfig = useLayoutConfigType({ moduleLayout: 'platform', innerLayout: LayoutConfigTypes.NAVIGATION });
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);

  const subNav = navigationConfig?.subNav;
  const { onClick, banner } = register;

  if (!banner) return null;

  const { content, style } = banner;

  const { text: title, ...titleStyle } = content?.title || {};
  const { text: subtitle, ...subtitleStyle } = content?.subtitle || {};
  const { height, bgImage, bgColor, textBlockWidth, textBlockAlignment } = style || {};
  const backgroundImage = createImageSource(bgImage, height, language);
  const containerClass = clsx('w-100 signup-banner');
  const containerStyle = { backgroundImage, backgroundColor: bgColor, minHeight: height };
  const textBlockClass = { width: textBlockWidth, alignItems: textBlockAlignment };

  return subNav?.defaultUser && !isAuthenticated && !isSearchView ? (
    <DesktopDown>
      <div className={containerClass} style={containerStyle} onClick={onClick}>
        {(title || subtitle) && (
          <Flex.Col className="m-auto text-left" style={textBlockClass}>
            {title && <Text className="esports-slide__title" style={titleStyle} text={t(title)} />}
            {subtitle && <Text className="esports-slide__title-secondary" style={subtitleStyle} text={t(subtitle)} />}
          </Flex.Col>
        )}
      </div>
    </DesktopDown>
  ) : (
    <></>
  );
};

export default memo(NavUserBanner);
