import cell02StyleLoader from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell02/Cell02StyleLoader';
import {
  ComponentType,
  StyledNames,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/layoutWrapperConstants';
import useGameDescription from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/hooks/useGameDescription';
import { GridType } from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import { providerIdToSVG } from 'modules/casino/shared/utils/helpersCasino';

const Description = ({ gameUniqueId, categoryId }) => {
  const { description, gameProviderId, gridType } = useGameDescription({ gameUniqueId, categoryId });
  const DescriptionStyled = cell02StyleLoader(StyledNames.DescriptionStyled, ComponentType.data);
  const ProviderLogoStyled = cell02StyleLoader(StyledNames.ProviderLogoStyled, ComponentType.data);
  const MetaInfoStyled = cell02StyleLoader(StyledNames.MetaInfoStyled, ComponentType.data);

  return (
    <MetaInfoStyled $isSlider={gridType == GridType.SLIDER}>
      <DescriptionStyled className="text-truncate live-cell__descriptions">{description}</DescriptionStyled>
      {providerIdToSVG[gameProviderId] && <ProviderLogoStyled $icon={providerIdToSVG[gameProviderId]} />}
    </MetaInfoStyled>
  );
};
Description.displayName = 'Description';
export default Description;
