import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from 'components/shared';
import { hideRootModal, selectRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { MyAccountTranslation, MyProfile } from 'utils/common/translationUtils/translationStrings';
import { modalType, modalShowTime, selectPlayerWinInfo } from './slice/playerWinInfo.slice';
import { PlayerWinInfo } from './types/playerWinInfo.types';
import { Flex } from '../layout';
import { ModalUI } from '../ModalUI';
import { UI } from '../UI';

interface Props {
  style: { subheaderTextColor?: string; messageTextColor?: string };
}

const PlayerWinInfoModal: React.FC<PlayerWinInfo.SliceTypes & Props> = ({ ...props }): JSX.Element => {
  const rootModalType = useAppSelector(selectRootModal.type);
  const rootModalProps = useAppSelector(selectRootModal.props);
  const links = useAppSelector(selectPlayerWinInfo.links);
  const values = useAppSelector(selectPlayerWinInfo.values);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onClose = useCallback(() => dispatch(hideRootModal()), []);
  const modalRef = useRef(null);

  useEffect(() => {
    modalRef?.current &&
      rootModalType === modalType &&
      rootModalProps?.hasAutoClose &&
      setTimeout(() => {
        dispatch(hideRootModal());
      }, modalShowTime);
  }, [modalRef]);

  return (
    <Modal visible ref={modalRef} className={'player-info__modal'}>
      <Modal.Close closeText={t(MyProfile.OffersTab.closeBtn)} closeAction={onClose} />
      <Modal.Body className={'player-info__body no-scrollbars'}>
        <Flex.ColAC>
          <ModalUI.Title
            className={'player-info__header'}
            text={t(MyAccountTranslation.PlayerInfoPopup.Headers.first)}
          />
          <div className={'player-info__icon-circle'}>
            <span className="player-info__icon-content">18</span>
            <span className="player-info__icon-content--plus">+</span>
          </div>
          <ModalUI.Title
            className={'player-info__header-secondary'}
            text={t(MyAccountTranslation.PlayerInfoPopup.Headers.second)}
            style={{ color: props?.style?.subheaderTextColor }}
          />
          <div className={'player-info__text'} style={{ color: props?.style?.messageTextColor }}>
            {t(MyAccountTranslation.PlayerInfoPopup.Texts.main)}
            <Link className={'player-info__link'} to={links?.header} onClick={onClose}>
              {t(MyAccountTranslation.PlayerInfoPopup.Labels.linkMain)}
            </Link>
          </div>
          <Flex.ColCenter className={'player-info__table'}>
            <div className={'player-info__table-header w-100'}>
              {t(MyAccountTranslation.PlayerInfoPopup.Headers.table)}
            </div>
            {Object.keys(values).map((key, i) => (
              <Flex.ACJB key={i} className={'player-info__table-data w-100'}>
                <div>{t(MyAccountTranslation.PlayerInfoPopup.Texts[key])}</div>
                <div>
                  {values[key]
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                </div>
              </Flex.ACJB>
            ))}
          </Flex.ColCenter>
          <div className={'player-info__text'} style={{ color: props?.style?.messageTextColor }}>
            {t(MyAccountTranslation.PlayerInfoPopup.Texts.footer)}
            <Link className={'player-info__link'} to={links?.footer} onClick={onClose}>
              {t(MyAccountTranslation.PlayerInfoPopup.Labels.linkFooter)}
            </Link>
          </div>
          <UI.Button
            variant={'primary'}
            size={'xl'}
            className={'w-100'}
            onClick={links.button ? () => [onClose(), navigate(links.button)] : onClose}
            text={t(MyAccountTranslation.PlayerInfoPopup.Labels.button)}
          />
        </Flex.ColAC>
      </Modal.Body>
    </Modal>
  );
};

export default PlayerWinInfoModal;
