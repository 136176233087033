import React, { memo, useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ScrollWrapper } from 'modules/casino/shared/features/casinoScroll';
import { arrowStyleTypes } from 'modules/casino/shared/features/casinoScroll/casinoScrollTypes';
import { CasinoSearchTranslations } from 'modules/casino/shared/features/casinoSearch/casinoSearchConstants';
import { casinoLaunchSelectors } from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import useGeneralCasinoType from 'modules/casino/shared/hooks/useGeneralCasinoType';
import { useSetRefsLoaded } from 'modules/casino/shared/hooks/useSetRefsLoaded';
import { useAppSelector } from 'store';
import { ProvidersBarUI } from './components/ProvidersBarUI';
import { ProvidersBarPosition } from './constants/casinoGameProvidersConstants';
import { useProviderAliasesByVertical } from './hooks/useProviderAliasesByVertical';
import { gameProvidersSelectors } from './slice/casinoGameProvidersSlice';

interface ProvidersBarProps {
  inDrawer?: boolean;
  position?: ProvidersBarPosition;
  filterFunctionality?: boolean;
}

const CasinoGameProviders: React.FC<ProvidersBarProps> = ({ inDrawer, position, filterFunctionality = false }) => {
  const { casinoType } = useGeneralCasinoType();
  const show = useAppSelector(casinoLaunchSelectors.showComponents);
  const providersByVertical = useProviderAliasesByVertical(casinoType);
  const allProviders = useAppSelector(gameProvidersSelectors.allProviderAliases);
  const { t } = useTranslation();

  const activeClass = 'providers-bar__item--active';

  const providersComponents = useMemo(() => {
    if (filterFunctionality) {
      return allProviders.map((providerAlias, index) => {
        return (
          <ProvidersBarUI.ItemFilter
            key={`${providerAlias}_${index}`}
            inDrawer={inDrawer}
            providerAlias={providerAlias}
          />
        );
      });
    } else {
      return providersByVertical.map((providerAlias, index) => {
        return (
          <ProvidersBarUI.Item
            key={`${providerAlias}_${index}`}
            inDrawer={inDrawer}
            destination={'providers-bar'}
            providerAlias={providerAlias}
            index={index}
          />
        );
      });
    }
  }, [inDrawer, providersByVertical, allProviders]);

  const scrollContainerStyles = clsx('providers-bar__wrapper', 'no-scrollbars', !show && 'hidden');
  const innerClass = clsx('scroll-container-inner', scrollContainerStyles);
  const { refLoaded, setRefs } = useSetRefsLoaded();

  return (
    <>
      <ProvidersBarUI.Wrapper activeClass={activeClass} refLoaded={refLoaded} position={position}>
        {filterFunctionality && <div className="providers-search__label">{t(CasinoSearchTranslations.filter)}</div>}
        <ScrollWrapper typeArrow={arrowStyleTypes.CasinoMenus} useDefaultChevron moveWith={300}>
          <div className={innerClass} id={'providers-scroll-container'} ref={setRefs}>
            <ProvidersBarUI.Spacer />
            {providersComponents}
            <ProvidersBarUI.Spacer />
          </div>
        </ScrollWrapper>
        {!filterFunctionality && <ProvidersBarUI.SearchIcon />}
      </ProvidersBarUI.Wrapper>
    </>
  );
};
export default memo(CasinoGameProviders);
