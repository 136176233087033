import { keyframes } from 'styled-components';

const swipeDown = keyframes`
  0%  {
}
5%  {
      opacity: 1;
    top: 0;
    transform:scale(0.9);

  }
 
  50% {
    top: 50px;
  }
  90% {
    top: 50px;
    opacity: 1;
  }
  
	100%  {
    top: 0px;
  }
`;

const hold = keyframes`
  0%  {
    opacity: 1;
    transform:scale(0.8);

  }
  
  95%{
    transform:scale(0.8);
  }
  98% {
    opacity: 1;
    transform:scale(1);
  }
  
  100% {
    transform:scale(1);

  }
  
  `;

const click = keyframes`
  0%  {
    opacity: 1;
    transform:scale(1);
  }
  50% {
    transform:scale(1);
  }
  70% {
    transform:scale(0.8);
  }
  76% {
    opacity: 1;
    transform:scale(1);
  }
  
  100% {
    transform:scale(1);

  }
  
  `;

export const AnimationHand = { Hold: hold, Click: click, SwipeDown: swipeDown };
export const ClipAnimation = (top: number, left: number, width: number, height: number) => keyframes`
    0% {
      /* opacity: 0; */
      clip-path: polygon(
        0% 0%,
        0% 100%,
        ${left + width / 2}px 100%,
        ${left + width / 2}px ${top + height / 2}px,
        ${left + width / 2}px ${top + height / 2}px,
        ${left + width / 2}px ${top + height / 2}px,
        ${left + width / 2}px ${top + height / 2}px,
        ${left + width / 2}px 100%,
        100% 100%,
        100% 0%
      );
    }

    100% {
      /* opacity: 1; */
      clip-path: polygon(
        0% 0%,
        0% 100%,
        ${left}px 100%,
        ${left}px ${top}px,
        ${left + width}px ${top}px,
        ${left + width}px ${top + height}px,
        ${left}px ${top + height}px,
        ${left}px 100%,
        100% 100%,
        100% 0%
      );
    }
`;

const ClipCircleAnimationSmall = () => keyframes`
    20% {
      clip-path:  circle(0px at 50% 120%);
    }
    100% {
      
      clip-path:  circle(120px at 50% 90%);

    }
`;
const ClipCircleAnimation = () => keyframes`
    20% {
      clip-path:  circle(0px at 50% 120%);
    }
    100% {
      clip-path:  circle(162px at 50% 90%);
    }
`;
const textAnimation = () => keyframes`
    75% {
      opacity:0;
      transform: translateX(300px);
    }
    100% {
      transform: translateX(0);
      opacity:1;
    }
`;
export const InfoAnimation = {
  ClipCircle: ClipCircleAnimation,
  ClipCircleSmall: ClipCircleAnimationSmall,
  Text: textAnimation,
};
