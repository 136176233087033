import { memo } from 'react';
import EasterEggItem from './EasterEggItem';
import { NotificationTypes } from '../../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import { GamificationModalUI } from '../../components/GamificationModalUI';
import useWinItemState from '../../hooks/useWinItemState';

type Props = {
  setAnimationComplete: (isVisible: boolean) => void;
  animationComplete: boolean;
  animationParams: NotificationTypes.GamificationModalParams;
  notification: NotificationTypes.PopUpNotification;
  folderName: number | string;
  animationAutoTrigger: boolean;
  bonusAlignment?: NotificationTypes.BonusAlignment;
};

const EasterEggItems: React.FC<Props> = ({
  animationComplete,
  animationParams,
  setAnimationComplete,
  notification,
  folderName,
  animationAutoTrigger,
  bonusAlignment,
}) => {
  const { bonusData } = notification;

  const { state, handlers } = useWinItemState();

  if (!bonusData) return null;

  return (
    <GamificationModalUI.Layout layout={animationParams.layout}>
      <EasterEggItem
        index={0}
        notification={notification}
        winItemIndex={state.winItemIndex}
        setWinItemIndex={handlers.setWinItemIndex}
        setAnimationComplete={setAnimationComplete}
        animationComplete={animationComplete}
        animationParams={animationParams}
        folderName={folderName}
        animationAutoTrigger={animationAutoTrigger}
        bonusAlignment={bonusAlignment}
      />
    </GamificationModalUI.Layout>
  );
};

export default memo(EasterEggItems);
