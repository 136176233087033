import NotificationPopupBody from './NotificationPopupBody';
import NotificationPopupFooter from './NotificationPopupFooter';
import NotificationPopupHeader from './NotificationPopupHeader';
import NotificationPopupModal from './NotificationPopupModal';

export const NotificationPopupUI = Object.assign(NotificationPopupModal, {
  header: NotificationPopupHeader,
  body: NotificationPopupBody,
  footer: NotificationPopupFooter,
});
