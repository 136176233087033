import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { SSBT } from 'utils/common/translationUtils/translationStrings';
// import LastStepTicketModal from './LastStepTicketModal';
import SSBTLastStepTicketModal from './SSBTLastStepTicketModal';
import { selectTicketData } from '../../../../../shared/slice/checkTicket.slice';
import { TerminalModalUI } from '../../../components/ssbtModalUI';
import { selectSSBTdata, setActiveStep, setBarcodeSelected } from '../../../slice/ssbt.slice';
import { CheckTicket } from '../../../types/ssbt.types';

type Props = {
  ticketInfo: CheckTicket | null;
  handleNextStep: () => void;
};

const SecondStepTicketModal: React.FC<Props> = ({ ticketInfo, handleNextStep }) => {
  const dispatch = useAppDispatch();
  const isTicketSelected = useAppSelector(selectSSBTdata.isTicketSelectedFromBarcodeReader);
  const status = useAppSelector(selectTicketData.ticketInfoStatus);

  const { t } = useTranslation();

  useEffect(() => {
    if (status !== 'idle') {
      dispatch(setActiveStep(1));
    }
    dispatch(setBarcodeSelected(false));
  }, [isTicketSelected]);

  const renderTerminalModal = () => {
    switch (status) {
      case 'pending':
        return (
          <>
            <TerminalModalUI.Body className={''}>
              <TerminalModalUI.Status
                className="mb-0"
                status="pending"
                text={t(SSBT.TerminalModal.requestTerminalText)}
              />
            </TerminalModalUI.Body>
          </>
        );
      case 'succeeded':
        return (
          <TerminalModalUI.Body className={'terminal-modal--ticket'}>
            {/* SSBT */}
            <SSBTLastStepTicketModal ticketInfo={ticketInfo} closeTerminalModal={handleNextStep} />
          </TerminalModalUI.Body>
        );
      case 'failure':
        return (
          <TerminalModalUI.Body className={''}>
            <TerminalModalUI.Status
              className="mb-0"
              status="error"
              text={t(SSBT.TerminalModal.requestRejectTerminal)}
            />
          </TerminalModalUI.Body>
        );
    }
  };
  const renderTicketInfoContent = () => {
    switch (status) {
      case 'failure':
        return (
          <TerminalModalUI.Body className={''}>
            <TerminalModalUI.Status
              className="mb-0"
              status="error"
              text={t(SSBT.TerminalModal.requestRejectTerminal)}
            />
          </TerminalModalUI.Body>
        );
      default:
        return isEmpty(ticketInfo) ? (
          <>
            <TerminalModalUI.Body className={''}>
              <TerminalModalUI.Status
                className="mb-0"
                status="pending"
                text={t(SSBT.TerminalModal.requestTerminalText)}
              />
            </TerminalModalUI.Body>
          </>
        ) : (
          <TerminalModalUI.Body className={'terminal-modal--ticket'}>
            <SSBTLastStepTicketModal ticketInfo={ticketInfo} />
          </TerminalModalUI.Body>
        );
    }
  };
  return <>{!isTicketSelected ? renderTicketInfoContent() : renderTerminalModal()}</>;
};
export default SecondStepTicketModal;
