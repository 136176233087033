import { createAction } from '@reduxjs/toolkit';
import { CasinoNavigationTypes } from 'modules/casino/modules/navigation/shared/types/casinoNavigationTypes';
import { PlayerRestrictions } from 'pages/myAccount/tabs/responsibleGambling/types/responsibleGambling.types';
import { DeviceType } from 'utils/common/constants';
import { Balance, User } from '../features/myProfile/types/myProfile.types';

export const setCurrentUser = createAction<User>('setCurrentUser');
export const setLanguage = createAction<string>('setLanguage');
export const setActiveCasinoType = createAction<string | null>('setActiveCasinoType');
export const setActiveCasinoCategory = createAction<string>('setActiveCasinoCategory');
export const setActiveCasinoVertical =
  createAction<CasinoNavigationTypes.ActiveVerticalType>('setActiveCasinoVertical');
export const loginUser = createAction('loginUser');
export const logoutUser = createAction('logoutUser');
export const setDeviceType = createAction<DeviceType>('setDeviceType');
export const logoutOnError = createAction('logoutOnError');
export const setPlayerRestrictions = createAction<PlayerRestrictions.Restriction[]>('setPlayerRestrictions');
export const hardwareBackPress = createAction('hardwareBackPress');
export const setUserBalance = createAction<Balance | null>('setUserBalance');
// actions for widget, not acting as widget
export const setTimeZoneOffset = createAction<number | string>('setTimeZoneOffset');
export const setInitialLanguage = createAction<string>('setInitialLanguage');
export const setAccessToken = createAction<string>('setAccessToken');
export const setIsSingleBetSelection = createAction<boolean>('setIsSingleBetSelection');
