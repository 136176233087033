import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCurrency } from 'hooks/common/useCurrency';
import { useIsUnmounted } from 'hooks/common/useIsUnmouted';
import { loginThunks, selectAuthentication, showQuickLoginForm } from 'pages/auth/login/slice/login.slice';
import userStorage from 'pages/auth/login/UserStorage';
import { selectAppHeader } from 'shared/common/features/appHeader';
import { selectBottomTabBar } from 'shared/common/features/bottomTabBar/slice/bottomTabBar.slice';
import {
  selectIntegrations,
  setIsGamificationWidgetsLoaded,
  setIsGamificationWidgetsMyChallenges,
  setIsGamificationWidgetsMyGameChallenges,
  setIsGamificationWidgetsProfile,
} from 'shared/common/features/integrations/slice/integrations.slice';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { selectLanguage } from 'shared/common/features/settings/slice/settings.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { getWindowConfigParam, isProductionBuild, isEmpty } from 'utils/common/helpersCommon';
import { getActiveDevEnvAndBU } from 'utils/config';
import useGamificationWidget from './hooks/useGamificationWidget';
import { Config, GamificationInitsType, WidgetInitTypes } from './types/gamification.types';

const isDevelopment = !isProductionBuild;
const BU = isDevelopment ? getActiveDevEnvAndBU(0) : getWindowConfigParam('BUSINESS_UNIT');
const ENV_TYPE = isDevelopment ? getActiveDevEnvAndBU(1) : getWindowConfigParam('ENV_TYPE');
const channel = getWindowConfigParam('CHANNEL');
const device = getWindowConfigParam('DEVICE');
const env = ENV_TYPE === 'PRODUCTION' ? '' : `-${ENV_TYPE}`;
const clientId = `${BU}${env}${channel ? `-${channel}-${device}` : ''}`.toLowerCase();

type Props = { gamificationInits: GamificationInitsType };

export const GamificationWidgetInit = ({ gamificationInits }: Props) => {
  const { currencyCode } = useCurrency();
  const language = useAppSelector(selectLanguage);
  const timeZoneOffset = userStorage.getUserTimeZone();
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const refreshTokenLoading = useAppSelector(selectAuthentication.refreshTokenLoading);
  const token = userStorage.getUser()?.access_token;
  const fixedBottom = useAppSelector(selectBottomTabBar.height);
  const preferredTheme = useAppSelector(selectMyProfile.preferredTheme);
  const preferredOddTypeId = useAppSelector(selectMyProfile.preferredOddTypeId);
  const fixedTop = 50;
  const appHeaderHeight = useAppSelector(selectAppHeader.height);
  const appMainNavHeight = useAppSelector(selectAppHeader.appMainNavHeight);
  const ianaTimezone = userStorage.getUserIanaTimeZoneCode();
  const loginFormVisibility = useAppSelector(selectAuthentication.quickLoginFormVisibility);
  const isScriptLoaded = useAppSelector(selectIntegrations.isGamificationScriptLoaded);
  const isWidgetLoaded = useAppSelector(selectIntegrations.isGamificationWidgetLoaded);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const isUnmount = useIsUnmounted();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useGamificationWidget();

  const onLoad = () => dispatch(setIsGamificationWidgetsLoaded(true));
  const onShowLogin = (params) => dispatch(showQuickLoginForm(params));
  const logoutFromWidget = () => dispatch(loginThunks.logout());
  const onNavigationChange = (navParams) =>
    navParams.page === 'back'
      ? navigate(navParams.params.back)
      : navigate(navParams.location.fullPath, { replace: navParams.options?.replaceCurrentHistory === true });
  const setGamificationProfile = (profile) => dispatch(setIsGamificationWidgetsProfile(profile));
  const onMyGamesChallengesChange = ({ biggestPercentage, challengeUpTo100 }) =>
    dispatch(setIsGamificationWidgetsMyGameChallenges({ biggestPercentage, challengeUpTo100 }));
  const onMyChallengesCountChange = (count) => {
    dispatch(setIsGamificationWidgetsMyChallenges(count));
  };

  const widgetListenersMap = {
    [WidgetInitTypes.GAMIFICATION]: {
      onGamificationProfileChange: setGamificationProfile,
      onMyChallengesCountChange,
      onMyGamesChallengesChange,
    },
    [WidgetInitTypes.LOTTO]: {},
  };
  const getWidgetListeners = () =>
    !isEmpty(gamificationInits)
      ? Object.keys(gamificationInits).reduce(
          (widgetListeners, key) =>
            gamificationInits[key].isVisible && widgetListenersMap[key]
              ? { ...widgetListeners, ...widgetListenersMap[key] }
              : widgetListeners,
          {},
        )
      : widgetListenersMap['gamification'];

  const initialRoute = `${location.pathname}${location.search}${location.hash}`;
  useEffect(() => {
    if (wrapperRef) {
      if (isScriptLoaded && window.XNaveGamification) {
        const config: Config = {
          clientId,
          params: {
            preferredTheme,
            language,
            initialRoute,
            timeZoneOffset,
            fixedBottom: 52,
            fixedTop,
            token,
            ianaTimezone,
            loginFormVisibility,
          },
          listeners: {
            //************** Documentation
            onLoad,
            onShowLogin,
            // onLogin, // return when user is login in gamification
            onLogout: logoutFromWidget,
            onNavigationChange,
            ...getWidgetListeners(),
          },
        };

        window.widgetGamification = window.XNaveGamification?.({
          ...(gamificationInits ? { type: [...Object.keys(gamificationInits)] } : ['gamification']),
          config,
        });
        window.widgetGamification && window.widgetGamification.init();
      }

      return () => {
        if (isUnmount() && window.widgetGamification) {
          window.widgetGamification.destroy();
          window.widgetGamification = undefined;
          dispatch(setIsGamificationWidgetsLoaded(false));
        }
      };
    }
  }, [isScriptLoaded]);

  useEffect(() => {
    const config = {
      language,
      token,
      timeZoneOffset,
      fixedBottom,
      initialRoute,
      fixedTop,
      appHeaderHeight,
      appMainNavHeight,
      loginFormVisibility,
    };
    isWidgetLoaded && window.widgetGamification?.changeConfig(config);
  }, [
    appHeaderHeight,
    appMainNavHeight,
    currencyCode,
    fixedBottom,
    isWidgetLoaded,
    language,
    preferredOddTypeId,
    preferredTheme,
    timeZoneOffset,
    token,
    refreshTokenLoading,
    loginFormVisibility,
    initialRoute,
  ]);

  useEffect(() => {
    !isAuthenticated && window.widgetGamification?.logout();
  }, [isAuthenticated]);

  return null;
};
