import { useCallback, useEffect, useState } from 'react';
import { isEmpty, localLog } from 'utils/common/helpersCommon';
import { CDN, CDNResponse } from '../utils/CDN';

type PreloadConfig<T> = {
  module: T;
  loadingFinished?: boolean;
  errorCallback?: () => void;
};

export const usePreloadAssets = <T extends object>({
  module,
  loadingFinished = true,
  errorCallback,
}: PreloadConfig<T>): {
  percents: number;
  imagesLoadingCompleted: boolean;
  imagesLoadedCount: number;
} => {
  const [percents, setPercents] = useState<number>(0);
  const [imagesCount, setImagesCount] = useState<number>(0);
  const [imagesLoadedCount, setImagesLoadedCount] = useState<number>(0);

  useEffect(() => {
    if (!loadingFinished) return;
    // Empty/undefined modules should not be processed
    if (!module || isEmpty(module)) return;
    processArray(module, incrementImagesCount);
    processArray(module, cacheModuleImages);
  }, [module, loadingFinished]);

  useEffect(() => {
    setPercents(100 * (imagesLoadedCount / imagesCount));
  }, [imagesLoadedCount, imagesCount]);

  const incrementImagesCount = useCallback((assetsModule: T) => {
    const objectLength = Object.keys(assetsModule).length;
    setImagesCount((c) => c + objectLength);
  }, []);

  const incrementLoadedImages = useCallback(async () => {
    await setImagesLoadedCount((c) => c + 1);
  }, []);

  const incrementLoadedImagesWith = useCallback(async (number) => {
    await setImagesLoadedCount((c) => c + number);
  }, []);

  const processArray = (assetsModule: T, func: (assetsModuleToProcess: T) => void | Promise<void>) => {
    if (Array.isArray(assetsModule)) {
      for (let i = 0; i < assetsModule.length; i++) {
        const element = assetsModule[i];
        processArray(element, func);
      }
    } else {
      func(assetsModule);
    }
  };

  const loadCDN = ([key, value]: [string, CDN], retryCount = 0): Promise<void | CDNResponse | undefined> => {
    window.loadedCDNs = [];

    return value
      .load(key, retryCount)
      .then((res: CDNResponse) => {
        if (res) {
          window.loadedCDNs.push(res);
        } else {
          errorCallback && errorCallback();
          throw new Error('result is not defined');
        }
      })
      .catch(async (err) => {
        if (retryCount >= 5) {
          await incrementLoadedImages();
          return;
        }
        if (typeof err === 'string' || err instanceof String) {
          localLog({ message: err, type: 'error' });
        } else {
          errorCallback && errorCallback();
          return value.load(key, retryCount + 1);
        }
      });
  };

  const cacheModuleImages = async (picturesToCache: T) => {
    const assetsToCachePromises = Object.entries(picturesToCache).map(([key, value]: [string, CDN]) =>
      loadCDN([key, value]),
    );
    await Promise.all(assetsToCachePromises).then(async (results) => {
      await incrementLoadedImagesWith(results.length);
    });
  };

  return {
    percents: Math.round(percents),
    // Empty modules are with imagesLoadingCompleted true
    imagesLoadingCompleted: Math.ceil(percents) >= 100 || isEmpty(module),
    imagesLoadedCount,
  };
};

export default usePreloadAssets;
