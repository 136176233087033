import styled from 'styled-components';

const StyledCardMeta = styled.div.attrs({ className: 'prm-lobby-card__meta' })`
  display: flex;
  align-items: center;
  margin-top: auto;
  min-height: 34px;
`;

export default StyledCardMeta;
