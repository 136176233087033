import styled from 'styled-components';
import cell01StyleLoader from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell01/Cell01StyleLoader';
import {
  ComponentType,
  StyledNames,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/layoutWrapperConstants';
import useGameDescription from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/hooks/useGameDescription';
import { providerIdToSVG } from 'modules/casino/shared/utils/helpersCasino';

const Description = ({ gameUniqueId, categoryId }) => {
  const { description, gameProviderId } = useGameDescription({ gameUniqueId, categoryId });
  const DescriptionStyled = cell01StyleLoader(StyledNames.DescriptionStyled, ComponentType.dataAlternative);
  const ProviderLogoStyled = cell01StyleLoader(StyledNames.ProviderLogoStyled, ComponentType.data);

  return (
    <DescriptionWrapperStyled>
      {providerIdToSVG[gameProviderId] && <ProviderLogoStyled $icon={providerIdToSVG[gameProviderId]} />}

      <DescriptionStyled className="text-truncate live-cell__descriptions">{description}</DescriptionStyled>
    </DescriptionWrapperStyled>
  );
};

const DescriptionWrapperStyled = styled.div`
  text-align: center;
`;
Description.displayName = 'Description';
export default Description;
