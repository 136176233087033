import React from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';

/** DrawerUI Section */
const drawerSectionsCSSMap = {
  sport: '',
  sportContainers: '',
  liveSport: '',
  casino: '',
  help: '',
} as const;

type DrawerSectionTypes = keyof typeof drawerSectionsCSSMap;

interface SectionProps {
  type: DrawerSectionTypes;
  isActive: boolean;
  children: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ isActive, children }) => {
  const sectionClass = clsx('drawer-section', isActive && 'drawer-section--active');
  return <Flex.Col className={sectionClass}>{children}</Flex.Col>;
};

export default Section;
