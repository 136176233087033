import React, { Suspense } from 'react';
import cell03ComponentLoader from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell03/Cell03ComponentLoader';
import cell03StyleLoader from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell03/Cell03StyleLoader';
import { LayoutTypes } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/LayoutTypes';
import {
  ComponentNames,
  ComponentType,
  StyledNames,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/layoutWrapperConstants';
import { SharedComponents } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared';
import { useShowGameTitle } from '../../../../../shared/hooks/useShowGameTitle';

const GameCellWrapper: React.FC<LayoutTypes.GameCellWrapperProps> = ({
  gameUniqueId,
  categoryId,
  layoutGrid,
  subType,
}) => {
  const Description = cell03ComponentLoader(ComponentNames.Description, ComponentType.data);
  const GameCellWrapperStyled = cell03StyleLoader(StyledNames.GameCellWrapperStyled, ComponentType.data);
  const { showGameTitle } = useShowGameTitle({ layoutGrid, subType });

  return (
    <GameCellWrapperStyled className={'casino-game-cell casino-game-cell--data'}>
      <Suspense>
        <SharedComponents.GameCellTablePlayers gameUniqueId={gameUniqueId} categoryId={categoryId} />
        <SharedComponents.GameCellTags
          gameUniqueId={gameUniqueId}
          categoryId={categoryId}
          className={`cell-tag-bottom--mixin cell-tag-bottom--layout_3`}
        >
          <SharedComponents.GameCellTableLimits gameUniqueId={gameUniqueId} categoryId={categoryId} />
        </SharedComponents.GameCellTags>
        <SharedComponents.GameCellTableCharacters
          gameUniqueId={gameUniqueId}
          categoryId={categoryId}
          classFromLayout={'mix-grid-character'}
          layoutStyleLoader={cell03StyleLoader}
        />
        {showGameTitle && <Description gameUniqueId={gameUniqueId} categoryId={categoryId} />}
      </Suspense>
    </GameCellWrapperStyled>
  );
};

export default GameCellWrapper;
GameCellWrapper.displayName = 'GameCellWrapper';
