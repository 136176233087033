import { createSlice, PayloadAction, isAnyOf, createSelector } from '@reduxjs/toolkit';
// import axios from 'axios';
// import userStorage from 'pages/auth/login/UserStorage';
// import { selectDeviceType } from 'shared/common/features/general/slice/deviceType.slice';
import { logoutUser, loginUser } from 'shared/common/sharedSlices/commonActions';
import { AppDispatch } from 'store';
import { RootState } from 'store/rootReducer';
// import { getHashHeader } from 'utils/common/axios-instance';
// import { filterRoutingKeys, getBusinessUnit, isEmpty, isTerminal, localLog } from 'utils/common/helpersCommon';
import { filterRoutingKeys, isEmpty, localLog } from 'utils/common/helpersCommon';
// import config from 'utils/config';
import { WsComponent, WsStatusType, WsSubscriptions } from '../types/webSockets.types';

export type WebsocketState = {
  status: WsStatusType;
  session: string | null;
  subscriptions: WsSubscriptions;
  addKeys: string[];
  removeKeys: string[];
};

const initialState: WebsocketState = {
  status: 'notStarted',
  session: '',
  subscriptions: {},
  addKeys: [],
  removeKeys: [],
};

const webSockets = createSlice({
  name: 'webSockets',
  initialState,
  reducers: {
    setStatus(state, action: PayloadAction<WsStatusType>) {
      if (state.status === 'notStarted' && action.payload === 'close') {
        return;
      }
      state.status = action.payload;
    },
    setSession(state, action: PayloadAction<string | null>) {
      state.session = action.payload;
    },
    registerKeys(state, action: PayloadAction<{ keys: string[]; component: WsComponent }>) {
      const { keys, component } = action.payload;

      keys?.forEach((key) => {
        if (state.subscriptions[key]) {
          state.subscriptions[key].push(component);
        } else {
          state.subscriptions[key] = [component];
        }
      });
    },
    unregisterKeys(state, action: PayloadAction<{ keys: string[]; component: WsComponent }>) {
      const { keys, component } = action.payload;

      keys?.forEach((key) => {
        if (state.subscriptions[key]) {
          if (state.subscriptions[key].includes(component)) {
            const index = state.subscriptions[key].indexOf(component);
            state.subscriptions[key].splice(index, 1);
          }
        }
      });

      Object.keys(state.subscriptions).forEach(
        (key) => isEmpty(state.subscriptions[key]) && delete state.subscriptions[key],
      );
    },

    unregisterKeysAndComponents(state, action: PayloadAction<WsSubscriptions>) {
      const routingKeysWithComponents = action.payload;

      for (const [keys, values] of Object.entries(routingKeysWithComponents)) {
        const key = state.subscriptions[keys];
        if (key) {
          values.forEach((component) => {
            const elIndex = key.findIndex((el) => el === component);

            if (elIndex > -1) {
              state.subscriptions[keys].splice(elIndex, 1);
            }
          });
        }
      }

      Object.keys(state.subscriptions).forEach(
        (key) => isEmpty(state.subscriptions[key]) && delete state.subscriptions[key],
      );
    },
    setNewRoutingKeys: (state, action) => {
      const { keys, type } = action.payload;
      type === 'subscribe' ? state.addKeys.push(...keys) : state.removeKeys.push(...keys);
    },
    filtersRoutingKeys: (state, action) => {
      const { keys, type } = action.payload;
      const currentAddKeys = state.addKeys;
      const currentRemoveKeys = state.removeKeys;

      if (type === 'addKeys') {
        state.addKeys = filterRoutingKeys(currentAddKeys, keys);
      } else {
        state.removeKeys = filterRoutingKeys(currentRemoveKeys, keys);
      }
    },
    resetSubs(state) {
      state.subscriptions = {};
      state.addKeys = [];
      state.removeKeys = [];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(logoutUser, loginUser), (state) => {
      state.subscriptions = {};
      state.status = 'notStarted';
      state.session = null;
      state.addKeys = [];
      state.removeKeys = [];
    });
  },
});

const {
  setStatus,
  setSession,
  registerKeys,
  unregisterKeys,
  resetSubs,
  unregisterKeysAndComponents,
  filtersRoutingKeys,
  setNewRoutingKeys,
} = webSockets.actions;
export default webSockets.reducer;

// type RoutingApiParams = {
//   getState: () => RootState;
//   keys: string[];
//   method: 'POST' | 'DELETE';
//   session: string | null;
// };

// const callRoutingApi = async ({ getState, keys, method, session }: RoutingApiParams) => {
//   if (!session) return undefined;
//   const user = userStorage.getUser();
//   const deviceType = isTerminal() ? 'terminal' : selectDeviceType.deviceType(getState());
//   const url = `${config.API_URL}/api/websocket/public/queues/${session}/routingkeys`;
//   const { hash, expiry } = getHashHeader();

//   const response = await axios({
//     method,
//     url: url,
//     data: JSON.stringify({ keys }),
//     headers: {
//       'Content-type': 'application/json',
//       'X-Platform-Origin': getBusinessUnit(),
//       'X-Platform-Device': deviceType,
//       ...(user && { Authorization: `Bearer ${user.access_token}` }),
//       'X-Platform-Hash': hash,
//       'X-Platform-Exp': String(expiry),
//     },
//   });
//   return response;
// };

const repeatableComponents = ['BetSlip', 'MyBets']; //component names that are allowed to assign multiple times to the same route
const nonUnsubscribable = ['ProducerStatus', 'Jackpots', 'Player'];
const filterKeys = (subscriptions: WsSubscriptions, key: string, component: WsComponent) => {
  return !subscriptions[key]?.includes(component) || repeatableComponents.includes(component);
};

export const ws = {
  setSession,
  setStatus,
  resetSubs,
  selectSubscriptions: (state: RootState): WsSubscriptions => state.common.webSockets.subscriptions,
  selectStatus: (state: RootState): WsStatusType => state.common.webSockets.status,
  selectSession: (state: RootState): string | null => state.common.webSockets.session,
  filtersRoutingKeys,
  selectRemoveKeys: (state: RootState) => state.common.webSockets.removeKeys,
  selectAddKeys: (state: RootState) => state.common.webSockets.addKeys,
  selectRoutingKeys: createSelector(
    (state: RootState) => state.common.webSockets.subscriptions,
    (subscriptions) => Object.keys(subscriptions),
  ),
  subscribe:
    (keys: string[], component: WsComponent, hasSubApiCall = true) =>
    async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
      const session = earlySessionCheck(getState, 'subscribing');
      if (!session) {
        return;
      }

      const subscriptions = ws.selectSubscriptions(getState());

      const filteredKeys = keys.filter((key) => filterKeys(subscriptions, key, component));

      if (isEmpty(filteredKeys)) {
        return;
      }

      dispatch(registerKeys({ keys: filteredKeys, component }));
      if (!hasSubApiCall) return;

      dispatch(setNewRoutingKeys({ type: 'subscribe', keys: filteredKeys }));

      // const callback = async () => await callRoutingApi({ getState, keys: filteredKeys, method: 'POST', session });
      // const callbackFail = () => {
      //   dispatch(unregisterKeys({ keys: filteredKeys, component }));
      //   localLog(
      //     `⚠️ %c~ Removing subscription keys from WebsocketSlice, because of POST API failure ${filteredKeys}`,
      //     'warn',
      //   );
      // };
      // Queue.enqueue(async () => await retryCallback({ callback, callbackFail }));
    },

  unsubscribe:
    (keys: string[], component: WsComponent, hasSubApiCall = true) =>
    async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
      const session = earlySessionCheck(getState, 'unsubscribing');
      if (!session) {
        return;
      }
      dispatch(unregisterKeys({ keys, component }));
      const subscriptions = ws.selectSubscriptions(getState());
      const keysToDelete: string[] = [];
      keys?.forEach((k) => {
        if (!subscriptions[k]) {
          keysToDelete.push(k);
        }
      });
      if (isEmpty(keysToDelete) || !hasSubApiCall) {
        return;
      }

      dispatch(setNewRoutingKeys({ type: 'delete', keys: keysToDelete }));

      // const callback = async () => await callRoutingApi({ getState, keys: keysToDelete, method: 'DELETE', session });
      // Queue.enqueue(callback);
    },

  unsubscribeUrlRedirect:
    () =>
    async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
      const wsSubscriptions: WsSubscriptions = getState().common.webSockets.subscriptions;
      const keysToDelete: string[] = [];
      const componentsToDelete: WsSubscriptions = {};

      for (const [keys, values] of Object.entries(wsSubscriptions)) {
        if (!values.some((component) => nonUnsubscribable.includes(component))) {
          keysToDelete.push(keys);
        }

        values.forEach((component) => {
          if (!nonUnsubscribable.includes(component)) {
            if (componentsToDelete[keys]) {
              componentsToDelete[keys] = [...componentsToDelete[keys], component];
            } else {
              componentsToDelete[keys] = [component];
            }
          }
        });
      }

      if (isEmpty(componentsToDelete) && isEmpty(keysToDelete)) {
        return;
      }

      dispatch(unregisterKeysAndComponents(componentsToDelete));
      // await callRoutingApi({ getState, keys: keysToDelete, method: 'DELETE' });
      // const callback = async () => await callRoutingApi({ getState, keys: keysToDelete, method: 'DELETE' });
      // Queue.enqueue(callback);
    },
};

const earlySessionCheck = (getState: () => RootState, subOrUnsub: 'subscribing' | 'unsubscribing'): string | null => {
  const session = ws.selectSession(getState());
  if (!session) {
    localLog({
      message: `⚠️ %c~ You tried ${subOrUnsub} when there is no session. This indicates a wrongful websocket flow`,
      type: 'warn',
    });
  }
  return session;
};
