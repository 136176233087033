import Cell03Data from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell03/components/data';
import Cell03Default from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell03/components/default';
import {
  ComponentNames,
  ComponentType,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/layoutWrapperConstants';

const components = {
  [ComponentNames.GameCellWrapper]: {
    [ComponentType.default]: Cell03Default.GameCellWrapper,
    [ComponentType.data]: Cell03Data.GameCellWrapper,
  },
  [ComponentNames.Description]: {
    [ComponentType.data]: Cell03Data.Description,
  },
};

const cell03ComponentLoader = (componentName, componentType) => {
  return components[componentName][componentType];
};

export default cell03ComponentLoader;
