import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

/** Cashier Modal Input Wrapper Section */
interface InputWrapperProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: React.ReactNode;
  spanAllColumns?: boolean;
  size?: 'sm' | 'md';
  gridLayout?: 'true' | 'false';
}

const InputsWrapper: React.FC<InputWrapperProps> = ({
  className,
  children,
  spanAllColumns,
  size = 'md',
  gridLayout = 'false',
}) => {
  const wrapClass = clsx('cashier-modal__input-wrapper', spanAllColumns && 'span-all-cols', className);

  return (
    <div className={wrapClass} data-size={size} data-grid-layout={gridLayout}>
      {children}
    </div>
  );
};

export default InputsWrapper;
