import { printEjectCommand, monitorPrintingCompletion } from 'modules/retail/shared/utils/retail.utils';
import { localLog } from 'utils/common/helpersCommon';
export const makeRequest = (data) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', data.command, true);
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.responseText);
      } else {
        reject(xhr.statusText);
      }
    };
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send(JSON.stringify(data.bodyData));
  });
};

export const printReceipt = async (commandsData) => {
  for (const data of commandsData) {
    try {
      const response = await makeRequest(data);
      if (data.command === printEjectCommand) {
        await monitorPrintingCompletion();
      }
      // Handle the response here
      localLog({ message: `Print receipt response: ${response}` });
    } catch (error) {
      // Handle errors here
      localLog({ message: `Print receipt error: ${error}` });
    }
  }
};
