import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { Breakpoints } from 'theme/Theme';
import { SCFadeIn } from '../../SharedSC/SharedSC';

const sharedStyle = css`
  @media only screen and (min-width: 360px) {
    min-height: 46px;
  }
  margin-bottom: 11px;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    margin-top: -20px;
    margin-bottom: 24px;
    min-height: 60px;
  }
  transition: all 0.15s;
  text-align: center;
  color: #fff;
  animation: ${SCFadeIn} 0.3s;
`;

interface StyledCTAButtonProps {
  btnbg: string;
}
export const StyledCTAButton = styled(motion.button).attrs(({ className }: { className? }) => ({
  className: className,
}))<StyledCTAButtonProps>`
  ${sharedStyle};
  z-index: 20;
  @media only screen and (min-width: 360px) {
    padding: 5px;
  }
  margin-left: 10vw;
  min-width: 156px;
  border: none;
  font-size: 18px;
  font-weight: 700;
  vertical-align: middle;
  @include fade-in();
  background-color: transparent;
  background-image: url(${(p) => p.btnbg});
  transition:
    min-width 0.15s,
    margin 0.15s;

  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    margin-left: 0;
    min-width: 258px;
  }

  &:disabled {
    filter: grayscale(1);
  }
`;

export const StyledPlaceholder = styled.div`
  ${sharedStyle};
`;
