import React from 'react';
import clsx from 'clsx';
import { useWidgetNavigate } from 'modules/casino/modules/container/widget/widgetTypes/genericWidgets/hooks/useWidgetNavigate';
import { WidgetTemplate } from 'modules/casino/shared/constants';
import { CasinoWidgetsTypes } from '../../../../types/casinoWidgetsTypes';

interface WrapperProps {
  type: CasinoWidgetsTypes.WidgetTypes;
  columns: number;
  children: React.ReactNode;
  backgroundUrl?: string;
  wrapperClassName?: string;
  rows?: number;
  navigation?: CasinoWidgetsTypes.WidgetNavigation;
}

const Wrapper: React.FC<WrapperProps> = ({
  type,
  backgroundUrl,
  columns,
  children,
  wrapperClassName,
  rows,
  navigation,
}) => {
  const { onClick } = useWidgetNavigate(navigation);
  const wrapperClass = clsx('cw', 'cw--generic', `cw--${WidgetTemplate[type]}`, wrapperClassName && wrapperClassName);
  const wrapperStyle = {
    backgroundImage: backgroundUrl && backgroundUrl,
    cursor: navigation && !navigation.buttonNavigation ? 'pointer' : undefined,
  };
  const validRows = rows ? rows : 1;
  const innerWrapperStyle = {
    gridTemplateColumns: WidgetTemplate[type] === WidgetTemplate.SECTION ? `` : `repeat(${columns}, ${100 / columns}%)`,
    gridTemplateRows:
      WidgetTemplate[type] === WidgetTemplate.SECTION ? `` : `repeat(${validRows}, ${100 / validRows}%)`,
  };

  const handleClick = navigation && !navigation.buttonNavigation ? onClick : undefined;

  return (
    <div className={wrapperClass} style={wrapperStyle} onClick={handleClick}>
      <div className="cw--inner-wrapper" style={innerWrapperStyle}>
        {children}
      </div>
    </div>
  );
};
Wrapper.displayName = 'GenericWidgetsUI.Wrapper';
export default Wrapper;
