import React from 'react';
import clsx from 'clsx';
import { Text } from 'components/shared';
import { Flex } from 'components/shared/layout';

/** Terminal Modal Status */
const statusTypesClassNameMap = {
  pending: '',
  success: '',
  error: '',
  isAgeLimitError: '',
};

type StatusTypes = keyof typeof statusTypesClassNameMap;

interface StatusProps {
  status: StatusTypes;
  className?: string;
  text?: string;
  textSecondary?: string;
  isAgeLimitError?: string;
}

const Status: React.FC<StatusProps> = ({ status, className, text, textSecondary }) => {
  const cssNS = 'terminal-status';

  const renderStatus = (s: StatusTypes) => {
    switch (s) {
      case 'pending':
        return <Flex.Center className={`${cssNS} ${cssNS}__spinner`} />;
      case 'success':
        return (
          <Flex.Center className={`${cssNS} ${cssNS}__success`}>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              className={`${cssNS}__icon`}
              viewBox="0 0 130.2 130.2"
            >
              <polyline
                className={`${cssNS}__path ${cssNS}__path-check`}
                fill="none"
                strokeLinecap="round"
                strokeMiterlimit="10"
                points="100.2,40.2 51.5,88.8 29.8,67.5 "
              />
            </svg>
          </Flex.Center>
        );
      case 'isAgeLimitError':
        return <Flex.Center className={`${cssNS} ${cssNS}__age-limit-error`}></Flex.Center>;
      case 'error':
        return (
          <Flex.Center className={`${cssNS} ${cssNS}__error`}>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              className={`${cssNS}__icon`}
              viewBox="0 0 130.2 130.2"
            >
              <line
                className={`${cssNS}__path ${cssNS}__path-line`}
                fill="none"
                strokeLinecap="round"
                strokeMiterlimit="10"
                x1="34.4"
                y1="37.9"
                x2="95.8"
                y2="92.3"
              />
              <line
                className={`${cssNS}__path ${cssNS}__path-line`}
                fill="none"
                strokeLinecap="round"
                strokeMiterlimit="10"
                x1="95.8"
                y1="38"
                x2="34.4"
                y2="92.2"
              />
            </svg>
          </Flex.Center>
        );
    }
  };

  const statusClass = clsx('text-center', `${cssNS}__wrapper`, className);

  return (
    <Flex.ColAC className={statusClass}>
      {renderStatus(status)}
      {text && <Text className={`${cssNS}__text`} text={text} />}
      {textSecondary && <Text className={`${cssNS}__text-secondary`} text={textSecondary} />}
    </Flex.ColAC>
  );
};
export default Status;
