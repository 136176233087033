import React from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';
import { leaderBoardCSSNS } from '../../LeaderBoardCellWidgetConstants';

type Props = {
  firstColText: string;
  secondColText: string;
  thirdColText?: string;
  isHighlighted?: boolean;
  isBold?: boolean;
};

const TableRow: React.FC<Props> = ({ firstColText, secondColText, thirdColText, isHighlighted, isBold }) => {
  const rowClass = clsx(
    `${leaderBoardCSSNS}-widget__row`,
    isBold && `${leaderBoardCSSNS}-widget__row--top-wins`,
    isHighlighted && `${leaderBoardCSSNS}-widget__row--highlighted`,
  );
  return (
    <>
      <div className={`${leaderBoardCSSNS}-widget__row--separator`}></div>
      <Flex className={rowClass}>
        <div className={`${leaderBoardCSSNS}-widget__cell`}>{firstColText}</div>
        <div className={`${leaderBoardCSSNS}-widget__cell`}>{secondColText}</div>
        {thirdColText && <div className={`${leaderBoardCSSNS}-widget__cell`}>{thirdColText}</div>}
      </Flex>
    </>
  );
};

export default TableRow;
