import clsx from 'clsx';
// import { Flex } from 'components/shared/layout';

interface ColProps {
  children?: React.ReactNode;
  className?: string;
}

const Col: React.FC<ColProps> = ({ children, className }) => {
  const colClass = clsx('financial-info-table__col', className);

  return <div className={colClass}>{children}</div>;
};

export default Col;
