import { useMemo } from 'react';
import { useLoadingState } from 'modules/casino/shared/hooks/useLoadingState';
import { useAppSelector } from 'store';
import { useGameCollectionId } from './useGameCollectionId';
import { CategoryLayoutTypes } from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { favoriteGamesSelectors } from '../additionalGames/favourites/slice/casinoFavouriteGamesSlice';
import { GamesSliceTypes } from '../constants/casinoGamesConstants';
import { gamesSelectors } from '../slice/casinoGamesSlice';
import { getGamesSliceType } from '../utils/casinoGamesUtils';

type HookType = (
  categoryId: string,
  categoryLayoutType: CategoryLayoutTypes,
) => {
  isLoading: boolean;
  hasError: boolean;
  hasNoGames: boolean;
  hasInitial: boolean;
  hasSuccess: boolean;
};

export const useCasinoGamesFetchStatus: HookType = (categoryId, categoryLayoutType) => {
  const gameSliceType = getGamesSliceType(categoryLayoutType);
  const { gameCollectionId } = useGameCollectionId({ categoryId, categoryLayoutType });

  const selectorMap = useMemo(() => {
    return {
      [GamesSliceTypes.FAVOURITES]: (state) => favoriteGamesSelectors.favoriteGamesLoadingState(state),
      [GamesSliceTypes.GENERAL]: (state) => gamesSelectors.categoryGamesLoadingState(state, gameCollectionId),
    };
  }, [gameCollectionId]);

  const loadingState = useAppSelector((state) => selectorMap[gameSliceType](state));
  const { hasInitial, isLoading, hasSuccess, hasError, hasNoGames } = useLoadingState(loadingState);
  return { hasInitial, isLoading, hasSuccess, hasError, hasNoGames };
};
