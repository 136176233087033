import { MyBets, SelectionStatuses } from 'pages/myAccount/tabs/history/sections/sportsHistory/types/myBets.types';
import { isEmpty } from 'utils/common/helpersCommon';

export const mapSelectionStatus = (status: MyBets.TicketOutcomeStatus): keyof typeof SelectionStatuses | undefined => {
  if (isEmpty(status)) return;
  switch (status) {
    case 'Loss':
      return 'LOST';
    case 'Cancelled':
      return 'CANCELED';
    default:
      return status.toLocaleUpperCase() as SelectionStatuses;
  }
};

export const mapTicketStatus = (status) => {
  if (isEmpty(status)) return;
  switch (status) {
    case 'Cancelled':
      return 'Cancel';
    default:
      return status as SelectionStatuses;
  }
};
