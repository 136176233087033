import styled from 'styled-components';
import useHandleBonusActions from 'shared/common/features/gamification/hooks/useHandleBonusActions';
import { NotificationTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationTypes';
import { Breakpoints } from 'theme/Theme';
import { SelectBonusButton } from '../../../components/SelectBonusButton';

export const SelectBonusLastSlide = ({
  bonus,
  notification,
  animationParams,
}: {
  bonus;
  notification: NotificationTypes.PopUpNotification;
  // notification: any;
  animationParams: NotificationTypes.GamificationModalParams;
}) => {
  const activeBonus = { ...bonus, ...bonus?.button };
  const { style } = notification;
  const { handleOfferAction } = useHandleBonusActions({
    bonus: activeBonus,
    notificationId: notification.id,
  });
  return (
    <FramePath $color={style.messageTextColor} style={{ opacity: activeBonus?.title ? 1 : 0 }}>
      <Title>{notification.message}</Title>

      <BonusTitle>
        <span>{typeof activeBonus === 'string' ? activeBonus : activeBonus?.title}</span>
      </BonusTitle>

      <SelectBonusButton
        text={activeBonus?.buttonText}
        show={true}
        image={'buttons/end.png'}
        folder={animationParams.folder}
        handleOnClick={handleOfferAction}
        isLast
      />
    </FramePath>
  );
};

const Title = styled.div`
  font-size: 22px;

  @media (min-width: ${Breakpoints.isDesktop}px) {
    font-size: 26px;
  }
`;
const BonusTitle = styled.div`
  padding: 0 5px;
  min-height: 231px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;

  @media (min-width: ${Breakpoints.isDesktop}px) {
    font-size: 30px;
  }
`;

const FramePath = styled.div<{ $color?: string }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  height: 100%;
  color: ${({ $color }) => $color ?? '#494949'};
  justify-content: space-between;
  padding-top: 35px;
  @media (min-width: ${Breakpoints.isDesktop}px) {
    padding-top: 70px;
  }
`;
