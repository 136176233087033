import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CATEGORY_CUSTOM_GAMES_COUNTER } from 'modules/casino/modules/container/cellsContainer/cellls/constants/casinoGamesConstants';
import { useGameCollectionId } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameCollectionId';
import { gamesSelectors } from 'modules/casino/modules/container/cellsContainer/cellls/slice/casinoGamesSlice';
import { Icon } from 'modules/casino/shared/components/icons';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { useAppSelector } from 'store';
import { CategoryLayoutTypes } from '../../types/CategoryLayoutTypes';
import CategoryFilter from '../CategoryFilter';

interface Props {
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
}

const LobbyActions: React.FC<Props> = ({ categoryId, categoryLayoutType }) => {
  const { navToAlias, navToCategory, navToGamesCount, isFilterEnabled, searchTags, navToActivity, queryType } =
    useGetCategoryParams(categoryId, CategoryLayoutTypes.LOBBY);
  const { gameCollectionId } = useGameCollectionId({ categoryId, categoryLayoutType: CategoryLayoutTypes.LOBBY });
  const totalElements = useAppSelector((state) => gamesSelectors.categoryGamesCount(state, gameCollectionId));
  const gamesCount = CATEGORY_CUSTOM_GAMES_COUNTER.includes(queryType) ? totalElements : navToGamesCount;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClickHandler = () => {
    window.scrollTo(0, 0);
    navigate(navToAlias, { state: { categoryId: navToCategory, queryType } });
  };

  const renderLobbyActions = () => {
    if (navToActivity) {
      return (
        <div className="lobby-category-header__link" onClick={onClickHandler}>
          <span>{`${t(`CasinoLobby.CategoryHeader.viewAllGamesText`)}${gamesCount ? ` (${gamesCount})` : ''}`}</span>
          <Icon className="rotated-270 lobby-category-header__link-icon" icon="chevron" />
        </div>
      );
    }

    if (isFilterEnabled && searchTags) {
      return <CategoryFilter categoryId={categoryId} searchTags={searchTags} categoryLayoutType={categoryLayoutType} />;
    }
    return <></>;
  };

  return renderLobbyActions();
};

export default LobbyActions;
