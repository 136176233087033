import React from 'react';
import ContainerLinks from 'components/shared/sportContainers/containerLinks/ContainerLinks';
import HighlightsLink from './HighlighsLink';
import { DrawerTypes } from '../../types/drawer.types';

interface Props {
  sportDrawerContainers: DrawerTypes.SportVertical[];
}

const renderLinkContainers = (containers: DrawerTypes.SportVertical) =>
  containers.content.map((item, idx) => (
    <ContainerLinks key={idx} title={item.name} containerId={item.id} apiUrl={item.apiUrl} inDrawer={true} />
  ));

const renderHighlightsContainers = (containers: DrawerTypes.SportVertical) =>
  containers.content.map((item, idx) => <HighlightsLink key={idx} item={item} />);

const SportsContainers: React.FC<Props> = ({ sportDrawerContainers }) => {
  return (
    <div className="drawer-section__containers">
      {sportDrawerContainers?.map((item) =>
        item.type === 1 ? renderHighlightsContainers(item) : renderLinkContainers(item),
      )}
    </div>
  );
};

export default SportsContainers;
