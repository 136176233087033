import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, BadgeGroup } from 'components/shared/badges';
import { TFunction } from 'i18n';
import { BetStatuses, SelectionStatuses } from 'pages/myAccount/tabs/history/sections/sportsHistory/types/myBets.types';
import { isEmpty } from 'utils/common/helpersCommon';
import { ClaimTicketTranslations, MyAccountTranslation } from 'utils/common/translationUtils/translationStrings';

const renderStatus = {
  [SelectionStatuses.WON](t: TFunction) {
    return <Badge variant="statusPositive" label={t(MyAccountTranslation.BetSelection.winStatus)} />;
  },
  [SelectionStatuses.LOST](t: TFunction) {
    return <Badge variant="statusNegative" label={t(MyAccountTranslation.BetSelection.lostStatus)} />;
  },
  [SelectionStatuses.VOID](t: TFunction) {
    return <Badge variant="statusNeutral" label={t(MyAccountTranslation.BetSelection.voidStatus)} />;
  },
  [SelectionStatuses.WON_VOID](t: TFunction) {
    return (
      <BadgeGroup>
        <Badge variant="statusPositive" label={`½ ${t(MyAccountTranslation.BetSelection.winStatus)}`} />
        <Badge variant="statusNeutral" label={`½ ${t(MyAccountTranslation.BetSelection.voidStatus)}`} />
      </BadgeGroup>
    );
  },
  [SelectionStatuses.LOSS_VOID](t: TFunction) {
    return (
      <BadgeGroup>
        <Badge variant="statusNegative" label={`½ ${t(MyAccountTranslation.BetSelection.lostStatus)}`} />
        <Badge variant="statusNeutral" label={`½ ${t(MyAccountTranslation.BetSelection.voidStatus)}`} />
      </BadgeGroup>
    );
  },
  [SelectionStatuses.CANCELED](t: TFunction) {
    return <Badge variant="statusNeutral" label={t(MyAccountTranslation.BetSelection.cancelledStatus)} />;
  },
  [SelectionStatuses.PAID](t: TFunction) {
    return <Badge variant="statusNeutral" label={t(ClaimTicketTranslations.Info.paid)} />;
  },
  [SelectionStatuses.ACCEPTED](t: TFunction) {
    return <Badge variant="statusPositive" label={t('MyBets.CheckTicket.ticketAcceptedStatus')} />;
  },
};

interface Props {
  status: keyof typeof SelectionStatuses | null | undefined;
  betStatus: BetStatuses;
}

const SelectionStatus: React.FC<Props> = ({ status, betStatus }) => {
  const { t } = useTranslation();

  if (betStatus === BetStatuses.CANCELED) return renderStatus['CANCELED'](t);
  if (isEmpty(status)) return null;
  return renderStatus?.[status]?.(t);
};

export default SelectionStatus;
