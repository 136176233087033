import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import CTAUI from 'shared/common/features/myProfile/components/CTAUI';
import { leftToRightAnimation } from 'shared/common/features/myProfile/utils/myProfile.utils';
import useTransitionTabs from '../../../../hooks/useTransitionTabs';
import { ClubTabsTypes } from '../../../types/clubTabs.types';
import { LinkItem } from '../../ClubLinkItem';
import { ComponentMapping } from '../../ComponentMapping';

export const CaptainUpTabLayout01: React.FC<ClubTabsTypes.LayoutKeyMapProps> = ({
  tabs,
  handleActiveItem,
  activeItem,
  badgesCount,
}) => {
  const { t } = useTranslation();

  const hideCategoryBonuses = () => {
    handleActiveItem(null);
  };

  const { initialOpacity, controls } = useTransitionTabs(tabs);

  return (
    <>
      {!activeItem &&
        tabs?.map((tab, idx) => (
          <motion.div
            initial={{ opacity: initialOpacity }}
            key={idx}
            custom={idx}
            animate={controls}
            className="mpd-cta__wrapper--layout-01"
          >
            {tab.route ? (
              <LinkItem tab={tab} />
            ) : (
              <CTAUI.Header
                dataQid={`mpd-offers-cat-${tab.key}`}
                onClick={() => handleActiveItem(tab)}
                icon={tab.icon || ''}
                useIconPath={false}
                title={t(tab.label)}
                badgeCount={badgesCount[tab.key]}
                isRead
                showChevron={true}
                className="mpd-cta-header--gamification"
              />
            )}
          </motion.div>
        ))}
      {activeItem && (
        <motion.div
          variants={leftToRightAnimation}
          className="mpd-section__tab-panel--gradient h-100"
          initial="closed"
          animate="open"
        >
          <CTAUI.Header
            onBackClick={hideCategoryBonuses}
            dataQid={`mpd-offers-cat-${activeItem.key}`}
            icon={activeItem.icon || ''}
            useIconPath={false}
            title={t(activeItem.label)}
            isRead
            showBackButton
            className="m-0 mb-3"
          />
          {/* <div className="mpd-cta-header mpd-cta-header--no-bg mt-0 pt-0 pr-0"> */}
          <div className="pb-2 mt-1">
            {activeItem.key && <ComponentMapping itemKey={activeItem.key as ClubTabsTypes.ComponentMappingType} />}
          </div>
        </motion.div>
      )}
    </>
  );
};
