import styled from 'styled-components';

const GameMediaStyled = styled.div`
  height: 100%;
  border-radius: var(--game-cell-br);
  overflow: hidden;
  position: relative;
`;

export default GameMediaStyled;
