import { SliceRootState } from 'store/rootReducer';
import { CreateThunk } from 'store/thunkCreators';

export enum HelpType {
  HelpMenu = 'HelpMenu',
  Faq = 'faq',
  PaymentMethods = 'Payment',
  TermsOfUse = 'TermsOfUse',
  PrivacyPolicy = 'PrivacyPolicy',
  Cookie = 'Cookie',
  About = 'Aboutus',
  ContactUs = 'contactUs',
  ReasonableBetting = 'REASONABLE_BETTING',
}

export enum SectionType {
  text = 'text',
  contactUs = 'contactUs',
  payments = 'payments',
  faq = 'faq',
  gamesInfo = 'gamesInfo',
}

export enum RequestStatuses {
  INITIAL,
  PENDING,
  ERROR,
  SUCCESS,
  EMPTY,
}

export const noActiveHelpCategory = '-1';

export declare module HelpTypes {
  export interface InitialState {
    helpMenus: Menus;
    helpSections: Sections;
    isLoading: boolean;
    activeTab: number;
    activeCategoryId: string;
    gamesInfo: Record<string, GameInfo[]>;
    status: RequestStatuses | null;
    isOpenChat: boolean;
  }

  export interface GameInfo {
    gameRefId: string;
    title: string;
    providerId: string;
    providerCode: number;
    providerName: string;
  }

  export interface MenuType {
    id: number;
    icon: { icon: string; width: number };
    type: SectionType;
    route: string;
    title: string;
    isActive: boolean;
    isMainTopic: boolean;
    isHome?: boolean;
  }

  export type Menus = MenuType[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export type Sections = Record<string, any>;
  export type MenuState = SliceRootState<'helpMenu'>;

  export type ContactBlockType = {
    description: string;
    title: string;
    type: 'email' | 'phone' | 'chat';
    value: string;
  };

  export type FAQBlockType = {
    id: number;
    name: string;
    description: string;
    isTop: boolean;
  };

  export type PaymentBlockIconType = {
    icon: string;
    width: string;
  };

  export type PaymentBlockLimitType = {
    min: string;
    max: string;
    currency: string;
  };

  export type PaymentBlockType = {
    id: number;
    title: string;
    description: string;
    transfer: string;
    icon: PaymentBlockIconType[];
    limits: PaymentBlockLimitType[];
  };

  export type TabsDataType = {
    id: number;
    alias: string;
    title: string;
    description: string;
    isVisible: boolean;
    items: FAQBlockType[] | PaymentBlockType[];
  };

  export type TabsType = {
    data: TabsDataType[];
    headerTitle: string;
    top: FAQBlockType[];
    type: 'faq' | 'payments';
    downloadUrl?: string;
  };

  export type TextPageType = {
    type: string;
    headerTitle: string;
    description: string;
  };

  export type ItemsResponseType = {
    HelpMenu: Menus;
    HelpSections: Sections;
    initialCategoryId: string | undefined;
  };

  export type ModuleThunks = {
    fetchHelpItems: CreateThunk<{ route?: string }, ItemsResponseType, string | null>;
    fetchGamesInfo: CreateThunk<undefined, Record<string, GameInfo[]>, string | null>;
  };
}
