import { memo, useMemo } from 'react';
import usePreloadAssets from 'components/shared/FrameAnimator/hooks/usePreloadAssets';
import { mapAnimationAssets } from 'components/shared/FrameAnimator/utils/images/utils';
import { Flex } from 'components/shared/layout';
import { defineAnimationParams } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationUtils';
import CashWheel from './CashWheel';
import CTAText from './components/CTAText';
import { NotificationTypes } from '../../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import { GamificationUI } from '../../components/GamificationUI';
import useAnimationComplete from '../../hooks/useAnimationComplete';
import { useAutoScrollToDepositButton } from '../../hooks/useAutoScrollToDepositButton';
import useHandleBonusActions from '../../hooks/useHandleBonusActions';
import useOfferTerms from '../../hooks/useOfferTerms';

const CashWheelContainer: React.FC<{ notification: NotificationTypes.PopUpNotification }> = ({ notification }) => {
  const { bonusData, header, subheader, message, style } = notification;
  const singleBonus = bonusData?.bonuses?.[0];

  const { animationComplete, setAnimationComplete } = useAnimationComplete();

  useAutoScrollToDepositButton(animationComplete);

  const animationParams = useMemo(
    () =>
      defineAnimationParams({
        animationKey: style.animationKey,
        layout: style.layout,
        folder: style.folder,
      }),
    [style.animationKey, style.layout, style.folder],
  );

  const module = useMemo(() => mapAnimationAssets(animationParams), [animationParams]);
  usePreloadAssets({ module, loadingFinished: true });

  const { showTerms, outsideClick, offerTerms, fetchBonusTerm } = useOfferTerms(singleBonus);
  const { handleOfferAction, error, status, statusCode } = useHandleBonusActions({
    bonus: singleBonus,
    notificationId: notification.id,
  });

  if (!singleBonus) return null;

  const winnerSegment = bonusData?.otherOptions?.findIndex((option) => option === singleBonus?.title);

  return (
    <>
      <GamificationUI.Header className="text-center">
        <GamificationUI.Logo animationParams={animationParams} />
        <CTAText
          showWinText={animationComplete}
          text={header}
          winText={message}
          error={error}
          bgColor={style.textBackground}
          textColor={style.headerTextColor}
        />
      </GamificationUI.Header>

      <CashWheel
        folder={animationParams.folder}
        segments={bonusData?.otherOptions}
        winnerSegment={winnerSegment}
        setAnimationComplete={setAnimationComplete}
        notificationId={notification.id}
        bonusTextColor={style?.bonusTextColor}
        bonusWinTextColor={style?.bonusWinTextColor}
        spinButtonTextColor={style?.spinButtonTextColor}
      />
      <Flex.ColAC className="mt-auto">
        <GamificationUI.CTAButton
          buttonText={singleBonus?.buttonText}
          disabled={status === 'pending'}
          errorText={error}
          onClick={handleOfferAction}
          isVisible={animationComplete}
          statusCode={statusCode}
          text={subheader}
          bgColor={style.backgroundButton}
          textColor={style.buttonTextColor}
          borderColor={style.buttonBorderColor}
        />

        <GamificationUI.Link
          className="py-1 text-center"
          isActive={showTerms}
          isVisible={animationComplete}
          onClick={fetchBonusTerm}
          textColor={style.termsTextColor}
        />
      </Flex.ColAC>

      <GamificationUI.Terms isVisible={showTerms} onClickOutside={outsideClick} text={offerTerms} />
    </>
  );
};

export default memo(CashWheelContainer);
