import React, { memo } from 'react';
import clsx from 'clsx';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { DataLoader } from 'modules/casino/shared/components/loaders';
import JackpotSection from 'modules/casino/shared/features/jackpots/components/JackpotSection';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { useLandingCasinoCategory } from './hooks/useLandingCasinoCategory';

interface Props {
  children: React.ReactNode;
  categoryIdCacheKey: string;
  categoryLayoutType: CategoryLayoutTypes;
  googleTagEvent?: string;
}

const LandingCasinoCategory: React.FC<Props> = ({
  children,
  categoryIdCacheKey,
  categoryLayoutType,
  googleTagEvent,
}) => {
  const { showBackground, backgroundImage, jackpotId, jackpotDetails, jackpotTemplateClass, isLoading } =
    useLandingCasinoCategory({ categoryIdCacheKey, categoryLayoutType, googleTagEvent });
  const casinoCatStyles = clsx(`casino-category-container ${cssGlobalNS}-container lobby`, showBackground && 'has-bgr');
  const categoryStyles = clsx('casino-category', jackpotDetails && 'jackpot-section', jackpotTemplateClass);

  return isLoading ? (
    <DataLoader />
  ) : (
    <div className={casinoCatStyles} style={showBackground && { backgroundImage }}>
      <div className={categoryStyles}>
        {jackpotDetails && <JackpotSection jackpot={jackpotDetails} jackpotId={jackpotId} />}
        {children}
      </div>
    </div>
  );
};

export default memo(LandingCasinoCategory);
