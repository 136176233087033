import { LayoutConfigInnerLayoutTypes } from '../../../../../../../../shared/constants';
import { useCasinoLayoutConfig } from '../../../../../../../../shared/hooks/useCasinoLayoutConfig';
import { CategorySubTypes } from '../../../../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';

export const useShowGameTitle = ({ layoutGrid, subType }) => {
  const casinoLayoutConfig = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.CASINO_LAYOUT_CONFIG });
  const showGameTitleByLayout =
    casinoLayoutConfig?.showGameTitleByLayout?.[layoutGrid] === undefined
      ? true
      : casinoLayoutConfig.showGameTitleByLayout[layoutGrid];
  const showGameTitleBySubType =
    casinoLayoutConfig?.showGameTitleBySubType?.[subType] === undefined
      ? true
      : casinoLayoutConfig.showGameTitleBySubType[subType];
  const showGameTitle =
    casinoLayoutConfig?.showGameTitle &&
    (subType !== CategorySubTypes.STANDARD ? showGameTitleBySubType : showGameTitleByLayout);

  return { showGameTitle };
};
