import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store';
// import { isEmpty } from 'utils/common/helpersCommon';
import { SSBT } from 'utils/common/translationUtils/translationStrings';
import { TerminalModalUI } from '../../../components/ssbtModalUI';
// import { terminalThunks } from '../../slice/ssbt.slice';
import { TerminalModalActionIcons } from '../../../types/ssbt.types';
import { selectSessionBalance } from '../../sessionBalance/slice/sessionBalance.slice';

const SecondStepDepositSSBTVoucherModal = ({ setActiveStep }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const retailSessionBalance = useAppSelector(selectSessionBalance.retailBalanceInfo)?.sessionBalance;
  // const voucherFormRef = useRef<any>(null);
  // const handleVoucherSubmit = (voucherRef, e) => {
  //   const formInstance = !isEmpty(voucherRef?.current) ? voucherRef?.current['voucherValue'].value : '';
  //   e.preventDefault();
  //   setActiveStep(3);
  //   dispatch(terminalThunks.getVoucherInfo({ voucherCode: encodeURIComponent(formInstance) }));
  //   setVoucherValue(formInstance);
  // };
  return (
    <TerminalModalUI.ContentWrapper>
      <TerminalModalUI.Header text={t(SSBT.TerminalModal.depositWithVoucherHeader)} />
      <TerminalModalUI.ActionIcon icon={TerminalModalActionIcons.QR} />
      {/* <form onSubmit={(e) => handleVoucherSubmit(voucherFormRef, e)} ref={voucherFormRef}>
        <input
          type="text"
          placeholder={`Please scan your voucher here`}
          className="form-control"
          name="voucherValue"
          autoFocus
        />
      </form> */}
      <TerminalModalUI.Button
        variant={'disabled'}
        text={t(SSBT.TerminalModal.depositText)}
        textSecondary={`0 ${retailSessionBalance?.currency}`}
        onClick={() => {
          return;
        }}
      />
      <TerminalModalUI.Link
        text={t(SSBT.TerminalModal.anotherDepositMethod)}
        onClick={() => dispatch(setActiveStep(1))}
      />
    </TerminalModalUI.ContentWrapper>
  );
};
export default SecondStepDepositSSBTVoucherModal;
