import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { UI } from 'components/shared/UI';
import { MenuItemType } from 'modules/casino/modules/navigation/shared/constants/casinoNavigationConstants';
import { CASINO_SEARCH_ROUTE } from 'modules/casino/shared/features/casinoSearch/casinoSearchConstants';
import { useSVG } from 'modules/casino/shared/hooks/useSVG';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { BurgerUI } from 'shared/common/features/burger/components/BurgerUI';
import { BurgerMenuSections } from 'shared/common/features/navigationMenu/types/navigationMenu.types';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { Drawer } from 'utils/common/translationUtils/translationStrings';
import CasinoProviderRow from './CasinoProviderRow';
import { useGetEnabledColorIcons } from '../../../../../../modules/casino/hooks/useGetEnabledColorIcons';
import { customCasinoNavigate } from '../../../../../../modules/casino/platformImplementation/utils/casinoWidget.utils';
import { navigateToCasinoIntegration } from '../../../integrations/utils/integrations.utils';
import useDrawerCasinoLayout02Data from '../../hooks/useDrawerCasinoLayout02Data';
import { setDrawer } from '../../slice/drawer.slice';

const CasinoDrawerSectionLayout02: React.FC<BurgerMenuSections> = ({ section }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { casinoMenuItems, casinoProvidersItems } = useDrawerCasinoLayout02Data({
    isActive: section?.isCategoryActive,
    sectionType: section?.sectionType,
  });
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const enableColorIcons = useGetEnabledColorIcons();
  const [isShowAll, setIsShowAll] = useState(false);
  const isPublicItems = isAuthenticated ? casinoMenuItems : casinoMenuItems?.filter((item) => item?.isPublic !== false);
  const casinoMenus = isShowAll ? isPublicItems : isPublicItems?.slice(0, 12);
  const handleCategoriesClick = (alias: string, menuType: string) => {
    if (menuType === MenuItemType.LOBBY) {
      navigateToCasinoIntegration({ fullPath: `${section.route}` });
      customCasinoNavigate({ path: section.route, navigate, options: {} });
      // navigate(`${section.route}`);
    } else if (menuType === MenuItemType.SEARCH) {
      navigateToCasinoIntegration({ fullPath: CASINO_SEARCH_ROUTE });
      // navigate(CASINO_SEARCH_ROUTE);
      customCasinoNavigate({ path: CASINO_SEARCH_ROUTE, navigate, options: {} });
    } else {
      navigateToCasinoIntegration({ fullPath: `${section.route}/${alias}` });
      customCasinoNavigate({ path: `${section.route}/${alias}`, navigate, options: {} });
      // navigate(`${section.route}/${alias}`);
    }

    dispatch(setDrawer(false));
  };

  const toggleShowAll = () => {
    setIsShowAll(!isShowAll);
  };
  useSVG();

  return (
    <>
      {section?.isCategoryActive ? (
        <BurgerUI.TilesWrapper>
          {!isEmpty(casinoMenus) && (
            <BurgerUI.TilesGrid>
              {casinoMenus?.map((item, index) => (
                <BurgerUI.CasinoTile
                  key={index}
                  icon={item?.iconLocation}
                  useColorIcon={enableColorIcons}
                  text={item.title}
                  onClick={() => handleCategoriesClick(item.alias, item.menuType)}
                  isActive={
                    item?.menuType === MenuItemType.LOBBY
                      ? `${section.route}` === location.pathname
                      : `${section.route}/${item.alias}` === location.pathname
                  }
                />
              ))}
            </BurgerUI.TilesGrid>
          )}

          {isPublicItems?.length > 12 && (
            <UI.Button
              variant="secondary"
              size="sm"
              className="mx-auto mt-2 mb-2 px-4"
              onClick={toggleShowAll}
              text={isShowAll ? t(Drawer.DrawerSection.viewLessText) : t(Drawer.DrawerSection.viewMoreText)}
              icon="chevron"
              iconClassName={isShowAll ? 'rotated-180' : ''}
            />
          )}
        </BurgerUI.TilesWrapper>
      ) : (
        <>
          {!isEmpty(casinoProvidersItems) && (
            <BurgerUI.ListWrapper>
              {casinoProvidersItems?.map((providerAlias, idx) => {
                return (
                  <CasinoProviderRow
                    key={idx}
                    rootType={section?.sectionType}
                    providerAlias={providerAlias}
                    verticalRoute={section?.route}
                  />
                );
              })}
            </BurgerUI.ListWrapper>
          )}
        </>
      )}
    </>
  );
};

export default CasinoDrawerSectionLayout02;
