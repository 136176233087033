import React from 'react';
import ExpandableIcon from 'modules/casino/shared/components/icons/ExpandableIcon';

interface ReprintTicketToggleBtnProps {
  text?: string;
  onClick?: React.MouseEventHandler;
  isExpanded?: boolean;
}

const ReprintTicketToggleBtn: React.FC<ReprintTicketToggleBtnProps> = ({ text, onClick, isExpanded }) => {
  return (
    <egtd-reprint-ticket-header-toggler onClick={onClick}>
      {text && <span>{text}</span>}
      <ExpandableIcon isExpanded={isExpanded} size={20} />
    </egtd-reprint-ticket-header-toggler>
  );
};

export default ReprintTicketToggleBtn;
