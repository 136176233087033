import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { Breakpoints } from 'theme/Theme';

const sharedStyle = css`
  min-height: 30px;
  @media only screen and (min-width: 360px) {
    min-height: 46px;
  }
  transition: all 0.15s;
  text-align: center;
  color: #fff;
`;

export const StyledCTAButton = styled(motion.button)<{ $addMarginTop?: string; $buttonBackground?: string }>`
  ${sharedStyle};
  @media only screen and (min-width: 360px) {
    padding: 5px 30px;
  }

  @media screen and (max-width: ${Breakpoints.isMobile}px) {
    margin-top: ${({ $addMarginTop }) => $addMarginTop && $addMarginTop};
  }

  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  vertical-align: middle;
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: ${({ $buttonBackground }) => ($buttonBackground ? $buttonBackground : '#19b14e')};
  filter: drop-shadow(
    0 2px 6px rgba(${({ $buttonBackground }) => ($buttonBackground ? $buttonBackground : '#19b14e')}, 0.3)
  );

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      transform: scale(1.15);
    }
  }

  &:disabled {
    filter: grayscale(1);
  }
`;

export const StyledPlaceholder = styled.div.attrs(({ className }: { className? }) => ({
  className: className,
}))`
  ${sharedStyle};
  padding: 5px 30px;
  display: flex;
  align-items: center;
`;
