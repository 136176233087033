import { createSlice } from '@reduxjs/toolkit';
import { setLanguage } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getCachiosOptions, getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { FooterTypes } from '../types/appFooter.types';

export const footerThunks = {
  fetchFooterPromotions: createAbortThunk<FooterTypes.ApiResponse, FooterTypes.ThunkArgs>(
    'common/fetchFooterPromotions',
    async ({ apiUri, language }, { rejectWithValue }) => {
      try {
        const { options } = getCachiosOptions(language);
        const response = await axiosInstance.get<FooterTypes.ApiResponse>(`${config.API_URL}${apiUri}`, {
          params: options,
        });

        return response.data;
      } catch (err) {
        return rejectWithValue(getError.responseDataMessage(err));
      }
    },
    // {
    //   condition: ({ apiUri }, { getState }) => {
    //     const promoApiUri = (getState() as RootState).common.appFooter.promoApiUri;
    //     if (promoApiUri === apiUri) return false;
    //   },
    // },
  ),
};

const initialState: FooterTypes.State = {
  isAppFooterVisible: false,
  hideAppFooter: false,
  showForLandingPage: false,
  promotions: null,
  promoApiUri: '',
  footerApiUri: '',
};

const appFooter = createSlice({
  name: 'appFooter',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(footerThunks.fetchFooterPromotions.fulfilled, (state, action) => {
        state.promotions = action.payload?.promotions;
        state.promoApiUri = action.meta.arg.apiUri || state.promoApiUri;
      })
      .addCase(footerThunks.fetchFooterPromotions.rejected, (state) => {
        state.promotions = null;
        state.promoApiUri = '';
      });
    builder.addCase(setLanguage, (state) => {
      state.promoApiUri = '';
      state.footerApiUri = '';
    });
  },
  reducers: {
    setAppFooter(state, action) {
      state.isAppFooterVisible = action.payload;
    },
    hideAppFooter(state, action) {
      state.hideAppFooter = action.payload;
    },
    showForLandingPage(state, action) {
      state.showForLandingPage = action.payload;
    },
  },
});

export const { setAppFooter, hideAppFooter, showForLandingPage } = appFooter.actions;

export const selectFooter = {
  hideAppFooter: (state: RootState) => state.common.appFooter.hideAppFooter,
  showForLandingPage: (state: RootState) => state.common.appFooter.showForLandingPage,
  isVisible: (state: RootState) => state.common.appFooter.isAppFooterVisible,
  promotions: (state: RootState) => state.common.appFooter.promotions,
};

export default appFooter.reducer;
