import React from 'react';
import clsx from 'clsx';
import { Icon } from 'components/shared';
import { Flex } from 'components/shared/layout';

/** Auth Notification Line */
interface NotificationLineProps {
  variant: 'info' | 'error';
  onClick?: () => void;
  icon: string;
  className?: string;
  children: React.ReactNode;
}

const NotificationLine: React.FC<NotificationLineProps> = ({ onClick, variant, icon, children }) => {
  const boxClass = clsx('auth-msg-line', `auth-msg-line--${variant}`);

  return (
    <Flex.AC className={boxClass} onClick={onClick}>
      <Icon size={14} defaultClassName={false} className={`mr-2 fill--${variant}`} icon={icon} />

      <div className="flex-1">{children}</div>
    </Flex.AC>
  );
};

export default NotificationLine;
