import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import usePreloadAssets from 'components/shared/FrameAnimator/hooks/usePreloadAssets';
import { mapAnimationAssets } from 'components/shared/FrameAnimator/utils/images/utils';
import { Flex } from 'components/shared/layout';
import { defineAnimationParams } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationUtils';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import CTAButton from './components/CTAButton';
import CTAText from './components/CTAText';
import Link from './components/Link';
import Logo from './components/Logo';
import { ReelsFrame, ReelsGridWrapper } from './components/ReelsGrid.styled';
import SpringReelItems from './SpringReelItems';
import { reelCampaignImages } from './utils/springReel.utils';
import { NotificationTypes } from '../../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import { GamificationUI } from '../../components/GamificationUI';
import useAnimationComplete from '../../hooks/useAnimationComplete';
import { useAutoScrollToDepositButton } from '../../hooks/useAutoScrollToDepositButton';
import useHandleBonusActions from '../../hooks/useHandleBonusActions';
import useOfferTerms from '../../hooks/useOfferTerms';
import useReelAnimation from '../../hooks/useReelAnimation';
import './SpringReel.scss';

const SpringReelContainer: React.FC<{ notification: NotificationTypes.PopUpNotification }> = ({ notification }) => {
  const { bonusData, header, subheader, message, style } = notification;
  const singleBonus = bonusData?.bonuses?.[0];

  const { animationComplete, setAnimationComplete } = useAnimationComplete();

  useAutoScrollToDepositButton(animationComplete);

  const animationParams = useMemo(
    () =>
      defineAnimationParams({
        animationKey: style.animationKey,
        layout: style.layout,
        folder: style.folder,
        numberOfFrames: style.numberOfFrames,
      }),
    [style.animationKey, style.layout, style.folder, style.numberOfFrames],
  );

  const { reel1, reel2, reel3 } = useMemo(() => {
    return reelCampaignImages(animationParams.folder);
  }, [animationParams.folder]);

  const reelSequences = useMemo(() => {
    return [reel1, reel2, reel3];
  }, [reel1, reel2, reel3]);
  // PRELOAD ASSETS
  const asset1 = useMemo(
    () => mapAnimationAssets(animationParams, `spin/${reelSequences[0]}/${animationParams.fileBaseName}`),
    [animationParams],
  );
  usePreloadAssets({ module: asset1, loadingFinished: true });
  const asset2 = useMemo(
    () => mapAnimationAssets(animationParams, `spin/${reelSequences[1]}/${animationParams.fileBaseName}`),
    [animationParams],
  );
  usePreloadAssets({ module: asset2, loadingFinished: true });
  const asset3 = useMemo(
    () => mapAnimationAssets(animationParams, `spin/${reelSequences[2]}/${animationParams.fileBaseName}`),
    [animationParams],
  );
  usePreloadAssets({ module: asset3, loadingFinished: true });
  // PRELOAD ASSETS
  const { showTerms, outsideClick, offerTerms, fetchBonusTerm } = useOfferTerms(singleBonus);
  const { handleOfferAction, error, status, statusCode } = useHandleBonusActions({
    bonus: singleBonus,
    notificationId: notification.id,
  });

  const { t } = useTranslation();

  const { frameImgSrc } = reelCampaignImages(animationParams.folder);

  const { stopSpin, startSpin, isSpinFinished, isSpinning } = useReelAnimation();

  if (!singleBonus) return null;

  return (
    <>
      <GamificationUI.Header className="text-center">
        <Logo animationParams={animationParams} />
        <CTAText showWinText={animationComplete} text={header} winText={message} error={error} />
      </GamificationUI.Header>

      <ReelsGridWrapper>
        <SpringReelItems
          notification={notification}
          animationComplete={animationComplete}
          animationParams={animationParams}
          setAnimationComplete={setAnimationComplete}
          isSpinning={isSpinning}
          spinFinished={stopSpin}
          reelSequences={reelSequences}
        />
        <ReelsFrame src={frameImgSrc} />
      </ReelsGridWrapper>

      <Flex.ColAC className="mt-auto">
        <CTAButton
          buttonText={
            animationComplete
              ? singleBonus?.buttonText
              : isSpinFinished
                ? t(Promotions.Gamification.chooseReelBtnText)
                : t(Promotions.Gamification.wheelSpinBtnText)
          }
          disabled={status === 'pending' || isSpinning || (isSpinFinished && !animationComplete)}
          errorText={error}
          onClick={animationComplete ? handleOfferAction : startSpin}
          isVisible={true}
          statusCode={statusCode}
          text={subheader}
          bgColor={style.backgroundButton}
          textColor={style.buttonTextColor}
          borderColor={style.buttonBorderColor}
        />

        <Link
          className="py-1 text-center"
          isActive={showTerms}
          isVisible={animationComplete}
          onClick={fetchBonusTerm}
          textColor={style.termsTextColor}
        />
      </Flex.ColAC>

      <GamificationUI.Terms isVisible={showTerms} onClickOutside={outsideClick} text={offerTerms} />
    </>
  );
};

export default memo(SpringReelContainer);
