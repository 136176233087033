import clsx from 'clsx';
import { Flex } from 'components/shared/layout';

interface CellProps {
  className?: string;
  isSpanned?: boolean;
  cellText?: string;
  cellValue?: string | number;
}

const Cell: React.FC<CellProps> = ({ className, isSpanned, cellText, cellValue }) => {
  const cellClass = clsx('financial-info-table__cell', isSpanned && 'financial-info-table__cell--span-all', className);

  return (
    <Flex.ACJB className={cellClass}>
      <div className="financial-info-table__cell-text">{cellText}</div>
      <div className="financial-info-table__cell-value">{cellValue}</div>
    </Flex.ACJB>
  );
};

export default Cell;
