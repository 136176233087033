import { cdnConfig, fitCover } from 'utils/common/cdnConfig';
import config from 'utils/config';
import { NotificationTypes } from '../../../notificationTypes';
import { sizeImage } from '../utils/popUpSliderNotificationModal.utils';

type Props = {
  type: 'carousel' | 'thumb';
  device: 'desktop' | 'mobile';
  item: NotificationTypes.PopUpNotification;
};
export const SliderNotificationsImage: React.FC<Props> = ({ type, item, device }) => {
  if (!item?.style?.bannerResourceLocation) return null;

  return (
    <img
      className={`ns-${type}__banner w-100`}
      src={`${config.CDN_IMAGE_API}${fitCover(sizeImage[type][device].width, sizeImage[type][device].height)}${
        cdnConfig.NOTIFICATIONS_MODULE
      }/${item?.style?.bannerResourceLocation}`}
    />
  );
};
