import styled from 'styled-components';
import { Breakpoints, ThemedIcon } from 'modules/casino/theme/Theme';
import config from 'utils/config';

const ProviderLogo = styled.img.attrs<{ $icon: number }>((props) => ({
  src: `${config.CDN_URL}/gaming-content/game-providers-new/logos/` + props.$icon + ThemedIcon() + '.svg',
}))<{ $icon: number }>`
  margin-bottom: -1px;
  max-width: 48px;
  margin-left: 5px;
  @media screen and (min-width: ${Breakpoints.isMobile}px) {
    margin-left: 10px;
  }
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    max-width: 68px;
  }
`;

export default ProviderLogo;

ProviderLogo.displayName = 'ProviderLogo';
