import React, { useLayoutEffect, useRef } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
type Props = {
  symbol?: string;
  handleInputCode: (character: string, idx: number) => void;
  isDisabled?: boolean;
  index: number;
  isFocus?: boolean;
  hasError?: boolean;
};
export const CodeFormItem: React.FC<Props> = ({
  symbol,
  handleInputCode,
  isDisabled = true,
  index,
  isFocus = false,
  hasError,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useLayoutEffect(() => {
    isFocus && inputRef.current && inputRef.current.focus();
  }, [inputRef, isFocus]);

  const classes = clsx('modal-tfa__code-item', hasError && 'modal-tfa__code-item--error');

  return (
    <StyledCell className={classes}>
      <StyledInput
        ref={inputRef}
        type="text"
        value={symbol ? symbol : ''}
        className="wh-100"
        disabled={isDisabled}
        maxLength={1}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          !isDisabled && e.eventPhase === 3 && e.target.value === '' && handleInputCode(e.target.value, index);
        }}
        onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
          !isDisabled && handleInputCode(e?.data, index);
          // e.eventPhase !== 3 && !isDisabled && handleInputCode(e?.data, index);
          return;
        }}
        onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
          if (!isDisabled && e?.clipboardData?.getData('text')) {
            handleInputCode(e?.clipboardData?.getData('text'), index);
          }
          return;
        }}
      />
    </StyledCell>
  );
};

const StyledCell = styled.div`
  font-size: 32px;
  text-align: center;
  overflow: hidden;
  font-weight: 500;
`;
const StyledInput = styled.input`
  border: none;
  text-align: center;
  font-weight: 500;
  padding: 0;
  &:focus {
    outline: 0;
  }
`;
