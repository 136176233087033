import React from 'react';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import config from 'utils/config';

type Props = {
  iconResource?: string;
  className?: string;
};

const PrizeDropIcon: React.FC<Props> = ({ iconResource, className }) => {
  const iconPath = `${config.CDN_URL}/${iconResource}`;
  const iconClass = clsx('icon', className);

  return <SVG className={iconClass} src={iconPath} cacheRequests={true} />;
};

export default PrizeDropIcon;
