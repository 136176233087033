import styled from 'styled-components';

const GameCellWrapperStyled = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: grid;
  align-items: flex-end;
`;
export default GameCellWrapperStyled;
GameCellWrapperStyled.displayName = 'GameCellWrapperStyled';
