import { CategoryQueryType } from 'modules/casino/shared/constants';
import { SvaraLaunchTypes } from '../casinoLaunchConstants';
import { GamePlayType } from '../casinoLaunchTypes';

export const useSvaraLaunchParam = (queryType: CategoryQueryType) => {
  const getSvaraLaunchParam = ({
    gamePlayType,
    isCreate,
    isFreeCustom,
    isPrivate,
  }: {
    gamePlayType?: GamePlayType;
    isFreeCustom?: boolean;
    isPrivate?: boolean;
    isCreate?: boolean;
  }): SvaraLaunchTypes => {
    if (queryType === CategoryQueryType.SVARA_CATEGORY) {
      if (isCreate) return SvaraLaunchTypes.CREATE;
      if (isPrivate) return SvaraLaunchTypes.PRIVATE;
      if (gamePlayType === GamePlayType.PAID) return SvaraLaunchTypes.PAID;
      if (gamePlayType === GamePlayType.FREE && isFreeCustom) {
        return SvaraLaunchTypes.FREE_CUSTOM;
      } else {
        return SvaraLaunchTypes.FREE;
      }
    } else {
      return SvaraLaunchTypes.LOBBY;
    }
  };

  return { getSvaraLaunchParam };
};
