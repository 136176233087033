import React from 'react';
import { Flex } from 'components/shared/layout';

/** Auth Simple Panel */
interface PageProps {
  children: React.ReactNode;
}

const Panel: React.FC<PageProps> = ({ children }) => {
  return <Flex.ColJB className={'mx-auto auth-panel__wrapper auth-panel__content'}>{children}</Flex.ColJB>;
};

export default Panel;
