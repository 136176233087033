import React, { useEffect, memo } from 'react';
import { useScrollToBackPosition } from 'hooks/useScrollToBackPosition';
import { selectBackdropVisibility } from 'shared/common/features/appBackdrop/slice/appBackdrop.slice';
import { useAppSelector } from 'store';
import { selectBottomTabBar } from '../bottomTabBar/slice/bottomTabBar.slice';

const AppBackdrop: React.FC = () => {
  const isBackdropVisible = useAppSelector(selectBackdropVisibility);
  //const isBetTicketLoading = useAppSelector(selectBetSlip.isBetTicketLoading);
  const isMyBetsVisible = useAppSelector(selectBottomTabBar.isMyBetsVisible);
  const { scrollToBack } = useScrollToBackPosition();

  useEffect(() => {
    if (isBackdropVisible || isMyBetsVisible) {
      scrollToBack(true, true);
      return () => {
        scrollToBack(false, true);
      };
    }
  }, [isBackdropVisible, isMyBetsVisible]);

  if (!isBackdropVisible) {
    return null;
  }

  return <div className={'backdrop backdrop--default'} />;
};

export default memo(AppBackdrop);
