import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppLogo } from 'components/shared';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import BurgerAccount from './components/BurgerAccount';
import BurgerNavWrapper from './components/BurgerNavWrapper';
import BurgerSocial from './components/BurgerSocial';
import { BurgerUI } from './components/BurgerUI';
//import ChatButton from '../../chat/ChatButton';
import { DrawerSearch, HelpDrawerSection } from '../drawer/components';
import CasinoDrawerSectionLayout02 from '../drawer/components/casino/CasinoDrawerSectionLayout02';
import ESportDrawerSectionLayout02 from '../drawer/components/esport/EsportDrawerSectionLayout02';
import FastSettingsDrawerSection from '../drawer/components/fastSettings/FastSettingsDrawerSection';
import LiveSportDrawerSectionLayout02 from '../drawer/components/liveSport/LiveSportDrawerSectionLayouto2';
import SportDrawerSectionLayout02 from '../drawer/components/sport/SportDraweSectionLayout02';
import VirtualDrawerSectionLayout02 from '../drawer/components/virtual/VirtualDrawerSectionLayout02';
import { setDrawer } from '../drawer/slice/drawer.slice';
import { getDrawerHeaderItemType } from '../drawer/utils/drawer.utils';
import { navigateToCasinoIntegration, navigateToIntegration } from '../integrations/utils/integrations.utils';
import { selectNavigationMenu, setExpandedSection } from '../navigationMenu/slice/navigationMenu.slice';
import { BurgerMenuLayoutMenuItems02 } from '../navigationMenu/types/navigationMenu.types';
// import BurgerSocial from './components/BurgerSocial';

/** BurgerDemo */
interface BurgerMenuProps {
  className?: string;
  children?: React.ReactNode;
  hasSearchField: boolean;
}

const BurgerMenuLayout02: React.FC<BurgerMenuProps> = ({ hasSearchField }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const menuItemsLayout02 = useAppSelector(selectNavigationMenu.menuItemsLayout02);
  const copyrightText = useAppSelector(selectNavigationMenu.copyrightBurgerMenu);
  const hasHelpSection = menuItemsLayout02?.some((item) => item?.type === 'help');
  const hasSettingsSection = menuItemsLayout02?.some((item) => item?.type === 'systemsettings');

  const onClose = () => {
    dispatch(setDrawer(false));
  };

  const toggleSections = (
    route: string,
    sectionId: number,
    verticalType: string | undefined,
    sectionType: string,
    isExternalRoute: boolean | undefined,
  ) => {
    if (
      !isEmpty(menuItemsLayout02) &&
      ((verticalType === 'casino' && sectionType !== 'custom') ||
        verticalType === 'sport' ||
        sectionType === 'systemsettings' ||
        sectionType === 'help')
    ) {
      dispatch(setExpandedSection(sectionId));
    } else {
      if (!isEmpty(route) && !isExternalRoute) {
        navigate(route);
        navigateToIntegration({ fullPath: route });
        navigateToCasinoIntegration({ fullPath: route });
      } else {
        window.open(route);
      }
      onClose();
    }
  };

  const expandableSections = (type: string, section: BurgerMenuLayoutMenuItems02) => {
    switch (type) {
      case 'sport':
        return <SportDrawerSectionLayout02 section={section} />;

      case 'live-sport':
        return <LiveSportDrawerSectionLayout02 />;

      case 'casino':
        return <CasinoDrawerSectionLayout02 section={section} />;

      case 'esports':
        return <ESportDrawerSectionLayout02 section={section} />;

      case 'virtuals':
        return <VirtualDrawerSectionLayout02 />;

      default:
        return null;
    }
  };

  return (
    <BurgerUI.Wrapper>
      <BurgerUI.Header>
        <div />
        <AppLogo />
        <div />
      </BurgerUI.Header>
      <BurgerUI.Close onClick={onClose} />

      {hasSearchField && <DrawerSearch showSeparator />}

      <BurgerUI.Separator className="mb-2" />

      <BurgerAccount />
      <BurgerUI.Content>
        {menuItemsLayout02?.map((item, index) => (
          <div key={index}>
            {item?.type === 'help' && hasHelpSection && <BurgerUI.Separator className="my-4" />}
            {item?.type === 'systemsettings' && !hasHelpSection && hasSettingsSection && (
              <BurgerUI.Separator className="my-4" />
            )}
            <BurgerNavWrapper
              index={index}
              iconName={item?.icon}
              iconColor={item?.iconColor}
              textName={item?.name}
              toggleSportTiles={toggleSections}
              isOpened={item?.isOpened}
              sectionType={getDrawerHeaderItemType(item)}
              route={item?.route}
              verticalType={item?.verticalType}
              concreteType={item?.concreteType}
              isExternalRoute={item?.isExternalRoute}
            />
            {item?.isOpened ? expandableSections(item?.type, item) : null}
            {item?.isOpened && item?.type === 'help' && <HelpDrawerSection isActive={true} />}
            {item?.isOpened && item?.type === 'systemsettings' && <FastSettingsDrawerSection section={item} />}
          </div>
        ))}

        <BurgerSocial />
        {/* <ChatButton /> */}
        <BurgerUI.Text text={copyrightText} />
      </BurgerUI.Content>
    </BurgerUI.Wrapper>
  );
};

export default BurgerMenuLayout02;
