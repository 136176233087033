import { memo } from 'react';
import Item from './Item';
import ItemFilter from './ItemFilter';
import Logo from './Logo';
import SearchIcon from './SearchIcon';
import Spacer from './Spacer';
import Wrapper from './Wrapper';

export const ProvidersBarUI = {
  Item: memo(Item),
  ItemFilter: memo(ItemFilter),
  Logo: memo(Logo),
  SearchIcon: memo(SearchIcon),
  Spacer: Spacer,
  Wrapper: Wrapper,
};
