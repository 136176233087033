import { useTranslation } from 'react-i18next';
import { NoDataMessage } from 'components/shared';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { GameLaunchTranslationKeys } from '../../../gameLaunch/casinoLaunchConstants';
import { TopBarTypes } from '../topBar.types';
import { TopBarPopover } from '../TopBarPopover';

export const CampaignMissionsProgressPopover: React.FC<TopBarTypes.MissionsCollectionPopover> = ({
  parentRef,
  handleClose,
  text,
  missionsTitle,
  missionId,
  show,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <TopBarPopover handleClose={handleClose} parentRef={parentRef} show={show}>
        {!missionId ? (
          <NoDataMessage message={t(GameLaunchTranslationKeys.noMissionPopoverText)} />
        ) : (
          <div className={`${cssGlobalNS}-gl__popover-wrapper`}>
            {text} {missionsTitle}
          </div>
        )}
      </TopBarPopover>
    </>
  );
};
