import React from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from 'theme/Theme';

interface Props {
  isMobileAgnostic?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const FormGroup: React.FC<Props> = ({ isMobileAgnostic, className, children }) => {
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });

  const cssNS = 'form-group';

  const formGroupStyles = clsx(
    cssNS,
    isMobileAgnostic ? (isDesktop ? `${cssNS}-default` : `${cssNS}-simple`) : `${cssNS}-default`,
    className ? className : 'col-12',
  );

  return <div className={formGroupStyles}>{children}</div>;
};
export default FormGroup;
