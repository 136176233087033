import { useAuthentication } from 'hooks/common/useAuthentication';
import { useAppDispatch, useAppSelector } from 'store';
import { claimTicketThunks, selectClaimTicket, setFormStep } from '../slice/claimTicket.slice';

const useClaimTicket = () => {
  const ticketInfo = useAppSelector(selectClaimTicket.claimTicketInfo);
  const claimTicketStatus = useAppSelector(selectClaimTicket.claimTicketStatus);
  const isLoading = claimTicketStatus === 'pending';
  const { isAuthenticated, showLoginForm } = useAuthentication();
  const dispatch = useAppDispatch();

  const handleClaimTicket = async () => {
    if (!isAuthenticated) return showLoginForm();
    await dispatch(claimTicketThunks.claimTicket());
    dispatch(setFormStep(3));
  };

  return { isAuthenticated, ticketInfo, handleClaimTicket, isLoading };
};

export default useClaimTicket;
