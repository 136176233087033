import React from 'react';

type Props = {
  children?: React.ReactNode;
  items: React.ReactNode;
};

export const OfferBonusLayout01: React.FC<Props> = ({ items }) => {
  return (
    <div data-ics--global data-ics--notification>
      {items}
    </div>
  );
};
