import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { notificationThunks, selectNotification, setActiveInboxNotificationId } from '../notificationSlice';
import { NotificationTypes } from '../notificationTypes';

export const useNotificationTab = (parentRef?: React.MutableRefObject<HTMLDivElement | null>) => {
  const activeNotification = useAppSelector(selectNotification.activeInboxNotification);
  const inboxNotifications = useAppSelector(selectNotification.inboxNotifications);
  const status = useAppSelector(selectNotification.inboxNotificationsStatus);
  const updateStatus = useAppSelector(selectNotification.updateNotificationsStatus);
  const error = useAppSelector(selectNotification.inboxNotificationsError);
  const [selectedMessages, setSelectedMessages] = useState<string[]>([]);
  const showNotificationManagement = !isEmpty(selectedMessages) && isEmpty(activeNotification);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // scroll to top when open notification
  if (!isEmpty(activeNotification) && parentRef?.current) {
    parentRef.current?.scrollTo({ top: 0 });
  }
  useEffect(() => {
    return () => {
      dispatch(setActiveInboxNotificationId(''));
    };
  }, [dispatch]);

  const handleSelectMessage = (notificationId: string) => {
    dispatch(setActiveInboxNotificationId(notificationId));
  };

  useEffect(() => {
    if (updateStatus === 'succeeded') {
      dispatch(notificationThunks.fetchInboxNotification());
      setSelectedMessages([]);
    }
  }, [dispatch, updateStatus]);

  const handleUpdateMessages = useCallback(
    (type: NotificationTypes.DeleteMessagesType) => {
      dispatch(notificationThunks.updateNotifications({ messagesIds: selectedMessages, type: type }));
    },
    [dispatch, selectedMessages],
  );

  const handleSelect = useCallback(
    (notificationId: string) => {
      const removeId = selectedMessages.includes(notificationId);
      if (removeId) {
        setSelectedMessages((prevState) => {
          const newState = prevState.filter(function (ele) {
            return ele != notificationId;
          });
          return newState;
        });
      } else {
        setSelectedMessages([...selectedMessages, notificationId]);
      }
    },
    [setSelectedMessages, selectedMessages],
  );

  return {
    status,
    inboxNotifications,
    error,
    activeNotification,
    handleUpdateMessages,
    handleSelectMessage,
    handleSelect,
    t,
    selectedMessages,
    updateStatus,
    showNotificationManagement,
  };
};
