import React from 'react';
import { DRAWER_HEADER_SCROLL_EVENT } from 'components/shared/ScrollContainer';
import { DrawerUI } from './DrawerUI';
import { BurgerMenuLayoutMenuItems02 } from '../../navigationMenu/types/navigationMenu.types';
import useDrawerActiveClass from '../hooks/useDrawerActiveClass';
import { getDrawerHeaderItemType } from '../utils/drawer.utils';

interface Props {
  item: BurgerMenuLayoutMenuItems02;
}

const DrawerHeaderItem: React.FC<Props> = ({ item }) => {
  const { name, icon, iconColor, route, type, concreteType, sectionType, isExternalRoute = false } = item;
  const { isActive, handleOnClick } = useDrawerActiveClass({
    itemType: getDrawerHeaderItemType(item),
    itemRoute: route,
    sectionType: sectionType,
    isExternalRoute,
  });

  if (isActive) {
    document.dispatchEvent(new Event(DRAWER_HEADER_SCROLL_EVENT));
  }
  return (
    <DrawerUI.Tab
      onClick={handleOnClick}
      icon={icon || ''}
      iconColor={iconColor}
      text={name}
      isActive={isActive}
      dataQid={type}
      dataCpp={concreteType}
    />
  );
};

export default DrawerHeaderItem;
