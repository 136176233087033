import { memo } from 'react';
import { Text } from 'components/shared';
import { Flex } from 'components/shared/layout';
import ImageComponent from './ImageComponent';
import { FooterTypes } from '../types/appFooter.types';
interface Props {
  section: FooterTypes.Section;
}

const PoweredBy: React.FC<Props> = ({ section }) => {
  if (!section.text) {
    return null;
  }

  return (
    <>
      <Flex className="footer-power-by__item d-flex-center w-100">
        <Text type="p" className="mb-0 footer-text mr-2" text={section.text} />
        {section.icon && <ImageComponent imgRef={section?.icon} route={''} />}
      </Flex>
    </>
  );
};

export default memo(PoweredBy);
