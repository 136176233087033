import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { selectPopOver, showPopOver } from 'shared/common/features/popover/popoverContainer/slice/popOver.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { BonusBtn } from '../BonusBtn';
import { EventLocationTypes } from '../types/eventProps.types';
import { getEnhancedMarketBonusInfo } from '../utils/event.utils';

/** EventUI Neutral Ground Toolip */
interface EnhancedOddsProps {
  componentLocation: EventLocationTypes;
  sportEventId: number;
}

// from sportBonuses.types.ts
type PromotionInfo = {
  promotionId: number;
  alias: string;
  categoryAlias: string;
  title: string;
  description: string;
  type?: string;
};

export const EnhancedMarkets: React.FC<EnhancedOddsProps> = ({ sportEventId }) => {
  const activeBonus = useAppSelector(selectPopOver.props);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const enhancedOddsBonus = getEnhancedMarketBonusInfo();

  const handleActiveBonus = useCallback(
    (e: React.MouseEvent<HTMLElement>, bonus: PromotionInfo) => {
      e.stopPropagation();
      if (wrapperRef.current) {
        const elPosition = wrapperRef.current.getBoundingClientRect();
        dispatch(
          showPopOver({
            modalProps: {
              top: elPosition.top,
              padding: wrapperRef.current.clientHeight + 5,
              // left: wrapperRef.current.offsetLeft,
              left: elPosition.x,
              title: bonus.title,
              show: true,
              descriptions: bonus.description,
              linkText: '',
              promotionId: bonus?.promotionId,
              id: sportEventId,
              location: 'all',
              categoryAlias: bonus.categoryAlias,
              alias: bonus.alias,
              wrapperRadius: true,
            },
          }),
        );
      }
    },
    [dispatch],
  );

  return (
    <StyledWrapper ref={wrapperRef}>
      <BonusBtn
        text={''}
        icon="percent"
        type="eventHeader"
        isActive={activeBonus?.id === sportEventId && activeBonus?.promotionId === enhancedOddsBonus.promotionId}
        isCircle={true}
        onClick={(e) => handleActiveBonus(e, enhancedOddsBonus)}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div.attrs({
  className: 'd-flex',
})`
  margin-right: 8px;
`;
