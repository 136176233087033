import Amount from './Amount';
import BoostProfit from './BoostProfit';
import FooterActions from './FooterActions';
import FooterButton from './FooterButton';
import OddsBoostToken from './OddsBoostToken';
import PossibleBoostWin from './PossibleBoostWin';
import PrevOdds from './PrevOdds';
import BonusIndicator from '../BetSlipSharedUI/BonusIndicator';

export const BetSlipSharedUI = {
  PossibleBoostWin: PossibleBoostWin,
  BoostProfit: BoostProfit,
  Amount: Amount,
  BonusIndicator: BonusIndicator,
  PrevOdds: PrevOdds,
  OddsBoostToken: OddsBoostToken,
  FooterActions: FooterActions,
  FooterButton: FooterButton,
};
