import { useEffect } from 'react';
import { useAppSelector } from 'store';
import { selectTicketData } from '../../../shared/slice/checkTicket.slice';
import { selectRgbControllerData } from '../features/rgbController/slice/rgbController.slice';
import { defaultColors, sportColorsMap } from '../features/rgbController/utils/rgbController.utils';
import { isEventLive, setColorRgbController } from '../utils/ssbt.utils';

export const useRgbController = (): void => {
  const ticketInfo = useAppSelector(selectTicketData.ticketInfo);
  const retailActiveSportId = useAppSelector(selectRgbControllerData.retailActiveSportId);

  useEffect(() => {
    const eventLiveStatus = isEventLive(ticketInfo) || [];

    switch (true) {
      case ticketInfo?.ticket?.totalWin === 0 &&
        ticketInfo?.ticket?.totalPossibleWin === 0 &&
        !eventLiveStatus.includes(true):
        setColorRgbController('255', '30', '0');
        break;
      case ticketInfo?.ticket?.totalWin !== 0 &&
        ticketInfo?.ticket?.totalPossibleWin === 0 &&
        !eventLiveStatus.includes(true):
        setColorRgbController('0', '255', '0');
        break;
      case eventLiveStatus.includes(true):
        setColorRgbController('0', '0', '255');
        break;
      case ticketInfo?.ticket?.totalPossibleWin !== 0 &&
        ticketInfo?.ticket?.totalWin === 0 &&
        !eventLiveStatus.includes(true):
        setColorRgbController('0', '0', '255');
        break;
      default:
        setColorRgbController('255', '0', '0');
    }
  }, [ticketInfo]);

  useEffect(() => {
    const { red, green, blue } = sportColorsMap?.[retailActiveSportId] || defaultColors;
    setColorRgbController(red, green, blue);
  }, [retailActiveSportId]);
};
