import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FrameAnimator } from 'components/shared/FrameAnimator';
import { getCdnImages } from 'components/shared/FrameAnimator/utils/ImageCDN';
import { mapAnimationFrames } from 'components/shared/FrameAnimator/utils/images/utils';
import { NotificationTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationTypes';
import ClickIcon from './components/ClickIcon';
import { PrizeCard } from './components/PrizeCard.styled';
import { ReelFrameFade, ReelFrameGlow } from './components/ReelFrame.styled';
import useGamificationItemState from '../../hooks/useGamificationItemState';

interface Props {
  index: number;
  notification: NotificationTypes.PopUpNotification;
  winItemIndex: null | number;
  setWinItemIndex: (index: number) => void;
  setAnimationComplete: (isVisible: boolean) => void;
  animationComplete: boolean;
  animationParams: NotificationTypes.GamificationModalParams;
  isSpinning: boolean;
  spinFinished: () => void;
  reelId: number;
}

const SpringReelItem: React.FC<Props> = ({
  index,
  notification,
  winItemIndex,
  setWinItemIndex,
  setAnimationComplete,
  animationComplete,
  animationParams,
  isSpinning,
  spinFinished,
  reelId,
}) => {
  const isClickDisabled = useRef(true);

  const { bonusData, frameAudioUrl } = notification;

  const [showGlow, setShowGlow] = useState(false);
  const { state, handleAnimationComplete, handleAnimation } = useGamificationItemState({
    bonusData,
    animationComplete,
    winItemIndex,
    index,
    setWinItemIndex,
    setAnimationComplete,
    notificationId: notification.id,
    frameAudioUrl,
  });

  useEffect(() => {
    winItemIndex !== null && handleAnimationComplete();
  }, [winItemIndex, handleAnimationComplete]);

  const onReelClick = () => {
    !isClickDisabled.current && handleAnimation();
    !isClickDisabled.current && handleAnimationComplete();
  };

  const onReelFinish = () => {
    isClickDisabled.current = false;
    setShowGlow(true);
    spinFinished();
  };

  const spinReelSequence = useMemo(() => {
    return getCdnImages(mapAnimationFrames(animationParams, `spin/${reelId}/${animationParams.fileBaseName}`));
  }, [animationParams, reelId]);

  const showPrize = state.giftTitle && state.playAnim;

  const wrapperStyle = { gridColumn: index + 1, gridColumnEnd: index + 1 };
  const itemStyle = { gridRowStart: 1, gridRowEnd: 1, gridColumnStart: 1, gridColumnEnd: 1 };

  return (
    <ReelFrameFade $isFaded={false} style={wrapperStyle}>
      <FrameAnimator
        images={spinReelSequence}
        width={animationParams.frameWidth}
        height={animationParams.frameHeight}
        maxWidth={animationParams.frameMaxWidth}
        maxHeight={animationParams.frameMaxHeight}
        aspectRatioWidth={animationParams.frameAspectRatio}
        fps={60}
        iterations={1}
        play={isSpinning}
        onIterationComplete={onReelFinish}
        onClick={onReelClick}
        style={itemStyle}
      />

      {showGlow && (
        <ReelFrameGlow $glowDelay={index * 0.8} style={itemStyle}>
          {!showPrize && <ClickIcon />}
        </ReelFrameGlow>
      )}

      {showPrize && (
        <PrizeCard $isWinner={winItemIndex === index} style={itemStyle}>
          {state.giftTitle}
        </PrizeCard>
      )}
    </ReelFrameFade>
  );
};

export default SpringReelItem;
