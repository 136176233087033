import React from 'react';
import { useWindowDimensions } from 'hooks/useWindowDimenions';

interface Props {
  children: React.ReactNode;
}

const MinBodyHeightSetter = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  // const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isTabletOrMobile}px)` });

  const { height } = useWindowDimensions();

  // useLayoutEffect(() => {
  //   // if (!isTabletOrMobile) {
  //   //   document.body.classList.add('scrollable-layout');
  //   // }
  //   // return () => {
  //   //   document.body.classList.remove('scrollable-layout');
  //   // };
  // }, [isTabletOrMobile]);

  // useLayoutEffect(() => {
  //   document.body.style.minHeight = `${height}px`;
  //   return () => {
  //     document.body.style.minHeight = ``;
  //   };
  // }, [height, isTabletOrMobile]);

  return (
    <div {...props} ref={ref} className="wh-100 sport-layout" style={{ minHeight: `${height / 2}px` }}>
      {props.children}
    </div>
  );
});
MinBodyHeightSetter.displayName = 'MinBodyHeightSetter';
export default MinBodyHeightSetter;
