import React, { useEffect, useMemo, useRef } from 'react';
import throttle from 'lodash/throttle';
import { isIOS } from 'react-device-detect';
import { useCurrencyConfig } from 'modules/casino/shared/hooks/useCurrencyConfig';
import { getUniqueUuidv4, isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import { formatSumAmount } from 'modules/casino/shared/utils/helpersCasino';
import { useAppSelector } from 'store';
import { useInViewWithDelay } from '../hooks/useInViewWithDelay';
import { jackpotSelectors } from '../jackpotSlice';
import {
  isHardcodedLevel,
  thousandsSeparators,
  getDecreasedJackpotValue,
  getJackpotLevelWithHighestValue,
  getJackpotValueByLevel,
} from '../jackpotUtils';

interface JackpotOdometerProps {
  jackpotId: string;
  level?: number | string;
  stopOdometer?: boolean;
  denomination?: number;
  isTicker?: boolean;
}

type JackpotMap = {
  ref: React.MutableRefObject<HTMLElement | null>;
  jackpotValue: number;
  jackpotId: string | number;
  isRunning: boolean;
  jackpotLevel: number | string;
  nextJackpotVal: number;
  step: number;
  fps: number;
  then: number | null;
  delta: number | null;
  intervalId: number;
  uniqueId: string;
  isVisible: boolean;
  isOdometerStopped: boolean;
  currency: string;
};
// Controling delay animation of Odometer
// Just `then = now` is not enough.
// Lets say we set fps at 10 which means
// each frame must take 100ms
// Now frame executes in 16ms (60fps) so
// the loop iterates 7 times (16*7 = 112ms) until
// delta > interval === true
// Eventually this lowers down the FPS as
// 112*10 = 1120ms (NOT 1000ms).
// So we have to get rid of that extra 12ms
// by subtracting delta (112) % interval (100).
// Hope that makes sense.

const currentOdometerJackpotValue = {};
const jackpotsMap = new Map<string, JackpotMap>();
const VISIBLE = 'visible';

const stepValuesIncrement = [
  // Fine tuning
  { value: 1, step: 0.01, fps: 150 },
  { value: 2, step: 0.01, fps: 140 },
  { value: 3, step: 0.01, fps: 130 },
  { value: 4, step: 0.01, fps: 120 },
  { value: 5, step: 0.01, fps: 110 },
  { value: 6, step: 0.01, fps: 100 },
  { value: 7, step: 0.01, fps: 60 },
  { value: 8, step: 0.01, fps: 50 },
  { value: 9, step: 0.01, fps: 40 },
  { value: 10, step: 0.01, fps: 30 },
  // Mid tuning
  { value: 15, step: 0.11, fps: 100 },
  { value: 20, step: 0.11, fps: 90 },
  { value: 25, step: 0.11, fps: 70 },
  { value: 30, step: 0.11, fps: 60 },
  { value: 35, step: 0.11, fps: 50 },
  { value: 40, step: 0.11, fps: 40 },
  { value: 45, step: 0.11, fps: 30 },
  { value: 50, step: 0.11, fps: 20 },
  { value: 60, step: 0.11, fps: 50 },
  { value: 70, step: 0.11, fps: 30 },
  // Tuning
  { value: 80, step: 0.11, fps: 15 },
  { value: 160, step: 1.11, fps: 10 },
  { value: 320, step: 1.11, fps: 5 },
  { value: 640, step: 1.11, fps: 1 },
  { value: 1000, step: 11.11, fps: 1 },
  { value: 10000, step: 111.11, fps: 1 },
  { value: 100000, step: 1111.11, fps: 1 },
];

const stepValuesDecrement = [
  { value: 1, step: 0.01, fps: 30 },
  { value: 3, step: 0.11, fps: 25 },
  { value: 10, step: 1.11, fps: 20 },
  { value: 1000, step: 11.11, fps: 20 },
  { value: 10000, step: 111.11, fps: 10 },
  { value: 1000000, step: 1111.11, fps: 1 },
];

const customTune = {
  value: 200,
  diff: 5,
};

const getStepValues = (
  jackpotValue: number,
  nextJackpotVal: number,
): { step: number; fps: number; nextValue: number } | undefined => {
  const jackpotVal = jackpotValue && +jackpotValue.toFixed(2);
  const nextJackpotValue = nextJackpotVal && +nextJackpotVal.toFixed(2);
  const diff = nextJackpotValue - jackpotVal;

  if (diff === 0) {
    return;
  }

  const isDecreasing = diff < 0;
  const values = isDecreasing ? stepValuesDecrement : stepValuesIncrement;
  const { step = 1, fps = 1 } = values.find((obj) => Math.abs(diff) <= obj.value) || {};

  return {
    step,
    fps: isDecreasing ? fps : nextJackpotValue <= customTune.value && diff > customTune.diff ? 1 : fps,
    nextValue: isDecreasing ? jackpotVal - step : jackpotVal + step,
  };
};

const addJackpot = (uniqueId: string, jackpot: JackpotMap) => {
  jackpotsMap.set(uniqueId, jackpot);
};
const removeJackpot = (uniqueId: string) => {
  jackpotsMap.delete(uniqueId);
};
const isElementInViewPort = (ref) => {
  if (ref && ref.current) {
    return ref.current.classList.contains(VISIBLE);
    //return ref.current.className === VISIBLE;
  }
  return false;
};

const JackpotOdometer: React.FC<JackpotOdometerProps> = ({
  level,
  jackpotId,
  stopOdometer = false,
  denomination,
  isTicker,
}) => {
  const jackpotUpdate = useAppSelector((state) => jackpotSelectors.selectJackpotUpdate(state, jackpotId));
  const jackpotDenomDetails = useAppSelector((state) => jackpotSelectors.selectJackpotDenomDetails(state, jackpotId));
  const levelWithHighestValue = getJackpotLevelWithHighestValue(jackpotDenomDetails);
  const currency = useAppSelector((state) => jackpotSelectors.selectJackpotCurrencySign(state, jackpotId));
  const { formatToComma } = useCurrencyConfig();

  const ref = useRef<HTMLElement | null>(null);
  const jackpotLevel = useRef<number | string>(level ?? levelWithHighestValue);
  // const [jackpotRef, isVisible] = useInView({
  //   /* Optional options */
  //   root: isTicker ? document.querySelector('.ticker-wrapper') : null,
  //   rootMargin: '0px',
  //   threshold: 0.1,
  //   delay: 400,
  //   trackVisibility: true,
  // });
  const [jackpotRef, isVisible] = useInViewWithDelay(false, 500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const uniqueId = useMemo(() => getUniqueUuidv4(), [jackpotId]);
  const updateJackpotRefValue = (jackpot: JackpotMap, jackpotValue: number) => {
    if (jackpot && jackpot.ref.current) {
      jackpot.ref.current.innerText = jackpotValue
        ? formatSumAmount({ sum: thousandsSeparators(jackpotValue.toFixed(2)), formatToComma }).toString()
        : '0';
    }
  };
  const updateJackpotValue = (jackpot: JackpotMap, nextJackpotVal: number) => {
    jackpot.jackpotValue = nextJackpotVal;
  };
  const updateLastUpdatedOdometerValue = (jackpot: JackpotMap, value: number) => {
    currentOdometerJackpotValue[jackpot.currency][jackpot.jackpotId][jackpot.jackpotLevel] = value;
  };
  const cancelAnimateJackpotOdometerFrame = (intervalId) => {
    cancelAnimationFrame(intervalId);
  };
  const stopAnimateJackpotOdometer = (jackpot: JackpotMap) => {
    jackpot.isOdometerStopped = true;
    jackpot.isRunning = false;
  };
  const animateJackpot = (jackpot, intervalId, time) => {
    if (!jackpot || !jackpot.ref.current || jackpot.isOdometerStopped) {
      cancelAnimateJackpotOdometerFrame(intervalId);
      return;
    }

    const { step, fps, nextValue = 0 } = getStepValues(jackpot.jackpotValue, jackpot.nextJackpotVal) || {};

    jackpot.fps = fps;
    jackpot.step = step;
    jackpot.intervalId = intervalId;
    jackpot.isRunning = true;

    if (!jackpot.then) {
      jackpot.then = time;
    }

    jackpot.delta = time - jackpot.then;

    if (jackpot.delta > jackpot.fps) {
      jackpot.then = time - (jackpot.delta % jackpot.fps);
      if (isElementInViewPort(jackpot.ref)) {
        updateJackpotValue(jackpot, nextValue);
        updateJackpotRefValue(jackpot, jackpot.jackpotValue);
        updateLastUpdatedOdometerValue(jackpot, jackpot.jackpotValue);

        intervalId = requestAnimationFrame(function (timeStamp) {
          animateJackpot(jackpot, intervalId, timeStamp);
        });
      } else {
        if (!isElementInViewPort(jackpot.ref)) {
          updateJackpotValue(
            jackpot,
            currentOdometerJackpotValue[jackpot.currency][jackpot.jackpotId][jackpot.jackpotLevel],
          );
          updateJackpotRefValue(jackpot, jackpot.jackpotValue);
          updateLastUpdatedOdometerValue(jackpot, jackpot.jackpotValue);
        }
        stopAnimateJackpotOdometer(jackpot);
        cancelAnimateJackpotOdometerFrame(intervalId);
      }
    } else {
      if (isElementInViewPort(jackpot.ref)) {
        intervalId = requestAnimationFrame(function (timeStamp) {
          animateJackpot(jackpot, intervalId, timeStamp);
        });
      } else {
        stopAnimateJackpotOdometer(jackpot);
        cancelAnimateJackpotOdometerFrame(intervalId);
        jackpot.then = null;
      }
    }
  };
  const runAnimateJackpot = (jackpot: JackpotMap) => {
    if (!jackpot.isRunning) {
      jackpot.isOdometerStopped = false;
      animateJackpot(jackpot, 0, 0);
    }
  };

  useEffect(() => {
    const currentLevel = level ?? levelWithHighestValue;

    if (jackpotLevel.current !== null && jackpotLevel.current !== currentLevel) {
      jackpotLevel.current = currentLevel;
    }
  }, [level, levelWithHighestValue]);

  useEffect(() => {
    const jackpot = jackpotsMap.get(uniqueId);

    if (!jackpot) return;

    if (jackpotUpdate && !stopOdometer && jackpot.ref.current) {
      const nextJackpotVal = getJackpotValueByLevel({
        jackpotDenomDetails,
        level: jackpotLevel.current,
        denomination,
      });

      jackpot.nextJackpotVal = nextJackpotVal || 0;
      jackpot.jackpotValue = currentOdometerJackpotValue[jackpot.currency][jackpot.jackpotId][jackpot.jackpotLevel];

      if (isElementInViewPort(jackpot.ref)) {
        runAnimateJackpot(jackpot);
      } else if (!isElementInViewPort(jackpot.ref)) {
        if (jackpot.isRunning) {
          stopAnimateJackpotOdometer(jackpot);
        }
        updateJackpotValue(
          jackpot,
          currentOdometerJackpotValue[jackpot.currency][jackpot.jackpotId][jackpot.jackpotLevel],
        );
        updateJackpotRefValue(jackpot, jackpot.jackpotValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jackpotUpdate]);

  useEffect(() => {
    let jackpotValue = getJackpotValueByLevel({
      jackpotDenomDetails,
      level: jackpotLevel.current,
      denomination,
    });

    const nextJackpotVal = jackpotValue;
    const then = null;
    const delta = null;
    const isRunning = false;
    const isOdometerStopped = false;
    const intervalId = 0;
    const step = 0;
    const fps = 0;

    if (!currentOdometerJackpotValue[currency]) {
      currentOdometerJackpotValue[currency] = {};
    }

    if (!currentOdometerJackpotValue[currency][jackpotId]) {
      currentOdometerJackpotValue[currency][jackpotId] = {};
    }

    if (denomination) {
      jackpotLevel.current = denomination;
    }

    if (isEmpty(currentOdometerJackpotValue[currency][jackpotId][jackpotLevel.current])) {
      if (!isHardcodedLevel(jackpotUpdate?.jackpotCategoryDetails?.template?.toLowerCase(), level)) {
        jackpotValue = getDecreasedJackpotValue(jackpotValue);
      }
      currentOdometerJackpotValue[currency][jackpotId][jackpotLevel.current] = jackpotValue;
    } else {
      jackpotValue = currentOdometerJackpotValue[currency][jackpotId][jackpotLevel.current];
    }

    const jackpot: JackpotMap = {
      ref,
      jackpotValue,
      jackpotId,
      isRunning,
      jackpotLevel: jackpotLevel.current,
      nextJackpotVal,
      step,
      fps,
      then,
      delta,
      intervalId,
      uniqueId,
      isVisible,
      isOdometerStopped,
      currency,
    };

    if (!jackpotsMap.has(uniqueId)) {
      addJackpot(uniqueId, jackpot);
    }
    updateJackpotRefValue(jackpot, jackpotValue);
    const animateThrottle = throttle(() => {
      runAnimateJackpot(jackpot);
    }, 400);
    if (jackpot.nextJackpotVal > jackpot.jackpotValue && isElementInViewPort(ref)) {
      animateThrottle();
    }

    return () => {
      if (jackpotsMap.get(uniqueId)) {
        const jackpotResult = jackpotsMap.get(uniqueId);

        if (jackpotResult) {
          stopAnimateJackpotOdometer(jackpotResult);
        }
        removeJackpot(uniqueId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueId]);

  useEffect(() => {
    if (isTicker && isIOS) return;
    const jackpot = jackpotsMap.get(uniqueId);
    if (jackpot) {
      jackpot.isVisible = isVisible;
      if (isVisible) {
        updateJackpotRefValue(jackpot, jackpot.jackpotValue);
        const animateThrottle = throttle(() => {
          updateJackpotValue(
            jackpot,
            currentOdometerJackpotValue[jackpot.currency][jackpot.jackpotId][jackpot.jackpotLevel],
          );
          runAnimateJackpot(jackpot);
        }, 400);
        if (jackpot.nextJackpotVal > jackpot.jackpotValue) {
          animateThrottle();
        }
      } else {
        updateJackpotRefValue(jackpot, jackpot.jackpotValue);
        stopAnimateJackpotOdometer(jackpot);
        cancelAnimateJackpotOdometerFrame(jackpot.intervalId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  return (
    <span ref={jackpotRef}>
      <span
        ref={ref}
        className={`jackpot-value-container ${isTicker && isIOS ? VISIBLE : isVisible ? VISIBLE : ''}`}
      ></span>
    </span>
  );
};

export default JackpotOdometer;
// JackpotOdometer.whyDidYouRender = false;
