export const toRem = (pixelSize: number) => {
  return pixelSize * 0.0625;
};

const calculateSlope = (minSize: number, maxSize: number, minViewport: number, maxViewport: number) => {
  return Number(((toRem(maxSize) - toRem(minSize)) / (toRem(maxViewport) - toRem(minViewport))).toFixed(3));
};

const calculateYIntersection = (minViewport: number, slope: number, minSize: number) => {
  return Number((-1 * toRem(minViewport) * slope + toRem(minSize)).toFixed(3));
};

/**
 * @param {string} cssProp - CSS Property to clamp
 * @param {number} minSize - [Pixels] Object min size
 * @param {number} maxSize - [Pixels] Object max size
 * @param {number} minViewport - [Pixels] Min viewport width (for minSize)
 * @param {number} maxViewport - [Pixels] Max viewport width (for minSize)
 * @returns Clampled CSS Property
 */
export const clampedSize = (
  cssProp = 'font-size',
  minSize: number,
  maxSize: number,
  minViewport = 375,
  maxViewport = 1025,
) => {
  const asd = `${cssProp}: clamp(${toRem(minSize)}rem,${calculateYIntersection(
    minViewport,
    calculateSlope(minSize, maxSize, minViewport, maxViewport),
    minSize,
  )}rem + calc(${calculateSlope(minSize, maxSize, minViewport, maxViewport)} * 100vw),${toRem(maxSize)}rem)`;

  return asd;
};
