import React from 'react';
import clsx from 'clsx';
import { CSS } from 'utils/common/types/css.types';
import Icon from '../Icon';

const statusIconMap = {
  positive: { css: `color--success`, icon: 'check-solid' },
  neutral: { css: `color--neutral`, icon: 'times-solid' },
  info: { css: `color--info`, icon: 'check-solid' },
  negative: { css: `color--danger`, icon: 'times-solid' },
  error: { css: `color--error`, icon: 'times-solid' },
  soldChips: { css: `color--success`, icon: 'poker-sold-chips' },
  buyIn: { css: `color--danger`, icon: 'poker-buy-in' },
  refund: { css: `color--neutral`, icon: 'poker-refund' },
  none: { css: '', icon: '' },
  warning: { css: `color--warning`, icon: 'times-solid' },
} as const;

type StatusIconTypes = keyof typeof statusIconMap;

interface StatusIconProps {
  size: CSS.Sizes;
  status: StatusIconTypes;
  spacingLeft?: boolean;
  spacingRight?: boolean;
  className?: string;
}

const StatusIcon: React.FC<StatusIconProps> = ({ size, status, spacingLeft, spacingRight, className }) => {
  const iconClass = clsx(
    className,
    spacingLeft && 'ml-1',
    spacingRight && 'mr-1',
    'status-icon',
    `status-icon--${size}`,
    `status-icon--${status}`,
    statusIconMap[status].css,
  );

  if (status === 'none') return <div className={iconClass} />;

  return <Icon defaultClassName={false} className={iconClass} icon={statusIconMap[status].icon} />;
};

export default StatusIcon;
