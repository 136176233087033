import { FormGroup } from 'components/shared/forms/components';
import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';
import FinancialDatePicker from '../../cashierModalUI/FinancialDatePicker';

export const ReprintTicketDateControls = ({ setSelectedDates, selectedDate, t }) => {
  const handleStartDateChange = (date: Date | null) => {
    setSelectedDates((prevState) => ({
      ...prevState,
      startDate: date,
    }));
  };
  const handleEndDateChange = (date) => {
    setSelectedDates((prevState) => ({
      ...prevState,
      endDate: date,
    }));
  };
  return (
    <>
      <FormGroup className="reprint-ticket-form">
        <FinancialDatePicker
          label={t(CashierTranslations.FinancialModal.startDate)}
          dateFormat="dd/MM/yyyy"
          onChange={(date) => handleStartDateChange(date)}
          value={
            selectedDate.startDate
              ? new Date(setSelectedDates.startDate)
              : t(CashierTranslations.FinancialModal.chooseDay)
          }
          selected={selectedDate.startDate ? new Date(selectedDate.startDate) : null}
          dataQid="startDate"
          maxDate={selectedDate.endDate ? new Date(selectedDate.endDate) : new Date()}
        />
      </FormGroup>
      <FormGroup className="reprint-ticket-form">
        <FinancialDatePicker
          label={t(CashierTranslations.FinancialModal.endDate)}
          dateFormat="dd/MM/yyyy"
          onChange={(date) => handleEndDateChange(date)}
          value={
            selectedDate.endDate ? new Date(setSelectedDates.endDate) : t(CashierTranslations.FinancialModal.chooseDay)
          }
          selected={selectedDate.endDate ? new Date(selectedDate.endDate) : null}
          dataQid="endDate"
          minDate={selectedDate.startDate ? new Date(selectedDate.startDate) : undefined}
          maxDate={new Date()}
        />
      </FormGroup>
    </>
  );
};
