import React from 'react';
import clsx from 'clsx';
import PageBoxTitle from './PageBoxTitle';

interface PageBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  isContent?: boolean;
  className?: string;
  title?: string;
  titleColor?: string;
  children: React.ReactNode;
}

const PageBox: React.FC<PageBoxProps> = ({ isContent, className, title, titleColor, children, ...rest }) => {
  const boxClass = clsx(isContent ? 'overflow-hidden prm-page__wrapper prm-page__content' : 'prm-page__box', className);
  return (
    <div className={boxClass} {...rest}>
      {title && <PageBoxTitle title={title} titleColor={titleColor} />}
      {children}
    </div>
  );
};

export default PageBox;
