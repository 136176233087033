/* eslint-disable import/order */
// if order is changed, app does not start
// import './wdyr';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { store, persistor } from './store';
import App from './App';
import { WindowConfigInterface } from 'utils/common/helpersCommon';
import { PersistGate } from 'redux-persist/integration/react';
import { CookiesProvider } from 'react-cookie';
import { CDNResponse } from 'components/shared/FrameAnimator/utils/CDN';
import { setActiveDomainLayoutConfig } from 'utils/config';
import { SportInitConfig, SportInitObject } from 'modules/sport/sportsBook/types/sportsImplementationPage.types';
import { FTWidgets } from 'pages/fantasyScout/types/fantasyScout.types';
import { StableNavigateContextProvider } from 'modules/casino/shared/utils/common/StableNavigateContext';
import { FantasyStarsTypes } from 'pages/fantasyStars/types/fantsyStars.types';
import { CasinoWidgetTypes } from 'modules/casino/platformImplementation/types/casinoWidget.types';

// Reflecting devAPISource changes in DEV mode
setActiveDomainLayoutConfig();

declare global {
  interface Window {
    SIR;
    avvpl;
    config: WindowConfigInterface;
    _PaymentIQCashierReset;
    ApplePaySession;
    vsmobile;
    isRNWebView: boolean;
    Cypress;
    store;
    appVersion?: string;
    layout;
    dataFromParent;
    sportbookLayout;
    ReactNativeWebView: {
      postMessage(msg: string): void;
    };
    ftWidgets: FTWidgets;
    ftStars: FantasyStarsTypes.Widget | null;
    loadedCDNs: Array<CDNResponse>;
    _smartico_user_id: string | null;
    _smartico_language: string | null;
    _smartico;
    XNaveSportsbook: (config: SportInitConfig) => SportInitObject | undefined;
    widgetSportsBook?: SportInitObject;
    openWindows;
    widgetCashier;
    Cashier;
    google: { accounts: { id: { initialize; prompt; cancel; renderButton } } };
    altenarWSDK;
    ASB;
    XNaveGamification;
    widgetGamification;
    XNaveCasino: (config: CasinoWidgetTypes.CasinoWidgetParams) => CasinoWidgetTypes.CasinoWidgetMethods | undefined;
    widgetCasino?: CasinoWidgetTypes.CasinoWidgetMethods;
    widgetCasinoVertical?: CasinoWidgetTypes.CasinoSubWidgetMethods;
    widgetCasinoLaunch?: CasinoWidgetTypes.CasinoSubWidgetMethods;
  }
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      // UI Components
      'egtd-v-grid-layout': React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      // Virtual Sports Navigation Elements
      'egtd-vnav-providers-bar': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      'egtd-vnav-providers-tab': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      'egtd-vnav-sports-bar': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      'egtd-vnav-sports-tab-l1': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      'egtd-vnav-sports-tab-l2': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      // Platform Button
      'egtd-p-btn': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      // Platform Switch
      'egtd-p-switch': DetailedHTMLProps<HTMLAttributes<HTMLInputElement>, HTMLInputElement>;
      // Platform History
      'egtd-p-history-card': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-p-history-container': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-p-history-item-block': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-p-history-item-block-divider': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      // Platform BetSlip History
      'egtd-bet-event-label-wrapper': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-bet-event-label': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      // Prize Drop
      'egtd-przd-modal': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-modal-grid-layout': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-modal-win-content': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-modal-footer': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-wrapper': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-modal-campaigns': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-modal-campaigns-wrap': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-modal-campaigns-right-col': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-header': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-title': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-badge': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-campaign-item': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-campaign-item-inner': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-campaign-item-text': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-campaign-item-icon': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-gift-item-grid': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-gift-item': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-gift-item-inner': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-gift-item-footer': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-counter': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-counter-display': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-counter-item': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-counter-item-date': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-counter-item-text': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-table': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-table-row': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-przd-table-cell': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      // Prize Drop in Promotions
      'egtd-promo-przd-gift-item-wrapper': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-promo-przd-header': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-promo-przd-view-all-btn': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-promo-przd-counter': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-promo-przd-counter-display': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-promo-przd-counter-item': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-promo-przd-counter-item-date': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-promo-przd-counter-item-text': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

      // Cashier ticket ui
      'egtd-cashier-ticket-wrapper': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-cashier-ticket-header': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-cashier-ticket-body': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-cashier-ticket-content-wrapper': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-cashier-ticket-content-header': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-cashier-ticket-content': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-cashier-ticket-footer': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

      // SSBT ticket ui
      'egtd-ssbt-ticket-wrapper': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-ssbt-ticket-header': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-ssbt-ticket-body': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-ssbt-ticket-content-wrapper': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-ssbt-ticket-content-header': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-ssbt-ticket-content': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-ssbt-ticket-footer': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-ticket-maxprofit': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

      // Reprint ticket
      'egtd-reprint-ticket-wrapper': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-reprint-ticket-outer': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-reprint-ticket': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-reprint-ticket-period': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-reprint-ticket-header': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-reprint-ticket-header-id': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-reprint-ticket-header-details': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
      'egtd-reprint-ticket-header-toggler': DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
    }
  }
}

declare module 'react' {
  interface HTMLCustomAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    class?: string;
  }
}

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <Router>
    {/* <React.StrictMode> */}
    <StableNavigateContextProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </PersistGate>
      </Provider>
    </StableNavigateContextProvider>
    {/* </React.StrictMode> */}
  </Router>,
);

if (window.Cypress) {
  window.store = store;
}
