const convertToRem = (pixelSize: number) => {
  return pixelSize * 0.0625;
};

const calculateSlope = (minSize: number, maxSize: number, minViewport: number, maxViewport: number) => {
  return Number(
    ((convertToRem(maxSize) - convertToRem(minSize)) / (convertToRem(maxViewport) - convertToRem(minViewport))).toFixed(
      3,
    ),
  );
};

const calculateYIntersection = (minViewport: number, slope: number, minSize: number) => {
  return Number((-1 * convertToRem(minViewport) * slope + convertToRem(minSize)).toFixed(3));
};

/**
 * @param {number} minSize - [Pixels] Object min size
 * @param {number} maxSize - [Pixels] Object max size
 * @param {number} minViewport - [Pixels] Min viewport width (for minSize)
 * @param {number} maxViewport - [Pixels] Max viewport width (for minSize)
 * @param {string} cssProp - CSS Property to clamp
 * @returns Clamped CSS Property
 */
const clampedSize = (minSize: number, maxSize: number, minViewport = 375, maxViewport = 1025, cssProp?: string) => {
  const cssParams = `clamp(${toRem(minSize)},${calculateYIntersection(
    minViewport,
    calculateSlope(minSize, maxSize, minViewport, maxViewport),
    minSize,
  )}rem + ${calculateSlope(minSize, maxSize, minViewport, maxViewport) * 100}vw,${toRem(maxSize)})`;

  return cssProp ? `${cssProp}: ${cssParams}` : cssParams;
};

const toRem = (pixelSize: number) => {
  return `${pixelSize * 0.0625}rem`;
};

export const Size = {
  clampedSize,
  toRem,
};
