import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import Icon from 'components/shared/Icon';

/** Cashier Modal StatusBar Section */
interface StatusProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  text: string;
  isWin?: boolean;
  isLoss?: boolean;
  isProcessing?: boolean;
  isNotification?: boolean; // TODO: Conditional 'isCreateTicket'
  icon?: string;
}

const Status: React.FC<StatusProps> = ({ className, text, isWin, isLoss, isProcessing, isNotification, icon }) => {
  // TODO: Statuses switch case
  const statusClass = clsx(
    'ticket-status',
    isWin && 'ticket-status--win',
    isLoss && 'ticket-status--loss',
    isProcessing && 'ticket-status--processing',
    isNotification && 'ticket-status--notification',
    className,
  );

  return (
    <div className={statusClass}>
      {isNotification && (
        <>
          {icon && <Icon icon={icon} className="icon" />}
          <div>{text}</div>
        </>
      )}
    </div>
  );
};

export default Status;
