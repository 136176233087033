import React from 'react';
import clsx from 'clsx';
import { BlockProps } from './History.types';

/** Block */
const Block: React.FC<BlockProps> = ({ className, isHeader, isBody, isFooter, children, ...rest }) => {
  const blockClass = clsx(isHeader && 'ma-text--md');
  const classProp = className
    ? { class: `${blockClass} ${className}` }
    : isHeader
      ? { class: 'ma-text--md' }
      : undefined;

  const headerProp = isHeader ? { 'is-header': isHeader } : undefined;
  const bodyProp = isBody ? { 'is-body': isBody } : undefined;
  const footerProp = isFooter ? { 'is-footer': isFooter } : undefined;

  return (
    <egtd-p-history-item-block {...classProp} {...headerProp} {...bodyProp} {...footerProp} {...rest}>
      {children}
    </egtd-p-history-item-block>
  );
};

export default Block;
