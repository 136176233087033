import React from 'react';
import clsx from 'clsx';
import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import {
  BOTTOM_TAGS,
  TagType,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/gameCellTagsConstants';
import { GameTag } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/GameCellTagsTypes';
import { useBonusTag } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/hooks/useBonusTag';
import { useGameTags } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/hooks/useGameTags';
import { JackpotTag } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/JackpotTag';
import { Tag } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/Tag';
import { useGridLayoutSettings } from 'modules/casino/shared/components/gridLayoutSettings/hooks/useGridLayoutSettings';
import { Flex } from 'modules/casino/shared/components/layout';
import { BonusTag } from './BonusTag';

interface Props {
  gameUniqueId: string;
  categoryId: string;
  children?: React.ReactNode;
  className?: string;
}

const GameCellTags: React.FC<Props> = ({ children, gameUniqueId, categoryId, className }) => {
  const game = useGameProps({ gameUniqueId, categoryId });
  const { bonusTagIcon, bonusTagMessage } = useBonusTag(game.id, game.gameType);
  const { showGameCellFavButton } = useGridLayoutSettings();
  const { showGameTags, tags, bottomTag, rightTag, hasTranspBottomTag, jackpotId } = useGameTags(game);
  const rightTagClasses = clsx([
    'cell-tags--right',
    showGameCellFavButton && 'cell-tags--right--fav-on',
    !showGameCellFavButton && !rightTag && 'cell-tags--right--bonus-only',
    className,
  ]);

  if (!showGameTags)
    return children ? (
      <>
        <Flex.Col className="cell-tags" data-qid="cell-tags">
          {children}
        </Flex.Col>
        {bonusTagIcon ? (
          <Flex.Col className={rightTagClasses}>
            <BonusTag gameUniqueId={gameUniqueId} description={bonusTagMessage} icon={bonusTagIcon} />
          </Flex.Col>
        ) : null}
      </>
    ) : bonusTagIcon ? (
      <Flex.Col className={rightTagClasses}>
        <BonusTag gameUniqueId={gameUniqueId} description={bonusTagMessage} icon={bonusTagIcon} />
      </Flex.Col>
    ) : null;

  const renderTag = (tag: GameTag, idx: number) => {
    switch (tag.type) {
      case TagType.LABEL:
      case TagType.BOTTOM_TEXT:
      case TagType.BOTTOM_TRANSPARENT_TEXT:
      case TagType.LOGO:
      case TagType.LABEL_RIGHT:
        return <Tag key={idx} tag={tag} />;
      case TagType.JACKPOT:
      case TagType.BOTTOM_LABEL:
        return jackpotId && <JackpotTag key={idx} tag={tag} jackpotId={jackpotId} />;
      case TagType.BOTTOM_TRANSPARENT:
        return jackpotId && <JackpotTag key={idx} tag={tag} jackpotId={jackpotId} />;
      default:
        return null;
    }
  };

  const cellTagClasses = clsx([
    'cell-tag-bottom',
    hasTranspBottomTag && 'cell-tag-bottom-transparent',
    className,
    // showBottomTag && `cell-tag-bottom--mixin cell-tag-bottom--${layout}`,
    // `cell-tag-bottom cell-tag-bottom--${layout}`,
  ]);

  return (
    <>
      <Flex.Col className="cell-tags" data-qid="cell-tags">
        {children}
        {tags?.map(
          (tag, idx) => !BOTTOM_TAGS.includes(tag.type) && tag.type !== TagType.LABEL_RIGHT && renderTag(tag, idx),
        )}
      </Flex.Col>
      {rightTag || bonusTagIcon ? (
        <Flex.Col className={rightTagClasses}>
          {rightTag && renderTag(rightTag, Math.random())}
          {bonusTagIcon ? (
            <BonusTag gameUniqueId={gameUniqueId} description={bonusTagMessage} icon={bonusTagIcon} />
          ) : (
            <></>
          )}
        </Flex.Col>
      ) : (
        <></>
      )}

      {bottomTag ? (
        <div className={cellTagClasses} data-qid="cell-tag-bottom">
          {renderTag(bottomTag, Math.random())}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default GameCellTags;
GameCellTags.displayName = 'GameCellTags';
