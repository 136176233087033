import { useProviderAliasesByVertical } from 'modules/casino/modules/container/shared/features/casinoGameProviders/hooks/useProviderAliasesByVertical';
import { useFetchCasinoNavigationItems } from 'modules/casino/modules/navigation/shared/hooks/useFetchCasinoNavigationItems';
import { useMenuItemsCollectionCacheKey } from 'modules/casino/modules/navigation/shared/hooks/useMenuItemsCollectionCacheKey';
import { casinoNavigationSelectors } from 'modules/casino/modules/navigation/shared/slice/casinoNavigationSlice';
import { useAppSelector } from 'store';

const useDrawerCasinoLayout02Data = ({ isActive, sectionType }: { isActive: boolean; sectionType: string }) => {
  const casinoProvidersItems = useProviderAliasesByVertical(sectionType);
  const { cacheKey } = useMenuItemsCollectionCacheKey({ casinoType: sectionType });
  const casinoMenuItems = useAppSelector((state) => casinoNavigationSelectors.menuItems(state, cacheKey));
  useFetchCasinoNavigationItems({ casinoType: sectionType, fetchNavItems: isActive });

  return { casinoProvidersItems, casinoMenuItems };
};

export default useDrawerCasinoLayout02Data;
