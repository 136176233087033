import { SettingsTypes } from 'pages/myAccount/tabs/settings/types/settings.types';

export const transformLanguage = (languages) => {
  return languages?.map((x) => {
    return {
      value: x.id,
      label: x.nameTranslations,
    };
  });
};

export const parseTimezones = (timezones: SettingsTypes.TimeZone[] | null): SettingsTypes.ParsedTimeZone[] => {
  return (
    timezones?.map((x) => {
      return {
        value: x.code,
        label: x.displayName,
        offsetMinutes: x.offsetMinutes,
      };
    }) || []
  );
};

export const transformOdds = (odds) => {
  return odds?.map((x) => {
    return {
      value: x.id,
      label: x.nameTranslations,
    };
  });
};
