import React, { ReactNode, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { GameMediaUI } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellMedia/components/GameMediaUI';
import { useGameVideo } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellMedia/hooks/useGameVideo';
import { GridLayouts } from '../../../../../types/gridTypes';

interface Props {
  gameUniqueId: string;
  categoryId: string;
  children: ReactNode;
  layoutGrid: GridLayouts;
}

export const GameVideo: React.FC<Props> = ({ gameUniqueId, categoryId, children, layoutGrid }) => {
  const videoSource = useGameVideo({ gameUniqueId, categoryId, layoutGrid });
  const videoFallbackRef = useRef<HTMLDivElement | null>(null);
  const [inViewRef, inViewVideo] = useInView({
    triggerOnce: false,
    rootMargin: `10px 0px`,
  });
  const onLoad = () => {
    if (videoFallbackRef.current) {
      videoFallbackRef.current?.classList.add('video-fallback-animated');
    }
  };
  return (
    <div ref={inViewRef}>
      <div ref={videoFallbackRef} className={'video-fallback'}>
        {children}
      </div>
      <GameMediaUI.Video videoSource={videoSource} onLoad={onLoad} inView={inViewVideo} />
    </div>
  );
};

GameVideo.displayName = 'GameVideo';
