import React, { useEffect } from 'react';
import clsx from 'clsx';
import { ValidationValueMessage, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Flex } from 'components/shared/layout';
import { useOnOff } from 'hooks/common/useOnOff';
import { Breakpoints, Desktop, DesktopDown } from 'theme/Theme';
import { FormUI } from './FormUI';
import InputGroup from './InputGroup';
import InputGroupPrepend from './InputGroupPrepend';
import { InputLocationType, InputModeType, RefType, ValidationRules } from '../formTypes';

interface Props {
  componentLocation?: InputLocationType;
  /** Group Props */
  isMobileAgnostic?: boolean;
  classNames?: string;
  label?: string;
  labelOnlyOnDesktop?: boolean;
  groupType?: 'default' | 'simple';
  /** Input Prefix Props */
  prefixOnlyOnMobile?: boolean;
  prefixText?: string;
  iconPrefix?: string;
  /** Input Props */
  type: string | undefined;
  name: string;
  value?: string | undefined;
  placeholder?: string | undefined;
  autoComplete?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onFocus?: () => void;
  onBlur?: (v) => void;
  maxLength?: number;
  minLength?: number;
  disabled?: boolean;
  inputClass?: string;
  inputMode?: InputModeType;
  /** Messages Props */
  hasSuccess?: boolean;
  hasSuccessIcon?: boolean;
  hasError?: boolean;
  hasErrorIcon?: boolean;
  errorMessage?: string;
  errorOnlyOnDesktop?: boolean;
  infoMessage?: string;
  controlRef?: RefType;
  externalError?: boolean;
  readOnly?: boolean;
  validationRules: ValidationRules<ValidationValueMessage<boolean>>;
}

const FormInputCheckbox: React.FC<Props> = (props) => {
  const [showInfo, setShowInfo] = useOnOff();
  const [isChecked, setIsChecked] = useOnOff(
    !!props.validationRules?.required?.value || !!(props.value && props.disabled),
  );
  const disabledInput = !isChecked || props.disabled;
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isTabletOrMobile}px)` });
  const { t } = useTranslation();
  const { setFocus, register, trigger, setValue } = useFormContext();

  useEffect(() => {
    if (isChecked) {
      setFocus(props.name);
    }
  }, [isChecked, props.name, setFocus]);

  const handleBlur = !props.infoMessage
    ? props.onBlur
    : (v) => {
        setShowInfo(false);
        props.onBlur && props.onBlur(v);
      };

  const handleFocus = !props.infoMessage
    ? props.onFocus
    : () => {
        setShowInfo(true);
        props.onFocus && props.onFocus();
      };

  const handleCheck = () => {
    if (!props.disabled) {
      isChecked && setValue(props.name, undefined);
      setIsChecked(!isChecked);
      trigger();
    }
  };

  const handleOnClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!disabledInput && props.onClick) {
      props.onClick(e);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabledInput && props.onChange) {
      props.onChange(e);
    }
  };

  const groupClass = clsx(
    'form-group',
    props.isMobileAgnostic && (isTabletOrMobile ? 'form-group-simple' : 'form-group-default'),
    props.componentLocation === 'login' && 'form-group--login',
    props.componentLocation === 'myAccount' && 'form-group--ma',
    props.groupType === 'default' && 'form-group-default',
    props.groupType === 'simple' && 'form-group-simple',
    props.classNames ? props.classNames : 'col-12',
    props.externalError && 'has-external-error',
  );

  const inputWrapperClass = clsx(
    'd-flex-col fcw',
    (props.disabled || props.readOnly) && 'disabled',
    props.groupType === 'default' && 'fcw--default',
    props.groupType === 'simple' && 'fcw--simple',
    props.hasError && 'has-error bg--error',
    props.hasErrorIcon && 'has-error-icon',
    props.hasSuccess && 'has-success',
    props.hasSuccessIcon && 'has-success-icon',
    showInfo && 'has-info bg--info',
  );

  const inputGroupCLass = clsx(
    'switch-input-wrapper',
    props.componentLocation === 'auth' && ' input-group--auth',
    props.componentLocation === 'login' && 'input-group--simple input-group--login',
  );

  const inputClass = clsx(
    'form-control',
    props.componentLocation === 'auth' &&
      props.isMobileAgnostic &&
      (isTabletOrMobile ? 'form-control--simple form-control--auth' : ''),
    props.componentLocation === 'login' && 'form-control--simple form-control--login',
    props.componentLocation === 'myAccount' && 'form-control--ma',
    props.inputClass && props.inputClass,
  );

  const validationRules = {
    ...props.validationRules,
    required: { value: isChecked, message: props.validationRules?.required?.message || '' },
  };

  return (
    <div className={groupClass}>
      {props.label &&
        (props.labelOnlyOnDesktop ? (
          <Desktop>
            <FormUI.Label componentLocation={props.componentLocation} label={t(props.label)} />
          </Desktop>
        ) : (
          <FormUI.Label componentLocation={props.componentLocation} label={t(props.label)} />
        ))}

      <div className={inputWrapperClass}>
        <InputGroup className={inputGroupCLass}>
          {props.iconPrefix &&
            (props.prefixOnlyOnMobile ? (
              <DesktopDown>
                <InputGroupPrepend icon={props.iconPrefix} />
              </DesktopDown>
            ) : (
              <InputGroupPrepend icon={props.iconPrefix} />
            ))}

          {props.prefixText && (
            <Flex.Center className="input-group-prepend">
              <span className="input-prepend-text">{props.prefixText}</span>
            </Flex.Center>
          )}

          <input
            {...(register && register(props.name, validationRules))}
            ref={props.controlRef}
            type={props.type}
            name={props.name}
            value={props.value}
            className={inputClass}
            placeholder={t(props.placeholder || '')}
            autoComplete={props.autoComplete}
            onChange={handleOnChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            maxLength={props.maxLength}
            minLength={props.minLength}
            disabled={disabledInput}
            inputMode={props.inputMode || 'text'}
            aria-label={props.name}
            data-qid={props.name}
            onClick={handleOnClick}
            readOnly={props.readOnly}
          />
          <div className={`form-group-switch-input d-flex-ac-jb`}>
            <div className="custom-control custom-switch" onClick={handleCheck}>
              <input
                disabled={props.disabled}
                type="checkbox"
                name={'name'}
                className="custom-control-input"
                checked={isChecked}
                readOnly
              />
              <label className="custom-control-label">&#xfeff;</label>
            </div>
          </div>
        </InputGroup>
        {props.hasError &&
          (props.errorOnlyOnDesktop ? (
            <Desktop>
              <FormUI.Message type="error" content={props.errorMessage} />
            </Desktop>
          ) : (
            <FormUI.Message type="error" content={props.errorMessage} />
          ))}
        {showInfo && <FormUI.Message type="info" content={t(props.infoMessage || '')} />}
      </div>
    </div>
  );
};

export default FormInputCheckbox;
