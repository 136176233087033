import { General } from 'shared/common/features/general/types/generalSlice.types';
import { getError } from './common/helpersCommon';

export const exitFullScreen = (element?) => {
  const document = element ? element : window.document;
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
};

export const enterFullScreen = (element?) => {
  const document = element ? element : window.document;
  if (document.requestFullscreen) {
    document.requestFullscreen();
  } else if (document.mozRequestFullScreen) {
    document.mozRequestFullScreen();
  } else if (document.webkitRequestFullscreen) {
    document.webkitRequestFullscreen();
  }
};

export const getTickerConfig = (
  pathname: string,
  tickerConfig: General.Ticker | undefined,
): General.TickerItem | undefined => {
  if (!tickerConfig) return;
  const paths = pathname.split('/');
  if (pathname.includes('in-play') || pathname.startsWith('/sports/results')) {
    return tickerConfig[`/${paths[1]}/${paths[2]}`];
  }

  return tickerConfig[`/${paths[1]}`];
};

export const providerIdToSVG = {
  '3': 'EVOLUTION',
  '8': 'PRAGMATIC',
  '36': 'PLAYTECH',
  '1': 'EGTI',
  '41': 'SMOJOS',
  '54': 'EZUGI',
  '30': 'SKYWIND',
};

export const safeJSONParse = (jsonAsString: string, reviver?) => {
  let jsonConfig = {};
  try {
    jsonConfig = JSON.parse(jsonAsString, reviver);
  } catch (e) {
    getError.message(e); //console.log('invalid json: ', jsonAsString);
  }
  return jsonConfig;
};

export const replaceTranslationPlaceholder = (text, textToReplace, placeholder) => {
  return (text = text.replace(new RegExp(placeholder, 'g'), textToReplace));
};

export const formatSumAmount = ({
  sum,
  formatToComma,
  omitZeroDecimals,
}: {
  sum: string | undefined | number;
  formatToComma: boolean;
  omitZeroDecimals?: boolean;
}): string | number => {
  if (!sum) return '';
  if (!formatToComma) return sum;

  const sumStr = typeof sum === 'number' ? sum.toString() : sum;
  const normalizedSum = sumStr.replace(/\s+/g, '');
  const parsedAmount = parseFloat(normalizedSum);
  const formattedAmount = parsedAmount.toFixed(2);
  const [wholePart, fractionalPart] = formattedAmount.split('.');
  const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  if (fractionalPart === '00' && omitZeroDecimals) {
    return `${formattedWholePart}`;
  }
  return `${formattedWholePart},${fractionalPart}`;
};

export const formatCurrencyDisplay = ({
  currencySymbolFirst,
  spaceBetween,
  sum,
  currency,
  formatToComma,
}: {
  currencySymbolFirst: boolean;
  spaceBetween: boolean;
  sum: string;
  currency: string;
  formatToComma: boolean;
}): string => {
  const formattedSum = formatSumAmount({ sum, formatToComma });

  return currencySymbolFirst ? `${currency}${spaceBetween && ' '}${formattedSum}` : `${formattedSum}${currency}`;
};
