import { DefaultTheme } from 'styled-components/native';
import { Buttons } from '../default/Buttons';
import { Common } from '../default/Common';
import { Home } from '../default/Home';
import { LayoutTypeConfig } from '../default/LayoutTypeConfig';

export const BethubTheme: DefaultTheme = {
  dark: {
    Common: Common,
    Buttons: Buttons,
    Home: Home,
    LayoutConfigs: LayoutTypeConfig,
  },
  light: {
    Common: Common,
    Buttons: Buttons,
    Home: Home,
    LayoutConfigs: LayoutTypeConfig,
  },
};
