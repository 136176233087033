import { useAppSelector } from 'store';
import { getBusinessUnit } from 'utils/common/helpersCommon';
import { selectLanguage } from '../../settings/slice/settings.slice';

export const useGetCookieLink = () => {
  const bu = getBusinessUnit();
  const language = useAppSelector(selectLanguage);

  switch (bu) {
    case 'SMBG':
      return language === 'en'
        ? 'https://help.sesame.bg/hc/en-bg/articles/360007542958-Cookies-Policy'
        : 'https://help.sesame.bg/hc/bg/articles/360007542958-Политика-за-употреба-на-Бисквитки-Кукита-';
    default:
      return '/help/cookie';
  }
};
