import React, { memo } from 'react';
import { useFetchPromotionLobbyItems } from 'pages/promotions/hooks/useFetchPromotionLobbyItems';
import PromotionExpandableRow from './PromotionExpandableRow';
import { DrawerUI } from '../DrawerUI';

interface Props {
  isActive: boolean;
}

const PromotionDrawerSection: React.FC<Props> = ({ isActive }) => {
  const lobbyItems = useFetchPromotionLobbyItems();

  return (
    <DrawerUI.Section type="casino" isActive={isActive}>
      {lobbyItems.map((item) => {
        return <PromotionExpandableRow item={item} key={item.id} />;
      })}
    </DrawerUI.Section>
  );
};

export default memo(PromotionDrawerSection);
