import React from 'react';
import { useTranslation } from 'react-i18next';
import { GameCellRestriction } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellRestriction';
import { LayoutConfigInnerLayoutTypes } from 'modules/casino/shared/constants';
import { GamePlayType } from 'modules/casino/shared/features/gameLaunch/casinoLaunchTypes';
import { useCasinoLayoutConfig } from 'modules/casino/shared/hooks/useCasinoLayoutConfig';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppSelector } from 'store';
import { GameCellButtons } from './GameCellButtons';
import { useGameButtons } from './hooks/useGameButtons';

interface Props {
  gameUniqueId: string;
  categoryId: string;
  gameCellRef: React.MutableRefObject<HTMLDivElement | null>;
  handleClick: (e: React.MouseEvent<Element, MouseEvent>, launchType: GamePlayType) => void;
  hasRestrictions?: boolean;
  showInfoPage: boolean;
}

const GameCellButtonsWrapper: React.FC<Props> = ({
  gameUniqueId,
  categoryId,
  gameCellRef,
  handleClick,
  hasRestrictions,
  showInfoPage,
}) => {
  const { t } = useTranslation();

  const { hideButtons, availableForFreeplay, hidePlayButton, gameBetType } = useGameButtons({
    gameCellRef,
    gameUniqueId,
    categoryId,
    showInfoPage,
  });

  const casinoLayoutConfig = useCasinoLayoutConfig({ innerLayout: LayoutConfigInnerLayoutTypes.CASINO_LAYOUT_CONFIG });
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const showFreePlayBtnConfig = casinoLayoutConfig?.showFreePlayBtn || !isAuthenticated;

  if (hideButtons && !hasRestrictions) {
    return null;
  }

  return hasRestrictions ? (
    <GameCellRestriction gameBetType={gameBetType} />
  ) : (
    <GameCellButtons
      showFreePlay={availableForFreeplay && showFreePlayBtnConfig}
      hidePlayButton={hidePlayButton}
      playText={`${t(`GameInfo.Buttons.play`)}`}
      freePlayText={`${t(`GameInfo.Buttons.freePlay`)}`}
      playHandler={(e) => handleClick(e, GamePlayType.PAID)}
      freePlayHandler={(e) => handleClick(e, GamePlayType.FREE)}
    />
  );
};

export default GameCellButtonsWrapper;
GameCellButtonsWrapper.displayName = 'Game Cell Buttons Wrapper';
