import React, { memo } from 'react';
import { isEmpty } from 'utils/common/helpersCommon';
import { Sport, SportIDs } from 'utils/common/types/sportTypes';
import EventScoreCurrent from './EventScoreCurrent';
import SingleEventScore from './eventScoreTypeName/SingleEventScore';

interface Props {
  periodScore: Sport.PeriodScoresData;
  homeScore: string;
  awayScore: string;
  sportId: number;
}

const ESportsScore: React.FC<Props> = ({ periodScore, homeScore, awayScore, sportId }) => {
  return (
    <>
      <SingleEventScore homeScore={homeScore} awayScore={awayScore} />
      {!isEmpty(periodScore) &&
        sportId !== SportIDs.ebasketball &&
        periodScore?.map((data) => (
          <EventScoreCurrent key={data.periodNumber} homeScore={data.homeScore} awayScore={data.awayScore} />
        ))}
    </>
  );
};

export default memo(ESportsScore);
