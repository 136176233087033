import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { AppDispatch } from 'store';
import { RootState } from 'store/rootReducer';
import { isEmpty } from 'utils/common/helpersCommon';
import {
  JackpotPopupType,
  WsNotificationsTypes,
} from '../../myProfile/sections/tabs/notificationsTab/types/wsNotifications.types';
import { WS } from '../../websockets/types/webSockets.types';
import { parsePopupContent } from '../utils/wsNotifications.utils';

type State = {
  notifications: WsNotificationsTypes.Notification[];
};
const initialState: State = {
  notifications: [],
};

export const updateInGameNotifications =
  (payload: WS.IngameNotification) =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const popupContent = parsePopupContent(payload.popupContent);
    if (popupContent[0]?.Style.type === JackpotPopupType && !getState().casino.casinoGameLauncher.launchInProcess) {
      return;
    }
    if (
      popupContent[0]?.Style.type === 'GamePopup' &&
      popupContent[0]?.Style.animationKey === 'Lotto' &&
      !popupContent[0].Id
    ) {
      popupContent[0].Id = popupContent[0].Message
        ? `lotto-${popupContent[0].Message}`
        : `lotto-${JSON.stringify(Math.random())}`;
    }

    if (isEmpty(payload?.popupContent)) return;
    dispatch(wsSetNotifications(popupContent));
  };

const inGameNotifications = createSlice({
  name: 'inGameNotifications',
  initialState,
  reducers: {
    wsSetNotifications(state, action: PayloadAction<WsNotificationsTypes.Notification[]>) {
      state.notifications = [...state.notifications, ...action.payload];
    },
    updateNotifications(state, action: PayloadAction<string>) {
      state.notifications = state.notifications?.filter((notification) => notification.Id !== action.payload) || [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state) => {
      state.notifications = [];
    });
  },
});

export const { wsSetNotifications, updateNotifications } = inGameNotifications.actions;

export const selectInGameNotifications = {
  notifications: (state: RootState) => state.promotion.inGameNotifications.notifications,
};
export default inGameNotifications.reducer;
