import { useEffect } from 'react';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { printCashierPayoutVoucher } from 'modules/retail/modules/cashier/printMethods/cashierVoucherPrint.utils';
import { useFetchSSBTdata } from 'modules/retail/modules/ssbt/hooks/useFetchSSBTdata';
import {
  resetCashierCancelVoucherStatus,
  resetCashierPayoutVoucherStatus,
  resetVoucherInfoCashier,
  voucherThunk,
} from 'modules/retail/shared/slice/voucher.slice';
import userStorage from 'pages/auth/login/UserStorage';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { useAppDispatch } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { useVoucherData } from './useVoucherData';
import { actionButtonVoucherEnums } from '../../cashierModalUI/actionButtonsMock';
import { setIsPrintingEjected } from '../../slice/cashier.slice';

export const useVoucherActions = (
  clickCount,
  setClickCount,
  buttonId,
  isActive,
  setActionButtonClicked,
  setSeconds,
  actionButtonClicked,
  inputValue,
  setInputValue,
  voucherInfoCashier,
) => {
  const dispatch = useAppDispatch();
  const { payoutVoucherStatus, cancelVoucherStatus } = useVoucherData();
  const userCurr = userStorage.getUserCurrency();
  const currency = userCurr || '';
  const retailAddress = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.RETAIL_ADDRESS,
    deviceLayout: 'general',
  });

  const isCurrencyBeforeAmount = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.CURRENCY_BEFORE_AMOUNT,
  });

  const { printerStatus } = useFetchSSBTdata();

  const payoutVoucher = () => {
    if (!isEmpty(printerStatus)) {
      dispatch(setIsPrintingEjected(true));
    }
    dispatch(voucherThunk.payoutVoucher({ code: inputValue }));
    setInputValue('');
  };

  const cancelVoucher = () => {
    if (!isEmpty(printerStatus)) {
      dispatch(setIsPrintingEjected(true));
    }
    dispatch(voucherThunk.cancelVoucher({ code: inputValue }));
    setInputValue('');
  };

  useEffect(() => {
    if (clickCount === 1 && isEmpty(voucherInfoCashier)) {
      if (buttonId === actionButtonVoucherEnums.payOutVoucher && isActive[buttonId] && actionButtonClicked) {
        setActionButtonClicked((prevState) => ({
          ...prevState,
          [actionButtonVoucherEnums.payOutVoucher]: false,
        }));
        setClickCount(0);
        setSeconds(0);
      } else if (buttonId === actionButtonVoucherEnums.cancelVoucher && isActive[buttonId] && actionButtonClicked) {
        setActionButtonClicked((prevState) => ({
          ...prevState,
          [actionButtonVoucherEnums.cancelVoucher]: false,
        }));
        setClickCount(0);
        setSeconds(0);
      }
    }
  }, [clickCount, voucherInfoCashier]);

  useEffect(() => {
    if (clickCount === 2) {
      if (buttonId === actionButtonVoucherEnums.payOutVoucher && isActive[buttonId] && actionButtonClicked) {
        payoutVoucher();
      } else if (buttonId === actionButtonVoucherEnums.cancelVoucher && isActive[buttonId] && actionButtonClicked) {
        cancelVoucher();
      }
    }
  }, [clickCount]);

  useEffect(() => {
    if (payoutVoucherStatus === 'succeeded') {
      if (!isEmpty(printerStatus)) {
        const printCashierPayoutVoucherObj = {
          stake: voucherInfoCashier?.amount,
          currency,
          retailAddress,
          actionName: 'Cashier.VoucherModal.payoutVoucherReceipt',
          posAddress: voucherInfoCashier?.posAddress,
          posName: voucherInfoCashier?.posName,
          isCurrencyBeforeAmount,
        };
        printCashierPayoutVoucher(printCashierPayoutVoucherObj);
      }
      setActionButtonClicked((prevState) => ({
        ...prevState,
        [actionButtonVoucherEnums.payOutVoucher]: false,
      }));
      setClickCount(0);
      setSeconds(0);
      dispatch(resetVoucherInfoCashier());
      dispatch(resetCashierPayoutVoucherStatus());
    }
  }, [payoutVoucherStatus]);

  useEffect(() => {
    if (cancelVoucherStatus === 'succeeded') {
      if (!isEmpty(printerStatus)) {
        const printCashierCancelVoucherObj = {
          stake: voucherInfoCashier?.amount,
          currency,
          retailAddress,
          actionName: 'Cashier.VoucherModal.cancelVoucherReceipt',
          posAddress: voucherInfoCashier?.posAddress,
          posName: voucherInfoCashier?.posName,
          isCurrencyBeforeAmount,
        };
        printCashierPayoutVoucher(printCashierCancelVoucherObj);
      }
      setActionButtonClicked((prevState) => ({
        ...prevState,
        [actionButtonVoucherEnums.cancelVoucher]: false,
      }));
      setClickCount(0);
      setSeconds(0);
      dispatch(resetVoucherInfoCashier());
      dispatch(resetCashierCancelVoucherStatus());
    }
  }, [cancelVoucherStatus]);

  useEffect(() => {
    if (payoutVoucherStatus === 'failure') {
      if (!isEmpty(printerStatus)) {
        dispatch(setIsPrintingEjected(false));
      }
      setActionButtonClicked((prevState) => ({
        ...prevState,
        [actionButtonVoucherEnums.payOutVoucher]: false,
      }));
      setClickCount(0);
      setSeconds(0);
    }

    if (cancelVoucherStatus === 'failure') {
      if (!isEmpty(printerStatus)) {
        dispatch(setIsPrintingEjected(false));
      }
      setActionButtonClicked((prevState) => ({
        ...prevState,
        [actionButtonVoucherEnums.cancelVoucher]: false,
      }));
      setClickCount(0);
      setSeconds(0);
    }
  }, [dispatch, payoutVoucherStatus, cancelVoucherStatus, printerStatus]);
};
