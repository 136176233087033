import React from 'react';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { TimeDataType } from 'modules/casino/shared/utils/dateUtils';
import { Layout03 } from './components';
import { usePromotionImgResource } from '../../../../hooks/usePromotionImgResource';
import { PromoWidgetsTypes } from '../../../../promoWidgetTypes';
import { getDateDifference } from '../../../../promoWidgetUtils';

interface Props {
  promoItem: PromoWidgetsTypes.PromoItem;
  openPromotion: () => void;
}

// SMBG cell layout
const PromoCellLayout_3: React.FC<Props> = ({ promoItem, openPromotion }) => {
  const { cellRef, imgSource } = usePromotionImgResource(promoItem.widgetResource);

  return (
    <div
      className={`${cssGlobalNS}-c-prm-lobby-card`}
      key={promoItem.id}
      ref={cellRef}
      style={{ backgroundImage: `url(${imgSource})` }}
    >
      <Layout03.CardText
        type="title"
        text={promoItem.title}
        textStyle={{ color: promoItem.titleTextColor, backgroundColor: '' }}
      />
      <Layout03.CardText type="description" text={promoItem?.description || ''} />
      <Layout03.CardMeta
        dateDifference={getDateDifference(promoItem, 'layout_3', false) as TimeDataType}
        onClick={openPromotion}
      />
    </div>
  );
};

export default PromoCellLayout_3;
