import { useEffect } from 'react';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';

export const storageRealityCheckConfirmed = 'egtd:reality_check_confirmed';

export const handleRealityCheckConfirmed = () => {
  localStorage.setItem(storageRealityCheckConfirmed, JSON.stringify(true));
};

type Props = {
  closeModal?: () => void;
  callback?: () => void;
};

const useRealityCheckConfirmed = ({ closeModal, callback }: Props): void => {
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);

  useEffect(() => {
    function onRealityStorageChange(e: StorageEvent) {
      if (e.key === storageRealityCheckConfirmed && isAuthenticated) {
        const rCheckConfirmedStorage = localStorage.getItem(storageRealityCheckConfirmed);
        const realityCheckConfirmed = rCheckConfirmedStorage && !isEmpty(JSON.parse(rCheckConfirmedStorage));

        if (realityCheckConfirmed) {
          closeModal && closeModal();
          callback && callback();

          localStorage.removeItem(storageRealityCheckConfirmed);
        }
      }
    }

    window.addEventListener('storage', onRealityStorageChange);

    return () => {
      window.removeEventListener('storage', onRealityStorageChange);
    };
  }, [callback, closeModal, isAuthenticated]);
};

export default useRealityCheckConfirmed;
