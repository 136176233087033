import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { UI } from 'components/shared/UI';
import { useNavigateToBankTab } from 'pages/myAccount/tabs/cashier/utils/cashier.utils';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import { cssGlobalNS } from 'utils/common/types/css.types';
import { useUserBalance } from '../../myProfile/hooks/useUserBalance';
import { MyProfileBalanceAmount } from '../../myProfile/sections';

type Props = {
  handleClose: () => void;
};

const RequestBetHeader: React.FC<Props> = ({ handleClose }) => {
  const bankNavigate = useNavigateToBankTab();

  const { balanceAmount } = useUserBalance();
  const { t } = useTranslation();

  const navigateToBank = () => {
    handleClose();
    bankNavigate('deposit');
  };
  return (
    <>
      <div className={`d-flex-ac-jb ${cssGlobalNS}-rb__box ${cssGlobalNS}-rb__header`}>
        <MyProfileBalanceAmount userBalance={balanceAmount} disableMargin />

        <UI.Button
          data-qid="userDepositBtn"
          size="md"
          onClick={navigateToBank}
          text={t(MyProfile.Button.depositProfileBtn)}
        />
      </div>
    </>
  );
};

export default memo(RequestBetHeader);
