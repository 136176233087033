import { useCallback } from 'react';
import { GLOBAL_TTL } from 'utils/common/constants';

export const useTimeToLeave = (): ((categoryTTL?: number, baseTTL?: number) => boolean) => {
  const hasExpired = useCallback((categoryTTL: number = GLOBAL_TTL, baseTTL: number = GLOBAL_TTL): boolean => {
    if (Date.now() - categoryTTL >= baseTTL) return true;
    return false;
  }, []);

  return hasExpired;
};
