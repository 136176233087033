import React from 'react';

/** Auth Form Group */
interface Props {
  children?: React.ReactNode;
}

const FormGroup: React.FC<Props> = ({ children }) => {
  return <div className="form-group">{children}</div>;
};

export default FormGroup;
