import { t } from 'i18next';
import isEqual from 'lodash/isEqual';
import memoize from 'lodash/memoize';
import omit from 'lodash/omit';
import { createCachedSelector, ParametricSelector } from 're-reselect';
// import { WebSocketMsgTypes } from 'shared/common/features/websockets/webSocketMsgTypes'; //for commented code - WebSocketMsgTypes.EVENT_CLOCK
import type { RootState } from 'store/rootReducer';
import { isEmpty } from 'utils/common/helpersCommon';
import { Sport, ET, SportLobby, InPlayTypes } from 'utils/common/types/sportTypes';
import { SportDataTypes } from 'utils/common/types/sportTypes/sportData.types';
import { setInitialEventClockStatus, wsEventClockUpdateFunc } from 'utils/common/wsUpdateFunc/wsEventClockUpdateFunc';
import { ClockNormalizedEvent } from 'utils/common/wsUpdateFunc/wsUpdate.types';
import { updateFavoriteLine, updateOdds } from 'utils/common/wsUpdateFunc/wsUpdateFuncUtils';
import {
  EventSelectors,
  EventWithMarkets,
  EventSelectorFuncType,
  EventInfoSelectorFuncType,
} from '../types/eventProps.types';

type NormalizedEventsSelectorFuncType<T extends ET.All_events & EventWithMarkets> = (
  state: RootState,
  sportEventId: string | number,
  sportId: number,
  marketId?: string | number,
) => Record<string, T> | undefined;
export const selectEvent = <T extends ET.All_events & EventWithMarkets>(
  selectorFunc: NormalizedEventsSelectorFuncType<T>,
  filterFunc?: (event: T) => string[],
): EventSelectors => {
  const eventSelector: ParametricSelector<RootState, string | number, T> = selectEventById<T>(selectorFunc);
  return {
    eventById: eventSelector as EventSelectorFuncType,
    eventInfo: createCachedSelector(
      [eventSelector, (_, eventIdSelect) => eventIdSelect],
      memoize(
        (event, _) => omit(event, ['markets', 'marketIds', 'marketsCount', 'eventClockStatus']),
        // creates key for memoization - if these props change, selector will recalculate, otherwise will take cached value
        (event, eventIdSelect) =>
          eventIdSelect + JSON.stringify(omit(event, ['markets', 'marketIds', 'marketsCount', 'eventClockStatus'])),
      ),
    )((_, id) => id) as EventInfoSelectorFuncType,
    marketById: createCachedSelector(
      [eventSelector, (_, sportEventId, sportId, marketId: string | number) => marketId],
      (event, marketId): Sport.MultilineMarket => event?.markets?.[marketId] as Sport.MultilineMarket,
    )((_, id) => id),
    marketsCount: createCachedSelector([eventSelector], (event) => event?.marketsCount)((_, id) => id),
    marketIds: createCachedSelector([eventSelector], (event): string[] | undefined => {
      if (!event) return;
      return !filterFunc ? event?.marketIds : filterFunc(event);
    })((_, id) => id),
    matchStatus: createCachedSelector([eventSelector], (event) => event?.eventClockStatus?.matchStatus)((_, id) => id),
    periodScore: createCachedSelector(eventSelector, (event) => event?.eventClockStatus?.periodScore)((_, id) => id),
    currentServer: createCachedSelector(
      eventSelector,
      (event) => event?.eventClockStatus?.sportSpecificProperties?.currentServer,
    )((_, id) => id),
    scoreChange: createCachedSelector([eventSelector], (event) => event?.eventClockStatus?.scoreChange)((_, id) => id),
    // TODO remove if not efficient
    score: createCachedSelector(
      eventSelector,
      memoize(
        (event) =>
          omit(event?.eventClockStatus, ['eventClock', 'matchStatus', 'eventStatus', 'lastUpdate', 'scoreChange']),
        (ev) =>
          ev.sportEventId +
          JSON.stringify(
            omit(ev?.eventClockStatus, ['eventClock', 'matchStatus', 'eventStatus', 'lastUpdate', 'scoreChange']),
          ),
      ),
    )((_, id) => id) as EventSelectors['score'],
    eventClockStatus: createCachedSelector([eventSelector], (event) => event?.eventClockStatus)((_, id) => id),
    isSuspended: createCachedSelector([eventSelector], (event) => checkIsSuspended(event))((_, id) => id),
    isNotMainMarket: createCachedSelector(
      [eventSelector, (_, sportEventId, sportId, marketId: string | number) => marketId],
      (event, marketId) => getIsNotMainMarket(event, marketId),
    )((_, id) => id),
    marketColumnCount: createCachedSelector([eventSelector], (event) => {
      if (event?.isMultiline) return;
      const market = Object.values(event?.markets || {})?.[0];
      if (market?.columnCount && market?.columnCount === market?.outcomes?.length) return;
      return market?.columnCount;
    })((_, id) => id),
  };
};
export const selectEventById = <T extends ET.All_events & EventWithMarkets>(
  selectorFunc: NormalizedEventsSelectorFuncType<T>,
): ParametricSelector<RootState, string | number, T> =>
  createCachedSelector(
    [selectorFunc, (_, sportEventId) => sportEventId],
    (normalizedEvents, sportEventId) => normalizedEvents?.[sportEventId] as T,
  )((_, id) => id);
export const getIsNotMainMarket = <T extends ET.All_events & EventWithMarkets>(event: T, marketId): boolean => {
  if (!event || !marketId) return false;
  const { markets, isMultiline, sportType } = event;
  if (isEmpty(markets) || isMultiline || sportType === 'VIRTUAL') return false;
  return (markets[marketId] as Sport.MultilineMarket)?.isMainMarket === false;
};
export const checkIsSuspended = <T extends ET.All_events & EventWithMarkets>(event: T): boolean => {
  if (isEmpty(event?.marketIds)) return false;
  return event?.marketIds?.every((marketId) => {
    const marketStatus = event?.markets?.[marketId]?.marketStatus;
    return (
      marketStatus === 'Suspended' ||
      marketStatus === 'Settled' ||
      !!(event?.sportType && event?.sportType === 'VIRTUAL' && marketStatus === 'Deactivated')
    );
  });
};
export type SportType = {
  id: number;
  sportId: number;
  isMultiline: boolean;
  columnTitles: InPlayTypes.Columns[];
};
export const pollingChangeEventFunc = ({
  existingEvent,
  eventClockStatus,
  event,
  markets,
  marketIds,
  addMarkets,
}: {
  event: ET.WithMarkets_Event & { live?: boolean };
  existingEvent: ET.Normalize<ET.WithMarkets_Event, Sport.MultilineMarket> & { eventClockStatus? };
  eventClockStatus: Sport.EventClockStatus;
  markets: Record<string, Sport.MultilineMarket>;
  marketIds: string[] | undefined;
  addMarkets?: boolean;
}): void => {
  // Delete existing event markets if they are not received from the API
  const inMultipleContainers = (existingEvent?.containersIds?.length || 0) > 1;
  if (!addMarkets && !inMultipleContainers && markets) {
    for (const currMarketId in existingEvent.markets) {
      const deleteMarket = !isEmpty(existingEvent?.containerMarketIds)
        ? !markets?.[currMarketId] && !existingEvent?.containerMarketIds?.includes(currMarketId)
        : !markets?.[currMarketId];
      if (deleteMarket) {
        delete existingEvent.markets[currMarketId];
        const index = existingEvent.marketIds?.findIndex((id) => id === currMarketId);
        if (index !== -1) existingEvent.marketIds?.splice(index, 1, currMarketId);
      }
    }
  }
  // Update odds or favourite line
  for (const marketId in markets) {
    const currentMarket = existingEvent.markets?.[marketId];
    const newMarket = markets[marketId];
    if (currentMarket) {
      // Update odds if the market exists
      updateOdds(existingEvent, marketId, newMarket.outcomes, newMarket.marketStatus);
    } else {
      // Update favourite market line when curr market template id is same as new market template id
      // (newMarket?.isFavourite || newMarket?.favoriteMarket), // different API props??!!?? incl favoriteMarket: false, but same template in tennis
      const currentFavoriteMarket = Object.values(existingEvent.markets).find(
        (market) => market?.marketTemplateId === newMarket?.marketTemplateId,
      );
      if (currentFavoriteMarket) {
        updateFavoriteLine(
          existingEvent,
          newMarket?.marketId,
          currentFavoriteMarket.marketId,
          newMarket?.marketStatus,
          newMarket?.marketName,
          newMarket?.outcomes,
        );
      } else {
        // Add new market which does not already exixts
        existingEvent.markets[marketId] = newMarket;
        // replace markets with polling for event in multiple containers
        if (existingEvent.marketIds?.length === 1) {
          const existingMarketId = existingEvent.marketIds[0];
          delete existingEvent.markets[existingMarketId];
        }
        existingEvent.marketIds = marketIds || [];
      }
    }
  }
  if (event.live && eventClockStatus) {
    wsEventClockUpdateFunc(existingEvent as ClockNormalizedEvent, {
      ...eventClockStatus,
      routingKey: '',
      // type: WebSocketMsgTypes.EVENT_CLOCK,
      // sportEventId: event.sportEventId,
      // sportId: event.sportId,
    });
  }
};
export const normalizeEventsFunc = <T extends ET.WithMarkets_Event>({
  sports,
  events,
  normalizedEvents,
  eventProps,
  marketProps,
  columnTitles,
  replaceEvent,
  pollingChangeEvent,
  addMarkets,
  containerMarketIds,
}: {
  sports?: Partial<SportType>[];
  events?: Record<string, T> | T[] | null;
  normalizedEvents?: Record<string, ET.Normalize<T>>;
  eventProps?;
  marketProps?: { live: boolean };
  columnTitles?: InPlayTypes.Columns[];
  replaceEvent?: boolean;
  pollingChangeEvent?: boolean;
  addMarkets?: boolean;
  containerMarketIds?: SportLobby.ContainerMarketIds;
}): Record<string, ET.Normalize<T>> => {
  const normalizedEventsMap: Record<string, ET.Normalize<T>> = normalizedEvents || {};
  if (!events) return normalizedEventsMap;
  const eventsArray = Array.isArray(events) ? events : Object.values(events);
  eventsArray.forEach((event) => {
    if (!event) return;
    const sportEventId = event.sportEventId;
    const sport = sports?.find((s) => s.id === event.sportId || s.sportId === event.sportId);
    const existingEvent = normalizedEventsMap[event.sportEventId];
    const overrideMarkets =
      addMarkets ||
      (existingEvent?.containersIds?.length || 0) > 1 ||
      !existingEvent?.containersIds?.includes(eventProps?.containerId);
    const eventClockStatus = event.eventClockStatus;
    const eventContainerMarketIds = getEventContainerMarketIds({ sportEventId, existingEvent, containerMarketIds });
    const { marketIds, markets } = getEventMarkets({ sport, event, eventProps, marketProps, columnTitles, addMarkets });
    const currentMarkets = overrideMarkets ? { ...existingEvent?.markets, ...markets } : markets;
    if (!existingEvent || replaceEvent) {
      normalizedEventsMap[event.sportEventId] = {
        ...existingEvent,
        ...event,
        ...(marketIds && !addMarkets && { marketIds }),
        markets: currentMarkets,
        eventClockStatus: setInitialEventClockStatus(event.eventClockStatus),
        isMultiline: !!sport?.isMultiline,
        ...eventProps,
        ...(existingEvent?.containersIds && { containersIds: existingEvent?.containersIds }),
        containerMarketIds: eventContainerMarketIds,
      };
    } else if (existingEvent && pollingChangeEvent) {
      pollingChangeEventFunc({ existingEvent, eventClockStatus, event, markets, marketIds, addMarkets });
    }
    if ((isEmpty(existingEvent?.marketIds) || isEmpty(existingEvent?.markets)) && !isEmpty(marketIds)) {
      !addMarkets && (normalizedEventsMap[event.sportEventId].marketIds = marketIds);
      normalizedEventsMap[event.sportEventId].markets = currentMarkets;
    }
    if (existingEvent?.containersIds && !existingEvent?.containersIds?.includes(eventProps?.containerId)) {
      const existingEventContainerIds = existingEvent?.containersIds || [];
      normalizedEventsMap[event.sportEventId].containersIds = [eventProps?.containerId, ...existingEventContainerIds];
    }
    if (eventContainerMarketIds && !isEqual(eventContainerMarketIds, existingEvent?.containerMarketIds)) {
      normalizedEventsMap[event.sportEventId].containerMarketIds = eventContainerMarketIds;
    }
    // adding markets for containers outright events
    if (existingEvent && !isEmpty(existingEvent?.marketIds) && addMarkets) {
      const existingMarkets = normalizedEventsMap[event.sportEventId].markets;
      Object.keys(markets).forEach(
        (marketId) => !existingMarkets[marketId] && (existingMarkets[marketId] = markets[marketId]),
      );
    }
  });
  return normalizedEventsMap;
};
export const isOutright = (sportId?: number): boolean | undefined => sportId === 1040; // TODO F1, golf, etc
export const mapOutcomes = (outcomes: Sport.Outcome[], sportId: number | undefined): Sport.Outcome[] => {
  return !isOutright(sportId) ? outcomes : [];
};

export const getEventMarkets = <T extends ET.WithMarkets_Event>({
  sport,
  event,
  eventProps,
  marketProps,
  columnTitles,
  addMarkets,
}: {
  sport?: Partial<SportType>;
  event: T;
  eventProps?;
  marketProps?: { live: boolean };
  columnTitles?: InPlayTypes.Columns[];
  addMarkets?: boolean;
}): { marketIds: string[] | undefined; markets: Record<string, Sport.MultilineMarket> } => ({
  marketIds: !addMarkets ? event.markets?.map((market) => market?.marketId) : [],
  markets: event.markets?.reduce((acc, curr) => {
    acc[curr?.marketId] = {
      ...curr,
      ...((sport?.isMultiline || eventProps?.isMultiline) && {
        columnPosition: (sport?.columnTitles || columnTitles)?.findIndex(
          (columnTitle) => columnTitle?.marketTemplateId === curr.marketTemplateId,
        ),
      }),
      outcomes: mapOutcomes(curr?.outcomes, event.sportId),
      isFavourite: curr?.favoriteMarket,
      ...marketProps,
    };
    return acc;
  }, {}),
});

export const showNeutralGroundFunc = (
  eventNeutralGround: boolean | null | undefined,
  eventSportId: number,
  eventStatusConfig: SportDataTypes.EventStatusConfig | null,
): boolean => {
  if (eventNeutralGround) {
    const sportConfig = eventStatusConfig?.sportsConfig?.filter((sport) => sport.id === eventSportId)[0];
    return !!sportConfig?.config?.showNeutralGround;
  }
  return false;
};

type Props = {
  totalCount: number;
  events: number[];
  maxCount: number;
};

const countEventsInView = ({ totalCount, events, maxCount }: Props): number => {
  if (totalCount > maxCount) return 0;

  if (maxCount - totalCount >= events.length) return events.length;

  return maxCount - totalCount;
};

/**
 * Assigns the number of events that will be "in view" in each sport / tournament.
 * The value of 'defaultEventsInView' is passed to Event component to evaluate 'initialInView' for useInView hook
 */
export const assignEventsCountInView = <T extends { eventIds: number[] }>({
  sportsOrTournaments,
  maxCount = 10,
}: {
  sportsOrTournaments: T[];
  maxCount?: number;
}) => {
  let totalCount = 0;
  return sportsOrTournaments.map((item) => {
    let eventsCount = 0;
    if (!isEmpty(item.eventIds)) {
      eventsCount = countEventsInView({ totalCount, events: item.eventIds, maxCount });
      totalCount += eventsCount;
    }

    return {
      ...item,
      defaultEventsInView: eventsCount,
    };
  });
};

type Params = {
  sports: InPlayTypes.Sport[];
  tournamentsMap: Record<string, InPlayTypes.Tournaments>;
  maxCount?: number;
};
/**
 * Goes through the tournaments in each sport and calculates how many events of the given tournament will be "in view".
 * The value of 'defaultEventsInView' is passed to Event component to evaluate 'initialInView' for useInView hook
 */
export const assignEventsCountInViewToMap = ({ sports, tournamentsMap, maxCount = 10 }: Params) => {
  let totalCount = 0;
  const updatedTournaments = sports.reduce(
    (map, sport) => {
      const tournamentsInSport = sport.tournamentIds.reduce(
        (tournamentMap, currId) => {
          const eventsCount = countEventsInView({ totalCount, events: tournamentsMap[currId].eventIds, maxCount });
          totalCount += eventsCount;
          tournamentMap[currId] = {
            ...tournamentsMap[currId],
            defaultEventsInView: eventsCount,
          };
          return tournamentMap;
        },
        {} as Record<string, InPlayTypes.Tournaments>,
      );

      return {
        ...map,
        ...tournamentsInSport,
      };
    },
    {} as Record<string, InPlayTypes.Tournaments>,
  );

  return updatedTournaments;
};

const getEventContainerMarketIds = ({ sportEventId, existingEvent, containerMarketIds }): string[] | undefined => {
  if (isEmpty(containerMarketIds)) return existingEvent?.containerMarketIds;
  let containerMarkets = [...containerMarketIds]?.filter((m) => m.sportEventId == sportEventId)?.map((m) => m.marketId);
  if (existingEvent?.containerMarketIds && containerMarkets) {
    containerMarkets = containerMarkets.concat(existingEvent.containerMarketIds);
  }
  if (isEmpty(containerMarkets)) return;
  return [...new Set(containerMarkets)] as string[];
};

export const getEnhancedMarketBonusInfo = () => ({
  promotionId: 33421134566,
  alias: '',
  title: t('Bonuses.EnhancedMarkets.popOverTitle'),
  categoryAlias: '',
  description: t('Bonuses.EnhancedMarkets.popOverDescription'),
  type: 'ENHANCE_ODDS',
});
