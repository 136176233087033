import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { TFunction } from 'i18n';
import { showMyProfileModal } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppDispatch } from 'store';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import CTAUI from '../../../../components/CTAUI';

const NoOffers: React.FC<{ t: TFunction; text: string }> = ({ t, text }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const navigateToPromotions = () => {
    dispatch(showMyProfileModal(false));
    navigate(`/promotions`);
  };
  return <CTAUI.Empty text={text} linkText={t(MyProfile.OffersTab.promotionsLink)} onClick={navigateToPromotions} />;
};

export default memo(NoOffers);
