import styled from 'styled-components';

const CardBody = styled.div.attrs({ className: 'prm-lobby-card__body' })`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

export default CardBody;
