import { ValidationRule, ValidationValueMessage, FieldValues as FormFieldValues, RefCallBack } from 'react-hook-form';
import type { ButtonGroupButtons } from './components/FormButtonGroup/formButtonGroup.types';

export type FieldValues = FormFieldValues;
export type RefType = RefCallBack;
export type OptionsType<type extends name> = type extends Phone ? string[] : DropdownOptionType[];
type name = string | 'phoneNumber';
type Phone = 'phoneNumber';
export type OnChangeTypeType<type extends InputTypes> = type extends InputTypes.DATETIME
  ? (dateTimeName: 'day' | 'month' | 'year') => (value: string | number) => void
  : (e?: React.ChangeEvent<HTMLInputElement>) => void | undefined;

export type FieldsType =
  | 'username'
  | 'password'
  | 'passwordConfirm'
  | 'countryName'
  | 'ucn'
  | 'birthday'
  | 'gender'
  | 'firstName'
  | 'lastName'
  | 'currencyName'
  | 'email'
  | 'phoneNumber'
  | 'city'
  | 'address'
  | 'affiliateCode'
  | 'over18'
  | 'acceptTerms'
  | 'politicallyExposed'
  | 'politicallyExposedRadio'
  | 'offers'
  | 'noAccounts'
  | 'toggleMfa'
  | 'mfaChannel'
  | 'confirmationCode'
  | 'socialMediaCredentials'
  | 'toggleMothersNameBeneficient'
  | 'toggleMothersName';

export interface FormFieldTypes {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  apiUrl?: string;
  type: InputTypes;
  inputType: InputType;
  inputMode?: InputModeType;
  validationRules?: ValidationRules;
  compareWith?: { value: string; message: string };
  defaultValue?: string | boolean;
  gridStyles?: GridStyles;
  options?: OptionsType<name>;
  link?: string;
  linkLabel?: string;
  readOnly?: boolean;
  relatedTo?: string;
  enabled?: boolean;
  effect?: FieldEffects;
  ci?: Captcha;
  disableOnSocialMedia?: boolean;
  hiddenOnSocialMedia?: boolean;
  disableOnValue?: boolean;
}

export type DependantReset = { fields: FieldsType[]; values: string[] };
export type DependantUpdate = Record<FieldsType, { setValue: boolean | string; targetValue: number | string }>;

export type Captcha = { image: string; refreshUrl: string; remaningTimeText?: string };
export type CaptchaRefresh = { ci: string; refreshTimeSeconds: number };

export type FieldEffects = {
  patternsFields?: string[];
  hiddenOnValue?: Partial<Record<FieldsType, string[]>>;
  disabledOnField?: Partial<Record<FieldsType, boolean>>;
  // requiredOnField?: Partial<Record<FieldsType, boolean>>;
  dependantReset?: DependantReset;
  dependantUpdate?: DependantUpdate;
  dependantField?: string;
  dependancy?: string[];
  replacePattern?: string; // TO DO remove
  replace?: {
    pattern?: string;
    text?: string;
    callback?: 'br';
    length?: number;
  };
};

export enum PhoneStatusEnum {
  verified = 'verified',
  pending = 'pending',
}

export type FormStatusType = keyof typeof PhoneStatusEnum;

export type FormControlTypes<T = OnChangeTypeType<InputTypes>> = {
  type?: InputTypes;
  name: string;
  label?: string;
  endLabel?: string;
  placeholder?: string;
  inputType?: InputType;
  inputMode?: InputModeType;
  validationRules?: ValidationRules;
  compareWith?: { value: string; message: string };
  gridStyles?: GridStyles;
  options?: OptionsType<name>;
  link?: string;
  linkLabel?: string;
  linkType?: 'external';
  links?: { label?: string; link?: string; linkLabel?: string }[];
  componentLocation?: InputLocationType;
  classNames?: string;
  hasPrefix?: string;
  hasError?: boolean;
  message?: string;
  hasSuccess?: boolean;
  hasInfo?: boolean;
  isChecked?: boolean;
  onChange?: T;
  onFocus?: (e?) => void;
  clearErrors?: () => void;
  onBlur?: (e?: React.FocusEvent<HTMLElement>) => void;
  maxLength?: number;
  minLength?: number;
  inputIcon?: string;
  autoComplete?: string;
  value?: string;
  preselectValue?: string;
  hasErrorIcon?: boolean;
  hasSuccessIcon?: boolean;
  isMobileAgnostic?: boolean;
  labelOnlyOnDesktop?: boolean;
  prefixOnlyOnMobile?: boolean;
  errorOnlyOnDesktop?: boolean;
  errorMessage?: string;
  infoMessage?: string;
  apiUrl?: string;
  controlRef?; //: RefCallBack;
  groupType?: 'default' | 'simple';
  validation?: Validation;
  fieldLabel?: boolean;
  position?: 'left' | 'right';
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  data?: FieldValues;
  onChangeCallback?: () => void;
  externalError?: boolean;
  // Effects are used to define hidden/disabled/dependant fields on other fields value change
  effect?: FieldEffects;
  // Derived from effect property
  disabled?: boolean;
  disableOnValue?: boolean;
  hidden?: boolean;
  defaultValue?;
  groupeClass?: string;
  numInputs?: number;
  shouldAutoFocus?: boolean;
  buttons?: ButtonGroupButtons[];
  isOutlined?: boolean;
  readOnly?: boolean;
  status?: FormStatusType;
  CustomComponent?;
  isHidden?: boolean;
};

export enum InputTypes {
  INPUT = 'input',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  CHECKBOX_INPUT = 'checkboxInput',
  DATETIME = 'datetime',
  CAPTCHA = 'captcha',
  SELECT = 'select',
  SWITCH = 'switch',
  FORMCHAT = 'formChat',
  LABEL = 'label',
  PHONENUMBER = 'phoneNumber',
  OTP = 'confirmationCode',
  BUTTON = 'button',
  BUTTON_GROUP = 'buttonGroup',
}

export enum InputNameTypes {
  EMAIL = 'email',
  USERNAME = 'username',
  PASSWORD = 'password',
  BIRTHDAY = 'birthday',
}

export const InputIcons = {
  [InputNameTypes.EMAIL]: 'envelope',
  [InputNameTypes.USERNAME]: 'user-active',
  [InputNameTypes.PASSWORD]: 'key',
  [InputNameTypes.BIRTHDAY]: 'calendar',
};

export const InputModes = {
  [InputNameTypes.EMAIL]: 'email',
  [InputNameTypes.USERNAME]: 'text',
  [InputNameTypes.PASSWORD]: 'numeric',
};

export type DropdownOptionType = {
  text?: string;
  value: string;
  name?: string;
  symbol?: string;
  icon?: string;
  disabled?: boolean;
};

export interface AdditionalInputProps {
  autoComplete?: string;
  value?: string;
  readOnly?: boolean;
  disabled?: boolean;
  pattern?: string;
}

export type InputModeType = 'none' | 'url' | 'email' | 'text' | 'tel' | 'numeric' | 'decimal' | 'search';
export type InputType = 'text' | 'checkbox' | 'number' | 'password';

export const inputLocationTypeClassNameMap = {
  auth: 'auth',
  login: 'login',
  myAccount: 'ma',
  myBeneficient: 'ma',
  reg: 'reg',
  realityCheckSession: 'rcs',
} as const;
export type InputLocationType = keyof typeof inputLocationTypeClassNameMap;
interface GridStyles {
  col?: number;
  md?: number;
  row?: number;
  size?: number;
  fraction?: number;
  rowDesktop?: number;
  sizeDesktop?: number;
}

export type ValidationRules<T = ValidationValueMessage<boolean> | boolean> = {
  maxLength?: ValidationValueMessage<number>;
  minLength?: ValidationValueMessage<number>;
  maxValue?: ValidationValueMessage<number | string>;
  minValue?: ValidationValueMessage<number | string>;
  pattern?: ValidationValueMessage<RegExp>;
  patterns?: Record<FieldsType, ValidationValueMessage<RegExp>>;
  required?: T;
  validationAsync?: AsyncValidation;
  infoAsync?: AsyncValidation;
  min?: ValidationValueMessage<number>;
  max?: ValidationValueMessage<number>;
  validate?;
};

export interface Validation {
  required?: ValidationRule<boolean>;
  min?: ValidationRule<number>;
  max?: ValidationRule<number>;
}

export interface AsyncValidation {
  message: string;
  apiUrl: string;
  fields: string[];
}

interface GtmAddressParams {
  first_name: string;
  last_name: string;
  city: string;
  country: string;
  street: string;
}

export interface GtmRegistrationParams {
  userId: string;
  phone_number: string;
  email: string;
  address: Record<string, GtmAddressParams>;
}

export type OnSubmit = (data: FieldValues) => void;
export type GetCustomProps = (
  field: Partial<FormFieldTypes>,
  options?: { text: string; value: string }[],
) => Partial<FormControlTypes> | undefined;
