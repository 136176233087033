import userStorage from 'pages/auth/login/UserStorage';
import { addLeadingZero, calcTimeToday, calcTimeTomorrow, dateDiffer } from './dateHelpers';
import { capitalizeFirstLetter, localLog } from '../common/helpersCommon';

// Foramtter uses js native Intl.DateTimeFormat.
// The Intl.DateTimeFormat object enables language-sensitive date and time formatting.: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
// additional resource: https://devhints.io/wip/intl-datetime

const languages = {
  today: {
    en: 'Today',
    bg: 'Днес',
  },
  tomorrow: {
    bg: 'Утре',
    en: 'Tomorrow',
  },
};

const getFormatterFunc = (lang: string, options: Intl.DateTimeFormatOptions) => {
  const formatLang = lang === 'en' ? 'en-GB' : lang;
  const formatFunc = new Intl.DateTimeFormat(formatLang, options).format;
  return (timestamp: number) => {
    let dateStr = formatFunc(timestamp);

    if (lang === 'bg' && options.hour) {
      if (!dateStr.includes('ч.')) {
        dateStr += ' ч.';
      }
      return addLeadingZero(dateStr);
    }

    return dateStr;
  };
};

/**
 * The different acts more like a hint. The final format will depend on the current locale i.e language of the user
 */
export type FormatType =
  | 'DD.MM HH:mm'
  | 'dddd HH:mm'
  | 'DD.MM.YYYY'
  | 'HH:mm DD.MM.YYYY'
  | 'HH:mm'
  | 'HH:mm:ss'
  | 'dddd HH:mm || DD.MM HH:mm'
  | 'DD.MM.YYYY HH:mm'
  | 'DD.MM.YYYY HH:mm:ss'
  | 'mm:ss without-locale' // does not include ч. or AM PM after time: 12:34
  | 'HH:mm:ss without-locale' // does not include ч. or AM PM after time: 15:34:57
  | 'HH:mm:ss without-locale diff'
  | 'All.HH:mm:ss' // calculates all hours - even if they are more than 24
  | 'Days HH:mm:ss'; //calculates remaining days, hours, minutes, seconds

type DateType = string | number | undefined | null;

type FormatDateFunc = (
  timestamp: number,
  offset: number,
  translations?: { day: string; days: string } | undefined,
) => string;

const getFormatterTypes = (): Record<FormatType, FormatDateFunc> => {
  const lang = userStorage.getUserLang() || 'en';

  const formatHourMinute = (() => {
    const formatFunc = getFormatterFunc(lang, {
      hour: 'numeric',
      minute: '2-digit',
    });
    return (timestamp: number) => formatFunc(timestamp);
  })();

  const formatWeekday = (() => {
    const formatterFunc = getFormatterFunc(lang, {
      weekday: 'long',
    });
    return (timestamp: number) => capitalizeFirstLetter(formatterFunc(timestamp)) as string;
  })();

  const formatHourMinuteSecondWithH24 = (() => {
    return (timestamp: number) => {
      const date = new Date(timestamp);
      const hours = addLeadingZero(date.getHours());
      const minutes = addLeadingZero(date.getMinutes());
      const seconds = addLeadingZero(date.getSeconds());
      return `${hours}:${minutes}:${seconds}`;
    };
  })();

  const formatHourMinuteSecondWithH48Diff = (() => {
    return (timestamp: number) => {
      let difference = timestamp;
      const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 48);
      difference -= daysDifference * 1000 * 60 * 60 * 48;

      const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
      difference -= hoursDifference * 1000 * 60 * 60;

      const minutesDifference = Math.floor(difference / 1000 / 60);
      difference -= minutesDifference * 1000 * 60;

      const secondsDifference = Math.floor(difference / 1000);

      const hours = addLeadingZero(hoursDifference);
      const minutes = addLeadingZero(minutesDifference);
      const seconds = addLeadingZero(secondsDifference);
      return `${hours}:${minutes}:${seconds}`;
    };
  })();

  const formatAllHoursMinuteSecond = (() => {
    return (timestamp: number, offset: number) => {
      let difference = timestamp - offset;
      const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
      difference -= daysDifference * 1000 * 60 * 60 * 24;

      const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
      difference -= hoursDifference * 1000 * 60 * 60;

      const minutesDifference = Math.floor(difference / 1000 / 60);
      difference -= minutesDifference * 1000 * 60;

      const secondsDifference = Math.floor(difference / 1000);

      const hours = addLeadingZero(hoursDifference + daysDifference * 24);
      const minutes = addLeadingZero(minutesDifference);
      const seconds = addLeadingZero(secondsDifference);
      return `${hours}:${minutes}:${seconds}`;
    };
  })();

  const formatDayHourMinuteSecond = (() => {
    return (timestamp: number, offset: number, translations?: { day: string; days: string } | undefined) => {
      let difference = timestamp - offset;
      const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
      difference -= daysDifference * 1000 * 60 * 60 * 24;

      const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
      difference -= hoursDifference * 1000 * 60 * 60;

      const minutesDifference = Math.floor(difference / 1000 / 60);
      difference -= minutesDifference * 1000 * 60;

      const secondsDifference = Math.floor(difference / 1000);

      const days = addLeadingZero(daysDifference);
      const hours = addLeadingZero(hoursDifference);
      const minutes = addLeadingZero(minutesDifference);
      const seconds = addLeadingZero(secondsDifference);
      const daysText = days === '01' ? translations?.day : translations?.days;

      return `${days} ${daysText} ${hours}:${minutes}:${seconds}`;
    };
  })();

  const formatMinuteSecondWithH24 = (() => {
    return (timestamp: number) => {
      const date = new Date(timestamp);
      const minutes = addLeadingZero(date.getMinutes());
      const seconds = addLeadingZero(date.getSeconds());
      return `${minutes}:${seconds}`;
    };
  })();

  const formatWeekdayTime = (() => {
    const formatterFunc = getFormatterFunc(lang, {
      weekday: 'long',
      hour: 'numeric',
      minute: '2-digit',
    });
    return (timestamp: number) => capitalizeFirstLetter(formatterFunc(timestamp)) as string;
  })();

  const formatDayMonthTime = (() => {
    const formatterFunc = getFormatterFunc(lang, {
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
    });
    return (timestamp: number) => formatterFunc(timestamp);
  })();

  const formatDayMonthYear = (() => {
    const formatterFunc = getFormatterFunc(lang, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return (timestamp: number) => formatterFunc(timestamp);
  })();

  const formatHourMinuteSecond = (() => {
    const formatterFunc = getFormatterFunc(lang, {
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
    });
    return (timestamp: number) => formatterFunc(timestamp);
  })();

  return {
    'HH:mm': formatHourMinute,
    'HH:mm:ss': formatHourMinuteSecond,
    'dddd HH:mm': formatWeekdayTime,
    'DD.MM HH:mm': formatDayMonthTime,
    'DD.MM.YYYY': formatDayMonthYear,
    'mm:ss without-locale': formatMinuteSecondWithH24,
    'HH:mm:ss without-locale': formatHourMinuteSecondWithH24,
    'HH:mm:ss without-locale diff': formatHourMinuteSecondWithH48Diff,
    'HH:mm DD.MM.YYYY': (timestamp) => `${formatHourMinute(timestamp)} ${formatDayMonthYear(timestamp)}`,
    'DD.MM.YYYY HH:mm': (timestamp) => `${formatDayMonthYear(timestamp)} ${formatHourMinute(timestamp)}`,
    'DD.MM.YYYY HH:mm:ss': (timestamp) => `${formatDayMonthYear(timestamp)} ${formatHourMinuteSecond(timestamp)}`,
    'dddd HH:mm || DD.MM HH:mm': (timestamp, offset) => {
      const dayDiff = dateDiffer.daysPassedFromNowUTC(timestamp - offset);
      const minutes = 60;
      const calcOffsetInHours = Number(userStorage.getUserTimeZone()) / minutes;

      const currentDay = 0;
      const sixthDay = 6;

      // logic here is when day is between today and six days ahead, display today/tomorrow or name of the day, otherwise display date
      if (dayDiff >= currentDay && dayDiff <= sixthDay) {
        if (calcTimeToday(calcOffsetInHours) === new Date(timestamp).getDate() && languages.today[lang]) {
          return formatWeekdayTime(timestamp).replace(formatWeekday(timestamp), languages.today[lang]);
        }
        if (calcTimeTomorrow(calcOffsetInHours) === new Date(timestamp).getDate() && languages.tomorrow[lang]) {
          return formatWeekdayTime(timestamp).replace(formatWeekday(timestamp), languages.tomorrow[lang]);
        }
        return formatWeekdayTime(timestamp);
      }
      return formatDayMonthTime(timestamp);
    },
    'All.HH:mm:ss': formatAllHoursMinuteSecond,
    'Days HH:mm:ss': formatDayHourMinuteSecond,
  };
};

const getOffset = (isUTC = false, date?: DateType) => {
  const timezoneOffset = isUTC ? 0 : Number(userStorage.getUserTimeZone() ?? 0);
  const localeOffset = !date ? new Date().getTimezoneOffset() : new Date(date).getTimezoneOffset();
  return (timezoneOffset + localeOffset) * 60_000;
};

const initializeDateTimeUtils = (isUTC = false) => {
  let formattersTypes = getFormatterTypes();
  let offset = getOffset(isUTC);

  return {
    reinitializeDateFormatter: () => {
      formattersTypes = getFormatterTypes();
      offset = getOffset(isUTC);
    },
    formatDate: (
      formatType: FormatType,
      date: DateType,
      takeDateOwnOffset?: boolean,
      translations?: { day: string; days: string },
    ): string => {
      if (!date) {
        return '';
      }
      try {
        if (isUTC && typeof date === 'string' && date.charAt(date.length - 1) !== 'Z') {
          // we add Z to the end of the date string, so that it becomes an UTC string with zero hour offset!
          // all backends should start doing this as well
          date += 'Z';
        }
        offset = !takeDateOwnOffset ? offset : getOffset(isUTC, date);
        const timestamp = new Date(date).getTime() + offset;
        return formattersTypes[formatType](timestamp, offset, translations);
      } catch {
        localLog({ message: `⚠️ %c~ invalid date format provided: ${date}, color:magenta`, type: 'warn' });
        return date.toString();
      }
    },
  };
};

const dateFormatter = initializeDateTimeUtils();
const dateFormatterUTC = initializeDateTimeUtils(true);

export const reinitializeDateFormatter = (): void => {
  dateFormatter.reinitializeDateFormatter();
  dateFormatterUTC.reinitializeDateFormatter();
};

export const { formatDate: formatDateWithOffset } = dateFormatter;
export const { formatDate: formatDateUTC } = dateFormatterUTC;

// Uncomment to preview all formats in the console
// setTimeout(() => {
//   const date = '2021-07-24T00:00:00Z';
// console.log('Format: DD.MM HH:mm:___', formatDateUTC('DD.MM HH:mm', date));
// console.log('Format: dddd HH:mm:___', formatDateUTC('dddd HH:mm', date));
// console.log('Format: DD.MM.YYYY:___', formatDateUTC('DD.MM.YYYY', date));
// console.log('Format: HH:mm DD.MM.YYYY:___', formatDateUTC('HH:mm DD.MM.YYYY', date));
// console.log('Format: HH:mm:___', formatDateUTC('HH:mm', date));
// console.log('Format: dddd HH:mm || DD.MM HH:mm:___', formatDateUTC('dddd HH:mm || DD.MM HH:mm', date));
// console.log('Format: HH:mm:ss:___', formatDateUTC('HH:mm:ss', date));
// console.log('Format: DD.MM.YYYY HH:mm:___', formatDateUTC('DD.MM.YYYY HH:mm', date));
// console.log('Format: DD.MM.YYYY HH:mm:ss:___', formatDateUTC('DD.MM.YYYY HH:mm:ss', date));
// console.log('Format: DD.MM.YYYY HH:mm:ss:___', formatDateUTC('DD.MM.YYYY HH:mm:ss', date));
// console.log('Format: mm:ss without-locale:___', formatDateUTC('mm:ss without-locale', date));
// console.log('Format: HH:mm:ss without-locale', formatDateUTC('HH:mm:ss without-locale', date));
// console.log('Format: All.HH:mm:ss', formatDateUTC('All.HH:mm:ss', date));
// console.log('Format: Days HH:mm:ss', formatDateUTC('Days HH:mm:ss', date));
//}, 3000);
