import React, { useEffect, useRef } from 'react';
import Keyboard, { KeyboardReactInterface } from 'react-simple-keyboard';

const virtualKeyboard = {
  default: ['1 2 3 4', '5 6 7 8', '9 0 - {bksp}'],
};

const NumpadKeyboard = ({ handleKeyboardInput, submitCount, currentField }) => {
  const keyboardRef = useRef<KeyboardReactInterface | null>(null);

  useEffect(() => {
    if (submitCount === 1 && keyboardRef.current) {
      keyboardRef.current.clearInput('username');
      keyboardRef.current.clearInput('password');
    }
  }, [submitCount, keyboardRef.current]);

  return (
    <div className="login-keyboard">
      <Keyboard
        layout={virtualKeyboard}
        layoutName={'default'}
        inputName={currentField}
        onChange={handleKeyboardInput}
        keyboardRef={(ref) => {
          if (ref) {
            keyboardRef.current = ref;
          }
        }}
        display={{
          '{bksp}':
            '<svg viewBox="0 0 24 18.55" width="48" height="37" fill="#3F3F3F" xmlns="http://www.w3.org/2000/svg"><path d="m22 0h-15c-.66-.01-1.28.35-1.6.93l-5.4 8.34 5.4 8.35c.35.55.95.89 1.6.93h15c1.12-.02 2.01-.94 2-2.06v-14.43c.01-1.12-.88-2.04-2-2.06zm-3 12.98-1.4 1.44-3.6-3.71-3.6 3.71-1.4-1.44 3.6-3.71-3.6-3.71 1.4-1.44 3.6 3.71 3.6-3.71 1.4 1.44-3.6 3.71z"/></svg>',
        }}
        disableCaretPositioning={true}
      />
    </div>
  );
};

export default NumpadKeyboard;
