import React from 'react';
import { useGamificationWidgetConfig } from 'shared/common/features/integrations/gamification/hooks/useGamificationWidgetConfig';
import { ClubChallanges } from '../features/clubChallenges';
import ClubChallangesWidget from '../features/clubChallenges/ClubChallangesWidget';
import { ClubGames } from '../features/clubGames';
import { ClubTabsTypes } from '../types/clubTabs.types';

const items = {
  myGames: () => <ClubGames />,
  challenges: (itemKey) => <ClubChallanges itemKey={itemKey} />,
  challengesWidget: (itemKey) => <ClubChallangesWidget itemKey={itemKey} />,
};
export const ComponentMapping: React.FC<{ itemKey: ClubTabsTypes.ComponentMappingType }> = ({ itemKey }) => {
  const { isWidget } = useGamificationWidgetConfig();
  if (!itemKey) return null;
  // !TODO GAMIFICATION remove
  return items[`${itemKey === 'challenges' ? (isWidget ? 'challengesWidget' : itemKey) : itemKey}`](itemKey);
};
