export type BadgeVariants = keyof typeof badgeVariantsMap;

const cssNS = 'badge';

export const badgeVariantsMap = {
  positive: { css: `${cssNS} ${cssNS}-positive`, status: 'positive' },
  negative: { css: `${cssNS} ${cssNS}-negative`, status: 'negative' },
  neutral: { css: `${cssNS} ${cssNS}-neutral`, status: 'neutral' },
  neutralLight: { css: `${cssNS} ${cssNS}-neutral-light`, status: 'neutral' },
  neutralDark: { css: `${cssNS} ${cssNS}-neutral-dark`, status: 'neutral' },
  info: { css: `${cssNS} ${cssNS}-info`, status: 'info' },
  infoDark: { css: `${cssNS} ${cssNS}-info-dark`, status: 'info' },
  warning: { css: `${cssNS} ${cssNS}-warning`, status: 'warning' },
  circle: { css: `${cssNS} ${cssNS}-circle`, status: 'positive' },
  banker: { css: `${cssNS} ${cssNS}-banker`, status: 'positive' },
  statusPositive: { css: `${cssNS} ${cssNS}-selection-status ${cssNS}-selection-status--positive`, status: 'positive' },
  statusNegative: { css: `${cssNS} ${cssNS}-selection-status ${cssNS}-selection-status--negative`, status: 'negative' },
  statusError: { css: `${cssNS} ${cssNS}-selection-status ${cssNS}-selection-status--negative`, status: 'error' },
  statusInfo: { css: `${cssNS} ${cssNS}-selection-status ${cssNS}-selection-status--info`, status: 'info' },
  statusNeutral: { css: `${cssNS} ${cssNS}-selection-status ${cssNS}-selection-status--neutral`, status: 'neutral' },
  statusSoldChips: {
    css: `${cssNS} ${cssNS}-poker-status ${cssNS}poker-status--sold-chips`,
    status: 'soldChips',
  },
  statusBuyIn: { css: `${cssNS} ${cssNS}-poker-status ${cssNS}poker-status--buy-in`, status: 'buyIn' },
  statusRefund: { css: `${cssNS} ${cssNS}-poker-status ${cssNS}-poker-status--refund`, status: 'refund' },
} as const;

export const badgeVariantsLayout2Map = {
  positive: { css: `${cssNS} ${cssNS}-positive bg--success`, status: 'positive' },
  negative: { css: `${cssNS} ${cssNS}-negative bg--warning`, status: 'negative' },
  neutral: { css: `${cssNS} ${cssNS}-neutral`, status: 'neutral' },
  neutralLight: { css: `${cssNS} ${cssNS}-neutral-light`, status: 'neutral' },
  neutralDark: { css: `${cssNS} ${cssNS}-neutral-dark`, status: 'neutral' },
  info: { css: `${cssNS} ${cssNS}-info bg--info`, status: 'info' },
  infoDark: { css: `${cssNS} ${cssNS}-info-dark bg--info`, status: 'info' },
  circle: { css: `${cssNS} ${cssNS}-circle`, status: 'positive' },
  banker: { css: `${cssNS} ${cssNS}-banker bg--success`, status: 'positive' },
  statusPositive: { css: `${cssNS} ${cssNS}-selection-status bg--success`, status: 'positive' },
  statusNegative: { css: `${cssNS} ${cssNS}-selection-status bg--warning`, status: 'negative' },
  statusError: { css: `${cssNS} ${cssNS}-selection-status bg--error`, status: 'negative' },
  statusInfo: { css: `${cssNS} ${cssNS}-selection-status bg--info`, status: 'info' },
  statusNeutral: { css: `${cssNS} ${cssNS}-selection-status ${cssNS}-selection-status--neutral`, status: 'neutral' },
  statusSoldChips: {
    css: `${cssNS} ${cssNS}-poker-status ${cssNS}poker-status--sold-chips`,
    status: 'soldChips',
  },
  statusBuyIn: { css: `${cssNS} ${cssNS}-poker-status ${cssNS}poker-status--buy-in`, status: 'buyIn' },
  statusRefund: { css: `${cssNS} ${cssNS}-poker-status ${cssNS}-poker-status--refund`, status: 'refund' },
} as const;
