import React from 'react';
import { Flex } from 'components/shared/layout';

interface StepProps {
  stepNum: number;
  text: string;
}

const Step: React.FC<StepProps> = ({ stepNum, text }) => {
  return (
    <Flex.AC className="my-2 prm-step">
      <Flex.AC className="mr-4 text-tabular prm-step__indicator">{stepNum}</Flex.AC>
      <div className="prm-step__text">{text}</div>
    </Flex.AC>
  );
};

export default Step;
