import { TabPanel } from 'react-tabs';
import { General } from 'shared/common/features/general/types/generalSlice.types';
import { ClubTab } from './captainUpTab';
import NavigationTabsContainer from './NavigationTabsContainer';
import { OffersTab } from './offersTab';
import { SmarticoTab } from './smarticoTab';
import { MyProfileTabType, MyProfileTabsEnum } from '../../types/myProfile.types';

interface Props {
  key: MyProfileTabType['key'];
  parentRef?: React.MutableRefObject<HTMLDivElement | null>;
  subMenu?: General.MyProfileRoutes[] | null;
}

export const renderAccountTab: React.FC<Props> = ({ key, parentRef, subMenu }) => {
  switch (key) {
    case MyProfileTabsEnum.ACCOUNT:
      return (
        <TabPanel key={key} className="d-flex-col">
          <NavigationTabsContainer subMenu={subMenu} parentRef={parentRef} />
        </TabPanel>
      );
    case MyProfileTabsEnum.OFFERS:
      return (
        <TabPanel key={key} className="mpd-section__tab-panel mpd-section-offers no-scrollbars">
          <OffersTab />
        </TabPanel>
      );
    case MyProfileTabsEnum.CLUB:
      return (
        <TabPanel
          key={key}
          className="mpd-section__tab-panel no-scrollbars mpd-section__tab-panel--club"
          selectedClassName={'h-100'}
        >
          <ClubTab subMenu={subMenu} />
        </TabPanel>
      );
    case MyProfileTabsEnum.SMARTICO:
      return (
        <TabPanel key={key} className="mpd-section__tab-panel no-scrollbars mpd-section__tab-panel--club">
          <SmarticoTab subMenu={subMenu} />
        </TabPanel>
      );
    default:
      return <TabPanel key={key} />;
  }
};
