export const loginErrors = {
  incorrectCredentials: 'Authentication.Error.incorrectCredentials',
  accountLockAttempts: 'Authentication.Error.accountLockAttempts',
  accountLockAttemptsCount: 'Authentication.Error.accountLockAttemptsCount',
  loginFailed: 'Authentication.Error.loginFailed',
  blockedDormantPlayer: 'Authentication.Error.blockedDormantPlayer',
  emailNotVerified: 'Authentication.Error.emailNotVerified',
  changedEmailNotVerified: 'Authentication.Error.changedEmailNotVerified',
};

export const preLinkTextConfig = {
  // incorrectCredentials: 'Authentication.ErrorSubheader.tryAgainText',
  loginFailed: 'Authentication.ErrorSubheader.tryAgainText',
  blockedDormantPlayer: 'Authentication.ErrorSubheader.contactPreLinkText',
};
export const linkTextConfig = {
  // incorrectCredentials: 'Authentication.ErrorSubheader.clickHereText',
  loginFailed: 'Authentication.ErrorSubheader.clickHereText',
  blockedDormantPlayer: 'Authentication.ErrorSubheader.contactLinkText',
};
export const postLinkTextConfig = {
  // incorrectCredentials: 'Authentication.ErrorSubheader.retrieveAccText',
  loginFailed: 'Authentication.ErrorSubheader.retrieveAccText',
  blockedDormantPlayer: 'Authentication.ErrorSubheader.contactPostLinkText',
};

export const linkToConfig = {
  incorrectCredentials: '/forgot-password',
  loginFailed: '/forgot-password',
  blockedDormantPlayer: '/help/contact-us',
};

export const isUnverified = (loginError?: string | null): boolean =>
  loginError === loginErrors.emailNotVerified || loginError === loginErrors.changedEmailNotVerified;

type ErrorConfig = {
  hasRedirect: boolean;
  preLinkText: string;
  linkText: string;
  postLinkText: string;
  linkTo: string;
};

export const useLoginErrors = (errorMessage: string): ErrorConfig => {
  const hasRedirect = !!errorMessage && Object.values(loginErrors).includes(errorMessage);
  const error = errorMessage.split('.')?.splice(-1)?.[0];

  const preLinkText = preLinkTextConfig[error] || '';
  const linkText = linkTextConfig[error] || '';
  const postLinkText = postLinkTextConfig[error] || '';
  const linkTo = linkToConfig[error] || '';

  return { hasRedirect, preLinkText, linkText, postLinkText, linkTo };
};

export default useLoginErrors;
