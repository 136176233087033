import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { Registration } from 'utils/common/translationUtils/translationStrings';
import { HelpDropdown } from './HelpDropdown';
import { selectRegistration } from '../slice/registration.slice';

const HelpDropdownMenu: React.FC = () => {
  const { t } = useTranslation();
  const HelpMenuItems = useAppSelector(selectRegistration.helpMenus);

  if (!HelpMenuItems) return null;

  return (
    <HelpDropdown.Menu text={t(Registration.HelpDropdownMenu.helpText)}>
      {HelpMenuItems.map((item, idx) => {
        return (
          <HelpDropdown.Item key={idx} href={item.route} icon={item.icon} text={t(item.name)} type={item.routeType} />
        );
      })}
    </HelpDropdown.Menu>
  );
};

export default HelpDropdownMenu;
