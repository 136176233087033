import { useTranslation } from 'react-i18next';
import { CasinoWidgetsTypes } from 'modules/casino/modules/container/widget/types/casinoWidgetsTypes';
import { Flex } from 'modules/casino/shared/components/layout';
import { useCurrency } from 'modules/casino/shared/hooks/useCurrency';
import StakeItem from './StakeItem';
import WinItem from './WinItem';
import { BigWinsWidgetTranslationKeys } from '../../../bigWinsConstants';

interface DescriptionProps {
  bigWin: CasinoWidgetsTypes.BigWin;
}

const CellDescription: React.FC<DescriptionProps> = ({ bigWin }) => {
  const { formatCurrency } = useCurrency();
  const { t } = useTranslation();
  const currencySymbol = formatCurrency(bigWin?.currency);
  const title = t(BigWinsWidgetTranslationKeys.bigWinTitle) || '';
  const placeLabel = bigWin?.place && bigWin?.date ? `${bigWin.date} ${bigWin.place}` : '';

  return (
    <Flex.ColAC className="bww-item__description">
      <div className="bww-item__title">{title?.toUpperCase()}</div>
      <WinItem value={bigWin?.winAmount} currencySymbol={currencySymbol} />
      <StakeItem
        label={t(BigWinsWidgetTranslationKeys.bigWinStake)}
        value={bigWin?.stake}
        currencySymbol={currencySymbol}
      />
      <div className="bww-item__location">{placeLabel}</div>
    </Flex.ColAC>
  );
};

export default CellDescription;
