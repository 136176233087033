import { General } from 'shared/common/features/general/types/generalSlice.types';

export const LayoutTypeConfig: General.LayoutTypesConfig = {
  sport: {
    betSlip: 'layout_1',
    myBets: 'layout_1',
    sportBtns: 'layout_1',
    carousel: 'layout_1',
    betStatusBadge: 'layout_1',
    lastWinner: 'layout_1',
  },
  navigationLayout: 'layout_1',
  promotions: { navigation: 'layout_1' },
  offersTab: 'layout_1',
  header: {
    wrapper: 'layout_1',
    drawerToggler: 'layout_1',
  },
  home: {
    videoBanner: 'layout_1',
  },
  casino: {
    grid: {
      loadMoreBtn: 'layout_1',
    },
    latestWinners: 'layout_1',
    latestWinnersNew: {
      Landing: 'layout_1',
      Casino: 'layout_1',
    },
  },
  sitemapTags: {
    changeFreq: 'weekly',
  },
};
