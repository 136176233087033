import { useCallback, useLayoutEffect, useRef } from 'react';
import { useAppDispatch } from 'store';
import { setAppHeaderHeight } from '../../components/appHeaderContainer/slice/appHeader.slice';

export const useRetailHeaderHeight = (): ((node: HTMLDivElement | null) => void) => {
  const dispatch = useAppDispatch();
  const headerRef = useRef<HTMLDivElement | null>(null);

  const handleRef = useCallback(
    (node: HTMLDivElement | null) => {
      node && (headerRef.current = node);
    },
    [headerRef],
  );

  useLayoutEffect(() => {
    if (headerRef.current) {
      dispatch(setAppHeaderHeight(headerRef.current?.clientHeight));
    }
  }, [headerRef.current, dispatch]);

  return handleRef;
};
