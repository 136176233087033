import { useCallback } from 'react';
import { useLocation } from 'react-router';
import useMyBetsRoute from 'hooks/common/useMyBetsRoute';
import usePlayerRestrictions from 'hooks/common/usePlayerRestrictions';
import { useGetFavGamesCounter } from 'modules/casino/modules/container/cellsContainer/cellls/additionalGames/favourites/hooks/useGetFavGamesCounter';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { selecGamificationPage } from 'pages/gamificationPage/slice/gamificationPage.slice';
import { PRODUCT_TYPE_ENUM } from 'pages/myAccount/tabs/responsibleGambling/types/responsibleGambling.types';
import { useAppSelector } from 'store';
import { selectNavigationMenu } from '../../navigationMenu/slice/navigationMenu.slice';
import { selectBottomTabBar } from '../slice/bottomTabBar.slice';
import { BottomTabBarTypes, TAB_TYPE } from '../types/bottomTabBar.types';
import { handleFilterBottomBar } from '../utils/bottamTabBar.utils';

type HookReturnType = {
  filteredTabs: BottomTabBarTypes.TabBarType[];
  setTabCounter: (route: string | null) => {
    [x: string]: number | string;
  };
  isBottomTabBar: boolean;
};

export const useBottomTabBarProps = (): HookReturnType => {
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const navigationMenu = useAppSelector(selectNavigationMenu.verticals);
  const homePageVertical = useAppSelector(selectNavigationMenu.homePageVertical);
  const unsettledBetsCount = useAppSelector(selectBottomTabBar.unsettledBetsCount);
  const unreadPromoCount = useAppSelector(selecGamificationPage.allUnreadNotifications);
  const favoriteGamesCount = useGetFavGamesCounter();
  const { playerRestrictionsByProducts } = usePlayerRestrictions({
    products: [PRODUCT_TYPE_ENUM.SPORT], // If this is going to be dynamic we could use tab prop
  });
  const { myBetsMenuPath } = useMyBetsRoute(location.pathname);
  const basePath = (myBetsMenuPath || location.pathname).split('/')[1];
  const homePageVerticalData = useAppSelector(selectNavigationMenu.homePageVertical);

  const { pathname } = useLocation();
  const isHomePage = pathname === homePageVertical?.route;

  const menuItems = navigationMenu?.find((item) => {
    return `/${basePath}` === item.route || item.isHomePage;
  });
  const activeBottomTabBar = isHomePage ? homePageVerticalData?.bottomTabBar : menuItems?.bottomTabBar;

  const filteredTabs =
    activeBottomTabBar?.filter((tab) => handleFilterBottomBar(tab, playerRestrictionsByProducts, isAuthenticated)) ||
    [];

  const isBottomTabBar = !!filteredTabs.length;

  const setTabCounter = useCallback(
    (route: string | null) => ({
      [TAB_TYPE.MYBETS]: (unsettledBetsCount || 0).toString(), // 0 should be displayed in badge
      [TAB_TYPE.CASINO_FAVOURITES]: favoriteGamesCount,
      [TAB_TYPE.PROMOTIONS]: unreadPromoCount?.[route || ''] || 0,
    }),
    [unsettledBetsCount, favoriteGamesCount, unreadPromoCount],
  );

  return {
    filteredTabs,
    setTabCounter,
    isBottomTabBar,
  };
};
