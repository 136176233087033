import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { Breakpoints } from 'theme/Theme';

const sharedStyle = css`
  padding: 2px 30px;
  min-width: 235px;
  height: 40px;
  transition: all 0.15s;
  text-align: center;
  color: #000;
`;

export const StyledCTAButton = styled(motion.button)<{ $addMarginTop?: string }>`
  ${sharedStyle};

  @media screen and (max-width: ${Breakpoints.isMobile}px) {
    margin-top: ${({ $addMarginTop }) => $addMarginTop && $addMarginTop};
  }
  font-size: 22px;
  font-weight: 700;

  vertical-align: middle;
  border: 0;
  border-radius: 100px;
  background-color: #d7b247;
  transition: background-color 0.15s;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: #e6b423;
    }
  }

  &:disabled {
    filter: grayscale(1);
  }
`;

export const StyledPlaceholder = styled.div.attrs(({ className }: { className? }) => ({
  className: className,
}))`
  ${sharedStyle};
`;
