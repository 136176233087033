import React, { useRef } from 'react';
import { CasinoWidgetsTypes } from 'modules/casino/modules/container/widget/types/casinoWidgetsTypes';
import { WidgetTemplate } from 'modules/casino/shared/constants';
import { GamePlayType } from 'modules/casino/shared/features/gameLaunch/casinoLaunchTypes';
import GameDescription from './GameDescription';
import GameImageWrapper from './GameImageWrapper';
import GameItemSkeleton from './GameItemSkeleton';
import { useOpenGame } from '../../../hooks/useOpenGame';

interface GameItemProps {
  lastWinner: CasinoWidgetsTypes.LastWinner;
  type: CasinoWidgetsTypes.WidgetTypes;
  cdnPathLayout: string;
  gameLayout?: string;
}

const LastWinnersWidgetGameItem: React.FC<GameItemProps> = ({ lastWinner, type, cdnPathLayout, gameLayout }) => {
  const widgetGameRef = useRef<HTMLDivElement>(null);
  const { handleOpenGame } = useOpenGame({
    providerId: lastWinner?.providerId,
    description: lastWinner?.description,
    refGameId: lastWinner?.refGameId,
    providerAlias: lastWinner?.providerAlias,
    id: lastWinner?.id,
    iframeLoad: lastWinner?.iframeLoad,
    gameType: lastWinner?.gameType,
    betType: lastWinner?.betType,
  });

  return (
    <>
      {lastWinner ? (
        <div
          className={` d-flex cw__game cw__game--${WidgetTemplate[type]} cursor-pointer`}
          ref={widgetGameRef}
          onClick={(e) => handleOpenGame(e, GamePlayType.PAID)}
        >
          <GameImageWrapper lastWinner={lastWinner} type={type} cdnPathLayout={cdnPathLayout} gameLayout={gameLayout} />
          <GameDescription lastWinner={lastWinner} type={type} />
        </div>
      ) : (
        <GameItemSkeleton skeletons={1} type={type} />
      )}
    </>
  );
};
LastWinnersWidgetGameItem.displayName = 'LastWinnersWidgetGameItem';
export default LastWinnersWidgetGameItem;
