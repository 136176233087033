import React from 'react';
import clsx from 'clsx';
import { ThemedIcon } from 'modules/casino/theme/Theme';

type LogoProps = {
  icon: string;
  path: string;
  className: string;
  inDrawer?: boolean;
  width?: number;
  height?: number;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
};

/** ProvidersBarUI Icon */
const Logo: React.FC<LogoProps> = ({ icon, path, className, width, height, onClick }) => {
  const iconClass = clsx(className);

  return (
    <img
      src={path + icon + ThemedIcon() + '.svg'}
      className={iconClass}
      onClick={onClick}
      width={width}
      height={height}
    />
  );
};

export default Logo;
