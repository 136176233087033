import { EntityId, PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { SocialTypes } from 'components/shared/forms/components/FormSocial/types/formSocial.types';
import { FormFieldTypes } from 'components/shared/forms/formTypes';
import { setAuthenticatedUserLocales } from 'pages/auth/login/loginHelpers';
import userStorage from 'pages/auth/login/UserStorage';
import { fetchGamificationPlayer } from 'pages/captainUp/features/UserInfo/slice/gamificationPlayer.slice';
import { selectAccountTabs, setMPDSectionActiveTab } from 'pages/myAccount/slice/mpdAccountTabs.slice';
import { setLimitsDeclarationNeed, setPlayerLimits } from 'pages/myAccount/tabs/limits/slice/limits.slice';
import { PlayerRestrictions } from 'pages/myAccount/tabs/responsibleGambling/types/responsibleGambling.types';
import { currencyFormat } from 'pages/myAccount/types/myAccount.types';
import { loginUserChat } from 'shared/common/chat/utils';
import { setDeclarations } from 'shared/common/features/interceptors/slice/interceptors.slice';
import { hideRootModal, showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { WS } from 'shared/common/features/websockets/types/webSockets.types';
import { CustomPlatformEvents } from 'shared/common/hooks/useHandleCustomEvents';
import {
  hardwareBackPress,
  logoutOnError,
  logoutUser,
  setCurrentUser,
  setLanguage,
  setPlayerRestrictions,
  setUserBalance,
} from 'shared/common/sharedSlices/commonActions';
import { AppDispatch } from 'store';
import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { defaultOddsFormat } from 'utils/common/constants';
import { getBusinessUnit, getError, isEmpty } from 'utils/common/helpersCommon';
import sendPlayerToRNWebView from 'utils/common/pushNotificationsHelper';
import config from 'utils/config';
import { setAppBackdrop } from '../../appBackdrop/slice/appBackdrop.slice';
import { fetchBusinessUnitSettings, selectGeneral } from '../../general/slice/general.slice';
import { LayoutConfigTypes, VerticalsConfigTypes } from '../../general/types/generalSlice.types';
import { setUpdated } from '../../localization/slice/localization.slice';
import { ModalPriorityEnum } from '../../rootModal/types/modal.types';
import { wsActions } from '../../websockets/webSocketActions';
import {
  Balance,
  BalanceType,
  CampaignBonusCollection,
  MyProfileInitialState,
  PlayersNotification,
  PreferredTheme,
  User,
} from '../types/myProfile.types';
import { getDefaultBusinessUnitThemeSkin, getPreferredThemeSkin, parseCampaign } from '../utils/myProfile.utils';

export const fetchBlockedBalance = createAbortThunk<number, void>(
  'myProfile/fetchBlockedBalance',
  async (_, { source, getState }) => {
    const playerId: string | undefined = selectMyProfile.playerId(getState());

    const response = await axiosInstance.get(
      `${config.API_URL}/api/payment/player/cashier/requested-withdrawals-sum?playerId=${playerId}`,
      { cancelToken: source.token },
    );
    return response.data;
  },
);

export const myProfileThunks = {
  fetchBalance: createAbortThunk<Balance, void>('myProfile/fetchBalance', async (_, { source, getState, dispatch }) => {
    const response = await axiosInstance.get(`${config.API_URL}/api/accounting/common/player/accounts/summary`, {
      cancelToken: source.token,
    });
    const balanceMap = selectGeneral.moduleLayout('platform', 'general', 'myProfile')(getState())?.balanceMap;

    if (!isEmpty(balanceMap)) {
      if (Object.values(balanceMap)?.some((arr) => arr.includes('blockedBalance'))) {
        dispatch(fetchBlockedBalance());
      }
    }
    return response.data;
  }),
  updatePreferredTheme: createAbortThunk<{ preferredTheme: PreferredTheme }, { preferredTheme: PreferredTheme }>(
    'myProfile/updatePreferredTheme',
    async ({ preferredTheme }, { source }) => {
      await axiosInstance.put(
        `${config.API_URL}/api/ews-crm/player/players-settings/update-preferred-theme?preferredTheme=${preferredTheme}`,
        {
          cancelToken: source.token,
        },
      );
      const responseData = {
        preferredTheme,
      };
      userStorage.setPreferredThemeSkin(preferredTheme);
      return responseData;
    },
  ),
  fetchMigrateUsernameForm: createAbortThunk<FormFieldTypes[], undefined, string>(
    'myProfile/fetchMigrateUsernameForm',
    async (_, { source }) => {
      const response = await axiosInstance.get<FormFieldTypes[]>(
        `${config.API_URL}/api/ews-crm/public/cms/player-content?key=player_migration_change_username`,
        {
          headers: { 'X-Platform-Origin': getBusinessUnit() },
          cancelToken: source.token,
        },
      );
      return response.data;
    },
  ),
  migrateUsername: createAbortThunk<boolean, { inputValue: string }, string | null>(
    'myProfile/migrateUsername',
    async ({ inputValue }, { dispatch }) => {
      await axiosInstance.post(
        `${config.API_URL}/api/ews-crm/player/players/username`,
        {
          username: inputValue,
        },
        {
          headers: { 'X-Platform-Origin': getBusinessUnit() },
        },
      );
      dispatch(getPlayerData());
      return true;
    },
  ),
  deleteUserAccount: createAbortThunk<boolean, boolean, string | null>(
    'myProfile/deleteUserAccount',
    async (hasDeleteRequest, { dispatch }) => {
      const requestType = hasDeleteRequest ? 'put' : 'post';
      await axiosInstance[requestType](
        `${config.API_URL}/api/ews-crm/player/player-cancellation${hasDeleteRequest ? '/cancel' : ''}`,
      );
      dispatch(getPlayerData());
      dispatch(hideRootModal());
      return true;
    },
  ),
  checkPassword: createAbortThunk<boolean, { inputValue: string; error: string }, string>(
    'myProfile/checkPassword',
    async ({ inputValue, error }, { rejectWithValue }) => {
      const result = await axiosInstance.get<string>(
        `${config.API_URL}/api/ews-crm/player/users/check-password?playerPassword=${encodeURIComponent(inputValue)}`,
      );
      const passwordResult = result.data === 'true';
      if (!passwordResult) {
        return rejectWithValue(error);
      }
      return passwordResult;
    },
  ),

  fetchBonusCollectionCampaign: createAbortThunk<string, { url: string }>(
    'myProfile/campaignBonusCollection',
    async ({ url }, { source, getState }) => {
      const playerId: string | undefined = selectMyProfile.playerId(getState());

      const response = await axiosInstance.get(`${config.API_URL}${url}${playerId ? `&playerId=${playerId}` : ''}`, {
        cancelToken: source.token,
      });

      return response.data;
    },
  ),

  fetchMyBeneficientDetails: createAbortThunk('myProfile/fetchMyBeneficientDetails', async (_, { rejectWithValue }) => {
    try {
      const url = `${config.API_URL}/api/ews-crm/player/players/beneficient`;
      const response = await axiosInstance.get(url);

      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseData(err));
    }
  }),

  getPlayerSportPreferences: createAbortThunk<void, undefined>(
    'myProfile/getPlayerSportPreferences',
    async (_, { source, dispatch }) => {
      const response = await axiosInstance.get(
        `${config.API_URL}/api/user-favorites/players-sport-preferences/player`,
        {
          cancelToken: source.token,
        },
      );
      dispatch(setPreferredOddTypeId(response.data.oddTypeId));
    },
  ),
};

const {
  fetchBalance,
  updatePreferredTheme,
  migrateUsername,
  fetchMigrateUsernameForm,
  deleteUserAccount,
  checkPassword,
  fetchBonusCollectionCampaign,
} = myProfileThunks;

const formFieldsAdapter = createEntityAdapter<FormFieldTypes, EntityId>({
  selectId: (field) => (field.type === 'label' ? field.name : field.type),
});

const initialState: MyProfileInitialState = {
  isModalShowing: false,
  showModalLock: false,
  user: null,
  error: null,
  balance: null,
  balanceVisibility: userStorage.getBalanceVisibility(),
  preferredTheme: getPreferredThemeSkin(),
  migrateUsername: { formFields: formFieldsAdapter.getInitialState() },
  errors: {},
  campaignBonuses: null,
  beneficientDetails: null,
  beneficientIsCreated: false,
  preferredOddTypeId: defaultOddsFormat,
};

const myProfile = createSlice({
  name: 'myProfile',
  initialState,
  reducers: {
    showMyProfileModal(state, action) {
      state.isModalShowing = action.payload;
      if (!action.payload) {
        state.showModalLock = false;
      }
    },
    keepMyProfileModalShown(state, action: PayloadAction<boolean>) {
      state.showModalLock = action.payload;
    },
    updateNotificationType(state, action: PayloadAction<{ notification: PlayersNotification & { active: boolean } }>) {
      const { playerNotificationTypeId, active } = action.payload.notification;

      if (active) {
        const updatedNotification = {
          playerNotificationTypeId,
        };
        state.user?.playersNotifications?.push(updatedNotification);
      } else {
        const updatedNotification = state.user?.playersNotifications?.filter(
          (x) => x.playerNotificationTypeId !== playerNotificationTypeId,
        );
        state.user?.playersNotifications &&
          updatedNotification &&
          (state.user.playersNotifications = updatedNotification);
      }
    },
    setBalanceVisibility(state) {
      state.balanceVisibility = !state.balanceVisibility;
    },
    setPreferredTheme(state, action: PayloadAction<PreferredTheme>) {
      state.preferredTheme = action.payload;
    },
    setPreferredOddTypeId(state, action: PayloadAction<number>) {
      state.preferredOddTypeId = action.payload;
      userStorage.setUserOddsId(action.payload);
    },
    clearMigrateUsernameError(state) {
      state.migrateUsername.error = null;
    },
    clearDeleteAccountError(state) {
      state.errors.deleteAccount = null;
    },
    clearPasswordCheckError(state) {
      state.errors.passwordCheck = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(myProfileThunks.fetchMyBeneficientDetails.fulfilled, (state, action) => {
        state.beneficientDetails = action.payload;
        state.beneficientIsCreated = true;
      })
      .addCase(myProfileThunks.fetchMyBeneficientDetails.rejected, (state) => {
        state.beneficientIsCreated = false;
      });
    builder
      .addCase(logoutUser, (state) => {
        state.preferredTheme = getDefaultBusinessUnitThemeSkin();
        state.user = null;
        state.balance = null;
        state.balanceVisibility = true;
        state.beneficientDetails = null;
        state.beneficientIsCreated = false;
        if (!state.showModalLock) {
          state.isModalShowing = false;
          state.showModalLock = false;
        }
      })
      .addCase(setCurrentUser, (state, action: PayloadAction<User>) => {
        state.user = action.payload;
      })
      .addCase(setPlayerRestrictions, (state, action: PayloadAction<PlayerRestrictions.Restriction[]>) => {
        if (state.user) {
          state.user.playerRestrictions = action.payload;
        }
      })
      .addCase(wsActions.wsPlayerLimits, (state, action: PayloadAction<WS.Limits>) => {
        if (state.user) {
          state.user.playerLimits = action.payload.playerLimits;
        }
      })
      .addCase(
        fetchBalance.fulfilled,
        (
          state,
          action: PayloadAction<Balance, string, { arg: void; requestId: string; requestStatus: 'fulfilled' }, never>,
        ) => {
          state.balance = { ...(state.balance ?? {}), ...action.payload };
        },
      )
      .addCase(fetchBlockedBalance.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          state.balance = { ...(state.balance ?? ({} as Balance)), blockedBalance: action.payload };
        }
      })
      .addCase(updatePreferredTheme.fulfilled, (state, action: PayloadAction<{ preferredTheme: PreferredTheme }>) => {
        const { preferredTheme } = action.payload;
        state.preferredTheme = preferredTheme;
      })
      .addCase(fetchMigrateUsernameForm.fulfilled, (state, action: PayloadAction<FormFieldTypes[]>) => {
        formFieldsAdapter.setAll(state.migrateUsername.formFields, action.payload);
      })
      .addCase(migrateUsername.rejected, (state, action) => {
        state.migrateUsername.error = action.payload;
      })
      .addCase(deleteUserAccount.rejected, (state, action) => {
        state.errors.deleteAccount = action.payload;
      })
      .addCase(setUserBalance, (state, action: PayloadAction<Balance | null>) => {
        state.balance = { ...(state.balance ?? ({} as Balance)), ...action.payload };
      })
      .addCase(hardwareBackPress, (state) => {
        if (state.isModalShowing) {
          state.isModalShowing = false;
          state.showModalLock = false;
        }
      });
    builder.addCase(
      checkPassword.rejected,
      (state, action: PayloadAction<string | { rejectValue: string } | null | undefined>) => {
        state.errors.passwordCheck = action.payload as string;
      },
    );
    builder.addCase(fetchBonusCollectionCampaign.fulfilled, (state, action) => {
      const campaign = parseCampaign(action.payload);

      const item = {
        folder: campaign.folder,
        completion: campaign.externalBonusData?.completion,
      };
      if (!state.campaignBonuses?.bonusCollection) {
        state.campaignBonuses = { ...state.campaignBonuses, bonusCollection: {} };
      }
      if (!campaign.externalBonusData?.completion) {
        state.campaignBonuses.bonusCollection = undefined;
        return;
      }
      state.campaignBonuses.bonusCollection = item;
    });
  },
});

export const {
  keepMyProfileModalShown,
  updateNotificationType,
  setBalanceVisibility,
  setPreferredTheme,
  clearMigrateUsernameError,
  clearDeleteAccountError,
  clearPasswordCheckError,
  setPreferredOddTypeId,
} = myProfile.actions;

export default myProfile.reducer;

export const selectMyProfile = {
  playerId: (state: RootState): string | undefined => state.myProfile.user?.id,
  dataUser: (state: RootState): User | null => state.myProfile.user,
  username: (state: RootState): string | undefined => state.myProfile.user?.username,
  userTwoNames: (state: RootState): string | undefined =>
    `${state.myProfile.user?.firstName} ${state.myProfile.user?.lastName}`,
  showModalLock: (state: RootState): boolean => state.myProfile.showModalLock,
  balance: (state: RootState): Balance | null | undefined => state.myProfile.balance,
  balanceByType:
    (type: BalanceType) =>
    (state: RootState): number | undefined =>
      state.myProfile.balance?.[type],
  languageId: (state: RootState): number | undefined => state.myProfile.user?.languageId,
  language: (state: RootState): string | undefined => state.myProfile.user?.language?.language,
  countryCodeIso: (state: RootState) => state.myProfile.user?.country?.codeIso,
  preferredOddTypeId: (state: RootState): number => state.myProfile.preferredOddTypeId,
  isMfaUserEnabled: (state: RootState) => state.myProfile.user?.isMfaEnabled,
  mfaChannel: (state: RootState) => state.myProfile.user?.mfaChannel,
  ianaTimezoneCode: (state: RootState) => state.myProfile.user?.ianaTimezone,
  notifications: (state: RootState): PlayersNotification[] | undefined => state.myProfile?.user?.playersNotifications,
  currency: (state: RootState): currencyFormat | undefined => state.myProfile?.user?.currency,
  currencyName: (state: RootState): string | undefined => state.myProfile?.user?.currency?.name,
  currencySymbol: (state: RootState): string | undefined => state.myProfile?.user?.currency?.symbol,
  isRequestingCancellation: (state: RootState): boolean | undefined => state.myProfile.user?.isRequestingCancellation,
  socialMediaAccounts: (state: RootState): SocialTypes[] | undefined => state.myProfile.user?.socialMediaAccounts,
  balanceVisibility: (state: RootState): boolean => state.myProfile.balanceVisibility,
  isModalShowing: (state: RootState): boolean => state.myProfile.isModalShowing,
  migrateUsernameError: (state: RootState): string | null | undefined => state.myProfile.migrateUsername?.error,
  migrateUsernameForm: formFieldsAdapter.getSelectors((state: RootState) => state.myProfile.migrateUsername.formFields),
  deleteAccountError: (state: RootState): string | null | undefined => state.myProfile.errors.deleteAccount,
  preferredTheme: (state: RootState): PreferredTheme => state.myProfile.preferredTheme,
  passwordCheckError: (state: RootState): string | null | undefined => state.myProfile.errors.passwordCheck,
  email: (state: RootState): string | undefined => state.myProfile.user?.email,
  phoneNumber: (state: RootState): string | undefined => state.myProfile.user?.phoneNumber,
  isPhoneVerified: (state: RootState) => state.myProfile?.user?.isPhoneVerified,
  hasTermsAndConditionsToAccept: (state: RootState) =>
    state.myProfile?.user?.termsAndConditionsToAccept && state.myProfile?.user?.termsAndConditionsToAccept.length,
  zendeskDepartment: (state: RootState) => state.myProfile.user?.rank?.department,
  documentVerificationStatus: (state: RootState) => state.myProfile.user?.documentVerificationStatus,
  getBonusCollections: (state: RootState): CampaignBonusCollection | undefined =>
    state.myProfile.campaignBonuses?.bonusCollection,
  hasBeneficiery: (state: RootState) => state.myProfile.user?.additionalDetails?.hasBeneficiery,
  beneficientDetails: (state: RootState) => state.myProfile.beneficientDetails,
  noBeneficient: (state: RootState) =>
    state.myProfile.beneficientIsCreated === false || state.myProfile?.beneficientDetails?.beneficientId === undefined,
  beneficientId: (state: RootState) => state.myProfile?.beneficientDetails?.beneficientId,
};

export const getPlayerDetails = async (): Promise<{ data: User } | undefined> => {
  try {
    const playerDetailsResponse = await axiosInstance.get(`${config.API_URL}/api/ews-crm/player/players`);
    return playerDetailsResponse;
  } catch (error) {
    throw new Error(getError.message(error) || "Get player's details request failed.");
  }
};

export const getPlayerData =
  (showRestrictions?: boolean) =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<{ data: User } | undefined> => {
    try {
      const noBuSettings = selectGeneral.noBuSettings(getState());
      if (noBuSettings) {
        const action = await dispatch(fetchBusinessUnitSettings());
        // If bu settings request failed when user is logged in, then log out user.
        if (action.meta.requestStatus === 'rejected') {
          dispatch(logoutOnError());
          return;
        }
      }
      const playerDetails = await getPlayerDetails();
      if (playerDetails?.data) {
        if (selectGeneral.confirmPhone(getState()) && playerDetails?.data?.isPhoneVerified === false) {
          document.dispatchEvent(new Event(CustomPlatformEvents.HIGH_PRIORITY_MODAL));
          dispatch(
            showRootModal({
              modalType: 'PHONE_CONFIRMATION',
              modalPriority: ModalPriorityEnum.GRAND,
              modalId: 'PHONE_CONFIRMATION_LOGGED_IN',
              modalProps: { type: 'player', location: 'logged-in' },
            }),
          );
        }
        if (playerDetails?.data.termsAndConditionsToAccept && playerDetails?.data.termsAndConditionsToAccept.length) {
          playerDetails.data.termsAndConditionsToAccept.forEach((termsAndConditions) => {
            dispatch(
              showRootModal({
                modalType: 'TERMS_AND_CONDITIONS',
                modalProps: {
                  alias: termsAndConditions.alias,
                  version: termsAndConditions.version,
                  subVersion: termsAndConditions.subVersion,
                },
                modalPriority: ModalPriorityEnum.GRAND,
                modalId: `TERMS_AND_CONDITIONS_ID_${termsAndConditions.alias}_${termsAndConditions.version}`,
              }),
            );
          });
        }
        dispatch(setCurrentUser(playerDetails.data));
        if (playerDetails.data.additionalDetails?.hasBeneficiery) {
          dispatch(myProfileThunks.fetchMyBeneficientDetails());
        }
        if (isEmpty(playerDetails.data.username)) {
          //prefetch migration form
          dispatch(myProfileThunks.fetchMigrateUsernameForm());
        }

        // Get gamification profile by layout configuration.
        const gamificationConfig =
          getState().common.general?.layoutConfig?.['platform']?.['general']?.[LayoutConfigTypes.VERTICALS]?.[
            VerticalsConfigTypes.GAMIFICATION
          ];
        if (gamificationConfig?.isVisible) {
          dispatch(fetchGamificationPlayer());
        }

        dispatch(setPlayerRestrictions(playerDetails.data?.playerRestrictions));
        const activeRestrictions = playerDetails.data?.playerRestrictions.filter(
          (r) => r.restrictionStatus === 'active' || !r.restrictionStatus,
        );
        showRestrictions &&
          !isEmpty(activeRestrictions) &&
          dispatch(
            showRootModal({
              modalType: 'PLAYER_RESTRICTIONS_INFO',
              modalProps: { playerRestrictionsByProducts: activeRestrictions },
              modalPriority: ModalPriorityEnum.MEDIUM,
              modalId: 'PLAYER_RESTRICTIONS_INFO_LOGIN',
            }),
          );
        dispatch(setLimitsDeclarationNeed(playerDetails.data?.needDeclaration || null));
        dispatch(setPlayerLimits(playerDetails.data?.playerLimits));

        if (window['LC_API'] || window['$zopim'] || window['zE']) {
          loginUserChat(playerDetails.data);
        }

        const { language, offsetMinutes, currency, preferredTheme, ianaTimezone } = playerDetails.data;
        if (language) {
          const userLanguage = language?.language;
          const storeLanguage = getState().common?.settings?.language;
          userLanguage !== storeLanguage && dispatch(setLanguage(userLanguage));
          setAuthenticatedUserLocales(userLanguage);
        }
        if (offsetMinutes !== undefined) {
          const storeTzOffset = userStorage.getUserTimeZone();
          if (Number(storeTzOffset) !== Number(offsetMinutes)) {
            userStorage.setUserTimeZone(offsetMinutes);
            dispatch(setUpdated(false));
          }
        }
        if (ianaTimezone) {
          const storeIanaTimezone = userStorage.getUserIanaTimeZoneCode();
          if (storeIanaTimezone !== ianaTimezone) {
            userStorage.setUserIanaTimeZoneCode(ianaTimezone);
          }
        }
        if (currency?.name) {
          userStorage.setUserCurrency(currency?.name);
        }
        if (preferredTheme) {
          dispatch(setPreferredTheme(preferredTheme));
          userStorage.setPreferredThemeSkin(preferredTheme);
        }

        if (window.isRNWebView) {
          sendPlayerToRNWebView(
            'login',
            `${config.API_URL}/api/ews-crm/player/player-devices/create`,
            playerDetails.data.id,
          );
        }

        const { isPendingDeclaration, pendingDeclarations } = playerDetails.data;
        if (isPendingDeclaration) {
          dispatch(setDeclarations(pendingDeclarations));
        }

        const isMultipleAccountTrackingEnabled = selectGeneral.isMultipleAccountTrackingEnabled(getState());
        const { id: playerId } = playerDetails.data;
        if (isMultipleAccountTrackingEnabled && playerId) {
          userStorage.setMultiAccountDataKey();
          userStorage.setMultiAccountDataValue(playerId);
        }
      }
      return playerDetails;
    } catch (err) {
      dispatch(logoutOnError());
    }
  };

export const showMyProfileModal =
  (value: boolean) =>
  (dispatch: AppDispatch, getState: () => RootState): void => {
    const locked = selectMyProfile.showModalLock(getState());
    const mpdAccountTabs = selectAccountTabs.mpdAccountTabs(getState());

    if (!locked) {
      dispatch(myProfile.actions.showMyProfileModal(value));
      dispatch(setAppBackdrop(value));
      dispatch(setMPDSectionActiveTab({ tabKey: mpdAccountTabs?.[0]?.key }));
      if (!value) {
        // dispatch(resetOffersTab());
      }
    }
  };

export const updateOddTypeId =
  () =>
  (dispatch: AppDispatch): void => {
    const localStorageOddTypeId = userStorage.getUserOddsId();
    if (localStorageOddTypeId) {
      dispatch(myProfile.actions.setPreferredOddTypeId(Number(localStorageOddTypeId)));
    }
  };
