import React from 'react';
import ParseHTML from 'shared/common/ParseHTML';
import { prizeDropCSSNS } from '../prizeDropUICSS.types';

type Props = {
  text: string;
};

const PrizeDropHTML: React.FC<Props> = ({ text }) => {
  return (
    <div className={`${prizeDropCSSNS}-color--primary`}>
      <ParseHTML html={text || ''} options={{ trim: false }} />
    </div>
  );
};

export default PrizeDropHTML;
