import { useLocation } from 'react-router-dom';

const useExtractAgentFromUrl = (): string => {
  const search = useLocation().search;
  const agent = new URLSearchParams(search).get('agent');

  return agent || '';
};

export default useExtractAgentFromUrl;
