import { categoryThunks } from 'modules/casino/modules/container/cellsContainer/containers/inner/slice/casinoCategorySlice';
import { casinoWidgetThunks } from 'modules/casino/modules/container/widget/slice/casinoWidgetsSlice';
import { homePageThunks } from 'pages/homePage/slice/homePage.slice';

export type LoaderName = 'homePage';
type ActionLoaderNameMap = Record<string, LoaderName>;

export const loaderIncrementors: ActionLoaderNameMap = {
  [homePageThunks.fetchContainer.pending.type]: 'homePage',
  [categoryThunks.fetchCategory.pending.type]: 'homePage',
  [casinoWidgetThunks.fetchLastWinners.pending.type]: 'homePage',
};

export const loaderDecrementors: ActionLoaderNameMap = {
  [homePageThunks.fetchContainer.rejected.type]: 'homePage',
  [categoryThunks.fetchCategory.rejected.type]: 'homePage',
  [casinoWidgetThunks.fetchLastWinners.rejected.type]: 'homePage',

  [homePageThunks.fetchContainer.fulfilled.type]: 'homePage',
  [categoryThunks.fetchCategory.fulfilled.type]: 'homePage',
  [casinoWidgetThunks.fetchLastWinners.fulfilled.type]: 'homePage',
};

export const loaderResetors: ActionLoaderNameMap = {};

export const isMatchWithIncrementAction = (action: { type: string }): boolean => !!loaderIncrementors[action.type];
export const isMatchWithDecrementAction = (action: { type: string }): boolean => !!loaderDecrementors[action.type];
export const isMatchWithResetAction = (action: { type: string }): boolean => !!loaderResetors[action.type];
