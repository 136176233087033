import { useCallback } from 'react';
import { useNavigateTo } from 'hooks/navigation';
import { useAppDispatch } from 'store';
import { navigateToIntegration } from '../../integrations/utils/integrations.utils';
import { setDrawer } from '../slice/drawer.slice';
import { SportsType } from '../types/drawer.types';

const useHandleDrawerSportClick = () => {
  const dispatch = useAppDispatch();
  const navigateTo = useNavigateTo();

  const handleSportClick = useCallback(
    ({ sportType, sportId, fullPath }: { sportType?: SportsType; sportId: number; fullPath?: string }) => {
      dispatch(setDrawer(false));
      switch (sportType) {
        case SportsType.LiveSport:
          navigateToIntegration({ page: 'sportsInPlay', params: { sportId } });
          navigateTo.sportsInPlay({ sportId });
          break;
        case SportsType.Sport:
          navigateToIntegration({ page: 'sportTypeLobby', params: { sportId } });
          navigateTo.sportTypeLobby({ sportId });
          break;
        case SportsType.ESports:
          navigateToIntegration({ page: 'eSportTypeLobby', params: { sportId } });
          navigateTo.eSportTypeLobby({ sportId });
          break;
        case SportsType.Virtuals:
          if (!fullPath) return;
          navigateTo.navigate(fullPath);
          navigateToIntegration({ fullPath });
          break;
        default:
          return;
      }
    },
    [dispatch, navigateTo],
  );

  return { handleSportClick };
};

export default useHandleDrawerSportClick;
