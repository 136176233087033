import { isMobile } from 'react-device-detect';
import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';

export const cashierActionButtons = (translation) => {
  return [
    {
      id: 1,
      text: translation(CashierTranslations.ActionButton.cancelTicket),
      icon: 'cancel-ticket',
      hasCounter: false,
      visible: true,
    },
    {
      id: 2,
      text: translation(CashierTranslations.ActionButton.cashoutButton),
      icon: 'cashier-cashout',
      hasCounter: false,
      visible: true,
    },
    {
      id: 3,
      text: translation(CashierTranslations.ActionButton.payTicketButton),
      icon: 'pay-ticket',
      hasCounter: true,
      visible: isMobile ? false : true,
    },
    {
      id: 4,
      text: translation(CashierTranslations.ActionButton.duplicateTicket),
      icon: 'cashier-cashout',
      hasCounter: false,
      secondIcon: 'pay-ticket',
      visible: isMobile ? false : true,
    },
  ];
};

export enum actionButtonEnums {
  cancelTicket = 1,
  cashoutButton = 2,
  payTicketButton = 3,
  duplicateTicket = 4,
}

export const voucherActionButtons = (t) => {
  return [
    {
      id: 1,
      text: t('Cashier.VoucherModal.cancelVoucher'),
      icon: 'cancel-ticket',
      hasCounter: true,
    },
    {
      id: 2,
      text: t('Cashier.VoucherModal.payoutVoucher'),
      icon: 'cashier-cashout',
      hasCounter: true,
    },
  ];
};
export enum actionButtonVoucherEnums {
  cancelVoucher = 1,
  payOutVoucher = 2,
}
