import { useInView } from 'react-intersection-observer';
import { useCategoryImpressions } from 'modules/casino/modules/container/cellsContainer/containers/shared/features/categoryImpressions/hooks/useCategoryImpressions';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';

type Props = {
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
  isTrackable: boolean;
};

const CategoryImpressions: React.FC<Props> = ({ categoryId, categoryLayoutType, isTrackable }) => {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '0px',
  });
  useCategoryImpressions({ categoryId, categoryLayoutType, isCategoryinView: inView });

  return isTrackable ? <div ref={inViewRef} /> : null;
};

export default CategoryImpressions;
