import React from 'react';
import { useTranslation } from 'react-i18next';
import { HighlightFeatureTranslate } from 'utils/common/translationUtils/translationStrings';
import { cssGlobalNS } from 'utils/common/types/css.types';
import { NextFeaturesUI } from './StyleComponents';

type NextFeaturesType = {
  handleNext: () => void;
  isLast: boolean;
  activeComponent: string;
  items: object;
};

const NextFeatures = ({ handleNext, isLast, activeComponent, items }: NextFeaturesType) => {
  const { t } = useTranslation();

  return (
    <NextFeaturesUI.Wrapper className={`${cssGlobalNS}-highlight-feature__actions d-flex-ae`}>
      {Object.keys(items).map((item) => (
        <NextFeaturesUI.Inner
          className={`d-flex-col-center w-100 ${activeComponent === item ? 'active' : ''}`}
          key={item}
        >
          <NextFeaturesUI.Text className={`${cssGlobalNS}-highlight-feature__text`}>
            {t(HighlightFeatureTranslate.Features[items[item].text])}
          </NextFeaturesUI.Text>
          <NextFeaturesUI.Button className={`${cssGlobalNS}-highlight-feature__button`} onClick={handleNext}>
            {!isLast ? t(HighlightFeatureTranslate.Button.next) : t(HighlightFeatureTranslate.Button.finish)}
          </NextFeaturesUI.Button>
        </NextFeaturesUI.Inner>
      ))}
    </NextFeaturesUI.Wrapper>
  );
};

export default NextFeatures;
