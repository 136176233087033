import React from 'react';
import clsx from 'clsx';

/** Footer Section Title */
interface SectionTitleProps {
  /** Section title text */
  text: string;
  /** Is the title secondary? */
  isSecondary?: boolean;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ text, isSecondary }) => {
  const textClass = clsx('text-center footer-title', `footer-title--${isSecondary ? 'secondary' : 'primary'}`);
  return <div className={textClass}>{text}</div>;
};

export default SectionTitle;
