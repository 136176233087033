import { cssGlobalNS } from 'utils/common/types/css.types';

export const cssNS = `${cssGlobalNS}-burger`;

export const cssBurgerNS = {
  Wrapper: `${cssNS}-wrapper`,
  Close: `${cssNS}-close`,
  Section: `${cssNS}-section`,
  Separator: `${cssNS}-separator`,
  Header: `${cssNS}-header`,
  NavItem: `${cssNS}-nav`,
  LinkItem: `${cssNS}-link`,
  Retail: `${cssNS}-retail`,
  Tile: `${cssNS}-tile`,
  TileIcon: `${cssNS}-tile__icon`,
  TileIsActive: `${cssNS}-tile--active`,
  List: `${cssNS}-list`,
  SportTile: `${cssNS}-sport-tile`,
  SportText: `${cssNS}-tile__sport-text`,
  CasinoTile: `${cssNS}-casino-tile`,
  SettingsWrap: `${cssNS}-settings`,
};

export const gridStates = { visible: { x: 0, transition: { staggerChildren: 0.1 } }, exit: { x: '-100%' } };

export const tileAnimation = {
  hidden: { scale: 0.2, opacity: 0 },
  visible: {
    scale: 1,
    opacity: 1,
  },
};
