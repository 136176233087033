export const fromRight = {
  initial: { x: '120%' },
  animate: {
    x: 0,
    transition: {
      duration: 0.5,
      ease: [0.49, 0, 0.47, 1],
    },
  },
  exit: {
    x: '120%',
    transition: {
      duration: 0.4,
      ease: [0.49, 0, 0.47, 1],
    },
  },
};

export const fromBottom = {
  initial: { y: '120%' },
  animate: {
    y: 0,
    transition: {
      type: 'spring',
      duration: 0.4,
      damping: 29,
      mass: 1,
      stiffness: 325,
    },
  },
  exit: {
    y: '120%',
    transition: {
      type: 'spring',
      duration: 0.4,
      damping: 29,
      mass: 1,
      stiffness: 325,
    },
  },
};

export const fromLeft = {
  initial: { x: '-120%' },
  animate: {
    x: 0,
    transition: {
      type: 'spring',
      duration: 0.4,
      damping: 29,
      mass: 1,
      stiffness: 325,
    },
  },
  exit: {
    x: '-120%',
    transition: {
      type: 'spring',
      duration: 0.4,
      damping: 29,
      mass: 1,
      stiffness: 325,
    },
  },
};
