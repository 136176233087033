import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Flex } from 'components/shared/layout';
import { Modal } from 'components/shared/Modal';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch } from 'store';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import RankingsAwards from './RankingsAwards';
import { PromoUI } from '../components/PromoUI';
import { PromotionTypes } from '../types/promotions.types';

type Props = {
  awards: PromotionTypes.AwardPlaces[];
  awardDescription: string;
  awardsPeriods: string;
  title?: string;
};

const PromoAwardsModal: React.FC<Props> = ({ awards, awardDescription, awardsPeriods, title }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isTablet = useMediaQuery({ query: `(min-width: 576px)` });

  return (
    <Modal visible className="modal-terms" size="xl">
      <Modal.Close closeText={t(Promotions.Common.closeBtn)} closeAction={() => dispatch(hideRootModal())} />
      <Modal.Body className={`custom-scrollbars ${isTablet ? 'p-4' : 'p-2'} prm-page__content`} padding="lg">
        <PromoUI.PageBoxTitle title={title} />
        <Flex.Col>
          <Flex.JB>
            <PromoUI.RankingBox isTitleCell text={awardDescription || ''} />
            <PromoUI.RankingBox isTitleCell text={awardsPeriods || ''} />
          </Flex.JB>
          <RankingsAwards awards={awards} />
        </Flex.Col>
      </Modal.Body>
    </Modal>
  );
};

export default PromoAwardsModal;
