import { useMemo } from 'react';
import { gameProvidersSelectors } from 'modules/casino/modules/container/shared/features/casinoGameProviders/slice/casinoGameProvidersSlice';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { casinoLobbySelectors } from 'modules/casino/modules/lobby/containersLobby/slice/casinoLobbySlice';
import { providersCategorySelectors } from 'modules/casino/modules/lobby/providerLobby/slice/providersCategorySlice';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { selectLanguage } from 'shared/common/features/settings/slice/settings.slice';
import { useAppSelector } from 'store';
import useGeneralCasinoType from './useGeneralCasinoType';
import { Casino } from '../types';

export const useGetCategoryPosition = (
  categoryId: string,
  categoryLayoutType: CategoryLayoutTypes,
): Casino.CategoryPosition => {
  const { casinoType } = useGeneralCasinoType();
  const language = useAppSelector(selectLanguage);
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const activeProviderAlias: string = useAppSelector(gameProvidersSelectors.activeProvider);

  const keyMap = useMemo(() => {
    return {
      [CategoryLayoutTypes.LOBBY]: `${language}_${casinoType}`,
      [CategoryLayoutTypes.PROVIDER_CATEGORY]: `${language}_${casinoType}_${activeProviderAlias}`,
    };
  }, [language, casinoType, activeProviderAlias]);

  const selectorMap = useMemo(() => {
    return {
      [CategoryLayoutTypes.LOBBY]: (state, key, authenticated) =>
        casinoLobbySelectors.lobbyCategoryIds(state, key, authenticated),
      [CategoryLayoutTypes.PROVIDER_CATEGORY]: (state, key, authenticated) =>
        providersCategorySelectors.providerCategoryIds(state, key, authenticated),
    };
  }, []);

  const categoryIds: string[] = useAppSelector(
    (state) =>
      selectorMap[categoryLayoutType] &&
      selectorMap[categoryLayoutType](state, keyMap[categoryLayoutType], isAuthenticated),
  );

  return {
    isFirst: categoryIds && categoryId === categoryIds[0],
    isLast: categoryIds && categoryId === categoryIds[categoryIds?.length - 1],
    categoryPosition: categoryIds && categoryIds.indexOf(categoryId),
  };
};
