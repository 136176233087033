import { useEffect, useRef, useState } from 'react';
import enquire from 'enquire.js';
import json2mq from 'json2mq';
import defaultProps from '../defaultProps';

const useZASliderInitialSetup = (settings) => {
  const [currentSettings, setCurrentSettings] = useState(settings);
  const responsiveMediaHandlers = useRef<Array<{ query: string; handler: () => void }>>([]);
  const mathMediaQuery = (query, handler) => {
    // javascript handler for  css media query
    enquire.register(query, handler);
    responsiveMediaHandlers.current.push({ query, handler });
  };

  const setUpdatedSettings = (breakpoint) => {
    let selectedSettings;
    let newProps;
    if (breakpoint) {
      newProps = settings.responsive.filter((resp) => resp.breakpoint === breakpoint);
      selectedSettings = newProps[0].settings = { ...defaultProps, ...settings, ...newProps[0].settings };
    } else {
      selectedSettings = { ...defaultProps, ...settings };
    }
    setCurrentSettings(selectedSettings);
  };

  useEffect(() => {
    if (settings.responsive) {
      const breakpoints = settings.responsive.map(function (breakpt) {
        return breakpt.breakpoint;
      }); // sort them in increasing order of their numerical value

      breakpoints.sort(function (x, y) {
        return x - y;
      });

      breakpoints.forEach(function (breakpoint, index) {
        // media query for each breakpoint
        let bQuery;

        if (index === 0) {
          bQuery = json2mq({
            minWidth: 0,
            maxWidth: breakpoint,
          });
        } else {
          bQuery = json2mq({
            minWidth: breakpoints[index - 1] + 1,
            maxWidth: breakpoint,
          });
        }
        mathMediaQuery(bQuery, function () {
          setUpdatedSettings(breakpoint);
        });
      });

      const query = json2mq({ minWidth: breakpoints.slice(-1)[0] });
      mathMediaQuery(query, function () {
        setUpdatedSettings(null);
      });
    }

    return () => {
      responsiveMediaHandlers.current.forEach(function (obj) {
        enquire.unregister(obj.query, obj.handler);
      });
    };
  }, []);

  return currentSettings;
};

export default useZASliderInitialSetup;
