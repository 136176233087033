import React, { memo } from 'react';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { BadgeVariants } from './badgeTypes';
import { BadgeLayout_1, BadgeLayout_2 } from './layout';

interface Props {
  variant?: BadgeVariants;
  className?: string;
  label: React.ReactNode | string;
  children?: React.ReactNode;
  title?: string;
  handleOnClick?: (checked: boolean) => void;
  checked?: boolean;
}

const BADGE_COMPONENTS = {
  layout_1: BadgeLayout_1,
  layout_2: BadgeLayout_2,
};

const Badge: React.FC<Props> = ({ variant, className, label, children, title, handleOnClick, checked }) => {
  const badgeLayoutConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.LAYOUTTYPECONFIG,
    deviceLayout: 'general',
  })?.sport;
  const badgeLayoutType = badgeLayoutConfig?.betStatusBadge || 'layout_1';
  const onBadgeClick = () => {
    handleOnClick && handleOnClick(!checked);
  };

  const BadgeLayout = BADGE_COMPONENTS[badgeLayoutType];
  return <BadgeLayout {...{ variant, className, label, children, title, handleOnClick: onBadgeClick }} />;
};

export default memo(Badge);
