import React from 'react';
import { ContentHeader } from 'modules/casino/shared/components/headers';
import { UIBlock } from 'modules/casino/shared/components/layout';
import { ExternalCasinoCategory } from './externalWrapper/ExternalCasinoCategory';
import { CategorySubTypes } from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';

interface Props {
  apiUri: string;
  containerName: string;
  containerId: number;
}

const SidebarCasinoCategory: React.FC<Props> = ({ apiUri, containerName, containerId }) => {
  const categoryId = apiUri.substring(apiUri.indexOf('=') + 1, apiUri.lastIndexOf('&page'));

  if (!categoryId) {
    return <></>;
  }

  return (
    <>
      <UIBlock>
        <ContentHeader title={containerName} />
        <ExternalCasinoCategory
          categoryId={categoryId}
          cacheKey={`${containerId}`}
          subType={CategorySubTypes.SIDEBAR}
        ></ExternalCasinoCategory>
      </UIBlock>
    </>
  );
};

export default SidebarCasinoCategory;
