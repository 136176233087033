import { CSSProperties } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Icon } from 'modules/casino/shared/components/icons';
import { Flex } from 'modules/casino/shared/components/layout';
import { TimeDataType } from 'modules/casino/shared/utils/dateUtils';
import { Promotions } from 'modules/casino/shared/utils/translationUtils/translationStrings';

interface RemPeriodProps {
  className?: string;
  style?: CSSProperties;
  dateDifference: TimeDataType;
}

// dateDifference upates only when the page reloads
const RemaningPeriod: React.FC<RemPeriodProps> = ({ className, style, dateDifference }) => {
  const { t } = useTranslation();
  const boxClass = clsx('text-nowrap fill--current', className);
  const hasDays = typeof dateDifference.days === 'number' || typeof dateDifference.days === 'string';
  const hasHours = typeof dateDifference.hours === 'number' || typeof dateDifference.hours === 'string';
  const hasMinutes = typeof dateDifference.minutes === 'number' || typeof dateDifference.minutes === 'string';

  return (
    <Flex.AC className={boxClass} style={style}>
      {dateDifference.status && t(Promotions.PrizeDrop[dateDifference.status])}
      {(hasDays || hasHours || hasMinutes) && (
        <Icon defaultClassName={false} size={16} className="mr-1 flex-shrink-0" icon="time-machine" />
      )}
      {dateDifference.days}
      {hasDays && t(Promotions.PromotionCell.daysText)} {dateDifference.hours}
      {hasHours && t(Promotions.PromotionCell.hoursText)} {dateDifference.minutes}
      {hasMinutes && t(Promotions.PromotionCell.minutesText)}
    </Flex.AC>
  );
};

export default RemaningPeriod;
