import { memo } from 'react';
import { Flex } from 'components/shared/layout';
import { useDateInterval } from '../hooks/useDateInterval';
import { retailFormatDate } from '../utils/retailHeaderLive.utils';

const RetailHeaderLiveDate = () => {
  const date = useDateInterval();
  const time = retailFormatDate(date);
  return (
    <Flex.Center className="h-100">
      <div className="r-header__time">{time.clock}</div>
      <Flex.ColAC className="r-header__date">
        <span>{time.date}</span>
        <span>{time.year}</span>
      </Flex.ColAC>
    </Flex.Center>
  );
};

export default memo(RetailHeaderLiveDate);
