import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// import { useMediaQuery } from 'react-responsive';
import { Flex } from 'components/shared/layout';
import Button from 'components/shared/UI/Button/Button';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { setLastPrintClick } from 'modules/retail/modules/cashier/features/lastPrint/slice/lastPrint.slice';
import { reprintInfoThunk } from 'modules/retail/modules/cashier/features/reprintTicket/slice/reprintTicket.slice';
import { selectCashierData } from 'modules/retail/modules/cashier/slice/cashier.slice';
// import { setIsVoucherSelect } from 'modules/retail/ssbt/features/ssbtVoucher/voucher.slice';
import { selectSessionBalance } from 'modules/retail/modules/ssbt/features/sessionBalance/slice/sessionBalance.slice';
import { RetailHeaderIcons } from 'modules/retail/modules/ssbt/types/ssbt.types';
import { resetTicketInfoState } from 'modules/retail/shared/slice/checkTicket.slice';
import { setIsVoucherSelect } from 'modules/retail/shared/slice/voucher.slice';
import { loginThunks, showQuickLoginForm } from 'pages/auth/login/slice/login.slice';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch, useAppSelector } from 'store';
// import { Breakpoints } from 'theme/Theme';
import { isCashier, isEmpty, LOGIN_FORM_TYPE_AUTH } from 'utils/common/helpersCommon';
import {
  CashierTranslations,
  MyBetsTranslation,
  SSBT,
  TimeoutModal,
} from 'utils/common/translationUtils/translationStrings';

import { RetailHeaderUserDepositBtn, RetailHeaderUserDefaultBtn } from './components';
import RetailHeaderHandHeldBtn from './components/RetailHeaderHandHeldBtn';
import RetailHeaderUserPosInfo from './components/RetailHeaderUserPosInfo';
import usePrintProgram from '../../../../hooks/usePrintProgram';

interface Props {
  isAuthenticated: boolean | null;
  isRetailMobile?: boolean;
}

const RetailHeaderUser: React.FC<Props> = ({ isAuthenticated, isRetailMobile }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const retailSessionBalance = useAppSelector(selectSessionBalance.retailBalanceInfo)?.sessionBalance;
  const login = useCallback(() => {
    dispatch(showQuickLoginForm({ status: true, loginActionType: LOGIN_FORM_TYPE_AUTH }));
  }, [dispatch]);
  const showHeaderButtons = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.RETAIL_HEADER_BUTTONS_VISIBLE,
    deviceLayout: 'general',
  });

  const posInfo = useAppSelector(selectCashierData.posInfo);
  const isPrintingEject = useAppSelector(selectCashierData.isPrintingEject);
  const { handlePreviewPrint, previewStatus } = usePrintProgram();

  const posName = !isEmpty(posInfo?.posInfo?.name) ? `${posInfo?.posInfo?.name}` : '';
  const terminalName = !isEmpty(posInfo?.posInfo?.terminalName) ? `${posInfo?.posInfo?.terminalName}` : '';

  const showDepositButton = !isRetailMobile && showHeaderButtons?.deposit;
  const showLimitsAndDepositButton = isAuthenticated && isRetailMobile && showHeaderButtons?.isLimitsAndDepositActive;

  const loginCashier = !isAuthenticated
    ? login
    : () => {
        return;
      };

  const logoutCashier = () => {
    dispatch(loginThunks.logout());
  };

  const showTerminalDepostModal = () => {
    dispatch(
      showRootModal({
        modalType: 'TERMINAL_DEPOSIT_CASH',
        modalId: 'TERMINAL_DEPOSIT_CASH',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
    dispatch(setIsVoucherSelect(false));
  };

  const showCheckTicketTerminal = () => {
    dispatch(
      showRootModal({
        modalType: 'TERMINAL_CHECK_TICKET',
        modalId: 'TERMINAL_CHECK_TICKET',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
  };

  const showAnexModal = () => {
    dispatch(
      showRootModal({
        modalType: 'ANEX_MODAL',
        modalId: 'ANEX_MODAL',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
  };

  // const showWithdrawTerminalModal = () => {
  //   if (isAuthenticated) {
  //     dispatch(showRootSportModal({ modalType: 'TERMINAL_WITHDRAW' }));
  //   }
  // };

  const showCashierModal = () => {
    dispatch(
      showRootModal({
        modalType: 'CASHIER_MODAL',
        modalId: 'CASHIER_MODAL',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
    dispatch(resetTicketInfoState());
  };

  const showFinanceModal = () => {
    dispatch(
      showRootModal({
        modalType: 'RETAIL_FINANCE_MODAL',
        modalId: 'RETAIL_FINANCE_MODAL',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
  };

  const createVoucher = () => {
    dispatch(
      showRootModal({
        modalType: 'TERMINAL_CREATE_VOUCHER',
        modalId: 'TERMINAL_CREATE_VOUCHER1',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
    // return (
    //   <>

    //   </>
    // );
    //   dispatch(terminalThunks.createVoucher({}));
  };

  const createRetailVoucher = () => {
    dispatch(
      showRootModal({
        modalType: 'CREATE_RETAIL_VOUCHER',
        modalId: 'CREATE_RETAIL_VOUCHER',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
  };

  const handleLastPrint = () => {
    dispatch(setLastPrintClick(true));
  };

  const showCashPayModal = () => {
    dispatch(
      showRootModal({
        modalType: 'CASH_PAY_MODAL',
        modalId: 'CASH_PAY_MODAL',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
  };
  const getInitialReprintInfo = () => {
    dispatch(reprintInfoThunk.getTerminal());
    dispatch(reprintInfoThunk.getOperations());
  };
  const showReprintModal = () => {
    getInitialReprintInfo();
    dispatch(
      showRootModal({
        modalType: 'REPRINT_MODAL',
        modalId: 'REPRINT_MODAL',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
  };

  return (
    <Flex.ACJE className="ruser">
      {isRetailMobile ? null : (
        <>
          {showHeaderButtons?.isPosAndTerminalInfo && isAuthenticated && (
            <RetailHeaderUserPosInfo posName={posName} terminalName={terminalName} />
          )}
          {showHeaderButtons?.cashPay && isAuthenticated && (
            <RetailHeaderUserDefaultBtn
              onClick={showCashPayModal}
              text={t(CashierTranslations.HeaderButton.cashPayButtonText)}
              icon={RetailHeaderIcons.CASH_PAY}
            />
          )}
          {showHeaderButtons?.isReprintButtonActive && isAuthenticated && (
            <RetailHeaderUserDefaultBtn
              onClick={showReprintModal}
              text={t(CashierTranslations.TransactionHistoryReprintModal.historyBtn)}
              icon={RetailHeaderIcons.REPRINT}
            />
          )}
          {showHeaderButtons?.printProgram && isCashier() && isAuthenticated && (
            <RetailHeaderUserDefaultBtn
              onClick={handlePreviewPrint}
              text={t(SSBT.Navigation.printProgram)}
              icon={RetailHeaderIcons.PRINT}
              isDisabled={previewStatus !== 'idle'}
            />
          )}
          {showHeaderButtons?.checkTicket && (
            <RetailHeaderUserDefaultBtn
              onClick={showCheckTicketTerminal}
              text={t(SSBT.Navigation.checkTicket)}
              icon={RetailHeaderIcons.QR}
            />
          )}
          {isAuthenticated && showHeaderButtons?.isAnexReport && (
            <RetailHeaderUserDefaultBtn
              onClick={showAnexModal}
              text={t(CashierTranslations.AnexReport.buttonAnexReport)}
              icon={RetailHeaderIcons.QR}
            />
          )}
          {!isEmpty(retailSessionBalance) && retailSessionBalance?.amount > 0 && showHeaderButtons?.createVoucher && (
            <RetailHeaderUserDefaultBtn
              text={`${t(MyBetsTranslation.BetSummary.cashoutText)}`}
              onClick={createVoucher}
              icon={RetailHeaderIcons.CASHOUT}
            />
          )}
          {isCashier() && isAuthenticated && (
            <RetailHeaderUserDefaultBtn
              onClick={() => showCashierModal()}
              text={t(CashierTranslations.HeaderButton.cashierModalButtonText)}
              icon={RetailHeaderIcons.CASHIER}
            />
          )}
          {isCashier() && isAuthenticated && showHeaderButtons?.voucherMenu && (
            <RetailHeaderUserDefaultBtn
              onClick={createRetailVoucher}
              text={t('Cashier.VoucherModal.voucherMenu')}
              icon={RetailHeaderIcons.VOUCHER}
            />
          )}
          {showHeaderButtons?.finance && isAuthenticated && (
            <RetailHeaderUserDefaultBtn
              onClick={showFinanceModal}
              text={t(CashierTranslations.HeaderButton.financeButtonText)}
              icon={RetailHeaderIcons.FINANCE}
            />
          )}
          {showHeaderButtons?.lastPrint && isAuthenticated && (
            <RetailHeaderUserDefaultBtn
              onClick={handleLastPrint}
              text={t(CashierTranslations.HeaderButton.lastPrintText)}
              icon={RetailHeaderIcons.REPRINT}
              isDisabled={isPrintingEject}
            />
          )}
          {showHeaderButtons?.logout && isAuthenticated && (
            <RetailHeaderUserDefaultBtn
              onClick={logoutCashier}
              text={t(TimeoutModal.Button.logoutText)}
              icon={RetailHeaderIcons.LOGOUT}
            />
          )}
        </>
      )}

      {!isAuthenticated &&
        !isRetailMobile &&
        showHeaderButtons?.login &&
        (isRetailMobile ? (
          <Button size="lg" onClick={loginCashier} text={t(`AppHeader.NavUser.loginBtn`)} />
        ) : (
          <RetailHeaderUserDefaultBtn
            onClick={loginCashier}
            text={t(`AppHeader.NavUser.loginBtn`)}
            icon={RetailHeaderIcons.LOGIN}
          />
        ))}

      {showDepositButton && (
        <RetailHeaderUserDepositBtn
          onClick={showTerminalDepostModal}
          showDepositIcon={!isRetailMobile}
          balance={!isEmpty(retailSessionBalance) && retailSessionBalance?.amount}
          currency={!isEmpty(retailSessionBalance) && retailSessionBalance?.currency}
        />
      )}
      {showLimitsAndDepositButton && <RetailHeaderHandHeldBtn />}
    </Flex.ACJE>
  );
};

export default memo(RetailHeaderUser);
