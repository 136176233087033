import i18next, { t } from 'i18next';
import { MapLastPartContentParams } from 'modules/retail/modules/ssbt/types/ssbt.types';
import { CommandData } from 'modules/retail/shared/types/retailCommon.types';
import { commands, printMethods, retailPrintOptions } from 'modules/retail/shared/utils/retail.utils';
import { store } from 'store';
import { isEmpty, toFixed } from 'utils/common/helpersCommon';
import { BetSlip, CashierTranslations } from 'utils/common/translationUtils/translationStrings';

export const mapLastPartContent = ({
  ticketIdData,
  totalOdds,
  _stake,
  _totalWin,
  dailySequenceNumber,
  terminalBetPin,
  retailAddress,
  totalCombinations,
  isDuplicate,
  isVermantiaFootballOnly,
  isLinearCodeOnReceipt,
  retailInfo,
  currency,
  checkTicketRetailInfo,
}: MapLastPartContentParams) => {
  const lastPartContent: CommandData[] = [];
  const hasFees = !isEmpty(retailInfo?.feePercentage || checkTicketRetailInfo?.feePercentage);
  const isMagnumBet = store.getState()?.common?.general?.layoutConfig?.['platform']?.general?.isMagnumBetRo;
  const grossStake = retailInfo?.grossStake || checkTicketRetailInfo?.grossStake;
  const feePercentage = retailInfo?.feePercentage || checkTicketRetailInfo?.feePercentage;
  const fee = retailInfo?.fee || checkTicketRetailInfo?.fee;
  printMethods.printOptions(lastPartContent, commands, retailPrintOptions.totalStakeOptions);
  printMethods.conditionalPrint(
    lastPartContent,
    commands,
    isMagnumBet,
    `${i18next.t('Cashier.Receipt.paymentAmount')}: ${grossStake} ${currency}`,
  );
  printMethods.print(lastPartContent, commands, _stake.str);
  printMethods.conditionalPrint(
    lastPartContent,
    commands,
    isMagnumBet && hasFees,
    `${i18next.t('Cashier.Receipt.betTax')}(${feePercentage * 100 + '%'}): ${fee} ${currency}`,
  );
  printMethods.conditionalPrint(
    lastPartContent,
    commands,
    totalCombinations === 1,
    `${i18next.t(BetSlip.SSBT.totalOdds)}: ${toFixed(Number(totalOdds), 2)}`,
  );
  printMethods.conditionalPrint(lastPartContent, commands, !isVermantiaFootballOnly, _totalWin.str);
  printMethods.print(lastPartContent, commands, ''.padEnd(44, '='));
  printMethods.printOptions(lastPartContent, commands, retailPrintOptions._addressOptions);
  printMethods.conditionalPrint(
    lastPartContent,
    commands,
    !isMagnumBet,
    `${i18next.t(CashierTranslations.CashierModal.betNumber)}: ${
      !isEmpty(dailySequenceNumber) ? dailySequenceNumber : ''
    }`,
  );
  printMethods.conditionalPrint(
    lastPartContent,
    commands,
    !isMagnumBet,
    `${i18next.t(CashierTranslations.CashierModal.pinNumber)}: ${!isEmpty(terminalBetPin) ? terminalBetPin : ''}`,
  );
  printMethods.print(lastPartContent, commands, '');
  printMethods.printOptions(lastPartContent, commands, retailPrintOptions.winbetAddress);
  printMethods.conditionalPrintBarcode(
    lastPartContent,
    commands,
    !isDuplicate && isLinearCodeOnReceipt,
    ticketIdData.str,
  );
  printMethods.print(lastPartContent, commands, '');
  printMethods.conditionalPrintQrCode(lastPartContent, commands, !isDuplicate, ticketIdData.str);
  printMethods.print(lastPartContent, commands, '');
  printMethods.conditionalPrint(
    lastPartContent,
    commands,
    !isEmpty(retailAddress?.responsibleGambling),
    t(retailAddress?.responsibleGambling),
  );
  printMethods.conditionalPrint(
    lastPartContent,
    commands,
    !isEmpty(retailAddress?.responsibleGambling1),
    t(retailAddress?.responsibleGambling1),
  );
  printMethods.conditionalPrint(
    lastPartContent,
    commands,
    !isEmpty(retailAddress?.responsibleGambling2),
    t(retailAddress?.responsibleGambling2),
  );
  printMethods.conditionalPrint(
    lastPartContent,
    commands,
    !isEmpty(retailAddress?.responsibleGambling3),
    t(retailAddress?.responsibleGambling3),
  );
  printMethods.conditionalPrint(
    lastPartContent,
    commands,
    !isEmpty(
      retailAddress?.responsibleGambling3 ||
        retailAddress?.responsibleGambling2 ||
        retailAddress?.responsibleGambling1 ||
        retailAddress?.responsibleGambling,
    ),
    '',
  );

  printMethods.printEject(lastPartContent, commands);

  return lastPartContent;
};
