import React, { useEffect, useCallback } from 'react';
import { useMatch } from 'react-router-dom';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { ConfirmationModalProps } from 'shared/common/features/rootModal/modals/ConfirmationModal';
import { showRootModal, hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { CustomPlatformEvents } from 'shared/common/hooks/useHandleCustomEvents';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { ModalPriorityEnum } from '../../rootModal/types/modal.types';
import { myProfileThunks, clearMigrateUsernameError, selectMyProfile } from '../slice/myProfile.slice';

const UsernameMigrationModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const username = useAppSelector(selectMyProfile.username);
  const formFields = useAppSelector(selectMyProfile.migrateUsernameForm.selectEntities);
  const isHelpMenu = useMatch('/help/*');
  const useModal = isAuthenticated && isEmpty(username) && !isEmpty(formFields);
  const shouldOpen = useModal && !isHelpMenu;
  const shouldClose = useModal && isHelpMenu;

  const closeModal = useCallback(() => {
    dispatch(hideRootModal());
  }, [dispatch]);

  useEffect(() => {
    shouldClose && dispatch(hideRootModal());
  }, [shouldClose, dispatch]);

  useEffect(() => {
    if (shouldOpen) {
      document.dispatchEvent(new Event(CustomPlatformEvents.HIGH_PRIORITY_MODAL));
      dispatch(
        showRootModal({
          modalType: 'CONFIRMATION_MODAL',
          modalProps: {
            hideClose: true,
            hasConfirmationInput: true,
            onSubmit: closeModal,
            title: formFields.title?.label,
            subtitle: formFields.subtitle?.label,
            inputErrorSelector: selectMyProfile.migrateUsernameError,
            resetInputErrorFunc: clearMigrateUsernameError,
            inputCheckFunction: myProfileThunks.migrateUsername,
            inputProps: formFields.input,
            linkLabel: formFields.link?.label,
            link: formFields.link?.link,
          },
          modalPriority: ModalPriorityEnum.LOW,
          modalId: 'CONFIRMATION_MODAL_USER_MIGRATION',
        } as ConfirmationModalProps),
      );
    }
  }, [closeModal, dispatch, formFields, shouldOpen]);

  return <></>;
};

export default UsernameMigrationModal;
