import React, { memo } from 'react';
import clsx from 'clsx';
import { browserVersion, isSafari } from 'react-device-detect';
import styled, { CSSProp } from 'styled-components';
import { Mixins } from 'theme/StyledComponents/shared';

interface Props {
  viewBox?: string;
  preserveAspectRatio?: 'none' | 'xMinYMin';
  className?: string;
  size?: number;
  defaultClassName?: boolean;
  iconPath?: string;
  icon: string | number | undefined;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
  style?: CSSProp;
  id?: string;
  // iconRef?: any;
  dataQidAttr?: string;
}

const Icon: React.FC<Props> = ({
  viewBox = '0 0 24 24',
  preserveAspectRatio,
  className,
  size,
  defaultClassName = true,
  // iconPath = Theme.icons.ui,
  iconPath,
  icon,
  onClick,
  style,
  id,
  // iconRef,
  dataQidAttr,
  ...rest
}) => {
  const constructedIconPath = iconPath ? `${iconPath}#${icon}` : `#${icon}`;

  const iconClass = clsx(defaultClassName && 'icon', className);

  return (
    <StyledIcon
      viewBox={viewBox}
      preserveAspectRatio={preserveAspectRatio}
      className={iconClass}
      size={size}
      $props={style}
      onClick={onClick}
      id={id}
      // ref={iconRef}
      data-qid={dataQidAttr}
      {...rest}
    >
      {browserVersion < '12' && isSafari ? <use xlinkHref={constructedIconPath} /> : <use href={constructedIconPath} />}
    </StyledIcon>
  );
};

const StyledIcon = styled.svg<{
  $props?: CSSProp;
  size?: number;
}>`
  ${({ $props }) => $props};
  height: ${({ size }) => size && Mixins.Size.toRem(size)};
  width: ${({ size }) => size && Mixins.Size.toRem(size)};
`;
Icon.displayName = 'Icon';

export default memo(Icon);
