import { useMediaQuery } from 'react-responsive';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes, General } from 'shared/common/features/general/types/generalSlice.types';
import { Breakpoints } from 'theme/Theme';
import useNavUserActions from './useNavUserActions';

type ReturnType = { isButtonVisible: boolean; onClick: () => void; buttonOrder: number; banner?: General.Banner };

const useNavItemConfig = (item: General.NavButtons): ReturnType => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: Breakpoints.isTabletOrMobile });
  const actions = useNavUserActions();
  const navigationConfig = useLayoutConfigType({ moduleLayout: 'platform', innerLayout: LayoutConfigTypes.NAVIGATION });
  //const isCollapsed = topNavHeaderStatus === 'collapsed';

  const { subNav, subNavContent } = navigationConfig || {};
  const isItemVisible = !!subNav?.[item];
  const buttonOrder = subNavContent?.[item]?.button?.order || 0;
  const banner = subNavContent?.[item]?.banner;
  // On mobile, we may need to show the signup button in header on scroll
  // const isButtonVisible = isTabletOrMobile && banner ? isItemVisible && isCollapsed : isItemVisible;
  const isButtonVisible = banner ? (isTabletOrMobile ? false : isItemVisible) : isItemVisible;
  const onClick = actions[item];

  return { isButtonVisible, onClick, buttonOrder, banner };
};

export default useNavItemConfig;
