import { useTranslation } from 'react-i18next';
import { CashierModalUI } from 'modules/retail/modules/cashier/cashierModalUI/Index';
import { InfoTableUI } from 'modules/retail/modules/ssbt/components/infoTableUI';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';
import { selectCashPay } from '../../slice/cashPay.slice';

const DepositInfoTable = () => {
  const verifyPlayerDeposit = useAppSelector(selectCashPay.verifyPlayerDeposit);
  const verifyPlayerDepositStatus = useAppSelector(selectCashPay.verifyPlayerDepositStatus);
  const { t } = useTranslation();

  if (verifyPlayerDepositStatus !== 'succeeded')
    return (
      <CashierModalUI.InfoMessage
        className="m-auto"
        isLoading={verifyPlayerDepositStatus === 'pending'}
        hasError={verifyPlayerDepositStatus === 'failure'}
        icon={'user'}
        infoMessage={
          verifyPlayerDepositStatus === 'failure'
            ? t(CashierTranslations.CashPayModal.errorInfoText)
            : t(CashierTranslations.CashPayModal.depositInfoText)
        }
      />
    );

  if (isEmpty(verifyPlayerDeposit)) return null;

  if (!isEmpty(verifyPlayerDeposit.errorReason))
    return (
      <CashierModalUI.InfoMessage
        className="m-auto"
        hasError={true}
        icon={'info'}
        infoMessage={verifyPlayerDeposit.errorReason}
      />
    );

  return (
    <>
      <InfoTableUI.Wrapper>
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.firstName)}
          cellValue={verifyPlayerDeposit.firstName}
        />
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.lastName)}
          cellValue={verifyPlayerDeposit.lastName}
        />
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.email)}
          cellValue={verifyPlayerDeposit.email}
        />
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.playerId)}
          cellValue={verifyPlayerDeposit.playerId}
        />
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.phone)}
          cellValue={verifyPlayerDeposit.phone}
        />
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.ucn)}
          cellValue={verifyPlayerDeposit.ucn}
        />
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.amount)}
          cellValue={`${verifyPlayerDeposit.amount} ${verifyPlayerDeposit.currency}`}
        />
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.amountAfterTax)}
          cellValue={`${verifyPlayerDeposit.amountAfterTax} ${verifyPlayerDeposit.currency}`}
        />
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.taxAmount)}
          cellValue={`${verifyPlayerDeposit.taxAmount} ${verifyPlayerDeposit.currency}`}
        />

        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.verificationStatus)}
          cellValue={verifyPlayerDeposit.verificationStatus}
        />
        <InfoTableUI.Cell
          isSpanned
          cellText={t(CashierTranslations.CashPayModal.currency)}
          cellValue={verifyPlayerDeposit.currency}
        />
      </InfoTableUI.Wrapper>
    </>
  );
};

export default DepositInfoTable;
