import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Breakpoints } from 'theme/Theme';
import { cdnConfig, fitCover } from 'utils/common/cdnConfig';
import config from 'utils/config';

type Props = {
  folder: string;
};

const StyledDwarf = styled(motion.img)`
  width: 45vw;
  position: absolute;
  right: 2px;
  bottom: 0;
  max-width: 174px;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    right: 9.29vw;
    width: 18.88vw;
    max-width: 258px;
  }
`;

const Dwarf: React.FC<Props> = ({ folder }) => {
  const path = `${cdnConfig.GAMIFICATION_MODULE}/${folder}`;

  const dwarfImg = `${config.CDN_IMAGE_API}${fitCover(174 * 2, 255 * 2)}${path}/dwarf.png`;

  return (
    <StyledDwarf
      initial={{ x: '100%' }}
      animate={{ x: 0 }}
      transition={{ duration: 0.2, delay: 0.1 }}
      src={dwarfImg}
      alt=""
    />
  );
};

export default Dwarf;
