import React, { memo } from 'react';
import CasinoContainerImplementation from 'modules/casino/platformImplementation/casinoWidgetTypes/CasinoContainerImplementation';
import useCasinoWidgetConfig from 'modules/casino/platformImplementation/hooks/useCasinoWidgetConfig';
import { WidgetContainerTypes } from 'modules/casino/platformImplementation/types/casinoWidget.types';
import { ContentHeader } from 'modules/casino/shared/components/headers';
import { UIBlock } from 'modules/casino/shared/components/layout';
import { ExternalCasinoCategory } from './externalWrapper/ExternalCasinoCategory';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';

interface GamesFeedProps {
  apiUri: string;
  containerId: number;
  containerName: string;
}

const FooterCasinoCategory: React.FC<GamesFeedProps> = ({ apiUri, containerId, containerName }) => {
  const categoryId = apiUri.substring(apiUri.indexOf('=') + 1, apiUri.lastIndexOf('&page'));
  const casinoWidgetConfig = useCasinoWidgetConfig();

  const renderContainer = () => {
    if (casinoWidgetConfig) {
      return (
        <CasinoContainerImplementation
          containerKey={`footer-category_${containerId}`}
          containerType={WidgetContainerTypes.CATEGORY}
          params={{
            categoryId,
            categoryType: CategoryLayoutTypes.EXTERNAL_CATEGORY,
            subType: CategorySubTypes.FOOTER,
          }}
        />
      );
    }

    return (
      <ExternalCasinoCategory
        categoryId={categoryId}
        cacheKey={`${containerId}`}
        subType={CategorySubTypes.FOOTER}
      ></ExternalCasinoCategory>
    );
  };

  return (
    <>
      <UIBlock type="gamesFeed">
        <ContentHeader title={containerName} />
        {renderContainer()}
      </UIBlock>
    </>
  );
};

export default memo(FooterCasinoCategory);
