import { useTranslation } from 'react-i18next';
import Icon from 'components/shared/Icon';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch } from 'store';
import { MyAccountTranslation } from 'utils/common/translationUtils/translationStrings';
import { FormStatusType } from '../formTypes';

const icons: Record<FormStatusType, string> = {
  verified: 'check-solid',
  pending: 'exclamation-solid',
};

const verificationBadgeText: Record<FormStatusType, string> = {
  verified: MyAccountTranslation.PhoneConfirmation.verified,
  pending: MyAccountTranslation.PhoneConfirmation.verifyButton,
};

const VerificationBadge: React.FC<{ status: FormStatusType }> = ({ status }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handlePhoneConfirmation = () => {
    status === 'pending' &&
      dispatch(
        showRootModal({
          modalType: 'PHONE_CONFIRMATION',
          modalPriority: ModalPriorityEnum.MEDIUM,
          modalId: 'PHONE_CONFIRMATION_LOGGED_IN',
          modalProps: { type: 'player', location: 'my-account', showLogoutButton: false, showCloseButton: true },
        }),
      );
  };

  const icon = icons[status];
  const text = verificationBadgeText[status];

  return (
    <span
      onClick={handlePhoneConfirmation}
      className={`verification-badge ${status === 'verified' ? 'success' : 'warning'}`}
    >
      <Icon defaultClassName={false} size={14} icon={icon} />
      {t(text)}
    </span>
  );
};

export default VerificationBadge;
