import styled from 'styled-components';
import { gamificationGenerateGradient } from '../../../utils/Gamification.utils';

export const StyledCTAText = styled.div.attrs(({ ...rest }) => ({
  ...rest,
}))<{ $color?: string; $textColor?: string }>`
  margin-top: -10px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  width: 80%;
  @media (min-width: 375px) {
    width: 70%;
  }
  min-height: 46px;
  font-size: clamp(0.875rem, 0.803rem + 0.31vw, 1rem);
  font-weight: 400;
  line-height: 1.1;
  text-shadow: 0px 1px 2px #000;
  color: ${({ $textColor }) => ($textColor ? $textColor : '#fff')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;

  ${({ $color }) => gamificationGenerateGradient($color ?? 'rgba(65, 76, 208, 0.4)')}
  transition: width 0.15s;
`;
