import { useEffect } from 'react';
import {
  gamificationPageThunks,
  resetPageNotificationsCount,
} from 'pages/gamificationPage/slice/gamificationPage.slice';
import { useAppDispatch } from 'store';
import { fetchCategories } from '../../myProfile/sections/tabs';
import { notificationThunks } from '../../myProfile/sections/tabs/notificationsTab/notificationSlice';

export const useNotifications = (isAuthenticated: boolean | null): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      const fetchOffers = dispatch(fetchCategories());
      const fetchInbox = dispatch(notificationThunks.fetchInboxNotification());
      const fetchPageNotificationsUnreadCount = dispatch(gamificationPageThunks.fetchUnreadNotificationsCount());

      return () => {
        fetchOffers.abort();
        fetchInbox.abort();
        fetchPageNotificationsUnreadCount.abort();
      };
    }
    if (!isAuthenticated) {
      dispatch(resetPageNotificationsCount());
    }
  }, [isAuthenticated, dispatch]);
};
