import { memo } from 'react';
import { CashBellContainer } from './campaigns/CashBell';
import { CashWheelContainer } from './campaigns/CashWheel';
import { DefaultBoxContainer } from './campaigns/DefaultBox';
import { DwarfsWagonContainer } from './campaigns/DwarfsWagon';
import { DwarfsWheelContainer } from './campaigns/DwarfsWheel';
import EasterEggContainer from './campaigns/EasterEgg';
import { JungleWheelContainer } from './campaigns/JungleWheel';
import { SpringReelContainer } from './campaigns/SpringReel';
import WizardBonus from './campaigns/wizardBonus/WizardBonus';
import { XmasReelContainer } from './campaigns/XmasReel';
import { NotificationTypes } from '../myProfile/sections/tabs/notificationsTab/notificationTypes';

const animationsMap = {
  CashWheel({ notification }: { notification: NotificationTypes.PopUpNotification }) {
    return <CashWheelContainer notification={notification} />;
  },
  Easter2022({ notification }: { notification: NotificationTypes.PopUpNotification }) {
    return <EasterEggContainer notification={notification} />;
  },
  // default campaigns have GiftBox animationKey and campaign prop for each default campaign
  GiftBox({ notification, isReady }: { notification: NotificationTypes.PopUpNotification; isReady: boolean }) {
    return <DefaultBoxContainer notification={notification} isReady={isReady} />;
  },
  FreeBox({ notification, isReady }: { notification: NotificationTypes.PopUpNotification; isReady: boolean }) {
    return <DefaultBoxContainer notification={notification} isReady={isReady} />;
  },
  JungleBox({ notification, isReady }: { notification: NotificationTypes.PopUpNotification; isReady: boolean }) {
    return <DefaultBoxContainer notification={notification} isReady={isReady} />;
  },
  GoldenBox({ notification, isReady }: { notification: NotificationTypes.PopUpNotification; isReady: boolean }) {
    return <DefaultBoxContainer notification={notification} isReady={isReady} />;
  },
  XmasBox({ notification, isReady }: { notification: NotificationTypes.PopUpNotification; isReady: boolean }) {
    return <DefaultBoxContainer notification={notification} isReady={isReady} />;
  },
  JungleWheel({ notification }: { notification: NotificationTypes.PopUpNotification }) {
    return <JungleWheelContainer notification={notification} />;
  },
  DwarfsWagon({ notification }: { notification: NotificationTypes.PopUpNotification }) {
    return <DwarfsWagonContainer notification={notification} />;
  },
  DwarfsWheel({ notification }: { notification: NotificationTypes.PopUpNotification }) {
    return <DwarfsWheelContainer notification={notification} />;
  },
  XmasReel({ notification }: { notification: NotificationTypes.PopUpNotification }) {
    return <XmasReelContainer notification={notification} />;
  },
  CashBell({ notification }: { notification: NotificationTypes.PopUpNotification }) {
    return <CashBellContainer notification={notification} />;
  },
  SpringReel({ notification }: { notification: NotificationTypes.PopUpNotification }) {
    return <SpringReelContainer notification={notification} />;
  },
  Box2Rows({ notification, isReady }: { notification: NotificationTypes.PopUpNotification; isReady: boolean }) {
    return (
      <DefaultBoxContainer
        notification={notification}
        isReady={isReady}
        rowLayout="2x3"
        primaryTextBgProps={true}
        differentImage={true}
      />
    );
  },
  WizardBonus({ notification }: { notification: NotificationTypes.PopUpNotification; isReady: boolean }) {
    return <WizardBonus notification={notification} />;
  },
};

const GamificationContainer: React.FC<{ notification: NotificationTypes.PopUpNotification; isReady: boolean }> = ({
  notification,
  isReady,
}) => {
  const Component = animationsMap[notification?.style?.animationKey || ''];

  if (!Component) return null;

  return <Component {...{ notification, isReady }} />;
};

export default memo(GamificationContainer);
