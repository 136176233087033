import isEqual from 'lodash/isEqual';
import { ZASliderItemToRotate, ZASliderItem, ZASliderItems } from '../types/zaslider.types';

export const supportedDirections = {
  vertical: {
    translates: 'Y',
  },
  horizontal: {
    translates: 'X',
  },
};
export const getTranslateDirection = (selectedDirection) => {
  return supportedDirections[selectedDirection].translates;
};

export const getContainerDimension = (itemDimension, slidesToShow, spaceBetween) => {
  return itemDimension * slidesToShow + spaceBetween * (slidesToShow - 1);
};

const findDuplicateItem = ({
  nextItem,
  itemsToRotate,
}: {
  nextItem: ZASliderItem;
  itemsToRotate: ZASliderItemToRotate;
}) => itemsToRotate.find(({ item }) => isEqual(item, nextItem));

export const getNextItem = ({
  items,
  itemsToRotate,
  nextItemIndex,
}: {
  items: ZASliderItems;
  itemsToRotate: ZASliderItemToRotate;
  nextItemIndex: number;
}) => {
  let nextIndexToAdd = 0;
  let nextItemToAdd: ZASliderItem | null = null;

  for (let i = nextItemIndex; i < items.length; i++) {
    const nextItem = items[i];
    const findedItem = findDuplicateItem({ nextItem, itemsToRotate });

    if (!findedItem) {
      nextIndexToAdd = i;
      nextItemToAdd = nextItem;
      break;
    }
  }

  if (nextItemToAdd) {
    return { nextIndexToAdd, nextItemToAdd };
  }

  for (let i = 0; i < nextItemIndex; i++) {
    const nextItem = items[i];
    const findedItem = findDuplicateItem({ nextItem, itemsToRotate });

    if (!findedItem) {
      nextIndexToAdd = i;
      nextItemToAdd = nextItem;
      break;
    }
  }

  return { nextIndexToAdd, nextItemToAdd };
};
