import React from 'react';
import clsx from 'clsx';
import OTPInput, { AllowedInputTypes } from 'react-otp-input';
import styled from 'styled-components';
import { FormUI } from './FormUI';
import { FormControlTypes } from '../formTypes';

const FormOTP: React.FC<FormControlTypes<(otp: string) => void>> = ({
  hasError,
  errorMessage,
  shouldAutoFocus = true,
  value,
  onChange,
  numInputs,
  inputMode,
  disabled,
}) => {
  const classes = clsx('modal-tfa__code-item', hasError && 'modal-tfa__code-item--error');

  const customOnPaste = (
    e: React.ClipboardEvent<HTMLInputElement>,
    onPasteProps: React.ClipboardEventHandler<HTMLInputElement>,
  ) => {
    if (!disabled) {
      onPasteProps(e);
      onChange && onChange(e.clipboardData.getData('text/plain'));
    }
  };

  const handleOnChange = (e: string) => {
    if (!disabled && onChange) {
      onChange(e);
    }
  };

  return (
    <>
      <OTPInput
        renderSeparator={() => <span className="px-1" />}
        renderInput={(inputProps) => (
          <StyledCell className={classes}>
            <StyledInput {...inputProps} disabled={disabled} onPaste={(e) => customOnPaste(e, inputProps.onPaste)} />
          </StyledCell>
        )}
        shouldAutoFocus={shouldAutoFocus}
        value={value}
        onChange={handleOnChange as (otp: string) => void}
        numInputs={numInputs}
        inputType={inputMode as AllowedInputTypes}
      />

      {hasError && errorMessage && (
        <FormUI.Row className="mt-3">
          <FormUI.Message type="error" content={errorMessage} />
        </FormUI.Row>
      )}
    </>
  );
};

export default FormOTP;

const StyledCell = styled.div`
  font-size: 38px;
  text-align: center;
  overflow: hidden;
  font-weight: 500;
`;
const StyledInput = styled.input`
  border: none;
  text-align: center;
  font-weight: 500;
  padding: 0;
  &:focus {
    outline: 0;
  }
`;
