import { CreateThunk } from 'store/thunkCreators';
import { ContainerTypeNames, ContainerSubTypeNames } from 'utils/common/types/common.types';
import { Sport } from 'utils/common/types/sportTypes';
import { FooterTypes } from '../../appFooter/types/appFooter.types';
import { BottomTabBarTypes } from '../../bottomTabBar/types/bottomTabBar.types';
import { DrawerTypes } from '../../drawer/types/drawer.types';
import { General } from '../../general/types/generalSlice.types';
export interface NavigationMenuState {
  verticals: Vertical[] | null;
  verticalsAll: Vertical[] | null;
  menuItems: DrawerTypes.MenuItem[];
  containersByVertical: Partial<Record<VerticalTypes, Container[]>>;
  containersByVerticalId: Partial<Record<number, Container[]>>;
  verticalsStatus: Sport.StateStatus;
  homePageVertical: Vertical | null;
  menuItemsLayout02: BurgerMenuLayoutMenuItems02[] | null;
  burgerMenuLayout02: BurgerMenuLayout02 | null;
  footer: FooterTypes.Footer;
}

export type BurgerMenuLayout02 = {
  breadcrumbMenuItem: BurgerMenuLayoutMenuItems02[];
  layout: string;
  showOnDesktop: boolean;
  hasSearchField: boolean;
};

export type BurgerMenuLayoutMenuItems02 = {
  icon: string | null;
  iconColor?: string;
  isCategoryActive: boolean;
  name: string;
  concreteType: string;
  typeId: number;
  options?: OptionsProps[];
  route: string;
  socialMedia: { icon?: string; link: string; text: string }[];
  rank: number;
  type: string;
  sectionType: 'help' | 'custom' | 'systemsettings';
  isOpened?: boolean;
  verticalType?: string;
  isExternalRoute?: boolean;
};

export type BurgerMenuSections = {
  section: BurgerMenuLayoutMenuItems02;
};

type OptionsProps = {
  name: string;
  text?: string;
  type: string;
  options: OptionItems[];
  items?;
};
type OptionItems = {
  text: string;
  value: string;
};

export type VerticalsAndContainersApiResponse = {
  verticals: Vertical[];
  menuItems: DrawerTypes.MenuItem[];
  footer: FooterTypes.Footer;
  breadcrumbMenuItem: BurgerMenuLayoutMenuItems02[];
  breadcrumbMenu: {
    layout: 'layout_1' | 'layout_2';
    showOnDesktop: boolean;
    hasSearchField: boolean;
    breadcrumbMenuItem: BurgerMenuLayoutMenuItems02[];
  };
};

export type UiVerticalType = 'sport' | 'casino' | 'retailGames' | null;

export type Vertical = {
  subverticals: Subvertical[];
  containers: Container[];
  footer?: FooterHome;
  id: number;
  name: string;
  route: string;
  apiUri: string;
  ranking: number;
  align: string;
  settings: string;
  isExternalRoute: boolean;
  isTickerLineVisible?: boolean;
  tickerLineSpeed?: number;
  uiVerticalType: UiVerticalType;
  default: boolean;
  bottomTabBar: BottomTabBarTypes.TabBarType[];
  isShowInMobileApp?: boolean;
  isHomePage: boolean;
  layoutConfig: { configs: General.RowColumn[] | null };
  isActive?: boolean;
  backgroundColor?: string;
  backgroundImage?: string;
  background?: string;
  alias?: string;
  ribbonConfig?: RibbonConfig;
  hasSeoContent?: boolean;
  casinoRootType: string | null;
  subtype?: 'bonusCollection';
  isLocked?: boolean;
  isHidden?: boolean;
  offsetPerDevice?: OffsetPerDeviceType;
  hasSidesOffset?: boolean;
};

type SectionItemLink = {
  id: number;
  ranking: number;
  typeName: null | string;
  icon: null | string;
  internalLink: null | string;
  externalLink: string;
  title: string;
  enableChatbot: boolean;
  linkSocials;
};

type SectionItem = {
  id: number;
  footerSectionId: number;
  typeName: string;
  ranking: number;
  icon: null | string;
  internalLink: null | string;
  externalLink: null | string;
  title: string;
  text: null | string;
  itemLinks: SectionItemLink[];
};

type SectionOption = {
  items: { code: string; displayName: string; offsetMinutes: number }[];
  name: string;
  text: string;
  type: string;
};

type Section = {
  autoScroll: boolean;
  depositButton: boolean;
  footerId: number;
  hasModal: boolean;
  icon: null | string;
  iconType: null | string;
  id: number;
  isHighlighted: boolean;
  items: SectionItem[];
  name: string;
  options: SectionOption[];
  ranking: number;
  text: null | string;
  title: null | string;
  typeName: 'Links';
};

type FooterHome = {
  sections: Section;
  verticals;
};

type RibbonConfig = {
  isNew?: boolean;
  labelColor?: string;
  textColor?: string;
};

export type Subvertical = {
  containers: Container[];
  id: number;
  name: string;
  route: string;
  apiUri: string;
  ranking: number;
  isDefault: boolean;
  align: string;
  isTickerLineVisible?: boolean;
  tickerLineSpeed?: number;
};

export type Container = {
  id: number;
  name: string;
  type: ContainerTypeNames;
  subType: ContainerSubTypeNames;
  containerTypeId: number;
  columnPosition: number;
  ranking: number;
  rowPosition: number;
  apiUri: string;
  configurations: string;
  containerConfigurations: ConfigurationsTypes;
  isFullWidth?: boolean;
  promoUri?: string;
  positionType: ContainerPosition;
  iconId: string | null;
  navigationUrl: string | null;
  navigationUrlTitle: string | null;
  subcontainersData?: Container[];
  layoutConfig: ContainerLayoutTypes;
  externalUrl?: string;
  backgroundFrame?: string;
  backgroundPerDevice?: backgroundPerDevice;
};

export type backgroundPerDevice = {
  desktop?: string;
  mobile?: string;
  tablet?: string;
  terminal?: string;
};
export type SliderLayoutTypes =
  | 'layout_1'
  | 'layout_2'
  | 'layout_3'
  | 'layout_4'
  | 'layout_5'
  | 'layout_6'
  | 'layout_7'
  | 'layout_9'
  | 'layout_10';
export type ConfigurationsTypes = {
  layout?: SliderLayoutTypes;
  loop?: boolean;
  showTitle?: boolean;
  staticTitle?: boolean;
  autoplay?: boolean;
  slideInterval?: number;
  iconType?: 'flags' | 'shirts' | 'none';
  layoutSelection?: SliderLayoutTypes;
  categoryId?: string;
  widgetId?: string;
  subLayoutSelection?: ContainerLayoutTypes;
  autoplayAccent?: boolean;
  slideIntervalAccent?: number;
  loopAccent?: boolean;
  pagination?: boolean;
};

export type ContainerPosition = 'Default' | 'Footer' | 'Header';

export type ContainerLayoutTypes = 'layout_1' | 'layout_2' | 'layout_3';
export enum VerticalsIds {
  SportLobby = 2,
  ESportLobby = 7,
  VirtualPreLobby = 6,
  InPlay = 1,
  CasinoEGT = 3,
  LiveCasino = 8,
  Casino = 4,
  Vegas = 5,
  Games = 10,
  Loto = 9,
  CaptainUp = 50,
}

export type VerticalTypes =
  | 'inplay'
  | 'sportLobby'
  | 'casinoEGT'
  | 'casino'
  | 'vegas'
  | 'virtualLobby'
  | 'esportLobby'
  | 'liveCasino'
  | 'games'
  | 'loto'
  | 'help';

export const verticalIdsmap: Record<number, VerticalTypes> = {
  1: 'inplay',
  2: 'sportLobby',
  3: 'casinoEGT',
  4: 'casino',
  5: 'vegas',
  6: 'virtualLobby',
  7: 'esportLobby',
  8: 'liveCasino',
  10: 'games',
  9: 'loto',
  18: 'help',
};

export type NavigationMenuThunks = {
  fetchVerticals: CreateThunk<void, VerticalsAndContainersApiResponse, string | null>;
};
type OffsetPerDeviceType = {
  mobile: number;
  desktop: number;
};
