import React, { memo } from 'react';
import clsx from 'clsx';
import Icon from 'components/shared/Icon';
import { useButtonBackToTop } from './hooks/useButtonBackToTop';

const ButtonBackToTop = () => {
  const { isBottomTabBarVisible, handleBackToTop, showBackToTop } = useButtonBackToTop();

  const buttonBackToTopStyles = clsx(
    'd-flex-center btn-back-to-top',
    isBottomTabBarVisible && 'btn-back-to-top--isBottomTabBarVisible',
    showBackToTop && 'btn-back-to-top--show',
  );

  return (
    <>
      <div className={buttonBackToTopStyles} onClick={() => handleBackToTop()}>
        <Icon className="btn-back__icon" icon={`arrow`} />
      </div>
    </>
  );
};

export default memo(ButtonBackToTop);
