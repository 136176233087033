import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError, isEmpty, getBusinessUnit } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { Challenges } from '../types/challenges.types';
import { normalizeCategories, normalizeChallenge } from '../utils/challenges.utils';

const initialState: Challenges.State = {
  categories: {},
  isLoading: {},
  hasFetched: {},
  hasError: {},
  normalizedChallenges: {},
  normalizedCategories: {},
};

export const challengesThunks = {
  getChallengesByType: createAbortThunk<Challenges.CategoryApi[], Challenges.GetAllArgs, string | null>(
    'challenges/getChallengesByType',
    async ({ page, categoryId, limit = 100 }, { rejectWithValue, signal, source }) => {
      try {
        signal.addEventListener('abort', () => source.cancel());

        const url = `${config.API_URL}/api/ews-game-journey/player/get-missions-by-categories`;
        const params = { categoryId, skip: (page - 1) * limit, limit };

        const response = await axiosInstance.get<Challenges.CategoryApi[]>(url, { params, cancelToken: source.token });
        return response.data;
      } catch (err) {
        if (Axios.isCancel(err)) {
          return rejectWithValue(null);
        }
        return rejectWithValue(getError.default());
      }
    },
    {
      condition: ({ alias }, { getState }) => {
        const { captainUp } = getState();
        const isLoading = captainUp.challenges.isLoading[alias];

        if (isLoading) return false;
      },
    },
  ),
  getItemById: createAbortThunk<Challenges.Challenge, { id: string; categoryId: number; alias: string }, string | null>(
    'challenges/getItemById',
    async ({ id, categoryId }, { rejectWithValue, signal, source }) => {
      try {
        signal.addEventListener('abort', () => source.cancel());

        const url = `${config.API_URL}/api/ews-game-journey/player/get-mission-details/${id}`;

        const response = await axiosInstance.get<Challenges.Challenge>(url, {
          params: { categoryId },
          headers: { 'X-Platform-Origin': getBusinessUnit() },
          cancelToken: source.token,
        });

        return response.data;
      } catch (err) {
        if (Axios.isCancel(err)) {
          return rejectWithValue(null);
        }
        return rejectWithValue(getError.default());
      }
    },
  ),
};

const challenges = createSlice({
  name: 'challenges',
  initialState,
  reducers: {
    resetChallenges: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(challengesThunks.getChallengesByType.pending, (state, action) => {
        const { alias } = action.meta.arg;
        state.isLoading[alias] = true;
      })
      .addCase(challengesThunks.getChallengesByType.fulfilled, (state, action) => {
        const { alias: aliasArg, page, polling } = action.meta.arg;

        state.categories[aliasArg] = action.payload?.map(({ alias }) => alias);

        const currPage = polling ? state.normalizedCategories?.[state.categories?.[aliasArg]?.[0]]?.page || page : page;
        normalizeCategories(state.normalizedCategories, action.payload, currPage, polling);

        action.payload?.forEach((challenge) => {
          normalizeChallenge(state.normalizedChallenges, challenge.items, challenge.alias);
        });
        state.hasFetched[aliasArg] = true;
        state.isLoading[aliasArg] = false;
      })
      .addCase(challengesThunks.getChallengesByType.rejected, (state, action) => {
        const { alias } = action.meta.arg;
        state.isLoading[alias] = false;
        if (action.payload) state.hasError[alias] = true;
      });
    builder.addCase(challengesThunks.getItemById.fulfilled, (state, action) => {
      if (!isEmpty(action.payload)) {
        const { id } = action.payload;
        const { alias } = action.meta.arg;
        if (!state.normalizedChallenges[alias]) {
          state.normalizedChallenges[alias] = {};
        }
        state.normalizedChallenges[alias][id] = action.payload;
      }
    });
    builder.addCase(logoutUser, () => initialState);
  },
});

export const { resetChallenges } = challenges.actions;
export default challenges.reducer;

export const selectChallenges = {
  isLoading:
    (alias: string) =>
    (state: RootState): boolean =>
      state.captainUp.challenges.isLoading?.[alias] || false,
  hasFetched:
    (alias: string) =>
    (state: RootState): boolean =>
      state.captainUp.challenges.hasFetched?.[alias] || false,
  hasError:
    (alias: string) =>
    (state: RootState): boolean =>
      state.captainUp.challenges.hasError?.[alias] || false,
  categoriesByType:
    (alias: string) =>
    (state: RootState): string[] =>
      state.captainUp.challenges.categories?.[alias],
  getChallengeById:
    (id: string, category: string) =>
    (state: RootState): Challenges.Challenge =>
      state.captainUp.challenges.normalizedChallenges?.[category]?.[id],
  getCategory:
    (category: string) =>
    (state: RootState): Challenges.Category =>
      state.captainUp.challenges.normalizedCategories?.[category],
  categoryHideName:
    (category: string) =>
    (state: RootState): boolean | undefined =>
      state.captainUp.challenges.normalizedCategories?.[category]?.hideName,
  hasChallengesItems: (state: RootState) => {
    let total = 0;
    Object.values(state.captainUp.challenges.normalizedCategories)?.forEach((category) => {
      if (category) {
        total += category.totalItems;
      }
    });
    return total > 0;
  },
};
