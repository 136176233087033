import { InputType, InputTypes } from 'components/shared/forms/formTypes';

export const defaultOtpInput = ({ inputMode, replacePattern }) => ({
  inputType: 'text' as InputType,
  inputMode,
  name: 'confirmationCode',
  type: InputTypes.OTP,
  effect: { replacePattern },
  validationRules: {
    required: {
      value: true,
      message: '',
    },
    minLength: {
      value: 6,
      message: '',
    },
  },
});
