import { Modal } from 'components/shared/Modal';
import { PrizeDrop } from '../PrizeDrop';

const PrizeDropModal = () => {
  return (
    <Modal size="prize-drop" visible dialogClassName="modal-dialog-centered">
      <PrizeDrop />
    </Modal>
  );
};

export default PrizeDropModal;
