import { t } from 'i18next';
import { BonusTagIconMapping } from 'modules/casino/modules/container/cellsContainer/cellls/constants/casinoGamesConstants';
import { GameType } from 'modules/casino/shared/constants';
import { gamesBonusSelectors } from 'modules/casino/shared/features/gameLaunchBonus/slice/gameLaunchBonus.slice';
import { replaceTranslationPlaceholder } from 'modules/casino/shared/utils/helpersCasino';
import { useAppSelector } from 'store';

export const useBonusTag = (
  gameId: string,
  gameType: GameType,
): {
  bonusTagIcon: string | undefined;
  bonusTagMessage: string;
} => {
  const activeBonusTag = useAppSelector((state) => gamesBonusSelectors.activeBonusTag(state, gameId, gameType));
  const { bonusType, bonusName } = activeBonusTag || {};
  const translatedMsg: string = bonusName
    ? replaceTranslationPlaceholder(
        t('CasinoLobby.BonusTagGames.bonusTagMessage'),
        `<b>${bonusName}</b>`,
        '<<bonusName>>',
      )
    : '';

  return {
    bonusTagIcon: (bonusType && BonusTagIconMapping[bonusType]) || '',
    bonusTagMessage: translatedMsg,
  };
};
