import { useNavigate } from 'react-router-dom';
import { useShowMyProfileUntilUnmount } from 'hooks/common/useShowMyProfileUntilUnmount';
import { keepMyProfileModalShown, showMyProfileModal } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch } from 'store';
import { notificationThunks, readNotification } from '../notificationSlice';
import { NotificationTypes } from '../notificationTypes';

export const useNotificationHandlers = (notification: NotificationTypes.PopUpNotification) => {
  useShowMyProfileUntilUnmount();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCloseButtonClick = () => {
    dispatch(hideRootModal());
    dispatch(readNotification(notification.id, 'popup'));
  };

  const handleAcceptButtonClick = () => {
    dispatch(keepMyProfileModalShown(false));
    dispatch(showMyProfileModal(false));
    dispatch(hideRootModal());
    dispatch(readNotification(notification.id, 'popup'));

    dispatch(
      notificationThunks.sendButtonClick({
        notificationId: notification.id,
        type: 'popup',
      }),
    );

    if (notification.button?.buttonUrl) {
      const url = notification.button.buttonUrl;
      url.startsWith('http://') || url.startsWith('https://') ? window.open(url, '_blank') : navigate(url as string);
    }
  };
  return { handleCloseButtonClick, handleAcceptButtonClick };
};
