import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppSelector } from 'store';

type UseShowPrivateSingleCategoryTypes = {
  isPublic: boolean;
};

const useShowSingleCategory = ({ isPublic }: UseShowPrivateSingleCategoryTypes): { showSingleCategory: boolean } => {
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);

  return { showSingleCategory: isAuthenticated || isPublic };
};

export default useShowSingleCategory;
