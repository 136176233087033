import React from 'react';
import { t } from 'i18next';
import { selectVoucherData, setRetailVoucherInfoStatus, voucherThunk } from 'modules/retail/shared/slice/voucher.slice';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { useAppDispatch, useAppSelector } from 'store';
import { SSBT } from 'utils/common/translationUtils/translationStrings';
import CloseModalButton from '../../../components/CloseModalButton';
import { TerminalModalUI } from '../../../components/ssbtModalUI';
import { TerminalModalActionIcons } from '../../../types/ssbt.types';
import { selectSessionBalance } from '../../sessionBalance/slice/sessionBalance.slice';

const TerminalCreateVoucher = () => {
  const { closeModal } = useHandleModals();
  const dispatch = useAppDispatch();
  // const voucher = useAppSelector(selectSSBTdata?.retailVoucher);
  const voucherStatus = useAppSelector(selectVoucherData.retailVoucherInfoStatus);
  const retailSessionBalance = useAppSelector(selectSessionBalance.retailBalanceInfo)?.sessionBalance;
  const createVoucher = () => {
    dispatch(voucherThunk.createVoucher({}));
  };
  const closeVoucherModal = () => {
    dispatch(setRetailVoucherInfoStatus('idle'));
    closeModal();
  };
  const renderTerminalModal = (status) => {
    switch (status) {
      case 'pending':
        return (
          <>
            <TerminalModalUI.Header text={t(SSBT.TerminalModal.requestTerminalText)} />
            <TerminalModalUI.Status status="pending" />
          </>
        );
      case 'succeeded':
        return (
          <>
            <TerminalModalUI.Status status="success" text={t(SSBT.TerminalModal.createVoucherSuccess)} />
            <CloseModalButton onClick={closeVoucherModal} />
          </>
        );

      case 'failure':
        return <TerminalModalUI.Status status="error" text={t(SSBT.TerminalModal.requestRejectTerminal)} />;
      default:
        return (
          <>
            <TerminalModalUI.Header text={t(SSBT.TerminalModal.createVoucherText)} />
            <TerminalModalUI.ActionIcon icon={TerminalModalActionIcons.CASHOUT} />
            <TerminalModalUI.Button
              variant={'primary'}
              text={t(SSBT.TerminalModal.generateVoucherText)}
              textSecondary={`${retailSessionBalance?.amount} ${retailSessionBalance?.currency}`}
              onClick={() => createVoucher()} //dispatch(terminalThunks.createVoucher({}));
            />
          </>
        );
    }
  };
  return (
    <TerminalModalUI.Wrapper isVisible={true}>
      {voucherStatus !== 'pending' && (
        <TerminalModalUI.Close text={t(SSBT.TerminalModal.closeTerminalModalText)} onClick={closeVoucherModal} />
      )}
      <TerminalModalUI.Body>
        <TerminalModalUI.ContentWrapper>{renderTerminalModal(voucherStatus)}</TerminalModalUI.ContentWrapper>
      </TerminalModalUI.Body>
    </TerminalModalUI.Wrapper>
  );
};

export default TerminalCreateVoucher;
