import { useCallback } from 'react';
import { selectAuthentication, showQuickLoginForm } from 'pages/auth/login/slice/login.slice';
import { useAppSelector, useAppDispatch } from 'store';
import { LOGIN_FORM_TYPE_MODAL } from 'utils/common/helpersCommon';

type ReturnType = { isAuthenticated: boolean | null; token?: string; showLoginForm: () => void };

export const useAuthentication = (): ReturnType => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);

  const showLoginForm = useCallback(
    () => dispatch(showQuickLoginForm({ status: true, loginActionType: LOGIN_FORM_TYPE_MODAL })),
    [dispatch],
  );
  // DO NOT CHANGE/EVALUATE isAuthenticated initial state.
  return { isAuthenticated, showLoginForm };
};
