import { useTranslation } from 'react-i18next';
import { DataLoader } from 'components/shared/loaders';
import NoDataMessage from 'components/shared/NoDataMessage';
import { PrizeDropTypes } from 'shared/common/features/PrizeDrop/types/prizeDrop.types';
import { isEmpty } from 'utils/common/helpersCommon';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import PrizeDropGiftItem from './PrizeDropGiftItem';
import PrizeDropGiftItemWrap from './PrizeDropGiftItemWrap';

type Props = {
  iconResource?: string;
  rewards: PrizeDropTypes.Rewards[];
  campaignStatus?: PrizeDropTypes.StateStatus;
  className?: string;
};

const PrizeDropGiftItemsContainer: React.FC<Props> = ({
  iconResource,
  campaignStatus = 'idle',
  rewards,
  className,
}) => {
  const { t } = useTranslation();

  if (campaignStatus === 'idle') return null;

  if (campaignStatus === 'pending') return <DataLoader />;

  if (isEmpty(rewards)) return <NoDataMessage message={t(Promotions.PrizeDrop.noRewards)} />;

  return (
    <PrizeDropGiftItemWrap className={className}>
      {rewards.map((reward, index) => (
        <PrizeDropGiftItem
          key={`${reward.bonusName || ''}-${index}`}
          title={reward.bonusName || ''}
          isActive={!!reward.givenAwards}
          givenAwards={reward.givenAwards}
          totalAwards={reward.totalAwards}
          iconResource={iconResource}
        />
      ))}
    </PrizeDropGiftItemWrap>
  );
};

export default PrizeDropGiftItemsContainer;
