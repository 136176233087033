import styled, { keyframes } from 'styled-components';
import { Mixins } from 'theme/StyledComponents/shared';

const pop = keyframes`
  0% { transform: scale(0); }
  100% { transform: scale(1); }
`;

const Clicker = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '100%', height: '100%', transform: 'rotate(-45deg)' }}
      width="80.84"
      height="132"
      viewBox="0 0 80.84 132"
    >
      <path d="M16.56 59.87c-.49 0-.98-.13-1.42-.41C10.64 56.6 6.89 52.64 4.28 48A32.257 32.257 0 01.17 32.23c0-4.35.85-8.57 2.53-12.55 1.62-3.84 3.95-7.28 6.91-10.24s6.41-5.28 10.24-6.91C23.83.85 28.05 0 32.4 0s8.57.85 12.55 2.53c3.84 1.62 7.28 3.95 10.24 6.91s5.28 6.41 6.91 10.24c1.68 3.98 2.53 8.2 2.53 12.55 0 5.41-1.37 10.77-3.96 15.48a2.656 2.656 0 01-3.6 1.05 2.656 2.656 0 01-1.05-3.6c2.16-3.94 3.3-8.41 3.3-12.94 0-7.19-2.8-13.96-7.89-19.04-5.09-5.09-11.85-7.89-19.04-7.89s-13.96 2.8-19.04 7.89c-5.09 5.09-7.89 11.85-7.89 19.04 0 4.62 1.19 9.17 3.44 13.17 2.18 3.88 5.32 7.19 9.08 9.58 1.23.78 1.6 2.42.82 3.66a2.65 2.65 0 01-2.24 1.23z"></path>
      <path d="M47.62 42.86c-.3 0-.6-.08-.87-.24-.82-.48-1.1-1.54-.62-2.36 1.42-2.42 2.17-5.2 2.17-8.03 0-8.77-7.13-15.9-15.9-15.9s-15.9 7.13-15.9 15.9c0 2.55.59 4.99 1.74 7.25.44.85.1 1.89-.75 2.32-.85.44-1.89.1-2.32-.75a19.406 19.406 0 01-2.12-8.82c0-5.17 2.01-10.03 5.67-13.69s8.52-5.67 13.69-5.67 10.03 2.01 13.69 5.67 5.67 8.52 5.67 13.69c0 3.44-.92 6.82-2.65 9.78-.32.55-.9.85-1.49.85z"></path>
      <path d="M54.44 132H41.43c-4.67 0-9.21-1.66-12.78-4.66-3.53-2.97-5.92-7.1-6.74-11.62-.34-1.88-.99-3.57-1.92-5.03L2.01 82.65a10.238 10.238 0 01-1.94-7.32c.31-2.65 1.62-5.04 3.68-6.74 3.82-3.14 9.35-3.12 13.15.07l5.74 4.81v-41.1c0-5.16 4.21-9.36 9.39-9.36h.16c5.05 0 9.16 4.11 9.16 9.16l.05 18.43c1.16-.49 2.45-.76 3.82-.76 3.88 0 7.22 2.38 8.64 5.75a9.305 9.305 0 014.49-1.15c4.32 0 7.97 2.95 9.04 6.94a9.31 9.31 0 014.12-.95h.16c5.05 0 9.16 4.11 9.16 9.16v12.29c0 16.95-2.82 25.36-5.19 29.43-.85 1.45-1.42 2.98-1.72 4.55-1.76 9.35-9.96 16.14-19.49 16.14zM10.29 71.84c-1.06 0-2.12.36-2.98 1.07a4.682 4.682 0 00-.76 6.46l.12.18 18.03 28.13c1.33 2.07 2.24 4.44 2.71 7.05a14.234 14.234 0 0014.02 11.69h13.01c6.85 0 12.73-4.87 14-11.59a19.68 19.68 0 012.38-6.32c1.65-2.84 4.43-10.09 4.43-26.62V69.6c0-1.97-1.6-3.57-3.57-3.57h-.16c-2.09 0-3.79 1.68-3.8 3.74v11.42c0 1.54-1.25 2.8-2.8 2.8s-2.8-1.25-2.8-2.8v-11.4-5.98c0-2.08-1.69-3.77-3.77-3.77s-3.77 1.69-3.77 3.77v11.86c0 1.54-1.25 2.8-2.8 2.8s-2.8-1.25-2.8-2.8V59.2c0-2.08-1.69-3.77-3.77-3.77-2.18 0-3.77 1.45-3.77 3.46v12.67c0 1.54-1.25 2.79-2.79 2.8-1.54 0-2.79-1.25-2.8-2.79l-.1-39.38c0-1.98-1.6-3.58-3.57-3.58h-.16c-2.1 0-3.8 1.69-3.8 3.77v47.09a2.793 2.793 0 01-4.59 2.14L13.3 72.94c-.87-.73-1.95-1.1-3.02-1.1z"></path>
    </svg>
  );
};

const SCClickIcon = styled.div`
  grid-row: 1 / 1;
  grid-column: 1 / 1;
  place-self: end end;

  margin-right: 15px;
  margin-bottom: 5px;

  height: ${Mixins.Size.clampedSize(35, 45)};
  width: ${Mixins.Size.clampedSize(35, 45)};

  fill: rgba(255, 255, 255, 0.75);

  animation: ${pop} 0.15s ease-in-out;
`;

const ClickIcon = () => {
  return (
    <SCClickIcon>
      <Clicker />
    </SCClickIcon>
  );
};

export default ClickIcon;
