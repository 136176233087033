import React, { HTMLAttributes, memo } from 'react';
import clsx from 'clsx';
import { StyledCTAText } from './CTAText.styled';
import Error from '../../../components/GamificationUI/Error';

interface Props extends HTMLAttributes<HTMLDivElement> {
  showWinText: boolean;
  text: string;
  winText: string;
  error?: string;
  className?: string;
}

const CTAText: React.FC<Props> = ({ showWinText, text, winText, error, className, ...rest }) => {
  const textClass = clsx('d-flex-center', className);

  return (
    <StyledCTAText className={textClass} {...rest}>
      {error ? <Error text={error} /> : showWinText ? winText : text}
    </StyledCTAText>
  );
};

export default memo(CTAText);
