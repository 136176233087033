import React from 'react';
import { cssNS } from './bonusLogUITypes';

interface Props {
  children?: React.ReactNode;
}

/** BonusItem Wrapper */
const Wrapper: React.FC<Props> = ({ children }) => {
  return (
    <div className={`${cssNS}__wrapper`} data-ics--global data-ics--history>
      {children}
    </div>
  );
};

export default Wrapper;
