import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { CasinoCategoryBreadcrumb, SetCategoryActiveFilterId } from '../types/CategoryLayoutTypes';

const initialState: CasinoCategoryBreadcrumb = {
  categoryFilters: {},
  categoryScroolTags: {},
};

const casinoBreadcrumbs = createSlice({
  name: 'categoryBreadcrumb',
  initialState,
  reducers: {
    setCategoryActiveFilterId(state, action: PayloadAction<SetCategoryActiveFilterId>) {
      const { collectionId, activeFilterId } = action.payload;
      const activeFiltersObj = { activeFilterId: activeFilterId };
      state.categoryFilters[collectionId] = { ...state.categoryFilters[collectionId], ...activeFiltersObj };
      state.categoryFilters[collectionId].activeFilterId = activeFilterId;
    },
    resetCategoryFilters(state) {
      state.categoryFilters = {};
    },
  },
});

export const { setCategoryActiveFilterId, resetCategoryFilters } = casinoBreadcrumbs.actions;

const selectActiveFilterId = (state: RootState, categoryId: string): string => {
  return state.casino.casinoBreadcrumbs.categoryFilters[categoryId]
    ? state.casino.casinoBreadcrumbs.categoryFilters[categoryId].activeFilterId
    : '';
};

export const categoryBreadcrumbSelectors = {
  activeFilterId: selectActiveFilterId,
};

export default casinoBreadcrumbs.reducer;
