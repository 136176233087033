import { useEffect } from 'react';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { useLoadingState } from '../../../../../../../shared/hooks/useLoadingState';
import { CategorySubTypes } from '../../../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { categorySelectors } from '../../../../containers/inner/slice/casinoCategorySlice';
import { favouritesThunks } from '../slice/casinoFavouriteGamesSlice';
import { CasinoFavouritesTypes } from '../types/casinoFavouriteGamesTypes';

export const useFetchFavouriteGames = ({
  subType,
  layoutConfig,
  query,
  hasSuccess,
  hasError,
  hasNoGames,
  isInGame,
}: CasinoFavouritesTypes.UseFetchFavouriteGamesProps): void => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const loadingState = useAppSelector((state) => categorySelectors.subTypeCategoryLoadingState(state, subType));
  const { hasSuccess: hasCategorySuccess } = useLoadingState(loadingState);

  useEffect(() => {
    if (isAuthenticated) {
      if (
        hasSuccess ||
        hasError ||
        hasNoGames ||
        (subType !== CategorySubTypes.STANDARD && !hasCategorySuccess) ||
        !query
      ) {
        return;
      }

      const promise = dispatch(favouritesThunks.fetchGames({ subType, layoutConfig, query, isInGame }));

      return () => {
        promise.abort();
      };
    }
  }, [isAuthenticated, dispatch, hasSuccess, hasError, hasCategorySuccess]);
};
