import React from 'react';
import { SCTilesWrapper } from './TilesWrapper.styled';
import { container } from '../../utils/ssbt.utils';

/** TilesWrapper */
interface TilesWrapperProps {
  children?: React.ReactNode;
}

const TilesWrapper: React.FC<TilesWrapperProps> = ({ children }) => {
  return (
    <SCTilesWrapper variants={container} initial="hidden" animate="visible">
      {children}
    </SCTilesWrapper>
  );
};

export default TilesWrapper;
