import React from 'react';
import clsx from 'clsx';
import useGameDescription from '../../../../../shared/hooks/useGameDescription';

const Description = ({ gameUniqueId, categoryId, className }) => {
  const { description } = useGameDescription({ gameUniqueId, categoryId });
  return (
    <div className={clsx(['w-100 px-1 text-center text-truncate casino-game-cell__title', className && className])}>
      {description}
    </div>
  );
};
export default Description;
