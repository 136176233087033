import styled from 'styled-components';
import { Breakpoints } from 'theme/Theme';

export const Marker = styled.div.attrs({
  className: 'grid-start-1x1 bg-contain',
})`
  z-index: 10;
  place-self: start center;
  margin-top: -30px;
  width: 50px;
  height: 54px;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    width: 64px;
    height: 70px;
  }
  transition:
    width 0.15s,
    height 0.15s;
`;
