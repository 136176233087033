import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { BottomTabBarTypes } from '../types/bottomTabBar.types';

const initialState: BottomTabBarTypes.BottomTabBar = {
  isMyBetsVisible: false,
  isBetSlipExpanded: false,
  isBottomTabBarVisible: false,
  isCheckTicketModalVisible: false,
  bottomTabBarHeight: 0,
  unsettledBetsCount: 0,
  tabs: [],
};

const bottomTabBar = createSlice({
  name: 'bottomTabBar',
  initialState,
  reducers: {
    setBottomTabBarVisbility(state, action: PayloadAction<boolean>) {
      state.isBottomTabBarVisible = action.payload;
    },
    setBottomTabBarHeight(state, action: PayloadAction<number>) {
      state.bottomTabBarHeight = action.payload;
    },
    setMyBetsVisibilityPlatform(state, action) {
      state.isMyBetsVisible = action.payload;
    },
    setMyBetsVisibilityFromSport(state, action) {
      state.isMyBetsVisible = action.payload;
    },
    setCheckTicketModalVisibilityPlatform(state, action: PayloadAction<boolean>) {
      state.isCheckTicketModalVisible = action.payload;
    },
    setCheckTicketModalVisibilityFromSport(state, action: PayloadAction<boolean>) {
      state.isCheckTicketModalVisible = action.payload;
    },
    setIsBetSlipExpandedPlatform(state, action) {
      state.isBetSlipExpanded = action.payload;
    },
    setIsBetSlipExpandedFromSport(state, action) {
      state.isBetSlipExpanded = action.payload;
    },
    setUnsettledBetsCountPlatform(state, action) {
      state.unsettledBetsCount = action.payload;
    },
    setOpenBetsCountPlatform(state, action) {
      const { openBetsCount } = action.payload;
      state.unsettledBetsCount = openBetsCount;
    },
  },
});

export const {
  setBottomTabBarVisbility,
  setBottomTabBarHeight,
  setMyBetsVisibilityPlatform,
  setMyBetsVisibilityFromSport,
  setCheckTicketModalVisibilityPlatform,
  setCheckTicketModalVisibilityFromSport,
  setIsBetSlipExpandedPlatform,
  setIsBetSlipExpandedFromSport,
  setUnsettledBetsCountPlatform,
  setOpenBetsCountPlatform,
} = bottomTabBar.actions;

export const selectBottomTabBar = {
  tabs: (state: RootState) => state.common.bottomTabBar.tabs,
  height: (state: RootState) => state.common.bottomTabBar.bottomTabBarHeight,
  isBottomTabBarVisible: (state: RootState) => state.common.bottomTabBar.isBottomTabBarVisible,
  isMyBetsVisible: (state: RootState) => state.common.bottomTabBar.isMyBetsVisible,
  isBetSlipExpanded: (state: RootState) => state.common.bottomTabBar.isBetSlipExpanded,
  isCheckTicketModalVisible: (state: RootState) => state.common.bottomTabBar.isCheckTicketModalVisible,
  unsettledBetsCount: (state: RootState) => state.common.bottomTabBar.unsettledBetsCount,
};

export default bottomTabBar.reducer;
