import React, { useEffect, useState } from 'react';
import {
  addFavGame,
  favoriteGamesSelectors,
  toggleFavGameIdInTopBar,
} from 'modules/casino/modules/container/cellsContainer/cellls/additionalGames/favourites/slice/casinoFavouriteGamesSlice';
import { useGetGameInfoById } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGetGameInfo';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { Icon } from 'modules/casino/shared/components/icons';
import { Flex } from 'modules/casino/shared/components/layout';
import { DEFAULT_GAME_ID_LOBBY } from 'modules/casino/shared/features/gameLaunch/casinoLaunchConstants';
import { casinoLaunchSelectors } from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import { useAppDispatch, useAppSelector } from 'store';

type Props = {
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
  collectionId: string;
  gridPage: number;
};

const FavouritesCell: React.FC<Props> = () => {
  const launchData = useAppSelector(casinoLaunchSelectors.launchData);
  const gameId = launchData?.gameId || DEFAULT_GAME_ID_LOBBY;
  const gamesInfo = useGetGameInfoById([gameId]);
  const favGamesIds = useAppSelector(favoriteGamesSelectors.favouritesGamesIds);
  const isGameInFavourites = favGamesIds.includes(gameId);
  const [isInFav, setIsInFav] = useState(isGameInFavourites);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsInFav(isGameInFavourites);
  }, [isGameInFavourites]);

  if (isInFav) return;

  const handleToggleFavourites = () => {
    dispatch(addFavGame(gamesInfo[0]));
    toggleFavGameIdInTopBar(gameId, true);
    setIsInFav(true);
  };

  return (
    <div className="casino-grid-item cg-add-fav" onClick={handleToggleFavourites}>
      <div className="casino-game-cell h-100">
        <Flex.Center className="cg-add-fav__wrapper">
          <Icon className="cg-add-fav__icon" icon={`plus-circled`} />
          <div className="cg-add-fav__text w-100 text-center text-truncate">{'Add'}</div>
        </Flex.Center>
      </div>
    </div>
  );
};
export default FavouritesCell;
