import React from 'react';
import clsx from 'clsx';
import { favoriteGamesSelectors } from 'modules/casino/modules/container/cellsContainer/cellls/additionalGames/favourites/slice/casinoFavouriteGamesSlice';
import FavouriteGamesModal from 'modules/casino/modules/container/cellsContainer/containers/inner/components/containerTypes/favourites/components/FavouriteGamesModal';
import { casinoLaunchSelectors } from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { playtechPokerSelectors } from 'pages/playtechPoker/slice/playtechPokerLogin.slice';
import { svaraAppSelectors } from 'pages/svaraAppLogin/slice/svaraAppLogin.slice';
import useIsSearchOpen from 'shared/common/features/appHeader/hooks/useIsSearchOpen';
import { BottomTabBar } from 'shared/common/features/bottomTabBar';
import { useAppSelector } from 'store';
import { DesktopDown } from 'theme/Theme';
import { isEmpty, isTerminal } from 'utils/common/helpersCommon';
import { Flex } from './layout';
import useCasinoWidgetConfig from '../../modules/casino/platformImplementation/hooks/useCasinoWidgetConfig';
import { selectIntegrations } from '../../shared/common/features/integrations/slice/integrations.slice';

const AppBottomPlatform = () => {
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const isFavouriteGamesModalOpen = useAppSelector(favoriteGamesSelectors.isFavouriteGamesModalOpen);
  const bottomTabBarClasses = clsx('bottom-tab-bar__wrapper', isFavouriteGamesModalOpen && 'fav-modal-backdrop-on');
  const isSearchPage = useIsSearchOpen();
  const openFromPoker = useAppSelector(playtechPokerSelectors.openFromPoker);
  const openFromSvara = useAppSelector(svaraAppSelectors.openFromSvara);
  const casinoWidgetConfig = useCasinoWidgetConfig();

  const launchInProcess = useAppSelector(casinoLaunchSelectors.launchInProcess);
  const casinoWidgetGameData = useAppSelector(selectIntegrations.casinoWidgetGameData);
  const gameLaunchInProcess = casinoWidgetConfig ? !isEmpty(casinoWidgetGameData) : launchInProcess;

  if (openFromPoker || openFromSvara || gameLaunchInProcess) {
    return <></>;
  }

  return (
    <DesktopDown>
      <Flex.Col className={bottomTabBarClasses}>
        {isAuthenticated && isFavouriteGamesModalOpen ? <FavouriteGamesModal /> : null}
        {!isSearchPage && !isTerminal() && <BottomTabBar />}
      </Flex.Col>
    </DesktopDown>
  );
};

export default AppBottomPlatform;
