import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { casinoFullScreenTypes } from './casinoFullScreenTypes';

const initialState: casinoFullScreenTypes.State = {
  isFullScreenActive: false,
  exitFullscreenKeyPressed: false,
};

const casinoFullScreen = createSlice({
  name: 'casinoFullScreen',
  initialState,
  reducers: {
    setIsFullSceenActive(state, action: PayloadAction<boolean>) {
      state.isFullScreenActive = action.payload;
    },
    setExitFullscreenKeyPressed(state, action: PayloadAction<boolean>) {
      state.exitFullscreenKeyPressed = action.payload;
    },
  },
});

export const { setIsFullSceenActive, setExitFullscreenKeyPressed } = casinoFullScreen.actions;

const selectIsFullScreenActive = (state: RootState): boolean => state.casino.casinoFullScreen.isFullScreenActive;
const selectExitFullscreenKeyPressed = (state: RootState): boolean =>
  state.casino.casinoFullScreen.exitFullscreenKeyPressed;

export const casinoFullScreenSelectors = {
  isFullScreenActive: selectIsFullScreenActive,
  exitFullscreenKeyPressed: selectExitFullscreenKeyPressed,
};

export default casinoFullScreen.reducer;
