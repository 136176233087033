import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UAParser from 'ua-parser-js';
import {
  setCommunicationChannel,
  setNewCodeCoolOff,
  setPhoneCoolOff,
  setPlayerId,
} from 'pages/auth/phoneConfirmation/slice/phoneConfirmation.slice';
import { deleteFantasyScoutToken } from 'pages/fantasyScout/slice/fantasyScout.slice';
import { deleteFantasyStarsToken } from 'pages/fantasyStars/slice/fantasyStars.slice';
import { logoutUserChat } from 'shared/common/chat/utils';
import { selectGeneral, setNetAppHeader } from 'shared/common/features/general/slice/general.slice';
import { getPlayerData, myProfileThunks } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { MFA_Channels } from 'shared/common/features/myProfile/types/myProfile.types';
import { hideRootModal, showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { loginUser, logoutOnError, logoutUser, setAccessToken } from 'shared/common/sharedSlices/commonActions';
import { AppDispatch } from 'store';
import { startAppListening } from 'store/middlewares/listenerMiddleware';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance, { getMidHeader } from 'utils/common/axios-instance';
import {
  getBusinessUnit,
  getCookie,
  getError,
  isCashier,
  isEmpty,
  LOGIN_FORM_TYPE_MODAL,
} from 'utils/common/helpersCommon';
import sendPlayerToRNWebView from 'utils/common/pushNotificationsHelper';
import config from 'utils/config';
import { reinitializeDateFormatter } from 'utils/dateUtils';
import { RootState } from './../../../../store/rootReducer';
import {
  authConstants,
  getAuthorizationHeaders,
  getClientId,
  getLoginErrorMessage,
  getLoginHeaders,
  LOGIN_URL,
  setUnauthenticatedUserProps,
} from '../loginHelpers';
import { Login } from '../types/login.types';
import UserStorage from '../UserStorage';

export const triggerLoginActions = ({ dispatch, responseData, navToOnSuccess }: Login.TriggerLoginArgs) => {
  UserStorage.setUser(responseData);
  dispatch(setAccessToken(responseData.access_token));
  dispatch(getPlayerData(true))
    .then((res) => {
      const loginPromise = res ? dispatch(loginUser()) : null;
      return Promise.all([res, loginPromise]);
    })
    .then(([res]) => navToOnSuccess && navToOnSuccess(responseData.player_id, res?.data));
  dispatch(myProfileThunks.getPlayerSportPreferences());
  reinitializeDateFormatter();
};

const triggerMfaActions = ({
  dispatch,
  responseData,
  channels,
}: {
  dispatch: AppDispatch;
  responseData: Login.ExtendedMfaResponse;
  channels?: MFA_Channels[];
}) => {
  dispatch(setPlayerId(responseData.playerId));
  dispatch(setCommunicationChannel(responseData.communicationChannel));
  dispatch(setPhoneCoolOff(responseData?.tokenExpiryDate));
  dispatch(setNewCodeCoolOff(responseData?.receiveNewCodeDate));
  dispatch(
    showRootModal({
      modalType: 'MULTI_FACTOR_VERIFICATION',
      modalProps: {
        showLogoutButton: false,
        showCloseButton: true,
        hideChannel: true,
        type: 'mfa_extended',
        location: 'login',
        channels,
      },
      modalPriority: ModalPriorityEnum.MEDIUM,
      modalId: 'MULTI_FACTOR_VERIFICATION',
    }),
  );
};

export const loginThunks: Login.LoginThunks = {
  fetchFormFields: createAbortThunk('login/fetchFormFields', async (_, { rejectWithValue, dispatch }) => {
    try {
      const url = `${config.API_URL}/api/ews-crm/public/cms/player-content?key=login_form`;
      const response = await axiosInstance.get(url);
      if (response.headers?.['x-platform-hash-netapp']) {
        dispatch(setNetAppHeader(response.headers['x-platform-hash-netapp']));
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(getError.message(err));
    }
  }),
  login: createAbortThunk('login/login', async ({ data, navToOnSuccess }, { dispatch, rejectWithValue, getState }) => {
    UserStorage.logout();
    const fbpCookieValue = getCookie('_fbp');
    const fbcCookieValue = getCookie('_fbc');
    const isMultipleAccountTrackingEnabled = selectGeneral.isMultipleAccountTrackingEnabled(getState());
    const multiAccountDataKey = UserStorage.getMultiAccountDataKey();
    const multiAccountDataValue = UserStorage.getMultiAccountDataValue();
    const shouldSendPlayerInfo =
      isMultipleAccountTrackingEnabled && !isEmpty(multiAccountDataKey) && !isEmpty(multiAccountDataValue);
    const params = {
      grant_type: authConstants.GRAND_TYPES.PASSWORD,
      client_id: getClientId(),
      ...data,
      ...(fbpCookieValue && { fbp: encodeURIComponent(fbpCookieValue) }),
      ...(fbcCookieValue && { fbc: encodeURIComponent(fbcCookieValue) }),
      ...(shouldSendPlayerInfo && { player_multi_account_data_key: multiAccountDataKey }),
      ...(shouldSendPlayerInfo && { player_multi_account_data_value: multiAccountDataValue.toString() }),
    };

    try {
      const formData = new FormData();
      Object.entries(params).forEach(([key, value]) => formData.append(key, value));
      const netAppHeader = selectGeneral.netAppHeader(getState());
      const midHeader = getMidHeader();

      const response = await axiosInstance.post(LOGIN_URL, formData, {
        headers: {
          ...getAuthorizationHeaders(),
          ...(netAppHeader && { 'x-platform-hash-netapp': netAppHeader }),
          ...(!isEmpty(midHeader) && { 'X-Platform-Mid': midHeader }),
          'Content-Type': 'multipart/form-data',
        },
        fetchOptions: { mode: 'cors' },
      });
      const responseData = response.data;
      const triggeredExtendedMfa: boolean = !!responseData?.triggeredExtendedMfa;

      if (!triggeredExtendedMfa) {
        triggerLoginActions({ dispatch, responseData, navToOnSuccess });
      } else {
        const channels = selectGeneral.mfaChannelsExtended(getState());
        triggerMfaActions({ dispatch, responseData, channels });
      }
      return responseData;
    } catch (err) {
      const responseStatus = getError.responseStatus(err);
      const responseData = getError.responseData(err) as Login.LoginResponseError;
      if (responseData.player_id) dispatch(setPlayerId(responseData.player_id));

      if (responseStatus === 570 && isCashier() && getBusinessUnit() === 'MBRO') {
        dispatch(showQuickLoginForm({ status: false }));
        dispatch(
          showRootModal({
            modalId: 'certificate',
            modalType: 'RETAIL_CERTIFICATE',
            modalPriority: ModalPriorityEnum.GRAND,
          }),
        );
      }
      const errorMsg = getLoginErrorMessage(responseStatus, responseData);
      return rejectWithValue(errorMsg);
    }
  }),
  refreshToken: createAbortThunk('login/refreshToken', async (_, { dispatch, rejectWithValue }) => {
    const user = await UserStorage.getUser();
    if (!user) {
      dispatch(loginThunks.logout());
      return;
    }
    const params = {
      grant_type: authConstants.GRAND_TYPES.REFRESH_TOKEN,
      client_id: getClientId(),
      refresh_token: user.refresh_token,
    };

    const response = await corsRequest(params);
    if (response.status === 200) {
      const responseData: Login.RefreshTokenResponse = await response.json();
      UserStorage.setUserRefreshToken(responseData);
      dispatch(setAccessToken(responseData.access_token));
      return responseData;
    } else {
      dispatch(loginThunks.logout());
      return rejectWithValue({ errorMessage: `Error occured, response code: ${response.status}` });
    }
  }),
  logout: createAbortThunk('login/logout', async (_, { dispatch }) => {
    dispatch(setUnauthenticatedUserProps());

    if (window.ftWidgets?.config?.token) {
      await dispatch(deleteFantasyScoutToken());
      window.ftWidgets.config.token = '';
    }

    if (window.ftStars?.token) {
      await dispatch(deleteFantasyStarsToken());
      window.ftStars.token = '';
    }

    reinitializeDateFormatter();
    if (window._smartico_user_id) {
      window._smartico_user_id = null;
      window._smartico_language = null;
    }
    await deleteToken();

    dispatch(logoutUser());
    if (window.isRNWebView) {
      sendPlayerToRNWebView('logout');
    }

    if (window['LC_API'] || window['$zopim'] || window['zE']) {
      logoutUserChat();
    }
  }),
};

const { fetchFormFields, login, refreshToken, logout } = loginThunks;

const initialState: Login.State = {
  loginLoading: false,
  // DO NOT CHANGE/EVALUATE isAuthenticated initial state.
  isAuthenticated: null,
  loginError: null,
  quickLoginFormVisibility: false,
  preloaderVisibility: false,
  validateSession: true,
  loginActionType: LOGIN_FORM_TYPE_MODAL,
  navigationOptions: null,
  fetchFormFieldsError: null,
  isFormLoading: false,
  fields: null,
  refreshTokenLoading: false,
};

const authentication = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setRefreshTokenChanging(state, action: PayloadAction<boolean>) {
      state.refreshTokenLoading = action.payload;
    },
    showQuickLoginForm(
      state,
      action: PayloadAction<{
        status: boolean;
        loginActionType?: Login.LoginActionType;
        navigationOptions?: Login.NavigationOptions;
      }>,
    ) {
      const { status, loginActionType, navigationOptions } = action.payload;
      state.quickLoginFormVisibility = status;
      state.loginActionType = loginActionType;
      if (navigationOptions) state.navigationOptions = navigationOptions; //when form is shown due to casino game launch without authenticated user.
    },
    showQuickLoginFormFromSport(state) {
      state.quickLoginFormVisibility = true;
      state.loginActionType = LOGIN_FORM_TYPE_MODAL;
    },
    validateSession(state, action: PayloadAction<boolean>) {
      state.validateSession = action.payload;
    },
    setPreloaderVisibility(state, action: PayloadAction<boolean>) {
      state.preloaderVisibility = action.payload;
    },
    setNavigationOptions(state, action: PayloadAction<Login.NavigationOptions | null>) {
      state.navigationOptions = action.payload;
    },
    resetFormFieldsData(state) {
      state.fields = null;
      state.fetchFormFieldsError = null;
    },
    clearLoginError(state) {
      state.loginError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormFields.pending, (state) => {
        state.isFormLoading = true;
      })
      .addCase(fetchFormFields.fulfilled, (state, action) => {
        state.isFormLoading = false;
        state.fields = action.payload;
      })
      .addCase(fetchFormFields.rejected, (state, action) => {
        state.isFormLoading = false;
        state.fetchFormFieldsError = action.payload;
      });
    builder
      .addCase(login.pending, (state) => {
        state.loginLoading = true;
        state.loginError = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        if (!(action.payload as Login.ExtendedMfaResponse)?.triggeredExtendedMfa) {
          state.preloaderVisibility = true;
        }
        state.loginLoading = false;
        state.quickLoginFormVisibility = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.loginLoading = false;
        state.loginError = action.payload;
      });
    builder
      .addCase(refreshToken.pending, (state) => {
        state.refreshTokenLoading = true;
        state.loginError = null;
      })
      .addCase(refreshToken.fulfilled, (state) => {
        state.refreshTokenLoading = false;
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.refreshTokenLoading = false;
        state.loginError = action.payload;
      });
    builder
      .addCase(logout.pending, (state) => {
        state.preloaderVisibility = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.preloaderVisibility = false;
      });
    builder
      .addCase(logoutUser, (state) => {
        state.isAuthenticated = false;
      })
      .addCase(loginUser, (state) => {
        state.isAuthenticated = true;
        state.preloaderVisibility = false;
      });
  },
});

export const {
  showQuickLoginForm,
  validateSession,
  setNavigationOptions,
  resetFormFieldsData,
  clearLoginError,
  setPreloaderVisibility,
  setRefreshTokenChanging,
  showQuickLoginFormFromSport,
} = authentication.actions;

export default authentication.reducer;

export const selectAuthentication = {
  isAuthenticated: (state: RootState): boolean | null => state.auth.login.isAuthenticated,
  preloaderVisibility: (state: RootState): boolean => state.auth.login.preloaderVisibility,
  validateSession: (state: RootState): boolean => state.auth.login.validateSession,
  loginActionType: (state: RootState): Login.LoginActionType | undefined => state.auth.login.loginActionType,
  loginFormFields: (state: RootState): Login.LoginFormInputTypes[] | null => state.auth.login.fields,
  isFormLoading: (state: RootState): boolean => state.auth.login.isFormLoading,
  loginLoading: (state: RootState): boolean => state.auth.login.loginLoading,
  refreshTokenLoading: (state: RootState): boolean => state.auth.login.refreshTokenLoading,
  navigationOptions: (state: RootState): Login.NavigationOptions | null => state.auth.login.navigationOptions,
  loginError: (state: RootState) => state.auth.login.loginError,
  quickLoginFormVisibility: (state: RootState): boolean => state.auth.login.quickLoginFormVisibility,
  fetchFormFieldsError: (state: RootState): null | string | undefined => state.auth.login.fetchFormFieldsError,
};

export const validateUserSession =
  () =>
  async (dispatch: AppDispatch): Promise<void> => {
    const user = UserStorage.getUser();
    dispatch(validateSession(false));
    if (user !== null) {
      const refreshTokenExpired = user.refreshExpirationDate - Date.now() < 0;
      if (refreshTokenExpired) {
        dispatch(logout());
        return;
      }
      dispatch(hideRootModal()); // if logged in from another tab, after session expiration
      dispatch(setPreloaderVisibility(true));
      dispatch(getPlayerData()).then((res) => res && dispatch(loginUser()));
      dispatch(setAccessToken(user.access_token));
    } else {
      dispatch(logoutUser());
    }
  };

export const updateStorageAuthToken =
  () =>
  (dispatch: AppDispatch): void => {
    // used to close refreshToken modal and invoke ws reconnect of all open tabs
    dispatch(hideRootModal());
    const user = UserStorage.getUser();
    dispatch(setAccessToken(user?.access_token));
    dispatch(setRefreshTokenChanging(true));
    setTimeout(() => dispatch(setRefreshTokenChanging(false)), 200);
  };

const deleteToken = async () => {
  const user = await UserStorage.getUser();
  if (!user) return;

  const parser = new UAParser();
  const result = parser.getResult();
  const deviceTypeName = (result.device && result.device.model) || '';
  const headers = {
    'X-Platform-Device-Name': deviceTypeName,
  };

  try {
    const response = await axiosInstance.delete(`${LOGIN_URL}/revoke`, { headers });
    UserStorage.logout();
    return response;
  } catch (err) {
    UserStorage.logout();
    return getError.message(err);
  }
};

const corsRequest = async (params) => {
  const headers = getLoginHeaders();
  const formData = new FormData();
  Object.keys(params).forEach((key) => formData.append(key, params[key]));
  return await fetch(`${LOGIN_URL}`, {
    method: 'post',
    body: formData,
    mode: 'cors',
    headers,
  });
};

///----- listeners -----///
startAppListening({
  actionCreator: logoutOnError,
  effect: (_, listenerApi) => {
    listenerApi.dispatch(loginThunks.logout());
  },
});
