import React from 'react';

import clsx from 'clsx';
import Icon from 'components/shared/Icon';
import { Flex } from 'components/shared/layout';
import { bonusCollectionModalPrefix } from '../utils/bonusCollectionsModal.utils';

type Props = {
  title: string;
  showClose?: boolean;
  handleClose?: () => void;
  handleInfo?: () => void;
  className?: string;
};

const BonusCollectionsModalHeader: React.FC<Props> = ({ title, handleClose, handleInfo, className }) => {
  return (
    <Flex.ACJB className={clsx(className, 'bs-modal__header')}>
      <Icon onClick={handleInfo} icon={'info-solid'} className={`${bonusCollectionModalPrefix}__header-icon`} />

      <div className={`${bonusCollectionModalPrefix}__header-title d-flex-center`}>{title}</div>

      {handleClose && (
        <div onClick={handleClose}>
          <Icon icon={'times'} className={`${bonusCollectionModalPrefix}__header-icon cursor-pointer`} />
        </div>
      )}
    </Flex.ACJB>
  );
};

export default BonusCollectionsModalHeader;
