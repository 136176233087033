import { FormEvent, RefObject } from 'react';
import { resetLastPrintInfoStatus } from 'modules/retail/modules/cashier/features/lastPrint/slice/lastPrint.slice';
import { clearRePrintData } from 'modules/retail/modules/cashier/utils';
import { printReceipt } from 'modules/retail/shared/printMethods/printUtils/helperPrintFunctions';
import { setIsVoucherSelect, setVoucherCode, voucherThunk } from 'modules/retail/shared/slice/voucher.slice';
import { setBetSlipQrCode } from 'shared/common/features/integrations/slice/integrations.slice';
import { hideRootModal, showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { AppDispatch } from 'store';
import { getError, isCashier, isEmpty, isTerminal } from 'utils/common/helpersCommon';
import { resetTicketInfoState, ticketInfoThunk } from '../../../shared/slice/checkTicket.slice';
import { setIsPopulateBetSlipFromQrCode, terminalThunks } from '../slice/ssbt.slice';
import { CheckTicket, TerminalThunks } from '../types/ssbt.types';

export const directMoneyPayout = async (): Promise<void> => {
  const portValidator = 'http://localhost:10001';
  const mapDataPayout = [
    { command: `${portValidator}/bnv_cmd_route_payout.json`, bodyData: { denom: '0', cc: 'EUR' } },
    // { command: `${portValidator}/bnv_cmd_route_payout.json`, bodyData: { denom: '10000', cc: 'EUR' } },
    // { command: `${portValidator}/bnv_cmd_route_payout.json`, bodyData: { denom: '20000', cc: 'EUR' } },
    // { command: `${portValidator}/bnv_cmd_route_payout.json`, bodyData: { denom: '50000', cc: 'EUR' } },
  ];
  try {
    await printReceipt(mapDataPayout);
  } catch (error) {
    getError.message(error);
  }
};

export const directMoneyStacker = async (): Promise<void> => {
  const portValidator = 'http://localhost:10001';
  const mapDataStacker = [
    { command: `${portValidator}/bnv_cmd_route_stacker.json`, bodyData: { denom: '500', cc: 'EUR' } },
    { command: `${portValidator}/bnv_cmd_route_stacker.json`, bodyData: { denom: '1000', cc: 'EUR' } },
    { command: `${portValidator}/bnv_cmd_route_stacker.json`, bodyData: { denom: '2000', cc: 'EUR' } },
    { command: `${portValidator}/bnv_cmd_route_stacker.json`, bodyData: { denom: '5000', cc: 'EUR' } },
    { command: `${portValidator}/bnv_cmd_route_stacker.json`, bodyData: { denom: '10000', cc: 'EUR' } },
    { command: `${portValidator}/bnv_cmd_route_stacker.json`, bodyData: { denom: '20000', cc: 'EUR' } },
    { command: `${portValidator}/bnv_cmd_route_stacker.json`, bodyData: { denom: '500000', cc: 'EUR' } },
  ];
  try {
    await printReceipt(mapDataStacker);
  } catch (error) {
    getError.message(error);
  }
};
// methods for widthdraw and checking amount in bankvalidator

export const payoutCoins = async (amount: number): Promise<void> => {
  await fetch('http://localhost:10003/azkoyen_cmd_d2_payout.json', {
    method: 'POST',
    body: JSON.stringify({ hop: 3, coins: amount * 100 }),
  });
};

// const checkAmount = async () => {
//   await axios.post('http://localhost:10001/bnv_cmd_all_amounts.json');
// };

export const setColorRgbController = async (red: string, green: string, blue: string): Promise<void> => {
  await fetch(`http://localhost:10005/rgb_cmd_set.json`, {
    method: 'POST',
    body: JSON.stringify({ rgb_n: '0', r: red, g: green, b: blue }),
  });
};

export const container = {
  visible: {
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};
export const item = {
  hidden: { x: '20%', opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
  },
};

export const handleDeposit = (handleNextStep: () => void, handleDepositTerminal: () => void): void => {
  handleNextStep();
  handleDepositTerminal();
};

export const setPreviousStep = (setActiveStep: (prevStep) => void): void => {
  setActiveStep((prevStep) => prevStep - 1);
};

export const handleNextStep = (setActiveStep: (prevStep) => void, setStep?: number): void => {
  setActiveStep((prevStep) => (setStep ? 2 : prevStep + 1));
};

export const closeModal = (dispatch: AppDispatch): void => {
  setColorRgbController('255', '0', '0');
  dispatch(hideRootModal());
};

export const handleOnSubmitLocal = (
  e: FormEvent<HTMLFormElement>,
  dispatch: AppDispatch,
  formRef: RefObject<HTMLFormElement>,
  handleSubmit: () => void,
  // thunks: TerminalThunks,
): void => {
  const formRefInstance = !isEmpty(formRef?.current) ? formRef?.current['ticketId'].value : '';
  e.preventDefault();
  dispatch(ticketInfoThunk.checkTicket({ ticketId: formRefInstance }));
  handleSubmit();
};

export const isEventLive = (data: CheckTicket | null): boolean[] => {
  if (isEmpty(data)) return [];

  return data?.ticket?.bets.reduce((acc: boolean[], bet) => {
    bet.outcomes.forEach((outcome) => {
      acc.push(outcome.live);
    });
    return acc;
  }, []);
};

export const handleOnSubmit = (
  value,
  thunks: TerminalThunks,
  dispatch: AppDispatch,
  isModal?: boolean,
  handleSubmit?: () => void,
): void => {
  dispatch(ticketInfoThunk.checkTicket({ ticketId: value }));
  if (!isModal) {
    if (isTerminal()) {
      dispatch(
        showRootModal({
          modalType: 'TERMINAL_CHECK_TICKET',
          modalId: 'TERMINAL_CHECK_TICKET',
          modalPriority: ModalPriorityEnum.LOW,
        }),
      );
    } else if (isCashier()) {
      dispatch(resetLastPrintInfoStatus());
      dispatch(
        showRootModal({
          modalType: 'CASHIER_MODAL',
          modalId: 'CASHIER_MODAL',
          modalPriority: ModalPriorityEnum.LOW,
        }),
      );
    }
  } else {
    handleSubmit && handleSubmit();
  }
};

// export const autoLogin = (
//   username: string,
//   password: string,
//   isAuthenticated: string | null,
//   dispatch: AppDispatch,
// ): void => {
//   const loginObject = { username, password };
//   if (isAuthenticated === null) {
//     dispatch(loginUserFromSport({ data: loginObject }));
//   }
// };

export const handleSubmitDepositVoucher = (voucherValue, dispatch) => {
  dispatch(voucherThunk.getVoucherInfo({ voucherCode: voucherValue }));
  dispatch(
    showRootModal({
      modalType: 'TERMINAL_DEPOSIT_CASH',
      modalId: 'TERMINAL_DEPOSIT_CASH',
      modalPriority: ModalPriorityEnum.LOW,
    }),
  );
};

export const populateBetSlipFromQrCode = (qrCode, dispatch) => {
  const code = qrCode.slice(1);
  dispatch(setBetSlipQrCode(code));
  dispatch(setIsPopulateBetSlipFromQrCode(true));
  clearRePrintData(dispatch);
};

export const scanVoucherCode = (voucherCode, dispatch) => {
  const code = voucherCode.slice(1);
  handleSubmitDepositVoucher(encodeURIComponent(code), dispatch);
  dispatch(setVoucherCode(code));
  dispatch(setIsVoucherSelect(true));
  dispatch(resetTicketInfoState());
};

export const checkTicket = (ticketCode, handleSubmit, dispatch) => {
  if (handleSubmit) {
    handleOnSubmit(ticketCode, terminalThunks, dispatch, true, handleSubmit);
  } else {
    handleOnSubmit(ticketCode, terminalThunks, dispatch, false);
  }
};
