import omit from 'lodash/omit';
import { CategoryImpressionsEventKeys } from '../constants/categoryImpressionsConstants';
import { CategoryImpressionsTypes } from '../types/categoryImpressionsTypes';

export const getClicksAndImpressionsEventType = ({
  key,
  trackingTemplate,
}: {
  key: CategoryImpressionsEventKeys;
  trackingTemplate: string;
}): string => (trackingTemplate ? `${trackingTemplate}-${key}` : key);

export const getImpressionsParams = ({
  key,
  registeredCategoryParams,
}: {
  key: CategoryImpressionsEventKeys;
  registeredCategoryParams: CategoryImpressionsTypes.CategoryImpressionsRegisteredParams;
}): { eventKey: string; params: CategoryImpressionsTypes.CategoryImpressionsParams } => ({
  eventKey: getClicksAndImpressionsEventType({ key, trackingTemplate: registeredCategoryParams?.trackingTemplate }),
  params: omit(registeredCategoryParams, ['trackingTemplate']),
});
