import { useEffect } from 'react';
import { useQuery } from 'hooks/useQuery';
import { usePlaytechPokerLogin } from 'pages/playtechPoker/hooks/usePlaytechPokerLogin';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { setOpenFromPoker } from '../slice/playtechPokerLogin.slice';

export const useSetOpenFromPoker = () => {
  const dispatch = useAppDispatch();
  const { query } = useQuery();
  const playtechPokerLogin = usePlaytechPokerLogin();
  const isLayoutConfigLoaded = useAppSelector(selectGeneral.isLayoutConfigLoaded);

  useEffect(() => {
    if (isLayoutConfigLoaded && playtechPokerLogin && query.get('openFromPoker')) {
      dispatch(setOpenFromPoker(true));
    }
  }, [dispatch, query]);

  return;
};
