import { pollDocumentScanner } from 'modules/retail/modules/cashier/slice/cashier.slice';
import { useAppSelector } from 'store';
import { pollRgbcontroller } from '../features/rgbController/slice/rgbController.slice';
import {
  enableMoneyValidator,
  runBillingValidator,
  runCoinAcceptorCommand,
  runPrinterStats,
  selectSSBTdata,
} from '../slice/ssbt.slice';

export const usePollingSSBTfunctions = () => {
  const isCoinAcceptorStopped = useAppSelector(selectSSBTdata.isCoinAcceptorStopped);
  const isRgbControllerStopped = useAppSelector(selectSSBTdata.isRgbControllerStopped);
  const createPollingInterval =
    (action, delay, conditionToStopPolling = false) =>
    (dispatch) => {
      const interval = setInterval(() => {
        if (conditionToStopPolling === true) {
          clearInterval(interval);
        }
        dispatch(action());
      }, delay);

      return () => clearInterval(interval);
    };

  const rgbPollingInterval = () => createPollingInterval(pollRgbcontroller, 1000, isRgbControllerStopped);
  const runCoinAcceptorCommandInterval = () =>
    createPollingInterval(runCoinAcceptorCommand, 800, isCoinAcceptorStopped);
  const runBillingValidatorInterval = () => {
    enableMoneyValidator();
    return createPollingInterval(runBillingValidator, 500);
  };
  const pollDocumentScannerInterval = () => createPollingInterval(pollDocumentScanner, 1000);
  const runPrinterStatsInterval = () => createPollingInterval(runPrinterStats, 8000);

  return {
    runPrinterStatsInterval,
    rgbPollingInterval,
    runCoinAcceptorCommandInterval,
    runBillingValidatorInterval,
    pollDocumentScannerInterval,
  };
};
