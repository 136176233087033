import React from 'react';
import clsx from 'clsx';
import { ProvidersBarUI } from 'modules/casino/modules/container/shared/features/casinoGameProviders/components/ProvidersBarUI';
import { useProviderDataById } from 'modules/casino/modules/container/shared/features/casinoGameProviders/hooks/useProviderDataById';
import config from 'utils/config';

interface Props {
  providerId: string;
  classNames?: string;
}

const JackpotsWidgetCellLogo: React.FC<Props> = ({ providerId, classNames }) => {
  const logoClass = clsx('jackpot-widget-cell--logo', classNames && classNames);
  const { code: providerCode } = useProviderDataById(providerId);

  return (
    <ProvidersBarUI.Logo
      className={logoClass}
      icon={providerCode || ''}
      path={`${config.CDN_URL}/gaming-content/game-providers-new/logos/`}
      height={34}
    />
  );
};

JackpotsWidgetCellLogo.displayName = 'JackpotsWidgetCellLogo';
export default JackpotsWidgetCellLogo;
