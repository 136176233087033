import { useTranslation } from 'react-i18next';
import { UI } from 'components/shared/UI';
import { useCurrency } from 'hooks/common/useCurrency';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { formatAmountToBRL } from 'modules/retail/modules/cashier/utils';
import SelectionStatus from 'modules/retail/modules/ssbt/components/betSelectionUI/SelectionStatus';
import { BetStatuses, MyBets } from 'pages/myAccount/tabs/history/sections/sportsHistory/types/myBets.types';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { toFixed } from 'utils/common/helpersCommon';
import { ClaimTicketTranslations } from 'utils/common/translationUtils/translationStrings';
import ClaimTicketNoAuthView from './ClaimTicketNoAuthView';
// import { getStatus } from '../../myBets/utils/checkTicket.utils';
import useClaimTicket from '../hooks/useClaimTicket';
import { mapSelectionStatus } from '../utils/claimTicket.utils';

const ClaimTicket = () => {
  const { isAuthenticated, ticketInfo, handleClaimTicket, isLoading } = useClaimTicket();
  const { winningAmount, status, ticketId, possibleAction } = ticketInfo || {};
  const { currencySymbol } = useCurrency();
  const { t } = useTranslation();
  const isWon = possibleAction === 'PAY_TICKET';
  const isUnsettled = (status as MyBets.TicketStatus) === 'Accepted';
  const isCurrencyBeforeAmount = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.CURRENCY_BEFORE_AMOUNT,
  });
  const amountText = isCurrencyBeforeAmount
    ? `${currencySymbol} ${formatAmountToBRL(winningAmount)}`
    : `${toFixed(winningAmount, 2)} ${currencySymbol}`;

  return (
    <div className="egtd-x-check-ticket-modal__status-wrapper">
      <div className="egtd-x-check-ticket-modal__status">
        <div className="egtd-x-text-fs-20"> {`${t(ClaimTicketTranslations.Info.ticketIdText)} ${ticketId}`} </div>
        {status && (
          <div style={{ zoom: 1.4 }}>
            <SelectionStatus status={mapSelectionStatus(status)} betStatus={status?.toUpperCase() as BetStatuses} />
          </div>
        )}
        {!isUnsettled && (
          <div className="egtd-x-text-fs-16">
            {t(ClaimTicketTranslations.Info.winText)}: {amountText}
          </div>
        )}
      </div>
      {!isAuthenticated && isWon && <ClaimTicketNoAuthView t={t} />}
      {!isWon ? (
        <></>
      ) : (
        // getStatus({
        //   status: isUnsettled ? status : 'Settled',
        //   outcomeStatus: mapTicketStatus(status),
        //   winAmount: winningAmount,
        //   t,
        // })
        <UI.Button
          key="addToBalance"
          text={`${t(ClaimTicketTranslations.Buttons.addToBalance)} ${amountText}`}
          onClick={handleClaimTicket}
          isDisabled={!isAuthenticated || isLoading}
          data-qid="add-to-balance-btn"
          //   showLoaderIcon={isLoading}
        />
      )}
    </div>
  );
};

export default ClaimTicket;
