import Cell_1_Wrapper from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell01/Cell_1_Wrapper';
import Cell_2_Wrapper from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell02/Cell_2_Wrapper';
import Cell_3_Wrapper from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell03/Cell_3_Wrapper';
import { LayoutProps } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/Layout_Wrapper';
import { CellLayout } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/layoutWrapperConstants';

const cells = {
  [CellLayout.cell_1]: Cell_1_Wrapper,
  [CellLayout.cell_2]: Cell_2_Wrapper,
  [CellLayout.cell_3]: Cell_3_Wrapper,
};

const layoutCellLoader = (cell): React.FC<LayoutProps> => {
  return cells[cell];
};

export default layoutCellLoader;
