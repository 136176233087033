import React, { memo } from 'react';
import clsx from 'clsx';

interface Props {
  homeScore: string;
  awayScore: string;
  classNames?: string;
  isHorizontal?: boolean;
}

const SingleEventScore: React.FC<Props> = ({ homeScore, awayScore, classNames, isHorizontal }) => {
  const eventScoreStyles = clsx('event-score', isHorizontal && classNames);
  return (
    <div className={eventScoreStyles}>
      <div className="score">{homeScore}</div>
      {isHorizontal && <div className="mx-auto"> - </div>}
      <div className="score">{awayScore}</div>
    </div>
  );
};

export default memo(SingleEventScore);
