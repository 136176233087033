import React, { useLayoutEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import { useOutsideClickPortal } from 'hooks/common/useOutsideClick';
import useCasinoWidgetConfig from 'modules/casino/platformImplementation/hooks/useCasinoWidgetConfig';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { TopBarTypes } from './topBar.types';
import TopBarPortal from './TopBarPortal';
import { fromBottom, fromLeft } from './utils/topBar.utils';

export const TopBarPopover: React.FC<TopBarTypes.TopBarPopover> = ({
  parentRef,
  children,
  show,
  additionalClass,
  handleClose,
}) => {
  const casinoWidgetConfig = useCasinoWidgetConfig();
  const bindTo: HTMLElement | null =
    show && parentRef.current
      ? parentRef.current.closest(casinoWidgetConfig ? '.egtd-game-launch-container' : '.cgl__wrapper')
      : null;

  const popoverRef = useRef<HTMLDivElement>(null);

  const [position, setPosition] = useState<{
    bottom: number;
    left: number;
  }>({
    bottom: 0,
    left: 0,
  });

  useOutsideClickPortal({
    ref: popoverRef,
    callback: handleClose,
    secondRef: parentRef,
    disableSecondRefForClick: true,
  });

  useLayoutEffect(() => {
    const sumPosition = () => {
      if (parentRef?.current && show) {
        const parent = parentRef.current.getBoundingClientRect();

        setPosition({
          bottom: isMobile ? parent.bottom - parent.y : 0,
          left: isMobile ? 0 : 98,
        });
      }
    };
    sumPosition();
    window.addEventListener('resize', sumPosition);
    return () => window.removeEventListener('resize', sumPosition);
  }, [parentRef, popoverRef, show]);

  const popOverClassnames = clsx(
    `${cssGlobalNS}-gl__popover`,
    additionalClass && additionalClass,
    isMobile ? `${cssGlobalNS}-gl__popover-mobile` : `${cssGlobalNS}-gl__popover-desktop`,
  );

  // if (!show || position.bottom === 0) return;

  return (
    <TopBarPortal bindTo={bindTo}>
      <AnimatePresence key="topBarPopOverKey">
        {show && (
          <motion.div
            className={popOverClassnames}
            ref={popoverRef}
            style={{
              bottom: position.bottom,
              left: position.left,
            }}
            variants={isMobile ? fromBottom : fromLeft}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </TopBarPortal>
  );
};
