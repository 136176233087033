/** Component CSS Namespace */
export const cssNS = 'bonus-log';

export const bonusLogComponentLocationCSSSuffixesMap = {
  myAccount: '--ma',
  myProfileDropdown: '--mpd',
  casinoTopBar: '--ctb',
} as const;

export const bonusLogComponentLocationCSSPaddingMap = {
  myAccount: '',
  myProfileDropdown: 'pt-2 pb-2',
  casinoTopBar: '',
} as const;

export type BonusLogComponentLocationType = keyof typeof bonusLogComponentLocationCSSSuffixesMap;
