import React, { memo } from 'react';
import { UIBlock } from 'components/shared/layout';
import { DrawerTypes } from 'shared/common/features/drawer/types/drawer.types';
import { isEmpty } from 'utils/common/helpersCommon';
import { ContainerLinkUI } from './ContainerLinkUI';
import { useContainerLinks } from './hooks/useContainerLinks';

interface Props {
  apiUrl: string;
  title: string;
  containerId: number;
  inDrawer?: boolean;
}

const ContainerLinks: React.FC<Props> = ({ apiUrl, title, containerId, inDrawer }) => {
  const { navigateToLink, container } = useContainerLinks({
    apiUrl,
    containerId,
    inDrawer,
  });

  return (
    <>
      {!isEmpty(container?.linkContainerContents) ? (
        <UIBlock>
          <ContainerLinkUI.Wrapper containerId={`link-container-${containerId}`}>
            <ContainerLinkUI.Header
              showIcon={container?.sportId !== -1}
              icon={container?.sportId?.toString() || ''}
              text={title}
            />
            <ContainerLinkUI.Content>
              {container?.linkContainerContents.map((containerLink, idx) => {
                const parsedParams: DrawerTypes.LinkParams = JSON.parse(containerLink?.params);
                return (
                  <ContainerLinkUI.Item
                    key={idx}
                    text={containerLink?.name}
                    showIcon={container.sportId === -1 && !!parsedParams?.sportName?.id}
                    icon={`${parsedParams?.sportName?.id}`}
                    onClick={() => navigateToLink(parsedParams, containerLink?.urlLink)}
                  />
                );
              })}
            </ContainerLinkUI.Content>
          </ContainerLinkUI.Wrapper>
        </UIBlock>
      ) : null}
    </>
  );
};

export default memo(ContainerLinks);
