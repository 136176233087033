import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'modules/casino/shared/components/layout';
import { Text } from 'modules/casino/shared/components/text';
import { cdnConfig, fitCover } from 'modules/casino/shared/utils/common/cdnConfig';
import { WsNotificationsTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/types/wsNotifications.types';
import config from 'utils/config';
import { JackpotTranslations, jackpotWinModalPrefix } from '../../utils/gameLaunchBonusConstants';

type Props = { bonus: WsNotificationsTypes.Bonus };

const JackpotModalSection: React.FC<Props> = ({ bonus }) => {
  const { t } = useTranslation();
  const winMessage = t(JackpotTranslations.winMessage) || '';
  const levelText = t(JackpotTranslations.level) || '';
  const multiplierText = t(JackpotTranslations.multiplier) || '';

  const imageUrl = `${config.CDN_IMAGE_API}${fitCover(303 * 2, 70 * 2)}${
    cdnConfig.PROMOTIONS_MODULE
  }/${bonus?.bannerResource}`;

  return (
    <Flex.ColCenter
      className={`${jackpotWinModalPrefix}__jackpot-won custom-scrollbars overflow-y-auto overflow-x-hidden`}
    >
      <img src={imageUrl} alt="" className={`${jackpotWinModalPrefix}__jackpot-won-image`} height={70} />
      <div className={`${jackpotWinModalPrefix}__jackpot-won--inner`}>
        <Flex.ColCenter>
          <Text className={`m-auto ${jackpotWinModalPrefix}__jackpot-won--title text-uppercase`} text={winMessage} />
          <Text
            className={`${jackpotWinModalPrefix}__jackpot-won--title-secondary text-uppercase`}
            text={bonus?.jackpotName || ''}
          />
        </Flex.ColCenter>
        <div className={`${jackpotWinModalPrefix}__jackpot-won-line`}></div>
        <Flex.ABJB className="w-100">
          <Flex.Col>
            <Text text={`${levelText} ${bonus?.jackpotLevelDescription || ''}`} />
            <Text className={`${jackpotWinModalPrefix}__jackpot-won--title`} text={bonus?.jackpotInitialAmount || ''} />
          </Flex.Col>

          <Flex.Col>
            <Text text={multiplierText} />
            <Text
              className={`ml-auto ${jackpotWinModalPrefix}__jackpot-won--title`}
              text={bonus?.jackpotMultiplier || ''}
            />
          </Flex.Col>
        </Flex.ABJB>
        <div className={`${jackpotWinModalPrefix}__jackpot-won-line`}></div>
        <Flex.Col>
          <Text
            className={`m-auto ${jackpotWinModalPrefix}__jackpot-won-text--accent`}
            text={bonus?.jackpotTotalAmount || ''}
          />
        </Flex.Col>
      </div>
    </Flex.ColCenter>
  );
};

export default JackpotModalSection;
