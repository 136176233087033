import { SSBT } from 'utils/common/translationUtils/translationStrings';

export const printerErrorMessages = (t) => {
  return [
    { error_code: 6601, errorMessage: t(SSBT.PrinterErrorMessages.theOutputMouthIsFull) },
    { error_code: 6602, errorMessage: t(SSBT.PrinterErrorMessages.thePrinterIsOffline) },
    { error_code: 6603, errorMessage: t(SSBT.PrinterErrorMessages.theHeadIsOverheated) },
    { error_code: 6604, errorMessage: t(SSBT.PrinterErrorMessages.theCoverIsOpen) },
    { error_code: 6605, errorMessage: t(SSBT.PrinterErrorMessages.noPaper) },
    { error_code: 6606, errorMessage: t(SSBT.PrinterErrorMessages.paperJammed) },
    { error_code: 6609, errorMessage: t(SSBT.PrinterErrorMessages.commandNotAcknowledged) },
    { error_code: 6610, errorMessage: t(SSBT.PrinterErrorMessages.tooManyColumnsDefined) },
    { error_code: 6701, errorMessage: t(SSBT.PrinterErrorMessages.noPrinterFound) },
    { error_code: 6702, errorMessage: t(SSBT.PrinterErrorMessages.printerHardwareMalfunction) },
    { error_code: 6703, errorMessage: t(SSBT.PrinterErrorMessages.printerCommunicationFailure) },
    { error_code: 6704, errorMessage: t(SSBT.PrinterErrorMessages.cutterError) },
    { error_code: 6705, errorMessage: t(SSBT.PrinterErrorMessages.unsupportedPrinter) },
    { error_code: 6706, errorMessage: t(SSBT.PrinterErrorMessages.powerSupplyVoltageError) },
  ];
};

export const bankValidatorErrors = (t) => {
  return [
    { alert: 'BNV_ALERT_TAMPER', errorMessage: t(SSBT.BankValidatorErrors.validatorTamper) },
    { alert: 'BNV_ALERT_CASHBOX_REMOVED', errorMessage: t(SSBT.BankValidatorErrors.cashboxRemoved) },
    { alert: 'BNV_ALERT_CASHBOX_REPLACED', errorMessage: t(SSBT.BankValidatorErrors.cashboxReplaced) },
    { alert: 'BNV_ALERT_BNV_ERROR', errorMessage: t(SSBT.BankValidatorErrors.validatorError) },
    { alert: 'BNV_ALERT_BNV_JAMMED', errorMessage: t(SSBT.BankValidatorErrors.validatorJammed) },
    { alert: 'BNV_ALERT_BNV_CLEARED', errorMessage: t(SSBT.BankValidatorErrors.validatorCleared) },
    { alert: 'BNV_ALERT_BNV_FULL', errorMessage: t(SSBT.BankValidatorErrors.containerFull) },
  ];
};

export const documentScannerError = (t) => {
  return [{ code: 1, errorMessage: t(SSBT.DocumentScanner.failedScan) }];
};

export const coinValidatorErrors = (t) => {
  return [
    { code: 'AZK_ERR_BADRESP', msg: '', errorMessage: t(`${SSBT.CoinValidatorErrors.unknownResponse}`) },
    { code: 'AZK_ERR_NAKRESP', msg: '', errorMessage: t(SSBT.CoinValidatorErrors.negativeACK) },
    { code: 'AZK_ERR_TIMEOUT', msg: '', errorMessage: t(SSBT.CoinValidatorErrors.commandTimeout) },
    { code: 'AZK_ERR_BUSY', msg: '', errorMessage: t(SSBT.CoinValidatorErrors.busyDevice) },
    { code: 'AZK_ERR_CMD', msg: '', errorMessage: t(SSBT.CoinValidatorErrors.unknownCommand) },
    { code: 'AZK_ERR_BADARG', msg: '', errorMessage: t(SSBT.CoinValidatorErrors.invalidArgument) },
    { code: 'AZK_ERR_BADDEV', msg: '', errorMessage: t(SSBT.CoinValidatorErrors.notConfigured) },
    { code: 'AZK_EVT_D2_ALERT', msg: '', errorMessage: t(SSBT.CoinValidatorErrors.notPresent) },
    { code: 'AZK_EVT_X6_POLL_MSG', msg: 'Coin rejected ', errorMessage: t(SSBT.CoinValidatorErrors.coinRejected) },
    {
      code: 'AZK_EVT_X6_POLL_MSG',
      msg: 'The acceptor is inhibited',
      errorMessage: t(SSBT.CoinValidatorErrors.acceptorInhibited),
    },
    {
      code: 'AZK_EVT_X6_POLL_MSG',
      msg: 'Timeout in the exit detector',
      errorMessage: t(SSBT.CoinValidatorErrors.timeoutDetector),
    },
    {
      code: 'AZK_EVT_X6_POLL_MSG',
      msg: 'Two coins passing too close together',
      errorMessage: t(SSBT.CoinValidatorErrors.passedTwoCoins),
    },
    {
      code: 'AZK_EVT_X6_POLL_MSG',
      msg: 'Shutter not working properly',
      errorMessage: t(SSBT.CoinValidatorErrors.shuttreNotWork),
    },
    {
      code: 'AZK_EVT_X6_POLL_MSG',
      msg: 'Sensor Module not working properly',
      errorMessage: t(SSBT.CoinValidatorErrors.sensorNotWork),
    },
    {
      code: 'AZK_EVT_X6_POLL_MSG',
      msg: 'Jam in exit detector ',
      errorMessage: t(SSBT.CoinValidatorErrors.jamInDetector),
    },
    {
      code: 'AZK_EVT_X6_POLL_MSG',
      msg: 'Error in the output module',
      errorMessage: t(SSBT.CoinValidatorErrors.outputModule),
    },
    {
      code: 'AZK_EVT_X6_POLL_MSG',
      msg: 'The anti-threading system has been activated',
      errorMessage: t(SSBT.CoinValidatorErrors.antiThreadingSystem),
    },
    {
      code: 'AZK_EVT_X6_POLL_MSG',
      msg: 'Coin too fast through the exit sensor',
      errorMessage: t(SSBT.CoinValidatorErrors.fastCoin),
    },
    { code: 'AZK_EVT_X6_POLL_MSG', msg: 'Coin is inhibited ', errorMessage: t(SSBT.CoinValidatorErrors.inhibitedCoin) },
    {
      code: 'AZK_EVT_X6_POLL_MSG',
      msg: 'Recovery mechanism activated ',
      errorMessage: t(SSBT.CoinValidatorErrors.recoveryMechanism),
    },
    {
      code: 'AZK_EVT_X6_POLL_MSG',
      msg: 'Unspecified error code',
      errorMessage: t(SSBT.CoinValidatorErrors.unspecifiedError),
    },
  ];
};
export const rgbControllerErrors = (t) => {
  return [
    { code: 'RGB_ERR_NOTFND ', errorMessage: t(SSBT.RgbControllerErrors.notFnd) },
    { code: 'RGB_ERR_BADRESP', errorMessage: t(SSBT.RgbControllerErrors.badResponse) },
    { code: 'RGB_ERR_PORT', errorMessage: t(SSBT.RgbControllerErrors.port) },
    { code: 'RGB_ERR_TIMEOUT', errorMessage: t(SSBT.RgbControllerErrors.timeout) },
    { code: 'RGB_ERR_BUSY', errorMessage: t(SSBT.RgbControllerErrors.busy) },
    { code: 'RGB_ERR_CMD', errorMessage: t(SSBT.RgbControllerErrors.unknownCommand) },
    { code: 'RGB_ERR_BADARG ', errorMessage: t(SSBT.RgbControllerErrors.invalidArgument) },
    { code: 'RGB_ERR_BADDEV ', errorMessage: t(SSBT.RgbControllerErrors.baddev) },
  ];
};
