import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { isMacOs } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import ResizeObserver from 'resize-observer-polyfill';
import smoothscroll from 'smoothscroll-polyfill';
import { useOnOff } from 'hooks/common/useOnOff';
import { useScrollToActiveElement } from 'hooks/common/useScrollToActiveElement';
import { Breakpoints, Desktop } from 'theme/Theme';
import DefaultChevron from './icons/DefaultChevron';

export enum arrowStyleTypes {
  Sports = 'sports',
  Casino = 'casino',
  CasinoSmall = 'casino-small',
  Tabs = 'tabs',
  ArrowsAlwaysVisible = 'arrowsAlwaysVisible',
  Platform = 'platform',
  PlatformSmall = 'platform-small',
}

export const SPORT_TYPE_LOBBY_SCROLL_EVENT = 'SPORT_TYPE_LOBBY_SCROLL_EVENT';
export const DRAWER_HEADER_SCROLL_EVENT = 'DRAWER_HEADER_SCROLL_EVENT';

export const handleScrollMoveWith = ({
  itemWidth,
  itemsToScroll,
  additionalGap,
}: {
  itemWidth: number;
  itemsToScroll: number;
  additionalGap?: number;
}) => {
  const moveWith = itemWidth * itemsToScroll + (additionalGap ? additionalGap : 0);
  return {
    moveWith,
  };
};

interface Props {
  containerRef?: React.MutableRefObject<null>;
  wrapperClassName?: string;
  classNames?: string;
  regularDesktopCellWidth?: number;
  /**
   * px move on click arrow
   */
  moveWith: number;
  /**
   * Arrow Type
   */
  typeArrow: arrowStyleTypes;
  /**
   * Refresh when items load
   */
  loadItems?: boolean;
  id?: string;
  centered?: boolean;
  onNavigate?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  /**
   * Scroll to active class
   */
  activeClass?: string;
  children?: React.ReactNode;
  centerScrollEventName?: string;
  /**
   * Should scroll to elem with active class upon children change
   */
  scrollToElemOnChildrenChange?: boolean;
  /**
   * Changing the color of the arrow if needed
   */
  arrowStyle?: 'dark' | 'light';
  /**
   * Setting full height of scroll container to ensure arrow buttons takes the entire height of the parent
   */
  setFullHeightArrow?: boolean;
  /**
   * Removes the absolute position of the arrows
   */
  staticArrow?: boolean;
}

const ScrollContainer: React.FC<Props> = ({
  containerRef,
  children,
  wrapperClassName,
  classNames,
  moveWith,
  typeArrow,
  loadItems,
  id,
  centered,
  onNavigate,
  activeClass,
  centerScrollEventName,
  scrollToElemOnChildrenChange = true,
  arrowStyle,
  setFullHeightArrow,
  staticArrow,
}) => {
  const parentWrapperRef = useRef<HTMLDivElement | null>(null);
  const scrollWrapperRef = useRef<HTMLDivElement>(null);
  const arrowLeftRef = useRef<HTMLDivElement>(null);
  const arrowRightRef = useRef<HTMLDivElement>(null);
  const scrollEndedRef = useRef(true);
  const [showArrows, setShowArrows] = useOnOff();
  const scrollToActiveElement = useScrollToActiveElement();
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });
  const triggerRef = useCallback(
    (node) => {
      node && (parentWrapperRef.current = node);
      node && containerRef && (containerRef.current = node);
    },
    [containerRef],
  );

  const updateArrows = () => {
    if (scrollWrapperRef.current) {
      const diff = scrollWrapperRef?.current?.clientWidth - scrollWrapperRef?.current?.scrollWidth;

      if (diff >= 0 && diff <= 1) {
        setShowArrows(false);
      } else {
        verifyArrows();
        setShowArrows(true);
      }
      activeClass && scrollToActiveElement(scrollWrapperRef.current, activeClass);
    }
  };

  // useResizeDetector({
  //   targetRef: scrollWrapperRef,
  //   handleHeight: false,
  //   onResize: updateArrows,
  // });

  const ro = new ResizeObserver(
    (
      entries,
      // observer
    ) => {
      for (const entry of entries) {
        const { width } = entry.contentRect;
        if (width) {
          updateArrows();
        }
      }
    },
  );

  useEffect(() => {
    if (scrollWrapperRef.current) {
      ro.observe(scrollWrapperRef.current);
    }
  }, [scrollWrapperRef]);

  const centerScroll = () => {
    if (scrollWrapperRef.current) {
      const lastElement = scrollWrapperRef.current?.lastElementChild as HTMLElement;
      const left = (lastElement.offsetLeft + lastElement.clientWidth - scrollWrapperRef.current.clientWidth) / 2;
      scrollWrapperRef.current?.scrollTo({ behavior: 'smooth', left });
    }
  };

  const arrowHeight = useCallback(() => {
    if (
      arrowStyleTypes.Tabs === typeArrow &&
      scrollWrapperRef &&
      arrowLeftRef.current &&
      arrowRightRef.current &&
      parentWrapperRef &&
      parentWrapperRef?.current
    ) {
      const childrenHeight = scrollWrapperRef?.current?.children[1]?.clientHeight;
      if (childrenHeight) {
        arrowLeftRef.current.style.height = `${childrenHeight}px`;
        arrowRightRef.current.style.height = `${childrenHeight}px`;
      }
      const wrapperTop = parentWrapperRef?.current?.getBoundingClientRect();
      const childrenTop = scrollWrapperRef?.current?.children[1]?.getBoundingClientRect();
      childrenTop && wrapperTop && (arrowLeftRef.current.style.top = `${childrenTop?.top - wrapperTop?.top}px`);
      childrenTop && wrapperTop && (arrowRightRef.current.style.top = `${childrenTop?.top - wrapperTop?.top}px`);
    }
  }, []);

  // used by NavSlider to scroll to the tab with active class OR to the item with 0 index (slider`s beginning);
  // centerScrollEventName`s event occurs when clicking on:
  // - sport from Drawer (DrawerSectionNavLink) -> scrolls to the tab with active class
  // - HOME link from Drawer (DrawerNavLinkDefault) -> scrolls to the beginning
  // - SPORT and eSPORT CustomNavLink form TopNavBar -> scrolls to the beginning
  // callback`s functionality is in setTimeout() to let React update style classes before applying scrolling
  // without setTimeout the callback is triggered while there is still an active class on the previously chosen tab - thus it doesn`t scroll
  useLayoutEffect(() => {
    if (!centerScrollEventName) {
      return;
    }

    const callback = () => {
      setTimeout(() => {
        centered && centerScroll();
        activeClass && scrollToActiveElement(scrollWrapperRef.current, activeClass);
        updateArrows();
      }, 0);
    };

    document.addEventListener(centerScrollEventName, callback, false);
    return () => {
      document.removeEventListener(centerScrollEventName, callback);
    };
  }, [centerScrollEventName]);

  useEffect(() => {
    centered && centerScroll();
    activeClass && scrollToElemOnChildrenChange && scrollToActiveElement(scrollWrapperRef.current, activeClass);
    updateArrows();
  }, [loadItems, children]);

  useEffect(() => {
    arrowHeight();
  }, [loadItems, children, arrowLeftRef, arrowRightRef, scrollWrapperRef, showArrows, arrowHeight, typeArrow]);

  const scrollToRight = () => {
    scrollEndedRef.current && scrollWithDirection(false);
  };

  const scrollToLeft = () => {
    scrollEndedRef.current && scrollWithDirection(true);
  };

  const scrollWithDirection = (left: boolean) => {
    const currentPosition = scrollWrapperRef.current!.scrollLeft;
    const moveWithPixes = left ? -moveWith : moveWith;
    const targetPosition = currentPosition + moveWithPixes;
    if (isMacOs) {
      smoothscroll.polyfill();
      scrollWrapperRef.current?.scrollTo({ behavior: 'smooth', left: targetPosition });
      return;
    }
    if (!isMacOs) {
      scrollWrapperRef.current?.scrollTo({ behavior: 'smooth', left: targetPosition });
      return;
    }
    // scrollVerticalTickToPosition(currentPosition, targetPosition);
  };

  const verifyArrows = () => {
    const currentPosition = scrollWrapperRef.current!.scrollLeft;
    refreshArrows(currentPosition);
  };

  const [isDisabledLeft, setIsDisabledLeft] = useState(true);
  const [isDisabledRight, setIsDisabledRight] = useState(false);

  const refreshArrows = (targetPosition: number) => {
    const lastElement = scrollWrapperRef.current?.lastElementChild as HTMLElement;
    arrowHeight();
    if (
      targetPosition + scrollWrapperRef.current!.clientWidth >=
      lastElement?.offsetLeft + lastElement?.offsetWidth - 1
      // lastElement?.offsetLeft + margin + regularDesktopCellWidth
    ) {
      staticArrow ? setIsDisabledRight(true) : arrowRightRef.current?.classList.add('hide');
    } else {
      staticArrow ? setIsDisabledRight(false) : arrowRightRef.current?.classList.remove('hide');
    }
    if (targetPosition <= 0) {
      staticArrow ? setIsDisabledLeft(true) : arrowLeftRef.current?.classList.add('hide');
    } else {
      staticArrow ? setIsDisabledLeft(false) : arrowLeftRef.current?.classList.remove('hide');
    }
  };

  const props = { wrapperref: scrollWrapperRef };
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }
    return child;
  });

  const onScroll = () => {
    isDesktop && verifyArrows();
  };

  const wrapperClass = clsx(
    `scroll-container-wrapper`,
    setFullHeightArrow && 'h-100',
    staticArrow && 'd-flex',
    wrapperClassName,
    (!showArrows || !isDesktop) && setFullHeightArrow && 'scroll-container-wrapper__pr-10',
  );

  const innerClass = clsx(
    `scroll-container-inner`,
    classNames,
    setFullHeightArrow && 'align-self-center',
    staticArrow && 'scroll-container-inner__always-visible-arrows',
  );

  const arrowClass = clsx(
    `${typeArrow}-grid-arrow-style`,
    arrowStyle === 'light' && `${typeArrow}-grid-arrow-style__light`,
    staticArrow && `${typeArrow}-grid-arrow-style__static`,
  );

  return (
    <div ref={triggerRef} className={wrapperClass}>
      {showArrows && (
        <Desktop>
          <div
            className={`scroll-button left-arrow ${staticArrow ? isDisabledLeft && 'disabled' : 'hide'} ${arrowClass}`}
            ref={arrowLeftRef}
            onClick={scrollToLeft}
          >
            <div className="arrow-icon">
              <DefaultChevron direction="left" className="icon left-direction" />
            </div>
          </div>
        </Desktop>
      )}

      <div className={innerClass} ref={scrollWrapperRef} id={id} onScroll={onScroll} onClick={onNavigate}>
        {childrenWithProps}
      </div>
      {centered && (
        <>
          <div className="left-shadow" />
          <div className="right-shadow" />
        </>
      )}
      {showArrows && (
        <Desktop>
          <div
            className={`scroll-button right-arrow  ${staticArrow && isDisabledRight && 'disabled'} ${arrowClass}`}
            ref={arrowRightRef}
            onClick={scrollToRight}
          >
            <div className="arrow-icon">
              <DefaultChevron className="icon right-direction" />
            </div>
          </div>
        </Desktop>
      )}
    </div>
  );
};

export default ScrollContainer;
