import { createSlice } from '@reduxjs/toolkit';
import { FormFieldTypes } from 'components/shared/forms/formTypes';
import { setPlayerId, setPlayerPhoneNumber } from 'pages/auth/phoneConfirmation/slice/phoneConfirmation.slice';
import { selectGeneral, setNetAppHeader } from 'shared/common/features/general/slice/general.slice';
import { MFA_Channels } from 'shared/common/features/myProfile/types/myProfile.types';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError, isEmpty } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { ForgotPassword } from '../types/forgotPassword.types';

const initialState: ForgotPassword.State = {
  submitError: null,
  fetchError: null,
  sendedEmail: false,
  fields: [],
  loading: 'idle',
  mfaCoolOff: '',
  isValidMfaCode: null,
  isLoadingCheckCode: false,
  mfaChannel: '',
  email: '',
  phoneNumber: '',
};

export const fetchForgotPassword = createAbortThunk('fetchForgotPassword', async (_, { rejectWithValue, dispatch }) => {
  try {
    const url = `${config.API_URL}/api/ews-crm/public/cms/player-content?key=forgot_password_form`;
    const response = await axiosInstance.get(url);
    if (response.headers?.['x-platform-hash-netapp']) {
      dispatch(setNetAppHeader(response.headers['x-platform-hash-netapp']));
    }

    return response.data;
  } catch (err) {
    return rejectWithValue(getError.responseDataMessage(err));
  }
});

export const requestChangePassword = createAbortThunk<
  { tokenExpiryDate?: string; phoneNumber?: string; email?: string; mfaChannel?: MFA_Channels },
  ForgotPassword.ChangePassArgs,
  string
>('requestChangePassword', async ({ data, language, mfaCode }, { rejectWithValue, getState }) => {
  try {
    const dataModified = {
      ...data,
      ...(data.birthday && { birthDate: data.birthday }),
      ...(mfaCode ? { mfaCode } : {}),
    };
    const url = `${config.API_URL}/api/ews-crm/public/users/send-forgotten-password?lang=${language}`;
    const netAppHeader = selectGeneral.netAppHeader(getState());
    const headers = netAppHeader ? { 'x-platform-hash-netapp': netAppHeader } : {};
    const response = await axiosInstance.post(url, dataModified, { headers });
    return response.data;
  } catch (err) {
    return rejectWithValue(getError.responseDataMessage(err));
  }
});

export const requestChangePasswordSms = createAbortThunk<
  { phoneNumber: string; playerId: string; receiveNewCodeDate: string; tokenExpiryDate: string },
  ForgotPassword.ChangePassArgs,
  string
>('requestChangePasswordSms', async ({ data }: ForgotPassword.ChangePassArgs, { rejectWithValue, dispatch }) => {
  try {
    const dataModified = {
      ...data,
      ...(data.birthday && { birthDate: data.birthday }),
    };
    const url = `${config.API_URL}/api/ews-crm/public/users/send-forgotten-password-sms`;
    const response = await axiosInstance.post(url, dataModified);
    if (response.data) {
      response.data.phoneNumber && dispatch(setPlayerPhoneNumber(response.data.phoneNumber));
      dispatch(setPlayerId(response.data.playerId));
    }
    return response.data;
  } catch (err) {
    return rejectWithValue(getError.responseDataMessage(err));
  }
});

const forgotPasswordSlice = createSlice({
  name: 'forgot-password',
  initialState,
  reducers: {
    resetMfaCheckCode(state) {
      state.mfaCoolOff = '';
      state.isValidMfaCode = null;
      state.mfaChannel = '';
      state.email = '';
      state.phoneNumber = '';
    },
    resetForm: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchForgotPassword.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchForgotPassword.fulfilled, (state, action) => {
        state.fields = action.payload;
        state.loading = 'succeeded';
      })
      .addCase(fetchForgotPassword.rejected, (state, action) => {
        state.fetchError = action.payload as string;
        state.loading = 'failure';
      });

    builder
      .addCase(requestChangePassword.pending, (state, action) => {
        if (action.meta.arg?.isInitial) {
          // Set loading state only for initial request
          state.loading = 'pending';
        }
        state.isLoadingCheckCode = true;
        state.submitError = null;
        state.isValidMfaCode = null;
      })
      .addCase(requestChangePassword.fulfilled, (state, action) => {
        if (!isEmpty(action.payload)) {
          // The api return time for code validation
          if (action.payload?.tokenExpiryDate) state.mfaCoolOff = action.payload?.tokenExpiryDate;
          if (action.payload?.mfaChannel) state.mfaChannel = action.payload?.mfaChannel;
          if (action.payload?.email) state.email = action.payload?.email;
          if (action.payload?.phoneNumber) state.phoneNumber = action.payload?.phoneNumber;
        } else {
          state.sendedEmail = true;
          state.isValidMfaCode = true;
        }
        state.isLoadingCheckCode = false;
        state.loading = 'succeeded';
      })
      .addCase(requestChangePassword.rejected, (state, action) => {
        if (action.meta.arg?.isInitial) {
          // Set submit error if entered email is not valid
          state.submitError = action.payload as string;
          state.loading = 'failure';
        } else {
          // else entered mfa code is not valid
          state.isValidMfaCode = false;
          state.isLoadingCheckCode = false;
        }
      });

    builder
      .addCase(requestChangePasswordSms.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(requestChangePasswordSms.fulfilled, (state) => {
        state.loading = 'succeeded';
      })
      .addCase(requestChangePasswordSms.rejected, (state, action) => {
        state.submitError = action.payload as string;
        state.loading = 'failure';
      });
  },
});

export const { resetForm, resetMfaCheckCode } = forgotPasswordSlice.actions;

export const selectForgotPassword = {
  submitError: (state: RootState): null | string => state.auth.forgotPassword.submitError,
  fetchError: (state: RootState): null | string => state.auth.forgotPassword.fetchError,
  sendedEmail: (state: RootState): boolean => state.auth.forgotPassword.sendedEmail,
  isLoadingCheckCode: (state: RootState) => state.auth.forgotPassword.isLoadingCheckCode,
  mfaCoolOff: (state: RootState) => state.auth.forgotPassword.mfaCoolOff,
  mfaChannel: (state: RootState) => state.auth.forgotPassword.mfaChannel || 'email',
  phoneNumber: (state: RootState) => state.auth.forgotPassword.phoneNumber,
  email: (state: RootState) => state.auth.forgotPassword.email,
  isValidMfaCode: (state: RootState) => state.auth.forgotPassword.isValidMfaCode,
  fields: (state: RootState): FormFieldTypes[] => state.auth.forgotPassword.fields,
  isLoading: (state: RootState): boolean =>
    state.auth.forgotPassword.loading === 'pending' || state.auth.forgotPassword.loading === 'idle',
};

export default forgotPasswordSlice.reducer;
