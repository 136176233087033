import { useEffect, useCallback, useState } from 'react';
import { UseFormReset } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FieldValues } from 'components/shared/forms/formTypes';
import { useAuthentication } from 'hooks/common/useAuthentication';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { MFA_Channels } from 'shared/common/features/myProfile/types/myProfile.types';
import { hideRootGrandModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { replaceStringTemplate } from 'utils/common/helpersCommon';
import { MyAccountTranslation } from 'utils/common/translationUtils/translationStrings';
import { getUserLocalTime } from 'utils/dateUtils';
import {
  PhoneConfirmationThunks,
  resetConfirmationError,
  resetPhoneConfirmationState,
  selectPhoneConfirmation,
  setCommunicationChannel,
} from '../slice/phoneConfirmation.slice';
import { PhoneConfirmationTypes } from '../types/phoneConfirmation.types';

export const usePhoneConfirmation = ({
  type,
  reset,
  isResetPassword,
  data,
  channels,
}: {
  data?: FieldValues;
  type: PhoneConfirmationTypes.VerificationType;
  reset: UseFormReset<FieldValues>;
  isResetPassword: boolean;
  channels: MFA_Channels[];
}) => {
  const [step, setStep] = useState(0);
  //
  const phoneCoolOff = useAppSelector(selectPhoneConfirmation.phoneCoolOff);
  const confirmationError = useAppSelector(selectPhoneConfirmation.error);
  const isLoading = useAppSelector(selectPhoneConfirmation.isLoadingCheckCode);
  const attemptsLeft = useAppSelector(selectPhoneConfirmation.attemptsLeft);
  const hasAttemptsLeft = useAppSelector(selectPhoneConfirmation.hasAttemptsLeft);
  const phoneNumber = useAppSelector(selectPhoneConfirmation.phoneNumber);
  const playerId = useAppSelector(selectPhoneConfirmation.playerId);
  // Case Extended MFA (BR)
  const isMfaEnabledExtended = useAppSelector(selectGeneral.isMfaEnabledExtended);
  const channel = useAppSelector(selectPhoneConfirmation.channel);

  const channelIdx = channels?.indexOf(channel) || 0;
  const nextChannel = channels?.[channelIdx + 1] || '';
  //

  const { isAuthenticated } = useAuthentication();
  const navigate = useNavigate();
  const isTimeFinished =
    type === 'mfa_extended'
      ? new Date().getTime() > new Date(phoneCoolOff).getTime()
      : getUserLocalTime() > new Date(phoneCoolOff).getTime();
  const [timerIsFinished, setTimerIsFinished] = useState<boolean>(isTimeFinished);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // TO DO check isNewCodeRequest is correct value?
    type !== 'mfa_extended' &&
      dispatch(
        PhoneConfirmationThunks.generatePhoneVerificationCode({
          type,
          playerId,
          isNewCodeRequest: true,
          phoneNumber,
          ...(isMfaEnabledExtended ? { channel } : {}),
        }),
      );
  }, [dispatch, type, playerId, phoneNumber, channel, isMfaEnabledExtended]);

  useEffect(() => {
    return () => {
      dispatch(resetPhoneConfirmationState());
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      if (channel) reset();
    };
  }, [reset, channel]);

  useEffect(() => {
    if (hasAttemptsLeft === false) {
      setTimerIsFinished(true);
    }
  }, [hasAttemptsLeft]);

  const errorMessage = replaceStringTemplate({
    template: t(confirmationError),
    placeholder: '<<attemptsLeft>>',
    value: attemptsLeft,
    fallback: t(MyAccountTranslation.PhoneConfirmation.wrongCode),
    regex: /<<\w+>>/g,
  });

  const isFormDisabled = !!errorMessage || isLoading || timerIsFinished;

  const handleSendCode = useCallback(
    (fields: FieldValues) => {
      const isLastStep = step === channels?.length - 1;
      const closeAction = () => {
        dispatch(hideRootGrandModal());
        if (!isAuthenticated) {
          const route = isResetPassword ? '/reset-password' : '/confirmation';
          navigate(route, { state: { playerId, verificationCode: fields.confirmationCode, phoneNumber } });
        }
      };
      if (!isFormDisabled && fields.confirmationCode) {
        dispatch(
          PhoneConfirmationThunks.checkPhoneVerificationCode({
            type,
            playerId,
            phoneNumber,
            isAuthenticated: !!isAuthenticated,
            closeAction,
            isResetPassword,
            verificationCode: fields.confirmationCode,
            isLastStep,
            ...(isLastStep && data ? data : {}),
            ...(isMfaEnabledExtended ? { nextChannel } : {}),
          }),
        ).then((action) => {
          if (action.meta.requestStatus === 'fulfilled') {
            if (!isLastStep) {
              setStep(step + 1);
              isMfaEnabledExtended && dispatch(setCommunicationChannel(nextChannel));
              reset();
            }
          }
        });
      }
    },
    [
      dispatch,
      reset,
      isFormDisabled,
      playerId,
      type,
      isAuthenticated,
      isResetPassword,
      phoneNumber,
      navigate,
      step,
      nextChannel,
      data,
      channels,
      isMfaEnabledExtended,
    ],
  );

  const onChangeCallback = useCallback(() => {
    errorMessage && dispatch(resetConfirmationError());
  }, [dispatch, errorMessage]);

  const timerFinnishCallback = useCallback(
    (isDone) => {
      setTimerIsFinished(isDone);
      errorMessage && dispatch(resetConfirmationError());
      reset();
    },
    [reset, dispatch, errorMessage],
  );

  const resetTimerCallback = useCallback(() => {
    setTimerIsFinished(false);
    errorMessage && dispatch(resetConfirmationError());
    reset();
  }, [dispatch, errorMessage, reset]);

  return {
    handleSendCode,
    onChangeCallback,
    timerFinnishCallback,
    resetTimerCallback,
    timerIsFinished,
    errorMessage,
    isFormDisabled,
    step,
  };
};
