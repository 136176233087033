import { createSelector, createSlice } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';
import { Casino } from 'modules/casino/shared/types';
import axiosInstance from 'modules/casino/shared/utils/common/axios-instance';
import { getOSType } from 'modules/casino/shared/utils/common/helpersCommon';
import { createAbortThunk } from 'modules/casino/store/thunkCreators';
import { RootState } from 'store/rootReducer';
import config from 'utils/config';
import { CasinoLobbyTypes } from '../types/casinoLobbyTypes';
import { getNormalizedLobbyData, lobbyItemsFilterConditions } from '../utils/casinoLobbyUtils';

export const casinoLobbyThunks: CasinoLobbyTypes.CasinoLobbyThunks = {
  fetchLobbyItemsByType: createAbortThunk(
    'casinoLobby/fetchLobbyItemsByType',
    async ({ apiUri, casinoType }, { source, getState }) => {
      const language = getState().common.settings.language;
      const key = `${language}_${casinoType}`;
      const fetchURL = `${config.API_URL}${apiUri}`;
      const response = await axiosInstance.get<Casino.LobbyCategory[]>(fetchURL, {
        data: null,
        headers: { 'X-Platform-OS-type': getOSType() },
        cancelToken: source.token,
      });

      response.data.sort(function (a, b) {
        return a.rank - b.rank;
      });

      return { key: casinoType, lobbyCacheKey: key, content: response.data };
    },
  ),
};

export const { fetchLobbyItemsByType } = casinoLobbyThunks;

const initialState: CasinoLobbyTypes.State = {
  lobbyItems: {},
  isLoading: false,
};
const casinoLobby = createSlice({
  name: 'casinoLobby',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLobbyItemsByType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLobbyItemsByType.fulfilled, (state, action) => {
        const { lobbyCacheKey, content } = action.payload;
        const lobbyItem = { [lobbyCacheKey]: getNormalizedLobbyData(content) };
        state.lobbyItems = { ...state.lobbyItems, ...lobbyItem };
        state.isLoading = false;
      })
      .addCase(fetchLobbyItemsByType.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

const selectLobbyItems = createCachedSelector(
  (state: RootState, lobbyId: string): CasinoLobbyTypes.LobbyItems => state.casino.casinoLobby.lobbyItems[lobbyId],
  (state: RootState, lobbyId: string, isAuthenticated): boolean => isAuthenticated,
  (lobbyItems, isAuthenticated): CasinoLobbyTypes.LobbyItems => {
    if (lobbyItems?.categories && lobbyItems?.categoryIds) {
      const filteredIds = lobbyItems.categoryIds.filter((id) => {
        const category = lobbyItems.categories[id];

        return lobbyItemsFilterConditions({
          queryType: category.queryType,
          categoryIsPublic: category.isPublic,
          isAuthenticated,
        });
      });

      return {
        categoryIds: filteredIds,
        categories: lobbyItems.categories,
        ttl: lobbyItems.ttl,
        refetchLobby: lobbyItems.refetchLobby,
      };
    }
    return lobbyItems || [];
  },
)((_, id, isAuthenticated) => `${id}_${isAuthenticated ? '1' : '0'}`);

const selectLobbyCategory = createCachedSelector(
  (state: RootState, lobbyId: string): CasinoLobbyTypes.LobbyItems => state.casino.casinoLobby.lobbyItems[lobbyId],
  (state: RootState, lobbyId: string, categoryId): string => categoryId,
  (lobbyItems, categoryId): Casino.LobbyCategory => lobbyItems?.categories[categoryId],
)((_, id) => id);

const selectLobbyCategoryIds = createSelector(
  selectLobbyItems,
  (lobbyItems): string[] => lobbyItems?.categoryIds || [],
);

const selectLobbyIsLoading = (state: RootState): boolean => state.casino.casinoLobby.isLoading;

export const casinoLobbySelectors = {
  lobbyItems: selectLobbyItems,
  lobbyCategory: selectLobbyCategory,
  lobbyIsLoading: selectLobbyIsLoading,
  lobbyCategoryIds: selectLobbyCategoryIds,
};

export default casinoLobby.reducer;
