import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { keepMyProfileModalShown, showMyProfileModal } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch, useAppSelector } from 'store';
import usePostBonusActions from './usePostBonusActions';
import { setReadNotificationById } from '../../myProfile/sections/tabs/notificationsTab/notificationSlice';
import { NotificationTypes } from '../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import { postBonus, selectOffersTab } from '../../myProfile/sections/tabs/offersTab';
import { BonusButtonAction } from '../../myProfile/sections/tabs/offersTab/types/offersTab.types';

type Props = {
  bonus?: NotificationTypes.Bonus;
  notificationId: string;
};

const useHandleBonusActions = ({ bonus, notificationId }: Props) => {
  const { id = '', bonusCode, buttonActionType, buttonUrl, categoryAlias = '', bonusType } = bonus || {};
  const { error, status, statusCode } = useAppSelector((state) => selectOffersTab.bonusStatusAndError(state, id));

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // usePostBonusActions - actions to redirect to my offers if bonus is accepted
  usePostBonusActions({ categoryAlias, bonusId: id, notificationId, bonusType });

  const handleOfferAction = useCallback(() => {
    if (buttonActionType === BonusButtonAction.Navigate) {
      dispatch(keepMyProfileModalShown(false));
      dispatch(showMyProfileModal(false));
      dispatch(setReadNotificationById({ notificationId, notificationType: 'popup' }));
      dispatch(hideRootModal());

      buttonUrl &&
        navigate(buttonUrl, { state: { promotionId: `${id}`, from: `${location.pathname}${location.search}` } });
    } else if (buttonActionType === BonusButtonAction.Accept) {
      if (buttonUrl && bonusCode) {
        dispatch(postBonus({ buttonUrl, id: `${id}`, bonusCode, hideModal: false }));
      }
    }
  }, [notificationId, buttonActionType, buttonUrl, id, bonusCode, dispatch, navigate]);

  return { handleOfferAction, error: t(error || ''), status, statusCode };
};

export default useHandleBonusActions;
