import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InGameCategoryWrapper from 'modules/casino/modules/container/cellsContainer/containers/external/InGameCategories/InGameCategoryWrapper';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import CasinoContainerImplementation from 'modules/casino/platformImplementation/casinoWidgetTypes/CasinoContainerImplementation';
import useCasinoWidgetConfig from 'modules/casino/platformImplementation/hooks/useCasinoWidgetConfig';
import { WidgetContainerTypes } from 'modules/casino/platformImplementation/types/casinoWidget.types';
import CasinoSearch from 'modules/casino/shared/features/casinoSearch';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { TopBarButton } from '../TopBarButton';
import { TopBarPopover } from '../TopBarPopover';

type Props = {
  type: string;
  text?: string;
  icon?: string;
};

export const SearchButton = ({ type, text, icon }: Props) => {
  const { t } = useTranslation();
  const [showCat, setShowCat] = useState(false);
  const casinoWidgetConfig = useCasinoWidgetConfig();

  const handleSearch = () => {
    setShowCat(true);
  };

  const SearchComponent = casinoWidgetConfig ? (
    <CasinoContainerImplementation
      containerKey={'in-game-casino-search'}
      containerType={WidgetContainerTypes.CATEGORY}
      params={{
        categoryType: CategoryLayoutTypes.EXTERNAL_CATEGORY,
        subType: CategorySubTypes.IN_GAME_SEARCHED_RESULTS,
      }}
    />
  ) : (
    <InGameCategoryWrapper>
      <div className={`${cssGlobalNS}-gl__popover__search--title`}>{t('GameLaunch.Search.title')}</div>
      <CasinoSearch />
    </InGameCategoryWrapper>
  );

  return (
    <TopBarButton
      type={type}
      handleClick={handleSearch}
      icon={icon}
      text={text && t(text)}
      renderChildren={(btnRef, handleClose, show) =>
        showCat &&
        show && (
          <TopBarPopover
            handleClose={handleClose}
            parentRef={btnRef}
            show={show}
            additionalClass={`${cssGlobalNS}-gl__popover__search`}
          >
            {SearchComponent}
          </TopBarPopover>
        )
      }
    />
  );
};
