import React, { memo } from 'react';
import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import CurrencySwapper from 'modules/casino/shared/components/common/CurrencySwapper';
import Icon from 'modules/casino/shared/components/icons/Icon';
import { useCurrencyConfig } from 'modules/casino/shared/hooks/useCurrencyConfig';
import { formatSumAmount } from 'modules/casino/shared/utils/helpersCasino';
import { Theme } from 'modules/casino/theme/Theme';
import { useGameLimits } from './hooks/useGameLimits';
import { useDataWrapper } from '../hooks/useDataWrapper';

interface Props {
  gameUniqueId: string;
  categoryId: string;
  icon?: string;
  iconPath?: string;
}

const GameCellTableLimits: React.FC<Props> = ({
  gameUniqueId,
  categoryId,
  icon = 'instant-game',
  iconPath = Theme.icons.liveCasinoGames,
}) => {
  const game = useGameProps({ gameUniqueId, categoryId });
  const { showTableLimits, gameData } = useDataWrapper({ game });
  const { betLimits, isFast } = gameData;
  const { limits, symbol } = useGameLimits(betLimits, gameData.gameCurrency);
  const { formatToComma, currencySymbolFirst, spaceBetween } = useCurrencyConfig();

  if (!showTableLimits || !limits?.min || !limits?.max) {
    return null;
  }

  return (
    <div className={'tag-limits'}>
      {isFast && (
        <>
          <Icon className="tag-limits__fast-game-icon" iconPath={iconPath} icon={icon} />{' '}
          <span className="tag-limits__separator"></span>
        </>
      )}

      <CurrencySwapper
        sumComponent={
          <>
            {formatSumAmount({ sum: limits?.min.toFixed(2), formatToComma })}
            <span className="tag-limits__max">-{formatSumAmount({ sum: limits?.max, formatToComma })}</span>
          </>
        }
        currencyComponent={<span className="tag-limits__symbol">{symbol}</span>}
        currencySymbolFirst={currencySymbolFirst}
        spaceBetween={spaceBetween}
      />
    </div>
  );
};

export default memo(GameCellTableLimits);
