import React, { useEffect, memo, useCallback } from 'react';
import i18n from 'i18next';
import { useErrorBoundary } from 'react-error-boundary';
import { Preloader } from 'components/shared/loaders';
import { useOnOff } from 'hooks/common/useOnOff';
import { getI18n } from 'i18n';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { selectLanguage } from 'shared/common/features/settings/slice/settings.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { selectLocalizationUpdated, updateLocalizationEnv } from './slice/localization.slice';
import { selectNavigationMenu } from '../navigationMenu/slice/navigationMenu.slice';

interface Props {
  children: React.ReactNode;
}

const Localization: React.FC<Props> = ({ children }) => {
  const [loaded, setLoaded] = useOnOff();
  const [error, setError] = useOnOff();
  const updated = useAppSelector(selectLocalizationUpdated);
  const language = useAppSelector(selectLanguage);
  const preloaderVisibility = useAppSelector(selectAuthentication.preloaderVisibility);
  const navigationLoading = useAppSelector(selectNavigationMenu.navigationLoading);
  const dispatch = useAppDispatch();
  const { showBoundary } = useErrorBoundary();

  const setInitialized = useCallback(() => {
    const translationsLoaded = i18n.hasResourceBundle(language, 'translation');

    if (translationsLoaded) {
      setLoaded(true);
      setError(false);
    } else {
      setError(true);
    }
  }, [language, setError, setLoaded]);

  useEffect(() => {
    setLoaded(false);
    setError(false);
    dispatch(updateLocalizationEnv());
    getI18n(language, dispatch);
    i18n.on('initialized', setInitialized);

    return () => {
      i18n.off('initialized');
    };
  }, [dispatch, language, setError, setInitialized, setLoaded]);

  useEffect(() => {
    // if only TZ is changed
    if (!updated && loaded) {
      dispatch(updateLocalizationEnv());
    }
  }, [dispatch, loaded, updated]);

  if (error) {
    showBoundary(new Error());
  }

  if (!loaded || !updated || preloaderVisibility || navigationLoading) {
    return <Preloader />;
  }

  return <>{updated ? children : null}</>;
};
export default memo(Localization);
