import React, { useLayoutEffect, useRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { LiveSports } from 'utils/common/translationUtils/translationStrings';
import { EventSelectors } from '../types/eventProps.types';

/** EventUI Score Change Indicator */
interface ScoreChangeProps {
  hasGoal?: boolean;
  // TODO support both props
  isHome?: boolean;
  eventSelectors?: EventSelectors;
  sportId?: number;
  sportEventId?: number;
}

export const ScoreChangeIndicator: React.FC<ScoreChangeProps> = ({
  hasGoal,
  sportEventId,
  sportId,
  isHome,
  eventSelectors,
}) => {
  const scoreChange = useAppSelector((state) =>
    eventSelectors && sportEventId ? eventSelectors.scoreChange(state, sportEventId, sportId) : undefined,
  );
  const { t } = useTranslation();
  const teamIndicator = isHome ? 'home' : 'away';
  const hasGoalIndicator = hasGoal || scoreChange === teamIndicator;
  const indicatorClass = clsx('badge badge-warning ml-1 badge-goal', hasGoalIndicator ? 'active' : 'd-none');
  const refDiv = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    setTimeout(() => {
      return hasGoalIndicator && refDiv.current?.classList.add('d-none');
    }, 4000);
  }, [hasGoalIndicator]);

  return (
    <div className={indicatorClass} ref={refDiv}>
      {t(LiveSports.EventsHeader.hasGoal)}
    </div>
  );
};
