import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setChatLanguage, setZopimContent } from './utils';

const PreloaderSupportChat = () => {
  const { t } = useTranslation();
  const zopim = window['$zopim'];
  const isZopim = zopim && zopim.livechat && typeof zopim.livechat.init == 'function' ? true : false;

  useEffect(() => {
    if (isZopim) {
      setChatLanguage();
      setZopimContent(t);
    }
  }, []);
  return null;
};
export default PreloaderSupportChat;
