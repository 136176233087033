import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';

export const useHideCookieConfig = () => {
  const hideCookie = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.HIDE_COOKIE,
    deviceLayout: 'general',
  });

  return hideCookie;
};
