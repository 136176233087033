import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import cachios from 'cachios';
import CryptoJS from 'crypto-js';
import { Options } from 'node-cache';
import { isIOS } from 'react-device-detect';
import { retailConfig } from 'modules/retail/shared/utils/excludeApiConfig';
import { showQuickLoginForm } from 'pages/auth/login/slice/login.slice';
import userStorage from 'pages/auth/login/UserStorage';
import { selectDeviceType } from 'shared/common/features/general/slice/deviceType.slice';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { logoutOnError } from 'shared/common/sharedSlices/commonActions';
import { store } from 'store';
import { getBusinessUnit, isCashier, isJson, isTerminal } from './helpersCommon';

const JSONbig = require('json-bigint')({ storeAsString: true });
const baseHeaders = {
  'Content-Type': 'application/json',
};
const gamingUrl = 'gaming/public';
const getIsPublicGamingUrl = (url: string | undefined) =>
  url?.includes(gamingUrl) && !url?.includes('launchUrl?device');
const secret = 'amazingsecret';
const tokenExpiration = 30 * 60 * 1000; //30 min
export const getHashHeader = () => {
  const expiry = Date.now() + tokenExpiration;
  const expiryInSeconds = Math.round(expiry / 1000);
  const hash = `${secret}${expiryInSeconds}`;
  const md5 = CryptoJS.MD5(hash).toString(CryptoJS.enc.Base64);
  const hashFinal = md5.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
  return { hash: hashFinal, expiry: expiryInSeconds };
};

export const getMidHeader = () => {
  const existingValue = localStorage.getItem('mid');
  if (existingValue) return existingValue;

  return null;
};

const axiosInstance: AxiosInstance = axios.create();
axiosInstance.defaults.transformResponse = (data) => (isJson(data) ? JSONbig.parse(data) : data);

axiosInstance.defaults.headers.common = baseHeaders;

axiosInstance.interceptors.request.use((config) => {
  const user = userStorage.getUser();
  const userTZ = userStorage.getUserTimeZone();
  const userTZCode = userStorage.getUserIanaTimeZoneCode() || userStorage.getAutomaticTimeZone()?.tz;
  const withDefaultUserTZValue = config?.url?.includes('player-tournaments/get-players-points');
  const device = selectDeviceType.deviceType(store.getState());
  const isRetailDevice = window.config.CHANNEL === 'RETAIL';
  const deviceType =
    window.config.CHANNEL === 'RETAIL' && window.config.DEVICE_TYPE === 'Handheld'
      ? 'handheld'
      : window.config.CHANNEL === 'RETAIL' && (window.config.DEVICE === 'ONLINE' || device === 'mobile')
        ? device
        : window.config.CHANNEL === 'RETAIL'
          ? 'terminal'
          : device;
  const tzOffset = (new Date().getTimezoneOffset() * -1)?.toString() || '';

  // TODO: for terminal development
  // const deviceType = 'terminal';
  config.headers = config.headers || {};
  config.headers['X-Platform-Lang'] = userStorage.getUserLang() || 'en';
  config.headers['X-Platform-TZ'] = withDefaultUserTZValue && userTZ === null ? tzOffset : userTZ || tzOffset;
  config.headers['X-Platform-Device'] = config.headers['X-Platform-Device'] ?? deviceType;
  if (userTZCode) {
    config.headers['X-Platform-Timezone'] = userTZCode;
  }

  const terminalId = user?.terminal_id;
  const posId = user?.pos_id;

  if (terminalId) {
    config.headers['X-Retail-TerminalId'] = terminalId;
  }

  if (posId) {
    config.headers['X-Retail-PosId'] = posId;
  }

  const { hash, expiry } = getHashHeader();

  config.headers['X-Platform-Hash'] = hash;
  config.headers['X-Platform-Exp'] = expiry;

  const url = config.url || '';
  const isExcluded = retailConfig.retail.excludeApis.some((pattern) => new RegExp(pattern).test(url));
  const excludePromotionApi = '/api/ews-bonuses/public/promotions';
  const isSSBTOrCashier = isCashier() || isTerminal();
  if (isRetailDevice && isExcluded) {
    return Promise.reject();
  }
  if (url.includes(excludePromotionApi) && isSSBTOrCashier) {
    return Promise.reject();
  }

  if (window.isRNWebView) {
    config.headers['x-platform-application'] = isIOS
      ? window?.appVersion
        ? `ios-${window.appVersion}`
        : 'ios'
      : `android-${window?.appVersion}`;
  }

  if (user && !getIsPublicGamingUrl(config.url)) {
    config.headers.Authorization = `Bearer ${user.access_token}`;
    return config;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      const user = userStorage.getUser();
      user && store.dispatch(logoutOnError());
    } else if (error?.response?.status === 595) {
      window.location.reload();
    } else if (error?.response?.status === 590) {
      window.location.reload();
    } else if (error?.response?.status === 570 && isCashier() && getBusinessUnit() === 'MBRO') {
      store.dispatch(showQuickLoginForm({ status: false }));
      store.dispatch(
        showRootModal({
          modalId: 'certificate',
          modalType: 'RETAIL_CERTIFICATE',
          modalPriority: ModalPriorityEnum.GRAND,
        }),
      );
    } else {
      return Promise.reject(error);
    }
  },
);

// override cachios types. Update cachios!!! dependancy on lower axios version
declare module 'cachios' {
  //only minor type changes
  export interface CachiosRequestConfigNew extends AxiosRequestConfig {
    ttl?: number;
    force?: boolean;
  }
  export interface CachiosInstanceNew {
    axiosInstance: AxiosInstance;
    get<T = never, R = AxiosResponse<T>>(url: string, config: CachiosRequestConfigNew): Promise<R>;
  }
  export interface CachiosStatic extends CachiosInstanceNew {
    create(axiosInstance: AxiosInstance, nodeCacheConf?: Options): CachiosInstanceNew;
  }
}

export const cachiosInstance = cachios.create(axiosInstance);

export default axiosInstance;
