import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { createAbortThunk, CreateThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { BetHistoryState } from '../../../hooks/BetHistoryStateTypes';
import { MyBets } from '../types/myBets.types';
import { normalizeEventsData } from '../utils/myBets.utils';

export const fetchBetHistory: CreateThunk<BetHistoryState.ThunkArgs, MyBets.ApiResponse, boolean | undefined> =
  createAbortThunk('fetchBetHistory', async (args, { rejectWithValue }) => {
    const { type, pageNumber, fromDate, toDate, isFetchMore } = args;
    const params = { type, pageNumber, sortDirection: 'DESC', fromDate, toDate };

    //do not delete debugging logs
    // console.log('timestamp_from ', fromDate, '---');
    // console.log('timestamp_to ', toDate, '---');
    // console.log('______________________');
    // console.log('UTC date_from ', new Date(queryParams.fromDate).toUTCString(), '---');
    // console.log('UTC date_to ', new Date(queryParams.toDate).toUTCString(), '---');
    // console.log('______________________');
    // console.log('LOCALE date_from ', new Date(queryParams.fromDate).toLocaleString(), '---');
    // console.log('LOCALE date_to', new Date(queryParams.toDate).toLocaleString(), '---');

    try {
      const response = await axiosInstance.get<MyBets.ApiResponse>(
        `${config.API_URL}/api/reporting/player/sport/bet-history`,
        { params },
      );

      return { ...response.data, type };
    } catch (error) {
      return rejectWithValue(isFetchMore);
    }
  });

interface BetHistoryStateType {
  betHistory: MyBets.BetContent[];
  outcomesByEventId: MyBets.NormalizedOutcomeEventMap;
  status: string | null;
  error: null | string | undefined;
  isLoadingMore: boolean;
  currentPage: number;
  totalPages: number;
  activeLiveTrackerItem: Record<string, boolean>;
}

const initialState: BetHistoryStateType = {
  betHistory: [],
  outcomesByEventId: {},
  currentPage: 0,
  totalPages: 0,
  isLoadingMore: false,
  status: 'idle',
  error: null,
  activeLiveTrackerItem: {},
};

const betHistory = createSlice({
  name: 'betHistory',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchBetHistory.pending, (state, action) => {
        if (action.meta?.arg?.isFetchMore) {
          state.isLoadingMore = true;
        } else {
          state.status = 'pending';
        }
      })
      .addCase(fetchBetHistory.fulfilled, (state, action) => {
        const { content, pageable, totalPages } = action.payload;

        state.betHistory = [...state.betHistory, ...content];
        state.outcomesByEventId = normalizeEventsData(state.betHistory);

        state.currentPage = pageable.pageNumber;
        state.totalPages = totalPages;
        state.status = 'succeeded';
        state.isLoadingMore = false;
      })
      .addCase(fetchBetHistory.rejected, (state, action) => {
        if (!action.payload) {
          state.status = 'failure';
        }
        state.error = getError.default();
        state.isLoadingMore = false;
      });
  },
  reducers: {
    clearBetHistory(state) {
      state.betHistory = [];
      state.outcomesByEventId = {};
      state.currentPage = 0;
      state.totalPages = 0;
      state.isLoadingMore = false;
      state.status = 'idle';
      state.error = null;
    },
    clearBetHistoryItems(state) {
      state.betHistory = [];
    },
    setActiveLiveTrackerItem(state, action: PayloadAction<string>) {
      if (state.activeLiveTrackerItem[action.payload]) {
        state.activeLiveTrackerItem = {};
      } else {
        state.activeLiveTrackerItem = { [action.payload]: true };
      }
    },
    resetActiveLiveTrackerItem(state) {
      state.activeLiveTrackerItem = {};
    },
  },
});

export const { clearBetHistory, clearBetHistoryItems, setActiveLiveTrackerItem, resetActiveLiveTrackerItem } =
  betHistory.actions;

export default betHistory.reducer;

export const selectBetHistory = {
  bets: (state: RootState): MyBets.BetContent[] => state.myAccount.betHistory.betHistory,
  currentPage: (state: RootState): number => state.myAccount.betHistory.currentPage,
  totalPages: (state: RootState): number => state.myAccount.betHistory.totalPages,
  status: (state: RootState): string | null => state.myAccount.betHistory.status,
  error: (state: RootState): string | null | undefined => state.myAccount.betHistory.error,
  isLoadingMore: (state: RootState): boolean => state.myAccount.betHistory.isLoadingMore,
  activeLiveTrackerById:
    (uniqueId: string) =>
    (state: RootState): boolean | undefined =>
      state.myAccount.betHistory.activeLiveTrackerItem[uniqueId],
  outcomesByEventId: (sportEventId: number) => (state: RootState) =>
    state.myAccount.betHistory.outcomesByEventId[sportEventId],
};
