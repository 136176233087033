import React, { Dispatch, MouseEventHandler, SetStateAction } from 'react';
import clsx from 'clsx';
import Icon from 'components/shared/Icon';
import { Flex } from 'components/shared/layout';
import { TerminalTheme } from 'modules/retail/modules/ssbt/ssbtConfig';
import Countdown from '../features/cashier-checkTicket/CountDownTimer';

/** Cashier Modal Grid Button Section */
interface GridButtonProps {
  className?: string;
  text: string;
  icon: string;
  secondIcon?: string;
  isActive?: boolean;
  hasCounter?: boolean;
  onClick: (id?: number) => void | (() => void) | MouseEventHandler<HTMLButtonElement>;
  actionButtonClicked?: number[] | null;
  setIsActive?: () => void;
  seconds?: number;
  setSeconds?: Dispatch<SetStateAction<number>>;
  id?: number;
  activeButtons?: number[];
  setActionButtonClicked?: React.Dispatch<React.SetStateAction<null | number[]>>;
  currentClickedActiveBtn?: number;
  setActiveButtons?: any;
}

const GridButton: React.FC<GridButtonProps> = ({
  className,
  text,
  icon,
  secondIcon,
  onClick,
  actionButtonClicked,
  seconds,
  setSeconds,
  id,
  setActionButtonClicked,
  currentClickedActiveBtn,
  setActiveButtons,
}) => {
  const buttonClass = clsx(
    'grid-button grid-button--disable',
    currentClickedActiveBtn === id && actionButtonClicked === null && 'grid-button--active',
    actionButtonClicked === id && currentClickedActiveBtn === id && 'grid-button--counter',
    className,
  );

  return (
    <Flex.ColJC
      className={buttonClass}
      onClick={
        currentClickedActiveBtn === id
          ? () => onClick(id)
          : () => {
              return;
            }
      }
    >
      <Flex.AC className="icon-pad">
        <Icon iconPath={TerminalTheme.icons.ui} icon={icon} />
        {secondIcon && <Icon iconPath={TerminalTheme.icons.ui} icon={secondIcon} />}
      </Flex.AC>
      <Flex.ACJB className="text">
        <span>{text}</span>{' '}
        {actionButtonClicked === id && (
          <Countdown
            seconds={seconds}
            setSeconds={setSeconds}
            setActionButtonClicked={setActionButtonClicked}
            activeButton={id}
            setActiveButtons={setActiveButtons}
          />
        )}
      </Flex.ACJB>
    </Flex.ColJC>
  );
};

export default GridButton;
