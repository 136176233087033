import React from 'react';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from 'theme/Theme';

/** Footer Social Icon */
interface SocialIconProps {
  /** Icon Wrapper CSS Class */
  className?: string;
  /** Icon Source */
  iconSrc: string;
  /** Link HREF */
  linkRoute?: string;
  /** Link Alt. Text */
  alt: string;
  componentLocation?: 'footer' | 'drawer';
}

const SocialIcon: React.FC<SocialIconProps> = ({
  className,
  iconSrc,
  linkRoute,
  alt,
  componentLocation = 'footer',
}) => {
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });
  const iconClass = clsx(className, 'social-icon__wrapper');
  const svgClass = clsx(
    'social-icon',
    isMobile || !isDesktop ? 'social-icon--mobile' : 'social-icon--desktop',
    componentLocation === 'footer' && 'social-icon--footer',
    componentLocation === 'drawer' && 'social-icon--drawer',
  );
  return (
    <a className={iconClass} target="_blank" href={linkRoute} rel="noreferrer">
      <img src={iconSrc} className={svgClass} title={alt} />
    </a>
  );
};

export default SocialIcon;
