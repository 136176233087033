import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from '../../general/types/generalSlice.types';

const useDrawerConfig = () => {
  const virtualMenu = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.DRAWER,
    deviceLayout: 'general',
  });
  return virtualMenu || {};
};

export default useDrawerConfig;
