import React, { memo, useCallback } from 'react';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { useCategoryGamesIds } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useCategoryGamesIds';
import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import { useCategoryCellImpressions } from 'modules/casino/modules/container/cellsContainer/containers/shared/features/categoryImpressions/hooks/useCategoryCellImpressions';
import ViewAllCell from 'modules/casino/modules/container/cellsContainer/grid/components/gridCell/components/ViewAllCell';
import { useGridCell } from 'modules/casino/modules/container/cellsContainer/grid/components/gridCell/hooks/useGridCell';
import { GridType } from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { useGridLayoutSettings } from 'modules/casino/shared/components/gridLayoutSettings/hooks/useGridLayoutSettings';
import { CategoryQueryType } from 'modules/casino/shared/constants';
import {
  setCollectionLoadMore,
  setCollectionShowAll,
} from 'modules/casino/shared/features/casinoUi/casinoUiStateSlice';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { useSetRefsLoaded } from 'modules/casino/shared/hooks/useSetRefsLoaded';
import { localLog } from 'modules/casino/shared/utils/common/helpersCommon';
import { useAppDispatch } from 'store';
import { gridAnimations, LayoutCellWrappers } from './gridCellConstants';
import Layout_Wrapper from '../gridLayoutWrappers/LayoutWrapper/Layout_Wrapper';

interface GridCellProps {
  gameUniqueId: string;
  index: number;
  collectionId: string;
  isLastItem: boolean;
  categoryId: string;
  categoryLayout: CategoryLayoutTypes;
  queryType: CategoryQueryType;
  skipLazyLoad: boolean;
  gridPage: number;
  gamesRenderedCount: number;
  useLoadAnimation?: boolean;
}

const GridCellLayout: React.FC<GridCellProps> = ({
  gameUniqueId,
  index,
  collectionId,
  isLastItem,
  gridPage,
  gamesRenderedCount,
  categoryId,
  categoryLayout,
  skipLazyLoad,
  queryType,
  useLoadAnimation = true,
}) => {
  const dispatch = useAppDispatch();
  const { refLoaded, setRefs, ref: gameCellRef, inView } = useSetRefsLoaded(true);
  const { gameLayout, gridType, layoutGrid, hasNumbers, description, providerAlias, id, number, showInfoPage } =
    useGameProps({
      gameUniqueId,
      categoryId,
    });
  const { navToActivity } = useGetCategoryParams(categoryId, categoryLayout);
  const showViewAllCell = !hasNumbers;
  const { hoverAnimationClass, showGameCellFavButton } = useGridLayoutSettings();
  const gamesCount = useCategoryGamesIds({ categoryLayoutType: categoryLayout, categoryId }).length;
  useCategoryCellImpressions({ categoryId, categoryLayoutType: categoryLayout, inView, gameUniqueId, index });
  const hasViewAllCell = !!(
    showViewAllCell &&
    gridType === GridType.SLIDER &&
    gamesCount > 5 &&
    categoryLayout === CategoryLayoutTypes.LOBBY &&
    isLastItem &&
    navToActivity
  );

  const onShowItemWithoutAnimation = useCallback(() => {
    if (isLastItem) {
      dispatch(setCollectionLoadMore({ collectionId, value: true }));
    }

    if (hasViewAllCell) {
      dispatch(setCollectionShowAll({ collectionId, value: true }));
    }
  }, [hasViewAllCell, isLastItem]);

  const onAnimationStartLastItem = useCallback(
    (event) => {
      if (
        event?.target &&
        event.target.classList.contains('casino-grid-item') &&
        gridAnimations.includes(event.animationName)
      ) {
        onAnimationStart(event);
        if (isLastItem) {
          dispatch(setCollectionLoadMore({ collectionId, value: true }));
        }

        if (hasViewAllCell) {
          dispatch(setCollectionShowAll({ collectionId, value: true }));
        }
      }
    },
    [hasViewAllCell, isLastItem],
  );
  const {
    hasRestrictions,
    onAnimationStart,
    hasFinishedLoading,
    handleButtonClick,
    handleWrapperButtonClick,
    setLayoutStyle,
  } = useGridCell({
    gameUniqueId,
    gameCellRef,
    collectionId,
    categoryId,
    gridPage,
    gamesRenderedCount,
    showGameCellFavButton,
    layoutGrid,
    queryType,
    skipLazyLoad,
    gamesCount,
    categoryLayout,
    index,
    onShowItemWithoutAnimation,
    useLoadAnimation,
  });

  const layoutProps = {
    gameUniqueId,
    index,
    categoryId,
    categoryLayout,
    gameCellRef,
    hasRestrictions,
    handleButtonClick,
    skipLazyLoad,
    finishedLoading: hasFinishedLoading,
    setLayoutStyle: setLayoutStyle,
    number,
  };

  const renderGridLayoutWrapper = () => {
    if (!LayoutCellWrappers[layoutGrid]) {
      localLog({
        message: `Category ${categoryId} with Grid Layout: ${layoutGrid} does not  have supported cell type.`,
        type: 'warn',
      });
      return <></>;
    }

    return refLoaded && <Layout_Wrapper {...layoutProps} />;
  };
  const gameCellStyles = clsx([
    `hover--${hoverAnimationClass}`,
    'lazyload-wrapper',
    'casino-grid-item',
    !isMobile && 'casino-grid-item--desktop',
    `casino-grid-item--${gameLayout?.toLowerCase()}`,
    showInfoPage && 'no-hover',
  ]);

  return (
    <>
      <div
        className={gameCellStyles}
        ref={setRefs}
        onAnimationStart={onAnimationStartLastItem}
        onClick={handleWrapperButtonClick}
        data-qid-gamename={description}
        data-qid-provider={providerAlias}
        data-qid-gameid={id}
      >
        {skipLazyLoad ? renderGridLayoutWrapper() : inView ? renderGridLayoutWrapper() : <></>}
      </div>
      {hasViewAllCell ? <ViewAllCell categoryId={categoryId} categoryLayout={categoryLayout} /> : <></>}
    </>
  );
};
export default memo(GridCellLayout);
