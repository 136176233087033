import { GameMediaTypes } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellMedia/gameCellMediaTypes';
import { fitCover } from 'modules/casino/shared/utils/common/cdnConfig';
import config from 'utils/config';

export const createVideoSource = ({
  providerId,
  gameLayout,
  refGameId,
  cdnPathLayout,
  cdnPath,
}: GameMediaTypes.CreateVideoSourcesProps): string => {
  return `${config.CDN_URL}/gaming-content/${cdnPath}/${providerId}/${cdnPathLayout}/game-banners/${gameLayout}/${refGameId}.mp4`;
};

export const createStaticImageSource = ({
  // width,
  height,
  cdnPathLayout,
  cdnPath,
  refGameId,
  gameLayout,
  imageQuality,
  providerId,
  liveDefaultImg,
}: GameMediaTypes.CreateStaticImageSource): string[] => {
  const src = `gaming-content/${cdnPath}/${providerId}/${cdnPathLayout}/game-banners/${gameLayout?.toLowerCase()}/${refGameId}.png`;
  const url = `${config.CDN_IMAGE_API}${fitCover(undefined, height * imageQuality)}/${src}`;
  const defaultUrl = getDefaultImage({ gameLayout, liveDefaultImg });
  return [url, defaultUrl];
};

export const createLiveImageSource = ({
  liveImageLocation,
  defaultUrl,
}: {
  liveImageLocation: string;
  defaultUrl: string;
}): string[] => [liveImageLocation, defaultUrl];

export const getDefaultImage = ({
  gameLayout,
  liveDefaultImg,
}: {
  gameLayout: string;
  liveDefaultImg?: string;
}): string => {
  return (
    liveDefaultImg || `${location.protocol}//${location.host}/assets/img/casino-cells/${gameLayout?.toLowerCase()}.jpg`
  );
};

export const createImageRefSrc = ({ src, withLiveUpdate }: { src: string; withLiveUpdate: boolean }): string => {
  const imageSrc = `${src}${withLiveUpdate ? `?c=${new Date().getTime()}` : ''}`;

  if (imageSrc.indexOf('undefined') >= 0) {
    return '';
  }

  return imageSrc;
};
