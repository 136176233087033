import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useProviderDataById } from 'modules/casino/modules/container/shared/features/casinoGameProviders/hooks/useProviderDataById';
import { Modal } from 'modules/casino/shared/components/modal';
import { useAppDispatch, useAppSelector } from 'store';
import JackpotLastWinner from './JackpotLastWinner';
import { hideRootCasinoModal } from '../../rootCasinoModal/rootCasinoModal.slice';
import { jackpotSelectors } from '../jackpotSlice';

type Props = {
  jackpotId: number | string;
  level: string;
  providerId: string;
  currency: string;
};

const JackpotWinDetailsModal: React.FC<Props> = ({ jackpotId, level, providerId, currency }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { code: providerCode } = useProviderDataById(providerId);

  const winDetails = useAppSelector((state) => jackpotSelectors.selectJackpotWinDetails(state, jackpotId, level));

  useEffect(() => {
    if (!winDetails?.hasWinners) {
      dispatch(hideRootCasinoModal());
    }
  }, [dispatch, providerCode, winDetails]);

  return winDetails && winDetails.hasWinners ? (
    <Modal visible className="jackpot-modal">
      <Modal.Close closeText={t('Jackpots.LastWinner.closeBtn')} closeAction={() => dispatch(hideRootCasinoModal())} />
      <JackpotLastWinner winDetails={winDetails} providerCode={providerCode} currency={currency} />
    </Modal>
  ) : (
    <></>
  );
};

export default JackpotWinDetailsModal;
