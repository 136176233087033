import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { useAppDispatch, useAppSelector } from 'store';
import { SSBT } from 'utils/common/translationUtils/translationStrings';
import DepositAgainButton from './DepositAgainButton';
import CloseModalButton from '../../../components/CloseModalButton';
import { TerminalModalUI } from '../../../components/ssbtModalUI';
import { selectSSBTdata } from '../../../slice/ssbt.slice';
import { sessionBalanceThunk } from '../../sessionBalance/slice/sessionBalance.slice';

type Props = {
  handleFirstStep: () => void;
};
const ThirdStepDepositSSBTCash: React.FC<Props> = ({ handleFirstStep }) => {
  //const depositInfoStatus = useAppSelector(selectSSBTdata.depositInfoStatus);
  const depositRetailStatus = useAppSelector(selectSSBTdata.depositRetailStatus);
  //const billTotal = useAppSelector(selectSSBTdata.billSum);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const date = new Date(Date.now());
  // const { currencySymbol } = useCurrency();
  // const { retailBalanceInfo } = useNavUserLogged();
  const { closeModal } = useHandleModals();

  // const rejectDeposit = () => {
  //   dispatch(hideRootModal());
  //   if (Number(totalSum) % 5 === 0) {
  //     payOut(totalSum, billTotal, totalSum, i18next, retailBalanceInfo?.sessionBalance?.currency);
  //   } else {
  //     payOut(totalSum, billTotal, totalSum, i18next, retailBalanceInfo?.sessionBalance?.currency);
  //     payoutCoins(totalSum);
  //   }
  //   dispatch(resetTotalSum());
  //   //dispatch(runBillingValidator());
  // };
  // const closeFailedModal = () => {
  //   rejectDeposit();
  // };

  const closeModalButton = () => {
    handleFirstStep();
    closeModal();
  };
  const renderDepositStateModals = (status) => {
    switch (status) {
      case 'pending':
        return (
          <>
            <TerminalModalUI.Status status="pending" />
            <TerminalModalUI.Header text={t(SSBT.TerminalModal.requestTerminalText)} />
          </>
        );
      case 'succeeded':
        return (
          <>
            <TerminalModalUI.Status
              status="success"
              text={t(SSBT.TerminalModal.transactionTextTerminal)}
              textSecondary={date.toDateString()}
            />
            <TerminalModalUI.ActionsWrapper>
              <DepositAgainButton variant="primary" onClick={handleFirstStep} />
              <CloseModalButton onClick={closeModalButton} />
            </TerminalModalUI.ActionsWrapper>
          </>
        );
      case 'failure':
        return (
          <>
            <TerminalModalUI.Status status="error" text={t(SSBT.TerminalModal.rejectedTransactionTerminal)} />
            {/* <CloseModalButton depositStatus={status} onClick={closeFailedModal} /> */}
          </>
        );
    }
  };

  useEffect(() => {
    if (depositRetailStatus === 'succeeded') {
      dispatch(sessionBalanceThunk.checkForUpdateBalance());
    }
  }, [dispatch, depositRetailStatus]);
  return (
    <TerminalModalUI.ContentWrapper>{renderDepositStateModals(depositRetailStatus)}</TerminalModalUI.ContentWrapper>
  );
};
export default ThirdStepDepositSSBTCash;
