/** Component CSS NameSpace */
export const cssNS = 'egtd-p-bet-log';

/** BetLog */
export const betLogCSSMap = {
  sport: `${cssNS}--sport`,
  casino: `${cssNS}--casino`,
} as const;

export type BetLogTypes = keyof typeof betLogCSSMap;
