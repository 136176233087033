import React, { memo } from 'react';
import { GridLayouts } from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import { lastWinnersCategoryWidgets } from './LastWinnersSectionMap';
import { CasinoWidgetsTypes } from '../../../types/casinoWidgetsTypes';

interface WidgetProps {
  widget: CasinoWidgetsTypes.Widget;
  layoutGrid: GridLayouts;
}

const LastWinnersCategoryLayout: React.FC<WidgetProps> = ({ widget, layoutGrid }) => {
  const LastWinnersWidgetComponent = lastWinnersCategoryWidgets[widget.template.type];

  return LastWinnersWidgetComponent ? <LastWinnersWidgetComponent widget={widget} layoutGrid={layoutGrid} /> : null;
};

export default memo(LastWinnersCategoryLayout);
