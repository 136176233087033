import { ButtonsTypes } from '../default/types/ButtonsTypes';

// Buttons
export const Buttons: ButtonsTypes.State = {
  ActionSuccessPrimaryWithHover: {
    color: 'var(--black)',
    bgStart: 'var(--yellow)',
    bgEnd: 'var(--yellow)',
    bgStartHover: 'var(--yellow-light)',
    bgEndHover: 'var(--yellow-light)',
    bg: 'var(--yellow)',
    bgHover: 'var(--yellow-light)',
  },
  PrimaryAction: {
    bgStart: 'var(--yellow)',
    bgEnd: 'var(--yellow)',
    bg: 'var(--yellow)',
  },
};
