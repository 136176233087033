import React, { memo } from 'react';
import { isDesktop } from 'react-device-detect';
import { Ticker } from 'shared/common/features/ticker';
import { Desktop, DesktopDown } from 'theme/Theme';
import { useTickerHeader } from '../../hooks/useTickerHeader';

const TickerWrapper: React.FC = () => {
  const { showTicker, tickerVerticalConfig, isExpanded } = useTickerHeader();

  if (!tickerVerticalConfig || (!tickerVerticalConfig?.visible && !showTicker)) return null;

  return (
    <>
      {isDesktop && (
        <Desktop>
          <Ticker speed={tickerVerticalConfig.speed} visible={tickerVerticalConfig.visible} />
        </Desktop>
      )}
      {isExpanded ? (
        <DesktopDown>
          <Ticker speed={tickerVerticalConfig.speed} visible={tickerVerticalConfig.visible} />
        </DesktopDown>
      ) : null}
    </>
  );
};

export default memo(TickerWrapper);
