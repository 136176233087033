import React, { memo } from 'react';
import { useGetDrawerLiveSport } from './hooks/useGetDrawerLiveSport';
import { SportsType } from '../../types/drawer.types';
import DrawerNavLinkDefault from '../DrawerNavLinkDefault';
import { DrawerUI } from '../DrawerUI';
import SportSectionNavLink from '../sport/SportSectionNavLink';
interface Props {
  isActive: boolean;
}

const LiveSportDrawerSection: React.FC<Props> = ({ isActive }) => {
  const { items, activeItem } = useGetDrawerLiveSport();

  return (
    <DrawerUI.Section type="liveSport" isActive={isActive}>
      <DrawerNavLinkDefault sportType={SportsType.LiveSport} icon={`common-content/menu-icons/link-home.svg`} />
      {items.map((item, idx) => {
        return (
          <SportSectionNavLink
            key={idx}
            item={item}
            isActive={activeItem === item.sportId}
            index={idx}
            sportType={SportsType.LiveSport}
          />
        );
      })}
    </DrawerUI.Section>
  );
};

export default memo(LiveSportDrawerSection);
