import React from 'react';
import SearchMessage, { MessageVariant } from './SearchMessage';

interface Props {
  title?: string;
  message?: string;
  classNames?: string;
}

const NoDataMessage: React.FC<Props> = ({ title, message, classNames }) => {
  return message ? (
    <SearchMessage variant={MessageVariant.info} title={title} message={message} classNames={classNames} />
  ) : (
    <></>
  );
};

export default NoDataMessage;
