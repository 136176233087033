import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import Button from 'components/shared/buttons/Button';
import Icon from 'components/shared/Icon';
interface Props {
  className?: string;
  style?: CSSProperties;
  onClickHandler?;
}

const ButtonBack: React.FC<Props> = ({ className, onClickHandler, style }) => {
  const buttonBackStyles = clsx('d-flex-center btn-back', className);
  return (
    <Button onClickHandler={onClickHandler} classNames={buttonBackStyles} style={style}>
      <Icon className="btn-back__icon" icon={`arrow`} />
    </Button>
  );
};

export default ButtonBack;
