import { TagType } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/gameCellTagsConstants';
import { GameTag } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/GameCellTagsTypes';

export const getBottomTag = ({ tags, jackpotId }): GameTag | undefined =>
  tags?.find(
    (x) =>
      (jackpotId && x.type === TagType.BOTTOM_LABEL) ||
      x.type === TagType.BOTTOM_TEXT ||
      x.type === TagType.BOTTOM_TRANSPARENT ||
      x.type === TagType.BOTTOM_TRANSPARENT_TEXT,
  );

export const getRightTag = ({ tags }): GameTag | undefined => tags?.find((x) => x.type === TagType.LABEL_RIGHT);

export const getTransparentBottomTag = ({ tags, jackpotId }): boolean | undefined =>
  tags?.find((x) => jackpotId && x.type === TagType.BOTTOM_TRANSPARENT);

export const getSortedTags = (tags) => tags?.slice().sort((a, b) => a.rank - b.rank);

export const getTagLogo = (tags) => tags?.find((tag) => tag.type === TagType.LOGO);
