import React from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import SportIconCDN from 'components/shared/icons/SportIconCDN';
import { cssBurgerNS, tileAnimation } from './burgerUI.types';
import { SCTileBadge } from './TileBadge.styled';

/** NavItem */
interface SportTileProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;

  icon: string;
  text: string;

  isActive?: boolean;

  showLiveBadge?: boolean;
  liveBadgeText?: string;
  onClick?: () => void;
}

const SportTile: React.FC<SportTileProps> = ({
  className,
  icon,
  text,
  isActive,
  liveBadgeText,
  onClick,
  showLiveBadge = true,
}) => {
  const tileClass = clsx(cssBurgerNS.Tile, cssBurgerNS.SportTile, isActive && cssBurgerNS.TileIsActive, className);

  return (
    <motion.div variants={tileAnimation} className={tileClass} onClick={onClick}>
      <SportIconCDN type="burger" isActive={isActive} icon={icon} />

      <span className={`text-truncate ${cssBurgerNS.SportText}`}>{text}</span>
      {liveBadgeText && showLiveBadge && <SCTileBadge>{liveBadgeText}</SCTileBadge>}
    </motion.div>
  );
};

export default SportTile;
