import { useMemo } from 'react';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from 'modules/casino/theme/Theme';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { General } from 'shared/common/features/general/types/generalSlice.types';
import { useAppSelector } from 'store';
import { isTerminal } from '../utils/common/helpersCommon';

export const useLayoutConfigType = <K extends keyof General.LayoutConfiguration>({
  innerLayout,
  deviceLayout,
  moduleLayout,
}: {
  innerLayout: K;
  deviceLayout?: General.LayoutConfigurationKeys;
  moduleLayout: General.ModuleConfigurationKeys;
}): General.LayoutConfiguration[K] => {
  const isMobileQuery = useMediaQuery({ query: `(max-width: ${Breakpoints.isMobile}px)` });
  const isTabletQuery = useMediaQuery({ query: `(max-width: ${Breakpoints.isTabletOrMobile}px)` });
  const activeDeviceLayout: General.LayoutConfigurationKeys = useMemo(() => {
    if (deviceLayout) {
      return deviceLayout;
    } else if (isTerminal()) {
      return 'terminal';
    } else if (isMobileOnly || isMobileQuery) {
      return 'mobile';
    } else if (isTablet || isTabletQuery) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }, [isTabletQuery, isMobileQuery, deviceLayout]);

  const selectedLayout = useAppSelector(selectGeneral.moduleLayout(moduleLayout, activeDeviceLayout, innerLayout));

  return selectedLayout;
};
