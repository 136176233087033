import React, { useEffect } from 'react';
import { setIsInGameLaunch } from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import { useAppDispatch } from 'store';

interface Props {
  children: React.ReactNode;
}

const InGameCategoryWrapper: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setIsInGameLaunch(true));

    return () => {
      dispatch(setIsInGameLaunch(false));
    };
  }, []);
  return <>{children}</>;
};

export default InGameCategoryWrapper;
