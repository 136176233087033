import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/shared';
import { Badge } from 'components/shared/badges';
import { Flex } from 'components/shared/layout';
import { BetStatuses, SelectionStatuses } from 'pages/myAccount/tabs/history/sections/sportsHistory/types/myBets.types';
import CustomBetTitle from './CustomBetTitle';
import SelectionStatus from './SelectionStatus';

/** BetSeletionUI CustomBet Header */
interface CustomBetHeaderProps {
  isLive: boolean;
  odds: string | number | undefined;
  selectionStatus: keyof typeof SelectionStatuses | null;
  betStatus: BetStatuses;
  bonusId: string;
}

const CustomBetHeader: React.FC<CustomBetHeaderProps> = ({ isLive, odds, selectionStatus, betStatus }) => {
  const { t } = useTranslation();

  return (
    <Flex.AC className="bsl-sel__header">
      {isLive && <Badge className="mr-1" variant="info" label={t('BetBuilder.SelectionHeader.isLiveText')} />}
      <CustomBetTitle />
      <Flex.AC className="ml-auto egtd-p-bi-sel__header-right-col">
        <Text className="egtd-p-bi-sel__accent" text={odds} />
        <div className="ml-1">
          <SelectionStatus status={selectionStatus} betStatus={betStatus}></SelectionStatus>
        </div>
      </Flex.AC>
    </Flex.AC>
  );
};

export default CustomBetHeader;
