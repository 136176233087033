import React from 'react';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { sliceString } from 'modules/casino/shared/utils/common/sliceString';
import { TimeDataType } from 'modules/casino/shared/utils/dateUtils';
import { Layout01 } from './components';
import { usePromotionImgResource } from '../../../../hooks/usePromotionImgResource';
import { PromoWidgetsTypes } from '../../../../promoWidgetTypes';
import { getDateDifference } from '../../../../promoWidgetUtils';

interface Props {
  promoItem: PromoWidgetsTypes.PromoItem;
  openPromotion: () => void;
}

// IBBG cell layout
const PromoCellLayout_1: React.FC<Props> = ({ promoItem, openPromotion }) => {
  const { cellRef, imgSource } = usePromotionImgResource(promoItem.widgetResource);

  return (
    <div className={`${cssGlobalNS}-c-prm-lobby-card`}>
      <div className={`d-flex-col ${cssGlobalNS}-c-prm-lobby-card__wrapper`}>
        <Layout01.CardThumb ref={cellRef} bgImage={imgSource} />
        <div className={`${cssGlobalNS}-c-prm-lobby-card__text-block`}>
          <Layout01.CardTitle text={promoItem.title} color={promoItem.titleTextColor} />
          <Layout01.CardDescription
            text={sliceString(promoItem?.description || '', 143)}
            color={promoItem?.descriptionTextColor || ''}
          />
          <Layout01.CardMeta
            dateDifference={getDateDifference(promoItem, 'layout_1', false) as TimeDataType}
            onClick={openPromotion}
          />
        </div>
      </div>
    </div>
  );
};

export default PromoCellLayout_1;
