import { CategoryLayoutTypes } from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { BigWinsWidget } from '../../widgetTypes/bigWinsWidget';
import { GenericSectionWidget } from '../../widgetTypes/genericWidgets/genericSectionWidget';
import { JackpotsWidget } from '../../widgetTypes/jackpotsWidgets';
import { LastWinnersCategoryLayout } from '../../widgetTypes/lastWinnersWidgets/lastWinnersSectionWidgets';
import { PromoWidget } from '../../widgetTypes/promoWidget';

export const sectionWidgetTypesMap = {
  LAST_WINNERS: LastWinnersCategoryLayout,
  LAST_WINNERS_PLUS: LastWinnersCategoryLayout,
  BIG_WINS: BigWinsWidget,
  JACKPOTS: JackpotsWidget,
  GENERIC: GenericSectionWidget,
  PROMO: PromoWidget,
};

const lobbyCategoryWidgets = {
  LAST_WINNERS: sectionWidgetTypesMap.LAST_WINNERS,
  LAST_WINNERS_PLUS: sectionWidgetTypesMap.LAST_WINNERS_PLUS,
  JACKPOTS: sectionWidgetTypesMap.JACKPOTS,
  BIG_WINS: sectionWidgetTypesMap.BIG_WINS,
  GENERIC: sectionWidgetTypesMap.GENERIC,
  PROMO: sectionWidgetTypesMap.PROMO,
};

const providerLobbyCategoryWidgets = {
  LAST_WINNERS: sectionWidgetTypesMap.LAST_WINNERS,
  LAST_WINNERS_PLUS: sectionWidgetTypesMap.LAST_WINNERS,
  BIG_WINS: sectionWidgetTypesMap.BIG_WINS,
  JACKPOTS: sectionWidgetTypesMap.JACKPOTS,
  GENERIC: sectionWidgetTypesMap.GENERIC,
};

const innerCategoryWidgets = {
  JACKPOTS: sectionWidgetTypesMap.JACKPOTS,
};

const landingCategoryWidgets = {
  JACKPOTS: sectionWidgetTypesMap.JACKPOTS,
};

const externalCategoryWidgets = {
  JACKPOTS: sectionWidgetTypesMap.JACKPOTS,
};

export const sectionWidgetByLayout = {
  [CategoryLayoutTypes.LOBBY]: lobbyCategoryWidgets,
  [CategoryLayoutTypes.PROVIDER_CATEGORY]: providerLobbyCategoryWidgets,
  [CategoryLayoutTypes.INNER_CATEGORY]: innerCategoryWidgets,
  [CategoryLayoutTypes.LANDING_CATEGORY]: landingCategoryWidgets,
  [CategoryLayoutTypes.EXTERNAL_CATEGORY]: externalCategoryWidgets,
};
