import { memo, useEffect } from 'react';
import { Flex } from 'components/shared/layout';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { sessionBalanceThunk } from 'modules/retail/modules/ssbt/features/sessionBalance/slice/sessionBalance.slice';
import { printVoucher } from 'modules/retail/modules/ssbt/printMethods/voucherPrintMethod';
import { selectVoucherData } from 'modules/retail/shared/slice/voucher.slice';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { useAppDispatch, useAppSelector } from 'store';
import { RetailHeaderMenu } from './features/retailHeaderMenu';
import { RetailHeaderUser } from './features/retailHeaderUser';

const RetailHeaderDefault = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const retailVoucherInfoStatus = useAppSelector(selectVoucherData?.retailVoucherInfoStatus);
  const voucherInfo = useAppSelector(selectVoucherData?.retailVoucherInfo);
  const isLinearCodeOnReceipt = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.TICKET_CODE_LINEAR,
    deviceLayout: 'general',
  });
  const retailAddress = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.RETAIL_ADDRESS,
    deviceLayout: 'general',
  });
  const isCurrencyBeforeAmount = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.CURRENCY_BEFORE_AMOUNT,
    deviceLayout: 'general',
  });

  useEffect(() => {
    if (retailVoucherInfoStatus === 'succeeded') {
      dispatch(sessionBalanceThunk.checkForUpdateBalance());
      const printVoucherObj = {
        totalWin: voucherInfo?.value?.amount ?? 0,
        code: voucherInfo?.code ?? '',
        currency: voucherInfo?.value?.currency ?? '',
        issuedAt: voucherInfo?.issuedAt ?? '',
        validUntil: voucherInfo?.validUntil ?? '',
        isLinearCodeOnReceipt: isLinearCodeOnReceipt ?? false,
        retailAddress,
        isCurrencyBeforeAmount: isCurrencyBeforeAmount ?? false,
      };
      printVoucher(printVoucherObj);
    }
  }, [retailVoucherInfoStatus]);

  return (
    <Flex.ACJB className="overflow-x-auto h-100 custom-scrollbars flex-1">
      <RetailHeaderMenu />
      <RetailHeaderUser isAuthenticated={isAuthenticated} />
    </Flex.ACJB>
  );
};

export default memo(RetailHeaderDefault);
