import { useTranslation } from 'react-i18next';
import { Icon } from 'components/shared';
import { CashierTheme } from '../../../pages/myAccount/tabs/cashier/utils/cashier.utils';

export const TextAndIcon = () => {
  const { t } = useTranslation();
  const icon = CashierTheme.icons.ui;
  return (
    <>
      {t('OnlineCashier.Chat.chatLabel')}{' '}
      <Icon defaultClassName={false} className="flex-shrink-0" size={28} iconPath={icon} icon={'chat'} />
    </>
  );
};
