import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { logoutUser, hardwareBackPress } from 'shared/common/sharedSlices/commonActions';
import { RootState } from 'store/rootReducer';

const initialState = {
  isBackdropVisible: false,
};

const appBackdrop = createSlice({
  name: 'appBackdrop',
  initialState,
  reducers: {
    setAppBackdrop(state, action) {
      state.isBackdropVisible = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(logoutUser, hardwareBackPress), (state) => {
      state.isBackdropVisible = false;
    });
  },
});

export const { setAppBackdrop } = appBackdrop.actions;

export const selectBackdropVisibility = (state: RootState): boolean => state.common.appBackdrop.isBackdropVisible;

export default appBackdrop.reducer;
