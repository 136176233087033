import clsx from 'clsx';
import Icon from 'components/shared/Icon';
import { Flex } from 'components/shared/layout';
import { isTerminal } from 'utils/common/helpersCommon';

interface ActionButtonProps {
  text: string;
  icon?: string;
  iconPath?: string;
  state?: 'disabled';
  onClick: React.MouseEventHandler<HTMLDivElement>;
  isTertiary?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = (props) => {
  const cssNS = isTerminal() ? 'terminal-ticket' : 'cashier-ticket';

  const emptyClick = () => null;
  const buttonClass = clsx(
    `btn btn-block ${cssNS}__action-btn`,
    props.isTertiary ? 'egtd-p-btn--tertiary' : 'egtd-p-btn--primary',
  );

  return (
    <Flex.Center className={buttonClass} onClick={props.state === 'disabled' ? emptyClick : props.onClick}>
      {props.text}
      <Icon className={'ml-2'} iconPath={props.iconPath} icon={props.icon} size={24} />
    </Flex.Center>
  );
};

export default ActionButton;
