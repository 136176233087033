import { SmarticoTypes } from '../types/smartico.types';

export const SMARTICO_URL = 'https://libs.smartico.ai/smartico.js';

export const smarticoScriptInit = ({ document, src, callback, attr, config }: SmarticoTypes.InitParams) => {
  const h = document.getElementsByTagName('head')[0];
  const s = document.createElement('script');
  s.onload = () => callback(config);
  s.src = src;
  s.setAttribute('n', attr);
  h.appendChild(s);
};

export const isScriptAttached = (attr: string): boolean => document.querySelectorAll(`script[n="${attr}"]`).length > 0;
