import { useCallback, useEffect } from 'react';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { setAccountActiveNavTab } from 'pages/myAccount/slice/mpdAccountTabs.slice';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { useAppDispatch, useAppSelector } from 'store';
import {
  fetchCategories,
  selectOffersTab,
  setActiveCategoryTitle,
  setRedirectedOfferId,
} from '../slice/offersTab.slice';

const POLLING_INTERVAL = 60000;

const useFetchOffers = () => {
  const categories = useAppSelector(selectOffersTab.categories);
  const activeCategory = useAppSelector(selectOffersTab.activeCategory);
  const status = useAppSelector(selectOffersTab.categoryStatus);

  const dispatch = useAppDispatch();

  const layout =
    useLayoutConfigType({
      moduleLayout: 'platform',
      innerLayout: LayoutConfigTypes.LAYOUTTYPECONFIG,
      deviceLayout: 'general',
    })?.offersTab || 'layout_1';

  const resetActiveCategories = useCallback(() => {
    dispatch(setAccountActiveNavTab(''));
    dispatch(setActiveCategoryTitle(''));
    dispatch(setRedirectedOfferId(''));
  }, [dispatch]);

  const hideCategoryBonuses = (e: React.MouseEvent) => {
    e.stopPropagation();
    resetActiveCategories();
  };

  useEffect(() => {
    return () => {
      resetActiveCategories();
    };
  }, [dispatch, resetActiveCategories]);

  useEffect(() => {
    let promise;

    const interval = setInterval(() => {
      promise = dispatch(fetchCategories());
    }, POLLING_INTERVAL);

    return () => {
      clearInterval(interval);
      if (promise && promise?.abort) {
        promise.abort?.();
      }
    };
  }, [dispatch]);

  return { layout, categories, activeCategory, status, hideCategoryBonuses };
};
export default useFetchOffers;
