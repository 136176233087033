import { useEffect, useState } from 'react';
import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import { gamesSelectors } from 'modules/casino/modules/container/cellsContainer/cellls/slice/casinoGamesSlice';
import { CasinoGamesTypes } from 'modules/casino/modules/container/cellsContainer/cellls/types/casinoGamesTypes';
import { GameData } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTablePlayers/gameCellTablePlayersTypes';
import { GridType } from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import { useAppSelector } from 'store';
import { RootState } from 'store/rootReducer';
import { useDataWrapper } from '../../hooks/useDataWrapper';
import { GameCellDataCharactersTypes } from '../gameCellTableCharactersConstants';

interface GameConfigData {
  description: string;
  configuration: Array<string | number | CasinoGamesTypes.ResultsObj>;
}

type UseDataCharactersTypes = {
  gameUniqueId: string;
  categoryId: string;
};

interface UseDataCharactersReturnTypes {
  showTableCharacters: boolean;
  gameConfig: GameConfigData;
  luckyNumbers: Array<CasinoGamesTypes.LuckyNumber>;
  isMacro: boolean;
  category: GameCellDataCharactersTypes;
  visualizationTemplateId: string | null;
  totalPlayers: number;
  gridType: GridType;
  description: string;
  gameData: GameData;
  hasLiveFeedInfo: boolean;
}

export const useDataCharacters = ({
  gameUniqueId,
  categoryId,
}: UseDataCharactersTypes): UseDataCharactersReturnTypes => {
  const game = useGameProps({ gameUniqueId, categoryId });
  const { showTableCharacters, gameData } = useDataWrapper({
    game,
  });
  const { description } = gameData;
  const { gridType } = game;
  const [gameConfig, setGameData] = useState<GameConfigData>({ description: '', configuration: [] });
  const configuration = useAppSelector((state: RootState) =>
    gamesSelectors.liveGameDataById(state, gameData.refGameId),
  );
  const visualizationTemplateId = gameData.visualizationTemplateId;
  const isMacro = gameData.gameLayout === 'macro';
  const luckyNumbers = configuration?.luckyNumbers && configuration?.luckyNumbers;
  const category = visualizationTemplateId && GameCellDataCharactersTypes[visualizationTemplateId];
  const totalPlayers = configuration ? configuration.tablePlayers : gameData.tablePlayers;

  useEffect(() => {
    const gameDataConfiguration = configuration ? configuration : gameData;

    setGameData(
      (visualizationTemplateId === GameCellDataCharactersTypes.Baccarat ||
        visualizationTemplateId === GameCellDataCharactersTypes.Football ||
        visualizationTemplateId === GameCellDataCharactersTypes.Cards) &&
        visualizationTemplateId.toString() != GameCellDataCharactersTypes.SuperSicBo
        ? { description: description, configuration: gameDataConfiguration?.history }
        : { description: description, configuration: gameDataConfiguration?.results },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration]);

  return {
    gridType,
    showTableCharacters,
    gameConfig,
    luckyNumbers,
    isMacro,
    category,
    visualizationTemplateId,
    totalPlayers,
    description,
    gameData,
    hasLiveFeedInfo: !!game.liveFeedInfo,
  };
};
