import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { usePreloadImage } from 'hooks/common/usePreloadImage';
import { constructAnimationImage } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationUtils';
import { createBonusItemAnimation } from '../utils/createBonus.utils';

type Props = {
  text?: string;
  show?: boolean;
  image: string;
  folder;
  handleOnClick?: () => void;
  btnColor?: string;
  isLast?: boolean;
  onlyOne?: boolean;
};

export const SelectBonusButton: React.FC<Props> = ({
  text,
  show,
  image,
  folder,
  handleOnClick,
  btnColor,
  isLast,
  onlyOne,
}) => {
  const forwardImage = constructAnimationImage({
    folder: folder,
    image: image,
    width: 162 * 2,
    height: 60 * 2,
    fit: 'contain',
  });

  const { isImageLoaded, imageLoadError } = usePreloadImage(forwardImage);

  return (
    <AnimatePresence>
      {show && (isImageLoaded || imageLoadError) && (
        <>
          {onlyOne && (
            <BtnWrapper variants={createBonusItemAnimation.btn} animate="visible" initial="hidden"></BtnWrapper>
          )}
          <BtnWrapper variants={createBonusItemAnimation.btn} animate="visible" initial="hidden" $isLast={isLast}>
            <ButtonStyle
              $background={forwardImage}
              className="text-truncate"
              $btnColor={btnColor}
              onClick={handleOnClick}
            >
              {text}
            </ButtonStyle>
          </BtnWrapper>
        </>
      )}
    </AnimatePresence>
  );
};

const BtnWrapper = styled(motion.div)<{ $isLast?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 162px;
  ${({ $isLast }) =>
    !$isLast &&
    `  
  flex: 0 0 162px;`}
`;
const ButtonStyle = styled(motion.div)<{ $background?: string; $btnColor?: string }>`
  background-size: contain;
  ${({ $background }) => ($background ? `background-image: url(${$background})` : '')};
  color: ${({ $btnColor }) => $btnColor ?? '#fff'};
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-inline: 5px;
`;
