import React, { memo } from 'react';
import useWidgetBackground from 'modules/casino/modules/container/widget/widgetTypes/hooks/useWidgetBackground';
import CellWrapper from 'modules/casino/modules/container/widget/widgetTypes/leaderBoardWidget/leaderBoardCellWidget/components/CellWrapper';
import WidgetLeaderBoard from 'modules/casino/modules/container/widget/widgetTypes/leaderBoardWidget/leaderBoardCellWidget/components/widgetLeaderBoardComponents/WidgetLeaderBoard';
import WidgetSkeleton from 'modules/casino/modules/container/widget/widgetTypes/leaderBoardWidget/leaderBoardCellWidget/components/WidgetSkeleton';
import { useFetchLeaderBoardData } from 'modules/casino/modules/container/widget/widgetTypes/leaderBoardWidget/leaderBoardCellWidget/hooks/useFetchLeaderBoardData';
import { CasinoWidgetsTypes } from '../../../types/casinoWidgetsTypes';

interface WidgetsProps {
  widget: CasinoWidgetsTypes.Widget;
}

const LeaderBoardCellWidget: React.FC<WidgetsProps> = ({ widget }) => {
  const { id, template, backgroundLocations } = widget || {};
  const backgroundWidgetResource = backgroundLocations;
  const { backgroundWrapper, setRefs } = useWidgetBackground({ backgroundWidgetResource, type: template?.type });

  useFetchLeaderBoardData({ id: widget.id, query: widget.query });

  return id && (!backgroundWidgetResource || backgroundWrapper) ? (
    <CellWrapper type={template?.type} backgroundUrl={backgroundWrapper}>
      <WidgetLeaderBoard widget={widget} />
    </CellWrapper>
  ) : (
    <WidgetSkeleton type={template?.type} columns={template?.columns} setRefs={setRefs} />
  );
};

export default memo(LeaderBoardCellWidget);
