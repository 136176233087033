import { useAppDispatch, useAppSelector } from 'store';
import { selectRealityCheckSession } from '..';
import { closeFormPreModal } from '../slice/realityCheckSession.slice';

interface ReturnProps {
  showModal: boolean;
  closeModal?: () => void;
}

const useSessionPreModal = (): ReturnProps => {
  const preModal = useAppSelector(selectRealityCheckSession.formPreModal);
  const showPreModal = useAppSelector(selectRealityCheckSession.showPreModal);
  const dispatch = useAppDispatch();
  const showModal = showPreModal && preModal?.showModal === 'true';

  return {
    showModal,
    closeModal: showModal ? () => dispatch(closeFormPreModal()) : undefined,
  };
};

export default useSessionPreModal;
