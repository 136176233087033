export enum GameCellDataCharactersTypes {
  Roulette = 'Roulette',
  Baccarat = 'Baccarat',
  SicBo = 'SicBo',
  SuperSicBo = 'SuperSicBo',
  Blackjack = 'Blackjack',
  Monopoly = 'Monopoly',
  Football = 'Football',
  Poker = 'Poker',
  LightningDice = 'LightningDice',
  DreamCatcher = 'DreamCatcher',
  CrazyTime = 'CrazyTime',
  MegaBall = 'MegaBall',
  MegaWheel = 'MegaWheel',
  DragonTiger = 'DragonTiger',
  Icons = 'Icons',
  SicBoDeluxe = 'SicBoDeluxe',
  Cards = 'Cards',
  SpinAWin = 'SpinAWin',
  Svara = 'Svara',
}

export const Roulette = {
  0: 'green',
  32: 'red',
  19: 'red',
  21: 'red',
  25: 'red',
  34: 'red',
  27: 'red',
  36: 'red',
  30: 'red',
  23: 'red',
  5: 'red',
  16: 'red',
  1: 'red',
  14: 'red',
  9: 'red',
  18: 'red',
  7: 'red',
  12: 'red',
  3: 'red',
  15: 'black',
  4: 'black',
  2: 'black',
  17: 'black',
  6: 'black',
  13: 'black',
  11: 'black',
  8: 'black',
  10: 'black',
  24: 'black',
  33: 'black',
  20: 'black',
  31: 'black',
  22: 'black',
  29: 'black',
  28: 'black',
  35: 'black',
  26: 'black',
};
export const Baccarat = { T: 'green', B: 'red', P: 'blue', Tr: 'blue', Dn: 'red', S: 'green' };
export const DreamCatcher = {
  '01': 'yellow',
  '02': 'blue',
  '05': 'purple',
  '10': 'green-two',
  '20': 'orange',
  '40': 'red',
  X2: 'white',
  X7: 'white',
};

export const Monopoly = {
  1: 'gray',
  2: 'green',
  4: 'pink',
  5: 'pink',
  10: 'skyblue',
  '2r': 'r2',
  '4r': 'r4',
  ch: 'ch',
};
export const SpinAWin = {
  1: 'spin-yellow',
  2: 'spin-blue',
  5: 'spin-green',
  10: 'spin-pink',
  20: 'spin-orange',
  40: 'spin-red',
  X2: 'x2',
  X7: 'x7',
};

export const SicBo = {
  4: 'small',
  5: 'small',
  6: 'small',
  7: 'small',
  8: 'small',
  9: 'small',
  10: 'small',
  11: 'big',
  12: 'big',
  13: 'big',
  14: 'big',
  15: 'big',
  16: 'big',
  17: 'big',
};

export const FootballStudioSymbol = { T: 'D', R: 'A', L: 'H', S: 'CC' };
export const FootballStudioColors = {
  D: 'draw',
  A: 'away',
  H: 'home',
  CC: 'cc',
  T: 'draw',
  R: 'away',
  L: 'home',
  S: 'cc',
};

export const spreadBetNumbetPosition = [
  [0, 1],
  [2, 11],
  [12, 18],
  [19, 33],
  [34, 54],
  [55, 67],
  [68, 72],
];
export const RouletteBg = {
  SLOT_GAME: 'purple',
  '0-1': '#00AE56',
  '2-11': '#F5B711',
  '12-18': '#8D25DF',
  '19-33': '#E50000',
  '34-54': '#FF6400',
  '55-67': '#E1479B',
  '68-72': '#2727C4',
};

export const CardColor = {
  D: 'red-cards',
  H: 'red-cards',
};
