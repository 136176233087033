import { useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { Breakpoints } from 'theme/Theme';
import { isTerminal } from 'utils/common/helpersCommon';

export type DeviceType = 'mobile' | 'tablet' | 'desktop' | '' | 'terminal';

const getDeviceBreakpoints = (width: number): DeviceType => {
  if (isTerminal()) {
    return 'terminal';
  }

  if (width <= Breakpoints.isTabletOrMobile || isMobileOnly) {
    return 'mobile';
  }
  if (width <= Breakpoints.isTabletOrMobile || isTablet) {
    return 'tablet';
  }

  return 'desktop';
};

export const useOnDeviceChange = (callback: (deviceType: DeviceType) => void, debounceDelay = 1000) => {
  const prevWidth = useRef(window.innerWidth);

  useEffect(() => {
    callback(getDeviceBreakpoints(window.innerWidth));
  }, []);

  useEffect(() => {
    const resizeHandler = debounce(() => {
      const width = window.innerWidth;
      if (width !== prevWidth.current) {
        callback(getDeviceBreakpoints(window.innerWidth));
      }
      prevWidth.current = window.innerWidth;
    }, debounceDelay);

    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [callback]);
};
