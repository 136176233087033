import React, { useCallback, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useCurrency } from 'hooks/common/useCurrency';
// import Button from 'components/shared/UI/Button/Button';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { ET } from 'modules/casino/shared/types/sportTypes/sportEvent.types';
import { MyBets } from 'pages/myAccount/tabs/history/sections/sportsHistory/types/myBets.types';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import CashierTicketContentHeader from './CashierTicketContentHeader';
import BetTerminal from '../../../features/checkTicket/components/BetSSBT';
import { CheckTicket, TicketBet } from '../../../types/ssbt.types';
import Systems from '../../betSelectionUI/betLogUI/Systems';

interface CashierTicketContentProps {
  ticketInfo: CheckTicket | null;
  ticketAcceptedDate?: string;
  duration?: number;

  betTypeText?: string;
  betSystem?: string | undefined;
  numberOfBetsText?: string;
  totalCombinations?: number;
  totalOddsText?: string;
  totalOddsToDisplay?: number | undefined;
  stakeText?: string;
  stakeAmount?: string;
  totalStakeText?: string;
  totalStakeAmount?: string;
  hasBetType?: boolean;
  systemText?: string;
  totalPossibleWin: number | string;
  bets: TicketBet[];

  bet: ET.TerminalOutcomesEvent extends MyBets.SelectionBonusesTypes ? ET.TerminalOutcomesEvent : TicketBet;
}

const CashierTicketContent: React.FC<CashierTicketContentProps> = ({
  ticketInfo,
  ticketAcceptedDate,
  duration = 0.3,
  bet,
  betTypeText,
  betSystem,
  numberOfBetsText,
  totalCombinations,
  totalOddsText,
  totalOddsToDisplay,
  stakeText,
  stakeAmount,
  totalStakeText,
  totalStakeAmount,
  hasBetType,
  systemText,
  totalPossibleWin,
  bets,
}) => {
  const { currencySymbol } = useCurrency();
  const { t } = useTranslation();
  const isCurrencyBeforeAmount = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.CURRENCY_BEFORE_AMOUNT,
  });

  const [isExpanded, setisExpanded] = useState<boolean>(true);
  const handleCollapsed = useCallback(() => {
    setisExpanded((prev) => !prev);
  }, []);

  return (
    <egtd-cashier-ticket-content-wrapper>
      <CashierTicketContentHeader
        ticketAcceptedDate={ticketAcceptedDate}
        ticketIdText={t('Cashier.CashierModal.betNumberCashier')}
        ticketId={bet.betId}
        isExpanded={isExpanded}
        icon={true}
        onClick={handleCollapsed}
      >
        {/* Logic for showing cashout btn need to be add */}
        {/* <Button variant="primary" size={isMobile ? 'md' : 'xl'} text="Cashout 20BGN" /> */}
      </CashierTicketContentHeader>
      <AnimatePresence initial={true}>
        {isExpanded && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            transition={{ duration: duration }}
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0.3, height: 0 },
            }}
            className="wrapper scroll-y no-scrollbars"
          >
            <egtd-cashier-ticket-content>
              <BetTerminal bet={bet} />

              <Systems
                betTypeText={`${betTypeText}`}
                betSystem={betSystem}
                numberOfBetsText={`${numberOfBetsText}`}
                totalCombinations={Number(totalCombinations)}
                totalOddsText={`${totalOddsText}`}
                totalOddsToDisplay={totalOddsToDisplay}
                stakeText={`${stakeText}`}
                stakeAmount={Number(stakeAmount).toFixed(2)}
                totalStakeText={`${totalStakeText}`}
                totalStakeAmount={Number(totalStakeAmount).toFixed(2)}
                systemText={`${systemText}`}
                hasBetType={hasBetType}
                isCurrencyBeforeAmount={isCurrencyBeforeAmount}
                possibleWin={totalPossibleWin}
                bets={bets}
                bet={bet}
                status={ticketInfo?.status}
                currency={currencySymbol}
              />
            </egtd-cashier-ticket-content>
          </motion.div>
        )}
      </AnimatePresence>
    </egtd-cashier-ticket-content-wrapper>
  );
};

export default CashierTicketContent;
