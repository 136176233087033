import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useCountDown } from 'hooks/common/useCountDown';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import { addLeadingZero, formatDateWithOffset } from 'utils/dateUtils';
import PrizeDropHeaderTimeCounterItem from './PrizeDropHeaderTimeCounterItem';
import PrizeDropTimeCounter from './PrizeDropTimeCounter';

type Props = {
  date: number;
  text: string;
  isColumn?: boolean;
  showDate?: boolean;
  setIsTimeExpired?: React.Dispatch<React.SetStateAction<boolean>>;
  slotStatus?: 'soon' | 'active' | 'ended';
  className?: string;
};

const PrizeDropHeaderTimeCounter: React.FC<Props> = ({
  slotStatus,
  date,
  text,
  isColumn,
  showDate,
  setIsTimeExpired,
  className,
}) => {
  const { t } = useTranslation();
  const { timer, isExpired } = useCountDown(date, 'systemTime');

  useEffect(() => {
    if (setIsTimeExpired) {
      setIsTimeExpired(isExpired);
    }
  }, [isExpired, setIsTimeExpired]);

  const classes = clsx(className, 'egtd-przd-counter-casino');

  return (
    <PrizeDropTimeCounter isColumn={isColumn} date={0} className={classes}>
      {(!isExpired || slotStatus === 'soon') && (
        <egtd-przd-counter-display>
          <div>{text}</div>
          {timer?.days !== undefined && (
            <PrizeDropHeaderTimeCounterItem
              date={addLeadingZero(timer.days > 0 ? timer.days : 0)}
              text={t(Promotions.PrizeDrop.days)}
            />
          )}
          {timer?.hours !== undefined && (
            <PrizeDropHeaderTimeCounterItem
              date={addLeadingZero(timer.hours > 0 ? timer.hours : 0)}
              text={t(Promotions.PrizeDrop.hours)}
            />
          )}
          {timer?.minutes !== undefined && (
            <PrizeDropHeaderTimeCounterItem
              date={addLeadingZero(timer.minutes > 0 ? timer.minutes : 0)}
              text={t(Promotions.PrizeDrop.minutes)}
            />
          )}
          {timer?.seconds !== undefined && (
            <PrizeDropHeaderTimeCounterItem
              date={addLeadingZero(timer.seconds > 0 ? timer.seconds : 0)}
              text={t(Promotions.PrizeDrop.seconds)}
            />
          )}
        </egtd-przd-counter-display>
      )}
      {showDate && isExpired && (
        <>
          <div className="mb-1">{t(Promotions.PrizeDrop.endedOnText)}</div>
          {formatDateWithOffset('DD.MM.YYYY HH:mm:ss', date)}
        </>
      )}
    </PrizeDropTimeCounter>
  );
};

export default PrizeDropHeaderTimeCounter;
