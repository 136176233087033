import { useEffect } from 'react';
import { useOnDeviceChange } from 'hooks/common/useOnDeviceChange';
import { useWindowDimensions } from 'hooks/useWindowDimenions';
import useProvidersFetch from 'modules/casino/modules/container/shared/features/casinoGameProviders/hooks/useProvidersFetch';
import { sessionBalanceThunk } from 'modules/retail/modules/ssbt/features/sessionBalance/slice/sessionBalance.slice';
import userStorage from 'pages/auth/login/UserStorage';
import { updateStoreFromLocalStorage } from 'pages/auth/login/utils/login.utils';
import { selectDeviceType } from 'shared/common/features/general/slice/deviceType.slice';
import { fetchBusinessUnitSettings, fetchCurrencySettings } from 'shared/common/features/general/slice/general.slice';
import { selectLocalizationUpdated } from 'shared/common/features/localization/slice/localization.slice';
import { updateOddTypeId } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { navigationMenuThunks } from 'shared/common/features/navigationMenu/slice/navigationMenu.slice';
import { metaDataThunks } from 'shared/common/features/seo/metaData/slice/metaData.slice';
import { selectLanguage } from 'shared/common/features/settings/slice/settings.slice';
import { setDeviceType } from 'shared/common/sharedSlices/commonActions';
import { useAppDispatch, useAppSelector } from 'store';

import { isCashier, isTerminal, loadPolyfills } from 'utils/common/helpersCommon';
import { useAuthentication } from './useAuthentication';
import useSetMidHeader from './useSetMidHeader';

export const useAppInit = (): void => {
  const deviceType = useAppSelector(selectDeviceType.deviceType);
  const language = useAppSelector(selectLanguage);
  const currentDeviceType = useAppSelector(selectDeviceType.deviceType);
  useSetMidHeader();
  const { isAuthenticated } = useAuthentication();
  const tzOffset = userStorage.getUserTimeZone();
  useAppSelector(selectLocalizationUpdated);
  const dispatch = useAppDispatch();
  const { height } = useWindowDimensions();

  useProvidersFetch();
  useOnDeviceChange((device) => {
    currentDeviceType !== device && dispatch(setDeviceType(device));
  });

  useEffect(() => {
    if (deviceType && language && tzOffset && isAuthenticated !== null) {
      dispatch(navigationMenuThunks.fetchVerticals());
      dispatch(fetchBusinessUnitSettings());
    }
  }, [deviceType, language, dispatch, tzOffset, isAuthenticated]);

  useEffect(() => {
    if (language) {
      dispatch(fetchCurrencySettings());
    }
  }, [language, dispatch]);
  useEffect(() => {
    if (isTerminal() && isAuthenticated) {
      dispatch(sessionBalanceThunk.checkForUpdateBalance());
    }
  }, [isAuthenticated]);
  useEffect(() => {
    loadPolyfills();
    dispatch(updateOddTypeId());

    const b = document.documentElement;
    b.setAttribute('data-useragent', navigator.userAgent);

    if (isTerminal() || isCashier()) {
      const element = document.documentElement;
      element.setAttribute('data-device', 'retail');
    }

    window.navigator.userAgent.includes('EGTTB');

    window.onstorage = (event: StorageEvent) => dispatch(updateStoreFromLocalStorage(event));

    if (window.isRNWebView) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ app_version: window.config.APP_MOBILE_VERSION }));
    }

    return () => {
      window.onstorage = null;
    };
  }, [dispatch]);

  useEffect(() => {
    if (language) {
      document.documentElement.setAttribute('lang', language);
      dispatch(metaDataThunks.metaData());
    }
  }, [language, dispatch]);

  useEffect(() => {
    const vh = height * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [height]);

  // Register `vite:preloadError` listener to handle dynamic import failures
  window.addEventListener('vite:preloadError', (_) => {
    //@ts-expect-error parameter
    window.location.reload(true);
  });
};
