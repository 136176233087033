import { useCallback, useEffect } from 'react';
import { useMyProfileConfig } from 'shared/common/features/myProfile/hooks/useMyProfileConfig';
import { myProfileThunks, selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { getDefaultBusinessUnitThemeSkin } from 'shared/common/features/myProfile/utils/myProfile.utils';
import { useAppDispatch, useAppSelector } from 'store';
import { usePreviousNew } from './usePreviousNew';

export const useTheme = () => {
  const myProfileLayout = useMyProfileConfig();
  const theme = useAppSelector(selectMyProfile.preferredTheme);
  const preferredTheme = myProfileLayout?.enableThemeSwitcher ? theme : getDefaultBusinessUnitThemeSkin(); //THIS IS TEMPORARY HACK
  const prevPreferredTheme = usePreviousNew(theme);

  const dispatch = useAppDispatch();

  const metaThemeColor = document.querySelector("meta[name='theme-color']");
  const mobileAppThemeDark = getComputedStyle(document.documentElement).getPropertyValue('--mobile-app-theme-dk');
  const mobileAppThemeLight = getComputedStyle(document.documentElement).getPropertyValue('--mobile-app-theme-lt');

  useEffect(() => {
    if (preferredTheme !== prevPreferredTheme) {
      if (preferredTheme === 'Light') {
        document.body.classList.remove('t--dark');
        document.body.classList.add('t--lite');
        metaThemeColor?.setAttribute('content', mobileAppThemeLight);
      } else if (preferredTheme === 'Dark') {
        document.body.classList.remove('t--lite');
        document.body.classList.add('t--dark');
        metaThemeColor?.setAttribute('content', mobileAppThemeDark);
      }
    }
  }, [preferredTheme, prevPreferredTheme]);

  const changeTheme = useCallback(() => {
    dispatch(
      myProfileThunks.updatePreferredTheme({
        preferredTheme: preferredTheme === 'Light' ? 'Dark' : 'Light',
      }),
    );
  }, [preferredTheme, dispatch]);

  return { preferredTheme, changeTheme };
};
