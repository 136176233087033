import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import Pagination from 'components/shared/UIPagination/Pagination';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { CashierTranslations } from 'modules/casino/shared/utils/translationUtils/translationStrings';
import SSBTLastStepTicketModal from 'modules/retail/modules/ssbt/features/checkTicket/components/SSBTLastStepTicketModal';
import { RetailHeaderIcons } from 'modules/retail/modules/ssbt/types/ssbt.types';
import RetailHeaderUserDefaultBtn from 'shared/common/features/appHeader/retailHeader/features/retailHeaderDefault/features/retailHeaderUser/components/RetailHeaderUserDefaultBtn';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { useAppDispatch, useAppSelector } from 'store';
import { Breakpoints } from 'theme/Theme';
import { formatCreatedDate, formatDateForReprintTicketSelectedPeriod, isEmpty } from 'utils/common/helpersCommon';
import { useReprintSelectors } from './hook/useReprintSelectors';

import { printTransactionHistoryDuplicatedTicket } from './hook/useTransactionHistoryPrint';
import {
  reprintInfoThunk,
  resetCheckTicketReprintInfoStatus,
  resetReprintReceiptInfo,
  setExpandedTicket,
} from './slice/reprintTicket.slice';
import { ReprintContentInfoProps, ReprintTicketHistory } from './type/reprint.types';
import ReprintTicketHeader from './UI/ReprintTicketHeader';
import ReprintTicketWrap from './UI/ReprintTicketWrap';
import ReprintTicketToggleBtn from './UI/ReprintToggleBtn';
import { CashierModalUI } from '../../cashierModalUI/Index';
import { selectCashierData, setIsPrintingEjected } from '../../slice/cashier.slice';

const ReprintContentInfo: React.FC<ReprintContentInfoProps> = ({
  ticketHistoryData,
  state,
  methods,
  t,
  disabledButtons,
  setDisabledButtons,
}) => {
  const dispatch = useAppDispatch();
  const { reprintInfo, reprintInfoStatus, firstSelectedFilters, expandedTicket, ticketInfo } = useReprintSelectors();
  const isPrintingEject = useAppSelector(selectCashierData.isPrintingEject);
  const isPrinterIdle = !isPrintingEject;
  const upperLimit = Math.min(state.selectedCurrentPage * state.numberOfItemsInPageSize, state.totalItems ?? 0);
  const handleMoreInfoClick = (ticketId: string, date: string, product, id: number) => {
    const isOmniPlayGames = product === 'Lotto' || product === 'RocketJet' || product === 'Joker';
    if (isOmniPlayGames) return;
    dispatch(
      setExpandedTicket(
        expandedTicket && expandedTicket.ticketId === ticketId && expandedTicket.createdDate === date
          ? null
          : { ticketId, createdDate: date, id: id },
      ),
    );
    isPrinterIdle && dispatch(reprintInfoThunk.getCheckTicketInfoByTicketHistoryId({ id }));
    dispatch(resetCheckTicketReprintInfoStatus());
  };
  const isCurrencyBeforeAmount = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.CURRENCY_BEFORE_AMOUNT,
  });

  useEffect(() => {
    if (!isEmpty(reprintInfo) && reprintInfoStatus === 'succeeded') {
      printTransactionHistoryDuplicatedTicket(reprintInfo, t, dispatch, isCurrencyBeforeAmount);
    }
    if (reprintInfoStatus === 'failure') {
      dispatch(setIsPrintingEjected(false));
    } else {
      return;
    }
  }, [reprintInfo, reprintInfoStatus]);

  const initialDisabledState = useMemo(() => {
    if (!ticketHistoryData?.items) return {};

    return ticketHistoryData.items.reduce((acc, item) => {
      if (!item.allowReprint) {
        const uniqueKey = `${item.platformTicketId}_${item.createdDate}`;
        acc[uniqueKey] = true;
      }
      return acc;
    }, {});
  }, [ticketHistoryData?.items]);

  useEffect(() => {
    setDisabledButtons(initialDisabledState);
  }, [initialDisabledState]);
  const selectedPeriod = !isEmpty(firstSelectedFilters?.selectedDate?.startDate)
    ? `${t(CashierTranslations.FinancialModal.selectedPeriod)}: ${formatDateForReprintTicketSelectedPeriod(state?.selectedDates?.startDate)} - ${formatDateForReprintTicketSelectedPeriod(state?.selectedDates?.endDate)}`
    : '';

  const handlePrint = (ticketId, createdDate, id) => {
    const uniqueKey = `${ticketId}_${createdDate}`;

    // Disable the clicked button immediately
    setDisabledButtons((prevState) => ({
      ...prevState,
      [uniqueKey]: true, // Disable this specific button by ticketId
    }));

    try {
      dispatch(setIsPrintingEjected(true)); // this is resolving a bug if a ticket is scanned right after pressing the reprint button
      dispatch(reprintInfoThunk.getTicketReprintInfoForReceipt({ id }));
    } catch (error) {
      return error;
    } finally {
      dispatch(resetReprintReceiptInfo());
    }
  };

  const isMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isMobile}px)` });
  const totalPage = Math.ceil((state.totalItems ?? 0) / 10);

  const classOuterProp = { class: 'custom-scrollbars overflow-x-hidden overflow-y-auto' };
  const classPeriodProp = { class: clsx(isMobile ? 'd-flex-jb' : 'mt-1 mb-1 text-center') };

  if (!ticketHistoryData || isEmpty(ticketHistoryData.items)) {
    return (
      <>
        <CashierModalUI.InfoMessage
          className="m-auto"
          icon="calendar-outline"
          infoMessage={t(CashierTranslations.TransactionHistoryReprintModal.noDataForSelectedFilters)}
          hasError={true}
        />
      </>
    );
  }

  return (
    <>
      {isMobile && (
        <Pagination
          currentPage={state.selectedCurrentPage - 1}
          totalPages={totalPage}
          totalResults={state.totalItems ?? 0}
          totalResultsTitle={`${t(CashierTranslations.TransactionHistoryReprintModal.totalTickets)} ${upperLimit}`}
          sidePagesCount={2}
          hasButtons={'prev-next'}
          hasPages={'all'}
          isDisabled={false}
          setPage={(e) => {
            methods.setCurrentPage(e + 1);
          }}
          className={'egtd-reprint'}
          isReprint
        />
      )}

      <egtd-reprint-ticket-outer {...classOuterProp}>
        <egtd-reprint-ticket-period {...classPeriodProp}>
          {selectedPeriod}{' '}
          {isMobile && (
            <div className="currentPage">
              Page: <span>{state.selectedCurrentPage}</span>
            </div>
          )}
        </egtd-reprint-ticket-period>
        {ticketHistoryData?.items.map((ticket: ReprintTicketHistory) => (
          <ReprintTicketWrap key={ticket.platformTicketId + ticket.createdDate}>
            <ReprintTicketHeader
              ticketId={ticket.platformTicketId}
              createDate={formatCreatedDate(ticket.createdDate)}
              amount={parseFloat(ticket.amount.toFixed(2))}
              product={ticket.product}
              tickedStatus={ticket.tickedStatus}
              paymentTickedStatus={ticket.paymentTickedStatus}
              mnemonicCode={ticket.mnemonicCode || ''}
              onClick={() =>
                handleMoreInfoClick(ticket.platformTicketId, ticket.createdDate, ticket.product, ticket.id)
              }
              isCurrencyBeforeAmount={isCurrencyBeforeAmount}
            >
              <RetailHeaderUserDefaultBtn
                onClick={() => {
                  handlePrint(ticket.platformTicketId, ticket.createdDate, ticket.id);
                }}
                text={t(CashierTranslations.TransactionHistoryReprintModal.reprintBtn)}
                icon={RetailHeaderIcons.REPRINT}
                isPending={false}
                isDisabled={disabledButtons[`${ticket.platformTicketId}_${ticket.createdDate}`] || !ticket.allowReprint}
                isReprint={true}
              />
              {ticket.product !== 'Lotto' && ticket.product !== 'RocketJet' && ticket.product !== 'Joker' && (
                <ReprintTicketToggleBtn
                  onClick={() =>
                    handleMoreInfoClick(ticket.platformTicketId, ticket.createdDate, ticket.product, ticket.id)
                  }
                  isExpanded={
                    expandedTicket?.ticketId === ticket.platformTicketId &&
                    expandedTicket?.createdDate === ticket.createdDate
                  }
                />
              )}
            </ReprintTicketHeader>

            {expandedTicket?.ticketId === ticket.platformTicketId &&
              expandedTicket?.createdDate === ticket.createdDate &&
              !isEmpty(ticketInfo) && (
                <SSBTLastStepTicketModal
                  ticketInfo={ticketInfo.ticket}
                  closeTerminalModal={() => {}}
                  isReprint={true}
                  expandedTicketId={expandedTicket.id}
                />
              )}
          </ReprintTicketWrap>
        ))}
      </egtd-reprint-ticket-outer>

      <Pagination
        currentPage={state.selectedCurrentPage - 1}
        totalPages={Math.ceil((state.totalItems ?? 0) / 10)}
        totalResults={state.totalItems ?? 0}
        totalResultsTitle={`${t(CashierTranslations.TransactionHistoryReprintModal.totalTickets)} ${upperLimit}`}
        sidePagesCount={2}
        hasButtons={'prev-next'}
        hasPages={'all'}
        isDisabled={false}
        setPage={(e) => {
          methods.setCurrentPage(e + 1);
        }}
        className={'egtd-reprint'}
        isReprint
      />
    </>
  );
};

export default ReprintContentInfo;
