import clsx from 'clsx';

/** Terminal Modal Header Section */
interface HeaderProps {
  text: string;
  className?: string;
}

const Header: React.FC<HeaderProps> = ({ text, className }) => {
  const headerClass = clsx('text-center terminal-modal__header', className);
  return <div className={headerClass}>{text}</div>;
};

export default Header;
