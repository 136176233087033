import React from 'react';
import { ValidationValueMessage } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomDatePicker from 'components/shared/forms/components/datepicker';
import { InputTypes, FormControlTypes, ValidationRules } from 'components/shared/forms/formTypes';
import FormButton from './FormButton';
import FormButtonGroup from './FormButtonGroup/FormButtonGroup';
import { ButtonGroupNames } from './FormButtonGroup/formButtonGroup.types';
import FormDropdown from './FormDropdown';
import FormInputCheckbox from './FormInputCheckbox';
import FormInputUI from './FormInputUI';
import FormInputUICaptcha from './FormInputUICaptcha';
import FormOTP from './FormOTP';
import FormRadio from './FormRadio';
import { SocialButtonInputTypes } from './FormSocial/types/formSocial.types';
import FormSwitch from './FormSwitch';
import PhoneInputSelect from './PhoneInputSelect';
import useFormControl from '../hooks/useFormControl';

const FormControl: React.FC<FormControlTypes> = (props) => {
  const {
    type = InputTypes.INPUT,
    name,
    label,
    inputType,
    validationRules,
    options,
    link,
    linkLabel,
    linkType,
    links,
    placeholder,
    onFocus,
    inputIcon,
    componentLocation,
    isMobileAgnostic,
    validation,
    classNames,
    disabled,
    groupeClass,
    numInputs = 6,
    shouldAutoFocus,
    inputMode,
    isOutlined,
    hasError: submitError,
    apiUrl,
    readOnly,
    defaultValue,
    preselectValue,
    status,
    CustomComponent,
    isHidden,
    ...rest
  } = props;
  const { t } = useTranslation();

  const { controlRef, onChange, onBlur, hasError, hasSuccess, errorMessage, invalid, value } = useFormControl(props);
  if (CustomComponent)
    return (
      <CustomComponent
        key={name}
        {...props}
        value={value}
        label={t(label || '')}
        placeholder={t(placeholder || '')}
        controlRef={controlRef}
        onFocus={onFocus}
        onChange={onChange}
        onBlur={onBlur}
        errorMessage={errorMessage}
      />
    );
  switch (type) {
    case InputTypes.INPUT:
      return (
        <FormInputUI
          {...rest}
          key={name}
          value={value}
          componentLocation={componentLocation}
          classNames={classNames}
          label={t(label || '')}
          placeholder={t(placeholder || '')}
          name={name}
          type={inputType}
          hasError={hasError}
          hasSuccess={hasSuccess}
          hasErrorIcon={rest.hasErrorIcon && invalid}
          hasSuccessIcon={rest.hasSuccessIcon && hasSuccess}
          isOutlined={isOutlined}
          maxLength={validationRules?.maxLength?.value}
          minLength={validationRules?.minLength?.value}
          maxValue={validationRules?.maxValue?.value}
          minValue={validationRules?.minValue?.value}
          iconPrefix={inputIcon}
          isMobileAgnostic={isMobileAgnostic}
          errorMessage={errorMessage}
          controlRef={controlRef}
          disabled={disabled}
          onFocus={onFocus}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={readOnly}
        />
      );
    case InputTypes.CHECKBOX_INPUT:
      return (
        <FormInputCheckbox
          {...rest}
          key={name}
          value={value}
          componentLocation={componentLocation}
          classNames={classNames}
          label={t(label || '')}
          placeholder={t(placeholder || '')}
          name={name}
          type={inputType}
          hasError={hasError}
          hasSuccess={hasSuccess}
          hasErrorIcon={rest.hasErrorIcon && invalid}
          hasSuccessIcon={rest.hasSuccessIcon && hasSuccess}
          maxLength={validationRules?.maxLength?.value}
          minLength={validationRules?.minLength?.value}
          iconPrefix={inputIcon}
          isMobileAgnostic={isMobileAgnostic}
          errorMessage={errorMessage}
          controlRef={controlRef}
          disabled={disabled}
          onFocus={onFocus}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={readOnly}
          validationRules={validationRules as ValidationRules<ValidationValueMessage<boolean>>}
        />
      );
    case InputTypes.CAPTCHA:
      return (
        <FormInputUICaptcha
          {...rest}
          key={name}
          value={value}
          componentLocation={componentLocation}
          classNames={classNames}
          label={t(label || '')}
          placeholder={t(placeholder || '')}
          name={name}
          type={inputType}
          hasError={hasError}
          hasSuccess={hasSuccess}
          hasErrorIcon={rest.hasErrorIcon && invalid}
          hasSuccessIcon={rest.hasSuccessIcon && hasSuccess}
          maxLength={validationRules?.maxLength?.value ?? rest?.maxLength}
          minLength={validationRules?.minLength?.value ?? rest?.minLength}
          iconPrefix={inputIcon}
          isMobileAgnostic={isMobileAgnostic}
          errorMessage={errorMessage}
          controlRef={controlRef}
          disabled={disabled}
          onFocus={onFocus}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={readOnly}
        />
      );
    case InputTypes.RADIO:
      return (
        <FormRadio
          options={options}
          label={t(label || '')}
          classNames={classNames}
          groupeClass={groupeClass}
          name={name}
          onChange={onChange}
          controlRef={controlRef}
          errorMessage={errorMessage}
          value={value}
          onBlur={onBlur}
          componentLocation={componentLocation}
          isOutlined={isOutlined}
          disabled={disabled}
          readOnly={readOnly}
        />
      );
    case InputTypes.DATETIME:
      return (
        <CustomDatePicker
          componentLocation={componentLocation}
          label={t(label || '')}
          hasError={hasError}
          errorMessage={errorMessage}
          validation={validation}
          isMobileAgnostic={isMobileAgnostic}
          onChange={onChange}
          defaultValue={defaultValue}
          disabled={disabled}
          readOnly={readOnly}
        />
      );
    case InputTypes.CHECKBOX:
      return (
        <FormSwitch
          componentLocation={componentLocation}
          label={t(label || '')}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          errorMessage={errorMessage}
          link={link}
          linkLabel={linkLabel}
          linkType={linkType}
          links={links}
          controlRef={controlRef}
          classNames={classNames}
          value={value}
          position={rest.position}
          fieldLabel={rest.fieldLabel}
          disabled={disabled}
          readOnly={readOnly}
          isHidden={isHidden}
        />
      );
    case InputTypes.PHONENUMBER:
      return (
        <PhoneInputSelect
          componentLocation={componentLocation}
          name={name}
          label={t(label || '')}
          preferredOptions={options as unknown as string[]}
          errorMessage={errorMessage}
          onChangeHandler={onChange}
          onFocus={onBlur}
          onBlur={onBlur}
          hasError={hasError}
          hasSuccess={hasSuccess}
          value={value}
          classNames={classNames}
          disabled={disabled}
          status={status}
        />
      );
    case InputTypes.SELECT:
      return (
        <FormDropdown
          componentLocation={componentLocation}
          name={name}
          options={options}
          label={t(label || '')}
          placeholder={t(placeholder || label || '')}
          onChange={onChange}
          onBlur={onBlur}
          classNames={classNames}
          controlRef={controlRef}
          disabled={disabled}
          value={value}
          isOutlined={isOutlined}
          isControlled
          apiUrl={apiUrl}
          preselectValue={preselectValue}
        />
      );
    case InputTypes.OTP:
      return (
        <FormOTP
          name={type}
          shouldAutoFocus={shouldAutoFocus}
          value={value}
          onChange={onChange}
          numInputs={numInputs}
          inputType={inputType}
          inputMode={inputMode}
          errorMessage={errorMessage}
          hasError={hasError || submitError}
          disabled={disabled}
          readOnly={readOnly}
        />
      );
    case InputTypes.BUTTON:
      return <FormButton className="w-100" text={label} disabled={disabled} />;
    case InputTypes.BUTTON_GROUP:
      return (
        <FormButtonGroup
          key={name}
          name={name as ButtonGroupNames}
          inputType={inputType as SocialButtonInputTypes}
          componentLocation={componentLocation}
          label={props.label}
          endLabel={props.endLabel}
          buttons={props.buttons}
          disabled={disabled}
        />
      );
    default:
      return null;
  }
};

export default FormControl;
