import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import usePollingInterval from 'hooks/common/usePollingInterval';
import {
  cashierThunks,
  disableScanDocument,
  enableScanDocument,
  selectCashierData,
} from 'modules/retail/modules/cashier/slice/cashier.slice';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch, useAppSelector } from 'store';
import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';

export const useCheckTerminalActivity = () => {
  const dispatch = useAppDispatch();
  const terminalActivity = useAppSelector(selectCashierData.terminalActivity);
  const { closeModal } = useHandleModals();
  const interval = usePollingInterval();
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const { t } = useTranslation();
  const pollTerminalStatus = useCallback(() => {
    dispatch(cashierThunks.statusTerminal());
  }, [dispatch]);
  useEffect(() => {
    const shouldEnableScan = isAuthenticated && terminalActivity === 'Active';

    if (terminalActivity !== 'Active') {
      disableScanDocument();
      return;
    }
    if (shouldEnableScan) {
      enableScanDocument();
    }
    const pollingInterval = setInterval(pollTerminalStatus, interval);

    return () => clearInterval(pollingInterval);
  }, [terminalActivity, isAuthenticated]);

  useEffect(() => {
    if (terminalActivity !== 'Active')
      dispatch(
        showRootModal({
          modalType: 'DEACTIVATED_TERMINAL_MODAL',
          modalId: 'DEACTIVATED_TERMINAL_MODAL',
          modalPriority: ModalPriorityEnum.HIGH,
          modalProps: {
            message:
              terminalActivity === 'QuietHours'
                ? t(CashierTranslations.DeactiveTerminalModal.quietHours)
                : t(CashierTranslations.DeactiveTerminalModal.messageDeactivated),
            resetStatus: pollTerminalStatus,
          },
        }),
      );

    if (terminalActivity === 'Active') {
      closeModal();
    }
  }, [terminalActivity]);
};
