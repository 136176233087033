import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';
import { FinanceInformation } from './type/financeReport.types';
import { formatCurrency } from '../../utils';

export const financialRecords = (t, financeInfo: FinanceInformation, isCurrencyBeforeAmount: boolean) => {
  return [
    {
      id: 0,
      text: t(CashierTranslations.FinancialModal.acceptedTicketsCount),
      value: financeInfo.numberOfTickets,
    },
    {
      id: 1,
      text: t('Cashier.FinancialModal.acceptedTicketsAmount'),
      value: formatCurrency(financeInfo.betAmount, financeInfo.currency, isCurrencyBeforeAmount),
    },
    {
      id: 2,
      text: t(CashierTranslations.FinancialModal.paidTicketsCount),
      value: financeInfo.paidTickets,
    },
    {
      id: 3,
      text: t(CashierTranslations.FinancialModal.paidAmountValue),
      value: formatCurrency(financeInfo.paidAmount, financeInfo.currency, isCurrencyBeforeAmount),
    },
    {
      id: 6,
      text: t(CashierTranslations.FinancialModal.cancelledTicketsCount),
      value: financeInfo.cancelledTickets,
    },
    {
      id: 7,
      text: t(CashierTranslations.FinancialModal.cancelledAmountValue),
      value: formatCurrency(financeInfo.cancelledAmount, financeInfo.currency, isCurrencyBeforeAmount),
    },
    {
      id: 8,
      text: t(CashierTranslations.FinancialModal.numberOfVoidedBets),
      value: `${financeInfo.voidedTickets}`,
    },
    {
      id: 9,
      text: t(CashierTranslations.FinancialModal.voidedBetsAmount),
      value: formatCurrency(financeInfo.voidedBetsAmount, financeInfo.currency, isCurrencyBeforeAmount),
    },
    {
      id: 10,
      text: t(CashierTranslations.FinancialModal.depositedFromPosAmount),
      value: formatCurrency(financeInfo.depositedFromPosAmount, financeInfo.currency, isCurrencyBeforeAmount),
    },
    {
      id: 11,
      text: t(CashierTranslations.FinancialModal.depositedToPosAmount),
      value: formatCurrency(financeInfo.depositedToPosAmount, financeInfo.currency, isCurrencyBeforeAmount),
    },
  ];
};

export const financialCommission = (t, commission, isCurrencyBeforeAmount: boolean) => {
  if (!commission) {
    return [];
  }

  return [
    {
      id: 0,
      text: t('Cashier.FinancialModal.betAmountVirtual'),
      value: formatCurrency(commission.betAmountVirtual, commission.currency, isCurrencyBeforeAmount),
    },
    {
      id: 1,
      text: t('Cashier.FinancialModal.betAmountSport'),
      value: formatCurrency(commission.betAmountSport, commission.currency, isCurrencyBeforeAmount),
    },
    {
      id: 2,
      text: t('Cashier.FinancialModal.cancelledAmountVirtual'),
      value: formatCurrency(commission.cancelledAmountVirtual, commission.currency, isCurrencyBeforeAmount),
    },
    {
      id: 3,
      text: t('Cashier.FinancialModal.cancelledBetsAmountSport'),
      value: formatCurrency(commission.cancelledBetsAmountSport, commission.currency, isCurrencyBeforeAmount),
    },
    {
      id: 4,
      text: t('Cashier.FinancialModal.voidedAmountVirtual'),
      value: formatCurrency(commission.voidedAmountVirtual, commission.currency, isCurrencyBeforeAmount),
    },
    {
      id: 5,
      text: t('Cashier.FinancialModal.voidedAmountSport'),
      value: formatCurrency(commission.voidedAmountSport, commission.currency, isCurrencyBeforeAmount),
    },
    {
      id: 6,
      text: t('Cashier.FinancialModal.winningsAmountVirtual'),
      value: formatCurrency(commission.winningsAmountVirtual, commission.currency, isCurrencyBeforeAmount),
    },
    {
      id: 7,
      text: t('Cashier.FinancialModal.winningsAmountSport'),
      value: formatCurrency(commission.winningsAmountSport, commission.currency, isCurrencyBeforeAmount),
    },
    {
      id: 8,
      text: t('Cashier.FinancialModal.comissionTurnoverVirtual'),
      value: formatCurrency(commission.comissionTurnoverVirtual, commission.currency, isCurrencyBeforeAmount),
    },
    {
      id: 9,
      text: t('Cashier.FinancialModal.comissionTurnoverSport'),
      value: formatCurrency(commission.comissionTurnoverSport, commission.currency, isCurrencyBeforeAmount),
    },
    {
      id: 10,
      text: t('Cashier.FinancialModal.betAmountGreyhounds'),
      value: formatCurrency(commission.betAmountGreyhounds, commission.currency, isCurrencyBeforeAmount),
    },
    {
      id: 11,
      text: t('Cashier.FinancialModal.comissionGgrSport'),
      value: formatCurrency(commission.comissionGgrSport, commission.currency, isCurrencyBeforeAmount),
    },
    {
      id: 12,
      text: t('Cashier.FinancialModal.cancelledTicketsAmountGreyhounds'),
      value: formatCurrency(commission.cancelledTicketsAmountGreyhounds, commission.currency, isCurrencyBeforeAmount),
    },
    {
      id: 13,
      text: '',
      value: '',
    },
    {
      id: 14,
      text: t('Cashier.FinancialModal.voidedTicketsAmountGreyhounds'),
      value: formatCurrency(commission.voidedTicketsAmountGreyhounds, commission.currency, isCurrencyBeforeAmount),
    },
    {
      id: 15,
      text: '',
      value: '',
    },
    {
      id: 16,
      text: t('Cashier.FinancialModal.winningsAmountGreyhounds'),
      value: formatCurrency(commission.winningsAmountGreyhounds, commission.currency, isCurrencyBeforeAmount),
    },
    {
      id: 17,
      text: t('Cashier.FinancialModal.totalTurnoverCommission'),
      value: formatCurrency(commission.totalTurnoverCommission, commission.currency, isCurrencyBeforeAmount),
    },
    {
      id: 18,
      text: t('Cashier.FinancialModal.comissionTurnoverGreyhounds'),
      value: formatCurrency(commission.comissionTurnoverGreyhounds, commission.currency, isCurrencyBeforeAmount),
    },
    {
      id: 19,
      text: t('Cashier.FinancialModal.totalCommission'),
      value: formatCurrency(commission.totalCommission, commission.currency, isCurrencyBeforeAmount),
    },
  ];
};
