import { useEffect, useRef, useCallback } from 'react';
import { HubConnectionBuilder, HubConnection, LogLevel } from '@microsoft/signalr';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import userStorage from 'pages/auth/login/UserStorage';
import { selectLanguage } from 'shared/common/features/settings/slice/settings.slice';
import { store, useAppDispatch, useAppSelector } from 'store';
import { getBrowserTabId, getBusinessUnit, isEmpty, localLog } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { ws } from './slice/webSockets.slice';
import { onMessageReceivedSignalR } from './WebsocketHandlers';
import { selectDeviceType } from '../general/slice/deviceType.slice';

export const WS_SERVICE_VERSION = 5;

export const WebSocketSignalR = (): null => {
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  // when getting new token, isAuthenticated is still true, using refreshTokenLoading to start new connection
  const refreshTokenLoading = useAppSelector(selectAuthentication.refreshTokenLoading);
  const currentWsSession = useAppSelector(ws.selectSession);
  const dispatch = useAppDispatch();
  const deviceType = useAppSelector(selectDeviceType.deviceType);

  const removeKeys = useAppSelector(ws.selectRemoveKeys);
  const addKeys = useAppSelector(ws.selectAddKeys);

  const currentLanguage = useAppSelector(selectLanguage);
  const signalRClient = useRef<HubConnection | null>(null);

  const connect = useCallback(
    async (language: string, device: string) => {
      const bu = getBusinessUnit();
      const user = userStorage.getUser();
      const token = user.access_token;

      const fingerprint = `ws-${bu}-${getBrowserTabId()}_${user.player_id}`;
      let wsUrl = `${config.API_URL}/ws2?fingerprint=${fingerprint}&language=${language}&bu=${bu}&device=${device}&serviceVer=${WS_SERVICE_VERSION}`;

      if (token) {
        wsUrl += `&Authorization=${token}`;
      }

      if (currentWsSession && fingerprint !== currentWsSession) {
        dispatch(ws.resetSubs());
      }

      signalRClient.current = new HubConnectionBuilder()
        .withUrl(wsUrl)
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: () => 1000,
        })
        .configureLogging(LogLevel.Warning)
        .build();

      const start = async () => {
        try {
          await signalRClient?.current?.start();
          dispatch(ws.setSession(fingerprint));
          dispatch(ws.setStatus('init'));
        } catch (err) {
          localLog({ message: `start error ${err}`, type: 'error' });
        }
      };

      const handleMessages = (contentType, routingKey, payload) => {
        onMessageReceivedSignalR({ dispatch, contentType, routingKey, payload });
      };

      const handleReconnecting = () => {
        dispatch(ws.setStatus('close'));
      };

      const handleReconnected = () => {
        dispatch(ws.setStatus('init'));
        const keys = ws.selectRoutingKeys(store.getState());
        signalRClient.current?.invoke('SetBindings', keys);
      };

      const handleOnClose = () => {
        dispatch(ws.setStatus('close'));
      };
      const handleConnected = () => localLog({ message: `connected`, type: 'warn' });

      signalRClient.current.on('msg', handleMessages);
      signalRClient.current.onreconnecting(handleReconnecting);
      signalRClient.current.onreconnected(handleReconnected);
      signalRClient.current.onclose(handleOnClose);
      signalRClient.current.on('connected', handleConnected);

      start();
    },
    [dispatch, isAuthenticated],
  );

  useEffect(() => {
    if (isAuthenticated && !refreshTokenLoading && currentLanguage !== '' && deviceType) {
      connect(currentLanguage, deviceType);
    }

    return () => {
      if (signalRClient.current) {
        signalRClient.current.stop();
        signalRClient.current = null;
      }
    };
  }, [isAuthenticated, connect, refreshTokenLoading, currentLanguage, deviceType]);

  useEffect(() => {
    if (signalRClient.current?.state === 'Connected' && !isEmpty(addKeys)) {
      signalRClient.current.invoke('AddBindings', addKeys);
      dispatch(ws.filtersRoutingKeys({ type: 'addKeys', keys: addKeys }));
    }
  }, [addKeys, dispatch]);

  useEffect(() => {
    if (signalRClient.current?.state === 'Connected' && !isEmpty(removeKeys)) {
      signalRClient.current.invoke('RemoveBindings', removeKeys);
      dispatch(ws.filtersRoutingKeys({ type: 'removeKeys', keys: removeKeys }));
    }
  }, [removeKeys, dispatch]);

  return null;
};
