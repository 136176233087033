import { useEffect } from 'react';
import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { useAppDispatch, useAppSelector } from 'store';
import { useGameCollectionId } from '../../../../../cellls/hooks/useGameCollectionId';
import { categoryImpressionsSelectors, registerCategoryGame } from '../slice/categoryImpressionsSlice';

type Props = {
  inView: boolean;
  gameUniqueId: string;
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
  index: number;
};

export const useCategoryCellImpressions = ({ inView, gameUniqueId, categoryId, categoryLayoutType, index }: Props) => {
  const dispatch = useAppDispatch();
  const { refGameId, description, providerId } = useGameProps({ gameUniqueId, categoryId });
  const { gameCollectionId } = useGameCollectionId({ categoryId, categoryLayoutType });
  const isCategoryRegistered = useAppSelector((state) =>
    categoryImpressionsSelectors.isCategoryRegistered(state, gameCollectionId),
  );

  useEffect(() => {
    if (isCategoryRegistered && inView) {
      dispatch(
        registerCategoryGame({
          gameCollectionId,
          gameData: { rank: index, gameId: refGameId, gameName: description, providerId },
        }),
      );
    }
  }, [dispatch, isCategoryRegistered, inView, gameCollectionId]);
};
