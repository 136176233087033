import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { FormGroup } from 'components/shared/forms/components';
import { UI } from 'components/shared/UI';
import { useAppDispatch } from 'store';
import { Breakpoints } from 'theme/Theme';
import { BetSlip, CashierTranslations } from 'utils/common/translationUtils/translationStrings';
import { clearFinancialInfoCommission, resetFinanceStatus } from './slice/financialReports.slice';
import FinancialDatePicker from '../../cashierModalUI/FinancialDatePicker';
import { CashierModalUI } from '../../cashierModalUI/Index';

type SelectedDatesType = {
  startDate: Date | null;
  endDate: Date | null;
};

type Props = {
  isBalanceReport: boolean;
  selectedStartDate: Date | null;
  setSelectedDates: React.Dispatch<React.SetStateAction<SelectedDatesType>>;
  selectedEndDate: Date | null;
  handleSearchButton: () => void;
};

const FinancialDateControls: React.FC<Props> = ({
  isBalanceReport,
  selectedStartDate,
  setSelectedDates,
  selectedEndDate,
  handleSearchButton,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });

  // Correctly checking if the date period is valid
  const isPeriodValid = !selectedStartDate || !selectedEndDate;

  const clearDates = () => {
    if (isBalanceReport) {
      dispatch(resetFinanceStatus());
    } else {
      dispatch(clearFinancialInfoCommission());
    }
    setSelectedDates({ startDate: null, endDate: null });
  };

  return (
    <>
      <CashierModalUI.InputWrapper>
        <FormGroup>
          <FinancialDatePicker
            label={t(CashierTranslations.FinancialModal.startDate)}
            dateFormat="dd/MM/yyyy"
            onChange={(date: Date | null) => {
              setSelectedDates((prevDates) => ({
                ...prevDates,
                startDate: date,
              }));
            }}
            value={
              selectedStartDate
                ? new Date(selectedStartDate).toLocaleDateString()
                : t(CashierTranslations.FinancialModal.chooseDay)
            }
            selected={selectedStartDate ? new Date(selectedStartDate) : null}
            dataQid="fromDate"
            maxDate={selectedEndDate ? new Date(selectedEndDate) : new Date()} // Max date is today or selected end date
          />
        </FormGroup>
        <FormGroup>
          <FinancialDatePicker
            label={t(CashierTranslations.FinancialModal.endDate)}
            dateFormat="dd/MM/yyyy"
            onChange={(date: Date | null) => {
              setSelectedDates((prevDates) => ({
                ...prevDates,
                endDate: date,
              }));
            }}
            value={
              selectedEndDate
                ? new Date(selectedEndDate).toLocaleDateString()
                : t(CashierTranslations.FinancialModal.chooseDay)
            }
            selected={selectedEndDate ? new Date(selectedEndDate) : null}
            dataQid="toDate"
            minDate={selectedStartDate ? new Date(selectedStartDate) : undefined} // Min date is the selected start date
            maxDate={new Date()} // Max date is today
          />
        </FormGroup>

        <CashierModalUI.ButtonsWrapper>
          <UI.Button
            size={isDesktop ? '2xl' : 'lg'}
            variant="secondary"
            text={`${t(BetSlip.Header.clearSelections)}`}
            onClick={clearDates}
          />
          <UI.Button
            text={`${t(CashierTranslations.FinancialModal.searchButton)}`}
            size={isDesktop ? '2xl' : 'lg'}
            variant="primary"
            isDisabled={isPeriodValid}
            onClick={handleSearchButton}
          />
        </CashierModalUI.ButtonsWrapper>
      </CashierModalUI.InputWrapper>
    </>
  );
};

export default FinancialDateControls;
