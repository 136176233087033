import React from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';

type Props = {
  text: string;
  isActive?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  controlRef;
};

const FooterButton: React.FC<Props> = ({ text, isActive, onClick, controlRef }) => {
  const btnClasses = clsx('bsl-footer__btn', isActive && 'bsl-footer__btn--active');

  return (
    <Flex.Center className={btnClasses} onClick={onClick} ref={controlRef}>
      <span className="position-relative bsl-footer__btn-text">{text}</span>
    </Flex.Center>
  );
};

export default FooterButton;
