import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';

import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { CalendarPreviewTypes } from '../types/calendarPreview.types';

const initialState: CalendarPreviewTypes.InitialState = {
  calendar: null,
  isLoaded: false,
};

export const calendarPreviewThunks: CalendarPreviewTypes.Thunks = {
  getCalendarPreview: createAbortThunk(
    'store/getCalendarPreview',
    async ({ url }, { rejectWithValue, signal, source }) => {
      try {
        signal.addEventListener('abort', () => source.cancel());

        const urlPath = `${config.API_URL}${url}`;

        const response = await axiosInstance.get(urlPath);

        return response.data;
      } catch (err) {
        if (Axios.isCancel(err)) {
          return rejectWithValue(null);
        }
        return rejectWithValue(getError.default());
      }
    },
  ),
};

const calendarPreview = createSlice({
  name: 'store',
  initialState,
  reducers: {
    resetCalendarPreview: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(calendarPreviewThunks.getCalendarPreview.pending, () => {})
      .addCase(
        calendarPreviewThunks.getCalendarPreview.fulfilled,
        (state, action: PayloadAction<CalendarPreviewTypes.CalendarApi>) => {
          state.calendar = action.payload;
          // state.calendar = calendarPreviewMock;
          state.isLoaded = true;
        },
      )
      .addCase(calendarPreviewThunks.getCalendarPreview.rejected, (state) => {
        state.isLoaded = true;
      });
  },
});
export const { resetCalendarPreview } = calendarPreview.actions;
export const { reducer: calendarPreviewReducer } = calendarPreview;
export const selectCalendarPreview = {
  calendar: (state: RootState) => state.calendarPreview.calendar,
  isLoaded: (state: RootState) => state.calendarPreview.isLoaded,
};
