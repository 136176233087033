import React from 'react';
import clsx from 'clsx';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const InputGroup: React.FC<Props> = ({ className, children }) => {
  const inputGroupStyles = clsx(['input-group', className]);

  return <div className={inputGroupStyles}>{children}</div>;
};
InputGroup.displayName = 'InputGroup';
export default InputGroup;
