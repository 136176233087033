import { ProcessCustomBetSelectionParams } from 'modules/retail/modules/ssbt/types/ssbt.types';
import { getError } from 'utils/common/helpersCommon';
import { mapBetbuilderEventsData, mapBetbuilderSign, mapBetbuilderSummaryBetData } from './betBuilderMethods';
import { printReceipt } from '../printUtils/helperPrintFunctions';

export const processCustomBetSelection = async <K extends { outcomeId: number | string; totalCombinations: number }>({
  value,
  getState,
  currency,
  isSingle,
  singles,
  getSingleBetProps,
  isCurrencyBeforeAmount,
}: ProcessCustomBetSelectionParams<K>): Promise<void> => {
  const mapBetbuilderSummaryBetDataObj = {
    value,
    currency,
    isSingle,
    singles,
    getSingleBetProps,
    isCurrencyBeforeAmount,
  };
  try {
    await printReceipt(mapBetbuilderSign({ value }));

    for (const customBet of value.customBetOutcomes) {
      await printReceipt(mapBetbuilderEventsData({ customBet, getState }));
    }
    await printReceipt(mapBetbuilderSummaryBetData(mapBetbuilderSummaryBetDataObj));
  } catch (error) {
    getError.message(error);
  }
};
