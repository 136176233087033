import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { EmailChangedConfirmThunk, EmailChangedConfirmType } from '../types/emailChangedConfirm.types';

const initialState: EmailChangedConfirmType = {
  submitError: '',
  isValidToken: false,
  isExpiredToken: false,
  isLoading: true,
  successConfirmationText: '',
};

export const emailChangedConfirmThunks: EmailChangedConfirmThunk = {
  confirmChangedEmail: createAbortThunk(
    'emailChangedConfirm/confirmChangedEmail',
    async (arg, { rejectWithValue, dispatch }) => {
      try {
        const url = `${config.API_URL}/api/ews-crm/public/player/confirm-changed-email`;

        const queryParams = {
          token: encodeURI(arg.urlToken),
          email: arg.urlEmail,
        };

        const response = await axiosInstance.get<string>(url, { params: queryParams });
        if (response?.status) {
          if (response.status === 200) {
            return response.data;
          }
          return '';
        }
      } catch (err) {
        if (
          (err as AxiosError<{ statusCode: number; message: string }>).response?.data?.statusCode ===
          EXPIRED_CHANGED_EMAIL_TOKEN_STATUS_CODE
        ) {
          dispatch(setChangedEmailExpiredTokend());
        }
        return rejectWithValue(getError.responseDataMessage(err));
      }
    },
  ),
};

const EXPIRED_CHANGED_EMAIL_TOKEN_STATUS_CODE = 427;

const emailChangedConfirmSlice = createSlice({
  name: 'emailChangedConfirm',
  initialState,
  reducers: {
    resetChangedEmailConfirmState(state) {
      state.submitError = '';
    },
    setChangedEmailExpiredTokend(state) {
      state.isExpiredToken = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(emailChangedConfirmThunks.confirmChangedEmail.fulfilled, (state, action) => {
        state.isValidToken = true;
        state.isLoading = false;
        state.successConfirmationText = action.payload;
      })
      .addCase(emailChangedConfirmThunks.confirmChangedEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        emailChangedConfirmThunks.confirmChangedEmail.rejected,
        (state, action: PayloadAction<string | { rejectValue: string } | null | undefined>) => {
          state.isLoading = false;
          state.submitError = action.payload as string;
        },
      );
  },
});

export const { resetChangedEmailConfirmState, setChangedEmailExpiredTokend } = emailChangedConfirmSlice.actions;

export default emailChangedConfirmSlice.reducer;

export const selectEmailChangedConfirm = {
  isLoading: (state: RootState) => state.auth.emailChangedConfirm?.isLoading,
  isValidToken: (state: RootState) => state.auth.emailChangedConfirm?.isValidToken,
  submitError: (state: RootState) => state.auth.emailChangedConfirm?.submitError,
  isExpiredToken: (state: RootState) => state.auth.emailChangedConfirm?.isExpiredToken,
  successConfirmationText: (state: RootState) => state.auth.emailChangedConfirm?.successConfirmationText,
};
