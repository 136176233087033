import React, { memo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'utils/common/helpersCommon';
import { MyAccountTranslation } from 'utils/common/translationUtils/translationStrings';
import { formatDateWithOffset } from 'utils/dateUtils';
import { PlayerRestrictions } from '../types/responsibleGambling.types';

const CSSClassesMap = {
  modal: { title: 'modal-terms__title', text: 'modal-terms__text' },
  myAccount: { title: 'form-text--lead', text: 'form-text--primary' },
  betSlip: { title: 'mb-2 bet-msg__title', text: 'mb-0' },
  gameCell: { title: 'modal-terms__text mb-1', text: 'modal-terms__text mb-1' },
} as const;

type Props = {
  restrictions?: PlayerRestrictions.Restriction[];
  componentLocation: keyof typeof CSSClassesMap;
  showBaseInfoOnly?: boolean;
};

const PlayerRestrictionsInfo: React.FC<Props> = ({ restrictions, componentLocation, showBaseInfoOnly = false }) => {
  const { t } = useTranslation();

  if (isEmpty(restrictions)) return null;

  const titleClass = clsx(CSSClassesMap[componentLocation].title);
  const textClass = clsx(CSSClassesMap[componentLocation].text);

  return (
    <>
      {restrictions?.map((restriction, idx) => (
        <React.Fragment key={`${idx}-${restriction.id}`}>
          <p className={titleClass}>
            {t(`MyAccount.Exclusions.active${restriction.restrictionConfigurationKey}Limit`)}
            {!showBaseInfoOnly && (
              <>
                {' - '}
                {t(`MyAccount.Exclusions.${restriction.productTypeName.toLowerCase()}_product`)}
              </>
            )}
          </p>
          {!showBaseInfoOnly && (
            <>
              <p className={textClass}>
                <span className="font-weight-bold">{t(MyAccountTranslation.Exclusions.activationDate)}: </span>
                {formatDateWithOffset('DD.MM.YYYY HH:mm:ss', restriction?.startDate, true)}
              </p>
              <p className={`${textClass} mb-4`}>
                <span className="font-weight-bold">{t(MyAccountTranslation.Exclusions.endDate)}: </span>
                {formatDateWithOffset('DD.MM.YYYY HH:mm:ss', restriction?.endDate, true)}
              </p>
            </>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default memo(PlayerRestrictionsInfo);
