import React from 'react';
import clsx from 'clsx';
import { Pointer } from 'components/shared';
import { ExpandableIcon } from 'components/shared/icons';
import { Flex } from 'components/shared/layout';
import { UI } from 'components/shared/UI';
import { cssBurgerNS } from './burgerUI.types';

/** NavItem */
interface NavItemProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;

  icon: string | null;
  iconColor?: string;
  text: string;

  isActive?: boolean;
  isExpandable?: boolean;
  isExpanded?: boolean;

  dataQid?: string;
  dataCpp?: string;
}

const NavItem: React.FC<NavItemProps> = ({
  className,
  icon,
  iconColor,
  text,
  isExpandable,
  isActive,
  isExpanded,
  dataQid,
  dataCpp,
  ...props
}) => {
  const dataCPPProp = iconColor ? { style: { fill: iconColor } } : { 'data-cpp': dataCpp };

  const wrapperClass = clsx('d-flex-ac', cssBurgerNS.NavItem, isActive && `${cssBurgerNS.NavItem}--active`, className);
  const expanderClass = clsx(
    `${cssBurgerNS.NavItem}__expander`,
    isActive && `${cssBurgerNS.NavItem}__expander--active`,
  );

  return (
    <div className={wrapperClass} data-qid={dataQid} {...dataCPPProp} {...props}>
      {icon && <UI.SVGCDN className={`mr-2 ${cssBurgerNS.NavItem}__icon`} icon={icon} />}
      {text}
      <Flex.Center className="ml-auto">
        {isExpandable ? (
          <ExpandableIcon className={expanderClass} isExpanded={isExpanded} />
        ) : (
          <Pointer className={`${cssBurgerNS.NavItem}__pointer`} direction="right" />
        )}
      </Flex.Center>
    </div>
  );
};

export default NavItem;
