import { useAppSelector } from 'store';
import ClaimTicket from './ClaimTicket';
import ClaimTicketForm from './ClaimTicketForm';
import StatusMessage from './StatusMessage';
import { selectClaimTicket } from '../slice/claimTicket.slice';

const StepsMap = {
  1: ({ isModal }) => <ClaimTicketForm isModal={isModal} />,
  2: () => <ClaimTicket />,
  3: () => <StatusMessage />,
};

const ClaimTicketComponents = ({ isModal }) => {
  const step = useAppSelector(selectClaimTicket.step);

  const StepComponent = StepsMap[step];
  return <StepComponent isModal={isModal} />;
};

export default ClaimTicketComponents;
