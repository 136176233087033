import { useCallback, useEffect } from 'react';
import { hideRootGrandModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch } from 'store';
import { acceptTermsAndConditions, fetchTermsAndConditions } from '../slice/termsAndConditions.slice';

interface Props {
  alias: string;
  version: string;
  subVersion: string;
  shouldFetch: boolean;
}

interface ReturnProps {
  onAccept: () => void;
}

const useTermsAndConditions = ({ alias, version, subVersion, shouldFetch }: Props): ReturnProps => {
  const dispatch = useAppDispatch();
  const onAccept = useCallback(() => {
    dispatch(
      acceptTermsAndConditions({ alias, version, subVersion, closeModal: () => dispatch(hideRootGrandModal()) }),
    );
  }, []);

  useEffect(() => {
    if (shouldFetch) {
      dispatch(fetchTermsAndConditions({ alias }));
    }
  }, [shouldFetch]);

  return { onAccept };
};

export default useTermsAndConditions;
