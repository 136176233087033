import { SocialAccountsUI } from 'pages/myAccount/tabs/account/sections/components/socialAccountsUI';
import { JwtApplePayload, jwtDecode } from 'utils/common/jwtDecode';
import SocialIcon from '../SocialIcon';
import { Social, SocialButtonInputTypes } from '../types/formSocial.types';

declare global {
  interface Window {
    AppleID: {
      auth: {
        init: (props: {
          clientId?: string;
          scope: 'name email' | 'email';
          redirectURI?: string;
          usePopup: boolean;
        }) => void;
        signIn: () => Promise<{
          authorization: { id_token: string; code: string };
          user?: { name: { firstName: string; lastName: string } };
        }>;
      };
    };
  }
}

const AppleButton = {
  config: {
    src: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
    crossOrigin: 'anonymous',
    async: true,
    defer: true,
  },
  effect: ({ clientId, redirectUrl }: { clientId?: string; redirectUrl?: string }) => {
    window.AppleID.auth.init({
      clientId,
      redirectURI: redirectUrl,
      usePopup: true,
      scope: 'name email',
    });
  },
  /* Default Button */
  /* 
    <div
      id={'appleid-signin'}
      data-type={'continue'}
      data-height={'40px'}
      data-width={'247px'}
      data-border-radius={'4px'}
    /> 
  */
  element: ({ className, button, isDisabled, onSocialAuth, t }: Social.ElementProps) => {
    const onClickHandler = isDisabled
      ? undefined
      : async () => {
          const data = await window.AppleID.auth.signIn();
          const credentials = jwtDecode<JwtApplePayload>(data.authorization.id_token);

          onSocialAuth?.({
            username: credentials.sub,
            password: data.authorization.id_token,
            userData: {
              userId: credentials.sub,
              firstName: data?.user?.name?.firstName,
              lastName: data?.user?.name?.lastName,
              email: credentials.email,
            },
          });
        };

    return button.inputType === SocialButtonInputTypes.UI_BUTTON ? (
      <SocialAccountsUI.Link
        type={button.type}
        text={button.label}
        btnText={button.text}
        isDisabled={isDisabled}
        onClick={onClickHandler}
      />
    ) : (
      <div className={className} onClick={onClickHandler}>
        {button.inputType === SocialButtonInputTypes.ICON && (
          <div className="form-social__button-logo">
            <SocialIcon type={button.type} size={20} />
          </div>
        )}
        {button.label && <div className="form-social__button-text">{t(button.label)}</div>}
      </div>
    );
  },
};

export default AppleButton;
