import { GameCellRestrictions } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellRestriction/gameCellRestriction.types';
import { BetLimits } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTableLimits/gameCellTableLimitsTypes';
import { GameTag } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/GameCellTagsTypes';
import { GameInfoModalTypes } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameInfoModal/gameInfoModalTypes';
import {
  GridLayouts,
  GridLoadingBehaviorType,
  GridRowType,
  GridType,
} from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { CasinoWidgetsTypes } from 'modules/casino/modules/container/widget/types/casinoWidgetsTypes';
import { CategoryQueryType, GameType } from 'modules/casino/shared/constants';
import { Casino } from 'modules/casino/shared/types';
import { CreateThunk } from 'modules/casino/store/thunkCreators';
import { MobileAppRestrictions } from '../../../../../shared/features/mobileAppRestrictions/mobileAppRestrictions.types';

export declare module CasinoGamesTypes {
  import GameSection = Casino.GameSection;
  import LayoutConfig = Casino.LayoutConfig;

  interface State {
    categoryGames: Record<string, CategoryCollection>;
    categoryGamesCount: Record<string, number>;
    categoryGamesLoadingState: Record<string, number>;
    gamesInfo: GameProps[];
    normalizedLiveGameData: NormalizedLiveGameData;
    gameOpenId: string;
    isLoading: boolean;
    ttl: number;
  }

  type NormalizedGames = Record<string, GameProps>;

  interface NormalizedLiveGameData {
    [key: string]: any;
  }

  interface CacheTTL {
    ttl: number;
  }

  type GameBackground = {
    background: string;
  };
  type GameRotationBackground = {
    rotatingIdsBackgrounds: Record<string, GameBackground>;
  };

  type GameModifiedTypes = {
    rotatingIds: string[];
    expandingIds: string[];
    standardIds: string[];
  };

  interface CasinoGamesCollectionGeneralProps extends CacheTTL {
    collectionId: string;
    size: number;
    totalPages: number;
    totalElements: number;
    currentPage: number;
    queryType: CategoryQueryType;
    categoryId: number;
    searchTag: string;
    last: boolean;
    hasNumbers?: boolean;
  }

  interface CategoryCollection extends CasinoGamesCollectionGeneralProps {
    gameIds: string[];
    gameIdsSpecial: string[];
    games: NormalizedGames;
  }

  interface FetchCategoryGamesPayload extends CasinoGamesCollectionGeneralProps {
    games: GameProps[];
  }

  type SearchTag = {
    id: number;
    name: string;
  };

  type LuckyNumber = {
    index: number;
    luckyNumber: number;
  };

  type ResultsObj = {
    multiplier: number;
    value: string;
  };

  type Configuration = {
    provider: string;
    betBehind: boolean;
    betLimits: BetLimits;
    history: Array<string>;
    luckyNumbers: Array<LuckyNumber>;
    results: Array<string | number | ResultsObj>;
    gameResult;
    seats: number;
    seatsTaken: Array<number>;
    tablePlayers: number;
    tableImage: string;
    ante: string;
    isFast: boolean;
  };

  type GameProps = {
    // Layouts
    gameLayout: GameLayout;
    rank: number;
    // Game
    providerAlias: string;
    gameId: string;
    id: string;
    refGameId: string;
    providerId: string;
    jackpotId: string;
    description: string;
    visualizationTemplateId: string;
    availableForFreeplay: boolean;
    iframeLoad: boolean;
    showInfoPage: boolean;
    isVideo: boolean;
    layoutModifier: 'STANDARD' | 'WIDGET' | 'SPECIAL';
    gameType: GameType;
    tags: GameTag[];
    gameInfo: GameInfoModalTypes.GameInfo;
    liveGameIconId: string;
    widget: CasinoWidgetsTypes.Widget;
    gameResource: GameResource;
    liveFeedInfo: Configuration;
    layoutGrid: GridLayouts;
    subType: CategorySubTypes;
    hasNumbers: boolean;
    gridType: GridType;
    liveImageLocation: string;
    uniqueId: string;
    isLiveGame: boolean;
    specialCell?: boolean;
    betLimits?: CasinoBetLimits;
    number?: number;
    // Svara
    baseGameId: string;
    betType: GameCellRestrictions.ProductType;
    businessUnit: string;
    isActive: boolean;
    isFreePlay: boolean;
    tableCurrency: string;
    isFromSvaraCategory?: boolean;
    isPermanent?: boolean;
    createTable?: boolean;
    isPrivateTable?: boolean;
    shouldLoad?: boolean;
    isFast: boolean;
    customFreePlay: boolean;
  };

  type GameLayout = 'REGULAR' | 'HORIZONTAL' | 'VERTICAL' | 'MACRO';

  type GameResource = {
    categoryUseStatic: boolean;
    lobbyUseStatic: boolean;
    thumbnailLocation: string;
  };

  type FetchCategoryGamesParams = {
    collectionId: string;
    categoryParams: CasinoGamesTypes.RenderGridCategoryParams;
    size: number;
    page: number;
    searchTagId: string;
    hasNumbers: boolean;
    mobileAppRestrictions: MobileAppRestrictions;
  };

  type LiveGameId = {
    refGameId: string;
    gameProviderId: string;
  };

  type FetchLiveDataParams = {
    liveGameIds: LiveGameId[];
  };

  type CasinoGamesThunks = {
    fetchCategoryGames: CreateThunk<
      {
        queryType: CategoryQueryType;
        params: FetchCategoryGamesParams;
        isAuthenticated?: boolean;
      },
      FetchCategoryGamesPayload,
      string | null
    >;
  };

  type CasinoLiveDataThunk = {
    fetchCasinoLiveData: CreateThunk<
      {
        liveGameIds: string[];
        isSvara?: boolean;
      },
      null,
      string | null
    >;
  };

  type GameInfoByIdThunk = {
    fetchGamesInfoByIds: CreateThunk<string[], CasinoGamesTypes.GameProps[], string | null>;
  };

  type RenderGridCategoryParams = {
    categoryId: number | string;
    subType: CategorySubTypes;
    layoutRows: GridRowType;
    layoutGrid: GridLayouts;
    gridType: GridType;
    queryType: CategoryQueryType;
    additionalParams;
    searchParams: string;
    filteredProviders: any;
    categoryLayoutType: CategoryLayoutTypes;
    gameSection?: GameSection;
    query?: string;
    playerId?: string;
  };

  type CasinoGamesRenderParams = {
    className: string;
    centeredSlides: boolean;
    handelLoadMore: () => void;
    gridLoadingBehavior?: GridLoadingBehaviorType;
    layoutConfig: Partial<LayoutConfig>;
    additionalParams;
    searchParams: string;
    filteredProviders: any;
    cdnPathLayout: string;
    gameLayout?: GameLayout;
    isCategoryLast?: boolean;
  };

  type PageableResult<TContent> = {
    content: TContent[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    pageable: {
      offset: number;
      pageNumber: number;
      pageSize: number;
      paged: boolean;
      sort: { unsorted: boolean; sorted: boolean; empty: boolean };
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    size: number;
    sort: { unsorted: boolean; sorted: boolean; empty: boolean };
    totalElements: number;
    totalPages: number;
  };

  type RotatingRenderData = {
    uniqueId: string;
    background: string;
  };

  type AccentGameRenderData = {
    uniqueId: string;
    background: string;
  };

  type Limits = {
    min: number;
    max: number;
  };
  type CasinoBetLimits = Record<string, Limits>;
}

export enum GameLayoutEnum {
  REGULAR = 'REGULAR',
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
  MACRO = 'MACRO',
}
