import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { getTickerConfig } from 'modules/casino/shared/utils/helpersCasino';
import { useAppDispatch, useAppSelector } from 'store';
import { General, LayoutConfigTypes } from '../../general/types/generalSlice.types';
import { selectIsExpanded, selectShowTicker, setIsExpanded, setShowTicker } from '../../ticker/slice/mostPlayed.slice';

type ReturnTypeTickerHeader = {
  showTicker: boolean;
  tickerVerticalConfig: General.TickerItem | undefined;
  isExpanded: boolean;
};
export const useTickerHeader = (): ReturnTypeTickerHeader => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isExpanded = useAppSelector(selectIsExpanded);
  const tickerConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.TICKER,
  });
  const showTicker = useAppSelector(selectShowTicker);

  const tickerVerticalConfig = useMemo(
    () => getTickerConfig(location.pathname, tickerConfig),
    [location.pathname, tickerConfig],
  );

  useEffect(() => {
    if (!tickerVerticalConfig?.visible && isExpanded) {
      dispatch(setIsExpanded(false));
    }
  }, [dispatch, isExpanded, tickerVerticalConfig]);

  //   Hide Ticker on resize
  const onResize = useCallback(() => {
    dispatch(setShowTicker(false));
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return { showTicker, tickerVerticalConfig, isExpanded };
};
