import React, { HTMLAttributes, RefObject } from 'react';
import clsx from 'clsx';
import Icon from 'components/shared/Icon';
import { CSS } from 'utils/common/types/css.types';
import InputStatus from './InputStatus';

/** Input */
interface InputProps extends HTMLAttributes<HTMLInputElement> {
  className?: string;
  inputRef?: RefObject<HTMLInputElement>;
  isDisabled?: boolean;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  onFocus?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  status?: Exclude<CSS.Variants, 'primary' | 'secondary' | 'danger'>;
  statusText?: string;
  textAlignment?: CSS.TextAlignments;
  value: string | number;
  placeholder?: string;
  errorMessage?: string;
  type?: string;
  maxLength?: number;
  minLength?: number;
}

const Input: React.FC<InputProps> = ({
  className,
  inputRef,
  isDisabled,
  onBlur,
  onChange,
  onClick,
  onFocus,
  onKeyDown,
  status,
  statusText,
  textAlignment,
  value,
  placeholder,
  errorMessage,
  ...rest
}) => {
  const statusIconsMap = {
    error: 'times-solid',
    info: 'info-solid',
    success: 'check-solid',
    warning: 'times-solid',
  };

  const wrapperClass = clsx('egtd-x-input', className);
  const statusProp = status ? { 'data-status': status } : undefined;
  const textAlignmentProp = textAlignment ? { 'data-text-align': textAlignment } : undefined;

  const statusIconClassProp = status
    ? { className: `egtd-x-input__status-icon fill--${status}` }
    : { className: 'egtd-x-input__status-icon' };

  return (
    <>
      <div className="egtd-x-input__wrapper">
        <input
          {...rest}
          {...statusProp}
          {...textAlignmentProp}
          className={wrapperClass}
          disabled={isDisabled}
          onBlur={onBlur}
          onChange={onChange}
          onClick={onClick}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          ref={inputRef}
          value={value}
          placeholder={placeholder}
        />
        {status && (
          <Icon
            defaultClassName={false}
            {...statusIconClassProp}
            {...textAlignmentProp}
            size={14}
            icon={statusIconsMap[status]}
          />
        )}
      </div>
      {statusText && <InputStatus status={status} text={statusText} />}
      {errorMessage && <InputStatus status={status} text={errorMessage} />}
    </>
  );
};

Input.displayName = 'EGTD Platform Input';

export default Input;
