export enum ComponentNames {
  GameCellInnerWrapper = 'GameCellInnerWrapper',
  Description = 'Description',
  GameCellWrapper = 'GameCellWrapper',
}

export enum StyledNames {
  GameMediaStyled = 'GameMediaStyled',
  ProviderLogoStyled = 'ProviderLogoStyled',
  DescriptionStyled = 'DescriptionStyled',
  MetaInfoStyled = 'MetaInfoStyled',
  DataCharactersWrapperStyled = 'DataCharactersWrapperStyled',
  GridWrapperStyled = 'GridWrapperStyled',
  GameCellCountImage = 'GameCellCountImage',
  GameCellWrapperStyled = 'GameCellWrapperStyled',
}

export enum ComponentType {
  default = 'default',
  data = 'data',
  dataAlternative = 'dataAlternative',
}

export enum CellLayout {
  cell_1 = 'cell_1',
  cell_2 = 'cell_2',
  cell_3 = 'cell_3',
}
