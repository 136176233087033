import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/shared';
import { Badge } from 'components/shared/badges';
import { Flex } from 'components/shared/layout';
import { BetSlipSharedUI } from 'pages/myAccount/tabs/history/sections/sportsHistory/components/BetSlipSharedUI';
import { BonusIconsTypes } from 'pages/myAccount/tabs/history/sections/sportsHistory/components/BetSlipSharedUI/BonusIndicator.types';
import { BetStatuses, SelectionStatuses } from 'pages/myAccount/tabs/history/sections/sportsHistory/types/myBets.types';
import { LiveSports } from 'utils/common/translationUtils/translationStrings';
import { Sport } from 'utils/common/types/sportTypes';
import SelectionStatus from './SelectionStatus';

/** BetSeletionUI Header */
interface HeaderProps {
  showLiveBadge?: boolean;
  selectionName: string;
  odds: string | number | undefined;
  status: keyof typeof SelectionStatuses | null;
  betStatus: BetStatuses;
  customMarketType?: Sport.CustomMarketType;
  renderPosition?: any;
  position?: any;
  isRaceSport?: boolean;
  isCustomBetSelection?: boolean;
  isCustomBetLive?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  showLiveBadge,
  selectionName,
  odds,
  status,
  betStatus,
  customMarketType,
  renderPosition,
  position,
  isRaceSport,
  isCustomBetSelection,
  isCustomBetLive,
}) => {
  const { t } = useTranslation();
  const rightColClass = clsx('d-flex-ac ml-1 egtd-p-bi-sel__header-right-col', isRaceSport && 'mb-auto ml-auto');
  return (
    <div className="d-flex-ac egtd-p-bi-sel__header">
      {showLiveBadge && <Badge variant="infoDark" className="ml-0 mr-1" label={t(LiveSports.Badge.labelText)} />}
      {!isRaceSport && !isCustomBetSelection && <Text className="mw-0 mr-auto text-truncate" text={selectionName} />}
      {!isRaceSport &&
        isCustomBetSelection &&
        (isCustomBetLive ? (
          <>
            <Badge className="mr-1" variant="info" label={t('BetBuilder.SelectionHeader.isLiveText')} />{' '}
            <Text className="mw-0 mr-auto text-truncate" text={t('BetBuilder.SelectionHeader.customBetTitle')} />
          </>
        ) : (
          <Text className="mw-0 mr-auto text-truncate" text={t('BetBuilder.SelectionHeader.customBetTitle')} />
        ))}

      {isRaceSport && <Flex.Col>{position.map(renderPosition)}</Flex.Col>}
      <div className={rightColClass}>
        {customMarketType === 'ENHANCED_ODDS' && (
          <BetSlipSharedUI.BonusIndicator className="mr-1" icon={BonusIconsTypes.EnhancedOdds} />
        )}
        <Text className="egtd-p-bi-sel__accent" text={odds} />
        <SelectionStatus status={status} betStatus={betStatus}></SelectionStatus>
      </div>
    </div>
  );
};

export default Header;
