import { useTranslation } from 'react-i18next';
import { Modal } from 'components/shared';
import { ClaimTicketTranslations } from 'utils/common/translationUtils/translationStrings';
import ClaimTicketComponents from './components/ClaimTicketComponents';
import useClaimTicketModal from './hooks/useClaimTicketModal';

const ClaimTicketModal: React.FC = () => {
  const { showCloseIconInHeader, onClose } = useClaimTicketModal();
  const { t } = useTranslation();

  return (
    <Modal visible={true}>
      <div className="egtd-x-check-ticket-modal" data-type="ticket-status">
        {!showCloseIconInHeader && (
          <Modal.Close closeText={t(ClaimTicketTranslations.Buttons.closeModal)} closeAction={onClose} />
        )}
        <div className="egtd-x-check-ticket-modal__content">
          {showCloseIconInHeader && (
            <div className="egtd-x-check-ticket-modal__header">
              <div>{t(ClaimTicketTranslations.Info.title)}</div>
              <Modal.Close closeText="" closeAction={onClose} />
            </div>
          )}
          <ClaimTicketComponents isModal />
        </div>
      </div>
    </Modal>
  );
};

export default ClaimTicketModal;
