import React from 'react';
import styled from 'styled-components';
import { Casino } from 'modules/casino/shared/types';
import { Breakpoints } from 'modules/casino/theme/Theme';

interface Props {
  layoutConfig: Casino.LayoutConfig;
  lazySkeleton: boolean;
  hasBackground: boolean;
}

const GridSkeleton: React.FC<Props> = ({ layoutConfig, lazySkeleton, hasBackground }) => {
  return (
    <StyledGridSkeleton
      className={`${layoutConfig.layout?.toLowerCase()}-grid skeleton-grid`}
      $lazySkeleton={lazySkeleton}
      $hasBackground={hasBackground}
    >
      <div className={`${layoutConfig.layoutGrid?.toLowerCase()} ${layoutConfig.layoutRows?.toLowerCase()} grid`}>
        <div className="casino-grid-item casino-grid-item--regular"></div>
      </div>
    </StyledGridSkeleton>
  );
};

const StyledGridSkeleton = styled.div<{ $lazySkeleton: boolean; $hasBackground: boolean }>`
  ${({ $lazySkeleton }) =>
    $lazySkeleton
      ? `
      margin-bottom: 20px !important; 
      @media screen and (min-width: ${Breakpoints.isMobile}px) {
        margin-bottom: 40px !important; 
      }`
      : `
  `}
  ${({ $hasBackground }) =>
    $hasBackground
      ? `
      padding: 12px 0;
      @media screen and (min-width: ${Breakpoints.isMobile}px) {
        padding: 22px 0;
      }
      @media screen and (min-width: ${Breakpoints.isDesktop}px) {
        padding: 54px 0;
      }`
      : ``}
`;

export default GridSkeleton;
