import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { showMyProfileModal } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { TombolaIndicatorDefault } from './components/DefaultTombolaIndicator';
import { DefaultTombolaLink } from './components/DefaultTombolaLink';
import { useGetTombolaTickets } from './hooks/useGetMainNavbarButtonsBonus';
import { selectHeaderBonusIcon } from './slice/mainNavbarButtonsBonus.slice';
import { TombolaTypes } from './types/mainNavbarButtonsBonus.types';
import { MainNavbarButtonsCompact } from '../../components/MainNavbarButtonsCompact';
import { MainNavBarButtonsTypes } from '../../types/mainNavBarButtons.types';
import { getContentTemplateKey } from '../../utils/mainNavBarButtons.utils';

type Props = {
  item: MainNavBarButtonsTypes.Item;
  isMyProfile?: boolean;
  isTombola?: boolean;
  type: 'compact' | 'default' | 'collection';
  isAuthenticated: boolean | null;
};

type PropsComponent = {
  text?: string;
  tombola?: TombolaTypes.Completion | null;
  onClick: () => void;
  isAuthenticated: boolean;
  isMyProfile?: boolean;
  icon?: string;
  iconMyProfile?: string;
};

export const containersMap: Record<'default' | 'compact' | 'collection', React.FC<PropsComponent>> = {
  default({ text, tombola, isAuthenticated, icon, iconMyProfile, onClick }: PropsComponent) {
    return (
      <TombolaIndicatorDefault
        text={text}
        tickets={tombola?.achievedTangiblePoints ?? 0}
        onClick={onClick}
        isAuthenticated={isAuthenticated}
        icon={icon}
        iconMyProfile={iconMyProfile}
      />
    );
  },
  compact({ isMyProfile, onClick, isAuthenticated, icon, iconMyProfile, tombola }: PropsComponent) {
    return (
      <MainNavbarButtonsCompact
        isMyProfile={isMyProfile}
        tickets={tombola?.achievedTangiblePoints ?? 0}
        onClick={onClick}
        icon={icon}
        iconMyProfile={iconMyProfile}
        isAuthenticated={isAuthenticated}
      />
    );
  },
  collection({ isMyProfile, onClick, isAuthenticated, icon, iconMyProfile, tombola }: PropsComponent) {
    return (
      <DefaultTombolaLink
        bonusCollection={tombola}
        isMyProfile={isMyProfile}
        onClick={onClick}
        icon={icon}
        iconMyProfile={iconMyProfile}
        isAuthenticated={isAuthenticated}
      />
    );
  },
};

const MainNavbarButtonsBonus: React.FC<Props> = ({
  item,
  isMyProfile,
  type = 'compact',
  isAuthenticated,
  isTombola = true,
}) => {
  const contentTemplateKey = getContentTemplateKey(item.apiUrl);
  useGetTombolaTickets({ apiUrl: item?.apiUrl, contentTemplateKey, isTombola, isMyProfile });
  const { t } = useTranslation();
  const tombola = useAppSelector(selectHeaderBonusIcon.getBonus(contentTemplateKey));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ContainerComponent: React.FC<PropsComponent> = containersMap[type];
  if (!ContainerComponent) return null;
  const text = item.text && t(item.text);
  const goToBonusPage = () => {
    dispatch(showMyProfileModal(false));
    item?.navigationUrls && navigate(item?.navigationUrls);
  };

  return (
    <ContainerComponent
      {...{
        tombola,
        onClick: goToBonusPage,
        text: text,
        isMyProfile,
        isAuthenticated: !!isAuthenticated,
        icon: item?.icon,
        iconMyProfile: item?.iconMyProfile,
      }}
    />
  );
};

export default MainNavbarButtonsBonus;
