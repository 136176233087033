import { useEffect, useRef } from 'react';
import { useIsUnmounted } from '../../../../hooks/common/useIsUnmouted';
import {
  selectIntegrations,
  setIsCasinoSubWidgetLoaded,
} from '../../../../shared/common/features/integrations/slice/integrations.slice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { CasinoWidgetTypes } from '../types/casinoWidget.types';

const CasinoLaunchImplementation: React.FC = () => {
  const containerId = `cw-game-launch`;
  const dispatch = useAppDispatch();
  const casinoWidgetRef = useRef<HTMLDivElement>(null);
  const isUnmount = useIsUnmounted();
  const isCasinoWidgetLoaded = useAppSelector(selectIntegrations.isCasinoWidgetLoaded);

  useEffect(() => {
    const config: CasinoWidgetTypes.LaunchGameConfig = {
      containerId,
      params: {
        relaunch: 1,
      },
      listeners: {
        onLaunchGameLoad,
      },
    };

    if (casinoWidgetRef) {
      if (isCasinoWidgetLoaded && window.widgetCasino) {
        window.widgetCasinoLaunch = window.widgetCasino?.launchGame?.(config);
      }

      return () => {
        if (isUnmount() && window.widgetCasinoLaunch) {
          window.widgetCasinoLaunch.remove();
          window.widgetCasinoLaunch = undefined;
          dispatch(setIsCasinoSubWidgetLoaded({ key: containerId, isLoaded: false }));
        }
      };
    }
  }, [isCasinoWidgetLoaded]);

  const onLaunchGameLoad = () => {
    dispatch(setIsCasinoSubWidgetLoaded({ key: containerId, isLoaded: true }));
  };
  return <div ref={casinoWidgetRef} id={containerId} />;
};

export default CasinoLaunchImplementation;
