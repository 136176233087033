import { Promotions } from 'utils/common/translationUtils/translationStrings';

export enum PlayerTournaments {
  GENERAL_RANKINGS = 'generalRankings',
  DAILY_RANKINGS = 'dailyRankings',
  WEEKLY_RANKINGS = 'weeklyRankings',
  MONTHLY_RANKINGS = 'monthlyRankings',
  EVENT_RANKINGS = 'eventRankings',
}

export enum RankingInnerPeriods {
  CURRENT = 'current',
  LAST = 'last',
  BEFORE_LAST = 'beforeLast',
}

export const PLAYER_TOURNAMENTS_RANKINGS = {
  [PlayerTournaments.EVENT_RANKINGS]: {
    rank: 1,
    translationKey: Promotions.Tournaments.eventRankings,
  },
  [PlayerTournaments.DAILY_RANKINGS]: {
    rank: 2,
    translationKey: Promotions.Tournaments.dailyRanking,
  },
  [PlayerTournaments.WEEKLY_RANKINGS]: {
    rank: 3,
    translationKey: Promotions.Tournaments.weeklyRanking,
  },
  [PlayerTournaments.MONTHLY_RANKINGS]: {
    rank: 4,
    translationKey: Promotions.Tournaments.monthlyRanking,
  },
  [PlayerTournaments.GENERAL_RANKINGS]: {
    rank: 5,
    translationKey: Promotions.Tournaments.generalRanking,
  },
};

export const PAST_PERIODS_RANKING = {
  [RankingInnerPeriods.CURRENT]: {
    rank: 1,
    translationKey: Promotions.Tournaments.currentRankings,
  },
  [RankingInnerPeriods.LAST]: {
    rank: 2,
    translationKey: Promotions.Tournaments.lastRankings,
  },
  [RankingInnerPeriods.BEFORE_LAST]: {
    rank: 3,
    translationKey: '',
  },
};

export declare namespace Tournaments {
  type StateStatus = 'pending' | 'idle' | 'success' | 'failed';

  interface GridState {
    error: string | null | undefined;
    data: GetPlayerRankingResponse | null;
    status: Tournaments.StateStatus;
    currentEvent: EventRaking | null;
  }

  type GetPlayerRankingResponse = {
    [PlayerTournaments.GENERAL_RANKINGS]: PeriodsRanking;
    [PlayerTournaments.DAILY_RANKINGS]: PeriodsRanking;
    [PlayerTournaments.WEEKLY_RANKINGS]: PeriodsRanking;
    [PlayerTournaments.MONTHLY_RANKINGS]: PeriodsRanking;
    [PlayerTournaments.EVENT_RANKINGS]: EventRaking[];
  };

  type PeriodsRanking = Periods & {
    label?: Record<RankingInnerPeriods, string>;
    winningPlayers?: string;
  };

  type Periods = {
    [RankingInnerPeriods.CURRENT]: PlayerRanking[];
    [RankingInnerPeriods.LAST]?: PlayerRanking[];
    [RankingInnerPeriods.BEFORE_LAST]?: PlayerRanking[];
  };

  type PlayerRankingMap = {
    rankingType: PlayerTournaments;
    data: PeriodRankingMap[] | EventRaking[];
  };

  type PeriodRankingMap = {
    periodType: string;
    rankingData: PlayerRanking[];
    label: string;
  };

  type PlayerRanking = {
    rank?: number;
    name: string;
    points: number;
    targetReachedDate?: string;
    isTargetReached?: boolean;
  };

  type EventRaking = {
    sportEventId: number;
    eventName: string;
    playersRanking: PlayerRanking[];
  };
}
