import { TOptions } from 'i18n';
import { LastPrintInfo } from 'modules/retail/modules/cashier/features/lastPrint/type/lastPrint.types';
import { CheckTicketRetailInfo, RetailAddress, RetailInfo } from 'modules/retail/modules/cashier/types/cashier.types';
import { BetStatuses } from 'pages/myAccount/tabs/history/components/BetLogUI/Header';
import { MyBets } from 'pages/myAccount/tabs/history/sections/sportsHistory/types/myBets.types';
import { RootState } from 'store/rootReducer';
import { CreateThunk } from 'store/thunkCreators';
import { ET, Sport } from 'utils/common/types/sportTypes';

export type SportsTerminalTypes = {
  moneyValidatorData: MoneyValidatorContent | null;
  error: boolean;
  printerStatus: PrinterStatus | null;
  coinAcceptorInfo: CoinAcceptorInfo | null;
  totalSum: number;
  billAmount: BillAmountInfo | null;
  billSum: number;
  depositInfo: DepositTerminalResponse | null;
  depositInfoStatus: Sport.StateStatus;
  errorModalVisible: boolean;
  depositRetail: RetailDepositInfo | null;
  depositRetailStatus: string;
  activeStep: 1 | 2 | 3 | 4;
  isTicketSelectedFromBarcodeReader: boolean;
  isPopulateBetSlipFromQrCode: boolean;
  isCoinAcceptorStopped: boolean;
  isRgbControllerStopped: boolean;
  payoutTicketStatusSSBT: string;
};

export type BillAmountInfo = {
  err: string;
  err_msg: string;
  list: BillAmountList[];
};
export type BillAmountList = {
  denom: string;
  cc: string;
  num: string;
};
export type RgbControllerTypes = {
  rgbControllerInfo: RgbControllerInfo | null;
  retailActiveSportId: number;
};
export type RgbControllerInfo = {
  alert: string;
  code: string;
  msg: string;
  rgb_n: string;
};
export type VoucherTypes = {
  retailVoucherInfo: RetailVoucherInfo | null;
  retailVoucherInfoStatus: string;
  retailVoucher: RetailVoucher | null;
  retailVoucherStatus: string;
  depositWithVoucherStatus: string;
  voucherCode: string;
  isVoucherSelect: boolean;
  voucherCashier: VoucherCashier | null;
  voucherCashierStatus: string;
  voucherInfoCashier: VoucherInfoCashier | null;
  voucherInfoCashierStatus: string;
  payoutVoucherStatus: string;
  cancelVoucherStatus: string;
};
type VoucherCashier = {
  code: string;
  issuedAt: string;
  validUntil: string;
  value: VoucherCashierValue;
  posAddress: PosAddress;
  posName: string;
};
type VoucherCashierValue = {
  amount: number;
  currency: string;
};
type VoucherInfoCashier = {
  amount: number;
  currency: string;
  errorMessage: string;
  isValid: boolean;
  possibleActions: string[];
};
type PosAddress = {
  country: string;
  city: string;
  zipCode: string;
  streetName: string;
  streetNumber: string;
};
export type TicketInfoType = {
  ticketInfoErrorMessage?: string | null;
  ticketInfoStatus: Sport.StateStatus;
  ticketInfo: CheckTicket | null;
  ticketIdForDuplicateInBetSlip: string | undefined;
};

export type RetailDepositInfo = {
  bettingBalance: number;
  sessionBalance: number;
};

export type RetailVoucherInfo = {
  code: string;
  issuedAt: string;
  validUntil: string;
  value: {
    amount: number;
    currency: string;
  };
};

export type RetailVoucher = {
  amount: number;
  currency: string;
  errorMessage: string | null;
  isValid: boolean;
};

export type MoneyValidatorContent = {
  code: string;
  msg: string;
  alert: string;
  amt: string;
  cc: string;
};

export type DocumentScanner = {
  alert: string;
  amt: string;
  cc: string;
  code: string;
  msg: string;
};
export type RgbControllers = {
  alert: string;
  code: string;
  msg: string;
  rgb_n: number;
};
export type PrinterStatus = {
  error_code: string;
  error_msg: string;
};

export type CoinAcceptorInfo = {
  alert: string;
  amt: string;
  code: string;
  hop: string;
  msg: string;
};

export type TicketSSBTlInfo = {
  bets: TicketBet[];
  createdDate: number;
  ticketId: string;
  totalWin: number;
  totalStake: number;
  totalPossibleWin: number;
  totalWinLong: number;
  campaign: Campaign[];
  possibleTotalWin: number;
  paidWin: number;
};

export type CheckTicket = {
  allowReprint?: boolean;
  address: Address;
  posName: string;
  possibleWin: number | undefined;
  voidAmount: number;
  winAmount: number;
  ticket: {
    ticketId: string;
    createdDate: number;
    bets: TicketBet[];
    totalStake: number;
    totalWin: number;
    paidWin: number;
    totalPossibleWin: number;
    totalPossiblePaidWin: number;
    totalCashoutStake: number;
    ticketSettlementTimeStamp: number;
    ticketAggregate: TicketAggregate;
  };
  status: string;
  possibleActions: string[];
  code: string;
  dailySequenceNumber: number;
  pin: string;
  withdrawalType: null;
  isClaimForm: boolean;
  totalAmount: number;
  tax: number;
  totalAmountAfterTax: number;
  fee: number;
  totalStake: number;
};
type Address = {
  country: string;
  city: string;
  zipCode: string;
  streetName: string;
  streetNumber: string;
};

export type TicketBet = {
  selectionRefs: {
    selectionIndex: string;
  };
  betStatus: BetStatuses;
  betId: string;
  selectedSystem: string;
  manualReofferDetails: {
    totalCombinations: number;
    totalOdds: number;
  };
  isFreeBet: boolean;
  stake: {
    originalValue: number;
  };
  stakeLong: number;
  totalCombinations: number;
  totalOdds: number;
  totalStake: number;
  totalStakeLong: number;
  win: number | string;
  totalWin: number;
  winLong: number;
  totalWinLong: number;
  possibleWin: number;
  possibleTotalWin: number;
  cashoutStake: number;
  outcomes: TerminalOutcomes[];
  settlementTimeStamp: number;
  campaign: any;
};
export type Campaign = {
  type: string;
  percentage: number;
  amountLong: number;
};
export type NormalizedTerminalOutcomeEventMap = Record<string, TerminalOutcomesNormalized>;
export type TerminalOutcomesNormalized = TerminalOutcomes & { eventClockStatus: Sport.EventClockStatus };
export type NormalizedTerminalOutcomeMap = Record<string, TerminalOutcomes>;

export type TerminalOutcomes = ET.TerminalOutcomesEvent;

export type DepositTerminalResponse = {
  message: string;
  status: string;
  transferId: string;
};

type PrintEventContentSelectionOutcomes = {
  outcomeTitle: string;
  eventTitle: string;
  marketName: string;
  outcomeShortName: string;
  eventStartDate: string;
  originalStake: number;
  originalStakeLong: number;
  usedStake: number;
  cashoutStake: number;
  timestamp: number;
  key: number;
  customBetSelections: CustomBetSelection[];
  isCustomBetSelection?: boolean;
  odds: number;
  selectionBonuses: MyBets.SelectionBonusesTypes;
};

export type PrintTerminalDataParams<T, K> = {
  isDuplicate: boolean;
  ticketId: string;
  timestamp: number;
  totalWin: number;
  outcomes: T[];
  stake: number;
  summary: K[];
  totalOdds: number | string;
  getState: () => RootState;
  totalCombinations: number;
  currency: string;
  retailInfo?: RetailInfo | LastPrintInfo | null;
  isCurrencyBeforeAmount: boolean;
};

export type PrintFirstPartDataParams = {
  getState: () => RootState;
  ticketId: string;
  timestamp: number;
  isDuplicate: boolean;
  address: PosAddress;
  posName: string;
  mnemonicCode: string;
  playerCpf?: string;
};

export type PrintEventContentDataParams<T, K> = {
  outcomes: T[];
  summary: K[];
  getState: () => RootState;
  currency: string;
  isCurrencyBeforeAmount: boolean;
};

export type PrintSummaryContentParams<T, K> = {
  summary: K[];
  getState: () => RootState;
  outcomes: T[];
  currency: string;
  isCurrencyBeforeAmount: boolean;
};

export type LastPartContentParams<K> = {
  _stake: Record<string, string>;
  _totalWin: Record<string, string>;
  getState: () => RootState;
  ticketId: string;
  totalOdds: number | string;
  totalCombinations: number | undefined;
  isDuplicate: boolean;
  currency: string;
  pin?: string;
  dailySequenceNumber?: number;
  outcomes?: K[];
  isLinearCodeOnReceipt?: boolean;
  retailInfo?: any;
  checkTicketRetailInfo?: CheckTicketRetailInfo;
};

export type OutcomesData = {
  id: string;
  sportEventId: number;
  isCustomBetSelection: boolean;
  americanOdds: number;
  banker: boolean;
  customBetOutcomes: null;
  customBetSelections: CustomBetSelection[];
  displayAmericanOdds: number;
  displayFractionalOdds: string;
  displayOdds: number;
  eventStartDate: string;
  scheduledTime: string | number;
  fractionalOdds: string;
  isLive: boolean;
  marketName: string;
  marketTemplatedId: number;
  marketTemplateName: string;
  name: string;
  odds: number;
  outcomeShortName: string;
  selectionBonuses: MyBets.SelectionBonusesTypes;
  sportEventName: string;
  sportId: number;
  outcomeTitle: string;
  eventTitle: string;
  originalStake: number;
  originalStakeLong: number;
  usedStake: number;
  cashoutStake: number;
  timestamp: number;
  key: number;
};

type MapEventContentValues = {
  id: string;
  name: string;
  odds: number;
  banker: boolean;
  marketName: string;
  marketTemplateName: string;
  marketTemplateId: string;
  sportId: number;
  outcomeTitle: string;
  eventTitle: string;
  outcomeShortName: string;
  eventStartDate: string;
  originalStake: number;
  originalStakeLong: number;
  usedStake: number;
  cashoutStake: number;
  timestamp: number;
  key: number;
  customBetSelections: CustomBetSelection[];
  selectionBonuses: MyBets.SelectionBonusesTypes;
};

export type MapEventContentSingleDataParams<T> = {
  value: MapEventContentValues;
  isSingle: boolean;
  getSingleBetProps: (value) => SingleBetProps | undefined;
  singles: T[];
  outcomes: PrintEventContentSelectionOutcomes[];
  currency: string;
  isCurrencyBeforeAmount: boolean;
};

export type MapLastPartContentParams = {
  ticketIdData: {
    str: string;
  };
  _stake: Record<string, string>;
  _totalWin: Record<string, string>;
  totalOdds: number | string;
  totalCombinations: number | undefined;
  isDuplicate: boolean;
  currency: string;
  terminalBetPin?: string;
  retailAddress: RetailAddress;
  isVermantiaFootballOnly: boolean;
  dailySequenceNumber?: number;
  isLinearCodeOnReceipt: boolean;
  retailInfo?: any;
  checkTicketRetailInfo?: CheckTicketRetailInfo;
};

export type SummaryDataXparams = {
  id: number;
  win: number;
  campaigns: Campaign[];
  totalOdds: number;
  selectedSystem: string;
  outcomeId: string;
  totalCombinations: number;
  bankerBetTypeName: string;
  stake: number;
  totalStake: number;
  totalWinLong: number;
};

export type MapSummaryDataParams = {
  x: SummaryDataXparams;
  getState: () => RootState;
  toDecimal: (value: number | undefined) => number;
  currency: string;
  isCurrencyBeforeAmount: boolean;
};

export type MapSummaryVirtualDataParams = {
  selectionTypes: Set<string>;
};

export type PrintManualReofferDataParams = {
  ticketId: string;
  getState: () => RootState;
  selections: PrintEventContentSelectionOutcomes[];
  bets: TicketBet[];
  currency: string;
  isCurrencyBeforeAmount: boolean;
};

export type PrintFirstPartManualReofferParams = {
  getState: () => RootState;
  ticketId: string;
};

type RetailAddressManualReoffer = {
  buLogo: string;
  responsibleGambling: string;
};

export type MapFirstPartManualReofferParams = {
  requestId: string;
  retailAddress: RetailAddressManualReoffer;
};

type MapSecondPartValueType = {
  outcomeTitle: string;
  odds: number;
  eventTitle: string;
  marketName: string;
  selectionBonuses: MyBets.SelectionBonusesTypes;
};

export type MapSecondPartManualReofferParams = {
  value: MapSecondPartValueType;
  bets: TicketBet[];
  getState: () => RootState;
  currency: string;
  isCurrencyBeforeAmount: boolean;
};

export type MapEventContentSportEventNameParams = {
  value: {
    isLive: boolean;
    eventStartDate: string;
    sportEventName: string;
    scheduledTime: string;
  };
};

export type MapManualReofferBetsDataParams = {
  bets: TicketBet[];
  currency: string;
  isCurrencyBeforeAmount: boolean;
};

export type MapSummaryManualReofferParams = {
  bets: {
    [x: string]: any;
  };
  getState: () => RootState;
  currency: string;
  isCurrencyBeforeAmount: boolean;
};

export type PrintEventContentManualReofferParams = {
  selections: PrintEventContentSelectionOutcomes[];
  bets: TicketBet[];
  getState: () => RootState;
  currency: string;
  isCurrencyBeforeAmount: boolean;
};

export type PrintSummaryContentManualReofferParams = {
  bets: TicketBet[];
  getState: () => RootState;
  currency: string;
  isCurrencyBeforeAmount: boolean;
};

export type PrintBetContentParams = {
  bets: TicketBet[];
  currency: string;
  isCurrencyBeforeAmount: boolean;
};

export type PrintLastPartDataManualReofferParams = {
  getState: () => RootState;
};

export type PrintManualReofferDataMobileParams = {
  ticketId: string;
  getState: () => RootState;
  selections: PrintEventContentSelectionOutcomes[];
  bets: TicketBet[];
  currency: string;
};

export type PrintVoucherParams = {
  totalWin: number;
  code: string;
  currency: string;
  issuedAt: string;
  validUntil: string;
  isLinearCodeOnReceipt: boolean;
  retailAddress: RetailAddress;
  isCurrencyBeforeAmount: boolean;
};

export type MapFirstPartReceiptInfoParams = {
  ticketIdData: {
    str: string;
  };
  timestamp: number;
  retailAddress: {
    buLogo?: any;
    companyName?: string;
    address1?: string;
    address2?: string;
    betShopAddress?;
    betShopAddress1?;
    responsibleGambling: string;
    responsibleGambling1?: string;
    responsibleGambling2?: string;
    responsibleGambling3?: string;
  };
  isDuplicate: boolean;
  posAddress: PosAddress;
  posName: string;
  currentMnemonicCode: string;
  playerCpf: string;
};

export type MapFirstPartReceiptInfoMobileParams = {
  ticketId: string;
  timestamp: number;
  retailAddress;
  isDuplicate: boolean;
  posAddress: PosAddress;
  posName: string;
  currentMnemonicCode: string;
  playerCpf?: string;
};

export type ProcessRegularBetValue = {
  americanOdds: number;
  banker: boolean;
  customBetOutcomes: null;
  customBetSelections: CustomBetSelection[];
  displayAmericanOdds: number;
  displayFractionalOdds: string;
  displayOdds: number;
  eventStartDate: string;
  scheduledTime: string | number;
  fractionalOdds: string;
  id: string;
  isCustomBetSelection: boolean;
  isLive: boolean;
  marketName: string;
  marketTemplatedId: number;
  marketTemplateName: string;
  name: string;
  odds: number;
  outcomeShortName: string;
  selectionBonuses: MyBets.SelectionBonusesTypes;
  sportEventId: number;
  sportEventName: string;
  sportId: number;
  outcomeTitle: string;
  eventTitle: string;
  originalStake: number;
  originalStakeLong: number;
  usedStake: number;
  cashoutStake: number;
  timestamp: number;
  key: number;
};

export type PrintTerminalDataType = PrintTerminalDataParams<
  OutcomesData,
  {
    selectedSystem: string;
    outcomeId: string;
    totalCombinations: number;
    id: number;
    stake: number;
    win: number;
    campaigns: Campaign[];
    totalOdds: number;
    bankerBetTypeName: string;
    totalStake: number;
    totalWinLong: number;
  }
>;

export type ProcessRegularBetSelectionParams<T, K> = {
  value: K;
  getState: () => RootState;
  sportEventId: string;
  isSingle: boolean;
  getSingleBetProps: (value) => SingleBetProps | undefined;
  singles: T[];
  outcomes: K[];
  currency: string;
  isCurrencyBeforeAmount: boolean;
};

export type TerminalBetslipOutcomes = {
  americanOdds: number;
  banker: boolean;
  eventStartDate?: string;
  fractionalOdds: string;
  id: string;
  marketTemplateId: string;
  marketTemplateName: string;
  name: string;
  odds: number;
  outcomeShortName?: string;
  sportEventId: string;
  sportEventName: string;
  customBetOutcomes: CustomBetOutcomes;
  isCustomBetSelection?: boolean;
  selectionBonuses: MyBets.SelectionBonusesTypes;
};
export type CustomBetOutcomes = {
  homeTeam: string;
  awayTeam: string;
  eventClockStatus: Sport.EventClockStatus;
  isVirtual: any;
  live: boolean;
  tournamentId: number;
  selectionStatus: MyBets.SelectionStatus;
  betStatus: BetStatuses | undefined;
  eventPath: string;
  scheduledTime: string;
  americanOdds: number;
  banker: boolean;
  customBetOutcomes: CustomBetOutcomes;
  customBetSelections: null;
  displayAmericanOdds: null;
  displayFractionalOdds: null;
  displayOdds: null;
  eventStartDate: string;
  fractionalOdds: string;
  id: number;
  isCustomBetSelection: boolean;
  isLive: boolean;
  marketTemplateId: string;
  marketTemplateName: string;
  name: string;
  odds: number;
  outcomeShortName: string;
  selectionBonuses: MyBets.SelectionBonusesTypes;
  sportEventId: number;
  sportEventName: string;
  sportId: number;
  marketTemplate: string;
};

export type CustomBetSelection = {
  actualOdds: number;
  americanOdds: number;
  displayAmericanOdds: number;
  displayFractionalOdds: string;
  displayOdds: number;
  eventTitle: string;
  fractionalOdds: string;
  isActive: boolean;
  isCustomBetSelection: boolean;
  isLive: boolean;
  marketId: string;
  marketName: string;
  marketStatus: string;
  marketTemplateId: string;
  odds: number;
  outcomeId: string;
  outcomeTitle: string;
  productId: number;
  selectionBonuses: MyBets.SelectionBonusesTypes;
  sportEventId: number;
  sportId: number;
  sportName: string;
  tournamentId: number;
};

export type CustomBetSelections = CustomBetSelection[];

export type CustomBetSelectionValue = {
  sportEventId: number;
  outcomeId: string;
  marketId: number;
  americanOdds: number;
  fractionalOdds: string;
  actualOdds: number;
  banker: boolean;
  customBetSelections: CustomBetSelection[];
  displayAmericanOdds: number;
  displayFractionalOdds: string;
  displayOdds: number;
  isActive: boolean;
  isCustomBetSelection: boolean;
  isLive: boolean;
  marketStatus: string;
  odds: number;
  productId: number;
  selectionBonuses: MyBets.SelectionBonusesTypes;
  sportId: number;
  tournamentId: number;
};

export type MapBetBuilderSignManualReofferParams = {
  value: {
    customBetSelections: CustomBetSelection[];
    odds?: number;
  };
};

export type LastPartManualReofferParams = {
  retailAddress: {
    responsibleGambling: string;
    responsibleGambling1: string;
    responsibleGambling2: string;
    responsibleGambling3: string;
  };
};

export type MapBetBuilderEventsDataManualReofferParams = {
  customBet: CustomBetSelection;
  value: {
    customBetSelections: CustomBetSelection[];
  };
  getState: () => RootState;
};

export type MapBetbuilderSignParams = {
  value: {
    customBetOutcomes: CustomBetOutcomes[];
    odds?: number;
  };
};

export type MapBetbuilderEventsDataParams = {
  customBet: CustomBetOutcomes;
  getState: () => RootState;
};

type SingleBetProps = {
  selectedSystem: string;
  stake: number;
  totalOdds?: number;
  win: number;
};

export type MapBetbuilderSummaryBetDataParams<T extends { outcomeId: number | string }> = {
  value: {
    id: number;
  };
  currency: string;
  isSingle: boolean;
  singles: T[];
  getSingleBetProps: (value) => SingleBetProps | undefined;
  isCurrencyBeforeAmount: boolean;
};

export type ProcessCustomBetSelectionParams<K> = {
  value: {
    id: number;
    customBetOutcomes: CustomBetOutcomes[];
    odds?: number;
  };
  getState: () => RootState;
  currency: string;
  isSingle: boolean;
  singles: K[];
  getSingleBetProps: (value) => SingleBetProps | undefined;
  isCurrencyBeforeAmount: boolean;
};

export type ProcessManualReofferCustomBetParams = {
  value: {
    customBetSelections: CustomBetSelection[];
  };
  getState: () => RootState;
};

export type PayoutParams = {
  amount: number;
  billTotal: number;
  totalSum: number;
  translationLibr: TOptions;
  currencySymbol: string;
};

export type TicketAggregate = {
  oddsChange: string;
  status: string;
  language: string;
  timestamp: number;
  promoCode: number | null;
  promotionId: null;
  bets: TicketBet[];
  id: string;
  reofferRefId: number | null;
  selections: TerminalOutcomes[];
  sender: Sender;
  totalCombinations: number;
  metadata: Metadata;
  reason: string | null;
  processingError: string | null;
  odds: Odds[];
  reofferedTotalStake: string;
  context: unknown; // type null in the response
  lastOperationStartTimeTracker: number;
};

export type Odds = {
  selectionIndex: number;
  odds: number;
  actualOdds: number;
  marketStatus: string;
  isActive: boolean;
  outcomeId: number;
  innerSelections: innerSelections[];
};
export type innerSelections = {
  actualOdds: number;
  innerSelections: unknown[]; // type null in the response
  isActive: boolean;
  marketStatus: string;
  odds: number;
  outcomeId: number;
  selectionIndex: unknown; // type null in the response
};

export type TicketAggregateSummary = {
  selectedSystem: string;
  betId: string;
  stakeLong: number;
  bonusStakeLong: null;
  winLong: number;
  bonusWinLong: null;
  totalStakeLong: number;
  totalCombinations: number;
  outcomeId: number | string;
  sportEventId: null;
  stake: number;
  win: number;
  totalStake: number;
  totalOdds: number;
  bankerBetTypeName: null;
  outcomesData: OutcomeDataSSBT[];
  winGrossLong: number;
  winGross: number;
  totalDisplayOdds: number;
  totalWin: number;
  totalWinLong: number;
  campaigns: Campaign[];
};

export type OutcomeDataSSBT = {
  outcomeId: number | string;
  turnoverLong: number;
  bonusTurnoverLong: number;
  exposureStake: number;
  exposureBonusStake: number;
  exposureStakeLong: number;
  exposureBonusStakeLong: number;
  exposure: number;
  exposureBonus: number;
  exposureLong: number;
  exposureBonusLong: number;
  stake: number;
  bonusStake: number;
  stakeLong: number;
  bonusStakeLong: number;
  win: number;
  bonusWin: number;
  loss: number;
  bonusLoss: number;
  cashoutStake: number;
  cashoutBonusStake: number;
  cashoutUsedStake: number;
  cashoutUsedBonusStake: number;
  previousStakeLong: number | null;
  previousWin: number | null;
  previousLoss: number | null;
  previousCashoutStake: number | null;
  previousCashoutUsedStake: number | null;
  winGross: number | null;
};

export type Metadata = {
  summaries: TicketAggregateSummary[];
  totalStake: number;
  totalOdds: number;
  totalWin: number;
  totalCombinations: number;
  totalStakeLong: number;
  totalWinLong: number;
  totalDisplayOdds: number;
  paidWin: number;
  paidWinLong: number;
};

export type Sender = {
  terminalId: string;
  device: string;
  offsetUTC: number;
  shopId: number | null;
  ip: string;
  deviceId: number | null;
  id: string;
  businessUnit: string;
  currency: string;
  sessionId: number | null;
  baseCurrency: string;
  systemCurrency: string;
  posId: number;
  channel: string;
};

export type TerminalThunks = {
  depositTerminal: CreateThunk<{ totalSum: number }, DepositTerminalResponse, string | null>;
  depositRetail: CreateThunk<{ totalSum: number }, RetailDepositInfo, string | null>;
  ssbtPayoutTicket: CreateThunk<{ code: string }, string | null>;
};
export type VoucherThunk = {
  createVoucher: CreateThunk<Record<string, never>, RetailVoucherInfo, string | null>;
  getVoucherInfo: CreateThunk<{ voucherCode: string }, RetailVoucher, string | null>;
  getVoucherInfoCashier: CreateThunk<{ voucherCode: string }, VoucherInfoCashier, string | null>;
  depositWithVoucher: CreateThunk<{ code: string }, Record<string, unknown>, string | null>;
  payoutVoucher: CreateThunk<{ code: string }, Record<string, unknown>, string | null>;
  cancelVoucher: CreateThunk<{ code: string }, Record<string, unknown>, string | null>;
  createVoucherCashier: CreateThunk<{ amount: string }, VoucherCashier, string | null>;
};
export type TicketInfoThunk = {
  checkTicket: CreateThunk<{ ticketId?: string }, TicketInfoType, string | null>;
};

export type TerminalDepositMethodsIcons =
  | 'A1'
  | 'CASH'
  | 'VISA'
  | 'EPAY'
  | 'SKRILL'
  | 'VOUCHER'
  | 'EASYPAY'
  | 'NETELLER'
  | 'TWISTPLAY'
  | 'MASTER_CARD'
  | 'CASHTERMINAL'
  | 'BANK_TRANSFER'
  | 'STATIA_DE_PLATA';

export enum TerminalModalActionIcons {
  CASHOUT = 'cashout',
  DEPOSIT = 'deposit',
  DEPOSIT_CARD = 'deposit-card',
  DEPOSIT_CASH = 'deposit-cash',
  QR = 'qr',
  SCANNER_TICKET = 'scanner-ticket',
  WALLET = 'wallet',
}
export enum RetailHeaderIcons {
  CASHIER = 'cashier',
  CASHOUT = 'cashout',
  DEPOSIT = 'deposit',
  FINANCE = 'finance',
  LOGIN = 'login',
  LOGOUT = 'logout',
  QR = 'qr',
  REPRINT = 'reprint',
  CASH_PAY = 'cash-pay',
  PRINT = 'print',
  VOUCHER = 'ticket',
}

export enum FormSteps {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
}
