import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

/** Cashier Modal Body Section */
interface BodyProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children: React.ReactNode;
}

const Body: React.FC<BodyProps> = ({ className, children }) => {
  const bodyClass = clsx('cashier-modal__body', className);
  return <div className={bodyClass}>{children}</div>;
};

export default Body;
