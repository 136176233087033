import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrency } from 'hooks/common/useCurrency';
import { formatAmountToBRL } from 'modules/retail/modules/cashier/utils';
import { isEmpty } from 'utils/common/helpersCommon';

interface ReprintTicketHeaderProps {
  children?: React.ReactNode;
  ticketId?: string;
  createDate?: string;
  amount?: number;
  product?: string;
  tickedStatus?: string;
  paymentTickedStatus?: string;
  mnemonicCode?: string | null;
  onClick?: React.MouseEventHandler;
  isCurrencyBeforeAmount?: boolean;
}

const ReprintTicketHeader: React.FC<ReprintTicketHeaderProps> = ({
  children,
  ticketId,
  createDate,
  amount,
  product,
  tickedStatus,
  paymentTickedStatus,
  mnemonicCode,
  onClick,
  isCurrencyBeforeAmount,
}) => {
  const { t } = useTranslation();
  const { currencyCode, currencySymbol } = useCurrency();
  return (
    <egtd-reprint-ticket-header onClick={onClick}>
      <egtd-reprint-ticket-header-id>
        <span className="label">{t('Cashier.CashierModal.ticketId')} </span>
        {ticketId}
      </egtd-reprint-ticket-header-id>

      <egtd-reprint-ticket-header-details>
        <span className="text">{createDate} / </span>
        <span className="text">{`${t(`Cashier.TicketType.${product}`)}`} / </span>
        <span className="text">{`${t(`Cashier.TicketStatus.${tickedStatus}`)}`} / </span>
        <span className="text">
          {/* maybe label will need translation */}
          <span className="label">{`${t('Cashier.CashPayModal.amount')}: `}</span>
          {isCurrencyBeforeAmount ? (
            <>
              {currencySymbol} {formatAmountToBRL(amount)}
            </>
          ) : (
            <>
              {amount} {currencyCode}
            </>
          )}{' '}
          /
        </span>
        <span className="text">{`${t(`Cashier.PaymentTicketStatus.${paymentTickedStatus}`)}`} </span>
        {!isEmpty(mnemonicCode) && <span className="text">{`/${mnemonicCode}`}</span>}
      </egtd-reprint-ticket-header-details>

      {children}
    </egtd-reprint-ticket-header>
  );
};

export default ReprintTicketHeader;
