import { Desktop, DesktopDown } from 'theme/Theme';
import { SliderNotificationsImage } from './SliderNotificationsImage';
import { NotificationTypes } from '../../../notificationTypes';
type Props = {
  item: NotificationTypes.PopUpNotification;
  type?: 'carousel' | 'thumb';
};
export const SliderNotificationsItemThumb: React.FC<Props> = ({ item, type = 'thumb' }) => {
  return (
    <>
      <DesktopDown>
        <SliderNotificationsImage type={type} device="mobile" item={item} />
      </DesktopDown>
      <Desktop>
        <SliderNotificationsImage type={type} device="desktop" item={item} />
      </Desktop>
    </>
  );
};
