import React from 'react';
import { cssGlobalNS } from 'utils/common/types/css.types';

/** BetSlip OddsBoostToken */
type OddsBoostTokenProps = {
  text: string;
};
const OddsBoostToken: React.FC<OddsBoostTokenProps> = ({ text }) => {
  return (
    <div
      className={`${cssGlobalNS}-p-bet-log__systems ${cssGlobalNS}-p-bet-log__box ${cssGlobalNS}-p-bet-log__systems-box`}
    >
      {text}
    </div>
  );
};

export default OddsBoostToken;
