import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { ResetPasswordGridState, ResetPasswordThunksProps } from '../types/resetPassword.types';

const initialState: ResetPasswordGridState = {
  submitError: '',
  validationTokenError: 'Invalid',
  isLoading: 'idle',
  successChangePassword: false,
};

export const ResetPasswordThunks: ResetPasswordThunksProps = {
  validateToken: createAbortThunk('resetPassword/validateToken', async (args, { rejectWithValue }) => {
    const { userToken, userEmail } = args;
    const url = `${config.API_URL}/api/ews-crm/public/users/forgotten-password`;
    const queryParams = {
      token: userToken,
      email: userEmail,
    };
    try {
      const response = await axiosInstance.get(url, { params: queryParams });
      if (response?.status && response.status === 200) {
        return response.data;
      }
    } catch (err) {
      return rejectWithValue(getError.responseDataErrors(err));
    }
  }),
  submitChangePassword: createAbortThunk(
    `resetPassword/submitChangePassword`,
    async (args, { rejectWithValue, getState }) => {
      const { newPassword, confirmNewPassword, userToken, userEmail } = args;
      const encodedToken = encodeURIComponent(userToken);
      const url = `${config.API_URL}/api/ews-crm/player/users/reset-password?token=${encodedToken}&email=${userEmail}`;
      const netAppHeader = selectGeneral.netAppHeader(getState());
      const headers = netAppHeader ? { 'x-platform-hash-netapp': netAppHeader } : {};

      const dataForSend = {
        newPassword,
        confirmNewPassword,
      };

      try {
        const response = await axiosInstance.put(url, dataForSend, { headers });
        if (response?.status && response.status === 204) {
          return response.data;
        }
      } catch (err) {
        return rejectWithValue(getError.responseDataMessage(err));
      }
    },
  ),
  submitChangePasswordPhone: createAbortThunk(
    `resetPassword/submitChangePasswordPhone`,
    async (args, { rejectWithValue, getState }) => {
      const { newPassword, confirmNewPassword, verificationCode, playerId, phoneNumber } = args;
      const url = `${config.API_URL}/api/ews-crm/player/users/reset-password-phone`;
      const netAppHeader = selectGeneral.netAppHeader(getState());
      const headers = netAppHeader ? { 'x-platform-hash-netapp': netAppHeader } : {};

      const dataForSend = {
        newPassword,
        confirmNewPassword,
        verificationCode,
        playerId,
        phoneNumber,
      };

      try {
        const response = await axiosInstance.put(url, dataForSend, { headers });
        if (response?.status && response.status === 204) {
          return response.data;
        }
      } catch (err) {
        return rejectWithValue(getError.responseDataMessage(err));
      }
    },
  ),
};

const resetPasswordSlice = createSlice({
  name: 'reset-password',
  initialState,
  reducers: {
    resetSubmitError(state) {
      state.submitError = '';
    },
    setResetPassLoadedState(state) {
      state.validationTokenError = '';
      state.isLoading = 'succeeded';
    },
    setResetPasswordInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(ResetPasswordThunks.validateToken.fulfilled, (state) => {
        state.validationTokenError = '';
        state.isLoading = 'succeeded';
      })
      .addCase(ResetPasswordThunks.validateToken.pending, (state) => {
        state.isLoading = 'pending';
      })
      .addCase(
        ResetPasswordThunks.validateToken.rejected,
        (state, action: PayloadAction<string | { rejectValue: string } | null | undefined>) => {
          state.isLoading = 'failure';
          state.submitError = action.payload as string;
        },
      );
    builder
      .addMatcher(
        isAnyOf(
          ResetPasswordThunks.submitChangePasswordPhone.fulfilled,
          ResetPasswordThunks.submitChangePassword.fulfilled,
        ),
        (state) => {
          state.successChangePassword = true;
          state.isLoading = 'succeeded';
        },
      )
      .addMatcher(
        isAnyOf(
          ResetPasswordThunks.submitChangePasswordPhone.pending,
          ResetPasswordThunks.submitChangePassword.pending,
        ),
        (state) => {
          state.isLoading = 'pending';
        },
      )
      .addMatcher(
        isAnyOf(
          ResetPasswordThunks.submitChangePasswordPhone.rejected,
          ResetPasswordThunks.submitChangePassword.rejected,
        ),
        (state, action: PayloadAction<string | { rejectValue: string } | null | undefined>) => {
          state.isLoading = 'failure';
          state.submitError = action.payload as string;
        },
      );
  },
});

export const { resetSubmitError, setResetPassLoadedState, setResetPasswordInitialState } = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;

export const selectResetPassword = {
  successChangePassword: (state: RootState): boolean => state.auth.resetPassword?.successChangePassword,
  isLoading: (state: RootState): boolean =>
    state.auth.resetPassword?.isLoading === 'pending' || state.auth.resetPassword?.isLoading === 'idle',
  submitError: (state: RootState): string => state.auth.resetPassword?.submitError,
  validationTokenError: (state: RootState): string => state.auth.resetPassword?.validationTokenError,
};
