import { useCallback, useLayoutEffect } from 'react';
import { gridAnimationDefault } from 'modules/casino/modules/container/cellsContainer/grid/components/gridCell/gridCellConstants';
import { getCellPosition } from 'modules/casino/shared/utils/casinoUtils';
import { useLoadingElements } from './useLoadingElements';
import { CategoryQueryType } from '../constants';

const exceptionalRouteCollections: CategoryQueryType[] = [
  CategoryQueryType.FAVOURITES_CATEGORY,
  CategoryQueryType.SEARCH_BY_TITLE,
  CategoryQueryType.LAST_PLAYED_CATEGORY,
  CategoryQueryType.SVARA_CATEGORY,
];
export const useLoadingEffect = (
  gameCellRef: React.MutableRefObject<HTMLDivElement | null>,
  collectionId: string,
  uniqueId: string,
  animation: string = gridAnimationDefault.FADE_IN,
  onShowItemWithoutAnimation: () => void,
  useLoadAnimation: boolean,
  queryType?: CategoryQueryType,
  skipLazyLoad?: boolean,
  shouldLoad?: boolean,
): {
  hasFinishedLoading: () => void;
  onAnimationStart: (event) => void;
} => {
  const { helpers, initLoading } = useLoadingElements();
  const withAnimation = !skipLazyLoad && useLoadAnimation;

  const forcedShowItem = () => {
    const item = getCellPosition();
    const currentIndex = helpers.currentIndex({ collectionId: collectionId, elementId: uniqueId });
    const closedItemIndex = helpers.currentIndex({ collectionId: collectionId, elementId: item?.uniqueId });

    const comparingIndex =
      queryType && exceptionalRouteCollections.includes(queryType)
        ? helpers.collectionLength({ collectionId })
        : currentIndex;
    const skipLoading = item && closedItemIndex !== -1 ? comparingIndex <= closedItemIndex : item;

    if ((!item && skipLazyLoad) || skipLoading) {
      helpers.forcedShowItem({ collectionId, currentIndex });
    }
  };

  useLayoutEffect(() => {
    const loadingOptions = {
      useAnimation: withAnimation,
      useLoadingEffect: !skipLazyLoad,
    };

    const onShowElement = () => {
      const noAnimationCss = () => {
        gameCellRef?.current?.classList.remove('casino-grid-item--loading');
        gameCellRef?.current?.classList.add(`casino-grid-item--is-loaded-no-animation`);
        onShowItemWithoutAnimation && onShowItemWithoutAnimation();
      };
      const animationCss = () => {
        gameCellRef?.current?.classList.remove('casino-grid-item--loading');
        if (shouldLoad) {
          gameCellRef?.current?.classList.add(`casino-grid-item--is-loaded-animate-svara`);
        } else {
          gameCellRef?.current?.classList.add(`casino-grid-item--is-loaded-${animation}`);
        }
      };

      skipLazyLoad ? noAnimationCss() : withAnimation ? animationCss() : noAnimationCss();
    };
    initLoading({ loadingOptions, collectionId, elementId: uniqueId, onShowElement });

    if (gameCellRef.current) {
      gameCellRef.current?.classList.add('casino-grid-item--loading');
      if (skipLazyLoad) {
        forcedShowItem();
      }
    }

    return () => {
      if (!helpers.collectionLength({ collectionId })) {
        return;
      }
      if (queryType && exceptionalRouteCollections.includes(queryType)) {
        helpers.removeItem({ collectionId, elementId: uniqueId });
      } else {
        helpers.reset({ collectionId, elementId: uniqueId });
        if (gameCellRef.current) {
          gameCellRef.current?.classList.add('casino-grid-item--loading');
        }
      }
    };
  }, [collectionId, uniqueId]);

  return {
    hasFinishedLoading: useCallback(() => {
      helpers.onLoadImage({ collectionId, elementId: uniqueId, pathName: window.location.pathname });
    }, [collectionId, uniqueId, helpers]),
    onAnimationStart: useCallback(() => {
      helpers.onAnimationStart({ collectionId, elementId: uniqueId });
    }, [collectionId, uniqueId, helpers]),
  };
};
