import userStorage from 'pages/auth/login/UserStorage';

const sendPlayerToRNWebView = (
  action: string,
  url: string | null = null,
  playerId: string | null = null,
  callback?: (result) => void,
) => {
  const player = {
    id: playerId,
    lang: userStorage.getUserLang(),
    token: userStorage.getUser()?.access_token,
    timezone: userStorage.getUserTimeZone(),
    url: url,
    action: action,
  };

  // sent player data to react native webview
  window.ReactNativeWebView?.postMessage(JSON.stringify(player));

  window.addEventListener('message', async (event) => {
    callback && callback(event);
  });
};

export default sendPlayerToRNWebView;
