import { useCallback, useEffect, useRef } from 'react';
import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

const useSliderRotatingConfig = () => {
  const paramsRef = useRef<any>(null);
  const swiperRef = useRef(null);
  const handleBeforeDestroy = useCallback((swiper) => {
    swiper.autoplay.stop();
  }, []);

  const updateHeroProgress = (swiper) => {
    swiperRef.current = swiper;
  };

  const handleOrientationChange = (swiper) => {
    if (swiper.autoplay) {
      swiper.autoplay.start();
      swiper.autoplay.paused = false;
      setTimeout(function () {
        swiper.autoplay.start();
        swiper.autoplay.paused = false;
      }, 500);
    }
  };

  useEffect(() => {
    const listenerFunction = () => handleOrientationChange(swiperRef.current);
    window.addEventListener('orientationchange', listenerFunction);
    return () => {
      window.removeEventListener('orientationchange', listenerFunction);
    };
  }, []);

  !paramsRef.current &&
    (paramsRef.current = {
      Swiper,
      slidesPerView: 1,
      spaceBetween: 0,
      modules: [Navigation, Autoplay, Pagination],
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      on: {
        beforeDestroy: handleBeforeDestroy,
        init: updateHeroProgress,
        resize: (swiper) => handleOrientationChange(swiper),
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: true,
      rebuildOnUpdate: false,
      shouldSwiperUpdate: true,
      resizeObserver: true,
      updateOnWindowResize: true,
    });
  return {
    params: paramsRef.current,
  };
};

export default useSliderRotatingConfig;
