import useCasinoWidgetConfig from 'modules/casino/platformImplementation/hooks/useCasinoWidgetConfig';
import { selectIntegrations } from 'shared/common/features/integrations/slice/integrations.slice';
import { useAppSelector } from 'store';
import { favoriteGamesSelectors } from '../slice/casinoFavouriteGamesSlice';

export const useGetFavGamesCounter = (): number => {
  const casinoWidgetConfig = useCasinoWidgetConfig();
  const favGamesCount = useAppSelector(favoriteGamesSelectors.favoriteGamesCount);
  const casinoFavGamesCounter = useAppSelector(selectIntegrations.casinoFavGamesCounter);

  return casinoWidgetConfig ? casinoFavGamesCounter : favGamesCount;
};
