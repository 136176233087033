import styled, { css, keyframes } from 'styled-components';

const particles = 40;
const width = 360;
const height = 360;

const initWidth = Math.floor(Math.random() * width) - width / 2 + 'px';
const initHeight = Math.floor(Math.random() * height) - height / 1.2 + 'px';
const initColor = `hsl(${Math.floor(Math.random() * 360)}, 100%, 50%)`;

const boxShadow = [`${initWidth} ${initHeight} ${initColor}`];
const boxShadow2 = ['hsl(0, 0%, 100%)'];

for (let i = 0; i < particles; i++) {
  const genWidth = Math.floor(Math.random() * width) - width / 2;
  const genHeight = Math.floor(Math.random() * height) - height / 1.2;
  const genColor = `hsl(${Math.floor(Math.random() * 360)}, 100%, 50%)`;

  boxShadow.push(`, ${genWidth}px ${genHeight}px ${genColor}`);
  boxShadow2.push(', hsl(0, 0%, 100%)');
}

const bangAnimation = keyframes`
    to {
        box-shadow: ${boxShadow};
    }
    `;

const gravityAnimation = keyframes`
    to {
      transform: translateY(200px);
      opacity: 0;
    }
`;

const positionAnimation = keyframes`
    0%,
    19.9% {
      margin-top: 10%;
      margin-left: 40%;
    }
    20%,
    39.9% {
      margin-top: 40%;
      margin-left: 30%;
    }
    40%,
    59.9% {
      margin-top: 20%;
      margin-left: 70%;
    }
    60%,
    79.9% {
      margin-top: 30%;
      margin-left: 20%;
    }
    80%,
    99.9% {
      margin-top: 30%;
      margin-left: 80%;
      transform: scale(0);
    }
`;

const StyledPyroShared = css`
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  box-shadow: ${boxShadow2};
  animation:
    1s ${bangAnimation} ease-out 4 backwards,
    1s ${gravityAnimation} ease-in 4 backwards,
    5s ${positionAnimation} linear 4 backwards;
`;

export const StyledPyroBefore = styled.div`
  ${StyledPyroShared}
`;

export const StyledPyroAfter = styled.div`
  ${StyledPyroShared}
  animation-delay: 1.25s, 1.25s, 1.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
`;
