import React from 'react';
import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import { StyledNames } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/layoutWrapperConstants';
import { GameImage } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellMedia/components/GameImage';
import { GameVideo } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellMedia/components/GameVideo';
import { GameMediaTypes } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellMedia/gameCellMediaTypes';
import { GridType } from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import { useSetRefsLoaded } from 'modules/casino/shared/hooks/useSetRefsLoaded';

const GameCellMedia: React.FC<GameMediaTypes.GameMediaProps> = ({
  gameUniqueId,
  categoryId,
  finishedLoading,
  skipLazyLoad,
  layoutStyleLoader,
  styledType,
  layoutGrid,
}) => {
  const { isVideo, gridType } = useGameProps({ gameUniqueId, categoryId });
  const { refLoaded, setRefs, ref: gameMediaRef } = useSetRefsLoaded();
  const GameMediaStyled = layoutStyleLoader(StyledNames.GameMediaStyled, styledType);
  const renderImage = () => (
    <GameImage
      gameUniqueId={gameUniqueId}
      categoryId={categoryId}
      gameMediaRef={gameMediaRef}
      finishedLoading={finishedLoading}
      skipLazyLoad={skipLazyLoad}
      layoutGrid={layoutGrid}
    />
  );

  const renderMedia = () => {
    if (isVideo) {
      return (
        <GameVideo gameUniqueId={gameUniqueId} categoryId={categoryId} layoutGrid={layoutGrid}>
          {renderImage()}
        </GameVideo>
      );
    }

    return renderImage();
  };

  return (
    GameMediaStyled && (
      <GameMediaStyled className={'media-wrapper'} ref={setRefs} $isSlider={GridType.SLIDER === gridType}>
        {refLoaded && renderMedia()}
      </GameMediaStyled>
    )
  );
};

export default GameCellMedia;
GameCellMedia.displayName = 'GameCellMedia';
