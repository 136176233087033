import { combineReducers } from '@reduxjs/toolkit';
import claimTicketReducer from 'modules/retail/modules/cashier/features/claimTicket/slice/claimTicket.slice';
import anexReportReducer from '../modules/cashier/features/anex2Report/slice/anexReport.slice';
import financialReducer from '../modules/cashier/features/finance/slice/financialReports.slice';
import lastPrintReducer from '../modules/cashier/features/lastPrint/slice/lastPrint.slice';
import reprintTicketReducer from '../modules/cashier/features/reprintTicket/slice/reprintTicket.slice';
import ucnPlayerReducer from '../modules/cashier/features/UCNForm/slice/playerInfo.slice';
import ucnCheckReducer from '../modules/cashier/features/ucnScan/slice/scanUCN.slice';
import cashierRetailReducer from '../modules/cashier/slice/cashier.slice';
import rgbControllerReducer from '../modules/ssbt/features/rgbController/slice/rgbController.slice';
import sessionBalanceReducer from '../modules/ssbt/features/sessionBalance/slice/sessionBalance.slice';
import ssbtReducer from '../modules/ssbt/slice/ssbt.slice';
import cashPayReducer from '../shared/features/cashPay/slice/cashPay.slice';
import ticketSSBTInfo from '../shared/slice/checkTicket.slice';
import printQueueReducer from '../shared/slice/printQueue.slice';
import voucherReducer from '../shared/slice/voucher.slice';

export const retailReducer = combineReducers({
  sessionBalance: sessionBalanceReducer,
  cashier: cashierRetailReducer,
  printQueue: printQueueReducer,
  cashPay: cashPayReducer,
  ssbt: ssbtReducer,
  voucher: voucherReducer,
  ticket: ticketSSBTInfo,
  rgbController: rgbControllerReducer,
  anexReport: anexReportReducer,
  reprintTicket: reprintTicketReducer,
  claimTicket: claimTicketReducer,
  financialReports: financialReducer,
  ucnPlayerInfo: ucnPlayerReducer,
  lastPrintInfo: lastPrintReducer,
  ucnCheck: ucnCheckReducer,
});
