import React, { memo, useCallback } from 'react';
import { GameType } from 'modules/casino/shared/constants';
import TickerSingleItem from './TickerSingleItem';
import { TickerItem } from './types/ticker.types';

interface Props {
  mostPlayed: TickerItem;
}

const TickerSingleItemPlayGame: React.FC<Props> = ({ mostPlayed }) => {
  const mockTicker = {
    vertical: 'casino-games',
    gameProviderId: '1',
    description: 'Shining Crown',
    refGameId: '839',
    isFreePlay: false,
    iframeLoad: false,
    gameType: GameType.SLOT_GAME,
    jackpotId: '',
  };
  // const { openLaunchGame } = useOpenGameLaunch({
  //   providerId: mockTicker.gameProviderId,
  //   id: mockTicker.refGameId,
  //   refGameId: mockTicker.refGameId,
  //   providerAlias: '',
  //   iframeLoad: mockTicker.iframeLoad,
  //   description: mockTicker.description,
  //   vertical: mockTicker.vertical,
  //   gameType: mockTicker.gameType,
  //   jackpotId: mockTicker.jackpotId,
  // });

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      // openLaunchGame(mockTicker.isFreePlay ? GamePlayType.FREE : GamePlayType.PAID);
    },
    [mockTicker.isFreePlay],
  );

  return <TickerSingleItem mostPlayed={mostPlayed} onClickHandler={handleClick} />;
};

export default memo(TickerSingleItemPlayGame);
