import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Flex } from 'components/shared/layout';
import { useOnOff } from 'hooks/common/useOnOff';
import { Breakpoints, Desktop, DesktopDown } from 'theme/Theme';
import { CommonTranslations } from 'utils/common/translationUtils/translationStrings';
import FormCaptcha from './FormCaptcha';
import { FormUI } from './FormUI';
import InputGroup from './InputGroup';
import InputGroupPrepend from './InputGroupPrepend';
import { Captcha, InputLocationType, InputModeType, RefType } from '../formTypes';
import useFormCaptcha from '../hooks/useFormCaptcha';

interface Props {
  componentLocation?: InputLocationType;
  /** Group Props */
  isMobileAgnostic?: boolean;
  classNames?: string;
  label?: string;
  labelOnlyOnDesktop?: boolean;
  groupType?: 'default' | 'simple';
  /** Input Prefix Props */
  prefixOnlyOnMobile?: boolean;
  prefixText?: string;
  iconPrefix?: string;
  /** Input Props */
  type: string | undefined;
  name: string | undefined;
  value?: string | undefined;
  ci?: Captcha; // Base64 captcha image & refresh url.
  placeholder?: string | undefined;
  autoComplete?: string;
  onChange?: (e) => void;
  onClick?: (e) => void;
  onFocus?: () => void;
  onBlur?: (v) => void;
  maxLength?: number;
  minLength?: number;
  disabled?: boolean;
  inputClass?: string;
  inputMode?: InputModeType;
  /** Messages Props */
  hasSuccess?: boolean;
  hasSuccessIcon?: boolean;
  hasError?: boolean;
  hasErrorIcon?: boolean;
  errorMessage?: string;
  errorOnlyOnDesktop?: boolean;
  infoMessage?: string;
  controlRef?: RefType;
  externalError?: boolean;
  readOnly?: boolean;
}

const FormInputUICaptcha: React.FC<Props> = (props) => {
  const [showInfo, setShowInfo] = useOnOff();
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isTabletOrMobile}px)` });
  const { t } = useTranslation();
  const { refreshCaptcha, remainingTime, captchaImage, shouldRefresh } = useFormCaptcha({ ci: props.ci });

  const handleCaptchaRefresh = () => {
    if (shouldRefresh) {
      refreshCaptcha();
      // Clear captcha input field upon Captcha image refresh button click
      props?.onChange && props.onChange('');
    }
  };

  const handleBlur = !props.infoMessage
    ? props.onBlur
    : (v) => {
        setShowInfo(false);
        props.onBlur && props.onBlur(v);
      };

  const handleFocus = !props.infoMessage
    ? props.onFocus
    : () => {
        setShowInfo(true);
        props.onFocus && props.onFocus();
      };

  const handleOnClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!props.disabled) {
      props.onClick && props.onClick(e);
    }
  };
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!props.disabled && props.onChange) {
      props.onChange(e);
    }
  };

  const groupClass = clsx(
    'form-group',
    props.isMobileAgnostic && (isTabletOrMobile ? 'form-group-simple' : 'form-group-default'),
    props.componentLocation === 'login' && 'form-group--login',
    props.groupType === 'default' && 'form-group-default',
    props.groupType === 'simple' && 'form-group-simple',
    props.externalError && 'has-external-error',
    'form-group--captcha',
  );

  const inputWrapperClass = clsx(
    'd-flex-col fcw',
    props.groupType === 'default' && 'fcw--default',
    props.groupType === 'simple' && 'fcw--simple',
    props.hasError && 'has-error bg--error',
    props.hasErrorIcon && 'has-error-icon',
    props.hasSuccess && 'has-success',
    props.hasSuccessIcon && 'has-success-icon',
    showInfo && 'has-info bg--info',
  );

  const inputGroupCLass = clsx('input-group--simple input-group--login');

  const inputClass = clsx(
    'form-control',
    props.componentLocation === 'login' && 'form-control--simple form-control--login',
    props.inputClass && props.inputClass,
  );

  return (
    <>
      <Flex.ACJB className="w-100 login__captcha">
        <div className={groupClass}>
          {props.label &&
            (props.labelOnlyOnDesktop ? (
              <Desktop>
                <FormUI.Label componentLocation={props.componentLocation} label={t(props.label)} />
              </Desktop>
            ) : (
              <FormUI.Label componentLocation={props.componentLocation} label={t(props.label)} />
            ))}
          <div className={inputWrapperClass} id="input-wrapper">
            <InputGroup className={inputGroupCLass}>
              {props.iconPrefix &&
                (props.prefixOnlyOnMobile ? (
                  <DesktopDown>
                    <InputGroupPrepend icon={props.iconPrefix} />
                  </DesktopDown>
                ) : (
                  <InputGroupPrepend icon={props.iconPrefix} />
                ))}
              {props.prefixText && (
                <Flex.Center className="input-group-prepend">
                  <span className="input-prepend-text">{props.prefixText}</span>
                </Flex.Center>
              )}
              <input
                ref={props.controlRef}
                type={props.type}
                name={props.name}
                value={props.value}
                className={inputClass}
                placeholder={t(props.placeholder || '')}
                autoComplete={props.autoComplete}
                onChange={handleOnChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                maxLength={props.maxLength}
                minLength={props.minLength}
                disabled={props.disabled}
                inputMode={props.inputMode || 'text'}
                aria-label={props.name}
                data-qid={props.name}
                onClick={handleOnClick}
                readOnly={props.readOnly}
              />
            </InputGroup>
          </div>
        </div>
        <FormCaptcha onClick={handleCaptchaRefresh} captchaImage={captchaImage} disabled={remainingTime > 0} />
      </Flex.ACJB>
      {props.hasError &&
        (props.errorOnlyOnDesktop ? (
          <Desktop>
            <FormUI.Message type="error" content={props.errorMessage} />
          </Desktop>
        ) : (
          <FormUI.Message type="error" content={props.errorMessage} />
        ))}
      {showInfo && <FormUI.Message type="info" content={t(props.infoMessage || '')} />}
      {remainingTime > 0 && (
        <div className="login__captcha-info">
          {t(props?.ci?.remaningTimeText || CommonTranslations.Time.remainingTimeText)} {remainingTime}{' '}
          {t(CommonTranslations.Time.abbSeconds)}
        </div>
      )}
    </>
  );
};

export default FormInputUICaptcha;
