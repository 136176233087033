import React from 'react';
import clsx from 'clsx';
import Icon from './Icon';

interface PointerProps {
  direction: 'up' | 'right' | 'down' | 'left';
  size?: number;
  className?: string;
}
const Pointer: React.FC<PointerProps> = ({ direction, size, className }) => {
  const pointerClass = clsx('pointer', className);
  return <Icon className={pointerClass} size={size} defaultClassName={false} icon={`chevron-${direction}`} />;
};

export default Pointer;
