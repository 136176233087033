import { t } from 'i18next';
import { setIsPrintingEjected } from 'modules/retail/modules/cashier/slice/cashier.slice';
import { PrintCashierPayoutVoucher, PrintVoucherCashier } from 'modules/retail/modules/cashier/types/cashier.types';
import { formatCurrency } from 'modules/retail/modules/cashier/utils';
import { enqueuePrintRequest, processPrintQueue } from 'modules/retail/shared/slice/printQueue.slice';
import { CommandData } from 'modules/retail/shared/types/retailCommon.types';
import {
  commands,
  monitorPrintingCompletion,
  printEjectCommand,
  printMethods,
} from 'modules/retail/shared/utils/retail.utils';
import { store } from 'store';
import { getError, isEmpty, retailFormatDate } from 'utils/common/helpersCommon';
import { makeRequest } from '../../../shared/printMethods/printUtils/helperPrintFunctions';
import {
  _addressOptions,
  _logoOptionsData,
  _optionsData,
  eventOptions,
  voucherValueOptions,
  winbetAddress,
  winbetOptions,
} from '../../../shared/printMethods/printUtils/printReceiptOptions';

export const printCashierPayoutVoucher = async ({
  stake,
  currency,
  retailAddress,
  actionName,
  posAddress,
  posName,
  isCurrencyBeforeAmount,
}: PrintCashierPayoutVoucher) => {
  const printCashierPayoutVoucherJob = async () => {
    const printVoucherData: CommandData[] = [];
    printMethods.printIsReady(printVoucherData, commands);
    printMethods.printStart(printVoucherData, commands);
    printMethods.print(printVoucherData, commands, '');
    printMethods.printOptions(printVoucherData, commands, _logoOptionsData);
    printMethods.printImage(printVoucherData, commands, retailAddress?.buLogo);
    printMethods.print(printVoucherData, commands, '');
    printMethods.printOptions(printVoucherData, commands, winbetOptions);
    printMethods.print(printVoucherData, commands, t('Cashier.Receipt.addressPOSText'));
    printMethods.print(
      printVoucherData,
      commands,
      `${posAddress.city}, ${posAddress.streetName} ${posAddress.streetNumber}`,
    );
    printMethods.print(printVoucherData, commands, '');
    printMethods.print(printVoucherData, commands, t('Cashier.Receipt.signatureNumber'));
    printMethods.print(printVoucherData, commands, posName);
    printMethods.print(printVoucherData, commands, '');
    printMethods.conditionalPrint(
      printVoucherData,
      commands,
      window.config.ENV_TYPE !== 'PRODUCTION',
      t('Cashier.Receipt.testOperation'),
    );
    printMethods.printOptions(printVoucherData, commands, _optionsData);
    printMethods.print(printVoucherData, commands, '');
    printMethods.printOptions(printVoucherData, commands, winbetOptions);
    printMethods.print(printVoucherData, commands, '');
    printMethods.print(printVoucherData, commands, ''.padEnd(44, '='));
    printMethods.print(printVoucherData, commands, '');
    printMethods.printOptions(printVoucherData, commands, _addressOptions);
    printMethods.print(printVoucherData, commands, t(actionName));
    printMethods.print(printVoucherData, commands, '');
    printMethods.printOptions(printVoucherData, commands, voucherValueOptions);
    printMethods.printOptions(printVoucherData, commands, winbetOptions);
    printMethods.print(printVoucherData, commands, ''.padEnd(44, '='));
    printMethods.print(printVoucherData, commands, '');
    printMethods.printOptions(printVoucherData, commands, voucherValueOptions);
    printMethods.colDefine(printVoucherData, commands, 290, 'middle', 'left');
    printMethods.colDefine(printVoucherData, commands, 290, 'middle', 'right');
    printMethods.print(printVoucherData, commands, `${t('Cashier.CashPayModal.amount')}: `);
    printMethods.print(printVoucherData, commands, formatCurrency(stake, currency, isCurrencyBeforeAmount) + ' ');
    printMethods.colReset(printVoucherData, commands);
    printMethods.print(printVoucherData, commands, '');
    printMethods.printOptions(printVoucherData, commands, winbetAddress);
    printMethods.print(printVoucherData, commands, ''.padEnd(44, '='));
    printMethods.print(printVoucherData, commands, '');
    printMethods.print(printVoucherData, commands, '');
    printMethods.conditionalPrint(
      printVoucherData,
      commands,
      !isEmpty(retailAddress?.responsibleGambling),
      t(retailAddress?.responsibleGambling),
    );
    printMethods.conditionalPrint(
      printVoucherData,
      commands,
      !isEmpty(retailAddress?.responsibleGambling1),
      t(retailAddress?.responsibleGambling1),
    );
    printMethods.conditionalPrint(
      printVoucherData,
      commands,
      !isEmpty(retailAddress?.responsibleGambling2),
      t(retailAddress?.responsibleGambling2),
    );
    printMethods.conditionalPrint(
      printVoucherData,
      commands,
      !isEmpty(retailAddress?.responsibleGambling3),
      t(retailAddress?.responsibleGambling3),
    );
    printMethods.conditionalPrint(
      printVoucherData,
      commands,
      !isEmpty(
        retailAddress?.responsibleGambling3 ||
          retailAddress?.responsibleGambling2 ||
          retailAddress?.responsibleGambling1 ||
          retailAddress?.responsibleGambling,
      ),
      '',
    );
    printMethods.printEject(printVoucherData, commands);
    try {
      for (const i of printVoucherData) {
        await makeRequest(i);
        if (i.command === printEjectCommand) {
          await monitorPrintingCompletion();
        }
      }
    } catch (error) {
      getError.message(error);
    } finally {
      store.dispatch(setIsPrintingEjected(false));
      // Add a delay before allowing the next job to start
      await new Promise((resolve) => setTimeout(resolve, 2000));
      // Process the next job in the queue if any
      store.dispatch(processPrintQueue());
    }
  };

  store.dispatch(enqueuePrintRequest(printCashierPayoutVoucherJob));

  // Check the current printing state and process the queue if no job is currently running
  const state = store.getState();
  if (!state.retail.printQueue.isPrinting) {
    store.dispatch(processPrintQueue());
  }
};
export const printVoucherCashier = async ({
  amount,
  code,
  currency,
  issuedAt,
  validUntil,
  retailAddress,
  posAddress,
  posName,
  isTicketCodeLinear,
  isCurrencyBeforeAmount,
}: PrintVoucherCashier) => {
  const printVoucherCashierJob = async () => {
    const printVoucherData: CommandData[] = [];
    printMethods.printIsReady(printVoucherData, commands);
    printMethods.printStart(printVoucherData, commands);
    printMethods.print(printVoucherData, commands, '');
    printMethods.printOptions(printVoucherData, commands, _logoOptionsData);

    printMethods.printImage(printVoucherData, commands, retailAddress?.buLogo);
    printMethods.print(printVoucherData, commands, '');
    printMethods.printOptions(printVoucherData, commands, winbetOptions);
    printMethods.print(printVoucherData, commands, t('Cashier.Receipt.addressPOSText'));
    printMethods.print(
      printVoucherData,
      commands,
      `${posAddress?.city}, ${posAddress?.streetName} ${posAddress?.streetNumber}`,
    );
    printMethods.print(printVoucherData, commands, '');
    printMethods.print(printVoucherData, commands, t('Cashier.Receipt.signatureNumber'));
    printMethods.print(printVoucherData, commands, posName);
    printMethods.print(printVoucherData, commands, '');
    printMethods.conditionalPrint(
      printVoucherData,
      commands,
      window.config.ENV_TYPE !== 'PRODUCTION',
      t('Cashier.Receipt.testOperation'),
    );
    printMethods.printOptions(printVoucherData, commands, _optionsData);
    printMethods.print(printVoucherData, commands, '');
    printMethods.printOptions(printVoucherData, commands, winbetOptions);
    printMethods.print(printVoucherData, commands, ''.padEnd(44, '='));
    printMethods.print(printVoucherData, commands, '');
    printMethods.printOptions(printVoucherData, commands, _addressOptions);
    printMethods.print(printVoucherData, commands, t('Cashier.VoucherModal.voucherCode'));
    printMethods.print(printVoucherData, commands, '');
    printMethods.printOptions(printVoucherData, commands, voucherValueOptions);
    printMethods.print(printVoucherData, commands, code);
    printMethods.print(printVoucherData, commands, '');
    printMethods.printOptions(printVoucherData, commands, winbetOptions);
    printMethods.print(printVoucherData, commands, ''.padEnd(44, '='));
    printMethods.printOptions(printVoucherData, commands, voucherValueOptions);
    printMethods.print(printVoucherData, commands, '');
    printMethods.colDefine(printVoucherData, commands, 290, 'middle', 'left');
    printMethods.colDefine(printVoucherData, commands, 290, 'middle', 'right');
    printMethods.print(printVoucherData, commands, t('Cashier.VoucherModal.issueDate'));
    printMethods.print(printVoucherData, commands, retailFormatDate(issuedAt));
    printMethods.colReset(printVoucherData, commands);
    printMethods.print(printVoucherData, commands, '');
    printMethods.printOptions(printVoucherData, commands, winbetOptions);
    printMethods.print(printVoucherData, commands, ''.padEnd(44, '='));
    printMethods.printOptions(printVoucherData, commands, voucherValueOptions);
    printMethods.print(printVoucherData, commands, '');
    printMethods.colDefine(printVoucherData, commands, 290, 'middle', 'left');
    printMethods.colDefine(printVoucherData, commands, 290, 'middle', 'right');
    printMethods.print(printVoucherData, commands, t('Cashier.VoucherModal.validDate'));
    printMethods.print(printVoucherData, commands, retailFormatDate(validUntil));
    printMethods.colReset(printVoucherData, commands);
    printMethods.print(printVoucherData, commands, '');
    printMethods.printOptions(printVoucherData, commands, winbetOptions);
    printMethods.print(printVoucherData, commands, ''.padEnd(44, '='));
    printMethods.print(printVoucherData, commands, '');
    printMethods.printOptions(printVoucherData, commands, voucherValueOptions);
    printMethods.colDefine(printVoucherData, commands, 290, 'middle', 'left');
    printMethods.colDefine(printVoucherData, commands, 290, 'middle', 'right');
    printMethods.print(printVoucherData, commands, t('Cashier.CashPayModal.amount'));
    printMethods.print(printVoucherData, commands, formatCurrency(amount, currency, isCurrencyBeforeAmount) + ' ');
    printMethods.colReset(printVoucherData, commands);
    printMethods.print(printVoucherData, commands, '');
    printMethods.printOptions(printVoucherData, commands, eventOptions);
    printMethods.print(printVoucherData, commands, ''.padEnd(44, '='));
    printMethods.print(printVoucherData, commands, '');
    printMethods.printOptions(printVoucherData, commands, winbetAddress);
    printMethods.print(printVoucherData, commands, '');
    printMethods.print(printVoucherData, commands, '');
    printMethods.print(printVoucherData, commands, '');
    printMethods.printOptions(printVoucherData, commands, winbetAddress);
    printMethods.conditionalPrintBarcode(printVoucherData, commands, isTicketCodeLinear, 'V' + code);
    printMethods.print(printVoucherData, commands, '');
    printMethods.print(printVoucherData, commands, '');
    printMethods.printQrCode(printVoucherData, commands, 'V' + code);
    printMethods.print(printVoucherData, commands, '');
    printMethods.print(printVoucherData, commands, '');
    printMethods.print(printVoucherData, commands, '');
    printMethods.conditionalPrint(
      printVoucherData,
      commands,
      !isEmpty(retailAddress?.responsibleGambling),
      t(retailAddress?.responsibleGambling),
    );
    printMethods.conditionalPrint(
      printVoucherData,
      commands,
      !isEmpty(retailAddress?.responsibleGambling1),
      t(retailAddress?.responsibleGambling1),
    );
    printMethods.conditionalPrint(
      printVoucherData,
      commands,
      !isEmpty(retailAddress?.responsibleGambling2),
      t(retailAddress?.responsibleGambling2),
    );
    printMethods.conditionalPrint(
      printVoucherData,
      commands,
      !isEmpty(retailAddress?.responsibleGambling3),
      t(retailAddress?.responsibleGambling3),
    );
    printMethods.conditionalPrint(
      printVoucherData,
      commands,
      !isEmpty(
        retailAddress?.responsibleGambling3 ||
          retailAddress?.responsibleGambling2 ||
          retailAddress?.responsibleGambling1 ||
          retailAddress?.responsibleGambling,
      ),
      '',
    );
    printMethods.printEject(printVoucherData, commands);
    try {
      for (const i of printVoucherData) {
        await makeRequest(i);
        if (i.command === printEjectCommand) {
          await monitorPrintingCompletion();
        }
      }
    } catch (error) {
      getError.message(error);
    } finally {
      store.dispatch(setIsPrintingEjected(false));
      // Process the next job in the queue if any
      store.dispatch(processPrintQueue());
    }
  };

  store.dispatch(enqueuePrintRequest(printVoucherCashierJob));

  // Check the current printing state and process the queue if no job is currently running
  const state = store.getState();
  if (!state.retail.printQueue.isPrinting) {
    store.dispatch(processPrintQueue());
  }
};
