import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

/** Cashier Modal Tabs Wrapper Section */
interface TabProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  text?: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  isActive?: boolean;
}

const TabsWrapper: React.FC<TabProps> = ({ className, isActive, text, onClick }) => {
  const tabClass = clsx('cashier-modal__tab', isActive && 'cashier-modal__tab--active', className);

  return (
    <div className={tabClass} onClick={onClick}>
      {text}
    </div>
  );
};

export default TabsWrapper;
