import { ReactNode } from 'react';
import clsx from 'clsx';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { ScrollWrapper } from 'modules/casino/shared/features/casinoScroll';
import { arrowStyleTypes } from 'modules/casino/shared/features/casinoScroll/casinoScrollTypes';
import { jackpotsWidgetWrapperClassesByLayout } from '../../jackpotsWidgets/jackpotsWidgetConstants';

interface WrapperProps {
  children: ReactNode;
  categoryLayoutType: CategoryLayoutTypes;
  className?: string;
}

const WidgetWrapper: React.FC<WrapperProps> = ({ children, categoryLayoutType, className }) => {
  const widgetWrapperStyle = clsx(
    'bww__wrapper',
    jackpotsWidgetWrapperClassesByLayout[categoryLayoutType] || '',
    'slider-grid',
    className && className,
  );

  return (
    <div className={widgetWrapperStyle}>
      <ScrollWrapper typeArrow={arrowStyleTypes.CasinoGrid}>
        <div className={'generic-wrapper grid'}>{children}</div>
      </ScrollWrapper>
    </div>
  );
};

export default WidgetWrapper;
