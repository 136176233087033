export const loteriaPopularData = {
  containterId: 'lema-container',
  iframeId: 'lema-iframe',
  route: '/loteria-popular',
  refGameId: 'jb', // to fetch launch options, ex. api/gaming/public/providers/99/gameId/jb/launchUrl?isFreeplay=true
  providerAlias: 'lema',
  providerId: 114, // to fetch launch options
  providerName: 'lema',
  verticalAlias: 'loteriaPopular',
};
