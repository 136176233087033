import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Icon, Text } from 'components/shared';
import { StatusIcon } from 'components/shared/icons';
import { Flex } from 'components/shared/layout';
import { BetStatuses, MyBets } from '../../types/myBets.types';
import SelectionStatusIcon from '../BetSelectionUI/SelectionStatusIcon';
import { BetSlipSharedUI } from '../BetSlipSharedUI';
import { BonusIconsTypes } from '../BetSlipSharedUI/BonusIndicator.types';

interface SelectionProps {
  isRetail?: boolean;
  isInOverflow?: boolean;
  isLast: boolean;
  outcome: string;
  marketName: string;
  handleRemove?: () => void;
  showRemoveButton: boolean;
  selectionStatus?: MyBets.SelectionStatus;
  betStatus?: BetStatuses;
  isSuspended?: boolean;
  hasEarlyPayout?: boolean;
  hasBoreDraw?: boolean;
  hasGoalInsurance?: boolean;
  isEarlyPayoutPaid?: boolean;
  isBoreDrawPaid?: boolean;
  isGoalInsurancePaid?: boolean;
}

const Selection: React.FC<SelectionProps> = ({
  isRetail = false,
  isInOverflow,
  isLast,
  outcome,
  marketName,
  handleRemove,
  showRemoveButton,
  selectionStatus,
  betStatus,
  isSuspended,
  hasEarlyPayout,
  hasBoreDraw,
  isEarlyPayoutPaid,
  isBoreDrawPaid,
  hasGoalInsurance,
  isGoalInsurancePaid,
}) => {
  const { t } = useTranslation();

  const itemClass = clsx(
    'position-relative mw-0 bbldr-line',
    isInOverflow && 'd-none',
    isLast && 'bbldr-line--last',
    isSuspended && 'bbldr-line--suspended',
  );

  const textClass = clsx('mw-0 cursor-pointer bbldr-text', isSuspended && 'bbldr-text--suspended');
  const bbldrTextClass = clsx(isRetail ? 'bbldr-text--primary' : 'bbldr-text--secondary', 'text-truncate');

  return (
    <Flex.AC className={itemClass}>
      {selectionStatus && betStatus ? (
        <SelectionStatusIcon status={selectionStatus} betStatus={betStatus} />
      ) : (
        <StatusIcon size="xs" className="mr-2" status="none" />
      )}

      <Flex.ACJB className={'w-100 overflow-hidden'}>
        <Flex.Col className={textClass}>
          <Text className="text-truncate bbldr-text--primary" text={outcome} />
          <Flex className="align-items-baseline">
            <Text className={bbldrTextClass} text={marketName} />
            {hasEarlyPayout && (
              <BetSlipSharedUI.BonusIndicator
                className="ml-1"
                icon={BonusIconsTypes.EarlyPayout}
                text={isEarlyPayoutPaid ? t('Bonuses.EarlyPayout.paidOff') : ''}
              />
            )}
            {hasBoreDraw && (
              <BetSlipSharedUI.BonusIndicator
                className="ml-1"
                icon={BonusIconsTypes.BoreDraw}
                text={isBoreDrawPaid ? 'Bonuses.BoreDraw.paidOff' : ''}
              />
            )}
            {hasGoalInsurance && (
              <BetSlipSharedUI.BonusIndicator
                className="ml-1"
                icon={BonusIconsTypes.GoalInsurance}
                text={isGoalInsurancePaid ? 'Bonuses.GoalInsurance.paidOff' : ''}
              />
            )}
          </Flex>
        </Flex.Col>
        {showRemoveButton && (
          <Icon icon={'bin'} className="flex-shrink-0 ml-1 bbldr-line__remove" onClick={handleRemove} />
        )}
      </Flex.ACJB>
    </Flex.AC>
  );
};

export default Selection;
