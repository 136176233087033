import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { setAccountActiveNavTab } from 'pages/myAccount/slice/mpdAccountTabs.slice';
import { isSectionInvisible } from 'pages/myAccount/tabs/account/utils/account.utils';
import { General } from 'shared/common/features/general/types/generalSlice.types';
import { refreshWidget } from 'shared/common/features/integrations/cashierWidget/cashierWidget.slice';
import { showMyProfileModal } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppDispatch } from 'store';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import { LocationType } from 'utils/common/types/common.types';
import { myChallengesThunks } from './captainUpTab/slice/clubTab.slice';
import { notificationThunks } from './notificationsTab/notificationSlice';
import { fetchCategories } from './offersTab';
import { MPDUI } from '../../components/MPDUI';
import { accTabsWithBadge, logoutAction } from '../../utils/myProfile.utils';

type Props = {
  subMenu?: General.MyProfileRoutes[] | null;
  parentRef;
};

const NavigationTabs: React.FC<Props> = ({ subMenu }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation() as LocationType<{ previousRoute?: string }>;

  const handleAction = useCallback(
    (item: General.MyProfileRoutes) => () => {
      switch (item?.key) {
        case 'logout':
          logoutAction(dispatch);
          break;
        case 'bank':
          dispatch(refreshWidget());
          break;
        case 'offers':
          dispatch(setAccountActiveNavTab('OffersTab'));
          dispatch(fetchCategories());
          break;
        case 'notifications':
          dispatch(setAccountActiveNavTab('NotificationsTab'));
          dispatch(notificationThunks.fetchInboxNotification());
          break;
        case 'captainUp':
          dispatch(setAccountActiveNavTab('CaptainUp'));
          dispatch(myChallengesThunks.getItems());
          break;
      }

      if (item?.route) {
        dispatch(showMyProfileModal(false));
        navigate(item.route, {
          state: {
            from: `${location.pathname}${location.search}`,
            // TO DO check isExpanded for delete account not inRow
            isExpanded: item?.isExpanded,
            key: item?.key,
            previousRoute: location.state?.previousRoute,
          },
        });
      }
    },
    [location, dispatch, navigate],
  );

  const visibleSubMenu = subMenu?.filter((item) => !isSectionInvisible(item));
  const navigationInGrid = visibleSubMenu?.filter((item) => !item?.isInRow);
  const navigationInRow = visibleSubMenu?.filter((item) => item?.isInRow);
  return (
    <MPDUI.NavGrid columns={3}>
      {navigationInGrid?.map((item) => (
        <MPDUI.NavItem
          key={item.key}
          hasBadge={item?.hasBadge && accTabsWithBadge.includes(`${item?.key}`)}
          icon={item.icon || ''}
          iconColor={item?.iconColor}
          onClick={handleAction(item)}
          tabKey={item?.key}
          text={t(item.label)}
          isNew={item?.isNew}
          isNavGrid
          badgeNewText={t(MyProfile.ProfileTab.new)}
        />
      ))}
      {navigationInRow?.map((item) => (
        <MPDUI.NavItemRow
          key={item.key}
          onClick={handleAction(item)}
          dataQid={`${item.key}`}
          dataCpp={`${item.key}`}
          text={t(item.label)}
          icon={item.icon}
          iconColor={item.iconColor}
        />
      ))}
    </MPDUI.NavGrid>
  );
};

export default memo(NavigationTabs);
