import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Modal } from 'components/shared';
import { usePreloadImage } from 'hooks/common/usePreloadImage';
import userStorage from 'pages/auth/login/UserStorage';
import { useGamificationWidgetConfig } from 'shared/common/features/integrations/gamification/hooks/useGamificationWidgetConfig';
import { WsNotificationsTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/types/wsNotifications.types';
import { Breakpoints } from 'theme/Theme';
import { fitCover } from 'utils/common/cdnConfig';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import config from 'utils/config';
import { NotificationPopupUI } from '../../components/NotificationPopupUI';
import { useCloseModal } from '../../hooks/useCloseModal';
import { classNameUtils } from '../../utils/className.utils';

type Props = { activeNotification: WsNotificationsTypes.Notification };

const LottoWinNumber: React.FC<Props> = ({ activeNotification }) => {
  const { t } = useTranslation();
  const { itemByType } = useGamificationWidgetConfig('LOTTO');
  const [activeLanguage] = useState(userStorage.getUserLang());
  const btnLink = itemByType?.popupNavigationLink || activeNotification.Button?.buttonUrl;
  const { handleClose, handleInfo } = useCloseModal(activeNotification, btnLink);
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });
  const sprite = `${config.CDN_URL}/common-content/gamification/${activeNotification.Style.folder || 'cash-chance'}/popup/sprite.png`;
  const win = `${config.CDN_IMAGE_API}${fitCover(180 * 2, 'auto', 'contain')}/common-content/gamification/${activeNotification.Style.folder || 'cash-chance'}/symbols/${activeNotification.Message}.png`;
  const background = `${config.CDN_IMAGE_API}${fitCover(isDesktop ? 392 * 2 : 332 * 2, 'auto', 'contain')}/common-content/gamification/${activeNotification.Style.folder || 'cash-chance'}/popup/bgr-popup.png`;
  const { isImageLoaded, imageLoadError } = usePreloadImage(sprite, undefined, 0);
  const { isImageLoaded: backgroundLoaded, imageLoadError: backgroundError } = usePreloadImage(
    background,
    undefined,
    0,
  );

  return (
    <Modal visible dialogClassName="modal-dialog-centered">
      <AnimatePresence>
        {(backgroundLoaded || !!backgroundError) && (isImageLoaded || !!imageLoadError) && (
          <NotificationPopupUI show={true}>
            <NotificationPopupUI.header
              title={activeNotification?.Header?.[activeLanguage || 'bg'] ?? ''}
              handleClose={handleClose}
            />
            <NotificationPopupUI.body
              text={activeNotification?.Subheader?.[activeLanguage || 'bg']}
              style={{ backgroundImage: `url(${background})` }}
            >
              <div className={`${classNameUtils.modal}-lotto`}>
                {isImageLoaded && (
                  <>
                    <img
                      src={win}
                      alt={activeNotification?.Message || ''}
                      className={`${classNameUtils.modal}-lotto-win grid-start-col-1 grid-start-row-1 w-100 ${isImageLoaded || imageLoadError ? 'active' : ''}`}
                    />
                    <img
                      src={sprite}
                      className={`${classNameUtils.modal}-lotto-animate grid-start-col-1 grid-start-row-1 ${isImageLoaded ? 'active' : ''}`}
                    />
                  </>
                )}
              </div>
            </NotificationPopupUI.body>
            <NotificationPopupUI.footer
              infoText={`${t(Promotions.PrizeDrop.winBoxInfoLink)}`}
              btnText={t(Promotions.PrizeDrop.continueButton)}
              infoLinkText={activeNotification?.Header?.[activeLanguage || 'bg'] ?? ''}
              handleClose={handleClose}
              handleInfo={handleInfo}
              showInfo={!!btnLink}
            />
          </NotificationPopupUI>
        )}
      </AnimatePresence>
    </Modal>
  );
};

export default LottoWinNumber;
