import React, { HTMLAttributes } from 'react';
import { CSS } from 'utils/common/types/css.types';

/** InputStatus */
interface InputStatusProps extends HTMLAttributes<HTMLDivElement> {
  status?: Exclude<CSS.Variants, 'primary' | 'secondary' | 'danger'>;
  text?: string;
}

const InputStatus: React.FC<InputStatusProps> = ({ status, text, ...rest }) => {
  const statusTextClassProp = status
    ? { className: `egtd-x-input__status color--${status}` }
    : { className: 'egtd-x-input__status' };

  if (!text) return null;

  return (
    <div {...statusTextClassProp} {...rest}>
      {text}
    </div>
  );
};

InputStatus.displayName = 'EGTD Input Status';

export default InputStatus;
