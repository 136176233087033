import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import config from 'utils/config';
import {
  FinanceInformation,
  FinancialCommissionInfo,
  FinancialReportsThunk,
  FinancialReportsType,
} from '../type/financeReport.types';

const initialState: FinancialReportsType = {
  financeInfo: null,
  financeStatus: 'idle',
  financeInfoError: '',
  isFinanceButton: false,
  financialCommissionInfo: null,
  financialCommissionInfoStatus: 'idle',
};

export const financialReportsThunk: FinancialReportsThunk = {
  financeCashier: createAbortThunk(
    `cashier/financeCashier`,
    async ({ dateFrom, dateTo, dateFromTimestamp, dateToTimestamp }) => {
      const url = `${config.API_URL}/api/pretail/agent/v2/pos/get-tickets-report?DateFrom=${dateFrom}&DateTo=${dateTo}&dateFromTimestamp=${dateFromTimestamp}&dateToTimestamp=${dateToTimestamp}`;
      const response = await axiosInstance.get(url);
      return response.data;
    },
  ),
  fetchFinancialCommission: createAbortThunk(
    `cashier/financialComission`,
    async ({ dateFrom, dateTo, dateFromTimestamp, dateToTimestamp }) => {
      const url = `${config.API_URL}/api/pretail/agent/v2/pos/get-commission-overview-report?DateFrom=${dateFrom}&DateTo=${dateTo}&dateFromTimestamp=${dateFromTimestamp}&dateToTimestamp=${dateToTimestamp}`;
      const response = await axiosInstance.get(url);
      return response.data;
    },
  ),
};

const financialReportSlice = createSlice({
  name: 'financialReportSlice',
  initialState,
  reducers: {
    resetFinanceStatus(state) {
      state.financeStatus = 'idle';
      state.financeInfo = null;
    },
    clearFinancialInfoCommission: (state) => {
      state.financialCommissionInfo = null;
      state.financialCommissionInfoStatus = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      financialReportsThunk.financeCashier.fulfilled,
      (state, action: PayloadAction<FinanceInformation>) => {
        state.financeInfo = action.payload;
        state.financeStatus = 'succeeded';
      },
    );
    builder.addCase(financialReportsThunk.financeCashier.pending, (state) => {
      state.financeStatus = 'pending';
    });
    builder.addCase(financialReportsThunk.financeCashier.rejected, (state, action) => {
      state.financeInfoError = action.payload;
      state.financeStatus = 'failure';
      state.financeInfo = null;
    });
    builder.addCase(
      financialReportsThunk.fetchFinancialCommission.fulfilled,
      (state, action: PayloadAction<FinancialCommissionInfo | FinanceInformation>) => {
        state.financialCommissionInfo = action.payload;
        state.financialCommissionInfoStatus = 'succeeded';
      },
    );
    builder.addCase(financialReportsThunk.fetchFinancialCommission.rejected, (state, action) => {
      state.financialCommissionInfo = null;
      state.financialCommissionInfoStatus = 'failure';
      state.financeInfoError = action.payload;
    });
    builder.addCase(financialReportsThunk.fetchFinancialCommission.pending, (state) => {
      state.financialCommissionInfoStatus = 'pending';
    });
  },
});
export const { resetFinanceStatus, clearFinancialInfoCommission } = financialReportSlice.actions;
export default financialReportSlice.reducer;
export const selectFinancialInfo = {
  financialCommissionInfo: (state: RootState) => state.retail.financialReports.financialCommissionInfo,
  financialCommissionInfoStatus: (state: RootState) => state.retail.financialReports.financialCommissionInfoStatus,
  isFinanceButton: (state: RootState) => state.retail.financialReports.isFinanceButton,
  financeInfo: (state: RootState): FinanceInformation | null => state.retail.financialReports.financeInfo,
  financeInfoError: (state: RootState) => state.retail.financialReports.financeInfoError,
  financeStatus: (state: RootState): string => state.retail.financialReports.financeStatus,
};
