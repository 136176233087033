import { useCallback } from 'react';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import {
  GameLauncherTopBarButtonTypes,
  LayoutConfigTypes,
} from 'shared/common/features/general/types/generalSlice.types';

export const useTopBarButtonsConfig = () => {
  const configTopBarButtons = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.GAME_LAUNCH_TOP_BAR,
  });

  const getButtonEnabled = useCallback(
    (type: GameLauncherTopBarButtonTypes) => {
      for (const section in configTopBarButtons) {
        if (section !== 'scrollable' && section !== 'pinned') continue;
        if (configTopBarButtons[section]?.some((button) => button.type === type && button.enabled)) {
          return true;
        }
      }
      return false;
    },
    [configTopBarButtons],
  );

  return { getButtonEnabled, configTopBarButtons };
};
