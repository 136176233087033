import React from 'react';

type Props = {
  badgeText: string;
  badgeStatus: string;
};

const PrizeDropBadge: React.FC<Props> = ({ badgeText, badgeStatus }) => {
  return <egtd-przd-badge data-badge-state={`${badgeStatus}`}>{badgeText}</egtd-przd-badge>;
};

export default PrizeDropBadge;
