import { memo, useCallback } from 'react';
import clsx from 'clsx';
import { Link, useLocation, useMatch } from 'react-router-dom';
import { Text } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { navigateToIntegration } from 'shared/common/features/integrations/utils/integrations.utils';
import { isActive } from 'utils/common/utilsNav';
import { useRouteToInPlay } from '../../../../../hooks/useRouteToInPlay';

type Props<T> = {
  item: T; // can be of type Vertical or Subvertical
};

const RetailHeaderMenuLink = <
  T extends {
    isExternalRoute?: boolean;
    name: string;
    route: string;
    ribbonConfig?: { isNew?: boolean; labelColor?: string; textColor?: string };
    onClick?: () => void;
  },
>({
  item,
}: Props<T>) => {
  const routeToInPlay = useRouteToInPlay(item.route);
  const route = routeToInPlay || item.route;
  const location = useLocation();
  const sportsMatch = useMatch(route ? `${route}/:params/*` : route);

  const handleNavClick = useCallback(() => {
    navigateToIntegration({ fullPath: route });
    item.onClick?.();
  }, [item, route]);

  const isActiveItem = isActive(route, sportsMatch, location) || routeToInPlay;
  const linkStyles = clsx('rnav__item', isActiveItem && 'rnav__item--active');

  if (item.isExternalRoute) {
    return (
      <Flex.AC className={linkStyles}>
        {/* {isActiveItem && <motion.div className={'rnav__item--frame'} layoutId="underline" />} */}
        <a href={route} target="_blank" className="rnav__item-link " rel="noreferrer">
          {item?.name}
        </a>
      </Flex.AC>
    );
  }

  return (
    <>
      <Flex.AC className={linkStyles}>
        {/* {isActiveItem && <motion.div className={'rnav__item--frame'} layoutId="underline" />} */}
        {route !== '/' && route !== '' ? (
          <Link to={route} onClick={handleNavClick} className="rnav__item-link">
            {item?.name}
          </Link>
        ) : (
          <Text className="rnav__item-link" text={item?.name} onClick={item.onClick} />
        )}
      </Flex.AC>
    </>
  );
};

export default memo(RetailHeaderMenuLink);
