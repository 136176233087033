import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import CTAUI from 'shared/common/features/myProfile/components/CTAUI';
import { useAppDispatch } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import useTransitionTabs from '../../../hooks/useTransitionTabs';
import { readOffers, setActiveCategoryTitle } from '../../slice/offersTab.slice';
import { Category, OffersTabLayout } from '../../types/offersTab.types';
import BonusCategory from '../BonusCategory';

type Props = {
  categories: Category[];
  activeCategory?: Category;
  layout: OffersTabLayout;
  hideCategoryBonuses?: (e: React.MouseEvent<Element, MouseEvent>) => void;
};

export const OffersTabLayout01: React.FC<Props> = ({ categories, activeCategory, hideCategoryBonuses, layout }) => {
  const dispatch = useAppDispatch();
  const { initialOpacity, controls } = useTransitionTabs(categories);

  useEffect(() => {
    if (activeCategory && activeCategory?.verticalUnseenCounter > 0) {
      const bonusIds = activeCategory?.promotions
        ?.map((promotion) => promotion?.bonuses?.filter((bonus) => bonus.isUnseen)?.map((bonus) => bonus?.id))
        .flat();

      if (!isEmpty(bonusIds)) {
        dispatch(readOffers(activeCategory?.alias, bonusIds));
      }
    }
  }, [dispatch, activeCategory]);

  return (
    <>
      {categories?.map((category, idx) =>
        activeCategory ? null : (
          <motion.div
            className="mpd-cta-header__wrapper"
            initial={{ opacity: initialOpacity }}
            key={category?.alias}
            custom={idx}
            animate={controls}
          >
            <CTAUI.Header
              dataQid={`mpd-offers-cat-${category.alias}`}
              onClick={() => dispatch(setActiveCategoryTitle(category.alias))}
              icon={category.resourceName || ''}
              title={category.title}
              onBackClick={hideCategoryBonuses}
              badgeCount={category.verticalUnseenCounter}
              isRead={category.verticalUnseenCounter <= 0}
            />
            {activeCategory && category?.promotions && (
              <BonusCategory promotions={category?.promotions} layout={layout} />
            )}
          </motion.div>
        ),
      )}
    </>
  );
};
