import { CreateThunk } from 'modules/casino/store/thunkCreators';
import { RequestStatuses } from '../../constants';
import { Casino } from '../../types';

export declare module GridLayoutSettingsTypes {
  interface State extends GridSettings, Casino.CacheTTL {
    fetchStatus: RequestStatuses;
  }

  type GridSettings = {
    showGameCellFavButton: boolean;
    gridAnimationClass: string;
    hoverAnimationClass: string;
    enableColorIcons: boolean;
    enableFilterByProvider: boolean | null;
    isMenuGamesCount: boolean;
    menuNavigationLayout: NavigationLayout | null;
    providerNavigationLayout: NavigationLayout | null;
    providerSliderLayout: ProviderSliderLayout;
    fetchStatus: RequestStatuses;
  };

  type Response = {
    gridLayoutSettings: GridLayoutResponse;
    navigationSettings: NavigationResponse;
  };

  type GridLayoutResponse = {
    showGameCellFavButton: boolean;
    gridAnimationClass: string;
    hoverAnimationClass: string;
  };

  export type NavigationResponse = {
    enableColorIcons: boolean;
    enableFilterByProvider: boolean | null;
    menuGamesCount: boolean;
    menuNavigationLayout: NavigationLayout | null;
    providerNavigationLayout: NavigationLayout | null;
    providerSliderLayout: ProviderSliderLayout;
  };

  type Thunks = {
    fetchGridLayoutSettings: CreateThunk<void, Response, string | null>;
  };
}

export enum NavigationLayout {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum ProviderSliderLayout {
  PROMO_SLIDER = 'promoSlider',
  SLIDER_WITH_ICONS = 'sliderWithIcons',
  NO_SLIDER = 'noSlider',
}
