import React, { memo } from 'react';
import clsx from 'clsx';
import { Icon } from 'components/shared';

type Props = {
  show?: boolean;
  handleClick?: () => void;
  isOpenTopNav?: boolean;
};

const ShowHideArrow: React.FC<Props> = ({ show = false, isOpenTopNav, handleClick }) => {
  const wrapperClasses = clsx(`top-navbar--${show ? 'show' : 'hide'} d-flex-center`);
  const iconClasses = clsx(`top-navbar--${show ? 'show' : 'hide'}-icon`, (!show || isOpenTopNav) && 'rotated-180');

  return (
    <div className={wrapperClasses} onClick={handleClick}>
      <Icon icon={'chevron'} className={iconClasses} />
    </div>
  );
};

export default memo(ShowHideArrow);
