import React, { memo } from 'react';
import CategoryBreadcrumbLayout from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/CategoryBreadcrumbLayout';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { Desktop, DesktopDown } from 'modules/casino/theme/Theme';
import FavouritesGridWrapper from './components/FavouritesGridWrapper';
import { useCasinoFavouritesCategory } from './hooks/useCasinoFavouritesCategory';

export interface Props {
  categoryId: string;
}

const CasinoFavouritesCategory: React.FC<Props> = ({ categoryId }) => {
  const { isLoading, hasSuccess, hasNoGames, gamesCount, handleBackBtnClick } = useCasinoFavouritesCategory({
    categoryId,
  });
  return (
    <>
      <DesktopDown>
        <CategoryBreadcrumbLayout categoryId={categoryId} type={CategoryLayoutTypes.FAVOURITES_CATEGORY} />
      </DesktopDown>
      <div className="category-single">
        <div className={`${cssGlobalNS}-container inner-category`}>
          <Desktop>
            <CategoryBreadcrumbLayout categoryId={categoryId} type={CategoryLayoutTypes.FAVOURITES_CATEGORY} />
          </Desktop>
          <FavouritesGridWrapper
            categoryId={categoryId}
            categoryLayout={CategoryLayoutTypes.FAVOURITES_CATEGORY}
            subType={CategorySubTypes.STANDARD}
            gamesCount={gamesCount}
            isLoading={isLoading}
            hasSuccess={hasSuccess}
            hasNoGames={hasNoGames}
            handleBackBtnClick={handleBackBtnClick}
          />
        </div>
      </div>
    </>
  );
};
export default memo(CasinoFavouritesCategory);
