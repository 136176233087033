import { useWsEffect } from 'hooks/ws/useWsEffect';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { myProfileThunks } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppDispatch, useAppSelector } from 'store';

export const FetchUserBalance = (): null => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);

  useWsEffect(() => {
    if (isAuthenticated) {
      const fetchBalance = dispatch(myProfileThunks.fetchBalance());

      return () => {
        fetchBalance.abort();
      };
    }
  }, [isAuthenticated]);

  return null;
};
