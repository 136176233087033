import React from 'react';
import { StyledMarker } from './Marker.styled';

interface MarkerProps {
  bgImage: string;
}

const Marker: React.FC<MarkerProps> = ({ bgImage }) => {
  return <StyledMarker className={'grid-start-1x1 bg-contain'} style={{ backgroundImage: `url(${bgImage})` }} />;
};

export default Marker;
