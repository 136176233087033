const range = (start: number, end: number, length = end - start + 1) => Array.from({ length }, (_, i) => start + i);

export const PERIOD_START_NUMBER = 1;
export const DAYS_IN_MONTH = 31;
export const MONTHS_IN_YEAR = 12;
export const MIN_YEAR = 1920;
export const LAWFUL_AGE = 18;
const MAX_ALLOWED_YEAR = new Date().getFullYear() - LAWFUL_AGE;

const Days = range(PERIOD_START_NUMBER, DAYS_IN_MONTH).map((day: number) => {
  return {
    id: day,
    name: day,
    value: day,
  };
});

const Months = range(PERIOD_START_NUMBER, MONTHS_IN_YEAR).map((month: number) => {
  return {
    id: month,
    name: month,
    value: month,
  };
});

const Years = range(MIN_YEAR, MAX_ALLOWED_YEAR).map((year: number) => {
  return {
    id: year,
    name: year,
    value: year,
  };
});

export { Days, Months, Years };
