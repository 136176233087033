import React from 'react';
import clsx from 'clsx';
import { Text } from 'components/shared';
import { InputModeType } from 'components/shared/forms/formTypes';
import { Flex } from 'components/shared/layout';
import { useOnOff } from 'hooks/common/useOnOff';

interface Props {
  value?: string;
  labelSpecifier?: string;
  placeholder: string;
  prependText?: string;
  error?: string;
  name: string;
  inputMode?: InputModeType;
  type?: string;
  minLength?: number;
  maxLength?: number;
  inputValue?: string;
  required?: boolean;
  autoComplete?: string;
  readOnly?: boolean;
  onChange?: (inputValue: string) => void;
  handleFocus?: () => void;
  onFocusOut?: () => void;
}

const TerminalInputGroup: React.FC<Props> = ({
  value,
  labelSpecifier,
  placeholder,
  prependText,
  error,
  name,
  inputMode,
  type,
  minLength,
  maxLength,
  inputValue,
  required,
  autoComplete,
  readOnly,
  handleFocus,
  onFocusOut,
  onChange,
}) => {
  const [inputActive, setInputActive] = useOnOff();

  const handleInput = () => {
    setInputActive(!inputActive);
    handleFocus && handleFocus();
  };

  const handleFocusOut = () => {
    setInputActive(!inputActive);
    onFocusOut && onFocusOut();
  };

  const inputGroupClass = clsx('terminal-input-group', readOnly && 'terminal-input-group--readonly');
  const inputPrependClass = clsx(
    'terminal-input-group__prepend',
    (value || inputValue) && 'terminal-input-group__prepend--active',
    error && 'terminal-input-group__prepend--error',
  );
  const inputClass = clsx(
    'terminal-form-control',
    (value || inputValue) && 'terminal-form-control--active',
    error && 'terminal-form-control--error',
    readOnly && 'terminal-form-control--readonly',
  );

  const handleInputChange = (e) => {
    onChange && onChange(e.target.value);
  };

  return (
    <>
      <Flex className={inputGroupClass}>
        {prependText && (
          <Flex.AC className={inputPrependClass}>{prependText && <Text text={prependText}></Text>}</Flex.AC>
        )}
        <input
          className={inputClass}
          placeholder={inputValue ? inputValue : placeholder}
          defaultValue={value || inputValue}
          onFocus={handleInput}
          onBlur={handleFocusOut}
          onChange={handleInputChange}
          name={name}
          inputMode={inputMode}
          type={type}
          maxLength={maxLength}
          minLength={minLength}
          required={required}
          autoComplete={autoComplete}
          readOnly={readOnly}
        />
        <span></span>
      </Flex>
      {labelSpecifier && <Text className="terminal-input-group__specifier" text={labelSpecifier} />}
    </>
  );
};

export default React.memo(TerminalInputGroup);
