import { getTagLogo } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/gameCellTagsUtils';
import config from 'utils/config';
import { CasinoWidgetsTypes } from '../../../../types/casinoWidgetsTypes';

interface Props {
  lastWinner: CasinoWidgetsTypes.LastWinner;
}

const TagLogo: React.FC<Props> = ({ lastWinner }) => {
  const tagLogo = getTagLogo(lastWinner?.tags);
  const logo = tagLogo && `${config.CDN_URL}/${tagLogo?.logo}`;

  return <img className="cw__game-image--logo" src={logo} />;
};
TagLogo.displayName = 'TagLogo';
export default TagLogo;
