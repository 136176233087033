import React from 'react';
import { Flex } from 'modules/casino/shared/components/layout';
import JackpotLevels from './JackpotLevels';
import { JackpotTypes } from '../jackpotTypes';

interface JackpotSectionProps {
  jackpot: JackpotTypes.JackpotCategoryDetails;
  jackpotId: string;
  fromWidget?: boolean;
  fromBanner?: boolean;
}

const JackpotLevelsWrapper: React.FC<JackpotSectionProps> = ({ jackpot, jackpotId, fromWidget, fromBanner }) => {
  return (
    <Flex.ACJA className="jackpot">
      <JackpotLevels
        jackpotId={jackpotId}
        levels={jackpot.levels}
        gameProviderId={jackpot.gameProviderId}
        fromWidget={fromWidget}
        fromBanner={fromBanner}
      />
    </Flex.ACJA>
  );
};

export default JackpotLevelsWrapper;
