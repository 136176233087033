import { TFunction } from 'i18n';
import {
  _addressOptions,
  _logoOptionsData,
  _optionsData,
} from 'modules/retail/shared/printMethods/printUtils/printReceiptOptions';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { AppDispatch, store } from 'store';
import { isTerminal, toFixed } from 'utils/common/helpersCommon';
import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';
import { resetAnexInfoState } from './features/anex2Report/slice/anexReport.slice';
import { clearFinancialInfoCommission, resetFinanceStatus } from './features/finance/slice/financialReports.slice';
import {
  resetReprintTicketSlice,
  resetSearchFiltersForNexStepPagination,
} from './features/reprintTicket/slice/reprintTicket.slice';

import {
  clearGetInfoPlayer,
  clearPlayerInfoErrorMessage,
  clearSaveInfoPlayer,
} from './features/UCNForm/slice/playerInfo.slice';
import { resetCashierCancelTicketStatus, resetPayoutTicketStatus } from './slice/cashier.slice';

import { resetTicketInfoState, ticketInfoThunk } from '../../shared/slice/checkTicket.slice';

export const checkTicketStatus = (
  status,
  t: TFunction,
  totalWin,
  currency,
  isClaimForm,
  totalAmountAfterTax,
  isCurrencyBeforeAmount,
) => {
  const isMagnumBet = store.getState()?.common?.general?.layoutConfig?.['platform']?.general?.isMagnumBetRo;
  const totalWinAmount = totalAmountAfterTax !== 0 ? totalAmountAfterTax : totalWin;
  const currencySettings = store.getState()?.common?.general?.currencySettings;

  const currencySymbol = currencySettings?.find((c) => c.name === currency)?.nameTranslations || currency;
  if (status === 'PAID') {
    return t(CashierTranslations.Status.paidStatus);
  } else if (status === 'CANCELLED') {
    return t(CashierTranslations.Status.cancelledStatus);
  } else if (status === 'WON') {
    return isClaimForm
      ? t(CashierTranslations.Status.winStatus) +
          ' ' +
          toFixed(totalWin, 2) +
          ' ' +
          currency +
          '/' +
          t('Cashier.CashierModal.paymentByBank')
      : isMagnumBet && isTerminal()
        ? t(CashierTranslations.Status.winStatus) +
          ' ' +
          toFixed(totalAmountAfterTax, 2) +
          ' ' +
          currency +
          ` ${t('SSBT.CheckTicketModal.addToBalance')}`
        : t(CashierTranslations.Status.winStatus) +
          ' ' +
          (isCurrencyBeforeAmount
            ? `${currencySymbol} ${formatAmountToBRL(totalWinAmount)}`
            : `${toFixed(totalWinAmount, 2)} ${currency}`);
  } else if (status === 'ACCEPTED') {
    return t(CashierTranslations.Status.acceptedStatus);
  } else if (status === 'LOSS') {
    return t(CashierTranslations.Status.lostStatus);
  } else if (status === 'BANKPAYMENTPROCESSING') {
    return t('Cashier.CashierModal.bankProcessing');
  } else if (status === 'VOID') {
    if (isMagnumBet) {
      return (
        t('Cashier.CashierModal.voidText') +
        ' ' +
        toFixed(totalWin, 2) +
        ' ' +
        currency +
        ` ${t('SSBT.CheckTicketModal.addToBalance')}`
      );
    } else {
      return t('Cashier.CashierModal.voidText');
    }
  } else if (status === 'PENDING_PAYMENT') {
    return t('Cashier.CashierModal.pendingResult');
  } else if (status === 'EXPIRED') {
    return t('Cashier.CashierModal.expired');
  } else if (status === 'BANKEXPIRED') {
    return t('Cashier.CashierModal.bankExpired');
  } else {
    return '';
  }
};

export const clearAllData = (dispatch: AppDispatch, closeModal: () => void, reset: () => void) => {
  dispatch(resetTicketInfoState());
  dispatch(resetCashierCancelTicketStatus());
  dispatch(resetPayoutTicketStatus());
  dispatch(clearSaveInfoPlayer());
  dispatch(clearGetInfoPlayer());
  dispatch(clearPlayerInfoErrorMessage());

  reset();
  closeModal();
};

export const clearAnexData = (dispatch: AppDispatch, closeModal: () => void) => {
  dispatch(resetAnexInfoState());
  closeModal();
};

export const clearFinanceData = (dispatch: AppDispatch, closeModal: () => void) => {
  dispatch(resetFinanceStatus());
  dispatch(clearFinancialInfoCommission());

  closeModal();
};

export const clearRePrintData = (dispatch: AppDispatch) => {
  dispatch(resetReprintTicketSlice());
  dispatch(resetSearchFiltersForNexStepPagination());

  dispatch(hideRootModal());
};
export const clearAllReportData = (dispatch: AppDispatch, closeModal: () => void) => {
  clearAnexData(dispatch, closeModal);
  clearFinanceData(dispatch, closeModal);
  clearRePrintData(dispatch);
};
export const handleCheckTicket = (dispatch: AppDispatch, inputValue: string) => {
  dispatch(ticketInfoThunk.checkTicket({ ticketId: inputValue }));
};

export const loadNewTicket = (
  setInputValue: (value: string) => void,
  dispatch: AppDispatch,
  setClickCount,
  setActiveButton,
  setSeconds,
) => {
  setInputValue('');
  setClickCount(0);
  setSeconds(0);
  setActiveButton([]);

  dispatch(resetTicketInfoState());
  dispatch(resetCashierCancelTicketStatus());
  dispatch(resetPayoutTicketStatus());
  dispatch(clearSaveInfoPlayer());
  dispatch(clearGetInfoPlayer());
  dispatch(clearPlayerInfoErrorMessage());
};

export const padNumberWithZeros = (num, length) => {
  return num.toString().padStart(length, '0');
};

export function formatAmountToBRL(value) {
  const stringValue = typeof value === 'number' ? value.toFixed(2) : value;

  return `${stringValue
    .replace('.', ',')
    .replace(' ', '.')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
}

export const formatCurrency = (amount: number, currency?: string, isCurrencyBeforeAmount?: boolean) => {
  const currencySettings = store.getState()?.common?.general?.currencySettings;
  const currencySymbol = currencySettings?.find((c) => c.name === currency)?.nameTranslations || currency;
  const formattedAmount = amount.toFixed(2);
  return isCurrencyBeforeAmount
    ? `${currencySymbol} ${formatAmountToBRL(formattedAmount)}`
    : `${formattedAmount} ${currency}`;
};
