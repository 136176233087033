export declare module BottomTabBarTypes {
  type BottomTabBar = {
    isMyBetsVisible: boolean;
    isBetSlipExpanded: boolean;
    isBottomTabBarVisible: boolean;
    isCheckTicketModalVisible: boolean;
    unsettledBetsCount: number;
    bottomTabBarHeight: number;
    tabs: TabBarType[];
  };

  type TabBarType = {
    icon: string;
    iconColor?: string;
    type: BarTypes;
    route: string | null;
    translation: string;
    titleTranslations: string;
    ranking: number;
    ribbonConfig?: RibbonConfig;
    backgroundImage?: string | null;
    backgroundColor?: string | null;
  };
  type RibbonConfig = {
    isNew?: boolean;
    labelColor?: string;
    textColor?: string;
  };

  type BarTypes =
    | 'casino'
    | 'sports'
    | 'inPlay'
    | 'mybets'
    | 'checkTicket'
    | 'claimTicket'
    | 'promotions'
    | 'sportSearch'
    | 'casinoSearch'
    | 'sportFavourites'
    | 'casinoFavourites'
    | 'specialTournament';
}

export const TAB_TYPE: Record<string, BottomTabBarTypes.BarTypes> = {
  CASINO: 'casino',
  SPORTS: 'sports',
  INPLAY: 'inPlay',
  MYBETS: 'mybets',
  CHECK_TICKET: 'checkTicket',
  CLAIM_TICKET: 'claimTicket',
  PROMOTIONS: 'promotions',
  SPORT_SEARCH: 'sportSearch',
  CASINO_SEARCH: 'casinoSearch',
  SPORT_FAVOURITES: 'sportFavourites',
  CASINO_FAVOURITES: 'casinoFavourites',
};
