import React from 'react';
import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import { GameType } from 'modules/casino/shared/constants';
import { isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import { GridLayouts } from 'utils/common/types/casino.types';
import { GameMediaUI } from './GameMediaUI';
import { useGameImage } from '../hooks/useGameImage';

interface Props {
  gameUniqueId: string;
  categoryId: string;
  gameMediaRef: React.MutableRefObject<HTMLDivElement | null>;
  skipLazyLoad: boolean;
  finishedLoading: () => void;
  layoutGrid: GridLayouts;
}

export const GameImage: React.FC<Props> = ({
  gameUniqueId,
  categoryId,
  gameMediaRef,
  skipLazyLoad,
  finishedLoading,
  layoutGrid,
}) => {
  const { liveImageLocation, gameType } = useGameProps({ gameUniqueId, categoryId });
  const { imageSources } = useGameImage({ gameUniqueId, categoryId, gameMediaRef, layoutGrid });

  const renderImageComponent = () => {
    return liveImageLocation ? (
      <GameMediaUI.Image
        withLiveImage={gameType !== GameType.SVARA}
        className="live-cell__image"
        srcList={imageSources}
        onLoad={finishedLoading}
        uniqueId={gameUniqueId}
        skipLazyLoad={skipLazyLoad}
      />
    ) : (
      <GameMediaUI.Image
        className="casino-game-cell__image"
        srcList={imageSources}
        onLoad={finishedLoading}
        uniqueId={gameUniqueId}
        skipLazyLoad={skipLazyLoad}
        width={gameMediaRef.current?.clientWidth}
        height={gameMediaRef.current?.clientHeight}
      />
    );
  };

  return isEmpty(imageSources) ? <></> : renderImageComponent();
};

GameImage.displayName = 'GameImage';
