import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from 'theme/Theme';
import { fitCover } from 'utils/common/cdnConfig';
import config from 'utils/config';
import { AppHeaderUI } from '../../../AppHeaderUI';

type Props = {
  isMyProfile?: boolean;
  disableBadge?: boolean;
  sx?: boolean;
  tickets?: number;
  onClick?: () => void;
  isAuthenticated: boolean;
  icon?: string;
  iconMyProfile?: string;
};

type GenerateImageUrlType = {
  size: number;
  icon: string | undefined;
};
const generateImageUrl = ({ icon, size }: GenerateImageUrlType) =>
  icon ? `${config.CDN_IMAGE_API}${fitCover(size, size)}/${icon}` : '';

export const MainNavbarButtonsCompact: React.FC<Props> = ({
  icon,
  iconMyProfile,
  disableBadge,
  tickets,
  onClick,
  isAuthenticated,
  sx,
  isMyProfile,
}) => {
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });

  const defaultSize = sx ? 24 : isMyProfile ? 34 : isDesktop ? 42 : 34;
  const imageSize = defaultSize * 2;
  const imageUrl = generateImageUrl({ icon: !isMyProfile ? icon : iconMyProfile, size: imageSize });
  const showBadge = !disableBadge && isAuthenticated;
  return (
    <div
      className={`d-flex-ac position-relative ${!isMyProfile && isDesktop && showBadge && 'mr-2'}`}
      style={{ maxHeight: defaultSize + 'px', alignSelf: 'center' }}
      onClick={onClick}
    >
      {imageUrl && (
        <img
          className="cursor-pointer"
          width={defaultSize}
          height={defaultSize}
          src={imageUrl}
          alt="Bonus Collection"
          data-cy="bonus-collection-header"
        />
      )}
      {showBadge && (
        <AppHeaderUI.Badge
          isVisible={true}
          isActive={false}
          text={String(tickets)}
          className="nav-user__badge--tombola"
        />
      )}
    </div>
  );
};
