import React from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { Icon } from 'components/shared';
import { PromotionTypes } from 'pages/promotions/types/promotions.types';
import { Breakpoints } from 'theme/Theme';
import { StyledPageCTA, StyledPageCTABackButton, StyledPageCTASubTitle, StyledPageCTATitle } from './PageCTA.styled';
import CTAButton from '../../components/PromoUI/CTAButton';

interface CTAProps {
  cta?: PromotionTypes.Button;
  onCtaClick?: React.MouseEventHandler;
  onBackClick: React.MouseEventHandler;
  hideButtons?: boolean;
}

const PageCTA: React.FC<CTAProps> = ({ cta, onCtaClick, onBackClick, hideButtons }) => {
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });

  const blockClass = clsx(
    'grid-start-1x1 py-4 px-2 prm-cta__details',
    isDesktop ? 'my-auto ml-50pct' : 'mt-auto text-center',
  );

  const ctaButtonStyle = { color: cta?.buttonTextColor || '', backgroundColor: cta?.buttonTextBackground || '' };

  return (
    <StyledPageCTA>
      {!hideButtons && (
        <StyledPageCTABackButton style={{ fill: cta?.subTitleBackground }} onClick={onBackClick}>
          <Icon icon={'arrow'} />
        </StyledPageCTABackButton>
      )}

      <motion.div className={blockClass} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
        {cta?.title && <StyledPageCTATitle style={{ color: cta?.titleTextColor }}>{cta?.title}</StyledPageCTATitle>}
        {cta?.subTitle && (
          <StyledPageCTASubTitle style={{ color: cta?.subTitleTextColor }}>{cta?.subTitle}</StyledPageCTASubTitle>
        )}
        {!hideButtons && cta?.buttonText && onCtaClick && (
          <CTAButton
            style={ctaButtonStyle}
            size={isDesktop ? 'xl' : 'lg'}
            text={cta?.buttonText}
            onClick={onCtaClick}
          />
        )}
      </motion.div>
    </StyledPageCTA>
  );
};

export default PageCTA;
