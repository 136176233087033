import React, { useRef, useCallback, useEffect, useState } from 'react';
import { DropdownOptionType, InputLocationType, RefType } from 'components/shared/forms/formTypes';
import { useOnOff } from 'hooks/common/useOnOff';
import { useOutsideClick } from 'hooks/common/useOutsideClick';
import { fetchMyAccountDropdownsData } from 'pages/myAccount/tabs/account/slice/account.slice';
import FormSelectUI from './FormSelectUI';

interface Props {
  componentLocation?: InputLocationType;
  options?: DropdownOptionType[];
  onChange;
  type?: string;
  name?: string;
  placeholder?: string;
  label?: string;
  hasLabelSecondary?: boolean;
  labelSecondary?: string;
  classNames?: string;
  disabled?: boolean;
  value?: string;
  controlRef?: RefType;
  onBlur?: (e?) => void;
  isControlled?: boolean;
  highlightSelected?: boolean;
  isOutlined?: boolean;
  formInfoMessage?: React.ReactNode;
  apiUrl?: string;
  preselectValue?: string;
}

const FormDropdown: React.FC<Props> = ({
  componentLocation,
  options,
  type,
  name,
  placeholder,
  onChange,
  label,
  hasLabelSecondary,
  labelSecondary,
  classNames,
  disabled,
  value,
  controlRef,
  onBlur,
  isControlled,
  highlightSelected,
  isOutlined,
  formInfoMessage,
  apiUrl,
  preselectValue,
}) => {
  const dropdown = useRef<HTMLDivElement>(null);
  const [dropdownOpen, setDropdownOpen] = useOnOff();
  const [dropup, setDropup] = useOnOff();
  const [fieldOptions, setFieldOptions] = useState(options);

  useEffect(() => {
    if (apiUrl && fieldOptions === undefined) {
      fetchMyAccountDropdownsData(apiUrl).then((data) => {
        setFieldOptions(data);
      });
    }
  }, []);

  const toggleDropdown = () => {
    if (disabled) {
      return;
    }
    setDropup(false);
    if (dropdown.current && window.innerHeight - dropdown.current.getBoundingClientRect().bottom < 220) {
      setDropup(true);
    }
    setDropdownOpen(!dropdownOpen);
  };

  const getSelectedItem = useCallback(
    (item) => () => {
      if (disabled) {
        return;
      }
      onChange(item);
      isControlled && onBlur && onBlur();
      setDropdownOpen(false);
    },
    [disabled, isControlled, onBlur, onChange, setDropdownOpen],
  );

  useEffect(() => {
    if (fieldOptions?.length === 1) {
      if (!value) {
        //select default value if only one exists
        getSelectedItem(isControlled ? fieldOptions[0].value : fieldOptions[0].text)();
      }
    } else if (preselectValue && !value) {
      getSelectedItem(preselectValue)();
    }
  }, [fieldOptions, value, getSelectedItem, isControlled, preselectValue]);

  const outsideClickHandler = useCallback(() => {
    dropdownOpen && setDropdownOpen(false);
  }, [dropdownOpen, setDropdownOpen]);

  useOutsideClick(dropdown, outsideClickHandler);

  return (
    <FormSelectUI.Wrapper
      componentLocation={componentLocation}
      colsClasses={classNames}
      disabled={disabled}
      dropdownOpen={dropdownOpen}
      label={label}
      hasLabelSecondary={hasLabelSecondary}
      labelSecondary={labelSecondary}
      formInfoMessage={formInfoMessage}
      ref={dropdown}
      isOutlined={isOutlined}
    >
      <FormSelectUI.Control
        autoComplete="off"
        disabled={disabled}
        isExpanded={dropdownOpen}
        onBlur={onBlur}
        name={name}
        onClick={toggleDropdown}
        onTogglerClick={toggleDropdown}
        placeholder={placeholder ? placeholder : value}
        {...(isControlled ? { value: fieldOptions?.find((o) => o?.value === value)?.text || '' } : {})}
        readOnly
        ref={controlRef}
        type={type}
      />

      {dropdownOpen && (
        <FormSelectUI.Menu dropup={dropup}>
          {fieldOptions &&
            fieldOptions?.map((option, i) => (
              <FormSelectUI.Option
                key={`${option.value}-${option.text}`}
                onClick={getSelectedItem(isControlled ? option.value : option.text)}
                isActive={highlightSelected && value === option.value}
                text={option.text || ''}
                // If option.disabled doesn't exist or false, check if incoming props.options contain a disabled prop
                disabled={option.disabled || options?.[i]?.disabled}
              />
            ))}
        </FormSelectUI.Menu>
      )}
    </FormSelectUI.Wrapper>
  );
};

export default FormDropdown;
