import React, { useCallback } from 'react';
import { AnimatePresence } from 'framer-motion';
import {
  casinoUiStateSelectors,
  setCollectionLoadMore,
} from 'modules/casino/shared/features/casinoUi/casinoUiStateSlice';
import { useAppDispatch, useAppSelector } from 'store';
import { LoadMoreButton } from './LoadMoreButton';
import LoadMoreOnScroll from './LoadMoreOnScroll';
import { GridLoadingBehaviorType } from '../../types/gridTypes';

type Props = {
  gridLoadingBehavior: GridLoadingBehaviorType;
  stopLoading: boolean;
  isInitialPage: boolean;
  fetchMoreData: () => void;
  collectionId: string;
};

export const LoadMore: React.FC<Props> = ({
  gridLoadingBehavior,
  fetchMoreData,
  stopLoading,
  isInitialPage,
  collectionId,
}) => {
  const dispatch = useAppDispatch();
  const showLoadMoreAfterLoadLastImage = useAppSelector((state) =>
    casinoUiStateSelectors.collectionLoadMore(state, collectionId),
  );
  const isLoadMoreOnScroll = gridLoadingBehavior === GridLoadingBehaviorType.InfinityScroll;

  const handleFetchMoreData = useCallback(() => {
    isLoadMoreOnScroll && dispatch(setCollectionLoadMore({ collectionId, value: false }));
    fetchMoreData();
  }, [fetchMoreData, isLoadMoreOnScroll]);

  if (!fetchMoreData || stopLoading) return null;

  return isLoadMoreOnScroll ? (
    <LoadMoreOnScroll
      handleOnScroll={handleFetchMoreData}
      isInitialPage={isInitialPage}
      showLoadMoreAfterLoadLastImage={showLoadMoreAfterLoadLastImage}
    />
  ) : (
    <AnimatePresence>
      {showLoadMoreAfterLoadLastImage && <LoadMoreButton handleClick={handleFetchMoreData} />}
    </AnimatePresence>
  );
};

LoadMore.displayName = 'LoadMore';
