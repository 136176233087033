import { useGameTags } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/hooks/useGameTags';
import { Breakpoints } from 'modules/casino/theme/Theme';

export const useGetLayoutConfig = (game) => {
  const { bottomTag, hasTranspBottomTag } = useGameTags(game);
  const isData = !!game?.visualizationTemplateId;
  const styledType = isData ? 'data' : 'default';
  const componentType = isData ? 'data' : 'default';
  const isDesktop = Breakpoints.isDesktop;
  const hideDataCharacters = (bottomTag || hasTranspBottomTag) as boolean;
  return { styledType, componentType, isData, isDesktop, hideDataCharacters };
};
