import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { UI } from 'components/shared/UI';
import { SharedLayout } from 'pages/promotions/layouts/shared';
import { Breakpoints } from 'theme/Theme';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import { TimeDataType } from 'utils/dateUtils';
import StyledCardMeta from './CardMeta.styled';

interface CardMetaProps {
  dateDifference?: TimeDataType;
  showButton?: boolean;
}

const CardMeta: React.FC<CardMetaProps> = ({ dateDifference, showButton = true }) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });

  return (
    <StyledCardMeta>
      {dateDifference && (dateDifference.days > -1 || dateDifference.status) && (
        <SharedLayout.RemPeriod className="mr-2" style={{ opacity: 0.6 }} dateDifference={dateDifference} />
      )}
      {showButton && (
        <UI.Button size={isDesktop ? 'md' : 'sm'} text={t(Promotions.PromotionCell.showMore)} className="ml-auto" />
      )}
    </StyledCardMeta>
  );
};

CardMeta.displayName = 'CardMeta';

export default CardMeta;
