import React, { memo } from 'react';
import { Flex } from '../layout';
import Pointer from '../Pointer';

interface Props {
  linkText: number | string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  showLinkIndicator?: boolean;
}

const ContentHeaderLink: React.FC<Props> = ({ onClick, linkText, showLinkIndicator = true }) => {
  return (
    <Flex.AC className="header-link" onClick={onClick}>
      {linkText}
      {showLinkIndicator && <Pointer className="ml-2 header-link__pointer" direction="right" />}
    </Flex.AC>
  );
};

export default memo(ContentHeaderLink);
