import { useCallback, useEffect, useState } from 'react';
export const useColumnLength = (
  dataWrapperRef: React.MutableRefObject<HTMLDivElement | null>,
  dataCharacterRefDefined: boolean,
): { columns: number | null } => {
  const [columns, setColumns] = useState<number | null>(null);
  const getColumnLength = useCallback(() => {
    dataWrapperRef.current &&
      setColumns(
        window.getComputedStyle(dataWrapperRef.current).getPropertyValue('grid-template-columns').split(' ').length,
      );
  }, [setColumns, dataWrapperRef]);
  useEffect(() => {
    if (dataWrapperRef?.current && dataCharacterRefDefined) {
      getColumnLength();
      window.addEventListener('resize', getColumnLength);
    }
    return () => {
      window.removeEventListener('resize', getColumnLength);
    };
  }, [dataWrapperRef, getColumnLength, dataCharacterRefDefined]);

  return { columns };
};
