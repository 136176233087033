import { useInView } from 'react-intersection-observer';
import { Flex } from 'components/shared/layout';
import ScrollContainer, { arrowStyleTypes } from 'components/shared/ScrollContainer';
import { DepositBtn } from '../index';

interface Props {
  sectionTypes: string;
  children?: React.ReactNode;
  isHighlighted?: boolean;
  depositButton?: boolean;
}

const ScrollWrapper: React.FC<Props> = ({ children, sectionTypes, isHighlighted, depositButton }) => {
  const [scrollRef, inView] = useInView({
    rootMargin: '500px',
    triggerOnce: true,
  });

  return (
    <>
      <Flex
        ref={scrollRef}
        data-type={sectionTypes}
        className={`footer-container footer-container--${isHighlighted ? 'primary' : 'secondary'}`}
      >
        <div className="footer-container__items footer-container__items--scroll align-items-stretch  m-auto d-flex-ac">
          {depositButton && <DepositBtn type="in-scroll" />}
          <ScrollContainer
            classNames={`d-flex footer-scroll__items h-100 ${sectionTypes}`}
            regularDesktopCellWidth={80}
            moveWith={160}
            typeArrow={arrowStyleTypes.Tabs}
          >
            {inView && children}
          </ScrollContainer>
        </div>
      </Flex>
    </>
  );
};

export default ScrollWrapper;
