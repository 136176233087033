import { useMemo } from 'react';
import { selectCashierData } from 'modules/retail/modules/cashier/slice/cashier.slice';
import { DocumentEventInfo } from 'modules/retail/modules/cashier/types/cashier.types';
import { selectSSBTdata } from 'modules/retail/modules/ssbt/slice/ssbt.slice';
import { useAppSelector } from 'store';

import { selectRgbControllerData } from '../features/rgbController/slice/rgbController.slice';
import { CoinAcceptorInfo, MoneyValidatorContent, PrinterStatus, RgbControllerInfo } from '../types/ssbt.types';

type Props = {
  moneyValidatorData: MoneyValidatorContent | null;
  printerStatus: PrinterStatus | null;
  coinValidatorInfo: CoinAcceptorInfo | null;
  documentScanner: DocumentEventInfo | null;
  rgbControllerInfo: RgbControllerInfo | null;
};

export const useFetchSSBTdata = (): Props => {
  const moneyValidatorData = useAppSelector(selectSSBTdata.moneyValidatorData);
  const printerStatus = useAppSelector(selectSSBTdata.printerStatusInfo);
  const coinValidatorInfo = useAppSelector(selectSSBTdata.coinAcceptorInfo);
  const documentScanner = useAppSelector(selectCashierData.documentEventInfo);
  const rgbControllerInfo = useAppSelector(selectRgbControllerData.rgbControllerInfo);

  const terminalInfo = useMemo(() => {
    return {
      moneyValidatorData,
      printerStatus,
      coinValidatorInfo,
      documentScanner,
      rgbControllerInfo,
    };
  }, [moneyValidatorData, printerStatus, coinValidatorInfo, documentScanner, rgbControllerInfo]);
  return terminalInfo;
};
