type Props = {
  startDate?: string;
  endDate?: string;
};

export const useMainNavBarButtonsCheckDate: React.FC<Props> = ({ startDate, endDate }): boolean => {
  const getStartDate = startDate && new Date(startDate);
  const getEndDate = endDate && new Date(endDate);
  const dateNow = new Date();

  if (getStartDate && getEndDate) {
    return dateNow >= getStartDate && dateNow <= getEndDate;
  } else if (getStartDate) {
    return dateNow >= getStartDate;
  } else if (getEndDate) {
    return dateNow <= getEndDate;
  }
  return true;
};
