import React from 'react';
import { useTranslation } from 'react-i18next';
import TableRow from 'modules/casino/modules/container/widget/widgetTypes/leaderBoardWidget/leaderBoardCellWidget/components/widgetLeaderBoardComponents/TableRow';
import { LeaderBoardWidgetTypes } from 'modules/casino/modules/container/widget/widgetTypes/leaderBoardWidget/leaderBoardCellWidget/LeaderBoardCellWidgetTypes';
import { leaderBoardCSSNS, LeaderBoardWidgetTranslationKeys } from '../../LeaderBoardCellWidgetConstants';

type Props = {
  awards: LeaderBoardWidgetTypes.Award[];
};

const LeaderBoardAwards: React.FC<Props> = ({ awards }) => {
  const { t } = useTranslation();

  return (
    <div className={`${leaderBoardCSSNS}-widget__winner--scrollable no-scrollbars`}>
      <TableRow
        firstColText={t(LeaderBoardWidgetTranslationKeys.place)}
        secondColText={t(LeaderBoardWidgetTranslationKeys.awards)}
      />
      {awards.map((award, index) => {
        return <TableRow key={award.place} firstColText={award.place} secondColText={award.award} isBold={index < 3} />;
      })}
    </div>
  );
};

export default LeaderBoardAwards;
