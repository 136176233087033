const cssNS = 'c-bonus-offers-info';

export const cssBonusOffersInfoNS = {
  Button: `${cssNS}-btn`,
  ButtonCompletion: `${cssNS}-btn__completion`,
  ButtonData: `${cssNS}-btn__data`,
  ButtonNoData: `${cssNS}-btn__no-data`,
  ButtonText: `${cssNS}-btn__text`,

  Popover: `${cssNS}-popover`,
  PopoverTitle: `${cssNS}-popover__title`,

  PopoverBonus: `${cssNS}-popover__bonus`,
  PopoverBonuses: `${cssNS}-popover__bonuses`,
  PopoverBonusProgress: `${cssNS}-popover__bonus-progress`,
  PopoverBonusTitle: `${cssNS}-popover__bonus-title`,

  PopoverOffers: `${cssNS}-popover__offers`,

  ProgressBar: `${cssNS}-progress-bar`,
  ProgressBarTrack: `${cssNS}-progress-bar__track`,
  ProgressBarCompletion: `${cssNS}-progress-bar__completion`,
};
