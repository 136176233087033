import React from 'react';
import clsx from 'clsx';
import { Text } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { UI } from 'components/shared/UI';

/** DrawerUI Tab */
interface TabProps {
  onClick: React.MouseEventHandler;
  icon: string;
  iconColor?: string;
  text: string;
  isActive: boolean;
  dataQid?: string;
  dataCpp?: string;
}

const Tab: React.FC<TabProps> = ({ onClick, icon, iconColor, text, isActive, dataQid, dataCpp }) => {
  const dataCPPProp = iconColor ? { style: { fill: iconColor } } : { 'data-cpp': dataCpp };

  const tabClass = clsx('text-center drawer-tab', isActive && 'drawer-tab--active');
  return (
    <Flex.ColAC className={tabClass} onClick={onClick} data-qid={dataQid} {...dataCPPProp}>
      <UI.SVGCDN className="drawer-tab__icon" icon={icon} />
      <Text className="mt-1 drawer-tab__title" text={text} />
    </Flex.ColAC>
  );
};

export default Tab;
