import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { RootState } from 'store/rootReducer';

const PlayerBlockAppInterceptor =
  ({ closeGame }: { closeGame: () => void }) =>
  async (_, getState: () => RootState) => {
    const isAuthenticated = selectAuthentication.isAuthenticated(getState());
    const buSettings = selectGeneral.buSettings(getState());
    const isPhoneVerified = selectMyProfile.isPhoneVerified(getState());
    const hasTermsAndConditionsToAccept = selectMyProfile.hasTermsAndConditionsToAccept(getState());

    // If user has no verified phone or has unaccepted T&C we close game.
    if (isAuthenticated && ((isPhoneVerified === false && buSettings?.confirmPhone) || hasTermsAndConditionsToAccept)) {
      return new Promise((__, reject) => reject(closeGame()));
    }
    return new Promise((resolve) => resolve(true));
  };

export default PlayerBlockAppInterceptor;
