import React, { memo, useState } from 'react';
import clsx from 'clsx';
import Icon from 'components/shared/Icon';
import config from 'utils/config';
import { AppHeaderUI } from '../AppHeaderUI';

interface UserButtonProps {
  className?: string;
  onClick: React.MouseEventHandler;
  iconClassName?: string;
  icon?: string;
  notificationsCounter: number;
  hasUnseenBonusOffers?: boolean;
  isActive?: boolean;
  dataQid?: string;
  gamificationIcon?: string;
}

const NavUserGamificationButton: React.FC<UserButtonProps> = ({
  className,
  onClick,
  iconClassName,
  icon = 'user',
  notificationsCounter,
  hasUnseenBonusOffers,
  isActive,
  dataQid,
  gamificationIcon,
}) => {
  const btnClass = clsx('align-self-center nav-user__btn', isActive && 'nav-user__btn--active', className);
  const iconClass = clsx('nav-user__btn-icon', iconClassName);
  const [setError, setSetError] = useState(false);

  return (
    <div className={btnClass} onClick={onClick} data-qid={dataQid}>
      {gamificationIcon && !setError ? (
        <div className="nav-user__btn-image">
          <img
            className="wh-100"
            onError={() => setSetError(true)}
            src={`${config.CDN_IMAGE_API}fit=cover,width=68,height=68,,format=auto/${gamificationIcon}`}
          />
        </div>
      ) : (
        <Icon className={iconClass} icon={icon} />
      )}

      <AppHeaderUI.Badge
        isVisible={notificationsCounter > 0}
        isActive={hasUnseenBonusOffers}
        text={notificationsCounter}
      />
    </div>
  );
};

export default memo(NavUserGamificationButton);
