import React from 'react';
import { Flex } from 'components/shared/layout';

/** DrawerUI Sort */
interface SorterProps {
  text: string;
}
const Sorter: React.FC<SorterProps> = ({ text }) => {
  return <Flex.AC className="drawer-menu-item drawer-sorter-az">{text}</Flex.AC>;
};

export default Sorter;
