import React, { memo, useMemo } from 'react';
import { motion, Variants } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import FrameAnimator from 'components/shared/FrameAnimator/FrameAnimator';
import { getCdnImages } from 'components/shared/FrameAnimator/utils/ImageCDN';
import { mapAnimationFrames } from 'components/shared/FrameAnimator/utils/images/utils';
import { Flex } from 'components/shared/layout';
import { Breakpoints } from 'theme/Theme';
import { NotificationTypes } from '../../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import { StyledBonus } from '../../components/GamificationModalUI/BonusTitle.styled';
import useGamificationItemState from '../../hooks/useGamificationItemState';
import useGamificationItemStyles from '../../hooks/useGamificationItemStyles';

interface Props {
  index: number;
  notification: NotificationTypes.PopUpNotification;
  winItemIndex: null | number;
  setWinItemIndex: (index: number) => void;
  setAnimationComplete: (isVisible: boolean) => void;
  animationComplete: boolean;
  animationParams: NotificationTypes.GamificationModalParams;
  imageByIndexFolder: boolean;
  itemsLength?: number;
  foldersName?: number[];
  bonusAlignment?: NotificationTypes.BonusAlignment;
}

const DefaultBoxItem: React.FC<Props> = ({
  index,
  notification,
  winItemIndex,
  setWinItemIndex,
  setAnimationComplete,
  animationComplete,
  animationParams,
  imageByIndexFolder,
  itemsLength,
  foldersName,
  bonusAlignment,
}) => {
  const { bonusData, frameAudioUrl } = notification;
  const isDesktopDown = useMediaQuery({ query: `(max-width: ${Breakpoints.isDesktop}px)` });

  const { state, handleAnimationComplete, handleAnimation } = useGamificationItemState({
    bonusData,
    animationComplete,
    winItemIndex,
    index,
    setWinItemIndex,
    setAnimationComplete,
    notificationId: notification.id,
    frameAudioUrl,
  });

  const { winTextClass } = useGamificationItemStyles(state.isWinItem, itemsLength, true);

  const tileOpenAnimationImages = useMemo(() => {
    if (imageByIndexFolder) {
      return getCdnImages(mapAnimationFrames(animationParams, `${index + 1}/${animationParams.fileBaseName}`));
    } else if (foldersName) {
      return getCdnImages(mapAnimationFrames(animationParams, `${foldersName[index]}/${animationParams.fileBaseName}`));
    } else {
      return getCdnImages(mapAnimationFrames(animationParams));
    }
  }, [animationParams]);

  return (
    <motion.div
      className="d-grid"
      variants={childVariants}
      style={{ maxWidth: `${isDesktopDown ? animationParams.frameWidth : animationParams.frameMaxWidth}px` }}
    >
      <div className="grid-start-1x1 gmf-modal__animation">
        <Flex.Center className="animation-frame__wrapper">
          <FrameAnimator
            images={tileOpenAnimationImages}
            width={animationParams.frameWidth}
            height={animationParams.frameHeight}
            maxWidth={isDesktopDown ? animationParams.frameWidth : animationParams.frameMaxWidth}
            maxHeight={isDesktopDown ? animationParams.frameHeight : animationParams.frameMaxHeight}
            aspectRatioWidth={animationParams.frameAspectRatio}
            fps={60}
            iterations={1}
            play={state.playAnim}
            onIterationComplete={handleAnimationComplete}
            onClick={handleAnimation}
          />
        </Flex.Center>
      </div>
      {state.giftTitle && state.winIsVisible && (
        <StyledBonus
          className={winTextClass}
          $size={'sm'}
          $position={bonusAlignment}
          style={{ maxWidth: `${isDesktopDown ? animationParams.frameWidth : animationParams.frameMaxWidth * 0.8}px` }}
        >
          {state.giftTitle}
        </StyledBonus>
      )}
    </motion.div>
  );
};

const childVariants: Variants = {
  hidden: {
    scale: 0, //move out of the site
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
  },
};

export default memo(DefaultBoxItem);
