import { useTranslation } from 'react-i18next';
import CashierPayCancelVoucherForm from './CashierPayCancelVoucherForm';
import CashierVoucherForm from './CashierVoucherForm';
import CreateCashierVoucher from './CreateCashierVoucher';
import PayCancelCashierVoucher from './PayCancelCashierVoucher';
import { useVoucherModal } from './useVoucherModal';
import { CashierModalUI } from '../../cashierModalUI/Index';

const CashierVoucherModal = () => {
  const { t } = useTranslation();
  const { closeVoucherModal, handleToggleVoucherTab, isCreateVoucher } = useVoucherModal();

  return (
    <CashierModalUI.Wrapper isVisible={true}>
      <CashierModalUI.Body>
        <CashierModalUI.Header clearData={closeVoucherModal} />

        {/* Cashier Modal Content Wrapper */}

        <CashierModalUI.Content>
          {/* TODO: Modal content here */}
          {isCreateVoucher && <CreateCashierVoucher />}
          {!isCreateVoucher && <PayCancelCashierVoucher />}
          {/* TODO: Conditionally change text and icon for different screens */}
        </CashierModalUI.Content>
        <CashierModalUI.NavCol>
          <CashierModalUI.TabsWrapper>
            <CashierModalUI.Tab
              text={t('Cashier.VoucherModal.createVoucher')}
              onClick={() => handleToggleVoucherTab(true)}
              isActive={isCreateVoucher}
            />
            <CashierModalUI.Tab
              text={t('Cashier.VoucherModal.payCancelVoucher')}
              onClick={() => handleToggleVoucherTab(false)}
              isActive={!isCreateVoucher}
            />
          </CashierModalUI.TabsWrapper>
          {isCreateVoucher && <CashierVoucherForm />}
          {!isCreateVoucher && <CashierPayCancelVoucherForm />}
        </CashierModalUI.NavCol>
      </CashierModalUI.Body>
    </CashierModalUI.Wrapper>
  );
};

export default CashierVoucherModal;
