import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { HELP_MAIN_ROUTE, helpRoute } from 'pages/help/utils/help.utils';
import { useAppDispatch } from 'store';
import { setDrawer } from '../../slice/drawer.slice';
import { DrawerUI } from '../DrawerUI';

type DrawerHelpNavLinkType = {
  icon: { icon: string; width: number };
  id: number;
  isActive: boolean;
  isMainTopic: boolean;
  isHome?: boolean;
  route: string;
  title: string;
  type: string;
};

interface Props {
  item: DrawerHelpNavLinkType;
  isActive: boolean;
}

const DrawerHelpSectionNavLink: React.FC<Props> = ({ item, isActive }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOnClick = () => {
    dispatch(setDrawer(false));
    if (item.id === 0 || item.isHome) {
      navigate(HELP_MAIN_ROUTE);
      return;
    }
    if (item.route) {
      navigate(helpRoute(item.route));
    }
  };

  return (
    <DrawerUI.Link
      onClick={handleOnClick}
      isActive={isActive}
      icon={item?.icon?.icon}
      text={item?.title}
      dataQid={`dr-help-${item.route}`}
      showHelpIcon
    />
  );
};

export default memo(DrawerHelpSectionNavLink);
