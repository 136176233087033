import { MyBets } from 'pages/myAccount/tabs/history/sections/sportsHistory/types/myBets.types';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppSelector } from 'store';
import { mapOddId, formatOddsValue, convertOddsValue, mapDisplayOddsId } from 'utils/common/helpersCommon';
import { Sport } from 'utils/common/types/sportTypes';

type ItemType = Sport.Outcome | MyBets.OutcomeEvent | { odds: number; fractionalOdds: string; americanOdds: number };

type DisplayOddsTypes = {
  displayAmericanOdds: number;
  displayOdds: number;
  displayFractionalOdds: string;
};

export const useOddsFormat = () => {
  const selectedOddTypeId = useAppSelector(selectMyProfile.preferredOddTypeId);
  const oddsFormat = mapOddId[selectedOddTypeId];
  const displayOddsFormat = mapDisplayOddsId[selectedOddTypeId];

  const convertOdds = (odds: number) => convertOddsValue(odds, selectedOddTypeId);
  const formatOdds = (item: ItemType) => formatOddsValue(item[oddsFormat], selectedOddTypeId) || convertOdds(item.odds);

  const formatDisplayOdds = (item: DisplayOddsTypes) =>
    formatOddsValue(item[displayOddsFormat], selectedOddTypeId) || convertOdds(item.displayOdds);

  return { oddsFormat, selectedOddTypeId, convertOdds, formatOdds, formatDisplayOdds };
};
