import { DefaultTheme } from 'styled-components/native';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { getBusinessUnit } from 'utils/common/helpersCommon';
import { BethubTheme } from './bethub';
import { InbetTheme } from './inbet';
import { SesameTheme } from './sesame';
import { WinbetTheme } from './winbet';
import { WinbetRoTheme } from './winbetRo';

const theme = {
  wbbg: WinbetTheme,
  wbro: WinbetRoTheme,
  ibbg: InbetTheme,
  smbg: SesameTheme,
  egtt: BethubTheme,
  bhbg: BethubTheme,
  bhbgnra: BethubTheme,
  whiteLabel: BethubTheme,
};

export const useGetTheme = (selectTheme = 'dark'): DefaultTheme => {
  const bu = getBusinessUnit().toLowerCase();

  const layoutConfigs = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.LAYOUTTYPECONFIG,
    deviceLayout: 'general',
  });
  const layoutConfig = layoutConfigs ? { LayoutConfigs: { ...layoutConfigs } } : { LayoutConfigs: {} };
  const defaultTheme = theme[bu] ? theme[bu][selectTheme] : theme['wbbg'][selectTheme];

  const th = { ...defaultTheme, ...layoutConfig };
  return th;
};
