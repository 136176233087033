import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { FormUI } from 'components/shared/forms/components';
import { Flex } from 'components/shared/layout';

interface InputProps {
  wrapperClassName?: string;
  inputClassName?: string;
  label?: string;
  id?: string;
  value?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  hasError?: boolean;
  errorText?: string;
  buttonText?: string;
  buttonOnClick?: React.MouseEventHandler;
  maxLength?: number;
  readOnly?: boolean;
  spanAllColumns?: boolean;
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
  pattern?: string;
  onInput?: React.ChangeEventHandler<HTMLInputElement>;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      wrapperClassName,
      inputClassName,
      label,
      id,
      value,
      placeholder,
      onChange,
      onFocus,
      isDisabled,
      hasError,
      errorText,
      buttonText,
      buttonOnClick,
      onKeyDown,
      maxLength,
      readOnly,
      spanAllColumns,
      inputMode,
      onInput,
    },
    ref,
  ) => {
    const inputWrapperClass = clsx(
      'terminal-form-control__wrapper',
      hasError && 'terminal-form-control__wrapper--has-error',
      wrapperClassName,
    );
    const inputClass = clsx('terminal-form-control', inputClassName);
    const flexClass = clsx(spanAllColumns && 'span-all-cols');

    return (
      <Flex.Col className={flexClass}>
        {label && (
          <Flex.ACJB>
            <FormUI.Label label={label} />
            {errorText && <FormUI.Label className="color--error" label={errorText} />}
          </Flex.ACJB>
        )}
        <Flex className={inputWrapperClass}>
          <input
            ref={ref}
            id={id}
            className={inputClass}
            type="text"
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onFocus={onFocus}
            disabled={isDisabled}
            onKeyDown={onKeyDown}
            maxLength={maxLength}
            readOnly={readOnly}
            inputMode={inputMode}
            onInput={onInput}
          />
          {buttonText && (
            <Flex.Center className="terminal-form-control__btn" onClick={buttonOnClick}>
              {buttonText}
            </Flex.Center>
          )}
        </Flex>
      </Flex.Col>
    );
  },
);

// Add a display name for the component
Input.displayName = 'Input';

export default Input;
