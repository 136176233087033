import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { MainNavBarButtonsTypes } from '../types/mainNavBarButtons.types';

export const useGetMainNavBarButtonsLayout = ({
  type,
}: {
  type?: MainNavBarButtonsTypes.ItemType;
}): {
  mainButtons: MainNavBarButtonsTypes.Item[];
  depositBtn?: MainNavBarButtonsTypes.Item;
  langBtn?: MainNavBarButtonsTypes.Item;
  typeItem?: MainNavBarButtonsTypes.Item;
} => {
  const mainNavBarButtons = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.Main_Nav_Bar_Buttons,
  });

  const offerItem = mainNavBarButtons?.filter((item) => {
    return item.type === 'offers';
  });

  const defaultButtons: MainNavBarButtonsTypes.Item[] = [
    ...(mainNavBarButtons ? mainNavBarButtons : []),
    {
      isVisible: true,
      type: 'offers',
    },
  ];

  const typeItem = type
    ? defaultButtons?.filter((item) => {
        return item.type === type;
      })?.[0]
    : undefined;
  const depositBtn = mainNavBarButtons?.filter((item) => item.type === 'deposit')?.[0];

  return { mainButtons: !offerItem?.[0] ? defaultButtons : mainNavBarButtons, depositBtn, typeItem };
};
