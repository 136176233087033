import { useCallback, useRef } from 'react';
import { isIOS } from 'react-device-detect';
import smoothscroll from 'smoothscroll-polyfill';
import { toggleNoScrollClasses } from 'utils/common/toggleNoScrollClasses';

export const useScrollToBackPosition = (): {
  scrollToBack: (addClass: boolean, htmlClass?: boolean, disableScroll?: boolean) => void;
  updateScrollPosition: () => void;
  resetScrollPosition: () => void;
} => {
  const scrollRef = useRef<number>(0);

  const updateScrollPosition = useCallback(() => {
    scrollRef.current = window.scrollY;
  }, []);

  const resetScrollPosition = useCallback(() => {
    scrollRef.current = 0;
  }, []);

  /**
   * Toggle Body and HTML no scroll classes
   * @param addClass boolean, Add or Remove classes
   * @param htmlClass boolean, Add or Remove classes to HTML root
   * @param disableScroll boolean, Scroll to prev position
   */

  const scrollToBack = (addClass, htmlClass = false, disableScroll = true) => {
    if (addClass) {
      isIOS && (scrollRef.current = window.scrollY);
      toggleNoScrollClasses(addClass, htmlClass);
    } else {
      toggleNoScrollClasses(addClass, htmlClass);
      const scrollYPostion = scrollRef.current < 10 ? window.scrollY : scrollRef.current;
      smoothscroll.polyfill();
      isIOS && disableScroll && window.scrollTo(0, scrollYPostion);
    }
  };

  return { scrollToBack, updateScrollPosition, resetScrollPosition };
};
