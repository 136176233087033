export const ttl = 60 * 60 * 24;

export const noActiveProviderAlias = '-1';

export enum ProvidersBarPosition {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export const DEFAULT_PROVIDERS_POSITION = ProvidersBarPosition.TOP;
