import React from 'react';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { SpecialCellMap } from './constants/SpecialCellMap';

interface Types {
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
  collectionId: string;
  gridPage: number;
}

const SpecialCell: React.FC<Types> = ({ categoryId, categoryLayoutType, gridPage, collectionId }) => {
  const { gameSection } = useGetCategoryParams(categoryId, categoryLayoutType);
  const SpecialCellComponent = SpecialCellMap[gameSection?.type];

  return SpecialCellComponent ? (
    <SpecialCellComponent
      categoryId={categoryId}
      categoryLayoutType={categoryLayoutType}
      collectionId={collectionId}
      gridPage={gridPage}
    />
  ) : (
    <></>
  );
};

export default SpecialCell;
