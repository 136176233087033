import React, { memo, useEffect, useRef } from 'react';
import { FormatType, addToDate, formatDateUTC } from 'modules/casino/shared/utils/dateUtils';

interface Props {
  endDateMS: number;
  startDateMS?: number;
  classNames?: string;
  format?: FormatType;
  translations?: { day: string; days: string };
}

const CountdownTimer: React.FC<Props> = ({
  classNames,
  startDateMS = Date.now(),
  endDateMS,
  format = 'mm:ss without-locale',
  translations,
}) => {
  const endDateRef = useRef(endDateMS);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const timerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      endDateRef.current = addToDate(endDateRef.current, -1, 'seconds');
      const diff = endDateRef.current - startDateMS;

      if (diff > 0 && endDateRef.current > startDateMS) {
        const time = formatDateUTC(format, diff, undefined, translations);
        if (timerRef.current) {
          timerRef.current.innerText = time;
        }
      } else {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      }
    }, 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const initialDiff = formatDateUTC(format, endDateRef.current - startDateMS, undefined, translations);
  return (
    <div className={classNames} ref={timerRef}>
      {initialDiff}
    </div>
  );
};

export default memo(CountdownTimer);
