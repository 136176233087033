import { combineReducers } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage/session';
import casinoGamesReducer from 'modules/casino/modules/container/cellsContainer/cellls/slice/casinoGamesSlice';
import casinoCarouselReducer from '../modules/carousel/slice/casinoCarouselSlice';
import casinoFavouriteGamesReducer from '../modules/container/cellsContainer/cellls/additionalGames/favourites/slice/casinoFavouriteGamesSlice';
import casinoCategoryReducer from '../modules/container/cellsContainer/containers/inner/slice/casinoCategorySlice';
import categoryImpressionsReducer from '../modules/container/cellsContainer/containers/shared/features/categoryImpressions/slice/categoryImpressionsSlice';
import recommendationEngineReducer from '../modules/container/cellsContainer/containers/shared/features/recommendationEngine/slice/recommendationEngineSlice';
import gameProvidersReducer from '../modules/container/shared/features/casinoGameProviders/slice/casinoGameProvidersSlice';
import casinoBreadcrumbsReducer from '../modules/container/shared/features/categoryBreadcrumb/slice/CategoryBreadcrumbSlice';
import casinoWidgetsReducer from '../modules/container/widget/slice/casinoWidgetsSlice';
import casinoLobbyReducer from '../modules/lobby/containersLobby/slice/casinoLobbySlice';
import providersCasinoCategoryReducer from '../modules/lobby/providerLobby/slice/providersCategorySlice';
import { casinoNavigationReducer } from '../modules/navigation/shared/slice/casinoNavigationSlice';
import gridLayoutSettingsReducer from '../shared/components/gridLayoutSettings/gridLayoutSettingsSlice';
import casinoFullScreenReducer from '../shared/features/casinoFullScreen/casinoFullScreenSlice';
import casinoSearchReducer from '../shared/features/casinoSearch/casinoSearchSlice';
import casinoUiStateReducer from '../shared/features/casinoUi/casinoUiStateSlice';
import casinoGameLauncherSlice from '../shared/features/gameLaunch/casinoLaunchSlice';
import messageManagerSlice from '../shared/features/gameLaunch/postMessages/messageManagerSlice';
import { casinoGamesBonusReducer } from '../shared/features/gameLaunchBonus/slice/gameLaunchBonus.slice';
import bonusOffersInfoButtonSlice from '../shared/features/gameLaunchBonus/topBar/bonusOffersInfo/slice/bonusOffersInfoButton.slice';
import tournamentsButtonSlice from '../shared/features/gameLaunchBonus/topBar/tournaments/slice/tournamentsButton.slice';
import jackpotReducer from '../shared/features/jackpots/jackpotSlice';
import casinoPopOverReducer from '../shared/features/popover/casinoPopOverSlice';

const casinoFavouriteGamesPersistConfig = {
  key: 'casinoFavouriteGames',
  version: 1,
  storage,
  whitelist: ['isFavouriteGamesModalOpen'],
};

export const casinoReducer = combineReducers({
  casinoBreadcrumbs: casinoBreadcrumbsReducer,
  casinoCategory: casinoCategoryReducer,
  casinoCarousel: casinoCarouselReducer,
  providersCasinoCategory: providersCasinoCategoryReducer,
  casinoGameLauncher: casinoGameLauncherSlice,
  casinoLobby: casinoLobbyReducer,
  casinoNavigation: casinoNavigationReducer,
  jackpot: jackpotReducer,
  gameProviders: gameProvidersReducer,
  casinoSearch: casinoSearchReducer,
  casinoFavouriteGames: persistReducer(casinoFavouriteGamesPersistConfig, casinoFavouriteGamesReducer),
  casinoWidgets: casinoWidgetsReducer,
  casinoGames: casinoGamesReducer,
  gridLayoutSettings: gridLayoutSettingsReducer,
  recommendationEngine: recommendationEngineReducer,
  casinoGamesBonus: casinoGamesBonusReducer,
  casinoFullScreen: casinoFullScreenReducer,
  categoryImpressions: categoryImpressionsReducer,
  messageManager: messageManagerSlice,
  casinoUiState: casinoUiStateReducer,
  casinoPopOver: casinoPopOverReducer,
  bonusOffersInfoButton: bonusOffersInfoButtonSlice,
  tournamentsButton: tournamentsButtonSlice,
});
