import { GenericCellWidget } from '../../widgetTypes/genericWidgets/genericCellWidget';
import { LastWinnersWidgets } from '../../widgetTypes/lastWinnersWidgets';
import LeaderBoardCellWidget from '../../widgetTypes/leaderBoardWidget/leaderBoardCellWidget/LeaderBoardCellWidget';
import PrizeDropCellWidget from '../../widgetTypes/prizeDropWidget/prizeDropCellWidget/PrizeDropCellWidget';
import { ThirdPartyCellWidget } from '../../widgetTypes/thirdPartyWidget/thirdPartyCellWidget';

export const cellWidgetTypesMap = {
  LAST_WINNERS: LastWinnersWidgets.LastWinnersCellWidget,
  GENERIC: GenericCellWidget,
  PRIZE_DROP: PrizeDropCellWidget,
  THIRD_PARTY: ThirdPartyCellWidget,
  LEADERBOARD: LeaderBoardCellWidget,
};
