import { Buffer } from 'buffer';
import { decode } from '@msgpack/msgpack';
import { GamificationPageTypes } from 'pages/gamificationPage/types/GamificationPage.types';
import { cdnConfig, fitCover } from 'utils/common/cdnConfig';
import { generateRandomNumberArray, isEmpty } from 'utils/common/helpersCommon';
import config from 'utils/config';
import {
  cashWheelConfig,
  dwarfsWheelConfig,
  easterEggConfig,
  dwarfsWagonConfig,
  videoConfig,
  christmasTreeConfig,
  christmasVillageConfig,
  jungleWheelConfig,
  setCampaignConfig,
  xmasReelConfig,
  giftBoxConfig,
  cashBellConfig,
  amusementWheelConfig,
  amusementWheel3DConfig,
  jungleBoxConfig,
  goldenBoxConfig,
  xmasBoxConfig,
  springReelConfig,
  Box2RowsConfig,
  TripleAwardBoxConfig,
  OlympicConfig,
  PageBoxes,
  wizardBonusConfig,
} from './animationsConfig';
import { NotificationTypes } from './notificationTypes';

export const normalizeInboxNotification = (
  inboxNotification: NotificationTypes.InboxNotification[],
): NotificationTypes.InboxNotificationMap => {
  return inboxNotification.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});
};

export const findActivePopupNotificaton = (popUpNotifications: NotificationTypes.PopUpNotificationMap) => {
  const now = Date.now();
  const newNotification = Object.values(popUpNotifications)
    .sort((a, b) => a.priorityId - b.priorityId)
    .find((notification) => {
      return !notification.isRead && new Date(notification?.validUntil).getTime() > now;
    });

  return newNotification;
};

export const parseBonus = (bonuses): NotificationTypes.Bonus[] => {
  if (isEmpty(bonuses)) return [];

  return bonuses.map((bonus) => {
    return {
      id: bonus[0],
      bonusStatusId: bonus[1],
      title: bonus[2],
      bonusCode: bonus[3],
      content: bonus[4],
      categoryAlias: bonus[5],
      bonusType: bonus[6],
      buttonText: bonus[7],
      buttonUrl: bonus[8],
      buttonActionType: bonus[9],
      termsAndConditionsId: bonus[10],
    };
  });
};

const isObject = (item) => item && typeof item === 'object' && !Array.isArray(item);

const items = (groupOptions) => {
  if (isEmpty(groupOptions)) return {};
  const result = {};

  groupOptions.forEach((item) => {
    const [key, value] = Object.entries(item)[0]; // Get the key and value from each object
    result[key] = value;
  });
  return result;
};

export const parseGroupOptions = (groupOptions) => {
  if (isEmpty(groupOptions)) return undefined;
  if (!groupOptions?.[0]?.data && !isObject(groupOptions)) {
    return groupOptions.map((item) => {
      return {
        title: item[3],
        buttonUrl: item[4],
        groupConditionId: item[2],
        groupId: item[1],
        playerBonusId: item[0],
      };
    });
  }
  return Object.keys(groupOptions).map((el) => {
    return {
      [el]: {
        image: groupOptions[el][0],
        imageActive: groupOptions[el][1],
        title: groupOptions[el][2],
        ...(!groupOptions[el][3]?.[0]?.data && !isObject(groupOptions[el][3])
          ? {
              data: parseGroupOptions(groupOptions[el][3]),
            }
          : { data: items(parseGroupOptions(groupOptions[el][3])) }),
      },
    };
  });
};

export const parseBonusData = (bonusData: string): NotificationTypes.BonusData => {
  const decodedArray = decode<ArrayLike<string[] & NotificationTypes.Bonus[] & undefined>>(
    Buffer.from(bonusData, 'base64'),
  ) as ArrayLike<string[] & NotificationTypes.Bonus[] & undefined>;

  return {
    otherOptions: decodedArray[11],
    ...(decodedArray?.[12] ? { bonuses: parseBonus(decodedArray?.[12]) } : { bonuses: [] }),
    ...(decodedArray?.[32]
      ? {
          groupOptions: parseGroupOptions(decodedArray?.[32])?.reduce((acc, item) => {
            const [key, value] = Object.entries(item)[0]; // Extract the key and value from the object
            acc[key] = value;
            return acc;
          }, {}),
        }
      : {}),
  };
};

export const reorderBonusOptions = (otherOptions: string[]): string[] => {
  const randomBonusOptions: string[] = [...otherOptions];

  if (!isEmpty(otherOptions) && otherOptions?.length > 1) {
    const randomNumbers = generateRandomNumberArray(otherOptions.length, otherOptions.length);
    randomNumbers.forEach((randomNum, idx) => {
      randomBonusOptions[idx] = otherOptions?.[randomNum];
    });
  }

  return randomBonusOptions;
};

export const normalizePopUpNotification = (
  inboxNotification: NotificationTypes.PopUpNotificationApi[],
): NotificationTypes.PopUpNotificationMap => {
  const sortedNotification = inboxNotification?.sort((a, b) => a.priorityId - b.priorityId);
  return sortedNotification?.reduce((acc, curr) => {
    if (curr?.bonusData) {
      const parsedBonusData = parseBonusData(curr.bonusData);
      if (!isEmpty(parsedBonusData.bonuses)) {
        parsedBonusData.otherOptions = reorderBonusOptions([
          ...(parsedBonusData.bonuses?.[0]?.title ? [parsedBonusData.bonuses[0].title] : []),
          ...(parsedBonusData?.otherOptions ? parsedBonusData.otherOptions : []),
        ]);

        acc[curr.id] = { ...curr, bonusData: parsedBonusData };
      }
    } else {
      acc[curr.id] = { ...curr, bonusData: {} };
    }

    return acc;
  }, {});
};

const animationConfigMap = {
  Easter2022: easterEggConfig, // wbbg, wbro
  JungleWheel: jungleWheelConfig, // wbbg, wbro
  CashWheel: cashWheelConfig, // ibbg
  DwarfsWagon: dwarfsWagonConfig, // wbbg, wbro
  DwarfsWheel: dwarfsWheelConfig, // wbbg, wbro
  Stoichkov: videoConfig, // wbbg
  ChristmasTree: christmasTreeConfig, // ibbg
  ChristmasVillage: christmasVillageConfig, // wbbg, wbro
  GiftBox: giftBoxConfig, // default box
  FreeBox: giftBoxConfig, // wbbg, wbro
  JungleBox: jungleBoxConfig, // wbbg, wbro
  GoldenBox: goldenBoxConfig, // ibbg
  XmasBox: xmasBoxConfig, // wbbg, wbro
  XmasReel: xmasReelConfig, // smbg
  SpringReel: springReelConfig, // smbg
  CashBell: cashBellConfig, // ibbg
  AmusementWheel: amusementWheelConfig, // wbbg
  AmusementWheel3D: amusementWheel3DConfig, // wbbg
  Box2Rows: Box2RowsConfig, //IBBG
  TripleAwardBox: TripleAwardBoxConfig, // smbg
  Olympic: OlympicConfig, //ibbg
  PageBoxes: PageBoxes, //Default page box
  WizardBonus: wizardBonusConfig,
};

export const defineAnimationParams = ({
  animationKey,
  layout,
  folder,
  numberOfFrames,
}: {
  animationKey?: NotificationTypes.AnimationKeys | GamificationPageTypes.AnimationKeys;
  layout?: NotificationTypes.GamificationCampaignsLayouts;
  folder?: NotificationTypes.GamificationFolder;
  numberOfFrames?: number;
}): NotificationTypes.GamificationModalParams => {
  const animConfig = animationConfigMap[animationKey || ''];
  const frames = numberOfFrames ? Number(numberOfFrames) : undefined;
  return setCampaignConfig({ folder, layout, numberOfFrames: frames, config: animConfig });
};

export const constructAnimationImage = ({
  folder,
  image,
  width,
  height,
  fit,
}: {
  folder: NotificationTypes.GamificationFolder;
  image: string;
  width: number;
  height: number;
  fit?: 'none' | 'contain' | 'cover';
}): string =>
  `${config.CDN_IMAGE_API}${fitCover(width, height, fit)}${cdnConfig.GAMIFICATION_MODULE}/${folder}/${image}`;

export const NotificationPopUpTypes = {
  slider: 'slider',
  single: 'single',
};
