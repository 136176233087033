import React, { memo } from 'react';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import DevAPISourceSwitcher from './DevAPISourceSwitcher';
import { LayoutConfigTypes } from '../../../general/types/generalSlice.types';

const MainNavbarDevAPISwitcher: React.FC = () => {
  const isTopNavBarEnabled = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.NAVIGATION,
  })?.topNav;

  if (isTopNavBarEnabled) return null;

  return <DevAPISourceSwitcher />;
};

export default memo(MainNavbarDevAPISwitcher);
