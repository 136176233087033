import { useTranslation } from 'react-i18next';
import CountdownTimer from 'components/shared/CountdownTimer';
import { Flex } from 'components/shared/layout';
import { getValue } from 'pages/myAccount/tabs/history/utils/history.utils';
import AmountCurrency from 'shared/common/features/myProfile/components/AmountCurrency';
import { selectRealityCheckSession } from 'shared/common/features/realityCheckSession';
import { setLiveTime } from 'shared/common/features/realityCheckSession/slice/realityCheckSession.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { RealityCheckModal } from 'utils/common/translationUtils/translationStrings';

export const RealityCheckSessionTopBar = () => {
  const sessionData = useAppSelector(selectRealityCheckSession.sessionHeaderData);
  const endTime = useAppSelector(selectRealityCheckSession.sessionEndTime);
  const liveTime = useAppSelector(selectRealityCheckSession.sessionLiveTime);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  if (isEmpty(sessionData)) return null;
  window.onfocus = () => {
    dispatch(setLiveTime());
  };

  return (
    <div className={'reality-check-session-top-bar'}>
      <Flex.ColCenter className={'reality-check-session-top-bar__box'}>
        <div className="reality-check-session-top-bar__title">{t(RealityCheckModal.Session.headerTime)}</div>
        <CountdownTimer
          startDateMS={sessionData.sessionStartTime}
          endDateMS={liveTime || Date.now()}
          duration={(endTime || sessionData.sessionEndTime) - sessionData.sessionStartTime}
          format={'HH:mm:ss without-locale'}
          takeDateOwnOffset={true}
          countDiff={1}
          isLive={true}
          isStatic={Boolean(endTime)}
        />
      </Flex.ColCenter>
      <Flex.ColCenter className={'reality-check-session-top-bar__box'}>
        <div className="reality-check-session-top-bar__title">{t(RealityCheckModal.Session.headerTotalBet)}</div>
        <AmountCurrency spaceBefore={false} amount={getValue(sessionData?.totalBet)} />
      </Flex.ColCenter>
      <Flex.ColCenter className={'reality-check-session-top-bar__box'}>
        <div className="reality-check-session-top-bar__title">{t(RealityCheckModal.Session.headerTotalNet)}</div>
        <AmountCurrency spaceBefore={false} amount={getValue(sessionData?.totalNet, false)} />
      </Flex.ColCenter>
    </div>
  );
};
