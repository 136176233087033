import { useCategoryGames } from './useCategoryGames';
import { CategoryLayoutTypes } from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';

export const useShowSpecialCell = ({
  gameUniqueId,
  categoryId,
  categoryLayout,
}: {
  gameUniqueId: string;
  categoryId: string;
  categoryLayout: CategoryLayoutTypes;
}): { specialCell: boolean | undefined } => {
  const items = useCategoryGames({ categoryLayoutType: categoryLayout, categoryId });
  if (!items[gameUniqueId]) return { specialCell: undefined };

  const { specialCell } = items[gameUniqueId];

  return { specialCell };
};
