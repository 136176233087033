import useGridLayoutSettingsFetch from 'modules/casino/shared/components/gridLayoutSettings/hooks/useGridLayoutSettingsFetch';
import { useSetBonusTagPolling } from 'modules/casino/shared/features/gameLaunchBonus/hooks/useSetBonusTagPolling';
import { useSetJackpotLevelsPolling } from 'modules/casino/shared/features/jackpots/hooks/useSetJackpotLevelsPolling';
import { jackpotSelectors } from 'modules/casino/shared/features/jackpots/jackpotSlice';
import { useAppSelector } from 'store';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { useSendInitialImpressions } from '../../containers/shared/features/categoryImpressions/hooks/useSendInitialImpressions';
import { useSendOnScrollImpressions } from '../../containers/shared/features/categoryImpressions/hooks/useSendOnScrollImpressions';
import { useFetchFavouriteGamesIds } from '../additionalGames/favourites/hooks/useFetchFavouriteGamesIds';
import { SET_POLLING_RESTRICTED_SUBTYPE } from '../constants/casinoGamesConstants';

export const useGamesRenderAdditionalFetches = ({
  categoryId,
  categoryLayoutType,
  subType,
}: {
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
  subType?: CategorySubTypes;
}) => {
  const pauseFetchInterval = useAppSelector(jackpotSelectors.selectPauseFetchInterval);
  const pauseSetPolling = subType && SET_POLLING_RESTRICTED_SUBTYPE.includes(subType);
  const skipPolling =
    categoryLayoutType === CategoryLayoutTypes.PROVIDER_CATEGORY ||
    categoryLayoutType === CategoryLayoutTypes.LOBBY ||
    (pauseSetPolling && pauseFetchInterval);

  useSetJackpotLevelsPolling({ skipPolling, pauseSetPolling });
  useSetBonusTagPolling({ skipPolling, pauseSetPolling });
  useFetchFavouriteGamesIds();
  useGridLayoutSettingsFetch();
  // useInSegmentCheckFetch();
  // Category impressions
  useSendInitialImpressions({ categoryId, categoryLayoutType });
  useSendOnScrollImpressions();
};
