import React, { useCallback, useRef } from 'react';
import { gamesSelectors } from 'modules/casino/modules/container/cellsContainer/cellls/slice/casinoGamesSlice';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';
import { isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import { useAppSelector } from 'store';
import GridCellLayout from '../../../gridCell/GridCellLayout';
import { getSpecialCellItemBackgroundColor } from '../../utils/specialCellUtils';

type Props = {
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
  collectionId: string;
  gridPage: number;
};

const AccentGameSection: React.FC<Props> = ({ categoryId, categoryLayoutType, collectionId, gridPage }) => {
  const accentGameData = useAppSelector((state) => gamesSelectors.accentGameSectionData(state, collectionId));
  const { queryType, gameSection } = useGetCategoryParams(categoryId, categoryLayoutType);
  const titleRef = useRef<HTMLImageElement>(null);
  const setRefs = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      const aspr = node.clientWidth / node.clientHeight;
      const paddingTop = node.clientHeight - (node.clientWidth - 30) / aspr;
      node.style.padding = `${paddingTop}px 15px 0`;
      if (titleRef.current) {
        titleRef.current.style.lineHeight = `${paddingTop}px`;
      }
    }
  }, []);

  if (isEmpty(accentGameData)) {
    return <></>;
  }

  return (
    <div className={`${cssGlobalNS}-c-accent casino-grid-item casino-grid-item--regular`}>
      <div ref={titleRef} className={`${cssGlobalNS}-c-accent--title`}>
        {gameSection?.titleAccentedGame || ''}
      </div>
      <div
        className={`${cssGlobalNS}-c-accent--wrapper`}
        ref={setRefs}
        style={{
          background: getSpecialCellItemBackgroundColor(accentGameData.background),
          width: '100%',
          height: '100%',
        }}
      >
        <GridCellLayout
          index={0}
          gridPage={gridPage}
          gamesRenderedCount={1}
          categoryId={categoryId}
          isLastItem={false}
          gameUniqueId={accentGameData.uniqueId}
          collectionId={collectionId}
          skipLazyLoad={true}
          queryType={queryType}
          categoryLayout={categoryLayoutType}
        />
      </div>
    </div>
  );
};

export default AccentGameSection;
