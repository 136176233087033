import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { homePageThunks } from 'pages/homePage/slice/homePage.slice';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getBusinessUnit } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { LANDING_PAGE_SEO_DATA_KEY } from '../seoDataConstants';
import { SEODataTypes } from '../types/seoData.types';

const seoDataThunks: SEODataTypes.SeoDataThunks = {
  fetchSEOData: createAbortThunk('common/fetchSEOData', async ({ verticalId }, { source }) => {
    const url = `${config.API_URL}/api/cms/public/seo-content/${verticalId}?workingBusinessUnit=${getBusinessUnit()}`;
    const response = await axiosInstance.get<SEODataTypes.SEOData>(url, {
      data: null,
      cancelToken: source.token,
    });

    return { id: verticalId, data: response.data };
  }),
};

export const { fetchSEOData } = seoDataThunks;

const initialState: SEODataTypes.State = {
  seoDataByVertical: {},
};

const seoData = createSlice({
  name: 'seoData',
  initialState,
  reducers: {
    resetSEO: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      seoDataThunks.fetchSEOData.fulfilled,
      (state, action: PayloadAction<SEODataTypes.SEODataResponse>) => {
        const { id, data } = action.payload;

        state.seoDataByVertical = { ...state.seoDataByVertical, [id]: data };
      },
    );
    builder.addCase(homePageThunks.fetchHomePage.fulfilled, (state, action) => {
      const { seoContent } = action.payload;

      if (seoContent) {
        state.seoDataByVertical = { ...state.seoDataByVertical, [LANDING_PAGE_SEO_DATA_KEY]: seoContent };
      }
    });
  },
});
export const { resetSEO } = seoData.actions;

const selectSeoDataByVertical = (state: RootState, seoDataKey: number | undefined): SEODataTypes.SEOData | undefined =>
  seoDataKey ? state.common.seoData.seoDataByVertical[seoDataKey] : undefined;

export const seoDataSelectors = {
  seoDataByVertical: selectSeoDataByVertical,
};

export default seoData.reducer;
