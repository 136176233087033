import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Icon from 'components/shared/Icon';
import NoDataMessage from 'components/shared/NoDataMessage';
import { useCDNIconSprites } from 'hooks/common/useCDNIconSprites';
import { PromotionTypes } from 'pages/promotions/types/promotions.types';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import PrizeDropPromoGiftItemsContainer from './PrizeDropPromoGiftItemsContainer';
import PrizeDropPromoTabsSecondary from './PrizeDropPromoTabsSecondary';
import { prizeDropPromoCSSNS } from '../prizeDropUICSS.types';

export const primaryTabs = [
  { name: Promotions.PrizeDrop.rewardsTab, icon: 'egtd-przd-gift' },
  { name: Promotions.PrizeDrop.winnersTab, icon: 'egtd-przd-podium' },
];

type Props = {
  personalRewards: PromotionTypes.RewardsObject<PromotionTypes.PersonalRewards> | null;
  rewardedPlayers: PromotionTypes.RewardsObject<PromotionTypes.PersonalRewards> | null;
  rewards?: PromotionTypes.RewardsObject<PromotionTypes.Rewards>;
  iconResource?: string;
};

const PrizeDropPromoTabsPrimary: React.FC<Props> = ({ iconResource, personalRewards, rewards, rewardedPlayers }) => {
  const [tabPrimaryIndex, setTabPrimaryIndex] = useState(0);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleModalOpen = useCallback(
    (type: 'rewardedPlayers' | 'personalRewards') => {
      const rewardsToDisplay = type === 'rewardedPlayers' ? rewardedPlayers : personalRewards;
      const modalProps = { iconResource, ...(rewardsToDisplay ? rewardsToDisplay : {}) };

      dispatch(
        showRootModal({
          modalType: 'PROMO_PD_REWARDED_PLAYERS_MODAL',
          modalProps,
          modalPriority: ModalPriorityEnum.LOW,
          modalId: 'PROMO_PD_REWARDED_PLAYERS_MODAL',
        }),
      );
    },
    [dispatch, rewardedPlayers, personalRewards, iconResource],
  );

  useCDNIconSprites('przdIcons', 'przd_icons_timestamp', 'przd-icons', 'common-content/prize-drop/sprites.svg');

  const onTabsSelect = useCallback((tabIdx: number) => {
    setTabPrimaryIndex(tabIdx);
  }, []);

  return (
    <Tabs
      selectedIndex={tabPrimaryIndex}
      onSelect={onTabsSelect}
      className={`${prizeDropPromoCSSNS}-tabs-primary`}
      focusTabOnClick={false}
    >
      <TabList className={`${prizeDropPromoCSSNS}-tabs-list-primary`} data-ics--global>
        {primaryTabs.map((tab, index) => (
          <Tab
            key={index}
            className={`${prizeDropPromoCSSNS}-tab`}
            selectedClassName={`${prizeDropPromoCSSNS}-tab--selected`}
          >
            <Icon icon={tab.icon} /> {t(tab.name)}
          </Tab>
        ))}
        <span
          className={`${prizeDropPromoCSSNS}-glider`}
          style={{ width: `calc(100% / ${primaryTabs.length})` }}
        ></span>
      </TabList>

      <TabPanel
        className={`${prizeDropPromoCSSNS}-panel custom-scrollbars`}
        selectedClassName={`${prizeDropPromoCSSNS}-panel--selected`}
      >
        {!isEmpty(rewards) ? (
          <PrizeDropPromoGiftItemsContainer iconResource={iconResource} rewards={rewards} />
        ) : (
          <NoDataMessage message={t(Promotions.PrizeDrop.noRewards)} />
        )}
      </TabPanel>

      <TabPanel
        className={`${prizeDropPromoCSSNS}-panel custom-scrollbars`}
        selectedClassName={`${prizeDropPromoCSSNS}-panel--selected`}
      >
        <>
          {!isEmpty(rewardedPlayers) ? (
            <PrizeDropPromoTabsSecondary
              onShowAllClick={handleModalOpen}
              personalRewards={personalRewards}
              rewardedPlayers={rewardedPlayers}
            />
          ) : (
            <NoDataMessage message={t(Promotions.PrizeDrop.norewardedPlayers)} />
          )}
        </>
      </TabPanel>
    </Tabs>
  );
};

export default PrizeDropPromoTabsPrimary;
