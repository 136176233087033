import React, { memo } from 'react';
import isEqual from 'lodash/isEqual';
import { Flex } from 'modules/casino/shared/components/layout';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { BreadcrumbActionComponents } from './components/breadcrumAction/BreadCrumbActions';
import WidgetBreadCrumbActionComponent from './components/breadcrumAction/widgetBreadCrumbActions/WidgetBreadCrumbActionComponent';
import { SubtitleComponents } from './components/headerSubtitles/HeaderSubtitles';
import { TitleComponents } from './components/headerTitles/TitleComponents';
import { CategoryLayoutTypes } from './types/CategoryLayoutTypes';

interface CategoryBreadcrumbLayoutProps {
  categoryId: string;
  type: CategoryLayoutTypes;
}

const CategoryBreadcrumbLayout: React.FC<CategoryBreadcrumbLayoutProps> = ({ categoryId, type }) => {
  const styleType = type.toLowerCase();
  const { widget } = useGetCategoryParams(categoryId, type);

  const renderCategoryTitle = () => {
    const CategoryTitleComponent = TitleComponents[type];
    if (!CategoryTitleComponent) {
      return <></>;
    }

    return <CategoryTitleComponent categoryId={categoryId} categoryLayoutType={type} />;
  };

  const renderBreadCrumbActions = () => {
    if (widget?.id) {
      return <WidgetBreadCrumbActionComponent widget={widget} categoryLayoutType={type} />;
    }

    const CategoryActionComponent = BreadcrumbActionComponents[type];

    if (!CategoryActionComponent) {
      return <></>;
    }

    return <CategoryActionComponent categoryId={categoryId} categoryLayoutType={type} />;
  };

  const renderCategorySubtitle = () => {
    const CategorySubtitleComponent = SubtitleComponents[type];

    if (!CategorySubtitleComponent) {
      return <></>;
    }

    return <CategorySubtitleComponent categoryId={categoryId} categoryLayoutType={type} />;
  };

  return (
    <>
      <Flex.ACJB className={`${styleType}-category-header`}>
        {renderCategoryTitle()}
        <div className={'category-breadcrumb-action'}>{renderBreadCrumbActions()}</div>
      </Flex.ACJB>
      {renderCategorySubtitle()}
    </>
  );
};

export default memo(CategoryBreadcrumbLayout, isEqual);
