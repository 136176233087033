import React from 'react';
import { StatusIcon } from 'components/shared/icons';
import { isEmpty } from 'utils/common/helpersCommon';
import { BetStatuses, SelectionStatuses } from '../../types/myBets.types';

const renderStatus = {
  [SelectionStatuses.WON]() {
    return <StatusIcon size="xs" className="mr-2" status="positive" />;
  },
  [SelectionStatuses.LOST]() {
    return <StatusIcon size="xs" className="mr-2" status="negative" />;
  },
  [SelectionStatuses.VOID]() {
    return <StatusIcon size="xs" className="mr-2" status="neutral" />;
  },
  [SelectionStatuses.WON_VOID]() {
    return <StatusIcon size="xs" className="mr-2" status="positive" />;
  },
  [SelectionStatuses.LOSS_VOID]() {
    return <StatusIcon size="xs" className="mr-2" status="negative" />;
  },
  [SelectionStatuses.CANCELED]() {
    return <StatusIcon size="xs" className="mr-2" status="neutral" />;
  },
};

interface Props {
  status: keyof typeof SelectionStatuses | null | undefined;
  betStatus: BetStatuses;
}

const SelectionStatusIcon: React.FC<Props> = ({ status, betStatus }) => {
  if (betStatus === BetStatuses.CANCELED) return renderStatus['CANCELED']();
  if (isEmpty(status)) return null;
  return renderStatus[status]();
};

export default SelectionStatusIcon;
