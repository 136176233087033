import React from 'react';
import clsx from 'clsx';
import Icon from 'components/shared/Icon';

/** EventUI Link */
interface LinkProps {
  linkType: 'live-stats' | 'live-stream';
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

export const Link: React.FC<LinkProps> = ({ linkType, className, onClick }) => {
  const iconClass = clsx('btn-event-link__icon', className);
  return <Icon className={iconClass} defaultClassName={false} icon={linkType} onClick={onClick} />;
};
