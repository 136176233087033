import styled from 'styled-components';
import { Breakpoints } from 'theme/Theme';

export const Bell = styled.img`
  z-index: 10;

  grid-row: 1 / -1;
  grid-column: 1 / -1;
  place-self: start center;

  margin-top: 98px;
  width: 110px;
  height: 110px;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    width: 110px;
    height: 110px;
  }
  transition:
    width 0.15s,
    height 0.15s;
`;
