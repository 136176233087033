import React, { useEffect } from 'react';
import { SettingsThunks } from 'pages/myAccount/tabs/settings/slice/settings.slice';
import Languages from 'shared/common/features/appFooter/components/Languages';
import { Odds } from 'shared/common/features/appFooter/components/Odds';
import { TimeZones } from 'shared/common/features/appFooter/components/TimeZones';
import { cssNS } from 'shared/common/features/burger/components/BurgerUI/burgerUI.types';
import { BurgerMenuLayoutMenuItems02 } from 'shared/common/features/navigationMenu/types/navigationMenu.types';
import { useAppDispatch } from 'store';

const FastSettingsDrawerSection: React.FC<{ section: BurgerMenuLayoutMenuItems02 }> = ({ section }) => {
  const dispatch = useAppDispatch();

  const SETTINGS_SECTIONS = {
    language: Languages,
    odds: Odds,
    timezones: TimeZones,
  };

  const renderOptions = (sections, isDrawer: boolean, options) => {
    const SettingsBlock = SETTINGS_SECTIONS[sections.name];
    return SettingsBlock ? <SettingsBlock section={sections} isDrawer={isDrawer} options={options} /> : <></>;
  };

  useEffect(() => {
    dispatch(SettingsThunks.fetchOdds());
  }, []);

  return (
    <div className={`${cssNS}-settings`}>
      {section?.options?.map((option, index) => (
        <div className={`${cssNS}-dropdown-wrap d-flex-col`} key={index}>
          <label>{option.text === null ? 'Time' : option?.text}</label>
          <>{renderOptions(option, true, option?.items)}</>
        </div>
      ))}
    </div>
  );
};

export default FastSettingsDrawerSection;
