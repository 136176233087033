import { useEffect, useState } from 'react';
import { AnimationControls, useAnimation } from 'framer-motion';

const useTransitionTabs = <T>(tabs?: T[] | null): { initialOpacity: number; controls: AnimationControls } => {
  const [initialOpacity, setInitialOpacity] = useState(0);

  const controls = useAnimation();
  useEffect(() => {
    controls.start((i) => ({
      opacity: 1,
      transition: { delay: i * 0.1 },
    }));
  }, [controls]);

  useEffect(() => {
    setInitialOpacity(1);
  }, [tabs]);

  return { initialOpacity, controls };
};

export default useTransitionTabs;
