import React, { memo } from 'react';
import { TFunction } from 'i18n';
import { formatCurrency } from 'modules/retail/modules/cashier/utils';

interface Props {
  t: TFunction;
  totalAmount: number;
  retailStatus?: string;
  isCurrencyBeforeAmount?: boolean;
  currencySymbol?: string;
}

const BetSummaryTerminalProfit: React.FC<Props> = ({
  t,
  currencySymbol,
  retailStatus,
  totalAmount,
  isCurrencyBeforeAmount,
}) => {
  return (
    <div>
      {/* <span className={currencyClassName}> {currencySymbol}</span> */}
      {retailStatus === 'ACCEPTED' && totalAmount !== 0 && (
        <>{`${t('Cashier.CashierModal.possibleProfit')}: ${formatCurrency(totalAmount, currencySymbol, isCurrencyBeforeAmount)}`}</>
      )}
    </div>
  );
};

export default memo(BetSummaryTerminalProfit);
