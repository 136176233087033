import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SecondStepDepositTerminalModal,
  FirstStepDepositSSBTmodal,
  ThirdStepDepositTerminalCash,
} from 'modules/retail/modules/ssbt/components';
import {
  selectSSBTdata,
  setActiveStep,
  setIsPopulateBetSlipFromQrCode,
} from 'modules/retail/modules/ssbt/slice/ssbt.slice';
import { selectVoucherData, setRetailVoucherInfoStatus, voucherThunk } from 'modules/retail/shared/slice/voucher.slice';
import { FormSteps } from 'pages/auth/registration/types/registration.types';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { useAppDispatch, useAppSelector } from 'store';
import LastStepDepositTerminalVoucher from './LastStepDepositSSBTvoucher';
import SecondStepDepositVoucherModal from './SecondStepDepositSSBTVoucherModal';
import ThirdStepDepositTerminalVoucher from './ThirdStepDepositSSBTVoucher';
import { TerminalModalUI } from '../../../components/ssbtModalUI';

//const cardCurrencySum = ['20', '50', '100', '150', '200', '300', '400', '500'];

const SSBTModalDeposit: React.FC = () => {
  const dispatch = useAppDispatch();
  const isQrCodeSelected = useAppSelector(selectVoucherData.isVoucherSelect);
  const activeStep = useAppSelector(selectSSBTdata.activeStep);
  const { t } = useTranslation();
  // const [activeStep, setActiveStep] = useState(FormSteps.FIRST);
  const [paymentMethod, setPaymentMethod] = useState('');
  const errorModalError = useAppSelector(selectSSBTdata.errorModalVisible);
  const { closeModal } = useHandleModals();
  const voucherCodeTerminal = useAppSelector(selectVoucherData.voucherCode);
  const isPopulateBetSlipFromQrCode = useAppSelector(selectSSBTdata.isPopulateBetSlipFromQrCode);

  useEffect(() => {
    if (isPopulateBetSlipFromQrCode) {
      dispatch(setActiveStep(1));
      dispatch(setIsPopulateBetSlipFromQrCode(false));
    }
  }, [isPopulateBetSlipFromQrCode]);

  const handleNextStep = () => {
    dispatch(setActiveStep(activeStep + 1));
  };
  const handleFirstStep = useCallback(() => {
    dispatch(setActiveStep(FormSteps.FIRST));
  }, []);

  const resetStepStateOnClose = () => {
    dispatch(setActiveStep(1));
    closeModal();
  };

  useEffect(() => {
    if (isQrCodeSelected) {
      dispatch(setRetailVoucherInfoStatus('idle'));
      dispatch(voucherThunk.getVoucherInfo({ voucherCode: encodeURIComponent(voucherCodeTerminal) }));
      setPaymentMethod('voucher');
      dispatch(setActiveStep(3));
    }
  }, [isQrCodeSelected, voucherCodeTerminal]);

  const renderSecondStepDepositModal = {
    cash: <SecondStepDepositTerminalModal handleNextStep={handleNextStep} setActiveStep={setActiveStep} />,
    voucher: <SecondStepDepositVoucherModal setActiveStep={setActiveStep} />,
  };

  const renderThirdStedDepositModal = {
    cash: <ThirdStepDepositTerminalCash handleFirstStep={handleFirstStep} />,
    voucher: <ThirdStepDepositTerminalVoucher voucherValue={voucherCodeTerminal} setActiveStep={setActiveStep} />,
  };

  const renderStepContainer = (step) => {
    switch (step) {
      case 1:
        return <FirstStepDepositSSBTmodal handleNextStep={handleNextStep} setPaymentMethod={setPaymentMethod} />;
      case 2:
        return renderSecondStepDepositModal[paymentMethod];
      case 3:
        return renderThirdStedDepositModal[paymentMethod];
      case 4:
        return <LastStepDepositTerminalVoucher setActiveStep={setActiveStep} />;
      default:
        return null;
    }
  };

  // const renderTerminalOffModal = () => {
  //   return (
  //     <>
  //       {/* ----- CHOOSE PAYMENT METHOD ----- */}
  //       {/* <TerminalModal.Header text="Изберете метод за депозит:" />
  //       <Flex.Center>
  //         <TerminalClickable icons={['VISA', 'MASTER_CARD']} text="Кредитна карта" onClick={() => {}} />
  //         <TerminalClickable icons={['CASH']} text="Пари в брой" onClick={() => {}} />
  //         <TerminalClickable icons={['VOUCHER']} text="Кредитна карта" onClick={() => {}} />
  //       </Flex.Center> */}

  //       {/* ----- CARD DEPOSIT ----- */}
  //       {/* <TerminalModal.Header text="Изберете сумата която искате да депозирате:" />
  //       <Flex.AS className='terminal-deposit-card'>

  //         <Flex.AC className='terminal-deposit-card__left'>
  //           {cardCurrencySum.map((currency) => (
  //             <TerminalClickable key={currency} text={`${currency}${currencySymbol}`} variant='sm' onClick={voidClick} />
  //           ))}
  //         </Flex.AC>
  //         <Flex.ColCenter className='terminal-deposit-card__right'>
  //           <TerminalModal.SubHeader text="Изберете друга сума" />

  //           <TerminalInputGroup
  //             name="some-name"
  //             labelSpecifier="Мин. сума: 10.00лв"
  //             placeholder="00.00"
  //             prependText='ЛЕВА'
  //             // appendIcon="MASTER_CARD"
  //             type="number"
  //           />
  //           <KeyboardNumeric variant='deposit' />
  //         </Flex.ColCenter>
  //       </Flex.AS>
  //       <Flex.ColAC>
  //         <TerminalModal.Button text="Депозирай" textSecondary="00.00 лв" onClick={voidClick} />
  //         <TerminalModal.Link text="Изберете друг метод за депозит" onClick={voidClick} />

  //       </Flex.ColAC> */}
  //     </>
  //   );
  // };

  return errorModalError ? null : (
    <TerminalModalUI.Wrapper isVisible={true}>
      <TerminalModalUI.Close text={t(`SSBT.TerminalModal.closeTerminalModalText`)} onClick={resetStepStateOnClose} />
      <TerminalModalUI.Body>{renderStepContainer(activeStep)}</TerminalModalUI.Body>
    </TerminalModalUI.Wrapper>
  );
};

export default SSBTModalDeposit;
