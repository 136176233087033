import React, { useLayoutEffect, useRef } from 'react';
import { useProviderDataById } from 'modules/casino/modules/container/shared/features/casinoGameProviders/hooks/useProviderDataById';
import { useAppSelector } from 'store';
import JackpotLastWinner from './JackpotLastWinner';
import { jackpotSelectors } from '../jackpotSlice';

interface Props {
  jackpotId: number | string;
  level: string;
  providerId: string;
  currency: string;
  fromWidget?: boolean;
  hideTooltip?: boolean;
}

const JackpotTooltip: React.FC<Props> = ({
  jackpotId,
  level,
  providerId,
  currency,
  fromWidget = false,
  hideTooltip = false,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { code: providerCode } = useProviderDataById(providerId);
  const winDetails = useAppSelector((state) => jackpotSelectors.selectJackpotWinDetails(state, jackpotId, level));

  useLayoutEffect(() => {
    if (ref.current) {
      if (fromWidget) {
        const tooltipPosition = ref.current.getBoundingClientRect().left + ref.current.getBoundingClientRect().width;
        if (window.innerWidth - tooltipPosition < 0) {
          ref.current.style.left = 'auto';
          ref.current.style.right = '100%';
          ref.current.classList.remove('left-arrow');
          ref.current.classList.add('right-arrow');
        } else {
          ref.current.style.right = 'auto';
          ref.current.style.left = '100%';
          ref.current.classList.remove('right-arrow');
          ref.current.classList.add('left-arrow');
        }
        return;
      }
      const tooltipBottom = ref.current.getBoundingClientRect().bottom;
      if (window.innerHeight - tooltipBottom < 0) {
        ref.current.style.top = 'auto';
        ref.current.style.bottom = '100%';
        ref.current.classList.remove('top-arrow');
        ref.current.classList.add('bottom-arrow');
      } else {
        ref.current.style.top = '100%';
        ref.current.style.bottom = 'auto';
        ref.current.classList.remove('bottom-arrow');
        ref.current.classList.add('top-arrow');
      }
    }
  }, [ref, fromWidget]);

  return !hideTooltip && winDetails && winDetails.hasWinners ? (
    <div className="jackpot-modal" ref={ref}>
      <JackpotLastWinner winDetails={winDetails} providerCode={providerCode} currency={currency} />
    </div>
  ) : (
    <></>
  );
};

export default JackpotTooltip;
