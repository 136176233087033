import React from 'react';
import { Flex } from 'components/shared/layout';

/** BetSeletionUI CustomBet Wrapper */

const CustomBetWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <Flex.Col className="p-2 w-100">{children}</Flex.Col>;
};

export default CustomBetWrapper;
