import { t } from 'i18next';
import { setIsPrintingEjected } from 'modules/retail/modules/cashier/slice/cashier.slice';
import { PrintCashPay } from 'modules/retail/modules/cashier/types/cashier.types';
import { formatCurrency } from 'modules/retail/modules/cashier/utils';
import { enqueuePrintRequest, processPrintQueue } from 'modules/retail/shared/slice/printQueue.slice';
import { CommandData } from 'modules/retail/shared/types/retailCommon.types';
import {
  commands,
  monitorPrintingCompletion,
  printEjectCommand,
  printMethods,
} from 'modules/retail/shared/utils/retail.utils';
import { store } from 'store';
import { getError, isEmpty, retailFormatDate } from 'utils/common/helpersCommon';
import { makeRequest } from '../../../shared/printMethods/printUtils/helperPrintFunctions';
import { _logoOptionsData, winbetOptions } from '../../../shared/printMethods/printUtils/printReceiptOptions';

export const printCashPayDeposit = async ({
  data,
  address,
  posAddress,
  transferId,
  numOfCopies,
  isCurrencyBeforeAmount,
}: PrintCashPay) => {
  const printCashPayDepositJob = async () => {
    const printDepositData: CommandData[] = [];
    printMethods.printIsReady(printDepositData, commands);
    printMethods.printStart(printDepositData, commands);
    printMethods.print(printDepositData, commands, '');
    printMethods.printOptions(printDepositData, commands, _logoOptionsData);
    printMethods.printImage(printDepositData, commands, address?.buLogo);
    printMethods.print(printDepositData, commands, '');
    printMethods.printOptions(printDepositData, commands, winbetOptions);
    printMethods.print(printDepositData, commands, t('Cashier.CashPayModal.depositText'));
    printMethods.print(printDepositData, commands, `${t('Cashier.CashPayModal.transferId')}: ${transferId}`);
    printMethods.print(printDepositData, commands, '');
    printMethods.print(printDepositData, commands, ''.padEnd(44, '='));
    printMethods.print(printDepositData, commands, '');
    printMethods.print(printDepositData, commands, `${t('Cashier.CashPayModal.username')}: ${data?.username}`);
    printMethods.print(printDepositData, commands, '');
    printMethods.print(printDepositData, commands, `${t('Cashier.CashPayModal.playerId')}: ${data?.playerId}`);
    printMethods.print(printDepositData, commands, '');
    printMethods.print(printDepositData, commands, `${t('Cashier.CashPayModal.name')}: ${data?.firstName}`);
    printMethods.print(printDepositData, commands, '');
    printMethods.print(printDepositData, commands, `${t('Cashier.CashPayModal.surname')}: ${data?.lastName}`);
    printMethods.print(printDepositData, commands, '');
    printMethods.print(
      printDepositData,
      commands,
      `${t('Cashier.CashPayModal.depositAmount')}: ${formatCurrency(data?.amount, data?.currency, isCurrencyBeforeAmount)}`,
    );
    printMethods.print(printDepositData, commands, '');
    printMethods.print(
      printDepositData,
      commands,
      `${t('Cashier.CashPayModal.date')}: ${retailFormatDate(Date.now())}`,
    );
    printMethods.print(printDepositData, commands, '');
    printMethods.print(printDepositData, commands, ''.padEnd(44, '='));
    printMethods.print(printDepositData, commands, '');
    printMethods.print(printDepositData, commands, `${t(address?.companyName)}`);
    printMethods.print(printDepositData, commands, `${t('Cashier.CashPayModal.address')}:`); //   // { command: commands.colDefine, bodyData: { width: 290, v_alignment: 'middle', h_alignment: 'right' } },
    if (!isEmpty(posAddress)) {
      printMethods.print(printDepositData, commands, `${posAddress?.streetNumber}, ${posAddress?.streetName}`);
      printMethods.print(printDepositData, commands, `${posAddress?.city}, ${posAddress?.country}`);
    }
    printMethods.print(printDepositData, commands, ''.padEnd(44, ''));
    printMethods.print(printDepositData, commands, '');
    printMethods.print(
      printDepositData,
      commands,
      `${t('Cashier.CashPayModal.cashierSignature')}: ......................`,
    );
    printMethods.print(printDepositData, commands, '');
    printMethods.print(printDepositData, commands, ''.padEnd(44, '='));
    printMethods.print(
      printDepositData,
      commands,
      `${t('Cashier.CashPayModal.amountBeforeTax')}: ${formatCurrency(data?.amount, data?.currency, isCurrencyBeforeAmount)}`,
    );
    printMethods.print(printDepositData, commands, `${t('Cashier.CashPayModal.tax')}: ${data?.taxAmount}%`);
    printMethods.print(
      printDepositData,
      commands,
      `${t('Cashier.CashPayModal.amountAfterTax')}: ${formatCurrency(data?.amountAfterTax, data?.currency, isCurrencyBeforeAmount)}`,
    );
    printMethods.print(printDepositData, commands, '');
    printMethods.print(printDepositData, commands, ''.padEnd(44, '='));
    printMethods.print(printDepositData, commands, '');
    printMethods.print(printDepositData, commands, '');
    printMethods.print(printDepositData, commands, `${t('Cashier.CashPayModal.clientSignature')}: ...................`);
    printMethods.print(printDepositData, commands, '');
    printMethods.print(printDepositData, commands, '');
    printMethods.printCopies(printDepositData, commands, numOfCopies);
    printMethods.printEject(printDepositData, commands);

    try {
      for (const i of printDepositData) {
        await makeRequest(i);
        if (i.command === printEjectCommand) {
          await monitorPrintingCompletion();
        }
      }
    } catch (error) {
      getError.message(error);
    } finally {
      store.dispatch(setIsPrintingEjected(false));
      // Process the next job in the queue if any
      store.dispatch(processPrintQueue());
    }
  };

  store.dispatch(enqueuePrintRequest(printCashPayDepositJob));

  // Check if the current printing state and process the queue if no job is currently running
  const state = store.getState();
  if (!state.retail.printQueue.isPrinting) {
    store.dispatch(processPrintQueue());
  }
};

export const printCashPayWithdraw = async ({
  data,
  address,
  posAddress,
  transferId,
  numOfCopies,
  isCurrencyBeforeAmount,
}: PrintCashPay) => {
  const printCashPayWithdrawJob = async () => {
    const printWithdwawData: CommandData[] = [];
    printMethods.printIsReady(printWithdwawData, commands);
    printMethods.printStart(printWithdwawData, commands);
    printMethods.print(printWithdwawData, commands, '');
    printMethods.printOptions(printWithdwawData, commands, _logoOptionsData);
    printMethods.printImage(printWithdwawData, commands, address?.buLogo);
    printMethods.print(printWithdwawData, commands, '');
    printMethods.printOptions(printWithdwawData, commands, winbetOptions);
    printMethods.print(printWithdwawData, commands, t('Cashier.CashPayModal.withdrawText'));
    printMethods.print(printWithdwawData, commands, `${t('Cashier.CashPayModal.transferId')}: ${transferId}`);
    printMethods.print(printWithdwawData, commands, '');
    printMethods.print(printWithdwawData, commands, ''.padEnd(44, '='));
    printMethods.print(printWithdwawData, commands, '');
    printMethods.print(printWithdwawData, commands, `${t('Cashier.CashPayModal.username')}: ${data?.username}`);
    printMethods.print(printWithdwawData, commands, '');
    printMethods.print(printWithdwawData, commands, `${t('Cashier.CashPayModal.playerId')}: ${data?.playerId}`);
    printMethods.print(printWithdwawData, commands, '');
    printMethods.print(printWithdwawData, commands, `${t('Cashier.CashPayModal.name')}: ${data?.firstName}`);
    printMethods.print(printWithdwawData, commands, '');
    printMethods.print(printWithdwawData, commands, `${t('Cashier.CashPayModal.surname')}: ${data?.lastName}`);
    printMethods.print(printWithdwawData, commands, '');
    printMethods.print(
      printWithdwawData,
      commands,
      `${t('Cashier.CashPayModal.withdrawAmount')}: ${formatCurrency(data?.amount, data?.currency, isCurrencyBeforeAmount)}`,
    );
    printMethods.print(printWithdwawData, commands, '');
    printMethods.print(
      printWithdwawData,
      commands,
      `${t('Cashier.CashPayModal.date')}: ${retailFormatDate(Date.now())}`,
    );
    printMethods.print(printWithdwawData, commands, '');
    printMethods.print(printWithdwawData, commands, ''.padEnd(44, '='));
    printMethods.print(printWithdwawData, commands, '');
    printMethods.print(printWithdwawData, commands, `${t(address?.companyName)}`);
    printMethods.print(printWithdwawData, commands, `${t('Cashier.CashPayModal.address')}:`); //   // { command: commands.colDefine, bodyData: { width: 290, v_alignment: 'middle', h_alignment: 'right' } },
    printMethods.print(printWithdwawData, commands, `${posAddress?.streetNumber}, ${posAddress?.streetName}`);
    printMethods.print(printWithdwawData, commands, `${posAddress?.city}, ${posAddress?.country}`);
    printMethods.print(printWithdwawData, commands, ''.padEnd(44, ''));
    printMethods.print(printWithdwawData, commands, '');
    printMethods.print(
      printWithdwawData,
      commands,
      `${t('Cashier.CashPayModal.cashierSignature')}: ......................`,
    );
    printMethods.print(printWithdwawData, commands, '');
    printMethods.print(printWithdwawData, commands, ''.padEnd(44, '='));
    printMethods.print(
      printWithdwawData,
      commands,
      `${t('Cashier.CashPayModal.amountBeforeTax')}: ${formatCurrency(data?.amount, data?.currency, isCurrencyBeforeAmount)}`,
    );
    printMethods.print(printWithdwawData, commands, `${t('Cashier.CashPayModal.tax')}: ${data?.taxAmount}%`);
    printMethods.print(
      printWithdwawData,
      commands,
      `${t('Cashier.CashPayModal.amountAfterTax')}: ${formatCurrency(data?.amountAfterTax, data?.currency, isCurrencyBeforeAmount)}`,
    );
    printMethods.print(printWithdwawData, commands, '');
    printMethods.print(printWithdwawData, commands, ''.padEnd(44, '='));
    printMethods.print(printWithdwawData, commands, '');
    printMethods.print(printWithdwawData, commands, '');
    printMethods.print(
      printWithdwawData,
      commands,
      `${t('Cashier.CashPayModal.clientSignature')}: ...................`,
    );
    printMethods.print(printWithdwawData, commands, '');
    printMethods.print(printWithdwawData, commands, '');
    printMethods.printCopies(printWithdwawData, commands, numOfCopies);
    printMethods.printEject(printWithdwawData, commands);

    try {
      for (const i of printWithdwawData) {
        await makeRequest(i);
        if (i.command === printEjectCommand) {
          await monitorPrintingCompletion();
        }
      }
    } catch (error) {
      getError.message(error);
    } finally {
      store.dispatch(setIsPrintingEjected(false));
      // Process the next job in the queue if any
      store.dispatch(processPrintQueue());
    }
  };

  store.dispatch(enqueuePrintRequest(printCashPayWithdrawJob));

  // Check if the current printing state and process the queue if no job is currently running
  const state = store.getState();
  if (!state.retail.printQueue.isPrinting) {
    store.dispatch(processPrintQueue());
  }
};
