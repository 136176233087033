import React from 'react';
import clsx from 'clsx';

/** Terminal Modal */
interface WrapperProps {
  children: React.ReactNode;
  isVisible: boolean;
}

const Wrapper: React.FC<WrapperProps> = ({ children, isVisible }) => {
  const modalClass = clsx('modal fade', isVisible && 'show d-block');

  const modalDialogClass = clsx('modal-dialog', 'cashier-modal__dialog custom-scrollbars');
  const modalContentClass = clsx('modal-content');

  return (
    <div className={modalClass} tabIndex={-1} role="dialog">
      <div className="modal-backdrop">
        <div className={modalDialogClass} role="document">
          <div className={modalContentClass}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Wrapper;
