import { RefObject, useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { TFunction } from 'i18n';
import useCasinoWidgetConfig from 'modules/casino/platformImplementation/hooks/useCasinoWidgetConfig';
import { useAppDispatch, useAppSelector } from 'store';
import { NavBarTranslations } from 'utils/common/translationUtils/translationStrings';
import { LocationType } from 'utils/common/types/common.types';
import { navigateToCasinoIntegration, navigateToIntegration } from '../../integrations/utils/integrations.utils';
import { SearchType, selectActiveSearchType } from '../components/common/slice/navSearch.slice';
import { useActiveSearchType } from '../components/mainNavbar/hooks/useActiveSearchType';

const searchPlaceholderMap = {
  [SearchType.Casino]: NavBarTranslations.Search.casinoPlaceholder,
  [SearchType.Sport]: NavBarTranslations.Search.sportPlaceholder,
};

type ReturnTypeSearch = {
  query;
  handleCloseSearch: () => void;
  handleMobileClear: () => void;
  handleOpenSearch: () => void;
  search: (text?: string) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  t: TFunction;
  searchPlaceholder: string;
};

export const useSearch = (inputRef: RefObject<HTMLInputElement>, searchType?: SearchType): ReturnTypeSearch => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation() as LocationType<{ internalNavigate?: boolean }>;
  const { t } = useTranslation();
  const currentRoute = location.pathname.replace(/^./, '');
  useActiveSearchType();
  const casinoWidgetConfig = useCasinoWidgetConfig();
  const activeSearchType = useAppSelector(selectActiveSearchType);
  const [query, setQuery] = useSearchParams();
  // const queryParam = query.get('query');

  const SearchTypesConfig = useMemo(
    () => ({
      [SearchType.Casino]: {
        defaultCloseRoute: '/casino',
        route: SearchType.Casino,
        handleOnChange: null,
        reset: null,
      },
      [SearchType.Sport]: {
        defaultCloseRoute: '/sports',
        route: SearchType.Sport,
        handleOnChange: null,
        reset: null,
      },
    }),
    [],
  );
  const config = SearchTypesConfig[searchType ?? activeSearchType];

  // useEffect(() => {
  //   queryParam && config.handleOnChange && dispatch(config.handleOnChange(queryParam));
  // }, [activeSearchType]);

  const handleOnChange = (value: string) => {
    const trimmedVal = value.replace(/\s\s+/g, ' ');
    // config.handleOnChange && dispatch(config.handleOnChange(trimmedVal));

    setQuery({ query: trimmedVal }, { replace: true });
    // window.history.replaceState({}, '', location.pathname + `?query=${trimmedVal}`);
  };

  const search = debounce((text) => {
    handleOnChange(text);
  }, 550);

  const handleOpenSearch = useCallback(() => {
    // config.handleOnChange && dispatch(config.handleOnChange(''));

    if (currentRoute === config.route) return;
    if (activeSearchType === SearchType.Sport) {
      navigateToIntegration({ fullPath: config.route });
    }

    if (activeSearchType === SearchType.Casino && casinoWidgetConfig) {
      navigateToCasinoIntegration({ fullPath: config.route });
    }
    navigate(config.route, { state: { internalNavigate: searchType === SearchType.Casino } });
  }, [dispatch, config, activeSearchType, searchType, location.pathname]);

  const handleCloseSearch = useCallback(() => {
    if (location.key === 'default') {
      navigate(config.defaultCloseRoute);
      if (activeSearchType === SearchType.Sport) {
        navigateToIntegration({ fullPath: config.defaultCloseRoute });
      }

      return;
    }
    navigate(-1);
  }, [location.state?.internalNavigate, navigate, searchType, dispatch, searchType, activeSearchType]);

  const handleMobileClear = useCallback(() => {
    // config.handleOnChange && dispatch(config.handleOnChange(''));
    if (inputRef.current) inputRef.current.value = '';
  }, [dispatch, inputRef, searchType, activeSearchType]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLElement).blur();
    }
  }, []);

  const searchPlaceholder = searchPlaceholderMap[activeSearchType];

  return { query, handleCloseSearch, handleMobileClear, handleKeyDown, handleOpenSearch, search, t, searchPlaceholder };
};
