import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getBusinessUnit } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { CashPayTypes } from '../types/cashPay.types';

const initialState: CashPayTypes.InitialState = {
  verifyPlayerDeposit: null,
  verifyPlayerDepositStatus: 'idle',
  processDeposit: null,
  processDepositStatus: 'idle',
  verifyWithdraw: null,
  verifyWithdrawStatus: 'idle',
  withdrawComplete: null,
  withdrawStatus: 'idle',
};

export const cashPayThunks = {
  verifyPlayerDeposit: createAbortThunk(
    `cashPay/depositTerminal`,
    async ({ amount, ucn }: { amount: number; ucn: string }) => {
      const url = `${config.API_URL}/api/cashpay/v1/agent/verify-player-deposit`;
      const data = {
        amount,
        businessUnit: getBusinessUnit(),
        ucn,
      };
      const response = await axiosInstance.post(url, data);
      return response.data;
    },
  ),
  processDeposit: createAbortThunk(
    `cashPay/processDeposit`,
    async ({
      amount,
      currency,
      playerId,
      processInstanceId,
    }: {
      amount: number;
      currency: string;
      playerId: string;
      processInstanceId: string;
    }) => {
      const url = `${config.API_URL}/api/cashpay/v1/agent/process-deposit`;
      const data = {
        amount,
        currency,
        playerId,
        processInstanceId,
      };
      const response = await axiosInstance.post(url, data);
      return response.data;
    },
  ),
  verifyWithdrawId: createAbortThunk(`cashPay/verifyWithdrawId`, async ({ id }: { id: string }) => {
    const url = `${config.API_URL}/api/cashpay/v1/agent/get-withdrawal`;
    const params = {
      externalTransferId: id,
    };
    const response = await axiosInstance.get(url, { params });
    return response.data;
  }),
  withdrawComplete: createAbortThunk(`cashPay/withdrawComplete`, async ({ id }: { id: string }) => {
    const url = `${config.API_URL}/api/cashpay/v1/agent/complete-withdrawal`;
    const data = {
      externalTransferId: id,
    };
    const response = await axiosInstance.post(url, data);
    return response.data;
  }),
};

const cashPaySlice = createSlice({
  name: 'cashPaySlice',
  initialState,
  reducers: {
    clearDeposit: (state) => {
      state.verifyPlayerDeposit = null;
      state.verifyPlayerDepositStatus = 'idle';
      state.processDeposit = null;
      state.processDepositStatus = 'idle';
    },
    clearWithdraw: (state) => {
      state.verifyWithdraw = null;
      state.verifyWithdrawStatus = 'idle';
      state.withdrawComplete = null;
      state.withdrawStatus = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(cashPayThunks.verifyPlayerDeposit.fulfilled, (state, action) => {
      const { playerVerificationResponseDto } = action.payload;
      state.verifyPlayerDeposit = playerVerificationResponseDto;
      state.verifyPlayerDepositStatus = 'succeeded';
    });
    builder.addCase(cashPayThunks.verifyPlayerDeposit.pending, (state) => {
      state.verifyPlayerDepositStatus = 'pending';
    });
    builder.addCase(cashPayThunks.verifyPlayerDeposit.rejected, (state) => {
      state.verifyPlayerDepositStatus = 'failure';
      state.verifyPlayerDeposit = null;
    });
    builder.addCase(cashPayThunks.processDeposit.fulfilled, (state, action) => {
      const { cashpayDepositResponse } = action.payload;
      state.processDeposit = cashpayDepositResponse;
      state.processDepositStatus = 'succeeded';
    });
    builder.addCase(cashPayThunks.processDeposit.pending, (state) => {
      state.processDepositStatus = 'pending';
    });
    builder.addCase(cashPayThunks.processDeposit.rejected, (state) => {
      state.processDepositStatus = 'failure';
      state.processDeposit = null;
    });
    builder.addCase(cashPayThunks.verifyWithdrawId.fulfilled, (state, action) => {
      state.verifyWithdraw = action.payload;
      state.verifyWithdrawStatus = 'succeeded';
    });
    builder.addCase(cashPayThunks.verifyWithdrawId.pending, (state) => {
      state.verifyWithdrawStatus = 'pending';
    });
    builder.addCase(cashPayThunks.verifyWithdrawId.rejected, (state) => {
      state.verifyWithdrawStatus = 'failure';
      state.verifyWithdraw = null;
    });
    builder.addCase(cashPayThunks.withdrawComplete.fulfilled, (state, action) => {
      state.withdrawComplete = action.payload;
      state.withdrawStatus = 'succeeded';
    });
    builder.addCase(cashPayThunks.withdrawComplete.pending, (state) => {
      state.withdrawStatus = 'pending';
    });
    builder.addCase(cashPayThunks.withdrawComplete.rejected, (state) => {
      state.withdrawStatus = 'failure';
      state.withdrawComplete = null;
    });
  },
});

export const { clearDeposit, clearWithdraw } = cashPaySlice.actions;

export default cashPaySlice.reducer;

export const selectCashPay = {
  verifyPlayerDeposit: (state: RootState) => state.retail.cashPay.verifyPlayerDeposit,
  verifyPlayerDepositStatus: (state: RootState) => state.retail.cashPay.verifyPlayerDepositStatus,
  processDeposit: (state: RootState) => state.retail.cashPay.processDeposit,
  processDepositStatus: (state: RootState) => state.retail.cashPay.processDepositStatus,
  verifyWithdraw: (state: RootState) => state.retail.cashPay.verifyWithdraw,
  verifyWithdrawStatus: (state: RootState) => state.retail.cashPay.verifyWithdrawStatus,
  withdrawComplete: (state: RootState) => state.retail.cashPay.withdrawComplete,
  withdrawStatus: (state: RootState) => state.retail.cashPay.withdrawStatus,
};
