import { MutableRefObject, useEffect } from 'react';
import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import { GameLayoutEnum } from 'modules/casino/modules/container/cellsContainer/cellls/types/casinoGamesTypes';
import { useGridLayoutConfig } from 'modules/casino/modules/container/cellsContainer/grid/components/gridCell/hooks/useGridLayoutConfig';
import { LIVE_CASINO_DEFAULT_URL } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellMedia/gameCellMediaConstants';
import {
  createLiveImageSource,
  createStaticImageSource,
  getDefaultImage,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellMedia/gameCellMediaUtils';
import { imageQuality } from 'modules/casino/shared/constants';
import { GridLayouts } from '../../../../../types/gridTypes';

export const useImage = ({
  gameUniqueId,
  categoryId,
  layoutGrid,
  imageModalRef,
  imgRef,
}: {
  gameUniqueId: string;
  categoryId: string;
  layoutGrid: GridLayouts;
  imageModalRef: MutableRefObject<HTMLDivElement | null>;
  imgRef: MutableRefObject<HTMLImageElement | null>;
}): {
  handleError: () => void;
} => {
  const { refGameId, providerId, gameResource, liveImageLocation } = useGameProps({
    gameUniqueId,
    categoryId,
  });
  const { cdnPathLayout, cdnPath } = useGridLayoutConfig(layoutGrid);

  const getImageSource = () => {
    const { clientWidth, clientHeight } = imageModalRef?.current || {};

    if (clientWidth && clientHeight) {
      return liveImageLocation
        ? createLiveImageSource({ liveImageLocation, defaultUrl: LIVE_CASINO_DEFAULT_URL })
        : createStaticImageSource({
            refGameId,
            cdnPathLayout,
            cdnPath,
            providerId,
            imageQuality,
            gameLayout: GameLayoutEnum.HORIZONTAL,
            width: clientWidth,
            height: clientHeight,
            liveDefaultImg: gameResource ? LIVE_CASINO_DEFAULT_URL : '',
          });
    }

    return [
      getDefaultImage({
        gameLayout: GameLayoutEnum.HORIZONTAL,
        liveDefaultImg: gameResource ? LIVE_CASINO_DEFAULT_URL : '',
      }),
    ];
  };

  useEffect(() => {
    const srcList = getImageSource();

    if (imgRef.current && srcList[0]) {
      imgRef.current.src = `${srcList[0]}${liveImageLocation ? `?z=${new Date().getTime()}` : ''}`;
    }
  }, []);

  const handleError = () => {
    const srcList = getImageSource();

    if (imgRef.current && srcList[1]) {
      imgRef.current.src = srcList[1];
    }
  };

  return { handleError };
};
