import { useCallback, useEffect } from 'react';
import { setIsCasinoWidgetScriptLoaded } from 'shared/common/features/integrations/slice/integrations.slice';
import { isScriptAttached } from 'shared/common/features/integrations/smartico/utils/smartico.utils';
import { useAppDispatch } from 'store';
import { getWindowConfigParam, isProductionBuild } from 'utils/common/helpersCommon';
import useCasinoWidgetConfig from './useCasinoWidgetConfig';
import { casinoScriptInit } from '../utils/casinoWidget.utils';

const scriptSrc = getWindowConfigParam('CASINO_WIDGET_URL');
const epoch = !isProductionBuild && scriptSrc?.includes('localhost') ? '' : `?v=${getWindowConfigParam('EPOCH')}`;

const useCasinoWidget = () => {
  const dispatch = useAppDispatch();
  const casinoWidgetConfig = useCasinoWidgetConfig();

  const callback = useCallback(() => {
    dispatch(setIsCasinoWidgetScriptLoaded(true));
  }, [dispatch]);
  useEffect(() => {
    if (!casinoWidgetConfig) {
      return;
    }

    const isAttached = isScriptAttached('XNaveCasino');
    if (scriptSrc && !isAttached) {
      const src = `${scriptSrc}${epoch}`;
      casinoScriptInit({
        document,
        src,
        attr: 'XNaveCasino',
        id: 'egtd-cw',
        callback,
      });
    }
  }, [callback, casinoWidgetConfig]);
};

export default useCasinoWidget;
