import { CSS, cssGlobalNS } from 'utils/common/types/css.types';

export interface ButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: CSS.ButtonVariants;
  size?: CSS.Sizes;

  className?: string;
  iconClassName?: string;
  children?: React.ReactNode;
  text?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  showLoader?: boolean;

  icon?: string;
  iconPath?: string;
  qid?: string;
}
export interface HTMLButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: CSS.ButtonVariants;
  size?: CSS.Sizes;

  className?: string;
  iconClassName?: string;
  children?: React.ReactNode;
  text?: string;
  isActive?: boolean;

  icon?: string;
  iconPath?: string;
}

export const cssBtnNS = `${cssGlobalNS}-p-btn`;

export const btnIconSizesMap: Partial<Record<CSS.Sizes, number>> = {
  xs: 14,
  sm: 14,
  md: 16,
  lg: 18,
  xl: 22,
  '2xl': 24,
};
