import React, { useCallback, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { t } from 'i18next';
import { useCurrency } from 'hooks/common/useCurrency';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { MyBets } from 'pages/myAccount/tabs/history/sections/sportsHistory/types/myBets.types';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { ET } from 'utils/common/types/sportTypes';
import SSBTTicketContentHeader from './SSBTTicketContentHeader';
import BetTerminal from '../../../features/checkTicket/components/BetSSBT';
import { CheckTicket, TicketBet } from '../../../types/ssbt.types';
import Systems from '../../betSelectionUI/betLogUI/Systems';

interface SSBTTicketContentProps {
  ticketInfo: CheckTicket | null;
  duration?: number;

  betTypeText?: string;
  betSystem?: string | undefined;
  numberOfBetsText?: string;
  totalCombinations?: number;
  totalOddsText?: string;
  totalOddsToDisplay?: number | undefined;
  stakeText?: string;
  stakeAmount?: string;
  totalStakeText?: string;
  totalStakeAmount?: string;
  hasBetType?: boolean;
  systemText?: string;

  bet: ET.TerminalOutcomesEvent extends MyBets.SelectionBonusesTypes ? ET.TerminalOutcomesEvent : TicketBet;
  bets: TicketBet[];
}

const SSBTTicketContent: React.FC<SSBTTicketContentProps> = ({
  ticketInfo,
  duration = 0.3,
  bet,

  betTypeText,
  betSystem,
  numberOfBetsText,
  totalCombinations,
  totalOddsText,
  totalOddsToDisplay,
  stakeText,
  stakeAmount,
  totalStakeText,
  totalStakeAmount,
  hasBetType,
  systemText,
  bets,
}) => {
  const { currencySymbol } = useCurrency();

  const [isExpanded, setisExpanded] = useState<boolean>(true);
  const handleCollapsed = useCallback(() => {
    setisExpanded((prev) => !prev);
  }, []);

  const isCurrencyBeforeAmount = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.CURRENCY_BEFORE_AMOUNT,
  });

  return (
    <egtd-ssbt-ticket-content-wrapper>
      <SSBTTicketContentHeader
        ticketIdText={t('Cashier.CashierModal.betNumberCashier')}
        ticketId={ticketInfo?.ticket?.ticketId}
        isExpanded={isExpanded}
        icon={true}
        onClick={handleCollapsed}
      />

      <AnimatePresence initial={true}>
        {isExpanded && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            transition={{ duration: duration }}
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0.3, height: 0 },
            }}
            className="wrapper scroll-y no-scrollbars"
          >
            <egtd-ssbt-ticket-content>
              <BetTerminal bet={bet} />

              <Systems
                betTypeText={`${betTypeText}`}
                betSystem={betSystem}
                numberOfBetsText={`${numberOfBetsText}`}
                totalCombinations={Number(totalCombinations)}
                totalOddsText={`${totalOddsText}`}
                totalOddsToDisplay={totalOddsToDisplay}
                stakeText={`${stakeText}`}
                stakeAmount={Number(stakeAmount).toFixed(2)}
                totalStakeText={`${totalStakeText}`}
                totalStakeAmount={Number(totalStakeAmount).toFixed(2)}
                systemText={`${systemText}`}
                hasBetType={hasBetType}
                possibleWin={bet?.possibleWin}
                isCurrencyBeforeAmount={isCurrencyBeforeAmount}
                bet={bet}
                bets={bets}
                currency={currencySymbol}
              ></Systems>
            </egtd-ssbt-ticket-content>
          </motion.div>
        )}
      </AnimatePresence>
    </egtd-ssbt-ticket-content-wrapper>
  );
};
export default SSBTTicketContent;
