import React from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UI } from 'components/shared/UI';

interface Props {
  disabled?: boolean;
  text?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  className?: string;
}

const FormButton: React.FC<Props> = ({ text = '', disabled, onClick, className }) => {
  const {
    formState: { isValid, isSubmitting },
  } = useFormContext();
  const { t } = useTranslation();

  const btnClass = clsx(className);

  const handleOnClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!disabled && onClick) {
      onClick(e);
    }
  };

  return (
    <UI.HTMLButton
      type="submit"
      className={btnClass}
      aria-label={text ? text : 'arrow'}
      data-qid={text ? 'regFinish' : 'regNextStep'}
      disabled={disabled || !isValid || isSubmitting}
      text={t(text)}
      icon={!text ? 'arrow' : ''}
      onClick={handleOnClick}
    />
  );
};

export default FormButton;
