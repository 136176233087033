import React from 'react';
import DepositForm from './components/deposit/DepositForm';
import WithdrawForm from './components/withdraw/WithdrawForm';
import { CashPayTypes } from './types/cashPay.types';

type Props = {
  activeTab: CashPayTypes.PaymentType;
};

export const componentMap: Record<CashPayTypes.PaymentType, React.FC> = {
  deposit: () => <DepositForm />,
  withdraw: () => <WithdrawForm />,
};

const OperationControl: React.FC<Props> = ({ activeTab }) => {
  const FormComponent = componentMap[activeTab];
  return (
    <>
      <FormComponent />
    </>
  );
};

export default OperationControl;
