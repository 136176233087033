import { FormFieldTypes, InputTypes, ValidationRules } from 'components/shared/forms/formTypes';

export type Interceptor = {
  id: string;
  playerId: string;
  status: string;
  content: any;
  createdOn: string;
  completedOn: string | null;
  declarationType: string;
  declarationInfo: Declarationinfo;
};

export type Declarationinfo = {
  playerNames: string;
  playerUcn: string;
  depositAmount: number;
  currency: string;
  fromDate: number;
  toDate: number;
};

export type SubmitData = {
  sourcesofFunds: Declaration[];
  acceptTerms: true;
  declarationId: string;
};

export type Declaration = {
  enabledOption: boolean;
  optionName: string;
  freeText: string;
};

export type StateStatus = 'pending' | 'idle' | 'failed' | 'succeeded';

export interface InterceptorInitialSlice {
  interceptors: Interceptor[];
  formStatus: StateStatus;
  declarationStatus: StateStatus;
  fields: FormFieldTypes[];
  error: any;
}

const SourceOfFundsCustomTypes = {
  PAGE_TITLE: 'formTitle',
  PAGE_SUBTITLE: 'formSubTitle',
  PAGE_CONTENT: 'formContent',
};

export const SourceOfFundsInputTypes = { ...InputTypes, ...SourceOfFundsCustomTypes };

export interface OptionType {
  [key: string]: Option;
}

export type Option = {
  label: string;
};

export type extendedValidationType = {
  field: FormFieldTypes;
  validationRules: ValidationRules;
};
