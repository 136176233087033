import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { LayoutConfigInnerLayoutTypes } from 'modules/casino/shared/constants';
import { GamePlayType } from 'modules/casino/shared/features/gameLaunch/casinoLaunchTypes';
import { triggerGTMEvent } from 'modules/casino/shared/features/seo/gtm/GoogleTagManager';
import { useCasinoLayoutConfig } from 'modules/casino/shared/hooks/useCasinoLayoutConfig';
import useGeneralCasinoType from 'modules/casino/shared/hooks/useGeneralCasinoType';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { getDeviceType } from 'modules/casino/shared/utils/common/helpersCommon';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { CategoryImpressionsEventKeys } from '../constants/categoryImpressionsConstants';
import { postGameImpression } from '../slice/categoryImpressionsSlice';
import { CategoryImpressionsTypes } from '../types/categoryImpressionsTypes';
import { getClicksAndImpressionsEventType } from '../utils/categoryImpressionsUtils';

type Props = {
  gameUniqueId: string;
  categoryId: string;
  categoryLayout: CategoryLayoutTypes;
  gamePosition: number;
};

export const useSendGameImpression = ({ gameUniqueId, categoryId, categoryLayout, gamePosition }: Props) => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const playerId = useAppSelector(selectMyProfile.playerId);
  const { casinoType } = useGeneralCasinoType();
  const { description, refGameId, gameType, providerId } = useGameProps({ gameUniqueId, categoryId });
  const { id, title, isTrackable, trackingTemplate } = useGetCategoryParams(categoryId, categoryLayout);
  const enableCategoryTracking = useCasinoLayoutConfig({
    innerLayout: LayoutConfigInnerLayoutTypes.ENABLE_CATEGORY_TRACKING,
  });

  const triggerGameTrackingEvent = (gamePlayType: GamePlayType) => {
    if (isTrackable && enableCategoryTracking) {
      const userId = playerId ? { userId: playerId } : {};
      const eventType = getClicksAndImpressionsEventType({
        key:
          gamePlayType === GamePlayType.FREE
            ? CategoryImpressionsEventKeys.DEMO_GAME_START
            : CategoryImpressionsEventKeys.GAME_START,
        trackingTemplate,
      });

      const additionalParams: CategoryImpressionsTypes.GameImpressionParams = {
        categoryId: id,
        categoryName: title,
        gameRank: gamePosition,
        categoryRoot: casinoType,
        gameName: description,
        gameType: gameType,
        gameIds: [refGameId],
        device: getDeviceType(),
        providerId,
        ...userId,
      };

      triggerGTMEvent({
        eventKey: eventType,
        additionalParams,
      });

      if (isAuthenticated) {
        dispatch(postGameImpression({ params: { ...additionalParams, eventType } }));
      }
    }
  };

  return {
    triggerGameTrackingEvent,
  };
};
