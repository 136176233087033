import React, { memo } from 'react';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { filterMenuItemsByPlayerId } from 'utils/filterMenuItemsByPlayerId';
import { BottomTabBarUI } from './BottomTabBarUI';
import { BottomTabBarButton } from './components';
import { useBottomTabBarHandleClick } from './hooks/useBottomTabBarHandleClick';
import { useBottomTabBarProps } from './hooks/useBottomTabBarProps';
import { useManageBottomTabBar } from './hooks/useManageBottomTabBar';
import { BottomTabBarTypes } from './types/bottomTabBar.types';

import { selectMyProfile } from '../myProfile/slice/myProfile.slice';

const BottomTabBar = () => {
  const { filteredTabs, setTabCounter } = useBottomTabBarProps();
  const playerId = useAppSelector(selectMyProfile.playerId);
  const onClickHandler = useBottomTabBarHandleClick();

  const items = filterMenuItemsByPlayerId({ items: filteredTabs, playerId });

  const { setBottomRef } = useManageBottomTabBar({ isBottomTabBar: !isEmpty(items) });

  if (filteredTabs?.length === 0) return null;

  return (
    <BottomTabBarUI.Wrapper forwardRef={setBottomRef} tabsLength={items.length}>
      {items?.map((tab: BottomTabBarTypes.TabBarType, i: number) => (
        <BottomTabBarButton
          tabType={tab.type}
          route={tab.route?.trim() || ''}
          key={tab.ranking + i}
          icon={tab.icon}
          iconColor={tab.iconColor}
          title={tab.titleTranslations || ''}
          notifications={setTabCounter(tab?.route?.trim() || '')?.[tab.type] || 0}
          notificationPosition={tab.type === 'casinoFavourites' || tab.type === 'promotions' ? 'right' : 'centered'}
          onClick={() => onClickHandler(tab)}
          isNew={tab?.ribbonConfig?.isNew}
          isNewBg={tab?.ribbonConfig?.labelColor}
          isNewColor={tab?.ribbonConfig?.textColor}
          bgImage={tab.backgroundImage || ''}
          bgColor={tab.backgroundColor || ''}
        />
      ))}
    </BottomTabBarUI.Wrapper>
  );
};
export default memo(BottomTabBar);
