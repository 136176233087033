import styled from 'styled-components';
import { Breakpoints } from 'modules/casino/theme/Theme';

const MetaInfo = styled.div.attrs({
  className: 'live-cell__meta d-flex-ac-jb',
})<{ $isSlider: boolean }>`
  padding: ${({ $isSlider }) => ($isSlider ? '8px 6px' : '5px 6px')};
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    padding: 7px 10px;
  }
`;
export default MetaInfo;
MetaInfo.displayName = 'MetaInfo';
