import React from 'react';
import clsx from 'clsx';

interface WrapperProps {
  className?: string;
  children: React.ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({ className, children }) => {
  const footerClass = clsx('footer-wrapper', className);
  return (
    <footer className={footerClass} data-qid="footer">
      {children}
    </footer>
  );
};

export default Wrapper;
