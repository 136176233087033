import { MutableRefObject, useCallback, useEffect } from 'react';
import { useOutsideClick } from 'hooks/common/useOutsideClick';
import { useOutsideTouch } from 'hooks/common/useOutsideTouch';
import { useOutsideMouseMove } from 'shared/common/hooks/useOutsideMouseMove';

export const useClosePopOver = (
  popOverRef: MutableRefObject<HTMLElement | null>,
  handleClickPopOverOutside: () => void,
  show: boolean,
): { preventClose: (e: React.MouseEvent<HTMLElement>) => void } => {
  useOutsideClick(popOverRef, handleClickPopOverOutside);
  useOutsideTouch(popOverRef, handleClickPopOverOutside);
  useOutsideMouseMove(popOverRef, handleClickPopOverOutside);
  const preventClose = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleClickPopOverOutside);
    document.addEventListener('touchmove', handleClickPopOverOutside);

    return () => {
      document.removeEventListener('touchmove', handleClickPopOverOutside);
      document.removeEventListener('scroll', handleClickPopOverOutside);
    };
  }, [show, handleClickPopOverOutside]);

  return { preventClose };
};
