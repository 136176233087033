import { setBackBtnVisible, setPayTicketStatus } from 'pages/retailGames/slice/retailGames.slice';
import { AppDispatch } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { OmniPlayTypes } from '../types/omniPlay.types';

export const omniPlayIframeId = 'omniPlay-iframe';
export const containerId = 'omniPlay-container';

export const constructUrl = (gamesMenuData: OmniPlayTypes.LaunchOptions, token: string | undefined) => {
  if (isEmpty(gamesMenuData) || !token) return '';
  const { url, currency, game_id, language } = gamesMenuData;
  const query = `?token=${token}&language=${language}&currency=${currency}&game_id=${game_id}`;

  const fullUrl = `${url}${query}`;
  return fullUrl;
};

export const navigateToBettingView = (iframeElId: string, baseUrl: string, dispatch: AppDispatch) => () => {
  const iframeElem = document.getElementById(iframeElId) as HTMLIFrameElement;

  iframeElem.contentWindow?.postMessage(
    {
      action: 'navigate',
      data: 'GamePage',
    },
    baseUrl,
  );
  dispatch(setBackBtnVisible(false));
  dispatch(setPayTicketStatus(null));
};

// export const navigateToTicketHistoryView = (iframeElId: string, baseUrl: string, dispatch: AppDispatch) => () => {
//   const iframeElem = document.getElementById(iframeElId) as HTMLIFrameElement;
//   iframeElem.contentWindow?.postMessage(
//     {
//       action: 'navigate',
//       data: 'TicketHistory',
//     },
//     baseUrl,
//   );
//   dispatch(setBackBtnVisible(true));
//   dispatch(setPayTicketStatus(null));
// };

export const navigateToTicketCheckView = (
  iframeElId: string,
  baseUrl: string,
  ticketCode: string,
  dispatch: AppDispatch,
) => {
  const iframeElem = document.getElementById(iframeElId) as HTMLIFrameElement;

  iframeElem.contentWindow?.postMessage(
    {
      action: 'scan-outcome',
      data: {
        value: ticketCode,
      },
    },
    baseUrl,
  );
  dispatch(setBackBtnVisible(true));
};

export const reloadAfterTicketPaid = (iframeElId: string, baseUrl: string, dispatch: AppDispatch) => {
  const iframeElem = document.getElementById(iframeElId) as HTMLIFrameElement;

  iframeElem.contentWindow?.postMessage(
    {
      action: 'ticket-paid',
    },
    baseUrl,
  );
  dispatch(setBackBtnVisible(false));
  dispatch(setPayTicketStatus(null));
};
