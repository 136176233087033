import React, { HTMLAttributes } from 'react';

/** Ticket Wrapper */
interface CashierTicketWrapperProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: React.ReactNode;
}

const CashierTicketWrapper: React.FC<CashierTicketWrapperProps> = ({ className, children }) => {
  const classProp = className ? { class: className } : undefined;

  return <egtd-cashier-ticket-wrapper {...classProp}>{children}</egtd-cashier-ticket-wrapper>;
};

export default CashierTicketWrapper;
