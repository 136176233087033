import React, { memo } from 'react';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import { useAuthentication } from 'hooks/common/useAuthentication';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { useAppDispatch, useAppSelector } from 'store';
import { StyledIcon } from './FavouritesIcon.styled';
import { FavouritesTypes, FavouritesSection, cssNS, favTypesCSSMap } from '../types/favourites.types';
import { selectFavouritesById, updateFavourites } from '../utils/favourites.utils';

// Component CSS NameSpace
const showActiveStar = {
  layout_1: true,
};
const FavouritesIcon: React.FC<FavouritesTypes.Props> = ({
  variant = 'fav',
  componentLocation = 'header',
  componentOrder,
  favouriteType,
  favouriteId,
  favouriteParentId,
  className,
  isLive,
  sportType,
}) => {
  const isFavourite = useAppSelector(selectFavouritesById(favouriteType, favouriteId, favouriteParentId));
  const { isAuthenticated, showLoginForm } = useAuthentication();
  const dispatch = useAppDispatch();
  const favouritesConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.FAVOURITESCONFIG,
  });

  const hideFavourites = favouritesConfig?.hideFavourites;

  const favIconLayoutType = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.LAYOUTTYPECONFIG,
    deviceLayout: 'general',
  })?.sport?.sportBtns;

  const layoutIcon = favIconLayoutType || 'layout_1';
  const handleChange = debounce(() => {
    if (!isAuthenticated && favouriteType === FavouritesSection.sportEventIds) {
      showLoginForm();
    } else {
      dispatch(
        updateFavourites({
          type: favouriteType,
          id: favouriteId,
          parentId: favouriteParentId,
          isLive,
          sportType,
        }),
      );
    }
  }, 300);

  const handleFavouritesChange = (event) => {
    event.stopPropagation();
    handleChange();
  };

  const renderIcon = (variantToRender: FavouritesTypes.Variant) => {
    switch (variantToRender) {
      case 'fav':
        return (
          <>
            {isFavourite && showActiveStar[layoutIcon] && (
              <path
                id="fav-on"
                d="M5.8 22.8c-.1 0-.3 0-.4-.1-.3-.2-.4-.5-.3-.7l1.5-7-5.2-4.7c-.3-.3-.3-.6-.3-.8.1-.3.4-.5.7-.5l6.9-.7 2.7-6.5c.1-.3.4-.4.7-.4s.5.2.7.4l2.7 6.5 6.8.7c.3 0 .5.2.6.5.1.3 0 .6-.2.8L17.4 15l1.5 7c.1.3-.1.6-.3.7-.1.1-.3.1-.4.1-.1 0-.3 0-.4-.1L12 19.1l-5.8 3.6c-.2.1-.3.1-.4.1z"
              />
            )}
            <path
              id="fav-off"
              d="M23.7 8.7c-.2-.3-.6-.5-.9-.5L16 7.5l-2.7-6.4c0-.3-.2-.5-.4-.7-.2-.3-.5-.4-.9-.4-.7 0-1.2.5-1.3 1.1L8 7.5l-6.8.7C.4 8.3-.1 9 0 9.8c0 .3.2.6.5.9l5.1 4.7-1.5 6.9c-.2.7.1 1.4.7 1.7h.6c.3 0 .6-.1.8-.3l1.4-.8.3-.2 4.1-2.6 5.8 3.6c.3.2.6.3.9.3.4 0 .7-.2.9-.5.3-.4.4-.8.2-1.3l-1.5-7 5.1-4.7c.7-.4.8-1.2.3-1.8zm-7 6l1.5 7.4-6.2-3.8h-.1l-5.3 3.3-.9.6 1.6-7.4-5.5-5L9 9h.1L12 2.1l2.9 6.8V9l7.3.7-5.5 5z"
            />
          </>
        );

      case 'pin':
        return (
          <>
            {isFavourite ? (
              <path
                id="pin-on"
                d="M129.78 116.76l-6.94-6.93a.37.37 0 01-.1-.37l.31-1a.34.34 0 01.58-.11l7.62 7.61a.35.35 0 01-.11.59l-1 .3a.37.37 0 01-.36-.09zm-13.73 8l-1.24-1.24-5.58 5.57 1.25 1.24zm-6.82 4.33l-.59 1.83 1.84-.59zm13.36-6.86l-5.15-5.15a.42.42 0 010-.62l5.35-4.34a.4.4 0 01.55 0l4.15 4.15a.41.41 0 010 .55l-4.35 5.35a.42.42 0 01-.55.1zm1.28-7.69a.36.36 0 000-.52l-.73-.73a.18.18 0 00-.25 0l-4.24 3.44a.15.15 0 000 .22l1.14 1.15zm-2.44 14.7l-11-11a1 1 0 01.27-1.67l1.57-.67a2.53 2.53 0 012.76.58l8.13 8.13a2.53 2.53 0 01.58 2.76l-.64 1.63a1 1 0 01-1.67.28zm-4-9l-3-3a1.57 1.57 0 00-1.7-.37l-1.44.6a16.17 16.17 0 016.13 2.8z"
                transform="translate(-108 -107.6)"
              />
            ) : (
              <path
                id="pin-off"
                d="M123.69 129.26l.67-1.57a3.18 3.18 0 00-.72-3.46l-.76-.76s.16.07.18 0l5.68-6.88.59.59a1 1 0 001 .25l1-.3a1 1 0 00.65-.69 1 1 0 00-.27-1l-7.61-7.61a1 1 0 00-1-.28 1 1 0 00-.68.65l-.31 1a1 1 0 00.26 1l.69.69-6.88 5.68-.07.07-.62-.62a3.18 3.18 0 00-3.46-.72l-1.57.67a1.61 1.61 0 00-1 1.18 1.69 1.69 0 00.48 1.53l4.34 4.34-5.57 5.58v.18l-.6 1.84a.64.64 0 00.81.8l1.83-.59h.12l5.57-5.57 4.48 4.48a1.7 1.7 0 001.54.48 1.61 1.61 0 001.23-.96zm-.85-19.43a.37.37 0 01-.1-.37l.31-1a.34.34 0 01.58-.11l7.62 7.61a.35.35 0 01-.11.59l-1 .3a.37.37 0 01-.37-.1zm-5.37 6.67l5.35-4.34a.4.4 0 01.55 0l4.15 4.15a.4.4 0 010 .55l-4.34 5.35a.42.42 0 01-.62 0l-5.15-5.15a.42.42 0 01.06-.56zm-7 13.87l-1.84.59.59-1.83 5.58-5.57 1.24 1.24zm11-1.09l-11-11a1 1 0 01.27-1.67l1.57-.67a2.53 2.53 0 012.76.58l8.13 8.13a2.53 2.53 0 01.58 2.76l-.68 1.59a1 1 0 01-1.67.28z"
                transform="translate(-108 -107.6)"
              />
            )}
          </>
        );
    }
  };

  if (hideFavourites) return null;

  const favClass = clsx(
    cssNS,
    variant === 'pin' && `${cssNS}--pin`,
    favTypesCSSMap[componentLocation].iconClass,
    isFavourite && `${cssNS}--active`,
    isFavourite && favTypesCSSMap[componentLocation].activeIconClass,
    className,
  );

  return (
    <StyledIcon
      xmlns="http://www.w3.org/2000/svg"
      className={favClass}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      onClick={handleFavouritesChange}
      $componentOrder={componentOrder}
      $componentLocation={componentLocation}
    >
      {renderIcon(variant)}
    </StyledIcon>
  );
};

export default memo(FavouritesIcon);
