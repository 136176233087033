import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout/Flex';
import RadiosGroupButton from './RadiosGroupButton';

interface Props {
  options?;
  onChange?: (v) => void;
  label?: string;
  name: string;
  errorMessage?: string;
  value?: string | number;
  onBlur?: (v?) => void;
  disabled?: boolean;
  controlRef;
}

const FormRadio: React.FC<Props> = ({ options, onChange, name, errorMessage, value, onBlur, disabled, controlRef }) => {
  const { t } = useTranslation();

  const handleOnChange = (selectedItem: string) => () => {
    if (!disabled) {
      if (selectedItem !== undefined || selectedItem !== null) {
        onChange && onChange(selectedItem);
        onBlur && onBlur();
      }
    }
  };

  return (
    <Flex.Col className="w-100">
      <Flex>
        {options &&
          options.map((option) => (
            <RadiosGroupButton
              key={`${name}-${option.value}`}
              controlRef={controlRef}
              isActive={option.value === value}
              onClick={handleOnChange(option.value)}
              text={t(option?.text || '')}
            />
          ))}
      </Flex>
      {errorMessage && <div className="form-message error bg--error">{errorMessage}</div>}
    </Flex.Col>
  );
};

export default FormRadio;
