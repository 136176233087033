import React, { memo, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { Dropdown, DropdownItem } from 'components/shared/dropdown';
import { UI } from 'components/shared/UI';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import userStorage from 'pages/auth/login/UserStorage';
import { SettingsThunks, selectSettings } from 'pages/myAccount/tabs/settings/slice/settings.slice';
import { setLanguage } from 'shared/common/sharedSlices/commonActions';
import { useAppDispatch, useAppSelector } from 'store';
import { getBusinessUnit } from 'utils/common/helpersCommon';
import { setTopNavHeaderStatus } from '../../appHeader';
import { FooterTypes } from '../types/appFooter.types';

interface Props {
  section?: FooterTypes.Section;
  isDrawer?: boolean;
  inHeader?: boolean;
  options?: { value: string; text: string }[];
}

function areEqual(prevProps: Props, nextProps: Props) {
  return isEqual(prevProps.section, nextProps.section);
}

const getFlagValue = ({ activeLanguage, countryCode, isRJBR }) => {
  const flagFromLang = activeLanguage === 'pt' && isRJBR ? 'br' : activeLanguage === 'en' ? 'gb' : activeLanguage;
  return countryCode === 'pt' && isRJBR ? 'br' : countryCode || flagFromLang;
};

const Languages: React.FC<Props> = ({ isDrawer, options, inHeader }) => {
  const [activeLanguage, setActiveLanguage] = useState(userStorage.getUserLang());
  const languages = useAppSelector(selectSettings.languages);
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const isRJBR = getBusinessUnit() === 'RJBR' || getBusinessUnit() === 'RJBRPOC';
  const dispatch = useAppDispatch();

  const countryCode = languages?.find((item) => item.language === activeLanguage)?.countryCode;
  const languageTitle = options?.find((item) => item?.value === activeLanguage)?.text;
  // const flagFromLang = activeLanguage === 'pt' && isRJBR ? 'br' : activeLanguage === 'en' ? 'gb' : activeLanguage;
  // const flag = countryCode === 'pt' && isRJBR ? 'br' : countryCode || flagFromLang;

  const flag = getFlagValue({ activeLanguage, countryCode, isRJBR });

  useEffect(() => {
    dispatch(SettingsThunks.fetchLanguages());
  }, [dispatch]);

  const handleLanguageChange = (lang: string) => {
    if (lang !== activeLanguage) {
      dispatch(setTopNavHeaderStatus('visible'));
      userStorage.setUserLang(lang);
      dispatch(setLanguage(lang));
      setActiveLanguage(lang);
      if (isAuthenticated) {
        const selectedLang = languages?.find((item) => item.language === lang);
        dispatch(SettingsThunks.updateUserLanguage({ languageId: selectedLang?.id }));
      }
    }
  };

  const dropDawnTitle = inHeader ? '' : isDrawer ? languageTitle : activeLanguage ? activeLanguage?.toUpperCase() : '';

  return (
    <Dropdown
      buttonDefaultClassName={isDrawer && !isDrawer ? true : false}
      buttonClassName={isDrawer ? '' : 'p-0 text-tabular footer-menu__link d-flex-center'}
      title={dropDawnTitle}
      titleClassName={inHeader ? '' : 'mr-1'}
      className={inHeader ? 'egtd-dropdown-header' : isDrawer ? '' : 'footer-system__item d-flex'}
      dropdownClassName={isDrawer ? '' : 'pos-l-auto pos-r-0'}
      icon={
        isDrawer ? (
          <div></div>
        ) : (
          <UI.Flag className={inHeader ? '' : 'mr-1'} size={16} flag={flag} isCircle={inHeader} />
        )
      }
    >
      {languages &&
        languages?.map((lang, index) => (
          <DropdownItem
            handleChangeDropdown={handleLanguageChange}
            isActive={lang.language === activeLanguage}
            key={index}
            flag={getFlagValue({ activeLanguage: lang.language, countryCode: lang.countryCode, isRJBR })}
            title={lang.nameTranslations}
            value={lang.language}
            dataQid={`userLang-${lang.language}`}
          />
        ))}
    </Dropdown>
  );
};

export default memo(Languages, areEqual);
