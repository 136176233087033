import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppSelector } from 'store';
import { RootState } from 'store/rootReducer';
import { useProviderLobbyKey } from './useProviderLobbyKey';
import { providersCategorySelectors } from '../slice/providersCategorySlice';

export const useProvidersLobby = () => {
  const providerLobbyKey = useProviderLobbyKey();
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const providerLobby = useAppSelector((state: RootState) =>
    providersCategorySelectors.providerLobby(state, providerLobbyKey, isAuthenticated),
  );
  const { items, itemIds, tags, ttl, refetchLobby } = providerLobby;

  return {
    providerLobbyItems: items,
    providerLobbyItemIds: itemIds,
    providerLobbyTags: tags,
    providerLobbyTTL: ttl,
    refetchProviderLobby: refetchLobby,
  };
};
