import { MutableRefObject, useEffect, useCallback } from 'react';
import { useIsUnmounted } from 'hooks/common/useIsUnmouted';
import { selectIntegrations } from 'shared/common/features/integrations/slice/integrations.slice';
import { useAppSelector } from 'store';
import { GamificationWidgetPropsType, GamificationWidgetType } from '../types/gamification.types';

type Props = {
  id: GamificationWidgetType;
  containerId: string;
  props?: GamificationWidgetPropsType;
  onLoadContainerEl: MutableRefObject<HTMLElement | null>;
  removeOnUnmount?: boolean;
};

const useAtWidget = ({
  id,
  containerId,
  props,
  onLoadContainerEl,
  removeOnUnmount = true,
}: Props): { updateWidgetProps: (changeWidgetProps?: GamificationWidgetPropsType) => void } => {
  const isWidgetLoaded = useAppSelector(selectIntegrations.isGamificationWidgetLoaded);
  const isUnmount = useIsUnmounted();
  useEffect(() => {
    if (isWidgetLoaded) {
      if (isWidgetLoaded && onLoadContainerEl?.current) {
        window.widgetGamification.addWidget({
          type: id,
          containerId,
          props,
        });
      }
      return () => {
        if (isUnmount() && window.widgetGamification && removeOnUnmount) {
          window.widgetGamification.removeWidget(containerId);
        }
      };
    }
  }, [containerId, id, isUnmount, isWidgetLoaded, onLoadContainerEl, props, removeOnUnmount]);

  const updateWidgetProps = useCallback(
    (changeWidgetProps) => {
      window.widgetGamification.changeWidgetProps({
        type: id,
        containerId,
        props: {
          ...props,
          ...changeWidgetProps,
        },
      });
    },
    [containerId, props],
  );

  return { updateWidgetProps };
};

export default useAtWidget;
