import { LayoutConfigInnerLayoutTypes } from 'modules/casino/shared/constants';
import { useCasinoLayoutConfig } from 'modules/casino/shared/hooks/useCasinoLayoutConfig';
import { Casino } from 'modules/casino/shared/types';
import { AggregatorConfig } from '../../casinoLaunchTypes';
import { aggregatorConfig } from '../../config/AggregatorConfig';

export const useAggregatorConfig = (): AggregatorConfig => {
  const casinoGameAggregatorConfig = useCasinoLayoutConfig({
    innerLayout: LayoutConfigInnerLayoutTypes.CASINO_GAME_AGGREGATOR_CONFIG,
  });

  const extendProvidersConfig = (
    current: AggregatorConfig,
    updates: Casino.PartialNestedObjects<AggregatorConfig>,
  ): AggregatorConfig => {
    Object.keys(updates).find((key) => {
      if (!Object.prototype.hasOwnProperty.call(current, key) || typeof updates[key] !== 'object') {
        current[key] = updates[key];
      } else {
        extendProvidersConfig(current[key], updates[key]);
      }
    });

    return current;
  };

  if (typeof casinoGameAggregatorConfig === 'undefined') return aggregatorConfig;

  const extendedConfig = extendProvidersConfig(
    aggregatorConfig,
    JSON.parse(JSON.stringify(casinoGameAggregatorConfig)),
  );

  return extendedConfig;
};
