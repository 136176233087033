import React from 'react';
import { SCCampaignBadge } from './styled/CampaignInfo.styled';

type Props = {
  badgeText: string;
  className: string;
};

const LeaderBoardBadge: React.FC<Props> = ({ badgeText, className }) => {
  return <SCCampaignBadge className={`${className}`}>{badgeText}</SCCampaignBadge>;
};

export default LeaderBoardBadge;
