import React from 'react';
import clsx from 'clsx';
import { cssBurgerNS } from './burgerUI.types';

/** Separator */
interface SeparatorProps {
  className?: string;
}

const Separator: React.FC<SeparatorProps> = ({ className }) => {
  const wrapperClass = clsx('mx-auto', cssBurgerNS.Separator, className);

  return <div className={wrapperClass} />;
};

export default Separator;
