import React from 'react';
import { useNavigate } from 'react-router-dom';
import { favoriteGamesSelectors } from 'modules/casino/modules/container/cellsContainer/cellls/additionalGames/favourites/slice/casinoFavouriteGamesSlice';
import { Flex } from 'modules/casino/shared/components/layout';
import { UI } from 'modules/casino/shared/components/UI';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { Desktop, DesktopDown } from 'modules/casino/theme/Theme';
import { useAppSelector } from 'store';
import { CategoryLayoutTypes } from '../../types/CategoryLayoutTypes';
import LobbySubtitle from '../headerSubtitles/LobbySubtitle';

interface Props {
  categoryId: string;
  categoryLayoutType?: CategoryLayoutTypes;
}

const FavouritesCategoryTitle: React.FC<Props> = ({ categoryId }) => {
  const navigate = useNavigate();
  const { title, titleColor } = useGetCategoryParams(categoryId, CategoryLayoutTypes.FAVOURITES_CATEGORY);
  const gamesCount = useAppSelector(favoriteGamesSelectors.favoriteGamesCount);
  const titleText = gamesCount && title ? `${title} (${gamesCount})` : title;

  const handleBackButton = () => {
    window.scrollBy(0, 0.1);
    navigate(-1);
  };

  return titleText ? (
    <>
      <DesktopDown>
        <UI.ButtonBack icon={`arrow`} onClickHandler={handleBackButton} />
        <div className={`favourites_category-category-header__title `} style={{ color: titleColor }}>
          {titleText}
        </div>
      </DesktopDown>
      <Desktop>
        <Flex.Col>
          <div className={`favourites_category-category-header__title`} style={{ color: titleColor }}>
            {titleText}
          </div>
          <LobbySubtitle
            categoryId={categoryId}
            categoryLayoutType={CategoryLayoutTypes.FAVOURITES_CATEGORY}
          ></LobbySubtitle>
        </Flex.Col>
      </Desktop>
    </>
  ) : (
    <></>
  );
};

export default FavouritesCategoryTitle;
