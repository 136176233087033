import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useCountDown } from 'hooks/common/useCountDown';
import { CommonTranslations } from 'utils/common/translationUtils/translationStrings';
import AnimatedCounterItem from './AnimatedCounterItem';

type Props = {
  date: number;
  text?: string;
  expire?: boolean;
  componentLocation?: 'default' | 'counterPromotion'; // Allow reusabilty of counter with different styling based on parent component
  sizeTitle?: 'small' | 'medium' | 'large';
};

const styles = {
  default: {
    counterClassStyle: 'animated-counter',
    counterMargin: 'my-2',
  },
  counterPromotion: {
    counterClassStyle: 'counter-promotion',
    counterMargin: '',
  },
};

const AnimatedCounter: React.FC<Props> = ({
  date,
  text,
  expire,
  componentLocation = 'default',
  sizeTitle = 'medium',
}) => {
  const { t } = useTranslation();
  const { timer, isExpired, prevTime } = useCountDown(date, 'systemTime');

  if (!timer || (expire && isExpired)) {
    return null;
  }

  const counterStyling = styles[componentLocation].counterClassStyle;
  const counterLayout = clsx('d-flex-col', counterStyling);
  const counterMargin = clsx(`${counterStyling}-display`, styles[componentLocation].counterMargin);

  return (
    <div className={counterLayout}>
      {text && <div className={`${counterStyling}-text ${counterStyling}-text--${sizeTitle}`}>{text}</div>}
      <div className={counterMargin}>
        {timer?.days !== undefined && timer?.days > 0 && (
          <AnimatedCounterItem
            prevDate={prevTime?.days ?? 0}
            date={timer.days > 0 ? timer.days : 0}
            text={t(CommonTranslations.Time.days)}
            counterStyling={counterStyling}
          />
        )}
        {timer?.hours !== undefined && (
          <AnimatedCounterItem
            prevDate={prevTime?.hours ?? 0}
            date={timer.hours > 0 ? timer.hours : 0}
            text={t(CommonTranslations.Time.hours)}
            counterStyling={counterStyling}
          />
        )}
        {timer?.minutes !== undefined && (
          <AnimatedCounterItem
            prevDate={prevTime?.minutes ?? 0}
            date={timer.minutes > 0 ? timer.minutes : 0}
            text={t(CommonTranslations.Time.minutes)}
            counterStyling={counterStyling}
          />
        )}
        {timer?.seconds !== undefined && (
          <AnimatedCounterItem
            prevDate={prevTime?.seconds ?? 0}
            date={timer.seconds > 0 ? timer.seconds : 0}
            text={t(CommonTranslations.Time.seconds)}
            counterStyling={counterStyling}
          />
        )}
      </div>
    </div>
  );
};

export default AnimatedCounter;
