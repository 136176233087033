import React from 'react';
import { ContainerProps } from './History.types';

/** Container */
const Container: React.FC<ContainerProps> = ({ className, children, ...rest }) => {
  const classProp = className ? { class: className } : { undefined };

  return (
    <egtd-p-history-container {...classProp} {...rest}>
      {children}
    </egtd-p-history-container>
  );
};

export default Container;
