import { GameType } from 'modules/casino/shared/constants';
import { CreateThunk } from 'modules/casino/store/thunkCreators';
import { MobileAppRestrictions } from '../../../../shared/features/mobileAppRestrictions/mobileAppRestrictions.types';
import { CasinoGamesTypes } from '../../cellsContainer/cellls/types/casinoGamesTypes';
import { GameCellRestrictions } from '../../cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellRestriction/gameCellRestriction.types';
import { GameTag } from '../../cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/GameCellTagsTypes';
import { LeaderBoardWidgetTypes } from '../widgetTypes/leaderBoardWidget/leaderBoardCellWidget/LeaderBoardCellWidgetTypes';
import { PrizeDropWidgetTypes } from '../widgetTypes/prizeDropWidget/prizeDropCellWidget/PrizeDropCellWidgetTypes';
import { PromoWidgetsTypes } from '../widgetTypes/promoWidget/promoWidgetTypes';

export enum DiffFormat {
  Seconds = 'seconds',
  Minutes = 'minutes',
  Hour = 'hour',
  Hours = 'hours',
  Day = 'day',
  Days = 'days',
}

export enum WidgetTemplates {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
  MACRO = 'MACRO',
  REGULAR = 'REGULAR',
  SECTION = 'SECTION',
  SECTION_STAKE = 'SECTION_STAKE',
  LANDING = 'LANDING',
  BANNER_SLIDER = 'BANNER_SLIDER',
  SECTION_STAKE_PLUS = 'SECTION_STAKE_PLUS',
}

export type LastWinnersDestinationTypes = 'Landing' | 'Casino';

export enum Destinations {
  Lending = 'Landing',
  Casino = 'Casino',
}

export enum ButtonOrder {
  BOTTOM = 1,
  TOP = 2,
}

export enum WidgetButtonType {
  Route = 'ROUTE',
  External = 'EXTERNAL',
  Modal = 'MODAL',
}

export declare module CasinoWidgetsTypes {
  type Widget = {
    id: string;
    type: string;
    query: string;
    template: TemplateType;
    title?: WidgetTitle;
    navigation?: WidgetNavigation;
    dataArea?: DataAreaTypes;
    background: WidgetBackground;
    backgroundLocations: string;
    pullInterval?: number;
    animationInterval?: number;
    thirdPartyType?: string;
    thirdPartyKey?: string;
    navigationUrl?: string;
  };

  type GenericWidget = {
    id: string;
    type: string;
    template: TemplateType;
    title?: WidgetTitle;
    navigation?: WidgetNavigation;
    background: WidgetBackground;
    backgroundLocations: string;
  };

  type TemplateType = {
    columns: number;
    rows: number;
    type: WidgetTypes;
  };

  type WidgetTypes =
    | 'VERTICAL'
    | 'HORIZONTAL'
    | 'MACRO'
    | 'REGULAR'
    | 'SECTION'
    | 'RIBBON'
    | 'SECTION_STAKE'
    | 'LANDING'
    | 'BANNER_SLIDER'
    | 'JACKPOT_BANNER'
    | 'SECTION_STAKE_PLUS';

  type DataAreaTypes = {
    alignmentX: 'LEFT' | 'CENTER' | 'RIGHT';
    alignmentY: 'TOP' | 'CENTER' | 'BOTTOM';
    column: number;
    order: number;
    row: number;
  };

  type WidgetBackground = {
    staticLocation: string;
    useStatic: boolean;
  };

  type WidgetTitle = {
    text: string;
    textColor: string;
    textSecondary?: string;
    textSecondaryColor?: string;
    column: number;
    order: number;
    row: number;
    alignmentX: 'LEFT' | 'CENTER' | 'RIGHT';
    alignmentY: 'TOP' | 'CENTER' | 'BOTTOM';
  };

  type WidgetNavigation = {
    type: WidgetButtonType;
    route: string;
    buttonNavigation: boolean;
    alignmentX: 'LEFT' | 'CENTER' | 'RIGHT';
    alignmentY: 'TOP' | 'CENTER' | 'BOTTOM';
    backgroundColor: string;
    column: number;
    row: number;
    order: number;
    text: string;
    textColor: string;
  };

  type TopWinnerArea = {
    column: number;
    alignmentX: 'LEFT' | 'CENTER' | 'RIGHT';
    alignmentY: 'TOP' | 'CENTER' | 'BOTTOM';
  };

  type TopWinnerTemplate = {
    columns: number;
    type: WidgetTypes;
  };

  type WidgetJackpot = {
    jackpotId: string;
    hyperlink: string;
    backgroundLocation: string;
    backgroundId?: string;
  };

  type BigWinImageResource = {
    staticLocation: string;
  };

  type BigWin = {
    id: string;
    winAmount: string;
    stake: string;
    place: string;
    date: string;
    currency: string;
    resource: BigWinImageResource;
    backgroundLocation: string;
    navigationUrl?: string;
    showIcon?: boolean;
  };

  type LastWinners = Record<string, LastWinner[]>;
  type WidgetJackpots = Record<string, WidgetJackpot[]>;
  type BigWins = Record<string, BigWin[]>;
  type WidgetPromoItems = Record<string, WidgetPromoItem>;

  type BigWinsFetchPayload = {
    id: string;
    data: BigWin[];
  };

  type WidgetPayload = {
    widgetId: string;
    data: Widget;
  };

  type LastWinnersFetchPayload = {
    id: string;
    data: LastWinner[];
  };

  type WidgetPromoItem = { itemIds: string[]; items: PromoWidgetsTypes.PromoItem[] };

  type WidgetPromoItemsFetchPayload = {
    id: string;
    data: PromoWidgetsTypes.PromoItem[];
  };

  type WidgetPromoItemIdsFetchPayload = {
    id: string;
    promoIds: string[];
  };

  type WidgetPrizeDropFetchPayload = {
    id: string;
    data: PrizeDropWidgetTypes.WidgetPrizeDropCampaign;
  };

  type WidgetLeaderBoardFetchPayload = {
    id: string;
    data: LeaderBoardWidgetTypes.LeaderBoardWidgetData;
  };

  interface State {
    widgets: Record<string, Widget>;
    lastWinners: LastWinners;
    lastWinnersLoadingState: Record<string, number>;
    widgetJackpots: WidgetJackpots;
    bigWins: BigWins;
    bigWinsCollectionFetched: Record<string, boolean>;
    widgetPromoItems: WidgetPromoItems;
    widgetPrizeDropData: Record<string, PrizeDropWidgetTypes.WidgetPrizeDropCampaign>;
    prizeDropLoadingState: Record<string, number>;
    widgetLeaderBoardData: Record<string, LeaderBoardWidgetTypes.LeaderBoardWidgetData>;
    leaderBoardLoadingState: Record<string, number>;
  }

  interface LastWinner {
    id: string;
    playerCurrency: string;
    description: string;
    jackpotId: string;
    rank: number;
    iframeLoad: boolean;
    isTopWin: boolean;
    gameType: GameType;
    betType: GameCellRestrictions.ProductType;
    isVideo: boolean;
    providerId: string;
    providerAlias: string;
    refGameId: string;
    winAmount: number;
    winAmountAsDecimal: number;
    winTime: number;
    winDate: string;
    tags: GameTag[];
    hasBaseDenominations: boolean;
    visualizationTemplateId: string;
    gameResource: CasinoGamesTypes.GameResource;
    stake: number | null;
    stakeAsDecimal?: number;
  }

  type CasinoWidgetThunks = {
    fetchLastWinners: CreateThunk<
      { query: string; id: string; mobileAppRestrictions: MobileAppRestrictions },
      LastWinnersFetchPayload,
      string | null
    >;
    fetchWidgetJackpots: CreateThunk<{ query: string; id: string }, WidgetJackpots, string | null>;
    fetchBigWins: CreateThunk<{ query: string; id: string }, BigWinsFetchPayload, string | null>;
    fetchWidgetById: CreateThunk<{ widgetId: string }, WidgetPayload, string | null>;
    fetchWidgetPromoItemIds: CreateThunk<{ query: string; id: string }, WidgetPromoItemIdsFetchPayload, string | null>;
    fetchWidgetPromoItems: CreateThunk<{ promoIds: string[]; id: string }, WidgetPromoItemsFetchPayload, string | null>;
    fetchWidgetPrizeDropCampaign: CreateThunk<
      { query: string; id: string },
      WidgetPrizeDropFetchPayload,
      string | null
    >;
    fetchWidgetLeaderBoardData: CreateThunk<
      { query: string; id: string },
      WidgetLeaderBoardFetchPayload,
      string | null
    >;
  };
}
