import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import type { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import { Sport } from 'utils/common/types/sportTypes';
import config from 'utils/config';
import { ClaimTicket } from '../types/claimTicket.types';

const initialState: ClaimTicket.InitialState = {};

export const claimTicketThunks: ClaimTicket.Thunks = {
  claimTicketSearch: createAbortThunk('claimTicket/claimTicketSearch', async (params, { rejectWithValue }) => {
    const { cpf, ticketId, type } = params;
    const apiUrl = `${config.API_URL}/api/pretail/public/v2/check-ticket?Cpf=${cpf}&TicketId=${ticketId}&Type=${type}`;

    try {
      const response = await axiosInstance.get(apiUrl);
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseData(err));
    }
  }),
  claimTicket: createAbortThunk(
    'claimTicket/claimTicket',
    async (_, { getState, rejectWithValue }) => {
      const playerId = selectMyProfile.playerId(getState());
      const formData = selectClaimTicket.formData(getState());
      const apiUrl = `${config.API_URL}/api/pretail/agent/v2/ticket/claim-ticket-payout`;

      try {
        const response = await axiosInstance.post(apiUrl, { playerId, ...formData });
        return response.data;
      } catch (err) {
        return rejectWithValue(getError.responseData(err));
      }
    },
    { selfAbort: true },
  ),
};

const { claimTicketSearch, claimTicket } = claimTicketThunks;

const claimTicketSlice = createSlice({
  name: 'claimTicket',
  initialState,
  reducers: {
    clearClaimTicket: () => initialState,
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setFormStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    clearFormData: (state) => {
      state.formData = {};
      state.step = 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(claimTicketSearch.pending, (state) => {
        state.searchTicketStatus = 'pending';
      })
      .addCase(claimTicketSearch.fulfilled, (state, action) => {
        state.ticketInfo = action.payload;
        state.searchTicketStatus = 'fulfilled';
        state.searchTicketError = false;
      })
      .addCase(claimTicketSearch.rejected, (state) => {
        state.searchTicketStatus = 'rejected';
        state.searchTicketError = true;
      })
      .addCase(claimTicket.pending, (state) => {
        state.claimTicketStatus = 'pending';
      })
      .addCase(claimTicket.fulfilled, (state, action) => {
        state.claimTicketStatus = 'fulfilled';
        state.claimTicketResponse = action.payload;
        state.claimTicketError = null;
      })
      .addCase(claimTicket.rejected, (state, action) => {
        if (action.payload) {
          state.claimTicketStatus = 'rejected';
          state.claimTicketError = action.payload;
        }
      })
      .addCase(logoutUser, () => initialState);
  },
});

export const { clearClaimTicket, setFormData, clearFormData, setFormStep } = claimTicketSlice.actions;

export default claimTicketSlice.reducer;

export const selectClaimTicket = {
  formData: (state: RootState) => state.retail.claimTicket.formData,
  step: (state: RootState) => state.retail.claimTicket.step || 1,
  claimTicketSearchStatus: (state: RootState): Sport.RequestState | undefined =>
    state.retail.claimTicket.searchTicketStatus,
  claimTicketSearchError: (state: RootState): boolean | undefined => state.retail.claimTicket.searchTicketError,
  claimTicketInfo: (state: RootState) => state.retail.claimTicket.ticketInfo,
  claimTicketStatus: (state: RootState): Sport.RequestState | undefined => state.retail.claimTicket.claimTicketStatus,
};
