import { useLocation } from 'react-router-dom';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { Balance } from 'shared/common/features/myProfile/types/myProfile.types';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { useMyProfileConfig } from './useMyProfileConfig';
import { General } from '../../general/types/generalSlice.types';

const verticalsMap = {
  ['casino']: 'casino',
  ['casino-egt']: 'casino',
  ['vegas']: 'casino',
  ['casino-games']: 'casino',
  ['live-games']: 'casino',
  ['live-casino']: 'liveCasino',
  ['sports']: 'sport',
  ['virtual']: 'sport',
  ['esports']: 'sport',
  ['in-play']: 'sport',
};

const mapBalanceByVertical = ({
  vertical,
  balance,
  balanceMap,
}: {
  vertical: string;
  balance?: Balance | null;
  balanceMap?: Record<General.BalanceVertical, General.BalanceKeysList>;
}) => {
  const verticalKey = verticalsMap[vertical] || 'other';
  const balanceGroups: General.BalanceKeysList = balanceMap?.[verticalKey] || [];

  if (isEmpty(balanceGroups)) return {};

  const result: Partial<{ [K in keyof Balance]: number }> = {};
  balanceGroups.forEach(
    (balanceKey) => balance?.[balanceKey] !== undefined && (result[balanceKey] = balance?.[balanceKey]),
  );
  return result;
};

export const useUserBalanceByConfig = () => {
  const balance = useAppSelector(selectMyProfile.balance);

  const location = useLocation();
  const vertical = location.pathname.split('/')[1];
  const balanceMap = useMyProfileConfig()?.balanceMap;

  return mapBalanceByVertical({ vertical, balance, balanceMap });
};
