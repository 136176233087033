import React from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import SocialIcon from 'components/shared/forms/components/FormSocial/SocialIcon';
import { SocialTypes } from 'components/shared/forms/components/FormSocial/types/formSocial.types';
import { UI } from 'components/shared/UI';
import { Breakpoints } from 'theme/Theme';
import { cssSocialAccountNS } from './socialAccountsUI.types';

/** Link */
interface LinkProps {
  type: SocialTypes;
  className?: string;
  text?: string;
  btnText?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  onClick?: React.MouseEventHandler;
  WrapElement?: (children) => React.ReactNode;
}

const Link: React.FC<LinkProps> = ({
  className,
  type,
  text,
  btnText,
  isActive,
  isDisabled,
  onClick,
  WrapElement = ({ children }) => children,
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isMobile}px)` });

  const activeProp = isActive ? { 'data-is-active': isActive } : undefined;
  const typeProp = type ? { 'data-type': type } : undefined;

  const linkClass = clsx(cssSocialAccountNS.Link, isDisabled && `${cssSocialAccountNS.Link}--disabled`, className);

  return (
    <div className={linkClass} {...activeProp} {...typeProp}>
      <SocialIcon type={type} size={isMobile ? 24 : 30} />
      {text}
      <WrapElement>
        <UI.Button
          className="ml-auto"
          size="md"
          variant={'primary'}
          isActive={isActive}
          text={btnText}
          onClick={onClick}
        />
      </WrapElement>
    </div>
  );
};

export default Link;
