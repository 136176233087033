import React, { memo } from 'react';
import { isEmpty } from 'utils/common/helpersCommon';
import { Sport } from 'utils/common/types/sportTypes';
import SingleEventScore from './SingleEventScore';
import EventScoreCurrent from '../EventScoreCurrent';
import { getPeriodScore } from '../utils/eventScore.utils';

interface Props {
  periodScore: Sport.PeriodScoresData;
  homeScore: string;
  awayScore: string;
  sportSpecificProps?: Sport.SpecificProperties;
}

const TripleScoreType: React.FC<Props> = ({ periodScore, homeScore, awayScore, sportSpecificProps }) => {
  const homeGameScore = sportSpecificProps?.homeGameScore === '50' ? 'A' : sportSpecificProps?.homeGameScore;
  const awayGameScore = sportSpecificProps?.awayGameScore === '50' ? 'A' : sportSpecificProps?.awayGameScore;

  return (
    <>
      <SingleEventScore homeScore={homeScore} awayScore={awayScore} />
      {!isEmpty(periodScore) && (
        <EventScoreCurrent
          homeScore={getPeriodScore(periodScore)?.map((data) => data.homeScore)}
          awayScore={getPeriodScore(periodScore)?.map((data) => data.awayScore)}
        />
      )}
      {!isEmpty(sportSpecificProps) && <EventScoreCurrent homeScore={homeGameScore} awayScore={awayGameScore} />}
    </>
  );
};

export default memo(TripleScoreType);
