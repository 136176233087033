import React, { memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text } from 'components/shared';
import { Flex } from 'components/shared/layout';
import { UI } from 'components/shared/UI';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { setMPDSectionActiveTab } from 'pages/myAccount/slice/mpdAccountTabs.slice';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import { navigateToIntegration } from 'shared/common/features/integrations/utils/integrations.utils';
import { showMyProfileModal } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { MyProfileTabsEnum } from 'shared/common/features/myProfile/types/myProfile.types';
import ParseHTML from 'shared/common/ParseHTML';
import { useAppDispatch } from 'store';
import { cdnConfig, fitCover } from 'utils/common/cdnConfig';
import { isEmpty, transformMyBetsDate } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { setActiveCategoryTitle, setRedirectedOfferId } from '../../offersTab/slice/offersTab.slice';
import { notificationThunks, readNotification } from '../notificationSlice';
import { NotificationTypes } from '../notificationTypes';

type Props = {
  notification: NotificationTypes.InboxNotification;
};

const NotificationItem: React.FC<Props> = ({ notification }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const layout =
    useLayoutConfigType({
      moduleLayout: 'platform',
      innerLayout: LayoutConfigTypes.LAYOUTTYPECONFIG,
      deviceLayout: 'general',
    })?.offersTab || 'layout_1';

  useEffect(() => {
    if (notification?.isRead === false) dispatch(readNotification(notification.id, 'inbox'));
  }, [dispatch, notification.id]);

  const sendClickEvent = () => {
    !notification?.isClicked &&
      dispatch(notificationThunks.sendButtonClick({ notificationId: notification.id, type: 'inbox' }));
  };

  const handleRedirectToLink = () => {
    dispatch(showMyProfileModal(false));
    navigate(notification.button?.buttonUrl as string);
    navigateToIntegration({ fullPath: notification.button?.buttonUrl });
    sendClickEvent();
  };

  const handleRedirectToOffer = () => {
    if (isEmpty(notification.redirectToOfferDetails)) return;
    const { title, offerId } = notification.redirectToOfferDetails;
    const tabKey = layout === 'layout_1' ? MyProfileTabsEnum.OFFERS : MyProfileTabsEnum.ACCOUNT;
    const navTab = layout === 'layout_1' ? undefined : 'OffersTab';

    dispatch(setMPDSectionActiveTab({ tabKey, navTab }));
    layout === 'layout_1' && dispatch(setActiveCategoryTitle(title));
    dispatch(setRedirectedOfferId(offerId));
    sendClickEvent();
  };

  return (
    <Flex.Col className="mpd-cta-item">
      <Flex.Col className="mpd-cta-item__section">
        <Text className="mpd-cta-item__title" text={notification.subheader} />
        <Text className="mt-1 mpd-cta-item__subtitle" text={transformMyBetsDate(notification.createDate)} />
      </Flex.Col>

      {notification?.style?.bannerResourceLocation && (
        <div className="mpd-cta-item__section">
          <img
            className="mpd-cta-item__banner"
            src={`${config.CDN_IMAGE_API}${fitCover(303 * 2, 308)}${cdnConfig.NOTIFICATIONS_MODULE}/${
              notification?.style?.bannerResourceLocation
            }`}
          />
          <img className="mpd-cta-item__banner" src="" />
        </div>
      )}

      <div className="mpd-cta-item__section">
        <ParseHTML html={notification?.message || ''} options={{ trim: true }} />
      </div>

      {!isEmpty(notification?.redirectToOfferDetails) && (
        <Flex.JE className="mpd-cta-item__section">
          <UI.Button size="md" text={notification.redirectToOfferDetails.buttonText} onClick={handleRedirectToOffer} />
        </Flex.JE>
      )}

      {notification?.button?.buttonUrl && isEmpty(notification?.redirectToOfferDetails) && (
        <Flex.JE className="mpd-cta-item__section">
          <UI.Button size="md" text={notification.button?.buttonText} onClick={handleRedirectToLink} />
        </Flex.JE>
      )}
    </Flex.Col>
  );
};

export default memo(NotificationItem);
