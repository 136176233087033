import React from 'react';
import { Text } from 'components/shared';
import { Flex } from 'components/shared/layout';

/** BonusLog Table Row */
interface TableRowProps {
  keyText: string;
  value?: string | number | JSX.Element | JSX.Element[] | null | undefined;
}

const TableRow: React.FC<TableRowProps> = ({ keyText, value }) => {
  return (
    <Flex className="bonus-table__row">
      <Text className="bonus-table__row bonus-table__row-key" text={keyText} />
      <Text className="mr-auto text-right bonus-table__row bonus-table__row-value" text={value} />
    </Flex>
  );
};

export default TableRow;
