import { useCallback, useState } from 'react';
type ButtonState = {
  prev: boolean;
  disabledNext: boolean;
  finish: boolean;
};
export const useButtonsState = (): {
  btnState: ButtonState;
  updateBtnState: ({ swiper, isLast }: { swiper; isLast?: boolean }) => void;
} => {
  const [btnState, setBtnState] = useState<ButtonState>({
    prev: false,
    disabledNext: true,
    finish: false,
  });

  const updateBtnState = useCallback(({ swiper, isLast }: { swiper; isLast?: boolean }) => {
    setBtnState((prev) => {
      return { ...prev, prev: swiper?.activeIndex > 0 };
    });

    setBtnState((prev) => {
      return { ...prev, disabledNext: !isLast };
    });
  }, []);

  return { updateBtnState, btnState };
};
