import React from 'react';
import { MessageVariant } from './SearchMessageConstants';
import { cssGlobalNS } from '../../types/css.types';
import { Icon } from '../icons';
import { Flex } from '../layout';

interface Props {
  variant: MessageVariant;
  message: string;
  classNames?: string;
}

const SearchMessage: React.FC<Props> = ({ variant, message, classNames }) => {
  return (
    <Flex.ColAC
      className={`mx-auto p-4 text-center ${cssGlobalNS}-c-search-msg__wrapper ${classNames ? classNames : ''}`}
    >
      <Icon className={`mb-4 ${cssGlobalNS}-c-search-msg__icon`} icon={variant} />
      <span className={`${cssGlobalNS}-c-search-msg__text`}>{message}</span>
    </Flex.ColAC>
  );
};

export default SearchMessage;
