import { RefObject, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { OnSubmit, GetCustomProps, FieldValues } from 'components/shared/forms/formTypes';
import { HookFormReturnProps } from 'components/shared/forms/hooks';
import { useOutsideClick } from 'hooks/common/useOutsideClick';
import useExtractURLParams from 'modules/casino/shared/features/gameLaunch/launcherRoute/hooks/useExtractURLParams';
import {
  showQuickLoginForm,
  setNavigationOptions,
  clearLoginError,
  selectAuthentication,
  loginThunks,
} from 'pages/auth/login/slice/login.slice';
import { selectRegistration } from 'pages/auth/registration/slice/registration.slice';
import { User } from 'shared/common/features/myProfile/types/myProfile.types';
import { triggerGTMEvent } from 'shared/common/features/seo/gtm/GoogleTagManager';
import { useAppDispatch, useAppSelector } from 'store';
import { Breakpoints } from 'theme/Theme';
import { isEmpty } from 'utils/common/helpersCommon';
import useChromeAutofillHack from './useChromeAutofillHack';
import { isUnverified } from './useLoginErrors';
import { Login } from '../../types/login.types';

const defaultValues = {
  username: '',
  password: '',
};

const inputIcon = {
  username: 'user-active',
  password: 'lock',
};

const autoComplete = {
  username: 'username',
  password: 'current-password',
};

interface Props {
  reset: HookFormReturnProps['reset'];
  setValue: HookFormReturnProps['setValue'];
  watch: HookFormReturnProps['watch'];
  formState: HookFormReturnProps['formState'];
}

interface ReturnProps {
  onSubmit: OnSubmit;
  getCustomProps: GetCustomProps;
  closeQuickLoginForm: () => void;
  isSubmitButtonDisabled?: boolean;
  handleKeyboardInput: (inputValue?: string) => void;
  currentField: string | null;
  submitCount: number;
  resetInputValuesWhenRetailKeyboardUnmount: () => void;
}

const useQuickLoginForm = (formProps: Props, bodyRef: RefObject<HTMLDivElement | null>): ReturnProps => {
  const { setValue, reset, watch, formState } = formProps;
  const { submitCount, isSubmitted, isValid } = formState;
  const dispatch = useAppDispatch();
  const [currentField, setCurrentField] = useState<string | null>(null);

  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isTabletOrMobile}px)` });
  const social = useAppSelector(selectRegistration.withSocial);
  const navigationOptions = useAppSelector(selectAuthentication.navigationOptions);
  const loginError = useAppSelector(selectAuthentication.loginError);
  const loginLoading = useAppSelector(selectAuthentication.loginLoading);
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const { onClick, clearAutofillOnChange, isCustomValid } = useChromeAutofillHack({ watch, formState });
  const params = useExtractURLParams();

  useEffect(() => {
    if (isTabletOrMobile) {
      //hide scroll for mobile devices
      document.body.classList.add('modal-open');
      document.body.classList.add('no-select');
    }

    return () => {
      document.body.classList.remove('modal-open');
      document.body.classList.remove('no-select');
      dispatch(clearLoginError());
      reset(defaultValues);
    };
  }, [isTabletOrMobile, dispatch, reset]);

  useEffect(() => {
    if (isSubmitted && loginError && !isUnverified(loginError?.errorMessage)) {
      reset(defaultValues, { keepIsSubmitted: true, keepTouched: true });
    }
  }, [isSubmitted, loginError, reset, submitCount]);

  const closeQuickLoginForm = useCallback(() => {
    dispatch(showQuickLoginForm({ status: false }));
    dispatch(setNavigationOptions(null));
  }, [dispatch]);

  const closeLoginFormAndNewWindow = useCallback(() => {
    if (location.pathname.includes('/game-launch/') && params?.isFreePlay === 'false') return;
    closeQuickLoginForm();
  }, []);
  useOutsideClick(bodyRef, closeLoginFormAndNewWindow);

  useEffect(() => {
    isAuthenticated && closeQuickLoginForm();
  }, [closeQuickLoginForm, isAuthenticated]);

  useEffect(() => {
    if (social) {
      Object.keys(defaultValues).forEach((k) => formProps.setValue(k, defaultValues[k]));
    }
  }, [social, formProps]);

  const navToOnSuccess = (userId: string, playerData: User | undefined): void => {
    if (navigationOptions) {
      navigationOptions.callback(true);
      dispatch(setNavigationOptions(null));
    }

    if (!isEmpty(playerData)) {
      const { email, phoneNumber, firstName, lastName, city, country } = playerData;
      const phone_number = phoneNumber;
      const address = {
        first_name: firstName,
        last_name: lastName,
        city: city,
        country: country?.name,
        street: playerData?.address,
      };

      triggerGTMEvent({
        eventKey: window.isRNWebView ? 'mobile-app-login-success' : 'login-success',
        additionalParams: { userId, email, phone_number, address },
      });
    }
  };
  const onFocusCallback = useCallback((props: React.FocusEvent<HTMLElement>) => {
    setCurrentField(props.target.getAttribute('name'));
  }, []);
  const resetInputValuesWhenRetailKeyboardUnmount = () => {
    reset(defaultValues, { keepIsSubmitted: true, keepTouched: true });
  };
  const handleKeyboardInput = (inputValue?: string) => {
    if (!currentField) {
      return;
    }
    setValue(currentField, inputValue?.trim());
  };

  const onSubmit = (data: FieldValues) => {
    const usernameTrimmed = data?.username?.trim();
    data.username = usernameTrimmed;
    setValue('username', usernameTrimmed);
    if (window.isRNWebView) {
      const checkbox = document.getElementById('webview-checkbox') as HTMLInputElement;
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          credentials: {
            username: data.username,
            password: data.password,
            isChecked: checkbox.checked,
          },
        }),
      );
    }
    dispatch(loginThunks.login({ data: data as Login.LoginData, navToOnSuccess }));
  };

  const onChangeCallback = useCallback(() => {
    loginError && dispatch(clearLoginError());
    clearAutofillOnChange();
  }, [clearAutofillOnChange, dispatch, loginError]);

  const getCustomProps = (field) => ({
    inputIcon: inputIcon[field.name],
    autoComplete: autoComplete[field.name],
    onChangeCallback,
    onClick,
    hasSuccess: isCustomValid,
    onFocus: onFocusCallback,

    ...(field?.gridStyles ? { classNames: `col-${field?.gridStyles.col} col-md-${field?.gridStyles.md}` } : {}),
  });

  const isSubmitButtonDisabled = loginLoading || (isCustomValid !== undefined ? !isCustomValid : isValid === false);

  return {
    onSubmit,
    getCustomProps,
    closeQuickLoginForm,
    isSubmitButtonDisabled,
    handleKeyboardInput,
    submitCount,
    currentField,
    resetInputValuesWhenRetailKeyboardUnmount,
  };
};
export default useQuickLoginForm;
