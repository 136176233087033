import React, { memo } from 'react';
import clsx from 'clsx';
import { useWindowDimensions } from 'hooks/useWindowDimenions';
import { cdnConfig, fitCover } from 'utils/common/cdnConfig';
import config from 'utils/config';
import { StyledCTAButton, StyledPlaceholder } from './CTAButton.styled';

interface Props {
  className?: string;
  buttonText?: string;
  disabled: boolean;
  errorText?: string;
  onClick: () => void;
  isVisible: boolean;
  showPlaceholder?: boolean;
  statusCode?: number;
  text?: string;
  folder: string;
}

const CTAButton: React.FC<Props> = ({
  className,
  buttonText,
  disabled,
  errorText,
  onClick,
  isVisible,
  showPlaceholder = true,
  statusCode,
  text,
  folder,
}) => {
  const path = `${cdnConfig.GAMIFICATION_MODULE}/${folder}`;

  const { height } = useWindowDimensions();
  const btnSize = height > 800 ? fitCover(204 * 2, 60 * 2) : fitCover(156 * 2, 46 * 2);
  const btnBg = `${config.CDN_IMAGE_API}${btnSize}${path}/button.png`;
  const btnClass = clsx('bg-contain', className);

  const showButton = (!errorText || statusCode === 511) && isVisible && buttonText;

  const animation = disabled
    ? {
        x: -2,
      }
    : {
        scale: 0.9,
      };

  return (
    <>
      {showButton ? (
        <StyledCTAButton className={btnClass} btnbg={btnBg} whileTap={animation} disabled={disabled} onClick={onClick}>
          {buttonText}
        </StyledCTAButton>
      ) : showPlaceholder ? (
        <StyledPlaceholder>{text}</StyledPlaceholder>
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(CTAButton);
