import React from 'react';
import { Flex } from 'components/shared/layout';

/** Auth Panel Body */
interface PanelBodyProps {
  children: React.ReactNode;
}

const PanelBody: React.FC<PanelBodyProps> = ({ children }) => {
  return (
    <Flex.Col className="auth-panel__body" data-ics--global data-ics--reg>
      {children}
    </Flex.Col>
  );
};

export default PanelBody;
