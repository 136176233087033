import { isMobile } from 'react-device-detect';
import { CasinoScroll } from './casinoScrollTypes';
import ScrollArrows from './components/ScrollArrows';
import ScrollContainer from './components/ScrollContainer';
import { useSetRefsLoaded } from '../../hooks/useSetRefsLoaded';

const ScrollWrapper: React.FC<CasinoScroll.ScrollWrapperProps> = ({
  children,
  centered,
  typeArrow,
  useDefaultChevron,
  moveWith = 0,
  forceArrowsUpdate,
}) => {
  const { refLoaded, setRefs, ref, inView } = useSetRefsLoaded(true);
  const scrollEl = ref.current?.firstElementChild;
  return (
    <ScrollContainer setRefs={setRefs}>
      {refLoaded && (
        <>
          {children}
          {centered && (
            <>
              <div className="left-shadow" />
              <div className="right-shadow" />
            </>
          )}

          {!isMobile && scrollEl && (
            <ScrollArrows
              centered={centered}
              scrollContainerRef={ref}
              typeArrow={typeArrow}
              useDefaultChevron={useDefaultChevron}
              moveWith={moveWith}
              inView={inView}
              forceArrowsUpdate={forceArrowsUpdate}
            />
          )}
        </>
      )}
    </ScrollContainer>
  );
};

export default ScrollWrapper;
