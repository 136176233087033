import React from 'react';
import clsx from 'clsx';

interface PageBoxTitleProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  title?: string;
  titleColor?: string;
}

const PageBoxTitle: React.FC<PageBoxTitleProps> = ({ className, title, titleColor, ...rest }) => {
  const titleClass = clsx('prm-page__title', className);
  return (
    <div className={titleClass} style={{ color: titleColor }} {...rest}>
      {title}
    </div>
  );
};

export default PageBoxTitle;
