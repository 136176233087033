import Descriptions from './Descriptions';
import Header from './Header';
import Link from './Link';
import Wrapper from './Wrapper';

export const PopoverUI = {
  Wrapper: Wrapper,
  Header: Header,
  Description: Descriptions,
  Link: Link,
};
