import { CasinoGamesTypes } from 'modules/casino/modules/container/cellsContainer/cellls/types/casinoGamesTypes';
import { GameTag } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/GameCellTagsTypes';
import {
  getBottomTag,
  getRightTag,
  getSortedTags,
  getTransparentBottomTag,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/gameCellTagsUtils';

type UseGameTagsReturnTypes = {
  showGameTags: boolean;
  jackpotId: string;
  tags: GameTag[];
  bottomTag: GameTag | undefined;
  rightTag: GameTag | undefined;
  hasTranspBottomTag: boolean | undefined;
};

export const useGameTags = (game: CasinoGamesTypes.GameProps): UseGameTagsReturnTypes => {
  const tags = game?.tags;
  const jackpotId = game?.jackpotId;
  const showGameTags = !!game?.tags;

  return {
    showGameTags,
    jackpotId,
    tags: getSortedTags(tags),
    bottomTag: getBottomTag({ tags, jackpotId }),
    rightTag: getRightTag({ tags }),
    hasTranspBottomTag: getTransparentBottomTag({ tags, jackpotId }),
  };
};
