import { combineReducers } from '@reduxjs/toolkit';
import tournamentsReducer from 'pages/promotions/promotionLeaderBoard/leaderBoardSlice';
import promotionsReducer from 'pages/promotions/slice/promotions.slice';
import prizeDropReducer from 'shared/common/features/PrizeDrop/slice/prizeDrop.slice';
import inGameNotificationsReducer from '../../shared/common/features/inGameNotifications/slice/inGameNotifications.slice';
import requestBetReducer from '../../shared/common/features/requestBet/slice/requestBet.slice';

export const promotionReducer = combineReducers({
  promotions: promotionsReducer,
  tournaments: tournamentsReducer,
  requestBet: requestBetReducer,
  prizeDrop: prizeDropReducer,
  inGameNotifications: inGameNotificationsReducer,
});
