import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import { useDataWrapper } from './useDataWrapper';

type UseGameDescriptionProps = {
  gameUniqueId: string;
  categoryId: string;
};

const useGameDescription = ({ gameUniqueId, categoryId }: UseGameDescriptionProps) => {
  const game = useGameProps({ gameUniqueId, categoryId });
  const { gameData } = useDataWrapper({
    game,
  });
  const { description, gameProviderId } = gameData;
  const { gridType } = game;

  return {
    description,
    gameProviderId,
    gridType,
  };
};

export default useGameDescription;
