import React, { HTMLAttributes, RefObject } from 'react';
import { Flex } from 'components/shared/layout';
import { cssGamificationNS } from 'utils/common/types/css.types';

interface WrapperProps extends HTMLAttributes<HTMLDivElement> {
  onClickHandler: () => void;
  title: string;
  wrapperRef?: RefObject<HTMLDivElement>;
  onAnimationStart: () => void;
  type?: string;
  hideTitle?: boolean;
}

export const Wrapper: React.FC<WrapperProps> = ({
  children,
  title,
  onClickHandler,
  wrapperRef,
  onAnimationStart,
  type,
  hideTitle,
  ...props
}) => {
  return (
    <div
      className={`${cssGamificationNS}-chall-cell ${cssGamificationNS}-chall-cell--${type} d-flex-col`}
      {...props}
      onClick={onClickHandler}
      ref={wrapperRef}
      onAnimationStart={onAnimationStart}
    >
      {!hideTitle && <div className={`${cssGamificationNS}-chall-cell__title text-truncate`}>{title}</div>}
      <Flex.Col className={`${cssGamificationNS}-chall-cell__inner`}>
        <div className={`${cssGamificationNS}-chall-cell__inner`}>{children}</div>
      </Flex.Col>
    </div>
  );
};
