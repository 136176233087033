import React from 'react';
import clsx from 'clsx';

/** Content */
interface ContentProps {
  className?: string;
  children?: React.ReactNode;
}

const Content: React.FC<ContentProps> = ({ className, children }) => {
  const wrapperClass = clsx('egtd-x-check-ticket-search', className);

  return <div className={wrapperClass}>{children}</div>;
};

export default Content;
