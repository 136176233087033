import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthUI } from 'pages/auth/components';
import ResendEmail from 'pages/auth/registrationConfirm/components/ResendEmail';
import { selectConfirmation } from 'pages/auth/registrationConfirm/slice/confirmation.slice';
import { useAppSelector } from 'store';
import useLoginErrors, { isUnverified } from './hooks/useLoginErrors';

type Props = { error: string; onClick: () => void; username: string };

const ErrorNotification: React.FC<Props> = ({ error, onClick, username }) => {
  const { t } = useTranslation();
  const { hasRedirect, preLinkText, linkText, postLinkText, linkTo } = useLoginErrors(error);
  const confirmationError = useAppSelector(selectConfirmation.submitError);
  const displayError = (isUnverified(error) && confirmationError) || error;

  return (
    <AuthUI.NotificationLine variant="error" icon="times-solid">
      <b>{t(displayError)}</b>

      {hasRedirect &&
        (!isUnverified(error) ? (
          <div>
            {t(preLinkText)}{' '}
            <Link to={linkTo} className="auth-msg__link" onClick={onClick}>
              {t(linkText)}
            </Link>{' '}
            {t(postLinkText)}
          </div>
        ) : (
          <ResendEmail
            emailOrUsername={username}
            linkClass="auth-msg__link"
            componentLocation="login"
            containerClass="auth-msg__error-text"
          />
        ))}
    </AuthUI.NotificationLine>
  );
};

export default ErrorNotification;
