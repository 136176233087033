import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getWindowConfigParam } from 'utils/common/helpersCommon';
import { changeLayoutConfig, setComponents, setSkin, setThemeColors } from './configurator.utils';
import { useAppDispatch } from '../../../../../store';
import { setConfiguratorData } from '../slice/integrations.slice';

type MassagesType = 'theme' | 'skin' | 'components' | 'layout';

const mapMassages = (type: MassagesType, data) => {
  const massages: Record<MassagesType, any> = {
    theme: (items) => setThemeColors(items),
    components: (items) => setComponents(items),
    skin: (items) => setSkin(items),
    layout: (items) => changeLayoutConfig(items),
  };

  return massages[type](data);
};

const useConfiguratorPostMessages = () => {
  const configuratorString = getWindowConfigParam('CONFIGURATOR');
  const configurator = configuratorString && JSON.parse(configuratorString);
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    configurator &&
      window.parent.postMessage(JSON.stringify({ isLoaded: true, location: location.pathname + location.search }), '*');
  }, [location, configurator]);

  useEffect(() => {
    if (configurator) {
      const onMessageReceived = (event) => {
        if (
          event.target.name === 'egtd-iframe' &&
          typeof event.data === 'string' &&
          (event.origin.indexOf('localhost') > 0 || event.origin === 'https://configurator.egt-digital.com')
        ) {
          const massages = JSON.parse(event.data);

          Object.keys(massages).map((massage) => {
            if (massages[massage as MassagesType] === 'theme') {
              document.documentElement?.removeAttribute('style');
            }
            if (massage === 'skin') {
              dispatch(setConfiguratorData({ skin: massages[massage as MassagesType] }));
            }
            mapMassages(massage as MassagesType, massages[massage as MassagesType]);
          });
        }
      };
      window.addEventListener('message', onMessageReceived, false);

      return () => {
        configurator && window.removeEventListener('message', onMessageReceived);
      };
    }
  }, [configurator]);
};

export default useConfiguratorPostMessages;
