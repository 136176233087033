import { useCallback, useEffect } from 'react';
import { useGameProps } from 'modules/casino/modules/container/cellsContainer/cellls/hooks/useGameProps ';
import { setGameOpenId } from 'modules/casino/modules/container/cellsContainer/cellls/slice/casinoGamesSlice';
import { useSendGameImpression } from 'modules/casino/modules/container/cellsContainer/containers/shared/features/categoryImpressions/hooks/useSendGameImpression';
import { disableSaveScrollPositionMap } from 'modules/casino/modules/container/cellsContainer/grid/components/gridCell/gridCellConstants';
import { GridLayouts } from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import { CategoryLayoutTypes } from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { useGridLayoutSettings } from 'modules/casino/shared/components/gridLayoutSettings/hooks/useGridLayoutSettings';
import { CategoryQueryType } from 'modules/casino/shared/constants';
import { setOpenGameData } from 'modules/casino/shared/features/gameLaunch/casinoLaunchSlice';
import { useAdditionalLaunchParams } from 'modules/casino/shared/features/gameLaunch/hooks/useAdditionalLaunchParams';
import { showRootCasinoModal } from 'modules/casino/shared/features/rootCasinoModal/rootCasinoModal.slice';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { useLoadingEffect } from 'modules/casino/shared/hooks/useLoadingEffect';
import usePlayerRestrictions from 'modules/casino/shared/hooks/usePlayerRestrictions';
import { useRestoreCellScrollPosition } from 'modules/casino/shared/hooks/useRestoreCellScrollPosition';
import { useAppDispatch } from 'store';
import { isCasinoFavBtn } from '../../gridLayoutWrappers/shared/GameCellFavouritesButton/gameCellFavouritesButtonUtils';
import { GameCellRestrictions } from '../../gridLayoutWrappers/shared/GameCellRestriction/gameCellRestriction.types';

type UseGameCellReturnTypes = {
  hasRestrictions: boolean;
  gameLayoutType: string;
  playerRestrictionsByProducts: GameCellRestrictions.Restriction[];
  onAnimationStart: (event) => void;
  hasFinishedLoading: () => void;
  handleButtonClick: (e, launchType) => void;
  handleWrapperButtonClick: (e) => void;
  setLayoutStyle: (styleProp: string, style: string | number) => void;
};

type UseGameCellProps = {
  gameUniqueId: string;
  gameCellRef: React.MutableRefObject<HTMLDivElement | null>;
  collectionId: string;
  categoryId: string;
  queryType: CategoryQueryType;
  gridPage: number;
  gamesRenderedCount: number;
  showGameCellFavButton: boolean;
  layoutGrid: GridLayouts;
  skipLazyLoad: boolean;
  gamesCount: number;
  categoryLayout: CategoryLayoutTypes;
  index: number;
  onShowItemWithoutAnimation: () => void;
  useLoadAnimation: boolean;
};

export const useGridCell = ({
  gameUniqueId,
  gameCellRef,
  collectionId,
  categoryId,
  gridPage,
  gamesRenderedCount,
  showGameCellFavButton,
  queryType,
  skipLazyLoad,
  gamesCount,
  categoryLayout,
  index,
  onShowItemWithoutAnimation,
  useLoadAnimation,
}: UseGameCellProps): UseGameCellReturnTypes => {
  const dispatch = useAppDispatch();
  const {
    id,
    providerAlias,
    gameLayout,
    refGameId,
    showInfoPage,
    uniqueId,
    providerId,
    description,
    iframeLoad,
    gameType,
    layoutModifier,
    shouldLoad,
    betType,
    customFreePlay,
    subType,
  } = useGameProps({ gameUniqueId, categoryId });
  const { isTrackable, layoutConfig } = useGetCategoryParams(categoryId, categoryLayout);
  const { gridAnimationClass } = useGridLayoutSettings(subType);
  const { playerRestrictionsByProducts, hasRestrictions } = usePlayerRestrictions({
    products: [betType],
  });
  const disableSaveScrollPosition = disableSaveScrollPositionMap.includes(subType);
  useRestoreCellScrollPosition({ gameCellRef, disableSaveScrollPosition, uniqueId, collectionId, refGameId });
  const extendedCollectionId = layoutModifier === 'SPECIAL' ? `${collectionId}${layoutModifier}` : collectionId;
  const { getAdditionalLaunchParams } = useAdditionalLaunchParams(queryType);
  const { hasFinishedLoading, onAnimationStart } = useLoadingEffect(
    gameCellRef,
    extendedCollectionId,
    uniqueId,
    gridAnimationClass,
    onShowItemWithoutAnimation,
    useLoadAnimation,
    queryType,
    skipLazyLoad,
    shouldLoad,
  );

  const gameLayoutType = gameLayout?.toLowerCase();
  // const { sendRecommendationGameData } = usePostRecommendationGameClick({
  //   gameUniqueId,
  //   queryType,
  //   categoryLayout,
  //   categoryId,
  //   categoryIdKey: categoryId,
  // });

  const { triggerGameTrackingEvent } = useSendGameImpression({
    gameUniqueId,
    gamePosition: index,
    categoryLayout,
    categoryId,
  });

  const handleButtonClick = useCallback(
    (e, gamePlayType) => {
      e.preventDefault();
      if (!hasRestrictions) {
        dispatch(setGameOpenId(`${refGameId}_${new Date().getTime()}`));
        const additionalLaunchParams = getAdditionalLaunchParams({
          refGameId,
          svaraParams: {
            gamePlayType,
            isFreeCustom: customFreePlay,
          },
        });

        const scrollPositionData = {
          collectionId,
          gridPage,
          gamesRenderedCount,
          gamesCount,
          uniqueId,
        };

        dispatch(
          setOpenGameData({
            description,
            gameType,
            refGameId,
            providerId,
            providerAlias,
            iframeLoad,
            gameId: id,
            gamePlayType,
            isTrackable,
            ...(!disableSaveScrollPosition && { scrollPositionData }),
            additionalLaunchParams,
          }),
        );
        triggerGameTrackingEvent(gamePlayType);
        // sendRecommendationGameData();
      }
    },
    [gamesCount],
  );

  const setLayoutStyle = (styleProp, style) => {
    if (gameCellRef.current) {
      gameCellRef.current.style[styleProp] = style;
    }
  };

  const handleWrapperButtonClick = (e) => {
    if ((showGameCellFavButton && isCasinoFavBtn(e)) || hasRestrictions) {
      return;
    }

    if (showInfoPage) {
      dispatch(
        showRootCasinoModal({
          modalType: 'GAME_INFO_MODAL',
          modalProps: {
            visible: true,
            gameUniqueId,
            handleButtonClick,
            categoryId,
            layoutGrid: layoutConfig.layoutGrid,
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (hasRestrictions) {
      gameCellRef.current?.classList.add('restricted');
    }
  }, [hasRestrictions]);

  return {
    gameLayoutType,
    playerRestrictionsByProducts,
    hasRestrictions,
    hasFinishedLoading,
    onAnimationStart,
    handleButtonClick,
    handleWrapperButtonClick,
    setLayoutStyle,
  };
};
