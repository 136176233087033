import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getCookie, getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { ConfirmationThunk, ConfirmationType } from '../types/confirmation.types';

const initialState: ConfirmationType = {
  submitError: '',
  isValidToken: false,
  isExpiredToken: false,
  isLoading: true,
  successConfirmation: false,
  resendEmail: false,
  userEmail: '',
};

export const ConfirmationThunks: ConfirmationThunk = {
  validateUser: createAbortThunk('confirmation/validateUser', async (arg, { rejectWithValue, dispatch }) => {
    try {
      const fbpCookieValue = getCookie('_fbp');
      const fbcCookieValue = getCookie('_fbc');
      const url = `${config.API_URL}/api/ews-crm/public/player/confirm-email`;

      const queryParams = {
        token: encodeURI(arg.urlToken),
        email: arg.urlEmail,
        ...(fbpCookieValue && { fbp: encodeURIComponent(fbpCookieValue) }),
        ...(fbcCookieValue && { fbc: encodeURIComponent(fbcCookieValue) }),
      };

      const response = await axiosInstance.get(url, { params: queryParams });
      if (response?.status) {
        if (response.status === 200) {
          return;
        }
      }
    } catch (err) {
      if ((err as AxiosError).response?.status === EXPIRED_TOKEN_STATUS_CODE) {
        dispatch(setExpiredTokend());
      }
      return rejectWithValue(getError.responseDataMessage(err));
    }
  }),
  resendConfirmationEmail: createAbortThunk(
    'confirmation/resendConfirmationEmail',
    async (arg, { rejectWithValue }) => {
      try {
        const url = `${config.API_URL}/api/ews-crm/public/player/send-confirm-email`;
        const queryParams = {
          emailOrUsername: arg.emailOrUsername,
        };

        const response = await axiosInstance.get(url, { params: queryParams });
        if (response?.status && response.status === 200) {
          return;
        }
      } catch (err) {
        const errorResponse = (err as { response })?.response;
        // status: 404 comes as data, status: 406 comes as data.message
        const errorMessage = errorResponse?.data?.message || errorResponse?.data;
        const value = typeof errorMessage === 'string' ? errorMessage : getError.default();
        return rejectWithValue(value);
      }
    },
  ),
};

const EXPIRED_TOKEN_STATUS_CODE = 419;

const confirmationSlice = createSlice({
  name: 'confirm-registration',
  initialState,
  reducers: {
    resetConfirmationState(state) {
      state.isValidToken = false;
      state.submitError = '';
      state.resendEmail = false;
      state.userEmail = '';
    },
    setConfirmationUserEmail(state, action) {
      state.userEmail = action.payload;
    },
    setExpiredTokend(state) {
      state.isExpiredToken = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ConfirmationThunks.validateUser.fulfilled, (state) => {
        state.isValidToken = true;
        state.isLoading = false;
      })
      .addCase(ConfirmationThunks.validateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        ConfirmationThunks.validateUser.rejected,
        (state, action: PayloadAction<string | { rejectValue: string } | null | undefined>) => {
          state.isValidToken = false;
          state.isLoading = false;
          state.submitError = action.payload as string;
        },
      );
    builder
      .addCase(ConfirmationThunks.resendConfirmationEmail.pending, (state) => {
        state.isLoading = true;
        state.resendEmail = false;
        state.submitError = '';
      })
      .addCase(ConfirmationThunks.resendConfirmationEmail.fulfilled, (state) => {
        state.resendEmail = true;
        state.isLoading = false;
      })
      .addCase(
        ConfirmationThunks.resendConfirmationEmail.rejected,
        (state, action: PayloadAction<string | { rejectValue: string } | null | undefined>) => {
          state.isLoading = false;
          state.submitError = action.payload as string;
        },
      );
  },
});

export const { resetConfirmationState, setExpiredTokend, setConfirmationUserEmail, endLoading } =
  confirmationSlice.actions;

export default confirmationSlice.reducer;

export const selectConfirmation = {
  isLoading: (state: RootState) => state.auth.registrationConfirm?.isLoading,
  isValidToken: (state: RootState) => state.auth.registrationConfirm?.isValidToken,
  submitError: (state: RootState) => state.auth.registrationConfirm?.submitError,
  resendEmail: (state: RootState) => state.auth.registrationConfirm?.resendEmail,
  userEmail: (state: RootState) => state.auth.registrationConfirm?.userEmail,
};
