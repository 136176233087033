import React, { memo } from 'react';
import { useSetRefsLoaded } from 'modules/casino/shared/hooks/useSetRefsLoaded';
import { useAppSelector } from 'store';
import JackpotLevel from './JackpotLevel';
import { useJackpotLastWinner } from '../../hooks/useJackpotLastWinner';
import { jackpotSelectors } from '../../jackpotSlice';
import JackpotTooltip from '../JackpotTooltip';

interface Props {
  idx: number;
  level: string;
  providerId: string;
  jackpotId: string;
  panelUrl: string;
  stopOdometer?: boolean;
  fromWidget?: boolean;
  fromBanner?: boolean;
}

const JackpotLevelWrapper: React.FC<Props> = ({
  idx,
  level,
  providerId,
  jackpotId = '0',
  panelUrl,
  stopOdometer = false,
  fromWidget,
  fromBanner,
}) => {
  const currency = useAppSelector((state) => jackpotSelectors.selectJackpotCurrencySign(state, jackpotId));
  const { refLoaded, setRefs, ref } = useSetRefsLoaded();

  const { showJackpotDetails, hideJackpotDetails, startTouch } = useJackpotLastWinner(
    jackpotId,
    level,
    providerId,
    currency,
  );

  return (
    <div
      className={`jackpot__numbers d-flex-center level${idx}`}
      ref={setRefs}
      onMouseDown={showJackpotDetails}
      onMouseEnter={showJackpotDetails}
      onMouseLeave={hideJackpotDetails}
    >
      {startTouch && (
        <JackpotTooltip
          jackpotId={jackpotId}
          level={level}
          providerId={providerId}
          currency={currency}
          fromWidget={fromWidget}
          hideTooltip={fromBanner}
        />
      )}
      {refLoaded && (
        <JackpotLevel
          level={level}
          jackpotId={jackpotId}
          stopOdometer={stopOdometer}
          panelUrl={panelUrl}
          refObj={ref}
        />
      )}
    </div>
  );
};

export default memo(JackpotLevelWrapper);
