import React, { memo } from 'react';
import clsx from 'clsx';
import Icon from 'components/shared/Icon';
import SVGCDN from 'components/shared/UI/SVGCDN/SVGCDN';
import { cdnPaths } from 'components/shared/UI/SVGCDN/SVGCDN.types';
import { AppHeaderUI } from '../AppHeaderUI';

interface UserButtonProps {
  className?: string;
  onClick: React.MouseEventHandler;
  iconClassName?: string;
  icon: string;
  useCDNIcon?: boolean;
  cdnIconPath?: keyof typeof cdnPaths;
  notificationsCounter: number;
  hasUnseenBonusOffers?: boolean;
  isActive?: boolean;
  dataQid?: string;
}

const NavUserButton: React.FC<UserButtonProps> = ({
  className,
  onClick,
  iconClassName,
  icon,
  useCDNIcon,
  cdnIconPath,
  notificationsCounter,
  hasUnseenBonusOffers,
  isActive,
  dataQid,
}) => {
  const btnClass = clsx('align-self-center nav-user__btn', isActive && 'nav-user__btn--active', className);
  const iconClass = clsx('nav-user__btn-icon', iconClassName);

  return (
    <div className={btnClass} onClick={onClick} data-qid={dataQid}>
      {useCDNIcon ? (
        <SVGCDN className={iconClass} path={cdnIconPath} icon={icon} />
      ) : (
        <Icon className={iconClass} icon={icon} />
      )}
      <AppHeaderUI.Badge
        isVisible={notificationsCounter > 0}
        isActive={hasUnseenBonusOffers}
        text={notificationsCounter}
      />
    </div>
  );
};

export default memo(NavUserButton);
