import { updateNotifications } from 'shared/common/features/inGameNotifications/slice/inGameNotifications.slice';
import { WsNotificationsTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/types/wsNotifications.types';
import { setActiveCampaignId } from 'shared/common/features/PrizeDrop/slice/prizeDrop.slice';
import { hideRootModal, showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch } from 'store';

export const useWinModalTriggers = (
  activeNotification: WsNotificationsTypes.Notification,
): { handleInfo: () => void; handleClose: () => void; bonus: WsNotificationsTypes.Bonus } => {
  const dispatch = useAppDispatch();
  const bonus = activeNotification?.BonusData?.bonuses?.[0];
  const handleClose = () => {
    dispatch(updateNotifications(activeNotification.Id));
    dispatch(hideRootModal());
  };

  const handleInfo = () => {
    const campaignId = bonus?.campaignId;
    handleClose();

    if (campaignId) dispatch(setActiveCampaignId(campaignId));
    dispatch(
      showRootModal({
        modalType: 'PRIZE_DROP_CAMPAIGNS_MODAL',
        modalPriority: ModalPriorityEnum.MEDIUM,
        modalId: 'PRIZE_DROP_CAMPAIGNS_MODAL',
      }),
    );
  };

  return { handleInfo, handleClose, bonus };
};
