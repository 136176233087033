import { Buffer } from 'buffer';
import { decode } from '@msgpack/msgpack';
import { GamesTypes } from '../types/games.types';
export const getUniqueUuidv4 = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const delimiter = '/';
export const getGameIDfromUrl = (apiUrl?: string): number | undefined => {
  if (!apiUrl) return undefined;
  const lastIndex = apiUrl?.lastIndexOf(delimiter);

  if (lastIndex === -1) {
    return undefined;
  }

  const gameId = apiUrl.substring(lastIndex + '/'.length);

  return Number(gameId);
};

export const parseGameData = (notification: string): GamesTypes.MyGameType => {
  const decodedResponse = decode(Buffer.from(notification, 'base64')) as string;

  return JSON.parse(decodedResponse);
};
