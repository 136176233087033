import { useEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import clsx from 'clsx';
import { useMyProfile } from 'shared/common/features/myProfile/hooks/useMyProfile';
import { useMyProfileConfig } from './hooks/useMyProfileConfig';
import { useMyProfileOnScroll } from './hooks/useMyProfileOnScroll';
import { MyProfileAccountSection, MyProfileStatisticsLayout1, MyProfileStatisticsLayout2 } from './sections';
import { MyProfileGamificationSectionInfo, MyProfileSectionInfo } from './sections/info';
import { useGetMainNavBarButtonsLayout } from '../appHeader/components/mainNavbar/mainNavbarButtons/hooks/useGetMainNavBarButtonsLayout';
import { useGamificationWidgetConfig } from '../integrations/gamification/hooks/useGamificationWidgetConfig';

const balanceComponentMap = {
  layout_1: <MyProfileStatisticsLayout1 />,
  layout_2: <MyProfileStatisticsLayout2 />,
};

export const myProfileSectionInfoMap: Record<'default' | 'gamification', React.FC<{ showBalanceToggler?: boolean }>> = {
  default({ showBalanceToggler }: { showBalanceToggler?: boolean }) {
    return <MyProfileSectionInfo showBalanceToggler={showBalanceToggler} />;
  },
  gamification() {
    return <MyProfileGamificationSectionInfo />;
  },
};

const MyProfile = () => {
  const myProfileRef = useRef<HTMLDivElement>(null);
  const { appMainNavHeight } = useMyProfile({ myProfileRef });
  const { onScroll } = useMyProfileOnScroll({ myProfileRef });
  const layout = useMyProfileConfig()?.layout || 'layout_1';
  const { isWidget, isVisible: gamificationIsVisible } = useGamificationWidgetConfig();
  const { typeItem } = useGetMainNavBarButtonsLayout({ type: 'language' });
  useEffect(() => {
    if (myProfileRef && myProfileRef.current) {
      disableBodyScroll(myProfileRef.current);
    }
    const handleResize = () => {
      if (myProfileRef && myProfileRef.current) {
        if (typeItem) {
          const pos = myProfileRef.current.getBoundingClientRect();
          !myProfileRef.current.style.right &&
            (myProfileRef.current.style.right = `-${window.innerWidth - (pos.left + pos.width)}px`);
        }
        myProfileRef.current.style.height = `${window.innerHeight - appMainNavHeight}px`;
        setTimeout(() => {
          myProfileRef.current && (myProfileRef.current.style.height = `${window.innerHeight - appMainNavHeight}px`);
        }, 300);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    return () => {
      if (myProfileRef && myProfileRef.current) {
        enableBodyScroll(myProfileRef.current);
      }
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, [myProfileRef, typeItem]);

  const BalanceComponent = balanceComponentMap[layout] || null;
  const MyProfileSectionInfoComponent =
    myProfileSectionInfoMap[gamificationIsVisible && isWidget ? 'gamification' : 'default'] ||
    myProfileSectionInfoMap['default'];
  const classMenu = clsx(`d-flex-col custom-scrollbars mpd-menu mpd-menu-${layout}`, typeItem && 'mpd-menu-offset');

  return (
    <div ref={myProfileRef} onScroll={onScroll} className={classMenu}>
      <MyProfileSectionInfoComponent showBalanceToggler={layout === 'layout_2'} />
      {/* <MyProfileSectionInfo showBalanceToggler={layout === 'layout_2'} /> */}
      {BalanceComponent}
      <MyProfileAccountSection parentRef={myProfileRef} />
    </div>
  );
};

export default MyProfile;
