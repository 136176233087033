import { memo, useCallback, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useMediaQuery } from 'react-responsive';
import { useOnOff } from 'hooks/common/useOnOff';
import { usePrevious } from 'hooks/usePrevious';
import { loginThunks, selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { selectRootModal, showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { Breakpoints } from 'theme/Theme';
import { isTerminal } from 'utils/common/helpersCommon';
import { useSessionTimerPause } from './hooks/useSessionTimerPause';
import useTrackGameLaunchWindows, {
  removeGameLaunchWindow,
  setGameLaunchWindow,
} from './hooks/useTrackGameLaunchWindows';
import { setIsSessionTimerActive } from '../features/general/slice/general.slice';
import { useInactivityCheckConfig } from '../features/myProfile/hooks/useInactivityCheckConfig';
import { ModalPriorityEnum } from '../features/rootModal/types/modal.types';
import { CustomPlatformEvents } from '../hooks/useHandleCustomEvents';

const SESSION_INACTIVITY_TIMEOUT = 1000 * 60 * 30;
const DEBOUNCE_TIMEOUT = 1000;

const SessionInactivity = () => {
  useTrackGameLaunchWindows();

  const [isVisible, setIsVisible] = useOnOff(true);
  const [paused, setPaused] = useOnOff();
  const modalType = useAppSelector(selectRootModal.type);
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const prevModalType = usePrevious(modalType);
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.isTabletOrMobile}px)` });
  const dispatch = useAppDispatch();
  const inactivityCheckConfig = useInactivityCheckConfig();

  const timeoutInterval = inactivityCheckConfig?.interval
    ? inactivityCheckConfig.interval * 1000 * 60
    : SESSION_INACTIVITY_TIMEOUT;

  useEffect(() => {
    if (
      (prevModalType === 'SESSION_INACTIVITY' && modalType === null) ||
      (prevModalType === 'TIMEOUT' && modalType === null)
    ) {
      handleReset();
    }
    if (modalType === 'TIMEOUT') {
      handlePause();
    }
  }, [modalType]);

  useEffect(() => {
    function visibilityChange() {
      if (document.visibilityState === 'visible') {
        setIsVisible(true);
        setGameLaunchWindow(window.name);
      } else {
        removeGameLaunchWindow(window.name);
        setIsVisible(false);
      }
    }
    document.addEventListener('visibilitychange', visibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', visibilityChange);
    };
  }, []);

  const handleOnIdle = () => {
    if (isTerminal()) {
      return null;
    }
    if (isAuthenticated) {
      if (isVisible) {
        document.dispatchEvent(new Event(CustomPlatformEvents.HIGH_PRIORITY_MODAL));
        dispatch(
          showRootModal({
            modalType: 'SESSION_INACTIVITY',
            modalPriority: ModalPriorityEnum.HIGH,
            modalId: 'SESSION_INACTIVITY',
            modalProps: !inactivityCheckConfig?.timeout
              ? undefined
              : {
                  type: inactivityCheckConfig.timeout.type,
                  time: inactivityCheckConfig.timeout.time,
                },
          }),
        );
      } else {
        dispatch(loginThunks.logout());
      }
    } else {
      if (!isTabletOrMobile) {
        document.dispatchEvent(new Event(CustomPlatformEvents.HIGH_PRIORITY_MODAL));
        dispatch(showRootModal({ modalType: 'TIMEOUT', modalPriority: ModalPriorityEnum.HIGH, modalId: 'TIMEOUT' }));
      }
    }
  };

  const { reset, pause } = useIdleTimer({
    timeout: timeoutInterval,
    onIdle: handleOnIdle,
    debounce: DEBOUNCE_TIMEOUT,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      // 'visibilitychange',
      // 'scroll',
    ],
  });

  const handleReset = useCallback(() => {
    reset();
    setPaused(false);
    dispatch(setIsSessionTimerActive(true));
  }, [reset, setPaused, dispatch]);

  const handlePause = useCallback(() => {
    pause();
    setPaused(true);
  }, [pause, setPaused]);

  useSessionTimerPause({ handlePause, handleReset, paused });

  return <></>;
};

export default memo(SessionInactivity);
