import { useCallback, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { selectBackdropVisibility, setAppBackdrop } from '../../appBackdrop';
import { fetchCategories } from '../../myProfile/sections/tabs';
import { setBottomTabBarHeight, setBottomTabBarVisbility } from '../slice/bottomTabBar.slice';

export const useManageBottomTabBar = ({ isBottomTabBar }: { isBottomTabBar?: boolean }) => {
  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const isBackdropVisible = useAppSelector(selectBackdropVisibility);
  const dispatch = useAppDispatch();
  const bottomRef = useRef<HTMLDivElement | null>(null);

  const updateBottomHeight = useCallback(
    (node) => {
      const paddingBottomStyle = window.getComputedStyle(node);
      const padding = paddingBottomStyle.getPropertyValue('padding-bottom')?.split('px');
      const paddingSize = padding?.[0] != '' ? JSON.parse(padding?.[0]) : 0;
      dispatch(setBottomTabBarHeight(node.clientHeight - paddingSize));
    },
    [dispatch],
  );

  const setBottomRef = useCallback(
    (node) => {
      node && (bottomRef.current = node);
      if (node) {
        updateBottomHeight(node);
      }
    },
    [bottomRef, updateBottomHeight],
  );

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchCategories());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (bottomRef.current) {
      updateBottomHeight(bottomRef.current);
    }
  }, [isLandscape, dispatch, updateBottomHeight]);

  useEffect(() => {
    if (isBottomTabBar) {
      dispatch(setBottomTabBarVisbility(true));
    } else {
      dispatch(setBottomTabBarVisbility(false));
    }
    return () => {
      dispatch(setBottomTabBarVisbility(false));
    };
  }, [dispatch, isBottomTabBar]);

  useEffect(() => {
    isBackdropVisible && dispatch(setAppBackdrop(true));
    return () => {
      dispatch(setAppBackdrop(false));
    };
  }, [dispatch, isBackdropVisible]);
  return { setBottomRef };
};
