import React from 'react';
import { ThemedIcon } from 'theme/Theme';
import config from 'utils/config';

/** DrawerUI Logo */
interface LogoProps {
  logo: string;
}

const Logo: React.FC<LogoProps> = ({ logo }) => {
  return (
    <img
      className="drawer-link__logo"
      src={`${config.CDN_URL}/gaming-content/game-providers-new/logos/${logo}${ThemedIcon()}.svg`}
    />
  );
};

export default Logo;
