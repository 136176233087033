import React from 'react';
import { Flex } from 'modules/casino/shared/components/layout';
import { jackpotWinModalPrefix } from '../../utils/gameLaunchBonusConstants';

type Props = {
  infoText: string;
  handleInfo: () => void;
  infoLinkText: string;
  btnText: string;
  handleClose: () => void;
};

const JackpotWinModalFooter: React.FC<Props> = ({ handleClose, infoText, infoLinkText, handleInfo, btnText }) => {
  return (
    <Flex.ACJB className={`${jackpotWinModalPrefix}__footer`}>
      <div className={`${jackpotWinModalPrefix}__footer-link`}>
        {infoText}{' '}
        <u className="cursor-pointer" onClick={handleInfo}>
          {infoLinkText}
        </u>
      </div>

      <div className={`${jackpotWinModalPrefix}__footer-btn cursor-pointer`} onClick={handleClose}>
        {btnText}
      </div>
    </Flex.ACJB>
  );
};

export default JackpotWinModalFooter;
