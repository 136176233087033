import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Axios from 'axios';
import { updateSports } from 'components/shared/sportContainers/utils/sportLobby.utils';
import { General } from 'shared/common/features/general/types/generalSlice.types';
import { Container } from 'shared/common/features/navigationMenu/types/navigationMenu.types';
import { RootState } from 'store/rootReducer';
import { createAbortThunk, rejectedWrapper } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError, isEmpty, setFavouriteMarketTemplates } from 'utils/common/helpersCommon';
import { ContainerTypes } from 'utils/common/types/common.types';
import { ET, InPlayTypes, Sport, SportLobby } from 'utils/common/types/sportTypes';
import config from 'utils/config';
import { sportsConfigMock } from './sportConfigMock';
import {
  assignEventsCountInView,
  normalizeEventsFunc,
  selectEvent,
} from '../../../components/shared/sportContainers/event/utils/event.utils';
import { HomePageModule } from '../types/homePage.types';

export const homePageThunks: HomePageModule.Thunks = {
  fetchHomePage: createAbortThunk('homePage/fetchConfig', async (containerUrl, { rejectWithValue, signal }) => {
    try {
      const source = Axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await axiosInstance.get<HomePageModule.ApiContainerData>(
        `${config.API_URL}/api/cms/public/landing-page/containers/${containerUrl}`,
        {
          cancelToken: source.token,
        },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseDataMessage(err));
    }
  }),
  fetchContainer: createAbortThunk('homePage/fetchContainer', async (arg, { rejectWithValue, signal }) => {
    const { apiUri } = arg;
    try {
      const source = Axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const response = await axiosInstance.get<HomePageModule.ApiContainerData>(`${config.API_URL}${apiUri}`, {
        cancelToken: source.token,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(getError.responseDataMessage(err));
    }
  }),
  fetchLiveSports: createAbortThunk('homePage/fetchLiveSports', async (_, { source }) => {
    const response = await axiosInstance.get<InPlayTypes.LiveSport[]>(
      `${config.API_URL}/api/sportsapi/public/sports/live`,
      {
        cancelToken: source.token,
      },
    );
    return response.data;
  }),
  fetchLiveSportEventsCount: createAbortThunk('homepage/fetchLiveSportEventsCount', async () => {
    const url = `${config.API_URL}/api/sportsapi/public/sport-events/live/count`;
    const response = await axiosInstance.get(url);

    return response.data;
  }),
  fetchEventStatusConfig: createAbortThunk('homepage/fetchEventStatusConfig', async () => {
    const url = `${config.API_URL}/api/sportsapi/public/Sports/config`;
    const response = await axiosInstance.get(url);

    return response.data;
  }),
};

const initialState: HomePageModule.State = {
  vertical: {
    containers: [],
    layout: [],
    offsetPerDevice: {},
    hasSidesOffset: false,
    status: 'idle',
  },
  addFooter: false,
  containers: {},
  normalizedEvents: {},
  liveSports: [],
  routingKeys: [],
  liveSportEventsCount: null,
  eventStatusConfig: null,
};

const homePageSlice = createSlice({
  name: 'homePageSlice',
  initialState,
  reducers: {
    resetContainerData: () => initialState,
    setHomePageCotainers: (state, action: PayloadAction<{ containers: Container[]; layout: General.RowColumn[] }>) => {
      state.vertical.containers = action.payload.containers;
      state.vertical.layout = action.payload.layout;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(homePageThunks.fetchHomePage.fulfilled, (state, action) => {
        state.vertical.layout = action.payload.layoutConfig?.configs;
        state.vertical.containers = action.payload.containers;
        state.vertical.offsetPerDevice = action.payload.offsetPerDevice;
        state.vertical.hasSidesOffset = action.payload.hasSidesOffset;
        state.addFooter = !isEmpty(action.payload?.footer?.sections);

        state.vertical.status = 'succeeded';
      })
      .addCase(homePageThunks.fetchHomePage.rejected, (state) => {
        state.vertical.status = 'failure';
      })
      .addCase(homePageThunks.fetchContainer.pending, (state, action) => {
        const { containerId, apiUri } = action.meta.arg;

        if (!state.containers[containerId]?.fetched) {
          state.containers[containerId] = {
            containerId,
            containerName: '',
            status: 'pending',
            fetched: false,
            error: null,
            apiUri,
            sports: [],
            landingData: {},
          };
        }
      })
      .addCase(
        homePageThunks.fetchContainer.rejected,
        rejectedWrapper((state, action) => {
          const { containerId, apiUri } = action.meta.arg;

          state.containers[containerId] = {
            containerId,
            status: 'failure',
            error: action.error,
            fetched: true,
            apiUri,
          };
        }),
      )
      .addCase(homePageThunks.fetchContainer.fulfilled, (state, action) => {
        const { containerId, apiUri, replaceEvent, containerType, pollingChangeEvent } = action.meta.arg;

        const normalizedSports: SportLobby.SportData[] = !isEmpty(action.payload.sports)
          ? assignEventsCountInView({
              sportsOrTournaments: action.payload.sports,
            })
          : [];

        if (!pollingChangeEvent) {
          state.containers[containerId] = {
            ...state.containers[containerId],
            containerId,
            containerName: action.payload?.containerName,
            navigationUrl: action.payload?.navigationUrl,
            sportId: action.payload?.sportId,
            sports: normalizedSports,
            eventIds: action.payload?.eventIds,
            apiUri,
            containerType,
            status: 'succeeded',
            fetched: true,
            error: null,
            landingData: action.payload,
          };
        } else {
          updateSports(state.containers[containerId], normalizedSports, action.payload?.eventIds);
        }

        const isMultiline = false;
        const marketProps = isMultiline ? { marketProps: { live: true } } : {};
        const sportsConfig = isMultiline ? { sports: action.payload?.sports || [] } : {};
        const isNewSlider = !!(
          containerType === ContainerTypes.slider && action.payload?.sports?.find((s) => s.sliderInfo?.marketId)
        );
        const addMarkets = isNewSlider;

        state.normalizedEvents = normalizeEventsFunc({
          replaceEvent,
          pollingChangeEvent,
          addMarkets,
          events: action.payload?.events,
          normalizedEvents: state.normalizedEvents,
          eventProps: { containerId, isMultiline, containerType, containersIds: [containerId] },
          ...marketProps,
          ...sportsConfig,
        }) as Record<string, ET.SportLobby_NormalizedEvent>;
      })
      .addCase(homePageThunks.fetchLiveSportEventsCount.fulfilled, (state, action) => {
        state.liveSportEventsCount = action.payload;
      })
      .addCase(homePageThunks.fetchLiveSports.fulfilled, (state, action) => {
        state.liveSports = action.payload;
      })
      .addCase(homePageThunks.fetchEventStatusConfig.fulfilled, (state, action) => {
        state.eventStatusConfig = action.payload;
        setFavouriteMarketTemplates(action.payload);
      })
      .addCase(homePageThunks.fetchEventStatusConfig.rejected, (state) => {
        state.eventStatusConfig = sportsConfigMock;
        setFavouriteMarketTemplates(sportsConfigMock);
      });
  },
});

export const { resetContainerData, setHomePageCotainers } = homePageSlice.actions;

export default homePageSlice.reducer;

export const homePageEventSelectors = selectEvent((state) => state.common.homePage.normalizedEvents);

export const selectHomePage = {
  containers: (state: RootState): Container[] => state.common.homePage.vertical.containers,
  layout: (state: RootState): General.RowColumn[] => state.common.homePage.vertical.layout,
  offsetPerDevice: (state: RootState) => state.common.homePage.vertical.offsetPerDevice,
  hasSidesOffset: (state: RootState) => state.common.homePage.vertical.hasSidesOffset,
  addLandingFooter: (state: RootState): boolean => state.common.homePage.addFooter,
  containerById:
    (containerId: number) =>
    (state: RootState): SportLobby.Container =>
      state.common.homePage.containers[containerId],
  liveSports: (state: RootState): InPlayTypes.LiveSport[] => state.common.homePage.liveSports,
  routingKeys: (state: RootState): string[] => state.common.homePage.routingKeys,
  pageStatus: (state: RootState): Sport.StateStatus => state.common.homePage.vertical.status,
  liveEventsCount: (state: RootState): number | null => state.common.homePage.liveSportEventsCount,
  eventStatusConfig: (state: RootState) => state.common.homePage.eventStatusConfig,
};

export const selectHomeFooterContainer = createSelector(selectHomePage.containers, (containers) =>
  containers.find((container) => container.type === 'footer'),
);
