import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { Flex } from 'components/shared/layout';

/** Terminal Modal Body Section */
interface BodyProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children: React.ReactNode;
}

const Body: React.FC<BodyProps> = ({ className, children }) => {
  const bodyClass = clsx('terminal-modal__body', className);
  return <Flex.ColAC className={bodyClass}>{children}</Flex.ColAC>;
};

export default Body;
