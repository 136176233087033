import {
  addFavGameId,
  removeFavGame,
} from 'modules/casino/modules/container/cellsContainer/cellls/additionalGames/favourites/slice/casinoFavouriteGamesSlice';
import { resetLastPlayedCollection } from 'modules/casino/modules/container/cellsContainer/cellls/slice/casinoGamesSlice';
import { useAppDispatch } from 'store';
import { resetLaunchData } from '../../casinoLaunchSlice';

const useOpenNewWindowsEventListeners = ({ openWindowsCollection }) => {
  const dispatch = useAppDispatch();

  const handleGameListUpdated = (event: CustomEvent) => {
    if (!event.detail) return;
    const { isInFavourites, gameId } = event.detail;
    !isInFavourites && gameId && dispatch(removeFavGame(gameId));
    isInFavourites && gameId && dispatch(addFavGameId(gameId));
    const favUpdateEvent = new CustomEvent('favouriteGamesUpdateFromParent', {
      detail: { inFavourite: isInFavourites, gameId: gameId },
    });
    openWindowsCollection.forEach((windowObj: Window) => {
      //Sending back a post message for favourites update to represent accordingly in all windows opened.
      windowObj.dispatchEvent(favUpdateEvent);
    });
  };

  const handleResizeWindowByGameRatio = (event: CustomEvent) => {
    const windowName = event.detail.windowName;
    openWindowsCollection.forEach((windowObj: Window) => {
      if (windowObj.name === windowName) {
        // const ratioType = event.detail.ratioType;
        // windowObj.resizeBy(0, -100);
      }
    });
  };
  const handleGameLaunchSuccess = (event: CustomEvent) => {
    dispatch(resetLastPlayedCollection());
    event.preventDefault();
  };

  const addEventListeners = (windowRef: Window) => {
    windowRef.addEventListener('beforeunload', (() => {
      dispatch(resetLaunchData());
    }) as EventListener);

    windowRef.addEventListener('gameLaunchSuccess', ((event: CustomEvent) =>
      handleGameLaunchSuccess(event)) as EventListener);

    windowRef.addEventListener('favouriteGamesUpdateFromChild', ((event: CustomEvent) =>
      handleGameListUpdated(event)) as EventListener);

    windowRef.addEventListener('resizeWindowByGameRatio', ((event: CustomEvent) =>
      handleResizeWindowByGameRatio(event)) as EventListener);
  };

  return { addEventListeners };
};

export default useOpenNewWindowsEventListeners;
