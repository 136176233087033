import { useCallback, useMemo } from 'react'; // SyntheticEvent is for the currently commented functionality
import { useLocation, useNavigate } from 'react-router-dom';
import useMyBetsRoute from 'hooks/common/useMyBetsRoute';
import { configRoutes } from 'hooks/common/useVerticalsConfig';
import { setIsFavouriteGamesModalOpen } from 'modules/casino/modules/container/cellsContainer/cellls/additionalGames/favourites/slice/casinoFavouriteGamesSlice';
import { useAppDispatch, useAppSelector } from 'store';
import { LocationType } from 'utils/common/types/common.types';
import { selectBackdropVisibility, setAppBackdrop } from '../../appBackdrop';
import { navigateToCasinoIntegration, navigateToIntegration } from '../../integrations/utils/integrations.utils';
import { showRootModal } from '../../rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from '../../rootModal/types/modal.types';
import {
  selectBottomTabBar,
  setCheckTicketModalVisibilityPlatform,
  setMyBetsVisibilityPlatform,
} from '../slice/bottomTabBar.slice';
import { BottomTabBarTypes, TAB_TYPE } from '../types/bottomTabBar.types';

type HookReturnType = (tab: BottomTabBarTypes.TabBarType) => void;

// interface Props {
//   bottomTabBarRef: RefObject<HTMLDivElement>;
// }

export const useBottomTabBarHandleClick = (): HookReturnType => {
  const isMyBetsVisible = useAppSelector(selectBottomTabBar.isMyBetsVisible);
  const isCheckTicketModalVisible = useAppSelector(selectBottomTabBar.isCheckTicketModalVisible);
  const isBackdropVisible = useAppSelector(selectBackdropVisibility);
  const { enabled } = useMyBetsRoute();
  const navigate = useNavigate();
  const location = useLocation() as LocationType<{ internalNavigate?: boolean }>;
  const dispatch = useAppDispatch();

  const setFalseToAppBackdropAndMyBetsVisibility = useCallback((): void => {
    dispatch(setAppBackdrop(false));
    dispatch(setMyBetsVisibilityPlatform(false));
  }, [dispatch]);

  const routeChange = useCallback(
    (route: string) => {
      location.pathname !== route && navigate(route);
      navigateToIntegration({ fullPath: route });
      navigateToCasinoIntegration({ fullPath: route });
      isBackdropVisible && setFalseToAppBackdropAndMyBetsVisibility();
    },
    [setFalseToAppBackdropAndMyBetsVisibility, isBackdropVisible, location.pathname, navigate],
  );

  const handleShowMyBets = useCallback(() => {
    // if (bottomTabBarRef.current) {
    //   dispatch(setBottomTabBarHeight(bottomTabBarRef.current.getBoundingClientRect().height));
    // }
    if (!isMyBetsVisible && !enabled) {
      dispatch(setMyBetsVisibilityPlatform(!isMyBetsVisible));
    } else if (enabled) {
      routeChange(configRoutes.myBets);
    }
  }, [dispatch, enabled, isMyBetsVisible, routeChange]);

  const handleShowCheckTicketModal = useCallback(() => {
    dispatch(setCheckTicketModalVisibilityPlatform(!isCheckTicketModalVisible));
  }, [dispatch, setCheckTicketModalVisibilityPlatform, isCheckTicketModalVisible]);

  const handleShowClaimTicketModal = useCallback(() => {
    dispatch(
      showRootModal({
        modalType: 'CLAIM_TICKET_MODAL',
        modalPriority: ModalPriorityEnum.MEDIUM,
        modalId: 'CLAIM_TICKET_MODAL',
      }),
    );
  }, [dispatch]);

  const handleSearch = useCallback(() => {
    if (!location.state?.internalNavigate) {
      const route = 'sports/quick-search';
      navigateToIntegration({ fullPath: route });
      navigate(route);
    }
  }, [dispatch, location.state?.internalNavigate]);

  const handleFavorites = useCallback(() => {
    !location.state?.internalNavigate && dispatch(setIsFavouriteGamesModalOpen(true));
    isBackdropVisible && setFalseToAppBackdropAndMyBetsVisibility();
  }, [dispatch, setFalseToAppBackdropAndMyBetsVisibility, isBackdropVisible, location.state?.internalNavigate]);

  const onClickActions = useMemo(
    () => ({
      [TAB_TYPE.MYBETS]: handleShowMyBets,
      [TAB_TYPE.CHECK_TICKET]: handleShowCheckTicketModal,
      [TAB_TYPE.CLAIM_TICKET]: handleShowClaimTicketModal,
      [TAB_TYPE.SPORT_SEARCH]: handleSearch,
      [TAB_TYPE.CASINO_FAVOURITES]: handleFavorites,
    }),
    [handleShowMyBets, handleShowCheckTicketModal, handleShowClaimTicketModal, handleSearch, handleFavorites],
  );

  const onClickHandler = useCallback(
    (tab: BottomTabBarTypes.TabBarType) => {
      dispatch(setIsFavouriteGamesModalOpen(false));
      if (tab?.route && tab.route.indexOf('https') >= 0) {
        window.open(tab.route, '_blank');
      } else {
        tab.route && !onClickActions[tab.type] ? routeChange(tab.route) : onClickActions[tab.type]();
        isMyBetsVisible && tab.type !== TAB_TYPE.MYBETS && dispatch(setMyBetsVisibilityPlatform(false));
      }
    },
    [dispatch, isMyBetsVisible, onClickActions, routeChange],
  );

  return onClickHandler;
};
