import { useEffect } from 'react';
import { useBonusTagsConfig } from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/shared/GameCellTags/hooks/useBonusTagsConfig';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { fetchBonusGames } from '../slice/gameLaunchBonus.slice';

let bonusTagGamesInitialFetch = false;

export const useInitialBonusTagFetch = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const enableGameTagIcon = useBonusTagsConfig();

  useEffect(() => {
    if (!enableGameTagIcon) {
      return;
    }

    if (isAuthenticated && !bonusTagGamesInitialFetch) {
      bonusTagGamesInitialFetch = true;
      dispatch(fetchBonusGames());
    }

    if (!isAuthenticated && bonusTagGamesInitialFetch) {
      bonusTagGamesInitialFetch = false;
    }
  }, [dispatch, isAuthenticated, enableGameTagIcon]);
};
