import React from 'react';
import clsx from 'clsx';
import { WidgetTemplate } from 'modules/casino/shared/constants';
import { CasinoWidgetsTypes } from '../../../../types/casinoWidgetsTypes';

interface WrapperProps {
  children: React.ReactNode;
  templateType: CasinoWidgetsTypes.WidgetTypes;
  widgetSubType?: string;
  wrapperClassName?: string;
}

const CellWrapper: React.FC<WrapperProps> = ({ children, templateType, widgetSubType, wrapperClassName }) => {
  const wrapperClass = clsx(
    'cw',
    'cw--generic',
    `cw--${WidgetTemplate[templateType]}`,
    widgetSubType && widgetSubType.toLowerCase(),
    wrapperClassName && wrapperClassName,
  );

  return <div className={wrapperClass}>{children}</div>;
};

export default CellWrapper;
