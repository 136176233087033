import styled from 'styled-components';
import { Breakpoints } from 'theme/Theme';
import { SCFadeIn } from '../../../campaigns/SharedSC/SharedSC';

const rotated = [''];

for (let i = 0; i <= 11; i++) {
  const angle = i * 30;
  rotated.push(`&:nth-child(${i + 1}) {
        transform: rotate(${angle}deg);
    }`);
}

interface Props {
  $hasWin: boolean;
  $isWinner: boolean;
  $color?: string;
  $winColor?: string;
}

export const StyledSegment = styled('div')<Props>`
  place-self: center end;
  width: 50%;
  padding-right: 15px;
  font-size: clamp(0.75rem, 0.722rem + 0.14vw, 0.813rem);
  font-weight: 700;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    padding-right: 20px;
  }

  text-align: center;
  text-shadow: 0px 0px 2px #000;
  color: ${(p) => (p.$isWinner ? p.$winColor || '#fce34c' : p.$color || '#fff')};
  ${(p) => p.$hasWin && !p.$isWinner && `opacity: 0.6`};
  transform-origin: left center;
  transition:
    padding 0.15s,
    color 0.15s,
    opacity 0.15s;
  overflow: hidden;
  animation: ${SCFadeIn} 0.15s;
  ${rotated}
`;

export const StyledSegmentText = styled.div`
  width: 70%;
  line-height: 1;
  transition:
    transform 0.15s,
    opacity 0.15s;

  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    width: 75%;
  }
`;
