export const getIconSrc = (iconLocation: string): string => (iconLocation ? `${iconLocation}` : '');

export const removeDashes = (providerName: string): string => {
  const dashIdx = providerName?.indexOf('-');
  if (dashIdx !== -1) {
    const newName = `${providerName?.substr(0, dashIdx)} ${providerName?.substr(dashIdx + 1, providerName?.length)}`;
    return removeDashes(newName);
  }

  return providerName;
};
