import React, { useEffect, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { createCookie } from 'utils/common/helpersCommon';
import { LayoutConfigTypes } from './features/general/types/generalSlice.types';

const AffiliateTokenHandler = () => {
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const affiliateExpires = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.AFFILIATE_EXPIRES,
    deviceLayout: 'general',
  });

  useEffect(() => {
    if (urlSearchParams.has('token_name')) {
      const affiliateToken = urlSearchParams.get('token_name');
      const expireDate = new Date().setTime(
        new Date().getTime() + (affiliateExpires ? affiliateExpires : 30) * 24 * 60 * 60 * 1000,
      );
      if (affiliateToken) {
        createCookie({ name: 'affiliateToken', value: affiliateToken, expiration: expireDate });
      }
    }
  }, []);
  return <></>;
};

export default memo(AffiliateTokenHandler);
