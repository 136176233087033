import { FormDropdown, FormGroup } from 'components/shared/forms/components';
import { CashierTranslations } from 'modules/casino/shared/utils/translationUtils/translationStrings';

export const ReprintTicketOptionsControls = ({
  activeOperations,
  selectedOption,
  setSelectedOption,
  activeTerminal,
  selectedTerminal,
  setSelectedTerminal,
  t,
}) => {
  const translatedOptions = activeOperations?.activeOptionsFromDropDown?.map((item) => ({
    text: t(CashierTranslations.TransactionHistoryReprintModal[item.text]),
    value: item.value,
  }));

  return (
    <>
      <FormGroup className="reprint-ticket-form">
        <FormDropdown
          label={t(CashierTranslations.TransactionHistoryReprintModal.selectTerminal)}
          value={selectedTerminal || ''}
          placeholder={t(CashierTranslations.TransactionHistoryReprintModal.selectTerminal)}
          isControlled
          options={activeTerminal.activeTerminalsFromDropDown}
          highlightSelected
          onChange={setSelectedTerminal}
        />
      </FormGroup>
      <FormGroup className="reprint-ticket-form">
        <FormDropdown
          label={t(CashierTranslations.TransactionHistoryReprintModal.selectOption)}
          value={selectedOption || ''}
          placeholder={t(CashierTranslations.TransactionHistoryReprintModal.selectOption)}
          isControlled
          options={translatedOptions}
          highlightSelected
          onChange={setSelectedOption}
        />
      </FormGroup>
    </>
  );
};
