import React from 'react';
import clsx from 'clsx';
import { Text } from 'components/shared';
import SportIconCDN from 'components/shared/icons/SportIconCDN';
import EventScoreWrapper from 'components/shared/sportContainers/event/eventScore/EventScoreWrapper';
import { isEmpty } from 'utils/common/helpersCommon';
import { Sport } from 'utils/common/types/sportTypes';
import { SportDataTypes } from 'utils/common/types/sportTypes/sportData.types';
import { SPORTS_WITH_EVENT_NAME } from './betSelectionUITypes';

/** BetSeletionUI Event */
interface EventProps {
  showEventName: boolean;
  showLiveScore: boolean;
  sportId: number;
  homeTeam: string;
  awayTeam: string;
  eventName: string;
  eventClockStatus: Sport.EventClockStatus;
  eventStatusConfig: SportDataTypes.EventStatusConfig | null;
  hasEventLink: boolean;
  handleEventClick: React.MouseEventHandler;
}

const Event: React.FC<EventProps> = ({
  showEventName,
  showLiveScore,
  sportId,
  homeTeam,
  awayTeam,
  eventName,
  eventClockStatus,
  eventStatusConfig,
  hasEventLink,
  handleEventClick,
}) => {
  const eventClass = clsx(
    'd-flex-ac event-name',
    hasEventLink && 'event-name-link',
    SPORTS_WITH_EVENT_NAME.includes(sportId) && 'mw-0 overflow-hidden',
  );
  const eventLinkStyle = clsx('text-truncate event-name');

  return (
    <div className={eventClass}>
      <SportIconCDN type="betslip" icon={sportId} />
      {showEventName ? (
        isEmpty(eventClockStatus?.homeScore) && isEmpty(eventClockStatus?.awayScore) ? (
          <Text onClick={handleEventClick} className="text-truncate" text={eventName} />
        ) : (
          <span className={eventLinkStyle}>
            {homeTeam} <strong>{eventClockStatus.homeScore}</strong> : <strong>{eventClockStatus.awayScore}</strong>{' '}
            {awayTeam}
          </span>
        )
      ) : (
        <>
          <div className="d-flex-col mw-0 overflow-hidden" onClick={handleEventClick}>
            <Text className="text-truncate" text={homeTeam} />
            <Text className="text-truncate" text={awayTeam} />
          </div>
          {showLiveScore && (
            <div className="d-flex ml-auto">
              <EventScoreWrapper
                eventClockStatus={eventClockStatus}
                sportId={sportId}
                eventStatusConfig={eventStatusConfig}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Event;
