import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CashierWidgetType } from 'shared/common/features/integrations/cashierWidget/types/cashierWidgetTypes';

export const BANK_ROUTE = '/my-account/bank';
export const CashierTheme = {
  icons: {
    ui: `/assets/img/icons/cashier/ui/sprites.svg?${window.config.EPOCH}`,
    payments: `/assets/img/icons/cashier/payments/sprites.svg?${window.config.EPOCH}`,
  },
  colorTheme: 'dark',
  iconThene: 'solid',
};

export const useNavigateToBankTab = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(
    (activePaymentTab: CashierWidgetType.CashierTabTypes) => {
      navigate(`${BANK_ROUTE}?tab=${activePaymentTab}`, {
        state: {
          from: `${location.pathname}${location.search}`,
        },
      });
    },
    [navigate, location],
  );
};

/**
 * Get cashier current active tab by url params. If not, default is deposit
 * @param query
 * @returns string deposit | history | withdrawal
 */
export const getActiveTab = (query: URLSearchParams): CashierWidgetType.CashierTabTypes => {
  const tab = query.get('tab') as CashierWidgetType.CashierTabTypes;
  if (['history', 'withdrawal'].includes(tab)) {
    return tab;
  }
  return 'deposit';
};
