const claimTicket = [
  {
    name: 'type',
    type: 'radio',
    options: [
      {
        value: 'Sport',
        text: 'ClaimTicket.Form.sportRadio',
      },
      {
        value: 'Lottery',
        text: 'ClaimTicket.Form.lotteryRadio',
      },
    ],
    validationRules: {
      required: {
        value: true,
        message: 'ClaimTicket.Validation.cpfRequired',
      },
    },
  },
  {
    name: 'cpf',
    type: 'input',
    label: 'ClaimTicket.Form.cpfInput',
    placeholder: 'ClaimTicket.Form.cpfInput',
    inputType: 'text',
    effect: {
      replace: {
        pattern: '^(\\d{1,3})(\\d{0,3})?(\\d{0,3})?(\\d{0,2})?$',
        callback: 'br',
      },
    },
    validationRules: {
      required: {
        value: true,
        message: 'ClaimTicket.Validation.cpfRequired',
      },
      maxLength: { value: 14, message: 'ClaimTicket.Validation.cpfMax' },
      minLength: { value: 14, message: 'ClaimTicket.Validation.cpfMin' },
    },
  },
  {
    name: 'ticketId',
    type: 'input',
    label: 'ClaimTicket.Form.enterTicketId',
    placeholder: 'ClaimTicket.Form.enterTicketId',
    tooltip: 'ClaimTicket.Form.enterTicketInfo',
    inputType: 'text',
    validationRules: {
      pattern: {
        value: '^[0-9]*$',
        message: 'ClaimTicket.Validation.ticketIdPattern',
      },
      required: {
        value: true,
        message: 'ClaimTicket.Validation.ticketIdRequired',
      },
      maxLength: {
        value: 30,
        message: 'ClaimTicket.Validation.ticketIdMax',
      },
      minLength: {
        value: 1,
        message: 'ClaimTicket.Validation.ticketIdMin',
      },
    },
  },
];

export const formsMap = {
  claim_ticket_form: claimTicket,
};
