import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ModalUI } from 'components/shared/ModalUI';
import { UI } from 'components/shared/UI';
import { useAppSelector } from 'store';
import { RealityCheckModal } from 'utils/common/translationUtils/translationStrings';
import SessionCreateContent from './SessionCreateContent';
import useSessionCreate from '../hooks/useSessionCreate';
import useSessionPreModal from '../hooks/useSessionPreModal';
import { selectRealityCheckSession } from '../slice/realityCheckSession.slice';

interface Props {
  resolve?: (value: unknown) => void;
}

const SessionCreate: React.FC<Props> = ({ resolve }) => {
  const formFields = useAppSelector(selectRealityCheckSession.formFields);
  const formProps = useForm({ mode: 'onChange' });
  const { t } = useTranslation();
  const { showModal, closeModal } = useSessionPreModal();
  const { fields, onSubmit } = useSessionCreate({
    formFields,
    resolve,
    watch: formProps.watch,
    setValue: formProps.setValue,
  });

  return showModal ? (
    <>
      <ModalUI.Title
        hasAccent
        className={'reality-check-session--pre-create__title mb-2'}
        text={t(RealityCheckModal.Session.preModalTitle)}
      />
      <div className={'mt-2 mb-2'}>{t(RealityCheckModal.Session.preModalText)}</div>
      <Link className={'mb-2'} to={'/help'} target={'_blank'}>
        {t(RealityCheckModal.Session.preModalLink)}
      </Link>
      <UI.HTMLButton
        className={'px-5 btn-action w-100'}
        text={t(RealityCheckModal.Session.preModalButton)}
        onClick={closeModal}
      />
    </>
  ) : (
    <FormProvider {...formProps}>
      <form
        onSubmit={formProps.handleSubmit(onSubmit)}
        className={'d-flex-col-ac reality-check-session-create mx-auto h-100'}
      >
        <div className="reality-check-session-create__form text-left w-100">
          {Object.values(fields || formFields || {}).map((field, i) => (
            <SessionCreateContent key={i} field={field} />
          ))}
        </div>
        <UI.HTMLButton
          className={'px-5 btn-action w-100'}
          id={'egtd-btn--reality-check-session-create'}
          type={'submit'}
          disabled={!formProps.formState.isValid}
          text={t(RealityCheckModal.Session.submitButton)}
          onClick={formProps.handleSubmit(onSubmit)}
        />
      </form>
    </FormProvider>
  );
};

export default SessionCreate;
