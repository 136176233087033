import { ET, SportIDs } from 'utils/common/types/sportTypes';
import { Sport } from 'utils/common/types/sportTypes/sport.types';

export const isStreamEventWithMarkets = (
  event: ET.StreamEventView_NormalizedEvent | ET.StreamWithMarkets_NormalizedEvent | null,
): event is ET.StreamWithMarkets_NormalizedEvent => {
  if ((event as ET.StreamWithMarkets_NormalizedEvent)?.markets) {
    return true;
  }
  return false;
};

export const isLiveMarket = (
  market: Sport.LiveMultilineMarket | Sport.MultilineMarket,
): market is Sport.LiveMultilineMarket => {
  return (market as Sport.LiveMultilineMarket).columnPosition !== undefined;
};

export const hideEventScore = (
  sportId: number,
  sportType?: Sport.SportType,
  eventStatus?: Sport.EventStatus,
): boolean => {
  return sportId === SportIDs.formula_1 || (sportType === 'VIRTUAL' && eventStatus !== 'Ended');
};

export const hidePeriodScore = (sportId: number): boolean => {
  return sportId === SportIDs.esoccer;
};

export enum EventLayouts {
  LAYOUT_1 = 'layout_1', // common event design: event header - left, event body - right
  LAYOUT_2 = 'layout_2', // Mixed highlights event header - top header with large Jersey, event body - bottom
}
