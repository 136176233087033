import i18next, { t } from 'i18next';
import { formatCurrency } from 'modules/retail/modules/cashier/utils';
import { MapSummaryDataParams, MapSummaryVirtualDataParams } from 'modules/retail/modules/ssbt/types/ssbt.types';
import { CampaignTypes, CommandData } from 'modules/retail/shared/types/retailCommon.types';
import { defineCampaignType } from 'modules/retail/shared/utils/helperFunctions';
import { printMethods, retailPrintOptions } from 'modules/retail/shared/utils/retail.utils';
import { commands } from 'modules/retail/shared/utils/retail.utils';
import { isEmpty, toFixed } from 'utils/common/helpersCommon';
import { BetSlip, SSBT } from 'utils/common/translationUtils/translationStrings';

export const mapSummaryData = ({ x, getState, toDecimal, currency, isCurrencyBeforeAmount }: MapSummaryDataParams) => {
  const summaryData: CommandData[] = [];
  const iconAccaBoost = getState()?.common?.general?.layoutConfig?.['platform']?.general?.retailBonusIcons?.accaboost;
  const iconAccaInsurance =
    getState()?.common?.general?.layoutConfig?.['platform']?.general?.retailBonusIcons?.accaInsurance;
  const accaInsuranceCampaign = defineCampaignType(x, CampaignTypes.accaInsurance);
  const accaBoostCampaign = defineCampaignType(x, CampaignTypes.accaBoost);

  const isStraightColumn = x.totalCombinations === 1; /// when is one bet combination
  printMethods.printOptions(summaryData, commands, retailPrintOptions.eventOptionsNew);
  printMethods.printOptions(summaryData, commands, retailPrintOptions.eventNameOptions);
  printMethods.colReset(summaryData, commands);
  printMethods.printOptions(summaryData, commands, retailPrintOptions._addressOptions);
  printMethods.printOptions(summaryData, commands, retailPrintOptions.eventNameOptions);

  printMethods.conditionalPrint(
    summaryData,
    commands,
    !isEmpty(x),
    !isEmpty(x?.bankerBetTypeName)
      ? `${x.totalCombinations} X ${x.stake} ${x.bankerBetTypeName}`
      : `${i18next.t('BetSlip.Systems.' + x.selectedSystem)}, ${x.totalCombinations} ${i18next.t(
          SSBT.Ticket.totalCombinations,
        )} X ${formatCurrency(x.stake, currency, isCurrencyBeforeAmount)}`,
  );

  printMethods.printOptions(summaryData, commands, retailPrintOptions.eventNameOptions);
  printMethods.conditionalPrint(
    summaryData,
    commands,
    isStraightColumn,
    `${i18next.t(BetSlip.SSBT.totalOdds)} ${toFixed(Number(x.totalOdds), 2)}`,
  );

  printMethods.conditionPrintImageAndStringInARow(
    summaryData,
    commands,
    !isEmpty(accaBoostCampaign),
    iconAccaBoost,
    `${i18next.t(BetSlip.SSBT.bonusPercentageText)}: + ${accaBoostCampaign?.percentage}%`,
  );
  printMethods.conditionalPrint(
    summaryData,
    commands,
    !isEmpty(accaBoostCampaign),
    `${t('Bonuses.AccaBoost.includeBonus')}: ${formatCurrency(Number(accaBoostCampaign?.amount), currency, isCurrencyBeforeAmount)}`,
  );

  printMethods.conditionPrintImageAndStringInARow(
    summaryData,
    commands,
    !isEmpty(accaInsuranceCampaign),
    iconAccaInsurance,
    `${i18next.t(BetSlip.BetSlipConfirmation.stakeInfoText)}: ${formatCurrency(x.totalStake, currency, isCurrencyBeforeAmount)}`,
  );

  printMethods.conditionalPrint(
    summaryData,
    commands,
    isEmpty(x.campaigns),
    `${i18next.t(BetSlip.BetSlipConfirmation.stakeInfoText)}: ${formatCurrency(x.totalStake, currency, isCurrencyBeforeAmount)}`,
  );
  printMethods.print(
    summaryData,
    commands,
    `${i18next.t(BetSlip.PlaceBetButton.possibleWinText)}: ${formatCurrency(toDecimal(x.totalWinLong), currency, isCurrencyBeforeAmount)}`,
  );
  printMethods.printOptions(summaryData, commands, retailPrintOptions.eventNameOptions);
  printMethods.print(summaryData, commands, ''.padEnd(44, '='));
  return summaryData;
};

export const mapSummaryVirtualData = ({ selectionTypes }: MapSummaryVirtualDataParams) => {
  const uniqueSelectionTypes = [...selectionTypes];
  const selectionsText = uniqueSelectionTypes.map((type) => `${i18next.t(`BetSlip.Systems.` + type)}`).join(', ');
  const summaryData: CommandData[] = [];
  printMethods.conditionalPrint(summaryData, commands, selectionTypes.size > 0, selectionsText);
  return summaryData;
};
