import React from 'react';
import { casinoWidgetsSelectors } from 'modules/casino/modules/container/widget/slice/casinoWidgetsSlice';
import { CasinoWidgetsTypes } from 'modules/casino/modules/container/widget/types/casinoWidgetsTypes';
import LeaderBoardInfo from 'modules/casino/modules/container/widget/widgetTypes/leaderBoardWidget/leaderBoardCellWidget/components/widgetLeaderBoardComponents/LeaderBoardInfo';
import LeaderBoardTabsSection from 'modules/casino/modules/container/widget/widgetTypes/leaderBoardWidget/leaderBoardCellWidget/components/widgetLeaderBoardComponents/LeaderBoardTabsSection';
import { isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import { useAppSelector } from 'store';
import { leaderBoardCSSNS } from '../../LeaderBoardCellWidgetConstants';

type Props = {
  widget: CasinoWidgetsTypes.Widget;
};

const WidgetLeaderBoard: React.FC<Props> = ({ widget }) => {
  const { id, template, navigationUrl } = widget || {};
  const leaderBoardData = useAppSelector((state) => casinoWidgetsSelectors.widgetLeaderBoardData(state, id));

  if (isEmpty(leaderBoardData)) {
    return <></>;
  }

  return (
    <div className={`d-flex ${leaderBoardCSSNS}-widget ${leaderBoardCSSNS}-widget--${template?.type.toLowerCase()}`}>
      <LeaderBoardInfo leaderBoardInfo={leaderBoardData} />
      <div className={`${leaderBoardCSSNS}-widget__boards`}>
        <LeaderBoardTabsSection
          awards={leaderBoardData.awards}
          ranking={leaderBoardData.ranking}
          cellLayout={template?.type}
          buttonUrl={navigationUrl!}
        />
      </div>
    </div>
  );
};

export default WidgetLeaderBoard;
