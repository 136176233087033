import { memo } from 'react';
import DwarfsWagonItem from './DwarfsWagonItem';
import { NotificationTypes } from '../../../myProfile/sections/tabs/notificationsTab/notificationTypes';
import { GamificationModalUI } from '../../components/GamificationModalUI';
import useWinItemState from '../../hooks/useWinItemState';

type Props = {
  setAnimationComplete: (isVisible: boolean) => void;
  animationComplete: boolean;
  animationParams: NotificationTypes.GamificationModalParams;
  notification: NotificationTypes.PopUpNotification;
};

const DwarfsWagonItems: React.FC<Props> = ({
  animationComplete,
  animationParams,
  setAnimationComplete,
  notification,
}) => {
  const { bonusData } = notification;

  const { state, handlers } = useWinItemState();

  if (!bonusData) return null;

  return (
    <GamificationModalUI.Layout layout={animationParams.layout} fullWidth={true}>
      {bonusData?.otherOptions?.map((item: string, index: number) => (
        <DwarfsWagonItem
          key={`${item}-${index}`}
          index={index}
          notification={notification}
          winItemIndex={state.winItemIndex}
          setWinItemIndex={handlers.setWinItemIndex}
          setAnimationComplete={setAnimationComplete}
          animationComplete={animationComplete}
          animationParams={animationParams}
        />
      ))}
    </GamificationModalUI.Layout>
  );
};

export default memo(DwarfsWagonItems);
