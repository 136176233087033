import React, { memo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { selectIsExpanded, setIsExpanded } from 'shared/common/features/ticker/slice/mostPlayed.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { AppHeader } from 'utils/common/translationUtils/translationStrings';

interface Props {
  onToggleClick: () => void;
}

const TickerToggler: React.FC<Props> = ({ onToggleClick }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isExpanded = useAppSelector(selectIsExpanded);

  const toggleClick = () => {
    dispatch(setIsExpanded(!isExpanded));
    onToggleClick();
  };

  const tikcerTogglerStyles = clsx('nav-link ticker-toggler', isExpanded && 'ticker-toggler--active');

  return (
    <Flex className="mx-auto">
      <span className={tikcerTogglerStyles} onClick={toggleClick}>
        {t(AppHeader.Toggler.mostPlayed)}
      </span>
    </Flex>
  );
};

export default memo(TickerToggler);
