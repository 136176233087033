import { useEffect, useRef } from 'react';
import { SocialAccountsUI } from 'pages/myAccount/tabs/account/sections/components/socialAccountsUI';
import { JwtPayload, jwtDecode } from 'utils/common/jwtDecode';
import SocialIcon from '../../SocialIcon';
import { Social, SocialButtonAuthTypes, SocialButtonInputTypes } from '../../types/formSocial.types';

// Uses Hacky solution to use original google button click event.
const GoogleButtonWrapper: React.FC<{
  children: React.ReactNode;
  className: string;
  isDisabled?: boolean;
  onClick?: () => void;
}> = ({ children, className, isDisabled, onClick }) => {
  const googleLoginWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isDisabled === false) {
      const googleLoginWrapper = document.createElement('div');
      googleLoginWrapper.style.display = 'none';
      googleLoginWrapper.classList.add('custom-google-button');
      googleLoginWrapperRef?.current?.appendChild?.(googleLoginWrapper);

      const currentRef = googleLoginWrapperRef.current;

      window.google.accounts.id.renderButton(googleLoginWrapper, {
        type: 'icon',
        width: '200',
      });

      return () => {
        currentRef?.removeChild(googleLoginWrapper);
      };
    }
  }, [isDisabled]);

  const handleClick = () => {
    onClick && onClick();
    const googleLoginWrapperButton = googleLoginWrapperRef?.current?.querySelector<HTMLDivElement>('div[tabindex="0"]');
    if (googleLoginWrapperButton) {
      googleLoginWrapperButton?.dispatchEvent(new MouseEvent('click'));
    } else {
      window.google?.accounts?.id?.prompt();
    }
  };

  return (
    <div className={className} ref={googleLoginWrapperRef} onClick={handleClick}>
      {children}
    </div>
  );
};

const GoogleButton = {
  config: {
    src: 'https://accounts.google.com/gsi/client',
    async: true,
    defer: true,
  },
  effect: ({
    onSocialAuth,
    authType,
    clientId,
  }: {
    onSocialAuth: Social.OnSocialAuth;
    authType?: SocialButtonAuthTypes;
    clientId?: string;
  }) => {
    window.google.accounts.id.initialize({
      client_id: clientId,
      context: authType === SocialButtonAuthTypes.REGISTER ? 'signup' : 'signIn',
      callback: async (response) => {
        const credentials = jwtDecode<JwtPayload>(response.credential);
        onSocialAuth?.({
          username: credentials.sub,
          password: response.credential,
          userData: {
            userId: credentials.sub,
            firstName: credentials.given_name,
            lastName: credentials.family_name,
            email: credentials.email,
          },
        });
      },
    });
  },
  element: ({ className, button, isDisabled, onClick, t }: Social.ElementProps) => {
    const onClickHandler = isDisabled ? undefined : onClick;

    return button.inputType === SocialButtonInputTypes.UI_BUTTON ? (
      <SocialAccountsUI.Link
        type={button.type}
        text={button.label}
        btnText={button.text}
        isDisabled={isDisabled}
        WrapElement={({ children }) => (
          <GoogleButtonWrapper className={className} isDisabled={isDisabled} onClick={onClickHandler}>
            {children}
          </GoogleButtonWrapper>
        )}
      />
    ) : (
      <GoogleButtonWrapper className={className} isDisabled={isDisabled} onClick={onClickHandler}>
        {button.inputType === SocialButtonInputTypes.ICON && (
          <div className="form-social__button-logo">
            <SocialIcon type={button.type} size={20} />
          </div>
        )}
        {button.label && <div className="form-social__button-text">{t(button.label)}</div>}
      </GoogleButtonWrapper>
    );
  },
  close: () => {
    window.google?.accounts?.id?.cancel();
  },
};

export default GoogleButton;
