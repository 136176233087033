import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { General } from 'shared/common/features/general/types/generalSlice.types';
import { useGamificationWidgetConfig } from 'shared/common/features/integrations/gamification/hooks/useGamificationWidgetConfig';
import { MPDUI } from 'shared/common/features/myProfile/components/MPDUI';
import { showMyProfileModal } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppDispatch } from 'store';

type Props = {
  tab: General.MyProfileRoutes;
  showArrow?: boolean;
};

export const LinkItem: React.FC<Props> = ({ tab, showArrow }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { route: gamificationRoute } = useGamificationWidgetConfig();

  const hideProfileMenu = useCallback(() => {
    navigate(`${gamificationRoute}${tab.route}`);
    window.widgetGamification &&
      window.widgetGamification.navigate({ fullPath: `${gamificationRoute}${tab.route}`, invokeCallBack: false });
    dispatch(showMyProfileModal(false));
  }, [dispatch, tab, gamificationRoute, navigate]);

  return (
    <>
      <MPDUI.NavItemInRow
        key={tab.key}
        onClick={hideProfileMenu}
        dataQid={`${tab.key}`}
        dataCpp={`${tab.key}`}
        text={t(tab.label)}
        icon={tab.icon}
        iconColor={tab.iconColor}
        showArrow={showArrow}
      />
    </>
  );
};
