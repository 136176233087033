import Cell01DataStyles from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell01/components/data/styles';
import Cell01DataAlternativeStyles from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell01/components/dataAlternative/styles';
import Cell01DefaultStyles from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/LayoutWrapper/components/cell01/components/default/styles';
import {
  ComponentType,
  StyledNames,
} from 'modules/casino/modules/container/cellsContainer/grid/components/gridLayoutWrappers/layoutWrapperConstants';

const components = {
  [StyledNames.GameCellWrapperStyled]: {
    [ComponentType.default]: Cell01DefaultStyles.GameCellWrapperStyled,
    [ComponentType.data]: Cell01DataStyles.GameCellWrapperStyled,
  },
  [StyledNames.GameMediaStyled]: {
    [ComponentType.default]: Cell01DefaultStyles.GameMediaStyled,
    [ComponentType.data]: Cell01DataStyles.GameMediaStyled,
    [ComponentType.dataAlternative]: Cell01DataAlternativeStyles.GameMediaStyled,
  },
  [StyledNames.ProviderLogoStyled]: {
    [ComponentType.data]: Cell01DataStyles.ProviderLogoStyled,
  },
  [StyledNames.DescriptionStyled]: {
    [ComponentType.data]: Cell01DataStyles.DescriptionStyled,
    [ComponentType.dataAlternative]: Cell01DataAlternativeStyles.DescriptionStyled,
  },
  [StyledNames.MetaInfoStyled]: {
    [ComponentType.data]: Cell01DataStyles.MetaInfoStyled,
  },
  [StyledNames.DataCharactersWrapperStyled]: {
    [ComponentType.data]: Cell01DataStyles.DataCharactersWrapperStyled,
  },
};

const cell01StyleLoader = (styledComponentName, styledType) => {
  return components[styledComponentName][styledType];
};

export default cell01StyleLoader;
