import React, { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex } from 'modules/casino/shared/components/layout';
import { casinoSearchSelectors } from 'modules/casino/shared/features/casinoSearch/casinoSearchSlice';
import { useAppDispatch, useAppSelector } from 'store';
import config from 'utils/config';
import { useProviderDataByAlias } from '../../hooks/useProviderDataByAlias';
import { gameProvidersSelectors, setActiveProvidersBarTab } from '../../slice/casinoGameProvidersSlice';
import {
  clearAllProviderButtonHighlights,
  getProviderPath,
  highlightProviderButton,
} from '../../utils/casinoGameProvidersUtils';
import { ProvidersBarUI } from '.';

/** ProvidersBarUI Item */
interface ItemProps {
  providerAlias: string;
  destination: string;
  index: number;
  inDrawer?: boolean;
}

const Item: React.FC<ItemProps> = ({ providerAlias, destination, index, inDrawer }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const headerSearchIsActive = useAppSelector(casinoSearchSelectors.headerSearchIsActive);
  const buttonRef = useRef<HTMLDivElement>(null);
  const { code: providerCode, id: providerId } = useProviderDataByAlias(providerAlias);
  const activeProvidersBarTabIndex = useAppSelector(gameProvidersSelectors.activeProvidersBarTab);

  const onPress = useCallback(() => {
    if (buttonRef.current && activeProvidersBarTabIndex !== index) {
      if (location.pathname.includes(`/providers/${providerAlias}`) && destination !== 'slider') {
        return;
      }

      // highlightProviderButton(buttonRef.current, destination);
      const newProviderPath = getProviderPath(window.location.pathname, providerAlias.toLowerCase());
      navigate(newProviderPath);

      dispatch(setActiveProvidersBarTab(index));
    }
  }, [activeProvidersBarTabIndex, providerAlias, index, destination, location.pathname, dispatch]);

  useEffect(() => {
    if (location.pathname.includes(`/providers/${providerAlias}`) && buttonRef.current) {
      if ((destination === 'slider' && index === activeProvidersBarTabIndex) || destination !== 'slider') {
        highlightProviderButton(buttonRef.current, destination);
      }
    } else {
      if (!location.pathname.includes(`/providers`) && buttonRef.current) {
        clearAllProviderButtonHighlights();
        dispatch(setActiveProvidersBarTab(null));
      }
    }
  }, [providerAlias, location.pathname, headerSearchIsActive, activeProvidersBarTabIndex, destination]);

  return (
    <Flex.AC
      ref={buttonRef}
      className="providers-bar__item"
      key={providerId}
      onClick={onPress}
      data-qid={providerAlias}
    >
      <ProvidersBarUI.Logo
        inDrawer={inDrawer}
        icon={providerCode}
        path={`${config.CDN_URL}/gaming-content/game-providers-new/logos/`}
        className={'providers-bar__icon'}
        height={40}
      />
    </Flex.AC>
  );
};

export default Item;
