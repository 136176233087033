import { useEffect } from 'react';
import { AppDispatch } from 'store';
import { ClearDataProps } from '../types/cashier.types';

// initally made for ReprintModal, FinanceModal and AnexModal

export const useCashierModalOpenRequest = (
  isCashierModalPrompted: boolean,
  dispatch: AppDispatch,
  closeModal: () => void,
  clearData: ClearDataProps,
) => {
  useEffect(() => {
    if (isCashierModalPrompted) {
      clearData(dispatch, closeModal);
    }
  }, [isCashierModalPrompted]);
};
