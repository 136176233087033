import { DefaultTheme } from 'styled-components/native';
import { Buttons } from './Buttons';
import { Common } from './Common';
import Home from './Home';
import { LayoutTypeConfig } from '../default/LayoutTypeConfig';

export const SesameTheme: DefaultTheme = {
  dark: {
    Common: Common,
    Buttons: Buttons,
    LayoutConfigs: LayoutTypeConfig,
    Home: Home,
  },
  light: {
    Common: Common,
    Buttons: Buttons,
    LayoutConfigs: LayoutTypeConfig,
    Home: Home,
  },
};
