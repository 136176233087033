import React, { memo, useEffect } from 'react';
import { resetFormFieldsData, selectAuthentication, loginThunks } from 'pages/auth/login/slice/login.slice';
import { resetNetAppHeader } from 'shared/common/features/general/slice/general.slice';
import { useAppDispatch, useAppSelector } from 'store';
import QuickLoginForm from './QuickLoginForm';

const QuickLoginFormModal: React.FC = () => {
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const navigationOptions = useAppSelector(selectAuthentication.navigationOptions);
  const quickLoginFormVisibility = useAppSelector(selectAuthentication.quickLoginFormVisibility);
  const dispatch = useAppDispatch();

  useEffect(() => {
    quickLoginFormVisibility && dispatch(loginThunks.fetchFormFields());
    return () => {
      dispatch(resetFormFieldsData());
      dispatch(resetNetAppHeader());
    };
  }, [dispatch, quickLoginFormVisibility]);

  useEffect(() => {
    if (!quickLoginFormVisibility && !isAuthenticated) {
      navigationOptions?.exit && navigationOptions.exit();
    }
  }, [isAuthenticated, navigationOptions, quickLoginFormVisibility]);

  if (!quickLoginFormVisibility) return null;
  return <QuickLoginForm />;
};

export default memo(QuickLoginFormModal);
