import { useState, useCallback, useEffect } from 'react';
import { UseFormWatch, FieldValues, UseFormSetValue } from 'react-hook-form';
import { useAppDispatch } from 'store';
import { sessionCreate } from '../';
import { hideRootGrandModal } from '../../rootModal/slice/rootModal.slice';
import { RealityCheckSession } from '../types/realityCheckSession.types';

interface Props {
  formFields: RealityCheckSession.FormFields | null;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  resolve?: (value: unknown) => void;
}

interface ReturnProps {
  fields: RealityCheckSession.FormFields | null;
  onSubmit: (data: FieldValues) => void;
}

const useSessionCreate = ({ formFields, resolve, watch, setValue }: Props): ReturnProps => {
  const [fields, setFields] = useState<RealityCheckSession.FormFields | null>(null);
  const dispatch = useAppDispatch();

  const onSuccess = useCallback(() => {
    dispatch(hideRootGrandModal());
    resolve && resolve(true);
  }, [dispatch, resolve]);

  const onSubmit = useCallback(
    (data: FieldValues) => {
      dispatch(
        sessionCreate({
          balance: data.selectInitialBalance,
          duration: data.selectDuration,
          alertPeriod: data.selectAlertPeriod,
          timeoutPeriod: data.selectTimeoutPeriod,
          onSuccess,
        }),
      );
    },
    [dispatch, onSuccess],
  );

  const onChange = useCallback(
    (change) => {
      if (fields) {
        change.selectAlertPeriod &&
          parseInt(change.selectDuration) <= parseInt(change.selectAlertPeriod) &&
          setValue('selectAlertPeriod', '');
        setFields(
          fields.map((field) =>
            field.name === 'selectAlertPeriod'
              ? {
                  ...field,
                  options: field.options?.map((option) => ({
                    ...option,
                    disabled: parseInt(change.selectDuration) <= parseInt(option.value),
                  })),
                }
              : field,
          ) as RealityCheckSession.FormFields,
        );
      }
    },
    [fields],
  );

  useEffect(() => {
    if (fields) watch(onChange);
  }, [onChange]);

  useEffect(() => {
    if (!fields && formFields?.length) setFields(formFields);
  }, [formFields]);

  return { fields, onSubmit };
};

export default useSessionCreate;
