import React from 'react';
import { isDesktop } from 'react-device-detect';
import { Flex } from 'modules/casino/shared/components/layout';
import { UI } from 'modules/casino/shared/components/UI';

interface Props {
  showFreePlay: boolean;
  hidePlayButton: boolean;
  freePlayText: string;
  freePlayHandler: React.MouseEventHandler;
  playText: string;
  playHandler: React.MouseEventHandler;
}

export const GameCellButtons: React.FC<Props> = ({
  showFreePlay,
  hidePlayButton,
  freePlayText,
  freePlayHandler,
  playText,
  playHandler,
}) => {
  return (
    <Flex.Center className="game-cell-actions">
      <Flex.ColAC className="game-cell-actions__wrapper">
        {!hidePlayButton && (
          <UI.Button
            c-size={isDesktop ? 'lg' : 'md'}
            className={'mb-1'}
            onClickHandler={playHandler}
            text={playText}
            data-qid="play-button"
          />
        )}
        {showFreePlay && (
          <UI.Button
            c-size={isDesktop ? 'md' : 'sm'}
            c-variant="secondary"
            onClickHandler={freePlayHandler}
            text={freePlayText}
            data-qid="free-play-button"
          />
        )}
      </Flex.ColAC>
    </Flex.Center>
  );
};

GameCellButtons.displayName = 'Game Cell Buttons';
