import { WS } from 'shared/common/features/websockets/types/webSockets.types';
import { RootState } from 'store/rootReducer';

export declare module MyBets {
  type InitialState = {
    activeTabIndex: number;
    activeTabType: MyBets.BetTabCategory;
    myBetsIds: string[];
    normalizedMyBets: NormalizedBetsMap;
    normalizedEvents: NormalizedOutcomeEventMap;
    activeRadarMatchId: number | null;
    status: 'pending' | 'idle' | 'rejected' | 'fulfilled';
    error: string | null;
    routingKeys: string[];
    unsettledBetsCount: number;
    isMyBetsVisible: boolean;
    activeTrackerItem: Record<string, boolean>;
    normalizedCashOutData: NormalizedCashOutDataMap;
    sessionRandomNumber: null | string;
  };

  type NormalizedBetsMap = Record<string, BetContent>;
  type NormalizedOutcomeEventMap = Record<string, OucomeEventNormalized>;
  type Stake = {
    value: number;
  };
  type NormalizedCashOutDataMap = Record<string, CashOutData>;

  type SelectionRef = {
    selectionIndex: number;
    banker: boolean;
  };

  type Summary = {
    betId: string;
    stakeLong: number;
    winLong: number;
    totalStakeLong: number;
    totalCombinations: number;
    outcomeId: string;
    stake: number;
    win: number;
    totalStake: number;
    totalOdds: number;
    selectedSystem: string;
  };

  type BetHistoryArgs = {
    type: SportBetHistoryType;
    pageNumber: number;
    fromDate: number | undefined;
    toDate: number | null;
    isFetchMore: boolean;
  };

  type ApiResponse = {
    playerBets: BetContent[];
    betsCashoutsData: CashOutData[];
    openBetsCount: number;
    content: BetContent[];
    pageable: Pageable;
    totalElements: number;
    last: boolean;
    totalPages: number;
    sort: Sort2;
    number: number;
    numberOfElements: number;
    first: boolean;
    size: number;
    empty: boolean;
  };

  type BetContent = {
    ticketId: string;
    bet: BetType;
    summary: SummaryType;
    outcomes: OutcomeEvent[] | OutcomeEventWithClock[];
    createdDate: number;
    hasReoffer: boolean;
    minCashoutStake: number | null;
    fullCashoutStake: number | null;
    isCashOutLoading: boolean;
    eventIds: string[];
  };

  type Campaign = {
    id: number;
    type: 'ACCABOOST' | 'ACCAINSURANCE' | 'BOOSTODDS' | 'BOOSTMULTIPLE';
    percentage: number;
    amountLong: number;
    maxPayout: number;
  };

  type BetType = {
    customBet: boolean;
    calculationOdds: number;
    stake: {
      value: number;
      bonusMoneyLong: number | null;
    };
    id: string;
    selectedSystem: 'string';
    selectionRefs: [
      {
        selectionIndex: number;
        banker: boolean;
      },
    ];
    betStatus: BetStatuses;
    cashoutStake: {
      value: number;
      valueLong: number;
    };
    events: EventType[];
    settlementTimestamp: number;
    outcomeStatus: OutcomeStatuses;
    isFreeBet: boolean;
    campaign: Campaign;
    campaigns: Campaign[];
  };

  type EventClockStatus = Omit<WS.EventClock, 'routingKey'> & {
    lastUpdate: number;
  };

  type EventType = {
    eventType: 'FULL_CASHOUT' | 'PARTIAL_CASH_OUT';
    originalStake: number;
    originalStakeLong: number;
    usedStake: number;
    usedStakeLong: number;
    cashoutStake: number;
    timestamp: number;
  };

  type CashOutData = {
    betId: string;
    minCashoutStake: number | null;
    fullCashoutStake: number | null;
    ticketId: string;
  };

  type SummaryType = {
    stakeLong: number;
    winLong: number;
    totalStakeLong: number;
    totalCombinations: number;
    outcomeId: number;
    sportEventId: number;
    // stake: number;
    win: number;
    // totalStake: number;
    totalOdds: number;
    totalDisplayOdds: number;
    selectedSystem: string;
    bankerBetTypeName: string;
    totalWinLong: number;
  };

  type OucomeEventNormalized = OutcomeEvent & { eventClockStatus: EventClockStatus };

  //TODO FIX THIS
  type OutcomeEvent = Outcome & {
    isCustomBet: boolean;
    customBetOutcomes: Outcome[];
  };
  type OutcomeEventWithClock = Outcome & {
    isCustomBet: boolean;
    customBetOutcomes: Outcome[];
    customBet: boolean; // ??? in history
    eventClockStatus: EventClockStatus;
  };

  type SelectionStatus = 'WON' | 'VOID' | 'CANCELED' | 'LOST' | 'WON_VOID' | 'LOSS_VOID' | null;
  type TicketStatus = 'Accepted' | 'Rejected' | 'RejectedFundsNotWithdrawn' | 'Cancelled' | 'Settled' | 'Reoffered';
  type TicketOutcomeStatus = 'NotSettled' | 'Won' | 'Loss' | 'Void' | 'Cancelled';

  type MarketStatus =
    | 'Settled'
    | 'Suspended'
    | 'Active'
    | 'Handed over'
    | 'Cancelled'
    | 'Deactivated'
    | 'Deactivated/Inactive';

  type Outcome = {
    id: string;
    isVirtual: boolean;
    active: boolean;
    odds: number;
    originalOdds: number;
    americanOdds: number;
    fractionalOdds: string;
    originalFractionalOdds?: string;
    originalAmericanOdds?: number;
    name: string;
    probability: number;
    currentProbability: number;
    marketId: string;
    marketStatus: MarketStatus;
    marketTemplateId: string;
    marketTemplateName: string;
    headToHead: boolean;
    banker: boolean;
    scheduledTime: string;
    selectionStatus: SelectionStatus;
    sportEventId: number;
    sportEventName: string;
    displayOdds: number;
    displayAmericanOdds: number;
    displayFractionalOdds: string;
  } & Outcome_Common;

  type EventStatus =
    | 'NotStarted'
    | 'Live'
    | 'Suspended'
    | 'Ended'
    | 'Closed'
    | 'Cancelled'
    | 'Delayed'
    | 'Interrupted'
    | 'Postponed'
    | 'Abandoned'
    | 'Unknown'
    | '';

  type Outcome_Common = {
    tournamentId: number;
    categoryId: number;
    awayTeam: string;
    homeTeam: string;
    sportEventType: 'match' | 'tournament' | 'stage';
    sportType: 'REGULAR' | 'ESPORT' | 'VIRTUAL';
    sportId: number;
    eventPath: string;
    eventStatus?: EventStatus;
    live: boolean;
    feedProducerId: number;
    radarMatchId: number;
    radarSeasonId: number;
    radarSportId: number;
    radarAwayCompetitorId: number;
    radarHomeCompetitorId: number;
    customMarketType: 'ENHANCED_ODDS' | null;
    selectionBonuses: SelectionBonusesTypes;
    settlementType: MyBets.SettlementType;
  };

  type SelectionBonusesTypes = Record<'EARLYPAYOUT' | 'BOREDRAW', SelectionBonusesValuesTypes>;

  type SelectionBonusesValuesTypes = {
    isPaid: boolean;
    id: 'string';
  };

  type Sort = {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };

  type Pageable = {
    sort: Sort;
    pageNumber: number;
    pageSize: number;
    offset: number;
    paged: boolean;
    unpaged: boolean;
  };

  type Sort2 = {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };

  type BetStatus = 'ACCEPTED' | 'REJECTED' | 'SETTLED' | 'CANCELED' | 'CASHED_OUT';

  type BetTabCategory = 'ALL' | 'LIVE' | 'SETTLED' | 'UNSETTLED';

  type CashoutCalculatorData = {
    betType: string;
    stake: number;
    selections: CashoutCalculatorSelections[];
    isFreeBet: boolean;
    isTotalStake: boolean;
    betId: string;
    ticketId: string;
  };

  type CashoutCalculatorSelections = {
    wageringEventId: string;
    outcomeOdds: number;
    banker: boolean;
    selectionPosition: number;
    outcomeId: string;
    isLoss: boolean;
    probability: number;
    currentProbability: number;
    selectionStatus: SelectionStatus;
  };

  type CashoutStakeValues = {
    minCashoutStake: number | null;
    fullCashoutStake: number | null;
    betId: string;
  };

  type SportBetHistoryType = 'SETTLED' | 'UNSETTLED';

  type PendingActionMeta = {
    arg: { isWsReconnect?: boolean };
  };

  type BetCashOutActionMeta = {
    arg: BetCashOut;
  };

  type BetCashOut = {
    ticketId: string;
    betId: string;
    cashoutStake: number;
    cashoutPercent: number;
    fullCashoutStake: number;
    minCashoutStake: number | null;
    outcomes: OutcomeEvent[] | OutcomeEventWithClock[];
  };

  type BetCashOutStatus = {
    betId: string;
    createdDate: number;
    isFullCashout: boolean;
  };

  type ThunkArgs = {
    getState: () => RootState;
    rejectWithValue: (value: string) => string;
  };

  type Clock = {
    defaultLength: number;
    periodsLength: Record<string, number> | null;
    remainingTimeInPeriod?: boolean;
    remainingTime?: boolean;
  };
  type ScoreType = 'Single' | 'Double' | 'Triple' | 'Esport' | 'CricketSpecific' | 'BaseballSpecific' | null;
  type Config = {
    id: number;
    config: {
      clock: Clock | null;
      scoreType: number | null;
      scoreTypeName: ScoreType;
      showNeutralGround?: boolean;
      widgetType;
      statistics?: boolean;
    };
    favoriteMarketTemplates: number[];
  };

  type EventStatusConfig = {
    pausingMatchStatuses: string[];
    sportsConfig: Config[];
    sportsWithClock: number[];
    showMatchStatuses: ShowMatchStatuses[];
    sportsWithStatistics: number[];
  };

  type ShowMatchStatuses = {
    matchStatus: string;
    name: string;
    shortName: string;
  };

  type OutcomeStatuses = 'WON' | 'LOSS' | 'VOID' | 'PARTIAL_WON' | 'PARTIAL_LOSS' | 'CANCELED';
  type SettlementType = 'FEED' | 'BU' | 'TICKET' | 'EARLYPAYOUT' | 'BOREDRAW' | null;
}

export enum BetStatuses {
  CASHED_OUT = 'CASHED_OUT',
  SETTLED = 'SETTLED',
  ACCEPTED = 'ACCEPTED',
  CANCELED = 'CANCELED',
  REJECTED = 'REJECTED',
}

export enum SelectionStatuses {
  WON = 'WON',
  LOST = 'LOST',
  VOID = 'VOID',
  WON_VOID = 'WON_VOID',
  LOSS_VOID = 'LOSS_VOID',
  CANCELED = 'CANCELED',
  PAID = 'PAID',
  ACCEPTED = 'ACCEPTED',
}

export enum OutcomeStatuses {
  WON = 'WON',
  LOSS = 'LOSS',
  VOID = 'VOID',
  PARTIAL_WON = 'PARTIAL_WON',
  PARTIAL_LOSS = 'PARTIAL_LOSS',
  CANCELED = 'CANCELED',
}
