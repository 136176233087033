import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NoDataMessage from 'components/shared/NoDataMessage';
import { PrizeDropUI } from 'components/shared/prizeDrop';
import { useGetCampaignData } from 'shared/common/features/PrizeDrop/hooks/useGetCampaignData';
import { selectPrizeDrop, setActiveCampaignId } from 'shared/common/features/PrizeDrop/slice/prizeDrop.slice';
import { PrizeDropStatus, PrizeDropTypes } from 'shared/common/features/PrizeDrop/types/prizeDrop.types';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { Promotions } from 'utils/common/translationUtils/translationStrings';
import PrizeDropCampaignItem from '../components/PrizeDropCampaignItem';
import PrizeDropCampaignsCol from '../components/PrizeDropCampaignsCol';
// import PrizeDropHeaderTimeCounter from '../components/PrizeDropHeaderTimeCounter';
import PrizeDropHeaderTimeCounter from '../components/PrizeDropHeaderTimeCounter';
import PrizeDropTabsPrimary from '../components/PrizeDropTabsPrimary';
import PrizeDropTimeCounter from '../components/PrizeDropTimeCounter';

const { SOON, ACTIVE, ENDED } = PrizeDropStatus;

export const ActiveCampaignHeader: React.FC<{ activeCampaign: PrizeDropTypes.Campaign; isInGameBar?: boolean }> = ({
  activeCampaign,
  isInGameBar,
}) => {
  const [isExpiredStartTime, setIsStartTimeExpired] = useState(activeCampaign?.startDate <= Date.now());
  const [isExpiredEndTime, setIsEndTimeExpired] = useState(activeCampaign?.endDate <= Date.now());
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { personalRewards, rewards, rewardedPlayers, terms, campaignStatus } = useGetCampaignData();

  const slotStatus = activeCampaign.isActive ? (isExpiredStartTime ? (isExpiredEndTime ? ENDED : ACTIVE) : SOON) : SOON;

  const handleClose = () => dispatch(hideRootModal());

  const handleBack = () => dispatch(setActiveCampaignId(0));

  return (
    <>
      <PrizeDropUI.Header
        handleClose={!isInGameBar ? handleClose : undefined}
        handleBack={handleBack}
        title={activeCampaign.title || ''}
        size="lg"
        isCenter
        iconResource={activeCampaign?.iconResource}
        className="egtd-przd-header"
      />
      {slotStatus === SOON && isInGameBar && (
        <PrizeDropHeaderTimeCounter
          date={activeCampaign?.startDate}
          text={t(Promotions.PrizeDrop.startsInText)}
          setIsTimeExpired={setIsStartTimeExpired}
          slotStatus={slotStatus}
        />
      )}
      {(slotStatus === ACTIVE || slotStatus === ENDED) && isInGameBar && (
        <PrizeDropHeaderTimeCounter
          date={activeCampaign?.endDate}
          text={t(Promotions.PrizeDrop.endsInText)}
          setIsTimeExpired={setIsEndTimeExpired}
          showDate={slotStatus === ENDED}
          slotStatus={slotStatus}
        />
      )}
      <PrizeDropTabsPrimary
        iconResource={activeCampaign?.iconResource}
        campaignStatus={campaignStatus}
        personalRewards={personalRewards?.rewards}
        rewards={rewards?.rewards}
        rewardedPlayers={rewardedPlayers?.rewards}
        terms={terms}
        isPopover={isInGameBar}
      />
      {slotStatus === SOON && !isInGameBar && (
        <PrizeDropTimeCounter
          date={activeCampaign?.startDate}
          text={t(Promotions.PrizeDrop.startsInText)}
          setIsTimeExpired={setIsStartTimeExpired}
          slotStatus={slotStatus}
          className="pl-0 pr-0"
        />
      )}
      {(slotStatus === ACTIVE || slotStatus === ENDED) && !isInGameBar && (
        <PrizeDropTimeCounter
          date={activeCampaign?.endDate}
          text={t(Promotions.PrizeDrop.endsInText)}
          setIsTimeExpired={setIsEndTimeExpired}
          showDate={slotStatus === ENDED}
          slotStatus={slotStatus}
        />
      )}
    </>
  );
};

const PrizeDropMobileView: React.FC<{ isInGameBar?: boolean }> = ({ isInGameBar }) => {
  const campaignsIds = useAppSelector(selectPrizeDrop.campaignsIds);
  const status = useAppSelector(selectPrizeDrop.status);
  const activeCampaign: PrizeDropTypes.Campaign | undefined = useAppSelector(selectPrizeDrop.activeCampaign);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleClose = () => dispatch(hideRootModal());

  const classWrapProp = { class: 'no-scrollbars overflow-y-auto overflow-x-hidden' };

  return (
    <>
      {isInGameBar ? (
        <>
          {activeCampaign ? (
            <ActiveCampaignHeader activeCampaign={activeCampaign} isInGameBar={isInGameBar} />
          ) : (
            <>
              <PrizeDropUI.Header
                handleClose={!isInGameBar ? handleClose : undefined}
                title={t(Promotions.PrizeDrop.campaignsTitle)}
                size="sm"
                iconResource=""
                className="egtd-przd-header"
              />
              <egtd-przd-modal-campaigns data-popover={true}>
                <egtd-przd-modal-campaigns-wrap {...classWrapProp}>
                  {isEmpty(campaignsIds) && status !== 'idle' ? (
                    <NoDataMessage message={t(Promotions.PrizeDrop.noInformation)} />
                  ) : (
                    campaignsIds?.map((id: number) => <PrizeDropCampaignItem key={`campaign-${id}`} id={id} />)
                  )}
                </egtd-przd-modal-campaigns-wrap>
              </egtd-przd-modal-campaigns>
            </>
          )}
        </>
      ) : (
        <egtd-przd-modal data-mobile={true}>
          {activeCampaign ? (
            <ActiveCampaignHeader activeCampaign={activeCampaign} />
          ) : (
            <PrizeDropCampaignsCol hasHeader />
          )}
        </egtd-przd-modal>
      )}
    </>
  );
};

export default PrizeDropMobileView;
