import React from 'react';
import { CategoryQueryType } from 'modules/casino/shared/constants';
import { CategoryLayoutTypes } from '../../../shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import GridWidgetCellLayout from '../../../widget/components/widgetCell/GridWidgetCellLayout';
import GridCellLayout from '../../grid/components/gridCell/GridCellLayout';
import { SpecialCell } from '../../grid/components/specialCell';
import { useShowSpecialCell } from '../hooks/useShowSpecialCell';
import { useShowWidget } from '../hooks/useShowWidget';

interface Props {
  gameUniqueId: string;
  index: number;
  collectionId: string;
  isLastItem: boolean;
  categoryId: string;
  categoryLayout: CategoryLayoutTypes;
  queryType: CategoryQueryType;
  skipLazyLoad: boolean;
  gridPage: number;
  gamesRenderedCount: number;
  useLoadAnimation: boolean;
}

const GridComponentsRender: React.FC<Props> = ({
  gameUniqueId,
  index,
  collectionId,
  isLastItem,
  gridPage,
  gamesRenderedCount,
  categoryId,
  categoryLayout,
  skipLazyLoad,
  queryType,
  useLoadAnimation,
}) => {
  const { showWidget } = useShowWidget({
    gameUniqueId,
    categoryLayout,
    categoryId,
  });

  const { specialCell } = useShowSpecialCell({
    gameUniqueId,
    categoryLayout,
    categoryId,
  });

  const renderGridCell = () => {
    return (
      <GridCellLayout
        index={index}
        gridPage={gridPage}
        gamesRenderedCount={gamesRenderedCount}
        categoryId={categoryId}
        isLastItem={isLastItem}
        gameUniqueId={gameUniqueId}
        collectionId={collectionId}
        skipLazyLoad={skipLazyLoad}
        queryType={queryType}
        categoryLayout={categoryLayout}
        useLoadAnimation={useLoadAnimation}
      />
    );
  };

  const renderGridComponent = () => {
    if (showWidget) {
      return (
        <GridWidgetCellLayout
          categoryId={categoryId}
          isLastItem={isLastItem}
          gameUniqueId={gameUniqueId}
          collectionId={collectionId}
        />
      );
    }

    if (specialCell) {
      return (
        <SpecialCell
          categoryId={categoryId}
          categoryLayoutType={categoryLayout}
          collectionId={collectionId}
          gridPage={gridPage}
        />
      );
    }

    return renderGridCell();
  };

  return renderGridComponent();
};

GridComponentsRender.displayName = 'GridComponentsRender';
export default GridComponentsRender;
