import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { gamificationPageThunks } from 'pages/gamificationPage/slice/gamificationPage.slice';
import { hideRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { WS } from 'shared/common/features/websockets/types/webSockets.types';
import { logoutUser } from 'shared/common/sharedSlices/commonActions';
import { AppDispatch } from 'store';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { Sport } from 'utils/common/types/sportTypes';
import config from 'utils/config';
import { NotificationTypes } from './notificationTypes';
import { normalizeInboxNotification, normalizePopUpNotification } from './notificationUtils';

const initialState: NotificationTypes.State = {
  inboxNotifications: {},
  inboxNotificationsStatus: 'idle',
  hasNotificationsFetched: false,
  inboxNotificationsError: null,
  inboxNotificationCounter: 0,
  activeInboxNotification: '',
  activePopupNotificationId: '',
  popUpNotificationsStatus: 'idle',
  popUpNotificationsError: null,
  popUpGroupedNotifications: [],
  popUpSingleNotifications: {},
  popUpSliderNotifications: {},
  activePriorityPopUp: null,
  sliderIsDone: false,

  inboxUpdateNotificationsStatus: 'idle',
};

export const notificationThunks: NotificationTypes.NotificationThunks = {
  fetchInboxNotification: createAbortThunk('notification/fetchInboxNotification', async (_, { source }) => {
    const apiUrl = `${config.API_URL}/api/ews-crm/player/player-messages/inbox/get`;
    const response = await axiosInstance.get<NotificationTypes.InboxNotification[]>(apiUrl, {
      cancelToken: source.token,
    });

    return response.data;
  }),
  fetchPopUpGroupedNotification: createAbortThunk(
    'notification/fetchPopUpGroupedNotification',
    async (_, { getState, dispatch, source }) => {
      const apiUrl = `${config.API_URL}/api/ews-crm/player/player-messages/popup/get-by-grouped`;
      const response = await axiosInstance.get(`${apiUrl}`, {
        cancelToken: source.token,
      });

      const currModalType = getState().common.rootModal.modalType;
      const currModalProps = getState().common.rootModal.modalProps;
      const activePopupNotificationId = getState().common.notification.activePopupNotificationId;

      if (!activePopupNotificationId && currModalType !== null && currModalProps !== null) {
        const currNotificationId = currModalProps?.notification?.id;
        const hasNotification = response.data.single?.items.some(
          (notification) => notification.id === currNotificationId,
        );

        dispatch(setReadNotificationById({ notificationId: currNotificationId, notificationType: 'popup' }));
        if (!hasNotification && currModalType === 'POP_UP_GAMIFICATION' && currNotificationId) {
          dispatch(hideRootModal());
        }
      }
      return response.data;
    },
  ),
  fetchInboxNotificationCounter: createAbortThunk('notification/notificationCounter', async (_, { source }) => {
    const apiUrl = `${config.API_URL}/api/ews-crm/player/player-messages/inbox/count-unread`;
    const response = await axiosInstance.get<number>(apiUrl, { cancelToken: source.token });
    return response.data;
  }),
  updateNotifications: createAbortThunk('notification/deleteNotifications', async (args) => {
    const { messagesIds, type } = args;
    const action = type === 'READ' ? 'read' : 'delete';
    const data = {
      id: messagesIds.join(),
    };
    const apiUrl = `${config.API_URL}/api/ews-crm/player/player-messages/inbox/${action}`;
    const response = await axiosInstance.post<number>(apiUrl, data);
    return response.data;
  }),
  sendButtonClick: createAbortThunk('notification/sendButtonClick', async (args) => {
    const { notificationId, type } = args;
    const data = {
      id: notificationId,
    };
    const apiUrl = `${config.API_URL}/api/ews-crm/player/player-messages/${type}/click`;
    await axiosInstance.post(apiUrl, data);
  }),
};

const { fetchInboxNotification, fetchPopUpGroupedNotification, fetchInboxNotificationCounter, updateNotifications } =
  notificationThunks;

const notification = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setActiveInboxNotificationId(state, action: PayloadAction<string>) {
      state.activeInboxNotification = action.payload === state.activeInboxNotification ? '' : action.payload;
    },
    setActivePopupNotificationId(state, action: PayloadAction<string>) {
      state.activePopupNotificationId = action.payload;
    },
    setActivePriorityPopUp(state, action: PayloadAction<NotificationTypes.ActivePriorityPopUpType>) {
      state.activePriorityPopUp = action.payload;
    },
    setSliderIsDone(state, action: PayloadAction<boolean>) {
      state.sliderIsDone = action.payload;
    },
    setReadNotificationById(
      state,
      action: PayloadAction<{ notificationId: string; notificationType: 'popup' | 'inbox' }>,
    ) {
      const { notificationId, notificationType } = action.payload;
      if (notificationType === 'popup' && state.popUpSingleNotifications?.[notificationId]) {
        state.popUpSingleNotifications[notificationId].isRead = true;
      } else if (notificationType === 'popup' && state.popUpSliderNotifications?.[notificationId]) {
        state.popUpSliderNotifications[notificationId].isRead = true;
      } else if (notificationType === 'inbox' && state.inboxNotifications?.[notificationId]) {
        state.inboxNotifications[notificationId].isRead = true;
      }
    },

    resetNotification: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInboxNotification.pending, (state) => {
        if (state.inboxNotificationsStatus === 'idle') {
          state.inboxNotificationsStatus = 'pending';
        }
      })
      .addCase(fetchInboxNotification.fulfilled, (state, action) => {
        state.inboxNotifications = normalizeInboxNotification(action.payload);
        state.inboxNotificationsStatus = 'succeeded';
        state.inboxNotificationsError = null;
        state.inboxUpdateNotificationsStatus = 'idle';
      })
      .addCase(fetchInboxNotification.rejected, (state, action) => {
        if (action.payload) {
          state.inboxNotificationsStatus = 'failure';
          state.inboxNotificationsError = action.payload;
        }
      })
      .addCase(fetchInboxNotificationCounter.fulfilled, (state, action) => {
        state.inboxNotificationCounter = action.payload;
      })
      .addCase(updateNotifications.pending, (state) => {
        if (state.inboxNotificationsStatus === 'idle') {
          state.inboxUpdateNotificationsStatus = 'pending';
        }
      })
      .addCase(updateNotifications.fulfilled, (state) => {
        state.inboxUpdateNotificationsStatus = 'succeeded';
      })
      .addCase(updateNotifications.rejected, (state, action) => {
        if (action.payload) {
          state.inboxUpdateNotificationsStatus = 'failure';
        }
      })
      .addCase(logoutUser, () => {
        return { ...initialState };
      })
      .addCase(fetchPopUpGroupedNotification.pending, (state) => {
        state.popUpNotificationsStatus = 'pending';
      })
      .addCase(fetchPopUpGroupedNotification.fulfilled, (state, action) => {
        const convertNotifications = Object.entries(action.payload).map(([key, value]) => ({ type: key, ...value }));
        convertNotifications.sort((a, b) => a.priority - b.priority);

        state.activePriorityPopUp = convertNotifications[0].type;
        state.popUpGroupedNotifications = convertNotifications;
        state.sliderIsDone = false;
        state.popUpSingleNotifications = normalizePopUpNotification(action.payload.single?.items || []);
        state.popUpSliderNotifications = normalizePopUpNotification(action.payload.slider?.items || []);
        state.popUpNotificationsStatus = 'succeeded';
        state.hasNotificationsFetched = true;
      })
      .addCase(fetchPopUpGroupedNotification.rejected, (state, action) => {
        if (action.payload) {
          state.popUpNotificationsStatus = 'failure';
          state.popUpNotificationsError = action.payload;
          state.hasNotificationsFetched = true;
        }
      });
  },
});

export const {
  setActiveInboxNotificationId,
  setReadNotificationById,
  resetNotification,
  setActivePopupNotificationId,
  setActivePriorityPopUp,
  setSliderIsDone,
} = notification.actions;

export const readNotification =
  (notificationId: string, notificationType: 'inbox' | 'popup') =>
  async (dispatch: AppDispatch): Promise<void> => {
    const messageType = {
      inbox: 'inbox',
      popup: 'popup',
    };

    const data = {
      id: notificationId,
    };
    try {
      dispatch(setReadNotificationById({ notificationId, notificationType }));
      await axiosInstance.post(
        `${config.API_URL}/api/ews-crm/player/player-messages/${messageType[notificationType]}/read`,
        data,
      );
    } catch (err) {
      dispatch(setReadNotificationById({ notificationId, notificationType }));
    }
  };

export const handleUpdateNotification =
  (payload: WS.Notification) =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    if (getState().casino.casinoGameLauncher.launchInProcess) return;
    if (payload.message.hasNewPopupNotification) {
      dispatch(fetchPopUpGroupedNotification());
    }

    if (payload.message.hasNewInboxMessage) {
      dispatch(fetchInboxNotification());
    }

    if (payload.message.hasNewPage) {
      const apiUri = getState().common.pageNotification.activeVertical?.apiUri;
      const verticalId = getState().common.pageNotification.activeVertical?.id;
      // State for activePageNotification is set upon readPageNotification api call
      const activeCurr = getState().common.pageNotification.activePageNotification;

      // Fetch unread notifications count
      dispatch(gamificationPageThunks.fetchUnreadNotificationsCount());

      const shouldUpdateCurrNotification = !activeCurr && apiUri && verticalId !== null;

      // Fetch new notification if curr is not active (curr notification is read/there is no notification)
      if (shouldUpdateCurrNotification) {
        dispatch(gamificationPageThunks.fetchNotification({ apiUri, verticalId, noLoadingState: true }));
      }
    }
  };

export const selectNotification = {
  inboxNotifications: (state: RootState): NotificationTypes.InboxNotificationMap =>
    state.common.notification.inboxNotifications,
  inboxNotificationsError: (state: RootState): string | null => state.common.notification.inboxNotificationsError,
  inboxNotificationsStatus: (state: RootState): Sport.StateStatus => state.common.notification.inboxNotificationsStatus,
  hasFetched: (state: RootState): boolean => state.common.notification.hasNotificationsFetched,
  notificationCounter: createSelector(
    (state: RootState) => state.common.notification.inboxNotifications,
    (notifications) => Object.values(notifications).filter((n) => !n.isRead).length,
  ),
  activeInboxNotification: (state: RootState): NotificationTypes.InboxNotification | undefined =>
    state.common.notification.inboxNotifications[state.common.notification.activeInboxNotification],
  activePopupNotificationId: (state: RootState): string => state.common.notification.activePopupNotificationId,
  popUpGroupedNotifications: (state: RootState): NotificationTypes.ConvertPopUpGroup[] =>
    state.common.notification.popUpGroupedNotifications,
  popUpSingleNotifications: (state: RootState): NotificationTypes.PopUpNotificationMap =>
    state.common.notification.popUpSingleNotifications,
  popUpSliderNotifications: (state: RootState): NotificationTypes.PopUpNotificationMap =>
    state.common.notification.popUpSliderNotifications,
  activePriorityPopUp: (state: RootState) => state.common.notification.activePriorityPopUp,
  popUpNotificationsStatus: (state: RootState): Sport.StateStatus => state.common.notification.popUpNotificationsStatus,
  popUpNotificationsError: (state: RootState): string | null => state.common.notification.popUpNotificationsError,
  sliderIsDone: (state: RootState): boolean => state.common.notification.sliderIsDone,

  updateNotificationsStatus: (state: RootState): Sport.StateStatus =>
    state.common.notification.inboxUpdateNotificationsStatus,
};

export default notification.reducer;
