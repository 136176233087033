import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';

interface DialProps {
  finalRevolutionAngle: number;
  revolutionsPeriod: number;
  isSpinning: boolean;
  hasStopped: boolean;
  onAnimationComplete: () => void;
  bgImage: string;
  className?: string;
  style?: CSSProperties;
  children: React.ReactNode;
}

const Dial: React.FC<DialProps> = ({
  finalRevolutionAngle,
  revolutionsPeriod,
  isSpinning,
  onAnimationComplete,
  bgImage,
  className,
  style,
  children,
}) => {
  const dialStates = {
    static: {
      rotate: 0,
      transition: {
        ease: [0.085, 0.67, 0.07, 1.03],
        duration: revolutionsPeriod,
      },
    },
    spinning: {
      rotate: [0, finalRevolutionAngle],
      transition: {
        ease: [0.085, 0.67, 0.07, 1.03],
        duration: revolutionsPeriod,
      },
    },
  };

  const dialClass = clsx(
    'grid-start-1x1 d-grid grid-1x1 h-100',
    className,
    // hasStopped && 'cshwhl-dial--has-win',
  );

  return (
    <motion.div
      className={dialClass}
      variants={dialStates}
      animate={isSpinning ? dialStates.spinning : dialStates.static}
      // onAnimationComplete={onAnimationComplete}
      onUpdate={(i) => i.rotate === finalRevolutionAngle && onAnimationComplete()}
      style={{ ...style, backgroundImage: `url(${bgImage})` }}
    >
      {children}
    </motion.div>
  );
};

export default Dial;
