import { setLayoutConfig } from 'shared/common/features/general/slice/general.slice';
import { store } from 'store';
import { getCdnBusinessUnit, getClientNameBU, getWindowConfigParam, isProductionBuild } from './common/helpersCommon';

// change to switch env
// const ACTIVE_API_DOMAIN: keyof typeof activeDomainApiMap = 'dev';
const sessionStoragePISource = sessionStorage?.getItem('devAPISource');
const retailChannel = getWindowConfigParam('CHANNEL');
const retailDevice = getWindowConfigParam('DEVICE');
const isDevelopment = !isProductionBuild;

export const ACTIVE_BU_DEV =
  sessionStoragePISource && isDevelopment
    ? sessionStoragePISource
    : `${getWindowConfigParam('BUSINESS_UNIT')}_${getWindowConfigParam('ENV_TYPE')}${
        retailChannel ? `_${retailChannel}_${retailDevice}` : ''
      }`;

export const getActiveDevEnvAndBU = (splitNum: 0 | 1) => ACTIVE_BU_DEV.split('_')[splitNum];

export const activeDomainApiMap = {
  // WBBG
  WBBG_DEV: { api: 'https://winbet-api-dev.egt-digital.com', domain: 'https://winbet-dev.egt-digital.com' },
  WBBG_INT: { api: 'https://winbet-api-int.egt-digital.com', domain: 'https://winbet-int.egt-digital.com' },
  WBBG_UAT: { api: 'https://winbet-api-uat.egt-digital.com', domain: 'https://winbet-uat.egt-digital.com' },
  WBBG_PRODUCTION: { api: 'https://winbet-api.egt-digital.com', domain: 'https://winbet.bg' },

  // WBRO
  WBRO_DEV: { api: 'https://winbet-ro-api-dev.egt-digital.com', domain: 'https://winbet-ro-dev.egt-digital.com' },
  WBRO_INT: { api: 'https://winbet-ro-api-int.egt-digital.com', domain: 'https://winbet-ro-int.egt-digital.com' },
  WBRO_UAT: { api: 'https://winbet-ro-api-uat.egt-digital.com', domain: 'https://winbet-ro-uat.egt-digital.com' },
  WBRO_PRODUCTION: { api: 'https://winbet-ro-api.egt-digital.com', domain: 'https://winbet.ro' },

  // IBBG
  IBBG_DEV: { api: 'https://inbet-api-dev.egt-digital.com', domain: 'https://inbet-dev.egt-digital.com' },
  IBBG_INT: { api: 'https://inbet-api-int.egt-digital.com', domain: 'https://inbet-int.egt-digital.com' },
  IBBG_UAT: { api: 'https://inbet-api-uat.egt-digital.com', domain: 'https://inbet-uat.egt-digital.com' },
  IBBG_PRODUCTION: { api: 'https://inbet-api.egt-digital.com', domain: 'https://inbet.com' },

  // SESAME
  SMBG_DEV: { api: 'https://sesame-api-dev.egt-digital.com', domain: 'https://sesame-dev.egt-digital.com' },
  SMBG_INT: { api: 'https://sesame-api-int.egt-digital.com', domain: 'https://sesame-int.egt-digital.com' },
  SMBG_UAT: { api: 'https://sesame-api-uat.egt-digital.com', domain: 'https://sesame-uat.egt-digital.com' },
  SMBG_PRODUCTION: { api: 'https://sesame-api.egt-digital.com', domain: 'https://sesame.bg' },

  // SESAME retail
  SMBGRT_DEV_RETAIL_ONLINE: {
    api: 'https://sesame-retail-api-dev.egt-digital.com',
    domain: 'https://sesame-retail-dev.egt-digital.com',
  },
  SMBGRT_INT_RETAIL_ONLINE: {
    api: 'https://sesame-retail-api-int.egt-digital.com',
    domain: 'https://sesame-retail-int.egt-digital.com',
  },
  SMBGRT_UAT_RETAIL_ONLINE: {
    api: 'https://sesame-retail-api-uat.egt-digital.com',
    domain: 'https://sesame-retail-uat.egt-digital.com',
  },

  // SESAME cashier
  SMBGRT_DEV_RETAIL_CASHIER: {
    api: 'https://sesame-retail-cashier-ssl-dev.egt-digital.com',
    domain: 'https://sesame-retail-cashier-ssl-dev.egt-digital.com',
  },
  SMBGRT_INT_RETAIL_CASHIER: {
    api: 'https://sesame-retail-cashier-ssl-int.egt-digital.com',
    domain: 'https://sesame-retail-cashier-ssl-int.egt-digital.com',
  },
  SMBGRT_UAT_RETAIL_CASHIER: {
    api: 'https://sesame-retail-cashier-ssl-uat.egt-digital.com',
    domain: 'https://sesame-retail-cashier-ssl-uat.egt-digital.com',
  },

  // EGT-TODAY
  EGTT_DEV: { api: 'https://egt-today-api-dev.egt-digital.com', domain: 'https://egt-today-dev.egt-digital.com' },
  EGTT_INT: { api: 'https://egt-today-api-int.egt-digital.com', domain: 'https://egt-today-int.egt-digital.com' },
  EGTT_UAT: { api: 'https://egt-today-api-uat.egt-digital.com', domain: 'https://egt-today-uat.egt-digital.com' },
  EGTT_PRODUCTION: { api: 'https://egt-today-api.egt-digital.com', domain: 'https://egt-digital.today' },

  // MAGNUM BET
  MBRO_DEV: {
    api: 'https://magnumbet-ro-api-dev.egt-digital.com',
    domain: 'https://magnumbet-ro-dev.egt-digital.com/',
  },
  MBRO_INT: {
    api: 'https://magnumbet-ro-api-int.egt-digital.com',
    domain: 'https://magnumbet-ro-int.egt-digital.com/',
  },
  MBRO_UAT: {
    api: 'https://magnumbet-ro-api-uat.egt-digital.com',
    domain: 'https://magnumbet-ro-uat.egt-digital.com/',
  },

  // RJBR
  RJBR_DEV: {
    api: 'https://rjbr-api-dev.egt-digital.com',
    domain: 'https://rjbr-dev.egt-digital.com',
  },
  RJBR_INT: {
    api: 'https://rjbr-api-int.egt-digital.com',
    domain: 'https://rjbr-int.egt-digital.com',
  },
  RJBR_UAT: {
    api: 'https://rjbr-api-uat.egt-digital.com',
    domain: 'https://rjbr-uat.egt-digital.com',
  },
  RJBR_PRODUCTION: {
    api: 'https://rjbr-sa-api.egt-digital.com',
    domain: 'https://rjbr-sa.egt-digital.com',
  },
  // RJBR
  RJBR_DEV_RETAIL_CASHIER: {
    api: 'https://rjbr-sa-api-dev.egt-digital.com',
    domain: 'https://rjbr-retail-handheld-dev.egt-digital.com',
  },
  RJBR_INT_RETAIL_CASHIER: {
    api: 'https://rjbr-api-int.egt-digital.com',
    domain: 'https://rjbr-retail-handheld-int.egt-digital.com',
  },
  RJBR_UAT_RETAIL_CASHIER: {
    api: 'https://rjbr-retail-handheld-uat.egt-digital.com',
    domain: 'https://rjbr-retail-handheld-uat.egt-digital.com',
  },

  // terminal
  // terminalDev: 'https://retail-ssbt-api.egt-digital.com',
  EGTT_DEV_RETAIL_SSBT: {
    api: 'https://egt-today-retail-ssl-dev.egt-digital.com',
    domain: 'https://egt-today-retail-dev.egt-digital.com',
  },
  EGTT_INT_RETAIL_SSBT: {
    api: 'https://egt-today-retail-ssl-int.egt-digital.com',
    domain: 'https://egt-today-retail-ssbt-ssl-int.egt-digital.com',
  },
  EGTT_UAT_RETAIL_SSBT: {
    api: 'https://egt-today-retail-ssl-uat.egt-digital.com',
    domain: 'https://egt-today-retail-uat.egt-digital.com',
  },
  EGTT_PRODUCTION_RETAIL_SSBT: {
    api: 'https://retail-ssbt.egt-digital.com',
    domain: 'https://retail-ssbt.egt-digital.com',
  },

  // EGTT cashier
  EGTT_PRODUCTION_RETAIL_CASHIER: {
    api: 'https://egt-today-retail-cashier-modern-ssl.egt-digital.com',
    domain: 'https://egt-today-retail-cashier-modern-ssl.egt-digital.com',
  },

  EGTT_DEV_RETAIL_CASHIER: {
    api: 'https://egt-today-retail-cashier-modern-ssl-dev.egt-digital.com',
    domain: 'https://egt-today-retail-cashier-modern-ssl-dev.egt-digital.com',
  },

  EGTT_INT_RETAIL_CASHIER: {
    api: 'https://egt-today-retail-cashier-modern-ssl-int.egt-digital.com',
    domain: 'https://egt-today-retail-cashier-modern-ssl-int.egt-digital.com',
  },

  EGTT_UAT_RETAIL_CASHIER: {
    api: 'https://egt-today-retail-cashier-modern-ssl-uat.egt-digital.com',
    domain: 'https://egt-today-retail-cashier-modern-ssl-uat.egt-digital.com',
  },

  // BETHUB
  BHBG_DEV: { api: 'https://bethub-dev-api.egt-digital.com', domain: 'https://bethub-dev.egt-digital.com' },
  BHBG_INT: { api: 'https://bethub-int-api.egt-digital.com', domain: 'https://bethub-int.egt-digital.com' },
  BHBG_UAT: { api: 'https://bethub-uat-api.egt-digital.com', domain: 'https://bethub-uat.egt-digital.com' },
  BHBG_PRODUCTION: { api: 'https://bethub-api.egt-digital.com', domain: 'https://bethub.bg' },

  BHBGNRA_DEV: { api: 'https://bethubbg-api-uat.egt-digital.com', domain: 'https://bethub-dev.egt-digital.com' },
  BHBGNRA_INT: { api: 'https://bethubbg-api-uat.egt-digital.com', domain: 'https://bethub-int.egt-digital.com' },
  BHBGNRA_UAT: { api: 'https://bethubbg-api-uat.egt-digital.com', domain: 'https://bethubbg-uat.egt-digital.com' },

  // Inbet retail
  IBBGRT_DEV_RETAIL_ONLINE: {
    api: 'https://inbet-retail-api-dev.egt-digital.com',
    domain: 'https://inbet-retail-dev.egt-digital.com',
  },
  IBBGRT_INT_RETAIL_ONLINE: {
    api: 'https://inbet-retail-api-int.egt-digital.com',
    domain: 'https://inbet-retail-int.egt-digital.com',
  },
  IBBGRT_UAT_RETAIL_ONLINE: {
    api: 'https://inbet-retail-api-uat.egt-digital.com',
    domain: 'https://inbet-retail-uat.egt-digital.com',
  },

  IBBGRT_DEV_RETAIL_SSBT: {
    api: 'https://inbet-retail-ssbt-ssl-dev.egt-digital.com',
    domain: 'https://inbet-retail-ssbt-ssl-dev.egt-digital.com/',
  },

  IBBGRT_INT_RETAIL_SSBT: {
    api: 'https://inbet-retail-ssbt-ssl-int.egt-digital.com',
    domain: 'https://inbet-retail-ssbt-ssl-int.egt-digital.com/',
  },

  IBBGRT_UAT_RETAIL_SSBT: {
    api: 'https://inbet-retail-ssbt-ssl-uat.egt-digital.com',
    domain: 'https://inbet-retail-ssbt-ssl-uat.egt-digital.com/',
  },

  // Inbet cashier
  IBBGRT_DEV_RETAIL_CASHIER: {
    api: 'https://inbet-retail-cashier-modern-ssl-dev.egt-digital.com',
    domain: 'https://inbet-retail-cashier-modern-ssl-dev.egt-digital.com',
  },
  IBBGRT_INT_RETAIL_CASHIER: {
    api: 'https://inbet-retail-cashier-modern-ssl-int.egt-digital.com',
    domain: 'https://inbet-retail-cashier-modern-ssl-int.egt-digital.com',
  },
  IBBGRT_UAT_RETAIL_CASHIER: {
    api: 'https://inbet-retail-cashier-modern-ssl-uat.egt-digital.com',
    domain: 'https://inbet-retail-cashier-modern-ssl-uat.egt-digital.com',
  },

  IBBGRTNRA_UAT_RETAIL_CASHIER: {
    api: 'https://inbetbg-retail-cashier-modern-ssl-uat.egt-digital.com',
    domain: 'https://inbetbg-retail-cashier-modern-ssl-uat.egt-digital.com',
  },

  IBBGRTNRA_UAT_RETAIL_ONLINE: {
    api: 'https://inbetbg-retail-api-uat.egt-digital.com',
    domain: 'https://inbetbg-retail-uat.egt-digital.com',
  },

  IBBGRT_PRODUCTION_RETAIL_ONLINE: {
    api: 'https://inbet-retail-api.egt-digital.com',
    domain: 'https://inbetsport.com/',
  },
  IBBGRT_PRODUCTION_RETAIL_CASHIER: {
    api: 'https://cashier.inbetsport.com',
    domain: 'https://cashier.inbetsport.com/',
  },

  // MAGNUM BET RETAIL CASHIER
  MBRO_DEV_RETAIL_CASHIER: {
    api: 'https://magnumbet-ro-retail-cashier-ssl-dev.egt-digital.com',
    domain: 'https://magnumbet-ro-retail-cashier-ssl-dev.egt-digital.com',
  },
  MBRO_INT_RETAIL_CASHIER: {
    api: 'https://magnumbet-ro-retail-cashier-ssl-int.egt-digital.com',
    domain: 'https://magnumbet-ro-retail-cashier-ssl-int.egt-digital.com',
  },
  MBRO_UAT_RETAIL_CASHIER: {
    api: 'https://magnumbet-ro-retail-cashier-ssl-uat.egt-digital.com',
    domain: 'https://magnumbet-ro-retail-cashier-ssl-uat.egt-digital.com',
  },

  // MAGNUM BET RETAIL SSBT
  MBRO_DEV_RETAIL_SSBT: {
    api: 'https://magnumbet-ro-retail-ssbt-ssl-dev.egt-digital.com',
    domain: 'https://magnumbet-ro-retail-ssbt-ssl-dev.egt-digital.com',
  },
  MBRO_INT_RETAIL_SSBT: {
    api: 'https://magnumbet-ro-retail-ssbt-ssl-int.egt-digital.com',
    domain: 'https://magnumbet-ro-retail-ssbt-ssl-int.egt-digital.com',
  },
  MBRO_UAT_RETAIL_SSBT: {
    api: 'https://magnumbet-ro-retail-ssbt-ssl-uat.egt-digital.com',
    domain: 'https://magnumbet-ro-retail-ssbt-ssl-uat.egt-digital.com',
  },

  // KAJOT
  KJSK_DEV: { api: 'https://kajot-api-dev.egt-digital.com', domain: 'https://kajot-dev.egt-digital.com' },
  KJSK_INT: { api: 'https://kajot-api-int.egt-digital.com', domain: 'https://kajot-int.egt-digital.com' },
  KJSK_UAT: { api: 'https://kajot-api-uat.egt-digital.com', domain: 'https://kajot-uat.egt-digital.com' },

  // VIVABET
  VVRO_DEV: { api: 'https://vivabet-ro-api-dev.egt-digital.com', domain: 'https://vivabet-ro-dev.egt-digital.com' },
  VVRO_INT: { api: 'https://vivabet-ro-api-int.egt-digital.com', domain: 'https://vivabet-ro-int.egt-digital.com' },
  VVRO_UAT: { api: 'https://vivabet-ro-api-uat.egt-digital.com', domain: 'https://vivabet-ro-uat.egt-digital.com' },
  VVRO_PRODUCTION: { api: 'https://vivabet-ro-api.egt-digital.com', domain: 'https://vivabet.ro' },

  // ESGLI
  ESGLI_DEV: { api: 'https://esgli-api-dev.egt-digital.com', domain: 'https://esgli-dev.egt-digital.com' },
  ESGLI_INT: { api: 'https://esgli-api-int.egt-digital.com', domain: 'https://esgli-int.egt-digital.com' },
  ESGLI_UAT: { api: 'https://esgli-api-uat.egt-digital.com', domain: 'https://esgli-uat.egt-digital.com' },

  // FOLIATTI
  FTMX_DEV: { api: 'https://foliatti-mx-api-dev.egt-digital.com', domain: 'https://foliatti-mx-dev.egt-digital.com' },
  FTMX_INT: { api: 'https://foliatti-mx-api-int.egt-digital.com', domain: 'https://foliatti-mx-int.egt-digital.com' },
  FTMX_UAT: { api: 'https://foliatti-mx-api-uat.egt-digital.com', domain: 'https://foliatti-mx-uat.egt-digital.com' },
  FTMX_PRODUCTION: { api: 'https://foliatti-mx-api.egt-digital.com', domain: 'https://foliatti.egt-digital.com' },

  // MAGICBET
  MGBG_DEV: { api: 'https://magicbet-bg-api-dev.egt-digital.com', domain: 'https://magicbet-bg-dev.egt-digital.com' },
  MGBG_INT: { api: 'https://magicbet-bg-api-int.egt-digital.com', domain: 'https://magicbet-bg-int.egt-digital.com' },
  MGBG_UAT: { api: 'https://magicbet-bg-api-uat.egt-digital.com', domain: 'https://magicbet-bg-uat.egt-digital.com' },
  MGBG_PRODUCTION: { api: 'https://mgbg-api.egt-digital.com', domain: 'https://magicbet.bg' },

  // LUCKYSEVEN
  LSRO_DEV: {
    api: 'https://luckyseven-ro-api-dev.egt-digital.com',
    domain: 'https://luckyseven-ro-dev.egt-digital.com',
  },
  LSRO_INT: {
    api: 'https://luckyseven-ro-api-int.egt-digital.com',
    domain: 'https://luckyseven-ro-int.egt-digital.com',
  },
  LSRO_UAT: {
    api: 'https://luckyseven-ro-api-uat.egt-digital.com',
    domain: 'https://luckyseven-ro-uat.egt-digital.com',
  },

  // ONE CASINO
  OCRO_UAT: {
    api: 'https://onecasino-ro-api-uat.egt-digital.com',
    domain: 'https://onecasino-ro-uat.egt-digital.com',
  },
  OCRO_PRODUCTION: {
    api: 'https://onecasino-ro-api.egt-digital.com',
    domain: 'https://onecasino-ro.egt-digital.com',
  },
} as const;

const attachScript = (src: string, callback?: () => void) => {
  const h = document.getElementsByTagName('head')[0];
  const s = document.createElement('script');
  s.src = src;
  s.onload = () => callback && callback();
  h.appendChild(s);
};

const timeNow = new Date().getTime();

export const setActiveDomainLayoutConfig = () => {
  if (isDevelopment) {
    if (
      (window.config.BUSINESS_UNIT as string) !== '${BUSINESS_UNIT}' &&
      (window.config.ENV_TYPE as string) !== '${ENV_TYPE}'
    ) {
      sessionStorage.setItem(
        'devAPISource',
        `${getWindowConfigParam('BUSINESS_UNIT')}_${getWindowConfigParam('ENV_TYPE')}${
          retailChannel ? `_${retailChannel}_${retailDevice}` : ''
        }`,
      );
    }

    const epoch = getWindowConfigParam('EPOCH') || timeNow;
    const setPlatformLayout = () => store.dispatch(setLayoutConfig(window.layout));
    // Attach platform development layout config and set in store for local development
    const platformSrc = `${activeDomainApiMap[ACTIVE_BU_DEV].domain}/layout/layout.js?v=${epoch}`;
    attachScript(platformSrc, setPlatformLayout);
  }
};

const getApiUrlFromServerEnv = () => {
  const { API_URL } = window.config;
  return API_URL;
};

const ACTIVE_BU_API = isDevelopment ? activeDomainApiMap[ACTIVE_BU_DEV].api : getApiUrlFromServerEnv();

const FB_TRACKING_SERVICE_API_URL = isDevelopment ? '' : window.config.FB_TRACKING_SERVICE_URL;
const ERROR_LOGGER_SERVICE_API_URL =
  window.config.ENV_TYPE !== 'PRODUCTION' ? 'https://winbet-api-dev.egt-digital.com/api/error-collector/listen' : ''; //temp disable PRODUCTION logs

const CDN_URL = `https://${getCdnBusinessUnit()}cdn.kubdev.com`;

const config = {
  CDN_URL: CDN_URL,
  CDN_IMAGE_API: `${CDN_URL}/cdn-cgi/image/`,
  API_URL: `${ACTIVE_BU_API}`,
  CASINO_CAROUSEL_CDN_IMAGE_API: 'gaming-content/slider/',
  PAYMENT_PROVIDERS_LOGOS_URL: 'common-content/help/payment-methods/',
  PAYMENT_PROVIDER_URL: 'https://pay.paymentiq.io',
  FB_TRACKING_SERVICE_URL: `${FB_TRACKING_SERVICE_API_URL}`,
  ERROR_LOGGER_URL: `${ERROR_LOGGER_SERVICE_API_URL}`,
  WIDGETS_SCRIPT: 'https://widgets.sir.sportradar.com/efc04764567bafa4cb35aa9b7b2c3df9/widgetloader',
  BETRADAR_STREAM_SCRIPT: 'https://avplayer-cdn.sportradar.com/dist/latest/avvpl-player.js',
  LIVESCORE_PAGE_SCRIPT: `https://ls.sir.sportradar.com/${getClientNameBU()}/iframescript.js`,
  VIRTUAL_PLAYER_CLIENT_ID: 29967,
  VIRTUAL_IFRAME_CLIENT_ID: 5087,
  VIRTUAL_CLIENT_NAME: `${getClientNameBU()}`,
};

export const GAME_LAUNCH_PREFIX_PLATFORM = 'game-launch';

export const locationPath = {
  // SPORT
  SPORT: 'sports',
  ESPORTS: 'esports',
  // CASINO
  CASINOEGT: 'casino-egt',
  CASINO: 'casino',
  LIVECASINO: 'live-casino',
  CASINOGAMES: 'casino-games',
  VEGAS: 'vegas',
  GAMELAUNCH: GAME_LAUNCH_PREFIX_PLATFORM,
  // PROMOTION
  PROMOTIONS: 'promotions',
  // help
  HELP: 'help',
  // MY ACCOUNT
  // MYACCOUNT: 'my-account',
  // CASHIER
  // CASHIER: 'my-account',
  // LANDIGN PAGE
  LANDING: 'landing',
} as const;

export const cssPath = {
  SPORT: 'sport',
  ESPORT: 'sport',
  CASINO: 'casino',
  HELP: 'help',
  ACCOUNT: 'myAccount',
  GAMELAUNCH: 'casino',
  // MYACCOUNTDROPDOWN: 'myAccountDropdown',
  // CASHIER: 'egtdCashier',
  TERMINAL: 'terminal',
  LANDINGPAGE: 'landingPage',
  PROMOTIONS: 'promotions',
} as const;

export default config;
