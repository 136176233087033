import { SocialAccountsUI } from 'pages/myAccount/tabs/account/sections/components/socialAccountsUI';
import SocialIcon from '../SocialIcon';
import { Social, SocialButtonInputTypes } from '../types/formSocial.types';

const TwitterButton = {
  element: ({ className, button, isDisabled, t }: Social.ElementProps) => {
    const onClickHandler = !isDisabled
      ? undefined
      : () => {
          window.open(
            'https://api.twitter.com/oauth/authenticate?oauth_token=cHdlmPofsk0WTmqottg7jFlU9nfDHLj6pYusAVPmQ',
            'popup',
            'width=600,height=600',
          );
        };

    return button.inputType === SocialButtonInputTypes.UI_BUTTON ? (
      <SocialAccountsUI.Link
        type={button.type}
        text={button.label}
        btnText={button.text}
        isDisabled={isDisabled}
        onClick={onClickHandler}
      />
    ) : (
      <div className={className} onClick={onClickHandler}>
        {button.inputType === SocialButtonInputTypes.ICON && (
          <div className="form-social__button-logo">
            <SocialIcon type={button.type} size={20} />
          </div>
        )}
        {button.label && <div className="form-social__button-text">{t(button.label)}</div>}
      </div>
    );
  },
};

export default TwitterButton;
