import { LayoutConfigInnerLayoutTypes } from 'modules/casino/shared/constants';
import { useCasinoLayoutConfig } from 'modules/casino/shared/hooks/useCasinoLayoutConfig';
import { selectGeneral } from 'shared/common/features/general/slice/general.slice';
import { useAppSelector } from 'store';
import { DEFAULT_PROVIDERS_POSITION } from '../constants/casinoGameProvidersConstants';

export const useProvidersBarPositionConfig = () => {
  const isLayoutConfigLoaded = useAppSelector(selectGeneral.isLayoutConfigLoaded);
  const providersPositionConfig = useCasinoLayoutConfig({
    innerLayout: LayoutConfigInnerLayoutTypes.CASINO_PROVIDERS_BAR_POSITION,
  });

  return (
    isLayoutConfigLoaded &&
    ((providersPositionConfig && providersPositionConfig?.toUpperCase()) || DEFAULT_PROVIDERS_POSITION)
  );
};
