import { isEmpty } from '../helpersCommon';
import { ET, Sport } from '../types/sportTypes';

type Event = {
  markets: Record<string, Sport.MultilineMarket>;
  marketsCount?: number;
  feedProducerId: number;
  marketIds: string[];
};

export const updateOdds = (
  event: Event,
  currentMarketId: string,
  outcomes: Sport.Outcome[],
  marketStatus: Sport.MarketStatus,
): void => {
  event.markets[currentMarketId]?.outcomes?.forEach((marketOutcome, outcomeIndex) => {
    const oddsUpdate = outcomes.find((outcome) => outcome.id === marketOutcome.id);

    if (oddsUpdate) {
      event.markets[currentMarketId].outcomes[outcomeIndex].odds = oddsUpdate.odds;
      event.markets[currentMarketId].outcomes[outcomeIndex].originalOdds = oddsUpdate.originalOdds;
      event.markets[currentMarketId].outcomes[outcomeIndex].americanOdds = oddsUpdate.americanOdds;
      event.markets[currentMarketId].outcomes[outcomeIndex].fractionalOdds = oddsUpdate.fractionalOdds;
      event.markets[currentMarketId].outcomes[outcomeIndex].originalFractionalOdds = oddsUpdate.originalFractionalOdds;
      event.markets[currentMarketId].outcomes[outcomeIndex].originalAmericanOdds = oddsUpdate.originalAmericanOdds;
      event.markets[currentMarketId].outcomes[outcomeIndex].isActive = !!(oddsUpdate as Sport.Outcome).isActive;
    }
  });
  event.markets[currentMarketId].marketStatus = marketStatus;
};

export const updateFavoriteLine = (
  event: Event,
  marketId: string,
  currentMarketId: string,
  marketStatus: Sport.MarketStatus,
  marketName: string,
  outcomes: Sport.Outcome[],
): void => {
  const currentMarket = event.markets[currentMarketId];
  event.markets[marketId] = {
    ...currentMarket,
    marketId,
    marketStatus: marketStatus,
    marketName: marketName || currentMarket.marketName,
    outcomes: outcomes.map((outcome, index) => {
      const currentOutcome = currentMarket?.outcomes[index];
      return {
        ...outcome,
        id: outcome.id,
        name: currentOutcome?.name,
        title: currentOutcome?.title,
        odds: outcome.odds,
        fractionalOdds: outcome.fractionalOdds,
        americanOdds: outcome.americanOdds,
        isActive: !!(outcome as Sport.Outcome).isActive,
        specifier: outcome.specifier,
        ...(currentOutcome?.columnName && { columnName: currentOutcome?.columnName }),
        marketTemplateOutcomeId: outcome.marketTemplateOutcomeId,
      };
    }),
  };
  event.marketIds.push(marketId);
  delete event.markets[currentMarketId];
  const index = event.marketIds.findIndex((id: string) => id === currentMarketId);
  if (index !== -1) event.marketIds.splice(index, 1);
};

export const hasOutcomes = (
  markets: ET.HeadToHeadMarket | { marketId: string; marketTemplateId: number },
): markets is ET.HeadToHeadMarket => {
  if (!isEmpty((markets as ET.HeadToHeadMarket).outcomes)) {
    return true;
  }
  return false;
};
