import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/shared/Modal';
import { ModalUI } from 'components/shared/ModalUI';
import { UI } from 'components/shared/UI';
import userStorage from 'pages/auth/login/UserStorage';
import { useNavigateToBankTab } from 'pages/myAccount/tabs/cashier/utils/cashier.utils';
import { useAppDispatch } from 'store';
import { fitCover } from 'utils/common/cdnConfig';
import { MyProfile } from 'utils/common/translationUtils/translationStrings';
import config from 'utils/config';
import { refreshWidget } from '../../integrations/cashierWidget/cashierWidget.slice';
import { INSUFFICIENT_BALANCE_MODAL } from '../../myProfile/components/InsufficientBalanceTrigger';
import useHandleModals from '../hooks/useHandleModals';

const cdnPath = `${config.CDN_IMAGE_API}${fitCover(244, 172, 'cover')}`;

const InsufficientBalanceModal = () => {
  const { t } = useTranslation();
  const { closeModal } = useHandleModals();
  const bankNavigate = useNavigateToBankTab();
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    userStorage.setUserModalType(INSUFFICIENT_BALANCE_MODAL);
    closeModal();
  }, [closeModal]);

  const handleDepositButton = () => {
    bankNavigate('deposit');
    dispatch(refreshWidget());
    handleClose();
  };

  return (
    <Modal visible className="modal-terms">
      <Modal.Close closeText={t(MyProfile.OffersTab.closeBtn)} closeAction={handleClose} />
      <Modal.Body className="d-flex-col-ac text-center" padding="lg">
        <img className="mb-3" height={86} src={`${cdnPath}/common-content/general-ui/balance.png`} />
        <ModalUI.Title className="mb-3 text-uppercase" hasAccent text={t(MyProfile.InsufficientBalance.lowBalance)} />
        <ModalUI.Title className="mb-3" text={t(MyProfile.InsufficientBalance.loadBalanceText)} />
        <UI.Button size="lg" onClick={handleDepositButton} text={t(MyProfile.InsufficientBalance.depositBtn)} />
      </Modal.Body>
    </Modal>
  );
};

export default InsufficientBalanceModal;
