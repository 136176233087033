export declare module MessagesTypes {
  type State = {
    postMessageState: PostMessageState | null;
  };

  type PostMessageState = {
    type: PostMessageType;
    options: { gameInfo: string; ratioType: RatioType; errorCode: ErrorCodes; isProviderWithLobby: boolean };
    sendMessage?: SendMessage;
  };

  export type RatioType =
    | 'RESPONSIVE'
    | 'OLD-STANDARD'
    | 'MODERN-STANDARD'
    | 'ULTRAWIDE'
    | 'SUPERWIDE'
    | 'CUSTOM-STANDARD-1';

  export type SendMessage = {
    message?: string | Record<string, string | undefined>;
    type: string;
  };

  type ProviderPostMessageConfig = {
    message: string;
    type: string;
    sendMessage?: SendMessage;
    allowDuplicatedMessages?: boolean;
    refGameIdPath?: string;
    withProviderGameAlias?: boolean;
    parseData?: boolean;
    sliceRefGameId?: boolean;
  };
}

export enum PostMessageReceiverType {
  MOBILE_APP = 'MOBILE_APP',
  PROVIDER = 'PROVIDER',
  PROVIDER_FULSCREEN_SEND = 'PROVIDER_FULSCREEN_SEND',
}

export enum PostMessageType {
  EXIT = 'EXIT',
  RELOAD = 'RELOAD',
  CASHIER = 'CASHIER',
  PLAYFORREALCOMMAND = 'PLAYFORREALCOMMAND',
  SCREEN_SCROLLING = 'SCREEN_SCROLLING',
  SCREEN_NOT_SCROLLING = 'SCREEN_NOT_SCROLLING',
  OPEN_GAME = 'OPEN_GAME',
  OPEN_LOBBY = 'OPEN_LOBBY',
  FULLSCREEN = 'FULLSCREEN',
  SEND_MESSAGE = 'SEND_MESSAGE',
  RATIO = 'RATIO',
  ERROR = 'ERROR',
  PROVIDER_INFO = 'PROVIDER_INFO',
  GAME_LOAD_COMPLETED = 'GAME_LOAD_COMPLETED',
}

export enum SendPostMessageCommands {
  ENTER_FULLSCREEN = 'ENTER_FULLSCREEN',
  EXIT_FULLSCREEN = 'EXIT_FULLSCREEN',
}

export enum ErrorCodes {
  LAUNCH_URL_REQUEST_FAIL = '1000',
  LAUNCH_URL_INVALID_LAUNCH_TOKEN = '1100',
  RELAUNCH_URL_REQUEST_FAIL = '2000',
  RELAUNCH_URL_INVALID_LAUNCH_TOKEN = '2100',
  GAME_ID_NOT_FOUND = '3000',
  GAME_ID_REQUEST_FAIL = '3100',
  IFRAME_LOAD_ERROR = '4000',
  INVALID_CLOSE_URL = '5000',
  INVALID_LANG = '5100',
  MISSING_LAUNCH_TOKEN = '5200',
  INVALID_ENABLE_REFRESH = '5300',
  INVALID_HOME_BUTTON = '5400',
  INVALID_ALLOW_DESKTOP_FULLSCREEN = '5500',
  INVALID_ALLOW_MOBILE_FULLSCREEN = '5550',
  INVALID_CASHIER_URL = '5600',
  INVALID_PROVIDER_LOBBY = '5700',
  INVALID_CODE = '5800',
  MISSING_CODE = '5850',
  MISSING_GAME_CODE = '5900',
}
