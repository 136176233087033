import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

/** Cashier Modal Create Ticket Input Info */
interface InputInfoProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  text: string;
  hasEvent?: boolean;
  hasError?: boolean;
}

const InputInfo: React.FC<InputInfoProps> = ({ className, text, hasEvent, hasError }) => {
  const inputInfoClass = clsx(
    'cashier-modal__input-info-message',
    hasEvent && 'input-info-message--has-event',
    hasError && 'input-info-message--has-error',
    className,
  );
  return <div className={inputInfoClass}>{text}</div>;
};

export default InputInfo;
