import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { triggerGTMEvent } from 'modules/casino/shared/features/seo/gtm/GoogleTagManager';
import axiosInstance from 'modules/casino/shared/utils/common/axios-instance';
import { isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import { createAbortThunk } from 'modules/casino/store/thunkCreators';
import { logoutUser, setActiveCasinoVertical } from 'shared/common/sharedSlices/commonActions';
import { AppDispatch } from 'store';
import { RootState } from 'store/rootReducer';
import config from 'utils/config';
import { CategoryImpressionsEventKeys } from '../constants/categoryImpressionsConstants';
import { CategoryImpressionsTypes } from '../types/categoryImpressionsTypes';
import { getImpressionsParams } from '../utils/categoryImpressionsUtils';

export const categoryImpressionsThunk: CategoryImpressionsTypes.CategoryImpressionsThunk = {
  postCategoryImpressions: createAbortThunk(
    `categoryImpressions/postCategoryImpressions`,
    async ({ params }, { source }) => {
      const fetchUrl = `${config.API_URL}/api/casino-recommendations/player/recommendations/feed/Impressions`;

      await axiosInstance.post(fetchUrl, params, { cancelToken: source.token });
    },
  ),
  postGameImpression: createAbortThunk(`categoryImpressions/postGameImpression`, async ({ params }, { source }) => {
    const fetchUrl = `${config.API_URL}/api/casino-recommendations/player/recommendations/feed/Clicks`;

    await axiosInstance.post(fetchUrl, params, { cancelToken: source.token });
  }),
};

export const { postGameImpression, postCategoryImpressions } = categoryImpressionsThunk;

const initialState: CategoryImpressionsTypes.State = {
  registeredCategoryImpressions: {},
  sentCategoryDataIds: [],
};

const categoryImpressions = createSlice({
  name: 'categoryImpressions',
  initialState,
  reducers: {
    registerCategory(
      state,
      action: PayloadAction<{
        gameCollectionId: string;
        eventParams: CategoryImpressionsTypes.CategoryImpressionsRegisteredParams;
      }>,
    ) {
      const { gameCollectionId, eventParams } = action.payload;
      state.registeredCategoryImpressions[gameCollectionId] = eventParams;
    },
    registerCategoryGame(
      state,
      action: PayloadAction<{
        gameCollectionId: string;
        gameData: CategoryImpressionsTypes.RegisteredCategoryGameData;
      }>,
    ) {
      const { gameCollectionId, gameData } = action.payload;

      state.registeredCategoryImpressions[gameCollectionId].items?.push(gameData);
    },
    registerSentCategoryData(state, action: PayloadAction<{ gameCollectionId: string }>) {
      const { gameCollectionId } = action.payload;

      state.sentCategoryDataIds.push(gameCollectionId);
      state.registeredCategoryImpressions[gameCollectionId].items = [];
    },
    clearRegisteredData(state) {
      state.registeredCategoryImpressions = {};
      state.sentCategoryDataIds = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, () => {
      sendOnScrollImpressions();
    });
    builder.addCase(setActiveCasinoVertical, () => {
      sendOnScrollImpressions();
    });
  },
});

export const { registerCategory, registerCategoryGame, clearRegisteredData, registerSentCategoryData } =
  categoryImpressions.actions;

const selectIsCategoryRegistered = (state: RootState, gameCollectionId: string): boolean =>
  !isEmpty(state.casino.categoryImpressions.registeredCategoryImpressions[gameCollectionId]);

const selectRegisteredCategoryParams = (
  state: RootState,
  gameCollectionId: string,
): CategoryImpressionsTypes.CategoryImpressionsRegisteredParams | undefined =>
  state.casino.categoryImpressions.registeredCategoryImpressions[gameCollectionId];

const selectIsCategoryDataSent = (state: RootState, gameCollectionId: string): boolean =>
  state.casino.categoryImpressions.sentCategoryDataIds?.includes(gameCollectionId);

export const categoryImpressionsSelectors = {
  isCategoryRegistered: selectIsCategoryRegistered,
  registeredCategoryParams: selectRegisteredCategoryParams,
  isCategoryDataSent: selectIsCategoryDataSent,
};

export default categoryImpressions.reducer;

export const sendOnScrollImpressions = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const registeredImpressionsByCategory = getState().casino.categoryImpressions.registeredCategoryImpressions;
  const isAuthenticated = getState().auth.login.isAuthenticated;

  if (!isEmpty(registeredImpressionsByCategory)) {
    const keys: string[] = Object.keys(registeredImpressionsByCategory);

    keys?.forEach((gameCollectionId) => {
      const isCategoryDataSent = getState().casino.categoryImpressions.sentCategoryDataIds?.includes(gameCollectionId);
      const registeredCategoryParams = registeredImpressionsByCategory[gameCollectionId];

      if (isCategoryDataSent && registeredCategoryParams?.items?.length) {
        const { eventKey, params } = getImpressionsParams({
          key: CategoryImpressionsEventKeys.CATEGORY_VIEW_SCROLL,
          registeredCategoryParams,
        });

        if (isAuthenticated) {
          dispatch(
            postCategoryImpressions({
              params: { ...params, eventType: eventKey },
            }),
          );
        }

        triggerGTMEvent({ eventKey, additionalParams: params });
        dispatch(registerSentCategoryData({ gameCollectionId }));
      }
    });
  }

  dispatch(clearRegisteredData());
};
