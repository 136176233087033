import { useEffect } from 'react';
import { useLoadingState } from 'modules/casino/shared/hooks/useLoadingState';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppDispatch, useAppSelector } from 'store';
import {
  gameLaunchBonusThunk,
  gamesBonusSelectors,
  resetMissionCollections,
} from '../../../slice/gameLaunchBonus.slice';
import { MISSIONS_COLLECTION_INTERVAL } from '../../../utils/gameLaunchBonusConstants';

export const useCampaignMissionsProgress = ({ isFreePlay }: { isFreePlay: boolean }) => {
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const loadingState = useAppSelector(gamesBonusSelectors.missionsCollectionFetchStatus);
  const { hasInitial, isLoading } = useLoadingState(loadingState);
  const data = useAppSelector(gamesBonusSelectors.missionsCollection);
  const dispatch = useAppDispatch();

  const startPolling = !!(!hasInitial && isAuthenticated);

  useEffect(() => {
    if (!isFreePlay && isAuthenticated) {
      dispatch(gameLaunchBonusThunk.fetchMissionCollectionProgress());
      return () => {
        dispatch(resetMissionCollections());
      };
    }
  }, [dispatch, isAuthenticated, isFreePlay]);

  useEffect(() => {
    if (!isFreePlay && isAuthenticated && data && startPolling) {
      const autoPoll = setInterval(() => {
        dispatch(gameLaunchBonusThunk.fetchMissionCollectionProgress());
      }, MISSIONS_COLLECTION_INTERVAL);
      return () => {
        clearInterval(autoPoll);
      };
    }
  }, [data, dispatch, hasInitial, isAuthenticated, isFreePlay, startPolling]);

  return { isLoading, data };
};
