import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/shared';
import { UI } from 'components/shared/UI';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { setLastPrintClick } from 'modules/retail/modules/cashier/features/lastPrint/slice/lastPrint.slice';
import { selectCashierData } from 'modules/retail/modules/cashier/slice/cashier.slice';
import { selectSessionBalance } from 'modules/retail/modules/ssbt/features/sessionBalance/slice/sessionBalance.slice';
import { TerminalTheme } from 'modules/retail/modules/ssbt/ssbtConfig';
import { TerminalModalActionIcons } from 'modules/retail/modules/ssbt/types/ssbt.types';
import { resetTicketInfoState } from 'modules/retail/shared/slice/checkTicket.slice';
import { setIsVoucherSelect } from 'modules/retail/shared/slice/voucher.slice';
import { loginThunks, showQuickLoginForm } from 'pages/auth/login/slice/login.slice';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import AmountCurrency from 'shared/common/features/myProfile/components/AmountCurrency';
import { showRootModal } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { ModalPriorityEnum } from 'shared/common/features/rootModal/types/modal.types';
import { useAppDispatch, useAppSelector } from 'store';
import { isCashier, isEmpty, isTerminal, LOGIN_FORM_TYPE_AUTH } from 'utils/common/helpersCommon';
import {
  CashierTranslations,
  MyBetsTranslation,
  SSBT,
  TimeoutModal,
} from 'utils/common/translationUtils/translationStrings';

interface Props {
  userBalance: string;
  isAuthenticated: boolean | null;
}

const NavUserTerminal: React.FC<Props> = ({ isAuthenticated }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isPrintingEject = useAppSelector(selectCashierData.isPrintingEject);
  const retailSessionBalance = useAppSelector(selectSessionBalance.retailBalanceInfo)?.sessionBalance;
  // const isDesktop = useMediaQuery({ query: `(min-width: ${Breakpoints.isDesktop}px)` });
  const login = useCallback(() => {
    dispatch(showQuickLoginForm({ status: true, loginActionType: LOGIN_FORM_TYPE_AUTH }));
  }, [dispatch]);
  const showHeaderButtons = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.RETAIL_HEADER_BUTTONS_VISIBLE,
    deviceLayout: 'general',
  });
  // const retailAddress = useLayoutConfigType({
  //   moduleLayout: 'platform',
  //   innerLayout: LayoutConfigTypes.RETAIL_ADDRESS,
  //   deviceLayout: 'general',
  // });

  const logoutCashier = () => {
    dispatch(loginThunks.logout());
  };

  const showTerminalDepostiModal = () => {
    dispatch(
      showRootModal({
        modalType: 'TERMINAL_DEPOSIT_CASH',
        modalId: 'TERMINAL_DEPOSIT_CASH',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
    dispatch(setIsVoucherSelect(false));
  };

  const showCheckTicketTerminal = () => {
    dispatch(
      showRootModal({
        modalType: 'TERMINAL_CHECK_TICKET',
        modalId: 'TERMINAL_CHECK_TICKET',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
  };

  // const showWithdrawTerminalModal = () => {
  //   if (isAuthenticated) {
  //     dispatch(showRootSportModal({ modalType: 'TERMINAL_WITHDRAW' }));
  //   }
  // };

  const showCashierModal = () => {
    dispatch(
      showRootModal({
        modalType: 'CASHIER_MODAL',
        modalId: 'CASHIER_MODAL',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
    dispatch(resetTicketInfoState());
  };

  const showFinanceModal = () => {
    dispatch(
      showRootModal({
        modalType: 'RETAIL_FINANCE_MODAL',
        modalId: 'RETAIL_FINANCE_MODAL',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
  };

  const createVoucher = () => {
    dispatch(
      showRootModal({
        modalType: 'TERMINAL_CREATE_VOUCHER',
        modalId: 'TERMINAL_CREATE_VOUCHER1',
        modalPriority: ModalPriorityEnum.LOW,
      }),
    );
    // return (
    //   <>

    //   </>
    // );
    //   dispatch(terminalThunks.createVoucher({}));
  };

  const handleLastPrint = () => {
    dispatch(setLastPrintClick(true));
  };
  return (
    <>
      {!isAuthenticated && showHeaderButtons?.login && (
        <UI.Button
          variant={'tertiary'}
          size="lg"
          onClick={
            !isAuthenticated
              ? login
              : () => {
                  return;
                }
          }
          text={t(`AppHeader.NavUser.loginBtn`)}
          iconPath={isTerminal() ? TerminalTheme.icons.ui : ''}
          icon={isTerminal() ? TerminalModalActionIcons.WALLET : 'user-active'}
        />
      )}
      {isAuthenticated && !isEmpty(retailSessionBalance) && isTerminal() && (
        <div className="d-flex-je navbar-text nav-user__deposit">
          <AmountCurrency
            amount={retailSessionBalance?.amount}
            externalCurrencySymbol={retailSessionBalance?.currency}
          />
          <span className="ml-2">
            <Icon iconPath={TerminalTheme.icons.ui} icon={TerminalModalActionIcons.WALLET} />
          </span>
        </div>
      )}
      {showHeaderButtons?.deposit && (
        <UI.Button
          variant="tertiary"
          size="lg"
          className="ml-3"
          onClick={isAuthenticated ? showTerminalDepostiModal : login}
          text={t(SSBT.Navigation.deposit)}
          iconPath={isTerminal() ? TerminalTheme.icons.ui : ''}
          icon={isTerminal() ? TerminalModalActionIcons.DEPOSIT : 'gear'}
        />
      )}

      {showHeaderButtons?.checkTicket && (
        <UI.Button
          variant="tertiary"
          size="lg"
          className="ml-3"
          onClick={showCheckTicketTerminal}
          text={t(SSBT.Navigation.checkTicket)}
          iconPath={isTerminal() ? TerminalTheme.icons.ui : ''}
          icon={isTerminal() ? TerminalModalActionIcons.QR : 'gear'}
        />
      )}

      {!isEmpty(retailSessionBalance) && retailSessionBalance?.amount > 0 && showHeaderButtons?.createVoucher && (
        <UI.Button
          variant="tertiary"
          size="lg"
          className="ml-3"
          onClick={() => createVoucher()}
          iconPath={isTerminal() ? TerminalTheme.icons.ui : ''}
          icon={isTerminal() ? TerminalModalActionIcons.WALLET : 'gear'}
          text={`${t(MyBetsTranslation.BetSummary.cashoutText)}`}
        />
      )}

      {isCashier() && isAuthenticated && (
        <UI.Button
          variant="tertiary"
          size="lg"
          className="ml-3"
          onClick={() => showCashierModal()}
          text={t(CashierTranslations.HeaderButton.cashierModalButtonText)}
          icon={'gear'}
        />
      )}
      {showHeaderButtons?.finance && isAuthenticated && (
        <UI.Button
          variant="tertiary"
          size="lg"
          className="ml-3"
          onClick={() => showFinanceModal()}
          text={t(CashierTranslations.HeaderButton.financeButtonText)}
          icon={'gear'}
        />
      )}
      {showHeaderButtons?.lastPrint && isAuthenticated && (
        <UI.Button
          variant="tertiary"
          size="lg"
          className="ml-3"
          isDisabled={isPrintingEject}
          onClick={handleLastPrint}
          text={t(CashierTranslations.HeaderButton.lastPrintText)}
          icon={'gear'}
        />
      )}
      {showHeaderButtons?.logout && isAuthenticated && (
        <UI.Button
          variant="tertiary"
          size="lg"
          className="ml-3"
          onClick={logoutCashier}
          text={t(TimeoutModal.Button.logoutText)}
          icon={'logout'}
        />
      )}
    </>
  );
};

export default memo(NavUserTerminal);
