import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrency } from 'hooks/common/useCurrency';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';
import useHandleModals from 'shared/common/features/rootModal/hooks/useHandleModals';
import { clearModalsQueue } from 'shared/common/features/rootModal/slice/rootModal.slice';
import { useAppDispatch } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { CashierTranslations, MyAccountTranslation } from 'utils/common/translationUtils/translationStrings';
import FinancialDateControls from './FinancialDateControls';
import FinancialInfoTable from './FinancialInfoTable';
import { useFinancialInfo } from './useFinanceInfo';
import { useFinancialReportManager } from './useFinancialModal';
import FiltersWrapper from '../../cashierModalUI/FiltersWrapper';
import { CashierModalUI } from '../../cashierModalUI/Index';
import { useCashierModalOpenRequest } from '../../hooks/useCashierModalOpenRequest';
import { useCashierModalPrompt } from '../../hooks/useCashierModalPrompt';
import { clearFinanceData, formatAmountToBRL } from '../../utils';

const FinancialModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { closeModal } = useHandleModals();
  const { t } = useTranslation();
  const isMagnumBetRo = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.IS_MAGNUM_BET_RO,
    deviceLayout: 'general',
  });

  const isCurrencyBeforeAmount = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.CURRENCY_BEFORE_AMOUNT,
  });
  const { currencySymbol } = useCurrency();
  const {
    posInfo,
    reportType,
    requestDate,
    handleToggleReportType,
    selectedDates,
    setSelectedDates,
    handleSearchButton,
  } = useFinancialReportManager();

  const handleToggleBtn = (type: string) => {
    if (isMagnumBetRo) return;
    handleToggleReportType(type);
  };

  const { isPrintingEject } = useFinancialInfo();
  const isCashierModalPrompted = useCashierModalPrompt();
  useCashierModalOpenRequest(isCashierModalPrompted, dispatch, closeModal, clearFinanceData);

  const handleFinancialModalClosing = () => {
    if (isPrintingEject) {
      return;
    }
    dispatch(clearModalsQueue());
    clearFinanceData(dispatch, closeModal);
  };
  const isBalanceReport = reportType === 'Balance';
  const limitMessage = isCurrencyBeforeAmount
    ? `${t(CashierTranslations.FinancialModal.betsLimit)} : ${currencySymbol} ${formatAmountToBRL(posInfo?.posInfo?.financialSettings?.bettingShopTemporaryLimit)}`
    : `${t(CashierTranslations.FinancialModal.betsLimit)} : ${posInfo?.posInfo?.financialSettings?.bettingShopTemporaryLimit}${posInfo?.posInfo?.financialSettings?.currency}`;

  const modalTitle = !isEmpty(posInfo)
    ? `${t(CashierTranslations.FinancialModal.posNameText)} ${posInfo?.posInfo?.name}, `
    : '';
  const terminalName = !isEmpty(posInfo?.posInfo?.terminalName)
    ? `${t(CashierTranslations.ActionButton.terminalName)} - ${posInfo?.posInfo?.terminalName}`
    : '';
  const modalText = `${modalTitle}  ${terminalName}`;

  return (
    <>
      <CashierModalUI.Wrapper isVisible={true}>
        <CashierModalUI.Body>
          <CashierModalUI.Header text={modalText} clearData={handleFinancialModalClosing} />

          <CashierModalUI.Content>
            <FinancialInfoTable isBalanceReport={isBalanceReport} requestDate={requestDate} />
          </CashierModalUI.Content>
          <CashierModalUI.NavCol>
            <FiltersWrapper>
              <CashierModalUI.TabsWrapper>
                <CashierModalUI.Tab
                  text={t(MyAccountTranslation.TransactionHistory.Headers.balance)}
                  onClick={() => handleToggleBtn('Balance')}
                  isActive={isBalanceReport}
                />
                {!isMagnumBetRo && (
                  <CashierModalUI.Tab
                    text={t(`Cashier.FinancialModal.comissionText`)}
                    onClick={() => handleToggleReportType('Commission')}
                    isActive={!isBalanceReport}
                  />
                )}
              </CashierModalUI.TabsWrapper>
              <FinancialDateControls
                isBalanceReport={isBalanceReport}
                selectedStartDate={selectedDates.startDate}
                setSelectedDates={setSelectedDates}
                selectedEndDate={selectedDates.endDate}
                handleSearchButton={handleSearchButton}
              />
            </FiltersWrapper>

            {!isEmpty(posInfo) && (
              <CashierModalUI.InfoMessage
                className={'cashier-modal__info-message-financial'}
                infoMessage={limitMessage}
              />
            )}
          </CashierModalUI.NavCol>
        </CashierModalUI.Body>
      </CashierModalUI.Wrapper>
    </>
  );
};

export default FinancialModal;
