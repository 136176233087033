import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import { RootState } from 'store/rootReducer';
import { CasinoPopOver } from './casinoPopOverTypes';

const initialState: CasinoPopOver.PopOverState = {
  modalProps: {
    show: false,
    position: 'left',
    location: 'content',
  },
};

const casinoPopOver = createSlice({
  name: 'casinoPopOver',
  initialState,
  reducers: {
    showPopOver(state, action: PayloadAction<CasinoPopOver.PopOverState>) {
      const { modalProps } = action.payload;

      if (isEmpty(modalProps)) return;

      state.modalProps = modalProps;
    },
    hidePopOver(state) {
      state.modalProps = initialState.modalProps;
    },
  },
});

export const { showPopOver, hidePopOver } = casinoPopOver.actions;

export default casinoPopOver.reducer;

export const selectPopOver = {
  props: (state: RootState) => state.casino.casinoPopOver.modalProps,
};
