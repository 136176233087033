import React from 'react';
import { Flex } from 'components/shared/layout';

type RadiosGroupButtonProps = {
  text: string;
  isActive?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  controlRef;
};

const RadiosGroupButton: React.FC<RadiosGroupButtonProps> = ({ text, isActive, onClick, controlRef }) => {
  const activeProp = isActive ? { 'data-active': isActive } : undefined;
  return (
    <Flex.Center className={'s2zx__btn'} onClick={onClick} ref={controlRef} {...activeProp}>
      <span className="position-relative s2zx__btn-text" {...activeProp}>
        {text}
      </span>
    </Flex.Center>
  );
};

export default RadiosGroupButton;
