import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useMarketingKeys } from 'hooks/common/useMarketingKeys';

const TagManagerInit = (): null => {
  const { GTM_KEY, GTM_AUTH, GTM_ENV } = useMarketingKeys();

  useEffect(() => {
    if (GTM_KEY && GTM_AUTH && GTM_ENV) {
      const tagManagerArgs = {
        gtmId: GTM_KEY,
        auth: GTM_AUTH,
        preview: GTM_ENV,
      };

      TagManager.initialize(tagManagerArgs);
    }
  }, [GTM_KEY, GTM_AUTH, GTM_ENV]);

  return null;
};

export default TagManagerInit;
