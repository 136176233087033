import { fitCover } from 'modules/casino/shared/utils/common/cdnConfig';
import { isEmpty } from 'modules/casino/shared/utils/common/helpersCommon';
import userStorage from 'pages/auth/login/UserStorage';
import config from 'utils/config';
import { JackpotTypes } from './jackpotTypes';

export const getJackpotImageSources = (
  url: string,
  width: number,
  height: number | null,
  quality: number,
  noFit?: boolean,
) => {
  const uri = `${config.CDN_IMAGE_API}${fitCover(
    width * quality,
    height ? height * quality : '',
    noFit ? 'none' : 'cover',
  )}/${url}`;
  return uri;
};

export const thousandsSeparators = (num) => {
  if (num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    // const num_parts = num?.toString().split('.');
    // num_parts[0] = num_parts?.[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    // return num_parts.join('.');
  }

  return '';
};

export const getLevels = ({
  jackpotDenomDetails,
  denomination,
}: {
  jackpotDenomDetails: JackpotTypes.JackpotDenomDetails;
  denomination?: number | undefined;
}) => {
  if (denomination && jackpotDenomDetails[denomination]) {
    return jackpotDenomDetails[denomination]?.levels;
  }
  const denomValues = Object.values(jackpotDenomDetails);

  if (isEmpty(denomValues)) {
    return [];
  }

  /*
    Take the levels from a first jackpotDenomDetails object, not this one with prop = '0',
    because factory jackpots may have only one denomination ('1'). In this case,
    when try to get levels with no denomination and prop = '0', levels will be 'undefined'.
  */
  return denomValues[0]?.levels;
};

export const getJackpotValueByLevel = ({
  jackpotDenomDetails,
  level,
  denomination,
}: {
  jackpotDenomDetails: JackpotTypes.JackpotDenomDetails;
  level: number | string;
  denomination?: number | undefined;
}): number => {
  if (isEmpty(jackpotDenomDetails)) {
    return 0;
  }
  const levels = getLevels({ jackpotDenomDetails, denomination });

  if (isEmpty(levels)) {
    return 0;
  }

  if (denomination) {
    return Number(levels[0]?.levelValue || 0);
  } else {
    return Number(levels.find((item) => item.level === Number(level))?.levelValue || 0);
  }
};

export const getJackpotLevelWithHighestValue = (jackpotDenomDetails: JackpotTypes.JackpotDenomDetails): number => {
  if (!jackpotDenomDetails) {
    return 0;
  }

  const levels = getLevels({ jackpotDenomDetails });

  if (!levels) {
    return 0;
  }

  // Level with '0' array index has the highest value
  return levels[0]?.level;
};

export const getJackpotCurrency = (jackpotCurrencyDetails: JackpotTypes.JackpotCurrencyDetails): string => {
  const userCurrency = String(userStorage.getUserCurrency());

  if (!jackpotCurrencyDetails) {
    return userCurrency;
  }

  if (!isEmpty(jackpotCurrencyDetails[userCurrency]?.denominationWinsDetails)) {
    return userCurrency;
  }

  const currencyDetails = Object.keys(jackpotCurrencyDetails);

  if (isEmpty(currencyDetails)) {
    return userCurrency;
  }

  return (
    currencyDetails.find((currency) => !isEmpty(jackpotCurrencyDetails[currency]?.denominationWinsDetails)) ||
    userCurrency
  );
};

// Mocked Jackpot Update
const getRandomFloat = (min, max) => Math.random() * (max - min) + min;

const getMockedLevel = (currentLevel, index) => {
  const { levelValue } = currentLevel;

  switch (index) {
    case 0:
      return {
        ...currentLevel,
        levelValue: +levelValue + getRandomFloat(15, 50),
      };
    case 1:
      return {
        ...currentLevel,
        levelValue: +levelValue + getRandomFloat(9, 11.5),
      };
    case 2:
      return {
        ...currentLevel,
        levelValue: +levelValue + getRandomFloat(6, 8.5),
      };
    case 3:
      return {
        ...currentLevel,
        levelValue: +levelValue + getRandomFloat(3.2, 5.7),
      };
    case 4:
      return {
        ...currentLevel,
        levelValue: +levelValue + getRandomFloat(0.8, 1.4),
      };
    default:
      return currentLevel;
  }
};

export const getMockedJackpotUpdate = (
  jackpotCurrencyDetails: JackpotTypes.JackpotCurrencyDetails,
): JackpotTypes.JackpotCurrencyDetails => {
  if (!jackpotCurrencyDetails) {
    return jackpotCurrencyDetails;
  }

  const currency = getJackpotCurrency(jackpotCurrencyDetails);
  const denomDetails = jackpotCurrencyDetails[currency]?.denominationWinsDetails;
  const denomEntries = Object.entries(jackpotCurrencyDetails[currency]?.denominationWinsDetails)[0];

  if (isEmpty(denomEntries) || isEmpty(denomEntries[1])) {
    return jackpotCurrencyDetails;
  }

  return {
    ...jackpotCurrencyDetails,
    [currency]: {
      denominationWinsDetails: {
        ...denomDetails,
        [denomEntries[0]]: {
          levels: denomEntries[1]?.levels?.map((currentLevel, idx) => getMockedLevel(currentLevel, idx)),
        },
      },
    },
  };
};
// Mocked Jackpot Update

export const getDecreasedJackpotValue = (jackpotValue: number) => {
  const numbers = String(jackpotValue).split('.')[0].length;
  switch (numbers) {
    case 1:
      return jackpotValue - jackpotValue * 0.2;
    case 2:
      return jackpotValue - jackpotValue * 0.06;
    case 3:
      return jackpotValue - jackpotValue * 0.002;
    case 4:
      return jackpotValue - jackpotValue * 0.002;
    case 5:
      return jackpotValue - jackpotValue * 0.0003;
    case 6:
      return jackpotValue - jackpotValue * 0.00002;
    case 7:
      return jackpotValue - jackpotValue * 0.00005;
    default:
      return jackpotValue;
  }
};

export const isHardcodedLevel = (template: string, level: string | number | undefined) => {
  if (
    (template === 'bell_link' || template === 'high_cash' || 'bell_link_vip') &&
    ((level !== undefined && Number(level) === 3) || (level !== undefined && Number(level) === 4))
  ) {
    return true;
  }

  return false;
};
