import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'components/shared/badges';
import { Flex } from 'components/shared/layout';
import { BetSlipSharedUI } from 'pages/myAccount/tabs/history/sections/sportsHistory/components/BetSlipSharedUI';
import { BonusIconsTypes } from 'pages/myAccount/tabs/history/sections/sportsHistory/components/BetSlipSharedUI/BonusIndicator.types';
import { MyBets } from 'pages/myAccount/tabs/history/sections/sportsHistory/types/myBets.types';

interface MarketProps {
  market: string;
  showBankerLabel: boolean;
  bankerLabel: string;
  selectionBonuses: MyBets.SelectionBonusesTypes;
}

const Market: React.FC<MarketProps> = ({ market, showBankerLabel, bankerLabel, selectionBonuses }) => {
  const { t } = useTranslation();
  const earlyPayoutBonus = selectionBonuses?.['EARLYPAYOUT'];
  const boreDrawBonus = selectionBonuses?.['BOREDRAW'];
  return (
    <div className="d-flex-ac-jb market">
      <Flex className="align-items-baseline">
        {market}
        {!!earlyPayoutBonus && (
          <BetSlipSharedUI.BonusIndicator
            className="ml-1"
            icon={BonusIconsTypes.EarlyPayout}
            text={earlyPayoutBonus?.isPaid ? t('Bonuses.EarlyPayout.paidOff') : ''}
          />
        )}
        {!!boreDrawBonus && (
          <BetSlipSharedUI.BonusIndicator
            className="ml-1"
            icon={BonusIconsTypes.BoreDraw}
            text={boreDrawBonus?.isPaid ? t('Bonuses.BoreDraw.paidOff') : ''}
          />
        )}
      </Flex>

      {showBankerLabel && <Badge variant="banker" label={bankerLabel} />}
    </div>
  );
};

export default Market;
