import React from 'react';
import DataLoader from '../loaders/DataLoader';

const CasinoLoader = () => {
  return (
    <div className="data-loader-wrapper">
      <DataLoader />
    </div>
  );
};
export default CasinoLoader;
