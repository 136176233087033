import { useState, useEffect, useCallback } from 'react';
import { AnyAction } from '@reduxjs/toolkit';
import { Selector } from 'react-redux';
import { useProlongedLoading } from 'hooks/common/useProlongedLoading';
import {
  selectMyProfile,
  clearPasswordCheckError,
  myProfileThunks,
} from 'shared/common/features/myProfile/slice/myProfile.slice';
import { useAppDispatch, useAppSelector } from 'store';
import { RootState } from 'store/rootReducer';
import { CreateThunk } from 'store/thunkCreators';
import { MyAccountTranslation } from 'utils/common/translationUtils/translationStrings';
import { hideRootModal } from '../slice/rootModal.slice';

export const emptySelector = () => undefined;
export type ActionType = () => AnyAction;
export type StringSelector = Selector<RootState, string | undefined, undefined>;

interface Props {
  statusSelector?: StringSelector;
  errorSelector?: StringSelector;
  inputErrorSelector?: StringSelector;
  resetFunc?: ActionType;
  resetInputErrorFunc?: ActionType;
  hasConfirmationInput: boolean;
  inputCheckFunction: CreateThunk<{ inputValue: string; error?: string }, boolean, { rejectValue: string }>;
  inputErrorMessage?: string;
  onSubmit: () => void;
}

const useConfirmationModalData = ({
  statusSelector = emptySelector,
  errorSelector = emptySelector,
  inputErrorSelector = emptySelector,
  resetInputErrorFunc,
  resetFunc,
  hasConfirmationInput,
  inputCheckFunction,
  onSubmit,
  inputErrorMessage,
}: Props) => {
  const [isPassCheckLoading, setPassCheckLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const submitStatus = useAppSelector(statusSelector);
  const submitError: string | undefined = useAppSelector(errorSelector);
  const inputCheckError: string | undefined = useAppSelector(inputErrorSelector);

  const isLoading = useProlongedLoading(submitStatus === 'pending', 800) || isPassCheckLoading;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (inputCheckError) {
      setInputValue('');
      hasConfirmationInput && setPassCheckLoading(false);
    }
  }, [inputCheckError, hasConfirmationInput]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      inputCheckError && resetInputErrorFunc && dispatch(resetInputErrorFunc());
      setInputValue(e.target.value);
    },
    [dispatch, inputCheckError, resetInputErrorFunc],
  );

  const closeModal = useCallback(() => {
    resetFunc && dispatch(resetFunc());
    resetInputErrorFunc && dispatch(resetInputErrorFunc());
    dispatch(hideRootModal());
  }, [dispatch, resetFunc, resetInputErrorFunc]);

  const handleOnSubmit = async () => {
    if (hasConfirmationInput) {
      setPassCheckLoading(true);
      const result = await dispatch(inputCheckFunction({ inputValue, error: inputErrorMessage }));
      result?.payload === true && onSubmit();
      setPassCheckLoading(false);
    } else {
      onSubmit();
    }
  };

  return {
    handleInputChange,
    closeModal,
    handleOnSubmit,
    isLoading,
    submitError,
    submitStatus,
    inputCheckError,
    inputValue,
  };
};

export const passwordConfirmationModalProps = {
  inputErrorSelector: selectMyProfile.passwordCheckError,
  resetInputErrorFunc: clearPasswordCheckError,
  inputCheckFunction: myProfileThunks.checkPassword,
  inputProps: {
    iconPrefix: 'lock',
    inputType: 'password',
    name: 'password',
    placeholder: MyAccountTranslation.Exclusions.passwordPlaceholder,
  },
  inputErrorMessage: MyAccountTranslation.Exclusions.incorrectPassword,
};

export default useConfirmationModalData;
