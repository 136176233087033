import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Sport, InPlayTypes, mapSportIDsByKey } from 'utils/common/types/sportTypes';
import useHandleDrawerSportClick from '../../hooks/useHandleDrawerSportClick';
import { SportsType } from '../../types/drawer.types';
import { DrawerUI } from '../DrawerUI';

interface Props {
  item: InPlayTypes.LiveSport | Sport.ActiveSport;
  isActive: boolean;
  index: number;
  sportType?: SportsType;
}

const SportSectionNavLink: React.FC<Props> = ({ item, isActive, sportType }) => {
  const { t } = useTranslation();
  const { handleSportClick } = useHandleDrawerSportClick();

  return (
    <DrawerUI.Link
      onClick={() => handleSportClick({ sportType, sportId: item.id })}
      isActive={isActive}
      sportIcon={item.id}
      text={item.name}
      showLiveCounter={sportType === SportsType.LiveSport}
      liveCounter={item.liveEventsCount}
      liveCounterText={t('Drawer.Texts.Еvents')}
      showFav={sportType === SportsType.Sport || sportType === SportsType.ESports}
      favId={item.id}
      dataQid={`dr-${mapSportIDsByKey(item.id) || ''}`}
    />
  );
};

export default memo(SportSectionNavLink);
