import React, { memo, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectPromotions } from 'pages/promotions/slice/promotions.slice';
import { PromotionTypes } from 'pages/promotions/types/promotions.types';
import { useAppDispatch, useAppSelector } from 'store';
import { setDrawer } from '../../slice/drawer.slice';
import { DrawerUI } from '../DrawerUI';

interface Props {
  item: PromotionTypes.Category;
}

const PromotionExpandableRow: React.FC<Props> = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const activeMenuItemId = useAppSelector(selectPromotions.activeMenuItemId);

  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsExpanded(item.id === activeMenuItemId);
  }, [item.id, activeMenuItemId]);

  const onClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleProviderClick = (alias: string) => {
    navigate(`/promotions/${item.alias}/${alias.toLowerCase()}`);
    dispatch(setDrawer(false));
  };

  const contentClass = clsx(isExpanded ? '' : 'hidden');

  return (
    <>
      <DrawerUI.Link onClick={onClick} isExpandable isActive={isExpanded} text={item?.title} icon={item?.resources} />
      <div className={contentClass}>
        {item.promotions.map((promo) => {
          return (
            <DrawerUI.Link
              key={promo.id}
              onClick={() => handleProviderClick(promo.alias)}
              isActive={location.pathname.includes(promo.alias)}
              text={promo?.title}
            />
          );
        })}
      </div>
    </>
  );
};

export default memo(PromotionExpandableRow);
