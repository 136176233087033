import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { BonusOffersInfoButton } from '../types/bonusOffersInfoButton.types';

export const BonusOffersInfoButtonThunks: BonusOffersInfoButton.Thunks = {
  fetchCasinoBonusOffersInfo: createAbortThunk(
    'fetchCasinoOffersInfo',
    async (fetchParams, { source, getState, rejectWithValue }) => {
      try {
        const gameId = fetchParams?.gameId || getState().casino.bonusOffersInfoButton.gameId;
        const url = `${config.API_URL}/api/ews-bonuses/player/players/bonuses/offers/all/${gameId}`;
        const response = await axiosInstance.get<BonusOffersInfoButton.ResponseData | 'null'>(url, {
          cancelToken: source.token,
        });

        return response.data !== 'null' ? response.data : null;
      } catch (err) {
        return rejectWithValue(getError.responseData(err));
      }
    },
  ),
};

export const { fetchCasinoBonusOffersInfo } = BonusOffersInfoButtonThunks;

const initialState: BonusOffersInfoButton.SliceTypes = {
  data: null,
  gameId: null,
  error: null,
  isLoading: true,
  isSuccess: true,
};

const bonusOffersInfoButtonSlice = createSlice({
  name: 'fetchCasinoOffersInfo',
  initialState,
  reducers: {
    setGameId(state, action: PayloadAction<string>) {
      state.gameId = action.payload;
    },
    clearUnseenOffersCounter(state) {
      if (state.data) state.data.unseen = [];
    },
    clearBonusOffersInfoButtonData(state) {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCasinoBonusOffersInfo.pending, (state) => {
        state.isLoading = !state.gameId;
      })
      .addCase(fetchCasinoBonusOffersInfo.rejected, (state) => {
        state.error = getError.default();
        state.isLoading = false;
      })
      .addCase(
        fetchCasinoBonusOffersInfo.fulfilled,
        (state, action: PayloadAction<BonusOffersInfoButton.ApiResponse.Main>) => {
          if (JSON.stringify(state.data) !== JSON.stringify(action.payload)) {
            const unseen = [] as string[];
            state.data = action.payload
              ? {
                  bonuses: action.payload.activeOffers.reduce(
                    (bonuses, category) =>
                      category.promotions
                        ? [
                            ...bonuses,
                            ...category.promotions.reduce(
                              (promoBonuses, promotion) => [...promoBonuses, ...(promotion.bonuses || [])],
                              [] as BonusOffersInfoButton.Bonus[],
                            ),
                          ]
                        : bonuses,
                    [] as BonusOffersInfoButton.Bonus[],
                  ),
                  offers: action.payload.inactiveOffers.reduce((bonuses, category) => {
                    if (category.promotions && category.verticalUnseenCounter) {
                      category.promotions.forEach((promotion) =>
                        promotion.bonuses.filter((bonus) => bonus.isUnseen).forEach((bonus) => unseen.push(bonus.id)),
                      );
                    }

                    return [...bonuses, ...(category.promotions || [])];
                  }, [] as BonusOffersInfoButton.Offer[]),
                  unseen,
                }
              : null;
          }
          state.error = null;
          state.isLoading = false;
          state.isSuccess = true;
        },
      );
  },
});

export const { setGameId, clearUnseenOffersCounter, clearBonusOffersInfoButtonData } =
  bonusOffersInfoButtonSlice.actions;
export default bonusOffersInfoButtonSlice.reducer;

export const selectBonusOffersInfoButton = {
  isLoading: (state: RootState): boolean => state.casino.bonusOffersInfoButton.isLoading,
  isSuccess: (state: RootState): boolean => state.casino.bonusOffersInfoButton.isSuccess,
  data: (state: RootState): BonusOffersInfoButton.Data | null => state.casino.bonusOffersInfoButton.data,
  error: (state: RootState): string | null | undefined =>
    state.casino.bonusOffersInfoButton.error as string | null | undefined,
};
