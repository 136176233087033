import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Flex } from 'modules/casino/shared/components/layout';
import { UI } from 'modules/casino/shared/components/UI';
import usePlayerRestrictions from 'modules/casino/shared/hooks/usePlayerRestrictions';
import { usePlayerRestrictionsConfig } from 'modules/casino/shared/hooks/usePlayerRestrictionsConfig';
import { MyAccountTranslation } from 'modules/casino/shared/utils/translationUtils/translationStrings';

import PlayerRestrictionsInfo from './components/PlayerRestrictionsInfo';
import { GameCellRestrictions } from './gameCellRestriction.types';

const GameCellRestriction: React.FC<{ gameBetType: GameCellRestrictions.ProductType }> = ({ gameBetType }) => {
  const { playerRestrictionsByProducts } = usePlayerRestrictions({
    products: [gameBetType],
  });
  const playerRestrictionsConfig = usePlayerRestrictionsConfig();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const buttonLabel = t(playerRestrictionsConfig.buttonText || MyAccountTranslation.Settings.headerText);

  const onButtonClick = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    navigate(playerRestrictionsConfig.buttonRoute);
  };

  return (
    <Flex.Center className="game-cell-actions">
      <Flex.ColAC className="game-cell-actions__wrapper">
        <PlayerRestrictionsInfo
          showBaseInfoOnly
          componentLocation="gameCell"
          restrictions={playerRestrictionsByProducts}
        />
        <UI.Button c-size="md" text={buttonLabel} onClickHandler={onButtonClick} />
      </Flex.ColAC>
    </Flex.Center>
  );
};

export default GameCellRestriction;
GameCellRestriction.displayName = 'GameCellRestriction';
