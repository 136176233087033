import React from 'react';
import { Flex } from 'modules/casino/shared/components/layout';
import JackpotLevelsWrapper from './JackpotLevelsWrapper';
import JackpotLogo from './JackpotLogo';
import { JackpotTypes } from '../jackpotTypes';

interface JackpotSectionProps {
  jackpot: JackpotTypes.JackpotCategoryDetails;
  jackpotId: string;
  fromWidget?: boolean;
  widgetLogo?: boolean;
}

const JackpotSection: React.FC<JackpotSectionProps> = ({ jackpot, jackpotId, fromWidget, widgetLogo }) => {
  const logoUrl = widgetLogo ? jackpot?.widgetLogoLocation : jackpot?.logoLocation;

  return (
    <Flex.ColCenter className={'jackpot-section__container '}>
      <JackpotLogo logoUrl={logoUrl} />
      <JackpotLevelsWrapper jackpotId={jackpotId} jackpot={jackpot} fromWidget={fromWidget} />
    </Flex.ColCenter>
  );
};

export default JackpotSection;
