import { RefObject } from 'react';
import config from 'utils/config';

type Props = {
  handleVideoIsLoaded?: () => void;
  soundEffect?: string;
  soundEffectOgg?: string;
  folder: string;
  loop?: boolean;
  audioRef?: RefObject<HTMLAudioElement>;
  autoPlay?: boolean;
};

export const SoundEffect: React.FC<Props> = ({
  soundEffect,
  folder,
  loop,
  audioRef,
  soundEffectOgg,
  autoPlay = true,
}) => {
  return (
    <>
      {soundEffect && (
        <audio id="player" autoPlay={autoPlay} loop={loop} ref={audioRef}>
          {soundEffectOgg && (
            <source
              src={`${config.CDN_URL}/common-content/gamification/${folder}/${soundEffectOgg}`}
              type="audio/ogg"
            />
          )}
          <source src={`${config.CDN_URL}/common-content/gamification/${folder}/${soundEffect}`} type="audio/mp3" />
        </audio>
      )}
    </>
  );
};
