export enum CategoryLayoutTypes {
  LOBBY = 'LOBBY',
  PROVIDER_CATEGORY = 'PROVIDER_CATEGORY',
  PROVIDER_CATEGORY_MOBILE_LAYOUT = 'MOBILE_LAYOUT',
  INNER_CATEGORY = 'INNER_CATEGORY',
  FAVOURITES_CATEGORY = 'FAVOURITES_CATEGORY',
  FAVOURITES_MODAL_CATEGORY = 'FAVOURITES_MODAL_CATEGORY',
  SEARCH_RESULTS_CATEGORY = 'SEARCH_RESULTS_CATEGORY',
  MOST_SEARCHED_CATEGORY = 'MOST_SEARCHED_CATEGORY',
  LANDING_CATEGORY = 'LANDING_CATEGORY',
  EXTERNAL_CATEGORY = 'EXTERNAL_CATEGORY',
  SVARA_CATEGORY = 'SVARA_CATEGORY',
  IN_GAME_FAVOURITES_CATEGORY = 'IN_GAME_FAVOURITES_CATEGORY',
}
export enum CategorySubTypes {
  FREE_SPIN = 'FREE_SPIN',
  IN_GAME_FREE_SPIN = 'IN_GAME_FREE_SPIN',
  FREE_SPIN_CHALLENGE = 'FREE_SPIN_CHALLENGE',
  FOOTER = 'FOOTER',
  PROMOTION = 'PROMOTION',
  LANDING = 'LANDING',
  SIDEBAR = 'SIDEBAR',
  RESULT_SEARCHED = 'SEARCHED_RESULTS',
  MOST_SEARCHED = 'MOST_SEARCHED_RESULTS',
  FAVOURITES_MODAL = 'FAVOURITES_MODAL',
  STANDARD = 'STANDARD',
  PARALLAX = 'PARALLAX',
  IN_GAME_SEARCH = 'IN_GAME_SEARCH',
  IN_GAME_FAVOURITES = 'IN_GAME_FAVOURITES',
  IN_GAME_MOST_PLAYED = 'IN_GAME_MOST_PLAYED',
  IN_GAME_RECOMMENDED = 'IN_GAME_RECOMMENDED',
  IN_GAME_SEARCHED_RESULTS = 'IN_GAME_SEARCHED_RESULTS',
  IN_GAME_MOST_SEARCHED_RESULTS = 'IN_GAME_MOST_SEARCHED_RESULTS',
}

// LAYOUT_SIDEBAR = 'LAYOUT_SIDEBAR', // casino vertical: for games in sport sidebar
//   LAYOUT_GAMES_FEED_PROMOTION = 'LAYOUT_GAMES_FEED_PROMOTION', // casino slider: one row for promotions / only regular
//   LAYOUT_GAMES_FEED_FOOTER = 'LAYOUT_GAMES_FEED_FOOTER', // casino slider: one row for footer / only regular
//   LAYOUT_FREE_SPIN_BONUS = 'LAYOUT_FREE_SPIN_BONUS', // casino slider: one row for my profile - my offers / only regular
//   LAYOUT_LANDING = 'LAYOUT_LANDING', // casino landing and home category with title
//   LAYOUT_LANDING_NO_TITLE = 'LAYOUT_LANDING_NO_TITLE', // casino landing and home category no title DEPRECATED
//   LAYOUT_FAVOURITES_MODAL = 'LAYOUT_FAVOURITES_MODAL', // only VERTICAL for games fav modal from bottom bar
//   LAYOUT_RESULT_SEARCHED = 'LAYOUT_RESULT_SEARCHED', // only VERTICAL for games in search results
//   LAYOUT_MOST_SEARCHED = 'LAYOUT_MOST_SEARCHED', // only VERTICAL for games in most searched
//   LAYOUT_UNKNOWN = 'LAYOUT_UNKNOWN', // Do not enter to layout.js !!!!
//   LAYOUT_FAVOURITES_CATEGORY = 'LAYOUT_FAVOURITES_CATEGORY', DEPRECATED
//   LAYOUT_FREE_SPIN_CHALLENGES = 'LAYOUT_FREE_SPIN_CHALLENGES',
//   LAYOUT_LANDING_CONTAINER = 'LAYOUT_LANDING_CONTAINER', // casino landing/home container

export const INNER_CATEGORIES = [
  CategoryLayoutTypes.INNER_CATEGORY,
  CategoryLayoutTypes.FAVOURITES_CATEGORY,
  CategoryLayoutTypes.MOST_SEARCHED_CATEGORY,
  CategoryLayoutTypes.SEARCH_RESULTS_CATEGORY,
  CategoryLayoutTypes.EXTERNAL_CATEGORY,
  CategoryLayoutTypes.SVARA_CATEGORY,
];

export type SearchTag = {
  name: string;
  id: number;
};

export type SetCategoryActiveFilterId = {
  collectionId: string;
  activeFilterId: string;
};

// export interface CasinoCategoryBreadcrumb {
//   // activeFilter: string;
//   // categorySearchTags: string;
//   // gamesCount: number;
//   // isFilterEnabled: boolean;
//   // searchTags: SearchTag[];
//   // title: string;
//   // providerCategoryTitle: string;
//   // id: number;
//   // categoryAlias: string;
//   // fromProviders: boolean;
// }

interface CategoryFilterData {
  activeFilterId: string;
  // categorySearchTags: string;
  // gamesCount: number;
  isFilterEnabled: boolean;
  searchTags: SearchTag[];
  // title: string;
  // providerCategoryTitle: string;
  // id: number;
  // categoryAlias: string;
  // fromProviders: boolean;
}

export interface CasinoCategoryBreadcrumb {
  categoryFilters: Record<string, CategoryFilterData>;
  categoryScroolTags;
}
