import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { getError } from 'utils/common/helpersCommon';
import config from 'utils/config';
import { SEConfirmationThunk, SEConfirmationType, SuccessConfirmation } from '../types/selfExclusionConfirmation.types';

const initialState: SEConfirmationType = {
  submitError: '',
  isLoading: true,
  successConfirmation: '',
};

export const SEConfirmationThunks: SEConfirmationThunk = {
  validateSelfExclusion: createAbortThunk(
    'selfExclusionConfirmation/validateUser',
    async (arg, { rejectWithValue }) => {
      try {
        const url = `${config.API_URL}/api/ews-crm/public/player/self-exclusion-confirmation`;

        const params = { token: encodeURI(arg.urlToken), cstype: arg.cstype };

        const response = await axiosInstance.get<SuccessConfirmation>(url, { params });
        if (response?.status) {
          if (response.status === 200) {
            return response.data;
          }
        }
      } catch (err) {
        return rejectWithValue(getError.responseDataMessage(err));
      }
    },
  ),
};

const seConfirmationSlice = createSlice({
  name: 'selfExclusionConfirmation',
  initialState,
  reducers: {
    resetSEConfirmationState(state) {
      state.submitError = '';
      state.successConfirmation = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SEConfirmationThunks.validateSelfExclusion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.successConfirmation = action.payload;
        state.submitError = '';
      })
      .addCase(SEConfirmationThunks.validateSelfExclusion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        SEConfirmationThunks.validateSelfExclusion.rejected,
        (state, action: PayloadAction<string | { rejectValue: string } | null | undefined>) => {
          state.isLoading = false;
          state.submitError = action.payload as string;
          state.successConfirmation = '';
        },
      );
  },
});

export const { resetSEConfirmationState } = seConfirmationSlice.actions;

export default seConfirmationSlice.reducer;

export const selectSEConfirmation = {
  isLoading: (state: RootState) => state.myAccount.selfExclusionConfirm?.isLoading,
  submitError: (state: RootState) => state.myAccount.selfExclusionConfirm?.submitError,
  successConfirmation: (state: RootState) => state.myAccount.selfExclusionConfirm?.successConfirmation,
};
