import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { LayoutConfigTypes } from 'shared/common/features/general/types/generalSlice.types';

const route = '/my-account/responsible-gambling';

export const usePlayerRestrictionsConfig = () => {
  const playerRestrictionsConfig = useLayoutConfigType({
    moduleLayout: 'platform',
    innerLayout: LayoutConfigTypes.PLAYER_RESTRICTIONS,
    deviceLayout: 'general',
  }) || { buttonRoute: route };

  !playerRestrictionsConfig.buttonRoute && (playerRestrictionsConfig.buttonRoute = route);
  return playerRestrictionsConfig;
};
