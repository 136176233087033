import { createSlice } from '@reduxjs/toolkit';
import { NotificationTypes } from 'shared/common/features/myProfile/sections/tabs/notificationsTab/notificationTypes';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { AppDispatch } from 'store';
import { RootState } from 'store/rootReducer';
import { createAbortThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import config from 'utils/config';
import { GamificationPageTypes } from '../types/GamificationPage.types';
import { formatNotification, parsePageNotification } from '../utils/gamificationPage.utils';

const initialState: GamificationPageTypes.State = {
  notifications: {},
  activePageNotification: '',
  activeVertical: { id: null, apiUri: '' },
  unreadNotificationsByVertical: {},
  loading: 'idle',
  hasError: false,
};

export const gamificationPageThunks: GamificationPageTypes.Thunks = {
  fetchNotification: createAbortThunk(
    'gamificationPage/fetchNotification',
    async ({ apiUri }, { source, getState }) => {
      const playerId: string | undefined = selectMyProfile.playerId(getState());
      const response = await axiosInstance.get<string>(
        `${config.API_URL}${apiUri}${playerId ? `&playerId=${playerId}` : ''}`,
        {
          cancelToken: source.token,
        },
      );

      return response.data;
    },
  ),
  fetchUnreadNotificationsCount: createAbortThunk(
    'gamificationPage/fetchUnreadNotificationsCount',
    async (_, { source }) => {
      const response = await axiosInstance.get<Record<string, number>>(
        `${config.API_URL}/api/ews-crm/player/player-messages/page/count-unread`,
        { cancelToken: source.token },
      );

      return response.data;
    },
  ),
  fetchNotificationAwards: createAbortThunk('notification/fetchNotificationAwards', async (args) => {
    const { url } = args;
    const apiUrl = `${config.API_URL}/${url}`;
    const response = await axiosInstance.post<GamificationPageTypes.TripleBoxAwardsResponse>(apiUrl);

    return response.data;
  }),
  bonusesSelection: createAbortThunk(
    'notification/bonusesSelection',
    async ({ url, replacedPlayerBonusId, selectedBonusConditionId }) => {
      const apiUrl = `${config.API_URL}/${url}`;
      const props = {
        replacedPlayerBonusId,
        selectedBonusConditionId,
      };
      const response = await axiosInstance.post<NotificationTypes.Bonus>(apiUrl, props);

      return response.data;
    },
  ),
  getGroupConditionTerms: createAbortThunk('notification/bonusesSelection', async ({ url }) => {
    const apiUrl = `${config.API_URL}/${url}`;

    const response = await axiosInstance.get<{ text?: string }>(apiUrl);

    return response.data;
  }),
};

export const readPageNotification =
  ({
    id,
    verticalId,
    verticalRoute,
    selectedPlayerBonusId,
  }: {
    id: string;
    verticalId?: number;
    verticalRoute?: string;
    selectedPlayerBonusId?: string;
  }) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      dispatch(setReadPageNotificationById({ id, verticalId, verticalRoute }));
      await axiosInstance.post(`${config.API_URL}/api/ews-crm/player/player-messages/page/read`, {
        id,
        selectedPlayerBonusId,
      });
    } catch (err) {
      dispatch(setReadPageNotificationById({ id, verticalId, verticalRoute }));
    }
  };

const gamificationPage = createSlice({
  name: 'gamificationPage',
  initialState,
  reducers: {
    setReadPageNotificationById(state, action) {
      const { verticalId = 0, verticalRoute = '' } = action.payload;
      if (state.notifications?.[verticalId]) {
        state.notifications[verticalId].isRead = true;
        if (state.unreadNotificationsByVertical[verticalRoute] > 0) {
          state.unreadNotificationsByVertical[verticalRoute] = state.unreadNotificationsByVertical[verticalRoute] - 1;
        }
      }
    },
    setActivePageNotification: (state, action) => {
      state.activePageNotification = action.payload;
    },
    resetPageNotificationsCount: (state) => {
      state.unreadNotificationsByVertical = {};
    },
    resetPageNotification: (state, action) => {
      if (state.notifications[action.payload]) {
        delete state.notifications[action.payload];
        state.activePageNotification = '';
        state.activeVertical = { id: null, apiUri: '' };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(gamificationPageThunks.fetchNotification.fulfilled, (state, action) => {
        const { verticalId, apiUri } = action.meta.arg;

        state.activePageNotification = '';
        state.activeVertical = { id: verticalId, apiUri };

        const notification = formatNotification(parsePageNotification(action.payload));

        state.notifications[verticalId] = notification;
        state.loading = 'succeeded';
        state.hasError = false;
      })
      .addCase(gamificationPageThunks.fetchNotification.pending, (state, action) => {
        const { noLoadingState } = action.meta.arg;
        if (!noLoadingState) {
          state.loading = 'pending';
        }
        state.hasError = false;
      })
      .addCase(gamificationPageThunks.fetchNotification.rejected, (state, action) => {
        const { verticalId } = action.meta.arg;
        state.activePageNotification = '';
        delete state.notifications[verticalId];
        state.loading = 'failure';
        state.hasError = true;
      });
    builder.addCase(gamificationPageThunks.fetchUnreadNotificationsCount.fulfilled, (state, action) => {
      state.unreadNotificationsByVertical = action.payload;
    });
  },
});

export default gamificationPage.reducer;

export const {
  setReadPageNotificationById,
  resetPageNotificationsCount,
  resetPageNotification,
  setActivePageNotification,
} = gamificationPage.actions;

export const selecGamificationPage = {
  notification: (verticalId?: number) => (state: RootState) =>
    verticalId ? state.common.pageNotification.notifications[verticalId] : undefined,
  loading: (state: RootState) => state.common.pageNotification.loading,
  hasError: (state: RootState) => state.common.pageNotification.hasError,
  activePageNotification: (state: RootState) => state.common.pageNotification.activePageNotification,
  unreadNotificationsByVertical:
    (route = '') =>
    (state: RootState) =>
      state.common.pageNotification.unreadNotificationsByVertical?.[route] || 0,
  allUnreadNotifications: (state: RootState) => state.common.pageNotification.unreadNotificationsByVertical,
};
