import React, { useCallback, useState } from 'react';
import CTAUI from 'shared/common/features/myProfile/components/CTAUI';
import { useAppDispatch } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { readOffers } from '../../slice/offersTab.slice';
import { Category } from '../../types/offersTab.types';

type Props = {
  activeCategory?: Category;
  promotionTitle?: string;
  items: React.ReactNode;
  bonusIds?: string[];
};

export const OfferBonusLayout02: React.FC<Props> = ({ activeCategory, items, bonusIds, promotionTitle }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useAppDispatch();

  const handleExpand = useCallback(() => {
    setIsExpanded((expand) => !expand);
    if (activeCategory?.alias && !isEmpty(bonusIds)) {
      dispatch(readOffers(activeCategory?.alias, bonusIds));
    }
  }, [activeCategory?.alias, bonusIds, dispatch]);

  return (
    <div className="mpd-cta__wrapper--layout-02" data-ics--global data-ics--offers>
      {activeCategory && (
        <CTAUI.Header
          dataQid={`mpd-offers-cat-${activeCategory.alias}`}
          icon={activeCategory.resourceName || ''}
          title={promotionTitle ?? activeCategory.title}
          isRead={isEmpty(bonusIds)}
          expandable
          isExpanded={isExpanded}
          onClick={handleExpand}
        />
      )}
      {isExpanded ? items : null}
    </div>
  );
};
