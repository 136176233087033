import React from 'react';
import clsx from 'clsx';
import { GridLayouts, GridRowType } from 'modules/casino/modules/container/cellsContainer/grid/types/gridTypes';
import {
  CategoryLayoutTypes,
  CategorySubTypes,
} from 'modules/casino/modules/container/shared/features/categoryBreadcrumb/types/CategoryLayoutTypes';
import { NavigationLayout } from 'modules/casino/shared/components/gridLayoutSettings/gridLayoutSettingsTypes';
import { useGridLayoutSettings } from 'modules/casino/shared/components/gridLayoutSettings/hooks/useGridLayoutSettings';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { cssGlobalNS } from 'modules/casino/shared/types/css.types';

type Props = {
  children: React.ReactNode[];
  layoutGrid: GridLayouts;
  subType: CategorySubTypes;
  rows: GridRowType;
  forwardRef?: any;
  childrenCount?: number;
  categoryId?: string;
  categoryLayout?: CategoryLayoutTypes;
};

const GenericLayoutWrapper: React.FC<Props> = ({
  children,
  layoutGrid,
  subType,
  rows,
  forwardRef,
  categoryId,
  categoryLayout,
}) => {
  const categoryIdNew = categoryId || 'false';
  const categoryLayoutNew = categoryLayout || CategoryLayoutTypes.LOBBY;
  const { menuNavigationLayout, providerNavigationLayout } = useGridLayoutSettings();
  const hasSidebar =
    menuNavigationLayout === NavigationLayout.VERTICAL || providerNavigationLayout === NavigationLayout.VERTICAL;
  const { hasNumbers } = useGetCategoryParams(categoryIdNew, categoryLayoutNew);
  const customChildCount = hasNumbers ? `${cssGlobalNS}-c-flex` : 'grid';
  const gridGenericWrapperStyle = clsx(
    'generic-wrapper',
    `subtype_${subType.toLowerCase()}`,
    layoutGrid.toLowerCase(),
    rows.toLowerCase(),
    customChildCount,
    hasNumbers && 'numbering-category',
    hasSidebar && 'grid--hasSidebar',
  );
  return (
    <div className={gridGenericWrapperStyle} ref={forwardRef}>
      {children}
    </div>
  );
};

export default GenericLayoutWrapper;
