import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/shared';
import { Modal } from 'components/shared/Modal';
import { ModalUI } from 'components/shared/ModalUI';
import { UI } from 'components/shared/UI';
import useHandleModals from '../hooks/useHandleModals';

type Props = {
  modalTypePhrase?: 'SportRefreshModal' | 'TimeoutModal';
  titlePhrase?: 'longTimeInactiveText' | 'connectionLostText' | 'newContentText';
};

const TimeOutModal: React.FC<Props> = ({ titlePhrase = 'longTimeInactiveText', modalTypePhrase = 'TimeoutModal' }) => {
  const { t } = useTranslation();
  const { refreshPage } = useHandleModals();

  return (
    <Modal visible={true} className="modal-terms">
      <Modal.Body className="d-flex-col-ac text-center" padding="lg">
        <ModalUI.Title text={t(`${modalTypePhrase}.Message.${titlePhrase}`)} />
        <Icon className="my-3 modal-terms__icon" icon="refresh" />
        <ModalUI.Title className="mb-3" hasAccent text={t(`${modalTypePhrase}.Message.refreshPageText`)} />
        <UI.Button size="lg" onClick={refreshPage} text={t(`${modalTypePhrase}.Button.refreshButton`)} />
      </Modal.Body>
    </Modal>
  );
};

export default TimeOutModal;
