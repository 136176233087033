import React, { useRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/shared/layout';
import { useLayoutConfigType } from 'hooks/common/useLayoutConfigType';
import { selectCashierData } from 'modules/retail/modules/cashier/slice/cashier.slice';
import { formatCurrency } from 'modules/retail/modules/cashier/utils';
import { selectAuthentication } from 'pages/auth/login/slice/login.slice';
import { useAppSelector } from 'store';
import { isEmpty } from 'utils/common/helpersCommon';
import { CashierTranslations } from 'utils/common/translationUtils/translationStrings';
import { BurgerUI } from './components/BurgerUI';
import { useBurgerRetailData } from './hooks/useBurgerRetailData';
import { NavBrand } from '../appHeader/components';
import RetailMobileTopBar from '../appHeader/components/appHeaderContainer/RetailMobileTopBar';
import RetailDrawerToggler from '../appHeader/components/AppHeaderUI/RetailDrawerToggler';
import { RetailHeaderUser } from '../appHeader/retailHeader/features/retailHeaderDefault/features/retailHeaderUser';
import { RetailHeaderUserDefaultBtn } from '../appHeader/retailHeader/features/retailHeaderDefault/features/retailHeaderUser/components';
import { useDrawerVisibility } from '../drawer/hooks/useDrawerVisibility';
import { LayoutConfigTypes } from '../general/types/generalSlice.types';

/** BurgerRetailMobile */
interface BurgerMenuProps {
  className?: string;
  children?: React.ReactNode;
  isToggled: boolean;
}

const BurgerRetailMobile: React.FC<BurgerMenuProps> = ({ isToggled }) => {
  const { t } = useTranslation();
  const { burgerMenuData, burgerMenuItemsList } = useBurgerRetailData();
  const posInfo = useAppSelector(selectCashierData.posInfo);
  const isAuthenticated = useAppSelector(selectAuthentication.isAuthenticated);
  const drawerRef = useRef<HTMLDivElement>(null);
  useDrawerVisibility({ drawerRef, isToggled });

  const retailDrawerWrapperClass = clsx(isToggled && 'drawer-overlay drawer-overlay--dark', 'drawer--on-top');
  const retailDrawerClass = clsx('d-flex-col drawer', isToggled && 'drawer--active drawer--retail');

  const isCurrencyBeforeAmount = useLayoutConfigType({
    moduleLayout: 'platform',
    deviceLayout: 'general',
    innerLayout: LayoutConfigTypes.CURRENCY_BEFORE_AMOUNT,
  });

  const temporaryLimitText = formatCurrency(
    posInfo?.posInfo?.financialSettings?.bettingShopTemporaryLimit || 0,
    posInfo?.posInfo?.financialSettings.currency || '',
    isCurrencyBeforeAmount,
  );

  return (
    <div className={retailDrawerWrapperClass}>
      <div ref={drawerRef} className={retailDrawerClass} tabIndex={1}>
        <BurgerUI.Retail>
          <div className="r-header r-header--mobile">
            <RetailMobileTopBar isAuthenticated={isAuthenticated} />
            <Flex.ACJB className="r-header__inner--mobile">
              <RetailDrawerToggler isDrawerVisible={isToggled} />
              <NavBrand logoClassName="r-header-logo--mobile" />
              <RetailHeaderUser isAuthenticated={isAuthenticated} isRetailMobile={true} />
            </Flex.ACJB>
          </div>
          {!isEmpty(posInfo) && (
            <div className="text-center">
              {`${t(CashierTranslations.FinancialModal.betsLimit)} : ${temporaryLimitText}`}
            </div>
          )}
          <div className="egtd-burger-retail__links-wrapper no-scrollbars overflow-y-auto overflow-x-hidden">
            {burgerMenuItemsList.map((itemAlias, index) => {
              const itemData = burgerMenuData[itemAlias];
              if (isEmpty(itemData) || !itemData.isEnabled) return;

              return (
                <div key={index} className="rnav__item-link ">
                  <RetailHeaderUserDefaultBtn
                    onClick={itemData.onClick}
                    text={t(itemData.text)}
                    icon={itemData.icon}
                    isDisabled={itemData.isDisabled}
                  />
                </div>
              );
            })}
          </div>
        </BurgerUI.Retail>
      </div>
    </div>
  );
};

export default BurgerRetailMobile;
