import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { useAppSelector } from 'store';
import { selectFinancialInfo } from '../features/finance/slice/financialReports.slice';

/** Cashier Modal Info Wrapper Section */
interface ModalContentProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: React.ReactNode;
}

const ModalContent: React.FC<ModalContentProps> = ({ className, children }) => {
  const isFinanceButton = useAppSelector(selectFinancialInfo.isFinanceButton);

  const wrapClass = clsx(
    isFinanceButton && 'cashier-modal__financial-content custom-scrollbars overflow-y-auto overflow-x-hidden',
    !isFinanceButton && 'cashier-modal__content',
    className,
  );

  return <div className={wrapClass}>{children}</div>;
};

export default ModalContent;
