import React from 'react';
import { UI } from 'components/shared/UI';
import { useGridLayoutSettings } from 'modules/casino/shared/components/gridLayoutSettings/hooks/useGridLayoutSettings';
import { Flex } from 'modules/casino/shared/components/layout';
import { Text } from 'modules/casino/shared/components/text';
import { useGetCategoryParams } from 'modules/casino/shared/hooks/useGetCategoryParams';
import { CategoryLayoutTypes } from '../../types/CategoryLayoutTypes';
import { getIconSrc } from '../../utils/categoryBreadcrumbUtils';
import LobbySubtitle from '../headerSubtitles/LobbySubtitle';

interface Props {
  categoryId: string;
  categoryLayoutType: CategoryLayoutTypes;
}

const LobbyTitle: React.FC<Props> = ({ categoryId, categoryLayoutType }) => {
  const { title, iconLocation, titleColor } = useGetCategoryParams(categoryId, categoryLayoutType);
  const iconSrc = getIconSrc(iconLocation);
  const { enableColorIcons } = useGridLayoutSettings();
  const usePng = enableColorIcons && iconSrc && iconSrc.split('.').pop()?.toLowerCase() === 'png';

  const iconComponent = usePng ? (
    <UI.PNGCDN className="lobby-category-header__title-icon" icon={iconSrc} />
  ) : (
    <UI.SVGCDN className="lobby-category-header__title-icon" icon={iconSrc} />
  );

  return (
    <Flex.AC className="mw-0 mr-2">
      {iconSrc && iconComponent}
      <Flex.Col className="overflow-hidden">
        <div className="lobby-category-header__title">
          <Text
            text={title}
            style={{ color: titleColor }}
            type={categoryLayoutType == CategoryLayoutTypes.LOBBY ? 'h1' : undefined}
          />
        </div>
        <LobbySubtitle categoryId={categoryId} categoryLayoutType={categoryLayoutType}></LobbySubtitle>
      </Flex.Col>
    </Flex.AC>
  );
};

export default LobbyTitle;
