import React from 'react';
import clsx from 'clsx';
import { CSS } from 'utils/common/types/css.types';
import SVGCDN from '../UI/SVGCDN/SVGCDN';

/** DefaultChevron */
interface DefaultChevronProps {
  className?: string;
  direction?: CSS.Directions;
  size?: number;
}

const DefaultChevron: React.FC<DefaultChevronProps> = ({ className, direction = 'right', size = 12 }) => {
  const checvronClass = clsx(className);

  return <SVGCDN path={'GENERAL'} size={size} className={checvronClass} icon={`chevron-default-${direction}`} />;
};

DefaultChevron.displayName = 'Default Chevron Icon';

export default DefaultChevron;
