import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Breakpoints } from 'theme/Theme';
import { isEmpty } from 'utils/common/helpersCommon';

export const SelectBonusHeader = ({ bonus, header, style }) => {
  return (
    <HeaderWrapper>
      {isEmpty(bonus) && <Info>{header}</Info>}
      {!isEmpty(bonus) && (
        <AwardWrapper $color={style?.headerTextColor} $background={style?.textBackground}>
          {Object.keys(bonus).map((el, index) => (
            <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ duration: 0.5 }} key={index}>
              {index > 0 && <Symbol>&gt;</Symbol>}
              <span>“{bonus[el].title}”</span>
            </motion.div>
          ))}
        </AwardWrapper>
      )}
    </HeaderWrapper>
  );
};
const HeaderWrapper = styled.div`
  min-height: 58px;
  @media (min-width: ${Breakpoints.isMobile}px) {
    min-height: 76px;
  }
`;
const AwardWrapper = styled.div<{ $color?: string; $background?: string }>`
  background: ${({ $background }) => $background ?? 'rgba(0, 0, 0, 0.35)'};
  color: ${({ $color }) => $color ?? '#fff'};
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  padding: 9px 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 2px;
  backdrop-filter: blur(2px);
  line-height: normal;
  @media (min-width: ${Breakpoints.isMobile}px) {
    font-size: 16px;
    padding: 14px 7px;
  }
`;

const Info = styled.div`
  color: #434343;
  display: -webkit-box;
  text-overflow: ellipsis;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-size: 16px;
  text-align: center;

  font-style: italic;
  @media (min-width: ${Breakpoints.isMobile}px) {
    font-size: 18px;
  }
`;
const Symbol = styled.span`
  padding: 0 5px;
`;
