import {
  FavouritesSection,
  FavouritesTypes,
} from 'shared/common/features/drawer/components/favourites/types/favourites.types';
import { selectDrawer, setFavourites } from 'shared/common/features/drawer/slice/drawer.slice';
import { selectMyProfile } from 'shared/common/features/myProfile/slice/myProfile.slice';
import { AppDispatch } from 'store';
import { RootState } from 'store/rootReducer';
import { createAbortThunk, CreateThunk } from 'store/thunkCreators';
import axiosInstance from 'utils/common/axios-instance';
import { localLog } from 'utils/common/helpersCommon';
import { Sport } from 'utils/common/types/sportTypes';
import config from 'utils/config';

// -------- selecting favourites from UserStorage -------- //

const getFavouriteByType = (
  items: FavouritesTypes.Favourites,
  type: FavouritesTypes.FavouritesSection,
  parentId?: number,
): number[] | undefined => {
  if (!items) return;
  return parentId
    ? (items[type] as FavouritesTypes.MarketTemplatesPerSport[])?.find((item) => item.sportId === parentId)
        ?.marketTemplateIds
    : (items[type] as number[]);
};

export const selectFavouritesById =
  (type: FavouritesTypes.FavouritesSection, id: number, parentId?: number) =>
  (state: RootState): boolean | undefined =>
    type && id ? getFavouriteByType(selectDrawer.favourites(state), type, parentId)?.includes(id) : undefined;

export const selectFavouritesByType =
  (type: FavouritesTypes.FavouritesSection, parentId?: number) =>
  (state: RootState): number[] | undefined =>
    getFavouriteByType(selectDrawer.favourites(state), type, parentId);

// -------- updating favourites -------- //
const apiMap = {
  sportIds: 'sportId',
  sportEventIds: 'sportEventId',
  marketTemplatesPerSport: 'marketTemplateId',
  drawer: 'drawer',
};

export const updateFavourites =
  ({
    type,
    id,
    parentId,
    isLive,
    sportType,
  }: {
    type: FavouritesTypes.FavouritesSection;
    id: number;
    parentId?: number;
    isLive?: boolean;
    activeEventId?: boolean;
    sportType?: Sport.SportType;
    eventMatchId?: number;
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const exists = !!selectFavouritesById(type, id, parentId)(getState());
    const playerId = selectMyProfile.playerId(getState());

    if (playerId && type !== FavouritesSection.drawer) {
      const requestParams = { [apiMap[type]]: id, userId: playerId, ...(parentId && { sportId: parentId }) };
      const newFavourites = exists ? await deleteUserFavourite(requestParams) : await addUserFavourite(requestParams);
      newFavourites?.playerId ? dispatch(setFavourites(newFavourites)) : dispatch(getUserFavourites());
    } else {
      setNewFavourites({ type, id, parentId, getState, dispatch });
    }

    if (type === FavouritesSection.sportEventIds && isLive && sportType !== 'ESPORT') {
      setNewFavourites({ type: 'liveSportEventIds', id, parentId, getState, dispatch });
    }
  };

export const getUserFavourites: CreateThunk<void, FavouritesTypes.Favourites, string | null> = createAbortThunk(
  'settings/userFavourites',
  async (_, { getState, dispatch }) => {
    const playerId = selectMyProfile.playerId(getState());
    const response = await axiosInstance.get(`${config.API_URL}/api/user-favorites/player/${playerId}`);
    const data = response.data;
    dispatch(setFavourites(data));
    return data;
  },
);

export const addUserFavourite = async (
  data: FavouritesTypes.FavouritesRequest,
): Promise<FavouritesTypes.Favourites | undefined> => {
  try {
    const url = `${config.API_URL}/api/user-favorites/add`;
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error) {
    localLog({ message: 'Add player favourites request failed.', type: 'warn' });
  }
};

export const deleteUserFavourite = async (
  data: FavouritesTypes.FavouritesRequest,
): Promise<FavouritesTypes.Favourites | undefined> => {
  try {
    const url = `${config.API_URL}/api/user-favorites/delete`;
    const response = await axiosInstance.delete(url, { data });
    return response.data;
  } catch (error) {
    localLog({ message: 'History is not correct, bigger than allDays', type: 'warn' });
  }
};

const setNewFavourites = ({ type, id, parentId, getState, dispatch }) => {
  const favouritesByType = selectFavouritesByType(type, parentId)(getState());
  const newFavourites = setFavouritePerType(favouritesByType, type, id, parentId);
  dispatch(setFavourites({ [type]: newFavourites }));
};

const setFavouritePerType = (
  favourites: number[] | undefined,
  type: FavouritesTypes.FavouritesSection,
  id: number,
  parentId: number | undefined,
): number[] | FavouritesTypes.MarketTemplatesPerSport[] => {
  const exists = !!favourites?.find((favouritesId) => favouritesId === id);
  const newFavourites = !exists
    ? [...(favourites || []), id]
    : favourites?.filter((favouritesId) => favouritesId !== id) || [];

  return parentId
    ? [
        ...((favourites?.[type] as FavouritesTypes.MarketTemplatesPerSport[])?.filter(
          (item) => item.sportId !== parentId,
        ) || []),
        { sportId: parentId, marketTemplateIds: newFavourites },
      ]
    : newFavourites;
};
