import styled, { css } from 'styled-components';
import { Breakpoints } from 'theme/Theme';

const btnStyleType1 = (isCircle, showText) => css`
  border-radius: ${isCircle ? '100%' : '3px'};
  ${isCircle &&
  `
      width:16px;
      height:16px;
      `}

  ${!showText &&
  !isCircle &&
  `  
    width:38px;
    height:27px;
  `}
  ${showText &&
  !isCircle &&
  `  
    padding: 8px 12px 7px 12px;
  `}
`;
const btnStyleType2 = (isCircle, showText) => css`
  border-radius: ${isCircle ? '6px' : '100px'};
  ${isCircle &&
  `
  width:18px;
  height:18px;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    width:24px;
    height:24px;
  }
      `}

  ${!showText &&
  !isCircle &&
  `  
  width:26px;
  height:26px;
  border-radius:6px;
  padding:5px;
  border:0;
  @media screen and (min-width: ${Breakpoints.isDesktop}px) {
    width:30px;
    height:30px;
  }
  `}
`;

const getBtnStyle = (layout, isCircle, showText) => {
  const btnMap = {
    layout_1: btnStyleType1(isCircle, showText),
    layout_2: btnStyleType2(isCircle, showText),
  };

  return btnMap[layout];
};

export const StyledBonusBtn = styled.div<{
  $isAccaBoost?: boolean;
  $showText?: string;
  $isCircle?: boolean;
  $isCircleIcon?: boolean;
}>`
  font-size: 0.75rem;
  fill: currentColor;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 8px;
    @media screen and (min-width: ${Breakpoints.isDesktop}px) {
      margin-right: 10px;
    }
  }
  ${({ $showText, $isCircle }) =>
    $showText &&
    !$isCircle &&
    `  
    padding: 8px 12px 7px 12px;
  `}
  ${({ $isCircle, $showText, theme }) => getBtnStyle(theme.LayoutConfigs?.sport?.sportBtns, $isCircle, $showText)}
`;

export const SpanStyled = styled.span.attrs({
  className: 'acca-bonuses-icon-wrapper d-flex-center',
})<{ showText: boolean }>`
  border-radius: 100%;
  width: 16px;
  height: 16px;
  margin-top: 2px;
  margin-left: ${({ showText }) => (!showText ? '4px' : '')};
`;
